import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularGridInstance,Column,Formatter } from 'angular-slickgrid';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { environment } from 'src/environments/environment';
import { ZsClContentService } from '../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { MessageService } from 'src/app/common/components/message/message.service';

declare var $: any;
const mappingColumnmDownvoteFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  let level =
    dataContext.score * 100 >= 75
      ? 'HIGH'
      : dataContext.score * 100 >= 50
      ? 'MEDIUM'
      : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let txt = "";

  if (level === 'HIGH') {
    txt =
      txt +
      `<div class="box-container text-center text-uppercase" title='${level}'>${level}</div>`;
  }
  if (level === 'MEDIUM') {
    txt =
      txt +
      `<div class="box-container boxM text-center text-uppercase" title='${level}'>${level}</div>`;
  }
  if (level === 'LOW') {
    txt =
      txt +
      `<div class="box-container boxL text-center text-uppercase" title='${level}'>${level}</div>`;
  }

  txt =
    txt +
    `<div class="porcent text-center" title='${score100}%'>${score100}%</div>`;
  return txt;
};

const datasetNameRadioFormattter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return `<div class="datasource-name-formatter text-wrap d-flex">
  <span class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label mr-l-14"></label>
  </span>
                <span title='${value}' class="pointer hyper-link">${value}</span><br>
            </div>`;
};

const radioFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
};

const mappingColumnsFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
 
  let level =
    dataContext.score * 100 >= 80
      ? 'HIGH'
      : dataContext.score * 100 >= 50
      ? 'MEDIUM'
      : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let txt = '';

  let condLike = dataContext.is_up_vote ? 'fas' : 'fal';
  let condUnlike = dataContext.is_down_vote ? 'fas' : 'fal';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

  if (level === 'HIGH') {
    txt =
      txt +
      `<div class="box-container text-center text-uppercase" title='${level}'>${level}</div>`;
  }
  if (level === 'MEDIUM') {
    txt =
      txt +
      `<div class="box-container boxM text-center text-uppercase" title='${level}'>${level}</div>`;
  }
  if (level === 'LOW') {
    txt =
      txt +
      `<div class="box-container boxL text-center text-uppercase" title='${level}'>${level}</div>`;
  }

  txt =
    txt +
    `<div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-2">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-2">${downCount}</span>
  `;

  return txt;
};

@Component({
  selector: "zetta-synonymsmodel",
  templateUrl: "./synonyms-model.component.html",
  styleUrls: ["./synonyms-model.component.scss"],
})
export class SynonymsModelComponent implements OnInit {
  isLoadingSynonyms = true;
  synonyms: any = [];
  gridSynonymsDataOptions: any;
  synonymsTableColDef: any;
  tableSettings: object = { height: "100%", width: "100%" };
  gridSynonyms: AngularGridInstance;
  noData = false;
  totalSynonyms = 0;
  grandTotal = 0;
  subTotal = '';
  hasSynonymsScrolled = false;
  @Input() showModel: boolean;
  @Output() hideModel = new EventEmitter<boolean>();
  @Input() catalog_id: number;
  @Input() entity_attribute_id: string;
  @Input() user_id: number;
  @Input() synonymsName: string;
  @Input() dataSetId: number;
  synCatalogName: string;
  catalog_name: string;
  synPage = 1;
  synonymsList: any;
  limit = 100;
  loggedInUserDetails: [];
  showDownvoteModal = false;
  dataset_id = 0;
  selectedDsColumn;
  showBackdrop: boolean = true;
  showSynonym: boolean = true;
  confarray: any = [];
  object_name;
  attribute_id;
  object_id;
  selectedTag = "";
  notSureOption = "not_sure";
  total = 0;
  page = 1;
  pglimit = 100;
  modeltableSettings: object = { height: "100%", width: "99.50%" };
  modalGridOptions: any;
  modelColumnDef = [];
  is_downvote_loading = true;
  public modalGrid: AngularGridInstance;
  @Input() isConcept: boolean = false;
  isTagSelected = false;
  ds_classification: any = [];
  concept_name: string;
  entity_name_right: string;
  concept_id: number;
  synonym_entity_attribute_id: number;
  attribute_id_left: number;
  entity_id_left: number;
  tenant_id_left: number;
  tenant_id_right: number;
  attribute_id_right: string;

  showFeedbackSummaryModel: Boolean = false;
  votedUsers: [];
  screenName: string;
  voteValue: string;
  semanticObjectName: string;
  conceptName: string;
  synSemanticObjectName: string;
  synConceptName: string; 
  showLoadingSpinner: boolean = false;
  disableSaveButton: boolean = false;
  constructor(
    private catalogSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,
    private router: Router,
    public zettaUtils: ZettaUtils
  ) {}

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activatedRoute.parent.params.subscribe((params) => {
      this.dataset_id = params["id"];
    });
    this.gridSynonymsDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };
    this.synonymsTableColDef = [
      {
        displayname: "Catalog",
        physicalname: "catalog_name_left",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Semantic Object",
        physicalname: "entity_name_left",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Concept",
        physicalname: "attribute_logical_name_left",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Synonym Catalog",
        physicalname: "catalog_name_right",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Synonym Object",
        physicalname: "entity_name_right",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Synonym Concept",
        physicalname: "attribute_logical_name_right",
        sortable: true,
        datatype: "String",
        filterable: true,
      },
      {
        displayname: "Synonym Confidence",
        physicalname: "",
        sortable: true,
        datatype: "String",
        filterable: false,
        formatter: mappingColumnsFormatter, 
      },
    ];
  }
  ngOnChanges(changes: SimpleChanges) {
    sessionStorage.removeItem("serverfilter");
    sessionStorage.removeItem("sortfilter");
    if (changes.showModel && changes.showModel.currentValue) {
      this.getCatalog();
      this.getSynonyms(this.synPage, this.limit);
    }
  }
  getCatalog() {
    this.catalogSvc.getCatalog(this.catalog_id).subscribe((resp) => {
      this.catalog_name = resp.name;
    });
  }
  getSynonyms(page, limit) {
    this.tableSettings["search_url"] = `${environment.config.API_URL}/users/${
      this.user_id
    }/catalogs/${this.catalog_id}/synonyms?pagination=true&${
      this.entity_attribute_id
    }&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.catalogSvc
      .getSynonymsPaged(
        this.catalog_id,
        this.entity_attribute_id,
        page,
        limit,
        serverFilter,
        sortfilter
      )
      .subscribe(
        (data) => {
          this.synonyms = data["currentpage"];
          this.synonymsList = data;
          if (this.synonyms.length === 0) {
            this.noData = true;
          } else {
            if (page == 1) {
              limit = 100;
              this.synCatalogName = this.synonyms[0]["catalog_name_right"];
            }
          }
          this.totalSynonyms = data["totalrecords"];
          if (page === 1) {
            this.grandTotal = data["totalrecords"];
          }
          this.isLoadingSynonyms = false;
          let concatedRows = this.synonyms;
          ($("#synonyms_modal") as any).modal("show");
          if (this.gridSynonyms) {
            concatedRows = [
              ...this.gridSynonyms.dataView.getItems(),
              ...this.synonyms,
            ];
            this.synonyms = concatedRows;
            this.synonyms.forEach((element) => {
              element.id = Math.random();
            });
            this.gridSynonyms.dataView.setItems(this.synonyms);
            this.gridSynonyms.gridService.setSelectedRows([]);
            this.hasSynonymsScrolled = false;
          }
        },
        (err) => {
          this.synonyms = [];
          this.synonymsList = [];
          ($("#synonyms_modal") as any).modal("show");
        }
      );
  }

  onSynonymsGridCreation(grid) {
    this.gridSynonyms = grid;
  }

  closeModel() {
    this.synonyms = [];
    this.synonymsList = [];
    ($("#synonyms_modal") as any).modal("hide");
    this.hideModel.emit(false);
    this.disableSaveButton = false;
  }

  onScrollSyn(parentClass) {
    if (
      this.zettaUtils.virtualScroll(parentClass) &&
      !this.hasSynonymsScrolled &&
      !this.noData
    ) {
      this.hasSynonymsScrolled = true;
      this.onNextSynPage();
    }
  }

  onNextSynPage(): void {
    if (this.synPage < this.synonymsList["totalpages"]) {
      this.synPage++;
      this.getSynonyms(this.synPage, this.limit);
    }
  }
  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridSynonyms.gridService.getDataItemByRowIndex(args.row);
    this.selectedDsColumn = args.row; 
    this.concept_name = row.attribute_logical_name_right;
    this.entity_name_right = row.entity_name_right; 
    (this.tenant_id_left = row.tenant_id_left),
      (this.tenant_id_right = row.tenant_id_right),
      (this.attribute_id_left = row.attribute_id_left);
    this.synonym_entity_attribute_id = row.synonym_entity_attribute_id;
    if (
      eventEle.target.className == "fal fa-thumbs-down handDown" ||
      eventEle.target.className == "handDown fal fa-thumbs-down" ||
      eventEle.target.className == "fal handDown fa-thumbs-down"
    ) {
      this.selectedTag = "";
      this.entity_id_left = row.entity_id_left;
      this.showDownvoteModal = true;
    }
    if (eventEle.target.className == "fal fa-thumbs-up") {
      $("#thumbs-up-" + args.row).removeClass("fal fa-thumbs-up");
      $("#thumbs-up-" + args.row).addClass("fas fa-thumbs-up");
      $("#thumbs-down-" + args.row).removeClass("fas fa-thumbs-down");
      $("#thumbs-down-" + args.row).addClass("fal fa-thumbs-down");
      row["is_up_vote"] = true;
      row["is_down_vote"] = false;
      this.confarray.forEach((ele) => {
        if (
          ele.synonym_entity_attribute_id == row.synonym_entity_attribute_id
        ) {
          this.confarray.splice(ele, 1);
        }
      });
      this.confarray.push({
        synonym_entity_attribute_id: row.synonym_entity_attribute_id,
        is_up_vote: true,
        is_down_vote: false,
      });
    } else if (
      (eventEle.target.className == "fal fa-thumbs-down handDown" ||
        eventEle.target.className == "handDown fal fa-thumbs-down" ||
        eventEle.target.className == "fal handDown fa-thumbs-down") &&
      this.selectedTag
    ) {
      row["is_up_vote"] = false;
      row["is_down_vote"] = true;
      $("#thumbs-up-" + args.row).removeClass("fas fa-thumbs-up");
      $("#thumbs-up-" + args.row).addClass("fal fa-thumbs-up");
      $("#thumbs-down-" + args.row).removeClass("fal fa-thumbs-down");
      $("#thumbs-down-" + args.row).addClass("fas fa-thumbs-down");
    } else if (eventEle.target.className.includes('countUp')) {      
      this.showFeedbackSummaryModel = true;
      this.votedUsers = row.upvotes;
      this.voteValue = 'upVote'
      this.screenName = 'synonyms';
      this.semanticObjectName = row.entity_name_left;
      this.conceptName = row.attribute_logical_name_left;
      this.synSemanticObjectName = row.entity_name_right;
      this.synConceptName = row.attribute_logical_name_right; 
    } else if (eventEle.target.className.includes('countDown')) {
      this.showFeedbackSummaryModel = true;
      this.votedUsers = row.downvotes;
      this.voteValue = 'downVote'
      this.screenName = 'synonyms';
      this.semanticObjectName = row.entity_name_left;
      this.conceptName = row.attribute_logical_name_left;
      this.synSemanticObjectName = row.entity_name_right;
      this.synConceptName = row.attribute_logical_name_right; 
    }
  }

  onRunModel() {
    this.SaveVote();
    this.catalogSvc.runModel303().subscribe(
      (res) => {
        this.messageSvc.sendMessage({
          message: "",
          type: "Info",
          hideInfo: true,
          activeJobs: "Classify",
          messageHeader: AppGlobals.CLASSIFICATION_MSG_HEADER,
          messageText: AppGlobals.CLASSIFICATION_MSG_TEXT,
          path: "zs-cl/catalogs/" + this.catalog_id,
        });
      },
      (err) => {
        this.messageSvc.sendMessage({
          message: err.error.error.message,
          type: "ERROR",
          hideboard: true,
        });
      }
    );
  }

  getselectedTag(name) {
    this.selectedTag = name;
    this.saveClassificationTags();
  }

  saveClassificationTags() {
    this.confarray.forEach((ele) => {
      if (ele.synonym_entity_attribute_id == this.synonym_entity_attribute_id) {
        this.confarray.splice(ele, 1);
      }
    });
    if (this.selectedTag !== undefined) {
      if (this.selectedTag == "not_sure") {
        this.confarray.push({
          synonym_entity_attribute_id: this.synonym_entity_attribute_id,
          is_up_vote: false,
          is_down_vote: true,
        });
      } else {
        this.attribute_id_right = this.selectedTag;
        this.confarray.push({
          synonym_entity_attribute_id: this.synonym_entity_attribute_id,
          is_up_vote: false,
          is_down_vote: true,
          upvote: {
            attribute_id_left: this.attribute_id_left,
            tenant_id_left: this.tenant_id_left,
            tenant_id_right: this.tenant_id_right,
            attribute_id_right: this.attribute_id_right,
          },
        });
      }
    }
    if (this.selectedTag == undefined) {
      $("#thumbs-down-" + this.selectedDsColumn).removeClass(
        "fas fa-thumbs-down"
      );
      $("#thumbs-down-" + this.selectedDsColumn).addClass("fal fa-thumbs-down");
    } else {
      $("#thumbs-up-" + this.selectedDsColumn).removeClass("fas fa-thumbs-up");
      $("#thumbs-up-" + this.selectedDsColumn).addClass("fal fa-thumbs-up");
      $("#thumbs-down-" + this.selectedDsColumn).removeClass(
        "fal fa-thumbs-down"
      );
      $("#thumbs-down-" + this.selectedDsColumn).addClass("fas fa-thumbs-down");
    }
  }
  SaveVote() {
    this.showLoadingSpinner = true;
    this.disableSaveButton = true;
    let self = this;
    this.catalogSvc
      .saveSynonymLevel(this.loggedInUserDetails["tenant_id"], this.confarray)
      .subscribe(
        (data) => {
          self.showLoadingSpinner = false;
          self.disableSaveButton = false;
          self.closeModel();
        },
        (err) => {
          self.showLoadingSpinner = false;
          self.disableSaveButton = false;
          self.closeModel();
          self.messageSvc.sendMessage({ message: 'Something went wrong', type: "ERROR",hideboard: true});
        }
      );
    
  }

  closeModalPopup(event) {
    if (event) {
      $("#thumbs-down-" + this.selectedDsColumn).removeClass(
        "fal fa-thumbs-down"
      );
      $("#thumbs-down-" + this.selectedDsColumn).addClass("fas fa-thumbs-down");
    }
    this.showDownvoteModal = false;
  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
   }
}
