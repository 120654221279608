import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseService } from 'src/app/common/services/base-service';
import { HelpService } from '../help.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

@Component({
  selector: 'zetta-help',
  templateUrl: './user-help.component.html',
  styleUrls:['./user-help.component.scss']
})
export class UserHelpComponent implements OnInit {
  apiUrl: string ;
  userId:number ;
  srcUrl:SafeHtml ;
  appId:number;
  constructor(private _baseService:BaseService,private sanitizer: DomSanitizer, private helpService: HelpService){}
  ngOnInit(){
      this.userId =this._baseService.getObjectFromSession('userInfo').user_id;
      this.appId =this._baseService.getObjectFromSession('userInfo').preference.default_app_id;
      this.apiUrl=this._baseService.apiUrl;
      this.srcUrl =this.sanitizer.bypassSecurityTrustResourceUrl(this.apiUrl+'/users/'+this.userId+'/help/app/'+this.appId);
  }

  openHelpContent(argss) {
    this.helpService.getPdfUrl(argss).subscribe(res => {
      if(res) {
        this.helpService.exportPdfFormat(res)
      }
    })
  }

  navigateToAutoLogin(docType) {
    if(docType == 'Classify') {
      window.open(AppGlobals.HELP_DOC_CLASSIFY_URL, "_blank");
    }  else if(docType == 'Resolve') {
      window.open(AppGlobals.HELP_DOC_RESOLVE_URL, "_blank");
    } else {
      this.openHelpContent(this.apiUrl + '/users/' + this.userId + '/help/app/' + 4);
    }
  }
}