import { AppGlobals } from '../../../../common/shared/app.globals';
import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../project.service';
//import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
// import { optionSelectionFormatter } from '../../../../common/shared/formatters/optionSelectionFormatter';
// import { blueTextModelFormatter } from '../../../../common/shared/formatters/blueTextModelFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project.model';
import { ActivatedRoute } from '@angular/router';
import { matchFormatter } from '../../../../common/shared/formatters/matchFormatter';
import { noMatchFormatter } from '../../../../common/shared/formatters/noMatchFormatter';
import { notSureFormatter } from '../../../../common/shared/formatters/notSureFormatter';
import { FeedbackFilter } from '../../../../common/shared/filters/feedback-filter';
import { blueTextFormattter } from '../../../../common/shared/formatters/blueTextFormatter';
import { toolTipFormatter } from '../../../../common/shared/formatters/toolTipFormatter';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
import { taskAssignmentFormatter } from 'src/app/common/shared/formatters/taskAssignmentCheckboxFormatter';
import { ColDef, Grid, GridApi, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mather-merger-formatter/mather-merger-formatter.component';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { ProjectNameFormatterComponent } from 'src/app/common/shared/cell-renderer/project-name-formatter/project-name-formatter.component';
import { NameLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
import { OptionSelectionRendererComponent } from 'src/app/common/shared/cell-renderer/option-Selection-formatter/option-Selection-formatter.component';
import { BlueTextFormatterComponent } from 'src/app/common/shared/cell-renderer/blue-Text-Formatter/blue-Text-Formatter.component';
import { BlueTextRendererComponent } from 'src/app/common/shared/cell-renderer/bluetext-renderer-formattter/bluetext-renderer-formattter.component';
import { TaskAssignmentRendererComponent } from 'src/app/common/shared/cell-renderer/task-assignment-formatter/task-assignment-formatter.component';
import { event } from 'jquery';
import { environment } from '../../../../../environments/environment';
declare var $: any;

const zettaUtilsInstance = new ZettaUtils();
const blueTextModelFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  // if (dataContext.has_suggested_clusters && dataContext['status'] !== 'Completed') {
  // if (dataContext['status'] !== 'Completed') {
      return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}' data-toggle="modal"  data-backdrop="static"  data-target="#tm-modal">
                  ${value}
              </div>`;
  // } else {
      // return `<div class="text-truncate" title='${value}'>${value}</div>`;
  // }
}

const optionSelectionFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  // if (dataContext.has_suggested_clusters && dataContext['status'] !== 'Completed') {
  // if (dataContext['status'] !== 'Completed') {
      return `<div class='formatter'>
                  <div class='fa fa-graduation-cap pointer modal-feedback' data-toggle="modal" data-backdrop="static" data-target="#tm-modal"></div>
              </div>`;
  // } else {
      // return `<div class='formatter'><div class='fa fa-graduation-cap modal-feedback disable-feedback'></div></div>`;
  // }
}

 const numericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        return `<div class="text-center w-100" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
    }
    
    
    const taskAssignmentRenderer = (params, taskStatus) => {
      const { data, colDef } = params;
    
      if(colDef.field === 'isReviewer' && data.isReviewer && taskStatus ) {
      return ` <label id='user-${params.row}-${colDef.field}' class="ml-3 mr-top-7 check-cell">
      <input type="checkbox" class="matched-task">
      </label>`
      } else {
        return `<label class="ml-3 mr-top-7 check-cell">
          <input type="checkbox" disabled></label>`;
      }
    };

    const taskAssignmentApproverRenderer = (params, taskStatus) => {
      const { data, colDef } = params;
      if(colDef.field === 'isApprover' && data.isApprover && taskStatus ) {
      return ` <label id='user-${params.row}-${colDef.field}' class="ml-3 mr-top-7 check-cell">
      <input type="checkbox" class="matched-task">
      </label>`
      } else {
        return `<label class="ml-3 mr-top-7 check-cell">
          <input type="checkbox" disabled></label>`;
      }
    };

@Component({
  selector: 'zetta-matcher-feedback',
  templateUrl: './matcher-feedback.component.html',
  styleUrls: ['./matcher-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MatcherFeedbackComponent implements OnInit {

  /* Stage 1 properties */
  public isStage1Loading = true;
  public isStage1SelectedClusterExpanded = false;
  public stage1SelectedClusterTotal = 0;

  public stage1Rows: object[];
  public stage1Columns: object[];
  public stage1selectedClusterName: string;
  public stage1FeedBack: object[] = [];

  /* Stage 2 properties */
  public isStage2Loading = true;
  public isStage2SelectedClusterExpanded = false;
  public stage2SelectedClusterTotal = 0;

  public stage2Rows: object[];
  public stage2Columns: object[];
  public stage2selectedClusterName: string;
  stage2FeedBack: object[] = [];

  /* Stage 3 properties */
  public isStage3Loading = true;
  public isStage3SelectedClusterExpanded = false;
  public stage3SelectedClusterTotal = 0;
  searchedClusterkeys = [];
  searchedClusters;
  stage3EnableFinishBtn: boolean = false;
  newlySelectedClusters;
  isSearching = false;
  searchedColumns;
  selectedClusterColumns;
  selectedOrphanRecords;
  orphanGrid: AngularGridInstance;
  addedClusterGrid: Array<AngularGridInstance> = [];

  public stage3Rows: object[];
  public stage3Columns: object[];
  public stage3selectedClusterName: string;
  public searchtext = '';
  public suggestedClusters: object = {};
  public suggestedFeedback: object = {};
  public isSuggestedClusterLoading = false;
  public isSuggested = true;
  public suggestedColumns;
  public trainModelDataSource: object = {};
  public selectOptionDataSource: object = {};
  public suggestedClusterkeys = [];
  public rowData;
  public grid: AngularGridInstance;
  public stage2Grid: AngularGridInstance;
  public suggestedGrid: AngularGridInstance;
  public matcherGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedClusterName;
  public selectedCluster;
  public isSelectedClusterExpanded = false;
  public selectedColumnsfeedbackColumns;
  public currentStage = 0;
  public selectedClusterId = 0;
  public hasPrevious = true;
  selectedOrphanRecsToAssign = [];
  customAddedCluserInstance: Array<AngularGridInstance> = [];
  viewTaskAssignments = false;
  taskAssignmentPermission = true;
  taskAssignmentsColDef: any;
  isTaskAssignmentsLoading = true;
  public hasScrolled = false;
  taskAssignmentsRowData: any;
  taskAssignmentsGridOptions: any;
  taskAssignmentsGrid: AgGridAngular;
  errorMsg: string;
  enableApplyBtn: boolean;
  summaryHasChanged = false;
  ownClusterSelected: boolean = false;
  existingClustersSelected: boolean = false;

  public tableSettings: object = { 'height': "100%", 'width': "100%" };
  public addedClusterTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  gridOptions: any;
  feedbackGridOptions: any;
  addedClusterGridOptions: any;
  stage2GridOptions: any;
  projectDetail = new Project();

  isLoading = false;
  isClusterItemsLoading = false;
  total = 0;
  page = 1;
  limit = 100;
  pageLeft = 1;
  selectedClusterTotal = 0;
  noneOfAboveSearchedCluster: any;
  proposedClusters: any;
  public isReRunModelEnabled = true;
  noData = false;
  selectedTaskStatus: string;
  selectedLeftGridRowsSize = 0;
  isProjectAdmin: Boolean = false;
  shouldDisableFeedback = false;
  modeState: any = {};
  feedbackStage3GridOptions: any;
  selectedClusters = [];
  addedClusters = [];
  customAddedClusters = [];
  selectedRecsData = [];
  newCluster = {
    opened: false
  };
  selectedOrphanIndexes = [];
  addedCluserInstance: Array<AngularGridInstance> = [];
  selectedOrphanRecordsCount;
  newPayload = [];
  msgText: string = '';
  msgHeader: string = '';
  showConfirmBox: boolean = false;
  onPreviousClicked: boolean = false;
  resolveTrainingTaskGridState = {
    sort: null,
    search: ''
  }
  columnDefs: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid:AgGridAngular;
  gridApi:any;
  columnApi: any;
  taskStatusCompleted:string='Completed';
  taskStatusReviewed: string='Reviewed';
  observableSvc: any;
  isShowRightGrid: any;
  taskAssignDetails: any;
  rightGridColumnsDef: any;
  leftGridData;
  selectedRows: any[];
  subTotal= null;
  showApprovalCheckBox: boolean = false;
  showReviewerCheckBox: boolean = false;
  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    private _router: Router, public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
    
    this.checkUserPermission();
    this.getProjectUsers();

    this.trainModelTableColDef = [
      {
        'displayname': 'Task Name',
        'physicalname': 'zs_cluster_identifier',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
        'formatter': blueTextModelFormatter,
      },
      {
        'displayname': 'Records',
        'physicalname': 'zs_total_records_cluster',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'headerCssClass': 'text-center',
        'formatter': numericFormatter,
      },
      {
        'displayname': 'Status',
        'physicalname': 'status',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 140,
        'headerCssClass': 'pl-4 ml-3',
        'formatter': assignmentStatusFormatter,
      },
      {
        'displayname': 'Open',
        'physicalname': '',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-center',
        'formatter': optionSelectionFormatter,
      },
      {
        'displayname': 'Last Updated',
        'physicalname': 'last_updated',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'headerCssClass': 'pl-4',
        'formatter': dateFormatter
      }
    ];

    this.taskAssignmentsGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      headerHeight: 45, 
      rowHeight: 30,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
    };

    this.feedbackGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };

    this.addedClusterGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      CheckboxSelector: true,
      multiSelectable: true,
    };

    this.feedbackStage3GridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      multiSelectable: true
    };
    this.stage2GridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      frozenColumn: 2,
    };

    this.getRunsupervisedData(false);
    this.addedClusters = [];
    this.customAddedClusters = [];
    this.intialiseGrid()
  }
  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: '',
        headerCheckboxSelection: () => {
          return !!this.isProjectAdmin; 
        },
         checkboxSelection: (params) => {
          return this.isProjectAdmin && (params.data && params.data.status !== this.taskStatusCompleted && params.data.status!== this.taskStatusReviewed);
        },
        minWidth: 30,
        maxWidth:35
      },
      {
        headerName: 'Task Name',
        field: 'zs_cluster_identifier',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        minWidth: 300,
        cellRendererFramework: BlueTextFormatterComponent,
       
      },
      {
        headerName: 'Records',
        field:'zs_total_records_cluster',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        cellRendererFramework: NumericFormatterComponent,
        minWidth: 130,
      },{
        headerName: 'Status',
        field:'status',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework:AssignmentStatusFormatterComponent,
        minWidth: 130,
        floatingFilter: true,
        sortable: true,
       
      },{
        headerName: 'Open',
        field: '',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: false,
        sortable: false,
        cellClass: 'cell-align-center-text',
        cellRendererFramework:OptionSelectionRendererComponent,
        minWidth: 130,
      },{
        headerName: 'Last Updated',
        field: 'last_updated',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework: DateFormatterFormatterComponent,
        floatingFilter: true,
        sortable: true,
        minWidth: 130,
      
      },
    ];
   
    this.fsGridOptions = {  
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      suppressRowClickSelection:true,

      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
  }

  getTaskAssignmentsColDef() {
    this.taskAssignmentsColDef = [
      {
        headerName: 'User Name',
        field: 'user_name',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework: BlueTextRendererComponent,
        floatingFilter: true,
        sortable: true,
        minWidth: 220,
      
      },{
        headerName: 'Reviewer',
        field: 'isReviewer',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRenderer: (params) => taskAssignmentRenderer(params, this.showReviewerCheckBox),
        floatingFilter: false,
        sortable: false,
        minWidth: 180,
      },{
        headerName: 'Approver',
        field: 'isApprover',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRenderer: (params) => taskAssignmentApproverRenderer(params, this.showApprovalCheckBox),
        floatingFilter: false,
        sortable: false,
        minWidth: 180,
      },
    ];
  }

  getRunsupervisedData(showLoadingIndicator) {
    if (showLoadingIndicator) {
      this.isLoading = true;
    }
    this.ProjectSvc.getRunsuperviseByProjectId({ userId: this.loggedInUserDetails.user_id, selectedProjectId: this.projectDetail.project_id }, 'entitiesresolved').subscribe((data) => {
        if (showLoadingIndicator) {
          this.isLoading = false;
        }
        this.isReRunModelEnabled = data['run_supervised'];
      }, err => {
        if (showLoadingIndicator) {
          this.isLoading = false;
        }
      });
  }

  onGridStatusChanged(_trainingTaskGridState) {
    this.resolveTrainingTaskGridState.sort = _trainingTaskGridState.sort ? _trainingTaskGridState.sort: null;
  }

  getTrainModelList(pageno, limit = 100) {
     this.isLoading = true;
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved/feedback/summary`;
    this.ProjectSvc.getTrainEntitiesByProjectId(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limit).subscribe((data) => {
          this.trainModelDataSource = data;
          if (this.trainModelDataSource['currentpage'].length === 0) {
            this.noData = true;
          }
          let shouldGridStateChangeSortApply = this.resolveTrainingTaskGridState.sort && this.resolveTrainingTaskGridState.sort != '' ? true : false;
          let inReviewTasks = [];
          let otherTasksApartFromInReview = [];
          this.isLoading = false;
          this.total = data['totalrecords'];
          this.trainModelDataSource['currentpage'].forEach(element => {
            element.shouldDisable = (element.status === AppGlobals.TASK_COMPLETED) ? true : false;
            if (shouldGridStateChangeSortApply) {
              if (element.status === AppGlobals.TASK_IN_REVIEW) {
                inReviewTasks.push(element);
              } else {
                otherTasksApartFromInReview.push(element);
              }
            }
          });
          if (shouldGridStateChangeSortApply) {
            this.trainModelDataSource['currentpage'] = [];
            this.trainModelDataSource['currentpage'] = [...inReviewTasks,...otherTasksApartFromInReview];
          } 
          let concatedLeftRows = this.trainModelDataSource['currentpage'];
          if (this.grid) {
            concatedLeftRows = [...this.grid.dataView.getItems(), ...this.trainModelDataSource['currentpage']];
            this.trainModelDataSource['currentpage'].forEach(element => {
              element.id = Math.random();
            });
            this.trainModelDataSource['currentpage'] = concatedLeftRows;
            this.grid.dataView.setItems(concatedLeftRows);
            this.grid.gridService.setSelectedRows([]);
            this.hasScrolled = false;
          }
          // this.trainModelDataSource['currentpage'] = this.trainModelDataSource['currentpage'].filter(task => task.status === "Completed");
    }, err => {
          this.isLoading = false;
          this.trainModelDataSource['currentpage'] = [];
    });
  }

  onSelectedCluster(e) {
    this.isSelectedClusterExpanded = true;
  }

  getSelectedClusterCount() {
    return this.selectedClusterTotal;
  }

  selectOptionModelList(clusterId, limit = 1000) {
    this.isLoading = true;
    this.isSelectedClusterExpanded = false;
    this.selectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        clusterId, this.projectDetail.project_id, 1, limit).subscribe((data) => {
          this.selectOptionDataSource = data;
          this.rowData = this.selectOptionDataSource['currentpage'];
          this.selectedColumnsfeedbackColumns = this.selectOptionDataSource['columns'];
          this.selectedColumnsfeedbackColumns.unshift(...this.getFeedbackColumns());
          this.rowData.forEach((element: any) => {
            element.id = Math.random();
            this.selectedClusterName = element.zs_cluster_identifier;
          });
          this.getSuggestedCluster(clusterId, this.selectedClusterName);
          this.selectedClusterTotal = data['totalrecords'];
          if (this.matcherGrid) {
            this.matcherGrid.dataView.setItems(this.rowData);
            this.matcherGrid.gridService.setSelectedRows([]);
          }
          this.isLoading = false;
    }, err => {
          this.isLoading = false;
    });
  }

  getSuggestedCluster(clusterId, fullName): void {
    // this.isSuggestedClusterLoading = true;
    this.isSuggested = true;
    this.ProjectSvc.getSuggestedCluster({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: clusterId, clusterName: fullName
    }).subscribe(resp => {
        this.isSuggestedClusterLoading = true;
        this.suggestedClusterkeys = Object.keys(resp.currentpage);
        this.onLoadModal();
        this.suggestedClusters = resp.currentpage;
        this.suggestedColumns = resp.columns;
        this.suggestedColumns.forEach(col => {
          col.displayname = col.display_name;
          if (col.displayname === 'Full Name') {
            col.minWidth = 300;
          }
          col.physicalname = col.physical_name;
          col.datatype = col.data_type;
        });
        this.suggestedClusterkeys.forEach(e => {
          this.suggestedClusters[e].rows = [];

          this.suggestedClusters[e].forEach(element => {
            element.id = Math.random();
            if (element['zs_feedbackid'] !== undefined && element['zs_feedbackid'] !== '') {
              this.suggestedFeedback[e] = element['zs_feedbackid'];
            }
            this.suggestedClusters[e].rows.push(element);
          });
        });
        this.isSuggested = false;
    }, err => {
        this.isSuggested = false;
        this.onLoadModal();
    });
  }

  getFeedbackColumns() {
    return [{
      'displayname': '',
      'physicalname': 'match',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': matchFormatter,
      'filter': {
        model: new FeedbackFilter('Match', 'match-feedback')
      },
      'params': { selectedTaskStatus: this.selectedTaskStatus, shouldDisableFeedback:this.shouldDisableFeedback }
    },
    {
      'displayname': '',
      'physicalname': 'nomatch',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': noMatchFormatter,
      'filter': {
        model: new FeedbackFilter('No Match', 'nomatch-feedback')
      },
      'params': { selectedTaskStatus: this.selectedTaskStatus, shouldDisableFeedback:this.shouldDisableFeedback }
    },
    {
      'displayname': '',
      'physicalname': 'notsure',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': notSureFormatter,
      'filter': {
        model: new FeedbackFilter('Not Sure', 'notsure-feedback')
      },
      'params': { selectedTaskStatus: this.selectedTaskStatus,shouldDisableFeedback:this.shouldDisableFeedback }
    }];
  }

  onStage2UpdateFeedback(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.stage2Grid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('radio-formatter')) {
        if (this.stage2SelectedClusterTotal === 1) {
          return;
        }
        if ($(eventEle.target).hasClass('match-formatter')) {
          row.zs_feedbackid = AppGlobals.MATCH_FEEDBACK_STATUS;
        } else if ($(eventEle.target).hasClass('nomatch-formatter')) {
          row.zs_feedbackid = AppGlobals.NO_MATCH_FEEDBACK_STATUS;
        } else if ($(eventEle.target).hasClass('notsure-formatter')) {
          row.zs_feedbackid = AppGlobals.NOT_SURE_FEEDBACK_STATUS;
        }
        this.stage2Grid.gridService.updateDataGridItem(row, false);
        this.stage2FeedBack = this.stage2FeedBack.filter(data => data['zs_recid'] !== row.zs_recid);
        this.stage2FeedBack.push({
          'zs_recid': row.zs_recid,
          'zs_feedbackid': row.zs_feedbackid
        });
        // auto save for 2nd screen removed since it is changing task status
        this.autoUpdateFeedback(this.stage2FeedBack)
      }
    }
  }

  autoUpdateFeedback(data) {
    let anchorRecord = []
    this.stage2Rows.filter(row => {
      if (row['is_anchor_record']) {
        anchorRecord.push({
          'zs_recid': row['zs_recid'],
          'zs_feedbackid': '1',
          'feedback_type': 'not_matched_records'
        })
      }
    });
    data.forEach(item => {
      item['feedback_type']= 'not_matched_records';
    });
    const requestPayload = [...data, ...anchorRecord];
    this.isClusterItemsLoading = true;
    this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload })
      .subscribe(resp => {
          if (resp) {
            this.taskSaved();
          }
      }, err => {
      });
  }

  updateFeedback(data, refresh) {
    if(this.currentStage == 0) {
      this.ProjectSvc.updateFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.stage1FeedBack }).subscribe(resp => {
        if (refresh) {
          this.taskSaved();
          this.refresh();
        }
    }, err => {
    });
    } else {
      let anchorRecord = []
      this.stage2Rows.filter(row => {
        if (row['is_anchor_record']) {
          anchorRecord.push({
            'zs_recid': row['zs_recid'],
            'zs_feedbackid': '1'
          })
        }
      });
      const requestPayload = [...data, ...anchorRecord];
      this.isClusterItemsLoading = true;
        this.ProjectSvc.updateFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload }).subscribe(resp => {
            if (refresh) {
              this.taskSaved();
              // this.activatedRoute.
              this.refresh();
            }
        }, err => {
        });
    }
  }

  setRoleIdToNull(){

  }
  onGridCreation(grid) {
    const parent = this;
    parent.agGrid = grid;
    const gridApi = grid.api;

    gridApi.addEventListener('rowSelected', function (event) {
        parent.selectedRows = gridApi.getSelectedRows();
        parent.leftGridData = parent.selectedRows;
      if  (parent.selectedRows.length) {
        parent.selectedLeftGridRowsSize = parent.selectedRows.length;
        const selectTasks = [];
        parent.taskAssignmentsRowData.forEach(user => {
          user.role_id = null;
        });
        parent.selectedRows.forEach(row => {
          const rowData = row.data
          if (row !== undefined && row !== null) {
            selectTasks.push(rowData);
            parent.taskAssignmentsRowData.forEach(user => {
              const selectedUser = user.roles.find(role => role === row.role_id);
              if (selectedUser) {
                user.role_id = row.role_id;
              }
            });
          }
        });
      } else {
        parent.viewTaskAssignments = false;
        parent.selectedLeftGridRowsSize = 0;
        parent.taskAssignmentsRowData.forEach(user => {
          user.role_id = null;
        });
      }
      parent.isHavingAccess();
      if (parent.taskAssignmentsGrid) {
        parent.taskAssignmentsGrid.api.setRowData(parent.taskAssignmentsRowData);
      }
    });
  }

  checkForTaskTypes() {
    throw new Error('Method not implemented.');
  }

  onSuggestedGridCreation(grid) {
    this.suggestedGrid = grid;
  }

  onStage2GridCreation(grid) {
    this.stage2Grid = grid;
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
    this.zettaUtilsSvc.setGridHorizontalScrollTogether('tm-modal');
  }

  onTaskAssignmentsGridCreation(grid) {
    const parent = this;
    parent.agGrid = grid;
    const gridApi = grid.api;
    this.taskAssignmentsGrid = grid;
}

onTaskAssignmentsGridCellClick(params): void {
  const { event, data, colDef } = params;
  const row = data;
  const fieldName = colDef.field;
  if (row !== undefined && row !== null) {
      if (fieldName === 'isReviewer' && event.target.className.includes('matched-task')) {
          this.toggleCheckedState(row, fieldName, AppGlobals.RESOLVE_PROJECT_REVIEWER);
      }
      if (fieldName === 'isApprover' && event.target.className.includes('matched-task')) {
          this.toggleCheckedState(row, fieldName, AppGlobals.RESOLVE_PROJECT_APPROVER);
      }
      if (fieldName === 'isAdmin' && event.target.className.includes('matched-task')) {
          this.toggleCheckedState(row, fieldName, AppGlobals.RESOLVE_PROJECT_ADMIN);
      }
  }
}

toggleCheckedState(row, fieldName, roleId): void {
  const isChecked = !row.isChecked;

  if (!isChecked) {
      // $(`#user-${row.row}-${fieldName}`).addClass('d-none');
      $(`#user-${row.row}-${fieldName}-checked`).removeClass('d-none');
  } else {
      // $(`#user-${row.row}-${fieldName}`).removeClass('d-none');
      $(`#user-${row.row}-${fieldName}-checked`).addClass('d-none');
  }

  row.isChecked = isChecked;

  if (fieldName === 'isReviewer') {
      row.isReviewerChecked = isChecked;
  } else if (fieldName === 'isApprover') {
      row.isApproverChecked = isChecked;
  } else if (fieldName === 'isAdmin') {
      row.isCheckedAdmin = isChecked;
  }

  row.role_id = roleId;
  this.validateUser();
}
  
  validateUser() {
    if (this.taskAssignmentsGrid && this.taskAssignmentsGrid.api) {
      const taskAssignmentUser = [];
      this.taskAssignmentsGrid.api.forEachNode(node => {
        taskAssignmentUser.push(node.data);
      });

      if (taskAssignmentUser.length) {
        const reviewerUser = taskAssignmentUser.filter(user => user.isReviewerChecked === true);
        const approverUser = taskAssignmentUser.filter(user => user.isApproverChecked === true);

        if (reviewerUser.length || approverUser.length) {
          this.enableApplyBtn = true;
          this.errorMsg = '';

          if (reviewerUser.length > 1 || approverUser.length > 1) {
            this.errorMsg = reviewerUser.length > 1 ? AppGlobals.REVIEWER_RVALIDATION_MSG : approverUser.length > 1 ? AppGlobals.APPROVER_RVALIDATION_MSG : '';
            this.enableApplyBtn = false;
          }
        } else {
          this.enableApplyBtn = false;
        }
      }
    }
  }
  
  goToPage(n: number): void {
    this.page = n;
    this.getTrainModelList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getTrainModelList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getTrainModelList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtilsSvc.getTotalPages(this.total, this.limit);
  }


  onCellClick(params): void {
    const { event, data, colDef } = params;
      const row = params.data;
      this.selectedCluster = row;
    if (row !== undefined && row !== null) {
      if (event.target.title === row.name) {
        this._router.navigate(['/zs/projects', row.project_id]);
      } else if ($(event.target).hasClass('modal-feedback')) {
        this.currentStage = 0;
        this.selectedClusterId = row.zs_cluster_id;
        this.selectedTaskStatus = row.status;
        this.summaryHasChanged = false;
        this.shouldDisableFeedback = false;
        let userDetails = this.taskAssignmentsRowData.find(user => user.user_id === this.loggedInUserDetails.user_id);   
        const isReviewdUser = (userDetails && userDetails.roles) ? userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER) : '';       
        if (row.status === AppGlobals.TASK_COMPLETED) {
          this.shouldDisableFeedback = true;
        }
        if (isReviewdUser && row.status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.shouldDisableFeedback = true;
        }
        this.getSupervisedFeedback();
      }
    }
  }

  

  onReRunModel() {
    // Scenario id is 1 for this case
    this.ProjectSvc.reRunModel({ projectId: this.projectDetail.project_id, scenarioId: 1 }, 'stage3').subscribe(resp => {
    
      if(resp){
        this.goToNext();
      }
    }, err => {
      // alert(err.error.message);
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }
  goToNext() {
    this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel: true, messageHeader: AppGlobals.RESOLVE_PROJECT_RUN_MODEL_HEADER_TEXT, messageText: AppGlobals.CLASSIFICATION_MSG_TEXT, path: `/zs/projects/${this.projectDetail.project_id}` });
}
  onTaskAssignments() {
    if(!this.viewTaskAssignments){
      this.checkForTaskStatus();
      this.getTaskAssignmentsColDef();
      this.getProjectUsers();
    }
    this.viewTaskAssignments = !this.viewTaskAssignments;
  }

  toggleAccordian(event) {
    const target = event.target.target;
    if ($(target).hasClass('show')) {
      $(target).removeClass('show');
      // this.isSuggested = false;
    } else {
      $(target).addClass('show');
      // this.getSuggestedCluster();
    }
  }

  toggleSuggestAccordian(event, clusterId) {
    const target = event.target.target;
    if (this.suggestedClusters['openCluster'] !== clusterId) {
      this.suggestedClusters['openCluster'] = clusterId;
    } else {
      this.suggestedClusters['openCluster'] = undefined;
    }
  }

  clearStage1Objects() {
    this.stage1Rows = [];
    this.stage1Columns = [];
    this.suggestedClusters = {};
    this.suggestedClusterkeys = [];
    this.suggestedFeedback = {};
    this.isSuggested = true;
    this.isSelectedClusterExpanded = false;
  }

  clearStage2Objects() {
    this.stage2Rows = [];
    this.stage2Columns = [];
    this.stage2FeedBack = [];
  }

  clearStage3Objects() {
    this.stage3Rows = [];
    this.stage3Columns = [];
    this.searchedClusterkeys = [];
    this.searchedClusters = '';
    this.isSearching = false;
    this.searchtext = '';
    $('#collapse1').removeClass('show');
    this.hasPrevious = true;
  }

  clearOrphanClusters() {
    this.searchedClusterkeys = [];
    this.searchedClusters = '';
    this.isSearching = false;
    this.searchtext = '';
    this.isSelectedClusterExpanded = false;
    // this.suggestedClusterkeys = [];
    // this.suggestedFeedback = {};
    // this.isSuggested = true;
    // this.isSelectedClusterExpanded = false;
  }

  getSupervisedFeedback() {
    if (this.currentStage === 0) {
      this.clearStage1Objects();
      this.getStage1Data();
    } else if (this.currentStage === 1) {
      this.clearStage2Objects();
      this.getStage2Data();
    } else if (this.currentStage === 2) {
      this.clearStage3Objects();
      this.getStage3Data();
    }
  }

  getStage1Data(limit = 1000) {
    this.isStage1Loading = true;
    this.hasPrevious = true;
    this.isStage1SelectedClusterExpanded = false;
    this.stage1SelectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        this.selectedClusterId, this.projectDetail.project_id, 1, limit).subscribe((data) => {
          this.stage1Rows = data['currentpage'];
          this.stage1Columns = data['columns'];
          this.stage1SelectedClusterTotal = data['totalrecords'];

          this.stage1Columns.forEach((col: any) => {
            col.displayname = col.display_name;
            if (col.displayname === 'Full Name') {
              col.minWidth = 300;
            }
            col.physicalname = col.physical_name;
            col.datatype = col.data_type;
            col.formatter = toolTipFormatter;
          });
          this.stage1Rows.forEach((element: any) => {
            element.id = Math.random();
            this.stage1selectedClusterName = element.zs_cluster_identifier;
            /**
             * Finish auto feedback only when there is a record in the cluster and zero suggestions
            */
            if (this.stage1SelectedClusterTotal === 1) {
              this.stage1FeedBack.push({
                'zs_recid': element.zs_recid,
                'zs_feedbackid': (element['zs_feedbackid'] !== undefined && element['zs_feedbackid'] !== '') ? element['zs_feedbackid'] : AppGlobals.MATCH_FEEDBACK_STATUS,
              });
            }
          });
          this.getSuggestedCluster(this.selectedClusterId, this.stage1selectedClusterName);
          this.isStage1Loading = false;
    }, err => {
          this.isStage1Loading = false;
          if (err && err.status === 404) {
            this.messageSvc.sendMessage({ message:  (err && err.error && err.error.message) ? err.error.message : 'Something went wrong', type: 'ERROR', hideboard: true });
          }
    });
  }

  getStage2Data(limit = 1000) {
    this.isStage2Loading = true;
    this.isStage2SelectedClusterExpanded = false;
    this.stage2SelectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        this.selectedClusterId, this.projectDetail.project_id, 1, limit).subscribe((data) => {
          this.stage2Rows = data['currentpage'];
          this.stage2Columns = data['columns'];
          this.stage2Columns.forEach((col: any) => {
            col.displayname = col.display_name;
            if (col.displayname === 'Full Name') {
              col.minWidth = 300;
            }
            col.formatter = function (row, cell, value, columnDef, dataContext) {
              if (dataContext.is_anchor_record === true) {
                col.cssClasses = 'anchor-record';
                return `<div class="text-truncate w-100 p-0 anchor-record" title="${value || 'null'}"><span class="mx-2">${value || '<span class="blur">null</span>'}</span></div>`;
              } 
              else {
                return `<div class="text-truncate" title="${value || 'null'}">${value || '<span class="blur">null</span>'}</div>`;
              }
            }
            col.physicalname = col.physical_name;
            col.datatype = col.data_type;
          });
          this.stage2Columns.unshift(...this.getFeedbackColumns());
          this.stage2Rows.forEach((element: any) => {
            element.id = Math.random();
            this.stage2selectedClusterName = element.zs_cluster_identifier;
            if (this.stage2Rows.length === 1) {
              element.zs_feedbackid = (element['zs_feedbackid'] !== undefined && element['zs_feedbackid'] !== '') ? element['zs_feedbackid'] : AppGlobals.MATCH_FEEDBACK_STATUS;
            }
            // Capture initial Feedback
            if (element.zs_feedbackid !== undefined && element.zs_feedbackid !== null
              && element.zs_feedbackid >= 0 && element.zs_feedbackid !== '') {
              this.stage2FeedBack.push({
                'zs_recid': element.zs_recid,
                'zs_feedbackid': element.zs_feedbackid
              });
            }
          });
          this.stage2SelectedClusterTotal = data['totalrecords'];
          this.isStage2Loading = false;
    }, err => {
          this.isStage2Loading = false;
    });
  }

  getStage3Data(limit = 100) {
    this.isStage3Loading = true;
    this.isStage3SelectedClusterExpanded = false;
    this.stage3SelectedClusterTotal = 0;
    this.stage3EnableFinishBtn = true;
    this.stage3Rows = this.stage2Rows.filter(row => row['zs_feedbackid'] === AppGlobals.NO_MATCH_FEEDBACK_STATUS);
    if(this.customAddedClusters && this.customAddedClusters.length) {
      this.customAddedClusters.forEach(record => {
        if(record && record.rows) {
          record['rows'].forEach(row => {
            this.stage3Rows = this.stage3Rows.filter(rec => rec['zs_record_id'] !== row['zs_record_id']);
          });
        }
      });
    }

    if(this.addedClusters && this.addedClusters.length) {
      this.addedClusters.forEach(record => {
        if(record && record.rows) {
          record['rows'].forEach(row => {
            this.stage3Rows = this.stage3Rows.filter(rec => rec['zs_record_id'] !== row['zs_record_id']);
          });
        }
      });
    }

    this.stage3Columns = this.stage1Columns;
    this.stage3Columns.forEach((col: any) => {
      col.displayname = col.display_name;
      if (col.displayname === 'Full Name') {
        col.minWidth = 300;
      }
      col.physicalname = col.physical_name;
      col.datatype = col.data_type;
      col.formatter = toolTipFormatter;
    });

    this.stage3Rows.forEach((element: any) => {
      element.id = Math.random();
      this.stage3selectedClusterName = element.zs_cluster_identifier;
    });

    // TODO Get Proposed cluster
    this.getProposedClusters();
    this.noneOfAboveSearchedCluster = this.zettaUtilsSvc.generateUUID();
    this.proposedClusters = undefined;
    this.stage3SelectedClusterTotal = this.stage3Rows.length;
    this.isStage3Loading = false;
    this.isFinishDisabled();
  }

  // TODO
  getProposedClusters() {
    const proposedCluster = [];
    const proposedClusterkeys = [];
    // TO DO get logic for proposed Cluster & its Keys(clusterids)

    this.searchedClusterkeys = [...proposedClusterkeys];
    this.searchedClusters = [...proposedCluster];
  }

  isNextDisabled() {
    if (this.currentStage === 1) {
      let anchor_record_row = this.stage2Rows.filter(row => {
        if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
          return row;
        }
      });
      let stage2RowsNew = [];
      if (anchor_record_row.length > 0) {
        stage2RowsNew = this.stage2Rows;
      } else {
        stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
      }
      if (this.stage2Rows !== undefined && this.stage2Rows.length > 0 && this.stage2FeedBack.length === stage2RowsNew.length) {
        const noMatchRec = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
        if (noMatchRec === undefined) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else if (this.currentStage === 0 &&
      (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
      return true;
    } else {
      return false;
    }
  }

  onFinish() {
    if (this.currentStage === 1) {
      let stage2Data = [];
      this.stage2Rows.forEach( row=> {
        stage2Data.push({ 'feedback_type': 'not_matched_records','zs_feedbackid': row['zs_feedbackid'] ? row['zs_feedbackid'] : 1, 'zs_recid': row['zs_recid']});
      });
      this.updateFeedback(stage2Data, true);
    } else if (this.stage1SelectedClusterTotal === 1 && this.suggestedClusterkeys.length <= 0) {
      this.updateFeedback(this.stage1FeedBack, true);
    } else if (this.currentStage === 2) {
      this.updateSearchedResultFeedback();
      // on finish we're calling the 2nd step feedback not on autosave
      this.updateFeedback(this.stage2FeedBack, false);
    } else if (this.stage1SelectedClusterTotal === 1 && this.suggestedClusterkeys.length > 0) {
      this.updateSuggestedClusterFeedback(true, true);
      }
      if(this.currentStage !== 2) {
        this.showConfirmBoxOnCancel();
      }
    this.getRunsupervisedData(true);
  }

  // Update Feedback from stage 3
  updateSearchedResultFeedback() {
    const requestPayload = {};
    // Get Recid's from stage 3 rows
    let recids = '';
    for (let i = 0; i < (this.stage3Rows.length ? this.stage3Rows.length : (this.addedClusters.length ? this.addedClusters.length: this.customAddedClusters.length)); i++) {
      if (i + 1 < (this.stage3Rows.length ? this.stage3Rows.length : (this.addedClusters.length ? this.addedClusters.length: this.customAddedClusters.length))) {
        recids = recids + (this.stage3Rows.length ? this.stage3Rows : (this.addedClusters.length ? this.addedClusters: this.customAddedClusters))[i]['zs_recid'] + ',';
      } else {
        recids = recids + (this.stage3Rows.length ? this.stage3Rows : (this.addedClusters.length ? this.addedClusters: this.customAddedClusters))[i]['zs_recid'];
      }
    }
    requestPayload['zs_feedbackid'] = AppGlobals.NO_MATCH_FEEDBACK_STATUS;
    requestPayload['zs_recid'] = recids;
    requestPayload['feedback_type'] = 'not_matched_records';
    requestPayload['zs_cluster_id_proposed'] = this.proposedClusters;

    if(this.selectedCluster == 2) {
      this.ProjectSvc.updateResoveProjectFeedback({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload
    }).subscribe(resp => {
      if(resp) {
        this.trainModelDataSource = [];
        if (this.grid) {
          this.grid.dataView.setItems(this.trainModelDataSource);
        }
        this.summaryHasChanged = true;
        this.getTrainModelList(1, this.limit);
          this.taskSaved();
          this.refresh();
          this.stage1FeedBack = [];
          this.addedClusters = [];
          this.customAddedClusters = [];
          $('#tm-modal').modal('hide');
          $('#tm-modal').addClass('d-none');
          this.showConfirmBoxOnCancel();
          $('.modal-backdrop').remove();
      }
    });
    } else {
      this.ProjectSvc.updateFeedback({
          userId: this.loggedInUserDetails.user_id,
          projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload
      }).subscribe(resp => {
        if(resp) {
          this.trainModelDataSource = [];
          if (this.grid) {
            this.grid.dataView.setItems(this.trainModelDataSource);
          }
          this.summaryHasChanged = true;
          this.getTrainModelList(1, this.limit);
            this.taskSaved();
            this.refresh();
            $('#tm-modal').modal('hide');
            $('.modal').modal('hide');
            this.suggestedFeedback = {};
            this.customAddedClusters = [];
            this.addedClusters = [];
            this.stage1FeedBack = [];
        }
      }
      );
    }
  }

  isFinishDisabled() {
    if (this.shouldDisableFeedback) {
      return true;
    }
    if (this.stage1SelectedClusterTotal === 1) {
      if (this.currentStage === 0 &&
        (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
        return true;
      } else {
        return false;
      }
    }
    if (this.currentStage === 1 && this.stage2Rows) {
      let anchor_record_row = this.stage2Rows.filter(row => {
        if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
          return row;
        }
      });
      let stage2RowsNew = [];
      if (anchor_record_row.length > 0) {
        stage2RowsNew = this.stage2Rows;
      } else {
        stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
      }
      
      // Add 2nd Stage Feedback's are captured
      if (this.stage2FeedBack.length === stage2RowsNew.length && this.stage2FeedBack.length > 0) {
        const noMatchRec = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
        if (noMatchRec) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else if (this.currentStage === 2 && this.proposedClusters !== undefined) {
      if(this.stage3EnableFinishBtn && this.stage3Rows.length>0) {
        return true;
      } else {
        return false;
      }
    } else if(this.currentStage === 2 && !this.proposedClusters && this.stage3Rows && this.stage3Rows.length<1) {
      return false;
    }
    return true;
  }

  nextModal() {
    if (this.currentStage === 1) {
      // since auto-save is implemented no need to call on next, in future if required can be uncommented
      // this.updateFeedback(this.stage2FeedBack, false);
      this.currentStage = 2;
      this.isFinishDisabled();
      this.customAddedClusters.forEach(cluster => {cluster['openRecord'] = false});
      this.addedClusters.forEach(cluster => {cluster['openRecord'] = false});
    } else if (this.currentStage === 0) {
      this.updateSuggestedClusterFeedback(false);
      return;
    }

    this.isSelectedClusterExpanded = false;
    this.getSupervisedFeedback();
  }

  preModal() {
    if (this.currentStage === 0) {
      return;
    }
    if(this.currentStage==2) {
      if((this.addedClusters && this.addedClusters.length>0) || (this.customAddedClusters && this.customAddedClusters.length>0)) {
        this.showConfirmBox = true;
        this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
        this.msgText = "Continue?";
        this.onPreviousClicked = true;
      } else {
        this.isSelectedClusterExpanded = false;
        this.currentStage = this.currentStage - 1;
        this.getSupervisedFeedback();
      }
    } else {
      this.isSelectedClusterExpanded = false;
      this.currentStage = this.currentStage - 1;
      this.getSupervisedFeedback();
    }
  }

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
    this.closeModal();
    $('#cancelBoxModal').modal('hide');
    $('#cancelBoxModal').addClass('d-none');
    // this.showConfirmBoxOnCancel();
    $('.modal-backdrop').remove();
    $('.modal-backdrop').removeClass('show');
    this.addedClusters = [];
    this.customAddedClusters = [];
  }
  
  closeModal() {

    if(this.currentStage == 2) {
      if((this.addedClusters && this.addedClusters.length>0) || (this.customAddedClusters && this.customAddedClusters.length>0)) {
        this.showConfirmBox = true;
        this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
        this.msgText = "Continue?";
      } else {
        this.showConfirmBox = false;
        this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
        this.isSelectedClusterExpanded = false;
        this.stage1FeedBack = [];
        this.addedClusters = [];
        this.customAddedClusters = [];
        $('#tm-modal').modal('hide');
        $('#tm-modal').addClass('d-none');
        this.showConfirmBoxOnCancel();
        $('.modal-backdrop').remove();
      }
    } else {
      this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
      this.isSelectedClusterExpanded = false;
      this.stage1FeedBack = [];
      $('#tm-modal').modal('hide');
      $('#tm-modal').addClass('d-none');
      this.showConfirmBoxOnCancel();
      $('.modal-backdrop').remove();
    }
    this.onPreviousClicked = false;
  }

  onSearch() {
    // {{url}}/users/1/projects/2/entitiesresolved/feedback/search?key=jing ying s de rl de cv
    this.isSearching = true;
    this.searchedClusterkeys = [];
    this.ProjectSvc.searchEntityCluster({
        userId: this.loggedInUserDetails.user_id, clusterId: this.selectedClusterId,
        projectId: this.projectDetail.project_id, searchString: this.searchtext
    }, true).subscribe(resp => {
      this.searchedClusterkeys = Object.keys(resp.currentpage);
      this.searchedClusters = resp.currentpage;
      this.searchedColumns = resp.columns; 
      this.searchedColumns.forEach((col: any) => {
        col.displayname = col.display_name;
        if (col.displayname === 'Full Name') { 
          col.minWidth = 300;
        }
        col.physicalname = col.physical_name + '_o';
        col.datatype = col.data_type;
        col.formatter = toolTipFormatter;
      });
      this.selectedClusterColumns = this.searchedColumns;
      this.searchedClusterkeys.forEach(e => {
        this.searchedClusters[e].rows = [];

        this.searchedClusters[e].forEach(element => {
          element.id = Math.random();
          this.searchedClusters[e].rows.push(element);
        });
      });
      this.isSearching = false;
      this.proposedClusters = undefined;
      // }

    }, err => {
      this.isSearching = false;
    });
   
  }

  onSearchedClusterPlus(clusterId, searchedClusters, clusterName='', existingCluster, searchedClusterkeys=[]) {
    this.checkForClusterSelectedTypes();
    if (this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && !this.existingClustersSelected && !this.ownClusterSelected) {
      this.proposedClusters = clusterId;
      let newRows = [];
      if (searchedClusters) {
        let combinedRows = this.selectedOrphanRecords.concat(searchedClusterkeys);
        this.addedClusters.push({ ...this.selectedOrphanRecords[0], rows: combinedRows, clusterName: clusterName, openRecord: false });
        // this.addedClusters.push(this.searchedClusters[clusterId]);
        this.newPayload = [];
        this.addedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              this.newPayload.push({
                'zs_recid': row.zs_recid,
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              });
            });
            // projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
            // comment and use below line of code to 
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.newPayload })
              .subscribe(resp => {
                if(resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
              this.stage3Rows.splice(ele, 1);
              this.isFinishDisabled();
            });
            if (this.orphanGrid) {
              this.orphanGrid.gridService.setSelectedRows([]);
              this.orphanGrid.dataView.refresh();
              this.orphanGrid.dataView.setItems(this.stage3Rows);
            }
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
                }
              });
          }
        });
      } else {
        let addedRecs = [];
        let selectedRecIds = [];
        this.customAddedClusters.push({ ...this.selectedOrphanRecords[0], rows: this.selectedOrphanRecords });
        this.customAddedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              if(row && row.zs_recid) {
                selectedRecIds.push(row.zs_recid);
              }
            });

            let newPayload = {
              'zs_recid': selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
              .subscribe(resp => {
                if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
                    this.stage3Rows.splice(ele, 1);
                      this.isFinishDisabled();
                      });            
                    if (this.orphanGrid) {
                      this.orphanGrid.gridService.setSelectedRows([]);
                      this.orphanGrid.dataView.refresh();
                      this.orphanGrid.dataView.setItems(this.stage3Rows);
                      }
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                    this.isFinishDisabled();
                    this.selectedOrphanRecords = [];
                    this.selectedOrphanIndexes = [];
                  }
              });
          }
        })
      }
      this.updateSuggestedClusterFeedback(false);
    } else {
      this.checkForClusterSelectedTypes();
      if(this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && (this.existingClustersSelected || this.ownClusterSelected)) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        let existingInstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  searchedClusterinstanceRows.push(row);
                  this.addedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.addedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                searchedClusterinstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
            }
          });
          this.customAddedCluserInstance.forEach((instance, inde) => {
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                existingInstanceRows.push(row);
                this.customAddedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              existingInstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
            }
          });
          this.selectedOrphanRecords.forEach(rec => {
            selectedRecIds.push(rec.zs_recid);
          });
          let payload = {
              'zs_recid':selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };
            let selectedRecs = this.selectedOrphanRecords.concat(existingInstanceRows).concat(searchedClusterinstanceRows);
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload })
            .subscribe(resp => {
              if (resp) {
                this.taskSaved();
                this.selectedOrphanIndexes.reverse().forEach(ele => {
                  this.stage3Rows.splice(ele, 1);
                    this.isFinishDisabled();
                    });            
                  if (this.orphanGrid) {
                    this.orphanGrid.gridService.setSelectedRows([]);
                    this.orphanGrid.dataView.refresh();
                    this.orphanGrid.dataView.setItems(this.stage3Rows);
                    }
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
                  if(existingCluster) {
                    let combinedRows = selectedRecs.concat(searchedClusterkeys);
                    this.addedClusters.push({...searchedClusterinstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
                  } else {
                    this.customAddedClusters.push({...existingInstanceRows[0], rows: selectedRecs, clusterName, openRecord: false});
                  }
                }
            });
            return;
      }else if(this.existingClustersSelected && this.ownClusterSelected) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  searchedClusterinstanceRows.push(row);
                  this.addedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.addedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                searchedClusterinstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
            }
          });
            this.customAddedCluserInstance.forEach((instance, inde) => {
              let existingInstanceRows = [];
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  existingInstanceRows.push(row);
                  this.customAddedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                existingInstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
                if(existingCluster) {
                  let newPayload = {'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0','feedback_type': "not_matched_records",'zs_cluster_id_proposed': clusterId};
                  this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                  .subscribe(resp => {
                    if (resp) {
                      this.taskSaved();
                      this.selectedOrphanIndexes.forEach(ele => {
                      this.isFinishDisabled();
                      });
                      this.proposedClusters = {};
                      this.stage3EnableFinishBtn = true;
                      this.selectedOrphanIndexes = [];
                      this.selectedOrphanRecords = [];
                      this.searchedClusterkeys = [];
                      this.searchedClusters = '';
                      this.isSearching = false;
                      this.searchtext = '';
                    }
                  });                  
                } else {
                  let newPayload = {
                    'zs_recid': selectedRecIds.toString(),
                    'zs_feedbackid': '0',
                    'feedback_type': "not_matched_records",
                    'zs_cluster_id_proposed': clusterId
                  };
                this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                .subscribe(resp => {
                  if (resp) {
                    this.taskSaved();
                    this.selectedOrphanIndexes.forEach(ele => {
                    this.isFinishDisabled();
                    });
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.selectedOrphanIndexes = [];
                    this.selectedOrphanRecords = [];
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                  }
                });
              }
              if(existingCluster) {
                let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
                let allrows = existingInstanceRows.concat(combinedRows);
                this.addedClusters.push({...searchedClusterinstanceRows[0], rows: allrows, clusterName , openRecord: false});
              } else {
                let combinedRows = searchedClusterinstanceRows.concat(existingInstanceRows);
                this.customAddedClusters.push({...existingInstanceRows[0], rows: combinedRows, clusterName, openRecord: false});
              }
            }});
      } else {
        this.addedCluserInstance.forEach((instance, inde) => {
        let searchedClusterinstanceRows = [];
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                searchedClusterinstanceRows.push(row);
                this.addedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.addedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              let selectedRecIds = [];
              searchedClusterinstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
          let newPayload = {'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0','feedback_type': "not_matched_records",'zs_cluster_id_proposed': clusterId};
          this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
          .subscribe(resp => {
            if (resp) {
              this.taskSaved();
              this.selectedOrphanIndexes.forEach(ele => {
              this.isFinishDisabled();
              });
              this.proposedClusters = {};
              this.stage3EnableFinishBtn = true;
              this.selectedOrphanIndexes = [];
              this.selectedOrphanRecords = [];
              this.searchedClusterkeys = [];
              this.searchedClusters = '';
              this.isSearching = false;
              this.searchtext = '';
            }
          });
          if(existingCluster) {
            let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
            this.addedClusters.push({...searchedClusterinstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
          } else {
            this.customAddedClusters.push({...searchedClusterinstanceRows[0], rows: searchedClusterinstanceRows, clusterName, openRecord: false});
            }
          }
        });
          this.customAddedCluserInstance.forEach((instance, inde) => {
            let existingInstanceRows = [];
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                existingInstanceRows.push(row);
                this.customAddedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              let selectedRecIds = [];
              existingInstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
    
              let newPayload = {
                'zs_recid': selectedRecIds.toString(),
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              };
    
              this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
              .subscribe(resp => {
                if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.forEach(ele => {
                  this.isFinishDisabled();
                  });
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.selectedOrphanIndexes = [];
                  this.selectedOrphanRecords = [];
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                }
              });
              if(existingCluster) {
                let combinedRows = existingInstanceRows.concat(searchedClusterkeys);
                this.addedClusters.push({...existingInstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
              } else {
                this.customAddedClusters.push({...existingInstanceRows[0], rows: existingInstanceRows, clusterName, openRecord: false});
              }
            }
          });
      }
      }
  }

  onSearchedClusterMinus(clusterId) {
    this.proposedClusters = undefined;
  }

  toggleSearchedAccordian(event, clusterId) {
    const target = event.target.target;
    if (this.searchedClusters['openCluster'] !== clusterId) {
      this.searchedClusters['openCluster'] = clusterId;
    } else {
      this.searchedClusters['openCluster'] = undefined;
    }
  }

  toggleAddedAccordian(event, clusterId, ind) {
    if (this.addedClusters['openCluster'] !== clusterId) {
      this.addedClusters['openCluster'] = clusterId;
    } else {
      this.addedClusters['openCluster'] = undefined;
    }
    this.addedClusters[ind]['openRecord'] = true;
  }

  customAddedClustersToggle(eve, clusId, ind) {
   const target = eve.target.target;
   if (this.customAddedClusters['openCluster'] !== clusId) {
     this.customAddedClusters['openCluster'] = clusId;
    } else {
      this.customAddedClusters['openCluster'] = undefined;
    }
      this.customAddedClusters[ind]['openRecord'] = true;
  }

  autoUpdateSuggestedClusterFeedback() {
    const payload = {};
    payload['zs_feedbackid'] = AppGlobals.SUGGESTED_CLUSTER_FEEDBACK_STATUS;
    let clusterIds = '';
    let feedbackIds = '';
    const keys = Object.keys(this.suggestedFeedback);

    for (let i = 0; i < keys.length; i++) {
      if (i + 1 < keys.length) {
        clusterIds = clusterIds + keys[i] + ',';
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]] + ',';
      } else {
        clusterIds = clusterIds + keys[i];
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]];
      }
    }

    payload['zs_feedback_closest_cluster_ids'] = clusterIds;
    payload['zs_feedback_closest_cluster_ids_feedbackids'] = feedbackIds;
    this.ProjectSvc.updateFeedback({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
      }).subscribe(resp => {
        if(resp) {
          this.taskSaved();
        }
    });
  }

  updateSuggestedClusterFeedback(refresh, stage1=false) {
    const payload = {};
    payload['zs_feedbackid'] = AppGlobals.SUGGESTED_CLUSTER_FEEDBACK_STATUS;
    let clusterIds = '';
    let feedbackIds = '';
    const keys = Object.keys(this.suggestedFeedback);

    for (let i = 0; i < keys.length; i++) {
      if (i + 1 < keys.length) {
        clusterIds = clusterIds + keys[i] + ',';
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]] + ',';
      } else {
        clusterIds = clusterIds + keys[i];
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]];
      }
    }

    payload['zs_feedback_closest_cluster_ids'] = clusterIds;
    payload['zs_feedback_closest_cluster_ids_feedbackids'] = feedbackIds;
    if(stage1) {
      this.updateFeedback(this.stage1FeedBack, true);
    } else {
      if(this.currentStage<=1) {
      this.ProjectSvc.updateFeedback({
          userId: this.loggedInUserDetails.user_id,
          projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
          // comment and use below line of code to 
          // projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.newPayload
        }).subscribe(resp => {
          this.taskSaved();
          this.currentStage = 1;
          this.suggestedFeedback = {};
          this.getSupervisedFeedback();
          if (refresh) {
            this.updateFeedback(this.stage1FeedBack, true);
          }
      });
      }
    }
  }

  captureSuggestedFeedback(cId, feedback) {
    this.suggestedFeedback[cId] = feedback;
    this.autoUpdateSuggestedClusterFeedback();
  }

  refresh() {
    this.trainModelDataSource = [];
    if (this.grid) {
      this.grid.dataView.setItems(this.trainModelDataSource);
    }
    this.summaryHasChanged = true;
    this.getTrainModelList(1, this.limit);
    $('.modal-backdrop').remove();
  }

  isClusterChecked(currentFeedback, feedbacktoBe) {
    if (currentFeedback === feedbacktoBe) {
      return true;
    } else {
      return false;
    }
  }

  getSuggestedClusterStyle() {
    if ($('#collapse1').hasClass('show')) {
      return 'suggested-cluster expanded';
    } else {
      return 'suggested-cluster collapsed';
    }
  }

  taskSaved() {
    $('#task-saved').removeClass('d-none');
    setTimeout(function () {
      $('#task-saved').addClass('d-none');
    }, 5000);
  }

  /** Stage = -1: Cluster has one record and 0 Suggested cluster: DO NOT SHOW TRAIN MODAL
   *  Stage = 0: When we have suggested cluster: DISPLAY TRAIN MODAL STEP 1
   *  Stage = 1: When selected cluster has > 1 records and 0 suggested clusters THEN skip step 1 and handle previous button.
   * */
  handleModal() {
    this.hasPrevious = true;
    if ((this.suggestedClusterkeys.length === 0 || this.suggestedClusterkeys === undefined) && this.stage1SelectedClusterTotal <= 1) {
      // Do not display modal
      return -1;
    } else if ((this.suggestedClusterkeys.length === 0 || this.suggestedClusterkeys === undefined) && this.stage1SelectedClusterTotal > 1) {
      // Skip step 1
      return 1;
    } else {
      return 0;
    }
  }

  onNextLeft(): void {
    this.pageLeft++;
    this.getTrainModelList(this.pageLeft, this.limit);
  }

  onLeftGridScroll(parentClass) {
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNextLeft();
    }
  }

  onLoadModal() {
    if (this.handleModal() === -1) {
      $('#tm-modal').removeClass('d-none');  //we can remove this and close modal --> /** Stage = -1: Cluster has one record and 0 Suggested cluster: DO NOT SHOW TRAIN MODAL
      // this.closeModal();
      return;
    } else if (this.handleModal() === 1) {
      $('#tm-modal').removeClass('d-none');
      this.currentStage = 1;
      this.hasPrevious = false;
      this.getSupervisedFeedback();
      return;
    } else if (this.handleModal() === 0) {
      $('#tm-modal').removeClass('d-none');
      // this.currentStage = 1;
    }
  }

  checkUserPermission() {
    this.isLoading = true;
    this.ProjectSvc.getProject(this.ProjectSvc.getObjectFromSession('userInfo').user_id,
      this.projectDetail.project_id).subscribe(responseList => {
        let allUsers = responseList.users;
        this.isLoading = false;
        allUsers.forEach(user => {
          if (user.user_id === this.loggedInUserDetails.user_id) {
            const adminUser = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN);
            if (adminUser) {
              this.isProjectAdmin = adminUser ? true : false;
            }
          }
        });
        this.gridOptions = {
          enableGridMenu: false,
          selectable: true,
          enableFiltering: true,
          CheckboxSelector: true,
          checkboxSelection:true,
          suppressRowClickSelection:true,
          enableCellNavigation: true,
          enableRowSelection: true,
          rowHeight: 34
        };
        this.getTrainModelList(this.page, this.limit);
      }, err => {
        this.isLoading = false;
      });
  }


  isHavingAccess() {
    if (this.selectedLeftGridRowsSize > 0 && this.isProjectAdmin) {
      return true;
    } else {
      return false;
    }
  }

  getProjectUsers() {
    this.ProjectSvc.getprojectUsers(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe(userList => {
      this.taskAssignmentsRowData = userList;
      this.taskAssignmentsRowData.forEach(user => {
        user.id = Math.random();
        user.isAdmin = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN) ? true : false;
        user.isReviewer = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER) ? true : false;;
        user.isApprover = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_APPROVER) ? true : false;;
        user.isReviewerChecked = false;
        user.isApproverChecked = false;
        user.isCheckedAdmin = false;
        user.isChecked = false;
      });
      this.isTaskAssignmentsLoading = false;
    }, error => {
      this.taskAssignmentsRowData = [];
      this.isTaskAssignmentsLoading = false;
    });
  }
  addUserRole() {
    const selectedUserRoles = [];
    const taskAssignmentUser = [];
  
    if (this.taskAssignmentsGrid && this.taskAssignmentsGrid.api) {
      this.taskAssignmentsGrid.api.forEachNode(node => {

        taskAssignmentUser.push(node.data);
      });
      const filteredTaskAssignmentUser = taskAssignmentUser.filter(item =>
        item.isApproverChecked === true || item.isReviewerChecked === true
      );
 
      const leftSideGridDataSet = this.leftGridData;
  
      if (taskAssignmentUser.length && leftSideGridDataSet.length) {
        leftSideGridDataSet.forEach(row => {
          const matchingUser = taskAssignmentUser.find(user => user.role_id === row.role_id);
  
          if (matchingUser) {
            selectedUserRoles.push({
              user_id: matchingUser.user_id,
              role_id: matchingUser.role_id,
              cluster_id: row.zs_cluster_id
            });
          }
        });
  
        this.ProjectSvc.saveTasksToUsers(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, selectedUserRoles).subscribe(resp => {
          this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
          this.viewTaskAssignments =false;
          if (this.agGrid) {
            const gridApi = this.agGrid.api
            this.selectedLeftGridRowsSize=0
            gridApi.setRowData([]);
            this.isLoading = true;
            this.getTrainModelList(1, this.limit);
            this.selectedLeftGridRowsSize=0
        }
        
        }, err => {
          this.messageSvc.sendMessage({ message: AppGlobals.TASK_ASSIGNMENT_FAILD_MSG, type: 'ERROR', hideboard: true });
        });
      }
    }
  }

  onStage3GridCreation(grid) {
    const parent = this;
    parent.orphanGrid = grid;
    parent.selectedOrphanIndexes = [];
    parent.orphanGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      const row = parent.orphanGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.selectedOrphanRecordsCount = args.rows.length > 0 ? true : false
      parent.selectedOrphanRecords = [];
      args.rows.forEach(ind => {
      parent.selectedOrphanIndexes = args.rows;
      parent.selectedOrphanRecords.push(parent.stage3Rows[ind]);
      });
    });
}

onGridCreation2(grid, cluserIndex) {
  let parent = this;
  parent.selectedOrphanRecsToAssign = [];
  parent.addedCluserInstance[cluserIndex] = grid;
  parent.addedCluserInstance[cluserIndex].slickGrid.onSelectedRowsChanged.subscribe((eve, argus) => {
  const row = parent.addedCluserInstance[cluserIndex].gridService.getDataItemByRowIndex(argus.rows);
  parent.addedCluserInstance[cluserIndex]['selectedRowIdexes'] = argus.rows;
  parent.addedClusters[cluserIndex]['selectedOrphanRecordsCount'] = argus.rows.length > 0 ? true : false;
  parent.addedClusters[cluserIndex]['selectedOrphanRecordsLength'] = argus.rows.length;
  });
  parent.addedClusterGrid[cluserIndex] = grid;
  grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    setTimeout(() => {
    const row = grid.gridService.getDataItemByRowIndex(args.rows);
    const leftSideGridDataSet = grid.slickGrid.getSelectedRows();
    parent.selectedOrphanRecsToAssign.push(row);
    let selectedRecsData = [{clusterIndex: cluserIndex, 
      orphanRecInd: leftSideGridDataSet, orphanRec: parent.addedClusters[cluserIndex]}];
      parent.selectedRecsData.push(selectedRecsData);
}, 1000);
  });
}

  onCustomGridCreation2(grid, cluserIndex, record) {
    let parent = this;
    parent.selectedOrphanRecsToAssign = [];
    parent.customAddedCluserInstance[cluserIndex] = grid;
    parent.customAddedCluserInstance[cluserIndex].slickGrid.onSelectedRowsChanged.subscribe((eve, argus) => {
      const row = parent.customAddedCluserInstance[cluserIndex].gridService.getDataItemByRowIndex(argus.rows);
      parent.customAddedCluserInstance[cluserIndex]['selectedRowIdexes'] = argus.rows;
      parent.customAddedClusters[cluserIndex]['selectedOrphanRecordsCount'] = argus.rows.length > 0 ? true : false;
      parent.customAddedClusters[cluserIndex]['selectedOrphanRecordsLength'] = argus.rows.length;
    });
    parent.addedClusterGrid[cluserIndex] = grid;
    grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      setTimeout(() => {
        const row = grid.gridService.getDataItemByRowIndex(args.rows);
        const leftSideGridDataSet = grid.slickGrid.getSelectedRows();
        parent.selectedOrphanRecsToAssign.push(row);
        let selectedRecsData = [{
          clusterIndex: cluserIndex,
          orphanRecInd: leftSideGridDataSet, orphanRec: parent.customAddedClusters[cluserIndex]
        }];
        parent.selectedRecsData.push(selectedRecsData);
      }, 1000);
    });
    grid.gridService.renderGrid();
  }

  showConfirmBoxOnCancel() {
    if(this.currentStage == 2) {
      if(this.addedClusters && this.addedClusters.length>0 && this.customAddedClusters && this.customAddedClusters.length>0) {
        this.showConfirmBox = true;
      } else {
        this.showConfirmBox = false;
      }
      this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
      this.msgText = "Continue?";
    } else if(this.currentStage < 2) {
    $('#tm-modal').modal('hide');
    $('.modal').modal('hide');
    this.suggestedFeedback = {};
    this.customAddedClusters = [];
    this.addedClusters = [];
    this.stage1FeedBack = [];
    }
  }

  checkForClusterSelectedTypes() {
    this.existingClustersSelected = this.addedCluserInstance.some((instance) => instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length)
    this.ownClusterSelected = this.customAddedCluserInstance.some(instance => instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length)
  }

  toggleSaveChangesModal() {
    $('#cancelBoxModal').modal('show');
    $('#cancelBoxModal').addClass('show');
  }

  onConfirmNavigate(event) {
    if(!this.onPreviousClicked) {
      $('#tm-modal').modal('hide');
      $('.modal').modal('hide');
      this.showConfirmBox = false;
      this.addedClusters = [];
      this.customAddedClusters = [];
    } else {
      this.isSelectedClusterExpanded = false;
      this.currentStage = this.currentStage - 1;
      this.getSupervisedFeedback();
      this.showConfirmBox = false;
    }
  }
  clearText(event) {
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  receiveTotalRecords($event) { 
    this.subTotal = this.zettaUtilsSvc.getSubTotal(this.total, $event.subTotal); 
  }

  checkForTaskStatus() {
    const inReviewTasks = this.selectedRows.filter(row => row.status === 'In Review');
    const pendingForApprovelTasks = this.selectedRows.filter(row => row.status === 'Pending For Approval');
    if(pendingForApprovelTasks && pendingForApprovelTasks.length>0 && !inReviewTasks.length) {
      this.showApprovalCheckBox = true;
      this.showReviewerCheckBox = false;
    } else if(inReviewTasks && inReviewTasks.length>0 && !pendingForApprovelTasks.length) {
      this.showReviewerCheckBox = true;
      this.showApprovalCheckBox = false;
    } else {
      this.showApprovalCheckBox = false;
      this.showReviewerCheckBox = false;
    }
  }
}
