import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../project.service';

@Component({
  selector: 'zetta-pd-activity-history',
  templateUrl: './pd-activity-history.component.html',
  styleUrls: ['./pd-activity-history.component.scss']
})
export class PdActivityHistoryComponent implements OnInit {
  private _userinfo;
  public activities;
  project_id;
  isResolveProject= true;
  project_type: String = '';
  @Input() limitMaxHeight?: Boolean = false;

  constructor(private _projectService: ProjectService,private route:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this._userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const routeParams = this.route.snapshot.params;
    this.project_id= routeParams.id;
    const url = this.router.url.split('/')[1];
    if(url==='zs-cl'){
      this.isResolveProject =false;
    }
    this.getActivityHistory(this.project_id);
    this.project_type = sessionStorage.getItem('project_type');   
  }

  getActivityHistory(project_id) {
    if(this.isResolveProject){
      this._projectService.getProjectActivityHistory(this._userinfo['user_id'],project_id).subscribe( res => {      
        this.activities = res.splice(0, 18);
      });      
    }else{
      const tempActivity = [
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"},
        {activity_name: "updated Project", created_ts: "2021-11-23T10:24:14.426Z", user_name: "default_tenant_su"}
      ];
      this.activities =tempActivity
    }
   
	}

}
