import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../common/services/base-service';
import { Component, OnInit, NgZone, Compiler, HostListener, OnDestroy } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../common/services/authentication.service';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AppGlobals } from '../../common/shared/app.globals';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { ContentService } from '../../zettasense/content/content.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'zetta-zs-in-header',
  templateUrl: './zs-in-header.component.html',
  styleUrls: ['./zs-in-header.component.scss']
})
export class ZsInHeaderComponent implements OnDestroy, OnInit {
  
	public userinfo;
	public profilePic;
	private _apiUrl: string = environment.config.API_URL;
	endTime = 1;
	minutesDisplay = 0;
	secondsDisplay = 0;
	unsubscribe$: Subject<void> = new Subject();
	timerSubscription: Subscription;
	routeDelay;
	defaultApp;
	defaultAppId;
	selectedApp = 'z-ing';
	value;
	notificationcount = 0;
	noticationlist = [];
	filterednotifications;
	badgecount: boolean = true;
	isview: boolean = false;
	recallnotf: any;
	page = 1;
	limit = 100;
	shortname:string;
	customStyleAvtar = {
		fontSize:'12px'
	  };
	pipelineType = 'Ingestion';     //'Ingestion'  or   'Transformation'

	constructor(public app: AppComponent,
		private _router: Router,
		private zone: NgZone,
		private _compiler: Compiler,
		private _baseService: BaseService,
		private http: HttpClient,
		private _authSvc: AuthenticationService,
		public zettaUtils: ZettaUtils,
		public contentSvc: ContentService,
		private authService:MsalService) { }

	ngOnInit(): void {
		this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
		this.zettaUtils.getEntitlements('z-ing');
		this.defaultAppId = this.userinfo.preference ? this.userinfo.preference.default_app_id : -1;
		this.defaultAppId === -1 ? this.defaultApp = '' : this.userinfo.apps.find( app => parseInt(app.app_id) === parseInt(this.userinfo.preference.default_app_id))
																	? this.defaultApp = this.userinfo.apps.find( app => parseInt(app.app_id) === parseInt(this.userinfo.preference.default_app_id)).short_name
																	: this.defaultApp = '';

		this.value = this.defaultApp === this.selectedApp ? true : false;
		if(sessionStorage.getItem("profilePicSrc")){
			if(sessionStorage.getItem("profilePicSrc")){
				this.profilePic=sessionStorage.getItem("profilePicSrc");
			}
		}else{
			this.getUserPic();
		}
		
		this.resetTimer();
		this._baseService.userActionOccured.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
			}
			this.resetTimer();
		});
		this.getNotificationListData();
	}

	@HostListener('document:keyup', ['$event'])
	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:click', ['$event'])
	@HostListener('document:wheel', ['$event'])

	resetActiveTimer() {
		this._baseService.notifyUserAction();
	}

	public getUserPic() {		
		sessionStorage.setItem('isUserProfile', "1");
		this._baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {
			let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.profilePic = reader.result;
			}, false);
			if (image && image.size && image.type == "image/gif") {
				this.profilePic = reader.readAsDataURL(image);
			} else {
				this.profilePic = null;
			}
		}, err => {
			this.profilePic = err.url;			
		});
	}

	logout() {
		this.timerSubscription.unsubscribe();
		this._compiler.clearCache();
		if(!!this.authService.getAccount() && sessionStorage.getItem('loginProvider') === 'azure_sos'){
			this.authService.logout();
		} else {
			this._authSvc.signOut();
		}
		//this._authSvc.signOut();
		// this.routeDelay = setTimeout(() => {
		// 	this._router.navigate([AppGlobals.ZETTASENSE_LOGIN]);
		// }, 100);

		const route = sessionStorage.getItem('is_zs_login_page') === 'true' ? '/zl/login' : '/zl/login';
		sessionStorage.clear();	
		localStorage.clear();	
		this._router.navigate([route]);
	}

	ngOnDestroy() {
		this.timerSubscription.unsubscribe();
		clearInterval(this.recallnotf);
		// clearTimeout(this.routeDelay);
	}

	resetTimer(endTime: number = this.endTime) {
		const interval = 1000;
		const duration = endTime * AppGlobals.USER_SESSION_TIMEOUT;
		this.timerSubscription = timer(0, interval).pipe(take(duration)).subscribe(value =>
			this.render((duration - +value) * interval),
			err => { },
			() => {
				//TODO fix logout timer issue
				// this.logout();
			}
		)
	}

	private render(count) {
		this.secondsDisplay = this.getSeconds(count);
		this.minutesDisplay = this.getMinutes(count);
	}

	private getSeconds(ticks: number) {
		const seconds = ((ticks % 60000) / 1000).toFixed(0);
		return this.pad(seconds);
	}

	private getMinutes(ticks: number) {
		const minutes = Math.floor(ticks / 60000);
		return this.pad(minutes);
	}

	private pad(digit: any) {
		return digit <= 9 ? '0' + digit : digit;
	}

	reDirectApp(short_name, IsZmLogin, IsZsLogin) {
		sessionStorage.setItem('selectedApp', short_name);
		sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, IsZmLogin);
		sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, IsZsLogin);
		if(short_name === 'z-ing') {
			this._router.navigate([short_name + '/ingestion']);
		} else {
			this._router.navigate([short_name + '/home']);
		}
	}

	changedDefaultApp() {
		let appId = -1;
		if(this.selectedApp === 'zs' && this.value) {
			appId = 1;
		} 
		if(this.selectedApp === 'zs-cl' && this.value) {
			appId = 3;
		}
		if(this.selectedApp === 'z-ing' && this.value) {
			appId = 4;
		}
		
		const postData = {
			user_id: this.userinfo.user_id,
			payload: { preference: { default_app_id: appId } }
		};
		this._baseService.updateUserPreferences(postData, this.http).subscribe((result) => {
			this.userinfo.preference.default_app_id = appId;
			sessionStorage.setItem('userInfo', JSON.stringify(this.userinfo));
		}, err => { });
	}

	//Retreiving notficationList and badge number
	getNotificationListData() {
		this.contentSvc.getNotificationCount(this.userinfo.user_id).subscribe((data) => {
			this.noticationlist = data;
			if (this.noticationlist) {
				this.notificationcount = data["badge"];
				let concatedRows = this.noticationlist["notifications"];
				let obj = concatedRows.map((item) => ({ 'value': item.short_name, 'text': item.name }));
				this.filterednotifications = obj.slice(0, 10);
				if (this.noticationlist['notifications'].length > 10) {
					this.isview = true;
				}
			}
		},
			err => {
				this.notificationcount = 0;
			});
		if (this.notificationcount > 0) {
			this.badgecount = true;
		}
	}

	// To find notfications read or not
	notifcationviewed() {		
		this.contentSvc.notificationview(this.userinfo.user_id).subscribe((data) => {
			this.badgecount = false;
		});
	}

	pipelineTypeChange() {
		this.pipelineType = '';
	}

	//AfterViewInit
	ngAfterViewInit() {
		this.recallnotf = setInterval(() => {
			this.getNotificationListData();
		}, 30000)
	}

	//Grid Filtering based on the value selected
	Filterddata(event) {
		this.shortname=event.value;
		sessionStorage.setItem('shortname', this.shortname)
		this._router.navigate(['zs/notifications']);
	}

	callPipeline(typePipe) {
		if(this.pipelineType !== typePipe) {
			this.pipelineType = typePipe;
			if (typePipe === 'Transformation') {
				this._router.navigate(['z-ing/transformation']);
			} else if (typePipe === 'Ingestion') {
				this._router.navigate(['z-ing/ingestion']);
			}
		}
	}

}
