import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-created-by-cell-renderer',
  template: `{{ createdBy }}`,
})
export class CreatedByCellRendererComponent implements ICellRendererAngularComp {
  createdBy: string;

  agInit(params: ICellRendererParams): void {
    this.createdBy = params.data.created_by;
  }

  refresh(params: ICellRendererParams): boolean {
    this.createdBy = params.data.created_by;
    return true;
  }
}
