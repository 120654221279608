import { MapDataSetsService } from '../../../zettasense/content/project/map-data-sets/map-data-sets-service';
import { Project, Dataset } from '../../../zettasense/content/project/project.model';
import { AngularGridInstance, Grouping, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, AfterViewChecked, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import * as _ from 'lodash';
import { toggleFormatter } from '../../../common/shared/formatters/toggleFormatter';
import { IDeactivateComponent } from '../../../common/shared/auth-guard.service';
import { ConfirmDialogService } from '../../../common/components/confirm-dialog/confirm-dialog.service';
declare var $: any;
import { unMappedAttributeFormattter } from '../../../common/shared/formatters/unMappedAttributeFormatter';
import { attributeMappingFormatter } from '../../../common/shared/formatters/attributeMappingFormatter';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { ZsClContentService } from '../zs-cl-content.service';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { dropFormatter } from '../../../common/shared/formatters/dropFormatter';
import { AppGlobals } from '../../../common/shared/app.globals';

const RIGHT_EMPTY_GRID_MSG = 'Entity attribute list is empty for mapping.';
const LEFT_EMPTY_GRID_MSG = 'No attribute to map.';
const MODAL_EMPTY_GRID_MSG = 'No Data.';

const dragFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return dataContext.groupedRow === true ? `<div class="groupedDiv"></div>`
        : `<div value =${row} class="row dragMe flex-nowrap mr-2 pr-2">
                                                    <div class="col-md-1 grab"><img src="../../../../assets/images/common/drag-drop-tbrow.png"></div>
                                                    <div class="pointer text-truncate" title='${value}'>${value}</div>
                                              </div>` ;
}

@Component({
    selector: 'zetta-tm-map-data-columns',
    templateUrl: './tm-map-data-columns.component.html',
    styleUrls: ['./tm-map-data-columns.component.scss'],
})
export class TmMapDataColumnsComponent implements OnInit, OnDestroy, AfterViewChecked, IDeactivateComponent {

    isSampelDsLoading = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    rightGridMappedDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    fieldsMapped = 0;
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    entityAttribute = new Map<string, any>();
    defaultEntityAttributes = [];
    mappedDataSetAttributes = [];
    defaultUnMappedEntityAttributes = [];
    entityResp;
    entityRespAfter;
    updatedDataSetAttributes: Dataset[] = [];
    leftGridOptions: any;
    rightGridOptions: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    leftGridMappingHeader: any;
    hasActiveJob = true;
    loggedInUserDetails: [];
    rightGridDataView: any;
    dataset_id;
    catalogs_ids;
    objects_ids;
    catalogs_ids_arr: any = [];
    objects_ids_arr: any = [];
    entitiesResp: any = [];
    selectedentitiesResp: any = [];
    count = 0;
    dataviewObj: any;
    gridObj: any;
    catalogs: any;
    objectsList: any = [];
    resp: any = [];
    job_id: any;
    canGoNextScreen = false;
    flash: any = {};
    xxxxx;
    catalog_id;
    object_id;
    concept_id;
    dataset_classify_ids: any = [];
    dataset_ids: any = [];
    no_matches: string = '';
    datasets: string = '';
    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;
    page = 1;
    isLeftGridDataReady = false;
    isRightGridDataReady = false;
    oneRow: any = [];
    mappedOneAttribute: any = [];
    showmodelpopup = false;
    datasetId: any;
    leftGridMappedAttribute: any = [];
    leftGridTotalMappedAttribute = 0;
    defaulMappedtEntityAttributes: any = [];
    dq_rule_level: string;
    @Input() isBusinessRule;
    backTo: string;
    isMappingChanged = false;
    hasMappingChnaged = false;
    rule_id: string;
    obRule_ids = [];
    attributIds: string;
    showSpinnerWhileUpdatingMapping: boolean = false;
    originalMappedColumnConceptMapping: any = [];
    constructor(public zettaUtils: ZettaUtils,
        public mapService: MapDataSetsService,
        private datasetSvc: DatasetService,
        private catalogSvc: ZsClContentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private messageSvc: MessageService,
        private projectService: ProjectService,
        private sanitizer: DomSanitizer,
        private baseService: BaseService,
        private confirmDialogService: ConfirmDialogService,) { }

    ngOnInit() {
        this.flash.showboard = false;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
            this.dataset_id = params['id'];
        });

        this.progressState.states = this.zettaUtils.getStateList('classify-train-model');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'classifyTrain';
        this.progressState.isEdit = false;

        this.activatedRoute.queryParamMap.subscribe(params => {
            this.backTo = params.get('BackTo');
            this.obRule_ids = params.getAll('selectoobRule');
            this.datasets = params.get('datasets');
            if (params.has('dqRuleLevel')) {
                this.dq_rule_level = params['params']['dqRuleLevel'];
            }
            if (params.get('isMappingChanged') == 'true') {
                this.isMappingChanged = true;
            }
            if (params.has('rule_id')) {
                this.rule_id = params.get('rule_id');
            }
            if (this.rule_id == null || this.rule_id == undefined) {
                if (this.obRule_ids.length == 1) {
                    this.rule_id = this.obRule_ids.toString();
                }
            }
            if (params.has('attributeIds')) {
                this.attributIds = params.get('attributeIds');
              }
        });
        this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
        this.objects_ids = this.object_id;
        this.objects_ids_arr = this.objects_ids.split(',');
        this.concept_id = this.activatedRoute.snapshot.queryParamMap.get('concept_id');

        this.no_matches = this.activatedRoute.snapshot.queryParamMap.get('no_matches');
        if (this.no_matches !== null) {
            this.dataset_classify_ids = this.no_matches.split(',');
        }

        if (this.datasets !== null) {
            this.dataset_ids = this.datasets.split(',');
        }
        if (this.concept_id === undefined || this.concept_id === null) {
            this.activatedRoute.parent.params.subscribe(params => {
                this.catalog_id = params['catalog_id'];
            });
            if (this.isBusinessRule) {
                this.activatedRoute.parent.parent.parent.params.subscribe(params => {
                    this.catalog_id = params['catalog_id'];
                });
            }
            if (this.router.url.includes('create-bussiness-rule')) {
                this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
            }
        } else {
            this.activatedRoute.parent.parent.params.subscribe(params => {
                this.catalog_id = params['catalog_id'];
            });
            if (this.isBusinessRule) {
                this.activatedRoute.parent.parent.parent.params.subscribe(params => {
                    this.catalog_id = params['catalog_id'];
                });
            }
            if (this.router.url.includes('create-bussiness-rule')) {
                this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
            }
        };
        this.catalogs_ids = this.catalog_id;
        this.catalogs_ids_arr = this.catalogs_ids.split(',');

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: false,
            enableRowSelection: true,
            mapDataSets: true,
            noDataMsg: RIGHT_EMPTY_GRID_MSG,
        };

        this.modalGridOptions = {
            enableGridMenu: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true
        };

        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        sessionStorage.removeItem('mappedDatasets');
        if (this.concept_id) {
            this.bindTrainConceptGrid();
        } else {
            this.bindTrainObjectGrid();
        }

    }
    bindTrainConceptGrid() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogs_ids,
            object_id: this.object_id,
            concept_id: this.concept_id,
            is_multi_attribute: this.isBusinessRule, 
            attribute_ids: this.attributIds
        };

        this.catalogSvc.getTrainSemanticsLevel(data,false).subscribe(resp => {
            this.defaulMappedtEntityAttributes = resp;
            this.catalogSvc.getMapConcepts(this.objects_ids).subscribe(entitiesResp => {
                Object.entries(entitiesResp).forEach(([key, value]) => {
                    this.selectedentitiesResp = this.entitiesResp.concat(value);
                });
                if (this.concept_id ) {

                    if(this.isBusinessRule)
                    {
                        this.selectedentitiesResp.forEach(element => {
                            if (this.attributIds.includes(element.attribute_id)){
                                this.entitiesResp.push(element);
                            }
                        });
                    }
                    else
                    {
                         this.entitiesResp = this.selectedentitiesResp.filter(entities => entities.attribute_id === +this.concept_id);
                    }
                }
                this.prepareEntityMapping(this.entitiesResp);
                this.catalogs_ids_arr.forEach((catalogId, i) => {
                    this.catalogSvc.getSemanticsObjects(+catalogId).subscribe(respObjects => {
                        respObjects.forEach(e => {
                            e.catalog_id = +catalogId;
                        });
                        this.objectsList = this.objectsList.concat(respObjects);
                        if (i === 0) {
                            // this is old api call kept reference
                           // this.setDefaultRightGridDataSources();  
                           // this.initRightGridRows();
                            if (this.isBusinessRule) {
                                this.getMappedAttributesForEntity();
                            } else {
                                this.getMappedAttributesForConcept();
                            }
                           
                        }
                    }, error => {
                        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
                    });
                });
                // this is old api call kept reference
               // this.setDefaultLeftGridDataSources(); 
               // this.initColDef();
            }, error => {
                this.messageSvc.sendMessage({ message: error.error.message, type: 'INFO', hideboard: true });
            });
        });

    }
    bindTrainObjectGrid() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogs_ids,
            object_id: this.objects_ids,
            is_multi_attribute: this.isBusinessRule, 
            attribute_ids: this.attributIds
        };
        this.catalogSvc.getTrainSemanticsLevel(data,false).subscribe(resp => {
            this.defaulMappedtEntityAttributes = resp;
            this.catalogSvc.getMappingAttributes(this.objects_ids).subscribe(entitiesResp => {
                Object.entries(entitiesResp).forEach(([key, value]) => {
                    this.entitiesResp = this.entitiesResp.concat(value);
                });
                if (this.concept_id !== null) {
                    this.entitiesResp = this.entitiesResp.filter(entities => entities.attribute_id === +this.concept_id);
                }
                this.prepareEntityMapping(this.entitiesResp);
                // this is old api call kept reference
                // this.setDefaultRightGridDataSources();
                //this.initRightGridRows()                
                //this.setDefaultLeftGridDataSources();
                //this.initColDef();
                this.getMappedAttributesForEntity();
            }, error => {
                this.messageSvc.sendMessage({ message: error.error.error, type: 'INFO', hideboard: true });
            });
        });
    }

    getSemanticObject(catalogId) {
        this.catalogSvc.getSemanticsObjects(catalogId).subscribe(resp => {
            resp.forEach(element => {
                element.catalog_id = catalogId;
            });
            this.objectsList = this.objectsList.concat(resp);
        }, err => { });
    }

    getChainedAttribute(atts): string[] {
        const arr: string[] = [];
        atts.map((arrayItem) => {
            if (arrayItem.is_visible_input) {
                arr.push(arrayItem);
                if (arrayItem.chained) {
                    arrayItem.chained.map(element => arr.push(element)
                    );
                }
            }
        });
        return arr;
    }
    setDefaultRightGridDataSources() {
        this.rightGridDataSource = [];
        this.defaultEntityAttributes.forEach(attribute => {
            this.entityAttribute.set(attribute.physical_name, attribute);
            this.rightGridDataSource.push({
                'id': Math.random(),
                'logical_name': attribute.logical_name,
                'dataset_name': '',
                'target_attribute': 'dropDiv',
                'system_attribute': attribute.physical_name,
                'attribute_id': attribute.attribute_id,
                'sort_order': attribute.sort_order,
                'indent': attribute.indent,
                'parent': attribute.parent,
                'sequenceIndex': attribute.sequenceIndex,
                '_collapsed': attribute._collapsed,
                'data_type': attribute.data_type,
                'chained': attribute.chained,
                'parentattributeid': attribute.parentattributeid,
                'catalog_id': this.catalogs_ids,
                'entity_id': attribute.entity_id,
                'name': attribute.name,
            });
        });
    }

    setDefaultLeftGridDataSources() {
        this.leftGridDataSource = [];
        const dataset: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_ids: this.datasets,
           // pageno: this.page,
           // perpage: 30,
        }

        this.datasetSvc.getAllDatasets(dataset).subscribe(respDatasets => {
            this.datasetSvc.getMultipleDatasetColumns(dataset).subscribe(resp => {
                resp.forEach(element => {
                    const dataset = respDatasets.filter(dataset => dataset.dataset_id === element.dataset_id)[0];
                    element.id = Math.random();
                    element.groupedRow = false;
                    if (dataset && dataset.datasource_name) {
                        element.datasource_name = dataset.datasource_name;
                    }
                    const firstRow = this.mappedDataSetAttributes.find(row =>
                        row.dataset_id === element.dataset_id &&
                        row.dataset_column_id === element.dataset_column_id);
                    if (firstRow) {
                        element.mapping = firstRow.attribute_name;
                    }
                });
                this.defaultUnMappedEntityAttributes.push(...resp);
                this.initLeftGridRows();
            }, err => {
                this.isLeftGridDataReady = true;
                if (err.status == 404) {
                    this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });
                }
            });

        }, err => {
            this.isLeftGridDataReady = true;
            if (err.status == 404) {
                this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });
            }
        });
    }

    selectOptionDataSetModelList() {
        this.isSampelDsLoading = true;
        setTimeout(() => {
            if (this.entityResp) {
                this.isSampelDsLoading = false;
                this.entityRespAfter = this.entityResp;
            }
        }, 1000);
    }

    initRightGridRows() {
        const parent = this;
        this.rightGridDataSource.forEach(attribute => {
            attribute.id = Math.random();
            this.dataset_ids.forEach(element => {
                const firstRow = parent.defaulMappedtEntityAttributes.filter(row => row.attribute_id === attribute.attribute_id && parseInt(row.dataset_id) === parseInt(element));
                if (firstRow) {
                    firstRow.forEach(element => {
                        element['logical_name'] = attribute.logical_name;
                        element['dataset_name'] = element.dataset;
                        element['dataset_id'] = element.dataset_id;
                        element['sort_order'] = attribute.sort_order;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        element['target_attribute'] = element.dataset_column;
                        this.mappedDataSetAttributes.push(element);
                    });
                }
            });

        });

        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== AppGlobals.WHOLE_DATA_TYPE) {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });
        this.isRightGridDataReady = true;
    }

    initRightGridRowsHighPrediction(maapedAttribute) {
        const parent = this;
        this.rightGridDataSource.forEach(attribute => {
            attribute.id = Math.random();
            this.dataset_ids.forEach(element => {
                const mappedAttributeForDataset = maapedAttribute.filter(row => row.attribute_id === attribute.attribute_id && parseInt(row.dataset_id) === parseInt(element));
                if (mappedAttributeForDataset) {
                    mappedAttributeForDataset.forEach(mappedAttr => {
                        mappedAttr['logical_name'] = mappedAttr.attribute_name;
                        mappedAttr['id'] = Math.random();
                        mappedAttr['sort_order'] = ''
                        mappedAttr['isNewlyCreated'] = true;
                        mappedAttr['styleCss'] = 'remove-border';
                        mappedAttr['entity_id'] = mappedAttr.entity_id
                        mappedAttr['target_attribute'] = mappedAttr.column_name;
                        parent.mappedDataSetAttributes.push(mappedAttr);
                    });
                }
            });

        });

        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== AppGlobals.WHOLE_DATA_TYPE) {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });
        parent.rightGridDataSource.forEach((element) => {
            element.id = Math.random();
        });
        this.isRightGridDataReady = true;
        this.initColDef();
        this.setDefaultLeftGridDataSources();
    }

    removeMapping(row: any, isLeftgrid: boolean) {
        // showing the error message if user tries to remove mapping before left grid is rendered.
        if (!this.isLeftGridDataReady) {
            this.messageSvc.sendMessage({ message: 'Please wait for Dataset columns to load to remove mapping', type: 'ERROR', hideboard: true });
            return;
        }
        if (!isLeftgrid) {
            let payload = {
                dataset_id: row.dataset_classify_id ? row.dataset_classify_id : row.dataset_id,
                dataset_column_id: row.dataset_column_id,
                removing_attribute_id: parseInt(row.attribute_id),
                mapping_type: "not_sure",
                entity_id: row.entity_id,
                catalog_id:  this.catalog_id
            }

            /**
             * originalMappedColumnConceptMapping being used only to compare 
             * if currently deleted mapping is already been saved in DB or not.
             */
            let isExistingMappings = this.originalMappedColumnConceptMapping.filter((val) => {
                return val.attribute_id === payload.removing_attribute_id && val.dataset_id === payload.dataset_id &&
                val.dataset_column_id === payload.dataset_column_id;
            });

            /**
             * calling API to remove mappings from DB
             */
            if (isExistingMappings.length > 0) {
                let self = this;
                this.showSpinnerWhileUpdatingMapping = true;
                this.catalogSvc.removeMappedColumn(payload).subscribe(resp => {
                    self.showSpinnerWhileUpdatingMapping = false;
                    self.onMappingRemoved(row, self);
                    self.removeOriginalMapping(payload.removing_attribute_id,payload.dataset_id,payload.dataset_column_id);
                }, err => {
                    self.showSpinnerWhileUpdatingMapping = false;
                    self.messageSvc.sendMessage({ message: err.error && err.error.message ? err.error.message: 'Something went wrong', type: 'ERROR', hideboard: true });
                });
            } else {
                /**
                 * removing the mapping only from UI since no mappings prior in DB
                 */
                this.onMappingRemoved(row,this);
            }

            // commented the below logic and migrated the same existing logic to another method named 'onMappingRemoved()'
            //this.isMappingChanged = true;
            //let self = this;
           // Commenting this remove mapping api call as it is not required to call API when we remove we'll call API when user clicks on next or save button
           /* this.catalogSvc.removeMappedColumn(payload).subscribe(resp => {
                self.isMappingChanged = true;

                    // Remove Mapped Row from right Grid
                row['dataset_name'] = '';
                // updated Mapped Record data            
                this.mappedDataSetAttributes = this.mappedDataSetAttributes.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                    && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));    
                const leftGridRow = this.leftGrid.dataView.getItems().filter(leftRow => (leftRow.column === row.target_attribute
                    && leftRow.mapping === row.logical_name));

                const leftGridDataSourceRow = this.leftGridDataSource.find(leftRow => (leftRow.column === row.target_attribute
                    && leftRow.mapping === row.logical_name));

                // Remove Mapped Row from Left Grid
                if (leftGridRow.length > 0) {
                    if (leftGridRow[0].groupedRow) {
                        this.leftGrid.gridService.deleteDataGridItem(leftGridRow[0]);
                    } else {
                        leftGridRow[0].mapping = undefined;
                        this.leftGrid.gridService.updateDataGridItem(leftGridRow[0], false);
                    }
                }
                if (leftGridDataSourceRow) {
                    if (leftGridDataSourceRow.groupedRow) {
                        this.leftGridDataSource = this.leftGridDataSource.filter(leftRow => !(leftRow.column === row.target_attribute
                            && leftRow.mapping === row.logical_name));
                    } else {
                        leftGridDataSourceRow.mapping = undefined;
                    }
                }
                if (this.rightGridDataSource.length > 1) {
                    this.rightGridDataSource = this.rightGridDataSource.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                        && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));
                }
                this.saveDataSetMapping(row, 'DELETE', row.dataset_id);
                this.progressState = Object.assign({}, this.progressState);
                this.updateMappingLeftGridColumn(false);
                if (row['isNewlyCreated'] && row['isNewlyCreated'] === true) {
                    this.rightGrid.dataView.beginUpdate();
                    this.rightGrid.dataView.deleteItem(row['id']);
                    this.rightGrid.dataView.endUpdate();
                } else {
                    row['target_attribute'] = 'dropDiv';
                    row['dataset_column_id'] = null;
                    console.log('roe: ', row);
                    this.rightGrid.gridService.updateDataGridItem(row, false);
                }
            }, err => {
                this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
            });    */        
        } 
        //this.mappingResp();
    }

    removeOriginalMapping(attribute_id, dataset_id, dataset_column_id) {
        let columnMappingIndex = this.originalMappedColumnConceptMapping.findIndex((columnMapping) => {
            return columnMapping.attribute_id == attribute_id && columnMapping.dataset_id == dataset_id &&
            columnMapping.dataset_column_id == dataset_column_id;
        });
        this.originalMappedColumnConceptMapping.splice(columnMappingIndex, 1);
    }

    onMappingRemoved(row, self) {
        /** 
         * Migrated existing logic of deleting from grid to seperate method
         *  to handle API deletion and non-api deletion
         */
        self.isMappingChanged = true;

        // Remove Mapped Row from right Grid
        row['dataset_name'] = '';
        // updated Mapped Record data            
        self.mappedDataSetAttributes = self.mappedDataSetAttributes.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
            && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));    
        const leftGridRow = self.leftGrid.dataView.getItems().filter(leftRow => (leftRow.column === row.target_attribute
            && leftRow.mapping === row.logical_name));

        const leftGridDataSourceRow = self.leftGridDataSource.find(leftRow => (leftRow.column === row.target_attribute
            && leftRow.mapping === row.logical_name));

        // Remove Mapped Row from Left Grid
        if (leftGridRow.length > 0) {
            if (leftGridRow[0].groupedRow) {
                self.leftGrid.gridService.deleteDataGridItem(leftGridRow[0]);
            } else {
                leftGridRow[0].mapping = undefined;
                self.leftGrid.gridService.updateDataGridItem(leftGridRow[0], false);
            }
        }
        if (leftGridDataSourceRow) {
            if (leftGridDataSourceRow.groupedRow) {
                self.leftGridDataSource = self.leftGridDataSource.filter(leftRow => !(leftRow.column === row.target_attribute
                    && leftRow.mapping === row.logical_name));
            } else {
                leftGridDataSourceRow.mapping = undefined;
            }
        }
        if (self.rightGridDataSource.length > 1) {
            self.rightGridDataSource = self.rightGridDataSource.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));
        }
        self.saveDataSetMapping(row, 'DELETE', row.dataset_id);
        self.progressState = Object.assign({}, self.progressState);
        self.updateMappingLeftGridColumn(false);
        if (row['isNewlyCreated'] && row['isNewlyCreated'] === true) {
            self.rightGrid.dataView.beginUpdate();
            self.rightGrid.dataView.deleteItem(row['id']);
            self.rightGrid.dataView.endUpdate();
        } else {
            row['target_attribute'] = 'dropDiv';
            row['dataset_column_id'] = null;
            self.rightGrid.gridService.updateDataGridItem(row, false);
        }
        self.mappingResp();
    }

    groupRows() {
        this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);
        if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.leftGridDataSource);
        }
    }
    groupRows2() {
        this.rightGridDataSource = _.orderBy(this.rightGridDataSource, ['logical_name', 'column'], ['asc']);
        if (this.rightGrid) {
            this.rightGrid.dataView.setItems(this.rightGridDataSource);
        }
    }
    initLeftGridRows() {
        this.leftGridDataSource = [];
        this.leftGridDataSource.push(... this.defaultUnMappedEntityAttributes);
        this.defaultUnMappedEntityAttributes.forEach(attribute => {
            const firstRow = this.mappedDataSetAttributes.filter(row => row.dataset_column_id === attribute.dataset_column_id && row.dataset_id === attribute.dataset_id);
            if (attribute.hasOwnProperty('mapping') && firstRow) {
                firstRow.forEach((element, index) => {
                    if (element.logical_name && index > 0) {
                        this.leftGridMappedAttribute.push({
                            'id': Math.random(),
                            'dataset_name': attribute.dataset_name,
                            'column': attribute.column,
                            'mapping': element.logical_name,
                            'dataset_id': attribute.dataset_id,
                            'groupedRow': true
                        });
                    }
                });
            }
        });
        this.leftGridDataSource.push(... this.leftGridMappedAttribute);
        this.leftGridTotalMappedAttribute = this.leftGridDataSource.filter(data => data.mapping).length;
        this.leftGridRows = this.defaultUnMappedEntityAttributes.length;
        this.leftGridColumnsDef = this.getLeftGridColumns(this.leftGridTotalMappedAttribute);
        this.groupRows();
        this.isLeftGridDataReady = true;
    }

    getRightGridColumns() {
        return [
            {
                'displayname': 'Concept',
                'physicalname': 'logical_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'params': { 'rootObj': this },
                'formatter': toggleFormatter,
            }, {
                'displayname': 'Mapping',
                'physicalname': 'target_attribute',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': dropFormatter
            }
        ];
    }

    getLeftGridColumns(totalMapping?: number) {
        return [
            {
                'displayname': 'Data Set',
                'physicalname': 'dataset_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'cssClass': 'blueLink',
                'formatter': dragFormatter,
                'minWidth': 120,
            },
            {
                'displayname': 'Column Name',
                'physicalname': 'column',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': unMappedAttributeFormattter,
            },
            {
                'displayname': `Mapping(${totalMapping})`,
                'physicalname': 'mapping',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': attributeMappingFormatter,
            }
        ];
    }

    initColDef() {
        this.rightGridColumnsDef = this.getRightGridColumns();
    }

    rightGridCreated(grid) {
        this.buildParentAndChildRelation(this.rightGridDataSource);
        this.rightGrid = grid;
        this.rightGridDataView = this.rightGrid.dataView;
        this.rightGridDataView.beginUpdate();
        this.rightGridDataView.setFilter(this.myFilter);
        this.rightGridDataView.setFilterArgs(this.rightGridDataSource);
        this.rightGridDataView.endUpdate();

        const self = this;
        grid.slickGrid.onCellChange.subscribe(function (e, args) {
            self.rightGrid.dataView.updateItem(args.item.id, args.item);
        });

        grid.slickGrid.onClick.subscribe(function (e, args) {
            if ($(e.target).hasClass('expandCollapse')) {
                const rowitem = self.rightGrid.dataView.getItem(args.row);
                if (rowitem) {
                    if (!rowitem._collapsed) {
                        rowitem._collapsed = true;
                        self.hidingParentElement(rowitem);
                    } else {
                        rowitem._collapsed = false;
                        self.hidingParentElement(rowitem);
                    }
                    self.rightGrid.dataView.updateItem(rowitem.id, rowitem);
                }
                e.stopImmediatePropagation();
            }
        });

        // wire up model events to drive the grid
        self.rightGrid.dataView.onRowCountChanged.subscribe(function (e, args) {
            grid.slickGrid.updateRowCount();
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });

        grid.slickGrid.onScroll.subscribe(function (e, args) {
            self.showHideDragDrop();
            self.removeBorders();
        });

        self.rightGrid.dataView.onRowsChanged.subscribe(function (e, args) {
            grid.slickGrid.invalidateRows(args.rows);
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });

        this.dataviewObj = this.rightGrid.dataView;
        this.gridObj = this.rightGrid.slickGrid; // grid object

        // this.groupBySemanticObject();

    }

    groupBySemanticObject() {
        this.dataviewObj.setGrouping([
            {
                getter: 'name',
                formatter: (g) => {
                    return `<span class="text-bold" style="color:#505050">${g.value}</span>`;
                },
                aggregators: [],
                collapsed: false,
                lazyTotalsCalculation: true
            }
        ] as Grouping[]);
    }

    hidingParentElement(row) {
        const self = this;
        self.mappedDataSetAttributes.forEach(element => {
            const rightTableDS = self.rightGridDataView.getItems();
            // get the index of element
            const index = rightTableDS.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = rightTableDS[index];
                if (parentObj.parentattributeid === -1 && row.attribute_id === parentObj.attribute_id) {
                    if (!row._collapsed) {
                        parentObj['dataset_name'] = element['dataset_name'];
                        parentObj['target_attribute'] = 'dropDiv';
                        setTimeout(() => {
                            self.rightGridDataView.deleteItem(element.id);
                        }, 10);
                    } else {
                        element['parent'] = parentObj.parent;
                        element['parentattributeid'] = parentObj.parentattributeid;
                        element['sequenceIndex'] = parentObj.sequenceIndex;
                        element['_collapsed'] = parentObj._collapsed;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        self.rightGridDataView.insertItem(index + 1, element);
                        parentObj['dataset_name'] = '';
                    }
                    self.rightGrid.dataView.updateItem(parentObj.id, parentObj);
                }
            }
        });
    }

    showHideDragDrop() {
        $('.fa-caret-right').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').show();
        });
        $('.fa-caret-down').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').hide();
        });
    }

    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    myFilter(item, args) {
        if (item.parent != null) {
            let parent = args[item.parent.id];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = args[parent.parent ? parent.parent.id : null];
            }
        }
        return true;
    }

    // Save Project Mapping only
    updateProjectMapping(datasets: Dataset[]) {
        // Add Validation for all Datasets
        const project = new Project();
        project.project_id = this.projectDetail.project_id;
        // project.user_id = this.projectDetail.user_id;
        project.user_id = this.loggedInUserDetails['user_id'];
        project.tenant_id = this.projectDetail.tenant_id;
        project.datasets = [...datasets];

        // Convert as per api
        project.datasets = project.datasets.map(dataset => {
            dataset.attributes.forEach(attribute => {
                attribute['id'] = undefined;
                attribute['logical_name'] = undefined;
                attribute['dataset_name'] = undefined;
            });
            return dataset;
        });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    onRunMapping(payload) {
        let self = this;
        this.showSpinnerWhileUpdatingMapping = true;
        this.catalogSvc.runModelClassify1(payload).subscribe(resp => {
            if (resp) {
                this.isMappingChanged = true;
            }
            // Awaiting for mapping api response and navigating to next screen
            self.showSpinnerWhileUpdatingMapping = false;
            if (!self.isBusinessRule) {
                if (!self.concept_id) {
                    self.router.navigate(['/zs-cl/catalogs/' + self.catalog_id + '/objects/' + self.object_id + '/review-training'],
                        { queryParams: { object_id: self.object_id, no_matches: self.no_matches, datasets: self.datasets, currentStateIndex: 3 } });
                } else {
                    self.router.navigate(['/zs-cl/catalogs/' + self.catalog_id + '/objects/' + self.object_id + '/concepts/review-training'],
                        { queryParams: { object_id: self.object_id, concept_id: self.concept_id, no_matches: self.no_matches, datasets: self.datasets, currentStateIndex: 3 } });
                };
            }
        }, err => {
            self.showSpinnerWhileUpdatingMapping = false;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
        });
    }

    removeBorders() {
        $('.remove-border').each(function () {
            $(this).parent().parent().children('.slick-cell').each(function () {
                $(this).attr('id', 'remove-border-bottom');
            });
        });
    }

    onDrop(draggedRowIndex, droppedRowIndex) {
        this.mappedOneAttribute = [];
        this.hasMappingChnaged = true;
        // console.log('onDrop')
        // console.log(draggedRowIndex)
        // console.log(droppedRowIndex)
        const draggedRowData = this.leftGrid.gridService.getDataItemByRowIndex(draggedRowIndex);
        const droppedRowData = this.rightGrid.gridService.getDataItemByRowIndex(droppedRowIndex);
        // console.log('----- draggedRowData')
        // console.log(draggedRowData)
        // console.log('----- droppedRowData')
        // console.log(droppedRowData)
        const newRightRow = {
            'id': Math.random(),
            'logical_name': droppedRowData.logical_name,
            'concept': droppedRowData.logical_name,
            'dataset_name': draggedRowData.dataset_name,
            'dataset': draggedRowData.dataset_name,
            'datasource_name': draggedRowData.datasource_name,
            'datasource': draggedRowData.datasource_name,
            'target_attribute': draggedRowData.column,
            'dataset_column': draggedRowData.column,
            'column_type': draggedRowData.data_type,
            'data_type': draggedRowData.data_type,
            'system_attribute': droppedRowData.system_attribute,
            'attribute_id': droppedRowData.attribute_id,
            'dataset_id': draggedRowData.dataset_id,
            'sort_order': droppedRowData.sort_order,
            'authoritativeness': draggedRowData.authoritativeness,
            'dataset_column_id': draggedRowData.dataset_column_id,
            'entity_id': droppedRowData.entity_id,
            'semantic': droppedRowData.name,
            'catalog_id': droppedRowData.catalog_id,
            'match': true
        };
        const newLeftRow = {
            'id': Math.random(),
            'dataset_name': draggedRowData.dataset_name,
            'column': draggedRowData.column,
            'mapping': droppedRowData.logical_name,
            'dataset_id': draggedRowData.dataset_id,
            'groupedRow': true
        };

        const existingMapping = this.getDuplicate(newRightRow);
        if (existingMapping) {
            this.messageSvc.sendMessage({ message: `Mapping already exists.`, type: 'INFO', hideboard: true });
        } else {
            this.saveDataSetMapping(newRightRow, 'ADD', draggedRowData.dataset_id);
            this.mappedDataSetAttributes.push(newRightRow);
            this.mappedOneAttribute.push(newRightRow);
            const coloneAttribute = Object.assign({}, droppedRowData);
            coloneAttribute['isNewlyCreated'] = false;
            if (droppedRowData['dataset_name'] !== '') {
                const dropIndex = (parseInt(droppedRowIndex, 10) + 1);
                coloneAttribute.id = newRightRow.id;
                coloneAttribute['styleCss'] = 'remove-border';
                droppedRowData['styleCss'] = 'remove-border';
                coloneAttribute['isNewlyCreated'] = true;
                coloneAttribute['dataset_id'] = newRightRow.dataset_id;
                coloneAttribute['target_attribute'] = newRightRow.target_attribute;
                coloneAttribute['dataset_name'] = newRightRow.dataset_name;
                coloneAttribute['dataset_column_id'] = newRightRow.dataset_column_id;
                this.rightGridDataView.insertItem(dropIndex, coloneAttribute);
                const rowData = this.rightGridDataView.getItems();
                this.rightGridDataView.setItems(rowData);
                this.removeBorders();
                const index = this.rightGridDataSource.findIndex(item => item.attribute_id === coloneAttribute.attribute_id);
                this.rightGridDataSource.splice(index + 1, 0, coloneAttribute);
            } else {
                droppedRowData['dataset_id'] = newRightRow.dataset_id;
                droppedRowData['target_attribute'] = newRightRow.target_attribute;
                droppedRowData['dataset_name'] = newRightRow.dataset_name;
                droppedRowData['authoritativeness'] = newRightRow.authoritativeness;
                droppedRowData['dataset_column_id'] = newRightRow.dataset_column_id;
            }
            this.rightGrid.gridService.updateDataGridItem(droppedRowData, false);
            this.onDropUpdateLeftGridRow(true, newLeftRow);
            this.updateMappingLeftGridColumn(true);

            // console.log('this.mappingResp')
            this.mappingResp();         // Multiples rows mapping
            // console.log('this.mappingOneRow')
            this.mappingOneRow();       // one row mapping
        }
        // console.log('this.mappedDataSetAttributes ********************* onDrop() ********************')
        // console.log(this.mappedDataSetAttributes)
        // console.log('this.mappedOneAttribute ********************* ONE onDrop() ********************')
        // console.log(this.mappedOneAttribute)

    }

    
    mappingResp() {
        let catalogs = [];
        this.mappedDataSetAttributes.forEach(row => {
            catalogs.push(parseInt(row.catalog_id))
            // console.log(catalogs)
        });

        var catalogsU = Array.from(new Set(catalogs))
        // console.log(catalogsU)

        catalogsU.forEach((cat, i) => {
            this.resp = [
                {
                    "catalog_id": +cat,
                    "entities": []
                }
            ]

            let objects = [];
            this.mappedDataSetAttributes.forEach(row => {
                if (row.catalog_id === cat) {
                    objects.push(row.entity_id);
                }
            });
            var objectsU = Array.from(new Set(objects))

            objectsU.forEach((obj, j) => {
                this.resp[i]['entities'][j] = {
                    'entity_id': +obj,
                    'mappings': []
                }

                this.mappedDataSetAttributes.forEach((catmap, k) => {
                    if (catmap.catalog_id === cat && catmap.entity_id === obj) {
                        this.resp[i]['entities'][j]['mappings'][k] = {
                            'dataset_column_id': +catmap.dataset_column_id,
                            'attribute_id': +catmap.attribute_id
                        }
                    }
                });

            });
        });
    }

    mappingOneRow() {
        this.oneRow = [];
        this.oneRow = [
            {
                "catalog_id": +this.catalog_id,
                "entities": [
                    {
                        "entity_id": +this.object_id,
                        "mappings": [
                            {
                                "dataset_column_id": +this.mappedOneAttribute[0].dataset_column_id,
                                "attribute_id": +this.mappedOneAttribute[0].attribute_id
                            }
                        ]
                    }
                ]
            }
        ];

        
       // this.onRunMapping(); // This is the old flow, it will call on drop the attribute 
    }

    onDropUpdateLeftGridRow(addRow: boolean, row: any) {
        if (addRow) {
            const firstRow = this.leftGridDataSource.find(leftRow => row.column === leftRow.column && row.dataset_id === leftRow.dataset_id && leftRow.mapping === undefined && leftRow.groupedRow === false);
            if (firstRow) {
                firstRow.mapping = row.mapping;
                this.leftGrid.gridService.updateDataGridItem(firstRow, false);
            } else {
                this.leftGridDataSource.push(row);
                this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);

                let currentLeftGridRow = this.leftGrid.dataView.getItems();
                currentLeftGridRow.push(row);
                currentLeftGridRow = _.orderBy(currentLeftGridRow, ['dataset_name', 'column'], ['asc']);
                this.leftGrid.dataView.setItems(currentLeftGridRow);
            }
            this.progressState = Object.assign({}, this.progressState);
        }
    }

    ngAfterViewChecked() {
        const self = this;
        $('.dropDiv').droppable({
            drop: function (event, ui) {
                const dragElement = $(ui.draggable).find('div.dragMe');
                if (dragElement === undefined || dragElement === null || dragElement.length === 0) {
                    return;
                }
                const droppedRowIndex = $(this).attr('value');
                const draggedRowIndex = dragElement.attr('value');
                self.onDrop(draggedRowIndex, droppedRowIndex);
                $(this).removeClass('drag-enter');
            },
            over: function (event, ui) {
                $(this).addClass('drag-enter');
            },
            out: function (event, ui) {
                $(this).removeClass('drag-enter');
            }
        });
    }

    onRightGridCellClicked(params) {
        const event = params.eventData;
        const args = params.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);

        if (event.target.className === 'fa fa-minus deleteBtn') {
            this.removeMapping(row, false);
        }
    }

    getDuplicate(newRightGridRow: any) {
        return this.mappedDataSetAttributes.find(existingMapping => {
            return existingMapping.logical_name === newRightGridRow.logical_name &&
                existingMapping.target_attribute === newRightGridRow.target_attribute &&
                existingMapping.dataset_name === newRightGridRow.dataset_name;
        });
    }

    isMappingValid(): boolean {
        return (this.hasActiveJob || !this.mappedDataSetAttributes.length) ? false : true;
    }

    updateMappingLeftGridColumn(addRow: boolean) {
        let prevValue: number;
        if (addRow) {
            prevValue = this.mappedDataSetAttributes.length - 1;
        } else {
            prevValue = this.mappedDataSetAttributes.length + 1;
        }

        if (this.leftGridMappingHeader === undefined) {
            const allHeaders = $('.slick-header-column');
            for (let i = 0; i < allHeaders.length; i++) {
                const existingTooltip = $(allHeaders[i]).attr('title');
                if (existingTooltip === `Mapping(${prevValue})`) {
                    this.leftGridMappingHeader = allHeaders[i];
                }
            }
        }
        if (this.leftGridMappingHeader) {
            $(this.leftGridMappingHeader).attr('title', `Mapping(${this.mappedDataSetAttributes.length})`);
            const headerCell = this.leftGridMappingHeader.children[0];
            if (headerCell) {
                $(headerCell).html(`Mapping(${this.mappedDataSetAttributes.length})`);
            }
        }
    }

    getEntityName() {
        if (this.projectDetail.entity_name) {
            return this.projectDetail.entity_name;
        }
    }

    saveDataSetMapping(input: any, opcode: string, selectedDataSetId: number) {
        // create dataset from mapping
        const attribute = Object.assign({}, input);
        const dataSet = new Dataset();
        dataSet.dataset_id = selectedDataSetId;
        dataSet.attributes = [];
        attribute.opCode = opcode;
        dataSet.attributes.push(attribute);
        // save in DB
        this.updateProjectMapping([dataSet]);
    }

    getLeftGridCount(): number {
        if (this.leftGrid) {
            const filterRows = this.leftGrid.dataView.getItems().filter(row => !row.groupedRow);
            return filterRows.length;
        } else {
            return this.defaultUnMappedEntityAttributes.length;
        }
    }

    isPropRootKey(rootObject, prop) {
        return rootObject.hasOwnProperty(prop);
    }

    prepareEntityMapping(dataSet) {
        let counter = -1;
        const chaninedItems = [];
        dataSet.filter(function (item) {
            if (item['data_type'] === AppGlobals.WHOLE_DATA_TYPE) { chaninedItems.push(...item['chained']); } return item['data_type'] === AppGlobals.WHOLE_DATA_TYPE;
        });
        for (let chainedIndex = 0; chainedIndex < chaninedItems.length; chainedIndex++) {
            const chainedElement = chaninedItems[chainedIndex];
            // loop parent obj
            for (let parentIndex = 0; parentIndex < dataSet.length; parentIndex++) {
                const parentElement = dataSet[parentIndex];
                if ((chainedElement.attribute_id === parentElement.attribute_id) && (!parentElement.hasOwnProperty('chained') || !chainedElement.hasOwnProperty('chained'))) {
                    dataSet.splice(parentIndex, 1);
                    break;
                }
            }
        }
        this.defaultEntityAttributes = [];
        dataSet.forEach((attribute) => {
            counter += 1;
            attribute['indent'] = 0;
            attribute['parent'] = null;
            attribute['sequenceIndex'] = counter;
            attribute['parentattributeid'] = -1;
            attribute['_collapsed'] = false;
            this.defaultEntityAttributes.push(attribute); // expand root element
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === AppGlobals.WHOLE_DATA_TYPE && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['indent'] = 1;
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                    chainElement['parentattributeid'] = attribute['attribute_id'];
                    chainElement['_collapsed'] = false;
                    this.defaultEntityAttributes.push(chainElement);
                }
            }
        });
        
    }

    buildParentAndChildRelation(dataSource) {
        let counter = -1;
        dataSource.forEach((attribute) => {
            counter += 1;
            attribute['sequenceIndex'] = counter;
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === 'Chain' && attribute.chained && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                }
            }
        });
        dataSource.forEach((attribute) => {
            const filteredData = dataSource.filter(data => data.parentattributeid === attribute.attribute_id);
            if (filteredData.length > 1) {
                const index = dataSource.findIndex(item => item.attribute_id === attribute.attribute_id);
                for (let chainIndex = 0; chainIndex < filteredData.length; chainIndex++) {
                    const chainElement = filteredData[chainIndex];
                    chainElement['parent'] = { id: index };
                }
            }
        });
    }

    onCancelStep(): void {
        if (this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        };
    }

    onCancel() {
        if (this.router.url.includes('create-bussiness-rule')) {
            $('.modal').modal('hide');
            if (this.dq_rule_level == 'concept') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
                }
            } else if (this.dq_rule_level == 'catalog') {
                if (this.backTo == 'catalog') {
                    this.router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }

            } else if (this.dq_rule_level == 'object') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }
            } else {
                this.router.navigate(['/zs-cl/dataquality']);
            }
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
        }
    }

    onPreviousStep(): void {
        if (this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/select-datasets'],
                { queryParams: { object_id: this.object_id, no_matches: this.no_matches, datasets: this.datasets } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/select-datasets'],
                { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
        };
    }

    onPrevious() {
        if (this.router.url.includes('create-bussiness-rule')) {
            this.router.navigate(['/zs-cl/rules/create-bussiness-rule/select-dataset'], { queryParamsHandling: 'preserve' });
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/select-dataset'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
        }
    }

    onMessageCancel() {
        return false
    }

    onMessageYes() {
        return true
    }

    showDialog() {
        const parent = this;
        parent.confirmDialogService.confirmThis("Are you sure to delete?", function () {
            parent.xxxxx = true;
        }, function () {
            parent.xxxxx = false;
        })

    }

    canExit(): boolean {
        // this.showDialog();
        if (this.mappedDataSetAttributes.length > 0 && !this.canGoNextScreen) {
            if (confirm("Your data will be lost if you leave this screen. Are you sure?")) {
                return true
            } else {
                this.flash.type = 'WARN2';
                this.flash.message = "Your data will be lost if you leave this screen. Are you sure?";
                this.flash.showboard = false;
                return false
            }
        } else {
            return true
        }

        // const parent = this;
        // parent.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
        //     // alert("Yes clicked");  
        //     parent.xxxxx = true;
        //     // console.log(parent.xxxxx)
        //     return true
        // }, function () {  
        //     // alert("No clicked");
        //     parent.xxxxx = false;
        //     // console.log(parent.xxxxx)
        //     return false  
        // })  

        // return true

    }

    onNext() {

        // sessionStorage.setItem('mappedDatasets', this.mappedDataSetAttributes)
        sessionStorage.setItem('mappedDatasets', JSON.stringify(this.mappedDataSetAttributes));

        const new_dataset_column_ids = []
        this.mappedDataSetAttributes.forEach(element => {
            new_dataset_column_ids.push(+element.dataset_column_id);
        });
        this.updateEntityMapping();
        // Commenting this code as it is not required, bucause mapping is already creating by calling classify API
        // const payload = {
        //     dataset_classify_id: new_dataset_column_ids
        // }
        // if (this.concept_id === undefined || this.concept_id === null) {
        //     this.catalogSvc.semanticObjectsOfListMapping({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalog_id, object_id: this.object_id, payload: payload }).subscribe((res) => {
        //     }, (error) => { });
        // } else {
        //     this.catalogSvc.conceptsOfListMapping({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalog_id, object_id: this.object_id, concept_id: this.concept_id, payload: payload }).subscribe((res) => {
        //     }, (error) => { });
        // };
        if (this.isBusinessRule) {
            if (this.hasMappingChnaged) {

                if (this.obRule_ids.length > 1) {
                    this.obRule_ids.forEach(ele => {
                        const data = {
                            rule_id: ele,
                            payload: {
                                is_mapping_changed: true
                            }
                        };
                        this.catalogSvc.updateChangeMappings(data).subscribe(resp => {
                        }, error => { });
                    });
                }
                
                if (this.rule_id) {
                    const data = {
                        rule_id: this.rule_id,
                        payload: {
                            is_mapping_changed: true
                        }
                    };
                    this.catalogSvc.updateChangeMappings(data).subscribe(resp => {
                    }, error => { });

                }
            }
            // Once the components are available, routing will be updated
            if (this.router.url.includes('create-bussiness-rule')) {
                this.router.navigate(['/zs-cl/rules/create-bussiness-rule/add-users'], { queryParams: { datasets: this.datasets, isMappingChanged: this.isMappingChanged }, queryParamsHandling: 'merge' });
            }
            if (this.router.url.includes('data-quality-rules')) {
                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/bussiness-run-schedule'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id, datasets: this.datasets } });
            }
        } 
        // else {
        //     console.log('Concept ....', this.concept_id);
        //     if (this.concept_id === undefined || this.concept_id === null) {
        //         console.log('concept is null; ', this.concept_id);
        //         this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/review-training'],
        //             { queryParams: { object_id: this.object_id, no_matches: this.no_matches, datasets: this.datasets, currentStateIndex: 3 } });
        //     } else {
        //         console.log('concept is not null;;;;;;;;;;;;;;;;;;;;;;;s; ');
        //         this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/review-training'],
        //             { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets, currentStateIndex: 3 } });
        //     };
        // }
    }

    // Modal Datasets
    selectOptionModelList(dataSetId, limit = 30) {
        this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        // this.isSampleDsLoading = true;
        this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'],
            this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
                this.modalRowData = data.currentpage;
                const rowKeys = [];
                if (data.currentpage.length) {
                    const myVar: any = data.currentpage[0];
                    for (var key in myVar) {
                        rowKeys.push(key)
                    }
                }
                this.modelColumnDef = [];
                rowKeys.forEach(column => {
                    this.modelColumnDef.push({
                        'displayname': column,
                        'physicalname': column,
                        'sortable': true,
                        'datatype': 'String',
                        'filterable': true,
                        'minWidth': 150,
                    });
                });

                this.modalRowData.forEach((element: any) => {
                    element.id = Math.random();
                });

                // this.total = data['totalrecords'];
                if (this.modalGrid) {
                    this.modalGrid.dataView.setItems(this.modalRowData);
                    this.modalGrid.gridService.setSelectedRows([]);
                }
                this.isSampleDsLoading = false;
            }, err => {
                this.isSampleDsLoading = false;
                this.modalRowData = [];
            });

    }

    onModelGridCreation(grid) {
        this.modalGrid = grid;
    }

    onDatasetCellClick(e): void {
        const parent = this;
        const eventEle = e.eventData;
        const args = e.args;
        const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);
        // console.log(row)
        if (row !== undefined && row !== null) {
            if (eventEle.target.title === row.dataset_name) {
                this.dsName = row.dataset_name;
                this.showmodelpopup = true;
                this.datasetId = row.dataset_id;
                // this.selectOptionModelList(row.dataset_id);
                // $('#data_refresh_modal').modal('show');
                // $('#data_refresh_modal').removeClass('d-none');
            }
        }
    }

    popupoutput(message) {
        this.showmodelpopup = message;
    }

    onSave() {
        this.updateEntityMapping();
        this.router.navigate(['/zs-cl/dataquality']);
        $('.modal').modal('hide');
    }
    getMappedAttributesForEntity() {
        let data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalog_id,
            entity_id: this.object_id,
            dataset_ids: this.dataset_ids.toString()
        };
        if (this.isBusinessRule) {
            const multiAttributes = { attributeIds: this.attributIds };
            data = { ...data, ...multiAttributes };
        }

        this.catalogSvc.getMappings(data).subscribe(resp => {
            this.originalMappedColumnConceptMapping = resp;
            this.rightGridDataSource = [];
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction(resp);
        }, error => {
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction([]);
        });
    }
    getMappedAttributesForConcept() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalog_id,
            entity_id: this.object_id,
            concept_id: this.concept_id,
            dataset_ids: this.dataset_ids.toString()
        };
        this.catalogSvc.getMappingsForConcept(data).subscribe(resp => {
            this.originalMappedColumnConceptMapping = resp;
            this.rightGridDataSource = [];
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction(resp);
        }, error => {
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction([]);
        });
    }

    updateEntityMapping() {
        const attributes = [];
        const mappedData = this.rightGrid.dataView.getItems();
        mappedData.forEach(attr => {
            if (attr.dataset_column_id) {
                attributes.push({
                    dataset_column_id: attr.dataset_column_id,
                    attribute_id: attr.attribute_id,
                });
            }
        });
        const payload = [
            {
                catalog_id: +this.catalog_id,
                entities: [
                    {
                        entity_id: +this.object_id,
                        mappings: attributes
                    }
                ]
            }
        ];
        this.onRunMapping(payload);
    }

}