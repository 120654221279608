import { Component, Input, OnInit, Output } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
@Component({
  selector: 'days-formatter',
  template: `<div class="text-center w-100 p-3" [title]="formattedValue">{{ formattedValue }}</div>`,
  styles: [],
})


export class DaysCellRenderer implements ICellRendererAngularComp, OnInit {
    value: any;
    params: any;
  formattedValue: string;
  @Output() isclassifyProject?:boolean;

  constructor(private zettaUtilsInstance: ZettaUtils) {}

  ngOnInit(): void {
   
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
    this.formattedValue = this.zettaUtilsInstance.commarization(this.value, 2);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
