import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'confidence-formatter',
  template: `
    <div class="row align-items-center">
      <div class="col-md-auto">
        <label [ngClass]="getLabelClass()" [title]="getTitle()">{{ getLabelText() }}</label>
      </div>
    </div>
  `,
  styles: [`
    .priorityCell {
      font-weight: bold
    }

    .low {
      color: white;
    }

    .medium {
      color: white;
    }

    .high {
      color: white;
    }
  `]
})
export class ConfidenceFormatterComponent implements OnInit, ICellRendererAngularComp {
  value: any;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getLabelClass(): string {
    return this.value === 'L' ? 'priorityCell low' :
      this.value === 'H' ? 'priorityCell high' :
        'priorityCell medium';
  }

  getLabelText(): string {
    return this.value === 'L' ? 'Low' :
      this.value === 'H' ? 'High' :
        'Medium';
  }

  getTitle(): string {
    return this.getLabelText();
  }

  constructor() { }

  ngOnInit(): void {
  }
}
