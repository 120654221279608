import { AppGlobals } from '../../common/shared/app.globals';
import { Injectable } from '@angular/core';
import { BaseService } from '../../common/services/base-service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ZcContentService extends BaseService {

	constructor(private http: HttpClient) { super(); }

	public getReports(): Observable<any> {
		return this.http.get(`./assets/reports.json`);
	}
}
