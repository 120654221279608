import { Column, Formatter } from 'angular-slickgrid';

export const editRemoveFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (dataContext.action === 'add') {
        return `<button type="button" class="btn btn-primary btn-zetta btn-light mx-1 column-btn add-btn ${dataContext.isValidFormula ? '' : 'disabled-state' }" ><span id='group_addEdit_${row}' class="btn-label px-2 add-btn">Add</span></button>`;
    } else {
        if (dataContext.isEditing) {
            return `<i class="fas fa-floppy-o actionInlineBtn ${dataContext.isValidFormula ? '' : 'disabled-state' }" id='group_addEdit_${row}'></i> <i class="fa fa-minus deleteBtn ml-3" data-toggle="modal" data-target="#action"></i>`;
        } else {
            return `<i class="fal fa-pencil actionInlineBtn" id='group_addEdit_${row}'></i> <i class="fa fa-minus deleteBtn ml-3" data-toggle="modal" data-target="#action"></i>`;
        }
    }
};

