import { Column, Formatter } from 'angular-slickgrid';

export const managejobsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.field === 'spark_appid') {
    return !!value ? `<div class="blueLink spark_uiurl" style="padding-left: 16px;">${value}</div>` : ``;
  }

  if (columnDef.field === 'step') {
    return `<div style="padding-left: 16px;">${value}</div>`;
  }

  if (columnDef.field === 'status') {
    let st = '';
    if (value === 'Not Started') st = "st-gray";
    if (value === 'Started') { st = "st-green"; value = 'Running'; };  //Active
    if (value === 'Running') { st = "st-green"; value = 'Running'; };
    if (value === 'Completed') st = "st-blue";
    if (value === 'Failed') { st = "st-red";};
    if (value === 'Cancelled') st = "st-red";
    if (value === 'Timed Out') { st = "st-red"; value = 'Cancelled'; };
    if (value === 'Skipped') st = "st-gray";
    return `<div><div class="status ${st}">${value}</div></div>`;
  }

  let actions = "";
  if (columnDef.field === 'actions') {
    var btnJobActions: any = columnDef.params.btnJobActions;
    var classBtnJobActions = '';
    if (btnJobActions.visible) {
      if (btnJobActions.enable) {
        classBtnJobActions = '';
      } else {
        classBtnJobActions = 'disabled';
      }
    } else {
      classBtnJobActions = 'd-none';
    }

    //Active
    if (dataContext.started !== null && dataContext.ended === null && dataContext.status !== 'Cancelled' && dataContext.status !== 'Failed') {
      return `<div class="mr-4"><button type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1 spark_uiurl pr-1 ${classBtnJobActions}" >
                  <span class="btn-label spark_uiurl">Log</span>
              </button>
              <button type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1 cancel-job ${classBtnJobActions}">
                  <span class="btn-label cancel-job">Cancel</span>
              </button></div>`;
    }

    //Completed
    if ((dataContext.started !== null && dataContext.ended !== null) || (dataContext.started !== null && (dataContext.status === 'Cancelled' || dataContext.status === 'Failed'))) {
      return `<div class="ml-2rem"><button type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1 spark_uiurl ${classBtnJobActions}">
                  <span class="btn-label spark_uiurl">Log</span>
              </button></div>`;
    }
  }

};
