import { User } from '../../../../../common/zm-shared/zm-zetta-models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from 'src/app/common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { Analytic } from '../../analytic.model';

@Component({
  selector: 'zm-ad-users',
  templateUrl: './ad-users.component.html',
  styleUrls: ['./ad-users.component.scss']
})
export class AdUsersComponent implements OnInit {

  // analyticDetail = new Analytic();
  totalUsers = 0;
  users: User[] = [];
  activeUsers: User[] = [];

  analyticDetail: Analytic = new Analytic();
  xxxxx: string;

  constructor(public zettaUtility: ZmZettaUtils,
    private _analyticService: AnalyticService,
    private activeRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    const queryParams = this.activeRoute.snapshot.queryParams;
    const routeParams = this.activeRoute.snapshot.params;
    this.analyticDetail.analyticid = routeParams.id;

    this.getAnalytic();
  }

  getAnalytic() {
      this._analyticService.getAnalytic(this._analyticService.getObjectFromSession('userInfo').userid, this.analyticDetail.analyticid).subscribe(responseList => {
        // this.analyticDetail = responseList;
        this.analyticDetail = responseList['currentpage'][0];

        if (this.analyticDetail.users) {
          this.totalUsers = this.analyticDetail.users.length;
          this.users = this.analyticDetail.users;
          this.activeUsers = this.analyticDetail.active_users;
        }
      }, err => {
        this.analyticDetail.users = [];
      });

  }

  // getProject() {
  //     this._projectService.getProject(this._projectService.getObjectFromSession('userInfo').userid,
  //     this.projectDetail.projectid).subscribe(responseList => {
  //       this.projectDetail = responseList;

  //       if (this.projectDetail.users) {
  //         this.totalUsers = this.projectDetail.users.length;
  //         this.activeUsers = this.projectDetail.active_users;
  //       }
  //     }, err => {
  //       this.projectDetail.users = [];
  //     });

  // }

  getProfileImageClass(index: number, total: number) {
    if (total === 1) {
      return 'col-md-12 text-center';
    } else if (index === 0 || index === 1) {
      return 'col-md-6 mb-2';
    } else if (index === 2 && total === 3) {
      return 'col-md-12';
    } else if (index === 2 || index === 3) {
      return 'col-md-6';
    }
  }

  goManageUsers() {
    this.router.navigate(['/zm/analytics/' + this.analyticDetail.analyticid + '/users'], { queryParams: { showBreadcrumb: 'true' } });  
  }

}