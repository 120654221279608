import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ProjectStatus } from "src/app/common/components/project-progress/project-progress.model";
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { ZsClContentService } from "../../zs-cl-content.service";
@Component({
    selector:'zetta-define-sub-concept',
    templateUrl: './zs-cl-define-new-sub-concept.component.html',
    styleUrls:['./zs-cl-define-new-sub-concept.component.scss']
})
export class ZsClDefinNewSubConceptComponent implements OnInit{
    activeNavLnk:string ='1';
    progressState = new ProjectStatus();
    loggedInUserDetails:any;
    project_id:number
    projectData:object;
    isDataReady=false;
    showBreadcrumb=false;
    isEditProject: string;
    constructor(private zettaUtils:ZettaUtils, 
        private contentService: ZsClContentService,
        private activatedRoute: ActivatedRoute){}

    ngOnInit(): void {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo')); 
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.isEditProject= this.activatedRoute.snapshot.queryParamMap.get('isEditProject');
        this.activatedRoute.queryParamMap.subscribe(params =>{
          if(params.has('activeLink')){
            this.activeNavLnk= params.get('activeLink');
          }          
        });
        this.activatedRoute.parent.params.subscribe(params => {
            this.project_id = params['id'];
          });
          if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
          }
          if (!this.showBreadcrumb) {
            $('#breadcrumb').addClass('d-none');
          }
        this.getProject();
    }

   
    getProject(){
        const payload = {
          user_id: this.loggedInUserDetails.user_id,
          tenant_id: this.loggedInUserDetails.tenant_id,
          project_id: this.project_id
        };
        this.contentService.getclassifyProject(payload).subscribe(resp => {
          if(resp){
            this.projectData = resp;           
              this.progressState.states = this.zettaUtils.getStateList('create-classify-concept-parser-project');
              this.progressState.currentStateIndex = 3;
              this.progressState.type = 'Data Classification Project';
              this.progressState.classificationProject = resp;
              this.progressState.isEdit = this.isEditProject === 'true';
          }
            this.progressState.currentStateInfo = 'Saved...';
          
          this.isDataReady = true;
        });
      }
      
      
}