import { Component, OnInit, Compiler, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../common/shared/app.globals';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'zetta-zs-in-redirection',
  templateUrl: './zs-in-redirection.component.html',
  styleUrls: ['./zs-in-redirection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsInRedirectionComponent implements OnInit {

  public userinfo;
  safeSrc: SafeResourceUrl;
  url;
  // pipelineType;

  constructor(private sanitizer: DomSanitizer,
              private baseService: BaseService,
              private _router: Router,
              private _compiler: Compiler,
              private _authSvc: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient
              // private headerCLA: ZsClHeaderComponent
              ) { }

  ngOnInit() {

    this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const app = this.activatedRoute.snapshot.queryParamMap.get('app');
    const defAppId = this.activatedRoute.snapshot.queryParamMap.get('defAppId');

    if(defAppId !== '' && defAppId !== null && defAppId !== undefined) {
        sessionStorage.setItem('preference.default_app_id', defAppId);
        const postData = {
                          user_id: this.userinfo.user_id,
                          payload: {
                            preference: { default_app_id: +defAppId }
                          }
                        };
        this.baseService.updateUserPreferences(postData, this.http).subscribe((result) => {
          this.userinfo.preference.default_app_id = +defAppId;
          sessionStorage.setItem('userInfo', JSON.stringify(this.userinfo));
          if(app === 'Resolve') {
            sessionStorage.setItem('selectedApp', 'zs');
            sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');
            sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');
            this._router.navigate(['zs' + '/home']);
          }
          if(app === 'Classify') {
            sessionStorage.setItem('selectedApp', 'zs-cl');
            sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');
            sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');
            this._router.navigate(['zs-cl' + '/home']);
          }
          if(app === 'Logout') {
            // alert('Logout, defAppId = ' + defAppId)
            // // this.timerSubscription.unsubscribe();
            this._compiler.clearCache();
            this._authSvc.signOut();
            // // this.routeDelay = setTimeout(() => {
            // // 	this._router.navigate([AppGlobals.ZETTASENSE_LOGIN]);
            // // }, 100);
            
            // const route = sessionStorage.getItem('is_zs_login_page') === 'true' ? '/zl/login' : '/zl/login';
            const route = '/zl/login';
            sessionStorage.clear();	
            // alert('Cache deleted')
            // window.location.reload();
            this._router.navigate([route]);
            // window.location.reload();
          }
        }, err => { });

    } else {
      if(app === 'Resolve') {
        sessionStorage.setItem('selectedApp', 'zs');
        sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');
        sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');
        this._router.navigate(['zs' + '/home']);
      }
      if(app === 'Classify') {
        sessionStorage.setItem('selectedApp', 'zs-cl');
        sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');
        sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');
        this._router.navigate(['zs-cl' + '/home']);
      }
      if(app === 'Logout') {
        // alert('Logout, defAppId = ' + defAppId)
        // // this.timerSubscription.unsubscribe();
        this._compiler.clearCache();
        this._authSvc.signOut();
        // // this.routeDelay = setTimeout(() => {
        // // 	this._router.navigate([AppGlobals.ZETTASENSE_LOGIN]);
        // // }, 100);
        
        // const route = sessionStorage.getItem('is_zs_login_page') === 'true' ? '/zl/login' : '/zl/login';
        const route = '/zl/login';
        sessionStorage.clear();	
        // alert('Cache deleted')
        // window.location.reload();
        this._router.navigate([route]);
        // window.location.reload();
      }

    }
    
  }

}