import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatasetService } from '../dataset.service';
import { IDataset } from '../dataset.model';
import { MessageService } from '../../message/message.service';

@Component({
  selector: 'zetta-create-dataset',
  templateUrl: './create-dataset.component.html',
  styleUrls: ['./create-dataset.component.scss']
})

export class CreateDatasetComponent implements OnInit, OnDestroy {

  showBreadcrumb = false;
  progressState = new ProjectStatus();
  createDatasetForm: FormGroup;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  errorMessage;
  dataSetTypes = [];
  datasourceId
  selectedDataSource: {};
  isDataReady = false;
  loadProject = false;
  datasetDelimiter;
  @ViewChild('focusElement') focusElement;

  constructor(public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private datasetSvc: DatasetService,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.setDataSetForm();
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }
    const datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
    this.datasetDelimiter = ',';

    console.log(datasetId)

    this.progressState.states = this.zettaUtils.getStateList('create-dataset');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Set';
    this.progressState.isEdit = (datasetId !== undefined && datasetId !== null) ? true : false;
    this.progressState.isEdit ? sessionStorage.setItem('newDataSet', 'false') : sessionStorage.setItem('newDataSet', 'true');

    const user_id = this.loggedInUserDetails.user_id;
    const tenant_id = this.loggedInUserDetails.tenant_id;
    if (datasetId !== null) {
      const getDataset: IDataset = {
        user_id: user_id,
        tenant_id: tenant_id,
        dataset_id: datasetId
      };
      this.datasetSvc.getDataset(getDataset).subscribe((result) => {
        this.datasetDelimiter = result.delimiter;
        this.createDatasetForm.patchValue({
          title: result.name,
          description: result.description,
          // datasettypeid: result.dataset_type_id,
          // delimiter: result.delimiter,
          // datasettypeid: result.dataset_type_id,
          delimiter: ',',
          datasource_id: result.datasource_id
        });
        this.getDatasourceGroup(result);
      }, (responseError) => {
        this.errorMessage = responseError.error.message;
        // this.messageSvc.sendMessage({ message: 'Get dataset has failed', type: 'INFO', hideboard: true });
      });
    } else {
      this.datasetSvc.getTenantInfo(user_id, tenant_id).subscribe((result) => {
        this.createDatasetForm.patchValue({
          datasource_id: result.default_datasource_id
        });
      }, (responseError) => {
        this.errorMessage = responseError.error.message;
      });
      this.getDatasourceGroup({});
    }
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }

  delimiterInput(selectedValue: string) {
    if (selectedValue !== ',' && selectedValue !== '/t' && selectedValue !== '|') {
      $('#delimiters').val('');
    } else {
      $('#delimiters').val(selectedValue);
    }
  }

  delimitersSelected(selectedValue: string) {
    $('#delimiter')[0]['value'] = selectedValue;
  }

  isRadioBtnChecked(inputSource: any) {
    if (inputSource) {
      this.selectedDataSource = inputSource;
    }
  }

  setDataSetForm() {
    this.createDatasetForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: [''],
      datasettypeid: [''],
      datasource_id: [''],
      delimiter: [this.datasetDelimiter],
    });
  }

  getDatasourceGroup(obj) {
    this.datasetSvc.getDataSetTypes().subscribe(responseList => {
      this.dataSetTypes = responseList;
      this.selectedDataSource = this.dataSetTypes.find(res => res['lookup_id'] === obj['dataset_type_id']);
      this.isDataReady = true;
    });
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

  onNext() {
    const data = this.createDatasetForm.value;
    const datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
    const user_id = this.loggedInUserDetails.user_id;
    const tenant_id = this.loggedInUserDetails.tenant_id;
    if (datasetId !== null) {
      const postData: IDataset = {
        user_id: user_id,
        tenant_id: tenant_id,
        dataset_id: datasetId,
        payload: {
          name: data.title,
          description: data.description,
          datasource_id: data['datasource_id'] ? data['datasource_id'] : '1',
          dataset_type_id: +data.datasettypeid ? +data.datasettypeid : 4,
          delimiter: +data.datasettypeid === 4 ? $('#delimiter')[0]['value'] : ',',
          header: true,
          dataset_id: datasetId
        }
      };
      this.datasetSvc.editDataset(postData).subscribe((result) => {
        this.router.navigate(
          ['/zs/datasets/select-data-source'],
          { queryParams: { showBreadcrumb: this.showBreadcrumb, datasetId: result.dataset_id } }
        );
      }, (responseError) => {
        this.errorMessage = responseError.error.message;
        this.messageSvc.sendMessage({ message: 'Edit dataset has failed', type: 'INFO', hideboard: true });
      });
    } else {
      const postData: IDataset = {
        user_id: user_id,
        tenant_id: tenant_id,
        payload: {
          name: data.title,
          description: data.description,
          datasource_id: data['datasource_id'],
          dataset_type_id: +data.datasettypeid ? +data.datasettypeid : 4,
          delimiter: +data.datasettypeid === 4 ? $('#delimiter')[0]['value'] : ',',
          header: true,
          source_table_or_filename: 'sample' + Math.random() + '.csv'
        }
      };
      this.datasetSvc.createDataset(postData).subscribe((result) => {
        this.router.navigate(
          ['/zs/datasets/select-data-source'],
          { queryParams: { showBreadcrumb: this.showBreadcrumb, datasetId: result.dataset_id } }
        );
      }, (responseError) => {
        this.errorMessage = responseError.error.message;
        this.messageSvc.sendMessage({ message: 'Create dataset has failed', type: 'INFO', hideboard: true });
      });
    }

  }

  onTextChange() {
    if (this.errorMessage) {
      this.errorMessage = false;
    }
  }

}
