import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { BaseService } from '../../../../../common/services/base-service';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ContentService } from '../../../../../zettasense/content/content.service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../../../common/components/project-progress/project-progress.model';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/common/components/message/message.service';
declare var $: any;
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { ObservableService } from "../../../../../common/services/observable.service";

@Component({
  selector: 'zetta-business-rule-definition',
  templateUrl: './business-rule-definition.component.html',
  styleUrls: ['./business-rule-definition.component.scss'],
})
export class BusinessRuleDefinitionComponent implements OnInit {

  @Input() isEditRuleView: any;
  @Input() ruleNameSelected;
  activeNavLnkBusiness = "rule-definition";
  rule_selected: any;
  rule_data_quality_dimension: any;
  concepts: any = [];
  withinDatasetColumns: any = [];
  semantic_objects: any;
  createNewRule = false;
  isSingleAttribute = true;
  loggedInUserDetails;
  isLoading = true;
  dataQualityRule: any = [];
  entities: any = [];
  attributes: any = [];
  attAdd: any = [];
  dqDimensions: any = [];
  dqSequence: any = [];
  dqValueTypes: any = [];
  dqOperators: any = [];
  isWithinDsColumn: any = [];
  withinDatasets: any = [];
  typeRule;
  rule_id;
  containers = [];
  dqObjectIdAdded: any = null;
  dqConceptIdAdded: any = null;
  total_dqr_attributes = 0;
  total_dqr_exceptions = 0;
  flagAttributes: any = [];
  step2 = false;
  validateMultiColumn = true;
  progressState = new ProjectStatus();
  payloadSaveRule;
  isvalidate = false;
  @Input() isRuleInValid: boolean;
  @Input() isRuleAdmin: boolean;
  @Input() isRuleExecuter: boolean;

  join_condition = [
    { name: 'OR' },
    { name: 'AND' },
  ];
  object_id: number;
  concept_id: number;
  dq_rule_level: string;
  rule_types: any;
  value_types: any;
  semantics = [];
  operators: any;
  dsColumnName = "Account Master 10K";
  datasetId = 3;
  catalogId = "1";
  entityId;
  conceptId;
  data: any;
  selectedEntityID = {};
  selectedAttributeID = {};
  removeFlagInd;
  CatalogName;
  defaultCatalog;
  conceptSettings = {};
  conceptTypeSettings = {};
  datasetdropdownSettings = {};
  datasetColumndropdownSettings = {};
  multiDatasetColumnSettings = {};
  multiDatasetSettings = {};
  conceptsSettings = {};
  enableNext = true;
  isSkipedBefore = false;
  dqr_exceptions = [];
  datasets = [];
  dataset_columns = [];
  defaultConcepts = [];
  dataQualityOperators = [];
  dataQualityValueTypes = [];
  dqr_attributes = [];
  entitiesDQ: any = [];
  dimensionsDQ: any = [];
  sequencesDQ: any = [];
  withindMultiatasetColumns = [[]];
  ruleExists;
  isRuleLoading = true;
  backTo: string;
  selectedRuleType = "14943";
  isMultiAttribute = false;
  isBetweenSelected = false;
  defaultConceptId: number;
  page = 1;
  limit = 30;
  obRule_ids;
  dqDataTypes = [];
  uniqueAttributeIds = [];
  attributeIds : string;
  rules = [
    {
      rule_id: '1',
      rule_name: '',
      rule_description: '',
      dimension_lookup_id: '',
      data_quality_rule_attributes: { attribute_id: [], entity_id: [] },
      semantic_objects: [
        {
          id: '1',
          name: 'Customer'
        }
      ],
      concepts: [],
      flags: [
        {
          sequence: 1,
          rule_type_id: '1',
          rule_name: '',
          concept_id: '',
          concept_name: 'Customer Name',
          concepts: [],
          value_type_id: '17',
          value_type_name: '',
          value_type_lookup_id: 14879,
          rule_sequence_type_lookup_id: 14943,
          comparison_concept_id: '',
          comparison_value_type_lookup_id: 14879,
          comparison_concept_name: 'Customer Number',
          comparison_value_type_id: '1',
          comparison_value_type_name: 'value',
          operator_id: '1',
          operator_name: '',
          threshold: '',
          lower_limit: '',
          upper_limit: '',
          custom_exp: '',
          comparison_custom_exp: '',
          within_list_column_id: '',
          within_source_id: '',
          within_filter: '',
          join_condition: 'AND',
          data_set_name: [],
          column: [],
          within_ds_column_id: '',
          name: 'Single Attribute',
          selected_rule_type: '14943',
          dataQualityOperators: [],
          within_dataset_id:'',
          within_dataset_column_id:'',
        }
      ]
    }
  ];
  customBusinessRule;
  ruleInfo;
  ruleInfoClone;
  payloadForEditRule:any = {};
  is_clone = false;

  constructor(private baseService: BaseService, private router: Router, private datasetService: DatasetService,
    private catalogSvc: ZsClContentService, private contentSvc: ContentService, private activatedRoute: ActivatedRoute, public zettaUtils: ZettaUtils,
    private messageSvc: MessageService,
    public _observableService: ObservableService
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.router.url.includes('create-bussiness-rule')) {
        this.createNewRule = true;
      } else {
        this.createNewRule = false;
      }

      this.ruleExists = JSON.parse(sessionStorage.getItem('ruleDefintion'));
      if (this.ruleExists) {
        this.rules[0] = this.ruleExists;
        this.mapExisting(this.ruleExists);
      } else {
        this.mapExisting(this.ruleExists);
      }
    });
    let DQ_rule_level: string = '';
    this.activatedRoute.queryParamMap.subscribe(params => {
      DQ_rule_level = params.get('dqRuleLevel');
    });
    window.addEventListener('popstate', () => {
      if (DQ_rule_level == 'dataset') {
        this.router.navigate(['/zs/datasets']);
      }
    });
  }



  ngOnInit() {
    this.activeNavLnkBusiness = sessionStorage.getItem('activeNavLnkBusiness');
    this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
    this.progressState.currentStateIndex = 2;
    this.progressState.type = 'create-bussiness-rules';
    this.progressState.type = 'classifySet';

    this.loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    this.rule_selected = this.rules[0];

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.obRule_ids = params.get('selectoobRule');
      const is_clone = params.get('is_clone');
      if (is_clone && is_clone === 'true') {
          this.is_clone = true;
      }
      if (params.has("catalog_id")) {
        this.catalogId = params.get('catalog_id');
        this.object_id = +params.get('object_id');
        this.concept_id = +params.get('concept_id');
        this.defaultConceptId = this.concept_id;
        this.dq_rule_level = params.get('dqRuleLevel');
        this.isSkipedBefore = params.get('is_skiped') == "true" ? true : false;
        this.backTo = params.get('BackTo');
        if (params.has('attributeIds')) {
          this.attributeIds = params.get('attributeIds');
        }
        
        if (this.rules[0]['data_quality_rule_attributes']) {
          let isEntityIdPresent = this.rules[0]['data_quality_rule_attributes'].entity_id.find((entity) => {
            return entity.entity_id === this.object_id;
          })

          let isAttributeIdPresent = this.rules[0]['data_quality_rule_attributes'].attribute_id.find((attribute) => {
            return attribute.attribute_id === this.concept_id;
          })

          if (!isEntityIdPresent) {
            this.rules[0]['data_quality_rule_attributes'].entity_id.push({ entity_id: this.object_id })
          }

          if (!isAttributeIdPresent) {
            this.rules[0]['data_quality_rule_attributes'].attribute_id.push({ attribute_id: this.concept_id });
          } 
        }
        //this.rules[0]['data_quality_rule_attributes'].entity_id.push({ entity_id: this.object_id })
        //this.rules[0]['data_quality_rule_attributes'].attribute_id.push({ attribute_id: this.concept_id });
      }
    });
    this.baseService.hasPageRefresh.subscribe(value => {
      if (value) {
        this.activeNavLnkBusiness = "rule-definition"
        sessionStorage.removeItem('activeNavLnkBusiness');
      }
    });

    this.ruleInfo = JSON.parse(sessionStorage.getItem('ruleInfo'));
    this.ruleInfoClone = JSON.parse(sessionStorage.getItem('ruleInfoClone'));

    if(this.ruleInfoClone) {
      this.rules[0].rule_id = this.ruleInfoClone.data_quality_rule_id;
      this.rules[0].rule_name = this.ruleInfoClone.rule_name + '_' + Date.now().toString().slice(6,12);
      this.rules[0].rule_description = this.ruleInfoClone.rule_description;
      this.rules[0].dimension_lookup_id = this.ruleInfoClone.dimension_lookup_id;
      this.rules[0].flags.forEach((flag, index) => {
        flag.operator_id = this.ruleInfo.exception_rules[index].operator_lookup_id;
        flag['operator_lookup_id'] = this.ruleInfo.exception_rules[index].operator_lookup_id;
        flag.value_type_lookup_id = this.ruleInfo.exception_rules[index].value_type_lookup_id;
        flag.rule_sequence_type_lookup_id = this.ruleInfo.exception_rules[index].rule_sequence_type_lookup_id;
        flag.concept_id = this.ruleInfo.exception_rules[index].attribute_id;
        flag.concepts[0] = { attribute_id: this.ruleInfo.exception_rules[index].attribute_id, logical_name: '' };
      });
      this.rule_selected = this.rules[0];
      sessionStorage.setItem('ruleDefintion', JSON.stringify(this.rules[0]));
    }

    if (this.object_id) {
      this.getConcepts(this.object_id, true);
    }

    this.getRuleDetail();

    if (this.router.url.includes('business-rule-definition')) {
      this.createNewRule = true;
    }
    if (this.router.url.includes('create-bussiness-rule')) {
      this.createNewRule = true;
      this.rule_selected.flags = this.rule_selected.flags;
      this.datasetService.getDataQualityDimensions().subscribe(res => {
        this.rule_data_quality_dimension = res;
      })
      this.datasetService.getDataQualitySequenceRules().subscribe(res => {
        this.rule_types = res;
      })
      this.datasetService.getDQValues().subscribe(res => {
        this.value_types = res;
      })
      this.datasetService.getDQOperators().subscribe(res => {
        this.operators = res;
        if (this.rules.length) {
          this.rules[0].flags[0]['dataQualityOperators'] = this.operators
        }
      })
      this.datasetService.getDQDataTypes().subscribe(res => {
        this.dqDataTypes = res;
      })

    } else {
      this.typeRule = "Business Rule";
      // this.rule_id = 40;
      this.activatedRoute.parent.queryParamMap.subscribe(params => {
        this.rule_id = +params.get('rule_id');
        if (this.rule_id > 0) {
          this.getDataQualityRule();
        }
      });
      if (this.createNewRule || this.rule_id > 0) {
        this.datasetService.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe((res) => {
          this.baseService.hasPageRefresh.subscribe(value => {
            if (value) {
              this.activeNavLnkBusiness = "rule-definition";
              sessionStorage.removeItem('activeNavLnkBusiness');
            }
          });

          if (this.router.url.includes('create-bussiness-rule')) {
            this.createNewRule = true;
            this.rule_selected.flags = [this.rule_selected.flags[0]];
            this.datasetService.getDataQualityDimensions().subscribe(res => {
              this.rule_data_quality_dimension = res;
            })
            this.datasetService.getDataQualitySequenceRules()
              .subscribe(res => {
                this.rule_types = res;
              })
            this.datasetService.getDQValues()
              .subscribe(res => {
                this.value_types = res;
              })
            this.datasetService.getDQOperators()
              .subscribe(res => {
                this.operators = res;
                if (this.rules.length) {
                  this.rules[0].flags[0]['dataQualityOperators'] = this.operators
                }
              })
          }

          this.datasetService.getAllDatasets({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id }).subscribe((res) => {
            this.catalogSvc.getHighConceptsDatasets({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalogId, object_id: this.object_id, concept_id: this.concept_id, pageNo: this.page, limit: this.limit }).subscribe((datasetsData) => {
              this.data = datasetsData.currentpage;
              this.isLoading = false;
              this.data.forEach(element => {
                element.id = Math.random();
                element.score100 = (element.score * 100).toFixed();
                element.level = element.score100 >= 75 ? 'HIGH' : (element.score100 >= 50 && element.score100 < 75) ? 'MEDIUM' : 'LOW';
              });
              this.data.sort(((a, b) => b.score100 - a.score100));
            }, err => { });

            if (this.rule_id > 0) {
              // this.getDataQualityRule();
            }

          })
        })
      }
    }

    this.conceptSettings = {
      idField: 'attribute_id',
      textField: 'logical_name',
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.conceptsSettings = {
      idField: 'attribute_id',
      textField: 'logical_name',
      enableCheckAll: false,
      allowSearchFilter: true,
    }
    this.conceptTypeSettings = {
      idField: 'attribute_id',
      textField: 'logical_name',
      enableCheckAll: false,
      allowSearchFilter: true,
      singleSelection: true,
      itemsShowLimit: 1,
    };
    this.datasetdropdownSettings = {
      singleSelection: true,
      idField: 'dataset_id',
      textField: 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.datasetColumndropdownSettings = {
      singleSelection: true,
      idField: 'dataset_column_id',
      textField: 'column',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.multiDatasetSettings = {
      singleSelection: false,
      idField: 'dataset_id',
      textField: 'name',      
      itemsShowLimit: 1,
      enableCheckAll:false,
      allowSearchFilter: true
    };
    this.multiDatasetColumnSettings = {
      singleSelection: false,
      idField: 'dataset_column_id',
      textField: 'column',      
      itemsShowLimit: 1,
      enableCheckAll:false,
      allowSearchFilter: true
    };
  }

  mapExisting(rules) {
    if (rules) {
      this.rules[0] = rules;
    } else {
      this.rules = [
        {
          rule_id: '1',
          rule_name: '',
          rule_description: '',
          dimension_lookup_id: '',
          data_quality_rule_attributes: { attribute_id: [], entity_id: [] },
          semantic_objects: [
            {
              id: '1',
              name: 'Customer'
            }
          ],
          concepts: [],
          flags: [
            {
              sequence: 1,
              rule_type_id: '1',
              rule_name: '',
              concept_id: '',
              concept_name: 'Customer Name',
              concepts: [],
              value_type_id: '17',
              value_type_name: '',
              value_type_lookup_id: 14879,
              rule_sequence_type_lookup_id: 14943,
              comparison_concept_id: '',
              comparison_value_type_lookup_id: 14879,
              comparison_concept_name: 'Customer Number',
              comparison_value_type_id: '1',
              comparison_value_type_name: 'value',
              operator_id: '1',
              operator_name: '',
              threshold: '',
              lower_limit: '',
              upper_limit: '',
              custom_exp: '',
              comparison_custom_exp: '',
              within_list_column_id: '',
              within_source_id: '',
              within_filter: '',
              join_condition: 'AND',
              data_set_name: [],
              column: [],
              within_ds_column_id: '',
              name: 'Single Attribute',
              selected_rule_type: '14943',
              dataQualityOperators: this.operators,
              within_dataset_id:'',
              within_dataset_column_id:'',
            }
          ]
        }
      ];
    }
  }
  getDataQualityRule() {
    this.withindMultiatasetColumns = [];
    this.datasets = [];
    this.activeNavLnkBusiness = sessionStorage.getItem("activeNavLnkBusiness");
    this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id).subscribe(response => {
      this.dataQualityRule = response;
      this.isRuleLoading = false;
      this.total_dqr_attributes = this.dataQualityRule.data_quality_rule_attributes ? this.dataQualityRule.data_quality_rule_attributes.length : 0;
      this.total_dqr_exceptions = this.dataQualityRule.exception_rules.length;
      this.validatebtn();
      if (this.dataQualityRule.data_quality_rule_attributes && this.dataQualityRule.data_quality_rule_attributes.length) {
        this.catalogId = this.dataQualityRule.data_quality_rule_attributes[0].catalog_id;
        this.object_id = this.dataQualityRule.data_quality_rule_attributes[0].entity_id;
        this.concept_id = this.dataQualityRule.data_quality_rule_attributes[0].attribute_id;
      }
      if (this.dataQualityRule.exception_rules.length) {
        this.dataQualityRule.exception_rules.forEach((element, i) => {
          if (element.operator_lookup_id === 14916) {
            element.lower_limit = element.comparison.lower_limit || element.lower_limit;
            element.upper_limit = element.comparison.upper_limit || element.upper_limit;
          }         
          if (element.rule_sequence_type_lookup_id == 14878 && element.value_type_lookup_id != 14894 &&
            element.value_type_lookup_id != 14893 && element.value_type_lookup_id != 14892 &&
            element.value_type_lookup_id != 14891) {
            this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = true;
            this.dataQualityRule.exception_rules[i]['sequence'] = 4;
          } else {
            this.onChangeValueType(element.value_type_lookup_id, i, false);
          }
          if (element.rule_sequence_type_lookup_id == 14878) {
            this.dataQualityRule.exception_rules[i].comparison_value_type_lookup_id = element.comparison.value_type_lookup_id;
            this.dataQualityRule.exception_rules[i].comparison_custom_exp = element.comparison.calculation;
          }
          this.withindMultiatasetColumns.push([]);
        });
      }

      this.contentSvc.getEntities('1', 'all').subscribe(resp => {
        this.entitiesDQ = resp['currentpage'];
        if(this.dataQualityRule.data_quality_rule_attributes && this.dataQualityRule.data_quality_rule_attributes.length){
        this.dataQualityRule.data_quality_rule_attributes.forEach((element, i) => {
          this.contentSvc.getEntityAttributes(element.entity_id).subscribe(respA => {
            this.attributes[i] = respA;
            const attributes = respA.filter(row => row.attribute_id === element.attribute_id);
            if(attributes.length){
              element.attribute_name = attributes[0].logical_name;
            }
          }, err => { });
          const entity_name = this.entitiesDQ.filter(row => row.entity_id === element.entity_id);
          if (entity_name.length) {
            element.entity_name = entity_name[0].name;
          }
          // this.getEntityAttributes(element.entity_id, i);
        });
      }
      }, err => { });

      this.catalogSvc.getDataQualityDimensions().subscribe(respDim => {
        this.dimensionsDQ = respDim;
        this.dataQualityRule.dq_dimension = this.dimensionsDQ.filter(row => row.lookup_id === this.dataQualityRule.dimension_lookup_id)[0].name;
      }, err => { });

      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        ele.rule_sequence_operation = (!ele.rule_sequence_operation || ele.rule_sequence_operation === undefined || ele.rule_sequence_operation === null) ? "AND" : ele.rule_sequence_operation;
        (!ele.rule_sequence_operation || ele.rule_sequence_operation === undefined || ele.rule_sequence_operation === null) ? ele.join_condition = "AND" : ele.join_condition = ele.rule_sequence_operation;
        this.catalogSvc.getDataQualitySequenceRuleTypes('Business').subscribe(respSeq => {
          this.sequencesDQ = respSeq;
          const sequencesDQNames = respSeq.filter(row => row.lookup_id === ele.rule_sequence_type_lookup_id);
          if (sequencesDQNames.length && sequencesDQNames[0].lookup_id === 14878) {
            this._observableService.sendRuleSequenceType = true;
          } else {
            this._observableService.sendRuleSequenceType = false;
          }
          if (sequencesDQNames.length) {
            ele.rule_sequence_type_name = sequencesDQNames[0].column;
          }
        }, err => { });

        this.contentSvc.getEntityAttributes(ele.entity_id).subscribe(respAtt => {
          this.flagAttributes[i] = respAtt;
          const attribute_name = respAtt.filter(row => row.attribute_id === ele.attribute_id);
          if (attribute_name.length) {
            ele.attribute_name = attribute_name[0].logical_name;
          }
        }, err => { });

        this.catalogSvc.getDataQualityValueTypes().subscribe(respVT => {
          this.dataQualityValueTypes = respVT;
          const value_type_name = this.dataQualityValueTypes.filter(row => row.lookup_id === ele.value_type_lookup_id);
          if (value_type_name.length) {
            ele.value_type_name = value_type_name[0].name;
          }
        }, err => { });

        this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
          this.dataQualityOperators = respOp;
          this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = this.dataQualityOperators;

          if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {            
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
            this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
          } else if ( this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_ATTRIBUTE && this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
            this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
          } else {
            this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = respOp;
          }
          const operator_name = this.dataQualityOperators.filter(row => row.lookup_id === ele.operator_lookup_id);
          if (operator_name.length) {
            ele.operator_name = operator_name[0].name;
          }
        }, err => { });

        this.datasetService.getDQDataTypes().subscribe(res => {
          this.dqDataTypes = res;
        })
        this.datasetService.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
          this.datasets = response;
          if (this.router.url.includes('create-bussiness-rule')) {
            this.withinDatasets = response;
          }
          const dataset_name = this.datasets.filter(row => row.dataset_id === ele.within_source_id);
          if (dataset_name.length) {
            ele.dataset_name = dataset_name[0].name;
          }

          // if (ele.within_source_id) {
          //   this.datasetService.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, ele.within_source_id, true).subscribe(response => {
          //     this.dataset_columns = response['currentpage'];
          //     const column_name = this.dataset_columns.filter(row => row.dataset_column_id === ele.within_list_column_id);
          //     if (column_name.length) {
          //       ele.column_name = column_name[0].column;
          //     }
          //   }, err => { });
          // }
        });
      });
      if (this.dataQualityRule && this.dataQualityRule.data_quality_rule_attributes && this.dataQualityRule.data_quality_rule_attributes.length) {
        this.dataQualityRule.data_quality_rule_attributes.forEach((element, i) => {
          this.getEntityAttributes(element.entity_id, i);
        });
      }
      if (this.dataQualityRule && (this.dataQualityRule.default_rule_lookup_id == null || this.dataQualityRule.default_rule_lookup_id.length < 1)) {
        this.customBusinessRule = true;
      } else {
        this.customBusinessRule = false;
      }
      this.addWithinDatasetsAndColumns();
    }, err => { });
    // this.isRuleLoading = false;
  }

  addWithinDatasetsAndColumns() {   
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      if ((element.within_source_id && element.within_list_column_id)) {
        const within_dataset_ids = element.within_source_id.split(',');
        const within_dataset_column_ids = element.within_list_column_id.split(',');
        const within_datasets = [];
        const within_column = [];
        element.within_dataset_id = +within_dataset_ids[0];
        element.within_dataset_column_id = +within_dataset_column_ids[0];
        within_dataset_ids.forEach(dataset_id => {
          const single_dataset_id = dataset_id;
          this.datasetService.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] },
            single_dataset_id).subscribe(respColumns => {
              let columnExits = [];
              if (this.withindMultiatasetColumns && this.withindMultiatasetColumns[i] && this.withindMultiatasetColumns[i].length) {
                columnExits = this.withindMultiatasetColumns[i].filter(ds => ds.dataset_id == dataset_id);
              }
              if (!columnExits.length) {
                respColumns.forEach(element => {
                  if (within_dataset_ids.length > 1 && !element.column.includes('.')) {
                    element.column = element.dataset_name.trim() + '.' + element.column;
                  }
                  this.withindMultiatasetColumns[i].push(element);
                });
              }
              const datasetExits = within_datasets.find(ds => ds.dataset_id == single_dataset_id)
              if (!datasetExits) {
                within_datasets.push({
                  dataset_id: +single_dataset_id,
                  name: respColumns[0].dataset_name.trim()
                });
              }
              within_dataset_column_ids.forEach(column_id => {
                const curr_dataset_column = this.withindMultiatasetColumns[i].find(ds => ds.dataset_column_id == column_id);
                const selectedColumnExist = within_column.find(col => col.dataset_column_id == column_id)
                if (curr_dataset_column && !selectedColumnExist) {
                  within_column.push({
                    dataset_column_id: +column_id,
                    column: curr_dataset_column.column
                  });
                }
              });
              if (within_datasets.length && within_dataset_ids.length == within_datasets.length) {
                this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];
                element.data_set_name = within_datasets;
              }
              if (within_column.length && within_column.length == within_dataset_column_ids.length) {
                element.column = within_column;
              }
            });
        });
      }
    });
  }

  getCatalogDetail() {
    this.catalogSvc.getCatalogs(this.loggedInUserDetails.user_id).subscribe(response => {
      // this.CatalogName = response;
      if (response) {
        this.CatalogName = response;
        if (this.defaultCatalog && this.defaultCatalog.catalog_id) {
          this.changeCatalogName(this.defaultCatalog.catalog_id);
        } else {
          this.defaultCatalog = this.CatalogName.find(item => item.name.toLowerCase() === AppGlobals.DEFAULT_SYSTEM_CATALOG.toLowerCase());
          if (this.defaultCatalog) {
            this.changeCatalogName(this.defaultCatalog['catalog_id']);
          }
        }
      }
    })
  }

  changeCatalogName(valu) {
    this.catalogId = valu;
    this.getEntities(valu)
  }

  getEntities(id) {
    this.catalogSvc.getSemanticsObjects(id)
      .subscribe(res => {
        this.semantic_objects = res
      });
  }

  getEntityAttributes(entityId, i) {
    this.contentSvc.getEntityAttributes(entityId).subscribe(response => {
      if (i !== null) {
        this.attributes[i] = response;
      } else {
        this.attAdd = response;
      }
    }, err => { });
  }

  getFlagEntityAttributes(entityId, i) {
    this.contentSvc.getEntityAttributes(entityId).subscribe(response => {
      if (i !== null) {
        this.flagAttributes[i] = response;
      } else {
        this.attAdd = response;
      }
    }, err => { });
  }

  getDatasetColumn(datasetID, i) {
    this.datasetService.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetID, true).subscribe(response => {
      this.dataset_columns[i] = response['currentpage'];
    }, err => { });

  }

  updateRuleDefination() {
    const isObjectEmpty = (objectName) => {
      return Object.keys(objectName).length === 0
    }
    this.onChange();
    let atLeastOnePrimaryColumn: any = [];
    if(isObjectEmpty(this.payloadForEditRule)) { 
      atLeastOnePrimaryColumn =  this.dataQualityRule['exception_rules'].filter(col => this.dataQualityRule && this.dataQualityRule['data_quality_rule_attributes'] && this.dataQualityRule['data_quality_rule_attributes'].length > 0 && col.attribute_id == this.dataQualityRule['data_quality_rule_attributes'][0].attribute_id);
      //this.onChange(); 
    } else {
      atLeastOnePrimaryColumn = this.payloadForEditRule['exception_rules'].filter(col => this.payloadForEditRule && this.payloadForEditRule['data_quality_rule_attributes'] && this.payloadForEditRule['data_quality_rule_attributes'].length > 0 && col.attribute_id == this.payloadForEditRule['data_quality_rule_attributes'][0].attribute_id);
    }
    // console.log("dataQualityRule :", this.dataQualityRule); 
    if(atLeastOnePrimaryColumn.length) {
      this.catalogSvc.updateBusinessDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, this.payloadForEditRule).subscribe(response => {
        this.messageSvc.sendMessage({ message: 'Changes saved successfully.', type: 'SUCCESS', hideboard: true });      
        this.payloadForEditRule = {};
        setTimeout(() => {
          this.router.navigate(['/zs-cl/dataquality']);
        }, 300);
      }, err => {
        this.messageSvc.sendMessage({ message: err && err.error && err.error.length > 0 && err.error[0].message ? err.error[0].message: 'Something went wrong', type: 'ERROR', hideboard: true });
      });
    } else {
      this.messageSvc.sendMessage({ message: AppGlobals.RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_CONCEPT, type: 'INFO', hideboard: true });
    }
  }

  cancelRuleDefination() {
    this.catalogSvc.isEditView.next(false);
    if (this.rule_id) {
      this.getDataQualityRule();
    }
  }

  onReRunRule() {
    this.updateRunExecutor(true);
    this.catalogSvc.reRunRule(this.rule_id, 'business').subscribe(resp => {
      this.messageSvc.sendMessage({
        message: '', type: 'Info', hideInfo: true, activeJobs: 'DataQuality',
        messageHeader: AppGlobals.RULES_MSG_HEADER, messageText: AppGlobals.RULES_MSG_TEXT,
      });
    });
  }
  updateRunExecutor(is_run_executor) {
    const data = {
      rule_id: this.rule_id,
      payload: {
        run_executor: is_run_executor
      }
    };
    this.catalogSvc.updateRunExecutor(data).subscribe(resp => {
    }, error => { });
  }
  onChangeComparionValueType(val, i) {
    this.dataQualityRule.exception_rules[i].selected_comparison_value_type_lookup_id = val;
    if (this.dataQualityRule.exception_rules[i].hasOwnProperty('comparison_calculation') &&
      this.dataQualityRule.exception_rules[i].comparison_calculation) {
    } else {
      this.dataQualityRule.exception_rules[i].comparison_calculation = '';
    }
    this.onChange();
  }
  onChange() {
    this.validatebtn();
      this.dqr_attributes = [];
    if (this.dataQualityRule.data_quality_rule_attributes && this.dataQualityRule.data_quality_rule_attributes.length) {
      this.dataQualityRule.data_quality_rule_attributes.forEach(ele => {
        this.dqr_attributes.push({ entity_id: ele.entity_id, attribute_id: ele.attribute_id });
      });
    }
      this.dqr_exceptions = [];
    this.dataQualityRule.exception_rules.forEach((ele, i) => {
      this.dqr_exceptions.push({
        rule_sequence_type_lookup_id: ele.rule_sequence_type_lookup_id,
        value_type_lookup_id: ele.value_type_lookup_id,
        entity_id: ele.entity_id,
        attribute_id: ele.attribute_id,
        operator_lookup_id: ele.operator_lookup_id,
        threshold: ele.threshold ? ele.threshold : null,
        ...(ele.operator_lookup_id === 14916 && ele.sequence !== 4) ? { lower_limit: ele.lower_limit } : {},
        ...(ele.operator_lookup_id === 14916 && ele.sequence !== 4) ? { upper_limit: ele.upper_limit } : {},
        ...(this.dataQualityRule.exception_rules.length > 1 && i < this.dataQualityRule.exception_rules.length - 1) ? { rule_sequence_operation: ele.rule_sequence_operation } : {},
        ...(ele.value_type_lookup_id == 14895) ? { calculation: ele.calculation } : {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? {within_source_id: ele.data_set_name && ele.data_set_name.length ? (ele.data_set_name.map(ds => ds.dataset_id)).toString() : []}: {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? {within_list_column_id: ele.column && ele.column.length ? (ele.column.map(ds => ds.dataset_column_id)).toString() : []} : {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? { within_filter: ele.within_filter } : {},
        comparison: {
          ...(ele.sequence === 4) ? { entity_id: this.object_id } : {},
          ...(ele.comparison_value_type_lookup_id == 14895) ? { calculation: ele.comparison_custom_exp } : {},
          ...(ele.sequence === 4) ? { attribute_id: ele.comparison.attribute_id } : {},
          ...(ele.sequence === 4) ? { value_type_lookup_id: ele.comparison_value_type_lookup_id } : {},
          ...(ele.operator_lookup_id === 14916 && ele.sequence === 4) ? { lower_limit: ele.lower_limit } : {},
          ...(ele.operator_lookup_id === 14916 && ele.sequence === 4) ? { upper_limit: ele.upper_limit } : {},
        }
      })
    });
    this.total_dqr_exceptions = this.dataQualityRule.exception_rules.length;

    this.payloadForEditRule = {
      "rule_name": this.dataQualityRule.rule_name,
      "rule_description": this.dataQualityRule.rule_description,
      "dimension_lookup_id": this.dataQualityRule.dimension_lookup_id,
      "data_quality_rule_attributes": this.dqr_attributes,
      "exception_rules": this.dqr_exceptions
    }
  }

  onChangeAtribute(entityId, i) {
    this.getEntityAttributes(entityId, i)
    this.dataQualityRule.data_quality_rule_attributes[i].entity_id = entityId;
    this.onChange();
  }

  onChangeDataset(datasetId, i) {
    this.getDatasetColumn(datasetId, i)
    this.dataQualityRule.exception_rules[i].within_source_id = datasetId;
    this.onChange();
  }
  onChangeWithinDataset(dataset, i) {
    this.withinDatasetColumns[i] = [];
    if(this.dataQualityRule.exception_rules[i].data_set_name.length == 1){
      this.withindMultiatasetColumns[i] = [];
    }
    this.dataQualityRule.exception_rules[i].comparison.within_source_id = dataset.dataset_id;
    this.getIsWithinDatasetColumns(dataset.dataset_id, i);
    this.dataQualityRule.exception_rules[i].column = [];
    this.onChange();
  }
  onDeSelectWithinDatasetNameChange(val, i) {
    if(this.withindMultiatasetColumns[i].length){
      this.withindMultiatasetColumns[i] = this.withindMultiatasetColumns[i].filter(col => col.dataset_name != val.name);
      this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];        
      this.removeDatasetNameFromColumn(i);
    }else{
      this.withinDatasetColumns[i] = []; 
      this.withindMultiatasetColumns[i] = [];  
    }
    this.dataQualityRule.exception_rules[i].column = [];
    this.onChange();
  }
  onChangeWithinDatasetColumn(colId, i) {
    this.dataQualityRule.exception_rules[i].comparison.within_list_column_id = colId;
    this.onChange();
  }
  onDeSelectDatasetEditChange(dataset, i) {
    if(this.withindMultiatasetColumns[i].length){
      this.withindMultiatasetColumns[i] = this.withindMultiatasetColumns[i].filter(col => col.dataset_name != dataset.name);
      this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];   
    }else{
      this.withinDatasetColumns[i] = [];   
    }
    this.dataQualityRule.exception_rules[i].column = [];
    this.validateFlags();
  }
  appendDatasetNameToColumns(i) {
    if ((this.rules[0].flags.length && this.rules[0].flags[i] && this.rules[0].flags[i].data_set_name.length > 1) ||
      (this.dataQualityRule.exception_rules && this.dataQualityRule.exception_rules[i].data_set_name.length > 1)) {
      this.withindMultiatasetColumns[i].forEach(element => {
        if (!element.column.includes('.')) {
          element.column = element.dataset_name.trim() + '.' + element.column;
        }
      });
    }
  }
  getIsWithinDatasetColumns(datasetId, i) {
    if (datasetId > 0) {
      this.datasetService.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
        response.forEach(element => {
          this.withindMultiatasetColumns[i].push(element);
        });        
        this.appendDatasetNameToColumns(i);        
        if (i != undefined && i != null) {
          this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];
        } else {
          this.withinDatasetColumns = this.withindMultiatasetColumns[i];
        }
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.withinDatasetColumns = [];
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }
  onChangeColumn(columnId, i) {
    this.dataQualityRule.exception_rules[i].within_list_column_id = columnId;
    this.onChange();
  }

  onChangeConcept(conceptId, i) {
    this.dataQualityRule.data_quality_rule_attributes[i].attribute_id = conceptId;
    this.onChange();
  }

  onChangeValueType(valueTypeId, i, hasValueTypeChanges = false) {    
    this.dataQualityRule.exception_rules[i].value_type_lookup_id = valueTypeId;   //Multi column
    if (this.dataQualityRule.exception_rules[i].value_type_lookup_id == 14894 || this.dataQualityRule.exception_rules[i].value_type_lookup_id == 14893
      || this.dataQualityRule.exception_rules[i].value_type_lookup_id == 14892
      || this.dataQualityRule.exception_rules[i].value_type_lookup_id == 14891) {
      this.isBetweenSelected = true;
      this.dataQualityRule.exception_rules[i]['operator_lookup_id'] = !hasValueTypeChanges ? this.dataQualityRule.exception_rules[i]['operator_lookup_id'] : null;
      this.dataQualityRule.exception_rules[i]['operator_name'] = !hasValueTypeChanges ? this.dataQualityRule.exception_rules[i]['operator_name'] : null;
      this.dataQualityRule.exception_rules[i]['sequence'] = 4;
      if (this.selectedRuleType == '14878') {
        this.isMultiAttribute = false;
        this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = false;
      }
      this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] = 14878;
      this.ruleTypeCallByValueType(14878, i);
    } else {
      if (this.isBetweenSelected) {
        this.isBetweenSelected = false;
        this.dataQualityRule.exception_rules[i]['sequence'] = 1;
        this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] = 14943;
        this.ruleTypeCallByValueType(14943, i);
      }
    }        
    if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.operators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
      this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
    } else if ( this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_ATTRIBUTE && this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.operators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
      this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
    } else {      
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.operators = respOp;
        this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = this.operators;
      });
    }
    if (hasValueTypeChanges) {
      this.onChange();
    }
  }

  onEditOperatorChange(val, ind) {
    if (val == 14919 || val == 14920) {
      this.dataQualityRule.exception_rules[ind]['sequence'] = 1;
      this.dataQualityRule.exception_rules[ind]['rule_sequence_type_lookup_id'] = 14943;
      this.dataQualityRule.exception_rules[ind]['value_type_lookup_id'] = 14879;
      this.ruleTypeCallByValueType(14943, ind);
    }

    this.onChange();
  }


  onChangeFlagRuleSequenceType(id, i) {
    this.selectedRuleType = id;
    this.dataQualityRule.exception_rules[i].comparison_attribute_id = null;
    this.dataQualityRule.exception_rules[i].rule_sequence_type_lookup_id = id;   //value

    this.dataQualityRule.exception_rules[i].isMultiAttribute = id == 14878 ? true : false;
    this.dataQualityRule.exception_rules[i].sequence = id == 14878 ? 4 : 1;
    this.dataQualityRule.exception_rules[i].selected_rule_type = id;
    this.dataQualityRule.exception_rules[i]['selected_comparison_value_type_lookup_id'] = '';
    this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = 14879;
    if ((this.dataQualityRule.exception_rules[i]['operator_lookup_id'] == 14919 && id == 14878) ||
      this.dataQualityRule.exception_rules[i]['operator_lookup_id'] == 14920 && id == 14878) {
      this.dataQualityRule.exception_rules[i]['operator_lookup_id'] = null;
      this.dataQualityRule.exception_rules[i]['operator_name'] = null;
    }

    if (this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == 14878) {
      if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
        let dqvaluesTypes = this.operators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
        this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
      } else {
        let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
        this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = dqvaluesTypes;
      }
      this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = 14879;
    } else {
      this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = null;
      this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] = 14879;
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.dataQualityOperators = respOp;
      });
      this.dataQualityRule.exception_rules[i]['dataQualityOperators'] = this.dataQualityOperators
    }
    this.onChange();
  }
  ruleTypeCallByValueType(id, i) {
    this.selectedRuleType = id;
    this.dataQualityRule.exception_rules[i].comparison_attribute_id = null;
    this.dataQualityRule.exception_rules[i].rule_sequence_type_lookup_id = id;   //value

    this.dataQualityRule.exception_rules[i].selected_rule_type = id;
    this.dataQualityRule.exception_rules[i]['selected_comparison_value_type_lookup_id'] = '';
    this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = 14879;
    this.onChange();
  }
  onTabClick(activeLink: string) {
    this.activeNavLnkBusiness = activeLink;
    if (this.activeNavLnkBusiness == 'applied-columns') {
      //this.updateAppliedCoumnsData();
    }
    sessionStorage.setItem('activeNavLnkBusiness', activeLink);
    this.catalogSvc.showActiveLink.next(activeLink);
  }

  add() {
    if (this.dqObjectIdAdded && this.dqConceptIdAdded) {
      this.dataQualityRule.data_quality_rule_attributes.push({ entity_id: this.dqObjectIdAdded, attribute_id: this.dqConceptIdAdded });
      this.onChange();
      this.dataQualityRule.data_quality_rule_attributes.forEach((element, i) => {
        this.getEntityAttributes(element.entity_id, i)
        this.attAdd = [];
      });
      this.dqObjectIdAdded = null;
      this.dqConceptIdAdded = null;
    }
  }

  delete(row) {
    this.dataQualityRule.data_quality_rule_attributes.splice(row, 1);
    this.onChange();
    this.dataQualityRule.data_quality_rule_attributes.forEach((element, i) => {
      this.getEntityAttributes(element.entity_id, i);
    });
  }

  addFlag(row) {
    let newAttribute = {
      // entity_id: row > 0 ? this.dataQualityRule.exception_rules[row].entity_id : this.dataQualityRule.data_quality_rule_attributes[0].entity_id, 
      // attribute_id: row > 0 ? this.dataQualityRule.exception_rules[row].attribute_id : this.dataQualityRule.data_quality_rule_attributes[0].attribute_id,
      entity_id: this.dataQualityRule.data_quality_rule_attributes[0].entity_id,
      attribute_id: this.dataQualityRule.data_quality_rule_attributes[0].attribute_id,
      calculation: null,
      lower_limit: null,
      upper_limit: null,
      operator_lookup_id: null,
      rule_sequence_type_lookup_id: 14943,
      threshold: "",
      value_type_lookup_id: 14879,
      within_list_column_id: null,
      within_source_id: null,
      within_filter: null,
      join_condition: "AND",
      dataQualityOperators: this.dataQualityOperators,
      within_dataset_id:'',
      within_dataset_column_id:'',
      comparison: {
        within_list_column_id: null,
        within_source_id: null
      }
    };
    this.dataQualityRule.exception_rules.splice(row + 1, 0, newAttribute);
    this.withindMultiatasetColumns.push([]);
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      this.getFlagEntityAttributes(element.entity_id, i)
      this.attAdd = [];
    });
    this.onChange();
  }

  deleteFlag(row) {
    this.dataQualityRule.exception_rules.splice(row, 1);
    this.onChange();
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      this.getFlagEntityAttributes(element.entity_id, i);
    });
  }

  // addNewSemantic() {
  //   this.rules[0].semantic_objects.push(
  //     { id: '5', 
  //   name: 'Customer Top Level ID' })
  // }

  onFlagAdd(flag) {
    this.rules[0].flags.push(
      {
        sequence: 1,
        rule_type_id: '1',
        rule_name: '',
        concept_id: '',
        concept_name: 'Customer Name',
        concepts: this.defaultConcepts,
        value_type_id: '17',
        value_type_name: '',
        value_type_lookup_id: 14879,
        rule_sequence_type_lookup_id: 14943,
        comparison_concept_id: '',
        comparison_value_type_lookup_id: 14879,
        comparison_concept_name: 'Customer Number',
        comparison_value_type_id: '1',
        comparison_value_type_name: 'value',
        operator_id: '1',
        operator_name: '',
        threshold: '',
        lower_limit: '',
        upper_limit: '',
        custom_exp: '',
        comparison_custom_exp: '',
        within_list_column_id: '',
        within_source_id: '',
        within_filter: '',
        join_condition: 'AND',
        data_set_name: [],
        column: [],
        within_ds_column_id: '',
        name: 'Single Attribute',
        selected_rule_type: '14943',
        dataQualityOperators: this.operators,
        within_dataset_id:'',
        within_dataset_column_id:'',
      }
    );
    this.withindMultiatasetColumns.push([]);
    this.validateFlags();
    this.validateMultiColumn = true;
  }

  onFlagDelete(flag, ind) {
    this.removeFlagInd = ind;
    let currFlag = this.rule_selected.flags[this.removeFlagInd];
    if (currFlag.name !== 'Single Attribute' || (currFlag.operator_name && currFlag.operator_name !== '') || currFlag.value_type_name !== '' || currFlag.value_type_lookup_id) {
      $('#deleteFlag').modal('show');
    };
    this.validateFlags();
  }

  hideFlageDeleteModal() {
    $('#deleteFlag').modal('hide');
  }

  removeFlag() {
    this.rules[0].flags.splice(this.removeFlagInd, 1);
    $('#deleteFlag').modal('hide');
  }

  defineCustomRule() {
    const dqMappingPayload = [];
    let isNotWithin = false;
    const attributeIds = [];
    this.uniqueAttributeIds = [];
    const data = {
      rule_name: this.rules[0].rule_name,
      rule_description: this.rules[0].rule_description,
      dimension_lookup_id: this.rules[0]['dimension_lookup_id'],
      data_quality_rule_attributes: this.mapSemantics(),
      // this.rules[0].data_quality_rule_attributes,
      exception_rules: this.rules[0].flags.map((rule, ind) => {
        if (rule.operator_name === 'is not within') {
          isNotWithin = true;
          dqMappingPayload.push({
            dataset_column_id: +rule.column[0].dataset_column_id,
            attribute_id: rule['concepts'][0]['attribute_id'],
          });
        }
        attributeIds.push(rule['concepts'][0]['attribute_id']);
        // inserting attribute_id for multiattribute rule
        if (rule['sequence'] === 4) {
          attributeIds.push(rule['compConcepts'][0].attribute_id);
        }
        
        return {
          value_type_lookup_id: rule['value_type_lookup_id'],
          operator_lookup_id: rule['operator_lookup_id'],
          entity_id: this.object_id,
          attribute_id: rule['concepts'][0]['attribute_id'],
          rule_sequence_type_lookup_id: rule['rule_sequence_type_lookup_id'],
          ...(rule.operator_name === 'is greater than'
            || rule.operator_name === 'equals' || rule.operator_name === 'does not equal'
            || rule.operator_name === 'is less than' || rule.operator_name === 'is less than or equal to'
            || rule.operator_name === 'contains' || rule.operator_name === 'does not contain'
            || rule.operator_name === 'ends with' || rule.operator_name === 'does not begin with'
            || rule.operator_name === 'begins with' || rule.operator_name === 'is greater than or equal to'
            || rule.operator_name === 'must be greater than, less than, or equal to' || rule.operator_name === 'must be equal to'
            || rule.operator_name === 'column' || rule.value_type_name === 'datatype') ? { threshold: rule.threshold } : {},
          rule_type: rule['name'],
          concept_lookup_id: rule['concepts'][0]['attribute_id'],
          ...(rule.operator_name === 'between' && rule['sequence'] !== 4) ? { lower_limit: rule.lower_limit } : {},
          ...(rule.operator_name === 'between' && rule['sequence'] !== 4) ? { upper_limit: rule.upper_limit } : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_source_id: (rule.data_set_name.map(ds => ds.dataset_id)).toString() } : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_list_column_id: (rule.column.map(ds => ds.dataset_column_id )).toString()} : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_filter: rule.within_filter } : {},
          ...(rule['value_type'] == 'custom calculation') ? { calculation: rule.custom_exp } : {},
          ...(this.rules[0].flags.length > 0 && ind !== this.rules[0].flags.length - 1) ? { rule_sequence_operation: this.rules[0].flags[ind + 1]['join_condition'] } : {},

          comparison: {
            entity_id: this.object_id,
            ...(rule['sequence'] === 4 && rule.operator_name === 'between') ? { lower_limit: rule.lower_limit } : {},
            ...(rule['sequence'] === 4 && rule.operator_name === 'between') ? { upper_limit: rule.upper_limit } : {},
            ...(rule.comparison_value_type_lookup_id == 14895) ? { calculation: rule.comparison_custom_exp } : {},
            ...(rule['sequence'] === 4) ? { attribute_id: rule['compConcepts'][0].attribute_id } : {},
            ...(rule['sequence'] === 4) ? { value_type_lookup_id: rule.comparison_value_type_lookup_id } : {},
          }
        }
      })
    }
    // adding the concept selected in select concept step
    attributeIds.push(this.defaultConceptId);
    const uniqueAttributeIdsSet = new Set(attributeIds);    
    uniqueAttributeIdsSet.forEach(id => {
      this.uniqueAttributeIds.push(id);
    });

    let atLeastOnePrimaryColumn = data['exception_rules'].filter(col => data && data['data_quality_rule_attributes'] && data['data_quality_rule_attributes'].length > 0 && col.attribute_id == data['data_quality_rule_attributes'][0].attribute_id);

    if(atLeastOnePrimaryColumn.length) {
      if (this.ruleExists || this.ruleInfoClone) {
        this.catalogSvc.updateBusinessDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rules[0]['rule_id'], data).subscribe(resp => {
          if (resp) {
            sessionStorage.setItem('ruleDefintion', JSON.stringify(this.rules[0]));
            if (isNotWithin) {
              this.createDQRuleMapping(dqMappingPayload, resp['data_quality_rule_id']);
            } else {
              this.redirectToNext(resp['data_quality_rule_id']);
            }
          }
        }, err => {
          this.messageSvc.sendMessage({ message: (err.error && err.error[0] && err.error[0].message) ? err.error[0].message : err.error.error, type: 'ERROR', hideboard: true });
        })
      } else {
        this.datasetService.createCustomRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], data).subscribe(resp => {
          if (resp) {
            this.rules[0]['rule_id'] = resp['data_quality_rule_id'];
            sessionStorage.setItem('ruleDefintion', JSON.stringify(this.rules[0]));
            if (isNotWithin) {
              this.createDQRuleMapping(dqMappingPayload, resp['data_quality_rule_id']);
            } else {
              this.redirectToNext(resp['data_quality_rule_id']);
            }         
          }
        }, err => {
          this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
        })
        // this.router.navigate(['/zs-cl/rules/create-bussiness-rule/review-mapping'],{queryParams: {rule_id: 32 },  queryParamsHandling: 'merge'});
      }
    } else {
      this.messageSvc.sendMessage({ message: AppGlobals.RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_CONCEPT, type: 'INFO', hideboard: true });
    }
  }

  onSkipAndContinue() {
    this.router.navigate(['/zs-cl/rules/create-bussiness-rule/review-mapping'], { queryParams: { selectedooBrule: this.obRule_ids, attributeIds: this.attributeIds }, queryParamsHandling: 'preserve' });
  }  

  mapSemantics() {
    if (this.rules[0].data_quality_rule_attributes['entity_id'] && this.rules[0].data_quality_rule_attributes['attribute_id']) {
      let combined = this.rules[0].data_quality_rule_attributes['entity_id'].map((item, index) => {
        return {
          entity_id: item.entity_id,
          attribute_id: this.rules[0].data_quality_rule_attributes['attribute_id'][index].attribute_id
        }
      })
      return combined;
    }
  }

  onRuleTypeChange(val, ind) {
    this.selectedRuleType = val;
    this.isMultiAttribute = val == '14878' ? true : false;
    this.rules[0].flags[ind]['isMultiAttribute'] = val == '14878' ? true : false;
    this.rules[0].flags[ind]['selected_rule_type'] = val;
    this.rule_types.forEach(rule => {
      if (rule.lookup_id == val) {
        this.rules[0].flags[ind]['name'] = rule['name'];
        this.rules[0].flags[ind]['sequence'] = rule['sequence'];
      }
    });
    if ((this.rules[0].flags[ind]['operator_lookup_id'] == 14919 && val == 14878) ||
      this.rules[0].flags[ind]['operator_lookup_id'] == 14920 && val == 14878) {
      this.rules[0].flags[ind]['operator_id'] = null;
      this.rules[0].flags[ind]['operator_name'] = null;
    }
    if (this.rules[0].flags[ind]['rule_sequence_type_lookup_id'] == 14878) {
      if (this.rules[0].flags[ind]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
        let dqvaluesTypes = this.operators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
        this.rules[0].flags[ind]['dataQualityOperators'] = dqvaluesTypes;
      } else {
        let dqvaluesTypes = this.operators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
        this.rules[0].flags[ind]['dataQualityOperators'] = dqvaluesTypes;
      }
      this.rules[0].flags[ind]['comparison_value_type_lookup_id'] = 14879;
    }  else {
      this.rules[0].flags[ind]['comparison_value_type_lookup_id'] = null;
      this.rules[0].flags[ind]['value_type_lookup_id'] = 14879;
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.operators = respOp;
      });
      this.rules[0].flags[ind]['dataQualityOperators'] = this.operators;
    }
    if (this.operators.filter(operator => operator.lookup_id == this.rules[0].flags[ind]['operator_lookup_id']).length && this.rules[0].flags[ind]['operator_id'] != null) {
      this.enableNext = true;
    } else {
      this.enableNext = false;
      this.rules[0].flags[ind]['operator_id'] = null;
      this.rules[0].flags[ind]['operator_name'] = null;
    }
    this.validateFlags();
    this.rules[0].flags[ind]['selected_comparison_value_type_name'] = '';
  }
  // this will call when we chnage value type
  onSelectValueChange(val, ind) {
    this.selectedRuleType = val;
    this.rules[0].flags[ind]['selected_rule_type'] = val;
    this.rule_types.forEach(rule => {
      if (rule.lookup_id == val) {
        this.rules[0].flags[ind]['name'] = rule['name'];
        this.rules[0].flags[ind]['sequence'] = rule['sequence'];
      }
    });
    this.validateFlags();
  }
  onValueTypeChange(val, ind) {
    this.value_types.forEach(rule => {
      if (rule.name === val || rule.lookup_id == val) {
        //this.rules[0].flags[ind]['value_type_lookup_id'] = rule['lookup_id'];
        this.rules[0].flags[ind]['value_type'] = rule['name'];
        this.rules[0].flags[ind]['value_type_name'] = rule['name'];
      }
    });
    if (this.rules[0].flags[ind]['value_type'] == 'sum between' || this.rules[0].flags[ind]['value_type'] == 'difference between'
      || this.rules[0].flags[ind]['value_type'] == 'product between'
      || this.rules[0].flags[ind]['value_type'] == 'quotient between') {
      this.rules[0].flags[ind]['operator_id'] = null;
      this.rules[0].flags[ind]['operator_name'] = null;
      this.isBetweenSelected = true;
      this.rules[0].flags[ind]['sequence'] = 4;
      if (this.selectedRuleType == '14878') {
        this.isMultiAttribute = false;
        this.rules[0].flags[ind]['isMultiAttribute'] = false;
      }
      this.rules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14878;
      this.onSelectValueChange(14878, ind);
    } else if (this.rules[0].flags[ind]['value_type'] == 'datatype') {
      this.onOperatorChange('datatype', ind);
    } else {
      if (this.isBetweenSelected) {
        this.isBetweenSelected = false;
        this.rules[0].flags[ind]['sequence'] = 1;
        this.rules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14943;
        this.onSelectValueChange(14943, ind);
      }
    }
    this.rules[0].flags[ind]['selected_comparison_value_type_name'] = '';
    if (this.rules[0].flags[ind]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.operators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
      this.rules[0].flags[ind]['dataQualityOperators'] = dqvaluesTypes;
    } else if ( this.rules[0].flags[ind]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_ATTRIBUTE && this.rules[0].flags[ind]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.operators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
      this.rules[0].flags[ind]['dataQualityOperators'] = dqvaluesTypes;
    } else {
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.operators = respOp;
      });
      this.rules[0].flags[ind]['dataQualityOperators'] = this.operators;
    }
  }

  onCompValueTypeChange(val, ind) {
    this.value_types.forEach(rule => {
      if (rule.name === val) {
        this.rules[0].flags[ind]['comparison_value_type_lookup_id'] = rule['lookup_id'];
        this.rules[0].flags[ind]['selected_comparison_value_type_name'] = val;
      }
    })
  }

  onCompConceptTypeChange(val, ind) {
    this.concepts = this.concepts.filter(conc => conc.attribute_id !== val.attribute_id);
    this.validateFlags();
  }

  onJoinCondChange(val, ind) {
    this.rules[0].flags[ind]['join_condition'] = val;
  }

  onConceptTypeChange(val, ind) {
    this.validateFlags();
    this.validateFlags();
    this.validateMultiColumn = true;
  }

  onOperatorChange(val, ind) {
    this.operators.forEach(operator => {
      if (operator.name === val || operator.lookup_id == val ) {
        this.rules[0].flags[ind]['operator_name'] = operator['name'];
        this.rules[0].flags[ind]['operator_lookup_id'] = operator['lookup_id'];
        if (operator['name'] === 'is within' || operator['name'] === 'is not within') {
          this.getDatasets();
        }
      }
    });
    if (this.rules[0].flags[ind]['operator_name'] == 'is within' || this.rules[0].flags[ind]['operator_name'] == 'is not within') {
      this.rules[0].flags[ind]['sequence'] = 1;
      this.rules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14943;
      this.rules[0].flags[ind]['value_type_lookup_id'] = 14879;
      this.onSelectValueChange(14943, ind);
    }
    if (val == "null") {
      this.enableNext = false;
      this.rules[0].flags[ind]['operator_name'] = null;
      this.rules[0].flags[ind]['operator_lookup_id'] = null;
    } else {
      this.enableNext = true;
    }
    this.validateFlags();
  }

  getDatasets() {
    this.datasetService.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
      this.datasets = response;
      if (this.router.url.includes('create-bussiness-rule')) {
        this.withinDatasets = response;
      }
    });
  }
  getColumns(data, ind) {
    this.datasets.forEach(dataset => {
      if (dataset.name == data) {
        this.getDatasetColumns(dataset['dataset_id']);
        this.rules[0].flags[ind]['data_set_Id'] = dataset['dataset_id'];
      }
    })
  }

  getDatasetColumns(datasetId) {
    if (datasetId > 0) {
      this.datasetService.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId, true).subscribe(response => {
        this.dataset_columns = response['currentpage'];
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }
  
  onDatasetNameChange(dataset, ind) {
    this.withinDatasetColumns[ind] = [];
    this.getIsWithinDatasetColumns(dataset.dataset_id, ind);
    this.rules[0].flags[ind].column = [];
    this.validateFlags();
  }

  validatebtn() {
    if (this.dataQualityRule.rule_name && this.dataQualityRule.dimension_lookup_id && this.dataQualityRule.exception_rules.length) {
      this.isvalidate = true;
      this.dataQualityRule.exception_rules.forEach(flag => {
        if (flag.rule_sequence_type_lookup_id && flag.operator_lookup_id !== null) {
          if (flag.operator_lookup_id === 14919 || flag.operator_lookup_id === 14920) {
            if (flag.data_set_name && flag.data_set_name.length && flag.column && flag.column.length) {
              this.isvalidate = true;
            } else {
              this.isvalidate = false;
              return;
            }
          }
          if (flag.operator_lookup_id === 14916) {
            if (flag.lower_limit && flag.upper_limit) {
              this.isvalidate = true;
            } else {
              this.isvalidate = false;
              return;
            }
          }
          if (flag.value_type_lookup_id === 14952) {
            if (flag.threshold) {
              this.isvalidate = true;
            } else {
              this.isvalidate = false;
              return;
            }
          }
          if ((flag.operator_lookup_id == 14902 || flag.operator_lookup_id == 14903 || flag.operator_lookup_id == 14904 ||
            flag.operator_lookup_id == 14907 || flag.operator_lookup_id == 14908 || flag.operator_lookup_id == 14909 ||
            flag.operator_lookup_id == 14910 || flag.operator_lookup_id == 14911 || flag.operator_lookup_id == 14912 ||
            flag.operator_lookup_id == 14913 || flag.operator_lookup_id == 14914 || flag.operator_lookup_id == 14915 ||
            flag.operator_lookup_id == 14900)) {
            if (!flag.threshold) {
              this.isvalidate = false;
              return;
            } else {
              this.isvalidate = true;
            }
          }
          if (flag.rule_sequence_type_lookup_id === 14878) {
            if (flag.comparison.attribute_id !== null) {
              this.isvalidate = true;
            } else {
              this.isvalidate = false;
              return;
            }
          }
        } else {
          this.isvalidate = false;
          return;
        }
      });
    } else {
      this.isvalidate = false;
      return;
    }
  }
  removeDatasetNameFromColumn(i) {
    if ((this.rules[0].flags.length && this.rules[0].flags[i] && this.rules[0].flags[i].data_set_name.length < 2) ||
      (this.dataQualityRule.exception_rules && this.dataQualityRule.exception_rules[i] && this.dataQualityRule.exception_rules[i].data_set_name.length < 2)) {
      this.withindMultiatasetColumns[i].forEach(element => {
        if (element.column.includes('.')) {
          element.column = element.column.split('.')[1];
        }
      });
    }
  }

  onDeSelectDatasetNameChange(val, i) {
    if(this.withindMultiatasetColumns[i].length){
      this.withindMultiatasetColumns[i] = this.withindMultiatasetColumns[i].filter(col => col.dataset_name != val.name);
      this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];        
      this.removeDatasetNameFromColumn(i);
    }else{
      this.withinDatasetColumns[i] = [];   
    }
    this.rules[0].flags[i].column = [];
    this.validateFlags();
  }
  onEditIsWithinDsColumnChange(val, ind) {
    this.dataQualityRule.exception_rules[ind].comparison.within_ds_column_id = val.dataset_column_id;
    this.onChange();
  }
  onEditDeIsWithinDsColumnChange(val, ind) {
    this.dataQualityRule.exception_rules[ind].comparison.within_ds_column_id = null;
    this.onChange();
  }
  onIsWithinDsColumnChange(val, ind) {
    this.validateFlags();
  }
  onDeIsWithinDsColumnChange(val, ind) {
    this.validateFlags();
  }
  updateColumn(col, ind) {
    this.dataset_columns.forEach(column => {
      if (column.column == col) {
        this.rules[0].flags[ind]['column_id'] = column['dataset_column_id'];
      }
    })
  }

  selectDq(val) {
    this.rule_data_quality_dimension.forEach(operator => {
      if (operator.lookup_id === +val) {
        this.rules[0]['dimension_lookup_id'] = operator['lookup_id'];
        this.rules[0]['dqDimName'] = operator['name'];
        this.rules[0]['dimSequence'] = operator['sequence'];
      }
    });
    if (val == "null") {
      this.enableNext = false;
      this.rules[0]['dimension_lookup_id'] = null;
      this.rules[0]['dqDimName'] = null;
    } else {
      this.enableNext = true;
    }
    this.validateFlags();
  }

  onSemanticChange(val, ind) {
    this.entityId = val;
    this.semantic_objects.forEach(operator => {
      if (operator['entity_id'] == val) {
        this.rules[0]['data_quality_rule_attributes'].entity_id.push({ entity_id: val });
        this.contentSvc.getEntityAttributes(val).subscribe(response => {
          this.concepts = [];
        }, err => { });
        this.selectedEntityID = operator;
        this.getEntityAttributes(val, ind);
      }
    })
    this.getConcepts(val, false);
    this.validateFlags();
  }

  getConcepts(id, needUpdate) {
    if (id) {
      this.catalogSvc.getConcepts(this.catalogId, id, null ,true)
        .subscribe(res => {
          this.concepts = res;
          if (needUpdate) {
            let concept = this.concepts.filter(conc => conc.attribute_id == this.concept_id);
            this.defaultConcepts = concept;
            this.rules[0].flags[0]['concepts'] = concept.map(concept => {
              return {
                attribute_id: concept.attribute_id,
                logical_name: concept.logical_name
              };
            });
          }
        });
    }
  }

  onConceptChange(val, ind) {
    this.conceptId = val.attribute_id;
    this.attributes.forEach(operator => {
      if (operator['logical_name'] == val.logical_name) {
        this.rules[0]['data_quality_rule_attributes'].attribute_id.push({ attribute_id: operator.attribute_id });
        this.selectedAttributeID = operator;
      }
    });
    this.validateFlags();
  }

  onFlagConceptChange(val, ind) {
    this.conceptId = val;
    this.concepts.forEach(operator => {
      if (operator['logical_name'] == val) {
        this.rules[0]['data_quality_rule_attributes'].attribute_id.push({ attribute_id: operator['attribute_id'] });
      }
    });
    this.validateFlags();
  }

  addSemanticsRow() {
    // if(this.rules[0].data_quality_rule_attributes['attribute_id'].length>0 &&
    // this.rules[0]['data_quality_rule_attributes'].entity_id.length>0) {
    this.semantics.push({ id: Math.random() });
    // } 
  }

  removeSemantics(ind) {
    this.semantics.splice(ind, 1);
  }

  onCancel() {
    if (this.router.url.includes('create-bussiness-rule')) {
      $('.modal').modal('hide');
      if (this.dq_rule_level == 'concept') {
        if (this.backTo == 'catalog') {
          this.router.navigate([`/zs-cl/catalogs/${this.catalogId}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        } else {
          this.router.navigate([`/zs-cl/catalogs/${this.catalogId}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
        }
      } else if (this.dq_rule_level == 'catalog') {
        if (this.backTo == 'catalog') {
          this.router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
        } else {
          this.router.navigate([`/zs-cl/catalogs/${this.catalogId}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
        }
      } else if (this.dq_rule_level == 'object') {
        if (this.backTo == 'catalog') {
          this.router.navigate([`/zs-cl/catalogs/${this.catalogId}`], { queryParams: { showBreadcrumb: true } });
        } else {
          this.router.navigate([`/zs-cl/catalogs/${this.catalogId}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
        }
      } else {
        if(this.is_clone) {
          let rule_ids: any = [];
          rule_ids.push(this.ruleInfoClone.data_quality_rule_id)
          this.catalogSvc.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
          sessionStorage.removeItem('ruleInfo');
          sessionStorage.removeItem('ruleInfoClone');
          sessionStorage.removeItem("tRuleDefinition");
        } 
        this.router.navigate(['/zs-cl/dataquality']);
      }
    }
    if (this.router.url.includes('data-quality-rules')) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalogId + '/objects/' + this.object_id + '/concepts/data-quality-rules'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
    }
  }
  navigateBack() {
    if (this.router.url.includes('create-bussiness-rule')) {
      this.router.navigate(['/zs-cl/rules/create-bussiness-rule/out-of-box-rule'], { queryParamsHandling: 'preserve' });
    }
    if (this.router.url.includes('data-quality-rules')) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalogId + '/objects/' + this.object_id + '/concepts/data-quality-rules'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
    }
  }

  getRuleDetail() {
    this.rule_data_quality_dimension = this.dqDimensions;
    this.rule_types = this.dqSequence;
    this.operators = this.dqOperators;
    this.value_types = this.dqValueTypes;
    // this.semantic_objects = this.entities;
    // this.semantics = this.entities;
  }

  navigateDqDetails() {
    if (this.router.url.includes('create-bussiness-rule')) {
      this.router.navigate(['/zs-cl/dataquality']);
      $('.modal').modal('hide');
    }
    if (this.router.url.includes('data-quality-rules')) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalogId + '/objects/' + this.object_id + '/concepts/data-quality-rules'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
    }
  }

  validateFlags() {
    this.rule_selected.flags.forEach(flag => {
      if (flag.sequence == 1) {
        if (flag['operator_name'] && flag['operator_lookup_id'] && flag['concepts']) {
          if (flag.operator_name === 'is greater than'
            || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
            || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
            || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
            || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
            || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
            flag.operator_name === 'must be greater than, less than, or equal to' ||
            flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
            if (!flag.threshold) {
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else if (flag.operator_name === 'between') {
            if ((flag.upper_limit && flag.upper_limit.length > 0) && (flag.lower_limit && flag.lower_limit.length > 0)) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (flag.data_set_name.length && flag.column.length) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else {
            //this.enableNext = true;
          }
        } else {
          this.enableNext = false;
        }
      } else if (flag.sequence == 4) {
        if (flag['operator_name'] && flag['operator_lookup_id'] && (flag['compConcepts'] && flag['compConcepts'][0])) {
          if (flag.operator_name === 'between') {
            if (flag.lower_limit && flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (flag.data_set_name.length && flag.column.length) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'sum between' || flag.value_type === 'difference between'
            || flag.value_type === 'product between' || flag.value_type === 'quotient between') {
            if (flag.operator_name === 'is greater than'
              || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
              || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
              || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
              || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
              || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
              flag.operator_name === 'must be greater than, less than, or equal to' ||
              flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
              if (!flag.threshold) {
                this.enableNext = false;
              } else {
                this.enableNext = true;
              }
            }
          } else {
            this.enableNext = true;
          }
        } else {
          this.enableNext = false;
        }
      }
    })
  }

  onRuleConceptRemove(data, ind) {
    if (this.rules[0].flags[ind]['concept_id'].length > 0) {
      this.validateMultiColumn = true;
      this.validateFlags();
    } else {
      this.validateColumns();
    }
  }

  validateColumns() {
    this.validateMultiColumn = false;
  }

  updateAppliedCoumnsData() {
    this.catalogSvc.getHighConceptsDatasets({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalogId, object_id: this.object_id, concept_id: this.concept_id, pageNo: this.page, limit: this.limit }).subscribe((datasetsData) => {
      this.data = datasetsData.currentpage;
      this.isLoading = false;
      this.data.forEach(element => {
        element.id = Math.random();
        element.score100 = (element.score * 100).toFixed();
        element.level = element.score100 >= 75 ? 'HIGH' : (element.score100 >= 50 && element.score100 < 75) ? 'MEDIUM' : 'LOW';
      });
      this.data.sort(((a, b) => b.score100 - a.score100));
    }, err => { });
  }

  onDataTypeChanged(dataType, ind) {
    if (dataType !== 'Select one') {
      this.rules[0].flags[ind]['threshold'] = dataType;
    } else {
      this.rules[0].flags[ind]['threshold'] = null;
    }
    this.validateFlags();
  }

  onExceptionDataTypeChange(dataType, ind) {
    this.dataQualityRule.exception_rules[ind]['threshold'] = dataType;
    this.onChange();
  }

  onUpdatingJoinCondition(ind, val) {
    this.dataQualityRule.exception_rules[ind]['rule_sequence_operation'] = val;
    this.onChange();
  }

  createDQRuleMapping(dqMappingPayload, rule_id) {
    const payload = [
      {
        catalog_id: +this.catalogId,
        entities: [
          {
            entity_id: +this.object_id,
            mappings: dqMappingPayload
          }
        ]
      }
    ];
    this.catalogSvc.runModelClassify1(payload).subscribe(respMp => {
      const data = {
        rule_id: rule_id,
        payload: {
          is_mapping_changed: true
        }
      };
      this.catalogSvc.updateChangeMappings(data).subscribe(resp => {
        this.redirectToNext(rule_id);
      }, error => {
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
      });
    }, err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
    });
  }

  redirectToNext(rule_id: number) {
    if (this.createNewRule) {
      this.router.navigate(['/zs-cl/rules/create-bussiness-rule/review-mapping'], { queryParams: { rule_id: rule_id,attributeIds: this.uniqueAttributeIds.toString() }, queryParamsHandling: 'merge' });
    }
    if (this.router.url.includes('data-quality-rules')) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalogId + '/objects/' + this.object_id + '/concepts/data-quality-rules/review-mapping'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id,attributeIds: this.uniqueAttributeIds.toString() } });
    }
  }

  // On change DQ Rule name in Edit Mode 
  onChangeRuleName(ruleName) {    
    if (this.ruleNameSelected !== this.dataQualityRule.rule_name) {
      if (this.payloadForEditRule['exception_rules']) {        
        let rule_name = ruleName
        this.payloadForEditRule = { rule_name, ...this.payloadForEditRule };
      } else {
        this.payloadForEditRule = {
          "rule_name": ruleName,
          "exception_rules": []
        };
      }
    }
  }

  onChangeRuleDescription(description) {
    if (this.payloadForEditRule['exception_rules']) {
      let rule_description = description
      this.payloadForEditRule = { rule_description, ...this.payloadForEditRule };
    } else {
      this.payloadForEditRule = {
        "rule_description": description,
        "exception_rules": []
      };
    }
  }

  onChangeDimensions(lookup_id) {
    if (this.payloadForEditRule['exception_rules']) {
      let dimension_lookup_id = lookup_id
      this.payloadForEditRule = { dimension_lookup_id, ...this.payloadForEditRule };
    } else {
      this.payloadForEditRule = {
        "dimension_lookup_id": lookup_id,
        "exception_rules": []
      };
    }
  }
}

