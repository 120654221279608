import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularGridInstance } from "angular-slickgrid";
import { MessageService } from "src/app/common/components/message/message.service";
import { ZsClContentService } from "../../../zs-cl-content.service";
import { environment } from 'src/environments/environment';
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { DatasetService } from "src/app/common/components/datasets/dataset.service";
import { IDataset } from "src/app/common/components/datasets/dataset.model";
import { radioButtonNewFormatter } from "src/app/common/shared/formatters/radioButtonNewFormatter";
declare var $: any;
@Component({
    selector: 'zetta-dataset-column',
    templateUrl: './data-set-coulmns.component.html',
    styleUrls: ['./data-set-coulmns.component.scss']
})
export class DataSetColumnsComponent implements OnInit {
    adhocjoberror: boolean;
    errorMessage;
    isDataReady = false;
    objectList: any;
    gridColumnsDef: any = [];
    tableSettings: object = { 'height': "100%", 'width': "99.40%" };
    gridOptions: any;
    sourceGrid: AngularGridInstance;
    loggedInUserDetails = null;
    project_id: number;
    total = 0;
    catalog_id: number;
    object_id: number;
    page = 1;
    limit = 100;
    source_selected = false;
    hasScrolled = false;
    noData = false;
    project_classifier_id: number;
    column_selected = false;
    dataset_column_id: string;
    dataset_id: string;
    dataset_name: string;
    datasource_id: string;
    showBreadcrumb: Boolean = false;
    project_type_id;
    projecData=null;
    img_logo= '../../../../assets/images/common/spinner-2.gif';
    job_id:string;
    is_algo_called=false;
    intervalId:any;

    constructor(private activatedRoute: ActivatedRoute,
        private contentService: ZsClContentService,
        private zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private datasetService: DatasetService,
        private router: Router) { }

    ngOnInit(): void {
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.projecData = JSON.parse(sessionStorage.getItem('projectData'));
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.parent.paramMap.subscribe(params => {
            this.project_id = +params.get('id');
        });
        this.activatedRoute.queryParamMap.subscribe(param => {
            this.catalog_id = +param.get('catalog_id');
            this.object_id = +param.get('entity_id');
            this.dataset_id = param.get('dataset_id');
            this.datasource_id = param.get('datasource_id');
            this.dataset_column_id = param.get('column_id');
            if(param.has('column_id')){
                this.dataset_column_id = param.get('column_id');
            }else{
                if(this.projecData.hasOwnProperty('project_details')) {
                    if(this.projecData.project_details.hasOwnProperty('sub_concept_source')) {
                        this.dataset_column_id = this.projecData.project_details.sub_concept_source.dataset_column_id;
                    }
                }
            }
        });

        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }
        if (!this.showBreadcrumb) {
            $('#breadcrumb').addClass('d-none');
        }

        this.activatedRoute.queryParamMap.subscribe(param => {
            this.project_type_id = +param['params']['project_type'];
        });
        this.gridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
        };
        this.initColDef();
        this.getDataset();
        this.getSubConceptColumns(this.page, this.limit);
    }
    initColDef() {
        this.gridColumnsDef = [
            {
                'displayname': '',
                'physicalname': 'isChecked',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': radioButtonNewFormatter,
                'minWidth': 30,
                'maxWidth': 40,
            }, {
                'displayname': 'Column',
                'physicalname': 'column',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,

            },
            {
                'displayname': 'Data Type',
                'physicalname': 'column_type',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'maxWidth': 200,
            }
        ];
    }
    getSubConceptColumns(pageNo, limit) {
        const getDataset: IDataset = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            dataset_id: this.dataset_id.toString(),
            pageno: pageNo,
            perpage: limit
        };
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets/${getDataset.dataset_id}/columns?pagination=true&pageno=1&pagesize=${limit}`;
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
            serverFilter = sessionStorage.getItem("serverfilter");
        }
        let serverSort = "";
        if (sessionStorage.getItem("sortfilter")) {
            serverSort = sessionStorage.getItem("sortfilter");
        }
        this.datasetService.getPagedDatasetColumns(getDataset, serverFilter, serverSort).subscribe(resp => {
            this.objectList = resp['currentpage'];
            if (this.objectList.length === 0) {
                this.noData = true;
            }
            this.total = resp.totalrecords;
            if (this.dataset_column_id) {
                this.objectList.forEach(element => {
                    if (element.dataset_column_id == this.dataset_column_id) {
                        this.column_selected = true;
                    }
                    element.isChecked = element.dataset_column_id == this.dataset_column_id ? true : false;
                });
            }
            let concatedRows = this.objectList;
            if (this.sourceGrid) {
                concatedRows = [...this.sourceGrid.dataView.getItems(), ...this.objectList];
                this.objectList.forEach(element => {
                    element.id = Math.random();
                });
                this.objectList = concatedRows;
                this.sourceGrid.dataView.setItems(this.objectList);
                this.sourceGrid.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }
            this.isDataReady = true;
        }, error => {
            this.isDataReady = true;
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });

    }

    onCellClick(e): void {
        const event = e.eventData;
        const args = e.args;
        const row = this.sourceGrid.gridService.getDataItemByRowIndex(args.row);
        if (row) {
            if (event.target.className.includes('radio-formatter')) {
                $('#data-' + args.row).prop('checked', true);
                this.source_selected = true;
                this.dataset_column_id = row.dataset_column_id;
                this.column_selected = true;
            }
        }
    }
    getDataset() {
        const Datasets = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            dataset_id: this.dataset_id.toString()
        };
        this.datasetService.getDataset(Datasets).subscribe((responseList) => {
            if (responseList) {
                this.dataset_name = responseList.name;
            }
        }, err => { });
    }
    saveSubConceptSource() {
        const payload = {
            datset_id: this.dataset_id,
            datasource_id: this.datasource_id,
            dataset_column_id: this.dataset_column_id
        };
        this.contentService.saveSubConceptSource(this.project_id, payload).subscribe(resp => {
            $('#adhoc_model').modal('show');
            this.runAdhocJob();
        }, error => {
            clearInterval(this.intervalId);
            $('#adhoc_model').modal('hide');
            this.messageSvc.sendMessage({ message: error.error.error.message, type: 'ERROR', hideboard: true });
        });
    }
    gridCreated(grid: AngularGridInstance) {
        this.sourceGrid = grid;
    }

    onScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextPage();
        }
    }
    onNextPage(): void {
        this.page++;
        this.getSubConceptColumns(this.page, this.limit);
    }

    onNext() {
        this.saveSubConceptSource();
    }
    onPrev() {
        this.router.navigate(['../source'], { relativeTo: this.activatedRoute, queryParams: { activeLink: '2' }, queryParamsHandling: 'merge' })
    }
    onCancel() {
        this.router.navigate(['/zs-cl/projects']);
    }

    runAdhocJob() {
        const engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
        const algoId = engine_type_id == 451 ? 311 : engine_type_id == 452 ? 413 : '';
        const payload = {
            "input": JSON.stringify({})
        };
        this.datasetService.autoDetectPost(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], payload).subscribe(resp => {
            if (resp.length) {
                const payload = {
                    "classify_project_id": this.project_id,
                    "supervised": false,
                    "classify_picker": true,
                    "job_id": resp[0].job_id,
                };
                this.job_id = resp[0].job_id;
                this.datasetService.getOutputFormAlgo(payload, +algoId).subscribe(data => {
                    console.log(data);
                    if (data) {
                        this.is_algo_called = true;
                        this.getOutputFromAlgo();
                    }
                }, error => {
                    clearInterval(this.intervalId);
                    $('#adhoc_model').modal('hide');
                    this.messageSvc.sendMessage({ message: `Algo ${algoId} call has failed`, type: 'ERROR', hideboard: true });
                });
            }
        }, error => { });
    }
    ngAfterViewInit() {
        this.intervalId = setInterval(() => {
            this.getOutputFromAlgo();
        }, 30000);
    }
    getOutputFromAlgo() {
        if (this.job_id && this.is_algo_called) {
            this.datasetService.getAdhocjobsById(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.job_id).subscribe(resp => {
                if (resp.job_status === 14784) {
                    clearInterval(this.intervalId);
                    $('#adhoc_model').modal('hide');
                    this.adhocjoberror = false;
                    // Navigating to the next screen only when data processing is done
                    this.router.navigate([`/zs-cl/projects/${this.project_id}/concept-training-data`], { queryParams: { activeLink: '1', column_id: this.dataset_column_id }, queryParamsHandling: 'merge' });
                }
                if (resp.job_status == 14785) {
                    this.adhocjoberror = true;
                    this.errorMessage = resp.errorMessage;
                    clearInterval(this.intervalId);
                }

            });
        }
    }
    hideModel() {
        clearInterval(this.intervalId);
        $('#auto_detect_model').modal('hide');
    }
}