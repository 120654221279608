import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from '../zs-cl-content.service';
import { dateFormatter } from 'src/app/common/shared/formatters/dateFormatter';
declare var $: any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'zetta-zs-cl-feedback-summary',
  templateUrl: './zs-cl-feedback-summary.component.html',
  styleUrls: ['./zs-cl-feedback-summary.component.scss']
})

export class ZsClFeedbackSummaryComponent implements OnInit {
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  @Input() showModel: boolean = false;
  @Input() conceptName: string;
  @Input() semanticObjectName: string;
  @Input() datasourceName: string;
  @Input() datasetName: string;
  @Input() datasetColumnName: string;
  @Input() synSemanticObjectName: string;
  @Input() synConceptName: string;
  @Input() screenName: string;
  @Input() votedList;
  @Input() voteValue;
  @Input() catalogID: number;
  @Input() objectID: number;
  @Input() conceptID: number;
  @Input() datasetID: number;
  @Input() datsetColumnID: number;
  @Output() hideFeedbackModel = new EventEmitter<boolean>();
  feedbackSummaryDataOptions: any;
  feedbackSummaryTableColDef: any;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  feedbackSummaryGrid: AngularGridInstance;
  noData = false;
  feedbackSummaryList: any = [];
  isLoading: Boolean = true;
  totalSummaryList = 0;
  pageNo = 1;
  limit = 100;
  hasScrolled = false;

  constructor(public zettaUtils: ZettaUtils,
    private catalogSvc: ZsClContentService) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.feedbackSummaryDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.feedbackSummaryTableColDef = [{
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
    }, {
      'displayname': 'Group',
      'physicalname': 'group_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
    }, {
      'displayname': 'Updated Date',
      'physicalname': 'created_ts',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      formatter: dateFormatter,
      'minWidth': 60,
    }];

    setTimeout(() => {
      if (this.voteValue == 'upVote') {
        if (this.screenName == 'mappedColumn' || this.screenName == 'conceptTechView') {
          this.getFeedbackSummaryUpVoteConcept(1, this.limit);
        } else if (this.screenName == 'allConceptTechView' || this.screenName == 'discoverDataset' || this.screenName === 'datasetClassification') {
          this.getFeedbackSummaryUpVoteObject(1, this.limit);
        } else {
          this.getFeedbackSummary(this.votedList);
        }
      } else {
        if (this.screenName == 'mappedColumn' || this.screenName == 'conceptTechView') {
          this.getFeedbackSummaryDownVoteConcept(1, this.limit);
        } else if (this.screenName == 'allConceptTechView' || this.screenName == 'discoverDataset' || this.screenName === 'datasetClassification') {
          this.getFeedbackSummaryDownVoteObject(1, this.limit);
        } else {
          this.getFeedbackSummary(this.votedList);
        }
      }
    }, 100);
  }

  getFeedbackSummary(votedUserList) {
    this.feedbackSummaryList = votedUserList;
    if (this.feedbackSummaryList.length === 0) {
      this.noData = true;
    }
    this.totalSummaryList = votedUserList.length;

    this.isLoading = false;
    let concatedRows = this.feedbackSummaryList;
    ($('#feedback_summary_modal') as any).modal('show');
    if (this.feedbackSummaryGrid) {
      concatedRows = [...this.feedbackSummaryGrid.dataView.getItems(), ...this.feedbackSummaryList];
      this.feedbackSummaryList = concatedRows;
      this.feedbackSummaryList.forEach(element => {
        element.id = Math.random();
      });
      this.feedbackSummaryGrid.dataView.setItems(this.feedbackSummaryList);
      this.feedbackSummaryGrid.gridService.setSelectedRows([]);
    }
  }

  getFeedbackSummaryUpVoteConcept(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogID}/semantics/${this.objectID}/concepts/${this.conceptID}/mappedcolumns/${this.datsetColumnID}/history?pagination=true&for=up_vote&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    let payload = {
      catalog_id: this.catalogID,
      object_id: this.objectID,
      concept_id: this.conceptID,
      column_id: this.datsetColumnID
    }
    this.catalogSvc.getUpVotedUsersByConcept(payload, pageNo, limit, serverFilter, sortfilter).subscribe((data) => {
      this.feedbackSummaryList = data['currentpage'];
      if (this.feedbackSummaryList.length === 0) {
        this.noData = true;
      }
      this.totalSummaryList = data['totalrecords'];
      
      this.isLoading = false;
      let concatedRows = this.feedbackSummaryList;
      ($('#feedback_summary_modal') as any).modal('show');
      if (this.feedbackSummaryGrid) {
        concatedRows = [...this.feedbackSummaryGrid.dataView.getItems(), ...this.feedbackSummaryList];
        this.feedbackSummaryList = concatedRows;
        this.feedbackSummaryList.forEach(element => {
          element.id = Math.random();
        });
        this.feedbackSummaryGrid.dataView.setItems(this.feedbackSummaryList);
        this.feedbackSummaryGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  getFeedbackSummaryDownVoteConcept(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogID}/semantics/${this.objectID}/concepts/${this.conceptID}/mappedcolumns/${this.datsetColumnID}/history?pagination=true&for=down_vote&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    let payload = {
      catalog_id: this.catalogID,
      object_id: this.objectID,
      concept_id: this.conceptID,
      column_id: this.datsetColumnID
    }
    this.catalogSvc.getDownVotedUsersByConcept(payload, pageNo, limit, serverFilter, sortfilter).subscribe((data) => {
      this.feedbackSummaryList = data['currentpage'];
      if (this.feedbackSummaryList.length === 0) {
        this.noData = true;
      }
      this.totalSummaryList = data['totalrecords'];
      
      this.isLoading = false;
      let concatedRows = this.feedbackSummaryList;
      ($('#feedback_summary_modal') as any).modal('show');
      if (this.feedbackSummaryGrid) {
        concatedRows = [...this.feedbackSummaryGrid.dataView.getItems(), ...this.feedbackSummaryList];
        this.feedbackSummaryList = concatedRows;
        this.feedbackSummaryList.forEach(element => {
          element.id = Math.random();
        });
        this.feedbackSummaryGrid.dataView.setItems(this.feedbackSummaryList);
        this.feedbackSummaryGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  getFeedbackSummaryUpVoteObject(pageNo, limit) {    
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogID}/semantics/${this.objectID}/datasets/${this.datasetID}/history?pagination=true&for=up_vote&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    let payload = {
      catalog_id: this.catalogID,
      object_id: this.objectID,
      concept_id: this.conceptID,
      dataset_id: this.datasetID
    }
    this.catalogSvc.getUpVotedUsersBySemanticObject(payload, pageNo, limit, serverFilter, sortfilter).subscribe((data) => {
      this.feedbackSummaryList = data['currentpage'];
      if (this.feedbackSummaryList.length === 0) {
        this.noData = true;
      }
      this.totalSummaryList = data['totalrecords'];
      
      this.isLoading = false;
      let concatedRows = this.feedbackSummaryList;
      ($('#feedback_summary_modal') as any).modal('show');
      if (this.feedbackSummaryGrid) {
        concatedRows = [...this.feedbackSummaryGrid.dataView.getItems(), ...this.feedbackSummaryList];
        this.feedbackSummaryList = concatedRows;
        this.feedbackSummaryList.forEach(element => {
          element.id = Math.random();
        });
        this.feedbackSummaryGrid.dataView.setItems(this.feedbackSummaryList);
        this.feedbackSummaryGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  getFeedbackSummaryDownVoteObject(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogID}/semantics/${this.objectID}/datasets/${this.datasetID}/history?pagination=true&for=down_vote&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    let payload = {
      catalog_id: this.catalogID,
      object_id: this.objectID,
      concept_id: this.conceptID,
      dataset_id: this.datasetID
    }
    this.catalogSvc.getDownVotedUsersBySemanticObject(payload, pageNo, limit, serverFilter, sortfilter).subscribe((data) => {
      this.feedbackSummaryList = data['currentpage'];
      if (this.feedbackSummaryList.length === 0) {
        this.noData = true;
      }
      this.totalSummaryList = data['totalrecords'];
      
      this.isLoading = false;
      let concatedRows = this.feedbackSummaryList;
      ($('#feedback_summary_modal') as any).modal('show');
      if (this.feedbackSummaryGrid) {
        concatedRows = [...this.feedbackSummaryGrid.dataView.getItems(), ...this.feedbackSummaryList];
        this.feedbackSummaryList = concatedRows;
        this.feedbackSummaryList.forEach(element => {
          element.id = Math.random();
        });
        this.feedbackSummaryGrid.dataView.setItems(this.feedbackSummaryList);
        this.feedbackSummaryGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  onFeedbackSummaryGridCreation(grid) {
    this.feedbackSummaryGrid = grid;
  }

  closeModel() {
    ($('#feedback_summary_modal') as any).modal('hide');
    this.hideFeedbackModel.emit(false);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNextPage();
    }
  }

  onNextPage(): void {
    this.pageNo++;
    if (this.voteValue == 'upVote') {  
      if (this.screenName == 'mappedColumn' || this.screenName == 'conceptTechView') {
        this.getFeedbackSummaryUpVoteConcept(this.pageNo, this.limit);
      } else if (this.screenName == 'allConceptTechView' || this.screenName == 'discoverDataset') {
        this.getFeedbackSummaryUpVoteObject(this.pageNo, this.limit);
      } else {
        this.getFeedbackSummary(this.votedList);
      }
    } else {
      if (this.screenName == 'mappedColumn' || this.screenName == 'conceptTechView') {
        this.getFeedbackSummaryDownVoteConcept(this.pageNo, this.limit);
      } else if (this.screenName == 'allConceptTechView' || this.screenName == 'discoverDataset') {
        this.getFeedbackSummaryDownVoteObject(this.pageNo, this.limit);
      } else {
        this.getFeedbackSummary(this.votedList);
      }
    }  }
}
