import { Column, Formatter } from 'angular-slickgrid';
export const measuredDQFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let dqRuleEnabled = false;
  let loggedInUserDetail = JSON.parse(sessionStorage.getItem('userInfo'));
  if (loggedInUserDetail['enable_dq_rule']) {
    dqRuleEnabled = true;
  }
  let confidenceLevel = '';
  if (dqRuleEnabled) {
    let conf = '';
    let cssLevel = '';
    let fixed = 2;
    fixed = Math.pow(10, fixed);
    const dqScore = dataContext.measured_dq_score;
    const score100 = dqScore != 'n/a' ? Math.floor(dqScore * fixed) / fixed : dqScore;
    if (dqScore != 'n/a') {
      conf = dqScore >= 75 ? 'HIGH' : (dqScore >= 50 && dqScore < 75) ? 'MEDIUM' : 'LOW';
      cssLevel = conf === 'HIGH' ? 'priorityCell high' : conf === 'MEDIUM' ? 'priorityCell medium' : 'priorityCell low';
    }
    if (dataContext.action !== 'add') {
      let mr_left = 'ml-2';
      if (dataContext.disableMr) {
        mr_left = '';
      }
      const disableMr = !dataContext.disableMr ? '' : 'ml-2';
      const spanCss = "ml-1";
      const activeRuleCss = dqScore != 'n/a' ? (dataContext.align_text_center ? `${mr_left} box-cat-obj-dq ${cssLevel}` : `${mr_left} box-cat-obj ${cssLevel}`) : (dataContext.disable_text_center ? 'disable-rule pd-right-40' : (dataContext.align_text_center ? 'disable-rule mt-3' : 'disable-rule'));
      const activeRuleText = dqScore != 'n/a' ? `${score100}%` : 'n/a';
      return confidenceLevel += `<div class="font-wieght ${activeRuleCss} text-center"><span class="${spanCss}">${activeRuleText}</span></div>`;
    }

  } else {
    if (dataContext.action !== 'add') {
      if(dataContext.disable_text_center) {
        return confidenceLevel = `<div class="font-wieght disable-rule text-center pd-right-40"><span class="inactive-text">n/a</span></div>`;
      } else {
        return confidenceLevel = `<div class="font-wieght disable-rule text-center"><span class="inactive-text">n/a</span></div>`;
      }
    }    
  }
}