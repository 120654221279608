import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Formatter, AngularGridInstance, Editors, FieldType, AngularUtilService } from 'angular-slickgrid';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import { ContentService } from '../../../zettasense/content/content.service';
import { Event, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { Object, Concept } from '../classify.model';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { DatasourceService } from '../../../common/components/datasources/datasource.service';
import * as _ from 'lodash';
declare var $: any;
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { AppGlobals } from '../../../common/shared/app.globals';
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { totalRulesFormatter } from 'src/app/common/shared/formatters/totalRulesFormtter';

const DATA_MSG = '';

const tagFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.action === 'edit'){
    let resp = `<div class="text-container"><span class="text-content">
                      <i class="fa fa-plus actionInlineBtn add-tag text-center ml-2"></i> <span class="ml-1 blueLink add-tag">Add Tag</span>
                </span></div>`

    if (dataContext.tags && dataContext.tags != null) {      
      if (dataContext.tags.length) {
        resp = `<div class="text-container d-inline"><span class="text-content"><span><i class="fa fa-plus actionInlineBtn add-tag text-center ml-2" id="tag_${row}"></i>`
        dataContext.tags.forEach(elem => {
          resp += `
                  <button type="button" id="tag_${row}_${elem.semantic_tag_id}" class="mb-1 ml-0 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2" style="height: 21px;">
                      <span class="btn-label btn-label1" style="padding-bottom: 10px !important; color: #777; cursor: default;" title="${elem}">${elem.tag_name}</span>
                      <span class="text-secondary delete-tag" title="${elem.semantic_concept_tag_id}">
                        <i class="fal fa-times delete-tag" style="font-size: 10px" title="${elem.semantic_concept_tag_id}"></i>
                      </span>
                  </button>`;
        }); 
        resp += `</span>
                  <div class="ellipsis tags-ellipsis"><i id="ellipsis_${row}" class="fal fa-ellipsis-h tags-ellipsis"></i></div></span></div>
                  
                `;
      }
      return resp;
    } else {
      return resp;
    }
  }
  


  // if (dataContext.action === 'edit'){
  //   let resp = `<span class="xxxxxxx"><i class="fa fa-plus actionInlineBtn add-tag text-center ml-2"></i> <span class="ml-1 blueLink add-tag">Add Tag</span></span>`
  //   if (dataContext.tags && dataContext.tags != null) {      
  //     if (dataContext.tags.length) {
  //       resp = `<span style="font-size: 20px !important;"><i class="fa fa-plus actionInlineBtn add-tag text-center ml-2" id="tag_${row}"></i>`
  //       // resp = `<i class="fa fa-tag ml-10" id="tag_${row}" aria-hidden="true"></i>`;
  //       dataContext.tags.forEach(elem => {
  //         // if (elem.length > 26) {
  //         //   elem = elem.substring(0, 26) + '...';
  //         // }           
  //         resp += `
  //                 <button type="button" id="tag_${row}_${elem.semantic_tag_id}" class="mb-1 ml-0 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2" style="height: 21px;">
  //                     <span class="btn-label btn-label1" style="padding-bottom: 10px !important; color: #777; cursor: default;" title="${elem}">${elem.tag_name}</span>
  //                     <span class="text-secondary delete-tag" title="${elem.semantic_concept_tag_id}">
  //                       <i class="fal fa-times delete-tag" style="font-size: 10px" title="${elem.semantic_concept_tag_id}"></i>
  //                     </span>
  //                 </button>`;

  //         // resp += `
  //         //         <button type="button" id="tag_${row}_${elem.semantic_tag_id}" class="mb-1 ml-0 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2" style="height: 20px; margin: 0px 0px 1px 0px !important; padding: 0px 10px 10px 0px !important; line-height: 20px !importante;">
  //         //                           <span class="btn-label btn-label1" style="padding-bottom: 10px !important; color: #777;" title="${elem}">${elem.tag_name}</span>
  //         //                           <span class="text-secondary delete-tag" style="padding-bottom: 10px !important; color: #777; opacity:0.7;" title="${elem.semantic_concept_tag_id}" style="font-size: 10px"><i class="fal fa-times delete-tag" title="${elem.semantic_concept_tag_id}"></i></span>
  //         //                        </button>`;

  //       }); 
  //       resp += `</span>`;
  //     }
  //     return resp;
  //   } else {
  //     return resp;
  //   }
  // }
  
  };

const myblueTextFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {      
  let css = '';
  css = row === 0 ? 'selected-row' : '';
  if (columnDef.field === 'logical_name') {
    return `<div id ='concept-${columnDef.field}-${row}' class="mr-left-0 wd-cell ${css}"><div class="blueLink mr-left-10 text-truncate" title="${value}">${value}</div></div>`;
  }
  if (columnDef.field === 'mapped_data_columns') {
    return value ? `<div id ='concept-${columnDef.field}-${row}' class="${css}"><div class="blueColor text-truncate ml-3" title="${value}">${value}</div></div>` : '';
  }
   
};

const mappingFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        if (columnDef.field === 'logical_name' || columnDef.field === 'description') {
           return `<div class="w-100 blueLink" title="${value}">${value}</div>`;
        } else {
          let cssLevel = '';
          let confidenceLevel = '';
          cssLevel = dataContext.level === 'HIGH' ? '' : dataContext.level === 'MEDIUM' ? 'boxM' : 'boxL';
          confidenceLevel += `<div class="box1 ${cssLevel} text-center">${dataContext.level}</div>
                              <div class="porcent text-center">${dataContext.score100}%</div>
                              <div class="handUp text-bold pointer"><i class="fal fa-thumbs-up"></i></div>
                              <div class="handDown text-bold pointer"><i class="fal fa-thumbs-down handDown"></i></div>`;
      
          return confidenceLevel; 
        }
};

const mappingColumnsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let cssLevel = '';
  let confidenceLevel = '';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

  let condLike = !dataContext.enable_up_vote ? 'fas' : 'fal';
  let condUnlike = !dataContext.enable_down_vote ? 'fas' : 'fal';
  cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
  confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
  <div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-2">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-2">${downCount}</span>`;
  return confidenceLevel;
};

export const mappingConfidenceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let cssLevel = '';
  let confidenceLevel = '';
  let condLike = !dataContext.enable_up_vote ? 'fas' : 'fal';
  let condUnlike = !dataContext.enable_down_vote ? 'fas' : 'fal';
  cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

  confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
  <div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-2">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-2">${downCount}</span>`;
  return confidenceLevel;
};

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if( dataContext.action !== AppGlobals.editTxt){
        if (columnDef.field !== AppGlobals.actionTxt) {
            return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v mr-left-3" aria-hidden="true"></i></span>` 
                                         : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v font-22 mr-left-3" aria-hidden="true"></i></span>`;
        } else {
            return ``;
        }
    }else {
        return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v mr-left-3" aria-hidden="true"></i></span>` 
                                     : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v font-22 mr-left-3" aria-hidden="true"></i></span>`;
    }
};

const actionsConceptFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.field === 'action') {
      if (dataContext.action === 'edit') {
        let penClass = '';
        let iscatalogAdmin = sessionStorage.getItem("isCatalogAdmin");
        if (iscatalogAdmin == "true") {
          penClass = 'actionInlineBtn';
        } else {
          penClass = 'disabledEditAction';
        }
        return `<div class="text-center w-100 number" title="${value}">
                    <i id='edit-${row}' class="fal fa-pen ${penClass}"></i>
                </div>`
      } else {
        let iscatalogAdmin = sessionStorage.getItem("isCatalogAdmin");
        if(iscatalogAdmin == 'true') {
        return `<div class="text-center w-100 number" title="${value}">
                  <i class="fa fa-plus actionInlineBtn"></i>
              </div>`
        } else {
          return `<div class="text-center w-100 number" title="${value}">
          <i class="fa fa-plus not-allowed-cursor"></i>
      </div>`
        }
      }
  } else if (columnDef.field === 'mapped_synonyms') {
      if(dataContext.action === 'edit'){
          return value > 0 ? `<div class="text-center pointer pr-3" title='${value}'>
                      <i class="fal fa-link actionInlineBtn blueLink no-underline pr-2"></i><span class="blueLink no-underline">${value}</span>
                  </div>` : `<div class="text-center pointer pr-3" title='${value}'>
                  <i class="fal fa-link actionInlineBtn blueLink no-underline pr-2"></i><span class="disabled">0</span>
              </div>`;
      } else {
          return ``;
      }
  } else if (columnDef.field === 'mapped_data_columns') {
      if(dataContext.action === 'edit'){
          if(value > 0) {
            return `<div class="text-right blueLink pointer mr-5" title='${value}' data-toggle="modal" data-target="#mapped_columns_modal">
                        ${value}
                    </div>`;
          } else {
            return `<div class="text-normal text-right mr-5" title='${value}'>
                        ${value}
                    </div>`;
          }
      } else {
          return ``;
      }
  }  else if (columnDef.field === 'quality_rules') {
    if(dataContext.action === 'edit'){
        if(value > 0) {
          return `<div class="text-right blueLink pointer mr-5" title='${value}'>
                      ${value}
                  </div>`;
        } else {
          return `<div class="text-normal text-right mr-5" title='${value}'>
                      ${value}
                  </div>`;
        }
    } else {
        return ``;
    }
  } else {
    if (dataContext.action === AppGlobals.editTxt) {
      if (dataContext.iscatalogUser == true) {
        return `<div class="text-center w-100 number">
                          <i class="fas fa-graduation-cap actionInlineBtn"></i>
                </div>`
      }
      else {
        return `<div class="text-center w-100 number">
                          <i class="fas fa-graduation-cap not-allowed-cursor"></i>
              </div>`
      }
  } else {
    return ``;
  }
  }
}

const customConceptRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {   
    if( dataContext.action !== 'edit'){
   return  columnDef.name.toLocaleLowerCase()==='definitions'?`<div class="ctm-def-wd definition" ><input id='concept-${columnDef.field}-${row}' class='ctmInputBox custom-input-definition' type='text' placeholder='${columnDef.params.placeholder}' 
      tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value=''></input></div>`:`<div class="box-input ctm-obj-wd concept"><input id='concept-${columnDef.field}-${row}' class='ctmInputBox custom-input-concept' type='text' placeholder='${columnDef.params.placeholder}' 
      tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value=''></input></div>`;
    }else{
        return `<div id="noEdit-${columnDef.field}-${row}" title="${value}" class="w-100 pr-2 pl-1 text-truncate">${value}</div>
                <div id="isEdit-${columnDef.field}-${row}" class="w-100 box-input pr-2" style="display:none">
                    <input id='concept-${columnDef.field}-${row}' title='${value}' class='ctmInputBoxNoEdit d-inline-block' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' 
                        tabindex='${columnDef.params.tabindex}' autofocus="autofocus" autocomplete="off">
                </div>`
    }
};

const customDropDownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
    var options = '';
    var valueName = '';
    if(dataContext.action !== 'edit'){     //Add
            for (var i in columnDef.params.list) {
                    options = options + '<option value=\''+ columnDef.params.list[i].entity_id +'\'>'+ columnDef.params.list[i].semantic_object +'</option>\n';
            }
    }else{     //edit  
            for (var i in columnDef.params.list) {
                if(dataContext.entity_id === columnDef.params.list[i].entity_id) {
                    options = options + '<option selected value=\''+ columnDef.params.list[i].entity_id +'\'>'+ columnDef.params.list[i].semantic_object +'</option>\n';
                    valueName = columnDef.params.list[i].semantic_object;
                } else {
                    options = options + '<option value=\''+ columnDef.params.list[i].entity_id +'\'>'+ columnDef.params.list[i].semantic_object +'</option>\n';
                }
            }
    }
    let placeholder = '';  
    if(columnDef.field === 'name') { placeholder = 'Select Object...' }

    var classList;
    if(dataContext.action !== 'edit'){ classList = 'ctmInputBox' } else { classList = 'ctmInputBoxNoEdit' }
    if( dataContext.action !== 'edit'){        
        return `<div class="selectInput w-91-per d-inline-block object">
                    <select id='concept-entity_id-${row}' class="${classList} select-input-object">
                        <option value="">${placeholder}</option>
                        ${options}
                    </select>
                </div>`;
    }else{
        return `<div id="noEdit-entity_id-${row}" title="${value}" class="w-91-per d-inline-block pr-2 pl-1 text-truncate">
                    ${valueName}
                </div>
                <div id="isEdit-entity_id-${row}" class="w-100 box-input pr-2" style="display:none">
                    <select id='concept-entity_id-${row}' title='${value}' class="${classList}">
                        ${options}
                    </select>
                </div>`
    }
}

const datasourceNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = dataContext.name;
  if (name.includes('"')) {
    name = name.replace(/"/g, "'");
  }
		return `<div class="datasource-name-formatter text-wrap">
				<span title="${name}" class="pointer hyper-link">${name}</span><br>
				</div>`;
};

const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = value;
  if (value.includes('"')) {
    name = value.replace(/"/g, "'");
  }
    return `<div class="datasource-name-formatter text-wrap">
                <span title="${name}" class="pointer hyper-link">${name}</span><br>
            </div>`;
};
const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="formatter match-radio-formatter">
  <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
};
const mappingColumnmDownvoteFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score*100) >= 75 ? 'HIGH' : (dataContext.score*100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score*100);
  let txt = '';

  if(level === 'HIGH') { txt = txt + `<div class="box-left text-center text-uppercase" title='${level}'>${level}</div>`; }
  if(level === 'MEDIUM') { txt = txt + `<div class="box-left boxM text-center text-uppercase" title='${level}'>${level}</div>`; }
  if(level === 'LOW') { txt = txt + `<div class="box-left boxL text-center text-uppercase" title='${level}'>${level}</div>`; }
  
  txt = txt + `<div class="porcent text-center" title='${score100}%'>${score100}%</div>`;
  return txt;
};
const synonymFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div id="syn-${value}">
              <span title='${value}' class="pointer hyper-link">${value}</span>
          </div>`;
  };
const totalRulesFormatter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(dataContext.action !=='add'){
    const enbleRule = value > 0 ? 'blueLink' :'disabled-state'
    return `<div class="text-right w-100 pd-right-20 ${enbleRule}" title="${value}">${value}</div>`;
  }  
}
@Component({
  selector: 'zetta-zs-cl-catalog-object-concepts',
  templateUrl: './zs-cl-catalog-object-concepts.component.html',
  styleUrls: ['./zs-cl-catalog-object-concepts.component.scss'],
})

export class ZsClCatalogObjectConceptsComponent implements OnInit, OnDestroy {

    gridOptions: any;
    eventData: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    conceptsTableSettings: object = { 'height': "100%", 'width': "100%"};
    techTableSettings: object = { 'height': "100%", 'width': "100%" };
    mappedColumntableSettings: object = { 'height': "100%", 'width': "100%" };
    datasorceTableSettings: object = { 'height': "100%", 'width': "100%" };
    datasetTableSettings: object = { 'height': "100%", 'width': "100%" };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    pageLeft = 1;
    limitLeft = 20;
    hasScrolled = false;
    hasMaapedColumnScrolled = false;
    hasTechnicalScrolled = false;
    noData = false;
    noTechnicalData = false;
    loggedInUserDetails: [];
    selectedFiles: string[] = [];
    isDataReady = false;
    isTechnicalDataReady = false;
    entity: any = [];
    showBreadcrumb = false;
    total = 0;
    subTotal = '';
    grandTotal = 0;
    subTotalTechView = '';
    grandTotalTechView = 0;
    totalTechnicalView = 0;
    totalTechnicalViewRecords = 0;
    totalPages = 0;
    responseList: any = [];
    dataRes;
    is_upload_complete = false;
    semantic_object;
    showLoader = true;
    showConceptsLoader: Boolean = true;
    catalog_id;
    object_id;
    concept_id;
    conceptsList: any = [];
    catalog: any = [];
    object: any = [];
    technicalView: any = [];
    gridCon: AngularGridInstance;
    conceptTableColDef: any;
    addConcept: Concept = new Concept();
    addConcepts: any = [];
    selectedConceptMetadata: any;
    lastRowSelected = 0;
    viewMode = "business";

    conceptTechnicalTableColDef: any;
    conceptMappingTableColDef: any;
    gridTechnicalViewOptions: any;
    leftGrid: AngularGridInstance;

    progressState = new ProjectStatus();
    loadCatalog = false;
    isCatalogReady = false;
    objectslistDropDown: any = [];
    datasources: any = [];
    datasets: any = [];
    datasetsTech: any = [];
    mappedColumns: any = [];
    mappedColumnList:any;
    classificationTags: any = [];
    searchClassificationTags: any = [];
    isLoadingDatasets = true;
    isLoadingDatasources = true;
    isLoadingDatasetsTech = true;
    isLoadingMappedColumns = true;
    isLoadingclassificationTags = true;
    isLoadingSearchClassificationTags
    noDataDatasets = false;
    noDataDatasources = false;
    noDataDatasetsTech = false;
    noDataMappedColumns = false;
    showProgressModal: boolean = false;
    public gridDatasources: AngularGridInstance;
    public gridDatasets: AngularGridInstance;
    public gridDatasetsTech: AngularGridInstance;
    public gridMappedColumns: AngularGridInstance;
    public gridConcept:AngularGridInstance;
    public gridClassification:AngularGridInstance;
    datasetsTableColDef: any;
    datasourcesTableColDef: any;
    mappedColumnsTableColDef: any;
    classificationTableColDef:any;
    page = 1;
    pageTechnical = 0;
    limit = 100;
    pageConcepts = 1;
    limitConcepts = 100;
    techViewPageLimit = 100;

    gridDataOptions: any;
    hasDatasetsScrolled = false;
    hasDatasourcesScrolled = false;
    hasDatasetsTechScrolled = false;
    totalDatasources;
    grandTotalDataset = 0;
    subTotalDataset = '';
    totalDatasets;
    totalDatasetsTech;
    totalMappedColumns;
    grandTotalMappedColumn = 0;
    subTotalMappedColumn = '';
    conceptName;
    selectedObjectMetadata: any;
    objectDate: string;
    totalObjects;
    countObjects = 0;
    objectsIdsArr: any = [];
    objectsIds;

    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    public modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;

    selectedTag = "";
    selectedTagOwn = "";
    selectedMappedColumn;
    selectedDsColumn;
    searchTags: any = [];
    isDatasetMapped:boolean=false;
    isDatasourceMapped:boolean=false;
    dataSetModelTarget="";
    dataSourceModelTarget="";
    dsColumnName="";
    semanticObjectName="";
    notSureOption="not_sure";
    isDataQualityMapped:boolean=false;

    showmodelpopup=false;
    showDownvoteModal=false;    
    datasetId:any;
    datasetColumnId;
    mappingType;

    synonyms: any = [];
    totalSynonyms = 0;
    isLoadingSynonyms = false;
    hasSynonymsScrolled = false;
    gridSynonyms: AngularGridInstance;
    synonymsTableColDef: any;
    removing_attribute_id;
    ds_classification:any;
    is_downvote_loading=true;
    old_row=0;
    ddl_catalogs:any=[];
    ddl_semantics:any=[];
    ddl_concepts:any=[];
    ddl_catalogs_id;
    ddl_concept_id;
    showBackdrop:boolean=true;
    showConceptColumn:boolean=true;
    tags=['UI','API','ALGO','QA','ADMIN','UI','API','ALGO','QA','ADMIN','UI','API','ALGO','QA','ADMIN','UI','API','ALGO','QA','ADMIN']    
    filteredTags=[];
    displayTags=[];
    enteredInputTag="";
    @ViewChild('tagInput') tagInput;
    measured_rule_score:string;
    measured_rule_css:string;
    selectedConceptRow;
    selectedRowTooltip;
    emptyRow:any;
    technicalEmptyRow:any;
    showSynonyms=false;
    synCatalogName:string;
    catalogName:string;
    showSynonymsModel=false;
    entity_attribute_id:string;
    user_id:number;
    isFiltering = false;
    isSorting = false;
    isCatalogAdmin: Boolean;
    mappedColumnPageNo=1;
    conceptObjName;
    conceptObjDescription;
    isEditFlow=false;
    showFeedbackSummaryModel: Boolean=false;
    enableRunModel: boolean = false;
    datasetsExist: boolean = false;
    votedUsers: [];
    datasourceName: string;
    datasetName: string;
    datasetColumnName: string;
    screenName: string;
    voteValue: string;
    clearModal;
    catalogData: object;
    is_upload_complete_classify: Boolean = false;
    dataResponse: any;
    isCatalogUser = false;
    fu_type_entityAttributes: any;
    fu_type_importConceptTraining: any;
    mapped_datasets: number;
    istechview: Boolean = false;
    fileType: string = '';    
    revokeProcessing;
    revokeTrack;
    uploadedCatalogData;
    latestJobId;
    trackJobErrorDetails:string;
    constructor(private contentSvc: ContentService,
                private catalogSvc: ZsClContentService,
                private datasetSvc: DatasetService,
                private datasourceSvc: DatasourceService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                public zettaUtils: ZettaUtils,
                private projectService: ProjectService,
                private sanitizer: DomSanitizer,
                private baseService: BaseService,
                private http: HttpClient,
                private messageSvc: MessageService) { }


    ngOnInit() {
      this.fu_type_entityAttributes = AppGlobals.FU_TYPE_ENTITY_ATTRIBUTES;
      this.fu_type_importConceptTraining = AppGlobals.FU_TYPE_IMPORT_CONCEPT_TRAINING;
      sessionStorage.removeItem('serverfilter');
      sessionStorage.removeItem('sortfilter');
      sessionStorage.removeItem("concept_name");
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.uploadedCatalogData = JSON.parse(sessionStorage.getItem('catalog_concepts_data'));        
        this.activatedRoute.parent.params.subscribe(params => {
          this.catalog_id = params['catalog_id'];
          this.loadCatalog = this.catalog_id ? true : false;
          sessionStorage.setItem('catalog_id',this.catalog_id);
        });
        
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');

        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
            this.activatedRoute.parent.params.subscribe(params => {
              this.catalog_id = params['catalog_id'];
            });
            
        } else {
            this.activatedRoute.parent.params.subscribe(params => {
              this.catalog_id = params['catalog_id'];
            });
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }        
      this.activatedRoute.queryParamMap.subscribe(params => {
        if (params.has('catalog_id')) {
          this.catalog_id = params['params']['catalog_id'];
          this.object_id = params['params']['object_id'];
          this.concept_id = params['params']['concept_id'];
        }
        if(params.has('isEdit')){
          this.isEditFlow = params.get('isEdit') === "true" ? true : false;
        }
        this.mapped_datasets = +params.get('mapped_datasets');
        this.istechview = params.get('istechView') === "true" ? true : false;
        if (this.istechview) {
          this.viewMode = 'technical';
          this.getTechnicalView(1, this.techViewPageLimit);
          this.pageTechnical = 1;
        }
      });


      this.catalogSvc.getCatalogUser(this.catalog_id).subscribe(resp => {
        if (resp.length) {
          resp.forEach(user => {
            if (user.user_name == this.loggedInUserDetails['user_name']) {
              this.isCatalogUser = true;
            }
            if (user.user_id == this.loggedInUserDetails['user_id']) {
              const userRoles = user.roles.filter(role => role === AppGlobals.CATALOG_ADMIN);
              if (userRoles.length) {
                this.isCatalogAdmin = true;
              } else {
                this.isCatalogAdmin = false;
              }
              if(this.isEditFlow || this.showBreadcrumb) {
                if(user.roles.includes(AppGlobals.CATALOG_ADMIN) || user.roles.includes(AppGlobals.CATALOG_APPROVER) || user.roles.includes(AppGlobals.CATALOG_REVEIWER)) {
                this.enableRunModel = true;
                } else {
                  this.enableRunModel = false
                }
              }
            }
          });
        }
      });
      if (this.router.url.split('?')[0] == `/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}`) {
        this.router.navigate([`zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: this.showBreadcrumb } })
      }
          this.datasourcesTableColDef = [{
            'displayname': 'Data Source',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasourceNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Description',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 200,
          }];

          this.datasetsTableColDef = [{
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Data Set',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Mapping Confidence Level',
            'physicalname': 'score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': mappingConfidenceFormatter,
            'minWidth': 380,
            'maxWidth': 390,
          }];

          this.mappedColumnsTableColDef = [{
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Data Set',
            'physicalname': 'dataset_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Data Set Column',
            'physicalname': 'column_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            // 'cssClass': 'blueLink',
            // 'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Data Type',
            'physicalname': 'data_type',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 70,
          }, {
            'displayname': 'Mapping Confidence Level',
            'physicalname': 'score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': mappingColumnsFormatter,
            'minWidth': 200,
          }];

          this.synonymsTableColDef = [{
            'displayname': 'Catalog',
            'physicalname': 'catalog_name_left',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
          }, {
            'displayname': 'Semantic Object',
            'physicalname': 'entity_name_left',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
          }, {
            'displayname': 'Concept',
            'physicalname': 'attribute_logical_name_left',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
          }, {
            'displayname': 'Synonym Catalog',
            'physicalname': 'catalog_name_right',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
            // 'columnGroup': 'From Other Catalogs',
            'formatter': synonymFormattter
          }, {
            'displayname': 'Synonym Object',
            'physicalname': 'entity_name_right',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
            // 'columnGroup': 'From Other Catalogs',
          }, {
            'displayname': 'Synonym Concept',
            'physicalname': 'attribute_logical_name_right',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 500,
            'maxWidth': 510,
            // 'columnGroup': 'From Other Catalogs',
          }];
          this.classificationTableColDef = [{
            'displayname': 'Catalog Name',
            'physicalname': 'semantic_object_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',   
            'formatter': datasetNameFormattter,
            'maxWidth': 350,
          },{
            'displayname': 'Semantic Object',
            'physicalname': 'semantic_object_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',   
            'formatter': datasetNameFormattter,
            'maxWidth': 250,
          }, {
            'displayname': '',
            'physicalname': 'radio',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': radioFormatter,
            'minWidth': 30,
            'maxWidth': 30,
            // 'params': this.dataSelected,
          },{
            'displayname': 'Concept',
            'physicalname': 'logical_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
            'maxWidth': 250,
          }, {
            'displayname': 'Mapping Confidence Level',
            'physicalname': '',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': mappingColumnmDownvoteFormatter,
            'minWidth': 200,
            'maxWidth': 390,
          }];

          this.gridDataOptions = {
            enableGridMenu: false,
            selectable: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableFiltering: true,           
          };
      
    
        this.gridOptions = {
            enableGridMenu: false,
            // frozenRow: 1,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            rowHeight: 31,
            // noDataMsg: DATA_MSG,
        };

        this.gridTechnicalViewOptions = {
          enableGridMenu: false,
          // frozenRow: 0,
          enableAddRow: false,
          selectable: true,
          // editable: true,
          enableFiltering: true,
          CheckboxSelector: false,
          enableCellNavigation: true,
          rowHeight: 31           
        };

        this.modalGridOptions = {
          enableGridMenu: false,
          selectable: true,
          enableFiltering: true,
          CheckboxSelector: false,
          enableCellNavigation: true,
          enableRowSelection: true
        };
       
       this.getProgressStates();
        // // this.showBreadcrumb ? this.getConcepts() : this.getAllConcepts();
        // // this.getConcepts();
        // // this.getAllConcepts();
        this.catalogSvc.getSemanticsObjects(this.catalog_id).subscribe(response => {
            response.forEach(item => { 
              this.objectslistDropDown.push(item); 
              this.objectsIdsArr.push(item.entity_id)
            });
            this.objectsIds = this.objectsIdsArr.toString();
            this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
        }, err => { });

        if(this.catalog_id && this.object_id) {
        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe(resp => { 
            this.object = resp;
            this.object.name = this.object.semantic_object;
            this.object.objectDate = this.object["last_updated_date"] ? new Date(this.object["last_updated_date"]).toLocaleDateString() : new Date(Date.now()).toLocaleDateString();
            const ruleObj = this.catalogSvc.getMeasuredRule(resp.measured_dq_score, this.loggedInUserDetails['enable_dq_rule']);
            this.measured_rule_score = ruleObj["dqRuleText"];
            this.measured_rule_css = ruleObj["dqRuleCss"];
            this.progressState.states = this.zettaUtils.getStateList('create-catalog');
            this.progressState.currentStateIndex = 3;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Catalog';
            this.progressState.catalog = this.object;
            this.isDatasetMapped= resp.mapped_data_sets>0? true:false;
            this.dataSourceModelTarget=resp.mapped_data_sources > 0 ?'#datasources_modal':'';            
            this.isDatasourceMapped= resp.mapped_data_sources>0 ? true:false;
            this.dataSetModelTarget=resp.mapped_data_sets > 0 ?'#datasets_modal':'';
            this.isDataQualityMapped=resp.total_rules>0? true:false;
            
            // if(resp.mapped_data_sets>0){
            //   this.isDatasetMapped=true;
            // }else{
            //   this.isDatasetMapped=false;
            // }
            
            
        }, err => { });
        }
        
        // this.getDatasetsTech(this.page, this.limit);
        // // this.getMappedColumns(this.page, this.limit);
        this.initConceptGrid();

        this.searchClassificationTags = this.getSearchClassificationTags();
        this.latestJobId = '';
        setTimeout(() => {
          this.getTooltips();
        }, 3000);
        this.catalogData = {
          catalogId: this.catalog_id,
          objectId: this.object_id,
          catalogLevel: 'semantic'
        };
        this.checkJobQueueStatus(false);
    }


getProgressStates() {       
        this.catalogSvc.getCatalog(this.catalog_id).subscribe(resp => { 
          this.catalog = resp;          
          this.progressState.states = this.zettaUtils.getStateList('create-catalog');
          this.progressState.currentStateIndex = 3;
          this.progressState.currentStateInfo = 'Saved...';
          this.progressState.type = 'Catalog';
          this.progressState.catalog = this.catalog; 
          this.progressState.isEdit = this.isEditFlow;
          this.isCatalogReady = true;
      }, err => { 
        this.isCatalogReady = true;
      });   
}

conceptGrid(grid: AngularGridInstance) {``
  this.gridCon = grid;
}

conceptLeftGrid(grid: AngularGridInstance) {
  this.leftGrid = grid;
}

getConcepts(pageNo, limit = 1) { 
  this.showConceptsLoader = true;
  //this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts?pagination=true&pageno=${1}&pagesize=${limit}`;
  this.conceptsTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts?pagination=true&ignore_system_attributes=true&pageno=${1}&pagesize=${limit}`;
  let serverFilter="";
   if(sessionStorage.getItem("serverfilter")){
      serverFilter=sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
   }else{
    if(this.isFiltering){ 
      pageNo = 1;
      limit = 100;
    }
   }
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   }
    this.catalogSvc.getConceptsPaged(this.catalog_id, this.object_id, pageNo, limit, serverFilter, sortfilter, true).subscribe(resp => {
        this.conceptsList = resp['currentpage'];
        this.showConceptsLoader = false;
        if (this.conceptsList.length === 0) {
            this.noData = true;
        }
      if (this.conceptsList) {
        this.conceptsList = resp['currentpage'].map((item, index) => {         
          item["isRuleEnabled"] = this.loggedInUserDetails["enable_dq_rule"];
          item['action'] = 'edit';
          item['id'] = Math.random();
          if (this.isCatalogUser) {
            item['iscatalogUser'] = true;
          }
          else {
            item['iscatalogUser'] = false;
          }
          return item;
        });
      }
        if(pageNo === 1) {
          const firstRow = this.getAddConceptRow();  
          this.emptyRow= firstRow;        
          this.setNewConceptDetails(firstRow);
          this.conceptsList.splice(0, 0, firstRow);
        }
        this.isDataReady = true;
        this.total = resp['totalrecords'];
        if (pageNo === 1) {
          this.grandTotal = resp['totalrecords'];
        }
        let concatedRows = this.conceptsList;
        if (this.gridCon) {
          concatedRows = [...this.gridCon.dataView.getItems(), ...this.conceptsList];
          this.conceptsList = concatedRows;
          this.gridCon.dataView.setItems(concatedRows);
          this.gridCon.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
    }, err => {
      this.showLoader = false;
        this.isDataReady = true;
        this.showConceptsLoader = false;
        // this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
}

onScrollD(parentClass) {
  if (sessionStorage.getItem("serverfilter")) {
    this.isFiltering = true;
    this.noData = false;
  } else {
    if (sessionStorage.getItem("sortfilter")) {
     // this.isSorting = true
    }
   
    if (this.isFiltering) {
      this.page = 1;
      this.isFiltering = false;
      this.noData = false;
    }
  }

  if(this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasetsScrolled && this.page < this.total){
    this.hasDatasetsScrolled = true;
    this.onNextDs();
  }
}



onScroll(parentClass) {
  if (sessionStorage.getItem("serverfilter")) {
    this.isFiltering = true;
    this.noData = false;
  } else {
    if (this.isFiltering) {
      this.pageConcepts = 1;
      this.isFiltering = false;
      this.noData = false;
    }
  }
  $('.basic-tooltip').hide();
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
  // if (!this.hasScrolled && !this.noData) {
    this.hasScrolled = true;
    this.onNextPage();
  }
}

onScrollTechview(parentClass) { 
  if (sessionStorage.getItem("serverfilter")) {
    this.isFiltering = true;
    this.noTechnicalData = false;
  } else {
    if (sessionStorage.getItem("sortfilter")) {
      this.isSorting = false;
      this.noTechnicalData = false;
    }   
    if (this.isFiltering) {
      this.pageTechnical = 1;
      this.isFiltering = false;
      this.noTechnicalData = false;
    }
  }  
  // if (this.zettaUtils.virtualScroll(parentClass) && !this.hasTechnicalScrolled && !this.noTechnicalData) {
  if (!this.hasTechnicalScrolled && !this.noTechnicalData) {
    if (this.pageTechnical < this.totalPages) {
      this.hasTechnicalScrolled = true;
      this.onNextPageTechView();
    }    
  }
}

onMappedColumnsScroll(parentClass) {
  $('.basic-tooltip').hide();
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasMaapedColumnScrolled && !this.noData) {
    this.hasMaapedColumnScrolled = true;    
    this.onMappedColumnNextPage();
  }
}
onNextPage(): void {
  this.pageConcepts++;
  this.getConcepts(this.pageConcepts, this.limitConcepts);
}

onNextDs(): void {
  this.page++;
  this.getDatasets(this.page,this.limit);
}

onMappedColumnNextPage(): void {
  if(this.mappedColumnPageNo < this.mappedColumnList.totalpages){
    this.mappedColumnPageNo++;
    this.getMappedColumns(this.mappedColumnPageNo, this.limit);
  }
  
}
onNextPageTechView(): void {
  this.pageTechnical++;
  this.getTechnicalView(this.pageTechnical, this.techViewPageLimit);
}

onPrevious() {
  this.router.navigate(['/zs-cl/catalogs/'+ this.catalog.catalog_id+ '/users/objects'], { queryParams: { showBreadcrumb: this.showBreadcrumb },queryParamsHandling:'merge'  });
}
getAllConcepts() {
  this.showConceptsLoader = true;
  this.catalogSvc.getMapConcepts(this.objectsIds, true).subscribe(resp => {
        this.conceptsList = [];
        for(let element in resp){
           this.conceptsList = this.conceptsList.concat(resp[element]);
          }
        const conceptList = this.conceptsList;
        this.total = this.conceptsList.length;
        if (this.gridCon) {
            this.conceptsList = conceptList.map((item) => {
                item['action'] = 'edit';
                item['id'] = Math.random();
                return item;
            });
        }
        this.grandTotal = this.conceptsList.length;
        this.showLoader = false;
        const firstRow = this.getAddConceptRow();
        this.setNewConceptDetails(firstRow);
        this.conceptsList.splice(0, 0, firstRow);
        this.isDataReady = true;
        if(this.gridCon) {
          this.gridCon.dataView.setItems(this.conceptsList);
          this.gridCon.gridService.setSelectedRows([]);
        }
        this.hasScrolled = false;
        this.showConceptsLoader = false;
    }, err => {
        this.isDataReady = false;
        this.showConceptsLoader = false;
        // this.messageSvc.sendMessage({ message: err.error.error.message, type: 'INFO', hideboard: true });
        const firstRow = this.getAddConceptRow();
        this.setNewConceptDetails(firstRow);
        this.conceptsList.splice(0, 0, firstRow);
        this.isDataReady = true;
        this.gridCon.dataView.setItems(this.conceptsList);
        this.gridCon.gridService.setSelectedRows([]);
        this.hasScrolled = false;
        this.showLoader = false;
    });
}

getAddConceptDefaultRow() {
  return { 
      'id': Math.random(), 
      'logical_name': 'All Concepts', 
      'description': '', 
      'mapped_data_columns': this.mapped_datasets, 
      'attribute_id':'',
      'action': 'edit' ,
     
  };
}

  getTechnicalView(pageNo, limit = 1) {
    this.techTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts?pagination=true&pageno=${1}&pagesize=${this.techViewPageLimit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) {
        pageNo = 1;
        limit = 100;
      }
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.catalogSvc.getConceptsPaged(this.catalog_id, this.object_id, pageNo, limit, serverFilter, sortfilter, true).subscribe(respT => {
      this.technicalView = respT["currentpage"];
      if (this.technicalView.length === 0) {
        this.noTechnicalData = true;
      }
      this.totalTechnicalViewRecords = respT["totalrecords"];
      if (pageNo === 1) {
        this.grandTotalTechView = respT['totalrecords'];
        this.totalPages = respT['totalpages'];
      }
      if (this.leftGrid) {
        this.technicalView = respT["currentpage"].map((item) => {
          item['id'] = Math.random();
          return item;
        });
      }
      if (this.technicalView.length > 0 && pageNo === 1) {
        if (pageNo === 1) {
          const firstRow = this.getAddConceptDefaultRow();
          this.technicalEmptyRow = firstRow;
          this.technicalView.splice(0, 0, firstRow);
        }
      }

      this.isTechnicalDataReady = true;

      let concatedRowsT = this.technicalView;
      if (this.leftGrid) {
        if (pageNo > 1) {
          concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.technicalView];
          this.technicalView = concatedRowsT;
        }
        this.leftGrid.dataView.setItems(concatedRowsT);
        this.leftGrid.gridService.setSelectedRows([]);
      }
      this.hasTechnicalScrolled = false;
    }, err => {
      this.isTechnicalDataReady = true;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

handleConceptGridClickEvent(eventData) {
  const parent = this;
  this.eventData = eventData;
  const args = eventData.args;
  const event = eventData.eventData;
  const metadata = this.gridCon.gridService.getColumnFromEventArguments(args);
  this.selectedConceptMetadata = metadata;
  const row = this.gridCon.gridService.getDataItemByRowIndex(args.row);
  const fieldName = metadata.columnDef.field;
  let swapRow;
  let updateRow = false;
  var oldValue: any;
  var newValue: any;
  this.selectedConceptRow = args.row;
  if (row !== undefined && row !== null) {
     sessionStorage.setItem('curr_attribute_id', row.attribute_id);
      let idCell = '#concept-' + fieldName + '-' + args.row;
      if (event.target.className.includes('blueLink') && fieldName === 'mapped_synonyms' && !event.target.className.includes('fal fa-link')) { 
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.showSynonymsModel = true;
        this.user_id =this.loggedInUserDetails['user_id'];
        this.entity_attribute_id =`attribute_id_left=${row.attribute_id}`
         this.conceptName = row.logical_name;
           return;
       }
      this.concept_id = row.attribute_id;
      if(event.target.className.includes('add-tag')){
        this.getAllTag();       
        $('#tagModal').modal('show');
        return;
      }
      if(event.target.className.includes('delete-tag')){   
        this.onDeleteConceptTag(event.target.title, row);
        return;
      }
      if(event.target.className.includes('tags-ellipsis')){    
        $('.basic-tooltip').hide();
        var coordenadas = $("#ellipsis_" + args.row).offset();
        const tooltipElm = document.createElement('div');
        tooltipElm.className = 'basic-tooltip';
        var htmlTags = `<div class="ttTagTitle">Tags</div><div class="ttTagX">X</div><div class="ttTagContent">`;
        row.tags.forEach(ele => {
          htmlTags += `<div class="ttTagItem">${ele.tag_name}</div>`;
        });
        htmlTags += `</div>`
        tooltipElm.innerHTML = htmlTags;
        document.body.appendChild(tooltipElm);
        tooltipElm.style.top = (coordenadas.top + 10) + 'px';
        tooltipElm.style.left = (coordenadas.left + 15) + 'px';
        $(".ttTagX").click(function(){
          $('.basic-tooltip').hide();
        });
        $(".basic-tooltip").mouseleave(function() {
          $('.basic-tooltip').hide();
        });
      }
    if (fieldName === 'total_rules') {
     if (this.loggedInUserDetails['enable_dq_rule'] && row.total_rules > 0) {
        this.catalogSvc.showExceptionsGrid.next({ showException: false, name: "" });
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/' + this.concept_id + '/dq-rules'],
          { queryParams: { catalog_id: this.catalog_id, object_id: this.object_id, concept_id: row.attribute_id, dqRuleLevel: 'concept', concept_type:row.data_type, showBreadcrumb: this.showBreadcrumb } });
      } 

      if(this.loggedInUserDetails['enable_dq_rule'] && !row.total_rules || this.loggedInUserDetails['enable_dq_rule'] && row.total_rules==0) {
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/out-of-box-rule'],{queryParams:{catalog_id:this.catalog_id, object_id:this.object_id,concept_id: row.attribute_id, dqRuleLevel:'concept',concept_type:row.data_type, showBreadcrumb:this.showBreadcrumb,BackTo:'catalog'}});
      }
    }
      if(metadata.dataContext.action === 'edit') {
          if (!event.target.className.includes('fal fa-pen actionInlineBtn') 
                && !event.target.className.includes('fas fa-pen actionInlineBtn') 
                && !event.target.className.includes('fas fa-graduation-cap actionInlineBtn') 
                && fieldName !== 'mapped_data_columns' 
                && fieldName !== 'mapped_data_columns'
                && fieldName !== 'mapped_synonyms'
                && fieldName !== 'quality_rules') {

                const objectX = new Concept();
                
                $('#concept-entity_id-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                $('#concept-logical_name-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                $('#concept-description-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                // $(idCell).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                $('#concept-tags-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                $(idCell).focusout(function(){
                    newValue = $(idCell)[0]['value'];
                    if(fieldName === 'logical_name') { oldValue = row.logical_name; }
                    if(fieldName === 'description') { oldValue = row.description; }
                    if(oldValue !== newValue) {
                        if(fieldName === 'entity_id') { row.entity_id = newValue; }
                        if(fieldName === 'logical_name') { row.logical_name = newValue; }
                        if(fieldName === 'description') { row.description = newValue; }
    
                        const payload = new Concept();
                        payload.entity_id = row.entity_id;
                        if(fieldName === 'entity_id') { payload.entity_id = +newValue }
                        if(fieldName === 'logical_name') { payload.logical_name = newValue }
                        if(fieldName === 'description') { payload.description = newValue }

                        payload.description = $('#concept-description-' + args.row)[0]['value'];
                        payload.logical_name = $('#concept-logical_name-' + args.row)[0]['value'];
                        const concept_name = $('#concept-logical_name-' + args.row)[0]['value'];
                        const concept_desc = $('#concept-description-' + args.row)[0]['value'];
      
                    }

                    $('#concept-entity_id-' + args.row).css({'background-color': '#fff', 'border': '0'});
                    $('#concept-logical_name-' + args.row).css({'background-color': '#fff', 'border': '0'});
                    $('#concept-description-' + args.row).css({'background-color': '#fff', 'border': '0'}); 
                    // $(idCell).css({ 'background-color': '#fff', 'border': '0' });

                });

            } else if (event.target.className.includes('fal fa-pen actionInlineBtn') || event.target.className.includes('fas fa-pen actionInlineBtn')) {
              if (event.target.className.includes('fal fa-pen actionInlineBtn') ) {
                    $('#edit-' + args.row).removeClass('fal fa-pen actionInlineBtn');
                    $('#edit-' + args.row).addClass('fas fa-pen actionInlineBtn');
                    $('#edit-' + args.row).show();

                    $('#noEdit-entity_id-' + args.row).hide();
                    $('#noEdit-logical_name-' + args.row).hide();
                    $('#noEdit-description-' + args.row).hide();
                    $('#isEdit-entity_id-' + args.row).show();
                    $('#isEdit-logical_name-' + args.row).show();

                    $('#isEdit-description-' + args.row).show();
                        const concept_name = $('#concept-logical_name-' + args.row)[0]['value'];
                        const concept_desc = $('#concept-description-' + args.row)[0]['value'];
                        parent.conceptObjName = concept_name;
                        parent.conceptObjDescription = concept_desc;
                } else {
                    $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
                    $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
                    $('#edit-' + args.row).show();

                    $('#noEdit-entity_id-' + args.row).show();
                    $('#noEdit-logical_name-' + args.row).show();
                    $('#noEdit-description-' + args.row).show();
                    $('#isEdit-entity_id-' + args.row).hide();
                    $('#isEdit-logical_name-' + args.row).hide();
                    $('#isEdit-description-' + args.row).hide();

                  // reading updated value from edited concept cell
                  const concept_name = $('#concept-logical_name-' + args.row)[0]['value'];
                  const concept_desc = $('#concept-description-' + args.row)[0]['value'];

                  let payload: any = {};
                  if(parent.conceptObjName == concept_name) {
                    payload.description = concept_desc;
                  } else {
                  payload.logical_name = concept_name;
                  payload.description = concept_desc;
                  }
                    //parent.object_id = parent.object_id ? parent.object_id : row.entity_id;
                    parent.object_id = row.entity_id ? row.entity_id:  parent.object_id;
                    this.pageConcepts = 1;
                    parent.catalogSvc.editConcept(+parent.loggedInUserDetails['user_id'], +parent.catalog_id, parent.object_id, +row.attribute_id, payload).subscribe(responseList => {
                    this.messageSvc.sendMessage({ message: "Changes Saved Successfully", type: 'SUCCESS', hideboard: true });
                    parent.gridCon.dataView.setItems([]);
                    parent.showBreadcrumb ? parent.getConcepts(parent.pageConcepts, parent.limitConcepts) : parent.getAllConcepts();
                  }, err => {
                    parent.gridCon.dataView.setItems([]);
                    parent.showBreadcrumb ? parent.getConcepts(parent.pageConcepts, parent.limitConcepts) : parent.getAllConcepts();
                    this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                  });
                }

            } else if(event.target.className.includes('fas fa-graduation-cap actionInlineBtn')) {
                $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).show();

                $('#noEdit-entity_id-' + this.lastRowSelected).show();
                $('#noEdit-logical_name-' + this.lastRowSelected).show();
                $('#noEdit-description-' + this.lastRowSelected).show();
                $('#isEdit-entity_id-' + this.lastRowSelected).hide();
                $('#isEdit-logical_name-' + this.lastRowSelected).hide();
                $('#isEdit-description-' + this.lastRowSelected).hide();               

                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/review-training'], { queryParams: { object_id: this.object_id, concept_id: row.attribute_id, catalogLevel: 'concept' }, queryParamsHandling: 'merge' });

            } else if(fieldName === 'mapped_data_columns') {
                sessionStorage.removeItem('serverfilter');
                sessionStorage.removeItem('sortfilter');
                $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).show();

                $('#noEdit-entity_id-' + this.lastRowSelected).show();
                $('#noEdit-logical_name-' + this.lastRowSelected).show();
                $('#noEdit-description-' + this.lastRowSelected).show();
                $('#isEdit-entity_id-' + this.lastRowSelected).hide();
                $('#isEdit-logical_name-' + this.lastRowSelected).hide();
                $('#isEdit-description-' + this.lastRowSelected).hide();

                this.conceptName = row.logical_name;
                this.concept_id = row.attribute_id;
                this.getMappedColumns(1, this.limit);
                $('#mapped_columns_modal').modal('show');

            } else if((event.target.className.includes('fal fa-link actionInlineBtn') || fieldName === 'mapped_synonyms') && event.target.className != 'disabled') {
                $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
                $('#edit-' + this.lastRowSelected).show();

                $('#noEdit-entity_id-' + this.lastRowSelected).show();
                $('#noEdit-logical_name-' + this.lastRowSelected).show();
                $('#noEdit-description-' + this.lastRowSelected).show();
                $('#isEdit-entity_id-' + this.lastRowSelected).hide();
                $('#isEdit-logical_name-' + this.lastRowSelected).hide();
                $('#isEdit-description-' + this.lastRowSelected).hide();
                sessionStorage.removeItem('serverfilter');
                sessionStorage.removeItem('sortfilter');
                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/syn-select-data-catalog'], { queryParams: { object_id: this.object_id, concept_id: row.attribute_id } });

            } else if(fieldName === 'quality_rules') {              
              $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
              $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
              $('#edit-' + this.lastRowSelected).show();

              $('#noEdit-entity_id-' + this.lastRowSelected).show();
              $('#noEdit-logical_name-' + this.lastRowSelected).show();
              $('#noEdit-description-' + this.lastRowSelected).show();
              $('#isEdit-entity_id-' + this.lastRowSelected).hide();
              $('#isEdit-logical_name-' + this.lastRowSelected).hide();
              $('#isEdit-description-' + this.lastRowSelected).hide();

              this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules'], { queryParams: { object_id: this.object_id, concept_id: row.attribute_id } });

          }
            

      } else {

          if (event.target.className.includes('fa fa-plus actionInlineBtn')) {
              this.readConceptDetails();
              // validate Attribute Concept
              if (this.validateNewConcept()) {
                  this.addConcept.is_system = false; 
                  const entiyId = this.addConcept.entity_id;
                  const payload = {
                          name: this.addConcept.logical_name,
                          description: this.addConcept.description,
                          entity_id: entiyId
                        }
                  this.catalogSvc.saveConcept(this.loggedInUserDetails['user_id'], this.catalog_id, entiyId, payload).subscribe(responseList => {
                      // this.showBreadcrumb ? this.getConcepts() : this.getAllSemanticsConcepts();
                      this.gridCon.dataView.setItems([]);
                      this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
                  }, err => {
                      // this.conceptsList = [];
                      this.isDataReady = true;
                      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                  });
              }
          }
      }

      if(this.lastRowSelected !== args.row) {
            $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).show();

            $('#noEdit-entity_id-' + this.lastRowSelected).show();
            $('#noEdit-logical_name-' + this.lastRowSelected).show();
            $('#noEdit-description-' + this.lastRowSelected).show();
            $('#isEdit-entity_id-' + this.lastRowSelected).hide();
            $('#isEdit-logical_name-' + this.lastRowSelected).hide();
            $('#isEdit-description-' + this.lastRowSelected).hide();
      }

  }
  this.lastRowSelected = args.row;
}

 // Validate New Attribute Details
 validateNewConcept() {

    let systemAttributes = [];
    if (this.zettaUtils.getSystemAttributes().length) {
      this.zettaUtils.getSystemAttributes().forEach((attributes) => {
          systemAttributes.push(attributes);
          systemAttributes.push(attributes.toLowerCase());
      })
    }
      if (this.addConcept.logical_name && this.addConcept.entity_id) {

         if (systemAttributes.indexOf(this.addConcept.logical_name) > -1) {
          this.messageSvc.sendMessage({ message: 'Oops! cannot add system Attributes', type: 'ERROR', hideboard: true });
          return false;
         }

          return true;
      } else {
          this.messageSvc.sendMessage({ message: 'Please fill the required fields', type: 'INFO', hideboard: true });
          return false;
      }
 }

readConceptDetails() {
    this.addConcept.entity_id = this.showBreadcrumb ? this.object_id : (($('#concept-entity_id-0')[0] && $('#concept-entity_id-0')[0]['value']) ? $('#concept-entity_id-0')[0]['value']:'');
    this.addConcept.logical_name = (($('#concept-logical_name-0')[0] && $('#concept-logical_name-0')[0]['value']) ? $('#concept-logical_name-0')[0]['value']: '');
    this.addConcept.description = (($('#concept-description-0')[0] && $('#concept-description-0')[0]['value']) ? $('#concept-description-0')[0]['value'] : '');    
    // this.addConcept.concatenatedtags = $('#concept-concatenatedtags-0')[0]['value'];
}

setNewConceptDetails(row) {
    row.entity_id = this.addConcept.entity_id;
    row.logical_name = this.addConcept.logical_name;
    row.description = this.addConcept.description;
    // row.concatenatedtags = this.addConcept.concatenatedtags;
}

// Delete Concepts Rows on Delete Action Confirmation
// deleteSelectedRow() {
//   const dataConcept = {
//     user_id: this.loggedInUserDetails['user_id'],
//     catalog_id: this.catalog_id,
//     object_id: this.selectedConceptMetadata.dataContext.object_id
//   }
//   this.catalogSvc.deleteConcept(dataConcept).subscribe(responseList => {
//       this.gridCon.gridService.deleteDataGridItemById(this.selectedConceptMetadata.dataContext.id);
//       this.total = this.total - 1;
//       $('#btnCloseActionModel').click();
//   }, err => {
//   });
// }

initConceptGrid() {

    if (this.showBreadcrumb) {
        this.conceptTableColDef = [
            {
                'displayname': '',
                'physicalname': 'actions',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'cssClass': 'text-right',
                'formatter': actionsFormatter,
                'maxWidth': 13,
            }, {
                'displayname': 'Concepts',
                'physicalname': 'logical_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customConceptRowFormatter,
                'minWidth': 150,
                'maxWidth': 250,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add Concept...', tabindex: 1 }
            }, {
                'displayname': 'Definitions',
                'physicalname': 'description',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customConceptRowFormatter,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add definition...', tabindex: 2 },                
            }, {
              'displayname': 'Tags',
              // 'physicalname': 'tags',
              'physicalname': 'concatenatedtags',
              // 'physicalname': 'physical_name',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'formatter': tagFormatter,
              // 'formatter': customConceptRowFormatter,
              'params': { placeholder: '', tabindex: 3 },
              'minWwidth': 90,
              // 'maxWidth': 600,
          },{
                'displayname': 'Actions',
                'physicalname': 'action',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsConceptFormatter,
                'cssClass': 'text-center',
                'headerCssClass': 'text-center pr-1 mr-top-1',
                'maxWidth': 65,
            }, {
                'displayname': 'Synonyms',
                'physicalname': 'mapped_synonyms',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsConceptFormatter,
                'maxWidth': 90,
            }, {
                'displayname': 'Mapped Data Columns',
                'physicalname': 'mapped_data_columns',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': actionsConceptFormatter,
                'cssClass': 'text-right',
                'headerCssClass': 'text-left titleBreak pt-0 pb-0 pr-3 toRight',
                'minWidth': 90,
                'maxWidth': 90
            }, {
                'displayname': 'Train Model',
                'physicalname': 'train',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsConceptFormatter,
                'headerCssClass': 'mr-top-1',
                'maxWidth': 90,
            }, {
              'displayname': '# of Data Quality Rules',
              'physicalname': 'total_rules',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'formatter': totalRulesFormatter,
              'cssClass': `text-right ${this.loggedInUserDetails['enable_dq_rule'] ? 'blueLink':'disabled-state'}`,
              'headerCssClass': 'text-left titleBreak pt-0 pb-0 pr-3 toRight',
              'minWidth': 90,
              'maxWidth': 90
          },{
            'displayname': 'Measured Data Quality',
            'physicalname': 'measured_dq_score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': measuredDQFormatter,
            'cssClass': 'text-right',
            'headerCssClass': `text-left titleBreak pt-0 pb-0 pr-3 toRight ${this.loggedInUserDetails['enable_dq_rule'] ? '':'disabled'}`,
            'minWidth': 90,
            'maxWidth': 90
        },
        ];
    } else {
        this.conceptTableColDef = [
            {
              'displayname': '',
              'physicalname': 'actions',
              'sortable': false,
              'datatype': 'String',
              'filterable': false,
              'cssClass': 'text-right',
              'formatter': actionsFormatter,
              'maxWidth': 13,
            }, 
            {
              'displayname': 'Semantic Object',
              'physicalname': 'name',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'formatter': customDropDownFormatter,
              'minWidth': 150,
              'maxWidth': 250,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              'params': { list: this.objectslistDropDown, tabindex: 1 }
          }, {
              'displayname': 'Concepts',
              'physicalname': 'logical_name',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'formatter': customConceptRowFormatter,
              'minWidth': 150,
              'maxWidth': 250,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              'params': { placeholder: 'Add Concept...', tabindex: 2 }
          }, {
              'displayname': 'Definitions',
              'physicalname': 'description',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              'formatter': customConceptRowFormatter,
              'params': { placeholder: 'Add definition...', tabindex: 3 }
          },{
              'displayname': 'Actions',
              'physicalname': 'action',
              'sortable': false,
              'datatype': 'String',
              'filterable': false,
              'formatter': actionsConceptFormatter,
              'cssClass': 'text-center',
              'headerCssClass': 'text-center pr-1',
              'maxWidth': 65,
          }
        ];
    }
  
  this.conceptTechnicalTableColDef = [
    {
      'displayname': 'Concepts',
      'physicalname': 'logical_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
      'formatter': myblueTextFormattter,
    }, {
      'displayname': 'Mappings',
      'physicalname': 'mapped_data_columns',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': myblueTextFormattter,
    }
  ];
  this.isDataReady = true;
}

getAddConceptRow() {
    if (this.showBreadcrumb) {
        return { 
            'id': Math.random(), 
            'logical_name': '', 
            'description': '', 
            // 'concatenatedtags': '', 
            'mapped_data_columns': '0', 
            'action': 'add' ,
            'train': 'train'
        };
    } else {
        return { 
            'id': Math.random(), 
            'entity_id': 'Select Object...',
            'logical_name': '', 
            'description': '', 
            // 'concatenatedtags': '',
            'mapped_data_columns': '0', 
            'action': 'add' ,
            'train': 'train'
        };
    }
}

getPaggedConcept(page, limit) {
  this.readConceptDetails();
  this.catalogSvc.getConcepts(this.catalog_id, this.object_id, null, false).subscribe(resp => {
      this.conceptsList = resp;
      if (this.conceptsList.length === 0) {
          this.noData = true;
      }
      let concatedRows = this.conceptsList;
      if (this.gridCon) {
          concatedRows = [...this.gridCon.dataView.getItems(), ...this.conceptsList];
          this.conceptsList = resp.map((item) => {
              item['action'] = 'edit';
              item['id'] = Math.random();
              return item;
          });
      }

      const firstRow = this.getAddConceptRow();
      this.setNewConceptDetails(firstRow);
      this.conceptsList.splice(0, 0, firstRow);
      this.isDataReady = true;
      this.conceptsList = concatedRows;
      this.gridCon.dataView.setItems(concatedRows);
      this.gridCon.gridService.setSelectedRows([]);
      this.gridCon.dataView.refresh();
      this.hasScrolled = false;
  }, err => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
  });

}

openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.gridCon.gridService.getDataItemByRowIndex(args.row);

    if (row.isdisable) {
      $('#context_menu').hide();
    } else {
      $('#context_menu')
        .removeClass('d-none')
        .data('row', args.row)
        .css('top', eventEle.pageY)
        .css('left', eventEle.pageX + 5)
        .css('position', 'fixed')
        .css('display', 'block')
        .show();
    }
    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.gridCon.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      const env = JSON.parse(sessionStorage.getItem('userApp'));      
      const attribute_id = sessionStorage.getItem('curr_attribute_id');
      if (action === 'Delete' && attribute_id == row.attribute_id && parent.isCatalogAdmin) {
        const new_object_id = parent.object_id ? parent.object_id : row.entity_id;
        parent.deleteRow(parent.catalog_id, new_object_id, row.attribute_id);
      }
    });
  }

  deleteRow(catalogId, objectId, attributeId) {
    const parent = this;
    const user_id = this.loggedInUserDetails['user_id'];
    const deleteAttribute: any = {
      user_id: user_id,
      catalog_id: +catalogId,
      object_id: +objectId,
      attribute_id: +attributeId,
    };
    parent.catalogSvc.deleteConcept(deleteAttribute).subscribe((result) => {
      this.conceptsList = [];
      if(this.gridCon) {
        this.gridCon.dataView.setItems(this.conceptsList);
        this.gridCon.gridService.setSelectedRows([]);
      }
      this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
    }, (error) => {
      this.messageSvc.sendMessage({ message: 'Delete concept has failed', type: 'INFO', hideboard: true });
    });
  }

    ngOnDestroy() {
        // if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        // }
        $('.basic-tooltip').hide();
        this.messageSvc.clearMessage();
        this.clearProcessingData();
        this.clearTrackData();
        this.latestJobId = '';
    }

  fileUploaded() {
    if (this.is_upload_complete) {
      if (this.isEditFlow) {
        this.router.navigate(['/zs-cl/catalogs']);
      } else {
        if (this.gridCon) {
          this.showConceptsLoader = true;
          this.conceptsList = [];
          this.gridCon.dataView.setItems(this.conceptsList);
          this.getConcepts(1, this.limitConcepts);
          this.clearModal = Math.random();
        }
      }
    }
  }

  cancelImportConcept() {
    this.clearModal = Math.random();
  }

  receiveFileName($event) {
    if ($event && $event['showProcessingModel']) {
      this.fileType = $event.fileType;
      $('#uploadFileModal').modal('hide');
      this.triggerAlgoToProcessFile();
      this.clearModal = Math.random();
      sessionStorage.setItem('catalog_concepts_data', JSON.stringify({ "showProgress": true, "catalog_id": this.catalog_id, "object_id": this.object_id, fileUploadEventData: $event }));
      this.showProgressModal = true;
      $('#processing_model').modal('show');
    } else {
      if ($event === AppGlobals.FILE_UPLOADED) {
        if (this.is_upload_complete) {
          if (this.gridCon) {
            this.conceptsList = [];
            this.gridCon.dataView.setItems(this.conceptsList);
          }
          this.catalogSvc.getSemanticsObjects(this.catalog_id).subscribe(response => {
            this.objectsIdsArr = [];
            response.forEach(item => {
              this.objectslistDropDown.push(item);
              this.objectsIdsArr.push(item.entity_id);
            });
            this.objectsIds = this.objectsIdsArr.toString();
            this.showBreadcrumb ? this.getConcepts(1, this.limitConcepts) : this.getAllConcepts();
          });
          $('#uploadFileModal').modal('hide');
          this.clearModal = Math.random();
        }
      } else {
        this.dataRes = $event
        this.is_upload_complete = this.dataRes.is_upload_complete;
        if (this.is_upload_complete) {
          if (this.isEditFlow) {
            this.catalogSvc.getSemanticsObjects(this.catalog_id).subscribe(response => {
              this.objectsIdsArr = [];
              response.forEach(item => {
                this.objectslistDropDown.push(item);
                this.objectsIdsArr.push(item.entity_id);
              });
              this.objectsIds = this.objectsIdsArr.toString();
              this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
            }, err => { });
          }
        }
      }
    }
  }

    changeView(viewMode) {
      if(viewMode === 'technical') {
        $('.basic-tooltip').hide();
        this.viewMode = 'technical';
        this.getTechnicalView(1,this.techViewPageLimit);
        this.pageTechnical = 1;
      } else {    
        this.router.navigate([`zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: this.showBreadcrumb } })
        this.viewMode = 'business';
      }
      
    }


    getDatasources() {
        const getDatasources: any = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalog_id,
            object_id: this.object_id,
        };
        this.catalogSvc.getDatasources(getDatasources).subscribe((data) => { 
            this.datasources = data;
            if (this.datasources.length === 0) {
            this.noData = true;
            }
            this.totalDatasources = this.datasources.length;
            this.isLoadingDatasources = false;
            if (this.gridDatasources) {
              this.datasources.forEach(element => {
                  element.id = Math.random();
              });
              this.gridDatasources.dataView.setItems(this.datasources);
              this.gridDatasources.gridService.setSelectedRows([]);
              this.hasDatasourcesScrolled = false;
            }
        }, err => { });
      }

      onDatasourcesGridCreation(grid) {
        this.gridDatasources = grid;
      }

      viewDatasourcesClick() {
        this.getDatasources();
      }

      onDatasourceCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.gridDatasources.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
            if (eventEle.target.title === row.name) {
                $('#datasources_modal').modal('hide');
                $('#datasources_modal').addClass('d-none');
                this.router.navigate(['/zs/datasources', row.datasource_id]);
            }
        }
      }

      clearDatasources() {
        this.gridDatasources.dataView.setItems([]);
      }

      onDatasourcesScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasourcesScrolled && !this.noData) {
          this.hasDatasourcesScrolled = true;
          this.onDatasourcesNext();
        }
      }

      onDatasourcesNext(): void {
        this.page++;
        this.getDatasources();
      }

    getDatasets(page,limit) {
        this.datasetTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?pagination=true&pageno=${1}&pagesize=${this.limit}`;
        var serverfilter = "";
        if(sessionStorage.getItem("serverfilter")){
          var serverfilter = sessionStorage.getItem("serverfilter");
          this.isFiltering = true;
        } else {
          if(this.isFiltering){ 
            page = 1;
            limit = 100;
          }
          this.isFiltering = false;
        }
       let sortfilter="";
       if(sessionStorage.getItem("sortfilter")){
          sortfilter=sessionStorage.getItem("sortfilter");
         // this.isSorting = true;
       }
        const getDataset: any = {
          user_id: this.loggedInUserDetails['user_id'],
          catalog_id: this.catalog_id,
          object_id: this.object_id,
          page:page,
          limit:limit
        };

        this.catalogSvc.getDatasets(getDataset).subscribe((data) => {
            this.datasets = data['currentpage'] ? data['currentpage'] : data;
            if (this.datasets.length === 0) {
              this.noData = true;
            }
            this.datasets.forEach(element => {
              element.id = Math.random();
              element.score100 = (element.score * 100).toFixed();
              element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
            });
            this.datasets.sort(((a, b) => b.score100 - a.score100));
            this.totalDatasets = this.datasets.length;
            if (page === 1) {
              this.grandTotalDataset = data['totalrecords'];
            }
            this.isLoadingDatasets = false;
            let concatedRows = this.datasets;
            if (this.gridDatasets) {
              concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
              this.datasets = concatedRows;
              this.gridDatasets.dataView.setItems(this.datasets);
              this.gridDatasets.gridService.setSelectedRows([]);
              this.hasDatasetsScrolled = false;
            }
        }, err => { });
      }

      onDatasetsGridCreation(grid) {
        const parent = this;
        parent.gridDatasets = grid;
        parent.gridDatasets.slickGrid.onSort.subscribe((e, args) => {
          this.page = 1;
          this.hasDatasetsScrolled = false;
        });
      }

      viewDatasetsClick() {
        this.getDatasets(this.page,this.limit);
      }
      
  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    this.selectedDsColumn = args.row;
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this.dsName = row.name;
        this.showmodelpopup = true;
        this.datasetId = row.dataset_id;
      } else if (eventEle.target.title === row.datasource_name) {
        $('#datasets_modal').modal('hide');
        $('#datasets_modal').addClass('d-none');
        this.router.navigate(['/zs/datasources', row.datasource_id]);
      } else if (eventEle.target.className == 'fal fa-thumbs-down handDown' || eventEle.target.className =='handDown fal fa-thumbs-down' || eventEle.target.className == 'fal handDown fa-thumbs-down') {
        this.showDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;
        this.datasetId = row.dataset_id;
        this.showConceptColumn = false;
        this.dsColumnName = row.name;
      } else if (eventEle.target.className == 'fal fa-thumbs-up') {
        const upVotedata = {
          "dataset_id": row.dataset_id,
          "mapping_type": "manual",
          "entity_id": this.object_id,
          "catalog_id": this.catalog_id,
          "is_semantic_level_mapping": true
        };
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          payload: upVotedata
        };
        this.catalogSvc.saveVoteSemanticObject(payload, 'up_vote').subscribe(resp => {
          $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
          $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
          $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
          $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          if (this.gridDatasets) {
            this.gridDatasets.dataView.setItems([]);
            this.isLoadingDatasets = true;
            this.getDatasets(1, this.limit);
          }
        }, error => {
          this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
      } else if (eventEle.target.className.includes('countUp')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.upvotes;
        this.datasourceName = row.datasource_name;
        this.datasetName = row.name;
        this.datasetId = row.dataset_id;
        this.voteValue = 'upVote';
        this.screenName = 'discoverDataset';

      } else if (eventEle.target.className.includes('countDown')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.downvotes;
        this.datasourceName = row.datasource_name;
        this.datasetName = row.name;
        this.datasetId = row.dataset_id;
        this.voteValue = 'downVote';
        this.screenName = 'discoverDataset';
      }
    }
  }
     
      onRunModelDs() {        
        //Call the Run Model API       
        const datasetId = this.selectedDsColumn === undefined ? this.datasets[0].dataset_id : this.datasets[+this.selectedDsColumn].dataset_id;        
        this.catalogSvc.runModel303().subscribe((res) => {
          this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT });
        }, (err) => { });
      }

      clearDatasets() {
        this.gridDatasets.dataView.setItems([]);
      }

      onDatasetsScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasetsScrolled && !this.noData) {
          this.hasDatasetsScrolled = true;
          this.onDatasetsNext();
        }
      }

      onDatasetsNext(): void {
        this.page++;
        this.getDatasets(this.page,this.limit);
      }

      // getDatasetsTech(pageNo, limit = 1) {
      //   const getDataset: any = {
      //     user_id: this.loggedInUserDetails['user_id'],
      //     catalog_id: this.catalog_id,
      //     object_id: this.object_id,
      //   };
      //   this.catalogSvc.getDatasets(getDataset).subscribe((data) => {

      //       this.datasetsTech = data;
      //       if (this.datasetsTech.length === 0) {
      //       this.noData = true;
      //       }
      //       this.totalDatasetsTech = this.datasetsTech.length;
      //       this.isLoadingDatasetsTech = false;
      //       // let concatedRows = this.datasetsTech;
      //       if (this.gridDatasetsTech) {
      //       // concatedRows = [...this.gridDatasetsTech.dataView.getItems(), ...this.datasetsTech];
      //       this.datasetsTech.forEach(element => {
      //           element.id = Math.random();
      //       });
      //       // this.datasetsTech = concatedRows;
      //       this.gridDatasetsTech.dataView.setItems(this.datasetsTech);
      //       this.gridDatasetsTech.gridService.setSelectedRows([]);
      //       this.hasDatasetsTechScrolled = false;
      //       }
      //   }, err => { });
      // }

      onDatasetsTechGridCreation(grid) {
        this.gridDatasetsTech = grid;
      }
      
      onDatasetsTechCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.gridDatasetsTech.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
          if (eventEle.target.title === row.name) {
            this.router.navigate(['/zs/datasets', row.dataset_id]);
          } else if (eventEle.target.title === row.datasource_name) {
            this.router.navigate(['/zs/datasources', row.datasource_id]);
          }
        }
      }

      clearDatasetsTech() {
        this.gridDatasetsTech.dataView.setItems([]);
      }

      // onDatasetsTechScroll(parentClass) {
      //   if (this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasetsTechScrolled && !this.noData) {
      //     this.hasDatasetsTechScrolled = true;
      //     this.onDatasetsTechNext();
      //   }
      // }
      
      // onDatasetsTechNext(): void {
      //   this.page++;
      //   this.getDatasetsTech(this.page, this.limit);
      // }

      getMappedColumns(pageNo, limit) {
            this.mappedColumntableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/mappedcolumns?pagination=true&pageno=${1}&pagesize=${limit}`;
            let serverFilter = "";
            if (sessionStorage.getItem("serverfilter")) {
              serverFilter = sessionStorage.getItem("serverfilter");
            }
            let sortfilter = "";
            if (sessionStorage.getItem("sortfilter")) {
              sortfilter = sessionStorage.getItem("sortfilter");
            }
          //this.contentSvc.getAttributeDataTypes().subscribe((dataTypes) => {
              this.catalogSvc.getConceptsDatasets({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalog_id, object_id: this.object_id, concept_id: this.concept_id, pageNo:pageNo, limit: limit }, serverFilter, sortfilter).subscribe((data) => {
                  this.mappedColumns = data['currentpage'];
                  this.mappedColumnList =data;
                  if (this.mappedColumns.length === 0) {
                    this.noDataMappedColumns = true;
                  }
                  this.totalMappedColumns = data.totalrecords;
                if (pageNo === 1) {
                  this.grandTotalMappedColumn = data.totalrecords;
                }
                  this.isLoadingMappedColumns = false;
                  this.mappedColumns.forEach(element => {
                      element.id = Math.random();
                      element.score100 = (element.score * 100).toFixed();
                      element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
                      //element.data_type = dataTypes.filter(dataType => dataType.lookup_id === element.lookup_id)[0].name;
                  });
                  this.mappedColumns.sort(((a, b) => b.score100 - a.score100));                  
                  let concatedRows=this.mappedColumns
                if (this.gridMappedColumns) {
                  concatedRows = [...this.gridMappedColumns.dataView.getItems(), ...this.mappedColumns];
                  this.mappedColumns = concatedRows;
                  this.gridMappedColumns.dataView.setItems(concatedRows);
                  this.gridMappedColumns.gridService.setSelectedRows([]);
                  this.hasMaapedColumnScrolled = false;
                }
                this.datasetsExist = data['currentpage'].length > 0;
              }, err => {
                  this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
              });
         // }, err => { });
      }
    
      onRunModel() {
        //Call the Run Model API
        const datasetId = this.selectedMappedColumn === undefined ? this.mappedColumns[0].dataset_id : this.mappedColumns[+this.selectedMappedColumn].dataset_id;       
        this.catalogSvc.runModel303().subscribe((res) => {
          this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT });
        }, (err) => { });
      }
     
      onRunModel406() {
        //Call the Run Model API
        this.catalogSvc.runModel303().subscribe((res) => {
          this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT });
        }, (err) => { });
      }

      onMappedColumnsGridCreation(grid) {
        this.gridMappedColumns = grid;
      }
      onConceptGridCreation(grid: AngularGridInstance) {
        this.gridConcept = grid;
      }
      viewMappedColumnsClick() {
        this.getMappedColumns(this.mappedColumnPageNo, this.limit);
      }

      onMappedColumnsCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.gridMappedColumns.gridService.getDataItemByRowIndex(args.row);

        const metadata = this.gridMappedColumns.gridService.getColumnFromEventArguments(args);
        const fieldName = metadata.columnDef.field;

        this.selectedMappedColumn = args.row;

          if (fieldName === 'score') {             
            if (eventEle.target.className.includes('fa-thumbs-down')) {
              
              if(sessionStorage.getItem("SemanticObjectName")){
              this.semanticObjectName =sessionStorage.getItem("SemanticObjectName");
              }
              
              // this.getClassificationTags(row);
              // $('#datasets_modal').addClass('datasets_modal');
              // // $('#datasets_modal').addClass('datasets_modal');
              // $('#edit_Classification_tag').modal('show');
              // $('#edit_Classification_tag').removeClass('d-none');             
              // $("#tagKeep").prop("checked", true);

              this.dsColumnName = row.column_name;
              this.datasetId = row.dataset_id;
              this.datasetColumnId = row.dataset_column_id;
              this.mappingType = row.mapping_type === undefined ? 'manual' : row.mapping_type;
              this.showDownvoteModal = true;
              this.removing_attribute_id = row.attribute_id;
            }
            
            const payload: any = {
              "dataset_id": +row.dataset_id,
              "dataset_column_id": +row.dataset_column_id,
              "removing_attribute_id": +row.attribute_id,
              "attribute_id": +row.attribute_id,
              // "mapping_type": 'manual',
              // "mapping_type": row.mapping_type,
              "mapping_type": row.mapping_type === undefined ? 'manual' : row.mapping_type,
              "entity_id": +this.object_id,
              "catalog_id": +this.catalog_id
            };

            if (eventEle.target.className.includes('fal fa-thumbs-up')) {
              $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
              $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
              $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              this.upVote(payload);
            } else if (eventEle.target.className.includes('fas fa-thumbs-up')) {
              $('#thumbs-up-' + args.row).removeClass('fas fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fal fa-thumbs-up');
              this.upVote(payload);
            } else if (eventEle.target.className.includes('countUp')) {
              this.showFeedbackSummaryModel = true;
              this.votedUsers = row.upvotes;
              this.datasourceName = row.datasource_name;
              this.datasetName = row.dataset_name;
              this.datasetColumnName = row.column_name;
              this.voteValue = 'upVote';
              this.screenName = 'mappedColumn';
              this.concept_id = row.attribute_id;
              this.datasetColumnId=row.dataset_column_id;

            } else if (eventEle.target.className.includes('countDown')) {
              this.showFeedbackSummaryModel = true;
              this.votedUsers = row.downvotes;
              this.datasourceName = row.datasource_name;
              this.datasetName = row.dataset_name;
              this.datasetColumnName = row.column_name;
              this.voteValue = 'downVote';
              this.screenName = 'mappedColumn';
              this.concept_id = row.attribute_id;
              this.datasetColumnId=row.dataset_column_id;
            }
          }

        if (row !== undefined && row !== null) {
          if (eventEle.target.title === row.datasource_name) {
            $('#mapped_columns_modal').modal('hide');
            $('#mapped_columns_modal').addClass('d-none');
            this.router.navigate(['/zs/datasources', row.datasource_id]);
          } else if (eventEle.target.title === row.dataset_name) {
            this.dsName = row.dataset_name;
            this.showmodelpopup=true;
            this.datasetId=row.dataset_id;

           
          }
        }
      }

      
      upVote(payload) {
        const getRow: any = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          payload: payload
        };
        this.catalogSvc.upVote(getRow).subscribe((data) => {
          if (this.gridMappedColumns) {
            this.gridMappedColumns.dataView.setItems([]);
            this.isLoadingMappedColumns = true;
            this.getMappedColumns(1, this.limit);
          }
        }, err => {
          this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
         });
      }

      downVote(payload) {
        const getRow: any = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          payload: payload
        };
        this.catalogSvc.downVote(getRow).subscribe((data) => {
        }, err => { });
      }
       
      handleObjectGridClickEvent(eventData) {
        const parent = this;
        parent.eventData = eventData;
        const args = eventData.args;
        const event = eventData.eventData;
        // const metadata = this.gridCon.gridService.getColumnFromEventArguments(args);
        const metadata = parent.leftGrid.gridService.getColumnFromEventArguments(args);
        parent.selectedObjectMetadata = metadata;
        const attribute_id = parent.selectedObjectMetadata.dataContext.attribute_id;
        const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);
        const fieldName = metadata.columnDef.field;
        parent.concept_id=attribute_id;  
        $('#concept-logical_name-' + parent.lastRowSelected).removeClass('selected-row');
        if (row !== undefined && row !== null) {              
          $('#concept-logical_name-' + args.row).addClass('selected-row');
          if (event.target.className.includes('blueLink') && fieldName === 'logical_name') {  
            parent.router.navigate(['../' + parent.object_id + '/concepts'], { relativeTo: parent.activatedRoute, queryParams: { attribute_id: attribute_id}, queryParamsHandling: 'merge'});                        
            parent.leftGrid.gridService.setSelectedRow(args.row);
          }
          parent.lastRowSelected = args.row;
        }
      }

    clearMappedColumns() {
      this.gridMappedColumns.dataView.setItems([]);
    }

    getClassificationTags(row) {
       const ds_column_id= row.dataset_column_id ?row.dataset_column_id: row.dataset_type_id;
          this.catalogSvc.getClassificationTags({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: row.dataset_id, column_id: ds_column_id }).subscribe((data) => {
              this.classificationTags = data;
              this.isLoadingclassificationTags = false;
              this.classificationTags.forEach(element => {
                  element.score100 = +(element.score * 100).toFixed();
                  element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
                  element.dataset_id = row.dataset_id;
                  element.column_name = row.column_name;
              });
              this.classificationTags.sort(((a, b) => b.score100 - a.score100));
          }, err => {
              this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
          });
    }


    closeClassificationTags() {
      $('#edit_Classification_tag').modal('hide');
      $('#mapped_columns_modal').removeClass('datasets_modal');
      $('#datasets_modal').removeClass('datasets_modal');

      this.selectedTag = "";
      this.selectedTagOwn = "";
      this.searchClassificationTags = this.getSearchClassificationTags();
    }

    saveClassificationTags() {

      if (this.selectedTag !== "" && this.selectedTag !== undefined) {        
        if(this.selectedTag === 'not_sure'){          
          const payload: any = {
            "dataset_id": +this.mappedColumns[this.selectedMappedColumn].dataset_id,
            "dataset_column_id": +this.mappedColumns[this.selectedMappedColumn].dataset_column_id,
            "removing_attribute_id": +this.mappedColumns[this.selectedMappedColumn].attribute_id,            
            // "mapping_type":'not_sure',
            // "mapping_type": this.mappedColumns[this.selectedMappedColumn].mapping_type,
            "mapping_type": this.mappedColumns[this.selectedMappedColumn].mapping_type === undefined ? 'manual' : this.mappedColumns[this.selectedMappedColumn].mapping_type,
            "entity_id": +this.object_id,
            "catalog_id": +this.catalog_id
          }
         // this.downVote(payload);  
        }else{
          this.selectedTag = this.selectedTag + '';
          this.selectedTag = this.selectedTag.split('_')[0];
          const payload: any = {
            "dataset_id": +this.mappedColumns[this.selectedMappedColumn].dataset_id,
            "dataset_column_id": +this.mappedColumns[this.selectedMappedColumn].dataset_column_id,
            "removing_attribute_id": +this.mappedColumns[this.selectedMappedColumn].attribute_id,
            "attribute_id": + this.selectedTag,
            // "mapping_type":'manual',
            // "mapping_type": this.mappedColumns[this.selectedMappedColumn].mapping_type,
            "mapping_type": this.mappedColumns[this.selectedMappedColumn].mapping_type === undefined ? 'manual' : this.mappedColumns[this.selectedMappedColumn].mapping_type,
            "entity_id": +this.object_id,
            "catalog_id": +this.catalog_id
          }  
         // this.downVote(payload);
        }              

        

        // if (eventEle.target.className.includes('fal fa-thumbs-up')) {
          $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
          $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
          $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
          $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
        // } else if (eventEle.target.className.includes('fas fa-thumbs-up')) {
          // $('#thumbs-up-' + args.row).removeClass('fas fa-thumbs-up');
          // $('#thumbs-up-' + args.row).addClass('fal fa-thumbs-up');
        // }

      }
      this.closeClassificationTags();
    }


    onSearchClassificationTags(event: any) {
      this.searchClassificationTags = this.getSearchClassificationTags(event.term);
    }

    getSearchClassificationTags(term: string = null) {
      if (term) {
          this.catalogSvc.getSearchClassificationTags(term).subscribe((data) => {
            this.searchTags = data;
          }, err => { });
        // return this.http.get<any>(`https://api.github.com/search/users?q=${term}`).pipe(map(rsp => rsp.items));
          return this.http.get<any>(`${this.baseService.apiUrl}/users/${this.loggedInUserDetails['user_id']}/attributes?logical_name=${term}`);
      } else {
        return of([]);
      }
    }

    onChangeClassificationTags(event) {
      $("#tagOwn").prop("checked", true);
      this.selectedTag = event !== undefined ? event.attribute_id : "";
      if(event !== undefined) {
        this.selectedTag = event.attribute_id;
      } else {
        this.selectedTag = "";
        this.searchClassificationTags = this.getSearchClassificationTags();
      }
    }  

    onFocusClassificationTags($event: Event) {
      // this.event.push({ name: '(focus)', value: $event });
      $("#tagOwn").prop("checked", true);
    }

    selectOptionDataSetModelList(dataSetId, limit = 30) {
      this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      // this.isSampleDsLoading = true;
      this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
          this.modalRowData = data.currentpage;
          let rowKeys = [];
          if (data.currentpage.length) {
              let myVar: any = data.currentpage[0];
              for (var key in myVar) {
                  rowKeys.push(key) 
              }
          }
          this.modelColumnDef = [];
          rowKeys.forEach(column => {
            this.modelColumnDef.push({
              'displayname': column,
              'physicalname': column,
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'minWidth': 150,
            })
          })
  
          this.modalRowData.forEach((element: any) => {
            element.id = Math.random();
          });
  
          // this.total = data['totalrecords'];
          if (this.modalGrid) {
            this.modalGrid.dataView.setItems(this.modalRowData);
            this.modalGrid.gridService.setSelectedRows([]);
          }
          this.isSampleDsLoading = false;

        }, err => {
          this.isSampleDsLoading = false;
          this.modalRowData = [];
        });
    }

    onModelGridCreation(grid) {
      this.modalGrid = grid;
    }
      
    saveAndClose() {
      sessionStorage.removeItem('curr_attribute_id');
      sessionStorage.removeItem('isCatalogAdmin');
      this.router.navigate(['/zs-cl/catalogs']);
    }

    closeModal() {
      $('#datasets_modal').removeClass('datasets_modal');
    }

    popupoutput(message){
      this.showmodelpopup = message;   
    }

  // On close of downvote feedback modal
  closeModalPopup(event) {
    if (event) {
      $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      if (this.gridDatasets) {
        this.gridDatasets.dataView.setItems([]);
        this.isLoadingDatasets = true;
        this.getDatasets(1, this.limit);
      }
      if (this.gridMappedColumns) {
        this.gridMappedColumns.dataView.setItems([]);
        this.isLoadingMappedColumns = true;
        this.getMappedColumns(1, this.limit);
      }
    }
    this.showDownvoteModal = false;
  }
  
   
  onViewObjectModel(){    
    this.router.navigate(['./view-object-model'],{relativeTo:this.activatedRoute,queryParams:{attribute_id:this.concept_id,catalog_id:this.catalog_id, entityName: this.object.name},queryParamsHandling:'merge'});                             
  }

  showTag() {
    $('.basic-tooltip').hide();
    $('#tag').removeClass('d-none');
    $('#tag').addClass('d-inline');
    $('#suggestion').removeClass('d-none');
    $('#suggestion').addClass('d-inline');
  }

  hideTags() {
    $('#tag').addClass('d-none');
    $('#tag').removeClass('d-inline');
    $('#suggestion').addClass('d-none');
    $('#suggestion').removeClass('d-inline');
  }

  cleanTags() {
    this.tagInput.nativeElement.value = "";
    this.selectTag();
  }

  selectTag() {
    const search = this.tagInput.nativeElement.value;
    if (search.length) {
      this.filteredTags = this.filterTags(search)
      if (!this.filteredTags.length) {
        // this.hideTags();
      }
    } else {
      this.updateSuggestions();
    }
  }

  filterTags(searchString: string) {
    return this.filteredTags.filter(tag =>
      tag['tag_name'].toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
  }

  getSelectedTag(serachedTag) {
    if (serachedTag) {
      if (this.displayTags && this.displayTags.length) {
        const existingTag = this.displayTags.find(tag => tag.tag_name.toLowerCase() == serachedTag.tag_name.toLowerCase());
        if (!existingTag) {
          this.displayTags.push(serachedTag);
        }
      } else {
        this.displayTags.push(serachedTag);
      }
    }
    this.updateSuggestions();
    this.enteredInputTag = "";
    // this.hideTags()
  }

  getAllTag() {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
    }
    this.catalogSvc.getAllTags(data).subscribe(resp => {
      // this.tags = resp['currentpage'];
      this.tags = resp;
      this.displayTags = this.conceptsList[this.selectedConceptRow].tags;
      this.updateSuggestions();
      this.showTag();
    }, error => { });
  }

  updateSuggestions() {
    this.filteredTags = this.tags;
    this.displayTags.forEach((ele, i) => {
      this.filteredTags = this.filteredTags.filter( item => item.semantic_tag_id !== ele.semantic_tag_id);
    });
  }

  onSaveNewTag(event) {
    if (event.key === "Enter" && event.target.value) {
      const data = {
        user_id: this.loggedInUserDetails['user_id'],
        payload: {
          tag_name: event.target.value
        }
      }
      this.catalogSvc.addNewTag(data).subscribe(resp => {
        this.displayTags.push(resp[0]);
        this.updateSuggestions();
        this.enteredInputTag = "";
      }, error => {
        this.messageSvc.sendMessage({ message: error.error[0].message, type: 'ERROR', hideboard: true });
      });
    }
  }

  saveTags() {
    const tagPayload = [];
    if (this.displayTags) {
      this.displayTags.forEach(element => {
        tagPayload.push({ semantic_tag_id: element.semantic_tag_id })
      });
    }
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      catalog_id: this.catalog_id,
      object_id: this.object_id,
      concept_id: this.concept_id,
      payload: tagPayload
    }
    this.catalogSvc.addTagToConcept(data).subscribe(resp => {
      this.displayTags.forEach((element, ind) => {
        if(!element.semantic_concept_tag_id) {
          element.semantic_concept_tag_id = resp[ind].semantic_concept_tag_id;
        }
      });
      this.conceptsList[this.selectedConceptRow].tags = this.displayTags;
      this.gridCon.dataView.setItems(this.conceptsList);
      this.gridCon.gridService.renderGrid();
      this.onCancel();
      this.getTooltips()
    }, error => {
      this.messageSvc.sendMessage({ message: 'Tag not provided. Please add the Tag before applying.', type: 'ERROR', hideboard: true });
    });
  }

  onCancel() {
    // this.hideTags();
    this.displayTags = [];
    // this.tags = [];
    // this.conceptsList[this.selectedConceptRow].tags = this.displayTags;
    // this.getAllTag();
    this.updateSuggestions();
    // this.filteredTags = [];
    this.enteredInputTag = "";
    $('#tagModal').modal('hide');
  }

  onDeleteTag(tag) {
    this.displayTags = this.displayTags.filter(exitTag => exitTag != tag);
    this.updateSuggestions();
    this.enteredInputTag = "";
  }
  
  onDeleteConceptTag(tag_id, row) {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      catalog_id: this.catalog_id,
      object_id: this.object_id,
      concept_id: this.concept_id,
      semantic_concept_tag_id: tag_id
    }
    this.catalogSvc.removeTagToConcept(data).subscribe(resp => {
        this.conceptsList[this.selectedConceptRow].tags = this.conceptsList[this.selectedConceptRow].tags.filter(item => item.semantic_concept_tag_id != tag_id);
        this.gridCon.dataView.setItems(this.conceptsList);
        this.gridCon.gridService.renderGrid();
        this.getTooltips();
        this.onCancel();
    }, error => {
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }


  getTooltips() {
    this.conceptsList.forEach((element, i) => {
      var rowTag = (this.conceptsList && this.conceptsList[i+1]) ? this.conceptsList[i+1].tags : [];
      if(rowTag) {
        $("#ellipsis_" + (i+1)).mouseenter(function() {
          $('.basic-tooltip').hide();
          var coordenadas = $("#ellipsis_" + (i+1)).offset();
          const tooltipElm = document.createElement('div');
          tooltipElm.className = 'basic-tooltip';
          var htmlTags = `<div class="ttTagTitle">Tags</div><div class="ttTagX">X</div><div class="ttTagContent">`;
          rowTag.forEach(ele => {
            htmlTags += `<div class="ttTagItem">${ele.tag_name}</div>`;
          });
          htmlTags += `</div>`
          tooltipElm.innerHTML = htmlTags;
          document.body.appendChild(tooltipElm);
          tooltipElm.style.top = (coordenadas.top + 10) + 'px';
          tooltipElm.style.left = (coordenadas.left + 15) + 'px';
          $(".ttTagX").click(function(){
            $('.basic-tooltip').hide();
          });
          $(".basic-tooltip").mouseleave(function() {
            $('.basic-tooltip').hide();
          });
        })
      }
    });
  }

  onResize(event) {
    $('.basic-tooltip').hide();
  }
  closeSynonymsModel(event) {
    $('#synonyms_modal').modal('hide');
     this.synonyms=[];
     this.showSynonymsModel = event;
   }

  onExport() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/datasources?export=true`, '_self');
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/datasources?export=true`).subscribe(resp => {
      this.catalogSvc.exportCsvFiles(resp, 'DiscoveredDataSourcesReport');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onExportDataSet() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?export=true`, '_self');
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?export=true`).subscribe(resp => {
      this.catalogSvc.exportCsvFiles(resp, 'DataSetsReport_forSemanticObject');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onExportMappedColumn() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/mappedcolumns?export=true`, '_self');
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/mappedcolumns?export=true`).subscribe(resp => {
      this.catalogSvc.exportCsvFiles(resp, 'DiscoveredMappedDataColumnsReport');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }
  receiveTotalRecords($event) {   
    this.subTotalMappedColumn = this.zettaUtils.getSubTotal(this.grandTotalMappedColumn, $event);   
  }

  receiveTotalConceptRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);       
  }
  receiveTotalTechViewRecords($event) {
    const parent = this;
    this.subTotalTechView = this.zettaUtils.getSubTotal(this.grandTotalTechView, $event);
    this.pageTechnical = 1;
  }
  onSemanticsRunModel() {
    this.catalogSvc.runModel303().subscribe((res) => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs-cl/catalogs/' + this.catalog_id });      
    }, (err) => {
    });
  }

  viewDataQualityClick() {
    if (this.isDataQualityMapped) {
      this.catalogSvc.showExceptionsGrid.next({ showException: false, name: "" });
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id +'/dq-rule'],
      { queryParams: {catalog_id:this.catalog_id, object_id: this.object_id, dqRuleLevel: 'object', showBreadcrumb:this.showBreadcrumb } });
    }

  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }

  onConceptRunModel() {
    this.catalogSvc.runModel303().subscribe(res => {
      if(res) {
        this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs-cl/catalogs/'});   
      }
    }, (err) => {});
  }

  receivedFileNameClassify($event) {
    this.dataResponse = $event;
    if (this.dataResponse.is_upload_complete) {
      this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
      this.is_upload_complete_classify = this.dataResponse.is_upload_complete;
    }
  }
 
  cancelUploadMappings() {
    this.clearModal = Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
  }
  
  receiveTotalRecordDataset(event){
    this.subTotalDataset = this.zettaUtils.getSubTotal(this.grandTotalDataset, event);
  }

  openModalImport(modal){
    $('#' + modal).modal('show');
  }

  getHeaderObj(conceptList, headerIndex){
    
    let headerObj;
    if (headerIndex > -1) {
       headerObj = conceptList[headerIndex];
       headerObj['id'] = Math.random();
    } else {
        headerObj = {
      action: 'add',
      description: '',
      entity_id: null,
      id: Math.random(),
      logical_name: null,
      mapped_data_columns: "0",
      train: "train"
    }
  }
  return headerObj;
};

  
  triggerAlgoToProcessFile() {
    let payload = { object_type_id: AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, catalog_id: this.catalog_id, ontology_file_type: '.' + this.fileType };
    this.projectService.processCatalogFile(payload)
      .subscribe(startProcess => {
        this.checkJobQueueStatus(true);
      });
  }

  checkJobQueueStatus(makeStatusCall) {
    this.uploadedCatalogData = this.uploadedCatalogData ? this.uploadedCatalogData : sessionStorage.getItem('catalog_object_data');
    if (((this.uploadedCatalogData && this.uploadedCatalogData.catalog_id) && (this.catalog_id == this.uploadedCatalogData.catalog_id)) || makeStatusCall) {
      let dataFromLocal = sessionStorage.getItem('catalog_concepts_data');
      if ((this.uploadedCatalogData && this.uploadedCatalogData['showProgress']) || (dataFromLocal && dataFromLocal['showProgress'])) {
        this.showProgressModal = true;
        $('#processing_model').modal('show');
      }
      this.projectService.getJobQueueStatus({ objectTypeId: AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, objectId: this.catalog_id })
        .subscribe(queueStatus => {
          if ((!queueStatus) || (queueStatus && queueStatus['status'] != 'pending')) {
            clearInterval(this.revokeProcessing);
            this.clearProcessingData();
            this.showProgressModal = true;
            $('#processing_model').modal('show');
            // if((this.uploadedCatalogData && this.uploadedCatalogData['showProgress']) || (dataFromLocal && dataFromLocal['showProgress'])) {
            this.trackProcessingdata();
            // }
          } else {
            while (!this.revokeProcessing) {
              this.revokeProcessing = setInterval(() => this.checkJobQueueStatus(true), AppGlobals.CLASSIFY_CATALOG_CHECK_DURATION);
            }
          }
        });
    }
  }

  trackProcessingdata() {
    this.projectService.getLatestJob(this.loggedInUserDetails['user_id'], this.catalog_id, AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE)
      .subscribe(latestJobId => {
        if (latestJobId && latestJobId['loader'] && latestJobId['loader']['job_id']) {
          this.latestJobId = latestJobId['loader']['job_id'];
          if (this.latestJobId) {
            this.projectService.getCurrentActiveJobsById(this.loggedInUserDetails['user_id'], this.catalog_id, AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, this.latestJobId)
              .subscribe(uploadStatus => {
                if (uploadStatus && uploadStatus['job_completed']) {
                  this.is_upload_complete = true;
                  clearInterval(this.revokeProcessing);
                  this.refreshData();
                  this.hideProcessingSpinnerModal();
                  this.is_upload_complete = true;
                  clearInterval(this.revokeTrack);
                  this.revokeProcessing = null;
                  this.revokeProcessing = null;
                  this.clearTrackData();
                  this.clearProcessingData();
                  this.trackJobErrorDetails = '';
                } else if (uploadStatus && !uploadStatus['job_completed'] && !uploadStatus['is_errored']) {
                  while (!this.revokeTrack) {
                    this.revokeTrack = setInterval(() => this.trackProcessingdata(), AppGlobals.CLASSIFY_CATALOG_CHECK_DURATION);
                  }
                } else if (uploadStatus && !uploadStatus['job_completed'] && uploadStatus['is_errored']) {
                  this.trackJobErrorDetails = uploadStatus['error_message'];
                  this.showProgressModal = true;
                  $('#processing_model').modal('show');
                  clearInterval(this.revokeTrack);
                  clearInterval(this.revokeProcessing);
                  this.revokeTrack = undefined;
                  this.revokeProcessing = undefined;
                  sessionStorage.removeItem('catalog_concepts_data');
                }
              });
          }
        }
      });
  }

  refreshData() {
    if (this.is_upload_complete) {
      if (this.gridCon) {
        this.conceptsList = [];
        this.gridCon.dataView.setItems(this.conceptsList);
      }
      this.showBreadcrumb ? this.getConcepts(1, this.limitConcepts) : null;
      if (this.isEditFlow) {
        this.catalogSvc.getSemanticsObjects(this.catalog_id).subscribe(response => {
          this.objectsIdsArr = [];
          response.forEach(item => {
            this.objectslistDropDown.push(item);
            this.objectsIdsArr.push(item.entity_id);
          });
          this.objectsIds = this.objectsIdsArr.toString();
          this.showBreadcrumb ? this.getConcepts(this.pageConcepts, this.limitConcepts) : this.getAllConcepts();
        }, err => { });
      }
    }
  }

  hideProcessingSpinnerModal() {
    this.showProgressModal = false;
    $('#processing_model').modal('hide');
    clearInterval(this.revokeTrack);
    clearInterval(this.revokeProcessing);
    this.revokeTrack = undefined;
    this.revokeProcessing = undefined;
    sessionStorage.removeItem('catalog_concepts_data');
  }

  clearProcessingData() {
    clearInterval(this.revokeProcessing);
    this.revokeProcessing=null;
    while (this.revokeProcessing!== null){
        this.revokeProcessing=null; 
    }
  }

  clearTrackData() {
    clearInterval(this.revokeTrack);
    this.revokeTrack=null;
    while (this.revokeTrack!== null){
        this.revokeTrack=null; 
    }
  }

  //Below method is responsible for persisting empty row to grid on sort and filter 
  onDataFetched(dataObj) {
   let sortType = sessionStorage.getItem('sortfilter');
   let serverFilter = sessionStorage.getItem("serverfilter");
   //if (serverFilter || sortType) {
     let conceptList = dataObj.items;
     if (conceptList.length > 0) {
      let headerIndex = conceptList.findIndex((concepts) => {
        return concepts && concepts.action === 'add';
      });
        let headerObj = this.getHeaderObj(conceptList, headerIndex);
      if (headerIndex > -1) {
        conceptList.splice(headerIndex, 1);
      }
        conceptList.unshift(headerObj);
        conceptList.forEach((concept) => {
            concept['id'] = Math.random()
        })
      }
    }

    onSearchDataFetched(conceptsDataObj) {
      if(conceptsDataObj && conceptsDataObj['currentpage'] && conceptsDataObj['currentpage'].length>0) {
        let conceptList = conceptsDataObj && conceptsDataObj['currentpage'] && conceptsDataObj['currentpage'].length > 0 ? conceptsDataObj['currentpage'] : []; 
        if (this.gridCon) {
          this.gridCon.dataView.setItems(this.addOrUpdateHeaderObjToConceptList(conceptList));
        }
      } else {
        if (this.gridCon) {
          this.gridCon.dataView.setItems([{action: 'add',
          description: '',
          entity_id: null,
          logical_name: null,
          mapped_data_columns: "0",
          id: Math.random(),
          train: "train"}]);
        }
      }
    }

    addOrUpdateHeaderObjToConceptList(conceptList) {
      let newHeaderObj = {
        action: 'add',
        description: '',
        entity_id: null,
        logical_name: null,
        mapped_data_columns: "0",
        train: "train"
      };
      if (conceptList && conceptList.length > 0) {
        let headerIndex = conceptList.findIndex((concepts) => {
          return concepts && concepts.action === 'add';
        });
        if (headerIndex > -1) {
          conceptList.splice(headerIndex, 1);
        }
        newHeaderObj['id'] = Math.random();
        conceptList.unshift(newHeaderObj);
      } else {
        let conceptList = [newHeaderObj];
        return conceptList;
      }
      return conceptList;
    }

    onDataSorted(conceptsDataObj) {
      let conceptList = conceptsDataObj && conceptsDataObj['currentpage'] && conceptsDataObj['currentpage'].length > 0 ? conceptsDataObj['currentpage'] : []; 
      if (this.gridCon) {
        this.gridCon.dataView.setItems(this.addOrUpdateHeaderObjToConceptList(conceptList));
      }
  }  
}
