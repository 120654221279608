import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ProjectStatus } from 'src/app/common/components/project-progress/project-progress.model';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { environment } from 'src/environments/environment';
import { ZsClContentService } from '../zs-cl-content.service';

export const referenceDataFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(value && value.length>0) {
    return `<div class="text-truncate position-relative"><label title="${value}" class="text-truncate wd-95">${value}
    <span class="d-inline-block position-absolute trash-position">
    <i class="fa-light fa-trash-can" aria-hidden="true"></i></span>
    </label></div>`;
  } else if(value == null){
    return;
   } else {
     return `<div class="text-truncate position-relative"><label title="${value}" class="text-truncate wd-95">${value}</div>`
   }

}

export const referenceDelete: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(value) {
    return `
    <span class="d-inline-block trash-can-icon-position" 
    >
    <i class="fa-light fa-trash-can" aria-hidden="true"></i>
    </span>
    `;
  }
}

@Component({
  selector: 'zetta-concept-training-data',
  templateUrl: './concept-training-data.component.html',
  styleUrls: ['./concept-training-data.component.scss']
})
export class ConceptTrainingDataComponent implements OnInit {
  activeNavLnk: string;
  gridOptions: object;
  public loggedInUserDetails = null;
  progressState = new ProjectStatus();
  project_id;
  projectData:any;
  tableSettings: object = { 'height': "100%", 'width': "99.60%" };
  referenceGrid: AngularGridInstance;
  hasConceptSelected=false;
  referenceGridColumnsDef: any = [];
  referenceDataSource: any = [];
  showTrainingDataModal = false;
  selectedRowIndexs;
  eventData;
  hasScrolled = false;
  page = 1;
  limit = 100;
  dataCount;
  referenceDataAdded = false;
  selectedSubConcepts = '';
  isDataReady = false;
  project_type:string;
  manageData=false;
  project_type_id:number;
  isFiltering = false;
  constructor(
    private zettaUtils: ZettaUtils,
        private catalogSvc: ZsClContentService, private route: ActivatedRoute,
        private router: Router, private messageSvc: MessageService
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.route.parent.params.subscribe(params => {
        this.project_id = params['id'];
    });
    this.route.queryParamMap.subscribe(param => {
      this.project_type_id = +param.get('project_type_id');
      this.project_type = param.get('project_type');
      if (param.has('manageData')) {
        this.manageData = param.get('manageData') == 'true' ? true : false;
      }
      this.activeNavLnk= param.get('activeLink');
    });
    this.getProject();    
    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };
    this.referenceGridColumnsDef = [{
      'displayname': 'Classifier',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 150,
      'maxWidth': 250,
      'headerCssClass': 'mr-top-1',
      }, {
          'displayname': 'Sub-Concept',
          'physicalname': 'sub_concept',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
          'maxWidth': 250,
          'headerCssClass': 'mr-top-1',
      }, {
        'displayname': 'Reference Data Source',
        'physicalname': 'reference_data',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 200,
        'maxWidth': 480,
        'headerCssClass': 'mr-top-1',
        'formatter': referenceDataFormatter
      }
    ];

  this.getReferenceDataList(this.page, this.limit);
  }

  referenceGridCreated(grid) {
    const parent = this;
    parent.referenceGrid = grid;
    parent.referenceGrid.slickGrid.onSelectedRowsChanged.subscribe( (e, args) => {
        parent.hasConceptSelected = args.rows.length > 0 ? true : false;
        parent.selectedRowIndexs = args.rows
    });
  }

getReferenceDataList(page, limit) {
  var serverfilter = "";
    if(sessionStorage.getItem("serverfilter")){
      var serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if(this.isFiltering){ 
        page = 1;
        limit = 100;
      }
      this.isFiltering = false;
    }
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   }
  this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.project_id}/referencedata?pagination=true&page=1&pagesize=${limit}`;
  this.catalogSvc.getReferenceData(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.project_id, page, limit,serverfilter, sortfilter)
  .subscribe(res => {
    this.isDataReady = true;
    if(res && res.length>0) {
      if(res[1] && res[1].length>0) {

        let concatedRowsT = res[0]['currentpage'];
        this.dataCount = res[0]['totalrecords'];
        res[1].forEach(referencename => {
          concatedRowsT.map(dataset => {
            if(referencename['classifier'] == dataset['class'] && referencename['sub_concept'] == dataset['sub_concept']) {
               dataset['reference_data'] = `${referencename['datasource_name']}, ${referencename['dataset_name']}, ${referencename['data_column']}`;
               dataset['id'] = Math.random();
               return dataset
            } else {
              dataset['reference_data'] = '';
              dataset['id'] = Math.random();
              return dataset;
            }
          });
        });
        if (this.referenceGrid) {
          concatedRowsT = [...this.referenceGrid.dataView.getItems(), ...concatedRowsT];
          this.referenceDataSource = concatedRowsT;
          this.referenceGrid.dataView.setItems(this.referenceDataSource);
          this.referenceGrid.gridService.setSelectedRows([]);
          if(page < res[0]['totalpages']) {
            this.hasScrolled = false;
          } 
        }
      } else if(res[0] && res[0]['currentpage'].length>0 && !res[1].length) {
        this.referenceDataSource = res[0]['currentpage'];
        this.referenceDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.dataCount = res[0]['totalrecords'];
        let concatRows = this.referenceDataSource;
        if (this.referenceGrid) {
          concatRows = [...this.referenceGrid.dataView.getItems(), ...concatRows];
          this.referenceDataSource = concatRows;
          this.referenceGrid.dataView.setItems(this.referenceDataSource);
          this.referenceGrid.gridService.setSelectedRows([]);
          if(page < res[0]['totalpages']) {
            this.hasScrolled = false;
          } 
        }
      }
    }
  });
}
onCellClick(eventData): void {
  const parent = this;
  parent.eventData = eventData;
  const args = eventData.args;
  const event = eventData.eventData;
  const row = this.referenceGrid.gridService.getDataItemByRowIndex(args.row);
  if (row !== undefined && row !== null) {
    if (event.target.className.includes('fa-light fa-trash-can')) {
      this.referenceDataSource[args.row]['reference_data'] = '';
      this.referenceGrid.gridService.renderGrid();
      this.checkForReferenceDataAdded();
    }
  }
}

  getProject() {
    const payload = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id
          };
    this.catalogSvc.getclassifyProject(payload).subscribe(resp => {
      if(resp){
        this.projectData = resp;
            this.progressState.states = this.manageData ? this.zettaUtils.getStateList('manage-classify-concept-parser-project-data') : this.zettaUtils.getStateList('create-classify-concept-parser-project');
            this.progressState.currentStateIndex = this.manageData? 0: 4;
            this.progressState.type = this.manageData ? 'manageProjectData' : 'Data Classification Project';
            this.progressState.classificationProject = resp;
            this.progressState.isEdit = false;
        this.progressState.currentStateInfo = 'Saved...';
      }
    });
}

onRefenceDataOpen() {
  this.selectedRowIndexs.forEach(selectedRow => {
    console.log(
      this.referenceDataSource[selectedRow]['sub_concept']
    );
    let referenceString = this.selectedRowIndexs.map(selectedRow => {
      return this.referenceDataSource[selectedRow].sub_concept;
    }).join(', ');
    this.selectedSubConcepts = referenceString;
    console.log(referenceString);
  this.showTrainingDataModal = true;
});
}

updateData(eve) {
  this.showTrainingDataModal = false;
  if(eve.data) {
    let referenceString = eve.data.map(data => {
      return data.source_name;
    }).join(',');
    this.selectedRowIndexs.forEach(selectedRow => {
      this.referenceDataSource[selectedRow]['reference_data'] = referenceString;
      this.referenceDataSource[selectedRow]['references'] = [];
    });
    this.referenceGrid.gridService.renderGrid();
    this.checkForReferenceDataAdded();
    if(eve.data) {
      eve.data.map((data, ind) => {
        this.selectedRowIndexs.forEach(selectedRow => {
          this.referenceDataSource[selectedRow]['references'].push({datasource_id: data.datasource_id, dataset_id:data.logical_name,
            dataset_column_id:data.description,
          });
        });
      })
  };
  };

}
hideOverlay(eve) {
  this.showTrainingDataModal = false;
}

checkForReferenceDataAdded() {
  const referenceFound = this.referenceDataSource.some(item => {
  if(item && item['reference_data'] && item['reference_data'].length) {
    return item['reference_data'].length>0
  }
  });
  this.referenceDataAdded = referenceFound;
}

submitReferenceData() {
  let referenceData = [];
  this.selectedRowIndexs.forEach(index => {
      this.referenceDataSource[index]['classifier'] = this.referenceDataSource[index]['class'],
      delete this.referenceDataSource[index].id,
      delete this.referenceDataSource[index].reference_data,
      delete this.referenceDataSource[index].class,
      referenceData.push(this.referenceDataSource[index]);
  });
  let payload = {
    reference_data: referenceData,
  };
  this.catalogSvc.saveReferenceData(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.project_id, payload)
  .subscribe(res => {
    if(res) {
      this.onNextStep();
    }
  });
}

onScrollView(parentClass) {
  if(sessionStorage.getItem("serverfilter")){
    this.isFiltering = true;
    
  } else {
    if(this.isFiltering){
      this.page = 1;
      this.isFiltering = false;
     
    }
  }
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled) {
    this.onNextPageView();
  }
}

onNextPageView(): void {
  this.page++;
  this.getReferenceDataList(this.page, this.limit);
}

onNextStep() {
  this.router.navigate(['../add-reference-data/data-sets'], { relativeTo: this.route, 
    queryParams: { activeLink: '2' }, queryParamsHandling: 'merge' });
}

onCalcel() {
  if(this.manageData){
    this.router.navigate(['/zs-cl/projects/', this.project_id ], { queryParams: { project_type: this.project_type, project_type_id: this.project_type_id  }});
  }else{
    this.router.navigate(['zs-cl/projects']);
  }
  
}

onPrevStep() {
  this.router.navigate(['../new-sub-concept/column'], { relativeTo: this.route, queryParams: { activeLink: '3' }, queryParamsHandling: 'merge' });
  }
}
