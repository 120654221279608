import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../project.service';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Project } from '../project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { matherMergerFormatter } from '../../../../common/shared/formatters/matherMergerFormatter';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { checkBoxDisableFormatter } from '../../../../common/shared/formatters/checkBoxDisableFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
import { StatisticService } from '../../statistics/statistics.service';
declare var $: any;
import { environment } from '../../../../../environments/environment';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { BaseService } from 'src/app/common/services/base-service';
import { revieweCheckBoxFormattter } from 'src/app/common/shared/formatters/reviewerCheckBoxFormatter';
import { aprooveerCheckBoxFormattter } from 'src/app/common/shared/formatters/approverCheckBoxFormatter';
import { disabledCheckBoxFormatter } from 'src/app/common/shared/formatters/disableCheckBoxFormatter';
import { ColDef, GridApi, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mather-merger-formatter/mather-merger-formatter.component';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { TaskNameFromatterComponent } from 'src/app/common/shared/cell-renderer/classify-taskName-formatter/classify-taskName-formatter.component';
import { NoOfDaysFormatterComponent } from 'src/app/common/shared/cell-renderer/get-noOfDays-fromatter/get-noOfDays-fromatter.component';
declare var $: any;
const getNoOfDays:Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  // if (dataContext.updated_ts === null || dataContext.updated_ts === undefined || dataContext.updated_ts === '') {
    var today = new Date();
    var date_to_reply = new Date(dataContext.last_updated);
    var timeinmilisec = today.getTime() - date_to_reply.getTime();
    
    return `<div class="text-center">
    ${Math.floor(timeinmilisec / (1000 * 60 * 60 * 24)) }
    </div>`
  // }
}

const classifyTaskNameFormatter = (row: number, call, value: any, columnDef: Column, dataContext: any) => {
  if (value && value !== null) {
    const icon = columnDef.field == "task_type" ? 'fas fa-graduation-cap' : '';
    let taskNameFormatter = dataContext.project_type_id === AppGlobals.CONCEPT_PARSER ? 
    `<div class="blueColor modal-feedback text-truncate" title='${value}'
  data-toggle="modal" data-backdrop="static" data-keyboard="false"><i class='pointer mr-2 ${icon}'
  ></i>${value}</div>` :
  `<div class="blueColor modal-feedback text-truncate" title='${value}'
  data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#datasets_modal"><i class='pointer mr-2 ${icon}'
  ></i>${value}</div>`;
    return taskNameFormatter;
  }
}
const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = value;
  if (value.includes('"')) {
    name = value.replace(/"/g, "'");
  }
  return `<div class="datasource-name-formatter text-wrap">
            <span title="${value}">${name}</span><br>
        </div>`;
};


@Component({
  selector: 'zetta-manage-project-tasks',
  templateUrl: './manage-project-tasks.component.html',
  styleUrls: ['./manage-project-tasks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageProjectTasksComponent implements OnInit {

 public projectTasksData:  object = {};
 public grid: AngularGridInstance;
 public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  projectTasksGridOptions: any;
 public tableSettings: object = { 'height': "100%", 'width': "100%" };
  projectTaskTableColDef: any;
  gridOptions: any;
  projectDetail = new Project();
  isLoading = false;
  total = 0;
  page = 1;
  limit :number;
  grandTotal = 0;
  subTotal = '';
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  selectedRowDataSource;
  taskType: string;
  isShowRightGrid = false;
  rightGridDataSource: any = [];
  rightGridColumnsDef: any;
  rightGridOptions: any;
  rightGrid: AngularGridInstance;
  showBreadcrumb = false;
  selectedLeftGridRowsSize = 0;
  errorMsg = '';
  isRightGridLoading = false;
  selectedUserRoles: any = [];
  statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
  enableApplyBtn = false;

  hasScrolled = false;
  noData = false;
  isFeedbackCaptured:boolean= false;

  manageClassify;
  is_import_enable = true;
  clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
  dataRes;
  is_upload_complete = false;
  isProjectAdmin: Boolean = false;
  isFourEyeChecked: Boolean;
  taskName: string;
  selected_record_id: string;
  totalDatasets: number;
  task_id: number;
  gridDatasets: AngularGridInstance;
  datasets = [];
  isLoadingDatasets = true;
  hasDatasetsScrolled = false;
  trainModalDatasetSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  datasetsTableColDef: any;
  reviewPriorMappingsDef: any;
  selectedMappedColumn: number;
  showmodelpopup = false;
  dsName: string;
  showDownvoteModal: boolean = false;
  datasetColumnId: number;
  datasetId: number;
  classInfo: any;
  rowData: any;
  classes: any;
  trainModalGridOptions: any;
  catalog_id: number;
  object_id: number;
  attribute_id: number;
  mappingType: string;
  screen_name: string
  showConceptColumn = false;
  showBackdrop = false;
  taskPage = 1;
  taskLimit = 20;
  summaryHasChanged = false;
  screenName: string;
  task_status: string;
  project_users = [];
  is_task_completed = false;
  shouldDisableFeedback = false;
  fu_type_classifyProjectTraining;
  taskAssignDetails: {};
  assignType: string = 'classifyProject';
  selectedProjects = [];
  viewTaskAssignments = false;
  selectedRow: any;
  assignToUserProgress: boolean = true;
  showAssignLoader: boolean = false;
  isConceptParserProject: boolean = false;
  showConceptParserModel: boolean = false;
  projectId: string;
  project_id: string;
  selectedColumn:string;
  selected_task_id: number;
  trainModalPayload;
  selectedPridiction = [];
  isSubConcept: boolean = false;
  isSubConceptLoading: boolean = false;
  classifier:string;
  feedbackAdded=false;
  msgHeader:string;
  msgText:string;    
  showConfirmBox:boolean;
  hasDeleted:boolean;
  disableConceptParserFeedback: boolean;
  taskTypeId: string;
  is_approver: boolean = false;
  is_pending_for_approval: boolean = false;
  enableFinishTask: boolean = false;
  enableApprove:boolean = false;
  enableApproved: boolean = false;
  taskReviewerName: string = '';
  taskReviewerDate: string = '';
  userRole: object = {};
  is_reviewer = false;
  projId: any;
  showPriorFeedbackRerun = false;
  columnDefs: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid:AgGridAngular;
  gridApi:any;
  columnApi: any;
  taskStatusCompleted:string='Completed';
  taskStatusReviewed: string='Reviewed';
  projectTaskGridState = {
    sort: null,
    search: ''
  }
  classifyProjectTaskGridState = {
    sort: null,
    search: ''
  }
  openMatcherFeedbackModal: boolean = false;
  openMergerFeedbackModal: boolean = false;
  public project_type;
  isPriorFeedbackOpen: boolean= false;
  showResultsListModal: boolean = false;
  recordId;
  resultDetail: object = {};

  constructor(private projectSvc: ProjectService,
    private catalogService: ZsClContentService,
    public zettaUtilsSvc: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    
    private staticRailService : StatisticService,
    private messageSvc: MessageService,
    private ZsClContentService: ZsClContentService,
    private observableSvc: BaseService) { }

  ngOnInit() {
    this.fu_type_classifyProjectTraining = AppGlobals.FU_TYPE_CLASSIFY_PROJECT_TRAINING;
    this.loggedInUserDetails = this.projectSvc.getObjectFromSession('userInfo');
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    }); 
    this.projectId = this.projectDetail.project_id;
    if(this.router.url.includes('zs-cl/projects')) {
      this.manageClassify = true;
      this.getClassifyProject();
      this.assignType = 'classifyProject';
      this.taskAssignDetails = {
        user_id: this.loggedInUserDetails['user_id'],
        title: 'All Users',
        selectImgPath: '../../../../assets/images/common/select-data1.png',
        gridColDef: this.rightGridColumnsDef,
        // homeUrl: '/zs-cl/home',
        alignCardHeight: true,
        diffTaskStatusSelected: false,
        updateURL: `users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/tasks`,
        getUserUrl: `users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/users`,
      };
    } else {
      this.manageClassify = false;
      this.getProject();
      this.getProjectTaskList(this.page, this.limit);
      this.assignType = 'resolveTask';
      this.taskAssignDetails = {
        title: 'All Users',
        user_id: this.loggedInUserDetails['user_id'],
        selectImgPath: '../../../../assets/images/common/select-data1.png',
        gridColDef: this.rightGridColumnsDef,
        // homeUrl: '/zs-cl/home',
        alignCardHeight: true,
        diffTaskStatusSelected: false,
        updateURL: `users/${this.loggedInUserDetails.user_id}/projects/${this.projectDetail.project_id}/projecttasks`,
        getUserUrl: `users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/users`,
      };
    }





    this.projectTaskTableColDef = [{
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': this.manageClassify ? classifyTaskNameFormatter : matherMergerFormatter,
      'minWidth': 120,
    }, {
      'displayname': 'Task Name',
      'physicalname':  this.manageClassify ? 'summary' :'task_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': this.manageClassify ? classifyTaskNameFormatter : matherMergerFormatter,
      'minWidth': 220,
    }, {
      'displayname': 'Assigned To',
      'physicalname': this.manageClassify ? 'assigned_to_user' : 'assigned_to',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-left',
      'minWidth': 120,
    }, {
      'displayname': 'Role',
      'physicalname': 'user_role',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
    }, {
      'displayname': 'Task Status',
      'physicalname':  this.manageClassify ? 'status' :'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': assignmentStatusFormatter,
      'minWidth': 180,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      'formatter': dateFormatter
    }, {
      'displayname': 'Days Since Last Update',
      // 'physicalname': 'last_updated_day',
      'physicalname':  this.manageClassify ? 'last_updated_on' :'last_updated_day',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      // 'formatter':  numericFormatter,
      'formatter':  this.manageClassify ? getNoOfDays : numericFormatter,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      checkboxSelection:true,
      suppressRowClickSelection:true,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 10,
    };

    this.projectTasksGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 15,
    };
    this.trainModalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'reviewer',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': revieweCheckBoxFormattter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }, {
      'displayname': 'Approver',
      'physicalname': 'approver',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': aprooveerCheckBoxFormattter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }];

    this.datasetsTableColDef = [{
      'displayname': 'Classifier Prediction',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormattter,
      'minWidth': 450,
      'maxWidth':500
    },
    {
      'displayname': 'Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': confidenceLevelFormatter,
      'minWidth': 200,
      'maxWidth': 300
    }
    ];

    this.reviewPriorMappingsDef = [{
      'displayname': 'Classifier Prediction',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormattter,
      'minWidth': 250,
      'maxWidth': 200,
    },
    {
      'displayname': 'Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': confidenceLevelFormatter,
      'maxWidth': 270,
    }];

    this.observableSvc.showAssignUsers.subscribe(toggle => {
      if (toggle) {
        this.viewTaskAssignments = toggle;
      } else {
        this.viewTaskAssignments = false;
      }
    });
    this.intialiseGrid()
  }

  onClosePriorFeedback(){ 
    this.isPriorFeedbackOpen = false;
  }

  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: '',
        headerCheckboxSelection: true,
         checkboxSelection: (params) => {
          return params.data.task_status !== this.taskStatusCompleted && params.data.task_status!== this.taskStatusReviewed;
        },
        minWidth: 30,
      },
      {
        headerName: 'Task Type',
       
        field: 'task_type',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        minWidth: 120,
        cellRendererFramework: this.manageClassify ?  TaskNameFromatterComponent: MatherMergerFormatterComponent,
        tooltipField:'task_type'
      },
      {
        headerName: 'Task Name',
        field: this.manageClassify ? 'summary' :'task_name',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: true,
        sortable: true,
        cellRendererFramework: this.manageClassify ?  TaskNameFromatterComponent: MatherMergerFormatterComponent,
        minWidth:180,
        tooltipField: this.manageClassify ? 'summary' :'task_name'
      },{
        headerName: 'Assigned To',
        field: this.manageClassify ? 'assigned_to_user' : 'assigned_to',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:this.manageClassify ? 'assigned_to_user' : 'assigned_to',
        floatingFilter: true,
        sortable: true,
        minWidth:120,
      
      },{
        headerName: 'Role',
        field: 'user_role',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:'user_role',
        floatingFilter: true,
        sortable: true,
        minWidth:110
      },{
        headerName: 'Task Status',
        field:  this.manageClassify ? 'status' :'task_status',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: true,
        sortable: true,
        cellRendererFramework:AssignmentStatusFormatterComponent,
        minWidth:170,
        tooltipField :  this.manageClassify ? 'status' :'task_status',
      },{
        headerName: 'Last Updated',
        field: 'last_updated',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework: DateFormatterFormatterComponent,
        floatingFilter: true,
        sortable: true,
        minWidth:120,
        tooltipField :'last_updated'
      
      },{
        headerName: 'Days Since Last Update',
        field: this.manageClassify ? 'last_updated' :'last_updated_day',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: true,
        cellClass: 'ag-right-aligned-cell',
        // cellRendererFramework: NoOfDaysFormatterComponent,
        cellRendererFramework: this.manageClassify ? NoOfDaysFormatterComponent: NumericFormatterComponent,
        sortable: true,
        minWidth:160,
        tooltipField : this.manageClassify ? 'last_updated' :'last_updated_day'
      },
    ];
   
    this.fsGridOptions = { 
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
  }
  onGridReady(grid:any) { 
    this.agGrid = grid; 
    this.columnApi = grid.columnApi; 
    
  }
  receiveTotalRecords($event) { 
    this.isLoading = false;   
    this.subTotal = this.zettaUtilsSvc.getSubTotal(this.grandTotal, $event.subTotal); 
   }


  getProject() {
    this.projectSvc.getProject(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(resp => {
      this.isFourEyeChecked = resp.four_eye_check;      
      this.project_users = resp.users;
      const settingsValue = resp['entitlement']['project'];
      if (settingsValue['canUpdate'] && settingsValue['canView']) {
        this.isProjectAdmin = true;
      }
    });
  }

  onGridStatusChanged(projectTaskGridState) {
    if (this.manageClassify) {
      this.classifyProjectTaskGridState.sort = projectTaskGridState.sort ? projectTaskGridState.sort: null;
    } else {
      this.projectTaskGridState.sort = projectTaskGridState.sort ? projectTaskGridState.sort: null;
    }
  }

  getClassifyProject() {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.projectDetail.project_id
    };
    
    this.ZsClContentService.getclassifyProject(data).subscribe(responseList => {
      this.project_type = responseList.type_name;
      this.isFourEyeChecked = responseList.four_eye_check;
      if (responseList.type === AppGlobals.CONCEPT_PARSER) {
        this.isConceptParserProject = true;
      }
      if (responseList.users && responseList.users.length) {
        this.project_users = responseList.users;
        const classifyLoggedInUser = responseList.users.filter(user => user.user_id == this.loggedInUserDetails['user_id']);
        if (classifyLoggedInUser.length) {
          let rolesAssigned = classifyLoggedInUser[0].roles;
          if (rolesAssigned && rolesAssigned.length) {
            let isProjectAdminRoleAssignedToUser = rolesAssigned.find((role) => {
              return role === AppGlobals.CLASSIFY_PROJECT_ADMIN;
            })
            this.isProjectAdmin = isProjectAdminRoleAssignedToUser ? true: false;
            this.showPriorFeedbackRerun = isProjectAdminRoleAssignedToUser ? true: false;
            // this.isProjectAdmin = adminUser[0].role_name.toLowerCase() == 'project_admin' ? true : false;
            // this.showPriorFeedbackRerun = adminUser[0].role_name.toLowerCase() == 'project_admin' ? true : false;
          }
        }
      }
      if(responseList.type == AppGlobals.SEMANTIC_OBJECT_CLASSIFIER){
         this.validateIfSocFeedbackCapturedforProject();
      }
      this.loadClassifyData(this.page, this.limit);
    }, err => {
      this.loadClassifyData(this.page, this.limit);
    });
  }

  getProjectTaskList(pageno, limit) {
    this.isLoading = true;
    let orderBy = this.projectTaskGridState.sort && this.projectTaskGridState.sort != '' ? this.projectTaskGridState.sort: null;
    this.projectSvc.getManageProjectTasksList({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }, pageno, limit).subscribe((data) => {
        this.projectTasksData = data;
        if (this.projectTasksData['currentpage'].length === 0) {
          this.noData = true;
          this.is_import_enable=false;
        }
        this.isLoading = false;
        this.total = data['totalrecords'];
        let shouldGridStateChangeSortApply = this.projectTaskGridState.sort && this.projectTaskGridState.sort != '' ? true : false;
        let inReviewTasks = [];
        let otherTasksApartFromInReview = [];

        this.projectTasksData['currentpage'].forEach(element => {
          element.shouldDisable = element.task_status == AppGlobals.TASK_COMPLETED ? true : false;

          if (shouldGridStateChangeSortApply) {
            if (element.task_status === AppGlobals.TASK_IN_REVIEW) {
              inReviewTasks.push(element);
            } else {
              otherTasksApartFromInReview.push(element);
            }
          }
        });

        if (shouldGridStateChangeSortApply) {
          this.projectTasksData['currentpage'] = [...inReviewTasks,...otherTasksApartFromInReview];
        } 
        
        let concatedRows = this.projectTasksData['currentpage'];
        
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.projectTasksData['currentpage']];
          this.projectTasksData['currentpage'].forEach(element => {
            element.id = Math.random();
          });
          this.projectTasksData['currentpage'] = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
      }, err => {
        this.isLoading = false;
        this.projectTasksData['currentpage'] = [];
      });
  }

  loadClassifyData(pageno, limit = 1) {
    this.isLoading = true;
    this.staticRailService.isStatisticDataChanged.next(true);
    this.projectSvc.getClassifyUserTasks(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id , pageno, limit).subscribe((data) => {
        this.projectTasksData['currentpage'] = data;
        if (data.length === 0) {
          this.noData = true;
          this.is_import_enable=false;
        }
        this.isLoading = false;
        this.total = data['totalrecords'];
        let shouldGridStateChangeSortApply = this.classifyProjectTaskGridState.sort && this.classifyProjectTaskGridState.sort != '' ? true : false;
        let inReviewTasks = [];
        let otherTasksApartFromInReview = [];
        this.projectTasksData['currentpage'].forEach(element => {
          element.shouldDisable = element.status == AppGlobals.TASK_COMPLETED || element.status == AppGlobals.TASK_REVIEWED_STATUS ? true : false;
          element.project_type_id = this.isConceptParserProject ? AppGlobals.CONCEPT_PARSER : AppGlobals.SEMANTIC_OBJECT_CLASSIFIER;
          element.last_updated_on = element.last_updated;
          if (shouldGridStateChangeSortApply) {
            if (element.status === AppGlobals.TASK_IN_REVIEW) {
              inReviewTasks.push(element);
            } else {
              otherTasksApartFromInReview.push(element);
            }
          }
        });
        if (shouldGridStateChangeSortApply) {
          this.projectTasksData['currentpage'] = [...inReviewTasks,...otherTasksApartFromInReview];
        }         
        let concatedRows = this.projectTasksData['currentpage'];
        this.checkFinishTaskBtnValidation(this.projectTasksData['task_outputs']);           
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.projectTasksData['currentpage']];
          this.projectTasksData['currentpage'].forEach(element => {
            element.id = Math.random();
          });
          this.projectTasksData['currentpage'] = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
      }, err => {
        this.isLoading = false;
        this.projectTasksData['currentpage'] = [];
      });
  }

  onGridCreation(grid) {
    const parent = this;
    parent.agGrid = grid;
    parent.columnApi = grid.columnApi;

    grid.api.addEventListener('rowSelected', function (event) {
        const selectedRows = grid.api.getSelectedRows();
        parent.selectedLeftGridRowsSize = selectedRows.length;
        parent.selectedProjects = [];
        selectedRows.forEach(row => {
            parent.selectedProjects.push(row);
        });
       
        parent.selectedRow = selectedRows.length > 0 ? selectedRows[0].data : null;
        parent.observableSvc.showAssignUsers.next(false);
        parent.viewTaskAssignments = false;

        if (parent.isShowRightGrid) {
            parent.isShowRightGrid = false;
            parent.errorMsg = '';
        }
        parent.checkForTaskTypes();
        parent.taskAssignDetails['gridColDef'] = parent.rightGridColumnsDef;
    });
}

  validationChecking(dataSet, row) {
    this.errorMsg = '';
    this.enableApplyBtn = false;
    const reviewsList = dataSet.filter(item => item.isReviewerChecked === true);
    const approverList = dataSet.filter(item => item.isApproverChecked === true);

    if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size === 0
      && this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
      row.isReviewerChecked = false;
    } else if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size >= 0
      && this.statusObj.inreview_size > 0 && row.isReviewerChecked === true) {
      row.isReviewerChecked = (reviewsList.length === 1) ? true : false;
      if (row.isReviewerChecked) {
        this.errorMsg = `${row.user_name} will be set as the Reviewer only for the
        ${this.statusObj.inreview_size} tasks that are in Review status, out of ${this.statusObj.totalSelection} total tasks selected.`;
      } else {
        this.errorMsg = 'Only one Reviewer is allowed.';
      }
    } else if (this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
    } else if (reviewsList.length > 1) {
      this.errorMsg = 'Only one Reviewer is allowed.';
      row.isReviewerChecked = false;
    } else if (approverList.length > 1) {
      this.errorMsg = 'Only one Approver is allowed.';
      row.isApproverChecked = false;
    } else {
      this.enableApplyBtn = true;
    }

    if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.REVEIWER;
    } else if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.APPROVER;
    }
    // TODO: Call API to Update DB
    this.rightGrid.gridService.updateDataGridItem(row, false);
  }

  setStatusProperty() {
    this.statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
    const leftGridDataService = this.grid.gridService;
    const leftSideGridDataSet = this.grid.slickGrid.getSelectedRows();
    const rows: any[] = [];

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
      }
    });
    let completedList;
    let reviewsList;
    let approveList;
    if (this.manageClassify) {
      completedList = rows.filter(item => item.status === AppGlobals.TASK_COMPLETED);
      reviewsList = rows.filter(item => item.status === AppGlobals.TASK_IN_REVIEW);
      approveList = rows.filter(item => item.status === AppGlobals.TASK_PENDING_APPROVAL || item.status === AppGlobals.TASK_PENDING_FOR_APPROVAL);
    } else {
      completedList = rows.filter(item => item.task_status === AppGlobals.TASK_COMPLETED);
      reviewsList = rows.filter(item => item.task_status === AppGlobals.TASK_IN_REVIEW);
      approveList = rows.filter(item => item.task_status === AppGlobals.TASK_PENDING_APPROVAL || item.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL);
    }

    this.statusObj = {
      'completed_size': completedList.length, 'inreview_size': reviewsList.length,
      'pendingapproval_size': approveList.length, 'totalSelection': rows.length
    };
  }

  rightGridCreated(grid) {
    this.rightGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    if(!this.manageClassify) {
      this.getProjectTaskList(this.page, this.limit);
    }
  }

  onNext(): void {
    this.page++;
    if(!this.manageClassify) {
      this.getProjectTaskList(this.page, this.limit);
    }
  }
  onNextTask(): void {
    this.taskPage++;
    this.getTaskDetails(this.task_id, this.taskPage, this.taskLimit);
  }
  onPrev(): void {
    this.page--;
    if(!this.manageClassify) {
      this.getProjectTaskList(this.page, this.limit);
    }
  }

  canDisableConceptParserFeedback(taskStatus, assignedTo) {
    
    let loggedInUserDetails = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;

    if (taskStatus == AppGlobals.TASK_COMPLETED) {
      return true;
    }

    if (taskStatus === AppGlobals.TASK_REVIEWED_STATUS) {
      return true;
    }
    
    if (taskStatus === AppGlobals.TASK_IN_REVIEW) {
      return false;
    }

    if (taskStatus === AppGlobals.TASK_PENDING_FOR_APPROVAL || taskStatus === AppGlobals.TASK_PENDING_APPROVAL) {
       if (loggedInUserDetails && loggedInUserDetails.user_id && loggedInUserDetails.user_id === assignedTo) {
        return false;
      }
      return true;
    }
    return false;
  }

  onCellClick(cellParams): void {
    const { event, data, colDef } = cellParams;
    const args = cellParams.args;
    const row = cellParams.data;
    if(!this.manageClassify) {
      this.shouldDisableFeedback = false;
      let userDetails = this.project_users.find(user => user.user_id === this.loggedInUserDetails.user_id);
      if (userDetails !== undefined) {
      const isAdminUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN);
      const isApproverUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_APPROVER);
      const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER);
      if (row.task_status === AppGlobals.TASK_COMPLETED) {
        this.shouldDisableFeedback = true;
      }
      if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
        this.shouldDisableFeedback = true;
      }
      if (event.target.title === row.task_name || event.target.title === row.task_type) {
        this.selectedClusterId = row.cluster_id;
        this.selectedTaskName = row.task_name;
        this.selectedTaskType = row.task_type;
        this.taskType = row.task_type;
        this.selectedTaskStatus = row.task_status;
        this.selectedRowDataSource = row;
        this.summaryHasChanged = false;
      } else {
      if (event.target.title === row.summary || event.target.title === row.task_type) {
        this.selectedClusterId = row.cluster_id;
        this.selectedTaskName = row.summary;
        this.selectedTaskType = row.task_type;
        this.taskType = row.task_type;
        this.selectedTaskStatus = row.status;
        this.selectedRowDataSource = row;
        this.summaryHasChanged = false;
      }
     }
     if(this.selectedClusterId && this.taskType !== 'Merge Training'){
        this.openMatcherFeedbackModal = true;
        this.openMergerFeedbackModal = false;
      } else if(this.taskType === 'Merge Training') {
        this.openMatcherFeedbackModal = false;
        this.openMergerFeedbackModal = true;
      }
    }
    } else {
      if (event.target.className.includes('fas fa-graduation-cap')
        || event.target.title === row.summary || event.target.title === row.task_type) {
        this.is_task_completed = false;
        this.selectedTaskName = row.summary;
        this.selected_record_id = row.record_id;
        this.recordId =  encodeURIComponent(this.selected_record_id);
        this.task_id = row.task_id;
        let userDetails = this.project_users.find(user => user.user_id === this.loggedInUserDetails.user_id);
        const isAdminUser = userDetails.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_ADMIN);
        if (isAdminUser && row.status === AppGlobals.TASK_COMPLETED) {
          this.is_task_completed = true;
        }
        const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        if (row.status === AppGlobals.TASK_COMPLETED) {
          this.is_task_completed = true;
        }
        if (isReviewdUser && row.status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.is_task_completed = true;
        }
        if(row.status == AppGlobals.TASK_PENDING_APPROVAL || row.status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          this.is_pending_for_approval = true;
        } else {
          this.is_pending_for_approval = false;
        }
        this.taskTypeId = row.task_type_id;
        this.checkUserRoleDetails();
        if (this.isConceptParserProject) {
          this.disableConceptParserFeedback = this.canDisableConceptParserFeedback(row.status, row.assigned_to);
          this.showConceptParserModel =true;
          this.selected_record_id =row.record_id;
          this.selected_task_id = row.task_id;
          this.selectedColumn=row.summary;
          this.projectId = row.reference_object_id;
          this.getPridictionList(row.status);
        } else {
          // Classify SOC
          this.getTaskDetails(row.task_id, this.taskPage, this.taskLimit);
        }
      }
    }
  }

  getPridictionList(taskStatus) {
    this.catalogService.getPredictionList(this.projectId, this.selected_record_id).subscribe(resp => {
      this.classifier = resp['classifier'];
      ($('#classification_tag_model') as any).modal('show');
    }, error => {
      ($('#classification_tag_model') as any).modal('show');
      this.classifier = "";
    }); 
  }

  closeFeedbackModelForConceptParserProject(event) {
    // close feedback model logic to be handled
    this.showConceptParserModel = false;
    if( event){
    this.projectTasksData['currentpage'] = [];
    this.page=1;      
    this.loadClassifyData(this.page);
    }
  }

  closeSubConceptModel(data) {
    this.isSubConcept = false;
  }

  onDeletePrediction(data) {
    this.msgHeader = data.messageHeader;
    this.msgText = data.messageText;
    this.showConfirmBox = true;
  }

  onConfirmDelete(event) {
    this.hasDeleted = event;
    this.showConfirmBox = false;
  }

  clearText(event) {
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  showSubConceptModal(event) {
    // handle show sub concept logic here
    if (event.isSubConcept) {
      this.isSubConceptLoading = true
      this.isSubConcept = event.isSubConcept;
      this.selectedPridiction = event.data;
    }
  }

  addSubConcept(event) {
    this.feedbackAdded = event;
  }

  getUsersList() {
    this.isRightGridLoading = true;
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements'))
      ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';
    this.projectSvc.getUsers(this.loggedInUserDetails['user_id'],
      this.projectDetail.project_id, this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
        this.isShowRightGrid = true;
        this.rightGridDataSource = responseList[1];
        this.rightGridDataSource.forEach(element => {
          element.id = Math.random();
          element.isFourEyeChecked = this.isFourEyeChecked;
        });
        this.isRightGridLoading = false;
        this.rightGridDataSource.forEach(row => {
          row['isProjectAdminChecked'] = false;
          row['isReviewerChecked'] = false;
          row['isApproverChecked'] = false;
          row.roles.forEach(roles => {
            switch (roles) {
              case AppGlobals.PROJECT_ADMIN:
                row['isProjectAdminChecked'] = false;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.REVEIWER:
                row['isReviewerChecked'] = false;
                row['isReviewer'] = true;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.APPROVER:
                row['isApproverChecked'] = false;
                row['isApprover'] = true;
                row['selectedRoles'] = row['roles'];
                break;
              default:
                row['selectedRoles'] = [];
            }
          });
        });
      }, err => {
      });
  }


  getClassifyUsersList() {
    this.isRightGridLoading = true;
    AppGlobals.PROJECT_ADMIN = AppGlobals.CLASSIFY_PROJECT_ADMIN;
    AppGlobals.REVEIWER = AppGlobals.CLASSIFY_PROJECT_REVEIWER;
    AppGlobals.APPROVER = AppGlobals.CLASSIFY_PROJECT_APPROVER;
    this.projectSvc.getTaskAssignments(this.loggedInUserDetails,
      this.projectDetail.project_id).subscribe(responseList => {
        this.isShowRightGrid = true;
        responseList.forEach(ele => {
          if(ele['roles'] && ele['roles'].length) {
            return ele['roles'].map(role => {
              if(role && role['role_id'] || role.role_name) {
                ele['roles'].push(role.role_id);
                 return ele['roles'].shift();
              } else {
                return role;
              }
            })
          }
        });

        this.rightGridDataSource = responseList;
        this.rightGridDataSource.forEach(element => {
          element.id = Math.random();
          element.isFourEyeChecked = this.isFourEyeChecked;
        });
        this.isRightGridLoading = false;
        this.rightGridDataSource.forEach(row => {
          row['isProjectAdminChecked'] = false;
          row['isReviewerChecked'] = false;
          row['isApproverChecked'] = false;
          row.roles.forEach(roles => {
            switch (roles) {
              case AppGlobals.PROJECT_ADMIN:
                row['isProjectAdminChecked'] = false;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.REVEIWER:
                row['isReviewerChecked'] = false;
                row['isReviewer'] = true;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.APPROVER:
                row['isApproverChecked'] = false;
                row['isApprover'] = true;
                row['selectedRoles'] = row['roles'];
                break;
              default:
                row['selectedRoles'] = [];
            }
          });
        });
      }, err => {
      });
  }

  onRunModel(){
    const payload ={
      classify_project_id:this.projId,
      classify_picker:true,
      supervised: true
    };
    this.ZsClContentService.runModelClassifyProject(payload).subscribe(resp =>{
      if (resp) {
        this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.projId}`});
      }
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  onRightGridCellClick(e): void {
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.rightGrid.dataView.getItems();
    row['selectedRoleID'] = null;

    if (row !== undefined && row !== null) {
      if (fieldName === 'isReviewerChecked') {
        row['selectedRoleID'] = AppGlobals.REVEIWER;
        row['isReviewerChecked'] = !row['isReviewerChecked'];
        if (row['isReviewerChecked']) {
          row['selectedRoles'].push(AppGlobals.REVEIWER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
        }
      } else if (fieldName === 'isApproverChecked') {
        row['selectedRoleID'] = AppGlobals.APPROVER;
        row['isApproverChecked'] = !row['isApproverChecked'];
        if (row['isApproverChecked']) {
          row['selectedRoles'].push(AppGlobals.APPROVER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
        }
      }
      this.rightGrid.gridService.updateDataGridItem(row, false);
      this.setStatusProperty();
      this.validationChecking(dataSet, row);
    }
  }

  assignTaskBtnClick() {
      this.viewTaskAssignments = !this.viewTaskAssignments;
      this.observableSvc.showAssignUsers.next(this.viewTaskAssignments);
      this.selectedLeftGridRowsSize = 0;
  }

  addUserRole() {
    this.rightGridDataSource = this.rightGrid.dataView.getItems();    
    const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
    const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
    if (selectedProjectApproverList.length !== 0 ||
      selectedProjectReviewerList.length !== 0) {
      this.saveUserRole();
    }
  }

  navigateToProjects() {
    if(this.manageClassify) {
      this.router.navigate([`/zs-cl/projects/${this.projectDetail.project_id}`],{ queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}`],{ queryParamsHandling: 'merge' });
    }
  }

  onCancel() {
    if(this.manageClassify) 
      {
        this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id ],{ queryParamsHandling: 'merge' });                 
      }
      else{
        this.router.navigate(['/zs/projects/', this.projectDetail.project_id ],{ queryParamsHandling: 'merge' });                 
      }
    
  }

  saveUserRole() {
    const parent = this;
    const leftGridDataService = parent.grid.gridService;
    const leftSideGridDataSet = parent.grid.slickGrid.getSelectedRows();

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        parent.rightGridDataSource.map(resp => {
          if (resp.isApproverChecked === true || resp.isReviewerChecked === true) {
            parent.selectedUserRoles.push({ user_id: resp.user_id, role_id: resp.selectedRoleID, cluster_id: row.cluster_id });
          }
        });
      }
    });

    if(this.manageClassify) {
      this.projectSvc.updateTaskToUsers(this.loggedInUserDetails, this.projectDetail.project_id,
        this.selectedUserRoles).subscribe(resp => {
          this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
          if (this.grid) {
            this.grid.dataView.setItems([]);
            this.isLoading = true;
            this.loadClassifyData(1, this.limit);
          }
        }, err => {
          this.selectedUserRoles = [];
          this.messageSvc.sendMessage({ message: 'Users save user role has failed', type: 'INFO', hideboard: true });
        });
    } else {
      // this.projectSvc.saveTasksRoles(this.loggedInUserDetails['userid'], this.projectDetail.projectid, this.selectedUserRoles).subscribe(resp => {
        this.projectSvc.saveTasksToUsers(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.selectedUserRoles).subscribe(resp => {
          this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
          if (this.grid) {
            this.grid.dataView.setItems([]);
            this.isLoading = true;
            this.getProjectTaskList(1, this.limit);
          }
        }, err => {
          this.selectedUserRoles = [];
          this.messageSvc.sendMessage({message: 'Task Assignments Unsuccesfull', type: 'ERROR', hideboard: true});
        });
    }

  }

  onScroll(parentClass) {
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  
  onScrollTask(parentClass){
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasDatasetsScrolled && !this.noData) {
      this.hasDatasetsScrolled = true;
      this.onNextTask();
    }
  }
  downloadTasks() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/tasks/export`,'_self');
    this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/tasks/export`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `classify_training_tasks_${this.projectDetail.project_id}`);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });   
  }

  receiveFileName($event) {
    this.dataRes = $event
    this.is_upload_complete = this.dataRes.is_upload_complete; 
    if(this.is_upload_complete){
      this.projectTasksData['currentpage'] = [];
      if(this.grid){
       this.grid.dataView.setItems(this.projectTasksData['currentpage']);
      }
      this.page=1;
      this.loadClassifyData(this.page, this.limit);
    }   
}

  cancelUpload() {
    this.clearModal =  Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
  }
 
  openPriorFeedbackTaskModal(modal) {    
    $('#' + modal).modal('show');
    this.isPriorFeedbackOpen = true;
  }
  isHavingAccess() {
    if (this.selectedLeftGridRowsSize > 0 && this.isProjectAdmin) {      
      return false;
    } else {
      return true;
    }
  }
  onClose() {
    this.datasets = [];
    if (this.gridDatasets) {
      this.gridDatasets.dataView.setItems(this.datasets);
    }
    this.enableApprove = false;
    this.enableApproved = false;
    this.enableFinishTask = false;
    this.is_approver = false;
    this.is_pending_for_approval = false;
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onMergerFeedbackModalClosed(event) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
  }
  
  onMatcherFeedbackModalClosed(eve) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
  }

  getTaskDetails(id, page, limit) {
    this.datasets = [];
    this.trainModalDatasetSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/tasks/${id}?pagination=true&pageno=${1}&pagesize=${this.limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    if(serverFilter == 'confidence' || serverFilter == 'score' || serverFilter == 'class' || sortfilter.includes('orderby=score') || sortfilter.includes('orderby=confidence') || sortfilter.includes('orderby=class')) {
      serverFilter = serverFilter;
      sortfilter = sortfilter;
    } else {
      serverFilter = '';
      sortfilter = '';
    }
    this.projectSvc.getTaskDetails(this.loggedInUserDetails.user_id,
      this.loggedInUserDetails.tenant_id, this.projectDetail.project_id, this.task_id, page, limit, serverFilter, sortfilter)
      .subscribe(res => {
        if (res) {
          this.datasets = res['task_outputs'];
          this.taskName = res['summary'];
          this.datasetId = res['dataset_id'];
          this.getTaskReviewer();
          this.checkForTaskStatus(res);
          this.checkFinishTaskBtnValidation(res['task_outputs']);
          this.datasets.forEach(element => {
            element.newId = element.id;
            element.datasource_name = element.name;
            element.score100 = (element.score * 100).toFixed();
            element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
            element.shouldDisableFeedback = this.is_task_completed;
            element.id = Math.random();
          });
          if(this.is_task_completed) {
            this.enableFinishTask = false;
            this.enableApprove = false;
          }
          this.datasets.sort(((a, b) => b.score100 - a.score100));
          this.totalDatasets = this.datasets.length;
          this.isLoadingDatasets = false;
          let concatedRows = this.datasets;
          if (this.gridDatasets) {
            concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
            this.datasets = concatedRows;
            this.gridDatasets.dataView.setItems(this.datasets);
            this.gridDatasets.gridService.setSelectedRows([]);
            this.hasDatasetsScrolled = false;
          }
          if (this.datasets.length === 0) {
            this.noData = true;
          }
        }
      });
      this.checkFinishTaskBtnValidation(this.datasets);
  }
  onDatasetsGridCreation(grid) {
    this.gridDatasets = grid;
  }
  
  validateIfSocFeedbackCapturedforProject(){
    if(this.loggedInUserDetails && this.loggedInUserDetails['user_id'] && this.loggedInUserDetails['tenant_id']){
      let self=this;
      this.projectSvc.validateIfClassifySocProjectFeedbackCaptured(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectId, AppGlobals.SEMANTIC_OBJECT_CLASSIFIER)
      .subscribe(feedbackStatus=>{
        if(feedbackStatus){ 
          self.isFeedbackCaptured = feedbackStatus.feedbackCaptured;
        }
        else{
          self.isFeedbackCaptured = false;
        }
        
      },error=>{
          self.isFeedbackCaptured = false;
      })
    }
  }

  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    if (row !== undefined && row !== null) {
      this.showmodelpopup = true;
      this.dsName = row.class;
      if (eventEle.target.className.trim() == 'fal fa-thumbs-down handDown' || eventEle.target.className.trim() == 'fal handDown fa-thumbs-down' || eventEle.target.className.trim() == 'handDown fal fa-thumbs-down' || eventEle.target.className.trim() == 'fal fa-thumbs-down') {
        this.showDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;
        this.screenName = 'classify';
        let dislikeData = {
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 0,
          task_id: this.task_id,
          class: row.class,
          dataset_id: this.datasetId
        };
        this.classInfo = dislikeData;
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          projId: this.projectDetail.project_id,
          payload: dislikeData
        };
        this.rowData = args.row;
        this.classes = 'fal fa-thumbs-down';
      } else if (eventEle.target.className.trim() === 'fal fa-thumbs-up') {
        const upVotedata = {
          dataset_id: this.datasetId,
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 1,
          task_id: this.task_id,
          class: row.class
        };
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          projId: this.projectDetail.project_id,
          payload: upVotedata
        };
        let self = this;
        this.projectSvc.updateTaskFeedback(payload, 'classify').subscribe(resp => {
          $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
          $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
          $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
          $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          if (row.vote === 0) {
            $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
            $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          }
          self.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(args.row);
          // self.projectTasksData['currentpage'] = [];
          // if (self.grid) {
          //   self.grid.dataView.setItems(this.projectTasksData['currentpage']);
          // }
          this.projectSvc.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projectDetail.project_id, this.task_id)
          .subscribe(res => {
            if(resp) {
                this.checkFinishTaskBtnValidation(res['task_outputs']);
              }
            });
          // self.page = 1;
          // self.loadClassifyData(self.page, self.limit);
        }, error => {
          this.messageSvc.sendMessage({ message: error && error.error && error.error.message ? error.error.message : 'Something went wrong while saving feedback', type: 'ERROR', hideboard: true });
        });
      }
    }
  }

/**
   * 
   * Revoking upvote access for non-selected or previously selected as we only allow 1 upvote
   *  for SOC project feedback per task. 
   */
revokeUpvoteAccessForOtherRecordSOCProjectFeedback(upvotedRow) {
  let totalRecords = this.datasets.length;
  for(let row =0; row < totalRecords; row++) {
    if (row != upvotedRow) {
      $('#thumbs-up-' + row).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + row).addClass('fal fa-thumbs-up');
    } 
  }
}

  downVoted(event) {
    if (event) {
      if (this.classes === 'fas fa-thumbs-down') {
        $('#thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
        $('#thumbs-down-' + this.rowData).addClass('fal fa-thumbs-down');
      } else {
        $('#thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.rowData).addClass('fal fa-thumbs-up');
      }
      this.projectSvc.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projectDetail.project_id, this.task_id)
      .subscribe(res => {
          if(res) {
            this.checkFinishTaskBtnValidation(res['task_outputs']);
          }
        });
    }
  }
  closeModalPopup(event) {
    if (event) {
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      this.projectTasksData['currentpage'] = [];
      if (this.grid) {
        this.grid.dataView.setItems(this.projectTasksData['currentpage']);
      }
      this.page = 1;
      this.loadClassifyData(this.page, this.limit);
    }
    this.showDownvoteModal = false;
  }
  onReloadScreen(event) {
    if (event) {
      this.viewTaskAssignments =false;
      if (this.agGrid) {
        this.page = 1;
        this.projectTasksData['currentpage'] = [];
        if(this.manageClassify) {
          this.loadClassifyData(this.page, this.limit);
        } else {
           // close pop-up here openMatcherFeedbackModal, and merger
          this.openMatcherFeedbackModal = false;
          this.openMergerFeedbackModal = false;
          this.getProjectTaskList(this.page, this.limit);
        }
      }
      this.summaryHasChanged = true;
    }
  }
  openModalImport(modal) {
    $('#' + modal).modal('show');
  }


  checkForTaskTypes() {
    let reviewerTasks = this.selectedProjects.every(task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW);
    let approverTasks = this.selectedProjects.every(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));
    let inReviewTasks = this.selectedProjects.some( task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW) 
    let inPendingTasks = this.selectedProjects.some(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));
    
    if (reviewerTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = disabledCheckBoxFormatter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(approverTasks) {
      this.rightGridColumnsDef[3]['formatter'] = disabledCheckBoxFormatter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(inReviewTasks && inPendingTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = true;
    } 
  }

  onUpdateUsers(eve) {
    this.showAssignLoader = eve;
  }

  checkFinishTaskBtnValidation(tasks) {
    if(tasks && tasks.length > 0 && !this.is_task_completed) {
          let at_least_one_vote = tasks.filter(task => task.vote == 1 || task.vote == 0);
          if(this.is_approver && this.is_pending_for_approval) {
            this.enableApprove = at_least_one_vote.length > 0 ? true : false;
          } else {
            this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
          }
        }
      }

    getTaskReviewer(){
      this.ZsClContentService.taskReviewer(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.task_id, this.taskTypeId)
      .subscribe(resp => {
        if (resp.length) {
          this.taskReviewerName = resp[0].first_name;
          this.taskReviewerDate = resp[0].created_ts;
        } else {
          this.taskReviewerName = resp.name;
          this.taskReviewerDate = resp.date;
        } 
      }, error=>{
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
      });
    }

    checkForTaskStatus(taskData) {
      if(taskData && taskData.task_status == AppGlobals.TASK_PENDING_APPROVAL || taskData.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
        this.is_pending_for_approval = true;
        } else {
          this.is_pending_for_approval = false;
        }
      }
      
      checkUserRoleDetails() {
        this.userRole = this.projectSvc.getObjectFromSession('userRole');
        if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_APPROVER) {
          this.is_approver = true;
        }
        if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_REVEIWER) {
          this.is_reviewer = true;
        }
      }

      approveBtn() {
        const data = {
          "user_id": this.loggedInUserDetails.user_id, 
          "tenant_id": this.loggedInUserDetails.tenant_id,
          "projId": this.projectDetail.project_id,
          payload: {
            "task_id": this.task_id
          }
        };
        this.ZsClContentService.approveClassIfySocTaskFeedback(data)
        .subscribe(res => {
          if(res) {
            this.enableApprove = false;
            this.enableApproved = true;
            this.enableFinishTask = true;
          }
        });
      }
      
      finishTasks() {
        const data = {
          "user_id": this.loggedInUserDetails.user_id, 
          "tenant_id": this.loggedInUserDetails.tenant_id,
          "projId": this.projectDetail.project_id,
          payload: {
            "task_id": this.task_id,
            "dataset_id": this.datasetId
          }
        };
        this.ZsClContentService.updateClassIfySocTaskFeedback(data)
        .subscribe(resp => {
          this.messageSvc.sendMessage({ message: resp.message, type: 'SUCCESS', hideboard: true });
          this.onClose();
          this.is_pending_for_approval = false;
          if (this.manageClassify) {
            this.page = 1;
            if (this.grid) {
              this.grid.dataView.setItems([]);
              this.projectTasksData['currentpage'] = [];
            }
            
            this.loadClassifyData(this.page, this.limit);
          }
        });
      }

      triggerClassifyReslutsModal() {
        this.showResultsListModal = true;
      }
    
      hideClassifyResultsModal(eve) {
        this.showResultsListModal = eve;
      }
    
}
