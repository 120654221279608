import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  template: `
  <!-- <div> -->
            <ng-container *ngIf="shouldDisplayCheckbox">
            <input [id]="'attribute-' + columnDef.field + '-' + params.rowIndex" class="entity-checkbox" type="checkbox" [disabled]="hideElement === 'disabled'">
            </ng-container>
            <ng-container *ngIf="shouldDisplayInput">
            <input [id]="'attribute-' + columnDef.field + '-' + params.rowIndex" class="ctmInputBox textInputBox" type="text" [placeholder]="params.placeholder" [tabindex]="params.tabindex" autofocus value="{{params.value}}" [disabled]="hideElement === 'disabled'">
            </ng-container>
            <ng-container>
            <div *ngIf="shouldDisplayYesNo" [class.c-green]="value === true" [class.c-red]="value === false">
            {{ value ? 'Yes' : 'No' }}
            </div>
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayCopyIcon">
              <div class="w-100 text-center c-blue"><i class="fal fa-copy"></i></div> : ''
              </div>
            </ng-container>

            <ng-container>
              <div *ngIf="shouldDisplayRedNotActiveArrow">
                <div class="w-100 text-center text-bold">
                    <i class="fa fa-arrow-up notActiveRedFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                    <i class="fa fa-arrow-down redFont" style="cursor:pointer;" aria-hidden="true"></i>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="shouldDisplayGreenRedNotActive">
            <div class="w-100 text-center text-bold">
                        <i class="fa fa-arrow-up greenFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                        <i class="fa fa-arrow-down notActiveRedFont" style="cursor:pointer;" aria-hidden="true"></i>
                    </div>
            </ng-container>
            <ng-container *ngIf="shouldDisplayGreenRedActive">
            <div class="w-100 text-center text-bold">
                        <i class="fa fa-arrow-up greenFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                        <i class="fa fa-arrow-down redFont" style="cursor:pointer;" aria-hidden="true"></i>
                    </div>
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayAttributeValue">
              <div class='w-100 text-truncate text-right pr-1'> {{params.value}}</div>
              </div>
            </ng-container>
          
            <ng-container>
              <div *ngIf="shouldDisplayCommonAttrValue">
              <div class='w-100 text-truncate pr-2 w-100'>{{params?.value}}</div>
              </div>
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayDefaultAttrValue">
              <div class='text-truncate' title='{{params.value}}'>{{params.value}}</div>
              </div>
            </ng-container>  
  `,
  styles: [
    // Add your styles here
    // For example:
    '.c-green { color: green; } .c-red { color: red; } .entity-checkbox, .ctmInputBox { /* Your styles */ }'
  ]
})
export class CustomCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  value: any;
  columnDef: any;
  shouldDisplayCheckbox = false;
  shouldDisplayInput = false;
  shouldDisplayYesNo = false;
  shouldDisplayCopyIcon = false;
  shouldDisplayRedNotActiveArrow = false;
  shouldDisplayAttributeValue = false;
  shouldDisplayCommonAttrValue = false;
  shouldDisplayDefaultAttrValue = false;
  shouldDisplayGreenRedNotActive = false;
  shouldDisplayGreenRedActive = false;

  hideElement = 'disabled'; // Default value

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const {  column, value, data, rowIndex } = params;
    const { field } = column.getColDef();
    this.params = params; 
    this.columnDef = params.column.getColDef();
    let result = '';
    // Custom logic to determine display conditions
    if (data.action !== 'delete') {
      if (field === 'is_visible_output' || field === 'is_visible_input') {
        this.shouldDisplayCheckbox = true;
      } else if (field === 'sort_order' || field === 'attribute_id') {
        result = '';
      }else {
        this.shouldDisplayInput = true;
      }
    } else {
      if (field === 'is_visible_output') {
        this.shouldDisplayYesNo = true;
      } else if (field === 'is_visible_input') {
        if(value === true ){
          this.shouldDisplayCopyIcon = true;
        }
        
      } else if (field === 'sort_order'){ 
          if(value === 1) {
            this.shouldDisplayRedNotActiveArrow = true;
          } 
          else if(params.rowIndex === data.grdLenght){
            this.shouldDisplayGreenRedNotActive = true;
          }else {
            this.shouldDisplayGreenRedActive = true;
          }


      } else if( field === 'attribute_id') {
        this.shouldDisplayAttributeValue = true;
      } else if(field === 'logical_name' || field === 'physical_name' || field === 'description') {
        this.shouldDisplayCommonAttrValue = true;
      } else {
        this.shouldDisplayDefaultAttrValue = true;
      } 
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false; // Returning false ensures the cell isn't refreshed
  }
}