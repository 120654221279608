import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { environment } from 'src/environments/environment';
declare var $: any;


const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="datasource-name-formatter text-wrap">
                <span title='${dataContext.class}' class="pointer hyper-link">${dataContext.class}</span><br>
            </div>`;
};
const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
};
const mappingColumnmDownvoteFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let txt = '';

  if (level === 'HIGH') { txt = txt + `<div class="box-container text-center text-uppercase" title='${level}'>${level}</div>`; }
  if (level === 'MEDIUM') { txt = txt + `<div class="box-container boxM text-center text-uppercase" title='${level}'>${level}</div>`; }
  if (level === 'LOW') { txt = txt + `<div class="box-container boxL text-center text-uppercase" title='${level}'>${level}</div>`; }

  txt = txt + `<div class="porcent text-center" title='${score100}%'>${score100}%</div>`;
  return txt;
};
@Component({
  selector: 'zetta-zs-cl-training-downvote',
  templateUrl: './zs-cl-training-downvote.component.html',
  styleUrls: ['./zs-cl-training-downvote.component.scss']
})
export class ZsClTrainingDownvoteComponent implements OnInit, OnChanges {

  public modalGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  modeltableSettings: object = { 'height': "86%", 'width': "99.50%", 'border-top': "100%" };
  modalGridOptions: any;
  modelColumnDef = [];
  noData = false;
  ds_classification: any = [];
  is_downvote_loading = true;
  old_row = 0;
  selectedRowIndex: boolean = false;
  ddl_catalogs: any = [];
  ddl_semantics: any = [];
  ddl_concepts: any = [];
  ddl_catalogs_id;
  ddl_concept_id;
  ddl_object_id;
  selectedTag = "";
  selectedTagOwn = "";
  public gridClassification: AngularGridInstance;
  classificationTableColDef: any;
  gridDataOptions: any;
  showModal: boolean;
  selectedRow: object = {};
  showDuplicationMessage: boolean = false;
  @Input() dsName: any;
  @Input() dataSetId: any;
  @Input() count;
  @Input() dataset_column_id;
  @Input() catalog_id;
  @Input() object_id;
  @Input() mapping_type;
  @Input() ds_column_name;
  @Input() attribute_id;
  @Input() showConcept: boolean;
  @Input() showBackdrop: boolean;
  @Input() classInfo;
  @Input() screenName;
  @Input() projectId: any;
  @Input() socReviewPriorFeedback: boolean = false;
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
  @Output() disliked: EventEmitter<any> = new EventEmitter();
  newClassValue: any = '';
  projId: any;
  className: string;
  tagSelected: Boolean = false;
  page = 1;
  limit = 100;
  hasScrolled = false;

  constructor(
    private catalogSvc: ZsClContentService,
    private projService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils:ZettaUtils,
    private messageSvc: MessageService
  ) {
  }

  ngOnInit(): void {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.modelColumnDef = [{
      'displayname': 'Class Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'maxWidth': 350,
      'minWidth': 200
    },
    //  {
    //   'displayname': 'Mapping Confidence Level',
    //   'physicalname': '',
    //   'sortable': true,
    //   'datatype': 'String',
    //   'filterable': true,
    //   'formatter': mappingColumnmDownvoteFormatter,
    //   'minWidth': 200,
    //   'maxWidth': 390,
    // }
  ];

    this.activatedRoute.parent.params.subscribe(params => {
      this.projId = params['id'];
    });

      this.modelColumnDef.splice(0, 0, {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 20,
        'maxWidth': 35,
      });
    
    if(this.showConcept){
      this.getClassificationTags();
    }else{
      this.getClassificationObjectTags(this.page, this.limit);
    }
    this.getAllCatalogs();

    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(!this.projId) {
        if(changes && changes.projectId && changes.projectId.currentValue) {
          this.projId = changes.projectId.currentValue;
        }
        if(changes && changes.showConcept && changes.showConcept.currentValue){
          this.getClassificationTags();
        }else{
          this.getClassificationObjectTags(this.page, this.limit);
        }
        if(changes && changes.socReviewPriorFeedback && changes.socReviewPriorFeedback.currentValue) {
          this.socReviewPriorFeedback = changes.socReviewPriorFeedback.currentValue;
        }
      }   
    }
  }
  onClassiCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.modalGrid.gridService.getDataItemByRowIndex(args.row);
    if (eventEle.target.className.includes('radio-formatter')) {
      $("#data-" + this.old_row).prop('checked', false);
      this.selectedTag = row.attribute_id;
      $("#tagKeep").prop('checked', false);
      $("#tagNotSure").prop('checked', false);
      $("#rbtConcept").prop('checked', false);
      $("#rbtObject").prop('checked', false);
      this.old_row = args.row;
      this.selectedRow = row;
      this.selectedRowIndex = (args.row>=0) ? true : false;
      if(this.selectedRowIndex) {
        $(`input[id=data-${this.old_row}]`).prop('checked', true);
      }
      this.tagSelected = true;
      this.classInfo.class = row.class;
      this.classInfo.vote = 1;
      this.classInfo.down_vote_class=this.dsName;
      this.classInfo.is_manual = row.is_manual;
      if(!this.showConcept){
        this.selectedTag = row.entity_id;
      }
    }
  }
  getAllCatalogs() {
    // this.catalogSvc.getCatalogs(this.loggedInUserDetails['user_id']).subscribe(resp => {
    //   this.ddl_catalogs = resp;
    // });
  }
  getAllSemanticObjects(catalogId) {
    // this.catalogSvc.getSemanticsObjects(catalogId).subscribe(resp => {
    //   this.ddl_semantics = resp;
    // });
  }
  getSelectedConcept(objectId) {
    // this.catalogSvc.getConcepts(this.ddl_catalogs_id, objectId).subscribe(resp => {
    //   this.ddl_concepts = resp;
    // });
  }
  onChangeNotSure() {
    $("#data-" + this.old_row).prop('checked', false);
    $("#rbtConcept").prop('checked', false);
    this.selectedTag = "not_sure";
  }
  onChangeRbtConcept() {
    $("#data-" + this.old_row).prop('checked', false);
    $("#rbtConcept").prop('checked', true);
    if (this.ddl_concept_id) {
      this.selectedTag = this.ddl_concept_id;
    }
  }
  onChangeRbtObject() {
    $("#data-" + this.old_row).prop('checked', false);
    $("#rbtObject").prop('checked', true);
    if (this.ddl_object_id) {
      this.selectedTag = this.ddl_object_id;
    }
  }
  onChangesKeepTag() {
    $("#data-" + this.old_row).prop('checked', false);
    $("#tagKeep").prop('checked', true);
    this.selectedTag = undefined;
    this.tagSelected = false;
  }
  onChangeCatalog(event) {
    this.ddl_catalogs_id = event.target.value;
    this.getAllSemanticObjects(this.ddl_catalogs_id);
  }
  onChangesObject(event) {
    this.ddl_object_id = event.target.value;
    if (this.showConcept) {
      this.getSelectedConcept(this.ddl_object_id);
    }
  }
  onChangesConcept(event) {
    this.ddl_concept_id = event.target.value;
    if ($("#rbtConcept").prop("checked")) {
      this.selectedTag = this.ddl_concept_id;
    }

  }
  getClassificationObjectTags(pageNo, limit) {
    this.projId = this.projId ? this.projId : this.projectId;
      if(this.loggedInUserDetails && this.loggedInUserDetails['user_id']) {
        this.modeltableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/feedback/classes?paginate=true&pageno=${1}&pagesize=${limit}`;
  
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }



    if(this.loggedInUserDetails && this.loggedInUserDetails['user_id']) {
      this.projService.getClassNames(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, pageNo, limit, serverFilter, sortfilter)
      
    .subscribe(res => {
      // this.ds_classification = res['currentpage'];
      if (res && res['currentpage'] && res['currentpage'].length) {
        this.ds_classification = res['currentpage'].map((existingClass) => {
            existingClass.is_manual = false;
            return existingClass;
        })
      }
      if (this.ds_classification) {
        this.bindGrid(this.ds_classification);
      }
    }, err => {
      this.is_downvote_loading = false;
      ($('#classification_tag_model') as any).modal('show');
      this.hasScrolled = false;
    });
    }}
  }
  bindGrid(data) {
    this.ds_classification = data;
    let concatedRows = this.ds_classification; 
    if (this.ds_classification.length === 0) {
      this.noData = true;
    }
    this.ds_classification.forEach(element => {
      element.id = Math.random();
      element.level = (element.score * 100) >= 75 ? 'HIGH' : (element.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
      element.score100 = Math.round(element.score * 100);
      element.checked = element.level === 'HIGH' ? true : false;
      element.is_manual = element.is_manual ? true : false
    });
    this.ds_classification.sort(((a, b) => b.score100 - a.score100));
    this.is_downvote_loading = false;
    ($('#classification_tag_model') as any).modal('show');
    if (this.modalGrid) {
      this.ds_classification.forEach(element => {
        element.isClassifyProject = true;
      });     
        concatedRows = [...this.modalGrid.dataView.getItems(), ...this.ds_classification];
        this.ds_classification.forEach(element => {
          element.id = Math.random();
        });
        this.ds_classification = concatedRows;
        if(this.selectedRowIndex && this.old_row>=0) {
          $(`input[id=data-${this.old_row}]`).prop('checked', true);
        }
        this.modalGrid.dataView.setItems(concatedRows);
        this.modalGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      this.hasScrolled = false;
  }
}
  getClassificationTags() {

    this.modeltableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/feedback/classes?paginate=true&pageno=1&pagesize=20`;
  
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      serverFilter = sessionStorage.getItem("sortfilter");
    }



      this.projService.getClassNames(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, serverFilter, sortfilter)
      .subscribe(res => {
       const data = res['currentpage'];
      if (data) {
        this.bindGrid(data)
      }
    }, err => {
      this.is_downvote_loading = false;
      ($('#classification_tag_model') as any).modal('show');
    });
  }
 
  saveClassificationTags() {
    if (this.showConcept) {
      if (this.selectedTag !== "" && this.selectedTag !== undefined) {
        
          this.downVote();
        }
    } else {
      this.saveClassificationObjectTags();
    }
    $('#classification_tag_model').modal('hide');
    this.selectedTag = "";
    this.selectedTagOwn = "";
  }

   
  downVote() {
  if(this.socReviewPriorFeedback) {
    this.disliked.emit({voted: true, classdata: this.classInfo});
    this.modelPopupOutput.emit(true); 
  } else {
    const payloadData: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      projId: this.projId,
      payload: this.classInfo
    }; 

    this.projService.updateTaskFeedback(payloadData,this.screenName)
    .subscribe(res => {
      if(res) {
        this.disliked.emit(true);
        // console.log("RES GOT TO OUTPUT EVENT   " + res);
        $('#divBackdrop').addClass('d-none');
          this.modelPopupOutput.emit(true);
      } else {
        this.disliked.emit(false);
      }
    },error=>{
      this.modelPopupOutput.emit(false);
    })
  }

    // if (this.showConcept) {
    //   // this.catalogSvc.downVote(payloadData).subscribe((data) => {
    //     this.modelPopupOutput.emit(true);
    //   // }, err => { });
    // } else {
      $('#divBackdrop').addClass('d-none');
      // this.catalogSvc.saveVoteSemanticObject(payloadData, 'down_vote').subscribe(resp => {
     //   this.modelPopupOutput.emit(true);
      // }, error=>{        
      // }
      // );
    // }
  }

  closeClassificationTags() {
    $('#classification_tag_model').modal('hide');
    this.selectedTag = "";
    this.selectedTagOwn = "";
    this.modelPopupOutput.emit(false);
  }


  onModelGridCreation(grid) {
    this.modalGrid = grid;
    this.modalGrid.slickGrid.onScroll.subscribe(resp => {
      setTimeout(() => {
      if(this.selectedRowIndex) {
        $(`input[id=data-${this.old_row}]`).prop('checked', true);
      }
    }, 250);
    });
    this.modalGrid.slickGrid.onSort.subscribe(resp => {
      sessionStorage.setItem("resetpage_filter", "true");
      this.noData = false;
      this.hasScrolled = false;
    });
}
  onClassificationGridCreation(grid) {
    this.gridClassification = grid;
  }
  closeModelPopup() {
    this.modelPopupOutput.emit(false);
  }

  saveClassificationObjectTags() {
    let mappingType = 'manual'
    if (this.selectedTag && this.selectedTag === 'not_sure') {
      mappingType = 'not_sure';
      const payload = {
        "dataset_id": this.dataSetId,
        "mapping_type": mappingType,      
        "entity_id": this.object_id,   /// have to remove this line after fixing  this from api  
        "removing_entity_id": +this.object_id,
        "catalog_id": +this.catalog_id,
        "is_semantic_level_mapping": true
      }
      this.downVote();
    }else{
      const payload = {
        "dataset_id": this.dataSetId,
        "mapping_type": mappingType,
        "entity_id": this.selectedTag,
        "removing_entity_id": +this.object_id,
        "catalog_id": +this.catalog_id,
        "is_semantic_level_mapping": true
      }
      this.downVote();
    }
  }

  addNewClassName(event: Event) {
    event.preventDefault();
    this.showDuplicationMessage = false;
    if (!this.className || this.className.trim() === '') {
      return; // Do nothing if className is empty
    }
    if(this.modalGrid) {
      let items = this.modalGrid.dataView.getItems();
      this.ds_classification = items;
    }

    this.ds_classification.forEach(classname => {
      if(classname.class == this.className) {
        this.showDuplicationMessage = true;
      }
    });
    if(this.showDuplicationMessage) {
      this.messageSvc.sendMessage({ message: `${this.className} already exists`, type: 'INFO', hideboard: true });
      this.showDuplicationMessage = false;
    } else {      
      this.ds_classification.unshift(
        {
          "class": this.className,
          "score": 1,
          "confidence": "H",
          "is_manual": true
          },
        );
      this.ds_classification.forEach(element => {
        element.id = Math.random();
        element.level = (element.score * 100) >= 75 ? 'HIGH' : (element.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
        element.score100 = Math.round(element.score * 100);
        element.checked = element.level === 'HIGH' ? true : false;
      });
      this.ds_classification.sort(((a, b) => b.score100 - a.score100));
      this.is_downvote_loading = false;
      this.modalGrid.dataView.refresh();
      this.gridClassification = this.modalGrid;
      if (this.gridClassification) {
        this.gridClassification.dataView.setItems(this.ds_classification);
        this.gridClassification.gridService.setSelectedRows([]);
      }
      this.className = '';
      $("#className").val('');
      this.classInfo.class = this.className;
    }
  }
  
  onScroll(parentClass) {
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  onNext(): void {
    this.page++;
    this.getClassificationObjectTags(this.page, this.limit);
  }

onDataFiltered(searchedData) {
  setTimeout(() => {
    let data = searchedData;
    let selectedInd = -1;
    this.old_row = -1;
    if(searchedData && searchedData.length>0) {
      let foundEle = searchedData.findIndex( (elem, i) => {
        $(`input[id=data-${i}]`).prop('checked', false);
        if(this.selectedRow && this.selectedRow['class'] && elem.class === this.selectedRow['class']) {
          selectedInd = i;
          return elem.class === this.selectedRow['class'] ? (selectedInd = i, true) : false;
        } else {
          selectedInd = -1;
        }
    });
      this.modalGrid.dataView.refresh();
      if(Number(foundEle)>=0 && this.selectedRowIndex && Number(selectedInd) >=0 ) {
          $(`input[id=data-${selectedInd}]`).prop('checked', true);
          this.old_row = selectedInd;
        } else {
          $(`input[id=data-${this.old_row}]`).prop('checked', false);
          selectedInd = -1;
          this.old_row = -1;
          return;
        }
      }
  }, 250);
  }

  onSearchFilteredData(searchedData) {
    sessionStorage.setItem("resetpage_filter", "true");
    this.noData = false;
    this.hasScrolled = false;
    let data = searchedData['items'];
    let selectedInd = -1;
    this.old_row = -1;
    if(data && data.length>0) {
      let foundEle = data.findIndex( (elem, i) => {
        $(`input[id=data-${i}]`).prop('checked', false);
        if(this.selectedRow && this.selectedRow['class'] && elem.class === this.selectedRow['class']) {
          selectedInd = i;
          return elem.class === this.selectedRow['class'] ? (selectedInd = i, true) : false;
        } else {
          selectedInd = -1;
        }
    });
      this.modalGrid.dataView.refresh();
      if(Number(foundEle)>=0 && this.selectedRowIndex && Number(selectedInd) >=0 ) {
          $(`input[id=data-${selectedInd}]`).prop('checked', true);
          this.old_row = selectedInd;
        } else {
          $(`input[id=data-${this.old_row}]`).prop('checked', false);
          selectedInd = -1;
          this.old_row = -1;
          return;
        }
      }

  }
}
