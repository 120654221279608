import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';

@Component({
  selector: 'zetta-zs-in-ingestion',
  templateUrl: './zs-in-ingestion.component.html',
  styleUrls: ['./zs-in-ingestion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsInIngestionComponent implements OnInit {

  safeSrc: SafeResourceUrl;
  url;
  // pipelineType;

  constructor(private sanitizer: DomSanitizer,
              private baseService: BaseService) { }

  ngOnInit() {

    //Ingestion Pipelines: http://40.121.147.116:18630/        admin/admin
    //Transformation Pipelines: http://40.121.147.116:19630/        admin/admin

    this.url = 'http://40.121.147.116:18630/';

    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    
  }

}