import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ZettaUtils } from '../../zettaUtils';

@Component({
  selector: 'app-datasource-type-formatter',
  template: `
    <div>
      <img [src]="getIconByTypeID()" class="type-img pr-1">
      <span [title]="value.datasource_type">{{ value.datasource_type }}</span>
    </div>
  `
})
export class DatasourceTypeFormatterComponent implements ICellRendererAngularComp {
  value: any;
  zettaUtilsInstance = new ZettaUtils();

  agInit(params: ICellRendererParams): void {
    this.value = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.data;
    return true;
  }

  getIconByTypeID(): string {
    return this.zettaUtilsInstance.getDatasourceTypeById(this.value.datasource_type_id);
  }
}
