import { Component, OnInit } from '@angular/core';
import { AnalyticService } from '../analytic.service';
import { ZmZettaUtils } from '../../../../common/zm-shared/zm-zettaUtils';
import { Router } from '@angular/router';

@Component({
	selector: 'zm-my-analytics',
	templateUrl: './my-analytics.component.html',
	styleUrls: ['./my-analytics.component.scss']
})

export class MyAnalyticsComponent implements OnInit {
	public myAnalyticsDetails: any = [];
	public images: any = [];
	public _userInfo;
	public isLoading: boolean = true;

	constructor(
		public service: AnalyticService,
		protected zettaUtils: ZmZettaUtils,
		private router: Router) { 
		this._userInfo = this.service.getObjectFromSession('userInfo');
	}

	ngOnInit() {
		if (this._userInfo){
			this.service.getAnalytics(this._userInfo['userid'], 1, 5).subscribe(responseList => {
				this.myAnalyticsDetails = responseList['currentpage'].splice(0, 3);
				let i: number = 0;
				this.myAnalyticsDetails.forEach(analyticItem => {
					
					if(analyticItem.algoid == 11) { this.images[i] = "icon-analytics-correlation.png" }
					if(analyticItem.algoid == 12) { this.images[i] = "icon-analytics-classification.png" }
					if(analyticItem.algoid == 13) { this.images[i] = "icon-analytics-clustering.png" }
					if(analyticItem.algoid == 14) { this.images[i] = "icon-analytics-simulation.png" }
				
					// if (!(analyticItem.mapped_attributes_count && analyticItem.mapped_attributes_count > 0)) {
					// 	analyticItem.finishSetup = true;
					// } else {
					// 	analyticItem.hasJob = true;
					// 	this.service.getCurrentActiveJobs(this.service.getObjectFromSession('userInfo').userid, analyticItem.projectid).subscribe(
					// 		jobs => {
					// 			if (jobs.jobcompleted === undefined) {
					// 				analyticItem.hasJob = false;
					// 			}
					// 		});
					// }
					i++;
				});
				this.isLoading = false;
			},
			error => {
				this.myAnalyticsDetails = [];
			});
		}
	}

	redirectToAnalyticDetails(analytic) {

		this.router.navigate(['/zm/analytics/' + analytic.analyticid]);

		//We can to use when the API's ready
		// if (analytic.finishSetup) {
		// 	this.router.navigate(['/zm/analytics/create-project'], { queryParams: { id: analytic.analyticid } });
		// } else if (analytic.finishSetup === undefined && !analytic.hasJob) {
		// 		this.service.runModel(analytic.analyticid, 0).subscribe(resp => {
		// 			this.router.navigate(['/zm/analytics', analytic.analyticid]);
		// 		}, err => {
		// 			alert(err.error.message);
		// 		});
		// } else {
		// 	this.router.navigate(['/zm/analytics/' + analytic.analyticid]);
		// }
	}

	onRunModel(analyticItem) {
        this.service.runModel(analyticItem.analyticid, 0).subscribe(resp => {
            this.router.navigate(['/zm/analytics', analyticItem.analyticid]);
        }, err => {
            // alert(err.error.message);
        });
	}
}
