import { Component, Input, OnInit,ViewChild,SimpleChanges, Output, EventEmitter } from "@angular/core";
import { BaseChartDirective, Label } from 'ng2-charts';
import { ChartType, ChartDataSets, ChartConfiguration } from 'chart.js';
import { DatasetService } from "src/app/common/components/datasets/dataset.service";
import { MessageService } from "src/app/common/components/message/message.service";
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { Router } from "@angular/router";
declare var $: any;


@Component({
    selector:'zetta-profile-result',
    templateUrl:'./profile-result.component.html',
    styleUrls:['./profile-result.component.scss']
})
export class ProfileResult implements OnInit{

 @Input() dataset_id:string;
 @Input() column_id:string;  
 @Input() showHeader:boolean; 
 @Input() showNodataMsg?: boolean = false;
 profile_report: any;
 loggedInUserDetails:any;
 is_profile_loading=false;
 is_no_data:boolean
 is_primary_key=false;
 data_sets=[];
 datasetTags: any = [];
 isDatasetProfileCalled = false;
 
 public barChartLabels: Label[] = ['', '', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[];  
  public chartColors: any[] = [
    {
      backgroundColor: '#EFB049'
    }];  
    
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false,
        ticks: {
          display: true,
        }
      }],
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };


 constructor(private datasetService: DatasetService, private messageSvc:MessageService, 
  public zettaUtils:ZettaUtils, private router:Router){
 }

ngOnInit(): void {
}
ngOnChanges(changes: SimpleChanges) {
    if(changes.column_id && changes.column_id.currentValue){
        this.column_id = changes.column_id.currentValue;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.getDataset();
        this.getDatasetProfile()
    }
}
 getDatasetProfile() {
    const Datasets= {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString()
    };    
    this.datasetService.getDatasetProfileResult(Datasets, this.column_id).subscribe(resp => {
      if (resp) {
        this.isDatasetProfileCalled = true;
        this.is_profile_loading = false;
        if (!resp.profile_report) {
          this.is_no_data = false;
        }
        this.profile_report = resp.profile_report;
        let chartArry: any[] = [];
        if (resp && resp.profile_report) {
          this.is_primary_key = this.profile_report.primary_key_candidate;
          this.profile_report.most_frequent.forEach(el => {
            chartArry.push(el.distribution);
          });
          this.barChartData = [{ data: chartArry, label: 'Most Frequent Data' }]
        }
      }

      this.is_profile_loading = false;
      this.datasetTags = [];
      if (resp.tags != null) {
        this.datasetTags = resp.tags_score;
      }
    }, err => { 
      this.is_profile_loading = false; 
      if(err.status === 404){
        this.messageSvc.sendMessage({message: err.error.message, type: 'ERROR', hideboard: true});
      }
    });
  }
  
  getDataset() {
    const Datasets= {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString()
    }
    this.datasetService.getDataset(Datasets).subscribe((responseList) => {
      if (responseList) {
        this.data_sets = responseList;
      }
      this.datasetTags = [];
      if (responseList.tags != null) {
        this.datasetTags = responseList.tags_score;
      }
    }, err => { this.is_profile_loading = false; });
  }

  onDatasetProfile() {
    $('.modal').modal('hide');
    this.router.navigate(['/zs/datasets', this.dataset_id], { queryParams: { showBreadcrumb: true } });
  }
}