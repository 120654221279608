import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetService } from '../../dataset.service';
import { MessageService } from '../../../message/message.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import _ from 'lodash';
declare var $: any;
import { ObservableService } from '../../../../../common/services/observable.service';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ZsClContentService } from '../../../../../zettaclassify/zs-cl-content/zs-cl-content.service';
import { dateFormatter } from '../../../../../common/shared/formatters/dateFormatter';
import { environment } from '../../../../../../environments/environment';
import { IDataset } from '../../dataset.model';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { dqRuleNameFormattter } from 'src/app/common/shared/formatters/dqRuleNameFormatter';



const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="text-truncate">
              <span title='${value}' class="pointer hyper-link text-truncate" style="line-height: 8px !important">${value}</span><br>
          </div>`;
};

const emptyFormattter: Formatter = () => {
  return ``;
};

const porcentFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let txt = '';
  let classBox = '';
  value >= 70 ? classBox = '' : value >= 50 ? classBox = 'boxM' : classBox = 'boxL';
  txt = txt + `<div class="box-container ${classBox} text-center" style="font-family: 'Inter Black'">${value}%</div>`
  value === '' ? txt = '' : txt = txt;
    
  return txt;
};


@Component({
  selector: 'zetta-dataset-data-quality',
  templateUrl: './dataset-data-quality.component.html',
  styleUrls: ['./dataset-data-quality.component.scss']  
})
export class DatasetDataQualityComponent implements OnInit, OnDestroy {

  public tableSettings: object = { 'height': '100%', 'width': "99.10%", 'pageSize': 20, 'isNormalPagination': false };
  public tableSettingsRule: object = { 'height': '100%', 'width': "99.15%", 'pageSize': 15, 'isNormalPagination': false };
  public tableSettingsExcep: object = { 'height': '100%', 'width': "99.15%", 'pageSize': 15, 'isNormalPagination': false };
  public loggedInUserDetails;
  public datasetAttributes: any = [];
  grid: AngularGridInstance;
  gridRules: AngularGridInstance; 
  gridExceptions: AngularGridInstance; 
  attributesTableColDef: any;
  rulesTableColDef: any;
  exceptionsTableColDef: any;
  datasetColumn: any = [];
  gridOptions: any;
  gridDataOptions: any;
  dataset_id = 0;
  totalColumns = 0;
  attributeName = '';
  noData = false;
  grandTotalColumn = 0;
  grandTotalRule = 0;
  subTotalColumn = '';
  subTotalRule = '';
  noDataExceptions = false;
  pageno = 1;
  pagenoExceptions = 1;
  limit = 100;
  limitExceptions = 100;
  hasScrolled = false;
  hasScrolledColumns = false;
  hasScrolledExceptions = false;
  rules: any = [];
  exceptions: any = [];
  selectRule = true;
  totalRules;
  totalExceptions;
  dataset_column_id;
  is_attr_loading = true;
  is_rules_loading = false;
  is_classify = false;
  is_classify_app = false;
  ruleName = '';
  ruleId;
  is_exceptions_loading: Boolean = true;
  // is_scrolled_loading = false;
  summary: any = {measured_dq_score: 0, good_records: 0, bad_records: 0, total_records: 0 };
  dimensions;
  measured_rules: string;
  dqRuleQuryParams:string;
  measured_dq_score:number;
  measured_rule_css:string;
  last_updated_date:Date;
  good_records:number;
  bad_records:number;
  total_records:number;
  today;
  getDataSet: any = [];
  total = 0;
  pageNo = 1;
  limitNo= 20;
  isFiltering = false;
  isSorting=false;
  @Input() dsDqScore:any;
  public lineProjectResolvedChartDS = null;
  public lineChartUniqueID: any = '';
  public lineChartOptions: object = {
		'isMoneyFormat': false,
		'height': 55,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#e5b500',
		'circleColor': '#ff0000'
	};
  public lineChartDQRule = null;
  dataQualityRule: any = [];
  sequencesDQ: [];
  isMultiAttributeRule: Boolean;
  isRuleAdmin = false;
  isRuleExecuter = false;
  constructor(private datasetService: DatasetService,
              private activatedRoute: ActivatedRoute,
              private messageSvc: MessageService,
              public observeSvc: ObservableService,
              private zsClContentService: ZsClContentService,
              private zettaUtils: ZettaUtils,
              private router: Router,
  ) { 
    this.lineChartUniqueID = this.zettaUtils.generateUUID();
  }

  ngOnInit() {
    this.loggedInUserDetails = this.datasetService.getObjectFromSession('userInfo');
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    const apps = JSON.parse(sessionStorage.userInfo).apps;
    if (apps.length) {
      const cl_apps = apps.filter(item => item.name == 'Classify');
      if (cl_apps.length) {
        this.is_classify_app = true;
      }
    }
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.getDatasetAttributeList(this.pageNo, this.limit);

    this.attributesTableColDef = [
      {
        'displayname': '',
        'physicalname': '',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': emptyFormattter,
        'maxWidth': 9,
      }, {
        'displayname': 'Attribute',
        'physicalname': 'column',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'formatter': datasetNameFormattter,
      }, {
        'displayname': 'DQ Score',
        'physicalname': 'measured_dq_score',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': measuredDQFormatter,
        'maxWidth': 100,
      }
    ];

    this.rulesTableColDef = [
      {
        'displayname': '',
        'physicalname': '',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': emptyFormattter,
        'maxWidth': 9,
      }, {
        'displayname': 'Rule ID',
        'physicalname': 'rule_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'maxWidth': 60,
      }, {
        'displayname': 'Rule Name',
        'physicalname': 'rule_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'formatter': dqRuleNameFormattter,
        'minWidth': 60,
        // 'maxWidth': 100,
      }, {
        'displayname': 'DQ Dimension',
        'physicalname': 'rule_dimension',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 120,
        'maxWidth': 120,
      }, {
        'displayname': '# of Exceptions',
        'physicalname': 'exceptions',
        'sortable': true,
        'datatype': 'Numeric',
        'filterable': true,
        'minWidth': 70,
        'maxWidth': 90,
        'headerCssClass': 'text-left titleBreak',
      }, {
        'displayname': 'Rule Score',
        'physicalname': 'measured_dq_score',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': measuredDQFormatter,
        'minWidth': 90,
        'maxWidth': 90,
        //'headerCssClass': 'text-left titleBreak',
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      editable: true,
      autoEdit: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 30,
    };
    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30,
    };

  }

  getDatasetAttributeList(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${ this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/dqrules/columns?pagination=true&pageno=1&pagesize=${limit}`;   
    var serverfilter = "";
    if(sessionStorage.getItem("serverfilter")){
      var serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if(this.isFiltering){ 
        pageNo = 1;
      }
      this.isFiltering = false;
    }
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   }
    this.datasetService.getDQDatasetColumnList(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, 'true',pageNo,limit,serverfilter, sortfilter).subscribe((data) => {
      this.datasetAttributes = data['currentpage'];
        this.is_attr_loading = false;
        this.totalColumns = this.datasetAttributes.length;
        this.total = data['totalrecords'];
        
        const firstRow = this.addFirstRow();

        if ( pageNo == 1 ) {
          this.grandTotalColumn = data['totalrecords'];
          this.datasetAttributes.splice(0, 0, firstRow);
        }

        this.datasetAttributes.forEach(element => {
          element.id = Math.random();
        });
        let concatedRows = this.datasetAttributes;
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.datasetAttributes];
          this.datasetAttributes.forEach(element => {
            element.id = Math.random();
          });
          this.datasetAttributes = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.dataView.setItems(this.datasetAttributes);
          this.grid.gridService.setSelectedRows([]);
          if(pageNo < data['totalpages']) {
            this.hasScrolledColumns = false;
          } else {
            this.hasScrolledColumns = true;
          }
        }
    }, err => {
      this.is_attr_loading = false;
      this.datasetAttributes = [];
    });
  }

  getDQDatasetRules() {
    this.datasetService.getDQDatasetRules(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, this.dataset_column_id).subscribe((data) => {
      this.rules = data;
      if (this.rules.length === 0) {
        this.noData = true;
      }
      this.rules.forEach(element => {
        element.id = Math.random();
      });
      this.totalRules = this.rules.length;
      let concatedRows = this.rules;
      if (this.gridRules) {
        // concatedRows = [...this.gridRules.dataView.getItems(), ...this.rules];
        // this.rules = concatedRows;
        this.gridRules.dataView.setItems(this.rules);
        this.gridRules.gridService.setSelectedRows([]);
      }
      this.is_rules_loading = false;
    }, err => {
      this.rules = [];
      this.is_rules_loading = false;
    });
  }

  getTotalRecords() {
    const Datasets: IDataset = {
     user_id: this.loggedInUserDetails.user_id,
     tenant_id: this.loggedInUserDetails.tenant_id,
     dataset_id: this.dataset_id.toString(),
   }
   this.datasetService.getDataset(Datasets).subscribe((responseList) => {
       this.getDataSet = responseList;
      this.total_records=this.getDataSet.total_records;
       });
  }

  getDQDatasetSummary(columnId) {
    let today = new Date();
		this.today = today.toLocaleDateString();
    this.datasetService.getDQDatasetSummary(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, columnId).subscribe((data) => {
      this.summary = data;
      this.resetData();
      if(this.summary.length){
        this.last_updated_date = this.summary[0].last_updated_date;
        this.total_records = this.summary[0].total_records;
        this.good_records = this.summary[0].total_records - this.summary[0].exceptions;
        this.bad_records = this.summary[0].exceptions;
        const ruleObj = this.zsClContentService.getMeasuredRule(this.summary[0].measured_dq_score, this.loggedInUserDetails['enable_dq_rule'] );
        this.measured_dq_score = ruleObj["dqRuleText"];
        this.measured_rule_css = ruleObj["dqRuleCss"];   
      }      
    }, err => {
    });
  }

  getDQDatasetDimensionView(columnId) {
    this.datasetService.getDQDatasetDimensionView(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, columnId).subscribe((data) => {
      this.dimensions = data;
      this.dimensions.forEach(element => {
        element.measured_dq_score = Math.floor(element.measured_dq_score);
        element.progressbar_bgcolor = element.measured_dq_score >= 75 ? 'high' : element.measured_dq_score >= 50 ? 'medium' : 'low';
      });
    }, err => {
    });
  }

  loadExceptionColumns(width=0) {    
      this.exceptionsTableColDef = [
        {
          'displayname': '',
          'physicalname': '',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': emptyFormattter,
          'maxWidth': 9,
        }, {
          'displayname': 'Primary Key',
          'physicalname': 'primary_key',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 80,
          // 'maxWidth': 80,
          'headerCssClass': 'text-left titleBreak pt-2 pb-0 pr-3 toRight',
        }, {
          'displayname': 'Column Name',
          'physicalname': 'dataset_column_name',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 70,
          // 'maxWidth': 100,
        }, {
          'displayname': 'Column Value',
          'physicalname': 'dataset_column_value',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 80,
          // 'maxWidth': 100,
          'headerCssClass': 'text-left titleBreak pt-2 pb-0 pr-3 toRight',
        },{
          'displayname': 'Rule Break Reason',
          'physicalname': 'rule_break_reason',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': width ? width : 200,
          'cssClass': 'text-left',
          'headerCssClass': 'text-left titleBreak pr-1 m-top-5',
        },{
          'displayname': 'Last Updated',
          'physicalname': 'last_updated_date',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 80,
          // 'maxWidth': 80,
          'headerCssClass': 'text-left titleBreak pt-2 pb-0 pr-3 toRight',
          'formatter': dateFormatter,
        }
      ];    
  }

  getExceptions(pagenoExceptions, limitExceptions) {
   // this.tableSettingsExcep['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${ this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.ruleId}&pagination=true&pageno=1&pagesize=${limitExceptions}&dataset_id=${this.dataset_id}`;   
    let serverfilter = "";
    if (sessionStorage.getItem("serverfilter")) {
       serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) {
        pagenoExceptions = 1;
      }
      this.isFiltering = false;
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
      this.isSorting = true;
    } else {
      if (this.isSorting) {
        pagenoExceptions = 1;
      }
      this.isSorting = false;
    }
    this.datasetService.getDQRuleExceptions(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.ruleId, pagenoExceptions, limitExceptions, true, this.dataset_id,serverfilter,sortfilter).subscribe((data) => {
      this.exceptions = data['currentpage'];
      // if (this.exceptions.length === 0) {
      //   this.noDataExceptions = true;
      // }
      this.is_exceptions_loading = false;
      let maxlen = 0;
      this.exceptions.forEach((element, i) => {
        element.id = Math.random();
        if (this.exceptions[i].rule_break_reason && this.exceptions[i].rule_break_reason.length > maxlen) {
          maxlen = this.exceptions[i].rule_break_reason.length;
        }
      });
      let width = 0;
      if (maxlen) {
        width = Math.round((7 * maxlen));
      }
      this.loadExceptionColumns(width);
      this.totalExceptions = data['totalrecords'];
      let concatedRows = this.exceptions;
      if (this.gridExceptions) {
        if(this.selectRule === true) {
          this.gridExceptions.dataView.setItems([]);
          this.selectRule = false;
        }
        concatedRows = [...this.gridExceptions.dataView.getItems(), ...this.exceptions];
        this.exceptions = concatedRows;
        this.gridExceptions.dataView.setItems(this.exceptions);
        this.gridExceptions.gridService.setSelectedRows([]);
        if(this.pagenoExceptions < data['totalpages']) {
          this.hasScrolledExceptions = false;
        } else {
          this.hasScrolledExceptions = true;
        }
      }
      if (data.pageno === 1) {
        this.tableSettingsExcep['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.ruleId}&pagination=true&pageno=1&pagesize=${data['totalrecords']}&dataset_id=${this.dataset_id}`;
      }
    }, err => {
      this.exceptions = [];
      this.is_exceptions_loading = false;
    });
  }

  getDataQualityRule(rule_id) {
    this.zsClContentService.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], rule_id).subscribe(response => {
      this.dataQualityRule = response;
      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        this.zsClContentService.getDataQualitySequenceRuleTypes('Technical').subscribe(respSeq => {
          this.sequencesDQ = respSeq;
          const sequencesDQNames = respSeq.filter(row => row.lookup_id === ele.rule_sequence_type_lookup_id);          
          if (sequencesDQNames.length > 0) {            
            if (sequencesDQNames[0].lookup_id === 14876) {
              this.isMultiAttributeRule = true;
            } else {              
              this.isMultiAttributeRule = false;
            }
          }
          this.is_exceptions_loading = true;
          this.totalExceptions = 0;
          this.pagenoExceptions = 1;
          this.loadExceptionColumns();
          this.getExceptions(this.pagenoExceptions, this.limitExceptions);  
        }, err => { });
      });
    }, err => { });
  }

  onCellClick(eventData): void {
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;    
    if (fieldName === 'column') { 
      this.dqRuleQuryParams = row.column == 'All Columns' ? `dataset_id=${this.dataset_id}` : `dataset_column_id=${row.dataset_column_id}`;
      this.attributeName = row.column;
      this.ruleName = '';
      this.totalRules = 0;
      this.dataset_column_id = row.dataset_column_id;
      this.is_rules_loading = true;
      this.lineChartDQRule = null;
      this.getDQDatasetSummary(this.dataset_column_id);
      this.getDQDatasetDimensionView(this.dataset_column_id);
      this.getDQDatasetRules();    
      const existingClass = $(".left-grid-row-selected");
      if(existingClass) {
        existingClass.removeClass("left-grid-row-selected");
      }
      eventData.eventData.target.parentElement.parentElement.parentElement.classList.add("left-grid-row-selected");
    }
  }

  onCellRuleClick(eventData): void {
    const args = eventData.args;
    const row = this.gridRules.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    console.log(row)
    if (fieldName === 'rule_name') {
      this.selectRule = true;
      this.ruleName = row.rule_name;
      this.ruleId = row.rule_id;
      this.is_exceptions_loading = true;
      this.totalExceptions = 0;
      this.pagenoExceptions = 1;
      this.isRuleAdmin = row.is_dq_rule_admin;
      this.isRuleExecuter = row.is_dq_rule_executor;
      if (this.gridExceptions) {
        this.exceptions = [];
        this.gridExceptions.dataView.setItems(this.exceptions);
        this.gridExceptions.gridService.setSelectedRows([]);       
      }
      this.loadExceptionColumns();
      this.getExceptions(this.pagenoExceptions, this.limitExceptions);   
      this.lineChartDQRule = null;
      this.getDQRunModel();
      const existingClass = $(".center-grid-row-selected");
      if(existingClass) {
        existingClass.removeClass("center-grid-row-selected");
      }
      eventData.eventData.target.parentElement.parentElement.parentElement.classList.add("center-grid-row-selected"); 
    }
  }

  onGridCreated(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSort.subscribe((e, args) => {
      const firstRow = grid.gridService.getDataItemByRowIndex(0);
      setTimeout(() => {
          grid.gridService.deleteDataGridItem(firstRow);
          const currentData = grid.dataView.getItems();
          currentData.splice(0, 0, firstRow);
          grid.dataView.setItems(currentData);
          grid.gridService.setSelectedRows([]);
      }, 500);
    });
    
  }

  onRulesGridCreation(grid) {
    const parent = this;
    parent.gridRules = grid;
  }

  onExceptionsGridCreation(grid) {
    const parent = this;
    parent.gridExceptions = grid;
  }

  getMeasuredRule(measured_dq_score) {
    this.measured_rules = measured_dq_score >= 75 ? 'high' : measured_dq_score >= 50 ? 'medium' : 'low';
  }

  getDQRunModel() {
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			tenant_id: this.loggedInUserDetails['tenant_id'],
			rule_id: this.ruleId
		};
		this.zsClContentService.getDQRunModel(data, `?view=dataset_data_quality&${this.dqRuleQuryParams}`).subscribe(data => {
			this.lineChartDQRule = this.prepareLineChartData(data);
		}, error => {
			this.lineChartDQRule = [];
		});
	}
  prepareLineChartData(data) {
		const dataSet = [];
		data.forEach((element) => {
			const d = new Date(element['last_updated_date']);
			dataSet.push({ 'year': d.getFullYear(), 'value': Math.floor(element['measured_dq_score']), 'fulldate': d });
		});
		return dataSet;
	}

  addFirstRow() {
    return { '': '', 'column': 'All Columns', 'measured_dq_score': this.dsDqScore };
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  onScrollExceptions(parentClass) {
    const filterKey = sessionStorage.getItem("serverfilter");
    const sortKey = sessionStorage.getItem("sortfilter");
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.pagenoExceptions = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    // if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolledExceptions && !this.noDataExceptions) {
    // if (!this.hasScrolledExceptions && !this.noDataExceptions) {
    if (!this.hasScrolledExceptions && !filterKey && !sortKey) {
      this.hasScrolledExceptions = true;
      // this.is_exceptions_loading = true;
      this.onNextPageExceptions();
    }
  }

  onNextPageExceptions(): void {
    this.pagenoExceptions++;
    this.getExceptions(this.pagenoExceptions, this.limitExceptions)
  }

  onReRun(){    
    const payload = { "data_quality_rule_id": this.ruleId };
    this.datasetService.runModelDQ(payload).subscribe((result) => {  

    },error=>{
      this.messageSvc.sendMessage({message: 'Algo call has failed', type: 'ERROR', hideboard: true});
    });
  }

  manageRules(){
    sessionStorage.setItem('viewMode', 'technical');
    if (this.dataset_column_id) {
      this.router.navigate(['/zs/datasets/'+this.dataset_id+'/columns/'+ this.dataset_column_id+'/manage-rules' ], { queryParams: { showBreadcrumb: true, dataset_id: this.dataset_id, dataset_column_id: this.dataset_column_id, dqRuleLevel: 'dataset' } });
    } else {
      this.router.navigate(['/zs/datasets', this.dataset_id, 'manage-rules'], { queryParams: { showBreadcrumb: true, dataset_id: this.dataset_id, dqRuleLevel: 'dataset' } });
    }
  }

  resetData(){
    
    this.last_updated_date = this.today;
    this.total_records = 0;
    this.good_records = 0;
    this.bad_records = 0;
    this.measured_dq_score = 0;
    this.getMeasuredRule(this.measured_dq_score);
    if(this.total_records==0)
    {
      this.getTotalRecords();
    }
  } 

  onExport(){    
    //window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.ruleId}&export=true`,'_self');   
    this.zsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.ruleId}&dataset_id=${this.dataset_id}&export=true`).subscribe(resp => {
      this.zsClContentService.exportCsvFiles(resp, 'DQExceptionsReport');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  goToPage(n: number): void {
    this.pageNo = n;
    this.getDatasetAttributeList(this.pageNo, this.limit);
  }

  onNext(): void {
    this.pageNo++;
    this.getDatasetAttributeList(this.pageNo, this.limit);
  }

  onPrev(): void {
    this.pageNo--;
    this.getDatasetAttributeList(this.pageNo, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onScroll(parentClass) {
    if(sessionStorage.getItem("serverfilter")){
      this.isFiltering = true;
      this.noData = false;
    } else {
      if(this.isFiltering){
        this.pageNo = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolledColumns) {
      this.hasScrolledColumns = true;
      this.onNext();
    }
  }
  receiveTotalRecords($event) {
    this.subTotalColumn = this.zettaUtils.getSubTotal(this.grandTotalColumn, $event);
  }
  onUploadDataSource() {
    if(this.dataset_id && this.dataset_column_id){
      //window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/columns/${this.dataset_column_id}/dqrules?export=true`, '_self');
      this.zsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/columns/${this.dataset_column_id}/dqrules?export=true`).subscribe(resp => {
        this.zsClContentService.exportCsvFiles(resp, 'DQRule_forDatasetColumn');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    }
    if(this.dataset_id && !this.dataset_column_id){
      //window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/dqrules?export=true`, '_self');
      this.zsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/dqrules?export=true`).subscribe(resp => {
        this.zsClContentService.exportCsvFiles(resp, 'DQRule_forDatasets');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    }
    
  }
}
