import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ObservableService } from '../../services/observable.service';
import { FsDownloadGraphService } from './fs-download-graph.service';
import { AppGlobals } from '../../shared/app.globals';
import { ContentService } from 'src/app/zettasense/content/content.service';
import { MessageService } from '../message/message.service';

@Component({
  selector: 'fs-download-graph',
  templateUrl: './fs-download-graph.component.html', 
  styleUrls: ['./fs-download-graph.component.scss'], 
  encapsulation: ViewEncapsulation.None
})
export class FsDownloadGraphComponent implements OnInit {

  @Input() disableGraphDownloadButton: boolean = true;
  @Input() objectId: number; 
  @Input() ObjectTypeId: number;
  isGraphDataDownloading: boolean = false;
  isLoading = false;
  constructor(private _fsDownloadGraphService: FsDownloadGraphService, private contentService: ContentService,
    private messageService: MessageService) { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  downloadGraph() {
    if (!this.disableGraphDownloadButton) {

      this.isGraphDataDownloading = true;
      let self = this;
      this.isLoading = true;
      this.contentService.triggerDownloadGraphAlgoApi(this.objectId, this.ObjectTypeId)
        .subscribe((resp) => {
          self.isLoading = false;
          self._fsDownloadGraphService.setDownloadGraphData = {objectId: self.objectId, objectTypeId: self.ObjectTypeId};
        }, (err) => {
          self.isLoading = false;
          self.messageService.sendMessage({ message: err && err.error && err.error.message ? err.error.message : 'something went wrong', type: 'ERROR', hideboard: true });
        })
    }
  }


  isGraphDataDownloadingInProgress() {
    if (this.ObjectTypeId === AppGlobals.OBJECT_TYPE_ID_CATALOG || this.ObjectTypeId === AppGlobals.OBJECT_TYPE_ID_DATSET) {
      let isGraphDataDownloadingForCatalog = this._fsDownloadGraphService.getGraphData().find((catalog) => {
        return catalog.objectId === this.objectId && catalog.objectTypeId === this.ObjectTypeId;
      });
      return isGraphDataDownloadingForCatalog ? true : false;
    }
    return false;
  }
}
