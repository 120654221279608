import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
declare var $: any;
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

const customFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let rowState = !dataContext.match ? 'txt-disabled' : '';
  return `<div class="state-row-${row} ${rowState}">${value}</div>`;
};

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {  
  let iconClass = !dataContext.match ? 'fal fa-heart' : 'fa fa-trash';
  return `<div class="pointer text-center"><i class="${iconClass}" aria-hidden="true" id="actions-row-${row}"></i></div>`;
};

const removeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  let rowState = !dataContext.match ? 'txt-disabled' : '';
  let blueLink = !dataContext.match ? '' : 'blueLink'; 
  return `<div class="text-wrap state-row-${row} ${rowState}"><span " class="${blueLink} blueLink-${row}">Remove</span></div>`;
};

const dataNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let rowState = !dataContext.match ? 'txt-disabled' : '';
  let blueLink = !dataContext.match ? '' : 'blueLink';
  return `<div class="text-wrap state-row-${row} ${rowState}"><span title='${value}'" class="${blueLink} blueLink-${row}">${value}</span></div>`;
};

const EMPTY_GRID_MSG = 'No catalogs to map.';

@Component({
  selector: 'zetta-tm-review-training-data',
  templateUrl: './tm-review-training-data.component.html',
  styleUrls: ['./tm-review-training-data.component.scss'],
})
export class TmReviewTrainingDataComponent implements OnInit, OnDestroy {

  public catalogs: any;
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
  tableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  progressState = new ProjectStatus();
  dataset_id;
  catalogs_ids: any;
  selectedGridRowsSize = 0;

  public concepts: any;
  catalog_id;
  object_id;
  concept_id;
  currentStateIndex;
  titleName = '';
  dq_rule_level:string;
  dataset_classify_ids: any = [];
  dataset_ids: any = [];
  no_matches: string = '';
  datasets: string = '';
  mappedDatasets: any = [];

  modalRowData: any;
  modalGridOptions: any;
  modelColumnDef = [];
  isSampleDsLoading = true;
  public modalGrid: AngularGridInstance;
  url;
  safeSrc: SafeResourceUrl;
  dsName;
  errorMessage = undefined;
  showmodelpopup=false;
  datasetId:any;
  mappedDataSets = [];
  @Input() isBusinessRule;
  backTo:string;
  mappingsRemoved: Boolean = false;
  rule_id:string;
  clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
  dataResponse;
  is_upload_complete = false;
  catalogData: object;
  attributeIds: string;
  fu_type_importConceptTraining: any;
  is_clone = false;

  constructor(private catalogSvc: ZsClContentService,
              private activatedRoute: ActivatedRoute,
              public zettaUtils: ZettaUtils,
              private _router: Router,
              private projectService: ProjectService,
              private sanitizer: DomSanitizer,
              private baseService: BaseService,
              private messageSvc: MessageService) { }

  ngOnInit() {
    this.fu_type_importConceptTraining = AppGlobals.FU_TYPE_IMPORT_CONCEPT_TRAINING;
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    
    this.mappedDatasets = JSON.parse(sessionStorage.getItem('mappedDatasets'));

    this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
    this.concept_id = this.activatedRoute.snapshot.queryParamMap.get('concept_id');
    this.no_matches = this.activatedRoute.snapshot.queryParamMap.get('no_matches');
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.backTo = params.get('BackTo');
      const is_clone = params.get('is_clone');
      if (is_clone && is_clone === 'true') {
          this.is_clone = true;
      }
      this.rule_id = params.get('rule_id');
    });

    if (this.no_matches !== null) {
      const no_matches_arr = this.no_matches.split(',');
      no_matches_arr.forEach(element => {
        this.dataset_classify_ids.push(+element);
      });
    }

    this.datasets = this.activatedRoute.snapshot.queryParamMap.get('datasets');
    if (this.datasets !== null) {
        this.dataset_ids = this.datasets.split(',');
    }

    const currentStateIndex = this.activatedRoute.snapshot.queryParamMap.get('currentStateIndex');
    this.activatedRoute.queryParamMap.subscribe(params => {
      if(params.has('dqRuleLevel')){
        this.dq_rule_level = params['params']['dqRuleLevel'];
      } else if(params.has('catalogLevel')) {
        if(params['params']['catalogLevel'] == 'semantic') {
          this.activatedRoute.parent.params.subscribe(para => {
            this.catalogData = {
              catalogLevel: params['params']['catalogLevel'],
              catalogId: para.catalog_id,
              objectId: params['params']['object_id'],
            };
          });
        } else if (params['params']['catalogLevel'] == 'concept') {
          this.activatedRoute.parent.parent.params.subscribe(para => {
            this.catalogData = {
              catalogLevel: params['params']['catalogLevel'],
              catalogId: para.catalog_id,
              objectId: params['params']['object_id'],
              conceptId: params['params']['concept_id']
            };
          });
        }
      }
      if (params.has('attributeIds')) {
        this.attributeIds = params.get('attributeIds');
      }
    });
    if (this.isBusinessRule || (this.concept_id === undefined || this.concept_id === null)) {      
        this.activatedRoute.parent.params.subscribe(params => {          
          this.catalog_id = params['catalog_id'];
        });
        if (this.isBusinessRule) {
          this.activatedRoute.parent.parent.parent.params.subscribe(params => {
            this.catalog_id = params['catalog_id'];
          });
        }
        if(this._router.url.includes('create-bussiness-rule')) {
          this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
        }
        this.getConceptsAll();
    } else {
        this.activatedRoute.parent.parent.params.subscribe(params => {
          this.catalog_id = params['catalog_id'];
        });
        if (this.isBusinessRule) {
          this.activatedRoute.parent.parent.parent.params.subscribe(params => {
            this.catalog_id = params['catalog_id'];
          });
        }
        if(this._router.url.includes('create-bussiness-rule')) {
          this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
        }
        this.getConcepts();
    };

    if (this.isBusinessRule) {
      this.initColDefDataRules()
      // this.getConcepts()
    } else {
      this.initColDef()
    }

    this.currentStateIndex = (currentStateIndex === undefined || currentStateIndex === null) ? 0 : +currentStateIndex;
    this.progressState.states = this.zettaUtils.getStateList('classify-train-model');
    this.progressState.currentStateIndex = +this.currentStateIndex;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifyTrain';
    // this.progressState.object = this.object;
    this.progressState.isEdit = false;

    // this.getCatalogList();

    // if(this.concept_id === undefined || this.concept_id === null) {
    //   this.getConceptsAll();
    // } else {
    //   this.getConcepts();
    // };
    
    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: false,
      noDataMsg: EMPTY_GRID_MSG,
      // rowHeight: 30
    };

    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };

  }

  initColDef() {
    this.tableColDef = [
      {
        'displayname': 'Semantic Object',
        'physicalname': 'semantic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        // 'minWidth': 150,
        // 'maxWidth': 250,
        'formatter': customFormatter,
      }, {
        'displayname': 'Concept',
        'physicalname': 'concept',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customFormatter,
      }, {
        'displayname': 'Data Source',
        'physicalname': 'datasource',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Data Set',
        'physicalname': 'dataset',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Data Set Column',
        'physicalname': 'dataset_column',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customFormatter,
      }, {
        'displayname': 'Data Type',
        'physicalname': 'data_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customFormatter,
      }, {
        'displayname': 'Actions',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'headerCssClass': 'text-center',
        'formatter': actionsFormatter,
        'minWidth': 50,
        'maxWidth': 60,
      },
    ];

  }

  initColDefDataRules() {
    this.tableColDef = [
      {
        'displayname': 'Semantic Object',
        'physicalname': 'semantic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        // 'minWidth': 150,
        // 'maxWidth': 250,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Concept',
        'physicalname': 'concept',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Data Source',
        'physicalname': 'datasource',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Data Set',
        'physicalname': 'dataset',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dataNameFormattter,
      }, {
        'displayname': 'Data Set Column',
        'physicalname': 'dataset_column',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customFormatter,
      }, {
        'displayname': 'Data Type',
        'physicalname': 'data_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customFormatter,
      }, {
        'displayname': 'Actions',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': removeFormatter,
        'minWidth': 70,
        'maxWidth': 90,
      },
    ];

  }
  ngOnDestroy() {
    sessionStorage.removeItem('mappedDatasets');
    this.messageSvc.clearMessage();
  }

  getCatalogList() {
    
    this.catalogSvc.getCatalogs(this.loggedInUserDetails.user_id).subscribe((data) => {
      this.catalogs = data;
      if (this.catalogs.length === 0) {
        this.noData = true;
      }
      this.catalogs.forEach(element => {
        element.id = Math.random();
      });
      this.isLoading = false;
      this.total = this.catalogs.length;
      let concatedRows = this.catalogs;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.catalogs];
        this.catalogs = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, (error) => {
      this.isLoading = false;
      // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
    });
  }

  getConceptsAll() {
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      catalog_id: this.catalog_id,
      object_id: this.object_id,
      is_multi_attribute: this.isBusinessRule, 
      attribute_ids: this.attributeIds
    };
    this.catalogSvc.getTrainSemanticsLevel(data,true).subscribe((data) => {
      this.concepts = data;
      if (this.concepts.length === 0) {        
        this.noData = true;
      }
      if (this.concepts.length) {
        this.titleName = this.concepts[0].semantic;
      }      

      this.concepts.forEach(element => {
        element.id = Math.random();
        // if (this.no_matches !== null && this.no_matches.includes(element.dataset_classify_id)){
        //   this.dataset_classify_ids.push(element.dataset_classify_id);
        if (this.no_matches !== null && this.no_matches.includes(element.dataset_column_id)){
          this.dataset_classify_ids.push(element.dataset_column_id);
          element.match = false
        } else {
          element.match = true
        }
        if (this.isBusinessRule) {
          this.mappedDataSets.push(element.dataset_id);
        }
      });

      let indexes = [];
      if(this.mappedDatasets !== null && this.mappedDatasets.length > 0) {
        this.mappedDatasets.forEach((element, index) => {
          // this.concepts.unshift(element);
          indexes.unshift(index);
        });
      }

      this.isLoading = false;
      this.total = this.concepts.length;
      let concatedRows = this.concepts;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.concepts];
        this.concepts = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
        this.grid.gridService.highlightRow(indexes, 2000);
      }

    }, (error) => {
      this.isLoading = false;
      // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
    });
  }

  getConcepts() {
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      catalog_id: this.catalog_id,
      object_id: this.object_id,
      concept_id: this.concept_id,
      is_multi_attribute: this.isBusinessRule,
      attribute_ids: this.attributeIds
    }
    this.catalogSvc.getTrainConceptsLevel(data,true).subscribe((data) => {
      this.concepts = data;
      if(this.concepts && this.concepts[0] && this.concepts[0].concept) {
      this.titleName = this.concepts[0].concept;
      }
      if (this.concepts.length === 0) {
        this.noData = true;
      }
      this.concepts.forEach(element => {
        element.id = Math.random();
        // if (this.dataset_classify_ids.includes(element.dataset_classify_id)){
        if (this.dataset_classify_ids.includes(element.dataset_column_id)){
          element.match = false
        } else {
          element.match = true
        }
        this.mappedDataSets.push(element.dataset_id);
      });
      let indexes = [];
      if(this.mappedDatasets !== null && this.mappedDatasets.length > 0) {
        this.mappedDatasets.forEach((element, index) => {
          // this.concepts.unshift(element);
          indexes.unshift(index);
        });
      }

      this.isLoading = false;
      this.total = this.concepts.length;
      let concatedRows = this.concepts;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.concepts];
        this.concepts = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
        this.grid.gridService.highlightRow(indexes, 2000);
      }
    }, (error) => {
      this.isLoading = false;
      // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedGridRowsSize = args.rows.length;
    });
  }

  onNext(): void {
    this.page++;
    this.getCatalogList();
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onCancelStep(): void {
    if (this.concept_id === undefined || this.concept_id === null) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
    } else {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
    };
  }

  onNextStep(): void {
    if(this.concept_id === undefined || this.concept_id === null) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/select-datasets'], 
                      { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
    } else {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/select-datasets'], 
                      { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
    };
  }

  onPreviousStep(): void {
    if(this.concept_id === undefined || this.concept_id === null) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/map-data-columns'], 
                      { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
    } else {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/map-data-columns'], 
                      { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
    };
  }

  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent.catalogs_ids = row.catalog_id;
    $("#data-" + args.row).prop("checked", true);
    parent.selectedGridRowsSize = 1;
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    const eventEle = eventData.eventData;
    if (row !== undefined && row !== null) {
      
      if (fieldName === 'actions') { 
        // if(!this.dataset_classify_ids.includes(row.dataset_classify_id)){
        //     this.dataset_classify_ids.push(row.dataset_classify_id);
        if(!this.dataset_classify_ids.includes(row.dataset_classify_id)){
          this.dataset_classify_ids.push(row.dataset_classify_id);
            // this.concepts.match = false;
            row.match = false;
        } else {
            // this.dataset_classify_ids = this.dataset_classify_ids.filter(e => e !== row.dataset_classify_id);
            this.dataset_classify_ids = this.dataset_classify_ids.filter(e => e !== row.dataset_classify_id);
            // this.concepts.match = true;
            row.match = true;
        }

        this.no_matches = this.dataset_classify_ids.toString();

        let payload = {
          dataset_id: row.dataset_id,
          dataset_column_id: row.dataset_column_id,
          removing_attribute_id: row.attribute_id,
          mapping_type: "not_sure",
          entity_id: row.entity_id,
          catalog_id: row.catalog_id
        }

        if (!this.isBusinessRule && eventEle.target.className.includes('fa-trash')) {
          if (this.concept_id === undefined || this.concept_id === null) {
            this.catalogSvc.downVoteMapping({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails['tenant_id'], payload: payload }).subscribe((res) => {
              this.mappingsRemoved = true;
              this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/review-training'],
                { queryParams: { object_id: row.entity_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
            }, (error) => { });
          } else {
            this.catalogSvc.downVoteMapping({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails['tenant_id'], payload: payload }).subscribe((res) => {
              this.mappingsRemoved = true;
              this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/concepts/review-training'],
                { queryParams: { object_id: row.entity_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
            }, (error) => { });
          };
          this.changeRowState(true, args.row);
        } else if (!this.isBusinessRule && eventEle.target.className.includes('fa-heart')) {
          let upVotePayload = {
            dataset_id: row.dataset_id,
            dataset_column_id: row.dataset_column_id,
            removing_attribute_id: row.attribute_id,
            attribute_id: row.attribute_id,
            mapping_type: "manual",
            entity_id: row.entity_id,
            catalog_id: row.catalog_id
          }
          if (this.concept_id === undefined || this.concept_id === null) {
            this.catalogSvc.upvotetMapping({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails['tenant_id'], payload: upVotePayload }).subscribe((res) => {
              this.mappingsRemoved = true;
              this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/review-training'],
                { queryParams: { object_id: row.entity_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
            }, (error) => { });
          } else {
            this.catalogSvc.upvotetMapping({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails['tenant_id'], payload: upVotePayload }).subscribe((res) => {
              this.mappingsRemoved = true;
              this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/concepts/review-training'],
                { queryParams: { object_id: row.entity_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
            }, (error) => { });
          };
          this.changeRowState(false, args.row);
        } else {
          this.catalogSvc.downVoteMapping({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails['tenant_id'], payload: payload }).subscribe((res) => {
            this.mappingsRemoved = true;
            this.changeRowState(true, args.row);
          });
        }
      }

      if (fieldName === 'datasource' && row.match === true) { 
        this._router.navigate(['/zs/datasources', row.datasource_id]);
      }
      if (fieldName === 'dataset' && row.match === true) { 
        this.dsName = row.dataset;
        this.showmodelpopup=true;
        this.datasetId=row.dataset_id;
        // this.selectOptionDataSetModelList(row.dataset_id);
        // $('#data_refresh_modal').modal('show');
        // $('#data_refresh_modal').removeClass('d-none');
      }

    }
  }

  changeRowState(state, row){
    if (state) {
      $('.state-row-' + row).addClass('txt-disabled');
      $('#actions-row-' + row).addClass('fal fa-heart').removeClass('fa fa-trash');
      $('.blueLink-' + row).removeClass('blueLink');
    } else {
      $('.state-row-' + row).removeClass('txt-disabled')
      $('#actions-row-' + row).addClass('fa fa-trash').removeClass('fal fa-heart').css({'color': 'red !important'});
      $('.blueLink-' + row).addClass('blueLink');
    }
  }

  onRunModel(): void {   
    const datasets_ids = [];
    this.dataset_ids.forEach(element => {
      datasets_ids.push(+element);
    });
    this.catalogSvc.runModel303().subscribe((res) => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs-cl/catalogs/' + this.catalog_id });      
    }, (err) => {
    });

    // datasets_ids.forEach(element => {
    //     this.catalogSvc.runModel301(element).subscribe((res) => {
    //       console.log(res)
    //       this._router.navigate(['/zs-cl/catalogs']);
    //     }, (err) => {
    //     });
    // });
  }

  onSaveClose() {
    this.messageSvc.sendMessage({ message: 'Changes Saved Successfully', type: 'SUCCESS', hideboard: true });
    setTimeout(() => {
      if ( this.catalog_id && this.object_id && !this.concept_id ) {
        this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects'], { queryParams: { showBreadcrumb: true } });
      }
      if ( this.catalog_id && this.object_id && this.concept_id ) {
        this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true} });
      }
    }, 300);
  }

  // Modal Datasets
  selectOptionDataSetModelList(dataSetId, limit = 30) {
    this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // this.isSampleDsLoading = true;
    this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'],
      this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
        this.modalRowData = data.currentpage;
        const rowKeys = [];
        if (data.currentpage.length) {
          const myVar: any = data.currentpage[0];
          for (var key in myVar) {
              rowKeys.push(key) 
          }
      }
      this.modelColumnDef = [];
      rowKeys.forEach(column => {
        this.modelColumnDef.push({
          'displayname': column,
          'physicalname': column,
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
        });
      });

      this.modalRowData.forEach((element: any) => {
        element.id = Math.random();
      });

      // this.total = data['totalrecords'];
      if (this.modalGrid) {
        this.modalGrid.dataView.setItems(this.modalRowData);
        this.modalGrid.gridService.setSelectedRows([]);
      }
      this.isSampleDsLoading = false;
    }, err => {
      this.isSampleDsLoading = false;
      this.modalRowData = [];
    });
}

onModelGridCreation(grid) {
  this.modalGrid = grid;
}
popupoutput(message){
  this.showmodelpopup = message;   
}

  onCancel() {
    if(this._router.url.includes('create-bussiness-rule')) {
      $('.modal').modal('hide');            
      if (this.dq_rule_level == 'concept') {
        if (this.backTo == 'catalog') {
          this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        } else {
          this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
        }        
      } else if (this.dq_rule_level == 'catalog') {
          if (this.backTo == 'catalog') {
            this._router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
          } else {
            this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
          }       
      } else if (this.dq_rule_level == 'object') {
          if (this.backTo == 'catalog') {
            this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
          } else {
            this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
          }
      }else {
        if(this.is_clone) {
          let rule_ids: any = [];
          rule_ids.push(this.rule_id)
          this.catalogSvc.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
          sessionStorage.removeItem('ruleInfo');
          sessionStorage.removeItem('ruleInfoClone');
          sessionStorage.removeItem("tRuleDefinition");
        } 
        this._router.navigate(['/zs-cl/dataquality']);
      }
    }
    if (this._router.url.includes('data-quality-rules')) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
    }
  }

  onPrevious() {
    if(this._router.url.includes('create-bussiness-rule')) {
      this._router.navigate(['/zs-cl/rules/create-bussiness-rule/business-rule-definition'],{queryParamsHandling:'preserve'});
    }
    if (this._router.url.includes('data-quality-rules')) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/business-rule-definition'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
    }
  }

  onNextClick() {
    if(this._router.url.includes('create-bussiness-rule')) {
      const datasetIds = this.mappedDataSets.toString();      
      if(this.mappingsRemoved) {
        const data ={
          rule_id: this.rule_id,
          payload:{
            is_mapping_changed:true
          }
        }
        this.catalogSvc.updateChangeMappings(data).subscribe(resp =>{
        },err=>{});
        this._router.navigate(['/zs-cl/rules/create-bussiness-rule/select-dataset'], {queryParams :  {mappedDataSets: datasetIds, isMappingChanged: true} ,queryParamsHandling:'merge'});
      } else {
        this._router.navigate(['/zs-cl/rules/create-bussiness-rule/select-dataset'], {queryParams :  {mappedDataSets: datasetIds} ,queryParamsHandling:'merge'});
      }
    }
    
    if (this._router.url.includes('data-quality-rules')) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/select-dataset'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
    }

  }

  cancelUpload() {
    this.clearModal = Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
  }

  onClose() {
    this.clearModal = Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
    this.currentStateIndex = 3;
    this.progressState.states = this.zettaUtils.getStateList('classify-train-model');
    this.progressState.currentStateIndex = this.currentStateIndex;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifyTrain';
    this.progressState.isEdit = false;
    if(this.concept_id === undefined || this.concept_id === null) {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/review-training'], 
                      { queryParams: { currentStateIndex: this.currentStateIndex }, queryParamsHandling: 'merge' });
    } else {
      this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/review-training'], 
                      { queryParams: { currentStateIndex: this.currentStateIndex }, queryParamsHandling: 'merge' });
    };
    setTimeout(() => {
      window.location.reload();
    },800)
  }

  receiveFileName($event) {
    this.dataResponse = $event
    this.is_upload_complete = this.dataResponse.is_upload_complete;
    if (this.is_upload_complete) {
      if (this.grid) {
        this.concepts = [];
        this.grid.dataView.setItems(this.concepts);
        if(this.catalogData && this.catalogData['catalogLevel'] == 'concept') {
          this.getConcepts();
        } else {
          this.getConceptsAll();
        }
      }
    }
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }
}
