import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatasourceService } from '../datasource.service';
import { datasourceNameFormattter } from '../../../../common/shared/formatters/datasourceNameFormatter';
import { datasourceTypeFormattter } from '../../../../common/shared/formatters/datasourceTypeFormatter';
import { datasourceCreatedByFormattter } from '../../../../common/shared/formatters/datasourceCreatedByFormatter';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { MessageService } from '../../message/message.service';
import { environment } from 'src/environments/environment';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ActionLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
import { DatasourceNameFormatterComponent } from 'src/app/common/shared/cell-renderer/data-source-name/data-source-name-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { DatasourceTypeFormatterComponent } from 'src/app/common/shared/cell-renderer/data-source-typename/data-source-typename-formatter.component';
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { CreatedByCellRendererComponent } from 'src/app/common/shared/cell-renderer/data-source-createdby-formatter/datasource-createdby-formatter.component';
import { ActionsCellRendererComponent } from 'src/app/common/shared/cell-renderer/data-source-action-formatter/data-source-action-formatter.component';
import { GroupNumericCellRendererComponent } from 'src/app/common/shared/cell-renderer/data-source-groupname-formatter/data-source-groupname-formatter.component';
import { NumericFormatterTextComponent } from 'src/app/common/shared/cell-renderer/data-source-numeric-formatter/data-source-numeric-formatter.component';
const zettaUtilsInstance = new ZettaUtils();
const numericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  
  //return `<div class="text-right w-100 pr-2 pr-3-lg ${columnDef.maxWidth=== 140 ? 'max-width-140':'max-width-200' }" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
  return `<div class="text-right ${cell==5 ?'pd-ds':'pd-enitile'}" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
}

const numericFormattertext: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    let cssColmWidth = 'wd-total-entitle';
    if (columnDef.field === 'datasets') {
      cssColmWidth = 'wd-total-ds';
    }
    return value ? `<div class="text-right ${cssColmWidth}" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>` : `<div class="text-right w-75 text-bold" title="0">0</div>`
}

const groupnumericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="text-right grp-enitile wd-total-entitle" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`    
}

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let isAdmin=false;
  if(dataContext.entitlements){    
    if(dataContext.entitlements.menu.clone && dataContext.entitlements.menu.delete && dataContext.entitlements.menu.edit){     
        isAdmin=true;      
    }
  } 
  return isAdmin 
  ? `<span class="text-center pointer context-menu"><i class="fa fa-ellipsis-v fa-2x pad-top-12" aria-hidden="true"></i></span>` 
  : `<span class="text-center pointer context-menu disabled-state"><i class="fa fa-ellipsis-v fa-2x pad-top-12" aria-hidden="true"></i></span>`;
};

@Component({
  selector: 'zetta-datasource-home',
  templateUrl: './datasource-home.component.html',
  styleUrls: ['./datasource-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatasourceHomeComponent implements OnInit {

  public datasources: any = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  grandTotal = 0;
  subTotal = '';
  page = 1;
  limit= 100;
  noData = false;
  isFiltering = false;
  totalPage = 0;
  columnApi:any;
  gridApi:any;
  shouldOpenModal: boolean = false;
  datasource_id;

  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {}
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  subTotalRecords = 0;
  row;



  constructor(
    private datasourceSvc: DatasourceService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService, private http : HttpClient,) {}

  ngOnInit() {
    // sessionStorage.removeItem('serverfilter');
    // sessionStorage.removeItem('sortfilter');
    this.getDatasourceList(this.page, this.limit);
    this.projectHomeTableColDef = [{
      'displayname': '',
      'physicalname': 'actions',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': actionsFormatter,
      'maxWidth': 15,
      'minWidth': 15,
    }, {
      'displayname': 'Id',
      'physicalname': 'datasource_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'maxWidth' : 100
    }, {
      'displayname': 'Data Source Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      'formatter': datasourceNameFormattter,
    }, {
      'displayname': 'Data Source Type',
      'physicalname': 'datasource_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      'maxWidth': 390,
      'formatter': datasourceTypeFormattter,
      'headerCssClass': 'text-left titleBreak padT-2 pb-0 pr-3 toRight',
    }, {
      'displayname': 'Created By',
      'physicalname': 'created_by',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasourceCreatedByFormattter,
      'minWidth': 120,
      'maxWidth': 120,
      'headerCssClass': 'text-left titleBreak padT-2 pb-0 pr-3 toRight',
    }, {
      'displayname': 'Last Modified',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': dateFormatter,
      'headerCssClass': 'text-left titleBreak pt-1 pb-0 pr-3 toRight',
      'minWidth': 90,
      'maxWidth': 90,
    }, {
      'displayname': '# of Datasets',
      'physicalname': 'datasets',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': numericFormattertext,
      'minWidth': 80,
      'maxWidth': 80,
      'headerCssClass': 'text-left titleBreak pt-1 pb-0 pr-1 toRight',
    }, {
      'displayname': '# of Group Entitlements',
      'physicalname': 'group_entitlements',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': groupnumericFormatter,
      'minWidth': 100,
      'maxWidth': 100,
      'headerCssClass': 'text-left titleBreak pt-1 pb-0 pr-3 toRight ',
    }, {
      'displayname': '# of User Entitlements',
      'physicalname': 'user_entitlements',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': numericFormattertext,
      'minWidth' : 100,
      'maxWidth': 100,
      'headerCssClass': 'text-left titleBreak pt-1 pb-0 pr-3 toRight',
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 50     //scss file  --> line-height: 60px !important;
    };
    this.intialiseGrid();
  }
  intialiseGrid() {
    this.columnDefs = [
     {
        headerName: '',
        field: 'actions',
        cellRendererFramework: ActionsCellRendererComponent, 
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth : 30,
        maxWidth : 30,
        suppressColumnsToolPanel: true,
       
      }, {
        headerName: 'Id',
        field: 'datasource_id',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField: 'datasource_id',
        minWidth: 100
        
      }, {
        headerName: 'Data Source Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        suppressSizeToFit :true,
        sortable: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework : DatasourceNameFormatterComponent,
        tooltipField : 'name',
        minWidth : 600,
        // maxWidth : 600
        },{
        headerName: 'Data Source Type',
        field: 'datasource_type',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        editable: true,
        tooltipField:'datasource_type',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework : DatasourceTypeFormatterComponent,
        minWidth:180
        
      }, {
        headerName: 'Created By',
        field: 'created_by', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'created_by',
        cellRendererFramework : CreatedByCellRendererComponent,
        minWidth : 180
      }, {
        headerName: 'Last Modified',
        field: 'last_updated',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        sortable:true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField :'last_updated',
        cellRendererFramework : DateFormatterFormatterComponent,
        minWidth:180
       
      }, 
      {
        headerName: '# of Datasets',
        field: 'datasets',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        sortable:true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework : NumericFormatterTextComponent ,
        tooltipField : 'datsets',
        minWidth: 110
       
      }, 
      {
        headerName: '# of Group Entitlements',
        field: 'group_entitlements',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        sortable:true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellClass: 'ag-right-aligned-cell',
        tooltipField : 'group_entitlements',
        minWidth:120,
        cellRendererFramework: GroupNumericCellRendererComponent,
       
      }, 
      {
        headerName: '# of User Entitlements',
        field: 'user_entitlements',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        cellClass: 'ag-right-aligned-cell pr-3',
        sortable:true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
       cellRendererFramework : NumericFormatterTextComponent ,
       tooltipField :'user_entitlements',
       minWidth: 120
      }, 
    ];
    this.fsGridOptions = { 
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 50 ,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
      
    };
    
  }

  getDatasourceList(pageNo, limit) { 
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${ this.loggedInUserDetails['tenant_id']}/datasources?pageno=1&pagesize=${limit}`;
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${ this.loggedInUserDetails['tenant_id']}/datasources`;
    var serverfilter = "";
    // if(sessionStorage.getItem("serverfilter")){
    //   var serverfilter = sessionStorage.getItem("serverfilter");
    //   this.isFiltering = true;
    // } else {
    //   if(this.isFiltering){ 
    //     pageNo = 1;
    //   }
    //   this.isFiltering = false;
    // }
   let sortfilter="";
  //  if(sessionStorage.getItem("sortfilter")){
  //     sortfilter=sessionStorage.getItem("sortfilter");
  //     }
      this.isLoading = true;
    this.datasourceSvc.getDatasources({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], pageno: pageNo, perpage: limit},serverfilter, sortfilter).subscribe((data) => {
        this.datasources = data['currentpage'];
        if (this.datasources.length === 0) {
          this.noData = true;
        }
        this.isLoading = false;
        if (pageNo === 1) {
          this.grandTotal = data['totalrecords'];          
        }
        this.totalPage = data['totalpages'];
        let concatedRows = this.datasources;

        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.datasources];
          this.datasources.forEach(element => {
            element.id = Math.random();
          });
          this.datasources = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.gridService.setSelectedRows([]);
          if(pageNo <data['totalpages']) {
            this.hasScrolled = false;
          }
        }
        if(pageNo > data['totalpages']) {        
          this.page = 1;
          this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasources?paginate=true&pageno=1&pagesize=${limit}`;
        }
      }, err => {
        this.isLoading = false;
        // this.messageSvc.sendMessage({message: 'Get datasources has failed', type: 'INFO', hideboard: true});
      });
  }
  
  onGridReady(grid:any) { 
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;   
    
  }
  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSort.subscribe((e, args) => {
      this.page = 1;
      this.hasScrolled = false;
    });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDatasourceList(this.page, this.limit);
  }

  onNext(): void {
    if (this.totalPage > this.page) {
      this.page++;
      this.getDatasourceList(this.page, this.limit);
    }
   
  }

  onPrev(): void {
    this.page--;
    this.getDatasourceList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.grandTotal, this.limit);
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    // $('#context_menu').children('li')[1].className = 'disable-option';
    if($('#context_menu').children('li')[1]){ $('#context_menu').children('li')[1].className = 'd-none' };
    if($('#context_menu').children('li')[2]){ $('#context_menu').children('li')[2].className = 'd-none' };
    if(row.entitlements.menu.clone && row.entitlements.menu.delete && row.entitlements.menu.edit)
    {
      $('#context_menu')
      .removeClass('d-none')
      .data('row', args.row)
      .css('top', eventEle.pageY)
      .css('left', eventEle.pageX + 5)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();
    }else{
      $('#context_menu').hide();
    }
   

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      const env = JSON.parse(sessionStorage.getItem('userApp'));
      if (action === 'Edit') {
        parent._router.navigate(['/zs/datasources/create-datasource'], { queryParams: { 'id': row.datasource_id, showBreadcrumb: false, editView: true } });
      } else if (action === 'Delete') {
        // parent.deleteRow(row.projectid, row.id);
        // alert('In Progress');
        return;
        parent.messageSvc.sendMessage({message: 'Delete in progress', type: 'INFO', hideboard: true});
      } else if (action === 'Clone') {
        // parent.cloneRow(row);
        // alert('In Progress');
        return;
        parent.messageSvc.sendMessage({message: 'Clone in progress', type: 'INFO', hideboard: true});
      }
    });
  }

  onScroll(parentClass) {
    if(sessionStorage.getItem("serverfilter")){
      this.isFiltering = true;
      this.noData = false;
    } else {
      if(this.isFiltering){
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    if (!this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  // onCellClick(e): void {    
  //   const eventEle = e.eventData;
  //   const args = e.args;
  //   const row = this.grid.gridService.getDataItemByRowIndex(args.row);
  //   if (row !== undefined && row !== null) {
  //     let element = eventEle.target.getAttribute("data-title")
  //     if (eventEle.target.title === row.name) {
  //       this._router.navigate(['/zs/datasources', row.datasource_id]);
  //     }
  //   }
  // }

  onAgCellClick(cellParams){
    const fieldName = cellParams.colDef.field;
   this.row = cellParams.data;
    
    // const eventEle = cellParams.event;
    if(this.row !== undefined && this.row !== null){
      // let element = eventEle.target.getAttribute("data-title")
      
      if(cellParams.value === this.row.name){
        if(this.row.entitlements.menu.clone && this.row.entitlements.menu.delete && this.row.entitlements.menu.edit){
        this._router.navigate(['/zs/datasources', this.row.datasource_id]);
        }
      }
      if(fieldName === 'actions'){
        if(this.row.entitlements.menu.clone && this.row.entitlements.menu.delete && this.row.entitlements.menu.edit){
          $('#context_menu')
          .removeClass('d-none')
          .data('row', cellParams.rowIndex)
          .css('top', cellParams.event.clientY)
          .css('left', cellParams.event.clientX + 4)
          .css('position', 'fixed')
          .css('display', 'block')
          .show();
        }
        else {
          $('#context_menu').hide();
        }
        $('body').one('click', function () {
          $('#context_menu').hide();
        });
        
  
      }
    }
    

  }
  onEditDatasource(hasEditClicked) {
    if (hasEditClicked) {
      this._router.navigate(['/zs/datasources/create-datasource'], { queryParams: { id: this.row.datasource_id, showBreadcrumb: false, editView: true } });
    }
  }
  onDeleteDataSource(hasDeleteClicked) {
    if (hasDeleteClicked) {
     this.messageSvc.sendMessage({message: 'Delete in progress', type: 'INFO', hideboard: true});
    }
  }
  onCloneDataSource(hasClone){
    if(hasClone){
      this.messageSvc.sendMessage({message: 'Clone in progress', type: 'INFO', hideboard: true});
    }
  }

  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal);
  }
}
 

