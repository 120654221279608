import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'zetta-zs-cl-add-reference-data',
  templateUrl: './zs-cl-add-reference-data.component.html',
  styleUrls: ['./zs-cl-add-reference-data.component.scss']
})
export class ZsClAddReferenceDataComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
  }

  onNext() {
    this.router.navigate(['../data-sets'], { relativeTo: this.activatedRoute, queryParams: { activeLink: '2' }, queryParamsHandling: 'merge' })
  }

}
