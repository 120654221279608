import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
import { AppGlobals } from '../../app.globals';

@Component({
  selector: 'app-project-status-formatter',
  template: `
    <div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100 ml-2">
      <div class="col-auto m-0 p-0 text-left">
        <i class="{{ getIconByCurrentStatus() }} mr-left-7 p-0" [style.color]="getIconColorByCurrentStatus()"></i>
      </div>
      <div class="col ml-2 m-0 p-0 text-left line-h-14">
        <span title="{{ params.data.status }}">{{ params.data.status }}</span><br>
      </div>
    </div>
  `,
})
export class ProjectStatusFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getIconByCurrentStatus(): string {
    const zettaUtilsInstance = new ZettaUtils();
    return zettaUtilsInstance.getIconByProjectCurrentStatus(this.params.data.status);
  }

  getIconColorByCurrentStatus(): string {
    const zettaUtilsInstance = new ZettaUtils();
    return zettaUtilsInstance.getIconColorByProjCurrentStatus(this.params.data.status);
  }
  
}
