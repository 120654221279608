import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtils = new ZettaUtils();

export const addRemoveFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (dataContext.user_id) {
        return value === 'delete' ? `<i class="fa fa-minus actionInlineBtn
        ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'remove_user') ? 'disabled-state' : ''}"
        data-toggle="modal" data-backdrop="false" data-target=#${columnDef.field}></i>` : `<i class="fa fa-plus actionInlineBtn
        ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'import_user') ? 'disabled-state' : ''}"></i>`;
    } else {
        return value === 'delete' ? `<i class="fa fa-minus actionInlineBtn
        ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'groups', 'remove_group') ? 'disabled-state' : ''}"
        data-toggle="modal" data-backdrop="false" data-target=#${columnDef.field}></i>` : `<i class="fa fa-plus actionInlineBtn
        ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'groups', 'add_group') ? 'disabled-state' : ''}"></i>`;
    }
};

