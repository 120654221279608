import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AnalyticService } from '../analytic.service';
import { numericFormatter } from '../../../../common/zm-shared/zm-formatters/numericFormatter';
import { numericMockFormatter } from '../../../../common/zm-shared/zm-formatters/numericMockFormatter';
import { versionMockFormatter } from '../../../../common/zm-shared/zm-formatters/versionMockFormatter';
import { analyticNameFormattter } from '../../../../common/zm-shared/zm-formatters/analyticNameFormatter';
import { dateFormatter } from '../../../../common/zm-shared/zm-formatters/dateFormatter';
import { porcentMockFormatter } from '../../../../common/zm-shared/zm-formatters/porcentMockFormatter';
import { actionsFormatter } from '../../../../common/shared/formatters/actionsFormatter';
import { ZmZettaUtils } from '../../../../common/zm-shared/zm-zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Analytic } from '../analytic.model';

@Component({
  selector: 'zm-analytics-home',
  templateUrl: './analytics-home.component.html',
  styleUrls: ['./analytics-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsHomeComponent implements OnInit {

  public analytics: object = {};
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': '100%', 'pageSize': 6, 'isNormalPagination': false };
  analyticHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;

  isLoading = true;
  total = 0;
  page = 1;
  limit = 30;
  noData = false;

  constructor(
    private AnalyticSvc: AnalyticService,
    public zettaUtils: ZmZettaUtils,
    private _router: Router) { }

  ngOnInit() {
    this.getAnalyticHomeList(this.page, this.limit);
    this.analyticHomeTableColDef = [
      {
        'displayname': '',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'cssClass': 'text-center',
        'formatter': actionsFormatter,
        'maxWidth': 20,
      },
      {
        'displayname': 'Analytics Project Name',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 250,
        'formatter': analyticNameFormattter,
      },
      {
        'displayname': 'Description',
        'physicalname': 'description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': null,
        'minWidth': 250,
      },
      {
        'displayname': 'Users',
        'physicalname': 'users',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'minWidth': 80,
        'formatter': numericMockFormatter,
      },
      {
        'displayname': 'Data Sets',
        'physicalname': 'datasets',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': numericMockFormatter,
      },
      {
        'displayname': 'Version',
        'physicalname': 'version',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': versionMockFormatter,
      },
      {
        'displayname': 'Last Modified',
        'physicalname': 'lastmodified',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': dateFormatter,
      },
      {
        'displayname': '# of Model Runs',
        'physicalname': 'nummodelruns',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'minWidth': 100,
        'formatter': numericMockFormatter,
      },
      {
        'displayname': 'Last Run Date',
        'physicalname': 'lastrundate',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': dateFormatter,
      },
      {
        'displayname': '# of Outcome Attributes',
        'physicalname': 'analyticid',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'minWidth': 100,
        'maxWidth' : 190,
        'formatter': numericFormatter,
      },
      {
        'displayname': 'Accuracy',
        'physicalname': 'accuracy',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        // 'headerCssClass': 'text-right',
        'formatter': porcentMockFormatter,
      },
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30
    };
  }

  getAnalyticHomeList(pageno, limit = 1) {
    this.AnalyticSvc.getAnalytics(this.loggedInUserDetails.userid, pageno, limit).subscribe((data) => {
      this.analytics = data;
      if (this.analytics['currentpage'].length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = data['totalrecords'];
      let concatedRows = this.analytics['currentpage'];

      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.analytics['currentpage']];
        this.analytics['currentpage'].forEach(element => {
          element.id = Math.random();
        });
        this.analytics['currentpage'] = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getAnalyticHomeList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getAnalyticHomeList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getAnalyticHomeList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zm/analytics', row.analyticid]);
      }
    }
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    $('#context_menu')
      .removeClass('d-none')
      .data('row', args.row)
      .css('top', eventEle.pageY)
      .css('left', eventEle.pageX + 5)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      if (action === 'Edit') {
        // parent._router.navigate(['/zm/analytics/create-analytic'], { queryParams: { 'id': row.analyticid, showBreadcrumb: false } });
      } else if (action === 'Delete') {
        parent.deleteRow(row.analyticid, row.id);
      } else if (action === 'Clone') {
        // parent.cloneRow(row);
      }
      // alert(action);
    });
  }

  // cloneRow(projectObj) {
  //   const parent = this;
  //   const tempProject = new Project();
  //   tempProject.name = 'Copy_of_' + projectObj.name + '_' + Math.floor((Math.random() * 1000) + 1);
  //   tempProject.analyticid = projectObj.analyticid;
  //   tempProject.userid = this.loggedInUserDetails.userid;

  //   this.isLoading = true;
  //   parent.AnalyticSvc.cloneProject(tempProject).subscribe(response => {

  //     parent._router.navigate(['/zs/projects/create-project'], { queryParams: { 'id': response.analyticid, showBreadcrumb: false } });

  //   }, err => {
  //     this.isLoading = true;
  //   });
  // }

  deleteRow(analyticid, rowId) {
    const parent = this;
    this.isLoading = true;
    const currentAnalytic = new Analytic();
    currentAnalytic.analyticid = analyticid;
    currentAnalytic.isdeleted = true;
    currentAnalytic.userid = this.loggedInUserDetails.userid;
    parent.AnalyticSvc.saveAnalytic(currentAnalytic).subscribe(responseList => {
      this.analytics['currentpage'] = this.analytics['currentpage'].filter(analytic => analytic.analyticid !== analyticid);
      // parent.grid.gridService.deleteDataGridItemById(rowId);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
}
