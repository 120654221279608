import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { MessageService } from '../../components/message/message.service';
declare var $: any;


@Component({
  selector: 'zetta-prod-config-settings-modal',
  templateUrl: './prod-config-settings-modal.component.html',
  styleUrls: ['./prod-config-settings-modal.component.scss']
})
export class ProdConfigSettingsModalComponent implements OnInit, OnChanges, OnDestroy {
  header: string = 'Configuration Settings for Production';
  mergerTypes = [{name: 'Always Merge Most Recent Changes', lookup_id: 15599}, {name: 'Let System Decide', lookup_id: 15600}];
  updateTypes = [ {name: 'Automatically', lookup_id: 15601}, {name: 'After Manual Reviews', lookup_id: 15602}, 
                  {name: 'After Manual Review Only for Low Confidence Matches', lookup_id: 15603}, 
                  {name: 'After Manual Review Only for Low and Medium Confidence Matches', lookup_id: 15604}];
  fourEyeCheckTypes = [{name: 'ON - Different Reviewer & Approver', lookup_id: 15605}, {name: 'OFF - Only a Reviewer', lookup_id: 15606}];
  apiTypes = [{name: 'Real Time API’s only', lookup_id: 15607}, {name: 'Real Time & Scheduled API’s', lookup_id: 15608}];

  merge_lookup_id: number;
  update_lookup_id: number;
  foureye_lookup_id: number;
  api_lookup_id: number;
  enableValidation: boolean = false;
  @Input() projectDetail: object = {};
  loggedInUserDetails;
  projectId;
  schedulerDetails;
  @Output() onModalClose = new EventEmitter<boolean>();
  constructor(private projectService: ProjectService, private activeRoute: ActivatedRoute, private router: Router, private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.activeRoute.parent.params.subscribe(params => {
      this.projectId = params['id']; 
    });
    $('#prod-config-settings-modal').modal('show');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.projectDetail && changes.projectDetail.currentValue) {
      this.projectDetail = changes.projectDetail.currentValue;
      if(this.projectDetail['four_eye_check']) {
        this.updateFourEyeTypeChange({lookup_id: this.fourEyeCheckTypes[0]['lookup_id']});
      } else {
        this.updateFourEyeTypeChange({lookup_id: this.fourEyeCheckTypes[1]['lookup_id']});
      }
    }
  }

  ngOnDestroy() {
    $('#prod-config-settings-modal').modal('hide');
  }

  onCancel() {
    this.onModalClose.emit(true);
  }

  saveSettingsConfig() {
    let payload = {
      merge_lookup_id: this.merge_lookup_id,
      updated_changes_lookup_id: this.update_lookup_id,
      four_eyes_check_lookup_id: this.foureye_lookup_id,
      apis_turn_on_lookup_id: this.api_lookup_id,
      ...this.api_lookup_id ===  15608 ? { schedulers: [this.schedulerDetails.schedulerInfo[0]] } : {},
    };
    this.projectService.saveProjectConfigSettings(this.loggedInUserDetails['user_id'], this.projectId, payload)
    .subscribe(resp => {
      if(resp) {
        this.enableValidation = false;
        this.router.navigate([`/zs/projects/${this.projectId}/production-stage-details`], {queryParamsHandling: 'merge'});
        this.onCancel();
      }}, err => {
        this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
    });
  }

  mergerTypeChange(obj) {
    this.merge_lookup_id = obj['lookup_id'];
    this.validateForm();
  }

  updateTypeChange(obj) {
    this.update_lookup_id = obj['lookup_id'];
    this.validateForm();
  }

  updateFourEyeTypeChange(obj) {
    this.foureye_lookup_id = obj['lookup_id'];
    this.validateForm();
  }

  updateApiTypeChange(obj) {
    this.api_lookup_id = obj['lookup_id'];
    this.validateForm();
  }

  validateForm() {
    if(this.merge_lookup_id && this.update_lookup_id && this.foureye_lookup_id && this.api_lookup_id) {
      if(this.api_lookup_id == 15608) {
        this.enableValidation = false;
        if(this.schedulerDetails && this.schedulerDetails.enableButtonCondition == true) {
          this.enableValidation = true;          
        }
      } else {
        this.enableValidation = true;
      }
    } else {
      this.enableValidation = false;
    }
  }

  updateProjSchedulerInfo(eve) {
    if(eve) {
      this.schedulerDetails = eve;
      if(eve.enableButtonCondition == true) {
        this.validateForm();
      } else {
        this.enableValidation = false;
      }
    }
  }

}
