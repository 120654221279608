import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';


@Component({
  selector: 'action-link-formatter',
  templateUrl: './action-link-formatter.component.html',
  styles: [
  ]
})
export class ActionLinkFormatterComponent implements OnInit, ICellRendererAngularComp {

  active = false;
  value = undefined;
  params: any;
  html = `<div [ngClass]="{'disabled-state':!active}" class="text-truncate"><label title="{{value}}" class="blueLink text-truncate w-100">{{value}}</label></div>`;

  agInit(params: ICellRendererParams): void {
    this.value = params.data ? true : false;
    this.params = params.data;
    if (params.data && params.data.entitlement) {
      if (params.data.entitlement.read_write || params.data.entitlement.read || params.data.entitlement.is_admin) {
        this.active = true;
      } else {
        this.active = false;
      }
    }

      if (params.data.isdisable) {
        this.active = false;
      } else {
        this.active = true;
      }
   
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.params.context.componentParent.updateRow(this.params.rowIndex);
  }
  onActionClick() {

  }

}
