import { entityNameFormatter } from '../../../../common/shared/formatters/entityNameFormatter';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../../../../zettasense/content/project/project.service';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { projectNameFormattter } from '../../../../common/shared/formatters/projectNameFormatter';
import { taskStatusFormatter } from '../../../../common/shared/formatters/taskStatusFormatter';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { editRemoveFormatter } from '../../../../common/shared/formatters/editRemoveFormatter';
import { customColumnValidatorFormatter } from '../../../../common/shared/formatters/customColumnValidatorFormatter';
import { MessageService } from '../../message/message.service';
import { radioButtonFormatter } from '../../../../common/shared/formatters/radioButtonFormatter';
import { DropDownEditor } from './dropdown.editior';
import { DatasetService } from '../dataset.service';
import { ConditionalExpr } from '@angular/compiler';

const customDropDownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  dataContext.action !== 'delete' ? `<div class='row dropdownBorder'><div class='col-md-10 text-truncate pl-1 pr-0'><label id='user-${columnDef.field}' placeholder='Select Type' "title='${dataContext.column_type}'>${dataContext.column_type}</label></div><div class='col-md-2 p-0'> <span><i class="fa fa-caret-down ctmCaretDown"></i></span></div></div>` : `<div class='row'><div class='col-md-10 text-truncate pr-0'><label placeholder='Select Type' title='${dataContext.column_type}'>${dataContext.column_type}</label></div><div class='col-md-2 p-0'> <span><i class="fa fa-caret-down ctmCaretDown"></i></span></div></div>`;

const customGroupRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.isEditing || dataContext.action === 'add') {
    return `<input id='group_addEdit_${row}_colName' class='ctmInputBox' type='text' value='${value}'>`;
  } else {
    return `<div class='text-truncate'><label title='${value}'> ${value}</label></div>`;
  }
};

const booleanFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  value ? `True` : `False`;

const LEFT_EMPTY_GRID_MSG = 'No Data.';
@Component({
  selector: 'zetta-add-data-transformation',
  templateUrl: './add-data-transformation.component.html',
  styleUrls: ['./add-data-transformation.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddDataTransformationComponent implements OnInit, OnDestroy {

  public existingColumnDataSource: any = [];
  public newColumnDataSource: any = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  public leftTableSettings: object = { 'height': '100%', 'width': '100%' };
  public rightTableSettings: object = { 'height': '100%', 'width': '100%' };
  existingColumnsTableColDef: any;
  existingColumnsGridOptions: any;
  leftGridDataSource: any = [];
  rightGridDataSource: any = [];
  shortcutGridDataSource: any = [];
  gridColumnsDef: any = [];
  rightGridColumnsDef: any = [];
  shortcutGridColumnsDef: any = [];
  newColumnsTableColDef: any;
  newColumnsGridOptions: any;
  addColumnObj: any = {};
  leftGridOptions: any;
  hasScrolled = false;
  rightGridOptions: any;
  eventData: any;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  showBreadcrumb = false;
  progressState = new ProjectStatus();
  columnTypeslist: any;
  columnGridRef: AngularGridInstance;
  isColumnsResponseReady = false;
  datasetId;
  rowData: object;
  datasetDetails: object;
  customColumn = [];

  constructor(
    private projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageSvc: MessageService,
    private _datasetSvc: DatasetService,) { }

  ngOnInit() {
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    this.progressState.states = this.zettaUtils.getStateList('create-dataset');
    this.progressState.currentStateIndex = 2;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Set';
    this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
    this.progressState.isEdit = sessionStorage.getItem('newDataSet') === 'true' ? false : this.progressState.isEdit;

    this.getExistingColumnsList(this.page, this.limit);
    // this.getDatasetColumns()

    this.existingColumnsTableColDef = [{
      'displayname': 'Column Id',
      'physicalname': 'dataset_column_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'maxWidth': 90,
      'formatter': null,
    }, {
      'displayname': 'Column Name',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': null,
      'minWidth': 100,
    }, {
      'displayname': 'Column Original',
      'physicalname': 'column_original',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 100,
      'formatter': null,
    }, {
      'displayname': 'Deleted',
      'physicalname': 'column_deleted',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Primary Key',
      'physicalname': 'column_is_primary_key',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Formula',
      'physicalname': 'column_formula',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Sort Order',
      'physicalname': 'sort_order',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': null,
    }];

    this.newColumnsTableColDef = [{
      'displayname': 'Column Id',
      'physicalname': 'dataset_column_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'maxWidth': 90,
      'formatter': null,
    }, {
      'displayname': 'Column Name',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': null,
      'minWidth': 100,
    }, {
      'displayname': 'Column Original',
      'physicalname': 'column_original',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 100,
      'formatter': null,
    }, {
      'displayname': 'Deleted',
      'physicalname': 'column_deleted',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Primary Key',
      'physicalname': 'column_is_primary_key',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Formula',
      'physicalname': 'column_formula',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': booleanFormatter,
    }, {
      'displayname': 'Sort Order',
      'physicalname': 'sort_order',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-left',
      'headerCssClass': 'text-left',
      'formatter': null,
    }];

    this.rightGridColumnsDef = [{
      'displayname': 'Function Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }, {
      'displayname': 'Formula',
      'physicalname': 'value',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }];

    this.shortcutGridColumnsDef = [{
      'displayname': 'Shortcut',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }, {
      'displayname': 'Description',
      'physicalname': 'group',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }];

    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: false,
      asyncEditorLoading: false,
      multiSelectable: false,
      autoEdit: true,
      editable: true,
      noDataMsg: LEFT_EMPTY_GRID_MSG,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: false,
      CheckboxSelector: false,
      enableCellNavigation: false,
      multiSelectable: true,
      noDataMsg: LEFT_EMPTY_GRID_MSG,
    };

    this.existingColumnsGridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: false,
      rowHeight: 30
    };

    this.newColumnsGridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: false,
      rowHeight: 30
    };
  }

  getExistingColumnsList(pageno, limit = 1) {
    const dataset: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
    }
    this._datasetSvc.getDatasetColumns(dataset, this.datasetId).subscribe((data) => {
      this.existingColumnDataSource = data;
      if (this.existingColumnDataSource['length'] === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = this.existingColumnDataSource['length'];
      if (this.grid) {
        this.existingColumnDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.grid.dataView.setItems(this.existingColumnDataSource);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
      this.messageSvc.sendMessage({
        message: 'Get existing column list has failed', type: 'INFO', hideboard: true
      });
    });
  }

  initTypeGrid() {
    this.gridColumnsDef = [{
      'displayname': 'Column Name',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': customGroupRowFormatter,
      'params': { 'placeholder': 'Column Name' },
      'maxWidth': 120,
    }, {
      'displayname': 'Type',
      'physicalname': 'column_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': customDropDownFormatter,
      'params': this.columnTypeslist,
      'editor': { model: DropDownEditor },
      'maxWidth': 100,
    }, {
      'displayname': 'Primary Key',
      'physicalname': 'column_is_primary_key',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'maxWidth': 100,
      'formatter': radioButtonFormatter
    }, {
      'displayname': 'Formula',
      'physicalname': 'column_formula',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': customColumnValidatorFormatter,
      'minWidth': 250,
    }, {
      'displayname': ' ',
      'physicalname': 'action',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': editRemoveFormatter
    }];
  }

  getAddColumnRow() {
    return {
      'isEditing': false, 'is_Valid_Formula': false, 'action': 'add', 'dataset_column_id': -1, 'dataset_id': this.datasetId, 'column': ' ',
      'column_type': ' ', 'column_type_id': -1, 'column_original': 'first_name', 'column_deleted': false, 'column_is_primary_key': false, 'column_formula': ' ', 'cleaners': []
    };
  }

  leftGrid(grid: AngularGridInstance) {
    const parent = this;
    parent.columnGridRef = grid;
    parent.hideAddRowCheckbox();
    // sort event
    parent.columnGridRef.slickGrid.onSort.subscribe(function (e, args) {
      const firstRowData = args.grid.getData().getItems().find(data => data.fixedRow === true);
      setTimeout(() => {
        parent.columnGridRef.gridService.deleteDataGridItem(firstRowData);
        parent.columnGridRef.gridService.addItemToDatagrid(firstRowData);
        parent.hideAddRowCheckbox();
      }, 500);
    });
  }

  hideAddRowCheckbox() {
    setTimeout(() => {
      $('.add-btn').parent().parent().find('.slick-cell-checkboxsel').hide();
    }, 500);
  }

  handleUserGridClickEvent(e) {
    this.eventData = e;
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.columnGridRef.gridService.getDataItemByRowIndex(args.row);
    if ($(eventEle.target).hasClass('radio-button')) {
      $('.table-radio input:radio').removeAttr('checked');
      $('#key_' + args.row).attr('checked', 'checked');
    }
    if ($(eventEle.target).hasClass('fa-pencil') || $(eventEle.target).hasClass('column-validator')
      || $(eventEle.target).hasClass('fa-floppy-o') || $(eventEle.target).hasClass('add-btn')
      || $(eventEle.target).hasClass('fa-minus')) {
      if (row !== undefined && row !== null) {
        if ($(eventEle.target).hasClass('fa-pencil')) {
          row.isEditing = !row.isEditing;
          this.columnGridRef.gridService.updateDataGridItem(row, false, false);
        } if ($(eventEle.target).hasClass('column-validator')) {
          this.validateColumn(eventEle, row, args.row);
        } if ($(eventEle.target).hasClass('add-btn')) {
          this.addNewColumn(eventEle, row, args.row);
        } if ($(eventEle.target).hasClass('fa-floppy-o')) {
          this.editRowClick(eventEle, row, args.row);
        } if ($(eventEle.target).hasClass('fa-minus')) {
          this.rowData = row;
        }
      }
    }
  }

  readColumnDetails(eventElement, row, index) {
    let formulaValue = $('#group_formula_' + index).val();
    formulaValue = formulaValue ? formulaValue.toString().trim() : null;
    let columnNameValue = $('#group_addEdit_' + index + '_colName').val();
    columnNameValue = columnNameValue ? columnNameValue.toString().trim() : null;
    const columnPmValue = $('#key_' + index).attr('checked') ? true : false;

    this.addColumnObj['column'] = columnNameValue;
    this.addColumnObj['column_formula'] = formulaValue;
    this.addColumnObj['column_is_primary_key'] = columnPmValue;
    this.addColumnObj['column_type'] = row['column_type'];
  }

  validateColumn(eventElement, row, index) {
    this.readColumnDetails(eventElement, row, index);
    if (this.addColumnObj.column_formula) {
      const payload = {
        'formula': this.addColumnObj.column_formula // 'substring(\'MNAME\', 10, 25)'
      };
      this._datasetSvc.validateColumn({
        user_id: this.loggedInUserDetails['user_id'],
        tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId, payload: payload
      }).subscribe((data) => {
        row.isValidFormula = !row.isValidFormula;
        row['column_formula'] = this.addColumnObj.column_formula;
        row['column'] = this.addColumnObj.column;
        row['column_is_primary_key'] = this.addColumnObj.column_is_primary_key;
        this.disappear();
        this.columnGridRef.gridService.updateDataGridItem(row, false, false);
      }, err => {
      });
    } else {
      this.messageSvc.sendMessage({ message: 'Please fill the formula field', type: 'INFO', hideboard: true });
    }
  }

  addNewColumn(eventElement, row, index) {
    this.readColumnDetails(eventElement, row, index);
    if (this.addColumnObj.column) {
      const payload = {
        'dataset_id': row.dataset_id,
        'column_original': 'ID',
        'column': this.addColumnObj.column,
        'column_type_id': row.lookup_id,
        'column_deleted': false,
        'column_is_primary_key': this.addColumnObj.column_is_primary_key,
        'column_formula': this.addColumnObj.column_formula
      };
      this._datasetSvc.createColumn({
        user_id: this.loggedInUserDetails['user_id'],
        tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId, payload: payload
      }).subscribe((data) => {
        $('.table-radio input:radio').removeAttr('checked');
        row['column_formula'] = '';
        row['column'] = '';
        row['column_is_primary_key'] = false;
        row.isValidFormula = false;
        row.isAddedColumn = !row.isAddedColumn;
        this.disappear();
        data.id = Math.random();
        data.lookup_id = row.lookup_id;
        data.column_type = row.column_type;
        const currentData = this.columnGridRef.dataView.getItems();
        currentData.splice(1, 0, data);
        this.columnGridRef.dataView.setItems(currentData);
        this.columnGridRef.gridService.setSelectedRows([]);
        this.hideAddRowCheckbox();
      }, err => {
      });
    } else {
      this.messageSvc.sendMessage({ message: 'Please fill the fields', type: 'INFO', hideboard: true });
    }
  }

  deleteRow() {
    this._datasetSvc.deleteColumn({
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId, column_id: this.rowData['dataset_column_id']
    }).subscribe((data) => {
      this.columnGridRef.gridService.deleteDataGridItem(this.rowData);
      $('#btnCloseActionModel').click();
    }, err => {
    });
  }

  editRowClick(eventElement, row, index) {
    this.readColumnDetails(eventElement, row, index);
    if (this.addColumnObj.column) {
      const payload = {
        'dataset_column_id': row.dataset_column_id,
        'column': this.addColumnObj.column,
        'column_type_id': row.lookup_id,
        'column_formula': this.addColumnObj.column_formula,
        'column_is_primary_key': this.addColumnObj.column_is_primary_key,
      };
      this._datasetSvc.editColumn({
        user_id: this.loggedInUserDetails['user_id'],
        tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId, payload: payload
      }).subscribe((data) => {
        row.isEditing = !row.isEditing;
        row.isSavedFormula = !row.isSavedFormula;
        row.isValidFormula = false;
        row['column_formula'] = this.addColumnObj.column_formula;
        row['column'] = this.addColumnObj.column;
        row['column_is_primary_key'] = this.addColumnObj.column_is_primary_key;
        this.disappear();
        this.columnGridRef.gridService.updateDataGridItem(row, false, false);
      }, err => {
      });
    } else {
      this.messageSvc.sendMessage({ message: 'Please fill the fields', type: 'INFO', hideboard: true });
    }
  }

  getDatasetColumns() {
    const parent = this;
    parent.isLoading = true;
    this.initTypeGrid();
    parent.projectService.getColumns({userId: this.loggedInUserDetails['user_id'], tenant_id: parent.loggedInUserDetails['tenant_id'], datasetId: this.datasetId}).subscribe((data) => {
      if (data.length === 0) {
        parent.noData = true;
      }

      parent.leftGridDataSource = data[0];
      parent.datasetDetails = data[1];
      parent.leftGridDataSource.forEach(element => {
        if (element.column_type_id === 349) {
          this.customColumn.push(element);
        }
        element.id = Math.random();
        element.action = 'validate';
        element.isEditing = false;
        element.isValidFormula = false;
        element.isSavedFormula = false;
        element.isAddedColumn = false;
      });

      const firstRow = parent.getAddColumnRow();
      firstRow['fixedRow'] = true;
      parent.leftGridDataSource.splice(0, 0, firstRow);
      parent.isLoading = false;
      parent.isColumnsResponseReady = true;

    });
    parent.projectService.getColumnsTypes().subscribe((columnsTypes) => {
      columnsTypes.forEach(element => {
        element.id = Math.random();
      });
      parent.columnTypeslist = columnsTypes.map((item) => ({ 'value': item.lookup_id, 'text': item.name }));
      if (parent.columnTypeslist) {
        this.initTypeGrid();
      }
      parent.columnTypeslist = parent.columnTypeslist.reduce((arr, item) => {
        const exists = !!arr.find(x => x.value === item.value);
        if (!exists) {
          arr.push(item);
        }
        return arr;
      }, []);
    });
    parent.projectService.getFormulas().subscribe((formulas) => {
      formulas.forEach(element => {
        element.id = Math.random();
      });
      parent.rightGridDataSource = formulas;
    });
  }

  getNewColumnsList(pageno, limit = 1) {
    const dataset: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
    }
    this._datasetSvc.getDatasetColumns(dataset, this.datasetId).subscribe((data) => {
      this.newColumnDataSource = data;
      // this.newColumnDataSource = [];
      if (this.newColumnDataSource['length'] === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = this.newColumnDataSource['length'];
      if (this.grid) {
        this.newColumnDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.grid.dataView.setItems(this.newColumnDataSource);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: 'New column list has failed', type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getExistingColumnsList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getExistingColumnsList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getExistingColumnsList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/projects', row.project_id]);
      }
    }
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

  addNewColumnClick() {
    this.getNewColumnsList(this.page, this.limit);
  }

  onPrevious() {
    this.router.navigate(['/zs/datasets/select-data-source'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
  }

  onNextClick() {
    this.router.navigate(['/zs/datasets/user-entitlements'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
  }

  disappear() {
    setTimeout(() => {
      $('.validated').addClass('disappear');
    }, 1000);
  }

  getModelPosition(position: string, isPasswordModel?: boolean) {
    let styleValue = '';
    if (position === 'top') {
      styleValue = (this.eventData.eventData.pageY - 40) + 'px';
    }
    return styleValue;
  }

}
