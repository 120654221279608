import { Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityPrams } from './statistics.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatisticService extends BaseService {
    public isStatisticDataChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { super(); }

    getProjectSummary(userid: string, projectid: string, pickertype: string, summaryfor: string): Observable<any> {
        if(summaryfor === 'productionStateFixIssues') {
            return this.http.get(`${this.apiUrl}/users/` + userid + `/projects/` + projectid + `/summaries?model=${pickertype}&summaryfor=fixissues&latest=true&ignorejob=true&onlyopentasks=true`);
        } else {
            return this.http.get(`${this.apiUrl}/users/` + userid + `/projects/` + projectid + `/summaries?model=${pickertype}&summaryfor=${summaryfor}&latest=true`);
        }
    }

    getProjectTasksSummary(param: EntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/projecttasks/summary`);
    }

    getAllTasksSummary(userId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/summary?allusers=true`);
    }

    getAllMyTasksSummary(userId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/summary?allusers=false`);
    }

    getClassifyProjectSummary(userId: string, tenantId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/summary`);
    }
    getTrainModalSUmmary(userId, tenantId, projectId) : Observable<any>{
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/summary?summaryfor=statistics`);
    }
    getFixIssuesSUmmary(userId, tenantId, projectId, summaryForAllTaskType) : Observable<any>{
        if (summaryForAllTaskType) {
            return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/summary?summaryfor=statistics&projecttasks=true`);  
        }
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/summary?summaryfor=statistics&task_type=fixing`);
    }
    getAllClassifyTasksSummary(userId: string,summaryfor: string, allUsers:boolean): Observable<any> {        
        let allTasksUrl =`${this.apiUrl}/users/${userId}/summary?summaryfor=${summaryfor}`;
        if(allUsers){
            allTasksUrl+='&allusers=true';
        }
        return this.http.get(allTasksUrl);      
    }

    getGoldenRecordsSummary(userid: string, projectid: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userid + `/projects/` + projectid + `/entitiesmastered/winners/summary`);
    }

    getProductionStageStatistics(userid: string, projectid: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userid + `/projects/` + projectid + `/production/statistics`);
    }

}
