import { Column, Formatter } from 'angular-slickgrid';
import { AppGlobals } from '../app.globals';

export const aprooveerCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    const checkCond = dataContext.roles.includes(AppGlobals.CLASSIFY_PROJECT_APPROVER) || dataContext.roles.includes(AppGlobals.RESOLVE_PROJECT_APPROVER) || dataContext.roles.includes(AppGlobals.CATALOG_APPROVER);
    if (checkCond) {
        let checkCond = dataContext.isApprover ? 'checked' : 'unchecked';
        return `<label class="checkbox-container ml-3">
                    <input type="checkbox" ${checkCond}>
                    <span class="checkmark" style="border-radius: 2px; border: 1px solid #D1CFCF;height:15px; width: 15px; background-color: #fff;"></span>
                </label>`;
    } else {
        return `<label class="ml-3 mr-top-7 check-cell">
                    <input type="checkbox" disabled>
                </label>`;
    }
}