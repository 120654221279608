import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-entity-name-renderer',
  template: `<span class="entity-name" [title]="params.data.entity_type_name + ' > ' + params.data.entity_name">
              {{ params.data.entity_type_name }} > {{ params.data.entity_name }}
            </span>`
})
export class entityNameFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
