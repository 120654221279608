import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { BaseService } from '../../services/base-service';
import { MessageService } from '../message/message.service';
import { AppGlobals } from '../../shared/app.globals';
import { Router } from '@angular/router';

@Component({
  selector: 'zetta-assign-users',
  templateUrl: './assign-users.component.html',
  styleUrls: ['./assign-users.component.scss']
})
export class AssignUsersComponent implements OnInit, OnChanges, OnDestroy {
  usersList: any;
  rightGridColumnsDef: any;
  usersGridOptions: any;
  usersGrid: AngularGridInstance;
  selectedRow: any;
  userTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  hasLeftUsersSelected: boolean = false;
  viewTaskAssignments: boolean = false;
  isTasksLoading: boolean = false;
  loggedInUserDetails: Object = {};
  enableApplyBtn: boolean = false;
  selectedUsersList = [];
  errorMsg: string = '';
  @Input() userPanelDetails : object;
  @Input() selectedProject : Array<any> = [];
  @Input() assignType : string;
  @Input() hideHomeButtons?: boolean = false;
  @Output() assignedTasks = new EventEmitter();
  @Output() showLoader = new EventEmitter();
  @Input() manageTasks? = false;
  @Input() showHeaderTitleWithUserCount: boolean = false;
  @Input() hideNoDataFoundImg: boolean = false;
  @Input() maintainUserStateOnSort: boolean = false;
  @Input() classifyProjectResults?:boolean = false;

  constructor(private service: ProjectService, private observableService: BaseService,
    private router: Router, private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.service.getObjectFromSession('userInfo');
    this.usersGridOptions = {
      enableGridMenu: false,
      selectable: false,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: false,
      CheckboxSelector: false,
      rowHeight: 34,
      multiSelectable: false,
    };

    this.observableService.showAssignUsers.subscribe(toggle => {
      if (toggle) {
        this.onTaskAssignments();
      } else {
        this.viewTaskAssignments = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if(changes.userPanelDetails && changes.userPanelDetails.currentValue) {
        this.userPanelDetails = changes.userPanelDetails.currentValue;
        this.assignType = changes.assignType.currentValue;
      }
      if(changes.selectedProject && changes.selectedProject.currentValue.length && !this.viewTaskAssignments) {
        this.selectedRow = changes.selectedProject.currentValue;
      } 
      if(changes.selectedProject && !changes.selectedProject.currentValue.length) {
        this.selectedRow = [];
      }
    }
  }

  ngOnDestroy(): void {
  }

  usersTaskGridReady(grid) {
    this.usersGrid = grid;
  }

  onTaskAssignments() {
    if (!this.viewTaskAssignments) {
        this.getUsersList();
    } 
    this.viewTaskAssignments = !this.viewTaskAssignments;
  }

  getUsersList() {
    this.usersList = [];
    if (this.userPanelDetails && this.userPanelDetails['getUserUrl']) {
      this.isTasksLoading = true;
      let user_id = this.userPanelDetails['user_id'];
      if (user_id == this.loggedInUserDetails['user_id']) {
        this.service.getProjectUsers(this.userPanelDetails['getUserUrl']).subscribe(data => {
          if (data && data.length > 0) {
            this.updateUsersData(data);
            this.isTasksLoading = false;
          }
        }, err => {
          this.usersList = [];
          this.isTasksLoading = false;
          this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
      }
    }
  }  

  onUserGridClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.usersGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.usersGrid.slickGrid.getColumns()[args.cell].field;
    const rows = this.usersGrid.dataView.getItems();

    if (row !== undefined && row !== null) {
      if (fieldName == 'reviewer' && eventEle.target.className.includes('checkmark')) {
          this.selectedUsersList[args.row]['isReviewer'] = !this.selectedUsersList[args.row]['isReviewer'];
          this.checkForReviewerValidation();
          this.applyChangesValidation();
      } else if (fieldName == 'approver' && eventEle.target.className.includes('checkmark')) {
        this.selectedUsersList[args.row]['isApprover'] = !this.selectedUsersList[args.row]['isApprover'];
        this.applyChangesValidation();
        this.checkForReviewerValidation();
      } else {
        return;
      }
      this.usersGrid.gridService.updateDataGridItem(row, false);
    }
  }

  addUserRole() {
    this.showLoader.emit(true);
    let data = [];
    let reviewers = [];
    let approvers = [];
    this.errorMsg = '';
    //this.selectedUsersList.forEach(user => user['isReviewer'] ? reviewers.push(user) : (user['isApprover'] ? approvers.push(user) : ''));
    
    this.selectedUsersList.forEach((user) => {
        if (user && user['isReviewer']) {
          reviewers.push(user);
        }

        if (user && user['isApprover']) {
          approvers.push(user);
        }
    })
    
    if(this.assignType == 'classifyProject' || this.assignType == 'resolveTask') {
      this.selectedProject.forEach(proj => {
        if( proj.status == AppGlobals.TASK_IN_REVIEW || proj.task_status == AppGlobals.TASK_IN_REVIEW) {
          if(reviewers && reviewers.length) {
            data.push({user_id: reviewers[reviewers.length-1].user_id, role_id: this.assignType == 'classifyProject' ? AppGlobals.CLASSIFY_PROJECT_REVEIWER : AppGlobals.RESOLVE_PROJECT_REVIEWER, cluster_id: proj.cluster_id, task_id: proj.task_id});
          }
        } else if((proj.status == 'Pending For Approval' || proj.status == 'Reviewed') || (proj.task_status == 'Pending For Approval' || proj.task_status == 'Reviewed')) {
          if(approvers && approvers.length) {
            data.push({user_id: approvers[approvers.length-1].user_id, role_id: this.assignType == 'classifyProject' ? AppGlobals.CLASSIFY_PROJECT_APPROVER : AppGlobals.RESOLVE_PROJECT_APPROVER, cluster_id: proj.cluster_id, task_id: proj.task_id});
          }
        }
      });
    } else if(this.assignType == 'catalogTask') {
      this.selectedProject.forEach(projec => {
            if(projec['task_type_id'] == AppGlobals.catalogSemanticTaskType) {
              if( projec.status == AppGlobals.TASK_IN_REVIEW || projec.task_status == AppGlobals.TASK_IN_REVIEW) {
                if(reviewers && reviewers.length) {
                  data.push({user_id: reviewers[reviewers.length-1].user_id, role_id:  AppGlobals.CATALOG_REVEIWER, entity_id: projec.entity_id, task_id: projec.task_id});
                }
              }
              if (projec.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || projec.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
                if (approvers && approvers.length) {
                  data.push({user_id: approvers[approvers.length-1].user_id, role_id:  AppGlobals.CATALOG_APPROVER, entity_id: projec.entity_id, task_id: projec.task_id});
                }
              }
            } else if(projec['task_type_id'] == AppGlobals.catalogConceptTaskType) {
              if( projec.status ==AppGlobals.TASK_IN_REVIEW || projec.task_status == AppGlobals.TASK_IN_REVIEW) {
                if(reviewers && reviewers.length) {
                  data.push({user_id: reviewers[reviewers.length-1].user_id, role_id: AppGlobals.CATALOG_REVEIWER, attribute_id: projec.attribute_id, task_id: projec.task_id});
                }
              }
              if (projec.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || projec.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || projec.task_status == AppGlobals.TASK_PENDING_APPROVAL) {
                if (approvers && approvers.length) {
                  data.push({user_id: approvers[approvers.length-1].user_id, role_id: AppGlobals.CATALOG_APPROVER, attribute_id: projec.attribute_id, task_id: projec.task_id});
                }
              }
            }
          })
    }
    
      this.service.assignProjects(`${this.userPanelDetails['updateURL']}`, data)
        .subscribe(res => {
          if(res.status == 200) {
            if(this.usersGrid) {
              this.usersGrid.dataView.setItems([]);
              this.observableService.showAssignUsers.next(false);
              this.onTaskAssignments();
              this.assignedTasks.emit(true);
            }
            this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
            this.showLoader.emit(false);
            this.enableApplyBtn = false;
            if(this.manageTasks)
            this.viewTaskAssignments=false;
            this.selectedRow.length=false;       
           }
        }, err => {
          this.showLoader.emit(false);
          this.messageSvc.sendMessage({ message: err.error.errors[0] ? err.error.errors[0]['message'] : err.error.message, type: 'INFO', hideboard: true });
        });
  }
   
  updateUsersData(userList) {
    if(this.usersGrid) {
      this.usersList = [];
      this.usersGrid.dataView.setItems([]);
    }
    this.usersList = userList;
    this.selectedUsersList = userList;
    this.isTasksLoading = false;
    if(this.assignType == 'classifyProject') {
      userList.forEach( user => user.roles = user.roles.map(role => role.role_id));
    }
    
    let concatedRows: string[] = userList;
    if (this.usersGrid) {
      concatedRows = [...this.usersGrid.dataView.getItems(), ...this.usersList];
      this.usersList.forEach(element => {
        element['id'] = Math.random();
      });
      this.usersList = concatedRows;
      this.usersGrid.dataView.setItems(concatedRows);
      this.usersGrid.gridService.setSelectedRows([]);
    }
    this.isTasksLoading = false;
  }

  applyChangesValidation() {
    if(this.assignType == 'classifyProject' || this.assignType == 'catalogTask'){
      this.enableApplyBtn = this.selectedUsersList.find(user => user['isReviewer'] == true || user['isApprover'] == true);
    } else if(this.assignType == 'resolveTask' ) {
    this.enableApplyBtn = this.selectedUsersList.find(user => user['isReviewer'] == true || user['isApprover'] == true);
  }
  }

  hideAssignments() {
    this.viewTaskAssignments = false;
    if(this.usersGrid) {
      this.usersGrid.dataView.setItems([]);
      this.onTaskAssignments();
      this.assignedTasks.emit(true);
    }
    this.observableService.showAssignUsers.next(false);
    this.selectedRow = [];
  }

  navigateBack() {
    this.router.navigate([`${this.userPanelDetails['homeUrl']}`]);
  }

  onDataSortedTest(usersData) {
    if (this.maintainUserStateOnSort) {
      this.selectedUsersList = usersData;
    }
  }

  checkForReviewerValidation() {
    let reviewers = [];
    let approvers = [];
    this.errorMsg = '';
    //this.selectedUsersList.forEach(user => user['isReviewer'] ? reviewers.push(user) : (user['isApprover'] ? approvers.push(user) : ''));
    this.selectedUsersList.forEach((user) => {
       if (user && user['isReviewer']) {
          reviewers.push(user);
       }
       if (user && user['isApprover']) {
          approvers.push(user);
       }
    })
    
    if (this.userPanelDetails['diffTaskStatusSelected']) {
      if (reviewers.length == 1 && !approvers.length) {
        this.enableApplyBtn = false;
        this.errorMsg = `${reviewers[reviewers.length - 1].user_name} will be set as the Reviewer only for the
         tasks that are In Review status, out of ${this.selectedProject.length} total tasks selected.`;
      } else if (reviewers.length == 1 && approvers.length == 1) {
        this.enableApplyBtn = true;
        this.errorMsg = '';
      } else if (reviewers.length > 1 || approvers.length > 1) {
        this.errorMsg = reviewers.length > 1 ? AppGlobals.REVIEWER_RVALIDATION_MSG : (approvers.length > 1 ? AppGlobals.APPROVER_RVALIDATION_MSG : '');
        this.enableApplyBtn = false;
      } else if (approvers.length < 1 || reviewers.length < 1) {
        this.enableApplyBtn = false;
      }
    } else {
      if (reviewers.length || approvers.length) {
        this.enableApplyBtn = true;
        this.errorMsg = '';
        if (reviewers.length > 1 || approvers.length > 1) {
          this.errorMsg = reviewers.length > 1 ? AppGlobals.REVIEWER_RVALIDATION_MSG : (approvers.length > 1 ? AppGlobals.APPROVER_RVALIDATION_MSG : '');
          this.enableApplyBtn = false;
        }
      }
    }
  }
}
