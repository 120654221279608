import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
// import { UserGroupService } from '../../../../usergroup/usergroup.service';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import * as d3 from 'd3';
import $ from 'jquery';
import { Analytic } from '../../analytic.model';
// import { Project } from '../../../../../zettasense/content/project/project.model';

@Component({
  selector: 'zm-ad-data',
  templateUrl: './ad-data.component.html',
  styleUrls: ['./ad-data.component.scss']
})

export class AdDataComponent implements OnInit {

  public openTaskDS: any = [];
  public usersDataSource: any = [];
  public todaysDate = new Date();
  public isDataReady = false;
  public totalrecords: string;

  public lineAnalyticChartOptions: object = {
    // 'height': 120,
    // 'width': 660,
    'resizeRequired': true,
    'label': 'year',
    'value': 'value',
    'lineColor': '#57cff2',
    'circleColor': '#ff0000',
    'isMoneyFormat':true,
  };
  public lineAnalyticChartDs = null;
  private _userinfo;
  public analyticData  = new Analytic();
  // public analyticData  = new Project();
  
  constructor(public cdRef: ChangeDetectorRef,
    public zettaUtils: ZmZettaUtils,
    // public userGroupService: UserGroupService,
    private analyticService: AnalyticService,
    public observeSvc: ZmObservableService,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    
    this.analyticData.totalrecords = 0;
    this.analyticData.datasets = [];

    const queryParams = this.activeRoute.snapshot.queryParams;
    const routeParams = this.activeRoute.snapshot.params;
    this._userinfo = JSON.parse(sessionStorage.getItem('userInfo'));

    // this.getAnalyticData(routeParams.id);
    // this.getActivityDatasets(routeParams.id);


    let dataSet = [];
    let value = 0;
    var totalrec = 0;
    for (var i = 0; i < 10; i++) {
      let d = new Date((Math.floor(Math.random() * (12 - 1)) + 1) + '/' +
                       (Math.floor(Math.random() * (28 - 1)) + 1) + '/' +
                       '201' + (i));
      value = (Math.floor(Math.random() *    i*(Math.floor(Math.random() * (300000 - 100)) + 100))    + (Math.floor(Math.random() * (200000 - 100)) + 100) + i*i*(Math.floor(Math.random() * (20000 - 10000)) + 10000));
      dataSet.push({ 'year': d.getFullYear(), 'value': value, 'fulldate':d });
      totalrec = totalrec + value;
    }
    this.lineAnalyticChartDs = dataSet;
    console.log('totalrec: ' + totalrec)
    totalrec = totalrec / 1000000;
    // this.totalrecords = totalrec.toFixed(1);
    this.totalrecords = (value/1000000).toFixed(1);

console.log('this.totalrecords: ' + this.totalrecords)

  }
  
	getAnalyticData(analyticId) {
    this.analyticService.getAnalytic(this._userinfo.userid, analyticId)
      .subscribe(res => {
        this.analyticData = res;
        // this.observeSvc.setEntityName = res.entityname;  
      },
        error => {
        this.analyticData.users = [];
      });
	}

  getActivityDatasets(analyticId){
    let parent = this;
    parent.analyticService.getActivityDatasets(parent._userinfo.userid, analyticId).subscribe(
      data => {
        parent.isDataReady = true;
        // parent.lineAnalyticChartDs = parent.prepareLineChartData(data);
      },
      error => {
        parent.isDataReady = true;
        parent.lineAnalyticChartDs = [];
      }
    );
  }

  prepareLineChartData(data) {
    let dataSet = [];
    data.forEach((element) => {
        var d = new Date(element['createdts']);
        dataSet.push({ 'year': d.getFullYear(), 'value': element['input_totalrecords'],'fulldate':d });  
    });
    return dataSet;
  }
}