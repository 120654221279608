import { Component, OnInit, ViewEncapsulation, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../../../zettasense/content/project/project.model';
import { ActivatedRoute } from '@angular/router';
import { mergerFormatter } from '../../../common/shared/formatters/mergerFormatter';
import { MessageService } from '../../../common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { AppGlobals } from '../../shared/app.globals';
declare var $: any;

@Component({
  selector: 'zetta-merger-feedback-modal',
  templateUrl: './merger-feedback-modal.component.html',
  styleUrls: ['./merger-feedback-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MergerFeedbackModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedZsClusterId: string;
  @Input() selectedZsTaskName: string;
  @Input() selectedZsTaskType: string;
  @Input() selectedZsProjectId: string;
  @Input() taskType: string;
  @Input() selectedZsTaskStatus: string;
  @Input() selectedZsRowDataSource: any;
  @Input() shouldDisableFeedback = false;
  @Output() onComplete = new EventEmitter<boolean>();
  @Output() onModalClose = new EventEmitter<boolean>();

  public selectOptionDataSource: object = {};
  public selectedRowDataSource: object = {};
  public selectedClusterId: string = '';
  public selectedTaskStatus: string = '';
  public rowData;
  public grid: AngularGridInstance;
  public mergerGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedCluster;
  public selectedColumnsfeedbackColumns;
  public selectedTaskName: String = '';
  public isClusterItemsLoading = false;
  public isReRunModelEnabled = true;

  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  gridOptions: any;
  modelGridOptions: any;
  projectDetail = new Project();
  userFeedback = new Map<string, object>();
  isLoading = false;
  page = 1;
  limit = 100;
  userWinnerFeedback = [];
  addOtherValue = 'Add Other';

  constructor(
    public projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.userFeedback.clear();
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.modelGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      frozenColumn: 1// to fix source system and primary key
    };
    $('#merger-feedback-modal').modal({
      show: true,
      backdrop: 'static'
    });
  }

  ngOnChanges() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    // this.activatedRoute.parent.params.subscribe(params => {
    //   this.projectDetail.project_id = '25';
    // });
    // this.activatedRoute.parent.params.subscribe(params => {
    //   this.projectDetail.project_id = params['id'];
    // });
    this.projectDetail.project_id = this.selectedZsProjectId;
    this.selectedClusterId = this.selectedZsClusterId;
    this.selectedTaskName = this.selectedZsTaskName;
    this.taskType = this.selectedZsTaskType;
    this.selectedTaskStatus = this.selectedZsTaskStatus;
    this.selectedRowDataSource = this.selectedZsRowDataSource;
    this.userFeedback[0] = { status: this.selectedTaskStatus }
    this.userFeedback[1] = { shouldDisableFeedback: this.shouldDisableFeedback };
    this.userFeedback[2] = {userWinnerFeedback : this.userWinnerFeedback};

    
    this.selectOptionModelList(this.selectedClusterId);
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  closeModal() {
    this.onModalClose.emit(true);
  }

  onModelMergerCreation(grid) {
    this.mergerGrid = grid;
  }

  minimizeRows(data) {
    const dataArray = data.currentpage;
    const columnsArray = [];
    // looging all physicalname from columns 
    for (let colIndex = 0; colIndex < data.columns.length; colIndex++) {
      if (data.columns[colIndex]['physical_name'] && data.columns[colIndex]['is_visible'] && data.columns[colIndex]['is_visible_in_merger']) {
        columnsArray.push(data.columns[colIndex]['physical_name']);
      }
    }
    // travering through each object
    for (let rowIndex = 0; rowIndex < dataArray.length; rowIndex++) {
      const eachRowItem = dataArray[rowIndex];
      // travering through each column in object
      for (let property in eachRowItem) {
        const eachColumnItem = eachRowItem[property];
        let columnFound = columnsArray.find(function (element) { return element === property; });
        // console.log('Each column value :: ', eachColumnItem);
        // if no data in column value, check data in future rows
        if (!eachColumnItem && columnFound) {
          // keeping this for feture reference
          // travering through each column in future row objects until a data is found
          // for (let nextRowIndex = rowIndex + 1; nextRowIndex < dataArray.length; nextRowIndex++) {
          //   const futureRowItem = dataArray[nextRowIndex];
          //   const futureColumnItem = futureRowItem[property];
          //   if (futureColumnItem) {
          //     // console.log('Future Item is ', futureColumnItem);
          //     // change current column value with future existed value
          //     eachRowItem[property] = futureColumnItem;
          //     eachRowItem[property + '_winner'] = futureRowItem[property + '_winner'];
          //     eachRowItem['shiftedCells'] = eachRowItem['shiftedCells'] ? eachRowItem['shiftedCells'] : [];
          //     let obj = {};
          //     obj['zs_recid'] = futureRowItem['zs_recid'];
          //     obj[property] = futureColumnItem;
          //     obj[property + '_winner'] = futureRowItem[property + '_winner'];
          //     eachRowItem['shiftedCells'].push(obj);

          //     // since moving the value to previous row, we set the future column value to null
          //     futureRowItem[property] = null;
          //     break;
          //   }
          // }
        }
      }
    }
    // remove rows with null values in all columns
    const newArray = [];
    for (let rowIndex = 0; rowIndex < dataArray.length; rowIndex++) {
      const eachRowItem = dataArray[rowIndex];
      let isValidRow = false;
      let anyNonNullColumn = false;
      for (let property in eachRowItem) {
        anyNonNullColumn = false;
        let columnFound = columnsArray.find(function (element) { return element === property; });
        if (columnFound) {
          const eachColumnItem = (eachRowItem[property] === undefined || eachRowItem[property] == null) ? true : false;
          if (!eachColumnItem) {
            anyNonNullColumn = true;
            break;
          }
        }
      }
      if (anyNonNullColumn) {
        isValidRow = true;
      }
      if (isValidRow) {
        newArray.push(eachRowItem);
      }
    }
    return newArray;
  }

  selectOptionModelList(clusterId, limit = 1000) {
    const parent = this;
    parent.isLoading = true;
    parent.projectService.getEntitiesMasteredFeedback(parent.loggedInUserDetails.user_id, clusterId, parent.projectDetail.project_id, 1, limit).subscribe((data) => {
      console.log(data)
          parent.rowData = parent.minimizeRows(data);
          console.log(parent.rowData)
          data['currentpage'] = parent.rowData;
          console.log(data['currentpage'])
          parent.selectOptionDataSource = data;
          parent.readDefaultFeedback(data['currentpage'], parent.selectOptionDataSource['columns']);

          parent.selectOptionDataSource['columns'].forEach((element: any) => {
            element.displayname = element.display_name;
            if (element.displayname === 'Full Name') {
              element.minWidth = 300;
            }
            element.physicalname = element.physical_name;
            element.datatype = element.data_type;
            element['formatter'] = mergerFormatter;
            element['params'] = parent.userFeedback;
            element['minWidth'] = 160;
          });

          const emptyObj = {};
          if(parent.rowData && parent.rowData.length) {
            Object.keys(parent.rowData[0]).forEach(function (key) {
              emptyObj[key] = 'Add Other';
            });
          }
          parent.rowData.push(emptyObj);
          this.selectedColumnsfeedbackColumns = this.selectOptionDataSource['columns'];
          // this.readDefaultFeedback(data['currentpage'], this.selectedColumnsfeedbackColumns);
          if (this.mergerGrid) {
            this.mergerGrid.dataView.setItems(this.rowData);
            this.mergerGrid.gridService.setSelectedRows([]);
          }
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
        });
  }

  onSelectedClusterCellClick(e): void {
    this.isClusterItemsLoading = false;
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.mergerGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.mergerGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.mergerGrid.dataView.getItems();

    console.log(args.row)
    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('radio-formatter')) {
        if ($(eventEle.target).hasClass('merger-formatter')) {
          row['shiftedCells'] = (row['shiftedCells'] && Array.isArray(row['shiftedCells'])) ? row['shiftedCells'] : [];
          const shiftedCCell = row['shiftedCells'].find(function (element) { return element[fieldName] === row[fieldName]; });
          const selectedRowObj = {};
          selectedRowObj['zs_recid'] = shiftedCCell ? shiftedCCell['zs_recid'] : row['zs_recid'];
          selectedRowObj[fieldName] = row[fieldName];
          this.userFeedback.set(fieldName, selectedRowObj);
        }

        dataSet.forEach((rowObj) => {
          this.mergerGrid.gridService.updateDataGridItem(rowObj, false);
        });
        this.mergerGrid.gridService.setSelectedRows([args.row]);
      }
    }
  }

  isApplyDisabled() {
    if (this.selectedTaskStatus === 'Completed') {
      return true;
    } else {
      return false;
    }
    // return false;
    // TODO:Confirm full validation like all columns are required with feedback
    // const keys = Array.from( this.userFeedback.keys());
    // if (this.selectedColumnsfeedbackColumns && keys.length > 0 && (keys.length === this.selectedColumnsfeedbackColumns.length)) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

  applySections() {
    const requestPayload = {};
    this.userFeedback.forEach((value: object, key: string) => {
      requestPayload[key] = value[key];
      if (value[key] != this.addOtherValue) {
        requestPayload[key + AppGlobals.ZS_REC_ID_MERGER_TEMPLATE] = value['zs_recid'];
      }
    });

    this.projectService.updateMergerFeedback(this.loggedInUserDetails.user_id, this.projectDetail.project_id, this.selectedClusterId, requestPayload).subscribe(resp => {
          this.isClusterItemsLoading = true;
          this.selectedRowDataSource['status'] = resp['status'];
          // $('#tm-modal').modal('hide');  
          $('#merger-feedback-modal').modal('hide');
          //  this.grid.gridService.updateDataGridItem(this.selectedRowDataSource, true, true);
          this.refresh();
    }, err => {
    });
  }

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
  }

  onReRunModel(data) {
    // Scenario id is 2 for this case
    this.projectService.reRunModel({ projectId: this.projectDetail.project_id, scenarioId: 2 }, 'stage4').subscribe(resp => {
      this._router.navigate(['/zs/projects', this.projectDetail.project_id]);
    }, err => {
      // alert(err.error.message);
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  // Read Get API Response for Feedback
  readDefaultFeedback(rows, columns) {
    rows.forEach(row => {
      row.id = Math.random();
      // this.selectedCluster = row.zs_cluster_identifier;
      columns.forEach(column => {
        const fieldName = column.physical_name;
        if (row[fieldName] !== null && row[fieldName] !== undefined && (row[fieldName + '_winner'] === "1" || row[fieldName + '_winner'] === 1)) {
          this.userFeedback.set(fieldName, row);
          this.userWinnerFeedback.push(row)
        }
      });
    });
  }

  refresh() {
    this.onComplete.emit(true);
  }

  getRunsupervisedData() {
    this.isLoading = true;
    this.projectService.getRunsuperviseByProjectId({ userId: this.loggedInUserDetails.user_id, selectedProjectId: this.projectDetail.project_id }, 'entitiesmastered').subscribe((data) => {
        this.isLoading = false;
        this.isReRunModelEnabled = data['run_supervised'];
      }, err => {
        this.isLoading = false;
      });
  }
}
