import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Catalog } from '../../classify.model';
import { ZsClContentService } from '../../zs-cl-content.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';

@Component({
  selector: 'zetta-zs-cl-create-project',
  templateUrl: './zs-cl-create-project.component.html',
  styleUrls: ['./zs-cl-create-project.component.scss']
})
export class ZsClCreateProjectComponent implements OnInit, OnDestroy {
  progressState = new ProjectStatus();
  createDataClassificationForm: FormGroup;
  errorMessage = undefined;
  dataClassification = new Catalog();
  ClassificationTypes: [];
  loggedInUserDetails: [];
  showBreadcrumb = false;
  project_id:string;
  selectedProjectType:number;
  formIsValid=false;
  semantic_id:string;
  isDataReady=false;
  isever_executed: string;
  isEditProject: string;
  constructor(public zettaUtils: ZettaUtils,
    private formBuilder: FormBuilder,
    public service: ZsClContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.isEditProject= this.activatedRoute.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }    
    this.progressState.type = 'Data Classification Project';
    this.progressState.currentStateIndex = 0;
    this.progressState.isEdit = this.isEditProject === 'true'
    this.activatedRoute.queryParamMap.subscribe(param =>{
      this.project_id = param.get('id');
      if(this.project_id){
        this.getProject();
      }
    });
    this.setDatasourceForm()
    this.getClassification()
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
  }

  setDatasourceForm() {
    this.createDataClassificationForm = this.formBuilder.group({
      title: [this.dataClassification.name, [Validators.required,Validators.pattern("^[A-Za-z0-9_]+( [A-Za-z0-9_]+)*")]],
      description: [this.dataClassification.description],
      classificationType: [this.dataClassification.lookup_id, Validators.required]
    });
  }

  getClassification() {
    this.service.getClassificationType().subscribe(response => {
      if(response) {
        this.ClassificationTypes = response.map(ele => {
          if(ele['lookup_id'] == AppGlobals.SEMANTIC_OBJECT_CLASSIFIER) {
            ele['description'] = AppGlobals.CLASSIFY_PROJECT_SEMANTIC_TYPE_DESCRIPTION;
            return ele;
          } else if(ele['lookup_id'] == AppGlobals.CONCEPT_PARSER) {
             ele['description'] = AppGlobals.CLASSIFY_PROJECT_CONCEPT_TYPE_DESCRIPTION;
             return ele;
          }
        }).sort((a, b) => a.lookup_id - b.lookup_id);
      }
      this.isDataReady = true;
    })
  }
  getProject(){
    const payload = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.project_id
    };
    this.service.getclassifyProject(payload).subscribe(resp => {
      if(resp){
        sessionStorage.setItem('projectData', JSON.stringify(resp));
        this.dataClassification = resp;
        this.dataClassification.lookup_id = resp.type;
        this.selectedProjectType = resp.type;
        this.semantic_id = resp.semantic_id;
        this.isever_executed=resp.ever_executed;
        this.setDatasourceForm()
        this.validateForm();
      }      
    });
  }
  validateForm(){    
    if (this.createDataClassificationForm.controls.title.value.trim() && (this.selectedProjectType || this.createDataClassificationForm.controls.classificationType.value)) {
      this.formIsValid = true;
    }
    else {
      this.formIsValid = false;
    }
  }
  onSubmit() {
    if (this.formIsValid) {
      const project_type = this.createDataClassificationForm.controls.classificationType.value;
      let updateData: any = {};
      let addData = {
        name: this.createDataClassificationForm.controls.title.value,
        description: this.createDataClassificationForm.controls.description.value,
        type: project_type ? project_type : this.selectedProjectType
      };
      if (this.project_id && this.semantic_id) {
        updateData = {
          name: this.createDataClassificationForm.controls.title.value,
          description: this.createDataClassificationForm.controls.description.value,
          type: project_type ? project_type : this.selectedProjectType,
          semantic_id: this.semantic_id
        };
      }
      const data = {
        user_id: this.loggedInUserDetails['user_id'],
        tenant_id: this.loggedInUserDetails['tenant_id'],
        payload: (this.project_id && this.semantic_id) ? updateData : addData
      };
      this.service.createClassificationProject(data, this.project_id).subscribe(responseList => {
        const project_type = responseList.length ? responseList[0].type : responseList.type
        let projectId = responseList.length ? responseList[0].classify_project_id : responseList.classify_project_id
        if(this.isEditProject)
        {
          this.router.navigate(['/zs-cl/projects/', projectId, 'add-users'], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: project_type ,isEditProject:true,ever_executed:this.isever_executed} });
        }
        else
        {
          this.router.navigate(['/zs-cl/projects/', projectId, 'add-users'], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: project_type } });
        }
       
      }, err => {
        this.errorMessage = err.error.message;
      });
    }

  }

  onTextChange() {
    this.validateForm();
    if (this.errorMessage) {
      this.errorMessage = false;
    }
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }

}
