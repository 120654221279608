import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-datasource-detail',
  templateUrl: './datasource-detail.component.html',
  styleUrls: ['./datasource-detail.component.scss']
})
export class DatasourceDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
