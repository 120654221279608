import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-blue-text-cell-renderer',
  template: `<div class="text-truncate"><label [title]="params.value" class="blueLink text-truncate w-100">{{ params.value }}</label></div>`,
  styles: [`
    .blue-link {
      color: #0e709f;
      cursor: default;
      margin-bottom: 0;
    }   
     .blue-link:hover {
      text-decoration: underline;
    }
  `]
})
export class BlueTextCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
