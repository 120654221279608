import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularGridInstance } from "angular-slickgrid";
import { MessageService } from "src/app/common/components/message/message.service";
import { hyperLinkFormattter } from "src/app/common/shared/formatters/hyperLinkFormatter";
import { ZsClContentService } from "../../../zs-cl-content.service";
import { environment } from 'src/environments/environment';
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { radioButtonNewFormatter } from "src/app/common/shared/formatters/radioButtonNewFormatter";

@Component({
    selector: 'zetta-registered-data-source',
    templateUrl: './registerd-data-source.component.html',
    styleUrls: ['./registerd-data-source.component.scss']
})
export class RegisterdDataSourceComponent implements OnInit {
    isDataReady = false;
    objectList: any;
    gridColumnsDef: any = [];
    tableSettings: object = { 'height': "100%", 'width': "99.70%" };
    gridOptions: any;
    sourceGrid: AngularGridInstance;
    loggedInUserDetails = null;
    project_id: number;
    total = 0;
    grandTotal = 0;
    subTotal = '';
    concept_id: number;
    page = 1;
    limit = 100;
    source_selected = false;
    hasScrolled = false;
    noData = false;
    project_classifier_id: number;
    dataset_id: string;
    datasource_id: string;
    showmodelpopup = false;
    datasetName: string;
    projecData=null;
    constructor(private activatedRoute: ActivatedRoute,
        private contentService: ZsClContentService,
        private zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private router: Router) { }

    ngOnInit(): void {
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.projecData = JSON.parse(sessionStorage.getItem('projectData'));
        this.activatedRoute.parent.parent.paramMap.subscribe(params => {
            this.project_id = +params.get('id');
        });
        this.activatedRoute.queryParamMap.subscribe(param => {
            this.concept_id = +param.get('concept_id');            
            this.datasource_id = param.get('datasource_id');
            if (param.has('dataset_id')) {
                this.dataset_id = param.get('dataset_id');
            } else {
                if (this.projecData.hasOwnProperty('project_details')) {
                    if (this.projecData.project_details.hasOwnProperty('sub_concept_source')) {
                        this.dataset_id = this.projecData.project_details.sub_concept_source.dataset_id;
                        this.datasource_id = this.projecData.project_details.sub_concept_source.datasource_id;
                    }
                }
            }
        });
        this.gridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
        };
        this.initColDef();
        this.getClassifireObject(this.page, this.limit);
    }
    initColDef() {
        this.gridColumnsDef = [
            {
                'displayname': '',
                'physicalname': 'isChecked',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': radioButtonNewFormatter,
                'minWidth': 30,
                'maxWidth': 40,
            }, {
                'displayname': 'Data Source',
                'physicalname': 'datasource_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'maxWidth': 300,
            },
            {
                'displayname': 'Data Set Name',
                'physicalname': 'dataset_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': hyperLinkFormattter
            }
        ];
    }
    getClassifireObject(pageNo, limit) {
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.project_id}/subconceptsources?concept_id=${this.concept_id}&paginate=true&pageno=1&pagesize=${limit}`;
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
            serverFilter = sessionStorage.getItem("serverfilter");
        }
        let serverSort = "";
        if (sessionStorage.getItem("sortfilter")) {
            serverSort = sessionStorage.getItem("sortfilter");
        }
        
        this.contentService.getSubConceptSource(this.project_id, this.concept_id,
            pageNo, limit, serverFilter, serverSort).subscribe(resp => {
                this.objectList = resp['currentpage'];
                if (this.objectList.length === 0) {
                    this.noData = true;
                }
                this.total = resp.totalrecords;
                if (pageNo === 1) {
                    this.grandTotal = resp.totalrecords;
                }
                if (this.dataset_id) {
                    this.objectList.forEach(element => {
                        if (element.dataset_id == this.dataset_id) {
                            this.source_selected = true;
                        }
                        element.isChecked = element.dataset_id == this.dataset_id ? true : false;
                    });
                }
               
                let concatedRows = this.objectList;
                if (this.sourceGrid) {
                    concatedRows = [...this.sourceGrid.dataView.getItems(), ...this.objectList];
                    this.objectList.forEach(element => {
                        element.id = Math.random();
                    });
                    this.objectList = concatedRows;
                    this.sourceGrid.dataView.setItems(this.objectList);
                    this.sourceGrid.gridService.setSelectedRows([]);
                    this.hasScrolled = false;
                }
                this.isDataReady = true;
            }, error => {
                this.isDataReady = true;
                this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            });

    }

    onCellClick(e): void {
        const event = e.eventData;
        const args = e.args;
        const row = this.sourceGrid.gridService.getDataItemByRowIndex(args.row);
        if (row) {
            this.dataset_id = row.dataset_id;
            this.datasource_id = row.datasource_id;
            if (event.target.className.includes('radio-formatter')) {
                $('#data-' + args.row).prop('checked', true);
                this.source_selected = true;
                this.project_classifier_id = row.classify_project_classifier_id;
            }
            if (event.target.title === row.dataset_name) {
                this.showmodelpopup = true;
                this.datasetName = row.dataset_name;
            }
        }
    }


    gridCreated(grid: AngularGridInstance) {
        this.sourceGrid = grid;
    }

    onScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextPage();
        }
    }
    onNextPage(): void {
        this.page++;
        this.getClassifireObject(this.page, this.limit);
    }

    onNext() {
        if (this.dataset_id && this.datasource_id) {
            this.router.navigate(['../column'], { relativeTo: this.activatedRoute, queryParams: { dataset_id: this.dataset_id, datasource_id: this.datasource_id, activeLink: '3' }, queryParamsHandling: 'merge' })
        } 
    }
    onPrev() {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute, queryParams: { activeLink: '1' }, queryParamsHandling: 'merge' })
    }
    onCancel() {
        this.router.navigate(['/zs-cl/projects']);
    }
    popupoutput(message) {
        this.showmodelpopup = message;
    }
    onUploadDataSource(){
        if(this.source_selected){
          this.router.navigate(['/zs/datasets/select-data-source']);
        }        
    }
    onManageDataSet(){
        this.router.navigate(['/zs/datasets']);
    }

    receiveTotalRecords($event) { 
        this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
    }
}