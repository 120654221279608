import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';

export interface CellParams {
    name?: string;
    active?: boolean;
}

@Component({
    selector: 'name-link-formatter',
    template: `<div class="d-flex align-items-center justify-content-center"><div class="font-wieght {{activeRuleCss}} text-center"><span class="{{spanCss}}">{{activeRuleText}}</span></div><div>`,
    styles: [
    ]
})
export class MeasuredDQRuleFormatterComponent implements OnInit, ICellRendererAngularComp {

    dqRuleEnabled = false;
    value: any;
    params: any;
    loggedInUserDetail = JSON.parse(sessionStorage.getItem('userInfo'));
    activeRuleCss: string;
    spanCss: string;
    activeRuleText: string;
    padRight: string

    agInit(params: ICellRendererParams & CellParams): void {
        this.value = params.value;
        this.dqRuleEnabled = false;
        let confidenceLevel = '';
        if (this.loggedInUserDetail['enable_dq_rule']) {
            this.dqRuleEnabled = true;
        }
        if (params.data) {
            if (this.dqRuleEnabled) {
                let conf = '';
                let cssLevel = '';
                let fixed = 2;
                fixed = Math.pow(10, fixed);
                const dqScore = params.data.measured_dq_score;
                const score100 = dqScore != 'n/a' ? Math.floor(dqScore * fixed) / fixed : dqScore;
                if (dqScore != 'n/a') {
                    conf = dqScore >= 75 ? 'HIGH' : (dqScore >= 50 && dqScore < 75) ? 'MEDIUM' : 'LOW';
                    cssLevel = conf === 'HIGH' ? 'priorityCell high' : conf === 'MEDIUM' ? 'priorityCell medium' : 'priorityCell low';
                }
                if (params.data.action !== 'add') {
                    let mr_left = 'ml-2';
                    if (params.data.disableMr) {
                        mr_left = '';
                    }
                    const disableMr = !params.data.disableMr ? '' : 'ml-2';
                    this.spanCss = "ml-1";
                    this.activeRuleCss = dqScore != 'n/a' ? `${mr_left} box-cat-obj ${cssLevel}` : (params.data.disable_text_center ? 'disable-rule pd-right-40' : 'disable-rule');
                    this.activeRuleText = dqScore != 'n/a' ? `${score100}%` : 'n/a';
                }
            }
            else {
                if (params.data.action !== 'add') {
                    if (params.data.disable_text_center) {
                        this.padRight = 'pd-right-40'
                    }
                }
            }
        }

    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    constructor() { }

    ngOnInit(): void {
        if (this.loggedInUserDetail['enable_dq_rule']) {
            this.dqRuleEnabled = true;
        }
    }

    onClick() {
        this.params.context.componentParent.updateRow(this.params.rowIndex);
    }


}
