import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ProjectStatus } from 'src/app/common/components/project-progress/project-progress.model';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { Project } from '../classify.model';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZsClContentService } from '../zs-cl-content.service';
import { MessageService } from 'src/app/common/components/message/message.service';
declare var $: any;
const ATLEAST_ONE_ADMIN_MSG = 'At-least one user with Admin role is required';

export const adminCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.role_id === 39) {
    return `
  <div class="formatter match-radio-formatter d-flex justify-content-center radio-formtter-wd">
  <input type="radio" checked />
  <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
  } else if (dataContext.role_id === 40) {
    return `<div class="formatter match-radio-formatter d-flex justify-content-center radio-formtter-wd">
 <input type="radio" />
 <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
 </div>`;
  };
}

export const executorCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.role_id === 40) {
    return `
    <div class="formatter match-radio-formatter d-flex justify-content-center radio-formtter-wd">
    <input type="radio" checked />
    <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
    </div>`;
  } else if (dataContext.role_id === 39) {
    return `<div class="formatter match-radio-formatter d-flex justify-content-center radio-formtter-wd">
    <input type="radio" />
    <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
    </div>`;
  }
}

@Component({
  selector: 'zetta-dq-users',
  templateUrl: './dq-users.component.html',
  styleUrls: ['./dq-users.component.scss']
})
export class DqUsersComponent implements OnInit {
  progressState = new ProjectStatus();
  ruleType: String = '';
  leftGrid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  leftGridDataSource: any = [];
  rightGridDataSource: any = [];
  gridColumnsDef: any;
  rightGridColumnsDef: any;
  leftGridRows = 0;
  rightGridRows = 0;
  leftGridOptions: any;
  rightGridOptions: any;
  showBreadcrumb = false;
  isRuleEdit = false;
  hasActiveJob = true;
  tableSettings: object = { 'height': "100%", 'width': "99.40%" };
  rightTableSettings: object = { 'height': "100%", 'width': "99.40%" };
  loggedInUserDetails: [];
  nextstepbtn = false;
  isDataReady = false;
  projectDetail: Project = new Project();
  isUserDataReady = false;
  hasLeftUsersSelected = false;
  hasRightUsersSelected = false;
  rule_id;
  ruleIds= [];
  selectedRules: any = [];
  viewMode;
  isAdminSelected = false;
  backTo: string;
  catalog_id: number;
  object_id: number;
  concept_id: number;
  dq_rule_level: string;
  isMultiRulesSelected = false;
  obRule_ids = [];
  dataset_id:string;
  dataset_column_id:string;
  is_clone = false;
  is_skipped_column: Boolean = false;

  constructor(public zettaUtils: ZettaUtils, private router: Router,
    public ZsClContentService: ZsClContentService, private activeRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.activeRoute.parent.queryParamMap.subscribe(params => {
      // this.rule_id = +params.get('rule_id');
      // if (this.rule_id > 0) {
      //   this.isRuleEdit = true;
      // }
      const mode = sessionStorage.getItem('viewMode');
      if (!mode) {
        this.viewMode = 'business'
      } else {
        this.viewMode = mode;
      }
      this.ruleIds = params.getAll('rule_id');
      this.rule_id = +params.get('rule_id');
      this.selectedRules.push(this.rule_id);
      this.isMultiRulesSelected = params.get('isMultiRules') == 'true' ? true : false;
      if (params.get('showBreadCrumb') && params.get('showBreadCrumb') == 'true') {
        this.showBreadcrumb = true;
        this.isRuleEdit = true;
      } else {
        this.showBreadcrumb = false;
        this.isRuleEdit = false;
      }
      // if(this.router.url.includes('edit_users') && this.showBreadcrumb){
      //   this.isRuleEdit = true;
      // } 
    });
    this.activeRoute.queryParamMap.subscribe(params => {
      this.catalog_id = +params.get('catalog_id');
      this.object_id = +params.get('object_id');
      this.concept_id = +params.get('concept_id');
      this.dq_rule_level = params.get('dqRuleLevel');
      this.dataset_id = params.get('dataset_id');
      this.dataset_column_id = params.get('dataset_column_id');
      this.backTo = params.get('BackTo');
      this.is_skipped_column = params.get('is_skiped_mapped_column') == "true" ? true : false;
      const is_clone = params.get('is_clone');
      if (is_clone && is_clone === 'true') {
          this.is_clone = true;
      }
      if (this.rule_id == null || this.rule_id == undefined || this.rule_id == 0) {
        this.obRule_ids = params.getAll('selectoobRule');
        if (this.obRule_ids.length >0) {
          this.rule_id = this.obRule_ids[0].toString();
          this.ruleIds = this.obRule_ids[0];
        }
      }
    });
    if (this.router.url.includes('create-technical-rule')) {
      this.ruleType = 'technical';
      // this.isRuleEdit = false;
    } else if (this.router.url.includes('create-bussiness-rule')) {
      this.ruleType = 'business';
    }
    if (this.ruleType === 'technical') {
      this.progressState.states = this.zettaUtils.getStateList('create-technical-rules');
      this.progressState.currentStateIndex = this.ruleType === 'technical' ? 1 : 6;
      this.progressState.type = 'create-technical-rules';
      this.progressState.type = 'classifySet';
    } else if (this.ruleType === 'business') {
      this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
      this.progressState.currentStateIndex = 6;
      this.progressState.type = 'create-bussiness-rules';
    }



    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.initColDef();

    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
      // noDataMsg: LEFT_EMPTY_GRID_MSG,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };

    // if(this.router.url.includes('edit-users')) {
    this.getUsers();
    // } else {
    //   this.getUsersList();
    //   this.isDataReady = true;
    //   if(!this.showBreadcrumb) {
    //     this.getRightUsers();
    //   }
    // }




  }

  initColDef() {
    this.gridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'className': 'blueLink'
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Group Name',
      'physicalname': 'group_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }];
    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'maxWidth': 150,
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'maxWidth': 150,
    },
    {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'maxWidth': 150
    },
    {
      'displayname': 'Rule Admin',
      'physicalname': 'role_id',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': adminCheckBoxFormattter,
      'minWidth': 80,
      'maxWidth': 120,
    },
    {
      'displayname': 'Rule Executor',
      'physicalname': 'role_name',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': executorCheckBoxFormattter,
      'minWidth': 80,
      'maxWidth': 120,
    }
    ];
  }

  // getUsersList() {
  // this.ZsClContentService.getLeftUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
  //   this.leftGridDataSource = responseList['currentpage'];
  //   console.log('left side users ', this.leftGridDataSource);
  //   this.leftGridDataSource.forEach(element => {
  //     element.id = Math.random();
  //   });
  // })
  //   this.isUserDataReady = true;
  // }

  // getRightUsers(){
  //   this.ZsClContentService.getRuleUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id).subscribe(respons => {
  //     this.rightGridDataSource = respons;
  //     console.log('right side users ', respons);
  //     this.rightGridDataSource.forEach(element => {
  //       element.id = Math.random();
  //     });
  //     this.isDataReady = true;
  //   });
  // }

  getUsers() {
    let ruleid;
    if(this.obRule_ids.length>0)
    {
      ruleid=this.obRule_ids[0];
    }
    else{
      ruleid=this.ruleIds[0];
    }
    this.ZsClContentService.getRuleUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], ruleid).subscribe(respons => {
      this.rightGridDataSource = respons;

      // user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
      if (this.isMultiRulesSelected) {
        if(this.ruleIds && this.ruleIds.length > 0) {
          let ruleIds = this.ruleIds;
          this.ruleIds = ruleIds.filter((id, ind) => ruleIds.indexOf(id) == ind);
          if(this.ruleIds && this.ruleIds.length > 1) {
            this.rightGridDataSource = this.rightGridDataSource.filter(user => user.user_id == this.loggedInUserDetails['user_id']);
          }
        }
      }
      this.rightGridDataSource.forEach(element => {
        element.id = Math.random();
        if (element.role_id == 39) {
          this.isAdminSelected = true;
        }
      });
      this.isDataReady = true;

      this.ZsClContentService.getLeftUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
        this.leftGridDataSource = responseList['currentpage'];

        this.rightGridDataSource.forEach(element => {
          let leftGridDS = this.leftGridDataSource.filter(obj => obj.user_id !== element.user_id);
          this.leftGridDataSource = leftGridDS;
        });

        this.leftGridDataSource.forEach(element => {
          element.id = Math.random();
        });
        if (this.rightGridDataSource.length > 0) {
          this.nextstepbtn = true;
        }

        this.isUserDataReady = true;
      })
    });
  }

  rightGridCreated(grid) {
    const parent = this;
    parent.rightGrid = grid;
    parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasRightUsersSelected = args.rows.length > 0 ? true : false;
      parent.nextstepbtn = args.rows.length > 0 ? true : false;
    });
  }
  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
    });
  }

  onAdd() {
    const leftGridDataService = this.leftGrid.gridService;
    const rightGridDataService = this.rightGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const userList: Object[] = [];
    const rows: any[] = [];
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        row['role_id'] = 40;

        rows.push(row);
      }
    });

    rows.forEach(row => {
      this.rightGridDataSource.push(row);
      rightGridDataService.addItemToDatagrid(row, true);
      this.leftGridDataSource = this.leftGridDataSource.filter(user => user.user_id !== row.user_id);
      leftGridDataService.deleteDataGridItem(row);

    });


    leftGridDataService.setSelectedRows([]);
    rightGridDataService.setSelectedRows([]);
    this.rightGridDataSource.forEach(element => {
      if (element.role_id) {
        userList.push({ user_id: element.user_id, role_id: element.role_id });
      } else {
        userList.push({ user_id: element.user_id });
      }
    });
    this.leftGridRows = this.leftGridRows - userList.length;
    this.rightGridRows = this.rightGridRows + userList.length;
    this.validateIsAdminSelected();
    // this.saveDqRule(userList);
  }

  onRemove() {
    const leftGridDataService = this.leftGrid.gridService;
    const rightGridDataService = this.rightGrid.gridService;
    const selectedRows: number[] = rightGridDataService.getSelectedRows();
    const userList: Object[] = [];
    const rows: any[] = [];

    selectedRows.forEach(index => {
      const row = rightGridDataService.getDataItemByRowIndex(index);
      if (row !== undefined && row !== null) {
        if (row.user_id == this.loggedInUserDetails['user_id']) {
          this.messageSvc.sendMessage({ message: 'Logged on user cannot be removed', type: 'INFO', hideboard: true });
        }
        else {
          rows.push(row);
        }
      }
    });
    rows.forEach(row => {
      row.is_admin = false;
      this.leftGridDataSource.push(row);
      leftGridDataService.addItemToDatagrid(row, true);
      this.rightGridDataSource = this.rightGridDataSource.filter(user => user.user_id !== row.user_id);
      rightGridDataService.deleteDataGridItem(row);
    });
    this.rightGridDataSource.forEach(element => {
      userList.push({ user_id: element.user_id });

    });
    let data = {
      body: [
        {
          data_quality_rule_id: this.ruleIds.map(i => Number(i)),
          users: [userList[0]]
        }
      ]
    };
    leftGridDataService.renderGrid();
    rightGridDataService.renderGrid();
    leftGridDataService.setSelectedRows([]);
    rightGridDataService.setSelectedRows([]);
    this.leftGridRows = this.leftGridRows + userList.length;
    this.rightGridRows = this.rightGridRows - userList.length;
    this.validateIsAdminSelected();
  }

  saveDqRule(users) {
    let data;
    if (this.showBreadcrumb) {
      data = {
        data_quality_rule_id: this.ruleIds.map(i => Number(i)),
        users: users
      };
    } else {
      data = {
        data_quality_rule_id: this.ruleIds.map(i => Number(i)),
        users: users
      };
    }
    this.ZsClContentService.saveDqUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, data)
      .subscribe(resp => {
      });
  }

  onRightGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
    const rows = this.rightGrid.dataView.getItems();
    const userList: Object[] = [];
    if (row !== undefined && row !== null) {
      if (fieldName === 'role_id') {
        row['role_id'] = 39;
        this.rightGridDataSource.forEach(element => {
          if (element.user_id == row.user_id) {
            element.isAdminSelected = true;
          }
        });
      } else if (fieldName === 'role_name') {
        row['role_id'] = 40;
        this.rightGridDataSource.forEach(element => {
          if (element.user_id == row.user_id) {
            element.isAdminSelected = false;
          }

        });
      }
      this.validateIsAdminSelected();
      let payload = {
        data_quality_rule_id: this.rule_id,
        user_id: row.user_id,
        role_id: row.role_id
      };
      this.ZsClContentService.updateDqUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], payload)
        .subscribe(res => {
        })
      this.rightGrid.gridService.updateDataGridItem(row, false);
    }
  }
  validateIsAdminSelected() {
    const adminData = this.rightGridDataSource.filter(ds => ds.isAdminSelected === true || ds.role_id == 39);
    if (adminData.length) {
      this.isAdminSelected = true;
    } else {
      this.isAdminSelected = false;
    }
  }

  navigateBack(opc) {
    $('.modal').modal('hide');
    this.router.navigate(['/zs-cl/dataquality']);
    if (this.dq_rule_level == 'concept') {
      if (this.backTo == 'catalog') {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'catalog') {
      if (this.backTo == 'catalog') {
        this.router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'object') {
      if (this.backTo == 'catalog') {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'dataset') {
      if(this.dataset_id && !this.dataset_column_id && this.backTo === 'dataset'){
        this.router.navigate([`/zs/datasets`]);   
      }  
      if (this.dataset_id && !this.dataset_column_id && this.backTo !== 'dataset') {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
      if (this.dataset_id && this.dataset_column_id) {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/columns/' + this.dataset_column_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
    } else {
      if(opc == 'Cancel' && this.is_clone) {
        sessionStorage.removeItem("ruleInfo");
        sessionStorage.removeItem("ruleInfoClone");
        sessionStorage.removeItem("tRuleDefinition");
        // removed deleting the rule for technical rule as part of requirement
        if (this.ruleType === 'business') {
          this.ZsClContentService.deleteDataQualityRule({ dq_rules_ids: this.ruleIds }).subscribe(resp => { });
        }
      }
      this.router.navigate(['/zs-cl/dataquality']); 
    }
  }

  navigateToNext() {

    let data;
    const gridrows = this.rightGrid.dataView.getItems();
    const userList: Object[] = [];
    gridrows.forEach(row => {
      userList.push({ user_id: row.user_id, role_id: row.role_id });
    });
    let rule_idmap=[];
    if(this.obRule_ids.length>0)
    {
      rule_idmap.push(this.ruleIds);
    }
    else if(this.obRule_ids.length==0)
    {
      rule_idmap = this.ruleIds.map(i => Number(i));
    }
    data = {

      data_quality_rule_id: rule_idmap,
      users: userList
    };
    this.ZsClContentService.saveDqUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, data)
      .subscribe(resp => {
      })
    if (this.ruleType === 'business') {
      this.router.navigate(['bussiness-run-schedule'], { relativeTo: this.activeRoute.parent, queryParamsHandling: 'preserve' });
    } else if (this.ruleType === 'technical') {
      this.router.navigate(['technical-run-schedule'], { relativeTo: this.activeRoute.parent, queryParamsHandling: 'preserve' });
    }
  }

  applychanges() {

    let data;
    const gridrows = this.rightGrid.dataView.getItems();
    const userList: Object[] = [];
    gridrows.forEach(row => {
      userList.push({ user_id: row.user_id, role_id: row.role_id });
    });
    data = {
      data_quality_rule_id: this.ruleIds.map(i => Number(i)),
      users: userList
    };
    this.ZsClContentService.saveDqUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, data)
      .subscribe(resp => {
      })
    $('.modal').modal('hide');

    if (this.viewMode === 'business') {
      this.router.navigate(['/zs-cl/dataquality/' + this.rule_id + '/' + this.viewMode + '/rule-definition'], { queryParams: { rule_id: this.ruleIds.map(i => Number(i)) } });
    } else if (this.viewMode === 'technical') {
      this.router.navigate(['/zs-cl/dataquality/' + this.rule_id + '/' + this.viewMode + '/rule-definition'], { queryParams: { rule_id: this.ruleIds.map(i => Number(i)) } });
    }

  }


  onPreviousClick() {
    if (this.router.url.includes('create-bussiness-rule')) {
      if (this.is_skipped_column) {
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/select-dataset'], { queryParamsHandling: 'preserve' });
      } else {
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/map-data-columns'], { queryParamsHandling: 'merge' });
      }
    } else if (this.router.url.includes('create-technical-rule')) {
      this.router.navigate(['/zs-cl/rules/create-technical-rule'], { queryParamsHandling: 'merge' });
    }
  }
}
