import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { BaseService } from 'src/app/common/services/base-service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ChartType, ChartDataSets, ChartConfiguration } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { DatasetService } from '../dataset.service';
import { IDataset } from '../dataset.model';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';

const columnFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {  
  if(value == 'null' || value == null) {
    return `
    <div><span title="null">null</span></div>
    `
  } else {
    return `
    <span class="text-truncate" title="${value}">${value}</span>
      `
  }
};
@Component({
    selector: 'zetta-dataset-sample',
    templateUrl: './dataset-sample.component.html',
    styleUrls: ['./dataset-sample.component.scss']
})
export class DatasetsSampleComponent implements OnInit {
    _count: boolean = false;
    dsName1:string;
    public modalGrid: AngularGridInstance;
    public loggedInUserDetails = null;
    isSampleDsLoading = false;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    selectedApp = 'zs';
    is_profile_loading = true;
    data_sets: any;
    profile_report: any = [];
    dataset_id = 1;
    noData=false;
    datasetTags: any = [];
    projectsJobs: string[] = [];
    datasetAttributes: any;
    page = 1;
    limit = 100;
    datasetId;
    showModal: boolean;
    url;
    Currenturl:string;
    isClassify:boolean=false;
    safeSrc: SafeResourceUrl;
    @Input() dsName:any;
    @Input() dataSetId:any;
    @Input() count;
    @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
    totalPage:number;
    is_collapse=false;
    btn_text="Expand All";
    hasScrolled=false;
    activeTab='ds-sample';

  public barChartLabels: Label[] =['', '', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public chartColors: any[] = [
    { 
      backgroundColor: '#EFB049'
    }];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [{
        display:false,
        ticks:{
          display:true,          
        }       
      }],
      yAxes: [{
        display:false,
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  }; 
  
  public barChartData: ChartDataSets[];
    constructor( public _projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private baseService: BaseService,
        private datasetService:DatasetService,
        private zettaUtils: ZettaUtils,
        private router:Router) {        
    }

    ngOnInit(): void {
      this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
      this.selectOptionDataSetModelList(this.dataSetId);
      this.getDataset();
      this.Currenturl=this.router.url.split('zs-cl')[1];
      if(this.Currenturl && this.Currenturl.includes('catalogs') 
      || this.Currenturl && this.Currenturl.includes('rules')){
        this.isClassify = true;
      }
      this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + this.dataSetId + '/dataprofiler/report';
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      this.modalGridOptions = {
        enableGridMenu: false,
        selectable: true,
        enableFiltering: true,
        CheckboxSelector: false,
        enableCellNavigation: true,
        enableRowSelection: true
      };
    }


    collapseAll(collapse){
     this.is_collapse=!collapse;
     this.btn_text = this.is_collapse?'Collapse All' :'Expand All';
    }
  getDataset() {
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataSetId.toString()
    }

    this.datasetService.getDataset(Datasets).subscribe((responseList) => {
      if (responseList) {
        this.projectsJobs['openProject'] = 'project'
        this.is_profile_loading = false;
        this.data_sets = responseList;
        let chartArry: any[] = [];
        let fullChart = [];
        if (responseList.profile_report) {        
          for (const prop in responseList.profile_report) {
            this.profile_report.push({
              "dataset_column_id": prop,
              "dataset_column_name":responseList.profile_report[prop].dataset_column_name,
              "primary_key_candidate": responseList.profile_report[prop].primary_key_candidate,
              "data_types": responseList.profile_report[prop].data_types,
              "least_frequent": responseList.profile_report[prop].least_frequent,
              "max_value": responseList.profile_report[prop].max_value,
              "min_value": responseList.profile_report[prop].min_value,
              "most_frequent": responseList.profile_report[prop].most_frequent,
              "nulls": responseList.profile_report[prop].nulls,
              "regex": responseList.profile_report[prop].regex,
              "uniques": responseList.profile_report[prop].uniques,
            });
          }
        }
        if (this.profile_report.length) {
          this.noData=true;
          this.profile_report.forEach(element => {
            element.most_frequent.forEach(el => {
              chartArry.push({ data: el.distribution, label: 'blinks' });
            });
            if (chartArry.length) {
              fullChart.push(chartArry);
            }
            chartArry = [];
          });
          this.barChartData = fullChart;
        }
      }

      this.is_profile_loading = false;
      this.datasetTags = [];
      if (responseList.tags != null) {
        this.datasetTags = responseList.tags_score;
      }
    }, err => { this.is_profile_loading = false; });
  }

  selectOptionDataSetModelList(dataSetId, page = 1, limit = 100) {
        this._projectService.getSampleDataset(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, page, limit).subscribe((data) => {
            this.modalRowData = data['currentpage'];
            this.totalPage =data['totalpages'];
            console.log(this.modalRowData);
            this.showModal = true;
            ($('#detect_model') as any).modal('show');
            let rowKeys = [];
            if (data['currentpage'].length > 0) {
              rowKeys = Object.keys(data['currentpage'][0]);
            }
            if(page==1){
              this.modelColumnDef = [];    
              rowKeys.forEach(column => {
                this.modelColumnDef.push({
                  'displayname': column,
                  'physicalname': column,
                  'sortable': true,
                  'datatype': 'String',
                  'filterable': true,
                  'minWidth': 150,
                  'formatter': columnFormattter
                });
              });
            }
            
    
            this.modalRowData.forEach((element: any) => {
              element.id = Math.random();
            });
            let concatedRows = this.modalRowData;
            if (this.modalGrid) {
              concatedRows = [...this.modalGrid.dataView.getItems(), ...this.modalRowData];
              this.modalRowData = concatedRows;
              this.modalGrid.dataView.setItems(this.modalRowData);
              this.modalGrid.gridService.setSelectedRows([]);
              this.hasScrolled = false;
            }
            this.isSampleDsLoading = false;
          }, err => {
            this.isSampleDsLoading = false;
            this.modalRowData = [];
            ($('#detect_model') as any).modal('show');
          });
      }
    
      onModelGridCreation(grid) {
        this.modalGrid = grid;
      }

      closeModelPopup(){
        this.modelPopupOutput.emit(false);
      }

      
      getProjectStyle() {
        if ($('#collapse0').hasClass('show')) {
          return 'selected-project expanded';
        } else {
          return 'selected-project collapsed';
        }
      }
    
      getJobStyle() {
        if ($('#collapse1').hasClass('show')) {
          return 'selected-job expanded';
        } else {
          return 'selected-job collapsed';
        }
      }

      showTab(tab) {
        this.activeTab = tab;
      }

      onScroll(parentClass) {        
       // if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
        if(this.page < this.totalPage){
          this.hasScrolled = true;
          this.onNext();
        }
         
        //}
      }
      onNext(): void {
        this.page++;
        this.selectOptionDataSetModelList(this.dataSetId,this.page, this.limit);
      }
      
}