import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, Injector, APP_INITIALIZER } from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe, LOCATION_INITIALIZED } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ZmZettaUtils } from '../zm-shared/zm-zettaUtils';
import { AppGlobals } from './app.globals';
import { AuthenticationService } from '../zm-services/authentication.service';
import { ZmAuthTokenInterceptor } from '../zm-services/AuthTokenInterceptor';
import { ZmObservableService } from '../zm-services/zm-observable.service';
import { ZmBaseService } from '../zm-services/zm-base-service';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZmZettaGridComponent } from '../zm-components/zm-zetta-grid/zm-zetta-grid.component';
import { ZmDonutChartComponent } from '../zm-components/zm-donut-chart/zm-donut-chart.component';
import { ZmLineChartComponent } from '../zm-components/zm-line-chart/zm-line-chart.component';
import { ZmMlineChartComponent } from '../zm-components/zm-mline-chart/zm-mline-chart.component';
import { ZmBarChartComponent } from '../zm-components/zm-bar-chart/zm-bar-chart.component';
import { ZmHbarChartComponent } from '../zm-components/zm-hbar-chart/zm-hbar-chart.component';
import { ZmHbarNegChartComponent } from '../zm-components/zm-hbar-neg-chart/zm-hbar-neg-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZmAuthGuard } from './zm-auth-guard.service';
import { ZmPaginationComponent } from '../zm-components/zm-pagination/zm-pagination.component';
import { AvatarModule } from 'ngx-avatar';
import { ZmSpinnerComponent } from '../zm-components/zm-spinner/zm-spinner.component';

import { ZmRangeSliderComponent } from '../zm-components/zm-range-slider/zm-range-slider.component';
import { SliderElementDirective } from '../zm-components/zm-range-slider/slider-element.directive';
import { SliderHandleDirective } from '../zm-components/zm-range-slider/slider-handle.directive';
import { SliderLabelDirective } from '../zm-components/zm-range-slider/slider-label.directive';
import { TooltipWrapperComponent } from '../zm-components/zm-range-slider/tooltip-wrapper.component';


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// use an Initializer Factory as describe here: https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        // console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

// @dynamic
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularSlickgridModule.forRoot(
      {
        alwaysShowVerticalScroll: true,
        autoEdit: false,
        asyncEditorLoading: false,
        autoFitColumnsOnFirstLoad: true,
        enablePagination: true,
        autoResize: {
          bottomPadding: 20,
          minHeight: 180,
          minWidth: 300,
          sidePadding: 0
        },
        cellHighlightCssClass: 'slick-cell-modified',
        checkboxSelector: {
          cssClass: 'slick-cell-checkboxsel'
        },
        columnPicker: {
          hideForceFitButton: false,
          hideSyncResizeButton: true
        },
        datasetIdPropertyName: 'id',
        defaultFilterPlaceholder: '&#128269;',
        editable: false,
        enableAutoResize: true,
        enableAutoSizeColumns: true,
        enableCellNavigation: false,
        enableColumnPicker: true,
        enableColumnReorder: true,
        enableExport: true,
        enableGridMenu: true,
        enableHeaderMenu: true,
        enableMouseHoverHighlightRow: true,
        enableSorting: true,
        enableTextSelectionOnCells: true,
        explicitInitialization: true,
        exportOptions: {
          delimiter: ',',
          exportWithFormatter: false,
          filename: 'export',
          groupingAggregatorRowText: '',
          sanitizeDataExport: false,
          useUtf8WithBom: true
        },
        forceFitColumns: false,
        gridMenu: {
          hideClearAllFiltersCommand: false,
          hideClearAllSortingCommand: false,
          hideExportCsvCommand: false,
          hideExportTextDelimitedCommand: true,
          hideForceFitButton: false,
          hideRefreshDatasetCommand: false,
          hideSyncResizeButton: true,
          hideToggleFilterCommand: false,
          hideTogglePreHeaderCommand: false,
          iconCssClass: 'fa fa-bars',
          iconClearAllFiltersCommand: 'fa fa-filter text-danger',
          iconClearAllSortingCommand: 'fa fa-unsorted text-danger',
          iconExportCsvCommand: 'fa fa-download',
          iconExportTextDelimitedCommand: 'fa fa-download',
          iconRefreshDatasetCommand: 'fa fa-refresh',
          iconToggleFilterCommand: 'fa fa-random',
          iconTogglePreHeaderCommand: 'fa fa-random',
          menuWidth: 16,
          resizeOnShowHeaderRow: true
        },
        headerMenu: {
          autoAlign: true,
          autoAlignOffset: 12,
          minWidth: 140,
          iconSortAscCommand: 'fa fa-sort-asc',
          iconSortDescCommand: 'fa fa-sort-desc',
          iconColumnHideCommand: 'fa fa-times',
          hideColumnHideCommand: false,
          hideSortCommands: false
        },
        headerRowHeight: 35,
        multiColumnSort: true,
        numberedMultiColumnSort: true,
        tristateMultiColumnSort: false,
        sortColNumberInSeparateSpan: true,
        suppressActiveCellChangeOnEdit: true,
        pagination: {
          pageSizes: [10, 15, 20, 25, 30, 40, 50, 75, 100],
          pageSize: 25,
          totalItems: 0
        },
        topPanelHeight: 35
      }
    )],
  exports: [
    RouterModule,
    CommonModule,
    DecimalPipe,
    CurrencyPipe,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ZmZettaGridComponent,
    ZmDonutChartComponent,
    ZmLineChartComponent,
    ZmMlineChartComponent,
    ZmBarChartComponent,
    ZmHbarChartComponent,
    ZmHbarNegChartComponent,
    ZmPaginationComponent,
    ZmSpinnerComponent,
    AvatarModule,

    ZmRangeSliderComponent,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    ZmZettaUtils, AuthenticationService, ZmAuthGuard, AppGlobals, ZmBaseService, ZmObservableService,
    //{ provide: HTTP_INTERCEPTORS, useClass: ZmAuthTokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  declarations: [
    ZmZettaGridComponent,
    ZmDonutChartComponent,
    ZmLineChartComponent,
    ZmMlineChartComponent,
    ZmBarChartComponent,
    ZmHbarChartComponent,
    ZmHbarNegChartComponent,
    ZmPaginationComponent,
    ZmSpinnerComponent,

    ZmRangeSliderComponent,
    SliderElementDirective,
    SliderHandleDirective,
    SliderLabelDirective,
    TooltipWrapperComponent,
  ]
})
export class ZmAppSharedModule {
  constructor() {
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ZmAppSharedModule
    };
  }
}
