import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList, ViewEncapsulation, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Formatter, Column, AngularGridInstance } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { ProjectService } from '../../../../zettasense/content/project/project.service';
import { DropDownEditor } from '../add-data-transformation/dropdown.editior';
import { DatasetService } from '../dataset.service';
import { interval, Subscription } from 'rxjs';
import { MessageService } from '../../message/message.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
declare var $: any;

const customConceptRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
    if (value == undefined) {
        value = "";
    }

    if(columnDef.field=="header")
    {
        return `<div>
        <input type="checkbox" id="dataSelected" name="dataSelected" value="${​​​​​​​​value}">
        </div>`

    }
    if (columnDef.field == "dataset_name") {
        return `<div class="div-input pr-3">
                    <input type="text" class="form-control text-Value" 
                    id='${columnDef.field}-${row}' name="name" value="${value}"​​​​​​​​>
                </div>`
    } else if (columnDef.field == "description" || columnDef.field == "schema") {
        return `<div class="div-input pr-3 pt-1">
                    <textarea row='3' id='${columnDef.field}-${row}' formControlName='${columnDef.field}' name='${columnDef.field}' class='form-control textarea-Value'>${value}</textarea>
                </div>`
    } else if (columnDef.field == "filetype" || columnDef.field == "delimiter") {
        var options = '';
        var valueName = '';
        for (var i in columnDef.params.list) {
            options = options +
                '<option value=\'' +
                columnDef.params.list[i].lookup_id + '\'>' + columnDef.params.list[i].name +
                '</option>\n';
            valueName = columnDef.params.list[i].name;
        }

     
        let placeholder = '';
        if (columnDef.field === 'delimiter') { placeholder = 'Select Delimiter...' }
        if (columnDef.field === 'filetype') { placeholder = 'Select Filetype...' }
        var classList
        return `<div class="div-input pr-3 pt-1 ${classList}">
                        <select id='${columnDef.field}-${row}'
                        class="form-control dropdown-Value">
                        <option value=${valueName}>${placeholder}</option>   ${options}
                        </select>
                </div>`

    }

};


const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
    value ? `<label class="checkbox-container">
                <input type="checkbox" checked>
                <span class="checkmark"></span>
             </label>` 
          : `<label class="checkbox-container">
                <input type="checkbox">
                <span class="checkmark"></span>

             </label>`;

export class DefineDs{
    id?: string;
    name:string;
    description:string;
    fileName:string;
    fileType:number;
    delimeter:number;
    headerRow:boolean;
    schema:string;
    isSelected:boolean;
    fileExtension: string;
    schemaType: number;
}
@Component({
    templateUrl: './define-dataset.component.html',
    styleUrls: ['./define-dataset.component.scss'],
    //encapsulation: ViewEncapsulation.None
})

export class DefineDatasetComponent implements OnInit, OnChanges, OnDestroy {

    isDataReady = false;
    adhocjoberror:boolean;
    errorMessage;
    progressState = new ProjectStatus();
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public grid: AngularGridInstance;
    public tableSettings: object = { 'height': '100%', 'width': "99.50%", 'pageSize': 20, 'isNormalPagination': false };
    datasetTableColDef: any;
    gridOptions: any;
    hasScrolled = false;
    isLoading = true;
    noData = false;
    total = 0;
    page = 1;
    limit = 100;
    selectedDataFiles;
    totallength = 0;
    showBreadcrumb = false;
    dataSelected = false;
    leftGridDataSource: any = [];
    listFileType: any = [];
    listDelimiters: any = [];
    selectedheaderrow = false;
    showModal: boolean;
    directorypath: string;
    datasource_Id;
    userdata: any[] = [];
    datasetname;
    datasetdesc;
    isDataSelected=false;
    selectedFiles:DefineDs[] =[];
    job_id;
    intervalId:any;
    subscription: Subscription;
    is_algo_called=false;
    selected_delimiter="";
    is_all_selected=true;
    dir_path="";
    img_logo= '../../../../assets/images/common/spinner-2.gif';
    deltaFile;
    public engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
    snowFlakeDS;
    snowFlakeEnabled: Boolean = false;
    selectedDatasetIds = [];
    isPreviousStepDisable: Boolean = false;
    listSchemaType: Array<object> = [{}];
    mapToCatalogs: boolean = false;
    catalogsList;
    semanticsList;
    catalogId;
    semanticId;
    defaultSchemaType = AppGlobals.DATA_SET_HEADER_SCHEMA;
    constructor(
        private router: Router,
        public projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private ZettaUtils: ZettaUtils,
        private datasetService: DatasetService,
        private clContentService: ZsClContentService,
        private messageSvc:MessageService) { }

        ngOnChanges(changes:SimpleChanges){
           
        }
    ngOnInit() {
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
         let  selectedDataSource = this.activatedRoute.snapshot.queryParamMap.get('datasourceType');
         if(selectedDataSource == '14950') {
            this.snowFlakeDS = true;
            this.snowFlakeEnabled = true;
        }
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }
        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }
       
        this.isDataReady = true;
        this.getFileTypes();
        this.getDelimeters()
        this.getSelectedFiles();  
        this.isLoading = false;
        this.datasetTableColDef = [{
            'displayname': 'Name',
            'physicalname': 'dataset_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customConceptRowFormatter,
            'minWidth': 120
        }, {
            'displayname': 'Description',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': false,
            'formatter': customConceptRowFormatter,
            'minWidth': 120,
        }, {
            'displayname': 'FileName',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'string',
            'filterable': true,
            'minWidth': 80,

        },
        {
            'displayname': 'FileType <label style="color:red">*</label>',
            'physicalname': 'filetype',
            'sortable': true,
            'datatype': 'string',
            'filterable': false,
            'formatter': customConceptRowFormatter,
            'minWidth': 90,
            'maxWidth': 120,
            'params': { list: this.listFileType }
        },
        {
            'displayname': 'Delimiter <label style="color:red">*</label>',
            'physicalname': 'delimiter',
            'sortable': true,
            'datatype': 'string',
            'filterable': false,
            'formatter': customConceptRowFormatter,
            'minWidth': 40,
            'maxWidth': 130,
            'editor': { model: DropDownEditor },
            'params': { list: this.listDelimiters }

        },
        {
            'displayname': 'HeaderRow <label style="color:red">*</label>',
            'physicalname': 'header',
            'sortable': true,
            'datatype': 'string',
            'filterable': false,
            'formatter': checkBoxFormattter,
            'minWidth': 70,
            'maxWidth': 100,
            'headerCssClass': 'titleBreak pt-2',

        },
        {
            'displayname': 'Schema',
            'physicalname': 'schema',
            'sortable': true,
            'datatype': 'string',
            'filterable': false,
            'formatter': customConceptRowFormatter,
            'minWidth': 90,

        },
        ];

        this.gridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            rowHeight: 50,
            editable: true,

        };

        const datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
        let dataset_Ids = []
        this.activatedRoute.queryParams.subscribe((params: any) => {
            dataset_Ids = params.datasetId;
        });

        this.progressState.states = this.ZettaUtils.getStateList('create-dataset');
        this.progressState.currentStateIndex = 1;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Data Set';
        this.progressState.isEdit = (datasetId !== undefined && datasetId !== null) ? true : false;
        this.progressState.isEdit ? sessionStorage.setItem('newDataSet', 'false') : sessionStorage.setItem('newDataSet', 'true');
        this.getCatalogs();
        if (dataset_Ids && dataset_Ids.length) {
            this.isPreviousStepDisable = true;
            this.selectedFiles = [];
            this.selectedDatasetIds = [];
            this.selectedDatasetIds = dataset_Ids;
            this.getDatasetSchemaLists();
            dataset_Ids.forEach((element, ind) => {
                this.getDatasetDetails(element, ind);
            });
        }
        this.getDatasetSchemaLists();
    }

    getDatasetDetails(dataset_id, ind) {
        const Datasets = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            dataset_id: dataset_id
        };
        this.datasetService.getDataset(Datasets).subscribe((responseList) => {
            if(responseList) {
                let delimiter;
                let shouldRetrieveSemanticObj = responseList.dataset_schema_type_lookup_id ===AppGlobals.DATA_SET_CATALOG_OBJECT_SCHEMA ? true: false;
                if (responseList.delimiter == ",") {
                    delimiter = 14786;
                } else if (responseList.delimiter == "|") {
                    delimiter = 14788;
                } else if (responseList.delimiter == '~') {
                    delimiter = 14795;
                } else if (responseList.delimiter == ";") {
                    delimiter = 14796
                } else if (responseList.delimiter == ":") {
                    delimiter = 14799
                } else {
                    delimiter = 14797;
                }
                this.selectedFiles.push({
                    id: responseList.dataset_id,
                    name: responseList.name,
                    description: responseList.description,
                    fileType: responseList.dataset_type_id,
                    fileExtension: responseList.dataset_type,
                    fileName: responseList.source_table_or_filename,
                    isSelected: true,
                    headerRow: responseList.header,
                    delimeter: delimiter,
                    schemaType: responseList['dataset_schema_type_lookup_id'] ? responseList['dataset_schema_type_lookup_id']: null,
                    schema: '',
                    ...responseList['mapped_catalog_id'] ? {catalog : responseList['mapped_catalog_id']} : {},
                    ...responseList['mapped_catalog_id'] ? {mapToCatalogs : true} : {mapToCatalogs : false},
                    ...responseList['mapped_semantic_id'] ? {semantic : responseList['mapped_semantic_id']} : {},
                    // ...responseList['dataset_schema_type_lookup_id'] ? {schemaType : responseList['dataset_schema_type_lookup_id']} : {}
                });
                this.bindObject(responseList.mapped_catalog_id, ind, shouldRetrieveSemanticObj, dataset_id);
                this.enableNextBtn();
            }
        }, err => { });
        this.enableNextBtn();
    }

    ngOnDestroy() {
        sessionStorage.removeItem('newDataSet');
    }

    ngAfterViewInit() {
        this.intervalId = setInterval(() => {
            this.getOutputFromAlgo();
        }, 30000)
    }
    getFileTypes(){
        this.datasetService.getFileTypeAndDelimeters('FileTypes').subscribe(resp=>{         
            if(resp.length){
                resp.forEach(type => {
                    this.listFileType.push({
                       "lookup_id":type.lookup_id,
                       "name":type.name
                    })
                });                
            }
            this.enableNextBtn();
        });
    }
    getDelimeters(){
        this.datasetService.getFileTypeAndDelimeters('Delimeters').subscribe(resp=>{
            if(resp.length){
                resp.forEach(type => {
                    this.listDelimiters.push({
                       "lookup_id":type.lookup_id,
                       "name":type.name,
                       "value":type.value
                    })
                });                
            }
            this.enableNextBtn();
        })
    }

    getDBRDeltaFiles(datasource_id, file_path) {
        this.datasetService.getDataSetSubFiles({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: datasource_id, "dir_name": file_path }).subscribe(resp => {
            if (resp.length) {
                resp.forEach(dir => {
                    if (dir['type'] === 'prefix' || dir['type'] === 'dir') {
                        if (dir['name'] === '_delta_log' || dir['name'] === '_delta_log/') {
                            this.selectedFiles.forEach(element => {
                                if (!element.fileExtension) {
                                    element.fileType = 14777;
                                    element.delimeter = 14797;
                                    element.headerRow = true;
                                }
                            });
                        }
                    }
                })
            }
        })

    }

    enableNextBtn(type=""){
        let isChecked=false;   
        this.selectedFiles.forEach(element => {                    
            if(type=="file")  {
                if(element.fileType==14790){
                    element.delimeter=14786;
                }else if(element.fileType==14791){
                    element.delimeter=14797;
                    element.headerRow = true;
                }else if (element.fileType == 14777) {
                    element.delimeter = 14797;
                    element.headerRow = true;
                }
              else{
                    element.delimeter=-1;
                    element.headerRow = false;
                }
            }
            
        });
       
        if(this.selectedFiles.length){
        let selected_item=this.selectedFiles.filter(file=>file.isSelected===true)
        if(this.totallength===selected_item.length){
            this.is_all_selected=true;
        }else{
            this.is_all_selected = false;
        }
            let checked_files = this.selectedFiles.filter(file => {
                let datasetSchemaTypeValidation = file.headerRow && file['schemaType'] &&
                    ((file['schemaType'] == AppGlobals.DATA_SET_HEADER_SCHEMA) ||
                        (file['schemaType'] == AppGlobals.DATA_SET_CATALOG_OBJECT_SCHEMA && file['catalog'] && file['catalog'] > 0 && file['semantic'] && file['semantic'] > 0 && file['mapToCatalogs'])) ? true : false;
                return file.fileType > 0 &&
                    (file.delimeter > 0 && file.delimeter != 14789) &&
                    (datasetSchemaTypeValidation || file.schema.length);
            })
            if (selected_item.length == checked_files.length) {
                this.isDataSelected = true;
            } else {
                this.isDataSelected = false;
            }
            
    
        }
       if(this.snowFlakeDS) {
        this.snowFlakeEnabled = true;
       } else {
        this.snowFlakeEnabled = false;
       }

    }
    selectAllFiles(is_select){
    this.selectedFiles.forEach(file => {
        file.isSelected=is_select;
    });
    }
    onPrevious() {
        this.router.navigate(['/zs/datasets/select-data-source'], { queryParams: { datasource_Id: this.datasource_Id } });
    }


    onGridCreation(grid) {
     
        this.grid = grid;
    }

    
    
    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.grid.gridService.getDataItemByRowIndex(args.row);
        console.log(row)
        const fieldName = this.grid.slickGrid.getColumns()[args.cell].field;
        const dataSet = this.grid.dataView.getItems();
        let idCell = '#'+fieldName + '-' + args.row;
        var oldValue: any;
        var newValue: any;
        if (row !== undefined && row !== null) {
            if (fieldName === 'header') {
                row['header'] = !row['header'];

            }

            if (fieldName === 'dataset_name') {
               newValue = $(idCell)[0]['value'];
               oldValue=row['dataset_name']
               if(oldValue !== newValue)
               {
                row.dataset_name = newValue;
               }
            }

            if (fieldName === 'description') {
                newValue = $(idCell)[0]['value'];
                oldValue=row['description']
                if(oldValue !== newValue)
                {
                 row.description = newValue;
                }
             }

             if (fieldName === 'schema') {
                newValue = $(idCell)[0]['value'];
                oldValue=row['schema']
                if(oldValue !== newValue)
                {
                 row.schema = newValue;
                }
             }

             if(fieldName=="delimiter")
             {
                newValue = $(idCell)[0]['value'];
                row.delimiter = newValue;
             }
             if(fieldName=="filetype")
             {
                newValue = $(idCell)[0]['value'];
                row.filetype = newValue;
             }
          //   this.reRenderSlickGrid(this.grid);
            // this.leftgrid.gridService.updateDataGridItem(row, false);

        }
    }

    reRenderSlickGrid(gridInstance) {
        gridInstance.slickGrid.updateRowCount();
        gridInstance.slickGrid.render();
        gridInstance.slickGrid.invalidate();
    }


    getSelectedFiles(): void {
        if (sessionStorage.getItem("selected_dir_file")) {
            this.selectedDataFiles = JSON.parse(sessionStorage.getItem("selected_dir_file"));
        }
        if (this.selectedDataFiles.length) {
            this.directorypath = this.selectedDataFiles[0].connection_string;
            this.datasource_Id = this.selectedDataFiles[0].datasource_id;
            this.dir_path = this.selectedDataFiles[0].short_path;
            for (let i = 0; i < this.selectedDataFiles.length; i++) {
                this.selectedFiles.push({
                    name: this.selectedDataFiles[i].name.split('.')[0],
                    fileExtension: this.selectedDataFiles[i].name.split('.')[1],
                    description: '',
                    fileType: this.snowFlakeDS ? 14957 : -1,
                    fileName: this.selectedDataFiles[i].name,
                    isSelected: true,
                    delimeter: this.snowFlakeDS ? 14789 :-1,
                    headerRow: false,
                    schema: '',
                    schemaType: null,
                });
                if (this.selectedDataFiles[i].type === 'dir' || this.selectedDataFiles[i].type === 'prefix') {
                    const lastChar = this.selectedDataFiles[i].connection_string.slice(-1);
                    if (lastChar !== '/') {
                        this.selectedDataFiles[i].connection_string += '/';
                    }
                    let file_path = this.selectedDataFiles[i].connection_string + this.selectedDataFiles[i].short_path;
                    this.getDBRDeltaFiles(this.selectedDataFiles[i].datasource_id, file_path);
                } else {
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        if (this.selectedFiles[i].fileExtension == "parquet") {
                            this.selectedFiles[i].fileType = 14791;
                            this.selectedFiles[i].delimeter = 14797;
                            this.selectedFiles[i].headerRow = true;
                            this.selectedFiles[i]['schemaType'] = this.defaultSchemaType; //defaultSchemaType
                        } else if (this.selectedFiles[i].fileExtension == "csv") {
                            this.selectedFiles[i].fileType = 14790;
                            this.selectedFiles[i].delimeter = 14786;
                            this.selectedFiles[i].headerRow = false;
                        } else {
                            if(this.snowFlakeDS) {
                                this.selectedFiles[i].delimeter = 14789;
                            } else {
                                this.selectedFiles[i].delimeter = -1;
                            }
                        }
                    }
                }
            }
            this.totallength = this.selectedDataFiles.length;
        }
    }


    callAutoDetect(e): void {
        const parent=this;
        let payload: any = {};
        let files=[];
        this.showModal = true;
        this.isDataSelected =false;
        let delimeter='';
        $('#auto_detect_model').modal('show');
        if(this.selectedFiles){
            this.selectedFiles.forEach(file=>{
               if(file.isSelected){
                files.push({
                    "name": file.name,
                    "description": file.description,
                    "datasource_id": this.datasource_Id,
                    "source_table_or_file_name": file.fileName,
                    "file_type": file.fileType,
                    "delimeter": file.delimeter,
                    "header": file.headerRow,
                    "schema": file.schema
                 });
                }
            });            
            payload={
                "object_type_id":347,
                "input":files
            }
        }
          
        this.datasetService.autoDetectPost(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'],payload).subscribe(resp=>{              
               if(resp.length){
                   payload={
                    "datasource_id" : this.datasource_Id,
                    "job_id": resp[0].job_id,
                    "dir": this.dir_path?this.dir_path:undefined
                   }
                   this.job_id=resp[0].job_id;
                   let algo_id;
                   if (this.engine_type_id === 452) {
                       algo_id = 402;
                   }
                   if (this.engine_type_id === 451) {
                       algo_id = 210;
                   }
                   this.datasetService.algo210(payload).subscribe(data=>{ 
                       if(data){
                           this.is_algo_called =true;                           
                           this.getOutputFromAlgo();
                       }
                   },error =>{
                    clearInterval(this.intervalId);
                    $('#auto_detect_model').modal('hide');
                    this.messageSvc.sendMessage({ message: `Algo ${algo_id} call has failed`, type: 'ERROR', hideboard: true });
                   });
               }
        },error=>{          
            clearInterval(this.intervalId);
            $('#auto_detect_model').modal('hide');
          this.messageSvc.sendMessage({ message: 'AutoDetect API call has failed', type: 'ERROR', hideboard: true });
        });
   
    }
    getOutputFromAlgo(){
        let schema="";
        if(this.job_id && this.is_algo_called){
            this.datasetService.getAdhocjobsById(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'],this.job_id).subscribe(respData=>{                
                if(respData.job_status === 14784){
                    this.adhocjoberror = false;
                    clearInterval(this.intervalId);
                    $('#auto_detect_model').modal('hide');
                    if(respData.output.length){
                        respData.output.forEach(element => {
                         this.selectedFiles.forEach(file=>{   
                             schema="";                          
                             if(file.name === element.name){
                                file.name=element.name;
                                file.description =element.description;
                                file.fileName=element.source_table_or_filename;
                                file.fileType=element.dataset_type_id;  
                                file.headerRow=element.header; 
                                file.delimeter=element.delimiter 
                                if(element.columns.length){
                                   element.columns.forEach(col => {
                                    schema+=col.column+", "
                                   });
                                file.schema = schema;
                                }    
                                                        
                             }
                         })
                        });
                    }
                    this.enableNextBtn();
                }
                if(respData.job_status==14785)
                {
                    this.adhocjoberror = true;
                    this.errorMessage = respData.errorMessage;
                    clearInterval(this.intervalId);
                }
               
               },error=>{
                clearInterval(this.intervalId);
                $('#auto_detect_model').modal('hide');
                  this.messageSvc.sendMessage({ message: 'GetAdhocjobsById call has failed', type: 'ERROR', hideboard: true });
               });
        }
        
    }

   
    //Bootstrap Modal Close event
    hide() {
        this.showModal = false;
        clearInterval(this.intervalId);
        $('#auto_detect_model').modal('hide');
       
    }

    onNext() {
        let fileData = this.selectedFiles.filter(file => file.isSelected === true);
        let payload = [];
        let delimeter = '';
        if (fileData.length) {
            fileData.forEach((element, index) => {
                if (this.selectedDatasetIds.length) {
                    payload.push({
                        "dataset_id": element.id ? element.id : this.selectedDatasetIds[index],
                        "name": element.name,
                        "datasource_id": this.datasource_Id,
                        "description": element.description,
                        "dataset_type_id": element.fileType,
                        "delimiter": element.delimeter,
                        "source_table_or_filename": element.fileName,
                        "header": element.headerRow,
                        "total_records": 0,
                        "columns": [],
                        ...element['catalog'] ? {catalog_id : element['catalog']} : {},
                        ...element['semantic'] ? {semantic_id : element['semantic']} : {},
                        ...element['schemaType'] ? {dataset_schema_type_lookup_id : element['schemaType']} : {},
                    });
                } else {
                    payload.push({
                        "name": element.name,
                        "datasource_id": this.datasource_Id,
                        "description": element.description,
                        "dataset_type_id": element.fileType,
                        "delimiter": element.delimeter,
                        "source_table_or_filename": element.fileName,
                        // "tenantId": 1,
                        "header": element.headerRow,
                        "total_records": 0,
                        "columns": [],
                        ...element['catalog'] ? {catalog_id : element['catalog']} : {},
                        ...element['semantic'] ? {semantic_id : element['semantic']} : {},
                        ...element['schemaType'] ? {dataset_schema_type_lookup_id : element['schemaType']} : {},
                    });
                }
            });
        }
        if (this.selectedDatasetIds.length) {
            let bulkDatasetIds = "";
            this.datasetService.updateBulkData(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], payload).subscribe(resp => {
                if (resp.length == 1) {
                    bulkDatasetIds += resp[0].dataset_id + ",";
                } else if (resp.length) {
                    resp.forEach(element => {
                        bulkDatasetIds += element.dataset_id + ",";
                    });
                }
                if (bulkDatasetIds.length) {
                    bulkDatasetIds = bulkDatasetIds.substring(0, bulkDatasetIds.length - 1);
                    sessionStorage.setItem("bulkDatasetIds", bulkDatasetIds);
                    this.router.navigate(['/zs/datasets/user-entitlements']);
                }
            }, error => {
                this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
            });
        } else {
            let bulkDatasetIds = "";
            this.datasetService.saveBulkData(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], payload).subscribe(resp => {
                if (resp.length == 1) {
                    bulkDatasetIds += resp[0].dataset_id + ",";
                } else if (resp.length) {
                    resp.forEach(element => {
                        bulkDatasetIds += element.dataset_id + ",";
                    });
                }
                if (bulkDatasetIds.length) {
                    bulkDatasetIds = bulkDatasetIds.substring(0, bulkDatasetIds.length - 1);
                    sessionStorage.setItem("bulkDatasetIds", bulkDatasetIds);
                    this.router.navigate(['/zs/datasets/user-entitlements']);
                }
            }, error => {
                this.messageSvc.sendMessage({ message: 'Name should be unique for each files ', type: 'ERROR', hideboard: true });
            });
        }
    }

    changeSchemaType(schema, ind) {
        if(schema == AppGlobals.DATA_SET_CATALOG_OBJECT_SCHEMA) {
           // this.catalogsList = [];
           // this.semanticsList = [];
            //this.getCatalogs();
            this.mapToCatalogs = true;
            this.catalogId = -1;
            this.selectedFiles[ind]['catalog'] = -1;
            this.selectedFiles[ind]['semantic'] = -1;
            this.selectedFiles[ind]['schema'] = "";
            this.selectedFiles[ind]['schemaType'] = AppGlobals.DATA_SET_CATALOG_OBJECT_SCHEMA;
            this.selectedFiles[ind]['mapToCatalogs'] = true;
        } else {
            this.mapToCatalogs = false;
            this.selectedFiles[ind]['schema'] = "";
            this.selectedFiles[ind]['mapToCatalogs'] = false;
            this.selectedFiles[ind]['schemaType'] = AppGlobals.DATA_SET_HEADER_SCHEMA;
        }
        this.enableNextBtn();
    }

    getCatalogs() {
        let data = { user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] };
        this.clContentService.getCatalogs(data.user_id)
            .subscribe(catalogs => {
            if (catalogs) {
                this.catalogsList = catalogs;
            }
        });
    }

    bindObject(catalogid, ind, shouldRetrieveSemanticObjectOfCatalog, dataset_id?) {
        if (shouldRetrieveSemanticObjectOfCatalog) {
            this.clContentService.getSemanticsObjects(catalogid).subscribe(objectList => {
                if (!dataset_id) {
                    this.selectedFiles[ind]['semanticsList'] = objectList;
                } else {
                    // after dataset created flow
                    let dsIndex = this.selectedFiles.findIndex((file) => {
                        return file.id == dataset_id;
                    })
                    if (dsIndex > -1) {
                        this.selectedFiles[dsIndex]['semanticsList'] = objectList;
                    }
                }
            }, error => { });
        } else {
            if (this.selectedFiles && this.selectedFiles[ind]) {
                this.selectedFiles[ind]['semanticsList'] = [];
            }
        }
    }
    
    changeCatalogName(selectedCatalog, ind) {
        this.catalogId = selectedCatalog;
        this.selectedFiles[ind]['catalog'] = Number(selectedCatalog);
        this.selectedFiles[ind]['semantic'] = -1;
        if (selectedCatalog != -1) {
            this.bindObject(selectedCatalog, ind, true);
        }
        this.enableNextBtn();
    }

    changeObjectName(selectedObject, ind) {
        this.selectedFiles[ind]['semanticId'] = selectedObject;
        this.selectedFiles[ind]['semantic'] = Number(selectedObject);
        this.semanticId = selectedObject;
        this.enableNextBtn();
    }

    enableHeaderRow(row, ind) {
        if(row && row.headerRow) {
            row['lookup_id'] = AppGlobals.DATA_SET_HEADER_SCHEMA;
            row['schemaType'] = this.defaultSchemaType.toString();
            this.selectedFiles[ind]['mapToCatalogs'] = false;
            // this.selectedFiles[ind]['defaultSchemaType'] = this.defaultSchemaType;
        } else if(row && !row.headerRow) {
            this.selectedFiles[ind]['schemaType'] = null;
        }
        this.enableNextBtn(row);
    }

    getDatasetSchemaLists() {
        this.clContentService.getDatasetSchemaLists()
        .subscribe(lookups => {
            if(lookups) {
                this.listSchemaType = lookups;
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
        });
    }
}