import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ContentService } from '../../../../zettasense/content/content.service';
import { MessageService } from '../../message/message.service';

@Component({
  selector: 'zetta-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  public adminUsers: any;
  isAdmin: boolean;
  tenantGroupsUsers: object;
  total = 0;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  userLoggedInFromAzure: boolean;
  constructor(private router: Router,
    public contentSvc: ContentService,
    private authService:MsalService,
    private activatedRoute:ActivatedRoute, 
    private messageSvc: MessageService
    ) { }

  ngOnInit() {
    this.isAdmin = this.loggedInUserDetails.is_super_admin;
    this.userLoggedInFromAzure = !!this.authService.getAccount();
    // this.isAdmin = true;
    if (this.isAdmin) {
      this.contentSvc.getAdminUsers().subscribe((data) => {
        this.adminUsers = data['currentpage'];
        this.adminUsers = this.adminUsers.filter(user => user.is_super_admin === true);
        this.total = this.adminUsers.length;
        this.adminUsers = this.adminUsers.splice(0, 4);
      }, error => {
        this.messageSvc.sendMessage({ message: error.error.error.message, type: 'ERROR', hideboard: true });
    });
    } else {
      this.contentSvc.getGroupsUsers(this.loggedInUserDetails).subscribe(resp => {
        this.tenantGroupsUsers = resp;
      }, error => {
        this.messageSvc.sendMessage({ message: error.error.error.message, type: 'ERROR', hideboard: true });
    });
    }

  }

  getProfileImageClass(index: number, total: number) {
    if (total === 1) {
      return 'col-md-12 text-center';
    } else if (index === 0 || index === 1) {
      return 'col-md-6 mb-2';
    } else if (index === 2 && total === 3) {
      return 'col-md-12';
    } else if (index === 2 || index === 3) {
      return 'col-md-6';
    }
  }
  onManageUser(){    
   if(this.userLoggedInFromAzure){
     this.router.navigate(['/zs/admin/ad-users'],{queryParams: { showBreadcrumb: true} });
   }else{
    this.router.navigate(['/zs/admin/users'],{queryParams: { showBreadcrumb: true} });
   }
  }

  getAdminUser() {
    if(this.isAdmin)
    {
      this.router.navigate(['/zs/admin/superadmin-users'],{queryParams: { showBreadcrumb: true} });
    }
}

}
