import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { AppGlobals } from '../../../common/shared/app.globals';
declare var $: any;

const numericFormatter: Formatter =(row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="text-truncate" title="${value}">${value}</div>`
}

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let check = dataContext.checked ? 'checked' : '';
  return `<div class="formatter match-radio-formatter">
            <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}" ${check}>
            <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
          </div>`;
};

const EMPTY_GRID_MSG = 'No catalogs to map.';

@Component({
  selector: 'zetta-syn-select-data-catalog',
  templateUrl: './syn-select-data-catalog.component.html',
  styleUrls: ['./syn-select-data-catalog.component.scss'],
 // encapsulation: ViewEncapsulation.None
})

export class SynSelectDataCatalogComponent implements OnInit, OnDestroy {

  public catalogs: any;
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  tableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  progressState = new ProjectStatus();
  dataset_id;
  catalogs_ids: any;
  selectedGridRowsSize = 0;
  catalog_id;
  object_id;
  syn_catalog_id;
  syn_object_id;
  isFiltering=false;   
  uploaded_files=[];
  clearModal =  Math.random(); 
  dataRes;
  is_upload_complete = false;
  fu_type_synonymImport: any;
  concept_id: number;

  constructor(
    private catalogSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.fu_type_synonymImport = AppGlobals.FU_TYPE_SYNONYM_IMPORT;
    this.activatedRoute.parent.parent.params.subscribe(params => {
      if(params.hasOwnProperty('catalog_id')){
        this.catalog_id = params['catalog_id'];
      }      
    });
    this.activatedRoute.parent.params.subscribe(params => {
      if(params.hasOwnProperty('catalog_id')){
        this.catalog_id = params['catalog_id'];
      } 
    });
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has("concept_id")) {
        this.concept_id = +params.get("concept_id");
      }
      if (params.has("syn_catalog_id")) {
        this.syn_catalog_id = +params.get("syn_catalog_id");
        this.selectedGridRowsSize = 1;
      }
    });

    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
    this.progressState.states = this.zettaUtils.getStateList('classify-set');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifySet';
    this.progressState.isEdit = false;

    this.getCatalogList(this.page, this.limit);
    this.tableColDef = [
      {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 32,
        'maxWidth': 32,
        // 'params': this.dataSelected,
      }, {
        'displayname': 'Data Catalog',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 150,
        'maxWidth': 250,
        'formatter': numericFormatter,
      }, {
        'displayname': 'Data Catalog Description',
        'physicalname': 'description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: EMPTY_GRID_MSG,      
    };

  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getCatalogList(pageNo, limit = 1) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs?pagination=true&pageno=1&pagesize=${limit}`;
    let serverfilter = "";
    if(sessionStorage.getItem("serverfilter")){
      serverfilter = sessionStorage.getItem("serverfilter"); 
      this.isFiltering=false;   
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
    } 
    this.catalogSvc.getCatalogsListPagedAndSearch(pageNo, limit, serverfilter,sortfilter).subscribe((data) => {
      this.catalogs = data["currentpage"];
      if (this.catalogs.length === 0) {
        this.noData = true;
      }
      this.catalogs.forEach((element, i) => {
        element.id = Math.random();
        if(this.syn_catalog_id && element.catalog_id == this.syn_catalog_id) {
          element.checked = true;
        }
      });
      this.isLoading = false;
      this.total = data["totalrecords"];
      let concatedRows = this.catalogs;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.catalogs];
        this.catalogs = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, (error) => {
      this.isLoading = false;
      // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedGridRowsSize = args.rows.length;
    });
  }

  onNext(): void {
    this.page++;
    this.getCatalogList(this.page, this.limit);
  }

  onScroll(parentClass) {
    if(sessionStorage.getItem("serverfilter")){
      this.isFiltering = true;
      this.noData = false;
    } else {
      if(this.isFiltering){
        this.page = 1;
        this.noData = false;
      }
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  receiveTotalRecords($event) {
    this.total=$event;      
  }

  onCancel(): void {
    if (this.object_id && this.concept_id) {
      this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
    } else {
      this._router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
    }
  }

  onNextStep(): void {   
    this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-select-semantic'], 
                      { queryParams: { object_id: this.object_id, syn_catalog_id: this.syn_catalog_id}, queryParamsHandling:'merge' });
  }

  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent.syn_catalog_id = row.catalog_id;
    $("#data-" + args.row).prop("checked", true);
    parent.selectedGridRowsSize = 1;
  }

  cancelUpload() {
    this.clearModal =  Math.random();
    $('#uploadFileModal').modal('hide');
  }

  cancelPopUp() {
    this.clearModal =  Math.random();
    $('#uploadFileModal').modal('hide');
  }

  receiveFileName($event) {
    if ($event === AppGlobals.FILE_UPLOADED) {
      if (this.is_upload_complete) {
        this.cancelUpload();
        this.onCancel();
      }
    } else {
      this.dataRes = $event;
      this.uploaded_files = this.dataRes.selectedDataset
      this.is_upload_complete = this.dataRes.is_upload_complete;
      const total_files = this.dataRes.count;
    }
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }
}
