import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { ObservableService } from '../../../common/services/observable.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project/project.service';
import { StatisticService } from './statistics.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StatisticsModel } from './statistics.model';
import { Project } from '../../content/project/project.model';
import { User } from '../../../common/shared/zetta-models';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/common/services/base-service';

@Component({
    selector: 'zetta-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
    providers: [StatisticService]
})
export class StatisticsComponent implements OnInit, OnChanges {

    loggedInUserDetails: User;
    input: StatisticsModel[] = [];
    projectDetail = new Project();
    isLeftSideBarActive = true;
    dqScore: string;
    noDqScore: boolean = false;
    @Input() pickertype: string;
    @Input() summaryfor: string;
    @Input() classifyViewResults?: boolean;
    @Output() showTopPanel = new EventEmitter<boolean>();
    @Output() classifySummaryData = new EventEmitter();
    @Input() isSummaryChanged: boolean;
    @Input() NoTasks: boolean =  false;
    UserDetails = JSON.parse(sessionStorage.getItem('userInfo'));

    constructor(private ProjectSvc: ProjectService,
        private staticRailService: StatisticService,
        private activatedRoute: ActivatedRoute,
        public observeSvc: ObservableService,
        private zettaUtils: ZettaUtils,
        private http: HttpClient,
        private baseService:BaseService) {
        this.loggedInUserDetails = this.staticRailService.getObjectFromSession('userInfo');
    }
    

    getStatisticBarStyle() {
        if (!this.isLeftSideBarActive) {
            return 'side-bar-expanded';
        } else {
                return 'side-bar-collapsed';
            }
        }

    ngOnInit() {
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        this.baseService.isStatisticDataChanged.subscribe( value => { 
            if(value){
                if (this.pickertype === 'manageClassifyProject') {
                    this.staticRailService.getClassifyProjectSummary(
                        this.loggedInUserDetails.user_id,
                        this.UserDetails.tenant_id,
                        this.projectDetail.project_id).subscribe(resp => {
                            this.input = resp.filter(element => (element.name !== 'total_entities'));
                            if(this.input){
                                this.showTopPanel.emit(true);
                            }
                        }, err => {
                            this.showTopPanel.emit(false);
                        });
                }
            }
        });

        if (!this.projectDetail.project_id) {
            if (this.pickertype === 'allTasks') {
                this.staticRailService.getAllTasksSummary(this.loggedInUserDetails.user_id).subscribe(resp => {
                    this.input = resp;
                }, err => {
                });
            }
            else if(this.pickertype.toLowerCase() === 'classify'.toLowerCase()){
                let allUsers=false;
                if(this.summaryfor.toLowerCase() === 'allTasks'.toLowerCase()){
                    allUsers = true;
                }
              this.staticRailService.getAllClassifyTasksSummary(this.loggedInUserDetails.user_id,this.pickertype,allUsers).subscribe(resp=>{
                this.input = resp;                
              },error=>{              
              });
            }
            else {
                this.staticRailService.getAllMyTasksSummary(this.loggedInUserDetails.user_id).subscribe(resp => {
                    this.input = resp;
                }, err => {
                });
            }

        } else {
            if (this.pickertype === 'tasks') {
                this.staticRailService.getProjectTasksSummary({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id }).subscribe(resp => {
                    this.input = resp.filter(element => element.name !== 'project_total_tasks');
                }, err => {
                });
            } else if (this.pickertype === 'manageprojecttasks') {               
                this.getManageProjectSummary();
            }
            else if (this.pickertype === 'manageClassifyProject') {
                this.staticRailService.getClassifyProjectSummary(
                    this.loggedInUserDetails.user_id,
                    this.UserDetails.tenant_id,
                    this.projectDetail.project_id).subscribe(resp => {
                        this.input = resp.filter(element => (element.name !== 'total_entities'));
                        if(this.input){
                            this.showTopPanel.emit(true);
                            this.classifySummaryData.emit(this.input);
                        }
                    }, err => {
                        this.showTopPanel.emit(false);
                    });
            }  else if(this.pickertype === 'golenRecords') {
                this.staticRailService.getGoldenRecordsSummary(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe(resp => {
                    resp.forEach(element => {
                        if(element.name == 'dq_score') {
                            this.dqScore = Number(element.value * 100).toFixed(2);
                            let isNum = isNaN(Number(this.dqScore));
                            if (isNum) {
                                this.noDqScore = true;
                            } else {
                                this.noDqScore = false;
                            }
                            if(this.dqScore == '100.00') {
                                this.dqScore = '100';
                            }
                            this.input = resp;
                        } else if(element.name === 'total_records' || element.name === 'as_of') {
                            element['count'] = element.value;
                        } else {
                            return;
                        }
                    });
                }, err => {
                });
            } else if(this.pickertype == 'production-summary') {
                this.staticRailService.getProductionStageStatistics(this.loggedInUserDetails.user_id, this.projectDetail.project_id)
                .subscribe(data => {
                    if(data && data.matcher && data.merger && data.latestDate) {
                        for (let key in data) {
                            if(key == 'latestDate') {
                                    this.input[0] = {"display_name": "As of", "name": "as_of","value": Number(key),count: data['latestDate'], records: "0"};
                            } else if(key == 'matcher') {
                                this.input[1] = {"display_name": "Matching Confidence","name": "overall_confidence","value": data.matcher.over_all_confidence,count: "0",records: "0"};
                            } else if (key == 'merger') {
                                this.input[2] = {"display_name": "Merging Confidence","name": "overall_confidence","value": data.merger.over_all_confidence,count: "0",records: "0"};
                            }
                        }
                    } else {
                        this.NoTasks = true;
                    }
                }, err => {
                });
            }       
            else {
                this.getProjectMatcherSummary();
            }

        }

        this.observeSvc.refreshDataGrid.subscribe(data => {
            this.isLeftSideBarActive = data;
        });
           
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSummaryChanged && changes.isSummaryChanged.currentValue) {
            if (this.pickertype.toLowerCase() === 'classify'.toLowerCase()) {
                let allUsers = false;
                if (this.summaryfor.toLowerCase() === 'allTasks'.toLowerCase()) {
                    allUsers = true;
                }
                this.staticRailService.getAllClassifyTasksSummary(this.loggedInUserDetails.user_id, this.pickertype, allUsers).subscribe(resp => {
                    this.input = resp;
                }, error => {
                });
            } else if (this.pickertype.toLowerCase() === 'matcher' || this.pickertype.toLowerCase() === 'merger') {
                this.getProjectMatcherSummary();
            } else if (this.pickertype.toLowerCase() === 'manageprojecttasks') {
                this.getManageProjectSummary();
            }
        }
        if(changes && changes.classifyViewResults && changes.classifyViewResults.currentValue) {
            this.classifyViewResults = changes.classifyViewResults.currentValue;
        }
    }

    currencyFormat(input) {
        return this.zettaUtils.currencyFormat(input);
    }

    getProjectMatcherSummary(){
        this.staticRailService.getProjectSummary(this.loggedInUserDetails.user_id, this.projectDetail.project_id, this.pickertype, this.summaryfor).subscribe(resp => {
            this.input = resp.filter(element => (element.name !== 'compression_ratio' && element.name !== 'total_records'));
        }, err => {
        });
    }
    getManageProjectSummary() {
        this.staticRailService.getProjectTasksSummary({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id }).subscribe(resp => {
            this.input = resp;
        }, err => {
        });
    }

}
