import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance } from 'angular-slickgrid';
import { ProjectService } from '../../../../zettasense/content/project/project.service';
import { BaseService } from '../../../services/base-service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { environment } from '../../../../../environments/environment';
import { DatasetService } from '../dataset.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';



@Component({
  selector: 'zetta-client-profits',
  templateUrl: './client-profits.component.html',
  styleUrls: ['./client-profits.component.scss']
})
export class ClientProfitsComponent implements OnInit {

  dataSetName: String;
  dataRes;
  activeNavLnk = "ds-attribute";    //"ds-attribute"    "ds-data-quality"
  showmodelpopup = false;
  datasetId;
  @Output() dataRes1 = new EventEmitter<any>();
  tableSettings: object = { 'height': "100%", 'width': "99.71%" };
  public grid: AngularGridInstance;
  public loggedInUserDetails = null;
  dsSampleRows: any;
  isSampleDsLoading=false;
  gridOptions: any;
  gridColumnDef = [];
  page = 1;
  limit = 100;
  total_rows;
  total_column;
  ds_dq_score;
  isFiltering = false;
  noData = false;
  hasScrolled = false;
  total=0;
  isSorting = false;
  enableDQRule = JSON.parse(sessionStorage.userInfo).enable_dq_rule;
  dataset;
  entitlements;
  isAdmin;
  isReadWrite;
  isRead;
  enableDsRelationshipTab: boolean;
  isDatasetHaveAdminORReadWriteAccess: boolean;

  constructor(private activatedRoute: ActivatedRoute,private _projectService: ProjectService,private sanitizer: DomSanitizer,
    private baseService: BaseService, private catalogSvc: ZsClContentService,
    public zettaUtils: ZettaUtils, private datasetService: DatasetService) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.baseService.hasPageRefresh.subscribe(value=>{
      if(value){
        this.activeNavLnk="ds-attribute"
        sessionStorage.removeItem('activeNavLnk');       
      }
    });
    this.dataRes = false;
    this.activatedRoute.parent.params.subscribe(params => {
      this.datasetId = params['id'];
    });
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.gridOptions = {
        enableGridMenu: false,
        selectable: true,
        enableFiltering: true,
        CheckboxSelector: false,
        enableCellNavigation: true,
        enableRowSelection: true
    };
    this.selectOptionDataSetModelList(this.datasetId,this.page, this.limit);
    this.getDataset();
    if(sessionStorage.getItem('user_entitlement')) {
      let user_entitlement = sessionStorage.getItem('user_entitlement');
      let user = user_entitlement.split(',');     
      this.isAdmin = JSON.parse(user[0]) ? true : false;
      this.isReadWrite = JSON.parse(user[1]) ? true : false;
      this.isRead = JSON.parse(user[2]) ? true : false;
    }
    this.catalogSvc.isJobCompleted.subscribe(resp => {
      if(resp == true) {
        this.enableDsRelationshipTab = true;
      } else if(resp == false) {
        this.enableDsRelationshipTab = false;
      }
    }
    );
  }

  getDataset() {
    const Datasets = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      dataset_id: this.datasetId
    };
    this.datasetService.getDataset(Datasets).subscribe((response) => {
      if(response) {
        this.entitlements = response.entitlement.users.filter(ele => ele.user_id == this.loggedInUserDetails['user_id'])[0];
        this.isDatasetHaveAdminORReadWriteAccess = this.entitlements && (this.entitlements.is_admin || this.entitlements.read_write) ? true : false;
      }
    }, err => { });
  }

  selectOptionDataSetModelList(dataSetId, pageNo, limit = 20) { 
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets/${this.datasetId}/sampledata?checkAtrributes=true&pageno=${1}&pagesize=${limit}`;  
    var serverfilter = "";
    if(sessionStorage.getItem("serverfilter")){
      var serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if(this.isFiltering){ 
        pageNo = 1;
      }
      this.isFiltering = false;
    }
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
      this.isSorting = true;
   } else {
     if (this.isSorting) {
       pageNo = 1;
     }
     this.isSorting = false;
   }
    this._projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, pageNo, limit,serverfilter,sortfilter).subscribe((data) => {
      if (data) {
        this.dsSampleRows = data['currentpage'];
        this.total = data.totalpages;
        this.isSampleDsLoading = false;
        let rowKeys = [];
        this.total_rows = data.totalrecords
        if (data['currentpage'].length > 0) {
          rowKeys = Object.keys(data['currentpage'][0]);
          this.total_column = rowKeys.length;
        }
       this.gridColumnDef = [];
        rowKeys.forEach(column => {
          this.gridColumnDef.push({
            'displayname': column,
            'physicalname': column == 'id' ? 'ds_sample_id' : column,
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 150,
          });
        });

        this.dsSampleRows.forEach((element: any) => {
          element.ds_sample_id = element.id;
          element.id = Math.random();

        });
        let concatedRows = this.dsSampleRows;
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.dsSampleRows];
          this.dsSampleRows = concatedRows;
          this.grid.dataView.setItems(this.dsSampleRows);
          this.grid.gridService.setSelectedRows([]);
              if(pageNo <data['totalpages']) {
          this.hasScrolled = false;
        }
        }
        this.isSampleDsLoading = false;

        if(pageNo > data['totalpages']) {        
          this.page = 1;
          this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets/${this.datasetId}/sampledata?checkAtrributes=true&pageno=${1}&pagesize=${limit}`;
        }
      }

    }, err => {
      this.isSampleDsLoading = false;
      this.dsSampleRows = [];
    });
  }

  onGridCreation(grid) {
    const parent = this;
      parent.grid = grid;
      parent.grid.slickGrid.onSort.subscribe((e, args) => {
        this.page = 1;
        this.hasScrolled = false;
      });
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }

    if(!this.hasScrolled && this.page < this.total){
      this.hasScrolled = true;
      this.onNext();
    }
  }
  goToPage(n: number): void {
    this.page = n;
    this.selectOptionDataSetModelList(this.datasetId,this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.selectOptionDataSetModelList(this.datasetId,this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.selectOptionDataSetModelList(this.datasetId,this.page, this.limit);

  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total_rows, this.limit);
  }

  onTabClick(activeLink: string) {
      if((activeLink == 'ds-data-quality' && !this.enableDQRule) || (activeLink == 'ds-relationships' && !this.enableDsRelationshipTab) ||
         ((activeLink == 'ds-refresh' || activeLink == 'ds-enitilement') && (!this.isAdmin && !this.isReadWrite && this.isRead)) ||
         (activeLink == 'ds-enitilement' && (!this.isAdmin && this.isReadWrite))) {
        return;
      } else {
        this.activeNavLnk = activeLink;
        sessionStorage.setItem('activeNavLnk', activeLink);
      }
 }
  getDataSetName(dataSetName) {
    this.dataSetName = dataSetName;
  }
  getDataSetDqScore(dq_score) {
    this.ds_dq_score = dq_score;
  }

  receiveData($event) {
    this.dataRes = $event;
    this.dataRes1.emit(this.dataRes);
  }
  showpopup() {
    this.showmodelpopup = true;   
  }

  popupoutput(message){
      this.showmodelpopup = message;   
    }
}
