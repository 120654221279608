import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'confidence-level-formatter',
  template: `
    <div [ngClass]="['margin-top-5',getCssClasses()]">
      <ng-container *ngIf="!dataContext.action || dataContext.action !== 'add'">
        <ng-container *ngIf="dataContext.measured_data_quality">
          <div class="font-wieght ml-2 box-cat-obj" [ngClass]="getCssClasses()" *ngIf="dataContext.isRuleEnabled">
            <span class="ml-1">{{ getActiveRuleText() }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!dataContext.measured_data_quality" >
          <div class="box-cat-obj text-center " [ngClass]="getCssClasses()">{{ getConfidenceLevel() }}</div>
          <div class="porcent text-center mr-2  font-weight-normal text-black" [title]="getScoreTitle()">
  {{ getScorePercentage() }}
</div>

          <div class="handUp text-bold pointer">
            <i [id]="'thumbs-up-' + row" class="fa-thumbs-up" [class.fas]="isThumbsUpSelected()" [class.fal]="!isThumbsUpSelected()" [class.disabled-feedback]="dataContext.shouldDisableFeedback"></i>
          </div>
          <div class="handDown text-bold pointer">
            <i [id]="'thumbs-down-' + row" class="fa-thumbs-down" [class.fas]="isThumbsDownSelected()" [class.fal]="!isThumbsDownSelected()" [class.disabled-feedback]="dataContext.shouldDisableFeedback"></i>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  styles: [
    `
    .margin-top-5 {
      margin-top: 5px;
    }
`
  ]
})
export class ConfidenceLevelFormatterComponent implements OnInit, ICellRendererAngularComp {
  @Input() row: number;
  @Input() dataContext: any;

  agInit(params: ICellRendererParams): void {
    this.row = params.rowIndex;
    this.dataContext = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getCssClasses(): string {
    const score100 = Math.round(this.dataContext.score * 100);
    const conf = score100 >= 75 ? 'HIGH' : (score100 >= 50 && score100 < 75) ? 'MEDIUM' : 'LOW';
    return conf === 'HIGH' ? 'priorityCell high' : conf === 'MEDIUM' ? 'priorityCell medium' : 'priorityCell low';
  }

  getActiveRuleText(): string {
    const score100 = Math.round(this.dataContext.score * 100);
    return this.dataContext.isRuleEnabled ? `${score100}%` : 'n/a';
  }

  getConfidenceLevel(): string {
    const score100 = Math.round(this.dataContext.score * 100);
    return score100 >= 75 ? 'HIGH' : (score100 >= 50 && score100 < 75) ? 'MEDIUM' : 'LOW';
  }

  getScorePercentage(): string {
    const score100 = Math.round(this.dataContext.score * 100);
    return `${score100}%`;
  }

  getScoreTitle(): string {
    const score100 = Math.round(this.dataContext.score * 100);
    return `${score100}%`;
  }

  isThumbsUpSelected(): boolean {
    return this.dataContext.vote === 1;
  }

  isThumbsDownSelected(): boolean {
    return this.dataContext.vote === 0;
  }

  ngOnInit(): void {
  }
}
