import { Analytic } from '../zm-analytics/analytic.model';


export class ProjectStatus {

    states: string [];

    currentStateIndex: number;

    currentStateInfo: string;

    type: string;

    project: Analytic;

    constructor() {
    }
}
