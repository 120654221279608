import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from './../shared/zetta-models';


import { Records } from './records.constants';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppGlobals } from '../shared/app.globals';

export class BaseService {
  baseUrl: string = environment.config.BASE_URL;
  apiUrl: string = environment.config.API_URL;

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };
  public hasPageRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deleteRule: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addManualClass: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deSelectText: Subject<any> = new Subject();
  public resetTextSelected: Subject<any> = new Subject();
  public addSubConcept: Subject<any> = new Subject();
  public isStatisticDataChanged: Subject<boolean> = new Subject();
  public showAssignUsers: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor() { }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  handleError(error) {
    // redirect to login/404 screen > based upon the response message
    return Observable.throw(error.json().error || 'Server error');
  }

  public fetchRecords(recordTye: Records, http: HttpClient, id?: string): Observable<any> {
    if (id) {
      return http.get(`${this.apiUrl}` + recordTye + `/` + id+`?group_results_by=whole`);
    } else {
      return http.get(`${this.apiUrl}` + recordTye);
    }
  }

  public getUserPic(userid: string, groupid: string, http: HttpClient): Observable<any> {
    return http.get(`${this.apiUrl}/users/${userid}/groups/${groupid}/profile_pic`,{ responseType: 'blob' });
  }

  getObjectFromSession(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  addObjectToSession(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeObjectFromSession(key: string) {
    sessionStorage.removeItem(key);
  }

  clearSession() {
    let rememberMe = sessionStorage.getItem('rememberMe');
    let zsLogin = sessionStorage.getItem('is_zs_login_page');
    sessionStorage.clear();
    sessionStorage.setItem('rememberMe', rememberMe);
    zsLogin === 'true' ? sessionStorage.setItem('is_zs_login_page', 'true') : '';
  }

  public updateUserPreferences(preferences: any, http: HttpClient): Observable<any> {
		return http.put(`${this.apiUrl}/users/${preferences.user_id}`, preferences.payload);
	}
  
  public getAppLocation(userId: any, http: HttpClient): Observable<any> {
    const endpoint = `${this.apiUrl}/users/${userId}/apps`;
    return http.get(endpoint);
  }

  public getIngestHostStatus(userId: any, http: HttpClient): Observable<any> {
    const appId = 4;
    const endpoint = `${this.apiUrl}/users/${userId}/apps/${appId}`;
    return http.get(endpoint);
  }

  public getIngestLocationByTenant(userId: any, tenantId: any, http: HttpClient): Observable<any> {
    const endpoint = `${this.apiUrl}/users/${userId}/tenants/${tenantId}/env`;
    return http.get(endpoint);
  }
  getAttributeRules(attributes) {
    if (attributes.length) {
      attributes.forEach(attr => {
        attr.must_be_populated_or_non_null = false;
        attr.must_be_populated_or_non_null_rule_id = null;
        attr.must_be_defined_data_type = false;
        attr.must_be_defined_data_type_rule_id = null;
        attr.reference_data_list = false;
        attr.reference_data_list_rule_id = null;
        attr.must_be_unique_value = false;
        attr.must_be_unique_value_rule_id = null;
        attr.must_come_before = false;
        attr.must_come_before_rule_id = null;
        attr.must_come_after = false;
        attr.must_come_after_rule_id = null;
        attr.must_be_equal_to = false;
        attr.must_be_equal_to_rule_id = null;
        attr.must_be_greater_than = false;
        attr.must_be_greater_than_rule_id = null;
        attr.must_be_less_than = false;
        attr.must_be_less_than_rule_id = null;
        attr.right_attribute_id = null;
        attr.must_be_populated_or_non_null_lookup_id = null;
        attr.must_be_defined_data_type_lookup_id = null;

        if (attr.default_rules.length) {
          attr.default_rules.forEach(rule => {
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_POPULATED_OR_NOT_NULL_LOOKUP_ID) {
              attr.must_be_populated_or_non_null = rule.is_rule_exists_with_concept;
              attr.must_be_populated_or_non_null_rule_id = rule.data_quality_rule_id;
              attr.must_be_populated_or_non_null_lookup_id = AppGlobals.MUST_BE_POPULATED_OR_NOT_NULL_LOOKUP_ID;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_DEFINED_DATA_TYPE_LOOKUP_ID) {
              attr.must_be_defined_data_type = rule.is_rule_exists_with_concept;
              attr.must_be_defined_data_type_rule_id = rule.data_quality_rule_id;
              attr.must_be_defined_data_type_lookup_id = AppGlobals.MUST_BE_DEFINED_DATA_TYPE_LOOKUP_ID;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_HAVE_VALID_VALUE_FROM_REFERENCE_DATA_LIST_LOOKUP_ID) {
              attr.reference_data_list = rule.is_rule_exists_with_concept;
              attr.reference_data_list_rule_id = rule.data_quality_rule_id;
              attr.reference_data_list_lookup_id = AppGlobals.MUST_HAVE_VALID_VALUE_FROM_REFERENCE_DATA_LIST_LOOKUP_ID;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_UNIQUE_VALUE_LOOKUP_ID) {//14926
              attr.must_be_unique_value = rule.is_rule_exists_with_concept;
              attr.must_be_unique_value_rule_id = rule.data_quality_rule_id;
              attr.must_be_unique_value_lookup_id = AppGlobals.MUST_BE_UNIQUE_VALUE_LOOKUP_ID;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_COME_BEFORE_LOOKUP_ID) { //14927
              attr.must_come_before = rule.is_rule_exists_with_concept;
              attr.must_come_before_rule_id = rule.data_quality_rule_id;
              attr.right_attribute_id = rule.right_attribute_id;
              attr.must_come_before_lookup_id = AppGlobals.MUST_COME_BEFORE_LOOKUP_ID;
              attr.must_come_before_attribute_id =  rule.right_attribute_id;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_COME_AFTER_LOOKUP_ID) {//14928
              attr.must_come_after = rule.is_rule_exists_with_concept;
              attr.must_come_after_rule_id = rule.data_quality_rule_id;
              attr.right_attribute_id = rule.right_attribute_id;
              attr.must_come_after_lookup_id = AppGlobals.MUST_COME_AFTER_LOOKUP_ID;
              attr.must_come_after_attribute_id =  rule.right_attribute_id;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_EQUAL_TO_LOOKUP_ID) {//14929
              attr.must_be_equal_to = rule.is_rule_exists_with_concept;
              attr.must_be_equal_to_rule_id = rule.data_quality_rule_id;
              attr.must_be_equal_to_lookup_id = AppGlobals.MUST_BE_EQUAL_TO_LOOKUP_ID;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_GREATER_THAN_LOOKUP_ID) {//149230
              attr.must_be_greater_than = rule.is_rule_exists_with_concept;
              attr.must_be_greater_than_rule_id = rule.data_quality_rule_id;
              attr.right_attribute_id = rule.right_attribute_id;
              attr.must_be_greater_than_lookup_id = AppGlobals.MUST_BE_GREATER_THAN_LOOKUP_ID;
              attr.must_be_greater_than_attribute_id =  rule.right_attribute_id;
            }
            if (rule.default_rule_lookup_id === AppGlobals.MUST_BE_LESS_THAN_LOOKUP_ID) {//149231
              attr.must_be_less_than = rule.is_rule_exists_with_concept;
              attr.must_be_less_than_rule_id = rule.data_quality_rule_id;
              attr.right_attribute_id = rule.right_attribute_id;
              attr.must_be_greater_than_lookup_id = AppGlobals.MUST_BE_LESS_THAN_LOOKUP_ID;
              attr.must_be_less_than_attribute_id =  rule.right_attribute_id;
            }
          });
        }
      });
      return attributes;
    }
 }
}
