import { ZettaUtils } from '../../shared/zettaUtils';
import { ProjectStatus } from './project-progress.model';
import { Component, Input, OnInit, ChangeDetectionStrategy, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { $ } from 'protractor';

@Component({
  selector: 'zetta-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectProgressComponent implements OnInit {

  @Input() stateData: ProjectStatus;
  @Input() applyConnectorEnd=false;
  @Input() dqRule=false;
  @Input() editTrainModalData? = false;
  url: string;
  isEntitlements='';
  img: string;
  project_type = '';
  fadeOut = false;
  showBreadcrumb=false;
  
  constructor(public zettaUtils: ZettaUtils,private router:Router, private route: ActivatedRoute,) {    
    this.url=this.router.url.split('?')[0];
    if(this.url.includes('set-user-entitlements')){
      this.isEntitlements='set-user-entitlements';
      return;
    }
    if(this.url.includes('add-users')){      
      this.isEntitlements='add-users';
      return;
    }
    let path=this.router.url.split('zs')[1];
    
    if(path.split('/')[2].includes('schedule-refresh')){
      this.isEntitlements="schedule-refresh";
      return;
    }
    if(path.split('/')[2].includes('define-datasets')){
      this.isEntitlements="define-datasets";
      return;
    }
    
    if(path.split('/')[1].includes('datasets')){
       this.isEntitlements='datasets';
       return;
    }
    
    if(path.split('/')[1].includes('datasource')){
       this.isEntitlements='datasource';
       return;
    }
    if(path.split('/')[1].includes('projects')){
      this.isEntitlements='projects';
      return;
   }
    const synUrl=this.router.url.split('?')[0];
    if(synUrl.includes('syn-select-data-catalog') || synUrl.includes('syn-select-semantic')){
      this.isEntitlements='synonyms';
      return
    }
    if(this.url.includes('concepts')){
      this.isEntitlements='concept';
      return;
    }
    const urlP=this.router.url.split('zs')[1];
    if(urlP.includes('catalogs')){
      this.isEntitlements='catalogs';
      return;
    }
    const dsUrl=this.router.url.split('?')[0];
    if(dsUrl.includes('add-admin-users')){
      this.isEntitlements='admin';
      return
    }
    
    
  }

  ngOnInit() {   
    this.fadeout(); 
    if(this.stateData.type === 'Data Source' && this.stateData.hasOwnProperty('datasource')) {
      this.img = '../../../../../' + this.zettaUtils.getBreadCrumbDatasourceTypeById(parseInt(this.stateData.datasource.lookup_id));
    }
    this.route.queryParamMap.subscribe(params => {
      this.project_type = params.get('project_type');
      this.showBreadcrumb = params.get('showBreadcrumb') == 'true' ? true : false;
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if(changes && changes['stateData'] && !changes['stateData'].firstChange){
      $('.saved').removeClass('fadeOut');
      this.fadeout();
    }
  }

  getStyle(index: number, selectedIndex: number) {
    if (index > selectedIndex + 1) {
      return 'removeLeftPadding';
    } else {
      return 'removeLeftPadding';
    }
  }

  getStateStyle(index: number, selectedIndex: number) {
    if (index === 3 && index === selectedIndex) {
      return 'customLineSelected3Index';
    } else if (index === 3 && index !== selectedIndex) {
      return 'customLineNotSelected3Index';
    }
  }

  getLineStyle(index: number, selectedIndex: number) {
    if (index === 0 && index === selectedIndex) {
      return 'customLineSelected0Index';
    } else if (index === 0 && index !== selectedIndex) {
      return 'customLineNotSelected0Index';
    } else if (index === 1 && index !== selectedIndex) {
      return 'customLineNotSelected1Index';
    } else if (index === 1 && index === selectedIndex) {
      return 'customLineSelected1Index';
    } else if (index === 2 && index === selectedIndex) {
      return 'customLineSelected2Index';
    } else if (index === 2 && index !== selectedIndex) {
      return 'customLineNotSelected2Index';
    }
  }
  fadeout() {
   setTimeout( this.addclass, 1000);
  }
  addclass() {
    $('.saved').addClass('fadeOut');
  }
}
