// blue-text-formatter.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-blue-text-formatter',
  template: `
  <div class="position-relative">
    <div class="position-absolute mr-l-3 green-right-arrow f-size-16"><i class="fas fa-right-long"></i></div>
  </div>
  `,
  styles: [
    // Add any additional styles if needed
  ]
})
export class RightArrowIconFormatter implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
