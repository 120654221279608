import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../../../../zettasense/content/content.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { MessageService } from '../../message/message.service';
import { environment } from '../../../../../environments/environment';

const activityParameterFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {	
  let noData = false
  if (!dataContext.param.object_id) {
    noData = true
  } 
  return noData ? 
  `<div class="text-wrap text-truncate wd-16">
    <span data-title='${dataContext.param.object_id}' class="text-truncate"></span>
  </div>`
  : `<div class="text-wrap text-truncate wd-16">
      <span data-title='${dataContext.param.object_id}' class="text-truncate">${dataContext.param.object_id}</span>
    </div>`;
};
@Component({
  selector: 'zetta-job-settings',
  templateUrl: './job-settings.component.html',
  styleUrls: ['./job-settings.component.scss']
})

export class JobSettingsComponent implements OnInit {

  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public settings: any;
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	is_visible_setting=false;	
	is_enable_setting=false;

  activityHistory:any = [];
  public grid: AngularGridInstance;
  tableSettings: object = { 'height': "100%", 'width': "99%", 'pageSize': 10, 'isNormalPagination': false };
  historyTableColDef: any;
  gridOptions: any;
  isLoading = true;
  noData = false;
  hasScrolled = false;
  page = 1
  limit = 100

  constructor(private router: Router, 
    public contentSvc: ContentService,
    private zettaUtils: ZettaUtils,
    private messageSvc: MessageService) {
    if (this.login_user) {
      try {
        let user_entitlements = this.login_user[0].entitlement;
        if (this.login_user.length > 1) {
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if (user_entitlements.length) {
            user_entitlements = user_entitlements[0].entitlement;
          } else {
            user_entitlements = this.login_user[0].entitlement;
          }
        }
        const admin = user_entitlements.screens.filter(item => item.name == 'admin')[0];
        if (admin) {
          const job_setting = admin.childs.filter(item => item.name == 'manage_job_setting')[0];
          if (job_setting) {
            this.is_visible_setting = job_setting.visible;
            this.is_enable_setting = job_setting.enable;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
      this.contentSvc.getJobSettings(this.loggedInUserDetails.user_id).subscribe((data) => {
        this.settings = data;
      });

      this.historyTableColDef = [{
        'displayname': 'User ID',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 50,
      }, {
        'displayname': 'Activity',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 90,
      }, {
        'displayname': 'Parameter',
        'physicalname': 'param',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100,
        'formatter': activityParameterFormatter,

      },{
        'displayname': 'Date Time',
        'physicalname': 'created_ts',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 70,
        'formatter': dateFormatter
      }];
  
      this.gridOptions = {
        enableGridMenu: false,
        selectable: true,
        CheckboxSelector: false,
        enableCellNavigation: true,
        enableRowSelection: true,
        enableFiltering: true,
      };
  
      this.getActivityHistoryData(this.page, this.limit)
  }

  onGridCreation(grid) {    
    this.grid = grid;
  }

  getActivityHistoryData(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/activities?pageno=${1}&pagesize=${limit}`;   

    let serverfilter="";
    if(sessionStorage.getItem("serverfilter")){
       serverfilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    const user_id = this.loggedInUserDetails.user_id;
    this.contentSvc.getActivityHistory(user_id, page, limit, serverfilter, sortfilter).subscribe((data) => {
      this.activityHistory = data['currentpage'];
      if (this.activityHistory.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      let concatedRows = this.activityHistory;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.activityHistory];
        this.activityHistory.forEach(element => {
          element.id = Math.random();
          element.parameter = element.param.object_id
        });
        this.activityHistory = concatedRows;
        this.grid.dataView.setItems(this.activityHistory);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: 'View Audit logs has failed', type: 'INFO', hideboard: true });

    });
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onNext(): void {
    this.page++;
    this.getActivityHistoryData(this.page, this.limit);
  }

  getManageJobs() {
    this.router.navigate(['zs/admin/activities']);
  }

}
