import { Component, NgModule } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-source-system-renderer',
  template: `<ng-container *ngIf="shouldDisplayMasterIdFormatter">
                <div class="blueLink pointer modal-feedback text-truncate" data-backdrop="true">{{ paramValue }}</div>
            </ng-container>
            <ng-container *ngIf="!shouldDisplayMasterIdFormatter">
                <div class="text-center fixed-div" >{{ paramValue }}</div>
            </ng-container>`
})
export class SourceSystemRendererComponent {
  params: any;
  paramValue: any;
  shouldDisplayMasterIdFormatter = false;
    
  agInit(params: ICellRendererParams): void {  
    //this.paramValue = params.value; 
    this.paramValue = params.value;

    if(params.colDef.field === "zs_cluster_id" || (params.colDef.field.includes('zs_full_name_'))){
        this.shouldDisplayMasterIdFormatter = true;
    }
  }

}
 