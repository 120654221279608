import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../project.service';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { taskNameFormatter } from '../../../../common/shared/formatters/taskNameFormatter';
import { taskStatusFormatter } from '../../../../common/shared/formatters/taskStatusFormatter';
import { optionSelectionFormatter } from '../../../../common/shared/formatters/optionSelectionFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Column, FieldType, Formatter, Formatters, GridOption, OnEventArgs, AngularGridInstance, Editors } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project.model';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

import { mergerFormatter } from '../../../../common/shared/formatters/mergerFormatter';
import { blueTextModelFormatter } from '../../../../common/shared/formatters/blueTextModelFormatter';
import { dateFormatter } from 'src/app/common/shared/formatters/dateFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { taskAssignmentFormatter } from 'src/app/common/shared/formatters/taskAssignmentCheckboxFormatter';
import { blueTextFormattter } from 'src/app/common/shared/formatters/blueTextFormatter';
import { AppGlobals } from 'src/app/common/shared/app.globals';

@Component({
  selector: 'zetta-train-mastering-model',
  templateUrl: './train-mastering-model.component.html',
  styleUrls: ['./train-mastering-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainMasteringModelComponent implements OnInit, OnDestroy {

  public trainModelDataSource: object = {};
  public selectOptionDataSource: object = {};
  public selectedRowDataSource: object = {};
  public selectedClusterId: string = '';
  public rowData;
  public grid: AngularGridInstance;
  public mergerGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedCluster;
  public isSelectedClusterExpanded = false;
  public selectedColumnsfeedbackColumns;
  public selectedTaskName: String = '';
  public selectedTaskStatus: string;
  public isClusterItemsLoading = false;
  public isReRunModelEnabled = true;
  selectedProjectId;
  taskType;
  selectedTaskType;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  gridOptions: any;
  modelGridOptions: any;
  projectDetail = new Project();
  userFeedback = new Map<string, object>();

  isLoading = false;
  hasScrolled = false;
  total = 0;
  page = 1;
  limit = 100;
  pageRight = 1;
  noData = false;
  isTaskAssignmentsLoading = true;
  taskAssignmentsRowData: any;
  taskAssignmentsGridOptions: any;
  taskAssignmentsGrid: AngularGridInstance;
  errorMsg: string;
  enableApplyBtn: boolean;
  taskAssignmentsColDef: any;
  selectedLeftGridRowsSize = 0;
  isProjectAdmin: Boolean = false;
  viewTaskAssignments = false;
  summaryHasChanged = false;
  shouldDisableFeedback = false;
  openMergerFeedbackModal: boolean = false;
  modeState: any = {};

  constructor(
    public projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
    
    this.checkUserPermission();
    this.getProjectUsers();   
    this.trainModelTableColDef = [
      {
        'displayname': 'Task Name',
        'physicalname': 'zs_cluster_identifier',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
        'formatter': blueTextModelFormatter,
      },
      {
        'displayname': 'Records',
        'physicalname': 'zs_total_records_cluster',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'headerCssClass': 'text-right',
        'formatter': numericFormatter,
      },
      {
        'displayname': 'Status',
        'physicalname': 'status',
        'sortable': false,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 140,
        'headerCssClass': 'pl-4 ml-3',
        'formatter': assignmentStatusFormatter,
      },
      {
        'displayname': 'Open',
        'physicalname': '',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-center',
        'formatter': optionSelectionFormatter,
      },
      {
        'displayname': 'Last Updated',
        'physicalname': 'last_updated',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'headerCssClass': 'pl-4',
        formatter: dateFormatter
      }
    ];
    this.modelGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };
    this.taskAssignmentsGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };

    this.getRunsupervisedData();
  }
  getTaskAssignmentsColDef() {
    this.taskAssignmentsColDef = [
      {
        'displayname': 'User Name',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100,
        'formatter': blueTextFormattter,
      },
      {
        'displayname': 'Reviewer',
        'physicalname': 'isReviewer',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': taskAssignmentFormatter,
      },
      {
        'displayname': 'Approver',
        'physicalname': 'isApprover',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': taskAssignmentFormatter,
      }
    ];
  }
  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getTrainModelList(pageno, limit = 100) {
    // this.isLoading = true;    
    this.projectService.getTrainEntitiesMasterByProjectId(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limit).subscribe((data) => {
          this.trainModelDataSource = data;
          if (this.trainModelDataSource['currentpage'].length === 0) {
            this.noData = true;
          }
          this.isLoading = false;
          this.trainModelDataSource['currentpage'].forEach(element => {
            element.shouldDisable = element.status === AppGlobals.TASK_COMPLETED ? true : false;
          });
          this.total = data['totalrecords'];
          let concatedRows = this.trainModelDataSource['currentpage'];
          if (this.grid) {
            concatedRows = [...this.grid.dataView.getItems(), ...this.trainModelDataSource['currentpage']];
            this.trainModelDataSource['currentpage'].forEach(element => {
              element.id = Math.random();
            });
            this.trainModelDataSource['currentpage'] = concatedRows;
            this.grid.dataView.setItems(concatedRows);
            this.grid.gridService.setSelectedRows([]);
            this.hasScrolled = false;
          }
          this.getRunsupervisedData()
        }, err => {
          this.isLoading = false;
          this.trainModelDataSource['currentpage'] = [];
        });
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    const gridDataService = this.grid.gridService;   
    grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      if (parent.taskAssignmentsGrid) {
        parent.taskAssignmentsGrid.dataView.setItems([]);
      }
      const selectedRows = args.rows;
      if (selectedRows.length) {
        parent.selectedLeftGridRowsSize = args.rows.length;
        const selectTasks = [];
        parent.taskAssignmentsRowData.forEach(user => {
          user.role_id = null;
        });
        selectedRows.forEach(index => {
          const row = gridDataService.getDataItemByRowNumber(index);
          if (row !== undefined && row !== null) {
            selectTasks.push(row);
            parent.taskAssignmentsRowData.forEach(user => {
              const selectedUser = user.roles.find(role => role === row.role_id);
              if (selectedUser) {
                user.role_id = row.role_id;
              }
            });
          }
        });
      } else {
        parent.viewTaskAssignments = false;
        parent.selectedLeftGridRowsSize = 0;
        parent.taskAssignmentsRowData.forEach(user => {
          user.role_id = null;
        });
      }
      parent.isHavingAccess();
      if (parent.taskAssignmentsGrid) {
        parent.taskAssignmentsGrid.dataView.setItems(parent.taskAssignmentsRowData);
      }
    });
  }

  isHavingAccess() {
    if (this.selectedLeftGridRowsSize > 0 && this.isProjectAdmin) {
      return true;
    } else {
      return false;
    }
  }
  onModelMergerCreation(grid) {
    this.mergerGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getTrainModelList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getTrainModelList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getTrainModelList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    this.selectedClusterId = null;
    this.isClusterItemsLoading = false;
    this.userFeedback.clear();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/projects', row.project_id]);
      } else if ($(eventEle.target).hasClass('fa-graduation-cap') || $(eventEle.target).hasClass('blueLink')) {
        this.selectedTaskName = row.zs_cluster_identifier;
        this.selectedClusterId = row.zs_cluster_id;
        this.selectedRowDataSource = row;
        // this.selectOptionModelList(row.zs_cluster_id);
        this.selectedProjectId = this.projectDetail.project_id;
        this.taskType = 'Merge Training';
        this.selectedTaskType = 'Merge Training';
        this.selectedTaskStatus = row.status;
        this.summaryHasChanged = false;
        this.shouldDisableFeedback = false;
        this.openMergerFeedbackModal = true;
        let userDetails = this.taskAssignmentsRowData.find(user => user.user_id === this.loggedInUserDetails.user_id);        
        const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER);
        if (row.status === AppGlobals.TASK_COMPLETED) {
          this.shouldDisableFeedback = true;
        }
        if (isReviewdUser && row.status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.shouldDisableFeedback = true;
        }
      }
    }
  }

  minimizeRows(data) {
    const dataArray = data.currentpage;
    const columnsArray = [];
    // looging all physicalname from columns 
    for (let colIndex = 0; colIndex < data.columns.length; colIndex++) {
      if (data.columns[colIndex]['physical_name'] && data.columns[colIndex]['is_visible_input']) {
        columnsArray.push(data.columns[colIndex]['physical_name']);
      }
    }
    // travering through each object
    for (let rowIndex = 0; rowIndex < dataArray.length; rowIndex++) {
      const eachRowItem = dataArray[rowIndex];
      // travering through each column in object
      for (let property in eachRowItem) {
        const eachColumnItem = eachRowItem[property];
        let columnFound = columnsArray.find(function (element) { return element === property; });
        // console.log('Each column value :: ', eachColumnItem);
        // if no data in column value, check data in future rows
        if (!eachColumnItem && columnFound) {
          // travering through each column in future row objects until a data is found
          for (let nextRowIndex = rowIndex + 1; nextRowIndex < dataArray.length; nextRowIndex++) {
            const futureRowItem = dataArray[nextRowIndex];
            const futureColumnItem = futureRowItem[property];
            if (futureColumnItem) {
              // console.log('Future Item is ', futureColumnItem);
              // change current column value with future existed value
              eachRowItem[property] = futureColumnItem;
              eachRowItem[property + '_feedback'] = futureRowItem[property + '_feedback'];
              eachRowItem['shiftedCells'] = eachRowItem['shiftedCells'] ? eachRowItem['shiftedCells'] : [];
              let obj = {};
              obj['zs_recid'] = futureRowItem['zs_recid'];
              obj[property] = futureColumnItem;
              obj[property + '_feedback'] = futureRowItem[property + '_feedback'];
              eachRowItem['shiftedCells'].push(obj);

              // since moving the value to previous row, we set the future column value to null
              futureRowItem[property] = null;
              break;
            }
          }
        }
      }
    }
    // remove rows with null values in all columns
    const newArray = [];
    for (let rowIndex = 0; rowIndex < dataArray.length; rowIndex++) {
      const eachRowItem = dataArray[rowIndex];
      let isValidRow = false;
      let anyNonNullColumn = false;
      for (let property in eachRowItem) {
        anyNonNullColumn = false;
        let columnFound = columnsArray.find(function (element) { return element === property; });
        if (columnFound) {
          const eachColumnItem = (eachRowItem[property] === undefined || eachRowItem[property] == null) ? true : false;
          if (!eachColumnItem) {
            anyNonNullColumn = true;
            break;
          }
        }
      }
      if (anyNonNullColumn) {
        isValidRow = true;
      }
      if (isValidRow) {
        newArray.push(eachRowItem);
      }
    }
    return newArray;
  }

  selectOptionModelList(clusterId, limit = 1000) {
    const parent = this;
    parent.isLoading = true;
    parent.projectService.getEntitiesMasteredFeedback(parent.loggedInUserDetails.user_id, clusterId, parent.projectDetail.project_id, 1, limit).subscribe((data) => {
          parent.rowData = parent.minimizeRows(data);
          data['currentpage'] = parent.rowData;
          parent.selectOptionDataSource = data;
          parent.readDefaultFeedback(data['currentpage'], parent.selectOptionDataSource['columns']);
          parent.selectOptionDataSource['columns'].forEach((element: any) => {
            element.displayname = element.display_name;
            if (element.displayname === 'Full Name') {
              element.minWidth = 300;
            }
            element.physicalname = element.physical_name;
            element.datatype = element.data_type;
            element['formatter'] = mergerFormatter;
            element['params'] = parent.userFeedback;
            element['minWidth'] = 160;
          });

          const emptyObj = {};
          Object.keys(parent.rowData[0]).forEach(function (key) {
            emptyObj[key] = 'Add Other';
          });
          parent.rowData.push(emptyObj);

          this.selectedColumnsfeedbackColumns = this.selectOptionDataSource['columns'];

          // this.readDefaultFeedback(data, this.selectedColumnsfeedbackColumns);

          if (this.mergerGrid) {
            this.mergerGrid.dataView.setItems(this.rowData);
            this.mergerGrid.gridService.setSelectedRows([]);
          }
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
        });
  }

  onSelectedClusterCellClick(e): void {
    this.isClusterItemsLoading = false;
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.mergerGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.mergerGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.mergerGrid.dataView.getItems();

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('radio-formatter')) {
        if ($(eventEle.target).hasClass('merger-formatter')) {
          row['shiftedCells'] = (row['shiftedCells'] && Array.isArray(row['shiftedCells'])) ? row['shiftedCells'] : [];
          const shiftedCCell = row['shiftedCells'].find(function (element) { return element[fieldName] === row[fieldName]; });
          const selectedRowObj = {};
          selectedRowObj['zs_recid'] = shiftedCCell ? shiftedCCell['zs_recid'] : row['zs_recid'];
          selectedRowObj[fieldName] = row[fieldName];
          this.userFeedback.set(fieldName, selectedRowObj);
        }

        dataSet.forEach((rowObj) => {
          this.mergerGrid.gridService.updateDataGridItem(rowObj, false);
        });
      }
    }
  }

  isApplyDisabled() {
    return false;
    // TODO:Confirm full validation like all columns are required with feedback
    // const keys = Array.from( this.userFeedback.keys());
    // if (this.selectedColumnsfeedbackColumns && keys.length > 0 && (keys.length === this.selectedColumnsfeedbackColumns.length)) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

  applySections() {
    const requestPayload = {};
    this.userFeedback.forEach((value: object, key: string) => {
      requestPayload[key] = value[key];
    });

    this.projectService
      .updateMergerFeedback(this.loggedInUserDetails.user_id, this.projectDetail.project_id,
        this.selectedClusterId, requestPayload).subscribe(resp => {
          this.isClusterItemsLoading = true;
          this.selectedRowDataSource['status'] = resp['status'];
          $('#tm-modal').modal('hide');
          //  this.grid.gridService.updateDataGridItem(this.selectedRowDataSource, true, true);
          this.refresh();
        }, err => {
        });
  }

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
}

  onReRunModel(data) {
    // Scenario id is 2 for this case
    this.projectService.reRunModel({ projectId: this.projectDetail.project_id, scenarioId: 2 }, 'stage4').subscribe(resp => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Dataset', 
      messageHeader:AppGlobals.RESOLVE_PROJECT_RUN_MODEL_HEADER_TEXT, messageText:AppGlobals.RESOLVE_PROJECT_RUN_MODEL_TEXT, path: `/zs/projects/${this.projectDetail.project_id}` });
      // this._router.navigate(['/zs/projects', this.projectDetail.project_id]); /zs/projects/${this.projectDetail.project_id}` 
    },   err => {
      let errorMessage = 'Something went wrong';
      if (err && err[0] && err[0].message) {
        errorMessage = err[0].message;
      }
      this.messageSvc.sendMessage({ message: errorMessage, type: 'INFO', hideboard: true });
    }
  );
  }

  // Read Get API Response for Feedback
  readDefaultFeedback(rows, columns) {
    rows.forEach(row => {
      row.id = Math.random();
      // this.selectedCluster = row.zs_cluster_identifier;
      columns.forEach(column => {
        const fieldName = column.physicalname;
        if (
          row[fieldName] !== null &&
          row[fieldName] !== undefined &&
          (row[fieldName + '_feedback'] === '1' || row[fieldName + '_feedback'] === 1)
        ) {
          this.userFeedback.set(fieldName, row);
        }
      });
    });
  }

  refresh() {
    this.trainModelDataSource = [];
    if (this.grid) {
      this.grid.dataView.setItems(this.trainModelDataSource);
    }
    this.openMergerFeedbackModal= false;
    this.summaryHasChanged = true;
    this.getTrainModelList(1, this.limit);
  }

  onNextRight(): void {
    this.pageRight++;
    this.getTrainModelList(this.pageRight, this.limit);
  }

  onLeftScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNextRight();
    }
  }

  getRunsupervisedData() {
    this.isLoading = true;
    this.projectService.getRunsuperviseByProjectId({ userId: this.loggedInUserDetails.user_id, selectedProjectId: this.projectDetail.project_id }, 'entitiesmastered').subscribe((data) => {
        this.isLoading = false;
        this.isReRunModelEnabled = data['run_supervised'];
      }, err => {
        this.isLoading = false;
      });
  }
  addUserRole() {
    const selectedUserRoles = [];
    let taskAssignmentUser = this.taskAssignmentsGrid.dataView.getItems();
    taskAssignmentUser = taskAssignmentUser.filter(item => item.isApproverChecked === true || item.isReviewerChecked === true);
    const leftGridDataService = this.grid.gridService;
    const leftSideGridDataSet = this.grid.slickGrid.getSelectedRows();
    if (taskAssignmentUser.length) {
      leftSideGridDataSet.forEach(index => {
        const row = leftGridDataService.getDataItemByRowNumber(index);
        if (row !== undefined && row !== null) {
          taskAssignmentUser.forEach(user => {
            if (user.role_id === row.role_id) {
              selectedUserRoles.push(
                {
                  user_id: user.user_id,
                  role_id: user.role_id,
                  cluster_id: row.zs_cluster_id
                });
            }
          });
        }
      });
      this.projectService.saveTasksToUsers(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, selectedUserRoles).subscribe(resp => {
        this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
        if (this.grid) {
          this.grid.dataView.setItems([]);
          this.isLoading = true;
          this.getTrainModelList(1, this.limit);
        }
      }, err => {
        this.messageSvc.sendMessage({ message: AppGlobals.TASK_ASSIGNMENT_FAILD_MSG, type: 'ERROR', hideboard: true });
      });
    }
  }
  onTaskAssignments() {
    this.viewTaskAssignments = true;
    if (this.viewTaskAssignments) {
      this.getTaskAssignmentsColDef();
    }
  }
  getProjectUsers() {
    this.projectService.getprojectUsers(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe(userList => {
      this.taskAssignmentsRowData = userList;
      this.taskAssignmentsRowData.forEach(user => {
        user.id = Math.random();
        user.isAdmin = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN) ? true : false;
        user.isReviewer = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER) ? true : false;;
        user.isApprover = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_APPROVER) ? true : false;;
        user.isReviewerChecked = false;
        user.isApproverChecked = false;
        user.isCheckedAdmin = false;
        user.isChecked = false;
      });
      this.isTaskAssignmentsLoading = false;
    }, error => {
      this.taskAssignmentsRowData = [];
      this.isTaskAssignmentsLoading = false;
    });
  }
  onTaskAssignmentsGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskAssignmentsGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.taskAssignmentsGrid.slickGrid.getColumns()[args.cell].field;

    if (row !== undefined && row !== null) {
      if (fieldName === 'isReviewer' && eventEle.target.className.includes('matched-task')) {
        if (!row.isChecked) {
          $(`#user-${args.row}-${fieldName}`).addClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).removeClass('d-none');
        } else {
          $(`#user-${args.row}-${fieldName}`).removeClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).addClass('d-none');
        }
        row.isChecked = !row.isChecked;
        row.isReviewerChecked = row.isChecked;
        row.role_id = AppGlobals.RESOLVE_PROJECT_REVIEWER;
        this.validateUser();
      }
      if (fieldName === 'isApprover' && eventEle.target.className.includes('matched-task')) {
        if (!row.isChecked) {
          $(`#user-${args.row}-${fieldName}`).addClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).removeClass('d-none');
        } else {
          $(`#user-${args.row}-${fieldName}`).removeClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).addClass('d-none');
        }
        row.isChecked = !row.isChecked;
        row.isApproverChecked = row.isChecked;
        row.role_id = AppGlobals.RESOLVE_PROJECT_APPROVER;
        this.validateUser();
      }
      if (fieldName === 'isAdmin' && eventEle.target.className.includes('matched-task')) {
        if (!row.isChecked) {
          $(`#user-${args.row}-${fieldName}`).addClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).removeClass('d-none');
        } else {
          $(`#user-${args.row}-${fieldName}`).removeClass('d-none');
          $(`#user-${args.row}-${fieldName}-checked`).addClass('d-none');
        }
        row.isChecked = !row.isChecked;
        row.isCheckedAdmin = row.isChecked;
        row.role_id = AppGlobals.RESOLVE_PROJECT_ADMIN;
        this.validateUser();
      }
    }
  }
  validateUser() {
    let taskAssignmentUser = this.taskAssignmentsGrid.dataView.getItems();
    if (taskAssignmentUser.length) {
      const reviewerUser = taskAssignmentUser.filter(user => user.isReviewerChecked === true);
      const approverUser = taskAssignmentUser.filter(user => user.isApproverChecked === true);
      if (reviewerUser.length || approverUser.length) {
        this.enableApplyBtn = true;
        this.errorMsg = '';
        if (reviewerUser.length > 1 || approverUser.length > 1) {
          this.errorMsg = reviewerUser.length > 1 ? AppGlobals.REVIEWER_RVALIDATION_MSG : approverUser.length > 1 ? AppGlobals.APPROVER_RVALIDATION_MSG : '';
          this.enableApplyBtn = false;
        } else {
          this.errorMsg = '';
        }
      } else {
        this.enableApplyBtn = false;
      }
    }
  }
  checkUserPermission() {
    this.projectService.getProject(this.loggedInUserDetails.user_id,
      this.projectDetail.project_id).subscribe(responseList => {
        let allUsers = responseList.users;
        allUsers.forEach(user => {
          if (user.user_id === this.loggedInUserDetails.user_id) {
            const adminUser = user.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN);
            if (adminUser) {
              this.isProjectAdmin = adminUser ? true : false;
            }
          }
        });
        this.gridOptions = {
          enableGridMenu: false,
          selectable: true,
          enableFiltering: true,
          CheckboxSelector: this.isProjectAdmin,
          enableCellNavigation: true,
          enableRowSelection: true,
          rowHeight: 34,
        };
        this.getTrainModelList(this.page, this.limit);
      }, err => {
      });
      
  }
  onTaskAssignmentsGridCreation(grid) {
    this.taskAssignmentsGrid = grid;
  }
  onReloadScreen(event) {
    if (event) {
      this.refresh();
    }
  }
  onMergerFeedbackModalClosed(event) {
    this.openMergerFeedbackModal = false;
  }
}
