import { Component, OnInit } from '@angular/core';
import { DatasourceService } from '../../datasource.service';
import { ActivatedRoute } from '@angular/router';
import { Datasource } from '../../datasource.model';
import { Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../../common/components/message/message.service';

@Component({
  selector: 'zetta-connection-detail',
  templateUrl: './connection-detail.component.html',
  styleUrls: ['./connection-detail.component.scss']
})
export class ConnectionDetailComponent implements OnInit {

  datasource = new Datasource();
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  connectionParam;
  canedit = false;
  dataSourceUsers: any = [];
  type="";
  image="";
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	is_visible_edit=false;	
  storage_provider:string;
  datasource_sub_type_id: number;
	
  constructor(private _datasourceSvc: DatasourceService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public zettaUtils: ZettaUtils,
    private messageSvc: MessageService) {
      if (this.login_user) {
        try {
          let user_entitlements=this.login_user[0].entitlement;
          if(this.login_user.length>1){
            user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
            if(user_entitlements.length){
              user_entitlements=user_entitlements[0].entitlement;
            }else{
              user_entitlements=this.login_user[0].entitlement;
            }
          }
          const data_source =user_entitlements.screens.filter(item => item.name == 'datasource_detail')[0].childs.filter(item => item.name == 'modify_connection')[0];
          if (data_source) {
            this.is_visible_edit = data_source.visible;
            this.canedit = data_source.enable;
          }
        } catch (error) {
          console.log(error);
        }  
      }
     }

  ngOnInit() {
    this._activatedRoute.parent.params.subscribe(params => {
      this.datasource.datasource_id = params['id'];
    });
    this.getConnectionParam();
    this.getUsersToDatasource();
  }

  getConnectionParam() {
    this._datasourceSvc.getDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(responseList => {      
      this.connectionParam = responseList;
      this.type = responseList.datasource_type;
      this.datasource_sub_type_id = responseList.datasource_sub_type_id;
      if(this.type=="Hadoop"){
        this.image='icon-Hadoop.png';
      }else if(this.type=="Cloud Object Storage"){
        this.image='icon-Cloud.png';
        if(responseList.datasource_sub_type_id === 327){
          this.connectionParam.connection_param.storage_account = responseList.connection_param.account;
          this.connectionParam.connection_param.bucket_name = responseList.connection_param.container;
        }
        this.getStorageLayers(responseList.connection_param.storage_provider);
      }else if(this.type=="Relational Database"){
        this.image='icon-Database.png';
      } else if(this.type=='Snowflake Data Warehouse' || this.connectionParam.datasource_type_id == 14950) {
        this.image = 'icon-snowflake.png';
        this.connectionParam.connection_param.password = this.connectionParam.connection_param.password.replace(/[^*]/g,"*");
      }
    }, err => {  });
  }

  getUsersToDatasource() {
    this._datasourceSvc.getUsersToDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(responseList => {
      this.dataSourceUsers = responseList['currentpage'];
      console.log(this.dataSourceUsers)
      this.dataSourceUsers.forEach(element => {
        if(this.loggedInUserDetails['user_id'] === element.user_id && element.is_admin) {
          this.canedit = true;
        }
      });
     
    }, error => {
      this.messageSvc.sendMessage({ message: 'Get users has failed', type: 'INFO', hideboard: true });
    });
  }

  modifyConnectionClick() {
    this._router.navigate(['/zs/datasources', this.datasource.datasource_id, 'modify-connection'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: true, sub_type_id:this.datasource_sub_type_id } });
  }
  getStorageLayers(storage_provider) {
    this._datasourceSvc.getDataCloudStorageLayer('DatasourceSubTypes_HDFS').subscribe(resp => {
      if (resp.length) {
        this.storage_provider = resp.find(ds => ds.lookup_id == storage_provider || ds.name == storage_provider).name;
      }
    }, error => {
    });
  }
}
