import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

export const datasourceTypeFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
	function getIconByTypeID() {
		return zettaUtilsInstance.getDatasourceTypeById(dataContext.datasource_type_id);
	}
	return `<img src="${getIconByTypeID()}" class="type-img pr-1"> <span title='${dataContext.datasource_type}'>${dataContext.datasource_type}</span>`
};
