import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { Project } from '../../content/project/project.model';
import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../../content/project/project.service';
import { AngularGridInstance } from 'angular-slickgrid';
import { confidenceFormatter } from '../../../common/shared/formatters/confidenceFormatter';
import { ActivatedRoute } from '@angular/router';
import { numericFormatter } from '../../../common/shared/formatters/numericFormatter';
import { toolTipFormatter } from '../../../common/shared/formatters/toolTipFormatter';
import { environment } from 'src/environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { AppGlobals } from '../../../common/shared/app.globals';
declare const $: any;
@Component({
  selector: 'zetta-view-resolution-results',
  templateUrl: './view-resolution-results.component.html',
  styleUrls: ['./view-resolution-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ViewResolutionResultsComponent implements OnInit, OnDestroy {

  public entityResolveDataSource: object = {};
  public viewClusterDetails: object = {};
  public selectedRowData: object = {};
  public leftgrid: AngularGridInstance;
  public rightgrid: AngularGridInstance;
  public loggedInUserDetails;
  public suggestedFeedback: object = {};
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public leftTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public rightTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };

  viewResultsTableColDef: any;
  viewResultsDetailsTableColDef: any;
  leftGridOptions: any;
  rightGridOptions: any;
  projectDetail = new Project();
  isLoading = true;
  showEntitiesSpinner: boolean = false;
  hasScrolled = false;
  hasRightGridScrolled = false;
  isLoadingRight = false;
  selectedClusterRows = [];
  selectedClusterColumns = [];
  suggestedClusters = {};
  suggestedClusterkeys = [];
  isSuggested = true;
  isSelectedClusterExpanded = false;
  selectedClusterTotal = 0;
  isSeletedClusterLoading = false;
  selectedClusterId = undefined;
  selectedClusterName = undefined;
  isSuggestedClusterLoading = false;
  suggestedColumns = [];
  similarClusterGridOptions: any;
  page = 1;
  limit = 1000
  total = 0;
  totalLeft = 0;
  pageLeft = 1;
  limitLeft = 100;
  totalRight = 0;
  pageRight = 1;
  limitRight = 100;
  grandTotal = 0;
  subTotal;
  isRightPaginationRequired = false;
  initRightGrid = true;
  noData = false;
  modeState: any = {};
  confidenceLevel = [{level:'Low', isChecked:false}, {level:'Medium', isChecked:false}, {level:'High', isChecked:false}]
  selectedConfidenceLevel;
  selectedDatasets;
  selectedFullKeywordTextSearch;
  isClearSearch = false;
  datasets: any = [];
  filteredDatasource = [];
  entityResolveDataSourceSearch: object = {};
  isAdvanceSearch = false;
  serverFilter = '';
  zs_cluster_identifier;
  zs_accuracy_level;
  zs_source_systems_cluster;
  totalPages = 0;
  entityId:string;
  filteredData: boolean = false;
  onFilterApplied: boolean = false;
  totalEntities;
  filteredDataCount;
  constructor(private projectService: ProjectService, 
              private activatedRoute: ActivatedRoute, 
              public zettaUtils: ZettaUtils,
              private messageSvc: MessageService) {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
  }

  ngOnInit() {
    this.selectedConfidenceLevel = [];
    this.selectedDatasets = [];
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });
    this.activatedRoute.queryParams.subscribe(querys => {
      this.entityId = querys.entityId;
    });
    this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
     sessionStorage.removeItem("serverfilter");
     sessionStorage.removeItem("sortfilter"); 
    if ($('#filter-zs_cluster_identifier').val() === '' ||
      $('#filter-zs_cluster_identifier').val() === undefined) {
      this.zs_cluster_identifier = '';
      sessionStorage.removeItem('zs_cluster_identifier');
    }
    if ($('#filter-zs_accuracy_level').val() === '' ||
      $('#filter-zs_accuracy_level').val() === undefined) {
      this.zs_accuracy_level = '';
      sessionStorage.removeItem('zs_accuracy_level');
    }  
    this.isSelectedClusterExpanded = false;
    this.getViewResultsByLoggedInUser(this.pageLeft, true, false);////////
    this.getListDatasetsForProject();
    this.validateAdvanceSearch();
    if (!this.isClearSearch) {
      this.zs_cluster_identifier = '';
      sessionStorage.removeItem('zs_cluster_identifier');
      this.zs_accuracy_level = '';
      sessionStorage.removeItem('zs_accuracy_level');
    }
    this.viewResultsTableColDef = [
    {
      'displayname': 'Name',
      'physicalname': 'zs_cluster_identifier',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': toolTipFormatter,
      'minWidth': 155,
    }, {
      'displayname': 'Records',
      'physicalname': 'count',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': numericFormatter,
      'cssClass': 'text-right',
      'headerCssClass': 'text-left',
      'minWidth': 70,
    }, {
      'displayname': 'Confidence Level',
      'physicalname': 'zs_accuracy_level',
      'sortable': true,
      'formatter': confidenceFormatter,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }];

    this.leftGridOptions = {
      enableFiltering: true,
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      showNoData: false,
      rowHeight: 34,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };

    this.similarClusterGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: false,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };
  }

  ngOnDestroy(): void {
    this.onClearSearch();
  }
  
  getViewResultsByLoggedInUser(pageno, totalRecords, showLoader = true, limit = 100) {
    this.showEntitiesSpinner = showLoader;
    let serverFilter= sessionStorage.getItem("serverFilter") ? sessionStorage.getItem("serverFilter") : '';
    this.zs_cluster_identifier = sessionStorage.getItem("zs_cluster_identifier");
    this.zs_accuracy_level = sessionStorage.getItem("zs_accuracy_level");
    this.zs_source_systems_cluster = sessionStorage.getItem("zs_source_systems_cluster");
    serverFilter = (this.zs_cluster_identifier ? '&zs_cluster_identifier=' + this.zs_cluster_identifier : '') + 
                   (this.zs_accuracy_level ? '&accuracy_level=' + this.zs_accuracy_level : '') + 
                   (this.zs_source_systems_cluster ? '&zs_source_systems_cluster=' + this.zs_source_systems_cluster : '');
    let sourceSystemClusterFilter = this.zs_source_systems_cluster ? '&zs_source_systems_cluster=' + this.zs_source_systems_cluster : '';               
    let accuracyLevelFilter = this.zs_accuracy_level ? '&accuracy_level=' + this.zs_accuracy_level : '';
    let clusterIdentifierFilter = this.zs_cluster_identifier ? '&zs_cluster_identifier=' + this.zs_cluster_identifier : '';
    this.serverFilter = serverFilter;
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    } 
    this.leftTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved?pageno=${1}&pagesize=${limit}${sourceSystemClusterFilter}${accuracyLevelFilter}${clusterIdentifierFilter}`;
    let selectedConfidenceLevel = [];
    this.selectedConfidenceLevel.forEach(ele => { selectedConfidenceLevel.push(ele.level.toUpperCase()) });
    let textSelectedConfidenceLevel = selectedConfidenceLevel.toString();
    let selectedDatasets = [];
    this.selectedDatasets.forEach(ele => { selectedDatasets.push(ele.name) });
    
    this.projectService.getAdvanceSearch(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limit, serverFilter, sortfilter).subscribe(resp => {
      if(resp) {
        this.entityResolveDataSource = resp;
        this.showEntitiesSpinner = false;
        if (this.entityResolveDataSource['currentpage'].length === 0) {
          this.noData = true;
        }
        this.isLoading = false;
        this.totalLeft = resp['totalrecords'];
        this.totalPages = resp['totalpages'];
        if(totalRecords || !serverFilter) {
          this.grandTotal = Number(resp['totalrecords']);
        } else {
          this.subTotal = Number(resp['totalrecords']);
        }
        let concatedRows = this.entityResolveDataSource['currentpage'];
        if (this.leftgrid) {
          concatedRows = [...this.leftgrid.dataView.getItems(), ...this.entityResolveDataSource['currentpage']];
          this.entityResolveDataSource['currentpage'].forEach((element,i) => {
            element.id = Math.random();
            element.accuracy_level = element.zs_accuracy_level;
          });
          this.entityResolveDataSource['currentpage'] = concatedRows;
          this.leftgrid.dataView.setItems(concatedRows);
          this.leftgrid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
      }
    }, err => { 
      this.isLoading = false;
      this.showEntitiesSpinner = false;
      this.entityResolveDataSource['currentpage'] = [];
      this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
    });
  }

  // on click of project grid row
  onRowSelection(args) {
    // Dont Accept new request until previous one is completed
    if (this.isLoadingRight) {
      return;
    }
    this.viewClusterDetails = {};
    this.initRightGrid = false;
    this.leftgrid.gridService.setSelectedRow(args.args.row);
    this.selectedRowData = this.leftgrid.gridService.getDataItemByRowIndex(args.args.row);
    this.pageRight = 1;
    // refresh Right Side Pagination component
    this.isRightPaginationRequired = false;
    this.totalRight = 0;
    this.isLoadingRight = true;
    this.viewClusterDetails['currentpage'] = undefined;
    this.getEntityDetailsByCusterId(this.pageRight);
    if (this.rightgrid) {
      this.rightgrid.dataView.setItems([]);
    }

  }

  getEntityDetailsByCusterId(pageno, limit = 100) {
    this.rightTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved/${this.selectedRowData['zs_cluster_id']}?pageno=${1}&pagesize=${limit}`;
    this.projectService.getEntitiesDetailsByClusterId(this.loggedInUserDetails.user_id,
    this.projectDetail.project_id, this.selectedRowData['zs_cluster_id'], pageno, limit).subscribe((data) => {
      if(data) {
        let filteredInd;
        let dataUpdated = data;
        let sourceSystemSInd;
        dataUpdated['columns'].forEach((col, ind) => {
          if (col['logical_name'] == `Source SystemS`) {
            return sourceSystemSInd = ind;
          }
        });
        if(sourceSystemSInd != undefined || sourceSystemSInd != null) {
          dataUpdated['columns'].splice(sourceSystemSInd, 1);
        }
        dataUpdated['columns'].forEach((col, ind) => {
          // if (col['physical_name'] == `zs_source_system_${this.entityId}` || col['physicalname'] == `zs_source_system_${this.entityId}`) {
          //   filteredInd = ind;
          //   return col;
          // }
          if(col['display_name'] == AppGlobals.LAST_RECORED_UPDATED_DATE){
            col['data_type'] = "String";
          }
        });
        // if(filteredInd != undefined || filteredInd != null) {
        //   dataUpdated['columns'].splice(filteredInd, 1);
        // }
        // dataUpdated['columns'].forEach(col => {
        //   console.log('tje column is   ', col);
        //   // col['physical_name'].toLowerCase() !== 'source_system'
        // });
        // dataUpdated['columns'].filter(col => {
        //   col['logical_name'].toLowerCase() !== 'source systems' 
        // });
        // console.log('tje column is   ', dataUpdated['columns']);
        // || col['logical_name'].toLowerCase() !== 'Source SystemS'
        this.viewClusterDetails = dataUpdated;
        this.totalRight = data['totalrecords'];
        this.isLoadingRight = false;
        this.isRightPaginationRequired = true;
        this.viewClusterDetails['columns'].forEach(column => {
          column.minWidth = 200;
          column.sortable = true;
          column.filterable = true;
          column.displayname = column.display_name;
          column.physicalname = column.physical_name;
          column.datatype = column.data_type;

          if (column.display_name === 'Full Name') {
            column.minWidth = 300;
            column.formatter = toolTipFormatter;
          }
        });

        let concatedRightRows = this.viewClusterDetails['currentpage'];
        if (this.rightgrid) {
          concatedRightRows = [...this.rightgrid.dataView.getItems(), ...this.viewClusterDetails['currentpage']];
          this.viewClusterDetails['currentpage'].forEach(element => {
            element.id = Math.random();
            if (element.zs_cluster_identifier) {
              this.zettaUtils.getWidthOfText(element.zs_cluster_identifier);
            }
          });
          this.viewClusterDetails['currentpage'] = concatedRightRows;
          this.rightgrid.dataView.setItems(this.viewClusterDetails['currentpage']);
          this.rightgrid.gridService.setSelectedRows([]);
          this.hasRightGridScrolled = false;
        }
      }
    }, err => {
          this.isLoadingRight = false;
          this.entityResolveDataSource['currentpage'] = [];
          if (err && err.status === 404) {
            this.messageSvc.sendMessage({ message:  (err && err.error && err.error.message) ? err.error.message : 'Oops Something went wrong', type: 'ERROR', hideboard: true });
          } else {
            this.messageSvc.sendMessage({ message:  (err && err.error && err.error.message) ? err.error.message : 'Oops Something went wrong', type: 'ERROR', hideboard: true });
          }
    });
  }

  onLeftGridCreation(leftgrid) {
    this.leftgrid = leftgrid;
  }

  onRightGridCreation(rightgrid) {
    this.rightgrid = rightgrid;
  }

  onNextLeft(): void {
      this.pageLeft++;
      this.getViewResultsByLoggedInUser(this.pageLeft, false, false);
  }

  onNextRight(): void {
    this.pageRight++;
    this.getEntityDetailsByCusterId(this.pageRight);
  }

  currencyFormat(input) {
    return this.zettaUtils.currencyFormat(input);
  }

  closeModal() {
    this.zettaUtils.removeGridAutoScrollTogetherEvent('tm-modal');
    this.clearSimilarClusterObjects();
  }

  toggleSuggestAccordian(event, clusterId) {
    const target = event.target.target;
    if (this.suggestedClusters['openCluster'] !== clusterId) {
      this.suggestedClusters['openCluster'] = clusterId;
    } else {
      this.suggestedClusters['openCluster'] = undefined;
    }
  }
  onSelectedCluster(e) {
    this.isSelectedClusterExpanded = true;
  }

  getSuggestedClusterStyle() {
    if ($('#collapse1').hasClass('show')) {
      return 'suggested-cluster expanded';
    } else {
      return 'suggested-cluster collapsed';
    }
  }

  clearSimilarClusterObjects() {
    this.selectedClusterRows = [];
    this.selectedClusterColumns = [];
    this.suggestedClusters = {};
    this.suggestedClusterkeys = [];
    this.isSuggested = true;
    this.isSelectedClusterExpanded = false;
    this.selectedClusterTotal = 0;
    this.isSeletedClusterLoading = false;
    this.selectedClusterId = undefined;
    this.selectedClusterName = undefined;
    this.isSuggestedClusterLoading = false;
    this.suggestedColumns = [];
  }

  onViewSimilarEntities() {
    this.selectedClusterId = this.selectedRowData['zs_cluster_id'];
    this.getSimilarClusters(this.page);
  }

  getSimilarClusters(page,limit = 1000) {
    this.isSeletedClusterLoading = true;
    this.isSelectedClusterExpanded = false;
    this.selectedClusterTotal = 0;
    this.projectService.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        this.selectedClusterId, this.projectDetail.project_id, page, limit).subscribe((data) => {
          this.selectedClusterRows = data['currentpage'];
          this.selectedClusterColumns = data['columns'];
          this.selectedClusterColumns.forEach((col: any) => {

            col.displayname = col.display_name;
            col.physicalname = col.physical_name;
            col.datatype = col.data_type;

            if (col.display_name === 'Full Name') {
              col.minWidth = 300;
            }
            col.formatter = toolTipFormatter;
          });
          this.selectedClusterRows.forEach((element: any) => {
            element.id = Math.random();
            this.selectedClusterName = element.zs_cluster_identifier;
          });
          this.getSuggestedCluster(this.selectedClusterId, this.selectedClusterName);
          this.selectedClusterTotal = data['totalrecords'];
          this.isSeletedClusterLoading = false;
    }, err => {
          this.isSeletedClusterLoading = false;
    });
  }

  getSuggestedCluster(clusterId, fullName): void {
    this.isSuggested = true;
    this.projectService.getSimilarClusters({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: clusterId, clusterName: fullName
      }).subscribe(resp => {
        this.isSuggestedClusterLoading = true;
        this.suggestedClusterkeys = Object.keys(resp.currentpage);
        this.suggestedClusters = resp.currentpage;
        this.suggestedColumns = resp.columns;
        this.suggestedColumns.forEach(col => {

          col.displayname = col.display_name;
          col.physicalname = col.physical_name;
          col.datatype = col.data_type;

          if (col.display_name === 'Full Name') {
            col.minWidth = 300;
          }
        });
        this.suggestedClusterkeys.forEach(e => {
          this.suggestedClusters[e].rows = [];
          this.suggestedClusters[e].forEach(element => {
            element.id = Math.random();
            this.suggestedClusters[e].rows.push(element);
          });
        });
        this.isSuggested = false;
    }, err => {
        this.isSuggested = false;
    });
  }

  onScroll(parentClass) {
    this.pageLeft = this.entityResolveDataSource['pageno'] ;
    this.totalPages = this.entityResolveDataSource['totalpages'] ;
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && this.pageLeft < this.entityResolveDataSource['totalpages']) {
      this.hasScrolled = true;
      this.onNextLeft();
    } 
  }

  onRightScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasRightGridScrolled && !this.noData) {
      this.hasRightGridScrolled = true;
      this.onNextRight();
    }
  }

  isClusterChecked(currentFeedback, feedbacktoBe) {
    if (currentFeedback === feedbacktoBe) {
      return true;
    } else {
      return false;
    }
  }

  captureSuggestedFeedback(cId, feedback) {
    this.suggestedFeedback[cId] = feedback;
  }
  receiveTotalRecords($event) {
    this.onFilterApplied = sessionStorage.getItem("serverfilter") ? true : false;
    if( (this.onFilterApplied && this.filteredData) && (this.onFilterApplied && this.isClearSearch) ) {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event, true);
    } else if(!this.onFilterApplied && !this.filteredData) {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event, true);
    } else if(!this.onFilterApplied && this.filteredData) {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event, true);
    } else {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event, true);
    }
    }

  receiveTotalRows($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event['totalrecords'], true);
    this.entityResolveDataSource = $event;
    this.pageLeft = this.entityResolveDataSource['pageno'] ;
    this.totalPages = this.entityResolveDataSource['totalpages'] ;
  }

  getListDatasetsForProject(){
    this.projectService.getListDatasetsForProject( this.loggedInUserDetails.user_id, this.projectDetail.project_id ).subscribe(resp => {
      let ds = [];
      resp['currentpage'].forEach(ele => {
        if(!ds.includes(ele.name)){
          ds.push(ele.name);
          this.datasets.push({name: ele.name, isChecked: false})
        }
      });
    }, err => { });
  }

  resetConfidenceLevel(){
    if(this.confidenceLevel.length){
       this.confidenceLevel.forEach(element => {
         element.isChecked = false;
       });
    }
  }

   resetSelectedDatasources(){
    if(this.datasets.length){
      this.datasets.forEach(element => {
        element.isChecked=false;
      });
     }
  }
 
  onFullKeywordTextSearch(){
    sessionStorage.setItem("zs_cluster_identifier", this.zs_cluster_identifier)
    this.validateAdvanceSearch();
  }

  onChangeConfidenceLevel(ischecked, ruleName) {
    this.selectedConfidenceLevel = [];
    if(this.confidenceLevel.length){
      this.selectedConfidenceLevel = this.confidenceLevel.filter(cfl => cfl.isChecked == true);
    }
    let selectedConfidenceLevel = '';
    this.selectedConfidenceLevel.forEach((ele, i) => {
      if(i < this.selectedConfidenceLevel.length - 1) {
        selectedConfidenceLevel += ele.level.toLowerCase() + ',';
      } else {
        selectedConfidenceLevel += ele.level.toLowerCase()
      }
    });
    this.zs_accuracy_level = selectedConfidenceLevel;
    sessionStorage.setItem("zs_accuracy_level", this.zs_accuracy_level);
    this.validateAdvanceSearch();
  }

  onSelectDatasource(){
    this.selectedDatasets = [];
    if(this.datasets.length){
      this.selectedDatasets = this.datasets.filter(cfl => cfl.isChecked == true);
    }
    let selectedDatasets = '';
    this.selectedDatasets.forEach((ele, i) => {
      if(i < this.selectedDatasets.length - 1) {
        selectedDatasets += ele.name + ',';
      } else {
        selectedDatasets += ele.name;
      }
    });
    this.zs_source_systems_cluster = selectedDatasets;
    sessionStorage.setItem("zs_source_systems_cluster", this.zs_source_systems_cluster);
    this.validateAdvanceSearch();
}
 
  validateAdvanceSearch(){
    if(this.selectedConfidenceLevel.length || this.selectedDatasets.length || this.zs_cluster_identifier){
      this.isClearSearch=true;
    }else{
      this.isClearSearch = false;
    }
  }

  onOpenSearch() {
    this.zs_cluster_identifier = sessionStorage.getItem("zs_cluster_identifier") ? sessionStorage.getItem("zs_cluster_identifier") : '';
    this.zs_accuracy_level = sessionStorage.getItem("zs_accuracy_level") ? sessionStorage.getItem("zs_accuracy_level") : '';
    this.zs_source_systems_cluster = sessionStorage.getItem("zs_source_systems_cluster") ? sessionStorage.getItem("zs_source_systems_cluster") : '';
    if($('#filter-zs_cluster_identifier').val() === ''){
      this.zs_cluster_identifier = '';
      sessionStorage.setItem("zs_cluster_identifier", this.zs_cluster_identifier)
    }
    if($('#filter-zs_accuracy_level').val() === ''){
      this.zs_accuracy_level = '';
      sessionStorage.setItem("zs_accuracy_level", this.zs_accuracy_level)
    }
    let zs_accuracy_level = this.zs_accuracy_level.split(',')
    this.confidenceLevel.forEach(ele => {
      ele['isChecked'] = false;
      zs_accuracy_level.forEach(e => {
        if(ele.level.toLowerCase() == e) {
          ele['isChecked'] = true;
        }
      });
    });
    let zs_source_systems_cluster = this.zs_source_systems_cluster.split(',')
    this.datasets.forEach(ele => {
      zs_source_systems_cluster.forEach(e => {
        if(ele.name.toLowerCase() == e) {
          ele['isChecked'] = true;
        }
      });
    });
    this.validateAdvanceSearch();
  }

  onClearSearch(){
    this.zs_cluster_identifier = '';
    this.zs_accuracy_level = '';
    this.zs_source_systems_cluster = '';
    $('#filter-zs_cluster_identifier').val('');
    $('#filter-zs_accuracy_level').val('');
    $('#filter-count').val('');
    sessionStorage.setItem('zs_cluster_identifier', '');
    sessionStorage.setItem('zs_accuracy_level', '');
    sessionStorage.setItem('zs_source_systems_cluster', '');
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.selectedConfidenceLevel=[];
    this.selectedDatasets=[];
    this.resetConfidenceLevel();
    this.resetSelectedDatasources();
    this.validateAdvanceSearch();
    this.isAdvanceSearch = false;
    this.pageLeft = 1;
    this.leftgrid.dataView.setItems([])
    this.leftgrid.gridService.setSelectedRows([]);
    this.hasRightGridScrolled = false;
    this.isLoadingRight = false;
    this.viewClusterDetails = {};
    this.initRightGrid = true;
    this.filteredData = false;
    this.subTotal = 0;
    this.entityResolveDataSource['currentpage'] = null;
    this.getViewResultsByLoggedInUser(this.pageLeft, false);
  }

  onSearch(){
    sessionStorage.setItem("zs_cluster_identifier", this.zs_cluster_identifier)
    sessionStorage.setItem("zs_accuracy_level", this.zs_accuracy_level)
    sessionStorage.setItem("zs_source_systems_cluster", this.zs_source_systems_cluster)
    $('#filter-zs_cluster_identifier').val(this.zs_cluster_identifier);
    $('#filter-zs_accuracy_level').val(this.zs_accuracy_level);
    this.serverFilter = (this.zs_cluster_identifier ? '&zs_cluster_identifier=' + this.zs_cluster_identifier : '') + 
                        (this.zs_accuracy_level ? '&accuracy_level=' + this.zs_accuracy_level : '') + 
                        (this.zs_source_systems_cluster ? '&zs_source_systems_cluster=' + this.zs_source_systems_cluster : '');
    sessionStorage.setItem('serverfilter', this.serverFilter);
    this.isAdvanceSearch = true;
    this.pageLeft = 1;
    this.leftgrid.dataView.setItems([])
    this.leftgrid.gridService.setSelectedRows([]);
    this.getViewResultsByLoggedInUser(this.pageLeft, false, true);
    this.hasRightGridScrolled = false;
    this.isLoadingRight = false;
    this.viewClusterDetails = {};
    this.initRightGrid = true;
    $("#advancedSearchModal").modal("hide");
    if((this.selectedDatasets && this.selectedDatasets.length) || (this.selectedConfidenceLevel && this.selectedConfidenceLevel.length)) {
      this.filteredData = true;
    } else {
      this.filteredData = false;
        this.subTotal = 0;
    }
  }
}

