import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-confidence-level-renderer',
  template: `
    <ng-container *ngIf="!dataContext.action || dataContext.action !== 'add'">
      <ng-container *ngIf="dataContext.measured_data_quality; else noMeasuredDataQuality">
        <div [ngClass]="dataContext.isRuleEnabled ? 'ml-1' : ''"></div>
        <div [ngClass]="dataContext.isRuleEnabled ? 'ml-2 box-cat-obj ' + cssLevel + ' ml-10' : 'disable-rule ml-10'">
          <span *ngIf="dataContext.isRuleEnabled">{{ scorePercent }}%</span>
        </div>
      </ng-container>
      <ng-template #noMeasuredDataQuality>
        <div class="box-cat-obj {{ cssLevel }} ml-10 text-center">{{ confidenceLevel }}</div>
        <div class="porcent text-center mr-2 ml-10" [title]="scorePercent + '%'">{{ scorePercent }}%</div>

        <div class="handUp text-bold pointer">
          <i [id]="'thumbs-up-' + row" class="{{ condLike }} fa-thumbs-up"></i>
        </div>
        <span class="blueLink countUp">{{ upCount }}</span>
        <div class="handDown text-bold pointer">
          <i [id]="'thumbs-down-' + row" class="{{ condUnlike }} fa-thumbs-down"></i>
        </div>
        <span class="blueLink countDown ml-1">{{ downCount }}</span>
      </ng-template>
    </ng-container>
  `,
  styles: [`
    
  `],
})
export class ConfidenceLevelNoEditCellRendererComponent implements ICellRendererAngularComp {
  cssLevel = '';
  confidenceLevel = '';
  scorePercent: number;
  condLike: string = 'fas';
  condUnlike: string = 'fas';
  upCount: string | number
  downCount: string | number 
  // Data context provided by Ag-Grid
  dataContext: any;

  // Implement ICellRendererAngularComp interface
  agInit(params: ICellRendererParams): void {
    this.dataContext = params.data;
    this.calculateConfidenceLevel();
  }

  // Implement ICellRendererAngularComp interface
  refresh(params: ICellRendererParams): boolean {
    this.dataContext = params.data;
    this.calculateConfidenceLevel();
    return true;
  }

  private calculateConfidenceLevel(): void {
    this.scorePercent = Math.round(this.dataContext.score * 100);

    if (this.scorePercent >= 75) {
      this.confidenceLevel = 'HIGH';
      this.cssLevel = 'priorityCell high';
    } else if (this.scorePercent >= 50 && this.scorePercent < 75) {
      this.confidenceLevel = 'MEDIUM';
      this.cssLevel = ' priorityCell medium';
    } else {
      this.confidenceLevel = 'LOW';
      this.cssLevel = 'priorityCell low';
    }
    this.condLike = !this.dataContext.enable_up_vote ? 'fas' : 'fal';
    this.condUnlike = !this.dataContext.enable_down_vote ? 'fas' : 'fal';

    this.upCount = (this.dataContext.upvotes.length) > 0 ? this.dataContext.upvotes.length : '     ';
    this.downCount = (this.dataContext.downvotes.length) > 0 ? this.dataContext.downvotes.length : '       ';
  }
}
