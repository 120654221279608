import { ZmZettaUtils } from '../../../common/zm-shared/zm-zettaUtils';
import { Analytic } from '../zm-analytics/analytic.model';
import { AnalyticService } from '../zm-analytics/analytic.service';
import { AngularGridInstance } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../zm-project-progress/project-progress.model';
import { AppGlobals } from '../../../common/zm-shared/app.globals';
import { checkBoxFormattter } from '../../../common/zm-shared/zm-formatters/checkBoxFormatter';
import { radioButtonFormatter } from '../../../common/zm-shared/zm-formatters/radioButtonFormatter';

const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';

@Component({
    selector: 'zm-users',
    templateUrl: './zm-users.component.html',
    styleUrls: ['./zm-users.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ZmUsersComponent implements OnInit, OnDestroy {
    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    analyticDetail: Analytic = new Analytic();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;

    tableSettings: object = { 'height': '100%' , 'width': '100%' };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];
    errorRibbon = false;

    constructor(
        public zettaUtils: ZmZettaUtils,
        public analyticSvc: AnalyticService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }
        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            // createPreHeaderPanel: true,
            // showPreHeaderPanel: true,
            // preHeaderPanelHeight: 10,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        

        const queryParams = this.activatedRoute.snapshot.queryParams;
        const routeParams = this.activatedRoute.snapshot.params;
        this.analyticDetail.analyticid = queryParams.id;
        this.analyticDetail.analyticid = routeParams.id;

        // console.log('queryParams.id:' + queryParams.id)
        // console.log('routeParams.id:' + routeParams.id)

        
        // this.analyticDetail.analyticid = this.activatedRoute.snapshot.params['id'];

        // this.analyticSvc.getCurrentActiveJobs(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid).subscribe(
        //     jobs => {
        //         this.hasActiveJob = this.analyticSvc.hasActiveJob(jobs);
        //     });
        this.hasActiveJob = false;

        this.getUsersList();
    }

    initColDef() {
        this.gridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'username',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'First Name',
            'physicalname': 'firstname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Last Name',
            'physicalname': 'lastname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Group Name',
            'physicalname': 'groupname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];

        this.rightGridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'username',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'First Name',
            'physicalname': 'firstname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Last Name',
            'physicalname': 'lastname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Admin',
            'physicalname': 'isProjectAdminChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'cssClass': 'text-center',
            'headerCssClass': 'text-center',
            'formatter': checkBoxFormattter,
            // 'columnGroup': 'Authorizations',
            'minWidth': 60,
        }, {
            'displayname': 'Read Only',
            'physicalname': 'isReviewerChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'cssClass': 'text-center',
            'headerCssClass': 'text-center',
            'formatter': checkBoxFormattter,
            // 'columnGroup': 'Authorizations',
            'minWidth': 60,
        }, {
            'displayname': 'Read/Write',
            'physicalname': 'isApproverChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'cssClass': 'text-center',
            'headerCssClass': 'text-center',
            'formatter': checkBoxFormattter,
            // 'columnGroup': 'Authorizations',
            'minWidth': 60,
        }];
    }

    getUsersList() {
        this.analyticSvc.getAnalyticUsers(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid, this.loggedInUserDetails['groupid']).subscribe(responseList => {
                // this.rightGridDataSource = responseList[1]; 
                this.rightGridDataSource = responseList[1]['currentpage'][0]['users']; 
                this.rightGridDataSource.forEach(row => {
                    row.roles.forEach(roles => {
                        switch (roles) {
                            case AppGlobals.PROJECT_ADMIN:
                                row['isProjectAdminChecked'] = true;
                                row['selectedRoles'] = row['roles'];
                                break;
                            case AppGlobals.REVEIWER:
                                row['isReviewerChecked'] = true;
                                row['selectedRoles'] = row['roles'];
                                break;
                            case AppGlobals.APPROVER:
                                row['isApproverChecked'] = true;
                                row['selectedRoles'] = row['roles'];
                                break;
                            default:
                                row['isProjectAdminChecked'] = false;
                                row['isReviewerChecked'] = false;
                                row['isApproverChecked'] = false;
                                row['selectedRoles'] = [];
                        }
                    });
                });
                this.rightGridRows = this.rightGridDataSource.length;
                this.leftGridDataSource = responseList[0]['currentpage'];
                this.progressState.states = this.zettaUtils.getStateList('create-analytic');

                this.progressState.currentStateIndex = 1;
                this.progressState.currentStateInfo = 'Saved...'; 
                this.progressState.type = 'Analitycs';
                this.progressState.project = this.analyticDetail;

                // this.progressState.project.name = responseList[2]['name'];   
                // this.progressState.project.algoid = responseList[2]['algoid'];
                this.progressState.project.name = 'Mortgage Default Prediction Model Analytics';   
                this.progressState.project.algoid = '11';

                if(this.progressState.project.algoid == '11') { this.progressState.project.img = "icon-analytics-correlation.png" }
                if(this.progressState.project.algoid == '12') { this.progressState.project.img = "icon-analytics-classification.png" }
                if(this.progressState.project.algoid == '13') { this.progressState.project.img = "icon-analytics-clustering.png" }
                if(this.progressState.project.algoid == '14') { this.progressState.project.img = "icon-analytics-simulation.png" }

                this.leftGridDataSource = this.leftGridDataSource.filter(user1 => !this.rightGridDataSource.find(user2 => user1.userid === user2.userid));
                this.leftGridRows = this.leftGridDataSource.length;
                this.initColDef();
                this.isDataReady = true;
            }, err => {
                // alert('error');
            });

    }

    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
        const dataSet = this.rightGrid.dataView.getItems();

        if (row !== undefined && row !== null) {
            if (fieldName === 'isProjectAdminChecked') {
                row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
                if (row['isProjectAdminChecked']) {
                    row['selectedRoles'].push(AppGlobals.PROJECT_ADMIN);
                } else {
                    row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.PROJECT_ADMIN);
                }
            } else if (fieldName === 'isReviewerChecked') {
                row['isReviewerChecked'] = !row['isReviewerChecked'];
                if (row['isReviewerChecked']) {
                    row['selectedRoles'].push(AppGlobals.REVEIWER);
                } else {
                    row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
                }
            } else if (fieldName === 'isApproverChecked') {
                row['isApproverChecked'] = !row['isApproverChecked'];
                if (row['isApproverChecked']) {
                    row['selectedRoles'].push(AppGlobals.APPROVER);
                } else {
                    row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
                }
            }
            this.rightGrid.gridService.updateDataGridItem(row, false);
            // TODO: Call API to Update DB
        }
    }

    onAdd() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const userList: Object[] = [];
        const rows: any[] = [];

        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            row['selectedRoles'] = [];
            this.rightGridDataSource.push(row);
            rightGridDataService.addItemToDatagrid(row, true);
            // remove Item from left grid
            userList.push({ userid: row.userid });
            this.leftGridDataSource = this.leftGridDataSource.filter(user => user.userid !== row.userid);
            leftGridDataService.deleteDataGridItem(row);
        });
        
        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        // Update Row Count
        this.leftGridRows = this.leftGridRows - userList.length;
        this.rightGridRows = this.rightGridRows + userList.length;
        // update DB
        this.updateAnalytic(userList, 'ADD');
    }

    onRemove() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const userList: String[] = [];
        const rows: any[] = [];

        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                row.roles = [];
                rows.push(row);
            }
        });

        rows.forEach(row => {
            this.leftGridDataSource.push(row);
            leftGridDataService.addItemToDatagrid(row, true);
            // remove Item from right grid
            userList.push(row.userid);
            this.rightGridDataSource = this.rightGridDataSource.filter(user => user.userid !== row.userid);
            rightGridDataService.deleteDataGridItem(row);
        });
        // this.leftGrid.slickGrid.setData();
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);

        this.leftGridRows = this.leftGridRows + userList.length;
        this.rightGridRows = this.rightGridRows - userList.length;
        this.updateAnalytic(userList, 'DELETE');
    }

    rightGridCreated(grid) {
        this.rightGrid = grid;
    }
    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    updateAnalytic(users: any[], opcode: string) {
        if (users.length > 0) {
            const analytic = new Analytic();
            analytic.analyticid = this.analyticDetail.analyticid;
            analytic.userid = this.loggedInUserDetails['userid'];
            analytic.groupid = this.analyticDetail.groupid;
            if ('ADD' === opcode) {
                analytic.users = users;
            } else {
                analytic.deleteusers = users;
            }
            this.analyticSvc.saveAnalytic(analytic).subscribe(resp => {
                $('#projectState1').removeClass('d-none');
                setTimeout(function () {
                    $('#projectState1').addClass('d-none');
                }, 5000);
            });
        }
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zm/analytics']);
        } else {
            this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
        }
    }

    onPrevious() {
        this.router.navigate(['/zm/analytics/create-analytic'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    addUserRole(boolean: boolean) {
        // const selectedAnalyticAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
        // const selectedAnalyticApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
        // const selectedAnalyticReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
        // if (selectedAnalyticAdminList.length === 0 || selectedAnalyticApproverList.length === 0 ||
        //     selectedAnalyticReviewerList.length === 0) {
        //     this.errorRibbon = true;
        // } else {
        //     this.errorRibbon = false;
        //     this.saveUserRole(boolean);
        // }

        if (boolean) {
            // this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
            this.router.navigate(['/zm/analytics/1']);
        } else {
            // this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid, 'add-training'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
            this.router.navigate(['/zm/analytics/1/add-training'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
        }
    }

    saveUserRole(bool: boolean) {
        this.rightGridDataSource.map(resp => {
            this.selectedUserRoles.push({ userid: resp.userid, roles: resp.selectedRoles });
            this.selectedUserRoles = this.selectedUserRoles.filter(res => res.userid);
        });
        this.analyticSvc.saveUserRoles(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid, this.selectedUserRoles).subscribe(resp => {
            if (bool) {
                this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
            } else {
                this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid, 'add-training'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
            }
        });
    }

}
