import { Column, Formatter } from 'angular-slickgrid';
import { ZmZettaUtils } from '../zm-zettaUtils';
const zettaUtilsInstance = new ZmZettaUtils();

export const analyticNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

	if(dataContext.icon_id=='11'){ var img = "icon-analytics-correlation.png" };
	if(dataContext.icon_id=='12'){ var img = "icon-analytics-classification.png" };
	if(dataContext.icon_id=='13'){ var img = "icon-analytics-clustering.png" };
	if(dataContext.icon_id=='14'){ var img = "icon-analytics-simulation.png" };
	if(dataContext.icon_id=='15'){ var img = "icon-workbooks1.png" };

	return `
		<div class="formatter project-title-formatter-nodesc pointer m-0 p-0" style="margin-left: -5px !important;">
			<div class='text-truncate text-regular hyper-link m-0 p-0' title='${dataContext.name}'>
				<div class="pull-left p-0 m-0 mr-2"><img class="img-size pb-1" src="./assets/images/zettamesh/${img}"></div>
				<div class="p-0 m-0 mr-2">
					<p class="analytic-name m-0 text-truncate" title='${dataContext.name}'>${dataContext.name}</p>
				</div>
			</div>		
		</div>`;
	
};
