import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/common/services/base-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
    selector:'zetta-api',
    templateUrl:'./user-api.component.html',
    styleUrls:['./user-api.component.scss']
})
export class UserApiComponent implements OnInit{

    apiUrl: string ;
    userId:number ;
    srcUrl:SafeHtml ;
    constructor(private _baseService:BaseService,private sanitizer: DomSanitizer){}
    ngOnInit(){
        this.userId =this._baseService.getObjectFromSession('userInfo').user_id
        this.apiUrl=this._baseService.apiUrl;
        this.srcUrl =this.sanitizer.bypassSecurityTrustResourceUrl(this.apiUrl+'/users/'+this.userId+'/help/rest/');
    }
}