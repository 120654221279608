import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'mather-merger-formatter',
  template: `
    <div [ngClass]="getCssClasses()" [title]="params.value"
      [attr.data-toggle]="showIcon ? 'modal' : null"
      [attr.data-backdrop]="showIcon ? 'static' : null"
      [attr.data-keyboard]="showIcon ? 'false' : null"
      [attr.data-target]="getModalTarget()">
      <i *ngIf="showIcon" [class]="getTaskNameIconClass(this.params.data.task_type? this.params.data.task_type.toLowerCase() : (this.params.data.tasktype ? this.params.data.tasktype.toLowerCase(): '') )"></i>
      {{ params.value }}
    </div>
  `,
  styles: [],
})
export class MatherMergerFormatterComponent implements ICellRendererAngularComp {
  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  getCssClasses(): string {
    if (this.params.data.task_type === 'Match Training' || this.params.data.task_type === 'Match Fixing' || this.params.data.tasktype === 'Match Fixing') {
      return 'blueColor pointer modal-feedback text-truncate';
    } else if (this.params.data.task_type === 'Merge Training' || this.params.data.task_type === 'Merge Fixing') {
      return 'blueColor pointer modal-feedback text-truncate';
    } else {
      return 'text-truncate';
    }
  }
  getTaskNameIconClass(str)
    {
      switch (str) {
       case 'match training':
         return 'fas fa-graduation-cap';
       case 'merge training':
         return 'fas fa-graduation-cap';
       case 'merge fixing':
         return 'fas fa-wrench';
       case 'match fixing':
       return 'fas fa-wrench';
       default:
         return 'fas  fa-graduation-cap';
     }
   //   const zettaUtilsInstance = new ZettaUtils();
   //   return this.zettaUtilsInstance.getTaskNameIcon(this.value ? this.value.toLocaleLowerCase() : '');
   }
  get showIcon(): boolean {
    return (
      this.params.data.task_type === 'Match Training' ||
      this.params.data.task_type === 'Merge Training' ||
      this.params.data.task_type === 'Match Fixing' ||
      this.params.data.task_type === 'Merge Fixing' ||
       this.params.data.tasktype === 'Match Fixing'
    );
  }
  getModalTarget(): string {
    if (this.params.data.task_type === 'Match Training' || this.params.data.task_type === 'Match Fixing' || this.params.data.tasktype === 'Match Fixing') {
      return '#matcher-feedback-modal';
    } else if (this.params.data.task_type === 'Merge Training' || this.params.data.task_type === 'Merge Fixing' || this.params.data.tasktype === 'Match Fixing') {
      return '#merger-feedback-modal';
    } else {
      return '';
    }
  }
} 