import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

export const matherMergerFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

    function getTaskNameIcon() {
        return zettaUtilsInstance.getTaskNameIcon(dataContext.task_type.toLocaleLowerCase());
    }

    if (dataContext.task_type === 'Match Training') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
         ></i>${value}</div>`;
    } else if (dataContext.task_type === 'Merge Training') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#merger-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
         ></i>${value}</div>`;
    } else if (dataContext.task_type === 'Match Fixing') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
         ></i>${value}</div>`;
    } else if (dataContext.task_type === 'Merge Fixing') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
         ></i>${value}</div>`;
    } else if (dataContext.task_type !== 'Match Training') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal">${value}</div>`;
    } else if (dataContext.task_type !== 'Merge Training') {
        return `<div class="blueColor pointer modal-feedback text-truncate" title='${value}'
         data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal">${value}</div>`;
    } else {
        return `<div class="text-truncate" title='${value}'>${value}</div>`;
    }

    // function getTaskNameIcon() {
    //     return zettaUtilsInstance.getTaskNameIcon(value.toLocaleLowerCase());
    // }
    // 
    // if (value === 'Match Training') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
    //      ></i>${value}</div>`;
    // } else if (value === 'Merge Training') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
    //      ></i>${value}</div>`;
    // } else if (value === 'Match Fixing') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
    //      ></i>${value}</div>`;
    // } else if (value === 'Merge Fixing') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal"><i class='mr-2 ${getTaskNameIcon()}'
    //      ></i>${value}</div>`;
    // } else if (value !== 'Match Training') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal">${value}</div>`;
    // } else if (value !== 'Merge Training') {
    //     return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'
    //      data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#matcher-feedback-modal">${value}</div>`;
    // } else {
    //     return `<div class="text-truncate" title='${value}'>${value}</div>`;
    // }

};


