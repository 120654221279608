

import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';

const zettaUtilsInstance = new ZettaUtils();

export const toolTipFormatter: Formatter =
    (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        return `<span class="text-truncate" title="${value || 'null'}">${value || '<span class="blur">null</span>'}</span>`;
    };
