import { User } from '../../../../../common/shared/zetta-models';
import { ObservableService } from '../../../../../common/services/observable.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ProjectService } from '../../../../../zettasense/content/project/project.service';
import { Project } from '../../../../../zettasense/content/project/project.model';
import { DatasetService } from '../../dataset.service';
import { IDataset } from '../../dataset.model';
import { param } from 'jquery';

@Component({
  selector: 'zetta-data-entitlements',
  templateUrl: './data-entitlements.component.html',
  styleUrls: ['./data-entitlements.component.scss']
})
export class DataEntitlementsComponent implements OnInit {

  projectDetail = new Project();
  totalUsers = 0;
  isNotDataAdmin: boolean = true;
  datasetId;
  isAdmin=false;
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  private _project_entitlements = {};

  constructor(public zettaUtility: ZettaUtils,
    private _projectService: ProjectService,
    public observeSvc: ObservableService,
    private dsService:DatasetService,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
      this.datasetId=params['id'];
    });
    
     if(sessionStorage.getItem('user_entitlement')) {
      let user_entitlement=sessionStorage.getItem('user_entitlement');
      let user=user_entitlement.split(',');     
      this.isAdmin = JSON.parse(user[0]) ? true:false;      
    }

    this.observeSvc.getCurrentEntitlements.subscribe(data => { this._project_entitlements = data ? data : {}; });
    this.observeSvc.getDataSetDetails.subscribe(resp => {
      this.isNotDataAdmin = resp['created_by'] === this._projectService.getObjectFromSession('userInfo')['user_name'] ? false : true;
    })
    this.getUserEntitlements();
  }

  getUserEntitlements() {
    this._projectService.getUserEntitlements(this._projectService.getObjectFromSession('userInfo').user_id,
      this._projectService.getObjectFromSession('userInfo').tenant_id, this.projectDetail.project_id).subscribe(responseList => {
        this.projectDetail = responseList;
        this.getActiveUsers();
        // if (this.projectDetail.users) {
        //   this.totalUsers = this.projectDetail.users.length;
        // }
      }, err => {
        this.projectDetail.created_ts = new Date();
       // this.projectDetail.users = [];
      });

  }

  getActiveUsers(){
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.datasetId
    }
    this.dsService.getActiveUsersbyDatasetId(Datasets).subscribe((resp)=>{
        this.projectDetail.users =resp;
        if (this.projectDetail.users) {
          this.totalUsers = this.projectDetail.users.length;
        }
    }, err =>{
      this.projectDetail.users = [];
    });
  }
  isHavingAccess() {
    if (this.zettaUtility.isProcessRunning) {
      return true;
    } else {
      return this._project_entitlements['canView'] ? !this._project_entitlements['canView'] : true;
    }
  }


  getProfileImageClass(index: number, total: number) {
    if (total === 1) {
      return 'col-md-12 text-center';
    } else if (index === 0 || index === 1) {
      return 'col-md-6 mb-2';
    } else if (index === 2 && total === 3) {
      return 'col-md-12';
    } else if (index === 2 || index === 3) {
      return 'col-md-6';
    }
  }
}

