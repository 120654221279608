import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import { Event, ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { of } from "rxjs";
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
declare var $: any;
import { environment } from 'src/environments/environment';

const mappingColumnsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score*100) >= 75 ? 'HIGH' : (dataContext.score*100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score*100);
  let txt = '';
  let condLike = !dataContext.enable_up_vote ? 'fas' : 'fal';
  let condUnlike = !dataContext.enable_down_vote ? 'fas' : 'fal';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;&nbsp;`;

  if(level === 'HIGH') { txt = txt + `<div class="box-right text-center text-uppercase" title='${level}'>${level}</div>`; }
  if(level === 'MEDIUM') { txt = txt + `<div class="box-right boxM text-center text-uppercase" title='${level}'>${level}</div>`; }
  if(level === 'LOW') { txt = txt + `<div class="box-right boxL text-center text-uppercase" title='${level}'>${level}</div>`; }
  
  txt = txt + `<div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-2">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-2">${downCount}</span>
  `;
  return txt;
};

const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (cell == 0) {
    const isConceptLevel = columnDef.params.isConceptLevel;
    const col_wd = isConceptLevel ? 'wd-10' : 'wd-15-vw';
    return `<div class="datasource-name-formatter mr-left-10 text-truncate ${col_wd}">
            <span title='${value}' class="pointer hyper-link">${value}</span><br>
        </div>`;
  } else {
    return `<div class="datasource-name-formatter wd-8 text-truncate">
             <span title='${value}' class="pointer hyper-link">${value}</span><br>
           </div>`;
  }
};
const LEFT_EMPTY_GRID_MSG = 'No Data Found.';

const dsColumnName: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="datasource-name-formatter wd-10 text-truncate">
          <span title='${value}' class="pointer">${value}</span><br>
         </div>`;
};

@Component({
    selector: 'zetta-zs-cl-catalog-object-concept-right',
    templateUrl: './zs-cl-catalog-object-concept-right.component.html',
    styleUrls: ['./zs-cl-catalog-object-concept-right.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

export class ZsClCatalogObjectConceptRightComponent implements OnInit{

  public conceptDataSource = [];
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    // public grid: AngularGridInstance;
    public rightGrid: AngularGridInstance;
    public tableSettings: object = { 'height': '100%', 'width': "99.96%", 'pageSize': 20, 'isNormalPagination': false };
    public modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    datasetTableColDef: any;
    gridOptionsRight: any;
    isLoading = true;
    noData = false;
    total = 0;
    page = 1;
    limit = 20;
    catalog_id;
    object_id;
    attribute_id;
    isAllConcepts;
    querystring:string='';
    classificationTags: any = [];
    isLoadingclassificationTags = true;
    selectedDsColumn;
    selectedTag = "";
    searchClassificationTags: any = [];
    selectedTagOwn = "";
    searchTags: any = [];
    

    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    public modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;
    showmodelpopup=false;
    datasetId:any;
    showDownvoteModal=false;    
    datasetColumnId;
    mappingType;
    dsColumnName;
    showBackdrop:boolean=false;
    showConceptColumn:boolean=true;
    isFiltering: boolean = false;
    hasDatasetsScrolled: boolean = false;
    showFeedbackSummaryModel: Boolean=false;
    votedUsers: [];
    conceptName: string;
    semanticObjectName: string;
    datasourceName: string;
    datasetName: string;
    datasetColumnName: string;
    screenName: string;
    voteValue: string;

    constructor(
        private catalogSvc: ZsClContentService,
        private activatedRoute: ActivatedRoute,
        public zettaUtils: ZettaUtils,
        private router: Router,
        private projectService: ProjectService,
        private sanitizer: DomSanitizer,
        private baseService: BaseService,
        private http: HttpClient,
        private messageSvc: MessageService) { }

  initDefaultCodDef() {
    this.datasetTableColDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
      'params': { isConceptLevel: false }
    }, {
      'displayname': 'Data Set',
      'physicalname': 'dataset_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': mappingColumnsFormatter,
      'minWidth': 200,
    }];
  }
  initColDef() {
    this.datasetTableColDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
      'params': { isConceptLevel: true }
    }, {
      'displayname': 'Data Set',
      'physicalname': 'dataset_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
    }, {
      'displayname': 'Data Set Column',
      'physicalname': 'column_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': dsColumnName,
    }, {
      'displayname': 'Data Type',
      'physicalname': 'data_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': mappingColumnsFormatter,
      'minWidth': 200,
    }];
  }
  ngOnInit() {
    this.gridOptionsRight = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      // enableRowSelection: true,
      enableFiltering: true,
      noDataMsg: LEFT_EMPTY_GRID_MSG

    };

    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };

      this.conceptDataSource = [];
      this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
      this.catalog_id = sessionStorage.getItem('catalog_id');
      this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
      this.activatedRoute.parent.queryParamMap.subscribe(params => {
        this.attribute_id = params.get('attribute_id');
        this.conceptDataSource = [];
        if (this.attribute_id > 0) {
          this.initColDef();
        } else {
          this.initDefaultCodDef();
        }
        this.getConceptsT(this.page);
      });

  }

  getConceptsT(page) {
    let limit = 100;
    if (this.attribute_id != null && this.attribute_id > 0) {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/techview?concept=${this.attribute_id}&pagination=true&pageno=${1}&pagesize=${limit}`;
    } else {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/techview?pagination=true&pageno=${1}&pagesize=${limit}`;
    }
    this.isLoading = true;
    this.querystring = '';
    if (this.attribute_id != null && this.attribute_id > 0) {
      this.querystring = `?concept=${this.attribute_id}`;
    }
    let serverfilter = "";
        if(sessionStorage.getItem("serverfilter")){
          serverfilter = sessionStorage.getItem("serverfilter");
          this.isFiltering = true;
        } else {
          if(this.isFiltering){ 
            page = 1;
          }
          this.isFiltering = false;
        }
       let sortfilter="";
       if(sessionStorage.getItem("sortfilter")){
          sortfilter=sessionStorage.getItem("sortfilter");
       } 
    const getDataset: any = {
      user_id: this.loggedInUserDetails['user_id'],
      catalog_id: this.catalog_id,
      object_id: this.object_id,
      concept: this.querystring,
      pageNo: page,
      limit,
      paginate: true
    };
    
    this.catalogSvc.getAllConcept(getDataset, serverfilter, sortfilter).subscribe((data) => {
      this.conceptDataSource = data;
      this.isLoading = false;
      if (this.rightGrid) {
        this.conceptDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.rightGrid.dataView.setItems(this.conceptDataSource);
        this.rightGrid.gridService.setSelectedRows([]);
      }
    }, err => {
      this.isLoading = false;
     // this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    this.rightGrid = grid;
  }
  
  onDatasetCellClick(e): void { 
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.rightGrid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    this.selectedDsColumn = args.row;

    if (eventEle.target.className.includes('fa-thumbs-down')) {
      this.showConceptColumn = row.dataset_column_id ? true : false;
      this.dsColumnName = this.showConceptColumn ? row.column_name : row.dataset_name;
      this.datasetId=row.dataset_id;
      this.datasetColumnId=row.dataset_column_id;
      this.mappingType=row.mapping_type === undefined ? 'manual' : row.mapping_type;
      this.showDownvoteModal=true;
             
    //  this.getClassificationTags(row);
    //  $('#edit_Classification_tagTech').modal('show');
    //  $('#edit_Classification_tagTech').removeClass('d-none');
    //  $("#tagKeep").prop("checked", true);
    }

    const payload: any = {
      "dataset_id": +row.dataset_id,
      "dataset_column_id": +row.dataset_column_id,
      "removing_attribute_id": +this.attribute_id,
      "attribute_id": +this.attribute_id,
      "mapping_type": 'manual',
      "entity_id": +this.object_id,
      "catalog_id": +this.catalog_id
    }
    
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.dataset_name) {
        this.dsName = row.dataset_name;
        this.showmodelpopup=true;
        this.datasetId=row.dataset_id;
      } else if (eventEle.target.title === row.datasource_name) {
        $('#datasets_modal').modal('hide');
        $('#datasets_modal').addClass('d-none');
        this.router.navigate(['/zs/datasources', row.datasource_id]);
      }
    }
    if (eventEle.target.className.includes('fal fa-thumbs-up')) {
      if(row.dataset_column_id==undefined||row.dataset_column_id==0)
      {
        const upVotedata = {
          "dataset_id": row.dataset_id,
          "mapping_type": "manual",
          "entity_id": this.object_id,
          "catalog_id": this.catalog_id,
          "is_semantic_level_mapping": true
        }
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          payload: upVotedata
        }
        this.catalogSvc.saveVoteSemanticObject(payload, 'up_vote').subscribe(resp => {
          $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
          $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
          $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
          $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          this.getConceptsT(1);
        }, error => {
          this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
      }
      else{
      this.upVote(payload);
      }
    } else if (eventEle.target.className.includes('fal fa-thumbs-down')) {
      $('#thumbs-down-' + args.row).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
     // this.downVote(payload);
    } else if (eventEle.target.className.includes('countUp')) {
      this.showFeedbackSummaryModel = true;
      this.votedUsers = row.upvotes
      if (this.attribute_id != null && this.attribute_id > 0) {
        this.catalogSvc.getConcept(this.catalog_id, this.object_id, this.attribute_id).subscribe((data) => {
          this.conceptName = data.logical_name;
          this.datasetColumnName = row.column_name;
        });
        this.screenName = 'conceptTechView';
      } else {
        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe((data) => {
          this.semanticObjectName = data.semantic_object;
        });
        this.screenName = 'allConceptTechView';
      }
      this.datasourceName = row.datasource_name;
      this.datasetName = row.dataset_name;
      this.attribute_id = row.attribute_id;
      this.datasetId = row.dataset_id;
      this.datasetColumnId = row.dataset_column_id;
      this.voteValue = 'upVote'
    } else if (eventEle.target.className.includes('countDown')) {
      this.showFeedbackSummaryModel = true;
      this.votedUsers = row.downvotes
      if (this.attribute_id != null && this.attribute_id > 0) {
        this.catalogSvc.getConcept(this.catalog_id, this.object_id, this.attribute_id).subscribe((data) => {
          this.conceptName = data.logical_name;
          this.datasetColumnName = row.column_name;
        });
        this.screenName = 'conceptTechView';
      } else {
        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe((data) => {
          this.semanticObjectName = data.semantic_object;
        });
        this.screenName = 'allConceptTechView';
      }
      this.datasourceName = row.datasource_name;
      this.datasetName = row.dataset_name;
      this.attribute_id = row.attribute_id;
      this.datasetId = row.dataset_id;
      this.datasetColumnId = row.dataset_column_id;
      this.voteValue = 'downVote';
    }

  }
  getClassificationTags(row) {
    this.catalogSvc.getClassificationTags({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id:row.dataset_id, column_id:row.dataset_column_id }).subscribe((data) => {
      this.classificationTags = data;
      this.isLoadingclassificationTags = false;
      this.classificationTags.forEach(element => {
        element.score100 = +(element.score * 100).toFixed();
        element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
        element.dataset_id = row.dataset_id;
        element.column_name = row.column_name;
      });
      this.classificationTags.sort(((a, b) => b.score100 - a.score100));
    }, err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }
  upVote(payload) {
    const getRow: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      payload: payload
    };
    this.catalogSvc.upVote(getRow).subscribe((data) => {
      $('#thumbs-up-' + this.selectedDsColumn).removeClass('fal fa-thumbs-up');
      $('#thumbs-up-' + this.selectedDsColumn).addClass('fas fa-thumbs-up');
      $('#thumbs-down-' + this.selectedDsColumn).removeClass('fas fa-thumbs-down');
      $('#thumbs-down-' + this.selectedDsColumn).addClass('fal fa-thumbs-down');
      this.getConceptsT(1);
    }, err => { });
  }
  downVote(payload) {
    const getRow: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      payload: payload
    };
    this.catalogSvc.downVote(getRow).subscribe((data) => {
    }, err => { });
  }

  saveClassificationTags() {      
    if (this.selectedTag !== "" && this.selectedTag !== undefined) {        
      this.selectedTag = this.selectedTag + '';
      this.selectedTag = this.selectedTag.split('_')[0];
      const payload: any = {
        "dataset_id": +this.conceptDataSource[this.selectedDsColumn].dataset_id,
        "dataset_column_id": +this.conceptDataSource[this.selectedDsColumn].dataset_column_id,
        "removing_attribute_id": +this.conceptDataSource[this.selectedDsColumn].attribute_id,
        "attribute_id": +this.selectedTag,
        "mapping_type": 'manual',
        "entity_id": +this.object_id,
        "catalog_id": +this.catalog_id
      } 
      this.upVote(payload);
        $('#thumbs-down-' + this.selectedDsColumn).removeClass('fal fa-thumbs-down');
        $('#thumbs-down-' + this.selectedDsColumn).addClass('fas fa-thumbs-down');
      }
    this.closeClassificationTags();
  }

  closeClassificationTags() {
    $('#edit_Classification_tagTech').modal('hide');
    this.selectedTag = "";
    this.selectedTagOwn = "";
    this.searchClassificationTags = this.getSearchClassificationTags();
  }
  
  selectOptionDataSetModelList(dataSetId, limit = 30) {
    // this.isSampleDsLoading = true;
    this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
      this.modalRowData = data.currentpage;
      const rowKeys = [];
      if (data.currentpage.length) {
        const myVar: any = data.currentpage[0];
        for (var key in myVar) {
          rowKeys.push(key);
        }
      }
      this.modelColumnDef = [];
      rowKeys.forEach(column => {
        this.modelColumnDef.push({
          'displayname': column,
          'physicalname': column,
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
        });
      });
      this.modalRowData.forEach((element: any) => {
        element.id = Math.random();
      });
      if (this.modalGrid) {
        this.modalGrid.dataView.setItems(this.modalRowData);
        this.modalGrid.gridService.setSelectedRows([]);
      }
      this.isSampleDsLoading = false;
    }, err => {
      this.isSampleDsLoading = false;
      this.modalRowData = [];
    });
  }

  onSearchClassificationTags(event: any) {
    this.searchClassificationTags = this.getSearchClassificationTags(event.term);
  }

  getSearchClassificationTags(term: string = null) {
    if (term) {
        this.catalogSvc.getSearchClassificationTags(term).subscribe((data) => {
          this.searchTags = data;
        }, err => { });
      // return this.http.get<any>(`https://api.github.com/search/users?q=${term}`).pipe(map(rsp => rsp.items));
        return this.http.get<any>(`${this.baseService.apiUrl}/users/${this.loggedInUserDetails['user_id']}/attributes?logical_name=${term}`);
    } else {
      return of([]);
    }
  }
  onChangeClassificationTags(event) {
    $("#tagOwn").prop("checked", true);
    this.selectedTag = event !== undefined ? event.attribute_id : "";
    if(event !== undefined) {
      this.selectedTag = event.attribute_id;
    } else {
      this.selectedTag = "";
      this.searchClassificationTags = this.getSearchClassificationTags();
    }
  }  

  onFocusClassificationTags($event: Event) {
    // this.event.push({ name: '(focus)', value: $event });
    $("#tagOwn").prop("checked", true);
  }

  onModelGridCreation(grid) {
    this.modalGrid = grid;
  }

  popupoutput(message){
    this.showmodelpopup = message;   
  }
  closeModalPopup(event){        
    if(event){
      $('#thumbs-down-' + this.selectedDsColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedDsColumn).addClass('fas fa-thumbs-down');
      $('#thumbs-up-' + this.selectedDsColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedDsColumn).addClass('fal fa-thumbs-up');
      this.getConceptsT(1);
    }
    this.showDownvoteModal = false
  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }

  onTrainModel() {
    if (this.attribute_id) {
      this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/select-datasets`],
        { queryParams: { object_id: this.object_id, concept_id: this.attribute_id }, queryParamsHandling:'merge' });      
    } else {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/select-datasets'],
        { queryParams: { object_id: this.object_id, concept_id: this.attribute_id }, queryParamsHandling:'merge'  });
    };
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    if(!this.isLoading && this.page < this.total){
      this.isLoading = true;
      this.getConceptsT(this.page);
    }
  }
}