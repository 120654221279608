import { AppGlobals } from '../../common/shared/app.globals';
import { ProjectService } from '../content/project/project.service';
import { AnalyticService } from '../../zettamesh/zm-content/zm-analytics/analytic.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Project } from '../content/project/project.model';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { DatasourceService } from '../../common/components/datasources/datasource.service';
import { DatasetService } from '../../common/components/datasets/dataset.service';
import { ContentService } from '../content/content.service';
import { ZcContentService } from '../../zettaconsume/zc-content/zc-content.service';
import { ObservableService } from '../../common/services/observable.service';
import { ZsClContentService } from '../../zettaclassify/zs-cl-content/zs-cl-content.service';
import { ZmContentService } from '../../zettamesh/zm-content/zm-content.service';


/**
 * @export
 * @interface Breadcrumb
 */
export interface Breadcrumb {
  label: string;
  url: string;
  type?: string;
  typeValue?: any;
}

@Component({
  selector: 'zetta-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

/**
 * @export
 * @class BreadcrumbComponent
 * @implements {OnInit}
 */
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];
  ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  ROUTE_DATA_BREADCRUMB_TYPE = 'type';
  isDataReady = true;
  object_id; 
  project_type = '';
  dqRuleSelected: Boolean = false;
  loggedInUserDetails: [];
  projectId;
  showRecentLabel: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private analyticService: AnalyticService,
    public zettaUtils: ZettaUtils,
    private datasourceSvc: DatasourceService,
    private datasetSvc: DatasetService,
    private reportSvc: ZcContentService,
    private contentSvc: ContentService,
    public observeSvc: ObservableService,
    private catalogSvc: ZsClContentService,
    private zmContentSvc: ZmContentService,
    private ZsClContentService: ZsClContentService ) { }

  ngOnInit() {
    const breadcrumb: Breadcrumb = {
      label: 'Home',
      url: ''
    };

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // set breadcrumbs
      const root: ActivatedRoute = this.route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = [...this.breadcrumbs];
    });

    if(this.breadcrumbs === undefined) {
      this.breadcrumbs = [breadcrumb];
    }

    this.route.queryParamMap.subscribe(params => {
      this.project_type = params.get('project_type');
      if(params.has('showRecent') && params.get('showRecent') == 'false') {
        this.showRecentLabel = true;
      } else {
        this.showRecentLabel = false;
      }
    });
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    
    this.observeSvc.datasetLabelUpdate.subscribe(resp => {
      if(resp && resp['name'] && this.breadcrumbs && this.breadcrumbs.length>0) {
        this.breadcrumbs[1]['label'] = resp['name'];
      }
    });
  }

  /**
   * @private
   * @param {ActivatedRoute} route
   * @param {string} [url=""]
   * @param {Breadcrumb[]} [breadcrumbs=[]]
   * @returns {Breadcrumb[]}
   * @memberof BreadcrumbComponent
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;
    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length === 0) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;
      // add breadcrumb
      const breadcrumb = this.generateBreadcrumbLabel(child, url);
      if(breadcrumb.label.includes('Object Model')){
        let catalog_id = child.snapshot.pathFromRoot[2].params.catalog_id;
        let object_id = child.snapshot.params.object_id;
        this.object_id=object_id;
        breadcrumbs.push({
          "label":sessionStorage.getItem("concept_name"),
          "url":'./zs-cl/catalogs/'+catalog_id+'/objects/'+object_id,
          "type":'object',
          "typeValue":sessionStorage.getItem("concept_name")
        }
          
        );
      }
      breadcrumbs.push(breadcrumb);
      if ((child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'concept' &&
        child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Train Model') ||
        (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'object' &&
          child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Train Model')) {
        breadcrumbs.push({
          "label": 'Train Model',
          "url": '',
          "type": 'object',
          "typeValue": 'Train Model'
        });
      }

      if(breadcrumbs[0]['label'].includes('System Admin') || breadcrumbs[0]['label'] == 'Tenant Admin') {
        if(this.loggedInUserDetails['is_admin'] == true && this.loggedInUserDetails['is_super_admin'] == false) {
          breadcrumbs[0]['label'] = 'Tenant Admin';
        } else if(this.loggedInUserDetails['is_super_admin'] == true) {
          breadcrumbs[0]['label'] = 'System Admin';
        }
      }

      // recursive
      if(breadcrumb['url'].includes('/business/rule-definition') || breadcrumb['url'].includes('/technical/rule-definition')) {
        this.dqRuleSelected = true;
      } else {
        this.dqRuleSelected = false;
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  private generateBreadcrumbLabel(child: ActivatedRoute, url: string) {
    const breadcrumb: Breadcrumb = {
      label: child.snapshot.data[this.ROUTE_DATA_BREADCRUMB],
      url: url
    };
    if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'project') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'project';
        this.generateProjectBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'entity') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'entity';
        this.generateEntityBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'datasource') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'datasource';
        this.generateDatasourceBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'dataset' || child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Schedule Refresh' || child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'User Entitlements') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'dataset';
        this.generateDataSetBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'report') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'report';
        this.generateReportBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'catalog') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'catalog';
        this.generateCatalogBreadcrumbLabel(child, url, breadcrumb);
    } else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'object') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'object';
        this.generateObjectBreadcrumbLabel(child, url, breadcrumb);
    }else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'concept') {
        this.isDataReady = false;
        breadcrumb.label = '...';
        breadcrumb.type = 'concept';
        this.generateConceptBreadcrumbLabel(child, url, breadcrumb);
    }else if(child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'view-model'){
        breadcrumb.type = 'view-model';
        breadcrumb.label = sessionStorage.getItem("concept_name") + ' Object Model';
        breadcrumb.typeValue = sessionStorage.getItem("concept_name") + ' Object Model';
        this.isDataReady = true;     
    }else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'zs-cl-project') {
      this.isDataReady = false;
      breadcrumb.label = '...';
      breadcrumb.type = 'zs-cl-project';
      this.generateZsClProjectBreadcrumbLabel(child, url, breadcrumb);
    }else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'column') {
      this.isDataReady = false;
      breadcrumb.label = '...';
      breadcrumb.type = 'column';
      this.getDatasetColumnBreadCrumb(child, url, breadcrumb);
      }
    // else if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB_TYPE] === 'workbook') {
    //     this.isDataReady = false;
    //     breadcrumb.label = '...';
    //     breadcrumb.type = 'workbook';
    //     this.generateWorkbookBreadcrumbLabel(child, url, breadcrumb);
    // }
    
    return breadcrumb;
  }
  
  private generateProjectBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
      this.projectId = id;
    }
    if (id !== null && id !== undefined) {
      this.projectService.getProject(this.projectService.getObjectFromSession(AppGlobals.USER_INFO).user_id, id, {basic:true}).subscribe(resp => {
        if(resp) {
          const project = <Project>resp[0];
          breadcrumb.label = project.name;
          breadcrumb.typeValue = project;
          this.projectId = project.project_id;
          this.isDataReady = true;
        }
      });
    }
  }

  private generateEntityBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    if (id !== null && id !== undefined) {
      this.contentSvc.getEntity(id, true, true).subscribe(resp => {
        breadcrumb.label = resp.name;
        breadcrumb.typeValue = resp;
        this.isDataReady = true;
      });
    }
  }

  private generateDatasourceBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    if (id !== null && id !== undefined) {
      this.datasourceSvc.getDatasource({
        userId: this.projectService.getObjectFromSession(AppGlobals.USER_INFO).user_id,
        tenant_id: this.projectService.getObjectFromSession(AppGlobals.USER_INFO).tenant_id,
        typeId: id
      }).subscribe(resp => {
        breadcrumb.label = resp.name;
        breadcrumb.typeValue = resp;
        this.isDataReady = true;
      });
    }
  }

  private generateDataSetBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    var id = child.snapshot.params.id;
    if (child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Schedule Refresh' || child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'User Entitlements') {
      id = +child.snapshot.queryParams.datasetId;
    }
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    if (id) {
      this.datasetSvc.getDataset({
          user_id: this.projectService.getObjectFromSession(AppGlobals.USER_INFO).user_id,
          tenant_id: this.projectService.getObjectFromSession(AppGlobals.USER_INFO).tenant_id,
          dataset_id: id
        }).subscribe(resp => {
        breadcrumb.label = resp.name;
        breadcrumb.typeValue = resp;
        this.isDataReady = true;
        this.observeSvc.setDataSetDetails = resp;
      });
    }
  }

  private generateReportBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    if (id !== null && id !== undefined) {
      this.reportSvc.getReports().subscribe((resp) => {
        resp = resp.find(report => report.reportid === id);
        breadcrumb.label = resp.reportname;
        breadcrumb.typeValue = resp;
        this.isDataReady = true;
      });
    }
  }

  private generateCatalogBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.catalog_id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.catalog_id;
    }
    if (id !== null && id !== undefined) {
      this.catalogSvc.getCatalog(id).subscribe(resp => {
        breadcrumb.label = resp.name;
        breadcrumb.typeValue = resp;
        this.isDataReady = true;
      });
    }
  } 
  
  private generateObjectBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {    
    let catalog_id = child.snapshot.pathFromRoot[2].params.catalog_id;
    let object_id = child.snapshot.params.object_id;
    if (object_id === null || object_id === undefined) {
      object_id = child.parent.snapshot.params.object_id;
    }
    
    if (object_id !== null && object_id !== undefined) {
      this.catalogSvc.getSemanticObject(catalog_id, object_id).subscribe(resp => {
          breadcrumb.label = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Synonyms' ? resp.semantic_object + '  >  ' + child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] : resp.semantic_object;
          breadcrumb.typeValue = resp;
          this.isDataReady = true;         
          sessionStorage.setItem("concept_name",breadcrumb.label);
      });
    }
  }

  private generateConceptBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let catalog_id = child.snapshot.pathFromRoot[3].params.catalog_id;
    let object_id = child.snapshot.params.object_id;
    let concept_id = child.snapshot.queryParams.attribute_id;
    if (concept_id === null || concept_id === undefined) { concept_id = child.snapshot.queryParams.concept_id; }
    if (object_id === null || object_id === undefined) { object_id = child.snapshot.queryParams.object_id; }
    if (catalog_id === null || catalog_id === undefined) { catalog_id = child.snapshot.pathFromRoot[2].params.catalog_id; }
    if (concept_id !== null && concept_id !== undefined) {
      this.catalogSvc.getConcept(catalog_id, object_id, concept_id).subscribe(resp => {
        if(child.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Synonyms' ) {
            this.object_id = object_id;
            breadcrumb.label = resp.logical_name + " > " + child.snapshot.data[this.ROUTE_DATA_BREADCRUMB]
        } else {
            breadcrumb.label = resp.logical_name
        }
        breadcrumb.typeValue = resp.logical_name;
        this.isDataReady = true;
      });
    }
  }

  private generateZsClProjectBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    if (id !== null && id !== undefined) {
      const data ={
        user_id : this.ZsClContentService.getObjectFromSession(AppGlobals.USER_INFO).user_id,
        tenant_id : this.ZsClContentService.getObjectFromSession(AppGlobals.USER_INFO).tenant_id,
        project_id : id
      }
      this.ZsClContentService.getclassifyProject(data).subscribe(resp => {
        const project = <Project>resp;
        breadcrumb.label = project.name;
        breadcrumb.typeValue = project;
        this.isDataReady = true;
      });
    }
  }
  private getDatasetColumnBreadCrumb(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
    let id = child.snapshot.params.id;
    let dataset_column_id;
    if (id === null || id === undefined) {
      id = child.parent.snapshot.params.id;
    }
    child.parent.queryParamMap.subscribe(params => {
      dataset_column_id = params.get('dataset_column_id');
    });

    if (id !== null && id !== undefined) {
       let user_id = this.ZsClContentService.getObjectFromSession(AppGlobals.USER_INFO).user_id;
       let tenant_id = this.ZsClContentService.getObjectFromSession(AppGlobals.USER_INFO).tenant_id;
      this.datasetSvc.getDatasetColumn(user_id,tenant_id, id,dataset_column_id).subscribe(resp => {
        breadcrumb.label = resp.column;
        breadcrumb.typeValue = resp;
        breadcrumb.url = `zs/datasets/${id}`
        this.isDataReady = true;
        this.breadcrumbs.push({
          "label": 'Manage Rule',
          "url": breadcrumb.url,
          "type":'',
          "typeValue": 'Manage Rule',
        });
      });
    }
  }

  // private generateWorkbookBreadcrumbLabel(child: ActivatedRoute, url: string, breadcrumb: Breadcrumb) {
  //   console.log(child)
  //   console.log(url)
  //   console.log(breadcrumb)
  //   console.log('Breadcrumb ......................')
  //   console.log(child.snapshot.params.id)
  //   var id = child.snapshot.params.id;
  //   if (id !== null && id !== undefined) {
  //     this.zmContentSvc.getWorkbooks(this.projectService.getObjectFromSession(AppGlobals.USER_INFO).user_id).subscribe(resp => {
  //       resp = resp.filter(item => item.WorkBookId == id)[0];
  //       console.log(resp)
  //       breadcrumb.label = resp.Name;
  //       breadcrumb.typeValue = resp;
  //       this.isDataReady = true;
  //       // this.observeSvc.setDataSetDetails = resp;
  //     });
  //   }
  //   console.log(breadcrumb)
  // }

checkEventTrigg(ind) {
  if(this.dqRuleSelected) {
    this.catalogSvc.defaultView.next(true);
  } else {
    return;
  }
}

mapUrls(breadcrumb, ind) {
  if(ind > 0 && this.router.url.includes('production-stage-details') && this.projectId) {
    breadcrumb.url = `/zs/projects/${this.projectId}/production-stage-details`;
  }
  if(this.showRecentLabel && this.breadcrumbs && this.breadcrumbs.length) {
    this.breadcrumbs[this.breadcrumbs.length-1]['label'] = 'Golden Records ';
  }
  return breadcrumb.url;
}

}