import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { ObservableService } from 'src/app/common/services/observable.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import * as d3 from 'd3';
import $ from 'jquery';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ZsClContentService } from '../../../zs-cl-content.service';

@Component({
  selector: 'zetta-zs-cl-pd-tasks',
  templateUrl: './zs-cl-tasks.component.html',
  styleUrls: ['./zs-cl-tasks.component.scss']
})
export class ZsClPdTasksComponent implements OnInit, AfterViewInit {

  public openTaskDS;
  public barChartUniqueID: any = Math.floor(Math.random() * 1000);
  public barChartWidth: number;
  public $barContainer;
  public barChartHeight: number;
  public isDataReady = false;
  public isChartInValid = false;
  public openTaskChartOptions: object = {
      'resizeRequired': true,
      'variable': 'value',
      'category': 'task',
      'isNumberFormat': true,
      'labelPercentValue': 68,
      'labelPercentLabel': 'Open Tasks',
      'isPercentRequired': true

  };
  public openTaskDataSource;
  private _project_entitlements = {};

  public barChartColors = ['#a13bf0', '#a13bf0', '#cca1ed', '#cca1ed'];
  public openTaskChartColors = ['#4bb100','#0e709f'];
  public userinfo;
  project_id:string;
  @Input() projectAdmin = false; 

  constructor(
    public activeRoute: ActivatedRoute,
    private router: Router,
    public cdRef: ChangeDetectorRef,
    public zettaUtils: ZettaUtils,
    public observeSvc: ObservableService,
    private _projectService: ProjectService,
    private zsClContentService: ZsClContentService) {
    this.userinfo = this._projectService.getObjectFromSession('userInfo');
  }

  ngOnInit() {
    this.activeRoute.parent.params.subscribe(params => {
      this.project_id = params['id'];
    });    
    this.observeSvc.getCurrentEntitlements.subscribe(data => {this._project_entitlements = data ? data :{};});
  }

  ngAfterViewInit() {
    const parent = this;
    parent.observeSvc.refreshDataGrid.subscribe(function (data) {
      setTimeout(() => {
        $('#chart_' + parent.barChartUniqueID + ' svg').remove();
        parent.renderBarChart();
      }, 10);
    });

    const pdata = {
      user_id: parent.userinfo.user_id,
      tenant_id: parent.userinfo.tenant_id,
      project_id: this.project_id
    }
    parent.zsClContentService.getClassifyProjGraphSummary(pdata).subscribe(
      data => {
        parent.isDataReady = true;
        parent.openTaskDS = parent.prepareBarChartData(data);
        parent.openTaskDataSource = parent.getDonutParseData(data);
        setTimeout(() => {
          parent.renderBarChart();
        }, 100);
      },
      error => {
        parent.isDataReady = true;
        parent.isChartInValid = true;
        parent.openTaskDS = [];
        parent.openTaskDataSource = [];
      }
    );
  }

  isHavingAccess(){
    if (this.zettaUtils.isProcessRunning || !this.projectAdmin) {
			return true;
		}
		else (this.projectAdmin && !this.zettaUtils.isProcessRunning)
		{
			return false;
		}
  }

  onResize(event) {

  }

  prepareBarChartData(data) {
  const dataSet = [];
    data.forEach((e: any) => {
      if (e['name'] === 'training_tasks_count_to_be_reviewed') {
        dataSet.push({ 'area': 'Classifier Training', 'value': e['value'] });
      } else if (e['name'] === 'training_tasks_type_fixing_count_to_be_reviewed') {
        dataSet.push({ 'area': 'Classifier Fixing', 'value': e['value'] });
      }
    });
    return dataSet;
  }

  getDonutParseData(data) {
    const dataSet = [];
    let totalTask=0;
    let reviewedTask=0;
    if(data.length){
      reviewedTask = data.find(item => item.name ==='training_tasks_total').value;
    }    
    data.forEach((element) => {
      if(element.name === 'training_tasks_completed'){
        totalTask =element.value;
      }
      if (element.name === 'training_tasks_total') {
          dataSet.push({ 'task': 'In Review', 'value': element.value});
        }
      if (element.name === 'training_tasks_completed') {
        dataSet.push({ 'task': 'Completed', 'value': element['value']});
      } 
      if (element.name === 'training_tasks_total') {
        this.openTaskChartOptions['labelPercentValue'] = element.value;
      }
    });
    return dataSet;
  }

  renderBarChart() {
    const parent = this;
    this.isChartInValid = false;
    parent.$barContainer =  $('.gray-task-container');

    parent.barChartHeight = parent.$barContainer.outerHeight(true);
    parent.barChartWidth = parent.$barContainer.outerWidth(true) - 10;
    if (parent.barChartWidth === undefined || parent.barChartHeight === undefined) {
           this.isChartInValid = true;
            return false;
    }
    let maxValue=0;
    if (this.openTaskDS && parent.openTaskDS.length > 0) {
       maxValue = Math.max.apply(Math, parent.openTaskDS.map(function(o) { return parseInt(o.value, 10); }));
      if (maxValue <= 0) {
          this.isChartInValid = true;
          return false;
      }
    }

    let  colour = d3.scaleOrdinal()
        .range(parent.barChartColors),
      margin = { top: 20, right: 10, bottom: 20, left: 79 },
      width = parseFloat(parent.barChartWidth.toString()) - margin.left - margin.right,
      height = parseFloat(parent.barChartHeight.toString()) - margin.top - margin.bottom;

    let svg = d3.select('#chart_' + parent.barChartUniqueID).append('svg:svg')
    .attr('width', parent.barChartWidth)
    .attr('height', parent.barChartHeight)

    let tooltip = d3.select('#chart_' + parent.barChartUniqueID).append('div').attr('class', 'toolTip');

    let x = d3.scaleLinear().range([0, width-10]);
    let y = d3.scaleBand().range([height-20, 0]);
    let tickCount = maxValue < 5 ? maxValue : 6
    var axis = d3.axisTop(x).ticks(tickCount).tickFormat(function(d) { return (d); }).tickSizeInner([- height]);

    let g = svg.append('g')
      .attr('transform', 'translate(' + (parent.barChartHeight * 0.68 ) + ',' + margin.top + ')');
    x.domain([0, d3.max(parent.openTaskDS, function(d) { return parseInt(d.value, 10); })]).nice();
    y.domain(parent.openTaskDS.map(function(d) { return d.area; })).padding(0.2);

    g.append('g')
      .attr('class', 'x axis x_axis')
      .attr('transform', 'translate(' + 10 + ',' + 0 + ')')
      .call(axis);

    g.append('g')
      .attr('class', 'y axis')      
      .call(d3.axisLeft(y).tickSize(0).tickSizeOuter(0))
      .selectAll('text')
      .attr('x', '-75')
      .attr('dy', '-0.1em')
      .style('text-anchor', 'start');

    let tickItems = svg.selectAll(".x_axis .tick");
    let maxTickPosValue = 0;
    tickItems.each(function(data,index) {
      if(tickItems.size()-1 === index){
        var tick = d3.select(this).attr("transform");
        maxTickPosValue = tick.substring(10, tick.indexOf(','));        
      }
    });

    const background = svg.selectAll(null)
      .data(parent.openTaskDS)
      .enter()
      .append('rect')
      .attr('x', function(d) {
        return parent.barChartHeight * 0.69;
      })
      .attr('transform', 'translate(' + 10 + ',' + 0 + ')')
      .attr('y', function(d, i) {
        return y(d.area) + 22;
      })
      .attr('height', y.bandwidth() - 10)
      .attr('width',maxTickPosValue)
      .attr('rx', 5)
      .attr('ry', 5)
      .style('fill', (d, i) => fillbgColor(parseFloat(d.value)));

      const bars = svg.selectAll(null)
      .data(parent.openTaskDS)
      .enter()
      .append('rect')
      .attr('x', function(d) {
        return parent.barChartHeight * 0.69;
      })
      .attr('transform', 'translate(' + 10 + ',' + 0 + ')')
      .attr('y', function(d, i) {
        return y(d.area) + 22;
      })
      .attr('height', y.bandwidth() - 10)
      .attr('width', function(d) {
        return x(d.value);
      })
      .attr('rx', 5)
      .style('stroke', (d, i) => getColor(d.area, i))
      .attr('fill', (d, i) => getColor(d.area, i))
        .on('mousemove', function(d){
                tooltip
                  .style('left', d3.event.clientX - 50 + 'px')
                  .style('top', d3.event.clientY - 40 + 'px')
                  .style('display', 'inline-block')
                  .html("<strong>"+d.area+":</strong> <span style='color:red'>" + d.value + "</span>");              
            })
            .on('mouseout', function(d){ tooltip.style('display', 'none');});

          function getColor(text, i) {
              text = text || '';
              switch (text.toLowerCase()) {
                  case 'Classifier Fixing':
                      return '#cca1ed';
                      break;
                  case 'Classifier Training':
                      return '#cca1ed';
                      break;
                  case 'merge training':
                      return '#a13bf0';
                      break;
                  default:
                      return '#a13bf0';
              }
          }

          function fillbgColor(value) {
           return value > 0 ? '#ddd' : 'none';
          }
    };

    navigateToTasks() {
      this.router.navigate(['../../tasks'], {relativeTo: this.activeRoute,
        queryParams: {showBreadcrumb: 'true', class: null}, queryParamsHandling: 'merge' }
        );
    }
}
