import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'zetta-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tableDataSource: any = [];
  tableSettings: object = { 'height': '100%', 'width': '100%', "pageSize": 5, "isNormalPagination": false };
  public dataSize: number = -1;
  constructor(public contentSvc: ContentService) { }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.contentSvc.getDataSourcesByID(1).subscribe((data) => {
      this.tableDataSource = data;
      this.dataSize = Object.keys(data).length;
    });
  }
}
