import { Component, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';

@Component({
	selector: 'ad-view-most-significant-attribute',
	templateUrl: './ad-view-most-significant-attribute.component.html',
	styleUrls: ['./ad-view-most-significant-attribute.component.scss']
})

export class AdViewMostSignificantAttributeComponent implements OnInit {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public screenHeight: number = screen.height;
	
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName: string = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	public entitiesChartOptions: object = {
		'resizeRequired': false,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'labelPercentValue' : 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence'
	};

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];

	public chartDS1 = null;
	public chartOptions1: object = {
		// 'chartID': 1,	//use only with subcharts
		'subchart': false,
		'height': 370,
		'width': 750,
		'resizeRequired': true,
		'labelY': '',
		'labelX': 'CORRELATION INDEX',
		'barColor1': '#00CB00',
		'barColor2': '#00a900',
		'margin' : { 'top': 50, 'right': 80, 'bottom': 10, 'left': 190 },
		'isMoneyFormat':true
	};
	
	public chartDS2 = null;
	public chartOptions2: object = {
		'height': 370,
		'width': 750,
		'resizeRequired': true,
		'labelY': '',
		'labelX': 'CORRELATION INDEX',
		'barColor1': '#EC43A2',
		'barColor2': '#EC43A2', 
		'margin' : { 'top': 50, 'right': 178, 'bottom': 10, 'left': 80 },
		'isMoneyFormat':true
	};

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZmZettaUtils,
		private _projectService: AnalyticService,
		public observeSvc: ZmObservableService,
		private activeRoute: ActivatedRoute, private router: Router) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;
		// parent.getEntityResolvedData(parent.userinfo.userid, routeParams.id);


		let dataSet1 = [
			{ 'label': '11k_Clone_Delete', 'value': 50 },
			{ 'label': 'Test_libpostal_project', 'value': 120 },
			{ 'label': 'Test_libpostal_project222', 'value': 300 },
			{ 'label': 'Dummey_Resolve', 'value': 390 },
			{ 'label': 'Generated Data 5K_Veda', 'value': 485 },
			{ 'label': 'Institution Real Data 11K', 'value': 530 },
			{ 'label': 'Generated Data 3K_Veda', 'value': 623 },
			{ 'label': 'Test_libpostal_project 8K', 'value': 710 },
			{ 'label': 'Institution Real Data 15K', 'value': 1000 },
		  ];
		  parent.chartDS1 = dataSet1;

		
		let dataSet2 = [
			{ 'label': '11k_Clone_Delete', 'value': -830 },
			{ 'label': 'Test_libpostal_project', 'value': -710 },
			{ 'label': 'Test_libpostal_project222', 'value': -623 },
			{ 'label': 'Dummey_Resolve', 'value': -530 },
			{ 'label': 'Generated Data 5K_Veda', 'value': -400 },
			{ 'label': 'Institution Real Data 11K', 'value': -290 },
			{ 'label': 'Generated Data 3K_Veda', 'value': -215 },
			{ 'label': 'Test_libpostal_project 8K', 'value': -185 },
			{ 'label': 'Institution Real Data 15K', 'value': -80 },
		  ];
		  parent.chartDS2 = dataSet2;


		// parent._projectService.getEntitiesSummaryData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.statisticsObj = {
		// 			'model_runs': 0,
		// 			'model_runs_as_of': new Date(),
		// 			'unique_institutions': 0,
		// 			'compression_ratio': 'N/A'
		// 		};
				
		// 	for (let index = 0; index < data.length; index++) {
		// 		const obj = data[index];
		// 		if (obj.name === 'run_number') {
		// 			parent.statisticsObj['model_runs'] = obj['value'] || 0;
		// 		}
		// 		else if (obj.name === 'as_of') {
		// 			parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
		// 		} else if (obj.name === 'total_entities') {
		// 			parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
		// 		} else if (obj.name === 'compression_ratio') {
		// 			parent.statisticsObj['compression_ratio'] = obj['count'] || 'N/A';
		// 		} else if (obj.name === 'overall_confidence') {
		// 			parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
		// 			parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
		// 		}
		// 	}
		// 		parent.entitiesDataSource = parent.getDonutParseData(data);
		// 	},
		// 	error => {
		// 		parent.entitiesDataSource = [];
		// 		parent.statisticsObj = {'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 'N/A'};
		// 	}
		// );

	}

	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
				this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	// prepareLineChartData(data) {
	//     const dataSet = [];
	//     data.forEach((element) => {
	//     	const d = new Date(element['createdts']);
	//         dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
	//     });
	//     return dataSet;
	//   }
	  
	  refresh() {
		window.location.reload();
	  }
}
