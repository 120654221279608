import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DatasourceService } from '../datasource.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Project } from '../../../../zettasense/content/project/project.model';
import { ZettaUtils } from './../../../../common/shared/zettaUtils';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from './../../../../common/components/project-progress/project-progress.model';
import { Datasource } from '../datasource.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { INVALID } from '@angular/forms/src/model';

@Component({
  selector: 'zetta-define-connection',
  templateUrl: './define-connection.component.html',
  styleUrls: ['./define-connection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefineConnectionComponent implements OnInit, OnDestroy {

  isDataReady = false;
  progressState = new ProjectStatus();
  showBreadcrumb = false;
  editView = false;
  loggedInUserDetails: [];
  datasource: any = {};
  testSuccess = false;
  testFailure = false;
  noValidate = false;
  defineConnectionForm: FormGroup;
  systemTypes: [];
  formData: any;
  rootDir: string;
  StorageLayers:object =[];
  selected_storage_layer:number=-1;
  selected_DB_subtype: number = -1;
  isInModifyConnectionPage: boolean = false;

  constructor(private datasourceSvc: DatasourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public zettaUtils: ZettaUtils,
    private formBuilder: FormBuilder,
    private messageSvc: MessageService,
    private location:Location) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.datasource.datasource_id = this.activatedRoute.snapshot.queryParamMap.get('id');
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    const editView = this.activatedRoute.snapshot.queryParamMap.get('editView');
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('sub_type_id')) {
        this.selected_storage_layer = +params.get('sub_type_id');
      }
    });

    this.isInModifyConnectionPage = this.location.path() && this.location.path().includes('modify-connection') ? true: false;
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (this.datasource.datasource_id) {
      this.getDatasource();
    }
    this.setConnectionForm();

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    if(editView) {
      this.noValidate = false;
      this.editView = true;
      
    }
   
    this.progressState.states = this.zettaUtils.getStateList('create-datasource');
    this.progressState.currentStateIndex = 1;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Source';
    this.progressState.isEdit = (this.datasource.datasource_id !== undefined && this.datasource.datasource_id !== null) ? true : false;
    this.progressState.isEdit = sessionStorage.getItem('newDataSource') === 'true' ? false : this.progressState.isEdit;
  }

  getDatasource() {
    this.datasourceSvc.getDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(responseList => {
      this.datasource = responseList;
      this.datasource.lookup_id = this.datasource['datasource_type_id'];
      this.progressState.datasource = this.datasource;
      this.isDataReady = true;
      if (this.datasource['datasource_type_id'] == AppGlobals.RELATIONAL_DATABASE) {
        this.getRDBMSSUbTypes(this.selected_storage_layer);
      } else {
        this.getStorageLayers(this.selected_storage_layer);      
      }
      // this.datasource.datasource_type = 'Snowflake Data Warehouse';
      this.setConnectionForm();
    }, error => {
      this.messageSvc.sendMessage({ message: 'Get datasource has failed', type: 'INFO', hideboard: true });
    });
  }

  getRDBMSSUbTypes(database_system) {
    this.datasourceSvc.getDataCloudStorageLayer('DatasourceSubTypes_RDBMS').subscribe(responseList => {
      this.systemTypes = responseList;
      if (this.datasource.lookup_id == AppGlobals.RELATIONAL_DATABASE) {
        if (responseList.length) {
          responseList.forEach(element => {
            if (element.lookup_id == AppGlobals.MICROSOFT_SQL_SERVER) {
              element.value = 'MsSQL';
            }
            if (element.lookup_id == AppGlobals.ORACLE) {
              element.value = 'Oracle';
            }
            if (element.lookup_id == AppGlobals.MYSQL) {
              element.value = 'MySQL';
            }
            if (element.lookup_id == AppGlobals.POSTGRES_SQL) {
              element.value = 'Postgresql';
            }
          });
          this.selected_DB_subtype = responseList.find(ds => ds.lookup_id == database_system || ds.name == database_system).value;
        }
      }
    }, error => {
      this.messageSvc.sendMessage({ message: 'Get system types has failed', type: 'INFO', hideboard: true });
    });
  }

  setConnectionForm() {
    var dirpath ="data/"+this.loggedInUserDetails['tenant_id']+"/raw";
    var cnn_string;
    var hadoopaccesskey;
    var storage_provider;
    var storage_account;
    var storage_key;
    var secrete_access_key;
    var bucket_name;
    var region;
    var db_system;
    var connection_method;
    var host;
    var driver;
    var warehouse;
    var schemaName;
    var accountName;
    var user_name;
    var username;
    var server;
    var password;
    var database;
    var role;
    var ssl;
    var account;
    var access_key;
    var accesskey;
    var container;
    var root_directory;
    var schema;
    var database_system;
    if(this.datasource.datasource_type === AppGlobals.HADOOP) {
        account = (this.datasource.connection_param.account === "undefined" || !this.datasource.connection_param.account) ? "" : this.datasource.connection_param.account;
        accesskey = (this.datasource.connection_param.accesskey === "undefined" || !this.datasource.connection_param.accesskey) ? "" : this.datasource.connection_param.accesskey;
        container = (this.datasource.connection_param.container === "undefined" || !this.datasource.connection_param.container) ? "" : this.datasource.connection_param.container;
        root_directory = (this.datasource.connection_string === "undefined" || !this.datasource.connection_string) ? "" : this.datasource.connection_string;
        cnn_string = (this.datasource.connection_string === "undefined" || !this.datasource.connection_string) ? "" : this.datasource.connection_string;        
        if(!account || !accesskey || !container || !root_directory || !cnn_string) {
          this.noValidate = false;
          this.testSuccess = false;
        }
      } else if(this.datasource.datasource_type === AppGlobals.CLOUDSTORAGE) {
          if(this.datasource.datasource_sub_type_id === 327){
            storage_provider = this.datasource.connection_param.storage_provider;
            storage_account = this.datasource.connection_param.account;
            bucket_name = this.datasource.connection_param.container;
            storage_key = this.datasource.connection_param.accesskey;
            cnn_string = this.datasource.connection_param.root_directory;
            region ='';
            if(!storage_provider || !storage_account || !bucket_name || !storage_key || !cnn_string) {
              this.noValidate = false;
              this.testSuccess = false;
            }
          } else{
            storage_provider = (this.datasource.connection_param.storage_provider === "undefined" || !this.datasource.connection_param.storage_provider) ? "AWS" : this.datasource.connection_param.storage_provider;
            storage_account = (this.datasource.connection_param.storage_account === "undefined" || !this.datasource.connection_param.storage_account) ? "" : this.datasource.connection_param.storage_account;
            storage_key = (this.datasource.connection_param.storage_key === "undefined" || !this.datasource.connection_param.storage_key) ? "" : this.datasource.connection_param.storage_key;
            bucket_name = (this.datasource.connection_param.bucket_name === "undefined" || !this.datasource.connection_param.bucket_name) ? "" : this.datasource.connection_param.bucket_name;
            region = (this.datasource.connection_param.region === "undefined" || !this.datasource.connection_param.region) ? "" : this.datasource.connection_param.region;
            secrete_access_key = this.datasource.connection_param.secrete_access_key;
            if(this.datasource.connection_string !== "" && this.datasource.connection_string !== undefined && this.datasource.connection_param.root_directory == undefined) {
              cnn_string = this.datasource.connection_string;
            }
            if(this.datasource.connection_string !== "" && this.datasource.connection_string !== undefined && this.datasource.connection_param.root_directory !== undefined && this.datasource.connection_param.root_directory !== "undefined") {
              cnn_string = this.datasource.connection_param.root_directory;
            } else {
            // cnn_string = "data/" + this.loggedInUserDetails['tenant_id'] + "/raw";
            }
            if(!storage_provider || !storage_account || !bucket_name || !storage_key || !region || !secrete_access_key || !cnn_string) {
              this.noValidate = false;
              this.testSuccess = false;
            }
       }  
    } else if(this.datasource.datasource_type === AppGlobals.RDBMS) {
      database_system = (this.datasource.connection_param.database_system === undefined || this.datasource.connection_param.database_system === "undefined") ? "Postgres" : this.datasource.connection_param.database_system;
      connection_method = (this.datasource.connection_param.connection_method === undefined || this.datasource.connection_param.connection_method === "undefined") ? "JDBC" : this.datasource.connection_param.connection_method;
      driver = this.datasource.connection_param.driver === "undefined" ? "" : this.datasource.connection_param.driver;
      host = this.datasource.connection_param.server === "undefined" ? "" : this.datasource.connection_param.server;
      user_name = this.datasource.connection_param.user_name === "undefined" ? "" : this.datasource.connection_param.user_name;
      password = this.datasource.connection_param.password === "undefined" ? "" : this.datasource.connection_param.password;
      database = this.datasource.connection_param.database === "undefined" ? "" : this.datasource.connection_param.database;
      schema = this.datasource.connection_param.schema === "undefined" ? "" : this.datasource.connection_param.schema;
      ssl = this.datasource.connection_param.database === "undefined" ? true : this.datasource.connection_param.ssl;
      if(!database_system || !connection_method || !driver || !host || !user_name || !password || !database || !ssl) {
        this.noValidate = false;
        this.testSuccess = false;
      }
    } else if(this.datasource.datasource_type == AppGlobals.SFDB) {
      db_system = (this.datasource.connection_param.db_system == "undefined"||this.datasource.connection_param.db_system == undefined) ? "SnowFlake" : this.datasource.connection_param.db_system;
      connection_method = (this.datasource.connection_param.connection_method == undefined || this.datasource.connection_param.connection_method == "undefined") ? "JDBC" : this.datasource.connection_param.connection_method;
      warehouse = (this.datasource.connection_param.warehouse == undefined || this.datasource.connection_param.warehouse == "undefined") ? "" : this.datasource.connection_param.warehouse;
      schemaName = (this.datasource.connection_param.schemaName == undefined || this.datasource.connection_param.schemaName == "undefined") ? "" : this.datasource.connection_param.schemaName;
      accountName = (this.datasource.connection_param.account == undefined || this.datasource.connection_param.account == "undefined") ? "" : this.datasource.connection_param.account;
      server = (this.datasource.connection_param.server == undefined || this.datasource.connection_param.server == "undefined") ? "" : this.datasource.connection_param.server;
      username = (this.datasource.connection_param.username == undefined || this.datasource.connection_param.username == "undefined") ? "" : this.datasource.connection_param.username;
      password = (this.datasource.connection_param.password == undefined || this.datasource.connection_param.password == "undefined") ? "" : this.datasource.connection_param.password;
      database = (this.datasource.connection_param.database == undefined || this.datasource.connection_param.database == "undefined") ? "" : this.datasource.connection_param.database;
      role = (this.datasource.connection_param.role == undefined || this.datasource.connection_param.role == "undefined") ? "" : this.datasource.connection_param.role;
      ssl = this.datasource.connection_param.database == "undefined" ? true : this.datasource.connection_param.ssl;
      if(!db_system || !connection_method || !warehouse || !schemaName || !accountName || !server || !username || !password || !database || !ssl) {
        this.noValidate = false;
        this.testSuccess = false;
      }
  }

    if(this.datasource.datasource_type === AppGlobals.CLOUDSTORAGE) {
        this.defineConnectionForm = this.formBuilder.group({
          storage_provider: [storage_provider, Validators.required],
          storage_account: [storage_account, Validators.required],
          storage_key: [storage_key, Validators.required],
          secrete_access_key: [secrete_access_key, Validators.required],
          bucket_name: [bucket_name, Validators.required],
          region: [region, Validators.required],
          root_directory: [cnn_string, Validators.required],
        });
        if(!storage_provider || !storage_account || !storage_key || !secrete_access_key || !bucket_name || !region || !root_directory) {
          this.noValidate = false;
          this.testSuccess = false;
        }
    } else if(this.datasource.datasource_type === AppGlobals.HADOOP) {
        this.defineConnectionForm = this.formBuilder.group({
          account: [account, Validators.required],
          access_key: [accesskey, Validators.required],
          container: [container, Validators.required],
          root_directory: [root_directory],
        });
        if(!account || !access_key || !container) {
          this.noValidate = false;
          this.testSuccess = false;
        }
    } else if(this.datasource.datasource_type === AppGlobals.RDBMS) {
        this.defineConnectionForm = this.formBuilder.group({
          database_system: [database_system, Validators.required],
          connection_method: [connection_method, Validators.required],
          driver: [driver, Validators.required],
          host: [host, Validators.required],
          user_name: [user_name, Validators.required],
          password: [password, Validators.required],
          database: [database, Validators.required],
          schema: [schema, Validators.required],
          ssl: [true]
        });
        if(!database_system || !connection_method || !driver || !host || !user_name || !password || !database) {
          this.noValidate = false;
          this.testSuccess = false;
        }
  } else if(this.datasource.datasource_type === AppGlobals.SFDB) {
    this.defineConnectionForm = this.formBuilder.group({
      db_system: [db_system, Validators.required],
      connection_method: [connection_method, Validators.required],
      warehouse: [warehouse, Validators.required],
      schemaName: [schemaName, Validators.required],
      username: [username, Validators.required],
      password: [password, Validators.required],
      database: [database, Validators.required],
      accountName: [accountName, Validators.required],
      server: [server, Validators.required],
      role: [role ],
      ssl: [true]
    });
    if(!db_system || !connection_method || !warehouse || !schemaName || !username || !password || !database || !accountName || !server) {
      this.noValidate = false;
      this.testSuccess = false;
    }
  }

  if(this.showBreadcrumb || this.editView) {
    this.noValidate = true;
  }
    // if(this.defineConnectionForm && this.defineConnectionForm.valueChanges) {
    //   this.defineConnectionForm.valueChanges.subscribe(formVal => {
    //     this.noValidate = false;
    //     this.testSuccess = false;
    // });
  //}
}

validateDatasourceConnection (isInModifyConnectionPage,context,datasourcePayload, connectionPayload) {
  
  context.datasourceSvc.testAndValidate({ userId: context.loggedInUserDetails['user_id'], tenant_id: context.loggedInUserDetails['tenant_id'], sourceId: context.datasource.datasource_id, sourceType: context.datasource.datasource_type,cloudProvider:context.selected_storage_layer, payload: connectionPayload }).subscribe(responseList => {
    
    if (isInModifyConnectionPage) {
      // update ds connection params
      context.datasourceSvc.updateDefineConnection({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], sourceId: this.datasource.datasource_id, payload: datasourcePayload}).subscribe((resp) => {
        context.testSuccess = true;
        context.isDataReady = true;
        context.noValidate = true;
        context.testFailure = false;
       // context.messageSvc.sendMessage({ message: 'Test and validate has failed', type: 'INFO', hideboard: true });
      }, (err) => {
        context.messageSvc.sendMessage({ message: 'Something went wrong. try again later', type: 'INFO', hideboard: true });
      })
    } else {
      context.testSuccess = true;
      context.isDataReady = true;
      context.noValidate = true;
      context.testFailure = false;
    }
  }, (err) => {
    context.isDataReady = true;
    context.testFailure = true;
    context.noValidate = false;
    context.messageSvc.sendMessage({ message: 'Test and validate has failed', type: 'INFO', hideboard: true });
  });
}

  testConnection() {
      var formData = {};
      if(this.datasource.datasource_type === AppGlobals.CLOUDSTORAGE) {
        formData = {
            account: this.defineConnectionForm.controls.storage_account.value,
            access_key: this.defineConnectionForm.controls.storage_key.value,
            secrete_access_key:this.selected_storage_layer === 328 ? this.defineConnectionForm.controls.secrete_access_key.value : '',
            container: this.defineConnectionForm.controls.bucket_name.value,
            root_directory: this.defineConnectionForm.controls.root_directory.value
        }
        this.formData = {
          connection_param: {
            storage_provider: this.defineConnectionForm.controls.storage_provider.value,
            storage_account: this.defineConnectionForm.controls.storage_account.value,
            storage_key: this.defineConnectionForm.controls.storage_key.value,
            bucket_name: this.defineConnectionForm.controls.bucket_name.value,
            region: this.defineConnectionForm.controls.region.value,
            root_directory: this.defineConnectionForm.controls.root_directory.value,
          },
          connection_string: this.defineConnectionForm.controls.root_directory.value
        };
      } else if(this.datasource.datasource_type === AppGlobals.HADOOP) {
        formData = {
            account: this.defineConnectionForm.controls.account.value,
            access_key: this.defineConnectionForm.controls.access_key.value,
            container: this.defineConnectionForm.controls.container.value,
            root_directory: this.defineConnectionForm.controls.root_directory.value
        }
        this.formData = {
          connection_param: {
            account: this.defineConnectionForm.controls.account.value,
            accesskey: this.defineConnectionForm.controls.access_key.value,
            container: this.defineConnectionForm.controls.container.value,
            root_directory: this.defineConnectionForm.controls.root_directory.value,
          },
          connection_string: this.defineConnectionForm.controls.root_directory.value      
        };
      } else if(this.datasource.datasource_type === AppGlobals.RDBMS) {
        formData = {
            server: this.defineConnectionForm.controls.host.value,
            database: this.defineConnectionForm.controls.database.value,
            user_name: this.defineConnectionForm.controls.user_name.value,
            password: this.defineConnectionForm.controls.password.value,
            port: 5432,
            database_system: this.defineConnectionForm.controls.database_system.value,
            connection_method: this.defineConnectionForm.controls.connection_method.value,
            driver: this.defineConnectionForm.controls.driver.value,
            schema: this.defineConnectionForm.controls.schema.value,
            ssl: this.defineConnectionForm.controls.ssl.value,
        }
        this.formData = { 
          connection_param: {
            server: this.defineConnectionForm.controls.host.value,
            database: this.defineConnectionForm.controls.database.value,
            user_name: this.defineConnectionForm.controls.user_name.value,
            password: this.defineConnectionForm.controls.password.value,
            port : 5432,
            database_system: this.defineConnectionForm.controls.database_system.value,
            connection_method: this.defineConnectionForm.controls.connection_method.value,
            driver: this.defineConnectionForm.controls.driver.value,
            ssl: this.defineConnectionForm.controls.ssl.value,
            schema: this.defineConnectionForm.controls.schema.value
          }
        };
      } else if(this.datasource.datasource_type === AppGlobals.SFDB) {
        formData = {
          schema: this.defineConnectionForm.controls.schemaName.value,
            database: this.defineConnectionForm.controls.database.value,
            username: this.defineConnectionForm.controls.username.value,
            password: this.defineConnectionForm.controls.password.value,
            database_system: this.defineConnectionForm.controls.db_system.value,
            connection_method: this.defineConnectionForm.controls.connection_method.value,
            warehouse: this.defineConnectionForm.controls.warehouse.value,
            // schema: "zettasense",
            ssl: this.defineConnectionForm.controls.ssl.value,
            account: this.defineConnectionForm.controls.accountName.value,
            server: this.defineConnectionForm.controls.server.value,
            role: this.defineConnectionForm.controls.role.value,
        }
        this.formData = { 
          connection_param: {
            schemaName: this.defineConnectionForm.controls.schemaName.value,
            database: this.defineConnectionForm.controls.database.value,
            account: this.defineConnectionForm.controls.accountName.value, 
            username: this.defineConnectionForm.controls.username.value,
            password: this.defineConnectionForm.controls.password.value,
            database_system: this.defineConnectionForm.controls.db_system.value,
            connection_method: this.defineConnectionForm.controls.connection_method.value,
            warehouse: this.defineConnectionForm.controls.warehouse.value,
            ssl: this.defineConnectionForm.controls.ssl.value,
            server: this.defineConnectionForm.controls.server.value,
            schema: this.defineConnectionForm.controls.schemaName.value,
            role: this.defineConnectionForm.controls.role.value,
          }
        };
      }  
    this.isDataReady = false;    

    this.validateDatasourceConnection(this.isInModifyConnectionPage, this, this.formData, formData);

  

} 
  
  onNext(updateCond) {
    var formData = {};
    let connection_param_obj = {};
    if(this.datasource.datasource_type === AppGlobals.CLOUDSTORAGE) {
      if (this.selected_storage_layer === 327) {
        connection_param_obj = {
          storage_provider: this.defineConnectionForm.controls.storage_provider.value,
          account: this.defineConnectionForm.controls.storage_account.value,
          accesskey: this.defineConnectionForm.controls.storage_key.value,
          container: this.defineConnectionForm.controls.bucket_name.value,
          root_directory: this.defineConnectionForm.controls.root_directory.value
        };
      }
      if(this.selected_storage_layer === 328){
        connection_param_obj = {
          storage_provider: this.defineConnectionForm.controls.storage_provider.value,
          storage_account: this.defineConnectionForm.controls.storage_account.value,
          storage_key: this.defineConnectionForm.controls.storage_key.value,
          secrete_access_key: this.defineConnectionForm.controls.secrete_access_key.value,
          bucket_name: this.defineConnectionForm.controls.bucket_name.value,
          region: this.defineConnectionForm.controls.region.value,
          root_directory: this.defineConnectionForm.controls.root_directory.value,
        };
      }
      formData = {
          account: this.defineConnectionForm.controls.storage_account.value,
          access_key: this.defineConnectionForm.controls.storage_key.value,
          container: this.defineConnectionForm.controls.bucket_name.value,
          root_directory: this.defineConnectionForm.controls.root_directory.value,
      }
      this.formData = {
        datasource_sub_type_id: this.selected_storage_layer,
        connection_param: { ...connection_param_obj },
        // connection_param: {
        //   storage_provider: this.defineConnectionForm.controls.storage_provider.value,
        //   storage_account: this.defineConnectionForm.controls.storage_account.value,
        //   storage_key: this.defineConnectionForm.controls.storage_key.value,
        //   secrete_access_key: this.selected_storage_layer === 328 ? this.defineConnectionForm.controls.secrete_access_key.value : '',
        //   bucket_name: this.defineConnectionForm.controls.bucket_name.value,
        //   region: this.defineConnectionForm.controls.region.value,
        //   root_directory: this.defineConnectionForm.controls.root_directory.value,
        // },
        connection_string: this.defineConnectionForm.controls.root_directory.value
      };
    } else if(this.datasource.datasource_type === AppGlobals.HADOOP) {
      formData = {
          account: this.defineConnectionForm.controls.account.value,
          access_key: this.defineConnectionForm.controls.access_key.value,
          container: this.defineConnectionForm.controls.container.value,
          root_directory: this.defineConnectionForm.controls.root_directory.value
      }
      this.formData = {
        connection_param: {
          account: this.defineConnectionForm.controls.account.value,
          accesskey: this.defineConnectionForm.controls.access_key.value,
          container: this.defineConnectionForm.controls.container.value,
          root_directory: this.defineConnectionForm.controls.root_directory.value,
        },
        connection_string: this.defineConnectionForm.controls.root_directory.value      
      };
    } else if(this.datasource.datasource_type === AppGlobals.RDBMS) {
      formData = {
          server: this.defineConnectionForm.controls.host.value,
          database: this.defineConnectionForm.controls.database.value,
          user_name: this.defineConnectionForm.controls.user_name.value,
          password: this.defineConnectionForm.controls.password.value,
          port: 5432,
          database_system: this.defineConnectionForm.controls.database_system.value,
          connection_method: this.defineConnectionForm.controls.connection_method.value,
          driver: this.defineConnectionForm.controls.driver.value,
          schema: this.defineConnectionForm.controls.schema.value,
          ssl: this.defineConnectionForm.controls.ssl.value,
      }
      this.formData = { 
        connection_param: {
          server: this.defineConnectionForm.controls.host.value,
          database: this.defineConnectionForm.controls.database.value,
          user_name: this.defineConnectionForm.controls.user_name.value,
          password: this.defineConnectionForm.controls.password.value,
          port : 5432,
          database_system: this.defineConnectionForm.controls.database_system.value,
          connection_method: this.defineConnectionForm.controls.connection_method.value,
          driver: this.defineConnectionForm.controls.driver.value,
          ssl: this.defineConnectionForm.controls.ssl.value,
          schema: this.defineConnectionForm.controls.schema.value
        }
      };
    } else if(this.datasource.datasource_type === AppGlobals.SFDB) {
      formData = {
        schema: this.defineConnectionForm.controls.schemaName.value,
          database: this.defineConnectionForm.controls.database.value,
          username: this.defineConnectionForm.controls.username.value,
          password: this.defineConnectionForm.controls.password.value,
          database_system: this.defineConnectionForm.controls.db_system.value,
          connection_method: this.defineConnectionForm.controls.connection_method.value,
          warehouse: this.defineConnectionForm.controls.warehouse.value,
          ssl: this.defineConnectionForm.controls.ssl.value,
          account: this.defineConnectionForm.controls.accountName.value,
          server: this.defineConnectionForm.controls.server.value,
          role: this.defineConnectionForm.controls.role.value,
      }
      this.formData = { 
        connection_param: {
          schemaName: this.defineConnectionForm.controls.schemaName.value,
          database: this.defineConnectionForm.controls.database.value,
          account: this.defineConnectionForm.controls.accountName.value, 
          username: this.defineConnectionForm.controls.username.value,
          password: this.defineConnectionForm.controls.password.value,
          database_system: this.defineConnectionForm.controls.db_system.value,
          connection_method: this.defineConnectionForm.controls.connection_method.value,
          warehouse: this.defineConnectionForm.controls.warehouse.value,
          ssl: this.defineConnectionForm.controls.ssl.value,
          server: this.defineConnectionForm.controls.server.value,
          schema: this.defineConnectionForm.controls.schemaName.value,
          role: this.defineConnectionForm.controls.role.value,
        }
      };
    }
              //Update the DB
    this.datasourceSvc.updateDefineConnection({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], sourceId: this.datasource.datasource_id, payload: this.formData }).subscribe(response => {
    if(updateCond) {
      this.router.navigate([`/zs/datasources/${this.datasource.datasource_id}`]);
    } else {
      this.router.navigate(['/zs/datasources', this.datasource.datasource_id, 'add-admin-users'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb, sub_type_id:this.selected_storage_layer }, queryParamsHandling:'merge' });
    }
    
    }, err => {
      this.messageSvc.sendMessage({ message: 'The update has failed', type: 'INFO', hideboard: true });
    });
  }

  onPrevious() {
    this.router.navigate(['/zs/datasources/create-datasource'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

  updateInfo() {
    this.onNext(true);
  }

  getStorageLayers(storage_provider){
    this.datasourceSvc.getDataCloudStorageLayer('DatasourceSubTypes_HDFS').subscribe(resp=>{
    this.StorageLayers = resp;
    if(this.progressState.isEdit && this.progressState['datasource_type_id'] == 100){
      if (resp.length) {
        this.selected_storage_layer  = resp.find(ds => ds.lookup_id == storage_provider || ds.name == storage_provider).lookup_id;
      }
    }
    },error=>{
    });
  }

  detectFormValueChanges(controlName){
    if(this.defineConnectionForm && this.defineConnectionForm.controls[controlName].valueChanges) {
      this.noValidate = false;
      this.testSuccess = false;
   }
  }
}
