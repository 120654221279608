import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';

@Component({
  selector: 'app-assignment-status-formatter',
  template: `
    <div [style.height]="height" [style.borderLeft]="'6px solid ' + getTrainResolveModelColorByStatus()" class="text-container">
      <span class="ml-2" [title]="value">{{ value }}</span>
    </div>
  `,
  styles: [`
    .text-container {
      height: 100%; /* Ensure the container fills the cell height */
      display: flex; /* Use flexbox for layout */
      align-items: center; /* Vertically center the text */
      justify-content: flex-start; /* Align text to the start */
      user-select: text; /* Allow text selection */
    }
  `]
})
export class AssignmentStatusFormatterComponent implements ICellRendererAngularComp {
  value: any;
  height: string;

  constructor(private zettaUtilsInstance: ZettaUtils) {}

  agInit(params: any): void {
    this.value = params.value;
    this.height = '100%'; // Fill the cell height
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  getTrainResolveModelColorByStatus(): string {
    const zettaUtilsInstance = new ZettaUtils();
    return zettaUtilsInstance.getTrainResolveModelColorByStatus(this.value);
  }
}
