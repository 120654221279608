import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter,OnDestroy } from '@angular/core';
import { StatisticService } from 'src/app/zettasense/content/statistics/statistics.service';
import { Project } from 'src/app/zettasense/content/project/project.model';

@Component({
  selector: 'zetta-zs-cl-training-statistics',
  templateUrl: './zs-cl-training-statistics.component.html',
  styleUrls: ['./zs-cl-training-statistics.component.scss']
})
export class ZsClTrainingStatisticsComponent implements OnInit, OnDestroy  {
  loggedInUserDetails;
  info:any = [];
  projectDetail = new Project();
  isLeftSideBarActive = true;
  projId;
  @Input() pickertype: string;
  @Input() summaryfor: string;
  @Input() summaryForAllTaskType: boolean = false;
  @Output() task_completed :EventEmitter<number> = new EventEmitter();


  constructor(
      private staticRailService: StatisticService,
      private activatedRoute: ActivatedRoute,
      private _router :Router,
      private zettaUtils: ZettaUtils) {
      this.loggedInUserDetails = this.staticRailService.getObjectFromSession('userInfo');
  }

  getStatisticBarStyle() {
      if (!this.isLeftSideBarActive) {
          return 'side-bar-expanded';
      } else {
          return 'side-bar-collapsed';
      }
  }

  ngOnInit() {
      this.activatedRoute.parent.params.subscribe(params => {
          this.projectDetail.project_id = params['id'];
          this.projId = params['id'];
      });
      if (this._router.url.includes('fix-issues') || this.summaryForAllTaskType) {
          this.getFixIssuesStatistics();
      } else {
          this.staticRailService.isStatisticDataChanged.subscribe(value => {
              if (value) {
                  this.staticRailService.getTrainModalSUmmary(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projectDetail.project_id)
                      .subscribe(res => {
                          if (res) {
                              this.info = res;
                              const completed_task = res.find(task => task.name.toLowerCase() == 'training_tasks_completed'.toLowerCase());
                              if (completed_task) {
                                  this.task_completed.emit(completed_task.value);
                              }
                          }
                      }, error => { });
              }
          });
       }  
  }

  ngOnDestroy(): void {
    this.projId = null;
  }


  currencyFormat(input) {
      return this.zettaUtils.currencyFormat(input);
  }

  getFixIssuesStatistics() {
    // valdiate later on multiple api call
      this.staticRailService.isStatisticDataChanged.subscribe(value => {
          if (value && this.projId) {
              this.staticRailService.getFixIssuesSUmmary(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, this.summaryForAllTaskType)
                  .subscribe(res => {
                      if (res) {
                          this.info = res;
                          const completed_task = res.find(task => task.name.toLowerCase() == 'training_tasks_completed'.toLowerCase());
                          if (completed_task) {
                              this.task_completed.emit(completed_task.value);
                          }
                      }
                  }, error => { });
          }
      });
 }
}
