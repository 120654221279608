import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'zetta-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit{
    activeLnk='Documents';
    ngOnInit(){
        if(sessionStorage.getItem('activeLnk')){
            this.activeLnk =sessionStorage.getItem('activeLnk');
        }
    }
    getAPI(activeLink: string) {
        this.activeLnk = activeLink;
        sessionStorage.setItem('activeLnk', activeLink);
    }

}