import { Component, OnInit, Output, EventEmitter} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularGridInstance } from "angular-slickgrid";
import { ZsClContentService } from "../../../zs-cl-content.service";
import { environment } from 'src/environments/environment';
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { MessageService } from "src/app/common/components/message/message.service";
import { radioButtonNewFormatter } from "src/app/common/shared/formatters/radioButtonNewFormatter";

@Component({
    selector: 'zetta-object-classifier',
    templateUrl: './zs-cl-object-classifier.component.html',
    styleUrls: ['./zs-cl-object-classifier.component.scss']
})
export class ZsClObjectClassifier implements OnInit {
    isDataReady = false;
    objectList: any;
    gridColumnsDef: any = [];
    tableSettings: object = { 'height': "100%", 'width': "99.70%" };
    gridOptions: any;
    objectGrid: AngularGridInstance;
    loggedInUserDetails = null;
    project_id: number;
    total = 0;
    grandTotal = 0;
    subTotal = '';
    catalog_id: number;
    object_id: number;
    page = 1;
    limit = 100;
    object_selected = false;
    hasScrolled = false;
    noData = false;
    project_classifier_id: number;
    concept_id:number;    
    projecData=null;
    constructor(private activatedRoute: ActivatedRoute,
        private contentService: ZsClContentService,
        private zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private router: Router) { }

    ngOnInit(): void {
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.projecData = JSON.parse(sessionStorage.getItem('projectData'));
        this.activatedRoute.parent.parent.paramMap.subscribe(params => {
            this.project_id = +params.get('id');
        });
        this.activatedRoute.queryParamMap.subscribe(param => {
            this.catalog_id = +param.get('catalog_id');
            this.object_id = +param.get('entity_id');
            if(param.has('concept_id')){
                this.concept_id = +param.get('concept_id');
            }else{
                if(this.projecData && this.projecData.hasOwnProperty('project_details')){
                    if(this.projecData.project_details.hasOwnProperty('semantic_object_classifier')){
                        this.concept_id = this.projecData.project_details.semantic_object_classifier.attribute_id;
                    }
                }
            }
            
        });
        this.gridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
        };
        this.initColDef();
        this.getClassifireObject(this.page, this.limit);
    }
    initColDef() {
        this.gridColumnsDef = [
            {
                'displayname': '',
                'physicalname': 'isChecked',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': radioButtonNewFormatter,
                'minWidth': 30,
                'maxWidth': 40,
            }, {
                'displayname': 'Classifier Concepts',
                'physicalname': 'logical_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'maxWidth': 200,
            },
            {
                'displayname': 'Description',
                'physicalname': 'description',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
            }
        ];
    }
    getClassifireObject(pageNo, limit) {
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/classifiers?paginate=true&pageno=1&pagesize=${limit}&ignore_system_attributes=true`;
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
            serverFilter = sessionStorage.getItem("serverfilter");
        }
        let serverSort = "";
        if (sessionStorage.getItem("sortfilter")) {
            serverSort = sessionStorage.getItem("sortfilter");
        }
        
        this.contentService.getObjectClassifier(this.catalog_id, this.object_id,
            pageNo, limit, serverFilter, serverSort).subscribe(resp => {
                this.objectList = resp['currentpage'];
                if (this.objectList.length === 0) {
                    this.noData = true;
                }
                this.total = resp.totalrecords;                
                if (pageNo === 1) {
                    this.grandTotal = resp.totalrecords;
                }
                if (this.concept_id) {
                    this.objectList.forEach(element => {
                        if (element.attribute_id == this.concept_id) {
                            this.object_selected = true;
                            this.project_classifier_id = element.classify_project_classifier_id;
                        }
                        element.isChecked = element.attribute_id == this.concept_id ? true : false;
                    });
                }
                let concatedRows = this.objectList;
                if (this.objectGrid) {
                    concatedRows = [...this.objectGrid.dataView.getItems(), ...this.objectList];
                    this.objectList.forEach(element => {
                        element.id = Math.random();
                    });
                    this.objectList = concatedRows;
                    this.objectGrid.dataView.setItems(this.objectList);
                    this.objectGrid.gridService.setSelectedRows([]);
                    this.hasScrolled = false;
                }
                this.isDataReady = true;
            }, error => {
                this.isDataReady = true;
                this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            });

    }
    onCellClick(e): void {
        const event = e.eventData;
        const args = e.args;
        const row = this.objectGrid.gridService.getDataItemByRowIndex(args.row);
        if (row) {
            if (event.target.className.includes('radio-formatter')) {
                $('#data-' + args.row).prop('checked', true);
                this.object_selected = true;
                this.project_classifier_id = row.classify_project_classifier_id;
                this.concept_id = row.attribute_id;
            }
        }
    }

    saveObjectClassifier() {
        const payload = {
            classify_project_classifier_id: this.project_classifier_id
        }
        this.contentService.saveObjectClassifier(this.catalog_id, this.object_id, this.project_id, payload).subscribe(resp => {                        
            this.router.navigate(['./source'],{relativeTo: this.activatedRoute, queryParams:{concept_id: this.concept_id,activeLink:'2'}, queryParamsHandling:'merge'});    
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.error.message, type: 'ERROR', hideboard: true });
        });
    }
    gridCreated(grid: AngularGridInstance) {
        this.objectGrid = grid;
    }

    onScroll(parentClass) {
        $('.basic-tooltip').hide();
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextPage();
        }
    }
    onNextPage(): void {
        this.page++;
        this.getClassifireObject(this.page, this.limit);
    }

    onNext() {
        this.saveObjectClassifier();
    }
    onPrev() {
        this.router.navigate(['/zs-cl/projects/', this.project_id, 'select-concept-parse'], {queryParamsHandling:'preserve' });
    }
    onCancel() {
        this.router.navigate(['/zs-cl/projects' ]);
    }

    receiveTotalRecords($event) {
        this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);     
    }
}