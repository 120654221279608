import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContentService } from '../../content.service';
import { Entity } from '../entity.model';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

@Component({
  selector: 'zetta-create-entity',
  templateUrl: './create-entity.component.html',
  styleUrls: ['./create-entity.component.scss']
})
export class CreateEntityComponent implements OnInit, OnDestroy {

  loggedInUserDetails: [];
  createEntityForm: FormGroup;
  isDataReady = false;
  showBreadcrumb = false;
  loadEntity = false;
  progressState = new ProjectStatus();
  entity = new Entity();
  icons = Array(30).fill(0).map((x, i) => i);
  entityIcons: any = [];
  entityTypes: any = [];
  catalog_id: number;
  catalogList = [];
  isFormValid = false;

  entityGroup: Map<String, any[]> = new Map<String, any[]>();
  entityIdMap: Map<String, any> = new Map<String, any>();
  savingEntity = false;
  errorMessage = undefined;
  @ViewChild('focusElement') focusElement;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    public zettaUtils: ZettaUtils,
    private contentSvc: ContentService,
    private formBuilder: FormBuilder,
    private messageSvc: MessageService,
    private classifyContentSvc: ZsClContentService
  ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.entity.entity_id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.loadEntity = this.entity.entity_id ? true : false;
    this.activatedRoute.queryParamMap.subscribe(param => {
      this.catalog_id = +param.get('catalog_id');
      if (param.has('entity_id')) {
        this.entity.entity_id = +param.get('entity_id');
      }
    })

    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb || this.entity.entity_id) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }
    this.getCatalogs();
    this.getEntityTypes();
    this.getEntityIcons();

    if (this.loadEntity || this.entity.entity_id) {
      this.contentSvc.getEntity(this.entity.entity_id).subscribe(responseList => {
        this.entity = responseList;
        console.log(responseList)
        this.isDataReady = true;
        this.setEntityForm();
        this.validateForm();
      }, err => {
        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
      });
    } else {
      this.isDataReady = true;
      this.setEntityForm();
      this.validateForm();
    }

    this.progressState.states = this.zettaUtils.getStateList('create-entity');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'entity';
    this.progressState.isEdit = this.loadEntity;

  }

  validateForm() {
    const entity_type_id = parseInt(this.createEntityForm.controls.entity_type_id.value);
    let entityIcons = this.entityIcons;
    const entity_icon = entityIcons.filter(icon => icon.name == this.createEntityForm.controls.entity_icon.value);
    if (entity_type_id && entity_icon.length > 0) {
      this.isFormValid = true;
    }
    else {
      this.isFormValid = false;
    }
  }

  getEntityTypes() {
    this.contentSvc.getEntityTypes().subscribe(responseList => {
      this.entityTypes = responseList;
      let entityTypes = [];
      this.entityTypes.forEach((element, index) => {
        element.lookup_id !== 274 && element.lookup_id !== 329 ? entityTypes.push(element) : '';
      });
      this.entityTypes = entityTypes;
      console.log(this.entityTypes)
    }, err => { });
  }

  getEntityIcons() {
    this.contentSvc.getEntityIcons().subscribe(responseList => {
      this.entityIcons = responseList;
      // this.entityIcons = this.entityIcons.shift();
      this.entityIcons.splice(0, 1);
      this.validateForm();
    }, err => { });
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }

  setEntityForm() {
    this.createEntityForm = this.formBuilder.group({
      name: [this.entity.name, [Validators.required]],
      description: [this.entity.description],
      entity_type_id: [this.entity.entity_type_id ? this.entity.entity_type_id.toString() : undefined, Validators.required],
      // entity_sub_type_id: [this.entity.entity_sub_type_id, Validators.required],
      entity_icon: [this.entity.entity_icon, Validators.required],
      // icon_id: [this.entity.icon_id, Validators.required]
      catalog : [this.catalog_id]
    });
  }

  onNext() {
    this.onSubmit(false);
  }

  onChange(id, val) {
    this.createEntityForm.controls[id].setValue(val);
    this.validateForm();
  }

  onSubmit(redirect: boolean) {
    if (this.savingEntity) {
      return;
    }
    if (this.createEntityForm.valid) {
      this.errorMessage = undefined;
      this.savingEntity = true;
      const entitytemp = new Entity();
      if (this.entity) {
        entitytemp.entity_id = this.entity.entity_id;
      }

      entitytemp.name = this.createEntityForm.controls.name.value;
      entitytemp.description = this.createEntityForm.controls.description.value;
      entitytemp.entity_type_id = parseInt(this.createEntityForm.controls.entity_type_id.value);
      entitytemp.entity_sub_type_id = 9;
      entitytemp.cluster_identifier_attribute_id = 700;
      entitytemp.created_by = this.loggedInUserDetails['user_id'];
      let entityIcons = this.entityIcons;

      const entity_icon = entityIcons.filter(icon => icon.name == this.createEntityForm.controls.entity_icon.value);
      if (entity_icon.length) {
        entitytemp.icon_id = entity_icon[0].lookup_id;
      }
      if (!this.loadEntity && !this.entity.entity_id) {
        entitytemp.entity_id = undefined;
      }
      let entityObject:any;
      if(entitytemp.entity_id){
        entityObject = this.contentSvc.saveEntity(entitytemp);
      }else{
        entityObject = this.classifyContentSvc.saveSemanticObject(this.loggedInUserDetails['user_id'], this.catalog_id,entitytemp)
      }
      //this.classifyContentSvc.saveSemanticObject(this.loggedInUserDetails['user_id'], this.catalog_id,entitytemp).subscribe(responseList => {      
      entityObject.subscribe(responseList => {
        if (responseList.length) {
          this.loadEntity = true;
          this.entity = responseList[0];
        }
        this.errorMessage = '';
        this.savingEntity = false;
        $('#projectState0').removeClass('d-none');
        setTimeout(function () {
          $('#projectState0').addClass('d-none');
        }, 5000);

        if (redirect) {
          this.router.navigate(['/zs/entities', this.entity.entity_id, 'users']);
        } else {
          this.router.navigate(['/zs/entities/' + this.entity.entity_id + '/add-users'], {queryParams: {catalog_id:this.catalog_id}, queryParamsHandling:'merge'});
        }

      }, err => {
        this.errorMessage = err.error.message;
        this.savingEntity = false;
        this.messageSvc.sendMessage({ message: 'Get save entity has failed', type: 'INFO', hideboard: true });
      });
      this.errorMessage = undefined;
      this.savingEntity = true;
    }
    
  }
  onChangeEntity() {
    this.errorMessage = '';
  }
  getCatalogs() {
    this.classifyContentSvc.getCatalogs(this.loggedInUserDetails['user_id'], true).subscribe(response => {
      this.catalogList = response;
      this.catalogList = this.catalogList.filter(catalog => catalog.name !== AppGlobals.DEFAULT_SYSTEM_CATALOG);
      const default_catalog = this.catalogList.find(catalog => catalog.catalog_id === this.catalog_id);
      if (!default_catalog) {
        this.catalog_id = default_catalog.catalog_id;
      }
    });
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

}