import { Column, Formatter } from 'angular-slickgrid';
import { ZmZettaUtils } from '../zm-zettaUtils';

const zettaUtilsInstance = new ZmZettaUtils();

export const numericMockFormatter: Formatter =
    (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
          }
        return `<div class="m-0 p-0" style="margin-right: -7px !important;">${getRandomInt(1, 10)}</div>`
    }
