import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { Formatter, Column, AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { ContentService } from '../../../zettasense/content/content.service';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from 'src/app/zettasense/header/header.component';



const dateTimeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => dateFormat(value);
function dateFormat(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    const dd = d.getDay() + 1;
    const day = (dd <= 9 ? '0' + dd : dd);
    const hh = d.getHours();
    const hours = (hh <= 9 ? '0' + hh : hh);
    const mm = d.getMinutes();
    const minutes = (mm <= 9 ? '0' + mm : mm);
    const ss = d.getSeconds();
    const seconds = (ss <= 9 ? '0' + ss : ss);

    return `<div class='text-left' title='${month}/${day}/${d.getFullYear()}'>
              ${month}/${day}/${d.getFullYear()} ${hours}:${minutes}:${seconds}
            </div>`;
}
@Component({
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    public noticationDataSource = [];
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public grid: AngularGridInstance;
    public tableSettings: object = { 'height': '100%', 'width': "99.50%", 'pageSize': 20, 'isNormalPagination': false };
    notificationTableColDef: any;
    gridOptions: any;
    hasScrolled = false;
    isLoading = true;
    noData = false;
    total = 0;
    page = 1;
    limit = 100;
    constructor(
        private _router: Router,
        public contentSvc: ContentService,
        public zettaUtils: ZettaUtils) { }

    ngOnInit() {
        this.getNotificationGridData(this.page, this.limit);
       
        this.notificationTableColDef = [{
            'displayname': 'Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': '',
            'minWidth': 100,
        },{
            'displayname': 'Activity',
            'physicalname': 'activity_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': '',
            'minWidth': 100,            
        }, {
            'displayname': 'TimeStamp',
            'physicalname': 'created_ts',
            'sortable': true,
            'datatype': 'DateTime',
            'filterable': true,
            'formatter': dateTimeFormatter,
            'minWidth': 60,

        },{
            'displayname': 'Source IP',
            'physicalname': 'source_ip',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': '',
            'minWidth': 60,
        },
    ];

        this.gridOptions = {
            enableGridMenu: false,
            selectable: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableFiltering: true
        };
       // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails.user_id}/activities?pageno=${this.page}&pagesize=${this.limit}`;
    }

    //Retreiving notfications data in grid
    getNotificationGridData(pageNo, limit = 1) {
        this.isLoading = true;
        let shortname=sessionStorage.getItem('shortname')
        sessionStorage.removeItem('shortname')
        this.contentSvc.getFilteredGridData(this.loggedInUserDetails.user_id, pageNo, limit,shortname).subscribe((data) => {
            this.noticationDataSource = data;
            if (this.noticationDataSource['currentpage'].length === 0) {
                this.noData = true;
            }
            this.isLoading = false;
            this.total = data['totalrecords'];
            let concatedRows = this.noticationDataSource['currentpage'];
            if (this.grid) {
                concatedRows = [...this.grid.dataView.getItems(), ...this.noticationDataSource['currentpage']];
                this.noticationDataSource['currentpage'].forEach(element => {
                    element.id = Math.random();
                });
                this.noticationDataSource = concatedRows;
                this.grid.dataView.setItems(concatedRows);
                this.grid.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }
        }, err => {
            this.isLoading = false;
        });
    }

    onGridCreation(grid) {
        this.grid = grid;
    }    
    
    goToPage(n: number): void {
        this.page = n;
        this.getNotificationGridData(this.page, this.limit);
    }

    onNext(): void {
        this.page++;
        this.getNotificationGridData(this.page, this.limit);
    }

    onPrev(): void {
        this.page--;
        this.getNotificationGridData(this.page, this.limit);
    }

    totalPages(): number {
        return this.zettaUtils.getTotalPages(this.total, this.limit);
    }

    onScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNext();
        }
    }    
}