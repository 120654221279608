
import { Column, Formatter} from 'angular-slickgrid';

export const checkBoxDisableFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

  function getCheckboxHtml(field, val) {
    let returnHtml = '';
    if ((field === 'isApproverChecked' && !dataContext['isApprover'])
    || (field === 'isReviewerChecked' &&  dataContext['isApproverChecked'])) {
      returnHtml = `<input type="checkbox" disabled>`;
    } else if (field === 'isReviewerChecked' && !val && dataContext['isReviewer']) {
      returnHtml = `<input type="checkbox">`;
    } else if ((field === 'isReviewerChecked' && !dataContext['isReviewer'])
    || (field === 'isApproverChecked' &&  dataContext['isReviewerChecked'])) {
      returnHtml = `<input type="checkbox" disabled>`;
    } else if (field === 'isApproverChecked' && !val && dataContext['isApprover']) {
      if (!dataContext['isFourEyeChecked']) {
        returnHtml = `<input type="checkbox" disabled>`;
      } else {
        returnHtml = `<input type="checkbox">`;
      }
    } else {
      if (field === 'isApproverChecked' && !dataContext['isFourEyeChecked']) {
        returnHtml = `<input type="checkbox" disabled>`;
      } else {
        returnHtml = '<input type="checkbox" checked>';
      }
    }
    return returnHtml;
  }
  return getCheckboxHtml(columnDef.field, value);

};

