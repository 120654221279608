import { Component, OnInit, ViewContainerRef, OnDestroy  } from '@angular/core';
import { ProjectService } from '../../project/project.service';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../../project/project.model';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../common/components/message/message.service';
import { matherMergerFormatter } from '../../../../common/shared/formatters/matherMergerFormatter';
import { projectNameFormattter } from '../../../../common/shared/formatters/projectNameFormatter';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { environment } from 'src/environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { ProjectNameFormatterComponent } from 'src/app/common/shared/cell-renderer/project-name-formatter/project-name-formatter.component';
import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mather-merger-formatter/mather-merger-formatter.component';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { DaysCellRenderer } from 'src/app/common/shared/cell-renderer/days-formatter/days-formatter.component';

const zettaUtilsInstance = new ZettaUtils();

const daysFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="text-center w-100" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
}

@Component({
  selector: 'zetta-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent implements OnInit, OnDestroy {

  public loggedInUserDetails = null;
  public selectedCluster;
  taskGridOptions: any;
  taskGridColumnsDef: any;
  tasksDataSource: any;
  isTasksLoading = true;
  hasScrolled = false;
  noData = false;
  public taskGrid: AngularGridInstance;
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedRowDataSource;
  taskType: string;
  selectedTaskStatus: string;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectDetail = new Project();
  total = 0;
  page = 1;
  limit = 100;
  shouldDisableFeedback = false;
  project_users = [];
  imagePath = "../../../../assets/images/common/YETI-Search.png";
  allMyTaskGridState = {
    sort: null,
    search: ''
  }
  openMatcherFeedbackModal: boolean = false;
  openMergerFeedbackModal: boolean = false;
  isFiltering = false;
  isSorting = false;

  columnApi:any;
  gridApi:any;
  isRowDataAvailable: boolean = false;
  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {}
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  subTotalRecords = 0;
  prouductionTaskSelected: boolean = false;
  isProjectDataLoaded: boolean = false;
  isProjectFourEyeCheckOn: boolean = false;
  shouldDisableMatchFixingTasks: boolean = true;
  shouldMoveMatchFixingData: boolean = false;
  selectedJobId: string;
  
  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router, 
    public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,private http : HttpClient,) { }

  ngOnInit() {
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');
    this.taskGridColumnsDef = [{
      'displayname': 'Id',
      'physicalname': 'task_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
    }, {
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Project Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 170,
      'params': { 'isDisplayDesc': false },
      'formatter': projectNameFormattter
    }, {
      'displayname': 'Task Name',
      'physicalname': 'taskname',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 170,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    }, {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'DateTime',
      'filterable': true,
      'minWidth': 90,
      'maxWidth': 180,
      'formatter': dateFormatter
    }, {
      'displayname': 'Days Since Last Updated',
      'physicalname': 'last_updated_day',
      'sortable': true,
      'datatype': 'Numeric',
      'filterable': true,
      'minWidth': 50,
      'maxWidth': 180,
      'cssClass':'min-width-100-per',
      'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
      'formatter': daysFormatter
    }];

    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: false,
      rowHeight: 34,
      noDataMsg : ''
    };

    this.loadTasksData(this.page, this.limit);
    this. intialiseGrid();
  }

  onGridStatusChanged(_allMyTaskGridState) {
    this.allMyTaskGridState.sort = _allMyTaskGridState.sort ? _allMyTaskGridState.sort: null;
  }
  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: 'Id',
        field: 'task_id',  
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField:'task_id',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }, 
        cellClass: 'task-Id-selection',
        minWidth : 200,
        suppressMenu:true
        
      }, {
        headerName: 'Task Type',
        field: 'task_type', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        cellClass: 'task-type-selection',
       cellRendererFramework:MatherMergerFormatterComponent,
       floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      tooltipField:'task_type',
      minWidth : 200,
      suppressMenu:true
      }, {
        headerName: 'Project Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        editable: true, 
        cellClass: 'task-Project-selection',
        tooltipField:'name',
       cellRendererFramework:ProjectNameFormatterComponent,
       floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      minWidth : 200,
      suppressMenu:true 
      }, {
        headerName: 'Task Name',
        field: 'taskname',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        tooltipField:'taskname',
        cellRendererFramework:MatherMergerFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth : 200,
        suppressMenu:true 
      }, {
        headerName: 'Assigned To',
        field: 'user_name', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        tooltipField : 'user_name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellClass: 'task-Assigned-selection',
        minWidth : 200,
        suppressMenu:true 
      }, {
        headerName: 'Role',
        field: 'role_name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        tooltipField:'role_name',
        cellClass: 'task-role-selection',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth : 210,
        suppressMenu:true 
      }, {
        headerName: 'Task Status',
        field: 'task_status', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellClass: 'task-status-selection',
        tooltipField: 'task_status',
        cellRendererFramework:AssignmentStatusFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth : 210,
        suppressMenu:true, 
       
      }, {
        headerName: 'Last Updated',
        field: 'last_updated',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        sortable:true,
        cellClass: 'task-last-updated-selection',
        cellRendererFramework:DateFormatterFormatterComponent,
        tooltipField:'last_updated',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth : 210,
        suppressMenu:true
       
      }, 
      {
        headerName: 'Days Since Last Updated',
        field: 'last_updated_day',  
        filter: false,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable:false,
        cellClass: 'task-Days-last-updated-selection ag-right-aligned-cell',
        cellRendererFramework: DaysCellRenderer,
        tooltipField:'last_updated_day',
        minWidth : 200,
        suppressMenu:true 
       
      }, 
    ];
    this.fsGridOptions = { 
      suppressRowClickSelection: true,
      enableCellTextSelection:true,
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
      
    };
    
  }

  loadTasksData(pageno, limit = 1) {
    limit =  100;
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tasks?allusers=false&paginate=true&pageno=${1}&pagesize=${limit}`;
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tasks?allusers=false`;
    // this.ProjectSvc.getAllMyOpenTasks(this.loggedInUserDetails.userid, pageno, limit).subscribe((data) => {
      var serverfilter = "";
      if(sessionStorage.getItem("serverfilter")) {
        var serverfilter = sessionStorage.getItem("serverfilter");
        this.isFiltering = true;
      } else {
        if (this.isFiltering) { 
          this.page = 1;
        }
        this.isFiltering = false;
      } 
     let sortfilter = "";
     if (sessionStorage.getItem("sortfilter")) {
        sortfilter = sessionStorage.getItem("sortfilter");
     } 
     this.isTasksLoading = true;
    let isCustomGridStateHandling = this.allMyTaskGridState.sort && this.allMyTaskGridState.sort != '' ? this.allMyTaskGridState.sort: null;
    this.ProjectSvc.getAllMyTasks(this.loggedInUserDetails.user_id, pageno, limit, serverfilter, sortfilter, isCustomGridStateHandling).subscribe((data) => {
      this.tasksDataSource = data;
      if (this.tasksDataSource['currentpage'].length === 0) {
        this.noData = true;
      }

      // this.tasksDataSource['currentpage'] = this.tasksDataSource['currentpage'].filter(task => task.task_status === "Completed");
      // this.tasksDataSource['currentpage'] = this.tasksDataSource['currentpage'].filter(task => task.task_status === "Completed" && task.project_id === 262);
      this.isRowDataAvailable = this.tasksDataSource && this.tasksDataSource['currentpage'] && this.tasksDataSource['currentpage'].length > 0;

      this.isTasksLoading = false;
      if (pageno === 1) {
        this.total = data['totalrecords'];
      }
      let concatedRows = this.tasksDataSource['currentpage'];

      if (this.taskGrid) {
        concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource['currentpage']];
        this.tasksDataSource['currentpage'].forEach(element => {
          element.id = Math.random();
        });
        this.tasksDataSource['currentpage'] = concatedRows;
        this.taskGrid.dataView.setItems(concatedRows);
        this.taskGrid.gridService.setSelectedRows([]);
        if (pageno < data['totalpages']) {
          this.hasScrolled = false;
        }
        else {
          this.hasScrolled = true;
        }
        }   
    }, err => {
      this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
      this.isTasksLoading = false;
    });
  }
  shouldShowGrid(): boolean {
    return this.isRowDataAvailable;
  }

  // Method to determine whether to show image
  shouldShowImage(): boolean {
    return !this.isRowDataAvailable;
  }


  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }
  onGridReady(grid:any) { 
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
    this.intialiseGrid();
  }

  taskGridReady(grid) {
    const parent = this;
    this.taskGrid = grid;
    parent.taskGrid.slickGrid.onSort.subscribe((e, args) => {
      parent.page = 1;
      parent.hasScrolled = false;
    });
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadTasksData(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.loadTasksData(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.loadTasksData(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row);

    const clickedCellIndex = args.cell;

    if(clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('name')){ 
      this._router.navigate(['/zs/projects', row.project_id]);
    } else if(clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('taskname') || clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('task_type') ){
      this.selectedClusterId = row.cluster_id;
      this.selectedTaskName = row.taskname;
      this.selectedTaskType = row.task_type;
      this.projectDetail.project_id = row.project_id;
      this.taskType = row.task_type;
      this.selectedTaskStatus = row.task_status;
      this.selectedRowDataSource = row;
      this.shouldDisableFeedback = false;
      this.prouductionTaskSelected = row.project_status == AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS ? true : false;
      if(this.selectedTaskType !== AppGlobals.RESOLVE_MATCH_FIXING_TASK) {
        this.getProject(row);
        }
      if(this.selectedClusterId && this.taskType !== 'Merge Training'){
        this.openMatcherFeedbackModal = true;
        this.openMergerFeedbackModal = false;
      } else if(this.taskType === 'Merge Training'){
        this.openMatcherFeedbackModal = false;
        this.openMergerFeedbackModal = true;
      }
    }
  }
  onAgCellClick(cellparams):void{
    const fieldName = cellparams.colDef.field;
    const row = cellparams.data;
    if(row !== undefined && row!==null){
    if(cellparams.value===row.name){
      if(row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS) {
        this._router.navigate([`/zs/projects/${row.project_id}/production-stage-details`], { queryParams: { entity_id: row.entity_id }});
      } else {
        this._router.navigate(['/zs/projects', row.project_id]);
      }
    }
    else if(cellparams.value === row.taskname || cellparams.value === row.task_type){
            this.selectedClusterId = row.cluster_id;
            this.selectedTaskType = row.task_type;
            this.selectedTaskName = row.taskname;
            this.projectDetail.project_id = row.project_id;
            this.taskType = row.task_type;
            this.selectedTaskStatus = row.task_status;
            this.selectedRowDataSource = row;
            this.shouldDisableFeedback = false;
            this.selectedJobId = row.zs_job_id;
            this.isProjectFourEyeCheckOn = row.four_eye_check;
            this.prouductionTaskSelected = row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS ? true : false;
            this.shouldMoveMatchFixingData = this.validateMatchFixingTasksDataShouldBeMerged(row);
            this.shouldDisableMatchFixingTasks = (row.task_status === AppGlobals.TASK_COMPLETED || row.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
            if(this.selectedTaskType !== AppGlobals.RESOLVE_MATCH_FIXING_TASK) {
              this.getProject(row);
            }
            if(this.selectedClusterId && this.taskType !== 'Merge Training'){
              this.openMatcherFeedbackModal = true;
              this.openMergerFeedbackModal = false;
            } else if(this.taskType === 'Merge Training') {
              this.openMatcherFeedbackModal = false;
              this.openMergerFeedbackModal = true;
            }
          }
        }
      }
  getProject(row) {
    this.ProjectSvc.getProject(this.loggedInUserDetails['user_id'], row.project_id).subscribe(resp => {
      this.project_users = resp.users;
      let userDetails = this.project_users.find(user => user.user_id === this.loggedInUserDetails.user_id);      
      const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER);
      if (row.task_status === AppGlobals.TASK_COMPLETED) {
        this.shouldDisableFeedback = true;
      }
      if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
        this.shouldDisableFeedback = true;
      }
      this.isProjectFourEyeCheckOn = resp.four_eye_check ? resp.four_eye_check:false; 
      this.isProjectDataLoaded = true;
    });
  }

  
  onReloadFixingDataGrid(event) {
    if (event) {
      this.openMatcherFeedbackModal = false;
      this.page = 1;
      this.openMergerFeedbackModal = false;
      this.tasksDataSource = [];
      this.loadTasksData(1, this.limit);
      if (event.shouldRunDeltaMerger && event.completed) {
          // call algo API from here
          this.runDeltaMatcherAlgoAPI();
      } else {
        this.resetMatcherTaskGridStates();
      }
    }
  }

  runDeltaMatcherAlgoAPI() {
    let self = this;
    this.selectedJobId = this.selectedJobId ? this.selectedJobId : this.zettaUtils.generateUUID();
    this.ProjectSvc.runDeltaMatcherAlgoJob('stage8', this.selectedJobId, this.projectDetail.project_id,
    this.selectedClusterId).subscribe((res) => {
      self.resetMatcherTaskGridStates();
    }, (err) => {
      self.resetMatcherTaskGridStates();
    })
  }

  resetMatcherTaskGridStates() {
    this.selectedClusterId = '';
    this.selectedTaskName = '';
    this.selectedTaskType = '';
    this.taskType = '';
    this.selectedTaskStatus = '';
    this.shouldDisableMatchFixingTasks = true;
    this.shouldMoveMatchFixingData = false;
    this.selectedJobId = '';
    this.isProjectFourEyeCheckOn = false;
  }

  
  validateMatchFixingTasksDataShouldBeMerged(rowData) {
    if (rowData && rowData.task_status) {
        if (this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          return true;
        }
        if (!this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_IN_REVIEW) {
          return true;
        }
    }
    return false;  
  }
  
  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true;
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      console.log("fileterfdsv")
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onReloadScreen(event) {
    if (event) {
      // close pop-up here openMatcherFeedbackModal, and merger
      this.openMatcherFeedbackModal = false;
      this.openMergerFeedbackModal = false;
      
      this.tasksDataSource = [];
      if (this.taskGrid) {
        this.taskGrid.dataView.setItems(this.tasksDataSource);
      }
      this.loadTasksData(1, this.limit);
    }
  }
  onMergerFeedbackModalClosed(event) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
  }

  onMatcherFeedbackModalClosed(eve) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
 
  }
}
