import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import _ from 'lodash';
import { ContentService } from '../content/content.service';
import { actionsFormatter } from '../../common/shared/formatters/actionsFormatter';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { AngularGridInstance, Formatter, Column, parseUtcDate } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { blueTextFormattter } from '../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../environments/environment';
import { dateFormatter } from '../../common/shared/formatters/dateFormatter';
import { MessageService } from '../../common/components/message/message.service';
import { imageFormatter } from '../../common/shared/formatters/imageFormatter';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { DatePipe } from '@angular/common';
import { UserGroupService } from "../../zettasense/usergroup/usergroup.service";
import { AppGlobals } from 'src/app/common/shared/app.globals';


declare var $: any;

const customObjectRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  if( dataContext.action !== 'edit'){

      return `<div class="box-input-tenant"><input id='object-${columnDef.field}-${row}' class='ctmInputBox-tenant' type='text' placeholder='${columnDef.params.placeholder}' 
              tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value=''></input></div>`;
  }else{
          return `<div id="noEdit-${columnDef.field}-${row}" title="${value}" class="w-100 pr-3 text-truncate pl-2">${value}&nbsp;</div>
                  <div id="isEdit-${columnDef.field}-${row}" class="w-100 pr-3" style="display:none">
                      <input id='object-${columnDef.field}-${row}' title='${value}' class='ctmInputBoxNoEdit-tenant' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' autocomplete="off">
                  </div>`
      }
};


const actionsObjectFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.field === 'action') {
      if (dataContext.action === 'edit') {
        return `
        <div class='d-flex col-1'>
            <div class="text-center col-6 p-0 number mr-3" title="${value}">
                    <i id='edit-${row}' class="fal fa-pen actionInlineBtn blue-color-link"></i>
            </div>
            <div class="text-center col-6 p-0 number" title="delete">
                    <i id='delete-${row}' class="far fa-trash-alt actionInlineBtn red-color-link"></i>
            </div>
        </div>`
      } else {
        return `<div class="col-6 number" title="${value}">
                <i class="fa fa-plus actionInlineBtn blue-color-link"></i>
            </div>`
      }
  } else {
        if(dataContext.action === 'edit'){
                return `<div class="text-center w-100 number">
                            <i class="fa fa-graduation-cap actionInlineBtn"></i>
                        </div>`
            } else {
                return ``;
            }
    }
  }



@Component({
  selector: 'zetta-manage-tenants',
  templateUrl: './manage-tenants.component.html',
  styleUrls: ['./manage-tenants.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ManageTenantsComponent implements OnInit, OnDestroy {

  public tenants: Array<object>;
  public grid: AngularGridInstance;
  public tenantGrid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 25, 'isNormalPagination': false };
  public tenantTableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 25, 'isNormalPagination': false };
  tenantsTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isEdit = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;

  tenantForm: FormGroup;
  deleteTenantForm: FormGroup;
  tenant: any = {};
  public getTenant;
  isDataReady = true;
  title = "";
  logoImage = '../../../../assets/images/common/placeholder.png';
  iconImage = '../../../../assets/images/common/';
  isFormValid=false;
  tanent_apps:any=[]; 
  is_checked=false;  
  is_logo=false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  clusterManagementTypes=[];
  public panelScreen: number;
  public buttonWrap: boolean;
  gridObj: AngularGridInstance;
  objectTableColDef: any;
  showBreadcrumb = true;
  objectsList: any = [];
  addObject: Object = new Object();
  uniqueList = [];
  uniques = [];
  dataBricksVal='';
  isAdmin: boolean;
  eventData: any;
  selectedObjectMetadata: any;
  is_apps=false;  
  lastRowSelected: number = 0;
  admin= {
    description:'',
    name:'',
    user_name: '',
    email: '',
    id: Math.random().toString(36).slice(-6), 
    entity_id: '-1', 
    action: 'edit',
    synonyms: Math.floor(Math.random() * (9 - 0)) + 0
  };
  apps=[];
  clusterValue:'';
  @ViewChild('focusElement') focusElement;
  addFirstRow;
  quotaTypes: object;
  minDate: Date;
  constructor(
    private formBuilder: FormBuilder,
    private contentSvc: ContentService,
    public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private messageSvc: MessageService,
    private datePipe: DatePipe,
    private userGroupService: UserGroupService) {
      this.datePickerConfig = Object.assign({},
        {
         containerClass: 'theme-dark-blue',
          showWeekNumbers: false,          
        });
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate());
     }
   
  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.getTenant = { name: "", administrator: "", email: "", };
    this.getTenantList(this.page, this.limit);
    this.getClustureManagementTypes();
    this.setTenantForm();
    this.objectsList = [];
    this.addFirstRow = this.getAddObjectRow();   
    this.tenantsTableColDef = [
      {
        'displayname': '',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'cssClass': 'text-right',
        'formatter': actionsFormatter,
        'maxWidth': 20,
      }, {
        'displayname': 'Tenant Name',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 200,
        'formatter': imageFormatter,
      }, {
        'displayname': 'Created By',
        'physicalname': 'created_by',
        'sortable': true,
        'datatype': 'String',
        'filterable': true
      }, {
        'displayname': 'Username',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true
      }, {
        'displayname': 'User E-Mail',
        'physicalname': 'email',
        'sortable': true,
        'datatype': 'String',
        'filterable': true
      }, {
        'displayname': 'Last Modified',
        'physicalname': 'last_updated',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': dateFormatter,
      }];


    this.objectTableColDef = [
      {
        'displayname': 'First Name',
        'physicalname': 'description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customObjectRowFormatter,
        'headerCssClass': 'mr-top-1',
        'minWidth': 200,
        'maxWidth': 210,
        'params': { placeholder: 'First Name..', tabindex: 1, showBreadCrumb: this.showBreadcrumb }
      }, {
        'displayname': 'Last Name',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customObjectRowFormatter,
        'headerCssClass': 'mr-top-1',
        'minWidth': 200,
        'maxWidth': 210,
        'params': { placeholder: 'Last Name...', tabindex: 2, showBreadCrumb: this.showBreadcrumb }
      },
      {
        'displayname': 'User Name',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customObjectRowFormatter,
        'headerCssClass': 'mr-top-1',
        'minWidth': 200,
        'maxWidth': 210,
        'params': { placeholder: 'User Name...', tabindex: 3, showBreadCrumb: this.showBreadcrumb }
      }, {
        'displayname': 'E-Mail',
        'physicalname': 'email',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customObjectRowFormatter,
        'headerCssClass': 'mr-top-1',
        'minWidth': 200,
        'maxWidth': 210,
        'params': { placeholder: 'Email...', tabindex: 4, showBreadCrumb: this.showBreadcrumb }
      }
      , {
        'displayname': 'Actions',
        'physicalname': 'action',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': actionsObjectFormatter,
        'cssClass': 'text-center',
        'headerCssClass': 'text-center pr-1',
        'maxWidth': 80,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 40
    };
    this.setDeleteTenantForm();
    this.getTenantApps();
    this.getQuotaType();
  }

  getQuotaType() {    
    this.contentSvc.getQuotaTypes().subscribe((res) => {
      this.quotaTypes = res;
    });
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }
  
  onRowSelection(eventData) {
    const parent = this;
    this.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const metadata = this.tenantGrid.gridService.getColumnFromEventArguments(args);
    this.selectedObjectMetadata = metadata;
    const row = this.tenantGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;

    // On click of plus icon in Tenant admin grid
    if (event.target.className.includes('blue-color-link') && event.target.className.includes('fa fa-plus')) {
      const newNAME = $('#object-description-' + args.row)[0]['value'];
      const newDESC = $('#object-name-' + args.row)[0]['value'];
      const newUser_Name = $('#object-user_name-' + args.row)[0]['value'];
      const newEmail = $('#object-email-' + args.row)[0]['value'];
      let newTenantAdmin = [{
        description: newNAME,
        name: newDESC,
        user_name: newUser_Name,
        email: newEmail,
        id: Math.random().toString(36).slice(-6),
        action: 'edit',
        synonyms: Math.floor(Math.random() * (9 - 0)) + 0
      }];
      if (newUser_Name) {
        this.contentSvc.tenantAdminUserValidation(newUser_Name).subscribe((responseData) => {
          if (responseData) {
            this.messageSvc.sendMessage({ message: 'User Name already exists.', type: 'ERROR', hideboard: true });
          }
        }, error => {
          if (this.tenantGrid) {            
            let concatedRows = [...this.tenantGrid.dataView.getItems(), ...newTenantAdmin];
            const firstRow = this.getAddObjectRow();
            this.setNewObjectDetails(firstRow);
            this.objectsList.splice(0, 0, firstRow);            
            this.isDataReady = true;            
            this.objectsList = concatedRows;
            const unique = [];
            const map = new Map();
            for (const item of concatedRows) {
              if (!map.has(item.id)) {
                map.set(item.id, true);
                if (item && item.id && item.name) {
                  unique.push(
                    item
                  );
                }
              }
            }
            this.tenantGrid.dataView.refresh();
            this.uniqueList = [{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...unique];
            this.uniques = [...unique];
            this.tenantGrid.dataView.setItems([]);
            this.tenantGrid.dataView.setItems([
              {
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, ...unique]);
            this.uniqueList = [];
            this.validateForm();
            $('#object-description-' + args.row)[0]['value'] = '';
            $('#object-name-' + args.row)[0]['value'] = '';
            $('#object-user_name-' + args.row)[0]['value'] = '';
            $('#object-email-' + args.row)[0]['value'] = '';
          }
        })
      }
      this.validateForm();
    } else if (event.target.className.includes('red-color-link') && event.target.className.includes('far fa-trash-alt')) {
      if (row.user_id) {
        this.userGroupService.deleteUsers(row.user_id).subscribe((responseData) => {
          // Remove tenant admin details from grid
          this.uniques = this.uniques.filter(item => item.id !== row.id);
          this.objectsList = this.objectsList.filter(item => item.id !== row.id);
          if (!this.isEdit) {
            if (this.objectsList.length === 0) {
              this.objectsList = [{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }];
              this.validateForm();
              this.tenantGrid.dataView.setItems([...this.objectsList]);
            } else if (this.uniques.length === 0) {
              this.objectsList = this.objectsList.filter(item => item.id)
                .filter((value, index, self) => self.indexOf(value) === index)
              this.tenantGrid.dataView.setItems([...this.objectsList]);
            } else {
              this.objectsList = this.objectsList.filter(item => item.id)
                .filter((value, index, self) => self.indexOf(value) === index)
              this.tenantGrid.dataView.setItems([{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, ...this.uniques]);
            }
          } else {
            if (this.objectsList.length === 0) {
              this.objectsList = [
                {
                  'id': Math.random().toString(36).slice(-6),
                  'entity_id': '-1',
                  'description': '',
                  'name': '',
                  'user_name': '',
                  'email': '',
                  'action': 'add'
                }
              ];
              this.validateForm();
              this.tenantGrid.dataView.setItems([{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }]);
            } else if (this.uniques.length === 0) {
              this.objectsList = this.objectsList.filter(item => item.id)
                .filter((value, index, self) => self.indexOf(value) === index)
              this.objectsList = [
                {
                  'id': Math.random().toString(36).slice(-6),
                  'entity_id': '-1',
                  'description': '',
                  'name': '',
                  'user_name': '',
                  'email': '',
                  'action': 'add'
                }];
              if (this.objectsList.length === 1) {
                this.tenantGrid.dataView.setItems([...this.objectsList]);
              } else {
                this.tenantGrid.dataView.setItems([{
                  'id': Math.random().toString(36).slice(-6),
                  'entity_id': '-1',
                  'description': '',
                  'name': '',
                  'user_name': '',
                  'email': '',
                  'action': 'add'
                }, this.uniques]);
              }

            } else if (this.uniques.length === 1) {
              this.objectsList = this.objectsList.filter(item => item.id)
                .filter((value, index, self) => self.indexOf(value) === index);
              this.tenantGrid.dataView.setItems([{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, ...this.uniques])
            } else {
              this.tenantGrid.dataView.setItems([...this.objectsList]);
            }
          }
        }, error => {
          this.messageSvc.sendMessage({ message: AppGlobals.DELETE_TENANT_ADMIN_USER_MSG, type: 'ERROR', hideboard: true });
        });
      } else {
        // Remove tenant admin details from grid
        this.uniques = this.uniques.filter(item => item.id !== row.id);
        this.objectsList = this.objectsList.filter(item => item.id !== row.id);
        if (!this.isEdit) {
          if (this.objectsList.length === 0) {
            this.objectsList = [{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }];
            this.validateForm();
            this.tenantGrid.dataView.setItems([...this.objectsList]);
          } else if (this.uniques.length === 0) {
            this.objectsList = this.objectsList.filter(item => item.id)
              .filter((value, index, self) => self.indexOf(value) === index)
            this.tenantGrid.dataView.setItems([...this.objectsList]);
          } else {
            this.objectsList = this.objectsList.filter(item => item.id)
              .filter((value, index, self) => self.indexOf(value) === index)
            this.tenantGrid.dataView.setItems([{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...this.uniques]);
          }
        } else {
          if (this.objectsList.length === 0) {
            this.objectsList = [
              {
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }
            ];
            this.validateForm();
            this.tenantGrid.dataView.setItems([{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }]);
          } else if (this.uniques.length === 0) {
            this.objectsList = this.objectsList.filter(item => item.id)
              .filter((value, index, self) => self.indexOf(value) === index)
            this.objectsList = [
              {
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }];
            if (this.objectsList.length === 1) {
              this.tenantGrid.dataView.setItems([...this.objectsList]);
            } else {
              this.tenantGrid.dataView.setItems([{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, this.uniques]);
            }

          } else if (this.uniques.length === 1) {
            this.objectsList = this.objectsList.filter(item => item.id)
              .filter((value, index, self) => self.indexOf(value) === index);
            this.tenantGrid.dataView.setItems([{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...this.uniques])
          } else {
            this.tenantGrid.dataView.setItems([...this.objectsList]);
          }
        }
      }
      this.validateForm();
    } else if (row !== undefined && row !== null && metadata.columnDef.field === 'action') {
      let idCell = '#object-' + fieldName + '-' + args.row;
      if (metadata.dataContext.action === 'edit' ||
        event.target.className == 'fal fa-pen actionInlineBtn blue-color-link' ||
        event.target.className == 'fal blue-color-link fa-pen actionInlineBtn' ||
        event.target.className == 'blue-color-link fas fa-pen actionInlineBtn'
      ) {
        var oldValue: any;
        var newValue: any;
        const objectX: any = {};
        $('#object-description-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-name-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-user_name-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-email-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        // $(idCell).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });

        $(idCell).focusout(function () {
          newValue = $(idCell)[0]['value'];
          if (fieldName === 'description') { oldValue = row.description; }
          if (fieldName === 'name') { oldValue = row.name; }
          if (fieldName === 'email') { oldValue = row.email; }
          if (fieldName === 'user_name') { oldValue = row.user_name; }
          if (oldValue !== newValue) {
            const objectX: any = {};
            objectX.entity_id = row.entity_id;
            if (fieldName === 'description') {
              objectX.description = newValue;
              parent.admin['description'] = newValue;
            }
            if (fieldName === 'name') {
              objectX.name = newValue
              parent.admin['name'] = newValue;
            }
            if (fieldName === 'user_name') {
              objectX.user_name = newValue
              parent.admin['user_name'] = newValue;
            }
            if (fieldName === 'email') {
              objectX.email = newValue
              parent.admin['email'] = newValue;
            }
          }
          $('#object-description-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-name-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-email-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-user_name-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
        });
        if (event.target.className.includes('fal fa-pen actionInlineBtn') || event.target.className.includes('fas fa-pen actionInlineBtn') || event.target.className == 'blue-color-link fas fa-pen actionInlineBtn'
          || event.target.className == 'fal blue-color-link fa-pen actionInlineBtn'
        ) {

          if (event.target.className.includes('fal fa-pen actionInlineBtn') ||
            event.target.className == 'fal blue-color-link fa-pen actionInlineBtn'
          ) {
            $('#edit-' + args.row).removeClass('fal fa-pen actionInlineBtn');
            $('#edit-' + args.row).addClass('fas fa-pen actionInlineBtn');
            $('#edit-' + args.row).show();

            $('#noEdit-description-' + args.row).hide();
            $('#noEdit-name-' + args.row).hide();
            // Email and Username are made non-editable
            // $('#noEdit-email-' + args.row).hide();
            // $('#noEdit-user_name-' + args.row).hide();
            $('#isEdit-description-' + args.row).show();
            $('#isEdit-name-' + args.row).show();
            // Email and Username are made non-editable
            // $('#isEdit-user_name-' + args.row).show();
            // $('#isEdit-email-' + args.row).show();
          } else if (event.target.className.includes('fas fa-pen actionInlineBtn') ||
            event.target.className == 'blue-color-link fas fa-pen actionInlineBtn'
          ) {

            let email = $('#object-email-' + args.row).val();
            let uName = $('#object-user_name-' + args.row).val();
            let desc = $('#object-description-' + args.row).val();
            let name = $('#object-name-' + args.row).val();
              this.tenantGrid.dataView.setItems([]);
              this.objectsList[args.row]['email'] = email;
              this.objectsList[args.row]['user_name'] = uName;
              this.objectsList[args.row]['description'] = desc;
              this.objectsList[args.row]['name'] = name;
  
              let concatedData = [...this.tenantGrid.dataView.getItems(), ...this.objectsList];
              const firstRow = this.getAddObjectRow();
              this.setNewObjectDetails(firstRow);
              this.objectsList.splice(0, 0, firstRow);
              this.isDataReady = true;
              this.uniqueList = [];
              concatedData.map(ten => ten.id = Math.random().toString(36).slice(-6));
              this.objectsList = concatedData;
              const unique = [];
              const map = new Map();
              for (const item of concatedData) {
                if (!map.has(item.id)) {
                  map.set(item.id, true);
                  if (item && item.id && item.name) {
                    unique.push(
                      item
                    );
                  }
                }
              }
              this.tenantGrid.dataView.refresh();
              this.uniqueList = [{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, ...unique];
              this.uniques = [...unique];
              this.tenantGrid.dataView.setItems(this.uniqueList);
              this.uniqueList = [];
              $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
              $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
              $('#edit-' + args.row).show();
  
              $('#noEdit-description-' + args.row).show();
              $('#noEdit-name-' + args.row).show();
              $('#noEdit-user_name-' + args.row).show();
              $('#noEdit-email-' + args.row).show();
              $('#isEdit-description-' + args.row).hide();
              $('#isEdit-name-' + args.row).hide();
              $('#isEdit-user_name-' + args.row).hide();
              $('#isEdit-email-' + args.row).hide();
          } else {
            if (!this.admin.description) {
              this.admin.description = row.description;
            } else {
              this.admin.description = this.admin.description;
            }
            if (!this.admin.name) {
              this.admin.name = row.name;
            } else {
              this.admin.name = this.admin.name;
            }
            if (!this.admin.user_name) {
              this.admin.user_name = row.user_name;
            } else {
              this.admin.user_name = this.admin.user_name;
            }
            if (!this.admin.email) {
              this.admin.email = row.email;
            } else {
              this.admin.email = this.admin.email;
            }

            this.isDataReady = false;
            this.objectsList = [];
            // args.row
            this.tenantGrid.dataView.refresh();
            this.uniques[args.row - 1] = this.admin;



            // const firstRow = this.getAddObjectRow();
            // this.setNewObjectDetails(firstRow);
            // this.objectsList.splice(0, 0, firstRow);
            this.tenantGrid.dataView.setItems([
              {
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              },
              ...this.uniques]);
            this.tenantGrid.gridService.setSelectedRows([]);

            this.objectsList = [{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...this.uniques];
            this.isDataReady = true;
            this.admin = {
              description: '',
              name: '',
              user_name: '',
              email: '',
              id: Math.random().toString(36).slice(-6),
              entity_id: '-1',
              action: 'edit',
              synonyms: Math.floor(Math.random() * (9 - 0)) + 0
            };
            $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + args.row).show();

            $('#noEdit-description-' + args.row).show();
            $('#noEdit-name-' + args.row).show();
            $('#noEdit-user_name-' + args.row).show();
            $('#noEdit-email-' + args.row).show();
            $('#isEdit-description-' + args.row).hide();
            $('#isEdit-name-' + args.row).hide();
            $('#isEdit-user_name-' + args.row).hide();
            $('#isEdit-email-' + args.row).hide();
          }

        }
        else if (event.target.className.includes('fal fa-link actionInlineBtn')) {
          $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
          $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
          $('#edit-' + this.lastRowSelected).show();

          $('#noEdit-description-' + this.lastRowSelected).show();
          $('#noEdit-name-' + this.lastRowSelected).show();
          $('#noEdit-email-' + this.lastRowSelected).show();
          $('#noEdit-user_name-' + this.lastRowSelected).show();
          $('#isEdit-description-' + this.lastRowSelected).hide();
          $('#isEdit-name-' + this.lastRowSelected).hide();
          $('#isEdit-email-' + this.lastRowSelected).hide();
          $('#isEdit-user_name-' + this.lastRowSelected).hide();
        }

        this.lastRowSelected = args.row;
      }
    }
  }

readObjectDetails() {
  this.addObject['description'] = $('#object-description-0')[0]['value'];
  this.addObject['name'] = $('#object-name-0')[0]['value'];
  this.addObject['user_name'] = $('#object-user_name-0')[0]['value'];
  this.addObject['email'] = $('#object-email-0')[0]['value'];
}

setNewObjectDetails(row) {
  row.description = this.addObject['description'];
  row.name = this.addObject['name'];
  row.user_name = this.addObject['user_name'];
  row.email = this.addObject['email'];
}

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getTenantApps(){
    this.contentSvc.getApps(this.loggedInUserDetails.user_id).subscribe(resp=>{
      this.tanent_apps=[];
      if(resp.length){
        resp.forEach(el => {
          let app_icon="";
          if(el.app_id==1){
            app_icon=this.iconImage+"Zs_resolve.png"
          }if(el.app_id==4){
            app_icon=this.iconImage+"Zs_ingest.png"
          }if(el.app_id==3){
            app_icon=this.iconImage+"Zs_classify.png"
          }
          this.tanent_apps.push({
            'name':el.name,
            'app_icon':app_icon,
            'app_id':el.app_id,
            'ex_date':'',
            'is_checked':false
          });
        });
        this.is_apps=true;
      }
      
    },error=>{
      this.is_apps=false;
    });
  }
  
  getTenantList(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants?pageno=${1}&pagesize=${limit}`;   
    let serverfilter="";
    if(sessionStorage.getItem("serverfilter")){
       serverfilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    this.contentSvc.getTenants(this.loggedInUserDetails.user_id, page, limit, serverfilter, sortfilter).subscribe((data) => {
      this.tenants = data;
      this.isLoading = false;
      if (this.tenants && this.tenants['currentpage'] && this.tenants['currentpage'].length === 0) {
        this.noData = true;
      } else {
        if(this.tenants && this.tenants['currentpage'] && this.tenants['currentpage'].length>0) {
        this.tenants['currentpage'].forEach(tenant => {
          tenant.image = this.logoImage;
          this.contentSvc.getLogo(this.loggedInUserDetails.user_id, tenant.tenant_id).subscribe(image => {
            let reader = new FileReader();
            reader.addEventListener('load', () => {
              tenant.image = reader.result;
              this.grid ? this.grid.slickGrid.invalidate() : '';
            }, false);
            if (image && image.size) {
              reader.readAsDataURL(image);
            } else {
              tenant.image = this.logoImage;
            }
          })
        });
      }
    }
      this.total = data['totalrecords'];
      if (this.grid) {
        if( this.tenants && this.tenants['currentpage'] && this.tenants['currentpage'].length>0) {
      let concatedRows = this.tenants['currentpage'];
        concatedRows = [...this.grid.dataView.getItems(), ...this.tenants['currentpage']];
        this.tenants['currentpage'].forEach(element => {
          element.id = Math.random().toString(36).slice(-6);
        });
        this.tenants['currentpage'] = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }
    }, (error) => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: 'Get tenants failed', type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }
  onChange(event) {
    var keyCode = ('which' in event) ? event.which : event.keyCode;
    if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105) && keyCode != 8) {
      event.preventDefault();
      return false;
    }
  }

  goToPage(n: number): void {
    this.page = n;
    this.getTenantList(this.page, this.limit);
  }

  onNext(): void {
    if(this.page < this.tenants['totalpages']){
      this.page++;
      this.getTenantList(this.page, this.limit);
    }
    
  }

  onPrev(): void {
    this.page--;
    this.getTenantList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    let row = this.grid.gridService.setSelectedRows([]);    
    row = this.grid.gridService.getDataItemByRowIndex(args.row);
    
    $('#context_menu')
      .removeClass('d-none')
      .data('row', args.row)
      .css('top', eventEle.pageY)
      .css('left', eventEle.pageX + 5)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }

      const action = $(e.target).attr('data');      
      const env = JSON.parse(sessionStorage.getItem('userApp'));
      // User clicks on EDIT option from context menu below code will execute
      if (action === 'Edit') {
        parent.title = "Edit Tenant Parameters";
        parent.isEdit = true;
        parent.getTenant = row;        
        parent.tenant = parent.getTenant;
        parent.getTenantDetails(parent.getTenant.tenant_id);
        $('#tenant_modal').modal('show');
      // User clicks on DELETE option from context menu below code will execute
      } else if (action === 'Delete') {
        parent.getTenant = row;
        parent.tenant = parent.getTenant;
        parent.setDeleteTenantForm();
        $('#delete_tenant_modal').modal('show');
      } else if (action === 'Clone') {
        // window.open(`${parent.apiUrl}/users/${parent.loggedInUserDetails.user_id}/tenants/${parent.loggedInUserDetails.tenant_id}/datasets/${row.tenant_id}/export`, '_self');
      }
    });
  }

  // Get tenant details in the pop-up
  getTenantDetails(tenant_id) {
    this.objectsList = [];
    this.uniques = [];
    const tenantInfo = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: tenant_id
    };

    this.contentSvc.getTenant(tenantInfo).subscribe(res => {
      if (res) {
        this.tenant = res;
        this.displayTenantApps(res.apps);
        this.contentSvc.getLogo(this.loggedInUserDetails.user_id, tenant_id).subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener('load', () => {
            this.tenant.image = reader.result;
          }, false);
          if (image && image.size) {
            reader.readAsDataURL(image);
          } else {
            this.tenant.image = this.logoImage;
          }
        });
        this.tenantForm.patchValue({
          tenant_id: res.tenant_id,
          name: res.name,
          description: res.description,
          quota_type_lookup_id: res.quota_type_lookup_id,
          quota_type_value: res.quota_type_value,
          enable_dq_rule: res.enable_dq_rule,
          is_azure_sos_tenant: res.is_azure_sos_tenant,
          is_trial_tenant: res.is_trial_tenant,
        });
        this.isDataReady = true;
        if (res.admins && res.admins.length > 0) {
          this.uniques = res.admins.map((item) => {
            item['action'] = 'edit';
            item['id'] = Math.random().toString(36).slice(-6);
            item['entity_id'] = '-1';
            item['description'] = item['first_name'];
            item['name'] = item['last_name'];
            item['user_name'] = item['user_name'];
            item['email'] = item['email'];
            return item;
          });          
          // this.gridObj.dataView.refresh();
          this.clusterValue = res.cluster_information.clustureType;
          this.mapClusterValues(res.cluster_information);
          this.validateForm();
          this.objectsList = [];
        }
        this.objectsList = [];
        if (this.tenantGrid || res.admins.length > 0) {          
          this.objectsList = [{
            'id': Math.random().toString(36).slice(-6),
            'entity_id': '-1',
            'description': '',
            'name': '',
            'user_name': '',
            'email': '',
            'action': 'add'
          }, ...this.uniques];          
          // this.gridObj.dataView.refresh();
          this.tenantGrid.dataView.setItems([{
            'id': Math.random().toString(36).slice(-6),
            'entity_id': '-1',
            'description': '',
            'name': '',
            'user_name': '',
            'email': '',
            'action': 'add'
          }]);
        }
      } else {
        if (this.tenantGrid) {
          this.objectsList = [{
            'id': Math.random().toString(36).slice(-6),
            'entity_id': '-1',
            'description': '',
            'name': '',
            'user_name': '',
            'email': '',
            'action': 'add'
          }];
          this.tenantGrid.dataView.setItems([
            {
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }]);
        }
      }

    })
  }
  
  mapClusterValues(values) {
    const clusterValue = values.clustureType;
    this.clusterValue = values.clusterType;
    this.tenantForm.controls['clustureType'].patchValue(values.clustureType);
    this.tenant.clustureType = values.clustureType;
    switch (clusterValue) {
      case '14846':
        this.tenant.livyEndPoint = values.livyEndPoint;
        this.tenant.livyUsername = values.livyUsername;
        this.tenant.livyPassword = values.livyPassword;
        this.tenantForm.controls['livyEndPoint'].patchValue(values.livyEndPoint); 
        this.tenantForm.controls['livyUsername'].patchValue(values.livyUsername);
        this.tenantForm.controls['livyPassword'].patchValue(values.livyPassword);
        let livyInfo = {
          clustureType: values.clusterValue,
          livyEndPoint: values.livyEndPoint,
          livyUsername: values.livyUsername,
          livyPassword: values.livyPassword
        }
        return livyInfo;
          break;
      case '14847':
        if(values.credentialType==='token') {
          this.dataBricksVal = 'token';
          this.tenant.token = values.engine_auth_token;
            this.tenant.dbUrl = values.dbUrl,
            this.tenant.clusterId = values.clusterId,
            this.tenant.deploymentMode = values.credentialType,
            this.tenant.engine_auth_token = values.engine_auth_token
            this.tenant.clustureType = values.clustureType;

            this.tenantForm.controls['token'].patchValue(values.token); 
            this.tenantForm.controls['dbUrl'].patchValue(values.dbUrl);
            this.tenantForm.controls['clusterId'].patchValue(values.clusterId);
            this.tenantForm.controls['deployment_mode'].patchValue(values.credentialType);
            this.tenantForm.controls['token'].patchValue(values.engine_auth_token);
        } else {
          this.dataBricksVal = 'dbcreds';
            clustureType: values.clusterValue,
            this.tenant.dbUrl = values.dbUrl,
            this.tenant.clusterId = values.clusterId,
            this.tenant.deploymentMode = 'userOrPassword',
            this.tenant.dbUserName = values.dbUserName,
            this.tenant.dbPassword = values.dbPassword
            this.tenant.clustureType = values.clustureType;

            this.tenantForm.controls['token'].patchValue(values.token); 
            this.tenantForm.controls['dbUrl'].patchValue(values.dbUrl);
            this.tenantForm.controls['clusterId'].patchValue(values.clusterId);
            // this.tenantForm.controls['deploymentMode'].patchValue(values.deploymentMode);
            this.tenantForm.controls['deployment_mode'].patchValue(values.credentialType);
            this.tenantForm.controls['dbUserName'].patchValue(values.dbUserName);
            this.tenantForm.controls['dbPassword'].patchValue(values.dbPassword);
        }
          break;
      case '14848':
          break;
      case '14849':
        return {
          clustureType: clusterValue,
          projectId: 'proj',
          GCSStagingURI: '',
          clusterName: ''
        }
          break;
      case '14850':
          break;
      case '14854':
        return {
          clustureType: clusterValue,
          livyEndPoint: 'LIVY',
          username:'livyUserName',
          password: 'livyPassword'
        }
      default:
    }
  this.validateDbForm();
  this.validateForm();
  }

  getClusterInfo() {
    const clusterValue = this.tenantForm.controls['clustureType'].value;
    switch (clusterValue) {
      case '14846':
         const livyInfo = {
          clustureType: clusterValue,
          livyEndPoint: this.tenantForm.controls.livyEndPoint.value,
          livyUsername: this.tenantForm.controls.livyUsername.value,
          livyPassword: this.tenantForm.controls.livyPassword.value
        };
        return livyInfo;
          break;
      case '14847':
        if(this.dataBricksVal==='token') {
          let clustInfo = {
              clustureType: clusterValue,
              dbUrl: this.tenantForm.controls.dbUrl.value,
              clusterId: this.tenantForm.controls.clusterId.value,
              credentialType: 'token',
              engine_auth_token: this.tenantForm.controls.token.value
            }
            return clustInfo;
        } else {
          let clustInfo = {
              clustureType: clusterValue,
              dbUrl: this.tenantForm.controls.dbUrl.value,
              clusterId: this.tenantForm.controls.clusterId.value,
              credentialType: 'dbcreds',
              dbUserName: this.tenantForm.controls.dbUserName.value,
              dbPassword: this.tenantForm.controls.dbPassword.value
          };
          return clustInfo;
        }
            break;
        case '14848':
            break;
        case '14849':
            break;
        case '14850':
            break;
        case '14854':
            break;
        default:
          console.log('DEFAULT ');   
    }
  }

  displayTenantApps(apps) {
    this.tanent_apps.forEach((element, index) => {
      apps.find(app => {
        if (element.app_id === app.app_id) {
          element.is_checked = true;
          element.ex_date = this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
        }
      });
    });
  }

  // Display Licensed solution details on edit pop-up
  applyApps(apps) {   
    if(apps && apps.length>0){      
    let exisApps= [];
     apps.forEach(app => {
      if(app.app_id===1) {
        app['is_checked']=true,
        app['app_icon']= this.iconImage+"Zs_resolve.png",
        app['app_id']=app.app_id,
        app['name']="ZettaSense",
        app['ex_date']=this.datePipe.transform(app.auto_expire_on_ts, 'shortDate')
      } else if(app.app_id===3) {
        app['is_checked']=true,
        app['app_icon']= this.iconImage+"Zs_classify.png",
        app['app_id']=app.app_id,
        app['name']="Classify",
        app['ex_date']=this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
      } else if(app.app_id===4) {
        app['is_checked']=true,
        app['app_icon']= this.iconImage+"Zs_ingest.png",
        app['app_id']=app.app_id,
        app['name']="Ingest",
        app['ex_date']=this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
      }
     });
    let nonExistApps = [{app_id:1},{app_id:3},{app_id:4}].filter(obj => apps.every(s => s.app_id !== obj.app_id));
        let newApp = [];
        nonExistApps.forEach(ap => {
          ap['is_checked']=false,
          ap['app_icon']=ap.app_id===1 ? this.iconImage+"Zs_resolve.png" : 
          (ap.app_id===3 ? this.iconImage+"Zs_classify.png": this.iconImage+"Zs_ingest.png"),
          ap['app_id']=ap.app_id,
          ap['name']=ap.app_id===1 ? "ZettaSense" : 
          (ap.app_id===3 ? "Classify": "Ingest"),
          ap['ex_date']=''
        })
        this.tanent_apps=[...apps];
        nonExistApps.forEach(nonApp=> {
          apps.push(nonApp);
        });
    }
  }

  setDeleteTenantForm() {
    this.deleteTenantForm = this.formBuilder.group({
      tenant_id: [this.getTenant.tenant_id],
      tenant_data_retention_days: [0, [Validators.required, Validators.min(0)]]
    });
  }

  getClustureManagementTypes(){
    this.contentSvc.getClustureManagerTypes().subscribe(res=>{
      res.map(element => {
        if(element.lookup_id === 14846 || element.lookup_id === 14847 ) {
          element['disable'] = false;
        } else {
           element['disable'] = true;
        }
      });
      this.clusterManagementTypes=res;
      return res;
    },err=>{
      console.log("error is there is clusture");
    })
  }
  
  onResize() {
    this.panelScreen = $('#tenanManagementPanel').outerWidth();
    if (this.panelScreen < 480) {
      this.buttonWrap = true;
    } else {
      this.buttonWrap = false;
    }
  }
  objectGrid(grid: AngularGridInstance) {
    this.tenantGrid = grid;

    this.tenantGrid.slickGrid.onSort.subscribe((e, args) => {
      this.readObjectDetails();
      const firstRow = grid.gridService.getDataItemByRowIndex(0);
      setTimeout(() => {
          this.setNewObjectDetails(firstRow);
          grid.gridService.deleteDataGridItem(firstRow);
          const currentData = grid.dataView.getItems();
          currentData.splice(0, 0, firstRow);
          grid.dataView.setItems(currentData);
          grid.gridService.setSelectedRows([]);
      }, 500);
    });
  }
  getManageTenants() {
    this._router.navigate(['/zs/manage-tenants']);
  }
  getAddObjectRow() {
    return { 
        'id': Math.random().toString(36).slice(-6), 
        'entity_id': '-1', 
        'description': '', 
        'name': '', 
        'user_name': '', 
        'email': '', 
        'action': 'add' 
    };
  }

  editTenantClick() {
    this.getTenantApps();
    this.title = 'Edit Tenant Parameters';
    this.isEdit = true;
    this.setTenantForm();
  }

  // On Check/Uncheck license app solution
  onAppChange(isChecked, i) {
    this.tanent_apps.forEach((element, index) => {
      if (index === i) {
        element.is_checked = isChecked;
      }
    });
    this.validateForm();
  }

  isTenantExpiryDateValid(tenantAppExpiryDate) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    tenantAppExpiryDate.setHours(0, 0, 0, 0);
    if (tenantAppExpiryDate && tenantAppExpiryDate.getTime() >= currentDate.getTime()) {
      return true;
    }
    return false;
  }
  // On Check/Uncheck license app date solution
  onAppDateChange(date, i) {
    this.tanent_apps.forEach((element, index) => {
      if (index === i) {
        element.ex_date = this.isTenantExpiryDateValid(date) ? this.datePipe.transform(date, 'shortDate'): null;
      }
    });
    console.log('APp  ', this.tanent_apps);
    this.validateForm();
  }

  validateForm(){ 
    const tenantAdmins = this.tenantGrid.dataView.getItems();
    const apps = this.tanent_apps.filter(app => app.is_checked == true && app.ex_date);
    const clusterType = this.tenantForm.controls['clustureType'].value;
    let isQuotaValueBetween = this.tenantForm.controls.quota_type_value.value > 0 && this.tenantForm.controls.quota_type_value.value < AppGlobals.MAXIMUM_QUOTA_VALUE ? true : false;
    const isQuotaType = this.tenantForm.controls.quota_type_lookup_id.value != -1 && isQuotaValueBetween ? true : false;
    if(!this.isEdit) {
      if (apps.length && tenantAdmins && tenantAdmins.length > 1 && clusterType && clusterType.length > 0 && clusterType !== '-1' && isQuotaType) {
        if(clusterType === '14846') { 
          this.tenantForm.patchValue({ 
            dbUrl:null,
            clusterId:null,
            dbUserName:null,
            dbPassword:null
          })        
            if(this.tenantForm.controls['livyEndPoint'].value && this.tenantForm.controls['livyUsername'].value && this.tenantForm.controls['livyPassword'].value ) {
              this.isFormValid = true;
            } else {
              this.isFormValid = false;
            }
          } if(clusterType === '14847') {
            this.tenantForm.patchValue({ 
              livyEndPoint:null,
              livyUsername:null,
              livyPassword:null
            })
            if(this.tenantForm.controls['dbUrl'].value && this.tenantForm.controls['clusterId'].value){
              if(this.dataBricksVal==='token' && this.tenantForm.controls['token'].value){
                this.isFormValid=true;
              }
              else if(this.dataBricksVal!=='token') {
                if(this.tenantForm.controls['dbUserName'].value && this.tenantForm.controls['dbPassword'].value){
                  this.isFormValid=true;
                }
              }else{
                this.isFormValid=false;
              }
            }else{
              this.isFormValid=false;
            }                  
          }
        }else{
          this.isFormValid=false;
        }  
    } else {
      this.isFormValid = true;
      if (apps.length && this.uniques && this.uniques.length > 0 && clusterType && clusterType !== '-1' && isQuotaType) {
        if(clusterType === '14846') {
          if(this.tenantForm.controls['livyEndPoint'].value && this.tenantForm.controls['livyUsername'].value && this.tenantForm.controls['livyPassword'].value ) {
            this.isFormValid = true;
          } else {
            this.isFormValid = false;
          }
        } else if(clusterType === '14847') {
          if(this.dataBricksVal!=='token') {
            this.isFormValid=true;
          } else if(this.dataBricksVal==='token' &&  this.tenantForm.controls['token'].value) {
           this.isFormValid=true;
          }        
        }
      }else{
        this.isFormValid=false;
      }
    }
  }


  
  setTenantForm() {
    this.tenantForm = this.formBuilder.group({
      name: [this.tenant.name],
      description: [this.tenant.description],
      created_by: [this.tenant.tenant_admin_name],
      email: [this.tenant.tenant_admin_email],
      first_name: [this.tenant.first_name],
      last_name: [this.tenant.last_name],
      tenant_id: [this.tenant.tenant_id],
      app_name:[],
      ex_date: '',
      is_checked: false,
      clustureType:'-1',
      authentication_method:'-1',
      deployment_mode:'-1',
      token: '',
      dbUrl:'',
      clusterId: '',
      dbUserName: '',
      dbPassword:'',
      livyEndPoint:'',
      livyUsername:'',
      livyPassword:'',
      is_trial_tenant:false,
      is_azure_sos_tenant:false,
      enable_dq_rule: false,
      quota_type_lookup_id: '-1',
      quota_type_value: [this.tenant.quota_type_value]
      
    });
    this.isFormValid=false;
  } 

  onSave() {
    this.isDataReady = true;
    const apps =this.tanent_apps.filter((app) => {
      return app.is_checked==true && app.ex_date
    });
    let tanent_app=[];
    apps.forEach(el => {
        tanent_app.push({
          'app_id':el.app_id,
          'auto_expire_on_ts':this.datePipe.transform(el.ex_date, 'yyyy-MM-dd')
        });
    });
    
    if (this.tenantForm.valid) {
      const tenantTemp: any = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.tenant.tenant_id,
      };
      if (this.isEdit) {
        this.uniques.forEach(admin => {
          admin['first_name']=admin.description,
          admin['last_name']=admin.name,
          admin['user_name']=admin.user_name,
          admin['email']=admin.email,
          delete admin.description,
          delete admin.name,
          delete admin.id,
          delete admin.entity_id,
          delete admin.action,
          delete admin.synonyms
        })
        const adminUser = this.uniques;
        tenantTemp.payload = {
          'name': this.tenantForm.controls.name.value,
          'description': this.tenantForm.controls.description.value,
          'default_app_id':apps[0].app_id,
          'apps':tanent_app,
          'app_id':this.loggedInUserDetails.preference.default_app_id,
          'is_trial_tenant':this.tenantForm.controls.is_trial_tenant.value ? true: false,
          'is_azure_sos_tenant':this.tenantForm.controls.is_azure_sos_tenant.value ? true: false,
          'enable_dq_rule':this.tenantForm.controls.enable_dq_rule.value ? true: false,
          'engine_type_id': this.tenantForm.controls['clustureType'].value==='14847'?452:451,
          'admin':adminUser,
          'cluster_information': this.getClusterInfo(),
          'quota_type_lookup_id': this.tenantForm.controls.quota_type_lookup_id.value,
          'quota_type_value': parseInt(this.tenantForm.controls.quota_type_value.value)
        };
        this.contentSvc.editTenant(tenantTemp).subscribe(responseList => {
          if(responseList) {
            this.tenants['currentpage'] = [];
            this.grid.dataView.setItems([]);
            this.messageSvc.sendMessage({ message: 'Tenant updated successfully', type: 'SUCCESS', hideboard: true });
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.getTenantList(1, this.limit);
            this.isDataReady = false;
          }
        }, err => {
          if (err.error.error) {
            let isLimitExceeds: Boolean = err.error.error.includes('out of range');
            if (isLimitExceeds) {
              this.messageSvc.sendMessage({ message: AppGlobals.QUOTA_TYPE_LIMIT_EXCEEDS_MSG, type: 'ERROR', hideboard: true });
            } else {
              if (err.status === 400) {
                this.messageSvc.sendMessage({ message: err.error.message ? err.error.message : err.error.error, type: 'ERROR', hideboard: true });
              } else {
                this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
              } 
            }
          }
        });
        this.getTenantApps();
      } else {
        this.uniques.forEach(admin => {
          admin['first_name']=admin.description,
          admin['last_name']=admin.name,
          admin['user_name']=admin.user_name,
          admin['email']=admin.email,
          delete admin.description,
          delete admin.name,
          delete admin.id,
          delete admin.entity_id,
          delete admin.action,
          delete admin.synonyms
        })
        const adminUser = this.uniques;
        tenantTemp.payload = {
          'name': this.tenantForm.controls.name.value,
          'description': this.tenantForm.controls.description.value,
          'default_app_id':apps[0].app_id,
          'apps':tanent_app,
          'app_id':this.loggedInUserDetails.preference.default_app_id,
          'is_trial_tenant':this.tenantForm.controls.is_trial_tenant.value ? true: false,
          'is_azure_sos_tenant':this.tenantForm.controls.is_azure_sos_tenant.value ? true: false,
          'enable_dq_rule':this.tenantForm.controls.enable_dq_rule.value ? true: false,
          'admin':adminUser,
          'engine_type_id': this.tenantForm.controls['clustureType'].value==='14847'?452:451,
          'cluster_information': this.getClusterInfo(),
          'quota_type_lookup_id': this.tenantForm.controls.quota_type_lookup_id.value,
          'quota_type_value': parseInt(this.tenantForm.controls.quota_type_value.value)
        };
        this.objectsList=[{
          'id': Math.random().toString(36).slice(-6), 
          'entity_id': '-1', 
          'description': '', 
          'name': '', 
          'user_name': '', 
          'email': '', 
          'action': 'add' 
      
        }];
        this.uniques=[];
        if(this.dataBricksVal==='token') {
          tenantTemp.payload['engine_auth_token'] = this.tenantForm.controls.token.value
        }
        this.contentSvc.createTenant(tenantTemp).subscribe(responseList => {
          if(responseList) {
          this.messageSvc.sendMessage({ message: 'Tenant created successfully', type: 'SUCCESS', hideboard: true });
          this.isFormValid = false;
          this.tenants['currentpage'] = [];
          if (this.gridObj) {
              this.gridObj.dataView.setItems([]);
            }
          $('.modal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          this.getTenantList(1, this.limit);
        }
        }, err => {
          if (err.status === 400) {
            this.messageSvc.sendMessage({ message: err.error.message ? err.error.message : err.error.error, type: 'ERROR', hideboard: true });
          } else {
            this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
          } 
          this.isFormValid=false;
        });
      }
    }
  }

  onClose() {
    this.isLoading = true;
    this.getTenantList(this.page, this.limit);
    this.getTenantApps();
  }
  
  onDelete() {
    this.isLoading = true;
    const deleteTenant: any = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.deleteTenantForm.controls.tenant_id.value,
      payload: {
        body: {
          'tenant_data_retention_days': this.deleteTenantForm.controls.tenant_data_retention_days.value
        }
      }
    };
    this.contentSvc.deleteTenant(deleteTenant).subscribe((result) => {
      this.isLoading = false;
      window.location.reload();
    }, (error) => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: 'Delete Tenant has failed', type: 'INFO', hideboard: true });
    });
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  saveLogo(ev) {
    if (ev.target.files && ev.target.files[0]) {
      let fd = new FormData();
      let reader = new FileReader();
      fd.append('logo', ev.target.files[0]);
      const logoPayload: any = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.getTenant.tenant_id,
        payload: fd
      };
      this.contentSvc.uploadLogo(logoPayload).subscribe(responseList => {
        reader.onload = (e: any) => {
          this.getTenant.image = e.target.result;
          this.grid ? this.grid.slickGrid.invalidate() : '';
          this.is_logo=true;
        }
        reader.readAsDataURL(ev.target.files[0]);
        this.messageSvc.sendMessage({ message: 'Logo Updated', type: 'INFO', hideboard: true });
      }, err => {
      });
    }
  }

  // On click of create new tenant button below function will execute
  createTenantClick() {
    this.objectsList = [];
    this.getTenantApps();
    this.title = 'Create New Tenant';
    this.isEdit = false;
    this.tenant = {
      'name': '',
      'description': '',
      'system_user_name': '',
      'system_user_password': '',
      'system_user_email': '',
      'image': this.logoImage,
      'is_trial_tenant': false,
      'is_azure_sos_tenant': false,
      'enable_dq_rule': false
    };

    if (this.tenantGrid) {
      const firstRow = this.getAddObjectRow();
      this.setNewObjectDetails(firstRow);
      this.objectsList.splice(0, 0, firstRow);
      this.isDataReady = true;
      this.tenantGrid.dataView.refresh();
      this.tenantGrid.dataView.setItems(this.objectsList);
      this.uniques = this.objectsList;
    }
    
    this.setTenantForm();
  }


  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
  }

  selectDB(val) {
    this.dataBricksVal = val;
    if(val==='token') {
      this.isFormValid = false;
    }
  }

  validateDbForm() {
    const dbVal = this.dataBricksVal==='token';
    if(dbVal) {
      if(this.tenantForm.controls['token'].value && this.tenantForm.controls['token'].value.length>0) {
        this.validateForm();
      } else {
        this.isFormValid = false;
      }
    } else {
      return;
    }
  }

}
