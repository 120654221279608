import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { Analytic } from '../../analytic.model';
import { AnalyticService } from '../../analytic.service';
import { AngularGridInstance } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../zm-project-progress/project-progress.model';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';
import { dragIconFormatter } from '../../../../../common/zm-shared/zm-formatters/dragIconFormatter';

const RIGTH_EMPTY_GRID_MSG = 'Please select the attributes that you want to use as your inputs into the model. You can select as many variables as you like.';
const LEFT_EMPTY_GRID_MSG = 'No data to map.';

@Component({
    selector: 'define-segments',
    templateUrl: './define-segments.component.html',
    styleUrls: ['./define-segments.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DefineSegComponent implements OnInit, OnDestroy {
    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    analyticDetail: Analytic = new Analytic();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;

    tableSettings: object = { 'height': '100%' };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];
    errorRibbon = false;

    constructor(
        public zettaUtils: ZmZettaUtils,
        public analyticSvc: AnalyticService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: false,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: false,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.activatedRoute.parent.params.subscribe(params => {
            this.analyticDetail.analyticid = params['id'];
        });

        // this.analyticSvc.getCurrentActiveJobs(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid).subscribe(
        //     jobs => {
        //         this.hasActiveJob = this.analyticSvc.hasActiveJob(jobs);
        //     });
        this.hasActiveJob = false;

        this.getUsersList();
    }

    initColDef() {
        this.leftGridColumnsDef = [{
            'displayname': '',
            'physicalname': '',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'maxWidth': 24,
            'params': { 'type': 'segment', 'grid': 'left' },
            'formatter': dragIconFormatter,
        }, {
            'displayname': 'Attribute',
            'physicalname': 'targetattribute',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Data Type',
            'physicalname': 'datatype',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];

        this.rightGridColumnsDef = [{
            'displayname': '',
            'physicalname': '',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'maxWidth': 24,
            'params': { 'type': 'segment', 'grid': 'right' },
            'formatter': dragIconFormatter,
        }, {
            'displayname': 'Prediction Outcome',
            'physicalname': 'targetattribute',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];
    }

    getUsersList() {
        // this.analyticSvc.getProjectDataSets(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid, this.loggedInUserDetails['groupid']).subscribe(responseList => {
        this.analyticSvc.getAnalyticDataSets(this.loggedInUserDetails['userid'], '1', this.loggedInUserDetails['groupid']).subscribe(responseList => {
                this.rightGridDataSource = responseList[1]['currentpage'][0]['datasets'][2]['attributes'];
                this.rightGridDataSource = this.rightGridDataSource.filter(attribute1 => attribute1.setdata === 'segment');
                this.rightGridRows = this.rightGridDataSource.length;
                // this.leftGridDataSource = responseList[0];
                // this.leftGridDataSource = responseList[1]['datasets'][0].attributes;
                this.leftGridDataSource = responseList[1]['currentpage'][0]['datasets'][2]['attributes'];

                // this.progressState.states = this.analyticSvc.getProjectStateList();
                this.progressState.states = this.zettaUtils.getStateList('create-analytic');
                this.progressState.currentStateIndex = 3;
                this.progressState.currentStateInfo = null; 
                this.progressState.type = 'Analitycs';
                this.progressState.project = this.analyticDetail;
                this.progressState.project.name = "Mortgage Default Prediction Model Analytics";            //example
                this.progressState.project.img = "icon-analytics-correlation.png";            //example

                // this.leftGridDataSource = this.leftGridDataSource.filter(attribute1 => !this.rightGridDataSource.find(attribute2 => attribute1.attributeid === attribute2.attributeid));
                this.leftGridRows = this.leftGridDataSource.length;
                this.initColDef();
                this.isDataReady = true;
            }, err => {
                // alert('error');
            });
    }

    changeItemRowCssClass() {
        const leftGridDataService = this.leftGrid.gridService;
        this.leftGrid.dataView.getItemMetadata = this.updateItemRowCssClass(this.leftGrid.dataView.getItemMetadata);
        leftGridDataService.renderGrid();
    }

    updateItemRowCssClass(previousItemMetadata: any) {
        const newCssClass = 'item-disabled';
        return (rowNumber: number) => {
            const item = this.leftGrid.dataView.getItem(rowNumber);
            let meta = { cssClasses: '' };
            if (typeof previousItemMetadata === 'object') {
                meta = previousItemMetadata(rowNumber);
            }
            if (meta && (item.setdata == 'training' || item.setdata == 'prediction' || item.setdata == 'output' || item.setdata == 'input' || item.setdata == 'segment')) {
                meta.cssClasses = (meta.cssClasses || '') + ' ' + newCssClass;
            }
            return meta;
        };
    }

    onAdd() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const attributeList: Object[] = [];
        const rows: any[] = [];

        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
           
            row.setdata = 'segment'; 

            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            row['selectedRoles'] = [];
            this.rightGridDataSource.push(row);
            rightGridDataService.addItemToDatagrid(row, true);
            // remove Item from left grid
            attributeList.push({ attributeid: row.attributeid });
            // this.leftGridDataSource = this.leftGridDataSource.filter(attribute => attribute.attributeid !== row.attributeid);
            // leftGridDataService.deleteDataGridItem(row);
        });
        
        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        // Update Row Count
        this.leftGridRows = this.leftGridRows - attributeList.length;
        this.rightGridRows = this.rightGridRows + attributeList.length;
        // update DB
        // this.updateProject(userList, 'ADD');

        // update CssClass for each row
        this.changeItemRowCssClass();

    }

    onRemove() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const attributeList: String[] = [];
        const rows: any[] = [];

        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);

            row.setdata = '';

            if (row !== undefined && row !== null) {
                row.roles = [];
                rows.push(row);
            }
        });

        rows.forEach(row => {
            // this.leftGridDataSource.push(row);
            // leftGridDataService.addItemToDatagrid(row, true);
            // remove Item from right grid
            attributeList.push(row.attributeid);
            this.rightGridDataSource = this.rightGridDataSource.filter(attribute => attribute.attributeid !== row.attributeid);
            rightGridDataService.deleteDataGridItem(row);
        });
        // this.leftGrid.slickGrid.setData();
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);

        this.leftGridRows = this.leftGridRows + attributeList.length;
        this.rightGridRows = this.rightGridRows - attributeList.length;
        // this.updateProject(attributeList, 'DELETE');

        // update CssClass for each row
        this.changeItemRowCssClass();

    }

    rightGridCreated(grid) {
        this.rightGrid = grid;
    }
    leftGridCreated(grid) {
        this.leftGrid = grid;

        this.changeItemRowCssClass();

    }

    updateAnalytic(datasets: any[], opcode: string) {
        if (datasets.length > 0) {
            const analytic = new Analytic();
            analytic.analyticid = this.analyticDetail.analyticid;
            analytic.userid = this.loggedInUserDetails['userid'];
            analytic.groupid = this.analyticDetail.groupid;
            if ('ADD' === opcode) {
                analytic.datasets = datasets;
            } else {
                analytic.deleteusers = datasets;
            }
            this.analyticSvc.saveAnalytic(analytic).subscribe(resp => {
                $('#projectState1').removeClass('d-none');
                setTimeout(function () {
                    $('#projectState1').addClass('d-none');
                }, 5000);
            });
        }
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zm/analytics']);
        } else {
            // this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
            this.router.navigate(['/zm/analytics/1']);
        }
    }

    onPrevious() {
        // this.router.navigate(['/zm/analytics/15/define-inputs'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
        this.router.navigate(['/zm/analytics/1/define-inputs'], {queryParams: {'id': '1', showBreadcrumb: this.showBreadcrumb}});
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    addUserRole(boolean: boolean) {
        // const selectedProjectAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
        // const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
        // const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
        // if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
        //     selectedProjectReviewerList.length === 0) {
        //     this.errorRibbon = true;
        // } else {
        //     this.errorRibbon = false;
        //     this.saveUserRole(boolean);
        // }

        if (boolean) {
            this.router.navigate(['/zm/analytics/1']);
        } else {
            // this.router.navigate(['/zm/analytics/15/schedule-model'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
            this.router.navigate(['/zm/analytics/1/schedule-model'], {queryParams: {'id': '1', showBreadcrumb: this.showBreadcrumb}});
        }

    }

    saveUserRole(bool: boolean) {
        this.rightGridDataSource.map(resp => {
            this.selectedUserRoles.push({ userid: resp.userid, roles: resp.selectedRoles });
            this.selectedUserRoles = this.selectedUserRoles.filter(res => res.userid);
        });
        this.analyticSvc.saveUserRoles(this.loggedInUserDetails['userid'], this.analyticDetail.analyticid,
        this.selectedUserRoles).subscribe(resp => {
            if (bool) {
                this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
            } else {
                this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid, 'datasets'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
            }
        });
    }

}
