import { Component, OnInit, ViewEncapsulation, OnDestroy, SimpleChange, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Formatter, AngularGridInstance, Editors, FieldType, AngularUtilService } from 'angular-slickgrid';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ContentService } from '../../../zettasense/content/content.service';
import { Event, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Entity, Attribute } from '../../../zettasense/content/entities/entity.model';
import { ZsClContentService } from '../zs-cl-content.service';
import { Object } from '../classify.model';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';   
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { numericFormatter } from 'src/app/common/shared/formatters/numericFormatter';
import { totalRulesFormatter } from 'src/app/common/shared/formatters/totalRulesFormtter';
import { AppGlobals } from '../../../common/shared/app.globals';

declare var $: any;

const DATA_MSG = '';

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if( dataContext.action !== 'edit'){
        if (columnDef.field !== 'actions') {
            return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>` 
                                         : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v" aria-hidden="true" style="font-size:22px;"></i></span>`;
        } else {
            return ``;
        }
    }else {
        return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>` 
                                     : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v" aria-hidden="true" style="font-size:22px;"></i></span>`;
    }
};

const actionsObjectFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.field === 'action') {
      if (dataContext.action === 'edit') {
        let editIcon = '';
        let iscatalogAdmin = sessionStorage.getItem("isCatalogAdmin");
        if (iscatalogAdmin == "true") {
          editIcon = 'actionInlineBtn';
        } else {
          editIcon = 'disableEdit';
        }
        return `<div class="text-center w-100 number catalog-concepts" title="${value}">
                    <i id='edit-${row}' class="fal fa-pen ${editIcon}"></i>
                </div>`
      } else {
        let iscatalogAdmin = sessionStorage.getItem("isCatalogAdmin");
        if(iscatalogAdmin == 'true') {
          return `<div class="text-center w-100 number" title="${value}">
                    <i class="fa fa-plus actionInlineBtn"></i>
                  </div>`
        } else {
          return `<div class="text-center w-100 number" title="${value}">
                    <i class="fa fa-plus not-allowed-cursor"></i>
                  </div>`
        }
      }
  } else if (columnDef.field === 'mapped_synonyms') {
    if (dataContext.action === 'edit') {     
      return value > 0 ? `<div class="text-right pointer pr-3" title='${value}'>
                            <i class="fal fa-link actionInlineBtn blueLink no-underline pr-2"></i>
                            <span class="blueLink no-underline">${value}</span>
                          </div>` 
                       : `<div class="text-right pointer pr-3" title='${value}'>
                            <i class="fal fa-link actionInlineBtn blueLink no-underline pr-2"></i>
                            <span class="disabled">0</span>
                          </div>`
    } else {
      return ``;
    }
  } else if (columnDef.field === 'mapped_data_sources') {
        if(dataContext.action === 'edit'){
            if(value > 0) {
            return `<div class="text-right blueLink pointer pr-3" title='${value}'>
                        ${value}
                    </div>`;
            } else {
            return `<div class="text-normal text-right pr-3" title='${value}'>
                        ${value}
                    </div>`;
            }
        } else {
            return ``;
        }
    } else if (columnDef.field === 'mapped_data_sets') {
        if(dataContext.action === 'edit'){
              return `<div class="text-right blueLink pointer pr-3" title='${value}'>
                          ${value}
                      </div>`;
        } else {
            return ``;
        }
    } else {
    if (dataContext.action === AppGlobals.editTxt) {
      if (dataContext.iscatalogUser == true && (dataContext.attribute_count > 0 )) {
        return `<div class="text-center w-100 number train-modal-icon">
                            <i class="fas fa-graduation-cap actionInlineBtn"></i>
                  </div>`
      }
      else {
        return `<div class="text-center w-100 number train-modal-icon">
                            <i class="fas fa-graduation-cap not-allowed-cursor"></i>
                </div>`
      }
    } else {
      return ``;
    }
  }
}

const datasourceNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="datasource-name-formatter text-wrap">
            <span title='${dataContext.name}' class="pointer hyper-link">${dataContext.name}</span><br>
            </div>`;
};

const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = value;
  if (value.includes('"')) {
    name = value.replace(/"/g, "'");
  }
  return `<div class="datasource-name-formatter text-wrap">
            <span title="${value}" class="pointer hyper-link">${name}</span><br>
        </div>`;
};

const synonymFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
return `<div id="syn-${value}">
            <span title='${value}' class="pointer hyper-link">${value}</span>
        </div>`;
};

const emptyActionFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
return ``;
};

const mappingFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
 let level = (dataContext.score*100) >= 75 ? 'HIGH' : (dataContext.score*100) >= 50 ? 'MEDIUM' : 'LOW';
 let score100 = Math.round(dataContext.score*100);
 let cssLevel = '';
 let confidenceLevel = '';
 let condLike = !dataContext.enable_up_vote ? 'fas' : 'fal';
 let condUnlike = !dataContext.enable_down_vote ? 'fas' : 'fal';
 cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
 let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
 let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

 confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
 <div class="porcent text-center" title='${score100}%'>${score100}%</div>
 <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
 <span class="blueLink countUp mr-2">${upCount}</span>
 <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
 <span class="blueLink countDown mr-2">${downCount}</span>`;
return confidenceLevel; 
};



const customObjectRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  if (dataContext.action !== AppGlobals.editTxt) {
    const leftPd = columnDef.field === 'semantic_object' ? '' : 'pd-left-1';
    return `<div class="name-obj ${leftPd}"><input id='object-${columnDef.field}-${row}' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' 
                tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value=''></input></div>`;

  } else {
    if (columnDef.field === 'semantic_object') {
      let csslink = columnDef.params.showBreadCrumb ? 'blueLink' : '';
      return `<div id="noEdit-${columnDef.field}-${row}" title="${value}" class="w-100 ${csslink} pr-3 text-truncate">${value}</div>
                    <div id="isEdit-${columnDef.field}-${row}" class="w-100 pr-3" style="display:none">
                        <input id='object-${columnDef.field}-${row}' title='${value}' class='ctmInputBoxNoEdit' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' autocomplete="off">
                    </div>`
    } else {
      return `<div id="noEdit-${columnDef.field}-${row}" title="${value}" class="w-100 pr-3 text-truncate">${value}&nbsp;</div>
                    <div id="isEdit-${columnDef.field}-${row}" class="w-100 pr-3" style="display:none">
                        <input id='object-${columnDef.field}-${row}' title='${value}' class='ctmInputBoxNoEdit' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' autocomplete="off">
                    </div>`
    }
  }
};
const totalRulesFormatter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let isRuleDisabled = false
  let loggedInUserDetail = JSON.parse(sessionStorage.getItem('userInfo'));  
  if(value == 0 || !loggedInUserDetail['enable_dq_rule']){     
    isRuleDisabled = true;      
  }
  if(dataContext.action !=='add'){    
    return isRuleDisabled 
    ?    `<div class="text-right w-100 pd-right-20 disabled-state disabled" title="${value}">${value}</div>`
    :     `<div class="text-right w-100 pd-right-20 blueLink" title="${value}">${value}</div>`
  }  
}

@Component({
  selector: 'zetta-zs-cl-catalog-objects',
  templateUrl: './zs-cl-catalog-objects.component.html',
  styleUrls: ['./zs-cl-catalog-objects.component.scss'],
})

export class ZsClCatalogObjectsComponent implements OnInit, OnDestroy {

    gridOptions: any;
    eventData: any;
    syntableSettings: object = { 'height': "100%", 'width': "100%" };
    tableSettings: object = { 'height': "100%", 'width': "99.6%" };
    datasourceTableSettings: object = { 'height': "100%", 'width': "100%" };
    datasetTableSettings: object = { 'height': "100%", 'width': "100%" };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    pageLeft = 1;
    limitLeft = 20;
    page = 1;
    datasetsPageNo:number = 1;
    totalDatasetsPages:number;
    limit = 100;
    hasScrolled = false;
    noData = false;
    loggedInUserDetails: [];
    selectedFiles: string[] = [];
    isDataReady = false;
    entity: any = [];
    showBreadcrumb = true;
    total = 0;
    grandTotal = 0;
    subTotal = '';
    responseList: any = [];
    dataRes;
    is_upload_complete = false;

    catalog_id;
    objectsList: any;
    catalog: any;
    catalogName: string;
    catalogDescription: string;
    gridObj: AngularGridInstance;
    objectTableColDef: any;
    addObject: Object = new Object();
    addObjects: any = [];
    selectedObjectMetadata: any;
    lastRowSelected = 0;
    loadCatalog = false;
    progressState = new ProjectStatus();
    isCatalogReady = false;
    object_id;
    semantic_objectid;
    semantic_object;
    datasources: any = [];
    datasets: any = [];
    totalDatasources = 0;
    totalDatasets = 0;
    grandTotalDataset = 0;
    subTotalDataset = '';
    isLoadingDatasources = true;
    isLoadingDatasets = true;
    gridDatasources: AngularGridInstance;
    gridDatasets: AngularGridInstance;
    hasDatasourcesScrolled = false;
    hasDatasetsScrolled = false;
    datasourcesTableColDef: any;
    datasetsTableColDef: any;
    gridDataOptions: any;

    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    public modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;
    showmodelpopup=false;
    datasetId:any;

    synonyms: any = [];
    synonymsList: any = [];
    totalSynonyms = 0;
    isLoadingSynonyms = false;
    hasSynonymsScrolled = false;
    gridSynonyms: AngularGridInstance;
    synonymsTableColDef: any;
    gridSynonymsDataOptions: any;
    showLoader: Boolean = true;
    showDownvoteModal:boolean=false;
    object_name:string;
    dataset_id:number;
    datasetColumnId:number;
    showBackdrop:boolean=true;
    showConceptColumn:boolean=false;
    selectedMappedColumn:number;
    dqRuleText:string;
    dqRulecss:string;    
    dqRuleDate: string; 
    emptyRow:any;
    showSynonyms=false;
    synCatalogName:string;
    entity_name:string;
    synPage = 1;
    entity_id:number;
    showSynonymsModel=false;
    entity_attribute_id:string;
    user_id:number;
    semanticObjName;
    semanticObjDescription;
    isFiltering = false;
    isCatalogAdmin: Boolean;
    isEditFlow = false;
    clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
    dataResponse;
    is_upload_complete_classify = false;
    enableRunModel: boolean = false;
    catalogData: object;
    showFeedbackSummaryModel: Boolean=false;
    votedUsers;
    datasourceName;
    datasetName;
    screenName;
    voteValue;
    semanticObjectName;
    isCatalogUser = false;
    noSemanticObject: Boolean = false;
    fu_type_entityAttributes: any;
    fu_type_importConceptTraining: any;
    fileType: string = '';    
    revokeProcessing;
    revokeTrack;
    uploadedCatalogData;
    showProgressModal: boolean = false;
    latestJobId;
    trackJobErrorDetails: string = '';
    constructor(private contentSvc: ContentService,
                private catalogSvc: ZsClContentService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                public zettaUtils: ZettaUtils,
                private projectService: ProjectService,
                private sanitizer: DomSanitizer,
                private baseService: BaseService,
                private messageSvc: MessageService) { }

    ngOnInit() {
      this.fu_type_entityAttributes = AppGlobals.FU_TYPE_ENTITY_ATTRIBUTES;
      this.fu_type_importConceptTraining = AppGlobals.FU_TYPE_IMPORT_CONCEPT_TRAINING;
      sessionStorage.removeItem('serverfilter');
      sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.uploadedCatalogData = JSON.parse(sessionStorage.getItem('catalog_object_data'));
        this.activatedRoute.parent.params.subscribe(params => {
          this.entity.entity_id = params['catalog_id'];
          this.catalog_id = params['catalog_id'];
          this.loadCatalog = this.catalog_id ? true : false;
        });
      this.activatedRoute.queryParamMap.subscribe(params => {
        if (params.has('isEdit')) {
          this.isEditFlow = params.get('isEdit') === "true" ? true : false;
        }
      });

        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');

        if (showBreadcrumb && showBreadcrumb === 'false') {
            this.showBreadcrumb = false;
        } 

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }
        sessionStorage.removeItem("isCatalogAdmin");
        this.catalogSvc.getCatalogUser(this.catalog_id).subscribe(resp => {
          if (resp.length) {
            resp.forEach(user => {
              if (user.user_name == this.loggedInUserDetails['user_name']) {
                this.isCatalogUser = true;
              }
              if(user.user_id == this.loggedInUserDetails['user_id']) {
                const userRoles = user.roles.filter(role => role === 32);
                if (userRoles.length) {
                  sessionStorage.setItem("isCatalogAdmin", "true");
                  this.isCatalogAdmin = true;
                } else {
                  this.isCatalogAdmin = false;
                }
                if(this.isEditFlow || this.showBreadcrumb) {
                  if(user.roles.includes(AppGlobals.CATALOG_ADMIN) || user.roles.includes(AppGlobals.CATALOG_APPROVER) || user.roles.includes(AppGlobals.CATALOG_REVEIWER)) {
                    this.enableRunModel = true;
                  } else {
                    this.enableRunModel = false
                  }
                }
              }
            });
          }
        });
        this.gridOptions = {
            enableGridMenu: false,
            // frozenRow: 1,
            enableAddRow: false,
            selectable: true,
            //editable: true,
            //autoEdit: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            rowHeight: 31,
            // noDataMsg: DATA_MSG,
            // enableMouseHoverHighlightRow: true 
        };

        this.datasourcesTableColDef = [{
            'displayname': 'Data Source',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasourceNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Description',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 200,
          }];

          this.datasetsTableColDef = [{
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Data Set',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink',
            'formatter': datasetNameFormattter,
            'minWidth': 70,
          }, {
            'displayname': 'Mapping Confidence Level',
            'physicalname': 'score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': mappingFormatter,
            'minWidth': 380,
            'maxWidth': 390,
          }];
  
          this.gridDataOptions = {
            enableGridMenu: false,
            selectable: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableFiltering: true,
          };

          this.gridSynonymsDataOptions = {
            enableGridMenu: false,
            selectable: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableFiltering: true,
            // createPreHeaderPanel: true,
            // showPreHeaderPanel: true,
            // preHeaderPanelHeight: 35,
            // explicitInitialization: true,
            // frozenColumn: 2,
          };

          this.catalogData = {
            catalogId: this.catalog_id,
            catalogLevel: 'catalog'
          };
          this.modalGridOptions = {
            enableGridMenu: false,
            selectable: true,            
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true
          };
          // this.catalogSvc.getCatalogs(this.loggedInUserDetails['user_id']).subscribe(resp => { 
          //   const catalogs = [];
          //   resp.forEach(element => {
          //     catalogs.push(+element.catalog_id);
          //   });
            // if (catalogs.includes(+this.catalog_id)) {
              this.getCatalog();
              this.initObjectGrid();
              this.getSemanticsObjects(this.page, this.limit);
            // } else {
              // this.router.navigate(['/zs-cl/catalogs']);
            // }
          // }, err => { });
          this.latestJobId = '';
          this.checkJobQueueStatus(false);
    }


   
getCatalog() {  
    this.catalogSvc.getCatalog(this.catalog_id).subscribe(resp => { 
        this.catalog = resp;
        this.catalogName = resp.name;
        this.catalogDescription = resp.description;
        const ruleObj = this.catalogSvc.getMeasuredRule(resp.measured_dq_score, this.loggedInUserDetails['enable_dq_rule'])
        this.dqRuleText = ruleObj["dqRuleText"]
        this.dqRulecss = ruleObj["dqRuleCss"]
        this.dqRuleDate = this.catalog["last_updated_date"] ? new Date(this.catalog["last_updated_date"]).toLocaleDateString() : new Date(Date.now()).toLocaleDateString();
        sessionStorage.setItem('catalog_user',resp.created_by);
        
        this.progressState.states = this.zettaUtils.getStateList('create-catalog');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Catalog';
        this.progressState.catalog = this.catalog;
        this.progressState.isEdit = this.isEditFlow;
        this.isCatalogReady = true;

        this.synonymsTableColDef = [{
          'displayname': 'Catalog',
          'physicalname': 'catalog_name_left',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Original Catalog: ' + this.catalogName,
        }, {
          'displayname': 'Semantic Object',
          'physicalname': 'entity_name_left',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Original Catalog: ' + this.catalogName,
        }, {
          'displayname': 'Concept',
          'physicalname': 'attribute_logical_name_left',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Original Catalog: ' + this.catalogName,
        }, {
          'displayname': 'Synonym Catalog',
          'physicalname': 'catalog_name_right',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Other Catalogs',
        }, {
          'displayname': 'Synonym Object',
          'physicalname': 'entity_name_right',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Other Catalogs',
        }, {
          'displayname': 'Synonym Concept',
          'physicalname': 'attribute_logical_name_right',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 500,
          'maxWidth': 510,
          // 'columnGroup': 'From Other Catalogs',
        }];

    }, err => {
      this.isCatalogReady = true;
     });
}

objectGrid(grid: AngularGridInstance) {
  this.gridObj = grid;

  this.gridObj.slickGrid.onSort.subscribe((e, args) => {
    this.readObjectDetails();
    const firstRow = grid.gridService.getDataItemByRowIndex(0);
    setTimeout(() => {
        this.setNewObjectDetails(firstRow);
        grid.gridService.deleteDataGridItem(firstRow);
        const currentData = grid.dataView.getItems();
        currentData.splice(0, 0, firstRow);
        grid.dataView.setItems(currentData);
        grid.gridService.setSelectedRows([]);
    }, 500);
  });
}

getSemanticsObjects(pageNo, limit = 1) { 
   this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics?pagination=true&pageno=${1}&pagesize=${limit}`;
   let serverFilter = "";
   if(sessionStorage.getItem("serverfilter")){
      serverFilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
   }else{
    if(this.isFiltering){ 
      pageNo = 1;
    }
   }
   let sortfilter = "";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter = sessionStorage.getItem("sortfilter");
   }
   this.showLoader = true;
    this.catalogSvc.getSemanticsObjectsPaged(this.catalog_id, pageNo, limit, serverFilter, sortfilter).subscribe(resp => {
        this.objectsList = resp['currentpage'];
        this.showLoader = false;
        if (this.objectsList.length === 0) {
            this.noData = true;
            this.noSemanticObject = true;
        }
        if (this.objectsList) {
          this.objectsList = resp['currentpage'].map((item, index) => {  
            item["isRuleEnabled"] = this.loggedInUserDetails["enable_dq_rule"];
                item['action'] = 'edit';
                item['id'] = Math.random();
            if (this.isCatalogUser) {
              item['iscatalogUser'] = true;
            }
            else {
              item['iscatalogUser'] = false;
            }
            return item;
          });
        }
        if(pageNo === 1) {
          const firstRow = this.getAddObjectRow();
          this.emptyRow=firstRow;
          this.setNewObjectDetails(firstRow);
          this.objectsList.splice(0, 0, firstRow);
        }
        this.isDataReady = true;
        this.total = resp['totalrecords'];
        if (pageNo === 1) {
          this.grandTotal = resp['totalrecords'];
        }
        let concatedRows = this.objectsList;
         if (this.gridObj) {
          concatedRows = [...this.gridObj.dataView.getItems(), ...this.objectsList];
          this.objectsList = concatedRows;
          this.gridObj.dataView.setItems(concatedRows);
          this.gridObj.gridService.setSelectedRows([]);
          if (pageNo < resp['totalpages']) {
            this.hasScrolled = false;
          }
         }
         this.showLoader = false;
    }, err => {
      this.showLoader = false;
        this.isDataReady = true;
        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
}

onScroll(parentClass) {
  if (sessionStorage.getItem("serverfilter")) {
    this.isFiltering = true;
    this.noData = false;
  } else {
    if (this.isFiltering) {
      this.page = 1;
      this.isFiltering = false;
      this.noData = false;
    }
  }
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
  // if (!this.hasScrolled && !this.noData) {
    this.hasScrolled = true;
    this.onNextPage();
  }
}

onNextPage(): void {
  this.page++;
  this.getSemanticsObjects(this.page, this.limit);
}

onNextDs(): void {
  this.datasetsPageNo++;
  this.getDatasets(this.semantic_objectid,this.page, this.limit);
}
handleObjectGridClickEvent(eventData) {
  const parent = this;
  this.eventData = eventData;
  const args = eventData.args;
  const event = eventData.eventData;
  const metadata = this.gridObj.gridService.getColumnFromEventArguments(args);
  this.selectedObjectMetadata = metadata;
  const row = this.gridObj.gridService.getDataItemByRowIndex(args.row);
  const fieldName = metadata.columnDef.field;
  let swapRow;
  let updateRow = false;
  var oldValue: any;
  var newValue: any;
  
  if (row !== undefined && row !== null) {
    this.object_id = row.entity_id;
    this.semantic_object = row.semantic_object;
    sessionStorage.setItem("SemanticObjectName",row.semantic_object);

    if (event.target.className.includes('blueLink') && fieldName === 'semantic_object') { 
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects', row.entity_id], { queryParams: { object_id: row.entity_id, showBreadcrumb: this.showBreadcrumb, mapped_datasets: row.mapped_data_sets } });
    }

    if (event.target.className.includes('blueLink') && fieldName === 'mapped_synonyms' && !event.target.className.includes('fal fa-link')) {     
      this.entity_id=row.entity_id
      this.showSynonymsModel = true;
      this.user_id =this.loggedInUserDetails['user_id'];
      this.entity_attribute_id =`entity_id_left=${row.entity_id}`
      this.entity_name = row.semantic_object;
      sessionStorage.removeItem('serverfilter');
      sessionStorage.removeItem('sortfilter');
    }

    if (event.target.className.includes('blueLink') && fieldName === 'mapped_data_sources') { 
        this.getDatasources();
        $('#datasources_modal').modal('show');
    }
    
    if (event.target.className.includes('blueLink') && fieldName === 'mapped_data_sets') { 
      sessionStorage.removeItem('serverfilter');
      sessionStorage.removeItem('sortfilter'); 
      this.datasetsPageNo = 1;
      this.datasets = [];
      if (this.gridDatasets) {
        this.gridDatasets.dataView.setItems(this.datasets);
        this.gridDatasets.gridService.setSelectedRows([]);
      }
      this.getDatasets(row.entity_id,this.datasetsPageNo, this.limit);
        $('#datasets_modal').modal('show');
    }

      let idCell = '#object-' + fieldName + '-' + args.row;

      if(fieldName ==='total_rules'){       
        if (this.loggedInUserDetails['enable_dq_rule'] && row.total_rules > 0) {
          this.catalogSvc.showExceptionsGrid.next({ showException: false, name: "" });
          this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id +'/dq-rule'],
          { queryParams: {catalog_id:this.catalog_id, object_id: row.entity_id, dqRuleLevel: 'object', showBreadcrumb:this.showBreadcrumb } });
        }if(this.loggedInUserDetails['enable_dq_rule'] && !row.total_rules || this.loggedInUserDetails['enable_dq_rule'] && row.total_rules==0){
          this.router.navigate(['/zs-cl/rules/create-bussiness-rule'],{queryParams:{catalog_id:this.catalog_id, object_id:row.entity_id, dqRuleLevel:'object',showBreadcrumb:this.showBreadcrumb,BackTo:'catalog'}});
        }
      }

      if(metadata.dataContext.action === 'edit') {
          if (!event.target.className.includes('fal fa-pen actionInlineBtn') 
                && !event.target.className.includes('fas fa-pen actionInlineBtn') 
                && !event.target.className.includes('fas fa-graduation-cap actionInlineBtn') 
                && fieldName !== 'mapped_data_sources' 
                && fieldName !== 'mapped_data_sets'
                && fieldName !== 'mapped_synonyms') {


                $('#object-semantic_object-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                $('#object-definitions-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
                // $(idCell).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });

                $(idCell).focusout(function(){
                    newValue = $(idCell)[0]['value'];
                    if(fieldName === 'semantic_object') { oldValue = row.semantic_object; }
                    if(fieldName === 'definitions') { oldValue = row.definitions; }
                    if(oldValue !== newValue) {
                       if(fieldName === 'semantic_object') { row.semantic_object = newValue; }
                      if(fieldName === 'definitions') { row.definitions = newValue; }
    
                        const objectX = new Object();
                        objectX.entity_id = row.entity_id;
                        if(fieldName === 'semantic_object') { objectX['name'] = newValue }
                        if(fieldName === 'definitions') { objectX['description'] = newValue }
                    }

                    $('#object-semantic_object-' + args.row).css({'background-color': '#fff', 'border': '0'});
                    $('#object-definitions-' + args.row).css({'background-color': '#fff', 'border': '0'}); 
                    // $(idCell).css({ 'background-color': '#fff', 'border': '0' });

                });

          } else if (event.target.className.includes('fal fa-pen actionInlineBtn') || event.target.className.includes('fas fa-pen actionInlineBtn')) {
                if (event.target.className.includes('fal fa-pen actionInlineBtn') ) {
                    $('#edit-' + args.row).removeClass('fal fa-pen actionInlineBtn');
                    $('#edit-' + args.row).addClass('fas fa-pen actionInlineBtn');
                    $('#edit-' + args.row).show();

                    $('#noEdit-semantic_object-' + args.row).hide();
                    $('#noEdit-definitions-' + args.row).hide();
                    $('#isEdit-semantic_object-' + args.row).show();
                    $('#isEdit-definitions-' + args.row).show();
                    const concept_name = $('#object-semantic_object-' + args.row)[0]['value'];
                    const concept_desc = $('#object-definitions-' + args.row)[0]['value'];
                    parent.semanticObjName = concept_name;
                    parent.semanticObjDescription = concept_desc;
                } else {
                   // reading updated value from edited concept cell
                    const concept_name = $('#object-semantic_object-' + args.row)[0]['value'];
                    const concept_desc = $('#object-definitions-' + args.row)[0]['value'];
                    let objectX: any = {};
                    objectX.entity_id = row.entity_id;
                    parent.addObject.semantic_object = concept_desc;
                    if(parent.semanticObjName == concept_name) {
                      objectX.description = concept_desc;
                    } else{
                    objectX.name = concept_name;
                    objectX.description = concept_desc;
                    }
                      parent.contentSvc.saveEntity(objectX).subscribe(responseList => { 
                        if(responseList) {
                          this.messageSvc.sendMessage({ message: 'Changes saved successfully', type: 'SUCCESS', hideboard: true });
                          this.gridObj.dataView.setItems([]);
                        this.getSemanticsObjects(this.page, this.limit);
                        }
                      }, err => {  
                        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                        this.gridObj.dataView.setItems([]);
                        this.getSemanticsObjects(this.page, this.limit);
                      })
                    $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
                    $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
                    $('#edit-' + args.row).show();
                    $('#noEdit-semantic_object-' + args.row).show();
                    $('#noEdit-definitions-' + args.row).show();
                    $('#isEdit-semantic_object-' + args.row).hide();
                    $('#isEdit-definitions-' + args.row).hide();
                }

          } else if(event.target.className.includes('fas fa-graduation-cap actionInlineBtn') || fieldName === 'mapped_data_sources' || fieldName === 'mapped_data_sets') {
            $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).show();

            $('#noEdit-semantic_object-' + this.lastRowSelected).show();
            $('#noEdit-definitions-' + this.lastRowSelected).show();
            $('#isEdit-semantic_object-' + this.lastRowSelected).hide();
            $('#isEdit-definitions-' + this.lastRowSelected).hide();

            if(event.target.className.includes('fas fa-graduation-cap actionInlineBtn')) {
                // this.router.navigate(['/zs/datasets/' + row.dataset_id + '/catalogs']);
                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/review-training'], { queryParams: { object_id: row.entity_id, catalogLevel: 'semantic' } });
            }

          } else if((event.target.className.includes('fal fa-link actionInlineBtn') || fieldName === 'mapped_synonyms') && event.target.className != 'disabled') {
            $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).show();

            $('#noEdit-semantic_object-' + this.lastRowSelected).show();
            $('#noEdit-definitions-' + this.lastRowSelected).show();
            $('#isEdit-semantic_object-' + this.lastRowSelected).hide();
            $('#isEdit-definitions-' + this.lastRowSelected).hide();
            sessionStorage.removeItem('serverfilter');
            sessionStorage.removeItem('sortfilter');

            if(event.target.className.includes('fal fa-link actionInlineBtn')) {
                // this.router.navigate(['/zs/datasets/' + row.dataset_id + '/catalogs']);
                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + row.entity_id + '/syn-select-data-catalog'], { queryParams: { object_id: row.entity_id } });
            }

          }

      } else {

          if (event.target.className.includes('fa fa-plus actionInlineBtn')) {
              this.readObjectDetails();
              // validate Attribute Object
              if (this.validateNewObject()) {
                  this.addObject.is_system = false; 
                  const payload = {
                        name: this.addObject.semantic_object,
                        description: this.addObject.definitions
                      }
                  this.catalogSvc.saveSemanticObject(this.loggedInUserDetails['user_id'], this.catalog_id, payload).subscribe(responseList => {
                    this.gridObj.dataView.setItems([]);
                    this.getSemanticsObjects(this.page, this.limit);
                  }, err => {
                      this.isDataReady = true;
                      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                  });
              }
          }
      }

      if(this.lastRowSelected !== args.row) {
            $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + this.lastRowSelected).show();

            $('#noEdit-semantic_object-' + this.lastRowSelected).show();
            $('#noEdit-definitions-' + this.lastRowSelected).show();
            $('#isEdit-semantic_object-' + this.lastRowSelected).hide();
            $('#isEdit-definitions-' + this.lastRowSelected).hide();
      }

  }
  this.lastRowSelected = args.row;
}

 // Validate New Attribute Details
 validateNewObject() {
  if ((this.addObject.semantic_object)) {
      return true;
  } else {
      this.messageSvc.sendMessage({ message: 'Please fill the required fields', type: 'INFO', hideboard: true });
      return false;
  }
}

readObjectDetails() {
  this.addObject.semantic_object = $('#object-semantic_object-0')[0]['value'];
  this.addObject.definitions = $('#object-definitions-0')[0]['value'];
}

setNewObjectDetails(row) {
  row.semantic_object = this.addObject.semantic_object;
  row.definitions = this.addObject.definitions;
}

// Delete Objects Rows on Delete Action Confirmation
// deleteSelectedRow() {
//   const dataObject = {
//     user_id: this.loggedInUserDetails['user_id'],
//     catalog_id: this.catalog_id,
//     object_id: this.selectedObjectMetadata.dataContext.object_id
//   }
//   this.catalogSvc.deleteSemanticObject(dataObject).subscribe(responseList => {
//       this.gridObj.gridService.deleteDataGridItemById(this.selectedObjectMetadata.dataContext.id);
//       this.total = this.total - 1;
//       $('#btnCloseActionModel').click();
//   }, err => {
//   });
// }

initObjectGrid() {

    if (this.showBreadcrumb) {
        this.objectTableColDef = [
              {
                'displayname': '',
                'physicalname': '',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                //'formatter': actionsFormatter,  // Commenting this three dot action after discussed with Umesh as this not required now
                'formatter': emptyActionFormatter,
                'maxWidth': 10,
            }, {
                'displayname': 'Semantic Object',
                'physicalname': 'semantic_object',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customObjectRowFormatter,
                'minWidth': 150,
                'maxWidth': 250,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add Semantic Object...', tabindex: 1, showBreadCrumb: this.showBreadcrumb }
            }, {
                'displayname': 'Definitions',
                'physicalname': 'definitions',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customObjectRowFormatter,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add definition...', tabindex: 2, showBreadCrumb: this.showBreadcrumb  }
            }, {
                'displayname': 'Actions',
                'physicalname': 'action',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsObjectFormatter,
                'cssClass': 'text-center',
                'headerCssClass': 'text-center pr-1 mr-top-1',
                'maxWidth': 65,
            }, {
                'displayname': 'Synonyms',
                'physicalname': 'mapped_synonyms',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsObjectFormatter,
                'maxWidth': 90,
            }, {
                'displayname': 'Mapped Data Sources',
                'physicalname': 'mapped_data_sources',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'formatter': numericFormatter,
                'formatter': actionsObjectFormatter,
                'cssClass': 'text-right',
                'headerCssClass': 'text-left titleBreak pt-0 pb-0 pr-3 toRight',
                'minWidth': 90,
                'maxWidth': 90
            }, {
                'displayname': 'Mapped Data Sets',
                'physicalname': 'mapped_data_sets',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'formatter': numericFormatter,
                'formatter': actionsObjectFormatter,
                'cssClass': 'text-right',
                'headerCssClass': 'text-left titleBreak pt-0 pb-0 pr-3 toRight',
                'minWidth': 90,
                'maxWidth': 90
            }, {
                'displayname': 'Train Model',
                'physicalname': 'train',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'headerCssClass': 'mr-top-1',
                'formatter': actionsObjectFormatter,
                'maxWidth': 90,

            },{
              'displayname': '# of Data Quality Rules',
              'physicalname': 'total_rules',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
              'formatter': totalRulesFormatter,
              'cssClass': 'text-right',
              'headerCssClass': `text-left titleBreak pt-0 pb-0 pr-3 toRight ${this.loggedInUserDetails['enable_dq_rule'] ? '':'disabled'}`,
              'minWidth': 90,
              'maxWidth': 90
          },
          {
            'displayname': 'Measured Data Quality',
            'physicalname': 'measured_dq_score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': measuredDQFormatter,
            'cssClass': 'text-right',
            'headerCssClass': `text-left titleBreak pt-0 pb-0 pr-3 toRight ${this.loggedInUserDetails['enable_dq_rule'] ? '':'disabled'}`,
            'minWidth': 90,
            'maxWidth': 90
        },
        ];
    } else {
        this.objectTableColDef = [
            {
              'displayname': '',
              'physicalname': '',
              'sortable': false,
              'datatype': 'String',
              'filterable': false,
              //'formatter': actionsFormatter,  // Commenting this three dot action after discussed with Umesh as this not required now
              'formatter': emptyActionFormatter,
              'maxWidth': 10,
            },{
                'displayname': 'Semantic Object',
                'physicalname': 'semantic_object',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customObjectRowFormatter,
                'minWidth': 150,
                'maxWidth': 250,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add Semantic Object...', tabindex: 1, showBreadCrumb: this.showBreadcrumb  }
            }, {
                'displayname': 'Definitions',
                'physicalname': 'definitions',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': customObjectRowFormatter,
                'headerCssClass': 'mr-top-1',
                'params': { placeholder: 'Add definition...', tabindex: 2, showBreadCrumb: this.showBreadcrumb  }
            }, {
                'displayname': 'Actions',
                'physicalname': 'action',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': actionsObjectFormatter,
                'cssClass': 'text-center',
                'headerCssClass': 'text-center pr-1',
                'maxWidth': 65,
            }
        ];
    }

  this.isDataReady = true;
}

getAddObjectRow() {
  return { 
      'id': Math.random(), 
      'entity_id': '-1', 
      'semantic_object': '', 
      'definitions': '', 
      'mapped_data_sourcest': 0, 
      'mapped_data_sets': 0, 
      'action': 'add' ,
      'train': 'train'
  };
}

getPaggedObject(page, limit) {
  this.readObjectDetails();
  
  let sortFilter="";
  if(sessionStorage.getItem("sortfilter")) {
   sortFilter=sessionStorage.getItem("sortfilter");
  }
  this.catalogSvc.getSemanticsObjects(this.catalog_id).subscribe(resp => {
      this.objectsList = resp;
      if (this.objectsList.length === 0) {
          this.noData = true;
      }
      let concatedRows = this.objectsList;
      if (this.gridObj) {
          concatedRows = [...this.gridObj.dataView.getItems(), ...this.objectsList];
          this.objectsList = resp.map((item) => {
              item['action'] = 'edit';
              item['id'] = Math.random();
              return item;
          });
      }

      const firstRow = this.getAddObjectRow();
      this.setNewObjectDetails(firstRow);
      this.objectsList.splice(0, 0, firstRow);
      this.isDataReady = true;
      this.objectsList = concatedRows;
      this.gridObj.dataView.setItems(concatedRows);
      this.gridObj.gridService.setSelectedRows([]);
      this.gridObj.dataView.refresh();
      this.hasScrolled = false;
  }, err => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
  });

}

openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.gridObj.gridService.getDataItemByRowIndex(args.row);

    if (row.isdisable) {
      $('#context_menu').hide();
    } else {
      $('#context_menu')
        .removeClass('d-none')
        .data('row', args.row)
        .css('top', eventEle.pageY)
        .css('left', eventEle.pageX + 5)
        .css('position', 'fixed')
        .css('display', 'block')
        .show();
    }
    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.gridObj.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      const env = JSON.parse(sessionStorage.getItem('userApp'));
      if (action === 'Edit') {
        // parent._router.navigate(['/zs/datasets/create-dataset'], { queryParams: { 'datasetId': row.dataset_id, showBreadcrumb: false } });
      } else if (action === 'Export') {
        // window.open(`${parent.apiUrl}/users/${parent.loggedInUserDetails.user_id}/tenants/${parent.loggedInUserDetails.tenant_id}/datasets/${row.dataset_id}/export`, '_self');
      } else if (action === 'Delete') {
        // parent.deleteRow(row.dataset_id, row.id);
      }
    });
  }

    ngOnDestroy() {
        // if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        // }
        this.messageSvc.clearMessage();
        this.clearProcessingData();
        this.clearTrackData();
        this.latestJobId = '';
    }

    fileUploaded(){
        // this.getEntityAttributes();
        this.router.navigate(['/zs-cl/catalogs']);
       // this.router.navigate(['/zs/entities/' + this.entity.entity_id + '/entity-model']);
    }

  receiveFileName($event) {
    if ($event && $event['showProcessingModel']) {
      this.fileType = $event.fileType;
      $('#uploadFileModal').modal('hide');
      this.triggerAlgoToProcessFile();
      this.clearModal = Math.random();
      sessionStorage.setItem('catalog_object_data', JSON.stringify({ "showProgress": true, "catalog_id": this.catalog_id, "object_id": this.object_id, fileUploadEventData: $event }));
      this.showProgressModal = true;
      $('#processing_model').modal('show');

    } else {
      if ($event === AppGlobals.FILE_UPLOADED) {
        if (this.is_upload_complete) {
          $('#uploadFileModal').modal('hide');
          this.clearModal = Math.random();
        }
      } else {
        this.dataRes = $event;
        this.is_upload_complete = this.dataRes.is_upload_complete;
        if (this.is_upload_complete) {
          if (this.gridObj) {
            this.objectsList = [];
            this.gridObj.dataView.setItems(this.objectsList);
            this.gridObj.gridService.setSelectedRows([]);
          }
          this.getSemanticsObjects(1, this.limit);
        }
      }
    }
  }

  closeImportObject() {
    if (this.is_upload_complete) {
      if (this.gridObj) {
        this.showLoader = true;
        this.objectsList = [];
        this.gridObj.dataView.setItems(this.objectsList);
        this.getSemanticsObjects(1, this.limit);
        this.clearModal = Math.random();
      }
    }
  }

  cancelImportObject() {
    this.clearModal = Math.random();
  }

    receiveTotalRecords($event) {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);    
    }

    onCancel() {
        this.router.navigate(['/zs-cl/catalogs']);
    }

    onPrevious() {
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/users'], { queryParams: { catalog_id: this.catalog_id, showBreadcrumb: this.showBreadcrumb, isClassify: true }, queryParamsHandling:'merge'});
    }

    onNext() {
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/concepts'], { queryParams: { showBreadcrumb: this.showBreadcrumb, isClassify: true }, queryParamsHandling:'merge'});
    }
    
  

    onSynonymsGridCreation(grid) {
      this.gridSynonyms = grid;
    }

    getDatasources() {
        const getDatasources: any = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalog_id,
            object_id: this.object_id,
        };
        this.catalogSvc.getDatasources(getDatasources).subscribe((data) => { 
            this.datasources = data;
            if (this.datasources.length === 0) {
            this.noData = true;
            }
            this.totalDatasources = this.datasources.length;
            this.isLoadingDatasources = false;
            if (this.gridDatasources) {
              this.datasources.forEach(element => {
                  element.id = Math.random();
              });
              this.gridDatasources.dataView.setItems(this.datasources);
              this.gridDatasources.gridService.setSelectedRows([]);
              this.hasDatasourcesScrolled = false;
            }
        }, err => { });
    }

    onDatasourcesGridCreation(grid) {
        this.gridDatasources = grid;
    }

    onDatasourceCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.gridDatasources.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
            if (eventEle.target.title === row.name) {
                $('#datasources_modal').modal('hide');
                $('#datasources_modal').addClass('d-none');
                this.router.navigate(['/zs/datasources', row.datasource_id]);
            }
        }
    }

    getDatasets(objectId,page, limit) {
      this.semantic_objectid=objectId;
      this.datasetTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?pagination=true&pageno=${1}&pagesize=${limit}`;
      var serverfilter = "";
      if(sessionStorage.getItem("serverfilter")){
        var serverfilter = sessionStorage.getItem("serverfilter");
        this.isFiltering = true;
      } else {
        if(this.isFiltering){ 
          page = 1;
        }
        this.isFiltering = false;
      }
     let sortfilter="";
     if(sessionStorage.getItem("sortfilter")){
        sortfilter=sessionStorage.getItem("sortfilter");
       // this.isSorting = true;
     } 
     
      const getDataset: any = {
          user_id: this.loggedInUserDetails['user_id'],
          catalog_id: this.catalog_id,
          object_id: objectId,
          page:page,
          limit:limit
        };
        this.catalogSvc.getDatasets(getDataset,serverfilter,sortfilter).subscribe((data) => {
          console.log('datasets are here  ', data);
            this.datasets = data['currentpage'];
            this.totalDatasetsPages = data['totalpages'];
            if (this.datasets.length === 0) {
              this.noData = true;
              this.datasets = [];
            }
            this.datasets.forEach(element => {
                element.id = Math.random();
                element.score100 = (element.score * 100).toFixed();
                element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
            });
            this.datasets.sort(((a, b) => b.score100 - a.score100));
            this.totalDatasets = data['totalrecords'];
            if (page === 1) {
              this.grandTotalDataset = data['totalrecords'];
            }
            this.isLoadingDatasets = false;
            let concatedRows = this.datasets;
            if (this.gridDatasets) {
              concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
               this.datasets = concatedRows;
              this.gridDatasets.dataView.setItems(this.datasets);
              this.gridDatasets.gridService.setSelectedRows([]);
              this.hasDatasetsScrolled = false;
              
            }
        }, err => { });
      }

      onDatasetsGridCreation(grid) {
        const parent = this;
        parent.gridDatasets = grid;
        parent.gridDatasets.slickGrid.onSort.subscribe((e, args) => {
          this.page = 1;
          this.hasScrolled = false;
        });
      }

      onDatasetCellClick(e): void {        
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
        this.selectedMappedColumn = args.row;
        if (row !== undefined && row !== null) {
          if (eventEle.target.title === row.name) {
            this.dsName = row.name;           
            this.showmodelpopup=true;
            this.datasetId=row.dataset_id;
          } else if (eventEle.target.title === row.datasource_name) {
            $('#datasets_modal').modal('hide');
            $('#datasets_modal').addClass('d-none');
            this.router.navigate(['/zs/datasources', row.datasource_id]);
          } else if(eventEle.target.className =='fal fa-thumbs-down handDown' || eventEle.target.className =='handDown fal fa-thumbs-down'){
            this.object_id = row.entity_id;
            this.showDownvoteModal = true;
            this.datasetColumnId = row.dataset_type_id;
            this.datasetId = row.dataset_id;
            this.dsName = row.name;    
          } else if (eventEle.target.className == 'fal fa-thumbs-up') {
            const upVotedata = {
              "dataset_id": row.dataset_id,
              "mapping_type": "manual",
              "entity_id": this.object_id,
              "catalog_id": this.catalog_id,
              "is_semantic_level_mapping": true
            };
            const payload = {
              user_id: this.loggedInUserDetails['user_id'],
              tenant_id: this.loggedInUserDetails['tenant_id'],
              payload: upVotedata
            };
            this.catalogSvc.saveVoteSemanticObject(payload, 'up_vote').subscribe(resp => {
              $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
              $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
              $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              if (this.gridDatasets) {
                this.gridDatasets.dataView.setItems([]);
                this.isLoadingDatasets = true;
                this.getDatasets(row.entity_id, 1, this.limit);
              }
            }, error => {
              this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            });
          }  else if (eventEle.target.className.includes('countUp')) {
            this.showFeedbackSummaryModel = true;
            this.votedUsers = row.upvotes;
            this.datasourceName = row.datasource_name;
            this.datasetName = row.name;
            this.object_id = row.entity_id;
            this.datasetId = row.dataset_id;
            this.voteValue = 'upVote'
            this.screenName = 'discoverDataset';    
          } else if (eventEle.target.className.includes('countDown')) {
            this.showFeedbackSummaryModel = true;
            this.votedUsers = row.downvotes;
            this.datasourceName = row.datasource_name;
            this.datasetName = row.name;
            this.object_id = row.entity_id;
            this.datasetId = row.dataset_id;
            this.voteValue = 'downVote';
            this.screenName = 'discoverDataset';
          }
        }
      }

      clearDatasets() {
        this.gridDatasets.dataView.setItems([]);
      }

  selectOptionDataSetModelList(dataSetId, limit = 100) {
    this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // this.isSampleDsLoading = true;
    this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
      this.modalRowData = data.currentpage;
      let rowKeys = [];
      if (data.currentpage.length) {
        let myVar: any = data.currentpage[0];
        for (var key in myVar) {
          rowKeys.push(key)
        }
      }
      this.modelColumnDef = [];
      rowKeys.forEach(column => {
        this.modelColumnDef.push({
          'displayname': column,
          'physicalname': column,
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
        });
      });

      this.modalRowData.forEach((element: any) => {
        element.id = Math.random();
      });
      if (this.modalGrid) {
        this.modalGrid.dataView.setItems(this.modalRowData);
        this.modalGrid.gridService.setSelectedRows([]);
      }
      this.isSampleDsLoading = false;
    }, err => {
      this.isSampleDsLoading = false;
      this.modalRowData = [];
    });
  }


  onScrollD(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
       // this.isSorting = true
      }
     
      if (this.isFiltering) {
        this.datasetsPageNo = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }

    if(!this.hasDatasetsScrolled && this.datasetsPageNo < this.totalDatasetsPages){
      this.hasDatasetsScrolled = true;
      this.onNextDs();
    }
  }
      onModelGridCreation(grid) {
        this.modalGrid = grid;
      }

      closeModal() {
        $('#datasets_modal').removeClass('datasets_modal');
      }

      popupoutput(message){
        this.showmodelpopup = message;   
      }

  closeModalPopup(event) {
    if (event) {
      $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      setTimeout(() => {
        if (this.gridDatasets) {
          this.gridDatasets.dataView.setItems([]);
          this.isLoadingDatasets = true;
          this.getDatasets(this.object_id, 1, this.limit);
        }
      }, 500);
    }
    this.showDownvoteModal = false
  }

  onExport() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?export=true`,'_self');
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasets?export=true`).subscribe(resp => {
      this.catalogSvc.exportCsvFiles(resp, 'DataSetsReport_forSemanticObject');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onExportDatasources() {
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/datasources?export=true`).subscribe(resp => {
        let data = 'Catalog,Semantic Object,Semantic Object Id,Datasource Id,Name,Description\n';
        JSON.parse(resp).forEach(e => {
          data += this.catalogName + ',' + this.semantic_object + ',' + this.object_id + ',' + e.datasource_id + ',' + e.name + ',' + e.description + '\n';
        });
        this.catalogSvc.exportCsvFiles(data, 'DataSourcesReport_forSemanticObject');
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  closeSynonymsModel(event) {
    $('#synonyms_modal').modal('hide');
    this.synonyms=[];
    this.showSynonymsModel = event;
  }

  onRunModel(){
    this.catalogSvc.runModel303().subscribe((res) => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs-cl/catalogs/' + this.catalog_id });      
    }, (err) => {
    });
  }

  cancelUploadMappings() {
    this.clearModal = Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
  }

  closeUploadMappings() {
    this.clearModal = Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
    this.showLoader = true;
    this.getSemanticsObjects(1, this.limit);
  }

  receivedFileNameClassify($event) {
    this.dataResponse = $event;
    if (this.dataResponse.is_upload_complete) {
      this.is_upload_complete_classify = this.dataResponse.is_upload_complete;
    }
  }

  onConceptRunModel() {
    this.catalogSvc.runModel303().subscribe(res => {
      if(res) {
        this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs-cl/catalogs/'});   
      }
    }, (err) => {});
  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }

  receiveTotalRecordDataset(event){
    this.subTotalDataset = this.zettaUtils.getSubTotal(this.grandTotalDataset, event);
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }

  disableNextStep() {
    if (this.objectsList && this.objectsList.length > 1) {
      return false;
    } else {
      return true;
    }
  }

  triggerAlgoToProcessFile() {
    this.latestJobId = '';
    let payload = {object_type_id: AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, catalog_id: this.catalog_id, ontology_file_type: '.' +this.fileType};
    this.projectService.processCatalogFile(payload)
    .subscribe(startProcess => {
      this.checkJobQueueStatus(true);
    });
  }

  checkJobQueueStatus(makeStatusCall) {
    this.uploadedCatalogData = this.uploadedCatalogData ? this.uploadedCatalogData : sessionStorage.getItem('catalog_object_data');
    if(((this.uploadedCatalogData && this.uploadedCatalogData.catalog_id) && (this.catalog_id == this.uploadedCatalogData.catalog_id)) || makeStatusCall) {
    let dataFromLocal = sessionStorage.getItem('catalog_object_data');
    if((this.uploadedCatalogData && this.uploadedCatalogData['showProgress']) || (dataFromLocal && dataFromLocal['showProgress'])) {
       this.showProgressModal = true;
          $('#processing_model').modal('show');
    }
      this.projectService.getJobQueueStatus({objectTypeId: AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, objectId: this.catalog_id})
        .subscribe(queueStatus => {
          if((!queueStatus) || (queueStatus && queueStatus['status'] != 'pending')) {
            clearInterval(this.revokeProcessing);
            this.revokeProcessing=null;
            this.clearProcessingData();
            // if((this.uploadedCatalogData && this.uploadedCatalogData['showProgress']) || (dataFromLocal && dataFromLocal['showProgress'])) {
                this.trackProcessingdata();
              // }
          } else {
            while (!this.revokeProcessing){
              this.revokeProcessing = setInterval(() => this.checkJobQueueStatus(true), AppGlobals.CLASSIFY_CATALOG_CHECK_DURATION);
          }
          }
      });
    }
  }

  trackProcessingdata() {
    this.projectService.getLatestJob(this.loggedInUserDetails['user_id'], this.catalog_id, AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE)
    .subscribe(latestJobId => {
      if(latestJobId && latestJobId['loader'] && latestJobId['loader']['job_id']) {
        this.latestJobId = latestJobId['loader']['job_id'];
        if(this.latestJobId) {
          this.projectService.getCurrentActiveJobsById(this.loggedInUserDetails['user_id'], this.catalog_id, AppGlobals.CLASSIFY_CATALOG_OBJECT_TYPE, this.latestJobId)
                  .subscribe(uploadStatus => {
                            if(uploadStatus && uploadStatus['job_completed']) {
                              this.is_upload_complete = true;
                              clearInterval(this.revokeTrack);
                              // this.revokeTrack = undefined;
                              this.revokeProcessing = null;
                              clearInterval(this.revokeProcessing);
                              // this.revokeProcessing = undefined;
                              this.revokeProcessing = null;
                              this.refreshData();
                              this.hideProcessingSpinnerModal();
                              this.clearTrackData();
                              console.log('called the refresh here  ');
                              this.trackJobErrorDetails = '';
                            } else if(uploadStatus && !uploadStatus['job_completed'] && !uploadStatus['is_errored']) {
                              while (!this.revokeTrack){
                              this.revokeTrack = setInterval(() => this.trackProcessingdata(), AppGlobals.CLASSIFY_CATALOG_CHECK_DURATION);
                            }
                            this.trackJobErrorDetails = '';
                          } else if(uploadStatus && !uploadStatus['job_completed'] && uploadStatus['is_errored']) {
                            this.trackJobErrorDetails = uploadStatus['error_message'];
                              this.showProgressModal = true;
                              $('#processing_model').modal('show');
                              clearInterval(this.revokeTrack);
                              clearInterval(this.revokeProcessing);
                              this.revokeTrack = undefined;
                              this.revokeProcessing = undefined;
                              sessionStorage.removeItem('catalog_object_data');
                            }
                          });
                      }}});
  }

  refreshData() {
        if (this.is_upload_complete) {
          clearInterval(this.revokeProcessing);
          clearInterval(this.revokeTrack);
          this.revokeTrack = undefined;
          this.revokeProcessing = undefined;
          this.revokeProcessing = null;
          if (this.gridObj) {
            this.objectsList = [];
            this.gridObj.dataView.setItems(this.objectsList);
            this.gridObj.gridService.setSelectedRows([]);
          }
          this.getSemanticsObjects(1, this.limit);
        }
  }

  hideProcessingSpinnerModal() {
    this.showProgressModal = false;
    $('#processing_model').modal('hide');
    clearInterval(this.revokeTrack);
    clearInterval(this.revokeProcessing);
    this.revokeTrack = undefined;
    this.revokeProcessing = undefined;
    sessionStorage.removeItem('catalog_object_data');
  }

  clearProcessingData() {
    clearInterval(this.revokeProcessing);
    this.revokeProcessing=null;
    while (this.revokeProcessing!== null){
        this.revokeProcessing=null; 
    }
  }

  clearTrackData() {
    clearInterval(this.revokeTrack);
    this.revokeTrack=null;
    while (this.revokeTrack!== null){
        this.revokeTrack=null; 
    }
  }

}

