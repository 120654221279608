import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class HelpService extends BaseService {
    userid: number = this.getObjectFromSession('userInfo').user_id;
    appid: number = this.getObjectFromSession('userInfo').preference.default_app_id

    constructor(private http: HttpClient) {
        super();
    }

    public getUserHelpPdf(): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${this.userid}/help/app/${this.appid}`, { responseType: 'arraybuffer' });
    }

    public getPdfUrl(apiUrl): Observable<any> {
		return this.http.get(`${apiUrl}`,{responseType: 'arraybuffer' as 'json'});
	}
	
	public exportPdfFormat(data) {
		const blob = new Blob([data], { type: 'application/pdf' });
		let url = URL.createObjectURL(blob);
        window.open(url, '_blank');
	};
}