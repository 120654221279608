import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ZmObservableService } from '../../common/zm-services/zm-observable.service';
import { ZettaUtils } from '../../common/shared/zettaUtils';

@Component({
  selector: 'zetta-zm-sidebar',
  templateUrl: './zm-sidebar.component.html',
  styleUrls: ['./zm-sidebar.component.scss']
})
export class ZmSidebarComponent implements OnInit {

  isLeftSideBarActive = true;

  constructor(public observeSvc: ZmObservableService,
    private zettaUtils: ZettaUtils) { }

  ngOnInit() {
    this.zettaUtils.getEntitlements('zm');
    this.zettaUtils.getShortName();
    this.zettaUtils.changeFavicon('../../assets/images/zettamesh/favicon.png', 'ZettaInsights');
  }

  toggleLeftSideBar(zmMenuIndex: number) {
    const currentSessionIndex = parseInt(sessionStorage.getItem('zmMenuIndex'), 10) || 1;
    if (currentSessionIndex && currentSessionIndex === zmMenuIndex) {
      this.isLeftSideBarActive = !this.isLeftSideBarActive;
      this.observeSvc.refreshDataGrid = this.isLeftSideBarActive;
    }
    sessionStorage.setItem('zmMenuIndex', zmMenuIndex.toString());
  }

  applyClass() {
    return this.isLeftSideBarActive ? 'fa-angle-right' : 'fa-angle-left';
  }
}
