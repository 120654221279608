import { Component, OnInit } from '@angular/core';
import { ObservableService } from '../../common/services/observable.service';
import { ZettaUtils } from '../../common/shared/zettaUtils';

@Component({
  selector: 'zetta-zs-cl-sidebar',
  templateUrl: './zs-cl-sidebar.component.html',
  styleUrls: ['./zs-cl-sidebar.component.scss']
})
export class ZsClSidebarComponent implements OnInit {

  isLeftSideBarActive = true;
  userInfo: any;
  enableDQRule = JSON.parse(sessionStorage.userInfo).enable_dq_rule;  
  isTenantAdmin: Boolean = JSON.parse(sessionStorage.userInfo).is_admin;
  disableDataQuality: Boolean;
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
  user_entitlements;

	constructor(public observeSvc: ObservableService,
		private zettaUtils: ZettaUtils) {
		if (this.login_user) {
			try {
				this.user_entitlements = this.login_user.find(app => app.app.app_id === 3);
				if (this.user_entitlements) {					
					this.user_entitlements = this.user_entitlements.entitlement;
				} else {
					this.user_entitlements = this.login_user[0].entitlement;
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	ngOnInit() {
		if (this.enableDQRule === false || this.isTenantAdmin === true) {
			this.disableDataQuality = true
		}
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		this.zettaUtils.getEntitlements('zs-cl');
		// this.zettaUtils.getShortName();
		this.zettaUtils.changeFavicon('../../assets/images/common/favicon.png', 'FlureeSense');
	}

	toggleLeftSideBar(menuIndex: number) {
		const currentSessionIndex = parseInt(sessionStorage.getItem('menuIndex'), 10) || 1;
		if (currentSessionIndex && currentSessionIndex === menuIndex) {
			this.isLeftSideBarActive = !this.isLeftSideBarActive;
			this.observeSvc.refreshDataGrid = this.isLeftSideBarActive;
		}
		sessionStorage.setItem('menuIndex', menuIndex.toString());
	}

	applyClass() {
		return this.isLeftSideBarActive ? 'fa-angle-right' : 'fa-angle-left';
	}

	getSidebarEntitlements(sidebarName) {
		const sidebarEntitlement = JSON.parse(sessionStorage.getItem('userEntitlement'));
		const entitlements = this.user_entitlements.menus.find(menus => menus.name === 'left_panel').childs.find(children => children.name === sidebarName);
		if (entitlements) {

			if(sidebarName === 'admin') { 
				return this.userInfo.is_admin || this.userInfo.is_super_admin ? entitlements.enable : entitlements.disabled;
			} else {
				return entitlements.enable;
			}
			
			
		}
	}

	checkForSystemAdmin(menuItem) {
		if(menuItem == 'tasks') {
			let adminOrSuperAdmin = JSON.parse(sessionStorage.userInfo).is_super_admin || JSON.parse(sessionStorage.userInfo).is_admin;
			if (adminOrSuperAdmin) {
				return false;
			} else {
				return true;
			}
		}
	}
}
