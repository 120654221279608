import { AppGlobals } from './common/shared/app.globals';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { ZettaUtils } from './common/shared/zettaUtils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FsDownloadGraphService } from './common/components/fs-download-graph/fs-download-graph.service';
import { MessageService } from './common/components/message/message.service';
import { ContentService } from './zettasense/content/content.service';
import { Observable } from 'rxjs';

declare var $: any;
@Component({
  selector: 'zetta-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isLogin: false;
  currentPage: string;
  isZcLoggedIn = false;

  safeSrc: SafeResourceUrl;
  url;
  isComponentReadyForGraphObservableSubscription: boolean = false;
  graphDownloadObservable;
  constructor(location: Location,
              router: Router,
              private _zettUtils: ZettaUtils,
              private sanitizer: DomSanitizer,
              private downloadGraphService: FsDownloadGraphService,
              private _contentService: ContentService,
              private messageService: MessageService) {

    router.events.subscribe((val) => {
        if (location.path() !== '') {
          this.currentPage = location.path();
        } else {
          this.currentPage = 'Home';
        }
        if (router.url.includes('/zc')) {
          this.isZcLoggedIn = true;
        } else {
          this.isZcLoggedIn = false;
        }
    });

    //Close all open modals when "go back" button is clicked on browser
    history.pushState(null, null);
    window.addEventListener('popstate', () => {
        // Add here the classes of the MODAL as they look when it is open
        var existModals = document.getElementsByClassName('modal fade show');
        if (existModals) {       
            $('.modal').modal('hide');
            $('.modal').addClass('d-none');
            history.pushState(null, null);
        }
    });

  }

  ngOnInit() {
    this._zettUtils.changeFavicon('./assets/images/common/favicon.png', 'Fluree');

    // this.url = 'http://40.117.73.151:18630/';
    // this.url = 'http://13.92.23.197:19630/';
    this.url = 'https://cloud.streamsets.com/security/login?ss-requestedUrl=http:%2F%2F13.92.23.197:19630';
    // this.url = 'https://cloud.streamsets.com/security/login?ss-requestedUrl=http://13.92.23.197:19630';
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    this.downloadGraphService.downloadGraphDataBS.subscribe((data) => {
      let objectData = Object.keys(data);
      this.isComponentReadyForGraphObservableSubscription = objectData.length ? true : false;
      if (objectData.length && this.downloadGraphService.getGraphData().length <= 0 && this.isComponentReadyForGraphObservableSubscription) {
        // Subscribe to Observable for first time to download graph
        this.downloadGraphService.updateGraphDataState(data);
        this.graphDownloadObservable = Observable.timer(0, AppGlobals.GRAPH_DOWNLOAD_TIMER)
          .subscribe((data) => {
            this.validateAndDownloadGraphData();
          })

      } else {
        // update data to observable for subsequent times to download graph
        if (objectData.length) {
          this.downloadGraphService.updateGraphDataState(data);
        }
      }
    })
  }

  validateAndDownloadGraphData() {
    let self = this;
    let userId = sessionStorage.getItem("userInfo") && JSON.parse(sessionStorage.getItem("userInfo")).user_id ? JSON.parse(sessionStorage.getItem("userInfo")).user_id : null
    if (this.downloadGraphService.getGraphData().length) {
      this._contentService.getLatestJobQueueStatusForDownloadingGraph(this.downloadGraphService.getGraphData())
        .subscribe((queueData) => {
          let dataToValidateJobStatus = queueData && queueData.filter((queue) => {
            return queue['jobQueueCleared'];
          })
          // If no data in job queue, then validate in zs job and download if it is success
          if (dataToValidateJobStatus && dataToValidateJobStatus.length) {
            dataToValidateJobStatus.forEach((graphData, index) => {
              self._contentService.getLatestJobIdForObject(userId, graphData.object_id, graphData.object_type_id)
                .subscribe((latestZsJob) => {
                  if (latestZsJob && latestZsJob.loader && latestZsJob.loader.job_id) {
                    // call track api
                    let latestJobId = latestZsJob.loader.job_id;
                    self._contentService.getLatestJobStatusForDownloadingGraph(graphData, userId, latestJobId)
                      .subscribe((jobStatusData) => {
                        if (jobStatusData && !jobStatusData.is_cancelled && !jobStatusData.is_errored && jobStatusData.job_completed) {
                          // download here with job_id
                          let job_id = jobStatusData.job_id;
                          if (this.downloadGraphService.validateIfGraphDataExist(graphData.object_id, graphData.object_type_id) &&
                            !this.downloadGraphService.checkIfDownloadTriggeredForObjectId(graphData.object_id, graphData.object_type_id)) {
                            self.downloadGraphService.updateDownloadTriggeredForObjectId(graphData.object_id, graphData.object_type_id);
                            self._contentService.downloadGraph(job_id, userId).subscribe((file) => {
                              self.downloadGraphService.removeObjectDataFromGraphData(graphData.object_id, graphData.object_type_id);
                              self.downloadFile(file, graphData.object_id, graphData.object_type_id);
                            }, (err) => {
                              // cancelling download API if there are any error
                              self.downloadGraphService.removeObjectDataFromGraphData(graphData.object_id, graphData.object_type_id);
                              self.showDownloadGraphErrorMessage(graphData.object_id, graphData.object_type_id);
                            })
                          } else {
                            // remove else part
                          }
                        } else {
                          if (jobStatusData && (jobStatusData.is_cancelled || jobStatusData.is_errored)) {
                            self.downloadGraphService.removeObjectDataFromGraphData(graphData.object_id, graphData.object_type_id);
                            self.showDownloadGraphErrorMessage(graphData.object_id, graphData.object_type_id);
                          }
                        }
                      }, (err) => {
                        self.downloadGraphService.removeObjectDataFromGraphData(graphData.object_id, graphData.object_type_id);
                        self.showDownloadGraphErrorMessage(graphData.object_id, graphData.object_type_id);
                      })
                  } else {
                    // not stopping downloading
                  }
                }, (err) => {
                  // not stopping downloading
                })
            })
          } else {
            // no action here as job is still in job queue
          }
        }, (err) => {
          // resetting here if job queue api fails, no need to download
          this.downloadGraphService.resetGraphData();
          // no unsubscribe error here
        })
    } else {
      // unsubscrine observable here
      this.isComponentReadyForGraphObservableSubscription = false;
      if (this.graphDownloadObservable) {
        this.graphDownloadObservable.unsubscribe();
      } else {
      }
    }
  }

  downloadFile(blobData: Blob, objectId, objectTypeId) {
    const url = window.URL.createObjectURL(blobData);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.getFileNameForDownloadedFile(objectId, objectTypeId)}.zip`; // Specify the file name here if incase needs change
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getFileNameForDownloadedFile(objectId,objectTypeId) {
      let fileName = 'file';
      let date = new Date();
      let month = (date.getMonth() + 1); 
      let currentDate = date.getDate().toString().padStart(2,'0');
      let dateFormat = month.toString().padStart(2,'0') + currentDate + date.getFullYear() + date.getSeconds() + date.getMilliseconds();
      if (objectTypeId == AppGlobals.OBJECT_TYPE_ID_DATSET) {
        fileName = 'dataset_' + objectId + '_' + dateFormat;

      } else if (objectTypeId == AppGlobals.OBJECT_TYPE_ID_CATALOG) {
        fileName = 'catalog_' + objectId + '_' + dateFormat;
      }
      return fileName;
  }

  showDownloadGraphErrorMessage(objectId,objectTypeId) {
    if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_CATALOG) {
      this.messageService.sendMessage({ message: `Exporting graph failed for catalog ${objectId}`, type: 'ERROR', hideboard: true });
    } else if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_DATSET) {
      this.messageService.sendMessage({ message: `Exporting graph failed for dataset ${objectId}`, type: 'ERROR', hideboard: true });
    }
  }

  getSessionStorage(): Storage {
    return sessionStorage;
  }

  isZmLoginPage(): boolean {
    return this.getSessionStorage().getItem(AppGlobals.IS_ZM_LOGIN_PAGE) === 'true' ? true : false;
  }

  isZsLoggedIn(): boolean {
    return this.getSessionStorage().getItem(AppGlobals.ISLOGGEDIN) === 'true' ? true : false;
  }

  isZmLoggedIn(): boolean {
    return this.getSessionStorage().getItem(AppGlobals.ISZMLOGGEDIN) === 'true' ? true : false;
  }

  isZsLogIn(): boolean {
    return this.getSessionStorage().getItem(AppGlobals.ISZSLOGGEDIN) === 'true' ? true : false;
  }

  isZsClLoggedIn(): boolean {
    return this.getSessionStorage().getItem('selectedApp') === 'zs-cl' ? true : false;
  }

  isZsInLoggedIn(): boolean {
    return this.getSessionStorage().getItem('selectedApp') === 'z-ing' ? true : false;
  }
}
