import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {

  viewMode = 'business';

  constructor() { }

  ngOnInit() { }

  changeView(viewMode) {
    this.viewMode = viewMode;
  }

}
