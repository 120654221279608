import { Column, Formatter } from 'angular-slickgrid';

export const totalRulesFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
	let isRuleDisabled = false
	let loggedInUserDetail = JSON.parse(sessionStorage.getItem('userInfo'));
	if(!loggedInUserDetail['enable_dq_rule']){     
	  isRuleDisabled = true;      
	}
	if(dataContext.action !=='add'){    
	  return isRuleDisabled 
	  ?    `<div class="text-right w-100 pd-20 disabled-state disabled inactive-text" title="${value}">${value}</div>`
	  :     `<div class="text-right w-100 pd-20 blueLink" title="${value}">${value > 0 ? value : 'Add'}</div>`
	}  
  }

