import {Column, Formatter} from 'angular-slickgrid';

export const dropFormatter : Formatter = (row : number, cell : number, value : any, columnDef : Column, dataContext : any) => value === "dropDiv"
    ? `<div class="dropDiv drop" value=${row}>Drag & drop data set attibute here.</div>`
    : `<div class="row dropDiv ${dataContext['styleCss']}" value=${row}>
            <div class="col-md-10 pr-0 text-truncate">
                <label title="${dataContext.dataset_name}.${value}">
                    <span>${dataContext.dataset_name}.</span><span style="font-weight: bolder;">${value}</span>
                </label>
            </div>
            <div class="col-md-1">
                <i title="Remove Mapping" class="fa fa-minus deleteBtn"></i>
            </div>
        </div>`;
