import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { ProjectStatus } from "src/app/common/components/project-progress/project-progress.model";
import { ProjectService } from "src/app/zettasense/content/project/project.service";
import { AngularGridInstance, Formatter } from "angular-slickgrid";
import { DatasetService } from "src/app/common/components/datasets/dataset.service";
import { IDataset } from "src/app/common/components/datasets/dataset.model";
import { BaseChartDirective, Label } from 'ng2-charts';
import { ChartType, ChartDataSets, ChartConfiguration } from 'chart.js';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ZsClContentService } from "../../zs-cl-content.service";
import { MessageService } from "src/app/common/components/message/message.service";
import { hyperLinkFormattter } from "src/app/common/shared/formatters/hyperLinkFormatter";
import { AppGlobals } from "src/app/common/shared/app.globals";

const dsFormatter: Formatter = (row: number, cell: number, value: any, dataContext: any) => {
  return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'>${value}</div>`
}
const radioFormatter: Formatter = (row: number, cell: number, value: any, dataContext: any) => {
  if(value){
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }else{
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }
 
};
@Component({
  selector: 'zett-zs-cl-new-classifire',
  templateUrl: './zs-cl-define-new-classifire.component.html',
  styleUrls: ['./zs-cl-define-new-classifire.component.scss']
})

export class ZsClDefineNewClassifreComponent implements OnInit, OnDestroy {
  createClassiFireForm: FormGroup;
  progressState = new ProjectStatus();
  activeNavLnk: string;
  gridOptions: any;
  public loggedInUserDetails = null;
  gridColumnsDef: any = []
  classifireGridColumnsDef:any=[];
  isDataReady = false;
  showBreadcrumb= false;
  sourceGrid: AngularGridInstance;
  classifireGrid:AngularGridInstance
  tableSettings: object = { 'height': "100%", 'width': "99.40%" };
  tableSettingsStep2: object = { 'height': "100%", 'width': "99.70%" };
  profile_report: any;
  dataset_id: number;
  datasource_id: string;
  dataset_column_id: string;
  source_id:string;
  is_profile_loading: boolean = false;
  is_no_data: boolean = true;
  is_primary_key: boolean = false;
  total_source=0;
  total_columns=0;
  source_selected=false;
  column_selected=false;
  project_id:string;
  catalog_id:string;
  entity_id:string;
  isColumnDataReady=false;
  projectData:any;
  classifier:object = {};
  datasetTags: any = [];
  classify_project_source_id:string;
  classify_project_source_column_id:string;
  existing_dataset_column_id:string;  
  isSourceDataReady=false;
  showmodelpopup=false;
  datasetName:string;
  datasetId:string;
  isEditProject : string;
  classifierTextLimit: Number;
  public barChartLabels: Label[] = ['', '', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[];  
  public chartColors: any[] = [
    {
      backgroundColor: '#EFB049'
    }];  
  dataSource: any = [];
  classifireDataSource:any = [];    
  rightGridRows: any;
  leftGridRows: any

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false,
        ticks: {
          display: true,
        }
      }],
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };



  constructor(public zettaUtils: ZettaUtils, private contentService: ZsClContentService,
    private service: ProjectService,
    private datasetService: DatasetService, private formBuilder: FormBuilder,
    private router: Router, private route:ActivatedRoute, private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    sessionStorage.removeItem("activeStep")  
    this.activeNavLnk= sessionStorage.getItem("activeLinkStep") ?  sessionStorage.getItem("activeLinkStep") :"1";
    this.isEditProject= this.route.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.route.snapshot.queryParamMap.get('showBreadcrumb');
    this.classifierTextLimit = AppGlobals.CLASSIFY_CLASSIFIER_NAME_LENGTH;
    this.route.parent.params.subscribe(params => {
      this.project_id = params['id'];
    });
    this.route.queryParamMap.subscribe(param =>{
      this.catalog_id = param.get('catalog_id');
      this.entity_id = param.get('entity_id');
      if(param.has('datasource_id') && param.has('dataset_id') && param.has('source_id')){
        this.datasource_id = param.get('datasource_id');
        this.dataset_id =  +param.get('dataset_id');
        this.source_id =  param.get('source_id');
      }      
    });
   
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
    };
    this.getClassifierName();
    this.getProject();
    this.setClassifireForm()
    this.initColDef();
    
    if(this.activeNavLnk==="2"){
      this.getClassifireSource();
    }
    if(this.activeNavLnk==="3"){
      this.getDatasetColumns();
    }
  }
  onChange(event) {
    if (event.code === "Space" && event.target.value == '') {
      return false;
    }
  }

  setClassifireForm() {
    this.createClassiFireForm = this.formBuilder.group({
      name: [this.classifier['classifier_name'], [Validators.required]]
    })
  }

  initColDef() {
    this.gridColumnsDef = [
      {
        'displayname': '',
        'physicalname': 'isChecked',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 30,
        'maxWidth': 40,
      }, {
        'displayname': 'Data Source',
        'physicalname': 'datasource_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true
      },
      {
        'displayname': 'Data Set Name',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': hyperLinkFormattter,
      }
    ];

    this.classifireGridColumnsDef =[
      {
        'displayname': '',
        'physicalname': 'isChecked',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 30,
        'maxWidth': 40,
      }, {
        'displayname': 'Column',
        'physicalname': 'column',
        'sortable': true,
        'datatype': 'String',
        'filterable': true
      },
      {
        'displayname': 'Data Type',
        'physicalname': 'column_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ]

  }
  getProject(){
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id
    };
    this.contentService.getclassifyProject(payload).subscribe(resp => {
      if(resp){
        this.projectData = resp;
        if (this.showBreadcrumb === true) {                    
          this.progressState.states = this.zettaUtils.getStateList('manage-project-data');
          this.progressState.currentStateIndex = 1;
          this.progressState.type = 'manageProjectData';
        }
        else {
          this.progressState.states = this.zettaUtils.getStateList('create-classify-project');
          this.progressState.currentStateIndex = 3;
          this.progressState.type = 'Data Classification Project';
          this.progressState.classificationProject = resp;
          this.progressState.isEdit = this.isEditProject === 'true'
        }
        this.progressState.currentStateInfo = 'Saved...';
      }
      this.isDataReady = true;
    });
  }
  getClassifierName() {
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id
    };
    this.contentService.getClassifireName(payload).subscribe(resp => {
      if (resp.length) {
        this.classifier = resp[0];
        this.setClassifireForm();
      }
    }, error => { });
  }
  getClassifireSource() {
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id
    };
    
      this.contentService.getClassifireDataSource(payload).subscribe(resp => {
      if (resp.length) {
        this.total_source = resp[0].length;
        this.dataSource = resp[0];
        this.isSourceDataReady=true;
        
        if(resp[1].length){
          this.classify_project_source_id=resp[1][0].classify_project_source_id;
          const prevDataset = resp[1][0].dataset_id;          
          this.datasource_id=resp[1][0].datasource_id;
          this.dataset_id=resp[1][0].dataset_id;
          this.dataSource.forEach(el => {
            if(el.dataset_id == prevDataset){
              el.isChecked= true;
              this.source_selected = true;
            }else{
              el.isChecked= false;
            }
           });
        }
        // let concatedRows = this.dataSource;
        if (this.sourceGrid) {
          // concatedRows = [...this.sourceGrid.dataView.getItems(), ...this.dataSource];
          this.dataSource.forEach(element => {
            element.id = Math.random();
          });
          // this.dataSource = concatedRows;
          this.sourceGrid.dataView.setItems(this.dataSource);
          this.sourceGrid.gridService.setSelectedRows([]);
        }
        
      }

    }, error => {
      this.isSourceDataReady=true;
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });

  }

  getDatasetColumns(){
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id:this.dataset_id,
      source_id: this.source_id,
      project_id: this.project_id
    };
    
      this.contentService.getClassifireColumn(payload).subscribe(resp =>{
      if(resp.length){
        this.classifireDataSource =resp[0];
        if(resp[1].length){
          this.column_selected=true;
          this.classify_project_source_column_id = resp[1][0].classify_project_source_column_id;
          this.existing_dataset_column_id = resp[1][0].dataset_column_id;
          this.source_selected = true;          
          this.classifireDataSource.forEach(el => {
            el.isChecked= el.dataset_column_id == this.existing_dataset_column_id ? true :false;
           });
        }
        this.classifireDataSource.forEach(element => {
          element.id = Math.random();
        });      
        this.isColumnDataReady=true;
        this.total_columns = resp[0].length
        // let concatedRows = this.classifireDataSource;
        if (this.classifireGrid) {
          // concatedRows = [...this.classifireGrid.dataView.getItems(), ...this.classifireDataSource];
          this.classifireDataSource.forEach(element => {
            element.id = Math.random();
          });
          // this.classifireDataSource = concatedRows;
          this.classifireGrid.dataView.setItems(this.classifireDataSource);
          this.classifireGrid.gridService.setSelectedRows([]);       
        }
        if(this.existing_dataset_column_id){
          this.dataset_column_id = this.existing_dataset_column_id;
          this.getDatasetProfile(this.existing_dataset_column_id);
        }
      }
     
    },error=>{
      this.isColumnDataReady=true;
     // this.messageSvc.sendMessage({message: error.error.error.message, type: 'ERROR', hideboard: true});
    })
  }
 

  getDatasetProfile(dataset_column_id) {
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString()
    };    
    this.datasetService.getDatasetProfileResult(Datasets, dataset_column_id).subscribe(resp => {
      if (resp) {
        this.is_profile_loading = false;
        if (!resp.profile_report) {
          this.is_no_data = false;
        }
        this.profile_report = resp.profile_report;
        let chartArry: any[] = [];
        if (resp && resp.profile_report) {
          this.is_primary_key = this.profile_report.primary_key_candidate;
          this.profile_report.most_frequent.forEach(el => {
            chartArry.push(el.distribution);
          });
          this.barChartData = [{ data: chartArry, label: 'Most Frequent Data' }]
        }
      }

      this.is_profile_loading = false;
      this.datasetTags = [];
      if (resp.tags != null) {
        this.datasetTags = resp.tags_score;
      }
    }, err => { 
      this.is_profile_loading = false; 
      if(err.status === 404){
        this.messageSvc.sendMessage({message: err.error.message, type: 'ERROR', hideboard: true});
      }
    });
  }

  onSubmit() {
    if (this.createClassiFireForm.invalid) {
      return;
    }
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id,
      payload: {
        display_name: this.createClassiFireForm.controls.name.value,
        entity_id: this.entity_id,
        attribute_id: this.classifier['attribute_id']
      }
    };    
    this.contentService.saveClassifire(data).subscribe(resp => { 
      this.activeNavLnk = "2";
      sessionStorage.setItem('activeLinkStep', "2");
      this.getClassifireSource();      
    }, error => { 
      this.messageSvc.sendMessage({message: error.error.message, type: 'ERROR', hideboard: true});
    });   
    
  }
 
  onSourceCellClick(e): void {
    const event = e.eventData;
    const args = e.args;
    const row = this.sourceGrid.gridService.getDataItemByRowIndex(args.row);
    if (row) {
      if (event.target.className.includes('radio-formatter')) {
        $('#data-'+ args.row).prop('checked', true);
        this.dataset_id = row.dataset_id;
        this.datasource_id = row.datasource_id;
        this.source_selected = true;
        this.dataSource.forEach(element => {
          if (element.dataset_id === row.dataset_id) {
            element.isChecked = true;
          } else {
            element.isChecked = false;
          }
        });     
      }
      if(event.target.title  === row.name){
        this.datasetName =row.name;
        this.datasetId = row.dataset_id;
        this.showmodelpopup =true;
      }
      
    }
  }
  onNextStep2() {
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id,
      payload: {
        dataset_id: this.dataset_id,
        datasource_id: this.datasource_id
      }
    }
    this.contentService.saveClassifireSource(data,this.classify_project_source_id).subscribe(resp => {
      this.source_id = resp[0].classify_project_source_id;
      this.activeNavLnk = "3";
      sessionStorage.setItem('activeLinkStep', "3");  
      this.source_selected =false;     
      const qParams = {catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id:this.datasource_id, dataset_id:this.dataset_id,source_id:this.source_id, showBreadcrumb: this.showBreadcrumb };
      this.router.navigate([`/zs-cl/projects/${this.project_id}/new-classifier`], { queryParams:  qParams,queryParamsHandling:'merge'});
      this.getDatasetColumns();
    }, error => { 
      this.messageSvc.sendMessage({message: error.error.message, type: 'ERROR', hideboard: true});
    });
    this.isSourceDataReady=false;
  }

  onColumnCellClick(e): void {
    const event = e.eventData;
    const args = e.args;
    const row = this.classifireGrid.gridService.getDataItemByRowIndex(args.row);    
    if (row) {
      if (event.target.className.includes('radio-formatter')) {
        $('#data-'+ args.row).prop('checked', true);
        this.dataset_column_id = row.dataset_column_id;
        this.column_selected = true;
        this.classifireDataSource.forEach(element => {
          if (element.dataset_column_id === row.dataset_column_id) {
            element.isChecked = true;
          } else {
            element.isChecked = false;
          }
        });  
        this.getDatasetProfile(this.dataset_column_id);
      }      
    }
  }

  onNextStep3() {
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id,
      source_id: this.source_id,
      payload: {
        dataset_column_id: this.dataset_column_id,
      }      
    };
    this.contentService.saveClassifireColumn(data,this.classify_project_source_column_id).subscribe(resp=>{
      this.activeNavLnk = "3";
      sessionStorage.removeItem('activeLinkStep');
     const qParams = {catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id:this.datasource_id, dataset_id:this.dataset_id,source_id:this.source_id, showBreadcrumb: this.showBreadcrumb };
      this.router.navigate(["/zs-cl/projects/"+ this.project_id +"/add-training-data"], { queryParams:  qParams,queryParamsHandling:'merge'});
    },error=>{
      this.messageSvc.sendMessage({message: error.error.message, type: 'ERROR', hideboard: true});
    })
    
  }
  onPrevStep1(){
    sessionStorage.removeItem('activeLinkStep');
    this.router.navigate(["/zs-cl/projects/"+ this.project_id +"/select-object"], {queryParams:{catalog_id:this.catalog_id,entity_id:this.entity_id,showBreadcrumb: this.showBreadcrumb}, queryParamsHandling:'merge'});
  }
  onPrevStep2() {
    this.activeNavLnk = "1";
    sessionStorage.setItem('activeLinkStep', "1"); 
    this.isSourceDataReady =false;
    this.getClassifierName(); 
  }

  onPrevStep3() {
    this.activeNavLnk = "2";
    sessionStorage.setItem('activeLinkStep', "2"); 
    this.getClassifireSource();
    const qParams = {catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id:this.datasource_id, dataset_id:this.dataset_id,source_id:this.source_id, showBreadcrumb: this.showBreadcrumb };
    this.router.navigate([`/zs-cl/projects/${this.project_id}/new-classifier`], { queryParams:  qParams, queryParamsHandling:'merge'});
    
  }

  gridCreated(grid:AngularGridInstance) {
    this.sourceGrid = grid;
  }

  classifireGridCreated(grid:AngularGridInstance){
    this.classifireGrid = grid;
  }

  onCancel() {
    if (this.showBreadcrumb === true) { 
      this.router.navigate(['/zs-cl/projects/', this.project_id ],{ queryParams: { showBreadcrumb: 'true' }, queryParamsHandling: 'merge' });
    } 
    else {
      this.router.navigate(['/zs-cl/projects' ]);
    }                 
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }
  popupoutput(message){
    this.showmodelpopup = message;   
  }
  onUploadDataSource(){
    this.router.navigate(['/zs/datasets/select-data-source']);
  }
  onManageDataSet(){
    this.router.navigate(['/zs/datasets']);
  }
}
