import { Component, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';
import $ from 'jquery';

@Component({
	selector: 'ad-view-highest-impact-attributes',
	templateUrl: './ad-view-highest-impact-attributes.component.html',
	styleUrls: ['./ad-view-highest-impact-attributes.component.scss']
})

export class AdViewHighestImpactAttributesComponent implements OnInit {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public $container;
	public screenHeight: number = screen.height;
	
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName: string = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	public entitiesChartOptions: object = {
		'resizeRequired': false,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'labelPercentValue' : 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence'
	};

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];
	
	public subChartOptions1 = [];
	public subChartOptions2 = [];


	public chartDS1 = null;
	public chartOptions1: object = {
		'chartID': 1,	//use only with subcharts
		'subchart': false,
		'height': 230,
		'width': 750,
		'resizeRequired': true,
		'labelX': 'IMPACT INDEX',
		'labelY': '',
		'barColor1': '#00CB00',
		'barColor2': '#00a900',
		'margin' : { 'top': 40, 'right': 90, 'bottom': 0, 'left': 210 },
		'isMoneyFormat':true
	};
	
	public chartDS2 = null;
	public chartOptions2: object = {
		'chartID': 2,	//use only with subcharts
		'subchart': false,
		'height': 230,
		'width': 650,
		'resizeRequired': true,
		'labelX': 'IMPACT INDEX',
		'labelY': '',
		'barColor1': '#EC43A2',
		'barColor2': '#EC43A2',
		'margin' : { 'top': 40, 'right': 210, 'bottom': 0, 'left': 90 },
		'isMoneyFormat':true,
	};

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZmZettaUtils,
		private _projectService: AnalyticService,
		public observeSvc: ZmObservableService,
		private activeRoute: ActivatedRoute, private router: Router) {
			
			this.lineChartUniqueID = this.zettaUtils.generateUUID();
			this.userinfo = this._projectService.getObjectFromSession('userInfo');
		
	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;
		// parent.getEntityResolvedData(parent.userinfo.userid, routeParams.id);

		let dataSet1 = [
			{ 
				'id': 1, 
				'label': '11k_Clone_Delete',
				'value': 80,
				'subvalues': [ { 'label': '11k_Clone_Delete abc', 'value': 80 },
							   { 'label': '11k_Clone_Delete defgh', 'value': 55 },
							 ]
			},
			{ 
				'id': 2, 
				'label': 'Test_libpostal_project', 
				'value': 185,
				'subvalues': [ { 'label': 'Test_libpostal_project abc', 'value': 185 },
							   { 'label': 'Test_libpostal_project defgh', 'value': 95 },
							   { 'label': 'Test_libpostal_project sssss', 'value': 45 },
							 ] 
			},
			{ 
				'id': 3, 
				'label': 'Dummey_Resolve', 
				'value': 290,
				'subvalues': [ { 'label': 'Dummey_Resolve abc', 'value': 100 },
							   { 'label': 'Dummey_Resolve defgh', 'value': 290 },
							 ] 
			},
			{ 
				'id': 4, 
				'label': 'Generated Data 5K_Veda', 
				'value': 400,
				'subvalues': [ { 'label': 'Generated Data 5K_Veda abc', 'value': 280 },
							   { 'label': 'Generated Data 5K_Veda defgh', 'value': 320 },
							   { 'label': 'Generated Data 5K_Veda aaaaa', 'value': 365 },
							   { 'label': 'Generated Data 5K_Veda bbbbb', 'value': 400 },
							 ] 
			},
			{ 
				'id': 5, 
				'label': 'Generated Data 3K_Veda', 
				'value': 623,
				'subvalues': [ { 'label': 'Generated Data 3K_Veda abc', 'value': 623 },
							   { 'label': 'Generated Data 3K_Veda defgh', 'value': 190 },
							 ] 
			},
			{ 
				'id': 6, 
				'label': 'Test_libpostal_project 8K', 
				'value': 710,
				'subvalues': [ { 'label': 'Test_libpostal_project 8K abc', 'value': 710 },
							   { 'label': 'Test_libpostal_project 8K defgh', 'value': 390 },
							 ] 
			},
			{ 
				'id': 7, 
				'label': 'Institution Real Data 15K', 
				'value': 830,
				'subvalues': [ { 'label': 'Institution Real Data 15K abc', 'value': 350 },
							   { 'label': 'Institution Real Data 15K defgh', 'value': 830 },
							 ] 
			},
		];
		parent.chartDS1 = dataSet1;

		let dataSet2 = [
			{ 
				'id': 1,
				'label': '11k_Clone_Delete', 
				'value': -830, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -810 },
					{ 'label': 'Dummey_Resolve_label', 'value': -530 },
					{ 'label': 'Generated Data 5K_Veda_label', 'value': 400 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 290 },
				]
			},
			{ 
				'id': 2,
				'label': 'Test_libpostal_project', 
				'value': -710, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -710 },
					{ 'label': 'Dummey_Resolve_label', 'value': -243 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 190 },
				]
			},
			{ 
				'id': 3,
				'label': 'Test_libpostal_project222', 
				'value': -623, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -623 },
					{ 'label': 'Dummey_Resolve_label', 'value': 444 },
					{ 'label': 'Generated Data 5K_Veda_label', 'value': -333 },
					{ 'label': 'Institution Real Data 11K_label', 'value': -290 },
				]
			},
			{ 
				'id': 4,
				'label': 'Dummey_Resolve', 
				'value': -530, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -530 },
					{ 'label': 'Dummey_Resolve_label', 'value': -430 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 390 },
				]
			},
			{ 
				'id': 5,
				'label': 'Generated Data 5K_Veda', 
				'value': -400, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -400 },
					{ 'label': 'Dummey_Resolve_label', 'value': -230 },
					{ 'label': 'Generated Data 5K_Veda_label', 'value': -60 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 390 },
				]
			},
			{ 
				'id': 6,
				'label': 'Institution Real Data 11K', 
				'value': -290, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -290 },
					{ 'label': 'Dummey_Resolve_label', 'value': -130 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 90 },
				]
			},
			{ 
				'id': 7,
				'label': 'Generated Data 3K_Veda', 
				'value': -215, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -215 },
					{ 'label': 'Dummey_Resolve_label', 'value': -130 },
					{ 'label': 'Generated Data 5K_Veda_label', 'value': 30 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 190 },
				]
			},
			{ 
				'id': 8,
				'label': 'Test_libpostal_project 8K', 
				'value': -185, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -185 },
					{ 'label': 'Dummey_Resolve_label', 'value': -55 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 66 },
				]
			},
			{ 
				'id': 9,
				'label': 'Institution Real Data 15K', 
				'value': -80, 
				'subvalues': [
					{ 'label': 'Test_libpostal_project_label', 'value': -80 },
					{ 'label': 'Dummey_Resolve_label', 'value': -63 },
					{ 'label': 'Generated Data 5K_Veda_label', 'value': 77 },
					{ 'label': 'Institution Real Data 11K_label', 'value': 12 },
				]
			},
		];
		parent.chartDS2 = dataSet2;

		for (let i = 0; i < parent.chartDS1.length; i++) {
			this.subChartOptions1.push({ 
									'chartID': this.chartOptions1['chartID'] + '_' + (i+1),
									'subchart': true,
									'height': 120, 
									'width': 660,
									'resizeRequired': true,
									'labelX': 'CHANGE IMPACT',
									'labelY': '',
									'barColor1': '#FBB54D',
									'barColor2': '#FBB54D',
									'margin' : { 'top': 40, 'right': 100, 'bottom': 0, 'left': 250 },
									'isMoneyFormat':true
			});
		}

		for (let i = 0; i < parent.chartDS2.length; i++) {
			this.subChartOptions2.push({ 
									'chartID': this.chartOptions2['chartID'] + '_' + (i+1),
									'subchart': true,
									'height': 120, 
									'width': 660,
									'resizeRequired': true,
									'labelX': 'CHANGE IMPACT',
									'labelY': '',
									'barColor1': '#FBB54D',
									'barColor2': '#FBB54D',
									'margin' : { 'top': 40, 'right': 100, 'bottom': 0, 'left': 250 },
									// 'margin' : { 'top': 40, 'right': 470, 'bottom': 50, 'left': 90 },
									'isMoneyFormat':true
			});
		}


		
		// parent._projectService.getEntitiesSummaryData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.statisticsObj = {
		// 			'model_runs': 0,
		// 			'model_runs_as_of': new Date(),
		// 			'unique_institutions': 0,
		// 			'compression_ratio': 'N/A'
		// 		};
				
		// 	for (let index = 0; index < data.length; index++) {
		// 		const obj = data[index];
		// 		if (obj.name === 'run_number') {
		// 			parent.statisticsObj['model_runs'] = obj['value'] || 0;
		// 		}
		// 		else if (obj.name === 'as_of') {
		// 			parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
		// 		} else if (obj.name === 'total_entities') {
		// 			parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
		// 		} else if (obj.name === 'compression_ratio') {
		// 			parent.statisticsObj['compression_ratio'] = obj['count'] || 'N/A';
		// 		} else if (obj.name === 'overall_confidence') {
		// 			parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
		// 			parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
		// 		}
		// 	}
		// 		parent.entitiesDataSource = parent.getDonutParseData(data);
		// 	},
		// 	error => {
		// 		parent.entitiesDataSource = [];
		// 		parent.statisticsObj = {'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 'N/A'};
		// 	}
		// );

	}

	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
				this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	// prepareLineChartData(data) {
	//     const dataSet = [];
	//     data.forEach((element) => {
	//     	const d = new Date(element['createdts']);
	//         dataSet.push({ 'label': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
	//     });
	//     return dataSet;
	//   }
	  
	//   refresh() {
	// 	window.location.reload();
	//   }
}
