import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { DatasetService } from '../../dataset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDataset } from '../../dataset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { dateFormatter } from '../../../../../common/shared/formatters/dateFormatter';
import { ObservableService } from '../../../../../common/services/observable.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { MessageService } from '../../../message/message.service';
declare var $: any;

@Component({
  selector: 'zetta-data-description',
  templateUrl: './data-description.component.html',
  styleUrls: ['./data-description.component.scss'],
})

export class DataDescriptionComponent implements OnInit {

  @Output() setDSName: EventEmitter<any> = new EventEmitter();
  activityHistory: object = {};
  grid: AngularGridInstance;
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  historyTableColDef: any;
  gridOptions: any;
  createDatasetForm: FormGroup;
  dataset: any = {};
  isDataReady = true;
  getDataSet: any = [];
  datasetAttributes: any;
  totalAttributes: number;
  @Input() dataRes1: any;
  @Output() setDSDQScore: EventEmitter<any> = new EventEmitter();
  // dataRes;

  isLoading = true;
  page = 1;
  limit = 100;
  noData = false;
  hasScrolled = false;
  isNotDataAdmin: boolean = true;
  tagsList: any = [];
  datasetTags: any = [];
  isAdmin = false;
  isRead = false;
  isReadWrite = false;
  isAdminReadWrite = false;
  user_apps = false;
  score_class: string;
  summary: any = {};
  isSummaryReady = false;
  measured_dq_score: string;
  measured_rule_css: string;
  dataset_id: number;
  datasetName: string;
  showClassificationTag = false;
  showDownvoteModal = false;
  catalog_id: number;
  object_id: number;
  selected_row: number;
  canEdit: Array<Object> = [];
  constructor(private datasetSvc: DatasetService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private zettaUtils: ZettaUtils,
    private router: Router,
    public observeSvc: ObservableService,
    private catalogSvc: ZsClContentService,
    private messageSvc: MessageService) {
    const apps = JSON.parse(sessionStorage.userInfo).apps;
    if (apps.length) {
      const cl_apps = apps.filter(item => item.name == 'Classify');
      if (cl_apps.length) {
        this.user_apps = true;
      }

    }
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset.dataset_id = params['id'];
      this.dataset_id = params['id'];
    });
    if (sessionStorage.getItem('user_entitlement')) {
      let user_entitlement = sessionStorage.getItem('user_entitlement');
      let user = user_entitlement.split(',');
      this.isAdmin = JSON.parse(user[0]) ? true : false;
      this.isReadWrite = JSON.parse(user[1]) ? true : false;
      this.isRead = JSON.parse(user[2]) ? true : false;
      if (this.user_apps && (this.isAdmin || this.isReadWrite || this.isRead)) {
        this.isAdminReadWrite = true;
      }
    }
    this.getDQDatasetSummary();
    this.getDataset();


    this.historyTableColDef = [{
      'displayname': 'Date Time',
      'physicalname': 'created_ts',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': dateFormatter
    }, {
      'displayname': 'User',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'Activity',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
  }

  getDQDatasetSummary() {
    this.datasetSvc.getDQDatasetSummary(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset.dataset_id, null).subscribe((data) => {
      console.log(data)
      if (data.length > 0) {
        this.summary = data[0];
        this.summary.measured_dq_score = this.summary.measured_dq_score;
      } else {
        this.score_class = 'n/a';
        this.measured_dq_score = 'n/a';
      }
      this.summary.progressbar_bgcolor = this.summary.measured_dq_score >= 75 ? 'high' : this.summary.measured_dq_score >= 50 ? 'medium' : 'low';
      this.isSummaryReady = true;
      this.score_class = this.summary.measured_dq_score !== 'n/a' ? ((this.summary.measured_dq_score) >= 75 ? 'high' : (this.summary.measured_dq_score >= 50 && this.summary.measured_dq_score < 75) ? 'medium' : 'low') : 'n/a';
      const ruleObj = this.catalogSvc.getMeasuredRule(this.summary.measured_dq_score, this.loggedInUserDetails['enable_dq_rule'])
      this.measured_dq_score = ruleObj["dqRuleText"];
      for (let i = 0; i <= this.summary.measured_dq_score; i++) {
        setTimeout(() => {
          $('.progress-bar').css('width', this.loggedInUserDetails.enable_dq_rule ? i + '%' : '0%').attr('aria-valuenow', this.loggedInUserDetails.enable_dq_rule ? i : 0);
        }, 10);
      }
      this.setDSDQScore.emit(this.summary.measured_dq_score);
    }, err => {
    });
  }

  getActivityHistoryData(pageNo, limit = 1) {
    const getDataset: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset.dataset_id,
      pageno: pageNo,
      perpage: limit
    };
    this.datasetSvc.getActivityHistory(getDataset).subscribe((data) => {
      this.activityHistory = data;
      if (this.activityHistory['currentpage'].length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      let concatedRows = this.activityHistory['currentpage'];
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.activityHistory['currentpage']];
        this.activityHistory['currentpage'].forEach(element => {
          element.id = Math.random();
        });
        this.activityHistory['currentpage'] = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
    });
  }

  clearActivity() {
    this.grid.dataView.setItems([]);
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onNext(): void {
    this.page++;
    this.getActivityHistoryData(this.page, this.limit);
  }

  viewHistoryClick() {
    this.getActivityHistoryData(this.page, this.limit);
  }

  setDatasetForm() {
    this.createDatasetForm = this.formBuilder.group({
      description: [this.getDataSet.description],
      name:  [this.getDataSet.name]
    });
  }

  getDataset() {
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset.dataset_id
    }
    this.datasetSvc.getDataset(Datasets).subscribe((responseList) => {
      this.isLoading = false;
      this.isDataReady = true;
      this.getDataSet = responseList;
      this.dataset = this.getDataSet;
      this.datasetName = responseList['name'];
      if(responseList.entitlement && responseList.entitlement.users && responseList.entitlement.users.length) {
        this.canEdit = responseList.entitlement.users.filter(user => user.is_admin).filter(filteredUser => filteredUser.user_id === this.loggedInUserDetails.user_id);
      }
      this.isNotDataAdmin = responseList['created_by'] === this.loggedInUserDetails.user_name ? false : true;
      this.setDSName.emit(responseList['name']);
      this.setDatasetForm();
      this.datasetTags = [];
      if (responseList.tags != null) {
        this.datasetTags = responseList.tags_score;
        this.datasetTags.forEach(element => {
          element.level = element.score >= 75 ? 'HIGH' : (element.score >= 50 && element.score < 75) ? 'MEDIUM' : 'LOW';
        });
        this.datasetTags.sort(((a, b) => b.score - a.score));
      }
    }, err => { 
      this.isDataReady = true;
    });
  }

  getDatasetAttributes() {
    this.datasetSvc.getDatasetColumns(this.loggedInUserDetails, this.dataset.dataset_id).subscribe((data) => {
      this.datasetAttributes = data;
      this.totalAttributes = this.datasetAttributes.length;
    }, err => {
    });
  }

  editDiscriptionClick() {
    this.isDataReady = false;
    this.createDatasetForm.controls.name.patchValue('');
    this.createDatasetForm.controls.description.patchValue('');
    this.getDataset();
  }

  onSave() {
    this.isDataReady = false;
    if (this.createDatasetForm.valid) {
      const datasetTemp: IDataset = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.loggedInUserDetails.tenant_id,
        dataset_id: this.dataset.dataset_id,
        payload: {
          'description': this.createDatasetForm.controls.description.value,
          'name': this.createDatasetForm.controls.name.value
        }
      };
      this.datasetSvc.editDataset(datasetTemp)
      .subscribe(resp => {
        if(resp) {
          this.getDataSet['name'] = resp['name'];
          this.getDataSet['description'] = resp['description'];
          this.getDataSet['updated_ts'] = resp['updated_ts'];
          this.observeSvc.datasetLabelUpdate.next(resp);
          this.isDataReady = true;
          $('#edit_description_modal').modal('hide');
          $('.modal-backdrop').remove();
        }
      }, err => {
       this.messageSvc.sendMessage({ message: (err && err.error && err.error.message) ? err.error.message : 'OopsSomething went wrong', type: 'ERROR', hideboard: true });
       this.isDataReady = true;
      });
    }
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  classifySet() {
    this.router.navigate(['/zs/datasets/' + this.dataset.dataset_id + '/catalogs']);
  }

  onClick() {
    this.showClassificationTag = true;
  }
  hideClassificationModal(event) {
    this.showClassificationTag = event;
  }
}
