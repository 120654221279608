import { AppGlobals } from '../../../common/shared/app.globals';
import { Component, OnInit, ViewContainerRef, ViewEncapsulation, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { numericFormatter } from '../../../common/shared/formatters/numericFormatter';
import { optionSelectionFormatter } from '../../../common/shared/formatters/optionSelectionFormatter';
import { blueTextModelFormatter } from '../../../common/shared/formatters/blueTextModelFormatter';
import { assignmentStatusFormatter } from '../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../../../zettasense/content/project/project.model';
import { ActivatedRoute } from '@angular/router';
import { matchFormatter } from '../../../common/shared/formatters/matchFormatter';
import { noMatchFormatter } from '../../../common/shared/formatters/noMatchFormatter';
import { notSureFormatter } from '../../../common/shared/formatters/notSureFormatter';
import { FeedbackFilter } from '../../../common/shared/filters/feedback-filter';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { checkBoxFormattter } from '../../../common/shared/formatters/checkBoxFormatter';
import { toolTipFormatter } from '../../../common/shared/formatters/toolTipFormatter';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { MessageService } from '../message/message.service';
declare var $: any;

@Component({
  selector: 'zetta-matcher-feedback-modal',
  templateUrl: './matcher-feedback-modal.component.html',
  styleUrls: ['./matcher-feedback-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatcherFeedbackModalComponent implements OnInit, OnChanges {

  /* Stage 1 properties */
  public isStage1Loading = true;
  public isStage1SelectedClusterExpanded = false;
  public stage1SelectedClusterTotal = 0;

  public stage1Rows: object[];
  public stage1Columns: object[];
  public stage1selectedClusterName: string;
  public stage1FeedBack: object[] = [];

  /* Stage 2 properties */
  public isStage2Loading = true;
  public isStage2SelectedClusterExpanded = false;
  public stage2SelectedClusterTotal = 0;

  public stage2Rows: object[];
  public stage2Columns: object[];
  public stage2selectedClusterName: string;
  stage2FeedBack: object[] = [];

  /* Stage 3 properties */
  public isStage3Loading = true;
  public isStage3SelectedClusterExpanded = false;
  public stage3SelectedClusterTotal = 0;
  searchedClusterkeys = [];
  searchedClusters;
  newlySelectedClusters;
  isSearching = false;
  searchedColumns;
  selectedOrphanRecords;
  orphanGrid: AngularGridInstance;
  selectedClusterColumns;
  stage3EnableFinishBtn: boolean = false;
  addedClusterGrid: Array<AngularGridInstance> = [];
  public stage3Rows: object[];
  public stage3Columns: object[];
  public stage3selectedClusterName: string;
  public searchtext = '';
  public suggestedClusters: object = {};
  public suggestedFeedback: object = {};
  public isSuggestedClusterLoading = false;
  public isSuggested = true;
  public suggestedColumns;
  public trainModelDataSource: object = {};
  public selectOptionDataSource: object = {};
  public suggestedClusterkeys = [];
  public rowData;
  public grid: AngularGridInstance;
  public stage2Grid: AngularGridInstance;
  public suggestedGrid: AngularGridInstance;
  public matcherGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedClusterName;
  public selectedCluster;
  public isSelectedClusterExpanded = false;
  public selectedColumnsfeedbackColumns;
  public currentStage = 0;
  public selectedClusterId;
  public hasPrevious = true;

  selectedOrphanRecsToAssign = [];
  viewTaskAssignments = false;
  taskAssignmentsColDef: any;
  isTaskAssignmentsLoading = true;
  public hasScrolled = false;
  taskAssignmentsRowData: any;
  taskAssignmentsGridOptions: any;
  taskAssignmentsGrid: AngularGridInstance;


  public tableSettings: object = { 'height': '100%', 'width': '100%', 'pageSize': 6, 'isNormalPagination': false };
  public addedClusterTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  gridOptions: any;
  feedbackGridOptions: any;
  feedbackStage3GridOptions: any;
  addedClusterGridOptions: any;
  selectedClusters = [];
  addedClusters = [];
  customAddedClusters = [];
  selectedRecsData = [];
  newCluster = {
    opened: false
  };
  selectedOrphanIndexes = [];
  addedCluserInstance: Array<AngularGridInstance> = [];
  newPayload = [];

  stage2GridOptions: any;
  projectDetail = new Project();

  isLoading = false;
  isClusterItemsLoading = false;
  total = 0;
  page = 1;
  limit = 100;
  pageLeft = 1;
  selectedClusterTotal = 0;
  noneOfAboveSearchedCluster: any;
  proposedClusters: any;
  noData = false;
  selectedOrphanRecordsCount;
  ownClusterSelected: boolean = false;
  existingClustersSelected: boolean = false;
  customAddedCluserInstance: Array<AngularGridInstance> = [];
  onPreviousClicked: boolean = false;
  msgText: string = '';
  msgHeader: string = '';
  showConfirmBox: boolean = false;
  isMatchFixingTasks: boolean = false;
  taskStatusCompleted: string = AppGlobals.TASK_COMPLETED;
  taskStatusReviewed: string = AppGlobals.TASK_REVIEWED_STATUS;
  showLoadingSpinnerWhileCapturingMatchFixingTasks: boolean = false;
  isErroredWhileFetchingSuggestedCluster = false;

  @Input() selectedZsClusterId: string;
  @Input() selectedZsTaskName: string;
  @Input() selectedZsTaskType: string;
  @Input() selectedZsProjectId: string; 
  @Input() taskType: string;
  @Input() selectedZsTaskStatus: string;
  @Input() shouldDisableFeedback = false;
  @Input() shouldMoveMatchFixingData: boolean = false;// If true, used to merge/swipe data
 isProductionStageTasks:boolean = false;
 @Input() producitonTask? : boolean = false
  @Output() onCLose = new EventEmitter<boolean>(false);
  @Output() onComplete = new EventEmitter<boolean>();
  @Output() onCompleteMatchFixingTasks = new EventEmitter<Object>();

  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    public viewContainerRef: ViewContainerRef,
    private messageSvc: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.trainModelTableColDef = [{
      'displayname': 'Task Name',
      'physicalname': 'zs_cluster_identifier',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 130,
      'formatter': blueTextModelFormatter,
    }, {
      'displayname': 'Records',
      'physicalname': 'zs_total_records_cluster',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-right',
      'headerCssClass': 'text-right',
      'formatter': numericFormatter,
    }, {
      'displayname': 'Status',
      'physicalname': 'status',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 140,
      'headerCssClass': 'pl-4 ml-3',
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Open',
      'physicalname': '',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-center',
      'formatter': optionSelectionFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'headerCssClass': 'pl-4',
      'formatter': dateFormatter
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.taskAssignmentsGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 15,
    };

    this.feedbackGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };

    this.addedClusterGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      CheckboxSelector: true,
      multiSelectable: true,
    };
    this.feedbackStage3GridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      multiSelectable: true
    };

    this.stage2GridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      frozenColumn: 2,
    };
    if (this.isMatchFixingTasks) {
      $('#matcher-feedback-modal').modal({
        show: true,
        backdrop: 'static'
      });
    } else {
      $('#matcher-feedback-modal').modal('show');
    }
    if(this.router.url.includes('production-stage-details')) {
      this.isProductionStageTasks = true;
    } else {
      this.isProductionStageTasks = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    // this.activatedRoute.parent.params.subscribe(params => {
    //   this.projectDetail.project_id = '25';
    // });
    // this.activatedRoute.parent.params.subscribe(params => {
    //   this.projectDetail.project_id = params['id'];
    // });
    this.currentStage = 0;
    this.projectDetail.project_id = this.selectedZsProjectId;
    this.selectedClusterId = this.selectedZsClusterId;
    this.stage1selectedClusterName = this.selectedZsTaskName;
    this.taskType = this.selectedZsTaskType;
    this.isMatchFixingTasks = this.taskType === AppGlobals.RESOLVE_MATCH_FIXING_TASK ? true : false;
    this.getSupervisedFeedback();
    if(changes && changes.producitonTask && changes.producitonTask.currentValue) {
      this.producitonTask = changes.producitonTask.currentValue;
    }
    if(changes && changes.shouldMoveMatchFixingData && changes.shouldMoveMatchFixingData.currentValue) {
      this.shouldMoveMatchFixingData = changes.shouldMoveMatchFixingData.currentValue;
    }
}

  getTaskAssignmentsColDef() {
    this.taskAssignmentsColDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'formatter': blueTextFormattter,
    }, {
      'displayname': 'Project Admin',
      'physicalname': 'isprojectadmin',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxFormattter,
      'columnGroup': 'Authorizations'
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'isreviewer',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxFormattter,
      'columnGroup': 'Authorizations'
    }, {
      'displayname': 'Approver',
      'physicalname': 'isapprover',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxFormattter,
      'columnGroup': 'Authorizations'
    }];
  }

  onSelectedCluster(e) {
    this.isSelectedClusterExpanded = true;
  }

  getSelectedClusterCount() {
    return this.selectedClusterTotal;
  }

  selectOptionModelList(clusterId, limit = 1000) {
    this.isLoading = true;
    this.isSelectedClusterExpanded = false;
    this.selectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        clusterId, this.projectDetail.project_id, this.page, limit).subscribe((data) => {
          this.selectOptionDataSource = data;
          this.rowData = this.selectOptionDataSource['currentpage'];
          this.selectedColumnsfeedbackColumns = this.selectOptionDataSource['columns'];
          this.selectedColumnsfeedbackColumns.unshift(...this.getFeedbackColumns());
          this.rowData.forEach((element: any) => {
            element.id = Math.random();
            this.selectedClusterName = element.zs_cluster_identifier;
          });
          this.getSuggestedCluster(clusterId, this.selectedClusterName);
          this.selectedClusterTotal = data['totalrecords'];
          if (this.matcherGrid) {
            this.matcherGrid.dataView.setItems(this.rowData);
            this.matcherGrid.gridService.setSelectedRows([]);
          }
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
        });
  }

  getSuggestedCluster(clusterId, fullName): void {
    this.isSuggested = true;
    this.isErroredWhileFetchingSuggestedCluster = false;
    this.ProjectSvc.getSuggestedCluster({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: clusterId, clusterName: fullName }).subscribe(resp => {
        this.isSuggestedClusterLoading = true;
        this.suggestedClusterkeys = Object.keys(resp.currentpage);
        this.onLoadModal(this.isMatchFixingTasks);
        this.suggestedClusters = resp.currentpage;
        this.suggestedColumns = resp.columns;
        this.suggestedColumns.forEach(col => {
          col.displayname = col.display_name;
          if (col.displayname === 'Full Name') {
            col.minWidth = 300;
          }
          col.physicalname = col.physical_name;
          col.datatype = col.data_type;
          col.formatter = toolTipFormatter;
        });
        this.suggestedClusterkeys.forEach(e => {
          this.suggestedClusters[e].rows = [];

          this.suggestedClusters[e].forEach(element => {
            element.id = Math.random();
            if (element['zs_feedbackid'] !== undefined && element['zs_feedbackid'] !== '') {
              this.suggestedFeedback[e] = element['zs_feedbackid'];
            }
            this.suggestedClusters[e].rows.push(element);
          });
        });
        this.isSuggested = false;
      }, err => {
        this.isSuggested = false;
        this.isErroredWhileFetchingSuggestedCluster = true;
        this.onLoadModal(this.isMatchFixingTasks);
      });
  }

  getFeedbackColumns() {
    return [{
      'displayname': '',
      'physicalname': 'match',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': matchFormatter,
      'filter': {
        model: new FeedbackFilter('Match', 'match-feedback')
      },
      'params': { selectedTaskStatus: this.selectedZsTaskStatus, shouldDisableFeedback: this.shouldDisableFeedback }
    }, {
      'displayname': '',
      'physicalname': 'nomatch',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': noMatchFormatter,
      'filter': {
        model: new FeedbackFilter('No Match', 'nomatch-feedback')
      },
      'params': { selectedTaskStatus: this.selectedZsTaskStatus, shouldDisableFeedback: this.shouldDisableFeedback }
    }, {
      'displayname': '',
      'physicalname': 'notsure',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': notSureFormatter,
      'filter': {
        model: new FeedbackFilter('Not Sure', 'notsure-feedback')
      },
      'params': { selectedTaskStatus: this.selectedZsTaskStatus, shouldDisableFeedback: this.shouldDisableFeedback }
    }];
  }

  onStage2UpdateFeedback(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.stage2Grid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('radio-formatter')) {
        if (this.stage2SelectedClusterTotal === 1) {
          return;
        }
        if ($(eventEle.target).hasClass('match-formatter')) {
          row.zs_feedbackid = AppGlobals.MATCH_FEEDBACK_STATUS;
        } else if ($(eventEle.target).hasClass('nomatch-formatter')) {
          row.zs_feedbackid = AppGlobals.NO_MATCH_FEEDBACK_STATUS;
        } else if ($(eventEle.target).hasClass('notsure-formatter')) {
          row.zs_feedbackid = AppGlobals.NOT_SURE_FEEDBACK_STATUS;
        }
        this.stage2Grid.gridService.updateDataGridItem(row, false);
        this.stage2FeedBack = this.stage2FeedBack.filter(data => data['zs_recid'] !== row.zs_recid);
        this.stage2FeedBack.push({
          'zs_recid': row.zs_recid,
          'zs_feedbackid': row.zs_feedbackid
        });
        // auto save for 2nd screen removed since it is changing task status
        this.autoUpdateFeedback(this.stage2FeedBack)
      }
    }
  }

  autoUpdateFeedback(data) {
    let anchorRecord = []
    this.stage2Rows.filter(row => {
      if (row['is_anchor_record']) {
        anchorRecord.push({
          'zs_recid': row['zs_recid'],
          'zs_feedbackid': '1',
          'feedback_type': 'not_matched_records'
        })
      }
    });
    data.forEach(item => {
      item['feedback_type']= 'not_matched_records';
    });
    const requestPayload = [...data, ...anchorRecord];
    this.isClusterItemsLoading = true;
      this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload })
      .subscribe(resp => {
          if (resp) {
            this.taskSaved();
          }
      }, err => {
      });
  }

  updateMatchFixingFeedbackForStep1AndStep2(data, refresh) {
    let self = this;
    if (this.currentStage == 0) {       
      this.ProjectSvc.updateFeedbackOfMatchFixingTasks({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.stage1FeedBack }, this.shouldMoveMatchFixingData).subscribe(resp => {
        if (refresh) {
          let matchededSuggestedClusters = [];
          for (let cluster in this.suggestedFeedback) {
             if (this.suggestedFeedback[cluster] == "1") {
               matchededSuggestedClusters.push(cluster);
             }
          }
          
          let isMatchedRecordPresent = matchededSuggestedClusters.length ? true : false;
          $('#tm-modal').modal('toggle');
          self.taskSaved();
          // step1: handling shouldDeltaMatcherToRun if any of the suggested cluster is matched and 'shouldMoveMatchFixingData' -> true 
          self.refreshMatchFixingTasks(isMatchedRecordPresent, this.shouldMoveMatchFixingData);
        }
      }, err => {
        self.messageSvc.sendMessage({ message: 'Something went wrong', type: 'ERROR', hideboard: true });
      });
    } else {
      const requestPayload = data;
      this.isClusterItemsLoading = true;
      this.ProjectSvc.updateFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload }).subscribe(resp => {
        if (refresh) {
          self.taskSaved();
          self.refreshMatchFixingTasks(false, false); // stage 2
          self.removeCancel();
        }
      }, err => {
         // throw error message here
         self.messageSvc.sendMessage({ message: 'Something went wrong', type: 'ERROR', hideboard: true });
      });
    }
  }

  updateFeedback(data, refresh) {
      if(this.currentStage == 0) {
        this.ProjectSvc.updateFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.stage1FeedBack }).subscribe(resp => {
          if (refresh) {
            $('#tm-modal').modal('toggle');
            this.taskSaved();
            this.refresh();
          }
        }, err => {
        });
      } else {
        const requestPayload = data;
        this.isClusterItemsLoading = true;
        this.ProjectSvc.updateFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload }).subscribe(resp => {
          if (refresh) {
            this.taskSaved();
            this.refresh();
            this.removeCancel();
          }
        }, err => {
        });
      }
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onSuggestedGridCreation(grid) {
    this.suggestedGrid = grid;
  }

  onStage2GridCreation(grid) {
    this.stage2Grid = grid;
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('matcher-feedback-modal');
    this.zettaUtilsSvc.setGridHorizontalScrollTogether('matcher-feedback-modal');
  }

  onStage3GridCreation(grid) {
    const parent = this;
    parent.orphanGrid = grid;
    parent.selectedOrphanIndexes = [];
    parent.orphanGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedOrphanRecordsCount = args.rows.length > 0 ? true : false
      const row = parent.orphanGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.selectedOrphanRecords = [];
      args.rows.forEach(ind => {
      parent.selectedOrphanIndexes = args.rows;
      parent.selectedOrphanRecords.push(parent.stage3Rows[ind]);
    });
    });
}

onGridCreation2(grid, cluserIndex) {
  let parent = this;
  parent.selectedOrphanRecsToAssign = [];
  parent.addedCluserInstance[cluserIndex] = grid;
  parent.addedCluserInstance[cluserIndex].slickGrid.onSelectedRowsChanged.subscribe((eve, argus) => {
  const row = parent.addedCluserInstance[cluserIndex].gridService.getDataItemByRowIndex(argus.rows);
  parent.addedCluserInstance[cluserIndex]['selectedRowIdexes'] = argus.rows;
  parent.addedClusters[cluserIndex]['selectedOrphanRecordsCount'] = argus.rows.length > 0 ? true : false;
  parent.addedClusters[cluserIndex]['selectedOrphanRecordsLength'] = argus.rows.length;
  });
  parent.addedClusterGrid[cluserIndex] = grid;
  grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    setTimeout(() => {
    const row = grid.gridService.getDataItemByRowIndex(args.rows);
    const leftSideGridDataSet = grid.slickGrid.getSelectedRows();
    parent.selectedOrphanRecsToAssign.push(row);
    let selectedRecsData = [{clusterIndex: cluserIndex, 
      orphanRecInd: leftSideGridDataSet, orphanRec: parent.addedClusters[cluserIndex]}];
      parent.selectedRecsData.push(selectedRecsData);
}, 1000);
  });
}

onCustomGridCreation2(grid, cluserIndex) {
  let parent = this;
  parent.customAddedCluserInstance[cluserIndex] = grid;
  parent.customAddedCluserInstance[cluserIndex].slickGrid.onSelectedRowsChanged.subscribe((eve, argus) => {
    const row = parent.customAddedCluserInstance[cluserIndex].gridService.getDataItemByRowIndex(argus.rows);
    parent.customAddedCluserInstance[cluserIndex]['selectedRowIdexes'] = argus.rows;
    parent.customAddedClusters[cluserIndex]['selectedOrphanRecordsCount'] = argus.rows.length > 0 ? true : false;
    parent.customAddedClusters[cluserIndex]['selectedOrphanRecordsLength'] = argus.rows.length;
  });
  parent.addedClusterGrid[cluserIndex] = grid;
  grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    setTimeout(() => {
      const row = grid.gridService.getDataItemByRowIndex(args.rows);
      const leftSideGridDataSet = grid.slickGrid.getSelectedRows();
      parent.selectedOrphanRecsToAssign.push(row);
      let selectedRecsData = [{
        clusterIndex: cluserIndex,
        orphanRecInd: leftSideGridDataSet, orphanRec: parent.customAddedClusters[cluserIndex]
      }];
      parent.selectedRecsData.push(selectedRecsData);
    }, 1000);
  });
  grid.gridService.renderGrid();
}

  onTaskAssignmentsGridCreation(grid) {
    this.taskAssignmentsGrid = grid;
  }

  onTaskAssignmentsGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskAssignmentsGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.taskAssignmentsGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.taskAssignmentsGrid.dataView.getItems();

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('checkmark')) {
        const currentValue = row[fieldName];
        if (currentValue !== undefined) {
          row[fieldName] = !row[fieldName];
        } else {
          row[fieldName] = true;
        }
        this.taskAssignmentsGrid.gridService.updateDataGridItem(row, false);
      }
    }
  }

  goToPage(n: number): void {
    this.page = n;
  }

  onNext(): void {
    this.page++;
  }

  onPrev(): void {
    this.page--;
  }

  totalPages(): number {
    return this.zettaUtilsSvc.getTotalPages(this.total, this.limit);
  }

  onTaskAssignments() {
    if (!this.viewTaskAssignments) {
      this.getTaskAssignmentsColDef();
      this.isTaskAssignmentsLoading = true;
      this.taskAssignmentsRowData = undefined;
      // TODO: Need Proper GET API for user with existing access.
      this.ProjectSvc.getProject(this.ProjectSvc.getObjectFromSession('userInfo').user_id,
        this.projectDetail.project_id).subscribe(responseList => {
          this.projectDetail = responseList;
          this.taskAssignmentsRowData = [... this.projectDetail.users];
          this.taskAssignmentsRowData.forEach(user => {
            user.id = Math.random();
          });
          this.isTaskAssignmentsLoading = false;
        }, err => {
          this.taskAssignmentsRowData = [];
          this.isTaskAssignmentsLoading = false;
        });
    } else {
      this.taskAssignmentsRowData = undefined;
    }
    this.viewTaskAssignments = !this.viewTaskAssignments;
  }

  toggleAccordian(event) {
    const target = event.target.target;
    if ($(target).hasClass('show')) {
      $(target).removeClass('show');
    } else {
      $(target).addClass('show');
    }
  }

  toggleSuggestAccordian(event, clusterId) {
    if (this.suggestedClusters['openCluster'] !== clusterId) {
      this.suggestedClusters['openCluster'] = clusterId;
    } else {
      this.suggestedClusters['openCluster'] = undefined;
    }
  }

  clearStage1Objects() {
    this.stage1Rows = [];
    this.stage1Columns = [];
    this.suggestedClusters = {};
    this.suggestedClusterkeys = [];
    this.suggestedFeedback = {};
    this.isSuggested = true;
    this.isSelectedClusterExpanded = false;
  }

  clearStage2Objects() {
    this.stage2Rows = [];
    this.stage2Columns = [];
    this.stage2FeedBack = [];
  }

  clearStage3Objects() {
    this.stage3Rows = [];
    this.stage3Columns = [];
    this.searchedClusterkeys = [];
    this.searchedClusters = '';
    this.isSearching = false;
    this.searchtext = '';
    $('#collapse1').removeClass('show');
    this.hasPrevious = true;
  }

  getSupervisedFeedback() {
    if (this.currentStage === 0) {
      this.clearStage1Objects();
      this.getStage1Data(this.page);
    } else if (this.currentStage === 1) {
      this.clearStage2Objects();
      this.getStage2Data(this.page, this.limit);
    } else if (this.currentStage === 2) {
      this.clearStage3Objects();
      this.getStage3Data();
    }
  }

  getStage1Data(page, limit = 100) {
    this.isStage1Loading = true;
    this.hasPrevious = true;
    this.isStage1SelectedClusterExpanded = false;
    this.stage1SelectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        this.selectedClusterId, this.projectDetail.project_id, page, limit).subscribe((data) => {
          this.stage1Rows = data['currentpage'];
          this.stage1Columns = data['columns'];
          this.stage1SelectedClusterTotal = data['totalrecords'];

          this.stage1Columns.forEach((col: any) => {
            col.displayname = col.display_name;
            if (col.displayname === 'Full Name') {
              col.minWidth = 300;
            }
            col.physicalname = col.physical_name;
            col.datatype = col.data_type;
            col.formatter = toolTipFormatter;
          });
          this.stage1Rows.forEach((element: any) => {
            element.id = Math.random();
            this.stage1selectedClusterName = element.zs_cluster_identifier;
            /**
             * Finish auto feedback only when there is a record in the cluster and zero suggestions
            */
            if (this.stage1SelectedClusterTotal === 1 && !this.isMatchFixingTasks) {
              this.stage1FeedBack.push({
                'zs_recid': element.zs_recid,
                'zs_feedbackid': AppGlobals.MATCH_FEEDBACK_STATUS,
              });
            }

            if (this.isMatchFixingTasks) {
              this.stage1FeedBack.push({
                'zs_recid': element.zs_recid,
                'zs_feedbackid': AppGlobals.MATCH_FEEDBACK_STATUS,
              });
            }
          });
          this.getSuggestedCluster(this.selectedClusterId, this.stage1selectedClusterName);
          this.isStage1Loading = false;
        }, err => {
          this.isStage1Loading = false;
          if (err && err.status === 404) {
            this.messageSvc.sendMessage({ message:  (err && err.error && err.error.message) ? err.error.message : 'Something went wrong', type: 'ERROR', hideboard: true });
          }
        });
  }

  getStage2Data(page, limit = 100) {
    this.isStage2Loading = true;
    this.isStage2SelectedClusterExpanded = false;
    this.stage2SelectedClusterTotal = 0;
    this.ProjectSvc.getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
        this.selectedClusterId, this.projectDetail.project_id, page, limit).subscribe((data) => {
          this.stage2Rows = data['currentpage'];
          this.stage2Columns = data['columns'];
          this.stage2Columns.forEach((col: any) => {
            col.displayname = col.display_name;
            if (col.displayname === 'Full Name') {
              col.minWidth = 300;
            }
            col.formatter = function (row, cell, value, columnDef, dataContext) {
              if (dataContext.is_anchor_record === true) {
                col.cssClasses = 'anchor-record';
                return `<div class="text-truncate w-100 p-0 anchor-record" title="${value || 'null'}"><span class="mx-2">${value || '<span class="blur">null</span>'}</span></div>`;
              } 
              else {
                return `<div class="text-truncate" title="${value || 'null'}">${value || '<span class="blur">null</span>'}</div>`;
              }
            }
            col.physicalname = col.physical_name;
            col.datatype = col.data_type;
          });
          this.stage2Columns.unshift(...this.getFeedbackColumns());
          this.stage2Rows.forEach((element: any) => {
            element.id = Math.random();
            this.stage2selectedClusterName = element.zs_cluster_identifier;
            if (this.stage2Rows.length === 1) {
              element.zs_feedbackid = AppGlobals.MATCH_FEEDBACK_STATUS;
            }
            // Capture initial Feedback
            if (element.zs_feedbackid !== undefined && element.zs_feedbackid !== null
              && element.zs_feedbackid >= 0 && element.zs_feedbackid !== '') {
              this.stage2FeedBack.push({
                'zs_recid': element.zs_recid,
                'zs_feedbackid': element.zs_feedbackid
              });
            }
          });
          this.stage2SelectedClusterTotal = data['totalrecords'];
          this.isStage2Loading = false;
        }, err => {
          this.isStage2Loading = false;
        });
  }

  getStage3Data(limit = 100) {
    this.isStage3Loading = true;
    this.isStage3SelectedClusterExpanded = false;
    this.stage3SelectedClusterTotal = 0;
    this.stage3EnableFinishBtn = true;
    this.stage3Rows = this.stage2Rows.filter(row => row['zs_feedbackid'] === AppGlobals.NO_MATCH_FEEDBACK_STATUS);
    if(this.customAddedClusters && this.customAddedClusters.length) {
      this.customAddedClusters.forEach(record => {
        if(record && record.rows) {
          record['rows'].forEach(row => {
            this.stage3Rows = this.stage3Rows.filter(rec => rec['zs_record_id'] !== row['zs_record_id']);
          });
        }
      });
    }
    if(this.addedClusters && this.addedClusters.length) {
      this.addedClusters.forEach(record => {
        if(record && record.rows) {
          record['rows'].forEach(row => {
            this.stage3Rows = this.stage3Rows.filter(rec => rec['zs_record_id'] !== row['zs_record_id']);
          });
        }
      });
    }
    this.stage3Columns = this.stage1Columns;
    this.stage3Columns.forEach((col: any) => {
      col.displayname = col.display_name;
      if (col.displayname === 'Full Name') {
        col.minWidth = 300;
      }
      col.physicalname = col.physical_name;
      col.datatype = col.data_type;
      col.formatter = toolTipFormatter;
    });

    this.stage3Rows.forEach((element: any) => {
      element.id = Math.random();
      this.stage3selectedClusterName = element.zs_cluster_identifier;
    });

    // TODO Get Proposed cluster
    this.getProposedClusters();
    this.noneOfAboveSearchedCluster = this.zettaUtilsSvc.generateUUID();
    this.proposedClusters = undefined;
    this.stage3SelectedClusterTotal = this.stage3Rows.length;
    this.isStage3Loading = false;
    this.isFinishDisabled();
  }

  // TODO
  getProposedClusters() {
    const proposedCluster = [];
    const proposedClusterkeys = [];
    // TO DO get logic for proposed Cluster & its Keys(clusterids)

    this.searchedClusterkeys = [...proposedClusterkeys];
    this.searchedClusters = [...proposedCluster];
  }

  isNextDisabled() {
    if (this.currentStage === 1) {
      let anchor_record_row = this.stage2Rows.filter(row => {
        if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
          return row;
        }
      });
      let stage2RowsNew = [];
      if (anchor_record_row.length > 0) {
        stage2RowsNew = this.stage2Rows;
      } else {
        stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
      }
      if (this.stage2Rows !== undefined && this.stage2Rows.length > 0 && this.stage2FeedBack.length === stage2RowsNew.length) {
        const noMatchRec = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
        if (noMatchRec === undefined) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else if (this.currentStage === 0 &&
      (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
      return true;
    } else {
      return false;
    }
  }

  enableNextButtonForMatchFixingTasks() {
    // Handle for all 3 stages. 

    // step1
    if (this.currentStage === 0) {

       if (this.showLoadingSpinnerWhileCapturingMatchFixingTasks) {
         return false;
       } else {
        return true; 
       }
      // Below if block is for showing/hiding next-button for fixing tasks when match record exists
        // if (this.suggestedClusterkeys && this.suggestedClusterkeys.length && this.suggestedFeedback 
        // && Object.keys(this.suggestedFeedback).length && Object.keys(this.suggestedFeedback).length === this.suggestedClusterkeys.length) {
        //   let clusterKeys = Object.keys(this.suggestedFeedback);
        //   let isMatchRecordPresent = clusterKeys.find((cKey) => {
        //     return this.suggestedFeedback[cKey] == "1";
        //   })
        // }           
        // return isMatchRecordPresent ? false : true;  
      // }
    }

    // step2
    if(this.currentStage === 1) {
      let anchor_record_row = this.stage2Rows.filter(row => {
        if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
          return row;
        }
      });
      let stage2RowsNew = [];
      if (anchor_record_row.length > 0) {
        stage2RowsNew = this.stage2Rows;
      } else {
        stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
      }

      if (this.stage2Rows !== undefined && this.stage2Rows.length > 0 && this.stage2FeedBack.length === stage2RowsNew.length) { 
        let isNoMatchRecordPresent = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
        return isNoMatchRecordPresent ? true: false;
      }
    }
    return false;
  }

  disableFinishButtonForMatchFixingTasks() {
    if (this.shouldDisableFeedback) {
      return true;
    }

    if (this.currentStage === 0) {

      if (this.showLoadingSpinnerWhileCapturingMatchFixingTasks) {
        return true;
      }
      if(this.stage1SelectedClusterTotal && this.stage1SelectedClusterTotal > 1) {
        return true;
      } else {
  //     if (this.suggestedClusterkeys && this.suggestedClusterkeys.length && this.suggestedFeedback
  //       && Object.keys(this.suggestedFeedback).length) {
  //       let clusterKeys = Object.keys(this.suggestedFeedback);
  //       let isMatchRecordPresent = clusterKeys.find((cKey) => {
  //         return this.suggestedFeedback[cKey] == "1";
  //       })
  //       return isMatchRecordPresent ? false : true;
      if (this.stage1SelectedClusterTotal === 1) {
        if (this.currentStage === 0 &&
          (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
          return true;
        } else {
          return false;
        }
      }
  }
}

    if(this.currentStage === 1) {
      let anchor_record_row = this.stage2Rows.filter(row => {
        if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
          return row;
        }
      });

      let stage2RowsNew = [];
      if (anchor_record_row.length > 0) {
        stage2RowsNew = this.stage2Rows;
      } else {
        stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
      }

      if (this.stage2Rows !== undefined && this.stage2Rows.length > 0 && this.stage2FeedBack.length === stage2RowsNew.length) { 
        let isNoMatchRecordPresent = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
        return isNoMatchRecordPresent ? true: false;
      }
    }

    if(this.currentStage === 2) {
      if (this.proposedClusters) {
        if (this.stage3EnableFinishBtn && this.stage3Rows && this.stage3Rows.length <= 0) {
          return false;
        }
      } else {
        if(this.stage3Rows && this.stage3Rows.length <= 0) {
          return false;
        }
      } 
    }
    return true;
  }

  onFinish() {
    if (this.currentStage === 1) {
      let stage2Data = [];
      this.stage2Rows.forEach(row=> {
      stage2Data.push({ 'feedback_type': 'not_matched_records','zs_feedbackid': row['zs_feedbackid'] ? row['zs_feedbackid'] : 1, 'zs_recid': row['zs_recid']});});
      this.updateFeedback(stage2Data, true);
    } else if (this.stage1SelectedClusterTotal === 1) {
      this.updateFeedback(this.stage1FeedBack, true);
    } else if (this.currentStage === 2) { 
      this.updateSearchedResultFeedback();
      // on finish we're calling the 2nd step feedback not on autosave
      this.updateFeedback(this.stage2FeedBack, false);
    }
    // if(this.currentStage !== 2) {
    //   this.closeModal();
    // }
  }

  finishMatchFixingTasks() {
    /**
     *  MATCH_FIXING_TASKS Merging/swiping data only happens on below condition. 
     *    - IF FOUR_EYES - ON -> When TASK status is 'Pending for approval'and moving to 'completed' state
     *    - IF FOUR_EYES - OFF ->When Task status is 'In Review' and moving to 'completed' state
     * 
     *  MERGING OF Tasks can be made possible by passing 'fix_task=true`'
     * 
     */
    if (this.currentStage === 1) {
      let stage2Data = [];
      this.stage2Rows.forEach(row=> {
      stage2Data.push({ 'feedback_type': 'not_matched_records','zs_feedbackid': row['zs_feedbackid'] ? row['zs_feedbackid'] : 1, 'zs_recid': row['zs_recid']});});
      this.updateMatchFixingFeedbackForStep1AndStep2(stage2Data, true);
    } else if (this.currentStage === 0) {
      this.updateMatchFixingFeedbackForStep1AndStep2(this.stage1FeedBack, true);
    } else if (this.currentStage === 2) { 
      this.finishStep3Task();
    }
  }


  finishStep3Task() {
    let requestPayload = [];
    if (this.addedClusters && this.addedClusters.length) {
      this.addedClusters.forEach((cluster) => {
        if (cluster) { 
          let rows = cluster.rows;
          if (rows && rows.length) {
            rows.forEach((record) => {
              requestPayload.push({
                "zs_recid": record.zs_recid,
                "zs_feedbackid": "0",
                "feedback_type": "not_matched_records",
                "zs_cluster_id_proposed": cluster.newlyProposedCluster
               })
            })
          }
         
        }
      })
    }

    if (this.customAddedClusters && this.customAddedClusters.length) {
      this.customAddedClusters.forEach((cluster) => {
          if (cluster) {
            let rows = cluster.rows;
            if (rows && rows.length) {
              rows.forEach((record) => {
                requestPayload.push({
                  "zs_recid": record.zs_recid,
                  "zs_feedbackid": "0",
                  "feedback_type": "not_matched_records",
                  "zs_cluster_id_proposed": cluster.newlyProposedCluster
                 })
              })
            }
            
          }
      })
    }
  
    this.ProjectSvc
      .updateStep3FeedbackOfMatchFixingTasks({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload
      },this.shouldMoveMatchFixingData)
      .subscribe((response) => {
        this.taskSaved();
        this.refreshMatchFixingTasks(true, this.shouldMoveMatchFixingData);
        this.removeCancel();
        this.showConfirmBox = false;
        this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
        this.isSelectedClusterExpanded = false;
        this.stage1FeedBack = [];
        this.addedClusters = [];
        this.customAddedClusters = [];
        $('#matcher-feedback-modal').modal('hide');
        $('#matcher-feedback-modal').addClass('d-none');
        // this.showConfirmBoxOnCancel();
        $('.modal-backdrop').remove();
      }, (err) => {
          // handle error  here
          this.messageSvc.sendMessage({ message: 'Something went wrong', type: 'ERROR', hideboard: true });
      }) 
     }

  // Update Feedback from stage 3
  updateSearchedResultFeedback() {
    const requestPayload = {};
    // Get Recid's from stage 3 rows
    let recids = '';
    for (let i = 0; i < (this.stage3Rows.length ? this.stage3Rows.length : (this.addedClusters.length ? this.addedClusters.length: this.customAddedClusters.length)); i++) {
      if (i + 1 < (this.stage3Rows.length ? this.stage3Rows.length : (this.addedClusters.length ? this.addedClusters.length: this.customAddedClusters.length))) {
        recids = recids + (this.stage3Rows.length ? this.stage3Rows : (this.addedClusters.length ? this.addedClusters: this.customAddedClusters))[i]['zs_recid'] + ',';
      } else {
        recids = recids + (this.stage3Rows.length ? this.stage3Rows : (this.addedClusters.length ? this.addedClusters: this.customAddedClusters))[i]['zs_recid'];
      }
    }
    requestPayload['zs_feedbackid'] = AppGlobals.NO_MATCH_FEEDBACK_STATUS;
    requestPayload['zs_recid'] = recids;
    requestPayload['feedback_type'] = 'not_matched_records';
    requestPayload['zs_cluster_id_proposed'] = this.proposedClusters;
    
    this.ProjectSvc
      .updateFeedback({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: requestPayload
      })
      .subscribe(resp => {
        this.taskSaved();
        this.refresh();
        this.removeCancel();
        this.showConfirmBox = false;
        this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
        this.isSelectedClusterExpanded = false;
        this.stage1FeedBack = [];
        this.addedClusters = [];
        this.customAddedClusters = [];
        $('#matcher-feedback-modal').modal('hide');
        $('#matcher-feedback-modal').addClass('d-none');
        // this.showConfirmBoxOnCancel();
        $('.modal-backdrop').remove();
      });
    }

    isFinishDisabled() {
      if (this.shouldDisableFeedback) {
        return true;
      }
      if (this.stage1SelectedClusterTotal === 1) {
        if (this.currentStage === 0 &&
          (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
          return true;
        } else {
          return false;
        }
      }
      if (this.currentStage === 1 && this.stage2Rows) {
        let anchor_record_row = this.stage2Rows.filter(row => {
          if (row['is_anchor_record'] && row['zs_feedbackid'] !== "") {
            return row;
          }
        });
        let stage2RowsNew = [];
        if (anchor_record_row.length > 0) {
          stage2RowsNew = this.stage2Rows;
        } else {
          stage2RowsNew = this.stage2Rows.filter(row => !row['is_anchor_record']);
        }
        
        // Add 2nd Stage Feedback's alre captured
        if (this.stage2FeedBack.length === stage2RowsNew.length && this.stage2FeedBack.length > 0) {
          const noMatchRec = this.stage2FeedBack.find(recs => parseInt(recs['zs_feedbackid'], 10) === 0);
          if (noMatchRec) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (this.currentStage === 2 && this.proposedClusters !== undefined) {
        if(this.stage3EnableFinishBtn && this.stage3Rows.length>0) {
          return true;
        } else {
          return false;
        }
      } else if(this.currentStage === 2 && !this.proposedClusters && this.stage3Rows && this.stage3Rows.length<1) {
        return false;
      }
      return true;
    }

  nextModal() {
    if (this.currentStage === 1) {
      this.currentStage = 2;
      this.isFinishDisabled();
      this.customAddedClusters.forEach(cluster => {cluster['openRecord'] = false});
      this.addedClusters.forEach(cluster => {cluster['openRecord'] = false});
    } else if (this.currentStage === 0) {
      this.updateSuggestedClusterFeedback();
      return;
    }

    this.isSelectedClusterExpanded = false;
    this.getSupervisedFeedback();
  }

  preModal() {
    if (this.currentStage === 0) {
      return;
    }
    if(this.currentStage==2) {
      if((this.addedClusters && this.addedClusters.length>0) || (this.customAddedClusters && this.customAddedClusters.length>0)) {
        this.showConfirmBox = true;
        this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
        this.msgText = "Continue?";
        this.onPreviousClicked = true;
      } else {
        this.isSelectedClusterExpanded = false;
        this.currentStage = this.currentStage - 1;
        this.getSupervisedFeedback();
      }
    } else {
    this.isSelectedClusterExpanded = false;
    this.currentStage = this.currentStage - 1;
    this.getSupervisedFeedback();
  }
}

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
    this.closeModal();
    $('#cancelBoxModal').modal('hide');
    $('#cancelBoxModal').addClass('d-none');
    // this.showConfirmBoxOnCancel();
    $('.modal-backdrop').remove();
    $('.modal-backdrop').removeClass('show');
    this.addedClusters = [];
    this.customAddedClusters = [];
  }

  closeModal() {
    if(this.currentStage == 2) {
      if((this.addedClusters && this.addedClusters.length>0) || (this.customAddedClusters && this.customAddedClusters.length>0)) {
        this.showConfirmBox = true;
        this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
        this.msgText = "Continue?";
      } else {
        this.showConfirmBox = false;
        this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
        this.isSelectedClusterExpanded = false;
        this.stage1FeedBack = [];
        this.addedClusters = [];
        this.onCLose.emit(true);
        this.customAddedClusters = [];
        $('#matcher-feedback-modal').modal('hide');
        $('#matcher-feedback-modal').addClass('d-none');
        // this.showConfirmBoxOnCancel();
        $('.modal-backdrop').remove();
      }
    } else {
      this.currentStage = 0;
      this.onCLose.emit(true);
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('matcher-feedback-modal');
    this.isSelectedClusterExpanded = false;
    $('#matcher-feedback-modal').modal('hide');
    $('#matcher-feedback-modal').addClass('d-none');
    this.showConfirmBoxOnCancel();
    $('.modal-backdrop').remove();
  }
  this.onPreviousClicked = false;
}

  onSearch() {
    // {{url}}/users/1/projects/2/entitiesresolved/feedback/search?key=jing ying s de rl de cv
    this.searchtext = $("#search").val();
    this.isSearching = true;
    this.searchedClusterkeys = [];
    this.ProjectSvc.searchEntityCluster({
        userId: this.loggedInUserDetails.user_id, clusterId: this.selectedClusterId,
        projectId: this.projectDetail.project_id, searchString: this.searchtext
    }, true).subscribe(resp => {
      this.searchedClusterkeys = Object.keys(resp.currentpage);
      this.searchedClusters = resp.currentpage;
      this.searchedColumns = resp.columns; 
      this.searchedColumns.forEach((col: any) => {
        col.displayname = col.display_name;
        if (col.displayname === 'Full Name') { 
          col.minWidth = 300;
        }
        col.physicalname = col.physical_name + '_o';
        col.datatype = col.data_type;
        col.formatter = toolTipFormatter;
      });
      this.searchedClusterkeys.forEach(e => {
        this.searchedClusters[e].rows = [];

        this.searchedClusters[e].forEach(element => {
          element.id = Math.random();
          this.searchedClusters[e].rows.push(element);
        });
      });
      this.isSearching = false;
      this.proposedClusters = undefined;
    }, err => {
      this.isSearching = false;
    });
  }

  onSearchedClusterPlus(clusterId, searchedClusters, clusterName='', existingCluster, searchedClusterkeys=[]) {
    this.checkForClusterSelectedTypes();
    if (this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && !this.existingClustersSelected && !this.ownClusterSelected) {
      this.proposedClusters = clusterId;
      let newRows = [];
      if (searchedClusters) {
        let combinedRows = this.selectedOrphanRecords.concat(searchedClusterkeys);
        this.addedClusters.push({ ...this.selectedOrphanRecords[0], rows: combinedRows, clusterName: clusterName, openRecord: false });
        // this.addedClusters.push(this.searchedClusters[clusterId]);
        this.newPayload = [];
        this.addedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              this.newPayload.push({
                'zs_recid': row.zs_recid,
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              });
            });
            // projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
            // comment and use below line of code to 
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.newPayload })
              .subscribe(resp => {
                if(resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
              this.stage3Rows.splice(ele, 1);
              this.isFinishDisabled();
            });
            if (this.orphanGrid) {
              this.orphanGrid.gridService.setSelectedRows([]);
              this.orphanGrid.dataView.refresh();
              this.orphanGrid.dataView.setItems(this.stage3Rows);
            }
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
                }
              });
          }
        });
      } else {
        let addedRecs = [];
        let selectedRecIds = [];
        this.customAddedClusters.push({ ...this.selectedOrphanRecords[0], rows: this.selectedOrphanRecords });
        this.customAddedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              if(row && row.zs_recid) {
                selectedRecIds.push(row.zs_recid);
              }
            });

            let newPayload = {
              'zs_recid': selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
              .subscribe(resp => {
                if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
                    this.stage3Rows.splice(ele, 1);
                      this.isFinishDisabled();
                      });            
                    if (this.orphanGrid) {
                      this.orphanGrid.gridService.setSelectedRows([]);
                      this.orphanGrid.dataView.refresh();
                      this.orphanGrid.dataView.setItems(this.stage3Rows);
                      }
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                    this.isFinishDisabled();
                    this.selectedOrphanRecords = [];
                    this.selectedOrphanIndexes = [];
                  }
              });
          }
        })
      }
      this.updateSuggestedClusterFeedback();
    } else {
      this.checkForClusterSelectedTypes();
      if(this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && (this.existingClustersSelected || this.ownClusterSelected)) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        let existingInstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  searchedClusterinstanceRows.push(row);
                  this.addedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.addedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                searchedClusterinstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
            }
          });
          this.customAddedCluserInstance.forEach((instance, inde) => {
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                existingInstanceRows.push(row);
                this.customAddedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              existingInstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
            }
          });
          this.selectedOrphanRecords.forEach(rec => {
            selectedRecIds.push(rec.zs_recid);
          });
          let payload = {
              'zs_recid':selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };
            let selectedRecs = this.selectedOrphanRecords.concat(existingInstanceRows).concat(searchedClusterinstanceRows);
            this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload })
            .subscribe(resp => {
              if (resp) {
                this.taskSaved();
                this.selectedOrphanIndexes.reverse().forEach(ele => {
                  this.stage3Rows.splice(ele, 1);
                    this.isFinishDisabled();
                    });            
                  if (this.orphanGrid) {
                    this.orphanGrid.gridService.setSelectedRows([]);
                    this.orphanGrid.dataView.refresh();
                    this.orphanGrid.dataView.setItems(this.stage3Rows);
                    }
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
                  if(existingCluster) {
                    let combinedRows = selectedRecs.concat(searchedClusterkeys);
                    this.addedClusters.push({...searchedClusterinstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
                  } else {
                    this.customAddedClusters.push({...existingInstanceRows[0], rows: selectedRecs, clusterName, openRecord: false});
                  }
                }
            });
            return;
      }else if(this.existingClustersSelected && this.ownClusterSelected) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  searchedClusterinstanceRows.push(row);
                  this.addedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.addedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                searchedClusterinstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
            }
          });
            this.customAddedCluserInstance.forEach((instance, inde) => {
              let existingInstanceRows = [];
              if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
                instance['selectedRowIdexes'].reverse().forEach(  ind => {
                  let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                  existingInstanceRows.push(row);
                  this.customAddedClusters[inde]['rows'].splice(ind, 1);
                  if(instance) {
                    instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                    instance.gridService.setSelectedRows([]);
                    instance.dataView.refresh();
                  }
                });
                existingInstanceRows.forEach(row => {
                  selectedRecIds.push(row.zs_recid);
                });
                if(existingCluster) {
                  let newPayload = {'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0','feedback_type': "not_matched_records",'zs_cluster_id_proposed': clusterId};
                  this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                  .subscribe(resp => {
                    if (resp) {
                      this.taskSaved();
                      this.selectedOrphanIndexes.forEach(ele => {
                      this.isFinishDisabled();
                      });
                      this.proposedClusters = {};
                      this.stage3EnableFinishBtn = true;
                      this.selectedOrphanIndexes = [];
                      this.selectedOrphanRecords = [];
                      this.searchedClusterkeys = [];
                      this.searchedClusters = '';
                      this.isSearching = false;
                      this.searchtext = '';
                    }
                  });                  
                } else {
                  let newPayload = {
                    'zs_recid': selectedRecIds.toString(),
                    'zs_feedbackid': '0',
                    'feedback_type': "not_matched_records",
                    'zs_cluster_id_proposed': clusterId
                  };
                this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                .subscribe(resp => {
                  if (resp) {
                    this.taskSaved();
                    this.selectedOrphanIndexes.forEach(ele => {
                    this.isFinishDisabled();
                    });
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.selectedOrphanIndexes = [];
                    this.selectedOrphanRecords = [];
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                  }
                });
              }
              if(existingCluster) {
                let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
                let allrows = existingInstanceRows.concat(combinedRows);
                this.addedClusters.push({...searchedClusterinstanceRows[0], rows: allrows, clusterName , openRecord: false});
              } else {
                let combinedRows = searchedClusterinstanceRows.concat(existingInstanceRows);
                this.customAddedClusters.push({...existingInstanceRows[0], rows: combinedRows, clusterName, openRecord: false});
              }
            }});
      } else {
        this.addedCluserInstance.forEach((instance, inde) => {
        let searchedClusterinstanceRows = [];
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                searchedClusterinstanceRows.push(row);
                this.addedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.addedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              let selectedRecIds = [];
              searchedClusterinstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
          let newPayload = {'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0','feedback_type': "not_matched_records",'zs_cluster_id_proposed': clusterId};
          this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
          .subscribe(resp => {
            if (resp) {
              this.taskSaved();
              this.selectedOrphanIndexes.forEach(ele => {
              this.isFinishDisabled();
              });
              this.proposedClusters = {};
              this.stage3EnableFinishBtn = true;
              this.selectedOrphanIndexes = [];
              this.selectedOrphanRecords = [];
              this.searchedClusterkeys = [];
              this.searchedClusters = '';
              this.isSearching = false;
              this.searchtext = '';
            }
          });
          if(existingCluster) {
            let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
            this.addedClusters.push({...searchedClusterinstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
          } else {
            this.customAddedClusters.push({...searchedClusterinstanceRows[0], rows: searchedClusterinstanceRows, clusterName, openRecord: false});
            }
          }
        });
          this.customAddedCluserInstance.forEach((instance, inde) => {
            let existingInstanceRows = [];
            if(instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
              instance['selectedRowIdexes'].reverse().forEach(  ind => {
                let row = {...instance.gridService.getDataItemByRowIndex(ind), id: Math.random()};
                existingInstanceRows.push(row);
                this.customAddedClusters[inde]['rows'].splice(ind, 1);
                if(instance) {
                  instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                  instance.gridService.setSelectedRows([]);
                  instance.dataView.refresh();
                }
              });
              let selectedRecIds = [];
              existingInstanceRows.forEach(row => {
                selectedRecIds.push(row.zs_recid);
              });
    
              let newPayload = {
                'zs_recid': selectedRecIds.toString(),
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              };
    
              this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
              .subscribe(resp => {
                if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.forEach(ele => {
                  this.isFinishDisabled();
                  });
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.selectedOrphanIndexes = [];
                  this.selectedOrphanRecords = [];
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                }
              });
              if(existingCluster) {
                let combinedRows = existingInstanceRows.concat(searchedClusterkeys);
                this.addedClusters.push({...existingInstanceRows[0], rows: combinedRows, clusterName , openRecord: false});
              } else {
                this.customAddedClusters.push({...existingInstanceRows[0], rows: existingInstanceRows, clusterName, openRecord: false});
              }
            }
          });
      }
      }
  }

  onSearchedClusterPlusForMatchFixing(clusterId, searchedClusters, clusterName = '', existingCluster, searchedClusterkeys = []) {
    this.checkForClusterSelectedTypes();
    if (this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && !this.existingClustersSelected && !this.ownClusterSelected) {
      this.proposedClusters = clusterId;
      let newRows = [];
      if (searchedClusters) {
        let combinedRows = this.selectedOrphanRecords.concat(searchedClusterkeys);
        this.addedClusters.push({ ...this.selectedOrphanRecords[0], rows: combinedRows, clusterName: clusterName, openRecord: false , newlyProposedCluster: clusterId});
        // this.addedClusters.push(this.searchedClusters[clusterId]);
        this.newPayload = [];
        this.addedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              this.newPayload.push({
                'zs_recid': row.zs_recid,
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              });
            });
            // projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
            // comment and use below line of code to 
           // this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.newPayload })
             // .subscribe(resp => {
               // if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
                    this.stage3Rows.splice(ele, 1);
                    this.isFinishDisabled();
                  });
                  if (this.orphanGrid) {
                    this.orphanGrid.gridService.setSelectedRows([]);
                    this.orphanGrid.dataView.refresh();
                    this.orphanGrid.dataView.setItems(this.stage3Rows);
                  }
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
               // }
              //});
          }
        });
      } else {
        let addedRecs = [];
        let selectedRecIds = [];
        this.customAddedClusters.push({ ...this.selectedOrphanRecords[0], rows: this.selectedOrphanRecords, newlyProposedCluster: this.zettaUtilsSvc.generateUUID() });
        this.customAddedClusters.forEach((record, ind) => {
          if (record.rows && record.rows.length) {
            record.rows.forEach(row => {
              if (row && row.zs_recid) {
                selectedRecIds.push(row.zs_recid);
              }
            });

            let newPayload = {
              'zs_recid': selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };
          //  this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
            //  .subscribe(resp => {
               // if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.reverse().forEach(ele => {
                    this.stage3Rows.splice(ele, 1);
                    this.isFinishDisabled();
                  });
                  if (this.orphanGrid) {
                    this.orphanGrid.gridService.setSelectedRows([]);
                    this.orphanGrid.dataView.refresh();
                    this.orphanGrid.dataView.setItems(this.stage3Rows);
                  }
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                  this.isFinishDisabled();
                  this.selectedOrphanRecords = [];
                  this.selectedOrphanIndexes = [];
               // }
             // });
          }
        })
      }
      this.updateSuggestedClusterFeedback();
    } else {
      this.checkForClusterSelectedTypes();
      if (this.selectedOrphanRecords && this.selectedOrphanRecords.length > 0 && (this.existingClustersSelected || this.ownClusterSelected)) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        let existingInstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              searchedClusterinstanceRows.push(row);
              this.addedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.addedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            searchedClusterinstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });
          }
        });
        this.customAddedCluserInstance.forEach((instance, inde) => {
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              existingInstanceRows.push(row);
              this.customAddedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            existingInstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });
          }
        });
        this.selectedOrphanRecords.forEach(rec => {
          selectedRecIds.push(rec.zs_recid);
        });
        let payload = {
          'zs_recid': selectedRecIds.toString(),
          'zs_feedbackid': '0',
          'feedback_type': "not_matched_records",
          'zs_cluster_id_proposed': clusterId
        };
        let selectedRecs = this.selectedOrphanRecords.concat(existingInstanceRows).concat(searchedClusterinstanceRows);
       // this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload })
         // .subscribe(resp => {
           // if (resp) {
              this.taskSaved();
              this.selectedOrphanIndexes.reverse().forEach(ele => {
                this.stage3Rows.splice(ele, 1);
                this.isFinishDisabled();
              });
              if (this.orphanGrid) {
                this.orphanGrid.gridService.setSelectedRows([]);
                this.orphanGrid.dataView.refresh();
                this.orphanGrid.dataView.setItems(this.stage3Rows);
              }
              this.proposedClusters = {};
              this.stage3EnableFinishBtn = true;
              this.searchedClusterkeys = [];
              this.searchedClusters = '';
              this.isSearching = false;
              this.searchtext = '';
              this.isFinishDisabled();
              this.selectedOrphanRecords = [];
              this.selectedOrphanIndexes = [];
              if (existingCluster) {
                let combinedRows = selectedRecs.concat(searchedClusterkeys);
                this.addedClusters.push({ ...searchedClusterinstanceRows[0], rows: combinedRows, clusterName, openRecord: false });
              } else {
                this.customAddedClusters.push({ ...existingInstanceRows[0], rows: selectedRecs, clusterName, openRecord: false });
              }
            //}
          //});
        return;
      } else if (this.existingClustersSelected && this.ownClusterSelected) {
        let selectedRecIds = [];
        let searchedClusterinstanceRows = [];
        this.addedCluserInstance.forEach((instance, inde) => {
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              searchedClusterinstanceRows.push(row);
              this.addedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.addedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            searchedClusterinstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });
          }
        });
        this.customAddedCluserInstance.forEach((instance, inde) => {
          let existingInstanceRows = [];
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              existingInstanceRows.push(row);
              this.customAddedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            existingInstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });
            if (existingCluster) {
              let newPayload = { 'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0', 'feedback_type': "not_matched_records", 'zs_cluster_id_proposed': clusterId };
              //this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                //.subscribe(resp => {
                  //if (resp) {
                    this.taskSaved();
                    this.selectedOrphanIndexes.forEach(ele => {
                      this.isFinishDisabled();
                    });
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.selectedOrphanIndexes = [];
                    this.selectedOrphanRecords = [];
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                  //}
                //});
            } else {
              let newPayload = {
                'zs_recid': selectedRecIds.toString(),
                'zs_feedbackid': '0',
                'feedback_type': "not_matched_records",
                'zs_cluster_id_proposed': clusterId
              };
              //this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
                //.subscribe(resp => {
                  //if (resp) {
                    this.taskSaved();
                    this.selectedOrphanIndexes.forEach(ele => {
                      this.isFinishDisabled();
                    });
                    this.proposedClusters = {};
                    this.stage3EnableFinishBtn = true;
                    this.selectedOrphanIndexes = [];
                    this.selectedOrphanRecords = [];
                    this.searchedClusterkeys = [];
                    this.searchedClusters = '';
                    this.isSearching = false;
                    this.searchtext = '';
                  //}
                //});
            }
            if (existingCluster) {
              let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
              let allrows = existingInstanceRows.concat(combinedRows);
              this.addedClusters.push({ ...searchedClusterinstanceRows[0], rows: allrows, clusterName, openRecord: false });
            } else {
              let combinedRows = searchedClusterinstanceRows.concat(existingInstanceRows);
              this.customAddedClusters.push({ ...existingInstanceRows[0], rows: combinedRows, clusterName, openRecord: false });
            }
          }
        });
      } else {
        this.addedCluserInstance.forEach((instance, inde) => {
          let searchedClusterinstanceRows = [];
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              searchedClusterinstanceRows.push(row);
              this.addedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.addedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            let selectedRecIds = [];
            searchedClusterinstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });
            let newPayload = { 'zs_recid': selectedRecIds.toString(), 'zs_feedbackid': '0', 'feedback_type': "not_matched_records", 'zs_cluster_id_proposed': clusterId };
            //this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
             // .subscribe(resp => {
                //if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.forEach(ele => {
                    this.isFinishDisabled();
                  });
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.selectedOrphanIndexes = [];
                  this.selectedOrphanRecords = [];
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                //}
             // });
            if (existingCluster) {
              let combinedRows = searchedClusterinstanceRows.concat(searchedClusterkeys);
              this.addedClusters.push({ ...searchedClusterinstanceRows[0], rows: combinedRows, clusterName, openRecord: false });
            } else {
              this.customAddedClusters.push({ ...searchedClusterinstanceRows[0], rows: searchedClusterinstanceRows, clusterName, openRecord: false });
            }
          }
        });
        this.customAddedCluserInstance.forEach((instance, inde) => {
          let existingInstanceRows = [];
          if (instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length) {
            instance['selectedRowIdexes'].reverse().forEach(ind => {
              let row = { ...instance.gridService.getDataItemByRowIndex(ind), id: Math.random() };
              existingInstanceRows.push(row);
              this.customAddedClusters[inde]['rows'].splice(ind, 1);
              if (instance) {
                instance.dataView.setItems(this.customAddedClusters[inde]['rows']);
                instance.gridService.setSelectedRows([]);
                instance.dataView.refresh();
              }
            });
            let selectedRecIds = [];
            existingInstanceRows.forEach(row => {
              selectedRecIds.push(row.zs_recid);
            });

            let newPayload = {
              'zs_recid': selectedRecIds.toString(),
              'zs_feedbackid': '0',
              'feedback_type': "not_matched_records",
              'zs_cluster_id_proposed': clusterId
            };

            //this.ProjectSvc.updateResoveProjectFeedback({ userId: this.loggedInUserDetails.user_id, projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: newPayload })
              //.subscribe(resp => {
               // if (resp) {
                  this.taskSaved();
                  this.selectedOrphanIndexes.forEach(ele => {
                    this.isFinishDisabled();
                  });
                  this.proposedClusters = {};
                  this.stage3EnableFinishBtn = true;
                  this.selectedOrphanIndexes = [];
                  this.selectedOrphanRecords = [];
                  this.searchedClusterkeys = [];
                  this.searchedClusters = '';
                  this.isSearching = false;
                  this.searchtext = '';
                //}
              //});
            if (existingCluster) {
              let combinedRows = existingInstanceRows.concat(searchedClusterkeys);
              this.addedClusters.push({ ...existingInstanceRows[0], rows: combinedRows, clusterName, openRecord: false });
            } else {
              this.customAddedClusters.push({ ...existingInstanceRows[0], rows: existingInstanceRows, clusterName, openRecord: false });
            }
          }
        });
      }
    }
  }

  onSearchedClusterMinus(clusterId) {
    this.proposedClusters = undefined;
  }

  toggleSearchedAccordian(event, clusterId) {
    if (this.searchedClusters['openCluster'] !== clusterId) {
      this.searchedClusters['openCluster'] = clusterId;
    } else {
      this.searchedClusters['openCluster'] = undefined;
    }
  }

  toggleAddedAccordian(event, clusterId, ind) {
    const target = event.target.target;
    if (this.addedClusters['openCluster'] !== clusterId) {
      this.addedClusters['openCluster'] = clusterId;
    } else {
      this.addedClusters['openCluster'] = undefined;
    }
    this.addedClusters[ind]['openRecord'] = true;
  }
  customAddedClustersToggle(eve, clusId, ind) {
    const target = eve.target.target;
    if (this.customAddedClusters['openCluster'] !== clusId) {
      this.customAddedClusters['openCluster'] = clusId;
    } else {
      this.customAddedClusters['openCluster'] = undefined;
    }
    this.customAddedClusters[ind]['openRecord'] = true;
  }


  autoUpdateSuggestedClusterFeedback() {
    const payload = {};
    payload['zs_feedbackid'] = AppGlobals.SUGGESTED_CLUSTER_FEEDBACK_STATUS;
    let clusterIds = '';
    let feedbackIds = '';
    const keys = Object.keys(this.suggestedFeedback);
    for (let i = 0; i < keys.length; i++) {
      if (i + 1 < keys.length) {
        clusterIds = clusterIds + keys[i] + ',';
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]] + ',';
      } else {
        clusterIds = clusterIds + keys[i];
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]];
      }
    }
    payload['zs_feedback_closest_cluster_ids'] = clusterIds;
    payload['zs_feedback_closest_cluster_ids_feedbackids'] = feedbackIds;
    let self = this;
    this.ProjectSvc.updateFeedback({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
      }).subscribe(resp => {
        if (self.isMatchFixingTasks) {
          self.showLoadingSpinnerWhileCapturingMatchFixingTasks = false;
        }

        if(resp) {
          self.taskSaved();
        }
        
    }, (err) => {
        if (self.isMatchFixingTasks) {
          self.showLoadingSpinnerWhileCapturingMatchFixingTasks = false;
        }
    });
  }

  updateSuggestedClusterFeedback() {
    const payload = {};
    payload['zs_feedbackid'] = AppGlobals.SUGGESTED_CLUSTER_FEEDBACK_STATUS;
    let clusterIds = '';
    let feedbackIds = '';
    const keys = Object.keys(this.suggestedFeedback);

    for (let i = 0; i < keys.length; i++) {
      if (i + 1 < keys.length) {
        clusterIds = clusterIds + keys[i] + ',';
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]] + ',';
      } else {
        clusterIds = clusterIds + keys[i];
        feedbackIds = feedbackIds + this.suggestedFeedback[keys[i]];
      }
    }

    payload['zs_feedback_closest_cluster_ids'] = clusterIds;
    payload['zs_feedback_closest_cluster_ids_feedbackids'] = feedbackIds;
    if(this.currentStage<=1) {
    this.ProjectSvc
      .updateFeedback({
        userId: this.loggedInUserDetails.user_id,
        projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: payload
        // comment and use below line of code to 
        // projectId: this.projectDetail.project_id, clusterId: this.selectedClusterId, payload: this.newPayload
      })
      .subscribe(resp => {
        this.taskSaved();
        this.currentStage = 1;
        this.suggestedFeedback = {};
        this.getSupervisedFeedback();
      });
  }
}

  captureSuggestedFeedback(cId, feedback) {
    if (this.isMatchFixingTasks) {
      this.showLoadingSpinnerWhileCapturingMatchFixingTasks = true;
    }
    this.suggestedFeedback[cId] = feedback;
    this.autoUpdateSuggestedClusterFeedback();
  }

  refresh() {
    this.onComplete.emit(true);
    $('.modal-backdrop').remove();
  }

  refreshMatchFixingTasks(shouldRunDeltaMerger = false, shouldMoveMatchFixingData) {
    this.onCompleteMatchFixingTasks.emit({ completed: true, shouldRunDeltaMerger: shouldMoveMatchFixingData && shouldRunDeltaMerger ? true : false});
    $('.modal-backdrop').remove();
  }

  isClusterChecked(currentFeedback, feedbacktoBe) {
    if (currentFeedback === feedbacktoBe) {
      return true;
    } else {
      return false;
    }
  }

  getSuggestedClusterStyle() {
    if ($('#collapse1').hasClass('show')) {
      return 'suggested-cluster expanded';
    } else {
      return 'suggested-cluster collapsed';
    }
  }

  taskSaved() {
    $('#task-saved').removeClass('d-none');
    setTimeout(function () {
      $('#task-saved').addClass('d-none');
    }, 5000);
  }

  /** Stage = -1: Cluster has one record and 0 Suggested cluster: DO NOT SHOW TRAIN MODAL
   *  Stage = 0: When we have suggested cluster: DISPLAY TRAIN MODAL STEP 1
   *  Stage = 1: When selected cluster has > 1 records and 0 suggested clusters THEN skip step 1 and handle previous button.
   * */
  handleModal() {
    this.hasPrevious = true;
    if ((this.suggestedClusterkeys.length === 0 || this.suggestedClusterkeys === undefined) && this.stage1SelectedClusterTotal <= 1) {
      // Do not display modal
      return -1;
    } else if ((this.suggestedClusterkeys.length === 0 || this.suggestedClusterkeys === undefined) && this.stage1SelectedClusterTotal > 1) {
      // Skip step 1
      return 1;
    } else {
      return 0;
    }
  }

  onNextLeft(): void {
    this.pageLeft++;
  }

  onLeftGridScroll(parentClass) {
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNextLeft();
    }
  }

  onLoadModal(isMatchFixingTasks) {
    if (isMatchFixingTasks) {
      $('#matcher-feedback-modal').removeClass('d-none');
      return;
    }

    if (!isMatchFixingTasks) {
      if (this.handleModal() === -1) {
        this.closeModal();
        return;
      } else if (this.handleModal() === 1) {
        $('#matcher-feedback-modal').removeClass('d-none');
        this.currentStage = 1;
        this.hasPrevious = false;
        this.getSupervisedFeedback();
        return;
      } else if (this.handleModal() === 0) {
        $('#matcher-feedback-modal').removeClass('d-none');
      }
    }
  }

  counter() {
    $("#search").val().length >= 3 ? this.searchtext = $("#search").val() : this.searchtext = '';
  } 

  showConfirmBoxOnCancel() {
    if(this.currentStage == 2) {
      if(this.addedClusters && this.addedClusters.length>0 && this.customAddedClusters && this.customAddedClusters.length>0) {
        this.showConfirmBox = true;
      } else {
        this.showConfirmBox = false;
      }
      this.msgHeader = "Your Changes are not saved Yet, this action will remove all Changes.";
      this.msgText = "Continue?";
    } else if(this.currentStage < 2) {
    $('#matcher-feedback-modal').modal('hide');
    $('.modal').modal('hide');
    this.suggestedFeedback = {};
    this.customAddedClusters = [];
    this.addedClusters = [];
    this.stage1FeedBack = [];
  }
  }

  clearOrphanClusters() {
    this.searchedClusterkeys = [];
    this.searchedClusters = '';
    this.isSearching = false;
    this.searchtext = '';
    this.isSelectedClusterExpanded = false;
  }

  checkForClusterSelectedTypes() {
    this.existingClustersSelected = this.addedCluserInstance.some((instance) => instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length)
    this.ownClusterSelected = this.customAddedCluserInstance.some(instance => instance['selectedRowIdexes'] && instance['selectedRowIdexes'].length)
  }

  toggleSaveChangesModal() {
    $('#cancelBoxModal').modal('show');
    $('#cancelBoxModal').addClass('show');
  }
  onConfirmNavigate(event) {
    if(!this.onPreviousClicked) {
      $('#matcher-feedback-modal').modal('hide');
      $('.modal').modal('hide');
      this.showConfirmBox = false;
      this.addedClusters = [];
      this.customAddedClusters = [];
      this.onCLose.emit(true);
    } else {
      this.isSelectedClusterExpanded = false;
      this.currentStage = this.currentStage - 1;
      this.getSupervisedFeedback();
      this.showConfirmBox = false;
    }
  }
  clearText(event) {
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  checkForNextDisabled() {
    if (this.currentStage === 0 &&
      (this.isSuggested || Object.keys(this.suggestedFeedback).length !== this.suggestedClusterkeys.length)) {
      return true;
    } else {
      return false;
    }
  }
}
