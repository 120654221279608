import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import $ from 'jquery';
import * as _ from 'lodash';
import { FormBuilder } from '@angular/forms';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../common/components/message/message.service';
import { ContentService } from '../../content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Attribute } from '../entity.model';
import { environment } from 'src/environments/environment';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { DeleteIconRendererComponent } from '../../../../common/shared/cell-renderer/delete-icon-formatter/delete-icon-formatter.component';
import { CustomCellRenderer } from '../../../../common/shared/cell-renderer/custom-cell-render-formatter/custom-cell-render-formatter-component';
import { CustomDropdownCellRenderer } from 'src/app/common/shared/cell-renderer/custom-cell-dropdown-formatter/custom-cell-dropdown-formatter-component';
import { CustomCellEditModeFormatter } from 'src/app/common/shared/cell-renderer/custom-cell-render-formatter/custom-cell-editmode-formatter-component';
 
let grdLenght;
const customDropDownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
    let placeholder = '';  
    if(columnDef.field === 'data_type') { placeholder = 'Select Data Type' };
    if(columnDef.field === 'scrubber_name') { placeholder = 'Select Scrubber Type' };
    if(columnDef.field === 'matcher_type_name') { placeholder = 'Select Matcher Type' };
    if( dataContext.action !== 'delete'){
        return `<div class='selectInput w-100'>
                    <select id='attribute-${columnDef.field}-${row}' class="ctmInputBox textSelectBox" style="color: #444; background-color:#F8F8F8" disabled>
                        <option value="">${placeholder}</option>
                    </select>
                </div>`;
    } else {
        return `<div class='text-truncate' title='${value}'>${value}</div>`
    }
}

const customAttributeRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
    var hideElement = 'disabled';
    if( dataContext.action !== 'delete'){
            if(columnDef.field === 'is_visible_output' || columnDef.field === 'is_visible_input'){
                return `<div class="w-100 text-center pt-1">
                            <input id='attribute-${columnDef.field}-${row}' class="entity-checkbox" type="checkbox" ${hideElement}>
                        </div>`;
            } else if(columnDef.field === 'sort_order' || columnDef.field === 'attribute_id'){
                return ``;
            } else {
                return `<input id='attribute-${columnDef.field}-${row}' class='ctmInputBox textInpuBox' type='text' placeholder='${columnDef.params.placeholder}' tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value='' ${hideElement}></input>`;
            }
    }else{
            if(columnDef.field === 'is_visible_output' ){ 
                    return value === true ? `<div class="w-100 text-center c-green">Yes</div>` : `<div class="w-100 text-center c-red">No</div>`
            } else if(columnDef.field === 'is_visible_input') {
                    return value === true ? `<div class="w-100 text-center c-blue"><i class="fal fa-copy"></i></div>` :  ``
            } else if(columnDef.field === 'sort_order') { 
                var arrow = '';
                    if(value===1){
                        arrow=`<div class="w-100 text-center text-bold">
                        <i class="fa fa-arrow-up notActiveRedFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                        <i class="fa fa-arrow-down redFont" style="cursor:pointer;" aria-hidden="true"></i>
                    </div>`
                    }else if(row === grdLenght){
                        arrow=`<div class="w-100 text-center text-bold">
                        <i class="fa fa-arrow-up greenFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                        <i class="fa fa-arrow-down notActiveRedFont" style="cursor:pointer;" aria-hidden="true"></i>
                    </div>`
                    }else{
                        arrow=`<div class="w-100 text-center text-bold">
                        <i class="fa fa-arrow-up greenFont" style="cursor:pointer;" aria-hidden="true"></i>&nbsp;
                        <i class="fa fa-arrow-down redFont" style="cursor:pointer;" aria-hidden="true"></i>
                    </div>`
                    }
                    return arrow;
            } else if(columnDef.field === 'attribute_id') {
                return `<div class='w-100 text-truncate text-right pr-1'>${value}</div>`
            }  else if(columnDef.field === 'logical_name' || columnDef.field === 'physical_name' || columnDef.field === 'description') {
                return `<div class='w-100 text-truncate pr-2 w-100'>${value}</div>`
            } else {
                return `<div class='text-truncate' title='${value}'>${value}</div>`
            }
    }
};
    
@Component({
  selector: 'zetta-entity-model-view',
  templateUrl: './entity-model-view.component.html',
  styleUrls: ['./entity-model-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EntityModelViewComponent implements OnInit, OnDestroy { 
    GridApi: any;
    columnDefs: ColDef[] = [];
    defaultColDef: ColDef = {}
    fsGridOptions: GridOptions;
    agGrid: AgGridAngular;
    grid: AngularGridInstance;
    columnApi: any;
    gridOptions: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    pageLeft = 1;
    limitLeft = 20;
    hasScrolled = false;
    noData = false;
    loggedInUserDetails: [];
    isDataReady = false;
    loadEntity = false;
    entity: any = [];
    showBreadcrumb = true;
    entityDataSource: any = [];
    total = 0;
    attributeTableColDef: any;
    attributeTableRows: any = [];    
    responseList: any = [];
    gridRef: AngularGridInstance;
    isEditMode = false;
    addAttribute: Attribute = new Attribute();
    attributeDataTypeslistDropDown: any = [];
    attributeScrubberTypeslistDropDown: any = [];
    attributeMatcherTypeslistDropDown: any = [];
    login_user = JSON.parse(sessionStorage.userInfo).entitlements;
    is_visible_edit = false;
    is_enable_edit = false;
    dataQualityCount: number = 0;
    showmodelpopup = false;
    catalog_id: number;
    is_catalog_admin = false;
    dateConcept = [];
    numberConcept = [];
    showLoadingSpinner: boolean = false;
    constructor(private contentSvc: ContentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService, 
        private classifyContentSvc: ZsClContentService) {          
         }
         
    // attributeGrid(grid: AngularGridInstance) {
    //     this.gridRef = grid;
    //     this.onBeforeEditCell.subscribe(function (e, args) {
    //         if (args.column.field === 'data_type' || args.column.field === 'scrubber_name' || args.column.field === 'matcher_type_name') {             //  === 'group_id'  ------------ OJO ------------
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     });

    // }
  

    onDataSorted(entityDataObj) {

        let entityData = entityDataObj.data;
        let headerIndex = entityData.findIndex((entity) => {
            return entity.action === 'add';
        })
        let headerData;

        if (headerIndex > -1) {
            headerData = entityData[headerIndex];
            entityData.splice(headerIndex, 1);
        }

        entityData = this.sortEntityData(entityData, entityDataObj.sortBy,entityDataObj.sortType);

        if (entityDataObj.showHeader) {
            if (headerData) {
                entityData.unshift(headerData);
            } else {
                entityData.unshift(this.getAddAttributeRow());
            }
        }

        this.gridRef.dataView.setItems(entityData);
        this.gridRef.gridService.setSelectedRows([]);
    }

    sortEntityData(entityData, column, sortType) {
        let sortByAsc = (a,b) => {
            if (a[column] && b[column] && (a[column].toLowerCase() < b[column].toLowerCase())) {
                return -1;
            }
            if (a[column] && b[column] && (a[column].toLowerCase() > b[column].toLowerCase())) {

            }
            return 0;
        }

        let sortByDesc = (a,b) => {
            if (a[column] && b[column] && (a[column].toLowerCase() > b[column].toLowerCase())) {
                return -1;
            }
            if (a[column] && b[column] && (a[column].toLowerCase() < b[column].toLowerCase())) {

            }
            return 0;
        }

        if (sortType === 'asc') {
            entityData.sort(sortByAsc);
        } else {
            entityData.sort(sortByDesc);
        }
        return entityData;
    }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
          this.entity.entity_id = params['id'];
        });
        this.activatedRoute.queryParamMap.subscribe(param => {
            this.catalog_id = +param.get('catalog_id');
            this.dataQualityCount = +param.get('total_rules');
        });
        this.checkCatalogAdmin();
        this.getDateConcept();
        this.getNumberConcept();
        this.gridOptions = {
            enableGridMenu: false,
            frozenRow: 1,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            rowHeight: 35,
        };
        
        this.contentSvc.getAttributeDataTypes().subscribe(resp => {
            resp.forEach(item => { this.attributeDataTypeslistDropDown.push(item); });
        }, err => { });

        this.contentSvc.getAttributeScrubberTypes().subscribe(resp => {
            resp.forEach(item => { this.attributeScrubberTypeslistDropDown.push(item); });
        }, err => { });

        this.contentSvc.getEntityAttributeMatcherTypes().subscribe(resp => {
            resp.forEach(item => { this.attributeMatcherTypeslistDropDown.push(item); });
        }, err => { });

        this.initialiseGrid();
        this.getEntityAttributes();

       
    }

    initialiseGrid() {
        this.columnDefs = [
            {
                headerName: 'Attr. ID',
                field: 'attribute_id',  
                filter: 'agTextColumnFilter',
                sortable: true,
                editable: false,
                floatingFilter: true,
                minWidth: 120,
                maxWidth: 120, 
                cellClass: 'ag-right-aligned-cell',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                  },
              cellRendererFramework: CustomCellEditModeFormatter,
              cellRendererParams: { 'placeholder': 'Add user name', 'tabindex': 1 },
              
            },{
              headerName: 'Sort Order',
              field: 'sort_order',    
              floatingFilter: false,
              sortable: false, 
              minWidth: 130,
              maxWidth: 130, 
              editable: false,
              cellRendererFramework: CustomCellRenderer              
              
            }, {
              headerName: 'Logical Name',
              field: 'logical_name', 
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true, 
              editable: false,
              minWidth: 150,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomCellRenderer,
              cellRendererParams: { 'placeholder': 'Add Logical Name' },
               
            }, {
              headerName: 'Physical Name',
              field: 'physical_name',
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true,  
              editable: false,
              minWidth: 150,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomCellRenderer,
              cellRendererParams: { 'placeholder': 'Add Physical Name' },
            }, {
              headerName: 'Description',
              field: 'description',
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true, 
              editable: false,
              minWidth: 150,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomCellRenderer,
              cellRendererParams: { 'placeholder': 'Add Description' },
            }, {
              headerName: 'Data Type',
              field: 'data_type', 
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true,
              editable: false,
              minWidth: 130,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomDropdownCellRenderer,
              cellRendererParams: { list: this.attributeDataTypeslistDropDown }
            }, {
              headerName: 'Scrubber Name',
              field: 'scrubber_name',
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true, 
              editable: false,
              minWidth: 150,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomDropdownCellRenderer,
              cellRendererParams: { list: this.attributeScrubberTypeslistDropDown }
            }, {
              headerName: 'Match Type',
              field: 'matcher_type_name', 
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              sortable: true,
              editable: false,
              minWidth: 150,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: CustomDropdownCellRenderer,
              cellRendererParams: { list: this.attributeMatcherTypeslistDropDown }
            }, {
              headerName: 'Reference Data',
              field: 'is_visible_input',  
              filter: 'agTextColumnFilter',
              floatingFilter: false,
              sortable: false, 
              headerClass: 'entify-ref-header-view',
              editable: false,
              minWidth: 100,
              cellRendererFramework: CustomCellRenderer 
            }, {
              headerName: ' ',
              field: 'action',   
              editable: false,
              cellClass: 'entify-delete-icon',
              suppressColumnsToolPanel: true,
              minWidth: 50,
              cellRendererFramework: DeleteIconRendererComponent, 
            } 
          ];
          this.fsGridOptions = {  
            headerHeight: 45,  
            rowHeight: 30,   
            floatingFiltersHeight: 49,
            enableCellTextSelection: true,
            getRowStyle: params => { 
              if (params.node.rowIndex % 2 === 0) {
                return { background: AppGlobals.EVEN_ROW_BG_COLOR };
              }
              return { background: AppGlobals.ODD_ROW_BG_COLOR };
            },
          };  
       
      }
       
    
    
    getEntityAttributes() {
        this.showLoadingSpinner = true;
        this.contentSvc.getEntity(this.entity.entity_id, true, true).subscribe(resp => {
            this.entityDataSource = resp;
            this.showLoadingSpinner = false;
            this.attributeTableRows = resp.attributes;
            this.isDataReady = true;
            if (this.attributeTableRows.length === 0) {
                this.noData = true;
            }
            this.total = this.attributeTableRows.length;
                this.attributeTableRows = resp.attributes.map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            // Commented below since initial setting first row to pin handled by AG-GRID
            // const firstRow = this.getAddAttributeRow();
            // this.setNewAttributeDetails(firstRow);
            // this.attributeTableRows.splice(0, 0, firstRow);
            this.isDataReady = true;
            this.hasScrolled = false;
            grdLenght=this.attributeTableRows.length-1;
            if (this.gridRef) {
                this.gridRef.dataView.setItems(this.attributeTableRows);
                this.gridRef.gridService.setSelectedRows([]);
                
            }
        }, (err) => {
            this.showLoadingSpinner = false;
            this.isDataReady = true;
            this.messageSvc.sendMessage({ message: err && err.error && err.error.message ? err.error.message: 'Something went wrong while fetching entities', type: 'INFO', hideboard: true });
        });
    }

    readAttributeDetails() {
        // this.addAttribute.sort_order = $('#attribute-sort_order')[0]['value'];
        try {
            this.addAttribute.logical_name = 'test';//$('#attribute-logical_name-0')[0]['value'];
            this.addAttribute.physical_name = $('#attribute-physical_name-0')[0]['value'];
            this.addAttribute.description = $('#attribute-description-0')[0]['value'];
            this.addAttribute.attribute_data_type_id = $('#attribute-attribute_data_type_id-0')[0]['value'];
            this.addAttribute.attribute_scrubber_type_id = $('#attribute-attribute_scrubber_type_id-0')[0]['value'];
            this.addAttribute.matcher_type_id = $('#attribute-matcher_type_id-0')[0]['value'];
            this.addAttribute.is_visible_output = $('#attribute-is_visible_output-0')[0]['checked'];
            this.addAttribute.is_visible_input = $('#attribute-is_visible_input-0')[0]['checked'];
        } catch(e) {
            console.log('e', e);
        }
    }

    setNewAttributeDetails(row) {
        row.logical_name = this.addAttribute.logical_name;
        row.physical_name = this.addAttribute.physical_name;
        row.description = this.addAttribute.description;
        row.is_visible_output = this.addAttribute.is_visible_output;
        row.is_visible_input = this.addAttribute.is_visible_input;
        row.attribute_data_type_id = this.addAttribute.attribute_data_type_id;
        row.attribute_scrubber_type_id = this.addAttribute.attribute_scrubber_type_id;
        row.matcher_type_id = this.addAttribute.matcher_type_id;
    }
  
    getAddAttributeRow() {
        return { 
            'id': Math.random(), 
            'attribute_id': '-1', 
            'sort_order': '', 
            'logical_name': '', 
            'physical_name': '', 
            'description': '', 
            'data_type': 'Select Data Type', 
            'scrubber_name': 'Select Scrubber Type', 
            'matcher_type_name': 'Select Matcher Type', 
            'is_visible_output': false, 
            'is_visible_input': false, 
            'action': 'add' 
        };
    }

    getPaggedAttribute(page, limit) {
        this.readAttributeDetails();
        this.contentSvc.getEntity(this.entity.entity_id, true, true).subscribe(resp => {
            this.attributeTableRows = resp.attributes;
            if (this.attributeTableRows.length === 0) {
                this.noData = true;
            }
            let concatedRows = this.attributeTableRows;
            if (this.gridRef) {
                concatedRows = [...this.gridRef.dataView.getItems(), ...this.attributeTableRows];
                this.attributeTableRows = resp.attributes.map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }
            // Commented below since initial setting first row to pin handled by AG-GRID
            // const firstRow = this.getAddAttributeRow();
            // this.setNewAttributeDetails(firstRow);
            // this.attributeTableRows.splice(0, 0, firstRow);
            this.isDataReady = true;
            this.attributeTableRows = concatedRows;
            this.gridRef.dataView.setItems(concatedRows);
            this.gridRef.gridService.setSelectedRows([]);
            this.gridRef.dataView.refresh();
            this.hasScrolled = false;
        }, err => {
            this.isDataReady = true;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });

    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getPaggedAttribute(this.pageLeft, this.limitLeft);
    }

    leftGridCount() {
        if (this.gridRef) {
            // Remove Add Row Count
            const hasFilter = this.gridRef.dataView.getItems().length - this.attributeTableRows.length;
            if (hasFilter < 0) {
                return this.gridRef.dataView.getItems().length;
            } else {
                return this.gridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.attributeTableRows.length - 1;
        }
    }

    onLeftGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass, 'bottom') && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
        }
    }

    onEditMode(){
        $('#breadcrumb').addClass('d-none');
        this.router.navigate(['/zs/entities/' + this.entity.entity_id + '/entity-model'], { queryParamsHandling: 'preserve' });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }
    checkCatalogAdmin(){
        this.classifyContentSvc.getCatalogUser(this.catalog_id).subscribe(resp => {
            if (resp.length) {
              resp.forEach(user => {
                if (user.user_id == this.loggedInUserDetails['user_id']) {
                  const userRoles = user.roles.filter(role => role === AppGlobals.CATALOG_ADMIN);
                  if (userRoles.length) {
                    this.is_catalog_admin = true;
                  } else {
                    this.is_catalog_admin = false;
                  }
                }
              });
            }
          });
    }

    onGridReady(params: any) { 
        this.GridApi = params.api;
        this.columnApi = params.columnApi;
        // Below line is using to Freeze the first row
        this.GridApi.setPinnedTopRowData([{attribute_id: this.catalog_id}]);
      }

    attributeGrid(gridApi: GridApi) {
        window.addEventListener("DOMContentLoaded", (event) => {
        this.GridApi.addEventListener('cellEditingStarted', (event) => {
          const args = event as any; // Adjust the type if needed
      
          if (args.column.getColDef().field === 'data_type' || args.column.getColDef().field === 'scrubber_name' || args.column.getColDef().field === 'matcher_type_name') {
            return true;
            } else {
                return false;
            }
        });
    });
      }
    showRuleDialog() { 
        this.showmodelpopup = true;
    }
    popupoutput(message) {
        this.showmodelpopup = message;
    }
    getDateConcept(){
        this.classifyContentSvc.getConcepts(this.catalog_id, this.entity.entity_id,'date',false).subscribe(resp=>{
         this.dateConcept = resp;
        },error=>{});
    } 
    getNumberConcept(){
        this.classifyContentSvc.getConcepts(this.catalog_id, this.entity.entity_id, 'int',false).subscribe(resp=>{
         this.numberConcept = resp;
        },error=>{});
    }   
}

