
import { Column, Formatter} from 'angular-slickgrid';

export const matchFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (columnDef.params.shouldDisableFeedback) {
        if (dataContext.zs_feedbackid === '1') {
            return `<div class='formatter match-radio-formatter' style="pointer-events: none; opacity: 0.4;">
                      <input type='radio' id='match-row-${row}' name='' checked disabled /><label for='match-row-${row}' class="radio-formatter radio-label match-formatter"></label>
                    </div>`;
        } else {
            return `<div class='formatter match-radio-formatter' style="pointer-events: none; opacity: 0.4;">
                      <input type='radio' id='match-row-${row}' name='' disabled /><label for='match-row-${row}' class="radio-formatter radio-label match-formatter"></label>
                    </div>`;
        }
    } else {
        let anchor_record = ' ';
        if (dataContext.zs_feedbackid === '1' && dataContext.is_anchor_record === false) {
            return `<div class='formatter match-radio-formatter'>
                      <input type='radio' id='match-row-${row}' name='' checked /><label for='match-row-${row}' class="radio-formatter radio-label match-formatter"></label>
                    </div>`;
        } else if (dataContext.is_anchor_record === true) {
            return `<div class="w-100 h-100 p-0 m-0 anchor-record">${anchor_record}</div>`;
        } else {
            return `<div class='formatter match-radio-formatter'>
                      <input type='radio' id='match-row-${row}' name='match-row-${row}' /><label for='match-row-${row}' class="radio-formatter radio-label match-formatter"></label>
                    </div>`;
        }
    }
}

// dataContext.zs_feedbackid === '1' ?
//   `<div class='formatter match-radio-formatter'>
//     <input type='radio' name=''  checked />aaa  <label class="radio-formatter match-formatter"></label>
//   </div>` :
//   `<div class='formatter match-radio-formatter'>
//     <input type='radio' name=''  />bbb <label class="radio-formatter match-formatter"></label>
//   </div>`;