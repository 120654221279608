
// export class Analytic {
//     analyticid: string;
//     name: string;
//     description: string;
//     // version: string;
//     // users: string;    //any[];
//     // datasets: string;   //Dataset[];
//     // lastmodified: Date ;
//     // nummodelruns: number;
//     // lastrundate: Date ;
//     // numoutcomeattributes: number;
//     // accuracy: string;
//     // analytictypeid: string;
//     // analytictypename: string;
//     // analytictypedesc: string;
//     analyticstype: string;
//     userid: string;
//     tablename: string;
//     groupid: string;
//     algoid: string;
//     isdeleted  = false;
//     users: any[];
//     predictiondatasets: any[];
//     trainingdatasets: any[];
    
//     // entityid: string;
//     // datasets: Dataset[];
    
//     // projectid: string;
//     // sourceip: string;
//     // createdts: Date ;
//     // updatedts: Date;
    
//     // active_users: any[];
//     // deleteusers: any[];
//     // deletedatasets: any[];
//     // totalrecords: number = undefined;
    
//     // entityname: string  = undefined;

//     constructor() {}
// }



export class Analytic {
    analyticid: string;
    name: string;
    description: string;
    version: string;
    // users: string;    //any[];
    // datasets: string;   //Dataset[];
    lastmodified: Date ;
    nummodelruns: number;
    lastrundate: Date ;
    numoutcomeattributes: number;
    accuracy: string;
    analytictypeid: string;
    analytictypename: string;
    analytictypedesc: string;
    algoid: string;
    img: string;

    userid: string;
    // entityid: string;
    datasets: Dataset[];
    groupid: string;
    projectid: string;
    // sourceip: string;
    // createdts: Date ;
    // updatedts: Date;
    users: any[];
    active_users: any[];
    deleteusers: any[];
    // deletedatasets: any[];
    totalrecords: number = undefined;
    isdeleted  = false;
    // entityname: string  = undefined;

    constructor() {}
}









export class Dataset {

  datasetid: number;
  name: string = undefined;
  authoritativeness: number = undefined;
  sortorder: number = undefined;

  attributes: {'targetattribute': string, 'systemattribute': string, 'attributeid': number, 'opCode' }[];
  constructor() { }
}

export class ProjectUser {

    constructor() { }

    userid: String;
    username: String;
    group: {name: string, id: string};
  }

  export class ProjectDataSet {

    constructor() { }

    userid: String;
    username: String;
    group: {name: string, id: string};
  }


export interface IEntityPrams {
  userId?: string;
  projectId?: string;
  clusterId?: any;
  clusterName?: string;
  searchString?: string;
  payload?: object;
  selectedProjectId?: string;
  scenarioId?: number;
}
