import { AppGlobals } from '../../common/zm-shared/app.globals';
import { Injectable } from '@angular/core';
import { ZmBaseService } from '../../common/zm-services/zm-base-service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ZmContentService extends ZmBaseService {
	
	constructor(private http: HttpClient) { super() }

	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	getWorkbooksList(): Observable<any> {
		return this.http.get('./assets/workbooks.json');
	}

	getDataSetsList(userid, groupid, pageno, perpage): Observable<Object> {
		return this.http.get('./assets/datasets.json');
	}

	getWorkbooks(userId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/workbooks`);
	}

	getDataSets(userId: string, workbookId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/workbooks/${workbookId}/datasets`);
	}

	getFormulas(userId: string, workbookId: string, datasetId: string, pageno, perpage): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/workbooks/${workbookId}/datasets/${datasetId}/operations/?pageno=${pageno}&pagesize=${perpage}`);
	}

	getDataSourcesByID(dataSourceId): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).userid}/groups/${this.getObjectFromSession(AppGlobals.USER_INFO).groupid}/datasources/` + dataSourceId + `/data`).pipe(
			map(this.extractData));
	}

	getDataSources(pageno, perpage): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).userid}/groups/${this.getObjectFromSession(AppGlobals.USER_INFO).groupid}/datasources?pageno=${pageno}&pagesize=${perpage}`).pipe(
			map(this.extractData));
	}

	getSummaryByDataSourcesID(dataSourceId, stageId): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).userid}/groups/${this.getObjectFromSession(AppGlobals.USER_INFO).groupid}/datasources` + dataSourceId + `/data/summary?stage=` + stageId).pipe(
			map(this.extractData));
	}
}
