import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { DatasourceService } from '../../datasources/datasource.service';
import { JsonPipe } from '@angular/common';
import $ from 'jquery';
import { Entity } from 'src/app/zettasense/content/entities/entity.model';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
    value==true ? `<label class="checkbox-container">
                <input type="checkbox" checked>
                <span class="checkmark checkmark-left-56"></span>
             </label>` 
          : `<label class="checkbox-container">
                <input type="checkbox">
                <span class="checkmark checkmark-left-56"></span>
             </label>`;
      
const entityCheckBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
value == AppGlobals.CATALOG_ADMIN ? `<label class="checkbox-container">
            <input type="checkbox" checked>
            <span class="checkmark checkmark-left-56"></span>
        </label>` 
    : `<label class="checkbox-container">
            <input type="checkbox">
            <span class="checkmark checkmark-left-56"></span>
        </label>`; 

const datasetNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
                if(dataContext.checkForDisableCond == true) {
                    return `<div class="disable-row">${value}</div>`;
                } else {
                    return `<div>${value}</div>`;
                }
              }

const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';
const ATLEAST_ONE_ADMIN_MSG = 'There must be another project admin assigned before you can be removed from the project';

@Component({
    selector: 'zetta-add-admin-users',
    templateUrl: './add-admin-users.component.html',
    styleUrls: ['./add-admin-users.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AddAdminUsersComponent implements OnInit, OnDestroy, AfterViewChecked {
    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    leftDataSource: any = [];
    rightDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;

    tableSettings: object = { 'height': "100%", 'width': "100%" };
    rightTableSettings: object = { 'height': "100%", 'width': "100%" };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];

    UsersData: any = [];
    errorRibbon = false;
    nextstepbtn = false;
    isApprovalRequired = true;
    isAdminSelected=true;
    datasourceAddAdmins: boolean = true;
    datasource: any = {};
    hasLeftUsersSelected = false;
    hasRightUsersSelected = false;
    btnDarkOpacity = 1;
    btnLightOpacity = 0.3;
    btnDarkApply = false;
    btnLightApply = false;
    oldUsersAssignedToDS: any = [];
    applyBtnDisable = true;
    entity = new Entity();
    loadEntity = false;
    isEntityAdminUser: Boolean = false;
    catalogId: string;
    semanticId: string;
    updateUserList: any = [];
    enableApplyBtn: boolean = false;
    rowsLeftSelected; any = [];

    constructor(public datasourceSvc: DatasourceService,
        private projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private zettaUtils: ZettaUtils,
        private zsContentService: ZsClContentService,
        private messageSvc: MessageService) { }

    ngOnInit() {
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.isEntityAdminUser = this.router.url.includes('zs/entities') && this.router.url.includes('add-users');
        if(this.isEntityAdminUser) {
            this.entity.entity_id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('id'));
            this.loadEntity = this.entity.entity_id ? true : false;
            const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
            if (showBreadcrumb && showBreadcrumb === 'true') {
              this.showBreadcrumb = true;
            }
            if (!this.showBreadcrumb || this.entity.entity_id) {
              // Remove BreadCrumb
              $('#breadcrumb').addClass('d-none');
            }            
            this.progressState.states = this.zettaUtils.getStateList('create-entity');
            this.progressState.currentStateIndex = 1;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'entity';
            this.progressState.isEdit = this.loadEntity;
            if(this.activatedRoute.queryParams) {
                this.activatedRoute.queryParams.subscribe(qParam => {
                this.catalogId = qParam.catalog_id;
                });
            }
            this.activatedRoute.parent.params.subscribe(param => this.semanticId = param.id);
            
        } else {
            this.datasource.datasource_id = this.activatedRoute.snapshot.queryParamMap.get('id');
            const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
            if (showBreadcrumb && showBreadcrumb === 'true') {
                this.showBreadcrumb = true;
            }
    
            if (!this.showBreadcrumb) {
                $('#breadcrumb').addClass('d-none');
            }
            this.progressState.states = this.zettaUtils.getStateList('create-datasource');
            this.progressState.currentStateIndex = 2;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Data Source';
            this.progressState.isEdit = (this.datasource.datasource_id !== undefined && this.datasource.datasource_id !== null) ? true : false;
            this.progressState.isEdit = sessionStorage.getItem('newDataSource') === 'true' ? false : this.progressState.isEdit;
            this.getDatasource();
        }
        this.getUsersToDatasource();
        this.initColDef();
        this.testConnection();

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };


    }

    initColDef() {
        
        this.gridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': datasetNameFormatter,
        }, {
            'displayname': 'First Name',
            'physicalname': 'first_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Last Name',
            'physicalname': 'last_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Group Name',
            'physicalname': 'group_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];

        this.rightGridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'maxWidth': 150,
        }, {
            'displayname': 'First Name',
            'physicalname': 'first_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'maxWidth': 150,
        }, {
            'displayname': 'Last Name',
            'physicalname': 'last_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'maxWidth': 160,
        }, {
            'displayname': this.isEntityAdminUser ? 'Entity Admin' : 'Data Source Admin',
            'physicalname': this.isEntityAdminUser ? 'role_id' : 'is_admin',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': this.isEntityAdminUser ? entityCheckBoxFormatter : checkBoxFormattter,
            'minWidth': 120,
            'maxWidth': 130,
        }];
    }

    getDatasource() {
        this.datasourceSvc.getDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(responseList => {
          this.datasource = responseList;
          this.datasource.lookup_id = this.datasource['datasource_type_id'];
          this.progressState.datasource = this.datasource;
          this.isDataReady = true;
        }, error => {
          this.messageSvc.sendMessage({ message: 'Get datasource has failed', type: 'INFO', hideboard: true });
        });
      }

      getUsersToDatasource() {
        if(this.isEntityAdminUser) {
            var serverfilter = "";
            if (sessionStorage.getItem("serverfilter")) {
                serverfilter = sessionStorage.getItem("serverfilter");
            }
            var sortfilter = "";
            if (sessionStorage.getItem("sortfilter")) {
                sortfilter = sessionStorage.getItem("sortfilter");
            }
            this.datasourceSvc.getEntityAdminUsers({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], catalogId: this.catalogId, semanticId: this.semanticId}).subscribe(responseList => {
                this.rightGridDataSource = responseList;
                this.rightGridDataSource = this.rightGridDataSource.filter((thing, index, self) => index === self.findIndex((t) => ( t.user_id === thing.user_id )))
                this.rightGridDataSource.forEach(element => {
                    element.id = Math.random();
                });
                this.oldUsersAssignedToDS = this.rightGridDataSource;
            }, error => {
              this.messageSvc.sendMessage({ message: 'Get users has failed', type: 'INFO', hideboard: true });
            });
        } else {
            var serverfilter = "";
            if (sessionStorage.getItem("serverfilter")) {
                serverfilter = sessionStorage.getItem("serverfilter");
            }
            var sortfilter = "";
            if (sessionStorage.getItem("sortfilter")) {
                sortfilter = sessionStorage.getItem("sortfilter");
            }
            this.datasourceSvc.getUsersToDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id },serverfilter,sortfilter).subscribe(responseList => {
                this.rightGridDataSource = responseList['currentpage'];
                this.rightGridDataSource = this.rightGridDataSource.filter((thing, index, self) => index === self.findIndex((t) => ( t.user_id === thing.user_id )))
                this.rightGridDataSource.forEach(element => {
                    element.id = Math.random();
                });
                this.oldUsersAssignedToDS = this.rightGridDataSource;
            }, error => {
              this.messageSvc.sendMessage({ message: 'Get users has failed', type: 'INFO', hideboard: true });
            });
        }
    }

    testConnection() {
        const parent = this;
        if(parent.isEntityAdminUser) {
            this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/groups/${this.loggedInUserDetails['group_id']}/users`;
        }
        var serverfilter = "";
        if (sessionStorage.getItem("serverfilter")) {
           serverfilter = sessionStorage.getItem("serverfilter");
        }
        var sortfilter = "";
        if (sessionStorage.getItem("sortfilter")) {
          sortfilter = sessionStorage.getItem("sortfilter");
        }
        if(parent.isEntityAdminUser) {
            this.datasourceSvc.getUsers({ userId: this.loggedInUserDetails['user_id'], groupId: this.loggedInUserDetails['group_id']}).subscribe(responseList => {
                this.leftGridDataSource = responseList;
                this.leftGridDataSource.forEach(element => {
                    element.id = Math.random();
                  });
                  this.isDataReady = true;
                }, err => {  });
        } else {
            this.zsContentService.getLeftUsers( this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
                this.leftGridDataSource = responseList['currentpage'];
                this.leftGridDataSource.forEach(element => {
                  element.id = Math.random();
                });
                this.isDataReady = true;
              }, err => {  });
        }
    }

    getUsersList() {
        this.datasourceSvc.getUsers({ userId: this.loggedInUserDetails['user_id'], groupId: this.loggedInUserDetails['group_id']}).subscribe(responseList => {
            this.rightGridDataSource = responseList;
            // this.rightGridDataSource = responseList[1];
            this.rightGridDataSource.forEach(row => {
                row.id = Math.random();
            //     let countRoles = 0;
            //     row.roles.forEach(roles => {
            //         switch (roles) {
            //             case AppGlobals.PROJECT_ADMIN:
            //                 row['isAdminChecked'] = true;
            //                 row['isApprovalRequired'] = this.isApprovalRequired;
            //                 row['selectedRoles'] = row['roles'];
            //                 adminCount++;
            //                 countRoles++;
            //                 break;
            //             default:
            //                 row['isAdminChecked'] = false;
            //                 row['isApprovalRequired'] = this.isApprovalRequired;
            //                 row['isReviewerChecked'] = false;
            //                 row['isApproverChecked'] = false;
            //                 row['selectedRoles'] = [];
            //         }
            //     });
            //     if (countRoles > 0) {
            //         rowUserCount++;
            //     }
            });

            this.rightGridRows = this.rightGridDataSource.length;
            // this.leftGridDataSource = responseList[0]['currentpage'];
            this.leftGridDataSource = responseList;
            // this.leftGridDataSource = this.leftGridDataSource.filter(user1 => !this.rightGridDataSource.find(user2 => user1.user_id === user2.user_id));
            this.leftGridRows = this.leftGridDataSource.length;
            this.leftGridDataSource.forEach(row => {
                row.id = Math.random();
            });

            this.initColDef();
            this.isDataReady = true;
        }, err => {
            // alert('error');
            this.messageSvc.sendMessage({ message: 'Users get user list has failed', type: 'INFO', hideboard: true });
        });
    }

    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
            const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
            const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
            const rows = this.rightGrid.dataView.getItems();
    
            if (row !== undefined && row !== null) {
                if (fieldName === 'is_admin') {
                    row['is_admin'] = !row['is_admin']; 
                } 
                const userList: Object[] = [];
                if(this.isEntityAdminUser && fieldName == 'role_id') {
                    row['role_id'] = (row['role_id'] == AppGlobals.CATALOG_ADMIN) ? '' : AppGlobals.CATALOG_ADMIN;
                    rows.forEach(row => {
                        if(row && row.role_id) {
                            userList.push({ user_id: row.user_id, role_id: row.role_id }); 
                        }
                    });
                } else {
                    rows.forEach(row => {
                        userList.push({ user_id: row.user_id, is_admin: row['is_admin'] }); 
                    }); 
                }
                if(!this.isEntityAdminUser) {
                this.updateDatasource(userList);
                this.rightGrid.gridService.updateDataGridItem(row, false);           
                // TODO: Call API to Update DB
    
            } else {
                this.updateUserList = userList;
                this.rightGrid.gridService.updateDataGridItem(row, false);  
                this.enableApplyBtn = true;
            }
            }
    }
    checkIsAdmin() {
        const rows = this.rightGrid.dataView.getItems();
        if (rows.length && !this.isEntityAdminUser) {
            this.isAdminSelected = rows.some(user => user.is_admin === true);
            this.isAdminSelected ? this.applyBtnDisable = false : this.applyBtnDisable = true;
        } else if(rows.length && this.isEntityAdminUser) {
            this.isAdminSelected = rows.some(user => user.role_id == AppGlobals.CATALOG_ADMIN); 
        }
    }

    onAdd() {
        this.btnLightApply = false;
        this.applyBtnDisable = false;
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const userList: Object[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null && !row.checkForDisableCond) {
                row['checkForDisableCond'] = true;
                rows.push(row);
            }
        });

        rows.forEach(row => {
            row['isApprovalRequired'] = this.isApprovalRequired;
            // row['is_admin'] = false;
            if(this.isEntityAdminUser) {
                row['role_id'] = '';
            } else {
                row['is_admin'] = true;
            }
            this.rightGridDataSource.push(row);
            rightGridDataService.addItemToDatagrid(row, true);
        });

        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        this.checkForDisable();
        leftGridDataService.renderGrid();
        
        setTimeout(() => {
            $('div.disable-row').parent().parent().addClass('disabled-state');
        }, 500)

        // Update Row Count
        this.rightGridDataSource.forEach(element => {
            if(this.isEntityAdminUser) {
                userList.push({ user_id: element.user_id, role_id: AppGlobals.CATALOG_ADMIN }); 
            } else { 
                userList.push({ user_id: element.user_id, is_admin: element.is_admin });
            }
            if(this.oldUsersAssignedToDS.length === this.rightGridDataSource.length) {
                let users = this.oldUsersAssignedToDS.filter(user => user.user_id === element.user_id).length > 0 ? true : false;
                if(!users) {
                    this.applyBtnDisable = false;
                }
            } else {
                this.applyBtnDisable = false;
            }
        });

        this.leftGridRows = this.leftGridRows - userList.length;
        this.rightGridRows = this.rightGridRows + userList.length;
        // update DB
        if(!this.showBreadcrumb && !this.isEntityAdminUser) {
            this.updateDatasource(userList);
        } else if(this.isEntityAdminUser) {
            this.updateUserList = userList;
        }
        this.rowsLeftSelected = [];
    } 

    onRemove() {
        this.btnLightApply = false;
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const userList: Object[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            // This is for edit flow
            if(this.showBreadcrumb){
                if (row !== undefined && row !== null) {
                    rows.push(row);
                }
            }
            // This is for create flow
            if(!this.showBreadcrumb){
                if (row !== undefined && row !== null && row.user_id !== this.loggedInUserDetails['user_id']) {
                    rows.push(row);
                }
                if (row !== undefined && row !== null && row.user_id === this.loggedInUserDetails['user_id']) {
                    this.messageSvc.sendMessage({ message: 'Logged on user cannot be removed', type: 'INFO', hideboard: true });
                }
            }

        });
       
        rows.forEach(row => {
            row.is_admin = false;
            delete row['isApprovalRequired'];
            this.leftGridDataSource.forEach((element, ind) => {
                if(element.user_id === row.user_id) {
                    element.checkForDisableCond = false;
                    row['checkForDisableCond'] = false;
                }
                let items = this.leftGrid.dataView.getItems();
                leftGridDataService.renderGrid();
                this.leftGrid.dataView.refresh();
            });
            // remove Item from right grid
            this.rightGridDataSource = this.rightGridDataSource.filter(user => user.user_id !== row.user_id);
            rightGridDataService.deleteDataGridItem(row);
        });
        if(this.isEntityAdminUser) {
            this.removeEntityUser(rows);
        }
        this.rightGridDataSource.forEach(element => {
            userList.push({ user_id: element.user_id, is_admin: element.is_admin });
            if(this.oldUsersAssignedToDS.length === this.rightGridDataSource.length) {
                let user = this.oldUsersAssignedToDS.filter(user => user.user_id === element.user_id).length > 0 ? true : false;
                if(!user) {
                    this.applyBtnDisable = false;
                }
            } else {
                this.applyBtnDisable = false;
            }
        });

        if(!this.showBreadcrumb && !this.isEntityAdminUser) {
            this.updateDatasource(userList);
        }

        this.checkForDisable();
        
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        this.leftGridRows = this.leftGridRows + userList.length;
        this.rightGridRows = this.rightGridRows - userList.length;
        setTimeout(() => {
            $('div.disable-row').parent().parent().addClass('disabled-state');
        }, 500)
        this.rowsLeftSelected = [];
        const leftItems  = this.leftGrid.dataView.getItems();
        let leftFiltered = leftItems.filter(item => item.checkForDisableCond == true);
        let items = this.getDuplicateUsers(leftFiltered, this.rightGridDataSource);
        setTimeout(() => {
            items.forEach(right => {
            leftFiltered.forEach(left => {
                if(right.user_name == left.user_name) {
                        left['checkForDisableCond'] = false;
                }
                });
            });
            leftGridDataService.renderGrid();
            this.leftGrid.dataView.refresh();
        }, 0);
    }

    rightGridCreated(grid) {
        const parent = this;
        parent.rightGrid = grid;
        parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
          parent.hasRightUsersSelected = args.rows.length > 0 ? true : false;
          parent.nextstepbtn = args.rows.length > 0 ? true : false;
        });    
        parent.rightGrid.slickGrid.onSort.subscribe(sorted => {
            let sortfilter = "";
            if (sessionStorage.getItem("sortfilter")) {
                sortfilter = sessionStorage.getItem("sortfilter");
            }
            let sortFilters = sortfilter.split('&');
            let values = [];
            let sortBy;
            let sortOrder;
            sortFilters.forEach(sort => {
            values.push(sort.split('='));
            });
            sortBy = values[0][1].toLowerCase();
            sortOrder = values[1][1];
            let sortedData = this.rightGrid.dataView.getItems();
            if(sortOrder == 'asc') {
                sortedData.sort((a,b) => a.sortBy - b.sortBy);
            } else if (sortOrder == 'desc') {
                sortedData.sort((a,b) => a.sortBy - b.sortBy);
            }
            parent.rightGrid.dataView.setItems(sortedData);
        });
    }

    leftGridCreated(grid) {
        const parent = this;
        parent.leftGrid = grid;
        const leftGridDataService = parent.leftGrid.gridService;
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            let leftSelected = [];
            if(args.rows.length === parent.leftGridDataSource.length) {
                parent.leftGridDataSource.forEach((element, index) => {
                    if(element['checkForDisableCond'] !== true) {
                    }
                });
            }
                parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
                parent.checkForDisable();
            });
        parent.leftGrid.slickGrid.onSort.subscribe(sorted => {
            if(sorted) {
                parent.checkForDisable();
            }
        });
        this.checkForDisable();
    }

    updateDatasource(users: any[]) {
        // Checking is_admin selected or not
        this.checkIsAdmin();
        if (users.length > 0) {
            const adminUsers: any = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                datasource_id: +this.datasource.datasource_id,
                payload: users
            }

            /**
             * Calling API on 'Add' or 'remove' for Datasource. 
             * showBreadcrumb is false in case of 'Create' and 'Edit' flow
             * Below API called only for Datasource
             *  */ 
            if (!this.showBreadcrumb && !this.isEntityAdminUser) {
                this.datasourceSvc.updateUsersToDatasource(adminUsers).subscribe(resp => {
                    console.log("Update data :", resp);
                }, err => {
                    this.messageSvc.sendMessage({ message: 'Update has failed', type: 'INFO', hideboard: true });
                });
            }
        }
    }

    onCancel() {
        if (!this.showBreadcrumb && !this.isEntityAdminUser) {
            this.router.navigate(['/zs/datasources']);
        }else if(this.isEntityAdminUser) {
            this.router.navigate(['/zs/entities']);
        } else {
            this.router.navigate(['/zs/datasources', this.datasource.datasource_id]);
        }
    }

    onPrevious() {
        if(this.isEntityAdminUser) {
            this.router.navigate(['/zs/entities/create-entity'], { queryParams: { catalog_id: this.catalogId, entity_id: this.semanticId }, queryParamsHandling: 'merge' });
        } else {
            this.router.navigate(['/zs/datasources', this.datasource.datasource_id, 'define-connection'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
        }
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    checkForDisable() {
        const parent = this;
        parent.leftGridDataSource.forEach(element => {
            parent.rightGridDataSource.forEach(rightEle => {
              if(element.user_name === rightEle.user_name) {
                element['checkForDisableCond'] = true;
              } else {
                return;
              }
            });
        });
        $('div.disableRow div.slick-row div.disable-row').parent().parent().addClass('disabled-state');

        setTimeout(() => {
            $('div.disable-row').parent().parent().addClass('disabled-state');
        }, 500)
    }

    ngAfterViewChecked() {
                this.checkForDisable();
    }

    addUserRole() {
        const gridrows = this.rightGrid.dataView.getItems();
        const userList: Object[] = [];
        gridrows.forEach(row => {
                 userList.push({ user_id: row.user_id, is_admin: row['is_admin'] });
        });
        const saveUsers: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            datasource_id: +this.datasource.datasource_id,
            payload: userList
        };
        if(this.showBreadcrumb) {
            this.btnDarkApply = true;
            this.btnDarkOpacity = 1;
        }
        // const adminUsers: any = {
        //     user_id: this.loggedInUserDetails['user_id'],
        //     tenant_id: this.loggedInUserDetails['tenant_id'],
        //     datasource_id: +this.datasource.datasource_id,
        //     payload: userList
        // }
        //  this.datasourceSvc.updateUsersToDatasource(adminUsers).subscribe(resp => { 
        //     }, err => {
        //         this.messageSvc.sendMessage({ message: 'Update has failed', type: 'INFO', hideboard: true });
        //     });
        this.datasourceSvc.updateUsersToDatasource(saveUsers).subscribe(resp => {
            if(this.showBreadcrumb) {
                setTimeout(() => { this.btnDarkOpacity = 0 }, 1500);
                setTimeout(() => { this.btnDarkApply = false; this.btnLightApply = true; }, 700);
                setTimeout(() => { this.btnLightOpacity = 1 }, 700);
                setTimeout(() => { this.btnLightApply = false; this.applyBtnDisable = true; }, 700);
                this.oldUsersAssignedToDS = gridrows; 
            } else {
                this.router.navigate(['/zs/datasources',this.datasource.datasource_id]);
            } 
        }, err => {
            this.messageSvc.sendMessage({ message: 'Update has failed', type: 'INFO', hideboard: true });
        });
    }

    onNextStep() {
        if(this.isEntityAdminUser) {
        this.router.navigate(['/zs/entities/' + this.semanticId + '/entity-model'], {queryParamsHandling: 'preserve'});
        } else {
            this.router.navigate(['/zs/datasources', this.datasource.datasource_id, 'set-user-entitlements'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
        }
    }

    mandatoryApproval(isMandatory: boolean) {
        // const payload = { required_approval: isMandatory };
        // let colAdmin = false;
        // let colReview = false;
        // let colApprobe = false;
        // let diferentUsers = 0;
        // let filas = 0;
        // this.projectSvc.setApproval(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, payload).subscribe(resp => {
        //     this.isApprovalRequired = isMandatory;
        //     const dataSet = this.rightGrid.dataView.getItems();
        //     dataSet.forEach(row => {
        //         row['isApprovalRequired'] = isMandatory;
        //         row['isApproverChecked'] = isMandatory ? row['isApproverChecked'] : false;
        //         if (row['isAdminChecked'] === true && colAdmin === false) {
        //             colAdmin = true;
        //             diferentUsers++;
        //         }
        //         if (row['isReviewerChecked'] === true && colReview === false) {
        //             colReview = true;
        //             diferentUsers++;
        //         }
        //         if (row['isApproverChecked'] === true && colApprobe === false) {
        //             colApprobe = true;
        //             diferentUsers++;
        //         }
        //         if (row['isAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
        //             filas++;
        //         }
        //         this.rightGrid.gridService.updateDataGridItem(row, false);
        //     });
        //     if (isMandatory) {
        //         if (diferentUsers > 2 && filas > 2) {
        //             this.nextstepbtn = true;
        //         } else {
        //             this.nextstepbtn = false;
        //         }
        //     } else {
        //         if (diferentUsers > 1 && filas > 1) {
        //             this.nextstepbtn = true;
        //         } else {
        //             this.nextstepbtn = false;
        //         }
        //     }
        // }, err => {
        // });
    }

    removeEntityUser(users) {
        this.checkIsAdmin();
        if (users.length > 0) {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogId,
            payload: {
                body: {
                    'users': users.map(user => user.user_id)
                }
            }
        }
        this.projectService.deleteUserFromCatalog(data)
        .subscribe(delResp => { 
            this.enableApplyBtn = false;
        }, err => {
            this.messageSvc.sendMessage({ message: 'Delete Entity User Failed', type: 'ERROR', hideboard: true });
        });
    }
    }

    updateEntityUsers() {
        this.checkIsAdmin();
        if (this.updateUserList.length > 0) {
        this.datasourceSvc.updateEntityUsers({user_id: this.loggedInUserDetails['user_id'], catalogId: this.catalogId, semanticId: this.semanticId}, this.updateUserList)
        .subscribe(res => {
            this.updateUserList = [];
            this.enableApplyBtn = false;
        }, err => {
            this.messageSvc.sendMessage({ message: 'Update has failed', type: 'INFO', hideboard: true });
        });
    }
    }

    getDuplicateUsers(left, right) {
        return left.filter(leftUser => {
          return !right.some(rightUser => {
            return leftUser.user_name === rightUser.user_name;
          });
        });
      }
    
}
