import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from './../project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { ObservableService } from '../../../../common/services/observable.service';
import { MessageService } from 'src/app/common/components/message/message.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { ContentService } from '../../content.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'zetta-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  private _userinfo;
  modeState: any = {};
  object_types:any=[];
  usersData: any = [];
  object_type_id:number;
  project_id:number;
  public openTaskDataSource;
  asOfDate: any = '';
  entityId: any;
  entity_name: string;
  lowThresholdValue: boolean = false;
  thresholdValue: number;
  disableThresholdValue: boolean;
  lowDqScore: boolean = true;
  currentExecuting: boolean = true;
  publishedGoldenRecords : boolean = false;
  onDestroy$: Subject<void> = new Subject();
  projectValues = {};
  projDetails: object = {};
	public entitiesMasteredChartColors = ['#4bb100', '#E4E7F4',];
    public openTaskChartOptions: object = {
      'resizeRequired': true,
      'category': 'category',
      'variable': 'count',
      'labelPercentValue': 0,
      'labelPercentLabel': 'Data Quality',
      'labelPercentLabel2': 'Measure',
      'isMoneyFormat': true,
    };
  constructor(public activeRoute: ActivatedRoute,
    private _projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    public observeSvc: ObservableService,
    private messageSvc: MessageService,
    private clContentService: ZsClContentService,
    private contentService: ContentService,
    private router: Router) { }

  ngOnInit() {
    this._userinfo = this._projectService.getObjectFromSession('userInfo');
    const routeParams = this.activeRoute.snapshot.params;
    this.project_id=routeParams.id;
    this.object_type_id = +sessionStorage.getItem('project_type_id');  
    this.isJobActive(this._userinfo.user_id, this.project_id, this.object_type_id)
    this._projectService.getProject(this._userinfo.user_id, routeParams.id, {basic:true})
    .subscribe(res => {
      if(res) {
        this.entityId = res[0].entity_id;
      }
    })
    this._projectService.getProject(this._userinfo.user_id, routeParams.id).subscribe(resp => {
        const settingsValue = resp['entitlement']['project'];
      this.usersData = resp['users'];
      this.entity_name = resp.entity_name;
        sessionStorage.setItem('project_entitlements', JSON.stringify(settingsValue));
        this.observeSvc.setCurrentEntitlements = settingsValue;

        if (!(resp.mapped_attributes_count && resp.mapped_attributes_count > 0)) {
          this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
        } else {
          this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
        }
        this.entityId = resp.entity_id;
        this.projDetails = resp;
      }, err => {
      });
      this._projectService.getProjectDqScroce(this._userinfo.user_id, this.project_id)
      .subscribe(res => {
      this.asOfDate = new Date(res[1].count).getMonth() +1  +'/' + new Date(res[1].count).getDate() +'/'+new Date(res[1].count).getFullYear();
      this.openTaskDataSource = this.getDonutParseData(res);
      });
      this._projectService.getProjectPublishStatus( this._userinfo.user_id, this.project_id ).subscribe(resp => { 
        this.lowDqScore = false;
      if(resp && parseInt(resp['publish_status']) == AppGlobals.GOLDEN_RECORDS_PUBLISHED_STATUS) {
         this.lowDqScore = false;
      } else {
        this.lowDqScore = true;
      this.clContentService.thresholdValue
      .subscribe(res => {
        if (res) {
          this.thresholdValue = Number(res);
        }
      });
      this.clContentService.matcherConfidenceValue
      .subscribe(matcher => {
        if(matcher) {
          this.checkForThreshold();
        }
      });
        }
      });
  }
 
  isJobActive(user_id, project_id, object_type_id) {
    this._projectService.getCurrentActiveJobs(user_id, project_id, object_type_id).subscribe(jobs => {
      this.zettaUtils.isFileNotFound=false;
        if (this._projectService.hasActiveJob(jobs)) {
          // this.messageSvc.sendMessage({ message: AppGlobals.ACTIVE_JOB_ERROR, type: 'ERROR', hideboard: false });
          this.messageSvc.sendMessage({ message: 'This project has an active job.', type: 'WARN', hideboard: true });
          this.router.navigate(['/zs/projects', project_id]);
          return;
        }
      },error=>{
        if(error.status===404){
          this.zettaUtils.isFileNotFound=true;
        }
      });
  }


  getDonutParseData(data) {
    const dqData = [];
    data.forEach((element) => {
      if (element.name === AppGlobals.DQ_SCORE_LABEL) {
        if (element.count > 0) {
          let percentVal = Number(element.count * 100).toFixed(2);
          percentVal = percentVal == '100.00' ? '100' : percentVal;
          if(Number(percentVal) >= 75) {
            this.entitiesMasteredChartColors = ['#4bb100', '#E4E7F4'];
          } else if ( Number(percentVal) >= 50 && Number(percentVal) < 75) {
            this.entitiesMasteredChartColors = ['#eeb049', '#E4E7F4'];
          } else {
            this.entitiesMasteredChartColors = ['#d35162', '#E4E7F4'];
          }
          this.openTaskChartOptions["labelPercentValue"] = percentVal;
          dqData.push({
            count: percentVal,
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
          dqData.push({
            count: 100 - Number(percentVal),
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
        } else {
          let percentVal = 0
          this.openTaskChartOptions["labelPercentValue"] = element.count;
          dqData.push({
            count: 0,
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
          dqData.push({
            count: 100 - Number(percentVal),
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
        }
      }
    });
    return dqData;
  }

  navigateToGoldenRecords() {
    this.router.navigate(['/zs/projects/' + this.project_id + '/golden-records'], { queryParams: { entityId: this.entityId, entity_name: this.entity_name }, queryParamsHandling: 'merge' });
  }

  ngOnDestroy(): void {
    this.lowDqScore = true;
    this.currentExecuting = true;
    this.openTaskDataSource;
  }

  updatMergerValues(eve) {
    this.projectValues = eve;
    this.checkForThreshold();
  }

  checkForThreshold() {
  let value = this.thresholdValue;
   let matcher;
   let merger;
   if(this.projectValues['matcherValue'] != undefined) {
    matcher = this.projectValues['matcherValue'];
   }
   if(this.projectValues['mergerValue'] != undefined) {
    merger = this.projectValues['mergerValue'];
   }
   let showPlaceholder = this.projectValues['showMergerPlaceHolder']

    if(matcher == 0 && (merger == 0 || !merger)) {
      this.currentExecuting = true;
      this.lowDqScore = true;
    } else if(showPlaceholder && !(this.projectValues['stageNo'] && this.projectValues['stageNo'] == 4)) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    } else if(!showPlaceholder && !merger) {
      this.currentExecuting = true;
      this.lowDqScore = true;
    }  else if(!showPlaceholder && merger < value) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    }  else if(merger >= value) {
      this.currentExecuting = false;
      this.lowDqScore = false;
    } else if((matcher < value && (!merger || merger == 0))) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    } else if(matcher < value || merger < value) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    } else if(merger > 0 && merger < value) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    } else if(matcher >= value && merger < value) {
      this.currentExecuting = false;
      this.lowDqScore = true;
    } else {
      this.currentExecuting = true;
      this.lowDqScore = false;
    }

  }
}
