import { AppGlobals } from '../../common/shared/app.globals';
import { Injectable } from '@angular/core';
import { BaseService } from '../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Entity, Attribute } from './entities/entity.model';

@Injectable({
	providedIn: 'root'
})
export class ContentService extends BaseService {

	constructor(private http: HttpClient) { super() }

	public engine_type_id = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.userInfo).engine_type_id : 0;
	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	getDataSourcesByID(dataSourceId): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/groups/${this.getObjectFromSession(AppGlobals.USER_INFO).group_id}/datasources/` + dataSourceId + `/data`).pipe(
			map(this.extractData));
	}

	getDataSources(pageno, perpage): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/datasources?pageno=${pageno}&pagesize=${perpage}`).pipe(
			map(this.extractData));
	}

	getSummaryByDataSourcesID(dataSourceId, stageId): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/groups/${this.getObjectFromSession(AppGlobals.USER_INFO).group_id}/datasources` + dataSourceId + `/data/summary?stage=` + stageId).pipe(
			map(this.extractData));
	}

	getEntitiesSettings(entityId): Observable<Object> {
		return this.http.get(`${this.apiUrl}/entities/${entityId}`).pipe(
			map(this.extractData));
	}

	updateEntitiesSettings(entityId, entityattributesList): Observable<Object> {
		return this.http.put(`${this.apiUrl}/entities/${entityId}/entityattributes`, entityattributesList);
	}

	public getTenants(userId: string, pageno, perpage, serverfilter='',sortfilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants?pageno=${pageno}&pagesize=${perpage}${serverfilter}${sortfilter}`);
	}

	public getTenant(getData: any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}`);
	}

	public editTenant(getData: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}`, getData.payload);
	}

	public uploadLogo(getData: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}/logo`, getData.payload);
	}

	public getLogo(userId: string, tenantId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/logo`, { responseType: 'blob' });
	}

	public saveTenant(editData: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${editData.user_id}/tenants/${editData.tenant_id}`, editData.payload);
	}

	public deleteTenant(deleteData): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${deleteData.user_id}/tenants/${deleteData.tenant_id}`, deleteData.payload);
	}

	public createTenant(postData: any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${postData.user_id}/tenants`, postData.payload);
	}

	public getJobSettings(userId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/manage_job_settings`);
	}

	public getSecurityDetails(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups/globalsettings`);
	}

	public getSystemSettings(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups/globalsettings`);
	}

	public getGroupsUsers(getData: any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}/groupusers`);
	}

	public getAdminUsers(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users?is_super_admin=true`);
	}

	public getEntities(pageno, perpage, serverfilter = '', sortfilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/entities?pageno=${pageno}&pagesize=${perpage}${serverfilter}${sortfilter}`);
	}

	public getEntity(entityId: any, retrieveForAllTenantUsers? : boolean, ignoreSystemAttributes?: boolean): Observable<any> {
		let queryString = '';
		if (ignoreSystemAttributes) {
			queryString = '?ignore_system_attributes=true';
		}
		if (retrieveForAllTenantUsers) {
			queryString = queryString && queryString != '' ? `${queryString}&allUsers=true` : '?allUsers=true';
			//return this.http.get(`${this.apiUrl}/entities/` + entityId + `?allUsers=true`);	
		}
		return this.http.get(`${this.apiUrl}/entities/` + entityId + queryString);
	}

	public getEntityTypes(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=EntityTypes`);
	}

	public getEntityIcons(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=EntityIcons`);
	}

	public getClustureManagerTypes(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=ClusterManagerTypes`);
	}

	public deleteEntity(entityId): Observable<any> {
		return this.http.delete(`${this.apiUrl}/entities/${entityId}`);
	}

	saveAttribute(entityId, attribute: Attribute): Observable<any> {
		if (attribute.attribute_id === undefined || attribute.attribute_id === null) {
			return this.http.post(`${this.apiUrl}/entities/` + entityId + `/attributes/`, attribute);
		} else {
			return this.http.put(`${this.apiUrl}/entities/` + entityId + `/attributes/` + attribute.attribute_id, attribute);
		}
	}

	updateAttribute(entityId, attribute_id, payload): Observable<any> {
		return this.http.put(`${this.apiUrl}/entities/${entityId}/attributes/${attribute_id}?drag_drop_sort_order=true`, payload);
	}

	public deleteEntityAttribute(entityId, attributeId): Observable<any> {
		return this.http.delete(`${this.apiUrl}/entities/${entityId}/attributes/${attributeId}`);
	}

	public getAttributeDataTypes(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=AttributeDataTypes`);
	}

	public getAttributeScrubberTypes(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=AttributeScrubberTypes`);
	}

	public getEntityAttributeMatcherTypes(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=EntityAttributeMatcherTypes`);
	}

	public getEntityData(): Observable<any> {
		let entityAttributeMatcherTypes = this.http.get(`${this.apiUrl}/lookups?group=EntityAttributeMatcherTypes`);
		let entityAttributeScrubberData = this.http.get(`${this.apiUrl}/lookups?group=AttributeScrubberTypes`);
		let entityAttributeDataTypes = this.http.get(`${this.apiUrl}/lookups?group=AttributeDataTypes`);
		return forkJoin([entityAttributeMatcherTypes, entityAttributeScrubberData, entityAttributeDataTypes])
        .pipe(map(([entityAttributeMatcherTypes, entityAttributeScrubberData, entityAttributeDataTypes]) => ({entityAttributeMatcherTypes, entityAttributeScrubberData, entityAttributeDataTypes})));
	}

	cloneEntity(entity): Observable<any> {
		return this.http.post(`${this.apiUrl}/entities/copy`, entity);
	}

	saveEntity(entity: any): Observable<any> {
		if (entity.entity_id === undefined || entity.entity_id === null) {
			return this.http.post(`${this.apiUrl}/entities`, entity, { observe: 'response' });
		} else {
			return this.http.put(`${this.apiUrl}/entities/` + entity.entity_id, entity);
		}
	}

	public uploadEntityFile(catalogID: any, isResolve?, entityId?: any) {
		if (isResolve) {
			return `${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogID}/entities/${entityId}/attributes/import`;	
		}
		return `${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogID}/import`;
	}

	forgetPassword(payload: any): Observable<any> {
		return this.http.post(`${this.apiUrl.split("/api")[0]}/resetpassword`, payload);
	}

	resetPassword(payload: any,token): Observable<any> {
		return this.http.put(`${this.apiUrl.split("/api")[0]}/resetpassword?token=${token}`, payload);
	}

	changePassword(payload: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/changepassword`, payload);
	}


	//Retreiving the notification data in grid
	public getNotificationGridData(userId: string, pageno, perpage): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/activities?pageno=${pageno}&pagesize=${perpage}`);
	}

	//Retreiving the badge number
	public getNotificationCount(userId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/activities/badges`);
	}

	public getLatestJobQueueStatusForDownloadingGraph(objectData): Observable<any> {
		
		let self = this;
		return forkJoin(objectData.map((val) => {
			// pass algo id dataset -> 419, catalog -> 421
			let algo_id;
			if (val.objectTypeId === AppGlobals.OBJECT_TYPE_ID_CATALOG) {
				algo_id = '421';
			}
			if (val.objectTypeId === AppGlobals.OBJECT_TYPE_ID_DATSET) {
				algo_id = '419';
			}
			return this.http.get(`${this.apiUrl}/algos/queue/status?object_id=${val.objectId}&object_type_id=${val.objectTypeId}&algo_id=${algo_id}`);
		})).pipe(
			map((queuData, index) => {
				return queuData.map((queue) => {
					if ((!queue) || (queue && queue['status'] != 'pending')) {
						if (queue) {
							queue['object_id'] = objectData[index].objectId ;
							queue['object_type_id'] = objectData[index].objectTypeId;
							queue['jobQueueCleared'] = true;
						} else {
							queue = {
								'object_id': objectData[index].objectId,
								'object_type_id': objectData[index].objectTypeId,
								'jobQueueCleared': true
							}
						}
					}
					return queue;
				})
			})
		);
	}
	public getLatestJobStatusForDownloadingGraph(jobData, userId, jobId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/${jobId}/track?object_type_id=${jobData['object_type_id']}&object_id=${jobData['object_id']}`)
	}
	public downloadGraph(jobId,userId) {
		return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/${jobId}/download`, { responseType: 'blob' })
	}
	public triggerDownloadGraphAlgoApi(objectId, objectTypeId) {
		// let reqObj = {
		// 	object_type_id: objectTypeId
		// }
		// if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_DATSET) {
		// 	reqObj['dataset_id'] = objectId;
		// } else if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_CATALOG) {
		// 	reqObj['catalog_id'] = objectId;
		// }
		// only supporting download graph for Dataset and Catalog
		if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_DATSET) {
			return this.http.post(`${this.apiUrl}/algos/419`, { dataset_id: objectId ,object_type_id: objectTypeId});
		} else if (objectTypeId === AppGlobals.OBJECT_TYPE_ID_CATALOG) {
			return this.http.post(`${this.apiUrl}/algos/421`, { catalog_id: objectId ,object_type_id: objectTypeId});
		}
		//return this.http.post(`${this.apiUrl}/algos/419`, {object_id: objectId, object_type_id: objectTypeId});
		
	}
	public getLatestJobIdForObject(userId, objectId, objectTypeId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/latest?object_type_id=${objectTypeId}&object_id=${objectId}`);
    }


	//checking notifications read or not
	public notificationview(userId: string): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${userId}/activities/read`, {});
	}

	//Retreiving the notification data in grid
	public getFilteredGridData(userId: string, pageno, perpage, shortname = ''): Observable<any> {
		if (shortname) {
			return this.http.get(`${this.apiUrl}/users/${userId}/activities?short_name=${shortname}&pageno=${pageno}&pagesize=${perpage}`);
		} else {
			return this.http.get(`${this.apiUrl}/users/${userId}/activities`);
		}


	}
	public getApps(userId: string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/apps`);
	}
	public runModel600(entity_id): Observable<any> {
		let api_url = `${this.apiUrl}/algos/600`;
		if (this.engine_type_id == 452) {
			api_url = `${this.apiUrl}/algos/407`;
		}
		return this.http.post(api_url, { 'entity_id': entity_id });
	}

	public runHeuristicBuilder(entity_id): Observable<any>{
		let api_url = `${this.apiUrl}/algos/407`;
		return this.http.post(api_url, { 'entity_id': entity_id });
	}

	public getEntityAttributes(entity_id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}/entities/${entity_id}/attributes`);
	}

	public getActivityHistory(user_id, pageno, perpage,serverFilter='', sortfilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${user_id}/activities?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`);
	}

	public getUserList() {
		return this.http.get(`${this.apiUrl}/users`);
	}

	public getActivityList(user_id) {
		return this.http.get(`${this.apiUrl}/users/${user_id}/activities/types`);
	}

	public uploadTrainingTasks(data: any) {
		return `${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/tasks/import `;
	}

	public uploadCatalogTasks(data: any) {
		return `${this.apiUrl}/users/${data.user_id}/catalogs/task/import?task_type_id=${data.task_type_id}`;
	}

	public uploadDatasetEntityMapping(data: any) {
		return `${this.apiUrl}/users/${data.user_id}/projects/${data.project_id}/entity/${data.entity_id}/validatedatasetentityimport`;
	}

	tenantAdminUserValidation(userName): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userName}`);
	}

	public uploadClassifyMappingConcept(data: any, query='') {
		return `${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/importmapping${query}`;
	}
	
	public uploadSynonym(data:any){
		return `${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/synonyms/import `;
	}

	public getEntityAttributeDefaultRules(entityId: any, pageNo, pageSize, serverFilter = '', sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/entities/${entityId}/attributesdefaultrules?pagination=true&pageno=${pageNo}&pagesize=${pageSize}${serverFilter}${sortfilter}`);
	}
	public getQuotaTypes() {
		return this.http.get(`${this.apiUrl}/lookups?group=TenantQuota`);
	}

	getConsumptionReport(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota`);
	}

	getSourceQualityRecords(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/summary?quota=source`);
	}

	getSourceQualityDimension(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/dimensions?quota=source`);
	}

	getSourceQualityRunModel(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/runmodel?quota=source`);
	}

	getTargetQualityRecords(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/summary?quota=target`);
	}

	getTargetQualityDimension(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/dimensions?quota=target`);
	}

	getTargetQualityRunModel(): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/quota/dataquality/runmodel?quota=target`);
	}

	public getAdminUsersPaginate(pageNo, pageSize): Observable<any> {
		return this.http.get(`${this.apiUrl}/users?is_super_admin=true&pagination=true&pageno=${pageNo}&pagesize=${pageSize}`);
	}
}
