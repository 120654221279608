import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";


@Component({
    selector: 'zetta-confirmation-message',
    templateUrl: './confirmation-message.component.html',
    styleUrls: ['./confirmation-message.component.scss']
})
export class ConfirmationMessageComponent implements OnInit {
    @Input() messageHeader: string;
    @Input() message: string;
    @Input() showConfirmation: boolean;
    @Output() onConfirm = new EventEmitter<object>();
    @Output() onCancel = new EventEmitter<object>();

    ngOnInit(): void {

    }

    onConfirmClick() {
        this.onConfirm.emit({ isDeleted: true });
    }
    onCancelClick() {
        this.onCancel.emit({ isDeleted: true });
    }
}