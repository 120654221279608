import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from '../project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Router } from '@angular/router';
import { MessageService } from '../../../../common/components/message/message.service';

@Component({
	selector: 'zetta-my-projects',
	templateUrl: './my-projects.component.html',
	styleUrls: ['./my-projects.component.scss']
})

export class MyProjectsComponent implements OnInit, OnDestroy {
	public myProjectsDetails: any = [];
	public _userInfo;
	public isLoading = true;
	pageLeft = 1;
	limit = 3;
	public login_user = JSON.parse(sessionStorage.userInfo).entitlements;	
	public project_view_all:any;
	public project_create:any;	
	is_visible_view_all=false;
	is_visible_create=false;
	is_enable_view_all=false;
	is_enable_create=false;
	constructor(
		public service: ProjectService,
		protected zettaUtils: ZettaUtils,
		private router: Router,
		private messageSvc: MessageService) {
		this._userInfo = this.service.getObjectFromSession('userInfo');
	}

	ngOnInit() {
		if (this.login_user) {
			try {
				let user_entitlements=this.login_user[0].entitlement;
				if(this.login_user.length>1){
				  user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
				  if(user_entitlements.length){
					user_entitlements=user_entitlements[0].entitlement;
				  }else{
					user_entitlements=this.login_user[0].entitlement;
				  }
				}
				const my_projects = user_entitlements.screens.filter(item => item.name == 'home')[0].childs.filter(item => item.name == 'my_projects')[0].childs;
				if (my_projects.length) {
					this.project_view_all = my_projects.filter(item => item.name == 'my_projects_view_all_projects');
					this.is_visible_view_all = this.project_view_all[0].visible
					this.is_enable_view_all = this.project_view_all[0].enable
					this.project_create = my_projects.filter(item => item.name == 'my_projects_create_new_project');
					this.is_visible_create = this.project_create[0].visible
					this.is_enable_create = this.project_create[0].enable
				}
			} catch (error) {
				console.log(error);
			}

		}
		if (this._userInfo) {
			this.service.getMyProjectDetails(this._userInfo['user_id'], this.pageLeft, this.limit).subscribe(responseList => {

				this.myProjectsDetails = responseList['currentpage'];

				this.myProjectsDetails.forEach(projectItem => {
					if (!(projectItem.mapped_attributes_count && projectItem.mapped_attributes_count > 0)) {
						projectItem.finishSetup = true;
					} else {
						projectItem.hasJob = true;
						// this.service.getCurrentActiveJobs(this.service.getObjectFromSession('userInfo').user_id, projectItem.project_id).subscribe(jobs => {
						// 		if (jobs.job_completed === undefined) {
						// 			projectItem.hasJob = false;
						// 		}
						// 	},
						// error => {
						// 	// this.messageSvc.sendMessage({ message: 'My projects get current active jobs has failed', type: 'INFO', hideboard: true });
						// });
					}
					projectItem.users = Array.from(new Set(projectItem.users.map(user => user.user_id))).length
				});
				this.isLoading = false;
			},
				error => {
					this.myProjectsDetails = [];
					this.isLoading = false;
				});
		}
	}

	ngOnDestroy() {
		this.messageSvc.clearMessage();
	}

	redirectToProjectDetails(project) {
		if (project.finishSetup) {
			this.router.navigate(['/zs/projects/create'], { queryParams: { id: project.project_id } });
		} else if (project.current_status === "Production"){
			this.router.navigate([`/zs/projects/${project.project_id}/production-stage-details`], { queryParams: { entity_id: project.entity_id, entity_name: project.entity_name}});
  		}  
		// else if (project.finishSetup === undefined && !project.hasJob) {
		// this.service.runModel(project.projectid, 0).subscribe(resp => {
		// 	this.router.navigate(['/zs/projects', project.projectid]);
		// }, err => {
		// 	alert(err.error.message);
		// });
		// }
		else {
			this.router.navigate(['/zs/projects/' + project.project_id]);
		}
	}

	onRunModel(projectItem) {
		this.service.runModel(projectItem.project_id, 0).subscribe(resp => {
			this.router.navigate(['/zs/projects', projectItem.project_id]);
		}, err => {
			// alert(err.error.message);
			this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
		});
	}
}
