import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ObservableService } from '../../services/observable.service';
import { ZettaUtils } from '../../shared/zettaUtils';
import { formatDate } from '@angular/common';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zetta-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements AfterViewInit {

    public donut: any = {};
    public chartHeight: number;
    public chartWidth: number;
    public fontSize: number;
    public isChartInValid = false;
    public $container;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;
    @Input() noDataAlignCenter = false;
    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(public cdRef: ChangeDetectorRef, public observeSvc: ObservableService, public zettaUtils: ZettaUtils) {}

    ngAfterViewInit() {
        this.isChartInValid = (!this.dataDataSource || this.dataDataSource.length === 0) ? true : false;
        if (!this.isChartInValid) {
            this.isChartInValid = (!this.chartOptions || !this.chartOptions['label'] || !this.chartOptions['value']) ? true : false;
        }

        if (!this.chartColors) {
            this.chartColors = ['#0e709f', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }

        this.cdRef.detectChanges();

        if (this.dataDataSource && !this.isChartInValid) {
            setTimeout(() => {
                this.renderChart();
            }, 100);
        }

        const parent = this;
        this.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    renderChart() {
        const parent = this;
        parent.$container = $('#chart_' + parent.chartUniqueID);
        if (parent.chartOptions['height']) {
            parent.chartHeight = parent.chartOptions['height'];
        } else {
            parent.chartHeight = (parent.$container.outerHeight(true) <= 0 ? 50 : parent.$container.outerHeight(true));
        }

        if (parent.chartOptions['width']) {
            parent.chartWidth = parent.chartOptions['width'];
        } else {
            parent.chartWidth = (parent.$container.outerWidth(true) <= 0 ? 250 : parent.$container.outerWidth(true));
        }

        parent.fontSize = (Math.min(parent.chartWidth, parent.chartHeight) / 4);

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        $('#chart_' + parent.chartUniqueID).empty();

        const margin = { top: 5, right: 27, bottom: 20, left: 25 },
            width = parseFloat(parent.chartWidth.toString()) - margin.left - margin.right,
            height = parseFloat(parent.chartHeight.toString()) - margin.top - margin.bottom;

        const rootNode = d3.select('#chart_' + parent.chartUniqueID);
        const tooltip = rootNode.append('div').attr('class', 'toolTip');
        const parseDate = d3.timeFormat('%m/%d/%Y');

        const svg = rootNode.append('svg:svg')
            .attr('width', parent.chartWidth)
            .attr('height', parent.chartHeight)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        parent.dataDataSource.forEach(function(d, i) {
            d.label = d['fulldate'];
            d.value = +d.value;
        });

        parent.dataDataSource.sort(function(a, b) { return a.label - b.label; });

        const lineTooltip = rootNode.append('div').attr('class', 'toolTip');

        // Creates the xScale
        const xScale = d3.scaleTime().range([0, width]);

        // Creates the yScale
        const yScale = d3.scaleLinear().range([height, 0]);

        // Defines the y axis styles
        const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

        // Defines the y axis styles
        const xAxis = d3.axisBottom(xScale).tickFormat(parseDate).tickSizeOuter(0);

        // line function convention (feeds an array)
        const line = d3.line()
            .x(function(d) { return xScale(d.label); })
            .y(function(d) { return yScale(d.value); })
            .curve(d3.curveMonotoneX);

        let max = new Date(d3.max(parent.dataDataSource, function(d) {return d.label; }));
        const min = new Date(d3.min(parent.dataDataSource, function(d) {return d.label; }));

        if (parent.dataDataSource.length === 1 && parseDate(min) === parseDate(max)) {
            const tempDate = new Date(max);
            max = new Date(tempDate.setDate(tempDate.getDate() + 1));
        }

        xScale.domain([min, max]); // .nice();
        yScale.domain([0, d3.max(parent.dataDataSource, function (d) { return d.value + 10; })]); // .nice();

        // Appends the y axis
        const yAxisGroup = svg.append('g')
            .attr('class', 'y_axis d-none')
            .call(yAxis);

        // Appends the x axis
        const xAxisGroup = svg.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);

        // Add the line
        svg.append('path')
            .datum(parent.dataDataSource)
            .attr('fill', 'none')
            .attr('stroke', parent.chartOptions['lineColor'])
            .attr('stroke-width', 2)
            .attr('d', d3.line()
                .x(function(d) { return xScale(d.label); })
                .y(function(d) { return yScale(d.value); })
            ).call(transition);

        // Add the line
        svg.selectAll('myCircles')
            .data(parent.dataDataSource)
            .enter()
            .append('circle')
            .attr('fill', 'red')
            .attr('stroke', 'none')
            .attr('cx', function(d) { return xScale(d.label); })
            .attr('cy', function(d) { return yScale(d.value); })
            .attr('r', 3)
            .on('mouseover', function() { lineTooltip.style('display', 'none'); })
            .on('mouseout', function() { lineTooltip.style('display', 'none'); })
            .on('mousemove', mousemove);

        svg.select('.axis--x').selectAll('line')
            .attr('display', 'none');
        svg.select('.axis--x').selectAll('text')
            .attr('text-anchor', 'start')
            .attr('display', 'none');

        const textItems = $('#chart_' + parent.chartUniqueID + ' .axis--x text');
        textItems.each(function(index) {
            if (index === 0) {
                $(this).attr('x', '-20');
                $(this).attr('text-anchor', 'start').show();
            } else if (index === textItems.length - 1) {
                $(this).attr('text-anchor', 'end').show();
            }
        });

        function transition(path) {
            path.transition()
                .duration(2000)
                .attrTween('stroke-dasharray', tweenDash);
        }

        function tweenDash() {
            const l = this.getTotalLength(),
                i = d3.interpolateString('0,' + l, l + ',' + l);
            return function(t) { return i(t); };
        }

        // Tooltip mouseovers
        function mousemove(d, i) {
        lineTooltip
            .style('left', d3.event.clientX - 35 + 'px')
            .style('top', d3.event.clientY - 35 + 'px')
            .style('display', 'inline-block')
            .html(function() {
                if (parent.chartOptions['isMoneyFormat']) {
                    return '<span>Records: <strong>' + d.value + '</strong> ' + d3.timeFormat('%m/%d/%Y')(d.label) + '</span>';
                } else {
                    return '<span>Run #' + (i + 1) + '<strong> ' + (d.value) + '%</strong></span>';
                }
            });
        }
    }

    onResize() {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }

}
