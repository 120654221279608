import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../../../common/components/project-progress/project-progress.model';
import { DataQualityService } from "../../data-quality.service";
import { AppGlobals } from "src/app/common/shared/app.globals";
import { MessageService } from "../../../message/message.service";
import { ZsClContentService } from "../../../../../zettaclassify/zs-cl-content/zs-cl-content.service";
declare var $: any;
@Component({
    selector: 'zetta-OutOf-Box-Rules',
    templateUrl: './out-of-box-rule.component.html',
    styleUrls: ['./out-of-box-rule.component.scss']
})
export class OutOfBoxRuleComponent implements OnInit {
    progressState = new ProjectStatus();
    dataTypes: [];
    outOfBoxRules: any[];
    selectedooBrules: Object[] = [];
    mustBeDataType = AppGlobals.MUST_BE_DATATYPE;
    mustBeGreater = AppGlobals.MUST_BE_GREATER;
    mustBeLess = AppGlobals.MUST_BE_LESS;
    mustBeAfter = AppGlobals.MUST_COME_AFTER;
    mustBeBefore = AppGlobals.MUST_COME_BEFORE;
    mustBeUnique = AppGlobals.MUST_UNIQE;
    mustBeQuitlier = AppGlobals.MUST_NOT_BE_QUTLIER;
    mustNotBeNull = AppGlobals.NOT_NULL;
    mustBeEqual = AppGlobals.MUST_BE_EQUAl;
    setReference = AppGlobals.SET_REFERENCE;
    isFormValid=false;
    dataTypeLookupId="0";
    mustComeBeforeConceptId="0";
    mustComeAfterConceptId="0";
    mustGreaterThanConceptId="0";
    mustLessThanConceptId="0";
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    selectedRules=[];    
    createNewRule;
    dateConcepts=[];
    numericConcept=[];
    selectedOperators=[];
    isSkiped=false;
    catalog_id:number;
    object_id:number;
    concept_id:number;    
    dq_rule_level:string;
    concept_type:string;
    backTo:string;
    selectedDataType:string;
    showTrainingDataModal: Boolean = false;
    selectedSubConcepts = [];
    selectedDatasets = [];
    displayReferenceData = false;
    selectedDataSourceID = [];
    selectedDataColumnId = [];
    within_source_id = [];
    within_column_id = [];
    isAddReferenceSelected = false;
    uniqueAttributeIds = [];
    rule_id;
    is_clone = false;
    constructor(public zettaUtils: ZettaUtils, private messageSvc: MessageService,
        private router: Router, private route: ActivatedRoute, private dqService: DataQualityService,
        private zsContentSvc:ZsClContentService) { }
    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            this.catalog_id = +params.get('catalog_id');
            this.object_id = +params.get('object_id');
            this.concept_id = +params.get('concept_id');
            this.dq_rule_level = params.get('dqRuleLevel');
            this.concept_type = params.get('concept_type');
            this.backTo = params.get('BackTo');
            const is_clone = params.get('is_clone');
            if (is_clone && is_clone === 'true') {
                this.is_clone = true;
            }
            this.rule_id = params.get('rule_id');
        });
        if (this.router.url.includes('create-bussiness-rule')) {
            this.createNewRule = true;
        }
        this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
        this.progressState.currentStateIndex = 1;
        this.progressState.type = 'create-bussiness-rules';
        this.progressState.type = 'classifySet';
        this.getDataTypes();
        this.getDefaultRules();
        this.getDateConcept();
        this.getNumericConcept();
    }
    
    getDataTypes(){
        this.dqService.getDataQualityDataTypes().subscribe(resp => {
            this.dataTypes = resp;
        }, error => {
            this.messageSvc.sendMessage({message: error.error.message, type: 'ERROR', hideboard: true});
         });
    }
    onNext() {
        if (this.rule_id <= 0) {
            sessionStorage.removeItem('ruleDefintion');
        }
        this.isSkiped=false;
        this.uniqueAttributeIds.push(this.concept_id);
        this.saveRules();
    }
    onCancel(){
        if (this.createNewRule) {
            $('.modal').modal('hide');
            if (this.dq_rule_level == 'concept') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
                }                
            } else if (this.dq_rule_level == 'catalog') {
                if (this.backTo == 'catalog') {
                    this.router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }                
            } else if (this.dq_rule_level == 'object') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }                
            }else {
                if(this.is_clone) {
                    let rule_ids: any = [];
                    rule_ids.push(this.rule_id)
                    this.zsContentSvc.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
                    sessionStorage.removeItem('ruleInfo');
                    sessionStorage.removeItem('ruleInfoClone');
                    sessionStorage.removeItem("tRuleDefinition");
                } 
                this.router.navigate(['/zs-cl/dataquality']);
            }            
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
        }
    }
    onPrevious(){
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/'],{queryParamsHandling:'preserve' });
    }
    getDefaultRules() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            concept_id:this.concept_id
        };
        this.dqService.getOutOfBoxRules(data).subscribe(resp => {
            if (resp) {
                this.outOfBoxRules = resp['currentpage'];
                if(this.outOfBoxRules.length){
                    this.outOfBoxRules.forEach(element => {
                        element.isChecked = false;
                        element.isVisible = true
                        if(((element.rule_name == this.mustBeBefore || element.rule_name == this.mustBeAfter) 
                            && this.concept_type.toLowerCase() !='date') || 
                            ((element.rule_name == this.mustBeGreater || element.rule_name == this.mustBeLess) 
                            && this.concept_type.toLowerCase() !='int')){
                            element.isVisible = false;  
                        }
                    });
                }
            }
        }, error => {
            this.messageSvc.sendMessage({message: error.error.message, type: 'ERROR', hideboard: true});
         });
    }
    getDateConcept(){
        this.zsContentSvc.getConcepts(this.catalog_id,this.object_id, 'date', false).subscribe(resp=>{
         this.dateConcepts = resp;
        },error=>{});
    }
    getNumericConcept(){
        this.zsContentSvc.getConcepts(this.catalog_id, this.object_id, 'int', false).subscribe(resp =>{
           this.numericConcept = resp;
        },error=>{});
    }

    onChange(ischecked,ruleName){      
        this.selectedRules=[];
        this.selectedRules = this.outOfBoxRules.filter(rule => rule.isChecked == true);
        if (ruleName == this.setReference) {
            this.isAddReferenceSelected = ischecked;
            if (!ischecked) {
                if (this.selectedDatasets.length == 0) {                    
                    this.selectedRules = this.outOfBoxRules.filter(rule => rule.isChecked == true && rule.rule_name.toLowerCase() != this.setReference.toLowerCase());
                    this.validateForm();
                }
            }
        }
        this.skipValidation(ischecked,ruleName)
        this.validateForm();
    }
    onSelectDataType(event) {
        this.dataTypeLookupId = event.target.value;
        const dataTypeName = this.dataTypes.filter(type => type['lookup_id'] == this.dataTypeLookupId);
        this.selectedDataType = null;
        if (dataTypeName.length) {
            this.selectedDataType = dataTypeName[0]['name'];
        }
        this.validateOperator(event.target.value, 'datatype');
        this.validateForm();
    }
    onSelectBeforeConcept(event){
        this.mustComeBeforeConceptId = event.target.value;
        this.validateOperator(event.target.value, 'before');
        this.validateForm();
    }
    onSelectAfterConcept(event){
        this.mustComeAfterConceptId = event.target.value;
        this.validateOperator(event.target.value, 'after');
        this.validateForm();
    }
    onSelectGreaterThanConcept(event){
        this.mustGreaterThanConceptId = event.target.value;
        this.validateOperator(event.target.value, 'greater');
        this.validateForm();
    }
    onSelectLessThanConcept(event){
        this.mustLessThanConceptId = event.target.value;
        this.validateOperator(event.target.value, 'less');
        this.validateForm();
    }
    onSelectAddReference(values){
        this.validateOperator(values, 'add-ref');
        this.validateForm();
    }

    validateOperator(value, operator:string){
        if(value !="0"){
            if(this.selectedOperators.length){
                const operators = this.selectedOperators.filter(opt => opt == operator);
                if(!operators.length){
                  this.selectedOperators.push(operator);
                }         
            }else{
               this.selectedOperators.push(operator);
            }
         }else{
           if(this.selectedOperators.length){
              this.selectedOperators = this.selectedOperators.filter(opt => opt != operator);
           }
         }
    }

    validateForm(){
        if(this.selectedRules.length && this.selectedRules.length == this.selectedOperators.length){
            this.isFormValid = true;
        }else{
            this.isFormValid = false;
            return;
        }              
    }

    skipValidation(ischecked, ruleName){
        // Will modify these condition after getting requirement for these feild validation
       if(ischecked && ruleName.toLowerCase() == this.mustBeEqual.toLowerCase()){
            this.validateOperator(1, 'equal');
        }if(!ischecked && ruleName.toLowerCase() == this.mustBeEqual.toLowerCase()){
            this.validateOperator(0, 'equal');
        }if(ischecked && ruleName.toLowerCase() == this.mustNotBeNull.toLowerCase()){
            this.validateOperator(1, 'not-null');
        }if(!ischecked && ruleName.toLowerCase() == this.mustNotBeNull.toLowerCase()){
            this.validateOperator(0, 'not-null');
        }if(ischecked && ruleName.toLowerCase() == this.mustBeQuitlier.toLowerCase()){
            this.validateOperator(1, 'outlier');
        }if(!ischecked && ruleName.toLowerCase() == this.mustBeQuitlier.toLowerCase()){
            this.validateOperator(0, 'outlier');
        }if(ischecked && ruleName.toLowerCase() == this.mustBeUnique.toLowerCase()){
            this.validateOperator(1, 'unique');
        }if(!ischecked && ruleName.toLowerCase() == this.mustBeUnique.toLowerCase()){
            this.validateOperator(0, 'unique');
        }
    }

    onSkipStep() {
        if (this.rule_id <= 0) {
            sessionStorage.removeItem('ruleDefintion');
        }
        this.isSkiped = true;
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/business-rule-definition'], {queryParams:{ is_skiped: this.isSkiped }, queryParamsHandling: 'merge'});
    }

    saveRules() {
        let data = [];
        this.selectedRules.forEach(element => {
            this.selectedooBrules.push(element.data_quality_rule_id);
        });
        this.selectedRules.forEach(rule => {
            let souceConcept = {};          
            let mergedObj: any;
            let attribute_id = null;
            if (rule.rule_name == this.mustBeGreater)
                attribute_id = this.mustGreaterThanConceptId;
            if (rule.rule_name == this.mustBeLess)
                attribute_id = this.mustLessThanConceptId;
            if (rule.rule_name == this.mustBeAfter)
                attribute_id = this.mustComeAfterConceptId;
            if (rule.rule_name == this.mustBeBefore)
                attribute_id = this.mustComeBeforeConceptId;
            const defaultObj ={
                "rule_id": rule.rule_id,
                "enity_id": this.object_id,
                "lookup_id": rule.lookup_id,
                "attribute_id": this.concept_id,
                "operator_lookup_id": null,
                "calculation": null,
                'name':rule.rule_name,
                'description':rule.description,
                "data_quality_rule_sequence_id": rule.data_quality_rule_sequence_id,
                "comparision": {
                    "entity_id": attribute_id ? this.object_id :null,
                    "attribute_id": +attribute_id || null,
                    "calculation": null
                }
            }
            if(this.displayReferenceData && rule.rule_name == this.setReference) {
                defaultObj['within_source_id'] = this.within_source_id; 
                defaultObj['within_list_column_id'] = this.within_column_id;
            }
            let dataTypeObj={};
            if (rule.rule_name == this.mustBeDataType ){
                dataTypeObj={
                  "threshold": this.selectedDataType
                };
            }
            mergedObj ={...defaultObj, ...souceConcept, ...dataTypeObj}
            data.push(mergedObj);
        });
        this.zsContentSvc.saveOutOfBoxRule(data).subscribe(resp => {
            if (this.createNewRule) {
                const rule_ids=[];
                this.selectedRules.forEach(rule =>{
                  const rules =  resp.find(res => res.default_rule_lookup_id === rule.lookup_id);
                  if(rules){
                    rule_ids.push(rules.data_quality_rule_id);
                  }
                });                 
                this.router.navigate(['/zs-cl/rules/create-bussiness-rule/business-rule-definition'], {queryParams:{is_skiped:this.isSkiped,selectoobRule:rule_ids,attributeIds: this.uniqueAttributeIds.toString()}, queryParamsHandling: 'merge' });
            } else {
                this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/business-rule-definition'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id } });
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }

    onRefenceDataOpen(rule) {
        if(!rule.is_rule_exists_with_concept) {
            this.showTrainingDataModal = true;
        } else {
            return;
        }
    }

    updateData(eve) {
        if(eve.data && eve.data.length>0) {
            this.selectedDatasets = eve.data;
            this.displayReferenceData =true;
            this.selectedDataSourceID = this.selectedDatasets.map(dataset => dataset.description);
            this.selectedDataColumnId = this.selectedDatasets.map(datset => datset.logical_name);
            this.within_source_id = this.selectedDatasets.map(datset => datset.dataset_id);
            this.within_column_id = this.selectedDatasets.map(datset => datset.dataset_column_id);
            this.onSelectAddReference(this.within_source_id[0]);
            this.selectedDatasets.forEach(element => {
                element.id = Math.random();
            });
            this.validateSetReference(true);
        } else {
            this.selectedDatasets = null;
            this.displayReferenceData = false;
            this.validateSetReference(false);
        }
    }

    validateSetReference(isChecked) {
        this.outOfBoxRules.forEach(rule => {
            if (rule.rule_name.toLowerCase() == this.setReference.toLowerCase()) {
                rule.isChecked = isChecked;
                this.isAddReferenceSelected = true;
            }
        });
        this.selectedRules = this.outOfBoxRules.filter(rule => rule.isChecked == true);
        this.skipValidation(this.isAddReferenceSelected, this.setReference);
        this.validateForm();
    }
    hideOverlay(eve) {
        this.showTrainingDataModal = false;
    }
    onDeleteRef(row) {
        this.selectedDatasets = this.selectedDatasets.filter(ds => ds.id !== row.id);
        this.within_source_id = this.selectedDatasets.map(datset => datset.dataset_id);
        this.within_column_id = this.selectedDatasets.map(datset => datset.dataset_column_id);
        if (this.selectedDatasets.length === 0) {
            this.selectedOperators = this.selectedOperators.filter(op => op !== 'add-ref');
            this.validateForm();
        }
    }
}