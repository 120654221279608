import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../../../../zettasense/content/content.service';

@Component({
  selector: 'zetta-security-management',
  templateUrl: './security-management.component.html',
  styleUrls: ['./security-management.component.scss']
})
export class SecurityManagementComponent implements OnInit {

  public security: any;
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	is_visible_security=false;	
	is_enable_security=false;	
  constructor(private router: Router, public contentSvc: ContentService) {
    if (this.login_user) {
      try {
        let user_entitlements = this.login_user[0].entitlement;
        if (this.login_user.length > 1) {
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if (user_entitlements.length) {
            user_entitlements = user_entitlements[0].entitlement;
          } else {
            user_entitlements = this.login_user[0].entitlement;
          }
        }
        const admin = user_entitlements.screens.filter(item => item.name == 'admin')[0];
        if (admin) {
          const security_magage = admin.childs.filter(item => item.name == 'manage_security')[0];
          this.is_visible_security = security_magage.visible;
          this.is_enable_security = security_magage.enable;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnInit() {
    this.contentSvc.getSecurityDetails().subscribe((data) => {
      this.security = data;
    });
  }

  getManageJobs() {
    this.router.navigate(['/zs/jobs']);
  }

}
