import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { DatasetService } from '../../../../../common/components/datasets/dataset.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDataset } from '../../../../../common/components/datasets/dataset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { dateFormatter } from '../../../../../common/shared/formatters/dateFormatter';
import { ObservableService } from '../../../../../common/services/observable.service';
import { ZmContentService } from '../../../zm-content.service';
declare var $: any;

@Component({
  selector: 'zetta-workbook-description',
  templateUrl: './workbook-description.component.html',
  styleUrls: ['./workbook-description.component.scss']
})

export class WorkbookDescriptionComponent implements OnInit {

  activityHistory: any = [];
  grid: AngularGridInstance;
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  historyTableColDef: any;
  gridOptions: any;
  createWorkbookForm: FormGroup;
  isDataReady = true; 
  totalAttributes: number;
  
  isLoading = true;
  page = 1;
  limit = 15;
  noData = false;
  hasScrolled = false;
  isNotDataAdmin: boolean = true;
  tagsList: any = [];

  workbook_id;
  workbook: any;
  totalWorkbooks;

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private zettaUtils: ZettaUtils,
              private router: Router,
              public observeSvc: ObservableService,
              private workbookSvc: ZmContentService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.workbook_id = params['id'];
    });
    
    this.getWorkbook(this.workbook_id);

    this.historyTableColDef = [{
      'displayname': 'Date',
      'physicalname': 'date',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 60,
      // 'formatter': dateFormatter
    }, {
      'displayname': 'User',
      'physicalname': 'user',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 80,
    }, {
      'displayname': 'Activity',
      'physicalname': 'activity',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 300,
      // 'maxWidth': 500,
    }, {
      'displayname': 'Version',
      'physicalname': 'version',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 40,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
  }

  getWorkbook(workbookId) {
    this.workbookSvc.getWorkbooks(this.loggedInUserDetails.user_id).subscribe((data) => {
      this.workbook = data.filter(item => item.WorkBookId == workbookId)[0];
      this.workbook.num_users = Math.floor(Math.random() * 8) + 1;
      this.workbook.num_ds = Math.floor(Math.random() * 4) + 1;
      this.workbook.num_records = Math.floor((Math.random() * (9000000 - 100000)) + 100000);
      this.workbook.num_model_runs = Math.floor(Math.random() * 14) + 1;
      this.isLoading = false;
      this.setWorkbookForm();
    }, err => { });
  }

  getActivityHistoryData(pageNo, limit = 1) { 
    this.workbookSvc.getWorkbooksList().subscribe((data) => {
      console.log(data)
      this.activityHistory = data['currentpage'][0]['activityHistory'];
      if (this.activityHistory.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      let concatedRows = this.activityHistory;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.activityHistory];
        this.activityHistory.forEach(element => {
          element.id = Math.random();
        });
        this.activityHistory = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
    });
  }

  clearActivity() {
    this.grid.dataView.setItems([]);
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onNext(): void {
    this.page++;
    this.getActivityHistoryData(this.page, this.limit);
  }

  viewHistoryClick() {
    this.getActivityHistoryData(this.page, this.limit);
  }

  setWorkbookForm() {
    this.createWorkbookForm = this.formBuilder.group({
      Description: [this.workbook.Description]
    });
  }

  onSave() {
    // this.isDataReady = true;
    // if (this.createWorkbookForm.valid) {
    //   const datasetTemp: IDataset = {
    //     user_id: this.loggedInUserDetails.user_id,
    //     tenant_id: this.loggedInUserDetails.tenant_id,
    //     dataset_id: this.dataset.dataset_id,
    //     payload: {
    //       'description': this.createWorkbookForm.controls.description.value
    //     }
    //   };
    //   this.datasetSvc.editDataset(datasetTemp).subscribe(responseList => {
    //     this.isDataReady = false;
    //     window.location.reload();
    //   }, err => {
    //   });
    // }
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

}
