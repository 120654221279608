import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { Project } from '../../classify.model';
import { ZsClContentService } from '../../zs-cl-content.service'
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/common/components/message/message.service';
declare var $: any;

const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';
const ATLEAST_ONE_ADMIN_MSG = 'There must be another project admin assigned before you can be removed from the project';
const ATLEAST_ONE_USER_MUST_SELECTED = 'Please select one or more users and click the Add button'
const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
 
  if (cell == 4) {
    return value ?
      `<label class="mr-left-30 classify-add-users-checkbox check-cell">
        <input type="checkbox" checked> 
       </label>` :
      `<label class="mr-left-30 classify-add-users-checkbox check-cell">
        <input type="checkbox">  
      </label>`;
  } else {
    return value ?
      `<label class="ml-2 classify-add-users-checkbox check-cell">
        <input type="checkbox" checked> 
      </label>` :
      `<label class="ml-2 classify-add-users-checkbox check-cell">
        <input type="checkbox">  
      </label>`;
  }
}

const reviewerCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (cell == 4) {
    return value ?
      `<label class="mr-left-30 classify-add-users-checkbox check-cell">
        <input type="checkbox" checked> 
       </label>` :
      `<label class="mr-left-30 classify-add-users-checkbox check-cell">
        <input type="checkbox">  
      </label>`;
  } else {
    return value ?
      `<label class="ml-3 classify-add-users-checkbox check-cell">
        <input type="checkbox" checked> 
      </label>` :
      `<label class="ml-3 classify-add-users-checkbox check-cell">
        <input type="checkbox">  
      </label>`;
  }
}
export const checkBoxFormattter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  value ?
    `<label class="ml-4">
      <input type="checkbox" checked> 
    </label>` :
    `<label class="ml-2">
      <input type="checkbox">  
    </label>`;

export const disabledCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  dataContext.isApprovalRequired ? (value ? `<label class="ml-3 classify-add-users-checkbox check-cell apprcheck">
    <input type="checkbox" checked></label>` :
    `<label class="ml-3 classify-add-users-checkbox check-cell aprv">
        <input type="checkbox"></label>`) :
    `<label class="ml-3 classify-add-users-checkbox check-cell aaprv">
        <input type="checkbox" disabled></label>`;

export const datasetNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
      if(dataContext.checkForDisableCond) {
        return `<div class="disable-row">${value}</div>`;
      } else {
        return `<div>${value}</div>`;
      }
    }

export const lastNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let blur = value == null ? 'blur' : '';
  return `<div class='${blur}'>${value}</div>`;    
}

@Component({
  selector: 'zetta-zs-cl-project-add-users',
  templateUrl: './zs-cl-project-add-users.component.html',
  styleUrls: ['./zs-cl-project-add-users.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ZsClProjectAddUsersComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  progressState = new ProjectStatus();
  leftGrid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  leftGridDataSource: any = [];
  rightGridDataSource: any = [];
  gridColumnsDef: any;
  rightGridColumnsDef: any;
  leftGridRows = 0;
  rightGridRows = 0;
  leftGridOptions: any;
  rightGridOptions: any;
  showBreadcrumb = false;
  // project_id;
  tableSettings: object = { 'height': "100%", 'width': "99.40%" };
  rightTableSettings: object = { 'height': "100%", 'width': "99.40%" };
  loggedInUserDetails: [];
  selectedUserRoles: any = [];
  errorRibbon = false;
  nextstepbtn = false;
  isApprovalRequired = false;
  isApproved = false;
  modeState: any = {};
  object_type_id: number;
  isDataReady = false;
  projectDetail: Project = new Project();
  projectData:any;
  isUserDataReady = false;
  project_type_id:number;
  hasLeftUsersSelected: Boolean = false;
  hasRightUsersSelected: Boolean = false;
  ever_executed = false;
  isEditProject: string;
  user_role_validation_msg = AppGlobals.USER_ROLE_VALIDATION_MSG;
  constructor(public zettaUtils: ZettaUtils,
    public ZsClContentService: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.object_type_id = +sessionStorage.getItem('project_type_id');
    //AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_admin') : '';
    //AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_reviewer') : '';
    //AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_approver') : '';
    this.ever_executed = this.activatedRoute.snapshot.queryParamMap.get('ever_executed') === 'true' ? true : false;
    this.isEditProject= this.activatedRoute.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
    this.activatedRoute.queryParamMap.subscribe(param =>{
      this.project_type_id = +param['params']['project_type'];
    });
    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
      noDataMsg: LEFT_EMPTY_GRID_MSG,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
      noDataMsg: RIGTH_EMPTY_GRID_MSG,
    };
    this.getProject();
    this.getUsersList();   
  }

 getProject(){
    const payload = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.projectDetail.project_id
    };
    this.ZsClContentService.getclassifyProject(payload).subscribe(resp => {
      if(resp){
        this.projectData = resp;
        if(this.project_type_id == 14863){
           this.progressState.states = this.zettaUtils.getStateList('create-classify-concept-parser-project');
        }else{
          this.progressState.states = this.zettaUtils.getStateList('create-classify-project');
        }        
        this.progressState.currentStateIndex = 1;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Data Classification Project';
        this.progressState.classificationProject = resp;
        this.progressState.isEdit = this.isEditProject === 'true'
        this.isApproved = this.projectData.four_eye_check;        
      }
      this.isDataReady = true;
    });
  }
  initColDef() {
    this.gridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormatter,
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': lastNameFormatter
    }, {
      'displayname': 'Group Name',
      'physicalname': 'group_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }];
    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': lastNameFormatter
    }, {
      'displayname': 'Project Admin',
      'physicalname': 'isProjectAdminChecked',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxFormattter,
      'maxWidth': 150,
      // 'headerCssClass': 'titleBreak pt-2',
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'isReviewerChecked',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': reviewerCheckBoxFormattter,
      'maxWidth': 80,
    }, {
      'displayname': 'Approver',
      'physicalname': 'isApproverChecked',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': disabledCheckBoxFormattter,
      'maxWidth': 80,
    }];
  }

  getUserRoles(): Object {
    let adminCount = 0;
    let reviewerCount = 0;
    let approberCount = 0;
    let rowUserCount = 0;
    let colUserCount = 0; 
    this.rightGridDataSource.forEach(row => { 
      let countRoles = 0;
      if (!Array.isArray(row['selectedRoles'])){
        row['selectedRoles'] = [];
      }
      row.roles.forEach(roles => {  
        switch (roles.role_id) { 
          case AppGlobals.CLASSIFY_PROJECT_ADMIN:
            row['isProjectAdminChecked'] = true;
            row['isApprovalRequired'] = this.isApprovalRequired; 
            if (!row['selectedRoles'].includes(roles.role_id)) {
                row['selectedRoles'].push(roles.role_id);
            }
            adminCount++;
            countRoles++;
            break;
          case AppGlobals.CLASSIFY_PROJECT_REVEIWER:
            row['isReviewerChecked'] = true;
            row['isApprovalRequired'] = this.isApprovalRequired;
            if (!row['selectedRoles'].includes(roles.role_id)) {
              row['selectedRoles'].push(roles.role_id);
            } 
            reviewerCount++;
            countRoles++;
            break;
          case AppGlobals.CLASSIFY_PROJECT_APPROVER:
            row['isApproverChecked'] = true;
            row['isApprovalRequired'] = this.isApprovalRequired;
            if (!row['selectedRoles'].includes(roles.role_id)) {
              row['selectedRoles'].push(roles.role_id);
            }  
            approberCount++;
            countRoles++;
            break;
          default:
            if (roles.role_id !== 1) {
              row['isProjectAdminChecked'] = false;
              row['isApprovalRequired'] = this.isApprovalRequired;
              row['isReviewerChecked'] = false;
              row['isApproverChecked'] = false;
              row['selectedRoles'] = [];
            }

        }
      });
      if (countRoles > 0) {
        rowUserCount++;
      }
    });

    if (adminCount > 0) {
      colUserCount++;
    }
    if (reviewerCount > 0) {
      colUserCount++;
    }
    if (approberCount > 0) {
      colUserCount++;
    }
    return { colUserCount, rowUserCount }
  }
  getUsersList() {
    this.ZsClContentService.getUsersTenant(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
      this.rightGridDataSource = responseList[1];
      const projectDetail = responseList[2];
      this.rightGridDataSource.forEach(element => {
        if (element.user_id === this.loggedInUserDetails['user_id']) {
          element.roles.forEach(role => {
            if (role.role_id === 1) {
              role.role_id = AppGlobals.CLASSIFY_PROJECT_ADMIN;
            }
          });
        }
      });
      if (projectDetail['four_eye_check']) {
        this.isApprovalRequired = true;
        $('.aaprv input:checkbox').removeAttr('disabled');
        $('.aprv input:checkbox').removeAttr('disabled');
        $('.apprcheck input:checkbox').removeAttr('disabled');
        $('.aprv.slick-row').removeClass('disableClick');
        $('.aaprv.slick-row').removeClass('disableClick');
        $('.apprcheck.slick-row').removeClass('disableClick');
      }
      const userCount = this.getUserRoles();

      this.rightGridRows = this.rightGridDataSource.length;
      this.leftGridDataSource = responseList[0]['currentpage'];      
      this.leftGridDataSource = this.leftGridDataSource.filter(user1 => !this.rightGridDataSource.find(user2 => user1.user_id === user2.user_id));
      this.leftGridRows = this.leftGridDataSource.length;
      this.initColDef();
      this.isUserDataReady = true;
      if (userCount['colUserCount'] > 2 && userCount['rowUserCount'] > 2) {
        this.nextstepbtn = true;
        this.errorRibbon = false;
      } else {
        this.nextstepbtn = false;
      }
      this.checkForDisable();
      this.nextProjStepBtn();
    }, err => {
      // alert('error');
      // this.messageSvc.sendMessage({ message: 'Users get user list has failed', type: 'INFO', hideboard: true });
    });
  }

 
  onRightGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;  

    if (row !== undefined && row !== null) {
      if (fieldName === 'isProjectAdminChecked') {
        row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
        if (row['isProjectAdminChecked']) {
          row['selectedRoles'].push(AppGlobals.CLASSIFY_PROJECT_ADMIN);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CLASSIFY_PROJECT_ADMIN);
        }
      } else if (fieldName === 'isReviewerChecked') {
        row['isReviewerChecked'] = !row['isReviewerChecked'];
        if (row['isReviewerChecked']) {
          row['selectedRoles'].push(AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        }
      } else if (fieldName === 'isApproverChecked') {
        row['isApproverChecked'] = !row['isApproverChecked'];
        if (row['isApproverChecked']) {
          row['selectedRoles'].push(AppGlobals.CLASSIFY_PROJECT_APPROVER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CLASSIFY_PROJECT_APPROVER);
        }
      }
      if(this.isApproved){
        row.isApprovalRequired = true;
      }
      this.enabledButton();      
      this.rightGrid.gridService.updateDataGridItem(row, false);
    }
  }

  enabledButton(){
    const dataSet = this.rightGridDataSource;
    let colAdmin = false;
    let colReview = false;
    let colApprobe = false;
    let diferentUsers = 0;
    let filas = 0;
    
    dataSet.forEach(row => {
      if (row['isProjectAdminChecked'] === true && colAdmin === false) {
        colAdmin = true;
        diferentUsers++;
      }
      if (row['isReviewerChecked'] === true && colReview === false) {
        colReview = true;
        diferentUsers++;
      }
      if (row['isApproverChecked'] === true && colApprobe === false) {
        colApprobe = true;
        diferentUsers++;
      }
      if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
        filas++;
      }
    });
    if (this.isApproved) {
      this.rightGridDataSource.forEach(ele => {
        this.isApprovalRequired = true;
        ele['isApprovalRequired'] = this.isApprovalRequired;
      });
      if (diferentUsers > 2 && filas > 2) {
        this.nextstepbtn = true;
      } else {
        this.nextstepbtn = false;
      }
    } else {
    if(colReview && colAdmin) {
      if (diferentUsers > 1 && filas > 1) {
        this.nextstepbtn = true;
      } else {
        this.nextstepbtn = false;
      }
      } else {
        this.nextstepbtn = false;
      }
    }
  }

  onAdd() {
    const leftGridDataService = this.leftGrid.gridService;
    const rightGridDataService = this.rightGrid.gridService;
    // call service to update DB as bulk operation (pass list of items to be inserted).
    // on success Add to left Grid
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const userList: Object[] = [];
    const rows: any[] = [];

    if (selectedRows.length === 0) {
      this.messageSvc.sendMessage({ message: ATLEAST_ONE_USER_MUST_SELECTED, type: 'ERROR', hideboard: true })
    }
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
      }
    });

    rows.forEach(row => {
      row['selectedRoles'] = [];
      row['isApprovalRequired'] = this.isApprovalRequired;
      this.rightGridDataSource.push(row);
      rightGridDataService.addItemToDatagrid(row, true);
      // remove Item from left grid
      row['checkForDisableCond'] = true;
    });

    // remove row selection from left/right grid
    leftGridDataService.setSelectedRows([]);
    rightGridDataService.setSelectedRows([]);
    // Update Row Count
    this.rightGridRows = this.rightGridRows + userList.length;
    this.checkForDisable();
    // update DB
    // this.updateProject(userList, 'ADD');
    this.enabledButton();
  }

  onRemove() {
    const leftGridDataService = this.leftGrid.gridService;
    const rightGridDataService = this.rightGrid.gridService;
    // call service to update DB as bulk operation (pass list of items to be removed).
    // on success removed from UI
    const selectedRows: number[] = rightGridDataService.getSelectedRows();
    const userList: String[] = [];
    const rows: any[] = [];
    selectedRows.forEach(index => {
      const row = rightGridDataService.getDataItemByRowIndex(index);
      if (row !== undefined && row !== null) {
        row.roles = [];
        rows.push(row);
      }
    });

    const projectAdmin = this.rightGridDataSource.filter(user => user.isProjectAdminChecked === true);
    const selectedAdmin = rows.filter(user => user.isProjectAdminChecked === true);
    const totalAdmin = projectAdmin.length - selectedAdmin.length;
    if (selectedRows.length > 0) {
      if (totalAdmin < 1) {
        this.messageSvc.sendMessage({ message: ATLEAST_ONE_ADMIN_MSG, type: 'ERROR', hideboard: true });
        return false;
      }
    }

    rows.forEach(row => {
      row.isProjectAdminChecked = false;
      row.isApproverChecked = false;
      row.isReviewerChecked = false;
      delete row['isApprovalRequired'];
      row['checkForDisableCond'] = false;
      // remove Item from right grid
      let updatedUserList = this.leftGrid.dataView.getItems();
      if (updatedUserList.length == 0) {
        this.leftGridDataSource.push(row);
        leftGridDataService.addItemToDatagrid(row, true);
      } else {
        let isUserExists = false;
        updatedUserList.forEach(user => {
          if (user.user_id == row.user_id) {
            isUserExists = true;
          }
        });
        if (!isUserExists) {
          this.leftGridDataSource.push(row);
          leftGridDataService.addItemToDatagrid(row, true);
        }
      }
      userList.push(row.user_id);
      this.rightGridDataSource = this.rightGridDataSource.filter(user => user.user_id !== row.user_id);
      rightGridDataService.deleteDataGridItem(row);
    });
    // this.leftGrid.slickGrid.setData();
    leftGridDataService.renderGrid();
    rightGridDataService.renderGrid();
    // remove row selection from left grid
    leftGridDataService.setSelectedRows([]);
    rightGridDataService.setSelectedRows([]);

    this.leftGridRows = this.leftGridRows + userList.length;
    this.rightGridRows = this.rightGridRows - userList.length;
    // this.updateProject(userList, 'DELETE');
    this.checkForDisable();
    this.enabledButton();
  }


  rightGridCreated(grid) {
    const parent = this;
    parent.rightGrid = grid;
    parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasRightUsersSelected = args.rows.length > 0 ? true : false;
      if (parent.isApproved) {
        parent.rightGridDataSource.forEach(ele => {
            if (parent.isApproved) {
                parent.isApprovalRequired = true;
                ele['isApprovalRequired'] = true;
            }
            else {
                parent.isApprovalRequired = false;
                ele['isApprovalRequired'] = false;
            }
        });
    }
    });
  }
  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
    });

    parent.leftGrid.slickGrid.onSort.subscribe((e, args) => {
      this.checkForDisable();
    });

  }

  onCancel() {
    this.router.navigate(['/zs-cl/projects']);
  }

  onPrevious() {
    if (this.isEditProject) {
      this.router.navigate(['/zs-cl/projects/create-project'],
        { queryParams: { 'id': this.projectDetail.project_id, isEditProject: true,ever_executed: this.ever_executed}, queryParamsHandling: 'merge' });
    }
    else {
      this.router.navigate(['/zs-cl/projects/create-project'],
        { queryParams: { 'id': this.projectDetail.project_id } });
    }
  }
  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

  leftGridCount() {
    if (this.leftGrid) {
      return this.leftGrid.dataView.getItems().length;
    } else {
      return this.leftGridDataSource.length;
    }
  }

  rightGridCount() {
    if (this.rightGrid) {
      return this.rightGrid.dataView.getItems().length;
    } else {
      return this.rightGridDataSource.length;
    }
  }

  addUserRole(boolean: boolean) {
    const selectedProjectAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
    const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
    const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
    const emptyUserRoleList = this.rightGridDataSource.filter(item => item.selectedRoles.length === 0);
  
    if(!this.isApproved) {
      if(selectedProjectAdminList.length === 0 || selectedProjectReviewerList.length === 0) {
        this.errorRibbon = true;
      } else {
        this.errorRibbon = false;
        this.saveUserRole(boolean);
      }
    } else {      
          if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
            selectedProjectReviewerList.length === 0 || emptyUserRoleList.length !== 0) {
            this.errorRibbon = true;
          } else {
            this.errorRibbon = false;
            this.saveUserRole(boolean);
          }
  }
}

  saveUserRole(bool: boolean) { 
    this.rightGridDataSource.map(resp => {
     
      if(!this.isApproved) {
        if(resp.selectedRoles  && resp.selectedRoles.length>0) { 
          this.selectedUserRoles.push({ user_id: resp.user_id, roles: resp.selectedRoles });
          this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
        }
      } else {
        this.selectedUserRoles.push({ user_id: resp.user_id, roles: resp.selectedRoles });
        this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
      }
    });
    this.ZsClContentService.saveUserRoles(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id,
      this.selectedUserRoles).subscribe(resp => { 
        if (this.project_type_id == 14863) {
          if(this.isEditProject)
          {
            this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id, 'select-concept-parse'], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id ,isEditProject: true,ever_executed: this.ever_executed }, queryParamsHandling: 'merge' });
          }
          else
          {
            this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id, 'select-concept-parse'], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id } });
          }
          
        } else {
          if (bool) {
            this.router.navigate(['/zs-cl/projects', this.projectDetail.project_id]);
          } else {
            if (this.isEditProject) {
              this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id, 'select-object'], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id, isEditProject: true, ever_executed: this.ever_executed }, queryParamsHandling: 'merge' });
            }
            else {
              this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id, 'select-object'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
            }  
          }
        }
      }, err => {
        this.selectedUserRoles = [];
        this.messageSvc.sendMessage({ message: 'Users save user role has failed', type: 'INFO', hideboard: true });
      });
  }

  updateApproval() {
    let data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      payload: {four_eye_check : this.isApproved},
    };
    this.ZsClContentService.createClassificationProject(data, this.projectDetail.project_id).subscribe(responseList => {
      const dataSet = this.rightGrid.dataView.getItems();      
      if(responseList[0]['four_eye_check']== true) {
        this.isApprovalRequired = true;
        this.isApproved = true;
        $('.aaprv input:checkbox').removeAttr('disabled');
        $('.aprv input:checkbox').removeAttr('disabled');
        $('.apprcheck input:checkbox').removeAttr('disabled');
        $('.aprv.slick-row').removeClass('disableClick');
        $('.aaprv.slick-row').removeClass('disableClick');
        $('.apprcheck.slick-row').removeClass('disableClick');
      } else {
        this.isApprovalRequired = false;
        this.isApproved = false;
        $('.aaprv input:checkbox').attr('disabled', 'disabled');
        $('.aprv input:checkbox').attr('disabled', 'disabled');
        $('.apprcheck input:checkbox').removeAttr('checked');
        $('.apprcheck input:checkbox').attr('disabled', 'disabled');
        $("#apprcheck").prop('checked', false);
        $("#aaprv").prop('checked', false);
        $("#aprv").prop('checked', false);
        this.rightGridDataSource.forEach(row => {
          row['isApproverChecked'] = false;
          row['isApprovalRequired'] = false;
        });
      }
      this.enabledButton();      
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'INFO', hideboard: true });
    });
  }

  onBack() {
    this.router.navigate(['/zs-cl/projects/', this.projectDetail.project_id ],{  queryParams: { showBreadcrumb: 'true' },queryParamsHandling: 'merge' });                 
  }

  
  checkForDisable() {
    if (this.rightGridDataSource) {
    this.leftGridDataSource.forEach(element => {
        this.rightGridDataSource.forEach(rightEle => {
          if(element.user_id == rightEle.user_id) {
            element['checkForDisableCond'] = true;
          } else {
            return;
          }
        });
    });
    }
    setTimeout(() => {
      $('div.disable-row').parent().parent().addClass('disabled-state');
    }, 2000)
  }

  ngAfterViewInit() {
    this.checkForDisable();
  }

  ngAfterViewChecked() {
    this.checkForDisable();
  }

  nextProjStepBtn() {
    let colAdmin = false;
    let colReview = false;
    let colApprobe = false;
    let diferentUsers = 0;
    let filas = 0;
    this.rightGridDataSource.forEach(row => {
        if (row['isProjectAdminChecked'] === true && colAdmin === false) {
            colAdmin = true;
            diferentUsers++;
        }
        if (row['isReviewerChecked'] === true && colReview === false) {
            colReview = true;
            diferentUsers++;
        }
        if (row['isApproverChecked'] === true && colApprobe === false) {
            colApprobe = true;
            diferentUsers++;
        }
        if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
            filas++;
        }
    });

    if (this.isApproved) {
        if (diferentUsers > 2 && filas > 2) {
            this.nextstepbtn = true;
        } else {
            this.nextstepbtn = false;
        }
    } else {
        if (colReview && colAdmin) {
            if (diferentUsers > 1 && filas > 1) {
                this.nextstepbtn = true;
            } else {
                this.nextstepbtn = false;
            }
        } else {
            this.nextstepbtn = false;
        }
    }

}
}
