import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {

  // Run Model
  // 0 create Project--> run model
  // 1 Entities Resolved --> Train model --> Re Run Model
  // 2 Entities Record Mastered --> Train model --> Re Run Model

  private static runModelAlgo = [201, 201, 201];
  private static runModelAlgoJobType = ['loader', 'matcher', 'merger'];

  static APP_ENTITLEMENTS = {
    admin: {
      user: {
        'canAdd': true,
        'canEdit': true,
        'canDelete': true,
        'canSetpassword': true,
      },
      usergroup: {

      },
      dataset: {

      },
      project: {

      }
    },
    user: {

    }
  };

  static USER_INFO = 'userInfo';
  static MATCH_FEEDBACK_STATUS = '1';
  static NO_MATCH_FEEDBACK_STATUS = '0';
  static NOT_SURE_FEEDBACK_STATUS = '2';
  static SUGGESTED_CLUSTER_FEEDBACK_STATUS = '3';

  static PROJECT_ADMIN: number;
  static REVEIWER: number;
  static APPROVER: number;

  static CLOUDSTORAGE = 'Cloud Object Storage';
  static HADOOP = 'Hadoop';
  static RDBMS = 'Relational Database';
  static SFDB = 'Snowflake Data Warehouse';

  static CHAIN_ATTRIBUTES = 'Chain';
  static ISLOGGEDIN = 'isloggedIn';
  static ISZMLOGGEDIN = 'isZmloggedIn';
  static ISMULTIPLE_APP_ACCESS = 'isMultipleAppAccess';
  static ISZSLOGGEDIN = 'isZsloggedIn';
  static ACTIVE_JOB_ERROR = ' Project has active job. Please wait or cancel the job.';
  static INTERAL_SERVER_ERROR = 'Oops! Something went wrong. Please contact support';
  static UNAUTHORIZED_ACCESS_ERROR = 'Unauthorized access!';

  static IS_ZS_LOGIN_PAGE = 'is_zs_login_page';
  static IS_ZM_LOGIN_PAGE = 'is_zm_login_page';

  /*********** User profile ***************/ 
  static PROFILE_UPDATE_MSG='Profile updated successfully!';
  static PROFILE_PIC_MSG='Profile picture uploaded successfully!';
  static PROFILE_PICTYPE='Selected profile type should be PNG, JPG, JPEG only!';
  static PROFILE_SIZE='Selected profile size should be less or equal to 5MB';

  /********Default Msg for Empty grid**********/

  static DEFAULT_EMPTY_GRID_MSG = 'No data found.';
  static DEFAULT_EMPTY_GRID_MSG_STYLE = 'default-empty-grid-msg';
  static DEFAULT_ERROR_MSG = 'Error Occurred';

  /***************** COMMON *****************/
  static FULL = 'full';
  /***************** router URLS *****************/
  // Landing
  static ZETTALABS = 'zl/login';
  static ZETTALABS_PATH = '/zl/login';

  // Base
  static ZETTALABS_BASE = 'zettalabs/main';
  static ZETTALABS_BASE_PATH = '/zettalabs/main';

  // Zettasense Login
  // static ZETTASENSE_LOGIN = 'zs/login';
  // static ZETTASENSE_PATH = '/zs/login';
  static ZETTASENSE_LOGIN = 'zl/login';
  static ZETTASENSE_PATH = '/zl/login';

  // Forgot password
  static ZL_FORGOTPWD = 'forgot-password';

  // Reset password
  static ZL_RESETPWD = 'zl/login/reset-password';

  // Home
  static HOME_BASE = 'zs/home';
  static HOME_ROUTE_BASE = '/zs/home';
  static HOME_DASHBOARD = '/zs/home';

  // Projects
  static PROJECT_BASE = 'zs/projects';
  static PROJECT_CREATE = '/zs/projects';

  // Incubator
  static INCUBATOR_BASE = 'zs/incubator';

  // Datasets
  static DATASET_BASE = 'zs/datasets';
  static DATASET_CREATE = '/zs/datasets';

    //Notifications
    static NOTIFICATION_PATH = 'zs/notifications';

  // login
  // static LOGIN = 'login';
  // static LOGIN_ROUTE = '/login';
  static REGISTER = 'register';
  static FOTGOT_PASSWORD = 'forgot-password';
  static STYLEGUIDE = 'styleguide';
  static RESET_PASSWORD = 'reset-password';
  static USER_SESSION_TIMEOUT = 900;
  static ENTITIES_RESOLVED_JOB_INTERVEL = 60000; // 1000*60*1 : 1 min
  static NUMERIC_REGEX = /\d/;
  static CHARACTERS_REGEX = /[A-Za-z ]+/;
  static MM_DD_YYYY_FORMAT = 'MM/dd/yyyy';

  // Zettaconsume Reports
  static ZETTACONSUME_REPORT = 'zc/reports';

  // Zettamesh Login
  static ZETTAMESH_LOGIN = 'zm/login';
  static ZETTAMESH_PATH = '/zm/login';

  // Zettamesh home
  static ZETTAMESH_HOME = 'zm/home';
  static ZETTAMESH_HOME_PATH = '/zm/home';

  // Zettamesh Projects
  static ZETTAMESH_PROJECT = 'zm/project';
  static ZETTAMESH_PROJECT_PATH = '/zm/project';

  // Zettamesh Data
  static ZETTAMESH_DATA = 'zm/data';
  static ZETTAMESH_DATA_PATH = '/zm/data';

  // Zettamesh Dataset
  static ZETTAMESH_DATASET = 'zm/dataset';
  static ZETTAMESH_DATASET_PATH = '/zm/dataset';

  // Zettamesh Projects
  static ZETTAMESH_TASKS = 'zm/tasks';
  static ZETTAMESH_TASKS_PATH = '/zm/tasks';

  // Zettamesh Projects
  static ZETTAMESH_MANAGEJOBS = 'zm/managejobs';
  static ZETTAMESH_MANAGEJOBS_PATH = '/zm/managejobs';

  // Help
  static ZETTA_HELP = 'zs/help';

  //Edit text
  static editTxt = 'edit';

  // Action text
  static actionTxt ='actions';

  static WHOLE_DATA_TYPE = 'Whole';
  static DEFAULT_USER_SETTING_TEXT='New User Default Settings';

  static CLASSIFICATION_MSG_HEADER=' Data Classification Model in Progress';
  static CLASSIFICATION_MSG_TEXT='The Model has been triggered. You can see job status and monitor progress in the System Jobs screen.';
  static DATASET_MSG_HEADER='Data Set Registration in Progress';
  static DATASET_MSG_TEXT='The process to register and profile your data sets has been triggered. You can see job status and monitor progress for Data Set jobs in the System Jobs screen';
  static CLASSIFICATION_PROJECT='Classification Project';
  static RULES_MSG_HEADER='Data Quality Rule Execution In Progress';
  static RULES_MSG_TEXT = 'The rule has been triggered. You can see job status and monitor progress in the System Jobs screen.';
  static CATALOGS_DELETE_MSG_TEXT = 'Are you sure, this action will disable the data and details of the catalog and cannot be easily reversed ?';
  static CLASSIFY_PROJECT_DELETE_MSG_TEXT = 'Are you sure? This action will delete the Project, disable its data for further use and cannot easily be reversed.';
  static CREATE_DATASET_MSG_HEADER = 'Multiple data set creation in progress.';
  static CREATE_DATASET_MSG_TEXT = 'The process to register and profile multiple data sets has beed triggered. You can track the status of these jobs in the System jobs screen. You can also view its progress by clicking on the View Progress link below.';
  static BULKRULES_MSG_HEADER = 'Data Quality Rule(s) Execution In Progress';
  static BUILRULES_MSG_TEXT = 'The rule(s) have been triggered. You can see job status and monitor progress in the System Jobs screen.';
  static SEMANTIC_DADA_SET_HEADER_MESSAGE = 'Jobs have been triggered to publish the targeted data sets. You can track the status of these Jobs in the Systems Jobs screen.';
  static SEMANTIC_DADA_SET_MESSAGE = 'Multiple data set creation in progress.';
  static SEMANTIC_DADA_SET_FOOTER_MESSAGE = 'The process to register and profile multiple data sets has been triggered. You can track the status of these jobs in the System Jobs screen.';
  static USER_EXITS ='User name already exist. Please use different name.';
  static EMAIL_EXITS ='E-mail address already exist. Please use different E-mail address.';
  static SAVE_TASK_ASSIGNMENT_TEXT = 'Task Assignments Successful';
  static DEFAULT_SYSTEM_CATALOG = 'FlureeSense System Catalog';
  static PUBLISH_GOLDEN_RECORDS_HEADER_MESSAGE = 'Publishing Golden Records in progress.';
  static PUBLISH_GOLDEN_RECORDS_MESSAGE = 'The Golden Records will be published to the selected target shortly. If you\'ve selected the \'Reconciliation Report\" option, please wait for the download prompt to appear';
  static RESOLVE_PROJECT_RUN_MODEL_HEADER_TEXT = 'Resolve Project Model in progress';
  static RESOLVE_PROJECT_RUN_MODEL_TEXT = 'The Model has been triggered. You can see job status and monitor progress in the System Jobs screen.';  
  static DATASET_REPROFILE_HEADER_MSG = 'Data Set Profiling in Progress.';
  static DATASET_REPROFILE_MSG_TEXT = 'The process to profile your data sets has been triggered. You can see job status and monitor progress for Data Set jobs in the System Jobs screen';
  static IMPORT_DUPLICATE_USER_ERROR_MSG = 'One or more of the username(s) in the import file already exists OR is duplicated.';
  static IMPORT_USER_MANDATORY_FIELD_ERROR_MSG = 'One or more of the mandatory fields is missing in the import file. Please check instructions for the mandatory fields.';
  static QUOTA_TYPE_LIMIT_EXCEEDS_MSG = 'The value provided for quota is out of range';
  static DELETE_TENANT_ADMIN_USER_MSG = 'Couldnt delete this user. Please try again later';
  static RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_COLUMN = 'At least one of the Exceptions should contain the Primary Column of the Rule.';
  static RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_CONCEPT = 'At least one of the Exceptions should contain the Primary Concept of the Rule.';
  

  //  Run Model API  calls.
  static getRunModelAlgo(scenarioId: number): number {
    return AppGlobals.runModelAlgo[scenarioId];
  }

  static getRunModelAlgoJobType(scenarioId: number): string {
    return AppGlobals.runModelAlgoJobType[scenarioId];
  }

  static CLASSIFY_PROJECT_ADMIN = 35;
  static CLASSIFY_PROJECT_APPROVER = 36;
  static CLASSIFY_PROJECT_REVEIWER = 37;

  
  static CATALOG_ADMIN=32;
  static CATALOG_APPROVER=33;
  static CATALOG_REVEIWER=34;

  static CUSTOM_OPTION = 'Create your own option';
  static CUSTOM_SOURCE = 'Source';
  static CUSTOM_SOURCEVALUE = 'Source Value';
  static CUSTOM_OPTIONVALUE = 'Custom';
  static GOLDEN_RECORD_MSG = 'Golden Record Updated';
  
  static NOT_NULL='Must be Populated or Non-Null';
  static MUST_UNIQE='Must be a Unique value';
  static MUST_BE_DATATYPE='Must be of the defined data type';
  static MUST_COME_AFTER='Must come After';
  static MUST_COME_BEFORE='Must come Before';
  static MUST_BE_GREATER ='Must be Greater Than';
  static MUST_BE_LESS ='Must be Less Than';
  static MUST_NOT_BE_QUTLIER ='Must Not Be a Statistical Outlier';
  static MUST_BE_EQUAl ='Must be Equal To';
  static SET_REFERENCE ='Must have a valid value from the referenced data list';

  static TECHNICAL_LEVEL_RULE = 14922;
  static BUSINESS_LEVEL_RULE = 14921;
  static FIRST_PAGESIZE = 100;

  static TECHNICAL_VIEW = 'technical';
  static BUSINESS_VIEW = 'business';

  // Data Quality Rule lookup Id's
  static DQ_VALUE_TYPE_IS_DATATYPE = 14952;
  static DQ_SEQUENCE_TYPE_IS_MULTI_ATTRIBUTE = 14878;
  static DQ_SEQUENCE_TYPE_IS_SINGLE_COLUMN = 14877;
  static DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN = 14876;
  static DQ_OPERATOR_DOES_NOT_EQUAL = 14903;
  static DQ_OPERATOR_EQUALS = 14902;

  // Task Status
  static TASK_PENDING_APPROVAL = 'Pending Approval';
  static TASK_IN_REVIEW = 'In Review';
  static TASK_COMPLETED = 'Completed';
  static TASK_PENDING_FOR_APPROVAL = 'Pending For Approval';
  static TASK_REVIEWED_STATUS = 'Reviewed';
  
  static DQ_SCORE_LABEL = 'dq_score';
  static MUST_BE_POPULATED_OR_NOT_NULL_LOOKUP_ID = 14923;
  static MUST_BE_DEFINED_DATA_TYPE_LOOKUP_ID = 14924;
  static MUST_HAVE_VALID_VALUE_FROM_REFERENCE_DATA_LIST_LOOKUP_ID = 14925;
  static MUST_BE_UNIQUE_VALUE_LOOKUP_ID = 14926;
  static MUST_COME_BEFORE_LOOKUP_ID = 14927;
  static MUST_COME_AFTER_LOOKUP_ID = 14928;
  static MUST_BE_EQUAL_TO_LOOKUP_ID = 14929;
  static MUST_BE_GREATER_THAN_LOOKUP_ID = 14930;
  static MUST_BE_LESS_THAN_LOOKUP_ID = 14931;

  // APP ID's ex- resolve, classify, ingest
  static INGEST_APP_ID = 4;
  
  // Quota Types
  static VOLUME_OF_DATA = 14964;
  static NUMBER_OF_RECORDS = 14965;

  static CLASSIFY_APP = 'Classify';

  // Datasource Types
  static CLOUD_OBJECT_STORAGE = 100;
  static FILE_SERVER = 427;
  static HADOOP_TYPE = 270;
  static RELATIONAL_DATABASE = 503;
  static NO_SQL_DATABASE = 271;
  static SNOWFLAKE_DATA_WAREHOUSE = 14950;

  // Datasource RDBMS sub types
  static MICROSOFT_SQL_SERVER = 15571;
  static ORACLE = 15572;
  static MYSQL = 15573;
  static POSTGRES_SQL = 15574;
  static CLASSIFY_PROJECT_ADMIN_ROLE_ID = 35;
  

  // Cluster Manager Types
  static APACHE_SPARK = 14846;
  static DATABRICKS = 14847

  // Jobs object types
  static classifySemanticDatasets = 14966;

  // Task Types
  static CLASSIFYCATALOGSEMANTICTASK = 'Semantic Object class training';
  static CLASSIFYCATALOGCONCEPTASK = 'Concept class training';

  // catalog tasks typeId's
  static catalogSemanticTaskType = 14868;
  static catalogConceptTaskType = 14867;

  // Datasource Filestorage Types
  static DATASOURCE_TYPE_HDFS = 270;
  static DATASOURCE_TYPE_CLOUDFS = 100;

  // Resolve Project User roles
  static RESOLVE_PROJECT_ADMIN = 1;
  static RESOLVE_PROJECT_REVIEWER = 5;
  static RESOLVE_PROJECT_APPROVER = 4;

  static REVIEWER_RVALIDATION_MSG = 'Only one Reviewer is allowed.';
  static APPROVER_RVALIDATION_MSG = 'Only one Approver is allowed.';
  static TASK_ASSIGNMENT_FAILD_MSG = 'Task Assignments Unsuccesfull'
  static DATASOURCE_TYPE_CLOUD = 'Cloud Object Storage';

  // File Upload type screens
  static FU_TYPE_ENTITY_ATTRIBUTES = {
    title: 'Upload File',
    description: 'You can add semantic objects and concepts to your catalog by importing the contents from a file.  You can use the default template to import relational data by clicking on the button below.  The attachment has a sample of catalog import objects already included for your reference.  Please delete the extra rows and re-upload the file.  If your catalog is based on a semantic data model and is of the file type of .rdf, .owl or .ttl, you can upload the file directly.  If your file import is not either of the csv type from the template below, or of .rdf, .owl or .ttl, it will be rejected.',
    details: []
  }

  static FU_TYPE_ENTITY_MODEL_ATTRIBUTES = {
    title: 'Upload File',
    description: [
      'If you don\'t have the attribute template, click here to download.Please remember to delete extra rows in the template and fully replace remaining ones with your own data before uploading.',
      'Please add Attribute Data Type, Scrubber Type, Match Type, etc. spelled correctly, as seen on UI.Duplicate attributes or incorrectly spelled rows will be ignored.'
    ],
    details: []
  };
 
  static FU_TYPE_SYNONYM_IMPORT = {
    title: 'Import Synonyms',
    description: 'Click here to download the template for your synonyms.',
    details: [
      'Download the template to see an example of how you can upload the synonyms for left to right association.Please do not alter the header.',
      'Replace the values with your own left and Right Catalog,Semantic Object,Concept,Synonym Concept etc, name exactly as it is spelt.',
      'You should be a user of both left Catalog and right Catalog of the template to be allowed to create a synonym of it.',
      'You cannot create a synonym to a concept in the same Semantic Object.',
      'Duplicate synonyms shall be ignored during the upload.'
    ]
  }
  static FU_TYPE_DQRYLES = {
    title: 'Import Rules',
    description: 'Click here to download the Rule Template to import your Data Quality Rules.',
    details: [
      'Please fill the template with all mandatory details and the correct details related to exception conditions.',
      'The Logged on user will be considered as Rule Admin by default.',
      'Your rule will be automatically scheduled for the upcoming midnight (EST timings). ',
      'The existing mappings will be considered in case of business rules so please ensure there are mappings present for the rule to run.',
      'You can edit these details from the rule edit screen once it has been imported and saved.',
      'Please use the appropriate template for the specific rule type (Business or Technical) accordingly. Importing from Dataset level or Technical Rule screen will only allow Technical rule template and Importing from other levels and Business Rule main screen will allow Business rule Template."',
    ]
  }
  static FU_TYPE_USERS_SUPERUSERS = {
    title: 'Import Users',
    description: 'Click here to download the users template.',
    details: [
      'Please use the template provided to import the users.',
      'Please provide the mandatory fields like username, password, First Name, Last Name, Email',
    ]
  }
  static FU_TYPE_TASKS = {
    title: 'Import Tasks',
    description: 'Click here to download the Project or Catalog Classify Tasks available for you to complete. Multiple links are available. Pls use the specific file for the specific type of Task upload. Do not modify the header.',
    details: [
      'To complete the training cycle, for each Catalog Task / Project Tasks, you will be provided some mappings with confidence percentages. Add values in the Vote column to indicate which are the correct mappings',
      'To upvote a mapping, update the cell to 1 in the Vote column in the csv file',
      'To downvote a mapping, update the cell to 0 in the Vote column in the csv file ',
      'In case of Catalog Tasks - To complete that task , each mapping must either be upvoted or downvoted',
      'In case of Project Tasks, for Concept Parser Project Type- each Task must have at-least 1 upvote.',
      'Each downloaded file may containing multiple such set of tasks. Please edit and upload accordingly. These will only be the tasks applicable to you as reviewer or approver. If as an approver you do not feel the need to change any mapping, just leave it as it is.',
    ]
  }
  static FU_TYPE_IMPORT_CONCEPT_TRAINING = {
    title: 'Import Concept Mappings',
    description: 'Click here to download the template for Concept Mappings to be Uploaded. Replace the template examples with your own mappings.',
    details: [
      'Please associate the mappings filling the Data Source name, Data set name, Column name, Concept etc. Name spelt exactly the way it is.',
      'You can upload mappings for multiple concepts across semantic objects and Catalogs as long as you\'re a user of that catalog.',
      'Duplicate mappings (if any) will be ignored.',
    ]
  }
  static FU_TYPE_CLASSIFY_PROJECT_TRAINING = {
    title: 'Import Training Tasks',
    description: 'Click here to download the import Template for your project assigned training tasks.',
    details: [
      'To complete the training cycle, for each task ID, you will be given up to 5 options of what the class may be.',
      'Enter a 1 in the Vote column next to an option to confirm and give positive feedback that it is the correct class.',
      'You can also enter a 0 in the Vote column next to incorrect responses to provide negative feedback, although this is optional. Negative feedback can be useful to break ties between two predicted classes.',
      'There must be at least one positive feedback for each Training Task in order for the import sheet to be accepted.',
      'Once your feedback file is complete, please upload it by selecting a file or dragging it into the area below.',
    ]
  }
  static FU_TYPE_DATASOURCE = {
    title: 'Upload File',
    description: '',
    details: []
  }

  static FU_TYPE_PROJECT_DATASET = {
    title: 'Upload File',
    description: '',
    details: []
  };

  static FU_TYPE_PROJECT_MAPPING = {
    title: 'Import Mappings',
    description: 'Please download the template and fill/edit the Mappings as required. Remember to delete the whole row if a mapping is incorrect or not required',
    details: [
      'On upload ONLY the allowed mappings will get updated and show on the screen. For example: incorrectly spelt / associated Items; Entities, Data Sets other than the ones belonging to this project will not get updated',
      'Remember that the mappings  from each Data Set (either uploaded or pre-existing) should have at-least one which is mapped to the Entity Identifier.',
      'This operation will add to your existing mappings on the parent screen. If same mapping exists, it wont duplicate and any mappings other than the ones in the File will remain.',
      'Mappings won’t get Saved till you press Run Model on the screen.',

    ]
  };

  static SCHEDULER_TYPE_ONCE_LOOKUPID = 338;

  static SCHEDULER_ONCE = 'Once';
  static SCHEDULER_SCHEDULED = 'Scheduled';
  // Classify project types
  static SEMANTIC_OBJECT_CLASSIFIER = 14862; //SOC
  static CONCEPT_PARSER = 14863;
  
  static CLASSIFY_TYPE_ID = 14845;
  static SCHEDULER_ONCE_OBJECT_TYPEID = 14948;
  static CONCEPT_PARSER_TASK_TYPE = 'Concept Parser';

  static VIEW_RESOLUTION_RESULT = 'View Resolution Results';

  // File types
  static IMPORT_DATASOURCE = 'datasource';
  static IMPORT_ENTITY_ATTRIBUTES = 'entityAttributes';
  static IMPORT_PROJECT_DATASET = 'projectDataset';
  static IMPORT_CLASSIFY_PROJECT_TRAINING = 'classifyProjectTraining';
  static IMPORT_CONCEPT_TRAINING = 'importConceptTraining';
  static IMPORT_SYNONYM = 'synonymImport';
  static IMPORT_DQ_RULES = 'dqRules';
  static IMPORT_TASKS = 'tasks';
  static IMPORT_ADMIN_USERS = 'users';

  // Tenant Management Cluster Manager Engine Type
  static ENGINE_TYPE = {
    HDI: 451,
    DBR: 452
  };

  // Classify project current status
  static PROJECT_IN_PROGRESS = 'In Progress';
  static PROJECT_PUBLISHED = 'Published';
  static PROJECT_NEW = 'New';
  static PROJECT_DRAFT = 'Draft';
  static PROJECT_PRODUCTION = 'Production';
  static PROJECT_SETUP = 'Setup';
  static PROJECT_TRAINING = 'Training';

  // Golden records columns
  static RECORED_LAST_UPDATED_DATE = 'Record Last Updated Date';
  static LAST_RECORED_UPDATED_DATE = 'Last Record Updated Date';
  static IDENTIFIER_AT_SOURCE = 'Identifier at Source';
  static SOURCE_SYSTEM = 'Source System';

  // Maximum value for tenant quota type
  static MAXIMUM_QUOTA_VALUE = 9223372036854775807;

  // File Status
  static FILE_UPLOADED = 'Files Uploaded';

  // Application App Id's
  static RESOLVE_APP_ID = 1;
  static CLASSIFY_APP_ID = 3;
  static INJEST_APP_ID = 4;

  // Row Default Color
  static EVEN_ROW_BG_COLOR = '#fff';
  static ODD_ROW_BG_COLOR = '#f7f7f7';
  // Row Heights
  static ROW_HEIGHT = 45;
  static hEADER_ROW_HEIGHT = 30;
  static FILTER_ROW_HEIGHT = 40;
  static DEFAULT_PAGE_SIZE = 100;
  static NO_DATA_MSG = 'No Data Found';
  static CLASSIFY_PROJECT_CONCEPT_TYPE_DESCRIPTION = 'Use this option to parse or split information embedded inside one data set column associated to a concept, into multiple new concepts.';
  static CLASSIFY_PROJECT_SEMANTIC_TYPE_DESCRIPTION = 'Use this option to create a custom tag to classify individual records within a data set associated to a semantic object.';

  static CLASSIFY_PROJECT_APPLY = 'Data Classification Project:';

  static STEP_1 = 1;
  static STEP_2 = 2;
  static STEP_3 = 3;
  static STEP_4 = 4;
  
  static RESOLVE_MERGE_ONLY_LOOKUP_ID = 15580;
  static RESOLVE_MATCH_ONLY_LOOKUP_ID = 15579;
  static RESOLVE_BOTH_MATCH_AND_MERGE = 15581;
  static RESOLVE_EXCLUDE_MATCH_AND_MERGE = 15582;

  static RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID = 15583;
  static RESOLVE_MERGING_STRATEGY_CONCATENATE_VALID_VALUES = 15584;
  static RESOLVE_MERGING_STRATEGY_ALWAYS_UPDATE_LATEST_VALUE = 15585;
  static RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE = 15586;
  static RESOLVE_MERGING_STRATEGY_NOT_APPLICABLE = 15587;

  static MAX_FILE_SIZE_ALLOWED_TO_UPLOAD_DATASET = '25 MB';
  static IMPORT_DATASETS_FILE_SIZE_LARGE_ERROR= `file(s) could not be uploaded where the file size was greater than maximum file size allowed (25MB). Please try uploading directly into your tenant's appropriate folder or contact support.`
  static DATASETS_IMPORTING_ERROR = 'An error occured while importing Datasets'

  static GOLDEN_RECORDS_PUBLISHED_STATUS = 373;

  // Resolve projects start/end steps
  static RESOLVE_MATCHER_END_STEP = 23;
  static RESOLVE_MERGER_START_STEP = 24;
  static RESOLVE_MERGER_END_STEP = 29;

  static RESOLVE_MATCHER_STAGE_NAME: string = "Matcher";
  static RESOLVE_MERGER_STAGE_NAME: string = "Merging";
  static RESOLVE_MASTER_STAGE_NAME: string = "Master";

  static RESOLVE_MATCHER_STAGE_NUMBER: number = 3;
  static RESOLVE_MERGER_STAGE_NUMBER: number = 4;
  static RESOLVE_MASTER_STAGE_NUMBER: number = 5;
  static JOB_CANCELLED_MSG = "Job failed/stopped due to a cancellation request.";
  static USER_ROLE_VALIDATION_MSG = 'There should be at-least one role selected for each user.';
  static DELETE_DATASET_MSG = "You are about to delete the selected Data Set:";
  static ARE_YOU_SURE = "Are you sure ?";

  static MIN_ENTITY_ATTRIBUTES_NEEDED = 1;
  static ENITY_IDENTIFIER_MATCHER_TYPE = 449;
  static HB_ENTITY_ATTRIBUTE_ERROR_MSG = 'In order to resolve this entity properly, we must have a minimum of one attribute. Please correct and try again.';
  static ENTITY_ATTRIBUTE_STATE_CHANGE_WARNING_MSG = 'There are saved changes which need to be triggered in the model. To exit this screen , please press Save to complete the process.';
  static ENTITY_ATTRIBUTE_STATE_CHANGE_WARNING_MSG_SUB = 'There are saved changes which need to be triggered in the model.';

  static CLASSIFY_USER_ROLE_NOT_FULFILLMENT_ERROR_MSG = 'It is required to complete this step even for creating Catalog as a Draft. This screen requires at-least 1 reviewer if Four Eyes OFF or at-least 1 Reviewer and 1 Approver if Four Eyes ON apart from admin to complete. You may exit in next screen after completing.';
  static CLASSIFY_USER_ROLE_NOT_FULFILLMENT_ERROR_MSG_POP_UP = 'This screen requires at-least 1 reviewer if Four Eyes OFF or at-least 1 Reviewer and 1 Approver if Four Eyes ON apart from admin to complete this step for creating Catalog as a Draft.';

  static CLASSIFY_PROJECT_TASK_TYPE = 14865;

  static GOLDEN_RECORD_DELIMITER = '$fsDelimiter$';
  static HELP_DOC_CLASSIFY_URL =  "https://sensedocsdev.wpengine.com/auto-login-classify";
  static HELP_DOC_RESOLVE_URL = "https://sensedocsdev.wpengine.com/auto-login-resolve";
  static RESOLVE_ENTITY_DATASET_MAPPING = 'entityResolveModalMapping';

  static PROCESS_CATALOG_TEXT = 'Currently converting the semantic model to the Classify input native format.  This process may take a few minutes.';
  static PROCESS_CATALOG_NAV_LINK_TEXT = 'Click here to return to the Catalogs screen and the catalog will be imported once completed.';

  static CLASSIFY_CATALOG_OBJECT_TYPE = 15592;
  static CLASSIFY_CATALOG_CHECK_DURATION = 30000;

  
  static OBJECT_TYPE_ID_CATALOG = 15592;
  static OBJECT_TYPE_ID_DATSET = 14779;
  static GRAPH_DOWNLOAD_TIMER = 30000;

  static DATA_SET_HEADER_SCHEMA = 15593;
  static DATA_SET_CATALOG_OBJECT_SCHEMA = 15594;

  static RESOLVE_MATCHER_ACCURACY_THRESHOLD = 80;

  static RESOLVE_MATCH_FIXING_TASK = 'Match Fixing';

  static RESOLVE_PROJECT_PRODUCTION_STATUS = 15611; // project in Production state
  static RESOLVE_PROJECT_TRAINING_STATUS = 15610;   // project in Training state

  static ZS_REC_ID_MERGER_TEMPLATE = '_zs_recid_template';

  static SOURCE_PRIMARY_KEY = 'Source Primary Key';

  static SOURCE_SYSTEM_IDENTIFIER = 'Source System Identifier';

  static SOURCE_SYSTEM_PRIMARY_KEY = 'Source System Primary Key';// Added this Temporarily to support existing Attributes with 'Source System Primary Key'. It will be replaced later

  static CLASSIFY_CLASSIFIER_NAME_LENGTH = 40;

  static RESOLVE_PROJECT_STATUS_PRODUCTION = 15611;
  static RESOLVE_PROJECT_STATUS_TRAINING = 15610;

  static ACCESS_DENIED_MESSAGE = 'You do not have permission to access the resource, Please ask your administrator.';
}
