import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance } from 'angular-slickgrid';
import { DatasourceService } from '../../datasource.service';
import { Datasource } from '../../datasource.model';
import { IEntityPrams } from '../../datasource.model';
import { MessageService } from '../../../../../common/components/message/message.service';

const RIGHT_EMPTY_GRID_MSG = 'No Datasource entitlements';

@Component({
  selector: 'zetta-datasource-entitlements',
  templateUrl: './datasource-entitlements.component.html',
  styleUrls: ['./datasource-entitlements.component.scss']
})
export class DatasourceEntitlementsComponent implements OnInit {
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  datasource_id;
  dsEntitlements: any = {};
  isLoading: boolean = false;
  grid: AngularGridInstance;
  tableSettings: object = { 'height': '100%', 'width': '100%' };
  entitlementsGridColumnsDef: any = [];
  gridOptions: any;
  payload: IEntityPrams;
  datasource = new Datasource();
  // connectionParam;
  canedit = false;
  dataSourceUsers: any = [];
  groupsUsersGrid: AngularGridInstance;
  total_users = 0;

  constructor(private datasourceSvc: DatasourceService,
              private route: ActivatedRoute,
              private messageSvc: MessageService) { }

  ngOnInit() {
    this.entitlementsGridColumnsDef = [{
      'displayname': 'User Group',
      'physicalname': 'group_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    },
    {
      'displayname': 'Number of Users',
      'physicalname': 'user_count',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }
  ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      noDataMsg: RIGHT_EMPTY_GRID_MSG,
    };

    this.route.parent.params.subscribe(params => {
      this.datasource_id = params['id'];
    });

    this.isLoading = true;

    this.datasourceSvc.getDatasourceEntitlements({ userId: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id, sourceId: this.datasource_id}).subscribe((entitlements) => {
      entitlements.groups.forEach(element => element.id = Math.random());
      this.dsEntitlements = entitlements;
      this.total_users = this.dsEntitlements['active_users'];
      this.isLoading = false;

      if (this.groupsUsersGrid) {
        this.dsEntitlements['groups'].forEach(element => {
          element.id = Math.random();
        });
        this.groupsUsersGrid.dataView.setItems(this.dsEntitlements['groups']);
        this.groupsUsersGrid.gridService.setSelectedRows([]);
      }
    }, err => {
      this.dsEntitlements = {};
      this.isLoading = false;
    });

    this.getUsersToDatasource();

  }

  getUsersToDatasource() {
    this.datasourceSvc.getUsersToDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource_id }).subscribe(responseList => {
      this.dataSourceUsers = responseList['currentpage'];
      this.dataSourceUsers = this.dataSourceUsers.filter((thing, index, self) => index === self.findIndex((t) => ( t.user_id === thing.user_id )))
      this.dataSourceUsers.forEach(element => {
        if(this.loggedInUserDetails['user_id'] === element.user_id && element.is_admin) {
          this.canedit = true;
        }
      });
    }, error => {
      this.messageSvc.sendMessage({ message: 'Get users has failed', type: 'INFO', hideboard: true });
    });
  }

  gridCreated(grid) {
    const parent = this;
    parent.groupsUsersGrid = grid;
  }

}
