import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ProjectService } from '../../project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../project.model';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../../common/components/message/message.service';
import * as _ from 'lodash'; 

// AgGrid code 
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { LineageCustomCellRendererComponent } from 'src/app/common/shared/cell-renderer/lineage-custom-formatter/lineage-custom-formatter.component';
 
declare var $:any;
@Component({
    selector:'zetta-golden-record-lineage',
    templateUrl:'./golden-record-lineage.component.html',
    styleUrls:['./golden-record-lineage.component.scss'],
})

export class GoldenRecordLineageComponent implements OnInit, OnChanges {

  public goldenRecords: object = {};
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  grLineageTableColDef = [];
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  isDataReady = false;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  projectDetail: Project = new Project();
  public viewLineageClusterDetails: object = {};
  // Ag Grid Code
  gridApi:any; 
  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;

  @Input() clusterID: any;
  @Input() entityId: number;
  @Input() displayNames: any;
  constructor(
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private messageSvc: MessageService,
    private _router: Router) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.getGoldenRecords(this.page, this.limit);

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30
    };

    this.fsGridOptions = {  
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };

    this.isDataReady = true;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.displayNames['SimpleChange']) {
      this.displayNames = this.modifyDisplayNames(changes.displayNames['SimpleChange']);
      this.viewLineageClusterDetails['columns'] = [];
      this.mapLineageHeaderColumns(this.displayNames);
    } else if(changes && changes.displayNames) {
      this.displayNames = this.modifyDisplayNames(changes.displayNames);
      this.viewLineageClusterDetails['columns'] = [];
      this.mapLineageHeaderColumns(this.displayNames);
    }
  }

  getGoldenRecords(pageNo, limit = 1) {
    this.projectService.getGoldenRecordsLineage(this.loggedInUserDetails.user_id, this.projectDetail.project_id, this.clusterID, pageNo, limit).subscribe((resp) => {
      if(resp) {
        this.viewLineageClusterDetails = resp;
        this.total = resp['totalrecords'];
        //    NEW CHANGE -  comparing with display names which are sorted- related code can be removed if display names are not required to comapre
        this.mapLineageHeaderColumns(this.displayNames); 
        this.isLoading = false;       
        let rowKeys1 = [];
        if (this.viewLineageClusterDetails['currentpage'].length > 0) {
          rowKeys1 = Object.keys(this.viewLineageClusterDetails['currentpage'][0]);
        }
        // NEW CHANGE - commented below since it is replaced with displaynames to show header rows data
        // this.viewLineageClusterDetails['columns'].forEach(column => {
        //   column.minWidth = 200;
        //   column.sortable = true;
        //   column.filterable = true;
        //   column.displayname = column.display_name;
        //   column.physicalname = column.physical_name + '_o';
        //   column.datatype = column.data_type;
        //   column.formatter = function (row, cell, value, columnDef, dataContext) {         
        //                           if(dataContext[columnDef.field + '_winner'] === '1') {
        //                             return `<div style="background-color: #FAF8DC;" title='` + value + `'>`+ value + `</div>`;
        //                           } else {
        //                             return `<div title='` + value + `'>`+ value + `</div>`;
        //                           }
        //                       }
        // });
        let concatedRightRows = this.viewLineageClusterDetails['currentpage'];
        if (this.grid) {
          concatedRightRows = [...this.grid.dataView.getItems(), ...this.viewLineageClusterDetails['currentpage']];
          this.viewLineageClusterDetails['currentpage'].forEach(element => {
            element.id = Math.random();
            if (element.zs_cluster_identifier) {
              this.zettaUtils.getWidthOfText(element.zs_cluster_identifier);
            }
          });
          this.viewLineageClusterDetails['currentpage'] = concatedRightRows;
          this.grid.dataView.setItems(concatedRightRows);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
      }
    }, err => {
          this.isLoading = false;
    });
  }
 
  onGridCreation(grid) {
    this.grid = grid;
  }
  
  onNext(): void {
    this.page++;
    this.getGoldenRecords(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  mapLineageHeaderColumns(displaynames) {
  if(displaynames && displaynames.length) {
    displaynames.forEach(column => { 
      // column['minWidth'] = 200;
      // column.sortable = true;
      // column.filterable = true;
      // column.displayname = column.display_name;
      // column.physicalname = column.physical_name + '_o';
      // column.datatype = column.data_type;
      column['minWidth'] = 200;
      column.headerName = column.display_name;
      column.field = column.physical_name + '_o';
      column.sortable = true;
      column.floatingFilter = true;
      column.filter = 'agTextColumnFilter';
      column.cellRendererFramework = LineageCustomCellRendererComponent;
      column.floatingFilterComponentParams = {
        suppressFilterButton: true,
      }; 
      column.tooltipField = column.physical_name + '_o';
      // column.formatter = function (row, cell, value, columnDef, dataContext) {         
      //       if(dataContext[columnDef.field + '_winner'] === '1') {
      //         return `<div style="background-color: #FAF8DC;" title='` + value + `'>`+ value + `</div>`;
      //       } else {
      //         return `<div title='` + value + `'>`+ value + `</div>`;
      //       }
      //   }
      });
        this.viewLineageClusterDetails['columns'] = displaynames;
        let data = this.viewLineageClusterDetails['columns'].forEach(element => this.getByLogicalName(element['physicalname']));
      }
  }

  getByLogicalName(physicalName) {
    if(this.viewLineageClusterDetails['currentpage'] && this.viewLineageClusterDetails['currentpage'].length) {
       this.viewLineageClusterDetails['currentpage'].find(record => {
        return record.physicalName === physicalName;
        });
    } else {
      return null;
    }
  }

  modifyDisplayNames(displaynames) {
    if(displaynames && displaynames['currentValue'] && displaynames['currentValue'].length) {
      let filteredNames = displaynames['currentValue'].filter(col => col.physical_name != `zs_source_system_${Number(this.entityId)}`);
        filteredNames.unshift({
        display_name: 'Source System',
        displayname: 'Source System',
        filterable:true,
        logical_name:'Source System',
        minWidth:200,
        physical_name: `zs_source_system_${this.entityId}`,
        physicalname:`zs_source_system_${this.entityId}`,
        project_id: this.projectDetail.project_id,
        sortable: true,
      });
      return filteredNames;
    }
  }

}
