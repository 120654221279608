import { ZettaUtils } from '../../common/shared/zettaUtils';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../content/project/project.service';
import { AngularGridInstance, Grouping, Column, Formatter, addToArrayWhenNotExists } from 'angular-slickgrid';
import { managejobsFormatter } from '../../common/shared/formatters/managejobsFormatter';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../common/components/message/message.service';
declare const $: any;
import _ from 'lodash';
import { DatasetService } from '../../common/components/datasets/dataset.service'
import { ConditionalExpr } from '@angular/compiler';
import { environment } from '../../../environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
const dateTimeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => dateFormat(value);

function dateFormat(val) {
  if (val === null || val === undefined || val === '') { return; }
  const d = new Date(val);
  const m = d.getMonth() + 1;
  const month = (m <= 9 ? '0' + m : m);
  const dd = d.getDate();
  const day = (dd <= 9 ? '0' + dd : dd);
  const hh = d.getHours();
  const hours = (hh <= 9 ? '0' + hh : hh);
  const mm = d.getMinutes();
  const minutes = (mm <= 9 ? '0' + mm : mm);
  const ss = d.getSeconds();
  const seconds = (ss <= 9 ? '0' + ss : ss);

  return `<div class='text-left' title='${d.getFullYear()}/${month}/${day}'>
              ${d.getFullYear()}/${month}/${day} ${hours}:${minutes}:${seconds}
            </div>`;

}

const elapsedTime: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div>${value}</div>`
}

@Component({
  selector: 'zetta-manage-jobs',
  templateUrl: './manage-jobs.component.html',
  styleUrls: ['./manage-jobs.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageJobsComponent implements OnInit, OnDestroy {

  public loggedInUserDetails;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  isLoading = true;
  hasScrolled = false;
  noData = false;
  public sparkJobsColumns;
  projectsJobs: string[] = [];
  totalProjectsJobs: number = 0;
  totalDatasetsJobs: number = 0;
  totalEntitiesJobs: number = 0;
  totalClassifyJobs: number = 0;
  totalClassifyPublishedProjectJobs: number = 0;
  totalResolvePublishedProjectJobs: number = 0;
  totalClassifySemanticDatasets: number = 0;
  totalClassifyProjectJobs: number = 0;
  totalDataQualityJobs: number = 0;
  totalDataSourceJobs: number = 0;
  totalJobQueue: number = 0;
  jobsByProjectId: any[] = [];
  sparkJobs: string[] = [];
  sparkJobGridOptions: any;
  public manageJobGrid: AngularGridInstance;
  dataviewObj: any;
  gridObj: any;
  jobId: any;
  refreshingIn: number;
  refreshingTime: number = 120;  // in seconds
  intervalCountdown: any;
  interval: any;
  selectedApp: any;
  userEntitlement: any = {};
  btnExportJob: any = {};
  btnJobActions: any = {};
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
  is_visible_export = false;
  is_enable_export = false;
  statusNotStarted = false;
  statusRunning = false;
  statusPaused = false;
  statusCompleted = false;
  statusCancelled = false;
  statusFilters: any = [];
  projectsFiltered: any = []; 
  activeLink="";
  objectId: any;
  object_id;
  object_title;
  objectsIds = [];
  logInfo: any = [];
  job_id: string;
  stage: string;
  log_interval: any;
  
  jobGridColumnsDef: any;
  jobQueueGridOptions: any;
  jobsQueueTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  // public jobsQueueGrid: AngularGridInstance;
  jobQueueDataSource;
  isJobQueueLoading = true;
  isJobsLoading = false;
  public jobQueueGrid: AngularGridInstance;
  page = 1;
  limit = 100;
  totalOverallJobsCount: number = 0;
  constructor(private projectService: ProjectService, 
              public zettaUtils: ZettaUtils,
              private router: Router,
              private messageSvc: MessageService, private activatedRoute:ActivatedRoute) {

                if (this.login_user) {
                  try {
                    let user_entitlements=this.login_user[0].entitlement;
                    if(this.login_user.length>1){
                      user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
                      if(user_entitlements.length){
                        user_entitlements=user_entitlements[0].entitlement;
                      }else{
                        user_entitlements=this.login_user[0].entitlement;
                      }
                    }          
                    const jobs = user_entitlements.screens.filter(item => item.name == 'system_jobs')[0].childs.filter(item => item.name == 'export_job')[0];
                    if (jobs) {
                      this.is_visible_export = jobs.visible;
                      this.is_enable_export = jobs.enable;
                    }
                  } catch (error) {
                    console.log(error);
                  }            
                }
  }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.selectedApp = sessionStorage.getItem('selectedApp');
    this.userEntitlement = this.projectService.getObjectFromSession('userEntitlement');

    if (this.userEntitlement.screens[7] === 'undefined') {
      this.btnExportJob = this.userEntitlement.screens[7].childs[0];
      this.btnJobActions = this.userEntitlement.screens[7].childs[1];
    } else {
      this.btnExportJob.visible = true;
      this.btnExportJob.enable = true;
      this.btnJobActions.visible = true;
      this.btnJobActions.enable = true;
    }
    
    if(this.selectedApp === 'zs') {
      this.objectsIds = ['14778', '14802', '14779','14781', '14967','14948'];  //'Projects', 'Entities', 'Datasets','Classify Jobs','DataSource' ,'PublishedProject', 'Data Quality'
      this.object_id = '14778';                       //By default
      this.object_title = 'Project';                  //By default
      this.activeLink='Project';
    }
    if(this.selectedApp === 'zs-cl') {
      this.objectsIds = ['14845', '14779', '14948', '14781', '14968', AppGlobals.classifySemanticDatasets];           //'Classify-Project', 'Datasets', 'Data Quality','Classify','DataSource', 'PublishedProject, Classify Semantic Datasets
      this.object_id = '14845';                       //By default
      this.object_title = 'Project';                  //By default
      this.activeLink='Classify-Project';
    }
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (param.has('activeState')) {
        this.activeLink = param.get('activeState');
        this.object_title = this.activeLink ;   
        this.object_id = this.activeLink == 'Dataset' ? '14779' : ('DataQuality' ? '14948' : '14845');
        if (this.activeLink == 'Classify') {
          this.object_id = '14781';
        }
        if(this.activeLink === 'PublishedProject'){
          this.object_id = '14968';
          this.object_title = 'Published Project';  
        }
        if(this.activeLink === 'ResolvePublishedProject'){
          this.object_id = '14967';
          this.object_title = 'Published Project';  
        }
        if(this.activeLink === 'classifySemanticDatasets'){
          this.object_id = AppGlobals.classifySemanticDatasets;
          this.object_title = 'Semantic Dataset Jobs';  
        }
      }
    });
    for(let i=0; i < this.objectsIds.length; i++){
      this.projectService.getObjectsJobs(this.loggedInUserDetails.user_id, this.objectsIds[i]).subscribe((objectsData) => {
        if (this.objectsIds[i] === '14778'){    //Projects
          this.totalProjectsJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14802'){    //Entities
          this.totalEntitiesJobs = objectsData.length;
        }
        // if (this.objectsIds[i] === '14845'){    //Classify
        //   this.totalClassifyJobs = objectsData.length;
        // }
        if (this.objectsIds[i] === '14845'){    //Classify-Project
          this.totalClassifyProjectJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14779'){    //Datasets
          this.totalDatasetsJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14948'){    //Data Quality
          this.totalDataQualityJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14781'){    //Classify
          this.totalClassifyJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14968') {    //ClassifyPublishedProject
          this.totalClassifyPublishedProjectJobs = objectsData.length;
        }
        if (this.objectsIds[i] === '14967') {    //ResolvePublishedProject
          this.totalResolvePublishedProjectJobs = objectsData.length;
        }
        if (this.objectsIds[i] === AppGlobals.classifySemanticDatasets) {    //classify Semantic Dataset Jobs
          this.totalClassifySemanticDatasets = objectsData.length;
        }
       } , err => { });
    }

    this.initFilters();
    this.getProjects();
    this.getAllJobQueue(this.page, this.limit);

    this.sparkJobsColumns = [
          {
            'displayname': 'Step',
            'physicalname': 'step',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': managejobsFormatter,
            'minWidth': 230
          }, {
            'displayname': 'Status',
            'physicalname': 'status',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': managejobsFormatter,
            'minWidth':90,
            'headerCssClass':'text-left ml-1'
          }, {
            'displayname': 'Start Time',
            'physicalname': 'started',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': dateTimeFormatter,
            'minWidth': 140,
            'cssClass':'text-left ml-1',
          }, {
            'displayname': 'End Time',
            'physicalname': 'ended',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': dateTimeFormatter,
            'minWidth': 140,
            'cssClass':'text-left ml-1',
          }, {
            'displayname': 'Total Elapsed Time',
            'physicalname': 'elapsed_time',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': elapsedTime,
            'cssClass':'text-left ml-2',
          }, {
            'displayname': 'Initiated By',
            'physicalname': 'initiateby',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'minWidth': 100,
            'cssClass':'text-left ml-2',
          }, {
            'displayname': 'Available Action',
            'physicalname': 'actions',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': managejobsFormatter,
            'params': { btnJobActions: this.btnJobActions },
            'minWidth': 150,
            'cssClass':'text-left',
            'headerCssClass':'text-left ml-2',
          }
    ];

    this.jobGridColumnsDef = [{
      
      'displayname': 'Job Name',
      'physicalname': 'job_name',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'args': false,
      'minWidth': 250,
    }, {
      'displayname': 'Job Type',
      'physicalname': 'job_type',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 160,
    },
    {
      'displayname': 'Job Queued Time',
      'physicalname': 'created_ts',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 50
    }];


    this.sparkJobGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: false,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };

    this.jobQueueGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: false,
      rowHeight: 34,
      multiSelectable: true,
    };

  }

  initFilters() {
    $('#status-not-started').attr('checked', this.statusNotStarted);
    $('#status-running').attr('checked', this.statusRunning);
    $('#status-paused').attr('checked', this.statusPaused);
    $('#status-completed').attr('checked', this.statusCompleted);
    $('#status-cancelled').attr('checked', this.statusCancelled);
  }
  
  onCheckStatus(status) {
    if (status === 'status-not-started') {
      this.statusNotStarted = !this.statusNotStarted;
      this.statusNotStarted ? this.statusFilters.push('Not Started') : this.statusFilters = this.statusFilters.filter( e => e !== 'Not Started' );
    }
    if (status === 'status-running') {
      this.statusRunning = !this.statusRunning;
      this.statusRunning ? this.statusFilters.push('Started') : this.statusFilters = this.statusFilters.filter( e => e !== 'Started' );     //Started  >>>  Running
    }
    if (status === 'status-paused') {
      this.statusPaused = !this.statusPaused;
      this.statusPaused ? this.statusFilters.push('Paused') : this.statusFilters = this.statusFilters.filter( e => e !== 'Paused' );
    }
    if (status === 'status-completed') {
      this.statusCompleted = !this.statusCompleted;
      this.statusCompleted ? this.statusFilters.push('Completed') : this.statusFilters = this.statusFilters.filter( e => e !== 'Completed' );
    }
    if (status === 'status-cancelled') {
      this.statusCancelled = !this.statusCancelled;
      this.statusCancelled ? this.statusFilters.push('Cancelled') : this.statusFilters = this.statusFilters.filter( e => e !== 'Cancelled' );
    }
  }

  onFilterBtn() {
      this.getProjects();
      this.getSparkJobs();
  }

  getObjectStyle() {
    if ($('#collapse0').hasClass('show')) {
      return 'selected-project expanded';
    } else {
      return 'selected-project collapsed';
    }
  }

  getJobStyle() {
    if ($('#collapse1').hasClass('show')) {
      return 'selected-job expanded';
    } else {
      return 'selected-job collapsed';
    }
  }


  initContent(objectType) {
    if (objectType === 'Project') { 
      this.activeLink = 'Project'
      this.object_id = '14778';
    }
    if (objectType === 'Entity') { 
      this.activeLink = 'Entity'
      this.object_id = '14802';
    }
    if (objectType === 'Classify-Project') { 
      this.activeLink = 'Classify-Project'
      this.object_id = '14845';
      objectType = 'Project'
    }
    if (objectType === 'Dataset') { 
      this.activeLink = 'Dataset'
      this.object_id = '14779';
    }
    if (objectType === 'DataQuality') { 
      this.activeLink = 'DataQuality'
      this.object_id = '14948';
    }
    if (objectType === 'Classify') { 
      this.activeLink = 'Classify'
      this.object_id = '14781';
    }    
    this.object_title = objectType;
    if (objectType === 'PublishedProject') {
      this.activeLink = 'PublishedProject'
      this.object_id = '14968';
      this.object_title = 'Published Project';
    }
    if (objectType === 'ResolvePublishedProject') {
      this.activeLink = 'ResolvePublishedProject'
      this.object_id = '14967';
      this.object_title = 'Published Project';
    }
    if (objectType === 'classifySemanticDatasets') {
      this.activeLink = 'classifySemanticDatasets'
      this.object_id = AppGlobals.classifySemanticDatasets;
      this.object_title = 'Semantic Dataset Jobs';
    }
    this.getProjects();
    if (objectType === 'JobsQueue') { 
      this.activeLink = 'JobsQueue'
    }
    
  }
   
  toggleAccordian0(event, project) {
    if (this.interval) { clearInterval(this.interval) }
    if (this.intervalCountdown) { clearInterval(this.intervalCountdown) }
    this.objectId = project.object_id;
    const target = event.target.target;
    if (this.jobsByProjectId['openProject'] !== project) {
      this.projectsJobs['openProject'] = project;
      this.getJobs();
    } else {
      this.projectsJobs['openProject'] = undefined;
    }
  }

  toggleAccordian1(event, job) {
    if (this.interval) { clearInterval(this.interval) }
    if (this.intervalCountdown) { clearInterval(this.intervalCountdown) }
    const refreshingTime = this.refreshingTime * 1000;
    this.jobId = job.job_id;
    const target = event.target.target;
    if (this.jobsByProjectId['openJob'] !== job) {
      this.jobsByProjectId['openJob'] = job;
      this.refreshingIn = this.refreshingTime;
      this.intervalCountdown = setInterval(() => {
          this.refreshingIn = this.refreshingIn <= 1 ? this.refreshingIn = this.refreshingTime : this.refreshingIn = this.refreshingIn - 1;
      }, 1000);
      const parent = this;
      parent.interval = setTimeout(function loop() {
        parent.getSparkJobs();
        parent.interval = setTimeout(loop, refreshingTime);
      }, 0);
    } else {
      this.jobsByProjectId['openJob'] = undefined;
    }
  }

  getProjects() {
      this.projectsJobs=[];
      this.isJobsLoading = true;
      let self = this;
      let filterByStatus = this.statusFilters && this.statusFilters.length ? this.statusFilters.join(',') : null; 
      this.projectService.getObjectsJobs(this.loggedInUserDetails.user_id, this.object_id, filterByStatus).subscribe((projectsData) => {        
            
            self.projectsJobs = projectsData;
            self.isJobsLoading = false;
            if (!this.projectsJobs.length) {
              self.isJobsLoading = false;
              this.noData = true;
            }            
      }, err => { 
        this.projectsJobs = [];
        self.isJobsLoading = false;
      });
  }

  getJobs(): void {
    const parent = this;
    parent.jobsByProjectId = [];
    parent.isLoading = true;
    parent.projectService.getJobsByObjectId(parent.loggedInUserDetails.user_id, parent.objectId, parent.object_id).subscribe((data) => {
        parent.jobsByProjectId = data;
        if (!parent.jobsByProjectId.length) {
          parent.noData = true;
        }

        if (parent.statusFilters.length) {
            parent.jobsByProjectId.forEach((job, jobIndex) => {
              parent.projectService.getSparkjob(parent.loggedInUserDetails.user_id, parent.objectId, job['job_id']).subscribe((sparkRes) => {
                    sparkRes.every(spark => {
                      if(parent.statusFilters.includes(spark['status'])) {
                        $('#accordian_jobs_' + job['job_id']).show();
                        return false;
                      }
                      $('#accordian_jobs_' + job['job_id']).hide();
                      return true;
                    });
                }, err => {  });
            });
        }
    }, err => {
      parent.messageSvc.sendMessage({ message: 'Get job history not available', type: 'INFO', hideboard: true });
    });
  }

  getSparkJobs(): void {
    this.sparkJobs = [];
    if (this.objectId && this.jobId) {
      this.projectService.getSparkjob(this.loggedInUserDetails.user_id, this.objectId, this.jobId).subscribe((data) => {
        const rows = data;
        if (!rows.length) {
          this.noData = true;
        }
        rows.reduce((previousSeconds, accumulator) => {
          // accumulator.duration_value = accumulator.duration;
          // accumulator.elapsed_time = accumulator.duration;
          const currentVaue = this.zettaUtils.convertIntoSeconds(accumulator.duration);
          accumulator.duration = this.zettaUtils.secondsToDhms(currentVaue);
          accumulator.id = Math.random();
        }, 0);
        rows.forEach(element => {
          let diff = this.zettaUtils.diffDates(element.started, element.ended);
          element.elapsed_time = diff;
        });
        this.sparkJobs = rows;
        this.isLoading = false;
        this.hasScrolled = false;

        if (this.statusFilters.length > 0) {
          this.sparkJobs = this.sparkJobs.filter(job => this.statusFilters.includes( job['status'] ) );
        }

        if(this.manageJobGrid){ 
          this.manageJobGrid.dataView.beginUpdate();
          this.manageJobGrid.dataView.setItems(this.sparkJobs);
          this.manageJobGrid.dataView.endUpdate();
          this.manageJobGrid.dataView.refresh();
        }
    }, err => {  });
    }
  }

  getAllJobQueue(pageno, limit) {
    this.jobsQueueTableSettings['search_url'] = `${environment.config.API_URL}/algos/queue?pagination=true&pageno=${1}&pagesize=${limit}`;
    const data = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      pageno: pageno,
      limit: limit
    };
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.projectService.getAllJobQueue(data, serverFilter, sortfilter).subscribe((data) => {
      this.jobQueueDataSource = data['currentpage'];
      if (this.jobQueueDataSource.length === 0) {
        this.noData = true;
      }
      this.isJobQueueLoading = false;
      this.totalJobQueue = data['totalrecords'];
      this.jobQueueDataSource.forEach(element => {        
        element.id = Math.random();
      
        element.job_name = element.algoJob.stage_name;
        element.job_type = element.algoJob.Job_Type;

      });
      
      let concatedRows = this.jobQueueDataSource;
      if (this.jobQueueGrid) {
        concatedRows = [...this.jobQueueGrid.dataView.getItems(), ...this.jobQueueDataSource];
        this.jobQueueDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.jobQueueDataSource = concatedRows;
        this.jobQueueGrid.dataView.setItems(concatedRows);
        this.jobQueueGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isJobQueueLoading = true;
    });
  }


  sparkHistoryGridReady(grid) {
        this.manageJobGrid = grid;
        this.dataviewObj = this.manageJobGrid.dataView;
        this.gridObj = this.manageJobGrid.slickGrid; // grid object
        this.groupByProject();
  }

  groupByProject() {
    this.dataviewObj.setGrouping([
      {
        getter: 'stage',
        formatter: (g) => {
          const sumOfDuration = g['rows'].reduce((previousSeconds, accumulator) => {
            // const currentVaue = this.zettaUtils.convertHHMMSStoSeconds(accumulator.duration_value);
            const currentVaue = this.zettaUtils.convertHHMMSStoSeconds(accumulator.elapsed_time);
            return previousSeconds + (currentVaue ? currentVaue : 0);
          }, 0);
          const hhmmssStr = this.zettaUtils.secondsToHHMMSS(sumOfDuration); 
          return `<span style="color:#505050">Stage :  ${g.value}  <span style="color:#7b7b7b"> (${hhmmssStr})</span></span>`;
        },
        aggregators: [],
        collapsed: false,
        lazyTotalsCalculation: true
      }
    ] as Grouping[]);
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
    if (this.interval) { clearInterval(this.interval) }
    if (this.intervalCountdown) { clearInterval(this.intervalCountdown) }
  }
  
  dateTimeFormat1(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    const dd = d.getDate();
    const day = (dd <= 9 ? '0' + dd : dd);
    const hh = d.getHours();
    const hours = (hh <= 9 ? '0' + hh : hh);
    const mm = d.getMinutes();
    const minutes = (mm <= 9 ? '0' + mm : mm);
    const ss = d.getSeconds();
    const seconds = (ss <= 9 ? '0' + ss : ss);
    return   month + '/' + day + '/' + d.getFullYear() + '    ' + hours + ':' + minutes + ':' + seconds;
  }

  redirect2SparkHistory(e): void {
    const args = e.args;
    const event = e.eventData;
    const row = this.manageJobGrid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (event.target.className.includes('cancel-job')) {
        this.projectService.cancelActiveJob(this.loggedInUserDetails.user_id, this.objectId).subscribe((res) => {
          const response = res;
          this.messageSvc.sendMessage({ message: 'Job has cancelled', type: 'INFO', hideboard: true });
        }, err => { });
      }
      if (event.target.className.includes('view-matcher')) {
        this.router.navigate(['/zs/projects/' + row.project_id + '/entities-resolved']);
      }
      if (event.target.className.includes('view-merger')) {
        this.router.navigate(['/zs/projects/' + row.project_id + '/entities-mastered']);
      }
      if (event.target.className.includes('spark_uiurl')) {
        this.job_id = row.job_id;
        this.stage = row.stage;
        this.logInfo = [];
        this.getLogInfo();
        $('#logModal').modal('show');
        this.log_interval = setInterval(() => {
          this.getLogInfo();
        }, 10000);
      }
    }
  }

  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
  }

  jobQueueGridReady(grid) {
    this.jobQueueGrid = grid;
  }

  onNext() {
    this.page++;
    this.getAllJobQueue(this.page, this.limit);
  }

  onJobQueueScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  getOverallJobsCount() {
    if (this.selectedApp === 'zs') {
      this.totalOverallJobsCount = this.totalProjectsJobs + this.totalEntitiesJobs + this.totalDatasetsJobs + this.totalDataQualityJobs + this.totalResolvePublishedProjectJobs +  this.totalJobQueue;
      return this.totalOverallJobsCount;
    }
    if (this.selectedApp === 'zs-cl') {
      this.totalOverallJobsCount = this.totalClassifyJobs + this.totalClassifyProjectJobs + this.totalDatasetsJobs + this.totalDataQualityJobs + this.totalClassifyPublishedProjectJobs + this.totalClassifySemanticDatasets + this.totalJobQueue;
      return this.totalOverallJobsCount;
    }
  }
  getLogInfo() {
    this.projectService.getJobLog(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.job_id, this.stage).subscribe((res) => {
      this.logInfo = res.split('\n');
    }, err => {
      clearInterval(this.log_interval);
    });
  }

  onCloseLogModal() {
    $('#logModal').modal('hide');
    clearInterval(this.log_interval);
  }
}
