import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
declare var $: any;
@Component({
  selector: 'zetta-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() app: string;
    @Input() label: string;
    @Input() type: string;
    @Input() img: string;
    @Input() imgSize: string;
    @Input() imgPosition: string;
    @Input() width: string;
    @Input() height: string;
    @Input() btnDisabled: any;
    @Input() btnType: string;
    @Input() pos: string;
    @Input() imgColor:string='';
    @Input() imgBgColor:string='';
    @Input() tooltipPos: any;
    @Input() btnError=false;
    @Input() tenantImage: string;
    @Input() borderRadius: string;
    @Input() borderRightNone?: Boolean = false;
    @Input() borderLeftNone?: Boolean = false;
    // zsColor = '#0d71a1';
    zsColor = '#091133';
    zmColor = '#483da0';
    fontFamily = 'Inter Regular';
    fontSize = '11px';
    iconSize = '4px';
    defaultHeight = '22px';
    fontWeight = '';
    heightBadge;
    labelPadding;
    fontColor;
    iconColor;
    bgColor;
    badgeBgColor;
    borderColor;
    cursor = 'pointer';

    constructor() { }

    ngOnInit() {
        this.app = this.app ? this.app : "zs";        //"zs" or "zm"
        this.type = this.type ? this.type : "dark";   //dark or light
        this.height = this.height ? this.height : this.defaultHeight;
        this.width = this.width ? this.width : "";
        this.iconSize = this.imgSize ? this.imgSize : (parseInt(this.height.split("px")[0])/2 - 3) + "px";
        this.heightBadge = parseInt(this.height.split("px")[0])-6 + "px"; 
        this.borderRadius = this.btnType === 'toggle' ? this.pos === 'left' ? '5px 0px 0px 5px' : '0px 5px 5px 0px' : this.borderRadius ? this.borderRadius : '130px';
        this.cursor = this.btnDisabled === 'true' || this.btnType === 'tag' || this.btnType === 'apply' || (this.btnType === 'toggle' && this.type === 'dark') ? 'default' : 'pointer'; 
        this.zsColor = this.btnType === 'icon' ? '#091133' : '#0d71a1';

        if(this.type === 'dark') {
            this.iconColor = '#FFFFFF';
            this.fontWeight = '300';
            if(this.btnType === 'apply') {
                this.fontColor = '#4AB100';
                if(this.btnDisabled === 'true') {
                    this.fontColor = '#FFFFFF';
                    this.bgColor = "#909090";
                    this.badgeBgColor = '#676767';
                    this.borderColor = "#909090";
                } else {
                    this.bgColor = '#E6F0AF';
                    this.badgeBgColor = '#4AB100';
                    this.borderColor = '#4AB100';
                }
            } else if(this.btnType === 'approve') {
                this.fontColor = '#ffffff';
                if(this.btnDisabled === 'true') {
                    this.fontColor = '#FFFFFF';
                    this.bgColor = "#909090";
                    this.badgeBgColor = '#676767';
                    this.borderColor = "#909090";
                } else {
                    this.bgColor = '#5AA422';
                    this.badgeBgColor = '#42800A';
                    this.borderColor = '#5AA422';
                }
            } else {
                this.fontColor = '#FFFFFF';
                if(this.btnDisabled === 'true') {
                    this.bgColor = "#909090";
                    this.badgeBgColor = '#676767';
                    this.borderColor = "#909090";
                } else {
                    this.bgColor = this.zsColor;
                    this.badgeBgColor = '#0C5980';
                    this.borderColor = this.zsColor;
                }
            }
        } else {
            this.bgColor = '#FFFFFF';
            this.fontWeight = '300';
            if(this.btnType === 'apply' || this.btnType === 'approve') {
                if(this.btnDisabled === 'true') {
                    this.fontColor = '#767676';
                    this.iconColor = this.imgColor ? this.imgColor : '#707070';
                    this.badgeBgColor = '#D8D4D4';
                    this.borderColor = this.imgColor ? this.imgColor : "#909090";
                } else {
                    this.fontColor = "#4AB100";
                    this.iconColor = '#FFFFFF';
                    this.badgeBgColor = '#4AB100';
                    this.borderColor =  '#4AB100';
                }
            } else {
                if(this.btnDisabled === 'true') {
                    this.fontColor = '#767676';
                    this.iconColor = this.imgColor ? this.imgColor : '#707070';
                    this.badgeBgColor = '#D8D4D4';
                    this.borderColor = this.imgColor ? this.imgColor : "#909090";
                } else {
                    this.fontColor = this.zsColor;
                    this.iconColor = this.imgColor ? this.imgColor : this.zsColor;
                    this.badgeBgColor = '#E3EDF2';
                    this.borderColor =  this.imgColor ? this.imgColor : this.zsColor;;
                }
            }

        }

        this.iconSize = this.label === 'Add' || this.label === 'Remove' ? '16px' : this.iconSize;
        this.labelPadding = this.imgPosition === 'left' ? '0px 13px 0px 7px' : '0px 7px 0px 10px';
        this.labelPadding = this.label === 'Add' ? '0px 0px 0px 8px' : this.label === 'Remove' ? '0px 8px 0px 0px' : this.labelPadding;

        if(this.btnType === 'tag') {
            this.bgColor = '#EFF1FB';
            this.fontWeight = '300';
            this.fontColor = '#777777';
            this.iconColor = '#777777';
            this.badgeBgColor = '#EFF1FB';
            this.borderColor = '#EFF1FB';
            this.iconSize = '12px';
            this.iconColor = '#777777';
            this.labelPadding = '0px 2px 0px 8px';
        }

        if(this.btnType === 'icon') { 
            this.labelPadding = '0px 0px 0px 0px';
            this.iconSize = '14px';
        }
    }

    onMouseOver(e) {
        if(this.btnType !== 'tag' && this.btnType !== 'apply' && this.btnType !== 'approve') {
                if(e.type === 'dark') {
                    if(this.btnDisabled === 'true') {
                        e.fontColor = this.imgColor ? this.imgColor : "#767676";
                        e.bgColor = this.imgBgColor ? this.imgBgColor : "#F0EFEF";
                        e.badgeBgColor = '#676767';
                    } else {
                        e.fontColor = this.btnType === 'toggle' ? '#FFFFFF': (this.imgColor ? this.imgColor : this.zsColor);
                        e.bgColor = this.btnType === 'toggle' ? '#091133' : '#EAF2FD';
                        e.badgeBgColor = this.imgBgColor ? this.imgBgColor : '#0C5980';
                    }
                } else {
                    if(this.btnDisabled === 'true') {
                        e.fontColor = this.imgColor ? this.imgColor : "#767676";
                        e.bgColor = this.imgBgColor ? this.imgBgColor : "#FFFFFF";
                        e.badgeBgColor = '#D8D4D4';
                    } else {
                        e.fontColor = this.imgColor ? this.imgColor : this.zsColor;
                        e.bgColor = this.btnType === 'toggle' ? '#F5FAFC' : (this.imgBgColor ? this.imgBgColor :'#EAF2FD');
                        e.badgeBgColor = '#CADCE5';
                    }
                }
        }
    }

    onMouseOut(e) {
        if(this.btnType !== 'tag' && this.btnType !== 'apply' && this.btnType !== 'approve') {
            if(e.type === 'dark') {
                e.fontColor = '#FFFFFF';
                e.iconColor = '#FFFFFF';
                e.fontWeight = '300';
                if(this.btnDisabled === 'true') {
                    e.bgColor = "#909090";
                    e.badgeBgColor = '#676767';
                    e.borderColor = "#909090";
                } else {
                    e.bgColor = this.zsColor;
                    e.badgeBgColor = '#0C5980';
                    e.borderColor = this.zsColor;
                }
            } else {
                this.bgColor = '#FFFFFF';
                this.fontWeight = '300';
                if(this.btnDisabled === 'true') {
                    this.fontColor = '#767676';
                    this.iconColor = this.imgColor ? this.imgColor :'#707070';
                    this.badgeBgColor = '#D8D4D4';
                    this.borderColor = this.imgColor ? this.imgColor :"#909090";
                } else {
                    this.fontColor = this.zsColor;
                    this.iconColor = this.imgColor ? this.imgColor : this.zsColor;
                    this.badgeBgColor = '#E3EDF2';
                    this.borderColor =  this.imgColor ? this.imgColor : this.zsColor;;
                }
            }
        }
    }

}
