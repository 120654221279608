import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { Project } from '../../content/project/project.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../../content/project/project.service';
import { Column, FieldType, Formatter, Formatters, GridOption, OnEventArgs, AngularGridInstance, Editors } from 'angular-slickgrid';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../common/components/message/message.service';
import $ from 'jquery';

class Param {
  group: string;
  param_value: string;
}

const customParamRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let add: boolean = false;
  if(columnDef.params.selectedGroup=='All' || (columnDef.params.selectedGroup!='All' && columnDef.params.selectedSubgroup=='All')){
    add = true;
  }
  if(columnDef.field=='param_value'){
      return dataContext.action !== 'delete' 
            ? `<input id='param_value' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' ${add ? 'disabled' : ''}></input>` 
            : columnDef.params.selectedGroup=='All' 
                      ? `<div class='text-truncate'><label title='${value}'> ${value}</label></div>` 
                      : `<input id='param_value-${dataContext.id}' class='ctmInputBox-1' type='text' value='${value}'></input>`;
  }else{
      return dataContext.action !== 'delete' 
            ? `<input id='group' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' ${add ? 'disabled' : ''}></input>` 
            : `<div class='text-truncate'><label title='${value}'> ${value}</label></div>`;
  }
};

const addRemoveParamFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
      let add = false;
      if(columnDef.params.selectedGroup=='All' || (columnDef.params.selectedGroup!='All' && columnDef.params.selectedSubgroup=='All')){
        add = true;
      }
      return value === 'delete' 
            // ? `<i class="fa fa-trash actionInlineBtn" data-toggle="modal" data-backdrop="false" data-target=#${columnDef.field}></i>` 
            ? `<i class="fa fa-trash actionInlineBtn ${columnDef.params.selectedGroup=='All' ? 'disabled-state' : ''}"></i>` 
            : `<i class="fa fa-plus actionInlineBtn actionplusBtn ${add ? 'disabled-state' : ''}"></i>`;
};

@Component({
  selector: 'zetta-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ParametersComponent implements OnInit {
  
  public viewParamsTableColDef: any;
  public paramsDataSource: any;
  public paramsDataSourceGroup: any;
  public paramsDataSourceTotal: any;
  public loggedInUserDetails;
  public tableSettings: object = { 'height': '100%', 'width': '100%', 'pageSize': 15, 'isNormalPagination': false };
  projectDetail = new Project();
  paramsGridRef: AngularGridInstance;
  addParam: Param = new Param();
  isParamsReady = false;
  paramstableRows: any = [];
  paramstableRowsSub: any = [];
  paramvalueInputs: any = [];
  paramvalueInputsSub: any = [];
  paramsTableColDef: any;
  gridOptions: any;
  eventData: any;
  totalRows = 0;
  noData = false;
  selectedGroup = "All";
  selectedSubgroup = "All";
  groups: any;
  subgroups: any;
  words = [];

  constructor(private projectService: ProjectService, 
              private activatedRoute: ActivatedRoute, 
              public zettaUtils: ZettaUtils,
              private messageSvc: MessageService) { }

  groupGrid(grid) {
    this.paramsGridRef = grid;
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
        this.projectDetail.project_id = params['id'];
    });
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.gridOptions = {
        enableGridMenu: false,
        frozenRow: 1,
        enableAddRow: false,
        selectable: true,
        editable: true,
        autoEdit: true,
        enableFiltering: true,
        CheckboxSelector: false,
        enableCellNavigation: true
    };
    this.getPaggedParams();
  }

  onRowClickEvent(eventData) {
    this.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const row = this.paramsGridRef.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
        // this.readParamDetails(row);
        if (event.target.className.includes('fa fa-plus actionInlineBtn')) {
            this.readParamDetails(row);
            if (this.validateNewParam()) {
                    this.addParam["id"] = this.getRandom(100000000000);
                    this.addParam["action"] = 'delete';
                    // if (this.selectedSubgroup!=='All') {
                    //   this.addParam["group"] = this.selectedSubgroup.toLowerCase() + '_' + this.addParam["group"];
                    // }
                    this.paramstableRows.push(this.addParam);
                    this.paramstableRowsSub.push(this.addParam);
                    let paramstableRowsTmp = [];
                    let item: any;
                    this.paramstableRows.forEach(element => {
                      item = { 'group': element.group, 'param_value': element.param_value };
                      paramstableRowsTmp = [ ...paramstableRowsTmp, ...item];
                    });
                    let paramstableRowsSubTmp = [];
                    let itemSub: any;
                    this.paramstableRowsSub.forEach(element => {
                      itemSub = { 'group': element.group, 'param_value': element.param_value };
                      paramstableRowsSubTmp = [ ...paramstableRowsSubTmp, ...itemSub];
                    });
                    paramstableRowsTmp.splice(0,1);
                    paramstableRowsSubTmp.splice(0,1);
                    this.projectService.saveParam(this.selectedGroup.toLowerCase(), paramstableRowsTmp, this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(responseList => {
                        // console.log(responseList)
                        this.isParamsReady = true;
                        if (this.selectedSubgroup=='All') {
                            this.paramsGridRef.dataView.setItems(this.paramstableRows);
                        }else{
                            this.paramsGridRef.dataView.setItems(this.paramstableRowsSub);
                        }
                        this.paramsGridRef.gridService.setSelectedRows([]);
                        this.addParam = new Param();
                        this.messageSvc.sendMessage({ message: 'Register has been added successfully', type: 'SUCCESS', hideboard: true });
                    }, err => {
                        this.isParamsReady = true;
                        this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
                    });
            }
        }

        if (event.target.className.includes('fa fa-trash actionInlineBtn')) {
              this.paramstableRows = this.paramstableRows.filter(param => param.id !== row.id);
              this.paramstableRowsSub = this.paramstableRowsSub.filter(param => param.id !== row.id);
              
              let paramstableRowsTmp = [];
              let item: any;
              this.paramstableRows.forEach(element => {
                  item = { 'group': element.group, 'param_value': element.param_value };
                  paramstableRowsTmp = [ ...paramstableRowsTmp, ...item];
              });

              let paramstableRowsSubTmp = [];
              let itemSub: any;
              this.paramstableRowsSub.forEach(element => {
                  itemSub = { 'group': element.group, 'param_value': element.param_value };
                  paramstableRowsSubTmp = [ ...paramstableRowsSubTmp, ...itemSub];
              });

              paramstableRowsTmp.splice(0,1);
              this.projectService.saveParam(this.selectedGroup.toLowerCase(), paramstableRowsTmp, this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(responseList => {
                  this.isParamsReady = true;
                  if (this.selectedSubgroup=='All') {
                      this.paramsGridRef.dataView.setItems(this.paramstableRows);
                  }else{
                      this.paramsGridRef.dataView.setItems(this.paramstableRowsSub);
                  }
                  this.paramsGridRef.gridService.setSelectedRows([]);
                  this.addParam = new Param();
                  this.messageSvc.sendMessage({ message: 'Register has been deleted successfully', type: 'SUCCESS', hideboard: true });
              }, err => {
                  this.isParamsReady = true;
                  this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
              });
        }
        
        if (this.selectedSubgroup=='All') {
            this.totalRows = this.paramstableRows.length -1;
        }else{
            this.totalRows = this.paramstableRowsSub.length -1;
        }
    }
  }
 
  onRowSelection() {
      this.paramsTableColDef[0].params.selectedGroup = this.selectedGroup;
      this.paramsTableColDef[1].params.selectedGroup = this.selectedGroup;
      this.paramsTableColDef[2].params.selectedGroup = this.selectedGroup;
      this.paramsTableColDef[0].params.selectedSubgroup = this.selectedSubgroup;
      this.paramsTableColDef[1].params.selectedSubgroup = this.selectedSubgroup;
      this.paramsTableColDef[2].params.selectedSubgroup = this.selectedSubgroup;
      this.getParamsDetails();
      this.paramsGridRef.dataView.setItems([]);
  }

  getParamsDetails() {
    if (this.selectedGroup=='All') {
        this.subgroups = [];
        this.selectedSubgroup = 'All';
    } else {
        if (this.selectedSubgroup=='All') {
            this.subgroups = [];
        } 
    }
    
    this.projectService.getParametersByProjectId(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe((data) => {
        this.paramsDataSource = data;  

        let concatedRowsParams = [];
        let concatedRowsParamsSub = [];
        if (this.selectedGroup=='All') {
            this.paramsDataSource.forEach(element => {
                element.params.forEach(subelement => {
                    this.words = subelement.group.split('_');
                    this.words[0] = this.words[0].charAt(0).toUpperCase() + this.words[0].slice(1).toLowerCase();
                    if (!this.subgroups.includes( this.words[0] )) {
                        this.subgroups.push(this.words[0]);
                    }
                    if (this.selectedSubgroup=='All') {
                        concatedRowsParams = [...concatedRowsParams, ...subelement];
                    } else {
                        if (this.selectedSubgroup == this.words[0] ) {
                            concatedRowsParams = [...concatedRowsParams, ...subelement];
                        }
                    }
                });
            });
        } else {
            this.paramsDataSource = this.paramsDataSource.filter(attribute => attribute.name === this.selectedGroup);
            this.subgroups = [];
            this.paramsDataSource[0].params.forEach(subelement => {
                this.words = subelement.group.split('_');
                this.words[0] = this.words[0].charAt(0).toUpperCase() + this.words[0].slice(1).toLowerCase();
                if (!this.subgroups.includes( this.words[0] )) {
                  this.subgroups.push(this.words[0]);
                }
                // if (this.selectedSubgroup=='All') {
                //   concatedRowsParams = [...concatedRowsParams, ...subelement];
                // } else {
                //     if (this.selectedSubgroup == this.words[0] ) {
                //         concatedRowsParams = [...concatedRowsParams, ...subelement];
                //     }
                // }

                if (this.selectedSubgroup!='All') {
                  if (this.selectedSubgroup == this.words[0] ) {
                    concatedRowsParamsSub = [...concatedRowsParamsSub, ...subelement];
                  }
                }

                concatedRowsParams = [...concatedRowsParams, ...subelement];

            });
        }

        // Subgroups  (ex. Global/Debug)
        this.paramstableRows = concatedRowsParams.map((item) => {
            item['action'] = 'delete';
            item['id'] = this.getRandom(100000000000);
            return item;
        });

        if (this.selectedSubgroup!='All') {
          this.paramstableRowsSub = concatedRowsParamsSub.map((item1) => {
              item1['action'] = 'delete';
              item1['id'] = this.getRandom(100000000000);
              return item1;
          });
        }else{
          this.paramstableRowsSub = [];
        }

        if (this.paramsTableColDef === undefined) {
            this.initGroupGrid();
        }

        const firstRow = this.getAddGroupRow();
        this.paramstableRows.splice(0, 0, firstRow);
        if (this.selectedSubgroup!='All') {
          this.paramstableRowsSub.splice(0, 0, firstRow);
        }
        
        this.totalRows = this.paramstableRows.length -1;
        this.isParamsReady = true;

        if (this.paramsGridRef) {
            this.paramstableRows.forEach(element => {
              element.id = this.getRandom(100000000000);
            });

            if (this.selectedSubgroup!='All') {
              this.paramstableRowsSub.forEach(element => {
                element.id = this.getRandom(100000000000);
              });
            }

            if (this.selectedSubgroup=='All') {
              this.paramsGridRef.dataView.setItems(this.paramstableRows);
            }else{
              this.paramsGridRef.dataView.setItems(this.paramstableRowsSub);
            }
            this.paramsGridRef.gridService.setSelectedRows([]);
        }

        if (this.selectedSubgroup=='All') {
          this.totalRows = this.paramstableRows.length -1;
        }else{
          this.totalRows = this.paramstableRowsSub.length -1;
        }
        
    }, err => {
      // this.isLoadingRight = false;
    });

  }

  // Validate New Param Details
  validateNewParam() {
    if ((this.addParam.group && this.addParam.param_value)) {
        return true;
    } else {
        this.messageSvc.sendMessage({ message: 'Please fill all fields', type: 'ERROR', hideboard: true });
        return false;
    }
  }

  setNewParamDetails(row) {
    row.group = this.addParam.group;
    row.param_value = this.addParam.param_value;
  }

  readParamDetails(row) {
    this.addParam.group = $('#group')[0]['value'];
    this.addParam.param_value = $('#param_value')[0]['value'];
  }

  readParamDetailsAllGroup(rows, rowsSub) {
    this.addParam.group = $('#group')[0]['value'];
    this.addParam.param_value = $('#param_value')[0]['value'];

    this.paramvalueInputs = [];
    let item: any;
    item = { 'id': Math.floor(Math.random() * Math.floor(100000000000)), 'group': this.addParam.group, 'param_value': this.addParam.param_value, 'action': 'add' };
    rows.forEach(row => {
      this.paramvalueInputs = [ ...this.paramvalueInputs, ...item];
      if ($("#param_value-" + row.id).val() != undefined) {
        item = { 'id': row.id, 'group': row.group, 'param_value': $("#param_value-" + row.id).val(), 'action': row.action };
      } else {
        item = { 'id': row.id, 'group': row.group, 'param_value': row.param_value, 'action': row.action };
      }
    });
    this.paramvalueInputs = [ ...this.paramvalueInputs, ...item];
    
    this.paramvalueInputsSub = [];
    let itemSub: any;
    itemSub = { 'id': Math.floor(Math.random() * Math.floor(100000000000)), 'group': this.addParam.group, 'param_value': this.addParam.param_value, 'action': 'add' };
    rowsSub.forEach(rowSub => {
      this.paramvalueInputsSub = [ ...this.paramvalueInputsSub, ...itemSub];
      itemSub = { 'id': rowSub.id, 'group': rowSub.group, 'param_value': $("#param_value-" + rowSub.id).val(), 'action': rowSub.action };
    });
    this.paramvalueInputsSub = [ ...this.paramvalueInputsSub, ...itemSub];
  }

  
  savebtn() {
    this.readParamDetailsAllGroup(this.paramstableRows, this.paramstableRowsSub)
    this.paramvalueInputs = this.paramvalueInputs.filter(param => param.action !== 'add');
    this.paramvalueInputsSub = this.paramvalueInputsSub.filter(param => param.action !== 'add');
    this.addParam["id"] = this.getRandom(100000000000);
    this.addParam["action"] = 'add';
    this.paramvalueInputs.unshift(this.addParam);
    this.paramvalueInputsSub.unshift(this.addParam);

    let paramstableRowsTmp = [];
    let item: any;
    this.paramvalueInputs.forEach(element => {
        item = { 'group': element.group, 'param_value': element.param_value };
        paramstableRowsTmp = [ ...paramstableRowsTmp, ...item];
    });

    let paramstableRowsSubTmp = [];
    let itemSub: any;
    this.paramvalueInputsSub.forEach(element => {
        itemSub = { 'group': element.group, 'param_value': element.param_value };
        paramstableRowsSubTmp = [ ...paramstableRowsSubTmp, ...itemSub];
    });
    paramstableRowsTmp.splice(0,1);
    if(paramstableRowsTmp.length > 0){
        this.projectService.saveParam(this.selectedGroup.toLowerCase(), paramstableRowsTmp, this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(responseList => {
            // console.log(responseList)
            this.isParamsReady = true;
            if (this.selectedSubgroup=='All') {
                this.paramsGridRef.dataView.setItems(this.paramvalueInputs);
            }else{
                this.paramsGridRef.dataView.setItems(this.paramvalueInputsSub);
            }
            this.paramsGridRef.gridService.setSelectedRows([]);
            this.addParam = new Param();
            this.messageSvc.sendMessage({ message: 'Registers has been updated successfully', type: 'SUCCESS', hideboard: true });
        }, err => {
            this.isParamsReady = true;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }
  }

  initGroupGrid() {
    this.paramsTableColDef = [
      {
          'displayname': 'Name',
          'physicalname': 'group',
          'sortable': false,
          'datatype': 'String',
          'filterable': true,
          'formatter': customParamRowFormatter,
          'minWidth': 220,
          'maxWidth': 450,
          'params': { 'placeholder': 'Add parameter name', 'selectedGroup': this.selectedGroup, 'selectedSubgroup': this.selectedSubgroup },
      }, {
          'displayname': 'Value',
          'physicalname': 'param_value',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': customParamRowFormatter,
          'minWidth': 100,
          'maxWidth': 300,
          'params': { 'placeholder': 'Add parameter value', 'selectedGroup': this.selectedGroup, 'selectedSubgroup': this.selectedSubgroup },
      }, {
          'displayname': ' ',
          'physicalname': 'action',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': addRemoveParamFormattter,
          'params': { 'selectedGroup': this.selectedGroup, 'selectedSubgroup': this.selectedSubgroup },
      }
    ];
  }

  getAddGroupRow() {
    return { 'id': Math.floor(Math.random() * Math.floor(100000000000)), 'group': '', 'param_value': '', 'action': 'add' };
  }

  getRandom(max) {
    max = Math.floor(Math.random() * Math.floor(max));
    let random = max.toString().split("0.")[0];
    return random;
  }
  
  getPaggedParams() {
    this.groups = [];
    this.subgroups = [];
    this.isParamsReady = false;

    this.projectService.getParametersByProjectId(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe((resp) => {
        this.paramsDataSource = resp;
        this.paramsDataSource.forEach(element => {
          this.groups.push(element.name);
        });
        if (this.paramsDataSource.length === 0) {
          this.noData = true;
        }

        let concatedRowsParams = [];
        this.paramsDataSource.forEach(element => {
            element.params.forEach(subelement => {
                subelement.id = this.getRandom(100000000000);
                concatedRowsParams = [...concatedRowsParams, ...subelement];
                this.words = subelement.group.split('_');
                this.words = this.words[0].charAt(0).toUpperCase() + this.words[0].slice(1).toLowerCase();
                if (!this.subgroups.includes( this.words )) {
                  this.subgroups.push(this.words);
                }
            });
        });

        this.groups.sort();
        this.subgroups.sort();
        this.paramsDataSource = concatedRowsParams;
        this.paramstableRows = this.paramsDataSource.map((item) => {
          item['action'] = 'delete';
          item['id'] = this.getRandom(100000000000);
          return item;
        });

        if (this.paramsTableColDef === undefined) {
            this.initGroupGrid();
        }
        const firstRow = this.getAddGroupRow();
        this.paramstableRows.splice(0, 0, firstRow);
        this.isParamsReady = true;

        if (this.paramsGridRef) {
            this.paramsGridRef.dataView.setItems(this.paramstableRows);
            this.paramsGridRef.gridService.setSelectedRows([]);
        }
        this.totalRows = this.paramsDataSource.length;
    }, err => {
      this.paramsDataSource = [];
    });
  }

}