import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-project-content',
  templateUrl: './project-content.component.html',
  styleUrls: ['./project-content.component.scss']
})
export class ProjectContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
