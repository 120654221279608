import { Component,Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ObservableService } from 'src/app/common/services/observable.service';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectService } from '../../project.service';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
  selector: 'zetta-pd-tasks',
  templateUrl: './pd-tasks.component.html',
  styleUrls: ['./pd-tasks.component.scss']
})
export class PdTasksComponent implements OnInit, AfterViewInit {

  public openTaskDS;
  public barChartUniqueID: any = Math.floor(Math.random() * 1000);
  public barChartWidth: number;
  public $barContainer;
  public barChartHeight: number;
  public isDataReady = false;
  public isChartInValid = false;
  public openTaskChartOptions: object = {
      'resizeRequired': true,
      'variable': 'value',
      'category': 'task',
      'isNumberFormat': true,
      'labelPercentValue': 68,
      'labelPercentLabel': 'Open Tasks',
      'isPercentRequired': true

  };
  public openTaskDataSource;
  private _project_entitlements = {};

  public barChartColors = ['#a13bf0', '#a13bf0', '#cca1ed', '#cca1ed'];
  public openTaskChartColors = ['#4bb100','#0e709f', '#eeb049'];
  public userinfo;
  @Input() usersData: any;
  isProjectAdmin = false;
  constructor(
    public activeRoute: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    public zettaUtils: ZettaUtils,
    public observeSvc: ObservableService,
    private _projectService: ProjectService) {
    this.userinfo = this._projectService.getObjectFromSession('userInfo');
  }

  ngOnInit() {
    this.observeSvc.getCurrentEntitlements.subscribe(data => {this._project_entitlements = data ? data :{};});
  }

  ngAfterViewInit() {
    const parent = this;
    const routeParams = parent.activeRoute.snapshot.params;

    parent.observeSvc.refreshDataGrid.subscribe(function (data) {
        setTimeout(() => {
            $('#chart_' + parent.barChartUniqueID + ' svg').remove();
              parent.renderBarChart();
        }, 10);
    });

    parent._projectService.getOpentasks(parent.userinfo.user_id, routeParams.id).subscribe(
      data => {
        parent.isDataReady = true;
        parent.openTaskDS = parent.prepareBarChartData(data);
        parent.openTaskDataSource = parent.getDonutParseData(data);
        setTimeout(() => {
            parent.renderBarChart();
        }, 100);
      },
      error => {
        parent.isDataReady = true;
        parent.isChartInValid = true;
        parent.openTaskDS = [];
        parent.openTaskDataSource = [];
      }
    );
  }

  isHavingAccess(){
    if (this.usersData) {
      const adminUser = this.usersData.filter(user => user.user_id == this.userinfo.user_id);
      if (adminUser.length) {
        this.isProjectAdmin = adminUser[0].role_name.toLowerCase() == 'project_admin' ? true : false;
      }
    }
    if (this.zettaUtils.isProcessRunning || !this.isProjectAdmin) {
      return true;
    }
    else (this.isProjectAdmin)
    {
      return false;
    }
  }

  onResize(event) {

  }

  prepareBarChartData(data) {
    const dataSet = [];
    data.forEach((e: any) => {
      if (e['display_name'] !== 'Completed' && e['display_name'] !== 'In Review' ) {
        dataSet.push({ 'area': e['display_name'], 'value': e['value'] });
      }
    });
    return dataSet.reverse();
  }

  getDonutParseData(data) {
		const dataSet = [];
		data.forEach((element) => {
    		if (element.name === 'all_completed_training' || element.name === 'all_in_review_training' ) {
          dataSet.push({'task': element.display_name, 'value':  element.value });
          if (element.name === 'all_in_review_training') {
            this.openTaskChartOptions['labelPercentValue'] = element.value;
          }
    		}
		});
		return dataSet;
	}

  renderBarChart() {
    const parent = this;
    this.isChartInValid = false;
    parent.$barContainer =  $('.gray-task-container');

    parent.barChartHeight = parent.$barContainer.outerHeight(true);
    parent.barChartWidth = parent.$barContainer.outerWidth(true) - 10;

    if (parent.barChartWidth === undefined || parent.barChartHeight === undefined) {
           this.isChartInValid = true;
            return false;
    }

    if (this.openTaskDS && parent.openTaskDS.length > 0) {
      const maxValue = Math.max.apply(Math, parent.openTaskDS.map(function(o) { return parseInt(o.value, 10); }));
      if (maxValue <= 0) {
          this.isChartInValid = true;
          return false;
      }
    }

    let  colour = d3.scaleOrdinal()
        .range(parent.barChartColors),
      margin = { top: 20, right: 10, bottom: 20, left: 79 },
      width = parseFloat(parent.barChartWidth.toString()) - margin.left - margin.right,
      height = parseFloat(parent.barChartHeight.toString()) - margin.top - margin.bottom;

    let svg = d3.select('#chart_' + parent.barChartUniqueID).append('svg:svg')
    .attr('width', parent.barChartWidth)
    .attr('height', parent.barChartHeight);

    let tooltip = d3.select('#chart_' + parent.barChartUniqueID).append('div').attr('class', 'toolTip');

    let x = d3.scaleLinear().range([0, width]);
    let y = d3.scaleBand().range([height, 0]);

    var axis = d3.axisTop(x).ticks(6).tickFormat(function(d) { return (d); }).tickSizeInner([- height]);

    let g = svg.append('g')
      .attr('transform', 'translate(' + (parent.barChartHeight * 0.68 ) + ',' + margin.top + ')');
    x.domain([0, d3.max(parent.openTaskDS, function(d) { return parseInt(d.value, 10); })]).nice();
    y.domain(parent.openTaskDS.map(function(d) { return d.area; })).padding(0.1);

    g.append('g')
      .attr('class', 'x axis x_axis')
      .call(axis);

    g.append('g')
      .attr('class', 'y axis')
      .call(d3.axisLeft(y).tickSize(0).tickSizeOuter(0))
      .selectAll('text')
      .attr('x', '-75')
      .attr('dy', '-0.25em')
      .style('text-anchor', 'start');

    let tickItems = svg.selectAll(".x_axis .tick");
    let maxTickPosValue = 0;
    tickItems.each(function(data,index) {
      if(tickItems.size()-1 === index){
        var tick = d3.select(this).attr("transform");
        maxTickPosValue = tick.substring(10, tick.indexOf(','));        
      }
    });

    const background = svg.selectAll(null)
      .data(parent.openTaskDS)
      .enter()
      .append('rect')
      .attr('x', function(d) {
        return parent.barChartHeight * 0.69;
      })
      .attr('y', function(d, i) {
        return y(d.area) + 22;
      })
      .attr('height', y.bandwidth() - 10)
      .attr('width',maxTickPosValue)
      .attr('rx', 5)
      .attr('ry', 5)
      .style('fill', (d, i) => fillbgColor(parseFloat(d.value)));

      const bars = svg.selectAll(null)
      .data(parent.openTaskDS)
      .enter()
      .append('rect')
      .attr('x', function(d) {
        return parent.barChartHeight * 0.69;
      })
      .attr('y', function(d, i) {
        return y(d.area) + 22;
      })
      .attr('height', y.bandwidth() - 10)
      .attr('width', function(d) {
        return x(d.value);
      })
      .attr('rx', 5)
      .style('stroke', (d, i) => getColor(d.area, i))
      .attr('fill', (d, i) => getColor(d.area, i))
        .on('mousemove', function(d){
                tooltip
                  .style('left', d3.event.clientX - 50 + 'px')
                  .style('top', d3.event.clientY - 40 + 'px')
                  .style('display', 'inline-block')
                  .html("<strong>"+d.area+":</strong> <span style='color:red'>" + d.value + "</span>");              
            })
            .on('mouseout', function(d){ tooltip.style('display', 'none');});

          function getColor(text, i) {
              text = text || '';
              switch (text.toLowerCase()) {
                  case 'match fixing':
                      return '#cca1ed';
                      break;
                  case 'merge fixing':
                      return '#cca1ed';
                      break;
                  case 'merge training':
                      return '#a13bf0';
                      break;
                  default:
                      return '#a13bf0';
              }
          }

          function fillbgColor(value) {
           return value > 0 ? '#ddd' : 'none';
          }
    };

}
