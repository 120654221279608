
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Column } from 'ag-grid-community';

@Component({
  selector: 'app-custom-checkbox-renderer',
  template: `
    <div  *ngIf="Column==='Rule Admin'" class="text-center">
      <i *ngIf="value === 'dq_rule_admin'" class="fal fa-check actionInlineBtn blueLink pl-1"></i>
      </div>
      <div *ngIf="Column==='Rule Executor'" class="text-center">
      <i *ngIf="value === 'dq_rule_executor'" class="fal fa-check actionInlineBtn blueLink"></i>
    </div>
  `,
})
export class UserEntilementCheckboxRendererComponent implements ICellRendererAngularComp {
  public value: any;
  Column:any;
  agInit(params: any): void {
    this.value = params.value;
    this.Column =params.column.colDef.headerName
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
