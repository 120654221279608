
import { Column, Formatter, Formatters} from 'angular-slickgrid';

export const dateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => dateFormat(value);

function dateFormat(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    return `<div class='text-left' title='${month}/${d.getDate()}/${d.getFullYear()}'>${month}/${d.getDate()}/${d.getFullYear()}</div>`;
}
