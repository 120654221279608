import { Inject, Injectable, NgZone, Compiler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppGlobals } from '../shared/app.globals';
import { MessageService } from '../components/message/message.service';


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private zone: NgZone,
        private _compiler: Compiler,
        private messageSvc: MessageService) {
        this.APIToken = 'Basic c3luYWk6cGFzc3BvcnRzcw==";//localStorage.getItem("id_token")';
    }

    private APIToken = null;
    private defaultApplicationHeaders = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
    };

    buildRequestHeaders(): HttpHeaders {  
        const jwtLoginHeader = {
            'Authorization' : 'Bearer '+ localStorage.getItem('access_token'),
            'authtype': sessionStorage.getItem('enable_jwt') && sessionStorage.getItem('enable_jwt') === 'true' ? 'jwt' : 'other'
        };    
        const headers = sessionStorage.getItem('enable_jwt') === 'true' ? { ...this.defaultApplicationHeaders, ...jwtLoginHeader} : {...this.defaultApplicationHeaders , ...{authtype: 'other'}};        
        return new HttpHeaders(headers);
    }
    getAuthType(): string {
        return sessionStorage.getItem('enable_jwt') ? 'jwt' : 'other'; 
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const headers = this.buildRequestHeaders();
        // console.log('Interceptor req.url common service:', req.url);
        const authReq = req.clone({
            headers: headers,
            withCredentials: true
        });
        // console.log('--------------------Auth Interceptor Start----------------------------------');
        // console.log('headers keys:', headers.keys());
        // console.log('URL:', req.url);
        // console.log('---------------------Auth Interceptor End------------');
        return next.handle(authReq)
            .pipe(
                tap(
                    (result: HttpEvent<any>) => { },
                    (error: HttpErrorResponse) => {
                        // NOTE: Need these consoles as we are not having full feldged error handling
                        // console.error("error response intercepted", error);
                        // console.log('req url :: ' + req.url);
                        if (error.status === 401) {
                            sessionStorage.clear();
                            localStorage.clear();
                            this._compiler.clearCache();
                            if (this.router.url !== AppGlobals.ZETTASENSE_PATH) {
                                this.router.navigate([AppGlobals.ZETTASENSE_LOGIN]);
                            }
                        }
                        if (error.status === 403) {
                            // alert('You are not authorized to access this page.');
                            this.messageSvc.sendMessage({
                                message: (error.error && error.error.message) ? error.error.message : 'Unauthorized',
                                type: 'INFO',
                                hideboard: true
                            });
                            //this.authenticationService.logOut(); // commenting this code as it is not required to logout in case of 403
                            return;
                        }
                        if (error.status === 400) {
                            /* Commenting because this is giving Bad request alert on the validation errors.*/
                            // this.messageSvc.sendMessage({
                            //     message: (error.error[0] && error.error[0].message) ? error.error[0].message : 'Bad Request',
                            //     type: 'ERROR',
                            //     hideboard: true
                            // });
                            return;
                        }
                        if (error.status === 404) {
                            /* Removing the 404 error handling message as for the project page services are returning the response
                             * along with 404 and we are displaying the Data not available instead of showing the error.*/
                            return;
                        }
                        if (error.status === 500) {                            
                            // if(error.error.error.substring(0, 38) !== 'No New latest Job found for project id') {
                            //     this.messageSvc.sendMessage({
                            //         message:  AppGlobals.INTERAL_SERVER_ERROR,
                            //         type: 'ERROR',
                            //         hideboard: true
                            //     });
                            // };
                            return;
                        }
                        if (error.status === 401) {
                            if (error.error.message) {
                                this.messageSvc.sendMessage({
                                    message: error.error.message,
                                    type: 'WARN',
                                    hideboard: true
                                });
                            }
                            this.authenticationService.logOut();
                            return;
                        }
                    }
                )
            );
    }
}
