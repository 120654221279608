import { Project } from '../../../../zettasense/content/project/project.model';
import { IDataset } from '../dataset.model';
import { ProjectService } from '../../../../zettasense/content/project/project.service';
import { DatasetService } from '../dataset.service';
import { AngularGridInstance, Formatter, Column, Editors } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { MessageService } from '../../message/message.service';
import { radioButtonFormatter } from 'src/app/common/shared/formatters/radioButtonFormatter';
import { environment } from 'src/environments/environment';
import { viewUserFormatter } from '../../../shared/formatters/viewUserFormatter';
import * as _ from 'lodash';

export const radioButtonFormattr: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    let radioDisabled;
    radioDisabled = columnDef.params.user_id === dataContext.user_id ? 'disabled' : '';
    const spacing = columnDef.field === 'isAdmin' ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : columnDef.field === 'isReadOnly' ? '&nbsp;&nbsp;&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;';
    columnDef.field = columnDef.field === 'Read/Write' ? 'isReadWriteOnly' : columnDef.field; 
        return value ? `${spacing}<div id="cell-${columnDef.field}-${row}" class="formatter match-radio-formatter div-checkbox radio-iteem">
                            <input type="radio" name="radio-${row}" id="radio-${columnDef.field}-${row}" checked ${radioDisabled}>
                            <label for="radio-${columnDef.field}-${row}" class="radio-formatter radio-label m-0 pointer"></label>
                        </div>` 
                    : `${spacing}<div id="cell-${columnDef.field}-${row}" class="formatter match-radio-formatter div-checkbox radio-iteem">
                            <input type="radio" name="radio-${row}" id="radio-${columnDef.field}-${row}">
                            <label for="radio-${columnDef.field}-${row}" class="radio-formatter radio-label m-0 pointer"></label>
                        </div>`;

}

export const userFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<i class='fas fa-user userIcon'></i><span class="roundedCorner circle-icon" title='${dataContext.selectUsersCount}'><span class="user-authorization">${dataContext.selectUsersCount}</span></span>`;
}
    
const RIGTH_EMPTY_GRID_MSG = 'Select one or more groups from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';

@Component({
    selector: 'zetta-user-entitlements',
    templateUrl: './user-entitlements.component.html',
    styleUrls: ['./user-entitlements.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserEntitlementsComponent implements OnInit, OnDestroy, DoCheck {

    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;

    tableSettings: object = { 'height': "100%", 'width': "100%" };
    rightTableSettings: object = { 'height': "100%", 'width': "99.42%" };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];
    datasetId;
    selectedCenterRowIndex = -1;
    tableGridSelectedItemsSize = 0;
    usersSelectedRows: any = [];
    tableSelectedRows: any = [];
    groupSelected = 0;
    userSelected = 0;
    groupName: string;
    selectedUserLength: number;
    selectRightGridUsers  = {};
    isShowRightGridDataSource = false;
    isAdminSelectAll = false;
    isReadOnlySelectAll = false;
    isReadWriteOnlySelectAll = false;
    isAdminChecked = false;
    isReadWriteonlyChecked = false;
    isReadonlyChecked = false;
    isUserSelected = false;

    isAdminChk: any = { top: '0px', left: '0px' };
    isReadOnlyChk: any = { top: '0px', left: '0px' };
    isReadWriteOnlyChk: any = { top: '0px', left: '0px' };
    isAuth: any = { top: '0px', left: '0px' };

    usersSavedIds: any = [];
    dataset_ids: any = [];
    isDatasetAdmin=false;
    default_access_level: any =[];
    entitlements: any = [];
    isInitialDataLoad: boolean = false;// used to show right gridto handle condition onselectrows changed
    constructor(public projectSvc: ProjectService,
        public datasetSvc: DatasetService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService) { }
    
    ngDoCheck() {        
        if ( $("#cell-isAdmin-0").length ) {
            var isAdmin = $("#cell-isAdmin-0").offset();
            var isReadOnly = $("#cell-isReadOnly-0").offset();
            var isReadWriteOnly = $("#cell-isReadWriteOnly-0").offset();            
            this.isAdminChk = { top: (Math.round(isAdmin.top) - 32) + 'px', left: (Math.round(isAdmin.left)-8) + 'px' };
            this.isReadOnlyChk = { top: (Math.round(isReadOnly.top) - 32) + 'px', left: (Math.round(isReadOnly.left)-8) + 'px' };
            this.isReadWriteOnlyChk = { top: (Math.round(isReadWriteOnly.top) - 32) + 'px', left: (Math.round(isReadWriteOnly.left)-8) + 'px' };
            this.isAuth = { top: (Math.round(isAdmin.top) - 32) + 'px', left: (Math.round(isAdmin.left)-15) + 'px' };
        }  
    }
  
    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
        this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
        this.progressState.isEdit ? sessionStorage.setItem('newDataSet', 'false') : sessionStorage.setItem('newDataSet', 'true');
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');        
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }
        this.isInitialDataLoad = true;
        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
            if(sessionStorage.getItem("bulkDatasetIds")) {
                this.dataset_ids = sessionStorage.getItem("bulkDatasetIds").split(",");
            } 
        } else {
            if(this.datasetId === null) {
                this.activatedRoute.parent.params.subscribe(params => {
                    this.datasetId = params['id'];
                });
            }
            this.dataset_ids = [this.datasetId];     
        }       
        let datasetIdSelected = this.showBreadcrumb ? this.datasetId: (this.dataset_ids && this.dataset_ids.length) ? this.dataset_ids[0]: null;
        this.getDataOfDatasetAndUsersGroup(datasetIdSelected);
        
        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.gridColumnsDef = [{
            'displayname': 'Group Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Users',
            'physicalname': '',
            'sortable': true,
            'datatype': 'String',
            'filterable': false,
            'minWidth': 80,
            'cssClass': 'd-flex',
            'formatter': userFormatter
        }, {
            'displayname': ' ',
            'physicalname': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'maxWidth': 96,
            'formatter': viewUserFormatter
        }];
       
        this.rightGridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 180,
            'maxWidth': 200,
        }, {

            'displayname': 'Admin',
            'physicalname': 'isAdmin',
            'sortable': false,
            'datatype': 'boolean',
            'filterable': false,
            'formatter': radioButtonFormattr,           
            'maxWidth': 80,
            'headerCssClass': 'mr-l-12',
            'params': { user_id: this.loggedInUserDetails['user_id'], showBreadcrumb: this.showBreadcrumb }
        }, {
            'displayname': 'Read Only',
            'physicalname': 'isReadOnly',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'columnGroup': 'Authorizations',
            'formatter': radioButtonFormattr,         
            'maxWidth': 80,
            'headerCssClass': 'mr-l-13',
            'params': { user_id: this.loggedInUserDetails['user_id'], showBreadcrumb: this.showBreadcrumb }
        }, {
            'displayname': 'Read/Write',
            'physicalname': 'isReadWriteOnly',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'columnGroup': 'Authorizations',
            'formatter': radioButtonFormattr,           
            'maxWidth': 80,
            'params': { user_id: this.loggedInUserDetails['user_id'], showBreadcrumb: this.showBreadcrumb }
        }];
        
    }


    getDataOfDatasetAndUsersGroup(datasetId) {
        
        if (datasetId) {
            const datasetInfo: IDataset = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                dataset_id: datasetId
            };
            
            let self = this;
            this.setupProjectMode();
            this.datasetSvc.getDatasetAndUsersGroupData(datasetInfo).subscribe((response) => {
                if (response) {
                    let datasetData = response.datasetData;
                    let userGroupData = response.userGroupData;
                    this.usersSavedIds = datasetData && datasetData['entitlement'] && datasetData['entitlement']['users'] && datasetData['entitlement']['users'].length ?  datasetData['entitlement']['users'] : [];
                    self.parseDatasetAndUserGroupData(datasetData, userGroupData);
                }
            }, (error) => {
                this.messageSvc.sendMessage({ message: 'Retrieve Dataset Failed', type: 'ERROR', hideboard: true });
                this.resetEntitlementStates();
            })
        } else {
            this.messageSvc.sendMessage({ message: 'Invalid dataset', type: 'ERROR', hideboard: true });
            this.resetEntitlementStates();
        }
    }


    resetEntitlementStates() {
        this.entitlements = [];
        this.isDatasetAdmin = false;
        this.leftGridDataSource = [];
        this.rightGridDataSource = [];
        this.isDataReady = true;
    }

    parseDatasetAndUserGroupData(datasetData, userGroupData) {
        if (datasetData && userGroupData && userGroupData.currentpage && userGroupData.currentpage.length) {

            // setting up dataset admin and entitlements
            if (datasetData && datasetData.entitlement && datasetData.entitlement.users) {
                this.entitlements = datasetData.entitlement.users ? datasetData.entitlement.users: [];
                const dataset_admin =  this.entitlements.filter(user => user.user_id === datasetData.created_by_id);
                if (dataset_admin.length) {
                    const access = dataset_admin[0].default_access_level;
                    if (access === "read_only") {
                        this.default_access_level = { read_only: true };
                    } else if (access === "is_admin") {
                        this.default_access_level = { is_admin: true };
                    } else if (access === "read_write") {
                        this.default_access_level = { read_write: true };
                    }
                    if (dataset_admin[0].is_admin) {
                        this.isDatasetAdmin = true;
                    }
                }
            } else {
                this.entitlements = [];
                this.isDatasetAdmin = false;
            }
             

            // setting up user group data(Left grid)
            userGroupData.currentpage.forEach((userGroup, index) => {
                userGroup.id = Math.random();
                userGroup.selectGroupsCount = 0;
                userGroup.selectUsersCount = 0;
                userGroup.defaultSelectedUsersLength = 0;                
                userGroup['selectedRightGridRows'] = [];
                userGroup.groupsCount = 0;
                if (userGroup.users && userGroup.users.length) {
                    
                    userGroup.users.forEach((user) => {
                        
                        let existingDatasetUserIndex = this.usersSavedIds.findIndex((datasetUser) => {
                            return datasetUser.user_id === user.user_id;
                        })
                        
                        if (existingDatasetUserIndex > -1) {

                            user.isAdmin = this.usersSavedIds[existingDatasetUserIndex].is_admin ? true : false;
                            user.isReadWriteOnly = this.usersSavedIds[existingDatasetUserIndex].read_write ? true: false;
                            user.isReadOnly = this.usersSavedIds[existingDatasetUserIndex].read_only ? true: false;



                            if (!this.tableSelectedRows.includes(index)) {
                                this.tableSelectedRows.push(index);
                                userGroup.groupsCount = userGroup.groupsCount + 1;
                            }
                       
                            if (!userGroup['selectedRightGridRows'].includes(existingDatasetUserIndex)) {
                                userGroup['selectedRightGridRows'].push(existingDatasetUserIndex);
                                userGroup.selectUsersCount = userGroup.selectUsersCount + 1;
                            }
                        } else {
                            user.isAdmin = false;
                            user.isReadOnly = false;
                            user.isReadWriteOnly = false;
                        }
                        userGroup.defaultSelectedUsersLength = userGroup['selectedRightGridRows'].length;
                    })
                }
            });
            
            this.leftGridDataSource = userGroupData.currentpage;

            // Right grid data
            this.isDataReady = true;
            
            let rightGridData = this.leftGridDataSource && this.leftGridDataSource.length ? this.leftGridDataSource[0].users: [];                 
            if (this.isDatasetAdmin && rightGridData && rightGridData.length && this.leftGridDataSource && this.leftGridDataSource.length) { 

                const default_user = rightGridData.filter(user => user.user_name.toLowerCase() === AppGlobals.DEFAULT_USER_SETTING_TEXT.toLowerCase());
                if(!default_user.length){
                    const firstRow = this.getDefaultSettingRow();
                    rightGridData.splice(0, 0, firstRow);   
                    this.leftGridDataSource.forEach((element) => {
                        if (element && element['selectedRightGridRows'] && element['selectedRightGridRows'].length) {
                            element['selectedRightGridRows'].forEach((el,index) => {                            
                                element['selectedRightGridRows'][index]=index+1;                             
                            });    
                        }
                        this.groupName = element.name;
                        element['selectedRightGridRows'] = (element['selectedRightGridRows'] && element['selectedRightGridRows'].length > 0) ? element['selectedRightGridRows'] : [];
                    });      
                    this.enableNextButtton();
                    rightGridData.forEach(ele => {
                        ele.id = Math.random();
                        ele.isDefault=false;
                    });
                    if (!this.showBreadcrumb && this.datasetId == null && this.usersSavedIds.length == 0) {
                        rightGridData.forEach((row) => {
                            this.entitlements.forEach((data) => {
                                if (data.user_id == row.user_id) {
                                    if (data.is_admin === true) {
                                        row['isAdmin'] = true;
                                    } else if (data.read_write === true) {
                                        row['isReadWriteOnly'] = true;
                                    } else if (data.read_only === true) {
                                        row['isReadOnly'] = true;
                                    }
                                }
                            });
                        });
                        this.enableNextButtton();
                    }
                }   
            }
            
            if (this.showBreadcrumb) {
                this.selectedCenterRowIndex = 0;
                this.isShowRightGridDataSource = true;
                this.groupSelected = 1;
            }
            this.rightGridDataSource = rightGridData;
            this.rightGridRows = this.rightGridDataSource.length;
        } else {
            this.entitlements = [];
            this.isDatasetAdmin = false;
            this.leftGridDataSource = [];
            this.rightGridDataSource = [];
            this.isDataReady = true;
        }
    }


    setupProjectMode() {
        this.progressState.states = this.zettaUtils.getStateList('create-dataset');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Data Set';
    }

    getDatasetEntitlements() {
        const getDataset: IDataset = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.dataset_ids[0]
        };
        this.datasetSvc.getDataset(getDataset).subscribe(resp => {
            if (resp) {
                if (resp.entitlement.users) {
                    this.entitlements = resp.entitlement.users;
                    const dataset_admin = resp.entitlement.users.filter(user => user.user_id === resp.created_by_id)
                    if (dataset_admin.length) {
                        const access = dataset_admin[0].default_access_level;
                        if (access === "read_only") {
                          this.default_access_level = { read_only: true };
                        } else if (access === "is_admin") {
                          this.default_access_level = { is_admin: true };
                        } else if (access === "read_write") {
                          this.default_access_level = { read_write: true };
                        }
                        if (dataset_admin[0].is_admin) {
                            this.isDatasetAdmin = true;
                        }
                    }
                }
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }
    getDefaultSettingRow() {
             return {
                'id': Math.random(),
                'user_name': 'New User Default Settings',
                'isAdmin': this.default_access_level.is_admin==true?true:false,
                'isReadOnly': this.default_access_level.read_only==true?true:false,
                'isReadWriteOnly': this.default_access_level.read_write==true?true:false
            };
    }
    onLeftGridCellClicked(e) {        
        const args = e.args;
        var adminselectedrows = [];
        var readonlyselectedrows = [];
        var readwriteonlyselectedrows = [];
        let userDataSource = [];
        const eventEle = e.eventData;
        if (eventEle.target.title === 'View Users') {
            this.selectedCenterRowIndex = args.row;
            const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
            this.rightGridDataSource = row.users;
            this.groupName = row.name;
            this.enableNextButtton();
            row['selectedRightGridRows'] = (row['selectedRightGridRows'] && row['selectedRightGridRows'].length > 0) ? row['selectedRightGridRows'] : [];
            this.rightGridDataSource.forEach(element => {
                element.id = Math.random();
                element.isDefault=false;
            });
            if (this.isDatasetAdmin) { 
                const default_user = this.rightGridDataSource.filter(user => user.user_name.toLowerCase() === AppGlobals.DEFAULT_USER_SETTING_TEXT.toLowerCase());
               if(!default_user.length){
                const firstRow = this.getDefaultSettingRow();
                this.rightGridDataSource.splice(0, 0, firstRow);                                 
               }                
            }
            if (!this.showBreadcrumb && this.datasetId == null && this.usersSavedIds.length == 0) {
                this.rightGridDataSource.forEach((row) => {
                    this.entitlements.forEach((data) => {
                        if (data.user_id == row.user_id) {
                            if (data.is_admin === true) {
                                row['isAdmin'] = true;
                            } else if (data.read_write === true) {
                                row['isReadWriteOnly'] = true;
                            } else if (data.read_only === true) {
                                row['isReadOnly'] = true;
                            }
                        }
                    });
                });
                this.enableNextButtton();
            }
         
            if (this.rightGrid) {                
                this.rightGrid.dataView.setItems(this.rightGridDataSource);
                if (row['selectedRightGridRows'].length === 0) {
                   // this.enableAllCheckbox(row);
                } else {
                    this.rightGrid.gridService.setSelectedRows(row['selectedRightGridRows']);
                    this.enableAllCheckbox(row);
                }

                this.rightGridDataSource.forEach((row, index) => {
                    if (row['isAdmin'] === true) {
                        adminselectedrows.push(index);
                    }
                    if (row['isReadOnly'] === true) {
                        readonlyselectedrows.push(index);
                    }
                    if (row['isReadWriteOnly'] === true) {
                        readwriteonlyselectedrows.push(index);
                    }

                    if (adminselectedrows.length == this.rightGridDataSource.length) {
                        $("#isAdmin").prop("checked", true);
                        this.isAdminChecked = true;
                    } else {
                        $("#isAdmin").prop("checked", false);
                        this.isAdminChecked=false;
                    }

                    if (readonlyselectedrows.length == this.rightGridDataSource.length) {
                        $("#isReadOnly").prop("checked", true);
                        this.isReadonlyChecked = true;
                    } else {
                        $("#isReadOnly").prop("checked", false);
                        this.isReadonlyChecked=false;
                    }

                    if (readwriteonlyselectedrows.length == this.rightGridDataSource.length) {
                        $("#isReadWriteOnly").prop("checked", true);
                        this.isReadWriteonlyChecked = true;
                    } else {
                        $("#isReadWriteOnly").prop("checked", false);
                        this.isReadWriteonlyChecked = false;
                    }
                });
            }
            this.isShowRightGridDataSource = true;
        }
    }

    leftGridCreated(grid) {
        const parent = this;
        parent.leftGrid = grid;
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.tableGridSelectedItemsSize = args.rows.length;
            parent.isShowRightGridDataSource = parent.isInitialDataLoad ? parent.isShowRightGridDataSource: false; // added 'isInitialDataLoad' to show right grid be feauly in view dataset screen
            parent.isInitialDataLoad = false;
            parent.tableSelectedRows = args.rows;
            parent.reRenderSlickGrid(parent.leftGrid);
            parent.groupSelected = args.rows.length;
            parent.usersSelectedRows = args.rows;
            parent.updateLeftGridProperties();
            if (parent.tableGridSelectedItemsSize == 0) {
                parent.isShowRightGridDataSource = false;
                parent.rightGridDataSource.forEach(row => {
                    if (row['isAdmin'] === true) { 
                        row['isAdmin'] = false; 
                    }

                    if (row['isReadOnly'] === true) { 
                        row['isReadOnly'] = false; 
                    }

                    if (row['isReadWriteOnly'] === true) { 
                        row['isReadWriteOnly'] = false; 
                    }

                    $("#isAdmin").prop("checked", false);
                    $("#isReadOnly").prop("checked", false);
                    $("#isReadWriteOnly").prop("checked", false);
                });
                parent.selectedUserLength = 0;
            }
            if (parent.leftGridDataSource.length !== parent.tableGridSelectedItemsSize) {
                parent.reRenderSlickGrid(parent.leftGrid);
            }
        });
        parent.leftGrid.slickGrid.setSelectedRows(parent.tableSelectedRows);  // OjO <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
        // parent.isShowRightGridDataSource = true;
    }

    updateLeftGridProperties() {        
        const parent = this;
        parent.userSelected = 0;
        for (let centerGridIndex = 0; centerGridIndex < parent.leftGridDataSource.length; centerGridIndex++) {
            const leftGridRow = parent.leftGridDataSource[centerGridIndex];
            leftGridRow.selectUsersCount = 0; // -1;
            leftGridRow.selectGroupsCount = 0; // -1;
            for (let selectedRowsIndex = 0; selectedRowsIndex < parent.usersSelectedRows.length; selectedRowsIndex++) {
                if (centerGridIndex === parent.usersSelectedRows[selectedRowsIndex]) {
                    leftGridRow.selectUsersCount = leftGridRow.defaultSelectedUsersLength;
                    leftGridRow.selectGroupsCount = parent.groupSelected;
                    parent.userSelected += leftGridRow.selectUsersCount;
                    break;
                }
            }
        }
        parent.reRenderSlickGrid(parent.leftGrid);
    }

    onRightGridCellClick(e): void {        
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
        const dataSet = this.rightGrid.dataView.getItems();
        row['selectedRightGridRows'] = args.row;
        if(row.user_id !== this.loggedInUserDetails['user_id'] && this.showBreadcrumb) {
            if (row !== undefined && row !== null) {
                if (fieldName === 'isAdmin') {
                    row['isAdmin'] = !row['isAdmin'];
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = false;
                } else if (fieldName === 'isReadOnly') {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = !row['isReadOnly'];
                    row['isReadWriteOnly'] = false;
                } else if (fieldName === 'isReadWriteOnly') {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = !row['isReadWriteOnly'];
                }
                this.enableNextButtton();
                this.enableAllCheckbox(args.row);
            }
        } else if (row.user_id !== this.loggedInUserDetails['user_id'] &&!this.showBreadcrumb) {
            if (row !== undefined && row !== null) {
                if (fieldName === 'isAdmin') {
                    row['isAdmin'] = !row['isAdmin'];
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = false;
                } else if (fieldName === 'isReadOnly') {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = !row['isReadOnly'];
                    row['isReadWriteOnly'] = false;
                } else if (fieldName === 'isReadWriteOnly') {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = !row['isReadWriteOnly'];
                }
                this.enableNextButtton();
                this.enableAllCheckbox(args.row);
            }
        }
        if(!this.showBreadcrumb) {
            this.reRenderSlickGrid(this.rightGrid);
        }
    }

    rightGridCreated(grid) {
        const parent = this;
        parent.rightGrid = grid;
        parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            const rightGridRow = parent.leftGridDataSource[parent.selectedCenterRowIndex];
            rightGridRow['selectedRightGridRows'] = args.rows;
            rightGridRow.defaultSelectedUsersLength = args.rows.length;
            parent.selectedUserLength = args.rows.length;
            parent.selectRightGridUsers[rightGridRow.group_id] = [];
            if (args.rows.length) {
                parent.rightGridDataSource.forEach((row, index) => {
                    let selectedUser = args.rows.filter(val => val == index);
                    row.isDefault = selectedUser.length ? true : false;
                });
            rightGridRow['selectedRightGridRows'].forEach((item) => {               
                parent.selectRightGridUsers[rightGridRow.group_id].push(parent.rightGrid.gridService.getDataItemByRowIndex(item));                
            });
            parent.rightGrid.slickGrid.setSelectedRows(rightGridRow['selectedRightGridRows']);
            parent.reRenderSlickGrid(parent.rightGrid);
            parent.reRenderSlickGrid(parent.leftGrid);
            parent.updateLeftGridProperties();
            parent.reRenderSlickGrid(parent.rightGrid);
            parent.rightGrid.slickGrid.setSelectedRows(parent.leftGridDataSource[parent.selectedCenterRowIndex]['selectedRightGridRows']);
            }                         
        });
        parent.rightGrid.slickGrid.onSort.subscribe((e, args) => {
            parent.reRenderSlickGrid(parent.rightGrid);
            parent.updateLeftGridProperties();
        });
        parent.rightGrid.filterService.onFilterChanged.subscribe(resp =>  {
            parent.reRenderSlickGrid(parent.rightGrid);
            parent.updateLeftGridProperties();
        });
        //  commenting below code which is iover-riding selections on  sort/filter
         parent.rightGrid.slickGrid.setSelectedRows(parent.leftGridDataSource[parent.selectedCenterRowIndex]['selectedRightGridRows']);
        // // parent.rightGrid.gridService.highlightRow([3, 4, 8, 9], 2000);
        parent.leftGridDataSource.forEach((element, index) => {
            parent.leftGridDataSource[index].selectUsersCount = parent.leftGridDataSource[index]['selectedRightGridRows'].length;
        });
    }

    reRenderSlickGrid(gridInstance) {
        gridInstance.slickGrid.updateRowCount();
        gridInstance.slickGrid.render();
        gridInstance.slickGrid.invalidate();
        this.enableNextButtton();
    }

    onNext() {
        {
            const rows: any[] = [];
            const grouprows: any[] = [];
            const leftGridDataService = this.leftGrid.gridService;
            const selectedRows: number[] = leftGridDataService.getSelectedRows();
            const parent = this;            
            let userdata: any[] = [];;
            let groupdata: any[] = [];;
            selectedRows.forEach(index => {
                const row = leftGridDataService.getDataItemByRowNumber(index);
                if (row !== undefined && row !== null) {
                    grouprows.push(row);
                }
            });

            grouprows.forEach(ele => {
               
                const selected_users = ele.users.filter(user=> user.isAdmin === true || user.isReadOnly === true || user.isReadWriteOnly === true)
                if (selected_users.length) {
                    this.default_access_level = [];
                    selected_users.forEach(user => {
                        if (user.user_id) {
                            userdata.push({
                                "user_id": user.user_id,
                                "is_admin": user.isAdmin,
                                "read_only": user.isReadOnly,
                                "read_write": user.isReadWriteOnly
                            });
                        } else {
                            if (this.isDatasetAdmin) {
                                if (user.isReadOnly) {
                                  this.default_access_level = {
                                    read_only: true,
                                  };
                                } else if (user.isAdmin) {
                                  this.default_access_level = {
                                    is_admin: true,
                                  };
                                } else if (user.isReadWriteOnly) {
                                  this.default_access_level = {
                                    read_write: true,
                                  };
                                }
                            }
                        }
                    });
                }
                groupdata.push({
                    "group_id": ele.group_id,
                    "users": userdata,
                    "default_access_level":this.default_access_level                   
                });
                userdata = [];
            }); 

            const postData: any = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                payload: {
                    "datasets": this.dataset_ids,
                    "entitlements": groupdata                   
                }
            };            
            this.datasetSvc.updateuserEntitlements(postData).subscribe(resp => {
                if(resp){
                    if (this.showBreadcrumb) {
                        // this.router.navigate(['/zs/datasets/' + this.datasetId]);
                        this.messageSvc.sendMessage({message: 'Successfully updated Dataset User Groups.', type: 'SUCCESS', hideboard: true});
                    } else {
                        this.messageSvc.sendMessage({message: 'Successfully updated Dataset User Groups.', type: 'SUCCESS', hideboard: true});
                        this.router.navigate(['/zs/datasets/schedule-refresh'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
                    }
                }               
            },
            error => {
                this.messageSvc.sendMessage({message: 'User entitlements update dataset has failed', type: 'INFO', hideboard: true});
            });
        }
    }

    onPrevious() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zs/datasets/define-datasets'], { queryParams: { datasetId: this.dataset_ids, showBreadcrumb: this.showBreadcrumb } });
        } else {
            this.router.navigate(['/zs/datasets/define-datasets'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
        }
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
        sessionStorage.removeItem('newDataSet');
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    enableAllCheckbox(centerGridRow) {
        const parent = this;
        var rows = [];
        var adminselectedrows = [];
        var readonlyselectedrows = [];
        var readwriteonlyselectedrows = [];

        const UserEntitlementLists = this.rightGrid.dataView.getItems();
        UserEntitlementLists.forEach((row, index) => {
            if (row['isAdmin'] === true || row['isReadOnly'] === true || row['isReadWriteOnly'] === true) {
                rows.push(index);
            } else if (row['isAdmin'] === false && row['isReadOnly'] === false && row['isReadWriteOnly'] === false) {
                rows = rows.filter(row => row !== index);
                $("#isAdmin").prop("checked", false);
                $("#isReadOnly").prop("checked", false);
                $("#isReadWriteOnly").prop("checked", false);
            }

            if (row['isAdmin'] === true) {
                adminselectedrows.push(index);
            }

            if (row['isReadOnly'] === true) {
                readonlyselectedrows.push(index);
            }

            if (row['isReadWriteOnly'] === true) {
                readwriteonlyselectedrows.push(index);
            }
        });

        if (adminselectedrows.length == parent.rightGridDataSource.length) {
            $("#isAdmin").prop("checked", true);
            this.isAdminChecked = true;
        } else {
            $("#isAdmin").prop("checked", false);
            this.isAdminChecked = false;
        }

        if (readonlyselectedrows.length==parent.rightGridDataSource.length) {
            $("#isReadOnly").prop("checked", true);
            this.isReadonlyChecked = true;
        } else {
            $("#isReadOnly").prop("checked", false);
            this.isReadonlyChecked = false;
        }

        if (readwriteonlyselectedrows.length==parent.rightGridDataSource.length) {
            $("#isReadWriteOnly").prop("checked", true);
            this.isReadWriteonlyChecked = true;
        } else {
            $("#isReadWriteOnly").prop("checked", false);
            this.isReadWriteonlyChecked = false;
        }
        if(!this.showBreadcrumb) {
            parent.rightGrid.slickGrid.setSelectedRows(rows);
        }
        parent.reRenderSlickGrid(parent.rightGrid);
    }

    onCheckAll(opc,e) {
        var rows = [];
        if (opc === 'isAdmin') {
            this.isAdminChecked = !this.isAdminChecked;
            this.isReadonlyChecked = false;
            this.isReadWriteonlyChecked = false;
        }
        if (opc === 'isReadOnly') {
            this.isReadonlyChecked = !this.isReadonlyChecked;
            this.isAdminChecked = false
            this.isReadWriteonlyChecked = false;
        }
        if (opc === 'isReadWriteOnly') {
            this.isReadWriteonlyChecked = !this.isReadWriteonlyChecked;
            this.isAdminChecked = false
            this.isReadonlyChecked = false;
        }
        this.rightGridDataSource.forEach((row, index) => {
            rows.push(index);
            if (row.user_id !== this.loggedInUserDetails['user_id']) {
                if (opc === 'isAdmin' && this.isAdminChecked) {
                    row['isAdmin'] = true;
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = false;
                } else {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = false;
                }

                if (opc === 'isReadOnly' && this.isReadonlyChecked) {
                    row['isReadOnly'] = true;
                    row['isAdmin'] = false;
                    row['isReadWriteOnly'] = false;
                }

                if (opc === 'isReadWriteOnly' && this.isReadWriteonlyChecked) {
                    row['isAdmin'] = false;
                    row['isReadOnly'] = false;
                    row['isReadWriteOnly'] = true;
                }
            }
      
            if(row['isAdmin'] == false && row['isReadOnly'] == false && row['isReadWriteOnly'] == false) {
                this.rightGrid.slickGrid.setSelectedRows([]);
                if (e.currentTarget.checked==true) {
                    e.currentTarget.checked=false;
                }
            } else {
                this.rightGrid.slickGrid.setSelectedRows(rows);
            }
        });
        
        this.selectedUserLength = this.rightGridDataSource.length;
        this.reRenderSlickGrid(this.rightGrid);
        this.enableNextButtton();
    }

    enableNextButtton(){
        let selected_rows = this.rightGridDataSource.filter(ele => ele.isAdmin == true || ele.isReadOnly == true || ele.isReadWriteOnly == true);
        if (selected_rows.length > 0){
            let count_admin = selected_rows.filter(item => item.isAdmin === true);
            this.isUserSelected = count_admin.length > 0 ? true : false;
        } else {
            this.isUserSelected = false;
        }
    }

}
