import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../content/project/project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { Project } from '../../../content/project/project.model';
import { toolTipFormatter } from '../../../../common/shared/formatters/toolTipFormatter';

@Component({
    selector: 'zetta-publish-result',
    templateUrl: './publish-result.component.html',
    styleUrls: ['./publish-result.component.scss']
})
export class PublishResultComponent implements OnInit {
    public leftSidePaneTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
    public middlePanelTableSettings: object = { 'height': "100%", 'width': "100%" };
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public middlePanelDS: object;
    public leftSidePanelDS: object;
    public leftPanelGridObject: AngularGridInstance;
    public middlePanelGridObject: AngularGridInstance;
    public leftPanelGridColumnsSettings: object = [];
    public middlePanelGridColumnsSettings: object = [];
    public leftSidePanelOptions: object = {};
    public middlePanelOptions: object = {};
    public isLeftLoading = true;
    public hasScrolled = false;
    projectDetail = new Project();
    leftGridSelection: string;
    totalLeft = 0;
    pageLeft = 1;
    limitLeft = 100;
    noData = false;

    public location: string;
    public publishStatus: string;

    constructor(private ProjectService: ProjectService,
        public zettaUtils: ZettaUtils,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        // this.project.projectid = this.activatedRoute.snapshot.queryParamMap.get('id');
        this.publishStatus = this.activatedRoute.snapshot.queryParamMap.get('publishStatus');

        this.location = "Default";

        this.leftSidePanelOptions = {
            enableGridMenu: false,
            // frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            rowHeight: 34,
        };

        this.middlePanelOptions = {
            enableGridMenu: false,
            // frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
        };

        this.leftPanelGridColumnsSettings = [
            {
                'displayname': 'Field Name',
                'physicalname': 'display_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'minWidth': 220,
            }
        ];

        this.middlePanelGridColumnsSettings = [
            {
                'displayname': 'Format Type',
                'physicalname': 'type_format',
                'sortable': true, 
                'datatype': 'String',
                'filterable': true, 
                'minWidth': 120,
                // 'formatter': blueTextFormattter,
            }
        ];

        this.getProjectResultsById(this.pageLeft, this.limitLeft);
        this.getEntitiesmasteredByClusterId();
    }

    onSelectDefault(): void {
        this.location = 'Default';
    }

    onSelectCustom(): void {
        this.location = 'Custom';
    }

    getProjectResultsById(pageno, limit = 20) {
        // this.isLeftLoading = true;
        // this.ProjectService.getProjectResults(this.loggedInUserDetails.userid, this.projectDetail.projectid, pageno, limit).subscribe(data => {
        this.ProjectService.getEntitiesmasteredByLoggedInUser(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limit, '','').subscribe(data => {
            this.leftSidePanelDS = data;
            if (this.leftSidePanelDS['currentpage'].length === 0) {
                this.noData = true;
              }
            this.isLeftLoading = false;
            this.totalLeft = data['totalrecords'];
            let concatedLeftRows =  this.leftSidePanelDS['currentpage'];
            if (this.leftPanelGridObject) {
                concatedLeftRows = [...this.leftPanelGridObject.dataView.getItems(), ...this.leftSidePanelDS['currentpage']];
                this.leftSidePanelDS['currentpage'].forEach(element => {
                    element.id = Math.random();
                });
                this.leftSidePanelDS['currentpage'] = concatedLeftRows;
                this.leftPanelGridObject.dataView.setItems(concatedLeftRows);
                this.leftPanelGridObject.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }
        }, err => {
            this.leftSidePanelDS = { 'currentpage': [] };
            this.isLeftLoading = false;
        });
    }

    getEntitiesmasteredByClusterId() {
            const rows = [ 
                { 'typeformat': 'CSV'},
                { 'typeformat': 'JSON'},
                { 'typeformat': 'Hive Table'},
            ];
            if (this.middlePanelGridObject) {
                rows.forEach(element => {
                    element['id'] = Math.random();
                });
                this.middlePanelGridObject.dataView.setItems(rows);
                this.middlePanelGridObject.gridService.setSelectedRows([]);
            }
            this.middlePanelDS = rows;
    }

    onLeftGridCreation(leftgrid) {
        this.leftPanelGridObject = leftgrid;
    }

    onMiddleGridCreation(middlegrid) {
        this.middlePanelGridObject = middlegrid;
    }
    
    goToPageLeft(n: number): void {
        this.pageLeft = n;
        this.getProjectResultsById(this.pageLeft);
    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getProjectResultsById(this.pageLeft);
    }

    onPrevLeft(): void {
        this.pageLeft--;
        this.getProjectResultsById(this.pageLeft);
    }

    totalLeftPages(): number {
        return this.zettaUtils.getTotalPages(this.totalLeft, this.limitLeft);
    }

    onLeftGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
          }
    }

    publishingResults() {
        this.router.navigate(['/zs/projects/' + this.projectDetail.project_id], { queryParams: { 'publishStatus': '372' } });
    }


}
