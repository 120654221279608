import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from './../zm-shared/zm-zetta-models';


import { ZmRecords } from './zm-records.constants';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

export class ZmBaseService {
  baseUrl: string = environment.config.BASE_URL;
  apiUrl: string = environment.config.API_URL;

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };


  constructor() { }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  handleError(error) {
    // redirect to login/404 screen > based upon the response message
    return Observable.throw(error.json().error || 'Server error');
  }

  public fetchRecords(recordTye: ZmRecords, http: HttpClient, id?: string): Observable<any> {
    if (id) {
      return http.get(`${this.apiUrl}` + recordTye + `/` + id);
    } else {
      return http.get(`${this.apiUrl}` + recordTye);
    }
  }

  public getUserPic(userid: string, groupid: string, http: HttpClient): Observable<any> {
    return http.get(`${this.apiUrl}/users/${userid}/groups/${groupid}/profilepic`);
  }

  getObjectFromSession(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  addObjectToSession(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeObjectFromSession(key: string) {
    sessionStorage.removeItem(key);
  }

  clearSession() {
    sessionStorage.clear();
  }
}
