import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatasetService } from '../dataset.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { MessageService } from '../../message/message.service';
import { environment } from 'src/environments/environment';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';

declare var $: any;
export const mappingConfidenceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let cssLevel = '';
  let confidenceLevel = '';
  let condLike = dataContext.is_up_vote ? 'fas' : 'fal';
  let condUnlike = dataContext.is_down_vote ? 'fas' : 'fal';
  cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

  confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
  <div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-2">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-2">${downCount}</span>`;
  return confidenceLevel;
};
@Component({
  selector: 'zetta-dataset-classification',
  templateUrl: './dataset-classification.component.html',
  styleUrls: ['./dataset-classification.component.scss']
})
export class DatasetClassificationComponent implements OnInit {

  @Input() dataset_name: string;
  @Input() showModal: boolean;
  @Input() dataset_id: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 30, 'isNormalPagination': false };
  gridOptions: any;
  columnDef: any;
  grid: AngularGridInstance;
  row_no: number;
  showDownvoteModal = false;
  catalog_id: number;
  object_id: number;
  showFeedbackSummaryModel = false;
  voteType: string;
  grandTotal = 0;
  subTotal = '';
  page = 1;
  limit = 100;
  userInfo = JSON.parse(sessionStorage.userInfo);
  dataset_classification: any;
  datasource_name: string;
  entity_name: string;
  votedUsers: any;
  isFiltering = false;
  isLoading = true;
  noData = false;
  hasScrolled = false;

  constructor(private datasetSvc: DatasetService,
    public zettaUtils: ZettaUtils,
    private messageSvc: MessageService,
    private catalogSvc: ZsClContentService) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('reset_page_sort');
    this.columnDef = [{
      'displayname': 'Catalog',
      'physicalname': 'catalog_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 300,
    }, {
      'displayname': 'Semantic Object',
      'physicalname': 'entity_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 300,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 300,
      'formatter': mappingConfidenceFormatter,
    }];
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.getDatasetClassification(this.page, this.limit);
  }

  getDatasetClassification(pageNo, limit) {
    let serverFilter="";
    if(sessionStorage.getItem("serverfilter")){
       serverFilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.userInfo['user_id']}/tenants/${this.userInfo['tenant_id']}/datasets/${this.dataset_id}/classification?pagination=true&pageno=${1}&pagesize=${this.limit}`;
    const data = {
      user_id: this.userInfo.user_id,
      tenant_id: this.userInfo.tenant_id,
      dataset_id: this.dataset_id,
      pageno: pageNo,
      limit: limit
    };
    this.datasetSvc.getDatasetClassification(data,serverFilter,sortfilter).subscribe((res) => {
      this.dataset_classification = res['currentpage'];
      if (res['currentpage'].length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      if (pageNo === 1) {
        this.grandTotal = res['totalrecords'];
      }
      let concatedRows = this.dataset_classification;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.dataset_classification];
        this.dataset_classification.forEach(element => {
          element.id = Math.random();
        });
        this.dataset_classification = concatedRows;
        this.grid.dataView.setItems(this.dataset_classification);
        this.grid.gridService.setSelectedRows([]);
      }
      this.hasScrolled = false;
      ($("#dataset_classification_model") as any).modal("show");
    }, (error) => {
      this.hasScrolled = false;
    });

  }
  clearDatasets() {
    this.onClose.emit(false);
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onCellClick(e) {
    const event = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      this.catalog_id = row.catalog_id;
      this.object_id = row.entity_id;
      this.row_no = args.row;
      this.datasource_name =row.datasource_name;
      this.dataset_name = row.dataset_name;
      this.entity_name = row.entity_name;
      if (event.target.className == 'fal fa-thumbs-up') {
        this.upvote(row)
      } else if (event.target.className == 'fal fa-thumbs-down handDown' || event.target.className == 'handDown fal fa-thumbs-down' || event.target.className == 'fal handDown fa-thumbs-down') {
        this.showDownvoteModal = true;
      } else if (event.target.className.includes('countUp')) {
        this.showFeedbackSummaryModel = true;
        this.voteType = 'upVote'
        this.votedUsers = row.upvotes;
      } else if (event.target.className.includes('countDown')) {
        this.showFeedbackSummaryModel = true;
        this.voteType = 'downVote'
        this.votedUsers = row.downvotes;
      }
    }
  }

  upvote(row) {
    const upVotedata = {
      "dataset_id": this.dataset_id,
      "mapping_type": "manual",
      "entity_id": this.object_id,
      "catalog_id": this.catalog_id,
      "is_semantic_level_mapping": true
    };
    const payload = {
      user_id: this.userInfo['user_id'],
      tenant_id: this.userInfo['tenant_id'],
      payload: upVotedata
    };
    this.catalogSvc.saveVoteSemanticObject(payload, 'up_vote').subscribe(resp => {
      $('#thumbs-up-' + this.row_no).removeClass('fal fa-thumbs-up');
      $('#thumbs-up-' + this.row_no).addClass('fas fa-thumbs-up');
      $('#thumbs-down-' + this.row_no).removeClass('fas fa-thumbs-down');
      $('#thumbs-down-' + this.row_no).addClass('fal fa-thumbs-down');
      this.dataset_classification.forEach(element => {
        if (element.entity_id === this.object_id) {
          element.is_up_vote = true;
        }
      });
      if (this.grid) {
      this.grid.dataView.setItems([]);
      this.getDatasetClassification(this.page, this.limit);
      }
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
  }
  closeModalPopup(event) {
    if (event) {
      $('#thumbs-up-' + this.row_no).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.row_no).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.row_no).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.row_no).addClass('fas fa-thumbs-down');
      this.dataset_classification.forEach(element => {
        if (element.entity_id === this.object_id) {
          element.is_down_vote = true;
        }
      });
      if (this.grid) {
        this.grid.dataView.setItems([]);
        this.getDatasetClassification(this.page, this.limit);
        }
    }
    this.showDownvoteModal = false;
  }
  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }

  onExportDataSetClassification() {
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.userInfo['user_id']}/tenants/${this.userInfo['tenant_id']}/datasets/${this.dataset_id}/classification?export=true`).subscribe((resp) => {
      this.catalogSvc.exportCsvFiles(resp, 'SemanticReport_forDataset');
    });
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("reset_page_sort") || sessionStorage.getItem("serverfilter")) {
      if (!this.isFiltering) {
        this.page = 1;
      }
      if (sessionStorage.getItem("serverfilter")) {
        this.isFiltering = true;
      }
      this.hasScrolled = false;
      this.noData = false;
      sessionStorage.removeItem("reset_page_sort");
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onNext(): void {
    this.page++;
    this.getDatasetClassification(this.page, this.limit);
  }
}
