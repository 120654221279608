import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ConfidenceLevelGoldenFormatterComponent } from 'src/app/common/shared/cell-renderer/confidence-level-formatter/confidence-level-golden-formatter.component';
import { SourceSystemRendererComponent } from 'src/app/common/shared/cell-renderer/source-system-formatter/source-system-formatter';
import { ProjectService } from '../project.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/common/components/message/message.service';
import { Project } from '../project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
// import { ZettaUtils } from 'src/app/common/shared/zettaUtils';

@Component({
  selector: 'zetta-recent-golden-records',
  templateUrl: './recent-golden-records.component.html',
  styleUrls: ['./recent-golden-records.component.scss']
})
export class RecentGoldenRecordsComponent implements OnInit, OnChanges {
  frequentFilters;
  recentTableColDef: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  gridApi;
  recentGoldenRecords;
  tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 30, 'isNormalPagination': false };
  loggedInUserDetails = null;
  recentGoldenRecs: object[];
  isLoading: boolean = false;
  noData: boolean = false;
  total:number = 0;
  grandTotal: number = 0;
  page: number = 1;
  projectDetail: Project = new Project();
  entityId;
  limit = 100;
  selectedRecentItem: string = 'last_24_hours';
  entity_id;
  entity_name;
  hasScrolled = false;
  subTotal = '';
  currentDatalength = '';
  @Input() projectData: any;

  constructor(private ProjectSvc: ProjectService, private messageSvc: MessageService, 
    private activatedRoute: ActivatedRoute, private router: Router, public zettaUtilsSvc: ZettaUtils) { }

  ngOnInit() {
    sessionStorage.removeItem("serverfilter")
    sessionStorage.removeItem("sortfilter"); 
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    this.frequentFilters = [{name: 'Last 24 Hours', value: "last_24_hours"}, {name: "Last Week", value: "last_week"}, {name: 'Last Month', value: "last_month"}, {name: 'All Changes', value: 'most_recent' }];
    this.fsGridOptions = {  
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id']; 
    });
    this.activatedRoute.queryParams.subscribe(query => {
      this.entityId = query.entity_id;
      this.entity_name = query.entity_name;
    });
    this.getGoldenRecords(this.page,this.limit, true); 
    this.recentTableColDef = [
      {
        headerName: 'Master ID',
        field: 'zs_cluster_id',  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 200,  
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: SourceSystemRendererComponent,
        tooltipField : 'zs_cluster_id'
      },
      
      {
        headerName: 'Full Name',
        field: `zs_full_name_${this.entityId}`,  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 150,  
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: SourceSystemRendererComponent,
        tooltipField : `zs_full_name_${this.entity_id}`,
      },

      {      
          headerName: 'Data Quality Scores',
          field: 'dq_score',  
          filter: 'agTextColumnFilter',
          floatingFilter: true, 
          sortable: true,
          headerClass: 'text-left titleBreak pr-3',
          cellClass: 'text-center golden-rec-justify-content ml-2',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          minWidth: 150, 
          cellRendererFramework: ConfidenceLevelGoldenFormatterComponent,   
          // cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus },
          tooltipField : 'dq_score'
      },

      {
        headerName: 'Last Updated',
        field: `last_updated`,  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 150,  
        cellClass: 'text-center ml-2',
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: DateFormatterFormatterComponent,
        tooltipField : 'last_updated'
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.projectData && changes.projectData.currentValue) {
      this.projectDetail = changes.projectData.currentValue;
      // this.intiCOlDefs()
    }
  }

  recentRecsGridCreation(grid) {
    this.agGrid = grid;
  }

  handleUserGridClickEvent(eventData) {
    const args = eventData.args;
    const event = eventData.event;
    this.gridApi = eventData.api;
    const metadata = eventData.data;
    const fieldName = eventData.colDef.field;
    const row = eventData.data;
    if (row !== undefined && row !== null) {
      if (fieldName == "zs_cluster_id" || fieldName == `zs_full_name_${this.entityId}`) {
        // this._router.navigate([`/zs/projects/${this.projectDetail.project_id}/golden-records/detail`]);
        this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details/golden-records/detail`], {
          queryParams: { 
            cluster_id: row['zs_record_id'], 
            record_id: row['zs_cluster_id'], 
            entity_id:this.entityId,
            showRecent:true,
            noReloadRequired: false 
          }, 
          queryParamsHandling: 'merge' 
        });
      }
    }
  }

  getGoldenRecords(pageno, limit = 1, setupColumns = false) {
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true&time_range=${this.selectedRecentItem}`;

    let serverFilter="";
   if(sessionStorage.getItem("serverfilter")){
      serverFilter=sessionStorage.getItem("serverfilter");
   }

   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   } 

    let self = this;
    this.ProjectSvc.getGoldenRecords(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, pageno, limit,serverFilter,sortfilter, true, this.selectedRecentItem).subscribe((data) => {
      if (setupColumns) {
        let entity_Identifier_name = data['entityIdentifierColumn'] && data['entityIdentifierColumn']['zs_entity_identifier'] ? data['entityIdentifierColumn']['zs_entity_identifier']: ' ';
        self.intiCOlDefs(entity_Identifier_name);
      }
      self.recentGoldenRecords = data['currentpage'];
      let tempData = data['currentpage'];
      if (data['currentpage'].length === 0) {
        self.noData = true;
      }
      self.isLoading = false;
      self.total = data['totalrecords'];
      if (pageno === 1) { 
        self.grandTotal = data['totalrecords'];       
      }
      
      if (pageno > data['totalpages']) {
        self.isLoading = false;
        self.page = 1;
        // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?paginate=true&pageno=${1}&pagesize=${limit}&forGoldenRecords=true`;
        }
      
    }, err => {
      self.recentGoldenRecords = null;
      self.isLoading = false;
      self.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  onChange(eve) {
    this.selectedRecentItem = eve.target.value;
    this.recentGoldenRecords = [];
    this.isLoading = true;
    this.page = 1;
    this.getGoldenRecords(this.page,this.limit, true);
    // this.intiCOlDefs()
  }

  navigateToGoldenRecords() {
    this.router.navigate(['/zs/projects/' + this.projectDetail['project_id'] + '/production-stage-details/golden-records'], { queryParams: { entity_id: this.entityId, entity_name: this.entity_name, showRecent:true }, queryParamsHandling: 'merge' });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getGoldenRecords(this.page, this.limit, false);
  }

  onNext(): void {   
    // this.isLoading = true; 
    this.hasScrolled = true;
    this.page++;
    this.getGoldenRecords(this.page, this.limit, false); 
  }
 

  onPrev(): void {
    this.page--;
    this.getGoldenRecords(this.page, this.limit, false);
  }

  totalPages(): number {
    return this.zettaUtilsSvc.getTotalPages(this.total, this.limit);
  }

  onScroll(parentClass) { 
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled) {
      this.zettaUtilsSvc.setGridAutoScrollTogether(parentClass);
      this.hasScrolled = true;
      this.onNext();
      
    }    
  }

  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtilsSvc.getSubTotal(this.grandTotal, $event.subTotal); 
    this.currentDatalength = $event.subTotal;
  }

  intiCOlDefs(entityIdentifierName) {
    this.recentTableColDef = [
      {
        headerName: 'Master ID',
        field: 'zs_cluster_id',  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 200,  
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: SourceSystemRendererComponent,
        tooltipField : 'zs_cluster_id'
      },
      
      {
        headerName: 'Full Name',
        field: `${entityIdentifierName}`,  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 150,  
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: SourceSystemRendererComponent,
        tooltipField : `zs_full_name_${this.entityId}`,
      },

      {      
          headerName: 'Data Quality Scores',
          field: 'dq_score',  
          filter: 'agTextColumnFilter',
          floatingFilter: true, 
          sortable: true,
          headerClass: 'text-left titleBreak pr-3',
          cellClass: 'text-center golden-rec-justify-content ml-2',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          minWidth: 150, 
          cellRendererFramework: ConfidenceLevelGoldenFormatterComponent,   
          tooltipField : 'dq_score'
      },

      {
        headerName: 'Last Updated',
        field: `last_updated`,  
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        editable: false,  
        minWidth: 150,  
        cellClass: 'text-center ml-2',
        headerClass: 'text-left titleBreak pr-3',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: DateFormatterFormatterComponent,
        tooltipField : 'last_updated'
      },
    ];
  }
}