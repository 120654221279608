import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tooltip-formatter',
  template: `<span class="text-truncate" [title]="value || 'null'">{{value}}</span>
            <ng-container *ngIf="shouldDisplayNull">
        <span  class="text-truncate blur">null</span>
        </ng-container>`
})
export class NullCellRendererComponent implements ICellRendererAngularComp {
  value: any;
  params: any;
  shouldDisplayNull = false;

  agInit(params: any): void {
    this.value = params.value;
    if(!this.value){
      this.shouldDisplayNull = true;
    }
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
