import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../content.service';
import { AppGlobals } from '../../../../common/shared/app.globals'
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
@Component({
  selector: 'zetta-quota-status',
  templateUrl: './quota-status.component.html',
  styleUrls: ['./quota-status.component.scss']
})
export class QuotaStatusComponent implements OnInit {
  totalQuota: Number = 0;
  isLoading: Boolean = true;
  usedQuota: Number = 0;
  totalQuotaAvailable: Number = 0;
  quotaTypeStatus: string;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  quotaType: Number;
  score_class: string = "";

  constructor(public contentSvc: ContentService, private zettaUtils: ZettaUtils) { }

  ngOnInit() {
    this.getTenantDetail();
    this.getQuotaStatus();
  }

  getTenantDetail() {
    this.contentSvc.getTenant(this.loggedInUserDetails).subscribe(resp => {
      this.quotaType = resp.quota_type_lookup_id;
      if (this.quotaType == AppGlobals.NUMBER_OF_RECORDS) {
        this.totalQuotaAvailable = resp.quota_type_value;
        this.quotaTypeStatus = ' records';
      } else {
        this.totalQuotaAvailable = resp.quota_type_value;
        this.quotaTypeStatus = ' GB';
      }
    });
  }

  getQuotaStatus() {
    this.contentSvc.getConsumptionReport().subscribe((data) => {
      this.isLoading = false;
      if(data) {
        let newVal = this.zettaUtils.truncateDecimals((data.used_quota * 100), 3);
        this.totalQuota = newVal;
        this.score_class = (Number(this.totalQuota) >= 75 ? 'high' : (Number(this.totalQuota)) >= 50 ? 'medium' : 'low');
        if (this.quotaType == AppGlobals.NUMBER_OF_RECORDS) {
          this.usedQuota = data.original_records;
        } else {
          this.usedQuota = data.original_data_in_gb;
        }
      }
    }, error => {
      this.isLoading = false;
    });
  }

}
