// task-name-renderer.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppGlobals } from '../../app.globals';

@Component({
  selector: 'app-task-name-renderer',
  template: `
    <div class="blueColor modal-feedback text-truncate" [title]="params.value"
         [attr.data-toggle]="dataToggle" [attr.data-backdrop]="dataBackdrop" [attr.data-keyboard]="dataKeyboard"
         [attr.data-target]="dataTarget">
      <i class="pointer mr-2" [class]="iconClass"></i>{{ params.value }}
    </div>
  `,
  styles: [/* Add your styles here */]
})
export class TaskNameFromatterComponent implements ICellRendererAngularComp {
  params: any;
  iconClass: string;
  dataToggle: string;
  dataBackdrop: string;
  dataKeyboard: string;
  dataTarget: string;

  agInit(params: any): void {
    this.params = params;
    this.iconClass = params.column.getColDef().field === 'task_type' ? 'fas fa-graduation-cap' : '';

    if (params.data.project_type_id === AppGlobals.CONCEPT_PARSER) {
      this.dataToggle = 'modal';
      this.dataBackdrop = 'static';
      this.dataKeyboard = 'false';
      this.dataTarget = '';
    } else {
      this.dataToggle = 'modal';
      this.dataBackdrop = 'static';
      this.dataKeyboard = 'false';
      this.dataTarget = '#datasets_modal';
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
