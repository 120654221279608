import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from "../../../../../../common/components/project-progress/project-progress.model";
import { ZsClContentService } from "../../../../zs-cl-content.service";
import { ZettaUtils } from "../../../../../../common/shared/zettaUtils";

@Component({
  selector: 'zetta-zs-cl-add-train-data-concept-parser',
  templateUrl: './zs-cl-add-train-data-concept-parser.component.html',
  styleUrls: ['./zs-cl-add-train-data-concept-parser.component.scss']
})
export class ZsClAddTrainDataConceptParserComponent implements OnInit {
  activeNavLnk: string = '1';
  progressState = new ProjectStatus();
  loggedInUserDetails: any;
  project_id: number
  projectData: object;
  isDataReady = false;
  showBreadcrumb = false;

  constructor(private activatedRoute: ActivatedRoute,
    private zsClContentService: ZsClContentService,
    private zettaUtils: ZettaUtils,) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    this.activatedRoute.parent.params.subscribe(params => {
      this.project_id = params['id'];
    });
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }

    this.activatedRoute.queryParamMap.subscribe(params =>{
      if(params.has('activeLink')){
        this.activeNavLnk= params.get('activeLink');
      }          
    });
    this.getProject();
  }

  getProject() {
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.project_id
    };
    this.zsClContentService.getclassifyProject(payload).subscribe(resp => {
      if (resp) {
        this.projectData = resp;
        this.progressState.states = this.showBreadcrumb ? this.zettaUtils.getStateList('manage-classify-concept-parser-project-data') : this.zettaUtils.getStateList('create-classify-concept-parser-project');
        this.progressState.currentStateIndex = this.showBreadcrumb ? 0 :4;
        this.progressState.type = this.showBreadcrumb ? 'manageProjectData' : 'Data Classification Project';
        this.progressState.classificationProject = resp;
        this.progressState.isEdit = false;
      }
      this.progressState.currentStateInfo = 'Saved...';

      this.isDataReady = true;
    });
  }
}
