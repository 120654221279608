import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../zs-cl-content.service'
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../classify.model';
import { environment } from '../../../../environments/environment';
import { BaseService } from 'src/app/common/services/base-service';
import { MessageService } from 'src/app/common/components/message/message.service';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

// import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
declare var $: any;

const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{  
  if (dataContext.bestPrediction && columnDef.field === "prediction") {
    return `<div class="text-truncate" style="background-color: #FAF8DC !important; margin-left: -40px !important; padding-left: 40px !important;"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;
  } else {
    return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;
  }
}

const columnFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {  
      return `<div class="mr-left-0 wd-cell"><div class="wd-20 text-truncate blueLink" title="${value}">${value === null || value === undefined ? '' : value}</div></div>`
};

const confidenceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  value === "H" ? `<div class"row align-items-center"><div class="col-md-auto pl-0"><label class="priorityCell high" title='High'>High</label></div></div>` :
    value === "M" ? `<div class"row align-items-center"><div class="col-md-auto pl-0"><label class="priorityCell medium" title='Medium'>Medium</label></div></div>` :
      `<div class"row align-items-center"><div class="col-md-auto pl-0"><label class="priorityCell low" title='Low'>Low</label></div></div>`;

const confidence100Formatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  if (!value || value == 'null') {
  return;
  } else {
    return `<div class="w-100 text-right pr-3">${value}%</div>`;
  }
}

const bestPredictionFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  if (!value || value == 'null') {
  return;
  } else {
    return `<div class="w-100 text-left pr-3">${value}</div>`;
  }
}

const confidenceLevelFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  let confidenceLevel = dataContext.bestPrediction ? '<div class="" style="background-color: #FAF8DC !important; margin-left: -40px !important; padding-left: 40px !important;">' : '<div>';
  let conf = value >= 75 ? 'HIGH' : (value >= 50  && value < 75 ) ? 'MEDIUM' : 'LOW';
  let condLike = dataContext.vote === null ? 'fal' : (dataContext.vote === 1 ? 'fas' : 'fal');
  let condUnlike = dataContext.vote === null ? 'fal' : (dataContext.vote === 0 ? 'fas' : 'fal');
  confidenceLevel += `<div class="porcent text-center mr-2" title='${value}%'>${value}%</div>
                      <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
                      <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down"></i></div></div>`;
  
  return confidenceLevel;
}
const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  if(dataContext.isChecked){
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }else{
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }
    
};
@Component({
  selector: 'zetta-zs-cl-view-results-parse',
  templateUrl: './zs-cl-view-results-parse.component.html',
  styleUrls: ['./zs-cl-view-results-parse.component.scss']
})

export class ZsClViewResultsParseComponent implements OnInit {
  public leftPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public centerPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public rightPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  loggedInUserDetails: [];
  public leftPanelDS: any = [];
  public centerPanelDS: any = [];
  public rightPanelDS: any = [];
  public leftPanelGridObject: AngularGridInstance;
  public centerPanelGridObject: AngularGridInstance;
  public rightPanelGridObject: AngularGridInstance;
  public leftPanelGridColumns: object = [];
  public centerPanelGridColumns: object = [];
  public rightPanelGridColumns: object = [];
  public leftPanelOptions: object = {};
  public centerPanelOptions: object = {};
  public rightPanelOptions: object = {};
  public isLeftLoading = true;
  public isCenterLoading = false;
  public isRightLoading = false;
  public hasScrolled = false;
  public hasScrolledCenter = false;
  topPanel = false;
  totalLeft = 0;
  totalRight = 0;
  totalCenter = 0;
  pageLeft = 1;
  pageCenter = 1;
  limitLeft = 100;
  limitCenter = 100;
  pageRight = 1;
  noDataLeftPanel = false;
  noDataCenterPanel = false;
  noDataRightPanel = false;
  initCenterGrid = true;
  initRightGrid = true;
  projectDetail = new Project();
  leftGridSelection: string;
  selectedMappedColumn: number;
  showmodelpopup = false;
  showDownvoteModal: boolean = false;
  taskId: number;
  classInfo: any;
  datasetId: any;
  dsName
  datasetColumnId: number;
  screen_name = "VIEW_RESULTS";
  rowData: any;
  classes: any;
  selectedRecordName: any;
  selectedParseName: any;
  selectedSubConceptName: string;
  hasName = false;
  selectedRowLeftPanel;
  selectedRowCenterPanel;
  isSubConcept = false;
  selectedSubConcept = "";
  selectedPridiction = [];
  subConceptList = [];
  classify_project_id;
  bestPrediction = true;
  classifyProjectType;
  classifier: string;
  dataset_id: number;
  constructor(public ZsClContentService: ZsClContentService,
    public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private _router : Router, 
    private baseService: BaseService,
    private datasetSvc: DatasetService,
    private messageSvc: MessageService
    ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.activatedRoute.parent.params.subscribe(params => {
      this.classify_project_id = params['id'];
    });

    this.activatedRoute.queryParams.subscribe(res => {
      this.classifyProjectType = res.project_type;  
    });

    this.leftPanelOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
    };

    this.centerPanelOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
    };

    this.rightPanelOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
    };

    this.leftPanelGridColumns = [
      {
        'displayname': 'Data set',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'minWidth': 60,
        'maxWidth': 190,
        'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
      },
      {
        'displayname': 'Id',
        'physicalname': 'view_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 60,
        'maxWidth': 65,
      },
      {
        'displayname': 'Concept Column',
        'physicalname': 'concept_column',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'minWidth': 260,
        'maxWidth': 300,
        'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
        'formatter': columnFormattter,
      },
      {
        'displayname': 'Classifier',
        'physicalname': 'classifier',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'headerCssClass': 'text-left',
        'minWidth': 100,
        'maxWidth': 100,
      },
      {
        'displayname': 'Confidence',
        'physicalname': 'confidence_label',
        'sortable': true,
        'formatter': confidenceFormatter,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-center',
        'minWidth': 95,
        'maxWidth': 100,
        'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
      }
    ];

    this.centerPanelGridColumns = [
      {
        'displayname': 'Sub-Concept',
        'physicalname': 'sub_concept',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': columnFormattter
      },
      {
        'displayname': 'Best Prediction',
        'physicalname': 'prediction',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 120,
        'formatter': bestPredictionFormatter
      },
      {
        'displayname': 'Confidence',
        'physicalname': 'confidence',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': confidence100Formatter,
        'headerCssClass': 'text-left',
        'minWidth': 100,
        // 'cssClass': 'mr-3',
        // 'maxWidth': 130,
      }
    ];

    this.rightPanelGridColumns = [
      {
        'displayname': 'Prediction',
        'physicalname': 'prediction',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 230,
        'formatter': dsNameFormattter
      },
      {
        'displayname': 'Confidence',
        'physicalname': 'confidence',
        'sortable': true,
        'datatype': 'String',
        'filterable': false,
        'formatter': confidenceLevelFormatter,
        // 'cssClass': 'mr-3',
        'headerCssClass': 'text-left',
        'minWidth': 150,
      }
    ];
    this.subConceptColumn = [
      {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 30,
        'maxWidth': 30,
      },
      {
        'displayname': 'Sub-Concept',
        'physicalname': 'characteristic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
  ]
    this.getRecords(this.pageLeft, this.limitLeft);
  }

  getRecords(pageno, limit) {
    this.leftPanelTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.classify_project_id}/viewresults?project_type=14863&pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('id=') ? `&${filter}` : ''
              || filter.includes('concept_column=') ? `&${filter}` : ''
              || filter.includes('classifier=') ? `&${filter}` : ''
              || filter.includes('confidence_label=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=id') ? `&${sort}` : ''
              || sort.includes('orderby=concept_column') ? `&${sort}` : ''
              || sort.includes('orderby=classifier') ? `&${sort}` : ''
              || sort.includes('orderby=confidence_label') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += sort.includes('type=asc') ? `&${sort}` : ''
                || sort.includes('type=desc') ? `&${sort}` : '';
            }

          }
        });
      }
    }
    this.ZsClContentService.getParseRecords(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.classify_project_id, pageno, limit, appliedFilter, appliedSorting).subscribe(data => {
      this.leftPanelDS = data['currentpage'];
      console.log(data['currentpage'])
      if (this.leftPanelDS.length === 0) {
        this.noDataLeftPanel = true;
      }
      this.leftPanelDS.forEach(element => {
        element.view_id = element.id;
      });
      this.isLeftLoading = false;
      this.totalLeft = data['totalrecords'];
      let concatedLeftRows = this.leftPanelDS;
      if (this.leftPanelGridObject) {
        concatedLeftRows = [...this.leftPanelGridObject.dataView.getItems(), ...this.leftPanelDS];
        this.leftPanelDS = concatedLeftRows;
        this.leftPanelDS.forEach(element => {
          element.id = Math.random();
        });
        this.leftPanelGridObject.dataView.setItems(this.leftPanelDS);
        this.leftPanelGridObject.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLeftLoading = false;
    });
  }

  // on click of project grid row
  onLeftPanelRowSelection(e) {
    this.isCenterLoading = true;
    this.initRightGrid = true;
    this.hasScrolledCenter = false;
    this.selectedParseName = null;
    const args = e.args;
    const event = e.eventData;
    this.leftPanelGridObject.gridService.setSelectedRow(args.row);
    const metadata = this.leftPanelGridObject.gridService.getColumnFromEventArguments(args);
    const row = this.leftPanelGridObject.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    this.selectedRowLeftPanel=null;
    if (row !== undefined && row !== null) {              
      if (event.target.className.includes('blueLink') && fieldName === 'concept_column') {
        this.selectedRecordName = row.concept_column;
        this.selectedRowLeftPanel = encodeURIComponent(row.view_id); //encodeURIComponent is used to handle special characters in the string which being passed as parameters in URL
        this.pageCenter = 1;
        this.pageRight = 1;
        this.centerPanelDS = [];
        this.classifier = row.classifier;
        this.dataset_id = row.dataset_id
        if (this.centerPanelGridObject) {
          this.centerPanelGridObject.dataView.setItems(this.centerPanelDS);
        }
        this.getParsingPrediction(this.pageCenter, this.limitCenter);
      }
    }
  }

  getParsingPrediction(pageno, limit) {
    console.log(this.selectedRowLeftPanel)
    this.isCenterLoading = true;
    this.centerPanelTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.classify_project_id}/viewresults/${this.selectedRowLeftPanel}/pedictions?project_type=14863&pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('sub_concept=') ? `&${filter}` : ''
              || filter.includes('prediction=') ? `&${filter}` : ''
              || filter.includes('confidence=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=sub_concept=') ? `&${sort}` : ''
              || sort.includes('orderby=prediction=') ? `&${sort}` : ''
              || sort.includes('orderby=confidence=') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += sort.includes('type=asc') ? `&${sort}` : ''
                || sort.includes('type=desc') ? `&${sort}` : '';
            }

          }
        });
      }
    }
    this.ZsClContentService.getParsingPrediction(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.classify_project_id, this.selectedRowLeftPanel, pageno, limit, appliedFilter, appliedSorting).subscribe(data => {
      this.centerPanelDS = data['currentpage'];
      this.initCenterGrid = false;
      if (data['currentpage'].length === 0) {
        this.noDataCenterPanel = true;
      } 
      this.centerPanelDS.forEach(element => {
        element.view_id = element.id;
        element.score100 = (element.score * 100).toFixed();
      });
      this.totalCenter = data['totalrecords'];
        let concatedRows = this.centerPanelDS;
        if (this.centerPanelGridObject && this.centerPanelDS) {
          concatedRows = [...this.centerPanelGridObject.dataView.getItems(), ...this.centerPanelDS];
          this.centerPanelDS = concatedRows;
          this.centerPanelDS.forEach(element => {
            element.id = Math.random();
          });
          this.centerPanelGridObject.dataView.setItems(concatedRows);
          this.centerPanelGridObject.gridService.setSelectedRows([]);
          this.hasScrolledCenter = false;
          this.noDataCenterPanel = false;
        }
    }, err => {
      this.centerPanelDS = [];
      this.isCenterLoading = false;
    });
  }

  onCenterPanelRowSelection(e) {
    this.isRightLoading = true;
    this.bestPrediction = true;
    const args = e.args;
    const event = e.eventData;
    this.centerPanelGridObject.gridService.setSelectedRow(args.row);
    const metadata = this.centerPanelGridObject.gridService.getColumnFromEventArguments(args);
    const row = this.centerPanelGridObject.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    if (row !== undefined && row !== null) {              
      if (event.target.className.includes('blueLink') && fieldName === 'sub_concept') {
        this.selectedParseName = row.sub_concept; 
        this.selectedRowCenterPanel = row.view_id;
        this.getSubConcept();
      }
    }
  }

  getSubConcept() {
    this.isRightLoading = true;
    this.rightPanelTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.classify_project_id}/viewresults/${this.selectedRowLeftPanel}/pedictions/${this.selectedParseName}?project_type=14863`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('characteristic=') ? `&${filter}` : '';
          }
        });
      }
    }
    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=characteristic=') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += sort.includes('type=asc') ? `&${sort}` : ''
                || sort.includes('type=desc') ? `&${sort}` : '';
            }
          }
        });
      }
    }
    this.ZsClContentService.getSubConcept(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.classify_project_id, this.selectedRowLeftPanel, this.selectedParseName,appliedFilter, appliedSorting).subscribe(data => {
      this.rightPanelDS = data;
      this.initRightGrid = false;
      if (this.rightPanelDS.length === 0) {
        this.noDataRightPanel = true;
      }
      this.rightPanelDS.forEach(element => {
        if(this.bestPrediction) {
          element.bestPrediction = true;
          this.bestPrediction = false;
        }
        element.newId = element.id;
        element.score100 = (element.score * 100).toFixed();
      });
      this.isRightLoading = false;
      this.totalRight = this.rightPanelDS.length;
      if(this.rightPanelDS) {
        this.rightPanelDS.forEach(element => {
          element.id = Math.random();
        });
      }
      if (this.rightPanelGridObject && this.rightPanelDS) {
        this.rightPanelGridObject.dataView.setItems(this.rightPanelDS);
        this.rightPanelGridObject.gridService.setSelectedRows([]);
      }
    }, err => {
      this.rightPanelDS = [];
      this.isRightLoading = false;
    });
  }

  onRightPanelRowSelection(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightPanelGridObject.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {        
        this.showmodelpopup = true;
      } else if (eventEle.target.className == 'fal fa-thumbs-down') {
        this.selectedSubConceptName =row.prediction
        this.showDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;
        let dislikeData = {
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 0,
          task_id: row.taskId,
          class: row.class
        };

        this.classInfo = dislikeData;        
        this.rowData = args.row;
        this.classes = 'fal fa-thumbs-down';
      } else if (eventEle.target.className == 'fal fa-thumbs-up') {  
        const payload = {
          tasks: [
            {
              "id": this.selectedRowLeftPanel,
              "vote": 1,
              "sub_concept": this.selectedParseName,
              "prediction": row.prediction,
              "classifier": this.classifier,
              "dataset_id": this.dataset_id
            }
          ]
        };
        this.ZsClContentService.saveSelectedFeedBack(payload, this.classify_project_id,false).subscribe(resp => {
          if(resp) {
            $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
            $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
            $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
            $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          }
      }, error => { });        
      }
    }
  }

  onShowPanel(event){
   this.topPanel = event;
  }

  closeModalPopup(event) {
    if (event.isDownVoted) {
      if(event.vote && event.vote==1){
        $('#thumbs-up-' +  this.selectedMappedColumn).removeClass('fal fa-thumbs-up');
            $('#thumbs-up-' +  this.selectedMappedColumn).addClass('fas fa-thumbs-up');
            $('#thumbs-down-' +  this.selectedMappedColumn).removeClass('fas fa-thumbs-down');
            $('#thumbs-down-' + this.selectedMappedColumn).addClass('fal fa-thumbs-down');
      }else{
        $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
        $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
        $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      }
     
      this.baseService.isStatisticDataChanged.next(true);
    }
    this.showDownvoteModal = false
  }

  downVoted(event) {
    if(event) {
     if(this.classes === 'fas fa-thumbs-down') {
        $('#thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
        $('#thumbs-down-' + this.rowData).addClass('fal fa-thumbs-down');
        $('#thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
     } else {
        // $('#thumbs-down-' + this.rowData.args.row).removeClass('fal fa-thumbs-down');
        // $('#thumbs-down-' + this.rowData.args.row).addClass('fas fa-thumbs-down');
        $('#thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.rowData).addClass('fal fa-thumbs-up');
        $('#thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
     }
    }
  }

  closeModal() {
    $('#datasets_modal').removeClass('datasets_modal');
  }

  popupoutput(message) {
    this.showmodelpopup = message;
  }

  onLeftGridCreation(leftgrid) {
    this.leftPanelGridObject = leftgrid;
  }

  onCenterGridCreation(centergrid) {
    this.centerPanelGridObject = centergrid;
  }

  onRightGridCreation(rightgrid) {
    this.rightPanelGridObject = rightgrid;
  }

  onNextLeft(): void {
    this.pageLeft++;
    this.getRecords(this.pageLeft, this.limitLeft);
  }

  onPrevLeft(): void {
    this.pageLeft--;
    this.getRecords(this.pageLeft, this.limitLeft);
  }

  onLeftGridScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noDataLeftPanel) {
      this.hasScrolled = true;
      this.onNextLeft();
    }
  }

  onNextCenter(): void {
    this.pageCenter++;
    this.getParsingPrediction(this.pageCenter, this.limitCenter)
  }

  onCenterGridScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolledCenter && !this.noDataCenterPanel) {
      this.hasScrolledCenter = true;
      this.onNextCenter();
    }
  }
  
  onRunModel(){
  if(this.classifyProjectType === 'Concept Parser') {
    let payload = {
            classify_project_id: this.classify_project_id,
            classify_picker: false,
            supervised: true
    }
    this.ZsClContentService.runConceptParserProject(payload)
    .subscribe(resp =>{
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.classify_project_id}`});
    },error=>{
    });
  } else {
    const payload = {
      classify_project_id: this.classify_project_id,
      classify_picker: false,
      supervised: true
    };
    this.ZsClContentService.runModelClassifyProject(payload).subscribe(resp =>{
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.classify_project_id}`});
    },error=>{
    });
  }
  }

  onExport() {
    this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.classify_project_id}/viewresults?project_type=14863&export=true`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `view_results_${this.classify_project_id}`);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onPublish() {
    this.datasetSvc.runModel416(this.classify_project_id,this.loggedInUserDetails['user_id']).subscribe((result) => {      
			this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify-Project', messageHeader: 'Publish Results in Progress', 
										  messageText: 'The process to Publish Results has been triggered. You can see job status and monitor progress for Classify Projects jobs in the System Jobs screen'});                         
		},error=>{
			this.messageSvc.sendMessage({message: 'Algo 416 call has failed', type: 'ERROR', hideboard: true});
		});  
  }

  showSubConceptModal(event){
    if(event.isSubConcept){
      this.isSubConcept=event.isSubConcept;
      this.selectedPridiction = event.data;
    }
   
  }
  public subConceptGrid: AngularGridInstance;
  subConceptColumn: object =[];
  subConceptResult: any = [
    ]
 
  onSubConceptCreation(grid) {
    this.subConceptGrid = grid;
  }

  onSubConceptCellClick(e){
    const args = e.args;
    const event = e.eventData;
    this.subConceptGrid.gridService.setSelectedRow(args.row);
    const metadata = this.subConceptGrid.gridService.getColumnFromEventArguments(args);
    const row = this.subConceptGrid.gridService.getDataItemByRowIndex(args.row);
    $("#data-" + args.row).prop("checked", true);
    this.selectedSubConcept = row.characteristic;
  }
  onApplySubConcept(){
    this.subConceptList=[];
    this.selectedPridiction.forEach(element => {
      this.subConceptList.push(
        {
          sub_concept: this.selectedSubConcept,
          prediction:element,          
        }
      )
    });
    
    this.baseService.addSubConcept.next({isAddConcept:true,data:this.subConceptList});
    $('#subConceptModal').modal('hide');
  }
  receiveTotalRecordsLeft($event) {
    this.totalLeft=$event;      
  }
  receiveTotalRecordsCenter($event) {
    this.totalCenter=$event;      
  }
  receiveTotalRecordsRight($event) {
    this.totalRight=$event;      
  }

  onBack() {
    this._router.navigate(['/zs-cl/projects/', this.classify_project_id ],{ queryParamsHandling: 'merge' });                 
  }

  onTrainModel() {
    this._router.navigate(['/zs-cl/projects/', this.classify_project_id,'train-model' ],{ queryParamsHandling: 'merge' });                 
  }
}
