import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../../../../../zettasense/content/project/project.service';
import { entityNameFormatter } from '../../../../shared/formatters/entityNameFormatter';
import { projectNameFormattter } from '../../../../shared/formatters/projectNameFormatter';
import { ZettaUtils } from '../../../../shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../../../components/message/message.service';

@Component({
  selector: 'zetta-related-projects',
  templateUrl: './related-projects.component.html',
  styleUrls: ['./related-projects.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RelatedProjectsComponent implements OnInit, OnDestroy {

  public projectDataSource = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': "100%", 'width': "99%", 'pageSize': 6, 'isNormalPagination': false };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  dataset_id;

  isLoading = true;
  page = 1;
  limit = 100;
  noData = false;

  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {

    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = parseInt(params['id']);
    });

    this.getProjectHomeList(this.page, this.limit);
    this.projectHomeTableColDef = [{
      'displayname': 'Project Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 220,
      'formatter': projectNameFormattter,
    }, {
      'displayname': 'Relationship',
      'physicalname': 'relationship',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': null,
      'minWidth': 120,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 40,
    };

  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getProjectHomeList(pageno, limit = 1) {
    this.ProjectSvc
      .getRelatedProjectsByDataSetId(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, pageno, limit)
      .subscribe((data) => {
        this.projectDataSource = data;

        if (this.projectDataSource.length === 0) {
          this.noData = true;
        }
        this.isLoading = false;
        let concatedRows = this.projectDataSource;

        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.projectDataSource];
          this.projectDataSource.forEach(element => {
            element.id = Math.random();
          });
          this.projectDataSource = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
      }, err => {
        this.messageSvc.sendMessage({ message: 'Get list of projects failed', type: 'INFO', hideboard: true });
        this.isLoading = false;
      });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/projects', row.project_id]);
      }
    }
  }

}
