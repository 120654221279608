

import { Column, Formatter } from 'angular-slickgrid';
import { ZmZettaUtils } from '../zm-zettaUtils';

const zettaUtilsInstance = new ZmZettaUtils();

export const toolTipFormatter: Formatter =
    (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        return `<p class="text-truncate" title="${value || 'null'}">${value || '<span class="blur">null</span>'}</p>`;
    };
