import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { ZettaUtils } from "../../../common/shared/zettaUtils";
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";
import { Router } from "@angular/router";
import { ZsClContentService } from "../zs-cl-content.service";
import { blueTextFormattter } from "../../../common/shared/formatters/blueTextFormatter";
import { environment } from "../../../../environments/environment";
import { dateFormatter } from "../../../common/shared/formatters/dateFormatter";
import { MessageService } from "../../../common/components/message/message.service";
import { DatasetService } from "../../../common/components/datasets/dataset.service";
import { ProjectService } from "../../../zettasense/content/project/project.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BaseService } from "../../../common/services/base-service";
import _ from "lodash";
import { measuredDQFormatter } from "src/app/common/shared/formatters/measuredDQFormatter";
import { AppGlobals } from "src/app/common/shared/app.globals";
import { totalRulesFormatter } from "src/app/common/shared/formatters/totalRulesFormtter";
import { mappingConfidenceFormatter } from "src/app/common/shared/formatters/mappingConfidenceFormatter";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  ITextFilterParams,
} from "ag-grid-community";
import { HttpClient } from "@angular/common/http";
import { AgGridAngular } from "ag-grid-angular";
import { BlueTextCellRendererComponent } from "src/app/common/shared/cell-renderer/catalog-blue-text-formatter/catalog-blue-text-formatter.component";
import { NullCellRendererComponent } from "src/app/common/shared/cell-renderer/null-formatter/null-formatter.component";
import { DateFormatterFormatterComponent } from "src/app/common/shared/cell-renderer/date-formatter/date-formatter.component";
import { ActionLinkFormatterComponent } from "src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component";
import { MeasuredDQRuleFormatterComponent } from "src/app/common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component";
import { RuleCountFormatterComponent } from "src/app/common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component";
import { NumericFormatterComponent } from "src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component";
import { NumericCellRendererComponent } from "src/app/common/shared/cell-renderer/catalog-numeric-formatter/catalog-numeric-formatter-component";
import { ActionsObjectFormatterComponent } from "src/app/common/shared/cell-renderer/action-object-formatter/action-object-formatter.component";
import { AdminActionsRendererComponent } from "src/app/common/shared/cell-renderer/catalog-action-link-formatter/catalog-action-link-formatter.component";

declare var $: any;

const DATA_MSG = "";

const numericFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return `<div class="text-right w-100 pd-right-10 blueLink" title="${value}">${value}</div>`;
};

const actionsObjectFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  if (columnDef.field === "mapped_data_sources") {
    if (value > 0) {
      return `<div class="text-right w-100 blueLink pointer pd-right-10" title='${value}'>
                        ${value}
                    </div>`;
    } else {
      return `<div class="text-normal text-right w-100 pd-right-10" title='${value}'>
                        ${value}
                    </div>`;
    }
  } else if (columnDef.field === "mapped_data_sets") {
    if (value > 0) {
      return `<div class="text-right w-100 blueLink pointer pd-right-10" title='${value}'>
                          ${value}
                      </div>`;
    } else {
      return `<div class="text-normal w-100 text-right pd-right-10" title='${value}'>
                          ${value}
                      </div>`;
    }
  }
};

const datasourceNameFormattter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return `<div class="datasource-name-formatter text-wrap">
          <span title='${dataContext.name}' class="pointer hyper-link">${dataContext.name}</span><br>
          </div>`;
};

const datasetNameFormattter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return `<div class="datasource-name-formatter text-wrap">
          <span title='${value}' class="pointer hyper-link">${value}</span><br>
      </div>`;
};

const mappingFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  let cssLevel = "";
  let confidenceLevel = "";
  const score100 = dataContext.score * 100;
  const level =
    score100 >= 75
      ? "HIGH"
      : score100 >= 50 && score100 < 75
      ? "MEDIUM"
      : "LOW";
  cssLevel = level === "HIGH" ? "" : level === "MEDIUM" ? "boxM" : "boxL";
  confidenceLevel += `<div class="box-catalog ${cssLevel} text-center">${level}</div>
                      <div class="porcent text-center">${score100.toFixed()}%</div>
                      <div class="handUp text-bold pointer"><i class="fal fa-thumbs-up"></i></div>
                      <div class="handDown text-bold pointer"><i class="fal fa-thumbs-down handDown"></i></div>`;

  return confidenceLevel;
};

const totalRulesFormatter2: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  let isRuleDisabled = false;
  let loggedInUserDetail = JSON.parse(sessionStorage.getItem("userInfo"));
  if (!loggedInUserDetail["enable_dq_rule"]) {
    isRuleDisabled = true;
  }
  if (dataContext.action !== "add") {
    return isRuleDisabled
      ? `<div class="text-right w-100 pd-20 disabled-state disabled inactive-text" title="${value}">${value}</div>`
      : value > 0
      ? `
    <div class="text-center w-100 pd-20 blueLink" title="${value}">${value}</div>`
      : `<div class="ml-1 text-center w-100 pd-20 blueLink" title="Add">Add</div>`;
  }
};

const actionsFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return dataContext.isdisable
    ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v f-size-22" aria-hidden="true"></i></span>`
    : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v f-size-22" aria-hidden="true"></i></span>`;
};

@Component({
  selector: "zetta-zs-cl-catalogs",
  templateUrl: "./zs-cl-catalogs.component.html",
  styleUrls: ["./zs-cl-catalogs.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class ZsClCatalogsComponent implements OnInit, OnDestroy {
  public catalogs: any;
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = {
    height: "100%",
    width: "100%",
    pageSize: 20,
    isNormalPagination: false,
  };
  public datasourcetableSettings: object = {
    height: "100%",
    width: "100%",
    pageSize: 20,
    isNormalPagination: false,
  };
  public datasettableSettings: object = {
    height: "100%",
    width: "100%",
    pageSize: 20,
    isNormalPagination: false,
  };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  grandTotal = 0;
  total = 0;
  subTotal = "";
  page = 1;
  pageDs = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  usersData;
  catalog_id;
  datasources: any = [];
  datasets: any = [];
  totalDatasources = 0;
  totalDatasets = 0;
  grandTotalDatasets = 0;
  subTotalDataset = "";
  isLoadingDatasources = true;
  isLoadingDatasets = true;
  gridDatasources: AngularGridInstance;
  gridDatasets: AngularGridInstance;
  hasDatasourcesScrolled = false;
  hasDatasetsScrolled = false;
  datasourcesTableColDef: any;
  datasetsTableColDef: any;
  gridDataOptions: any;
  dataCatalog;
  modalRowData: any;
  modalGridOptions: any;
  modelColumnDef = [];
  isSampleDsLoading = true;
  public modalGrid: AngularGridInstance;
  url;
  safeSrc: SafeResourceUrl;
  dsName;
  showmodelpopup = false;
  datasetId: any;
  isFiltering = false;
  isSorting = false;
  msgHeader: string;
  msgText: string;
  showConfirmBox: boolean;
  clearModal = Math.random(); // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
  isCatalogAdmin: boolean = false;
  uploaded_files = [];
  dataRes;
  is_upload_complete = false;
  dataResponse;
  is_upload_complete_classify = false;
  fu_type_synonymImport: any;
  entity_id: number;
  showDownvoteModal = false;
  selectedDatasetRow: any;
  selectedDatasetRowNo: number;
  dataset_type_id: number;
  row;

  columnApi: any;
  gridApi: any;
  shouldOpenModal: boolean = false;

  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {};
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  displayedRowCount: any;
  subTotalRecords = 0;

  constructor(
    private catalogSvc: ZsClContentService,
    private datasetSvc: DatasetService,
    private projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    private router: Router,
    private sanitizer: DomSanitizer,
    private baseService: BaseService,
    private messageSvc: MessageService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fu_type_synonymImport = AppGlobals.FU_TYPE_SYNONYM_IMPORT;
    sessionStorage.removeItem("serverfilter");
    sessionStorage.removeItem("sortfilter");
    sessionStorage.removeItem("catalog_user");
    sessionStorage.removeItem("viewMode");
    $("#breadcrumb").removeClass("d-none");
    this.getCatalogList(this.page, this.limit);
    this.projectHomeTableColDef = [
      {
        displayname: "",
        physicalname: "actions",
        sortable: false,
        datatype: "String",
        filterable: false,
        cssClass: "text-right",
        formatter: actionsFormatter,
        maxWidth: 12,
      },
      {
        displayname: "Id",
        physicalname: "catalog_id",
        sortable: true,
        datatype: "String",
        filterable: true,
        maxWidth: 100,
      },
      {
        displayname: "Data Catalog",
        physicalname: "name",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 100,
        formatter: blueTextFormattter,
      },
      {
        displayname: "Data Catalog Description",
        physicalname: "description",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 80,
      },
      {
        displayname: "Created By",
        physicalname: "created_by",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 90,
        maxWidth: 150,
      },
      {
        displayname: "Last Modified On",
        physicalname: "last_modified_on",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 80,
        maxWidth: 80,
        headerCssClass: "text-left titleBreak pt-0 pb-0 pr-3 toRight",
        formatter: dateFormatter,
      },
      {
        displayname: "Semantic Objects",
        physicalname: "semantic_objects",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: "text-left titleBreak pt-2 pb-0 pr-3 toRight",
        formatter: numericFormatter,
      },
      {
        displayname: "Concepts",
        physicalname: "concepts",
        sortable: true,
        datatype: "Numeric",
        filterable: true,
        formatter: numericFormatter,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        displayname: "Mapped Data Sources",
        physicalname: "mapped_data_sources",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: actionsObjectFormatter,
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: "text-left titleBreak pt-0 pb-0 pr-3 toRight",
      },
      {
        displayname: "Mapped Data Sets",
        physicalname: "mapped_data_sets",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: actionsObjectFormatter,
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: "text-left titleBreak pt-1 pb-0 pr-3 toRight",
      },
      {
        displayname: "Mapped Data Set Columns",
        physicalname: "mapped_data_set_columns",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: "text-left titleBreak pt-0 pb-0 pr-3 toRight",
        formatter: numericFormatter,
      },
      {
        displayname: "# of Data Quality Rules",
        physicalname: "total_rules",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: totalRulesFormatter2,
        cssClass: "text-center",
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: `text-left titleBreak pt-0 pb-0 pr-3 toRight ${
          this.loggedInUserDetails["enable_dq_rule"] ? "" : "disabled"
        }`,
      },
      {
        displayname: "Measured Data Quality",
        physicalname: "measured_dq_score",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: measuredDQFormatter,
        cssClass: "text-right",
        minWidth: 90,
        maxWidth: 90,
        headerCssClass: `text-left titleBreak pt-0 pb-0 pr-3 toRight ${
          this.loggedInUserDetails["enable_dq_rule"] ? "" : "disabled"
        }`,
      },
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      editable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30,
      noDataMsg: DATA_MSG,
    };

    this.datasourcesTableColDef = [
      {
        displayname: "Data Source",
        physicalname: "name",
        sortable: true,
        datatype: "String",
        filterable: true,
        cssClass: "blueLink",
        formatter: datasourceNameFormattter,
        minWidth: 70,
      },
      {
        displayname: "Description",
        physicalname: "description",
        sortable: true,
        datatype: "String",
        filterable: true,
        minWidth: 200,
      },
    ];

    this.datasetsTableColDef = [
      {
        displayname: "Data Source",
        physicalname: "datasource_name",
        sortable: true,
        datatype: "String",
        filterable: true,
        cssClass: "blueLink",
        formatter: datasetNameFormattter,
        // 'minWidth': 70,
      },
      {
        displayname: "Data Set",
        physicalname: "name",
        sortable: true,
        datatype: "String",
        filterable: true,
        cssClass: "blueLink",
        formatter: datasetNameFormattter,
        // 'minWidth': 70,
      },
      {
        displayname: "Mapping Confidence Level",
        physicalname: "score",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: mappingConfidenceFormatter,
        minWidth: 350,
        maxWidth: 370,
      },
    ];

    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };
    this.intialiseGrid();
  }
  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: "",
        field: "actions",
        maxWidth: 23,
          filter: false,
        sortable: false, 
        cellClass: 'elipse-overflow',
        suppressColumnsToolPanel: true,
        cellRendererFramework: AdminActionsRendererComponent,
      },
      {
        headerName: "Id",
        field: "catalog_id",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        minWidth: 100,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Data Catalog",
        field: "name",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        minWidth: 200,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: BlueTextCellRendererComponent,
      },
      {
        headerName: "Data Catalog Description",
        field: "description",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        minWidth: 140,
        tooltipField: "role_name",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: NullCellRendererComponent,
      },
      {
        headerName: "Created By",
        field: "created_by",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        minWidth: 120,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Last Modified On",
        field: "last_modified_on",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        minWidth:120,
        cellRendererFramework : DateFormatterFormatterComponent,
      },
      {
        headerName: "Semantic Objects",
        field: "semantic_objects",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: NumericCellRendererComponent,
        minWidth:150
      },
      {
        headerName: "Concepts",
        field: "concepts",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: NumericCellRendererComponent,
        minWidth:100
      },
      {
        headerName: "Mapped Data Sources",
        field: "mapped_data_sources",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: ActionsObjectFormatterComponent,
        minWidth:140,
        maxWidth:140
      },
      {
        headerName: "Mapped Data Sets",
        field: "mapped_data_sets",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: ActionsObjectFormatterComponent,
        minWidth:140,
        maxWidth:140
      },
      {
        headerName: "Mapped Data Set Columns",
        field: "mapped_data_set_columns",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: NumericCellRendererComponent,
        minWidth:140,
        maxWidth:140
      },
      {
        headerName: "# of Data Quality Rules",
        field: "total_rules",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: RuleCountFormatterComponent,
        minWidth:140,
        maxWidth:140
      },
      {
        headerName: "Measured Data Quality",
        field: "measured_dq_score",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cellClass: 'cell-align-center-text',
        sortable: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: MeasuredDQRuleFormatterComponent,
        minWidth:140,
        maxWidth:140
      },
    ];
    this.fsGridOptions = {
      enableCellTextSelection:true,
      ensureDomOrder: true,
      headerHeight: 45,
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: (params) => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getCatalogList(pageNo, limit = 1) {
    //limit = pageNo === 1 ? +(($("#catalogsGrid").height() - 70) / 30).toFixed() + 3 : this.limit;
    this.tableSettings["search_url"] = `${environment.config.API_URL}/users/${
      this.loggedInUserDetails["user_id"]
    }/catalogs?pagination=true&pageno=${1}&pagesize=${limit}`;
    this.tableSettings[
      "api_url"
    ] = `${environment.config.API_URL}/users/${this.loggedInUserDetails["user_id"]}/catalogs`;
    var serverfilter = "";
    // if(sessionStorage.getItem("serverfilter")){
    //   var serverfilter = sessionStorage.getItem("serverfilter");
    //   this.isFiltering = true;
    // } else {
    //   if(this.isFiltering){
    //     pageNo = 1;
    //   }
    //   this.isFiltering = false;
    // }
    var sortfilter = "";
    // if (sessionStorage.getItem("sortfilter")) {
    //   var sortfilter = sessionStorage.getItem("sortfilter");
    //   this.isSorting = true;
    // } else {
    //   if (this.isSorting) {
    //     pageNo = 1;
    //   }
    //   this.isSorting = false;
    // }
    this.isLoading = true;

    this.catalogSvc
      .getCatalogsListPagedAndSearch(pageNo, limit, serverfilter, sortfilter)
      .subscribe(
        (data) => {
          this.catalogs = data["currentpage"];
          if (this.catalogs.length === 0) {
            this.noData = true;
          }
          
          this.catalogs.forEach((element) => {
            element.id = Math.random();
            element.description =
            element.description === null ? "" : element.description;
            element.semantic_objects = +element.semantic_objects;
            element.concepts = +element.concepts;
            element.mapped_data_sources = +element.mapped_data_sources;
            element.mapped_data_sets = +element.mapped_data_sets;
            element.mapped_data_set_columns = +element.mapped_data_set_columns;
            element.isRuleEnabled = this.loggedInUserDetails.enable_dq_rule;
            element.isdisable =
              element.is_default_tenant_catalog || element.is_system
                ? true
                : false;
          });
          this.isLoading = false;
          this.total = data["totalrecords"];
          if (pageNo === 1) {
            this.grandTotal = data["totalrecords"];
          }
          let concatedRows = this.catalogs;
          if (this.grid) {
            concatedRows = [...this.grid.dataView.getItems(), ...this.catalogs];
            this.catalogs = concatedRows;
            this.grid.dataView.setItems(concatedRows);
            this.grid.gridService.setSelectedRows([]);
            if (pageNo < data["totalpages"]) {
              this.hasScrolled = false;
            }
          }

          if (pageNo > data["totalpages"]) {
            this.page = 1;
            this.tableSettings["search_url"] = `${
              environment.config.API_URL
            }/users/${
              this.loggedInUserDetails["user_id"]
            }/catalogs?pagination=true&pageno=${1}&pagesize=${limit}`;
          }
        },
        (error) => {
          this.isLoading = false;
          // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
        }
      );
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSort.subscribe((e, args) => {
      this.page = 1;
      this.hasScrolled = false;
    });
  }

  onNext(): void {
    this.page++;
    this.getCatalogList(this.page, this.limit);
  }

  onCellClick(eventData): void {
    const eventEle = eventData.eventData;
    const args = eventData.args;
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    this.dataCatalog = row.name;

    if (row !== undefined && row !== null) {
      this.catalog_id = row.catalog_id;
      //this.checkCatalogAdmin(row.catalog_id);
      if (eventEle.target.title === row.name) {
        sessionStorage.removeItem("isCatalogAdmin");
        this.catalogSvc.getCatalogUser(row.catalog_id).subscribe((resp) => {
          if (resp.length) {
            resp.forEach((user) => {
              if (user.user_id == this.loggedInUserDetails.user_id) {
                const userRoles = user.roles.filter((role) => role === 32);
                if (userRoles.length) {
                  sessionStorage.setItem("isCatalogAdmin", "true");
                }
              }
            });
          }
        });
        this.router.navigate(["/zs-cl/catalogs", row.catalog_id], {
          queryParams: { showBreadcrumb: true },
        });
      }
      if (
        eventEle.target.className.includes("blueLink") &&
        fieldName === "mapped_data_sources"
      ) {
        this.getDatasources(row.catalog_id);
        $("#datasources_modal").modal("show");
      }
      if (
        eventEle.target.className.includes("blueLink") &&
        fieldName === "mapped_data_sets"
      ) {
        sessionStorage.removeItem("serverfilter");
        sessionStorage.removeItem("sortfilter");
        this.pageDs = 1;
        this.isLoadingDatasets = true;
        this.datasets = [];
        this.getDatasets(row.catalog_id, this.pageDs, this.limit);
        $("#datasets_modal").modal("show");
      }
      if (fieldName === "total_rules") {
        if (this.loggedInUserDetails["enable_dq_rule"] && row.total_rules > 0) {
          //this.catalogSvc.showExceptionsGrid.next({ showException: false, name: "" });
          this.router.navigate(
            ["/zs-cl/catalogs/" + row.catalog_id + "/dq-rule"],
            {
              queryParams: {
                catalog_id: row.catalog_id,
                dqRuleLevel: "catalog",
                showBreadcrumb: true,
              },
            }
          );
        }
        if (
          (this.loggedInUserDetails["enable_dq_rule"] && !row.total_rules) ||
          (this.loggedInUserDetails["enable_dq_rule"] && row.total_rules == 0)
        ) {
          this.router.navigate(["/zs-cl/rules/create-bussiness-rule"], {
            queryParams: {
              catalog_id: row.catalog_id,
              dqRuleLevel: "catalog",
              showBreadcrumb: true,
              BackTo: "catalog",
            },
          });
        }
      }
    }
  }
  onGridReady(grid: any) {
    this.agGrid = grid;
    this.columnApi = grid.columnApi;
  }

  onAgCellClick(cellParams) {
    const fieldName = cellParams.colDef.field;
    const eventEle = cellParams.event;
    this.row = cellParams.data;
    this.dataCatalog = this.row.name;
    this.isCatalogAdmin = false;
    if (this.row !== undefined && this.row !== null) {
      this.catalog_id = this.row.catalog_id;
      if (cellParams.value === this.row.name) {
        this.router.navigate(["/zs-cl/catalogs", this.row.catalog_id], {
          queryParams: { showBreadcrumb: true },
        });
      }

      if (fieldName === "total_rules") {
        if (
          this.loggedInUserDetails["enable_dq_rule"] &&
          this.row.total_rules > 0
        ) {
          //this.catalogSvc.showExceptionsGrid.next({ showException: false, name: "" });
          this.router.navigate(
            ["/zs-cl/catalogs/" + this.row.catalog_id + "/dq-rule"],
            {
              queryParams: {
                catalog_id: this.row.catalog_id,
                dqRuleLevel: "catalog",
                showBreadcrumb: true,
              },
            }
          );
        }

        if (
          (this.loggedInUserDetails["enable_dq_rule"] &&
            !this.row.total_rules) ||
          (this.loggedInUserDetails["enable_dq_rule"] &&
            this.row.total_rules == 0)
        ) {
          this.router.navigate(["/zs-cl/rules/create-bussiness-rule"], {
            queryParams: {
              catalog_id: this.row.catalog_id,
              dqRuleLevel: "catalog",
              showBreadcrumb: true,
              BackTo: "catalog",
            },
          });
        }
      }

      if (fieldName == "actions") {
        if (this.row.isdisable) {
          $("#context_menu").hide();
        } else {
          this.catalogSvc
            .getCatalogUser(this.row.catalog_id)
            .subscribe((resp) => {
              $("#context_menu")
                .removeClass("d-none")
                .data("row", cellParams.row)
                .css("top", eventEle.pageY)
                .css("left", eventEle.pageX + 4)
                .css("position", "fixed")
                .css("display", "block")
                .show();
              if (resp.length) {
                resp.forEach((user) => {
                  if (user.user_id == this.loggedInUserDetails.user_id) {
                    const userRoles = user.roles.filter((role) => role === 32);
                    if (userRoles.length) {
                      this.isCatalogAdmin = true;
                    } else {
                      this.isCatalogAdmin = false;
                    }
                  }
                });
              } else {
                this.isCatalogAdmin = false;
              }
            });
        }
        $("body").one("click", function () {
          $("#context_menu").hide();
        });
      }
      if (
        eventEle.target.classList.contains("blueLink") &&
        fieldName === "mapped_data_sources"
      ) {
        this.getDatasources(this.row.catalog_id);
        $("#datasources_modal").modal("show");
      }
      if (
        eventEle.target.classList.contains("blueLink") &&
        fieldName === "mapped_data_sets"
      ) {
        sessionStorage.removeItem("serverfilter");
        sessionStorage.removeItem("sortfilter");
        this.pageDs = 1;
        this.isLoadingDatasets = true;
        this.datasets = [];
        this.getDatasets(this.row.catalog_id, this.pageDs, this.limit);
        $("#datasets_modal").modal("show");
      }
    }
  }

  onEditCatalog() {
    if (this.isCatalogAdmin) {
      this.router.navigate(["/zs-cl/catalogs/create-catalog"], {
        queryParams: {
          catalog_id: this.row.catalog_id,
          showBreadcrumb: false,
          isEdit: true,
        },
      });
    } else {
      return false;
    }
  }

  onDeleteCatalog() {
    if (this.isCatalogAdmin) {
      this.showConfirmBox = true;
      this.msgHeader = AppGlobals.CATALOGS_DELETE_MSG_TEXT;
    }
  }

  checkCatalogAdmin(catalog_id) {
    sessionStorage.removeItem("isCatalogAdmin");
    this.catalogSvc.getCatalogUser(catalog_id).subscribe((resp) => {
      if (resp.length) {
        resp.forEach((user) => {
          if (user.user_id == this.loggedInUserDetails.user_id) {
            const userRoles = user.roles.filter((role) => role === 32);
            if (userRoles.length) {
              this.isCatalogAdmin = true;
              sessionStorage.setItem("isCatalogAdmin", "true");
            } else {
              this.isCatalogAdmin = false;
            }
          }
        });
      }
    });
  }
  deleteRow() {
    this.isLoading = true;
    this.catalogSvc
      .deleteCatalog(this.loggedInUserDetails.user_id, this.catalog_id)
      .subscribe(
        ( ) => {
          this.clearText(this.catalogs);
        if (this.agGrid) {
          this.agGrid.api.purgeServerSideCache();
        }
        this.isLoading = false;
        },
        (error) => {
          this.messageSvc.sendMessage({
            message: "Delete catalog has failed",
            type: "INFO",
            hideboard: true,
          });
        }
      );
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    }
    if (sessionStorage.getItem("sortfilter")) {
      this.isSorting = true;
      this.noData = false;
    } else {
      if (this.isSorting) {
        this.page = 1;
        this.isSorting = false;
        this.noData = false;
      }
    }
    if (this.isFiltering) {
      this.page = 1;
      this.isFiltering = false;
      this.noData = false;
    }

    if (!this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  getDatasources(catalogId) {
    const getDatasources: any = {
      user_id: this.loggedInUserDetails["user_id"],
      catalog_id: catalogId,
    };
    this.catalogSvc.getCatalogDatasources(getDatasources).subscribe(
      (data) => {
        this.datasources = data;
        if (this.datasources.length === 0) {
          this.noData = true;
        }
        this.totalDatasources = this.datasources.length;
        this.isLoadingDatasources = false;
        if (this.gridDatasources) {
          this.datasources.forEach((element) => {
            element.id = Math.random();
          });
          this.gridDatasources.dataView.setItems(this.datasources);
          this.gridDatasources.gridService.setSelectedRows([]);
          this.hasDatasourcesScrolled = false;
        }
      },
      (err) => {}
    );
  }

  onDatasourcesGridCreation(grid) {
    this.gridDatasources = grid;
  }

  onDatasourceCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasources.gridService.getDataItemByRowIndex(
      args.row
    );
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        $("#datasources_modal").modal("hide");
        $("#datasources_modal").addClass("d-none");
        this.router.navigate(["/zs/datasources", row.datasource_id]);
      }
    }
  }

  getDatasets(catalogId, pageNo, limit) {
    const getDataset: any = {
      user_id: this.loggedInUserDetails["user_id"],
      catalog_id: catalogId,
      pageNo: pageNo,
      limit: limit,
    };
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.datasettableSettings["search_url"] = `${
      environment.config.API_URL
    }/users/${
      this.loggedInUserDetails["user_id"]
    }/catalogs/${catalogId}/datasets?pagination=true&pageno=${1}&pagesize=${limit}`;
    this.catalogSvc
      .getCatalogDatasets(getDataset, serverFilter, sortfilter)
      .subscribe(
        (data) => {
          this.datasets = data["currentpage"];
          if (this.datasets.length === 0) {
            this.noData = true;
          }
          this.datasets.forEach((element) => {
            element.id = Math.random();
            element.score100 = (element.score * 100).toFixed();
            element.level =
              element.score100 >= 75
                ? "HIGH"
                : element.score100 >= 50 && element.score100 < 75
                ? "MEDIUM"
                : "LOW";
          });
          this.datasets.sort((a, b) => b.score100 - a.score100);
          this.totalDatasets = data["totalrecords"];
          if (pageNo === 1) {
            this.grandTotalDatasets = data["totalrecords"];
          }
          this.isLoadingDatasets = false;
          let concatedRows = this.datasets;
          if (this.gridDatasets) {
            if (pageNo > 1) {
              concatedRows = [
                ...this.gridDatasets.dataView.getItems(),
                ...this.datasets,
              ];
              this.datasets = concatedRows;
            }
            this.gridDatasets.dataView.setItems(this.datasets);
            this.gridDatasets.gridService.setSelectedRows([]);
            this.hasDatasetsScrolled = false;
          }
          this.isLoadingDatasets = false;
        },
        (err) => {
          this.isLoadingDatasets = false;
        }
      );
  }

  onDatasetsGridCreation(grid) {
    const parent = this;
    parent.gridDatasets = grid;
    parent.gridDatasets.slickGrid.onSort.subscribe((e, args) => {
      this.pageDs = 1;
      this.hasDatasetsScrolled = false;
    });
  }

  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      this.entity_id = row.entity_id;
      this.selectedDatasetRow = row;
      this.selectedDatasetRowNo = args.row;
      if (eventEle.target.title === row.name) {
        this.dsName = row.name;
        this.showmodelpopup = true;
        this.datasetId = row.dataset_id;
      } else if (eventEle.target.title === row.datasource_name) {
        $("#datasets_modal").modal("hide");
        $("#datasets_modal").addClass("d-none");
        this.router.navigate(["/zs/datasources", row.datasource_id]);
      } else if (
        eventEle.target.className == "fal fa-thumbs-down handDown" ||
        eventEle.target.className == "handDown fal fa-thumbs-down" ||
        eventEle.target.className === "fal handDown fa-thumbs-down"
      ) {
        this.entity_id = row.entity_id;
        this.showDownvoteModal = true;
        this.dataset_type_id = row.dataset_type_id;
        this.datasetId = row.dataset_id;
        this.dsName = row.name;
      } else if (eventEle.target.className == "fal fa-thumbs-up") {
        const upVotedata = {
          dataset_id: row.dataset_id,
          mapping_type: "manual",
          entity_id: this.entity_id,
          catalog_id: this.catalog_id,
          is_semantic_level_mapping: true,
        };
        const payload = {
          user_id: this.loggedInUserDetails["user_id"],
          tenant_id: this.loggedInUserDetails["tenant_id"],
          payload: upVotedata,
        };
        this.catalogSvc.saveVoteSemanticObject(payload, "up_vote").subscribe(
          (resp) => {
            $("#thumbs-up-" + args.row).removeClass("fal fa-thumbs-up");
            $("#thumbs-up-" + args.row).addClass("fas fa-thumbs-up");
            $("#thumbs-down-" + args.row).removeClass("fas fa-thumbs-down");
            $("#thumbs-down-" + args.row).addClass("fal fa-thumbs-down");
            this.datasets.forEach((element) => {
              if (row.dataset_id === element.dataset_id) {
                element.is_up_vote = true;
              }
            });
          },
          (error) => {
            this.messageSvc.sendMessage({
              message: error.error.message,
              type: "ERROR",
              hideboard: true,
            });
          }
        );
      }
    }
  }

  clearDatasets() {
    this.gridDatasets.dataView.setItems([]);
  }

  selectOptionDataSetModelList(dataSetId, limit = 20) {
    this.url =
      this.baseService.apiUrl +
      "/users/" +
      this.loggedInUserDetails["user_id"] +
      "/tenants/" +
      this.loggedInUserDetails["tenant_id"] +
      "/datasets/" +
      dataSetId +
      "/dataprofiler/report";
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // this.isSampleDsLoading = true;
    this.projectService
      .getSelectOptionModelListByDataSetId(
        this.loggedInUserDetails["user_id"],
        this.loggedInUserDetails["tenant_id"],
        dataSetId,
        this.page,
        limit
      )
      .subscribe(
        (data) => {
          this.modalRowData = data.currentpage;
          let rowKeys = [];
          if (data.currentpage.length) {
            let myVar: any = data.currentpage[0];
            for (var key in myVar) {
              rowKeys.push(key);
            }
          }
          this.modelColumnDef = [];
          rowKeys.forEach((column) => {
            this.modelColumnDef.push({
              displayname: column,
              physicalname: column,
              sortable: true,
              datatype: "String",
              filterable: true,
              minWidth: 150,
            });
          });

          this.modalRowData.forEach((element: any) => {
            element.id = Math.random();
          });
          if (this.modalGrid) {
            this.modalGrid.dataView.setItems(this.modalRowData);
            this.modalGrid.gridService.setSelectedRows([]);
          }
          this.isSampleDsLoading = false;
        },
        (err) => {
          this.isSampleDsLoading = false;
          this.modalRowData = [];
        }
      );
  }

  onModelGridCreation(grid) {
    this.modalGrid = grid;
  }

  closeModal() {
    $("#datasets_modal").removeClass("datasets_modal");
  }

  receiveFileName($event) {
    if ($event === AppGlobals.FILE_UPLOADED) {
      if (this.is_upload_complete) {
        this.cancelUpload();
      }
    } else {
      this.dataRes = $event;
      this.uploaded_files = this.dataRes.selectedDataset;
      this.is_upload_complete = this.dataRes.is_upload_complete;
      const total_files = this.dataRes.count;
    }
  }

  cancelUpload() {
    this.clearModal = Math.random();
    $("#uploadFileModal").modal("hide");
  }

  popupoutput(message) {
    this.showmodelpopup = message;
  }
  onConfirmDelete(event) {
    this.deleteRow();
  }
  clearText(event) {
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  onExportMappedDatasource() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/datasources?export=true`, '_self');
    this.catalogSvc
      .exportResult(
        `${environment.config.API_URL}/users/${this.loggedInUserDetails["user_id"]}/catalogs/${this.catalog_id}/datasources?export=true`
      )
      .subscribe(
        (resp) => {
          this.catalogSvc.exportCsvFiles(resp, "DiscoveredDataSourcesReport");
        },
        (error) => {
          this.messageSvc.sendMessage({
            message: error.error,
            type: "ERROR",
            hideboard: true,
          });
        }
      );
  }

  onExportMappedDataset() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/datasets?export=true`, '_self');
    this.catalogSvc
      .exportResult(
        `${environment.config.API_URL}/users/${this.loggedInUserDetails["user_id"]}/catalogs/${this.catalog_id}/datasets?export=true`
      )
      .subscribe(
        (resp) => {
          this.catalogSvc.exportCsvFiles(
            resp,
            "DataSetsReport_forSemanticObject"
          );
        },
        (error) => {
          this.messageSvc.sendMessage({
            message: error.error,
            type: "ERROR",
            hideboard: true,
          });
        }
      );
  }

  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal);
  }
  

  onScrollDs(parentClass) {
    if (!this.hasDatasetsScrolled && !this.noData && this.pageDs < this.total) {
      this.hasDatasetsScrolled = true;
      this.onNextDs();
    }
  }

  onNextDs(): void {
    this.pageDs++;
    this.getDatasets(this.catalog_id, this.pageDs, this.limit);
  }

  receiveTotalDataset($event) {
    this.subTotalDataset = this.zettaUtils.getSubTotal(
      this.grandTotalDatasets,
      $event
    );
  }

  openModalImport(modal) {
    $("#" + modal).modal("show");
  }

  closeModalPopup(event) {
    if (event) {
      $("#thumbs-up-" + this.selectedDatasetRowNo).removeClass(
        "fas fa-thumbs-up"
      );
      $("#thumbs-up-" + this.selectedDatasetRowNo).addClass("fal fa-thumbs-up");
      $("#thumbs-down-" + this.selectedDatasetRowNo).removeClass(
        "fal fa-thumbs-down"
      );
      $("#thumbs-down-" + this.selectedDatasetRowNo).addClass(
        "fas fa-thumbs-down"
      );
      this.datasets.forEach((element) => {
        if (this.selectedDatasetRow.dataset_id === element.dataset_id) {
          element.is_down_vote = true;
        }
      });
    }
    this.showDownvoteModal = false;
  }
}
