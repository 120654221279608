// actions-object-formatter.component.ts

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-actions-object-formatter',
  template: `
    <div [ngClass]="getClass()" [title]="value">
      {{ value }}
    </div>
  `,
})
export class ActionsObjectFormatterComponent implements ICellRendererAngularComp {
  value: any;

  agInit(params: any): void {
    this.value = params.value;
  }

  refresh(): boolean {
    return false;
  }

  getClass(): string {
    return this.value > 0 ? 'text-right w-100 blueLink pointer pd-right-10' : 'text-normal text-right w-100 pd-right-10';
  }
}
