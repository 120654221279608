import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-compression-ratio-cell-renderer',
  template: `<div class=" wd-5rem p-4 " [title]="params.value">{{ params.value }}</div>`
})
export class CompressionRatioFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
