import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetService } from '../../dataset.service';
import { MessageService } from '../../../message/message.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../../../../shared/zettaUtils';
import { ZsClContentService } from '../../../../../zettaclassify/zs-cl-content/zs-cl-content.service';
import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BaseService } from '../../../../services/base-service';
import { ChartType, ChartDataSets, ChartConfiguration } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { IDataset } from '../../dataset.model';
import _ from 'lodash';
declare var $: any;
import { ProjectService } from '../../../../../zettasense/content/project/project.service';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { ObservableService } from '../../../../../common/services/observable.service';
import { environment } from 'src/environments/environment';

const tagFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let resp = ``;
  if (dataContext.tags !== undefined) {
    if (dataContext.tags != null && dataContext.tags.length) {
      const tagarr = dataContext.tags.split(',');
      if (tagarr.length > 1) {
        tagarr.forEach(elem => {
          if (elem.length > 26) {
            elem = elem.substring(0, 26) + '...';
          }
          resp += `<button type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1 tag-zetta tag-light">
                            <span class="btn-label btn-label1" title="${elem}">${elem}</span>
                         </button>`;
        });
      } else {
        if (dataContext.tags.length > 26) {
          dataContext.tags = dataContext.tags.substring(0, 26) + '...';
        }
        resp += `<button type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1 tag-zetta tag-light">
                            <span class="btn-label btn-label1" title="${dataContext.tags}">${dataContext.tags}</span>
                         </button>`;
      }
    }
    resp += ``;
  }
  return resp;
};
const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="datasource-name-formatter text-truncate">
              <span title='${value}' class="pointer hyper-link text-truncate">${value}</span><br>
          </div>`;
};
const mappingColumnsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score*100) >= 75 ? 'HIGH' : (dataContext.score*100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score*100);
  let cssLevel = '';
  let confidenceLevel = '';
  let condLike = !dataContext.enable_up_vote ? 'fas' : 'fal';
  let condUnlike = !dataContext.enable_down_vote ? 'fas' : 'fal';
  cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
  let upCount = (dataContext.upvotes.length) > 0 ? dataContext.upvotes.length : `&nbsp;`;
  let downCount = (dataContext.downvotes.length) > 0 ? dataContext.downvotes.length : `&nbsp;`;

  confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
  <div class="porcent text-center" title='${score100}%'>${score100}%</div>
  <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  <span class="blueLink countUp mr-1 mrt-6">${upCount}</span>
  <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>
  <span class="blueLink countDown mr-1 mrt-6">${downCount}</span>
  `;
 return confidenceLevel; 
 };
const datasetTagFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.tags && dataContext.tags != null && columnDef.params.is_classify_app) {
    let resp ='';
    if (dataContext.tags !== undefined) {
      if (dataContext.tags != null && dataContext.tags.length) {
        const tagarr = dataContext.tags.split(',');
        if (tagarr.length > 1) {
          tagarr.forEach(elem => {
            if (elem.length > 26) {
              elem = elem.substring(0, 26) + '...';
            }
            resp += `
                    <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-zetta1 btn-light1 tag-zetta tag-light">
                                      <span class="btn-label btn-label1" title="${elem}">${elem}</span>                                      
                                   </button>`;
          });
        } else {
          if (dataContext.tags.length > 26) {
            dataContext.tags = dataContext.tags.substring(0, 26) + '...';
          }
          resp += `
                  <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-zetta1 btn-light1 tag-zetta tag-light">
                                      <span class="btn-label btn-label1" title="${dataContext.tags}">${dataContext.tags}</span>                                      
                                   </button>`;
        }
      }
      resp += ``;
    }
    return resp;
  }
  else {
    return;
  }
};

@Component({
  selector: 'zetta-dataset-attributes',
  templateUrl: './dataset-attributes.component.html',
  styleUrls: ['./dataset-attributes.component.scss'],  
})
export class DatasetAttributesComponent implements OnInit, OnDestroy {

  public tableSettings: object = { 'height': '100%', 'width': "99.10%", 'pageSize': 15, 'isNormalPagination': false };
  public tableSettings2: object = { 'height': '100%', 'width': "99.15%", 'pageSize': 15, 'isNormalPagination': false };
  public loggedInUserDetails;
  public datasetAttributes: any = [];
  public datasetAttributesTags: any = [];
  viewResultsTableColDef: any;
  datasetColumn: any = [];
  profile_report: any;
  data_sets: any;
  datasetColumnTags: any = {};
  gridOptions: any;
  gridDataOptions: any;
  dataset_id = 0;
  isLoading = true;
  isLoadingDatasets = true;
  tagsList = [];
  totalRows;
  grid: AngularGridInstance;
  attributeName = '';
  datasetTags: any = [];
  datasetsTableColDef: any;
  public gridDatasets: AngularGridInstance;
  noData = false;
  hasDatasetsScrolled = false;
  datasources: any = [];
  page = 1;
  limit = 100;
  catalog_id;
  object_id;
  selectedDsColumn;
  datasets: any;
  classificationTags: any = [];
  totalDatasets;
  dataset_type_id = 0;
  dataset_column_id;
  object_name;
  attribute_id;
  selectedTag = "";
  selectedTagOwn = "";
  searchClassificationTags: any = [];
  searchTags: any = [];
  notSureOption = "not_sure";
  default_tag = '';
  is_attr_loading = true;
  is_profile_loading = false;
  is_classify = false;
  is_primary_key = false;
  is_no_data = true;
  public barChartLabels: Label[] = ['', '', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  is_classify_app = false;
  public gridClassification: AngularGridInstance;
  classificationTableColDef: any;
  ds_classification: any = [];
  is_downvote_loading = true;
  showDownvoteModal = false;
  showObject = false;
  datasetId: any;
  datasetColumnId;
  mappingType;
  showBackdrop: boolean = false;
  showConceptColumn: boolean = true;
  hasScrolled = false;
  toatalPage=0;
  isFiltering=false;
  errored_job_id: string;
  callCount = 0;
  hasDatasetRegistor = false;
  isJobInQueue = false;
  isjobStatusLoading = true;
  total_dataset_profiling_row = '';
  public chartColors: any[] = [
    {
      backgroundColor: '#EFB049'
    }];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false,
        ticks: {
          display: true,
        }
      }],
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  public barChartData: ChartDataSets[];

  public lineChartUniqueID: any = '';
  public isDataReady = false;
  public alive = true;
  public isStepsCompleted = false;
  public currentJobStatusObj:any;
  public statisticsObj: object = null;
  public observableRef: any = null;
  public selectedProjectName = '';
  public userinfo;
  public currentURL;
  public errorMessage: string;
  public errorDetails: string;
  object_type_id: number;
  header_title="Data Set error details";
  public entitiesChartOptions: object = {
    'resizeRequired': true,
    'variable': 'Confidence',
    'category': 'Current Model',
    'isMoneyFormat': true,
    'labelPercentValue': 0,
    'labelPercentLabel': 'Current Model',
    'labelPercentLabel2': 'Confidence'
  };

  public entitiesDataSource = null;
  public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];
  public lineChartOptions: object = {
    'isMoneyFormat': false,
    'height': 133,
    'resizeRequired': true,
    'label': 'year',
    'value': 'value',
    'lineColor': '#00cb00',
    'circleColor': '#ff0000'
  };

  public lineChartDS = null;
  public notApplicable = false;
  public refreshRequired = false;
  public const_caption: string = "Executing models, this may take a few minutes";
  public caption: string = "Executing models, this may take a few minutes";
  jobCompleted:Boolean = false;
  concept_name;

  showFeedbackSummaryModel: Boolean=false;
  votedUsers: [];
  conceptName: string;
  datasourceName: string;
  datasetName: string;
  datasetColumnName: string;
  screenName: string;
  voteValue: string;
  jobStagingStatus: object = {
    stage_no: 0
  };
  showStep3Progress = false;
  showStep2Progress = false;
  showStep1Progress = false;
  showdatasetAttributes: boolean = false;
  is_classification_image: Boolean = false;

  constructor(private datasetService: DatasetService,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,
    public zettaUtils: ZettaUtils,
    private router: Router, private catalogSvc: ZsClContentService, private baseService: BaseService,
    private http: HttpClient,
    private _projectService: ProjectService,
    public observeSvc: ObservableService,
  ) {
    this.refreshRequired = sessionStorage.getItem('isPageReload') ? JSON.parse(sessionStorage.getItem('isPageReload')) : false;
    this.loggedInUserDetails = this.datasetService.getObjectFromSession('userInfo');
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    const apps = JSON.parse(sessionStorage.userInfo).apps;
    if (apps.length) {
      const cl_apps = apps.filter(item => item.name == 'Classify');
      if (cl_apps.length) {
        this.is_classify_app = true;
      }

    }
  }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.getDatasetAttributeList(this.page, this.limit);
    this.getDataset();
    
    this.viewResultsTableColDef = [{
      'displayname': 'Attribute',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }, {
      'displayname': 'Data Type',
      'physicalname': 'column_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-uppercase',
    }, {
      'displayname': 'Classification',
      'physicalname': 'tags',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetTagFormatter,
      'params': { 'is_classify_app': this.is_classify_app }
    },
    ];

    this.datasetsTableColDef = [{
      'displayname': 'Semantic Object',
      'physicalname': 'semantic_object_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
      'maxWidth': 90,
      'headerCssClass': 'text-left titleBreak pt-2 pb-0 toRight',
    }, {
      'displayname': 'Concept',
      'physicalname': 'logical_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'formatter': datasetNameFormattter,
      'minWidth': 70,
      'maxWidth': 90,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': mappingColumnsFormatter,
      'minWidth': 380,
      'maxWidth': 390,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      editable: true,
      autoEdit: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 30,
    };
    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
    this.searchClassificationTags = this.getSearchClassificationTags();

    const parent = this;
    parent.currentURL = parent.router.url;		
    this.object_type_id = +sessionStorage.getItem('dataset_type_id');
    const routeParams = parent.activatedRoute.snapshot.params;
    this.baseService.hasPageRefresh.subscribe(hasRegister => {
      if (hasRegister) {
        this.errorMessage = "";
        this.notApplicable = false;
        this.isStepsCompleted = false;
        this.alive = true;
        this.isjobStatusLoading = true;
        this.isJobInQueue = true;
        this.getEntityResolvedData(this.loggedInUserDetails.user_id, this.dataset_id);
        this.showStep2Progress = false;
        this.showStep3Progress = false;
        this.showStep1Progress = true;
      }
    });
    if (!this.refreshRequired) {
      parent.getEntityResolvedData(this.loggedInUserDetails.user_id, this.dataset_id);
    } else {
      sessionStorage.setItem('isPageReload', 'false');
      parent.getEntityResolvedData(this.loggedInUserDetails.user_id, this.dataset_id);
      this.jobCompleted = true;
    }
     
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
    this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
			this.alive = false;
      this.observableRef.next();
      this.isStepsCompleted = true;
			this.observableRef.unsubscribe();
		}
		this.messageSvc.clearMessage();
    sessionStorage.removeItem('isPageReload');
  }

  getDatasetAttributeList(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets/${this.dataset_id}/columns?pagination=true&pageno=1&pagesize=${limit}`;
    const getDataset: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString(),
      pageno: pageNo,
      perpage: limit
    };    
   
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
      this.isFiltering=true;
    }
    let serverSort = "";
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    this.datasetService.getPagedDatasetColumns(getDataset,serverFilter, serverSort).subscribe((data) => {
      if (data) {
        this.datasetAttributes = data['currentpage'];        
        this.totalRows = data.totalrecords;
        this.toatalPage = data.totalpages;
        let concatedRows = this.datasetAttributes;
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...concatedRows];
          this.datasetAttributes.forEach(element => {
            element.id = Math.random();
          });
          this.datasetAttributes = concatedRows;
          this.grid.dataView.setItems(this.datasetAttributes);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
        this.is_attr_loading = false;
        
      }
    }, err => {
      this.is_attr_loading = false;
      this.hasScrolled = false;
      this.datasetAttributes = [];
    });
  }

  getDonutParseData(data) {
    const dataSet = [];
    data.forEach((element) => {
      if (element['name'] === 'high_confidence') {
        dataSet.push({ 'Current Model': 'High', 'Confidence': element['records'] });
      } else if (element['name'] === 'medium_confidence') {
        dataSet.push({ 'Current Model': 'Medium', 'Confidence': element['records'] });
      } else if (element['name'] === 'low_confidence') {
        dataSet.push({ 'Current Model': 'Low', 'Confidence': element['records'] });
      }
    });
    return dataSet;
  }
  
  getEntityResolvedData(userId, datasetId) {    
    const parent = this;
    parent.zettaUtils.isProcessRunning = true;
    // parent.observableRef = Observable.timer(0, AppGlobals.ENTITIES_RESOLVED_JOB_INTERVEL)
    parent.observableRef = Observable.timer(0, 20000)
    .takeWhile(() => parent.alive)
    .subscribe(() => {
      if (parent.object_type_id) { 
            parent._projectService.getLatestJob(userId, datasetId,parent.object_type_id).subscribe(resp=>{
              if(resp) {
                const job_id =resp.loader.job_id;
                this.isJobInQueue = resp.in_queue;
                this.isjobStatusLoading = false;
                if(resp.in_queue && !this.jobStagingStatus && !this.jobStagingStatus['stage_no']) {               
                  this.errorMessage = null;
                  this.isStepsCompleted = true;
                  this.notApplicable = false;
                  this.caption =  this.const_caption;
                  if (parent.currentJobStatusObj && parent.currentJobStatusObj.steps_completed) {
                    parent.currentJobStatusObj.steps_completed = 0;
                  }              
                }                
                if (this.callCount == 0) {
                  this.errored_job_id = job_id;
                }
                if (this.object_type_id && job_id && !this.isJobInQueue) {    
              parent._projectService.getCurrentActiveJobsById(userId, datasetId,this.object_type_id,job_id)
                .subscribe(response=>{
                  if(response) {
                  parent.jobStagingStatus = response;
                this.zettaUtils.isFileNotFound = false;
                if (Object.values(response).length === 0) {
                  parent.zettaUtils.isProcessRunning = false;
                  parent.notApplicable = true;
                  parent.entitiesDataSource = [];
                  return;
                }
                response['steps_completed'] = response['steps_completed'] || 0;
                response['total_steps'] = response['total_steps'] || 0;
                parent.currentJobStatusObj = response;
                if(response.caption) {
                  this.caption = response['caption'];
                }
                if (!response['is_errored']) {
                  parent.errorDetails = "";
                  parent.errorMessage = "";
                  this.caption = response.caption || this.const_caption;
                }
                if (response && response['steps_completed'] >= AppGlobals.STEP_1 && !response.is_errored) {
                  parent.errorMessage = "";
                  parent.notApplicable = true;
                  parent.isStepsCompleted = false;
                  parent.showdatasetAttributes = true;
                  parent.page = 1;
                  if(!parent.datasetAttributes.length && !response['is_errored']) {
                    parent.getDatasetAttributeList(parent.page, parent.limit);
                    parent.showdatasetAttributes = true;
                  }
                  parent.jobCompleted = true; 
                } else if(response && response['stage_no'] >= AppGlobals.STEP_1 && response.is_errored) {
                  parent.notApplicable = true;
                  parent.isStepsCompleted = false;
    
                }
                if(response.stage_no <= AppGlobals.STEP_1 && !response['job_completed']) {
                  parent.showStep1Progress = true;
                  parent.isJobInQueue = false;
                  this.isStepsCompleted = true;
                  this.notApplicable = false;
                } else if ((response.stage_no == AppGlobals.STEP_3 || response.stage_no == AppGlobals.STEP_2) && !response['job_completed']) {
                  parent.showStep2Progress = true;
                  parent.showStep1Progress = false;
                  parent.isJobInQueue = false;
                } else if(response.stage_no == AppGlobals.STEP_4 && !response['job_completed']) {
                  parent.showStep3Progress = true;
                  parent.showStep1Progress = false;
                  parent.isJobInQueue = false;
                } else {
                  parent.showStep1Progress = false;
                  parent.showStep2Progress = false;
                  parent.showStep3Progress = false;
                }
                if (response['job_completed']) {
                  parent.zettaUtils.isProcessRunning = false;
                  sessionStorage.setItem('isPageReload', 'true');
                  this.jobCompleted = true;  
                  this.catalogSvc.isJobCompleted.next(true);
                  parent.showStep1Progress = false;
                  parent.showStep2Progress = false;
                  parent.showStep3Progress = false;
                  // parent.refresh();
                } else if (response['is_errored'] && response['type'] !== 'merger') {
                  parent.isStepsCompleted = true;
                  parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
                  parent.errorDetails = response['error_details'];
                  parent.zettaUtils.isProcessRunning = false;
                  if (job_id != this.errored_job_id && this.hasDatasetRegistor) {
                    parent.alive = false;
                    parent.observableRef.unsubscribe();
                  } 
                  this.errored_job_id = job_id; 
                  this.callCount++;  
                  if (!this.hasDatasetRegistor) {
                  parent.alive = false;
                  parent.observableRef.unsubscribe();
                  }
                } else if (response['type'] !== 'merger' && !parent.currentJobStatusObj['job_completed']) {
                  parent.isStepsCompleted = true;
                } 
                parent.observeSvc.setTrackStatus = parent.currentJobStatusObj;
                parent.observeSvc.getTrackStatus.subscribe(data => parent.currentJobStatusObj = data);
                parent.currentJobStatusObj['algoid'] = AppGlobals.getRunModelAlgo(0);
                if(response.steps_completed == response.total_steps){
                    this.jobCompleted = true;
                    this.showStep2Progress = false;
                    this.showStep3Progress = false;
                }
              }
              },
                error => {
                  if (error.status === 404) {
                    this.zettaUtils.isFileNotFound = true;
                        this.jobCompleted = true;
                  }
                });
              }  else {
                parent.showStep1Progress = true;
                parent.notApplicable = false;
                parent.isStepsCompleted = true;
              }
              }
            }, err => {
              this.isjobStatusLoading = false;
            });
             
          }

      }, err => {
    });
      
  }

	@HostListener('window:unload', ['$event'])
	unloadHandler(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

  cancelJOB() {
    this._projectService.cancelActiveJob2(this.loggedInUserDetails.user_id, this.object_type_id, this.activatedRoute.snapshot.params.id).subscribe(response => {
      this.errorMessage = "";
      this.notApplicable = false;
      this.isStepsCompleted = false;
      this.alive = true;
      this.getEntityResolvedData(this.loggedInUserDetails.user_id, this.activatedRoute.snapshot.params.id);
    });
  }

  prepareLineChartData(data) {
    const dataSet = [];
    data.forEach((element) => {
      const d = new Date(element['created_ts']);
      dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
    });
    return dataSet;
  }

  getDatasetProfile(dataset_column_id) {
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString()
    }
    this.datasetService.getDatasetProfileResult(Datasets, dataset_column_id).subscribe(resp => {
      if (resp) {
        this.is_profile_loading = false;
        if (!resp.profile_report) {
          this.is_no_data = false;
        }
        this.profile_report = resp.profile_report;
        let chartArry: any[] = [];
        if (this.profile_report) {
          this.is_primary_key = this.profile_report.primary_key_candidate;
          this.profile_report.most_frequent.forEach(el => {
            chartArry.push(el.distribution);
          });
          this.barChartData = [{ data: chartArry, label: 'Most Frequent Data' }]
        }
      }

      this.is_profile_loading = false;
      this.datasetTags = [];
      if (resp.tags != null) {
        this.datasetTags = resp.tags_score;
      }
    }, err => { this.is_profile_loading = false; });
  }
  getDataset() {
    const Datasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      dataset_id: this.dataset_id.toString()
    }
    
    this.datasetService.getDataset(Datasets).subscribe((responseList) => {
      if (responseList) {
        this.data_sets = responseList;
        let total_records = `${this.zettaUtils.commarization(this.data_sets.total_records, 1)} Rows`;
        this.total_dataset_profiling_row = total_records;
        if (total_records.length >= 9) {
          this.total_dataset_profiling_row = `${this.total_dataset_profiling_row.substring(0, 7)}...`;
        }
        this.datasourceName = responseList.datasource_name;
        this.datasetName = responseList.name;
      }
      this.datasetTags = [];
      if (responseList.tags != null) {
        this.datasetTags = responseList.tags_score;
      }
    }, err => { this.is_profile_loading = false; });
  }


  getClassificationTags(datasetColumnId) {
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.tableSettings2['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/columns/${datasetColumnId}/tags?ignore_system_attributes=true`;   
   
    this.datasetService.getDatasetColumnsTags(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, datasetColumnId,serverFilter,sortfilter,true).subscribe((data) => {
      this.datasets = data;
      this.is_classify = false;
      if (this.datasets.length === 0) {
        this.noData = true;
      }
      this.datasets.forEach(element => {
        element.id = Math.random();
        element.level = (element.score * 100) >= 75 ? 'HIGH' : (element.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
        element.score100 = Math.round(element.score * 100);
        element.checked = element.level === 'HIGH' ? true : false;
        // if(element.is_up_vote==true)
        // {
        //   $('#thumbs-up-' + element.id).removeClass('fal fa-thumbs-up');
        //   $('#thumbs-up-' + element.id).addClass('fas fa-thumbs-up');
        // }
        
      });
      this.datasets.sort(((a, b) => b.score100 - a.score100));
      this.totalDatasets = this.datasets.length;
      this.isLoadingDatasets = false;
      let concatedRows = this.datasets;
      if (this.gridDatasets) {
        // concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
        // this.datasets = concatedRows;
        this.gridDatasets.dataView.setItems(this.datasets);
        this.gridDatasets.gridService.setSelectedRows([]);
        this.hasDatasetsScrolled = false;
      }
    }, err => {
      this.datasetColumnTags = [];
      this.datasets = [];
      this.is_classify = false;
    });
  }

  getDatasetColumnsTags(datasetColumnId) {
    this.datasetService.getDatasetColumnsTags(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, datasetColumnId,null,null,false).subscribe((data) => {
      this.datasetColumnTags = data;
      this.datasetColumnTags.forEach(element => {
        element.level = (element.score * 100) >= 75 ? 'HIGH' : (element.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
        element.score100 = Math.round(element.score * 100);
        // element.tagsArr = element.tags.split(',');
        element.checked = element.level === 'HIGH' ? true : false;
      });
      this.is_attr_loading = false;
      // this.tagsList = this.datasetColumnTags.tags.split(',');
    }, err => {
      this.datasetColumnTags = [];
    });
  }
  onSearchClassificationTags(event: any) {
    this.searchClassificationTags = this.getSearchClassificationTags(event.term);
  }
  onChangeClassificationTags(event) {
    $("#tagOwn").prop("checked", true);
    this.selectedTag = event !== undefined ? event.attribute_id : "";
    if (event !== undefined) {
      this.selectedTag = event.attribute_id;
    } else {
      this.selectedTag = "";
      this.searchClassificationTags = this.getSearchClassificationTags();
    }
  }

  onFocusClassificationTags($event: Event) {
    $("#tagOwn").prop("checked", true);
  }
  getSearchClassificationTags(term: string = null) {
    if (term) {
      this.catalogSvc.getSearchClassificationTags(term).subscribe((data) => {
        this.searchTags = data;
      }, err => { });
      return this.http.get<any>(`${this.baseService.apiUrl}/users/${this.loggedInUserDetails['user_id']}/attributes?logical_name=${term}`);
    } else {
      return of([]);
    }
  }
  closeClassificationTags() {
    $('#edit_Classification_tag').modal('hide');
    $('#mapped_columns_modal').removeClass('datasets_modal');
    $('#datasets_modal').removeClass('datasets_modal');

    this.selectedTag = "";
    this.selectedTagOwn = "";
    this.searchClassificationTags = this.getSearchClassificationTags();
  }
  saveClassificationTags() {

    if (this.selectedTag !== "" && this.selectedTag !== undefined) {
      if (this.selectedTag === 'not_sure') {
        const payload: any = {
          "dataset_id": +this.dataset_id,
          "dataset_column_id": +this.dataset_column_id,
          "removing_attribute_id": +this.attribute_id,
          "mapping_type": 'manual',
          "entity_id": +this.object_id,
          "catalog_id": +this.catalog_id
        }
        this.downVote(payload);
      } else {
        this.selectedTag = this.selectedTag + '';
        this.selectedTag = this.selectedTag.split('_')[0];
        const payload: any = {
          "dataset_id": +this.dataset_id,
          "dataset_column_id": +this.dataset_column_id,
          "removing_attribute_id": +this.attribute_id,
          "attribute_id": + this.selectedTag,
          "mapping_type": 'manual',
          "entity_id": +this.object_id,
          "catalog_id": +this.catalog_id
        }
        this.downVote(payload);
      }

    }
    this.closeClassificationTags();
  }

  onChangeMaping(tag = '') {

    this.selectedTag = tag;
  }
  gridCreated(grid) {
    const parent = this;
    parent.grid = grid;
  }
  onDatasetsGridCreation(grid) {
    this.gridDatasets = grid;
  }
  getClassificationEditTags() {
    this.classificationTags = this.datasets;
    // this.isLoadingclassificationTags = false;
    this.classificationTags.forEach(element => {
      element.score100 = +(element.score * 100).toFixed();
      element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
      element.dataset_id = this.dataset_id;
      element.column_name = this.object_name;// row.column_name;
    });
    this.classificationTags.sort(((a, b) => b.score100 - a.score100));

  }



  closeModalPopup(event) {
    if (event) {
      $('#thumbs-down-' + this.selectedDsColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedDsColumn).addClass('fas fa-thumbs-down');
      if (this.gridDatasets) {
        this.gridDatasets.dataView.setItems([]);
        this.isLoadingDatasets = true;
        this.getClassificationTags(this.dataset_column_id);
      }
    }
    this.showDownvoteModal = false;
  }

  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);

    const metadata = this.gridDatasets.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    this.selectedDsColumn = args.row;
    this.object_name = row.semantic_object_name;
    this.attribute_id = row.attribute_id;
    this.catalog_id = row.catalog_id;
    this.concept_name = row.logical_name

    if (eventEle.target.className == 'fal fa-thumbs-down handDown'||eventEle.target.className == 'handDown fal fa-thumbs-down') {
      // this.getClassificationEditTags();
      // $('#datasets_modal').addClass('datasets_modal');         
      // $('#edit_Classification_tag').modal('show');         
      // $('#edit_Classification_tag').removeClass('d-none');
      // $("#tagKeep").prop("checked", true);

      this.datasetId = row.dataset_id;
      this.datasetColumnId = row.dataset_column_id;
      this.mappingType = row.mapping_type === undefined ? 'manual' : row.mapping_type;
      this.showDownvoteModal = true;      
    }
    this.dataset_column_id = row.dataset_column_id;
    this.object_id = row.entity_id;
    const payload: any = {
      "dataset_id": +this.dataset_id,
      "dataset_column_id": + row.dataset_column_id,
      "attribute_id": +row.attribute_id,
      "mapping_type": row.mapping_type === undefined ? 'manual' : row.mapping_type,
      "entity_id": +row.entity_id,
      "catalog_id": +row.catalog_id
    }

    if (eventEle.target.className == 'fal fa-thumbs-up') {
      $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
      $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
      $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
      $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
      this.upVote(payload);
    } else if (eventEle.target.className == 'fal fa-thumbs-down handDown'
    ||eventEle.target.className == 'handDown fal fa-thumbs-down'||
    eventEle.target.className == 'handDown fas fa-thumbs-down'||
    eventEle.target.className =='fal handDown fa-thumbs-down') {
      $('#thumbs-up-' + args.row).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + args.row).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + args.row).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
  
    
      

      //this.downVote(payload);
    } else if (eventEle.target.className.includes('countUp')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.upvotes
        this.conceptName = row.logical_name;
        this.object_id = row.entity_id;
        this.datasetColumnId = row.dataset_column_id;
        this.screenName = 'mappedColumn';
        this.voteValue = 'upVote'
    } else if (eventEle.target.className.includes('countDown')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.downvotes
        this.conceptName = row.logical_name;
        this.object_id = row.entity_id;
        this.datasetColumnId = row.dataset_column_id;
        this.screenName = 'mappedColumn';
        this.voteValue = 'downVote';
    }

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {

      } else if (eventEle.target.title === row.datasource_name) {
        $('#datasets_modal').modal('hide');
        $('#datasets_modal').addClass('d-none');
        this.router.navigate(['/zs/datasources', row.datasource_id]);
      }
    }

  }
  upVote(payload) {
    const getRow: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      payload: payload
    };
    this.catalogSvc.upVote(getRow).subscribe((data) => {
      if (this.gridDatasets) {
        this.gridDatasets.dataView.setItems([]);
        this.isLoadingDatasets = true;
        this.getClassificationTags(this.dataset_column_id);
      }
    }, err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
     });
  }

  downVote(payload) {
    const getRow: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      payload: payload
    };
    this.catalogSvc.downVote(getRow).subscribe((data) => {
    }, err => { });
  }

  onRunModelDs() {
    //Call the Run Model API
    this.catalogSvc.runModel303().subscribe((res) => {
    this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Dataset', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:'zs/datasets/'+this.dataset_id });
    }, (err) => {
    this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });
     });
  }
  onCellClick(eventData): void {
    const args = eventData.args;
    const event = eventData.eventData;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    this.attributeName = row.column;
    this.dataset_type_id = row.column_type_id;
    this.is_classification_image = true;
    const existingClass = $(".left-grid-row-selected");
    if(existingClass) {
      existingClass.removeClass("left-grid-row-selected");
    }

    event.target.parentElement.classList.add("left-grid-row-selected");
    if(!this.showStep2Progress) {
      if (row.column_type_id) {
        this.is_classify = true;
        this.getClassificationTags(row.dataset_column_id);
        this.is_profile_loading = true;
        this.profile_report = false;
        this.getDatasetProfile(row.dataset_column_id);
        if (this.gridDatasets) {
          this.datasets = [];
          this.gridDatasets.dataView.setItems(this.datasets);
          this.gridDatasets.gridService.setSelectedRows([]);
        }
        this.datasetColumnName = row.column;
      }
    }
  }
  onRunModel() {
    const dataset_id = [this.dataset_id];
    this.datasetService.runModel300Stage1(dataset_id).subscribe((result) => {
      if(result) {
        this.hasDatasetRegistor = true;
        const messageInfo = { message: '', type: 'Info', hideInfo: true, activeJobs: 'Dataset', messageHeader: AppGlobals.DATASET_MSG_HEADER, messageText: AppGlobals.DATASET_MSG_TEXT, path: 'zs/datasets/' + this.dataset_id, queryParams: { showBreadcrumb: true } };
        this.messageSvc.sendMessage(messageInfo);
      }
    }, error => {
      this.messageSvc.sendMessage({ message: 'Algo 300 call has failed', type: 'ERROR', hideboard: true });
    });
  }
  onScroll(parentClass) {
    if(sessionStorage.getItem("serverfilter")){
      this.isFiltering = true;
      this.noData = false;
    } else {
      if(this.isFiltering){
        this.page = 1;
        this.noData = false;
      }
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      if(this.page < this.toatalPage){
        this.hasScrolled = true;
        this.onNext();
      }
    }
  }
  onNext(): void {
    
    this.page++;
    this.getDatasetAttributeList(this.page, this.limit);
  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }
}
