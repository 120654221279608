import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'zetta-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number;
  @Input() count: number;
  @Input() perPage = 2;
  @Input() loading: boolean;
  @Input() pagesToShow = 5;

  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<number>();
  private _totalPages = [];
  private pageNum = 0;

  constructor() { }

  ngOnInit() {
    
  }

  getMin(): number {
    return ((this.perPage * this.page) - this.perPage) + 1;
  }

  getMax(): number {
    let max = this.perPage * this.page;
    if (max > this.count) {
      max = this.count+1;
    }
    return max;
  }

  onPage(n: number): void {
    this.goPage.emit(n);
    this.pageNum = n;
  }

  onPrev(): void {
    this.goPrev.emit(true);
  }

  onNext(next: boolean): void {
    this.goNext.emit(next);
  }

  totalPages(): number {
    return Math.ceil(this.count / this.perPage) || 0;
  }

  lastPage(): boolean {
    return this.perPage * this.page >= this.count;
  }

  onPageChange(args): void {
    this._totalPages = [];
    this.goPage.emit(args.target.value);
  }

  getPages(): number[] {
    const c = Math.ceil(this.count / this.perPage);
    const p = this.page || 1;
    const pagesToShow = this.pagesToShow || 9;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }

  generatePageNumbers(): number[] {
    this._totalPages = [];
    for(let i = 1; i <= this.totalPages(); i++) {
      this._totalPages.push(i);
    }
    if(this.pageNum === 0){
      this.pageNum = this._totalPages.length;
    }
    return this._totalPages;
  }

}
