import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from '../../services/base-service';
import { ContentService } from 'src/app/zettasense/content/content.service';


@Injectable({
  providedIn: 'root'
})
export class FsDownloadGraphService  {

  private graphDataToDownload: any = [];
  downloadGraphDataBS: BehaviorSubject<any> = new BehaviorSubject<any>({});
  
  set setDownloadGraphData(objectDetails) {
    this.downloadGraphDataBS.next(objectDetails);
  }

  get getDownloadGraphDataAsObservable() {
    return this.downloadGraphDataBS.asObservable();
  }

  getGraphData() {
    return this.graphDataToDownload;
  }

  updateGraphDataState(newGraphData) {
    let objectIdIndex = this.graphDataToDownload.findIndex((data) => {
      return data.objectId === newGraphData.objectId && data.objectTypeId === newGraphData.objectTypeId;
    })
    if (objectIdIndex <= -1) {
      newGraphData['download_triggered'] = false;
      this.graphDataToDownload.push(newGraphData);
    }
  }  

  checkIfDownloadTriggeredForObjectId(objectId, objectTypeId) {
    let objectIdIndex = this.graphDataToDownload.findIndex((objectData) => {
      return objectData.objectId === objectId && objectData.objectTypeId === objectTypeId;
    })
  
    if (objectIdIndex > -1) {
      return this.graphDataToDownload[objectIdIndex]['download_triggered'];
    }
    return false;
  }

  updateDownloadTriggeredForObjectId(objectId, objectTypeId) {
    let objectIdIndex = this.graphDataToDownload.findIndex((objectData) => {
      return objectData.objectId === objectId && objectData.objectTypeId === objectTypeId;
    })
    if (objectIdIndex > -1) {
      this.graphDataToDownload[objectIdIndex]['download_triggered'] = true;
    }
  }

  resetGraphData() {
    this.graphDataToDownload = [];
  }

  validateIfGraphDataExist(objectId, objectTypeId) {
    let objectIdIndex = this.graphDataToDownload.findIndex((data) => {
      return data.objectId === objectId && data.objectTypeId === objectTypeId;
    })
    return objectIdIndex > -1 ? this.graphDataToDownload[objectIdIndex]: null;
  }

  removeObjectDataFromGraphData(objectId, objectTypeId) {
    let objectIdIndex = this.graphDataToDownload.findIndex((data) => {
      return data.objectId === objectId && data.objectTypeId === objectTypeId;
    })
    if (objectIdIndex > -1) {
      this.graphDataToDownload.splice(objectIdIndex, 1);
    }
  }
}
