import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { IDataset } from './dataset.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AppGlobals } from '../../shared/app.globals';

@Injectable({
  providedIn: 'root'
})
export class DatasetService extends BaseService {
  public isFileUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { super(); }
  public engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  public getDataset(getData: IDataset): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}/datasets/${getData.dataset_id}`);
  }

  async getDatasetAsync(getData: IDataset) {
    return await this.http.get(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}/datasets/${getData.dataset_id}`).pipe(map(this.extractData)).toPromise();
  }

  public getTenantInfo(userId: string, tenantid: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid);
  }

  public getDataSources(datasource: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/datasets`);
  }

  public getDataSourcesByDataset(datasource: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/datasets?datasets=true`);
  }

  public getDataSourcesPg(datasource: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/datasets?pageno=1&pagesize=1000`);
  }

  public getDataSetNew(datasource: any): Observable<any> {
    let dirQuery = '';
    let ignoreType = 'ignoreType=true';
    let paginateQuery = `paginate=true&pageno=${datasource.pageNo}&pagesize=${datasource.limit}`;
    if (datasource.datasource_type === 'Cloud Object Storage' || datasource.datasource_type === 'Hadoop') {
      datasource.dir = datasource.dir ? datasource.dir : `data/${datasource.tenant_id}/raw/`;
      const lastChar = datasource.dir.slice(-1);
      if (lastChar !== '/') {
        datasource.dir = `${datasource.dir}/`;
      }
    }

    // Temp fix for tree dir view for hadoop
    if (datasource.datasource_type_id === AppGlobals.HADOOP_TYPE) {
      datasource.dir = null;
      ignoreType = 'ignoreType=false';
    }
    
    dirQuery = datasource.dir ? `?dir=${datasource.dir}&${paginateQuery}&${ignoreType}` : `?${ignoreType}&${paginateQuery}`;   
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/contents${dirQuery}`);
  }

  public getDatasets(datasets: any, serverfilter = '', sortfilter = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets?paginate=true&pageno=${datasets.pageno}&pagesize=${datasets.perpage}${serverfilter}${sortfilter}`);
  }

  public getAttributeDatasets(datasets: any, serverfilter = '', sortfilter = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets?paginate=true&checkAtrributes=true&pageno=${datasets.pageno}&pagesize=${datasets.perpage}${serverfilter}${sortfilter}`);
  }

  public getDatasetsHasMappings(datasets: any, mappingValue, attributeId, serverfilter = '', sortfilter = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets?paginate=true&pageno=${datasets.pageno}&pagesize=${datasets.perpage}${serverfilter}${sortfilter}&has_mapping=${mappingValue}&attribute_id=${attributeId}`);
  }

  public getAllDatasets(datasets: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets`);
  }

  public getDatasetsNoPaginate(datasets: IDataset): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets?paginate=false&pageno=${datasets.pageno}&pagesize=${datasets.perpage}`);
  }

  // public getDatasets(datasets: IDataset): Observable<any> {
  //   if (datasets.pageno && datasets.perpage) {
  //       return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets?pageno=${datasets.pageno}&pagesize=${datasets.perpage}`);
  //   } else {
  //       return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets`);
  //   }
  // }

  public createDataset(postData: IDataset): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets`, postData.payload);
  }

  public editDataset(editData: IDataset): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${editData.user_id}/tenants/${editData.tenant_id}/datasets/${editData.dataset_id}`, editData.payload);
  }

  public deleteDataset(deleteData): Observable<any> {
    return this.http.delete(`${this.apiUrl}/users/${deleteData.user_id}/tenants/${deleteData.tenant_id}/datasets/${deleteData.dataset_id}`);
  }

  public uploadDataSourceFile(params: IDataset) {
    return `${this.apiUrl}/users/${params.user_id}/tenants/${params.tenant_id}/datasources/${params.datasource_id}/data/operations/upload`;
  }

  public getDatasetColumns(datasets: IDataset, id, isPaginationRequired = false): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets/${id}/columns?pagination=${isPaginationRequired}`);
  }

  public getMultipleDatasetColumns(datasets: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/columns?filter_by_dataset=true&dataset_ids=${datasets.dataset_ids}`);
  }

  public getDatasetColumn(userId, tenantId, datasetId, columnId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/columns/${columnId}`);
  }

  // public getDatasetColumnsModal(userId, tenantId, datasetId): Observable<any> { 
  //   return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}`);
  // }

  public getDatasetColumnsModal(userId, tenantId, datasetId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/semantics`);
  }
  public getPagedDatasetColumns(datasets: IDataset, serverFilter = '', serverSort = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets/${datasets.dataset_id}/columns?pagination=true&pageno=${datasets.pageno}&pagesize=${datasets.perpage}${serverFilter}${serverSort}`);
  }
  public getAllDatasetColumns(datasets, datasetId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasets.user_id}/tenants/${datasets.tenant_id}/datasets/${datasetId}/columns?pagination=false`);
  }
  public getDatasetTags(userId, tenantId, datasetId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/tags`);
  }

  public getDatasetColumnsTags(userId, tenantId, datasetId, columnId, serverFilter = "", sortFilter = "",ignoreSystemAttributes): Observable<any> {
    let ignoreSystemAttributeQueryParams;
    if (serverFilter || sortFilter) {
      ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '&ignore_system_attributes=true' : '' ;
      return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/columns/${columnId}/tags?${serverFilter}${sortFilter}${ignoreSystemAttributeQueryParams}`);
    }
    else {
       ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '?ignore_system_attributes=true' : '' ;
      return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/columns/${columnId}/tags${ignoreSystemAttributeQueryParams}`);
    }

  }

  public getDatasetRecords(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/records/${data.record_id}?projectId=${data.project_id}&projectType=classify`);
  }

  public validateColumn(postData: IDataset): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/${postData.dataset_id}/columns/validate`,
      postData.payload);
  }

  public createColumn(postData: IDataset): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/${postData.dataset_id}/columns`,
      postData.payload);
  }

  public editColumn(postData: IDataset): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/${postData.dataset_id}/columns`,
      postData.payload);
  }

  public deleteColumn(postData: IDataset): Observable<any> {
    return this.http.delete(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/${postData.dataset_id}/columns/${postData.column_id}`);
  }

  public getActivityHistory(param: IDataset): Observable<any> {
    // return this.http.get(`${this.apiUrl}/users/${param.user_id}/tenants/${param.tenant_id}/datasets/${param.dataset_id}/activities`);
    return this.http.get(`${this.apiUrl}/users/${param.user_id}/activities?paginate=true&pageno=${param.pageno}&pagesize=${param.perpage}`);
  }

  public getDataSetTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DatasetTypes`);
  }

  public getConfidenceLevelTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=ConfidenceLevel`);
  }

  public getUsers(userId: string, groupid: string) {
    return this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/users`);
  }

  public getUsersTenant(userId: string, tenantid: string) {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/users`);
  }

  public getUsersGroup(userId: string, tenantid: string) {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/groups`);
  }

  public getDatasetAndUsersGroupData(data) {
    let dataset = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}`);
    let userGroup = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/groups`);
    return forkJoin([dataset, userGroup])
        .pipe(map(([datasetData, userGroupData]) => ({datasetData, userGroupData})));
  }

  public getUser(userId: string) {
    return this.http.get(`${this.apiUrl}/users/` + userId);
  }

  public updateEntitlements(postData: IDataset): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/${postData.dataset_id}/userentitlements`, postData.payload);
  }

  public runModel(datasetId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/algos/202`, { 'dataset_id': datasetId });
  }

  public runModel203(datasetId: any[]): Observable<any> {
    let api_url = `${this.apiUrl}/algos/203`;
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/403`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId });
  }
  public runModel300(datasetId: any[]): Observable<any> {
    let api_url = '';
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/404`;
    }
    if (this.engine_type_id == 451) {
      api_url = `${this.apiUrl}/algos/300`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId });
  }
  public runModel210(datasetId: string): Observable<any> {
    let api_url = `${this.apiUrl}/algos/210`;
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/402`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId });
  }
  public runModel416(classifyProjectId: string, userId): Observable<any> {
    return this.http.post(`${this.apiUrl}/algos/416`, { 'classify_project_id': classifyProjectId, 'loggedInUserId': userId });
  }


  public getCurrentActiveJobs(userId, tenantId, object_type_id, datasetId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/track?object_type_id=${object_type_id}&object_id=${datasetId}`);
  }

  public getDatasetsJobs(userId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/jobs?job_type=dataset`);
  }

  public getJobsByDatasetId(userId: string, datasetId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/jobs/${datasetId}?job_type=dataset`);
  }

  public getSparkjob(userId: string, datasetId: string, jobId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/jobs/${datasetId}/${jobId}`);
  }

  public cancelJob(userId, tenantId, datasetId): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/jobs/cancel`);
  }

  public getActiveUsersbyDatasetId(dataset): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${dataset.user_id}/tenants/${dataset.tenant_id}/datasets/${dataset.dataset_id}/users`);
  }

  public getDataSetNewFiles(datasource: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/contents?dir=data/${datasource.tenant_id}/raw/${datasource.dir_name}/`);
  }
  public getDataSetSubFiles(datasource: any): Observable<any> {
    let dirName = datasource.dir_name;
    let queryParams = "";
    if (datasource.dir_name) {
      const lastTwoChar = dirName.slice(-1);
      if (lastTwoChar !== '/') {
        dirName = `${dirName}/`;
      }
    }
    queryParams = `?dir=${dirName}`;
    return this.http.get(`${this.apiUrl}/users/${datasource.user_id}/tenants/${datasource.tenant_id}/datasources/${datasource.datasourceId}/contents${queryParams}`);
  }
  public updateuserEntitlements(postData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${postData.user_id}/tenants/${postData.tenant_id}/datasets/entitlements`, postData.payload);
  }
  public autoDetectPost(user_id, tenant_id, payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/adhocjobs`, payload);
  }
  public algo210(payload) {
    let api_url = '';
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/402`;
    }
    if (this.engine_type_id == 451) {
      api_url = `${this.apiUrl}/algos/210`;
    }
    return this.http.post(api_url, payload);
  }
  public getAdhocjobsById(user_id, tenant_id, job_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/adhocjobs/${job_id}`);
  }
  public getFileTypeAndDelimeters(type): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups/?group=${type}`);
  }
  public saveBulkData(user_id, tenant_id, payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/bulk`, payload);
  }
  public getTenentGroup(user_id, tenant_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/groups?defaul_group=true`);
  }
  public saveDatasourceEntitlement(user_id, tenant_id, datasourceid, payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasources/${datasourceid}/contents?ignoreContentDelete=true`, payload);
  }
  public getScheduleRefreshDetails(user_id, tenant_id, datasets_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${datasets_id}/profiler`);
  }
  public getDatasetProfileResult(getData: IDataset, dataset_column_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${getData.user_id}/tenants/${getData.tenant_id}/datasets/${getData.dataset_id}/columns/${dataset_column_id}`);
  }
  public runModel300Stage3(datasetId: any[]): Observable<any> {
    let api_url = `${this.apiUrl}/algos/300`;
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/404`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId, stage: 'stage3' });
  }
  public runModel300Stage2(datasetId: any[]): Observable<any> {
    let api_url = `${this.apiUrl}/algos/300`;
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/404`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId, stage: 'stage2' });
  }
  public runModel300Stage1(datasetId: any[]): Observable<any> {
    let api_url = `${this.apiUrl}/algos/300`;
    if (this.engine_type_id == 452) {
      api_url = `${this.apiUrl}/algos/404`;
    }
    return this.http.post(api_url, { 'dataset_id': datasetId, stage: 'stage1' });
  }

  public getDataQualityDimensions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityDimensions`)
  }

  public getDataQualitySequenceRules(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityBusinessSequenceRuleTypes`)
  }

  public getDQOperators(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityOperators`)
  }

  public getDQDataTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityDataTypes`)
  }

  public getDQTechnicalRuleTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityTechnicalSequenceRuleTypes`)
  }

  public getDQValues(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityValueTypes`)
  }

  public createCustomRule(userId, tenantId, data) {
    return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules `, data);
  }

  public getDQDatasetColumnList(user_id, tenant_id, dataset_id, pagination, pageno, limit, serverFilter = '', sortfilter = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/dqrules/columns?pagination=${pagination}&pageno=${pageno}&pagesize=${limit}${serverFilter}${sortfilter}`);
  }

  public getDQDatasetSummary(user_id, tenant_id, dataset_id, column_id): Observable<any> {
    if (column_id) {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/columns/${column_id}/dqrules/summary`);
    } else {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/dqrules/summary`);
    }
  }

  public getDQDatasetDimensionView(user_id, tenant_id, dataset_id, column_id): Observable<any> {
    if (column_id) {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/columns/${column_id}/dqrules/dimensionview`);
    } else {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/dqrules/dimensionview`);
    }
  }

  public getDQDatasetRules(user_id, tenant_id, dataset_id, column_id): Observable<any> {
    if (column_id) {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/columns/${column_id}/dqrules`);
    } else {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/dqrules`);
    }
  }

  public getDQRuleExceptions(user_id, tenant_id, rule_id, pageno: number, limit: number, isDatasetLevel = false, dataset_id = null, serverFilter, serverSort): Observable<any> {
    let queryParams = '';
    if (isDatasetLevel) {
      queryParams = `&dataset_id=${dataset_id}`;
    }
    if (rule_id) {
      // return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/dataquality/rules/exceptions?rule_id=${rule_id}&pageno=${pageno}&pagesize=${limit}`);
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/dataquality/rules/exceptions?rule_id=${rule_id}&pageno=${pageno}&pagesize=${limit}${queryParams}${serverFilter}${serverSort}`);
      // return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/dataquality/rules/exceptions?rule_id=${rule_id}`);
    } else {
      return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/dataquality/rules/exceptions`);
    }
  }

  public runModelDQ(payload: any): Observable<any> {
    if (this.engine_type_id == 452) {
      return this.http.post(`${this.apiUrl}/algos/410`, payload);
    }
    if (this.engine_type_id == 451) {
      return this.http.post(`${this.apiUrl}/algos/308`, payload);
    }
  }
  public getOutputFormAlgo(payload, algoId: number) {
    return this.http.post(`${this.apiUrl}/algos/${algoId}`, payload);
  }

  getTechRelationshipDiagram(user_id, tenant_id, dataset_id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/relations?type=technical`, { responseType: 'blob' });
  }
  getBussRelationshipDiagram(user_id, tenant_id, dataset_id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/relations`, { responseType: 'blob' });
  }

  validateDatasetName(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/exists?name=${data.name}`);
  }

  public updateBulkData(user_id, tenant_id, payload: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/bulk`, payload);
  }

  public getDatasetClassification(data: any, serverFilter = '', sortfilter = ''): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/classification?pagination=true&pageno=${data.pageno}&pagesize=${data.limit}${serverFilter}${sortfilter}`);
  }
  public exportDatasetClassification(data: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/classification?export=true`);
  }
}



