import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ZsClContentService } from "../../zs-cl-content.service";
declare var $: any;

@Component({
    selector: 'zetta-dq-rule-validation',
    templateUrl: 'dq-rule-validation-model.component.html',
    styleUrls: ['./dq-rule-validation-model.component.scss']
})
export class DqRuleValidationModelComponent implements OnInit, OnChanges {

    img_logo = '../../../../assets/images/common/spinner-2.gif';
    hasError = false;
    @Input() showModel = false;
    @Output() closeModel = new EventEmitter<boolean>(); 

    constructor(private catalogSvc: ZsClContentService, private router:Router) { }


    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }

    ngOnChanges(changes: SimpleChanges): void {
     
    }

    showError() {
        this.hasError = !this.hasError;
    }

    cancelRequest(){
        this.closeModel.emit(false)
    }
    hideErrorModel(){
        $('#error_details').modal('hide');
    }

    onCustomrule() {
        if (this.router.url.includes('create-bussiness-rule')) {
            this.router.navigate(['/zs-cl/rules/create-bussiness-rule/out-of-box-rule'], { queryParamsHandling: 'preserve' });
        } else if (this.router.url.includes('create-technical-rule')) {
            this.router.navigate(['zs-cl/rules/create-technical-rule'], { queryParamsHandling: 'preserve' });
        }
        //this.router.navigate(['zs-cl/rules/create-technical-rule'], {queryParamsHandling:'preserve'});
    }
}