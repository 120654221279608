import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone, OnDestroy, ViewChild, Compiler } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../common/services/authentication.service';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZettaUtils } from '../common/shared/zettaUtils';
import { MessageService } from '../common/components/message/message.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../common/services/base-service';




@Component({
	selector: 'zetta-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

	//Azure AD Login
	title = 'Zettasense - App';
	isIframe = false;
	loggedIn = false;
	account: any;
	subscription: Subscription;
	subscriptions: Subscription[] = [];
	public loginForm: FormGroup;
	public submitted = false;
	//private userLoggedInFromAzure : boolean = sessionStorage.getItem('isAzureLoggedIn') === 'true' ? true : false;
	private userLoggedInFromAzure : boolean;
	isloggedIn: boolean = sessionStorage.getItem('isloggedIn') === 'true' ? true : false;
	
	defaultApp;
	@ViewChild('focusElement') focusElement;
    userLoggedIn=false;    
	public userinfo;
	public profilePic;
	private appLocation: String; // Hold app location currently only supports ingest app only. If we are supporting multiple then this var should be an array/list. 
	private userApps: any[];
	bgAvatar = 'rgb(217, 217, 217)';
	private _apiUrl: string = environment.config.API_URL;
	enable_jwt_auth= environment.config.AUTH_STRATEGY == 'jwt' ? true : false;

	constructor(
		private _fb: FormBuilder,
		private authSvc: AuthenticationService,
		private _router: Router,
		private _zettUtils: ZettaUtils,
		private _messageSvc: MessageService,
		private broadcastService: BroadcastService,
		private authService: MsalService,
		private _baseService: BaseService,
		private http: HttpClient,
		private _compiler: Compiler
	) {		
		this.userLoggedInFromAzure = !!this.authService.getAccount();
	 }

	ngOnInit() {
		this.initZettalabsLogin();
		this.initAzureLogIn();
		this.reLoginUserByJwt();
		this._zettUtils.changeFavicon('./assets/images/common/favicon.png', 'Fluree');		
		if(!this.userLoggedInFromAzure && !this.isloggedIn){
			//User is not logged in 
			sessionStorage.clear();
			this.initZettalabsLogin();
			this.initAzureLogIn();
			this.reLoginUserByJwt();
		} else if(this.userLoggedInFromAzure || this.isloggedIn){
			//User is logged in from azure SSO OR User is logged in from zettalabs sign-in
			this.redirectUser();
		}
	}

	redirectUser() {
		let entitlementsObj = JSON.parse(sessionStorage.getItem('entitlements'));
		let isMultiAppAccess = JSON.parse(sessionStorage.getItem(AppGlobals.ISMULTIPLE_APP_ACCESS));
		this.getAppLocation();
		this.getUserPic();
		if (this._zettUtils.getShortName() === 'z-ing') {
			this._router.navigate([`/${this._zettUtils.getShortName()}/ingestion`]);
		} else {			
			this._router.navigate([`/${this._zettUtils.getShortName()}/home`]);
			//this._router.navigate([`/zs/home`]);
		}
	}

	initAzureLogIn(){
		let loginSuccessSubscription: Subscription;
    	let loginFailureSubscription: Subscription;
		let acquireTokenFailuareSubscription: Subscription;
		this.subscriptions = [];//empty subscriptios array
		//azure AD login
		this.isIframe = window !== window.parent && !window.opener; //This is to avoid reload during acquireTokenSilent() because of hidden iframe
		this.checkoutAccount();

		// THis is called when user is logged in. Please note it is not called when user has already logged in and coming back to the site.
		loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', (res) => {
			this.account = res.account;
			this.userLoggedInFromAzure = true;
			this.userLoggedIn=true;
			this.checkoutAccount();
		});

		loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
			
		});

		this.subscriptions.push(loginSuccessSubscription);
	    this.subscriptions.push(loginFailureSubscription);

		this.authService.handleRedirectCallback((authError, response) => {
			if (authError) {				
				return;
			}			
			this.userLoggedIn=false;			
		});

		this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {			
		}, {
			correlationId: CryptoUtils.createNewGuid(),
			piiLoggingEnabled: false
		}));

		acquireTokenFailuareSubscription =  this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {			
			//this.authService.acquireTokenPopup - this also can be used...
			this.authService.acquireTokenSilent({
				scopes: [ "user.read",
                "openid",
                "profile",
				`${environment.config.AZURE_SOS.apiConsentUrl}`]
			}).then((response)=>{				
			}).catch((error)=>{				
			});
		});
		this.broadcastService.getMSALSubject()
		.subscribe((resp) => {
			if(resp && resp.payload && resp.payload.accessToken) {
				sessionStorage.setItem('accessToken', resp.payload.accessToken);
			}
		});
		this.subscriptions.push(acquireTokenFailuareSubscription);
	}

	//Azure AD function
	checkoutAccount() {
		const self = this;
		this.userLoggedInFromAzure = !!this.authService.getAccount();		
		if(this.userLoggedInFromAzure){
			sessionStorage.setItem('loginProvider', "azure_sos");			
			this.getUserDetails(this.authService.getAccount().userName);			
		} else {			
		}
	}

	//Azure AD function
	login() {
		// clearing the cache and session storage before login
		sessionStorage.clear();
		this._compiler.clearCache();

		sessionStorage.setItem('loginProvider', "azure_sos");
		const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
		if (isIE) {
			this.authService.loginRedirect();
		} else {			
			//this.authService.loginPopup();
			this.authService.loginRedirect();
		}
	}

	//Azure AD function
	logout() {	
		// clearing the cache and session storage before login
		sessionStorage.clear();
		this._compiler.clearCache();

		this.authService.logout();
	}

	ngAfterViewInit() {
		let interval = setInterval(() => {
			if (this.focusElement) {
				this.focusElement.nativeElement.focus();
				clearInterval(interval);
			}
		}, 1000);
	}

	ngOnDestroy() {
		this._messageSvc.clearMessage();
		this.broadcastService.getMSALSubject().next(1);
		if(this.subscriptions.length){
			this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		}
	}

	/*
	 initialize the default LoginForm
   */
	initZettalabsLogin() {
		this.loginForm = this._fb.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
			rememberMe: [sessionStorage.getItem('rememberMe') === 'true' ? true : false]
		});
	}

	// convenience getter for easy access to form fields
	get f() { return this.loginForm.controls; }

	getUserDetails(userName) {
		
		const parent = this;		
		this.authSvc.getUserRegistry(userName).subscribe(resp => {
			let defaultAppId = resp['preference'] ? resp['preference'].default_app_id : -1;
			defaultAppId === -1 ? this.defaultApp = '' : resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)) 
														 ? this.defaultApp = resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)).short_name 
														 : this.defaultApp = '';
			
			if (resp['entitlements'][0]['entitlement']['defaults']['allow_portal_login']) {
				// save Entitlements
				parent.authSvc.getEntitlements().subscribe(data => {
					sessionStorage.setItem('entitlements', JSON.stringify(data));
				});
				sessionStorage.setItem('userInfo', JSON.stringify(resp));
				if (parent.loginForm.value.rememberMe) {
					sessionStorage.setItem('Name', resp['user_name']);
					sessionStorage.setItem('rememberMe', 'true');
				} else {
					sessionStorage.removeItem('Name');
					sessionStorage.setItem('rememberMe', 'false');
				}
				sessionStorage.setItem(AppGlobals.ISLOGGEDIN, 'true');				//'isloggedIn'
				sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'false');	//'isMultipleAppAccess'
				sessionStorage.setItem('selectedApp', this.defaultApp)

				if(defaultAppId === -1 || defaultAppId === null || defaultAppId === 'null') {    //modify to: (defaultAppId === -1 || defaultAppId === null)
					if (resp['entitlements'] && resp['entitlements'].length > 1) {
						sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'true');	//'isMultipleAppAccess'
						this._router.navigate([AppGlobals.ZETTALABS_BASE]);   //'zettalabs/main'
					} else {
						if (resp['entitlements'] && resp['entitlements'].length > 0 && resp['entitlements'][0]['app']['name'] === 'ZettaSense') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');  	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.HOME_ROUTE_BASE]);		//'/zs/home'
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'ZettaMesh') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'true'); 	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTAMESH_LOGIN]);
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'Consume') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTACONSUME_REPORT]);
						}
					}
				} else {
						// sessionStorage.setItem('selectedApp', this.defaultApp)
						sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
						sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'
						// this._router.navigate(['/zs/login']);
						// this._router.navigate(['/zl/login']);
						if(this.defaultApp === 'z-ing') {
							this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
							// this._router.navigate(['/' + this.defaultApp + '/ingestion']);
							setTimeout(() => {
								const selectedAppShortName = sessionStorage.getItem('selectedApp') || 'zs';
								const redirectingFrom = this.userApps.filter((app)=>{ return app.short_name === selectedAppShortName; })[0].app_location;
								//get the status of ingest host if primary location is not available then re-direct to secondary location.
								// this._baseService.getIngestHostStatus(this.userinfo.user_id, this.http).subscribe((result) => {
								// 	let ingestApp = result[0];
								// 	console.log("ingestApp:", ingestApp);
								// 	if(ingestApp.is_primary_live){
								// 		this.appLocation = ingestApp.app_location;
								// 		var isPrimaryUp = "true";
								// 	} else {
								// 		this.appLocation = ingestApp.sec_app_location;
								// 		var isPrimaryUp = "false";
								// 	}
								// 	window.location.href = `${this.appLocation}?userId=${this.userinfo.user_id}
								// 												&tenantId=${this.userinfo.tenant_id}
								// 												&firstName=${this.userinfo.first_name}
								// 												&lastName=${this.userinfo.last_name}
								// 												&bgAvatar=${this.bgAvatar}
								// 												&defAppId=${this.userinfo.preference.default_app_id}
								// 												&profilePic=${this.profilePic}
								// 												&isPrimaryUp=${isPrimaryUp}
								// 												&redirectingFrom=${redirectingFrom}
								// 												&redirectingFromAPI=${this._apiUrl}`;
								// }, error => { 
								// 	console.log(`Error in getting app location:`, error);
								// });

								this._baseService.getIngestLocationByTenant(this.userinfo.user_id, this.userinfo.tenant_id, this.http).subscribe((result) => {
									let ingestApp = result;
									ingestApp.forEach(element => {
										if (element.is_current_environment) {
											this.appLocation = element.ingest_acquisition_url;
										}
									});
									window.location.href = `${this.appLocation}:18630/?userId=${this.userinfo.user_id}&
																						tenantId=${this.userinfo.tenant_id}&
																						firstName=${this.userinfo.first_name}&
																						lastName=${this.userinfo.last_name}&
																						defAppId=${this.userinfo.preference.default_app_id}&
																						redirectingFrom=${redirectingFrom}&
																						redirectingFromAPI=${this._apiUrl}&
																						access_token=${localStorage.getItem('access_token')}`;
								}, error => { 
									console.log(`Error in getting app location:`, error);
								});
								
							}, 500);
						} else if(this.defaultApp === 'zs-cl') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else if(this.defaultApp === 'zs') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else {
							this._router.navigate(['/' + this.defaultApp + 'zs/home']);
						}
				}
			} else {
				let errorMsg = 'Login restricted.';
				this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
			}
		},
		responseError => {
			if (responseError.status === 500) {
				this.userLoggedIn = true;
				if (responseError.error.error.message.toLowerCase() == "This user account does not exists!.".toLowerCase() && this.userLoggedInFromAzure) {
					this.logout();
				}
			}
			parent.loginForm.reset();
			let errorMsg = responseError.error.error.message || AppGlobals.UNAUTHORIZED_ACCESS_ERROR; 
			this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
		});
	}

	authenticateUser() {
		// clearing the cache and session storage before login
		sessionStorage.clear();
		this._compiler.clearCache();
		
		const parent = this;
		parent.submitted = true;
		sessionStorage.setItem('loginProvider', "zettalabs");
		// stop here if form is invalid
		if (parent.loginForm.invalid) {
			return;
		}

		parent.authSvc.login(parent.loginForm.value).subscribe(resp => {
			let defaultAppId = resp['preference'] ? resp['preference'].default_app_id : -1;
			defaultAppId === -1 ? this.defaultApp = '' : resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)) 
														 ? this.defaultApp = resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)).short_name 
														 : this.defaultApp = '';
			
			if (resp['entitlements'][0]['entitlement']['defaults']['allow_portal_login']) {
				// save Entitlements
				parent.authSvc.getEntitlements().subscribe(data => {
					sessionStorage.setItem('entitlements', JSON.stringify(data));
				});
				sessionStorage.setItem('userInfo', JSON.stringify(resp));
				if (parent.loginForm.value.rememberMe) {
					sessionStorage.setItem('Name', resp['user_name']);
					sessionStorage.setItem('rememberMe', 'true');
				} else {
					sessionStorage.removeItem('Name');
					sessionStorage.setItem('rememberMe', 'false');
				}
				sessionStorage.setItem(AppGlobals.ISLOGGEDIN, 'true');				//'isloggedIn'
				sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'false');	//'isMultipleAppAccess'
				sessionStorage.setItem('selectedApp', this.defaultApp)

				if(defaultAppId === -1 || defaultAppId === null || defaultAppId === 'null') {    //modify to: (defaultAppId === -1 || defaultAppId === null)
					if (resp['entitlements'] && resp['entitlements'].length > 1) {
						sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'true');	//'isMultipleAppAccess'
						this._router.navigate([AppGlobals.ZETTALABS_BASE]);   //'zettalabs/main'
					} else {
						if (resp['entitlements'] && resp['entitlements'].length > 0 && resp['entitlements'][0]['app']['name'] === 'ZettaSense') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');  	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.HOME_ROUTE_BASE]);		//'/zs/home'
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'ZettaMesh') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'true'); 	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTAMESH_LOGIN]);
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'Consume') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTACONSUME_REPORT]);
						}
					}
				} else {
						// sessionStorage.setItem('selectedApp', this.defaultApp)
						sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
						sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'
						// this._router.navigate(['/zs/login']);
						// this._router.navigate(['/zl/login']);						
						if(parent.defaultApp === 'z-ing') {
							this.redirectToIngest();							
						} else if(this.defaultApp === 'zs-cl') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else if(this.defaultApp === 'zs') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else {
							this._router.navigate(['/' + this.defaultApp + 'zs/home']);
						}
				}
			} else {
				let errorMsg = 'Login restricted.';
				this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
			}
		},
		responseError => {
			parent.loginForm.reset();
			let errorMsg = responseError.error.error.message || AppGlobals.UNAUTHORIZED_ACCESS_ERROR;
			this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
		});
	}

	authenticateJwtUser() {
		// clearing the cache and session storage before login
		sessionStorage.clear();
		this._compiler.clearCache();
		
		const parent = this;
		parent.submitted = true;
		sessionStorage.setItem('loginProvider', "zettalabs");
		// stop here if form is invalid
		if (parent.loginForm.invalid) {
			return;
		}		

		parent.authSvc.loginWithJwt(parent.loginForm.value).subscribe(resp => {
			parent.handleUserLoginResp(resp);			
		},
		responseError => {
			parent.loginForm.reset();
			let errorMsg = responseError.error.error;
			this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
		});
	}

	handleUserLoginResp(resp){
		const parent = this
		let defaultAppId = resp['preference'] ? resp['preference'].default_app_id : -1;
			defaultAppId === -1 ? this.defaultApp = '' : resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)) 
														 ? this.defaultApp = resp['apps'].find( app => parseInt(app.app_id) === parseInt(resp['preference'].default_app_id)).short_name 
														 : this.defaultApp = '';
			
			if (resp['entitlements'][0]['entitlement']['defaults']['allow_portal_login']) {
				// Creating local storage for jwt access tocken
				if(resp.hasOwnProperty('access_token') && resp.hasOwnProperty('expires')){
					localStorage.setItem('access_token',resp['access_token']);
					localStorage.setItem('expires',resp['expires']);
					sessionStorage.setItem('enable_jwt',"true");
				}
				// save Entitlements
				parent.authSvc.getEntitlements().subscribe(data => {
					sessionStorage.setItem('entitlements', JSON.stringify(data));					
				});
				sessionStorage.setItem('userInfo', JSON.stringify(resp));
				localStorage.setItem('userInfo', JSON.stringify(resp));
				
				if (parent.loginForm.value.rememberMe) {
					sessionStorage.setItem('Name', resp['user_name']);
					sessionStorage.setItem('rememberMe', 'true');
				} else {
					sessionStorage.removeItem('Name');
					sessionStorage.setItem('rememberMe', 'false');
				}
				sessionStorage.setItem(AppGlobals.ISLOGGEDIN, 'true');				//'isloggedIn'
				sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'false');	//'isMultipleAppAccess'
				sessionStorage.setItem('selectedApp', this.defaultApp);
				if(defaultAppId === -1 || defaultAppId === null || defaultAppId === 'null') {    //modify to: (defaultAppId === -1 || defaultAppId === null)
					if (resp['entitlements'] && resp['entitlements'].length > 1) {
						sessionStorage.setItem(AppGlobals.ISMULTIPLE_APP_ACCESS, 'true');	//'isMultipleAppAccess'
						this._router.navigate([AppGlobals.ZETTALABS_BASE]);   //'zettalabs/main'
					} else {
						if (resp['entitlements'] && resp['entitlements'].length > 0 && resp['entitlements'][0]['app']['name'] === 'ZettaSense') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');  	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.HOME_ROUTE_BASE]);		//'/zs/home'
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'ZettaMesh') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'true'); 	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTAMESH_LOGIN]);
						} else if (resp['entitlements'] && resp['entitlements'][0]['app']['name'] === 'Consume') {
							sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
							sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'false');	//'isZsloggedIn'
							this._router.navigate([AppGlobals.ZETTACONSUME_REPORT]);
						}
					}
				} else {						
						sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, 'false');	//'isZmloggedIn'
						sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, 'true');	//'isZsloggedIn'												
						if(parent.defaultApp === 'z-ing') {
							this.redirectToIngest();							
						} else if(this.defaultApp === 'zs-cl') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else if(this.defaultApp === 'zs') {
							this._router.navigate(['/' + this.defaultApp + '/home']);
						} else {
							sessionStorage.setItem('selectedApp', 'zs');
							this._router.navigate(['/' + this.defaultApp + 'zs/home']);
						}
				}
			} else {
				let errorMsg = 'Login restricted.';
				this._messageSvc.sendMessage({ message: errorMsg, type: 'ERROR', hideboard: true });
			}
	}

	loginUser() {
		if (this.enable_jwt_auth) {
			this.authenticateJwtUser();
		} else {
			localStorage.removeItem('access_token');
			localStorage.removeItem('expires');
			sessionStorage.removeItem('enable_jwt');
			this.authenticateUser();
		}
	}

	reLoginUserByJwt() {
		// checking if the token is already exting then, loggedin the user automatically!
		if (localStorage.getItem('access_token') && localStorage.getItem('expires')) {
			sessionStorage.clear();
			this._compiler.clearCache();
			sessionStorage.setItem('loginProvider', "zettalabs");
			const resp = JSON.parse(localStorage.getItem('userInfo'));
			this.handleUserLoginResp(resp);
		}
	}

	redirectToIngest() {
		this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));		
		this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
			this.userApps = result;
			this.appLocation = result.filter((app) => { return app.app_id == 4; })[0].app_location;
			if (this.defaultApp === 'z-ing') {
				this._router.navigate([`/${this.defaultApp}/ingestion`]);
			}
			const selectedAppShortName = sessionStorage.getItem('selectedApp') || 'zs';
			var redirectingFrom = this.userApps.filter((app) => { return app.short_name === selectedAppShortName; })[0].app_location;

			let index = redirectingFrom.indexOf("#");
			redirectingFrom = redirectingFrom.substring(0, index);
			this._baseService.getIngestLocationByTenant(this.userinfo.user_id, this.userinfo.tenant_id, this.http).subscribe((result) => {
				sessionStorage.setItem("isIngestRedirect", "z-ing");
				let ingestApp = result;
				if (ingestApp.length > 0) {
					ingestApp.forEach(element => {
						if (element.is_current_environment) {
							this.appLocation = element.ingest_acquisition_url;
						}
					});
				} else {
					this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
						let ingestApp = result;
						ingestApp.forEach(element => {
							if (element.app_id === 4) {
								this.appLocation = element.app_location;
							}
						});
					}, error => { 
						console.log(`Error in getting app location:`, error);
					});
				}

				// bgAvatar=${this.bgAvatar}&
				// profilePic=${this.profilePic}&

				window.location.href = `${this.appLocation}:18630/?userId=${this.userinfo.user_id}&
																						tenantId=${this.userinfo.tenant_id}&
																						firstName=${this.userinfo.first_name}&
																						lastName=${this.userinfo.last_name}&
																						defAppId=${this.userinfo.preference.default_app_id}&
																						redirectingFrom=${redirectingFrom}&
																						redirectingFromAPI=${this._apiUrl}&
																						access_token=${localStorage.getItem('access_token')}`;
			}, error => {
				console.log(`Error in getting app location:`, error);
			});
		});
	}
	getAppLocation(){		
			this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
				this.userApps = result;				
				this.appLocation = result.filter((app)=>{ return app.app_id == 4; })[0].app_location;
			}, error => {				
			});
	}

	public getUserPic() {
		sessionStorage.setItem('isUserProfile', "1");
			this._baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {			
				let reader = new FileReader();
				reader.addEventListener('load', () => {
					this.profilePic = reader.result;
				}, false);
				if (image && image.size && image.type == "image/gif") {
					this.profilePic = reader.readAsDataURL(image);
				} else {
					this.profilePic = null;
				}
			}, err => {
				this.profilePic = err.url;				
			});
	}
}
