import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { BlueTextFormatterComponent } from 'src/app/common/shared/cell-renderer/blue-Text-Formatter/blue-Text-Formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { CopyIconRendererComponent } from 'src/app/common/shared/cell-renderer/delete-icon-formatter/copy-icon-formatter';
import { ProjectService } from '../project.service';

@Component({
  selector: 'zetta-production-project-data',
  templateUrl: './production-project-data.component.html',
  styleUrls: ['./production-project-data.component.scss']
})
export class ProductionProjectDataComponent implements OnInit {
  activeNavLnk: string = 'realtimeapi';
  clusterManagerTypes = [{name: 'Input', value: 'input' }, {name: 'Consumption', value: 'consumption' }];
  selectedClusterManager: string = 'input';
  trainModelDataSource: object = {};
  columnDefs: ColDef[] = [];
  secondColumnDefs: ColDef[] = [];
  loggedInUserDetails;
  projectId;
  configDetails;
  fsGridOptions: GridOptions;
  agGrid:AgGridAngular;
  gridApi:any;
  columnApi: any;
  gridOptions: any;
  feedbackGridOptions: any;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  inputDataSource: object = {};
  projectDetail: object = {};
  constructor(private activeRoute: ActivatedRoute, private projectService: ProjectService, private router: Router) { }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.activeRoute.parent.params.subscribe(params => {
      this.projectId = params['id']; 
    });
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      checkboxSelection:true,
      suppressRowClickSelection:true,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.getSchedulerInfo();
this.trainModelDataSource = {
    "pagesize": 100,
    "pageno": 1,
    "totalpages": 10,
    "totalrecords": 942,
    "next": true,
    "previous": false,
    "currentpage": [
        {
            "cluster_id": "1f16278c-ad4e-4129-bfcc-5d92f4412ccc",
            "task_id": 652458,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 820,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "People Data 5K",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        },
        {
            "cluster_id": "07768d27-fdef-4339-b08f-1055ace8965e",
            "task_id": 652466,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 821,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "Prospect Data",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        },
        {
            "cluster_id": "410b0ae1-1f9e-4b05-81fe-65615d71c66f",
            "task_id": 652493,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 820,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "Salesforce Data",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        },
        {
            "cluster_id": "8041281f-6af5-456a-8d00-bf2584c8b5ba",
            "task_id": 652459,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 820,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "Customer Data",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        },
        {
            "cluster_id": "f15dc511-29c3-4a52-96e5-839a4ff59256",
            "task_id": 652481,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 820,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "People Data 5K",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        },
        {
            "cluster_id": "25e5abc7-9ea6-48aa-836d-30801920e790",
            "task_id": 652461,
            "task_type": "Match Training",
            "task_type_id": 278,
            "assigned_to": 821,
            "user_name": "https://fs-qa-cloudapp/",
            "project_id": 5606,
            "name": "Copy_of_Copy_of_Error_check _548_313",
            "entity_id": 6250,
            "taskname": "Customer Data",
            "role_name": "Project Reviewer",
            "task_status": "In Review",
            "last_updated": "2024-05-06T15:34:02.486Z",
            "last_updated_day": 2
        }]};

this.columnDefs = [
  {
    headerName: 'Dataset Name',
    field: 'name',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    minWidth: 80,
  },
  {
    headerName: 'URL',
    field:'ui_app_url',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    floatingFilter: true,
    sortable: true,
    // cellClass: 'ag-right-aligned-cell',
    minWidth: 60,
    cellRendererFramework: BlueTextFormatterComponent,
  },
  // {
  {
      headerName: 'Action',
      field: 'action',
      cellClass: 'blue-copy-icon text-center',
      cellRendererFramework: CopyIconRendererComponent,  
      maxWidth: 100,
      suppressColumnsToolPanel: true, 
      minWidth: 80,
  
  },
];

this.secondColumnDefs = [
  {
    headerName: 'API Name',
    field: 'taskname',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    minWidth: 140,
  },
  {
    headerName: 'Description',
    field: 'name',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: 'URL',
    field:'user_name',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    floatingFilter: true,
    sortable: true,
    // cellClass: 'ag-right-aligned-cell',
    minWidth: 100,
    cellRendererFramework: BlueTextFormatterComponent,
  },
  // {
  {
      headerName: 'Action',
      field: 'action',
      cellClass: 'blue-copy-icon text-center',
      cellRendererFramework: CopyIconRendererComponent,  
      // maxWidth: 100,
      suppressColumnsToolPanel: true, 
      minWidth: 80,
  
  },
];
this.activeRoute.parent.params.subscribe(params => {
  this.projectId = params['id']; 
});
this.getInputDataSets(1, 100);
}

onGridCreation(grid) {
const parent = this;
parent.agGrid = grid;
const gridApi = grid.api;
}

  onTabClick(activeLink: string) {
      this.activeNavLnk = activeLink;
  }

  onChange(eve) {
    this.selectedClusterManager = eve.target.value;
  }

  getSchedulerInfo() {
    this.projectService.getProjectConfigSettings(this.loggedInUserDetails['user_id'], this.projectId)
    .subscribe(resp => {
      this.configDetails = resp;
    });
  }

  getInputDataSets(pageno, limit) {
    this.projectService.getProjectDatasets(this.loggedInUserDetails['user_id'], this.projectId)
    .subscribe(resp => {
      if(resp) {
        this.inputDataSource = resp;
      }
    });
  }

  handleUserGridClickEvent(eventData){
    const args = eventData.args;
    const event = eventData.event;
    this.agGrid = eventData.api;
    const metadata = eventData.data;
    const fieldName = eventData.colDef.field;
    const row = eventData.data;
    if (row !== undefined && row !== null) {
      if (fieldName == "ui_app_url") {
        return;
        // this.router.navigate([`/zs/datasets/${row.dataset_id}`], { queryParams: {showBreadcrumb: true, dataset_flow: true}});
      } else if(fieldName == 'action') {
        window.navigator['clipboard'].writeText(row.ui_app_url).then(res => console.log('cOPY resp is ----  ', res)).catch(e => console.log('failed to copy'));
      }
    }
  }

  handleRightGridClick(eventData) {
    this.agGrid = eventData.api;
    const fieldName = eventData.colDef.field;
    const row = eventData.data;
    if (row !== undefined && row !== null) {
      if (fieldName == "action") {
        window.navigator['clipboard'].writeText(row.user_name).then(res => console.log('cOPY resp is ----  ', res)).catch(e => console.log('failed to copy'));
      }
    }
  }
  
}
