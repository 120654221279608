import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-dropdown-cell-renderer',
  template: ` 
    <div class="w-100 display-block ">
      <select [id]="'attribute-' + params.column.colId + '-' + params.node.rowIndex"
              [class]="getCssClass()"
              (change)="onDropdownValueChange($event)">
        <option [value]="''">{{ getPlaceholder() }}</option>
        <ng-container *ngFor="let item of params.list">
          <option [value]="item.name" [selected]="isSelected(item.name)">
            {{ item.name }}
          </option>
        </ng-container>
      </select>
    </div>
   
  `,
})
export class AgDropdownCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  @Output() dropdownValueChanged = new EventEmitter<string>();
  showDefaultDropdown = false;

  agInit(params: any): void {
    this.params = params;     

    if(params.node.id === 't-0'){
      this.showDefaultDropdown = true;
    }
  }

  refresh(params: any): boolean {
    this.params = params; 
    return true;
  }

  getCssClass(): string {
    return this.params.data.action !== 'delete' ? 'ctmInputBox drop-down-minimal dd-edit-entity-wd' : 'ctmInputBoxNoEdit drop-down-minimal';
  }

  onDropdownValueChange(event: any ) { 
    const newValue = this.params.data;
    const rowIndex = this.params.rowIndex; 

    const fieldName =  this.params.column.colId;  

    const dropdownObj = {
      value: newValue,
      fieldName: fieldName,
      rowIndex: rowIndex,  
      id: this.params.node.id
    }
  
    // this.dropdownValueChanged.emit(newValue);
    this.params.context.componentParent.onDropdown(dropdownObj)    
 
  } 

  getPlaceholder(): string {
        return 'Select Group';
  }

  isSelected(name: string) {
    return this.params.data.group_name === name;
  }
}
 