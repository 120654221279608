import { Component, OnInit } from '@angular/core';
import { ObservableService } from '../../common/services/observable.service';
import { ZettaUtils } from '../../common/shared/zettaUtils';

@Component({
  selector: 'zetta-zc-sidebar',
  templateUrl: './zc-sidebar.component.html',
  styleUrls: ['./zc-sidebar.component.scss']
})
export class ZcSidebarComponent implements OnInit {

  isLeftSideBarActive = true;

	constructor(public observeSvc: ObservableService,
		private zettaUtils: ZettaUtils) { }

	ngOnInit() {
		this.zettaUtils.getEntitlements('zc');
		this.zettaUtils.getShortName();
		this.zettaUtils.changeFavicon('../../assets/images/common/favicon.png', 'FlureeSense');
	}

	toggleLeftSideBar(menuIndex: number) {
		const currentSessionIndex = parseInt(sessionStorage.getItem('menuIndex'), 10) || 1;
		if (currentSessionIndex && currentSessionIndex === menuIndex) {
			this.isLeftSideBarActive = !this.isLeftSideBarActive;
			this.observeSvc.refreshDataGrid = this.isLeftSideBarActive;
		}
		sessionStorage.setItem('menuIndex', menuIndex.toString());
	}

	applyClass() {
		return this.isLeftSideBarActive ? 'fa-angle-right' : 'fa-angle-left';
	}

	getSidebarEntitlements(sidebarName) {
		const sidebarEntitlement = JSON.parse(sessionStorage.getItem('userEntitlement'));
		const entitlements = sidebarEntitlement.menus.find(menus =>
			menus.name === 'left_panel').childs.find(children => children.name === sidebarName);
		if (entitlements) {
			return entitlements.enable;
		}
	}

}
