import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-confidence-level-golden-formatter',
  template: `
    <div [ngClass]="getCssClasses()">
      <span>{{ getValue() }}</span>
    </div>
  `,
  styles: []
})
export class ConfidenceLevelGoldenFormatterComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;  
    // const api = this.params.api; 
    //   api.forEachNode(node => {
    //     console.log("from cellrendered:", node);
    //   });

      
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getValue(): string {
    const value = this.params.value;
    if (value === undefined || value === null || value === '') {
      return 'n/a';
    } else {
      const score100 = Number(value * 100); 
      const formattedPer = score100 === 100 ? '100%' : score100.toFixed(2) + '%';
      return formattedPer;
    }
  }

  getCssClasses(): string {
    const value = this.params.value;
    const score100 = Number(value * 100);
    const conf = score100 >= 75 ? 'HIGH' : (score100 >= 50 && score100 < 75) ? 'MEDIUM' : 'LOW';
    return `font-wieght box-cat-obj priorityCell text-center ${conf.toLowerCase()}`;
  }
}