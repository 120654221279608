import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-actions-cell-renderer',
  template: `
  <span *ngIf="value" class="text-right pointer context-menu" [ngClass]="{'disabled-state':!active}"><i class="fal fa-ellipsis-v f-size-22" aria-hidden="true"></i></span>
  
  `,
})
export class AdminActionsRendererComponent implements OnInit, ICellRendererAngularComp {
    active = false;
    value = undefined;
    params: any;

    agInit(params: ICellRendererParams): void {
        this.value = params.data ? true : false;
        this.params = params.data;
        if (params.data.isdisable) {
            this.active = false;
          } else {
            this.active = true;
          }
    }
    refresh(params: ICellRendererParams): boolean {
        return false;
      }
    
      constructor() { }
    
      ngOnInit(): void {
      }
    
      onClick() {
        this.params.context.componentParent.updateRow(this.params.rowIndex);
      }
      onActionClick() {
    
      }
    }