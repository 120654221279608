import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ObservableService } from '../../../../../common/services/observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { MessageService } from '../../../../../common/components/message/message.service';
import $ from 'jquery';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { DatasetService } from '../../../../../common/components/datasets/dataset.service';

@Component({
	selector: 'zetta-pd-classified',
	templateUrl: './zs-cl-pd-classified.component.html',
	styleUrls: ['./zs-cl-pd-classified.component.scss']
})

export class ZsClPdClassifiedComponent implements OnInit, OnDestroy {

	public project_type;
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName = '';
	public userinfo = JSON.parse(sessionStorage.userInfo);
	public errorMessage: string;
	public errorDetails: string;
	public lineProjectResolvedChartDS = null;
	public entitiesMasteredDataSource = null;
	public entitiesMasteredChartColors = ['#4bb100', '#eeb049', '#d35162'];
	public notApplicable = false;
	public projectId;
	modalClasses = [];
	public publishStatus: number;
	public pubStatus: number;
	public setInterv: any;
	public enableBtn: boolean = false;
	public projectType: string = '';
	public panelScreen: number;
	public buttonWrap: boolean;
	public project_view_all: any;
	public project_create: any;
	is_visible_golden = false;
	is_enable_golden = false;
	object_type_id: number;
	public currentURL;
	public entitiesDataSource = null;
	public const_caption: string = "Executing models, this may take a few minutes";
	public caption: string = "Executing models, this may take a few minutes";
	header_title="Data classified error details";
	showSummaryModal : boolean = false;
	isAdmin: boolean = false;
	projectDetail: object = {};
	fixingDataAvailable: boolean = false;
	trainModelDataAvailable: boolean = false;
	@Input() isUserGrantedClassifyProject;
	@Input() isProjectRanOnce: boolean = false;
	public entitiesChartOptions: object = {
		'labelPercentValue': 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence',
		'resizeRequired': true,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'isPercentRequired': true
	};
	public lineProjectResolvedChartOptions: object = {
		'isMoneyFormat': false,
		'height': 133,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#e5b500',
		'circleColor': '#ff0000'
	};

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZettaUtils,
		private zsClContentService: ZsClContentService,
		private _projectService: ProjectService,
		private datasetSvc: DatasetService,
		public observeSvc: ObservableService,
		private activeRoute: ActivatedRoute,
		private messageSvc: MessageService,
		private router: Router) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();

	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;		
		this.object_type_id = +sessionStorage.getItem('classify_type_id');
		this.projectId = routeParams.id;
		this.projectType = routeParams.project_type;
		this.project_type = sessionStorage.getItem("project_type");
		this.getEntityResolvedData(this.userinfo.user_id,this.projectId);
		parent.onResize();
		parent.getEntityModalRunsChartData();
		parent.getEntityModalRunsSummary();
		parent.getRightClassList();
		parent.getProject();
		parent.getGraphData();
	}
	isNotHavingAccess() {
		if (this.zettaUtils.isProcessRunning || this.notApplicable || !this.isUserGrantedClassifyProject || !this.trainModelDataAvailable) {
			return true;
		}
		return false;
	}

	canViewResultSummary() {
		if (this.zettaUtils.isProcessRunning || this.notApplicable || !this.isUserGrantedClassifyProject) {
			return false;
		}
		if (!this.isProjectRanOnce) {
			return false;
		}
		return true;
	}

	getEntityModalRunsChartData() {
		const data = {
			user_id: this.userinfo.user_id,
			tenant_id: this.userinfo.tenant_id,
			project_id: this.projectId
		};
		this.zsClContentService.getEntityModalRuns(data).subscribe(
			data => {
				this.lineProjectResolvedChartDS = this.prepareLineChartData(data);
			},
			error => {
				this.lineProjectResolvedChartDS = [];
			}
		);
		this.observeSvc.getEntityName.subscribe(data => {
			this.selectedProjectName = data;
		});
	}

	getEntityModalRunsSummary() {
		const data = {
			user_id: this.userinfo.user_id,
			tenant_id: this.userinfo.tenant_id,
			project_id: this.projectId
		};
		this.zsClContentService.getConfidenceModalSummary(data).subscribe(
			data => {
				this.statisticsObj = { 'model_runs': 0, 'model_runs_as_of': new Date(), 'master_records': 0, 'master_records_as_of': new Date() };
				for (let index = 0; index < data.length; index++) {
					const obj = data[index];
					if (obj.name === 'run_number') {
						this.statisticsObj['master_records'] = obj['value'] || 0;
					} else if (obj.name === 'as_of') {
						this.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
						this.statisticsObj['master_records_as_of'] = obj['count'] || new Date();
					} else if (obj.name === 'total_entities') {
						this.statisticsObj['model_runs'] = obj['count'] || 0;
					} else if (obj.name === 'overall_confidence') {
						this.statisticsObj['labelPercentValue'] = obj['value'] || 0;
						this.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
					}
				}
				this.entitiesMasteredDataSource = this.getDonutParseData(data);
			},
			error => {
				this.entitiesMasteredDataSource = [];
				this.statisticsObj = { 'model_runs': 0, 'model_runs_as_of': new Date(), 'master_records': 0, 'master_records_as_of': new Date() };
			}
		);
	}
	onResize() {
		this.panelScreen = $("#entitiesMasteredPanel").outerWidth();
		if (this.panelScreen < 650) {
			this.buttonWrap = true;
		} else {
			this.buttonWrap = false;
		}
	}

	getDonutParseData(data) {
		const dataSet = [];
		data.forEach((element) => {
			if (element['name'] === 'high_confidence') {
				dataSet.push({ 'Current Model': 'High', 'Confidence': element['records'] });
			} else if (element['name'] === 'medium_confidence') {
				dataSet.push({ 'Current Model': 'Medium', 'Confidence': element['records'] });
			} else if (element['name'] === 'low_confidence') {
				dataSet.push({ 'Current Model': 'Low', 'Confidence': element['records'] });
			}
		});
		return dataSet;
	}

	getEntityResolvedData(userId, projectId) {
		const parent = this;
		parent.zettaUtils.isProcessRunning = true;
		// parent.observableRef = Observable.timer(0, AppGlobals.ENTITIES_RESOLVED_JOB_INTERVEL)
		parent.observableRef = Observable.timer(0, 20000)
			.takeWhile(() => parent.alive)
			.subscribe(() => {
				// Cancel subscription in case URL changes
				if (parent.currentURL !== parent.router.url) {
					parent.alive = false;
					parent.observableRef.unsubscribe();
					return;
				}
				if(this.object_type_id){ //14845
					parent._projectService.getLatestJob(userId, projectId,this.object_type_id).subscribe(resp=>{
						const job_id =resp.loader.job_id;
						parent._projectService.getCurrentActiveJobsById(userId, projectId,this.object_type_id,job_id)
						.subscribe(response=>{
							this.zettaUtils.isFileNotFound=false;
							if (Object.values(response).length === 0) {
								parent.zettaUtils.isProcessRunning = false;
								parent.notApplicable = true;
								parent.entitiesDataSource = [];
								return;
							}
							response['steps_completed'] = response['steps_completed'] || 0;
							response['total_steps'] = response['total_steps'] || 0;
							parent.currentJobStatusObj = response;
							if (response['caption'] === null || response['caption'] === '') { 
								response['caption'] === this.const_caption; 
							} 
							if (this.caption === this.const_caption) {
								this.caption = response['caption'];
							} else {
								this.caption = this.const_caption
							}
							if (response['job_completed']) {
								parent.zettaUtils.isProcessRunning = false;
								sessionStorage.setItem('isPageReload', 'true');
								//parent.refresh();
								parent.observableRef.unsubscribe();
								
							} else if (response['is_errored'] && response['type'] !== 'merger') {
								parent.isStepsCompleted = true;
								parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
								// displaying error message only for classify type projects and error-details for resolve projects.
								if(this.object_type_id === AppGlobals.CLASSIFY_TYPE_ID ) {
									parent.errorDetails = response['error_message'];
								} else {
									parent.errorDetails = response['error_details'];
								}
								parent.zettaUtils.isProcessRunning = false;
								parent.alive = false;
								parent.observableRef.unsubscribe();
							} else if (response['type'] !== 'merger' && !parent.currentJobStatusObj['job_completed']) {
								parent.isStepsCompleted = true;
							}
							parent.observeSvc.setTrackStatus = parent.currentJobStatusObj;
							parent.observeSvc.getTrackStatus.subscribe(data => parent.currentJobStatusObj = data);
							parent.currentJobStatusObj['algoid'] = AppGlobals.getRunModelAlgo(0);
						 
						},error=>{
							if(error.status===404){
								this.zettaUtils.isFileNotFound =true;
								parent.zettaUtils.isProcessRunning = false;
							}
						});
					},error=>{
						if(error.status===500){
							this.zettaUtils.isFileNotFound =true;
							parent.zettaUtils.isProcessRunning = false;
						}
					});					
				}
				
			});
	}
	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
		this.messageSvc.clearMessage();
		clearInterval(this.setInterv);
	}

	@HostListener('window:unload', ['$event'])
	unloadHandler(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	cancelJOB() {
		this._projectService.cancelActiveJob(this.userinfo.user_id, this.projectId).subscribe(response => {
			this.alive = false;
			this.zettaUtils.isProcessRunning = false;
			this.observableRef.unsubscribe();
		});
	}

	prepareLineChartData(data) {
		const dataSet = [];
		data.forEach((element) => {
			const d = new Date(element['created_ts']);
			dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'].toFixed(2), 'fulldate': d });
		});
		return dataSet;
	}
	refresh() {
		window.location.reload();
	}

	navigateToTrainmodal() {
		this.router.navigate(['../../train-model'], {relativeTo: this.activeRoute,
			 queryParamsHandling: 'merge', queryParams: { class: null } });
	}

	toSemanticResults() {
		this.router.navigate(['../../view-result'], {relativeTo: this.activeRoute,
			queryParamsHandling: 'merge', queryParams: { class: null } });
	}

	toConceptResults() {
		this.router.navigate(['../../view-result-parse'], {relativeTo: this.activeRoute,
			queryParamsHandling: 'merge', queryParams: { class: null } });
	}

	publishResults(){    
		this.datasetSvc.runModel416(this.projectId,this.userinfo.user_id).subscribe((result) => {      
			this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'PublishedProject', messageHeader: 'Publish Results in Progress', 
										  messageText: 'The process to Publish Results has been triggered. You can see job status and monitor progress for Classify Projects jobs in the System Jobs screen'});                         
		},error=>{
			this.messageSvc.sendMessage({message: 'Algo 416 call has failed', type: 'ERROR', hideboard: true});
		});      
	}

	showResultSummaryModal() {
		this.observeSvc.setEntityName = this.selectedProjectName;
		this.showSummaryModal = true;
	}

	onCancel() {
	  this.showSummaryModal = false;
	}

	hideConfigModal(eve) {
	  this.showSummaryModal = eve;
	}

	getRightClassList() {
		this.zsClContentService.getClassifierClassList(this.userinfo.user_id, this.userinfo.tenant_id, this.projectId)
		.subscribe(classes => {
		  if(classes) {
			this.modalClasses = classes;
		  }
		}, err => {
		});
	}

	getProject() {
		const data = {
		  user_id: this.userinfo['user_id'],
		  tenant_id: this.userinfo['tenant_id'],
		  project_id: this.projectId
		};
		this.zsClContentService.getclassifyProject(data)
		.subscribe(res => {
		  this.projectDetail = res;
		  this.isAdmin = this.checkForAdminAccess(this.projectDetail);
		});
	}

	checkForAdminAccess(info) {
		if(info && this.userinfo) {
		  let loggedInUser =   (info && info['users'] && info['users'].length) ? info['users'].find(user => user['user_id'] == this.userinfo['user_id']) : null;
		  if(loggedInUser) {
				let isAdmin = loggedInUser.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_ADMIN);
				return isAdmin == AppGlobals.CLASSIFY_PROJECT_ADMIN ? true: false;
			}
		}
		return false;
	}

	getGraphData() {
		const data = {
			user_id: this.userinfo.user_id,
			tenant_id: this.userinfo.tenant_id,
			project_id: this.projectId
		  }
		this.zsClContentService.getClassifyProjGraphSummary(data)
		.subscribe(res => {
			if(res) {
				res.forEach((e: any) => {
					if (e['name'] === 'training_tasks_count_to_be_reviewed') {
						if(e.value && e.value > 0) {
							this.trainModelDataAvailable = true;
							this.fixingDataAvailable = false;
						}
					} else if (e['name'] === 'training_tasks_type_fixing_count_to_be_reviewed') {
						if(e.value > 0) {
						this.fixingDataAvailable = true;
						this.trainModelDataAvailable = false;
					}
					}
				  });
				}
			});
	}
}

