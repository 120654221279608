import { Component, OnInit } from '@angular/core';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../project-progress/project-progress.model';

@Component({
  selector: 'zetta-map-data-column',
  templateUrl: './map-data-column.component.html',
  styleUrls: ['./map-data-column.component.scss']
})
export class MapDataColumnComponent implements OnInit {
  progressState = new ProjectStatus();
  constructor(public zettaUtils: ZettaUtils,) { }

  ngOnInit() {
    this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
        this.progressState.currentStateIndex = 5;
        this.progressState.type = 'create-bussiness-rules';
        this.progressState.type = 'classifySet';  
  }

}
