import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zm-zetta-spinner',
  templateUrl: './zm-spinner.component.html',
  styleUrls: ['./zm-spinner.component.scss']
})
export class ZmSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
