import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { environment } from '../environments/environment';

//Azure AD
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
export const protectedResourceMap: [string, string[]][] = [
    //['https://graph.microsoft.com/v1.0', ['user.read', 'user.ReadBasic.All', 'group.Read.All']],
    ['https://graph.microsoft.com/v1.0', ['user.read']],
    ['./assets/i18n/en.json', null],
    ["https://login.microsoftonline.com", null],
    [`${environment.config.API_BASE_URL}`, [`${environment.config.AZURE_SOS.apiConsentUrl}`]]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './common/shared/app-shared.module';
import { ZettasenseModule } from './zettasense/zettasense.module';
import { ZettameshModule } from './zettamesh/zettamesh.module';
import { ZettaConsumeModule } from './zettaconsume/zettaconsume.module';
import { ZettaClassifyModule } from './zettaclassify/zettaclassify.module';
import { ZettaIngestModule } from './zettaingest/zettaingest.module';
import { ConfirmDialogModule } from './common/components/confirm-dialog/confirm-dialog.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './zettasense/header/header.component';
import { FooterComponent } from './zettasense/footer/footer.component';
import { SidebarComponent } from './zettasense/sidebar/sidebar.component';
import { BreadcrumbComponent } from './zettasense/breadcrumb/breadcrumb.component';
import { LandingComponent } from './landing/landing.component';
import { ZmSidebarComponent } from './zettamesh/zm-sidebar/zm-sidebar.component';
import { ZmHeaderComponent } from './zettamesh/zm-header/zm-header.component';
import { MainComponent } from './main/main.component';
import { StyleguideComponent } from './zettasense/styleguide/styleguide.component';

// Datasource Components
import { DatasourceHomeComponent } from './common/components/datasources/datasource-home/datasource-home.component';
import { CreateDatasourceComponent } from './common/components/datasources/create-datasource/create-datasource.component';
import { DefineConnectionComponent } from './common/components/datasources/define-connection/define-connection.component';
import { AddAdminUsersComponent } from './common/components/datasources/add-admin-users/add-admin-users.component';
import { SetUserEntitlementsComponent } from './common/components/datasources/set-user-entitlements/set-user-entitlements.component';
import { DatasourceDetailComponent } from './common/components/datasources/datasource-detail/datasource-detail.component';
import { DatasourceContentsComponent } from './common/components/datasources/datasource-detail/datasource-contents/datasource-contents.component';
import { ConnectionDetailComponent } from './common/components/datasources/datasource-detail/connection-detail/connection-detail.component';
import { DatasourceEntitlementsComponent } from './common/components/datasources/datasource-detail/datasource-entitlements/datasource-entitlements.component';
import { EditDescriptionComponent } from './common/components/datasources/datasource-detail/edit-description/edit-description.component';

// Datasets Components
import { DatasetHomeComponent } from './common/components/datasets/dataset-home/dataset-home.component';
import { CreateDatasetComponent } from './common/components/datasets/create-dataset/create-dataset.component';
import { SelectDataSourceComponent } from './common/components/datasets/select-data-source/select-data-source.component';
import { AddDataTransformationComponent } from './common/components/datasets/add-data-transformation/add-data-transformation.component';
import { ScheduleRefreshComponent } from './common/components/datasets/schedule-refresh/schedule-refresh.component';
import { ClientProfitsComponent } from './common/components/datasets/client-profits/client-profits.component';
import { UserEntitlementsComponent } from './common/components/datasets/user-entitlements/user-entitlements.component';
import { DatasetAttributesComponent } from './common/components/datasets/client-profits/dataset-attributes/dataset-attributes.component';
import { DatasetRelationShipsComponent } from './common/components/datasets/client-profits/dataset-relationships/dataset-relationships.component';
import { DataRefreshComponent } from './common/components/datasets/client-profits/data-refresh/data-refresh.component';
import { DataEntitlementsComponent } from './common/components/datasets/client-profits/data-entitlements/data-entitlements.component';
import { DataTransformationsComponent } from './common/components/datasets/client-profits/data-transformations/data-transformations.component';
import { RelatedProjectsComponent } from './common/components/datasets/client-profits/related-projects/related-projects.component';
import { DataDescriptionComponent } from './common/components/datasets/client-profits/data-description/data-description.component';
import { DatasetDataQualityComponent } from './common/components/datasets/client-profits/dataset-data-quality/dataset-data-quality.component';
import { FormsModule } from '@angular/forms';

// Admin Component
import { AdminComponent } from './common/components/admin/admin.component';
import { JobSettingsComponent } from './common/components/admin/job-settings/job-settings.component';
import { SecurityManagementComponent } from './common/components/admin/security-management/security-management.component';
import { SystemSettingsComponent } from './common/components/admin/system-settings/system-settings.component';
import { TenantManagementComponent } from './common/components/admin/tenant-management/tenant-management.component';
import { UserManagementComponent } from './common/components/admin/user-management/user-management.component';

import { UserProfileComponent } from './common/components/user-profile/user-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

// Zetta Consume
import { ZcHeaderComponent } from './zettaconsume/zc-header/zc-header.component';
import { ZcSidebarComponent } from './zettaconsume/zc-sidebar/zc-sidebar.component';
import { ZcContentComponent } from './zettaconsume/zc-content/zc-content.component';
import { ZcReportsComponent } from './zettaconsume/zc-content/zc-reports/zc-reports.component';
import { ReportDetailComponent } from './zettaconsume/zc-content/zc-reports/report-detail/report-detail.component';
import { JsonFilterPipe } from './common/shared/filters/json-filter';

// Zetta Classify
import { ZsClHeaderComponent } from './zettaclassify/zs-cl-header/zs-cl-header.component';
import { ZsClSidebarComponent } from './zettaclassify/zs-cl-sidebar/zs-cl-sidebar.component';
import { ZsClContentComponent } from './zettaclassify/zs-cl-content/zs-cl-content.component';
import { ZsClHomeComponent } from './zettaclassify/zs-cl-content/zs-cl-home/zs-cl-home.component';
import { ZsClCatalogsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalogs/zs-cl-catalogs.component';
import { ZsClCatalogObjectsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalog-objects/zs-cl-catalog-objects.component';
import { ZsClCatalogObjectConceptsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalog-object-concepts/zs-cl-catalog-object-concepts.component';
import { ZsClCreateCatalogComponent } from './zettaclassify/zs-cl-content/zs-cl-create-catalog/zs-cl-create-catalog.component';
import { DsSelectCatalogsComponent } from './zettaclassify/zs-cl-content/ds-select-catalogs/ds-select-catalogs.component';
import { DsSelectObjectsComponent } from './zettaclassify/zs-cl-content/ds-select-objects/ds-select-objects.component';
import { DsMapColumnsComponent } from './zettaclassify/zs-cl-content/ds-map-columns/ds-map-columns.component';

import { TmReviewTrainingDataComponent } from './zettaclassify/zs-cl-content/tm-review-training-data/tm-review-training-data.component';
import { TmSelectDataSetsComponent } from './zettaclassify/zs-cl-content/tm-select-data-sets/tm-select-data-sets.component';
import { TmMapDataColumnsComponent } from './zettaclassify/zs-cl-content/tm-map-data-columns/tm-map-data-columns.component';

import { SynSelectDataCatalogComponent } from './zettaclassify/zs-cl-content/syn-select-data-catalog/syn-select-data-catalog.component';
import { SynSelectSemanticObjectsComponent } from './zettaclassify/zs-cl-content/syn-select-semantic-objects/syn-select-semantic-objects.component';
import { SynMapConceptsComponent } from './zettaclassify/zs-cl-content/syn-map-concepts/syn-map-concepts.component';

import { ZsClDatasourcesComponent } from './zettaclassify/zs-cl-content/zs-cl-datasources/zs-cl-datasources.component';
import { ZsClDatasetsComponent } from './zettaclassify/zs-cl-content/zs-cl-datasets/zs-cl-datasets.component';
import { ZsClJobsComponent } from './zettaclassify/zs-cl-content/zs-cl-jobs/zs-cl-jobs.component';
import { ZsClAdminComponent } from './zettaclassify/zs-cl-content/zs-cl-admin/zs-cl-admin.component';

import { ZettainsightComponent } from './zettainsight/zettainsight.component';

//ZettaIngest
import { ZsInHeaderComponent } from './zettaingest/zs-in-header/zs-in-header.component';
import { ZsInIngestionComponent } from './zettaingest/zs-in-content/zs-in-ingestion/zs-in-ingestion.component';
import { ZsInTransformationComponent } from './zettaingest/zs-in-content/zs-in-transformation/zs-in-transformation.component';
import { ZsInRedirectionComponent } from './zettaingest/zs-in-content/zs-in-redirection/zs-in-redirection.component';

import { NotificationComponent } from './common/components/notifications/notification.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HelpComponent } from './common/components/help/help.component';
import { UserHelpComponent } from './common/components/help/user-help/user-help.component';
import { UserApiComponent } from './common/components/help/user-api/user-api.component';
import { UserDbComponent } from './common/components/help/user-db/user-db.component';
import {ZsClCatalogObjectConceptRightComponent} from './zettaclassify/zs-cl-content/zs-cl-catalog-object-concept-right/zs-cl-catalog-object-concept-right.component';
import { DefineDatasetComponent } from './common/components/datasets/define-dataset/define-dataset.component';
import { GoldenRecordsDetailsComponent } from './zettasense/content/project/golden-records/golden-records-details/golden-records-details.component';
import { GoldenRecordLineageComponent } from './zettasense/content/project/golden-records/golden-record-lineage/golden-record-lineage.component';
import { GoldenRecordHistoryComponent } from './zettasense/content/project/golden-records/golden-record-history/golden-record-history.component';
import { GoldenRecordRelationshipComponent } from './zettasense/content/project/golden-records/golden-record-relationship/golden-record-relationship.component';
import {BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ZsClViewObjectModelComponent } from './zettaclassify/zs-cl-content/zs-cl-view-object-model/zs-cl-view-object-model.component';
import { ChartsModule } from 'ng2-charts';
import { AdminUserGroup } from './common/components/admin-usergroup/admin-usergroup.component';
import { AdvanceSearchComponent } from './common/components/advance-search/advance-search.component'
import { ZsClAddTrainingDataComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-add-training-data/zs-cl-add-training-data.component';
import { ZsClCreateProjectComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-create-project/zs-cl-create-project.component';
import { ZsClProjectsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-projects/zs-cl-projects.component';
import { ZsClProjectAddUsersComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-add-users/zs-cl-project-add-users.component';
import { ZsClDefineNewClassifreComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-classifire/zs-cl-define-new-classifire.component';


import { ZsClProjectTrainingComponent } from './zettaclassify/zs-cl-content/zs-cl-project-training/zs-cl-project-training.component';
import { ZsClProjectSelectObjectComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-select-object/zs-cl-project-select-object.component';
import { ZsClProjectAddDataComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-add-data/zs-cl-project-add-data.component';
import { ZsClProjectDetailComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-detail/zs-cl-project-details.component';
import { ZsClPdUsersComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-detail/zs-cl-users/zs-cl-users.component';
import { ZsClPdClassifiedComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-detail/zs-cl-pd-classified/zs-cl-pd-classified.component';
import { ZsClViewResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-view-results/zs-cl-view-results.component';
import { ZsClViewResultsParseComponent } from './zettaclassify/zs-cl-content/zs-cl-view-results-parse/zs-cl-view-results-parse.component';
import { ZsClPdTasksComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-detail/zs-cl-tasks/zs-cl-tasks.component';
import { ZsClTasksComponent } from './zettaclassify/zs-cl-content/zs-cl-tasks/zs-cl-tasks.component';
import { ActivityLogsComponent } from './zettasense/activity-logs/activity-logs.component'
import { BussinessRulesComponent } from './common/components/data-quality-rules/bussiness-rules/bussiness-rules.component';
import { OutOfBoxRuleComponent } from './common/components/data-quality-rules/bussiness-rules/out-of-box-rule/out-of-box-rule.component';
import { SelectDatasetComponent } from './common/components/data-quality-rules/bussiness-rules/select-dataset/select-dataset.component';

import { DataQualityRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/data-quality-rules.component';
import { BusinessEntityLevelRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-entity-level-rules.component';
import { TechnicalColumnLevelRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-column-level-rules.component';
import { BusinessRuleDefinitionComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-rule-definition/business-rule-definition.component';
import { BusinessAppliedColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-applied-columns/business-applied-columns.component';
// import { BusinessRunScheduleComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-schedule/business-run-schedule.component';
import { BusinessRunExecutionResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-execution-results/business-run-execution-results.component';
import { MapDataColumnComponent } from './common/components/data-quality-rules/bussiness-rules/map-data-column/map-data-column.component';
import { ReviewPriorMappingsComponent } from './common/components/data-quality-rules/bussiness-rules/review-prior-mappings/review-prior-mappings.component';

import { TechnicalRuleDefinitionComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-rule-definition/technical-rule-definition.component';
import { TechnicalAppliedColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-applied-columns/technical-applied-columns.component';
import { TechnicalRunScheduleComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-run-schedule/technical-run-schedule.component';
import { TechnicalRunExecutionResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-run-execution-results/technical-run-execution-results.component';
import { ExceptionsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-execution-results/exceptions/exceptions.component';
import { TechnicalRulesComponent } from './technical-rules/technical-rules.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ZsClDQManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-dq-management/zs-cl-dq-management.component';
import { ZsClDqRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-dq-management/zs-cl-dq-rules/zs-cl-dq-rules.component';
import { ZsClDqExecutionResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-dq-management/zs-cl-dq-results/zs-cl-dq-execution-results.component';
import { ZsClSemanticLevelDqManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-semantic-level-dq-management/zs-cl-semantic-level-dq-management.component';
import { ZsClCatalogLevelDqManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-catalog-level-dq-management/zs-cl-catalog-level-dq-management.component';
import { BusinessUserEntitlementsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-user-entitlements/business-user-entitlements.component';
import { TechnicalUserEntitlementsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-user-entitlements/technical-user-entitlements.component';
import { SelectConceptComponent } from './common/components/data-quality-rules/bussiness-rules/select-concept/select-concept.component';
import { TextSelectDemoComponent } from './common/components/text-select/text-select-demo.component';
import { TextSelectDirective } from './common/components/text-select/text-select.directive';
import { HighlightDirective } from './common/shared/highlight.directive';
import { ZsClSelectConceptParsedComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/zs-cl-select-concept-parsed/zs-cl-select-concept-parsed.component';
import { ZsClDefinNewSubConceptComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/zs-cl-define-new-sub-concept.component';
import { ZsClObjectClassifier } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/zs-cl-object-classifier/zs-cl-object-classifier.component';
import { ZsClAddReferenceDataComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-add-reference-data/zs-cl-add-reference-data.component';
import { ZsClAddTrainDataConceptParserComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-add-train-data-concept-parser/zs-cl-add-train-data-concept-parser.component';
import { ZsClSelectTrainDatasetsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-select-train-datasets/zs-cl-select-train-datasets.component';
import { ZsClSelectTrainDataColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-select-train-data-columns/zs-cl-select-train-data-columns.component';
import { RegisterdDataSourceComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/registerd-data-source/registerd-data-source.component';
import { DataSetColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/data-set-coulmns/data-set-coulmns.component';
import { SynonymsModelComponent } from './zettaclassify/zs-cl-content/synonyms-model/synonyms-model.component';
import { DqRuleValidationModelComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/dq-rule-validation-model/dq-rule-validation-model.component';
import { ConsumptionReportComponent } from './zettasense/content/consumption-report/consumption-report.component';
import { DataProcessedComponent } from './zettasense/content/consumption-report/data-processed/data-processed.component';
import { RecordsProcessedComponent } from './zettasense/content/consumption-report/records-processed/records-processed.component';
import { QuotaStatusComponent } from './zettasense/content/consumption-report/quota-status/quota-status.component';
import { SourceQualityComponent } from './zettasense/content/consumption-report/source-quality/source-quality.component';
import { TargetQualityComponent } from './zettasense/content/consumption-report/target-quality/target-quality.component';
import { ProjectHomeComponent } from './zettasense/content/project/project-home/project-home.component';
import { DatasetClassificationComponent } from './common/components/datasets/dataset-classification/dataset-classification.component';
import { NameLinkFormatterComponent } from './common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
import { ClassificationTagFormatterComponent } from './common/shared/cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
import { DateFormatterFormatterComponent } from './common/shared/cell-renderer/date-formatter/date-formatter.component';
import { ButtonFormatterComponent } from './common/shared/cell-renderer/button-formatter/button-formatter.component';
import { RuleCountFormatterComponent } from './common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component';
import { MeasuredDQRuleFormatterComponent } from './common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { NoRowOverlayComponent } from './common/components/no-row-overlay/no-row-overlay.component';
import { ActionLinkFormatterComponent } from './common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
  
import { NumberFormatterComponent } from './common/shared/cell-renderer/number-formatter/number-formatter.component'; 
// import { DeleteIconRendererComponent } from './common/shared/cell-renderer/delete-icon-formatter/delete-icon-formatter.component';
// import { CustomCellRenderer } from './common/shared/cell-renderer/custom-cell-render-formatter/custom-cell-render-formatter-component';
// import { CustomDropdownCellRenderer } from './common/shared/cell-renderer/custom-cell-dropdown-formatter/custom-cell-dropdown-formatter-component';
 
// import { AgGridModule } from 'ag-grid-angular';
// import { FsGridComponent } from './common/components/fs-grid/fs-grid.component';
// import { NameLinkFormatterComponent } from './common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
// import { ClassificationTagFormatterComponent } from './common/shared/cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
// import { DateFormatterFormatterComponent } from './common/shared/cell-renderer/date-formatter/date-formatter.component';
// import { ButtonFormatterComponent } from './common/shared/cell-renderer/button-formatter/button-formatter.component';
// import { RuleCountFormatterComponent } from './common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component';
// import { MeasuredDQRuleFormatterComponent } from './common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
// import { NoRowOverlayComponent } from './common/components/no-row-overlay/no-row-overlay.component';
// import { ActionLinkFormatterComponent } from './common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
// import { AgGridModule } from 'ag-grid-angular';
import { FsGridComponent } from './common/components/fs-grid/fs-grid.component';
import { FsDownloadGraphComponent } from './common/components/fs-download-graph/fs-download-graph.component';

// import { LicenseManager } from 'ag-grid-enterprise'
// import 'ag-grid-enterprise';
// import { NumberFormatterComponent } from './common/shared/cell-renderer/number-formatter/number-formatter.component';
// LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")
import { AgGridModule } from 'ag-grid-angular';
// import { FsGridComponent } from './common/components/fs-grid/fs-grid.component';

import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-enterprise';
import { MatherMergerFormatterComponent } from './common/shared/cell-renderer/mather-merger-formatter/mather-merger-formatter.component';
import { AssignmentStatusFormatterComponent } from './common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { NumericFormatterComponent } from './common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
// // import 'ag-grid-enterprise';
import { ConfidenceFormatterComponent } from './common/shared/cell-renderer/confidence-formatter/confidence-formatter.component';
import { ConfidenceLevelFormatterComponent } from './common/shared/cell-renderer/confidence-level-formatter/confidence-level-formatter.component';
import { entityNameFormatterComponent } from './common/shared/cell-renderer/entity-name-formatter/entity-name-formatter.component';
import { ProjectStatusFormatterComponent } from './common/shared/cell-renderer/project-currentStatusType-formattter/project-currentStatusType-formattter.component';
import { CompressionRatioFormatterComponent } from './common/shared/cell-renderer/compression-ratio-formatter/compression-ratio-formatter.component';

import { NoOfDaysFormatterComponent } from './common/shared/cell-renderer/get-noOfDays-fromatter/get-noOfDays-fromatter.component';
import { TaskNameFromatterComponent } from './common/shared/cell-renderer/classify-taskName-formatter/classify-taskName-formatter.component';
import { ConfidenceLevelGoldenFormatterComponent } from './common/shared/cell-renderer/confidence-level-formatter/confidence-level-golden-formatter.component';
import { OptionSelectionRendererComponent } from './common/shared/cell-renderer/option-Selection-formatter/option-Selection-formatter.component';
import { BlueTextFormatterComponent } from './common/shared/cell-renderer/blue-Text-Formatter/blue-Text-Formatter.component';
import { BlueTextRendererComponent } from './common/shared/cell-renderer/bluetext-renderer-formattter/bluetext-renderer-formattter.component';
import { TaskAssignmentRendererComponent } from './common/shared/cell-renderer/task-assignment-formatter/task-assignment-formatter.component';
import { ConfidenceLevelNoEditCellRendererComponent } from './common/shared/cell-renderer/confidence-level-formatter-NoEdit/confidence-level-formatter-NoEdit.component';
import { UserEntilementCheckboxRendererComponent } from './common/shared/cell-renderer/user-entitlements-checkbox-formatter/user-entitlements-checkbox-formatter.component';
import { AdminCheckboxRendererComponent } from './common/shared/cell-renderer/user-entitlements-admin-checkbox-formatter/admin-checkbox-formatter.component';
import { ExecutorCheckboxRendererComponent } from './common/shared/cell-renderer/user-entitlements-admin-checkbox-formatter/executor-checkbox-renderer.component';
import { RightArrowIconTextFormatterComponent } from './common/shared/cell-renderer/right-arrow-text-formatter-component/right-arrow-text-formatter-component';
LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")
import { CatalogRouteGuard } from './common/shared/guards/catalog/catalog-route.guard';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        BreadcrumbComponent,
        LandingComponent,
        ZmSidebarComponent,
        ZmHeaderComponent,
        MainComponent,
        DatasourceHomeComponent,
        CreateDatasourceComponent,
        DefineConnectionComponent,
        AddAdminUsersComponent,
        SetUserEntitlementsComponent,
        DatasourceDetailComponent,
        DatasourceContentsComponent,
        ConnectionDetailComponent,
        DatasourceEntitlementsComponent,
        EditDescriptionComponent,
        DatasetHomeComponent,
        CreateDatasetComponent,
        SelectDataSourceComponent,
        AddDataTransformationComponent,
        ScheduleRefreshComponent,
        ClientProfitsComponent,
        UserEntitlementsComponent,
        DatasetAttributesComponent,
        DatasetRelationShipsComponent,
        DataRefreshComponent,
        DataEntitlementsComponent,
        DataTransformationsComponent,
        RelatedProjectsComponent,
        DataDescriptionComponent,
        DatasetDataQualityComponent,
        AdminComponent,
        JobSettingsComponent,
        SecurityManagementComponent,
        SystemSettingsComponent,
        TenantManagementComponent,
        UserManagementComponent,
        UserProfileComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ZcHeaderComponent,
        ZcSidebarComponent,
        ZcContentComponent,
        ZcReportsComponent,
        ReportDetailComponent,
        JsonFilterPipe,
        ZsClHeaderComponent,
        ZsClSidebarComponent,
        ZsClContentComponent,
        ZsClHomeComponent,
        ZsClCatalogsComponent,
        ZsClCatalogObjectsComponent,
        ZsClCatalogObjectConceptsComponent,
        ZsClCreateCatalogComponent,
        DsSelectCatalogsComponent,
        DsSelectObjectsComponent,
        DsMapColumnsComponent,
        TmReviewTrainingDataComponent,
        TmSelectDataSetsComponent,
        TmMapDataColumnsComponent,
        SynSelectDataCatalogComponent,
        SynSelectSemanticObjectsComponent,
        SynMapConceptsComponent,
        ZsClDatasourcesComponent,
        ZsClDatasetsComponent,
        ZsClCreateProjectComponent, 
        ZsClProjectsComponent, 
        ProjectHomeComponent,
        ZsClProjectAddUsersComponent,
        ZsClProjectSelectObjectComponent,
        ZsClProjectAddDataComponent,
        ZsClProjectTrainingComponent,
        ZsClJobsComponent,
        ZsClAdminComponent,
        ZsInHeaderComponent,
        ZsInIngestionComponent,
        ZsInTransformationComponent,
        ZsInRedirectionComponent,
        NotificationComponent,
        ZettainsightComponent,
        HelpComponent,
        UserHelpComponent,
        UserApiComponent,
        UserDbComponent,
        ZsClCatalogObjectConceptRightComponent,
        DefineDatasetComponent,
        GoldenRecordsDetailsComponent,
        GoldenRecordLineageComponent,
        GoldenRecordHistoryComponent,
        GoldenRecordRelationshipComponent,
        ZsClViewObjectModelComponent,
        AdvanceSearchComponent,
        AdminUserGroup,
        ZsClDefineNewClassifreComponent,                    
        ZsClAddTrainingDataComponent,
        ZsClViewResultsComponent,    
        ZsClViewResultsParseComponent,     
        ZsClProjectDetailComponent,
        ZsClPdUsersComponent,
        ZsClPdClassifiedComponent,          
        ZsClPdTasksComponent,
        ZsClTasksComponent,
        ActivityLogsComponent,
        BussinessRulesComponent,
        OutOfBoxRuleComponent,
        SelectDatasetComponent,
        DataQualityRulesComponent,
        BusinessEntityLevelRulesComponent,
        TechnicalColumnLevelRulesComponent,
        BusinessRuleDefinitionComponent,
        BusinessAppliedColumnsComponent,
        // BusinessRunScheduleComponent,
        BusinessRunExecutionResultsComponent,
        MapDataColumnComponent,    
        ReviewPriorMappingsComponent,   
        TechnicalRuleDefinitionComponent,
        TechnicalAppliedColumnsComponent,
        TechnicalRunScheduleComponent,
        TechnicalRunExecutionResultsComponent,
        ExceptionsComponent,
        TechnicalRulesComponent,
        StyleguideComponent,
        ZsClDQManagementComponent,
        ZsClDqRulesComponent,
        ZsClDqExecutionResultsComponent,
        ZsClSemanticLevelDqManagementComponent,
        ZsClCatalogLevelDqManagementComponent,
        ZsClDQManagementComponent,
        ZsClDqRulesComponent,
        ZsClDqExecutionResultsComponent,
        BusinessUserEntitlementsComponent,
        TechnicalUserEntitlementsComponent,
        StyleguideComponent,
        SelectConceptComponent,        
        HighlightDirective,
        ZsClSelectConceptParsedComponent,
        ZsClDefinNewSubConceptComponent,
        ZsClObjectClassifier,
        ZsClAddReferenceDataComponent,
        ZsClAddTrainDataConceptParserComponent, 
        ZsClSelectTrainDatasetsComponent, 
        ZsClSelectTrainDataColumnsComponent,
        RegisterdDataSourceComponent,
        DataSetColumnsComponent,
        SynonymsModelComponent,
        DqRuleValidationModelComponent,
        ConsumptionReportComponent,
		DataProcessedComponent,
		RecordsProcessedComponent,
		QuotaStatusComponent,		
        SourceQualityComponent,
		TargetQualityComponent,
		DatasetClassificationComponent,  
		NameLinkFormatterComponent,
        UserEntilementCheckboxRendererComponent,
        AdminCheckboxRendererComponent,
        ExecutorCheckboxRendererComponent,
        BlueTextRendererComponent,
        TaskAssignmentRendererComponent,
        BlueTextFormatterComponent,
        RightArrowIconTextFormatterComponent,
        OptionSelectionRendererComponent,
        ClassificationTagFormatterComponent, 
        ButtonFormatterComponent,
        RuleCountFormatterComponent,
        MeasuredDQRuleFormatterComponent,
        ActionLinkFormatterComponent,
        NumberFormatterComponent, 
        entityNameFormatterComponent,
        // DeleteIconRendererComponent,
        // CustomCellRenderer,
        // CustomDropdownCellRenderer 
	
	
		// FsGridComponent,
		// NameLinkFormatterComponent,
        // ClassificationTagFormatterComponent,
        // DateFormatterFormatterComponent,
        // ButtonFormatterComponent,
        // RuleCountFormatterComponent,
        // MeasuredDQRuleFormatterComponent,
        // NoRowOverlayComponent,
        // ActionLinkFormatterComponent,
        // NumberFormatterComponent
		// NameLinkFormatterComponent,
        // ClassificationTagFormatterComponent,
        // DateFormatterFormatterComponent,
        NumericFormatterComponent,
        BlueTextFormatterComponent,
        RightArrowIconTextFormatterComponent,
        // ButtonFormatterComponent,
        // RuleCountFormatterComponent,
        // MeasuredDQRuleFormatterComponent,
        // NoRowOverlayComponent,
        // ActionLinkFormatterComponent,
        // NumberFormatterComponent,
        MatherMergerFormatterComponent,
        // AssignmentStatusFormatterComponent,
		// NameLinkFormatterComponent,
      
        // DateFormatterFormatterComponent,
        // NoRowOverlayComponent,
        // NumberFormatterComponent,
        // ConfidenceFormatterComponent,
        ConfidenceLevelFormatterComponent,
        ConfidenceLevelGoldenFormatterComponent,
        ConfidenceFormatterComponent,
        ConfidenceLevelNoEditCellRendererComponent,
        ConfidenceLevelFormatterComponent,
        ProjectStatusFormatterComponent,
        CompressionRatioFormatterComponent,
        TaskNameFromatterComponent,
        NoOfDaysFormatterComponent,
        FsDownloadGraphComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppSharedModule.forRoot(),
        AppRoutingModule,
        ZettasenseModule,
        ZettameshModule,
        ZettaConsumeModule,
        ZettaClassifyModule,
        ZettaIngestModule,
        ConfirmDialogModule,
        FormsModule,
        PdfViewerModule,
        BsDatepickerModule.forRoot(), 
        ChartsModule, 
        NgMultiSelectDropDownModule.forRoot(),
        MsalModule.forRoot({
            auth: environment.config.AZURE_SOS.auth,
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE, // set to true for IE 11
            }
        },
        {
            popUp: !isIE,
            consentScopes:  environment.config.AZURE_SOS.concentNSecurity.consentScopes,
            // Token will not be sent to the unprotected URIs
            unprotectedResources: environment.config.AZURE_SOS.concentNSecurity.unprotectedResources,
            protectedResourceMap,
            extraQueryParameters: environment.config.AZURE_SOS.concentNSecurity.extraQueryParameters
        }
        )
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    entryComponents: [
        NameLinkFormatterComponent,
        BlueTextRendererComponent,
        TaskAssignmentRendererComponent,
        OptionSelectionRendererComponent,
        BlueTextFormatterComponent,
        RightArrowIconTextFormatterComponent,
        ClassificationTagFormatterComponent, 
        DateFormatterFormatterComponent,
        ButtonFormatterComponent,
        RuleCountFormatterComponent,
        MeasuredDQRuleFormatterComponent,
        NoRowOverlayComponent,
        ActionLinkFormatterComponent,
        entityNameFormatterComponent,
        NumberFormatterComponent,
        UserEntilementCheckboxRendererComponent,
        AdminCheckboxRendererComponent,
        ExecutorCheckboxRendererComponent,
        // DeleteIconRendererComponent,
        // CustomCellRenderer,
        // CustomDropdownCellRenderer

        // NameLinkFormatterComponent,
        // ClassificationTagFormatterComponent, 
        // DateFormatterFormatterComponent,
        // ButtonFormatterComponent,
        // RuleCountFormatterComponent,
        // MeasuredDQRuleFormatterComponent,
        // NoRowOverlayComponent,
        // ActionLinkFormatterComponent,
        // NumberFormatterComponent
        NameLinkFormatterComponent,
        UserEntilementCheckboxRendererComponent,
        AdminCheckboxRendererComponent,
        ExecutorCheckboxRendererComponent,
        OptionSelectionRendererComponent,
        NumericFormatterComponent,
        CompressionRatioFormatterComponent,
        TaskNameFromatterComponent,
        NoOfDaysFormatterComponent,
        MatherMergerFormatterComponent,
        AssignmentStatusFormatterComponent,
        // ConfidenceFormatterComponent,
        ConfidenceLevelFormatterComponent,
        ConfidenceLevelGoldenFormatterComponent,
        ConfidenceFormatterComponent,
        ConfidenceLevelNoEditCellRendererComponent,
        ConfidenceLevelFormatterComponent,
        ProjectStatusFormatterComponent
       
    ],
    bootstrap: [AppComponent],
    providers: [CatalogRouteGuard], 
})
export class AppModule {
}
