import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-zc-content',
  templateUrl: './zc-content.component.html',
  styleUrls: ['./zc-content.component.scss']
})
export class ZcContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
