import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ContentService } from '../../../zettasense/content/content.service';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DatasourceService } from '../../../common/components/datasources/datasource.service';
import { numericFormatter } from '../../../common/shared/formatters/numericFormatter';
import { titleFormatter } from '../../../common/shared/formatters/titleFormatter';
import { projectNameFormattter } from '../../../common/shared/formatters/projectNameFormatter';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { assignmentStatusFormatter } from '../../../common/shared/formatters/assignmentStatusFormatter';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { MessageService } from '../../../common/components/message/message.service';
import { ZsClContentService } from '../zs-cl-content.service';
import { actionsFormatter } from '../../../common/shared/formatters/actionsFormatter';
import { environment } from '../../../../environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { mappingConfidenceFormatter } from 'src/app/common/shared/formatters/mappingConfidenceFormatter';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
declare var $: any;

const zettaUtilsInstance = new ZettaUtils();

const titleDatasourceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  dataContext.name = dataContext.name ? dataContext.name : '';
  dataContext.description = dataContext.description ? dataContext.description : '';
  let description = dataContext.description !== '' ? `<div class="ds-description1 m-0 p-0 text-truncate" title='${dataContext.description}'>${dataContext.description}</div>` : ``;
  
  let isAdmin = false;
  if (dataContext.entitlements) {
    if (dataContext.entitlements.menu.clone && dataContext.entitlements.menu.delete && dataContext.entitlements.menu.edit) {
      isAdmin = true;
    }
  }
  return isAdmin
    ? `<div class="d-flex lh16 m-0 p-0 align-items-center h-100">
            <div class="col-10 m-0 p-0">
              <div class="data-source-name1 text-truncate" title='${dataContext.name}'>${dataContext.name}</div>
              ${description}
            </div>
          </div>`
    :
    `<div class="d-flex lh16 m-0 p-0 align-items-center h-100">
            <div class="col-10 m-0 p-0">
              <div class="data-source-name1 text-truncate disabled-state" title='${dataContext.name}'>${dataContext.name}</div>
              ${description}
            </div>
          </div> `;
};

const imgDatasourceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  function getTitleImage() {
    const hasFontImage = (columnDef.params && columnDef.params['fontImg'] !== undefined) ? true : false;
    const fontClass = columnDef.params['fontImg'];
    return hasFontImage ? `${fontClass}` : ``;
  }
  return `<div class="d-flex lh16 m-0 p-0 align-items-center h-100">
            <div class="col-auto m-0 p-0 pr-2 text-left">
              <i class='${getTitleImage()} item-img'></i>
            </div>
          </div>
        `;
};

const textFormater: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(cell==2 || cell==3){
    return `<div class="Aligner f-size-14 h-100 w-100 text-right">
                  <span class="pointer text-black w-100">${zettaUtilsInstance.commarization(value, 2).toLowerCase()}</span>
            </div>
           `;
  }
}


const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  if(dataContext.isChecked){
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }else{
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }
}
export const TaskNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>`<label title="${value}" class="blueLink text-truncate div-wd-100">${value}</label>`;
@Component({
  selector: 'zetta-zs-cl-home',
  templateUrl: './zs-cl-home.component.html',
  styleUrls: ['./zs-cl-home.component.scss'],
})
export class ZsClHomeComponent implements OnInit, OnDestroy {

  tableDataSource: any = [];
  tasksDataSource: any;
  gridColumnsDef: any;
  taskGridColumnsDef: any;
  gridOptions: any;
  taskGridOptions: any;
  gridObj: any;
  dataviewObj: any;
  _userInfo;
  public taskGrid: AngularGridInstance;
  public datasourceGrid: AngularGridInstance;

  /**
   * Pagination properites
   * @memberof DataSourcesComponent
   */
  isLoading = true;
  total = 0;
  page = 1;
  limit = 20;
  isOpenTaskLoading = true;
  hasScrolled = false;
  noData = false;
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  tableTaskSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  public dataSize = -1;
  selectedClusterId: string;
  taskType: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  selectedProjectId: string;
  public datasources: object = {};
  object_types:any=[];
  selectedRowDataSource;
  triggerFeedbackModal: Boolean = false;
  
  //My Catalogs
  public catalogs: any = [];
	public isLoadingCatalogs = true;
	pageCatalog = 1;
  limitCatalog = 3;
  
  icons = [291, 508, 332];
  measured_rule_score:string;
  measured_rule_css:string;

  public subConceptGrid: AngularGridInstance;
  selectedSubConcept:string;
  isSubConceptLoading=true;  
  selectedSubConceptRow:string;
  selected_record_id:number;
  isSubConcept=false;
  selectedPridiction=[]; 
  subConceptList=[];
  subConceptColumn: object =[];
  selectedColumn:string;
  subConceptResult=[];
  feedbackAdded=false;
  trainModalPayload;
  selected_task_id:number;
  classifier:string;
  trainModalPage = 1;
  msgHeader:string;
  msgText:string;    
  showConfirmBox:boolean;
  hasDeleted:boolean;
  showConceptParserModel=false;
  project_id:number;
  mappedColumntableSettings: object = { 'height': "100%", 'width': "100%" };
  datasetTableSettings: object = { 'height': "100%", 'width': "100%" };
  mappedColumns;
  datasets;
  isLoadingDatasets = false;
  hasDatasetsScrolled = false;
  totalDatasets = 0;
  isFiltering = false;
  mappedColumnList:any;
  hasMaapedColumnScrolled = false;
  public gridMappedColumns: AngularGridInstance;
  gridDatasets: AngularGridInstance;
  isLoadingMappedColumns = false;
  noDataMappedColumns = false;
  totalMappedColumns;
  mappedColumnPageNo=1;
  selectedMappedColumn;
  mappedColumnsTableColDef: any;
  datasetsTableColDef: any;
  conceptName;
  catalogId;
  attributeId;
  objectId;
  conceptId;
  showDownvoteModal=false;
  showDatasetsDownvoteModal = false;
  datasetPage=1;
  datasetId:any;
  datasetColumnId;
  mappingType;
  dsColumnName="";
  removing_attribute_id;
  gridDataOptions: any;
  dsName;
  showmodelpopup=false;
  originalData = [];
  projectType: String = '';
  feedbackChanged = false;
  enableFinishTask: Boolean = false;
  dataRes;
  is_upload_complete = false;
  clearModal: Number;
  enableViewAllMyTasks: Boolean = false;
  classify_project_users = [];
  is_task_completed = false;
  fu_type_tasks;
  _userRole;
  is_reviewer = false;
  is_approver = false;
  is_pending_for_approval = false;
  enableApprove = false;
  enableApproved = false;
  taskReviewerName: string = '';
  taskReviewerDate: string = '';
  task_id: number;
  task_type_id: number;
  summaryHasChanged = false;
  task_type: string;
  task_name: string;
  catalog_name: string;
  entity_name: string;
  current_row: any;
  allowVotingForCatalogTasks: boolean = true;

  constructor(
    public contentSvc: ContentService,
    private datasourceSvc: DatasourceService,
    private catalogSvc: ZsClContentService,
    public service: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) {
    this._userInfo = this.service.getObjectFromSession('userInfo');
  }

  ngOnInit() {    
    this.fu_type_tasks = AppGlobals.FU_TYPE_TASKS;
    this._userRole = this.service.getObjectFromSession('userRole');
    if (this._userRole.role_id === AppGlobals.CLASSIFY_PROJECT_APPROVER) {
      this.is_approver = true;
    }
    if (this._userRole.role_id === AppGlobals.CLASSIFY_PROJECT_REVEIWER) {
      this.is_reviewer = true;
    }
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.gridOptions = {
      enableGridMenu: false,
      enableFiltering: false,
      CheckboxSelector: false,
      enableCellNavigation: false,
      enableRowSelection: false,
      rowHeight: 60,
    };
    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: false,
    };
    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
    this.initColDef();
    this.initTaskColDef();
    this.initMappedColDef();
    this.initDatasetsColDef();
    this.getCatalogs();
    this.loadTasksData(this.page, this.limit);
    this.getDatasources(this.page, this.limit);
    this.getObjectTypes();
    this.checkForAllMyTasks();
  }
  getCatalogs(){
    this.catalogSvc.getCatalogsListPagedAndSearch(this.pageCatalog, this.limitCatalog, '', '', true).subscribe(responseList => {
      this.catalogs = responseList['currentpage'];
      this.catalogs.forEach(element => {
        const ruleObj = this.catalogSvc.getMeasuredRule(element.measured_dq_score, this._userInfo.enable_dq_rule)
        element.dqRuleText = ruleObj["dqRuleText"];
        element.dqRulecss = ruleObj["dqRuleCss"];
      });
      this.isLoadingCatalogs = false;
    }, error => {
      this.isLoadingCatalogs = false;
    });
  }
  

  getDatasources(pageno, limit = 5) {
    this.contentSvc.getDataSources(pageno, limit).subscribe((data) => {
      this.tableDataSource = data;
      this.tableDataSource['currentpage'].forEach((element, index) => {
        element.id = Math.random();
        element.isRuleEnabled = this._userInfo.enable_dq_rule;
        if (element.measured_dq_score == "n/a") {
          element.measured_dq_score = null;
          element['disable_text_center'] = true;
        }
        element['align_text_center'] = true;
        if (element.total_records && element.total_records !== 'n/a') {
          element.total_records = +element.total_records;
        }
      });
      if (this.datasourceGrid) {
        this.datasourceGrid.dataView.setItems(this.tableDataSource['currentpage']);
      }
      this.total = data['totalrecords'];
      this.isLoading = false;
      this.dataSize = Object.keys(data).length;
    }, error => {
      this.isLoading = false;
    });
  }


  redirectToCatalogs(catalog) {
			this._router.navigate(['/zs-cl/catalogs/' + catalog.catalog_id]);
	}

  // getCatalogList() {
  //   // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${user_id}/tenants/${tenant_id}/datasets?paginate=true&pageno=${pageNo}&pagesize=${limit}`;
  //   // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails.user_id}/catalogs`;

  //   this.catalogSvc.getCatalogs(this._userInfo['user_id']).subscribe((data) => {
  //     // console.log(data)
  //     this.catalogs = data;
  //     if (this.catalogs.length === 0) {
  //       this.noData = true;
  //     }
  //     this.catalogs.forEach(element => {
  //       element.id = Math.random();
  //     });
  //     this.isLoading = false;
  //     this.total = this.catalogs.length;
  //     let concatedRows = this.catalogs;
  //     if (this.grid) {
  //       concatedRows = [...this.grid.dataView.getItems(), ...this.catalogs];
  //       this.catalogs = concatedRows;
  //       this.grid.dataView.setItems(concatedRows);
  //       this.grid.gridService.setSelectedRows([]);
  //       this.hasScrolled = false;
  //     }
  //   }, (error) => {
  //     this.isLoading = false;
  //     // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
  //   });
  // }

  initColDef() {
    this.gridColumnsDef = [{
      'displayname': '',
      'physicalname': '',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 45,
      'maxWidth': 55,
      'params': { 'fontImg': 'fas fa-database' },
      'formatter': imgDatasourceFormatter
    }, {
      'displayname': 'Data Source',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 65,
      'formatter': titleDatasourceFormatter
    }, {
      'displayname': 'Datasets',
      'physicalname': 'datasets',
      'sortable': true,
      'headerCssClass': 'text-right pr-3',
      'datatype': 'String',
      'filterable': false,
      'minWidth': 80,
      'maxWidth': 80,
      'formatter': textFormater
    }, {
      'displayname': 'Records',
      'physicalname': 'total_records',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 70,
      'maxWidth': 75,
      'headerCssClass': 'text-right pr-3',
      'formatter': textFormater
    },{
      'displayname': 'Measured DQ',
      'physicalname': 'measured_dq_score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': measuredDQFormatter,
      'headerCssClass': `text-right titleBreak pr-3 ${this._userInfo.enable_dq_rule ? '':'disabled'}`,
      'minWidth': 90,
      'maxWidth': 90
  },];
  }

  initTaskColDef() {
    this.taskGridColumnsDef = [ {
      'displayname': '',
      'physicalname': 'actions',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-right',
      'formatter': actionsFormatter,
      'maxWidth': 12,
    },{
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
      // 'formatter': blueTextFormattter
    }, {
      'displayname': 'Catalog / Project  Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 170,
      'formatter': blueTextFormattter
    }, {
      'displayname': 'Task Name',
      'physicalname': 'task_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 170,
      'formatter': TaskNameFormattter
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    }, {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': dateFormatter
    }];

    this.subConceptColumn = [
      {
          'displayname': '',
          'physicalname': 'radio',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': radioFormatter,
          'minWidth': 30,
          'maxWidth': 30,
      },
      {
        'displayname': 'Sub-Concept',
        'physicalname': 'characteristic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
  ]
  }

  initMappedColDef() {
    this.mappedColumnsTableColDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'minWidth': 70,
    }, {
      'displayname': 'Data Set',
      'physicalname': 'dataset_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'minWidth': 70,
    }, {
      'displayname': 'Data Set Column',
      'physicalname': 'column_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'Data Type',
      'physicalname': 'data_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': mappingConfidenceFormatter,
      'minWidth': 200,
    }];
  }

  initDatasetsColDef() {
    this.datasetsTableColDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'minWidth': 70,
    }, {
      'displayname': 'Data Set',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'blueLink',
      'minWidth': 70,
    }, {
      'displayname': 'Mapping Confidence Level',
      'physicalname': 'score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': mappingConfidenceFormatter,
      'minWidth': 380,
      'maxWidth': 390,
    }];
  }

  loadTasksData(pageno, limit = 1) {
    this.tableTaskSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tenants/${this._userInfo['tenant_id']}/classify/tasks?opentasks=true&pagination=true&pageno=${1}&pagesize=${limit}`;
    this.service.getAllOpenTasks(this._userInfo['user_id'],this._userInfo['tenant_id'], pageno, limit, true).subscribe((data) => {
      this.tasksDataSource = data['currentpage'];

      if (this.tasksDataSource.length === 0) {
        this.noData = true;
      }
      this.isOpenTaskLoading = false;
      this.total = data['totalrecords'];
      let concatedRows = this.tasksDataSource;

      if (this.taskGrid) {
        concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
        this.tasksDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.tasksDataSource = concatedRows;
        this.taskGrid.dataView.setItems(concatedRows);
        this.taskGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isOpenTaskLoading = true;
    });
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  closeModalPopup(event) {    
    if (event) {
      if (this.originalData && this.originalData.length < 1) {
        this.originalData = this.datasets;
      }
      this.triggerFeedbackModal = false;      
      $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      if (this.task_type == AppGlobals.CLASSIFYCATALOGCONCEPTASK) {
        this.mappedColumns.forEach(element => {
          if (element.dataset_column_id === this.current_row.dataset_column_id) {
            element.is_down_vote = true;
          }
        });
      }
      if (this.task_type == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
        this.datasets.forEach(element => {
          if (element.dataset_id === this.current_row.dataset_id) {
            element.is_down_vote = true;
          }
        });
      }
    }
    this.showDownvoteModal = false;
    this.showDatasetsDownvoteModal = false;
    this.checkFinishTaskBtnValidation(this.datasets, true);
    this.selectedRowDataSource = {};
  }

  checkFinishTaskValidation(tasks, enable) {
    if(enable) {
      if(tasks && tasks.length>0) {
        const parent =this;
        parent.enableFinishTask = tasks.every(function(ele) {
          return ele.is_up_vote == true || ele.is_down_vote == true;
            });
      }
    }
  }
  
  updateFeedbackResponse(event) {
    if(event) {
      this.tasksDataSource = [];
      if(this.taskGrid){
        this.taskGrid.dataView.setItems(this.tasksDataSource);
       }
       this.page=1;
      this.isOpenTaskLoading = true;
      this.loadTasksData(this.page, this.limit);
    }
  }
  datasourceGridReady(grid) {
    this.datasourceGrid = grid;
  }

  taskGridReady(grid) {
    this.taskGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadTasksData(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.loadTasksData(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.loadTasksData(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  navigateToClassifyProjectOrCatalogScreen(reference_object_id, task_type, task_type_id, semantic_id?): void{
    if (task_type.toLowerCase() == AppGlobals.CLASSIFICATION_PROJECT.toLowerCase()) {
        this._router.navigate(['/zs-cl/projects', reference_object_id],
        { queryParams: { project_type: task_type, project_type_id: task_type_id } });
        
    } else if (task_type.toLowerCase() == AppGlobals.CLASSIFYCATALOGSEMANTICTASK.toLowerCase() || task_type.toLowerCase() == AppGlobals.CLASSIFYCATALOGCONCEPTASK.toLowerCase()) {
        this._router.navigate(['zs-cl/catalogs',reference_object_id],
        { queryParams: { project_type: task_type, project_type_id: task_type_id } });
    }
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row); 
    
    if (!this.allowVotingForCatalogTasks) {
      return;
    }
    
    if (row !== undefined && row !== null) {
      this.task_id = row.task_id;
      this.task_type_id = row.task_type_id;
      this.enableApprove = false;
      this.enableApproved = false;
      this.getTaskReviewer();
      if (eventEle.target.title === row.name) {
        this.navigateToClassifyProjectOrCatalogScreen(row.reference_object_id, row.task_type, row.task_type_id, row.semantic_id);        
      } else if (eventEle.target.title === row.task_name) {
         this.summaryHasChanged = false;
         this.is_task_completed = false;   
        if(row.task_type_id === AppGlobals.CLASSIFY_PROJECT_TASK_TYPE && row.project_type_id === AppGlobals.CONCEPT_PARSER){
         this.showConceptParserModel =true;
         this.selected_record_id =row.record_id;
         this.selected_task_id = row.task_id;
         this.selectedColumn=row.task_name;
         this.project_id = row.reference_object_id;
         this.getPridictionList();
      } else if (row.task_type == AppGlobals.CLASSIFYCATALOGCONCEPTASK) { // should trigger mapped columns modal with 5 cols
         sessionStorage.removeItem('serverfilter');
         sessionStorage.removeItem('sortfilter');
         this.mappedColumns = [];
         this.isLoadingMappedColumns = true;
         this.conceptName = row.task_name;
         this.catalogId = row.catalog_id;
         this.conceptId = row.attribute_id;
         this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
         this.projectType = row.task_type;
         this.task_type = row.task_type;
         this.task_name = row.task_name;
         this.catalog_name = row.name;
         this.entity_name = row.entity_name;
         if(row.task_status == AppGlobals.TASK_PENDING_APPROVAL || row.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          this.is_pending_for_approval = true;
        }
        let shouldDisableTskvoting = this.shouldDisableCatalogTask(row.task_status, row.assigned_to);
        this.allowVotingForCatalogTasks = !shouldDisableTskvoting;
         this.getMappedColumns(1, this.limit, this.shouldDisableCatalogTask(row.task_status, row.assigned_to));
         $('#mapped_columns_modal').modal('show');
      } else if (row.task_type == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) { // should trigger datasets modal with 3 cols
         sessionStorage.removeItem('serverfilter');
         sessionStorage.removeItem('sortfilter');
         this.datasets = [];
         this.isLoadingDatasets = true;
         this.conceptName = row.task_name;
         this.catalogId = row.catalog_id;
         this.conceptId = row.attribute_id;
         this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
         this.projectType = row.task_type;
         this.task_type = row.task_type;
         this.task_name = row.task_name;
         this.catalog_name = row.name;
         if(row.task_status == AppGlobals.TASK_PENDING_APPROVAL || row.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          this.is_pending_for_approval = true;
        }
        let shouldDisableTskvoting = this.shouldDisableCatalogTask(row.task_status, row.assigned_to);
        this.allowVotingForCatalogTasks = !shouldDisableTskvoting;
         this.getDatasets(row.semantic_id,this.page, this.limit, this.shouldDisableCatalogTask(row.task_status, row.assigned_to));
         $('#datasets_modal').modal('show');
        } else {
          this.is_task_completed = false;
          this.getClassifyProject(row);
        }    
      }
    }
  }  
  getClassifyProject(row) {
    const data = {
      user_id: this._userInfo['user_id'],
      tenant_id: this._userInfo['tenant_id'],
      project_id: row.reference_object_id
    };
    this.catalogSvc.getclassifyProject(data).subscribe(responseList => {
      this.classify_project_users = responseList.users;
      let userDetails = this.classify_project_users.find(user => user.user_id === this._userInfo['user_id']);
      if (userDetails) {
        const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        if (row.task_status === AppGlobals.TASK_COMPLETED) {
          this.is_task_completed = true;
        }
        if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.is_task_completed = true;
        }
      }

      this.selectedClusterId = row.cluster_id;
      this.selectedProjectId = row.reference_object_id + '';
      this.taskType = row.task_type;
      this.selectedTaskName = row.task_name;
      this.selectedTaskType = row.task_type;
      this.selectedTaskStatus = row.task_status;
      this.selectedRowDataSource = row;
      this.triggerFeedbackModal = true;
    }, err => {
    });
  }
  onDataSourceGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.datasourceGrid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/datasources', row.datasource_id]);
      }
    }
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  getObjectTypes() {
    this.service.getObjectTypes('ObjectTypeId').subscribe(resp => {
      if (resp.length) {
        this.object_types = resp;
        const project_type_id = this.object_types.filter(item => item.name == 'Project')[0].lookup_id;
        const dataset_type_id = this.object_types.filter(item => item.name == 'Dataset')[0].lookup_id;
        const classify_type_id = this.object_types.filter(item => item.name == 'Classify')[0].lookup_id;
        sessionStorage.setItem('project_type_id', project_type_id);
        sessionStorage.setItem('dataset_type_id', dataset_type_id);
        sessionStorage.setItem('classify_type_id',classify_type_id);
      }
    });
  } 

  getPridictionList() {
    this.catalogSvc.getPredictionList(this.project_id, this.selected_record_id).subscribe(resp => {
      this.classifier = resp['classifier'];
      ($('#classification_tag_model') as any).modal('show');
    }, error => {
      ($('#classification_tag_model') as any).modal('show');
      this.classifier = "";
    });
  }
  showSubConceptModal(event) {
    if (event.isSubConcept) {
      this.isSubConceptLoading = true
      this.isSubConcept = event.isSubConcept;
      this.selectedPridiction = event.data;
    }
  }

  closeFeedbackModel(event) {
    if (event) {
      // this.tasksDataSource=[];
      //if(this.taskGrid){
      //this.taskGrid.dataView.setItems(this.tasksDataSource);
      // }
      //this.page=1;
      //this.loadTasksData(this.page, this.limit);     
    }
    this.showConceptParserModel = false;
  }

  closeSubConceptModel(data) {
    this.isSubConcept = false;
  }
  clearText(event) {
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }
  onDeletePrediction(data) {
    this.msgHeader = data.messageHeader;
    this.msgText = data.messageText;
    this.showConfirmBox = true;
  }

  onConfirmDelete(event) {
    this.hasDeleted = event;
  }


  addSubConcept(event) {
    this.feedbackAdded = event;
  }

      onMappedColumnsGridCreation(grid) {
        this.gridMappedColumns = grid;
      }

      getDatasets(objectId,page, limit, shouldDisableFeedback?) {
        this.objectId=objectId;
        this.isLoadingDatasets = true;
        this.datasetTableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/datasets?pagination=true&pageno=${1}&pagesize=${limit}`;
        var serverfilter = "";
        if(sessionStorage.getItem("serverfilter")){
          var serverfilter = sessionStorage.getItem("serverfilter");
          this.isFiltering = true;
        } else {
          if(this.isFiltering){ 
            page = 1;
          }
          this.isFiltering = false;
        }
       let sortfilter="";
       if(sessionStorage.getItem("sortfilter")){
          sortfilter=sessionStorage.getItem("sortfilter");
       } 
        const getDataset: any = {
            user_id: this._userInfo['user_id'],
            catalog_id: this.catalogId,
            object_id: this.objectId,
            page:page,
            limit:limit
          };
          this.catalogSvc.getDatasets(getDataset,serverfilter,sortfilter).subscribe((data) => {
              this.datasets = data['currentpage'];
              if (this.datasets.length === 0) {
                this.noData = true;
              }
              this.datasets.forEach(element => {
                  element.id = Math.random();
                  element.score100 = (element.score * 100).toFixed();
                  element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
                  element.shouldDisableFeedback = shouldDisableFeedback;
                  if(this.is_reviewer) {
                    element.is_up_vote = false;
                    element.is_down_vote = false;
                  }
              });
              this.datasets.sort(((a, b) => b.score100 - a.score100));
              this.totalDatasets = data['totalrecords'];
              this.isLoadingDatasets = false;
              let concatedRows = this.datasets;
              if (this.gridDatasets && !shouldDisableFeedback) {
                concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
                 this.datasets = concatedRows;
                this.gridDatasets.dataView.setItems(this.datasets);
                this.gridDatasets.gridService.setSelectedRows([]);
                this.hasDatasetsScrolled = false;
              }
              this.checkFinishTaskBtnValidation(this.datasets, true,shouldDisableFeedback);
          }, err => {
            this.isLoadingDatasets = false;
           });
        }
  

  getMappedColumns(pageNo, limit, shouldDisableFeedback?) {
    this.mappedColumntableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/concepts/${this.conceptId}/mappedcolumns?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.isLoadingMappedColumns = true;
    this.catalogSvc.getConceptsDatasets({ user_id: this._userInfo['user_id'], catalog_id: this.catalogId, object_id: this.objectId, concept_id: this.conceptId, pageNo:pageNo, limit: limit }, serverFilter, sortfilter).subscribe((data) => {
          this.mappedColumns = data['currentpage'];
          this.mappedColumnList =data;
          if (this.mappedColumns.length === 0) {
            this.noDataMappedColumns = true;
          }
          this.totalMappedColumns = data.totalrecords;
          this.isLoadingMappedColumns = false;
          this.mappedColumns.forEach(element => {
              element.id = Math.random();
              element.score100 = (element.score * 100).toFixed();
              element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
              element.shouldDisableFeedback = shouldDisableFeedback
            if(this.is_reviewer) {
              element.is_up_vote = false;
              element.is_down_vote = false;
            }
          });
          this.mappedColumns.sort(((a, b) => b.score100 - a.score100));                  
          let concatedRows = this.mappedColumns;
        if (this.gridMappedColumns) {
          concatedRows = [...this.gridMappedColumns.dataView.getItems(), ...this.mappedColumns];
          this.mappedColumns = concatedRows;
          this.gridMappedColumns.dataView.setItems(concatedRows);
          this.gridMappedColumns.gridService.setSelectedRows([]);
          this.hasMaapedColumnScrolled = false;
        }
        this.checkFinishTaskBtnValidation(this.mappedColumns, true, shouldDisableFeedback);
      }, err => {
        this.isLoadingMappedColumns = false;
          this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
      });
}

onMappedColumnsCellClick(e): void {
  const eventEle = e.eventData;
  const args = e.args;
  const row = this.gridMappedColumns.gridService.getDataItemByRowIndex(args.row);
  const metadata = this.gridMappedColumns.gridService.getColumnFromEventArguments(args);
  const fieldName = metadata.columnDef.field;
  this.selectedMappedColumn = args.row;
  this.current_row = row;

  if (!this.allowVotingForCatalogTasks) {
    return;
  }
  

    if (fieldName === "score") {
      if (eventEle.target.className.includes("fa-thumbs-down")) {
        this.dsColumnName = row.column_name;
        this.datasetId = row.dataset_id;
        this.datasetColumnId = row.dataset_column_id;
        this.mappingType = row.mapping_type === undefined ? "manual" : row.mapping_type;
        this.showDownvoteModal = true;
        this.removing_attribute_id = row.attribute_id;
      }
      const payload: any = {
        dataset_id: +row.dataset_id,
        dataset_column_id: +row.dataset_column_id,
        removing_attribute_id: +row.attribute_id,
        attribute_id: +row.attribute_id,
        mapping_type: row.mapping_type === undefined ? "manual" : row.mapping_type,
        entity_id: +this.objectId,
        catalog_id: +this.catalogId,
      };
      if (eventEle.target.className.includes("fal fa-thumbs-up")) {
        $("#thumbs-up-" + args.row).removeClass("fal fa-thumbs-up");
        $("#thumbs-up-" + args.row).addClass("fas fa-thumbs-up");
        $("#thumbs-down-" + args.row).removeClass("fas fa-thumbs-down");
        $("#thumbs-down-" + args.row).addClass("fal fa-thumbs-down");
        if(!this.is_pending_for_approval) {
          this.enableFinishTask = true;
        };
        this.upVote(payload);
      } else if (eventEle.target.className.includes("fas fa-thumbs-up")) {
        $("#thumbs-up-" + args.row).removeClass("fas fa-thumbs-up");
        $("#thumbs-up-" + args.row).addClass("fal fa-thumbs-up");
        this.upVote(payload);
      }
    }
}

onDatasetsGridCreation(grid) {
  const parent = this;
  parent.gridDatasets = grid;
  parent.gridDatasets.slickGrid.onSort.subscribe((e, args) => {
    this.page = 1;
    this.hasScrolled = false;
  });
}

onDatasetCellClick(e): void {        
  const eventEle = e.eventData;
  const args = e.args;
  const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
  this.selectedMappedColumn = args.row;
  if (row !== undefined && row !== null) {
    this.current_row = row;
    if (eventEle.target.title === row.name) {
      this.dsName = row.name;           
      this.showmodelpopup=true;
      this.datasetId=row.dataset_id;
    } else if(eventEle.target.className =='fal fa-thumbs-down handDown' || eventEle.target.className =='handDown fal fa-thumbs-down'){
      this.showDatasetsDownvoteModal = true;
      this.datasetColumnId =row.dataset_type_id;
      this.datasetId=row.dataset_id;
    } else if(eventEle.target.className =='fal fa-thumbs-up'){
          const upVotedata ={
            "dataset_id": row.dataset_id,
            "mapping_type": "manual",
            "entity_id": this.objectId,
            "catalog_id": this.catalogId,
            "is_semantic_level_mapping": true
          };
          const payload = {
            user_id: this._userInfo['user_id'],
            tenant_id : this._userInfo['tenant_id'],
            payload:upVotedata
          };
          this.catalogSvc.saveVoteSemanticObject(payload,'up_vote').subscribe(resp =>{
              $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
              $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
              $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              this.datasets.forEach(element => {
                if (element.dataset_id === row.dataset_id) {
                  element.is_up_vote = true;
                }
              });
              if(this.is_approver && this.is_pending_for_approval) {
                this.enableApprove = true;
              } else {
                this.enableFinishTask = true;
              }
          }, error=>{
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
          });
    }
  }
}

clearDatasets() {
  this.gridDatasets.dataView.setItems([]);
}

upVote(payload) {
  const getRow: any = {
    user_id: this._userInfo['user_id'],
    tenant_id: this._userInfo['tenant_id'],
    payload: payload
  };
  this.catalogSvc.upVote(getRow).subscribe((data) => {
    this.mappedColumns.forEach(element => {
      if (element.dataset_column_id === this.current_row.dataset_column_id) {
        element.is_up_vote = true;
      }
    });
  }, err => { });
}


onMappedColumnsScroll(parentClass) {
  $('.basic-tooltip').hide();
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasMaapedColumnScrolled && !this.noData) {
    this.hasMaapedColumnScrolled = true;    
    this.onMappedColumnNextPage();
  }
}

onMappedColumnNextPage(): void {
  if(this.mappedColumnPageNo < this.mappedColumnList.totalpages){
    this.mappedColumnPageNo++;
    this.getMappedColumns(this.mappedColumnPageNo, this.limit);
  }
}

receiveTotalRecords($event) {
  this.totalMappedColumns=$event;      
}

clearMappedColumns() {
  this.showDownvoteModal = false;
  this.showDatasetsDownvoteModal = false;
  if (this.gridMappedColumns) {
    this.mappedColumns = [];
    this.gridMappedColumns.dataView.setItems([]);
  }
  if (this.gridDatasets) {
    this.datasets = [];
    this.gridDatasets.dataView.setItems([]);
  }  
  this.enableFinishTask = false;
  this.enableApproved = false;
  this.enableApprove = true;
  $('#mapped_columns_modal').modal('hide');
  $('#datasets_modal').modal('hide');
}

onScrollD(parentClass) {
  if (sessionStorage.getItem("serverfilter")) {
    this.isFiltering = true;
    this.noData = false;
  } else {
    if (this.isFiltering) {
      this.page = 1;
      this.isFiltering = false;
      this.noData = false;
    }
  }
  if(this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasetsScrolled && this.page < this.total){
    this.hasDatasetsScrolled = true;
    this.onNextDs();
  }
}

shouldDisableCatalogTask(taskStatus, assignedTo): boolean {
  let loggedInUserDetails = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;
  if (taskStatus === AppGlobals.TASK_COMPLETED) {
    return true;
  }
  
  if (taskStatus === AppGlobals.TASK_REVIEWED_STATUS) {
    return true;
  }

  if (taskStatus === AppGlobals.TASK_IN_REVIEW) {
    return false;
  }

  if (taskStatus === AppGlobals.TASK_PENDING_FOR_APPROVAL || taskStatus === AppGlobals.TASK_PENDING_APPROVAL) {
    if (loggedInUserDetails && loggedInUserDetails.user_id && loggedInUserDetails.user_id === assignedTo) {
      return false;
    }
    return true;
  }
  
  return false;
}

onNextDs(): void {
  this.datasetPage++;
  this.getDatasets(this.objectId,this.datasetPage, this.limit);
}

popupoutput(message){
  this.showmodelpopup = message;   
}

finishAllTasks() {
  if(this.projectType == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
    const votesPayload = this.datasets.map(data => {
      if(data.is_up_vote) {
        return {
            "dataset_id": data.dataset_id,
            "mapping_type": "manual",
            "entity_id": this.objectId,
            "catalog_id": this.catalogId,
            "is_semantic_level_mapping": true,
            "type":"up_vote"
        };
      } else {
        return {
          "dataset_id": data.dataset_id,
          "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
          "entity_id": this.objectId,
          "catalog_id": this.catalogId,
          "is_semantic_level_mapping": true,
          "removing_entity_id":this.objectId,
          "type":"down_vote"
        };
      }
    });
    $('#mapped_columns_modal').modal('hide');
    $('#datasets_modal').modal('hide');
    // this.catalogSvc.bulkVoteUpdate(this._userInfo, votesPayload).subscribe(res => {});
  } else if(this.projectType == AppGlobals.CLASSIFYCATALOGCONCEPTASK){
    const votesPayload = this.mappedColumns.map(data => {
      if(data.is_up_vote) {
        return {
          "dataset_id": data.dataset_id,
          "dataset_column_id": data.dataset_column_id,
          "removing_attribute_id": data.attribute_id,
          "attribute_id": data.attribute_id,
          "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
          "entity_id": this.objectId,
          "catalog_id": this.catalogId,
          "type":"up_vote"
        };
      } else {
        return {
          "dataset_id": data.dataset_id,
          "dataset_column_id": data.dataset_column_id,
          "removing_attribute_id": +this.attributeId, 
          "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
          "entity_id": +this.objectId,
          "catalog_id": +this.catalogId,
          "type": 'down_vote'
        };
      }
    });
    $('#datasets_modal').modal('hide');
    $('#mapped_columns_modal').modal('hide');
    // As discussed from API team commenting bulk feedback since individual feedback call is being made
    // this.catalogSvc.bulkVoteUpdate(this._userInfo, votesPayload).subscribe(res => {
    // });
  }
}

onExportMappedColumn() {
  this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/concepts/${this.conceptId}/mappedcolumns?export=true`).subscribe(resp => {
  this.catalogSvc.exportCsvFiles(resp, 'DiscoveredMappedDataColumnsReport');
      }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
}

onExport() {
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/datasets?export=true`).subscribe(resp => {
    this.catalogSvc.exportCsvFiles(resp, 'DataSetsReport_forSemanticObject');
   }, error => {
    this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  receiveFileName($event) {
    this.dataRes = $event;
    this.is_upload_complete = this.dataRes.is_upload_complete;
      if(this.is_upload_complete) {
        this.taskGrid.dataView.setItems([]);
        this.taskGrid.gridService.setSelectedRows([]);
        this.loadTasksData(1, this.limit);
      }
  }
  
  uploadTasks() {
    this.clearModal = Math.random();
  }
  
  cancelUpload() {
    this.clearModal = Math.random();
    $('#uploadFileModal').modal('hide');
  }

  checkForAllMyTasks() {
    this.service.getAllOpenTasks(this._userInfo['user_id'], this._userInfo['tenant_id'], this.page, this.limit, false)
    .subscribe(res => {
      if(res) {
        this.enableViewAllMyTasks = res['currentpage'] && res['currentpage'].length>0;
      }
    });
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }

  checkFinishTaskBtnValidation(tasks, enable, shouldDisableFinishButton?) {

    if(shouldDisableFinishButton) {
      return this.enableFinishTask = false;
    }

    if(enable) {
      if(tasks && tasks.length > 0) {
        let at_least_one_vote = tasks.filter(task => task.is_up_vote == true || task.is_down_vote == true);
        if(this.is_approver && this.is_pending_for_approval) {
          this.enableApprove = at_least_one_vote.length > 0 ? true : false;
        } else {
          this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
        }
      }
    }
  }
  approveBtn() {
    this.enableApprove = false;
    this.enableApproved = true;
    this.enableFinishTask = true;
  }
  getTaskReviewer() {
    this.catalogSvc.taskReviewer(this._userInfo['user_id'], this._userInfo['tenant_id'], this.task_id, this.task_type_id).subscribe(resp => {
      if (resp.length) {
        this.taskReviewerName = resp[0].first_name;
        this.taskReviewerDate = resp[0].created_ts;
      } else {
        this.taskReviewerName = resp.name;
        this.taskReviewerDate = resp.date;
      }      
    }, error => {
    });
  }
  finishTasks() {
      const data = {
        "task_id": this.task_id,
      };
      const payload = {
        user_id: this._userInfo['user_id'],
        catalog_id : this.catalogId,
        payload: data,
      };
      this.catalogSvc.finishTaskOfCatalog(payload).subscribe(resp => {
        this.hideFeedbackModal();
        this.isOpenTaskLoading = true;
        if (this.taskGrid) {
          this.tasksDataSource = [];
          this.taskGrid.dataView.setItems([]);
          this.taskGrid.gridService.setSelectedRows([]);
        }
        this.loadTasksData(1, this.limit);
      }, error => {
        this.hideFeedbackModal();
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
       });
    
  }

  hideFeedbackModal() {
    if (this.projectType == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
      $('#mapped_columns_modal').modal('hide');
      $('#datasets_modal').modal('hide');
    } else if (this.projectType == AppGlobals.CLASSIFYCATALOGCONCEPTASK) {
      $('#datasets_modal').modal('hide');
      $('#mapped_columns_modal').modal('hide');
    }
  }
}
