import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ZsClContentService } from '../../../../zettaclassify/zs-cl-content/zs-cl-content.service';
import { MessageService } from '../../../components/message/message.service';
import { AppGlobals } from '../../app.globals';

@Injectable({
  providedIn: 'root'
})
export class CatalogRouteGuard implements CanLoad, CanActivate, CanActivateChild{

  constructor(private router: Router, private catalogService: ZsClContentService, private messageSvc: MessageService) {

  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

    return this.getCatalogInfo(route.params.catalog_id, loggedInUserDetails.tenant_id)
    .then((isRouteAllowed) => {
      if (isRouteAllowed) {
        return true;
      } else {
        this.showErrorDialogMessage();
        return false;
      }
    })
    .catch((err) => {
      // If API rejected as access denied show error popup with access denied message
      if (err && err.status && err.status === 403) {
        this.showErrorDialogMessage();
        return false;
      } 
      // If API throws error due to other API error, show generic error message
      this.showGenericErrorMessage();
      return false;
    })
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return false;
  }


  getCatalogInfo(catalogId, loggedInUsertenantId): Promise<boolean> {
    
    return new Promise((resolve,reject) => {
        let isBasicInfoNeeded = true;
        this.catalogService.getCatalog(catalogId, isBasicInfoNeeded).subscribe((catalogInfo) => {
            //After getting response, checking if catalog belongs to the same tenant as logged in user. 
            if (catalogInfo && catalogInfo.tenant_id === loggedInUsertenantId) {
              return resolve(true);
            } else {
              return resolve(false);
            }

        }, (err) => {
            reject(err);
        })
    });
  }

  showErrorDialogMessage() {
    this.messageSvc.sendMessage({ message: AppGlobals.ACCESS_DENIED_MESSAGE, type: 'ERROR', hideboard: true });
  }
  showGenericErrorMessage() {
    this.messageSvc.sendMessage({ message: 'Something went wrong', type: 'ERROR', hideboard: true });
  }
}
