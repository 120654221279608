import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZmAppSharedModule } from '../common/zm-shared/zm-app-shared.module';
import { ZmHomeComponent } from './zm-home/zm-home.component';
import { ZmContentModule } from './zm-content/zm-content.module';
import { ZmContentService } from './zm-content/zm-content.service';
import { ZmContentComponent } from './zm-content/zm-content.component';
import { AnalyticsHomeComponent } from './zm-content/zm-analytics/analytics-home/analytics-home.component';
import { CreateAnalyticComponent } from './zm-content/zm-analytics/create-analytic/create-analytic.component';
import { MyAnalyticsComponent } from './zm-content/zm-analytics/my-analytics/my-analytics.component';
import { ZmProjectProgressComponent } from './zm-content/zm-project-progress/zm-project-progress.component';
import { ZmUsersComponent } from './zm-content/zm-users/zm-users.component';
import { AddTrainingComponent } from './zm-content/zm-analytics/create-analytic/add-training/add-training.component';
import { AddPredictionComponent } from './zm-content/zm-analytics/create-analytic/add-prediction/add-prediction.component';
import { DefineOutputsComponent } from './zm-content/zm-analytics/create-analytic/define-outputs/define-outputs.component';
import { DefineThresholdsComponent } from './zm-content/zm-analytics/create-analytic/define-thresholds/define-thresholds.component';
import { DefineInputsComponent } from './zm-content/zm-analytics/create-analytic/define-inputs/define-inputs.component';
import { DefineSegComponent } from './zm-content/zm-analytics/create-analytic/define-segments/define-segments.component';
import { ScheduleModelComponent } from './zm-content/zm-analytics/create-analytic/schedule-model/schedule-model.component';
import { AnalyticDetailComponent } from './zm-content/zm-analytics/analytic-detail/analytic-detail.component';
      import { AdDataComponent } from './zm-content/zm-analytics/analytic-detail/ad-data/ad-data.component';
      import { AdUsersComponent } from './zm-content/zm-analytics/analytic-detail/ad-users/ad-users.component';
      import { AdScheduleComponent } from './zm-content/zm-analytics/analytic-detail/ad-schedule/ad-schedule.component';
      import { AdModelResultsComponent } from './zm-content/zm-analytics/analytic-detail/ad-model-results/ad-model-results.component';
      import { AdViewMostSignificantAttributeComponent } from './zm-content/zm-analytics/analytic-detail/ad-view-most-significant-attribute/ad-view-most-significant-attribute.component';
      import { AdViewHighestImpactAttributesComponent } from './zm-content/zm-analytics/analytic-detail/ad-view-highest-impact-attributes/ad-view-highest-impact-attributes.component';
      import { AdViewPredictionModelAccuracyComponent } from './zm-content/zm-analytics/analytic-detail/ad-view-prediction-model-accuracy/ad-view-prediction-model-accuracy.component';
      import { AdViewPredictionsComponent } from './zm-content/zm-analytics/analytic-detail/ad-view-predictions/ad-view-predictions.component';

@NgModule({
  declarations: [
    ZmHomeComponent,
    ZmContentComponent,
    AnalyticsHomeComponent,
    CreateAnalyticComponent,
    MyAnalyticsComponent,
    ZmProjectProgressComponent,
    ZmUsersComponent,
    AddTrainingComponent,
    AddPredictionComponent,
    DefineOutputsComponent,
    DefineThresholdsComponent,
    DefineInputsComponent,
    DefineSegComponent,
    ScheduleModelComponent,
    AnalyticDetailComponent,
        AdDataComponent,
        AdUsersComponent,
        AdScheduleComponent,
        AdModelResultsComponent,
        AdViewMostSignificantAttributeComponent,
        AdViewHighestImpactAttributesComponent,
        AdViewPredictionModelAccuracyComponent,
        AdViewPredictionsComponent, 
  ],
  imports: [
    CommonModule,
    ZmContentModule,
    ZmAppSharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [ZmContentService]
})
export class ZettameshModule { }
