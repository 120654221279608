import { Component, OnInit, ViewContainerRef, OnDestroy  } from '@angular/core';
import { ProjectService } from '../project/project.service';
import { numericFormatter } from '../../../common/shared/formatters/numericFormatter';
import { assignmentStatusFormatter } from '../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project/project.model';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../common/components/message/message.service';
import { matherMergerFormatter } from '../../../common/shared/formatters/matherMergerFormatter';
import { projectNameFormattter } from '../../../common/shared/formatters/projectNameFormatter';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { BaseService } from 'src/app/common/services/base-service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { environment } from 'src/environments/environment';

const zettaUtilsInstance = new ZettaUtils();

const daysFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="text-center w-100" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
}

const aprooveerCheckBoxFormattter: Formatter = (row: number, cell: number, value: any,columnDef: Column, dataContext: any) => {  
  const checkCond = dataContext.roles.includes(AppGlobals.RESOLVE_PROJECT_APPROVER);
  if(checkCond) {
    let checkCond = dataContext.isApprover ? 'checked' : 'unchecked';
    return   `<label class="checkbox-container ml-3 pr-5">
    <input type="checkbox" ${checkCond}>
    <span class="checkmark" style="border-radius: 2px; border: 1px solid #D1CFCF;height:15px; width: 15px; background-color: #fff;"></span>
  </label>`;
  } else {
    return `<label class="ml-3 mr-top-7 pr-5 check-cell">
    <input type="checkbox" disabled>
  </label>`
}
}

const revieweCheckBoxFormattter: Formatter = (row: number, cell: number, value: any,columnDef: Column, dataContext: any) => {
  const checkCond = dataContext.roles.includes(AppGlobals.RESOLVE_PROJECT_REVIEWER);
  if(checkCond) {
    let checkCond = dataContext.isReviewer ? 'checked' : 'unchecked';
    return   `<label class="checkbox-container ml-3 pr-5">
    <input type="checkbox" ${checkCond}>
    <span class="checkmark" style="border-radius: 2px; border: 1px solid #D1CFCF;height:15px; width: 15px; background-color: #fff;"></span>
  </label>`;
  } else {
    return `<label class="ml-3 mr-top-7 pr-5 check-cell">
    <input type="checkbox" disabled>
  </label>`
  }
}

const disabledCheckBoxFormatter: Formatter = (row: number, cell: number, value: any,columnDef: Column, dataContext: any) => {  
  return   `<label class="ml-3 mr-top-7 pr-5 check-cell">
  <input type="checkbox" disabled>
</label>`;
}

@Component({
  selector: 'zetta-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {

  public loggedInUserDetails = null;
  public selectedCluster;
  taskGridOptions: any;
  taskGridColumnsDef: any;
  tasksDataSource: any[];
  isTasksLoading = true;
  hasScrolled = false;
  noData = false;
  public taskGrid: AngularGridInstance;
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  selectedRowDataSource;
  taskType: string;
  isFiltering = false;
  isSorting = false;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  public userTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectDetail = new Project();
  total = 0;
  page = 1;
  limit = 100;
  hasLeftUsersSelected: boolean = false;
  viewTaskAssignments: boolean = false;
  rightTasksDataSource: any;
  rightGridColumnsDef: any;
  rightTaskGridOptions: any;
  rightTasksGrid: AngularGridInstance;
  selectedRow: any;
  assignType: string = 'resolveTask';
  taskAssignDetails = {};
  selectedProjects = [];
  validatedSelectedProjects: boolean = false;
  project_users = [];
  shouldDisableFeedback = false;
  allTasksGridState = {
    sort: null,
    search: ''
  }
  openMatcherFeedbackModal: boolean = false;
  openMergerFeedbackModal: boolean = false;
  prouductionTaskSelected: boolean = false;
  isProjectDataLoaded: boolean = false;
  isProjectFourEyeCheckOn: boolean = false;
  shouldDisableMatchFixingTasks: boolean = true;
  shouldMoveMatchFixingData: boolean = false;
  selectedJobId: string;

  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router, public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private observableSvc: BaseService,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');

    this.taskGridColumnsDef = [
      {
        'displayname': 'Id',
        'physicalname': 'task_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100,
      }, {
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Project Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 170,
      'params': { 'isDisplayDesc': false, 'applyWidth': true,  isResolveTask: true },
      'formatter': projectNameFormattter
    }, {
      'displayname': 'Task Name',
      'physicalname': 'taskname',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 170,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Assigned To',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    },
    {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'string',
      'filterable': true,
      'formatter': dateFormatter
    }];

    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: true,
      rowHeight: 34
    };

    this.rightTaskGridOptions = {
      enableGridMenu: false,
      selectable: false,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: false,
      CheckboxSelector: false,
      rowHeight: 34,
      multiSelectable: false,
    };

        this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 50,
      'params': { 'isDisplayDesc': false },
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'reviewer',
      'sortable': true,
      'filterable': true,
      'minWidth': 10,
      'formatter': revieweCheckBoxFormattter
    },
    {
      'displayname': 'Approver',
      'physicalname': 'approver',
      'sortable': true,
      'filterable': false,
      'minWidth': 10,
      'formatter': aprooveerCheckBoxFormattter
    }
  ];

    this.loadTasksData(this.page, this.limit);
    this.taskAssignDetails = {
      title: 'All Users',
      selectImgPath: '../../../../assets/images/common/select-data1.png',
      gridColDef: this.rightGridColumnsDef,
      homeUrl: '/zs/home',
      user_id: this.loggedInUserDetails.user_id
    };

    this.observableSvc.showAssignUsers.subscribe(toggle => {
      if (toggle) {
        this.viewTaskAssignments = toggle;
      } else {
        this.viewTaskAssignments = false;
      }
    });
    this.observableSvc.showAssignUsers.next(false);
  }

  loadTasksData(pageno, limit = 1) {
    limit =  100;
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tasks?allusers=true&paginate=true&pageno=${1}&pagesize=${limit}`;
    var serverfilter = "";
    if(sessionStorage.getItem("serverfilter")) {
      var serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) { 
        this.page = 1;
      }
      this.isFiltering = false;
    } 
   let sortfilter = "";
   if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
   } 
    // this.ProjectSvc.getAllMyOpenTasks(this.loggedInUserDetails.userid, pageno, limit).subscribe((data) => {
    this.isTasksLoading = true;
    let isCustomGridStateHandling = this.allTasksGridState.sort && this.allTasksGridState.sort != '' ? this.allTasksGridState.sort: null;

    this.ProjectSvc.getAllTasks(this.loggedInUserDetails.user_id, pageno, limit, serverfilter, sortfilter, isCustomGridStateHandling).subscribe((data) => {
      this.tasksDataSource = data['currentpage'];
      if (this.tasksDataSource.length === 0) {
        this.noData = true;
      }
      // this.tasksDataSource = this.tasksDataSource.filter(task => task.task_status === "Completed" && task.project_id === 262);
      this.isTasksLoading = false;

      if (pageno === 1) {
        this.total = data['totalrecords'];
      }
      let concatedRows = this.tasksDataSource;
      if (this.taskGrid) {
        concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
        this.tasksDataSource.forEach(element => {
          element['id'] = Math.random();
          element['shouldDisable'] = (element['task_status'] == 'Completed' || element['task_status'] == 'Reviewed' ? true : false);
        });
        this.tasksDataSource = concatedRows;
        this.taskGrid.dataView.setItems(concatedRows);
        this.taskGrid.gridService.setSelectedRows([]);
        if (pageno < data['totalpages']) {
          this.hasScrolled = false;
        } else {
          this.hasScrolled = true;
        }
      }
    }, err => {
      // this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
      this.isTasksLoading = false;
    });
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  taskGridReady(grid) {
    const parent = this;
    parent.taskGrid = grid;
    parent.taskGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
      parent.selectedRow = parent.taskGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.projectDetail.project_id = parent.selectedRow ? parent.selectedRow.project_id : '';

      parent.selectedProjects = [];
        args.rows.forEach(ind => {
          const row = parent.taskGrid.gridService.getDataItemByRowIndex(ind);
          parent.selectedProjects.push(row);
        });
          parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
          parent.observableSvc.showAssignUsers.next(false);
          if(parent.selectedProjects && parent.selectedProjects.length>0) {
            parent.taskAssignDetails['getUserUrl'] = `users/${parent.loggedInUserDetails.user_id}/projects/${parent.selectedProjects[0]['project_id']}/users/`;
            parent.taskAssignDetails['updateURL'] = `users/${parent.loggedInUserDetails.user_id}/projects/${parent.selectedProjects[0]['project_id']}/projecttasks`;
            parent.checkValidates(parent.selectedProjects);
          } else {
            parent.validatedSelectedProjects = false;
          }
          parent.checkForTaskTypes();
          parent.taskAssignDetails['gridColDef'] = parent.rightGridColumnsDef;
      });
      parent.taskGrid.slickGrid.onSort.subscribe((e, args) => {
      parent.page = 1;
      parent.hasScrolled = false;
    });
  }

  rightTaskGridReady(grid) {
    this.rightTasksGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadTasksData(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.loadTasksData(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.loadTasksData(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onGridStatusChanged(projectTaskGridState) {
    this.allTasksGridState.sort = projectTaskGridState.sort ? projectTaskGridState.sort: null;
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row);

    const clickedCellIndex = args.cell;

    if(clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('name')){ 
      if(row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS) {
        this._router.navigate([`/zs/projects/${row.project_id}/production-stage-details`], { queryParams: { entity_id: row.entity_id }});
      } else {
        this._router.navigate(['/zs/projects', row.project_id]);
      }
    } else if(clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('taskname') || clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('task_type') ){
      this.selectedClusterId = row.cluster_id;
      this.selectedTaskType = row.task_type;
      this.selectedTaskName = row.taskname;
      this.projectDetail.project_id = row.project_id;
      this.taskType = row.task_type;
      this.selectedTaskStatus = row.task_status;
      this.selectedRowDataSource = row;
      this.shouldDisableFeedback = false;
      this.selectedJobId = row.zs_job_id;
      this.isProjectFourEyeCheckOn = row.four_eye_check;
      this.shouldDisableMatchFixingTasks = (row.task_status === AppGlobals.TASK_COMPLETED || row.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
      this.prouductionTaskSelected = row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS ? true : false;
      this.shouldMoveMatchFixingData = this.validateMatchFixingTasksDataShouldBeMerged(row);
      if(this.selectedTaskType !== AppGlobals.RESOLVE_MATCH_FIXING_TASK) {
        this.getProject(row);
      }
      if(this.selectedClusterId && this.taskType !== 'Merge Training'){
        this.openMatcherFeedbackModal = true;
        this.openMergerFeedbackModal = false;
      } else if(this.taskType === 'Merge Training') {
        this.openMatcherFeedbackModal = false;
        this.openMergerFeedbackModal = true;
      }
    }
  }  
  getProject(row) {
    this.ProjectSvc.getProject(this.loggedInUserDetails['user_id'], row.project_id).subscribe(resp => {
      this.project_users = resp.users;
      let userDetails = this.project_users.find(user => user.user_id === this.loggedInUserDetails.user_id);
      const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER);
      if (row.task_status === AppGlobals.TASK_COMPLETED) {
        this.shouldDisableFeedback = true;
      }
      if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
        this.shouldDisableFeedback = true;
      }
      this.isProjectDataLoaded = true;
    });
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true;
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
      if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onTaskAssignments(){
    this.viewTaskAssignments = !this.viewTaskAssignments;
    this.observableSvc.showAssignUsers.next(this.viewTaskAssignments);
  }

  loadRightTasksData() {
    this.rightTasksDataSource = [];
    this.ProjectSvc.getResolveUsers(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe((data) => {
       this.rightTasksDataSource = data;
       if (this.rightTasksDataSource.length === 0) {
         this.noData = true;
       }
       let concatedRows: string[] = this.rightTasksDataSource;
       if (this.rightTasksGrid) {
         concatedRows = [...this.rightTasksGrid.dataView.getItems(), ...this.rightTasksDataSource];
         this.rightTasksDataSource.forEach(element => {
           element['id'] = Math.random();
         });
         this.rightTasksDataSource = concatedRows;
         this.rightTasksGrid.dataView.setItems(concatedRows);
         this.rightTasksGrid.gridService.setSelectedRows([]);
         this.hasScrolled = false;
       }
     }, err => {
       this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
     });
   }

   checkValidates(indexes) {
    this.validatedSelectedProjects = indexes.every(proj => {
      if(proj['task_type'] == indexes[0]['task_type']) {
       if(proj['project_id'] == indexes[0]['project_id']) {
         return true;
       } else {
        return false;
       } 
      } else {
        return false;
      }
    });
   }

   assignedTasks(eve) {
    if(eve) {
      this.tasksDataSource = [];
        if (this.taskGrid) {
          this.taskGrid.dataView.setItems(this.tasksDataSource);
        }
      this.loadTasksData(1, this.limit);
    }
  }

  onReloadScreen(event) {
    if (event) {
      // close pop-up here openMatcherFeedbackModal, and merger
      this.openMatcherFeedbackModal = false;
      this.openMergerFeedbackModal = false;
      this.tasksDataSource = [];
      if (this.taskGrid) {
        this.taskGrid.dataView.setItems(this.tasksDataSource);
      }
      this.loadTasksData(1, this.limit);
      $('.modal-backdrop').remove();
    }
  }
  
  onReloadFixingDataGrid(event) {
    if (event) {
      this.openMatcherFeedbackModal = false;
      this.page = 1;
      this.openMergerFeedbackModal = false;
      this.tasksDataSource = [];
      this.loadTasksData(1, this.limit);
      if (event.shouldRunDeltaMerger && event.completed) {
          // call algo API from here
          this.runDeltaMatcherAlgoAPI();
      } else {
        this.resetMatcherTaskGridStates();
      }
    }
  }

  runDeltaMatcherAlgoAPI() {
    let self = this;
    this.selectedJobId = this.selectedJobId ? this.selectedJobId : this.zettaUtils.generateUUID();
    this.ProjectSvc.runDeltaMatcherAlgoJob('stage8', this.selectedJobId, this.projectDetail.project_id,
    this.selectedClusterId).subscribe((res) => {
      self.resetMatcherTaskGridStates();
    }, (err) => {
      self.resetMatcherTaskGridStates();
    })
  }

  resetMatcherTaskGridStates() {
    this.selectedClusterId = '';
    this.selectedTaskName = '';
    this.selectedTaskType = '';
    this.taskType = '';
    this.selectedTaskStatus = '';
    this.shouldDisableMatchFixingTasks = true;
    this.shouldMoveMatchFixingData = false;
    this.selectedJobId = '';
  }

  
  validateMatchFixingTasksDataShouldBeMerged(rowData) {
    if (rowData && rowData.task_status) {
        if (this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          return true;
        }
        if (!this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_IN_REVIEW) {
          return true;
        }
    }
    return false;  
  }

  checkForTaskTypes() {
    let reviewerTasks = this.selectedProjects.every(task => task.status == 'In Review' || task.task_status == 'In Review');
    let approverTasks = this.selectedProjects.every(task => (task.status == 'Pending For Approval' || task.status == 'Reviewed') || (task.task_status == 'Pending For Approval' || task.task_status == 'Reviewed'));
    let inReviewTasks = this.selectedProjects.some( task => task.status == 'In Review' || task.task_status == 'In Review') 
    let inPendingTasks = this.selectedProjects.some(task => (task.status == 'Pending For Approval' || task.status == 'Reviewed') || (task.task_status == 'Pending For Approval' || task.task_status == 'Reviewed'));
    
    if (reviewerTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = disabledCheckBoxFormatter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(approverTasks) {
      this.rightGridColumnsDef[3]['formatter'] = disabledCheckBoxFormatter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(inReviewTasks && inPendingTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = true;
    } 
  }

  onMergerFeedbackModalClosed(event) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
  }
  
  onMatcherFeedbackModalClosed(eve) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
  }
}
