import $ from 'jquery';

export class ZmZettaUtils {
  public isProcessRunning = false;

  // window reference
  getNativeWindow() {
    return window;
  }
  //  creating U Id
  generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  deepCloneObject(obj) {
    // return value is input is not an Object or Array.
    if (typeof (obj) !== 'object' || obj === null) {
      return obj;
    }

    let clone;

    if (Array.isArray(obj)) {
      clone = obj.slice();  // unlink Array reference.
    } else {
      clone = Object.assign({}, obj); // Unlink Object reference.
    }

    const keys = Object.keys(clone);

    for (let i = 0; i < keys.length; i++) {
      clone[keys[i]] = this.deepCloneObject(clone[keys[i]]); // recursively unlink reference to nested objects.
    }

    return clone; // return unlinked clone.
  }
  // array Sort
  sortList(a, b) {
    if (a.label === b.label) {
      return 0;
    } else {
      return (a.label < b.label) ? -1 : 1;
    }
  }

  getWidthOfText(txt) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = 'bold 16px Arial';
    return Math.round(ctx.measureText(txt + '&').width);
  }

  customSortFun(data, filed, order) {
    order = (order.toLowerCase() === 'asc') ? 1 : -1;
    data.sort((data1, data2) => {
      let value1 = data1[filed];
      let value2 = data2[filed];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (this.isValidDate(value1) && this.isValidDate(value2)) {
        const dateA: any = new Date(value1);
        const dateB: any = new Date(value2);
        return (order === 1) ? (dateA - dateB) : (dateB - dateA);
      } else if (value1.indexOf(',') < 0 && value1.indexOf('$') < 0 && isNaN(value1) &&
        typeof value1 === 'string' && typeof value2 === 'string') {
        value1 = value1.replace(/ +?/g, '').toLowerCase();
        value2 = value2.replace(/ +?/g, '').toLowerCase();
        result = value1 > value2 ? 1 : (value1 < value2 ? -1 : 0); // value1.localeCompare(value2);
      } else if (isNaN(value1) && typeof value1 === 'string' && typeof value2 === 'string') {
        value1 = $.trim(value1).toLowerCase();
        value2 = $.trim(value2).toLowerCase();
        result = value1.localeCompare(value2);
      } else {
        value1 = Number(value1);
        value2 = Number(value2);
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (order * result);
    });
  }

  isValidDate(strDate) {
    const myDateStr = new Date(strDate);
    if (!isNaN(myDateStr.getMonth())) {
      return true;
    }
    return false;
  }

  isValidNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /*
    ex :  commarization('10000000000',0);
    ex :  commarization('-10000000000',0)
  */
  commarization(num, fixed) {
    if (num === null || num === undefined) { return 'N/A'; }
    fixed = (!fixed || fixed < 0) ? 0 : fixed;
    num = Number(num);
    const b = (num).toPrecision(2).split('e'),
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
      d = c < 0 ? c : Math.abs(c),
      e = d + ['', 'K', 'M', 'B', 'T'][k];
    return e;
  }

  currencyFormat(num) {
    if (!num) { return 0; }
    const n = String(num),
      p = n.indexOf('.');
    return n.replace(
      /\d(?=(?:\d{3})+(?:\.|$))/g,
      (m, i) => p < 0 || i < p ? `${m},` : m
    );
  }

 
  getTotalPages(count: number, perPage: number): number {
    return Math.ceil(count / perPage) || 0;
  }

  
  /**
   * Convert view port width to px unit, Dynamic way to create width based on viewport width
   * @param {*} value
   * @returns
   * @memberof zettaUtils
   */
  vwTOpx(value) {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    return (x * value) / 100;
  }


  /**
   * Convert view port height to px unit, Dynamic way to adjust height based on viewport height
   *
   * @param {*} value
   * @returns
   * @memberof zettaUtils
   */
  vhTOpx(value) {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    return (y * value) / 100;
  }

  getTaskStatusIcon(flag) {
    return flag ? 'fa fa-check' : 'fa fa-hourglass-start';
    // switch (index) {
    //   case 1:
    //     return 'fa fa-hourglass-start';
    //     break;
    //   case 2:
    //     return 'fa fa-star';
    //     break;
    //   default:
    //     return 'fa fa-check';
    //     break;
    // }
  }

  getTaskStatusColor(flag) {
    return flag ? '#57cff2' : '#ff7000';
    // switch (index) {
    //   case 1:
    //     return '#ff7000';
    //     break;
    //   case 2:
    //     return '#fac977';
    //     break;
    //   default:
    //     return '#57cff2';
    //     break;
    // }
  }

  getProjectEntityClassById(index) {
    switch (index) {
      case 1:
        return 'fa fa-user';
      case 2:
        return 'fa fa-map-marker';
      case 3:
        return 'fa fa-exchange';
      case 4:
        return 'fa fa-university';
      case 6:
        return 'fa fa-users';
      case 8:
        return 'fa fa-balance-scale';
      case 9:
        return 'fa fa-building';
      case 10:
        return 'fa fa-file-text-o';
      case 12:
        return 'fa fa-money';
      case 13:
        return 'fa fa-usd';
      case 14:
        return 'fa fa-cubes';
      default:
        return 'fa fa-handshake-o';
    }
  }

  getProjectEntityColor(index) {
    switch (index) {
      case 1:
        return '#ff7000';
      case 2:
        return '#d35162';
      case 3:
        return '#eeb049';
      case 4:
        return '#fac977';
      case 6:
        return '#57cff2';
      case 8:
        return '#4d86ff';
      case 9:
        return '#4990e2';
      case 10:
        return '#0d71a1';
      case 12:
        return ' #00cb00';
      case 13:
        return '#e5b500';
      case 14:
        return '#f57cc5';
      default:
        return '#00adef';
    }
  }

  getAnalyticTypeColor(index) {
    switch (index) {
      case '11':
        return '#F48341';
      case '12':
        return '#06B6D0';
      case '13':
        return '#FED055';
      case '14':
        return '#5E55AB';
      default:
        return '#483da0';
    }
  }

  getTrainResolveModelColorByStatus(value) {
    let defaultColor = '#0e709f';
    value = value ? value.trim().toUpperCase() : '';
    if (value === 'READY FOR APPROVAL') {
      defaultColor = '#eeb049';
    } else if (value === 'UNASSIGNED') {
      defaultColor = '#a0a0a0';
    } else if (value === 'COMPLETED') {
      defaultColor = '#00cb00';
    }
    return defaultColor;
  }

  getManageJobsColorByStatus(value) {
    return value ? '#4bb100' : '#eeb049';
  }

  setGridAutoScrollTogether(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').on('scroll', function (e) {
      $('#' + uniqueParentID + ' .slick-viewport').scrollLeft(e.target.scrollLeft).scrollTop(e.target.scrollTop);
    });
  }

  setGridHorizontalScrollTogether(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').on('scroll', function (e) {
      $('#' + uniqueParentID + ' .slick-viewport').scrollTop(e.target.scrollTop);
    });
  }

  removeGridAutoScrollTogetherEvent(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').unbind('scroll');
  }

  virtualScroll(parentClass, position = 'top') {
    const scrolltop = $(`.${parentClass} .slick-viewport.slick-viewport-${position}.slick-viewport-left`).scrollTop();
    if (scrolltop !== 0) {
      const clientHeight = $(`.${parentClass} .grid-canvas.grid-canvas-${position}.grid-canvas-left`).last()[0].clientHeight;
      const innerheight = $(`.${parentClass} .slick-viewport.slick-viewport-${position}.slick-viewport-left`).innerHeight();
      const totalength = innerheight + scrolltop;
      return totalength > clientHeight - 1;
    }
  }

  getStatusColor(status) {
    if (status.is_completed) {
      return '#4bb100';
    } else if (status.is_errored) {
      return '#f70e0e';
    } else {
      return '#eeb049';
    }
  }

  convertIntoSeconds(obj) {
    let totalSeconds = 0;
    if (obj) {
      totalSeconds = parseInt(obj['days']) > 0 ? (parseInt(obj['days']) * 3600 * 24) : 0;
      totalSeconds += parseInt(obj['hours']) > 0 ? (parseInt(obj['hours']) * 3600) : 0;
      totalSeconds += parseInt(obj['minutes']) > 0 ? (parseInt(obj['minutes']) * 60) : 0;
      totalSeconds += parseInt(obj['seconds']) > 0 ? (parseInt(obj['seconds'])) : 0;
    }
    return totalSeconds;
  }

  secondsToDhms(seconds) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    const returnStr = (dDisplay + hDisplay + mDisplay + sDisplay);
    return returnStr ? returnStr : '0 second';
  }

  getStateList(state) {
    switch (state) {
      case 'create-analytic':
        return ['Describe Project', 'Add Users', 'Add Data Sets', 'Set Model Inputs', 'Schedule Model'];
      case 'create-project':
        return ['Describe Project', 'Add Users', 'Add Data Sets', 'Map Data Sets to Entity Model'];
      case 'create-dataset':
        return ['Describe Data Set', 'Select Data Source', 'Add Data Transformations', 'Add User Entitlements', 'Schedule Refresh'];
      case 'create-datasource':
       return ['Describe Data Source', 'Define Connection', 'Set User Entitlements'];
    }
  }

}
