import { Component, OnInit } from '@angular/core';
import { ProjectStatus } from '../../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { environment } from 'src/environments/environment';

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.isChecked) {
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  } else {
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }
};

const EMPTY_GRID_MSG = 'No Concepts.';

@Component({
  selector: 'zetta-zs-cl-select-concept-parsed',
  templateUrl: './zs-cl-select-concept-parsed.component.html',
  styleUrls: ['./zs-cl-select-concept-parsed.component.scss']
})
export class ZsClSelectConceptParsedComponent implements OnInit {
  progressState = new ProjectStatus();
  isDataReady: Boolean = false;
  catalogs;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  showBreadcrumb: Boolean = false;
  catalogID: Number;
  semanticObjects;
  concepts = [];
  semanticObjectId: Number;
  conceptId;
  public grid: AngularGridInstance;
  gridOptions: any;
  tableColDef: any;
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  isLoading: Boolean = true;
  totalConcepts: Number = 0;
  hasScrolled: Boolean = false;
  noData: Boolean = false;
  page = 1;
  limit = 100;
  projectId;
  project_type_id;
  projectData: any;
  selectedCatalog;
  selectedSemanticObject;
  selectedConceptId;
  grandTotal = 0;
  subTotal = '';
  isEditProject: string;
  constructor(public zettaUtils: ZettaUtils,
    private zsclContentService: ZsClContentService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
    });

    this.route.queryParamMap.subscribe(param => {
      this.project_type_id = +param['params']['project_type'];
    });
    this.isEditProject= this.route.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.route.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem("sortfilter");

    this.getProject();
    this.selObjectInit();
    this.getAllCatalogs();
    this.getSelectedConceptParsedData();
  }

  getProject() {
    const payload = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.projectId
    };
    this.zsclContentService.getclassifyProject(payload).subscribe(resp => {
      if (resp) {
        this.projectData = resp;
        this.progressState.states = this.zettaUtils.getStateList('create-classify-concept-parser-project');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Data Classification Project';
        this.progressState.classificationProject = resp;
        this.progressState.isEdit = this.isEditProject === 'true';
        this.isDataReady = true;
      }
    });
  }

  selObjectInit() {
    this.tableColDef = [
      {
        'displayname': ' ',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 35,
        'maxWidth': 35,
      }, {
        'displayname': 'Concept',
        'physicalname': 'logical_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 150,
        'maxWidth': 250,
      }, {
        'displayname': 'Description',
        'physicalname': 'description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: EMPTY_GRID_MSG,
    };
  }

  getAllCatalogs() {
    this.zsclContentService.getCatalogs(this.loggedInUserDetails['user_id']).subscribe(data => {
      if (data) {
        this.catalogs = data;
      }
    });
  }

  changeCatalogName(value) {
    this.isLoading = true;
    this.catalogID = parseInt(value);
    this.getEntities(value);
  }

  getEntities(catalogId) {
    this.zsclContentService.getSemanticsObjects(catalogId).subscribe(res => {
      this.semanticObjects = res;
    }, error => {
    })
  }

  changeSemanticObject(value) {
    this.isLoading = true;
    this.semanticObjectId = parseInt(value);
    this.page = 1;
    this.getConcepts(value, this.page, this.limit);
  }

  getConcepts(SemanticObjId, pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogID}/semantics/${this.semanticObjectId}/concepts?pagination=true&pageno=${1}&pagesize=${limit}`;
    if (pageNo === 1) {
      this.concepts = [];
      if (this.grid) {
        this.grid.dataView.setItems(this.concepts);
      }
    }

    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.zsclContentService.getConceptsPaged(this.catalogID, SemanticObjId, pageNo, limit, sortfilter, serverFilter,true).subscribe(res => {
      this.concepts = res.currentpage;
      if (this.concepts.length === 0) {
        this.noData = true;
      }
      res['currentpage'].forEach(el => {
        el.isChecked = el.attribute_id == this.selectedConceptId ? true : false;
        el._id = Math.random();
      });
      this.totalConcepts = res.totalrecords;
      if (pageNo === 1) {
        this.grandTotal = res.totalrecords;
      }
      let concatedLeftRows = this.concepts;
      this.isLoading = false;
      if (this.grid) {
        concatedLeftRows = [...this.grid.dataView.getItems(), ...this.concepts];
        this.concepts.forEach(element => {
          element.id = Math.random();
        });
        this.concepts = concatedLeftRows;
        this.grid.dataView.setItems(this.concepts);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, error => {
      this.isLoading = false;
      this.hasScrolled = false;
    })
  }

  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent.conceptId = row.attribute_id;
    $("#data-" + args.row).prop("checked", true);
  }

  getSelectedConceptParsedData() {
    this.zsclContentService.getProjectConceptParsed(this.projectId).subscribe(res => {
      if (res.length != 0) {
        this.selectedCatalog = res[0].catalog_id;
        this.selectedSemanticObject = res[0].semantic_id;
        console.log(this.selectedCatalog, 'and', this.selectedSemanticObject);
        if (this.selectedCatalog) {
          this.changeCatalogName(this.selectedCatalog);
        }
        if (this.selectedSemanticObject) {
          this.changeSemanticObject(this.selectedSemanticObject);
        }
        this.selectedConceptId = res[0].concept_id;
        this.conceptId = res[0].concept_id;
      }
    }, error => {
    })
  }

  onSaveClick() {
    const payload = {
      catalog_id: this.catalogID,
      semantic_id: this.semanticObjectId,
      concept_id: this.conceptId
    }

    this.zsclContentService.saveSelectedConceptParsed(this.projectId, payload).subscribe(res => {
      if (this.isEditProject) {
        this.router.navigate([`/zs-cl/projects/${this.projectId}/new-sub-concept`], { queryParams: { catalog_id: this.catalogID, entity_id: this.semanticObjectId, attributeId: this.conceptId, showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id, isEditProject: true }, queryParamsHandling: 'merge' });
      }
      else {
        this.router.navigate([`/zs-cl/projects/${this.projectId}/new-sub-concept`], { queryParams: { catalog_id: this.catalogID, entity_id: this.semanticObjectId, attributeId: this.conceptId, showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id }, queryParamsHandling:'merge' });
      } 
    })
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onNext(): void {
    this.page++;
    this.getConcepts(this.semanticObjectId, this.page, this.limit);
  }

  onPrevious() {
    if (this.isEditProject) {
      this.router.navigate([`/zs-cl/projects/${this.projectId}/add-users`], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id, isEditProject: true }, queryParamsHandling: 'merge' });
    }
    else {
      this.router.navigate([`/zs-cl/projects/${this.projectId}/add-users`], { queryParams: { showBreadcrumb: this.showBreadcrumb, project_type: this.project_type_id } });
    }
    
  }
  onCancel() {
    this.router.navigate(['/zs-cl/projects']);
  }

  receiveTotalRecords($event) {      
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
  }

}
