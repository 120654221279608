// blue-text-formatter.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-blue-text-formatter',
  template: `
    <div class="blueLink pointer modal-feedback text-truncate"
         [title]="params.value"
         data-toggle="modal"
         data-backdrop="static"
         data-target="#tm-modal">
      {{ params.value }}
    </div>
  `,
  styles: [
    // Add any additional styles if needed
  ]
})
export class BlueTextFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
