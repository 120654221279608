
import { DataSetsComponent } from './project/data-sets/data-sets.component';
import { EntityModelProjectComponent } from './project/entity-model-project/entity-model-project.component';
import { UsersComponent } from './project/users/users.component';
import { MapDataSetsComponent } from './project/map-data-sets/map-data-sets.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';
import { ContentComponent } from './content.component';
import { UsergroupComponent } from '../usergroup/usergroup.component';
import { UsergroupsComponent } from '../user-groups/user-groups.component';
import { CreateProjectComponent } from './project/create-project/create-project.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { DataSourcesComponent } from './project/data-sources/data-sources.component';
import { ManageJobsComponent } from '../manage-jobs/manage-jobs.component';
import { ManageTenantsComponent } from '../manage-tenants/manage-tenants.component';
import { ViewResolutionResultsComponent } from './view-resolution-results/view-resolution-results.component';
import { ParametersComponent } from './parameters/parameters.component';
import { EntityRecordsViewResultsComponent } from './entity-records-view-results/entity-records-view-results.component';
import { ProjectHomeComponent } from './project/project-home/project-home.component';
import { TrainMasteringModelComponent } from './project/train-mastering-model/train-mastering-model.component';
import { AuthGuard } from '../../common/shared/auth-guard.service';
import { MatcherFeedbackComponent } from './project/matcher-feedback/matcher-feedback.component';
import { TasksComponent } from './tasks/tasks.component';
import { MyTasksComponent } from './tasks/my-tasks/my-tasks.component';
import { EntitiesViewComponent } from './entities-view/entities-view.component';
import { ManageProjectTasksComponent } from './project/manage-project-tasks/manage-project-tasks.component';
import { MatchingRemediationTasksComponent } from './project/matching-remediation-tasks/matching-remediation-tasks.component';
import { MergerRemediationTasksComponent } from './project/merger-remediation-tasks/merger-remediation-tasks.component';

import { PublishResultComponent } from './project/publish-result/publish-result.component';
import { PublishResultsViewComponent } from './project/publish-results-view/publish-results-view.component';
import { EntitiesComponent } from './entities/entities.component';
import { CreateEntityComponent } from './entities/create-entity/create-entity.component';
import { EntityModelComponent } from './entities/entity-model/entity-model.component';
import { EntityModelViewComponent } from './entities/entity-model-view/entity-model-view.component';
import { EntityHomeComponent } from './entities/entity-home/entity-home.component';
import { GoldenRecordsComponent } from './project/golden-records/golden-records.component';
import { GoldenRecordsDetailsComponent } from './project/golden-records/golden-records-details/golden-records-details.component';
import { AddAdminUsersComponent } from 'src/app/common/components/datasources/add-admin-users/add-admin-users.component';
import { ProductionDetailComponent } from './project/production-detail/production-detail.component';




const ROUTES: Routes = [{
  path: 'zs/home',
  component: ContentComponent,
  data: { breadcrumb: 'Home' },
  canActivate: [AuthGuard],
  children: [
    // { path: '', component: DataSourcesComponent, canActivate: [AuthGuard] },
    { path: '', component: DataSourcesComponent, canActivate: [AuthGuard] },
  ],
},
{
  path: 'zs/entities',
  component: ContentComponent,
  data: { breadcrumb: 'Entities' },
  canActivate: [AuthGuard],
  children: [
    { path: '', component: EntityHomeComponent, data: { breadcrumb: 'Entities' }, canActivate: [AuthGuard] },
    { path: 'create-entity', component: CreateEntityComponent, data: { breadcrumb: 'Create New Entity Model' }, canActivate: [AuthGuard] },
    {
      path: ':id',
      component: ContentComponent,
      data: { breadcrumb: '...', type: 'entity' },
      canActivate: [AuthGuard],
      children: [
        { path: '', component: EntityModelViewComponent, data: { breadcrumb: 'Entity Model' }, canActivate: [AuthGuard] },
        { path: 'add-users', component: AddAdminUsersComponent, data: { breadcrumb: 'Entity Model' }, canActivate: [AuthGuard] },
        { path: 'entity-model', component: EntityModelComponent, data: { breadcrumb: 'Entity Model' }, canActivate: [AuthGuard], canDeactivate: [AuthGuard]},
        { path: 'entity-model-view', component: EntityModelViewComponent, canActivate: [AuthGuard] }
      ]
    }
  ],
},
// { 
//   path: 'zs/entities',
//   component: ContentComponent,
//   data: { breadcrumb: 'Entities' },
//   canActivate: [AuthGuard],
//   children: [
//     { path: 'zs/entities', component: EntitiesViewComponent, canActivate: [AuthGuard] },
//     { path: ':entityId', component: EntitiesViewComponent, canActivate: [AuthGuard] }
//   ],
// },
{
  path: 'zs/projects',
  component: ContentComponent,
  data: { breadcrumb: 'Projects' },
  canActivate: [AuthGuard],
  children: [
    { path: '', component: ProjectHomeComponent, canActivate: [AuthGuard] },
    { path: 'create', component: CreateProjectComponent, data: { breadcrumb: 'Create New Project' }, canActivate: [AuthGuard] },
    {
      path: ':id',
      component: ContentComponent,
      data: { breadcrumb: '...', type: 'project' },
      canActivate: [AuthGuard],
      children: [
        { path: '', component: ProjectDetailComponent, data: {}, canActivate: [AuthGuard] },
        { path: 'production-stage-details', component: ProductionDetailComponent, data: {}, canActivate: [AuthGuard] },
        { path: 'production-stage-details/golden-records', 
        component: GoldenRecordsComponent, data: { breadcrumb: 'Most Recent Golden Records Changes' }, canActivate: [AuthGuard]},
        {
          path:'production-stage-details/golden-records/detail',component:GoldenRecordsDetailsComponent,data: { breadcrumb: 'Golden Records' },canActivate:[AuthGuard]          
        },
        { path: 'production-stage-details/users', component: UsersComponent, data: { breadcrumb: 'Manage Project Users' }, canActivate: [AuthGuard] },
        {
          path: 'entities-resolved',
          component: ViewResolutionResultsComponent, data: { breadcrumb: 'View Resolution Results' }, canActivate: [AuthGuard]
        },
        {
          path: 'parameters',
          component: ParametersComponent, data: { breadcrumb: 'Project Parameters' }, canActivate: [AuthGuard]
        },
        {
          path: 'entities-mastered',
          component: EntityRecordsViewResultsComponent, data: { breadcrumb: 'Entity Records View Results' }, canActivate: [AuthGuard]
        },
        { path: 'users', component: UsersComponent, data: { breadcrumb: 'Manage Project Users' }, canActivate: [AuthGuard] },
        { path: 'datasets', component: DataSetsComponent, data: { breadcrumb: 'Manage Data Sets' }, canActivate: [AuthGuard] },
        { path: 'edit-entity-model', component: EntityModelProjectComponent, data: { breadcrumb: 'Edit Entity Models' }, canActivate: [AuthGuard] },
        { path: 'attributes', component: MapDataSetsComponent, data: { breadcrumb: 'Manage Entity Models' }, canActivate: [AuthGuard] },
        {
          path: 'entities-mastered-train', component: TrainMasteringModelComponent,
          data: { breadcrumb: 'Train Mastering Model' }, canActivate: [AuthGuard]
        },
        {
          path: 'publish-result',
          component: PublishResultComponent, data: { breadcrumb: 'Publish Result' }, canActivate: [AuthGuard]
        },
        {
          path: 'publish-results-view',
          component: PublishResultsViewComponent, data: { breadcrumb: ' View Publish Results' }, canActivate: [AuthGuard]
        },
        {
          path: 'golden-records',
          component: GoldenRecordsComponent, data: { breadcrumb: 'Golden Records' }, canActivate: [AuthGuard],
         
        },
        {
          path:'golden-records/detail',component:GoldenRecordsDetailsComponent,data: { breadcrumb: 'Golden Records' },canActivate:[AuthGuard]          
        },
        {
          path: 'entities-resolved-train', component: MatcherFeedbackComponent,
          data: { breadcrumb: 'Train Resolution Model' }, canActivate: [AuthGuard]
        },
        {
          path: 'tasks', component: ManageProjectTasksComponent,
          data: { breadcrumb: 'Manage Project Tasks' }, canActivate: [AuthGuard]
        },
        {
          path: 'matching-remediation-tasks', component: MatchingRemediationTasksComponent,
          data: { breadcrumb: 'Matching Remediation Tasks' }, canActivate: [AuthGuard]
        },
        {
          path: 'production-stage-details/matching-remediation-tasks', component: MatchingRemediationTasksComponent,
          data: { breadcrumb: 'Matching Remediation Tasks' }, canActivate: [AuthGuard]
        },
        {
          path: 'merger-remediation-tasks', component: MergerRemediationTasksComponent,
          data: { breadcrumb: 'Merger Remediation Tasks' }, canActivate: [AuthGuard]
        },
      ]
    }
  ],
},
{
  path: 'zs/tasks',
  component: ContentComponent,
  data: { breadcrumb: 'All Tasks' },
  canActivate: [AuthGuard],
  children: [
    { path: '', component: TasksComponent, data: { breadcrumb: 'All Tasks' }, canActivate: [AuthGuard] },
    { path: 'allmytasks', component: MyTasksComponent, data: { breadcrumb: 'All My Tasks' }, canActivate: [AuthGuard] }
  ],
},
// {
//   path: 'zs/tasks', component: TasksComponent, data: { breadcrumb: 'All Tasks' }, canActivate: [AuthGuard]
// },
{
  path: 'zs/mytasks', component: MyTasksComponent, data: { breadcrumb: 'All My Tasks' }, canActivate: [AuthGuard]
},
{
  path: 'zs/jobs', component: ManageJobsComponent, data: { breadcrumb: 'Jobs' }, canActivate: [AuthGuard]
},
{
  path: 'zs/manage-tenants', component: ManageTenantsComponent, data: { breadcrumb: 'Tenants' }, canActivate: [AuthGuard]
},
// {
//  path: 'zs/admin/users', component: UsergroupComponent, data: { breadcrumb: 'Groups and Users' }, canActivate: [AuthGuard], 
// },
// {
//   path: 'zs/admin/ad-users', component: UsergroupsComponent, data: { breadcrumb: 'Groups and Users',type: 'user-group' }, canActivate: [AuthGuard] 
// },
{
  path: '**',
  component: NotfoundComponent,
  data: { breadcrumb: 'Not Found' },
},

];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})

export class ContentRoutingModule { }

