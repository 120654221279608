import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';
declare var $: any;

export interface CellParams {
    editRoute:string;
    deleteRoute:string;
    exportRoute:string;
}
@Component({
    selector: 'classification-tag-formatter',
    templateUrl: './classification-tag-formatter.component.html',
    styles: [
    ]
})
export class ClassificationTagFormatterComponent implements OnInit, ICellRendererAngularComp {
    @Output() onClickGlassIcon = new EventEmitter<any>();
    active = false;
    value: any;
    params: any;
    tags: any;
    agInit(params: ICellRendererParams & CellParams): void {
        this.value = params.value;
        this.params = params;
        if (params.data && params.data.entitlement) {
          if ((params.data.entitlement.read_write || params.data.entitlement.read || params.data.entitlement.is_admin) && this.params.user_apps) {
            this.active = true;
          } else {
            this.active = false;
          }
        }
    
        const disabledElements = document.querySelectorAll('.text-truncate.disabled-state'); 
        disabledElements.forEach((element) => {
        const parentCell = element.closest('.ag-cell.ag-cell-not-inline-editing');
        if (parentCell) {
            parentCell.addEventListener('click', (event) => {
                event.stopPropagation();  
            });
        }
        });
        if (params.data && params.data.tags) {
            this.tags = params.data.tags ? params.data.tags.split(",") : [];
        }
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    constructor() { }

    ngOnInit(): void {
    }

    onClick() {
        this.params.context.componentParent.updateRow(this.params.rowIndex)
    }
    onViewClaasificationTag() { 
        console.log("Clickedd formatter");
        $('#object_classification_tags').modal('show');
        this.onClickGlassIcon.emit(true);
    }

}
