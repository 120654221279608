import { Project } from '../../../zettasense/content/project/project.model';
import { Datasource } from '../datasources/datasource.model';
export class ProjectStatus {

    states: string [];
    currentStateIndex: number;
    currentStateInfo: string;
    type: string;
    project: Project;
    datasource: Datasource;
    isEdit: boolean;
    catalog: any;
    classificationProject:any;

    constructor() {
    }
}

