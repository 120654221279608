// option-selection-renderer.component.ts
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';

@Component({
  selector: 'app-option-selection-renderer',
  template: `
 
      <div class='fa fa-graduation-cap pointer modal-feedback' style="color:#0e709f"
        data-toggle="modal" data-backdrop="static" data-target="#tm-modal">
      </div>
  `,
})
export class OptionSelectionRendererComponent implements OnInit, ICellRendererAngularComp{
    params: any;
    agInit(params: ICellRendererParams): void {

 
    }
    refresh(params: ICellRendererParams): boolean {
        return false;
      }
    
      constructor() { }
    
      ngOnInit(): void {
      }
}
