import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { ProjectService } from './../project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { ObservableService } from '../../../../common/services/observable.service';
import { MessageService } from 'src/app/common/components/message/message.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ZsClContentService } from '../../../zs-cl-content/zs-cl-content.service';

@Component({
  selector: 'zetta-project-detail',
  templateUrl: './zs-cl-project-details.component.html',
  styleUrls: ['./zs-cl-project-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsClProjectDetailComponent implements OnInit {
  private _userinfo;
  modeState: any = {};
  object_types: any = [];
  object_type_id: number;
  project_id: number;
  isProjectAdmin = false;
  total_records: number;
  total_mapped_ds: number;
  updated_date: any;
  attrubute_id: number;
  isUserGrantedClassifyProject: boolean = false;
  isProjectRanOnce: boolean = false;

  constructor(public activeRoute: ActivatedRoute,
    private _projectService: ProjectService,
    public zettaUtils: ZettaUtils,
    public observeSvc: ObservableService,
    private messageSvc: MessageService,
    private router: Router,
    private _contentService: ZsClContentService,
  ) { }

  ngOnInit() {
    this._userinfo = this._projectService.getObjectFromSession('userInfo');
    const routeParams = this.activeRoute.snapshot.params;
    this.project_id = routeParams.id;
    this.object_type_id = +sessionStorage.getItem('project_type_id');
    sessionStorage.removeItem("activeStep");
    this.getProject()
  }

  validateUserAccessOnClassifyProject(projectUsers) {
    let loggedInUserInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;
    if(projectUsers && projectUsers.length > 0) {
      let filteredUsers = projectUsers.filter((user) => {
        return loggedInUserInfo.user_id === user.user_id;
      });

      if (filteredUsers.length > 0) {
        return true;
      }
    } 
    
    return false;
  }

  getProject() {
    const data = {
      user_id: this._userinfo.user_id,
      tenant_id: this._userinfo.tenant_id,
      project_id: this.project_id
    };
    let self = this;
    this._contentService.getclassifyProject(data).subscribe(responseList => {
      this.isProjectRanOnce = responseList && responseList.ever_executed ? responseList.ever_executed : false;
      this.total_mapped_ds = responseList.project_details.datasets_mapped || 0;
      if (responseList.project_details.total_records.length && responseList.project_details.total_records[0]['sum']) {
        this.total_records = parseInt(responseList.project_details.total_records[0]['sum']);
      }
      this.updated_date = responseList.latest;
      if (responseList.type === AppGlobals.CONCEPT_PARSER) {
        this.attrubute_id = responseList.project_details.concept_to_be_parsed.concept_id;
      }
      this.isProjectAdmin = this.isLoggedInUserProjectAdmin(responseList.users ? responseList.users: []);
      self.isUserGrantedClassifyProject = self.validateUserAccessOnClassifyProject(responseList.users);

    }, (err) => {
       self.isUserGrantedClassifyProject = false;
       self.isProjectRanOnce = false;
    });

  }

  isLoggedInUserProjectAdmin(projectUsers) {
    let userDetails = projectUsers && this._userinfo ? projectUsers.find(user =>  user.user_id == this._userinfo['user_id']) : [];
    if (userDetails) {
      let userRoles = userDetails.roles && userDetails.roles.length ? userDetails.roles : [];
      let isAssignedUserAdmin = userRoles.find((userRole) => {
        return userRole === AppGlobals.CLASSIFY_PROJECT_ADMIN;
      })
      return isAssignedUserAdmin ? true : false;
    }
    return false;
  }

}
