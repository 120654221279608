import {Injectable} from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class DataQualityService extends BaseService{
constructor(private http:HttpClient){super();}

public getDataQualityDataTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lookups?group=DataQualityColumnDataTypes`);
}
public getOutOfBoxRules(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/dataquality/rules?fetch_default_rule=true&concept_id=${data.concept_id}`);
}

}