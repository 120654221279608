

import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';

const zettaUtilsInstance = new ZettaUtils();

export const numericFormatter: Formatter =
    (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        return `<div class="text-center w-100 text-bold" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
    }
