import { Component, OnInit } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { DatasourceService } from '../../datasource.service';
import { Datasource } from '../../datasource.model';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { DatasetService } from '../../../datasets/dataset.service';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';
import { MessageService } from "src/app/common/components/message/message.service";
import { AppGlobals } from '../../../../../common/shared/app.globals';

const dataSetCountFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let selectDatasetsCount = dataContext.datasets.length;  
  if (selectDatasetsCount > 0) {
    return `<i id="datasets-icon-${row}" class='fal fa-file-spreadsheet userIcon ml-2'></i><span class="roundedCorner" title='${selectDatasetsCount}'>${selectDatasetsCount}</span><i id="datasets-${row}" class='fas long-arrow-right viewDatasetsInUse pointer pull-right'></i>`;
  } else {
    return ``;
  }
}

const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let dsAcess = false;
  if (dataContext.user.length > 0) {
    if  (dataContext.user[0].is_admin||dataContext.user[0].read_write||dataContext.user[0].read_only) {
        dsAcess = true;
    }
  }
  return dsAcess
  ? `<div class="text-truncate"><label title="${value}" class="blueLink text-truncate w-8">${value}</label></div>`
  : `<div class="text-truncate"><label title="${value}" class=" text-truncate w-8">${value}</label></div>`;
};
const NoAcess='You do not have the privileges to this content.Please contact the Data Source administrator and request access.'

@Component({
  selector: 'zetta-datasource-contents',
  templateUrl: './datasource-contents.component.html',
  styleUrls: ['./datasource-contents.component.scss']
})

export class DatasourceContentsComponent implements OnInit {

  dataSourceContent: object = {};
  grid: AngularGridInstance;
  datasetGrid: AngularGridInstance;
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  dataSetTableSettings: object = { 'height': "100%", 'width': "95%", 'pageSize': 6, 'isNormalPagination': false };

  historyTableColDef: any;
  leftGridOptions: any;
  rightGridOptions: any;
  datasource = new Datasource();
  isDataReady = true;
  GridDataSource: any = [];
  dataSelected = -1;
  GridRows = 0;
  datasetTableColDef: any;
  public datasets: any = [];
  isFiltering = false;
  noData = false;
  hasScrolled = false;
  isLoading = true;
  isDSContentLoading: Boolean = true;
  page = 1;
  limit = 100;
  total = 0;
  prevSelectedRow = [-1];
  isAdmin: Boolean = false;
  datasourceList: any;
  totalPage = 0;

  constructor(private datasourceSvc: DatasourceService,
    private activatedRoute: ActivatedRoute,
    private datasetSvc: DatasetService,
    public zettaUtils: ZettaUtils,
    private router: Router,
    private messageSvc:MessageService

    ) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.activatedRoute.parent.params.subscribe(params => {
      this.datasource.datasource_id = params['id'];
    });
    this.getDataSourceById();
    this.getDataSourceAdmin();

    this.historyTableColDef = [{
      'displayname': 'Source Tables / Files',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 200,
      'headerCssClass': 'mr-hd-2',
    }, {
      'displayname': 'Datasets in Use',
      'physicalname': 'datsets',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': dataSetCountFormatter,
      'minWidth': 95,
      'maxWidth': 110,
    }
    ];

    this.datasetTableColDef = [
      {
        'displayname': 'Data Sets',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 160,
        'formatter': dsNameFormattter,
      },
    ];

    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
    };
  }

  getDataSourceById(){
    this.datasourceSvc.getDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(responseList => {   
      const first_char = responseList.connection_string.slice(0,1);
      let connnection_str = responseList.connection_string;
      this.datasourceList = responseList;
      if (first_char === '/' && responseList.datasource_sub_type_id === 328) {
        connnection_str = responseList.connection_string.slice(1, responseList.connection_string.length);
      }
      this.datasourceList.connection_string = connnection_str;
      this.getContentData(this.datasource.datasource_id,responseList.datasource_type, connnection_str, this.limit, this.page);
    },error=>{});
  }
  getContentData(datasourceId, ds_type, connection_string, limit, pageNo) {
    var serverfilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverfilter = sessionStorage.getItem("serverfilter");
    }
    var sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    let dirQuery = '';
    let ignoreType = 'ignoreType=true';
    let paginateQuery = `paginate=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`;
      if (ds_type === AppGlobals.DATASOURCE_TYPE_CLOUD) {
      connection_string = connection_string ? connection_string : `data/${this.loggedInUserDetails['tenant_id']}/raw/`;
      const lastChar = connection_string.slice(-1);
      if (lastChar !== '/') {
        connection_string = `${connection_string}/`;
      }
    }
    dirQuery = connection_string ? `?dir=${connection_string}&${paginateQuery}&${ignoreType}` : `?${ignoreType}&${paginateQuery}`;
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasources/${this.datasource.datasource_id}/contents${dirQuery}`;
    this.datasourceSvc.getDatasourceContents({
      userId: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      sourceId: this.datasource.datasource_id,
      datasource_type: ds_type,
      dir: connection_string,
      limit: limit,
      pageNo: pageNo
    }, serverfilter, sortfilter)
      .subscribe((data) => {
        const allRecords = data;
        data = data['currentpage'] ? data['currentpage'] : data;
        if (allRecords['currentpage']) {
          this.totalPage = allRecords.totalpages;
          this.total = allRecords.totalrecords;
        }
        this.isDSContentLoading = false;
        this.GridDataSource = data;
        if (this.GridDataSource) {
          this.GridDataSource.forEach(element => {
            element.id = Math.random();
            element.datasource_id = datasourceId;
            element.delimiter = ',';
            element.source_table_or_filename = element.name;
            element.header = true;
            element.schema = '';
            element.dataSelected = this.dataSelected;
          });
        }
        let concatedRows = this.GridDataSource;
        this.GridRows = this.GridDataSource.length;
        if (this.grid) {
          concatedRows = [...this.grid.dataView.getItems(), ...this.GridDataSource];
          this.GridDataSource = concatedRows;
          this.grid.dataView.setItems(concatedRows);
          this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
        if (!allRecords['currentpage']) {
          this.total = this.GridDataSource.length;
        }
      }, error => {
        this.GridDataSource = [];
        this.GridRows = this.GridDataSource.length;
        this.isDSContentLoading = false;
      });
  }

  getDataSourceAdmin() {
    this.datasourceSvc.getDatasource({
      userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id
    }).subscribe(responseList => {
      this.isAdmin = responseList.is_admin;
    })
  }

  getDatasetList(datasetList) {    
    this.datasets = datasetList;
    if (this.datasets.length === 0) {
      this.noData = true;
    }
    this.isLoading = false;
    this.datasets.forEach(element => {
      element.id = Math.random();
    });
    if (this.datasetGrid) {
      this.datasetGrid.dataView.setItems(this.datasets);
      this.datasetGrid.gridService.setSelectedRows([]);
      this.datasetGrid.dataView.refresh();
    }
  }

  createDataSets() {
    let data = {
      userId: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      sourceId: this.datasource.datasource_id
    };
    if(this.GridDataSource.length>0)
    {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isCreateDataSet: true, activeJobs: 'Dataset', messageHeader: AppGlobals.CREATE_DATASET_MSG_HEADER, messageText: AppGlobals.CREATE_DATASET_MSG_TEXT, path: 'zs/datasources/' + this.datasource.datasource_id });
      this.datasourceSvc.createAllDataset(data).subscribe((data) => {
      }, error => {     
        this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
      });
    }
    else{
      this.messageSvc.sendMessage({ message: 'This Data Source does not appear to have valid files/content. Please provide access to data files/tables before Creating All Datasets.', type: 'ERROR', hideboard: true });
    }
    
   
  }

  onCellClick(event) {
    const eventEle = event.eventData;
    const args = event.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    this.grid.gridService.setSelectedRow(args.row);
    let selectedRow = this.grid.gridService.getSelectedRows();

    let previousRow;
    if (selectedRow !== this.prevSelectedRow) {
      previousRow = this.prevSelectedRow;
      if (previousRow[0] >= 0) {
        $('#datasets-' + previousRow[0]).removeClass('viewDatasetsInUseSelected');
        $('#datasets-' + previousRow[0]).addClass('viewDatasetsInUse');
        $('#datasets-icon-' + previousRow[0]).removeClass('fas');
        $('#datasets-icon-' + previousRow[0]).addClass('fal');
      }
      this.prevSelectedRow.pop();
      this.prevSelectedRow.push(args.row)
    }

    if (row !== undefined && row !== null) {
      if (eventEle.target.classList[1] == 'long-arrow-right' || fieldName === 'datsets') {
        if(row.has_access==true)
        {
          this.getDatasetList(row.datasets);
          if (selectedRow) {
            $('#datasets-' + args.row).removeClass('viewDatasetsInUse');
            $('#datasets-' + args.row).addClass('viewDatasetsInUseSelected');
            $('#datasets-icon-' + args.row).removeClass('fal');
            $('#datasets-icon-' + args.row).addClass('fas');
          }
        }
          else
          {
            this.messageSvc.sendMessage({message: NoAcess, type: 'ERROR', hideboard: true});
          }

        }
    }
  }

  onDatasetCellClick(event) {
    const args = event.args;    
    const metadata = this.datasetGrid.gridService.getColumnFromEventArguments(args);    
    const fieldName = metadata.columnDef.field;
    const row = this.datasetGrid.gridService.getDataItemByRowIndex(args.row);

    if (fieldName === 'dataset_name' && (row.user[0]['read_only']==true||
    row.user[0]['is_admin']==true||row.user[0]['read_write']==true))
    {
      this.router.navigate(['/zs/datasets', row.dataset_id], {queryParams:{showBreadcrumb:true}});   
    } 

  }

  GridCount() {    
      if (this.grid) {
        return this.grid.dataView.getItems().length;
      } else {
        return this.GridDataSource.length;
      }
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.dataView.setItems(_.orderBy(this.grid.dataView.getItems(), ['name'], ['asc']));
}

  onDatasetGridCreation(grid) {
    const parent = this;
    parent.datasetGrid = grid;
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  onNext(): void {
    if (this.page < this.totalPage) {
      this.page++;
      this.getContentData(this.datasourceList.datasource_id, this.datasourceList.datasource_type, this.datasourceList.connnection_str, this.limit, this.page);
    }
  }
}
