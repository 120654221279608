import { AppGlobals } from '../../../common/zm-shared/app.globals';
import { Injectable } from '@angular/core';
import { ZmBaseService } from '../../../common/zm-services/zm-base-service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { ZmRecords } from './../../../common/zm-services/zm-records.constants';
// import { Project, IEntityPrams } from './project.model';
import { Analytic, IEntityPrams } from './analytic.model';

@Injectable({
    providedIn: 'root'
})

export class AnalyticService extends ZmBaseService {

    constructor(private http: HttpClient) { super(); }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    fetchEntities(id?: string): Observable<any> {
        return this.fetchRecords(ZmRecords.ENTITIES, this.http, id);
    }

    // saveProject(project: Project): Observable<any> {
    //     if (project.projectid === undefined || project.projectid === null) {
    //         return this.http.post(`${this.apiUrl}/users/` + project.userid + `/projects`, project, { observe: 'response' });
    //     } else {
    //         return this.http.put(`${this.apiUrl}/users/` + project.userid + `/projects/` + project.projectid, project);
    //     }
    // }

                // saveAnalytic(analytic: Analytic): Observable<any> {
                saveAnalytic(analytic: Analytic): Observable<any> {
                    if (analytic.analyticid === undefined || analytic.analyticid === null) {
                        return this.http.post(`${this.apiUrl}/users/${analytic.userid}/analytics`, analytic, { observe: 'response' });
                    } else {
                        return this.http.put(`${this.apiUrl}/users/${analytic.userid}/analytics/` + analytic.analyticid, analytic);
                    }
                }

    // saveProjectDataSets(project: Project): Observable<any> {
    //     return this.http.put(`${this.apiUrl}/users/` + project.userid + `/projects/` + project.projectid + `/updatedatasource`, project);
    // }

    public getUsers(userId: string, projectId: String, groupid: string) {
        const groupUsers = this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/users`);
        const projectUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
        const project = this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}`);
        return forkJoin([groupUsers, projectUsers, project]);
    }


                public geMyAnalyticTypeDetails(userId: number): Observable<any> {
                    return this.http.get(`./assets/entities-analytic.json`);
                }

                public getAnalytics(userId, pageno, perpage): Observable<any> {
                    return this.http.get(`./assets/analytics.json`).pipe(map(this.extractData));
                    // return this.http.get(`${this.apiUrl}/users/${userId}/analytics` + `?pageno=${pageno}&pagesize=${perpage}`).pipe(map(this.extractData));
                }

                public getAnalytic(userId: string, analyticId: String): Observable<any> {
                    return this.http.get(`./assets/analytics.json`).pipe(map(this.extractData));
                    // return this.http.get(`./assets/analytics.json?analyticid=${analyticId}`).pipe(map(this.extractData));
                    // return this.http.get(`${this.apiUrl}/users/${userId}/analytics/${analyticId}`);
                }

                public getAnalyticsType(): Observable<any> {
                    return this.http.get(`./assets/analytics-type.json`);
                    // return this.http.get(`${this.apiUrl}/algorithms?algotypeid=335`);
                }

                public getAnalyticUsers(userId: string, analyticId: String, groupid: string) {
                    // const users = this.http.get(`${this.apiUrl}/users/${userId}/groups/${groupid}/users`);
                    const users = this.http.get(`./assets/users.json`);
                    // const analyticUsers = this.http.get(`${this.apiUrl}/users/${userId}/analytics/${analyticId}/users/`);
                    // const analyticUsers = this.http.get(`${this.apiUrl}/users/${userId}/projects/5/users`);
                    const analyticUsers = this.http.get(`./assets/analytics.json`);
                    // const analytic = this.http.get(`${this.apiUrl}/users/${userId}/analytics/${analyticId}`);
                    // return forkJoin([users, analyticUsers, analytic]);
                    return forkJoin([users, analyticUsers]);
                }

                public getAnalyticDataSets(userId: string, analyticId: String, groupid: string) {
                    // const dataSetReponse = this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/datasets?checkAtrributes=true`);
                    // const dataSetReponse = this.http.get(`${this.apiUrl}/users/${userId}/groups/${groupid}/datasets`);
                    const dataSetReponse = this.http.get(`./assets/datasets.json`);
                    // const analyticResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/analytics/${analyticId}/datasets`);
                    const analyticResponse = this.http.get(`./assets/analytics.json`);
                    const dataSourcesReponse = this.http.get(`./assets/datasources.json`);
                    return forkJoin([dataSetReponse, analyticResponse, dataSourcesReponse]);
                }

    public getProjectDataSets(userId: string, projectId: String, groupid: string) {
        // const dataSetReponse = this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/datasets?checkAtrributes=true`);
        const dataSetReponse = this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/datasets`);
        // const projectResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        const projectResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + `3` + `/datasets`);
        return forkJoin([dataSetReponse, projectResponse]);
    }

    public getProject(userId: string, projectId: String): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
    }

    public deleteProject(userId: string, projectId: String): Observable<any> {
        return this.http.delete(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
    }

    public getProjectDataSetAttributes(userId: String, projectId: string, entityId: string, dataSetId: String): Observable<any> {
        const datasetAttributes = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/datasets/` + dataSetId);
        const entityAttributes = this.http.get(`${this.apiUrl}/entities/` + entityId);
        return forkJoin([datasetAttributes, entityAttributes]);
    }

    public getDataSetAttributes(groupid: string, datasetId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).userid}/groups/${groupid}/datasets/${datasetId}/columns`);
    }

    public getProjectStateList() {
        return ['Describe Project', 'Add Users', 'Add Data Sets', 'Map Data Sets to Entity Model'];
    }

    public geMyProjectDetails(userId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects`);
    }

    public getMyTasksData(userId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tasks`);
    }

    getEntitiesResultsByProjectId(userId, selectedProjectId, pageno, perpage): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getEntitiesDetailsByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getEntitiesmasteredByLoggedInUser(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered` + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }
    getEntitiesmasteredByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getAttributesOfEntitiesmasteredByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `/attributes`).pipe(
            map(this.extractData));
    }

    public getActivityHistory(userid: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userid}/activities`);
    }

    getProjectEntitiesByProjects(userId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects` + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    cloneProject(project): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/` + project.userid + `/projects/copy`, project);
    }

    public getUserPic(userid: string, groupid: string): Observable<any> {
        return this.getUserPic(userid, groupid);
    }

    getTrainEntitiesByProjectId(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved/feedback/summary?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // userId, selectedProjectId  
    // getRunsuperviseByProjectId(param: IEntityPrams, entityType): Observable<any> {
    //     return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.selectedProjectId}/${entityType}/runsupervised`).pipe(
    //         map(this.extractData));
    // }

    getTrainEntitiesMasterByProjectId(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/feedback/summary?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // public getEntityResolvedData(userId, selectedProjectId, jobId) {
    //     return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/jobs/track`).pipe(
    //         map(this.extractData));
    // }

    public getCurrentActiveJobs(userId, selectedProjectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/jobs/track`);
    }

    public cancelJob(algoid, jobId): Observable<any> {
        return this.http.delete(`${this.apiUrl}/algorithms/` + algoid + `/run/` + jobId);
    }

    //    scenario id to be passed
    //     0 -->  Create Project --> Run model
    //     1 -->  Entities Resolved --> Train model --> Re Run Model
    //     2 -->  Entities Record Mastered --> Train model --> Re Run Model
    runModel(projectId: string, scenarioId: number): Observable<any> {
        return this.http.post(`${this.apiUrl}/algorithms/${AppGlobals.getRunModelAlgo(scenarioId)}/run`, { 'projectid': projectId });
    }
    // Pass stage as parameter as its being used in matcher and merger
    // reRunModel(param: IEntityPrams, modelStage: string): Observable<any> {
    //     return this.http.post(`${this.apiUrl}/algorithms/${AppGlobals.getRunModelAlgo(param.scenarioId)}/run`, { projectid: param.projectId, stage: modelStage, supervised: true });
    // }

    getSelectOptionTrainEntitiesByProjectId(userId, clusterId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/entitiesresolved/feedback/clusters/${clusterId}?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // {{url}}/users/4/projects/10/entitiesmastered/train/clusters/37a49974-9181-4b94-a0e4-28d67f4717d2?pageno=1&pagesize=5

    getEntitiesMasteredFeedback(userId, clusterId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/entitiesmastered/feedback/clusters/${clusterId}?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    updateMergerFeedback(userId, projectId, clusterId, requestPayload): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${userId}/projects/${projectId}/entitiesmastered/feedback/clusters/${clusterId}`, requestPayload);
    }

    public getSelectOptionModelListByDataSetId(userId, groupid, dataSetId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/groups/${groupid}/datasets/${dataSetId}/sampledata?checkAtrributes=true?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    public getselectViewMergedBtnModelList(userId, groupid, projectid, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/groups/${groupid}/datasets/${projectid}/mergesampledata?checkAtrributes=true?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    public hasLoaderExecuted(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/jobs/status?jobtype=loader&status=success`);
    }

    public getEntitiesSummaryData(userId, projectId, pickertype): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries?model=${pickertype}&summaryfor=viewresults&latest=true`);
    }

    public getOpentasks(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/tasks`);
    }

    // public getActivityDatasets(userId: string, projectId: string): Observable<any> {
    //     return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries`);
    // }

    public getActivityDatasets(userId: string, analyticId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/analytics/${analyticId}/users`);
    }

    public getEntitiesModelsData(userId: string, projectId: string, pickertype: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/modelruns?pickertype=${pickertype}`);
    }

    public getSparkjobHistory(userId: string, pagesize: number = 1000): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/sparkjobs?pagesize=${pagesize}`);
    }

    // public getSuggestedCluster(param: IEntityPrams): Observable<any> {
    //     const encodedIdentifier = encodeURI(param.clusterName);
    //     return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}/closest?identifier=${encodedIdentifier}`);
    // }

    // public updateFeedback(param: IEntityPrams): Observable<any> {
    //     return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}`, param.payload);
    // }

    public hasActiveJob(job): boolean {
        if (job && (job.jobcompleted || job.jobcompleted === undefined || (!job.jobcompleted && job.is_errored))) {
            return false;
        } else {
            return true;
        }
    }

    // public searchEntityCluster(param: IEntityPrams): Observable<any> {
    //     return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/${param.clusterId}/search?key=${param.searchString}`);
    // }

    saveUserRoles(userId: string, analyticId: string, user): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/analytics/` + analyticId + `/users`, user);
    }

}