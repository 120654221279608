import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance } from 'angular-slickgrid';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { ZsClContentService } from '../zs-cl-content/zs-cl-content.service';
import { MessageService } from 'src/app/common/components/message/message.service';
declare var $: any;

@Component({
  selector: 'zetta-zs-cl-review-prior-feedback',
  templateUrl: './zs-cl-review-prior-feedback.component.html',
  styleUrls: ['./zs-cl-review-prior-feedback.component.scss']
})
export class ZsClReviewPriorFeedbackComponent implements OnInit, OnDestroy, OnChanges {
  
  isLoadingDatasets: boolean = true;
  gridDatasets: AngularGridInstance;
  enableFinishTask: boolean = false;
  loggedInUserDetails: object = null;
  selectedMappedColumn:number;
  showmodelpopup : boolean = false;
  dsName;
  datasetId:any;
  catalog_id;
  showDownvoteModal:boolean=false;
  datasetColumnId:number;
  object_id;
  classInfo: any;
  viewTaskAssignments = false;
  screen_name = "classify";
  rowData:any;
  classes: any;
  taskId: number;
  projId: string;
  selectedRow: object = {};
  feedbackClasses: any = [];
  @Input() taskName: number;
  @Input() selectedRecId: string;
  @Input() ctmGridOptions;
  @Input() datasetsTableColDef;
  @Input() trainModalDatasetSettings
  @Input() is_task_completed;
  @Input() dataset_id;
  @Input() classify_project_feedback_id;
  @Output() hidePriorModal: EventEmitter<any> = new EventEmitter();
  @Output() refreshPriorModalData: EventEmitter<any> = new EventEmitter();
  @Input() projectId: number;
  @Input() recordId: string;
  @Input() selectedDatasetId: string;
  @Input() selectedFeedbackClass: string;
  @Input() capturedFeedbackVote: number;
  @Input() selectedTaskSummary: string;
  @Input() selectedTaskId: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private ZsClContentService:ZsClContentService,
    private projectService: ProjectService,
    private messageSvc: MessageService,
  ) { }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    sessionStorage.removeItem("serverfilter");
    sessionStorage.removeItem("sortfilter");
    sessionStorage.removeItem('resetpage_filter');
    this.activatedRoute.parent.params.subscribe(params => {
      this.projId = params['id'];
    });
    ($('#review_prior_modal') as any).modal('show');
    this.getSOCFeedbackClasses();
  }

  getSOCFeedbackClasses() {
    let self = this;
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    if (this.loggedInUserDetails && this.loggedInUserDetails['user_id'] && this.loggedInUserDetails['tenant_id']) {
      this.ZsClContentService.getClassifyPredictions(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'],this.projectId,this.recordId,this.selectedDatasetId)
      .subscribe((data) => {
        self.isLoadingDatasets = false; //rename var later
        if (data && data.currentpage) {
          // self.feedbackClasses = data.currentpage;
          let socFeedbackClass = data.currentpage.map((feedbackClass) => {
              if (feedbackClass.class === this.selectedFeedbackClass) {
                feedbackClass.vote = (this.capturedFeedbackVote == 1 || this.capturedFeedbackVote == 0) ? this.capturedFeedbackVote : null;
              }  else {
                feedbackClass.vote = null;
              }
              return feedbackClass
          })
          self.feedbackClasses = socFeedbackClass;
        }
        
      }, (err) => {
          self.isLoadingDatasets = false;
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.taskName && changes.taskName['currentValue']) { 
      this.taskName = changes.taskName['currentValue'];
    }
    if(changes && changes.selectedRecId && changes.selectedRecId['currentValue']) {
      this.selectedRecId = changes.selectedRecId['currentValue'];
    }
    if(changes && changes.ctmGridOptions && changes.ctmGridOptions['currentValue']) {
      this.ctmGridOptions = changes.ctmGridOptions['currentValue'];
    }
    if(changes && changes.datasetsTableColDef && changes.datasetsTableColDef['currentValue']) {
      this.datasetsTableColDef = changes.datasetsTableColDef['currentValue'];
    }
    if(changes && changes.trainModalDatasetSettings && changes.trainModalDatasetSettings['currentValue']) {
      this.trainModalDatasetSettings = changes.trainModalDatasetSettings['currentValue'];
    }
    if(changes && changes.is_task_completed && changes.is_task_completed['currentValue']) {
      this.is_task_completed = changes.is_task_completed['currentValue'];
    }
    if(changes && changes.dataset_id && changes.dataset_id['currentValue']) {
      this.dataset_id = changes.dataset_id['currentValue'];
    }
    if(changes && changes.classify_project_feedback_id && changes.classify_project_feedback_id['currentValue']) {
      this.classify_project_feedback_id = changes.classify_project_feedback_id['currentValue'];
    }
    // if(changes && changes.mappings && changes.mappings['currentValue']) {
    //   let mappings = changes.mappings['currentValue'];
    //   mappings.forEach(element => {
    //     element.id = Math.random();
    //   });
    //   this.mappings = mappings;
    //   this.isLoadingDatasets = false;
    //   ($('#review_prior_modal') as any).modal('show');
    // }
  }

  ngOnDestroy(): void {
    ($('#review_prior_modal') as any).modal('hide');
  }

  onClose() {
    this.enableFinishTask = false;
    this.feedbackClasses = [];
    this.hidePriorModal.emit(true);
    $('#review_prior_modal').modal('hide');
  }

  onDatasetsGridCreation(grid) {
    this.gridDatasets = grid;
  }

  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    if (row !== undefined && row !== null) {
      this.showmodelpopup = true;
      this.dsName = row.class;
      if (eventEle.target.className.trim() == 'fal fa-thumbs-down handDown' || eventEle.target.className.trim() == 'fal handDown fa-thumbs-down' || eventEle.target.className.trim() == 'handDown fal fa-thumbs-down' || eventEle.target.className.trim() == 'fal fa-thumbs-down') {
        this.showDownvoteModal = true;
        this.selectedRow = row;
        this.selectedRow['selectedIndex'] = args.row;// added to get reference to remove upvote/downvote on downvote model finish
        this.datasetColumnId = row.dataset_type_id;
        let dislikeData = {
          score: row.score,
          id: row.id,
          name: row.name,
          confidence: row.confidence,
          vote: 0,
          task_id: this.taskId,
          class: row.class,
          dataset_id: this.dataset_id
        };
        this.classInfo = dislikeData;
        this.rowData = args.row;
        this.classes = 'fal fa-thumbs-down';
        this.selectedRow['vote'] = 0;
      } else if (eventEle.target.className.trim() === 'fal fa-thumbs-up') {
        this.selectedRow = row;
        let allTasks = this.gridDatasets.dataView.getItems();
        allTasks.forEach((task, ind) => {
          if(task.vote == 1) {
            $('#review_prior_modal #thumbs-up-' + ind).removeClass('fas fa-thumbs-up');
            $('#review_prior_modal #thumbs-up-' + ind).addClass('fal fa-thumbs-up');
          }});
        $('#review_prior_modal #thumbs-up-' + args.row).removeClass('fal');
          $('#review_prior_modal #thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
          $('#review_prior_modal #thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
          $('#review_prior_modal #thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          if (row.vote === 0) {
            $('#review_prior_modal #thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
            $('#review_prior_modal #thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
            $('#review_prior_modal #thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
            $('#review_prior_modal #thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
            this.enableFinishTask = true;
          }
          this.selectedRow['vote'] = 1;
          this.enableFinishTask = true;
          this.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(args.row);
      }
    }
  }

  popupoutput(message){
    this.showmodelpopup = message;
  }

  checkFinishTaskBtnValidation(tasks) {
    if(tasks && tasks.length > 0 && !this.is_task_completed) {
          let at_least_one_vote = tasks.filter(task => task.vote == 1 || task.vote == 0);
          this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
    }
  }

  downVoted(event) {
    if(event) {
      if(this.classes === 'fas fa-thumbs-down') {
        $('#review_prior_modal #thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
        $('#review_prior_modal #thumbs-down-' + this.rowData).addClass('fal fa-thumbs-down');
      } else {
        $('#review_prior_modal #thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
        $('#review_prior_modal #thumbs-up-' + this.rowData).addClass('fal fa-thumbs-up');
      }
      this.selectedRow['class'] = event.classdata.class;
      this.enableFinishTask = true;
      let selectedRowIndex = this.selectedRow['selectedIndex'];
      if (selectedRowIndex == 0 || selectedRowIndex) {
        this.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(selectedRowIndex);
      }
    }
  }

      closeModalPopup(event){
        if(event){
          $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
          $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
        }
        this.showDownvoteModal = false
      }

      finishTasks() {
        const data = {
          "user_id": this.loggedInUserDetails['user_id'], 
          "tenant_id": this.loggedInUserDetails['tenant_id'],
          "projId": this.projId,
          payload: {
            'vote': this.selectedRow['vote'],
            'class': this.selectedRow['class'],
            'classify_project_feedback_id': this.classify_project_feedback_id,
          }
        };
        this.ZsClContentService.updateClassIfySocTaskHistoryFinishFeedback(data)
        .subscribe(resp => {
          if(resp) {
            this.refreshPriorModalData.emit(true);
          }
        }, err => {
          this.messageSvc.sendMessage({ message: (err && err.error && err.error.error) ? err.error.error : 'Oops! something went wrong!.', type: 'ERROR', hideboard: true });
        });
      }

      revokeUpvoteAccessForOtherRecordSOCProjectFeedback(upvotedRow) {
        let totalRecords = this.feedbackClasses.length;
        for(let row =0; row < totalRecords; row++) {
          if (row != upvotedRow) {
            $('#review_prior_modal #thumbs-up-'+row).removeClass('fas fa-thumbs-up');
            $('#review_prior_modal #thumbs-up-'+row).addClass('fal fa-thumbs-up');
            $('#review_prior_modal #thumbs-down-' + row).removeClass('fas fa-thumbs-down');
            $('#review_prior_modal #thumbs-down-' + row).addClass('fal fa-thumbs-down');
          } 
        }
      }
}
