import { ZettaUtils } from "../../shared/zettaUtils";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";
import { UserGroupService } from "../../../zettasense/usergroup/usergroup.service";
import { MessageService } from "../../components/message/message.service";
import { User } from "../../../common/shared/zetta-models";
declare const $: any;
import { CustomDropDownEditor } from "src/app/zettasense/usergroup/dropdown.editor";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContentService } from "src/app/zettasense/content/content.service";
import { AppGlobals } from '../../../common/shared/app.globals';
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";

const customDropDownFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) =>
  dataContext.action !== "delete"
    ? `<div class='row'>
                <div class='col-md-10 text-truncate pr-0'>
                    <label id='user-${columnDef.field}' placeholder='Select Group' "title='${dataContext.group_name}'>${dataContext.group_name}</label>
                </div>
                <div class='col-md-2 p-0'> 
                    <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                </div>
            </div>`
    : `<div class='row'>
                        <div class='col-md-10 text-truncate pr-0'>
                            <label placeholder='Select Group' title='${dataContext.group_name}'>${dataContext.group_name}</label>
                        </div>
                        <div class='col-md-2 p-0'> 
                            <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                        </div>
           </div>`;

const customUserRowFormatter: Formatter = (
  row: number,
  cell: number,
  value: any,
  columnDef: Column,
  dataContext: any
) => {
  return dataContext.action !== "delete"
    ? `<input id='user-${columnDef.field}' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' tabindex='${columnDef.params.tabindex}' 
                   autofocus="autofocus" value='${value}'></input>`
    : `<div class='text-truncate'><span class='text-truncate' title='${value}'> ${value}</span></div>`;
};

const lockUnlockFormattter2 = (params) => {
  if (params.value === "none") return "";
  return params.value
    ? `<div class='text-center'> <i title='Unlock account' class="fas fa-lock actionInlineBtn blue-color-imp blueColor mr-162"
        ></i></div>`
    : `<div class='text-center'><i title='Account is Unlocked' class="fa fa-unlock actionInlineBtn grey-color cursor-default-imp mr-162"></i></div>`;
};

export const RemoveFormattter = (params) => {
  if(params && params.data && params.data.action ==='delete') {
    return `<i class="fa fa-minus actionDeletebtn "  data-toggle="modal" data-backdrop="false" data-target=#${params.colDef.field}></i>`
  } else {
    return `<i class="fa fa-plus actionDeletebtn"></i>`;
  }
}

const acativeLinkFormatter = (params) => {
  if(params && params.value) {
    return `<div class='text-truncate'><span class='text-truncate' title='${params.value}'> ${params.value}</span></div>`;
    }
}
@Component({
  selector: "zetta-admin-usergroup",
  templateUrl: "./admin-usergroup.component.html",
  styleUrls: ["./admin-usergroup.component.scss"]
})
export class AdminUserGroup implements OnInit {
  userDataSource: any = [];
  usertableRows: any = [];
  isUserResponseReady = false;
  userTableColDef: any;
  columnDefs: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  gridOptions: any;
  grouplist: any;
  grouplistDropDown: any;
  // used for change password model window
  selectedUserMetadata: any;
  defaltGroupRow: any = 0;
  selectedGroupMetadata: any = 0;
  eventData: any;
  gridApi;
  leftGridAction = false;
  tableSettings: object = { height: "100%", width: "100%" };
  userGridRef: AngularGridInstance;
  groupGridRef: AngularGridInstance;
  totalLeft = 0;
  pageLeft = 1;
  limitLeft = 100;
  totalRight = 0;
  pageRight = 1;
  limitRight = 100;
  hasScrolled = false;
  noData = false;
  hasScrolledRight = false;
  noDataRight = false;
  isLoading = false;
  loggedInUserDetails: [];
  selectedFiles: string[] = [];
  inputFocus: string = "#user-user_name";
  usersForm: FormGroup;
  group_name;
  group_id;
  user_exist = false;
  submitted = false;
  sosUser: Boolean;
  isFormValid = false;
  addUser: User = new User();
  fu_type_users: any;
  clearModal = Math.random();

  constructor(
    private userGroupService: UserGroupService,
    public zettaUtils: ZettaUtils,
    private formBuilder: FormBuilder,
    private messageSvc: MessageService,
    public contentSvc: ContentService,
  ) {}

  ngOnInit() {
    this.fu_type_users = AppGlobals.FU_TYPE_USERS_SUPERUSERS;
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    this.gridOptions = {
      enableGridMenu: false,
      frozenRow: -1,
      enableAddRow: false,
      selectable: true,
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: true,
      },
      editable: true,
      autoEdit: true,
      enableFiltering: true,
      CheckboxSelector: false,
    };
    this.initUserGrid();
    this.setUserForm();
   this.getUser(this.pageLeft, this.limitLeft);
   this.getGroupList();
   if(sessionStorage.getItem('loginProvider') == 'azure_sos') {
       this.sosUser = true;
   } else {
       this.sosUser = false;
   }
  }

  initUserGrid() {
    this.userTableColDef = [
      {
        headerName: 'User Name',
        field: 'user_name',
        sortable: true, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRenderer: (params) => acativeLinkFormatter(params),
        floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
        minWidth: 140,
        tooltipField : 'user_name'
      },
      {
        headerName: "Account Lock Status",
        field: "is_locked",
        sortable: false,
        datatype: "String",
        filterable: false,
        cellRenderer: (params) => lockUnlockFormattter2(params),
        cssClass: "text-center",
        headerCssClass: "text-start lock-status",
        minWidth: 100,
      },
      {
        headerName: "First Name",
        field: "first_name",
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        datatype: "String",
        filterable: true,
        cellRenderer: (params) => acativeLinkFormatter(params),
        minWidth: 120,
        params: { placeholder: "Add first name", tabindex: 2 },
      },
      {
        headerName: "Last Name",
        field: "last_name",
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        datatype: "String",
        filterable: true,
        cellRenderer: (params) => acativeLinkFormatter(params),
        minWidth: 120,
        params: { placeholder: "Add last name", tabindex: 3 },
      },
      {
        headerName: "E-mail",
        field: "email",
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        datatype: "String",
        filterable: true,
        cellRenderer: (params) => acativeLinkFormatter(params),
        minWidth: 120,
        params: { placeholder: "Add E-mail", tabindex: 4 },
      },
      {
        headerName: " ",
        field: "action",
        sortable: false,
        datatype: "String",
        filterable: false,
        cellRenderer: (params) => RemoveFormattter(params),
        cssClass: "text-center",
        headerCssClass: "text-center",
        minWidth: 20,
      },
    ];
    this.isUserResponseReady = true;
  }
  get formdata() {
    return this.usersForm.controls;
  }
  async getGroupList() {
    let groupService = await this.userGroupService.getPagedGroups(
      this.loggedInUserDetails["user_id"],
      this.loggedInUserDetails["tenant_id"],
      this.pageRight,
      this.limitRight
    );
    this.grouplistDropDown = groupService["currentpage"].map((item) => ({
      value: item.group_id,
      text: item.name,
    }));
  }
  getAddUserRow() {
    return {
      id: Math.random(),
      user_id: "-1",
      user_name: "",
      first_name: "",
      last_name: "",
      email: "",
      group_id: "1",
      group_name: "Default Group",
      password: "",
      action: "add",
      is_locked: "none",
    };
  }
  readUserDetails() {
    this.addUser.user_name = $("#user-user_name")[0]["value"];
    this.addUser.first_name = $("#user-first_name")[0]["value"];
    this.addUser.last_name = $("#user-last_name")[0]["value"];
    this.addUser.email = $("#user-email")[0]["value"];
  }

  validateNewUser() {
    if (
      this.addUser.user_name &&
      this.addUser.first_name &&
      this.addUser.last_name &&
      this.addUser.email &&
      this.addUser.group_id
    ) {
      return true;
    } else {
      this.messageSvc.sendMessage({
        message: "Please fill all user fields",
        type: "INFO",
        hideboard: true,
      });
      return false;
    }
  }

  getModelPosition(position: string, isPasswordModel?: boolean) { 
    let styleValue = '';
    if (position === 'top') {
        styleValue = (this.eventData.event.pageY - 80) + 'px';
    } else if (position === 'left') {
            styleValue = 25 + 'vw';
        
    }
    return styleValue;
}

   // Delete User Rows on Delete Action Confirmation
   deleteSelectedRow() {
    if (this.leftGridAction) {
        this.userGroupService.deleteUsers(this.selectedUserMetadata.user_id).subscribe(responseList => {
            this.gridApi.applyTransaction({ remove: [this.selectedUserMetadata] });
            this.totalRight = this.totalRight- 1;
            $('#btnCloseActionModel').click();
        }, err => {
        });
    }
}

  handleUserGridClickEvent(eventData) {
    this.eventData = eventData;
    this.gridApi = eventData.api;
    const event = eventData.event;
    const metadata = eventData.data;
    this.selectedUserMetadata = metadata;
    this.leftGridAction = true;
    this.defaltGroupRow = eventData.rowIndex;
    const row = eventData.data;
    if (row !== undefined && row !== null) {
      if (event.target.className.includes("fa fa-plus actionInlineBtn")) {
        this.readUserDetails();
         if (!this.addUser.group_id) {
           this.addUser.group_id = 1;
          this.addUser.group_name = "Default Group";
         }
        if (this.validateNewUser()) {
          const group_name = this.addUser.group_name;
          this.addUser.group_name = undefined;
          this.addUser.is_super_admin = true;
          this.userGroupService.saveUser(this.addUser).subscribe(
            (responseList) => {
              responseList.id = Math.random();
              responseList.password = "Change Password";
              responseList.action = "delete";
              responseList.group_id = this.addUser.group_id;
              responseList.group_name = group_name;
              this.gridApi.applyTransaction({remove: [row] });
              this.gridApi.applyTransaction({add: responseList });
              const currentData = this.userGridRef.dataView.getItems();
              this.usertableRows.push(responseList);
              currentData.splice(0, 0, this.getAddUserRow());
              this.usertableRows = currentData;
              this.isUserResponseReady = true;
              this.addUser = new User();
            },
            (err) => {
              this.isUserResponseReady = true;
              this.addUser.group_name = group_name;
              this.messageSvc.sendMessage({
                message: err.error[1].message.message,
                type: "INFO",
                hideboard: true,
              });
            }
          );
        }
      } else if (
        event.target.className.includes(
          "fas fa-lock actionInlineBtn blue-color-imp"
        )
      ) {
        let obj = {
          is_locked: true,
          failed_attempt_count: 0,
          failed_attempt_ts: new Date(),
        };
        this.userGroupService.changeLockStatus(row["user_id"], obj).subscribe(
          (res) => {
            row["is_locked"] = true;
            this.gridApi.applyTransaction({ update: [row] });
          },
          (err) => {
            this.messageSvc.sendMessage({
              message: err ? err.error.message : "",
              type: "INFO",
              hideboard: true,
            });
          }
        );
      }
    }
  }

  getAllUserByGroup(rowNumber) {        
   this.usertableRows.forEach(element => {
        element.id = Math.random();
        element.group_name = this.grouplistDropDown[rowNumber].text;
    });
}
  setUserForm() {
    this.usersForm = this.formBuilder.group({
      user_name: ["", Validators.required],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      email: ["", Validators.required],
      password: [],
    });
  }
  updateUserData(userData) {
    const newUser = new User;
    if ('group_id' === userData.editor.args.column.field) {
        const oldUser: User = userData.editor.args.item;
        newUser.user_id = oldUser.user_id;
        newUser.user_name = oldUser.user_name;
        newUser.group_id = oldUser.group_id;
        newUser.first_name = oldUser.first_name;
        newUser.last_name = oldUser.last_name;
        // Not a first row
        if (userData.row !== 0) {
            this.userGroupService.saveUser(newUser).subscribe(responseList => {
            }, err => {
                this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
            });
        } else {
            this.addUser.group_id = oldUser.group_id;
            this.addUser.group_name = oldUser.group_name;
        }
    }
}
  userGrid(grid: AngularGridInstance) {
    this.userGridRef = grid;
  //   this.userGridRef.slickGrid.onBeforeEditCell.subscribe(function (e, args) {
  //     if (args.column.field === 'group_id') {
  //         return true;
  //     } else {
  //         return false;
  //     }
  // });
  }
  getUser(pageNo, limit) {
    this.contentSvc.getAdminUsersPaginate(pageNo, limit).subscribe((resp) => {
      this.usertableRows = resp['currentpage'];
      this.usertableRows = this.usertableRows.filter(user => user.is_super_admin === true);
          this.totalRight = resp['totalrecords'];
          if (this.usertableRows.length === 0) {
            this.noData = true;
          }

          this.usertableRows= this.usertableRows.filter(user => user.is_super_admin === true);
          let concatedLeftRows = this.usertableRows;

            this.usertableRows =  this.usertableRows.map((item) => {
              item["password"] = "Change Password";
              item["action"] = "delete";
              item["id"] = Math.random();
              return item;
            });

          const firstRow = this.getAddUserRow();
          this.setNewUserDetails(firstRow);
          this.usertableRows.splice(0, 0, firstRow);
          this.isUserResponseReady = true;
          this.usertableRows = concatedLeftRows;
          if (pageNo < resp['totalpages']) {
            this.hasScrolled = false;
          }
        },
        (err) => {
          this.isUserResponseReady = true;
          this.messageSvc.sendMessage({
            message: err.error.message,
            type: "INFO",
            hideboard: true,
          });
        }
      );
  }

  setNewUserDetails(row) {
    row.user_name = this.addUser.user_name;
    row.first_name = this.addUser.first_name;
    row.last_name = this.addUser.last_name;
    row.email = this.addUser.email;
    row.group_name = this.addUser.group_name;
    row.group_id = this.addUser.group_id;
}

  validateForm() {
    if (
      this.usersForm.controls.user_name.value &&
      this.usersForm.controls.first_name.value &&
      this.usersForm.controls.last_name.value &&
      this.usersForm.controls.email.value &&
      this.usersForm.controls.password.value
    ) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

receiveFileName(eve) {
  if(eve) {
    this.getGroupList();
    this.usertableRows = [];
    this.getUser(1, this.limitLeft);
  }
}



  onCancel() {
    this.usersForm.reset();
  }
  onSaveUser() {
    this.submitted = true;
    if (this.usersForm.invalid) {
      return;
    }
    let app_id = [];
    app_id.push(this.loggedInUserDetails["preference"].default_app_id);    
    const userPayload = {
      user_name: this.usersForm.controls.user_name.value,
      password: this.usersForm.controls.password.value,
      first_name: this.usersForm.controls.first_name.value,
      last_name: this.usersForm.controls.last_name.value,
      email: this.usersForm.controls.email.value,
      group_name: this.loggedInUserDetails["group_name"],
      is_super_admin: true,
      is_tenant_admin: false,
      is_internal_user: false,
      app_id: app_id,
    };
    this.userGroupService.createUser(userPayload).subscribe(
      (resp) => {
        $("#addUserModal").modal("hide");
        this.usersForm.reset();
        this.submitted = false;
        this.usertableRows =[];
       this.getUser(1, this.limitLeft);
        this.messageSvc.sendMessage({
          message: "User has been added successfully!",
          type: "SUCCESS",
          hideboard: true,
        });
      },
      (error) => {
        this.submitted = false;
        if (error.status == 400) {
          this.user_exist = true;
        } else {
          $("#addUserModal").modal("hide");
        }
      }
    );
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }

  onNext(): void {
    this.pageLeft++;
    this.getUser(this.pageLeft, this.limitLeft);
  }

  onRightGridScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
}
