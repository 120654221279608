import { AppGlobals } from '../../common/shared/app.globals';
import { modelFormatter } from '../../common/shared/formatters/modelFormatter';
import { addRemoveFormattter } from '../../common/shared/formatters/addRemoveFormatter';
import { UserGroupService } from './usergroup.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Group, User } from '../../common/shared/zetta-models';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { CustomDropDownEditor } from './dropdown.editor';
import { PatternValidator } from '../../common/shared/formValidators/patternValidator';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { MessageService } from '../../common/components/message/message.service';
import { environment } from '../../../environments/environment';
declare var $: any;

import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { agAddRemoveFormatter } from 'src/app/common/shared/formatters/ag-add-remove-formatter';
import { DropdownCellRendererComponent } from 'src/app/common/shared/cell-renderer/custom-cell-render-formatter/custom-edit-mode-dropdown-formatter-component';
import { AgDropdownCellRendererComponent } from 'src/app/common/shared/cell-renderer/custom-cell-render-formatter/AgDropdownCellRendererComponent';
const customDropDownFormatter = (paraams) => {
    paraams.data.action !== 'delete'
        ? `<div class='row'>
                <div class='col-md-10 text-truncate pr-0'>
                    <label id='user-${paraams.column.colId}' placeholder='Select Group' "title='${paraams.data.group_name}'>${paraams.data.group_name}</label>
                </div>
                <div class='col-md-2 p-0'> 
                    <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                </div>
            </div>`
            
        : `<div class='row'>
                        <div class='col-md-10 text-truncate pr-0'>
                            <label placeholder='Select Group' title='${paraams.data.group_name}'>${paraams.data.group_name}</label>
                        </div>
                        <div class='col-md-2 p-0'> 
                            <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                        </div>
           </div>`;
        }

const customRowFormatter = (params) => {
if(params && params.data.action !== 'delete') {
    return `<input id='user-${params.column.colId}' class='ctmInputBox' type='text' placeholder='${params.placeholder}' tabindex='${params.tabindex}' 
                   autofocus="autofocus" value='${params['value']}'></input>`;
} else {
    return `<div class='text-truncate'><span class='text-truncate' title='${params['value']}'> ${params['value']}</span></div>`;
}
};

const customGroupRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return dataContext.action !== 'delete' ? `<input id='group-${columnDef.field}' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' value='${value}'></input>` : `<div class='text-truncate'><span class='text-truncate' title='${value}'> ${value}</span></div>`;
};

// const lockUnlockFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
//     if (value === 'none')
//         return ''
//     return value ? `<div class='text-center'> <i title='Unlock account' class="fas fa-lock actionInlineBtn blue-color-imp lock-user"
//         ></i></div>` : `<div class='text-center'><i title='Account is Unlocked' class="fa fa-unlock actionInlineBtn grey-color cursor-default-imp"></i></div>`;
// };

const lockUnlockFormattter = (params) => {
    if (params.value === 'none')
        return ''   
    return params.value ? `<div class='text-center'> <i title='Unlock account' class="fas fa-lock actionInlineBtn blue-color-imp"
        ></i></div>` : `<div class='text-center'><i title='Account is Unlocked' class="fa fa-unlock actionInlineBtn grey-color cursor-default-imp"></i></div>`;
};

@Component({
    selector: 'zetta-usergroup',
    templateUrl: './usergroup.component.html',
    styleUrls: ['./usergroup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UsergroupComponent implements OnInit, OnDestroy {

    passwordChangeForm: FormGroup;
    userDataSource: any = [];
    groupDataSource: any = [];
    addedUser = new User();
    addedGroup = new Group();
    usertableRows: any = [];
    grouptableRows: any = [];
    isUserResponseReady = false;
    isGroupResponseReady = false;
    userTableColDef: any;
    groupTableColDef: any;
    gridOptions: any;
    grouplist: any;
    grouplistDropDown: any;
    // used for change password model window
    selectedUserMetadata: any;
    defaltGroupRow: any = 0;
    selectedGroupMetadata: any = 0;
    eventData: any;
    leftGridAction = false;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    userGridRef: AngularGridInstance;
    groupGridRef: AngularGridInstance;
    addUser: User = new User();
    addGroup: Group = new Group();
    totalLeft = 0;
    pageLeft = 1;
    limitLeft = 100;
    totalRight = 0;
    pageRight = 1;
    limitRight = 100;
    hasScrolled = false;
    noData = false;
    hasScrolledRight = false;
    noDataRight = false;
    isLoading = false;
    loggedInUserDetails: [];
    selectedFiles: string[] = [];
    inputFocus: string = "#user-user_name";
    usersForm: FormGroup;
    groupsForm: FormGroup;
    group_name;
    user_exist = false;
    submitted = false;
    sosUser: Boolean;
    isFormValid = false;
    userNameExits: string;
    emailExits: string;
    clearModal = 0;
    fu_type_users: any;
    columnDefs: ColDef[] = [];
    fsGridOptions: GridOptions;
    agGrid: AgGridAngular;
    gridApi;

    constructor(private userGroupService: UserGroupService,
                private formBuilder: FormBuilder,
                public zettaUtils: ZettaUtils,
                private messageSvc: MessageService) { }

    groupGrid(grid: AngularGridInstance) {
        this.groupGridRef = grid;
        this.groupGridRef.slickGrid.onBeforeEditCell.subscribe(function (e, args) {
            return false;
        });

        this.groupGridRef.slickGrid.onSort.subscribe((e, args) => {
            this.readGroupDetails();
            const firstRow = grid.gridService.getDataItemByRowIndex(0);
            setTimeout(() => {
                this.setNewGroupDetails(firstRow);
                grid.gridService.deleteDataGridItem(firstRow);
                const currentData = grid.dataView.getItems();
                currentData.splice(0, 0, firstRow);
                grid.dataView.setItems(currentData);
                grid.gridService.setSelectedRows([]);
            }, 500);
        });
    }

    userGrid(grid: AngularGridInstance) {
        this.userGridRef = grid;
        // this.userGridRef.slickGrid.onBeforeEditCell.subscribe(function (e, args) {
        //     if (args.column.field === 'group_id') {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });

        // this.userGridRef.slickGrid.onSort.subscribe((e, args) => {
        //     this.readUserDetails();
        //     const firstRow = grid.gridService.getDataItemByRowIndex(0);
        //     setTimeout(() => {
        //         this.setNewUserDetails(firstRow);
        //         grid.gridService.deleteDataGridItem(firstRow);
        //         const currentData = grid.dataView.getItems();
        //         currentData.splice(0, 0, firstRow);
        //         this.userGridRef.dataView.setItems(currentData);
        //         this.userGridRef.gridService.setSelectedRows([]);
        //     }, 500);
        // });
    }

    ngOnInit() {
        this.fu_type_users = AppGlobals.FU_TYPE_USERS_SUPERUSERS;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.gridOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
              },
            editable: true,
            autoEdit: true,
            enableFiltering: true,
            CheckboxSelector: false,
        };
        this.passwordChangeForm = this.formBuilder.group({
            old_password: ['', [Validators.minLength(8)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirm_password: ['', Validators.required]
        }, {
            validator: [PatternValidator('password', AppGlobals.NUMERIC_REGEX, { hasNumber: true }, 'confirm_password'), PatternValidator('password', AppGlobals.CHARACTERS_REGEX, { hasNumber: true }, 'confirm_password')]
        });
        this.setGroupForm();
        this.setUserForm()
        // this.getPaggedGroups(this.pageRight, this.limitRight);
        this.getUserAndGroupList();
        if(sessionStorage.getItem('loginProvider') == 'azure_sos') {
            this.sosUser = true;
        } else {
            this.sosUser = false;
        }
    }

    ngOnDestroy() {
        this.messageSvc.clearMessage();
    }

    setUserForm() {
        this.usersForm = this.formBuilder.group({
            user_name: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', Validators.required],
            password: [],
        })
    }

    get f() { return this.usersForm.controls; }

    setGroupForm() {
        this.groupsForm = this.formBuilder.group({
            group_name: [],
            description: []
        })
    }

    async getUserAndGroupList() {
        let groupService = await this.userGroupService.getPagedGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], 1, this.limitRight);
        this.grouplistDropDown = groupService['currentpage'].map((item) => ({ 'value': item.group_id, 'text': item.name }));        
        this.userGroupService.getUserAndGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.pageLeft, this.limitLeft).subscribe(responseList => {
            this.userDataSource = responseList[1];
            // this.grouplist = responseList[1]['currentpage'].map((item) => ({ 'value': item.group_id, 'text': item.name }));
            this.grouplist = responseList[1];
            this.totalRight = responseList[1]['total_records'];
            this.totalLeft = responseList[1]['currentpage'][this.defaltGroupRow].users.length; 
            this.group_name = responseList[1]['currentpage'][this.defaltGroupRow].name;       
            if (this.grouplist['currentpage']) {
                this.initGroupGrid();
            }
            if (this.userDataSource) {
                this.getAllUserByGroup(this.defaltGroupRow);
            }
        }, err => {
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }

    handleGroupGridClickEvent(eventData) {
        this.eventData = eventData;
        const args = eventData.args;
        const event = eventData.eventData;
        const metadata = this.groupGridRef.gridService.getColumnFromEventArguments(args);
        this.selectedGroupMetadata = metadata;
        this.defaltGroupRow = metadata.row;
        if (this.defaltGroupRow != null && this.defaltGroupRow != undefined) {
            this.getAllUserByGroup(this.defaltGroupRow)
        }
        this.leftGridAction = false;
        const row = this.groupGridRef.gridService.getDataItemByRowIndex(args.row);        
        this.group_name = row.name;
        if (row !== undefined && row !== null) {
            if (event.target.className.includes('fa fa-plus actionInlineBtn')) {                
                this.readGroupDetails();
                if (this.validateNewGroup()) {
                    this.userGroupService.saveGroup(this.addGroup, this.loggedInUserDetails['user_id']).subscribe(responseList => {
                        this.isGroupResponseReady = true;
                        responseList.id = Math.random();
                        responseList.action = 'delete';
                        this.groupGridRef.gridService.deleteDataGridItem(row);
                        this.grouptableRows.push(responseList);
                        this.groupGridRef.gridService.addItemToDatagrid(responseList, true);
                        const currentData = this.groupGridRef.dataView.getItems();
                        currentData.splice(0, 0, this.getAddGroupRow());
                        this.groupGridRef.dataView.setItems(currentData);
                        this.groupGridRef.gridService.setSelectedRows([]);
                        this.addGroup = new Group();
                        // newly added
                        let obj = {};
                        // obj['value'] = responseList['group_id'];
                        // obj['text'] = responseList['name'];
                        obj['group_id'] = responseList['group_id'];
                        obj['name'] = responseList['name'];
                        // this.grouplist.splice(0, 0, obj);
                        this.grouplist['currentpage'].splice(0, 0, obj);
                        // this.userGridRef.dataView.refresh()
                    }, err => {
                        this.isGroupResponseReady = true;
                        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                    });
                } else if (event.target.className.includes('fas fa-lock actionInlineBtn blue-color-imp')) {
                    let obj = {
                        is_locked: true,
                        failed_attempt_count: 0,
                        failed_attempt_ts: new Date()
                    }
                    this.userGroupService.changeLockStatus(row['user_id'], obj).subscribe(res => {
                        row['is_locked'] = true;
                        this.userGridRef.gridService.updateDataGridItem(row);
                    }, err => {
                        this.messageSvc.sendMessage({ message: err ? err.error.message : '', type: 'INFO', hideboard: true });

                    })
                }
            }
        }
    }

    updateUserData(userData) {
        const newUser = new User;
        if ('group_id' === userData.editor.args.column.field) {
            const oldUser: User = userData.editor.args.item;
            newUser.user_id = oldUser.user_id;
            newUser.user_name = oldUser.user_name;
            newUser.group_id = oldUser.group_id;
            newUser.first_name = oldUser.first_name;
            newUser.last_name = oldUser.last_name;

            if (this.userGroupService.getObjectFromSession('userInfo').user_id === newUser.user_id) {
                const user = this.userGroupService.getObjectFromSession('userInfo');
                user.group_id = newUser.group_id;
                this.userGroupService.addObjectToSession('userInfo', user);
            }
            // Not a first row
            if (userData.row !== 0) {
                this.userGroupService.saveUser(newUser).subscribe(responseList => {
                }, err => {
                    this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                });
            } else {
                this.addUser.group_id = oldUser.group_id;
                this.addUser.group_name = oldUser.group_name;
            }
        }
    }

    getModelPosition(position: string, isPasswordModel?: boolean) {
        let styleValue = '';
        if (position === 'top') {
            styleValue = (this.eventData.pageY - 250) + 'px';
        } else if (position === 'left') {
            if (this.leftGridAction) {
                styleValue = 10 + 'vw';
            } else {
                styleValue = -39 + 'vw';
            }
        }
        return styleValue;
    }

    handleUserGridClickEvent(eventData) {
        this.eventData = eventData;
        const args = eventData.args;
        const event = eventData.event;
        this.gridApi = eventData.api;
        const metadata = eventData.data;
        this.selectedUserMetadata = metadata;
        this.leftGridAction = true;
        const row = eventData.data;
        if (row !== undefined && row !== null) {
            if (event.target.className.includes('fa fa-plus actionInlineBtn')) {
                this.readUserDetails();
                // validate User Object
                // Default Password
                // if (this.addUser.password === undefined) {
                //     this.messageSvc.sendMessage({ message: 'Please fill set password fields', type: 'INFO', hideboard: true });
                //     return;
                // }
                if (!this.addUser.group_id) {
                    this.addUser.group_id = 1;
                    this.addUser.group_name = "Default Group";
                }
                if (this.validateNewUser()) {
                    const group_name = this.addUser.group_name;
                    this.addUser.group_name = undefined;
                    this.addUser.is_super_admin = false;
                    this.userGroupService.saveUser(this.addUser).subscribe(responseList => {
                        responseList.id = Math.random();
                        responseList.password = 'Change Password';
                        responseList.action = 'delete';
                        responseList.group_id = this.addUser.group_id;
                        responseList.group_name = group_name;
                        this.gridApi.applyTransaction({remove: [row] });
                        this.gridApi.applyTransaction({add: responseList });          
                        this.usertableRows.push(responseList);
                        this.usertableRows.splice(0, 0, this.getAddUserRow());
                        this.isUserResponseReady = true;
                        this.addUser = new User();
                    }, err => {
                        this.isUserResponseReady = true;
                        this.addUser.group_name = group_name;
                        this.messageSvc.sendMessage({ message: err.error[1].message.message, type: 'INFO', hideboard: true });
                    });
                }
            } else if (event.target.className.includes('fas fa-lock actionInlineBtn blue-color-imp')) {
                let obj = {
                    is_locked: true,
                    failed_attempt_count: 0,
                    failed_attempt_ts: new Date()
                }
                this.userGroupService.changeLockStatus(row['user_id'], obj).subscribe(res => {
                    row['is_locked'] = true;
                    this.gridApi.applyTransaction({ update: [row] });
                }, err => {
                    this.messageSvc.sendMessage({ message: err ? err.error.message : '', type: 'INFO', hideboard: true });

                })
            }
        }
    }

    // Validate New User Details
    validateNewUser() {
        if ((this.addUser.user_name && this.addUser.first_name && this.addUser.last_name && this.addUser.email && this.addUser.group_id)) {
            return true;
        } else {
            this.messageSvc.sendMessage({ message: 'Please fill all user fields', type: 'INFO', hideboard: true });
            return false;
        }
    }

    // Validate New User Details
    validateNewGroup() {
        if ((this.addGroup.name && this.addGroup.description)) {
            return true;
        } else {
            this.messageSvc.sendMessage({ message: 'Please fill all group fields', type: 'INFO', hideboard: true });
            return false;
        }
    }

    readUserDetails() {
        this.addUser.user_name = $('#user-user_name')[0]['value'];
        this.addUser.first_name = $('#user-first_name')[0]['value'];
        this.addUser.last_name = $('#user-last_name')[0]['value'];
        this.addUser.email = $('#user-email')[0]['value'];
    }

    setNewUserDetails(row) {
        row.user_name = this.addUser.user_name;
        row.first_name = this.addUser.first_name;
        row.last_name = this.addUser.last_name;
        row.email = this.addUser.email;
        row.group_name = this.addUser.group_name;
        row.group_id = this.addUser.group_id;
    }

    readGroupDetails() {
        this.addGroup.name = $('#group-name')[0]['value'];
        this.addGroup.description = $('#group-description')[0]['value'];
        this.addGroup.tenant_id = this.loggedInUserDetails['tenant_id'];
    }

    setNewGroupDetails(row) {
        row.name = this.addGroup.name;
        row.description = this.addGroup.description;
    }

    // Delete User/Group Rows on Delete Action Confirmation
    deleteSelectedRow() {
        if (this.leftGridAction && this.selectedUserMetadata && this.selectedUserMetadata['user_id']) {
            this.userGroupService.deleteUsers(this.selectedUserMetadata.user_id).subscribe(responseList => {
                this.gridApi.applyTransaction({remove: [this.selectedGroupMetadata] });
                this.totalLeft = this.totalLeft - 1;
                $('#btnCloseActionModel').click();
            }, err => {
            });
        } else {
            this.userGroupService.deleteGroup(this.selectedGroupMetadata.dataContext.group_id,
                this.loggedInUserDetails['user_id']).subscribe(responseList => {
                    this.totalRight = this.totalRight - 1;
                    // this.grouplist = this.grouplist.filter(group => group.value === this.selectedGroupMetadata.dataContext.group_id);
                    this.grouplist['currentpage'] = this.grouplist['currentpage'].filter(group => group.group_id === this.selectedGroupMetadata.dataContext.group_id);
                    this.groupGridRef.gridService.deleteDataGridItemById(this.selectedGroupMetadata.dataContext.id);
                    $('#btnCloseActionModel').click();
                }, err => {
                });
        }
    }

    getAllUserByGroup(rowNumber) {        
        this.usertableRows = this.userDataSource['currentpage'][rowNumber].users.map((item) => {
            item['password'] = 'Change Password';
            item['action'] = 'delete';
            return item;
        });
        
        this.initUserGrid() 

        this.usertableRows.forEach(element => {
            element.id = Math.random();
            element.group_name = this.grouplistDropDown[rowNumber].text;
        });


    }
    // set up col def
    initUserGrid() {
        // this.usertableRows.splice(0, 0, this.getAddUserRow());
        this.userTableColDef = [{
            'headerName': 'User Name',
            'field': 'user_name',
            'sortable': true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellRenderer: (params) => customRowFormatter(params),
                cellRendererParams: {
                'placeholder': 'Add user name', 'tabindex': 1
            },
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            'minWidth': 160,
        },{
        'headerName': 'First Name',
        'field': 'first_name',
        'sortable': true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRenderer: (params) => customRowFormatter(params),
            cellRendererParams: {
                'params': { 'placeholder': 'Add first name', 'tabindex': 2 },
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        'minWidth': 120
    },{
        'headerName': 'Last Name',
        'field': 'last_name',
        'sortable': true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRenderer: (params) => customRowFormatter(params),
            cellRendererParams: {
                'params': { 'placeholder': 'Add last name', 'tabindex': 3 },
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        'minWidth': 120
    }, {
        'headerName': 'E-mail',
        'field': 'email',
        'sortable': true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRenderer: (params) => customRowFormatter(params),
            cellRendererParams: {
                'params': { 'placeholder': 'Add E-mail', 'tabindex': 4 },
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        'minWidth': 120
    },{
            headerName: 'Group Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            sortable: true,
            minWidth: 140,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRendererFramework: AgDropdownCellRendererComponent,
            cellRendererParams: {
                 list: this.grouptableRows
             }
    },
    // {
    //     'headerName': 'Group Name',
    //     'field': 'group_id',
    //     'sortable': false,
    //     floatingFilter: false,
    //     'minWidth': 120,
    //     'formatter': customDropDownFormatter,
    //     cellRendererFramework: DropdownCellRendererComponent, 
    //     cellRendererParams: { 
    //         list: this.grouplist
    //      } 
        // 'params': this.grouplist,
        // 'params': this.grouplistDropDown,
        // 'editor': { model: CustomDropDownEditor }
    // },
        // {
        //     'displayname': 'Password',
        //     'physicalname': 'password',
        //     'sortable': false,
        //     'datatype': 'String',
        //     'filterable': false,
        //     'minWidth': 120,
        //     'formatter': modelFormatter
        // }, 
        {
            'headerName': ' ',
            'field': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            cellRenderer: (params) => agAddRemoveFormatter(params),
            'cssClass': 'text-center',
            'headerCssClass': 'text-center',
            'minWidth': 20
        }];
        // checking if user is tenant admin or not
        if (this.loggedInUserDetails['is_admin']) {
            this.userTableColDef.splice(1, 0, {
                'headerName': 'Account Lock Status ',
                'field': 'is_locked',
                'filterable': false,
                cellRenderer: (params) => lockUnlockFormattter(params),
                cellClass: ["ag-lock-column"],
                headerClass: "lock-status-text",
                'minWidth': 100,
                'maxWidth': 140,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            
            });
        }
        this.isUserResponseReady = true;
    }

    initGroupGrid() {
        this.grouptableRows = this.grouplist['currentpage'].map((item) => {
            item['action'] = 'delete';
            return item;
        });

        //this.grouptableRows.splice(0, 0, this.getAddGroupRow());
        this.groupTableColDef = [{
            'displayname': 'Group Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customGroupRowFormatter,
            'minWidth': 120,
            'params': { 'placeholder': 'Add group' },
        }, {
            'displayname': 'Description',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customGroupRowFormatter,
            'minWidth': 240,
            'params': { 'placeholder': 'Add description' },
        }, {
            'displayname': ' ',
            'physicalname': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': addRemoveFormattter,
        }];
        this.isGroupResponseReady = true;
    }

    getAddUserRow() {
        return { 'id': Math.random(), 'user_id': '-1', 'user_name': '', 'first_name': '', 'last_name': '', 'email': '', 'group_id': '1', 'group_name': 'Default Group', 'password': '', 'action': 'add', 'is_locked': 'none' };
    }

    getAddGroupRow() {
        return { 'id': Math.random(), 'group_id': '-1', 'name': '', 'description': '', 'action': 'add' };
    }

    onSubmit() {
        // stop here if form is invalid
        if (this.passwordChangeForm.valid) {
            const row = this.selectedUserMetadata;
            if (row.user_id === '-1') {
                this.addUser.password = this.passwordChangeForm.controls.password.value;
                this.passwordChangeForm.reset();
                $('#btnClosePwdModel').click();
            } else {
                const user = new User();
                user.user_id = row.user_id;
                user.user_name = row.user_name;
                user.old_password = this.passwordChangeForm.controls.old_password.value;
                user.password = this.passwordChangeForm.controls.password.value;
                user.confirm_password = this.passwordChangeForm.controls.confirm_password.value;
                this.userGroupService.changePassword(user).subscribe(responseList => {
                    this.passwordChangeForm.reset();
                    $('#btnClosePwdModel').click();
                }, err => {

                });
            }
        } else {
            return;
        }
    }

    getPaggedUser(page, limit) {
        this.readUserDetails();
        this.userGroupService.getPagedUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], page, limit).subscribe(resp => {
            this.usertableRows = resp;
            if (this.usertableRows['currentpage'].length === 0) {
                this.noData = true;
            }
            let concatedLeftRows = this.usertableRows['currentpage'];
            if (this.userGridRef) {
                this.usertableRows = resp['currentpage'].map((item) => {
                    item['password'] = 'Change Password';
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }

            const firstRow = this.getAddUserRow();
            this.setNewUserDetails(firstRow);
            this.usertableRows.splice(0, 0, firstRow);
            this.isUserResponseReady = true;
            this.usertableRows['currentpage'] = concatedLeftRows;
            this.hasScrolled = false;
        }, err => {
            this.isUserResponseReady = true;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }

    async getPaggedGroups(page, limit) {
        this.readGroupDetails();
        try {
            let resp = this.userGroupService.getPagedGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], page, limit)
            this.grouptableRows = resp;
            if (this.grouptableRows['currentpage'].length === 0) {
                this.noDataRight = true;
            }
            let concatedLeftRows = this.grouptableRows['currentpage'];
            if (this.groupGridRef) {
                concatedLeftRows = [...this.groupGridRef.dataView.getItems(), ...this.grouptableRows['currentpage']];
                this.grouptableRows = resp['currentpage'].map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }

            const firstRow = this.getAddGroupRow();
            this.setNewUserDetails(firstRow);
            this.grouptableRows.splice(0, 0, firstRow);
            this.isGroupResponseReady = true;
            this.grouptableRows['currentpage'] = concatedLeftRows;
            this.groupGridRef.dataView.setItems(concatedLeftRows);
            this.groupGridRef.gridService.setSelectedRows([]);
            this.hasScrolledRight = false;
        } catch (error) {
            this.isGroupResponseReady = true;
            this.messageSvc.sendMessage({ message: error.error.message, type: 'INFO', hideboard: true });
        }
    }

    goToPageLeft(n: number): void {
        this.pageLeft = n;
        this.getPaggedUser(this.pageLeft, this.limitLeft);
    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getPaggedUser(this.pageLeft, this.limitLeft);
    }

    onPrevLeft(): void {
        this.pageLeft--;
        this.getPaggedUser(this.pageLeft, this.limitLeft);
    }

    goToPageRight(n: number): void {
        this.pageRight = n;
        this.getPaggedGroups(this.pageRight, this.limitRight);
    }

    onNextRight(): void {
        this.pageRight++;
        this.getPaggedGroups(this.pageRight, this.limitRight);
    }

    onPrevRight(): void {
        this.pageRight--;
        this.getPaggedGroups(this.pageRight, this.limitRight);
    }

    totalRightPages(): number {
        return this.zettaUtils.getTotalPages(this.totalRight, this.limitRight);
    }

    totalLeftPages(): number {
        return this.zettaUtils.getTotalPages(this.totalLeft, this.limitLeft);
    }

    leftGridCount() {
        if (this.userGridRef) {
            // Remove Add Row Count
            const hasFilter = this.userGridRef.dataView.getItems().length - this.usertableRows.length;
            if (hasFilter < 0) {
                return this.userGridRef.dataView.getItems().length;
            } else {
                return this.userGridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.usertableRows.length - 1;
        }
    }

    rightGridCount() {
        if (this.groupGridRef) {
            // Remove Add Row Count
            const hasFilter = this.groupGridRef.dataView.getItems().length - this.grouptableRows.length;
            if (hasFilter < 0) {
                return this.groupGridRef.dataView.getItems().length;
            } else {
                return this.groupGridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.grouptableRows.length - 1;
        }
    }

    onLeftGridScroll(parentClass) {
        this.tabFocus();
        if (this.zettaUtils.virtualScroll(parentClass, 'bottom') && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
        }
    }

    onRightGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass, 'bottom') && !this.hasScrolledRight && !this.noDataRight) {
            this.hasScrolledRight = true;
            this.onNextRight();
        }
    }

    removeFile(index) {
        this.selectedFiles.splice(index, 1);
    }

    tabFocus() {
        const parent = this;
        $(this.inputFocus).focus();

        $("#user-user_name").keydown(function (e) {
            var code = e.keyCode || e.which;
            if (code == 9 || code == 39) {
                $("#user-first_name").focus();
                parent.inputFocus = "#user-first_name";
                return false;
            }
        });

        $("#user-first_name").keydown(function (e) {
            var code = e.keyCode || e.which;
            if ((e.shiftKey && code == 9) || code == 37) {
                $("#user-user_name").focus();
                parent.inputFocus = "#user-user_name";
                return false;
            } else if (code == 9 || code == 39) {
                $("#user-last_name").focus();
                parent.inputFocus = "#user-last_name";
                return false;
            }
        });

        $("#user-last_name").keydown(function (e) {
            var code = e.keyCode || e.which;
            if ((e.shiftKey && code == 9) || code == 37) {
                $("#user-first_name").focus();
                parent.inputFocus = "#user-first_name";
                return false;
            } else if (code == 9 || code == 39) {
                $("#user-email").focus();
                parent.inputFocus = "#user-email";
                return false;
            }
        });

        $("#user-email").keydown(function (e) {
            var code = e.keyCode || e.which;
            if ((e.shiftKey && code == 9) || code == 37) {
                $("#user-last_name").focus();
                parent.inputFocus = "#user-last_name";
                return false;
            }
        });
    }
    onSaveUser() {
        this.submitted = true;
        if (this.usersForm.invalid) {
            return;
        }
        const app_ids = this.loggedInUserDetails['apps'].map(app => app.app_id);
        const userPayload = {
            "user_name": this.usersForm.controls.user_name.value,
            "password": this.usersForm.controls.password.value,
            "first_name": this.usersForm.controls.first_name.value,
            "last_name": this.usersForm.controls.last_name.value,
            "email": this.usersForm.controls.email.value,
            "group_name": this.group_name,
            "is_super_admin": false,
            "is_tenant_admin": false,
            "is_internal_user": true,
            "app_id": app_ids
        };
        this.userGroupService.createUser(userPayload).subscribe(resp => {
            $('#addUserModal').modal('hide');
            this.usersForm.reset();
            this.submitted = false;
            this.getUserAndGroupList();
            this.messageSvc.sendMessage({ message: 'User has been added successfully!', type: 'SUCCESS', hideboard: true });
        }, error => {
            this.submitted = false;
            if (error.status == 400) {
                this.user_exist = true;
                this.userNameExits = AppGlobals.USER_EXITS;
                this.emailExits = AppGlobals.EMAIL_EXITS;
            } else {
                $('#addUserModal').modal('hide');
                this.messageSvc.sendMessage({ message: error.error && error.error.error ? error.error.error: 'Something went wrong.' , type: 'Error', hideboard: true });
            }
        });
    }

    onSaveGroup() {
        this.addGroup.name = this.groupsForm.controls.group_name.value,
            this.addGroup.description = this.groupsForm.controls.description.value
        this.addGroup.tenant_id = this.loggedInUserDetails['tenant_id'];
        this.userGroupService.saveGroup(this.addGroup, this.loggedInUserDetails['user_id']).subscribe(responseList => {
            if (responseList) {
                this.groupsForm.reset();
                this.messageSvc.sendMessage({ message: 'Group has been added sucessfully!', type: 'SUCCESS', hideboard: true });
                $('#addGroupModal').modal('hide');
                this.isGroupResponseReady = false;
                if (this.groupGridRef) {
                    this.grouptableRows = [];
                    this.groupGridRef.dataView.setItems(this.grouptableRows);
                }
                this.getUserAndGroupList();
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            $('#addGroupModal').modal('hide');
        });
    }

    receiveFileName(eve) {
        if(eve) {
            this.getUserAndGroupList();
        }
    }

    validateForm(){
        if(this.usersForm.controls.user_name.value && this.usersForm.controls.first_name.value && this.usersForm.controls.last_name.value && this.usersForm.controls.email.value && this.usersForm.controls.password.value) {
            this.isFormValid = true;
        } else {
            this.isFormValid = false;
        }
    }

    resetForm() {
        this.usersForm.reset();
        this.user_exist = false;
        this.validateForm();
    }

    closeUploadModal() {
        this.clearModal = Math.random();
    }  

    openModalImport(modal) {
        $('#' + modal).modal('show');
    }

    onDropdownValueChanged(eve) {
        const newUser = new User;
        if (eve && eve.value) {
            const oldUser: User = this.usertableRows[eve.rowIndex];
            newUser.user_id = eve.value.user_id;
            newUser.user_name = eve.value.user_name;
            newUser.group_id = eve.value.group_id;
            newUser.first_name = eve.value.first_name;
            newUser.last_name = eve.value.last_name;

            if (this.userGroupService.getObjectFromSession('userInfo').user_id === newUser.user_id) {
                const user = this.userGroupService.getObjectFromSession('userInfo');
                user.group_id = newUser.group_id;
                this.userGroupService.addObjectToSession('userInfo', user);
            }
            // Not a first row
            if (eve.rowIndex >= 0) {
                this.userGroupService.saveUser(newUser).subscribe(responseList => {
                }, err => {
                    this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                });
            } else {
                this.addUser.group_id = oldUser.group_id;
                this.addUser.group_name = oldUser.group_name;
            }
        }
    }
}
