import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { environment } from '../../../../environments/environment';
import { ZsClContentService } from '../zs-cl-content.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

declare var $: any;

const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = value;
  if (value.includes('"')) {
    name = value.replace(/"/g, "'");
  }
  return `<div class="datasource-name-formatter text-wrap">
            <span title="${value}">${name}</span><br>
        </div>`;
};


@Component({
  selector: 'zetta-classify-feedback-modal',
  templateUrl: './classify-feedback-modal.component.html',
  styleUrls: ['./classify-feedback-modal.component.scss']
})
export class ClassifyFeedbackModalComponent implements OnInit, OnChanges {
  datasetsTableColDef: any;
  gridDatasets: AngularGridInstance;
  datasets:Array<any> = [];
  public loggedInUserDetails = null;
  catalogId;
  semanticId;
  isLoadingDatasets: Boolean;
  gridDataOptions: any;
  showDownvoteModal=false;
  showConceptColumn:boolean=false;
  mappingType;
  attribute_id;
  object_id;
  catalog_id;
  datasetColumnId;
  dataset_id: number;
  object_name;
  selectedDsColumn;
  selected_record_id:string;
  projId;
  dsName;
  classInfo: any;
  rowData:any;
  classes: any;
  screen_name =  'classify'; //"TRAINING-TASKS" -  updateTaskFeedback method checks this value conditionally 
  taskName:string;
  is_approver: boolean = false;
  is_pending_for_approval: boolean = false;
  enableFinishTask: boolean = false;
  enableApprove:boolean = false;
  enableApproved: boolean = false;
  taskReviewerName: string = '';
  taskReviewerDate: string = '';
  userRole: object = {};
  is_reviewer = false;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  showResultsListModal: boolean = false;
  recordId;
  datasetId;
  resultDetail: object = {};
  
  @Input() taskInfo: any;
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
  @Output() updateFeedbackResponse: EventEmitter<any> = new EventEmitter();
  @Input() enabled_feedback?: boolean = false;
  constructor(private projectService: ProjectService, private messageSvc: MessageService, private catalogSvc: ZsClContentService,
    public service: ProjectService) { }

  ngOnInit() {

  this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes) {
        this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
        this.taskInfo = changes.taskInfo.currentValue;
        if(changes.taskInfo.currentValue.task_types === 'Classification Project') {
          this.initializeTaskGrid();
        this.getSemantics();
        this.checkForTaskStatus(this.taskInfo);
        this.checkUserRoleDetails();
        }
      }
  // }
  }

getSemantics() {  
  this.isLoadingDatasets = true;
  this.projId = this.taskInfo['classify_project_id'];
  this.selected_record_id = this.taskInfo['record_id'];
  this.recordId = encodeURIComponent(this.selected_record_id);
  $('#datasets_modal').modal('show');
  this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.taskInfo['classify_project_id'], this.taskInfo['task_id'])
  .subscribe(resp => {    
    if(resp) {
      this.getTaskReviewer();
      this.datasets = resp['task_outputs'];
      this.taskName = resp['summary'];
      this.dataset_id = resp.dataset_id;
      this.datasetId = this.dataset_id;
      this.datasets.forEach(element => {
        element.newId = element.id;
        element.datasource_name = element.name;
        element.score100 = (element.score * 100).toFixed();
        element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
        element.shouldDisableFeedback = this.enabled_feedback;
        element.id = Math.random();
      });
      this.datasets.sort(((a, b) => b.score100 - a.score100));
      // this.totalDatasets = this.datasets.length;
      this.isLoadingDatasets = false;
      let concatedRows = this.datasets;
      if (this.gridDatasets) {
        concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
        this.datasets = concatedRows;
        this.gridDatasets.dataView.setItems(this.datasets);
        this.gridDatasets.gridService.setSelectedRows([]);
        // this.hasDatasetsScrolled = false;
      }
      if (this.datasets.length === 0) {
        // this.noData = true;
      }
      this.checkFinishTaskBtnValidation(this.datasets, true);
    }
  })

}

checkFinishTaskBtnValidation(tasks, enable) {
  if(enable) {
    if(tasks && tasks.length > 0 && !this.enabled_feedback) {
      let at_least_one_vote = tasks.filter(task => task.vote == 1 || task.vote == 0);
      if(this.is_approver && this.is_pending_for_approval) {
        this.enableApprove = at_least_one_vote.length > 0 ? true : false;
      } else {
        this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
      }
    }
  }
}

  onDatasetsGridCreation(grid) {
    this.gridDatasets = grid;
  }
  
  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedDsColumn = args.row;
    if (row !== undefined && row !== null) {   
         this.dsName = row.class;
      if(eventEle.target.className.trim() =='fal fa-thumbs-down handDown' || eventEle.target.className.trim() == 'fal handDown fa-thumbs-down' || eventEle.target.className.trim() == 'handDown fal fa-thumbs-down' || eventEle.target.className.trim() == 'fal fa-thumbs-down'){
        this.showDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;        
        let dislikeData = {
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 0,
          task_id: this.taskInfo.task_id,
          class: row.class,
          dataset_id: this.dataset_id,
        };
        this.classInfo = dislikeData;
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          projId: this.projId,
          payload: dislikeData
        };
        this.rowData = args.row;
        this.classes = 'fal fa-thumbs-down';
      } else if (eventEle.target.className.trim() == 'fal fa-thumbs-up') {
            const upVotedata ={
              score: row.score100,
              id: row.newId,
              name: row.name,
              dataset_id: this.dataset_id,
              confidence: row.confidence,
              vote: 1,
              task_id: this.taskInfo.task_id,
              class: row.class
            }
            const payload = {
              user_id: this.loggedInUserDetails['user_id'],
              tenant_id : this.loggedInUserDetails['tenant_id'],
              projId: this.projId,
              payload:upVotedata
            }
            let self = this;
            this.projectService.updateTaskFeedback(payload, 'classifySOCTYPE').subscribe(resp =>{
              this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.taskInfo['classify_project_id'], this.taskInfo['task_id'])
              .subscribe(resp => {    
              if(resp) {
                self.checkFinishTaskBtnValidation(resp['task_outputs'], true);
              }});
              $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
              $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
              $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              if (row.vote===0) {
                $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
                $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              }
              self.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(args.row);
              self.updateFeedbackResponse.emit(true);
            }, (error) =>{
              self.messageSvc.sendMessage({ message: error && error.error && error.error.message ? error.error.message : 'Something went wrong while saving feedback', type: 'ERROR', hideboard: true });
            });
      }
    }
  }

  /**
   * 
   * Revoking upvote access for non-selected or previously selected as we only allow 1 upvote
   *  for SOC project feedback per task. 
   */
  revokeUpvoteAccessForOtherRecordSOCProjectFeedback(upvotedRow) {
    let totalRecords = this.datasets.length;
    for(let row =0; row < totalRecords; row++) {
      if (row != upvotedRow) {
        $('#thumbs-up-' + row).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + row).addClass('fal fa-thumbs-up');
      } 
    }

  }

  onClose() {
    this.datasets = [];
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.modelPopupOutput.emit(false); 
    this.taskInfo = {};
  }

  initializeTaskGrid() {

    this.datasetsTableColDef = [{
      'displayname': 'Classifier Prediction',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormattter,
      'minWidth': 450,
      'maxWidth': 500,
    },
    {
      'displayname': 'Confidence Level',
      'physicalname': 'confidence',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': confidenceLevelFormatter,
      'minWidth': 250,
      'maxWidth': 370,
    }
  ];

    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
  }

  // On close of downvote feedback modal
  closeModalPopup(event) {
    if (event) {
      $('#thumbs-up-' + this.selectedDsColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedDsColumn).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selectedDsColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedDsColumn).addClass('fas fa-thumbs-down');
      this.updateFeedbackResponse.emit(true);
    }
    this.showDownvoteModal = false;
  }

  getTaskReviewer(){
    this.catalogSvc.taskReviewer(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.taskInfo['task_id'], this.taskInfo.task_type_id).subscribe(resp => {
      if (resp.length) {
        this.taskReviewerName = resp[0].first_name;
        this.taskReviewerDate = resp[0].created_ts;
      } else {
        this.taskReviewerName = resp.name;
        this.taskReviewerDate = resp.date;
      } 
    }, error=>{
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  checkForTaskStatus(taskData) {
    if(taskData && taskData.task_status == AppGlobals.TASK_PENDING_APPROVAL || taskData.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
      this.is_pending_for_approval = true;
      } else {
        this.is_pending_for_approval = false;
      }
  }

  checkUserRoleDetails() {
    this.userRole = this.service.getObjectFromSession('userRole');
    if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_APPROVER) {
      this.is_approver = true;
    }
    if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_REVEIWER) {
      this.is_reviewer = true;
    }
  }

  approveBtn() {
    const data = {
      "user_id": this.loggedInUserDetails.user_id, 
      "tenant_id": this.loggedInUserDetails.tenant_id,
      "projId": this.taskInfo['classify_project_id'],
      payload: {
        "task_id": this.taskInfo['task_id']
      }
    };
    this.catalogSvc.approveClassIfySocTaskFeedback(data)
    .subscribe(res => {
      if(res) {
        this.enableApprove = false;
        this.enableApproved = true;
        this.enableFinishTask = true;
      }
    });
  }

  finishTasks() {
    const data = {
      "user_id": this.loggedInUserDetails.user_id, 
      "tenant_id": this.loggedInUserDetails.tenant_id,
      "projId": this.taskInfo['classify_project_id'],
      payload: {
        "task_id": this.taskInfo['task_id'],
        "dataset_id": this.taskInfo['dataset_id']
      }
    };

    this.catalogSvc.updateClassIfySocTaskFeedback(data)
    .subscribe(resp => {
      this.messageSvc.sendMessage({ message: resp.message, type: 'SUCCESS', hideboard: true });
      this.datasets = [];
      $('.modal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.modelPopupOutput.emit(true); 
      this.updateFeedbackResponse.emit(true);
    });
  }

  downVoted(eve) {
    if(eve) {
      this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, this.taskInfo['task_id'])
      .subscribe(res => {
          if(res) {
            console.log('the repsonse is  ', res);
            this.checkFinishTaskBtnValidation(res['task_outputs'], true);
          }
        });
    }
  }
  triggerClassifyReslutsModal() {
    this.showResultsListModal = true;
  }

  hideClassifyResultsModal(eve) {
    this.showResultsListModal = eve;
  }

}
