import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppGlobals } from '../../app.globals';
import { ChangeDetectorRef } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-task-assignment-renderer',
  template: `
    <label *ngIf="!showEnabledCheckbox">
      <input type="checkbox" id="{{checkboxId}}" [disabled]="true">
      Disabled
    </label>

    <label *ngIf="showEnabledCheckbox">
      <input type="checkbox" id="{{checkboxId}}" [disabled]="!showCheckedCheckbox" [checked]="showCheckedCheckbox">
      Enabled and Checked
    </label>
  `,
  styles: [`
    /* Add your styles here if needed */
  `]
})
export class TaskAssignmentRendererComponent implements ICellRendererAngularComp {
    private params: any;
    public checkboxId: string;
    public showEnabledCheckbox: boolean = false;
    public showCheckedCheckbox: boolean = false;
    public isChecked: boolean = false;
  
    agInit(params: any): void {
      this.params = params;
      this.checkboxId = `user-${params.rowIndex}-${params.colDef.field}`;
  
      if (
        (params.colDef.field === 'isReviewer' || params.colDef.field === 'isApprover') &&
        params.value &&
        params.data.role_id === AppGlobals.RESOLVE_PROJECT_REVIEWER
      ) {
        this.showEnabledCheckbox = true;
        this.showCheckedCheckbox = true;
      }
    }
  
    refresh(params: any): boolean {
      // Implement this method if needed, it is called by AG-Grid when the grid refreshes
      return false;
    }
  
    ngOnInit() {
      // Implement this method if needed, it is called after the component is initialized
    }
  }
