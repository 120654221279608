import { Component, OnInit } from '@angular/core';
import { ObservableService } from "../../../../common/services/observable.service";

@Component({
  selector: 'zetta-zs-cl-catalog-level-dq-management',
  templateUrl: './zs-cl-catalog-level-dq-management.component.html',
  styleUrls: ['./zs-cl-catalog-level-dq-management.component.scss']
})
export class ZsClCatalogLevelDqManagementComponent implements OnInit {
  isRuleAdmin = false;
  isRuleExecuter = false;

  constructor(public _observableService: ObservableService) { }
  
  ngOnInit() {
  }

  ngOnDestroy() {
    this._observableService.unsubscribe();
  }

  recieveRuleAccess(ruleAccess) {
    if (ruleAccess) {
      this.isRuleAdmin = ruleAccess.isRuleAdmin;
      this.isRuleExecuter = ruleAccess.isRuleExecuter;
    }
  }
}
