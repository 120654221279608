import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import { formatDate } from '@angular/common';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zm-zetta-mline-chart',
    templateUrl: './zm-mline-chart.component.html',
    styleUrls: ['./zm-mline-chart.component.scss']
})
export class ZmMlineChartComponent implements AfterViewInit {

    public chartHeight: number;
    public chartWidth: number;
    public fontSize: number;
    public isChartInValid = false;
    public $container;
    public colors: any;
    public colorLines: any;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;

    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(public cdRef: ChangeDetectorRef, public observeSvc: ZmObservableService, public zettaUtils: ZmZettaUtils) {}

    ngAfterViewInit() {
        this.isChartInValid = (!this.dataDataSource || this.dataDataSource.length == 0) ? true : false;
        if (!this.isChartInValid) {
            this.isChartInValid = (!this.chartOptions || !this.chartOptions['label'] || !this.chartOptions['value']) ? true : false;
        }
        if (!this.chartColors) {
            this.chartColors = ['#483da0', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }
        this.cdRef.detectChanges();
        if (this.dataDataSource && !this.isChartInValid) {
            setTimeout(() => {
                this.renderChart();
            }, 100);
        }
        let parent = this;
        this.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    renderChart() {
        let parent = this;
        // parent.$container = $('#chart_' + parent.chartUniqueID);
        parent.$container = $('#chart_' + parent.chartUniqueID).parent().closest('div');
        parent.chartHeight = parent.chartOptions['height'];
        parent.chartWidth = parent.$container.width()-30;
        parent.fontSize = (Math.min(parent.chartWidth, parent.chartHeight) / 4);

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        $('#chart_' + parent.chartUniqueID).empty();
        
        // var width = $('#chart_' + parent.chartUniqueID).width();
        var width = parent.chartWidth;
        var height = parent.chartHeight;
        // var height = (width * 32) / 100;
        var margin = 70;
        var duration = 150;
        
        var lineOpacity = "0.75";
        var lineOpacityHover = "0.99";
        var otherLinesOpacityHover = "0.1";
        var lineStroke = "2.0px";
        var lineStrokeHover = "3.5px";
        
        var circleOpacity = '0.85';
        var circleOpacityOnLineHover = "0.25"
        var circleRadius = 4;
        var circleRadiusHover = 8;
        var godP = 2.2;
        var badP = 1.9;

        /* Format Data and Get Max value*/
        var parseDate = d3.timeParse("%Y");
        let maxValues = [];
        parent.dataDataSource.forEach(function(d) { 
            d.values.forEach(function(d) {
                d.date = parseDate(d.date);
                d.price = +d.price;    
            });
            const maxVal = Math.max(...d.values.map(o => o.price), 0);
            maxValues.push([maxVal]);
        });

        const maxVal = Math.max(...maxValues);
        parent.dataDataSource[0].name = 'Forecast';
        parent.dataDataSource[1].name = 'Actual';

        /* Scale */
        var xScale = d3.scaleTime()
                    // .domain(d3.extent(parent.dataDataSource[0].values, d => d.date))
                    .domain(d3.extent(parent.dataDataSource[0].values, d => d.date))
                    .range([0, width-margin]);

        var yScale = d3.scaleLinear()
                    // .domain([0, d3.max(parent.dataDataSource[0].values, d => d.price)])  //el valo max solo del primer conjunto de datos  array[0]
                    .domain([0, maxVal])  //max price value
                    .range([height-margin, 0]);

        var color = d3.scaleOrdinal(d3.schemeCategory10);

        /* Add SVG */
        let rootNode = d3.select('#chart_' + parent.chartUniqueID);
        var svg = rootNode.append("svg")
                    .attr("width", (width+margin)+"px")
                    .attr("height", (height+margin/2)+"px")
                    .append('g')
                    .attr("transform", `translate(${margin}, 40)`);

        let lineTooltip = rootNode.append('div').attr('class', 'toolTip');

        this.colors = ["#EC43A2", "#FBB54D", "#00C219"];
        this.colorLines = ["#F48341", "#1ABBCA", "#00C219", "#EC43A2"];

        /* Backgroun Colors and Lines (stroke-dasharray) */
        svg.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("fill", this.colors[2])
        .style("opacity", "0.08")
        .attr("height", (height-margin-margin)/godP)
        .attr("width", width-margin)
    
        svg.append("rect")
        .attr("x", 0)
        .attr("y", (height-margin-margin)/godP)
        .attr("fill", this.colors[1])
        .style("opacity", "0.08")
        .attr("height", (height-margin-margin)/badP)
        .attr("width", width-margin);
    
        svg.append("rect")
        .attr("x", 0)
        .attr("y", (height-margin-margin)/godP + (height-margin-margin)/badP)
        .attr("fill", this.colors[0])
        .style("opacity", "0.08")
        .attr("height", (height-margin)-((height-margin-margin)/godP + (height-margin-margin)/badP))
        .attr("width", width-margin);
        
        svg.append("line").style("stroke", this.colors[2]).style("stroke-dasharray", ("4, 4"))
                          .attr("x1", 0).attr("y1", (height-margin-margin)/godP)
                          .attr("x2", width-margin).attr("y2", (height-margin-margin)/godP);
        svg.append("line").style("stroke", this.colors[1]).style("stroke-dasharray", ("4, 4"))
                          .attr("x1", 0).attr("y1", (height-margin-margin)/godP + (height-margin-margin)/badP)
                          .attr("x2", width-margin).attr("y2", (height-margin-margin)/godP + (height-margin-margin)/badP);
    
        svg.append('text').text('High/God').style('font-size', '10px').style('fill', this.colors[2]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', 10).attr('dy', 0);
        svg.append('text').text('Performance').style('font-size', '10px').style('fill', this.colors[2]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', 20).attr('dy', 0);
    
        svg.append('text').text('Average').style('font-size', '10px').style('fill', this.colors[1]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', ((height-margin-margin)/godP + ((height-margin-margin)/badP)/2) -4).attr('dy', 0);
        svg.append('text').text('Performance').style('font-size', '10px').style('fill', this.colors[1]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', ((height-margin-margin)/godP + ((height-margin-margin)/badP)/2) + 6).attr('dy', 0);
        
        svg.append('text').text('Low/Bad').style('font-size', '10px').style('fill', this.colors[0]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', (height-margin) -14).attr('dy', 0);
        svg.append('text').text('Performance').style('font-size', '10px').style('fill', this.colors[0]).attr('transform', 'rotate(0)').attr('text-anchor', 'end').attr('x', -10).attr('y', (height-margin) -4).attr('dy', 0);
    
        /* Labels */
        const numLines = parent.dataDataSource.length;
        for(let i=0; i<numLines; i++) {
            svg.append("line").style("stroke", this.colorLines[i]).style("stroke-width", "3")
                .attr("x1", width/numLines-120*i -25).attr("y1", height-margin + 46)
                .attr("x2", width/numLines-120*i -5).attr("y2", height-margin + 46);
            svg.append('text').text(parent.dataDataSource[i].name)
                .style('font-size', '14px').style('text-align', 'left').style('fill', this.colorLines[i]).attr('text-anchor', 'start')
                .attr('transform', 'rotate(0)')
                .attr('x', width/numLines-120*i)
                .attr('y', height-margin + 50).attr('dy', 0);
        }

        /* Add line into SVG */
        var line = d3.line()
                    .x(d => xScale(d.date))
                    .y(d => yScale(d.price));

        let lines = svg.append('g')
                    .attr('class', 'lines');

        /* add the X gridlines */
        svg.append("g")			
                .attr("class", "grid").style('opacity', '0.08')
                // .attr("transform", "translate(" + margin.left + "," + (contentHeight + margin.top) + ")")
                .attr("transform", "translate(0," + (height - margin) + ")")
                .call(make_x_gridlines()
                    .tickSize(-(height-margin))
                    .tickFormat("")
                );

                // gridlines in x axis function
                function make_x_gridlines() {		
                    return d3.axisBottom(xScale)
                        .ticks(20)
                        // .ticks(parent.dataDataSource.length+2)
                }

        /* add the Y gridlines */
        // svg.append("g")			
        //         .attr("class", "grid")
        //         //  .attr("transform", "translate(" + margin.left + "," + (contentHeight + margin.top) + ")")
        //         // .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        //         .attr("transform", "translate(" + margin + "," + margin + ")")
        //         .call(make_y_gridlines()
        //             .tickSize(-(width-margin))
        //             .tickFormat("")
        //         );

        //         function make_y_gridlines() {		
        //             return d3.axisLeft(yScale)
        //                 .ticks(10)
        //         }

        /* Add lines */
        lines.selectAll('.line-group')
                    .data(parent.dataDataSource).enter()
                    .append('g')
                    .attr('class', 'line-group')  
                    .append('path')
                    .attr('class', 'line').style('fill', 'none')
                                          .style('stroke-width', lineStroke)  
                    .attr('d', d => line(d.values)).call(transition)
                    .style('stroke', (d, i) => this.colorLines[i])
                    .style('opacity', lineOpacity);

        /* Add circles in the line */
        lines.selectAll("circle-group")
                    .data(parent.dataDataSource).enter()
                    .append("g")
                    // .style("fill", (d, i) => color(i))
                    .style("fill", (d, i) => this.colorLines[i])
                    .selectAll("circle")
                    .data(d => d.values).enter()
                    .append("g")
                    .attr("class", "circle")  
                    .append("circle")
                    .attr("cx", d => xScale(d.date))
                    .attr("cy", d => yScale(d.price))
                    .attr("r", circleRadius)
                    .style('opacity', circleOpacity)
                    .on("mouseover", function(d) {
                        d3.select(this)
                            .transition()
                            .duration(duration)
                            .attr("r", circleRadiusHover);
                        lineTooltip.style('display', 'none');
                        })
                    .on("mouseout", function(d) {
                        d3.select(this) 
                            .transition()
                            .duration(duration)
                            .attr("r", circleRadius); 
                        lineTooltip.style('display', 'none') 
                        })
                    .on('mousemove', mousemove);

        /* Add Axis into SVG */
        var xAxis = d3.axisBottom(xScale).ticks(5);
        var yAxis = d3.axisLeft(yScale).ticks(5);

        svg.append("g")
                    .attr("class", "x axis")
                    .attr("transform", `translate(0, ${height-margin})`)
                    .call(xAxis)
                    .append('text')
                    .attr("x", width-margin-10).attr("y", 25)
                    .attr("transform", "rotate(0)")
                    .attr("fill", "#000")
                    .text("Years");

        svg.append("g")
                    // .attr("class", "y axis")
                    // .call(yAxis)
                    .append('text')
                    .attr("x", 0).attr("y", -10)
                    .attr("transform", "rotate(0)")
                    .attr("fill", "#000")
                    .text("Total values");

        /* Lines Animation */
        function transition(path) {
            path.transition()
                .duration(2000)
                .attrTween("stroke-dasharray", tweenDash);
        }

        function tweenDash() {
            var l = this.getTotalLength(),
                i = d3.interpolateString("0," + l, l + "," + l);
            return function(t) { return i(t); };
        }

        /* Tooltip mouseovers */                
        function mousemove(d,i) {
            lineTooltip
                .style('left', d3.event.pageX + 10 + 'px')
                .style('top', d3.event.pageY - 45 + 'px')
                .style('display', 'inline-block')
                .html(function() {
                    if(parent.chartOptions['isMoneyFormat']){
                        return `<span>Date: <strong>` + d3.timeFormat('%m/%d/%Y')(d.date) + `</strong></span><br>
                                <span>Price: <strong>` + d.price + `</strong></span>`;
                    }else{
                        return '<span>Values: ' + (i+1) + '<strong> ' + (d.price) + '%</strong></span>';
                    }
                });
        }; 
    }

    onResize() {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }

}