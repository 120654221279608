import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

@Component({
  selector: 'app-group-numeric-cell-renderer',
  template: `
    <div class="wd-total-entitle" [title]="value">
      {{ formattedValue }}
    </div>
  `,
})
export class GroupNumericCellRendererComponent implements ICellRendererAngularComp {
  value: any;
  formattedValue: string;
  zettaUtilsInstance = new ZettaUtils();

  agInit(params: any): void {
    this.value = params.value;
    // Assuming zettaUtilsInstance.commarization is available globally
    this.formattedValue = zettaUtilsInstance.commarization(this.value, 2);
  }

  refresh(params: any): boolean {
    this.value = params.value;
    this.formattedValue = zettaUtilsInstance.commarization(this.value, 2);
    return true;
  }
}
