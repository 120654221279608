import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-datasource-name-formatter',
  template: `
    <div [ngClass]="getCssClasses()"  style="line-height: 1" [title]="value.name">
      <span class="pointer hyper-link text-black text-truncate wd-8" [class.disabled-state]="!isAdmin">{{value.name}}</span><br>
      <span class="text-regular text-truncate wd-8" [title]="value.description">{{value.description}}</span>
    </div>
  `,
  styles: [`
  .hyper-link {
    &:hover {
        color: #0e709f;
    }
  }
    .pointer {
      cursor: pointer;
    }
    .disabled-state {
      color: grey;
    }
  `]
})
export class DatasourceNameFormatterComponent implements ICellRendererAngularComp {
  value: any;
  isAdmin: boolean;

  agInit(params: ICellRendererParams): void {
    this.value = params.data;
    this.isAdmin = this.checkIsAdmin(this.value);
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.data;
    this.isAdmin = this.checkIsAdmin(this.value);
    return true;
  }

  getCssClasses(): string {
    return this.isAdmin ? 'text-wrap text-truncate' : 'text-wrap text-truncate disabled-state';
  }

  private checkIsAdmin(dataContext: any): boolean {
    const entitlements = dataContext.entitlements;
    if (entitlements && entitlements.menu) {
      const menu = entitlements.menu;
      return !!(menu.clone && menu.delete && menu.edit);
    }
    return false;
  }
}
