
import { Column, Formatter, Formatters} from 'angular-slickgrid';

export const dateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => dateFormat(value);

function dateFormat(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);

    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);

    const dd = d.getDay() + 1;
    const day = (dd <= 9 ? '0' + dd : dd);

    return `<div title='${month}/${day}/${d.getFullYear()}'>${month}/${day}/${d.getFullYear()}</div>`;
}
