import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { BaseService } from 'src/app/common/services/base-service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { environment } from 'src/environments/environment';
declare var $: any;


const circleFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div style="border:1px solid ${dataContext.colorCode};border-radius:6px;
         background-color:${dataContext.colorCode};height:12px;width:12px;margin-top:8px">               
         </div>`;
};
const nameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="text-truncate wd-16">
				<span title='${value}' class="text-regular text-truncate pl-3">${value}</span>
				</div>`;
};
const deleteFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.params.shouldDisableSubConceptFeedback) {
    return `<div id="row_${value}" class="deleteRow-disabled"><i class="fa-light fa-trash-can"></i></div>`
  }
  const cssClass = dataContext.role_name =='dq_rule_admin' ? 'deleteRow':'deleteRow-disabled';
  return `<div id="row_${value}" class="deleteRow"><i class="fa-light fa-trash-can"></i></div>`
};
@Component({
  selector: 'zetta-training-task-modal',
  templateUrl: './zs-cl-training-task-modal.component.html',
  styleUrls: ['./zs-cl-training-task-modal.component.scss']
})
export class ZsClTrainingTaskModalComponent implements OnInit, OnChanges{

  public subConceptGrid: AngularGridInstance;

  public rightGrid : AngularGridInstance;
  public loggedInUserDetails = null;
  public rightPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };

  selectedTag = "";

  public gridClassification: AngularGridInstance;
  classificationTableColDef: any;
  gridDataOptions: any;
  showModal: boolean;
  @Input() subConcept: string;
  @Input() parsingPredictions: string;
  @Input() count;
  @Input() isFeedbackAdded=false;
  @Input() record_id: any;
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
  @Output() subConceptOutput: EventEmitter<any> = new EventEmitter();
  @Output() deletePrediction: EventEmitter<any> = new EventEmitter();
  @Output() resetPrediction: EventEmitter<any> = new EventEmitter();
  @Input() content:string;
  @Input() trainModalPayload;
  showBackdrop=false;
  @Input() projectId: any;
  @Input() task_id: number;
  @Input() isDeleted:boolean;
  enableApplyBtn=false;
  @Input() disableSubConceptsCapture: boolean;
  page = 1;
  limit = 20;
  selectedText="";
  divHight='232px';
  isBoxEnable=false;
  pridictionList=[];
  isLoading=true;
  isDbDelete:boolean;
  feedback_id:number;
  public leftPanelOptions: any;

  public leftPanelGridColumns: any;
  colorList = [
    {color:'red-text', isUsed:false,colorCode:'red'},
    {color:'green-text',isUsed:false,colorCode:'green' },
    {color:'pink-text',isUsed:false,colorCode:'pink'},
    {color:'blue-text',isUsed:false,colorCode:'blue'},
    {color:'purple-text',isUsed:false,colorCode:'purple'},
    {color:'orange-text',isUsed:false,colorCode:'orange'},
    {color:'aqua-text',isUsed:false,colorCode:'#00FFFF'},
    {color:'brown-text',isUsed:false,colorCode:'#A52A2A'},
    {color:'magenta-text',isUsed:false,colorCode:'#FF00FF'},
    {color:'olive-text',isUsed:false,colorCode:'#808000'}
  ];

  view_results = [];

  constructor(
    private catalogSvc: ZsClContentService,
    private projService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils:ZettaUtils,
    private baseService:BaseService,
    private messageSvc:MessageService
  ) {
  }

  ngOnInit(): void {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));

    this.leftPanelGridColumns = [
      {
        'displayname': '',
        'physicalname': 'colorCss',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 20,
        'maxWidth': 20,
        'formatter': circleFormatter
      },
      {
        'displayname': 'Sub Concept',
        'physicalname': 'sub_concept',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      },
      {
        'displayname': 'Selected Prediction',
        'physicalname': 'prediction',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'headerCssClass': 'text-left',
        'formatter': nameFormattter
      },{
        'displayname': 'Action',
        'physicalname': 'delete_icon',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 50,
        'maxWidth': 55,
        'params': { shouldDisableSubConceptFeedback: this.disableSubConceptsCapture },
        'formatter': deleteFormattter,
        
      }];

    if (!this.record_id) {
      this.getSubConceptList();
    }

    this.baseService.addSubConcept.subscribe(resp => {
      if (resp.isAddConcept) {
        this.enableApplyBtn = true;
        this.selectedText=undefined;
        
        this.baseService.resetTextSelected.next(this.content);
        resp.data.forEach(element => {
          let isExistingSubConceptIndex = this.view_results.findIndex((val) => {
              return val.sub_concept === element.sub_concept;
          });

          // removing duplicate subconcept
          if (isExistingSubConceptIndex > -1) {
            this.freeUpColorCodeForSubConcept({color: this.view_results[isExistingSubConceptIndex].color});
            this.view_results.splice(isExistingSubConceptIndex,1);
          } 

          let colorObj = this.getNextUnusedColorCodeForSubConcept();
          element.textColor = colorObj.color;
          element.colorCode = colorObj.colorCode;
          this.setColorCode(colorObj);
          this.view_results.push(element);
        });
        if (this.rightGrid) {
          this.view_results.forEach(ele => {
            ele.id = Math.random();
          });
          this.rightGrid.dataView.setItems([]);
          this.rightGrid.dataView.setItems(this.view_results);
        }
        this.baseService.deSelectText.next(this.view_results);
      }
    });

    this.leftPanelOptions = {
      enableGridMenu: false,
      frozenRow: -1,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: ' ',
    };
  }
  
  /**
   * As per existing implementation, upto 10 color codes been setup
   * 
   * if All 10 color codes been used then returning the first color
   */
  getNextUnusedColorCodeForSubConcept() {
    if (this.colorList.length > 0) {
      let unusedColorCode = this.colorList.filter((color) => {
        return color.isUsed == false;
      })
      if (unusedColorCode.length > 0) {
        return unusedColorCode[0];
      }
      return this.colorList[0];
    }     
    return null;
  }

  resetColorCode() {
    this.colorList.forEach((color) => {
      color.isUsed = false;
    })
  }

  setColorCode(colorObj) {
    let colorIndex = this.colorList.findIndex((color) => {
      return color.color == colorObj.color;
    })
    if (colorIndex > -1) {
      this.colorList[colorIndex].isUsed = true;
    }
  }

  freeUpColorCodeForSubConcept(colorObj) {
    // free up used resource color code
    let colorIndex = this.colorList.findIndex((color) => {
      return color.color == colorObj.color;
    })

    if (colorIndex > -1) {
      this.colorList[colorIndex].isUsed = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.record_id && changes.record_id.currentValue) {
      this.record_id = changes.record_id.currentValue.replace(/['"]+/g, '');
      this.getSubConceptList();
    }
    if(changes.isDeleted && changes.isDeleted.currentValue){
      if(this.isDbDelete){
        this.deletetePrediction();
      }else{
        this.view_results = this.view_results.filter(item => item.id != this.feedback_id);
        this.cleareFeedback(this.view_results);
      }
    }
  }

  getPridictionList() {
    this.catalogSvc.getPredictionList(this.projectId, this.record_id).subscribe(resp => {
      ($('#classification_tag_model') as any).modal('show');
    }, error => {
      ($('#classification_tag_model') as any).modal('show');
    });
  }

  closeClassificationTags() {
    $('#classification_tag_model').modal('hide');
    this.selectedTag = "";
    this.modelPopupOutput.emit(false);
  }

  closeModelPopup() {
    $('#classification_tag_model').modal('hide');
    $('#divBackdrop').addClass('d-none');
    this.selectedTag = "";
    this.view_results=[];
    this.modelPopupOutput.emit(false);
  }
  recieveSelectedText(data) {
    const selectedList = [];
    if (data.isMultiSelect) {
      this.selectedText = data.text;
      this.pridictionList.push(data.text)
    } else {
      this.pridictionList = [];
      this.selectedText = data.text;
      this.pridictionList.push(data.text);
    }
  }
  onTagIt() {
    this.subConceptOutput.emit({ isSubConcept: true, data: this.pridictionList });
  }
  onRightGridCreation(leftgrid) {
    this.rightGrid = leftgrid;
      setTimeout(() => {
        this.rightGrid.dataView.setItems([]);
        this.rightGrid.dataView.setItems(this.view_results);
    }, 500);
  }
  onSubConceptCreation(grid) {
    this.subConceptGrid = grid;
  }

  getSubConceptList() {
    let self = this;
    this.catalogSvc.getSubConceptList(this.projectId, this.record_id).subscribe(resp => {
      if (resp.length) {
        this.resetColorCode();
        resp.forEach(element => {
          element.id = Math.random();
          let colorObj = this.getNextUnusedColorCodeForSubConcept();
          element.textColor = colorObj.color;
          element.colorCode = colorObj.colorCode;
          this.setColorCode(colorObj);
        });
      }
      self.isLoading = false;
      self.view_results = resp;
      if (this.rightGrid) {
        setTimeout(() => {
          self.view_results = resp;
          self.rightGrid.dataView.setItems(self.view_results);
          self.rightGrid.slickGrid.invalidate();
          self.rightGrid.slickGrid.render();
        }, 200);
      } 
      self.baseService.deSelectText.next(this.view_results);
    }, error => { this.isLoading = false; });
  }

  onApplyChanges(){
    const results = this.view_results.map(result => {
      return {
        prediction : result['prediction'],
        id: this.record_id,
        sub_concept: result['sub_concept'],
        vote: 1
      }
    });

    let payload = {
      "task_id": this.task_id,
      "tasks": results
    };

    console.log('payload is ', payload);

    this.catalogSvc.saveSelectedFeedBack(payload, this.projectId, true).subscribe(resp => {
      if (resp) {
        $('#classification_tag_model').modal('hide');
        $('#divBackdrop').addClass('d-none');
        this.selectedTag = "";
        if (this.isFeedbackAdded) {
          this.modelPopupOutput.emit(true);
        }
        this.view_results = [];
      }
    });
  }

  onCellClick(eventData) {
    const args = eventData.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    let metadata = this.rightGrid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;

    if (fieldName === 'delete_icon' && !this.disableSubConceptsCapture) {
      const data = {
        dbDelete: true,
        messageHeader: `You are about to delete selected prediction.`,
        messageText: 'Are you sure that you want to delete this prediction ?',
        type: 'DELETE'
      }
      if (row.classify_project_concept_parser_feedback_id) {
        this.isDbDelete = true;
        this.feedback_id = row.classify_project_concept_parser_feedback_id
      } else {
        this.isDbDelete = false;
        this.feedback_id = row.id;
      }
      this.deletePrediction.emit(data);
    }
  }

  deletetePrediction() {
    this.catalogSvc.deleteFeedback(this.projectId, this.feedback_id).subscribe(resp => {
      this.view_results = this.view_results.filter(item => item.classify_project_concept_parser_feedback_id != this.feedback_id);
      this.cleareFeedback(this.view_results);
    }, error => {

    });
  }
  cleareFeedback(subConcepts) {
    if (this.rightGrid) {
      this.rightGrid.dataView.setItems([]);
      this.rightGrid.dataView.setItems(subConcepts);
      this.resetPrediction.emit(false);
    }
  }
}
