import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { forkJoin } from 'rxjs';
import { ZsClContentService } from "../../../../zs-cl-content.service";
import { ProjectService } from 'src/app/zettasense/content/project/project.service';

export const dropDownFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let options = '';
  dataContext.column.map(element => {
    if (element.dataset_column_id == dataContext.sub_concept_column_id) {
      options = options + `<option value= ${element.dataset_column_id} selected="selected">${element.column}</option>\n`;
    } else {
      options = options + `<option value= ${element.dataset_column_id}>${element.column}</option>\n`;
    }
  });
  return `<div class="select text-regular">
  <select id='column-${columnDef.field}-${row}' class="mt-1 form-control wd-260 f-size-14 ht-100">
    <option value="null">Select</option>
    ${options}
  </select>
</div>`;
}

export const dropDownFormattter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let options = '';
  dataContext.column_original.map(element => {
    if (element.dataset_column_id == dataContext.value_column_id) {
      options = options + `<option value= ${element.dataset_column_id} selected="selected">${element.column_original}</option>\n`;
    } else {
      options = options + `<option value= ${element.dataset_column_id}>${element.column_original}</option>\n`;
    }
  });
  return `<div class="select text-regular">
  <select id='column-${columnDef.field}-${row}' class="mt-1 form-control wd-260 f-size-14 ht-100">
    <option value="null">Select</option>
    ${options}
  </select>
</div>`;
}

@Component({
  selector: 'zetta-zs-cl-select-train-data-columns',
  templateUrl: './zs-cl-select-train-data-columns.component.html',
  styleUrls: ['./zs-cl-select-train-data-columns.component.scss']
})
export class ZsClSelectTrainDataColumnsComponent implements OnInit {
  isDataReady = false;
  objectList: any;
  gridColumnsDef: any = [];
  tableSettings: object = { 'height': "100%", 'width': "99.70%" };
  gridOptions: any;
  objectGrid: AngularGridInstance;
  loggedInUserDetails = null;
  project_id: number;
  project_type_id: number;
  total = 0;
  page = '1';
  limit = 20;
  hasScrolled = false;
  noData = false;
  columnData = [];
  dataCols = [];
  datasetIdS = '';
  dataSourceIds = '';
  datasourceID = [];
  datasetID = [];
  manageData=false;
  project_type:string;
  subConceptCol = [];
  valueColArr = [];
  isDisabled: Boolean = true;
  exitingDataColumns = [];

  constructor(private activatedRoute: ActivatedRoute,
    private datasetSvc: DatasetService,
    private zettaUtils: ZettaUtils,
    private router: Router,
    private contentService: ZsClContentService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.parent.parent.paramMap.subscribe(params => {
      this.project_id = +params.get('id');
    });

    this.activatedRoute.queryParamMap.subscribe(param => {
      this.project_type_id = +param['params']['project_type_id'];
      this.project_type = param.get('project_type');
      if (param.has('manageData')) {
        this.manageData = param.get('manageData') == 'true' ? true : false;
      }
    });

    this.activatedRoute.queryParamMap.subscribe(param => {
      this.datasetIdS = param['params']['dataset_id'];
    });
    let dsID = this.datasetIdS.split(',');
    this.datasetID.push(dsID);

    this.activatedRoute.queryParamMap.subscribe(param => {
      this.dataSourceIds = param['params']['datasource_id'];
    });
    let dsrID = this.dataSourceIds.split(',');
    this.datasourceID.push(dsrID);

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
      enableCellMenu: true,
    };
    this.initColDef();
    this.getTrainingDatasetColumns();    
    // keeping this for testing reference
    // for (let i = 0; i < this.datasetID[0].length; i++) {
    //   let payload = {
    //     user_id: this.loggedInUserDetails['user_id'],
    //     tenant_id: this.loggedInUserDetails['tenant_id'],
    //     dataset_id: this.datasetID[0][i],
    //     pageno: this.page,
    //     perpage: this.limit
    //   };
    //   this.columnData[i] = this.datasetSvc.getPagedDatasetColumns(payload);
    // };
    // forkJoin(this.columnData).subscribe(results => {
    //   this.getTrainingDataColumns(results);
    // });
  }

  initColDef() {
    this.gridColumnsDef = [
      {
        'displayname': 'Data Set',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'maxWidth': 200,
      },
      {
        'displayname': 'Sub-Concept column',
        'physicalname': 'sub_concept_column_id',
        'sortable': false,
        'filterable': false,
        'formatter': dropDownFormattter,
        'minWidth': 200,
        'maxWidth': 310,

      },
      {
        'displayname': 'Value Column',
        'physicalname': 'value_column_id',
        'sortable': false,
        'datatype': 'Array',
        'filterable': false,
        'formatter': dropDownFormattter2,
        'minWidth': 300,
        'maxWidth': 310,
      }
    ];
  }

  getTrainingDataColumns(colData) {
    let data = [];
    let existingDatasets : any;

    for (let i = 0; i < colData.length; i++) {
      let columnArray = colData[i]['currentpage'].filter(function (currentelement) {
        return currentelement.dataset_column_id && currentelement.column;
      })
      let valueArray = colData[i]['currentpage'].filter(function (currentelement) {
        return currentelement.dataset_column_id && currentelement.column_original;
      })

      let colArray = colData[i]['currentpage'].map(function (i) {
        return i.dataset_column_id;
      });
      if (this.exitingDataColumns.length) {
        existingDatasets = this.exitingDataColumns.find(ds => ds.dataset_id === colData[i]['currentpage'][0].dataset_id);
      }
      data.push({
        dataset_id: colData[i]['currentpage'][0].dataset_id,
        dataset_name: colData[i]['currentpage'][0].dataset_name,
        sub_concept_column_id: existingDatasets ? existingDatasets.sub_concept_column_id : colArray,
        value_column_id: existingDatasets ? existingDatasets.value_column_id : colArray,
        column: columnArray,
        column_original: valueArray
      });    
    }
    this.objectList = data;
    if (this.objectList.length === 0) {
      this.noData = true;
    }
    this.total = this.objectList.length;
    let concatedRows = this.objectList;
    if (this.objectGrid) {
      concatedRows = [...this.objectGrid.dataView.getItems(), ...this.objectList];
      this.objectList.forEach(element => {
        element.id = Math.random();
      });
      this.objectList = concatedRows;
      this.objectGrid.dataView.setItems(this.objectList);
      this.objectGrid.gridService.setSelectedRows([]);
      this.hasScrolled = false;
    }
    this.isDataReady = true;
    this.validate();
  }

  getTrainingDatasetColumns() {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.project_id
    };
    this.projectService.getClassifyProjectTraingDataset(data).subscribe(resp => {
      this.exitingDataColumns = resp;
      for (let i = 0; i < this.datasetID[0].length; i++) {
        let payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          dataset_id: this.datasetID[0][i],
          pageno: this.page,
          perpage: this.limit
        };
        this.columnData[i] = this.datasetSvc.getPagedDatasetColumns(payload);
      };
      forkJoin(this.columnData).subscribe(results => {
        this.getTrainingDataColumns(results);
      });
    }, error => {
    });
  }

  onCellClick(e) {
    const event = e.eventData;
    const args = e.args;
    const metadata = this.objectGrid.gridService.getColumnFromEventArguments(args);
    let row = metadata.row;
      let subConceptColVal = $('#column-sub_concept_column_id-' + row)[0]['value'];
      if ( !this.subConceptCol[row] ) {
        this.subConceptCol.splice(row, 0, subConceptColVal);
      } else {
        this.subConceptCol.splice(row, 1, subConceptColVal);
      }

      let valueCol = $('#column-value_column_id-' + row)[0]['value'];
      if ( !this.valueColArr[row] ) {
        this.valueColArr.splice(row, 0, valueCol);
      } else {
        this.valueColArr.splice(row, 1, valueCol);
      }

      for ( let i in this.subConceptCol ) {
        if ( this.subConceptCol[i] !== null && this.subConceptCol[i] !== 'null' ) {          
          for ( let i in this.valueColArr ) {
            if ( this.valueColArr[i] !== null && this.valueColArr[i] !== 'null' ) {              
              this.isDisabled = false;
              if ( this.subConceptCol[i] === this.valueColArr[i] ) {
                this.isDisabled = true;
                break;
              }
            }
          }
        }
      }
  }

  saveSubConceptSource() {
    let training_datasets = [];
    for (let i = 0; i < this.datasetID[0].length; i++) {
      let dsID = this.datasetID[0][i];
      let dsrID = this.datasourceID[0][i];
      let subConcept = +$('#column-sub_concept_column_id-' + i)[0]['value'];
      let valueColumn = +$('#column-value_column_id-' + i)[0]['value'];
      training_datasets.push({ datasource_id: dsrID, dataset_id: dsID, sub_concept_column_id: subConcept, value_column_id: valueColumn })
    }
    

    const payload = {
      training_datasets
    };
    this.contentService.saveTrainDatasetColumns(this.project_id, payload).subscribe(resp => {
      this.router.navigate(['../../add-data'], {relativeTo: this.activatedRoute, queryParamsHandling:'preserve'});
     // this.router.navigate(["/zs-cl/projects/" + this.project_id + "/add-data"], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
    }, error => {
    });
  }

  gridCreated(grid: AngularGridInstance) {
    this.objectGrid = grid;
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNextPage();
    }
  }

  onNextPage(): void {
  }

  onCancel() {
    if(this.manageData){
      this.router.navigate(['/zs-cl/projects', this.project_id], { queryParams: { project_type: this.project_type, project_type_id: this.project_type_id  }});
    }else{
      this.router.navigate(['/zs-cl/projects']);
    }
    
  }

  onPrev() {
    this.router.navigate(['../data-sets'], { relativeTo: this.activatedRoute, queryParams: { activeLink: '2' }, queryParamsHandling: 'merge' });
  }

  validate() {
    setTimeout(() => {
      for (let i = 0; i < this.datasetID[0].length; i++) {
        if ($('#column-sub_concept_column_id-' + i)[0]['value'] != 'null' && $('#column-value_column_id-' + i)[0]['value'] != 'null') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    }, 1000);    
  }
}
