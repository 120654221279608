import {
    Column,
    ColumnFilter,
    Filter,
    FilterArguments,
    FilterCallback,
    GridOption,
    OperatorType,
    OperatorString,
    SearchTerm,
} from 'angular-slickgrid';

// using external non-typed js libraries
declare var $: any;

export class FeedbackFilter implements Filter {
    private _clearFilterTriggered = false;
    private _shouldTriggerQuery = true;
    private $filterElm: any;
    grid: any;
    searchTerms: SearchTerm[];
    columnDef: Column;
    callback: FilterCallback;
    operator: OperatorType | OperatorString = OperatorType.equal;

    constructor(private value: string, private style?: string) {
    }

    /** Getter for the Column Filter */
    get columnFilter(): ColumnFilter {
        return this.columnDef && this.columnDef.filter || {};
    }

    /** Getter for the Grid Options pulled through the Grid Object */
    get gridOptions(): GridOption {
        return (this.grid && this.grid.getOptions) ? this.grid.getOptions() : {};
    }

    /**
     * Initialize the Filter
     */
    init(args: FilterArguments) {
        this.grid = args.grid;
        this.callback = args.callback;
        this.columnDef = args.columnDef;
        this.searchTerms = args.searchTerms || [];

        // filter input can only have 1 search term, so we will use the 1st array index if it exist
        const searchTerm = (Array.isArray(this.searchTerms) && this.searchTerms[0]) || '';

        // step 1, create HTML string template
        const filterTemplate = this.buildTemplateHtmlString();

        // step 2, create the DOM Element of the filter & initialize it if searchTerm is filled
        this.$filterElm = this.createDomElement(filterTemplate, searchTerm);

        // step 3, subscribe to the keyup event and run the callback when that happens
        this.$filterElm.keyup((e: any) => {
            const value = e && e.target && e.target.value || '';
            if (this._clearFilterTriggered) {
                this.callback(e, { columnDef: this.columnDef,
                    clearFilterTriggered: this._clearFilterTriggered,
                    shouldTriggerQuery: this._shouldTriggerQuery });
                this.$filterElm.removeClass('filled');
            } else {
                value === '' ? this.$filterElm.removeClass('filled') : this.$filterElm.addClass('filled');
                this.callback(e, { columnDef: this.columnDef, searchTerms: [value], shouldTriggerQuery: this._shouldTriggerQuery });
            }
            // reset both flags for next use
            this._clearFilterTriggered = false;
            this._shouldTriggerQuery = true;
        });
    }

    /**
     * Clear the filter value
     */
    clear(shouldTriggerQuery = true) {
        if (this.$filterElm) {
            this._clearFilterTriggered = true;
            this._shouldTriggerQuery = shouldTriggerQuery;
            this.$filterElm.val('');
            this.$filterElm.trigger('keyup');
        }
    }

    /**
     * destroy the filter
     */
    destroy() {
        if (this.$filterElm) {
            this.$filterElm.off('keyup').remove();
        }
    }

    /**
     * Set value(s) on the DOM element
     */
    setValues(values) {
        if (values) {
            this.$filterElm.val(values);
        }
    }

    //
    // private functions
    // ------------------

    /**
     * Create the HTML template as a string
     */
    private buildTemplateHtmlString() {
        return `<div class="${this.style} text-bold-black">${this.value}</div>`;
    }

    /**
     * From the html template string, create a DOM element
     * @param filterTemplate
     */
    private createDomElement(filterTemplate: string, searchTerm?: SearchTerm) {
        const $headerElm = this.grid.getHeaderRowColumn(this.columnDef.id);
        $($headerElm).empty();

        // create the DOM element & add an ID and filter class
        const $filterElm = $(filterTemplate);

        $filterElm.val(searchTerm);
        $filterElm.attr('id', `filter-${this.columnDef.id}`);
        $filterElm.data('columnId', this.columnDef.id);

        // append the new DOM element to the header row
        if ($filterElm && typeof $filterElm.appendTo === 'function') {
            $filterElm.appendTo($headerElm);
        }
        return $filterElm;
    }
}
