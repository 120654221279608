import { Column, Formatter } from 'angular-slickgrid';

export const blueTextModelFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    // if (dataContext.has_suggested_clusters && dataContext['status'] !== 'Completed') {
        return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}' data-toggle="modal" data-backdrop="true" data-target="#merger-feedback-modal">
                    ${value}
                </div>`;


        // return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}' data-toggle="modal" data-backdrop="true" data-target="#tm-modal">
        //             ${value}
        //         </div>`;


    // } else {
    //     return `<div class="text-truncate" title='${value}'>${value}</div>`;
    // }
}