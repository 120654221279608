import { AppGlobals } from '../../../../common/shared/app.globals';
import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project.model'; 
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { dateFormatter } from '../../../../common/zm-shared/zm-formatters/dateFormatter';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { DatasourceService } from 'src/app/common/components/datasources/datasource.service';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { ObservableService } from 'src/app/common/services/observable.service';
import { BaseService } from 'src/app/common/services/base-service';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, ITextFilterParams, ValueGetterParams, GetMainMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular'; 

import { ConfidenceLevelFormatterComponent } from 'src/app/common/shared/cell-renderer/confidence-level-formatter/confidence-level-formatter.component';
import { ConfidenceLevelGoldenFormatterComponent } from 'src/app/common/shared/cell-renderer/confidence-level-formatter/confidence-level-golden-formatter.component';
import { SourceSystemRendererComponent } from 'src/app/common/shared/cell-renderer/source-system-formatter/source-system-formatter';
import { MeasuredDQRuleFormatterComponent } from 'src/app/common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
 
declare var $: any;
 
const radioButtonFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (row === 0) {
        return `<div class="formatter radio-formatter">
                  <input type="radio" name="selected_row" id="selected_row_${row}" checked>
                  <label class="m-0 pointer" ></label>
                </div>`;
  } else {
        return `<div class="formatter radio-formatter">
                  <input type="radio" name="selected_row" id="selected_row_${row}">
                  <label class="m-0 pointer" ></label>
                </div>`;
  }
}

const textLinkFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.name === 'Field Name') {
      return `<div class="blueLink pointer text-truncate" title='` + value + `'>`+ value + `</div>`;
  } else {
    if(value === null) { return `` } else { return value }
  }
}

const textFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (dataContext.source_system === 'Manually Updated') {
        if (columnDef.name === 'Source System' ) {
            return value;
        }
        if (columnDef.name === 'Source Value' ) {
            return `<input type="text" id="source_value" value="${value}"
                      autofocus style=" height: 22px;
                      padding-left: 5px;
                      border-radius: 3px;
                      border: 1px solid #e6e6e6;
                      width: 100%;
                      color: #757575;">`
        }
        if (columnDef.name === 'Confidence' ) {
            return ``
        }
    } else {
        return value;
    }
}
 

const sourceSystemFormatter = (params) => { 
  const value = params.value;
  return `<div class="fixed-div" title="${value}">${value}</div>`;
};

const dataSourceDropdownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  var options = '';
  var valueName = '';
  if (columnDef.field === 'name') {
  if (columnDef.params.list && columnDef.params.list.length > 0) {
    for (var j of columnDef.params.list) {
                  options = options + '<option value=\''+j.datasource_id +'\'>'+ j.name +'</option>\n';
        }
      }   
  
  let placeholder = '';  
 placeholder = 'Select Datasource';
  var classList = 'grid-select-dropdown';
      return `<div class="selectInput w-100">
                  <select id='concept-${columnDef.field}-${row}' class="${classList} ctm-obj-wd">
                      <option value="">${placeholder}</option>
                      ${options}
                  </select>
              </div>`;
  }
}
const textBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
                `<div>
                <input type="text" class="input-text-box" id="concept-${columnDef.field}-${row}" value="${value}" />
                </div>` 

@Component({
  selector: 'zetta-golden-records',
  templateUrl: './golden-records.component.html',
  styleUrls: ['./golden-records.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class GoldenRecordsComponent implements OnInit {
 
  /* Stage 2 properties */
  public isStage2Loading = true;
  public isStage2SelectedClusterExpanded = false;
  public stage2SelectedClusterTotal = 0;

  public stage2Rows: object[];
  public stage2Columns: object[] = [];
  public projectHomeTableColDef: object[];
  public stage2selectedClusterName: string;
  public grid: AngularGridInstance;
  public stage2Grid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedClusterName;

  //Ag Grid Angular
  GridApi: any;
  gridApi: GridApi;
  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {}
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular; 
  columnApi: any;
  gridOptions: any;
  page = 1;
  limit = 100;
  leftTableColDef = [];    
  firstColDef=[];  
  hasScrolled = false;  
  isLoading = true;
  isDataReady = false;  
  pageno = 1;
  perpage = 100;
  noData = false;  
  grandTotal = 0;
  subTotal = '';
  searchFilterApplied: boolean = false;
  projectDetail: Project = new Project();
  goldenRecordKey = '';
  goldenRecordID = '';
  goldenZSRecordID = '';
  goldenRecordAttributeLength = 0;  
  public leftPanelDS: object=[];
  public leftPanelGridObject: AngularGridInstance;
  public leftPanelGridColumnsSettings: object = [];
  public leftPanelOptions: object = {};
  public isleftLoading = false;
  leftGridSelection: string;
  eventData: any;
  selectedConceptMetadata: any;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  trainModelTableColDef2: any = [];
  stage2GridOptions: any;
  targetDataSetName: any = '';
  targetDatasourceId: any = '';
  showPublishModal: Boolean = false;
  noRecentGoldenRecords: boolean = false;
  projectDetails: object = {};
  public leftPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };


  public rightPanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public rightPanelDS: object;
  public rightPanelGridObject: AngularGridInstance;
  public rightPanelGridColumnsSettings: object = [];
  public rightPanelOptions: object = {};
  public isrightLoading = false;
  rightGridSelection: string;
  public rightPanelTotal = 0;
  public goldenR: any;
  public columnName: string;
  public apiUrl: string = environment.config.API_URL; 
  public goldenRecord: any ;
  datasources= [];
  semanticDatasetsTableSettings: object = { 'height': "100%", 'width': "100%" };
  semanticDatasetsTableColDef: any;
  semanticDatasetsGridOptions: any;
  semanticDatasets: any = [];
  semanticDatasetsGrid: AngularGridInstance;
  entityId: any;
  entity_name: string;
  total = 0; 
  pageLeft = 1; 
  scrollableData: boolean = true;
  selectedTaskStatus: string;
  showRecentGoldenRecords: boolean = false;
  showConfigModal: boolean = false;
  modeState: any = {};
  public login_user = JSON.parse(sessionStorage.userInfo).entitlements;
  is_visible_publish = false;
  is_enable_publish = false
  includeReconciliation: Boolean = false;
  entityName: string;
  selectedRecentItem: string = 'last_24_hours';
  frequentFilters = [{name: 'Last 24 Hours', value: "last_24_hours"}, {name: "Last Week", value: "last_week"}, {name: 'Last Month', value: "last_month"}, {name: 'All Changes', value: 'most_recent' }];
  isAdminExists: boolean = false;
  trainingProject: boolean = false;
  constructor(
    public _observableService: ObservableService,
    private baseService: BaseService,
    private ProjectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    private _router: Router, public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,
    private ZsClContentService: ZsClContentService,
    private datasourceService: DatasourceService,
    private datssetService: DatasetService,
    public zettaUtils: ZettaUtils) {
    if (this.login_user) {
      try {
        let user_entitlements=this.login_user[0].entitlement;
        if(this.login_user.length>1){
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if(user_entitlements.length){
            user_entitlements=user_entitlements[0].entitlement;
          }else{
            user_entitlements=this.login_user[0].entitlement;
          }
        }
        const my_projects = user_entitlements.screens.filter(item => item.name == 'project_details')[0].childs.filter(item => item.name == 'golden_records')[0].childs[0];
        if (my_projects) {
          this.is_visible_publish = my_projects.visible;
          this.is_enable_publish = my_projects.enable;
        }
      } catch (error) {
        console.log(error);
      }

    }
    }

  ngOnInit() {

    sessionStorage.removeItem("serverfilter")
    sessionStorage.removeItem("sortfilter"); 
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id']; 
    // this.getGoldenRecords(this.page,this.limit); 
    
    });

    this.activatedRoute.queryParams.subscribe(qParams => {
      if(qParams.showRecent == 'true') {
        this.showRecentGoldenRecords = true;
      } else{
        this.showRecentGoldenRecords = false;
      }
      });

    if(this.showRecentGoldenRecords) {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true&time_range=${this.selectedRecentItem}`;
    } else {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true`;
    }
    // this.getFeedbackColumns2();
    // this.getGoldenRecodColumns();
    this.activatedRoute.parent.queryParams.subscribe(resp => {
    this.entityId = resp.entityId ? resp.entityId : resp.entity_id;
    this.entity_name = resp.entity_name;
    if (resp.selectedEntity) {
      this.entityName = resp.selectedEntity;
    }
  }); 
    if(this._router.url.includes('production-stage-details')) {
      this.modeState.mode = 'Production';
    } else {
      this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
    }
    sessionStorage.removeItem('resetpage_filter');
    sessionStorage.removeItem('activeNavLnk');
    
    this.stage2GridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      frozenColumn: 2,      
    };

    this.fsGridOptions = {  
      headerHeight: 45,  
      rowHeight: 30,   
      floatingFiltersHeight: 49,
      enableCellTextSelection: true,
      getRowStyle: params => { 
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };   

    this.isDataReady = true;

    this.leftPanelOptions = {
    enableGridMenu: false,
    frozenRow: -1,
    enableAddRow: false,
    selectable: true,
    editable: false,
    autoEdit: false,
    enableFiltering: true,
    CheckboxSelector: false,
    enableCellNavigation: true,
    };

    this.rightPanelOptions = {
      enableGridMenu: false,
      frozenRow: -1,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: ' ',
    };

    this.leftPanelGridColumnsSettings = [
      {
        'displayname': 'Field Name',
        'physicalname': 'display_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 120,
        'formatter': textLinkFormatter,
      },
      {
        'displayname': 'Value Selected',
        'physicalname': 'value',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': textLinkFormatter,
      },
      {
        'displayname': 'Last Updated Date',
        'physicalname': 'updated_ts',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        // 'cssClass': 'text-right',
        // 'headerCssClass': 'text-right',
        'formatter': dateFormatter,
      },
      {
        'displayname': 'Last Edited By',
        'physicalname': 'updated_by',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        // 'cssClass': 'text-right',
        // 'headerCssClass': 'text-right',
      }
    ];

    this.rightPanelGridColumnsSettings = [
      {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 40,
        'maxWidth': 40,
        'formatter': radioButtonFormatter,
      },
      {
        'displayname': 'Source Value',
        'physicalname': 'source_value',
        'sortable': false,
        'datatype': 'String',
        'filterable': true,
        'formatter': textFormatter,
      },
      {
        'displayname': 'Confidence',
        'physicalname': 'confidence',
        'sortable': false,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'headerCssClass': 'text-right',
        'formatter': textFormatter,
      }
    ];
    // this.getGoldenRecords(this.pageno, this.limit);
  this.getDatasources();
  this.getSemanticDatasetsByEntity();
  this.initColDef();
  this.semanticDatasetsGridOptions = {
   enableGridMenu: false,
   enableAddRow: false,
   selectable: false,
   enableFiltering: false,
   CheckboxSelector: false,
   enableCellNavigation: true,
   multiSelectable: false,
  };

  this.getEntityDetails(this.entityId);
  // this.getFeedbackColumns2();
  this.getGoldenRecodColumns();
  this.defaultColDef = {
    resizable: true, 
    flex: 1,
    minWidth: 100,  
  };
  this.getProjectDetails();
}
ngOnDestroy() {
  this.messageSvc.clearMessage();
  this.baseService.showConfirmation.unsubscribe();
  this._observableService.unsubscribe();
} 

getProjectDetails() {
  this.ProjectSvc.getProject(this.loggedInUserDetails.user_id, this.projectDetail.project_id)
  .subscribe(resp => {
    if(resp) {
      this.trainingProject = resp.project_status === AppGlobals.RESOLVE_PROJECT_STATUS_TRAINING ? true : false; 
      let loggedInUser =   (resp && resp.users && resp.users.length) ? resp.users.find(user => user.user_id == this.loggedInUserDetails.user_id) : null;
      if(loggedInUser) {
        let isAdmin = loggedInUser.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN);
        this.isAdminExists = isAdmin == 1 ? true : false;
      } else {
        this.isAdminExists = false;
      }
      this.projectDetails = resp;
    }
  });
}

onGridReady(params: GridReadyEvent) {  
  this.gridApi = params.api;  
  // this.getFeedbackColumns2();
  // this.getGoldenRecodColumns();
  // this.onStage2GridCreation(this.grid);
  // this.getGoldenRecords(this.pageno, this.limit); 

 }
  onStage2GridCreation(grid) {
    this.grid = grid;
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
    this.zettaUtilsSvc.setGridHorizontalScrollTogether('tm-modal');
  }
  onleftGridCreation(leftgrid) {
    this.leftPanelGridObject = leftgrid;
  }

  onrightGridCreation(rightgrid) {
    this.rightPanelGridObject = rightgrid;
  }

  // getFeedbackColumns2() {
  //   return [
  //     {      
  //       headerName: 'Data Quality Scores',
  //       field: 'dq_score',  
  //       filter: 'agTextColumnFilter',
  //       floatingFilter: true, 
  //       sortable: true,   
  //       maxWidth: 115,
  //       minWidth: 115,
  //       pinned: 'right',
  //       headerClass: 'text-left titleBreak pr-3',
  //       cellClass: 'text-center golden-rec-justify-content',
  //       floatingFilterComponentParams: {
  //         suppressFilterButton: true,
  //       },
  //       cellRendererFramework: ConfidenceLevelGoldenFormatterComponent,   
  //       cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus }
  //   },
  //   { 
  //     headerName: '# of Distinct Source System',
  //     field: 'distinct_source_systems',  
  //     filter: 'agTextColumnFilter',
  //     floatingFilter: true, 
  //     sortable: true,  
  //     pinned: 'right', 
  //     maxWidth: 135,
  //     minWidth: 135,
  //     cellClass: 'text-center golden-rec-justify-content',
  //     headerClass: 'pr-3',
  //     floatingFilterComponentParams: {
  //       suppressFilterButton: true,
  //     },
  //     cellRendererFramework: SourceSystemRendererComponent,      
  //     cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus }
  //   },    
  //   { 
  //     headerName: '# of Child Records',
  //     field: 'number_of_child_records',  
  //     filter: 'agTextColumnFilter',
  //     floatingFilter: true, 
  //     sortable: true,  
  //     pinned: 'right', 
  //     maxWidth: 115,
  //     minWidth: 115,
  //     cellClass: 'text-center golden-rec-justify-content',
  //     headerClass: 'text-left titleBreak pr-3',
  //     floatingFilterComponentParams: {
  //       suppressFilterButton: true,
  //     },
  //     cellRendererFramework: SourceSystemRendererComponent,      
  //     cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus }
  //   }
  // ];
    
  // } 

  getGoldenRecodColumns():any[]{    
    let parent = this;
    this.trainModelTableColDef2=[];
    this.ProjectSvc.getGoldenRecordColumns(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe((data) =>{
      if(data && data['rows'].length){

        let sourceSystemDynamicColumn = null;
        let sourcePrimaryKeyColumn = null;
        let lastRecordUpdatedColumn = null; 

        parent.trainModelTableColDef2.push({ 
            headerName: 'Master ID',
            field: 'zs_cluster_id',  
            sortable: true, 
            filter: 'agTextColumnFilter',
            floatingFilter: true, 
            editable: false,  
            minWidth: 150,  
            lockPinned: true,
            minHeight: 1040,
            headerClass: 'text-left titleBreak pr-3',
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRendererFramework: SourceSystemRendererComponent,
            tooltipField : 'zs_cluster_id'
        }); 

        data['rows'].forEach((element, index) => { 
           if (element.display_name !== AppGlobals.SOURCE_SYSTEM && element.display_name !== AppGlobals.IDENTIFIER_AT_SOURCE && element.display_name !== AppGlobals.SOURCE_SYSTEM_IDENTIFIER && element.display_name !== AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY && element.display_name !== AppGlobals.SOURCE_PRIMARY_KEY && element.display_name !== AppGlobals.LAST_RECORED_UPDATED_DATE  && element.display_name !== AppGlobals.RECORED_LAST_UPDATED_DATE) {
            parent.trainModelTableColDef2.push(
              { 
                headerName: element.display_name,
                field: element.physical_name,  
                sortable: true, 
                lockPinned: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true, 
                minWidth: 90, 
                minHeight: 100,   
                debounceMs: 400, 
                tooltipField : element.physical_name,
                // cellRenderer: params => {
                //   console.log("Cell Renderer Params:", params.colDef.field);
                //   return (params.value && params.value !== 0) ? 
                //     (typeof params.value === 'string' && params.value.includes(`$fsDelimiter$`)) ? 
                //       params.value.replaceAll('$fsDelimiter$', ';') : params.value 
                //     : `<span style="opacity: 0.6;">Null</span>`;
                // },
                cellRenderer: params => (params.value &&  params.value  !== 0) ? (typeof params.value === 'string' && params.value.includes(`$fsDelimiter$`)) ? params.value.replaceAll('$fsDelimiter$', ';'): params.value : `<span style="opacity: 0.6;">Null</span>`,
                floatingFilterComponentParams: {
                  suppressFilterButton: true, 
                } 
              }
            );
          } else {
            if ((element.display_name === AppGlobals.IDENTIFIER_AT_SOURCE) || (element.display_name === AppGlobals.SOURCE_SYSTEM_IDENTIFIER) || (element.display_name === AppGlobals.SOURCE_PRIMARY_KEY) || (element.display_name === AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY)) {
               sourcePrimaryKeyColumn = { 
                headerName: element.display_name,
                field: element.physical_name,  
                sortable: true, 
                lockPinned: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true, 
                minWidth: 90, 
                minHeight: 100,   
                debounceMs: 400, 
                tooltipField : element.physical_name,
                cellRenderer: params => (params.value &&  params.value  !== 0) ? (typeof params.value === 'string' && params.value.includes(`$fsDelimiter$`)) ? params.value.replaceAll('$fsDelimiter$', ';'): params.value : `<span style="opacity: 0.6;">Null</span>`,
                floatingFilterComponentParams: {
                  suppressFilterButton: true, 
                } 
              };
            }

            if ((element.display_name == AppGlobals.LAST_RECORED_UPDATED_DATE) || (element.display_name === AppGlobals.RECORED_LAST_UPDATED_DATE)) {
              lastRecordUpdatedColumn = { 
                headerName: element.display_name,
                field: element.physical_name,  
                sortable: true, 
                lockPinned: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true, 
                minWidth: 90, 
                minHeight: 100,   
                debounceMs: 400, 
                tooltipField : element.physical_name,
                cellRenderer: params => (params.value &&  params.value  !== 0) ? (typeof params.value === 'string' && params.value.includes(`$fsDelimiter$`)) ? params.value.replaceAll('$fsDelimiter$', ';'): params.value : `<span style="opacity: 0.6;">Null</span>`,
                floatingFilterComponentParams: {
                  suppressFilterButton: true, 
                } 
              };
            }

            if (element.display_name === AppGlobals.SOURCE_SYSTEM) {
              sourceSystemDynamicColumn = { 
                headerName: element.display_name,
                field: element.physical_name,  
                sortable: true, 
                lockPinned: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true, 
                minWidth: 90, 
                minHeight: 100,   
                debounceMs: 400, 
                tooltipField : element.physical_name,
                cellRenderer: params => (params.value &&  params.value  !== 0) ? (typeof params.value === 'string' && params.value.includes(`$fsDelimiter$`)) ? params.value.replaceAll('$fsDelimiter$', ';'): params.value : `<span style="opacity: 0.6;">Null</span>`,
                floatingFilterComponentParams: {
                  suppressFilterButton: true, 
                } 
              };
            }
          }
        }); 

        if (lastRecordUpdatedColumn) {
          parent.trainModelTableColDef2.push(lastRecordUpdatedColumn);        
        }
        if (sourcePrimaryKeyColumn) {
          parent.trainModelTableColDef2.unshift(sourcePrimaryKeyColumn);
        }
        if (sourceSystemDynamicColumn) {
          parent.trainModelTableColDef2.unshift(sourceSystemDynamicColumn);
        }

        parent.trainModelTableColDef2.push(
          {      
            headerName: 'Data Quality Scores',
            field: 'dq_score',  
            filter: 'agTextColumnFilter',
            floatingFilter: true, 
            sortable: true,  
            lockPinned: true, 
            maxWidth: 125,
            minWidth: 115,
            pinned: 'right',
            headerClass: 'text-left titleBreak pr-3 text-break-left-align',
            cellClass: 'text-center golden-rec-justify-content lock-pinned',
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRendererFramework: ConfidenceLevelGoldenFormatterComponent,   
            cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus },
            tooltipField : 'dq_score'
        },
        { 
          headerName: '# of Distinct Source System',
          field: 'distinct_source_systems',  
          filter: 'agTextColumnFilter',
          floatingFilter: true, 
          sortable: true,  
          pinned: 'right', 
          lockPinned: true,
          maxWidth: 155,
          minWidth: 140,
          cellClass: 'text-center golden-rec-justify-content lock-pinned',
          headerClass: 'pr-3 text-left',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          tooltipField:'distinct_source_systems',
          cellRendererFramework: SourceSystemRendererComponent,      
          cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus }
        },    

        { 
          headerName: '# of Child Records',
          field: 'number_of_child_records',  
          filter: 'agTextColumnFilter',
          floatingFilter: true, 
          sortable: true,  
          pinned: 'right', 
          lockPinned: true,
          maxWidth: 135,
          minWidth: 115,
          cellClass: 'text-center golden-rec-justify-content lock-pinned',
          headerClass: 'text-left titleBreak pr-3',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          tooltipField: 'number_of_child_records',
          cellRendererFramework: SourceSystemRendererComponent,      
          cellRendererParams: { selectedTaskStatus: this.selectedTaskStatus }
        }
        );

        if(parent.showRecentGoldenRecords) {
          parent.trainModelTableColDef2.push(
            {      
              headerName: 'Last Updated',
              field: 'last_updated',  
              filter: 'agTextColumnFilter',
              floatingFilter: true, 
              sortable: true,  
              lockPinned: true, 
              maxWidth: 130,
              minWidth: 115,
              pinned: 'right',
              headerClass: 'text-left titleBreak pr-2',
              cellClass: 'text-center golden-rec-justify-content lock-pinned',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              cellRendererFramework: DateFormatterFormatterComponent,
              tooltipField : 'last_updated'
          },
          );
        }

        parent.trainModelTableColDef2.forEach(column => {
          column.minWidth = 200;
        });
      } 
      parent.stage2Columns = parent.trainModelTableColDef2;
      parent.isLoading = false; 
    }, (err) => {
      parent.stage2Columns = parent.trainModelTableColDef2;
      parent.isLoading = false;
    });
    if(this.showRecentGoldenRecords) {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true&time_range=${this.selectedRecentItem}`;
    } else {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true`;
    }
    // this.stage2Columns = parent.trainModelTableColDef2;
    // console.log('stage2 columns: ', this.stage2Columns);
     return parent.trainModelTableColDef2;
  }
 
  getGoldenRecords(pageno, limit = 1) {
     
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true&paginate=true&pageno=${1}&pagesize=${limit}`;
    if(this.showRecentGoldenRecords) {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true&time_range=${this.selectedRecentItem}`;
    } else {
      this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?forGoldenRecords=true`;
    }

    let serverFilter="";
   if(sessionStorage.getItem("serverfilter")){
      serverFilter=sessionStorage.getItem("serverfilter");
   }

   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   } 

    this.ProjectSvc.getGoldenRecords(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, pageno, limit,serverFilter,sortfilter, this.showRecentGoldenRecords? true: false, this.showRecentGoldenRecords ? this.selectedRecentItem : '').subscribe((data) => {
      this.stage2Rows = data['currentpage'];
      let tempData = data['currentpage']; 
      // this.isLoading = false; 
      if(pageno==1) {
        if(data['currentpage'].length == 0 && this.showRecentGoldenRecords) {
          this.noRecentGoldenRecords = true;
        } else {
          this.noRecentGoldenRecords = false;
        }
        this.stage2Columns = this.trainModelTableColDef2; 
        // this.stage2Columns.unshift(...this.getFeedbackColumns2());
      }   
      let columnKeys = this.stage2Columns.map((columns) => {  
        return columns['field']; 
      }); 

      let goldenRecordDelimiter = '$fsDelimiter$'; 
      tempData.forEach((goldenRecordData, index) => { 
        goldenRecordData['id'] = Math.random();
          columnKeys.forEach((columns) => {  
            //goldenRecordData[columns] = goldenRecordData[columns] === '' ? 'null' : goldenRecordData[columns];
            if (goldenRecordData[columns] && typeof goldenRecordData[columns]==='string' && goldenRecordData[columns].includes(goldenRecordDelimiter)) {
              goldenRecordData[columns] = goldenRecordData[columns].replaceAll(goldenRecordDelimiter, ';');

            }
          })
      }) 
      this.stage2Rows = tempData; 
      
      if (data['currentpage'].length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = data['totalrecords'];
      if (pageno === 1) { 
        if(this.showRecentGoldenRecords) {
          this.grandTotal = data['currentpage'].length < limit ? data['currentpage'].length: data['totalrecords'];
        } else {
          this.grandTotal = data['totalrecords']; 
        }
      }
      
      if (pageno > data['totalpages']) {
        this.isLoading = false;
        this.page = 1;
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners?paginate=true&pageno=${1}&pagesize=${limit}&forGoldenRecords=true`;
        }
      
    }, err => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    
    });
  }

  getEntitiesmasteredByClusterId(zsClusterId) {
    this.ProjectSvc.getGoldenRecord(this.loggedInUserDetails.user_id, this.projectDetail.project_id, zsClusterId).subscribe((data) => {
        const rows = data;
        rows.splice(0, 2);
        rows.splice(rows.length-6, 6);
        this.goldenRecordAttributeLength = rows.length;
        if (this.leftPanelGridObject) {
          rows.forEach(element => {
            element['id'] = Math.random();
          });
          this.leftPanelGridObject.dataView.setItems(rows);
          this.leftPanelGridObject.gridService.setSelectedRows([]);
        }
        this.leftPanelDS = rows;
        this.isleftLoading = false;
      }, err => {
        this.leftPanelDS = [];
        this.isleftLoading = false;
      });
  }
  goToPage(n: number): void {
    this.page = n;
    // this.getGoldenRecords(this.page, this.limit);
  }

  onNext(): void {   
    this.isLoading = true; 
    this.page++;
    // this.getGoldenRecords(this.page, this.limit);    
  }
 

  onPrev(): void {
    this.page--;
    // this.getGoldenRecords(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtilsSvc.getTotalPages(this.total, this.limit);
  }

  onScroll(parentClass) { 
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled) {
      this.zettaUtilsSvc.setGridAutoScrollTogether(parentClass);
      this.hasScrolled = true;
      this.onNext();
      
    }    
  } 

  onCellClick(event: any) {
    const rowData = event.data;
    if (rowData) {
      this.isleftLoading = true;
      this.rightPanelDS = [];
      this.goldenRecord = rowData;
      this.goldenRecordID = rowData[this.goldenRecordKey];
      this.goldenZSRecordID = rowData['zs_record_id'];
      this.getEntitiesmasteredByClusterId(rowData['zs_cluster_id']); 
      sessionStorage.setItem("selected_FN", rowData.full_name);
      if(this._router.url.includes('production-stage-details')) {
        this._router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details/golden-records/detail`], {
          queryParams: { 
            cluster_id: rowData['zs_record_id'], 
            record_id: rowData['zs_cluster_id'], 
            selectedEntity: this.entityName, 
            entity_id: this.entityId,
            noReloadRequired: false 
          }, 
          queryParamsHandling: 'merge' 
        });
      } else {
        this._router.navigate([`/zs/projects/${this.projectDetail.project_id}/golden-records/detail`], {
          queryParams: { 
            cluster_id: rowData['zs_record_id'], 
            record_id: rowData['zs_cluster_id'], 
            selectedEntity: this.entityName, 
            noReloadRequired: false 
          }, 
          queryParamsHandling: 'merge' 
        });
      }

    }
  }
 
  onleftPanelRowSelection(params: any): void {
    const row = params.node.data;
    const column = params.column;
    this.columnName = column.colDef.headerName;
    if (row !== undefined && row !== null) {
      // this.isrightLoading = true;
      this.getRightGridEntities(this.columnName);
    }
  }

  getRightGridEntities(columnName) {
    this.ProjectSvc.getGoldenRecordColumn(this.loggedInUserDetails.user_id,
      this.projectDetail.project_id, this.goldenRecordID, columnName).subscribe((data) => {
        const rows = data;

        if (this.rightPanelGridObject) {
            rows.forEach(element => { 
              element['id'] = Math.random();
            });
            this.rightPanelGridObject.dataView.setItems(rows);
            this.rightPanelGridObject.gridService.setSelectedRows([]);
        }
        this.rightPanelDS = rows;
        this.rightPanelTotal = this.rightPanelDS['length'];
        this.isrightLoading = true;
        this.goldenR = {
                        zs_cluster_id: this.goldenRecordID,
                        zs_record_id: this.goldenZSRecordID,
                        source_system: this.rightPanelDS[0].source_system,
                        column_name: this.columnName,
                        column_value: this.rightPanelDS[0].source_value
                      };
      }, err => {
        this.rightPanelDS = [];
        this.isrightLoading = false;
      });
  }

  onrightPanelRowSelection(e) {
    const args = e.args;
    const row = this.rightPanelGridObject.gridService.getDataItemByRowIndex(args.row);
    var columnValue = '';
    if(row.source_system === 'Manually Updated') {
        columnValue = $("#source_value").val();
        $("#source_value").focus();
    } else {
        columnValue = row.source_value;
    }
    this.goldenR = {
                    zs_cluster_id: this.goldenRecordID,
                    zs_record_id: this.goldenZSRecordID,
                    source_system: row.source_system,
                    column_name: this.columnName,
                    column_value: columnValue
                  };
    $("#selected_row_" + args.row).prop("checked", true);
  }

  onSave() {
    if(this.goldenR.source_system === 'Manually Updated') {
      this.goldenR.column_value = $("#source_value").val()
    }
    this.ProjectSvc.updateGoldenRecord(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.goldenR).subscribe((data) => { });
    this.getGoldenRecords(this.page, this.limit);
    this.getEntitiesmasteredByClusterId(this.goldenR.zs_cluster_id)
    this.getRightGridEntities(this.goldenR.column_name)
  }

  publishGoldenRecord() {
      this.ZsClContentService.exportResult(this.apiUrl + `/users/` + this.loggedInUserDetails['user_id'] + `/projects/` + this.projectDetail.project_id + `/entitiesmastered/winners/publish?file_name=${this.targetDataSetName}`).subscribe(resp => {
        this.ZsClContentService.exportCsvFiles(resp, `winner`);
      this.includeReconciliation = false;
      this.initColDef();
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onClose() {
    location.reload();
  }
  receiveTotalRecords($event) {
    // this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
    if (this.grandTotal === 0) {
      this.grandTotal = $event.subTotal;
    }
    if(this.showRecentGoldenRecords) {
      this.subTotal = $event.subTotal;
    } else {
      this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal); 
    }
    if(sessionStorage.getItem("serverfilter")){
      let filter = sessionStorage.getItem("serverfilter");
      if(filter && filter.length>0) {
        this.searchFilterApplied = true;
      } else {
        this.searchFilterApplied = false;
      }
   }
   let jqueryElemnt = $('.ag-body-viewport.ag-layout-normal').eq(9).html('ag-layout-normal');
   this.scrollableData = jqueryElemnt.prevObject[0].scrollHeight > jqueryElemnt.prevObject[0].clientHeight; 
  }

   initColDef() {
    this.semanticDatasetsTableColDef = [
          {
            'displayname': 'Target Data Source',
            'physicalname': 'name',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': dataSourceDropdownFormatter,
            'minWidth': 150,
            'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
            'params': { list: this.datasources, tabindex: 1},
            },{
              'displayname': 'Target Data Set',
              'physicalname': 'target_dataset_name',
              'sortable': true,
              'datatype': 'String',
              'filterable': false,
              'formatter': textBoxFormatter,
              'minWidth': 150,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              }
    ];
  }

  getSemanticDatasetsByEntity() {
    this.semanticDatasets = [{
      id: Math.random(),
      target_dataset_name: this.entity_name + '_' + Date.now(),
    }];
  }
  getDatasources() {
    this.datasourceService.getDatasources({userId: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id, query: `datasource_types=${AppGlobals.DATASOURCE_TYPE_HDFS},${AppGlobals.DATASOURCE_TYPE_CLOUDFS}&has_access_only=true`})
    .subscribe(res => {
      this.datasources = res['currentpage'];
    });
  }

  semanticsModalCreated(grid) {
    this.semanticDatasetsGrid = grid;
  }
  onSemanticDsGridClickEvent(eventData) {
    const parent = this;
    this.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const metadata = this.semanticDatasetsGrid.gridService.getColumnFromEventArguments(args);
    this.selectedConceptMetadata = metadata;
    const row = this.semanticDatasetsGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    if(fieldName == 'name') {
      $('#concept-name-0').on('change', function(e) {
        let entityVal = $('#concept-name-0')[0]['value'];
        parent.targetDatasourceId = entityVal;
        parent.targetDataSetName = $('#concept-target_dataset_name-0').val();
      });
    }
     if (fieldName == 'target_dataset_name') {
        $('#concept-target_dataset_name-0').on('keyup',function(e){
          // to get the value from textbox which is returned as formatter from grid
          let dsName = $(this).val();
          parent.targetDataSetName = `${dsName}`;
        });
      }
  }
  
  publishData() {
    let validationData = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      name: this.targetDataSetName
    };
    this.datssetService.validateDatasetName(validationData)
    .subscribe(resp => {
        let data = {
          "project_id": this.projectDetail.project_id,
          "datasource_id": this.targetDatasourceId,
          "dataset_name": `${this.targetDataSetName}`,
          "loggedInUserId": this.loggedInUserDetails['user_id']
        };
        this.ProjectSvc.publishGoldenData(data)
        .subscribe(res => {
          if(res) {
            this.targetDataSetName = '';
            this.targetDatasourceId = '';
            this.includeReconciliation = false;
            $('#concept-name-0')[0]['value'] = '';
            $('#concept-target_dataset_name-0')[0]['value'] = '';
            this.showPublishModal = false;
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.initColDef();
            this.messageSvc.sendMessage({
              type: "INFO",
              hideInfo: true,
              publishGoldenRecords: true,
              activeJobs: "ResolvePublishedProject",
              isModel: true,
              messageHeader: AppGlobals.PUBLISH_GOLDEN_RECORDS_HEADER_MESSAGE,
              message: AppGlobals.PUBLISH_GOLDEN_RECORDS_MESSAGE,
            });
          }
        },
        err => {
          this.messageSvc.sendMessage({ message: err.error, type: 'ERROR', hideboard: true });
        });
        if(this.includeReconciliation) {
          this.publishGoldenRecord();
        }
    }, 
    error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  triggerModal() {
    this.initColDef();
    this.showPublishModal = true;
  }

  triggerConfigModal() {
    this.showConfigModal = true;
  }

  toggleReconcilation(eve) {
    this.includeReconciliation = eve.target.checked;
  }

  getEntityDetails(entityId) {
    this.ProjectSvc.getEntityAttributes(entityId)
    .subscribe(resp => {
      if(resp && resp['attributes']) {
        let attribute = resp['attributes'].filter(ele => ele['attribute_id'] == resp['cluster_identifier_attribute_id']);
        this.entityName = attribute[0]['logical_name'];
      }
    });
  }
  
  hidePublishModal() {
    this.targetDataSetName = '';
    this.targetDatasourceId = '';
    this.includeReconciliation = false;
    $('#concept-name-0')[0]['value'] = '';
    $('#concept-target_dataset_name-0')[0]['value'] = '';
    this.showPublishModal = false;
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.initColDef();
  }

  navigateToHome() {
    if(this._router.url.includes('production-stage-details')) {
      this._router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details`], {queryParamsHandling: 'merge' });
    } else {
      this._router.navigate([`/zs/projects/${this.projectDetail.project_id}`]);
    }
  }

  onChange(eve) {
    this.selectedRecentItem = eve.target.value;
    // this.recentGoldenRecords = [];
    this.isLoading = true;
    this.grandTotal = 0;
    this.subTotal = '';
    this.page = 1;
    this.getGoldenRecords(this.page,this.limit);
  }

  hideConfigModal(eve) {
    if(eve) {
      console.log('the event is ======  ', eve);
      this.showConfigModal = false;
    }
  }
}
