import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
// import { ActivatedRoute } from '@angular/router';
// import { AngularGridInstance } from 'angular-slickgrid';
// import { ProjectService } from '../../../../zettasense/content/project/project.service';

@Component({
  selector: 'zetta-zm-workbooks-details',
  templateUrl: './zm-workbooks-details.component.html',
  styleUrls: ['./zm-workbooks-details.component.scss']
})
export class ZmWorkbooksDetailsComponent implements OnInit {

  // dataSetName: String;
  // dataRes;
  activeNavLnk="dataset-formulas";
  // showmodelpopup = false;
  // datasetId;
  // @Output() dataRes1 = new EventEmitter<any>();
  // tableSettings: object = { 'height': "100%", 'width': "99.71%" };
  // public grid: AngularGridInstance;
  // public loggedInUserDetails = null;
  // dsSampleRows: any;
  // isSampleDsLoading=false;
  // gridOptions: any;
  // gridColumnDef = [];
  // page = 1;
  // limit = 15;
  // total_rows;
  // total_column;
  
  constructor(
              // private activatedRoute: ActivatedRoute,
              // private _projectService: ProjectService,
              // private sanitizer: DomSanitizer
              ) { }

  ngOnInit() {
    // this.dataRes = false;
    // console.log(this.activatedRoute)
    // this.activatedRoute.params.subscribe(params => {
    //   console.log(params)
    //   this.datasetId = params['id'];
    // });
    // console.log(this.datasetId)
    // this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));

    // this.gridOptions = {
    //     enableGridMenu: false,
    //     selectable: true,
    //     enableFiltering: true,
    //     CheckboxSelector: false,
    //     enableCellNavigation: true,
    //     enableRowSelection: true
    // };
    // this.selectOptionDataSetModelList(this.datasetId);
  }

  // selectOptionDataSetModelList(dataSetId, limit = 30) {        
  //   this._projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
  //     if (data) {
  //       this.dsSampleRows = data['currentpage'];
  //       console.log(data);
  //       this.isSampleDsLoading = false;
  //       let rowKeys = [];
  //       this.total_rows = data.totalrecords
  //       if (data['currentpage'].length > 0) {
  //         rowKeys = Object.keys(data['currentpage'][0]);
  //         this.total_column = rowKeys.length
  //       }
  //       this.gridColumnDef = [];
  //       rowKeys.forEach(column => {
  //         this.gridColumnDef.push({
  //           'displayname': column,
  //           'physicalname': column,
  //           'sortable': true,
  //           'datatype': 'String',
  //           'filterable': true,
  //           'minWidth': 150,
  //         });
  //       });

  //       this.dsSampleRows.forEach((element: any) => {
  //         element.id = Math.random();
  //       });
  //       if (this.grid) {
  //         this.grid.dataView.setItems(this.dsSampleRows);
  //         this.grid.gridService.setSelectedRows([]);
  //       }
  //       this.isSampleDsLoading = false;
  //     }

  //   }, err => {
  //     this.isSampleDsLoading = false;
  //     this.dsSampleRows = [];
  //   });
  // }
  // onGridCreation(grid) {
  //   this.grid = grid;
  // }
  onTabClick(activeLink: string) {
    this.activeNavLnk = activeLink;
    sessionStorage.setItem('activeNavLnk', activeLink);
 }
  // getDataSetName(dataSetName) {
  //   this.dataSetName = dataSetName;
  // }

  // receiveData($event) {
  //   this.dataRes = $event;
  //   this.dataRes1.emit(this.dataRes);
  // }
  // showpopup() {
  //   this.showmodelpopup = true;   
  // }

  // popupoutput(message){
  //     this.showmodelpopup = message;   
  //   }

}
