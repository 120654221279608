import { Component,OnInit, ViewEncapsulation } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
@Component({
    selector: 'project-name-formatter',
    template: `
      <div [ngClass]="getCssClasses()"title="{{value}}" style='color:#0e709f'>
        <i class="mr-2 {{getProjectEntityClassById()}}"></i>
        <div class="hyper-link" title="{{value}}">{{value}}</div>
      </div>
    `,
    styles: [`.agGrid .ag-theme-balham .ag-cell .hyper-link {
      display: inline;
  }
  
  .agGrid .ag-theme-balham .ag-cell .hyper-link:hover {
      text-decoration: underline;
      color:#0e709f;
  }`],
  
    encapsulation: ViewEncapsulation.None
  })
  export class ProjectNameFormatterComponent implements OnInit, ICellRendererAngularComp {
    active = false;
    value: any;
    params: any;
    zettaUtilsInstance = new ZettaUtils();
    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.value = params.value;  
    }
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
    ngOnInit(): void {
    }
    getCssClasses(): string {
      return this.active ? 'text-truncate' : 'text-truncate';
    }
    getProjectEntityClassById()
    {
      return this.zettaUtilsInstance. getProjectEntityClassById(this.value);
    }
  }