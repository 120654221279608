import { Component, OnInit } from '@angular/core';
import { ObservableService } from '../../common/services/observable.service';
import { ZettaUtils } from '../../common/shared/zettaUtils';

@Component({
	selector: 'zetta-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	isLeftSideBarActive = true;	
    is_super_admin=JSON.parse(sessionStorage.userInfo).is_super_admin;
	login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	user_entitlements;
	enableDQRule = JSON.parse(sessionStorage.userInfo).enable_dq_rule;  
	isTenantAdmin: Boolean = JSON.parse(sessionStorage.userInfo).is_admin;
	disableDataQuality: Boolean;

	constructor(public observeSvc: ObservableService,
				private zettaUtils: ZettaUtils) { 
					if (this.login_user) {
						try {
						  this.user_entitlements=this.login_user[0].entitlement;
						  if(this.login_user.length>1){
							this.user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
							if(this.user_entitlements.length){
							  this.user_entitlements=this.user_entitlements[0].entitlement;
							}else{
							  this.user_entitlements=this.login_user[0].entitlement;
							}
						  }
						} catch (error) {
						  console.log(error);
						}
				  
					  }
				}

	ngOnInit() {
		if (this.enableDQRule === false || this.isTenantAdmin === true) {
			this.disableDataQuality = true
		}
		this.zettaUtils.getEntitlements('zs');
		// this.zettaUtils.getShortName();
		this.zettaUtils.changeFavicon('../../assets/images/common/favicon.png', 'FlureeSense');
	}

	toggleLeftSideBar(menuIndex: number) {
		const currentSessionIndex = parseInt(sessionStorage.getItem('menuIndex'), 10) || 1;
		if (currentSessionIndex && currentSessionIndex === menuIndex) {
			this.isLeftSideBarActive = !this.isLeftSideBarActive;
			this.observeSvc.refreshDataGrid = this.isLeftSideBarActive;
		}
		sessionStorage.setItem('menuIndex', menuIndex.toString());
	}

	applyClass() {
		return this.isLeftSideBarActive ? 'fa-angle-right' : 'fa-angle-left';
	}

	checkForSystemAdmin() {
		if (this.is_super_admin || this.isTenantAdmin) {
			return false;
		} else {
			return true;
		}
	}

	getSidebarLeftMenuVisible(sidebarName){		
		if(this.user_entitlements){
			const entitlements = this.user_entitlements.menus.find(menus =>	menus.name === 'left_panel').childs.find(children => children.name === sidebarName);
			if (entitlements) {				
				return entitlements.visible;
			}
		}
	}
	getSidebarEntitlements(sidebarName) {		
		if(this.user_entitlements){
			const entitlements = this.user_entitlements.menus.find(menus =>	menus.name === 'left_panel').childs.find(children => children.name === sidebarName);
			if (entitlements) {				
				return entitlements.enable;
			}
		}
	
		// if (entitlements) {
		// 	if(sidebarUserInfo.is_super_admin && entitlements.name !== 'admin' ){
		// 		return entitlements.disable;
		// 	}else{
		// 		return entitlements.enable;
		// 	}
		// }

		

	}

}
