import { Injectable } from '@angular/core';
import { BaseService } from '../../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { dropFormatter } from '../../../../common/shared/formatters/dropFormatter';
import { mappedAttributeFormatter } from '../../../../common/shared/formatters/mappedAttributeFormatter'
import { unMappedAttributeFormattter } from '../../../../common/shared/formatters/unMappedAttributeFormatter';
import { attributeMappingFormatter } from '../../../../common/shared/formatters/attributeMappingFormatter';
import { dragFormatterLink } from 'src/app/common/shared/formatters/dragFormatterLink';

@Injectable({
    providedIn: 'root'
})

export class MapDataSetsService {

    constructor() { }

    getLeftGridColumns(totalMapping?: number) {
        return [
            {
                'displayname': 'Data Set',
                'physicalname': 'dataset_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': dragFormatterLink,
                'minWidth':120,
            },
            {
                'displayname': 'Column Name',
                'physicalname': 'column',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': unMappedAttributeFormattter,
            }, 
            {
                'displayname': `Mapping(${totalMapping})`,
                'physicalname': 'mapping',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': attributeMappingFormatter,
            }
        ];
    }

    getRightGridColumns() {
        return [
            {
                'displayname': 'Attribute',
                'physicalname': 'logical_name',
                'sortable': false,
                'datatype': 'String',
                'filterable': true,
                'formatter': mappedAttributeFormatter,
            }, 
            {
                'displayname': 'Mapping',
                'physicalname': 'target_attribute',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': dropFormatter
            }
        ];
    }

    getModalGridColumns() {
        return [
            {
                'displayname': 'Attribute ID',
                'physicalname': 'attribute_id',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
            },
            {
                'displayname': 'Attribute',
                'physicalname': 'logical_name',
                'sortable': false,
                'datatype': 'String',
                'filterable': true, 
                'minWidth':150,
            }, 
            {
                'displayname': 'Definition',
                'physicalname': 'description',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'minWidth':450,
            }
        ];
    }

}
