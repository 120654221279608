import { DatasourceService } from '../../datasources/datasource.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from './../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from './../../../../common/shared/zettaUtils';
import { Router } from '@angular/router';
import { Datasource } from '../datasource.model';
import { groupsFormatter } from 'src/app/common/shared/formatters/groupsFormatter';
import { userFormatter } from '../../../shared/formatters/userFormatter';
import { viewUserFormatter } from '../../../shared/formatters/viewUserFormatter';
import { exit } from 'process';
import { AppGlobals } from '../../../shared/app.globals';
import { MessageService } from '../../message/message.service';
declare var $: any;

@Component({
  selector: 'zetta-set-user-entitlements',
  templateUrl: './set-user-entitlements.component.html',
  styleUrls: ['./set-user-entitlements.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetUserEntitlementsComponent implements OnInit, OnDestroy {

  isLeftDataReady = false;
  isCenterDataReady = false;
  isRightDataReady = false;
  leftGrid: AngularGridInstance;
  centerGrid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  leftGridDataSource: any;
  centerGridDataSource: any = [];
  rightGridDataSource: any = [];
  groupsSelected: any = [];
  tableSelectedRows: any = [];
  leftGridColumnsDef: any;
  centerGridColumnsDef: any;
  rightGridColumnsDef: any;
  leftGridRows = 0;
  centerGridRows = 0;
  rightGridRows = 0;
  progressState = new ProjectStatus();
  leftGridOptions: any;
  centerGridOptions: any;
  rightGridOptions: any;
  showBreadcrumb = false;
  datasource: any = {};
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  loggedInUserDetails: [];
  totalGroupsSelected = 0;
  userSelected = 0;
  tableGridSelectedItemsSize = 0;
  isShowRightGridDataSource = false;
  isShowCenterGridDataSource = false;
  selectedCenterRowIndex = -1;
  groupName: string;
  selectedUserLength: number;
  selectRightGridUsers = {};
  selectedRightGridRows = [];
  allTablesSaved = [];
  allGroupsSaved = [];
  allUsersSaved = [];
  savedGroupsUsers: any = [];
  isDataReady = false;
  isDatasourceAdmin=false;
  cloudProvider:number;
  totalDataTable = 0;
  contentLimit = 5000; // assigning 5000 page size as we do not have pagination left panel
  contentPageNo = 1;
  btnDarkOpacity = 1;
  btnLightOpacity = 0.3;
  btnDarkApply = false;
  btnLightApply = false;
  applyBtnDisable = false;
  used_groups_ids = [];
  used_users_ids = [];
  selectedDB: String;
  selectedSchema: string;
  isRDBMSType: Boolean = false;
  leftGridSelectedRows = [];
  centerGridSelectedRows = [];
  rightGridSelectedRows = [];

  constructor(
    public datasourceSvc: DatasourceService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private router: Router,
    private messageSvc:MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.datasource.datasource_id = this.activatedRoute.snapshot.queryParamMap.get('id');
    //this.testConnection();
    //this.getSavedGroupsUsers();
    //this.getDatasourceUsers();
    
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    this.progressState.states = this.zettaUtils.getStateList('create-datasource');
    this.progressState.currentStateIndex = 3;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Source';
    this.progressState.isEdit = (this.datasource.datasource_id !== undefined && this.datasource.datasource_id !== null) ? true : false;
    this.progressState.isEdit = sessionStorage.getItem('newDataSource') === 'true' ? false : this.progressState.isEdit; 

    console.log(this.progressState.isEdit)

    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };

    this.centerGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };
    this.initColDef();
    this.getDatasourceGroupUsersAndUsersData();
  }

  initColDef() {

    this.leftGridColumnsDef = [{
      'displayname': 'Data Table Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      // 'minWidth': 190,
    }, {
      'displayname': 'Groups',
      'physicalname': '',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': groupsFormatter,
      // 'minWidth': 100,
    }, {
      'displayname': 'Users',
      'physicalname': 'selectUsersCount',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': userFormatter,
      'minWidth': 75,
    }];

    this.centerGridColumnsDef = [{
      'displayname': 'Group Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }, {
      'displayname': 'Users',
      'physicalname': 'selectUsersCount',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': userFormatter,
      'minWidth': 70,
    }, {
      'displayname': '',
      'physicalname': '',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-right',
      'formatter': viewUserFormatter,
      'minWidth': 80,
    }];

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }];
  }

  getDefaultSettingRow() {
    return { 'id': Math.random(), 'user_name': 'New User Default Settings', 'isAdmin': false, 'isReadOnly': false, 'isReadWriteOnly': false };
  }

  getDatasourceGroupUsersAndUsersData() {
    let datasourceGroupUsersParams = { userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], sourceId: this.datasource.datasource_id };
    let datasourceUsersParams = {
      userId: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      typeId: this.datasource.datasource_id
    };
    let self = this;
    this.datasourceSvc.getDatasourceUsersAndGroupUsers(datasourceGroupUsersParams, datasourceUsersParams).subscribe((resp) => {
      self.savedGroupsUsers = resp.datasourceGroupUsersData;
      if(resp.datasourceUsersData['currentpage'] && resp.datasourceUsersData['currentpage'].length > 0){
        const datasourceUser = resp.datasourceUsersData['currentpage'].filter(user => user.user_id === self.loggedInUserDetails['user_id']);
        if(datasourceUser){
          self.isDatasourceAdmin=datasourceUser[0].is_admin;
        }
      }
      // moved connection method
      self.testConnection();
    }, (error) => {
      this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message: 'Something went wrong while fetching datasource users' , type: 'ERROR', hideboard: true });
    })
  }

  testConnection() { 
    this.datasourceSvc.getDatasource({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id }).subscribe(res => {
      this.datasource = res;
      this.datasource.lookup_id = this.datasource['datasource_type_id'];
      this.progressState.datasource = this.datasource;
      this.isDataReady = true;

      var payload = {};
      if(this.datasource.datasource_type === 'Cloud Object Storage') {
        if (this.datasource.datasource_sub_type_id === 327) {
          payload = {
            account: this.datasource.connection_param.account,
            access_key: this.datasource.connection_param.accesskey,
            container: this.datasource.connection_param.container,
            root_directory: this.datasource.connection_param.root_directory,
            secrete_access_key: '',
          }
        }
        if(this.datasource.datasource_sub_type_id === 328){
           payload = {
            account: this.datasource.connection_param.storage_account,
            access_key: this.datasource.connection_param.storage_key,
            container: this.datasource.connection_param.bucket_name,
            root_directory: this.datasource.connection_param.root_directory,
            secrete_access_key: this.datasource.datasource_sub_type_id === 328 ? this.datasource.connection_param.secrete_access_key : '',
          }
        }
                
        this.cloudProvider = this.datasource.datasource_sub_type_id;
      } else if(this.datasource.datasource_type === 'Hadoop') {
        payload = {
            account: this.datasource.connection_param.account,
            access_key: this.datasource.connection_param.accesskey,
            container: this.datasource.connection_param.container,
            root_directory: this.datasource.connection_param.root_directory
        }
      } else if(this.datasource['datasource_type'] === 'Relational Database') {
        this.selectedDB = this.datasource.connection_param.database;
        this.selectedSchema = this.datasource.connection_param.schema;
        this.isRDBMSType = true;
        payload = {
            server: this.datasource.connection_param.server,
            database: this.datasource.connection_param.database,
            user_name: this.datasource.connection_param.user_name,
            password: this.datasource.connection_param.password,
            port: this.datasource.connection_param.port,
            database_system: this.datasource.connection_param.database_system,
            connection_method: this.datasource.connection_param.connection_method,
            driver: this.datasource.connection_param.driver,
            schema: this.datasource.connection_param.schema
        }
      } else if(this.datasource['datasource_type'] === 'Snowflake Data Warehouse') {
        payload = {
          account: this.datasource.connection_param.account,
          username: this.datasource.connection_param.username,
          password: this.datasource.connection_param.password,
          database: this.datasource.connection_param.database,
          schema: this.datasource.connection_param.schema,
          database_system: this.datasource.connection_param.database_system,
          server: this.datasource.connection_param.server,
          warehouse: this.datasource.connection_param.warehouse                
        }
      }
        this.datasourceSvc.testAndValidate({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], sourceId: this.datasource.datasource_id, sourceType: this.datasource.datasource_type, cloudProvider:this.cloudProvider, dir: this.datasource.connection_string, payload: payload }).subscribe(responseList => {      
          this.leftGridDataSource = responseList;
          //LeftGrid
          this.getLeftGridDataSource();

          this.isLeftDataReady = true;
          this.totalDataTable = this.leftGridDataSource.length;
          this.datasourceSvc.getGroups({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe((res) => {
            this.centerGridDataSource = res['currentpage'];


            //CenterGrid
            this.isCenterDataReady = true;
            this.centerGridDataSource.forEach((group, index) => {
              group.id = Math.random();
              group.selectGroupsCount = 0;
              group.defaultSelectedUsersLength = 0;
              group.selectUsersCount = 0;
              group.selectUsers = [];
              group.selectedRightGridRows = [];
            });
            
            //RightGrid
            if (this.isDatasourceAdmin) {
              const default_user = this.rightGridDataSource.filter(user => user.user_name.toLowerCase() === AppGlobals.DEFAULT_USER_SETTING_TEXT.toLowerCase())
              if (!default_user.length) {
                const firstRow = this.getDefaultSettingRow();
                this.rightGridDataSource.splice(0, 0, firstRow);
              }
            }

          });
        
      }, err => {
        this.isLeftDataReady = true;
       });
      
    }, err => { 
      this.isLeftDataReady = true;
    });
  }

  getLeftGridDataSource(){
    //LeftGrid
    this.leftGridDataSource.forEach((element, index) => {
      element.id = Math.random();
      element.selectGroupsCount = 0;
      element.selectUsersCount = 0;
      element.selectUsersGroups = [];
      this.savedGroupsUsers.forEach(tableFile => {
        if (element.name === tableFile.name) {
          element.selectGroupsCount = tableFile.groups.length;
          tableFile.groups.forEach(group => {
            element.selectUsersCount = element.selectUsersCount + group.users.length;
            //Init selectUsersGroups variable for each row (leftGrid)
            let users = [];
            group.users.forEach(user => {
              users.push({ user_id: user.user_id, is_admin: true })
            });
            element.selectUsersGroups.push({ group_id: group.group_id, users: users });
          });
        }
      });
    });
    this.reRenderSlickGrid(this.leftGrid);
  }

  // getSavedGroupsUsers() {    
  //   this.datasourceSvc.getDatasourceGroupsUsers({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], sourceId: this.datasource.datasource_id }).subscribe((data) => {      
  //     this.savedGroupsUsers = data;
  //   });
  // }

  getDatasourceUsers() {
    const param: any = {
      userId: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      typeId: this.datasource.datasource_id
    };
    this.datasourceSvc.getUsersToDatasource(param).subscribe(resp => {      
      if(resp['currentpage'].length > 0){
        const datasourceUser = resp['currentpage'].filter(user => user.user_id === this.loggedInUserDetails['user_id']);
        if(datasourceUser){
          this.isDatasourceAdmin=datasourceUser[0].is_admin;
        }
      }      
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }
  
  gridSortClicked(grid) {
    if(this.leftGridSelectedRows && this.leftGridSelectedRows.length>0) {
        this.leftGrid.slickGrid.setSelectedRows(this.leftGridSelectedRows);
      }
  }

  centerGridSortClicked() {
    if(this.centerGridSelectedRows.length>0) {
      this.centerGrid.slickGrid.setSelectedRows(this.centerGridSelectedRows);
    }
  }

  rightGridSortClicked() {
    if(this.rightGridSelectedRows && this.rightGridSelectedRows.length>0) {
      this.rightGrid.slickGrid.setSelectedRows(this.rightGridSelectedRows);
    }
  }

  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.btnDarkApply = false; 
      parent.btnLightApply = false;
      parent.tableGridSelectedItemsSize = args.rows.length;
      parent.tableSelectedRows = args.rows;

      parent.leftGrid.slickGrid.setSelectedRows(parent.tableSelectedRows);
      if(parent.tableSelectedRows.length > 0){
        parent.leftGridSelectedRows = parent.tableSelectedRows;
        parent.isShowCenterGridDataSource = true;
        let total_Groups_Ids = [];
        let total_User_Ids = [];
        parent.tableSelectedRows.forEach(ele => {
            parent.leftGridDataSource[ele].selectUsersGroups.forEach(group => {
              total_Groups_Ids.push(group.group_id);
              group.users.forEach(user => {
                total_User_Ids.push(user.user_id);
              });
            });
        });

        parent.used_groups_ids = total_Groups_Ids.filter((item, index) => {
          return total_Groups_Ids.indexOf(item) === index;
        });
        parent.used_users_ids = total_User_Ids.filter((item, index) => {
          return total_User_Ids.indexOf(item) === index;
        });

        parent.reRenderSlickGrid(parent.leftGrid);
        parent.groupTableEnableDisableCheckboxes();
        parent.reRenderSlickGrid(parent.centerGrid);
      } else if(args.rows.length<1) {
        parent.leftGrid.slickGrid.setSelectedRows([]);
        parent.tableSelectedRows = [];
      } else {
        parent.isShowCenterGridDataSource = false;
        parent.groupTableEnableDisableCheckboxes();
      }
      $(".tables-table #filter-checkbox-selectall-container input").click(function(e) {
        if(!$(this).is(':checked')) {
          parent.tableSelectedRows = [];
          parent.leftGridSelectedRows = [];
         } else {
         }
       });
    });

    parent.leftGrid.slickGrid.onSort.subscribe((e, args) => {
      setTimeout(() => {
        parent.leftGridDataSource = parent.leftGrid.dataView.getItems();
        this.getLeftGridDataSource();
      }, 500);
    });

  }

  centerGridCreated(grid) {
    const parent = this;
    parent.centerGrid = grid;
    parent.centerGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.btnDarkApply = false; 
      parent.btnLightApply = false;
      parent.totalGroupsSelected = args.rows.length;  
      parent.groupsSelected = args.rows;  
      parent.centerGrid.slickGrid.setSelectedRows(parent.groupsSelected);
      if (parent.groupsSelected.length > 0) {
        parent.centerGridSelectedRows = parent.groupsSelected;
      }
      parent.updateCenterGridProperties();
      const elem = $(".groups-table #filter-checkbox-selectall-container");
      setTimeout(() => {
        let elem = $('.groups-table #filter-checkbox-selectall-container input');
        if(elem) {
          elem.click(function(e) {
          parent.centerGridSelectedRows = [];
          parent.groupsSelected = [];
          });
        }
      }, 1000);
    });
  }

  rightGridCreated(grid) {
    const parent = this;
    parent.rightGrid = grid;
    parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.btnDarkApply = false; 
      parent.btnLightApply = false;
      const centerGridRow = parent.centerGridDataSource[parent.selectedCenterRowIndex];
      centerGridRow.defaultSelectedUsersLength = args.rows.length;
      parent.selectedUserLength = args.rows.length;
      parent.selectRightGridUsers[centerGridRow.group_id] = [];
      centerGridRow.selectUsers = [];
      parent.used_users_ids = [];
      if(parent.rightGrid) {
        parent.rightGrid.slickGrid.setSelectedRows(args.rows);
      }
      if (parent.selectedUserLength > 0) {
         parent.rightGridSelectedRows = args.rows;
      }
      args.rows.forEach((item) => {
        parent.selectRightGridUsers[centerGridRow.group_id].push(parent.rightGrid.gridService.getDataItemByRowIndex(item));
        centerGridRow.selectUsers.push(parent.rightGrid.gridService.getDataItemByRowIndex(item).user_id);
        parent.used_users_ids.push(parent.rightGrid.gridService.getDataItemByRowIndex(item).user_id)
      });
      centerGridRow.selectUsersCount = centerGridRow.selectUsers.length;
      parent.selectedRightGridRows = args.rows;
      centerGridRow['selectedRightGridRows'] = args.rows;
      parent.updateCenterGridProperties();
      parent.reRenderSlickGrid(parent.leftGrid);
      parent.reRenderSlickGrid(parent.centerGrid);

      setTimeout(() => {
        let elem = $('.right-users-grid #filter-checkbox-selectall-container input');
        if(elem) {
          elem.click(function(e) {
            parent.rightGridSelectedRows = [];
            parent.selectedUserLength = 0;
          });
        }
      }, 1000);
    });
  }

  updateCenterGridProperties() {
    const parent = this;
    parent.userSelected = 0;
    // we need to loop Center Grid to update userCount
    var groupUsers: any = [];
    for (let centerGridIndex = 0; centerGridIndex < parent.centerGridDataSource.length; centerGridIndex++) {
      const centerGridRow = parent.centerGridDataSource[centerGridIndex];
      centerGridRow['selectedRightGridRows'] = [];
      for (let selectedRowsIndex = 0; selectedRowsIndex < parent.groupsSelected.length; selectedRowsIndex++) {
        if (centerGridIndex === parent.groupsSelected[selectedRowsIndex]) {     // si es un grupo seleccionado
          centerGridRow.selectGroupsCount = parent.totalGroupsSelected;
          var users = [];
          centerGridRow.users.forEach((ele, index) => {
            if(parent.used_users_ids.includes(ele.user_id)) {
              centerGridRow['selectedRightGridRows'].push(index);
              users.push({ user_id: ele.user_id, user_name:ele.user_name, is_admin: true });
            }
          }); 
          groupUsers.push({group_id: centerGridRow.group_id, users: users})
          parent.userSelected += centerGridRow.selectUsersCount; 
          break;
        }
      }
    }
   
    parent.updateUserAndGroupCounts(groupUsers);
    parent.reRenderSlickGrid(parent.leftGrid);
    parent.reRenderSlickGrid(parent.centerGrid);
  }

  updateUserAndGroupCounts(groupUsers) {
    const parent = this;
    parent.leftGridDataSource = parent.leftGrid.dataView.getItems();
    for (let leftGridIndex = 0; leftGridIndex < parent.leftGridDataSource.length; leftGridIndex++) {
        const leftGridRow = parent.leftGridDataSource[leftGridIndex];
        for (let selectedRowsIndex = 0; selectedRowsIndex < parent.tableSelectedRows.length; selectedRowsIndex++) {
          if (leftGridIndex === parent.tableSelectedRows[selectedRowsIndex]) {
            leftGridRow.selectUsersCount = parent.userSelected;
            leftGridRow.selectGroupsCount = parent.totalGroupsSelected;
            leftGridRow.selectUsersGroups = groupUsers;
            break;
          }  
        } 
    }
  }

  onCenterCellClick(e) {  
    const args = e.args;
    const eventEle = e.eventData;
    if (eventEle.target.title === 'View Users') {
      this.btnDarkApply = false; 
      this.btnLightApply = false;
      this.selectedCenterRowIndex = args.row;
      const row = this.centerGrid.gridService.getDataItemByRowIndex(args.row);
      this.isShowRightGridDataSource = false;
      this.rightGridDataSource = row.users;
      this.isRightDataReady = true;
      this.groupName = row.name;
      row['selectedRightGridRows'] = (row['selectedRightGridRows'] && row['selectedRightGridRows'].length > 0) ? row['selectedRightGridRows'] : [];
      this.rightGridDataSource.forEach(element => {
        element.id = Math.random();
      });
      this.isShowRightGridDataSource = true;
      setTimeout(() => {
        this.rightGrid.slickGrid.setData(this.rightGridDataSource);
        this.rightGrid.slickGrid.invalidate();
        this.rightGrid.slickGrid.render();
        this.rightGrid.slickGrid.setSelectedRows(row['selectedRightGridRows']);
      }, 700);
    }
  }

  enableAllCheckbox(centerGridRow) {
    const parent = this;
    parent.isRightDataReady = false;
    setTimeout(() => {
      const rows = [];
      if (centerGridRow && centerGridRow['selectedRightGridRows'] && centerGridRow['selectedRightGridRows'].length > 0) {
        for (let rightGridIndex = 0; rightGridIndex < parent.rightGridDataSource.length; rightGridIndex++) {
          for (let selectedRowsIndex = 0; selectedRowsIndex < centerGridRow['selectedRightGridRows'].length; selectedRowsIndex++) {
            if (rightGridIndex === centerGridRow['selectedRightGridRows'][selectedRowsIndex]) {
              rows.push(rightGridIndex);
              break;
            }
          }
        }
      } else {
        for (let i = 0; i < parent.rightGrid.dataView.getItems().length; i++) {
          rows.push(i);
        }
      }
      parent.rightGrid.slickGrid.setSelectedRows(rows);
      parent.isRightDataReady = true;
    }, 800);
  }

  groupTableEnableDisableCheckboxes() {
    // disabling the check boxes
    let countGroups = 0;
    this.tableSelectedRows.forEach((element, index) => {
      countGroups = countGroups + this.leftGridDataSource[element].selectGroupsCount;
    });
    if (this.tableGridSelectedItemsSize === 0) {
      this.isShowCenterGridDataSource = false;
      this.isShowRightGridDataSource = false;
      this.centerGridDataSource.forEach(group => {
        group.selectGroupsCount = -1;
        group.selectUsersCount = 0;
        group.selectUsers = [];
        group.selectedRightGridRows = [];
      });

      $('.groups-table input[type="checkbox"]').attr('disabled', 'disabled');
      $('.groups-table .slick-row').addClass('disableClick');
    } else {
      let groupsSelectedIndexRows = [];
      if(!countGroups){
        this.centerGridDataSource.forEach((group, index) => {
          group.id = Math.random();
          group.selectGroupsCount = 0;
          group.defaultSelectedUsersLength = 0;
          group.selectUsersCount = 0;
          group.selectUsers = [];
          group.selectedRightGridRows = [];
        });
      } else {
        this.centerGridDataSource.forEach((group, index) => {
          if(this.used_groups_ids.includes(group.group_id)){
            groupsSelectedIndexRows.push(index);
            group.selectUsersCount = 0;
            group.selectUsers = [];
            group.users.forEach((user, index) => {
              if(this.used_users_ids.includes(user.user_id)){
                group.selectUsersCount++;
                group.selectUsers.push(user.user_id);
                group.selectedRightGridRows.push(index);
              }
            });
          }
        });
      }
      setTimeout(() => {
        this.centerGrid.slickGrid.setSelectedRows(groupsSelectedIndexRows);
      }, 700);
      $('.groups-table input[type="checkbox"]').removeAttr('disabled');
      $('.groups-table .slick-row').removeClass('disableClick');
    }
  }

  onPrevious() {
    const parent = this;
    let payload: any = []; 
    parent.leftGridDataSource.forEach(row => {
        if(row.selectUsersGroups.length > 0){
          var datasource_content_group = [];
          row.selectUsersGroups.forEach(element => {
              var users = [];
              element.users.forEach(user => {
                users.push({ user_id: user, is_admin: true })
              });
              datasource_content_group.push({
                "group_id": element.group_id,
                "datasource_content_group_users": [
                    {
                        "users": users
                    }
                ]
              });
          });
          payload.push({
              "name": row.name,
              "description": row.name,
              "datasource_content_group": datasource_content_group
          });
        }           
    });

    const params = {
            user_id: parent.loggedInUserDetails['user_id'],
            tenant_id: parent.loggedInUserDetails['tenant_id'],
            datasource_id: parent.datasource.datasource_id,
            payload: payload
    };
    sessionStorage.removeItem('usersDataprev');
    sessionStorage.setItem('usersDataprev', JSON.stringify(payload))
    this.router.navigate(['/zs/datasources', this.datasource.datasource_id, 'add-admin-users'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
  }

  reRenderSlickGrid(gridInstance) {
    if(gridInstance) {
      gridInstance.slickGrid.updateRowCount();
      gridInstance.slickGrid.render();
      gridInstance.slickGrid.invalidate();
    }
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }

  saveDatasource() { 
    const parent = this;
    let payload: any = []; 
    let default_user=[];  
    parent.leftGridDataSource = parent.leftGrid.dataView.getItems();  
    parent.leftGridDataSource.forEach(row => {      
      if (row.selectUsersGroups.length > 0 && row.selectGroupsCount > 0 && row.selectUsersCount > 0) {
        var datasource_content_group = [];
        row.selectUsersGroups.forEach(element => {
          var users = [];
           element.users.forEach(user => {
           
              if(user.user_id){
                users.push({ user_id: user.user_id, is_admin: true })
            }  
                  
          });
          
          if(element && element.users && element.users.length > 0 && element.users[0].user_name){
            default_user= element.users.filter(user => user.user_name.toLowerCase()=== AppGlobals.DEFAULT_USER_SETTING_TEXT.toLowerCase());
         }

          datasource_content_group.push({
            "group_id": element.group_id,
            "default_access_level": { 'is_admin': default_user.length ? true : false },
            "datasource_content_group_users": [
              {
                "users": users
              }
            ]
          });
        });
        payload.push({
          "name": row.name,
          "description": row.name,
          "datasource_content_group": datasource_content_group
        });
      }
    });

    const params = {
        user_id: parent.loggedInUserDetails['user_id'],
        tenant_id: parent.loggedInUserDetails['tenant_id'],
        datasource_id: parent.datasource.datasource_id,
        payload: payload
    };

    if(parent.showBreadcrumb) {
      this.btnDarkApply = true;
      this.btnDarkOpacity = 1;
    }
      parent.datasourceSvc.saveDatasourceEntitlements(params).subscribe((response) => {
      parent.selectRightGridUsers = {};
      if(parent.showBreadcrumb) {
        setTimeout(() => { this.btnDarkOpacity = 0 }, 500);
        setTimeout(() => { this.btnDarkApply = false; this.btnLightApply = true }, 700);
        setTimeout(() => { this.btnLightOpacity = 1 }, 700);
      } else {
        if(this.progressState.isEdit) {
              parent.router.navigate(['/zs/datasources', parent.datasource.datasource_id]);
        } else {
              parent.router.navigate(['/zs/datasources']);
        }
      }
      sessionStorage.removeItem('newDataSource');
    }, err => { });
  }

  rightGridCount() {
    if (this.rightGrid) {
        return this.rightGrid.dataView.getItems().length;
    } else {
        return this.rightGridDataSource.length;
    }
  }
}