import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Formatter,Column,AngularGridInstance } from "angular-slickgrid";
import { MessageService } from "src/app/common/components/message/message.service";
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { dateFormatter } from "src/app/common/zm-shared/zm-formatters/dateFormatter";
import { environment } from "src/environments/environment";
import { Project } from "../../project.model";
import { ProjectService } from "../../project.service";

// AgGrid code 
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
//import { DateFormatterFormatterComponent } from "src/app/common/shared/cell-renderer/date-formatter/date-formatter.component";
import { GRHistoryDateFormatterComponent } from "src/app/common/shared/cell-renderer/date-formatter/gr-history-date-formatter.component";

 
@Component({
    selector:'zetta-golden-record-history',
    templateUrl:'./golden-record-history.component.html',
    styleUrls:['./golden-record-history.component.scss']
})
export class GoldenRecordHistoryComponent implements OnInit{

    public grid: AngularGridInstance;
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
    goldenHistoryTableColDef = [];
    public goldenRecordsHistory: object = {};
    public goldenRecordsHistory2: any=[];
    projectDetail: Project = new Project();
    hasScrolled = false;
    isLoading = true;
    total = 0;
    page = 1;
    limit = 100;
    noData = false;
    goldenRecordKey = '';
    gridOptions: any;
    cluster_id;
    isFiltering: boolean = false;
    isSorting: boolean = false;

    // Ag Grid Code
    gridApi:any; 
    columnDefs: ColDef[] = []; 
    fsGridOptions: GridOptions;

    constructor(
        private projectService: ProjectService,       
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private activatedRoute:ActivatedRoute
        ) { }

    ngOnInit(){
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.activatedRoute.queryParamMap.subscribe((param)=>{
            this.cluster_id=param.get('record_id');
            this.getGoldenRecordsHistory(this.page, this.limit,this.cluster_id);          
           });
          
          this.goldenHistoryTableColDef = [
            {
              headerName: 'Activity Timestamp',
              field: 'activity_timestamp',
              sortable: true, 
              floatingFilter: true,
              cellRendererFramework: GRHistoryDateFormatterComponent,
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField :'activity_timestamp'
            },
            { 
              headerName: 'Activity',
              field: 'activity',
              sortable: true, 
              floatingFilter: true, 
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField : 'activity'
            },
            { 
              headerName: 'Attribute',
              field: 'attribute',
              sortable: true, 
              floatingFilter: true, 
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField : 'attribute'
            },
            { 
              headerName: 'Orignal Value',
              field: 'original_value',
              sortable: true, 
              floatingFilter: true, 
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField :'original_value'
            },
            { 
              headerName: 'Modified Value',
              field: 'changed_value',
              sortable: true, 
              floatingFilter: true, 
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField : 'changed_value'
            },
            { 
              headerName: 'User',
              field: 'user_name',
              sortable: true, 
              floatingFilter: true, 
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true
              },
              tooltipField :'user_name'
            }
          ];
      
        // this.gridOptions = {
        //     enableGridMenu: false,
        //     selectable: true,
        //     CheckboxSelector: false,
        //     enableCellNavigation: true,
        //     enableRowSelection: true,
        //     enableFiltering: true,
        //     allowServerSorting: true,
        //     rowHeight: 34
        //   };
          this.fsGridOptions = {  
            enableCellTextSelection: true,
            headerHeight: 45, 
            rowHeight: 30,
            floatingFiltersHeight: 49,
            getRowStyle: params => {
              if (params.node.rowIndex % 2 === 0) {
                return { background: AppGlobals.EVEN_ROW_BG_COLOR };
              }
              return { background: AppGlobals.ODD_ROW_BG_COLOR };
            },
          };
    }

  getGoldenRecordsHistory(pageNo, limit = 1, zsClusterId) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered/winners/${zsClusterId}/history?pageno=${1}&pagesize=${limit}`;
    let serverfilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) {
        pageNo = 1;
      }
      this.isFiltering = false;
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
      this.isSorting = true;
    } else {
      if (this.isSorting) {
        pageNo = 1;
      }
      this.isSorting = false;
    }
    this.projectService.getGoldenRecordsHistory(this.loggedInUserDetails.user_id, this.projectDetail.project_id, zsClusterId, pageNo, limit, serverfilter, sortfilter).subscribe((data) => {
      this.goldenRecordsHistory2 = data["currentpage"];
      if (this.goldenRecordsHistory2.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = data['totalrecords'];
      let concatedRows = this.goldenRecordsHistory2;
      this.isLoading = false;
      this.total = data['totalrecords'];
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.goldenRecordsHistory2];
        this.goldenRecordsHistory2 = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        if (pageNo < data['totalpages']) {
          this.hasScrolled = false;
        }
      }
    }, err => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }
  

  onGridCreation(grid) {
    this.grid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getGoldenRecordsHistory(this.page, this.limit,this.cluster_id);
  }

  onNext(): void {
    this.page++;
    this.getGoldenRecordsHistory(this.page, this.limit,this.cluster_id);
  }

  onPrev(): void {
    this.page--;
    this.getGoldenRecordsHistory(this.page, this.limit,this.cluster_id);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
}