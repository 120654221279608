export class Entity {

    constructor() { }

    entity_id: number;
    name: string;
    entity_sub_type_id: number;
    entity_type_name: string;
    description: string;
    entity_type_id: number;
    cluster_identifier_attribute_id: number;
    created_by: number;
    attributes: any[];
    entity_icon: string;
    icon_id: number;
}

export class Attribute {

    constructor() { }

    attribute_id: number;
    logical_name: string;
    physical_name: string;
    sort_order: number;
    data_type: string;
    scrubber_name: string;
    matcher_type_name: string;
    is_visible_input: boolean;
    is_visible_output: boolean;
    description: string;
    attribute_data_type_id: number;
    attribute_scrubber_type_id: number;
    matcher_type_id: number;
    is_system: boolean;
}