import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ProjectService } from '../../project.service';
import { ObservableService } from '../../../../../common/services/observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable, Subject } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ContentService } from '../../../content.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';

@Component({
	selector: 'zetta-pd-entity-resolved',
	templateUrl: './pd-entity-resolved.component.html',
	styleUrls: ['./pd-entity-resolved.component.scss']
})

export class PdEntityResolvedComponent implements OnInit, OnDestroy {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	object_type_id:number;
	thresholdValue: number;
	showMatcherProgress: boolean = false;
	currentSteps: object = {
		steps_completed : 0
	};
	isExecutionErrored: boolean = false;
	onDestroy$: Subject<void> = new Subject();
	public entitiesChartOptions: object = {
		'resizeRequired': true,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'labelPercentValue': 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence'
	};

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];
	public lineChartOptions: object = {
		'isMoneyFormat': false,
		'height': 133,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#00cb00',
		'circleColor': '#ff0000'
	};

	public lineChartDS = null;
	public notApplicable = false;
	public refreshRequired = false;
	public const_caption: string = "Executing models, this may take a few minutes";
	public caption: string = "Executing models, this may take a few minutes";
	public matcherValue;
	matcherAccurayThreshold: number;
	projId: Number;
	@Input() projDetails?= {};
	@Output() updatMatcherValues = new EventEmitter<any>();
	loggedInUserDetails;
	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZettaUtils,
		private _projectService: ProjectService,
		public observeSvc: ObservableService,
		private activeRoute: ActivatedRoute,
		private router: Router,
		private contentService: ContentService,
		private clContentService: ZsClContentService,
		private messageSvc: MessageService) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
		this.refreshRequired = sessionStorage.getItem('isPageReload') ? JSON.parse(sessionStorage.getItem('isPageReload')) : false;
	}

	ngOnInit() {
		const parent = this;
		this.matcherAccurayThreshold = AppGlobals.RESOLVE_MATCHER_ACCURACY_THRESHOLD;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;		
		this.loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
		this.object_type_id = +sessionStorage.getItem('project_type_id');
		if (!this.refreshRequired) {
			parent.getEntityResolvedData(parent.userinfo.user_id, routeParams.id);
		} else {
			parent.getEntityResolvedData(parent.userinfo.user_id, routeParams.id);
			sessionStorage.setItem('isPageReload', 'false');
		}

		parent._projectService.getModelSummaryLatest(parent.userinfo.user_id, routeParams.id, 'matcher').subscribe( data => {
				parent.statisticsObj = {
					'model_runs': 0,
					'model_runs_as_of': new Date(),
					'unique_institutions': 0,
					'compression_ratio': 0
				};
				for (let index = 0; index < data.length; index++) {
					const obj = data[index];
					if (obj.name === 'run_number') {
						parent.statisticsObj['model_runs'] = obj['value'] || 0;
					} else if (obj.name === 'as_of') {
						parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
					} else if (obj.name === 'total_entities') {
						parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
					} else if (obj.name === 'compression_ratio') {
						parent.statisticsObj['compression_ratio'] = obj['count'] || 0;
					} else if (obj.name === 'overall_confidence') {
						parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
						parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
						parent.matcherValue = obj['value'] || 0;
					}
				}
				parent.entitiesDataSource = parent.getDonutParseData(data);
				parent.updatMatcherValues.emit({matcherValue: Number(parent.matcherValue)});
				parent.clContentService.matcherConfidenceValue.next(parent.matcherValue ? Number(parent.matcherValue) : 0);
			},
			error => {
				parent.entitiesDataSource = [];
				parent.statisticsObj = { 'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 0 };
			}
		);

		parent._projectService.getModelSummary(parent.userinfo.user_id, routeParams.id, 'matcher').subscribe(
			data => {
				parent.lineChartDS = parent.prepareLineChartData(data);
			},
			error => {
				parent.lineChartDS = [];
			}
		);

		parent.observeSvc.getEntityName.subscribe(data => {
			this.selectedProjectName = data;
		});

		parent.contentService.getSystemSettings().subscribe(res => {
			if(res) {
				let filtered = res.filter(element => element.name == 'resolve_project_accuracy_threshold');
				if(filtered && filtered.length>0) {
				parent.thresholdValue = Number(filtered[0]['value']);
				parent.clContentService.thresholdValue.next(Number(filtered[0]['value']));
			}
			}
		  });
		parent.projId = routeParams.id;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.projDetails && changes.projDetails.currentValue) {
			this.projDetails = changes.projDetails.currentValue;
		}
	}

	shouldDisableTrainModelButton() {
		if (this.zettaUtils.isProcessRunning || this.notApplicable) {
			return true;
		}
		if (this.isProjectUser() && this.entitiesChartOptions && this.entitiesChartOptions['labelPercentValue'] < this.matcherAccurayThreshold) {
			return false;
		}
		return true;	
	}

	shouldDisableMatchFixingTasks() {
		if (this.zettaUtils.isProcessRunning || this.notApplicable) {
			return true;
		}
		
		if (this.isProjectUser() && this.entitiesChartOptions && this.entitiesChartOptions['labelPercentValue'] >= this.matcherAccurayThreshold) {
			return false;
		} 
		return true;
	}

	isProjectUser() {
		let loggedInUserId = this.loggedInUserDetails && this.loggedInUserDetails.user_id ? this.loggedInUserDetails.user_id: null;
		if (loggedInUserId && Object.keys(this.projDetails).length) {
			let projectUsers = this.projDetails['users'];
			if (projectUsers && projectUsers.length) {
				let isProjectUser = projectUsers.find((user) => {
					return user.user_id === loggedInUserId
				})
				if (isProjectUser) {
					return true;
				}
			}
		}
		return false;
	}

	getDonutParseData(data) {
		const dataSet = [];
		data.forEach((element) => {
			if (element['name'] === 'high_confidence') {
				dataSet.push({ 'Current Model': 'High', 'Confidence': element['records'] });
			} else if (element['name'] === 'medium_confidence') {
				dataSet.push({ 'Current Model': 'Medium', 'Confidence': element['records'] });
			} else if (element['name'] === 'low_confidence') {
				dataSet.push({ 'Current Model': 'Low', 'Confidence': element['records'] });
			}
		});
		return dataSet;
	}
	
	getEntityResolvedData(userId, projectId) {
		const parent = this;
		parent.zettaUtils.isProcessRunning = true;
		// parent.observableRef = Observable.timer(0, AppGlobals.ENTITIES_RESOLVED_JOB_INTERVEL)
		parent.observableRef = Observable.timer(0, 20000)
			.takeWhile(() => parent.alive)
			.subscribe(() => {
				// Cancel subscription in case URL changes
				if (parent.currentURL !== parent.router.url) {
					parent.alive = false;
					parent.observableRef.unsubscribe();
					return;
				}
				if(this.object_type_id){
					parent._projectService.getCurrentActiveJobs(userId, projectId,this.object_type_id).subscribe(response => {
						this.zettaUtils.isFileNotFound=false;
						if (Object.values(response).length === 0) {
							parent.zettaUtils.isProcessRunning = false;
							parent.notApplicable = true;
							parent.entitiesDataSource = [];
							return;
						}
						this.currentSteps = response;
						response['steps_completed'] = response['steps_completed'] || 0;
						response['total_steps'] = response['total_steps'] || 0;
						parent.currentJobStatusObj = response;
						if (response['caption'] === null || response['caption'] === '') { 
							response['caption'] === this.const_caption; 
						} 
						if(response && response['stage_no'] <= AppGlobals.RESOLVE_MATCHER_STAGE_NUMBER) {
							parent.showMatcherProgress = true;
							if(response['is_errored']) {
								parent.isExecutionErrored = true;
								parent.showMatcherProgress = false;
								parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
								parent.errorDetails = response['error_message'];
								parent.clContentService.matcherConfidenceValue.next(0);
							} else if(response['job_completed']) {
								parent.showMatcherProgress = false;
							} else {
								parent.isExecutionErrored = false;
								parent.showMatcherProgress = true;
							}
						} else if(response && response['stage_no'] > AppGlobals.RESOLVE_MATCHER_STAGE_NUMBER) {
							parent.showMatcherProgress = false;
							parent.errorMessage = '';
							parent.errorDetails = '';
							parent.isExecutionErrored = false;
						} else if(response && response['stage_no'] > AppGlobals.RESOLVE_MATCHER_STAGE_NUMBER && !response['is_errored']) {
							parent.showMatcherProgress = false;
							parent.isExecutionErrored = false;
						}
						if (this.caption === this.const_caption) {
							this.caption = response['caption'];
						} else {
							this.caption = this.const_caption
						}
						if (response['job_completed']) {
							parent.zettaUtils.isProcessRunning = false;
							sessionStorage.setItem('isPageReload', 'true');
							parent.clContentService.matcherConfidenceValue.next(Number(this.matcherValue));
							// parent.refresh();
						} else if (response['is_errored'] && response['type'] !== 'merger') {
							parent.isStepsCompleted = true;
							parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
							parent.errorDetails = response['error_message'];
							parent.zettaUtils.isProcessRunning = false;
							parent.alive = false;
							parent.observableRef.unsubscribe();
						} else if (response['type'] !== 'merger' && !parent.currentJobStatusObj['job_completed']) {
							parent.isStepsCompleted = true;
						}
						parent.observeSvc.setTrackStatus = parent.currentJobStatusObj;
						parent.observeSvc.getTrackStatus.subscribe(data => parent.currentJobStatusObj = data);
						parent.currentJobStatusObj['algoid'] = AppGlobals.getRunModelAlgo(0);
				},
						error => {						
							if(error.status===404){
								this.zettaUtils.isFileNotFound =true;
							}
							
						});
				}
				
			});
	}

	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
		this.matcherValue = 0;
		this.messageSvc.clearMessage();
	}

	@HostListener('window:unload', ['$event'])
	unloadHandler(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	cancelJOB() {
			this._projectService.cancelActiveJob(this.userinfo.user_id, this.activeRoute.snapshot.params.id).subscribe(response => {
				this.getEntityResolvedData(this.userinfo.user_id, this.activeRoute.snapshot.params.id);
				// this.alive = false;
				// this.zettaUtils.isProcessRunning = false;
				// this.observableRef.unsubscribe();
			});
	}

	prepareLineChartData(data) {
		const dataSet = [];
		data.forEach((element) => {
			const d = new Date(element['created_ts']);
			dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
		});
		return dataSet;
	}

	refresh() {
		window.location.reload();
	}

	toViewResults() {
		if (this.projDetails) {
			this.router.navigate([`/zs/projects/${this.projId}/entities-resolved`], { queryParams: { entityId: this.projDetails['entity_id'] } });
		} else {
			this.router.navigate([`/zs/projects/${this.projId}/entities-resolved`]);
		}
	}
}
