import { Project } from '../../../../../zettasense/content/project/project.model';
import { ProjectService } from '../../../../../zettasense/content/project/project.service';
import { DatasetService } from '../../../../../common/components/datasets/dataset.service';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../../common/components/message/message.service';
import { ZmContentService } from '../../../zm-content.service';
import * as _ from 'lodash';


const viewFormulasFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<span class="blueLink pointer" title='View Formulas' >View Formulas</span> <i class='fas long-arrow-right viewUsersContent pointer' title='View Formulas'></i>`;
};
      
const RIGTH_EMPTY_GRID_MSG = 'loading...';
const LEFT_EMPTY_GRID_MSG = 'No Data Sets.';

@Component({
    selector: 'zetta-dataset-formulas',
    templateUrl: './dataset-formulas.component.html',
    styleUrls: ['./dataset-formulas.component.scss'],
    encapsulation: ViewEncapsulation.None
})
  
export class DatasetFormulasComponent implements OnInit, OnDestroy  {
  
      isDataReady = false;
      leftGrid: AngularGridInstance;
      rightGrid: AngularGridInstance;
      leftGridDataSource: any = [];
      rightGridDataSource: any = [];
      gridColumnsDef: any;
      rightGridColumnsDef: any;
      projectDetail: Project = new Project();
      leftGridRows = 0;
      rightGridRows = 0;
      progressState = new ProjectStatus();
  
      leftGridOptions: any;
      rightGridOptions: any;
      showBreadcrumb = false;
      hasActiveJob = true;
  
      tableSettings: object = { 'height': "100%", 'width': "100%" };
      rightTableSettings: object = { 'height': "100%", 'width': "99.42%" };
      loggedInUserDetails: any;
      selectedUserRoles: any = [];
      datasetId;
      selectedCenterRowIndex = -1;
      tableGridSelectedItemsSize = 0;
      usersSelectedRows: any = [];
      tableSelectedRows: any = [];
      groupSelected = 0;
      userSelected = 0;
      groupName: string;
      selectedUserLength: number;
      selectRightGridUsers  = {};
      isShowRightGridDataSource = false;
      isAdminSelectAll = false;
      isReadOnlySelectAll = false;
      isReadWriteOnlySelectAll = false;
      isAdminChecked = false;
      isReadWriteonlyChecked = false;
      isReadonlyChecked = false;
      isUserSelected = false;
  
      isAdminChk: any = { top: '0px', left: '0px' };
      isReadOnlyChk: any = { top: '0px', left: '0px' };
      isReadWriteOnlyChk: any = { top: '0px', left: '0px' };
  
      usersSavedIds: any = [];
      dataset_ids: any = [];

      isLoading = true;
      workbook_id;
      workbook: any;
      totalWorkbooks;
      isDataReadyRight = false;
      totalRightGridDataSource = 0;
      hasScrolled = false;
      noData = false;
      WorkBookDataSetId;
      page = 1;
      limit = 30;
  
      constructor(public projectSvc: ProjectService,
          public datasetSvc: DatasetService,
          private activatedRoute: ActivatedRoute,
          private router: Router,
          public zettaUtils: ZettaUtils,
          private messageSvc: MessageService,
          private workbookSvc: ZmContentService) { }
      
      ngOnInit() {
          this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
          this.activatedRoute.params.subscribe(params => {
            this.workbook_id = params['id'];
          });
  
          this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
          this.progressState.isEdit = sessionStorage.getItem('newDataSet') === 'true' ? false : this.progressState.isEdit;
          const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
          if (showBreadcrumb && showBreadcrumb === 'true') {
              this.showBreadcrumb = true;
          }
  
          this.showBreadcrumb = true;
  
          if (!this.showBreadcrumb) {
              // Remove BreadCrumb
              $('#breadcrumb').addClass('d-none');
          } 
  
          this.leftGridOptions = {
              enableGridMenu: false,
              enableAddRow: false,
              selectable: false,
              enableFiltering: true,
              CheckboxSelector: false,
              enableCellNavigation: true,
              multiSelectable: false,
              noDataMsg: LEFT_EMPTY_GRID_MSG,
          };
  
          this.rightGridOptions = {
              enableGridMenu: false,
              enableAddRow: false,
              selectable: false,
              enableFiltering: true,
              CheckboxSelector: false,
              enableCellNavigation: true,
              multiSelectable: false,
              noDataMsg: RIGTH_EMPTY_GRID_MSG,
          };
  
          this.gridColumnsDef = [{
              'displayname': 'Data Set',
              'physicalname': 'Name',
              'sortable': true,
              'datatype': 'String',
              'filterable': true
          }, {
              'displayname': 'Data Set Type',
              'physicalname': 'SheetName',
              'sortable': true,
              'datatype': 'String',
              'filterable': true,
            //   'minWidth': 80,
            //   'formatter': userFormatter
          }, {
              'displayname': 'Records',
              'physicalname': 'records',
              'sortable': true,
              'datatype': 'String',
              'filterable': false,
            //   'minWidth': 60,
              // 'formatter': userFormatter
          }, {
              'displayname': ' ',
              'physicalname': 'action',
              'sortable': false,
              'datatype': 'String',
              'filterable': false,
              'minWidth': 120,
            //   'maxWidth': 280,
              'formatter': viewFormulasFormatter
          }];
  
          this.rightGridColumnsDef = [{
                'displayname': 'ColIndex',
                'physicalname': 'ColumnIndex',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }, {
                'displayname': 'ColumnName',
                'physicalname': 'ColumnName',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }, {
                'displayname': 'ColumnDataType',
                'physicalname': 'ColumnDataType',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }, {
                'displayname': 'FormulaType',
                'physicalname': 'FormulaType',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }, {
                'displayname': 'ExcelFormula',
                'physicalname': 'ExcelFormula',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 280,
            }, {
                'displayname': 'LastUpdatedBy',
                'physicalname': 'UpdatedBy',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }, {
                'displayname': 'DependentSheetName',
                'physicalname': 'DepedentSheetName',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                // 'minWidth': 180,
            }
        ];

        setTimeout(() => {
            console.log('000000000000000')
            this.workbookSvc.getDataSets(this.loggedInUserDetails.user_id, this.workbook_id).subscribe(responseList => {
                console.log('111111111111')
                console.log(responseList)
                this.leftGridDataSource = responseList;    
                this.leftGridDataSource.forEach((element, index) => {
                    element.id = Math.random();
                    element.records = index + '88000';
                });     
                console.log(this.leftGridDataSource)
                this.isDataReady = true;
                this.progressState.states = this.zettaUtils.getStateList('create-dataset');
                this.progressState.currentStateIndex = 2;
                this.progressState.currentStateInfo = 'Saved...';
                this.progressState.type = 'Data Set';
            }, err => {
                this.messageSvc.sendMessage({ message: 'User entitlements get users has failed', type: 'INFO', hideboard: true });
            })
        }, 500);

      }

      getFormulas(datasetId, pageno, limit = 30) {
        this.workbookSvc.getFormulas(this.loggedInUserDetails.user_id, this.workbook_id, datasetId, pageno, limit).subscribe(responseList => {
            console.log(responseList)
            this.rightGridDataSource = responseList['currentpage'];    
            console.log(this.rightGridDataSource)    
            if (this.rightGridDataSource.length === 0) {
                this.noData = true;
            }    
            this.rightGridDataSource.forEach((element, index) => {
                element.id = Math.random();
            });  
            this.totalRightGridDataSource = responseList.totalrecords;
            this.isDataReadyRight = true;
            this.rightGridRows = this.rightGridDataSource.length;
            if (this.rightGrid) {                
                this.rightGrid.dataView.setItems(this.rightGridDataSource);
                // this.rightGrid.gridService.setSelectedRows([]);
                // this.rightGrid.gridService.setSelectedRows(row['selectedRightGridRows']);
                this.hasScrolled = false;
            }
            this.isShowRightGridDataSource = true;
        }, err => {
            this.messageSvc.sendMessage({ message: 'User entitlements get users has failed', type: 'INFO', hideboard: true });
        })
    }
  
      onLeftGridCellClicked(e) {
          const args = e.args;
          const eventEle = e.eventData;
          if (eventEle.target.title === 'View Formulas') {
            //   this.selectedCenterRowIndex = args.row;
            const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);      
            //   this.leftGrid.gridService.setSelectedRows(args.row);
            this.leftGrid.gridService.highlightRow([args.row]);
            //   this.rightGrid.gridService.setSelectedRows([]);
            //   this.rightGrid.dataView.setItems([]);
            this.isDataReadyRight = false;
            this.WorkBookDataSetId = row.WorkBookDataSetId;
            this.rightGridDataSource = [];
            this.page = 1;
            this.limit = 30;
            this.getFormulas(this.WorkBookDataSetId, this.page, this.limit)
          }
      }
  
      leftGridCreated(grid) {
          const parent = this;
          parent.leftGrid = grid;
      }
  
      rightGridCreated(grid) {
          this.rightGrid = grid;
          // this.rightGrid.gridService.highlightRow([3, 4, 8, 9], 2000);
      }
     
      ngOnDestroy() {
          if (!this.showBreadcrumb) {
              $('#breadcrumb').removeClass('d-none');
          }
          this.messageSvc.clearMessage();
      }
  
      leftGridCount() {
          if (this.leftGrid) {
              return this.leftGrid.dataView.getItems().length;
          } else {
              return this.leftGridDataSource.length;
          }
      }
  
      rightGridCount() {
          if (this.rightGrid) {
              return this.rightGrid.dataView.getItems().length;
          } else {
              return this.rightGridDataSource.length;
          }
      }

      onNext(): void {
        this.page++;
        this.getFormulas(this.WorkBookDataSetId, this.page, this.limit);
      }

      onScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
          this.hasScrolled = true;
          this.onNext();
        }
      }
  
  }
  
  
  
