import { filter } from 'rxjs/operators';
import { Catalog } from './../../../../zettaclassify/zs-cl-content/classify.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Project } from '../project.model';
import { ProjectService } from '../project.service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { MessageService } from 'src/app/common/components/message/message.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { element } from 'protractor';
const zettaUtilsInstance = new ZettaUtils();

const entityNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  function getIconByEntityID() {
    return zettaUtilsInstance.getProjectEntityClassById(dataContext.entity_id);
  }
  function getColorByEntityID() {
    return zettaUtilsInstance.getProjectEntityColor(dataContext.entity_id);
  }
  function getEntityIcon() {
		return zettaUtilsInstance.getEntityIconClassByName(dataContext.icon);
	}

	function getColorIcon() {
		return zettaUtilsInstance.getEntityIconColorByName(dataContext.icon);
	}
  const entity_icon = dataContext.icon.startsWith('fa') ? `fas ${dataContext.icon}` : `fas fa-${dataContext.icon}`;
  return `
    <div class="d-flex flex-row justify-content-start formatter pointer wd-100 m-0 p-0 h-50">
      <div class="formatter match-radio-formatter">
        <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}">
        <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
      </div>
      <div class="iconEntity">
        <i class='${ dataContext.icon === 'r1c1' ? getIconByEntityID() : `${entity_icon}` } mr-2' style='color:${ dataContext.icon === 'r1c1' ?  getColorByEntityID() : getColorIcon()}'></i>
      </div>
      <div>
        <span title="${dataContext.semantic_object}" class="pointer text-regular text-truncate">${dataContext.semantic_object}</span><br>
      </div>
    </div>`;
}

const editEntityNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  function getIconByEntityID() {
    return zettaUtilsInstance.getProjectEntityClassById(dataContext.entity_id);
  }
  function getColorByEntityID() {
    return zettaUtilsInstance.getProjectEntityColor(dataContext.entity_id);
  }
  function getEntityIcon() {
		return zettaUtilsInstance.getEntityIconClassByName(dataContext.icon);
	}
	function getColorIcon() {
		return zettaUtilsInstance.getEntityIconColorByName(dataContext.icon);
	}
  const entity_icon = dataContext.icon.startsWith('fa') ? `fas ${dataContext.icon}` : `fas fa-${dataContext.icon}`;
  if(dataContext.entity_id == columnDef.params.list) {
    return `
    <div class="d-flex flex-row justify-content-start formatter pointer wd-100 m-0 p-0 h-50">
      <div class="formatter match-radio-formatter">
        <input type="radio" id="data-${row}" name="dataSelected" checked="true" value="data-${row}">
        <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
      </div>
      <div class="iconEntity">
        <i class='${ dataContext.icon === 'r1c1' ? getIconByEntityID() : `${entity_icon}` } mr-2' style='color:${ dataContext.icon === 'r1c1' ?  getColorByEntityID() : getColorIcon()}'></i>
      </div>
      <div>
        <span title="${dataContext.semantic_object}" class="pointer text-regular text-truncate">${dataContext.semantic_object}</span><br>
      </div>
    </div>`;
  } else {
    return `
    <div class="d-flex flex-row justify-content-start formatter pointer wd-100 m-0 p-0 h-50">
    <div class="formatter match-radio-formatter">
      <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}">
      <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
    </div>
    <div class="iconEntity">
      <i class='${ dataContext.icon === 'r1c1' ? getIconByEntityID() : `${entity_icon}` } mr-2' style='color:${ dataContext.icon === 'r1c1' ?  getColorByEntityID() : getColorIcon()}'></i>
    </div>
    <div>
      <span title="${dataContext.semantic_object}" class="pointer text-regular text-truncate">${dataContext.semantic_object}</span><br>
    </div>
  </div>`;
}
}


@Component({
  selector: 'zetta-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit, OnDestroy {

  createProjectForm: FormGroup;
  entityGroup: Map<String, any[]> = new Map<String, any[]>();
  entityIdMap: Map<String, any> = new Map<String, any>();
  now: number = Date.now();
  isDataReady = false;
  project = new Project();
  loadProject = false;
  errorMessage = undefined;
  showBreadcrumb = false;
  progressState = new ProjectStatus();
  modeState: any = {};
  savingProject = false;
  @ViewChild('focusElement') focusElement;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  CatalogName: [];
  entityName: [];
  entityGroups;
  catalogID = "";
  defaultCatalog;
  defaultCatalogName = "";
  object_type_id: number;
  public tableSettings: object = { 'height': "100%", 'width': "99%", 'border-top': "100%", 'pageSize': 10, 'isNormalPagination': false };
  gridOptions: any;
  projectHomeTableColDef: any;
  public entityData: any;
  public grid: AngularGridInstance;
  hasScrolled = false;
  isLoading = false;
  total = 0;
  page = 1;
  limit = 10;
  noData = false;
  entity_id: any;
  selectedEntity: {}
  resolveCatalog;
  newProject = true;
  isFormValid = false;
  selected_entity_row = null;
  constructor(public zettaUtils: ZettaUtils,
    private formBuilder: FormBuilder,
    public service: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageSvc: MessageService) {
  }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');
    this.project.project_id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.object_type_id = +sessionStorage.getItem('project_type_id');
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }
    if (this.project.project_id !== undefined && this.project.project_id !== null) {
      this.loadProject = true;
      this.getProject();
      sessionStorage.setItem('newProject', 'false');
      this.newProject = false;
    } else {
      this.getEntityGroup();
      sessionStorage.setItem('newProject', 'true');
      this.newProject = true;
    }


    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30     //scss file  --> line-height: 60px !important;
    };

    this.projectHomeTableColDef = [
      {
        'displayname': 'Entity Name',
        'physicalname': 'semantic_object',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-truncate',
        'minWidth': 180,
        'formatter': entityNameFormatter,
      },
      {
        'displayname': 'Entity Type',
        'physicalname': 'entity_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-left',
        'minWidth': 50,
      }]

    this.progressState.states = this.zettaUtils.getStateList('create-project');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Project';
    this.progressState.isEdit = this.loadProject ? true : false;

    this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
    this.getAllEntityGroup();
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }

  setProjectForm() {
    this.createProjectForm = this.formBuilder.group({
      title: [this.project.name, [Validators.required]],
      description: [this.project.description],
      entityModel: [this.project.entity_id, [Validators.required]]
    });
    this.validateForm();
  }

  onGridCreation(grid) {
    this.grid = grid;
    this.grid.slickGrid.onSort.subscribe(resp => {
      sessionStorage.setItem("resetpage_filter", "true");
      this.hasScrolled = false;
      this.noData = false;
    });
  }

  getProject() {
    this.service.getProject(this.service.getObjectFromSession('userInfo').user_id, this.project.project_id).subscribe(responseList => {
      this.project = responseList;      
      this.initEntityColumnDef();
      this.getCatalogDetail();
      this.getAllEntityGroup();
      this.setProjectForm();
      this.getEntityGroup();
    }, err => {
    });
    this.service.getCurrentActiveJobs(this.service.getObjectFromSession('userInfo').user_id, this.project.project_id, this.object_type_id).subscribe(jobs => {
      if (this.service.hasActiveJob(jobs)) {
        this.router.navigate(['/zs/projects', this.project.project_id]);
      }
    },
      error => {
        // this.messageSvc.sendMessage({message: 'Create project get current active jobs has failed', type: 'INFO', hideboard: true});
      });
  }
  initEntityColumnDef(){
    this.projectHomeTableColDef = [
      {
        'displayname': 'Entity Name',
        'physicalname': 'semantic_object',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-truncate',
        'minWidth': 180,
        'formatter': this.project.project_id ? editEntityNameFormatter : entityNameFormatter,
        'params': { list: this.project.entity_id}
      },
      {
        'displayname': 'Entity Type',
        'physicalname': 'entity_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-left',
        'minWidth': 50,
      }];
  }
  getCatalogDetail() {
    this.service.getCatalogDetails(this.loggedInUserDetails.user_id).subscribe(response => {
      let parent = this;
      parent.CatalogName = response;
      parent.defaultCatalog = parent.CatalogName.filter(function (element: any) {
        if(!parent.newProject) {
          if(element.catalog_id == parent.project.catalog_id) {
            parent.resolveCatalog = element;
            parent.defaultCatalog = element;
            parent.catalogID = parent.resolveCatalog.catalog_id;
          }
        } else {
          if (element.name == AppGlobals.DEFAULT_SYSTEM_CATALOG) { 
            parent.resolveCatalog = element;
            parent.defaultCatalog = element;
            parent.catalogID = parent.resolveCatalog.catalog_id;
            return element;
          }
        }
      })
      if(this.newProject && !this.resolveCatalog) {
        this.resolveCatalog = response[0];
        parent.catalogID = parent.resolveCatalog.catalog_id;
      }
      this.isLoading = true;
      parent.getEntityDetail(this.page, this.limit);
    })
  }

  getCatalogId(event) {
    this.catalogID = event.target.value    
    if(this.grid) {
      this.entityData = [];
      this.grid.dataView.setItems(this.entityData);
      this.grid.gridService.setSelectedRows([]);
    }
    this.page = 1;
    this.selectedEntity = null;
    this.entity_id = null;
    this.project.entity_id = null;
    this.createProjectForm.controls.entityModel.setValue(this.project.entity_id);
    $("#data-" + this.selected_entity_row).prop("checked", false);
    this.initEntityColumnDef();
    this.getEntityDetail(this.page, this.limit);
    this.validateForm();
    this.isLoading = true;
  }

  getEntityDetail(pageNo, limit) {
    this.tableSettings['search_url'] = this.service.searchEntityDetails(this.loggedInUserDetails.user_id, this.catalogID, 1, limit);
    this.service.getEntityDetails(this.loggedInUserDetails.user_id, this.catalogID, pageNo, limit).subscribe(response => {
      this.entityData = response['currentpage'];      
      this.total = response['totalrecords']      
      let concatedRows = this.entityData;      
      if (this.entityData.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.isLoading = false;
      if (this.grid) {        
        concatedRows = [ ...this.grid.dataView.getItems(), ...this.entityData];                
        this.entityData.forEach(element => {          
          element.id = Math.random();
        });
        this.entityData = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLoading = false;
      this.hasScrolled = false;

      // this.messageSvc.sendMessage({message: 'Get datasources has failed', type: 'INFO', hideboard: true});
    })

  }

  goToPage(n: number): void {
    this.page = n;
    this.getEntityDetail(this.page, this.limit);
  }

  onNext() {    
    this.page++;    
    this.getEntityDetail(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getEntityDetail(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  getAllEntityGroup() {
    this.service.getEntityGroup().subscribe(response => {
      this.entityGroups = response;
    })
  }

  getEntityGroup() {
    this.service.fetchEntities().subscribe(responseList => {
      responseList['currentpage'].forEach(element => {
        this.entityIdMap.set(element.entity_id.toString(), element);
        if ((element.entity_id === this.project.entity_id) && this.loadProject) {
          this.createProjectForm.controls.entityModel.setValue(element);
        }

        if (this.entityGroup.has(element['entity_type_name'])) {
          this.entityGroup.get(element['entity_type_name']).push(element);
        } else {
          const list: any[] = [];
          list.push(element);
          if ((element.entity_id === this.project.entity_id) && this.loadProject) {
            this.createProjectForm.controls.entityModel.setValue(element);
          }
          this.entityGroup.set(element['entity_type_name'], list);
        }
      });

      if (!this.loadProject) {
        this.setProjectForm();
      }
      this.isDataReady = true;
    }, err => {
      this.messageSvc.sendMessage({ message: 'Get entity group has failed', type: 'INFO', hideboard: true });
    });
    this.getCatalogDetail();
  }


  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    this.createProjectForm.controls.entityModel.setValue(row.entity_id);
    this.selectedEntity = row;
    parent.entity_id = row.entity_id;
    this.selected_entity_row = args.row;
    $("#data-" + args.row).prop("checked", true);
    this.validateForm();
  }

  onSubmit(redirect: boolean) {
    // Project is getting saved..
    if (this.savingProject) {
      return;
    }
    if (this.createProjectForm.valid) {
      this.errorMessage = undefined;
      this.savingProject = true;
      const projecttemp = new Project();
      if (this.project) {
        projecttemp.project_id = this.project.project_id;
      }

      projecttemp.name = this.createProjectForm.controls.title.value;
      projecttemp.description = this.createProjectForm.controls.description.value;
      projecttemp.entity_id = this.entity_id;
      projecttemp.user_id = this.service.getObjectFromSession('userInfo').user_id;
      projecttemp.tenant_id = this.service.getObjectFromSession('userInfo').tenant_id;

      if (!this.loadProject) {
        projecttemp.users = [];
        projecttemp.users.push({ user_id: projecttemp.user_id });
        projecttemp.project_id = undefined;
      }


      this.service.saveProject(projecttemp).subscribe(responseList => {
        if (responseList.body) {
          this.loadProject = true;
          this.project = responseList.body;
        }

        this.errorMessage = '';
        this.savingProject = false;
        $('#projectState0').removeClass('d-none');
        setTimeout(function () {
          $('#projectState0').addClass('d-none');
        }, 5000);

        if (redirect) {
          this.router.navigate(['/zs/projects', this.project.project_id, 'users']);
        } else {
          this.router.navigate(['/zs/projects', this.project.project_id, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
        }

      }, err => {
        this.errorMessage = err.error.message;
        this.savingProject = false;
        this.messageSvc.sendMessage({ message: 'You do not have permission to access the resource, Please ask your administrator', type: 'INFO', hideboard: true });
      });
    }
  }

  getRadioBtnRowClass(inputEntity: any, index: number, total: number) {
    const selectedEntity = this.createProjectForm.controls['entityModel'].value;
    if (selectedEntity !== undefined && selectedEntity !== null && selectedEntity.entity_id === inputEntity.entity_id) {
      if (index === 0 && index === total - 1) {
        return 'check-box-selected first-groupRow-radius last-groupRow-radius border-top border-right border-left border-bottom';
      } else if (index === 0) {
        return 'check-box-selected first-groupRow-radius border-top border-right border-left';
      } else if (index === total - 1) {
        return 'check-box-selected last-groupRow-radius border-right border-left border-bottom';
      } else {
        return 'check-box-selected border-right border-left';
      }
    }

    if (index === 0 && index === total - 1) {
      return 'border-left first-groupRow-radius last-groupRow-radius   border-top border-bottom border-right';
    } else if (index === 0) {
      return 'border-left first-groupRow-radius   border-top border-right';
    } else if (index === total - 1) {
      return 'border-left last-groupRow-radius border-bottom border-right';
    } else {
      return 'border-left border-right';
    }
  }

  onTheNext(): void {
    this.onSubmit(false);
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

  onTextChange() {
    if (this.errorMessage) {
      this.errorMessage = false;
    }
    this.validateForm();
  }

  onScroll(parentClass) {
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  
  validateForm() {
    if (this.createProjectForm.valid && this.createProjectForm.controls.title.value.trim()) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
}
