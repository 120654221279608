// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  envName: 'uat',
  version: 'v0.2.1',
  config: {
    'APP_NAME': 'Zetta App',
     'BASE_URL': 'http://localhost',
     'API_URL': 'http://localhost/api',
     'API_BASE_URL': 'http://localhost',
    'AUTH_STRATEGY':'jwt',
    'AZURE_SOS': {
      auth: {
        clientId: 'ac2b2a98-8a08-4191-bdc4-32704649935b',
        authority: "https://login.microsoftonline.com/9d5784cb-31ce-4e3d-b42c-a8fb0d41d233/",
        validateAuthority: true,
        // redirectUri: "https://zs-dev.azurewebsites.net/#/zs/home",
        // postLogoutRedirectUri: "https://zs-dev.azurewebsites.net/",
        redirectUri: "http://localhost:4200/#/zs/home",
        postLogoutRedirectUri: "http://localhost:4200/",
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // set to true for IE 11
      },
      concentNSecurity:{
        popUp: false,
        consentScopes: [
            "user.read",
            "openid",
            "profile",
            "api://139355fa-aae3-48f4-bc99-64465c178afe/default_group_scope"
        ],
        // Token will not be sent to the unprotected URIs
        unprotectedResources: ["https://www.microsoft.com/en-us/"], //This is ignored in current latest versions.
        extraQueryParameters: {}
      },
      apiConsentUrl: 'api://139355fa-aae3-48f4-bc99-64465c178afe/default_group_scope'
    }
  }
};

//For apotex qa env
// export const environment = {
//   production: true,
//   envName: 'apxedpqa',
//   version: 'v1.0.0',
//   config: {
//     'APP_NAME': 'Zetta App',
//     //uncomment below
//     // 'BASE_URL': 'https://apxcc-edp-qa-asvc-zetaclassify-ui-001.azurewebsites.net',
//     // 'API_URL': 'https://apxcc-edp-qa-asvc-zetaclassify-api-001.azurewebsites.net/api',
//     // 'API_BASE_URL': 'https://apxcc-edp-qa-asvc-zetaclassify-api-001.azurewebsites.net',
//     //comment Below
//     'BASE_URL': 'http://localhost:4200',
//     'API_URL': 'http://localhost:10002/api',
//     'API_BASE_URL': 'http://localhost:10002',
//     'AZURE_SOS': {
//       auth: {
//         clientId: '1dd7dada-3d3a-4283-a4b4-f13ad0f2ecfc', //Client Id/Application Id of UI 
//         authority: "https://login.microsoftonline.com/6ab8c3f8-6287-441e-b3e8-cfe768cc3c6c/", //Directory (tenant) ID of UI application
//         validateAuthority: true,
//         redirectUri: "https://apxcc-edp-qa-asvc-zetaclassify-ui-001.azurewebsites.net/#/zs-cl/home", //redirect urls
//         postLogoutRedirectUri: "https://apxcc-edp-qa-asvc-zetaclassify-ui-001.azurewebsites.net/",
//         navigateToLoginRequestUrl: true
//       },
//       cache: {
//         cacheLocation: "localStorage",
//         storeAuthStateInCookie: false, // set to true for IE 11
//       },
//       concentNSecurity: {
//         popUp: false,
//         consentScopes: [
//           "user.read",
//           "openid",
//           "profile",
//           "api://22f526f8-1c5c-466a-b307-5caa5072fe44/default_group_scope" //application /client id of API this UI app is calling
//         ],
//         // Token will not be sent to the unprotected URIs
//         unprotectedResources: ["https://www.microsoft.com/en-us/"], //This is ignored in current latest versions.
//         extraQueryParameters: {}
//       },
//       apiConsentUrl: 'api://22f526f8-1c5c-466a-b307-5caa5072fe44/default_group_scope' //application /client id of API this UI app is calling
//     }
//   }
// };

// export const environment = {
//   production: true,
//   envName: 'uat',
//   version: 'v0.2.1',
//   config: {
//     'APP_NAME': 'Zetta App',
//     //'BASE_URL': 'https://zettasense.azurewebsites.net',
//     //'API_URL': 'https://zettasense-api.azurewebsites.net/api'
//      //'API_BASE_URL': 'https://zettasense-api.azurewebsites.net',
//      'BASE_URL': 'http://localhost:4200',
//     'API_URL': 'http://localhost:10002/api',
//     'API_BASE_URL': 'http://localhost:10002',
//     'AZURE_SOS': {
//       auth: {
//         clientId: '8780b1cc-c393-42c7-8953-ee8e4e79ba21', //new zettasense app id
//         authority: "https://login.microsoftonline.com/common/",
//         validateAuthority: true,
//         // redirectUri: "https://zs-dev.azurewebsites.net/#/zs/home",
//         // postLogoutRedirectUri: "https://zs-dev.azurewebsites.net/",
//         redirectUri: "http://localhost:4200/#/zs/home",
//         postLogoutRedirectUri: "http://localhost:4200/",
//         navigateToLoginRequestUrl: true
//       },
//       cache: {
//         cacheLocation: "localStorage",
//         storeAuthStateInCookie: false, // set to true for IE 11
//       },
//       concentNSecurity:{
//         popUp: false,
//         consentScopes: [
//             "user.read",
//             "openid",
//             "profile",
//             //"api://139355fa-aae3-48f4-bc99-64465c178afe/default_group_scope" //single tenant
//             "api://57a0c587-5963-407a-bf58-0ecd6e4d6c6b/default_group_scope" //muliti tenant
//         ],
//         // Token will not be sent to the unprotected URIs
//         unprotectedResources: ["https://www.microsoft.com/en-us/"], //This is ignored in current latest versions.
//         extraQueryParameters: {}
//       },
//       apiConsentUrl: 'api://57a0c587-5963-407a-bf58-0ecd6e4d6c6b/default_group_scope'
//     }
//   }
// };
