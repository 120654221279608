import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentModule } from './content/content.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ContentModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ZettasenseModule { }
