// import { AngularGridInstance } from 'angular-slickgrid';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { BaseService } from '../../../../../common/services/base-service';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
// import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
// import { ProjectService } from '../../../../../zettasense/content/project/project.service';
// import { ObservableService } from '../../../../../common/services/observable.service';
// import 'rxjs/add/operator/takeWhile';
// import 'rxjs/add/observable/timer';
// import { Observable } from 'rxjs/Rx';
// import { AppGlobals } from '../../../../../common/shared/app.globals';
// import { MessageService } from '../../../../../common/components/message/message.service';
// import { DatasetService } from '../../../../../common/components/datasets/dataset.service';
// import { IDataset } from '../../../../../common/components/datasets/dataset.model';
// import { DatasetsSampleComponent } from '../../../../../common/components/datasets/dataset-sample/dataset-sample.component';

@Component({
  selector: 'zetta-workbook-run-scheduling',
  templateUrl: './workbook-run-scheduling.component.html',
  styleUrls: ['./workbook-run-scheduling.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkbookRunSchedulingComponent implements OnInit {

  public selectOptions = [
    "08:00",
    "09:00",
    "10:00"
  ];

  public selectedOption = "09:00";

  // @Output() dataRes = new EventEmitter<any>();
  // public modalGrid: AngularGridInstance;
  // public loggedInUserDetails = null;
  // isSampleDsLoading = false;
  // tableSettings: object = { 'height': "100%", 'width': "100%" };
  // modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
  // modalRowData: any;
  // modalGridOptions: any;
  // modelColumnDef = [];
  // page = 1;
  // limit = 15;
  // datasetId;
  // isNotDataAdmin: boolean = true;
  // url;
  // safeSrc: SafeResourceUrl;
  // @Input() dsName;
  // isFinished = false;
  // isErrored = false;
  // lastProfiledOn;
  // retryBtn = false;
  // countBtn = 0;
  // isAdmin=false;  
  // isReadWrite=false;
  // isAdminReadWrite=false;
  // showmodelpopup = false;
  // data_refresh:any;
  // public isDataReady = false;
  // public isExternalBtns = false;
	// public alive = true;
	// public isStepsCompleted = false;
  // public currentJobStatusObj: object = {};
	// public observableRef: any = null;
	// public currentURL;
	// public errorMessage: string;
	// public errorDetails: string;
	// public notApplicable = true;
	// public const_caption: string = "Executing models, this may take a few minutes";
	// public caption: string = "Executing models, this may take a few minutes";
  // object_type_id:number;


  constructor(
              // public cdRef: ChangeDetectorRef,
              // public zettaUtils: ZettaUtils,
              // private _projectService: ProjectService,
              // private datasetSvc: DatasetService,
              // public observeSvc: ObservableService,
              // private activatedRoute: ActivatedRoute,
              // private router: Router,
              // private messageSvc: MessageService,
              // private sanitizer: DomSanitizer,
              // private baseService: BaseService
              ) { }


  ngOnInit() {
    
    // this.currentJobStatusObj['job_completed'] = false;
    // this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    // this.activatedRoute.parent.params.subscribe(params => {
    //   this.datasetId = params['id'];
    // });

    // this.datasetId = '20';

    // this.object_type_id = +sessionStorage.getItem('dataset_type_id');    
    // if(sessionStorage.getItem('user_entitlement')) {
    //   let user_entitlement=sessionStorage.getItem('user_entitlement');
    //   let user=user_entitlement.split(',');     
    //   this.isAdmin = JSON.parse(user[0]) ? true:false;
    //   this.isReadWrite = JSON.parse(user[1]) ? true:false;     
    //   if(this.isAdmin || this.isReadWrite){
    //     this.isAdminReadWrite=true;
    //   }
    // }

    // this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + this.datasetId + '/dataprofiler/report';
    // this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    // this.modalGridOptions = {
    //   enableGridMenu: false,
    //   selectable: true,
    //   enableFiltering: true,
    //   CheckboxSelector: false,
    //   enableCellNavigation: true,
    //   enableRowSelection: true
    // };

    // this.observeSvc.getDataSetDetails.subscribe(resp => {
    //   this.isNotDataAdmin = resp['created_by'] === this.loggedInUserDetails.user_name ? false : true;
    // });

    // const parent = this;
		// const routeParams = parent.activatedRoute.snapshot.params;
    // parent.currentURL = parent.router.url;
    
    // parent.getEntityResolvedData();    
    // parent.getDataset();
  }

  // selectOptionDataSetModelList(dataSetId, limit = 30) {
  //   this.isSampleDsLoading = true;
  //   this._projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
  //       this.modalRowData = data['currentpage'];
  //       let rowKeys = [];
  //       if (data['currentpage'].length > 0) {
  //         rowKeys = Object.keys(data['currentpage'][0]);
  //       }
  //       this.modelColumnDef = [];

  //       rowKeys.forEach(column => {
  //         this.modelColumnDef.push({
  //           'displayname': column,
  //           'physicalname': column,
  //           'sortable': true,
  //           'datatype': 'String',
  //           'filterable': true,
  //           'minWidth': 150,
  //         });
  //       });

  //       this.modalRowData.forEach((element: any) => {
  //         element.id = Math.random();
  //       });
  //       if (this.modalGrid) {
  //         this.modalGrid.dataView.setItems(this.modalRowData);
  //         this.modalGrid.gridService.setSelectedRows([]);
  //       }
  //       this.isSampleDsLoading = false;
  //     }, err => {
  //       this.isSampleDsLoading = false;
  //       this.modalRowData = [];
  //     });
  // }

  // onModelGridCreation(grid) {
  //   this.modalGrid = grid;
  // }

	// getEntityResolvedData() {
  //   const parent = this;
  //   parent.isDataReady = false;
  //   parent.isExternalBtns = true;
  //   this.dataRes.emit(this.isExternalBtns);
  //   parent.zettaUtils.isProcessRunning = true;      
  //   this.loopTrack(this.object_type_id);
  //   this.countBtn = 0;
  //   this.retryBtn = false;
  //   parent.observableRef = Observable.timer(0, 30000).takeWhile(() => parent.alive).subscribe(() => {
  //     this.loopTrack(this.object_type_id);
  //     this.countBtn = this.countBtn + 1;
  //   });
  // }

// getScheduleDetails(){
//   this.datasetSvc.getScheduleRefreshDetails(this.loggedInUserDetails.user_id,this.loggedInUserDetails.tenant_id,this.datasetId).subscribe(resp=>{
//     if(resp){
//       this.data_refresh=resp;
//     }
//   },error=>{
//   })
// }

  // getDataset() {
  //   const Datasets: IDataset = {
  //     user_id: this.loggedInUserDetails.user_id,
  //     tenant_id: this.loggedInUserDetails.tenant_id,
  //     dataset_id: this.datasetId
  //   }
  //   this.datasetSvc.getDataset(Datasets).subscribe((resp) => {
  //     if (resp) {
  //       this.data_refresh = resp;
  //     }
  //   }, err => { });
  // }
  
  // loopTrack(object_type_id) {
  //   const parent = this;
  //       // Cancel subscription in case URL changes
  //       if (parent.currentURL !== parent.router.url) {
  //         parent.alive = false;
  //         parent.observableRef.unsubscribe();
  //         return;
  //       }
  //       parent.datasetSvc.getCurrentActiveJobs(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], object_type_id, this.datasetId).subscribe(response => {
  //         console.log(response)
  //         if(response.steps_completed == 3){
  //           parent.isDataReady = true;
  //         }
  //         if (parent.countBtn > 7 && (response['algo'] === "" || response['algo'] === null)) {
  //             parent.retryBtn = true;
  //             parent.isDataReady = true;
  //             parent.isExternalBtns = true;
  //         }
          
  //         if (Object.values(response).length === 0) {
  //           parent.zettaUtils.isProcessRunning = false;
  //           parent.notApplicable = true;
  //           return;
  //         }
  //         parent.currentJobStatusObj = response;
  //         parent.notApplicable = false;

  //         if (response['caption'] === null || response['caption'] === '') { 
  //               response['caption'] = this.const_caption; 
  //         } else if(response['caption'] === 'Finished') {
  //               let count = 0;
  //               let interval = setInterval(() => {
  //                   // this.refreshingIn = this.refreshingIn <= 1 ? this.refreshingIn = this.refreshingTime : this.refreshingIn = this.refreshingIn - 1;
  //                   if (count > 15) {
  //                     clearInterval(interval);
  //                   } else {
  //                     count = count + 1;
  //                   }
  //               }, 1000);

  //               parent.isDataReady = true;
  //               parent.isExternalBtns = true;
                
  //               const getDataset: IDataset = {
  //                 user_id: this.loggedInUserDetails['user_id'],
  //                 tenant_id: this.loggedInUserDetails['tenant_id'],
  //                 dataset_id: this.datasetId
  //               };
  //               this.datasetSvc.getDataset(getDataset).subscribe((result) => {
  //                 this.lastProfiledOn = result.last_updated;
  //               }, error => { });
  //               this.caption = 'Finished';
  //               this.isFinished = true;
  //         } else {
  //               if (this.caption === this.const_caption) {
  //                 this.caption = response['caption'];
  //               } else {
  //                 this.caption = this.const_caption
  //               }
  //               this.isFinished = false;
  //               parent.isExternalBtns = false;
  //         } 

  //         if (response['job_completed']) {
  //           parent.isDataReady = true;
  //           parent.isExternalBtns = true;
  //           parent.zettaUtils.isProcessRunning = false;
  //           sessionStorage.setItem('isPageReload', 'true');
  //           if (response['is_errored']) { 
  //             parent.isErrored = true;
  //             parent.isExternalBtns = true;
  //           } else {
  //             parent.isErrored = false;
  //           }
  //           // parent.refresh();
  //         } else if (response['is_errored']) {
  //           parent.isStepsCompleted = false;
  //           parent.errorMessage = 'Error Occurred';
  //           parent.errorDetails = response['error_details'];
  //           parent.zettaUtils.isProcessRunning = false;
  //           parent.alive = false;
  //           parent.observableRef.unsubscribe();
  //           parent.isDataReady = true;
  //           parent.isExternalBtns = true;
  //           parent.isErrored = true;
  //         } else if (!parent.currentJobStatusObj['job_completed']) {
  //           parent.isStepsCompleted = false;
  //           parent.isExternalBtns = false;
  //         }
  //         // this.dataRes.emit(this.isDataReady);
  //         this.dataRes.emit(this.isExternalBtns);
  //         // parent.observeSvc.setTrackStatus = parent.currentJobStatusObj;
  //         // parent.observeSvc.getTrackStatus.subscribe(data => parent.currentJobStatusObj = data);
  //         // parent.currentJobStatusObj['algoid'] = AppGlobals.getRunModelAlgo(0);
  //       }, error => {
  //         parent.isDataReady = true;
  //         parent.isExternalBtns = true;
  //         this.dataRes.emit(this.isExternalBtns);
  //       });
  //       // this.dataRes.emit(this.isExternalBtns);
  // }

	ngOnDestroy() {
		// this.zettaUtils.isProcessRunning = false;
		// if (this.observableRef) {
		// 	this.alive = false;
		// 	this.observableRef.unsubscribe();
		// }
		// this.messageSvc.clearMessage();
	}

	// @HostListener('window:unload', ['$event'])
	// unloadHandler(event) {
	// 	if (this.observableRef) {
	// 		this.alive = false;
	// 		this.observableRef.unsubscribe();
	// 	}
	// }

	// @HostListener('window:beforeunload', ['$event'])
	// beforeUnloadHander(event) {
	// 	if (this.observableRef) {
	// 		this.alive = false;
	// 		this.observableRef.unsubscribe();
	// 	}
	// }

	// refresh() {
	// 	window.location.reload();
  // }

  // onRetry() {
  //   this.datasetSvc.runModel(this.datasetId).subscribe((result) => {
  //   }, (responseError) => {
  //       this.errorMessage = responseError.error.message;
  //       this.messageSvc.sendMessage({ message: 'Run Model has failed', type: 'INFO', hideboard: true });
  //   });

  //   this.getEntityResolvedData();
  //   // sessionStorage.removeItem('newDataSet');
  //   // this.router.navigate(['/zs/datasets', this.datasetId]);
  // }

  // cancelJob() {
  //   this.datasetSvc.cancelJob(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.datasetId).subscribe((res) => {
  //   }, (err) => { });
  // }

  // showpopup() {
  //   this.showmodelpopup = true;   
  // }

  // popupoutput(message){
  //     this.showmodelpopup = message;   
  // }

  // onRunModel(){    
  //   const dataset_id =[this.datasetId];
  //   this.datasetSvc.runModel300Stage3(dataset_id).subscribe((result) => {      
  //     if(result.length){       
  //      this.router.navigate(['/zs/datasets']);            
  //     }      
  //  },error=>{
  //    this.messageSvc.sendMessage({message: 'Algo 300 call has failed', type: 'ERROR', hideboard: true});
  //  });
  // }

}