import { ZettaUtils } from '../zettaUtils';
const zettaUtils = new ZettaUtils();

export const agAddRemoveFormatter = (params) => {
    if(params && params.value) {
        let value = params['value'];
        if (params['data'] && params['data'].user_id) {
            return value === 'delete' ? `<i class="fa fa-minus actionInlineBtn
            ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'remove_user') ? 'disabled-state' : ''}"
            data-toggle="modal" data-backdrop="false" data-target=#${params.column.colId}></i>` : `<i class="fa fa-plus actionInlineBtn
            ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'import_user') ? 'disabled-state' : ''}"></i>`;
        } else {
            return value === 'delete' ? `<i class="fa fa-minus actionInlineBtn
            ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'groups', 'remove_group') ? 'disabled-state' : ''}"
            data-toggle="modal" data-backdrop="false" data-target=#${params.column.colId}></i>` : `<i class="fa fa-plus actionInlineBtn
            ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'groups', 'add_group') ? 'disabled-state' : ''}"></i>`;
        }
    }
};

