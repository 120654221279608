import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project.model';
import { MessageService } from '../../../../common/components/message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
// import { NoRowOverlayComponent } from '../../no-row-overlay/no-row-overlay.component';
import { ActionLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
declare var $: any;
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { entityNameFormatterComponent } from 'src/app/common/shared/cell-renderer/entity-name-formatter/entity-name-formatter.component';
import { ProjectStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/project-currentStatusType-formattter/project-currentStatusType-formattter.component';
import { ProjectNameDescriptionFormatterComponent } from 'src/app/common/shared/cell-renderer/project-name-description-formattter/project-name-description-formattter.component';
import { ProjectMeasuredDQRuleFormatterComponent } from 'src/app/common/shared/cell-renderer/project-measure-dq-rule-formatter/project-measure-dq-rule-formatter.component';
import { CompressionRatioFormatterComponent } from 'src/app/common/shared/cell-renderer/compression-ratio-formatter/compression-ratio-formatter.component';
import { environment } from 'src/environments/environment';
const zettaUtilsInstance = new ZettaUtils();

@Component({
  selector: 'zetta-project-home',
  templateUrl: './project-home.component.html',
  styleUrls: ['./project-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectHomeComponent implements OnInit, OnDestroy {

  public projectDataSource: object = {};
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  columnApi: any;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  grandTotal = 0;
  subTotal = '';
  noData = false;
  selectedProjectMetadata: any;
  canDeleteProject = false;
	public project_view_all:any;
	public project_create:any;	
  isFiltering = false;
	is_visible_create=false;	
	is_enable_create=false;
  msgHeader: string;
  msgText: string;    
  showConfirmBox: boolean = false;
  gridApi:any;
  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  subTotalRecords = 0;
  row;
  totalRowCount: any;
  displayedRowCount: any;
  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) {}

  ngOnInit() {    
    this.getProjectHomeList(this.page, this.limit);
    this.intialiseGrid(); 
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: '',
        field: 'actions', 
        filter: false,
        sortable: false, 
        cellClass: 'elipse-overflow',
        editable: false, 
        minWidth:30,
        maxWidth: 40,
        cellRendererFramework: ActionLinkFormatterComponent,
        suppressColumnsToolPanel: true,
      },{
        headerName: 'Id',
        field: 'project_id',  
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        floatingFilter: true,
        minWidth:80, 
        sortable: true, 
      }, {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        sortable: true, 
        minWidth: 200,
        floatingFilter: true,
        cellRendererFramework: ProjectNameDescriptionFormatterComponent, 
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
        
      }, {
        headerName: 'Entity Type',
        field: 'entity_type_name',
        cellRendererFramework: entityNameFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        minWidth:130, 
        sortable: true,  
        cellClass: 'custom-class-name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
      }, {
        headerName: 'Current Status',
        field: 'current_status',
        minWidth:130, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellRendererFramework: ProjectStatusFormatterComponent,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Users',
        field: 'users',
        minWidth:100, 
        cellRendererFramework: NumericFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, { 
        headerName: 'Data Sets',
        field: 'datasets',
        filter: 'agTextColumnFilter',
        minWidth:150, 
        cellRendererFramework: NumericFormatterComponent,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
        floatingFilter: true, 
        tooltipField: 'created_by_user',
        sortable: true, 
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Records',
        field: 'total_records',
        minWidth:130, 
        cellRendererFramework: NumericFormatterComponent,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Mastered Entities',
        field: 'mastered_entities',
        minWidth:180, 
        cellRendererFramework: NumericFormatterComponent,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,   
        cellClass: 'ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Compression Ratio',
        field: 'compression_ratio',
        minWidth:180, 
        cellClass: 'ag-right-aligned-cell',
        cellRendererFramework: CompressionRatioFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,   
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      },  {
        headerName: 'Measured Data Quality',
        field: 'dq_score',
        minWidth:180, 
        cellRendererFramework: ProjectMeasuredDQRuleFormatterComponent,
        filter: 'agTextColumnFilter',
        resizable: true, 
        cellClass: 'cell-align-center-text',
        floatingFilter: false,
        sortable: true, 
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }, 
      }, {
        headerName: 'Last Modified',
        field: 'greatest',
        minWidth:180, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField: "greatest",  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      },
    ];
    
    this.fsGridOptions = {  
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 52,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
 
  }

  getProjectHomeList(pageno, limit = 1) {
   this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects`; 
    let serverFilter="";
    let sortfilter="";
    this.ProjectSvc.getProjectEntitiesByProjects(this.loggedInUserDetails.user_id, pageno, limit, serverFilter, sortfilter).subscribe((data) => { 
      this.projectDataSource = data;
      if (this.projectDataSource['currentpage'].length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = data['totalrecords'];
      if (pageno === 1) {
        this.grandTotal = data['totalrecords'];
      }
      let concatedRows = this.projectDataSource['currentpage'];
      this.projectDataSource['currentpage'].forEach(project => {
      project['greatest'] = this.formatDate(project['greatest']);
      project['status'] = project['current_status'];
        if(project && project.dq_score && project.dq_score >0) {
          project['measured_dq_score'] = project['dq_score'];
        } else {
          project['measured_dq_score'] = 'n/a';
        }
        project.isdisable = true;
        if (project.users.length) {
          let project_admin_users = project.users.find(user => user.role_id === AppGlobals.RESOLVE_PROJECT_ADMIN && user.user_id === this.loggedInUserDetails.user_id);
          if (project_admin_users) {
            project.project_admin = true;
            project.isdisable = false;
          } 
        }
        project.users = Array.from(new Set(project.users.map(user => user.user_id))).length;
      });
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.projectDataSource['currentpage']];
        this.projectDataSource['currentpage'].forEach(element => {
          element.id = Math.random();
        });
        this.projectDataSource['currentpage'] = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLoading = false;
      this.hasScrolled = false;
    });
  }

  onGridCreation(grid:any) {
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
    this.agGrid.api.addEventListener('filterChanged', this.onFilterChanged.bind(this));
    this.intialiseGrid();
  }
  onFilterChanged() {
    const displayedRowCount = this.agGrid.api.getDisplayedRowCount();
    if (displayedRowCount === this.grandTotal) {
      this.subTotal = '';
    } else {
      this.subTotal = displayedRowCount.toString();
    }
  }

  goToPage(n: number): void {
    this.page = n;
    this.getProjectHomeList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getProjectHomeList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getProjectHomeList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onCellClick(e): void {
    const fieldName = e.colDef.field;
    this.row = e.data;
    if (this.row !== undefined && this.row !== null) {
      // let element = eventEle.target.getAttribute("data-title");
      if (e.value === this.row.name) {
        if(!this.row.ever_executed) {
            this._router.navigate(['/zs/projects/create'],{ queryParams: { id: this.row.project_id, isEditProject:true, resolveEditFlow: this.row.ever_executed ? true : false } });
        }else{
          if(this.row && this.row.current_status == 'Production') {
            this._router.navigate([`/zs/projects/${this.row.project_id}/production-stage-details`], { queryParams: { entity_id: this.row.entity_id, entity_name: this.row.entity_name}});
          } else {
            this._router.navigate(['/zs/projects', this.row.project_id]);
          }
        }
        
      }
    }
    if (fieldName === 'actions') {
      if (this.row.isdisable) {
        $('#context_menu').hide();
       
      } else {
        $('#context_menu')
      .removeClass('d-none')
      .data('row', e.rowIndex)
      .css('top', e.event.clientY)
      .css('left', e.event.clientX + 4)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();
      let contextElement = $('#context_menu');
      if(this.row && contextElement && contextElement.children() && contextElement.children().length) {
        $('#context_menu').children()[0].className = this.row['current_status'] == 'Production' ? 'disable-option' : '';
      }
      }
      $('body').one('click', function () {
        $('#context_menu').hide();
      });
    }
  }

  
  onEditProject(hasEditClicked) {
    if (hasEditClicked && this.row && this.row && this.row['current_status'] !== 'Production') {
     this._router.navigate(['/zs/projects/create'], { queryParams: { id: this.row.project_id, showBreadcrumb: false, isEditProject:true,ever_executed:this.row.ever_executed, resolveEditFlow: this.row.ever_executed ? true : false } });
    }
  }

  onDeleteProject(hasDeleteClicked) {
    if (hasDeleteClicked) {
      this.msgHeader =  AppGlobals.CLASSIFY_PROJECT_DELETE_MSG_TEXT;
      this.showConfirmBox = true;
    }
  }

  onCloneProject(hasCloneClicked) {
    if (hasCloneClicked) {
      this.cloneRow(this.row);
    }
  }

  // Delete Project Row on Delete Action Confirmation
  deleteSelectedRow() {
    this.deleteRow();
  }

  cloneRow(projectObj) {
    const parent = this;
    const tempProject = new Project();
    tempProject.name = 'Copy_of_' + projectObj.name + '_' + Math.floor((Math.random() * 1000) + 1);
    tempProject.project_id = projectObj.project_id;
    tempProject.user_id = this.loggedInUserDetails.user_id;
    tempProject.tenant_id = this.loggedInUserDetails.tenant_id;
    this.isLoading = true;
    parent.ProjectSvc.cloneProject(tempProject).subscribe(response => {
      parent._router.navigate(['/zs/projects/create'], { queryParams: { 'id': response.project_id, showBreadcrumb: false, isEditProject:true } });
    }, err => {
      this.isLoading = true;
    });
  }

  deleteRow() {
    const parent = this;
    this.isLoading = true;
    const currentProject = new Project();
    currentProject.project_id = this.row.project_id;
    currentProject.is_deleted = true;
    currentProject.user_id = this.loggedInUserDetails.user_id;
    parent.ProjectSvc.saveProject(currentProject).subscribe(responseList => {
      if(responseList && responseList.status == 200) {
      this.clearText(responseList);
      this.projectDataSource = [];
      this.isLoading = true;
      if (this.agGrid) { 
        this.agGrid.api.purgeServerSideCache();  
      }  
      this.messageSvc.sendMessage({ message: 'Project deleted successfuly!', type: 'SUCCESS', hideboard: true });
      this.page = 1;
      this.hasScrolled = false;
      this.noData = false;
      this.getProjectHomeList(this.page, this.limit);
      this.messageSvc.sendMessage({ message: 'Project deleted successfuly!', type: 'SUCCESS', hideboard: true });
    }
    }, err => {
      this.isLoading = false;
    });
  }


  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  receiveTotalRecords($event) {
    this.subTotal = this.displayedRowCount
    
  }

  onConfirmDelete(event) {
    this.deleteRow();
  }

  clearText(event) {
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  formatDate(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    return `${month}/${d.getDate()}/${d.getFullYear()}`;
  }
}