import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-cell-renderer',
  template: `
    <div class="lineage-col-wrapp" [style.background-color]="isWinner ? '#FAF8DC' : ''" [title]="value">
      {{ value }}
    </div>
  `,
})
export class LineageCustomCellRendererComponent {
  value: any;
  isWinner: boolean;

  agInit(params: any): void {
    this.value = params.value;
    this.isWinner = params.data[params.colDef.field + '_winner'] === '1';
  }
}