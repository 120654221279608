
import { Column, Formatter } from 'angular-slickgrid';

export const toggleFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const spacer = '<span style=\'display:inline-block;height:1px;width:' + (15 * dataContext['indent']) + 'px\'></span>';
    value = (dataContext['isNewlyCreated'] && dataContext['isNewlyCreated'] === true) ? '' : value;
    if (dataContext['datatype'] === 'Chain' && value !== '') {
        if (dataContext._collapsed) {
            return spacer + ' <span class=\'map-toggle map-expand\'></span>&nbsp;<span class="text-bold">' + value +
                '</span><span class=\'pull-right\'><i class="fa fa-caret-right expandCollapse" aria-hidden="true"></i></span>';
        } else {
            return spacer + ' <span class=\'map-toggle map-collapse\'></span>&nbsp;<span class="text-bold">'
                + value + '</span><span class=\'pull-right\'><i class="fa fa-caret-down expandCollapse" aria-hidden="true"></i></span>';
        }
    } else {
        return (dataContext['indent'] === 0) ? (spacer + ' <span class=\'map-toggle\'>' + value + '</span>')
                : (spacer + ' <span class=\'map-toggle\'>' + value + '</span>');
    }
};
