import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'zetta-production-detail',
  templateUrl: './production-detail.component.html',
  styleUrls: ['./production-detail.component.scss']
})
export class ProductionDetailComponent implements OnInit, OnChanges {
  modeState: object = {mode: '', };
  projectDetail: object = {};
  constructor() { }

  ngOnInit() {
    this.modeState['mode'] = 'Production';
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.projectData && changes.projectData.currentValue && changes.projectData.currentValue) {
      this.projectDetail = changes.projectData.currentValue;
      this.onProjectDataReceive(changes.projectData.currentValue);
    }
  }

  onProjectDataReceive(eve) {
    if(eve) {
      this.projectDetail = eve;
    }
  }
}
