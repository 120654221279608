import { Project } from '../../../../zettasense/content/project/project.model';
import { ProjectService } from '../../../../zettasense/content/project/project.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { HttpClient } from '@angular/common/http';
import { DatasourceService } from '../../../../common/components/datasources/datasource.service';
import { DatasetService } from '../../../../common/components/datasets/dataset.service';
import * as _ from 'lodash';
import { MessageService } from '../../message/message.service';
import { IDataset } from '../../../../common/components/datasets/dataset.model';
import { environment } from 'src/environments/environment';
import { AppGlobals } from '../../../../common/shared/app.globals';
declare var $: any;

const RIGTH_EMPTY_GRID_MSG = 'Select a data source from the left or upload a file from your local hard drive.';
const LEFT_EMPTY_GRID_MSG = 'No data sources.';

const datasetFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
     return `<div class="pointer" data-toggle="modal" data-target="#define_specification">${value}</div>`;
};

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if(dataContext.dataSelected === row) {
        return `<div class="formatter match-radio-formatter">
                    <input type="checkbox" class="pointer" id="data-${row}" name="dataSelected" value="data-${row}" checked>
                    <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
                </div>`;
    } else {
        return `<div class="formatter match-radio-formatter">
                    <input type="checkbox" class="pointer" id="data-${row}" name="dataSelected" value="data-${row}">
                    <label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
                </div>`;
    }
};

@Component({
    selector: 'zetta-select-data-source',
    templateUrl: './select-data-source.component.html',
    styleUrls: ['./select-data-source.component.scss']
})

export class SelectDataSourceComponent implements OnInit, OnDestroy {

    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    treeViewDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;
    datasetTypes: any = [];
    datasetTypeId: number;
    datasources;
    datasetList;
    clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
    toatalPage=0;
    tableSettings: object = { 'height': "100%", 'width': "99.4%" };
    loggedInUserDetails: [];
    datasourceId: number;
    datasourceName: string;
    enableUploadBtn = false;
    selectedLeftGridRowsSize = 0;
    selectedRightGridRowsSize = 0;
    dataset: any = {};
    datasetId;
    showTab: string;
    newDatasetSel: any = {};
    newDataset: any = {};
    errorMessage = undefined;
    public dataset_id;
    dataRes;
    is_upload_complete = false;
    showDelimiter = true;
    dataSelected = -1;
    isDataSelected = false;
    dataSourceSelected = -1;
    isFileSelected=false;
    is_select_all=false;

    hasScrolled = false;
    isLoading = true;
    total = 0;
    page = 1;
    limit = 100;
    grandTotal = 0;
    subTotal = '';
    isFiltering = false;
    public datasource: any = []; 
    selected_dir_files:any =[];   
    uploaded_files=[];
    isRightGridReady=false;
    isRightGridLoading=false;
    datasource_type:string;
    datasource_type_id;
    datasource_sub_type_id:number;
    contentLimit = 5000; // assigning 5000 page size as we do not have pagination for create dataset right panel
    contentPageNo = 1;
    is_allow_to_import = false;
    fu_type_datasource: any;
    datasource_Id: number;

    constructor(public projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public zettaUtils: ZettaUtils,
        private http: HttpClient,
        public datasourceSvc: DatasourceService,
        public datasetSvc: DatasetService,
        private messageSvc: MessageService) { }

        dir_data: object[] = [];
        files_data: object[] = [];
        dir_files:any=[];   
        child_file:object[]=[];    
        sub_file_data:object[]=[]; 
        connection_string;
        dataSourceConnectionString:string;

    ngOnInit() {    
        this.fu_type_datasource = AppGlobals.FU_TYPE_DATASOURCE;
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.datasource_Id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('datasource_Id'));
        if (this.datasource_Id) {
            let selectedData = JSON.parse(sessionStorage.getItem('selected_dir_file'));
            let selectedFiles = [];
            if (selectedData) {
                selectedData.forEach(file => {
                    selectedFiles.push(file.name);
                });
            }
            this.getDataSourceFiles(this.datasource_Id, selectedFiles);
        }
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
        // this.dataset_id = this.datasetId;  //to uploading file
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }


        this.leftGridOptions = {
            enableGridMenu: false,
            // frozenRow: 1,
            enableAddRow: false,
            selectable: true,
            editable: true,
            autoEdit: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            // rowHeight: 31,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        // this.leftGridOptions = {
        //     enableGridMenu: false,
        //     enableAddRow: false,
        //     selectable: true,
        //     editable: true,
        //     enableFiltering: true,
        //     CheckboxSelector: false,
        //     enableCellNavigation: true,
        //     multiSelectable: true,
        //     autoEdit: true,
        //     noDataMsg: LEFT_EMPTY_GRID_MSG,
        // };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.progressState.states = this.zettaUtils.getStateList('create-dataset');
        this.progressState.currentStateIndex = 0;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'Data Set';
        this.progressState.project = this.projectDetail;
        this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
        this.progressState.isEdit ? sessionStorage.setItem('newDataSet', 'false') : sessionStorage.setItem('newDataSet', 'true');
        this.initColDef();
        this.isDataReady = true;

       this.getDataSourceList(this.page, this.limit);
        if(this.datasetId!== null)
        { 
            this.datasetSvc.getDataset({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId } ).subscribe(respDataset => {
            this.dataset = respDataset;
            
            // this.newDataset.description = "";
            this.newDataset.datasource_id = this.dataset.datasource_id;
            this.newDataset.dataset_type_id = 4;  
            this.newDataset.delimiter = ',';
            this.newDataset.source_table_or_filename = this.dataset.source_table_or_filename;
            this.newDataset.header = true;
        }, error => {
            this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
        });
        }
        this.datasetSvc.getDataSetTypes().subscribe(respDatasetTypes => {
            this.datasetTypes = respDatasetTypes;
            this.datasetTypeId = this.datasetTypes[0].lookup_id;
        }, error => {
            this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
        });

    }

    removeCancel1() {
        $('#cancelBoxModal1').removeClass('show');
    }
    
    removeCancel2() {
        $('#cancelBoxModal2').removeClass('show');
        this.cancelUpload();
    }

    initColDef() {
        this.gridColumnsDef = [{
            'displayname': 'Data Source',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
        }, {
            'displayname': 'Source Type',
            'physicalname': 'datasource_type',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];
    }

    onRightGridCellClick(e): void {
        const parent = this;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
                this.rightGrid.gridService.updateDataGridItem(row, false);
                this.dataSelected = args.row;
                this.newDataset = {};
                this.newDataset.datasource_id = row.datasource_id;
                this.newDataset.dataset_type_id = row.dataset_type_id;  
                this.newDataset.delimiter = row.delimiter;
                this.newDataset.source_table_or_filename = row.source_table_or_filename;
                this.newDataset.header = row.header;
                this.newDataset.schema = row.schema;
                this.newDataset.dataSelected = this.dataSelected;
                this.newDataset.type = row.type;
                // this.rightGridDataSource[this.dataSelected].dataSelected = this.dataSelected;

                $("#data-" + this.dataSelected).prop("checked", false);
                 $('#define_specification').modal('show');

                if(row.dataset_type_id === 4){
                    $('#divDelimiter').removeClass('d-none');
                    $('#divHeader').removeClass('d-none');
                    if(row.header){
                        $('#divSchema').addClass('d-none');
                    } else {
                        $('#divSchema').removeClass('d-none');
                    }
                } else if(row.dataset_type_id === 361 || row.dataset_type_id === 369){
                    $('#divDelimiter').addClass('d-none');
                    $('#divHeader').addClass('d-none');
                    $('#divSchema').removeClass('d-none');
                } else if(row.dataset_type_id === 502) {
                    $('#divDelimiter').addClass('d-none');
                    $('#divHeader').addClass('d-none');
                    $('#divSchema').addClass('d-none');
                }
        }
    }

    onLeftGridCellClick(e): void {
        sessionStorage.removeItem('selected_dir_file');    
        const args = e.args;
        const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
            this.datasourceId = row.datasource_id;
            this.dir_data=[];
            let connnection_str = row.connection_string;
            const first_char = row.connection_string.slice(0,1);
            if (first_char === '/' && row.datasource_sub_type_id === 328) {
                connnection_str = row.connection_string.slice(1, row.connection_string.length);
            }
            this.dataSourceConnectionString = connnection_str;
            this.connection_string=row.connection_string ? row.connection_string : row.name;
            this.treeViewDataSource =[];
            this.isRightGridLoading =true;
            this.datasource_type = row.datasource_type;            
            this.datasource_type_id = row.datasource_type_id;  
            this.datasource_sub_type_id = row.datasource_sub_type_id; 
            this.is_allow_to_import = false;
            if (this.datasource_type_id === 100 || this.datasource_type_id === 270) {
                this.is_allow_to_import = true;
            }
            this.getDataSourceFiles(this.datasourceId);
        }

        this.dataSourceSelected = args.row;
        if(this.dataSourceSelected > 0) {
            $($('.grid-canvas').children()[0]).css('background-color','#FFFFFF');
        }

    }
    getDataSetById(datasourceId) {
       // const rightGridDataService = this.rightGrid.gridService;
        this.datasetSvc.getDataSourcesPg({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: datasourceId }).subscribe(resp => {
            this.treeViewDataSource = resp['currentpage'];
            this.datasetList = this.treeViewDataSource;
            if (this.treeViewDataSource) {
                this.treeViewDataSource.forEach(element => {
                    element.id = Math.random();
                    element.dataset_type_id = 4;  
                    element.delimiter = ',';
                    element.source_table_or_filename = element.name;
                    element.header = true;
                    element.schema = '';
                });
            }
            this.rightGridRows = this.treeViewDataSource.length;
        }, error => {
            this.treeViewDataSource = [];
            this.rightGridRows = this.treeViewDataSource.length;
            this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
        });
    }

    
    getDataSourceFiles(datasourceId,uploadedFiles=[]) { 
         this.datasetSvc.getDataSetNew({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: datasourceId, dir:this.dataSourceConnectionString, datasource_type:this.datasource_type, datasource_sub_type_id:this.datasource_sub_type_id, pageNo:this.contentPageNo, limit:this.contentLimit, datasource_type_id : this.datasource_type_id }).subscribe(resp => {
            resp = resp['currentpage'] ? resp['currentpage']:resp;
             if(resp.length){
                this.treeViewDataSource = resp.filter(item => item.name != '._auto_generated');
             }
             this.datasetList = this.treeViewDataSource;   
             this.isRightGridReady=true;         
             this.isRightGridLoading=false;         
             if (this.treeViewDataSource) {
                 this.dir_data =[];
                 this.treeViewDataSource.forEach(element => {
                     element.id = Math.random();
                     element.datasource_id = datasourceId;
                     element.dataset_type_id = 4;  
                     element.delimiter = ',';
                     element.source_table_or_filename = element.name;
                     element.header = true;
                     element.schema = '';
                     element.dataSelected = this.dataSelected; 
                     let isChecked=false; 
                     if(uploadedFiles.length){
                        uploadedFiles.forEach(file => {
                            if(element.name.toLowerCase() == file.toLowerCase()){
                                isChecked=true;
                                this.isDataSelected=true;
                             }
                         });
                     }                    
                     if(element.type !=="") {
                         this.dir_data.push(
                             {
                                 "type": element.type,
                                 "name": element.name,
                                 "id":element.id,
                                 "dataset_type_id":element.dataset_type_id,
                                 "datasource_id":element.datasource_id,
                                 "delimiter":element.delimiter,
                                 "schema":element.schema,
                                 "header":element.header,
                                 "dataSelected":element.dataSelected,
                                 "isChecked":isChecked,
                                 "files": this.files_data,            
                             }
                         );
                     }
                     this.files_data =[];
                 });
             }
             this.rightGridRows = this.treeViewDataSource.length;                         
            
         }, error => {
             this.treeViewDataSource = [];
             this.rightGridRows = this.treeViewDataSource.length;
             this.isRightGridReady=true;         
             this.isRightGridLoading=false;
             if (error.status !== 404) {
                 this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
             }
         });
     }

     getFilePath(level,file_path):string{
        let short_path="";
        if(level>1 && file_path){
        let path=file_path.split("/");
        path.forEach((file,index) => {
            if(index>2 && index<path.length-1){
                short_path+=file+"/";
            }            
        });
        
      }
      return short_path;
     }
     onClickDir(type,dir_name,file_path,level,isSelected=false){
       this.child_file=[];
       let dirFileName=dir_name;     
       let fileChecked=false;  
       let short_path="";
        if(type==='dir'||type==='prefix'){
            short_path=this.getFilePath(level,file_path);
            const lastChar =dir_name.slice(-1);
            let directoryName= dir_name;
            if(lastChar === '/'){
                directoryName=dir_name.slice(0,-1);
            }
            if(level===1){     
                if(this.dataSourceConnectionString){
                    let lastCharConStr = this.dataSourceConnectionString.slice(-1);
                    let newConnectionStr=this.dataSourceConnectionString;
                    if(lastCharConStr !== '/'){
                        newConnectionStr = `${newConnectionStr}/`
                      }
                    file_path = `${newConnectionStr}${directoryName}`;
                }else{
                    file_path= `data/${this.loggedInUserDetails['tenant_id']}/raw/${directoryName}`; 
                }
                short_path=dir_name;               
            }
        
            this.datasetSvc.getDataSetSubFiles({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: this.datasourceId ,"dir_name":file_path }).subscribe(resp => {            
                if (resp.length) {
                    if (!this.child_file.length) {                        
                        resp.forEach(element => {                            
                            if(element.type !='prefix' && element.type !='dir'){
                                fileChecked =isSelected;
                            }else{
                                fileChecked =false;
                            }
                            this.child_file.push(
                                {
                                    "name": element.name,
                                    "type": element.type,
                                    "path": element.path,
                                    "short_path":short_path,
                                    "isChecked": fileChecked,
                                }
                            );
                        });
                    }

                }
                // this below condition will add file when it contains dir as _delta_log
                const deltaFiles = this.child_file.filter(data => data['name'].toLowerCase() == '_delta_log' || data['name'].toLowerCase() == '_delta_log/');
                if (deltaFiles.length) {
                    this.child_file = deltaFiles;
                }
               if(this.child_file.length && type==='dir' && level==1){ 
                this.dir_data.forEach(ele => {
                   if(ele['name']===dir_name){ 
                    ele['files']=this.child_file;
                   }
                });                
              }
              if(this.child_file.length && level==2 && (type==='prefix' || type === 'dir')){
                this.dir_data.forEach(ele => {
                    ele['files'].forEach(el_f => {
                        if(el_f['name']===dirFileName){
                            el_f['sub_files']=this.child_file;
                        }
                    });                   
                });
                
              }
                  
              if(this.child_file.length && level==3 && (type==='prefix' || type === 'dir')){
                this.dir_data.forEach(ele => {
                    ele['files'].forEach(el_f => {                       
                            if(el_f.type==='prefix' || el_f.type === 'dir'){
                                el_f['sub_files'].forEach(el_s => {
                                    if(el_s['name']===dirFileName){
                                        el_s['sub_files3']=this.child_file;                                       
                                    }                                
                                });
                                
                            }                      
                    });                   
                });                
              }

              if(this.child_file.length && level===4 && (type==='prefix' || type === 'dir')){
                this.dir_data.forEach(ele => {
                    ele['files'].forEach(el_f => {
                        if(el_f.type==='prefix' || el_f.type === 'dir'){
                            el_f['sub_files'].forEach(el_s => {
                                if(el_s.type==='prefix' || el_s.type === 'dir'){
                                    el_s['sub_files3'].forEach(el => {
                                        if(el['name']===dirFileName){
                                            el['sub_files4']=this.child_file;
                                        }
                                    });
                                   
                                }
                            });
                        }
                        
                       
                    });                   
                });               
              }
              if(this.child_file.length && level===5 && (type==='prefix' || type === 'dir')){
                this.dir_data.forEach(ele => {
                    ele['files'].forEach(el_f => {
                        if(el_f.type==='prefix' || el_f.type === 'dir'){
                            el_f['sub_files'].forEach(el_s => {
                                if(el_s.type==='prefix' || el_s.type === 'dir'){
                                    el_s['sub_files3'].forEach(el => {
                                        if(el.type==='prefix' || el.type === 'dir'){
                                            el['sub_files4'].forEach(el5 => {
                                                if(el5['name']===dirFileName){
                                                    el5['sub_files5']=this.child_file;
                                                }
                                            });
                                        }
                                        
                                    });
                                   
                                }
                            });
                        }
                        
                       
                    });                   
                });               
              }
           });
                
               
        }
      
    }

    onSelectFile(item:any){
        
        if (item.isChecked && item.type === 'dir' || item.type === 'prefix') {
            if (item.files.length > 0) {
                item.files.forEach(element => {
                    element.isChecked = true;
                    if (element.type === 'prefix' || element.type === 'dir') {
                        if (element.hasOwnProperty('sub_files')) {
                            element.sub_files.forEach(el_sub => {
                                el_sub.isChecked = true;
                                if (el_sub.type === 'prefix' || el_sub.type === 'dir') {
                                    if (el_sub.hasOwnProperty('sub_files3')) {
                                        el_sub.sub_files3.forEach(el_sub3 => {
                                            el_sub3.isChecked = true;
                                            if (el_sub3.type === 'prefix' || el_sub3.type === 'dir') {
                                                if (el_sub3.hasOwnProperty('sub_files4')) {
                                                    el_sub3.sub_files4.forEach(el_sub3 => {
                                                        el_sub3.isChecked = true;
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }

                    }
                });
            }
        }
        if (item.isChecked == false && item.type === 'dir' || item.type === 'prefix') {
            if (item.files.length > 0) {
                item.files.forEach(element => {
                    element.isChecked = false;
                    if (element.type === 'prefix' || element.type === 'dir') {
                        if (element.hasOwnProperty('sub_files')) {
                            element.sub_files.forEach(el_sub => {
                                el_sub.isChecked = false;
                                if (el_sub.type === 'prefix' || el_sub.type === 'dir') {
                                    if (el_sub.hasOwnProperty('sub_files3')) {
                                        el_sub.sub_files3.forEach(el_sub3 => {
                                            el_sub3.isChecked = false;
                                            if (el_sub3.type === 'prefix' || el_sub3.type === 'dir') {
                                                if (el_sub3.hasOwnProperty('sub_files4')) {
                                                    el_sub3.sub_files4.forEach(el_sub3 => {
                                                        el_sub3.isChecked = false;
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }

                    }
                });
            }
        }
        this.onFileSelected();
    }

    onSelectSubFile(item,subFiles,level,checked,dirName){
        let total_selected_files=0;
        if(subFiles.length){
          if(level==2){
            if (item.hasOwnProperty('files') && item['files'].length) {
                item.files.forEach(element => {
                    if(element.type==='prefix' || element.type==='dir' )
                    if(element.name===dirName){
                        if (element.hasOwnProperty('sub_files') && element['sub_files'].length) {
                            element.sub_files.forEach(el2 => {
                                el2.isChecked =checked;
                            });
                        }
                        
                    }
                      
                  });
            }
            
          }
          if(level==3){
            if (item.hasOwnProperty('files') && item['files'].length) {
                item.files.forEach(element => {
                    if(element.type==='prefix' || element.type==='dir' )  
                    if (element.hasOwnProperty('sub_files') && element['sub_files'].length) {
                        element.sub_files.forEach(el2 => {
                            if(el2.name===dirName){
                                if (el2.hasOwnProperty('sub_files3') && el2['sub_files3'].length) {
                                    el2.sub_files3.forEach(el3 => {
                                        el3.isChecked =checked;
                                    });
                                }
                               
                               
                            }
                        });
                    }             
                        
                  });
            }
           
          }
        }
        this.onFileSelected();
       
    }

    onFileSelected(){
        let selected_item=false;
        this.dir_data.forEach(dir => {
            if(dir['isChecked']){
                selected_item=true;
            }
            if(dir['files'].length>0){
                dir['files'].forEach(file => {
                    if (file.hasOwnProperty('sub_files') && file['sub_files'].length) {
                        file['sub_files'].forEach(ele => {
                            if (ele.hasOwnProperty('sub_files3') && ele['sub_files3'].length) {
                                ele['sub_files3'].forEach(el_sub => {
                                    if(el_sub['isChecked']){
                                        selected_item=true;
                                     }
                                });
                            }
                         if(ele['isChecked']){
                            selected_item=true;
                         }
                     });
                    }
                    if(file['isChecked']){
                        selected_item=true;
                    }
                });
            }
         });
        this.isDataSelected=selected_item;
    }
   
    leftGridCreated(grid) {
        const parent = this;
        parent.leftGrid = grid;
        parent.leftGrid.slickGrid.onSort.subscribe((e, args) => {
          this.page = 1;
          this.hasScrolled = false;
        });
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.selectedLeftGridRowsSize = args.rows.length;
            const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.rows);
            parent.datasourceId = row && row.hasOwnProperty('datasource_id') ? row.datasource_id : '';
        });
    }

    removeCancel() {
        $('#cancelBoxModal').removeClass('show');
    }

   addFiles(item){
    if(item['isChecked']){
        this.selected_dir_files.push({
            "name":item['name'],
            "type":item['type'],
            "isChecked":item['isChecked'],                            
            "connection_string":this.connection_string,
            "datasource_id": this.datasourceId ? this.datasourceId : item['datasource_id'],
            "short_path":item['short_path']
        });
    } 
   }
   onSelectAllFiles(){
       console.log(this.is_select_all);
       if(this.dir_data){
           this.dir_data.forEach(dir => {
               if(dir['type']==='dir'){
                if(dir.hasOwnProperty('files') && dir['files'].length) {
                    dir['files'].forEach(file => {
                      if(file['type']==='prefix' || file['type'] === 'dir'){
                        if(file.hasOwnProperty('sub_files') && file['sub_files'].length) {
                            file['sub_files'].forEach(sub_file => {
                                if(sub_file['type']==='prefix' || sub_file['type'] === 'dir' ){
                                    if(sub_file.hasOwnProperty('sub_files3') && sub_file['sub_files3'].length) {
                                        sub_file['sub_files3'].forEach(sub_file3 => {
                                            if(sub_file3['type']==='prefix' || sub_file3['type'] === 'dir'){
                                                if (sub_file3.hasOwnProperty('sub_files4') && file['sub_files4'].length) {
                                                    sub_file3['sub_files4'].forEach(sub_file4 => {
                                                        if(sub_file4['type']==='prefix' || sub_file4['type'] === 'dir'){}else{
                                                            sub_file4['isChecked']=this.is_select_all
                                                        }
                                                    });
                                                }
                                            }else{
                                                sub_file3['isChecked']=this.is_select_all
                                            }
                                        });                                        
                                    }
                                }else{
                                    sub_file['isChecked']=this.is_select_all
                                }
                            });
                        }
                      }else{
                        file['isChecked']=this.is_select_all
                      }
                    })
                }
               }else{
                dir['isChecked']=this.is_select_all
               }              
           });
       }
       this.onFileSelected();
   }
    getSelectedDirFiles(){
        if(this.dir_data.length){
            this.dir_data.forEach(dir => {
                if(dir['type']==='dir'){                   
                        if(dir.hasOwnProperty('files') && dir['files'].length) {
                            dir['files'].forEach(file => {
                                if(file['type']==='prefix' || file['type'] === 'dir'){
                                    // this below condition will only add file when it contains name/dir as _delta_log
                                    if(file['name'].toLowerCase()=='_delta_log' || file['name'].toLowerCase()=='_delta_log/'){
                                        const deltaFiles ={
                                            name:file['short_path'],
                                            isChecked:true,
                                            short_path:file['short_path'],
                                            type: file['type'],
                                            path:file['path']
                                        };
                                        this.addFiles(deltaFiles);
                                    }
                                    if(file.hasOwnProperty('sub_files') && file['sub_files'].length) {
                                        file['sub_files'].forEach(sub_file => {
                                            if(sub_file['type']==='prefix' || sub_file['type'] === 'dir'){
                                                if(sub_file.hasOwnProperty('sub_files3') && sub_file['sub_files3'].length) {
                                                    file['sub_files3'].forEach(sub_file3 => {
                                                        if(sub_file3['type']==='prefix' || sub_file3['type'] === 'dir'){
                                                            if (sub_file3.hasOwnProperty('sub_files4') && sub_file3['sub_files4'].length) {
                                                                sub_file3['sub_files4'].forEach(sub_file4 => {
                                                                    if(sub_file4['type']!=='prefix' && sub_file4['type']!=='dir'){ 
                                                                        this.addFiles(sub_file4);
                                                                    }
                                                                });
                                                            }
                                                        }else{
                                                            this.addFiles(sub_file3);
                                                        }                                                       
                                                    });
                                                }
                                            }else{
                                                this.addFiles(sub_file);                     
                                            }
                                        });
                                    }       
                                }else{
                                    this.addFiles(file);                                    
                                }
                               
                            });
                        }
                  
                }else{
                    this.addFiles(dir);
                    
                }
            });
        }
        sessionStorage.setItem('selected_dir_file',JSON.stringify(this.selected_dir_files));
    }

    
    onNext() {
        this.getSelectedDirFiles();
        // this.router.navigate(['/zs/datasets/add-data-transformation'], { queryParams: { datasetId: this.activatedRoute.snapshot.queryParamMap.get('datasetId') } });
       this.router.navigate(['/zs/datasets/define-datasets'], { queryParams: { datasourceType: this.datasource_type_id}});
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zs/datasets']);
        } else {
            this.router.navigate(['/zs/datasets', this.projectDetail.project_id]);
        }
    }

    onPrevious() {
        // this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.router.navigate(['/zs/datasets/create-dataset'], { queryParams: { datasetId: this.activatedRoute.snapshot.queryParamMap.get('datasetId') } });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
        sessionStorage.removeItem('newDataSet');
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.treeViewDataSource.length;
        }
    }

    closeModal() {
        $('#uploadFileSelectFileModal').addClass('d-none');
        $('#uploadFileDefineSpecificationModal').addClass('d-none');
    }

    
  getTenentGroup(fileName){
      this.datasetSvc.getTenentGroup(this.loggedInUserDetails['user_id'],this.loggedInUserDetails['tenant_id']).subscribe(resp =>{
          if(resp['currentpage']){
              console.log(resp['currentpage']);
            let group_id =resp['currentpage'][0].group_id;
            this.saveDatasourceEnitilement(group_id,fileName)
          }
       
      });
  }
  saveDatasourceEnitilement(group_id,uploadedFiles){
    let dataset=[];
    if(uploadedFiles.length){    
        uploadedFiles.forEach(file => {
            dataset.push({
             "name":file,
             "description":'',
             "datasource_content_group":[
                {
                 "group_id":group_id,
                 "datasource_content_group_users":[
                     {
                         "users":[
                             {
                                 "user_id": this.loggedInUserDetails['user_id'], //logged in user users_id
                                 "is_admin": true //set hardcoded to true in this case.
                             }
                           ]
                     }
                 ]
                }
             ]
            })
        });
    }    
    const payload = dataset;
      this.datasetSvc.saveDatasourceEntitlement(this.loggedInUserDetails['user_id'],this.loggedInUserDetails['tenant_id'],this.datasourceId,payload).subscribe(resp=>{      
        this.getDataSourceFiles(this.datasourceId,uploadedFiles);    
        this.cancelUpload();        
        this.is_upload_complete = false;           
      },error=>{
          console.log(error);
      });
  }
    receiveFileName($event) {
        if($event !== "Files Uploaded"){
            this.dataRes = $event
            this.newDataset = this.dataRes.newDataset;
            this.uploaded_files = this.dataRes.selectedDataset   
            this.is_upload_complete = this.dataRes.is_upload_complete; 
            const total_files = this.dataRes.count;
        }        
        if($event === "Files Uploaded"){  
            this.isRightGridLoading=true;             
            this.dir_data=[];
            this.closeUploadModel();                   
        }
        
    }

    closeUploadModel(){
        $('#uploadFileSelectFileModal').modal('hide');
        this.is_upload_complete = false
        this.getTenentGroup(this.uploaded_files);
       // this.getDataSourceFiles(this.datasourceId,this.uploaded_files);    
    }

    previousUpload() {
        this.showTab = 'select-file';
    }

    saveMetadataDataset() {
        const postData: IDataset = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.newDataset.dataset_id + "",
            payload: {
                description: this.newDataset.description,
                datasource_id: this.newDataset.datasource_id ? +this.newDataset.datasource_id : this.datasourceId,
                dataset_type_id: this.newDataset.dataset_type_id ? this.newDataset.dataset_type_id : this.datasetTypeId,
                delimiter: this.newDataset.delimiter,
                source_table_or_filename: this.dataRes.fileName,
                header: this.newDataset.header,
            }
        };
        this.datasetSvc.editDataset(postData).subscribe((result) => {
            // var newData: any = result;
            var newData: any = {};
            newData.id = Math.random();
            newData.name = this.dataRes.newDataset.source_table_or_filename;
            newData.datasource_id = this.dataRes.newDataset.datasource_id;
            newData.dataset_type_id = this.dataRes.newDataset.dataset_type_id;
            newData.delimiter = this.dataRes.newDataset.delimiter;
            newData.source_table_or_filename = this.dataRes.newDataset.source_table_or_filename;
            newData.header = this.dataRes.newDataset.header;
            newData.schema = '';
            newData.type = 'file';
            const rightGridDataService = this.rightGrid.gridService;
            // this.rightGridDataSource.unshift(newData);
            rightGridDataService.addItemToDatagrid(newData);
        }, (responseError) => {
            this.errorMessage = responseError.error.message;
            this.messageSvc.sendMessage({ message: 'Create dataset has failed', type: 'INFO', hideboard: true });
        });

        //Update Entitlements
        const entitlements: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.newDataset.dataset_id + "",
            payload: [
                {
                "group_id": this.loggedInUserDetails['group_id'],
                "entitlements": [
                    {
                    "user_id": this.loggedInUserDetails['user_id'],
                    "rw": true
                    }
                ]
                }
            ]              
        };
        this.datasetSvc.updateEntitlements(entitlements).subscribe((result) => {
        }, (responseError) => {
            this.errorMessage = responseError.error.message;
            this.messageSvc.sendMessage({ message: 'Update Entitlements has failed', type: 'INFO', hideboard: true });
        });

        this.cancelUpload();
        this.is_upload_complete = false;
    }

    cancelUpload() {
        this.clearModal =  Math.random();
        this.dataset_id = Math.random();
        $('#uploadFileSelectFileModal').modal('hide');
        this.newDataset = undefined;
        //this.datasetSvc.isFileUploaded.next(true);
    }
    

    cancelUpload2() {
        this.showTab = 'select-file';
    }

    updateDataset() {
        let payload = {
                    datasource_id: this.newDataset.datasource_id ? +this.newDataset.datasource_id : this.datasourceId,
                    dataset_type_id: this.newDataset.dataset_type_id ? +this.newDataset.dataset_type_id : this.datasetTypeId,
                    delimiter: '',
                    source_table_or_filename: this.newDataset.source_table_or_filename,
                    header: false,
                    schema: '',
                    is_dir: this.newDataset.type === 'dir' ? true : false
                  }  

        // dataset_type_id = 4      -> Delimiter
        // dataset_type_id = 361    -> Fixed
        // dataset_type_id = 369    -> TagFormat
        // dataset_type_id = 502    -> Parquet
        if($('#dataset_type_id').val() === '4'){
            payload.delimiter = this.newDataset.delimiter;
            payload.header = this.newDataset.header;
            payload.schema = this.newDataset.header ? '' : $('#schema').val();
        } else if($('#dataset_type_id').val() === '361' || $('#dataset_type_id').val() === '369'){
            payload.schema = $('#schema').val();
        } 

        const postData: IDataset = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.datasetId + "",
            payload: payload
        };

        this.datasetSvc.editDataset(postData).subscribe((result) => {
            if($('#dataset_type_id').val() === '502') {   //Parquet
                this.datasetSvc.runModel210(this.datasetId).subscribe((res) => {
                }, (err) => {
                    this.errorMessage = err.error.message;
                    // this.messageSvc.sendMessage({ message: 'Run Model 210 has failed', type: 'INFO', hideboard: true });
                });
            }

            $('#define_specification').modal('hide');
            $("#data-" + this.dataSelected).prop("checked", true);
            this.isDataSelected = true;
            // this.rightGridDataSource[this.dataSelected].datasource_id = result.datasource_id;
            this.treeViewDataSource[this.dataSelected].dataset_type_id = result.dataset_type_id;
            this.treeViewDataSource[this.dataSelected].delimiter = result.delimiter;
            // this.rightGridDataSource[this.dataSelected].source_table_or_filename = result.source_table_or_filename;
            this.treeViewDataSource[this.dataSelected].header = result.header;
            this.treeViewDataSource[this.dataSelected].schema = result.schema;
            this.treeViewDataSource[this.dataSelected].dataSelected = this.dataSelected;

            // const rightGridDataService = this.rightGrid.gridService;
            // // rightGridDataService.addItemToDatagrid(newData);
            // rightGridDataService.renderGrid();

        }, (responseError) => {
            this.errorMessage = responseError.error.message;
            this.messageSvc.sendMessage({ message: 'Update dataset has failed', type: 'INFO', hideboard: true });
        });
    }

    cancel() {
        $("#data-" + this.dataSelected).prop("checked", false);
        this.isDataSelected = false;
    }

    onChangeHeader(){
        if($('#header').is(":checked")){
            $('#divSchema').addClass('d-none');
        }
        else if($('#header').is(":not(:checked)")){
            $('#divSchema').removeClass('d-none');
        }
    }

    onChangeFileFormat(){
        if($('#dataset_type_id').val() === '4'){
            $('#divDelimiter').removeClass('d-none');
            $('#divHeader').removeClass('d-none');
            this.onChangeHeader();
        } else if($('#dataset_type_id').val() === '361' || $('#dataset_type_id').val() === '369'){
            $('#divDelimiter').addClass('d-none');
            $('#divHeader').addClass('d-none');
            $('#divSchema').removeClass('d-none');
        } else if($('#dataset_type_id').val() === '502') {
            $('#divDelimiter').addClass('d-none');
            $('#divHeader').addClass('d-none');
            $('#divSchema').addClass('d-none');
        }
    }
    getDataSourceList(pageNo, limit){
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasources?pageno=${1}&pagesize=${limit}`;
        // let serverfilter="";
        if(sessionStorage.getItem("serverfilter")){
          var serverfilter = sessionStorage.getItem("serverfilter");
          this.isFiltering = true;
          // pageNo = 1;
          // this.page = 1;
        } else {
          if(this.isFiltering){ 
            pageNo = 1;
            limit = 100;
          }
          var serverfilter = "";
          this.isFiltering = false;
        }
        let sortfilter="";
        if(sessionStorage.getItem("sortfilter")){
           sortfilter=sessionStorage.getItem("sortfilter");
        }
        this.datasourceSvc.getDatasources({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], pageno: pageNo,
        perpage: limit},serverfilter,sortfilter).subscribe(responseList => {
            this.datasource = responseList['currentpage'];
            if (this.datasource.length === 0) {
               // this.noData = true;
            }
            this.isLoading = false;
            this.total = responseList['totalrecords'];
            this.toatalPage = responseList['totalpages'];
            if (pageNo === 1) {
                this.grandTotal = responseList['totalrecords'];
            }
            let concatedRows = this.datasource;
            if (this.leftGrid) {
              this.datasource.forEach(element => {
                element.id = Math.random();
              });
              concatedRows = [...this.leftGrid.dataView.getItems(), ...this.datasource];
              this.datasource = concatedRows;
              this.leftGrid.dataView.setItems(concatedRows);
              this.leftGridDataSource=concatedRows;
             // this.datasourceId = this.leftGridDataSource[0].datasource_id;
              this.leftGrid.gridService.setSelectedRows([]);
               this.hasScrolled = false;
            }
            if(pageNo > responseList['totalpages']) {
              this.page = 1;
              this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasources?pageno=${1}&pagesize=${limit}`;
            }
            // this.leftGridDataSource = responseList['currentpage'];
            // this.leftGridDataSource.forEach(element => {
            //     element.id = Math.random();
            // });
            // this.leftGrid.dataView.setItems(this.leftGridDataSource);
            // this.leftGrid.gridService.setSelectedRows([]);
            // this.datasourceId = this.leftGridDataSource[0].datasource_id;
            // // this.getDataSetById(this.datasourceId);
            // this.getDataSetNew(this.datasourceId);
            // if(pageNo > responseList['totalpages']) {
            //     this.page = 1;
            //     this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets?paginate=true&pageno=${this.page}&pagesize=${limit}`;
            //   }
        }, error => {
            this.messageSvc.sendMessage({ message: 'Select datasource get datasource has failed', type: 'INFO', hideboard: true });
        });
    } 

    onScroll(parentClass) {
        if (sessionStorage.getItem("serverfilter")) {
            this.isFiltering = true;
        }
        if (this.isFiltering) {
            this.page = 1;
            this.isFiltering = false;
            
        }   
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled) {
            if(this.page < this.toatalPage){
                this.hasScrolled = true;
                this.onDatasourceNext();
            }
        }
    }

    onDatasourceNext(): void {
        this.page++;
        this.getDataSourceList(this.page, this.limit);
    }

    receiveTotalRecords($event) {
        this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
    }

    openModalImport(modal) {
        $('#' + modal).modal('show');
    }
}