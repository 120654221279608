import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from '../../../../../common/services/base-service';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ContentService } from '../../../../../zettasense/content/content.service';
import { ProjectStatus } from 'src/app/common/components/project-progress/project-progress.model';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MessageService } from 'src/app/common/components/message/message.service';
import { filter } from 'rxjs/operators';
declare var $: any;
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { ObservableService } from "../../../../../common/services/observable.service";
@Component({
  selector: 'zetta-technical-rule-definition',
  templateUrl: './technical-rule-definition.component.html',
  styleUrls: ['./technical-rule-definition.component.scss'],
})
export class TechnicalRuleDefinitionComponent implements OnInit {

  progressState = new ProjectStatus();
  createNewRule = false;
  enableNext = true;
  activeNavLnkTechnical = "rule-definition";
  loggedInUserDetails;
  dataQualityRule: any = [];
  entities: any = [];
  attributes: any = [];
  attAdd: any = [];
  dqDimensions: any = [];
  dqSequence: any = [];
  dqValueTypes: any = [];
  dqOperators: any = [];
  typeRule;
  rule_id = 0;
  containers = [];
  dqObjectIdAdded: any = null;
  dqConceptIdAdded: any = null;
  total_dqr_attributes = 0;
  total_dqr_exceptions = 0;
  flagAttributes: any = [];
  removeFlagInd;
  dsColumnName = "Account Master 10K";
  datasetId = 3;
  data: any;
  catalogId = 1;
  operators;
  datasets: any = [];
  dataset_columns: any = [];
  lokup_dataset_columns: any = [];
  lookup_concept_id;
  withColumns: any = [];
  colAdd: any = [];
  dqDatasetIdAdded: any = null;
  dqDatasetColumnIdAdded: any = null;
  total_dqr_dataset_columns = 0;
  flagDatasetColumns: any = [];
  withinDatasetColumns: any = [];
  flagComparisonDatasetColumns: any = [];
  comparisonDatasetColumns: any = [];
  selectedColumnType: string;
  showMultiSelectionDiv = false;
  selectedColumns: any = [];
  isAppliedColumn = false;
  isColumnAdd = false;
  isViewMode = false;
  validateMultiColumn = true;
  dimensionsDQ;
  sequencesDQ;
  dataQualityOperators;
  dataQualityValueTypes;
  @Input() isEditRuleView: any;
  @Input() ruleNameSelected;
  @Output() isScheduleEdit: EventEmitter<any> = new EventEmitter();
  multiDatasetCols = []
  dropdownList = [];
  selectedExceptionColumns = [];
  selectedDatasetItem = {};
  selectedDatasetColumnItem = {};
  dropdownSettings = {};
  datasetSettings = {};
  columnSettings = {};
  columnSettingsDisabled = {};
  datasetdropdownSettings = {};
  datasetColumndropdownSettings = {};
  multiDatasetColumnSettings = {};
  closeDropdownSelection = false;
  disabled = false;
  addedDatasetList: any = [];
  addedDatasetColumnList: any = [];
  dataQualityPayload: any = [];
  selectedOperator: number;
  selectedComparisionDataset = {};
  selectedComparisionDatasetColumn = {};
  selectedLookupDataset = {};
  selectedComparisonDatasetId: number;
  selectedComparisonColumnId: number;
  selectedLookupDatasetId: number
  selectedDatasetId: number;
  selectedRuleType = "14877";
  isMultiAttribute = false;
  isBetweenSelected = false;
  isRuleLoading: Boolean = true;
  join_condition = [
    { name: 'AND' },
    { name: 'OR' },
  ];
  selectedDataset;
  rule_sequence_types = [];

  rule_selected: any;
  concepts: any = [];
  rule_name;
  rule_description;
  rule_data_quality_dimension: any;
  rule_data_quality_dimension_selected;
  semantics = [];
  semantic_objects: any;
  rules;
  technicalRules = [];
  dataset_column_ids = [];
  rule_types = [];
  ruleExists;
  ruleInfo;
  ruleInfoClone
  ds_col;
  dataset_col;
  dq_rule_level: string;
  backTo: string;
  dataset_id: number;
  dataset_column_id: number;
  prevDataset: any;
  @Input() isRuleInValid: boolean;
  @Input() isRuleAdmin: boolean;
  @Input() isRuleExecuter: boolean;
  isWithinDsColumn: any = [];
  withinDatasets: any = [];
  lookupDatasets: any = [];
  dqDataTypes = [];
  dqr_exceptions: any = [];
  dqr_dataset_columns: any = [];
  withindMultiatasetColumns = [[]];
  isFormValidated = false;
  payloadForEditRule: any = {};

  constructor(private baseService: BaseService,
    private datasetSvc: DatasetService,
    private catalogSvc: ZsClContentService,
    private contentSvc: ContentService,
    public zettaUtils: ZettaUtils,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private messageSvc: MessageService,
    public _observableService: ObservableService
  ) {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.ruleExists = JSON.parse(sessionStorage.getItem('tRuleDefintion'));
    this.ruleInfo = JSON.parse(sessionStorage.getItem('ruleInfo'));   //old rule
    this.ruleInfoClone = JSON.parse(sessionStorage.getItem('ruleInfoClone'));
    if(this.ruleExists) {
      this.technicalRules[0] = this.ruleExists;
    }
    if (this.ruleInfoClone) {
      this.mapExisting(this.ruleInfoClone);
    } else {
      this.mapExisting(this.ruleInfoClone);
    }
    let DQ_rule_level: string = '';
    this.activeRoute.queryParamMap.subscribe(params => {
      DQ_rule_level = params.get('dqRuleLevel');
    });
    window.addEventListener('popstate', () => {
      if (DQ_rule_level == 'dataset') {
        this.router.navigate(['/zs/datasets']);
      }
    });
  }


  mapExisting(tRules) {
    if (tRules) {
      this.dataQualityRule = this.ruleInfo;
      this.dataQualityRule.created_by = this.ruleInfoClone.created_by;
      this.dataQualityRule.created_ts = this.ruleInfoClone.created_ts;
      this.dataQualityRule.data_quality_rule_id = this.ruleInfoClone.data_quality_rule_id
      this.dataQualityRule.rule_name = this.ruleInfoClone.rule_name + '_' + this.ruleInfoClone.data_quality_rule_id;
      this.dataQualityRule.rule_description = this.ruleInfoClone.rule_description;
      this.dataQualityRule.source = this.ruleInfoClone.source;
      this.dataQualityRule.source_ip = this.ruleInfoClone.source_ip;
      this.dataQualityRule.source_type = this.ruleInfoClone.source_type;

      this.withindMultiatasetColumns = [];
      this.datasets = [];
      if (this.dataQualityRule.exception_rules.length) {
        this.dataQualityRule.exception_rules.forEach((element, i) => {

          if (element.rule_sequence_type_lookup_id == 14876) {
            element.comparison_join_by = element.comparison.join_condition;
            this.dataQualityRule.exception_rules[i]['comparison_dataset_id'] = element.comparison.dataset_id;
            this.dataQualityRule.exception_rules[i]['comparison_dataset_column_id'] = parseInt(element.comparison.dataset_column_id.length ? element.comparison.dataset_column_id[i] : 0);
            this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = element.comparison.value_type_lookup_id;
            this.dataQualityRule.exception_rules[i]['comparison_calculation'] = element.comparison.calculation;
            this.dataQualityRule.exception_rules[i]['comparision_filter'] = element.comparison.comparision_filter;
          }
          if (element.operator_lookup_id === 14916) {
            element.lower_limit = element.comparison.lower_limit || element.lower_limit;
            element.upper_limit = element.comparison.upper_limit || element.upper_limit;
          }
          this.selectedRuleType = element.rule_sequence_type_lookup_id;

          if (element.rule_sequence_type_lookup_id == 14876 && element.value_type_lookup_id != 14894 &&
            element.value_type_lookup_id != 14893 && element.value_type_lookup_id != 14892 &&
            element.value_type_lookup_id != 14891) {
            this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = true;
            this.dataQualityRule.exception_rules[i]['sequence'] = 2;
          } else {
            this.onChangeValueType(element.value_type_lookup_id, i, false);
          }
          if (element.rule_sequence_type_lookup_id == AppGlobals.DQ_SEQUENCE_TYPE_IS_SINGLE_COLUMN) {
            element.join_by = element.join_condition;
          }
          this.withindMultiatasetColumns.push([]);

          if (element.within_source_id && element.within_list_column_id) {
            const within_dataset_ids = element.within_source_id.split(',');
            const within_dataset_column_ids = element.within_list_column_id.split(',');
            const within_datasets = [];
            const within_column = [];
            element.within_dataset_id = +within_dataset_ids[0];
            element.within_dataset_column_id = +within_dataset_column_ids[0];
            within_dataset_ids.forEach(dataset_id => {
              const single_dataset_id = dataset_id;
              this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, single_dataset_id).subscribe(respColumns => {
                within_datasets.push({
                  dataset_id: parseInt(single_dataset_id),
                  name: respColumns[0].dataset_name.trim()
                });
                within_dataset_column_ids.forEach(column_id => {
                  const curr_dataset_column = respColumns.filter(col => col.dataset_column_id == column_id);
                  if (curr_dataset_column && curr_dataset_column.length > 0) {
                    within_column.push({
                      dataset_column_id: +column_id,
                      column: respColumns[0].dataset_name.trim() + '.' + curr_dataset_column[0].column
                    });
                  }
                });
                if (within_datasets.length && within_dataset_ids.length == within_datasets.length) {
                  element.data_set_name = within_datasets;
                }
                if (within_column.length && within_column.length == within_dataset_column_ids.length) {
                  element.column = within_column;
                }
              });
            });
          }
        });
      }

      this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
        this.datasets = response;
        this.withinDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataQualityRule.data_quality_rule_columns[0].dataset_id);
        this.lookupDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataQualityRule.data_quality_rule_columns[0].dataset_id);
        this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
          this.getDatasetColumns(element.dataset_id, i)
        });
      }, err => { });

      this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
        this.datasets = response;
        if (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length) {
          this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
            this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, element.dataset_id, false).subscribe(response => {
              this.ds_col = response;
              if (this.ds_col.length) {
                const columnName = this.ds_col.filter(row => row.dataset_column_id === element.dataset_column_id);
                if (columnName.length) {
                  element.column_name = columnName[0].column;
                }
              }

            }, err => { });
            const datasetNames = this.datasets.filter(row => row.dataset_id === element.dataset_id);
            if (datasetNames.length) {
              element.dataset_name = datasetNames[0].name;
            }
          });
        }
      });

      this.catalogSvc.getDataQualityDimensions().subscribe(respDim => {
        this.dimensionsDQ = respDim;
        const dq_dimension = this.dimensionsDQ.filter(row => row.lookup_id === this.dataQualityRule.dimension_lookup_id);
        if (dq_dimension.length) {
          this.dataQualityRule.dq_dimension = dq_dimension[0].name;
        }
      }, err => { });


      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        ele.rule_sequence_operation = (!ele.rule_sequence_operation || ele.rule_sequence_operation === undefined || ele.rule_sequence_operation === null) ? "AND" : ele.rule_sequence_operation;
        this.catalogSvc.getDataQualitySequenceRuleTypes('Technical').subscribe(respSeq => {
          this.dqSequence = respSeq
          const sequenceTypeName = this.dqSequence.filter(row => row.lookup_id === ele.rule_sequence_type_lookup_id);
          if (sequenceTypeName.length) {
            ele.rule_sequence_type_name = sequenceTypeName[0].name;
          }
          if (sequenceTypeName.length && sequenceTypeName[0].lookup_id === AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN) {
            this._observableService.sendRuleSequenceType = true;
          } else {
            this._observableService.sendRuleSequenceType = false;
          }
        }, err => { });

        this.catalogSvc.getDataQualityValueTypes().subscribe(respVT => {
          this.dataQualityValueTypes = respVT;
          const valuesTypes = this.dataQualityValueTypes.filter(row => row.lookup_id === ele.value_type_lookup_id);
          if (valuesTypes.length) {
            ele.value_type_name = valuesTypes[0].name;
          }
        }, err => { });

        this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
          this.dataQualityOperators = respOp;
          this.dataQualityRule.exception_rules[i]['dqOperators'] = this.dataQualityOperators;
          if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
            this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
          } else if (this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN && this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
            this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
          } else {
            this.dataQualityRule.exception_rules[i]['dqOperators'] = respOp;
          }
          const operators = this.dataQualityOperators.filter(row => row.lookup_id === ele.operator_lookup_id);
          if (operators.length) {
            ele.operator_name = operators[0].name;
          }

        }, err => { });
        this.datasetSvc.getDQDataTypes().subscribe(res => {
          this.dqDataTypes = res;
        })

        this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, ele.dataset_id, false).subscribe(response => {
          this.dataset_col = response;
          if (this.dataset_col.length) {
            const exception_column_name = this.dataset_col.filter(row => ele.dataset_column_id.includes(row.dataset_column_id.toString()));
            if (exception_column_name.length) {
              ele.exception_column_name = exception_column_name[0].column;
            }
            const comparison_dataset_column_name = this.dataset_col.filter(row => ele.comparison.dataset_column_id.includes(row.dataset_column_id.toString()));
            if (comparison_dataset_column_name.length) {
              ele.comparison_dataset_column_name = comparison_dataset_column_name[0].column;
            }
          }
        }, err => { });

      });

      if (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length) {
        this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
          this.getDatasetColumns(element.dataset_id, i)
        });
      }
      this.selectedExceptionColumns = [];
      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        (!ele.join_condition || ele.join_condition === undefined || ele.join_condition === null) ? ele.join_condition = "AND" : ele.join_condition = ele.join_condition;
        (!ele.join_by || ele.join_by === undefined || ele.join_by === null) ? ele.join_by = "" : ele.join_by = ele.join_by;
        (!ele.comparison_join_by || ele.comparison_join_by === undefined || ele.comparison_join_by === null) ? ele.comparison_join_by = "" : ele.comparison_join_by = ele.comparison_join_by;
        (!ele.comparison_dataset_id || ele.comparison_dataset_id === undefined || ele.comparison_dataset_id === null) ? ele.comparison_dataset_id = (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length ? this.dataQualityRule.data_quality_rule_columns[0].dataset_id : null) : ele.comparison_dataset_id = ele.comparison_dataset_id;
        (!ele.comparison.dataset_column_id || ele.comparison.dataset_column_id === undefined || ele.comparison.dataset_column_id === null) ? ele.comparison_dataset_column_id = 0 : ele.comparison_dataset_column_id = parseInt(ele.comparison.dataset_column_id[0]);
        (!ele.comparison_value_type_lookup_id || ele.comparison_value_type_lookup_id === undefined || ele.comparison_value_type_lookup_id === null) ? ele.comparison_value_type_lookup_id = 14879 : ele.comparison_value_type_lookup_id = ele.comparison_value_type_lookup_id;

        const selectedColumns = [];
        this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, ele.dataset_id, false).subscribe(response => {
          this.dataset_columns = response;
          if (i < this.dataQualityRule.exception_rules.length) {
            if (ele.rule_sequence_type_lookup_id === AppGlobals.DQ_SEQUENCE_TYPE_IS_SINGLE_COLUMN) {
              ele.lokup_dataset_columns = response;
            }
            this.flagDatasetColumns[i] = response;
            if (response.length && ele.dataset_column_id.length) {
              ele.dataset_column_id.forEach(column_id => {
                const selectedColumn = this.flagDatasetColumns[i].filter(column => column.dataset_column_id == column_id);
                selectedColumns.push(selectedColumn[0])
              });
              this.selectedExceptionColumns[i] = selectedColumns;
              ele.concept_id = selectedColumns
            }
          }
        });
        this.getFlagComparisonDatasetColumns(ele.comparison_dataset_id, i)
      });

      this.technicalRules[0] = this.dataQualityRule;
      this.technicalRules[0].data_quality_rule_attributes = {
        "dataset_column_id": [{
          "dataset_column_id": this.technicalRules[0].data_quality_rule_columns[0].dataset_column_id,
          "column": this.technicalRules[0].data_quality_rule_columns[0].column
        }],
        "dataset_id": [[{
          "dataset_id": this.technicalRules[0].data_quality_rule_columns[0].dataset_id,
          "name": this.technicalRules[0].data_quality_rule_columns[0].dataset_name
        }]]
      };
      this.technicalRules[0].semantic_objects = [{ "id": "1", "name": "Customer" }];
      this.technicalRules[0].flags = this.dataQualityRule.exception_rules;
    } else {
      if(!this.ruleExists) {
        this.technicalRules = [
          {
            rule_id: '1',
            rule_name: '',
            rule_description: '',
            data_quality_dimension: [],
            data_quality_rule_attributes: { dataset_column_id: [], dataset_id: [] },
            semantic_objects: [
              {
                id: '1',
                name: 'Customer'
              }
            ],
            concepts: [],
            flags: [
              {
                sequence: '1',
                rule_sequence_type_lookup_id: 14875,
                name: '',
                rule_type_id: '1',
                rule_type_name: '',
                concept_id: [],
                concept_name: 'Customer Name',
                value_type_id: '17',
                value_type_lookup_id: 14879,
                value_type_name: '',
                comparison_dataset_id: '',
                comparison_dataset_column_id: '',
                comparison_value_type_lookup_id: 14879,
                comparison_concept_name: 'Customer Number',
                comparison_value_type_id: '',
                comparison_value_type_name: 'value',
                operator_id: '1',
                operator_name: '',
                threshold: '',
                lower_limit: '',
                upper_limit: '',
                custom_exp: '',
                comparison_custom_exp: '',
                within_list_column_id: '',
                within_source_id: '',
                join_condition: '',
                data_set_name: '',
                column: '',
                dataset_column_ids: [],
                within_filter: '',
                comparision_filter: '',
                lookup_filter: '',
                dqOperators: this.dqOperators,
                lokup_dataset_columns: [],
                prev_selected_value_type: '',
                within_dataset_id: '',
                within_dataset_column_id: '',
              }
            ]
          }
        ];
      }
    }

    if (this.technicalRules.length) {
      if (this.technicalRules[0].data_quality_rule_attributes.dataset_id.length) {
        this.selectedDatasetId = this.technicalRules[0].data_quality_rule_attributes.dataset_id[0][0].dataset_id;
        this.dataset_column_ids = this.technicalRules[0].data_quality_rule_attributes.dataset_column_id
      }

    }
  }
  onComparisionColumnSelect(item: any) {
    this.selectedComparisonColumnId = item.dataset_column_id
    this.onChange();
  }

  onFlagChangeComparisonDataset(item, ind) {
    const truncated_dataset = this.getFormattedDatasetAndColumnName(item.name, 26);
    const selected_dataset = [{ dataset_id: item.dataset_id, name: truncated_dataset }];
    this.rule_selected.flags[ind].selectedComparisionDataset = selected_dataset;

    this.rule_selected.flags[ind].selected_comparision_dataset_id = item.dataset_id;
    this.rule_selected.flags[ind].selectedComparisionDatasetColumn = null;
    this.rule_selected.flags[ind].comparison_dataset_column_id = null;
    this.rule_selected.flags[ind].comparison_join_by = null;
    this.getFlagCOmpDatasetColumns(item, ind);
    this.validateFlags();
  }
  onFlagChangeComparisonDatasetColumn(data, ind) {
    const truncated_column = this.getFormattedDatasetAndColumnName(data.column, 21);
    const selected_column = [{ dataset_column_id: data.dataset_column_id, column: truncated_column }];
    this.rule_selected.flags[ind].selectedComparisionDatasetColumn = selected_column;

    this.rule_selected.flags[ind].comparison_dataset_column_id = data.dataset_column_id;
    this.validateFlags();
  }

  onSelectAll(items: any) {
    console.log(items);
  }


  ngOnInit() {
    this.activeNavLnkTechnical = sessionStorage.getItem('activeNavLnkTechnical');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.typeRule = "Technical Rule";
    this.activeRoute.parent.queryParamMap.subscribe(params => {
      this.rule_id = +params.get('rule_id');
      if (this.rule_id > 0) {
        this.isViewMode = true;
        if (!this.createNewRule) { this.getDataQualityRule(); }
      }
    });

    this.baseService.hasPageRefresh.subscribe(value => {
      if (value) {
        this.activeNavLnkTechnical = "rule-definition"
        sessionStorage.removeItem('activeNavLnkTechnical');
      }
    });
    this.activeRoute.queryParamMap.subscribe(params => {
      this.dq_rule_level = params.get('dqRuleLevel');
      this.dataset_id = +params.get('dataset_id');
      this.dataset_column_id = +params.get('dataset_column_id');
      if (params.has('BackTo')) {
        this.backTo = params.get('BackTo');
      }
    });

    if (this.router.url.includes('create-technical-rule')) {
      this.progressState.states = this.zettaUtils.getStateList('create-technical-rules');
      this.progressState.currentStateIndex = 0;
      this.progressState.type = 'create-technical-rules';
      this.createNewRule = true;
      this.rule_selected = this.technicalRules[0];
      this.rule_selected.semantic_objects.forEach((element, i) => {
        this.concepts[i] = this.rule_selected.semantic_objects.filter(x => x.id == 1)[0].concepts;
      });
      this.rule_name = this.rule_selected.name;
      this.rule_description = this.rule_selected.description;
      this.rule_selected.flags = this.rule_selected.flags;
      this.catalogSvc.getDataQualityDimensions().subscribe(res => {
        this.rule_data_quality_dimension = res;
      })
      this.rule_selected.flags = [this.rule_selected.flags[0]];
      this.datasetSvc.getDQDataTypes().subscribe(res => {
        this.dqDataTypes = res;
      })
    }

    this.catalogSvc.getDataQualitySequenceRuleTypes('Technical').subscribe(response => { this.dqSequence = response; }, err => { });
    this.catalogSvc.getDataQualityValueTypes().subscribe(response => { this.dqValueTypes = response; }, err => { });
    this.catalogSvc.getDataQualityOperators().subscribe(response => {
      this.dqOperators = response;
      if (this.technicalRules.length) {
        this.technicalRules[0].flags[0]['dqOperators'] = this.dqOperators
      }
    }, err => { });
    this.catalogSvc.getDataQualityDimensions().subscribe(response => { this.dqDimensions = response; }, err => { });

    this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
      this.datasets = response;      
      if (this.router.url.includes('create-technical-rule')) {
        this.withinDatasets = this.datasets;
        this.lookupDatasets = this.datasets;
      }
      const exitingDataset = this.datasets.filter(ds => ds.dataset_id == this.dataset_id);
      if (this.dq_rule_level == 'dataset') {
        if (exitingDataset.length && this.technicalRules.length) {
          this.technicalRules[0].data_quality_rule_attributes.dataset_id = [];
          this.technicalRules[0].data_quality_rule_attributes.dataset_id.push(
            exitingDataset
          );
          this.withinDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataset_id);
          this.lookupDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataset_id);
          this.selectedDatasetId = this.dataset_id;
          if (this.dataset_column_id) {
            this.technicalRules[0].flags[this.technicalRules[0].flags.length - 1]['concept_id'] = this.dataset_column_id;
            this.technicalRules[0].flags[0].dataset_column_ids = [];
            this.technicalRules[0].flags[0].dataset_column_ids.push(this.dataset_column_id);
            this.dataset_column_ids.push(this.dataset_column_id);
          }
          this.getDatasetColumns(this.dataset_id, null);
        }
      }

      // if(this.dataQualityRule && this.dataQualityRule.data_quality_rule_columns){
      //   this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
      //     this.getDatasetColumns(element.dataset_id, i)
      //   });
      // }
    });
    if (this.rule_id) {
      this.getDataQualityRule();
    }
    this.getRuleDetail();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'dataset_column_id',
      textField: 'dataset_name',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.columnSettings = {
      singleSelection: false,
      idField: 'dataset_column_id',
      textField: 'column',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.columnSettingsDisabled = {
      disabled: true,
      singleSelection: false,
      idField: 'dataset_column_id',
      textField: 'column',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.datasetdropdownSettings = {
      singleSelection: true,
      idField: 'dataset_id',
      textField: 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.datasetColumndropdownSettings = {
      singleSelection: true,
      idField: 'dataset_column_id',
      textField: 'column',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.datasetSettings = {
      idField: 'dataset_id',
      textField: 'name',
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.multiDatasetColumnSettings = {
      singleSelection: false,
      idField: 'dataset_id',
      textField: 'name',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  getDatasets() {
    this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id }).subscribe((res) => {
      this.catalogSvc.getCatalogDatasets({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalogId, pageNo: 1, limit: 20 }).subscribe((datasetsData) => {
        this.data = datasetsData['currentpage'];
        if (this.data.length === 0) {
          // this.noData = true;
        }
        this.data.forEach(element => {
          element.datasource_name = 'Default HDFS Folder';
          element.id = Math.random();
          element.score100 = (element.score * 100).toFixed();
          element.level = element.score100 >= 75 ? 'HIGH' : (element.score100 >= 50 && element.score100 < 75) ? 'MEDIUM' : 'LOW';
        });
        this.data.sort(((a, b) => b.score100 - a.score100));
      }, err => { });
    }, err => { });
  }


  getDataQualityRule() {
    this.withindMultiatasetColumns = [];
    this.datasets = [];
    this.activeNavLnkTechnical = sessionStorage.getItem('activeNavLnkTechnical');
    this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id).subscribe(response => {
      this.dataQualityRule = response;
      this.isRuleLoading = false
      this.total_dqr_dataset_columns = this.dataQualityRule.data_quality_rule_columns ? this.dataQualityRule.data_quality_rule_columns.length : 0;
      this.total_dqr_exceptions = this.dataQualityRule.exception_rules.length;
      this.validateEditForms();
      if (this.dataQualityRule.exception_rules.length) {
        this.dataQualityRule.exception_rules.forEach((element, i) => {
          // (!element.comparison.dataset_column_id || element.comparison.dataset_column_id === undefined || element.comparison.dataset_column_id === null) ? element.comparison_dataset_column_id = 0 : element.comparison_dataset_column_id = parseInt(element.comparison.dataset_column_id[0]);            
          if (element.rule_sequence_type_lookup_id == 14876) {
            element.comparison_join_by = element.comparison.join_condition;
            this.dataQualityRule.exception_rules[i]['comparison_dataset_id'] = element.comparison.dataset_id;
            this.dataQualityRule.exception_rules[i]['comparison_dataset_column_id'] = parseInt(element.comparison.dataset_column_id.length ? element.comparison.dataset_column_id[i] : 0);
            this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = element.comparison.value_type_lookup_id;
            this.dataQualityRule.exception_rules[i]['comparison_calculation'] = element.comparison.calculation;
            this.dataQualityRule.exception_rules[i]['comparision_filter'] = element.comparison.comparision_filter;
          }
          if (element.operator_lookup_id === 14916) {
            element.lower_limit = element.comparison.lower_limit || element.lower_limit;
            element.upper_limit = element.comparison.upper_limit || element.upper_limit;
          }
          this.selectedRuleType = element.rule_sequence_type_lookup_id;

          if (element.rule_sequence_type_lookup_id == 14876 && element.value_type_lookup_id != 14894 &&
            element.value_type_lookup_id != 14893 && element.value_type_lookup_id != 14892 &&
            element.value_type_lookup_id != 14891) {
            this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = true;
            this.dataQualityRule.exception_rules[i]['sequence'] = 2;
          } else {
            this.onChangeValueType(element.value_type_lookup_id, i, false);
          }
          if (element.rule_sequence_type_lookup_id == 14877) {
            element.join_by = element.join_condition;
          }
          this.withindMultiatasetColumns.push([]);
        });
        // this.onChange();
      }
      this.addWithinDatasetsAndColumns();
      this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
        this.datasets = response;
        this.withinDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataQualityRule.data_quality_rule_columns[0].dataset_id);
        this.lookupDatasets = this.datasets.filter(ds => ds.dataset_id != this.dataQualityRule.data_quality_rule_columns[0].dataset_id);
        this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
          this.getDatasetColumns(element.dataset_id, i)
        });
      }, err => { });

      this.datasetSvc.getAllDatasets({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(response => {
        this.datasets = response;
        if (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length) {
          this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
            this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, element.dataset_id, false).subscribe(response => {
              this.ds_col = response;
              if (this.ds_col.length) {
                const columnName = this.ds_col.filter(row => row.dataset_column_id === element.dataset_column_id);
                if (columnName.length) {
                  element.column_name = columnName[0].column;
                }
              }

            }, err => { });
            const datasetNames = this.datasets.filter(row => row.dataset_id === element.dataset_id);
            if (datasetNames.length) {
              element.dataset_name = datasetNames[0].name;
            }
          });
        }
      });

      this.catalogSvc.getDataQualityDimensions().subscribe(respDim => {
        this.dimensionsDQ = respDim;
        const dq_dimension = this.dimensionsDQ.filter(row => row.lookup_id === this.dataQualityRule.dimension_lookup_id);
        if (dq_dimension.length) {
          this.dataQualityRule.dq_dimension = dq_dimension[0].name;
        }
      }, err => { });

      // this.dqSequence = this.rule_sequence_types.filter(item => item.lookup_id !== 14878);    //Hard code
      // console.log(this.dqSequence)

      // this.dqSequence = this.rule_types;    //API is integrated

      // this.catalogSvc.getDataQualitySequenceRuleTypes('Technical').subscribe(response => {
      // if(this.typeRule === "Business Rule") {
      //   this.dqSequence = response.filter(item => item.lookup_id !== 14878);
      // this.dqSequence = response;
      // }, err => { });

      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        ele.rule_sequence_operation = (!ele.rule_sequence_operation || ele.rule_sequence_operation === undefined || ele.rule_sequence_operation === null) ? "AND" : ele.rule_sequence_operation;
        this.catalogSvc.getDataQualitySequenceRuleTypes('Technical').subscribe(respSeq => {
          this.dqSequence = respSeq
          const sequenceTypeName = this.dqSequence.filter(row => row.lookup_id === ele.rule_sequence_type_lookup_id);
          if (sequenceTypeName.length) {
            ele.rule_sequence_type_name = sequenceTypeName[0].name;
          }
          if (sequenceTypeName.length && sequenceTypeName[0].lookup_id === 14876) {
            this._observableService.sendRuleSequenceType = true;
          } else {
            this._observableService.sendRuleSequenceType = false;
          }
        }, err => { });

        this.catalogSvc.getDataQualityValueTypes().subscribe(respVT => {
          this.dataQualityValueTypes = respVT;
          const valuesTypes = this.dataQualityValueTypes.filter(row => row.lookup_id === ele.value_type_lookup_id);
          if (valuesTypes.length) {
            ele.value_type_name = valuesTypes[0].name;
          }

        }, err => { });

        // ele.value_type_name = this.dataQualityValueTypes.filter(row => row.lookup_id === ele.value_type_lookup_id)[0].logical_name;

        this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
          this.dataQualityOperators = respOp;
          this.dataQualityRule.exception_rules[i]['dqOperators'] = this.dataQualityOperators;
          if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
            this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
          } else if (this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN && this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
            let dqvaluesTypes = this.dataQualityOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
            this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
          } else {
            this.dataQualityRule.exception_rules[i]['dqOperators'] = respOp;
          }
          const operators = this.dataQualityOperators.filter(row => row.lookup_id === ele.operator_lookup_id);
          if (operators.length) {
            ele.operator_name = operators[0].name;
          }

        }, err => { });
        this.datasetSvc.getDQDataTypes().subscribe(res => {
          this.dqDataTypes = res;
        })

        this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, ele.dataset_id, false).subscribe(response => {
          this.dataset_col = response;
          if (this.dataset_col.length) {
            const exception_column_name = this.dataset_col.filter(row => ele.dataset_column_id.includes(row.dataset_column_id.toString()));
            if (exception_column_name.length) {
              ele.exception_column_name = exception_column_name[0].column;
            }
            const comparison_dataset_column_name = this.dataset_col.filter(row => ele.comparison.dataset_column_id.includes(row.dataset_column_id.toString()));
            if (comparison_dataset_column_name.length) {
              ele.comparison_dataset_column_name = comparison_dataset_column_name[0].column;
            }
          }
        }, err => { });

      });

      if (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length) {
        this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
          this.getDatasetColumns(element.dataset_id, i)
        });
      }
      this.selectedExceptionColumns = [];
      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        (!ele.join_condition || ele.join_condition === undefined || ele.join_condition === null) ? ele.join_condition = "AND" : ele.join_condition = ele.join_condition;
        (!ele.join_by || ele.join_by === undefined || ele.join_by === null) ? ele.join_by = "" : ele.join_by = ele.join_by;
        (!ele.comparison_join_by || ele.comparison_join_by === undefined || ele.comparison_join_by === null) ? ele.comparison_join_by = "" : ele.comparison_join_by = ele.comparison_join_by;
        (!ele.comparison_dataset_id || ele.comparison_dataset_id === undefined || ele.comparison_dataset_id === null) ? ele.comparison_dataset_id = (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length ? this.dataQualityRule.data_quality_rule_columns[0].dataset_id : null) : ele.comparison_dataset_id = ele.comparison_dataset_id;
        (!ele.comparison.dataset_column_id || ele.comparison.dataset_column_id === undefined || ele.comparison.dataset_column_id === null) ? ele.comparison_dataset_column_id = 0 : ele.comparison_dataset_column_id = parseInt(ele.comparison.dataset_column_id[0]);
        (!ele.comparison_value_type_lookup_id || ele.comparison_value_type_lookup_id === undefined || ele.comparison_value_type_lookup_id === null) ? ele.comparison_value_type_lookup_id = 14879 : ele.comparison_value_type_lookup_id = ele.comparison_value_type_lookup_id;

        // this.getFlagDatasetColumns(ele.dataset_id, i);            
        const selectedColumns = [];
        this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, ele.dataset_id, false).subscribe(response => {
          if (i < this.dataQualityRule.exception_rules.length) {
            if (ele.rule_sequence_type_lookup_id === 14877) {
              ele.lokup_dataset_columns = response;
            }
            this.flagDatasetColumns[i] = response;
            if (response.length && ele.dataset_column_id.length) {
              ele.dataset_column_id.forEach(column_id => {
                const selectedColumn = this.flagDatasetColumns[i].filter(column => column.dataset_column_id == column_id);
                selectedColumns.push(selectedColumn[0])
              });
              this.selectedExceptionColumns[i] = selectedColumns;
              // this.selectedExceptionColumns.push(selectedColumns);
            }
          }
        });

        this.getFlagComparisonDatasetColumns(ele.comparison_dataset_id, i)
      });
    }, err => { });
  }

  addWithinDatasetsAndColumns() {
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      if (element.within_source_id && element.within_list_column_id) {
        const within_dataset_ids = element.within_source_id.split(',');
        const within_dataset_column_ids = element.within_list_column_id.split(',');
        const within_datasets = [];
        const within_column = [];
        element.within_dataset_id = +within_dataset_ids[0];
        element.within_dataset_column_id = +within_dataset_column_ids[0];
        within_dataset_ids.forEach(dataset_id => {
          const single_dataset_id = dataset_id;
          this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] },
            single_dataset_id).subscribe(respColumns => {
              let columnExits = [];
              if (this.withindMultiatasetColumns && this.withindMultiatasetColumns[i] && this.withindMultiatasetColumns[i].length) {
                columnExits = this.withindMultiatasetColumns[i].filter(ds => ds.dataset_id == dataset_id);
              }
              if (!columnExits.length) {
                respColumns.forEach(element => {
                  if (within_dataset_ids.length > 1 && !element.column.includes('.')) {
                    element.column = element.dataset_name.trim() + '.' + element.column;
                  }
                  this.withindMultiatasetColumns[i].push(element);
                });
              }
              const datasetExits = within_datasets.find(ds => ds.dataset_id == single_dataset_id)
              if (!datasetExits) {
                within_datasets.push({
                  dataset_id: parseInt(single_dataset_id),
                  name: respColumns[0].dataset_name.trim()
                });
              }
              within_dataset_column_ids.forEach(column_id => {
                const curr_dataset_column = this.withindMultiatasetColumns[i].find(ds => ds.dataset_column_id == column_id);
                const selectedColumnExist = within_column.find(col => col.dataset_column_id == column_id)
                if (curr_dataset_column && !selectedColumnExist) {
                  within_column.push({
                    dataset_column_id: +column_id,
                    column: curr_dataset_column.column
                  });
                }
              });
              if (within_datasets.length && within_dataset_ids.length == within_datasets.length) {
                this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];
                element.data_set_name = within_datasets;
              }
              if (within_column.length && within_column.length == within_dataset_column_ids.length) {
                element.column = within_column;
              }
            });
        });
      }
    });
  }

  getDatasetColumns(datasetId, i) {
    if (datasetId > 0) {
      this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
        if (i != undefined && i != null) {
          this.dataset_columns[i] = response;
        } else {
          this.dataset_columns = response;
          if (this.dq_rule_level == 'dataset' && this.dataset_id && this.dataset_column_id) {
            const exitingDatasetColumn = this.dataset_columns.filter(ds => ds.dataset_column_id == this.dataset_column_id);
            if (exitingDatasetColumn.length && this.technicalRules.length) {
              this.technicalRules[0].data_quality_rule_attributes.dataset_column_id = [];
              this.technicalRules[0].data_quality_rule_attributes.dataset_column_id = exitingDatasetColumn;
              this.technicalRules[0].flags[0].concept_id = exitingDatasetColumn;
            }
          }
        }
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.dataset_columns = [];
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }
  appendDatasetNameToColumns(i) {
    if ((this.rule_selected && this.rule_selected.flags.length && this.rule_selected.flags[i].data_set_name.length > 1) ||
      (this.dataQualityRule.exception_rules && this.dataQualityRule.exception_rules.length &&
        this.dataQualityRule.exception_rules[i].data_set_name && this.dataQualityRule.exception_rules[i].data_set_name.length > 1)) {
      this.withindMultiatasetColumns[i].forEach(element => {
        if (!element.column.includes('.')) {
          element.column = element.dataset_name.trim() + '.' + element.column;
        }
      });
    }
  }

  getIsWithinDatasetColumns(datasetId, i) {
    if (datasetId > 0) {
      this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
        response.forEach(element => {
          this.withindMultiatasetColumns[i].push(element);
        });
        this.appendDatasetNameToColumns(i)
        if (i != undefined && i != null) {
          this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];               
        } else {
          this.withinDatasetColumns = this.withindMultiatasetColumns[i];          
        }
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.withinDatasetColumns = [];
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }
  onChangeWithinDataset(dataset, i) {
    this.withinDatasetColumns[i] = [];
    this.dataQualityRule.exception_rules[i].comparison.within_source_id = dataset.dataset_id;
    this.getIsWithinDatasetColumns(dataset.dataset_id, i);
    this.dataQualityRule.exception_rules[i].column = [];
    this.onChange();
  }
  onDeSelectWithinDatasetNameChange(dataset, i) {
    if (this.withindMultiatasetColumns[i].length) {
      this.withindMultiatasetColumns[i] = this.withindMultiatasetColumns[i].filter(col => col.dataset_name != dataset.name);
      this.withinDatasetColumns[i] = this.withindMultiatasetColumns[i];
      this.removeDatasetNameFromColumn(i);
    } else {
      this.withinDatasetColumns[i] = [];
    }
    this.dataQualityRule.exception_rules[i].column = [];
    this.onChange()
  }
  onChangeWithinDatasetColumn(colum, i) {
    this.dataQualityRule.exception_rules[i].comparison.within_list_column_id = colum.dataset_column_id;
    this.onChange();
  }
  onEditSelectLookupDataset(datasetId, i) {
    this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId, false).subscribe(response => {
      this.dataQualityRule.exception_rules[i].lokup_dataset_columns = response;
      if (response.length) {
        this.dataQualityRule.exception_rules[i].dataset_column_id[0] = response[0].dataset_column_id;
      }
    }, error => {
      if (error.status === 404 || error.error.message == 'Not found!') {
        this.withColumns = [];
        this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
      }
    });
    this.onChange();
  }
  onEditSelectLookupColumn(columnId, i) {
    this.dataQualityRule.exception_rules[i].dataset_column_id[0] = columnId;
    this.onChange();
  }
  getFlagDatasetColumns(datasetId, i) {
    // this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
    if (datasetId > 0) {
      this.datasetSvc.getDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId, false).subscribe(response => {
        if (response && response.length) {
          response.forEach(element => {
            element.isChecked = false;
          });
        }
        if (i !== null) {
          this.flagDatasetColumns[i] = response;
        } else {
          this.colAdd = response;
        }
        // }, err => { });
      })
    }
  }

  getWithInDatasetColumns(datasetId, i) {
    this.datasets.forEach(dataset => {
      if (dataset.name === datasetId) {
        this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, dataset.dataset_id).subscribe(response => {
          this.withColumns = response;
        }, err => {
          if (err.status === 404 || err.error.message == 'Not found!') {
            this.withColumns = [];
            this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
          }
        });
      }
    })
  }


  getFlagComparisonDatasetColumns(datasetId, i) {
    if (datasetId > 0) {
      this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
        if (this.selectedExceptionColumns && this.selectedExceptionColumns.length) {
          // this.selectedExceptionColumns.forEach(column => {
          response.forEach(element => {
            element.isDisabled = false;
            // if(column.dataset_column_id == element.dataset_column_id){
            //  element.isDisabled = true;
            // }
          });
          // });
        }
        if (i !== null) {
          this.flagComparisonDatasetColumns[i] = response;//['currentpage'];
        } else {
          this.colAdd = this.attributes;
        }
      })
    }
  }
  validateEditForms() {
    if (this.dataQualityRule.rule_name && this.dataQualityRule.dimension_lookup_id && this.dataQualityRule.exception_rules.length) {
      this.isFormValidated = true;
      this.dataQualityRule.exception_rules.forEach(flag => {
        if (flag.rule_sequence_type_lookup_id && flag.operator_lookup_id) {
          if (flag.rule_sequence_type_lookup_id === 14875 || flag.rule_sequence_type_lookup_id === 14877) { // single column and single lookup coloum
            if (flag.dataset_column_id.length) {
              this.isFormValidated = true;
            } else {
              this.isFormValidated = false;
              return;
            }
            if (flag.operator_lookup_id === 14916) {
              if (flag.lower_limit && flag.upper_limit) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
            if (flag.operator_lookup_id === 14904 || flag.operator_lookup_id === 14907 ||
              flag.operator_lookup_id === 14902 || flag.operator_lookup_id === 14903 ||
              flag.operator_lookup_id === 14908 || flag.operator_lookup_id === 14909 ||
              flag.operator_lookup_id === 14914 || flag.operator_lookup_id === 14915 ||
              flag.operator_lookup_id === 14912 || flag.operator_lookup_id === 14913 ||
              flag.operator_lookup_id === 14910 || flag.operator_lookup_id === 14911 ||
              flag.operator_lookup_id === 14901 || flag.operator_lookup_id === 14900) {
              if (!flag.threshold) {
                this.isFormValidated = false;
                return;
              } else {
                this.isFormValidated = true;
              }
            }
            if (flag.value_type_lookup_id == 14895) {
              if (flag.calculation) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
            if (flag.rule_sequence_type_lookup_id === 14875 && flag.operator_lookup_id === 14919 || flag.operator_lookup_id === 14920) {
              if (flag.data_set_name && flag.data_set_name.length && flag.column && flag.column.length) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
            if (flag.value_type_lookup_id === 14952 && flag.operator_lookup_id === 14903 || flag.operator_lookup_id === 14902) {
              if (flag.threshold) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
            if (flag.rule_sequence_type_lookup_id === 14877) {
              if (flag.join_by) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
          }
          if (flag.rule_sequence_type_lookup_id === 14876) {  // multi column
            if (flag.dataset_column_id.length) {
              this.isFormValidated = true;
            } else {
              this.isFormValidated = false;
              return;
            }
            if (flag.comparison_dataset_id && flag.comparison_dataset_column_id) {
              this.isFormValidated = true;
            } else {
              this.isFormValidated = false;
              return;
            }
            if (this.dataQualityRule.data_quality_rule_columns[0].dataset_id !== flag.comparison_dataset_id) {
              if (flag.comparison_join_by) {
                this.isFormValidated = true;
              } else {
                this.isFormValidated = false;
                return;
              }
            }
          }
        } else {
          this.isFormValidated = false;
          return;
        }


      });
    } else {
      this.isFormValidated = false;
      return;
    }
  }
  onChange() {
    this.validateEditForms();
    this.dqr_dataset_columns = [];
    if (this.dataQualityRule.data_quality_rule_columns && this.dataQualityRule.data_quality_rule_columns.length) {
      this.dataQualityRule.data_quality_rule_columns.forEach(ele => {
        //  dqr_dataset_columns.push({dataset_id: ele.dataset_id[0].dataset_id, dataset_column_id: ele.dataset_column_id[0].dataset_column_id})
        this.dqr_dataset_columns.push({ dataset_id: ele.dataset_id, dataset_column_id: ele.dataset_column_id })
      });
    }
    this.dqr_exceptions = [];
    this.dataQualityRule.exception_rules.forEach((ele, i) => {
      // ele.selectedExceptionColumn = this.selectedExceptionColumns;
      // if(ele.dataset_column_id>0){        
      this.dqr_exceptions.push({
        rule_sequence_type_lookup_id: ele.rule_sequence_type_lookup_id,
        value_type_lookup_id: ele.value_type_lookup_id,
        dataset_id: ele.dataset_id,
        dataset_column_id: ele.dataset_column_id ? ele.dataset_column_id.toString() : ele.dataset_column_id,
        operator_lookup_id: ele.operator_lookup_id,
        threshold: ele.threshold ? ele.threshold : null,
        ...(ele.operator_lookup_id === 14916 && ele.rule_sequence_type_lookup_id !== 14876) ? { lower_limit: ele.lower_limit } : {},
        ...(ele.operator_lookup_id === 14916 && ele.rule_sequence_type_lookup_id !== 14876) ? { upper_limit: ele.upper_limit } : {},
        ...(ele.rule_sequence_type_lookup_id == 14877) ? { join_condition: ele.join_by } : {},
        ...(this.dataQualityRule.exception_rules.length > 1 && i < this.dataQualityRule.exception_rules.length - 1) ? { rule_sequence_operation: ele.rule_sequence_operation } : {},
        ...(ele.value_type_lookup_id == 14895) ? { calculation: ele.calculation } : {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? { within_source_id: ele.data_set_name && ele.data_set_name.length ? (ele.data_set_name.map(ds => ds.dataset_id)).toString() : [] } : {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? { within_list_column_id: ele.column && ele.column.length ? (ele.column.map(ds => ds.dataset_column_id)).toString() : [] } : {},
        ...(ele.operator_lookup_id === 14919 || ele.operator_lookup_id === 14920) ? { within_filter: ele.within_filter } : {},
        ...(ele.rule_sequence_type_lookup_id === 14877) ? { comparision_filter: ele.comparision_filter } : {},
        comparison: {
          ...(ele.rule_sequence_type_lookup_id == 14876) ? { dataset_id: ele.comparison_dataset_id } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876) ? { dataset_column_id: ele.comparison_dataset_column_id ? ele.comparison_dataset_column_id.toString() : ele.comparison_dataset_column_id } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876) ? { value_type_lookup_id: ele.comparison_value_type_lookup_id } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876) ? { join_condition: ele.comparison_join_by } : {},
          ...(ele.comparison_value_type_lookup_id == 14895) ? { calculation: ele.comparison_calculation } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876) ? { comparision_filter: ele.comparision_filter } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876 && ele.operator_lookup_id === 14916) ? { lower_limit: ele.lower_limit } : {},
          ...(ele.rule_sequence_type_lookup_id == 14876 && ele.operator_lookup_id === 14916) ? { upper_limit: ele.upper_limit } : {},
        }
      })
      // }
    });

    this.total_dqr_exceptions = this.dataQualityRule.exception_rules ? this.dataQualityRule.exception_rules.length : 0;
    let payload = {
      "rule_name": this.dataQualityRule.rule_name,
      "rule_description": this.dataQualityRule.rule_description,
      "dimension_lookup_id": this.dataQualityRule.dimension_lookup_id,
      "data_quality_rule_columns": this.dqr_dataset_columns,
      "exception_rules": this.dqr_exceptions
    }

    this.payloadForEditRule = payload;
    // let selectedColumnId ="";
    // if(this.selectedExceptionColumns && this.selectedExceptionColumns.length){
    //   this.selectedExceptionColumns.forEach(element => {
    //     selectedColumnId += element.dataset_column_id +",";
    //   });
    //   selectedColumnId =selectedColumnId.substring(0,selectedColumnId.length-2);
    // }
    // if(!this.createNewRule) {
    //   this.catalogSvc.updateBusinessDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, payload).subscribe(response => {
    //     console.log(response)
    //   }, err => { });
    // }
  }

  updateRuleDefination() {
    const isObjectEmpty = (objectName) => {
      return Object.keys(objectName).length === 0
    }
    this.onChange();
    let atLeastOnePrimaryColumn: any = [];
    if(isObjectEmpty(this.payloadForEditRule)) {
      atLeastOnePrimaryColumn = this.dataQualityRule['exception_rules'].filter(col => col.dataset_column_id[0]  == '' + this.dataQualityRule['data_quality_rule_columns'][0].dataset_column_id);
     // this.onChange();
    } else {
      atLeastOnePrimaryColumn = this.payloadForEditRule['exception_rules'].filter(col => col.dataset_column_id.includes('' + this.payloadForEditRule['data_quality_rule_columns'][0].dataset_column_id));
    }

    if(atLeastOnePrimaryColumn.length) {
      this.catalogSvc.updateTechnicalDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, this.payloadForEditRule).subscribe(response => {
        this.messageSvc.sendMessage({ message: 'Changes saved successfully.', type: 'SUCCESS', hideboard: true });
        this.payloadForEditRule = {};
        setTimeout(() => {
          this.router.navigate(['/zs-cl/dataquality']);
        }, 300);
      }, err => {
        this.messageSvc.sendMessage({ message: err.error[0].message, type: 'ERROR', hideboard: true });
      });
    } else {
      this.messageSvc.sendMessage({ message: AppGlobals.RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_COLUMN, type: 'INFO', hideboard: true });
    }
  }

  cancelRuleDefination() {
    this.catalogSvc.isEditView.next(false);
    if (this.rule_id) {
      this.getDataQualityRule();
    }
  }

  onReRunRule() {
    this.updateRunExecutor(true);
    this.catalogSvc.reRunRule(this.rule_id, 'technical').subscribe(resp => {
      this.messageSvc.sendMessage({
        message: '', type: 'Info', hideInfo: true, activeJobs: 'DataQuality',
        messageHeader: AppGlobals.RULES_MSG_HEADER, messageText: AppGlobals.RULES_MSG_TEXT,
      });
    });
  }
  updateRunExecutor(is_run_executor) {
    const data = {
      rule_id: this.rule_id,
      payload: {
        run_executor: is_run_executor
      }
    };
    this.catalogSvc.updateRunExecutor(data).subscribe(resp => {
    }, error => { });
  }
  onSelectDataSet(item: any, i) {
    this.getDatasetColumns(item.dataset_id, i)
  }
  onSelectLookupDataSet(dataset: any, i) {
    this.selectedLookupDatasetId = dataset.dataset_id;
    const datasetItem = [{
      dataset_id: dataset.dataset_id
    }];
    this.getLookUpColumns(datasetItem, i)
    this.onChange();
  }
  onSelectLookupDataSetColumn(data, ind) {
    let updated_col = [];
    let selected_column = this.rule_selected.flags[ind].lookup_concept_id.slice();
    selected_column.forEach(col => {
      const truncated_column =this.getFormattedDatasetAndColumnName(col.column, 21);
      updated_col.push({ dataset_column_id: col.dataset_column_id, column: truncated_column });
    });
    this.rule_selected.flags[ind].lookup_concept_id = updated_col;

    this.validateFlags();
  }
  onDeSelectLookupDataSet(ds, ind) {
    this.lokup_dataset_columns = [];
    this.rule_selected.flags[ind].lookup_concept_id = [];
    this.validateFlags();
  }
  onDeSelectLookupColumn(ds, ind) {
    this.rule_selected.flags[ind].lookup_concept_id = [];
    this.validateFlags();
  }

  onChangeAtribute(datasetId, i) {
    this.getDSColumns(datasetId, i)
    if (i !== null) {
      this.dataQualityRule.data_quality_rule_columns[i].dataset_id = datasetId;
    } else {
      this.dataQualityRule.data_quality_rule_columns.dataset_id = datasetId;
    }
    this.onChange();

  }

  getDSColumns(datasetId, i) {
    if (datasetId > 0) {
      this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId).subscribe(response => {
        if (i) {
          this.dataset_columns[i] = response;
        } else {
          this.colAdd = response;
          this.dataset_columns = response;         
        }
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.dataset_columns = [];
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }

  onChangeColumn(columnId, i) {
    if (i !== null) {
      this.dataQualityRule.data_quality_rule_columns[i].dataset_column_id = columnId;
    } else {
      this.dataQualityRule.data_quality_rule_columns.dataset_column_id = columnId;
    }
    this.onChange();
  }

  onChangeComparisonDataset(dataset, i) {
    this.selectedComparisonDatasetId = dataset
    this.getFlagComparisonDatasetColumns(dataset, i);
    this.dataQualityRule.exception_rules[i].comparison_dataset_id = dataset;
    this.dataQualityRule.exception_rules[i].comparison_dataset_column_id = null;
    this.onChange();
  }

  getFlagCOmpDatasetColumns(datasetId, i) {
    if (datasetId.dataset_id) {
      this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, datasetId.dataset_id).subscribe(response => {
        this.comparisonDatasetColumns = response;        
      }, err => {
        if (err.status === 404 || err.error.message == 'Not found!') {
          this.comparisonDatasetColumns = [];
          this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
        }
      });
    }
  }

  onChangeValueType(valueTypeId, i, isloading = false) {
    this.dataQualityRule.exception_rules[i].value_type_lookup_id = valueTypeId;   //Multi column
    this.dqValueTypes.forEach(rule => {
      if (rule.lookup_id == valueTypeId) {
        this.dataQualityRule.exception_rules[i]['value_type'] = rule['name'];
        this.dataQualityRule.exception_rules[i]['value_type_name'] = rule['name'];
      }
    })

    if (this.dataQualityRule.exception_rules[i]['value_type'] == 'sum between' || this.dataQualityRule.exception_rules[i]['value_type'] == 'difference between'
      || this.dataQualityRule.exception_rules[i]['value_type'] == 'product between'
      || this.dataQualityRule.exception_rules[i]['value_type'] == 'quotient between') {
      this.dataQualityRule.exception_rules[i]['sequence'] = '2';
      //this.dataQualityRule.exception_rules[i]['operator_lookup_id'] = null;
      this.isBetweenSelected = true;
      if (this.selectedRuleType == '14876') {
        this.isMultiAttribute = true;
        this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = true;
      }
      this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] = 14876;
      this.ruleTypeCallByValueType(14876, i);
    } else {
      if (this.isBetweenSelected) {
        this.isBetweenSelected = false;
        this.dataQualityRule.exception_rules[i]['sequence'] = 1;
        this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] = 14875;
        this.ruleTypeCallByValueType(14875, i);
      }
    }
    this.dataQualityRule.exception_rules[i]['selected_comparison_value_type_name'] = '';
    if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
      this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
    } else if (this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN && this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
      this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
    } else {
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.dqOperators = respOp;
        this.dataQualityRule.exception_rules[i]['dqOperators'] = this.dqOperators;
      });
    }
    if (isloading && this.dataQualityRule.exception_rules[i]['value_type'] === 'datatype' || this.dataQualityRule.exception_rules[i]['prev_selected_value_type'] === 'datatype') {
      this.dataQualityRule.exception_rules[i]['threshold'] = null;
    }
    this.dataQualityRule.exception_rules[i]['prev_selected_value_type'] = this.dataQualityRule.exception_rules[i]['value_type']
    if (isloading) {
      this.onChange();
    }
  }

  onChangeOperator(operator_lookup_id, i) {
    this.selectedOperator = operator_lookup_id;
    this.dataQualityRule.exception_rules[i].operator_lookup_id = operator_lookup_id;
    this.dqOperators.forEach(operator => {
      if (operator['lookup_id'] == operator_lookup_id) {
        this.dataQualityRule.exception_rules[i]['operator_name'] = operator['name'];
      }
    });
    if (this.dataQualityRule.exception_rules[i]['operator_name'] == 'is within' || this.dataQualityRule.exception_rules[i]['operator_name'] == 'is not within') {
      this.dataQualityRule.exception_rules[i]['sequence'] = 1;
      this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] = 14875;
      this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] = 14879;
      this.ruleTypeCallByValueType(14875, i);
    }


    this.onChange();
  }
  onChangeComparisonValueType(valueTypeId, i) {
    this.dataQualityRule.exception_rules[i].comparison_value_type_lookup_id = valueTypeId;   //value
    this.onChange();
  }

  onChangeFlagRuleSequenceType(id, i) {
    this.selectedColumnType = id;
    this.selectedRuleType = id;
    this.dataQualityRule.exception_rules[i].rule_sequence_type_lookup_id = id;   //value
    this.dataQualityRule.exception_rules[i]['isMultiAttribute'] = id == '14876' ? true : false;
    this.dataQualityRule.exception_rules[i]['selected_rule_type'] = id;
    this.dataQualityRule.exception_rules[i].sequence = id == 14876 ? 2 : id == 14877 ? 3 : 1;

    if ((this.dataQualityRule.exception_rules[i]['operator_lookup_id'] == 14919 && id == 14876) ||
      (this.dataQualityRule.exception_rules[i]['operator_lookup_id'] == 14920 && id == 14876) || id == 14877) {
      this.dataQualityRule.exception_rules[i]['operator_lookup_id'] = null;
    }
    if (id === 14876) {
      this.dataQualityRule.exception_rules[i]['operator_lookup_id'] = null;
    }
    if (this.dataQualityRule.exception_rules[i]['rule_sequence_type_lookup_id'] == 14876) {
      this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = '14879';
      if (this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
        let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
        this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
      } else {
        let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
        this.dataQualityRule.exception_rules[i]['dqOperators'] = dqvaluesTypes;
      }
    } else {
      this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = '';
      this.dataQualityRule.exception_rules[i]['value_type_lookup_id'] = '14879';
      this.catalogSvc.getDataQualityOperators().subscribe(response => { this.dqOperators = response; }, err => { });
      this.dataQualityRule.exception_rules[i]['dqOperators'] = this.dqOperators;
    }

    this.onChange();
  }
  ruleTypeCallByValueType(id, i) {
    this.selectedRuleType = id;
    this.dataQualityRule.exception_rules[i].comparison_attribute_id = null;
    this.dataQualityRule.exception_rules[i].rule_sequence_type_lookup_id = id;   //value

    this.dataQualityRule.exception_rules[i].selected_rule_type = id;
    this.dataQualityRule.exception_rules[i]['selected_comparison_value_type_lookup_id'] = '';
    this.dataQualityRule.exception_rules[i]['comparison_value_type_lookup_id'] = 14879;
    this.onChange();
  }

  onTabClick(activeLink: string) {
    this.activeNavLnkTechnical = activeLink;
    sessionStorage.setItem('activeNavLnkTechnical', activeLink);
    this.catalogSvc.showActiveLink.next(activeLink);
    if (activeLink === 'applied-columns') {
      this.getDatasets();
    }
  }

  add() {
    if (this.dqDatasetIdAdded && this.dqDatasetColumnIdAdded) {
      // this.dataQualityRule.data_quality_rule_columns.push(
      //   { 
      //     dataset_id: this.dqDatasetIdAdded[0].dataset_id ,
      //     dataset_column_id: this.dqDatasetColumnIdAdded[0].dataset_column_id,
      //     selectedDatatset:this.dqDatasetIdAdded,
      //     selectedDatasetCoulmns : this.dqDatasetColumnIdAdded 

      //   });
      this.dataQualityRule.data_quality_rule_columns.push({ dataset_id: this.dqDatasetIdAdded, dataset_column_id: this.dqDatasetColumnIdAdded });
      this.onChange();
      this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
        this.getDatasetColumns(element.dataset_id, i)
        this.colAdd = [];
      });
      this.dqDatasetIdAdded = null;
      this.dqDatasetColumnIdAdded = null;
    }
  }

  delete(row) {
    this.dataQualityRule.data_quality_rule_columns.splice(row, 1);
    this.onChange();
    this.dataQualityRule.data_quality_rule_columns.forEach((element, i) => {
      this.getDatasetColumns(element.dataset_id, i);
    });
  }

  addFlag(row) {
    let newAttribute = {
      // dataset_id: row > 0 ? this.dataQualityRule.exception_rules[row].dataset_id : this.dataQualityRule.data_quality_rule_columns[0].dataset_id, 
      // dataset_column_id: row > 0 ? this.dataQualityRule.exception_rules[row].dataset_column_id : this.dataQualityRule.data_quality_rule_columns[0].dataset_column_id,
      dataset_id: this.dataQualityRule.data_quality_rule_columns[0].dataset_id,
      dataset_column_id: [],//this.selectedExceptionColumns.length ? this.dataQualityRule.data_quality_rule_columns[0].dataset_column_id:0,
      calculation: null,
      lower_limit: null,
      upper_limit: null,
      operator_lookup_id: null, //this.selectedOperator ?this.selectedOperator :14906,
      rule_sequence_type_lookup_id: 14875,
      threshold: "",
      value_type_lookup_id: 14879,
      within_list_column_id: null,
      within_source_id: null,
      lookup_dataset_id: null,
      within_filter: null,
      lookup_filter: '',
      lokup_dataset_columns: [],
      prev_selected_value_type: '',
      join_by: '',
      comparison_dataset_id: this.selectedComparisonDatasetId ? this.selectedComparisonDatasetId : this.dataQualityRule.data_quality_rule_columns[0].dataset_id,
      comparison_dataset_column_id: this.selectedComparisonColumnId ? this.selectedComparisonColumnId : 0,
      comparison_value_type_lookup_id: this.selectedLookupDatasetId ? this.selectedLookupDatasetId : 14879,
      comparison_join_by: '',
      join_condition: "AND",
      dataset_column_ids: [],
      dqOperators: this.dqOperators,
      comparison: {
        within_list_column_id: null,
        within_source_id: null,
        within_filter: null,
        comparision_filter: '',
        within_dataset_id: '',
        within_dataset_column_id: '',
      }
    };
    this.dataQualityRule.exception_rules.splice(row + 1, 0, newAttribute);
    this.onChange();
    this.withindMultiatasetColumns.push([]);
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      this.getFlagDatasetColumns(element.dataset_id, i)
      this.getFlagComparisonDatasetColumns(element.comparison_dataset_id, i)
      this.colAdd = [];
    });
    // this.selectedExceptionColumns = null;
    // console.log(this.dataQualityRule);
  }

  deleteFlag(row) {
    this.dataQualityRule.exception_rules.splice(row, 1);
    this.onChange();
    this.dataQualityRule.exception_rules.forEach((element, i) => {
      this.getFlagDatasetColumns(element.dataset_id, i);
      this.getFlagComparisonDatasetColumns(element.comparison_dataset_id, i)
    });
    this.validateFlags();
  }

  mapSemantics() {
    if (this.technicalRules[0].data_quality_rule_attributes['dataset_id'] && this.technicalRules[0].data_quality_rule_attributes['dataset_column_id']) {
      let combined = this.technicalRules[0].data_quality_rule_attributes['dataset_id'].map((item, index) => {
        return {
          dataset_id: item.dataset_id,
          dataset_column_id: this.technicalRules[0].data_quality_rule_attributes['dataset_column_id'][index].dataset_column_id
        }
      })
      return combined;
    }
  }

  onRuleTypeChange(val, ind) {
    this.selectedRuleType = val;
    this.isMultiAttribute = val == '14876' ? true : false;
    this.technicalRules[0].flags[ind]['isMultiAttribute'] = val == '14876' ? true : false;
    this.technicalRules[0].flags[ind]['selected_rule_type'] = val;

    this.dqSequence.forEach(rule => {
      if (rule.lookup_id == val) {
        this.technicalRules[0].flags[ind]['name'] = rule['name'];
        this.technicalRules[0].flags[ind]['sequence'] = rule['sequence'];
        this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = val;
      }
    });
    if ((this.technicalRules[0].flags[ind]['operator_lookup_id'] == 14919 && val == 14876) ||
      (this.technicalRules[0].flags[ind]['operator_lookup_id'] == 14920 && val == 14876) || val == 14877) {
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null;
      this.technicalRules[0].flags[ind]['operator_name'] = null;
    }
    if (this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] == 14876) {
      this.technicalRules[0].flags[ind]['comparison_value_type_lookup_id'] = '14879';
      if (this.technicalRules[0].flags[ind]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
        let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
        this.technicalRules[0].flags[ind]['dataQualityOperators'] = dqvaluesTypes;
      } else {
        let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
        this.technicalRules[0].flags[ind]['dqOperators'] = dqvaluesTypes;
      }
    } else {
      this.technicalRules[0].flags[ind]['comparison_value_type_lookup_id'] = '';
      this.technicalRules[0].flags[ind]['value_type_lookup_id'] = '14879';
      this.catalogSvc.getDataQualityOperators().subscribe(response => { this.dqOperators = response; }, err => { });
      this.technicalRules[0].flags[ind]['dqOperators'] = this.dqOperators;
    }

    if (this.operators.filter(operator => operator.lookup_id == this.technicalRules[0].flags[ind]['operator_lookup_id']).length && this.technicalRules[0].flags[ind]['operator_lookup_id'] != null) {
      this.enableNext = true;
    } else {
      this.enableNext = false;
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null;
      this.technicalRules[0].flags[ind]['operator_name'] = null;
    }
    this.validateFlags();
    this.technicalRules[0].flags[ind]['selected_comparison_value_type_name'] = '';
  }

  //this will call when we chnage value type
  onSelectValueChange(val, ind) {
    this.selectedRuleType = val;
    this.technicalRules[0].flags[ind]['selected_rule_type'] = val;
    this.dqSequence.forEach(rule => {
      if (rule.lookup_id == val) {
        this.technicalRules[0].flags[ind]['name'] = rule['name'];
        this.technicalRules[0].flags[ind]['sequence'] = rule['sequence'];
        this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = val;
      }
    });
    if (val == 14876) {
      this.technicalRules[0].flags[ind]['comparison_value_type_lookup_id'] = '14879'
    } else {
      this.technicalRules[0].flags[ind]['comparison_value_type_lookup_id'] = ''
    }
    this.validateFlags();
  }
  onValueTypeChange(val, ind) {
    if (val == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      // resetting below values for form validation purposes
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null; //threshold
      this.technicalRules[0].flags[ind]['operator_name'] = null;
      this.technicalRules[0].flags[ind]['threshold'] = null;
    }

    this.dqValueTypes.forEach(rule => {
      if (rule.lookup_id == val) {
        this.technicalRules[0].flags[ind]['value_type'] = rule['name'];
        this.technicalRules[0].flags[ind]['value_type_name'] = rule['name'];
      }
    })

    if (this.technicalRules[0].flags[ind]['value_type'] == 'sum between' || this.technicalRules[0].flags[ind]['value_type'] == 'difference between'
      || this.technicalRules[0].flags[ind]['value_type'] == 'product between'
      || this.technicalRules[0].flags[ind]['value_type'] == 'quotient between') {
      this.technicalRules[0].flags[ind]['sequence'] = '2';
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null;
      this.technicalRules[0].flags[ind]['operator_name'] = null;
      this.isBetweenSelected = true;
      if (this.selectedRuleType == '14876') {
        this.isMultiAttribute = true;
        this.technicalRules[0].flags[ind]['isMultiAttribute'] = true;
      }
      this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14876;
      this.onSelectValueChange(14876, ind);
    } else if (this.technicalRules[0].flags[ind]['value_type'] == 'datatype') {
      this.onOperatorChange('datatype', ind);
    } else {
      if (this.isBetweenSelected) {
        this.isBetweenSelected = false;
        this.technicalRules[0].flags[ind]['sequence'] = 1;
        this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14875;
        this.onSelectValueChange(14875, ind);
      }
    }
    this.technicalRules[0].flags[ind]['selected_comparison_value_type_name'] = '';
    if (this.technicalRules[0].flags[ind]['value_type_lookup_id'] == AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == AppGlobals.DQ_OPERATOR_EQUALS || row.lookup_id == AppGlobals.DQ_OPERATOR_DOES_NOT_EQUAL);
      this.technicalRules[0].flags[ind]['dqOperators'] = dqvaluesTypes;
    } else if (this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] == AppGlobals.DQ_SEQUENCE_TYPE_IS_MULTI_COLUMN && this.technicalRules[0].flags[ind]['value_type_lookup_id'] != AppGlobals.DQ_VALUE_TYPE_IS_DATATYPE) {
      let dqvaluesTypes = this.dqOperators.filter(row => row.lookup_id == 14902 || row.lookup_id == 14903 || row.lookup_id == 14904 || row.lookup_id == 14907 || row.lookup_id == 14908 || row.lookup_id == 14909);
      this.technicalRules[0].flags[ind]['dqOperators'] = dqvaluesTypes;
    } else {
      this.catalogSvc.getDataQualityOperators().subscribe(respOp => {
        this.dqOperators = respOp;
      });
      this.technicalRules[0].flags[ind]['dqOperators'] = this.dqOperators;
    }
    this.validateFlags();
  }

  onCompValueTypeChange(val, ind) {
    this.dqValueTypes.forEach(rule => {
      if (rule.lookup_id == val) {
        this.technicalRules[0].flags[ind]['comparison_value_type_lookup_id'] = rule['lookup_id'];
        this.technicalRules[0].flags[ind]['selected_comparison_value_type_name'] = rule['name'];
      }
    });
    this.validateFlags();
  }

  onOperatorChange(val, ind) {
    this.dqOperators.forEach(operator => {
      if (operator['lookup_id'] == val) {
        this.technicalRules[0].flags[ind]['operator_name'] = operator['name'];
      }
    });
    if (this.technicalRules[0].flags[ind]['operator_name'] == 'is within' || this.technicalRules[0].flags[ind]['operator_name'] == 'is not within') {
      this.technicalRules[0].flags[ind]['sequence'] = 1;
      this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = 14875;
      this.technicalRules[0].flags[ind]['value_type_lookup_id'] = 14879;
      this.onSelectValueChange(14875, ind);
    }
    if ((this.technicalRules[0].flags[ind]['operator_lookup_id'] == 14919 && val == 14876) ||
      (this.technicalRules[0].flags[ind]['operator_lookup_id'] == 14920 && val == 14876) || val == 14877) {
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null;
      this.technicalRules[0].flags[ind]['operator_name'] = null;
    }
    if (val == "null") {
      this.enableNext = false;
      this.technicalRules[0].flags[ind]['operator_lookup_id'] = null;
      this.technicalRules[0].flags[ind]['operator_name'] = null;
    } else {
      this.enableNext = true;
    }
    this.validateFlags();
  }

  selectDq(val) {
    this.rule_data_quality_dimension.forEach(operator => {
      if (operator.lookup_id === val) {
        this.technicalRules[0]['dqDimName'] = operator['name'];
        // this.technicalRules[0]['dimension_lookup_id'] = operator['lookup_id'];
        this.technicalRules[0]['dimSequence'] = operator['sequence'];
      }
    });
    this.validateFlags();
  }

  onSemanticChange(val, ind) {
    const truncated_dataset = this.getFormattedDatasetAndColumnName(val.name, 26);
    const selected_dataset = [{ dataset_id: val.dataset_id, name: truncated_dataset }];
    this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0] = selected_dataset;
    if (val.data_set_Id !== this.selectedDatasetId) {
      this.technicalRules[0]['data_quality_rule_attributes'].dataset_column_id = [];
      this.technicalRules[0].flags[this.technicalRules[0].flags.length - 1]['concept_id'] = [];
      this.dataset_column_ids = [];
    }

    if (this.technicalRules[0].flags[0]['rule_sequence_type_lookup_id'] == 14877 &&
     this.technicalRules[0].flags[0]['selectedLookupDatasetId'] && this.technicalRules[0].flags[0]['selectedLookupDatasetId'].length &&
     this.technicalRules[0].flags[0]['selectedLookupDatasetId'][0]['dataset_id'] == val.dataset_id) {
      this.technicalRules[0].flags[0]['lookup_concept_id'] = [];
      this.technicalRules[0].flags[0]['selectedLookupDatasetId'] = [];
      this.lookupDatasets = [];
      this.lokup_dataset_columns = [];
    }
    this.withinDatasets = this.datasets;
    this.lookupDatasets = this.datasets.filter(ds => ds.dataset_id != val.dataset_id);
    this.selectedDatasetId = val.dataset_id;
    this.getDatasetColumns(val.dataset_id, ind);
    this.validateFlags();
  }

  onDatasetNameChange(val, ind) {
    let updated_dataset = [];
    let selected_Dataset = this.rule_selected.flags[ind].data_set_name.slice();
    selected_Dataset.forEach(ds => {
      const truncated_dataset = this.getFormattedDatasetAndColumnName(ds.name, 21);
      updated_dataset.push({ dataset_id: ds.dataset_id, name: truncated_dataset });
    });
    this.rule_selected.flags[ind].data_set_name = updated_dataset;
    this.withinDatasetColumns[ind] = [];
    this.getIsWithinDatasetColumns(val.dataset_id, ind);
    this.rule_selected.flags[ind].column = [];
    this.validateFlags();
  }
  removeDatasetNameFromColumn(i) {
    if ((this.rule_selected && this.rule_selected.flags.length && this.rule_selected.flags[i].data_set_name.length < 2) ||
      (this.dataQualityRule.exception_rules && this.dataQualityRule.exception_rules[i].data_set_name.length < 2)) {
      this.withindMultiatasetColumns[i].forEach(element => {
        if (element.column.includes('.')) {
          element.column = element.column.split('.')[1];
        }
      });
    }
  }
  onDeSelectDatasetNameChange(val, ind) {
    if (this.withindMultiatasetColumns[ind].length) {
      this.withindMultiatasetColumns[ind] = this.withindMultiatasetColumns[ind].filter(col => col.dataset_name != val.name);
      this.withinDatasetColumns[ind] = this.withindMultiatasetColumns[ind];
      this.removeDatasetNameFromColumn(ind);
    } else {
      this.withinDatasetColumns[ind] = [];
    }

    this.rule_selected.flags[ind].column = [];
    this.validateFlags();
  }
  onIsWithinDsColumnChange(val, ind) {
    let updated_col = [];
    let selected_column = this.rule_selected.flags[ind].column.slice();
    selected_column.forEach(col => {
      const truncated_column =this.getFormattedDatasetAndColumnName(col.column, 21);
      updated_col.push({ dataset_column_id: col.dataset_column_id, column: truncated_column });
    });
    this.rule_selected.flags[ind].column = updated_col;
    this.rule_selected.flags[ind].within_ds_column_id = val.dataset_column_id;
    this.validateFlags();
  }
  onDeSelectWithinEditDsColumnChange(val, ind) {
    //.rule_selected.flags[ind].within_ds_column_id = null;
    this.onChange()
  }
  onDeIsWithinDsColumnChange(val, ind) {
    this.rule_selected.flags[ind].within_ds_column_id = null;
    this.validateFlags();
  }
  onLookupDatasetChange(val, ind) {
    this.datasets.forEach(operator => {
      if (operator['dataset_id'] == val) {
        this.technicalRules[0]['data_quality_rule_attributes'].dataset_id.push({ dataset_id: val });
        // this.rules[0]['dqDimName'] = operator['name'];
        // this.rules[0]['dimension_lookup_id'] = operator['lookup_id'];
        // this.rules[0]['dimSequence'] = operator['sequence'];
      }
    });
    this.validateFlags();
  }

  onConceptChange(val, ind) {
    const truncated_column = this.getFormattedDatasetAndColumnName(val.column, 24);
    const selected_column = [{ dataset_column_id: val.dataset_column_id, column: truncated_column }];
    this.technicalRules[0]['data_quality_rule_attributes'].dataset_column_id = selected_column;
    this.technicalRules[0].flags[this.technicalRules[0].flags.length - 1]['concept_id'] = selected_column;
    this.onRuleConceptChange(val, this.technicalRules[0].flags.length - 1);
    this.validateFlags();
  }




  // mapSemantics() {    
  //   if(this.technicalRules[0].data_quality_rule_attributes['dataset_id'] && this.technicalRules[0].data_quality_rule_attributes['dataset_column_id']) {
  //     let combined = this.technicalRules[0].data_quality_rule_attributes['dataset_id'].map( (item, index) => {
  //      return {
  //       dataset_id: item.dataset_id,
  //       dataset_column_id: this.technicalRules[0].data_quality_rule_attributes['dataset_column_id'][index].dataset_column_id
  //       }
  //     })
  //     return combined;
  //   }
  // }

  // onRuleTypeChange(val, ind) {    
  //   this.technicalRules[0].flags[ind]['rule_type_name'] = val;
  //   this.rule_types.forEach(rule => {
  //     if(rule.name === val) {
  //       this.technicalRules[0].flags[ind]['name'] = rule['name'];
  //       this.technicalRules[0].flags[ind]['rule_sequence_type_lookup_id'] = rule['lookup_id'];
  //       this.technicalRules[0].flags[ind]['sequence'] = rule['sequence'];
  //       console.log('rule type ', rule);
  //     }
  //   })

  // }

  // onValueTypeChange(val, ind) {
  //   this.value_types.forEach(rule => {
  //     if(rule.name === val) {
  //       this.technicalRules[0].flags[ind]['value_type_lookup_id'] = rule['lookup_id'];
  //       this.technicalRules[0].flags[ind]['value_type'] = rule['name'];
  //     }
  //   })
  //   if(val === 'sum between' || val === 'difference between'
  //   || val === 'product between'
  //   || val === 'quotient between') {
  //     this.technicalRules[0].flags[ind]['sequence'] = '2';
  //   }
  // }

  // onOperatorChange(val, ind) {    
  //   this.operators.forEach(operator => {
  //     if(operator.name === val) {
  //       this.technicalRules[0].flags[ind]['operator_name'] = operator['name'];
  //       this.technicalRules[0].flags[ind]['operator_lookup_id'] = operator['lookup_id'];
  //       this.technicalRules[0].flags[ind]['sequence'] = operator['sequence'];
  //       console.log('oper', operator);
  //     }
  //   })
  // }

  // selectDq(val) {    
  //   this.rule_data_quality_dimension.forEach(operator => {
  //     if(operator.name === val) {
  //       this.technicalRules[0]['dqDimName'] = operator['name'];
  //       this.technicalRules[0]['dimension_lookup_id'] = operator['lookup_id'];
  //       this.technicalRules[0]['dimSequence'] = operator['sequence'];
  //     }
  //   })
  // }

  // onSemanticChange(val, ind) {    
  //   console.log('onSemanticChange')
  //   console.log(val)
  //   console.log(ind)
  //   this.datasets.forEach(operator => {
  //     if(operator['dataset_id'] == val) {
  //       console.log(val, ind);
  //       this.technicalRules[0]['data_quality_rule_attributes'].dataset_id.push({dataset_id: val});
  //       // this.rules[0]['dqDimName'] = operator['name'];
  //       // this.rules[0]['dimension_lookup_id'] = operator['lookup_id'];
  //       // this.rules[0]['dimSequence'] = operator['sequence'];
  //     }
  //   })
  //   this.getDatasetColumns(val, ind);
  // }

  // onLookupDatasetChange(val, ind) {
  //   this.datasets.forEach(operator => {
  //     if(operator['dataset_id'] == val) {
  //       console.log(val, ind);
  //       this.technicalRules[0]['data_quality_rule_attributes'].dataset_id.push({dataset_id: val});
  //       // this.rules[0]['dqDimName'] = operator['name'];
  //       // this.rules[0]['dimension_lookup_id'] = operator['lookup_id'];
  //       // this.rules[0]['dimSequence'] = operator['sequence'];
  //       console.log('lookupdata', operator);
  //     }
  //   })
  // }

  // onConceptChange(val, ind) {    
  //   this.concepts.forEach(operator => {
  //     if(operator['dataset_column_id'] == val) {
  //       this.technicalRules[0]['data_quality_rule_attributes'].dataset_column_id.push({dataset_column_id: val});
  //     }
  //   })
  // }

  // addSemanticsRow() {    
  //   this.semantics.push({id: Math.random()});
  // }

  // removeSemantics(ind) {     
  //   this.semantics.splice(ind, 1)
  // }

  navigateBack() {
    $('.modal').modal('hide');
    sessionStorage.removeItem("ruleInfo");
    sessionStorage.removeItem("ruleInfoClone");
    sessionStorage.removeItem("tRuleDefinition");
    if (this.dq_rule_level == 'dataset') {
      if (this.dataset_id && !this.dataset_column_id && this.backTo === 'dataset') {
        this.router.navigate([`/zs/datasets`]);
      }
      if (this.dataset_id && !this.dataset_column_id && this.backTo !== 'dataset') {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
      if (this.dataset_id && this.dataset_column_id) {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/columns/' + this.dataset_column_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
    } else {
      let rule_ids: any = [];
      rule_ids.push(this.rule_id)
      //this.catalogSvc.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
      this.router.navigate([`/zs-cl/dataquality`]);
    }
  }

  onRuleConceptChange(eve, ind) {
    let updated_col = [];
    let selected_column = this.rule_selected.flags[ind].concept_id.slice();
    selected_column.forEach(col => {
      const truncated_column = this.getFormattedDatasetAndColumnName(col.column, 21);
      updated_col.push({ dataset_column_id: col.dataset_column_id, column: truncated_column });
    });
    this.rule_selected.flags[ind].concept_id = updated_col;
    this.technicalRules[0].flags[ind].dataset_column_ids = [];
    this.technicalRules[0].flags[ind].dataset_column_ids.push(eve.dataset_column_id);
    this.dataset_column_ids.push(eve.dataset_column_id);
    this.validateFlags();
    this.validateMultiColumn = true;
  }
  defineCustomRule() {
    const data = {
      rule_name: this.technicalRules[0].rule_name,
      rule_description: this.technicalRules[0].rule_description,
      dimension_lookup_id: this.technicalRules[0]['dimension_lookup_id'],
      data_quality_rule_attributes: [{ dataset_id: this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'], dataset_column_id: this.technicalRules[0]['data_quality_rule_attributes'].dataset_column_id[0].dataset_column_id }],
      exception_rules: this.technicalRules[0].flags.map((rule, ind) => {
        return {
          rule_sequence_type_lookup_id: rule['rule_sequence_type_lookup_id'],
          value_type_lookup_id: rule['value_type_lookup_id'],
          operator_lookup_id: rule['operator_lookup_id'],
          dataset_id: rule.sequence === 3 ? rule.selectedLookupDatasetId[0].dataset_id : this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'],
          // dataset_column_id: rule.sequence === 3 ? rule.lookup_concept_id[0].dataset_column_id.toString() : rule['dataset_column_id'].toString(),
          dataset_column_id: rule.sequence === 3 ? rule.lookup_concept_id[0].dataset_column_id.toString() : rule['dataset_column_id'] ? rule['dataset_column_id'].toString() : rule['dataset_column_ids'].toString(),
          ...(
            rule.operator_name === 'is greater than'
            || rule.operator_name === 'equals' || rule.operator_name === 'does not equal'
            || rule.operator_name === 'is less than' || rule.operator_name === 'is less than or equal to'
            || rule.operator_name === 'contains' || rule.operator_name === 'does not contain'
            || rule.operator_name === 'ends with' || rule.operator_name === 'does not begin with' || rule.operator_name === 'does not end with'
            || rule.operator_name === 'begins with' || rule.operator_name === 'is greater than or equal to'
            || rule.operator_name === 'must be greater than, less than, or equal to' || rule.operator_name === 'must be equal to'
            || rule.operator_name === 'column' || rule.value_type_name === 'datatype') ? { threshold: rule.threshold } : {},

          ...(this.technicalRules[0].flags.length > 0 && ind !== this.technicalRules[0].flags.length - 1) ? { rule_sequence_operation: this.technicalRules[0].flags[ind + 1]['join_condition'] } : {},
          ...(rule['value_type'] == 'custom calculation') ? { calculation: rule.custom_exp } : {},
          ...(rule['sequence'] == '3') ? { selectedLookupDatasetId: rule['lookup_concept_id'][0]['dataset_id'] } : {},
          ...(rule['sequence'] == '3') ? { join_condition: rule['join_by'] } : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_source_id: (rule.data_set_name.map(ds => ds.dataset_id)).toString() } : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_list_column_id: (rule.column.map(ds => ds.dataset_column_id)).toString() } : {},
          ...(rule.operator_name === 'is within' || rule.operator_name === 'is not within') ? { within_filter: rule.within_filter } : {},
          ...{ comparision_filter: rule['sequence'] == '3' ? (rule.lookup_filter ? rule.lookup_filter : null) : null },
          ...(rule.operator_name === 'between' && rule['sequence'] != '2') ? { lower_limit: rule.lower_limit } : {},
          ...(rule.operator_name === 'between' && rule['sequence'] != '2') ? { upper_limit: rule.upper_limit } : {},

          comparison: {
            ...(rule['sequence'] == '2') ? { dataset_id: rule['selected_comparision_dataset_id'] } : {},
            ...(rule['sequence'] == '2') ? { dataset_column_id: rule['comparison_dataset_column_id'].toString() } : {},
            ...(rule['sequence'] == '2') ? { value_type_lookup_id: rule['comparison_value_type_lookup_id'] } : {},
            ...(rule['sequence'] == '2') ? { join_condition: rule['comparison_join_by'] } : {},
            ...(rule['sequence'] == '2' && rule.operator_name === 'between') ? { lower_limit: rule.lower_limit } : {},
            ...(rule['sequence'] == '2' && rule.operator_name === 'between') ? { upper_limit: rule.upper_limit } : {},
            ...(rule['comparison_value_type_lookup_id'] == 14895) ? { calculation: rule.comparison_calculation } : {},
            ...{ comparision_filter: rule['sequence'] == '2' ? (rule.comparision_filter ? rule.comparision_filter : null) : null }
          }

        }
      })
    }

    data['data_quality_rule_columns'] = [data['data_quality_rule_attributes'][0]];
    delete data.data_quality_rule_attributes;
    
    let atLeastOnePrimaryColumn = data['exception_rules'].filter(col => col.dataset_column_id  == '' + data['data_quality_rule_columns'][0].dataset_column_id);
    if(atLeastOnePrimaryColumn.length) {

      if (this.ruleExists || this.ruleInfoClone) {
        this.catalogSvc.updateBusinessDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.technicalRules[0]['data_quality_rule_id'], data).subscribe(resp => {
          if (resp) {
            sessionStorage.setItem('tRuleDefintion', JSON.stringify(this.technicalRules[0]));
            this.router.navigate(['add-users'], { relativeTo: this.activeRoute.parent, queryParams: { rule_id: resp['data_quality_rule_id'] }, queryParamsHandling: 'merge' });
          }
        }, err => {
          this.messageSvc.sendMessage({ message: err.error[0].message, type: 'ERROR', hideboard: true });
        });
      } else {
        this.catalogSvc.saveDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], 'technical', data).subscribe(resp => {
          if (resp) {
            this.technicalRules[0]['rule_id'] = resp['data_quality_rule_id'];
            this.technicalRules[0]['data_quality_rule_id'] = resp['data_quality_rule_id'];
            sessionStorage.setItem('tRuleDefintion', JSON.stringify(this.technicalRules[0]));
            this.router.navigate(['add-users'], { relativeTo: this.activeRoute.parent, queryParams: { rule_id: resp['data_quality_rule_id'], dataset_id: data['data_quality_rule_columns'][0]['dataset_id'] }, queryParamsHandling: 'merge' });
          }
        }, err => {
          this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
        });
      }
    } else {
      this.messageSvc.sendMessage({ message: AppGlobals.RULES_MSG_AT_LEAST_ONE_OF_EXCEPTIONS_PRIMARY_COLUMN, type: 'INFO', hideboard: true });
    }
  }


  onJoinCondChange(val, ind) {
    this.technicalRules[0].flags[ind].join_condition = val;
  }

  onItemSelect(item: any, i) {
    if (this.dataQualityRule.exception_rules && this.dataQualityRule.exception_rules[i].dataset_column_id.length) {
      const SelectedColumns = this.dataQualityRule.exception_rules[i].dataset_column_id.filter(column_id => column_id == item.dataset_column_id);
      if (!SelectedColumns.length) {
        this.dataQualityRule.exception_rules[i].dataset_column_id.push(item.dataset_column_id);
      }
    } else {
      this.dataQualityRule.exception_rules[i].dataset_column_id.push(item.dataset_column_id);
    }
    this.onChange();
  }
  onItemDeSelect(item: any, i) {
    if (this.dataQualityRule.exception_rules[i].dataset_column_id.length) {
      const SelectedColumns = this.dataQualityRule.exception_rules[i].dataset_column_id.filter(column_id => column_id != item.dataset_column_id);
      this.multiDatasetCols = SelectedColumns;
      this.dataQualityRule.exception_rules[i].dataset_column_id = SelectedColumns;
    }
    this.onChange();
    console.log('onItemDeSelect', item);
  }

  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }
  onDeSelectCompDataset(item, i) {
    this.rule_selected.flags[i].selected_comparision_dataset_id = null;
    this.rule_selected.flags[i].selectedComparisionDatasetColumn = null;
    this.rule_selected.flags[i].comparison_dataset_column_id = null;
    this.rule_selected.flags[i].comparison_join_by = null;
    this.validateFlags();
  }
  onDeSelectCompDatasetColumn(i) {
    this.rule_selected.flags[i].comparison_dataset_column_id = null;
    this.validateFlags();
  }

  validate() {
    this.validateFlags();
  }

  validateInputFlagsForm(selectedFlag) {
    let isValid = false;
    selectedFlag.sequence = typeof(selectedFlag.sequence) === 'number' ? selectedFlag.sequence + '': selectedFlag.sequence;
    switch(selectedFlag.sequence) {
      case '1': 
        let isOperationNameAndOperatorLookupIdPresent = selectedFlag['operator_name'] && selectedFlag['operator_lookup_id'] ? true: false;
        if (!isOperationNameAndOperatorLookupIdPresent) {
          return false;
        }

        let operatorsWhichShowThreshold = ['is greater than', 'equals','does not equal','is less than','is less than or equal to','contains'
        ,'does not contain','ends with','does not begin with','begins with', 'is greater than or equal to', 'must be greater than, less than, or equal to'
        ,'must be equal to','column','does not end with'];

        if (operatorsWhichShowThreshold.indexOf(selectedFlag.operator_name) > -1 && selectedFlag.threshold) {
          return true;
        }

        // Need to handle case for null,lahn, is Not null
        if ((selectedFlag.operator_name === 'is null') || (selectedFlag.operator_name === 'is not null') 
        || (selectedFlag.operator_name === 'is Luhn') || (selectedFlag.operator_name === 'is not Luhn')) {
          return true;
        }

        if ((selectedFlag.operator_name === 'between') && (selectedFlag.lower_limit && selectedFlag.upper_limit)) {
          return true;
        }
        
        if ((selectedFlag.operator_name === 'is within' || selectedFlag.operator_name === 'is not within') && 
        (selectedFlag.data_set_name && selectedFlag.data_set_name.length > 0 && selectedFlag.column && selectedFlag.column.length)) {
          return true;
        }

        if ((selectedFlag.value_type === 'custom calculation') && (selectedFlag.custom_exp)) {
          return true;
        }

      return false;
      case '2': 
      
        let isOperationNameAndOperatorLookupIdPresentForSequence2 = selectedFlag['operator_name'] && selectedFlag['operator_lookup_id'] ? true: false;
        if (!isOperationNameAndOperatorLookupIdPresentForSequence2) {
          return false;
        }
      
        if (selectedFlag.value_type === 'sum between' || selectedFlag.value_type === 'difference between'
            || selectedFlag.value_type === 'product between' || selectedFlag.value_type === 'quotient between') {

            let operatorsWhichShowThresholdForSequence2 = ['is greater than','does not equal','is less than','is less than or equal to',
            'contains','does not contain','ends with','does not begin with','begins with','is greater than or equal to',
            'must be greater than, less than, or equal to','must be equal to','column','does not end with'];

            if ((operatorsWhichShowThresholdForSequence2.indexOf(selectedFlag.operator_name) > -1) && (selectedFlag.threshold && selectedFlag.threshold != '')
            && (selectedFlag.selected_rule_type == '14876' || !selectedFlag.isMultiAttribute)) {
              return true;
            }
        }

        if ((selectedFlag.operator_name === 'between') && (selectedFlag.lower_limit && selectedFlag.upper_limit)) {
          return true;
        }  

        if ((selectedFlag.operator_name === 'is within' || selectedFlag.operator_name === 'is not within') && 
        (selectedFlag.data_set_name && selectedFlag.column)) {
          return true;
        }

        if ((selectedFlag.value_type === 'custom calculation' || selectedFlag.selected_comparison_value_type_name === 'custom calculation') && 
        (selectedFlag.custom_exp || selectedFlag.comparison_custom_exp)) {
          return true;
        }
        let isValid = this.validateDataQualityDatasetComparison(selectedFlag);
        if (isValid) {
          if ((selectedFlag.comparison_join_by) && (selectedFlag['selected_comparision_dataset_id'] && selectedFlag['comparison_dataset_column_id']
          && selectedFlag['comparison_value_type_lookup_id']) 
          && (selectedFlag['operator_lookup_id'] != null && selectedFlag['operator_name'] != null &&
          selectedFlag['threshold'] != null && selectedFlag['threshold'] != '')) {
            return true;
          }
        } 
      return false;
      case '3': 
        if ((!selectedFlag['operator_name'] && !selectedFlag['operator_lookup_id']) || !selectedFlag['selectedLookupDatasetId'] || selectedFlag['selectedLookupDatasetId'].length <= 0 || !selectedFlag['join_by'] || !selectedFlag['lookup_concept_id'] || selectedFlag['lookup_concept_id'].length <= 0) {
          return false
        }

        let operatorsWhichShowThresholdForSequence3= ['is greater than','equals','does not equal','is less than','is less than or equal to'
        ,'contains','does not contain','ends with','does not begin with','begins with','is greater than or equal to',
        'must be greater than, less than, or equal to','must be equal to','column','does not end with'];

        if (operatorsWhichShowThresholdForSequence3.indexOf(selectedFlag.operator_name) > -1 && selectedFlag.threshold) {
          return true;
        }

        if ((selectedFlag.operator_name === 'is null') || (selectedFlag.operator_name === 'is not null') 
        || (selectedFlag.operator_name === 'is Luhn') || (selectedFlag.operator_name === 'is not Luhn')) {
          return true;
        }

        if ((selectedFlag.operator_name === 'between') && (selectedFlag.lower_limit && selectedFlag.upper_limit)) {
          return true;
        }

        if ((selectedFlag.operator_name === 'is within' || selectedFlag.operator_name === 'is not within') && 
        ((selectedFlag.data_set_name && selectedFlag.column))) {
          return true;
        }

        if ((selectedFlag.value_type === 'custom calculation') && ((selectedFlag.custom_exp))) {
          return true;
        }

      return false;
      default: 
      break;
    }
    return isValid;
  }

  validateDataQualityDatasetComparison(selectedFlag) {
    if (this.technicalRules && this.technicalRules[0] &&
      this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0] &&
      this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0] &&
      this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] &&
      selectedFlag['selectedComparisionDataset'] &&
      selectedFlag['selectedComparisionDataset'][0] &&
      selectedFlag['selectedComparisionDataset'][0]['dataset_id'] &&
      this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] !== selectedFlag['selectedComparisionDataset'][0]['dataset_id']) { 
        return true;
      }
     return false; 
  }


  validateFlags() {
    if (!this.rule_selected) {
      this.enableNext = false;
    }
    let self = this;
    this.enableNext = this.rule_selected.flags.every(this.validateInputFlagsForm, self);
    
    /*this.rule_selected.flags.forEach(flag => {
      console.log(`flag: ${flag.sequence}, ${flag.operator_name}, ${flag.operator_lookup_id}`);
      if (flag.sequence == 1) {
        if (flag['operator_name'] && flag['operator_lookup_id']) {
          if (flag.operator_name === 'is greater than'
            || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
            || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
            || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
            || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
            || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
            flag.operator_name === 'must be greater than, less than, or equal to' ||
            flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
            console.log(`Operator name others`);
              if (!flag.threshold) {
              console.log('');
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else if (flag.operator_name === 'between') {
            console.log(`opreator nane: between`);
            if (flag.lower_limit && flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            console.log('within');
            if (flag.data_set_name.length && flag.column.length) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            console.log('custom');
            if (flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          }
        } else {
          this.enableNext = false;
        }
      }
      if (flag.sequence == 2) {
        if (flag['operator_name'] && flag['operator_lookup_id']) {
          if (flag['selected_comparision_dataset_id'] && flag['comparison_dataset_column_id']
            && flag['comparison_value_type_lookup_id']) {
            this.enableNext = true;
          }
          else {
            this.enableNext = false;
          }
          if (flag.operator_name === 'between') {
            if (flag.lower_limit && flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          }
          if (flag.value_type === 'sum between' || flag.value_type === 'difference between'
            || flag.value_type === 'product between' || flag.value_type === 'quotient between') {
            if (flag.operator_name === 'is greater than'
              || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
              || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
              || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
              || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
              || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
              flag.operator_name === 'must be greater than, less than, or equal to' ||
              flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
              if (!flag.threshold) {
                this.enableNext = false;
              } else {
                this.enableNext = true;
              }
            }
          }
          if (flag.operator_name === 'between') {
            if (flag.lower_limit && flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          }
          if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (flag.data_set_name && flag.column) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          }
          if (flag.value_type === 'custom calculation' || flag.selected_comparison_value_type_name === 'custom calculation') {
            if (flag.custom_exp || flag.comparison_custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          }
          if (this.technicalRules[0] &&
            this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0] &&
            this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0] &&
            this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] &&
            flag['selectedComparisionDataset'] &&
            flag['selectedComparisionDataset'][0] &&
            flag['selectedComparisionDataset'][0]['dataset_id'] &&
            this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] !== flag['selectedComparisionDataset'][0]['dataset_id']) {
            if (flag.comparison_join_by) {
              if (flag['selected_comparision_dataset_id'] && flag['comparison_dataset_column_id']
                && flag['comparison_value_type_lookup_id']) {
                this.enableNext = true;
              } else {
                this.enableNext = false;
              }
            } else {
              this.enableNext = false;
            }
          }
        } else {
          this.enableNext = false;
        }

      }
      if (flag.sequence == 3) {
        if (!flag['operator_name'] && !flag['operator_lookup_id'] || !flag['selectedLookupDatasetId'] || !flag['selectedLookupDatasetId'][0] || !flag['join_by'] || !flag['lookup_concept_id'] || !flag['lookup_concept_id'][0]) {
          this.enableNext = false;
        } else {
          if (flag.operator_name === 'is greater than'
            || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
            || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
            || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
            || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
            || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
            flag.operator_name === 'must be greater than, less than, or equal to' ||
            flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
            if (!flag.threshold) {
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else if (flag.operator_name === 'between') {
            if (!flag.lower_limit && !flag.upper_limit) {
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (!flag.data_set_name && !flag.column) {
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (!flag.custom_exp) {
              this.enableNext = false;
            } else {
              this.enableNext = true;
            }
          } else {
            this.enableNext = true;
          }
        }
      }

    });*/
  }
  oldValidateFlags() {
    this.rule_selected.flags.forEach(flag => {
      if (flag.sequence == 2) {
        if (!flag['operator_name'] && !flag['operator_lookup_id'] || !flag['selectedComparisionDataset'] || !flag['selectedComparisionDataset'][0] || !flag['comparison_dataset_column_id']
          || !flag['comparison_dataset_column_id'][0] || !flag['comparison_value_type_lookup_id']) {
          this.enableNext = true;
        } else {
          if (flag.value_type === 'sum between' ||
            flag.value_type === 'difference between' ||
            flag.value_type === 'product between' ||
            flag.value_type === 'quotient between') {
            if (!flag.threshold) {
              this.enableNext = true;
            } else {
              if (flag.operator_name !== 'is null' || flag.operator_name !== 'is not null' || flag.operator_name !== 'between' ||
                flag.operator_name !== 'is Luhn' || flag.operator_name !== 'is not Luhn' || flag.operator_name !== 'is within' || flag.operator_name == 'is not within') {
                if (flag.threshold) {
                  this.enableNext = true;
                } else {
                  this.enableNext = false;
                }
              }
              if (this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] === flag['selectedComparisionDataset'][0]['dataset_id']) {
                this.enableNext = false;
              } else if ((this.technicalRules[0]['data_quality_rule_attributes'].dataset_id[0][0]['dataset_id'] !== flag['selectedComparisionDataset'][0]['dataset_id']) && !flag.join_by) {
                this.enableNext = true;
              } else {
                this.enableNext = false;
              }
            }
          } else if (flag.operator_name === 'between') {
            if (!flag.lower_limit && !flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (!flag.data_set_name && !flag.column) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (!flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else {
            this.enableNext = false;
          }
        }
      }
      else if (flag.sequence == 1) {
        if (!flag['operator_name'] && !flag['operator_lookup_id']) {
          this.enableNext = true;
        } else {
          if (flag.operator_name === 'is greater than'
            || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
            || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
            || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
            || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
            || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
            flag.operator_name === 'must be greater than, less than, or equal to' ||
            flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
            if (!flag.threshold) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'between') {
            if (!flag.lower_limit && !flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (!flag.data_set_name && !flag.column) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (!flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else {
            this.enableNext = false;
          }
        }

      } else if (flag.sequence == 3) {
        if (!flag['operator_name'] && !flag['operator_lookup_id'] || !flag['selectedLookupDatasetId'] || !flag['selectedLookupDatasetId'][0] || !flag['join_by'] || !flag['lookup_concept_id'] || !flag['lookup_concept_id'][0]) {
          this.enableNext = true;
        } else {

          if (flag.operator_name === 'is greater than'
            || flag.operator_name === 'equals' || flag.operator_name === 'does not equal'
            || flag.operator_name === 'is less than' || flag.operator_name === 'is less than or equal to'
            || flag.operator_name === 'contains' || flag.operator_name === 'does not contain'
            || flag.operator_name === 'ends with' || flag.operator_name === 'does not begin with'
            || flag.operator_name === 'begins with' || flag.operator_name === 'is greater than or equal to' ||
            flag.operator_name === 'must be greater than, less than, or equal to' ||
            flag.operator_name === 'must be equal to' || flag.operator_name === 'column' || flag.operator_name === 'does not end with') {
            if (!flag.threshold) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'between') {
            if (!flag.lower_limit && !flag.upper_limit) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.operator_name === 'is within' || flag.operator_name === 'is not within') {
            if (!flag.data_set_name && !flag.column) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else if (flag.value_type === 'custom calculation') {
            if (!flag.custom_exp) {
              this.enableNext = true;
            } else {
              this.enableNext = false;
            }
          } else {
            this.enableNext = false;
          }
        }
      }
    })
  }

  onFlagAdd(flag) {
    let col_array = [];
    if (this.technicalRules[0].data_quality_rule_attributes.dataset_column_id) {
      this.technicalRules[0].data_quality_rule_attributes.dataset_column_id.forEach(element => {
        col_array.push(element.dataset_column_id)
      });
    }

    this.technicalRules[0].flags.push(
      {
        sequence: '1',
        rule_sequence_type_lookup_id: 14875,
        name: '',
        rule_type_id: '1',
        rule_type_name: '',
        concept_id: this.technicalRules[0].data_quality_rule_attributes.dataset_column_id,
        concept_name: 'Customer Name',
        value_type_id: '17',
        value_type_lookup_id: 14879,
        value_type_name: '',
        comparison_dataset_id: '',
        comparison_dataset_column_id: '',
        comparison_value_type_lookup_id: 14879,
        comparison_concept_name: 'Customer Number',
        comparison_value_type_id: '',
        comparison_value_type_name: 'value',
        operator_id: '1',
        operator_name: '',
        threshold: '',
        lower_limit: '',
        upper_limit: '',
        custom_exp: '',
        comparison_custom_exp: '',
        within_list_column_id: '',
        within_source_id: '',
        join_condition: 'AND',
        data_set_name: '',
        column: '',
        dataset_column_ids: col_array,
        within_filter: '',
        dqOperators: this.dqOperators,
        within_dataset_id: '',
        within_dataset_column_id: '',
      }
    );
    this.withindMultiatasetColumns.push([]);
    this.validateFlags();
    this.validateMultiColumn = true;
  }

  onFlagDelete(flag, ind) {
    this.removeFlagInd = ind;
    let currFlag = this.rule_selected.flags[this.removeFlagInd];
    if (currFlag.name !== 'Single Attribute' || (currFlag.operator_name && currFlag.operator_name !== '') || currFlag.value_type_name !== '' || currFlag.value_type_lookup_id) {
      $('#deleteFlag').modal('show');
    };
  }

  hideFlageDeleteModal() {
    $('#deleteFlag').modal('hide');
  }
  removeFlag() {
    this.technicalRules[0].flags.splice(this.removeFlagInd, 1);
    $('#deleteFlag').modal('hide');
    this.validateFlags();
  }

  getRuleDetail() {

    this.rule_data_quality_dimension = this.dqDimensions;
    this.rule_types = this.dqSequence;
    this.operators = this.dqOperators;
    // this.value_types = this.dqValueTypes;
    // this.semantic_objects = this.entities;
    // this.semantics = this.entities;
  }

  onRuleConceptRemove(data, ind) {
    if (this.technicalRules[0].flags[ind]['concept_id'].length > 0) {
      this.validateMultiColumn = true;
      this.validateFlags();
    } else {
      this.validateColumns();
    }
  }

  validateColumns() {
    this.validateMultiColumn = false;
  }

  getLookUpColumns(data, ind) {
    const truncated_dataset =  this.getFormattedDatasetAndColumnName(data.name, 26);
    const selected_dataset = [{ dataset_id: data.dataset_id, name: truncated_dataset }];
    this.rule_selected.flags[ind].selectedLookupDatasetId = selected_dataset;

    this.rule_selected.flags[ind].lookup_concept_id = [];
    this.datasetSvc.getAllDatasetColumns({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }, data.dataset_id).subscribe(response => {
      this.lokup_dataset_columns = response;      
    }, err => {
      if (err.status === 404 || err.error.message == 'Not found!') {
        this.lokup_dataset_columns = [];
        this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
      }
    });
  }

  onDataTypeChanged(dataType, ind) {
    if (dataType !== 'Select one') {
      this.technicalRules[0].flags[ind]['threshold'] = dataType;
    } else {
      this.technicalRules[0].flags[ind]['threshold'] = null;
    }
    this.validateFlags();
  }

  onExceptionDataTypeChange(dataType, ind) {
    this.dataQualityRule.exception_rules[ind]['threshold'] = dataType;
    this.onChange();
  }

  onCompJoinBy(joinByText, i) {
    this.technicalRules[0].flags[i]['comparison_join_by'] = joinByText;
    this.validateFlags();
  }

  // On change DQ Rule name in Edit Mode 
  onChangeRuleName(ruleName) {
    if (this.ruleNameSelected !== this.dataQualityRule.rule_name) {
      if (this.payloadForEditRule['exception_rules']) {
        let rule_name = ruleName
        this.payloadForEditRule = { rule_name, ...this.payloadForEditRule };
      } else {
        this.payloadForEditRule = {
          "rule_name": ruleName,
          "exception_rules": []
        };
      }
    }
  }

  onChangeRuleDescription(description) {
    if (this.payloadForEditRule['exception_rules']) {
      let rule_description = description
      this.payloadForEditRule = { rule_description, ...this.payloadForEditRule };
    } else {
      this.payloadForEditRule = {
        "rule_description": description,
        "exception_rules": []
      };
    }
  }

  onChangeDimensions(lookup_id) {
    if (this.payloadForEditRule['exception_rules']) {
      let dimension_lookup_id = lookup_id
      this.payloadForEditRule = { dimension_lookup_id, ...this.payloadForEditRule };
    } else {
      this.payloadForEditRule = {
        "dimension_lookup_id": lookup_id,
        "exception_rules": []
      };
    }
  }

  scheduleEdit(events) {
    this.isScheduleEdit.emit(true);
  }

  getFormattedDatasetAndColumnName(name, limit) {
    return name.length > limit ? name.substring(0, limit) + '...' : name;
  }
}
