import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCustomPreloader } from './app-routing-loader';
import { StyleguideComponent } from './zettasense/styleguide/styleguide.component';
import { AppGlobals } from './common/shared/app.globals';
import { ZmAuthGuard } from './common/zm-shared/zm-auth-guard.service';
import { LandingComponent } from './landing/landing.component';
import { ZmDashboardComponent } from './zettamesh/zm-content/zm-dashboard/zm-dashboard.component';
import { ZmDatasetsComponent } from './zettamesh/zm-content/zm-datasets/zm-datasets.component';
import { ZmHomeComponent } from './zettamesh/zm-home/zm-home.component';
import { ZmWorkbooksComponent } from './zettamesh/zm-content/zm-workbooks/zm-workbooks.component';
import { ZmWorkbooksDetailsComponent } from './zettamesh/zm-content/zm-workbooks/zm-workbooks-details/zm-workbooks-details.component';
import { ZmDatasourceComponent } from './zettamesh/zm-content/zm-datasource/zm-datasource.component';
import { ZmSystemjobsComponent } from './zettamesh/zm-content/zm-systemjobs/zm-systemjobs.component';
import { ZmAlertsComponent } from './zettamesh/zm-content/zm-alerts/zm-alerts.component';
import { ZmMaintenanceComponent } from './zettamesh/zm-content/zm-maintenance/zm-maintenance.component';

// Analytics Components
import { ZmAnalyticsComponent } from './zettamesh/zm-content/zm-analytics/zm-analytics.component';
import { AnalyticsHomeComponent } from './zettamesh/zm-content/zm-analytics/analytics-home/analytics-home.component';
import { CreateAnalyticComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/create-analytic.component';
import { ZmUsersComponent } from './zettamesh/zm-content/zm-users/zm-users.component';
import { AddTrainingComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/add-training/add-training.component';
import { AddPredictionComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/add-prediction/add-prediction.component';
import { DefineOutputsComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/define-outputs/define-outputs.component';
import { DefineThresholdsComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/define-thresholds/define-thresholds.component';
import { DefineInputsComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/define-inputs/define-inputs.component';
import { DefineSegComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/define-segments/define-segments.component';
import { ScheduleModelComponent } from './zettamesh/zm-content/zm-analytics/create-analytic/schedule-model/schedule-model.component';
import { AnalyticDetailComponent } from './zettamesh/zm-content/zm-analytics/analytic-detail/analytic-detail.component';
import { MainComponent } from './main/main.component';

import { AdViewMostSignificantAttributeComponent } from './zettamesh/zm-content/zm-analytics/analytic-detail/ad-view-most-significant-attribute/ad-view-most-significant-attribute.component';
import { AdViewHighestImpactAttributesComponent } from './zettamesh/zm-content/zm-analytics/analytic-detail/ad-view-highest-impact-attributes/ad-view-highest-impact-attributes.component';
import { AdViewPredictionModelAccuracyComponent } from './zettamesh/zm-content/zm-analytics/analytic-detail/ad-view-prediction-model-accuracy/ad-view-prediction-model-accuracy.component';
import { AdViewPredictionsComponent } from './zettamesh/zm-content/zm-analytics/analytic-detail/ad-view-predictions/ad-view-predictions.component';

// Datasource Components
import { DatasourceHomeComponent } from './common/components/datasources/datasource-home/datasource-home.component';
import { CreateDatasourceComponent } from './common/components/datasources/create-datasource/create-datasource.component';
import { DefineConnectionComponent } from './common/components/datasources/define-connection/define-connection.component';
import { AddAdminUsersComponent } from './common/components/datasources/add-admin-users/add-admin-users.component';
import { SetUserEntitlementsComponent } from './common/components/datasources/set-user-entitlements/set-user-entitlements.component';
import { DatasourceDetailComponent } from './common/components/datasources/datasource-detail/datasource-detail.component';
import { AuthGuard } from './common/shared/auth-guard.service';
import { ContentComponent } from './zettasense/content/content.component';

// Datasets Components
import { CreateDatasetComponent } from './common/components/datasets/create-dataset/create-dataset.component';
import { DatasetHomeComponent } from './common/components/datasets/dataset-home/dataset-home.component';
import { SelectDataSourceComponent } from './common/components/datasets/select-data-source/select-data-source.component';
import { AddDataTransformationComponent } from './common/components/datasets/add-data-transformation/add-data-transformation.component';
import { ScheduleRefreshComponent } from './common/components/datasets/schedule-refresh/schedule-refresh.component';
import { UserEntitlementsComponent } from './common/components/datasets/user-entitlements/user-entitlements.component';
import { ClientProfitsComponent } from './common/components/datasets/client-profits/client-profits.component';

// Admin Component
import { AdminComponent } from './common/components/admin/admin.component';

import { ManageJobsComponent } from './zettasense/manage-jobs/manage-jobs.component';
import { TasksComponent } from './zettasense/content/tasks/tasks.component';

// User Profile Component
import { UserProfileComponent } from './common/components/user-profile/user-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

// Zetta Consume
import { ZcReportsComponent } from './zettaconsume/zc-content/zc-reports/zc-reports.component';
import { ReportDetailComponent } from './zettaconsume/zc-content/zc-reports/report-detail/report-detail.component';

// Zetta Classify
import { ZsClHomeComponent } from './zettaclassify/zs-cl-content/zs-cl-home/zs-cl-home.component';
import { ZsClCatalogsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalogs/zs-cl-catalogs.component';
import { ZsClCatalogObjectsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalog-objects/zs-cl-catalog-objects.component';
import { ZsClCatalogObjectConceptsComponent } from './zettaclassify/zs-cl-content/zs-cl-catalog-object-concepts/zs-cl-catalog-object-concepts.component';
import { ZsClCreateCatalogComponent } from './zettaclassify/zs-cl-content/zs-cl-create-catalog/zs-cl-create-catalog.component';
import { DsSelectCatalogsComponent } from './zettaclassify/zs-cl-content/ds-select-catalogs/ds-select-catalogs.component';
import { DsSelectObjectsComponent } from './zettaclassify/zs-cl-content/ds-select-objects/ds-select-objects.component';
import { DsMapColumnsComponent } from './zettaclassify/zs-cl-content/ds-map-columns/ds-map-columns.component';

import { TmReviewTrainingDataComponent } from './zettaclassify/zs-cl-content/tm-review-training-data/tm-review-training-data.component';
import { TmSelectDataSetsComponent } from './zettaclassify/zs-cl-content/tm-select-data-sets/tm-select-data-sets.component';
import { TmMapDataColumnsComponent } from './zettaclassify/zs-cl-content/tm-map-data-columns/tm-map-data-columns.component';

import { SynSelectDataCatalogComponent } from './zettaclassify/zs-cl-content/syn-select-data-catalog/syn-select-data-catalog.component';
import { SynSelectSemanticObjectsComponent } from './zettaclassify/zs-cl-content/syn-select-semantic-objects/syn-select-semantic-objects.component';
import { SynMapConceptsComponent } from './zettaclassify/zs-cl-content/syn-map-concepts/syn-map-concepts.component';

import { ZsClDatasourcesComponent } from './zettaclassify/zs-cl-content/zs-cl-datasources/zs-cl-datasources.component';
import { ZsClDatasetsComponent } from './zettaclassify/zs-cl-content/zs-cl-datasets/zs-cl-datasets.component';
import { ZsClJobsComponent } from './zettaclassify/zs-cl-content/zs-cl-jobs/zs-cl-jobs.component';
import { ZsClAdminComponent } from './zettaclassify/zs-cl-content/zs-cl-admin/zs-cl-admin.component';
import { ZsClTasksComponent } from './zettaclassify/zs-cl-content/zs-cl-tasks/zs-cl-tasks.component'
// Zetta Ingest
import { ZsInIngestionComponent } from './zettaingest/zs-in-content/zs-in-ingestion/zs-in-ingestion.component';
import { ZsInTransformationComponent } from './zettaingest/zs-in-content/zs-in-transformation/zs-in-transformation.component';
import { ZsInRedirectionComponent } from './zettaingest/zs-in-content/zs-in-redirection/zs-in-redirection.component';

import { NotificationComponent } from './common/components/notifications/notification.component';
import { ZettainsightComponent } from './zettainsight/zettainsight.component';
import { HelpComponent } from './common/components/help/help.component';
import { UserHelpComponent } from './common/components/help/user-help/user-help.component';
import { UserApiComponent } from './common/components/help/user-api/user-api.component';
import { UserDbComponent } from './common/components/help/user-db/user-db.component';
import { ZsClCatalogObjectConceptRightComponent } from './zettaclassify/zs-cl-content/zs-cl-catalog-object-concept-right/zs-cl-catalog-object-concept-right.component';
import { DefineDatasetComponent } from './common/components/datasets/define-dataset/define-dataset.component';
import { DatasetsSampleComponent } from './common/components/datasets/dataset-sample/dataset-sample.component';
import { ZsClViewObjectModelComponent } from './zettaclassify/zs-cl-content/zs-cl-view-object-model/zs-cl-view-object-model.component';
import { AdvanceSearchComponent } from './common/components/advance-search/advance-search.component';
import { AdminUserGroup } from './common/components/admin-usergroup/admin-usergroup.component';
import { UsergroupsComponent } from './zettasense/user-groups/user-groups.component';
import { UsergroupComponent } from './zettasense/usergroup/usergroup.component';
import { ZsClProjectsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-projects/zs-cl-projects.component';
import { ZsClCreateProjectComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-create-project/zs-cl-create-project.component';
import { ZsClProjectAddUsersComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-add-users/zs-cl-project-add-users.component';
import { ZsClProjectSelectObjectComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-select-object/zs-cl-project-select-object.component';
import { ZsClProjectAddDataComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-add-data/zs-cl-project-add-data.component';
import { UsersComponent } from './zettasense/content/project/users/users.component';
import { ZsClDefineNewClassifreComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-classifire/zs-cl-define-new-classifire.component';

import { ZsClAddTrainingDataComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-add-training-data/zs-cl-add-training-data.component';
import { ZsClProjectDetailComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-project-detail/zs-cl-project-details.component';
import { ZsClViewResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-view-results/zs-cl-view-results.component';
import { ZsClViewResultsParseComponent } from './zettaclassify/zs-cl-content/zs-cl-view-results-parse/zs-cl-view-results-parse.component'
import { ZsClProjectTrainingComponent } from './zettaclassify/zs-cl-content/zs-cl-project-training/zs-cl-project-training.component';
import { ActivityLogsComponent } from './zettasense/activity-logs/activity-logs.component';
import { ManageProjectTasksComponent } from './zettasense/content/project/manage-project-tasks/manage-project-tasks.component';
import { BussinessRulesComponent } from './common/components/data-quality-rules/bussiness-rules/bussiness-rules.component';
import { OutOfBoxRuleComponent } from './common/components/data-quality-rules/bussiness-rules/out-of-box-rule/out-of-box-rule.component';
import { SelectDatasetComponent } from './common/components/data-quality-rules/bussiness-rules/select-dataset/select-dataset.component';

import { DataQualityRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/data-quality-rules.component';
import { BusinessEntityLevelRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-entity-level-rules.component';
import { TechnicalColumnLevelRulesComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-column-level-rules.component';
import { BusinessRuleDefinitionComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-rule-definition/business-rule-definition.component';
import { BusinessAppliedColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-applied-columns/business-applied-columns.component';
import { BusinessRunScheduleComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-schedule/business-run-schedule.component';
import { BusinessRunExecutionResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-execution-results/business-run-execution-results.component';
import { MapDataColumnComponent } from './common/components/data-quality-rules/bussiness-rules/map-data-column/map-data-column.component';
import { ReviewPriorMappingsComponent } from './common/components/data-quality-rules/bussiness-rules/review-prior-mappings/review-prior-mappings.component';

import { TechnicalRuleDefinitionComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-rule-definition/technical-rule-definition.component';
import { TechnicalAppliedColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-applied-columns/technical-applied-columns.component';
import { TechnicalRunScheduleComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-run-schedule/technical-run-schedule.component';
import { TechnicalRunExecutionResultsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-run-execution-results/technical-run-execution-results.component';
import { TechnicalRulesComponent } from './technical-rules/technical-rules.component';
import { ZsClDQManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-dq-management/zs-cl-dq-management.component';
import { ZsClSemanticLevelDqManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-semantic-level-dq-management/zs-cl-semantic-level-dq-management.component';
import { ZsClCatalogLevelDqManagementComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/zs-cl-catalog-level-dq-management/zs-cl-catalog-level-dq-management.component';
import { DqUsersComponent } from './zettaclassify/zs-cl-content/dq-users/dq-users.component';
import { BusinessUserEntitlementsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-user-entitlements/business-user-entitlements.component';
import { TechnicalUserEntitlementsComponent } from './zettaclassify/zs-cl-content/zs-cl-data-quality-rules/technical-column-level-rules/technical-user-entitlements/technical-user-entitlements.component';
import { SelectConceptComponent } from './common/components/data-quality-rules/bussiness-rules/select-concept/select-concept.component';
import { TextSelectDemoComponent } from './common/components/text-select/text-select-demo.component';
import { ZsClSelectConceptParsedComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/zs-cl-select-concept-parsed/zs-cl-select-concept-parsed.component';
import { ZsClDefinNewSubConceptComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/zs-cl-define-new-sub-concept.component';
import { ZsClAddTrainDataConceptParserComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-add-train-data-concept-parser/zs-cl-add-train-data-concept-parser.component';
import { ZsClSelectTrainDatasetsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-select-train-datasets/zs-cl-select-train-datasets.component';
import { ZsClSelectTrainDataColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-select-train-data-columns/zs-cl-select-train-data-columns.component';
import { ZsClObjectClassifier } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/zs-cl-object-classifier/zs-cl-object-classifier.component';
import { RegisterdDataSourceComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/registerd-data-source/registerd-data-source.component';
import { DataSetColumnsComponent } from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-define-new-sub-concept/data-set-coulmns/data-set-coulmns.component';
import { ConceptTrainingDataComponent } from './zettaclassify/zs-cl-content/concept-training-data/concept-training-data.component';

import { ZsClAddReferenceDataComponent }from './zettaclassify/zs-cl-content/zs-cl-project/zs-cl-concept-parser-project/add-training-data/zs-cl-add-reference-data/zs-cl-add-reference-data.component';
import { ConsumptionReportComponent } from './zettasense/content/consumption-report/consumption-report.component';

import { CatalogRouteGuard } from './common/shared/guards/catalog/catalog-route.guard';


const ROUTES: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: AppGlobals.ZETTALABS,
    component: LandingComponent
  },
  {
    path: AppGlobals.ZETTALABS_BASE,
    component: MainComponent
  },
  {
    path: AppGlobals.ZETTASENSE_LOGIN,
    loadChildren: './zettasense/authentication/authentication.module#AuthenticationModule',
    data: { preload: true, delay: false }
  },
  {
    path: AppGlobals.ZL_FORGOTPWD,
    component: ForgotPasswordComponent,
  },
  {
    path: AppGlobals.ZL_RESETPWD,
    component: ResetPasswordComponent,
  },
  {
    path: AppGlobals.HOME_BASE,
    redirectTo: AppGlobals.HOME_DASHBOARD,
    pathMatch: AppGlobals.FULL
  },
  {
    path: AppGlobals.PROJECT_BASE,
    redirectTo: AppGlobals.PROJECT_CREATE,
    pathMatch: AppGlobals.FULL
  },
  {
    path: AppGlobals.DATASET_BASE,
    redirectTo: AppGlobals.DATASET_CREATE,
    pathMatch: AppGlobals.FULL
  },
  {
    path: AppGlobals.STYLEGUIDE,
    component: StyleguideComponent,
    data: { styleGuide: 'Style Guide' }
  },
  {
    path: AppGlobals.ZETTAMESH_LOGIN,
    loadChildren: './zettamesh/zm-authentication/zm-authentication.module#ZmAuthenticationModule',
    data: { preload: true, delay: false }
  },

  {
    path: 'zm',
    loadChildren: './zettamesh/zm-authentication/zm-authentication.module#ZmAuthenticationModule',
    data: { preload: true, delay: false }
  },

  {
    path: 'zm/home',
    component: ZmHomeComponent,
    data: { breadcrumb: 'Home' }
  },
  { path: 'zm/analytics', component: AnalyticsHomeComponent, data: { breadcrumb: 'Analytics' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/home', component: AnalyticsHomeComponent, data: { breadcrumb: 'Home', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/create-analytic', component: CreateAnalyticComponent, data: { breadcrumb: 'Create New Analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id', component: AnalyticDetailComponent, data: { breadcrumb: '...', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/users', component: ZmUsersComponent, data: { breadcrumb: 'Manage Analytic Users', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/add-training', component: AddTrainingComponent, data: { breadcrumb: 'Add Training Data', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/add-prediction', component: AddPredictionComponent, data: { breadcrumb: 'Add Prediction Data', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/define-outputs', component: DefineOutputsComponent, data: { breadcrumb: 'Define Outputs', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/define-thresholds', component: DefineThresholdsComponent, data: { breadcrumb: 'Define Thresholds', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/define-inputs', component: DefineInputsComponent, data: { breadcrumb: 'Define Inputs', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/define-segments', component: DefineSegComponent, data: { breadcrumb: 'Define Segments', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/schedule-model', component: ScheduleModelComponent, data: { breadcrumb: 'Schedule Model', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/most-significant-attribute', component: AdViewMostSignificantAttributeComponent, data: { breadcrumb: 'Most Significant Attribute', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/highest-impact-attributes', component: AdViewHighestImpactAttributesComponent, data: { breadcrumb: 'Highest Impact Attributes', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/prediction-model-accuracy', component: AdViewPredictionModelAccuracyComponent, data: { breadcrumb: 'Prediction Model Accuracy', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/analytics/:id/predictions', component: AdViewPredictionsComponent, data: { breadcrumb: 'Predictions', type: 'analytic' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/workbooks', component: ZmWorkbooksComponent, data: { breadcrumb: 'Workbooks', type: 'workbook' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/workbooks/:id', component: ZmWorkbooksDetailsComponent, data: { breadcrumb: 'Workbooks', type: 'workbook' }, canActivate: [ZmAuthGuard] },
  { path: 'zm/datasets', component: ZmDatasetsComponent },
  { path: 'zm/systemjobs', component: ZmSystemjobsComponent, data: { breadcrumb: 'System Jobs' } },
  { path: 'zm/alerts', component: ZmAlertsComponent },
  { path: 'zm/maintenance', component: ZmMaintenanceComponent },
  { path: 'zs/admin', component: ContentComponent, data: { breadcrumb: 'System Admin',type: 'system-admin' }, canActivate: [AuthGuard],
    children: [
      { path: '', component: AdminComponent, data: { breadcrumb: 'System Admin' }, canActivate: [AuthGuard] },
      { path: 'superadmin-users', component: AdminUserGroup, data: { breadcrumb: 'Users' }, canActivate: [AuthGuard] },
      { path: 'users', component: UsergroupComponent, data: { breadcrumb: 'Groups and Users', type: 'user-group' }, canActivate: [AuthGuard] },
      {path: 'ad-users', component: UsergroupsComponent, data: { breadcrumb: 'Groups and Users',type: 'user-group' }, canActivate: [AuthGuard] },
      { path: 'activities', component: ActivityLogsComponent, data: { breadcrumb: 'Activity Logs' }, canActivate: [AuthGuard]},
    ],
  },

  { path: 'zm/admin', component: ContentComponent, data: { breadcrumb: 'Admin' }, canActivate: [AuthGuard],
    children: [
      { path: '', component: ZmMaintenanceComponent, canActivate: [AuthGuard] },
      // { path: 'usergroups', component: UsergroupComponent, data: { breadcrumb: 'Groups and Users' }, canActivate: [AuthGuard] },
    ],
  },

  {
    path: 'zs/datasources',
    component: ContentComponent,
    data: { breadcrumb: 'Data Sources' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DatasourceHomeComponent, canActivate: [AuthGuard] },
      { path: 'create-datasource', component: CreateDatasourceComponent, data: { breadcrumb: 'Create New Datasource' }, canActivate: [AuthGuard] },
      { path: ':id', component: ContentComponent, data: { breadcrumb: '...', type: 'datasource' }, canActivate: [AuthGuard],
        children: [
          { path: '', component: DatasourceDetailComponent, data: {}, canActivate: [AuthGuard] },
          { path: 'define-connection', component: DefineConnectionComponent, data: { breadcrumb: 'Define Connection' }, canActivate: [AuthGuard] },
          { path: 'modify-connection', component: DefineConnectionComponent, data: { breadcrumb: 'Modify Connection' }, canActivate: [AuthGuard] },
          { path: 'add-admin-users', component: AddAdminUsersComponent, data: { breadcrumb: 'Add Admin Users' }, canActivate: [AuthGuard] },
          { path: 'set-user-entitlements', component: SetUserEntitlementsComponent, data: { breadcrumb: 'Set User Entitlements' }, canActivate: [AuthGuard] }
        ]
      },
    ],
  },
  {
    path: 'zs-cl/tasks',
    component: ContentComponent,
    data: { breadcrumb: 'All Tasks' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ZsClTasksComponent, data: { breadcrumb: 'All Tasks' }, canActivate: [AuthGuard] },
      { path: 'allmytasks', component: ZsClTasksComponent, data: { breadcrumb: 'All My Tasks' }, canActivate: [AuthGuard] }
    ],
  },
  {
    path: 'zs-cl/projects',
    component: ContentComponent,
    data: { breadcrumb: 'All Projects' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ZsClProjectsComponent, canActivate: [AuthGuard] },
      { path: 'create-project', component: ZsClCreateProjectComponent, canActivate: [AuthGuard] },
      {
        path: ':id',
        component: ContentComponent,
        data: { breadcrumb: '...', type: 'zs-cl-project' },
        canActivate: [AuthGuard],
        children: [    
          { path: '', component: ZsClProjectDetailComponent, data: {}, canActivate: [AuthGuard] }, 
          { path: 'add-users', component: ZsClProjectAddUsersComponent, canActivate: [AuthGuard] },
          { path: 'select-object', component: ZsClProjectSelectObjectComponent, canActivate: [AuthGuard] },
          { path: 'reference-data', component: ZsClAddTrainDataConceptParserComponent, data: { breadcrumb: 'Manage Projects Data' }, canActivate: [AuthGuard], children:[
            { path: '', component: ZsClAddReferenceDataComponent, canActivate: [AuthGuard]},
            {path:'data-sets',component : ZsClSelectTrainDatasetsComponent, canActivate: [AuthGuard]},
            {path:'data-column',component : ZsClSelectTrainDataColumnsComponent, canActivate: [AuthGuard]}
          ] },
          { path: 'new-classifier', component: ZsClDefineNewClassifreComponent, canActivate: [AuthGuard] }, 
          { path: 'new-sub-concept', component: ZsClDefinNewSubConceptComponent, canActivate: [AuthGuard], children:[
            {path:'',component : ZsClObjectClassifier, canActivate: [AuthGuard]},
            {path:'source',component : RegisterdDataSourceComponent, canActivate: [AuthGuard]},
            {path:'column',component : DataSetColumnsComponent, canActivate: [AuthGuard]}
          ] },
          { path: 'concept-training-data', component: ConceptTrainingDataComponent, canActivate: [AuthGuard] },  
          { path: 'add-training-data', component: ZsClAddTrainingDataComponent, canActivate: [AuthGuard] },          
          { path: 'add-data', component: ZsClProjectAddDataComponent, canActivate: [AuthGuard]},
          { path: 'view-result', component: ZsClViewResultsComponent, data: { breadcrumb: 'View Results' }, canActivate: [AuthGuard] },
          { path: 'view-result-parse', component: ZsClViewResultsParseComponent, data: { breadcrumb: 'View Results' }, canActivate: [AuthGuard] },
          {path: 'train-model', component: ZsClProjectTrainingComponent, data: { breadcrumb: 'Train Model' }, canActivate: [AuthGuard]},
          {path: 'tasks', component: ManageProjectTasksComponent, data: { breadcrumb: 'Manage Projects Tasks' }, canActivate: [AuthGuard]},
          {path: 'fix-issues', component: ZsClProjectTrainingComponent, data: { breadcrumb: 'Fix Issues' }, canActivate: [AuthGuard]},
          { path: 'select-concept-parse', component: ZsClSelectConceptParsedComponent, canActivate: [AuthGuard] },
          { path: 'add-reference-data', component: ZsClAddTrainDataConceptParserComponent, canActivate: [AuthGuard] , children:[
            { path: '', component: ZsClAddReferenceDataComponent, canActivate: [AuthGuard]},
            {path:'data-sets',component : ZsClSelectTrainDatasetsComponent, canActivate: [AuthGuard]},
            {path:'data-column',component : ZsClSelectTrainDataColumnsComponent, canActivate: [AuthGuard]}
          ]},
        ]
      }
    ],
  },

  {
    path: 'zs-cl/rules',
    component: ContentComponent,
    //data: { breadcrumb: 'All Rules' },
    canActivate: [AuthGuard],
    children: [
     // { path: '', component: BussinessRulesComponent, canActivate: [AuthGuard] },
     { path: 'create-bussiness-rule', component: BussinessRulesComponent, canActivate: [AuthGuard],children:[
        {path: '', component:SelectConceptComponent, canActivate:[AuthGuard]},
        {path: 'out-of-box-rule', component:OutOfBoxRuleComponent, canActivate:[AuthGuard]},
        {path: 'business-rule-definition', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] },
        {path: 'review-mapping', component:ReviewPriorMappingsComponent, canActivate:[AuthGuard]},
        {path: 'select-dataset', component:SelectDatasetComponent, canActivate:[AuthGuard]},
        {path: 'map-data-columns', component:MapDataColumnComponent, canActivate:[AuthGuard]},
        {path: 'add-users', component: DqUsersComponent, canActivate: [AuthGuard] },
        {path: 'edit-users', component: DqUsersComponent, canActivate: [AuthGuard] },
        {path: 'bussiness-run-schedule', component:BusinessRunScheduleComponent, canActivate:[AuthGuard]},

      ] },
      { path: 'create-technical-rule', component: TechnicalRulesComponent, canActivate: [AuthGuard],
    
      children:[
        {path: '', component:TechnicalRuleDefinitionComponent, canActivate:[AuthGuard]},
        {path: 'technical-run-schedule', component:BusinessRunScheduleComponent, canActivate:[AuthGuard]},
        {path: 'add-users', component:DqUsersComponent, canActivate:[AuthGuard]},
        {path: 'edit-users', component: DqUsersComponent, canActivate: [AuthGuard] },
      ]
    },
    ]
  }, 
  {
    path: 'zm/datasource',
    component: ContentComponent,
    data: { breadcrumb: 'Data Sources' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DatasourceHomeComponent, canActivate: [AuthGuard] },
      { path: 'create-datasource', component: CreateDatasourceComponent, data: { breadcrumb: 'Create New Datasource' }, canActivate: [AuthGuard] },
      { path: ':id', component: ContentComponent, data: { breadcrumb: '...', type: 'datasource' }, canActivate: [AuthGuard],
        children: [
          { path: '', component: DatasourceDetailComponent, data: {}, canActivate: [AuthGuard] },
          { path: 'define-connection', component: DefineConnectionComponent, data: { breadcrumb: 'Define Connection' }, canActivate: [AuthGuard] },
          { path: 'set-user-entitlements', component: SetUserEntitlementsComponent, data: { breadcrumb: 'Set User Entitlements' }, canActivate: [AuthGuard] }
        ]
      },
    ],
  },

  {
    path: 'zs/datasets',
    component: ContentComponent,
    data: { breadcrumb: 'Data Sets' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DatasetHomeComponent, canActivate: [AuthGuard] },
      // { path: 'create-dataset', component: CreateDatasetComponent, data: { breadcrumb: 'Create New Dataset' }, canActivate: [AuthGuard] },
      { path: 'select-data-source', component: SelectDataSourceComponent, data: { breadcrumb: 'Select Data Source' }, canActivate: [AuthGuard] },
      { path: 'define-datasets', component: DefineDatasetComponent, data: { breadcrumb: 'Define Data Sets' }, canActivate: [AuthGuard] },
      // { path: 'add-data-transformation', component: AddDataTransformationComponent, data: { breadcrumb: 'Add Data Transformation' }, canActivate: [AuthGuard] },
      { path: 'schedule-refresh', component: ScheduleRefreshComponent, data: { breadcrumb: 'Schedule Refresh' }, canActivate: [AuthGuard] },
      { path: 'user-entitlements', component: UserEntitlementsComponent, data: { breadcrumb: 'User Entitlements' }, canActivate: [AuthGuard] },
      { path: ':id', component: ContentComponent, data: { breadcrumb: '...', type: 'dataset' }, canActivate: [AuthGuard],
        children: [
          { path: '', component: ClientProfitsComponent, data: {}, canActivate: [AuthGuard] },
          { path: 'catalogs', component: DsSelectCatalogsComponent, data: { breadcrumb: 'Classify Data Set' }, canActivate: [AuthGuard] },
          { path: 'catalogs/objects', component: DsSelectObjectsComponent, data: { breadcrumb: 'Classify Data Set' }, canActivate: [AuthGuard] },
          { path: 'catalogs/objects/columns', component: DsMapColumnsComponent, data: { breadcrumb: 'Classify Data Set' }, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
          {
            path: 'manage-rules', component: DataQualityRulesComponent, data: { breadcrumb: 'Manage Rules', type: 'datasets' }, canActivate: [AuthGuard], children: [
              { path: ':rule_id', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] },
            ]
          },
          {
            path: 'columns/:column_id/manage-rules', component: DataQualityRulesComponent, data: { breadcrumb: 'Manage Rules', type: 'column' }, canActivate: [AuthGuard], children: [
              { path: ':rule_id', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] }
            ]
          }          
        ]
      },
    ],
  },

  {
    path: 'zm/dataset',
    component: ContentComponent,
    data: { breadcrumb: 'Data Sets' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DatasetHomeComponent, canActivate: [AuthGuard] },
      //{ path: 'create-dataset', component: CreateDatasetComponent, data: { breadcrumb: 'Create New Dataset' }, canActivate: [AuthGuard] },
      { path: 'select-data-source', component: SelectDataSourceComponent, data: { breadcrumb: 'Select Data Source' }, canActivate: [AuthGuard] },
      { path: 'define-datasets', component: DefineDatasetComponent, data: { breadcrumb: 'Define Data Sets' }, canActivate: [AuthGuard] },
      // { path: 'add-data-transformation', component: AddDataTransformationComponent, data: { breadcrumb: 'Add Data Transformation' }, canActivate: [AuthGuard] },
      { path: 'schedule-refresh', component: ScheduleRefreshComponent, data: { breadcrumb: 'Schedule Refresh' }, canActivate: [AuthGuard] },
      { path: 'user-entitlements', component: UserEntitlementsComponent, data: { breadcrumb: 'User Entitlements' }, canActivate: [AuthGuard] },
      { path: ':id', component: ContentComponent, data: { breadcrumb: '...', type: 'dataset' }, canActivate: [AuthGuard],
        children: [
          { path: '', component: ClientProfitsComponent, data: {}, canActivate: [AuthGuard] },
        ]
      },
    ],
  },
  {
    path: 'zs/profile',
    component:UserProfileComponent,
    data: { breadcrumb: 'User Preferences' },
    canActivate: [AuthGuard],
  },
  {
    path: 'zs/search',
    component:AdvanceSearchComponent,
    data: { breadcrumb: 'Search' },
    canActivate: [AuthGuard],
    },

  {
    path: AppGlobals.ZETTA_HELP,
    component: HelpComponent,
    data: { breadcrumb: 'Help' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: UserHelpComponent, data: { breadcrumb: 'Documents' }, canActivate: [AuthGuard] },
      { path: 'user-help', component: UserHelpComponent,data: { breadcrumb: 'Documents' }, canActivate: [AuthGuard] },
      { path: 'user-api', component: UserApiComponent, data: { breadcrumb: 'Api' }, canActivate: [AuthGuard] },
      { path: 'user-db', component: UserDbComponent, data: { breadcrumb: 'DB' }, canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'zc/reports',
    component: ContentComponent,
    data: { breadcrumb: 'Reports' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ZcReportsComponent, canActivate: [AuthGuard] },
      {
        path: ':id',
        component: ContentComponent,
        data: { breadcrumb: '...', type: 'report' },
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ReportDetailComponent, data: {}, canActivate: [AuthGuard] },
        ]
      },
    ],
  },
  {
    path: 'zs/dataquality',
    component: ContentComponent,
    data: { breadcrumb: 'Data Quality Rules' },
    canActivate: [AuthGuard],
  },
  {
    path: 'zs-cl/dataquality',
    component: ContentComponent,
    data: { },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DataQualityRulesComponent, canActivate: [AuthGuard] },
      { path: ':rule_id', component: DataQualityRulesComponent, data: { breadcrumb: 'Data Quality Rules' }, canActivate: [AuthGuard],
        children: [    
          { path: '', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] }, 
          { path: 'business/rule-definition', component: BusinessRuleDefinitionComponent, data: { breadcrumb: 'Business View' }, canActivate: [AuthGuard] },
          { path: 'business/applied-columns', component: BusinessAppliedColumnsComponent, canActivate: [AuthGuard] },
          { path: 'business/user-entitlements', component: BusinessUserEntitlementsComponent, canActivate: [AuthGuard] },
          { path: 'business/run-schedule', component: BusinessRunScheduleComponent, canActivate: [AuthGuard] },
          { path: 'business/run-execution-results', component: BusinessRunExecutionResultsComponent, canActivate: [AuthGuard] },
          { path: 'technical/rule-definition', component: TechnicalRuleDefinitionComponent, data: { breadcrumb: 'Technical View' }, canActivate: [AuthGuard] },
          { path: 'technical/applied-columns', component: TechnicalAppliedColumnsComponent, canActivate: [AuthGuard] },
          { path: 'technical/user-entitlements', component: TechnicalUserEntitlementsComponent, canActivate: [AuthGuard] },
          { path: 'technical/run-schedule', component: TechnicalRunScheduleComponent, canActivate: [AuthGuard] },
          { path: 'technical/run-execution-results', component: TechnicalRunExecutionResultsComponent, canActivate: [AuthGuard] },
        ]
      }
    ]
  },
  {
    path: 'zs/success-matrix',
    component: ConsumptionReportComponent,
    data: { breadcrumb: 'Consumption Report' },
    canActivate: [AuthGuard]
  },
  {
    path: 'zs-cl/home',
    component: ZsClHomeComponent,
    data: { breadcrumb: 'Home' },
    canActivate: [AuthGuard]
  },
  {
    path: 'zs-cl/catalogs',
    component: ContentComponent,
    data: { breadcrumb: 'Data Catalogs' },
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ZsClCatalogsComponent, data: { breadcrumb: 'Data Catalogs' }, canActivate: [AuthGuard] },
      { path: 'create-catalog', component: ZsClCreateCatalogComponent, data: { breadcrumb: 'Create New Catalog' }, canActivate: [AuthGuard] },
      { path: ':catalog_id', component: ContentComponent, data: { breadcrumb: '...', type: 'catalog' }, canActivate: [AuthGuard, CatalogRouteGuard],
        children: [
          { path: '', component: ZsClCatalogObjectsComponent, data: { breadcrumb: 'Semantic Objects' }, canActivate: [AuthGuard] },         
          { path: 'objects', component: ZsClCatalogObjectsComponent, canActivate: [AuthGuard] },
          { path: 'dq-rule', component: ContentComponent, data: { breadcrumb: 'Data Quality Management', type: 'dq-rule' }, canActivate: [AuthGuard],
            children: [
              { path: '', component: ZsClCatalogLevelDqManagementComponent },
              {
                path: 'manage-rules', component: DataQualityRulesComponent, data: { breadcrumb: 'Manage Rules', type: 'dq-rule' }, canActivate: [AuthGuard],
                children: [
                  { path: ':rule_id', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] },
                ]
              },
            ]
              },
          { path: 'objects/:object_id', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts', type: 'object' }, canActivate: [AuthGuard],
            children: [
              { path: '', component: ZsClCatalogObjectConceptRightComponent, canActivate: [AuthGuard] },
              { path: 'concepts', component: ZsClCatalogObjectConceptRightComponent, data: { breadcrumb: 'Concepts', type: 'concept' }, canActivate: [AuthGuard] },
             
            ]
          },
          { path: 'users', component: UsersComponent, data: { breadcrumb: 'Manage Project Users' }, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
          { path: 'users/objects', component: ZsClCatalogObjectsComponent, canActivate: [AuthGuard] },
          { path: 'users/objects/:object_id', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts', type: 'object' }, canActivate: [AuthGuard],
            children: [
              { path: '', component: ZsClCatalogObjectConceptRightComponent, canActivate: [AuthGuard] },
              { path: 'concepts', component: ZsClCatalogObjectConceptRightComponent, data: { breadcrumb: 'Concepts', type: 'concept' }, canActivate: [AuthGuard] },
             
            ]
          },
          { path: 'concepts', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts', type: 'object' }, canActivate: [AuthGuard] },        
          { path: 'objects/:object_id/view-object-model', component: ZsClViewObjectModelComponent, data: { breadcrumb: 'Customer Object Model', type: 'view-model' }, canActivate: [AuthGuard] },        
          // { path: 'objects/:object_id', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts', type: 'object' }, canActivate: [AuthGuard] },
          { path: 'objects/:object_id/review-training', component: TmReviewTrainingDataComponent, data: { breadcrumb: 'Train Model', type: 'object' }, canActivate: [AuthGuard] },
          { path: 'objects/:object_id/select-datasets', component: TmSelectDataSetsComponent, data: { breadcrumb: 'Train Model', type: 'object' }, canActivate: [AuthGuard] },
          { path: 'objects/:object_id/map-data-columns', component: TmMapDataColumnsComponent, data: { breadcrumb: 'Train Model', type: 'object' }, canActivate: [AuthGuard] },

          { path: 'objects/:object_id/syn-select-data-catalog', component: SynSelectDataCatalogComponent, data: { breadcrumb: 'Synonyms', type: 'object' }, canActivate: [AuthGuard] },
          { path: 'objects/:object_id/syn-select-semantic', component: SynSelectSemanticObjectsComponent, data: { breadcrumb: 'Synonyms', type: 'object' }, canActivate: [AuthGuard] },
          { path: 'objects/:object_id/syn-map-concepts', component: SynMapConceptsComponent, data: { breadcrumb: 'Synonyms', type: 'object' }, canActivate: [AuthGuard] },

          // { path: 'objects/:object_id/finalize-training', component: TmReviewTrainingDataComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },

          { path: 'objects/:object_id', component: ContentComponent, data: { breadcrumb: '...', type: 'object' }, canActivate: [AuthGuard],
            children: [
              { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Semantic Objects' }, canActivate: [AuthGuard] },
              { path: 'concepts/review-training', component: TmReviewTrainingDataComponent, data: { breadcrumb: 'Train Model', type: 'concept' }, canActivate: [AuthGuard] },
              { path: 'concepts/select-datasets', component: TmSelectDataSetsComponent, data: { breadcrumb: 'Train Model', type: 'concept' }, canActivate: [AuthGuard] },
              { path: 'concepts/map-data-columns', component: TmMapDataColumnsComponent, data: { breadcrumb: 'Train Model', type: 'concept' }, canActivate: [AuthGuard] },

              { path: 'concepts/syn-select-data-catalog', component: SynSelectDataCatalogComponent, data: { breadcrumb: 'Synonyms', type: 'concept' }, canActivate: [AuthGuard] },
              { path: 'concepts/syn-select-semantic', component: SynSelectSemanticObjectsComponent, data: { breadcrumb: 'Synonyms', type: 'concept' }, canActivate: [AuthGuard] },
              { path: 'concepts/syn-map-concepts', component: SynMapConceptsComponent, data: { breadcrumb: 'Synonyms', type: 'concept' }, canActivate: [AuthGuard] },
              { path: 'dq-rule', component: ContentComponent, data: { breadcrumb: 'Data Quality Management', type: 'dq-rule' }, canActivate: [AuthGuard],
              children:[
                {path:'', component: ZsClSemanticLevelDqManagementComponent},
                {
                  path: 'manage-rules', component: DataQualityRulesComponent, data: { breadcrumb: 'Manage Rules', type: 'dq-rule' }, canActivate: [AuthGuard],
                  children: [
                    { path: ':rule_id', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] },
                  ]
                },                 
              ]
              },

              {path: 'concepts/:concept_id',component:ContentComponent, data: { breadcrumb: 'Rules', type: 'concept' }, canActivate: [AuthGuard],
              children:[ 
                { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Semantic Objects', type: 'object' }, canActivate: [AuthGuard] },
                {path:'dq-rules', component:ContentComponent, data: { breadcrumb: 'Data Quality Management', type: 'dq-rule' }, canActivate: [AuthGuard],
                children:[
                  {path:'', component: ZsClDQManagementComponent},
                  {
                    path: 'manage-rules', component: DataQualityRulesComponent, data: { breadcrumb: 'Manage Rules', type: 'dq-rule' }, canActivate: [AuthGuard],
                    children: [
                      { path: ':rule_id', component: BusinessRuleDefinitionComponent, canActivate: [AuthGuard] },
                    ]
                  },              
                ]
               }
              ]
               },
              { path: 'concepts/data-quality-rules', component: BussinessRulesComponent, canActivate: [AuthGuard] ,
              children:[
                {path: '', component:OutOfBoxRuleComponent, canActivate:[AuthGuard]},
                {path: 'business-rule-definition', component:BusinessRuleDefinitionComponent, canActivate:[AuthGuard]},
                {path: 'review-mapping', component:ReviewPriorMappingsComponent, canActivate:[AuthGuard]},
                {path: 'select-dataset', component:SelectDatasetComponent, canActivate:[AuthGuard]},
                {path: 'map-data-columns', component:MapDataColumnComponent, canActivate:[AuthGuard]},
                {path: 'business-rule-definition', component:BusinessRuleDefinitionComponent, canActivate:[AuthGuard]},
                { path: 'add-users', component: DqUsersComponent, canActivate: [AuthGuard] },
                {path: 'bussiness-run-schedule', component:BusinessRunScheduleComponent, canActivate:[AuthGuard]},
              ]},

            ]}



          // { path: 'objects', component: ContentComponent, data: {type: 'object' }, canActivate: [AuthGuard],
          //   children: [
          //     { path: '', component: ZsClCatalogObjectsComponent, data: { type: 'object' }, canActivate: [AuthGuard] },
          //     { path: ':object_id', component: ContentComponent, data: { type: 'object' }, canActivate: [AuthGuard],
          //       children: [
          //         { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts' }, canActivate: [AuthGuard] },
          //         { path: 'review-training', component: TmReviewTrainingDataComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //         { path: 'select-datasets', component: TmSelectDataSetsComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //         { path: 'map-data-columns', component: TmMapDataColumnsComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //         { path: 'concepts', component: ZsClCatalogObjectConceptsComponent, canActivate: [AuthGuard],
          //           children: [
          //             { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts' }, canActivate: [AuthGuard] },
          //             { path: ':concept_id', component: ContentComponent, data: { breadcrumb: '...', type: 'concept' }, canActivate: [AuthGuard],
          //               children: [
          //                 // { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts' }, canActivate: [AuthGuard] },
          //                 { path: 'review-training', component: TmReviewTrainingDataComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //                 { path: 'select-datasets', component: TmSelectDataSetsComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //                 { path: 'map-data-columns', component: TmMapDataColumnsComponent, data: { breadcrumb: 'Train Model', type: 'train' }, canActivate: [AuthGuard] },
          //               ]} 
          //           ]}
          //       ]}
          //   ]}



          // { path: 'objectsnew', component: ZsClCatalogObjectConceptsNewComponent, data: { breadcrumb: 'Concepts', type: 'object' }, canActivate: [AuthGuard] },
          // { path: 'objects', component: ZsClCatalogObjectsComponent, canActivate: [AuthGuard], 
          //   children: [
          //     { path: '', component: ZsClCatalogObjectsComponent, data: { breadcrumb: 'Semantic Objects' }, canActivate: [AuthGuard] },
          //     { path: ':object_id', component: ContentComponent, data: { breadcrumb: '...', type: 'object' }, canActivate: [AuthGuard],
          //       children: [
          //         { path: '', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts' }, canActivate: [AuthGuard] },
          //         { path: 'concepts', component: ZsClCatalogObjectConceptsComponent, data: { breadcrumb: 'Concepts' }, canActivate: [AuthGuard] },
          //       ]
          //     }
          //   ] 
          // },
        ]
      }
    ],
  },

  {
    path: 'z-ing/ingestion',
    component: ZsInIngestionComponent,
    data: { breadcrumb: 'Ingestion' },
    canActivate: [AuthGuard]
  },
  {
    path: 'z-ing/transformation',
    component: ZsInTransformationComponent,
    data: { breadcrumb: 'Transformation' },
    canActivate: [AuthGuard]
  },
  { path: 'redirection', component: ZsInRedirectionComponent, data: { breadcrumb: 'Redirection' }, canActivate: [AuthGuard] },
  // { path: 'redirection/zs-cl', component: ZsInRedirectionComponent, canActivate: [AuthGuard] },
  {
    path: AppGlobals.NOTIFICATION_PATH,
    component: NotificationComponent
  },
  {
    path: 'zs/insight',
    component: ZettainsightComponent,
    data: { breadcrumb: 'insight' },
  },
  {
    path: 'zs/styleguide',
    component: StyleguideComponent,
    data: { breadcrumb: 'Styleguide' },
  },
  {
    path: 'zs-cl/text-select',
    component: TextSelectDemoComponent,    
  }
];


@NgModule({
  imports: [RouterModule.forRoot(ROUTES,
    {
      useHash: true,
      preloadingStrategy: AppCustomPreloader,
      onSameUrlNavigation: 'reload',
      // enableTracing: true  // <-- debugging purposes only
    })
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
export class AppRoutingModule {
}