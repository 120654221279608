import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ZmAuthGuard implements CanLoad {

  constructor(private router: Router) {
    const self = this;

  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    const self = this;
    const callback = () => {
      if (!self.checkLogin(url)) {
        return false;
      }
      return true;
    };
    return callback();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    const isZmloggedIn = sessionStorage.getItem('isZmloggedIn') === 'true' ? true : false;
    const rememberMe = sessionStorage.getItem('rememberMe');
    if (!isZmloggedIn) {
      this.router.navigate(['/zm/login']);
      return false;
    }
    return true;
  }


  get isZmLoggedin() {
    if (sessionStorage.getItem('isZmloggedIn')) {
      return true;
    } else {
      return false;
    }
  }

}
