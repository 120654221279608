import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../project-progress/project-progress.model';


@Component({
    selector:'zetta-custom-rule',
    templateUrl:'./select-dataset.component.html'
})
export class SelectDatasetComponent implements OnInit{

    progressState = new ProjectStatus();
    constructor(public zettaUtils: ZettaUtils,private router:Router,private route:ActivatedRoute) { }
    ngOnInit(): void {
        this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
        this.progressState.currentStateIndex = 4;
        this.progressState.type = 'create-bussiness-rules';
        this.progressState.type = 'classifySet';  
    }
    onPrevStep(){
        this.router.navigate(['./'],{relativeTo:this.route.parent});
    }
}