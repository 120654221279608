import { ZettaUtils } from './../../../../common/shared/zettaUtils';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from './../../../../common/components/project-progress/project-progress.model';
import { DatasourceService } from '../datasource.service';
import { Datasource } from '../datasource.model';
import * as _ from 'lodash';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { MessageService } from '../../message/message.service';

@Component({
  selector: 'zetta-create-datasource',
  templateUrl: './create-datasource.component.html',
  styleUrls: ['./create-datasource.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateDatasourceComponent implements OnInit {

  createDatasourceForm: FormGroup;
  isDataReady = false;
  datasource = new Datasource();
  errorMessage = undefined;
  showBreadcrumb = false;
  progressState = new ProjectStatus();
  dataSourceType: any = [];
  selectedDataSource: any;
  loggedInUserDetails: [];
  loadProject = false;
  @ViewChild('focusElement') focusElement;
  cloudStorageSubTypes = []; 
  isFormValid = false;
  selected_subType_lookup_id : number;
  relationalDBSubTypes: [];
  isRelationalDatabase: Number = AppGlobals.RELATIONAL_DATABASE;
  isEditFlow = false;

  constructor(public zettaUtils: ZettaUtils,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datasourceSvc: DatasourceService,
    private messageSvc: MessageService) {
  }

  ngOnInit() {
    this.datasource.datasource_id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');

    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('sub_type_id')) {
        this.selected_subType_lookup_id = +params.get('sub_type_id');
      }
      this.isEditFlow = params.get('editView') === 'true' ? true : false;
    });

    if (this.datasource.datasource_id !== undefined && this.datasource.datasource_id !== null) {
      this.loadProject = true;
      this.getDatasource();
      sessionStorage.setItem('newDataSource', 'false');
    } else {
      this.getDatasourceGroup();
      sessionStorage.removeItem('defineconnectionpath');
      sessionStorage.removeItem('TotalUsers');
      sessionStorage.setItem('newDataSource', 'true');
    }

    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }

    this.progressState.states = this.zettaUtils.getStateList('create-datasource');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Source';  
    this.progressState.isEdit = this.loadProject ? true : false;
    this.getStorageLayers();
    this.getRelationalDBTypes();
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if(this.focusElement){
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }
  
  setDatasourceForm() {
    this.createDatasourceForm = this.formBuilder.group({
        title: [this.datasource.name, [Validators.required, Validators.pattern("^[A-Za-z0-9_.!]+( [A-Za-z0-9_.!]+)*")]],
        description: [this.datasource.description],
        datasourceType: [{value : this.datasource.lookup_id,disabled: this.isEditFlow}, Validators.required]
    });
  }

  getDatasource() {
    this.datasourceSvc.getDatasource({userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], typeId: this.datasource.datasource_id}).subscribe(responseList => {
        this.datasource = responseList;
        this.datasource.lookup_id = this.datasource['datasource_type_id'].toString();
        this.progressState.datasource = this.datasource;        
        this.selectedDataSource = this.datasource;        
        if ((responseList.datasource_type_id === AppGlobals.CLOUD_OBJECT_STORAGE || this.selectedDataSource.lookup_id == AppGlobals.RELATIONAL_DATABASE) && responseList.datasource_sub_type_id) {
          this.selected_subType_lookup_id = responseList.datasource_sub_type_id; 
        }
        this.setDatasourceForm();
        this.getDatasourceGroup();
        this.validateForm();
      }, err => {
        this.messageSvc.sendMessage({ message: 'You do not have permission to access this resource, Please ask your administrator', type: 'INFO', hideboard: true });
        this.router.navigate(['/zs/datasources']);
      });
  }

  getDatasourceGroup() {
    this.datasourceSvc.getDatasourceTypes({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] }).subscribe(responseList => {
        this.dataSourceType = responseList['datasourcetypes'];       
        this.dataSourceType = _.orderBy(this.dataSourceType, ['sequence', 'column'], ['asc']);
        this.selectedDataSource = this.dataSourceType.find(res => res['lookup_id'] === this.datasource['datasource_type_id']);
        this.isDataReady = true;
      });
    if (!this.loadProject) {
      this.setDatasourceForm();
    }
  }

  onSubmit() {
    if (this.createDatasourceForm.valid) {
      const datasourceTemp = {
        'name': this.createDatasourceForm.controls.title.value,
        'description': this.createDatasourceForm.controls.description.value,
        'tenant_id': this.loggedInUserDetails['tenant_id'],
        // 'connection_string': '/data/' + this.loggedInUserDetails['tenant_id'] + '/raw',
        'connection_string': this.datasource['connection_string'] || '',
        'datasource_type_id': this.createDatasourceForm.controls.datasourceType.value,
        'connection_param': this.datasource['connection_param'] || ''
      };
      if (this.selectedDataSource.lookup_id == AppGlobals.RELATIONAL_DATABASE) {
        datasourceTemp['datasource_sub_type_id'] = this.selected_subType_lookup_id;
      }
      const sub_type_id = this.selected_subType_lookup_id ? { sub_type_id: this.selected_subType_lookup_id } : {};
      if (this.datasource.datasource_id !== undefined && this.datasource.datasource_id !== null) {
          this.datasourceSvc.updateDatasource({userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], payload: datasourceTemp, sourceId: this.datasource.datasource_id}).subscribe(responseList => {
            this.router.navigate(['/zs/datasources', this.datasource.datasource_id, 'define-connection'], { queryParams: { 'id': this.datasource.datasource_id, showBreadcrumb: this.showBreadcrumb, ...sub_type_id }, queryParamsHandling: 'merge' });
          }, err => {
            this.errorMessage = err.error.message;
          });
      } else {
          this.datasourceSvc.createDatasource({userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], payload: datasourceTemp}).subscribe(responseList => {
            this.router.navigate(['/zs/datasources', responseList.datasource_id, 'define-connection'], { queryParams: { 'id': responseList.datasource_id, showBreadcrumb: this.showBreadcrumb,...sub_type_id } });
          }, err => {
            this.errorMessage = err.error.message;
          });
      }
    }
  }

  isRadioBtnChecked(inputSource: any) {
    if (inputSource) {
      this.selectedDataSource = inputSource;
    }
    this.selected_subType_lookup_id = null;
    this.validateForm();
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }

  onTextChange() {
    this.validateForm();
    if (this.errorMessage) {
      this.errorMessage = false;
    }
  }
  getStorageLayers(){
    this.datasourceSvc.getDataCloudStorageLayer('DatasourceSubTypes_HDFS').subscribe(resp=>{
    this.cloudStorageSubTypes = resp;
    this.cloudStorageSubTypes = this.cloudStorageSubTypes.sort((n1, n2) => {
      if(n1.lookup_id > n2.lookup_id){
        return 1;
      }else{
        return -1;
      }
    });  
    if(this.cloudStorageSubTypes.length){
      this.cloudStorageSubTypes.forEach(element => {        
        if(element.lookup_id == 327){
          element.logo ='../../../../../assets/images/common/icon-AzureDataLake.png';
          element.subType ='Azure Data Lake';
        }
        if(element.lookup_id == 328){
          element.logo ='../../../../../assets/images/common/icon_aws.png';
          element.subType ='AWS Data Lake';
        }
      });
    }
    },error=>{
    });
  }
  datasourceSubType(cloudStorage) {
    if (cloudStorage.lookup_id) {
      this.selected_subType_lookup_id = cloudStorage.lookup_id;
    }
    this.validateForm();
  }
  validateForm() {
    if (this.createDatasourceForm.valid) {
      if (this.selectedDataSource.lookup_id == AppGlobals.CLOUD_OBJECT_STORAGE || this.selectedDataSource.lookup_id == AppGlobals.RELATIONAL_DATABASE) {
        if (this.selected_subType_lookup_id) {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else {
        this.isFormValid = true;
      }
    } else {
      this.isFormValid = false;
    }
  }

  getRelationalDBTypes() {
    this.datasourceSvc.getDataCloudStorageLayer('DatasourceSubTypes_RDBMS').subscribe(resp => {
      resp.forEach(element => {
        if (element.lookup_id == AppGlobals.POSTGRES_SQL) {
          element.name = 'Postgresql';
        }
      });
      this.relationalDBSubTypes = resp;
    }, error => {
    });
  }
}


