import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zetta-zm-donut-chart',
    templateUrl: './zm-donut-chart.component.html',
    styleUrls: ['./zm-donut-chart.component.scss']
})
export class ZmDonutChartComponent implements AfterViewInit {
    public donut: any = {};
    public chartHeight: number;
    public chartWidth: number;
    public isChartInValid = false;
    public $container;
    public totalCount = 0;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;

    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(private cdRef: ChangeDetectorRef, public zettaUtils: ZmZettaUtils, public observeSvc: ZmObservableService) {}

    ngAfterViewInit() {
        const parent = this;
        parent.isChartInValid = (!parent.dataDataSource || parent.dataDataSource.length === 0) ? true : false;
        if (!parent.chartColors) {
            parent.chartColors = ['#483da0', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }

        if (parent.dataDataSource && !parent.isChartInValid) {
            const maxValue = Math.max.apply(Math, parent.dataDataSource.map(function(o) {
                parent.totalCount += parseFloat(o[parent.chartOptions['variable']]);
                return o[parent.chartOptions['variable']];
            }));
            if (maxValue <= 0) {
                parent.isChartInValid = true;
                // return false;
            } else {
                parent.renderChart();
            }
        }

        parent.cdRef.detectChanges();
        parent.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    donutChart() {
        const parent = this;
        const floatFormat = d3.format('.4r');
        const percentFormat = d3.format(',.1%');
        const width = parent.chartWidth * 0.64;
        const height = parent.chartHeight;
        const radius = Math.min(width, height) / 2;
        const color = d3.scaleOrdinal().range(parent.chartColors);
        const data = parent.dataDataSource;
        const arc = d3.arc().innerRadius(radius * 0.8).outerRadius(radius * 0.6);
        const pie = d3.pie().value(function(d) {
            return floatFormat(d[parent.chartOptions['variable']]);
        }).sort(null);

        $('#chart_' + parent.chartUniqueID).empty();

        const rootNode = d3.select('#chart_' + parent.chartUniqueID);

        const tooltip = rootNode.append('div').attr('class', 'toolTip');

        const svg = rootNode
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .attr('class', 'pull-left');

        const g = svg.append('g')
            .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');
        // Center Text
        g.append('text')
            .attr('class', 'toolCircle')
            .attr('dy', -15)
            .html(function(d) {
                if (parent.chartOptions['isMoneyFormat'] !== undefined && !parent.chartOptions['isMoneyFormat']) {
                    return '<tspan x="3px" dy="3px" class="text-bold-black f-size-20 default-color">' +
                    parent.zettaUtils.commarization(parent.chartOptions['labelPercentValue'], 0) + '</tspan>' + ' '
                    + '<tspan x="0" dy=".8vw" class="center-label">' + parent.chartOptions['labelPercentLabel'] + '</tspan>';
                } else if (parent.chartOptions['isNumberFormat']) {
                    return '<tspan x="-1px" dy="3px" class="text-bold-black f-size-20 default-color">' +
                    parent.zettaUtils.commarization(parent.chartOptions['labelPercentValue'], 0) +
                    '</tspan><tspan x="0" dy="10" class="center-label">' + parent.chartOptions['labelPercentLabel'] + '</tspan>';
                } else {
                    let textStr = '<tspan x="3px" dy="3px" class="text-bold-black f-size-20 default-color">' +
                    parent.chartOptions['labelPercentValue'] + '</tspan><tspan style="font-size: 16px;">%</tspan>' + ' '
                    + '<tspan x="0" dy="10" class="center-label">' + parent.chartOptions['labelPercentLabel'] + '</tspan>';
                    if (textStr && parent.chartOptions['labelPercentLabel2']) {
                        textStr += '<tspan x="0" dy="9" class="center-label">' + parent.chartOptions['labelPercentLabel2'] + '</tspan>';
                    }
                    return textStr;
                }
            })
            .style('text-anchor', 'middle');

        const path = g.selectAll('path')
            .data(pie(data))
            .enter()
            .append('g')
            .append('path')
            .attr('d', arc)
            .attr('fill', (d, i) => getColor(d.data['Current Model'], i))
            .on('mousemove', function(d) {
                tooltip
                    .style('left', d3.event.pageX - 35 + 'px')
                    .style('top', d3.event.pageY - 35 + 'px')
                    .style('display', 'inline-block')
                    .html(function() {
                        if (parent.chartOptions['isMoneyFormat'] !== undefined && !parent.chartOptions['isMoneyFormat']) {
                            return '<tspan x="0" class="text-bold default-color">' +
                             parseFloat(d3.format('.0%')(d.data[parent.chartOptions['variable']])) + '</tspan>%';
                        } else if (parent.chartOptions['isNumberFormat']) {
                            let textStr = '<tspan x="0" class="text-bold default-color">' +
                             (d.data[parent.chartOptions['variable']]) + ' Tasks </tspan>';
                            if (parent.chartOptions['isPercentRequired']) {
                                // console.log(parent.totalCount,)
                                textStr += '( <tspan x="0" class="text-bold default-color">' +
                                 Math.round((parseFloat(d.data[parent.chartOptions['variable']]) / parent.totalCount) * 100) + '% )';
                            }
                            return textStr;
                        } else {
                            let textStr = '<tspan x="0" class="text-bold default-color">' +
                            d3.format(',')(d.data[parent.chartOptions['variable']]) + ' Records </tspan>';
                            if (parent.chartOptions['isPercentRequired']) {
                                textStr += '( <tspan x="0" class="text-bold default-color">' +
                                 Math.round((parseFloat(d.data[parent.chartOptions['variable']]) / parent.totalCount) * 100) + '% )';
                            }
                            return textStr;
                        }
                    });
            })
            .on('mouseout', function(d) { tooltip.style('display', 'none'); })
            .transition()
            .duration(1000)
            .attrTween('d', function (d) {
                const i = d3.interpolate(d.startAngle, d.endAngle);
                return function (t) {
                    d.endAngle = i(t);
                    return arc(d);
                };
            })
            .each(function(d, i) { this._current = i; });

        const legend = rootNode.append('div')
            .attr('class', 'legend pull-rigt')
            .style('margin-top', '30px');

        const keys = legend.selectAll('.key')
            .data(data)
            .enter().append('div')
            .attr('class', 'key')
            .style('display', 'flex')
            .style('align-items', 'center');

        keys.append('div')
            .attr('class', 'symbol')
            .style('height', '10px')
            .style('width', '10px')
            .style('margin', '5px 5px')
            .style('background-color', (d, i) => color(i));

        keys.append('div')
            .attr('class', 'name')
            .text(d => `${d[parent.chartOptions['category']]}`);

        keys.exit().remove();

        function getColor(text, i) {
            text = text || '';
            switch (text.toLowerCase()) {
                case 'high':
                    return '#4bb100';
                    break;
                case 'medium':
                    return '#eeb049';
                    break;
                case 'low':
                    return '#d35162';
                    break;
                default:
                    return color(i);
            }
        }

    }

    renderChart() {
        this.$container = $('#chart_' + this.chartUniqueID).parent().closest('div');
        if (this.chartOptions['height']) {
            this.chartHeight = this.zettaUtils.vhTOpx(this.chartOptions['height']) <= 150 ? 150 :
            this.zettaUtils.vhTOpx(this.chartOptions['height']);
        } else {
            this.chartHeight = this.$container.height();
        }

        if (this.chartOptions['width']) {
            this.chartWidth = this.zettaUtils.vhTOpx(this.chartOptions['width']) <= 248 ? 248 :
            this.zettaUtils.vhTOpx(this.chartOptions['width']);
        } else {
            this.chartWidth = this.$container.width();
        }
        const parent = this;

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        setTimeout(() => {
            parent.donutChart();
        }, 500);
    }

    onResize(event) {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }
}
