import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";
import { MessageService } from "src/app/common/components/message/message.service";
import { ProjectStatus } from "src/app/common/components/project-progress/project-progress.model";
import { hyperLinkFormattter } from "src/app/common/shared/formatters/hyperLinkFormatter";
import { ZettaUtils } from "src/app/common/shared/zettaUtils";
import { environment } from "src/environments/environment";
import { ZsClContentService } from "../../zs-cl-content.service";
import { AppGlobals } from "src/app/common/shared/app.globals";

const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;
}
@Component({
    selector: 'zetta-zs-cl-add-training-data',
    templateUrl: './zs-cl-add-training-data.component.html',
    styleUrls: ['./zs-cl-add-training-data.component.scss']
})

export class ZsClAddTrainingDataComponent implements OnInit, OnDestroy {
    activeNavLnk: string;
    gridOptions: object;
    total = 0;
    leftDstotal = 0;
    grandTotalTrainingData = 0;
    subTotalTrainingData = '';
    hasScrolled = false;
    page = 1;
    limit= 100;
    grandTotalConcept = 0;
    subTotalConcept = '';
    public loggedInUserDetails = null;
    progressState = new ProjectStatus();
    isDataReady: boolean = false;
    showBreadcrumb: boolean = false;
    tableSettings: object = { 'height': "100%", 'width': "99.40%",'pageSize': 20 };
    dataSourceTableSettings: object = { 'height': "100%", 'width': "99.40%",'pageSize': 20 };
    inputTableSettings: object = { 'height': "100%", 'width': "99.30%" };
    profile_report: any;
    dataset_id: number;
    is_profile_loading: boolean = false;
    is_no_data: boolean = true;
    is_primary_key: boolean = false;
    conceptGrid: AngularGridInstance;
    conceptSource: any = [];
    conceptGridColumnsDef: any = [];
    inputConceptGrid: AngularGridInstance;
    inputConceptSource: any = [];
    inputConceptgridColumnsDef: any = [];
    dataSourceGrid: AngularGridInstance;
    dataSource: any = [];
    datasourceGridColumnsDef: any = [];
    datasourceLeftGridColumnsDef: any = [];
    mappedTrainingGrid: AngularGridInstance;
    rightdataSource: any = [];
    showNoDataImg = true;
    project_id: string;
    totalInput = 0;
    totalConcept = 0;
    conceptRows = 0;
    totalTrainingData = 0;
    totalMappedTrainingData = 0;
    rightGridDataSource: any = [];
    semantic_object: string;
    catalog_id: string;
    entity_id: string;
    datasource_id:string;
    source_id:string;
    isDataSourceReady=false;
    hasConceptSelected=false;
    hasInputSelected=false;
    hasDatasourceSelected=false;
    hasRightDataSelected=false;
    projectData:any;
    isLoading=true;
    onSelectDataLoader: boolean = false;
    showmodelpopup=false;
    datasetName:string;
    datasetId:string;
    isFiltering = false;
    isEditProject : string
    conceptsNextPage: boolean = true;
    constructor(private zettaUtils: ZettaUtils,
        private catalogSvc: ZsClContentService, private route: ActivatedRoute,
        private router: Router, private messageSvc: MessageService
    ) { }

    ngOnInit() {
        sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        $('#nodata').addClass('d-none');
        this.route.parent.params.subscribe(params => {
            this.project_id = params['id'];
        });
        this.isEditProject= this.route.snapshot.queryParamMap.get('isEditProject');
        const showBreadcrumb = this.route.snapshot.queryParamMap.get('showBreadcrumb');
        this.route.queryParamMap.subscribe(param => {
            this.catalog_id = param.get("catalog_id");
            this.entity_id = param.get("entity_id");
            if (param.has('datasource_id') && param.has('dataset_id') && param.has('source_id')) {
                this.datasource_id = param.get('datasource_id');
                this.dataset_id = +param.get('dataset_id');
                this.source_id = param.get('source_id');
            }
        })
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }
        if (!this.showBreadcrumb) {
            $('#breadcrumb').addClass('d-none');
        }
        this.activeNavLnk = sessionStorage.getItem("activeStep") ? sessionStorage.getItem("activeStep") : "1";
        this.conceptGridColumnsDef = [{
            'displayname': 'Concepts',
            'physicalname': 'logical_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 150,
            'maxWidth': 250,
            'headerCssClass': 'mr-top-1',
        }, {
            'displayname': 'Definitions',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'headerCssClass': 'mr-top-1',
        }
        ];

        this.inputConceptgridColumnsDef = [
            {
                'displayname': 'Input Concept',
                'physicalname': 'logical_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
            }
        ]
        this.datasourceGridColumnsDef = [
            {
                'displayname': 'Data Source',
                'physicalname': 'datasource_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth': 150,
                'formatter': dsNameFormattter
            },
            {
                'displayname': 'Data Set Name',
                'physicalname': 'name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth': 150,
                'formatter': hyperLinkFormattter
            }
        ]
        this.datasourceLeftGridColumnsDef = [
            {
                'displayname': 'Data Source',
                'physicalname': 'datasource_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth': 150,
                'formatter': dsNameFormattter
            },
            {
                'displayname': 'Data Set Name',
                'physicalname': 'name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth': 150,
                'formatter': hyperLinkFormattter
            }
        ];
        this.gridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
        };
        this.getSemaniticObject();
        this.getProject();
        if (this.activeNavLnk == "2") {
            this.getMappedTrainingData();
        }
        if (this.activeNavLnk == "1") {
            this.getTrainingInput(false,this.page, this.limit);
        }

    }

    
    getProject() {
        const payload = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id
        };
        this.catalogSvc.getclassifyProject(payload).subscribe(resp => {
          if(resp){
            this.projectData = resp;
            if (this.showBreadcrumb === true) {
                this.progressState.states = this.zettaUtils.getStateList('manage-project-data');
                this.progressState.currentStateIndex = 2;
                this.progressState.type = 'manageProjectData';
            }
            else {
                this.progressState.states = this.zettaUtils.getStateList('create-classify-project');
                this.progressState.currentStateIndex = 4;
                this.progressState.type = AppGlobals.CLASSIFY_PROJECT_APPLY;
                this.progressState.classificationProject = resp;
                this.progressState.isEdit = this.isEditProject === 'true';
            }
            this.progressState.currentStateInfo = 'Saved...';
          }
          this.isDataReady = true;
        });
    }
    getSemaniticObject() {
        this.catalogSvc.getSemanticObject(this.catalog_id, this.entity_id).subscribe(resp => {
            if (resp) {
                this.semantic_object = resp.semantic_object;
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }
    onScroll(parentClass) {
        if(sessionStorage.getItem("serverfilter")){
            // this.page = 1;
            // this.hasScrolled = false;
            this.isFiltering = true;
          } else {
            if(this.isFiltering){
              this.page = 1;
              this.isFiltering = false;
            }
          }
        if (this.zettaUtils.virtualScroll(parentClass) && this.conceptsNextPage) {
          this.hasScrolled = true;
          this.onNext();
        }
      }

      onNext(): void {
        this.page++;
        this.getTrainingInput(false,this.page, this.limit);
      }
      onPrev(): void {
        this.page--;
        this.getTrainingInput(false,this.page, this.limit);
      }
    
      totalPages(): number {
        return this.zettaUtils.getTotalPages(this.total, this.limit);
      }


    bindConceptGrid(resp) {
        if (resp) {
            this.conceptSource = resp['currentpage'];
            this.conceptSource = this.conceptSource.filter(attr1 => !this.inputConceptSource.find(attr2 => attr1.attribute_id === attr2.attribute_id));
            this.totalConcept = resp['totalrecords']
            if(this.rightGridDataSource.length>0||this.inputConceptSource.length>0)
            {
                this.totalConcept =this.totalConcept -this.inputConceptSource.length;
            }
            this.total=resp['totalrecords'];
            this.conceptsNextPage = resp['next'];
            this.isLoading = false;
            let concatedRows = this.conceptSource;
            if (this.conceptGrid) {
                concatedRows = [...this.conceptGrid.dataView.getItems(), ...this.conceptSource];
                this.conceptSource = resp['currentpage'].map((item) => {
                    item['action'] = 'edit';
                    item['id'] = Math.random();
                    return item;
                });
                this.conceptSource = concatedRows;
                this.conceptGrid.dataView.setItems(concatedRows);
                this.conceptGrid.gridService.setSelectedRows([]);
                this.conceptGrid.dataView.refresh();
            }
        }
    }
    getTrainingInput(reload = false, pageNo, limit) {      
       // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.entity_id}/concepts?pageno=${1}&pagesize=${limit}`;   
        if(sessionStorage.getItem("serverfilter")){
            var serverfilter = sessionStorage.getItem("serverfilter");
            this.isFiltering = true;
            // pageNo = 1;
            // this.page = 1;
          } else {
            if(this.isFiltering){ 
              pageNo = 1;
            }
            var serverfilter = "";
            this.isFiltering = false;
          }
       let sortfilter="";
       if(sessionStorage.getItem("sortfilter")){
          sortfilter=sessionStorage.getItem("sortfilter");
       }
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
            catalog_id: this.catalog_id,
            object_id: this.entity_id,
            pageno: pageNo,
            pagesize: limit
        };
        this.catalogSvc.getTrainingInputData(data,serverfilter,sortfilter).subscribe(resp => {
            if (resp.length) {
                this.isLoading = false;
                if (resp[1]['currentpage'].length) {
                    this.inputConceptSource = resp[1]['currentpage'];
                    this.totalInput = resp[1].totalrecords;
                    let concatedRows = this.inputConceptSource;
                    this.inputConceptSource = resp[1]['currentpage'].map((item) => {
                        item['id'] = Math.random();
                        return item;
                    });
                    if (this.inputConceptGrid) {
                        // concatedRows = [...this.inputConceptGrid.dataView.getItems(), ...this.inputConceptSource];
                        this.inputConceptSource = resp[1]['currentpage'].map((item) => {
                            item['id'] = Math.random();
                            return item;
                        });
                        this.inputConceptSource = concatedRows;
                        this.inputConceptGrid.dataView.setItems(concatedRows);
                        this.inputConceptGrid.gridService.setSelectedRows([]);
                        this.inputConceptGrid.dataView.refresh();
                        const inputGridService = this.inputConceptGrid.gridService;
                        inputGridService.renderGrid();
                        this.hasScrolled = false;
                    }
                }
                if (!reload) {
                    if (pageNo === 1) {
                        this.grandTotalConcept = resp[0].totalrecords - resp[1].totalrecords;
                    }
                    this.bindConceptGrid(resp[0]);
                }
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }

    getMappedTrainingData(reload = false) {
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
        };
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
            serverFilter = sessionStorage.getItem("serverfilter");
        }
        let sortfilter = "";
        if (sessionStorage.getItem("sortfilter")) {
            sortfilter = sessionStorage.getItem("sortfilter");
        }
        //this.dataSourceTableSettings['search_url'] = `${environment.config.API_URL}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?checkAtrributes=true`;
        
        this.catalogSvc.getClassifierProjectTrainigData(data, serverFilter, sortfilter).subscribe(resp => {
            if (resp.length) {
                if (resp[1]['currentpage'].length) {
                    this.rightdataSource = resp[1]['currentpage'];
                    this.totalMappedTrainingData = resp[1].totalrecords;
                    let concatedRows = this.rightdataSource;
                    this.rightdataSource = resp[1]['currentpage'].map((item) => {
                        item['id'] = Math.random();
                        item['name'] = item.dataset_name
                        return item;
                    });
                    if (this.mappedTrainingGrid) {
                        // concatedRows = [...this.mappedTrainingGrid.dataView.getItems(), ...this.rightdataSource];
                        this.rightdataSource = concatedRows;
                        this.mappedTrainingGrid.dataView.setItems(concatedRows);
                        this.mappedTrainingGrid.gridService.setSelectedRows([]);
                        this.mappedTrainingGrid.dataView.refresh();
                        const mappedGridService = this.mappedTrainingGrid.gridService;
                        mappedGridService.renderGrid();
                    }

                }
                if (!reload) {                    
                    this.bindTrainingGrid(resp[0]);
                }
                this.onSelectDataLoader = false;
            }
        }, error => {
            this.onSelectDataLoader = false;
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }

    bindTrainingGrid(resp) {
        if (resp.length) {
            this.dataSource = resp;
            this.dataSource = this.dataSource.filter(attr1 => !this.rightdataSource.find(attr2 => attr1.dataset_id === attr2.dataset_id));
            this.totalTrainingData = this.dataSource.length;
            this.leftDstotal = this.totalTrainingData;
            if (this.page === 1) {
                this.grandTotalTrainingData = this.leftDstotal;
            }
            let concatedRows = this.dataSource;
            this.isDataSourceReady = true;
            if (this.dataSourceGrid) {
                concatedRows = [...this.dataSourceGrid.dataView.getItems(), ...this.dataSource];
                this.dataSource = resp.map((item) => {
                    item['id'] = Math.random();
                    return item;
                });
                this.dataSource = concatedRows;
                this.dataSourceGrid.dataView.setItems(concatedRows);
                this.dataSourceGrid.gridService.setSelectedRows([]);
                this.dataSourceGrid.dataView.refresh();
            }
        }
    }

    receiveLeftDataSourceRecords($event) {
        this.subTotalTrainingData = this.zettaUtils.getSubTotal(this.grandTotalTrainingData, $event);
    }

    onAddConcept() {
        this.isLoading = true;
        const leftGridDataService = this.conceptGrid.gridService;
        const rightGridDataService = this.inputConceptGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const conceptList: Object[] = [];
        const rows: any[] = [];

        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            this.inputConceptSource.push(row);
            rightGridDataService.addItemToDatagrid(row, true);
            // remove Item from left grid
            conceptList.push(row.attribute_id);
            this.conceptSource = this.conceptSource.filter(concept => concept.attribute_id !== row.attribute_id);
            leftGridDataService.deleteDataGridItem(row);
        });

        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        // Update Row Count
        this.totalConcept = this.totalConcept - conceptList.length;
        let rightConcepts = this.inputConceptGrid.dataView.getItems().length;
        this.grandTotalConcept = this.total - rightConcepts;
        this.totalInput = this.totalInput > 0 ? rightConcepts : 0;
        if (!this.conceptSource.length) {
            this.totalConcept = 0;
        }
        // update DB        
        if (conceptList.length) {
            this.addTrainingInput(conceptList);
        }
    }

    onRemoveConcept() {
        this.isLoading = true;
        const leftGridDataService = this.conceptGrid.gridService;
        const rightGridDataService = this.inputConceptGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const inputList: String[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                row.roles = [];
                rows.push(row);
            }
        });
        rows.forEach(row => {
            // add Item to left grid
            this.conceptSource.push(row);
            leftGridDataService.addItemToDatagrid(row, true);
            // remove Item from right grid
            inputList.push(row.classify_project_training_input_id);
            this.inputConceptSource = this.inputConceptSource.filter(concept => concept.classify_project_training_input_id !== row.classify_project_training_input_id);
            rightGridDataService.deleteDataGridItem(row);
        });
        this.conceptSource.forEach(element => {
            element['id'] = Math.random();
        });
        // this.leftGrid.slickGrid.setData();
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        this.totalConcept = this.totalConcept + inputList.length;
        let rightConcepts = this.inputConceptGrid.dataView.getItems().length;
        this.grandTotalConcept = this.total - rightConcepts;
        this.totalInput = this.totalInput > 0 ? rightConcepts : 0;
        if (inputList.length) {
            this.removeTrainingInput(inputList)
        }
    }

    addTrainingInput(concept_list) {
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
            payload: {
                concepts: concept_list
            }
        };
        this.catalogSvc.saveTrainingInput(data).subscribe(resp => {
            this.getTrainingInput(true, 1, this.limit);
        }, error => {
            this.isLoading = false;
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        })
    }
    removeTrainingInput(input_list) {
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
            payload: {
                body: {
                    classify_project_training_input_ids: input_list
                }
            }
        };
        this.catalogSvc.removeTrainingInput(data).subscribe(resp => {
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            if (error.error.message) {
                this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            }
        })
    }

    onAddTrainingData() {
        const leftGridDataService = this.dataSourceGrid.gridService;
        const rightGridDataService = this.mappedTrainingGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const trainingList: Object[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });
        rows.forEach(row => {
            trainingList.push({ dataset_id: row.dataset_id, datasource_id: row.datasource_id });
        });
        if (trainingList.length) {
            this.addTrainingData(trainingList, rows);
        }
    }
    onRemoveTrainingData() {
        const leftGridDataService = this.dataSourceGrid.gridService;
        const rightGridDataService = this.mappedTrainingGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const inputList: String[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                row.roles = [];
                rows.push(row);
            }
        });

        rows.forEach(row => {
            this.dataSource.push(row);
            leftGridDataService.addItemToDatagrid(row, true);
            // remove Item from right grid
            inputList.push(row.classify_project_training_data_id);
            this.rightGridDataSource = this.rightGridDataSource.filter(concept => concept.classify_project_training_data_id !== row.classify_project_training_data_id);
            rightGridDataService.deleteDataGridItem(row);
        });

        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);

        this.totalTrainingData = this.totalTrainingData + inputList.length;
        this.totalMappedTrainingData = this.totalMappedTrainingData > 0 ? this.totalMappedTrainingData - inputList.length : 0;
        if (inputList.length) {
            this.removeTrainingData(inputList)
        }
    }
    addTrainingData(training_list, selectedRows) {
        this.onSelectDataLoader = true;
        const leftGridDataService = this.dataSourceGrid.gridService;
        const rightGridDataService = this.mappedTrainingGrid.gridService;
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
            payload: training_list
        };
        this.catalogSvc.saveClassifireTrainingData(data).subscribe(resp => {
            selectedRows.forEach(row => {
                if(row) {
                    // remove Item from left grid               
                    this.dataSource = this.dataSource.filter(concept => concept.dataset_id !== row.dataset_id);
                    leftGridDataService.deleteDataGridItem(row);
                }
            });
            leftGridDataService.setSelectedRows([]);
            rightGridDataService.setSelectedRows([]);
            // Update Row Count
            this.totalTrainingData = this.totalTrainingData - training_list.length;
            this.totalMappedTrainingData = this.totalMappedTrainingData + training_list.length;
            this.getMappedTrainingData(true);
        }, error => {
            this.onSelectDataLoader = false;
            if (error.status == 400) {
                this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
            } else {
                this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            }

        })
    }
    removeTrainingData(input_list) {
        const data = {
            user_id: this.loggedInUserDetails.user_id,
            tenant_id: this.loggedInUserDetails.tenant_id,
            project_id: this.project_id,
            payload: {
                body: {
                    classify_project_training_input_data_ids: input_list
                }
            }
        };
        this.catalogSvc.removeClassifireTrainingData(data).subscribe(resp => {
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        })
    }

    onCellClick(e): void {
        const event = e.eventData;
        const args = e.args;
        const row = this.mappedTrainingGrid.gridService.getDataItemByRowIndex(args.row);
        if(event.target.title  === row.name){
            this.datasetName =row.name;
            this.datasetId = row.dataset_id;
            this.showmodelpopup =true;
        }
    }
    conceptGridCreated(grid) {
        const parent = this;
        parent.conceptGrid = grid;
        parent.conceptGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasConceptSelected = args.rows.length > 0 ? true : false;
        });
    }

    inputConceptgridCreated(grid) {
        const parent = this;
        parent.inputConceptGrid = grid;
        parent.inputConceptGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasInputSelected = args.rows.length > 0 ? true : false;
        });
    }
    onDataCellClick(e): void {
        const event = e.eventData;
        const args = e.args;
        const row = this.dataSourceGrid.gridService.getDataItemByRowIndex(args.row);
        if(event.target.title  === row.name){
            this.datasetName =row.name;
            this.datasetId = row.dataset_id;
            this.showmodelpopup =true;
        }
    }
    dataSourcegridCreated(grid) {
        const parent = this;
        this.dataSourceGrid = grid;
        parent.dataSourceGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasDatasourceSelected = args.rows.length > 0 ? true : false;
        });
    }
    rightGridCreated(grid: AngularGridInstance) {
        const parent = this;
        this.mappedTrainingGrid = grid;
        parent.mappedTrainingGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasRightDataSelected = args.rows.length > 0 ? true : false;
        });
    }

    onNextDefineConcept() {
        this.isLoading = true;
        this.activeNavLnk = "2";
        sessionStorage.setItem("activeStep", "2");
        this.getMappedTrainingData();
    }
    onPrevTrainingData() {
        this.activeNavLnk = "1";
        sessionStorage.setItem("activeStep", "1");
        this.getTrainingInput(false,this.page,this.limit);
    }

    onPrevStep() {
        sessionStorage.removeItem('activeStep');
        sessionStorage.setItem('activeLinkStep', "3");
        const qParams = { catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id: this.datasource_id, dataset_id: this.dataset_id, source_id: this.source_id, showBreadcrumb: this.showBreadcrumb };
        this.router.navigate(["/zs-cl/projects/" + this.project_id + "/new-classifier"], { queryParams: qParams,queryParamsHandling:'merge' });
    }
    onNextStepAddData() {
        sessionStorage.removeItem('activeStep');
        const qParams = { catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id: this.datasource_id, dataset_id: this.dataset_id, source_id: this.source_id, showBreadcrumb: this.showBreadcrumb };
        this.router.navigate(["/zs-cl/projects/" + this.project_id + "/add-data"], { queryParams: qParams ,queryParamsHandling:'merge'});
    }

    onCancel() {
        if (this.showBreadcrumb === true) { 
          this.router.navigate(['/zs-cl/projects/', this.project_id ], {queryParamsHandling:'preserve'});
        } 
        else {
          this.router.navigate(['/zs-cl/projects' ]);
        }                 
      }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
    }
    popupoutput(message){
        this.showmodelpopup = message;   
    }
    onUploadDataSource() {
        this.router.navigate(['/zs/datasets/select-data-source']);
    }
      onManageDataSet() {
        this.router.navigate(['/zs/datasets']);
    }
    receiveTotalRecords($event) {
        this.subTotalConcept = this.zettaUtils.getSubTotal(this.grandTotalConcept, $event);
    }
}