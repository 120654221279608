import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../common/services/base-service';


@Component({
  selector: 'zetta-zettainsight-sidebar',
  templateUrl: './zettainsight.component.html',
  styleUrls: ['./zettainsight.component.scss']
})
export class ZettainsightComponent implements OnInit {

	safeSrc: SafeResourceUrl;
  	isLeftSideBarActive = true;
  	url;

	constructor(private baseService: BaseService,
				private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.url = 'http://40.117.73.151:18630/';
    	this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}

}
