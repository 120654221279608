
import { Column, Formatter } from 'angular-slickgrid';
import { AppGlobals } from '../app.globals';

export const taskAssignmentFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let returnHtml = `<label class="ml-3 mr-top-7 check-cell">
      <input type="checkbox" disabled></label>`;
  let enabledCheckbox = `<label id='user-${row}-${columnDef.field}' class="ml-3 mr-top-7 check-cell">
      <input type="checkbox" class="matched-task"></label>
      <label id='user-${row}-${columnDef.field}-checked' class="ml-3 mr-top-7 check-cell d-none">
      <input type="checkbox" class="matched-task" checked></label>`;
  if (columnDef.field === 'isReviewer' && dataContext.isReviewer && dataContext.role_id === AppGlobals.RESOLVE_PROJECT_REVIEWER) {
    returnHtml = enabledCheckbox;
  }
  if (columnDef.field === 'isApprover' && dataContext.isApprover && dataContext.role_id === AppGlobals.RESOLVE_PROJECT_APPROVER) {
    returnHtml = returnHtml = enabledCheckbox;
  }
  return returnHtml;
}

