import { toolTipFormatter } from '../../shared/formatters/toolTipFormatter';
import { AppGlobals } from './../../shared/app.globals';

import {
    OnInit, Component, Input, AfterViewInit,
    ViewEncapsulation, Injectable, Output, EventEmitter, AfterViewChecked, SimpleChanges
} from '@angular/core';
import { ZettaUtils } from '../../shared/zettaUtils';
import { BaseService } from '../../services/base-service';
import { ObservableService } from '../../services/observable.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Column,
    FieldType,
    Filters,
    Formatters,
    GridOdataService,
    GridOption,
    AngularGridInstance,
    Statistic,
    GridStateChange,
    OperatorType,
    SortDirection,
    Formatter

} from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../../zettasense/content/content.service';
declare var Slick: any;
declare var $: any;

const defaultPageSize = 20;

@Component({
    selector: 'zetta-grid',
    templateUrl: './zetta-grid.component.html',
    styleUrls: ['./zetta-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@Injectable()
export class ZettaGridComponent implements OnInit, AfterViewChecked {

    @Input() rowsDataSource;
    @Input() columnsDataSource;
    @Input() tableSettings;
    @Input() ctmGridOptions;
    @Input() showNoDataFound = false;
    @Input() imgSrc = ''
    @Input() applyImageCondition : boolean = false;
    @Input() applyImage : boolean = false;
    @Input() noTaskMsg : boolean = false;
    @Output() gridCreated = new EventEmitter<AngularGridInstance>();
    @Output() gridSortClicked = new EventEmitter<AngularGridInstance>();
    @Output() cellClicked = new EventEmitter();
    @Output() cellEdited = new EventEmitter();
    @Output() rowsSelected = new EventEmitter();
    @Output() openContextMenu = new EventEmitter();
    @Output() gridScroll = new EventEmitter();
    @Output() recvTotalRecords = new EventEmitter<any>();
    @Output() recvTotalRows = new EventEmitter<any>();
    @Input() showNoDataImg=false;
    @Input() allowServerSorting = false;
    @Input() allowSelection=false;
    @Input() selectedColumn:string="";
    @Input() selectedColumnValue=0;
    @Input() allowToAddEmptyAddRow=false;
    @Input() emptyAddRow=false;
    @Input() isOrderTypeKey=false;
    @Input() allowToChangeTerm =false;
    @Input() runExecutionExceptionGrid = false;
    @Input() runDatasetRuleGrid = false;
    @Input() classifyProjectssGrid = false;
    @Input() exceptionForSampleDataUrl? = false;
    @Input() isEntityResolvedData = false;
    @Input() synonymObjectValidation = false;
    @Input() enableEdit = true;
    @Input() enableClone = true;
    @Input() showCloneOption = true;
    @Input() enableDelete = true;
    @Input() addEmptyRowForClientFilter = false;
    @Input() createTenantGrid = false;
    @Input() createTenantFirstRow;
    @Input() isClassifyTag = false;
    @Input() isDatasourceUser = false;
    @Input() isFeedbackSummary = false;
    @Input() disableFeedback = false;
    @Input() isCatalogAdmin? = false;
    @Input() isEntityList? = false;
    @Input() dataSourceLeftAdminUsers? = [];
    @Input() datasourceAddAdmins? = false;
    @Input() isResponseObjectArray? = false;
    @Input() checkForTrainModal? = false;
    @Input() isCatalogUser? = false;
    @Input() hasMultipleComma? = false;
    @Input() isClassifyProject? = false;
    @Input() disableCompletedTasks?: boolean = false;
    @Input() dataSourceDqSCore?: boolean = false;
    @Input() isManageTenantGrid: Boolean = false;
    @Input() allowTochangeTermOnClientFilter = false;
    @Input() persistCheckBoxSelection?: boolean = false;
    @Input() presistRadioSelection: boolean = false;
    @Input() isGoldenRecordDetailScreen?: boolean = false;// only left grid data as per 'GoldenRecordsDetailsComponent'
    @Output() onDataFetched = new EventEmitter<any>();
    @Output() onsearchDataFetched = new EventEmitter<any>();
    @Output() onSearchDataFetched = new EventEmitter<any>();
    @Output() onFilterCleared = new EventEmitter<any>();
    @Output() onDataSorted = new EventEmitter<any>();
    @Input() isCustomFormatterSearchFromDownVoteModel: boolean = false;
    @Input() showFilterNSortLoaders?: boolean = false;    // pass this as true to show spinner while filtering, sorting and pagination on scroll API calls
    @Input() isEntityScreenGrid: boolean = false;
    @Input() removeExtraWHiteSpaces: boolean = false;
    @Input() maintainGridServerStatus: boolean = false;
    @Output() captureGridStateForTasks = new EventEmitter<any>();
    @Input() handleServerSideSearchAndSortUpdateInComponent: boolean = false;
    @Output() onServerSideDataFetched = new EventEmitter<any>();
    @Input() maintainUserStateOnSort: boolean = false;

    // TO Maintain grid state for resolve tasks
    resolveTasksgridState = {
        search: {},
        sort: ''
    }

    grid: any;
    columnDefinitions: Column[];
    gridOptions: GridOption;
    dataset = [];
    tableUniqueID: any;
    dataSize = -1;
    angularGrid: AngularGridInstance;
    showLoader: boolean = false;
    searchFilterApplied: boolean = false;
    _commandQueue: any[] = [];

    odataQuery = '';
    processing = true;
    compaq = true;
    buttonLabel = 'Click to Standard View';

    dataView: any;
    isNormalPaginationRequired;
    validData = true;
    isPaginationRequired;
    search_api_url = '';
    inValidDataMsg = AppGlobals.DEFAULT_EMPTY_GRID_MSG;
    inValidDataMsgStyle = AppGlobals.DEFAULT_EMPTY_GRID_MSG_STYLE;
    currentPageTitle: string;
    noDataFoundMsg = '';
    filterdDataList: any = [];
    orignal_api_url = '';
    projectApi = "";
    catalogApi="";
    projectApiURL = "";
    isFirstCall = false;
    noOfCall = 0;
    isFilter=false;
    // isLoading=true;
    currentPageUrl='';
    _userInfo = JSON.parse(sessionStorage.userInfo);
    constructor(public http: HttpClient,
        public zettaUtils: ZettaUtils,
        public baseSvc: BaseService,
        public contentSvc: ContentService,
        public observeSvc: ObservableService,
        private route: ActivatedRoute,
        private router:Router) {
        this.tableUniqueID = this.zettaUtils.generateUUID();
    }
        

    getInValidDataMsg() {
        if(!this.noTaskMsg) {
            return this.getCtmGridOption('noDataMsg') || this.inValidDataMsg;
        }
    }


    getInValidDataMsgStyle() {
        const style = this.getCtmGridOption('noDataMsgStyle') || this.inValidDataMsgStyle;
        return style;
    }
    isValidData(): boolean {

        const currentDataValidity = this.rowsDataSource.length > 0 ? true :
            this.getCtmGridOption('showNoData') !== undefined ?
                !this.getCtmGridOption('showNoData')
                : false;

        if (this.angularGrid && this.validData !== currentDataValidity) {
            // just to redraw grid
            // this.angularGrid.resizerService.resizeGrid();
            this.angularGrid.sortService.clearSorting();
        }
        this.validData = currentDataValidity;
        return this.validData;
    }

    ngOnInit(): void {
        console.log(this.rowsDataSource)
        this.currentPageTitle = this.route.snapshot.data['breadcrumb'];
        // if(this.rowsDataSource && this.rowsDataSource.length && this.rowsDataSource.length>0) {
        this.rowsDataSource.forEach((item, index) => { item['id'] = Math.random(); });
        this.initializeGridColumns(this.rowsDataSource, this.columnsDataSource);
        this.dataSize = this.tableSettings.totalrecords || this.rowsDataSource.length;
        this.isNormalPaginationRequired = this.tableSettings.isNormalPagination || false;
        this.tableSettings.pageSize = this.isNormalPaginationRequired ? this.tableSettings.pageSize : this.rowsDataSource.length;
        // }
        // this.tableSettings.pageSize = this.tableSettings.pageSize || this.rowsDataSource.length;
        this.isPaginationRequired = (Math.ceil(this.dataSize / this.tableSettings.pageSize) > 1) ? true : false;
        this.search_api_url += this.tableSettings.search_url || '';
        this.isFirstCall = false;

        this.currentPageUrl = this.router.url.split('admin')[1];
        const parent = this;
        this.gridOptions = {
            enableGrouping: true,
            enableAutoResize: true,
            enableSorting: true,
            frozenColumn: parent.getCtmGridOption('frozenColumn'),
            frozenRow: parent.getCtmGridOption('frozenRow'),
            enableAddRow: parent.getCtmGridOption('enableAddRow'),
            enableGridMenu: false,
            createPreHeaderPanel: parent.getCtmGridOption('createPreHeaderPanel'),
            showPreHeaderPanel: parent.getCtmGridOption('showPreHeaderPanel'),
            preHeaderPanelHeight: parent.getCtmGridOption('preHeaderPanelHeight') || 0,
            enableColumnReorder: parent.getCtmGridOption('enableColumnReorder') !== undefined ? parent.getCtmGridOption('enableColumnReorder') : true,
            multiSelect: false,
            defaultFilterPlaceholder: '',
            autoResize: {
                containerId: parent.tableUniqueID,
                sidePadding: 15,
            },
            checkboxSelector: {
                // you can toggle these 2 properties to show the 'select all' checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true,
                // selectableOverride: (row: number, dataContext: any, grid: any) => parent.getCtmGridOption('condCheckbox') !== undefined ? (dataContext.shouldDisable ? false : true) : true,
                // to hide a radio checkbox conditionally use above line
                selectableOverride: (row: number, dataContext: any, grid: any) => ((dataContext&&dataContext.shouldDisable) ? false : true)

            },

            enableCellNavigation: parent.getCtmGridOption('editable'),
            enableFiltering: parent.getCtmGridOption('enableFiltering') !== undefined ? parent.getCtmGridOption('enableFiltering') : true,
            // enableFiltering: true,

            enablePagination: parent.isNormalPaginationRequired,
            cellFlashingCssClass: 'flashing',
            selectedCellCssClass: 'selected',

            enableCheckboxSelector: parent.getCtmGridOption('CheckboxSelector') !== undefined ?
                parent.getCtmGridOption('CheckboxSelector') : true,
            enableRowSelection: parent.getCtmGridOption('selectable') !== undefined ? parent.getCtmGridOption('selectable') : true,
            editable: parent.getCtmGridOption('editable') || false,
            autoEdit: parent.getCtmGridOption('autoEdit') || false,
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: parent.getCtmGridOption('multiSelectable') !== undefined ? parent.getCtmGridOption('multiSelectable') : true,
            },
            pagination: {
                pageSizes: [5, 10, 15, 20, 25, 30, 40, 50, 75, 100],
                pageSize: parent.tableSettings.pageSize,
                totalItems: 0
            },
            rowHeight: parent.getCtmGridOption('rowHeight') || 30,
            editCommandHandler: (item, column, editCommand) => {
                parent.cellEdited.emit(editCommand);
            },
            backendServiceApi: {
                service: new GridOdataService(),
                options: {
                    caseType: -1
                },
                preProcess: () => { 
                    if(parent.showFilterNSortLoaders && !parent.showLoader) {
                        parent.showLoader = true;
                    }                  
              },
                process: (query) => parent.getCustomerApiCall(query),
                postProcess: (response) => {
                    if(this.showFilterNSortLoaders) {
                        if(this.search_api_url && !this.searchFilterApplied && ((this.search_api_url.includes('type=asc')) || (this.search_api_url.includes('type=desc')))) {
                            this.showLoader = true;
                        } else {
                            this.showLoader = false;
                        }
                    }
                    parent.getCustomerCallback(response);
                }
            }
        };

        this.observeSvc.refreshDataGrid.subscribe(data => {
            parent.onResize();
        });


    }


    /**
     * @param {Array<Object>} tableRowsDS
     * @param {Array<Object>} columnDS
     * @memberof ZettaGridComponent
     */
    initializeGridColumns(tableRowsDS: Array<Object>, columnDS: Array<Object>): void {
        const parent = this;
        const tableCols = [];
        for (const col in columnDS) {
            if (columnDS.hasOwnProperty(col)) {
                const findColumnObj = columnDS[col];
                if (findColumnObj) {
                    const columnObj = {};
                    columnObj['sortable'] = findColumnObj['sortable'] !== undefined ? findColumnObj['sortable'] : true;
                    columnObj['filterable'] = findColumnObj['filterable'] !== undefined ? findColumnObj['filterable'] : true;
                    columnObj['id'] = findColumnObj['physicalname'];
                    columnObj['field'] = findColumnObj['physicalname'];
                    columnObj['name'] = findColumnObj['displayname'];
                    columnObj['toolTip'] = findColumnObj['displayname'];
                    columnObj['headerCssClass'] = findColumnObj['headerCssClass'] ||
                        parent.zettaUtils.getWidthOfText(columnObj['headerCssClass']);

                    columnObj['editor'] = findColumnObj['editor'];
                    columnObj['columnGroup'] = findColumnObj['columnGroup'];
                    columnObj['onCellClick'] = findColumnObj['onCellClick'];
                    columnObj['params'] = findColumnObj['params'];
                    // columnObj['minWidth'] = findColumnObj['minWidth'] || parent.zettaUtils.getWidthOfText(columnObj['name']);
                    // Set Default column width
                    if (findColumnObj['maxWidth']) {
                        columnObj['maxWidth'] = findColumnObj['maxWidth'];
                    } else {
                        columnObj['minWidth'] = findColumnObj['minWidth'] &&
                            findColumnObj['minWidth'] > parent.zettaUtils.getWidthOfText(columnObj['name'])
                            ? findColumnObj['minWidth'] : parent.zettaUtils.getWidthOfText(columnObj['name']);
                        columnObj['cssClass'] = findColumnObj['cssClass'] || parent.zettaUtils.getWidthOfText(columnObj['cssClass']);
                    }
                    if (findColumnObj['datatype'] === 'Long') {
                        columnObj['filter'] = { model: Filters.inputText };
                        columnObj['type'] = FieldType.number;
                        columnObj['formatter'] = findColumnObj['formatter'] || Formatters.multiple;
                        columnObj['params'] = findColumnObj['params'] || { formatters: [Formatters.complexObject] };
                    } else if (findColumnObj['datatype'] === 'DateTime') {
                        columnObj['formatter'] = findColumnObj['formatter'] || Formatters.dateIso;
                        columnObj['type'] = FieldType.date;
                        columnObj['filter'] = { model: Filters.inputText };
                    } else {
                        columnObj['filter'] = findColumnObj['filter'] || { model: Filters.inputText };
                        columnObj['formatter'] = findColumnObj['formatter'] || toolTipFormatter;
                    }
                    tableCols.push(columnObj);
                }
            }
        }
        parent.columnDefinitions = tableCols;
    }

    getCustomerCallback(data) {
        // totalItems property needs to be filled for pagination to work correctly
        // however we need to force Angular to do a dirty check, doing a clone object will do just that
        this.gridOptions.pagination.pageSize = this.tableSettings.pageSize;
        this.gridOptions.pagination.totalItems = this.dataSize; // data['totalRecordCount'];
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.onDataFetched.emit(data);
        // once pagination totalItems is filled, we can update the dataset
        this.dataset = data ? data['items'] : this.angularGrid.dataView.getItems();
        this.odataQuery = data['query'];
        this.noDataFoundMsg = '';
        if (this.isFilter && this.dataset.length == 0) {
            this.noDataFoundMsg = 'No data found';
        }

        if(this.datasourceAddAdmins) {
            data.items.forEach(element => {
                this.dataSourceLeftAdminUsers.forEach(rightEle => {
                    if(element['user_name'] === rightEle.user_name) {
                        element['checkForDisableCond'] = true;
                    } else {
                        return;
                    }
                });
            });
            $('div.disableRow div.slick-row div.disable-row').parent().parent().addClass('disabled-state');
        }

        if(this.removeExtraWHiteSpaces) {
            data.items.map(item => {
                let newiTem = item;
                for(let newObj of Object.keys(newiTem)) {
                    if(newiTem && newiTem[newObj] && newiTem[newObj].toString() && newiTem[newObj].toString().length && newiTem[newObj].toString().length>0) {
                        newiTem[newObj] =   newiTem[newObj].toString().replaceAll(/[ ][ ]*/g, ' ');
                    } else {
                    return;
                }
            }});
        }
        this.disableCompletedTasks ? data.items.forEach(task => task['shouldDisable'] = task['task_status'] == 'Completed' || task['task_status'] == 'Reviewed') : '';
        this.dataSourceDqSCore ? data.items.forEach(datasource => datasource['align_text_center'] = true) : '';


    }


    getCustomerApiCall(query) {
        // in your case, you will call your WebAPI function (wich needs to return a Promise)
        // for the demo purpose, we will call a mock WebAPI function
        // this.isLoading= true;
        return this.getDataApiMock(query);
    }

    /** This function is only here to mock a WebAPI call (since we are using a JSON file for the demo)
     *  in your case the getCustomer() should be a WebAPI function returning a Promise
     */
    getDataApiMock(query) {
        if(!this.showLoader && this.showFilterNSortLoaders) {
            this.showLoader = true;
        }
        const parent = this;
        let filtered_data='';
        // the mock is returning a Promise, just like a WebAPI typically does
        return new Promise((resolve, reject) => {
            if (parent.currentPageTitle === AppGlobals.VIEW_RESOLUTION_RESULT && this.isEntityResolvedData == true) {
                let zs_cluster_identifier = $('#filter-zs_cluster_identifier').val() ? $('#filter-zs_cluster_identifier').val() : '';
                let zs_accuracy_level = $('#filter-zs_accuracy_level').val() ? $('#filter-zs_accuracy_level').val() : '';
                let zs_source_systems_cluster = sessionStorage.getItem('zs_source_systems_cluster') ? sessionStorage.getItem('zs_source_systems_cluster') : '';
                if((zs_cluster_identifier && (zs_cluster_identifier!=='null' || zs_cluster_identifier!==null || zs_cluster_identifier!=='')) && (zs_accuracy_level && (zs_accuracy_level!=='null' || zs_accuracy_level!==null || zs_accuracy_level!==''))){
                    if (zs_source_systems_cluster && (zs_source_systems_cluster!=='null' && zs_source_systems_cluster!==null && zs_source_systems_cluster!=='')) {
                        filtered_data = "$filter=(substringof('" + zs_cluster_identifier + "', zs_cluster_identifier) and substringof('" + zs_accuracy_level + "', zs_accuracy_level) and substringof('" + zs_source_systems_cluster + "', zs_source_systems_cluster))";
                    } else {
                        filtered_data = "$filter=(substringof('" + zs_cluster_identifier + "', zs_cluster_identifier) and substringof('" + zs_accuracy_level + "', zs_accuracy_level))";
                    }
                }
                else if (zs_cluster_identifier && (zs_cluster_identifier!=='null' || zs_cluster_identifier!==null || zs_cluster_identifier!=='')) {
                    filtered_data = "$filter=(substringof('" + zs_cluster_identifier + "', zs_cluster_identifier))";
                }
                else if (zs_accuracy_level && (zs_accuracy_level!=='null' || zs_accuracy_level!==null || zs_accuracy_level!=='')) {
                    filtered_data = "$filter=(substringof('" + zs_accuracy_level + "', zs_accuracy_level))";
                }
            }
            
            let queryParams;

            if(this.exceptionForSampleDataUrl) {
                let selectedTab = sessionStorage.getItem('activeNavLnk');
                if(selectedTab == 'ds-sample') {
                    queryParams = query.split('&');
                }
            } else {
                queryParams = query.toLowerCase().split('&');
            }

            let top = 1;
            top = parent.rowsDataSource.length;
            let skip = 0;
            let orderBy = '';
            let countTotalItems = 100;
            const columnFilters = {};

            for (const param of queryParams) {
                // if (param.includes('$top=')) {
                // 	top = +(param.substring('$top='.length));
                // }
                if (param.includes('$skip=')) {
                    skip = +(param.substring('$skip='.length));
                }
                if (param.includes('$orderby=')) {
                    orderBy = param.substring('$orderby='.length);
                }
                if (param.includes('$filter=')) {
                    if(this.hasMultipleComma){
                    const filterBy = param.substring('$filter='.length).replace('%20', ' ').replace('%2c', ',');
                    const searchedColumn = filterBy.split('|AND|'); // Use a custom separator
                    if (searchedColumn.length > 0) {
                        searchedColumn.forEach(_filterByColumn => {
                            if (_filterByColumn.includes('substringof')) {
                                const filterMatch = _filterByColumn.match(/substringof\('(.*?)',([a-zA-Z0-9 _]*)/);
                                let fieldName = filterMatch[2].trim();
                                columnFilters[fieldName] = {
                                    type: 'substring',
                                    term: filterMatch[1].replace(/%2f/g, '/').replace(/%3a/g, ':').replace(/%20/g, ' ').replace(/%2c/g, ',').trim()
                                };
                            }
                        });
                    } 
                }else{
                    const filterBy = param.substring('$filter='.length).replace('%20', ' ').replace('%2c', ',');
                    const searchedColumn = filterBy.split('and');
                    if (searchedColumn.length > 0) {
                        searchedColumn.forEach(_filterByColumn => {
                            if (_filterByColumn.includes('substringof')) {
                                const filterMatch = _filterByColumn.match(/substringof\('(.*?)',([a-zA-Z0-9 _]*)/);
                                let fieldName = filterMatch[2].trim();
                                columnFilters[fieldName] = {
                                    type: 'substring',
                                    term: filterMatch[1].replace(/%2f/g, '/').replace(/%3a/g, ':').replace(/%20/g, ' ').trim()
                                };
                            
                            }
                        });
                    }
                }

                    // Below Code is for reference for future use if required.

                    //     else {
                    //     if (filterBy.includes('substringof')) {
                    //         const filterMatch = filterBy.match(/substringof\('(.*?)',([a-zA-Z _]*)/);
                    //         const fieldName = filterMatch[2].trim();
                    //         columnFilters[fieldName] = {
                    //             type: 'substring',
                    //             term: filterMatch[1].replace('%20', ' ').trim()
                    //         };
                    //     }
                    //     if (filterBy.includes('eq')) {
                    //         const filterMatch = filterBy.match(/([a-zA-Z _]*) eq '(.*?)'/);
                    //         const fieldName = filterMatch[1].trim();
                    //         columnFilters[fieldName] = {
                    //             type: 'equal',
                    //             term: filterMatch[2].replace('%20', ' ').trim()
                    //         };
                    //     }
                    //     if (filterBy.includes('startswith')) {
                    //         const filterMatch = filterBy.match(/startswith\(([a-zA-Z _]*),\s?'(.*?)'/);
                    //         const fieldName = filterMatch[1].trim();
                    //         columnFilters[fieldName] = {
                    //             type: 'starts',
                    //             term: filterMatch[2].replace('%20', ' ').trim()
                    //         };
                    //     }
                    //     if (filterBy.includes('endswith')) {
                    //         const filterMatch = filterBy.match(/endswith\(([a-zA-Z _]*),\s?'(.*?)'/);
                    //         const fieldName = filterMatch[1].trim();
                    //         columnFilters[fieldName] = {
                    //             type: 'ends',
                    //             term: filterMatch[2].replace('%20', ' ').trim()
                    //         };
                    //     }
                    // }
                }
            }
            const sort = orderBy.includes('asc')
                ? 'ASC'
                : orderBy.includes('desc')
                    ? 'DESC'
                    : '';
            sessionStorage.removeItem('sortfilter');
            let sortBy=orderBy;
            if (orderBy !== null && orderBy !== "") {
                const orderByKey = orderBy.split(' ')[0];
                if(orderByKey==='view_id'){
                    sortBy='id '+ orderBy.split(' ')[1];
                }
                if (orderByKey === 'ds_sample_id') {
                    sortBy = 'id ' + orderBy.split(' ')[1];
                }
                if (this.isClassifyTag || this.isDatasourceUser) {
                    orderBy = "?orderby=" + sortBy;
                }
                else {
                    orderBy = "&orderby=" + sortBy;
                }
                let sortOrder = orderBy.split(' ')[1];
                if (this.isOrderTypeKey) {
                    sortOrder = orderBy.split(' ')[0] + '&orderType=' + sortOrder;
                } else {
                    sortOrder = orderBy.split(' ')[0] + '&type=' + sortOrder;
                }
                sessionStorage.setItem('sortfilter', sortOrder);
                sessionStorage.setItem('reset_page_sort', '1');
            }
            const dataArray = parent.rowsDataSource;

            
            // Read the result field from the JSON response.
            const firstRow = skip;
            let filteredData = dataArray;
            let searchTerm = '';
            let filterType = '';
            if (columnFilters) {
                for (const columnId in columnFilters) {
                    if (columnFilters.hasOwnProperty(columnId)) {
                        filterType = columnFilters[columnId].type;
                        searchTerm = columnFilters[columnId].term;
                        try {
                            filteredData = filteredData.filter(column => {
                                let colId = columnId;
                                if (columnId && columnId.indexOf(' ') !== -1) {
                                    const splitIds = columnId.split(' ');
                                    colId = splitIds[splitIds.length - 1];
                                }
                                // Compare columns in lower case
                                let filterTerm;  
                                const matchedKey = Object.keys(column).filter(key => key.toLowerCase() === colId);
                                if (matchedKey.length > 0) {
                                    filterTerm = column[matchedKey[0]];
                                    // Date Filter
                                    const dateFilter = filterTerm;
                                    const date: any = filterTerm instanceof Date ? Date.parse(dateFilter) : 'NaN';
                                    if (this.isFeedbackSummary) {
                                        if (columnFilters['created_ts']) {
                                            const d = new Date(filterTerm);
                                            const m = d.getMonth() + 1;
                                            const month = (m <= 9 ? '0' + m : m);
                                            filterTerm = `${month}/${d.getDate()}/${d.getFullYear()}`;
                                            searchTerm = searchTerm.replace(/%2f/g, '/');
                                        }
                                    }

                                    if (!isNaN(date)) {
                                        const d = new Date(filterTerm);
                                        const m = d.getMonth() + 1;
                                        const month = (m <= 9 ? '0' + m : m);
                                        filterTerm = `${month}/${d.getDate()}/${d.getFullYear()}`;
                                        searchTerm = searchTerm.replace(/%2f/g, '/');
                                    }
                                    
                                    if (this.isEntityScreenGrid) {
                                        searchTerm = searchTerm && searchTerm.includes(`''`) ? searchTerm.replace(`''`,`'`): searchTerm;
                                        return searchTerm && column[columnId] && column[columnId].toLowerCase().includes(searchTerm.toLowerCase());
                                    }
                                    if (this.allowTochangeTermOnClientFilter) {
                                        if (colId.toLowerCase() === 'confidence') {
                                            const high = searchTerm.toLowerCase().startsWith('h');
                                            const low = searchTerm.toLowerCase().startsWith('l');
                                            const med = searchTerm.toLowerCase().startsWith('m');
                                            searchTerm = high ? 'h' : (low ? 'l' : (med ? 'm' : searchTerm));
                                        }
                                    }
                                }
                                if (filterTerm !== undefined) {
                                    // please refactor code incase filter type required is starts with
                                    filterType = 'substring';
                                    filterTerm = filterTerm ? filterTerm.toString(): '';
                                    switch (filterType) {
                                        case 'equal': return filterTerm.toLowerCase() === searchTerm;
                                        case 'ends': return filterTerm.toLowerCase().endsWith(searchTerm);
                                        case 'starts': return filterTerm.toLowerCase().startsWith(searchTerm);
                                        case 'substring': return filterTerm.toLowerCase().includes(searchTerm);
                                    }
                                }  
                            });
                        } catch (ex) {
                            console.log(ex);
                        }

                    }
                }
                countTotalItems = filteredData.length;
                if (this.createTenantGrid && Object.keys(columnFilters).length !== 0 && filteredData.length > 0) {
                    filteredData.splice(0, 0, this.createTenantFirstRow);
                }
                if (this.addEmptyRowForClientFilter && filteredData.length && searchTerm) {
                    let existingHeaderIndex = filteredData.findIndex((conceptsData) => {
                        return conceptsData.action === 'add';
                    })

                    if(existingHeaderIndex > -1) {
                        filteredData.splice(existingHeaderIndex, 1);
                    }

                    const row = this.addDefaultEmptyRow();
                    filteredData.splice(0, 0, row);
                }
            }
            sessionStorage.removeItem('serverfilter');
            
            const updatedData = filteredData.slice(firstRow, firstRow + top);   
            let currentPageno = 1;
            let currentPagesize;
            if (parent.tableSettings.search_url) {
                try {
                    let addAnd = "";
                    let icnt = 0;
                    let isprojectArr;
                    this.orignal_api_url = parent.tableSettings.search_url.split('?')[0];
                    isprojectArr = this.orignal_api_url.split('/');
                    this.orignal_api_url = this.orignal_api_url + "?";
                    this.projectApi = isprojectArr[isprojectArr.length - 1];
                    this.catalogApi = isprojectArr[isprojectArr.length - 3];
                    this.projectApiURL = isprojectArr[isprojectArr.length - 2];
                    let pageUrl = parent.tableSettings.search_url.split('?')[1];
                    if (pageUrl && pageUrl.length > 0) {
                        let urlArr = pageUrl.split('&');
                        if (urlArr.length > 0) {
                            urlArr.forEach(ele => {
                                addAnd = ""
                                icnt++;
                                if (ele.split('=')[0].toLowerCase() == "pagesize") {
                                    currentPagesize = ele.split('=')[1];
                                }
                                if (ele.split('=')[0].toLowerCase() == "pageno") {
                                    currentPageno = ele.split('=')[1];
                                    this.orignal_api_url += 'pageno=1' + "&";
                                } else {
                                    if (icnt != urlArr.length)
                                        addAnd = "&";
                                    this.orignal_api_url += ele + addAnd;
                                }
                            });
                        }

                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
            if (parent.tableSettings.search_url && searchTerm !== '') {
                this.isFilter=true;
                parent.search_api_url = parent.tableSettings.search_url// parent.search_api_url ? parent.search_api_url : parent.tableSettings.search_url;
                let pageNo = Math.ceil((firstRow + top) / parent.tableSettings.pageSize);
                // var searchString = '';
                var searchString = sessionStorage.getItem('serverfilter') ? sessionStorage.getItem('serverfilter') : '';
                let msHeader;
                this.noOfCall++;
                if(this.currentPageUrl && this.currentPageUrl.includes('ad-users')){
                    Object.keys(columnFilters).forEach((cf,index) => {
                        searchString+=  (index>0 ? '&':'')+ '"'+cf+':'+columnFilters[cf].term +'"'
                    });
                    searchString='?$search='+searchString + '&$count=true';
                    msHeader= new HttpHeaders({
                        'ConsistencyLevel':  'eventual'
                    });
                }else{
                    Object.keys(columnFilters).forEach(cf => {
                        let field = cf;
                        let term = columnFilters[cf].term;
                        term = term.replace("'", "''");
                        if (this.projectApi.toLowerCase() == 'viewresults' || (this.projectApiURL === 'tasks' && this.allowToChangeTerm)) {
                            if (cf.toLowerCase() === 'view_id') {
                                field = 'id'
                            }
                            if (cf.toLowerCase() === 'best_prediction') {
                                field = 'class'
                            }
                            if (cf.toLowerCase() === 'confidence') {
                                const high = term.toLowerCase().startsWith('h');
                                const low = term.toLowerCase().startsWith('l');
                                const med = term.toLowerCase().startsWith('m');
                                term = high ? 'h' : (low ? 'l' : (med ? 'm' : term));
                            }
                        }

                        if(this.projectApi.toLowerCase() === 'dqrules'){
                            if (cf.toLowerCase() === 'data_quality_rule_id') {
                                field = 'rule_id'
                            }
                            if (cf.toLowerCase() === 'dq_dimension') {
                                field = 'rule_dimension'
                            }
                        }

                        if (this.projectApi.toLowerCase() === 'queue') {
                            if (cf.toLowerCase() == 'job_name') {
                                field = 'stage_name';
                            }
                            if (cf.toLowerCase() == 'job_type') {
                                field = 'job_type';
                            }
                        }
                        if(this.projectApi.toLowerCase() === 'datasets' &&
                            this.catalogApi.toLowerCase()==='catalogs'){
                            if (cf.toLowerCase() === 'score') {
                                field = 'confidence'
                            }
                        }

                        if (this.projectApi.toLowerCase() == 'tasks') {
                            if (cf.toLowerCase() === 'task_type') {
                                field = 'task_type';
                            }
                        }
                        if (this.projectApi.toLowerCase() == 'sampledata') {
                            if (cf.toLowerCase() === 'ds_sample_id') {
                                field = 'id';
                            }
                        }
                        if (this.allowToChangeTerm && cf.toLowerCase() === 'total_rules') {
                            if (term.includes('a') || term.includes('ad') || term.includes('add')) {
                                term = 0;
                            }
                        }
                        if (this.projectApi.toLowerCase() == 'entitiesresolved') {
                            if (cf.toLowerCase() === 'zs_accuracy_level') {
                                field = 'accuracy_level';
                            };
                            if (cf.toLowerCase() === 'zs_source_systems_cluster') {
                                term = sessionStorage.getItem('zs_source_systems_cluster');
                            };
                            sessionStorage.setItem(cf.toLowerCase(), term);
                        }
                   
                        if (this.projectApi.toLowerCase() == 'tags') {
                           if(parent.search_api_url.includes("?")){
                            searchString = searchString + '&' + field + '=' + term;
                           }else{
                            searchString = searchString + '?' + field + '=' + term;
                           }
                        }
                        else if (this.projectApi.toLowerCase() === 'users' && this.isDatasourceUser && (this.catalogApi.toLowerCase() === 'datasources' || this.catalogApi.toLowerCase() === 'groups' )) {
                            searchString = searchString + '?' + field + '=' + term;
                        }
                        else {
                            searchString = searchString + '&' + field + '=' + term;
                        }

                    });
                }
                sessionStorage.setItem('serverfilter', searchString);
                sessionStorage.removeItem('resetpage_filter');
                if(searchString) {
                    parent.searchFilterApplied = true;
                }
                if ((this.isClassifyTag || this.isDatasourceUser) && searchString !== '') {
                    if(orderBy.length > 0)
                    {
                        const order = orderBy.split('=')[0];
                        orderBy="&orderby="+orderBy.split('=')[1];
                    }
                }

                if(orderBy.length > 0){
                    const order=orderBy.split(' ')[1];
                    if (this.isOrderTypeKey) {
                        orderBy = orderBy.split(' ')[0] + '&orderType=' + order;
                    } else {
                        orderBy = orderBy.split(' ')[0] + '&type=' + order;
                    }


                    //orderBy=orderBy.split(' ')[0]+'&type='+order;
                    parent.search_api_url=parent.tableSettings.search_url+searchString+orderBy;
                    searchString='';
                }
                parent.http.get(parent.search_api_url + searchString, {headers:msHeader}).subscribe(response => {
                    if(!parent.showLoader && parent.showFilterNSortLoaders) {
                        parent.showLoader = false;
                    }

                    if (this.handleServerSideSearchAndSortUpdateInComponent && response) {
                        if (response && response['currentpage'] && response['currentpage'].length) {
                            this.noDataFoundMsg= '';
                            this.showNoDataFound = false;
                            this.dataset = response['currentpage'];
                            this.recvTotalRecords.emit(response['totalrecords'] ? response['totalrecords'] : response['currentpage'].length);
                            parent.onServerSideDataFetched.emit(response);
                            return;
                        }
                    }
                     
                    if (this.projectApi.toLowerCase() == 'projects') {
                        if(response.hasOwnProperty('currentpage') && response['currentpage']){
                            response['currentpage'] = this.getProjectAdmin(response['currentpage']);
                            response['currentpage'].forEach(project => {
                                if(this.isClassifyProject){
                                    project.isClassifyProject=true;
                                }
                                if(project.hasOwnProperty('users') && project.users){
                                    project.users = Array.from(new Set(project.users.map(user => user.user_id))).length;
                                }
                            });
                        }
                    }
                    if(this.projectApi.toLowerCase() === 'viewresults'){
                        response['currentpage'].forEach(result => {
                            result.view_id = result.id;
                        });
                    }
                    if (this.projectApi.toLowerCase() == 'sampledata') {
                        response['currentpage'].forEach(result => {
                            result.ds_sample_id = result.id;
                        });
                    }
                    if (this.projectApiURL.toLowerCase() == 'tasks' && this.disableFeedback) {
                        if(!response['currentpage'] && response.hasOwnProperty('task_outputs') ){
                            if(response['task_outputs'].length){
                                response['task_outputs'].forEach(result => {
                                    result.shouldDisableFeedback = this.disableFeedback;
                                });
                            }
                        }
                        
                    }

                    /** ALL TASKS catalog SEMANTIC CLASS TRAINING tasks feedback */
                    if (this.catalogApi === 'semantics' && this.projectApi === 'datasets' && this.disableFeedback) {
                        console.log('semantic class training');
                        if (response['currentpage'] && response['currentpage'].length > 0) {
                            response['currentpage'].forEach((datasets) => {
                                datasets.shouldDisableFeedback = this.disableFeedback;
                            })
                        }
                    }

                    /** ALL TASKS catalog CONCEPT CLASS TRAINING tasks feedback */
                    if (this.catalogApi === 'concepts' && this.projectApi === 'mappedcolumns' && this.disableFeedback) {
                        console.log('concept class training');
                        if (response['currentpage'] && response['currentpage'].length > 0) {
                            response['currentpage'].forEach((columns) => {
                                columns.shouldDisableFeedback = this.disableFeedback;
                            })
                        }
                    }
                    

                    if (this.projectApi === 'semantics' && this.isEntityList) {
                        response['currentpage'].forEach(result => {
                            result.entity_admin = this.isCatalogAdmin;
                        });
                    }
                    if(this.projectApi.toLowerCase() === 'dqrules'){
                        response['currentpage'].forEach(result => {
                            result.data_quality_rule_id = result.rule_id;
                            result.dq_dimension=result.rule_dimension;
                            if (result.hasOwnProperty('role_name')) {
                                if ( result.role_name == 'dq_rule_admin' ) {
                                    result.shouldDisable = false;
                                } else {
                                    result.shouldDisable = true;
                                }
                            }
                        });
                    }

                    if(this.projectApi.toLowerCase() === 'rules'){
                        response['currentpage'].forEach(result => {
                            if (result.hasOwnProperty('measured_data_quality')) {
                                result.measured_dq_score = result.measured_data_quality;
                            }
                            if (result.hasOwnProperty('quality_score')) {
                                result.measured_dq_score = result.quality_score;
                            }
                            if (result.hasOwnProperty('role_name')) {
                                if ( result.role_name == 'dq_rule_admin' ) {
                                    result.shouldDisable = false;
                                } else {
                                    result.shouldDisable = true;
                                }
                            }
                            result.isRuleEnabled = this._userInfo["enable_dq_rule"];
                        });
                    }
                    if (this.projectApi.toLowerCase() === 'queue') {
                        response['currentpage'].forEach(result => {
                            if (result.algoJob.hasOwnProperty('stage_name')) {
                                result.job_name = result.algoJob.stage_name;
                            }
                            if (result.algoJob.hasOwnProperty('Job_Type') || result.algoJob.hasOwnProperty('job_type')) {
                                result.job_type = result.algoJob.Job_Type;
                            }
                        });
                    }
                    if (this.projectApi.toLowerCase() == 'tasks') {
                        response['currentpage'].forEach(element => {
                            element.shouldDisable = (element.status == AppGlobals.TASK_COMPLETED || element.task_status == AppGlobals.TASK_COMPLETED) ? true : false;
                            element.enableLink = element.assigned_to == this._userInfo['user_id'] ? true : false;
                        });
                    }
                    if (this.isManageTenantGrid) {
                        response['currentpage'] = this.getTenantLogo(response['currentpage']);
                    }
                    if(this.checkForTrainModal) {
                        if (this.projectApi.toLowerCase() == 'semantics' || this.projectApi.toLowerCase() == 'concepts') {
                            response['currentpage'].forEach(ele => ele.iscatalogUser = this.isCatalogUser);
                        }
                    }
                    if (this.synonymObjectValidation == true) {
                        this.checkForDisable(response['currentpage']);
                    }
                    if (this.projectApi.toLowerCase() == 'attributesdefaultrules') {
                        if (response['currentpage'].length) {
                            response['currentpage'] = this.baseSvc.getAttributeRules(response['currentpage']);
                        }
                    }
                    if(response['currentpage']){
                        this.filterdDataList = response['currentpage'].map((item) => {
                            item['action'] = 'edit';
                            item['id'] = Math.random();
                            if(this.allowSelection){
                                item.isChecked = item[`${this.selectedColumn}`] == this.selectedColumnValue ? true : false;
                            }
                            return item;
                        });
                        if(this.allowToAddEmptyAddRow){
                            this.filterdDataList.splice(0, 0, this.emptyAddRow);
                        }
                    }else{
                        if(this.isResponseObjectArray) {
                            this.filterdDataList = response['task_outputs'] ? response['task_outputs'] : response;
                        } else {
                            this.filterdDataList = response;
                        }
                        if(this.filterdDataList.length){
                            this.filterdDataList.forEach(element => {
                                if (element.id) {
                                    element['newId'] = element.id;
                                }
                                if (element.score) {
                                    element.score100 = (element.score * 100).toFixed();
                                }
                                element['id'] = Math.random();
                            });
                        }
                    }
                    if(this.currentPageUrl && this.currentPageUrl.includes('ad-users')){
                        this.getGroups(response);
                    }

                    // filter here for project status filter 
                    if (this.projectApi.toLowerCase() == 'projects' && columnFilters && columnFilters['status']) {
                        this.filterdDataList =  this.filterdDataList.filter((val) => {
                            return (val.status && val.status.toLowerCase() === columnFilters['status']['term']);
                        });
                        response['totalrecords'] = this.filterdDataList.length;
                    }

                    if (this.projectApi.toLowerCase() == 'attributes' && parent.tableSettings.search_url
                    && parent.tableSettings.search_url.includes('entities') && parent.tableSettings.search_url.includes('attributes')
                    && parent.tableSettings.search_url.includes('project')) {
                        parent.onSearchDataFetched.emit(parent.filterdDataList); 
                        return;
                    }
                    if (this.projectApi.toLowerCase() === 'winners') {
                        this.filterdDataList.forEach((goldenRecordData) => {
                            let columnKeys = Object.keys(goldenRecordData);
                            columnKeys.forEach((columns) => {
                                if (goldenRecordData[columns] && typeof goldenRecordData[columns] === 'string' &&
                                goldenRecordData[columns].includes(AppGlobals.GOLDEN_RECORD_DELIMITER)) {
                                    goldenRecordData[columns] = goldenRecordData[columns].replaceAll(AppGlobals.GOLDEN_RECORD_DELIMITER, ';');
                                }
                            })
                        })
                    }

                    if (this.projectApi.toLowerCase() === 'concepts') {
                        this.recvTotalRecords.emit(response['totalrecords'] ? response['totalrecords'] : this.filterdDataList.length);
                        this.onSearchDataFetched.emit(response);
                        return;
                    }

                    if (this.projectApi.toLowerCase() === 'summary' && parent.search_api_url.includes("entitiesresolved/fixissues/summary")) {
                        if (this.filterdDataList && this.filterdDataList.length) {
                            this.filterdDataList = this.filterdDataList.map((taskInfo) => {
                                taskInfo.shouldDisable = taskInfo && taskInfo.task_status && (taskInfo.task_status === AppGlobals.TASK_COMPLETED || taskInfo.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
                                return taskInfo;
                            })
                        }
                    }
                    
                    this.recvTotalRecords.emit(response['totalrecords'] ? response['totalrecords'] : this.filterdDataList.length);
                    this.recvTotalRows.emit(response);
                    parent.dataView = new Slick.Data.DataView({});
                    parent.dataView.beginUpdate();
                    parent.angularGrid.dataView.setItems(this.filterdDataList);
                    parent.angularGrid.gridService.setSelectedRows([]);
                    parent.dataView.endUpdate();
                    // this.isLoading=false;  
                    resolve({ items: this.filterdDataList, totalRecordCount: response['totalrecords'], query });

                }, error => {
                    this.showLoader = false;
                  });
            } else if (parent.tableSettings.search_url && searchTerm === "" && currentPageno >= 1 && this.noOfCall > 0) {
                sessionStorage.setItem('resetpage_filter','1' );
                sessionStorage.removeItem('serverfilter');
                sessionStorage.removeItem('zs_cluster_identifier');
                sessionStorage.removeItem('accuracy_level');                
                //sessionStorage.removeItem('zs_source_systems_cluster');
                if (orderBy.length > 0) {
                    if (parent.search_api_url.includes("?ignore_system_attributes=true") && this.projectApi.toLowerCase() === 'tags') {
                        const firstChar = orderBy.slice(0, 1);
                        if (firstChar === '?') {
                            orderBy = orderBy.replace("?", "&");
                        }  
                    }
                    const order = orderBy.split(' ')[1];
                    if (this.isOrderTypeKey) {
                        orderBy = orderBy.split(' ')[0] + '&orderType=' + order;
                    } else {
                        orderBy = orderBy.split(' ')[0] + '&type=' + order;
                    }
                    if (parent.search_api_url.includes("?only_admins=true") && this.isDatasourceUser) {
                        const firstChar = orderBy.slice(0, 1);
                        if (firstChar === '?') {
                            orderBy = orderBy.replace("?", "&");
                        }
                    }
                    // orderBy = orderBy.split(' ')[0] + '&type=' + order;
                    parent.orignal_api_url = parent.orignal_api_url + orderBy;
                    parent.orignal_api_url = parent.orignal_api_url.replace("??", "?");
                    searchString = '';
                }

                this.isFilter=true;
                if (this.projectApi.toLowerCase() == 'attributes' && parent.tableSettings.search_url
                    && parent.tableSettings.search_url.includes('entities') && parent.tableSettings.search_url.includes('attributes')
                    && parent.tableSettings.search_url.includes('project')) {
                        parent.onFilterCleared.emit(true);
                        return;
                    }
                parent.getData(parent.orignal_api_url, parent, resolve, query);
                
            } else {
                this.isFilter=true;
                if (dataArray.length > 0 && orderBy.length > 0) {

                    if (this.maintainGridServerStatus) {
                        this.resolveTasksgridState.sort = orderBy;
                        this.captureGridStateForTasks.emit(this.resolveTasksgridState);
                    }
                    const columnName = orderBy.split(' ')[0];
                    let columnSortForFormatterType;
                    let sortOrderType;
                    // Below if block for handling sort for formatter type for down vote model
                    if (this.isCustomFormatterSearchFromDownVoteModel || this.allowTochangeTermOnClientFilter || this.isEntityScreenGrid) {
                        const tempColumnArray = columnName.split('=');
                        const colSortOrderForFormatter = orderBy.split('=');
                        
                        columnSortForFormatterType = tempColumnArray.length > 0 && tempColumnArray[1] ? tempColumnArray[1] : null;
                        let sortOrderTypeArray = colSortOrderForFormatter.length > 0 && colSortOrderForFormatter[1] ? colSortOrderForFormatter[1].split(' '): [];
                        sortOrderType = sortOrderTypeArray.length > 0 ? sortOrderTypeArray[1] : 'asc';
                    }   

                    
                    if (this.isEntityScreenGrid) {
                        // called from entity screen in resolve
                        let entityData = {
                            data: updatedData,
                            sortBy: columnSortForFormatterType,
                            sortType: sortOrderType,
                            showHeader: searchTerm ? false : true
                        }
                        this.onDataSorted.emit(entityData);
                        return;
                    }

                    if (this.maintainUserStateOnSort) {
                        // use it to pass data to components 
                        this.onDataSorted.emit(updatedData);
                    }
                    

                    if (this.projectApi.toLowerCase() == 'catalogs' || this.projectApi.toLowerCase() == 'semantics' || this.projectApi.toLowerCase() == 'attributes' ||
                        this.projectApi.toLowerCase() == 'winners' || this.projectApi.toLowerCase() == 'viewresults'  || this.projectApi.toLowerCase() == 'rules' || this.projectApi.toLowerCase() == 'tasks' || this.projectApi.toLowerCase() == 'classes' ||
                        this.projectApi.toLowerCase() == 'datasets' || this.projectApi.toLowerCase() == 'datasources' || this.projectApi.toLowerCase() == 'entities' ||  this.projectApi.toLowerCase() == 'activities' ||
                        this.projectApi.toLowerCase() == 'tenants' || this.projectApi.toLowerCase() == 'train-model' || this.projectApi.toLowerCase() == 'pedictions' || this.projectApiURL.toLowerCase() == 'pedictions' || this.projectApi.toLowerCase() == 'semanticdatasets' ||this.allowServerSorting) {
                        parent.search_api_url = parent.tableSettings.search_url;//parent.search_api_url ? parent.search_api_url : parent.tableSettings.search_url; 
                        currentPagesize = currentPageno * currentPagesize;
                        const order = orderBy.split(' ')[1];

                        if (this.projectApi.toLowerCase() == 'pedictions' || this.projectApiURL.toLowerCase() == 'pedictions' || this.projectApi.toLowerCase() == 'classifiers' || this.projectApi.toLowerCase() == 'rules' || this.isOrderTypeKey ) {
                            orderBy = orderBy.split(' ')[0] + '&orderType=' + order;
                        } else {
                            orderBy = orderBy.split(' ')[0] + '&type=' + order;
                        }                        
                        if (parent.search_api_url.includes("&orderby=")) {
                            var index = parent.search_api_url.indexOf("&orderby=");
                            parent.search_api_url = parent.search_api_url.substring(0, index);

                        }
                        if (parent.search_api_url.includes("?ignore_system_attributes=true") && this.projectApi.toLowerCase() === 'tags') {
                            const firstChar = orderBy.slice(0, 1);
                            if (firstChar === '?') {
                                orderBy = orderBy.replace("?", "&");
                            }  
                        }

                        if (parent.search_api_url.includes("?orderby=") && this.projectApi.toLowerCase() == 'tags') {
                            var index = parent.search_api_url.indexOf("?orderby=");
                            parent.search_api_url = parent.search_api_url.substring(0, index);

                        }

                        if (parent.search_api_url.includes("?orderby=") && this.projectApi.toLowerCase() === 'users' && this.isDatasourceUser && (this.catalogApi.toLowerCase() === 'datasources' || this.catalogApi.toLowerCase() === 'groups')) {
                            let index = parent.search_api_url.indexOf("?orderby=");
                            parent.search_api_url = parent.search_api_url.substring(0, index);

                        }
                        if (parent.search_api_url.includes("?only_admins=true") && this.isDatasourceUser) {
                            const firstChar = orderBy.slice(0, 1);
                            if (firstChar === '?') {
                                orderBy = orderBy.replace("?", "&");
                            }
                        }
                        parent.search_api_url = parent.search_api_url + orderBy;
                        parent.getData(parent.search_api_url, parent, resolve, query);
                        if (this.projectApi.toLowerCase() === 'concepts') {
                            return;
                        }

                        if (parent.search_api_url.includes("entitiesresolved/fixissues/summary")) {
                            return;
                        }

                        if (this.handleServerSideSearchAndSortUpdateInComponent) {
                            return;
                        }

                    } else if (this.isCustomFormatterSearchFromDownVoteModel && columnSortForFormatterType && sortOrderType) {
                        if (sortOrderType === 'asc') {
                            dataArray.sort(function(a, b) {
                                return a['score'] - b['score'];
                            })
                            return resolve({ items: dataArray, totalRecordCount: dataArray.length, query });
                        } else {
                            let tempArray = dataArray.map((val) => {
                                return val;
                            });;
                            tempArray.sort(function(a, b) {
                                return b['score'] - a['score'];
                            })
                            return resolve({ items: tempArray, totalRecordCount: tempArray.length, query });
                        }
                    } else if (this.isGoldenRecordDetailScreen) {
                        // Not covered for all grid is GoldenRecord Details Screen. It's
                        //being used only on the left grid component being used in GoldenRecordsDetailsComponent for sorting.
                        // Urgent code. refactor later
                        let tempKey = {};
                        const tempColumnArray = columnName.split('=');
                        const colSortOrder = orderBy.split('=');
                        
                        let temp = tempColumnArray.length > 0 && tempColumnArray[1] ? tempColumnArray[1] : null;
                        let sortOrderTypeArray = colSortOrder.length > 0 && colSortOrder[1] ? colSortOrder[1].split(' '): [];
                        let sortOrderType = sortOrderTypeArray.length > 0 ? sortOrderTypeArray[1] : 'asc';

                        let ascSort = (a,b)  =>{
                            if (temp === 'display_name') {
                                if ( a.display_name.toString().toLowerCase() < b.display_name.toString().toLowerCase()){
                                    return -1;
                                }
                                if ( a.display_name.toString().toLowerCase() > b.display_name.toString().toLowerCase()){
                                    return 1;
                                }
                                return 0;
                            }
                        
                            if (temp === 'value') {
                                if ( (a.original_value && a.original_value.toString().toLowerCase()) < (b.original_value && b.original_value.toString().toLowerCase())){
                                    return -1;
                                }
                                if ( (a.original_value && a.original_value.toString().toLowerCase()) > (b.original_value && b.original_value.toString().toLowerCase())){
                                    return 1;
                                }
                                return 0;
                            }
                            return 0;
                        }

                        let descSort = (a,b)  =>{
                            if (temp === 'display_name') {
                                if ( b.display_name.toString().toLowerCase() < a.display_name.toString().toLowerCase() ){
                                    return -1;
                                }
                                if ( b.display_name.toString().toLowerCase() > a.display_name.toString().toLowerCase() ){
                                return 1;
                                }
                                return 0;
                            }

                            if (temp === 'value') {
                                if ( (b.original_value && b.original_value.toString().toLowerCase()) < (a.original_value && a.original_value.toString().toLowerCase()) ){
                                    return -1;
                                }
                                if ( (b.original_value && b.original_value.toString().toLowerCase()) > (a.original_value && a.original_value.toString().toLowerCase()) ){
                                    return 1;
                                }
                                return 0;
                            }
                        }

                        if (searchTerm == '') {
                            
                            let tempArray = dataArray.map((val) => {
                                if (val && val.display_name == ' ') {
                                    let originalDelimiterRecord = dataArray.find((oValue) => {
                                        return oValue.attribute_id == val.attribute_id && oValue.display_name != '';
                                    });   
                                    val.display_name = originalDelimiterRecord.display_name;
                                }
                                
                                if (!val.original_value || val.original_value == '') {
                                    val.original_value = val.value || val.value != null? val.value: '';
                                }
                                return val;
                            });
                        
                            
                            if (sortOrderType === 'asc') {
                                tempArray.sort(ascSort);
                            } else {
                                tempArray.sort(descSort);
                            }

                            /**
                             * Keeping a count of records which has broken into different row due to
                             *  Delimiter('$fsDemiter$')                      
                             */ 

                            tempArray.forEach((val) => {
                                if (!tempKey.hasOwnProperty(val.display_name)) {
                                    tempKey[val.display_name] = 1
                                } else {
                                    tempKey[val.display_name] += 1
                                }
                            });
                            
                            // check against display_name
                            let isDataContainsDelimiter = function(key) {
                                return tempKey[key] > 1;
                            }

                            let _sortedDelimiterGoldenRecordsKey = [];
                            tempArray = tempArray.map((val, index) => {
                                if (isDataContainsDelimiter(val.display_name)) {
                                    if (_sortedDelimiterGoldenRecordsKey.indexOf(val.display_name) > -1) {
                                        val.display_name = ' '   
                                    }
                                    _sortedDelimiterGoldenRecordsKey.push(val.display_name);
                                } 
                                return val;
                            });
                            return resolve({ items: tempArray, totalRecordCount: tempArray.length, query });
                        } else {
                            let tempArray = updatedData.map((val) => {
                                if (val && val.display_name == ' ') {
                                    let originalDelimiterRecord = dataArray.find((oValue) => {
                                        return oValue.attribute_id == val.attribute_id && oValue.display_name != '';
                                    });   
                                    val.display_name = originalDelimiterRecord.display_name;
                                }
                                
                                if (!val.original_value || val.original_value == '') {
                                    val.original_value = val.value || val.value != null? val.value: '';
                                }
                                return val;
                            });

                            if (sortOrderType === 'asc') {
                                tempArray.sort(ascSort);
                            } else {
                                tempArray.sort(descSort);
                            }

                            tempArray.forEach((val) => {
                                if (!tempKey.hasOwnProperty(val.display_name)) {
                                    tempKey[val.display_name] = 1
                                } else {
                                    tempKey[val.display_name] += 1
                                }
                            });
                            
                            // check against display_name
                            let isDataContainsDelimiter = function(key) {
                                return tempKey[key] > 1;
                            }

                            let _sortedDelimiterGoldenRecordsKey = [];
                            tempArray = tempArray.map((val, index) => {
                                if (isDataContainsDelimiter(val.display_name)) {
                                    if (_sortedDelimiterGoldenRecordsKey.indexOf(val.display_name) > -1) {
                                        val.display_name = ' '   
                                    }
                                    _sortedDelimiterGoldenRecordsKey.push(val.display_name);
                                } 
                                return val;
                            });
                            
                            return resolve({ items: tempArray, totalRecordCount: tempArray.length, query });
                        }
                    } else if (this.allowTochangeTermOnClientFilter) {
                        // classify task confidence sorting
                        
                        let tempArray = dataArray.filter((taskPrediction) => {
                            return (taskPrediction.class && taskPrediction.class.toLowerCase().includes(searchTerm));
                        })

                        if (columnSortForFormatterType === 'class') {
                            let ascSort = (a,b) => {
                                if (a.class < b.class) {
                                    return -1;
                                }
                                if (a.class > b.class) {
                                    return 1;
                                } 
                                return 0;
                            }

                            let descSort = (a,b) => {
                                if (a.class > b.class) {
                                    return -1;
                                }
                                if (a.class < b.class) {
                                    return 1;
                                } 
                                return 0;
                            }

                            if (sortOrderType === 'asc') {
                                tempArray.sort(ascSort);
                            } else {
                                tempArray.sort(descSort);
                            }
                        }

                        if (columnSortForFormatterType === 'confidence') {
                            let ascSort = (a,b) => {
                                if (parseInt(a.score100) < parseInt(b.score100)) {
                                    return -1;
                                }
                                if (parseInt(a.score100) > parseInt(b.score100)) {
                                    return 1;
                                } 
                                return 0;
                            }

                            let descSort = (a,b) => {
                                if (parseInt(a.score100) > parseInt(b.score100)) {
                                    return -1;
                                }
                                if (parseInt(a.score100) < parseInt(b.score100)) {
                                    return 1;
                                } 
                                return 0;
                            }

                            if (sortOrderType === 'asc') {
                                tempArray.sort(ascSort);
                            } else {
                                tempArray.sort(descSort);
                            }
                        }
                       
                        tempArray = tempArray.map((taskPrediction) => {
                            return taskPrediction;
                        })
                        return resolve({ items: tempArray, totalRecordCount: tempArray.length, query });
                    }
                    else {
                        const columnName1 = columnName.split('=')[1]
                        this.zettaUtils.customSortFun(updatedData, columnName1, sort);
                        //    orderBy='';
                    }
                } else if (this.isCustomFormatterSearchFromDownVoteModel) {
                    // call from downvote model
                    /**
                     *  client side multiple column searchable
                     */
                    let _filtered_search = Object.keys(columnFilters);
                    if (_filtered_search.length > 0) {
                        let searchableColumns = ['score', 'catalog_name', 'semantic_object_name'];
                        let tempData = dataArray.map((val) => {
                            return val;
                        });
                        let _filtered_data = [];
                        searchableColumns.forEach((val) => {
                            if (_filtered_search.indexOf(val) > -1 && val === 'score') {
                                let tempScore = tempData.filter((val) => {
                                    return val.level &&  columnFilters['score'] && columnFilters['score'].term && val.level.toLowerCase().includes(columnFilters['score'].term.toLowerCase())
                                })
                                _filtered_data = tempScore.map((val) => {
                                    return val;
                                });
                            }

                            if (_filtered_search.indexOf(val) > -1 && val === 'catalog_name') {
                                let tempCatalogInputData = _filtered_data.length > 0 ? _filtered_data : tempData;
                                let tempCatalogName = tempCatalogInputData.filter((val) => {
                                    return val.catalog_name &&  columnFilters['catalog_name'] && columnFilters['catalog_name'].term && val.catalog_name.toLowerCase().includes(columnFilters['catalog_name'].term.toLowerCase());
                                });
                                _filtered_data = tempCatalogName.map((val) => {
                                    return val;
                                });
                            }

                            if (_filtered_search.indexOf(val) > -1 && val === 'semantic_object_name') {
                                let tempSemanticObjectInputData = _filtered_data.length > 0 ? _filtered_data: tempData;
                                let tempSemanticObjectName = tempSemanticObjectInputData.filter((val) => {
                                      return val.semantic_object_name &&  columnFilters['semantic_object_name'] && columnFilters['semantic_object_name'].term && val.semantic_object_name.toLowerCase().includes(columnFilters['semantic_object_name'].term.toLowerCase());
                                })
                                _filtered_data = tempSemanticObjectName.map((val) => {
                                    return val;
                                });
                            }
                        })
                        return resolve({ items: _filtered_data, totalRecordCount: _filtered_data.length, query });
                    }
                } else if (this.isGoldenRecordDetailScreen) {
                    /** Edit GOlden record details screen searching */

                    if (searchTerm && searchTerm != '') {
                        let searchAbleFields = ['display_name', 'value'];
                        
                        let tempArray = dataArray.map((val) => {
                            if (val && val.display_name == ' ') {
                                let originalDelimiterRecord = dataArray.find((oValue) => {
                                    return oValue.attribute_id == val.attribute_id && oValue.display_name != '';
                                });   
                                val.display_name = originalDelimiterRecord.display_name;
                            }
                            
                            if (!val.original_value || val.original_value == '') {
                                val.original_value = val.value || val.value != null? val.value: '';
                            }
                            return val;
                        });
                        
                        let tempSearchAbleFields = [];
                        if (Object.keys(columnFilters).length > 0) {
                            tempSearchAbleFields = Object.keys(columnFilters);
                        }
    
                        let userSearchedObject = {};
                        tempSearchAbleFields.forEach((val) => {
                            if (!userSearchedObject.hasOwnProperty(val)) {
                                userSearchedObject[val] = columnFilters[val].term;
                            }
                        })
                        
                        let searchedArray = tempArray.map((val) => {
                            return val;
                        });
    
                        
                        searchAbleFields.forEach((val) => {
                            if (tempSearchAbleFields.indexOf(val) > -1) {
                                searchedArray = searchedArray.filter((searchRecordObject) => {
                                    return  userSearchedObject[val] && searchRecordObject[val] && searchRecordObject[val].toString().toLowerCase().includes(userSearchedObject[val]);
                                });
                            }
                        })
    
                        /**
                             * Keeping a count of records which has broken into different row due to
                             *  Delimiter('$fsDemiter$')                      
                             */ 
                        let tempKey = {};
                        searchedArray.forEach((val) => {
                            if (!tempKey.hasOwnProperty(val.display_name)) {
                                tempKey[val.display_name] = 1
                            } else {
                                tempKey[val.display_name] += 1
                            }
                        });
                        
                        // check against display_name
                        let isDataContainsDelimiter = function(key) {
                            return tempKey[key] > 1;
                        }
    
                        let _sortedDelimiterGoldenRecordsKey = [];
                        searchedArray = searchedArray.map((val, index) => {
                            if (isDataContainsDelimiter(val.display_name)) {
                                if (_sortedDelimiterGoldenRecordsKey.indexOf(val.display_name) > -1) {
                                    val.display_name = ' ';   
                                }
                                _sortedDelimiterGoldenRecordsKey.push(val.display_name);
                            } 
                            return val;
                        });
                        return resolve({ items: searchedArray, totalRecordCount: searchedArray.length, query });
                    } else {
                        /** HACK: make display_name empty for delimiter when search is cleared. Also been called on inital load */
                        let originalRecordsData = dataArray.map((val) => {
                            if (val && val.display_name == ' ') {
                                let originalDelimiterRecord = dataArray.find((oValue) => {
                                    return oValue.attribute_id == val.attribute_id && oValue.display_name != '';
                                });   
                                val.display_name = originalDelimiterRecord.display_name;
                            }
                            
                            if (!val.original_value || val.original_value == '') {
                                val.original_value = val.value || val.value != null? val.value: '';
                            }
                            return val;
                        });
                        let tempKey = {};
                        originalRecordsData.forEach((val) => {
                            if (!tempKey.hasOwnProperty(val.display_name)) {
                                tempKey[val.display_name] = 1
                            } else {
                                tempKey[val.display_name] += 1
                            }
                        });

                        // check against display_name
                        let isDataContainsDelimiter = function(key) {
                            return tempKey[key] > 1;
                        }

                        let _sortedDelimiterGoldenRecordsKey = [];
                        originalRecordsData = originalRecordsData.map((val, index) => {
                            if (isDataContainsDelimiter(val.display_name)) {
                                if (_sortedDelimiterGoldenRecordsKey.indexOf(val.display_name) > -1) {
                                    val.display_name = ' ';   
                                }
                                _sortedDelimiterGoldenRecordsKey.push(val.display_name);
                            } 
                            return val;
                        });
                        return resolve({ items: originalRecordsData, totalRecordCount: originalRecordsData.length, query });

                    }
                }
                // if(searchTerm==='' && orderBy===''){
                setTimeout(() => {
                    // this.isLoading=false; 
                    resolve({ items: updatedData, totalRecordCount: countTotalItems, query });
                }, 500);
                // }

            }

        });
    }
    
    /**
     * Toggle Standard/Compaq view
     * @memberof ZettaGridComponent
     */
    toggleView(): void {
        this.compaq = !this.compaq;
        if (!this.compaq) {
            this.gridOptions.rowHeight = 40;
            this.buttonLabel = 'Click to Compaq View';
        } else {
            this.gridOptions.rowHeight = 20;
            this.buttonLabel = 'Click to Standard View';
        }
        // update Grid upon metaupdate
        this.grid.setOptions(this.gridOptions);
        this.grid.invalidate();
        this.grid.render();
    }

    getGroups(response){
        if(this.currentPageUrl && this.currentPageUrl.includes('ad-users')){
            this.filterdDataList=[];
            const userGroups=[];
            const usersList=[];

            if (this.projectApi.toLowerCase() == 'groups') {
                response["value"].forEach(group => {
                    userGroups.push({
                        'displayName': group.displayName,
                        'description': '',
                        'group_id': group.id,
                        'action': '',
                        'id':Math.random()
                    });
                    this.filterdDataList=userGroups;
                });
            }else{
                response["value"].forEach(member => {
                    usersList.push({
                        'displayName': member.displayName,
                        'givenName': member.givenName,
                        'surname': member.surname,
                        'mail': member.mail,
                        'app_user': false,
                        'user_id': member.id,
                        'action':'delete',
                        'id':Math.random()
                    });
                });
                this.filterdDataList=usersList
            }


        }
    }
    getData(search_api_url, parent, resolve, query) {
        parent.http.get(search_api_url).subscribe(response => {
            if(this.presistRadioSelection && response && response['currentpage']) {
                this.onsearchDataFetched.emit(response['currentpage']);
            }
            if (this.handleServerSideSearchAndSortUpdateInComponent &&  response && response['currentpage'] && response['currentpage'].length) {
                this.noDataFoundMsg= '';
                this.showNoDataFound = false;
                this.dataset = response['currentpage'];
                this.recvTotalRecords.emit(response['totalrecords'] ? response['totalrecords'] : response['currentpage'].length);                
                this.onServerSideDataFetched.emit(response);
                return;
            }
            if(parent.showLoader && parent.showFilterNSortLoaders) {
                parent.showLoader = false;
            }
            if (this.projectApi.toLowerCase() == 'projects') {
                if(response.hasOwnProperty('currentpage') && response['currentpage']){
                    response['currentpage'] = this.getProjectAdmin(response['currentpage']);
                    response['currentpage'].forEach(project => {
                        if(this.isClassifyProject){
                            project.isClassifyProject=true;
                        }
                        if(project.hasOwnProperty('users') && project.users){
                            project.users = Array.from(new Set(project.users.map(user => user.user_id))).length;
                        }
                    });
                }
            }
            if(this.projectApi.toLowerCase() === 'viewresults'){
                response['currentpage'].forEach(result => {
                    result.view_id = result.id;
                });
            }
            if (this.projectApi.toLowerCase() == 'sampledata') {
                response['currentpage'].forEach(result => {
                    result.ds_sample_id = result.id;
                });
            }
            if (this.projectApiURL.toLowerCase() == 'tasks' && this.disableFeedback) {
                if(!response['currentpage'] && response.hasOwnProperty('task_outputs') ){
                    if(response['task_outputs'].length){
                        response['task_outputs'].forEach(result => {
                            result.shouldDisableFeedback = this.disableFeedback;
                        });
                    }
                }
                
            }

             /** ALL TASKS catalog SEMANTIC CLASS TRAINING tasks feedback */
             if (this.catalogApi === 'semantics' && this.projectApi === 'datasets' && this.disableFeedback) {
                console.log('semantic class training');
                if (response['currentpage'] && response['currentpage'].length > 0) {
                    response['currentpage'].forEach((datasets) => {
                        datasets.shouldDisableFeedback = this.disableFeedback;
                    })
                }
            }

            /** ALL TASKS catalog CONCEPT CLASS TRAINING tasks feedback */
            if (this.catalogApi === 'concepts' && this.projectApi === 'mappedcolumns' && this.disableFeedback) {
                console.log('concept class training');
                if (response['currentpage'] && response['currentpage'].length > 0) {
                    response['currentpage'].forEach((columns) => {
                        columns.shouldDisableFeedback = this.disableFeedback;
                    })
                }
            }

            if (this.projectApi === 'semantics' && this.isEntityList) {
                response['currentpage'].forEach(result => {
                    result.entity_admin = this.isCatalogAdmin;
                });
            }
            if(this.projectApi.toLowerCase() === 'dqrules'){
                response['currentpage'].forEach(result => {
                    result.data_quality_rule_id = result.rule_id;
                    result.dq_dimension=result.rule_dimension;
                    if (result.hasOwnProperty('role_name')) {
                        if ( result.role_name == 'dq_rule_admin' ) {
                            result.shouldDisable = false;
                        } else {
                            result.shouldDisable = true;
                        }
                    }
                });
            }
            if (this.projectApi.toLowerCase() === 'queue') {
                response['currentpage'].forEach(result => {
                    if (result.algoJob.hasOwnProperty('stage_name')) {
                        result.job_name = result.algoJob.stage_name;
                    }
                    if (result.algoJob.hasOwnProperty('Job_Type') || result.algoJob.hasOwnProperty('job_type')) {
                        result.job_type = result.algoJob.Job_Type;
                    }
                });
            }
            if(this.projectApi.toLowerCase() === 'rules'){
                response['currentpage'].forEach(result => {
                    if (result.hasOwnProperty('measured_data_quality')) {
                        result.measured_dq_score = result.measured_data_quality;
                    }
                    if (result.hasOwnProperty('quality_score')) {
                        result.measured_dq_score = result.quality_score;
                    }
                    if (result.hasOwnProperty('role_name')) {
                        if ( result.role_name == 'dq_rule_admin' ) {
                            result.shouldDisable = false;
                        } else {
                            result.shouldDisable = true;
                        }
                    }
                    result.isRuleEnabled = this._userInfo["enable_dq_rule"];
                    result.view_id = result.rule_id ? result.rule_id : result.data_quality_rule_id;
                    result.rule_id = result.rule_id ? result.rule_id : result.data_quality_rule_id;
                });
            }
            if (this.projectApi.toLowerCase() == 'tasks') {
                response['currentpage'].forEach(element => {
                    element.shouldDisable = (element.status == AppGlobals.TASK_COMPLETED || element.task_status == AppGlobals.TASK_COMPLETED) ? true : false;
                    element.enableLink = element.assigned_to == this._userInfo['user_id'] ? true : false;
                });
            }
            if (this.isManageTenantGrid) {
                response['currentpage'] = this.getTenantLogo(response['currentpage']);
            }
            if(this.checkForTrainModal) {
                if (this.projectApi.toLowerCase() == 'semantics' || this.projectApi.toLowerCase() == 'concepts') {
                    response['currentpage'].forEach(ele => ele.iscatalogUser = this.isCatalogUser);
                }
            }
            if (this.synonymObjectValidation == true) {
                this.checkForDisable(response['currentpage']);
            }
            if (this.projectApi.toLowerCase() == 'attributesdefaultrules') {
                if (response['currentpage'].length) {
                    response['currentpage'] = this.baseSvc.getAttributeRules(response['currentpage']);
                }
            }
            //this.recvTotalRecords.emit(response['totalrecords']);
            if(response['currentpage']){
                this.filterdDataList = response['currentpage'].map((item) => {
                    item['action'] = 'edit';
                    item['id'] = Math.random();
                    if(this.allowSelection){
                        item.isChecked = item[`${this.selectedColumn}`] == this.selectedColumnValue ? true : false;
                    }
                    return item;
                });
                if(this.allowToAddEmptyAddRow){
                    this.filterdDataList.splice(0, 0, this.emptyAddRow);
                }
            }else{
                if(this.isResponseObjectArray) {
                    this.filterdDataList = response['task_outputs'] ? response['task_outputs'] : response;
                } else {
                    this.filterdDataList = response;
                }
                if(this.filterdDataList.length){
                    this.filterdDataList.forEach(element => {
                        if (element.id) {
                            element['newId'] = element.id;
                        }
                        if (element.score) {
                            element.score100 = (element.score * 100).toFixed();
                        }
                        element['id'] = Math.random();
                    });
                }
            }

            
            if (this.projectApi.toLowerCase() == 'attributes' && parent.tableSettings.search_url
                    && parent.tableSettings.search_url.includes('entities') && parent.tableSettings.search_url.includes('attributes')
                    && parent.tableSettings.search_url.includes('project') && parent.search_api_url.includes("orderby=")) { 
                     
                     
                this.onDataSorted.emit(response);
                return;
            }

            if (this.projectApi.toLowerCase() === 'winners') {
                this.filterdDataList.forEach((goldenRecordData) => {
                    let columnKeys = Object.keys(goldenRecordData);
                    columnKeys.forEach((columns) => {
                        if (goldenRecordData[columns] && typeof goldenRecordData[columns] === 'string' &&
                        goldenRecordData[columns].includes(AppGlobals.GOLDEN_RECORD_DELIMITER)) {
                            goldenRecordData[columns] = goldenRecordData[columns].replaceAll(AppGlobals.GOLDEN_RECORD_DELIMITER, ';');
                        }
                    })
                })
            }
            this.recvTotalRecords.emit(response['totalrecords'] ? response['totalrecords'] : this.filterdDataList.length);
            this.recvTotalRows.emit(response);

            if (this.projectApi.toLowerCase() === 'concepts') {
                this.onDataSorted.emit(response);
                return;
            }

            if (this.projectApi.toLowerCase() === 'summary' && parent.search_api_url.includes("entitiesresolved/fixissues/summary")) {
                if (this.filterdDataList && this.filterdDataList.length) {
                    this.filterdDataList = this.filterdDataList.map((taskInfo) => {
                        taskInfo.shouldDisable = taskInfo && taskInfo.task_status && (taskInfo.task_status === AppGlobals.TASK_COMPLETED || taskInfo.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
                        return taskInfo;
                    })
                }
            }

            this.getGroups(response)
            parent.dataView = new Slick.Data.DataView({});
            parent.dataView.beginUpdate();
            parent.angularGrid.dataView.setItems(this.filterdDataList);
            parent.angularGrid.gridService.setSelectedRows([]);
            parent.dataView.endUpdate();
            // this.isLoading =false;
            resolve({ items: this.filterdDataList, totalRecordCount: response['totalrecords'], query });

        }, error => {
            this.showLoader = false;
          });
    }

    /**
     * Get Grid Instance once grid is ready
     * @param {AngularGridInstance} angularGrid
     * @memberof ZettaGridComponent
     */
    angularGridReady(angularGrid: AngularGridInstance): void {
        this.grid = angularGrid.slickGrid;
        this.gridCreated.emit(angularGrid);
        this.angularGrid = angularGrid;

        const parent = this;

        // added Context Menu 
        this.grid.onClick.subscribe(function (e, args) {
            e.preventDefault();
            try {
                parent.openContextMenuAction(e);
            } catch (e) { }

        });
        this.grid.onSort.subscribe((e, args) => {
                if(this.showFilterNSortLoaders) {
                if(!this.showLoader) {
                    this.showLoader = true;
                }
            }
            });
        if(this.persistCheckBoxSelection) {
            this.grid.onSort.subscribe((e, args) => {
                this.gridSortClicked.emit(angularGrid);
            });
        }
        parent.dataView = angularGrid.dataView;
    }

    openContextMenuAction(e) {
        const parent = this;
        const args = parent.grid.getCellFromEvent(e);
        if (args && args.cell === parent.grid.getColumnIndex('actions')) {
            parent.openContextMenu.emit({ 'eventData': e, 'args': args });
        }
    }

    onCellClicked(e, args) {
        // if (args.cell !== 0) {
        //     this.grid.setSelectedRows([args.row]);
        // }
        this.cellClicked.emit({ 'eventData': e, 'args': args });
    }


    getCtmGridOption(property: string) {

        if (this.ctmGridOptions && this.ctmGridOptions[property] !== null) {
            return this.ctmGridOptions[property];
        }
    }

    onSelectedRowsChanged(e, args) {
        this.rowsSelected.emit(args);
    }

    getProjectAdmin(response) {
        const admin_role_id = this.isClassifyProject ? AppGlobals.CLASSIFY_PROJECT_ADMIN_ROLE_ID : AppGlobals.RESOLVE_PROJECT_ADMIN;
        response.forEach(project => {
            project.isdisable = true;
            let project_admin_users = project.users.find(user => user.role_id === admin_role_id && user.user_id === this._userInfo['user_id']);
            if (project_admin_users) {
                project.isdisable = false;
            }
        });
        return response;
    }

    checkForDisable(response) {
        let catalog_id;
        let object_id;
        let syn_catalog_id;
        this.route.parent.params.subscribe(params => {
            catalog_id = +params['catalog_id'];
        });
        object_id = this.route.snapshot.queryParamMap.get('object_id');
        syn_catalog_id = this.route.snapshot.queryParamMap.get('syn_catalog_id');
        if (catalog_id == syn_catalog_id) {
            if (response && response.length) {
                response.forEach(element => {
                    if (element.entity_id == object_id) {
                        element['checkForDisableCond'] = true;
                    }
                });
            }
        }
        $('div.disable-row').parent().parent().removeClass('disabled-state');
        setTimeout(() => {
            $('div.disable-row').parent().parent().addClass('disabled-state');
        }, 500)
    }

    getTenantLogo(response) {
        response.forEach(tenant => {
            this.contentSvc.getLogo(this._userInfo['user_id'], tenant.tenant_id).subscribe(image => {
                let reader = new FileReader();
                reader.addEventListener('load', () => {
                    tenant.image = reader.result;
                    this.angularGrid ? this.angularGrid.slickGrid.invalidate() : '';
                }, false);
                if (image && image.size) {
                    reader.readAsDataURL(image);
                }
            });
        });
        return response;
    }

    ngAfterViewChecked() {

        // Remove grouped Rows Border
        this.groupedRows();

        // Add Last Row bottom Border
        this.lastRowBorder();

        // for attaching the dragble elements
        if (this.getCtmGridOption('dragRow') !== null && this.getCtmGridOption('dragRow')) {
            $('.slick-row').draggable({

                // cursor: 'crosstalk',
                containment: 'document',
                appendTo: 'body',
                cursor: 'move',
                helper: 'clone',
                revert: true,
                revertDuration: 200,
                zIndex: 9999,
                scroll: false
            });
        }
    }
    groupedRows() {
        const groupedCell = $('.groupedDiv').parent();
        if (groupedCell.length > 0) {
            for (let i = 0; i < groupedCell.length; i++) {
                $(groupedCell[i]).removeClass('grouped-row');
                $(groupedCell[i]).addClass('grouped-row');
                $(groupedCell[i]).siblings().removeClass('grouped-row');
                $(groupedCell[i]).siblings().addClass('grouped-row');
            }
        }
    }

    lastRowBorder() {

        const elements = $('div.grid-canvas-left');
        if (elements) {
            for (let i = 0; i < elements.length; i++) {

                let maxTop = 0;
                let lastRowElement = elements[i].children[0];

                for (let k = 0; k < elements[i].children.length; k++) {

                    // Clean existing css if any
                    for (let j = 0; j < elements[i].children[k].children.length; j++) {
                        $(elements[i].children[k].children[j]).removeClass('grid-last-row-bottom-border');
                    }
                    // get last row based on top style 
                    let tempTopStyle = parseInt($(elements[i].children[k]).css("top").replace('px', ''));
                    if (tempTopStyle > maxTop) {
                        maxTop = tempTopStyle;
                        lastRowElement = elements[i].children[k];
                    }
                }

                if (lastRowElement) {
                    // Add Bottom Border on Cells of last row
                    for (let j = 0; j < lastRowElement.children.length; j++) {
                        $(lastRowElement.children[j]).addClass('grid-last-row-bottom-border');
                    }
                }
            }
        }
    }

    onResize() {
        if (this.angularGrid) {
            this.angularGrid.resizerService.resizeGrid(250);
        }
    }

    onGridScroll(e, args) {
        //On Grid cell is executing multiple times when we click/select the grid cell. Just to differentiate we are checking for scrollTop . ScrollTop is 0 when grid cell is clicked other it is the number other than 0. 
        //Just checking it is not 0, if not zero then it is a scroll event. - SHARAD BIRADAR 29/03/2022
        if (args.scrollTop !== 0) {
            this.gridScroll.emit({ 'eventData': e, 'args': args });
        }
        //this.gridScroll.emit({ 'eventData': e, 'args': args });
        // this.cellClicked.emit();
    }

    addDefaultEmptyRow() {
        return {
            'id': Math.random(),
            'entity_id': 'Select Object...',
            'logical_name': '',
            'description': '',
            'name': '',
            'mapped_data_columns': '0',
            'action': 'add',
            'train': 'train'
        }
    }
}
