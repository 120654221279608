import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { environment } from 'src/environments/environment';
import { Project } from '../../classify.model';
import { ZsClContentService } from '../../zs-cl-content.service';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
// import { NoRowOverlayComponent } from '../../no-row-overlay/no-row-overlay.component';
import { ActionLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { NumberFormatterComponent } from 'src/app/common/shared/cell-renderer/number-formatter/number-formatter.component';
import { event, param } from 'jquery';
declare var $: any;
import { ProjectStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/project-currentStatusType-formattter/project-currentStatusType-formattter.component';
import { DataClassificationProjectFormatterComponent } from 'src/app/common/shared/cell-renderer/data-classification-project-formattter/data-classification-project-formattter.component';
const zettaUtilsInstance = new ZettaUtils();

@Component({
  selector: 'zetta-zs-cl-projects',
  templateUrl: './zs-cl-projects.component.html',
  styleUrls: ['./zs-cl-projects.component.scss']
})
export class ZsClProjectsComponent implements OnInit {

  isLoading = true;
  projectDataSource:any=[];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  total = 0;
  grandTotal = 0;
  subTotal = ''; 
  noData = false;
  selectedProjectMetadata = false;
  canDeleteProject = false;
  page = 1;
  limit = 100;
  msgHeader: string;
  msgText: string;    
  showConfirmBox: boolean;
  columnApi: any;
  gridApi:any;
  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  subTotalRecords = 0;
  
  row;
  constructor(private _router: Router, private contentService: ZsClContentService,
     public zettaUtils:ZettaUtils, private messageSvc:MessageService) { }

  ngOnInit() {
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects`;

    this.getProjectHomeList(this.page,this.limit);  
    $('#breadcrumb').removeClass('d-none');
    this.intialiseGrid(); 

  }
  intialiseGrid() {
    this.columnDefs = [ 
      {
        headerName: '',
        field: 'actions', 
        filter: false,
        sortable: false, 
        cellClass: 'elipse-overflow',
        editable: false, 
        minWidth:30,
        maxWidth: 40,
        cellRendererFramework: ActionLinkFormatterComponent,
        suppressColumnsToolPanel: true,
      },{
        headerName: 'Id',
        field: 'classify_project_id',  
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        floatingFilter: true, 
        minWidth:100,
        maxWidth: 130,
        sortable: true, 
      }, {
        headerName: 'Data Classification Project',
        field: 'name',
        sortable: true, 
        minWidth: 200,
        floatingFilter: true,
        cellRendererFramework: DataClassificationProjectFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
        
      }, {
        headerName: 'Datasets',
        field: 'datasets',
        cellRendererFramework: NumberFormatterComponent,
        minWidth:100,
        maxWidth:130, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
        cellClass: 'custom-class-name ag-right-aligned-cell',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
      }, {
        headerName: 'Records',
        field: 'total_records',
        filter: 'agTextColumnFilter',
        minWidth:100,
        maxWidth:130, 
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
        cellRendererFramework: NumberFormatterComponent,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Project Type',
        field: 'project_type',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:160
      }, {
        headerName: 'Current Status',
        field: 'status',
        cellRendererFramework: ProjectStatusFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        sortable: true,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:150
      }, {
        headerName: 'Created By',
        field: 'created_by',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,   
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:150
      }, {
        headerName: 'Last Modified',
        field: 'latest',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,   
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:160,
        cellRendererFramework: DateFormatterFormatterComponent
      }
    ];
    
    this.fsGridOptions = {  
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 52,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
 
  }
  getProjectHomeList(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects?paginate=true&pageno=${1}&pagesize=${limit}`;
    const data ={
      user_id: this.loggedInUserDetails.user_id,
      tenant_id:this.loggedInUserDetails.tenant_id,
      pageno: pageNo,
      pagesize: limit
    };
    let serverSort = "";
    let serverfilter = "";
    this.isLoading = true;
    this.contentService.getAllClassifyProject(data,serverfilter, serverSort).subscribe((data) => {      
      this.projectDataSource = data["currentpage"];
      if (this.projectDataSource.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      if (pageNo === 1) {
        this.grandTotal = data['totalrecords'];
      }
      let concatedRows = this.projectDataSource; 
      this.projectDataSource.forEach(element => {
        element.id = Math.random();
        element['latest'] = this.formatDate(element['latest']);
      });
      this.projectDataSource.forEach((element, i) => {
        element.isClassifyProject = true;
        element.project_admin = false;
        element.isdisable = true;
        if (element.users.length) {
          let project_admin_users = element.users.find(user => user.role_id === AppGlobals.CLASSIFY_PROJECT_ADMIN_ROLE_ID && user.user_id === this.loggedInUserDetails.user_id);
          if (project_admin_users) {
            element.project_admin = true;
            element.isdisable = false;
          }
           
        }
      });     
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.projectDataSource];
        this.projectDataSource = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
      }
      if(pageNo > data['totalpages']) {        
        this.page = 1;
        this.tableSettings['search_url'] =  `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects?paginate=true&pageno=${1}&pagesize=${limit}`;
      }
    }, err => {
      this.isLoading = false;
      this.hasScrolled = false;
    });
  }
  createNewProject() {
    this._router.navigate(['/zs-cl/projects/create-project']);
  }
  onGridCreation(grid:any) {
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
    this.agGrid.api.addEventListener('filterChanged', this.onFilterChanged.bind(this));
    this.intialiseGrid();
  }
  onFilterChanged() {
    const displayedRowCount = this.agGrid.api.getDisplayedRowCount();
    // if (displayedRowCount === this.grandTotal) {
    //   this.subTotal = '';
    // } else {
    //   this.subTotal = displayedRowCount.toString();
    // }
  }
  receiveTotalRecords($event) {   
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal); 
    
   }
  
  onCellClick(e): void {
    const fieldName = e.colDef.field;
    const eventEle = e.eventData;
    const args = e.args;
   this.row = e.data

    if (this.row !== undefined && this.row !== null) {
      // let element = eventEle.target.getAttribute("data-title");  
      if (e.value === this.row.name) {
        if(!this.row.ever_executed) {
          this._router.navigate(['/zs-cl/projects/create-project'], { queryParams: { id: this.row.classify_project_id } });
        }else{
          sessionStorage.setItem('project_type', this.row.project_type);
          this._router.navigate(['/zs-cl/projects', this.row.classify_project_id], { queryParams: { project_type: this.row.project_type, project_type_id: this.row.project_type_id  }});
        }
      }    
    } 
    if (fieldName === 'actions') {
    if (this.row.isdisable) {
      $('#context_menu').hide();
    } else {
      $('#context_menu')
      .removeClass('d-none')
      .data('row', e.rowIndex)
      .css('top', e.event.clientY)
      .css('left', e.event.clientX + 4)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();
    }

    $('body').one('click', function () {
      $('#context_menu').hide();
    });
  }
  }

  onEditProject(hasEditClicked) {
    if (hasEditClicked) {
      this._router.navigate(['/zs-cl/projects/create-project'], { queryParams: { id: this.row.classify_project_id, showBreadcrumb: false,isEditProject:true} , queryParamsHandling: 'merge' });   
    }
  }
  onDeleteProject(hasDeleteClicked) {
    if (hasDeleteClicked) {
      this.msgHeader =  AppGlobals.CLASSIFY_PROJECT_DELETE_MSG_TEXT;
      this.showConfirmBox = true;
    }
  }
  onCloneProject(hasCloneClicked) {
    if (hasCloneClicked) {
      this.cloneRow(this.row);
    }
  }

  cloneRow(projectObj) {
    const parent = this;
    const tempProject = new Project();
    tempProject.project_id = projectObj.classify_project_id;
    tempProject.user_id = this.loggedInUserDetails.user_id;
    tempProject.tenant_id = this.loggedInUserDetails.tenant_id;
    let data = projectObj;
    this.isLoading = true;
    parent.contentService.cloneProject(tempProject, data).subscribe(response => {
      if(response && response.length>0) {
          parent._router.navigate(['/zs-cl/projects/create-project'], { queryParams: { id: response[0].classify_project_id, showBreadcrumb: false } }); 
      }
    }, err => {
      this.isLoading = false;
      if(err.error.error === 'Not foud the project '+ tempProject.project_id ) {
        this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
      } else {
        this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
      }
    });
  }

  onScroll(parentClass) {
    if (!this.hasScrolled) {      
      this.hasScrolled = true;
      this.onNext();
    }
  }
  onNext(): void {
    this.page++;
    this.getProjectHomeList(this.page, this.limit);
  }
 
  deleteProject(){
    const data ={
      user_id: this.loggedInUserDetails.user_id,
      tenant_id:this.loggedInUserDetails.tenant_id,
      project_id:this.row.classify_project_id,
    };
    this.contentService.deleteProject(data).subscribe(resp=>{
      if(resp && resp.status == 200) {
        this.clearText(resp);
        this.projectDataSource=[];
        this.isLoading=true;
        if (this.agGrid) { 
          this.agGrid.api.purgeServerSideCache();  
        }  
        this.messageSvc.sendMessage({ message: 'Classification project has been deleted successfuly!', type: 'SUCCESS', hideboard: true });
        this.page=1;
        this.hasScrolled=false;
        this.noData=false;
        this.getProjectHomeList(this.page,this.limit);
      }
    },error=>{
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  onConfirmDelete(event) {
    this.deleteProject();
  }

  clearText(event) {
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }
  
  formatDate(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    return `${month}/${d.getDate()}/${d.getFullYear()}`;
  }
}
