import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZsClContentService } from '../../../../zettaclassify/zs-cl-content/zs-cl-content.service';
declare var $: any;


@Component({
    selector: 'zetta-bussiness-rules',
    templateUrl: './bussiness-rules.component.html',
    styleUrls: ['./bussiness-rules.component.scss']
})

// This is the Parent modal for create new entity level data quality rule  
export class BussinessRulesComponent implements OnInit {

    loggedInUserDetails;
    progressState = new ProjectStatus();
    catalog_id:string;
    object_id:string;
    concept_id:string;
    dq_rule_level:string;
    @Input() showCreateModel:boolean;
    @ViewChild('businessModal') businessModal: ElementRef;
    header = "Create New Business – Entity Level Data Quality Rule";
    ruleIds: any = [];
    rule;
    backTo:string;
    is_clone = false;
    rule_id;

    constructor(public zettaUtils: ZettaUtils, 
                private router: Router, 
                private activatedRoute:ActivatedRoute,
                public ZsClContentService: ZsClContentService) { }

    ngOnInit() {     
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.queryParamMap.subscribe(params => {
            const is_clone = params.get('is_clone');
            if (is_clone && is_clone === 'true') {
                this.is_clone = true;
            }
            this.rule_id = params.get('rule_id');
            let ruleIds = params.getAll('rule_id');
            this.ruleIds = ruleIds.filter((id, ind) => ruleIds.indexOf(id) == ind);
            if(this.ruleIds.length && this.ruleIds[0])
            {
              this.ZsClContentService.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.ruleIds[0]).subscribe((resp) => {
                this.rule = resp;
                if(this.router.url.includes('edit-users') && this.router.url.includes('showBreadCrumb=true')){
                    this.header = "Rule: " + this.rule.rule_name;
                }else if(this.router.url.includes('add-users') && this.router.url.includes('showBreadCrumb=true')) {
                    this.header = `Edit Rules for (${this.ruleIds.length}) Rules Selected`;
                }else {
                    this.header = "Create New Business - Entity Level Data Quality Rule";
                }
            }, err => { });
            }
        });

        this.activatedRoute.queryParamMap.subscribe(params => {
            this.catalog_id = params.get('catalog_id');
            this.object_id = params.get('object_id');
            this.concept_id = params.get('concept_id');
            this.dq_rule_level = params.get('dqRuleLevel');
            this.backTo = params.get('BackTo');
        });
        $(this.businessModal.nativeElement).modal({backdrop: 'static', keyboard: false});
    }

    onCancel(){
        if(this.dq_rule_level=='concept'){
            if(this.backTo=='catalog'){
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`],{queryParams:{object_id:this.object_id,showBreadcrumb:true}});
            }else{
              this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`],{queryParamsHandling:'preserve'});
            }            
        }else if(this.dq_rule_level=='object'){
            if(this.backTo=='catalog'){
              this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`],{queryParams:{showBreadcrumb:true}});
            }else{
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`],{queryParamsHandling:'preserve'});
            }
        }else if(this.dq_rule_level=='catalog'){
            if(this.backTo=='catalog'){
                this.router.navigate(['/zs-cl/catalogs'], {queryParams:{showBreadcrumb:true}});
            }else{
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`],{queryParamsHandling:'preserve'});
            }
            
        }else{
            if(this.is_clone) {
                let rule_ids: any = [];
                rule_ids.push(this.rule_id)
                this.ZsClContentService.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
                sessionStorage.removeItem('ruleInfo');
                sessionStorage.removeItem('ruleInfoClone');
                sessionStorage.removeItem("tRuleDefinition");
            } 
           this.router.navigate(['/zs-cl/dataquality']);
        }
    }
    
}