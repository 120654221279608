import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';
@Component({
  selector: 'name-link-formatter',
  template: `<div [ngClass]="{'disabled-state':!active}" class="text-truncate"><label title="{{value}}" class="blueLink text-truncate w-100">{{value}}</label></div>`,
  styles: [
  ]
})
export class NameLinkFormatterComponent implements OnInit, ICellRendererAngularComp {

  active = false;
  value: any;
  params: any;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    if (params.data && params.data.entitlement) {
      if (params.data.entitlement.read_write || params.data.entitlement.read || params.data.entitlement.is_admin) {
        this.active = true;
      } else {
        this.active = false;
      }
    }

    const disabledElements = document.querySelectorAll('.text-truncate.disabled-state'); 
disabledElements.forEach((element) => {
	const parentCell = element.closest('.ag-cell.ag-cell-not-inline-editing');
	if (parentCell) {
		parentCell.addEventListener('click', (event) => {
			event.stopPropagation();  
		});
	}
});
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.params.context.componentParent.updateRow(this.params.rowIndex);
  }


}
