import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'zetta-no-row-overlay',
  templateUrl: './no-row-overlay.component.html',
  styleUrls: ['./no-row-overlay.component.scss']
})
export class NoRowOverlayComponent implements OnInit, INoRowsOverlayAngularComp {
  params:any;
  constructor() { }
  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }
  

  ngOnInit() {
  }

}
