import { Component, OnInit, OnDestroy } from '@angular/core';
import { IMessage } from './message.model';
import { Subscription } from 'rxjs';
import { MessageService } from './message.service';
import _ from 'lodash';
import { Router } from '@angular/router';
import { BaseService } from '../../services/base-service';
declare var $: any;
@Component({
  selector: 'zetta-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  flash: IMessage;
  subscription: Subscription;
  showboard = false;
  timeout;
  showInfo=false;
  path:string;
  activeState:string;
  messageHeader:string;
  messageText:string;
  showConfirmation=false;
  isCreateDataSets = false;
  showInfoOnly = false;
  isReRunRule=false;
  queryParams = {};
  constructor(private messageSvc: MessageService, private route:Router, private baseService:BaseService) {
  }

  ngOnInit() {    
    this.subscription = this.messageSvc.getMessage().subscribe((message) => {
      this.flash = message;
      this.flash.type = this.flash.type.toUpperCase();
      this.showInfoOnly = (this.flash && this.flash['showInfoOnly']) ? true : false;
      this.showboard = (this.flash && this.flash.semanticDatasetsCreate) ? false : true;
      const parent = this;
      if(parent.flash.hideInfo){
        this.showInfo=true;
        this.path=parent.flash.path;
        this.activeState=this.flash.activeJobs;
        this.messageHeader = parent.flash.messageHeader;
        this.messageText = parent.flash.messageText;
        this.queryParams = this.flash.queryParams || null;
      }
      if (parent.flash.isCreateDataSet) {
        this.isCreateDataSets = true;
      }
      if (parent.flash.isReRunRule) {
        this.isReRunRule = true;
      }
      
      if(parent.flash.showConfirmation){
        this.showConfirmation = true;
      }
      if (parent.flash.hasOwnProperty('message') && (parent.flash.message === '')) {
        parent.showboard = false;
      } else {
        if(!this.showConfirmation){
        this.timeout = setTimeout(() => {
          if (parent.flash.hideboard) {
            parent.showboard = false;
          }
        }, (parent.flash.time) ? parent.flash.time : 5000);
        }
      }
    });
    this.baseService.showConfirmation.subscribe(showModel =>{
     this.onClose();
    });
  }

  onClose() {
    this.messageSvc.clearMessage();
    this.showboard = false;
    this.showConfirmation = false;
    this.showInfo = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearTimeout(this.timeout);
  }
  onSystemJob(){    
    this.showInfo=false
    this.hideModel(); 
    this.route.navigate(['/zs/jobs'],{queryParams:{'activeState':this.activeState}});
  }
  onContinue(){
    this.showInfo = false;
    this.hideModel(); 
    if(this.path){
      this.baseService.hasPageRefresh.next(true);
      this.route.navigate([this.path], {queryParams:this.queryParams});
    }    
  }

  onProceedRename() {
    this.baseService.addManualClass.next(true);
    this.onClose();
  }
  hideModel() {
    if (this.flash.isModel) {
      $('#divBackdrop').removeClass('backdrop');
      $('.modal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }
  }

  onOk(){
    this.baseService.deleteRule.next(true);
  }

  onSemanticsJobs() {
    this.showboard = false;
    this.onSystemJob();
  }
}
