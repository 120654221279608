import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-zm-analytics',
  templateUrl: './zm-analytics.component.html',
  styleUrls: ['./zm-analytics.component.scss']
})
export class ZmAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
