import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseService } from 'src/app/common/services/base-service';

@Component({
    selector: 'zetta-db',
    templateUrl: './user-db.component.html',
    styleUrls: ['./user-db.component.scss']
})
export class UserDbComponent implements OnInit {
    apiUrl: string;
    userId: number;
    srcUrl: SafeHtml;
    constructor(private _baseService: BaseService, private sanitizer: DomSanitizer) { }
    ngOnInit() {
        this.userId = this._baseService.getObjectFromSession('userInfo').user_id;
        this.apiUrl = this._baseService.apiUrl;
        this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiUrl + '/users/' + this.userId + '/help/db/');
    }
}