import { AngularGridInstance, Grouping, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, AfterViewChecked, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import * as _ from 'lodash';
import { toggleFormatter } from '../../../common/shared/formatters/toggleFormatter';
import { IDeactivateComponent } from '../../../common/shared/auth-guard.service';
import { ConfirmDialogService } from '../../../common/components/confirm-dialog/confirm-dialog.service';
declare var $: any;
import { attributeMappingFormatter } from '../../../common/shared/formatters/attributeMappingFormatter';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { ZsClContentService } from '../zs-cl-content.service';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import { AppGlobals } from '../../../common/shared/app.globals';
import { Dataset, Project } from '../../../zettasense/content/project/project.model';
import { MapDataSetsService } from 'src/app/zettasense/content/project/map-data-sets/map-data-sets-service';

const RIGHT_EMPTY_GRID_MSG = 'Entity attribute list is empty for mapping.';
const LEFT_EMPTY_GRID_MSG = 'No attribute to map.';
const MODAL_EMPTY_GRID_MSG = 'No Data.';

const dragFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return dataContext.groupedRow === true ? `<div class="groupedDiv"></div>`: `<div value =${row} class="row dragMe"><div class="col-md-1 grab"><img src="../../../../assets/images/common/drag-drop-tbrow.png"></div><div class='pointer modal-feedback' title='${value}'>${value}</div></div>` ;
}

const nameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {   
    return value ? `<div value =${row} class="row">
    <div class="pointer hyper-link ml-3" title='${value}'>${value}</div>
</div>` : `<div></div>`; 
}
const toggleFormatter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

    value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const spacer = '<span style=\'display:inline-block;height:1px;width:' + (15 * dataContext['indent']) + 'px\'></span>';
    value = (dataContext['isNewlyCreated'] && dataContext['isNewlyCreated'] === true) ? '' : value;
    if (dataContext['datatype'] === 'Chain' && value !== '') {
        if (dataContext._collapsed) {
            return spacer + ' <span class=\'map-toggle map-expand\'></span>&nbsp;<span class="text-bold">' + value +
                '</span><span class=\'pull-right\'><i class="fa fa-caret-right expandCollapse" aria-hidden="true"></i></span>';
        } else {
            return spacer + ' <span class=\'map-toggle map-collapse\'></span>&nbsp;<span class="text-bold">'
                + value + '</span><span class=\'pull-right\'><i class="fa fa-caret-down expandCollapse" aria-hidden="true"></i></span>';
        }
    } else {
        return (dataContext['indent'] === 0) ? (`<img src="../../../../assets/images/common/drag-drop-tbrow.png" class="mr-1">`+spacer + ' <span class=\'map-toggle\'>' + value + '</span>')
                : (spacer + ' <span class=\'map-toggle\'>' + value + '</span>');
    }
};
const dropFormatter : Formatter = (row : number, cell : number, value : any, columnDef : Column, dataContext : any) =>{ 
 return value === "dropDiv"
 ? `<div class="dropDiv drop" value=${row}>Drag & drop data set attibute here.</div>`
 : `<div class="row dropDiv ${dataContext['styleCss']}" value=${row}>
         <div class="col-md-10 pr-0 text-truncate">
             <label title="${dataContext.semantic_name}.${value}">
                 <span>${dataContext.semantic_name}.</span><span style="font-weight: bolder;">${value}</span>
             </label>
         </div>
         <div class="col-md-1">
             <i title="Remove Mapping" class="fa fa-minus deleteBtn"></i>
         </div>
     </div>`
} 
@Component({
    selector: 'zetta-syn-map-concepts',
    templateUrl: './syn-map-concepts.component.html',
    styleUrls: ['./syn-map-concepts.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SynMapConceptsComponent implements OnInit, OnDestroy, AfterViewChecked, IDeactivateComponent {
    
    isSampelDsLoading = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    rightGridMappedDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    fieldsMapped = 0;
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    entityAttribute = new Map<string, any>();
    defaultEntityAttributes = [];
    mappedDataSetAttributes = [];
    defaultUnMappedEntityAttributes = [];
    entityResp;
    entityRespAfter;
    updatedDataSetAttributes: Dataset[] = [];
    leftGridOptions: any;
    rightGridOptions: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    leftGridMappingHeader: any;
    hasActiveJob = true;
    loggedInUserDetails: [];
    rightGridDataView: any;
    dataset_id;
    catalogs_ids;
    objects_ids;
    catalogs_ids_arr: any = [];
    objects_ids_arr: any = [];
    entitiesResp: any = [];
    count = 0;
    dataviewObj: any;
    gridObj: any;
    catalogs: any;
    objectsList: any = [];
    resp: any = [];
    job_id: any;
    canGoNextScreen = false;
    flash: any = {};
    xxxxx;
    catalog_id;
    object_id;
    concept_id;
    dataset_classify_ids: any = [];
    dataset_ids: any = [];
    no_matches: string = '';
    datasets: string = '';
    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;
    page = 1;
    isLeftGridDataReady = false;
    isRightGridDataReady = false;
    oneRow: any = [];
    mappedOneAttribute: any = [];
    onlyMappedAttributeRight:any =[];
    onlyMappedAttributeLeft?:any =[];
    showmodelpopup=false;
    datasetId:any;
    leftGridMappedAttribute:any =[];
    leftGridTotalMappedAttribute=0;
    defaulMappedtEntityAttributes:any =[];
    dq_rule_level:string;
    @Input() isBusinessRule;
    backTo:string;
    isMappingChanged = false;

    syn_objects_ids:any;
    syn_objects_ids_arr:any;
    syn_catalog_id:any;
    rightCatalog:string;
    leftCatalog:string;
    objectName:string;
    MappledpayloadData=[];
    newLeftGridRow:any;
    newRightGridRow:any;

    constructor(public zettaUtils: ZettaUtils,
                public mapService: MapDataSetsService,
                private datasetSvc: DatasetService,
                private catalogSvc: ZsClContentService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private messageSvc: MessageService,
                private projectService: ProjectService,
                private sanitizer: DomSanitizer,
                private baseService: BaseService,
                private confirmDialogService: ConfirmDialogService,) { }

    ngOnInit() {
        this.flash.showboard = false;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
            this.catalog_id = params['catalog_id'];
        });

        
        this.progressState.states = this.zettaUtils.getStateList('classify-set');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'classifyTrain';
        this.progressState.isEdit = false;
        
        this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');        
        this.objects_ids = this.object_id;
        this.objects_ids_arr = this.objects_ids.split(',');
        this.concept_id = this.activatedRoute.snapshot.queryParamMap.get('concept_id');  

        this.syn_objects_ids = this.activatedRoute.snapshot.queryParamMap.get('syn_objects_ids');        
        this.syn_objects_ids_arr = this.syn_objects_ids.split(',');

        this.syn_catalog_id = this.activatedRoute.snapshot.queryParamMap.get('syn_catalog_id');  

        this.no_matches = this.activatedRoute.snapshot.queryParamMap.get('no_matches');
        if (this.no_matches !== null) {
            this.dataset_classify_ids = this.no_matches.split(',');
        }

        if (this.datasets !== null) {
            this.dataset_ids = this.datasets.split(',');
        }
        
        this.catalogs_ids = this.catalog_id;
        this.catalogs_ids_arr = this.catalogs_ids.split(',');

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: false,
            enableRowSelection: true,
            mapDataSets: true,
            noDataMsg: RIGHT_EMPTY_GRID_MSG,
        };

        this.modalGridOptions = {
            enableGridMenu: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true
          };

        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });        
        if (this.concept_id) {            
           this.bindTrainConceptGrid();
        } else {
            this.bindTrainObjectGrid();
        }
        this.catalogSvc.getCatalog(this.catalog_id).subscribe(resp =>{
            this.leftCatalog=resp.name;
          },error=>{});

        this.catalogSvc.getCatalog(this.syn_catalog_id).subscribe(resp =>{
            this.rightCatalog=resp.name;
        },error=>{});

        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe(resp =>{
            this.objectName=resp.logical_name;
        },error=>{});
         
    }

    bindLeftGridConcept(){
        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe(respObject=> {
            this.catalogSvc.getConcept(this.catalog_id,this.object_id, this.concept_id).subscribe(respConcept=>{                
                const firstRow = this.defaulMappedtEntityAttributes.find(row =>
                    row.entity_id_left == respObject.entity_id &&
                   row.attribute_id_left == this.concept_id);
                   let extingMapping;
                   if(firstRow){
                    extingMapping = firstRow.attribute_logical_name_right;
                   } 
                const tempObj = {
                    id:Math.random(),
                    object_name:respObject.semantic_object,
                    entity_id : respObject.entity_id,
                    concept_name:respConcept.logical_name,
                    concept_id:respConcept.attribute_id,
                    mapping: extingMapping,
                    groupedRow: false
                };
                this.leftGridDataSource=[];
                this.leftGridDataSource.push(tempObj);
                this.leftGridTotalMappedAttribute = this.leftGridDataSource.filter(data => data.mapping).length;
                this.leftGridColumnsDef = this.getLeftGridColumns(this.leftGridTotalMappedAttribute);
                this.groupRows();
                this.isLeftGridDataReady = true;
               },error=>{});
        }, error =>{

        });
        
    }
    bindLeftGridObject(){
        this.catalogSvc.getSemanticObject(this.catalog_id, this.object_id).subscribe(respObject=> {
            this.catalogSvc.getConcepts(this.catalog_id,this.object_id,null,true).subscribe(respConcept=>{
                const tempObj=[]
                if(respConcept.length){
                    respConcept.forEach(element => {
                        const firstRow = this.defaulMappedtEntityAttributes.find(row =>
                            row.entity_id_left == respObject.entity_id &&
                           row.attribute_id_left == element.attribute_id);
                           if(firstRow){
                               element.mapping = firstRow.attribute_logical_name_right;
                           } 
                        tempObj.push({
                            id:Math.random(),
                            object_name:respObject.semantic_object,
                            entity_id : respObject.entity_id,
                            concept_name:element.logical_name,
                            concept_id:element.attribute_id,
                            mapping: element.mapping,
                            groupedRow: false
                        });
                    });
                }
               
                this.leftGridDataSource=[];
                this.leftGridDataSource = tempObj;                
                this.leftGridTotalMappedAttribute = this.leftGridDataSource.filter(data => data.mapping).length;
                this.leftGridColumnsDef = this.getLeftGridColumns(this.leftGridTotalMappedAttribute);
                this.groupRows();
                this.isLeftGridDataReady = true;
               },error=>{});
        }, error =>{

        });
        
    }
    bindTrainConceptGrid(){       
        this.catalogSvc.getSynonymsMapping(this.catalog_id,`attribute_id_left=${this.concept_id}`).subscribe(resp => {     
            this.defaulMappedtEntityAttributes = resp;
            this.catalogSvc.getMapConcepts(this.syn_objects_ids,true).subscribe(entitiesResp => {
                Object.entries(entitiesResp).forEach(([key, value]) => {
                    this.entitiesResp = this.entitiesResp.concat(value);
                });
                if (this.concept_id) {
                    // will uncomment this code once maapling api is ready
                   // this.entitiesResp = this.entitiesResp.filter(entities => entities.attribute_id === +this.concept_id);
                }
                this.prepareEntityMapping(this.entitiesResp);               
                this.catalogs_ids_arr.forEach((catalogId, i) => {
                    this.catalogSvc.getSemanticsObjects(+catalogId).subscribe(respObjects => {
                        respObjects.forEach(e => {
                            e.catalog_id = +catalogId;
                        });
                        this.objectsList = this.objectsList.concat(respObjects);
                        if (i === 0) {
                            this.setDefaultRightGridDataSources();
                            this.initRightGridRows();
                        }
                    }, error => {                        
                        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
                     });
                });
                //this.setDefaultLeftGridDataSources();
                this.bindLeftGridConcept();
                this.initColDef();
            }, error => {
                 this.messageSvc.sendMessage({ message: error.error.message, type: 'INFO', hideboard: true });    
            });
        });

    }
    bindTrainObjectGrid() {
        this.catalogSvc.getSynonymsMapping(this.catalog_id,`entity_id_left=${this.object_id}`).subscribe(resp => {        
            this.defaulMappedtEntityAttributes = resp;
            this.catalogSvc.getMappingAttributes(this.syn_objects_ids,null,null,true).subscribe(entitiesResp => {
                Object.entries(entitiesResp).forEach(([key, value]) => {
                    this.entitiesResp = this.entitiesResp.concat(value);
                });
                if (this.concept_id !== null) {
                   // this.entitiesResp = this.entitiesResp.filter(entities => entities.attribute_id === +this.concept_id);
                }
                this.prepareEntityMapping(this.entitiesResp);
                this.setDefaultRightGridDataSources();
                this.initRightGridRows()
                this.bindLeftGridObject();
              //  this.setDefaultLeftGridDataSources();
                this.initColDef();
            }, error => {
                 this.messageSvc.sendMessage({ message: error.error.error, type: 'INFO', hideboard: true }); 
            });
        });
    }
   
    getSemanticObject(catalogId) {
        this.catalogSvc.getSemanticsObjects(catalogId).subscribe(resp => {
            resp.forEach(element => {
              element.catalog_id = catalogId;
            });
            this.objectsList = this.objectsList.concat(resp);
        }, err => { });
    }

    getChainedAttribute(atts): string[] {
        const arr: string[] = [];
        atts.map((arrayItem) => {
            if (arrayItem.is_visible_input) {
                arr.push(arrayItem);
                if (arrayItem.chained) {
                    arrayItem.chained.map(element => arr.push(element)
                    );
                }
            }
        });
        return arr;
    }
    setDefaultRightGridDataSources() {
        this.rightGridDataSource = [];
        this.defaultEntityAttributes.forEach(attribute => {
            this.entityAttribute.set(attribute.physical_name, attribute);
            this.rightGridDataSource.push({
                'id': Math.random(),
                'logical_name': attribute.logical_name,
                'dataset_name': '',
                'target_attribute': 'dropDiv',
                'system_attribute': attribute.physical_name,
                'attribute_id': attribute.attribute_id,
                'sort_order': attribute.sort_order,
                'indent': attribute.indent,
                'parent': attribute.parent,
                'sequenceIndex': attribute.sequenceIndex,
                '_collapsed': attribute._collapsed,
                'data_type': attribute.data_type,
                'chained': attribute.chained,
                'parentattributeid': attribute.parentattributeid,
                'catalog_id': this.catalogs_ids,
                'entity_id': attribute.entity_id,
                'name': attribute.name,
                'semantic_name': attribute.name,
            });
        });
    }    
    
    setDefaultLeftGridDataSources() { 
        this.leftGridDataSource = [];
        const dataset: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_ids: this.datasets,
            pageno: this.page,
            perpage: 30,
        } 
        this.datasetSvc.getDatasets(dataset).subscribe(respDatasets => {
            this.datasetSvc.getMultipleDatasetColumns(dataset).subscribe(resp => {
                resp.forEach(element => {
                    const dataset = respDatasets['currentpage'].filter(dataset => dataset.dataset_id === element.dataset_id)[0];
                    element.id = Math.random();
                    element.groupedRow = false;
                    element.datasource_name = dataset.datasource_name;
                    const firstRow = this.defaulMappedtEntityAttributes.find(row =>
                         row.dataset_id === element.dataset_id &&
                        row.dataset_column_id === element.dataset_column_id);
                        if(firstRow){
                            element.mapping = firstRow.concept;
                        }                    
                });
                this.defaultUnMappedEntityAttributes.push(...resp);
                //this.initLeftGridRows();
            }, err => {                
                this.isLeftGridDataReady =true;                  
                if(err.status == 404){
                 this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });              
                }              
             });
            
        }, err => {            
            this.isLeftGridDataReady =true;
            if(err.status == 404){
                this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });              
            }            
         });
    }

    selectOptionDataSetModelList() {
        this.isSampelDsLoading = true;
        setTimeout(() => {
            if (this.entityResp) {
                this.isSampelDsLoading = false;
                this.entityRespAfter = this.entityResp;
            }
        }, 1000);
    }
    
    initRightGridRows() {
        const parent = this;
        this.rightGridDataSource.forEach(attribute => {
            attribute.id = Math.random();
            this.syn_objects_ids_arr.forEach(entity => {
                const firstRow = parent.defaulMappedtEntityAttributes.filter(row => row.attribute_id_right === attribute.attribute_id && parseInt(row.entity_id_right) === parseInt(entity));
                if (firstRow.length) {
                    firstRow.forEach(element => {
                        element['logical_name'] = attribute.logical_name;
                        element['semantic_name'] = element.entity_name_left
                        element['entity_id'] = element.entity_id_right
                        element['sort_order'] = attribute.sort_order;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        element['target_attribute'] = element.attribute_logical_name_left;
                        this.mappedDataSetAttributes.push(element);
                    });
                }
            });
           
        });
        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id_right);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== AppGlobals.WHOLE_DATA_TYPE) {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });
        this.isRightGridDataReady = true;
        const newRightDatasorce=[];
        if(this.syn_objects_ids_arr.length){
            this.syn_objects_ids_arr.forEach(element => {
              const objectName = this.rightGridDataSource.filter(obj=>obj.entity_id == element);  
              if(objectName.length){
                objectName.forEach((item, index) => {
                      if(index==0){
                          item.object_name =item.name;
                      }
                      newRightDatasorce.push(item);

                 });
              }
            });
        }
        this.rightGridDataSource = newRightDatasorce;
        
    }
    
    removeMapping(row: any, isLeftgrid: boolean) {
        if (!isLeftgrid) {
            // Remove Mapped Row from right Grid
            row['dataset_name'] = '';
            // updated Mapped Record data
            this.mappedDataSetAttributes = this.mappedDataSetAttributes.filter(mappedAttr => !(mappedAttr.entity_id_left === row.entity_id_left
                && mappedAttr.target_attribute === row.target_attribute));

            const leftgridItems =this.leftGrid.dataView.getItems();
            const leftGridRow = this.leftGrid.dataView.getItems().filter(leftRow => (leftRow.concept_name === row.target_attribute
                && leftRow.mapping === row.logical_name));

            const leftGridDataSourceRow = this.leftGridDataSource.find(leftRow => (leftRow.concept_name === row.target_attribute
                && leftRow.mapping === row.logical_name));

            // Remove Mapped Row from Left Grid
            if (leftGridRow.length > 0) {
                if (leftGridRow[0].groupedRow) {
                    this.leftGrid.gridService.deleteDataGridItem(leftGridRow[0]);
                } else {
                    leftGridRow[0].mapping = undefined;
                    this.leftGrid.gridService.updateDataGridItem(leftGridRow[0], false);
                }
            }
            if (leftGridDataSourceRow) {
                if (leftGridDataSourceRow.groupedRow) {
                    this.leftGridDataSource = this.leftGridDataSource.filter(leftRow => !(leftRow.logical_name === row.target_attribute
                        && leftRow.mapping === row.logical_name));
                } else {
                    leftGridDataSourceRow.mapping = undefined;
                }
            }
            this.progressState = Object.assign({}, this.progressState);
            this.updateMappingLeftGridColumn(false);
            if (row['isNewlyCreated'] && row['isNewlyCreated'] === true) {
                this.rightGrid.dataView.beginUpdate();
                this.rightGrid.dataView.deleteItem(row['id']);
                this.rightGrid.dataView.endUpdate();
            } else {
                row['target_attribute'] = 'dropDiv';
                this.rightGrid.gridService.updateDataGridItem(row, false);
            }
        }
    }

    groupRows() {
        this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['object_name', 'concept_name'], ['asc']);
        if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.leftGridDataSource);
        }
    }
    groupRows2() {
        this.rightGridDataSource = _.orderBy(this.rightGridDataSource, ['logical_name', 'column'], ['asc']);
        if (this.rightGrid) {
            this.rightGrid.dataView.setItems(this.rightGridDataSource);
        }
    }
   

    getRightGridColumns() {
        return [
            {
                'displayname': 'Semantic Object',
                'physicalname': 'object_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'params': { 'rootObj': this },
                'cssClass': 'grouped-cell',
                'maxWidth':'200',
                'formatter': nameFormatter,
            },
            {
                'displayname': 'Concept',
                'physicalname': 'logical_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'cssClass': 'grouped-cell',
                'params': { 'rootObj': this },                
                'formatter': toggleFormatter,
            }, {
                'displayname': 'Mapping',
                'physicalname': 'target_attribute',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': dropFormatter
            }
        ];
    }

    getLeftGridColumns(totalMapping?: number) {
        return [
            {
                'displayname': 'Semantic Object',
                'physicalname': 'object_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth':120,
                'formatter': dragFormatter
            },
            {
                'displayname': 'Concept',
                'physicalname': 'concept_name',
                'sortable': false,
                'datatype': 'String',
                'filterable': true,
            }, 
            {
                'displayname': `Mapping(${totalMapping})`,
                'physicalname':'mapping',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': attributeMappingFormatter,
            }
        ];
    }

    initColDef() {       
        this.rightGridColumnsDef = this.getRightGridColumns();
    }

    rightGridCreated(grid) {
        this.buildParentAndChildRelation(this.rightGridDataSource);
        this.rightGrid = grid;
        this.rightGridDataView = this.rightGrid.dataView;
        this.rightGridDataView.beginUpdate();
        this.rightGridDataView.setFilter(this.myFilter);
        this.rightGridDataView.setFilterArgs(this.rightGridDataSource);
        this.rightGridDataView.endUpdate();

        const self = this;
        grid.slickGrid.onCellChange.subscribe(function (e, args) {
            self.rightGrid.dataView.updateItem(args.item.id, args.item);
        });

        grid.slickGrid.onClick.subscribe(function (e, args) {
            if ($(e.target).hasClass('expandCollapse')) {
                const rowitem = self.rightGrid.dataView.getItem(args.row);
                if (rowitem) {
                    if (!rowitem._collapsed) {
                        rowitem._collapsed = true;
                        self.hidingParentElement(rowitem);
                    } else {
                        rowitem._collapsed = false;
                        self.hidingParentElement(rowitem);
                    }
                    self.rightGrid.dataView.updateItem(rowitem.id, rowitem);
                }
                e.stopImmediatePropagation();
            }
        });

        // wire up model events to drive the grid
        self.rightGrid.dataView.onRowCountChanged.subscribe(function (e, args) {
            grid.slickGrid.updateRowCount();
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });

        grid.slickGrid.onScroll.subscribe(function (e, args) {
            self.showHideDragDrop();
            self.removeBorders();
        });

        self.rightGrid.dataView.onRowsChanged.subscribe(function (e, args) {
            grid.slickGrid.invalidateRows(args.rows);
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });

        this.dataviewObj = this.rightGrid.dataView;
        this.gridObj = this.rightGrid.slickGrid; 
    }

    groupBySemanticObject() {
        this.dataviewObj.setGrouping([
          {
            getter: 'name',
            formatter: (g) => {
              return `<span class="text-bold" style="color:#505050">${g.value}</span>`;
            },
            aggregators: [],
            collapsed: false,
            lazyTotalsCalculation: true
          }
        ] as Grouping[]);
      }

    hidingParentElement(row) {
        const self = this;
        self.mappedDataSetAttributes.forEach(element => {
            const rightTableDS = self.rightGridDataView.getItems();
            // get the index of element
            const index = rightTableDS.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = rightTableDS[index];
                if (parentObj.parentattributeid === -1 && row.attribute_id === parentObj.attribute_id) {
                    if (!row._collapsed) {
                        parentObj['dataset_name'] = element['dataset_name'];
                        parentObj['target_attribute'] = 'dropDiv';
                        setTimeout(() => {
                            self.rightGridDataView.deleteItem(element.id);
                        }, 10);
                    } else {
                        element['parent'] = parentObj.parent;
                        element['parentattributeid'] = parentObj.parentattributeid;
                        element['sequenceIndex'] = parentObj.sequenceIndex;
                        element['_collapsed'] = parentObj._collapsed;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        self.rightGridDataView.insertItem(index + 1, element);
                        parentObj['dataset_name'] = '';
                    }
                    self.rightGrid.dataView.updateItem(parentObj.id, parentObj);
                }
            }
        });
    }

    showHideDragDrop() {
        $('.fa-caret-right').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').show();
        });
        $('.fa-caret-down').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').hide();
        });
    }

    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    myFilter(item, args) {
        if (item.parent != null) {
            let parent = args[item.parent.id];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = args[parent.parent ? parent.parent.id : null];
            }
        }
        return true;
    }
   

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    onRunMapping() {
        this.catalogSvc.runModelClassify1(this.oneRow).subscribe(resp => {
            if(resp) {
                this.isMappingChanged = true;
            }
        }, err => {
            this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
        });
    }
   
    removeBorders() {
        $('.remove-border').each(function () {
            $(this).parent().parent().children('.slick-cell').each(function () {
                $(this).attr('id', 'remove-top-bottom');
            });
        });
    }

    onDrop(draggedRowIndex, droppedRowIndex) {
        this.mappedOneAttribute = [];
        const draggedRowData = this.leftGrid.gridService.getDataItemByRowIndex(draggedRowIndex);
        const droppedRowData = this.rightGrid.gridService.getDataItemByRowIndex(droppedRowIndex);
        const newRightRow = {
            'id': Math.random(), 
            'logical_name': droppedRowData.logical_name, 
            'concept': droppedRowData.logical_name,
            'dataset_name': draggedRowData.dataset_name,
            'dataset': draggedRowData.dataset_name,
            'datasource_name': draggedRowData.datasource_name,
            'datasource': draggedRowData.datasource_name,
            'target_attribute': draggedRowData.concept_name,
            'dataset_column': draggedRowData.column,
            'column_type': draggedRowData.data_type,
            'data_type': draggedRowData.data_type,
            'system_attribute': droppedRowData.system_attribute,
            'attribute_id': droppedRowData.attribute_id, 
            'dataset_id': draggedRowData.dataset_id, 
            'sort_order': droppedRowData.sort_order,
            'authoritativeness': draggedRowData.authoritativeness,
            'dataset_column_id': draggedRowData.dataset_column_id,
            'entity_id': droppedRowData.entity_id,
            'semantic_name': draggedRowData.object_name,
            'catalog_id': droppedRowData.catalog_id,
            'object_name':draggedRowData.object_name,
            'name':draggedRowData.name,
            'match': true
        };
        const newLeftRow = {
            'id': Math.random(), 
            'object_name': draggedRowData.object_name, 
            'concept_name': draggedRowData.concept_name,
            'mapping': droppedRowData.logical_name, 
            'entity_id': draggedRowData.entity_id,
            'concept_id' : draggedRowData.concept_id,
            'groupedRow': true
        };

        const existingMapping =this.getDuplicate(newRightRow);
        if (existingMapping) {
            this.messageSvc.sendMessage({ message: `Mapping already exists.`, type: 'INFO', hideboard: true });
            return;
        } else {
            this.newRightGridRow = newRightRow;
            this.newLeftGridRow = newLeftRow;
            this.mappedDataSetAttributes.push(newRightRow);
            this.mappedOneAttribute.push(newRightRow);
            this.onlyMappedAttributeRight.push(newRightRow);
            this.onlyMappedAttributeLeft.push(newLeftRow);
            const coloneAttribute = Object.assign({}, droppedRowData);
            coloneAttribute['isNewlyCreated'] = false;
            if (droppedRowData['semantic_name'] !== '') {
                const dropIndex = (parseInt(droppedRowIndex, 10) + 1);
                coloneAttribute.id = newRightRow.id;
                coloneAttribute['styleCss'] = 'remove-border';
                droppedRowData['styleCss'] = 'remove-border';
                coloneAttribute['isNewlyCreated'] = true;
                coloneAttribute['entity_id'] = newRightRow.entity_id;
                coloneAttribute['target_attribute'] = newRightRow.target_attribute;
                coloneAttribute['semantic_name'] = newRightRow.semantic_name;;
                coloneAttribute['object_name'] = '';
                this.rightGridDataView.insertItem(dropIndex, coloneAttribute);
                const rowData = this.rightGridDataView.getItems();
                this.rightGridDataView.setItems(rowData);
                this.removeBorders();
            } else {
                droppedRowData['entity_id'] = newRightRow.entity_id;
                droppedRowData['target_attribute'] = newRightRow.target_attribute;
                droppedRowData['object_name'] = ''
                droppedRowData['authoritativeness'] = newRightRow.authoritativeness;
                
            }
            this.rightGrid.gridService.updateDataGridItem(droppedRowData, false);
            this.onDropUpdateLeftGridRow(true, newLeftRow);
            this.updateMappingLeftGridColumn(true);

            this.MappledpayloadData = [];
            this.MappledpayloadData.push({
                "catalog_id_left": this.catalog_id,
                "entity_id_left": newLeftRow.entity_id,
                "attribute_id_left": newLeftRow.concept_id,
                "tenant_id_left": this.loggedInUserDetails['tenant_id'],
                "tenant_id_right": this.loggedInUserDetails['tenant_id'],
                "attribute_id_right": newRightRow.attribute_id,
                "catalog_id_right": this.syn_catalog_id,
                "entity_id_right": newRightRow.entity_id
            });
           this.saveMapping(this.MappledpayloadData);
        }
    }


    onDropUpdateLeftGridRow(addRow: boolean, row: any) {
        if (addRow) {
            const firstRow = this.leftGridDataSource.find(leftRow => row.concept_name === leftRow.concept_name && row.entity_id === leftRow.entity_id && leftRow.mapping === undefined && leftRow.groupedRow === false);
            if (firstRow) {
                firstRow.mapping = row.mapping;
                this.leftGrid.gridService.updateDataGridItem(firstRow, false);
            } else {
                this.leftGridDataSource.push(row);
                this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['object_name', 'object_name'], ['asc']);

                let currentLeftGridRow = this.leftGrid.dataView.getItems();
                currentLeftGridRow.push(row);
                currentLeftGridRow = _.orderBy(currentLeftGridRow, ['object_name', 'object_name'], ['asc']);
                this.leftGrid.dataView.setItems(currentLeftGridRow);
            }
            this.progressState = Object.assign({}, this.progressState);
        }
    }

    ngAfterViewChecked() {
        const self = this;
        $('.dropDiv').droppable({
            drop: function (event, ui) {
                const dragElement = $(ui.draggable).find('div.dragMe');
                if (dragElement === undefined || dragElement === null || dragElement.length === 0) {
                    return;
                }
                const droppedRowIndex = $(this).attr('value');
                const draggedRowIndex = dragElement.attr('value');
                self.onDrop(draggedRowIndex, droppedRowIndex);
                $(this).removeClass('drag-enter');
            },
            over: function (event, ui) {
                $(this).addClass('drag-enter');
            },
            out: function (event, ui) {
                $(this).removeClass('drag-enter');
            }
        });
    }

    onRightGridCellClicked(params) {
        const event = params.eventData;
        const args = params.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        if (event.target.className === 'fa fa-minus deleteBtn') {
            const payload=[];
            if(row.hasOwnProperty('attribute_id_left') && row.hasOwnProperty('attribute_id_right')){
                payload.push({
                    attribute_id_left: row.attribute_id_left,
                    attribute_id_right: row.attribute_id_right
                });
            }else{
                payload.push({
                    attribute_id_left: this.newLeftGridRow.concept_id,
                    attribute_id_right: this.newRightGridRow.attribute_id
                });
            }
            this.catalogSvc.deleteSynonymsMapping(this.catalog_id, payload).subscribe(resp => {
                this.removeMapping(row, false);
            }, error => { });
        }
    }

    getDuplicate(newRightGridRow: any) {
        return this.mappedDataSetAttributes.find(existingMapping => {
            return existingMapping.logical_name === newRightGridRow.logical_name &&
                existingMapping.target_attribute === newRightGridRow.target_attribute &&
                existingMapping.dataset_name === newRightGridRow.dataset_name;
        });
    }

    isMappingValid(): boolean {
        return (this.hasActiveJob || !this.mappedDataSetAttributes.length) ? false : true;
    }

    updateMappingLeftGridColumn(addRow: boolean) {
        let prevValue: number;
        if (addRow) {
            prevValue = this.mappedDataSetAttributes.length - 1;
        } else {
            prevValue = this.mappedDataSetAttributes.length + 1;
        }

        if (this.leftGridMappingHeader === undefined) {
            const allHeaders = $('.slick-header-column');
            for (let i = 0; i < allHeaders.length; i++) {
                const existingTooltip = $(allHeaders[i]).attr('title');
                if (existingTooltip === `Mapping(${prevValue})`) {
                    this.leftGridMappingHeader = allHeaders[i];
                }
            }
        }
        if (this.leftGridMappingHeader) {
            $(this.leftGridMappingHeader).attr('title', `Mapping(${this.mappedDataSetAttributes.length})`);
            const headerCell = this.leftGridMappingHeader.children[0];
            if (headerCell) {
                $(headerCell).html(`Mapping(${this.mappedDataSetAttributes.length})`);
            }
        }
    }

    getEntityName() {
        if (this.projectDetail.entity_name) {
            return this.projectDetail.entity_name;
        }
    }


    getLeftGridCount(): number {
        if (this.leftGrid) {
            const filterRows = this.leftGrid.dataView.getItems().filter(row => !row.groupedRow);
            return filterRows.length;
        } else {
            return this.defaultUnMappedEntityAttributes.length;
        }
    }

    isPropRootKey(rootObject, prop) {
        return rootObject.hasOwnProperty(prop);
    }
    
    prepareEntityMapping(dataSet) {
        let counter = -1;
        const chaninedItems = [];
        dataSet.filter(function (item) {
            if (item['data_type'] === AppGlobals.WHOLE_DATA_TYPE) { chaninedItems.push(...item['chained']); } return item['data_type'] === AppGlobals.WHOLE_DATA_TYPE;
        });
        for (let chainedIndex = 0; chainedIndex < chaninedItems.length; chainedIndex++) {
            const chainedElement = chaninedItems[chainedIndex];
            // loop parent obj
            for (let parentIndex = 0; parentIndex < dataSet.length; parentIndex++) {
                const parentElement = dataSet[parentIndex];
                if ((chainedElement.attribute_id === parentElement.attribute_id) && (!parentElement.hasOwnProperty('chained') || !chainedElement.hasOwnProperty('chained'))) {
                    dataSet.splice(parentIndex, 1);
                    break;
                }
            }
        }
        this.defaultEntityAttributes = [];
        dataSet.forEach((attribute) => {
            counter += 1;
            attribute['indent'] = 0;
            attribute['parent'] = null;
            attribute['sequenceIndex'] = counter;
            attribute['parentattributeid'] = -1;
            attribute['_collapsed'] = false;
            this.defaultEntityAttributes.push(attribute); // expand root element
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === AppGlobals.WHOLE_DATA_TYPE && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['indent'] = 1;
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                    chainElement['parentattributeid'] = attribute['attribute_id'];
                    chainElement['_collapsed'] = false;
                    this.defaultEntityAttributes.push(chainElement);
                }
            }
        });
    }

    buildParentAndChildRelation(dataSource) {
        let counter = -1;
        dataSource.forEach((attribute) => {
            counter += 1;
            attribute['sequenceIndex'] = counter;
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === 'Chain' && attribute.chained && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                }
            }
        });
        dataSource.forEach((attribute) => {
            const filteredData = dataSource.filter(data => data.parentattributeid === attribute.attribute_id);
            if (filteredData.length > 1) {
                const index = dataSource.findIndex(item => item.attribute_id === attribute.attribute_id);
                for (let chainIndex = 0; chainIndex < filteredData.length; chainIndex++) {
                    const chainElement = filteredData[chainIndex];
                    chainElement['parent'] = { id: index };
                }
            }
        });
    }

    onCancelStep(): void {
        if (this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        };        
    }

    

    onPreviousStep(): void {
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-select-semantic'], 
                      {queryParamsHandling:'preserve'}); 
    }

    onMessageCancel() {
        return false
    }

    onMessageYes() {
        return true
    }

    showDialog() {  
        const parent = this;
        parent.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
            parent.xxxxx = true;
        }, function () {  
            parent.xxxxx = false;
        })  
        
    } 

    canExit(): boolean {
        // this.showDialog();
        if (this.mappedDataSetAttributes.length > 0 && !this.canGoNextScreen) {
            if (confirm("Your data will be lost if you leave this screen. Are you sure?")) {
                return true
            } else {
                this.flash.type = 'WARN2';
                this.flash.message = "Your data will be lost if you leave this screen. Are you sure?";
                this.flash.showboard = false;
                return false
            }
        } else {
            return true
        }

    }

    saveMapping(payload){
        this.catalogSvc.saveSynonymsMapping(this.catalog_id, payload).subscribe(resp =>{
           },error=>{});
    }
    onSave() {
        if (this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
        }; 
    }

    // Modal Datasets
    selectOptionModelList(dataSetId, limit = 30) {
        this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        // this.isSampleDsLoading = true;
        this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'],
            this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
                this.modalRowData = data.currentpage;
                const rowKeys = [];
                if (data.currentpage.length) {
                    const myVar: any = data.currentpage[0];
                    for (var key in myVar) {
                        rowKeys.push(key)
                    }
                }
                this.modelColumnDef = [];
                rowKeys.forEach(column => {
                    this.modelColumnDef.push({
                        'displayname': column,
                        'physicalname': column,
                        'sortable': true,
                        'datatype': 'String',
                        'filterable': true,
                        'minWidth': 150,
                    });
                });

                this.modalRowData.forEach((element: any) => {
                    element.id = Math.random();
                });

                // this.total = data['totalrecords'];
                if (this.modalGrid) {
                    this.modalGrid.dataView.setItems(this.modalRowData);
                    this.modalGrid.gridService.setSelectedRows([]);
                }
                this.isSampleDsLoading = false;
            }, err => {
                this.isSampleDsLoading = false;
                this.modalRowData = [];
            });

    }
  
      onModelGridCreation(grid) {
        this.modalGrid = grid;
      }

      onDatasetCellClick(e): void {
        const parent = this;
        const eventEle = e.eventData;
        const args = e.args;
        const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);        
      }
    }     