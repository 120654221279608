import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { UserGroupService } from '../../../zettasense/usergroup/usergroup.service';
import { DatasetService } from '../datasets/dataset.service';
import { ContentService } from '../../../zettasense/content/content.service';
import { IDataset } from '../datasets/dataset.model';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from '../../shared/app.globals';
declare var $: any;

@Component({
  selector: 'zetta-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges{

  @ViewChild('fileInput') fileInput: any;

  fileName = 'Select a local file';
  isFileNameTemp = true;
  uploadSuccess = '';
  uploadError = '';
  apiUrl: string;
  itemAliasName: string;
  loggedInUserDetails: [];
  responses;
  dataset_id;
  newDataset: any;
  errorMessage = undefined;
  count=0;
  projType: String = '';
  project_type_id;
  catalogId: string;
  task_type_id: Number = 14868;
  fileType: string = '';
  @Input() type: string;
  @Input() datasourceids: number;
  @Input() datasetid: number;
  @Input() entityid: number;
  @Input() project_id: number;
  @Input() project_name: string;
  @Input() dataset_type_id: number;
  @Input() datasetList: any;
  @Input() clearModal: any;
  @Output() fileNameRes = new EventEmitter<string>();
  @Output() dataRes = new EventEmitter<any>();
  @Input() projectID: number;
  @Input() dqType?: string;
  @Input() catalogData?: object;
  @Input() idModal: string;
  @Input() fu_type: any;
  @Input() catalogID?: number;
  @Input() resolve_attribute = false;
  @Input() entity_description:boolean=false;
  @Input() supportNewFormats?:boolean=false;
  @Output() isPartialDataUploaded = new EventEmitter<boolean>();
  @Output() onFileUploadModelClose = new EventEmitter<any>();

  constructor(private userGroupService: UserGroupService,
              private datasetSvc: DatasetService,
              private messageSvc: MessageService,
              private activatedRoute: ActivatedRoute,
              private contentSvc: ContentService,
              private ZsClContentService: ZsClContentService) { }

  public uploader: FileUploader;
  public hasBaseDropZoneOver = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;    
    this.onBrowse();    
    this.onFileUpload();
  }

  ngOnInit() {
    this.datasetSvc.isFileUploaded.subscribe( value => {
       if(value){
        this.uploader.queue = [];
       }
    })
    this.uploader = undefined;
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.onFileUpload();
    var formData: FileUploaderOptions = {};
    if(this.type === 'datasource' || this.type === 'projectDataset') {
        formData = {
            url: this.apiUrl,
            itemAlias: this.itemAliasName,
            additionalParameter: this.datasetid ? { dataset_id: this.datasetid } : {},
            ...(sessionStorage.getItem('enable_jwt')) ? { authToken: 'Bearer ' + localStorage.getItem('access_token') } : {},
        };
        if(sessionStorage.getItem('loginProvider') == 'azure_sos') {
            formData.headers = [
                {
                    name:             'Authorization',
                    value:            'Bearer '+ sessionStorage.getItem('accessToken'),
                }
            ]
        } else {
            formData.headers = [{
                name: "authtype",
                value: sessionStorage.getItem('enable_jwt') && sessionStorage.getItem('enable_jwt') === 'true' ? 'jwt' : 'other'
            }];

        }
    } else {
        formData = {
            url: this.apiUrl,
            itemAlias: this.itemAliasName,
            ...(sessionStorage.getItem('enable_jwt')) ?
             { authToken: 'Bearer '+ localStorage.getItem('access_token') } : {},  
        };

        if(sessionStorage.getItem('loginProvider') == 'azure_sos') {
            formData.headers = [
                {
                    name:             'Authorization',
                    value:            'Bearer '+ sessionStorage.getItem('accessToken'),
                }
            ]
        } else {
            formData.headers = [{
                name: "authtype",
                value: sessionStorage.getItem('enable_jwt') && sessionStorage.getItem('enable_jwt') === 'true' ? 'jwt' : 'other'
            }];

        }
    }

      this.uploader = new FileUploader(formData);
    if(this.clearModal > 0) {
        this.fileName = 'Select a local file';
        this.uploader.queue = [];
    }

    this.newDataset = undefined;
    this.uploader.onAfterAddingFile = (file) => {
        file.withCredentials = true;
        this.fileName = file.file.name;
        if (this.type === 'datasource') {
            // formData.additionalParameter.dataset_id = this.datasetid;
            let fileType: any = this.fileName.split(".");            
            fileType = fileType[fileType.length-1];            
            if (fileType === 'csv') {
                const existDataset = this.datasetList.find(dataset => dataset.name === this.fileName || dataset.source_table_or_filename === this.fileName) ? true : false;
                if(!existDataset) {
                    this.uploadError = '';
                    this.newDataset = {};                
                } else {
                    this.messageSvc.sendMessage({ message: 'Data set exists with the same name! Please use a different name.', type: 'INFO', hideboard: true });
                    this.uploadError = '';
                    this.uploadSuccess = '';
                    this.uploader.queue = [];
                    this.fileName = 'Select a local file';
                }
            } else {
                this.messageSvc.sendMessage({ message: 'Invalid file type! .The supported file type is .csv', type: 'INFO', hideboard: true });
                this.uploadError = '';
                this.uploadSuccess = '';
                this.uploader.queue = [];
                this.fileName = 'Select a local file';
            }




        } else if (this.type === 'projectDataset') {
        // if (this.type === 'datasource' || this.type === 'projectDataset') {
            let fileType: any = this.fileName.split(".");
            fileType = fileType[fileType.length-1];
            if (fileType === 'csv') {
                const existDataset = this.datasetList.find(dataset => dataset.name === this.fileName || dataset.source_table_or_filename === this.fileName) ? true : false;
                if(!existDataset) {
                    this.uploadError = '';
                    // Create New Dataset
                    this.newDataset = {};
                    const postData: IDataset = {
                        user_id: this.loggedInUserDetails['user_id'],
                        tenant_id: this.loggedInUserDetails['tenant_id'],
                        payload: {
                            name: this.fileName,
                            description: 'Created on the fly for the project "' + this.project_name + '"',
                            datasource_id: this.datasourceids,
                            dataset_type_id: 4,
                            delimiter: ',',
                            source_table_or_filename: this.fileName,
                            header: true
                        }
                    };
                    this.datasetSvc.createDataset(postData).subscribe((result) => {
                        this.dataset_id = +result.dataset_id;
                        this.newDataset.dataset_id = this.dataset_id;
                        this.newDataset.name = result.name;
                        this.newDataset.description = result.description;
                        this.newDataset.datasource_id = result.datasource_id;
                        this.newDataset.dataset_type_id = result.dataset_type_id;
                        this.newDataset.delimiter = result.delimiter;
                        this.newDataset.source_table_or_filename = result.source_table_or_filename;
                        this.newDataset.header = result.header;
                        formData.additionalParameter.dataset_id = this.dataset_id;
                        // this.datasetList.push(this.newDataset);

                    }, (responseError) => {
                        this.errorMessage = responseError.error.message;
                        this.messageSvc.sendMessage({ message: this.errorMessage, type: 'INFO', hideboard: true });
                        this.uploader.queue = [];
                        this.uploadError = '';
                    });

                    this.responses = {
                        // fileName: this.fileName,
                        // is_upload_complete: true,
                        newDataset: this.newDataset
                    }
                    this.dataRes.emit(this.responses); 
                } else {
                    this.messageSvc.sendMessage({ message: 'Data set exists with the same name! Please use a different name.', type: 'INFO', hideboard: true });
                    this.uploadError = '';
                    this.uploadSuccess = '';
                    this.uploader.queue = [];
                    this.fileName = 'Select a local file';
                }
            } else {
                this.messageSvc.sendMessage({ message: 'Invalid file type! .The supported file type is .csv', type: 'INFO', hideboard: true });
                this.uploadError = '';
                this.uploadSuccess = '';
                this.uploader.queue = [];
                this.fileName = 'Select a local file';
            }
        } else if(this.type === 'entityAttributes') {
            let fileType: any = this.fileName.split(".");            
            fileType = fileType[fileType.length-1]; 
            this.fileType = fileType;       
            console.log("fileType:", fileType);   
            if (fileType === 'csv' ||  fileType === 'owl' || fileType === 'ttl' || fileType === 'tttl' || fileType === 'rdf') {
                return;
            } else {
                this.messageSvc.sendMessage({ message: 'Invalid file type! .The supported file type is .csv, .owl, .ttl and .rdf', type: 'INFO', hideboard: true });
                this.uploadError = '';
                this.uploadSuccess = '';
                this.uploader.queue = [];
                this.fileName = 'Select a local file';
            }
        }    
        this.isFileNameTemp = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
        if (status === 200) {          
          this.uploadError = '';
          if (this.type === 'entityAttributes' || this.type === 'classifyProjectTraining' || this.type === 'importConceptTraining' || this.type === 'synonymImport' || this.type == 'dqRules' || this.type == 'tasks' || this.type == 'users' ) {               
            if(this.type == 'dqRules' && response && JSON.parse(response).errorArray && JSON.parse(response).errorArray.length>0) {
                this.uploadSuccess = `Upload was successful with some errors. (${JSON.parse(response).errorArray.length} rules failed to upload)`;
              } else {
                  this.uploadSuccess = 'File Has been successfully uploaded.';
              }
          } else { 
              this.uploadSuccess = JSON.parse(response).mesage;   
          }
          if (this.type === AppGlobals.IMPORT_DATASOURCE || this.type === AppGlobals.IMPORT_ENTITY_ATTRIBUTES || this.type === AppGlobals.IMPORT_PROJECT_DATASET || this.type === AppGlobals.IMPORT_CLASSIFY_PROJECT_TRAINING || this.type === AppGlobals.IMPORT_CONCEPT_TRAINING || this.type === AppGlobals.IMPORT_SYNONYM || this.type == AppGlobals.IMPORT_DQ_RULES || this.type == AppGlobals.IMPORT_TASKS || this.type == AppGlobals.IMPORT_ADMIN_USERS) {         
              this.count++;
              let selected_dataset=[];
              this.uploader.queue.forEach(element => {
                selected_dataset.push(element.file.name);
              });
              
              this.responses = {
                  fileName: this.fileName,
                  is_upload_complete: true,
                  newDataset: this.newDataset,
                  selectedDataset:selected_dataset,
                  count:this.count
              }
              if(this.supportNewFormats && (this.fileType == 'rdf' || this.fileType == 'ttl' || this.fileType == 'owl')) {
                this.responses['showProcessingModel'] = true;
                this.responses['fileType'] = this.fileType;
                this.dataRes.emit(this.responses); 
              } else {
              this.dataRes.emit(this.responses); 
              } 
          }
          this.uploadError = '';      
        } 
        if (this.type == AppGlobals.IMPORT_DATASOURCE && status != 200) {
            let entityTooLargeMsg = AppGlobals.IMPORT_DATASETS_FILE_SIZE_LARGE_ERROR;
            let errMsg = response && JSON.parse(response).error ? JSON.parse(response).error : AppGlobals.DATASETS_IMPORTING_ERROR;
            this.uploadSuccess = null;
            this.uploadError = status === 413 ? entityTooLargeMsg : errMsg
        }
        if (this.type === 'classifyProjectTraining') {
            if (status === 500) {
                this.uploadSuccess = '';
                this.uploadError = JSON.parse(response).error.message ? JSON.parse(response).error.message : JSON.parse(response).message;
            } else {
                this.uploadError = JSON.parse(response).message;
            }
        } else if(this.type == 'dqRules' || this.type == 'tasks' || this.type === 'synonymImport' || this.type === 'importConceptTraining') {
            if (status === 500 || status === 400) {
                this.uploadSuccess = '';
                if(response && response.length>0 && !JSON.parse(response).message) {
                 this.uploadError = 'Please add the valid data to the template.';
                } else {
                 this.uploadError = JSON.parse(response).error ? JSON.parse(response).error : (JSON.parse(response).message ? JSON.parse(response).message : JSON.parse(response).error.message);
                }
            } else {
                this.uploadError = JSON.parse(response).message;
            }
        }

        if (this.type === 'importConceptTraining') {
            if (status === 500) {
                this.uploadSuccess = '';                
                this.uploadError = JSON.parse(response).error.error ? JSON.parse(response).error.error : JSON.parse(response).error;
            }
        }
        if (this.type === "synonymImport") {
            if (status === 500) {
                this.uploadSuccess = '';                
                this.uploadError = JSON.parse(response).error.error ? JSON.parse(response).error.error : JSON.parse(response).error;
            }
        } else if (this.type === AppGlobals.IMPORT_ENTITY_ATTRIBUTES) {            
            if (status == 500 || status == 400) {                
                this.uploadSuccess = '';
                let isEAPartiallyUploaded = response && JSON.parse(response).is_partial_data_uploaded ? JSON.parse(response).is_partial_data_uploaded: false;
                this.isPartialDataUploaded.emit(isEAPartiallyUploaded); 
                this.uploadError = JSON.parse(response).error.error ? JSON.parse(response).error.error : (JSON.parse(response).error.message ? JSON.parse(response).error.message : JSON.parse(response).error);
            }
        } else if(this.type == 'users') {
            if (status === 500) {
                this.uploadSuccess = '';              
                this.uploadError = AppGlobals.IMPORT_DUPLICATE_USER_ERROR_MSG;
            } else if (status === 400) {
                this.uploadSuccess = '';              
                this.uploadError = AppGlobals.IMPORT_USER_MANDATORY_FIELD_ERROR_MSG;
            } else {
                this.uploadError = JSON.parse(response).message;
            }
        }
        else if (this.type === AppGlobals.RESOLVE_ENTITY_DATASET_MAPPING) {
            if (status === 500 ||status === 400) {
                this.uploadSuccess = '';     
                this.uploadError = response && JSON.parse(response).error ? JSON.parse(response).error : 'Something went wrong';
            } else if (status === 200) {
                let apiresponse = JSON.parse(response);
                if (apiresponse.message) {
                    this.uploadError = apiresponse.message;
                    this.uploadSuccess = '';
                    this.dataRes.emit({ isUploadComplete: true,isPartialUpload: true ,data: apiresponse.data, filteredImportedData: apiresponse.filteredImportedData });
                } else {
                    this.uploadSuccess = 'File Has been successfully uploaded.';
                    this.uploadError = '';
                    this.dataRes.emit({ isUploadComplete: true,isPartialUpload: false ,data: apiresponse.data, filteredImportedData: apiresponse.filteredImportedData});
                } 
            } 
        }
    };
    this.uploader.onCompleteAll = () =>{
        if ((this.type == AppGlobals.IMPORT_DATASOURCE && (!this.uploadError)) || this.type === "entityAttributes" || this.type === "synonymImport") {
            this.dataRes.emit("Files Uploaded"); 
        }
    }
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['project_type']) {
          this.projType = params['project_type'];
          this.project_type_id = params['project_type_id'];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dqType) {
        this.dqType = changes.dqType.currentValue;
    } 
    if(changes.clearModal && (this.type == 'dqRules' || this.type == 'tasks' || this.type == 'users')) {
        if (this.uploader) {
            this.uploader.queue = [];
            this.fileName = 'Select a local file';
        }        
    }
  }
 
  onFileUpload() {
    if (this.type === 'users'){
        this.apiUrl = this.userGroupService.uploadFile();
        if (this.uploader) {
            this.uploader.queue.map(res => res.url = this.apiUrl);
        }
        this.itemAliasName = 'file';
    }
     else if (this.type === 'datasource' || this.type === 'projectDataset') {
        this.apiUrl = this.datasetSvc.uploadDataSourceFile({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasource_id: this.datasourceids });
        if (this.uploader) {
            this.uploader.queue.map(res => res.url = this.apiUrl);
        }
        this.itemAliasName = 'blob';
    } else if (this.type === 'entityAttributes') {     
        let isResolve = sessionStorage.getItem('selectedApp') === 'zs' ? true: false;
        this.apiUrl = this.contentSvc.uploadEntityFile(this.catalogID, isResolve, this.entityid);   
       // this.apiUrl = this.contentSvc.uploadEntityFile(this.catalogID);
        if (this.uploader) {
            this.uploader.queue.map(res => res.url = this.apiUrl);
        }
        this.itemAliasName = isResolve ? 'entity_attribute' : 'catalog';
    } else if (this.type === 'classifyProjectTraining') {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            project_id: this.projectID
        }
            this.apiUrl = this.contentSvc.uploadTrainingTasks(data);
            if (this.uploader) {
                this.uploader.queue.map(res => res.url = this.apiUrl);
            }
            this.itemAliasName = 'import_tasks';
        }  else if (this.type === 'importConceptTraining') {
            const data = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
            };
            if(this.catalogData && this.catalogData['catalogLevel'] == 'catalog') {
                const query = `?mappingfor=catalog&catalog_id=${this.catalogData['catalogId']}`;
                this.apiUrl = this.contentSvc.uploadClassifyMappingConcept(data,query);
            } else if (this.catalogData && this.catalogData['catalogLevel'] == 'semantic') {
                const query = `?mappingfor=semantic&catalog_id=${this.catalogData['catalogId']}&object_id=${this.catalogData['objectId']}`;
                this.apiUrl = this.contentSvc.uploadClassifyMappingConcept(data,query);
            } else if(this.catalogData && this.catalogData['catalogLevel'] == 'concept') {
                const query = `?mappingfor=concept&catalog_id=${this.catalogData['catalogId']}&object_id=${this.catalogData['objectId']}&concept_id=${this.catalogData['conceptId']}`;
                this.apiUrl = this.contentSvc.uploadClassifyMappingConcept(data,query);
            } else {
                this.apiUrl = this.contentSvc.uploadClassifyMappingConcept(data, '');
            }
            if (this.uploader) {
                this.uploader.queue.map(res => res.url = this.apiUrl);
            }
            this.itemAliasName = 'mappings';
        } else if (this.type === 'synonymImport') {
            const data = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
            };
            this.apiUrl = this.contentSvc.uploadSynonym(data);
            this.itemAliasName = 'synonyms';
            if (this.uploader) {
                this.uploader.queue.map(res => res.url = this.apiUrl);
            }
        } else if (this.type == 'dqRules') {
            if(this.dqType == 'technical' || this.dqType == 'dataset') {
                const data = {
                    user_id: this.loggedInUserDetails['user_id'],
                    tenant_id: this.loggedInUserDetails['tenant_id'],
                    ruleType: 'technical'
                };
                this.apiUrl = this.ZsClContentService.exportTechDqRule(data);
                if (this.uploader) {
                    this.uploader.queue.map(res => res.url = this.apiUrl);
                }
            } else {
                const data = {
                    user_id: this.loggedInUserDetails['user_id'],
                    tenant_id: this.loggedInUserDetails['tenant_id'],
                    ruleType: 'business'
                };
                this.apiUrl = this.ZsClContentService.exportTechDqRule(data);
                if (this.uploader) {
                    this.uploader.queue.map(res => res.url = this.apiUrl);
                }
            }
        } else if(this.type == 'tasks') {
            const data = {
                user_id: this.loggedInUserDetails['user_id'],
                task_type_id: this.task_type_id
            };
            this.apiUrl = this.contentSvc.uploadCatalogTasks(data);
            this.itemAliasName = 'catalog_task';
            if (this.uploader) {
                this.uploader.queue.map(res => res.url = this.apiUrl);
            }
        } 
        else if (this.type === 'entityResolveModalMapping') {
            const data = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                project_id: this.project_id,
                entity_id: this.entityid
            };
            this.apiUrl = this.contentSvc.uploadDatasetEntityMapping(data);
            this.itemAliasName = 'dataset_entity_mapping';
            if (this.uploader) {
                this.uploader.queue.map(res => res.url = this.apiUrl);
            }
        }
  }

  onBrowse() {
    if(this.type === 'projectDataset') {
        this.datasetid = undefined;
        if(this.clearModal > 0) {
            this.dataset_id = undefined;
        }
    }
    this.clearModal = 0;
    this.fileName = 'Select a local file';
    this.uploadError = '';
    this.uploadSuccess = '';
    this.newDataset = undefined;
    if(this.type === 'projectDataset') {
        if(this.dataset_id !== undefined) {
            const getDataset: IDataset = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                dataset_id: this.dataset_id + "",
            };
            this.datasetSvc.deleteDataset(getDataset).subscribe((data) => { 
                this.dataset_id = undefined;
            }, err => { });
        }
    }
  }

  uploadFile() {
    this.fileInput.nativeElement.value = '';
  }

    onExport() {
        if(this.type == 'dqRules') {
            let fileName;
            if(this.dqType == 'technical' || this.dqType == 'dataset') {
                fileName = `Technical_Rules_Template.csv`;
            } else {
                fileName = `Business_Rules_Template.csv`;
            }
            let link = document.createElement('a');
            link.href = `../../../../assets/templates/${fileName}`;
            link.download = `${fileName}`;
            link.click();
            link.remove();
        } else if(this.type == 'users') {
            let fileName = `Import_Tenant_Users.csv`;
            let link = document.createElement('a');
            link.href = `../../../../assets/templates/${fileName}`;
            link.download = `${fileName}`;
            link.click();
            link.remove();
        } else if(this.type == 'tasks') {
            let fileName = '';
            if(this.task_type_id === 14868) {
                fileName = 'Semantic_object_class_training';
            } else if(this.task_type_id === 14867) {
                fileName = 'Concept_class_training';
            }
                this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/task/export?task_type_id=${this.task_type_id}`).subscribe(resp => {
                this.ZsClContentService.exportCsvFiles(resp, fileName);
                }, error => {
                    this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
                });
        } else if(this.type == 'classifyProjectTraining') {
            this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectID}/tasks/export`).subscribe(resp => {
                this.ZsClContentService.exportCsvFiles(resp, `classify_training_tasks_${this.projectID}`);
            }, error => {
                this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
            }); 
        } else if (this.type === 'importConceptTraining') {
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', '../../../../assets/templates/Concept_Mapping_Template.csv');
            link.setAttribute('download', `Concept_Mapping_Template.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else if (this.type === 'synonymImport') {
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', '../../../../assets/templates/synonym_Template.csv');
            link.setAttribute('download', `synonym_Template.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else if (this.type === 'entityResolveModalMapping'){
            const link  = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', '../../../../assets/templates/Import_Resolve_Mappings.csv');
            link.setAttribute('download', 'Import_Resolve_Mappings.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }else {
            // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/template/export`,'_self');
            this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/template/export?resolve=${this.resolve_attribute}`).subscribe(resp => {
                const fileName = this.resolve_attribute ? 'attribute_template' : 'catalog_template';
                this.ZsClContentService.exportCsvFiles(resp, fileName);
            }, error => {
                this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
            });
        }
    }

    selectTaskType(val) {
        if(val == 'Concept Class Training') {
            this.task_type_id = 14867;
        } else if(val == 'Semantic Object Class Training') {
            this.task_type_id = 14868;
        }
    }

    onClose() {
        this.clearModal =  Math.random();
        $('#' + this.idModal).modal('hide'); 
        this.onFileUploadModelClose.emit(true);
    }
}
