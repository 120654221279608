import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { BaseService } from 'src/app/common/services/base-service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { MessageService } from 'src/app/common/components/message/message.service';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { environment } from 'src/environments/environment';
import { dqRuleNameFormattter } from 'src/app/common/shared/formatters/dqRuleNameFormatter';
declare var $: any;
import { ObservableService } from "../../../../../common/services/observable.service";

const columnFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(columnDef.name === 'Rule Name') {
    return `<div class="text-truncate"><div class="blueLink text-truncate" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title="${value}">${value}</div></div>`
} 
else
{
    return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;}
};


@Component({
  selector: 'zetta-dq-rules',
  templateUrl: './zs-cl-dq-rules.component.html',
  styleUrls: ['./zs-cl-dq-rules.component.scss'],
})
export class ZsClDqRulesComponent implements OnInit {
  eventData: any;
  tableSettings: object = { 'height': "100%", 'width': "99%" };
  hasTechnicalScrolled = false;
  noData = false;
  noTechnicalData = false;
  loggedInUserDetails: [];
  selectedFiles: string[] = [];
  isLoading = true;
  ruleLevelType:String = '';
  showBreadcrumb = false;
  rulesList:any = [];
  totalRules = 0;
  grandTotal = 0;
  subTotal = '';
  catalog_id:string;
  object_id:string;
  concept_id:string;
  technicalView: any = [];
  lastRowSelected = 0;
  rulesTableColDef: any;
  gridRuleOptions: any;
  rulesLeftGrid: AngularGridInstance;
  page = 1;
  limit = 100;
  selectedObjectMetadata: any;
  total = 0;
  hasScrolled=false;
  @Input() semanticObjectLevelDQ;
  @Input() catalogLevelDQ;
  @Output() checkRuleAccess = new EventEmitter<object>();
  dataQualityRule: any = [];
  sequencesDQ: [];

  constructor(private catalogSvc: ZsClContentService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zettaUtils: ZettaUtils,
              private baseService: BaseService,
              private messageSvc: MessageService,
              public _observableService: ObservableService
              ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
        this.catalog_id = params.get('catalog_id')
        this.object_id = params.get('object_id')
        this.concept_id = params.get('concept_id')
      });   
      sessionStorage.removeItem('serverfilter');
      sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));    
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    this.ruleLevelType = this.activatedRoute.snapshot.queryParamMap.get('dqRuleLevel');
    if (showBreadcrumb && showBreadcrumb === 'true') {
        this.showBreadcrumb = true;
    } 

    if (!this.showBreadcrumb) {
        // Remove BreadCrumb
        $('#breadcrumb').addClass('d-none');
    }

    this.gridRuleOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      rowHeight: 30           
    };

    this.rulesTableColDef = [
        {
        'displayname': 'Rule ID',
        'physicalname': 'rule_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 40,
        'maxWidth': 80,
      },
      {
        'displayname': 'Rule Name',
        'physicalname': 'rule_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 150,
       // 'maxWidth': 300,
        'cssClass':'blueLink',
        'formatter': dqRuleNameFormattter,
        'headerCssClass': 'text-left titleBreak pr-1 m-top-5',
      },
      {
        'displayname': 'DQ Dimension',
        'physicalname': 'rule_dimension',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 45,
        'headerCssClass': 'text-left titleBreak pr-1 m-top-5',
      },
      {
        'displayname': '# of Exceptions',
        'physicalname': 'exceptions',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 95,
        'headerCssClass': 'text-left titleBreak pr-1 m-top-5',
      },
      {
        'displayname': 'Rule Score',
        'physicalname': 'quality_score',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 90,     
        'formatter': measuredDQFormatter,
        'headerCssClass': 'text-left titleBreak pr-3 m-top-5',
      },
    ];
    
    if (this.catalogLevelDQ) {
      this.getRulesByCatalog(this.page,this.limit)
    } else if (this.semanticObjectLevelDQ) {
      this.getRulesBySemantic(this.page,this.limit); 
    } else {
    this.getRulesByConcept(this.page,this.limit);
    }
    
  }
    
  getRulesByConcept(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/dataquality/rules?pagination=true&pageno=1&pagesize=${limit}`;
    this.catalogSvc.getRulesByConcept(this.catalog_id,this.object_id,this.concept_id, page,limit).subscribe(respList=>{
       if(respList["currentpage"]){
           this.rulesList = respList["currentpage"]
           this.rulesList.forEach(element => {
            element.id = Math.random();
            element.measured_dq_score =element.quality_score;            
            element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
            element.disableMr = false;
          });
          this.totalRules= respList.totalrecords;
          if (page === 1) {
            this.grandTotal = respList.totalrecords;
          }
          this.total = respList['totalrecords'];
          let concatedRowsT = this.rulesList ;
          this.isLoading = false;
          if (this.rulesLeftGrid) {
            concatedRowsT = [...this.rulesLeftGrid.dataView.getItems(), ...this.rulesList];
            this.rulesList = concatedRowsT;
            this.rulesLeftGrid.dataView.setItems(this.rulesList);
            this.rulesLeftGrid.gridService.setSelectedRows([]);
            this.hasScrolled = false;
          }
       }
   },error=>{
    this.isLoading = false;
    this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
   })
  }

  getRulesBySemantic(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/dataquality/rules?pagination=true&pageno=1&pagesize=${limit}`;
    this.catalogSvc.getDQRulesSemanticLevel(this.catalog_id, this.object_id, page,limit).subscribe(respList => {
      if(respList["currentpage"]) {
        this.rulesList = respList["currentpage"]
        this.rulesList.forEach(element => {
          element.id = Math.random();
          element.measured_dq_score =element.quality_score;            
          element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
          element.disableMr = false;
        });
      this.totalRules= respList.totalrecords;
      this.total = respList['totalrecords'];
      if (page === 1) {
        this.grandTotal = respList.totalrecords;
      }
      let concatedRowsT = this.rulesList ;
      this.isLoading = false;
      if (this.rulesLeftGrid) {
        concatedRowsT = [...this.rulesLeftGrid.dataView.getItems(), ...this.rulesList];
        this.rulesList = concatedRowsT;
        this.rulesLeftGrid.dataView.setItems(this.rulesList);
        this.rulesLeftGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }
    })
  }

  getRulesByCatalog(page,limit){
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/dataquality/rules?pagination=true&pageno=1&pagesize=${limit}`;
    this.catalogSvc.getRulesByCatalog(this.catalog_id, page,limit).subscribe(respList=>{
       if(respList["currentpage"]){
           this.rulesList = respList["currentpage"]
           this.rulesList.forEach(element => {
            element.id = Math.random();
            element.measured_dq_score =element.quality_score;            
            element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
            element.disableMr = false;
          });
          this.totalRules= respList.totalrecords;
          this.total = respList['totalrecords'];
          if (page === 1) {
            this.grandTotal = respList.totalrecords;
          }
          let concatedRowsT = this.rulesList ;
          this.isLoading = false;
          if (this.rulesLeftGrid) {
            concatedRowsT = [...this.rulesLeftGrid.dataView.getItems(), ...this.rulesList];
            this.rulesList = concatedRowsT;
            this.rulesLeftGrid.dataView.setItems(this.rulesList);
            this.rulesLeftGrid.gridService.setSelectedRows([]);
            this.hasScrolled = false;
          }
       }
   },error=>{
    this.isLoading = false;
    this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
   })
  }

  conceptLeftGrid(grid: AngularGridInstance) {
    this.rulesLeftGrid = grid;
  }

  onScrollTechview(parentClass) {
     if (this.zettaUtils.virtualScroll(parentClass) && !this.hasTechnicalScrolled && !this.noTechnicalData) {
    this.hasTechnicalScrolled = true;
    this.onNextPageView();
    }
  }

  onNextPageView(): void {
    this.page++;
    if (this.semanticObjectLevelDQ) {
      this.getRulesBySemantic(this.page,this.limit); 
      } else {
      this.getRulesByConcept(this.page,this.limit);
      }
  }
  goToPage(n: number): void {
    this.page = n;
    if (this.catalogLevelDQ) {
      this.getRulesByCatalog(this.page, this.limit);
      }
      else if(this.semanticObjectLevelDQ){
      this.getRulesBySemantic(this.page,this.limit); 
    } else {
    this.getRulesByConcept(this.page,this.limit);
    }
  }

  onNext(): void {
    this.page++;
    if (this.catalogLevelDQ) {
    this.getRulesByCatalog(this.page, this.limit);
    }
    else if(this.semanticObjectLevelDQ){
    this.getRulesBySemantic(this.page,this.limit); 
  } else {
  this.getRulesByConcept(this.page,this.limit);
  }
  }

  onPrev(): void {
    this.page--;
    if (this.catalogLevelDQ) {
      this.getRulesByCatalog(this.page, this.limit);
      }
      else if(this.semanticObjectLevelDQ){
      this.getRulesBySemantic(this.page,this.limit); 
    } else {
    this.getRulesByConcept(this.page,this.limit);
    }
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }
  onScroll(parentClass) {

    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  ngOnDestroy() {
    $('#breadcrumb').removeClass('d-none');
    this.messageSvc.clearMessage();
  }
  handleRuleGridClickEvent(event): void {
    const eventEle = event.eventData;
    const args = event.args;
    const row = this.rulesLeftGrid.gridService.getDataItemByRowIndex(args.row);
    console.log(row)
    if (row !== undefined && row !== null) {
        if (eventEle.target.title === row.rule_name) {
          this.checkRuleAccess.emit({isRuleAdmin: row.is_dq_rule_admin,  isRuleExecuter:row.is_dq_rule_executor});
          this.getDataQualityRule(row.rule_id, row.rule_name);
          const existingClass = $(".left-grid-row-selected");
        if(existingClass) {
          existingClass.removeClass("left-grid-row-selected");
        }
        eventEle.target.parentElement.parentElement.parentElement.classList.add("left-grid-row-selected");
            this.catalogSvc.showGraphGrid.next({showGraph:true, name:row.rule_name,rule_id:row.rule_id})
            // this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + 
            // '/dq-rule'],
            //   { queryParams: { dqRuleLevel: 'catalog', catalog_id: this.catalog_id, showBreadcrumb: true, rule_id: row.rule_id }, queryParamsHandling: 'merge' },
            // );
        }
    }
  }

  getDataQualityRule(rule_id, rule_name) {
    this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], rule_id).subscribe(response => {
      this.dataQualityRule = response;
      this.dataQualityRule.exception_rules.forEach((ele, i) => {
        this.catalogSvc.getDataQualitySequenceRuleTypes('Business').subscribe(respSeq => {
          this.sequencesDQ = respSeq;
          const sequencesDQNames = respSeq.filter(row => row.lookup_id === ele.rule_sequence_type_lookup_id);          
          if (sequencesDQNames[0].lookup_id === 14878) {
            this._observableService.sendRuleSequenceType = true;
          } else {
            this._observableService.sendRuleSequenceType = false;
          }
          this.catalogSvc.showExceptionsGrid.next({ showException: true, name: rule_name, rule_id: rule_id });
        }, err => { });
      });
    }, err => { });
  }

  onManageRule(){
    sessionStorage.setItem('viewMode', 'business');
    if (this.catalogLevelDQ) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/dq-rule/manage-rules'],
      {queryParamsHandling:"preserve"}); 
    } else if (this.semanticObjectLevelDQ) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/dq-rule/manage-rules'],
      {queryParamsHandling:"preserve"});   
    } else {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/'+ this.concept_id +'/dq-rules/manage-rules'],
      {queryParamsHandling:"preserve"});  
    }
  }

  onExportReport() {
    if (this.catalogLevelDQ) {
      // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/dataquality/rules?export=true`, '_self');
      this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/dataquality/rules?export=true`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, 'DQRule_forCatalog');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });

    } else if (this.semanticObjectLevelDQ) {
      // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/dataquality/rules?export=true`, '_self');
      this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/dataquality/rules?export=true`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, 'DQRule_forSemanticObject');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    } else {
      // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/dataquality/rules?export=true`, '_self');
      this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/dataquality/rules?export=true`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, 'DQRule_forConcepts');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    }
  }
  
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);      
  }

  onUploadDataSource() {
    if(this.ruleLevelType == 'concept') {
      window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/dataquality/rules?export=true`, '_self');
    }
  }
}
