import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  template: `
  <!-- <div> -->
            <ng-container *ngIf="shouldDisplayCheckbox"> 
             <div class="mr-left-30 mr-top-7 check-cell">
             <!-- <input [id]="'attribute-' + columnDef.field + '-' + params.row" class="entity-checkbox" type="checkbox" [disabled]="hideElement === 'disabled'"> -->
            
                <input type="checkbox" [id]="'attribute-check-' + columnDef.field + '-' + params.rowIndex" class="chk-output d-none" checked [value]='params.value'> 
                <input type="checkbox" [id]="'attribute-uncheck-' +columnDef.field + '-' + params.rowIndex" class="chk-output" [value]='params.value'>  
               </div>
            </ng-container>
            <ng-container *ngIf="shouldDisplayInput">
            <!-- <input [id]="'attribute-' + columnDef.field + '-' + params.row" class="ctmInputBox textInputBox" type="text" [placeholder]="columnDef.params?.placeholder" [tabindex]="columnDef.params?.tabindex" autofocus value="{{params.value}}" [disabled]="hideElement === 'disabled'"> -->
            <input [id]="'attribute-' + columnDef.field + '-' + params.rowIndex" class="ctmInputBox textInputBox" type="text" [placeholder]="params.placeholder" [tabindex]="params.tabindex" autofocus value="{{params.value}}">
            </ng-container>
            <ng-container> 
            <div *ngIf="shouldDisplayYesNo" class="mr-left-30 mr-top-7 check-cell">
                <input type="checkbox" [id]="'attribute-check-' + columnDef.field + '-' + params.rowIndex" class="chk-output d-none" checked [value]="params.value"> 
                <input type="checkbox" [id]="'attribute-uncheck-' + columnDef.field + '-'+ params.rowIndex" class="chk-output" [value]="params.value">  
            </div> 
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayCopyIcon">
              <div [id]="'view-ref-'+ params.rowIndex" class="w-100 text-center pt-1">
                 <i class="fa-light fa-copy" actionInlineBtn></i>
                 </div> 
              </div>
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayPlusIcon">
              <div [id]="'add-ref-' + params.rowIndex" class="w-100 text-center pt-1">
                <i class="fa fa-plus actionInlineBtn"></i>
                </div><div [id]="'view-ref-'+params.rowIndex" class="w-100 text-center pt-1 d-none">
                <i class="fa-light fa-copy" actionInlineBtn></i>
                </div>
              </div>
            </ng-container>

            <ng-container>
              <div *ngIf="shouldDisplayRedNotActiveArrow">
              <div class="w-100 text-center text-bold">
                    <i class="fa fa-arrow-up notActiveGreenFont" aria-hidden="true"></i>&nbsp;
                    <i class="fa fa-arrow-down notActiveRedFont" aria-hidden="true"></i>
                </div>
              </div>
            </ng-container>

            <ng-container> 
              <div *ngIf="shouldDisplayRedActive" class="w-100 text-center text-bold">
                <i class="fa fa-arrow-up notActiveGreenFont" aria-hidden="true"></i>&nbsp;
                <i class="fa fa-arrow-down redFont" aria-hidden="true"></i> 
              </div>
            </ng-container>

            <ng-container> 
              <div *ngIf="shouldDisplayGreenRedNotActive" class="w-100 text-center text-bold">
                <i class="fa fa-arrow-up greenFont" aria-hidden="true"></i>&nbsp;
                <i class="fa fa-arrow-down notActiveRedFont" aria-hidden="true"></i>
              </div>
            </ng-container>
            <ng-container> 
              <div *ngIf="shouldDisplayGreenRedActive" class="w-100 text-center text-bold">
                <i class="fa fa-arrow-up greenFont" aria-hidden="true"></i>&nbsp;
                <i class="fa fa-arrow-down redFont" aria-hidden="true"></i>
              </div>
            </ng-container>

            <ng-container>
              <div *ngIf="shouldDisplayAttributeValue">
              <div class='w-100 text-truncate text-right pr-1'>{{params.value}}</div>
              </div>
            </ng-container>
          
            <ng-container>
              <div *ngIf="shouldDisplayCommonAttrValue">
              <div class='w-100 text-truncate pr-2 w-100'>{{ params.value.replaceAll("'","&#39;")}}</div>
              </div>
            </ng-container>
            <ng-container>
              <div *ngIf="shouldDisplayDefaultAttrValue">
               {{params.value}} <div class="w-100" ><input [id]="'attribute-' + columnDef.field +'-'+params.rowIndex" [title]="params.value" class="ctmInputBoxNoEdit" type="text" [placeholder]="columnDef.params?.placeholder" [value]="params.value" autocomplete="off"></div>
              </div>
            </ng-container>  
  `,
  styles: [ 
    '.c-green { color: green; } .c-red { color: red; } .entity-checkbox, .ctmInputBox { /* Your styles */ }'
  ]
})
export class CustomCellEditModeFormatter implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  value: any;
  columnDef: any;
  shouldDisplayCheckbox = false;
  shouldDisplayInput = false;
  shouldDisplayYesNo = false;
  shouldDisplayCopyIcon = false;
  shouldDisplayPlusIcon = false;
  shouldDisplayRedNotActiveArrow = false;
  shouldDisplayRedActive = false;
  shouldDisplayGreenRedNotActive = false;
  shouldDisplayGreenRedActive = false;
  shouldDisplayAttributeValue = false;
  shouldDisplayCommonAttrValue = false;
  shouldDisplayDefaultAttrValue = false;
  

  hideElement = 'disabled'; // Default value

  agInit(params: ICellRendererParams): void {
    this.params = params;  
    
    const {  column, value, data, rowIndex } = params;
    const { field } = column.getColDef();
    this.params = params; 
    this.columnDef = params.column.getColDef();
 
    let result = '';
    // Custom logic to determine display conditions
    if (data.action !== 'delete') {
      if (field === 'is_visible_output') {
        this.shouldDisplayCheckbox = true;
      } else if (field === 'sort_order' || field === 'attribute_id' || field === 'is_visible_input') {
        result = '';
      } else {
        this.shouldDisplayInput = true;
      }
    } else {
      if (field === 'is_visible_output') {
        this.shouldDisplayYesNo = true;
      } else if ( field === 'is_visible_input' && data.action !== 'add') {
        if(data.is_rule_exists_with_concept === true  ){
          this.shouldDisplayCopyIcon = true;
        }else {
            this.shouldDisplayPlusIcon = true;
        }
        
      } else if (field === 'sort_order'){  
          if(params.value === 1) { 
            if(params.value === params.data.maxValueOfPriority) {
                this.shouldDisplayRedNotActiveArrow = true;
            } else {
                this.shouldDisplayRedActive = true;
            }
           
          } else {
            if(params.value >=  params.data.maxValueOfPriority){
                this.shouldDisplayGreenRedNotActive = true;
            }else{
                this.shouldDisplayGreenRedActive = true;
            }
          }    
      } else if( field === 'attribute_id') {
        this.shouldDisplayAttributeValue = true;
      } else {
        // if(value == value && value.split('').indexOf(`'`) > -1) {
            if (params.value && typeof params.value === 'string' && params.value.includes(`'`)) {
            this.shouldDisplayCommonAttrValue = true; // value.replaceAll("'","&#39;")
          }  else {
            this.shouldDisplayDefaultAttrValue = true;
          }
      } 
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false; // Returning false ensures the cell isn't refreshed
  }
}