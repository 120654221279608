import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContentService } from '../../../zettasense/content/content.service';
import { Entity } from '../../../zettasense/content/entities/entity.model';
import { Catalog } from '../classify.model';
import { MessageService } from '../../../common/components/message/message.service';
import { ZsClContentService } from '../zs-cl-content.service';


@Component({
  selector: 'zetta-zs-cl-create-catalog',
  templateUrl: './zs-cl-create-catalog.component.html',
  styleUrls: ['./zs-cl-create-catalog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsClCreateCatalogComponent implements OnInit, OnDestroy {

  loggedInUserDetails: [];
  createCatalogForm: FormGroup;
  isDataReady = false;
  showBreadcrumb = false;
  loadCatalog = false;
  progressState = new ProjectStatus();
  catalog = new Catalog();
  icons: any;
  catalogGroup: Map<String, any[]> = new Map<String, any[]>();
  catalogIdMap: Map<String, any> = new Map<String, any>();
  savingCatalog = false;
  errorMessage = undefined;
  @ViewChild('focusElement') focusElement;

  constructor(private catalogSvc: ZsClContentService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zettaUtils: ZettaUtils,
              private contentSvc: ContentService,
              private formBuilder: FormBuilder,
              private messageSvc: MessageService ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.catalog.catalog_id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('catalog_id')); 
    this.loadCatalog = this.catalog.catalog_id ? true : false;

    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb || this.catalog.catalog_id) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    if (this.loadCatalog) {
      this.catalogSvc.getCatalog(this.catalog.catalog_id).subscribe(responseList => {
        this.catalog = responseList;
        this.isDataReady = true;
        this.progressState.catalog = responseList;
        this.setCatalogForm();
      }, err => {
        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
      });
    } else {
      this.isDataReady = true;
      this.setCatalogForm();
    }

    this.catalogSvc.getCatalogIcons().subscribe(response => {
        this.icons = response;
    }, err => { });

    this.progressState.states = this.zettaUtils.getStateList('create-catalog');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Catalog';
    this.progressState.isEdit = this.loadCatalog;
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }

  setCatalogForm() {
    this.createCatalogForm = this.formBuilder.group({
      name: [this.catalog.name, [Validators.required]],
      description: [this.catalog.description],
      // entity_type_id: [this.entity.entity_type_id ? this.entity.entity_type_id.toString() : undefined, Validators.required],
      icon_id: [this.catalog.icon_id, Validators.required]
    });
  }

  onNext() {
    this.onSubmit(false);
  }

  onChange(id, val) {
    this.createCatalogForm.controls[id].setValue(val);
  }
  onChangeName(val){
   if(val.length){
     this.errorMessage='';
   }
  }

  onSubmit(redirect: boolean) {    
    // Project is getting saved..
    if (this.savingCatalog) {
      return;
    }
    
    if (this.createCatalogForm.valid) {
      this.errorMessage = undefined;
      this.savingCatalog = true;
      const catalogtemp = new Catalog();

      // if (this.catalog) {
      //   catalogtemp.catalog_id = this.catalog.catalog_id;
      // }

      catalogtemp.name = this.createCatalogForm.controls.name.value;
      catalogtemp.description = this.createCatalogForm.controls.description.value;
      catalogtemp.icon_id = this.createCatalogForm.controls.icon_id.value;
      // catalogtemp.created_by = this.loggedInUserDetails['user_id'];

      const payload = {
        'name': this.createCatalogForm.controls.name.value,
        'description': this.createCatalogForm.controls.description.value,
        'icon_id': this.createCatalogForm.controls.icon_id.value
      }

      if (!this.loadCatalog) {
        // catalogtemp.catalog_id = undefined;
        this.catalog.catalog_id = undefined;
      }

      this.catalogSvc.saveCatalog(this.catalog.catalog_id, payload).subscribe(responseList => {
        
        if (responseList) {
          this.loadCatalog = true;
          this.catalog = responseList.length > 0 ? responseList[0]:responseList ;
        }

        this.errorMessage = '';
        this.savingCatalog = false;
        $('#projectState0').removeClass('d-none');
        setTimeout(function () {
          $('#projectState0').addClass('d-none');
        }, 5000);

        if (redirect) {
           this.router.navigate(['/zs-cl/catalogs', this.catalog.catalog_id, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb, isClassify:true }, queryParamsHandling:'merge'  });
        } else {
          // this.router.navigate(['/zs-cl/catalogs/' + this.catalog.catalog_id + '/objects']);
        ////  this.router.navigate(['/zs-cl/catalogs', this.catalog.catalog_id, 'objects'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
           this.router.navigate(['/zs-cl/catalogs', this.catalog.catalog_id, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb, isClassify:true }, queryParamsHandling:'merge' });
        }

      }, err => {
        this.errorMessage = err.error.message;
        this.savingCatalog = false;
        this.messageSvc.sendMessage({ message: this.errorMessage, type: 'INFO', hideboard: true });
      });
    }
  }

  onCancel() {
    this.router.navigate(['/zs-cl/catalogs']);
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    this.messageSvc.clearMessage();
  }

}