import { Component, OnInit } from '@angular/core';
import { Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../common/zm-services/authentication.service';
import { AppGlobals } from '../../common/zm-shared/app.globals';
import { ZmBaseService } from '../../common/zm-services/zm-base-service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'zetta-zm-header',
  templateUrl: './zm-header.component.html',
  styleUrls: ['./zm-header.component.scss']
})
export class ZmHeaderComponent implements OnInit {
  public userinfo;
  public profilePic;
  constructor(
    private _router: Router,
    private _compiler: Compiler,
    private _authSvc: AuthenticationService,
    private _baseService: ZmBaseService,
    private http: HttpClient,
    private authService:MsalService
  ) { }

  ngOnInit() {
    this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    console.log(this.userinfo)
  }

	resetActiveTimer() {
		this._baseService.notifyUserAction();
	}

	public getUserPic() {
		sessionStorage.setItem('isUserProfile', "1");
		this._baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {			
		}, err => {
			this.profilePic = err.url;
		});
	}
  
  logout() {
    // this.timerSubscription.unsubscribe();

    this._compiler.clearCache();        
    if(!!this.authService.getAccount() && sessionStorage.getItem('loginProvider') === 'azure_sos'){
			this.authService.logout();
		} else {
			this._authSvc.signOut();
		}

    localStorage.clear();
    sessionStorage.clear();
    // setTimeout(() => {
    //   this._router.navigate([AppGlobals.ZETTAMESH_LOGIN]);
    // }, 100);
  }

}
