import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZcContentService } from '../../zc-content.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'zetta-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit {

  public safeSrc: SafeResourceUrl;
  public reportid: number;
  public dataReports: any = [];

  constructor(
    public activeRoute: ActivatedRoute,
    private _contentService: ZcContentService,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    const routeParams = this.activeRoute.snapshot.params;
    this.reportid = routeParams.id;

    this._contentService.getReports().subscribe((data) => {
      this.dataReports = data;
      this.dataReports = this.dataReports.find(report => report.reportid === this.reportid);
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.dataReports.reportsource);
    }, err => {
      // this.isLoading = false;
    });
  }

}
