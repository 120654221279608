import { Component, OnInit } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { DatasourceService } from '../../datasource.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Datasource } from '../../datasource.model';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { dateFormatter } from '../../../../../common/shared/formatters/dateFormatter';
@Component({
  selector: 'zetta-edit-description',
  templateUrl: './edit-description.component.html',
  styleUrls: ['./edit-description.component.scss']
})
export class EditDescriptionComponent implements OnInit {

  activityHistory: object = {};
  grid: AngularGridInstance;
  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  historyTableColDef: any;
  gridOptions: any;
  createDatasourceForm: FormGroup;
  datasource = new Datasource();
  isDataReady = true;
  getDataSource;
  canedit = false;

  constructor(private datasourceSvc: DatasourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private zettaUtils: ZettaUtils) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.datasource.datasource_id = params['id'];
    });
    this.setDatasourceForm();
    this.getDatasource();
    this.historyTableColDef = [{
      'displayname': 'Date Time',
      'physicalname': 'created_date',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': dateFormatter
    }, {
      'displayname': 'User',
      'physicalname': 'activity_username',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'Activity',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
  }

  getActivityHistoryData() {
    this.datasourceSvc.getActivityHistory({
      userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'],
      sourceId: this.datasource.datasource_id
    })
      .subscribe((data) => {
        this.activityHistory = data;
        if (this.grid) {
          this.activityHistory['currentpage'].forEach(element => {
            element.id = Math.random();
          });
        }
      }, err => {
      });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  viewHistoryClick() {
    this.getActivityHistoryData();
  }

  setDatasourceForm() {
    this.createDatasourceForm = this.formBuilder.group({
      description: [this.datasource.description]
    });
  }

  getDatasource() {
    this.datasourceSvc.getDatasource({
      userId: this.loggedInUserDetails['user_id'], 
      tenant_id: this.loggedInUserDetails['tenant_id'],
      typeId: this.datasource.datasource_id
    }).subscribe(responseList => {
      this.getDataSource = responseList;
      this.datasource = this.getDataSource;
      this.setDatasourceForm();
      if(this.getDataSource.created_by === this.loggedInUserDetails['user_name']){
        this.canedit = true;
      }
    }, err => {
    });
  }

  editDiscriptionClick() {
    this.getDatasource();
  }

  onSave() {
    this.isDataReady = true;
    if (this.createDatasourceForm.valid) {
      const datasourceTemp = {
        'description': this.createDatasourceForm.controls.description.value
      };
      this.datasourceSvc.updateDatasource({
        userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'],
        payload: datasourceTemp, sourceId: this.datasource.datasource_id
      }).subscribe(responseList => {
        this.isDataReady = false;
        window.location.reload();
      }, err => {
      });
    }
  }

}
