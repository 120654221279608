import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppSharedModule } from '../../common/shared/app-shared.module';
import { ContentComponent } from './content.component';
import { ContentRoutingModule } from './content.routing';
import { ContentService } from './content.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { TasksComponent } from './tasks/tasks.component';
import { MyTasksComponent } from './tasks/my-tasks/my-tasks.component';
import { UsersComponent } from './project/users/users.component';
import { DataSetsComponent } from './project/data-sets/data-sets.component';
import { CreateProjectComponent } from './project/create-project/create-project.component';
import { ProjectContentComponent } from './project/project-content/project-content.component';
import { MyProjectsComponent } from './project/my-projects/my-projects.component';
import { DataSourcesComponent } from './project/data-sources/data-sources.component';
import { MapDataSetsComponent } from './project/map-data-sets/map-data-sets.component';
import { TrainMasteringModelComponent } from './project/train-mastering-model/train-mastering-model.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { PdDataComponent } from './project/project-detail/pd-data/pd-data.component';
import { PdUsersComponent } from './project/project-detail/pd-users/pd-users.component';
import { PdTasksComponent } from './project/project-detail/pd-tasks/pd-tasks.component';
import { PdEntityResolvedComponent } from './project/project-detail/pd-entity-resolved/pd-entity-resolved.component';
import { PdEntityMasteredComponent } from './project/project-detail/pd-entity-mastered/pd-entity-mastered.component';
import { PdActivityHistoryComponent } from './project/project-detail/pd-activity-history/pd-activity-history.component';
import { TrainResolutionModelComponent } from './project/train-resolution-model/train-resolution-model.component';
import { MatcherFeedbackComponent } from './project/matcher-feedback/matcher-feedback.component';
import { ManageJobsComponent } from '../manage-jobs/manage-jobs.component';
import { ManageTenantsComponent } from '../manage-tenants/manage-tenants.component';
import { EntityRecordsViewResultsComponent } from './entity-records-view-results/entity-records-view-results.component';
import { ViewResolutionResultsComponent } from './view-resolution-results/view-resolution-results.component';
import { ParametersComponent } from './parameters/parameters.component';
import { UsergroupComponent } from '../usergroup/usergroup.component';
import { EntitiesViewComponent } from './entities-view/entities-view.component';
import { GrdFilterPipe } from './entities-view/table-filter.pipe';
import { UiSwitchModule } from 'ngx-toggle-switch';
// import { StatisticsComponent } from './statistics/statistics.component';
import { FormsModule } from '@angular/forms';
import { ManageProjectTasksComponent } from './project/manage-project-tasks/manage-project-tasks.component';
import { MatchingRemediationTasksComponent } from './project/matching-remediation-tasks/matching-remediation-tasks.component';
import { MergerRemediationTasksComponent } from './project/merger-remediation-tasks/merger-remediation-tasks.component';
import { AdminComponent } from '../../common/components/admin/admin.component';

import { PublishResultComponent } from './project/publish-result/publish-result.component';
import { PublishResultsViewComponent } from './project/publish-results-view/publish-results-view.component';
import { EntitiesComponent } from './entities/entities.component';
import { EntityModelComponent } from './entities/entity-model/entity-model.component';
import { EntityModelViewComponent } from './entities/entity-model-view/entity-model-view.component';
import { CreateEntityComponent } from './entities/create-entity/create-entity.component';
import { EntityHomeComponent } from './entities/entity-home/entity-home.component';
// import { GoldenRecordsComponent } from './project/golden-records/golden-records.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { DataQualityRulesModelComponent } from './entities/data-quality-rules/data-quality-rules-model.component';
import { UsergroupsComponent } from '../user-groups/user-groups.component';
import { EntityModelProjectComponent } from './project/entity-model-project/entity-model-project.component';
import { ProductionDetailComponent } from './project/production-detail/production-detail.component';
import { RecentGoldenRecordsComponent } from './project/recent-golden-records/recent-golden-records.component';
import { ProductionProjectConfigComponent } from './project/production-project-config/production-project-config.component';
import { ProductionProjectDataComponent } from './project/production-project-data/production-project-data.component';
import { OpenGoldenRecordsComponent } from './project/open-golden-records/open-golden-records.component';
// import { AgGridModule } from 'ag-grid-angular';
// import { FsGridComponent } from 'src/app/common/components/fs-grid/fs-grid.component';
// import { NameLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';

// import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
// import { ProjectNameFormatterComponent } from 'src/app/common/shared/cell-renderer/project-name-formatter/project-name-formatter.component';
// import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mathermerger-formatter/mathermerger-formatter.component';

// import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';

// import { NoRowOverlayComponent } from 'src/app/common/components/no-row-overlay/no-row-overlay.component';
// import { LicenseManager } from 'ag-grid-enterprise'
// import 'ag-grid-enterprise';
// import { NumberFormatterComponent } from 'src/app/common/shared/cell-renderer/number-formatter/number-formatter.component';
// LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")

// import { AgGridModule } from 'ag-grid-angular';
// import { LicenseManager } from 'ag-grid-enterprise'
// import 'ag-grid-enterprise';
// LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")

@NgModule({
    imports: [
        CommonModule,
		// AgGridModule,
		ContentRoutingModule,
		AppSharedModule,
		UiSwitchModule,
		FormsModule, 
		BsDatepickerModule.forRoot()
		
	],
	declarations: [
		DashboardComponent,
		ContentComponent,
		NotfoundComponent,
		TasksComponent,
		MyTasksComponent,
		UsersComponent,
		CreateProjectComponent,
		ProjectContentComponent,
		MyProjectsComponent,
		MapDataSetsComponent,
		TrainMasteringModelComponent,
		ProjectDetailComponent,
		PdEntityResolvedComponent,
		PdEntityMasteredComponent,
		TrainResolutionModelComponent,
		MatcherFeedbackComponent,
		ManageJobsComponent,
		ManageTenantsComponent,
		EntityRecordsViewResultsComponent,
		ViewResolutionResultsComponent,
		ParametersComponent,
		UsergroupComponent,
		UsergroupsComponent,		
		EntitiesViewComponent,
		GrdFilterPipe,
		// StatisticsComponent,
		ManageProjectTasksComponent,
		MatchingRemediationTasksComponent,
		MergerRemediationTasksComponent,
		PublishResultComponent,
		PublishResultsViewComponent,
		DataSourcesComponent,
		DataSetsComponent,
		EntitiesComponent,
		EntityModelComponent,
		EntityModelViewComponent,
		CreateEntityComponent,
		EntityHomeComponent,
		// GoldenRecordsComponent,
		DataQualityRulesModelComponent,  
		EntityModelProjectComponent,
		ProductionDetailComponent, 
		RecentGoldenRecordsComponent, ProductionProjectConfigComponent, ProductionProjectDataComponent, OpenGoldenRecordsComponent,
		// NoRowOverlayComponent,
		// DateFormatterFormatterComponent,
		// ProjectNameFormatterComponent,
		// MatherMergerFormatterComponent,
		// AssignmentStatusFormatterComponent,
		// FsGridComponent,
	],
	entryComponents: [
		// NoRowOverlayComponent,
		// DateFormatterFormatterComponent,
		// ProjectNameFormatterComponent,
		// MatherMergerFormatterComponent,
		// AssignmentStatusFormatterComponent
	 ],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [ContentService,DatePipe]
})
export class ContentModule { }
