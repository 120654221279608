import { Column , Formatter } from 'angular-slickgrid';

export const datasetNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

    function transform(title) {
      return `${getTitleImage()}<span class="highlight-title text-ds" title="${title}">${title}</span>`;
    }

    function getTitleImage() {
      const hasFontImage = (columnDef.params && columnDef.params['fontImg'] !== undefined) ? true : false;
      const fontClass = columnDef.params['fontImg'];
      return hasFontImage ? `<i class="${fontClass} data-icon-wb-fa mr-2"></i>` : `<img class="data-source-ds-img mr-1" src="./assets/images/zettamesh/icon_sm_dataset.png">`;
    }
    
    return transform(value);
};
