import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `<ng-container *ngIf="isDeleteIcon">
                <i class="fa fa-trash delete-icon"> </i>
                </ng-container>
                <ng-container *ngIf="!isDeleteIcon">
                <i class="fa fa-plus add-icon"></i>
                </ng-container> 
                
                 `,
  styles: [".delete-icon { opacity: 0.3; cursor: default; }"],
})
export class DeleteIconRendererComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  isDeleteIcon = false; 

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isDeleteIcon = params.value === "delete"; 
    // console.log("Param Value:", params.value);
  }

  onDeleteClick(): void {
    // Handle delete action here
    // You can access row data via this.params.data
    // Implement your delete logic
  }

  refresh(params: ICellRendererParams): boolean {
    return false; // Returning false ensures the cell isn't refreshed
  }
}
