import {Component, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from '../../../zs-cl-content.service';

@Component({
    selector:'zetta-dq-execution-result',
    templateUrl:'zs-cl-dq-execution-results.component.html',
    styleUrls:['zs-cl-dq-execution-results.component.scss']
})
export class ZsClDqExecutionResultsComponent {
    measured_rule_score:number;
    measured_dq_score=0;
    measured_rule_css:string;
    public lineProjectResolvedChartDS = null;
    public lineChartUniqueID: any = '';
    rule_id:string;
    catalog_id:string;
    object_id:string;
    concept_id:string;
    objects:object;
    name:string;
    description:string;
    loggedInUserDetails: [];
    asOfDate;
    @Input() semanticObjectLevelDQ;
    @Input() catalogLevelDQ;
    dq_dimention:any=[];
    total_records:number;
    bad_records:number;
    good_records:number;
    dqRuleLevel:string;
    isDimensionDataAvail:Boolean = false;

    public lineProjectResolvedChartOptions: object = {
          'isMoneyFormat': false,
          'height': 133,
          'resizeRequired': true,
          'label': 'year',
          'value': 'value',
          'lineColor': '#e5b500',
          'circleColor': '#ff0000'
      };
    constructor(private zsClContentService:ZsClContentService,public zettaUtils:ZettaUtils, private activatedRoute: ActivatedRoute) {
      this.lineChartUniqueID = this.zettaUtils.generateUUID();
     }
  
    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));        
        this.activatedRoute.queryParamMap.subscribe(params => {
          this.catalog_id = params.get('catalog_id')
          this.object_id = params.get('object_id')
          this.concept_id = params.get('concept_id')
          this.dqRuleLevel=params.get('dqRuleLevel')
        });  
        this.zsClContentService.showGraphGrid.next({showGraph:true, name:'',rule_id:''});
        this.getGraphData(this.dqRuleLevel);
        if (this.catalogLevelDQ) {
            this.getCatalogs();
            this.getDQDimensionsForCatalog();
            this.getExecutionSummaryforCatalog();
        } else if (this.semanticObjectLevelDQ) {
            this.getSemantics();
            this.getMeasuredRule();
            this.getDQDimensions();
        } else {
            this.getConcepts();
            this.getExecutionSummary(); 
            this.getDimentions();
        }

    }

    getConcepts() {
        this.zsClContentService.getConcept(this.catalog_id, this.object_id, this.concept_id).subscribe(resp => {
            if (resp) {
                this.objects = resp;
                this.name = resp.logical_name;
                this.description = resp.description;
              }
         });
    }

    getSemantics() {
        this.zsClContentService.getSemanticObject(this.catalog_id, this.object_id).subscribe(resp => {
            if (resp) {
               this.name = resp.semantic_object;
               this.description = resp.definitions;
             }
         });
    }

    getCatalogs() {
        this.zsClContentService.getCatalog(this.catalog_id).subscribe(resp => {            
            if (resp) {
               this.name = resp.name;
               this.description = resp.description;
             }
         });
    }

    getMeasuredRule() {
        let today = new Date();
		this.asOfDate = today.toLocaleDateString();
      this.zsClContentService.getDQSummarySemanticLevel(this.loggedInUserDetails['user_id'], this.catalog_id, this.object_id).subscribe(
        data => {
            const DQSummaryDetail = data[0];
            if(DQSummaryDetail){
                this.total_records = DQSummaryDetail.total_records;
                this.bad_records = DQSummaryDetail.exceptions;
                this.good_records = this.total_records  - this.bad_records;
                this.asOfDate = DQSummaryDetail.last_updated_date;
                const dqScore = DQSummaryDetail.quality_score;
                const ruleObj = this.zsClContentService.getMeasuredRule(dqScore, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
            }
            else
            {
                this.total_records = 0;
                this.bad_records =0;
                this.good_records = 0;
                const ruleObj = this.zsClContentService.getMeasuredRule(0, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
                
            }
           // this.lineProjectResolvedChartDS = this.prepareLineChartData(data);
        },
        error => {
        }
    );           
    }

    getDimentions(){
        this.zsClContentService.getDimentionsByConcept(this.catalog_id, this.object_id, this.concept_id).subscribe(resp=>{
            if ( resp.length === 0 ) {                    
                this.isDimensionDataAvail = true;
            } else {
                this.isDimensionDataAvail = false;
                this.dq_dimention = resp;
                this.dq_dimention.forEach(element => {
                    element.value = element.quality_score.toFixed(2);
                });
            }
        })
    }
    getExecutionSummary(){
        let today = new Date();
		this.asOfDate = today.toLocaleDateString();
        this.zsClContentService.getExecutionSummaryByConcept(this.catalog_id, this.object_id, this.concept_id)
        .subscribe(resp =>{
            const summaryData = resp[0];
            if(summaryData){
                this.total_records = summaryData.total_records;
                this.bad_records = summaryData.exceptions;
                this.good_records = this.total_records  - this.bad_records;
                this.asOfDate = summaryData.last_updated_date;
                const dqScore = summaryData.quality_score;
                const ruleObj = this.zsClContentService.getMeasuredRule(dqScore, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
            }
            else
            {
                this.total_records = 0;
                this.bad_records =0;
                this.good_records = 0;
                const ruleObj = this.zsClContentService.getMeasuredRule(0, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
                
            }
            //this.lineProjectResolvedChartDS = this.prepareLineChartData(resp);
        })
    }
   
    getExecutionSummaryforCatalog(){
        let today = new Date();
		this.asOfDate = today.toLocaleDateString();
        this.zsClContentService.getExecutionSummaryByCatalog(this.catalog_id)
        .subscribe(resp =>{
            const summaryData = resp[0];
            if(summaryData){
                this.total_records = summaryData.total_records;
                this.bad_records = summaryData.exceptions;
                this.good_records = this.total_records  - this.bad_records;
                this.asOfDate = summaryData.last_updated_date;
                const dqScore = summaryData.quality_score ;
                const ruleObj = this.zsClContentService.getMeasuredRule(dqScore, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
            }
            else
            {
                this.total_records = 0;
                this.bad_records =0;
                this.good_records = 0;
                const ruleObj = this.zsClContentService.getMeasuredRule(0, this.loggedInUserDetails['enable_dq_rule'] );
                this.measured_rule_score = ruleObj["dqRuleText"];
                this.measured_rule_css = ruleObj["dqRuleCss"];
                
            }
           // this.getDQRunModel();
            //this.lineProjectResolvedChartDS = this.prepareLineChartData(resp);
        })
    }
  

    getDQDimensions() {
        this.zsClContentService.getDQDimensions(this.loggedInUserDetails['user_id'], this.catalog_id, this.object_id).subscribe(
            data => {
                if ( data.length === 0 ) {                    
                    this.isDimensionDataAvail = true;
                } else {
                    this.isDimensionDataAvail = false;
                    this.dq_dimention = data;
                    this.dq_dimention.forEach(element => {
                        element.value = element.quality_score.toFixed(2);
                    });
                }
            },
            error => {
            }
        );
    }

    getDQDimensionsForCatalog() {
        this.zsClContentService.getDQDimensionsByCatalog(this.loggedInUserDetails['user_id'], this.catalog_id).subscribe(
            data => {                
                if ( data.length === 0 ) {                    
                    this.isDimensionDataAvail = true;
                } else {
                    this.isDimensionDataAvail = false;
                    this.dq_dimention = data;
                    this.dq_dimention.forEach(element => {
                        element.value = element.quality_score.toFixed(2);
                    });
                }
            },
            error => {
            }
        );
    }
    
    
    getDQRunModel() {
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			catalog_id: this.catalog_id,
			rule_id: this.rule_id
		};
		this.zsClContentService.getModalRuns(data).subscribe(data => {
			this.lineProjectResolvedChartDS = this.prepareLineChartData(data);
		}, error => {
			this.lineProjectResolvedChartDS = [];
		});
	}

    getGraphData(catalogLevel) {
        if(catalogLevel == 'catalog') {
            const payload = {
                user_id: this.loggedInUserDetails['user_id'],
                catalog_id: this.catalog_id,
            };
            this.zsClContentService.getCatalogSummaryGraph(payload, 'catalog').subscribe(grapdata => {
                this.lineProjectResolvedChartDS = this.prepareLineChartData(grapdata);
            }, error => {
                this.lineProjectResolvedChartDS = [];
            });
        } else if(catalogLevel == 'object') {
            const payload = {
                user_id: this.loggedInUserDetails['user_id'],
                catalog_id: this.catalog_id,
                semantic_id:this.object_id
            };
            this.zsClContentService.getCatalogSummaryGraph(payload, 'object').subscribe(grapdata => {
            this.lineProjectResolvedChartDS = this.prepareLineChartData(grapdata);
            }, error => {
                this.lineProjectResolvedChartDS = [];
            });
        } else {
            const payload = {
                user_id: this.loggedInUserDetails['user_id'],
                catalog_id: this.catalog_id,
                semantic_id:this.object_id,
                concept_id:this.concept_id
            };
            this.zsClContentService.getCatalogSummaryGraph(payload, 'concept').subscribe(grapdata => {
            this.lineProjectResolvedChartDS = this.prepareLineChartData(grapdata);
            
            }, error => {
                this.lineProjectResolvedChartDS = [];
            });
        }
    }

    getsemanticDQRunModel() {
        
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			catalog_id: this.catalog_id,//this.userinfo.tenant_id,
			rule_id: this.rule_id,
            semantic_id:this.object_id
		};
		this.zsClContentService.getSemanticModalRuns(data).subscribe(data => {
		this.lineProjectResolvedChartDS = this.prepareLineChartData(data);
		}, error => {
		this.lineProjectResolvedChartDS = [];
		});
	}
    getconceptDQRunModel() {
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			catalog_id: this.catalog_id,
			rule_id: this.rule_id,
            semantic_id:this.object_id,
            concept_id:this.concept_id
		};
		this.zsClContentService.getConceptModalRuns(data).subscribe(data => {
		this.lineProjectResolvedChartDS = this.prepareLineChartData(data);
		}, error => {
		this.lineProjectResolvedChartDS = [];
		});
	}
  prepareLineChartData(data) {
	const dataSet = [];
        data.forEach((element) => {
            const d = new Date(element['last_updated_date']);
            if(element && ('measured_dq_score' in element)) {
            dataSet.push({ 'year': d.getFullYear(), 'value': element['measured_dq_score'].toFixed(2), 'fulldate': d });
            } else {
            dataSet.push({ 'year': d.getFullYear(), 'value': element['quality_score'].toFixed(2), 'fulldate': d });
            }
        });
		return dataSet;
    }
}