import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import { formatDate } from '@angular/common';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zm-zetta-line-chart',
    templateUrl: './zm-line-chart.component.html',
    styleUrls: ['./zm-line-chart.component.scss']
})
export class ZmLineChartComponent implements AfterViewInit {

    public donut: any = {};
    public chartHeight: number;
    public chartWidth: number;
    public fontSize: number;
    public isChartInValid = false;
    public $container;
    // public $containerDiv;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;

    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(public cdRef: ChangeDetectorRef, public observeSvc: ZmObservableService, public zettaUtils: ZmZettaUtils) {}

    ngAfterViewInit() {
        this.isChartInValid = (!this.dataDataSource || this.dataDataSource.length == 0) ? true : false;
        if (!this.isChartInValid) {
            this.isChartInValid = (!this.chartOptions || !this.chartOptions['label'] || !this.chartOptions['value']) ? true : false;
        }
        if (!this.chartColors) {
            this.chartColors = ['#483da0', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }
        this.cdRef.detectChanges();
        if (this.dataDataSource && !this.isChartInValid) {
            setTimeout(() => {
                this.renderChart();
            }, 100);
        }
        let parent = this;
        this.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    renderChart() {
        let parent = this;
        // parent.$container = $('#chart_' + parent.chartUniqueID);
        parent.$container = $('#chart_' + parent.chartUniqueID).parent().closest('div');

        if (parent.chartOptions['height']) {
            if(parent.chartOptions['height'] <= 200){
                parent.chartHeight = 200;
            }else if(parent.chartOptions['height'] <= parent.$container.height()) {
                parent.chartHeight = parent.chartOptions['height'];
            }else{
                parent.chartHeight = parent.$container.height()-10;
            }
        } else {
            parent.chartHeight = parent.$container.height()-10;
        }

        if (parent.chartOptions['width']) {
            if(parent.chartOptions['width'] <= 200){
                parent.chartWidth = 200;
            }else if(parent.chartOptions['width'] <= parent.$container.width()) {
                parent.chartWidth = parent.chartOptions['width'];
            }else{
                parent.chartWidth = parent.$container.width()-10;
            }
        } else {
            parent.chartWidth = parent.$container.width()-10;
        }

        parent.fontSize = (Math.min(parent.chartWidth, parent.chartHeight) / 4);

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        $('#chart_' + parent.chartUniqueID).empty();
        
        let margin = { top: 5, right: 22, bottom: 20, left: 20 },
            width = parseFloat(parent.chartWidth.toString()) - margin.left - margin.right,
            height = parseFloat(parent.chartHeight.toString()) - margin.top - margin.bottom;

        let rootNode = d3.select('#chart_' + parent.chartUniqueID);
        let tooltip = rootNode.append('div').attr('class', 'toolTip');
        var parseDate = d3.timeFormat('%m/%d/%Y');

        let svg = rootNode.append('svg:svg')
            .attr('width', parent.chartWidth)
            .attr('height', parent.chartHeight)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        parent.dataDataSource.forEach(function(d, i) {
            d.label = d['fulldate'];
            d.value = +d.value;
        });

        parent.dataDataSource.sort(function(a, b) { return a.label - b.label; });

        let lineTooltip = rootNode.append('div').attr('class', 'toolTip');

        //Creates the xScale 
        var xScale = d3.scaleTime().range([0, width]);

        //Creates the yScale
        var yScale = d3.scaleLinear().range([height, 0]);

        //Defines the y axis styles
        var yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

        //Defines the y axis styles
        var xAxis = d3.axisBottom(xScale).tickFormat(parseDate).tickSizeOuter(0);

        //line function convention (feeds an array)
        var line = d3.line()
            .x(function(d) { return xScale(d.label); })
            .y(function(d) { return yScale(d.value); })
            .curve(d3.curveMonotoneX);

        var max = new Date(d3.max(parent.dataDataSource, function(d){return d.label}));
        var min = new Date(d3.min(parent.dataDataSource, function(d){return d.label}));

        if(parent.dataDataSource.length === 1 && parseDate(min) === parseDate(max)){
            let tempDate = new Date(max);   
            max = new Date(tempDate.setDate(tempDate.getDate() + 1)); 
        }

        xScale.domain([min,max]);//.nice();
        yScale.domain([0, d3.max(parent.dataDataSource, function (d) { return d.value + 10;})]);//.nice();

        //Appends the y axis
        var yAxisGroup = svg.append('g')
            .attr('class', 'y_axis d-none')
            .call(yAxis);

        //Appends the x axis    
        var xAxisGroup = svg.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);

        // Add the line
        svg.append("path")
            .datum(parent.dataDataSource)
            .attr("fill", "none")
            .attr("stroke", parent.chartOptions['lineColor'])
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(function(d) { return xScale(d.label) })
                .y(function(d) { return yScale(d.value) })
            ).call(transition);

        // Add the line
        svg.selectAll("myCircles")
            .data(parent.dataDataSource)
            .enter()
            .append("circle")
            .attr("fill", "red")
            .attr("stroke", "none")
            .attr("cx", function(d) { return xScale(d.label) })
            .attr("cy", function(d) { return yScale(d.value) })
            .attr("r", 3)
            .on('mouseover', function() { lineTooltip.style('display', 'none'); })
            .on('mouseout', function() { lineTooltip.style('display', 'none'); })
            .on('mousemove', mousemove);

        svg.select('.axis--x').selectAll('line')
            .attr('display', 'none')
        svg.select('.axis--x').selectAll('text')
            .attr('text-anchor', 'start')
            .attr('display', 'none')

        let textItems = $('#chart_' + parent.chartUniqueID + ' .axis--x text');
        textItems.each(function(index) {
            if (index === 0) {
                $(this).attr('x', '-20')
                $(this).attr('text-anchor', 'start').show();
            }else if (index === textItems.length - 1) {
                $(this).attr('text-anchor', 'end').show();
            }
        });

        function transition(path) {
            path.transition()
                .duration(2000)
                .attrTween("stroke-dasharray", tweenDash);
        }

        function tweenDash() {
            var l = this.getTotalLength(),
                i = d3.interpolateString("0," + l, l + "," + l);
            return function(t) { return i(t); };
        }

        //Tooltip mouseovers            
        function mousemove(d,i) {
        lineTooltip
            .style('left', d3.event.pageX - 35 + 'px')
            .style('top', d3.event.pageY - 35 + 'px')
            .style('display', 'inline-block')
            .html(function() {
                if(parent.chartOptions['isMoneyFormat']){
                    return '<span>Records: <strong>' + d.value + '</strong> ' + d3.timeFormat('%m/%d/%Y')(d.label) + '</span>';
                }else{
                    return '<span>Run #' + (i+1) + '<strong> ' + (d.value) + '%</strong></span>';
                }
            });
        };        
    }

    onResize() {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }

}