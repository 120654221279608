import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from '../../shared/app.globals';
declare var $: any;

@Component({
  selector: 'zetta-processing-modal',
  templateUrl: './processing-modal.component.html',
  styleUrls: ['./processing-modal.component.scss']
})
export class ProcessingModalComponent implements OnInit {
processMainText = AppGlobals.PROCESS_CATALOG_TEXT;
processNavText = AppGlobals.PROCESS_CATALOG_NAV_LINK_TEXT;
isReadMore: boolean = true;
@Input() trackJobErrorDetails?: object;
  constructor(private router: Router) { }

  ngOnInit() {
    $('#processing_model').modal('show');
  }

  backToCatalogs() {
    $('#processing_model').modal('hide');
    this.router.navigate(['/zs-cl/catalogs/']);
  }

  closeProcessModal() {
    $('#processing_model').modal('hide');
    return;
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }
}
