import { Component, ChangeDetectorRef, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { UserGroupService } from '../../../../usergroup/usergroup.service';
import { ProjectService } from '../../project.service';
import { ObservableService } from '../../../../../common/services/observable.service';
import { Project } from '../../project.model';

@Component({
  selector: 'zetta-pd-data',
  templateUrl: './pd-data.component.html',
  styleUrls: ['./pd-data.component.scss']
})

export class PdDataComponent implements OnInit {

  public openTaskDS: any = [];
  public usersDataSource: any = [];
  public todaysDate = new Date();
  public isDataReady = false;
  isResolveProject= true;
  public lineProjectChartOptions: object = {
    'height': 115,
    'resizeRequired': true,
    'label': 'year',
    'value': 'value',
    'lineColor': '#57cff2',
    'circleColor': '#ff0000',
    'isMoneyFormat': true
  };
  public lineProjectChartDS = null;
  private _userinfo;
  private _project_entitlements = {};
  public projectData = new Project();
  project_id
  @Input() projectAdmin
  @Input() is_classify_project = false;
  @Input() total_mapped_ds: number;
  @Input() total_ds_records: number;
  @Input() latest_update: any;
  @Input() attrubute_id?: number;
  @Output() projectDataReceived = new EventEmitter<any>();
  isDisabled : boolean = false
  project_type: String = '';
  totalRecords;
  project_type_id:number;
  classifyProject:any;
  constructor(public cdRef: ChangeDetectorRef,
    public zettaUtils: ZettaUtils,
    public userGroupService: UserGroupService,
    private _projectService: ProjectService,
    public observeSvc: ObservableService,
    private activeRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.projectData.total_records = 0;
    this.projectData.datasets = [];

    const queryParams = this.activeRoute.snapshot.queryParams;
    const routeParams = this.activeRoute.snapshot.params;
    this.project_id = routeParams.id;
    
    this._userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.project_type = sessionStorage.getItem('project_type');
    this.activeRoute.queryParamMap.subscribe(param => {
      this.project_type = param.get('project_type');
      this.project_type_id = +param.get('project_type_id');
    });    
    if(this.is_classify_project){
      this.isResolveProject = false;
      this.isDataReady = true;      
    }else{
      this.getProjectData(routeParams.id);
      this.getActivityDatasets(routeParams.id);
    }

    if (this.project_type == 'Concept Parser') {
      this._projectService.getParseRecords(this._userinfo.user_id, this._userinfo.tenant_id, this.project_id).subscribe(data => {        
        this.totalRecords = data['totalrecords'];
      })
    }

    this.observeSvc.getCurrentEntitlements.subscribe(data => { this._project_entitlements = data || {}; });
  }

  isDisable() {
    if (this.isResolveProject == true) {
      return this.isHavingAccess();
    } else {
      if (this.projectAdmin === true) {
        return false;
      } else {
        return true;
      }
    }
  }

  isHavingAccess() {
      if (this.zettaUtils.isProcessRunning && !this.zettaUtils.isFileNotFound) {
        return true;
      } else {
        return (this._project_entitlements['canUpdate'] && this._project_entitlements['canView']) ? false : true;
      }
  }

  getProjectData(projectId) {
    this._projectService.getProject(this._userinfo.user_id, projectId)
      .subscribe(res => {
        this.projectData = res;
        this.projectDataReceived.emit(res);
        this.observeSvc.setEntityName = res.entity_name;
        this.zettaUtils.getProjectAdmin(this.projectData.user_id);
      }, error => {
        this.projectData.updated_ts = new Date();
        this.projectData.users = [];
      });
  }

  getActivityDatasets(projectId) {
    const parent = this;
    parent._projectService.getModelSummaryLoader(parent._userinfo.user_id, projectId).subscribe(
      data => {
        parent.isDataReady = true;
        parent.lineProjectChartDS = parent.prepareLineChartData(data);
      }, error => {
        parent.isDataReady = true;
        parent.lineProjectChartDS = [];
      }
    );
  }

  onClickManageData() {
    if ( this.isResolveProject === true ) {
      this.router.navigate(['/zs/projects/', this.project_id, 'datasets'], { queryParams: { showBreadcrumb: 'true', resolveEditFlow: true, class: null }, queryParamsHandling: 'merge' });
    }
    else {
      if(this.project_type.toLowerCase() == 'concept parser'){
        this.router.navigate(['/zs-cl/projects/', this.project_id, 'concept-training-data'], { queryParams: {project_type:this.project_type,project_type_id:this.project_type_id ,showBreadcrumb: 'true',activeLink:'1',manageData:true, attributeId:this.attrubute_id }}); 
      }else{
        this.router.navigate(['/zs-cl/projects/', this.project_id, 'select-object'], { queryParams: { showBreadcrumb: 'true' }, queryParamsHandling: 'merge' });
      }
    }
  }

  prepareLineChartData(data) {
    const dataSet = [];
    data.forEach((element) => {
      const d = new Date(element['created_ts']);
      dataSet.push({ 'year': d.getFullYear(), 'value': element['input_total_records'], 'fulldate': d });
    });
    return dataSet;
  }
}
