import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../zs-cl-content.service'
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../classify.model';
import { environment } from '../../../../environments/environment';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { ColDef, GridApi, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { NameLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
import { ConfidenceFormatterComponent } from 'src/app/common/shared/cell-renderer/confidence-formatter/confidence-formatter.component';
import { ClassificationTagFormatterComponent } from 'src/app/common/shared/cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
import { ButtonFormatterComponent } from 'src/app/common/shared/cell-renderer/button-formatter/button-formatter.component';
import { RuleCountFormatterComponent } from 'src/app/common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component';
import { MeasuredDQRuleFormatterComponent } from 'src/app/common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
 
import { event, param } from 'jquery';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ConfidenceLevelFormatterComponent } from 'src/app/common/shared/cell-renderer/confidence-level-formatter/confidence-level-formatter.component';
declare var $: any;
const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{  
  return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;
}

const confidenceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  value === 'L' ? `<div class"row align-items-center"><div class="col-md-auto"><label class="priorityCell low" title='Low'>Low</label></div></div>` :
    value === 'H' ? `<div class"row align-items-center"><div class="col-md-auto"><label class="priorityCell high" title='High'>High</label></div></div>` :
      `<div class"row align-items-center"><div class="col-md-auto"><label class="priorityCell medium" title='Medium'>Medium</label></div></div>`;

@Component({
  selector: 'zetta-zs-cl-view-results',
  templateUrl: './zs-cl-view-results.component.html',
  styleUrls: ['./zs-cl-view-results.component.scss']
})

export class ZsClViewResultsComponent implements OnInit {
  public leftSidePaneTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public rightSidePanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  loggedInUserDetails: [];
  public leftSidePanelDS: any = [];
  public rightSidePanelDS: any = [];
  public leftPanelGridObject: AngularGridInstance;
  public rightPanelGridObject: AngularGridInstance;
  public leftPanelGridColumnsSettings: object = [];
  public rightPanelGridColumnsSettings: object = [];
  public leftSidePanelOptions: object = {};
  public rightSidePanelOptions: object = {};
  public isLeftLoading = true;
  public isRightLoading = false;
  public hasScrolled = false;
  columnApi: any;
  isLoading = true;
  total = 0;
  grandTotal = 0;
  subTotal = '';
  topPanel=false;
  totalLeft = 0;
  pageLeft = 1;
  limitLeft = 100;
  totalRight = 0;
  pageRight = 1;
  limitRight = 20;
  limit = 20;
  noData = false;
  initRightGrid = true;
	showSummaryModal : boolean = false;
  totalPredictionData = 0
  projectDetail = new Project();
  projectDetailInfo: object = {};
  leftGridSelection: string;
  selectedMappedColumn: number;
  showmodelpopup = false;
  showDownvoteModal: boolean = false;
  taskId: number;
  classInfo: any;
  datasetId: any;
  dsName
  datasetColumnId: number;
  screen_name = "VIEW_RESULTS";
  rowData:any;
  selectedRowInd: string = '';
  classes: any;
  selectedName: any;
  hasName=false;
  gridApi: any;
  columnDefs: ColDef[] = [];
  rightSidecolumnDefs:ColDef[]=[];
  defaultColDef: ColDef = {}
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  // totalPages = 1;
  subTotalRecords = 0;
  tableSettings: any;
  apiUrl: string = environment.config.API_URL;
  page: any;
  queryParams: any;
  queryParamsValue: any = null;
  classFilter: any; 
  modalClasses = [];
  isClassifyProjectRanOnce: boolean = false;
  isClassifyProjectUser: boolean = false;
  showClassifierPredictionGrid: boolean = true; // Always be active. only changed when user navigates to result summary from view results screen
  showResultsListModal: boolean = false;
  resultDetail: object = {};
  classifyProjectId = null ;
  recordId ;
  confidenceValue: number;
  isAdmin: boolean = false;
  fixingDataAvailable: boolean = false;
	trainModelDataAvailable: boolean = false;

  constructor(public ZsClContentService: ZsClContentService,
    public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private datasetSvc: DatasetService,
    private _router : Router,
    private messageSvc: MessageService,
    private http : HttpClient) { }

  ngOnInit() {
 
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
      
    });
    this.activatedRoute.queryParams.subscribe(fieldKey => {
      this.queryParamsValue = fieldKey['class'];  
      this.queryParamsValue = [{
        resultFilterKey: 'class',
        resultFilterVal: this.queryParamsValue
      }] 
    });
    this.leftSidePaneTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults`;
    this.getClassifyRecords(this.pageLeft, this.limitLeft);
    this.leftSidePanelOptions = {
      enableGridMenu: false,
      frozenRow: -1,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true, 
      rowHeight: 34,
    };

    this.rightSidePanelOptions = {
      enableGridMenu: false,
      frozenRow: -1,
      enableAddRow: false,
      selectable: true,
      editable: false,
      autoEdit: false,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      rowHeight: 34,
    };

    this.intialiseGrid()
    this.getProjectDetails();
    this.getRightClassList();
    
     this.getClassifyProjectData();
     this.getGraphData();
  }

  getClassifyProjectData() {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.projectDetail.project_id
    };
    let self = this;
    this.ZsClContentService.getclassifyProject(data).subscribe((projectDetails) => {

        self.isClassifyProjectRanOnce = projectDetails && projectDetails.ever_executed ? projectDetails.ever_executed: false;
        if (projectDetails && projectDetails.users && projectDetails.users.length) {

          let classifyUsers = projectDetails.users.filter((classifyUser) => {
            return classifyUser.user_id === self.loggedInUserDetails['user_id'];
          })
          self.isClassifyProjectUser = classifyUsers && classifyUsers.length ? true: false;

        } else {
          self.isClassifyProjectUser = false;
        }
    }, (err) => {
        self.isClassifyProjectRanOnce = false;
        self.isClassifyProjectUser = false;
    })
  }

  receiveTotalRecords($event) {
  if (this.totalLeft === 0) {
    this.totalLeft = $event.subTotal;
  }
  this.isLeftLoading = false;
   this.subTotal = this.zettaUtils.getSubTotal(this.totalLeft, $event.subTotal);
  }

  onGridReady(grid) {
    this.agGrid = grid;
    this.columnApi = grid.columnApi; 
    this.gridApi = grid.api;   
  }

  onAgCellClick(cellParams) {
    this.isRightLoading = true;
    this.initRightGrid = true;
    const args = cellParams.args;
    this.leftPanelGridObject.gridService.setSelectedRow(args.row);
    const row = this.leftPanelGridObject.gridService.getDataItemByRowIndex(args.row);
    if(row) {
      this.selectedName = row.name;
      this.hasName=true;
      this.datasetId=row.dataset_id
    }
    const selectedRows = encodeURIComponent(row.id);  //encodeURIComponent is used to handle special characters in the string which being passed as parameters in URL
    this.getClassifyPrediction(selectedRows,this.datasetId);
  }
  
  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: 'Data set',
        field: 'dataset_name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField:'dataset_name',
        // maxWidth: 200,
        minWidth:110
      },
     {
        headerName: 'Id',
        field: 'id',  
        minWidth:100,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        
        cellClass: ["ag-cell--tomato-background", "ag-cell--monospace-font"],
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField:'id',
        // minWidth:70,
        // maxWidth: 78
        
      }, {
        headerName: 'Name',
        field: 'name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        // maxWidth:400,
        tooltipField:'name',
        minWidth: 120
      }, {
        headerName: 'Best Prediction',
        field: 'best_prediction',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        colId: 'class',
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        sortable: true,
        sort: 'best_prediction',
        cellClass: 'class',
        tooltipField:'best_prediction',
        minWidth: 140,
      },
      
      {
        headerName: 'Confidence Level',
        field: 'confidence',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellClass: 'cell-align-center-text',
        sortable: true,
        cellRendererFramework:ConfidenceFormatterComponent,  
        minWidth:145
      },
      
    ];
    this.rightSidecolumnDefs = [
      {
        headerName: 'Prediction',
        field: 'class',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField:'class',
        minWidth: 230,
        maxWidth: 300
      },
     {
        headerName: 'Confidence Level',
        field: 'confidence',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        sortable: false,
        cellRendererFramework:ConfidenceLevelFormatterComponent,  
        minWidth: 330,
        // maxWidth: 350
        
      },
      
    ];
    this.fsGridOptions = { 
      headerHeight: 45, 
      rowHeight: 30,
     
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    }; 

  }
 

  getClassifyRecords(pageno, limitLeft, serverFilter = "", serverSort = "") {
    // this.leftSidePaneTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults?pageno=${1}&pagesize=${limitLeft}`;

    // this.ZsClContentService.getClassifyRecords(
    //     this.loggedInUserDetails['user_id'],
    //     this.loggedInUserDetails['tenant_id'],
    //     this.projectDetail.project_id,
    //     pageno,
    //     limitLeft,
    //     serverFilter,
    //     serverSort
    // ).subscribe(data => {
    //     this.leftSidePanelDS = data['currentpage']
        
    //     if (this.leftSidePanelDS.length === 0) {
    //         this.noData = true;
    //     }
    //     this.isLeftLoading = false;
    //     if (pageno === 1) {
    //       this.totalLeft  = data['totalrecords'];          
    //     }
    //     this.totalPages = data['totalpages'];
    //     let concatedLeftRows = this.leftSidePanelDS;
    //     if (this.leftPanelGridObject) {
    //         concatedLeftRows = [...this.leftPanelGridObject.dataView.getItems(), ...this.leftSidePanelDS];
    //         this.leftSidePanelDS.forEach(element => {
    //             element.id = Math.random();
    //         });
    //         this.leftSidePanelDS = concatedLeftRows;
    //         this.leftPanelGridObject.dataView.setItems(this.leftSidePanelDS);
    //         this.leftPanelGridObject.gridService.setSelectedRows([]);

    //         if(pageno <data['totalpages']) {
    //           this.hasScrolled = false;
    //         }
    //     }
    //     console.log('component called: response here ', this.leftSidePanelDS)
    //     if(pageno > data['totalpages']) {        
    //       this.page = 1;
    //       this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults?pagination=true&pageno=${1}&pagesize=${limitLeft}`;
    //     }
    // }, err => {
    //     this.isLeftLoading = false;
    // });


}
onGridCreation(grid) {
  const parent = this;
  parent.leftPanelGridObject = grid;
  parent.leftPanelGridObject.slickGrid.onSort.subscribe((e, args) => {
    this.page = 1;
    this.hasScrolled = false;
  });
}
goToPage(n: number): void {
  this.page = n;
  this. getClassifyRecords(this.page, this.limitLeft);
}

  onNextLeft(): void {
    if (this.totalPages > this.page) {
    this.pageLeft++;
    this.getClassifyRecords(this.pageLeft, this.limitLeft);
  }
}
  
  onPrevLeft(): void {
    this.pageLeft--;
    if (this.pageLeft >= 1) {
      this.getClassifyRecords(this.pageLeft, this.limitLeft);
    }
  }
  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.grandTotal, this.limitLeft);
  }

 
  onLeftPanelRowSelection(cellParams) {
    this.isRightLoading = true;
    this.initRightGrid = true;
    const row = cellParams.data;
    if(row) {
      this.selectedName = row.name;
      this.hasName=true;
      this.datasetId=row.dataset_id
    }
    const selectedRows = encodeURIComponent(row.id);  //encodeURIComponent is used to handle special characters in the string which being passed as parameters in URL
    this.recordId = selectedRows;
    console.log('Record Id 228',this.recordId);
    this.getClassifyPrediction(selectedRows,this.datasetId);
  }

  getClassifyPrediction(selectedRow,selectedDatasetId) {
    this.rightSidePanelTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults?ids=${selectedRow}&dataset_id=${selectedDatasetId}`;

    this.isRightLoading = true;
    this.ZsClContentService.getClassifyPredictions(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id, selectedRow,selectedDatasetId).subscribe(data => {
      this.rightSidePanelDS = data['currentpage'];
      this.initRightGrid = false;

      if (this.rightSidePanelDS.length === 0) {
        this.noData = true;
      }
      this.rightSidePanelDS.forEach(element => {
        element.newId = element.id;
        element.score100 = (element.score * 100).toFixed();
        element.taskId = element.task.task_id;
      });
      this.isRightLoading = false;
      this.totalPredictionData = data['totalrecords'];
      if(this.rightSidePanelDS && this.rightSidePanelDS['currentpage']) {
        this.rightSidePanelDS['currentpage'].forEach(element => {
          element.id = Math.random();
        });
      }
      if (this.rightPanelGridObject && this.rightSidePanelDS && this.rightSidePanelDS['currentpage']) {
        this.rightPanelGridObject.dataView.setItems(this.rightSidePanelDS['currentpage']);
        this.rightPanelGridObject.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.rightSidePanelDS = { 'currentpage': [] };
      this.isRightLoading = false;
    });
  }
 onCellClick(params) {
  const { event, data, colDef } = params;
  const row = params.data; 

  if (colDef.field === 'confidence') {
    const iconClass = event.target.className;
    if (iconClass.includes('fa-thumbs-down') && iconClass.includes('fal')) {
      // Handle thumbs-down click
      this.showDownvoteModal = true;
      this.datasetColumnId = row.dataset_id; 
      this.dsName = row.class ? row.class : null;

      let dislikeData = {
        score: row.score*100 || "N/A", 
        id: row.id || "N/A",
        name: row.name,
        confidence: row.confidence,
        vote: 0,
        class: row.class,
        dataset_id: this.datasetId
      };
      
      this.classInfo = dislikeData;
      this.selectedRowInd = params.rowIndex; 
      this.rowData = row;
      this.classes = 'fa-thumbs-down fal';

    } else if (iconClass.includes('fa-thumbs-up') && iconClass.includes('fal')) {
      // Handle thumbs-up click
      const upVotedata = {
        dataset_id: this.datasetId,
        score: (row.score * 100),
        id: row.id,
        name: row.name,
        confidence: row.confidence,
        vote: 1,
        class: row.class
      };

      const payload = {
        user_id: this.loggedInUserDetails['user_id'],
        tenant_id: this.loggedInUserDetails['tenant_id'],
        projId: this.projectDetail.project_id,
        payload: upVotedata
      };

      let self = this;
      this.ZsClContentService.updateTaskFeedback(payload).subscribe(resp => {
        if (resp) {
          event.target.classList.remove('fal', 'fa-thumbs-up');
          event.target.classList.add('fas', 'fa-thumbs-up');
          $('#thumbs-down-' + params.rowIndex).removeClass('fas fa-thumbs-down');
          $('#thumbs-down-' + params.rowIndex).addClass('fal fa-thumbs-down');
          self.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(params.node.id);
        }
      }, (error) => {
        self.messageSvc.sendMessage({ message: error && error.error && error.error.message ? error.error.message : 'Something went wrong while saving feedback', type: 'ERROR', hideboard: true });
      });
    }
  }
}

/**
   * 
   * Revoking upvote access for non-selected or previously selected as we only allow 1 upvote
   *  for SOC project feedback per task. 
   */
revokeUpvoteAccessForOtherRecordSOCProjectFeedback(upvotedRow) {
  let totalRecords = this.rightSidePanelDS.length;
  for(let row =0; row < totalRecords; row++) {
    if (row != upvotedRow) {
      $('#thumbs-up-' + row).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + row).addClass('fal fa-thumbs-up');
    } 
  }
}
 

// onSortChangeData(event: any) { 
//   this.columnDefs.forEach((col, index) => {  
//     if (col.field === "best_prediction") {
//       if (this.gridApi) {
//         const sortingModel = this.gridApi.getSortModel(); 
//         console.log("sortingModel:", sortingModel)
//         if (sortingModel.length > 0) {
//           const sortModel = sortingModel[0];
//           let queryParams = '';
//           console.log("sortModel.field :", sortModel)
//           if (sortModel.colId === 'class') { 
//             queryParams = '?orderby=best_prediction&type=' + sortModel.sort;
//             console.log("queryParams:", queryParams)
//           } else   { 
//             queryParams = '?orderby=class&type=' + sortModel.sort;
//           }
//           queryParams += '&paginate=true&pageno=1&pagesize=100'; 
//           // Update query parameters
//           console.log("queryParams updated:", queryParams)
//           this.updateQueryParams(queryParams);
//         }
//       }
//     }
//   });
// }

updateQueryParams(queryParams: string) { 
  this._router.navigate([], { queryParams: { sortBy: queryParams }, queryParamsHandling: 'merge' });
}
  onShowPanel(event){
   this.topPanel = event;
  }

  closeModalPopup(event) {
    
    if (event) {
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      if(event.target) {
        event.target.classList.remove('fal', 'fa-thumbs-down');
        event.target.classList.add('fas', 'fa-thumbs-down');
      }
    }
    this.showDownvoteModal = false
  }

  downVoted(event) {
    if(event) {
     if(this.classes === 'fas fa-thumbs-down') {
        $('#thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
        $('#thumbs-down-' + this.rowData).addClass('fal fa-thumbs-down');
     } else {
      if(this.rowData && this.rowData.args && this.rowData.args.row) {
        $('#thumbs-down-' + this.rowData.args.row).removeClass('fal fa-thumbs-down');
        $('#thumbs-down-' + this.rowData.args.row).addClass('fas fa-thumbs-down');
        $('#thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.rowData).addClass('fal fa-thumbs-up');
      } else {
        $('#thumbs-down-' + this.selectedRowInd).removeClass('fal fa-thumbs-down');
        $('#thumbs-down-' + this.selectedRowInd).addClass('fas fa-thumbs-down');
        $('#thumbs-up-' + this.selectedRowInd).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.selectedRowInd).addClass('fal fa-thumbs-up');
      }
    }
    }
  }


  popupoutput(message) {
    this.showmodelpopup = message;
  }

  onLeftGridCreation(leftgrid) {
    this.leftPanelGridObject = leftgrid;
  }

  onRightGridCreation(rightgrid) {
    this.rightPanelGridObject = rightgrid;
  }


  onRunModel(){
    const payload ={
      classify_project_id:this.projectDetail.project_id,
      classify_picker:false,
      supervised: true
    };
    this.ZsClContentService.runModelClassifyProject(payload).subscribe(resp =>{
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.projectDetail.project_id}`});
    },error=>{
    });
  }

  onExport() {
    //  window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults?export=true`,'_self'); 
    this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/viewresults?export=true`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `view_results_${this.projectDetail.project_id}`);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  onPublish() {
    this.datasetSvc.runModel416(this.projectDetail.project_id,this.loggedInUserDetails['user_id']).subscribe((result) => {      
			this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Classify-Project', messageHeader: 'Publish Results in Progress', 
										  messageText: 'The process to Publish Results has been triggered. You can see job status and monitor progress for Classify Projects jobs in the System Jobs screen'});                         
		},error=>{
			this.messageSvc.sendMessage({message: 'Algo 416 call has failed', type: 'ERROR', hideboard: true});
		});  
  }

  onBack() {
    this._router.navigate(['/zs-cl/projects/', this.projectDetail.project_id ],{ queryParamsHandling: 'merge', queryParams: { class: null } });                 
  }

  onTrainModel() {
    this._router.navigate(['/zs-cl/projects/', this.projectDetail.project_id,'train-model' ],{ queryParamsHandling: 'merge', queryParams: { class: null } });                 
  }

  onFixIssues() {
    this._router.navigate(['/zs-cl/projects/', this.projectDetail.project_id,'fix-issues' ], { queryParamsHandling: 'merge', queryParams: { class: null } });
  }

  showResultSummaryModal() {
		this.showSummaryModal = true;
	}
	onCancel() {
	  this.showSummaryModal = false;
	}
	hideConfigModal(eve) {
	  this.showSummaryModal = eve;
}

  onReloadSearchedClass(event) {
    /**
     * Setting false as class selected from result summary to be filtered in view results grid. 
     */
    this.showClassifierPredictionGrid = false;
    let self = this;
    setTimeout(()=>{
      self.showClassifierPredictionGrid = true;
      }, 200)
  }

  getRightClassList() {
		this.ZsClContentService.getClassifierClassList(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id)
		.subscribe(classes => {
		  if(classes) {
			this.modalClasses = classes;
		  }
		}, err => {
		});
	  }

    getProjectDetails() {
        const data = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          project_id: this.projectDetail.project_id
        };
        this.ZsClContentService.getclassifyProject(data)
        .subscribe(res => {
          this.projectDetailInfo = res;
		      this.isAdmin = this.checkForAdminAccess(this.projectDetailInfo);
        }, err => {
          this.isAdmin = false;
        }
      );
    }

    checkForAdminAccess(info) {
      if(info && this.loggedInUserDetails) {
        let loggedInUser =   (info && info['users'] && info['users'].length) ? info['users'].find(user => user['user_id'] == this.loggedInUserDetails['user_id']) : null;
        if(loggedInUser) {
          let isAdmin = loggedInUser.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_ADMIN);
          return isAdmin == AppGlobals.CLASSIFY_PROJECT_ADMIN ? true: false;
        }
      }
      return false;
    }

  getTotalRecordCountOfClassifiedRecords() {
    return this.zettaUtils.currencyFormat(this.totalLeft);
  }

  onErrorLoadingClassifiedRecords(errorObj) {
    this.isLeftLoading = false;
  }

  triggerClassifyReslutsModal() {
    this.showResultsListModal = true;
  }

  hideClassifyResultsModal(eve) {
    this.showResultsListModal = eve;
  }

  getClassifySummaryData(summaryData) {
    if(summaryData && summaryData.length) {
      summaryData.forEach(element => {
        if(element.name === 'overall_confidence') {
          this.confidenceValue = element.value;
        }
      });
    }
  }

  getGraphData() {
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			tenant_id: this.loggedInUserDetails['tenant_id'],
			project_id: this.projectDetail.project_id
		  }
		this.ZsClContentService.getClassifyProjGraphSummary(data)
    .subscribe(res => {
			if(res) {
				res.forEach((e: any) => {
					if (e['name'] === 'training_tasks_count_to_be_reviewed') {
						if(e.value && e.value > 0) {
							this.trainModelDataAvailable = true;
							this.fixingDataAvailable = false;
						}
					} else if (e['name'] === 'training_tasks_type_fixing_count_to_be_reviewed') {
						if(e.value > 0) {
						this.fixingDataAvailable = true;
						this.trainModelDataAvailable = false;
					}
					}
				  });
				}
			});
	}
}