import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";

import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ProjectService } from '../project.service';
import { ContentService } from '../../content.service';

import { Project } from '../project.model';
import { MessageService } from '../../../../common/components/message/message.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AppGlobals } from '../../../../common/shared/app.globals';

import { environment } from 'src/environments/environment';
declare var $: any;

const AttributeUsageFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  let options = '';
  let data = columnDef.params.list;
  let defaultSystemAttributes = [AppGlobals.SOURCE_SYSTEM, AppGlobals.IDENTIFIER_AT_SOURCE, AppGlobals.SOURCE_SYSTEM_IDENTIFIER,AppGlobals.LAST_RECORED_UPDATED_DATE,AppGlobals.SOURCE_PRIMARY_KEY, AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY];
  if (dataContext.isHeader) {
    options = `<option value="">Select Attribute Usage</option>`;
    if (dataContext.enableDropdown) {
      data && data.forEach((val) => {
        let selectedDd = dataContext.group_selected_attr_usage_id && dataContext.group_selected_attr_usage_id == val.lookup_id ? 'selected="true"' : ' ';
        options = options + `<option ${selectedDd} value=${val.lookup_id} > ${val.name} </option>`;
      })
      return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;" 
                id='attribute_usage-${row}' >
                  ${options}
               </select>`);
    } else {
      return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
                id='attribute_usage-${row}' disabled=${dataContext.enableDropdown}>
                  ${options}
               </select>`);
    }
  }

  if (columnDef.params.list.length >= 0) {
    data && data.forEach((val) => {
      if ((dataContext.attr_usage_id && dataContext.attr_usage_id == val.lookup_id) || (!dataContext.attr_usage_id && val.lookup_id == AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE)) {
        options = options + `<option selected=${true} value=${val.lookup_id} > ${val.name} </option>`;
      } else {
        options = options + `<option value=${val.lookup_id} > ${val.name} </option>`;
      }
    })
  }

  if ((!dataContext.isHeader) && ((dataContext && dataContext.is_system) || (defaultSystemAttributes.indexOf(dataContext.logical_name) > -1))) {
    return (`<select disabled=${true} style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
    id='${columnDef.field}-${row}'">
        ${options}
    </select>`);
  }

  return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
            id='${columnDef.field}-${row}'">
                ${options}
            </select>`);
}

const MergingStrategyFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  
   
  let data = columnDef.params.list;
  let isHeader = dataContext.isHeader;
  let defaultSystemAttributes = [AppGlobals.SOURCE_SYSTEM, AppGlobals.IDENTIFIER_AT_SOURCE,AppGlobals.SOURCE_SYSTEM_IDENTIFIER,AppGlobals.LAST_RECORED_UPDATED_DATE,AppGlobals.SOURCE_PRIMARY_KEY, AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY];
  let options = isHeader ? `<option value="">Select Merging Strategy</option>` : '';
  let disableSelect = '';

  if (isHeader && (!dataContext.enableDropdown || (dataContext.enableDropdown && dataContext.group_selected_attr_usage_id == ""))) {
    // return select with disable state
    disableSelect = `disabled="true"`
  }

  function isNotApplicable() {
    if ( isHeader && ((dataContext.group_selected_attr_usage_id == AppGlobals.RESOLVE_MATCH_ONLY_LOOKUP_ID) || (dataContext.group_selected_attr_usage_id == AppGlobals.RESOLVE_EXCLUDE_MATCH_AND_MERGE))) {
      return true;
    }
    if (!isHeader && ((dataContext.attr_usage_id == AppGlobals.RESOLVE_MATCH_ONLY_LOOKUP_ID) || (dataContext.attr_usage_id == AppGlobals.RESOLVE_EXCLUDE_MATCH_AND_MERGE))) {
      return true;
    }
    return false;
  }

  if (isNotApplicable()) {
    return `<div style="text-align: center;">
              <span style="font-family:Inter Regular;">Not Applicable</span>
            </div>`;
  }

  data.forEach((val) => {
    if (val.lookup_id != AppGlobals.RESOLVE_MERGING_STRATEGY_NOT_APPLICABLE) {
       let isHeaderDefaultCheck = (isHeader && dataContext.group_selected_merging_strategy_id && dataContext.group_selected_merging_strategy_id == val.lookup_id);
       let rowDefaultCheck = !isHeader && ((!dataContext.merger_strategy_id && val.lookup_id == AppGlobals.RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID) ||
       (dataContext.merger_strategy_id == val.lookup_id));

      let isDefault = (isHeaderDefaultCheck || rowDefaultCheck) ? `selected="true"` : '';
  
      options = options + `<option ${isDefault} value=${val.lookup_id} > ${val.name} </option>`;
    }
  })

  let id= `merging-strategy-${row}`;

  if ((dataContext && dataContext.is_system) || (defaultSystemAttributes.indexOf(dataContext.logical_name) > -1)) {
    return (`<div id="merger-strt-cont-${row}">
                <select disabled=${true} style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 17vw;color: #757575;outline: 0;"
                  id=${id}>
                      ${options}
                 </select> 
          </div>`);
  }

  return (`<div id="merger-strt-cont-${row}">
                <select ${disableSelect} style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 17vw;color: #757575;outline: 0;"
                  id=${id}>
                      ${options}
                 </select> 
          </div>`);
}

const ProjectDatasetFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  let options = '';

  if (dataContext.isHeader) {
    options = `<option value="">Select Project Dataset</option>`;
    if (dataContext.enableDropdown) {
      let standardValue = ((dataContext.group_selected_attr_usage_id == AppGlobals.RESOLVE_MERGE_ONLY_LOOKUP_ID || dataContext.group_selected_attr_usage_id == AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE) &&
        (dataContext.group_selected_merging_strategy_id == AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE));
      if (standardValue) {

        let data = columnDef.params.list;
        data.forEach((val) => {
          let selectedDd = dataContext.group_selected_dataset_id && dataContext.group_selected_dataset_id == val.dataset_id ? 'selected="true"' : ' ';
          options = options + `<option ${selectedDd} value=${val.dataset_id} > ${val.name} </option>`;
        })
        return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
                  id='project_dataset_id-${row}' >
                      ${options}
                </select>`);
      } else {
        return '';
      }
    } else {
      return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
                id='project_dataset_id-${row}' disabled=${dataContext.enableDropdown}>
                    ${options}
              </select>`);
    }
  }

  if (columnDef.params.list.length >= 0) {
    let data = columnDef.params.list;
    // only if below standardValue is true then show dropdown else don't show
    let standardValue = ((dataContext.attr_usage_id == AppGlobals.RESOLVE_MERGE_ONLY_LOOKUP_ID || dataContext.attr_usage_id == AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE) &&
      (dataContext.merger_strategy_id == AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE));

    if (standardValue) {
      // show the dropdown
      let defaultValue = dataContext.dataset_id ? dataContext.dataset_id : null;
      options = `<option value="">Select Project Dataset</option>`;
      data.forEach((val) => {
        if (defaultValue && val.dataset_id == defaultValue) {
          options = options + `<option selected=${true} value=${val.dataset_id} > ${val.name} </option>`;
        } else {
          options = options + `<option value=${val.dataset_id} > ${val.name} </option>`;
        }
      });
      return (`<select style="height: 22px;padding-left: 5px;border-radius: 3px;border: 1px solid #e6e6e6;width: 14vw;color: #757575;outline: 0;"
                id='project_dataset_id-${row}'">
                 ${options}
              </select>`);
    } else {
      return '';
    }
  } else {
    return '';
  }
}

const DataTypeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  let dataType = '';
  if (row == 0) {
    return dataType = `<span style="display: flex;flex-direction: row;justify-content: right;">
                      ${dataContext.data_type}
                  </span>`;
  }
  dataType = `<span> ${dataContext.data_type} </span>`;
  return dataType;
}

const AttributeIdFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  let dataType = '';
  return dataType = `<div class="text-right mr-3">
                          ${dataContext.attribute_id}
                      </div>`;
}

@Component({
  selector: 'zetta-entity-model-project',
  templateUrl: './entity-model-project.component.html',
  styleUrls: ['./entity-model-project.component.scss']
})

export class EntityModelProjectComponent implements OnInit {

  showBreadCrumb: boolean = false;
  projectEntityModelGridTableSettings: object = { 'height': "100%", 'width': "99.70%" };
  taskGridOptions: any;
  projectEntityModelGridColDef: any;
  EntityModelData: any;
  progressState = new ProjectStatus();
  projectDetail: Project = new Project();
  isEditFlow: boolean;
  attributeUsageData: Array<Object> = [];
  mergingStrategyData: Array<Object> = [];
  projectDatasetData: Array<Object> = [];
  entityModelGridInstance: AngularGridInstance;
  userMappedProjectDataset: Array<Object> = [];
  isEntityDataFetched: boolean = false;
  entityName: string = '';
  catalogName: string = '';
  attributeCount: number = 0;
  entityId: string = null;
  enableHeaderDropdown: boolean = false;
  userId: string = null;
  modeState: any = {};
  showLoadingSpinner: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private projectService: ProjectService, private router: Router,
    private zettaUtils: ZettaUtils, private contentSvc: ContentService, private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.projectDetail.project_id = params['id'];
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.showBreadCrumb = params['showBreadcrumb'] && params['showBreadcrumb'] === 'true' ? true : false;
    });

    this.setUpProjectProgress();
    this.setUpProjectMode();

    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: false,
      enableFiltering: true,
      enableRowSelection: false,
      checkboxSelector: true,
      rowHeight: 34
    };
    this.attributeUsageData = [];
    let userInfo = this.zettaUtils.getLoggedInUserInfo();
    this.userId = userInfo.user_id;
    this.retrieveProjectDetails(userInfo.user_id, this.projectDetail.project_id, this);
  }

  initColumnDef(): void {
    this.projectEntityModelGridColDef = [{
      'displayname': 'Attr. ID',
      'physicalname': 'attribute_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'formatter': AttributeIdFormatter,
    }, {
      'displayname': 'Logical Name',
      'physicalname': 'logical_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
    },
    {
      'displayname': 'Description',
      'physicalname': 'description',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
    },
    {
      'displayname': 'Data type',
      'physicalname': 'data_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'formatter': DataTypeFormatter,
    },
    {
      'displayname': 'Attribute Usage',
      'physicalname': 'attribute_usage',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'formatter': AttributeUsageFormatter,
      'params': { list: this.attributeUsageData, enableDropdown: this.enableHeaderDropdown },
      'columnGroup': 'Test'
    },
    {
      'displayname': 'Merging Strategy',
      'physicalname': 'merging_strategy',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'width': 170,
      'minWidth': 160,
      'formatter': MergingStrategyFormatter,
      'params': { list: this.mergingStrategyData },
    },
    {
      'displayname': 'Optional source',
      'physicalname': 'project_dataset',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'formatter': ProjectDatasetFormatter,
      'params': { list: this.projectDatasetData },
    }];
  }

  setUpProjectProgress(): void {
    this.progressState.states = this.projectService.getProjectStateList();
    this.progressState.currentStateIndex = 3;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Project';
    this.progressState.isEdit = (this.projectDetail.project_id !== undefined && this.projectDetail.project_id !== null) ? true : false;
    this.progressState.isEdit = sessionStorage.getItem('newProject') === 'true' ? false : this.progressState.isEdit;
  }

  setUpProjectMode(): void {
    if (!this.showBreadCrumb) {
      // Remove BreadCrumb
      $('#breadCrumb').addClass('d-none');
      this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
    } else {
      this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
    }
  }

  onGridCellClicked(args): void {
    const tempArgs = args.args;
    let metadata = this.entityModelGridInstance.gridService.getColumnFromEventArguments(tempArgs);
    let row = tempArgs.row;
    const fieldName = metadata.columnDef.field;
    let self = this;

    switch (fieldName) {
      case 'attribute_usage':
        $(`#attribute_usage-${row}`).on('change', function (e) {
          self.onAttributeUsageDropDownSelected(row);
        })
        break;
      case 'merging_strategy':
        $(`#merging-strategy-${row}`).on('change', function (e) {
          self.onMergingStrategyDropdownSelected(row);
        })
        break;
      case "project_dataset":
        $(`#project_dataset_id-${row}`).on('change', function (e) {
          self.onProjectDatasetDropdownSelected(row);
        });
        break;
      default: break;
    }
  }

  onAttributeUsageDropDownSelected(selectedRow) {
    let isHeaderRow = selectedRow == 0 ? true : false;
    let selectedAttributeLookUpId = $('#attribute_usage-' + selectedRow)[0]['value'];
    if (isHeaderRow) {
      let selectedAttrRows = this.entityModelGridInstance.gridService.getSelectedRows();
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if (index == 0) {
          val.group_selected_attr_usage_id = selectedAttributeLookUpId;
        }
        if (selectedAttrRows.indexOf(index) > -1) {
          val.attr_usage_id = selectedAttributeLookUpId;
        }
        return val;
      });
      this.refreshEntityModelGrid(this, this.EntityModelData, selectedAttrRows, null);

    } else {
      let selectedAttributeLookUpId = $('#attribute_usage-' + selectedRow)[0]['value'];
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if (index == selectedRow) {
          val.attr_usage_id = selectedAttributeLookUpId;
        }
        return val;
      });
      this.refreshEntityModelGrid(this, this.EntityModelData, null, selectedRow);
    }
  }

  onMergingStrategyDropdownSelected(selectedRow) {
    let isHeaderRow = selectedRow == 0 ? true : false;
    let selectedMergerStrategyLookUpId = $('#merging-strategy-' + selectedRow)[0]['value'];
    if (isHeaderRow) {
      let selectedMergerStrategyRows = this.entityModelGridInstance.gridService.getSelectedRows();
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if (index == 0) {
          val.group_selected_merging_strategy_id = selectedMergerStrategyLookUpId;
        }

        if (selectedMergerStrategyRows.indexOf(index) > -1) {
          val.merger_strategy_id = selectedMergerStrategyLookUpId;
        }
        if (val.merger_strategy_id != AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE) {
          val.dataset_id = null;
        }
        return val;
      });

      this.refreshEntityModelGrid(this, this.EntityModelData, selectedMergerStrategyRows, null);

    } else {
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if (index == selectedRow) {
          val.merger_strategy_id = selectedMergerStrategyLookUpId;
        }
        if (val.merger_strategy_id != AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE) {
          val.dataset_id = null;
        }
        return val;
      });
      this.refreshEntityModelGrid(this, this.EntityModelData, null, selectedRow);
    }
  }

  onProjectDatasetDropdownSelected(selectedRow) {
    // Handle datasetId
    let isHeaderRow = selectedRow == 0 ? true : false;
    let projectDatasetLookupId = $('#project_dataset_id-' + selectedRow)[0]['value'];
    if (isHeaderRow) {
      let selectedProjectDatasetRows = this.entityModelGridInstance.gridService.getSelectedRows();
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if (index == 0 && index == selectedRow) {
          val.group_selected_dataset_id = projectDatasetLookupId;
        }
        if ((selectedProjectDatasetRows.indexOf(index) > -1) && (val.merger_strategy_id == AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE)) {
          val.dataset_id = projectDatasetLookupId;
        }
        return val;
      });
      this.refreshEntityModelGrid(this, this.EntityModelData, selectedProjectDatasetRows, null);
    } else {
      this.EntityModelData = this.EntityModelData.map((val, index) => {
        if ((index == selectedRow) && (val.merger_strategy_id == AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE)) {
          val.dataset_id = projectDatasetLookupId;
        }
        return val;
      });
      this.refreshEntityModelGrid(this, this.EntityModelData, null, selectedRow);
    }
  }

  refreshEntityModelGrid(self, entityModelData, isMultipleRowUpdate, row) {

    self.entityModelGridInstance.dataView.beginUpdate();
    self.entityModelGridInstance.dataView.setItems([]);
    self.entityModelGridInstance.dataView.setItems(entityModelData);
    self.entityModelGridInstance.dataView.endUpdate();
    self.entityModelGridInstance.dataView.refresh();
    if (!isMultipleRowUpdate) {
      self.entityModelGridInstance.slickGrid.invalidateRow(row);
      self.entityModelGridInstance.slickGrid.render();
    } else {
      self.entityModelGridInstance.slickGrid.invalidate();
      self.entityModelGridInstance.slickGrid.render();
    }
  }

  onEntityModelGridCreated(grid): void {
    this.entityModelGridInstance = grid;
    const self = this;
    this.entityModelGridInstance.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      let selectedRows = args.rows;
      if (selectedRows.length > 1) {
        self.EntityModelData = self.EntityModelData.map((val, index) => {
          if (index == 0) {
            val.enableDropdown = true;
          }
          return val;
        });
      } else {
        self.EntityModelData = self.EntityModelData.map((val, index) => {
          if (index == 0) {
            val.enableDropdown = false;
          }
          return val;
        });
      }
      self.refreshEntityModelGrid(self, self.EntityModelData, null, 0);
    });
  }

  onEntityModelDataSorted(sortObj) {
    let sortedEntityAttributeData = {
      entityAttributeData: sortObj,
      resolveAttributeUsageData: this.attributeUsageData,
      resolveMergingStrategyData: this.mergingStrategyData
    }
    this.parseEntityAttributeData(sortedEntityAttributeData, true);
  }

  onScroll(): void {

  }

  getDefaultGroupSelectionHeaderRow(attributeUsageData, mergingStrategyData) {
    let tempObj = {
      id: 0,
      attribute_id: ' ',
      logical_name: ' ',
      description: ' ',
      data_type: 'Bulk Selections: ',
      attribute_usage: attributeUsageData,
      merging_strategy: mergingStrategyData,
      project_dataset: this.projectDatasetData,
      shouldDisable: true, // hide checkbox,
      isHeader: true,
      enableDropdown: false,
      group_selected_attr_usage_id: ''
    }
    return tempObj;
  }

  onPreviousBtnClick(): void {
    this.router.navigate(['/zs/projects', this.projectDetail.project_id,
      'datasets'], { queryParams: { showBreadcrumb: this.showBreadCrumb }, queryParamsHandling:'merge' });
  }

  onCancelBtnClick(): void {
    if (!this.showBreadCrumb) {
      this.router.navigate(['/zs/projects']);
    } else {
      this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
    }
  }

  retrieveProjectDetails(userId: string, projectId: string, self, isCalledFromFilter?): void {

    this.showLoadingSpinner = true;
    this.projectService.getProject(userId, projectId).subscribe((projectDetailsResponse) => {
      self.userMappedProjectDataset = projectDetailsResponse.datasets && projectDetailsResponse.datasets.length > 0 ? projectDetailsResponse.datasets : [];
      self.entityName = projectDetailsResponse.entity_name ? projectDetailsResponse.entity_name : '';
      self.catalogName = projectDetailsResponse.catalog_name ? projectDetailsResponse.catalog_name : '';
      self.entityId = projectDetailsResponse.entity_id ? projectDetailsResponse.entity_id : null;
      self.projectDatasetData = projectDetailsResponse.datasets ? projectDetailsResponse.datasets : [];

      if (projectDetailsResponse.entity_id) {
        this.projectService.getProjectEntityAttributeData(projectDetailsResponse.entity_id, this.projectDetail.project_id).subscribe((resp) => {
          this.showLoadingSpinner = false;
          this.parseEntityAttributeData(resp, isCalledFromFilter);
        }, (err) => {
          this.showLoadingSpinner = false;
          let errMsg = 'Something went wrong while retrieving entity details';
          this.showError(errMsg);
        });
      } else {
        this.showLoadingSpinner = false;
        let errMsg = 'No Entity for the given project';
        this.showError(errMsg);
      }
    }, (error) => {
      this.showLoadingSpinner = false;
      let errMsg = 'Something went wrong while retrieving project details';
      this.showError(errMsg);
    });
  }

  parseEntityAttributeData(entityAttributeArrResponse, isSearchUsage?) {
    if (entityAttributeArrResponse.entityAttributeData.length) {
      entityAttributeArrResponse.entityAttributeData = entityAttributeArrResponse.entityAttributeData.filter(attr => attr.is_mappable);
    }
    entityAttributeArrResponse.entityAttributeData = [this.getDefaultGroupSelectionHeaderRow(entityAttributeArrResponse.resolveAttributeUsageData, entityAttributeArrResponse.resolveMergingStrategyData)].concat(entityAttributeArrResponse.entityAttributeData);
    let entityAttribute: Array<Object> = entityAttributeArrResponse.entityAttributeData.map((val, index) => {
      val.id = index;
      //val.attr_usage_id = !val.attr_usage_id ? AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE : val.attr_usage_id;
      //val.merger_strategy_id = !val.merger_strategy_id ? AppGlobals.RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID : val.merger_strategy_id;
      val.attr_usage_id = this.getAttributeUsagData(val);
      val.merger_strategy_id = this.getMergingStrategyData(val);
      val.shouldDisable = ((val.is_system) || (this.zettaUtils.isAttributeSystemAttribute(val.logical_name))) ? true : false;
      return val;
    });
    this.attributeUsageData = entityAttributeArrResponse.resolveAttributeUsageData;
    this.mergingStrategyData = entityAttributeArrResponse.resolveMergingStrategyData;

    this.EntityModelData = entityAttribute;
    this.attributeCount = this.EntityModelData.length - 1; //  subtracting 1 due to extra header row included
    if (!isSearchUsage) {
      this.projectEntityModelGridTableSettings['search_url'] = `${environment.config.API_URL}/entities/${this.entityId}/attributes?project=${this.projectDetail.project_id}`;
      this.initEntityGrid();
    } else {
      this.refreshEntityModelGrid(this, this.EntityModelData, [], null);
    }
  }

  getAttributeUsagData(data) {
    if (data && ((data.is_system) || (this.zettaUtils.isAttributeSystemAttribute(data.logical_name)))) {

      if (data.logical_name === AppGlobals.SOURCE_SYSTEM) {
        return AppGlobals.RESOLVE_EXCLUDE_MATCH_AND_MERGE;
      }

      if (data.logical_name === AppGlobals.SOURCE_PRIMARY_KEY || data.logical_name === AppGlobals.IDENTIFIER_AT_SOURCE || data.logical_name === AppGlobals.SOURCE_SYSTEM_IDENTIFIER 
        || data.logical_name === AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY) {
        return AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE;
      }

      if (data.logical_name === AppGlobals.LAST_RECORED_UPDATED_DATE) {
        return AppGlobals.RESOLVE_MERGE_ONLY_LOOKUP_ID;
      }
    }

    return data && data.attr_usage_id ? data.attr_usage_id : AppGlobals.RESOLVE_BOTH_MATCH_AND_MERGE;
  }

  getMergingStrategyData(data) {
    if (data && ((data.is_system) || (this.zettaUtils.isAttributeSystemAttribute(data.logical_name)))) {

      if (data.logical_name === AppGlobals.SOURCE_SYSTEM) {
        return AppGlobals.RESOLVE_MERGING_STRATEGY_NOT_APPLICABLE;
      }

      if (data.logical_name === AppGlobals.SOURCE_PRIMARY_KEY || data.logical_name === AppGlobals.IDENTIFIER_AT_SOURCE || data.logical_name === AppGlobals.SOURCE_SYSTEM_IDENTIFIER
        || data.logical_name === AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY) {
        return AppGlobals.RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID;
      }

      if (data.logical_name === AppGlobals.LAST_RECORED_UPDATED_DATE) {
        return AppGlobals.RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID;
      }

    }
    return data && data.merger_strategy_id ? data.merger_strategy_id : AppGlobals.RESOLVE_MERGING_STRATEGY_LET_SYSTEM_SELECT_VALUE_ID;
  }

  initEntityGrid() {
    this.isEntityDataFetched = true;
    this.initColumnDef();
  }

  isValidData() {
    if (this.isEntityDataFetched) {
      let selectedData = this.EntityModelData.filter((val, index) => {
        let isProjectDatasetApplicable = val.merger_strategy_id == AppGlobals.RESOLVE_MERGING_STRATEGY_ALWAYS_SELECT_FROM_SOURCE;
        let is_dataset_check_passed = ((!isProjectDatasetApplicable) || (isProjectDatasetApplicable && val.dataset_id)) ? true : false
        return (val.attr_usage_id) && (val.merger_strategy_id)
          && (is_dataset_check_passed);
      });
      if (selectedData.length == this.EntityModelData.length) {
        return true;
      }
    }
    return false;
  }

  isMergerStrategyNotApplicable(attribute_usage_id) {
     if ((attribute_usage_id == AppGlobals.RESOLVE_MATCH_ONLY_LOOKUP_ID) || (attribute_usage_id == AppGlobals.RESOLVE_EXCLUDE_MATCH_AND_MERGE)) {
        return true;
     }
     return false;
  }

  saveProjectEntityAttribute(): void {
    this.showLoadingSpinner = true;
    let payload = this.EntityModelData.map((val, index) => {
      return {
        project_id: this.projectDetail.project_id,
        entity_id: this.entityId,
        attribute_id: val.attribute_id,
        dataset_id: val.dataset_id,
        attribute_usage_lookup_id: val.attr_usage_id,
        merger_strategy_lookup_id: this.isMergerStrategyNotApplicable(val.attr_usage_id) ? AppGlobals.RESOLVE_MERGING_STRATEGY_NOT_APPLICABLE : val.merger_strategy_id
      }
    });
    payload.shift();// remove header row
    let self = this;
    this.projectService.saveProjectEntityAttribute(this.userId, this.projectDetail.project_id, this.entityId, payload)
      .subscribe((resp) => {
        this.showLoadingSpinner = false;
        self.navigateToAttributeScreen();
      }, (err) => {
        this.showLoadingSpinner = false;
        self.showError(err);
      })
  }

  navigateToAttributeScreen() {
    this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'attributes'],
      { queryParams: { showBreadcrumb: this.showBreadCrumb }, queryParamsHandling: 'merge' });
  }

  showError(errMsg: string) {
    // Handle error here
    this.messageService.sendMessage({ message: errMsg, type: 'ERROR', hideboard: true });
  }

  onEntitySearch(searchObj) {
    let items = searchObj;
    let temp = {
      entityAttributeData: items.map((val, index) => {
        val.id = index + 1
        return val;
      }),
      resolveAttributeUsageData: this.attributeUsageData,
      resolveMergingStrategyData: this.mergingStrategyData
    }
    this.parseEntityAttributeData(temp, true);
  }

  onFilterCleared() {
    this.retrieveProjectDetails(this.userId, this.projectDetail.project_id, this, true);
  }
}
