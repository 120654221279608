import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../content.service';

@Component({
  selector: 'zetta-records-processed',
  templateUrl: './records-processed.component.html',
  styleUrls: ['./records-processed.component.scss']
})
export class RecordsProcessedComponent implements OnInit {
  totalRecords: Number = 0;
  isLoading: Boolean = true;

  constructor(public contentSvc: ContentService) { }

  ngOnInit() {
    this.getProcessedData();
  }

  getProcessedData() {
    this.contentSvc.getConsumptionReport().subscribe((data) => {
      this.isLoading = false;
      this.totalRecords = data.processed_records;
    }, error => {
      this.isLoading = false;
    });
  }
}
