import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ZmAppSharedModule } from '../../common/zm-shared/zm-app-shared.module';
import { AppSharedModule } from '../../common/shared/app-shared.module';
import { ZmContentService } from './zm-content.service';
import { ContentService } from '../../zettasense/content/content.service';
import { AnalyticService } from './zm-analytics/analytic.service';
import { ZmDashboardComponent } from './zm-dashboard/zm-dashboard.component';
import { ZmDatasetsComponent } from './zm-datasets/zm-datasets.component';
import { ZmAlertsComponent } from './zm-alerts/zm-alerts.component';
import { ZmAnalyticsComponent } from './zm-analytics/zm-analytics.component';
import { ZmMaintenanceComponent } from './zm-maintenance/zm-maintenance.component';
import { ZmSystemjobsComponent } from './zm-systemjobs/zm-systemjobs.component';
import { ZmWorkbooksComponent } from './zm-workbooks/zm-workbooks.component';
import { ZmWorkbooksDetailsComponent } from './zm-workbooks/zm-workbooks-details/zm-workbooks-details.component';
import { WorkbookDescriptionComponent } from './zm-workbooks/zm-workbooks-details/workbook-description/workbook-description.component'; 
import { WorkbookEntitlementsComponent } from './zm-workbooks/zm-workbooks-details/workbook-entitlements/workbook-entitlements.component';
import { WorkbookLineageComponent } from './zm-workbooks/zm-workbooks-details/workbook-lineage/workbook-lineage.component';
import { WorkbookRunHistoryComponent } from './zm-workbooks/zm-workbooks-details/workbook-run-history/workbook-run-history.component';
import { WorkbookRunSchedulingComponent } from './zm-workbooks/zm-workbooks-details/workbook-run-scheduling/workbook-run-scheduling.component';
import { DatasetFormulasComponent } from './zm-workbooks/zm-workbooks-details/dataset-formulas/dataset-formulas.component';

import { ZmDatasourceComponent } from './zm-datasource/zm-datasource.component';

@NgModule({
  declarations: [
    ZmDashboardComponent,
    ZmDatasetsComponent,
    ZmAlertsComponent,
    ZmAnalyticsComponent,
    ZmDatasourceComponent,
    ZmMaintenanceComponent,
    ZmSystemjobsComponent,
    ZmWorkbooksComponent,
    ZmWorkbooksDetailsComponent,
    WorkbookDescriptionComponent,
    WorkbookEntitlementsComponent,
    WorkbookLineageComponent,
    WorkbookRunHistoryComponent,
    WorkbookRunSchedulingComponent,
    DatasetFormulasComponent
  ],
  imports: [
    CommonModule,
    ZmAppSharedModule,
    AppSharedModule,
    BrowserModule, 
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [ContentService, ZmContentService, AnalyticService]
})
export class ZmContentModule { }
