import { Component, OnInit, ViewEncapsulation, OnDestroy, HostListener } from '@angular/core';
declare var $: any;
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Formatter, AngularGridInstance, Editors, FieldType, AngularUtilService } from 'angular-slickgrid';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../common/components/message/message.service';
import { ProjectStatus } from './../../../../common/components/project-progress/project-progress.model';
import { ContentService } from '../../content.service';
import { Event, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Entity, Attribute } from '../entity.model';
import { DataQualityService } from 'src/app/common/components/data-quality-rules/data-quality.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { IDeactivateComponent } from 'src/app/common/shared/auth-guard.service';
import { ObservableService } from 'src/app/common/services/observable.service';

import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, GridApi } from 'ag-grid-community';
import { AgGridAngular} from 'ag-grid-angular';
import { CustomCellRenderer } from 'src/app/common/shared/cell-renderer/custom-cell-render-formatter/custom-cell-render-formatter-component';
import { CustomCellEditModeFormatter } from '../../../../common/shared/cell-renderer/custom-cell-render-formatter/custom-cell-editmode-formatter-component';
 import { MinusPlusIconRendererComponent } from '../../../../common/shared/cell-renderer/delete-icon-formatter/minus-plus-formatter-component';
 import {  } from 'ag-grid-angular';
import { DropdownCellRendererComponent } from '../../../../common/shared/cell-renderer/custom-cell-render-formatter/custom-edit-mode-dropdown-formatter-component';

import { Subscription } from 'rxjs';
  
@Component({
  selector: 'zetta-entity-model',
  templateUrl: './entity-model.component.html',
  styleUrls: ['./entity-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EntityModelComponent implements OnInit, OnDestroy, IDeactivateComponent {

    private entityStateSubscription: Subscription;
    gridApi: any;
    GridApi: any;
    columnApi: any;
    args: any;
    columnDefs: ColDef[] = [];
    defaultColDef: ColDef = {}
    fsGridOptions: GridOptions;
    agGrid: AgGridAngular;
    isEAPartiallyUploaded = false;
    gridOptions: any;
    eventData: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    pageLeft = 1;
    limitLeft = 20;
    isLoading = false;
    hasScrolled = false;
    noData = false;
    loggedInUserDetails: [];
    selectedFiles: string[] = [];
    isDataReady = false;
    progressState = new ProjectStatus();
    loadEntity = false;
    entity: any = [];
    showBreadcrumb = false;
    entityDataSource: any = [];
    total = 0;
    attributeTableColDef: any;
    attributeTableRows: any = [];
    responseList: any = [];
    item_added: any;
    gridRef: AngularGridInstance;
    isEditMode = true;
    addAttribute: Attribute = new Attribute();
    addAttributes: any = [];
    selectedAttributeMetadata: any;
    attributeDataTypeslistDropDown: any = [];
    attributeScrubberTypeslistDropDown: any = [];
    attributeMatcherTypeslistDropDown: any = [];
    maxValueOfPriority;
    minValueOfPriority;
    mySubscription: any;
    dataRes;
    is_upload_complete = false;
    old_row;
    showTrainingDataModal = false;
    selectedSubConcepts = [];
    selectedDatasets = [];
    within_datasource_id = [];
    within_dataset_id = [];
    within_dataset_column_id = [];
    displayReferenceData = false;
    rowNo = 0;
    outOfBoxRules = [];
    attribute_id: number;
    defaultRule: any;
    catalog_id: number;
    entity_id: number;
    fu_type_entityAttributes: any;
    clearModal = Math.random();
    originalAttributeState = [];
    isUploadCHeckForHB = false;
    resetStateOnSave = false; 
    editedAttributeEntityPayload = [];
    dragRowInfo;
    dropRowPosition;

    constructor(private contentSvc: ContentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private dqService: DataQualityService,
        private catalogSvc: ZsClContentService,
        private observableService: ObservableService,
        ) { }

    onGridReady(params: any) { 
      this.gridApi = params.api;
        this.columnApi = params.columnApi;   
      // this.handleAttributeGridClickEvent(this.columnApi); 
      setTimeout(() => {
          if (this.gridApi) {
              
              this.columnApi = this.gridApi.getColumnApi;
            this.args = this.eventData 
            this.handleAttributeGridClickEvent(this.columnApi) 
          }
        }, 100);
         this.gridApi.setPinnedTopRowData([{attribute_id: this.catalog_id, action: "add", id: this.catalog_id}]);
          
        }
      
    attributeGrid(gridApi: GridApi) { 
        window.addEventListener("DOMContentLoaded", (event) => {
            this.GridApi.addEventListener('cellEditingStarted', (event) => {
              const args = event as any; // Adjust the type if needed
          
              if (args.column.getColDef().field === 'data_type' || args.column.getColDef().field === 'scrubber_name' || args.column.getColDef().field === 'matcher_type_name') {
                return true;
                } else {
                    return false;
                }
            });
   
        });
    }
  
    ngOnInit() {
        this.fu_type_entityAttributes = AppGlobals.FU_TYPE_ENTITY_ATTRIBUTES;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
          this.entity.entity_id = params['id'];
          this.entity_id = +params['id'];
        });
        this.activatedRoute.queryParamMap.subscribe(param => {
            this.catalog_id = +param.get('catalog_id');
        });
        this.loadEntity = this.activatedRoute.snapshot.queryParamMap.get('id') ? true : false;
     
      //  this.getEntityAttributes();
        this.progressState.states = this.zettaUtils.getStateList('create-entity');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'entity';
        this.progressState.isEdit = this.loadEntity;
        
        this.getDataOfEntityAttributes();
        this.gridOptions = {
            enableGridMenu: false,
            selectable: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableFiltering: true,  
            rowDragEntireRow: true,
          };
          $('#breadcrumb').removeClass('d-none'); 
          this.initialiseGrid();

          this.defaultColDef = {
            resizable: true, 
            flex: 1,
            minWidth: 100,  
          };
    }

    getDataOfEntityAttributes() {
        let self = this;
        
        this.contentSvc.getEntityData().subscribe((resp)=> {
          
            self.attributeDataTypeslistDropDown = resp.entityAttributeDataTypes;
            self.attributeScrubberTypeslistDropDown = resp.entityAttributeScrubberData;
            self.attributeMatcherTypeslistDropDown = resp.entityAttributeMatcherTypes;
            
            self.initialiseGrid();
            self.isDataReady = true;
            self.getEntityAttributes();
             
        }, (err) => {
            self.messageSvc.sendMessage({ message: 'Something went wrong while fetching Entity data', type: 'ERROR', hideboard: true });
        })
    }

     
    getEntityAttributes(isSkipOriginalStateUpdate?: boolean, newEAadded?:boolean, emptyData?:boolean) { 
        this.isLoading = emptyData ? false : true;
        this.contentSvc.getEntity(this.entity.entity_id, false, true).subscribe(
          resp => { 
            if(emptyData) {
                this.entityDataSource = [];
            }
    
      
            this.isLoading = false;
            this.entityDataSource = resp;
            this.attributeTableRows = resp.attributes;
      
            if (this.attributeTableRows.length === 0) {
              this.noData = true;
            } 
      
            this.total = this.attributeTableRows.length;
            this.maxValueOfPriority = Math.max(...this.attributeTableRows.map(o => o.sort_order));
             
            this.minValueOfPriority = Math.min(...this.attributeTableRows.map(o => o.sort_order));
            
            const columnIndex = 1;  
            if (Array.isArray(this.columnDefs) && columnIndex >= 0 && columnIndex < this.columnDefs.length) {
              if (!this.columnDefs[columnIndex].cellRendererParams) {
                this.columnDefs[columnIndex].cellRendererParams = {}; 
              }
            
              this.columnDefs[columnIndex].cellRendererParams.maxValueOfPriority = this.maxValueOfPriority; 
              // this.gridOptions.api.setColumnDefs(this.columnDefs);
            }
 
            if (this.gridApi) {
              this.attributeTableRows = resp.attributes.map((item) => {
                item['action'] = 'delete';
                item['id'] = Math.random();
                item['is_visible_output'] = false;
                return item;
              });
            }
            
            // Commented below since initial setting first row to pin handled by AG-GRID
            // const firstRow = this.getAddAttributeRow();
            // this.setNewAttributeDetails(firstRow);
            // this.attributeTableRows.splice(0, 0, firstRow);
      
            this.isDataReady = true;
            this.gridApi.setRowData(this.attributeTableRows);
            this.gridApi.deselectAll();
            this.hasScrolled = false;
      
            // Maintaining original 
            if (!isSkipOriginalStateUpdate) {
              this.originalAttributeState = this.attributeTableRows.map(val => ({ ...val }));
            }
            if (newEAadded){
              this.emitEntityStateObservable(this, this.isStateChangeForEA());
            }
          },
          err => {
            this.isDataReady = true;
            this.isLoading = false;
            this.messageSvc.sendMessage({
              message: err.error && err.error.message ? err.error.message : 'Something went wrong while fetching entity attribute details',
              type: 'INFO',
              hideboard: true
            });
          }
        );
      }

    getModelPosition(position: string, isPasswordModel?: boolean) {
        let styleValue = '';
        if (position === 'top') {
            styleValue = (this.eventData.eventData.pageY - 50) + 'px';
        } else if (position === 'left') {
                styleValue = 37 + 'vw';
        }
        return styleValue;
    }
   
   handleAttributeGridClickEvent(eventData: any) {   
      const args = eventData; // All Arguments  
 
      const metadata = args.column;  // Column object
      const fieldName = metadata.getColDef().field;  // Fielname getting 
    
      const rowIndex = args.rowIndex; // Row Index 
      const rowNode = this.gridApi.getRowNode(rowIndex); // Row data
      
      const row = rowNode && rowNode.data ? rowNode.data: null; // row object
  
      let swapRow;
      let updateRow = false;
      var oldValue: any;
      var newValue: any;
      let target = eventData.event.target
      if(eventData && eventData.data && eventData.data.action ==="add" ) { 
             
        if (target.className === 'fa fa-plus actionInlineBtn') {        

            this.readAttributeDetails(false);
            // validate Attribute Object
            if (this.validateNewAttribute()) {
                this.addAttribute.is_system = false; 
                this.contentSvc.saveAttribute(this.entity.entity_id, this.addAttribute).subscribe(responseList => {
                     this.getEntityAttributes(true, true); 
                    this.gridApi.setPinnedTopRowData([{attribute_id: this.catalog_id, action: "add", id: this.catalog_id}]);

                }, err => {
                    this.isDataReady = true;
                    this.messageSvc.sendMessage({ message: err.error && err.error.message ? err.error.message: 'Something went wrong while saving new attribute', type: 'ERROR', hideboard: true });
                });
            }
        }  
      } else if (row !== undefined && row !== null) {
          this.attribute_id = row.attribute_id;
          let idCell = '#attribute-' + fieldName + '-' + rowIndex;  // args.row = rowIndex
          if(this.old_row){
            this.addClassOnPrevRow(this.old_row);
          }
         
          if (fieldName === 'is_visible_output') {
              if (target.classList.contains('chk-output')) {
                  if(!row['is_visible_output']){
                      $(`#attribute-check-${fieldName}-${rowIndex}`).removeClass('d-none')
                      $(`#attribute-uncheck-${fieldName}-${rowIndex}`).addClass('d-none')
                  }else{
                      $(`#attribute-check-${fieldName}-${rowIndex}`).addClass('d-none')
                      $(`#attribute-uncheck-${fieldName}-${rowIndex}`).removeClass('d-none')
                  }
                  row['is_visible_output'] = !row['is_visible_output'];
              } 
          }
          if (fieldName === 'is_visible_input') {
              // oldValue = $(idCell)[0]['checked'];
              // row['is_visible_input'] = !row['is_visible_input'];
              // $(idCell)[0]['checked'] = !$(idCell)[0]['checked'];
              // newValue = $(idCell)[0]['checked'];
          }  
           if(rowNode.data.action === 'delete') {   
      
                if (!target.classList.contains('fa fa-minus actionInlineBtn')) {                        
                  const attributeX = new Attribute(); 
                  if (fieldName === 'is_visible_output') {

                      attributeX.attribute_id = row.attribute_id;
                      attributeX.is_visible_output = newValue;
                      //this.contentSvc.saveAttribute(this.entity.entity_id, attributeX).subscribe(responseList => {  }, err => { });

                  } else if (fieldName === 'is_visible_input') {
                      if (target.className === 'fa fa-plus actionInlineBtn') { 
                          this.selectedDatasets = [];
                          this.getDefaultRules(row.attribute_id);
                          this.showTrainingDataModal = true;
                          this.rowNo = rowIndex;
                      }
                      if (target.className === 'fa-light fa-copy') {
                          this.getDefaultRules(row.attribute_id);
                          this.rowNo = rowIndex;
                          this.selectedDatasets = [];
                          this.getRefenceData();
                      }
                  } else if(fieldName === 'sort_order') {

                      // Priority Column
                      if (target.className === 'fa fa-arrow-up greenFont') {
                    
                          if (row.sort_order !== undefined && row.sort_order !== null && this.gridApi.getDisplayedRowCount() > 1) {
                              // Swap Ranking with higher ranked row  
                                const tempsortorder = row.sort_order;
                                swapRow = this.getHigherRankingRow(tempsortorder);
                                if (swapRow) {
                                    row.sort_order = swapRow.sort_order;
                                    swapRow.sort_order = tempsortorder;
                                } else {
                                    row.sort_order = row.sort_order - 1;
                                }
                                updateRow = true;
                            } else if (row.sort_order === null) { 
                                row.sort_order = 1;
                                updateRow = true;
                            }

                      } else if (target.className === 'fa fa-arrow-down redFont') { 
                          if (row.sort_order !== undefined && row.sort_order !== null && row.sort_order !== this.total && this.gridApi.getDisplayedRowCount() > 1) {
                              // Swap Ranking with lower ranked row  
                                const tempsortorder = row.sort_order; 
                                swapRow = this.getLowerRankingRow(tempsortorder);
                                if (swapRow) {
                                    row.sort_order = swapRow.sort_order;
                                    swapRow.sort_order = tempsortorder;
                                } else {
                                    row.sort_order = row.sort_order + 1;
                                }
                                updateRow = true;
                            } else if (row.sort_order === null) {
                                // in case data is corrupted from DB
                                row.sort_order = this.total;
                                updateRow = true;
                            }
                      }
                      if (updateRow) {
                          this.updateRightGridRow(row);
                          this.gridApi.applyTransaction({ update: [row] }); 
                          const attributes: Attribute[] = []; 
                          if (swapRow) {
                            this.updateRightGridRow(swapRow);
                            this.gridApi.applyTransaction({ update: [swapRow] });
                            
                            const swappedAttribute = new Attribute();
                            swappedAttribute.attribute_id = swapRow.attribute_id;
                            swappedAttribute.sort_order = swapRow.sort_order;
                            swappedAttribute.logical_name = swapRow.logical_name;
                            swappedAttribute.physical_name = swapRow.physical_name;
                            attributes.push(swappedAttribute);   
                          }
                        
                          const attribute = new Attribute();
                          attribute.attribute_id = row.attribute_id;
                          attribute.logical_name = row.logical_name;
                          attribute.physical_name = row.physical_name;
                          attribute.sort_order = row.sort_order;
                          attribute.data_type = row.data_type;
                          attribute.scrubber_name = row.scrubber_name;
                          attribute.matcher_type_name = row.matcher_type_name;
                          attribute.is_visible_output = row.is_visible_output;
                          attributes.push(attribute);
                          
                          if (Array.isArray(attributes)) {
                            attributes.forEach(element => {
                              this.contentSvc.saveAttribute(this.entity.entity_id, element).subscribe(
                                responseList => {   
                                  this.getEntityAttributes(true);  
                                },
                                err => { 
                                  // Handle error if needed
                                }
                              );
                            });
                          }    
                          // this.gridApi.deselectAll();
                        }

                  } else if (target.classList.contains('btn-save-entity')) {
                      this.old_row = rowIndex;
                      const idCell = '#attribute-' + fieldName + '-' + rowIndex; 

                      const attributePayload = new Attribute();
                      attributePayload.attribute_id = row.attribute_id;
                      attributePayload.logical_name = row.logical_name;
                      attributePayload.description = row.description;
                      attributePayload.physical_name = row.physical_name; 
                      
                      attributePayload.attribute_data_type_id = this.editedAttributeEntityPayload['attribute_data_type_id'];
                      attributePayload.attribute_scrubber_type_id =  this.editedAttributeEntityPayload['attribute_scrubber_type_id'];
                      attributePayload.matcher_type_id =  this.editedAttributeEntityPayload['matcher_type_id'];
 

                      attributePayload.is_visible_output = row.is_visible_output; 
                      let self = this;
                      this.contentSvc.saveAttribute(this.entity.entity_id, attributePayload)
                        .subscribe(response => {  
                          self.gridApi.hideOverlay();
                          $('#save_entity-' + rowIndex).addClass('d-none');
                          self.addClassOnEdit(rowIndex, idCell);  
                          let attributeIndex = self.attributeTableRows.findIndex((val)=> { 
                            return val.attribute_id == attributePayload.attribute_id  
                          }) 
 
                          if(attributeIndex > -1){
                            self.attributeTableRows[attributeIndex] = {
                              attribute_id: attributePayload.attribute_id,
                              physical_name : response && response.physical_name ? response.physical_name : self.gridApi.getValue(idCell + '-physical_name'),
                              logical_name : response && response.logical_name ? response.logical_name : self.gridApi.getValue(idCell + '-logical_name'),
                              description : response && response.description ? response.description : self.gridApi.getValue(idCell + '-description'),
                              attribute_data_type_id : response && response.attribute_data_type_id ? response.attribute_data_type_id : self.gridApi.getValue(idCell + '-data_type'),
                              attribute_scrubber_type_id : response && response.attribute_scrubber_type_id ? response.attribute_scrubber_type_id : self.gridApi.getValue(idCell + '-scrubber_name'),
                              matcher_type_id : self.editedAttributeEntityPayload['matcher_type_id'] ? self.editedAttributeEntityPayload['matcher_type_id'] : self.attributeTableRows[attributeIndex] && self.attributeTableRows[attributeIndex].matcher_type_id ? self.attributeTableRows[attributeIndex].matcher_type_id: null,
                              is_visible_output : response && response.is_visible_output ? response.is_visible_output : self.gridApi.getValue(idCell + '-uncheck-is_visible_output'),
                            }
                            let temp = self.editedAttributeEntityPayload['matcher_type_id'] ? self.editedAttributeEntityPayload['matcher_type_id'] : (self.attributeTableRows[attributeIndex] && self.attributeTableRows[attributeIndex].matcher_type_id )? self.attributeTableRows[attributeIndex].matcher_type_id: null
  
                          }
                         
                          self.emitEntityStateObservable(self, self.isStateChangeForEA());
                        }, (err) => {
                          self.messageSvc.sendMessage({ message: err.error && err.error.message ? err.error.message : 'Something went wrong while saving attributes', type: 'INFO', hideboard: true });
                        });
                    }  else {
                      //TestDATA
                      const oldValue = this.gridApi.getValue(idCell);
                     // this.addClassOnEdit(rowIndex, idCell);
                      
                      const onCellValueChanged = (params: any) => { 
                        const newValue = params.newValue;   
                        if (oldValue !== newValue) {
                          // this.gridApi.hideOverlay();
                          $('#save_entity-' + rowIndex).removeClass('d-none');
                          $('#delete-' + rowIndex).addClass('d-none');
                        }
                      };

                      const onCellFocusedOut = () => {
                        const newValue = this.gridApi.getValue(idCell);
                        this.addClassOnPrevRow(rowIndex); 
                        // Update the cell styles if needed
                        $(idCell).css({ 'background-color': '#fff', 'border': '0' });
                      };
                    
                      this.gridApi.addEventListener('cellValueChanged', onCellValueChanged);
                      this.gridApi.addEventListener('cellFocusedOut', onCellFocusedOut); 
                    }
              } 

          }   
      }
    }
        
    addClassOnEdit(row,idCell){
        $('#attribute-logical_name-' + row).css({ 'background-color': '#DAE8F1' });
        $('#attribute-physical_name-' + row).css({ 'background-color': '#DAE8F1' });
        $('#attribute-description-' + row).css({ 'background-color': '#DAE8F1' });
        $('#attribute-data_type-' + row).css({ 'background-color': '#DAE8F1' });
        $('#attribute-scrubber_name-' + row).css({ 'background-color': '#DAE8F1' });
        $('#attribute-matcher_type_name-' + row).css({ 'background-color': '#DAE8F1'});
        $(idCell).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });                             
    }
    addClassOnPrevRow(row){
        $('#attribute-logical_name-' + row).css({ 'background-color': '#FFFFFF' });
        $('#attribute-physical_name-' + row).css({ 'background-color': '#FFFFFF' }); 
        $('#attribute-description-' + row).css({ 'background-color': '#FFFFFF' }); 
        $('#attribute-data_type-' + row).css({ 'background-color': '#FFFFFF' }); 
        $('#attribute-scrubber_name-' + row).css({ 'background-color': '#FFFFFF' }); 
        $('#attribute-matcher_type_name-' + row).css({ 'background-color': '#FFFFFF' });        
    }
    // Validate New Attribute Details
    validateNewAttribute() {
        if ((this.addAttribute.logical_name && this.addAttribute.physical_name && this.addAttribute.attribute_data_type_id && this.addAttribute.attribute_scrubber_type_id && this.addAttribute.matcher_type_id)) {
           
          let systemAttributes = [];
          if (this.zettaUtils.getSystemAttributes().length) {
            this.zettaUtils.getSystemAttributes().forEach((sysAttributes) => {
                systemAttributes.push(sysAttributes);
                systemAttributes.push(sysAttributes.toLowerCase());
            })
          }
          
          if (systemAttributes.indexOf(this.addAttribute.logical_name.toLowerCase()) > -1) {
            this.messageSvc.sendMessage({ message: 'Oops! cannot add System Attributes', type: 'ERROR', hideboard: true });
            return false;
          }
          
          return true;

        } else {
            this.messageSvc.sendMessage({ message: 'Please fill the required fields', type: 'INFO', hideboard: true });
             return false;
        }
    }

    emitEntityStateObservable(context, state: boolean) {
        context.observableService.setEntityStateChangedForHB = state;
    }
    // emitEntityStateObservable(state: boolean) {
    //     this.zettaUtils.setEntityStateChanged(state);
    //   }
    readAttributeDetails(is_visible_output = false) {
        let physical_name = $('#attribute-physical_name-0')[0]['value'];
        physical_name = physical_name ? `zs_${physical_name}` : '';
        this.addAttribute.logical_name = $('#attribute-logical_name-0')[0]['value'];
        this.addAttribute.physical_name = physical_name;
        this.addAttribute.description = $('#attribute-description-0')[0]['value'];
        this.addAttribute.attribute_data_type_id = +$('#attribute-data_type-0')[0]['value'];
        this.addAttribute.attribute_scrubber_type_id = +$('#attribute-scrubber_name-0')[0]['value'];
        this.addAttribute.matcher_type_id = +$('#attribute-matcher_type_name-0')[0]['value'];
        this.addAttribute.is_visible_output = is_visible_output;
    }

    setNewAttributeDetails(row) {
        row.logical_name = this.addAttribute.logical_name;
        row.physical_name = this.addAttribute.physical_name;
        row.description = this.addAttribute.description;
        row.is_visible_output = this.addAttribute.is_visible_output;
        row.is_visible_input = this.addAttribute.is_visible_input;
        row.attribute_data_type_id = this.addAttribute.attribute_data_type_id;
        row.attribute_scrubber_type_id = this.addAttribute.attribute_scrubber_type_id;
        row.matcher_type_id = this.addAttribute.matcher_type_id;
    }

    // Delete Attributes Rows on Delete Action Confirmation
    deleteSelectedRow() {
        this.contentSvc.deleteEntityAttribute(this.entity.entity_id, this.selectedAttributeMetadata.dataContext.attribute_id).subscribe(responseList => {
            this.gridRef.gridService.deleteDataGridItemById(this.selectedAttributeMetadata.dataContext.id);
            this.total = this.total - 1;
            $('#btnCloseActionModel').click();
        }, err => {
        });
    }
 
  
  initialiseGrid() {
    this.columnDefs = [
      {
          headerName: '',
          field: 'sort_order', 
          filter: false,
          sortable: false, 
          editable: false, 
          rowDrag: true,
          minWidth: 120,
          maxWidth: 120, 
          suppressColumnsToolPanel: true,
          headerClass: 'entify-first-col'
      },
      {
        headerName: 'Attr. Id',
        field: 'attribute_id',  
        filter: 'agTextColumnFilter',
        sortable: true,
        floatingFilter: true,
        cellClass: 'ag-right-aligned-cell',
        minWidth: 120,
        maxWidth: 120, 
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: CustomCellEditModeFormatter, 
      },{
        headerName: 'Sort Order',
        field: 'sort_order',    
        floatingFilter: false,
        sortable: false, 
        minWidth: 130,
        maxWidth: 130, 
        cellRendererFramework: CustomCellEditModeFormatter,
        cellRendererParams: { 'maxValueOfPriority': this.maxValueOfPriority }, 
      }, {
        headerName: 'Logical Name',
        field: 'logical_name', // singleClickEdit
        cellRendererFramework: CustomCellEditModeFormatter,
        filter: 'agTextColumnFilter',
        singleClickEdit: true, 
        editable:true,
        floatingFilter: true,
        sortable: true, 
        minWidth: 150,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererParams: { 'placeholder': 'Add Logical Name', 'tabindex': 3 },
      }, {
        headerName: 'Physical Name',
        field: 'physical_name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        singleClickEdit: true,
        editable:true,
        sortable: true, 
        minWidth: 150,
        cellClass: 'custom-class-name',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: CustomCellEditModeFormatter,
        cellRendererParams: { 'placeholder': 'Add Physical Name', 'tabindex': 4 },
      }, {
        headerName: 'Description',
        field: 'description',
        filter: 'agTextColumnFilter',
        singleClickEdit: true, 
        editable:true,
        floatingFilter: true,
        sortable: true,
        minWidth: 150,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: CustomCellEditModeFormatter,
        cellRendererParams: { 'placeholder': 'Add Description', 'tabindex': 5 },
      }, {
        headerName: 'Data Type',
        field: 'data_type', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,  
        sortable: true,  
        minWidth: 130, 
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        }, 
        cellRendererFramework: DropdownCellRendererComponent, 
        cellRendererParams: { 
            list: this.attributeDataTypeslistDropDown
         } 
      }, {
        headerName: 'Scrubber Name',
        field: 'scrubber_name',  
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        sortable: true,  
        minWidth: 150, 
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: DropdownCellRendererComponent, 
        cellRendererParams: { list: this.attributeScrubberTypeslistDropDown }
      }, {
        headerName: 'Match Type',
        field: 'matcher_type_name', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        minWidth: 150, 
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        cellRendererFramework: DropdownCellRendererComponent, 
        cellRendererParams: { list: this.attributeMatcherTypeslistDropDown }
      }, {
        headerName: 'Reference Data',
        field: 'is_visible_input',
        cellRendererFramework: CustomCellEditModeFormatter,
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        sortable: false,   
        headerClass: 'entify-ref-header-edit', 
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        minWidth: 100,
        cellRendererParams: {'tabindex': 7 },
      }, {
        headerName: '',
        field: 'action',
        cellRendererFramework: MinusPlusIconRendererComponent,  
        minWidth: 100,
        suppressColumnsToolPanel: true, 
      } 
    ];
    this.fsGridOptions = { 
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49, 
      singleClickEdit: true,
      context : {

      },
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    }; 
  }
 
  onDropdownValueChanged(optionsObj: any) { 
    switch (optionsObj.fieldName) {
        case 'data_type':
          this.editedAttributeEntityPayload['attribute_data_type_id']= optionsObj.value; 
             break;
        case 'scrubber_name':
          this.editedAttributeEntityPayload['attribute_scrubber_type_id']= optionsObj.value;
             break;
        case 'matcher_type_name':
          this.editedAttributeEntityPayload['matcher_type_id']= optionsObj.value;
            break;
        default: 
            break;

    }   
     
    if(optionsObj.id !== 't-0' ){
      $('#save_entity-' + optionsObj.rowIndex).removeClass('d-none');
      $('#delete-' + optionsObj.rowIndex).addClass('d-none');   
    }  
    

}

  onCellClicked(event: any) { 
    const colId = event.column.getColId();
    if (colId === 'logical_name' && event.data.editable) {
      event.api.startEditingCell({
        rowIndex: event.rowIndex,
        colKey: colId,
      });
    }
  }
  
    getAddAttributeRow() {
        return { 
            'id': Math.random(), 
            'attribute_id': '-1', 
            'sort_order': '', 
            'logical_name': '', 
            'physical_name': '', 
            'description': '', 
            'data_type': '', 
            'scrubber_name': '', 
            'matcher_type_name': '', 
            'is_visible_output': false, 
            'is_visible_input': false, 
            'action': 'add' 
        };
    }

    getPaggedAttribute(page, limit) {
        this.readAttributeDetails();
        this.contentSvc.getEntity(this.entity.entity_id, false, true).subscribe(resp => {
            this.attributeTableRows = resp.attributes;
            if (this.attributeTableRows.length === 0) {
                this.noData = true;
            }
            let concatedRows = this.attributeTableRows;
            if (this.gridRef) {
                concatedRows = [...this.gridRef.dataView.getItems(), ...this.attributeTableRows];
                this.attributeTableRows = resp.attributes.map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }

            // const firstRow = this.getAddAttributeRow();
            // this.setNewAttributeDetails(firstRow);
            // this.attributeTableRows.splice(0, 0, firstRow);
            this.isDataReady = true;
            this.attributeTableRows = concatedRows;
            this.gridRef.dataView.setItems(concatedRows);
            this.gridRef.gridService.setSelectedRows([]);
            this.gridRef.dataView.refresh();
            this.hasScrolled = false;
        }, err => {
            this.isDataReady = true;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });

    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getPaggedAttribute(this.pageLeft, this.limitLeft);
    }

    leftGridCount() {
        if (this.gridRef) {
            // Remove Add Row Count
            const hasFilter = this.gridRef.dataView.getItems().length - this.attributeTableRows.length;
            if (hasFilter < 0) {
                return this.gridRef.dataView.getItems().length;
            } else {
                return this.gridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.attributeTableRows.length - 1;
        }
    }

    onLeftGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass, 'bottom') && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
        }
    }

    removeFile(index) {
        this.selectedFiles.splice(index, 1);
    }

    onViewMode(){
        $('#breadcrumb').removeClass('d-none');
        this.router.navigate(['/zs/entities/' + this.entity.entity_id + '/entity-model-view'], { queryParamsHandling: 'merge' });
    }

    toggleVisibility(e){
        $('#attribute-is_visible_output')[0]['value'] = !$('#attribute-is_visible_output')[0]['checked'];
        $('#attribute-is_visible_input')[0]['value'] = !$('#attribute-is_visible_input')[0]['checked'];
      }

    // Less ranking number is higher
    getHigherRankingRow(ranking: number) {
        let immediateHigherRankingRow: any; 
        this.gridApi.forEachNode(node => {
            const rowData = node.data;
            if (rowData.sort_order < ranking) {
              if (immediateHigherRankingRow) {
                if (immediateHigherRankingRow.sort_order < rowData.sort_order) {
                  immediateHigherRankingRow = rowData;
                }
              } else {
                immediateHigherRankingRow = rowData;
              }
            }
          });
         
        return immediateHigherRankingRow;
      } 

    getLowerRankingRow(ranking: number) {
      let immediateLowerRankingRow: any; 
      this.gridApi.forEachNode(node => {
        const row = node.data;
        if (row.sort_order > ranking) {
          if (!immediateLowerRankingRow || immediateLowerRankingRow.sort_order > row.sort_order) {
            immediateLowerRankingRow = row;
          }
        }
      });
    
      return immediateLowerRankingRow;
    }

    // updateRightGridRow(row) {
    //     let rightRow = this.attributeTableRows.find(rightRow => (rightRow.physical_name === row.physical_name && rightRow.physical_name === row.physical_name));
    //     if (rightRow) {
    //         rightRow.sort_order = row.sort_order;
    //     }
    // }
    updateRightGridRow(row: any) { 
      const NextRowData = this.attributeTableRows.find(nextRow => nextRow.physical_name === row.physical_name && nextRow.physical_name === row.physical_name); 
     if (NextRowData) {
        NextRowData.sort_order = row.sort_order;
      }
    }
  
    ngOnDestroy() {
      // if (!this.showBreadcrumb) {
       $('#breadcrumb').removeClass('d-none');
      // }
      this.messageSvc.clearMessage();
    }

    // IDeactivate callback
    canExit(): boolean {
        let userInfo = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')) : null;
        if (this.isStateChangeForEA() && userInfo && !this.resetStateOnSave) {
            this.messageSvc.sendMessage({ message: AppGlobals.ENTITY_ATTRIBUTE_STATE_CHANGE_WARNING_MSG, type: 'ERROR', hideboard: true });
            return false;
        }
        return true;
    }

    onCancel() {
        this.router.navigate(['/zs/entities'], { queryParams: {catalog_id: this.catalog_id}});
    }

    onPrevious() {
        this.router.navigate([`/zs/entities/${this.entity.entity_id}/add-users`], { queryParams: { 'id': this.entity.entity_id, showBreadcrumb: true}, queryParamsHandling:'preserve'  });
    }

    fileUploaded(){
        // this.getEntityAttributes();
        this.router.navigate(['/zs/entities/' + this.entity.entity_id + '/entity-model']);
    }

    receiveFileName($event) {
      this.isUploadCHeckForHB = true;
      if ($event === AppGlobals.FILE_UPLOADED) {
          if (this.is_upload_complete) {
              this.clearModal =  Math.random();
              $('#uploadFileModal').modal('hide');
          }
      } else {
          this.dataRes = $event
          this.is_upload_complete = this.dataRes.is_upload_complete;
          if (this.is_upload_complete) {
              // if (this.gridRef) {
              //     this.gridRef.dataView.setItems([]);
              //     this.getEntityAttributes();
              // }
              this.refreshEntityGrid();
          }
      }
  }
      
    hideOverlay(eve) {
        this.showTrainingDataModal = false;
    }
    
    updateData(eve) {
        const dqMappingPayload = [];
        if (eve.data && eve.data.length > 0) {
            this.selectedDatasets = eve.data;
            this.displayReferenceData = true;
            this.within_datasource_id = this.selectedDatasets.map(dataset => dataset.description);
            this.within_dataset_id = this.selectedDatasets.map(datset => datset.dataset_id);
            this.within_dataset_column_id = this.selectedDatasets.map(datset => datset.dataset_column_id);            
            this.within_dataset_column_id.forEach((attr) => {
              if (attr) {
                dqMappingPayload.push({
                  dataset_column_id: parseInt(attr),
                  attribute_id: this.attribute_id
                });
              }
            });
            $('#add-ref-' + this.rowNo).addClass('d-none');
            $('#view-ref-' + this.rowNo).removeClass('d-none');          
            const rulePayload = {
                "enity_id": this.entity_id,
                "lookup_id": this.defaultRule.lookup_id,
                "attribute_id": this.attribute_id,
                "operator_lookup_id": null,
                "calculation": null,
                'name': this.defaultRule.rule_name,
                'description': this.defaultRule.description,
                "data_quality_rule_sequence_id": this.defaultRule.data_quality_rule_sequence_id,
                'within_source_id': this.within_dataset_id,
                'within_list_column_id': this.within_dataset_column_id
            };
            this.createDQRuleMapping(dqMappingPayload,rulePayload);
        } else {
            this.displayReferenceData = false;
        }
    }
    
    getDefaultRules(attribute_id) {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            concept_id: attribute_id
        };
        this.dqService.getOutOfBoxRules(data).subscribe(resp => {
            if (resp) {
                this.outOfBoxRules = resp['currentpage'];
                if (this.outOfBoxRules.length) {
                    this.defaultRule = this.outOfBoxRules.find(rule => rule.rule_name === AppGlobals.SET_REFERENCE);
                    
                }
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message : 'Something went wrong while fetching rules', type: 'ERROR', hideboard: true });
        });
    }

    createDQRuleMapping(dqMappingPayload, rulePayload) {
        const oobRulePayload = [];
        const payload = [
            {
                catalog_id: this.catalog_id,
                entities: [
                    {
                        entity_id: this.entity_id,
                        mappings: dqMappingPayload
                    }
                ]
            }
        ];
        oobRulePayload.push(rulePayload);
        this.catalogSvc.runModelClassify1(payload).subscribe(resp => {
            this.createOOBRule(oobRulePayload);
        }, err => {
            this.messageSvc.sendMessage({ message: err.error && err.error.message ? err.error.message : 'Something went wrong.', type: 'ERROR', hideboard: true });
        });
    }

    createOOBRule(oobRulePayload) {
        this.catalogSvc.saveOutOfBoxRule(oobRulePayload).subscribe(respRule => {            
            if (respRule && respRule.length) {
                this.updateMapping(respRule[0].data_quality_rule_id);
                this.reRunRule(respRule[0].data_quality_rule_id);
                this.updateScheduler(respRule[0].data_quality_rule_id);
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message : 'An Error occured while saving rules' , type: 'ERROR', hideboard: true });
        });
    }

    updateScheduler(rule_id) {
        let object_type_id = AppGlobals.SCHEDULER_ONCE_OBJECT_TYPEID;
        const data = [{
            scheduler_type_lookup_id: AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID,
            scheduler_object_id: rule_id
        }];
        this.catalogSvc.createUpdateScheduler(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], object_type_id, data).subscribe(res => {
        }, error => {
            this.messageSvc.sendMessage({ message: error.error[0].message, type: 'ERROR', hideboard: true });
        });
    }
    updateMapping(rule_id) {
        const data = {
            rule_id: rule_id,
            payload: {
                is_mapping_changed: true
            }
        };
        this.catalogSvc.updateChangeMappings(data).subscribe(resp => {
        }, error => {
            this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message : 'Something went wrong.', type: 'ERROR', hideboard: true });
        });
    }
    reRunRule(rule_id) {
        this.catalogSvc.reRunRule(rule_id, 'business').subscribe(resp => {
        }, error => {
            this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message : 'An error occured while rerun rule', type: 'ERROR', hideboard: true });
        });
    }

    getRefenceData() {
        this.catalogSvc.getReferenceDataForConcept(this.entity_id, this.attribute_id).subscribe(resp => {
            if (resp.length) {
                resp.forEach(element => {
                    element.logical_name = element.left_within_source_id;
                    element.description = element.left_within_list_column_id;
                });
                this.selectedDatasets = resp;
            }
            this.showTrainingDataModal = true;
        }, error => {
            this.messageSvc.sendMessage({ message: error.error && error.error.message ? error.error.message: 'something went wrong while fetching reference data. try again later', type: 'ERROR', hideboard: true });
        });
    }

    isValidDataForHeuristicBuilder(attributes): boolean {
        let entityAttributes = attributes ? attributes.map((val) => { return val;}) : [];
        entityAttributes = entityAttributes.filter((val) => {
            return val.action != 'add';
        });

        // Commenting the below logic of verifying if matcher type is entity identifier exist as per story 9544 point 2b. 
        // let eaWithEntityIdentifierMatchType= entityAttributes.filter((val) => {
        //     return val.matcher_type_id == AppGlobals.ENITY_IDENTIFIER_MATCHER_TYPE;
        // });

        // if ((entityAttributes.length >= AppGlobals.MIN_ENTITY_ATTRIBUTES_NEEDED) && 
        // (eaWithEntityIdentifierMatchType.length > 0)) {
        //     return true;
        // }

        if (entityAttributes.length >= AppGlobals.MIN_ENTITY_ATTRIBUTES_NEEDED) {
          return true;
        }

        return false;
    }

    isEntityAttributeAndOriginalAttributeStateChangesDetected(entityAttribute) {
        
        // not validating for header row
        if (entityAttribute.action === 'add') {
            return false;
        } 

        let originalAttributeStateIndex = this.originalAttributeState.findIndex((attribute) => {
            return attribute.attribute_id === entityAttribute.attribute_id;
        });
        
        if (originalAttributeStateIndex > -1) {
          
            if(entityAttribute.attribute_data_type_id != this.originalAttributeState[originalAttributeStateIndex].attribute_data_type_id || 
              entityAttribute.matcher_type_id != this.originalAttributeState[originalAttributeStateIndex].matcher_type_id || 
              entityAttribute.attribute_scrubber_type_id != this.originalAttributeState[originalAttributeStateIndex].attribute_scrubber_type_id || 
              entityAttribute.physical_name != this.originalAttributeState[originalAttributeStateIndex].physical_name) {
                return true;
            }
             return false;
        }
     return true;
    }

    isStateChangeForEA() {
        
        if (!this.isDataReady) {
            return false;
        }
        
        if (this.originalAttributeState.length != this.attributeTableRows.length) {
            return true;    
        }
 
        let self = this;
        
        if (this.attributeTableRows.some(this.isEntityAttributeAndOriginalAttributeStateChangesDetected, self)) {
           return true;
        }

        return false;
    }
    
      
    resetStateOfEAHeuristicBuilder() {
        this.originalAttributeState = this.attributeTableRows;
    }

    navigateToHome() { 
       if (this.isValidDataForHeuristicBuilder(this.attributeTableRows)) {
                // Not waiting for algo api to return
                
                // removed isUploadCHeckForHB = false check to support edit entity after importing
                //if (this.isStateChangeForEA() && !this.isUploadCHeckForHB) {
                if (this.isStateChangeForEA()) {
                    let self = this;
                    this.contentSvc.runHeuristicBuilder(this.entity.entity_id).subscribe(resp=>{
                        self.isUploadCHeckForHB = false;
                    },err=>{             
                        self.isUploadCHeckForHB = false;                  
                    });   
                } 
                this.resetStateOnSave = true;
                this.resetStateOfEAHeuristicBuilder();    
                this.emitEntityStateObservable(this, this.isStateChangeForEA());
                this.router.navigate(['/zs/entities'], { queryParams: {catalog_id: this.catalog_id}});            
       } else {
            this.messageSvc.sendMessage({ message: AppGlobals.HB_ENTITY_ATTRIBUTE_ERROR_MSG, type: 'ERROR', hideboard: true });
       }
    }

    openModalImport(modal) {
        $('#' + modal).modal('show');
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
       if (this.isStateChangeForEA() && $event) {
           $event.preventDefault();
           $event.returnValue = false;//this.messageSvc.sendMessage({ message: 'Test', type: 'ERROR', hideboard: true });;
        }
    }


    onDataSorted(entityDataObj) {

        let entityData = entityDataObj.data;
        let headerIndex = entityData.findIndex((entity) => {
            return entity.action === 'add';
        })
        
        let headerData ;

        if (headerIndex > -1) {
            headerData = entityData[headerIndex];
            entityData.splice(headerIndex, 1);
        }
       
        entityData = this.sortEntityData(entityData, entityDataObj.sortBy,entityDataObj.sortType);

        if (entityDataObj.showHeader) {
            if (headerData) {
                entityData.unshift(headerData);
            } else {
                entityData.unshift(this.getAddAttributeRow());
            }
        }

        this.gridRef.dataView.setItems(entityData);
        this.gridRef.gridService.setSelectedRows([]);
    }

    isPartialEntityDataUploaded(isPartialDataUploaded) {
      this.isEAPartiallyUploaded = isPartialDataUploaded;
  }
  refreshEntityGrid() {
    this.getEntityAttributes();
  }
  onFileEntityUploadModelClose($event) {
    // if partial data is uploaded, refresh the grid on modal close
    if (this.isEAPartiallyUploaded) {
        this.isEAPartiallyUploaded = false;
        this.refreshEntityGrid();
    }
}

    sortEntityData(entityData, column, sortType) {
        let sortByAsc = (a,b) => {
            if (a[column] && b[column] && (a[column].toLowerCase() < b[column].toLowerCase())) {
                return -1;
            }
            if (a[column] && b[column] && (a[column].toLowerCase() > b[column].toLowerCase())) {

            }
            return 0;
        }

        let sortByDesc = (a,b) => {
            if (a[column] && b[column] && (a[column].toLowerCase() > b[column].toLowerCase())) {
                return -1;
            }
            if (a[column] && b[column] && (a[column].toLowerCase() < b[column].toLowerCase())) {

            }
            return 0;
        }

        if (sortType === 'asc') {
            entityData.sort(sortByAsc);
        } else {
            entityData.sort(sortByDesc);
        }
        return entityData;
    }

    onDragRowStart(eve) {
        let selectedRow = eve.api.getRowNode(eve.overIndex);
        this.dragRowInfo = selectedRow.data;
    }

    onDragRowEnd(eve) {
        let dropRowData = eve.api.getRowNode(eve.overIndex).data;
        this.dropRowPosition = eve.overIndex;
        let payload = {"attribute_id":this.dragRowInfo.attribute_id,"sort_order": dropRowData.sort_order};
        this.contentSvc.updateAttribute(this.entity.entity_id, this.dragRowInfo.attribute_id, payload)
        .subscribe(responseList => {  
            if(responseList) {
                this.getEntityAttributes(false, false, true);
            } 
        },
        err => { 
        });
    }
}

