import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';

const objectTitleFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const mapped_icon = (dataContext.mapped_data_sets > 0 || dataContext.mapped_data_sources > 0) ? `<i class="fal fa-code-branch"></i>` : ``
  return `<div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
  <div class="col-auto m-0 p-0 text-left mapped-icon">
   ${mapped_icon}
  </div>
  <div class="col ml-2 m-0 p-0 text-left">
    <div title="${value}" class="text-truncate pointer hyper-link object-title-wd">${value}</div>
  </div>
</div>`;
}

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
dataContext.isChecked ? `<label class="checkbox-container">
                <input type="checkbox" id="data-${row}" checked>
                <span class="checkmark checkmark-left-8"></span>
             </label>` 
          : `<label class="checkbox-container">
                <input type="checkbox" id="data-${row}">
                <span class="checkmark checkmark-left-8"></span>
             </label>`;


const EMPTY_GRID_MSG = 'No Semantic Objects to map.';

@Component({
    selector: 'zetta-ds-select-objects',
    templateUrl: './ds-select-objects.component.html',
    styleUrls: ['./ds-select-objects.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DsSelectObjectsComponent implements OnInit, OnDestroy {

  public catalogs: any;
  public objects: any;
  objectsList: any = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  tableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  grandTotal = 0;
  subTotal = '';
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  progressState = new ProjectStatus();

  dataset_id;
  catalogs_ids: any;
  objects_ids = '';
  catalogs_ids_arr: any = [];

  isDataReady;
  count = 0;
  selectedGridRowsSize = 0;
  selectedSemanticId: any;
  selectedSemanticId_arr: any = [];
  checkedIndexes: Array<number> = [];
  showLoadingSpinner: boolean = false;
  disableNextButton: boolean = false;
  constructor(
    private catalogSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.catalogs_ids = this.activatedRoute.snapshot.queryParamMap.get('catalogs_ids');
    if(this.catalogs_ids)
    {
      this.catalogs_ids_arr = this.catalogs_ids.split(',');
    }
   
    this.selectedSemanticId =this.activatedRoute.snapshot.queryParamMap.get('objects_ids');
   if(this.selectedSemanticId)
   {
    this.selectedSemanticId_arr = this.selectedSemanticId.split(',');
   }
    this.progressState.states = this.zettaUtils.getStateList('classify-set');
    this.progressState.currentStateIndex = 1;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifySet';
    this.progressState.isEdit = false;

    this.catalogs_ids_arr.forEach((element) => {
        this.getSemanticObject(this.page, this.limit);
    });
  
    this.tableColDef = [ 
      {
            'displayname': 'Semantic Object',
            'physicalname': 'semantic_object',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 150,
            'maxWidth': 250,
            'formatter': objectTitleFormatter,
        }, {
            'displayname': 'Definitions',
            'physicalname': 'definitions',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
        }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: true,
      multiSelectable: true,
      noDataMsg: EMPTY_GRID_MSG,
      CheckboxSelector: true,
      enableRowSelection:true,
    };

  }
  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
  }
  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

    getSemanticObject(pageNo,limit) {
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalogs_ids}/semantics?pagination=true&pageno=${1}&pagesize=${limit}`;
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
          serverFilter = sessionStorage.getItem("serverfilter");
        }
        let sortfilter = "";
        if (sessionStorage.getItem("sortfilter")) {
          sortfilter = sessionStorage.getItem("sortfilter");
        }
        this.showLoadingSpinner = true;
        this.catalogSvc.getSemanticsObjectsPaged(this.catalogs_ids, pageNo, limit, serverFilter, sortfilter).subscribe(resp => {      
          this.showLoadingSpinner = false;  
          if(resp['currentpage'].length){
              resp['currentpage'].forEach(element => {
                element.catalog_id = this.catalogs_ids;
              });
            } 
            this.objectsList = resp['currentpage'];
                this.objects = this.objectsList;
                if (this.objects.length === 0) {
                    this.noData = true;
                }
                if (!this.grid) {
                  this.objects.forEach((element, ind) => {
                    element.id = Math.random();
                    if (this.selectedSemanticId_arr.length > 0) {
                      this.selectedSemanticId_arr.forEach(ele => {
                        if (ele == element.entity_id) {
                          element.isChecked = true;
                          this.checkedIndexes.push(ind);
                        }
                      });
                    }
                    else {
                      element.isChecked = element.entity_id == this.selectedSemanticId ? true : false;
                    }
                  });
                }
                this.isLoading = false;
                this.total = resp['totalrecords'];
                if (pageNo === 1) {
                  this.grandTotal = resp['totalrecords'];
                }
                let concatedRows = this.objects;
                if (this.grid) {
                    concatedRows = [...this.grid.dataView.getItems(), ...this.objects];
                    this.objects = concatedRows;
                    if (this.objects && this.objects.length) {
                      this.objects.forEach((element, ind) => {
                        element.id = Math.random();
                        if (this.selectedSemanticId_arr.length > 0) {
                          this.selectedSemanticId_arr.forEach(ele => {
                            if (ele == element.entity_id) {
                              element.isChecked = true;
                              this.checkedIndexes.push(ind);
                            }
                          });
                        }
                        else {
                          element.isChecked = element.entity_id == this.selectedSemanticId ? true : false;
                        }
                      });
                    }
                    this.grid.dataView.setItems(concatedRows);
                    this.grid.gridService.setSelectedRows(this.checkedIndexes);
                  
                    this.hasScrolled = false;
                }
            this.count = this.count + 1;
        }, err => {
              this.showLoadingSpinner = false;
         });
    }
 
  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedGridRowsSize = args.rows.length;
      parent.checkedIndexes = args.rows;
      parent.selectedSemanticId_arr = parent.getSementicIdsByRowId(args.rows);
    });

      // parent.grid.gridService.setSelectedRows(this.checkedIndexes);
    if(this.selectedSemanticId_arr.length>0||this.selectedSemanticId)
    {
      parent.selectedGridRowsSize=1;
    }
    if(this.grid){
      this.grid.gridService.setSelectedRows(this.checkedIndexes);
    }
    
  }
  onNext(): void {
    this.page++;
    this.getSemanticObject(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onCancel(): void {
    this._router.navigate(['/zs/datasets']);
  }

  onPreviousStep(): void {
    this._router.navigate(['/zs/datasets/' + this.dataset_id + '/catalogs'], { queryParams: { catalogs_ids: this.catalogs_ids} });
  }

  onNextStep(): void {
     const rows: any[] = [];
     const gridrows = this.grid.dataView.getItems();
    const gridDataService = this.grid.gridService;
    const selectedRows = gridDataService.getSelectedRows();
    selectedRows.forEach(index => {
       const row = gridDataService.getDataItemByRowNumber(index);
       rows.push(row.entity_id);
    });
   this.objects_ids = rows.toString();
    const data: any = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      dataset_id: this.dataset_id,
      payload: {
        entity_id: rows
      }
    }; 
    
    this.showLoadingSpinner = true;
    this.disableNextButton = true;
    this.catalogSvc.saveDatasetMapping(data).subscribe(resp => {
      this.showLoadingSpinner = false;
      this.disableNextButton = false;
      this._router.navigate(['/zs/datasets/' + this.dataset_id + '/catalogs/objects/columns'], { queryParams: { catalogs_ids: this.catalogs_ids, objects_ids: this.objects_ids } });
    }, error => {
      this.showLoadingSpinner = false;
      this.disableNextButton = false;
      console.log(error);
    });
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
   }

  getSementicIdsByRowId(gridRows) {
    let _semanticIds = [];
    if (this.grid) {
      let selectedGridRows = this.grid.gridService.getDataItemByRowIndexes(gridRows);
      if (selectedGridRows.length) {
        selectedGridRows.forEach((semanticData) => {
           if (semanticData) {
              _semanticIds.push(semanticData.entity_id);
           } else {
               if (this.selectedGridRowsSize) {
                  gridRows.forEach((row) => {
                     if (this.objects && this.objects[row] && this.objects[row].entity_id) {
                      _semanticIds.push(this.objects[row].entity_id);
                     }
                  })
               }
           }
        })
      } 
    }
    return _semanticIds;
  }

  onSearchAndSortDataFetched(response) {
    const gridDataService = this.grid.gridService;
    const selectedRows = gridDataService.getSelectedRows();
    this.checkedIndexes = [];
    let semanticsData = response && response['currentpage'] ? response && response['currentpage'] : [];
    if (semanticsData && semanticsData.length) {
      semanticsData = semanticsData.map((_semantics, index) => {
        _semantics.isChecked = this.selectedSemanticId_arr && this.selectedSemanticId_arr.length && this.selectedSemanticId_arr.indexOf(_semantics.entity_id) > -1 ? true : false;
        _semantics.id = Math.random();
        return _semantics;
      })
    }
    if (this.grid) {
      this.grid.dataView.setItems(semanticsData);
      this.grid.gridService.setSelectedRows(this.checkedIndexes);
    }
  }
}


