import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-zm-alerts',
  templateUrl: './zm-alerts.component.html',
  styleUrls: ['./zm-alerts.component.scss']
})
export class ZmAlertsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
