import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'zetta-technical-run-execution-results',
  templateUrl: './technical-run-execution-results.component.html',
  styleUrls: ['./technical-run-execution-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TechnicalRunExecutionResultsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
