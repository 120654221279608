import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../content/project/project.service';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { confidenceFormatter } from '../../../../common/shared/formatters/confidenceFormatter';
import { blueTextFormattter } from '../../../../common/shared/formatters/blueTextFormatter';
import { Project } from '../../../content/project/project.model';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { toolTipFormatter } from '../../../../common/shared/formatters/toolTipFormatter';


@Component({
    selector: 'zetta-publish-results-view',
    templateUrl: './publish-results-view.component.html',
    styleUrls: ['./publish-results-view.component.scss']
})
export class PublishResultsViewComponent implements OnInit {
    public leftSidePaneTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public leftSidePanelDS: object;
    public leftPanelGridObject: AngularGridInstance;
    public leftPanelGridColumnsSettings: object = [];
    public leftSidePanelOptions: object = {};
    public isLeftLoading = true;
    public hasScrolled = false;
    projectDetail = new Project();
    leftGridSelection: string;
    totalLeft = 0;
    pageLeft = 1;
    limitLeft = 100;
    noData = false;

    constructor(private ProjectService: ProjectService,
        public zettaUtils: ZettaUtils,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });

        this.leftSidePanelOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            rowHeight: 34,
        };


        this.leftPanelGridColumnsSettings = [
            {
                'displayname': 'First Name',
                'physicalname': 'zs_first_name_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Last Name',
                'physicalname': 'zs_last_name_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Name Prefix',
                'physicalname': 'zs_name_prefix_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Govt Id',
                'physicalname': 'zs_govt_id_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Travel Id',
                'physicalname': 'zs_travel_id_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Gender',
                'physicalname': 'zs_gender_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Email Person',
                'physicalname': 'zs_email_person_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Phone Person 1',
                'physicalname': 'zs_phone_person_1_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Phone Person 2',
                'physicalname': 'zs_phone_person_2_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'DOB',
                'physicalname': 'zs_dob_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Other',
                'physicalname': 'zs_address_other_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Preferred Name',
                'physicalname': 'zs_preferred_name_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Phone Person 3',
                'physicalname': 'zs_phone_person_3_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Email Person 2',
                'physicalname': 'zs_email_person_2_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Email Person 3',
                'physicalname': 'zs_email_person_3_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Occupation',
                'physicalname': 'zs_occupation_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Marital Status',
                'physicalname': 'zs_marital_status_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'DOB Approx',
                'physicalname': 'zs_dob_approx_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Citizenship',
                'physicalname': 'zs_citizenship_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Citizenship 2',
                'physicalname': 'zs_citizenship_2_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Company Name',
                'physicalname': 'zs_company_name_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Company Name 2',
                'physicalname': 'zs_company_name_2_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Company Name 3',
                'physicalname': 'zs_company_name_3_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Voter Id',
                'physicalname': 'zs_voter_id_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Driver License',
                'physicalname': 'zs_driver_license_id_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Do Not Call',
                'physicalname': 'zs_do_not_call_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Do Not Email',
                'physicalname': 'zs_do_not_email_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Do Not Send Email',
                'physicalname': 'zs_do_not_send_mail_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Do Not Text',
                'physicalname': 'zs_do_not_text_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Is Deceased',
                'physicalname': 'zs_is_deceased_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Other Raw',
                'physicalname': 'zs_address_other_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Emplyment Raw',
                'physicalname': 'zs_address_employment_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Emplyment 2 Raw',
                'physicalname': 'zs_address_employment_2_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Emplyment 3 Raw',
                'physicalname': 'zs_address_employment_3_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Home Raw',
                'physicalname': 'zs_address_home_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Home 2 Raw',
                'physicalname': 'zs_address_home_2_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Home 3 Raw',
                'physicalname': 'zs_address_home_3_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Address Mailing Raw',
                'physicalname': 'zs_address_mailing_raw_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Full Name Person Internal',
                'physicalname': 'zs_full_name_person_internal_o',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            },
            {
                'displayname': 'Cluster Id',
                'physicalname': 'zs_cluster_id',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'cssClass': 'text-left',
                'headerCssClass': 'text-left',
                // 'minWidth': 180,
                // 'maxWidth': 320,
            }
            
        ];

        this.getProjectResultsById(this.pageLeft, this.limitLeft);
    }

    getProjectResultsById(pageno, limit = 100) {
        // this.isLeftLoading = true;
        this.ProjectService.getProjectResults(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limit).subscribe(data => {
            this.leftSidePanelDS = data;
            if (this.leftSidePanelDS['currentpage'].length === 0) {
                this.noData = true;
              }
            this.isLeftLoading = false;
            this.totalLeft = data['totalrecords'];
            let concatedLeftRows =  this.leftSidePanelDS['currentpage'];
            if (this.leftPanelGridObject) {
                concatedLeftRows = [...this.leftPanelGridObject.dataView.getItems(), ...this.leftSidePanelDS['currentpage']];
                this.leftSidePanelDS['currentpage'].forEach(element => {
                    element.id = Math.random();
                });
                this.leftSidePanelDS['currentpage'] = concatedLeftRows;
                this.leftPanelGridObject.dataView.setItems(concatedLeftRows);
                this.leftPanelGridObject.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }

        }, err => {
            this.leftSidePanelDS = { 'currentpage': [] };
            this.isLeftLoading = false;
        });
    }

    onLeftGridCreation(leftgrid) {
        this.leftPanelGridObject = leftgrid;
    }

    goToPageLeft(n: number): void {
        this.pageLeft = n;
        this.getProjectResultsById(this.pageLeft);
    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getProjectResultsById(this.pageLeft);
    }

    onPrevLeft(): void {
        this.pageLeft--;
        this.getProjectResultsById(this.pageLeft);
    }

    totalLeftPages(): number {
        return this.zettaUtils.getTotalPages(this.totalLeft, this.limitLeft);
    }

    onLeftGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
          }
      }
}
