import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';

export interface CellParams {
  name?: string;
  active?: boolean;
}

@Component({
  selector: 'name-link-formatter',
  template: `<div *ngIf="date" class='text-left' title='{{utcMonth}}/{{utcDate}}/{{utcYear}}'>{{utcMonth}}/{{utcDate}}/{{utcYear}} {{hours}}:{{minutes}}:{{seconds}}</div>`,
  styles: [
  ]
})
export class GRHistoryDateFormatterComponent implements OnInit, ICellRendererAngularComp {

  active = false;
  value: any;
  params: any;
  date: Date;
  utcDate: any;
  utcYear: any;
  utcMonth: any;
  hh: any;
  mm: any;
  ss: any;
  hours: any;
  minutes: any;
  seconds: any;

  agInit(params: ICellRendererParams & CellParams): void {
    this.value = params.value;
    if (params.value) {
      this.date = new Date(params.value);
      if (this.date) {
        const m = this.date.getUTCMonth() + 1;
        this.utcMonth = (m <= 9 ? '0' + m : m);
        this.utcDate = this.date.getUTCDate();
        this.utcYear = this.date.getUTCFullYear();
        this.hh = this.date.getHours();
        this.hours = (this.hh <= 9 ? '0' + this.hh : this.hh);
        this.mm = this.date.getMinutes();
        this.minutes = (this.mm <= 9 ? '0' + this.mm : this.mm);
        this.ss = this.date.getSeconds();
        this.seconds = (this.ss <= 9 ? '0' + this.ss : this.ss);
      }
    }

  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    console.log(this.params)
    this.params.context.componentParent.updateRow(this.params.rowIndex)
  }


}
