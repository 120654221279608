import $ from 'jquery';
import { Injectable } from '@angular/core';
import { AppGlobals } from './app.globals';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZettaUtils {
  public isProcessRunning = false;
  public isProjectAdmin = false;
  public isFileNotFound = false;

  //Save row data from entity edit mode
  private entityStateSubject = new Subject<boolean>();
  entityStateObservable$ = this.entityStateSubject.asObservable();

  setEntityStateChanged(state: boolean) {
    this.entityStateSubject.next(state);
  }
  

  // window reference
  getNativeWindow() {
    return window;
  }
  //  creating U Id
  generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  deepCloneObject(obj) {
    // return value is input is not an Object or Array.
    if (typeof (obj) !== 'object' || obj === null) {
      return obj;
    }

    let clone;

    if (Array.isArray(obj)) {
      clone = obj.slice();  // unlink Array reference.
    } else {
      clone = Object.assign({}, obj); // Unlink Object reference.
    }

    const keys = Object.keys(clone);

    for (let i = 0; i < keys.length; i++) {
      clone[keys[i]] = this.deepCloneObject(clone[keys[i]]); // recursively unlink reference to nested objects.
    }

    return clone; // return unlinked clone.
  }
  // array Sort
  sortList(a, b) {
    if (a.label === b.label) {
      return 0;
    } else {
      return (a.label < b.label) ? -1 : 1;
    }
  }

  getWidthOfText(txt) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = 'bold 16px Arial';
    return Math.round(ctx.measureText(txt + '&').width);
  }

  customSortFun(data, filed, order) {
    order = (order.toLowerCase() === 'asc') ? 1 : -1;
    data.sort((data1, data2) => {
      let value1 = data1[filed];
      let value2 = data2[filed];
      let result = null;
      value1 = value1 == 'n/a' ? null : value1;
      value2 = value2 == 'n/a' ? null : value2;
      if (value1 == null && value2 != null ) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (this.isValidDate(value1) && this.isValidDate(value2)) {
        const dateA: any = new Date(value1);
        const dateB: any = new Date(value2);
        return (order === 1) ? (dateA - dateB) : (dateB - dateA);
      } else if (value1.indexOf(',') < 0 && value1.indexOf('$') < 0 && isNaN(value1) &&
        typeof value1 === 'string' && typeof value2 === 'string') {
        value1 = value1.replace(/ +?/g, '').toLowerCase();
        value2 = value2.replace(/ +?/g, '').toLowerCase();
        result = value1 > value2 ? 1 : (value1 < value2 ? -1 : 0); // value1.localeCompare(value2);
      } else if (isNaN(value1) && typeof value1 === 'string' && typeof value2 === 'string') {
        value1 = $.trim(value1).toLowerCase();
        value2 = $.trim(value2).toLowerCase();
        result = value1.localeCompare(value2);
      } else {
        value1 = Number(value1);
        value2 = Number(value2);
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (order * result);
    });
  }

  isValidDate(strDate) {
    const myDateStr = new Date(strDate);
    if (!isNaN(myDateStr.getMonth())) {
      return true;
    }
    return false;
  }

  isValidNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /*
    ex :  commarization('10000000000',0);
    ex :  commarization('-10000000000',0)
  */
  commarization(num, fixed) {
    if (num === null || num === undefined) { return 'N/A'; }
    fixed = (!fixed || fixed < 0) ? 0 : fixed;
    num = Number(num);
    const b = (num).toPrecision(2).split('e'),
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
      d = c < 0 ? c : Math.abs(c),
      e = d + ['', 'K', 'M', 'B', 'T'][k];
    return e;
  }

  truncateDecimals(figure: any, decimals) {
      if (!decimals) decimals = 2;
      let d = Math.pow(10,decimals);
      return Number(((figure * d)/d).toFixed(decimals));
  }

  currencyFormat(num) {
    if (!num) { return 0; }
    const n = String(num),
      p = n.indexOf('.');
    return n.replace(
      /\d(?=(?:\d{3})+(?:\.|$))/g,
      (m, i) => p < 0 || i < p ? `${m},` : m
    );
  }

  getProjectEntityClassById(index) {
    switch (index) {
      case 1: return 'fas fa-user';
      case 2: return 'fas fa-map-marker';      //'fa fa-barcode';
      case 3: return 'fas fa-exchange';        //'fa fa-users';
      case 4: return 'fas fa-university';
      case 5: return 'fas fa-users';           //'fa fa-user-secret';
      case 6: return 'fas fa-balance-scale';   //'fa fa-star-o';
      case 7: return 'fas fa-building';      //'fa fa-balance-scale';
      case 8: return 'fas fa-file-alt';       //'fa fa-map-marker';
      case 9: return 'fas fa-user-secret';     //'fa fa-building-o';
      case 10: return 'fas fa-usd';             //'fa fa-exchange';
      case 11: return 'fas fa-cubes';           //'fa fa-tree';
      case 12: return 'fas fa-handshake';     //'fa fa-mobile';
      case 13: return 'fas fa-barcode';         //'fa fa-money';
      case 14: return 'fas fa-star';          //'fa fa-usd';
      case 15: return 'fas fa-cubes';
      case 16: return 'fas fa-tree';            //'fa fa-handshake-o';
      case 17: return 'fas fa-home';
      case 18: return 'fas fa-money';           //'fa fa-handshake-o';
      case 19: return 'fas fa-sitemap';
      case 20: return 'fas fa-mobile';          //'fa fa-handshake-o';
      case 21: return 'fas fa-handshake';
      case 22: return 'fas fa-sitemap';
      case 23: return 'fas fa-wrench';
      case 24: return 'fas fa-sun-o';
      case 25: return 'fas fa-cloud';
      case 26: return 'fas fa-bus';
      case 27: return 'fas fa-building';
      case 28: return 'fas fa-briefcase';
      case 29: return 'fas fa-shield';
      case 30: return 'fas fa-handshake';
      default: return 'fas fa-th-large';  //'fa fa-cube';
    }
  }

  getProjectEntityColor(index) {
    switch (index) {
      case 1: return '#f0a7d3';
      case 2: return '#d35162';
      case 3: return '#eeb049';
      case 4: return '#fac977';
      case 5: return '#00adef';
      case 6: return '#4d86ff';
      case 7: return '#4d86ff';
      case 8: return '#57cff2';
      case 9: return '#4990e2';
      case 10: return '#e5b500';
      case 11: return '#f57cc5';
      case 12: return '#4990e2';
      case 13: return '#00cb00';
      case 14: return '#e5b500';
      case 15: return '#f57cc5';
      case 16: return '#00adef';
      case 17: return '#00adef';
      case 18: return '#d35162';
      case 19: return '#f0a7d3';
      case 20: return '#0d99cc';
      case 21: return '#57cff2';
      case 22: return '#fac977';
      case 23: return '#fac977';
      case 24: return '#00cb00';
      case 25: return '#4d86ff';
      case 26: return '#d35162';
      case 27: return '#4990e2';
      case 28: return '#eeb049';
      case 29: return '#00adef';
      case 30: return '#f57cc5';
      default: return '#0d71a1';
    }
  }

  getEntityIconClassByName(name) {
    switch (name) {
      case 'fa-user-alt': return 'fas fa-user-alt';
      case 'fa-users': return 'fas fa-users';
      case 'fa-university': return 'fas fa-university';
      case 'fa-balance-scale': return 'fas fa-balance-scale';
      case 'fa-map-marker': return 'fas fa-map-marker';
      case 'fa-building': return 'fas fa-building';
      case 'fa-file-alt': return 'fas fa-file-alt';
      case 'fa-exchange': return 'fas fa-exchange';
      case 'fa-money-bill': return 'fas fa-money-bill';
      case 'fa-dollar-sign': return 'fas fa-dollar-sign';
      case 'fa-cubes': return 'fas fa-cubes';
      case 'fa-handshake': return 'fas fa-handshake';
      case 'fa-bullseye-pointer': return 'fal fa-bullseye-pointer';
      case 'fa-award': return 'fal fa-award';
      case 'fa-cloud': return 'fal fa-cloud';
      case 'fa-barcode-read': return 'fal fa-barcode-read';
      case 'fa-badge-dollar': return 'fal fa-badge-dollar';
      case 'fa-bus': return 'fal fa-bus';
      case 'fa-user-graduate': return 'fal fa-user-graduate';
      case 'fa-atom-alt': return 'fal fa-atom-alt';
      case 'fa-splotch': return 'fal fa-splotch';
      case 'fa-trees': return 'fal fa-trees';
      case 'fa-ethernet': return 'fal fa-ethernet';
      case 'fa-briefcase': return 'fal fa-briefcase';
      case 'fa-warehouse-alt': return 'fal fa-warehouse-alt';
      case 'fa-wrench': return 'fal fa-wrench';
      case 'fa-shield-alt': return 'fal fa-shield-alt';
      case 'fa-tablet': return 'fal fa-tablet';
      case 'fa-sun-dust': return 'fal fa-sun-dust';
      case 'fa-project-diagram': return 'fal fa-project-diagram';
      default: return 'fas fa-cube';
    }
  }

  getEntityIconColorByName(name) {
    switch (name) {
      case 'fa-user-alt': return '#FF591C';
      case 'fa-users': return '#00ADEF';
      case 'fa-university': return '#FFBA00';
      case 'fa-balance-scale': return '#4D86FF';
      case 'fa-map-marker': return '#D75363';
      case 'fa-building': return '#4990E2';
      case 'fa-file-alt': return '#0D71A1';
      case 'fa-exchange': return '#FFBA00';
      case 'fa-money-bill': return '#00CB00';
      case 'fa-dollar-sign': return '#FFBA00';
      case 'fa-cubes': return '#FF591C';
      case 'fa-handshake': return '#2FB1EB';
      case 'fa-bullseye-pointer': return '#00ADEF';
      case 'fa-award': return '#F57CC5';
      case 'fa-cloud': return '#4D86FF';
      case 'fa-barcode-read': return '#0D99CC';
      case 'fa-badge-dollar': return '#00ADEF';
      case 'fa-bus': return '#D35162';
      case 'fa-user-graduate': return '#E5B500';
      case 'fa-atom-alt': return '#00ADEF';
      case 'fa-splotch': return '#00CB00';
      case 'fa-trees': return '#F57CC5';
      case 'fa-ethernet': return '#E5B500';
      case 'fa-briefcase': return '#00CB00';
      case 'fa-warehouse-alt': return '#F57CC5';
      case 'fa-wrench': return '#E5B500';
      case 'fa-shield-alt': return '#00ADEF';
      case 'fa-tablet': return '#E5B500';
      case 'fa-sun-dust': return '#00CB00';
      case 'fa-project-diagram': return '#F57CC5';
      default: return '#0D71A1';
    }
  }

  getCatalogIconById(index) {
    switch (index) {
      case 291: return 'fal fa-user';                       //f007
      case 330: return 'fal fa-barcode-read';               //f464  
      case 331: return 'fal fa-users';                      //f0c0
      case 332: return 'fal fa-university';                 //f19c
      case 333: return 'fal fa-user-graduate';              //f501  
      case 334: return 'fal fa-splotch';                    //f5bc  
      case 506: return 'fal fa-balance-scale';              //f24e  
      case 507: return 'fal fa-map-marker';                 //f041  
      case 508: return 'fal fa-building';                   //f1ad  
      case 509: return 'fal fa-exchange';                   //f0ec  
      case 510: return 'fal fa-trees';                      //f3cd 
      case 511: return 'fal fa-mobile-alt';                 //f3cd 
      case 512: return 'fal fa-money-bill';                 //f0d6   
      case 513: return 'fal fa-dollar-sign';                //f155 
      case 514: return 'fal fa-cubes';                      //f1b3
      case 515: return 'fal fa-handshake';                  //f2b5
      case 516: return 'fal fa-warehouse-alt';              //f495  
      case 517: return 'fal fa-handshake';                  
      case 518: return 'fal fa-award';                      //f559    
      case 519: return 'fal fa-badge-dollar';               //f645
      case 520: return 'fal fa-atom-alt';                   //f5d3   
      case 521: return 'fal fa-ball-pile';                  //f77e
      case 522: return 'fal fa-wrench';                     //f0ad
      case 523: return 'fal fa-sun-dust';                   //f764
      case 524: return 'fal fa-cloud';                      //f0c2
      case 525: return 'fal fa-bus-school';                 //f5dd
      case 526: return 'fal fa-building';
      case 527: return 'fal fa-briefcase';                  //f0b1
      case 528: return 'fal fa-shield-alt';                 //f3ed
      case 529: return 'fal fa-project-diagram';            //f542
      default:  return 'fal fa-user';
    }
  }

  getSolidCatalogIconById(index) {
    switch (index) {
      case 291: return 'fas fa-user';                       //f007
      case 330: return 'fas fa-barcode-read';               //f464  
      case 331: return 'fas fa-users';                      //f0c0
      case 332: return 'fas fa-university';                 //f19c
      case 333: return 'fas fa-user-graduate';              //f501  
      case 334: return 'fas fa-splotch';                    //f5bc  
      case 506: return 'fas fa-balance-scale';              //f24e  
      case 507: return 'fas fa-map-marker';                 //f041  
      case 508: return 'fas fa-building';                   //f1ad  
      case 509: return 'fas fa-exchange';                   //f0ec  
      case 510: return 'fas fa-trees';                      //f3cd 
      case 511: return 'fas fa-mobile-alt';                 //f3cd 
      case 512: return 'fas fa-money-bill';                 //f0d6   
      case 513: return 'fas fa-dollar-sign';                //f155 
      case 514: return 'fas fa-cubes';                      //f1b3
      case 515: return 'fas fa-handshake';                  //f2b5
      case 516: return 'fas fa-warehouse-alt';              //f495  
      case 517: return 'fas fa-handshake';                  
      case 518: return 'fas fa-award';                      //f559    
      case 519: return 'fas fa-badge-dollar';               //f645
      case 520: return 'fas fa-atom-alt';                   //f5d3   
      case 521: return 'fas fa-ball-pile';                  //f77e
      case 522: return 'fas fa-wrench';                     //f0ad
      case 523: return 'fas fa-sun-dust';                   //f764
      case 524: return 'fas fa-cloud';                      //f0c2
      case 525: return 'fas fa-bus-school';                 //f5dd
      case 526: return 'fas fa-building';
      case 527: return 'fas fa-briefcase';                  //f0b1
      case 528: return 'fas fa-shield-alt';                 //f3ed
      case 529: return 'fas fa-project-diagram';            //f542
      default:  return 'fas fa-user';
    }
  }

  getCatalogIconColor(index) {
    switch (index) {
      case 291: return '#f0a7d3';                      
      case 330: return '#26A3D2';           
      case 331: return '#56CFF2';                    
      case 332: return '#FBD699';              
      case 333: return '#60CCF5';             
      case 334: return '#00CB00';                   
      case 506: return '#4D86FF';              
      case 507: return '#D45162';               
      case 508: return '#4890E2';                  
      case 509: return '#EFB049';                  
      case 510: return '#F67CC5';                      
      case 511: return '#E4B500';                 
      case 512: return '#00CB00';                
      case 513: return '#E4B500';               
      case 514: return '#F68CCC';                      
      case 515: return '#03AEEF';                  
      case 516: return '#03AEEF';              
      case 517: return '#D45162';                  
      case 518: return '#F0A7D3';                         
      case 519: return '#22A1D0';              
      case 520: return '#5ED1F2';                   
      case 521: return '#FCD18B';                  
      case 522: return '#FAC978';                     
      case 523: return '#00CB00';                  
      case 524: return '#4D86FF';                      
      case 525: return '#D55A6B';                 
      case 526: return '#5296E3';
      case 527: return '#EFB453';               
      case 528: return '#03AEEF';                
      case 529: return '#F67CC5';          
      default:  return '#f0a7d3';
    }
  }

  getTaskNameIcon(str) {
    switch (str) {
      case 'match training':
        return 'fas fa-graduation-cap';
      case 'merge training':
        return 'fas fa-graduation-cap';
      case 'merge fixing':
        return 'fas fa-wrench';
      default:
        return 'fas fa-wrench';
    }
  }

  getTotalPages(count: number, perPage: number): number {
    return Math.ceil(count / perPage) || 0;
  }

  getTaskStatusIcon(status, flag) {
    if (status === "Setup")
      return 'fas fa-star';
    else if (status === "Training" && !flag)
      return 'fas fa-hourglass-half';
    else if (status === "Training" && flag)
      return 'fas fa-check';
   // return flag ? 'fa fa-check' : 'fa fa-hourglass-start';
    // switch (index) {
    //   case 1:
    //     return 'fa fa-hourglass-start';
    //     break;
    //   case 2:
    //     return 'fa fa-star';
    //     break;
    //   default:
    //     return 'fa fa-check';
    //     break;
    // }
  }

  getTaskStatusColor(status, flag) {
    if (status === "Setup")
      return '#EEB049';
    else if (status === "Training" && !flag)
      return '#0D71A1';
    else if (status === "Training" && flag)
      return '#57cff2';
    //return flag ? '#57cff2' : '#ff7000';
    // switch (index) {
    //   case 1:
    //     return '#ff7000';
    //     break;
    //   case 2:
    //     return '#fac977';
    //     break;
    //   default:
    //     return '#57cff2';
    //     break;
    // }
  }

  /**
   * Convert view port width to px unit, Dynamic way to create width based on viewport width
   * @param {*} value
   * @returns
   * @memberof zettaUtils
   */
  vwTOpx(value) {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    return (x * value) / 100;
  }


  /**
   * Convert view port height to px unit, Dynamic way to adjust height based on viewport height
   *
   * @param {*} value
   * @returns
   * @memberof zettaUtils
   */
  vhTOpx(value) {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    return (y * value) / 100;
  }

  getTrainResolveModelColorByStatus(value) {    
    let defaultColor = '#0e709f';
    value = value ? value.trim().toUpperCase() : '';
    if (value === 'READY FOR APPROVAL') {
      defaultColor = '#eeb049';
    } else if (value === 'UNASSIGNED') {
      defaultColor = '#a0a0a0';
    } else if (value === 'COMPLETED') {
      defaultColor = '#00cb00';
    } else if (value === 'IN REVIEW') {
      defaultColor = '#0e709f';
    } else if (value === 'PENDING APPROVAL' || value == 'PENDING FOR APPROVAL') {
      defaultColor = '#eeb049';
    }
    return defaultColor;
  }

  getManageJobsColorByStatus(value) {
    return value ? '#4bb100' : '#eeb049';
  }

  setGridAutoScrollTogether(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').on('scroll', function (e) {
      $('#' + uniqueParentID + ' .slick-viewport').scrollLeft(e.target.scrollLeft);//.scrollTop(e.target.scrollTop); // commenting vertical combination scrolling as its not required for now
    });
  }

  setGridHorizontalScrollTogether(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').on('scroll', function (e) {
      $('#' + uniqueParentID + ' .slick-viewport').scrollTop(e.target.scrollTop);
    });
  }

  removeGridAutoScrollTogetherEvent(uniqueParentID) {
    $('#' + uniqueParentID + ' .slick-viewport').unbind('scroll');
  }

  virtualScroll(parentClass, position = 'top') {
    const scrolltop = $(`.${parentClass} .slick-viewport.slick-viewport-${position}.slick-viewport-left`).scrollTop();
    if (scrolltop !== 0) {
      const clientHeight = $(`.${parentClass} .grid-canvas.grid-canvas-${position}.grid-canvas-left`).last()[0].clientHeight;
      const innerheight = $(`.${parentClass} .slick-viewport.slick-viewport-${position}.slick-viewport-left`).innerHeight();
      const totalength = innerheight + scrolltop;
      return totalength > clientHeight - 1;
    }
  }
  agGridVirtualScroll(parentClass, position = 'top') {
    const scrolltop = $(`.${parentClass} .ag-body-viewport.ag-layout-normal.ag-row-no-animation`).scrollTop();
    if (scrolltop !== 0) {
      const clientHeight = $(`.${parentClass} .ag-center-cols-viewport`).last()[0].clientHeight;
      const innerheight = $(`.${parentClass} .ag-body-viewport.ag-layout-normal.ag-row-no-animation`).innerHeight();
      const totalength = innerheight + scrolltop;
      return totalength > clientHeight - 8;
    }
  }

  getStatusColor(status) {
    if (status.is_errored) {
      return '#f70e0e';
    } else {
      return '#08c';
    }
  }

  convertIntoSeconds(obj) {
    let totalSeconds = 0;
    if (obj) {
      totalSeconds = parseInt(obj['days']) > 0 ? (parseInt(obj['days']) * 3600 * 24) : 0;
      totalSeconds += parseInt(obj['hours']) > 0 ? (parseInt(obj['hours']) * 3600) : 0;
      totalSeconds += parseInt(obj['minutes']) > 0 ? (parseInt(obj['minutes']) * 60) : 0;
      totalSeconds += parseInt(obj['seconds']) > 0 ? (parseInt(obj['seconds'])) : 0;
    }
    return totalSeconds;
  }

  secondsToDhms(seconds) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    const returnStr = (dDisplay + hDisplay + mDisplay + sDisplay);
    return returnStr ? returnStr : '0 second';
  }

  convertHHMMSStoSeconds(hhmmss) {
    let totalSeconds = 0;
    var arr = hhmmss.split(":");
    if (arr) {
      totalSeconds = parseInt(arr[0]) > 0 ? (parseInt(arr[0]) * 3600) : 0;
      totalSeconds += parseInt(arr[1]) > 0 ? (parseInt(arr[1]) * 60) : 0;
      totalSeconds += parseInt(arr[2]) > 0 ? (parseInt(arr[2])) : 0;
    }
    return totalSeconds;
  }

  secondsToHHMMSS(seconds) {
    seconds = Number(seconds);
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const hDisplay = h > 9 ? h : '0' + h;
    const mDisplay = m > 9 ? m : '0' + m;
    const sDisplay = s > 9 ? s : '0' + s;
    const returnStr = (hDisplay + ':' + mDisplay + ':' + sDisplay);
    return returnStr ? returnStr : '00:00:00';
  }

  diffDates(start, end) {
    if(start !== undefined && start !== null && start !== '' && end !== undefined && end !== null && end !== '') {
        var fechaInicio = new Date(start).getTime();
        var fechaFin    = new Date(end).getTime();
        if (fechaInicio > fechaFin){
          return "";
        } else {
          var seconds = (fechaFin - fechaInicio)/1000;
          let diff = this.secondsToHHMMSS(seconds)
          return diff;
        }
    } else {
      return "";
    }
  }

  getStateList(state) {
    switch (state) {
      case 'create-project':
        return ['Describe Project', 'Add Users', 'Add Data Sets', 'Edit Entity Model','Map Data Sets to Entity Model'];
        case 'create-classify-project':
          return ['Describe Project', 'Add Users', 'Select Object to be Classified', 'Define New Classifier', 'Add Training Data', 'Add Data to Project'];          
          case 'create-classify-concept-parser-project':
          return ['Describe Project', 'Add Users', 'Select Concept to be Parsed', 'Define New Sub-Concepts', 'Add Training Data', 'Add Data to Project'];
          case 'manage-project-data':
          return ['Select Object to be Classified', 'Define New Classifier', 'Add Training Data', 'Add Data to Project'];
          case 'manage-classify-concept-parser-project-data':
            return ['Add Training Data', 'Add Data to Project'];    
      case 'create-dataset':
        return ['Select Data Source', 'Define Data Sets', 'Add User Entitlements', 'Schedule Refresh'];
      case 'create-datasource':
        return ['Describe Data Source', 'Define Connection', 'Add Admin Users', 'Set User Entitlements'];
      case 'create-entity':
        return ['Describe Entity', 'Add Admin Users', 'Add Attributes'];
      case 'create-catalog':
       return ['Describe Catalog', 'Add Users', 'Define Semantic Objects', 'Define Concepts'];
      case 'classify-set':
       return ['Select Data Catalog', 'Select Semantic Objects', 'Map Data Columns'];
      case 'classify-train-model':
       return ['Review Prior Training Data', 'Select Data Sets', 'Map Data Columns', 'Finalize Training Data'];
      case 'synonyms':
       return ['Select Data Catalog', 'Select Semantic Objects', 'Map Concepts'];
       case 'create-bussiness-rules':
       return ['Select Concept', 'Select an Out-of-the-Box Rule', 'Define Custom Rule', 'Review Prior Mappings','Select Data Sets', 'Map Data Column[s]', 'Add Users', 'Schedule Run Execution'];
       case 'create-technical-rules':
        return ['Define Custom Rule', 'Add Users', 'Schedule Run Execution'];
    }
  }

  getPropertyFromEntitlements(pathStr) {
    const entitlementsObj = JSON.parse(sessionStorage.getItem('entitlements'));
    let currentObj;
    const searchString = pathStr.split('/');
    for (let index = 0; index < entitlementsObj.length; index++) {
      const element = entitlementsObj[index];
      if (element.name === searchString[0]) {
        for (let index = 1; index < searchString.length; index++) {
          if (currentObj) {
            currentObj = currentObj[searchString[index]];
          } else if (element.entitlement) {
            currentObj = element.entitlement[searchString[index]];
          }
        }
        break;
      }
    }
    return currentObj;
  }

  getDatasourceTypeById(index) {
    switch (index) {
      case 100:
        return '../assets/images/common/icon-Cloud.png';
      case 270:
        return '../assets/images/common/icon-Hadoop.png';
      case 503:
        return '../assets/images/common/icon-Database.png';
      case 271:
        return '../assets/images/common/icon-NoSQL.png';
      case 427:
        return '../assets/images/common/icon-Server.png';
      case 14950: 
       return '../assets/images/common/icon-snowflake.png';
    }
  }

  getBreadCrumbDatasourceTypeById(index) {
    switch (index) {
      case 100:
        return '../assets/images/common/icon-cloud-solid.png';
      case 270:
        return '../assets/images/common/icon-Hadoop.png';
      case 503:
        return '../assets/images/common/icon-Database.png';
      case 271:
        return '../assets/images/common/icon-NoSQL.png';
      case 427:
        return '../assets/images/common/icon-Server.png';
      case 14950: 
       return '../assets/images/common/icon-snowflake.png';
    }
  }

  getEntitlementid(name) {
    let entitlements = JSON.parse(sessionStorage.getItem('entitlements'));
    if (entitlements) {
      entitlements = entitlements.find(res => res.name === name);
      return entitlements.role_id;
    }
  }

  // need to send zs or zm as appShortName based on application
  getEntitlements(appShortName: string) {
    const userDetailList = JSON.parse(sessionStorage.getItem('userInfo'));
    const entitlementList = userDetailList.entitlements.find(res => res.app.short_name === appShortName);
    // const entitlementList = userDetailList.entitlements.find(res => res.app.short_name === 'zs-cl');
    if(entitlementList){
      sessionStorage.setItem('userApp', JSON.stringify(entitlementList.app));
      sessionStorage.setItem('userEntitlement', JSON.stringify(entitlementList.entitlement));
      sessionStorage.setItem('userRole', JSON.stringify(entitlementList.role));
    }
    
  }

  getUserGroupEntitlements(screenName: string, parentName: string, childName: string) {
    const userGroupEntitlement = JSON.parse(sessionStorage.getItem('userEntitlement'));
    if (userGroupEntitlement && userGroupEntitlement.screens) {
      let entitlements = userGroupEntitlement.screens.find(screen => screen.name === screenName);
      if (entitlements && entitlements.childs) {
        entitlements = entitlements.childs.find(parent =>
          parent.name === parentName).childs.find(children => children.name === childName);
      }
      if (entitlements) {
        return entitlements.enable;
      }
    }
  }

  getProjectAdmin(userId) {
    const userDetail = JSON.parse(sessionStorage.getItem('userInfo'));
    this.isProjectAdmin = (userDetail.user_id === userId);
  }

  getShortName() {
    const userApp = JSON.parse(sessionStorage.getItem('userApp'));
    if (userApp) {
      return `/${userApp.short_name}`;
    }
  }

  getLoggedInUserInfo() {
    if (sessionStorage && sessionStorage.getItem('userInfo')) {
      return JSON.parse(sessionStorage.getItem('userInfo'));
    }
    return null;
  }

  setSessionStorageItem(key, data) {
    sessionStorage.setItem(key, data);
  }

  getSessionStorageItem(key) {
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  dateFormat(val) {
    if (val === null || val === undefined || val === '') { return; }
    const d = new Date(val);
    const m = d.getMonth() + 1;
    const month = (m <= 9 ? '0' + m : m);
    return `${month}/${d.getDate()}/${d.getFullYear()}`;
  }

  changeFavicon(imageUrl: string, title: string) {
    let link;
    link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = imageUrl;
    document.title = title;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  getDataSetIcon(index) {
    switch (parseInt(index)) {
      case 4:
        return 'fal fa-file-excel-o';
        break;
      case 272:
        return 'fas fa-database rdbms';
        break;
      case 361:
        return 'fal fa-database fixed';
        break;
      case 369:
        return 'fal fa-database tag-format';
        break;
      default:
        return 'fas fa-database rdbms';
        break;
    }
  }

  getDataSetIconColor(index) {
    switch (parseInt(index)) {
      case 4:
        return '#009900';
      case 272:
        return '#0E709F';
      case 361:
        return '#eeb049';
      case 369:
        return '#ff7000';
      default:
        return '#0E709F';
    }
  }

  getAppImageByAppShortName(appShortName) {
    switch (appShortName) {
      case 'zs-cl':
        return '../../../assets/images/common/fluree_icon_header-Classify.png';
      case 'z-ing':
        return '../../../assets/images/common/fluree_icon_header-Ingest.png';
      case 'zc':
        return '../../../assets/images/zettasense/ZettaSense_Consume.png';
      default:  //'zs'
        return '../../../assets/images/common/fluree_icon_header-Resolve.png';
    }
  }
  getAppImageLoginShortName(appShortName) {
    switch (appShortName) {
      case 'zs-cl':
        return '../../../assets/images/common/ZettaSense - Classify.png';
      case 'zs-in':
        return '../../../assets/images/common/ZettaSense - Ingest.png';
      case 'zc':
        return '../../../assets/images/common/ZettaSense - Consume.png';
      default:  //'zs'
        return '../../../assets/images/common/ZettaSense - Resolve.png';
    }
  }
  getSubTotal(grandTotal: any, subTotal:any, custom = false) {
    let formatedSubTotal = '';
    if (subTotal !== grandTotal) {
      subTotal = this.currencyFormat(subTotal); 
      if(custom) {
        formatedSubTotal = subTotal;
      } else {
        formatedSubTotal = subTotal + '/';
      }
    } 
    return formatedSubTotal;
  }

  getDatasourceTypeRDBMSById(index) {
    switch (index) {
      case AppGlobals.MICROSOFT_SQL_SERVER:
        return '../../../../../assets/images/common/icon-SQL-Server.png';
      case AppGlobals.ORACLE:
        return '../../../../../assets/images/common/icon-Oracle.png';
      case AppGlobals.MYSQL:
        return '../../../../../assets/images/common/icon-MySQL.png';
      case AppGlobals.POSTGRES_SQL:
        return '../../../../../assets/images/common/icon-Postgres.png';
    }
  }

  // To get the Icon according to Current status of classify project
  getIconByProjectCurrentStatus(name) {
    switch (name) {
      case AppGlobals.PROJECT_IN_PROGRESS: return 'fas fa-hourglass-half';
      case AppGlobals.PROJECT_PUBLISHED: return 'fa fa-newspaper-o';
      case AppGlobals.PROJECT_NEW: return 'fas fa-star';
      case AppGlobals.PROJECT_SETUP: return 'fas fa-star';
      case AppGlobals.PROJECT_DRAFT: return 'fas fa-file';
      case AppGlobals.PROJECT_PRODUCTION: return 'fas fa-hammer';
      case AppGlobals.PROJECT_TRAINING: return 'fas fa-hourglass-half';
      default: return 'fas fa-hourglass-half';
    }
  }

  // To get the Icon color according to Current status of classify project
  getIconColorByProjCurrentStatus(index) {
    switch (index) {
      case AppGlobals.PROJECT_IN_PROGRESS: return '#0D71A1';
      case AppGlobals.PROJECT_PUBLISHED: return '#00CB00';
      case AppGlobals.PROJECT_NEW: return '#EEB049';
      case AppGlobals.PROJECT_DRAFT: return '#A0A0A0';
      case AppGlobals.PROJECT_PRODUCTION: return '#CA00DB';
      case AppGlobals.PROJECT_SETUP: return '#EEB049';
      default: return '#0D71A1';
    }
  }

  getClassify() {
    const userApp = JSON.parse(sessionStorage.userInfo).apps;
    if (userApp) {
      const cl_apps = userApp.filter(item => item.name == AppGlobals.CLASSIFY_APP);
      if (cl_apps.length) {
        return cl_apps[0].name;
      }
    }
  }
  getCurrentPageNo(params, limit) {
    const startRow = params.request.startRow;
    const endRow = params.request.endRow;
    let pageNo = 1;
    if (startRow > 0) {
      let currentPage = endRow / limit + '';
      const newPage = currentPage.split('.');
      if (newPage.length > 1) {
        currentPage = (+newPage[0] + 1).toString();
      }
      pageNo = +currentPage;
    }
    return pageNo;
  }
  getSearchAndSortTerms(params) {
    let filterStr = "";
    let sortStr = "";
    let searchAndSortTerm = '';
    const filterKeys = params.request.filterModel;
    const sortingKeys = params.request.sortModel;
    if (filterKeys) {
      Object.entries(filterKeys).forEach(([key, value], index) => {
        filterStr += `&${key}=${value['filter']}`
      });
    }
    if (sortingKeys) {
      sortingKeys.forEach((sortKey) => {
        sortStr += `&orderby=${sortKey.colId}&type=${sortKey.sort}`;
      });
    }
    if (filterStr || sortStr) {
      searchAndSortTerm = filterStr + sortStr;
    }
    return searchAndSortTerm;
  }


  isAttributeSystemAttribute(attributeName) {
    let defaultSystemAttributes = this.getSystemAttributes();
    if (defaultSystemAttributes.indexOf(attributeName) > -1) {
      return true;
    }
    return false;
  }

  getSystemAttributes() {
    let defaultSystemAttributes = [AppGlobals.SOURCE_SYSTEM, AppGlobals.IDENTIFIER_AT_SOURCE,AppGlobals.LAST_RECORED_UPDATED_DATE,AppGlobals.SOURCE_PRIMARY_KEY, AppGlobals.SOURCE_SYSTEM_IDENTIFIER, AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY];
    return defaultSystemAttributes;
  }

}
