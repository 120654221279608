import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-copy-cell-renderer',
  template: `
    <ng-container *ngIf="params.colDef.field === 'action'">
      <i id="copy-{{ params.node.rowIndex }}" class="fa fa-copy actionInlineBtn pointer"
        data-toggle="modal"  data-target="{{ params.column.colId }}" data-backdrop="false"></i>
        <span class="text-center align-save-btn">
        <button type="button" id="save_entity-{{ params.node.rowIndex }}"
        class="btn btn-primary btn-zetta btn-dark mr-0 btn-save-entity d-none">Save</button>
        </span>
      
    </ng-container>
    <ng-template #addIcon>
      <i class="fa fa-plus actionInlineBtn"></i>
    </ng-template>
  `,
})
export class CopyIconRendererComponent implements ICellRendererAngularComp {
  params: any; 
  
  agInit(params: any): void {
    this.params = params; 
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
