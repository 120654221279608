import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../content.service';

@Component({
  selector: 'zetta-source-quality',
  templateUrl: './source-quality.component.html',
  styleUrls: ['./source-quality.component.scss']
})
export class SourceQualityComponent implements OnInit {
  total_records: number = 0;
  bad_records: number = 0;
  good_records: number = 0;
  isDimensionDataNotAvail: Boolean = false;
  sourceQualityDimention: any = [];
  public lineProjectResolvedChartDS = null;
  asOfDate;
  isLoading: Boolean = true;
  public entitiesDataSource = null;

  public lineProjectResolvedChartOptions: object = {
    'isMoneyFormat': false,
    'height': 130,
    'width': 360, 
    'resizeRequired': true,
    'label': 'year',
    'value': 'value',
    'lineColor': '#e5b500',
    'circleColor': '#ff0000'
  };

  public entitiesChartOptions: object = {
    'resizeRequired': true,
    'variable': 'count',
    'category': 'category',
    'isMoneyFormat': true,
    'labelPercentValue': 0,
    'labelPercentLabel': '',
  };

  public entitiesChartColors = ['#4bb100', '#d35162'];

  constructor(public contentSvc: ContentService) { }

  ngOnInit() {
    this.getSourceQualityRecords();
    this.getSourceQualityDimension();
    this.getSourceQualityRunModel();
  }

  getSourceQualityRecords() {
    this.contentSvc.getSourceQualityRecords().subscribe(data => {
      const allRecords = data[0];
      this.total_records = allRecords.total_records;
      this.bad_records = allRecords.bad_records;
      this.good_records = allRecords.good_records;
      this.entitiesChartOptions['labelPercentValue'] = allRecords.measured_dq_score.toFixed(2);
      let dateQuota = new Date(allRecords.last_updated_date);
      this.asOfDate = dateQuota.toLocaleDateString();
      this.entitiesDataSource = this.getDonutParseData(data);
    }, error => {
      this.entitiesDataSource = [];
    });
  }

  getSourceQualityDimension() {
    this.contentSvc.getSourceQualityDimension().subscribe(resp => {
      if (resp.length === 0) {
        this.isDimensionDataNotAvail = true;
      } else {
        this.isDimensionDataNotAvail = false;
        this.sourceQualityDimention = resp;
        this.sourceQualityDimention.forEach(element => {
          element.value = element.measured_dq_score.toFixed(2);
          element.dimensionColor = element.measured_dq_score >= 75 ? 'high' : (element.measured_dq_score < 75 && element.measured_dq_score >= 50) ? 'medium' : 'low';
        });
      }
    });
  }

  getSourceQualityRunModel() {
    this.contentSvc.getSourceQualityRunModel().subscribe(resp => {
      this.isLoading = false;
      this.lineProjectResolvedChartDS = this.prepareLineChartData(resp);
    }, error => {
      this.isLoading = false;
      this.lineProjectResolvedChartDS = [];
    });
  }

  prepareLineChartData(data) {
    const dataSet = [];
    data.forEach((element) => {
      const d = new Date(element['last_updated_date']);
      if (element && ('measured_dq_score' in element)) {
        dataSet.push({ 'year': d.getFullYear(), 'value': element['measured_dq_score'].toFixed(2), 'fulldate': d });
      }
    });
    return dataSet;
  }

  getDonutParseData(data) {
    const dataSet = [];
    data.forEach((element) => {
      if (element['good_records']) {
        dataSet.push({ 'count': element['good_records'],display_name: element.display_name, category: element['good_records']});
      } 
      if (element['bad_records']) {
        dataSet.push({count: element['bad_records'], display_name: element.display_name,category: element['bad_records']});
      }
    });
    return dataSet;
  }
}
