import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

export const projectNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
	let cssClass = 'wd-11rem';
	function getIconByEntityID() {
		return zettaUtilsInstance.getProjectEntityClassById(dataContext.entity_id);
	}

	function getColorByEntityID() {
		return zettaUtilsInstance.getProjectEntityColor(dataContext.entity_id);
	}

	function getProjectDesc() {
		const hasDescription = (columnDef.params && columnDef.params['isDisplayDesc'] !== undefined) ? columnDef.params.isDisplayDesc : true;
		const applyWidth = (columnDef.params && columnDef.params['applyWidth'] !== undefined) ? columnDef.params.applyWidth : false;
		if (applyWidth) {
			cssClass = 'wd-8rem';
		}
		return hasDescription ? dataContext.description : '';
	}

	dataContext.name =dataContext.name ? dataContext.name : '';
	dataContext.description = dataContext.description ? dataContext.description : '';
	let project_name;
	if(dataContext.name.includes('"')){
		dataContext.name = dataContext.name.replace(/"/g, "'");
	}
	project_name = dataContext.name;
	if(dataContext.name.length > 148){
		project_name = (dataContext.name.substring(0,148)).trim() + ' ...'
	}
	if (getProjectDesc() !== '') {
		let desc = dataContext.description;
		if(dataContext.isClassifyProject)		{
			if(dataContext.description.length > 155){
				desc = (dataContext.description.substring(0,155)).trim() + '...'
			}
		}else{
			if(dataContext.description.length > 52){
				desc = (dataContext.description.substring(0,52)).trim() + '...'
			}
		}
		
		return `
			<div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
			  <div class="col-auto m-0 p-0 text-left" style="width: 20px !important;">
				  <i class='${getIconByEntityID()} m-0 p-0' style='color:${getColorByEntityID()}; '></i>
			  </div>
			  <div class="ml-2 m-0 p-0 text-left ${cssClass} line-height-12 text-truncate">
					<span title="${dataContext.name}" class="text-truncate blueLink">${project_name}</span><br>
					<span title="${dataContext.description}" class="text-truncate project-des">${desc}</span>					
			  </div>
		  </div>`;
	} else {
		if(columnDef.params && columnDef.params.isResolveTask) {
			return `
				  <div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
					<div class="col-auto m-0 p-0 text-left" style="width: 20px !important;">
						<i class='${getIconByEntityID()} m-0 p-0' style='color:${getColorByEntityID()}; '></i>
					</div>
					<div class="ml-2 m-0 p-0 text-left wd-7rem text-truncate">
						<span title="${dataContext.name}" class="pointer hyper-link">${project_name}</span>
					</div>
				</div>`;
		} else {
			return `
				  <div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
					<div class="col-auto m-0 p-0 text-left" style="width: 20px !important;">
						<i class='${getIconByEntityID()} m-0 p-0' style='color:${getColorByEntityID()}; '></i>
					</div>
					<div class="ml-2 m-0 p-0 text-left wd-11rem text-truncate">
						<span title="${dataContext.name}" class="pointer hyper-link">${project_name}</span>
					</div>
				</div>`;
		}
	}
};
