import { Component, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';

@Component({
	selector: 'zm-ad-model-results',
	templateUrl: './ad-model-results.component.html',
	styleUrls: ['./ad-model-results.component.scss']
})

export class AdModelResultsComponent implements OnInit {


	public lineAnalyticChartOptions1: object = {
		// 'chartID': 1,	//use only with subcharts
		'subchart': false,
		'height': 210,
		'width': 500,
		'resizeRequired': true,
		'labelY': 'Year',
		'labelX': 'Records',
		'barColor1': '#01B3C6',
		'barColor2': '#0192a4',
		'margin' : { 'top': 25, 'right': 50, 'bottom': 0, 'left': 150 },
		'isMoneyFormat':true
	};
	public lineAnalyticChartDs1 = null;

	public lineAnalyticChartOptions2: object = {
		// 'chartID': 1,	//use only with subcharts
		'subchart': false,
		'height': 210,
		'width': 450,
		'resizeRequired': true,
		'labelY': 'Label',
		'labelX': 'Value',
		'barColor1': '#F48341',
		'barColor2': '#d36230',
		'margin' : { 'top': 25, 'right': 50, 'bottom': 0, 'left': 210 },
		'isMoneyFormat':true
	};
	public lineAnalyticChartDs2 = null;


	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName: string = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	public entitiesChartOptions: object = {
		'resizeRequired': false,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'labelPercentValue' : 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence'
	};

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];
	public lineChartOptions: object = {
		'isMoneyFormat': false,
		'height': 115,
		'resizeRequired': false,
		'labelX': 'label',
		'labelY': 'value',
		'lineColor': '#00cb00',
		'circleColor': '#ff0000'
	};

	public lineChartDS = null;

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZmZettaUtils,
		private _projectService: AnalyticService,
		public observeSvc: ZmObservableService,
		private activeRoute: ActivatedRoute, private router: Router) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
		
	}

	ngOnInit() {


		// let dataSet = [];
		// var yy: string;
		// for (var i = 0; i < 5; i++) {
		//   if(i<10){ yy='0'} else { yy=''}
		//   let d = new Date((Math.floor(Math.random() * (12 - 1)) + 1) + '/' +
		// 				   (Math.floor(Math.random() * (28 - 1)) + 1) + '/' +
		// 				   '20' + yy + (i));
		//   dataSet.push({ 'label': d.getFullYear(), 'value': Math.floor(Math.random() * (1000 - 50)) + 50, 'fulldate':d });
		// }
		// this.lineAnalyticChartDs1 = dataSet;

		let dataSet = [
						{ 'label': 'zs_full_name', 'value': 50 },
						{ 'label': 'zs_alternate_name', 'value': 120 },
						{ 'label': 'zs_nace_code', 'value': 280 },
						{ 'label': 'zs_sic_code', 'value': 340 },
						{ 'label': 'zs_lei', 'value': 480 },
						// { 'label': 'zs_swift_bic', 'value': 610 },
					  ];


		this.lineAnalyticChartDs1 = dataSet;




		let dataSet1 = [
			{ 'label': '11k_Clone_Delete', 'value': 50 },
			{ 'label': 'Test_libpostal_project', 'value': 120 },
			{ 'label': 'Test_libpostal_project', 'value': 300 },
			{ 'label': 'Dummey_Resolve', 'value': 390 },
			{ 'label': 'Generated Data 5K_Veda', 'value': 485 },
			{ 'label': 'Institution Real Data 11K', 'value': 530 },
			{ 'label': 'Generated Data 3K_Veda', 'value': 623 },
			// { 'label': 'Test_libpostal_project 8K', 'value': 710 },
			// { 'label': 'Institution Real Data 15K', 'value': 772 },
		  ];

		
		this.lineAnalyticChartDs2 = dataSet1;


		// const parent = this;
		// const routeParams = parent.activeRoute.snapshot.params;
		// parent.currentURL = parent.router.url;
		// parent.getEntityResolvedData(parent.userinfo.userid, routeParams.id);
		

		// parent._projectService.getEntitiesSummaryData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.statisticsObj = {
		// 			'model_runs': 0,
		// 			'model_runs_as_of': new Date(),
		// 			'unique_institutions': 0,
		// 			'compression_ratio': 'N/A'
		// 		};
				
		// 	for (let index = 0; index < data.length; index++) {
		// 		const obj = data[index];
		// 		if (obj.name === 'run_number') {
		// 			parent.statisticsObj['model_runs'] = obj['value'] || 0;
		// 		}
		// 		else if (obj.name === 'as_of') {
		// 			parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
		// 		} else if (obj.name === 'total_entities') {
		// 			parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
		// 		} else if (obj.name === 'compression_ratio') {
		// 			parent.statisticsObj['compression_ratio'] = obj['count'] || 'N/A';
		// 		} else if (obj.name === 'overall_confidence') {
		// 			parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
		// 			parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
		// 		}
		// 	}
		// 		parent.entitiesDataSource = parent.getDonutParseData(data);
		// 	},
		// 	error => {
		// 		parent.entitiesDataSource = [];
		// 		parent.statisticsObj = {'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 'N/A'};
		// 	}
		// );

	}



	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
				this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	
	// prepareLineChartData(data) {
	//     const dataSet = [];
	//     data.forEach((element) => {
	//     	const d = new Date(element['createdts']);
	//         dataSet.push({ 'label': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
	//     });
	//     return dataSet;
	//   }
	  
	  refresh() {
		window.location.reload();
	  }
}
