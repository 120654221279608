import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { AnalyticService } from '../analytic.service';
import { ZmContentService } from '../zm-content.service';
import { numericFormatter } from '../../../common/zm-shared/zm-formatters/numericFormatter';
import { numericMockFormatter } from '../../../common/zm-shared/zm-formatters/numericMockFormatter';
import { versionMockFormatter } from '../../../common/zm-shared/zm-formatters/versionMockFormatter';
// import { analyticNameFormattter } from '../../../common/zm-shared/zm-formatters/analyticNameFormatter';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { porcentMockFormatter } from '../../../common/zm-shared/zm-formatters/porcentMockFormatter';
import { actionsFormatter } from '../../../common/shared/formatters/actionsFormatter';
import { ZmZettaUtils } from '../../../common/zm-shared/zm-zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
// import { Analytic } from '../analytic.model';

const analyticNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
	// if(dataContext.icon_id=='11'){ var img = "icon-analytics-correlation.png" };
	// if(dataContext.icon_id=='12'){ var img = "icon-analytics-classification.png" };
	// if(dataContext.icon_id=='13'){ var img = "icon-analytics-clustering.png" };
	// if(dataContext.icon_id=='14'){ var img = "icon-analytics-simulation.png" };
	if(dataContext.icon_id=='15'){ var img = "icon-workbooks1.png" };
	return `
		<div class="formatter project-title-formatter-nodesc pointer m-0 p-0" style="margin-left: -5px !important;">
			<div class='text-truncate text-regular hyper-link m-0 p-0' title='${dataContext.Name}'>
				<div class="pull-left p-0 m-0 mr-2"><img class="img-size pb-1" src="../../../../assets/images/zettamesh/${img}"></div>
				<div class="p-0 m-0 mr-2">
					<p class="analytic-name m-0 text-truncate" title='${dataContext.Name}'>${dataContext.Name}</p>
				</div>
			</div>		
		</div>`;
};

  
@Component({
  selector: 'zetta-zm-workbooks',
  templateUrl: './zm-workbooks.component.html',
  styleUrls: ['./zm-workbooks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZmWorkbooksComponent implements OnInit {

  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'pageSize': 6, 'isNormalPagination': false };
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 30;
  noData = false;
  workbooks: any = [];
  workbookTableColDef: any;

  constructor(
              private ZmContentSvc: ZmContentService,
              public zettaUtils: ZmZettaUtils,
              private _router: Router) { }

  ngOnInit() {
    this.getWorkbooksList();
    
    this.workbookTableColDef = [
      {
        'displayname': '',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'cssClass': 'text-center',
        'formatter': actionsFormatter,
        'maxWidth': 20,
      },
      {
        'displayname': 'Workbook Name',
        'physicalname': 'Name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 250,
        'formatter': analyticNameFormattter,
      },
      {
        'displayname': 'Description',
        'physicalname': 'Description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': null,
        'minWidth': 250,
      },
      {
        'displayname': '#Users',
        'physicalname': 'num_users',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'minWidth': 80,
        'formatter': numericMockFormatter,
      },
      {
        'displayname': '#Data Sets',
        'physicalname': 'num_ds',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': numericMockFormatter,
      },
      {
        'displayname': 'Version',
        'physicalname': 'Version',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': versionMockFormatter,
      },
      {
        'displayname': 'Last Modified',
        'physicalname': 'UpdatedTs',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': dateFormatter,
      },
      {
        'displayname': '# of Model Runs',
        'physicalname': 'num_model_runs',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'mixWidth': 60,
        'formatter': numericMockFormatter,
      },
      {
        'displayname': 'Last Run Date',
        'physicalname': 'UpdatedTs',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'formatter': dateFormatter,
      }      
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30
    };
  }

  // getWorkbooksList() {
  //   this.ZmContentSvc.getWorkbooksList().subscribe((data) => {
  //     console.log(data)
  //     this.workbooks = data['currentpage'];
  //     if (this.workbooks.length === 0) {
  //       this.noData = true;
  //     }
  //     this.isLoading = false;
  //     this.total = data['totalrecords'];
  //     let concatedRows = this.workbooks;

  //     if (this.grid) {
  //       concatedRows = [...this.grid.dataView.getItems(), ...this.workbooks];
  //       this.workbooks.forEach(element => {
  //         element.id = Math.random();
  //       });
  //       this.workbooks = concatedRows;
  //       this.grid.dataView.setItems(concatedRows);
  //       this.grid.gridService.setSelectedRows([]);
  //       this.hasScrolled = false;
  //     }
  //   }, err => {
  //     this.isLoading = false;
  //   });
  // }


  getWorkbooksList() {
    this.ZmContentSvc.getWorkbooks(this.loggedInUserDetails.user_id).subscribe((data) => {
      
      this.workbooks = data;
      if (this.workbooks.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      this.total = data.length;
      // let concatedRows = this.workbooks;
      // concatedRows = [...this.grid.dataView.getItems(), ...this.workbooks];
      // this.workbooks = concatedRows;
      this.workbooks.forEach(element => {
        element.id = Math.random();
        element.num_users = Math.floor(Math.random() * 8) + 1;
        element.num_ds = Math.floor(Math.random() * 4) + 1;
        element.num_model_runs = Math.floor(Math.random() * 14) + 1;
        element.icon_id = '15';
      });
      if (this.grid) {
        this.grid.dataView.setItems(this.workbooks);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  // goToPage(n: number): void {
  //   this.page = n;
  //   this.getWorkbooksList();
  // }

  // onNext(): void {
  //   this.page++;
  //   this.getWorkbooksList();
  // }

  // onPrev(): void {
  //   this.page--;
  //   this.getWorkbooksList();
  // }

  // totalPages(): number {
  //   return this.zettaUtils.getTotalPages(this.total, this.limit);
  // }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.Name) {
        this._router.navigate(['/zm/workbooks', row.WorkBookId]);
      }
    }
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    $('#context_menu')
      .removeClass('d-none')
      .data('row', args.row)
      .css('top', eventEle.pageY)
      .css('left', eventEle.pageX + 5)
      .css('position', 'fixed')
      .css('display', 'block')
      .show();

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      if (action === 'Edit') {
        // parent._router.navigate(['/zm/workbooks/create-workbook'], { queryParams: { 'id': row.workbookid, showBreadcrumb: false } });
      } else if (action === 'Delete') {
        // parent.deleteRow(row.workbookid, row.id);
      } else if (action === 'Clone') {
        // parent.cloneRow(row);
      }
      // alert(action);
    });
  }

  // cloneRow(projectObj) {
  //   const parent = this;
  //   const tempProject = new Project();
  //   tempProject.name = 'Copy_of_' + projectObj.name + '_' + Math.floor((Math.random() * 1000) + 1);
  //   tempProject.workbookid = projectObj.workbookid;
  //   tempProject.userid = this.loggedInUserDetails.userid;

  //   this.isLoading = true;
  //   parent.ZmContentSvc.cloneProject(tempProject).subscribe(response => {

  //     parent._router.navigate(['/zs/projects/create-project'], { queryParams: { 'id': response.analyticid, showBreadcrumb: false } });

  //   }, err => {
  //     this.isLoading = true;
  //   });
  // }

  // deleteRow(analyticid, rowId) {
  //   const parent = this;
  //   this.isLoading = true;
  //   const currentAnalytic = new Analytic();
  //   currentAnalytic.analyticid = analyticid;
  //   currentAnalytic.isdeleted = true;
  //   currentAnalytic.userid = this.loggedInUserDetails.userid;
  //   parent.ZmContentSvc.saveAnalytic(currentAnalytic).subscribe(responseList => {
  //     this.analytics['currentpage'] = this.analytics['currentpage'].filter(analytic => analytic.analyticid !== analyticid);
  //     // parent.grid.gridService.deleteDataGridItemById(rowId);
  //     this.isLoading = false;
  //   }, err => {
  //     this.isLoading = false;
  //   });
  // }

  // onScroll(parentClass) {
  //   if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
  //     this.hasScrolled = true;
  //     this.onNext();
  //   }
  // }
}
