import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ZsSocFeedbackComponent } from './zs-cl-content/zs-soc-feedback/zs-soc-feedback.component';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: []
})
export class ZettaClassifyModule { }
