import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../../../../common/components/message/message.service';
import { ZmContentService } from '../../../zm-content.service';
declare var $: any;

const runHistoryFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if(columnDef.field === 'model_runtime') {
      if(dataContext.model_run_result === 'Fail') {
        return 'N/A';
      } else {
        return value;
      }
    } else if(columnDef.field === 'view-results') {
      return `<span class="blueLink popup-click" style="cursor: pointer; color: #308CC6" title='View Results'>View Results</span>`;
      
    } else if(columnDef.field === 'export-results') {
      return `<span class="blueLink" style="cursor: pointer; color: #308CC6" title='Export Results' >Export Results</span>`;
    } else {
      return `<div clas="m-0 p-0 w-100">${value}</div>`;
    }
}

@Component({
  selector: 'zetta-workbook-run-history',
  templateUrl: './workbook-run-history.component.html',
  styleUrls: ['./workbook-run-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WorkbookRunHistoryComponent implements OnInit, OnDestroy {

  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': "100%", 'width': "99%", 'pageSize': 6, 'isNormalPagination': false };
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  page = 1;
  limit = 20;
  noData = false;

  workbook_id;
  workbook: any = [];
  totalWorkbooks;
  runHistoryColDef: any;
  runHistory: any = [];
  viewResultsTableColDef: any = [];
  viewResults: any = [];
  isViewResultLoading = true;
  public viewResultGrid: AngularGridInstance;
  modelRunId;

  constructor(public zettaUtils: ZettaUtils,
              private activatedRoute: ActivatedRoute,
              private messageSvc: MessageService,
              private workbookSvc: ZmContentService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.workbook_id = params['id'];
    });

    this.workbook_id = 1;

    this.getWorkbook(this.workbook_id);
    this.runHistoryColDef = [{
      'displayname': 'Date',
      'physicalname': 'date',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 80,
      'maxWidth': 90,
      'formatter': runHistoryFormattter,
    }, {
      'displayname': 'Model Run ID',
      'physicalname': 'model_run_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 80,
      'maxWidth': 90,
      'formatter': runHistoryFormattter,
    }, {
      'displayname': 'Model Run Result',
      'physicalname': 'model_run_result',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 110,
      'formatter': runHistoryFormattter,
    }, {
      'displayname': 'Model Runtime',
      'physicalname': 'model_runtime',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 110,
      'formatter': runHistoryFormattter,
    }, {
      'displayname': 'Results',
      'physicalname': 'view-results',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
      'maxWidth': 110,
      'formatter': runHistoryFormattter,
    }, {
      'displayname': 'Export',
      'physicalname': 'export-results',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      // 'minWidth': 220,
      'formatter': runHistoryFormattter,
    },  
  ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30,
    };

  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getWorkbook(workbookId) {
    this.workbookSvc.getWorkbooksList().subscribe((data) => {
      console.log()
      this.workbook = data['currentpage'].filter(item => item.workbook_id == workbookId)[0];
      this.runHistory = this.workbook['runHistory'];
      this.isLoading = false;
    }, err => { });
  }

  // getProjectHomeList(pageno, limit = 1) {
  //   this.ProjectSvc
  //     .getRelatedProjectsByDataSetId(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.dataset_id, pageno, limit)
  //     .subscribe((data) => {
  //       this.projectDataSource = data;
  //       if (this.projectDataSource.length === 0) {
  //         this.noData = true;
  //       }
  //       this.isLoading = false;
  //       let concatedRows = this.projectDataSource;
  //       if (this.grid) {
  //         concatedRows = [...this.grid.dataView.getItems(), ...this.projectDataSource];
  //         this.projectDataSource.forEach(element => {
  //           element.id = Math.random();
  //         });
  //         this.projectDataSource = concatedRows;
  //         this.grid.dataView.setItems(concatedRows);
  //         this.grid.gridService.setSelectedRows([]);
  //         this.hasScrolled = false;
  //       }
  //     }, err => {
  //       this.messageSvc.sendMessage({ message: 'Get list of projects failed', type: 'INFO', hideboard: true });
  //       this.isLoading = false;
  //     });
  // }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === 'View Results') {
        this.modelRunId = row.model_run_id;
        this.getViewResults(this.page, this.limit);
        $('#view_results_modal').modal('show');
      }
    }
  }

  clearViewResults() {
    this.viewResultGrid.dataView.setItems([]);
  }

  onViewResultsGridCreation(grid) {
    this.viewResultGrid = grid;
  }

  getViewResults(pageNo, limit = 1) {
      this.viewResults = this.runHistory[this.modelRunId].viewResults;
      if (this.viewResults.length === 0) {
        this.noData = true;
      }
      this.isViewResultLoading = false;
      let concatedRows = this.viewResults;
      if (this.viewResultGrid) {
        concatedRows = [...this.viewResultGrid.dataView.getItems(), ...this.viewResults];
        this.viewResults.forEach(element => {
          element.id = Math.random();
        });
        this.viewResults = concatedRows;
        this.viewResultGrid.dataView.setItems(concatedRows);
        this.viewResultGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }

      for (var key in this.viewResults[0]) {
        this.viewResultsTableColDef.push({
          'displayname': key,
          'physicalname': key,
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          // 'formatter': runHistoryFormattter,
        });
      }
  }

  onNext(): void {
    this.page++;
    this.getViewResults(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

}
