import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
declare var $: any;

const catalogTitleFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const mapped_icon = (dataContext.mapped_data_sets > 0 || dataContext.mapped_data_sources > 0) ? `<i class="fal fa-code-branch"></i>` : ``;
  return `<div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
  <div class="col-auto m-0 p-0 text-left mapped-icon">
   ${mapped_icon}
  </div>
  <div class="col-auto ml-2 m-0 p-0 text-left">
  <div class="text-truncate catalog-title-wd" title="${value}">${value}</div>
  </div>
</div>`;
};

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.isChecked) {
  return `<div class="formatter match-radio-formatter">
  <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
} else {
  return `<div class="formatter match-radio-formatter">
  <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
}
};
const EMPTY_GRID_MSG = 'No catalogs to map.';

@Component({
  selector: 'zetta-ds-select-catalogs',
  templateUrl: './ds-select-catalogs.component.html',
  styleUrls: ['./ds-select-catalogs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DsSelectCatalogsComponent implements OnInit, OnDestroy {

  public catalogs: any;
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  tableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  grandTotal = 0;
  subTotal = '';
  noData = false;
  apiUrl: string = environment.config.API_URL;
  progressState = new ProjectStatus();
  dataset_id;
  catalogs_ids: any;
  selectedGridRowsSize = 0;
  selectedCatalogId;
  showLoadingSpinner: boolean = false;

  constructor(
    private catalogSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.selectedCatalogId = params.get('catalogs_ids');
  });
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.progressState.states = this.zettaUtils.getStateList('classify-set');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifySet';
    this.progressState.isEdit = false;

    this.getCatalogList(this.page, this.limit);
    this.tableColDef = [
      {
        'displayname': ' ',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 35,
        'maxWidth': 35,
        // 'params': this.dataSelected,
      },{
        'displayname': 'Data Catalog',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 150,
        'maxWidth': 250,
        'formatter': catalogTitleFormatter,
      }, {
        'displayname': 'Data Catalog Description',
        'physicalname': 'description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: EMPTY_GRID_MSG,      
    };

  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getCatalogList(pageNo, limit) {   
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs?pagination=true&pageno=1&pagesize=${limit}`;
    var serverfilter = "";
    if(sessionStorage.getItem("serverfilter")){
      var serverfilter = sessionStorage.getItem("serverfilter");
    } 
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
   } 
   this.showLoadingSpinner = true;
   let self = this;
   this.catalogSvc.getCatalogsListPagedAndSearch(pageNo, limit, serverfilter,sortfilter).subscribe((data) => {
      self.catalogs = data["currentpage"];
      self.showLoadingSpinner = false;

      if (self.catalogs.length === 0) {
        self.noData = true;
      }
      self.catalogs.forEach(element => {
        element.isChecked = element.catalog_id == self.selectedCatalogId ? true : false;
        element.id = Math.random();
      });
      self.isLoading = false;
      self.total = data["totalrecords"];
     if (pageNo === 1) {
      self.grandTotal = data["totalrecords"];
     }
      let concatedRows = self.catalogs;
      if (self.grid) {
        concatedRows = [...self.grid.dataView.getItems(), ...self.catalogs];
        self.catalogs = concatedRows;
        self.grid.dataView.setItems(concatedRows);
        self.grid.gridService.setSelectedRows([]);
        self.hasScrolled = false;
      }
    }, (error) => {
      self.isLoading = false;
      self.showLoadingSpinner = false;
      // this.messageSvc.sendMessage({ message: 'Get datasets failed', type: 'INFO', hideboard: true });
    });
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;

    // parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    //   parent.selectedGridRowsSize = args.rows.length;
    // });
    if(this.selectedCatalogId) {
      parent.selectedGridRowsSize=1;
    }
  }

  onNext(): void {
    this.page++;
    this.getCatalogList(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onCancel(): void {
    this._router.navigate(['/zs/datasets']);
  }

  onNextStep(): void {
    const gridrows = this.grid.dataView.getItems();
    const gridDataService = this.grid.gridService;
    const selectedRows: number[] = gridDataService.getSelectedRows();
    const rows: any[] = [];
    gridrows.forEach(index => {
      const row = gridDataService.getDataItemByRowNumber(index);
      if (index.isChecked==true) {
        rows.push(index.catalog_id);
      }
   });
    this._router.navigate(['/zs/datasets/' + this.dataset_id + '/catalogs/objects'], { queryParams: { catalogs_ids: this.catalogs_ids ? this.catalogs_ids : this.selectedCatalogId } });
  }

  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent.catalogs_ids = row.catalog_id;
    this.selectedCatalogId = row.catalog_id;
    if (row !== undefined && row !== null) {
      row['isChecked']=true;
    }
    const catalogsData = this.grid.dataView.getItems();
    if (catalogsData && catalogsData.length) {
      catalogsData.forEach((catalog) => {
        if (row && catalog.catalog_id != row.catalog_id) {
          catalog.isChecked = false;
        }
      })
    }
    
    $("#data-" + args.row).prop("checked", true);
    parent.selectedGridRowsSize = 1;
  }

  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
  }
  
  onSearchAndSortDataFetched(response) {
      let catalogsData = response && response['currentpage'] ? response && response['currentpage'] : [];
      if (catalogsData && catalogsData.length) {
        catalogsData = catalogsData.map((_catalogs) => {
          _catalogs.isChecked = _catalogs.catalog_id == this.selectedCatalogId ? true : false;
          _catalogs.id = Math.random();
          return _catalogs;
        })
      }
      if (this.grid) {
        this.grid.dataView.setItems(catalogsData);
      }
  }
}
