import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'zetta-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
    constructor() { }
    ngOnInit() {
    }
}
