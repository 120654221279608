import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ZmContentService } from '../zm-content/zm-content.service';
import { AnalyticService } from '../zm-content/zm-analytics/analytic.service';
import { numericFormatter } from '../../common/zm-shared/zm-formatters/numericFormatter';
import { dateMockFormatter } from '../../common/zm-shared/zm-formatters/dateMockFormatter';
import { wbookNameFormatter } from '../../common/zm-shared/zm-formatters/wbookNameFormatter';
import { wbookNumericFormatter } from '../../common/zm-shared/zm-formatters/wbookNumericFormatter';
import { datasetNameFormatter } from '../../common/zm-shared/zm-formatters/datasetNameFormatter';
import { ZmZettaUtils } from '../../common/zm-shared/zm-zettaUtils'; 
import { AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { dateFormatter } from '../../common/zm-shared/zm-formatters/dateFormatter';
declare var $: any;

@Component({
  selector: 'zetta-zm-home',
  templateUrl: './zm-home.component.html',
  styleUrls: ['./zm-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZmHomeComponent implements OnInit {

  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  wbGridRowsDef: any = [];
  wbGridColumnsDef: any;
  wbGridOptions: any;
  public wbGrid: AngularGridInstance;
  dsGridRowsDef: any = [];
  dsGridColumnsDef: any;
  dsGridOptions: any;
  public dsGrid: AngularGridInstance;

  isWBLoading = false;
  isDSLoading = false;
  totalWB = 0;
  totalDS = 0;

  page = 1;
  limit = 5;

  wbTableSettings: object = { 'height': '100%', 'pageSize': 6, 'isNormalPagination': false };
  dsTableSettings: object = { 'height': '100%', 'pageSize': 6, 'isNormalPagination': false };

  public dataSize = -1;

  constructor(
    public contentSvc: ZmContentService,
    public service: AnalyticService,
    public zettaUtils: ZmZettaUtils,
    private _router: Router) {
      // this._userInfo = this.service.getObjectFromSession('userInfo');
    }

  ngOnInit() {

    this.wbGridOptions = {
      enableGridMenu: false,
      enableFiltering: false,
      CheckboxSelector: false,
      enableCellNavigation: false,
      enableRowSelection: false,
      rowHeight: this.zettaUtils.vhTOpx(5.4)
      // rowHeight: 55
    };

    this.dsGridOptions = {
      enableGridMenu: false,
      // selectable: false,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: false,
      CheckboxSelector: false,
      rowHeight: this.zettaUtils.vhTOpx(4.3)
      // rowHeight: 50
    };
    this.initWBooksColDef();
    this.initDSetsColDef();
    // this.getDatasets(this.page, this.limit);
    // this.getWorkbooks(this.page, this.limit);
    this.getDatasetsList();
    this.getWorkbooksList();
  }


  initWBooksColDef() {
    this.wbGridColumnsDef = [{
        'displayname': 'Description',
        'physicalname': 'Description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 250,
        'cssClass': 'text-left',
        'headerCssClass': 'text-left',
        // 'params': { 'fontImg': 'fas fa-file' },
        'params': { },
        'formatter': wbookNameFormatter,
      }, {
        'displayname': 'Version',
        'physicalname': 'Version',
        'sortable': true,
        'cssClass': 'text-right text-wb',
        'headerCssClass': 'text-left',
        'datatype': 'String',
        'filterable': true,
        // 'minWidth': 100,
        'params': { },
        // 'formatter': wbookNameFormatter
      }, {
        'displayname': 'Last Modified On',
        'physicalname': 'UpdatedTs',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        // 'minWidth': 100,
        'cssClass': 'text-left text-wb',
        'headerCssClass': 'text-left',
        'params': { },
        'formatter': dateFormatter
      }
    ];
  }

  initDSetsColDef() {
    this.dsGridColumnsDef = [{
        'displayname': 'Data Set Name',
        'physicalname': 'Name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth' : 110,
        // 'params': { 'fontImg': 'fas fa-book' },
        'params': { },
        'formatter': datasetNameFormatter
      }, {
        'displayname': 'Data Set Description',
        'physicalname': 'Description',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'args': false,
        'minWidth' : 150,
        'params': { 'isDisplayDesc': false },
      }, {
        'displayname': 'Version',
        'physicalname': 'Version',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth' : 80,
        'maxWidth' : 80,
        'cssClass': 'text-left',
        'headerCssClass': 'text-left',
        // 'formatter': numericFormatter
      },
      {
        'displayname': 'Data Source',
        'physicalname': 'datasourcename',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
        'maxWidth' : 150,
      },
      {
        'displayname': 'Created By',
        'physicalname': 'CreatedBy',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
        'maxWidth' : 150,
        'cssClass': 'text-left',
        'headerCssClass': 'text-left',
      },
      {
        'displayname': 'Last Modified On',
        'physicalname': 'UpdatedTs',
        'sortable': true,
        'datatype': 'DateTime',
        'filterable': true,
        'minWidth': 90,
        'maxWidth' : 150,
        'cssClass': 'text-left',
        'headerCssClass': 'text-left',
        'formatter': dateMockFormatter,    
      }
    ];
  }


  wbGridReady(grid) {
    this.wbGrid = grid;
  }

  dsGridReady(grid) {
    this.dsGrid = grid;
  }

  getWorkbooksList() {
    this.isWBLoading = true;
    this.contentSvc.getWorkbooks(this.loggedInUserDetails.user_id).subscribe((data) => {
      this.wbGridRowsDef = data;
      this.wbGridRowsDef.forEach(element => element.id = Math.random());
      if (this.wbGrid) {
        this.wbGrid.dataView.setItems(this.wbGridRowsDef);
      }
      this.totalWB = data.length;
      this.isWBLoading = false;
    });
    
  }

  getDatasetsList() {
    this.isDSLoading = true;
    if (this.loggedInUserDetails) {
      this.contentSvc.getDataSets(this.loggedInUserDetails.user_id, '652').subscribe(responseList => {
        this.dsGridRowsDef = responseList;
        this.dsGridRowsDef.forEach(element => {
          element.id = Math.random();
          var versions = ["1.0", "2,0", "3.0", "4.0"];
          element.Version = versions[Math.floor(Math.random() * versions.length)];
          var datasources = ["Files", "File Server", "Hadoop", "Hive", "AVOX"];
          element.datasourcename = datasources[Math.floor(Math.random() * datasources.length)];
        });
        if (this.dsGrid) {
          this.dsGrid.dataView.setItems(this.dsGridRowsDef);
        }
        this.totalDS = responseList.length;
        this.isDSLoading = false;
      });
    }
  }

  
  // goToPage(n: number): void {
  //   this.page = n;
  //   this.getWorkbooks(this.page);
  // }

  // onNext(): void {
  //   this.page++;
  //   this.getWorkbooks(this.page);
  // }

  // onPrev(): void {
  //   this.page--;
  //   this.getWorkbooks(this.page);
  // }



  // onDatasetGridCellClick(e): void {
  //   const eventEle = e.eventData;
  //   const args = e.args;
  //   const row = this.dsGrid.gridService.getDataItemByRowIndex(args.row);

  //   if (row !== undefined && row !== null) {

  //     if (eventEle.target.title === row.name) {
  //       this._router.navigate(['/zs/projects', row.projectid]);
  //     }
  //   }
  // }

  // openContextMenu(e): void {
  //   const parent = this;
  //   const eventEle = e.eventData;
  //   eventEle.stopImmediatePropagation();
  //   const args = e.args;
  //   const row = this.dsGrid.gridService.getDataItemByRowIndex(args.row);

  //   $('#context_menu')
  //     .removeClass('d-none')
  //     .data('row', args.row)
  //     .css('top', eventEle.pageY)
  //     .css('left', eventEle.pageX)
  //     .css('position', 'fixed')
  //     .css('display', 'block')
  //     .show();

  //   $('body').one('click', function() {
  //     $('#context_menu').hide();
  //   });

  //   $('#context_menu').click(function(e) {
  //     if (!$(e.target).is('span')) {
  //       return;
  //     }
  //     if (!parent.dsGrid.slickGrid.getEditorLock().commitCurrentEdit()) {
  //       return;
  //     }
  //     const action = $(e.target).attr('data');
  //     if (action === 'Edit') {
  //       parent._router.navigate(['/zs/projects/create-project'], { queryParams: { 'id': row.projectid, showBreadcrumb: false } });

  //     } else if (action === 'Delete') {
  //       parent.deleteRow(row.projectid, row.id);
  //     } else {
  //       parent.cloneRow(row);
  //     }
  //   });
  // }

  // cloneRow(projectObj) {
  //   const parent = this;
  //   projectObj.name = projectObj.name + '_copy_' + Math.floor((Math.random() * 1000) + 1);
  //   projectObj.tablename = ''; // need fill this
  //   projectObj.description = ''; // need fill this
  //   parent.service.cloneProject(projectObj).subscribe(response => {

  //   });
  // }

  // deleteRow(projectId, rowId) {
  //   const parent = this;
  //   parent.service.deleteProject(parent._userInfo.userid, projectId).subscribe(responseList => {
  //     parent.dsGrid.gridService.deleteDataGridItemById(rowId);
  //   });
  // }


}
