import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ContentService } from '../../content.service';
import { ProjectService } from '../project.service';
import { DatasourceService } from '../../../../common/components/datasources/datasource.service';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { titleFormatter } from '../../../../common/shared/formatters/titleFormatter';
import { projectNameFormattter } from '../../../../common/shared/formatters/projectNameFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { matherMergerFormatter } from '../../../../common/shared/formatters/matherMergerFormatter';
import { MessageService } from '../../../../common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { NameLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
import { ClassificationTagFormatterComponent } from 'src/app/common/shared/cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
import { ButtonFormatterComponent } from 'src/app/common/shared/cell-renderer/button-formatter/button-formatter.component';
import { RuleCountFormatterComponent } from 'src/app/common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component';
import { MeasuredDQRuleFormatterComponent } from 'src/app/common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { ActionLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { NoRowOverlayComponent } from 'src/app/common/components/no-row-overlay/no-row-overlay.component';
import { NumberFormatterComponent } from 'src/app/common/shared/cell-renderer/number-formatter/number-formatter.component';
import { ProjectNameFormatterComponent } from 'src/app/common/shared/cell-renderer/project-name-formatter/project-name-formatter.component';
import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mathermerger-formatter/mathermerger-formatter.component';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { param } from 'jquery';
declare var $: any;
import { GridApi } from 'ag-grid-community';

const zettaUtilsInstance = new ZettaUtils();

const titleDatasourceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  dataContext.name = dataContext.name ? dataContext.name : '';
  dataContext.description = dataContext.description ? dataContext.description : '';
  let description = dataContext.description !== '' ? `<div class="ds-description1 m-0 p-0 text-truncate" title='${dataContext.description}'>${dataContext.description}</div>` : ``;
  
  let isAdmin = false;
  if (dataContext.entitlements) {
    if (dataContext.entitlements.menu.clone && dataContext.entitlements.menu.delete && dataContext.entitlements.menu.edit) {
      isAdmin = true;
    }
  }
  return isAdmin
    ? `<div class="titles row m-0 p-0 align-items-center h-100">
            <div class="m-0 p-0 w-100">
              <div class="col-12 m-0 p-0 text-truncate data-source-name1 lineH text-black pointer" title='${dataContext.name}'>
              ${dataContext.name}
              </div>
							<div class="col-12 m-0 p-0 ds-description1 text-truncate" title='${dataContext.description}'>
                ${dataContext.description}
							</div>
            </div>
          </div>`
    :
    `<div class="titles row m-0 p-0 align-items-center h-100">
            <div class="m-0 p-0 w-100">
              <div class="col-12 m-0 p-0 data-source-name1 text-truncate lineH text-black disabled-state" title='${dataContext.name}'>
              ${dataContext.name}
              </div>
							<div class="col-12 m-0 p-0 ds-description1 text-truncate" title='${dataContext.description}'>
                ${dataContext.description}
							</div>
            </div>
          </div> `;
};

const imgDatasourceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  function getTitleImage() {
    const hasFontImage = (columnDef.params && columnDef.params['fontImg'] !== undefined) ? true : false;
    const fontClass = columnDef.params['fontImg'];
    return hasFontImage ? `${fontClass}` : ``;
  }
  return `<div class="row m-0 p-0 align-items-center h-100">
							<div class="col m-0 p-0 pl-1 item-img">
              <i class='${getTitleImage()}'></i>
							</div>
				  </div>
				 `;
};

const DateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (value === null || value === undefined || value === '') { return; }
  const d = new Date(value);
  const m = d.getUTCMonth() + 1;
  const month = (m <= 9 ? '0' + m : m);
  return `<div class='text-left' title='${month}/${d.getUTCDate()}/${d.getUTCFullYear()}'>${month}/${d.getUTCDate()}/${d.getUTCFullYear()}</div>`;
};

const textFormater: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(cell==2 || cell==3){
    return `<div class="row m-0 p-0 align-items-center h-100">
              <div class="col m-0 p-0 text-black pointer w-100 text-right">
                ${zettaUtilsInstance.commarization(value, 2).toLowerCase()}
              </div>
            </div>
           `;
  }
}

@Component({
  selector: 'zetta-data-sources',
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.scss'],
})
export class DataSourcesComponent implements OnInit, OnDestroy {

  tableDataSource: any = [];
  tasksDataSource: any;
  gridColumnsDef: any;
  taskGridColumnsDef: any;
  gridOptions: any;
  taskGridOptions: any;
  gridObj: any;
  dataviewObj: any;
  _userInfo;
  public taskGrid: AngularGridInstance;
  public datasourceGrid: AngularGridInstance;

  /**
   * Pagination properites
   * @memberof DataSourcesComponent
   */
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  isOpenTaskLoading = true;
  hasScrolled = false;
  noData = false;
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  datasourceTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  public dataSize = -1;
  selectedClusterId: string;
  taskType: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  selectedProjectId: string;
  selectedRowDataSource;
  public datasources: object = {};
  ds_view_all;
  ds_create;
  is_visible_view_all = false;
  is_visible_create = false;
  is_enable_view_all = false;
  is_enable_create = false;
  is_visible_all_task = false;
  is_enable_all_task = false;
  object_types:any=[];
  enableViewAllMyTasks: Boolean = false;
  public loggedInUser = JSON.parse(sessionStorage.userInfo);
  public login_user = this.loggedInUser.entitlements;
  shouldDisableFeedback = false;
  prouductionTaskSelected: boolean = false;
  project_users = [];
  isFiltering = false;
  isSorting = false;
  openMatcherFeedbackModal: boolean = false;
  openMergerFeedbackModal: boolean = false;

  columnApi:any;
  gridApi:any;
  shouldOpenModal: boolean = false;

  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {}
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  subTotalRecords = 0;
  isProjectDataLoaded: boolean = false;
  isProjectFourEyeCheckOn: boolean = false;
  shouldDisableMatchFixingTasks: boolean = true;
  shouldMoveMatchFixingData: boolean = false;
  selectedJobId: string;
  
  constructor(
    public contentSvc: ContentService,
    private datasourceSvc: DatasourceService,
    public service: ProjectService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService, private http : HttpClient,) {
    this._userInfo = this.service.getObjectFromSession('userInfo');
  }
  onRowSelectionChanged(rowSelection: string) { 
    // console.log('Row Selection Mode:', rowSelection); 
  }
  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.gridOptions = {
      enableGridMenu: false,
      enableFiltering: false,
      enableSorting: true,
      CheckboxSelector: false,
      enableCellNavigation: false,
      enableRowSelection: false,
      rowHeight: 60
    };
    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: false,
      rowHeight: 34
    };
    this.initColDef();
    this.initTaskColDef();
    this.loadTasksData(this.page, this.limit);
    this.getProducts(this.page, this.limit);
    if (this.login_user) {
      try {
        let user_entitlements=this.login_user[0].entitlement;
        if(this.login_user.length>1){
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if(user_entitlements.length){
            user_entitlements=user_entitlements[0].entitlement;
          }else{
            user_entitlements=this.login_user[0].entitlement;
          }
        }
        const my_projects = user_entitlements.screens.filter(item => item.name == 'home')[0].childs.filter(item => item.name == 'my_data_sources')[0].childs;
        const tasks = user_entitlements.screens.filter(item => item.name == 'home')[0].childs.filter(item => item.name == 'my_tasks')[0];
        if (my_projects.length) {
          this.ds_view_all = my_projects.filter(item => item.name == 'my_data_sources_view_all');
          this.is_visible_view_all = this.ds_view_all[0].visible;
          this.is_enable_view_all = this.ds_view_all[0].enable;
          this.ds_create = my_projects.filter(item => item.name == 'my_data_sources_create_new');
          this.is_visible_create = this.ds_create[0].visible;
          this.is_enable_create = this.ds_create[0].enable;
        }
        if (tasks) {
          this.is_visible_all_task = tasks.visible;
          this.is_enable_all_task = tasks.enable;
        }
      } catch (error) {
        console.log(error);
      }
      this.getObjectTypes();
      this.checkForAllMyTasks();
    }
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tasks?opentasks=true`;
  }

  getProducts(pageno, limit = 100) {
    this.contentSvc.getDataSources(pageno, limit).subscribe((data) => {
      this.tableDataSource = data;
      this.tableDataSource['currentpage'].forEach(element => {
        element.id = Math.random();
        element.align_text_center = true;
        if (element.total_records && element.total_records !== 'n/a') {
          element.total_records = +element.total_records;
        }
      });
      
      if (this.datasourceGrid) {
        this.datasourceGrid.dataView.setItems(this.tableDataSource['currentpage']);
      }
      this.total = data['totalrecords'];
      this.isLoading = false;
      this.dataSize = Object.keys(data).length;
    }, error => {
      this.isLoading = false;
    });
  }

  initColDef() {
    this.gridColumnsDef = [{
      'displayname': '',
      'physicalname': '',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 45,
      'maxWidth': 45,
      'params': { 'fontImg': 'fas fa-database' },
      'formatter': imgDatasourceFormatter
    }, {
      'displayname': 'Data Source',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'headerCssClass': '',
      'formatter': titleDatasourceFormatter
    }, {
      'displayname': 'Data Sets',
      'physicalname': 'datasets',
      'sortable': true,
      'cssClass': 'text-align',
      'headerCssClass': 'text-right pr-3',
      'datatype': 'String',
      'filterable': false,
      'minWidth': 110,
      'maxWidth': 110,
      'formatter': textFormater
    }, {
      'displayname': 'Records',
      'physicalname': 'total_records',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'minWidth': 110,
      'maxWidth': 110,
      'cssClass': 'text-align',
      'headerCssClass': 'text-right pr-3',
      'formatter': textFormater
    },{
      'displayname': 'Measured DQ',
      'physicalname': 'measured_dq_score',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': measuredDQFormatter,
      'headerCssClass': `text-right titleBreak pr-3 ${this._userInfo.enable_dq_rule ? '':'disabled'}`,
      'cssClass': 'text-grey',
      'minWidth': 90,
      'maxWidth': 90
  },];
  }

  initTaskColDef() {
    this.taskGridColumnsDef = [{
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Project Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 170,
      'params': { 'isDisplayDesc': false },
      'formatter': projectNameFormattter
    }, {
      'displayname': 'Task Name',
      'physicalname': 'taskname',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 170,
      'formatter': matherMergerFormatter
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    }, {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': DateFormatter
    }];
    this.intialiseGrid();
  }
  intialiseGrid() {
    this.columnDefs = [
     {
        headerName: 'Task Type',
        field: 'task_type', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        cellRendererFramework: MatherMergerFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:150,
      }, {
        headerName: 'Project Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        cellRendererFramework :ProjectNameFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:300,
      }, {
        headerName: 'Task Name',
        field: 'taskname',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        cellRendererFramework : MatherMergerFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:300,
      },{
        headerName: 'Role',
        field: 'role_name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true, 
        editable: true,
        tooltipField:'role_name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:140,
        
      }, {
        headerName: 'Task Status',
        field: 'task_status', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellRendererFramework: AssignmentStatusFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:140,
      }, {
        headerName: 'Last Updated',
        field: 'last_updated',  
        filter:'agTextColumnFilter',
        floatingFilter: true,
        sortable:true,
        cellRendererFramework:DateFormatterFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:140,
       
      }, 
    ];
    this.fsGridOptions = { 
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
      
    };
    
  }

  loadTasksData(pageno, limit = 1) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tasks?opentasks=true&pageno=${1}&pagesize=${limit}`;
    
    let serverfilter = "";
      // if (sessionStorage.getItem("serverfilter")) {
      //    serverfilter = sessionStorage.getItem("serverfilter");
      //   this.isFiltering = true;
      //   pageno = 1;
      // } else {
      //   if(this.isFiltering) { 
      //     pageno = 1;
      //   }
      //   this.isFiltering = false;
      // }
    let sortfilter="";
    // if(sessionStorage.getItem("sortfilter")){
    //     sortfilter=sessionStorage.getItem("sortfilter");
    // } 
    this.service.getAllMyOpenTasks(this._userInfo['user_id'], pageno, limit, serverfilter, sortfilter).subscribe((data) => {
      this.isLoading = false;
      this.tasksDataSource = data['currentpage'];

      if (this.tasksDataSource.length === 0) {
        this.noData = true;
      }
      
      this.total = data['totalrecords'];
      let concatedRows = this.tasksDataSource;
      
      if (this.taskGrid) {
        concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
        this.tasksDataSource = concatedRows;
        this.tasksDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.taskGrid.dataView.setItems(this.tasksDataSource);
        this.taskGrid.gridService.setSelectedRows([]);
        if (pageno < data['totalpages']) {
          this.hasScrolled = false;
        }
        if (pageno > data['totalpages']) {
          this.page = 1;
          this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tasks?opentasks=true&pageno=${1}&pagesize=${limit}`;
          // this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tasks?opentasks=true`;
        }
      }
      this.isOpenTaskLoading = false;
    }, err => {
      this.isOpenTaskLoading = true;
    });
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  datasourceGridReady(grid) {
    this.datasourceGrid = grid;
  }

  taskGridReady(grid) {
    this.taskGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadTasksData(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.loadTasksData(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.loadTasksData(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }
  onGridReady(grid:any) { 
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;   
    
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row);

    const clickedCellIndex = args.cell;

    if(clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('name')){ 
      this._router.navigate(['/zs/projects', row.project_id]);
    } else if (clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('taskname') || clickedCellIndex === this.taskGrid.slickGrid.getColumnIndex('task_type') ){
        this.selectedClusterId = row.cluster_id;
        this.selectedProjectId = row.project_id + '';
        this.taskType = row.task_type;
        this.selectedTaskName = row.taskname;
        this.selectedTaskType = row.task_type;
        this.selectedTaskStatus = row.task_status;
        this.selectedRowDataSource = row;
        this.shouldDisableFeedback = false;

        if(this.selectedClusterId && this.taskType !== 'Merge Training'){
          this.openMatcherFeedbackModal = true;
          this.openMergerFeedbackModal = false;
        } else if(this.taskType === 'Merge Training') {
          this.openMatcherFeedbackModal = false;
          this.openMergerFeedbackModal = true;
        }

        this.getProject(row);
    }
  }

  onAgCellClick(cellParams){
    const row = cellParams.data;
    if (row !== undefined && row !== null) {
      if (cellParams.value === row.name) {
        if(row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS) {
          this._router.navigate([`/zs/projects/${row.project_id}/production-stage-details`], { queryParams: { entity_id: row.entity_id }});
        } else {
        this._router.navigate(['/zs/projects', row.project_id]);
        }
    } else if (cellParams.value === row.taskname || cellParams.value === row.task_type) {
  
          this.selectedClusterId = row.cluster_id;
          this.selectedProjectId = row.project_id + '';
          this.taskType = row.task_type;
          this.selectedTaskName = row.taskname;
          this.selectedTaskType = row.task_type;
          this.selectedTaskStatus = row.task_status;
          this.selectedRowDataSource = row;
          this.shouldDisableFeedback = false;
          this.selectedJobId = row.zs_job_id;
          this.isProjectFourEyeCheckOn = row.four_eye_check;
          this.prouductionTaskSelected = row.project_status === AppGlobals.RESOLVE_PROJECT_PRODUCTION_STATUS ? true : false;
          this.shouldDisableMatchFixingTasks = (row.task_status === AppGlobals.TASK_COMPLETED || row.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
          this.shouldMoveMatchFixingData = this.validateMatchFixingTasksDataShouldBeMerged(row);
          if(this.selectedTaskType !== AppGlobals.RESOLVE_MATCH_FIXING_TASK) {
          this.getProject(row);
          }
          if(this.selectedClusterId && this.taskType !== 'Merge Training'){
            this.openMatcherFeedbackModal = true;
            this.openMergerFeedbackModal = false;
          } else if(this.taskType === 'Merge Training') {
            this.openMatcherFeedbackModal = false;
            this.openMergerFeedbackModal = true;
          }
        }
       }
      }

  getProject(row) {
    this.service.getProject(this.loggedInUser['user_id'], row.project_id).subscribe(resp => {
      this.project_users = resp.users;
      let userDetails = this.project_users.find(user => user.user_id === this.loggedInUser.user_id);
      const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_REVIEWER);
      if (row.task_status === AppGlobals.TASK_COMPLETED) {
        this.shouldDisableFeedback = true;
      }
      if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
        this.shouldDisableFeedback = true;
      }
      this.isProjectFourEyeCheckOn = resp.four_eye_check ? resp.four_eye_check:false; 
      this.isProjectDataLoaded = true;
    });
  }
  onDataSourceGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.datasourceGrid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/datasources', row.datasource_id]);
      }
    }
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
      console.log('server filer', this.page);
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true;
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }  
      if (this.zettaUtils.virtualScroll(parentClass) &&!this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  getObjectTypes() {
    this.service.getObjectTypes('ObjectTypeId').subscribe(resp => {
      if (resp.length) {
        this.object_types = resp;
        const project_type_id = this.object_types.filter(item => item.name == 'Project')[0].lookup_id;
        const dataset_type_id = this.object_types.filter(item => item.name == 'Dataset')[0].lookup_id;
        sessionStorage.setItem('project_type_id', project_type_id);
        sessionStorage.setItem('dataset_type_id', dataset_type_id);
      }
    });
  }

  checkForAllMyTasks() {
    this.service.getAllMyTasks(this.loggedInUser['user_id'], this.page, this.limit)
    .subscribe(res => {
      if(res) {
        this.enableViewAllMyTasks = res['currentpage'] && res['currentpage'].length>0;
      }
    });
  }

  onReloadScreen(event) {
    if (event) {
      // close pop-up here openMatcherFeedbackModal, and merger
      this.openMatcherFeedbackModal = false;
      this.openMergerFeedbackModal = false;
      this.tasksDataSource = [];
      // if (this.taskGrid) {
      //   this.taskGrid.dataView.setItems(this.tasksDataSource);
      // }
      if (this.gridApi) {
        this.gridApi.setRowData(this.tasksDataSource);
      }
      this.isOpenTaskLoading = true;
      this.loadTasksData(1, this.limit);
    }
  }

  onReloadFixingDataGrid(event) {
    if (event) {
      this.openMatcherFeedbackModal = false;
      this.isOpenTaskLoading = true;
      this.page = 1;
      this.openMergerFeedbackModal = false;
      this.tasksDataSource = [];
      this.loadTasksData(1, this.limit);
      if (event.shouldRunDeltaMerger && event.completed) {
          // call algo API from here
          this.runDeltaMatcherAlgoAPI();
      } else {
        this.resetMatcherTaskGridStates();
      }
    }
  }

  runDeltaMatcherAlgoAPI() {
    let self = this;
    this.selectedJobId = this.selectedJobId ? this.selectedJobId : this.zettaUtils.generateUUID();
    this.service.runDeltaMatcherAlgoJob('stage8', this.selectedJobId, this.selectedProjectId,
    this.selectedClusterId).subscribe((res) => {
      self.resetMatcherTaskGridStates();
    }, (err) => {
      self.resetMatcherTaskGridStates();
    })
  }

  resetMatcherTaskGridStates() {
    this.selectedClusterId = '';
    this.selectedTaskName = '';
    this.selectedTaskType = '';
    this.taskType = '';
    this.selectedTaskStatus = '';
    this.shouldDisableMatchFixingTasks = true;
    this.shouldMoveMatchFixingData = false;
    this.selectedJobId = '';
    this.isProjectFourEyeCheckOn = false;
  }

  onMergerFeedbackModalClosed(event) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
    this.tasksDataSource = [];
    if (this.gridApi) {
      this.gridApi.setRowData(this.tasksDataSource);
    }
       this.page=1;
      this.isOpenTaskLoading = true;
      this.loadTasksData(this.page, this.limit)
  }
  
  onMatcherFeedbackModalClosed(eve) {
    this.openMatcherFeedbackModal = false;
    this.openMergerFeedbackModal = false;
    this.tasksDataSource = [];
    if (this.gridApi) {
      this.gridApi.setRowData(this.tasksDataSource);
    }
       this.page=1;
      this.isOpenTaskLoading = true;
      this.loadTasksData(this.page, this.limit)
    
  }

  validateMatchFixingTasksDataShouldBeMerged(rowData) {
    if (rowData && rowData.task_status) {
        if (this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          return true;
        }
        if (!this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_IN_REVIEW) {
          return true;
        }
    }
    return false;  
  }
}
