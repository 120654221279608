import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { BaseService } from 'src/app/common/services/base-service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
declare var $: any;

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.isChecked) {
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  } else {
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }
}
@Component({
  selector: 'zetta-sub-concept',
  templateUrl: './sub-concept-modal.component.html',
  styleUrls: ['./sub-concept-modal.component.scss']
})

export class SubConceptModalConcept implements OnInit, OnChanges {
  @Input() showSubConcept = false;
  @Input() project_id: number;
  @Input() classifier: string;
  @Input() selectedPridiction = [];
  @Input() record_id: number;
  @Output() feedbackAdded = new EventEmitter<boolean>();
  @Output() subConceptOutput = new EventEmitter<boolean>();
  public subConceptGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  subConceptResult = [];
  isSubConceptLoading = true;
  taskGridOptions: any;
  subConceptColumn: object = [];
  selectedSubConcept: string;
  selectedSubConceptRow: string;
  subConceptList = [];

  constructor(private ZsClContentService: ZsClContentService, private baseService: BaseService) { }

  ngOnInit(): void {
    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: false,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      rowHeight: 34,
      CheckboxSelector: false,
    };
    this.subConceptColumn = [
      {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 30,
        'maxWidth': 30,
      },
      {
        'displayname': 'Sub-Concept',
        'physicalname': 'characteristic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ]


  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showSubConcept && changes.showSubConcept.currentValue) {
      this.GetCharacteristicData();
    }
  }


  GetCharacteristicData() {
    this.ZsClContentService.getCharacteristicList(this.project_id, this.classifier).subscribe(resp => {
      this.subConceptResult = resp;
      this.isSubConceptLoading = false;
      if (this.subConceptGrid) {
        this.subConceptResult.forEach(element => {
          element.id = Math.random();
        });
        this.subConceptGrid.dataView.setItems(this.subConceptResult);
      }
      ($('#subConceptModal') as any).modal('show');
    }, error => {
      this.isSubConceptLoading = false;
    });
  }
  onSubConceptCreation(grid) {
    this.subConceptGrid = grid;
  }

  onSubConceptCellClick(e) {
    const args = e.args;
    const row = this.subConceptGrid.gridService.getDataItemByRowIndex(args.row);
    $("#data-" + args.row).prop("checked", true);
    this.selectedSubConcept = row.characteristic;
    this.selectedSubConceptRow = args.row;
  }
  onApplySubConcept() {
    this.subConceptList = [];
    this.selectedPridiction.forEach(element => {
      this.subConceptList.push(
        {
          sub_concept: this.selectedSubConcept,
          prediction: element,
        }
      )
    });
    this.saveFeedback();
  }
  saveFeedback() {
    const payloadList = [];
    if (this.subConceptList.length) {
      this.subConceptList.forEach(element => {
        payloadList.push({
          "id": this.record_id,
          "vote": 1,
          "sub_concept": element.sub_concept,
          "prediction": element.prediction
        });
      });
    }
    if (payloadList.length) {
      this.baseService.addSubConcept.next({ isAddConcept: true, data: this.subConceptList });
      this.feedbackAdded.emit(true);
      this.closeSubConcept();
    }

    $('#divBackdrop').addClass('d-none');

  }
  closeSubConcept() {
    this.selectedSubConcept = "";
    $('#subConceptModal').modal('hide');
    $("#data-" + this.selectedSubConceptRow).prop("checked", false);
    this.selectedSubConceptRow = undefined;
    this.subConceptResult = [];
    this.subConceptList = [];
    if (this.subConceptGrid) {
      this.subConceptGrid.dataView.setItems(this.subConceptResult);
    }
    this.subConceptOutput.emit(false);
  }
}