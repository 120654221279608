import { Column, Formatter } from 'angular-slickgrid';
export const confidenceLevelFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  let cssLevel = '';
  let confidenceLevel = '';
  let score100 = Math.round(dataContext.score * 100);
  let conf = score100 >= 75 ? 'HIGH' : (score100 >= 50  && score100 < 75 ) ? 'MEDIUM' : 'LOW';
  let condLike = dataContext.vote === null ? 'fal' : (dataContext.vote === 1 ? 'fas' : 'fal');
  let condUnlike = dataContext.vote === null ? 'fal' : (dataContext.vote === 0 ? 'fas' : 'fal');
  cssLevel = conf === 'HIGH' ? 'priorityCell high' : conf === 'MEDIUM' ? 'priorityCell medium' : 'priorityCell low';
  let enableFeedbackCss = dataContext.shouldDisableFeedback ? 'disabled-feedback' : '';
  if(dataContext.action !=='add'){
  if(dataContext.measured_data_quality){
    const spanCss = dataContext.isRuleEnabled ? "ml-1":'';
    const activeRuleCss = dataContext.isRuleEnabled ? `ml-2 box-cat-obj ${cssLevel}` : 'disable-rule';
    const activeRuleText = dataContext.isRuleEnabled ? `${score100}%`: 'n/a';
    confidenceLevel += `<div class="font-wieght ${activeRuleCss} text-center"><span class="${spanCss}">${activeRuleText}</span></div>`;
  }else{
    confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${conf}</div>
    <div class="porcent text-center  mr-2" title='${score100}%'>${score100}%</div>
             <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up ${enableFeedbackCss}"></i></div>
             <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down ${enableFeedbackCss}"></i></div>`;
  }
}
  
  return confidenceLevel;
}