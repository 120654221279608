import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../../../zs-cl-content.service'

const disabledCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (cell == 3) {
    return value === 'dq_rule_admin' ?
      `<div class="text-center">
            <i class="fal fa-check actionInlineBtn blueLink"></i>
          </div>` :
      `<div class="text-center">
          </div>`;
  } else {
    return value === 'dq_rule_executor' ?
      `<div class="text-center">
            <i class="fal fa-check actionInlineBtn blueLink"></i>
          </div>` :
      `<div class="text-center">
          </div>`;
  }
}

@Component({
  selector: 'zetta-technical-user-entitlements',
  templateUrl: './technical-user-entitlements.component.html',
  styleUrls: ['./technical-user-entitlements.component.scss']
})
export class TechnicalUserEntitlementsComponent implements OnInit {
  userGrid: AngularGridInstance;
  userGridDataSource: any = [];
  gridColumnsDef: any;
  tableSettings: object = { 'height': "100%", 'width': "99.40%" };
  gridOptions: any;
  loggedInUserDetails: [];
  noData = false;
  isDataReady = false;
  totalUsers: number;
  rule_id;

  constructor(public ZsClContentService: ZsClContentService, private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.activatedRoute.parent.queryParamMap.subscribe(params => {
      this.rule_id = +params.get('rule_id');
    });

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
    };
    this.initColDef();
    this.getUsersList()
  }

  getUsersList() {
    this.ZsClContentService.getUsers(this.rule_id).subscribe((responseList) => {
      this.userGridDataSource = responseList;
      if (this.userGridDataSource.length === 0) {
        this.noData = true;
      }
      this.totalUsers = this.userGridDataSource.length;
      this.isDataReady = true;
      let concatedRows = this.userGridDataSource;
      if (this.userGrid) {
        concatedRows = [...this.userGrid.dataView.getItems(), ...this.userGridDataSource];
        this.userGridDataSource = responseList['currentpage'].map((item) => {
          item['id'] = Math.random();
          return item;
        });
      }
      this.userGridDataSource = concatedRows;
      this.userGrid.dataView.setItems(concatedRows);
      this.userGrid.gridService.setSelectedRows([]);
    }, err => {
    });
  }
  initColDef() {
    this.gridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Rule Admin',
      'physicalname': 'role_name',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'headerCssClass': 'ml-4',
      'formatter': disabledCheckBoxFormattter,

    }, {
      'displayname': 'Rule Executor',
      'physicalname': 'role_name',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'headerCssClass': 'ml-4',
      'formatter': disabledCheckBoxFormattter,

    }];
  }

  gridCreated(grid) {
    this.userGrid = grid;
  }
}
