import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'zetta-business-run-execution-results',
  templateUrl: './business-run-execution-results.component.html',
  styleUrls: ['./business-run-execution-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BusinessRunExecutionResultsComponent implements OnInit {

  loggedInUserDetails: []; 
  measured_rule_score:number;
  measured_rules:string;
  public lineChartDQRule = null;
  public lineChartUniqueID: any = '';
  measured_rule_css:string;
  dqrSummary: any = {measured_dq_score: 0, good_records: 0, bad_records: 0, total_records: 0 };
  rule_id;
  today;
  isDataReady = false;
  dataset_column_id;
  dataset_id;
  @Input() isRuleAdmin: boolean;
  @Input() isRuleExecuter: boolean;
  public lineChartOptions: object = {
		'isMoneyFormat': false,
		'height': 83,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#e5b500',
		'circleColor': '#ff0000'
	};

  constructor(private zsClContentService: ZsClContentService,
			  private activatedRoute: ActivatedRoute,
			  private zettaUtils: ZettaUtils) {

    	this.lineChartUniqueID = this.zettaUtils.generateUUID();
   }

  ngOnInit() {
	this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));

	this.activatedRoute.parent.queryParamMap.subscribe(params => {		
		this.rule_id = +params.get('rule_id');
		this.dataset_id = +params.get('dataset_id');
		this.dataset_column_id = +params.get('dataset_column_id');
		let today = new Date();
		this.today = today.toLocaleDateString();
		if ( this.dataset_id && !this.dataset_column_id ) {
			this.getDQSummaryAtDatasetLevel();
		} else if ( this.dataset_id && this.dataset_column_id ) {
			this.getDQSummaryAtColumnLevel();
		} else {
			this.getDQSummary();
		}
	});

	this.getDQRunModel();
  }

  getDQSummary() {
	this.zsClContentService.getDQRsummary(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id).subscribe(resp => {
		this.isDataReady = true;
		if (resp.length > 0) {
			this.dqrSummary = resp[0];
		} 
		this.isDataReady = true;
		const ruleObj = this.zsClContentService.getMeasuredRule(this.dqrSummary.measured_dq_score, this.loggedInUserDetails['enable_dq_rule'] );
			this.measured_rule_score = ruleObj["dqRuleText"];
			this.measured_rule_css = ruleObj["dqRuleCss"];			
	}, err => { 
	});
  }

  getDQSummaryAtDatasetLevel() {
	this.zsClContentService.getDQSummaryAtDatasetLevel(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id).subscribe(resp => {
		this.isDataReady = true;
		if (resp.length > 0) {
			this.dqrSummary = resp[0];
		} 
		this.isDataReady = true;
		const ruleObj = this.zsClContentService.getMeasuredRule(this.dqrSummary.measured_dq_score, this.loggedInUserDetails['enable_dq_rule'] );
			this.measured_rule_score = ruleObj["dqRuleText"];
			this.measured_rule_css = ruleObj["dqRuleCss"];			
	}, err => { 
	});
  }

  getDQSummaryAtColumnLevel() {
	this.zsClContentService.getDQSummaryAtColumnLevel(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id, this.dataset_column_id).subscribe(resp => {
		this.isDataReady = true;
		if (resp.length > 0) {
			this.dqrSummary = resp[0];
		} 
		this.isDataReady = true;
		const ruleObj = this.zsClContentService.getMeasuredRule(this.dqrSummary.measured_dq_score, this.loggedInUserDetails['enable_dq_rule'] );
			this.measured_rule_score = ruleObj["dqRuleText"];
			this.measured_rule_css = ruleObj["dqRuleCss"];			
	}, err => { 
	});
  }

  getMeasuredRule() {
    this.measured_rules = this.dqrSummary.measured_dq_score >= 75 ? 'high' : (this.dqrSummary.measured_dq_score >= 50) ? 'medium' : 'low';
  }

  getDQRunModel() {
		const data = {
			user_id: this.loggedInUserDetails['user_id'],
			tenant_id: this.loggedInUserDetails['tenant_id'],
			rule_id: this.rule_id
		};
		this.zsClContentService.getDQRunModel(data).subscribe(data => {
			this.lineChartDQRule = this.prepareLineChartData(data);
		}, error => {
			this.lineChartDQRule = [];
		});
	}
  prepareLineChartData(data) {
		const dataSet = [];
		data.forEach((element) => {
			const d = new Date(element['last_updated_date']);
			dataSet.push({ 'year': d.getFullYear(), 'value': element['measured_dq_score'].toFixed(2), 'fulldate': d });
		});
		return dataSet;
	}
}
