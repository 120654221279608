import { Component,Output, EventEmitter, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Project } from "../../project.model";
import { ProjectService } from "../../project.service";
import { entityListNameFormatter } from "../../../../../common/shared/formatters/entityListNameFormatter"
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";
import { numericFormatter } from '../../../../../common/shared/formatters/numericFormatter';
import { AppGlobals } from '../../../../../common/shared/app.globals';


const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="text-truncate"><label title="${value}" class="blueLink text-truncate wd-16">${value}</label></div>`;
}
@Component({
  selector: 'zetta-golden-record-relationship',
  templateUrl: './golden-record-relationship.component.html',
  styleUrls: ['./golden-record-relationship.component.scss']
})
export class GoldenRecordRelationshipComponent {
  private _userinfo: any;
  projectDetail = new Project();
  public entityData: any;
  public entityName: any;
  isSeletedClusterLoading = false;
  suggestedClusters: any = [];;
  leftGridDataSource: any = [];
  leftGrid: AngularGridInstance;
  suggestedClusterkeys = [];
  similarClusterGridOptions: any;
  cluster_id;
  selectedEntity;
  record_id;
  leftGridColumnsDef: any;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  clusterarray: any = []
  @Input() entityId;
  @Output() activeLnik = new EventEmitter();

  constructor(private _projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    public ZettaUtils: ZettaUtils, private router: Router) { }


  ngOnInit() {
    this.leftGridColumnsDef = [{
      'displayname': 'Master ID',
      'physicalname': 'master_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': dsNameFormattter,
      'minWidth': 200,

    }, {
      'displayname': 'Full Name',
      'physicalname': 'full_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200
    }, {
      'displayname': '# of child Records',
      'physicalname': 'record_count',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-center',
      'headerCssClass': 'text-center',
      'formatter': numericFormatter,
      'minWidth': 50,

    }];

    this.similarClusterGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableSorting: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true
    };
    this._userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activeRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });
    this.activeRoute.queryParamMap.subscribe((param) => {
      this.cluster_id = param.get('record_id');
      this.record_id = param.get('cluster_id');
      this.selectedEntity = param.get('selectedEntity');

    });

    this.getProject();
    this.getSimilarClusters();
  }
  
  getProject() {
    let self = this;
    this._projectService.getProject(this._userinfo.user_id, this.projectDetail.project_id).subscribe(responseList => {
      self.entityData = responseList.entity_id
      self.entityName = responseList.entity_name;
      self.entityId = responseList.entity_id;
    }, err => {
    });
  }

  onLeftCellClick(e): void {
    const parent = this;
    const eventEle = e.eventData;
    const args = e.args;
    const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.leftGrid.slickGrid.getColumns()[args.cell].field;
    if (row !== undefined && row !== null) {
      if (fieldName == 'master_id') {
        if(this.router.url.includes('production-stage-details')) {
          this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details/golden-records/detail`], {
            queryParams: { 
              cluster_id: this.record_id, // cluster_id and record_id are interchangeable been used in existing code. hence not fixing it
              record_id: this.cluster_id, 
              selectedEntity: this.entityName, 
              entity_id: this.entityId,
              noReloadRequired: false 
            }, 
            queryParamsHandling: 'merge' 
          });
        } else {
          this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/golden-records/detail`], {
            queryParams: { 
              cluster_id: this.record_id, 
              record_id: this.cluster_id, 
              selectedEntity: this.entityName, 
              noReloadRequired: false 
            }, 
            queryParamsHandling: 'merge' 
          });
        }
        this.activeLnik.emit({ masterID: row.master_id, record_id: row.record_id });
        
      }
    }
  }

  ngDoCheck() {
    this.disableCheckbox();
  }

  getSimilarClusters() {
    this.isSeletedClusterLoading = true;
    this._projectService.getSimilarClusters({
      userId: this._userinfo.user_id,
      projectId: this.projectDetail.project_id, clusterId:
        this.cluster_id
    }).subscribe((resp) => {
      this.suggestedClusterkeys = Object.keys(resp.currentpage);
      this.suggestedClusters = resp.currentpage;
      this.suggestedClusterkeys.forEach(e => {
        this.suggestedClusters[e].forEach(element => {
          const found = this.clusterarray.some(el => el.master_id === e);
          if (!found) {
            this.clusterarray.push(
              {
                "master_id": e,
                "full_name": element.zs_cluster_identifier,
                "record_count": element.zs_total_records_cluster,
                "record_id": element.zs_record_id
                
              }
            )
          }

        });

      });
      this.clusterarray.forEach(e => {
        e.id = Math.random();
      });
      this.isSeletedClusterLoading = false;
    }, err => {
      this.isSeletedClusterLoading = false;
    });
    this.disableCheckbox();
  }

  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.disableCheckbox();
  }
  
    disableCheckbox() {
    setTimeout(() => {
      $('.cluster-table input[type="checkbox"]').attr('disabled', 'disabled');
    }, 1000);
  }

}