import { AppGlobals } from '../common/shared/app.globals';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { PatternValidator } from 'src/app/common/shared/formValidators/patternValidator';
import { Component, OnInit, Compiler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGroupService } from '../zettasense/usergroup/usergroup.service';
import { User } from 'src/app/common/shared/zetta-models';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ContentService } from '../zettasense/content/content.service';

@Component({
  selector: 'zetta-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  passwordChangeForm: FormGroup;
  errorMessage: string;
  inProgress = false;
  passwordResetSuccessMsg = 'Success! Your Password has been changed!';
  token;

  constructor(
    private formBuilder: FormBuilder,
    private userGroupService: UserGroupService,
    private _compiler: Compiler,
    private _authSvc: AuthenticationService,
    private contentSvc: ContentService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.passwordChangeForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: [
          PatternValidator(
            'newPassword',
            AppGlobals.NUMERIC_REGEX,
            { hasNumber: true },
            'confirmPassword'
          ), PatternValidator(
            'newPassword',
            AppGlobals.CHARACTERS_REGEX,
            { hasNumber: true },
            'confirmPassword'
          )
        ]
      }
    );
  }

  logout() {
    this._compiler.clearCache();
    this._authSvc.signOut();
    this._router.navigate(['/zl/login']);
  }

  onResetPassword() {
    if (this.passwordChangeForm.valid) {
      this.inProgress = true;
      if (this.token) {
        const payload: any = {
          token: this.token,
          password: this.passwordChangeForm.controls.newPassword.value,
          confirm_password: this.passwordChangeForm.controls.confirmPassword.value
        }
        this.contentSvc.resetPassword(payload ,this.token).subscribe((result) => {
          this.inProgress = false;
          this.passwordChangeForm.reset();
          this.messageSvc.sendMessage({ message: this.passwordResetSuccessMsg, type: 'INFO', hideboard: true });
          this.logout();
        }, (err) => {
          this.inProgress = false;
        });
      } else {
        this.contentSvc.changePassword({
          new_password: this.passwordChangeForm.controls.newPassword.value
        }).subscribe((response) => {
          this.inProgress = false;
          this.passwordChangeForm.reset();
          this.messageSvc.sendMessage({ message: this.passwordResetSuccessMsg, type: 'INFO', hideboard: true });
          this.logout();
        },
          errorResponse => {
            this.inProgress = false;
          });
      }
    }
  }
}
