import { ZettaUtils } from '../../shared/zettaUtils';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { AngularGridInstance, Grouping, Column, Formatter } from 'angular-slickgrid';
import { MessageService } from '../../components/message/message.service';
declare const $: any;
import _ from 'lodash';
import {ObservableService } from "../../services/observable.service";
import { ZsClContentService } from "../../../zettaclassify/zs-cl-content/zs-cl-content.service"
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';

export const matchingTypeFormatorDataSet: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="ml-l-2 blueLink pointer text-truncate" title='${value}'>
  <i class="fal fa-file-spreadsheet pr-1"></i> ${value}
</div>`
};
export const matchingTypeFormatorDataSetColumn: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="blueLink pointer text-truncate" title='${value}'>
  <i class="fal fa-columns pr-1"></i> ${value}
</div>`
};
export const matchingTypeFormatorSemantic: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="blueLink pointer text-truncate" title='${value}'>
  <i class="fal fa-cube pr-1"></i> ${value}
</div>`
};
export const matchingTypeFormatorConcepts: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="blueLink pointer text-truncate" title='${value}'>
  <i class="fal fa-lightbulb-o pr-1"></i> ${value}
</div>`
};

@Component({
  selector: 'zetta-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss']
})
export class AdvanceSearchComponent implements OnInit, OnDestroy {

  public loggedInUserDetails;
  public tableSettings: object = { 'height': "100%", 'width': "99.6%", 'pageSize': 6, 'isNormalPagination': false };
  public tagsTableSettings: object = { 'height': "100%", 'width': "99.6%", 'isNormalPagination': false };

  isDataSetLoading = true;
  isDataSetColumnLoading = true;
  isSemanticObjectLoading = true;
  isConceptLoading = true;
  isTagsLoading = true;
  hasScrolled: Boolean = false;
  hasTagsScrolled: Boolean = false;
  noData: Boolean = false;
  noTagsData: Boolean = false;
  public sparkJobsColumns;
  projectsJobs: string[] = [];
  totalDatasets: number = 0;
  totalDataSetCols: number = 0;
  totalSemanticObject: number = 0;
  totalConcepts: number = 0;
  totalTags: number = 0;
  jobsByProjectId: any[] = [];
  totalJobsByProjectId: number = 0;
  sparkJobs: string[] = [];
  totalSparkJobs: number = 0;
  searchsources:any[]=[]
  searchedDataSets : any[] = [];
  searchedDatasetColumns;
  searchedSemanticObjects;
  searchedConcepts;
  searchedTags;
  eventData: any;
  public manageJobGrid: AngularGridInstance;
  dataviewObj: any;
  gridObj: any;
  projectId: any;
  jobId: any;
  refreshingIn: number;
  refreshingTime: number = 120;  // in seconds
  intervalCountdown: any;
  interval: any;
  selectedApp: any;

  isLoadingDS = true;
  public sparkJobsColumnsDS;
  datasetsJobs: string[] = [];
  jobsByDatasetId: any[] = []; 
  totalJobsByDatasetId: number = 0;
  sparkJobsDS: string[] = [];
  eventDataDS: any;
  public manageJobGridDS: AngularGridInstance;
  dataviewObjDS: any;
  gridObjDS: any;
  datasetId: any;
  jobIdDS: any;
  refreshingInDS: number;
  refreshingTimeDS: number = 120;  // in seconds
  intervalCountdownDS: any;
  intervalDS: any;
  userEntitlement: any = {};
  btnExportJob: any = {};
  btnJobActions: any = {};
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
  is_visible_export = false;
  is_enable_export = false;
  statusDataSets = false;
  statusDataSetColumn = false;
  statusSemanticObject = false;
  statusConcepts = false;
  statusFilters: any = [];
  projectsFiltered: any = []; 
  datasetsFiltered: any = []; 
  projectHomeTableColDefDataSet: any;
  projectHomeTableColDefDataSetColumn: any;
  projectHomeTableColDefSemantic: any;
  projectHomeTableColDefConcepts: any;
  tagsTableColDef: any;
  selectedType='datasets'
  gridOptions: any;
  public grid: AngularGridInstance;
  searchText:string=''
  statusTags: boolean = false;
  searchedTagsData;
  hasAccess: boolean = false;
  
  constructor(private projectService: ProjectService, 
              private ZsClContentSvc: ZsClContentService,
              public zettaUtils: ZettaUtils,
              private messageSvc: MessageService, 
              public _observableService:ObservableService,
              private router: Router,
              ) {
                if (this.login_user) {
                  try {
                    let user_entitlements=this.login_user[0].entitlement;
                    if(this.login_user.length>1){
                      user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
                      if(user_entitlements.length){
                        user_entitlements=user_entitlements[0].entitlement;
                      }else{
                        user_entitlements=this.login_user[0].entitlement;
                      }
                    }          
                    const jobs = user_entitlements.screens.filter(item=>item.name=='system_jobs')[0].childs.filter(item=>item.name=='export_job')[0];
                    if (jobs) {
                      this.is_visible_export =jobs.visible;
                      this.is_enable_export =jobs.enable;
                    }
                      if (this.zettaUtils.getClassify() === AppGlobals.CLASSIFY_APP){
                        this.hasAccess = true;
                      }
                  } catch (error) {
                    console.log(error);
                  }            
                }
               }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.selectedApp = sessionStorage.getItem('selectedApp');
    this.userEntitlement = this.projectService.getObjectFromSession('userEntitlement');
    this._observableService.getSearchObservable.subscribe(text => {
      this.searchText = text;
      if (this.searchText !== undefined && this.searchText !== null && this.searchText !== '') {
        this.getSearchedData();
      }
    })
   
    this.initFilters();
    this.initializeColumn()
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30     //scss file  --> line-height: 60px !important;
    };
  }

  initializeColumn() {
    this.projectHomeTableColDefDataSet = [{
      'displayname': 'Matching Term',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': matchingTypeFormatorDataSet,
      'minWidth': 200,
      'name': 'test'
    }, {
      'displayname': 'Object Type',
      'physicalname': 'datasetObjectType',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }, {
      'displayname': 'From Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    },
    {
      'displayname': 'Relevance',
      'physicalname': 'relevance',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }
    ];
    this.projectHomeTableColDefDataSetColumn = [{
      'displayname': 'Matching Term',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': matchingTypeFormatorDataSetColumn,
      'minWidth': 200,
    }, {
      'displayname': 'Object Type',
      'physicalname': 'datasetColsObjectType',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }, {
      'displayname': 'From Data Set',
      'physicalname': 'dataset_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    },
    {
      'displayname': 'Relevance',
      'physicalname': 'relevance',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }
    ];
    this.projectHomeTableColDefSemantic = [{
      'displayname': 'Matching Term',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': matchingTypeFormatorSemantic,
      'minWidth': 200,
    }, {
      'displayname': 'Object Type',
      'physicalname': 'semanticObjectType',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }, {
      'displayname': 'From Catalog',
      'physicalname': 'catalog_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    },
    {
      'displayname': 'Relevance',
      'physicalname': 'relevance',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }
    ];
    this.projectHomeTableColDefConcepts = [{
      'displayname': 'Matching Term',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': matchingTypeFormatorConcepts,
      'minWidth': 200,
    }, {
      'displayname': 'Object Type',
      'physicalname': 'conceptObjectType',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }, {
      'displayname': 'From Semantic Object',
      'physicalname': 'entity_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    },
    {
      'displayname': 'Relevance',
      'physicalname': 'relevance',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
    }
    ];

    this.tagsTableColDef = [{
      'displayname': 'Catalog',
      'physicalname': 'catalog_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 190,
      'maxWidth': 250,
    }, {
      'displayname': 'Semantic Object',
      'physicalname': 'semantic_object',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 240,
    }, {
      'displayname': 'Concept',
      'physicalname': 'concept',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 240,
    }, {
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 240,
    }, {
      'displayname': 'Data Set',
      'physicalname': 'dataset_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 240,
    },
    {
      'displayname': 'Data Set Column',
      'physicalname': 'dataset_column',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 180,
      'maxWidth': 240,
    }]
  }

  initFilters() {
    $('#status-data-sets').attr('checked', this.statusDataSets);
    $('#status-data-set-column').attr('checked', this.statusDataSetColumn);
    $('#status-semantic-object').attr('checked', this.statusSemanticObject);
    $('#status-concepts').attr('checked', this.statusConcepts);
    $('#status-tags').attr('checked', this.statusTags);

  }
  
  onCheckStatus(status) {
    if (status === 'status-data-sets') {
      this.statusDataSets = !this.statusDataSets;
    }
    if (status === 'status-data-set-column') {
      this.statusDataSetColumn = !this.statusDataSetColumn;
    }
    if (status === 'status-semantic-object') {
      this.statusSemanticObject = !this.statusSemanticObject;
    }
    if (status === 'status-concepts') {
      this.statusConcepts = !this.statusConcepts;
    }
    if (status === 'status-tags') {
      this.statusTags = !this.statusTags;
    }
   
  }

  getSearchedData() {
    this.projectService.getSearchResults(this.loggedInUserDetails.user_id, this.searchText).subscribe((data) => {
      this.searchedDataSets = data.datasets;
      this.searchedDatasetColumns = data.dataset_Columns;
      this.searchedSemanticObjects = data.semantic_Objects;
      this.searchedConcepts = data.concept;
      this.searchedTags = data.tags;      
      if ( this.selectedType === 'datasets' ) {
        this.getSearchedDataSet();
      }
      if ( this.selectedType === 'data-set-column' ) {
        this.getSearchedDataSetCols();
      }
      if ( this.selectedType === 'semantic-object' ) {
        this.getSearchedSemanticObject();
      }
      if ( this.selectedType === 'concepts' ) {
        this.getSearchedConcepts();
      }
      if ( this.selectedType === 'tags' ) {
        this.getSearchedTags();
      }

      this.totalDatasets = this.searchedDataSets.length;
      this.totalDataSetCols = this.searchedDatasetColumns.length;
      this.totalSemanticObject = this.searchedSemanticObjects.length;
      this.totalConcepts = this.searchedConcepts.length;
      this.totalTags = Object.keys(this.searchedTags).length;
    }, err => {
      // this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    })
  }

  getSearchedDataSet() {   
    if (this.searchedDataSets.length === 0) {
      this.noData = true;
    }
    this.isDataSetLoading = false;
    this.searchedDataSets.forEach(element => {
      element.id = Math.random();
      element.datasetObjectType = 'Data Set';
    });
    if (this.grid) {
      this.grid.dataView.setItems(this.searchedDataSets);
      this.grid.gridService.setSelectedRows([]);
      this.grid.dataView.refresh();
      this.hasScrolled = false;
    }
  }

  getSearchedDataSetCols() {   
    if (this.searchedDatasetColumns.length === 0) {
      this.noData = true;
    }
    this.isDataSetColumnLoading = false;
    this.searchedDatasetColumns.forEach(element => {
      element.id = Math.random();
      element.datasetColsObjectType = 'Data Set Column';
    });
    if (this.grid) {
      this.grid.dataView.setItems(this.searchedDatasetColumns);
      this.grid.gridService.setSelectedRows([]);
      this.grid.dataView.refresh();
      this.hasScrolled = false;
    }
  }

  getSearchedSemanticObject() {   
    if (this.searchedSemanticObjects.length === 0) {
      this.noData = true;
    }
    this.isSemanticObjectLoading = false;
    this.searchedSemanticObjects.forEach(element => {
      element.id = Math.random();
      element.semanticObjectType = 'Semantic Object';
    });
    if (this.grid) {
      this.grid.dataView.setItems(this.searchedSemanticObjects);
      this.grid.gridService.setSelectedRows([]);
      this.grid.dataView.refresh();
      this.hasScrolled = false;
    }
  }

  getSearchedConcepts() {   
    if (this.searchedConcepts.length === 0) {
      this.noData = true;
    }
    this.isConceptLoading = false;
    this.searchedConcepts.forEach(element => {
      element.id = Math.random();
      element.conceptObjectType = 'Concept';
    });
    if (this.grid) {
      this.grid.dataView.setItems(this.searchedConcepts);
      this.grid.gridService.setSelectedRows([]);
      this.grid.dataView.refresh();
      this.hasScrolled = false;
    }
  }

  getSearchedTags() {
    if (this.searchedTags.length === 0) {
      this.noTagsData = true;
    }
    this.isTagsLoading = false;

  }

  changeSelectType(type){
    this.selectedType=type
    if ( this.selectedType === 'data-set-column' ) {
      this.getSearchedDataSetCols();
    }
    if ( this.selectedType === 'semantic-object' ) {
      this.getSearchedSemanticObject();
    }
    if ( this.selectedType === 'concepts' ) {
      this.getSearchedConcepts();
    }
    if ( this.selectedType === 'tags' ) {
      this.getSearchedTags();
    }
  }

  noSearchFilterCheckboxSelected(): boolean {
    if (!this.statusDataSets && !this.statusDataSetColumn && !this.statusSemanticObject &&
      !this.statusConcepts && !this.statusTags) {
        return true;
      }
    return false;  
  }

  allSearchFilterCheckboxSelected(): boolean {
    if (this.statusDataSets && this.statusDataSetColumn && this.statusSemanticObject &&
      this.statusConcepts && this.statusTags) {
        return true;
    }
    return false;  
  }

  /**
   * 
   * @param data - Response from search filter
   */
  showAllSearchSelectedData(data) {
    this.showDatasetsFromSearch(true, data.datasets);
    this.showDatasetsColumnsFromSearch(true, data.dataset_Columns);
    this.showSemanticObjectColumnsFromSearch(true, data.semantic_Objects);
    this.showConceptsFromSearch(true, data.concept);
    this.showTagsFromSearch(true, data.tags);
  }

  showDatasetsFromSearch(showDataset: boolean, datasets) {
     if (showDataset && datasets) {
        this.searchedDataSets = datasets;
        this.totalDatasets = this.searchedDataSets.length;
     } else {
        this.searchedDataSets = [];
        this.totalDatasets = 0;
     }
     if (this.selectedType === 'datasets') {
      this.getSearchedDataSet();
     }
  }

  showDatasetsColumnsFromSearch(showDatasetColumns: boolean, datasetsColumn) {
    if (showDatasetColumns && datasetsColumn) {
      this.searchedDatasetColumns = datasetsColumn;
      this.totalDataSetCols = this.searchedDatasetColumns.length;
    } else {
      this.searchedDatasetColumns = [];
      this.totalDataSetCols = 0;
    }
    if (this.selectedType === 'data-set-column') {
      this.getSearchedDataSetCols();
    }
 }

 showSemanticObjectColumnsFromSearch(showSemanticObject: boolean, semanticObject) {
  if (showSemanticObject && semanticObject) {
        this.searchedSemanticObjects = semanticObject;        
        this.totalSemanticObject = this.searchedSemanticObjects.length;        
  } else {
        this.searchedSemanticObjects = [];
        this.totalSemanticObject = 0;
  }
  if (this.selectedType === 'semantic-object') { 
    this.getSearchedSemanticObject();
  }
  
}

showConceptsFromSearch (showConcepts: boolean, conceptsData) {
  if (showConcepts && conceptsData) {
    this.searchedConcepts = conceptsData;
    this.totalConcepts = this.searchedConcepts.length;
  } else {
    this.searchedConcepts = [];
    this.totalConcepts = 0;
  }
  if (this.selectedType === 'concepts') {
    this.getSearchedConcepts();
  }
}

showTagsFromSearch (showTags: boolean, tagsData) {
  if (showTags && tagsData) {
    this.searchedTags = tagsData;
    this.totalTags = Object.keys(this.searchedTags).length;
  } else {
    this.searchedTags = {};
    this.totalTags = 0;
  }
  if ( this.selectedType === 'tags' ) { 
    this.getSearchedTags();
  }
  
}

  onFilterBtn() {
    let searchedFilters = {
      statusDataSets : this.statusDataSets,
      statusDataSetColumn: this.statusDataSetColumn,
      statusSemanticObject: this.statusSemanticObject,
      statusConcepts: this.statusConcepts,
      statusTags: this.statusTags
    }
    let self = this;
    this.projectService.getFilteredSearchResults(this.loggedInUserDetails.user_id, this.searchText, searchedFilters).subscribe((data) => {
     if (self.allSearchFilterCheckboxSelected()|| self.noSearchFilterCheckboxSelected()) {
      self.showAllSearchSelectedData(data);
     } else {
      self.showDatasetsFromSearch(self.statusDataSets,data.datasets);
      self.showDatasetsColumnsFromSearch(self.statusDataSetColumn,data.dataset_Columns);
      self.showSemanticObjectColumnsFromSearch(self.statusSemanticObject, data.semantic_Objects);
      self.showConceptsFromSearch(self.statusConcepts,data.concept);
      self.showTagsFromSearch(self.statusTags, data.tags);
     }
    }, err => {
      // this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    })
  }
   
  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
    }
  }

  onTagsGridScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasTagsScrolled && !this.noTagsData) {
      this.hasTagsScrolled = true;
    }
  }

  onGridCreation(grid) {
    this.grid = grid;
  }
  
  onDataSetCellClick(eventData): void {
    const args = eventData.args;    
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);    
    const fieldName = metadata.columnDef.field;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);    
    if (fieldName === 'name') {
      this.router.navigate(['/zs/datasets', row.dataset_id]);
    }
  }
 
  onDataSetColumnCellClick(eventData): void {
    const args = eventData.args;    
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);    
    const fieldName = metadata.columnDef.field;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);    
    if (fieldName === 'column') {
      this.router.navigate(['/zs/datasets', row.dataset_id]);
    }
  }

  onSemanticObjectCellClick(eventData): void {
    const args = eventData.args;    
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);    
    const fieldName = metadata.columnDef.field;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);    
    if (fieldName === 'name') {
      this.router.navigate(['/zs-cl/catalogs', row.catalog_id, 'objects', row.entity_id], { queryParams: { object_id: row.entity_id, showBreadcrumb: true } });
    }
  }

  onConceptCellClick(eventData): void {
    const args = eventData.args;    
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);    
    const fieldName = metadata.columnDef.field;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);    
    if (fieldName === 'name') {
      this.router.navigate(['/zs-cl/catalogs', row.catalog_id, 'objects', row.entity_id], { queryParams: { object_id: row.entity_id, showBreadcrumb: true } });
    }
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
    this._observableService.unsubscribeSearchData();
    if (this.interval) { clearInterval(this.interval) }
    if (this.intervalCountdown) { clearInterval(this.intervalCountdown) }
    if (this.intervalDS) { clearInterval(this.intervalDS) }
    if (this.intervalCountdownDS) { clearInterval(this.intervalCountdownDS) }
  }

  getObjectStyle() {
    if ($('#collapse0').hasClass('show')) {
      return 'selected-project expanded';
    } else {
      return 'selected-project collapsed';
    }
  }

  toggleAccordian0(event, project) {
    if (this.interval) { 
      clearInterval(this.interval) 
    }
    if (this.intervalCountdown) { 
      clearInterval(this.intervalCountdown) 
    }
    const target = event.target.target;
    if (this.jobsByProjectId['openProject'] !== project) {      
      this.projectsJobs['openProject'] = project;
    } else {
      this.projectsJobs['openProject'] = undefined;
    }
  }
}
