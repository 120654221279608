// no-of-days-renderer.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-no-of-days-renderer',
  template: `
    <div class="text-center">{{ noOfDays }}</div>
  `,
  styles: [/* Add your styles here */]
})
export class NoOfDaysFormatterComponent implements ICellRendererAngularComp {
  params: any;
  noOfDays: number;

  agInit(params: any): void {
    this.params = params;
    const today = new Date();
    const dateToReply = new Date(params.data.last_updated);
    const timeInMilliseconds = today.getTime() - dateToReply.getTime();
    this.noOfDays = Math.floor(timeInMilliseconds / (1000 * 60 * 60 * 24));
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
