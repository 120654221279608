import { ZmZettaUtils } from '../../../common/zm-shared/zm-zettaUtils';
import { ProjectStatus } from './project-progress.model';
import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zm-project-progress',
  templateUrl: './zm-project-progress.component.html',
  styleUrls: ['./zm-project-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZmProjectProgressComponent implements OnInit {

  @Input() stateData: ProjectStatus;

  fadeOut = false;

  constructor(public zettaUtils: ZmZettaUtils) {
  }

  ngOnInit() {
    this.fadeout();
  }

  getStyle(index: number, selectedIndex: number) {
    if (index > selectedIndex + 1) {
      return 'removeLeftPadding';
    } else {
      return 'removeLeftPadding';
    }
  }

  getStateStyle(index: number, selectedIndex: number) {
    if (index === 3 && index === selectedIndex) {
      return 'customLineSelected3Index';
    } else if (index === 3 && index !== selectedIndex) {
      return 'customLineNotSelected3Index';
    }
  }

  getLineStyle(index: number, selectedIndex: number) {
    if (index === 0 && index === selectedIndex) {
      return 'customLineSelected0Index';
    } else if (index === 0 && index !== selectedIndex) {
      return 'customLineNotSelected0Index';
    } else if (index === 1 && index !== selectedIndex) {
      return 'customLineNotSelected1Index';
    } else if (index === 1 && index === selectedIndex) {
      return 'customLineSelected1Index';
    } else if (index === 2 && index === selectedIndex) {
      return 'customLineSelected2Index';
    } else if (index === 2 && index !== selectedIndex) {
      return 'customLineNotSelected2Index';
    }
  }

  fadeout() {
    setTimeout( this.addclass, 1000);
  }
  
  addclass() {
    $('.saved').addClass('fadeOut');
  }

}
