import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZmBaseService } from '../zm-services/zm-base-service';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthenticationService extends ZmBaseService {

	constructor(private http: HttpClient) { super(); }

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}
	signOut() {
		// remove user from local storage to log user out
		localStorage.clear();
		this.clearSession();
		return this.http.get(`${this.apiUrl}/auth/logout`);
	}

	login(obj) {
		return this.http.post(`${this.apiUrl}/auth/login`, obj).pipe(
			map(this.extractData));
	}
}
