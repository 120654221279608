import { Column, Formatter} from 'angular-slickgrid';
import { AppGlobals } from '../app.globals';

export const mergerFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
	function isSelected(){
		let userFeedBack = columnDef.params;
		let shiftedCell = null;
		let selectedRecId =  null;
		let fbValueStr = null;
		if(userFeedBack.has(columnDef.field) && userFeedBack.get(columnDef.field)[columnDef.field] === value){
			dataContext['shiftedCells'] = (dataContext['shiftedCells'] && Array.isArray(dataContext['shiftedCells'])) ? dataContext['shiftedCells'] : [];
			shiftedCell = dataContext.shiftedCells.find(element  => element[columnDef.field] === dataContext[columnDef.field]);
			selectedRecId = shiftedCell ? shiftedCell['zs_recid'] : dataContext['zs_recid'];
			//let fbValueStr =  userFeedBack.get(columnDef.field)[columnDef.field + '_winner'];
			let userWinnerFeedback = userFeedBack[2].userWinnerFeedback;
			let fbValueObj = userWinnerFeedback.find(fdbk => fdbk[columnDef.field + '_winner'] && fdbk['zs_recid'] === dataContext.zs_recid);
			if (fbValueObj) {
				fbValueStr = fbValueObj[columnDef.field + '_winner'];
			}
			return ((fbValueStr !== null && fbValueStr !== undefined && (fbValueStr === '1' || fbValueStr === 1)) || (userFeedBack.get(columnDef.field)['zs_recid'] === selectedRecId)) ? true : false;
			//return (userFeedBack.get(columnDef.field)['zs_recid'] === selectedRecId) ? true : false;
		} else {
			return false;
		}
	}


	function getHtml(isChecked){
		let html = ""; 
		let addOtherText = 'Add Other';
		if (columnDef && (columnDef.name === AppGlobals.SOURCE_SYSTEM || columnDef.name === AppGlobals.IDENTIFIER_AT_SOURCE || columnDef.name === AppGlobals.SOURCE_SYSTEM_IDENTIFIER || 
			columnDef.name === AppGlobals.SOURCE_PRIMARY_KEY || columnDef.name === AppGlobals.LAST_RECORED_UPDATED_DATE || columnDef.name === AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY)) {
			if (value === addOtherText) {
				return '';
			}
			return value;
		}

		if (columnDef.params[1].shouldDisableFeedback) {
			html = `<div class='m-0 p-0 formatter match-radio-formatter' style='pointer-events: none; opacity: 0.6;'>`;
		} else {
			html = `<div class='m-0 p-0 formatter match-radio-formatter'>`;
		}
		 value = value ? value.trimStart(): null;
		if(value === undefined ||  value === null || value === ''){
			return '';
		}

		if(isChecked) {
			html += `<input type='radio' name='' checked />`;
		} else {
			html += `<input type='radio' name='' />`;
		}

		if(value !== "Add Other"){
			html +=  `<label class="radio-formatter radio-label merger-formatter w-100 d-inline-block text-truncate"><span title='${value}'>${value}</span></label>`;
		} else {
			html +=  `<label class="radio-formatter radio-label merger-formatter"></label><input type='text' class="input-text-new-row form-control" name='' placeholder="${value}" value=""/>`;
		}

		html += `</div>`;

		return html;
	}
	return getHtml(isSelected());
}
