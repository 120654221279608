import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { Analytic } from '../../analytic.model';
import { AnalyticService } from '../../analytic.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../zm-project-progress/project-progress.model';

@Component({
    selector: 'schedule-model',
    templateUrl: './schedule-model.component.html',
    styleUrls: ['./schedule-model.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ScheduleModelComponent implements OnInit, OnDestroy {
    isDataReady = false;
    analyticDetail: Analytic = new Analytic();
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    loggedInUserDetails: [];
    errorRibbon = false;

    constructor(
        public zettaUtils: ZmZettaUtils,
        public analyticSvc: AnalyticService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }

        this.activatedRoute.parent.params.subscribe(params => {
            this.analyticDetail.analyticid = params['id'];
        });

        this.progressState.states = this.zettaUtils.getStateList('create-analytic');
        this.progressState.currentStateIndex = 4;
        this.progressState.currentStateInfo = 'Saved...'; 
        this.progressState.type = 'Analitycs';
        this.progressState.project = this.analyticDetail;
        this.progressState.project.name = "Mortgage Default Prediction Model Analytics";            //example
        this.progressState.project.img = "icon-analytics-correlation.png";            //example
        this.isDataReady = true;

    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zm/analytics']);
        } else {
            this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
        }
    }

    onPrevious() {
        // this.router.navigate(['/zm/analytics/create-analytic'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
        this.router.navigate(['/zm/analytics/1/define-segments'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});

    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
    }

    

    addUserRole(boolean: boolean) {
        // const selectedProjectAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
        // const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
        // const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
        // if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
        //     selectedProjectReviewerList.length === 0) {
        //     this.errorRibbon = true;
        // } else {
        //     this.errorRibbon = false;
        //     this.saveUserRole(boolean);
        // }

        this.router.navigate(['/zm/analytics']);

    }

    runModel() {

        // this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
        this.router.navigate(['/zm/analytics/1']);

    }

}
