import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-zm-dashboard',
  templateUrl: './zm-dashboard.component.html',
  styleUrls: ['./zm-dashboard.component.scss']
})
export class ZmDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
