import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

export const entityListNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

	function getIconByEntityID() {
		return zettaUtilsInstance.getProjectEntityClassById(dataContext.entity_id);
	}

	function getColorByEntityID() {
		return zettaUtilsInstance.getProjectEntityColor(dataContext.entity_id);
	}


	function getEntityIcon() {
		return zettaUtilsInstance.getEntityIconClassByName(dataContext.icon);
		// return zettaUtilsInstance.getEntityClassByName(dataContext.entity_id);
	}

	function getColorIcon() {
		return zettaUtilsInstance.getEntityIconColorByName(dataContext.icon);
	}


    let desc;
	dataContext.name = dataContext.semantic_object ? dataContext.semantic_object : '';
	dataContext.description = dataContext.definitions ? dataContext.definitions : '';
	desc=dataContext.description;
	if(dataContext.description.length > 171){
		desc = (dataContext.description.substring(0,171)).trim()+'...'
	}
	let entityNameCss;
    if(!dataContext.description.length){
      entityNameCss = 'mr-top-7';
	}
		return `<div class="Aligner">
					<div class="Aligner-item">
						<div class="item-img">
							<i class='${ dataContext.icon === 'r1c1' ? getIconByEntityID() : getEntityIcon()} mr-2' style='color:${ dataContext.icon === 'r1c1' ?  getColorByEntityID() : getColorIcon()}'></i>
						</div>
						<div class="item-txt ${entityNameCss}">
							<div class="name-ht">
								<span title='${dataContext.name}' class="pointer text-black entity-hyper-link">${dataContext.name}</span>
							</div>
							<div class="description text-truncate desc-wd-56" title='${dataContext.description}'>
								${dataContext.description}
							</div>
						</div>
					</div>
				</div>
				`;
		
};
