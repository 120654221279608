export class Catalog {

    constructor() { }

    catalog_id: number;
    name: string;
    description: string;
    created_by: string;
    last_modified_on: string;
    mapped_data_sources: string;
    mapped_data_sets: string;
    mapped_data_set_columns: string;
    semantic_objects: string;
    concepts: string;
    icon_id: number;
    lookup_id: number;
}

export class Object {

    constructor() { }

    entity_id: number;
    semantic_object: string;
    definitions: string;
    mapped_data_sources: number;
    mapped_data_sets: number;
    is_system: boolean;
}

export class Concept {

    constructor() { }

    logical_name: string;
    description: string;
    mapped_data_columns: string;
    entity_id: number;
    is_system: boolean;
}

export class ReferenceData {

    constructor() { }

    logical_name: string;
    description: string;
    source_name: string;
    entity_id: string;
    dataset_name: string;
    column_name: string
}

export class Project {

    name: string ;
    description: string;
    user_id: string;
    entity_id: string;
    tenant_id: number;
    project_id: string;
    source_ip: string;
    created_ts: Date ;
    updated_ts: Date;
    users: any[];
    active_users: any[];
    deleteusers: any[];
    deletedatasets: any[];
    total_records: number = undefined;
    is_deleted  = false;
    entity_name: string  = undefined;
    img: string = undefined;
    catalog_id: number;
    constructor() {}
}