import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from 'src/app/common/components/project-progress/project-progress.model';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { MessageService } from 'src/app/common/components/message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
declare var $: any;
@Component({
  selector: 'zetta-business-run-schedule',
  templateUrl: './business-run-schedule.component.html',
  styleUrls: ['./business-run-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BusinessRunScheduleComponent implements OnInit, OnChanges {
  progressState = new ProjectStatus();
  timeScheduler;
  enable = true;
  lookup_id = 339;
  createNewRule: Boolean = false;
  prodConfigSchedulerInfo: object = {};
  @Input() datasetSchedule: Boolean;
  @Input() prodConfigSettings?: Boolean = false;
  @Input() resolveProjectId?: Boolean = false;
  @Input() datasetSchedule_ids: any = [];
  @Input() datasetScheduleSaveChanges: Boolean;
  @Input() multiSchedule: Boolean;
  @Input() rules: any;
  @Input() newDataset?: Boolean;
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
  @Output() scheduleEdit: EventEmitter<any> = new EventEmitter();
  @Output() updateProjSchedulerInfo: EventEmitter<any> = new EventEmitter();
  isTechnicalRunSchedule: Boolean = false;
  validateOccurence = false;
  refresh_data = [
    { refresh_data_id: '1', refresh_data_source: AppGlobals.SCHEDULER_ONCE },
    { refresh_data_id: '2', refresh_data_source: AppGlobals.SCHEDULER_SCHEDULED },
  ];
  dataset_sizes = [
    { data_set_size_id: '1', data_set_size: 'Full data set' },
    { data_set_size_id: '2', data_set_size: 'Sample size of' },
  ];
  data_set_size;
  loggedInUserDetails;
  rule_id;
  catalog_id;
  object_id;
  concept_id;
  dataset_id;
  dq_rule_level: string;
  enableNext = false;
  frequents = [];
  weekDays = [];
  weekDaysMonthly = [];
  weekdayMonthly_index = '';
  endTypes = [{ name: 'End By' }, { name: 'End After' }, { name: 'No end date' }];
  isEditView;
  frequentName = 'Daily';
  frequentLabel = 'Day(s)';
  today: string;
  endAfter: string;
  endBy;
  frequentType;
  occurrence;
  selectedEndType;
  sampleSize;
  datasetSize;
  sampleDataset;
  fullDataset;
  dayOccurrence;
  dailyFrequent;
  timeZone;
  weekday_index;
  month_index;
  monthlyOccurenceDay;
  selectDataset;
  selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
  backTo: string;
  is_mapping_changed;
  viewMode = 'business';
  obRule_ids = [];
  showValidationModel = false;
  dataset_ids:string;
  dataset_column_id: string;
  isSchedule = true;
  datasetId;
  data_size;
  dataset_flow = false;
  is_clone = false;

   time_schedule = [
    { schedule_time_id: '1', schedule_time: '01:00' },
    { schedule_time_id: '2', schedule_time: '02:00' },
    { schedule_time_id: '3', schedule_time: '03:00' },
    { schedule_time_id: '4', schedule_time: '04:00' },
    { schedule_time_id: '5', schedule_time: '05:00' },
    { schedule_time_id: '6', schedule_time: '06:00' },
    { schedule_time_id: '7', schedule_time: '07:00' },
    { schedule_time_id: '8', schedule_time: '08:00' },
    { schedule_time_id: '9', schedule_time: '09:00' },
    { schedule_time_id: '10', schedule_time: '10:00' },
    { schedule_time_id: '11', schedule_time: '11:00' },
    { schedule_time_id: '12', schedule_time: '12:00' },
    { schedule_time_id: '13', schedule_time: '13:00' },
    { schedule_time_id: '14', schedule_time: '14:00' },
    { schedule_time_id: '15', schedule_time: '15:00' },
    { schedule_time_id: '16', schedule_time: '16:00' },
    { schedule_time_id: '17', schedule_time: '17:00' },
    { schedule_time_id: '18', schedule_time: '18:00' },
    { schedule_time_id: '19', schedule_time: '19:00' },
    { schedule_time_id: '20', schedule_time: '20:00' },
    { schedule_time_id: '21', schedule_time: '21:00' },
    { schedule_time_id: '22', schedule_time: '22:00' },
    { schedule_time_id: '23', schedule_time: '23:00' },
    { schedule_time_id: '00', schedule_time: '00:00' },
  ];
  MonthlyOccurences = [];
  weeks = ['First', 'Second', 'Third', 'Fourth', 'Last'];
  week_index = '';
  timeZones = [];
  switchBtn = true;
  everyNDays = true;
  everyWeekday = false;
  dayOption = true;
  theOption = false;
  dayMonthOccurrence;
  theMonthOccurrence;
  everyWeeks = true;
  weekOccurrence = 1;
  dayNof = 1;
  is_validateForm = false;
  scheduled_time = this.time_schedule[0]['schedule_time'];
  day_index = [];
  object_type_id;
  day_selected = [];
  schedulerObjectId;
  newDataSet;
  weekdayMonthlySelected = '';
  weekSelected = '';
  current_day;
  disableRunBtn : Boolean = false;
  ruleInfo: any;
  showSchedulerDetails: boolean = true;
  schedulerRuleInfo;
  notScheduled: boolean = false;
  constructor(private service: ZsClContentService,
    private router: Router,
    public zettaUtils: ZettaUtils,
    private route: ActivatedRoute,
    private messageSvc: MessageService,
  ) { }

  ngOnInit() {
    this.object_type_id = this.datasetSchedule ? '14779' : '14948';
    this.viewMode = sessionStorage.getItem('viewMode') ? sessionStorage.getItem('viewMode') : 'business';
    this.newDataSet = sessionStorage.getItem('newDataSet') ? sessionStorage.getItem('newDataSet') : false;
    this.ruleInfo = JSON.parse(sessionStorage.getItem('ruleInfo'));
    this.getSchedulerFrequency();
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.route.parent.params.subscribe(params => {
      this.catalog_id = params['catalog_id'];
    });
    if (this.router.url.includes('create-bussiness-rule') || this.router.url.includes('create-technical-rule')) {
      this.route.queryParamMap.subscribe(params => {
        this.catalog_id = +params.get('catalog_id');
        this.object_id = +params.get('object_id');
        this.concept_id = +params.get('concept_id');
        this.dq_rule_level = params.get('dqRuleLevel');
        this.dataset_ids = params.get('dataset_id');
        this.dataset_column_id = params.get('dataset_column_id');
        this.backTo = params.get('BackTo');
      });
    }
    this.object_id = this.route.snapshot.queryParamMap.get('object_id');
    this.concept_id = this.route.snapshot.queryParamMap.get('concept_id');
    this.dataset_id = this.route.snapshot.queryParamMap.get('datasets');
    this.route.queryParamMap.subscribe(params => {
      this.rule_id = +params.get('rule_id');
      this.obRule_ids = params.getAll('selectoobRule');
      this.dataset_flow = params.get('dataset_flow') === "true" ? true : false;
      const is_clone = params.get('is_clone');
      if (is_clone && is_clone === 'true') {
          this.is_clone = true;
      }
    });
    if (this.rule_id > 0) {
      this.obRule_ids.push(this.rule_id);
    }

    this.route.queryParamMap.subscribe(param => {
      if (param.get('isMappingChanged') == 'true') {
        this.is_mapping_changed = true;
      }
    });

    if(this.datasetSchedule) {
      if(this.newDataSet == 'true'){
        this.schedulerObjectId = sessionStorage.getItem('bulkDatasetIds');
      } else {
        this.route.parent.params.subscribe(params => {
          this.schedulerObjectId = params['id'];
        });
      }
    } else {
      this.schedulerObjectId = this.rule_id;
    }
    this.service.getGroupScheduler('SchedulerFrequency').subscribe(response => {
      response.forEach(element => {
        element.label = element.name == 'Daily' ? 'Day(s)' : element.name == 'Weekly' ? 'Week(s)' : element.name == 'Monthly' ? 'Month(s)' : '';
        if(element.name == 'Daily' || element.name == 'Weekly' || element.name == 'Monthly'){
          this.frequents.push(element)
        }
      });
      this.updateFrequent(0);
    });

    if (this.router.url.includes('create-bussiness-rule') || this.router.url.includes('create-technical-rule')) {
      this.createNewRule = true;
      //By Default
      this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;   //"Once" || Scheduled
      this.isSchedule = false;
      this.selectDataset = "Full data set";
      this.dayOccurrence = 1;
      this.occurrence = null;
      this.selectedEndType = "No end date";
      this.getDateToday();
      this.changeOccurence('everyNDays');
    }

    if (this.router.url.includes('dataset') ) {
      //By Default
      this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;   //"Once" || Scheduled ;
      this.isSchedule = false;
      this.selectDataset = "Full data set";
      this.dayOccurrence = 1;
      this.occurrence = null;
      this.selectedEndType = "No end date";
      this.getDateToday();
      this.changeOccurence('everyNDays');
    }

    if (this.router.url.includes('bussiness-run-schedule')) {
      this.createNewRule = true;
      this.isTechnicalRunSchedule = false;
      this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
      this.progressState.currentStateIndex = 7;
      this.progressState.type = 'create-bussiness-rules';
      this.route.queryParamMap.subscribe(params => {
        this.object_id = +params.get('object_id');
      })
    }

    if (this.router.url.includes('technical-run-schedule')) {
      this.isTechnicalRunSchedule = true;
      this.createNewRule = true;
      this.progressState.states = this.zettaUtils.getStateList('create-technical-rules');
      this.progressState.currentStateIndex = 2;
      this.progressState.type = 'create-technical-rules';
      this.route.queryParamMap.subscribe(params => {
        this.dataset_id = +params.get('dataset_id');
      })
    }

    this.getDateToday();

    this.service.isEditView.subscribe(res => {
      if(res == true) {
        if(this.schedulerRuleInfo) {
          if(this.schedulerRuleInfo['scheduler_type_lookup_id'] == AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID) {
            this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;
            this.isSchedule = false;
            this.validateForm();
          } else {
            this.selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
            this.isSchedule = true;
            this.mapDetails(this.schedulerRuleInfo);
          }
        }
      }
      this.isEditView = res
    if(res==false) {
      if(this.schedulerRuleInfo) {
      if(this.schedulerRuleInfo['scheduler_type_lookup_id'] == AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID) {
        this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;
        this.isSchedule = false;
        this.validateForm();
      } else {
        this.selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
        this.isSchedule = true;
        this.mapDetails(this.schedulerRuleInfo);
      }}
    }
    });
    if (this.rule_id !== 0 || this.datasetSchedule) {
      if ((!this.createNewRule || this.datasetSchedule || this.is_clone) && !this.newDataset) {
        this.showSchedulerDetails = false;
        setTimeout(() => {
          this.getScheduleRuleDetails();
        }, 500);
      }
    }
    this.getTimeZones();

    this.service.getGroupScheduler('Weekdays').subscribe(response => {
      this.weekDaysMonthly = response;
      response.forEach((element, index) => {
        if(['1','2','3','4','5','6','7'].includes(element.value)){
          this.weekDays.push({name: element.name, value: element.value, id: element.value, selected: false})
        }
      });
      this.weekDays.sort((x, y) => x.value - y.value);
      this.weekDaysMonthly.sort((x, y) => x.value - y.value);
    });

    this.service.getGroupScheduler('ObjectTypeId').subscribe(response => {
    });
    if(this.prodConfigSettings) {
      this.dayOccurrence = 1;
      this.isEditView = false;
    }
  }
  
  getDateToday() {
    var today = new Date();
    var d = today.getDate();
    var day = (d <= 9 ? '0' + d : d);
    var m = today.getMonth() + 1;
    var month = (m <= 9 ? '0' + m : m);
    var year = today.getFullYear();
    this.today = year + '-' + month + '-' + day;
    this.current_day = this.today;
  }

  getTimeZones() {
    this.service.getGroupScheduler('Timezones').subscribe(response => {
      this.timeZones = response;
    });
  }

  getSchedulerFrequency() {
    this.service.getSchedulerFrequency().subscribe(response => {
      this.timeScheduler = response;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes) {
        if(!this.datasetSchedule) {
          if (changes && changes.rules && changes.rules.currentValue) {
            this.rules = changes.rules.currentValue;
          }
          if(changes.multiSchedule && changes.multiSchedule.currentValue) {
            this.multiSchedule = true;
            this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;
            this.isSchedule = false;
            this.validateForm();
            } else {
              this.multiSchedule = false;
            }
            if(this.prodConfigSettings && changes.resolveProjectId && changes.resolveProjectId.currentValue) {
              this.resolveProjectId = changes.resolveProjectId.currentValue;
              this.prodConfigSettings = changes.prodConfigSettings.currentValue;
              this.isEditView = false;
            }
        } else {
          if (changes.datasetSchedule_ids.currentValue) {
            this.datasetSchedule_ids = changes.datasetSchedule_ids.currentValue;
          }
          if(this.datasetScheduleSaveChanges) {
            let datasetSchedule_ids = [];
            this.datasetSchedule_ids.forEach(ele => { datasetSchedule_ids.push(parseInt(ele)) });
            datasetSchedule_ids.forEach(ele => {
              this.scheduleRuleExecution(false, ele);
            });
          }
          if(changes.newDataset && changes.newDataset.currentValue) {
            this.newDataset = changes.newDataSet.currentValue;
          }
        }
      }
  }

  scheduleRuleExecution(runRule, ruleid) {
    let data;
    this.schedulerRuleInfo = {};
    if(!this.isSchedule) {
      data = [{ "scheduler_type_lookup_id": AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID, "scheduler_object_id": +ruleid}];
    } else {
      if (this.lookup_id === 339) {
        data = [{
          scheduler_type_lookup_id: this.lookup_id,
          scheduler_object_id: +ruleid,
          timezone: this.timeZone,
          scheduler_start_date: this.today,
          scheduler_time: this.scheduled_time,
          frequency: this.everyNDays ? this.dayOccurrence : null,
          is_every_weekday: this.everyWeekday ? true : false,
          ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
          ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
          ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
          data_size: this.data_size,
        }];
      } else if (this.lookup_id === 340) {
        data = [{
          scheduler_type_lookup_id: this.lookup_id,
          scheduler_object_id: +ruleid,
          timezone: this.timeZone,
          scheduler_start_date: this.today,
          scheduler_time: this.scheduled_time,
          day_index: this.day_index.toString(),
          frequency: +this.weekOccurrence,
          ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
          ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
          ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
          data_size: this.data_size,
        }];
      } else if (this.lookup_id === 341) {
        data = [{
          scheduler_type_lookup_id: this.lookup_id,
          scheduler_object_id: +ruleid,
          timezone: this.timeZone,
          scheduler_start_date: this.today,
          scheduler_time: this.scheduled_time,
          ...this.dayOption ? { date_index: this.dayNof, frequency: this.dayMonthOccurrence, } : { },
          ...this.theOption ? { week_index: this.week_index, day_index: this.weekdayMonthly_index, frequency: this.theMonthOccurrence, } : { },
          ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
          ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
          ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
          data_size: this.data_size,
        }];
      }
    }
    if (this.createNewRule || this.isTechnicalRunSchedule) {
      this.service.createUpdateScheduler(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.object_type_id, data).subscribe(res => {
      }, error => {
          this.messageSvc.sendMessage({ message: error.error[0].message, type: 'ERROR', hideboard: true });
      });
      if (!this.datasetSchedule) {
        if (runRule) {
          this.service.reRunRule(ruleid, this.viewMode, this.is_mapping_changed).subscribe(resp => {
            this.messageSvc.sendMessage({
              message: '', type: 'Info', hideInfo: true, activeJobs: 'DataQuality',
              messageHeader: AppGlobals.RULES_MSG_HEADER, messageText: AppGlobals.RULES_MSG_TEXT,
              path: 'zs-cl/dataquality'
            });
            $('.modal-backdrop').remove();
          });
        } else {
          sessionStorage.setItem('newRuleSuccess', 'true');
          if (this.viewMode == "business") {
            this.service.reRunRule(ruleid, this.viewMode, this.is_mapping_changed).subscribe((resp) => { });
          }
          this.navigateBack('');
        }
      }
    } else {
      if(this.datasetSchedule_ids && this.datasetSchedule_ids.length && this.datasetSchedule_ids.length > 0) {
          this.service.createUpdateScheduler(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.object_type_id, data).subscribe(res => { 
            if(res && !this.newDataSet) {
              this.messageSvc.sendMessage({ message: 'Changes saved successfully.', type: 'SUCCESS', hideboard: true });
            }
        }, error => { 
              this.messageSvc.sendMessage({message: error.error[0].message, type: 'ERROR', hideboard: true});
          });
      } 

      if ((this.rules && this.rules.length && this.rules.length > 0 && !this.datasetSchedule) || this.multiSchedule) {
        let payload = Array(this.rules.length).fill(data[0]);
        let updatedPayload = payload.map((ele, ind) => { 
         return {...ele, 'scheduler_object_id': this.rules[ind]['rule_id']}
        });
        let self = this;
        this.service.createUpdateScheduler(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.object_type_id, updatedPayload).subscribe(res => { 
            self.messageSvc.sendMessage({ message: 'Changes saved successfully.', type: 'SUCCESS', hideboard: true });
            self.scheduleEdit.emit(true);
            self.modelPopupOutput.emit(true);
        }, error => { 
            self.messageSvc.sendMessage({message: error.error[0].message, type: 'ERROR', hideboard: true});
            self.modelPopupOutput.emit(true);
        });
        
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.multiSchedule = false;
      } else if (!this.datasetSchedule) {
        this.service.createUpdateScheduler(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.object_type_id, data).subscribe(res => { 
          this.messageSvc.sendMessage({ message: 'Changes saved successfully.', type: 'SUCCESS', hideboard: true });
          sessionStorage.removeItem('activeNavLnkTechnical');
          sessionStorage.removeItem('activeNavLnkBusiness');
        }, error => { 
          this.messageSvc.sendMessage({message: error.error[0].message, type: 'ERROR', hideboard: true});
        });
        this.scheduleEdit.emit(true);
      }
    }
  }
 
  saveAndRunRule() { 
    this.updateRunExecutor(true);
    this.obRule_ids.forEach(ele => {
      this.scheduleRuleExecution(true, ele);
    });
  }
  
  saveAndCloseRule() {
    this.updateRunExecutor(false);
    this.obRule_ids.forEach(ele => {
      this.scheduleRuleExecution(false, ele);
    });
    sessionStorage.removeItem("ruleInfo");
    sessionStorage.removeItem("ruleInfoClone");
    sessionStorage.removeItem("tRuleDefinition");
  }

  saveChangesRule(){
    // this.scheduleRuleExecution(false,this.rule_id);
    this.scheduleRuleExecution(false,this.schedulerObjectId);
  }

  applyChangesRule(){
      this.scheduleRuleExecution(false, this.rules);
  }

  updateRunExecutor(is_run_executor) {
    const data = {
      rule_id: this.rule_id,
      payload: {
        run_executor: is_run_executor
      }
    };
    this.service.updateRunExecutor(data).subscribe(resp => {
    }, error => { });
  }

  onPrevious() {
    this.router.navigate(['zs-cl/rules/create-technical-rule']);
  }

  onPreviousClick() {
    if (this.router.url.includes('create-bussiness-rule')) {
      this.router.navigate(['/zs-cl/rules/create-bussiness-rule/add-users'], { queryParamsHandling: 'preserve' });
    } else if (this.router.url.includes('create-technical-rule')) {
      this.router.navigate(['/zs-cl/rules/create-technical-rule/add-users'], { queryParamsHandling: 'preserve' });
    }
    if (this.router.url.includes('data-quality-rules')) {
      this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/add-users'], { queryParams: { object_id: this.object_id, concept_id: this.concept_id, datasets: this.dataset_id } });
    }
  }

  navigateBack(opc) {
    $('.modal').modal('hide');
    if (this.dq_rule_level == 'concept') {
      if (this.backTo == 'catalog') {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'catalog') {
      if (this.backTo == 'catalog') {
        this.router.navigate(['/zs-cl/catalogs'], { queryParams: { showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'object') {
      if (this.backTo == 'catalog') {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
      } else {
        this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
      }
    } else if (this.dq_rule_level == 'dataset') {
      if(this.dataset_id && !this.dataset_column_id && this.backTo === 'dataset'){
        this.router.navigate([`/zs/datasets`]);   
      }  
      if (this.dataset_id && !this.dataset_column_id && this.backTo !== 'dataset') {
        this.router.navigate(['/zs/datasets/' + this.dataset_ids + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
      if (this.dataset_ids && this.dataset_column_id) {
        this.router.navigate(['/zs/datasets/' + this.dataset_ids + '/columns/' + this.dataset_column_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
    } else {
      this.viewMode = sessionStorage.getItem('viewMode') ? sessionStorage.getItem('viewMode') : 'business';

      setTimeout(() => {
        if(opc == 'Cancel') {
          sessionStorage.removeItem("ruleInfo");
          sessionStorage.removeItem("ruleInfoClone");
          sessionStorage.removeItem("tRuleDefinition");
          // removed deleting the rule for technical rule as part of requirement
          if (!this.isTechnicalRunSchedule) {
            this.service.deleteDataQualityRule({ dq_rules_ids: this.obRule_ids }).subscribe(resp => { });
          }
          this.router.navigate([`/zs-cl/dataquality`]); 
        } else {
          this.router.navigate(['/zs-cl/dataquality/0/' + this.viewMode + '/rule-definition'], { queryParams: { rule_id: '0' } });
        }
      }, 500);
    }
  }

  updateFrequent(ind) {
    this.frequentName = this.frequents[ind]['name'];
    this.frequentLabel = this.frequents[ind]['label'];
    this.dayOccurrence = '1';
    if (this.frequentName == 'Daily') {
      this.lookup_id = 339;
      this.everyNDays = true;
      this.everyWeekday = false;
      this.dayOccurrence = '1';
      this.weekDays.forEach(ele => { ele.selected = false; });
    } else if (this.frequentName == 'Weekly') {
      this.lookup_id = 340;
      this.month_index = "";
      this.weekOccurrence = 1;
    } else if (this.frequentName == 'Monthly') {
      this.lookup_id = 341;
      this.dayOption = true;
      this.theOption = false;
      this.dayNof = 1;
      this.dayMonthOccurrence = 1;
      this.theMonthOccurrence = null;
      this.weekDays.forEach(ele => { ele.selected = false; });
      this.week_index = '';
      this.weekdayMonthly_index = '';
    } 
    this.validateForm();
  }

  selectEndType(val, ind) {
    this.selectedEndType = this.endTypes[ind]['name'];
    if (this.selectedEndType === 'No end date') {
      this.occurrence = null;
      this.endBy = null;
    } else if (this.selectedEndType === 'End After') {
      this.occurrence = 1;
      this.endBy = null;
    } else {
      this.occurrence = null;
    }
    this.validateForm();
  }

  getScheduleRuleDetails() {
    if(this.is_clone){
      this.schedulerObjectId = this.ruleInfo.data_quality_rule_id;
    }
    this.service.getSchedulerByObjectId(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.object_type_id, this.schedulerObjectId).subscribe(res => {
      if(res) {
        this.schedulerRuleInfo = res;
        if(res['scheduler_type_lookup_id'] == AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID) {
          this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;
          this.isSchedule = false;
          this.validateForm();
        } else {
          this.selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
          this.isSchedule = true;
          this.mapDetails(res);
        }
        this.notScheduled = false;
      } else {
        if(!this.datasetSchedule && !this.createNewRule && !this.isTechnicalRunSchedule) {
          this.notScheduled = true;
          this.selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
          this.isSchedule = true;
        }
      }
      this.showSchedulerDetails = true;
    },
    error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
      this.showSchedulerDetails = true;
    });
  }

  mapDetails(details) {
      if (details) {
      this.timeZone = details['timezone'];
      this.scheduled_time = details['start_time'] ? details['start_time'].substring(0, 5) : this.time_schedule[0]['schedule_time'];
      this.today = details['scheduler_start_date'] ? details['scheduler_start_date'] : null;
      this.endBy = details['scheduler_end_date'] && details['scheduler_end_date'] !== "Invalid date" && details['scheduler_end_date'] !== null ? details['scheduler_end_date'] : null;
      this.selectedEndType = details['scheduler_end_date'] && details['scheduler_end_date'] !== "Invalid date" && details['scheduler_end_date'] !== null ? 'End By' : details['no_of_occurances'] && details['no_of_occurances'] !== null ? 'End After' : 'No end date';
      this.occurrence = details['no_of_occurances'];
      this.lookup_id = details['scheduler_type_lookup_id'];
      if (details['data_size'] !== null) {
        this.selectDataset = 'Sample size of'; ''
        this.data_size = details['data_size'];
      } else {
        this.selectDataset = 'Full data set';
        this.data_size = null;
      }
      if (this.lookup_id === 339) {
        this.frequentName = 'Daily';
        this.frequentLabel = 'Day(s)';
        this.dayOccurrence = details['frequency'];
        if(this.dayOccurrence) {
          this.everyNDays = true;
          this.everyWeekday = false;
        } else {
          this.everyNDays = false;
          this.everyWeekday = true;
        }
      } else if (this.lookup_id === 340) {
        this.frequentName = 'Weekly';
        this.frequentLabel = 'Week(s) on:';
        this.weekOccurrence = details['frequency'];
        details['day_index'].split(',').forEach(ele => { this.day_index.push(parseInt(ele)) });
        this.day_index = details['day_index'] ? details['day_index'].split(',') : [];
        this.weekDays.forEach((ele) => {
          if (this.day_index.includes(ele.value)) {
            this.day_selected.push(ele.name)
          }
        });
      } else if (this.lookup_id === 341) {
        this.frequentName = 'Monthly';
        this.frequentLabel = 'Month(s)';
        this.month_index = details['month_index'];
        this.dayNof = details['date_index'];
        this.week_index = details['week_index'];
        this.dayOption = this.dayNof && !this.week_index ? true : false;
        this.theOption = !this.dayNof && this.week_index ? true : false;
        // "Day" option
        this.dayMonthOccurrence = this.dayOption ? details['frequency'] : null;
        // "The" option
        if(this.theOption) {
          this.week_index = details['week_index'];
          this.weekSelected = this.weeks[+this.week_index - 1];
          this.weekdayMonthly_index = details['day_index'];
          this.weekdayMonthlySelected = this.weekDays.filter(e => e.value == this.weekdayMonthly_index)[0].name;
          this.theMonthOccurrence = !this.dayNof ? details['frequency'] : null;
        }
      }
    }
  }

  refreshDataChange(ind) {
    this.selectRefreshData = this.refresh_data[ind]['refresh_data_source'];
    if (this.selectRefreshData == AppGlobals.SCHEDULER_ONCE) {
      this.isSchedule = false;
      this.validateForm();
    } else {
      this.isSchedule = true;
      this.validateForm();
    }
  }

  datasetSizeChange(data, ind) {
    this.selectDataset = this.dataset_sizes[ind]['data_set_size'];
    this.validateForm();
  }

  updateWeekdays(value) {
    let dayValue = this.weekDays.filter(day => day.value == value)[0].name;
    this.weekDays.forEach(ele => { 
      if(!this.day_index.includes(value)) {
        this.day_index.push(value);
      } else {
        this.day_index = this.day_index.filter(ele => ele != value);
      }
      if(!this.day_selected.includes(dayValue)) {
        this.day_selected.push(dayValue);
      } else {
        this.day_selected = this.day_selected.filter(ele => ele != dayValue);
      }
    });
    this.validateForm();
  }

  timeSelected(time) {
    this.scheduled_time = time;
    this.validateForm();
  }

  timeZoneSelected(timeZone) {
    this.timeZone = timeZone;
    this.validateForm();
  }
  
  changeOccurence(op) {
    if (this.frequentName == 'Daily') {
      if(op == 'everyNDays') {
        this.everyNDays = true;
        this.everyWeekday = false;
        this.dayOccurrence = '1';
      } else {
        this.everyNDays = false;
        this.everyWeekday = true;
        this.dayOccurrence = '';
      }
    } else if (this.frequentName == 'Weekly') {

    } else if (this.frequentName == 'Monthly') {
      if(op == 'dayOption') {
        this.dayOption = true;
        this.theOption = false;
        this.dayNof = 1;
        this.dayMonthOccurrence = 1;
        this.theMonthOccurrence = null;
        this.week_index = '';
        this.weekdayMonthly_index = '';
      } else {
        this.dayOption = false;
        this.theOption = true;
        this.theMonthOccurrence = 1;
        this.week_index = '1';
        this.weekdayMonthly_index = '1';
        this.dayNof = null;
        this.dayMonthOccurrence = null;
      }
    }
    this.validateForm();
  }

  toggleView() {
    this.service.isEditView.next(false);
    this.isEditView = false;
    if(this.schedulerRuleInfo) {
      if(this.schedulerRuleInfo['scheduler_type_lookup_id'] == AppGlobals.SCHEDULER_TYPE_ONCE_LOOKUPID) {
        this.selectRefreshData = AppGlobals.SCHEDULER_ONCE;
        this.isSchedule = false;
        this.validateForm();
      } else {
        this.selectRefreshData = AppGlobals.SCHEDULER_SCHEDULED;
        this.isSchedule = true;
        this.mapDetails(this.schedulerRuleInfo);
      }
    }
  }

  changeDaily(op) {
    if(op == 'everyNDays') {
      this.everyNDays = true;
      this.everyWeekday = false;
      this.dayOccurrence = '1';
    } else {
      this.everyNDays = false;
      this.everyWeekday = true;
      this.dayOccurrence = '';
    }
  }

  changeMonthly(op) {
    if(op == 'dayOption') {
      this.dayOption = true;
      this.theOption = false;
      this.theMonthOccurrence = 1;
    } else {
      this.dayOption = false;
      this.theOption = true;
      this.theMonthOccurrence = null;
    }
  }

  checkForConfigValidations() {
    if (this.lookup_id === 339) {
      this.prodConfigSchedulerInfo = [{
        scheduler_type_lookup_id: this.lookup_id,
        scheduler_object_id: +this.resolveProjectId,
        timezone: this.timeZone,
        scheduler_start_date: this.today,
        scheduler_time: this.scheduled_time,
        scheduler_object_type_id: 14778,
        tenant_id: this.loggedInUserDetails.tenant_id,
        frequency: this.everyNDays ? this.dayOccurrence : null,
        is_every_weekday: this.everyWeekday ? true : false,
        ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
        ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
        ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
        data_size: this.data_size,
      }];
    } else if (this.lookup_id === 340) {
      this.prodConfigSchedulerInfo = [{
        scheduler_type_lookup_id: this.lookup_id,
        scheduler_object_id: +this.resolveProjectId,
        timezone: this.timeZone,
        scheduler_start_date: this.today,
        scheduler_time: this.scheduled_time,
        day_index: this.day_index.toString(),
        scheduler_object_type_id: 14778,
        tenant_id: this.loggedInUserDetails.tenant_id,
        frequency: +this.weekOccurrence,
        ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
        ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
        ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
        data_size: this.data_size,
      }];
    } else if (this.lookup_id === 341) {
      this.prodConfigSchedulerInfo = [{
        scheduler_type_lookup_id: this.lookup_id,
        scheduler_object_id: +this.resolveProjectId,
        timezone: this.timeZone,
        scheduler_start_date: this.today,
        scheduler_time: this.scheduled_time,
        ...this.dayOption ? { date_index: this.dayNof, frequency: this.dayMonthOccurrence, } : { },
        ...this.theOption ? { week_index: this.week_index, day_index: this.weekdayMonthly_index, frequency: this.theMonthOccurrence, } : { },
        ...this.selectedEndType === 'End By' ? { scheduler_end_date: this.endBy } : { },
        ...this.selectedEndType === 'End After' ? { no_of_occurances: this.occurrence, } : { }, 
        ...this.selectedEndType === 'No end date' ? { no_end_date: true, } : { no_end_date: false, },
        data_size: this.data_size,
        tenant_id: this.loggedInUserDetails.tenant_id,
        scheduler_object_type_id: 14778,
      }];
    }
    this.updateProjSchedulerInfo.emit({schedulerInfo: this.prodConfigSchedulerInfo, enableButtonCondition: this.is_validateForm});
}

  validateForm(){
    this.is_validateForm = false;
    this.validateOccurence = false;
    this.weekDays.forEach(ele => {
      if (this.day_index.includes(ele.value)) {
        this.validateOccurence = true;
      }
    });

    if(!this.datasetSchedule) {
      if((this.timeZone && this.timeZone !== 'Select Frequency' && this.scheduled_time) && 
        (this.frequentName == 'Daily' ? ((this.everyNDays && this.dayOccurrence) || this.everyWeekday) 
                                      : this.frequentName == 'Weekly' ? (this.weekOccurrence && this.validateOccurence) 
                                                                      : this.frequentName == 'Monthly' ? ((this.dayOption && this.dayNof && this.dayMonthOccurrence) || (this.theOption && this.theMonthOccurrence)) 
                                                                                                        : '') && 
        (this.today && (this.selectedEndType === 'No end date' || (this.selectedEndType === 'End After' && this.occurrence) || (this.selectedEndType === 'End By' && this.endBy && (new Date(this.endBy) > new Date(this.today)))))) 
      {
        this.is_validateForm = true;
      } else {
        this.is_validateForm = false;
      }
    } else {
      if((this.timeZone && this.timeZone !== 'Select Frequency' && this.scheduled_time) && 
        (this.frequentName == 'Daily' ? ((this.everyNDays && this.dayOccurrence) || this.everyWeekday) 
                                      : this.frequentName == 'Weekly' ? (this.weekOccurrence && this.validateOccurence) 
                                                                      : this.frequentName == 'Monthly' ? ((this.dayOption && this.dayNof && this.dayMonthOccurrence) || (this.theOption && this.theMonthOccurrence)) 
                                                                                                        : '') && 
        (this.today && (this.selectedEndType === 'No end date' || (this.selectedEndType === 'End After' && this.occurrence) || (this.selectedEndType === 'End By' && this.endBy && (new Date(this.endBy) > new Date(this.today))))) &&  
        (this.selectDataset == 'Full data set' || (this.selectDataset == 'Sample size of' && this.data_size > 0))) 
      {
        this.is_validateForm = true;
      } else {
        this.is_validateForm = false;
      }
      if(this.prodConfigSettings) {
        this.checkForConfigValidations();
      }
    }
    if(!this.isSchedule)
    {
      this.is_validateForm = true;
      this.disableRunBtn = false;
    } else if(this.isSchedule && this.createNewRule) {
      this.disableRunBtn = true;
    } else {
      this.disableRunBtn = false;
    }

    if(this.prodConfigSettings) {
      this.checkForConfigValidations();
    }
  }
  onReProfileDataset() {
    const engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
    let algo_id = engine_type_id === 452 ? 404 : 300;
    this.route.params.subscribe(params => {
      this.dataset_id = +params['id'];
    });
    const payload = {
      "dataset_id": [this.dataset_id],
      "stage": "stage2"
    };
    this.service.reProfileDatasetStage2(payload).subscribe((result) => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs: 'Dataset', messageHeader: AppGlobals.DATASET_REPROFILE_HEADER_MSG, messageText: AppGlobals.DATASET_REPROFILE_MSG_TEXT, path: 'zs/datasets/' + this.dataset_id, queryParams: { showBreadcrumb: true } });
    }, error => {
      this.messageSvc.sendMessage({ message: `Algo ${algo_id} call has failed`, type: 'ERROR', hideboard: true });
    });
  }

  updateOccurrence(val) {
    this.occurrence = val;
    this.validateForm();
  }

  updateEndBy(val) {
      this.endBy = val;
      this.validateForm();
  }

  updateStartDate(val) {
    this.today = val;
  }
  
  updatedayOccurrence(val) {
      this.dayOccurrence = val;
      this.validateForm();
  }

  updateMonthly(val) {
    this.theMonthOccurrence = val;
    this.validateForm();
  }

  updateDayNumber(val) {
    this.dayNof = val;
    this.validateForm();
  }

  updateDayMonthOccurrence(val) {
    this.dayMonthOccurrence = val;
    this.validateForm();
  }

  updateDataSize(val) {
    this.data_size = val;
    this.validateForm();
  }
}
