
import { Column, Formatter} from 'angular-slickgrid';

export const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>

value ?
  `<div class="div-checkbox checkbox-item">
  <label class="checkbox-container">
  <input type="checkbox" checked>
  <span class="checkmark"></span>
  </label>
  </div>` 
  :
  `<div class="div-checkbox checkbox-item"> 
  <label class="checkbox-container">
  <input type="checkbox">
  <span class="checkmark"></span>
  </label>
  </div>`;
