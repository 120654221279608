import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
// import { ZettaUtils } from '../../../../assets/images/';
const zettaUtilsInstance = new ZettaUtils();

export const datasourceNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
	dataContext.description = dataContext.description === '' || dataContext.description === null ? '' : dataContext.description;
	let isAdmin = false;
	if(dataContext.entitlements){    
		if(dataContext.entitlements.menu.clone && dataContext.entitlements.menu.delete && dataContext.entitlements.menu.edit){     
			isAdmin = true;      
		}
	} 
	return isAdmin 
		? `<div class="datasource-name-formatter text-wrap text-truncate">
				<span title='${dataContext.name}' class="pointer hyper-link text-black text-truncate wd-8">${dataContext.name}</span><br>
				<span title='${dataContext.description}' class="text-regular text-truncate wd-8">${dataContext.description}</span>
		   </div>` 
		: `<div class="datasource-name-formatter text-wrap text-truncate disabled-state">
				<span title='${dataContext.name}' class="pointer hyper-link text-black text-truncate wd-8">${dataContext.name}</span><br>
				<span title='${dataContext.description}' class="text-regular text-truncate wd-8">${dataContext.description}</span>
	  	  </div>`;
};
