import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ZsClContentService } from "../../../../../zettaclassify/zs-cl-content/zs-cl-content.service";
import { ZettaUtils } from "../../../../shared/zettaUtils";
import { MessageService } from "../../../message/message.service";
import { ProjectStatus } from "../../../project-progress/project-progress.model";
declare var $: any;
@Component({
    selector: 'zetta-select-concept',
    templateUrl: 'select-concept.component.html',
    styleUrls: ['select-concept.component.scss']
})
export class SelectConceptComponent implements OnInit {
    catalogs = [];
    objects = [];
    concepts = [];
    defaultCatalog: any;
    catalog_id: number;
    semantic_id: number;
    concept_id: number;
    dq_rule_level: string;
    formIsValid = false;
    concept_type="";
    backTo:string; 
    ruleInfo: any = {};
    is_clone = false;
    rule_id;

    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    progressState = new ProjectStatus();
    constructor(public zettaUtils: ZettaUtils, private clContentService: ZsClContentService,
        private router: Router, private activatedRoute: ActivatedRoute, private messageSvc: MessageService) { }

    ngOnInit(): void {
        this.ruleInfo = JSON.parse(sessionStorage.getItem('ruleInfo'));
        this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
        this.progressState.currentStateIndex = 0;
        this.progressState.type = 'create-bussiness-rules';
        this.progressState.type = 'classifySet';
        this.activatedRoute.queryParamMap.subscribe(params => {
            this.catalog_id = +params.get('catalog_id');
            this.semantic_id = +params.get('object_id');
            this.concept_id = +params.get('concept_id');
            this.dq_rule_level = params.get('dqRuleLevel');
            this.backTo = params.get('BackTo');
            const is_clone = params.get('is_clone');
            if (is_clone && is_clone === 'true') {
                this.is_clone = true;
            }
            this.rule_id = params.get('rule_id');
            if(params.has('concept_type')){
                this.concept_type = params.get('concept_type');
            }
        });

        if(this.ruleInfo) {
            this.catalog_id = this.ruleInfo.data_quality_rule_attributes[0].catalog_id;
            this.semantic_id = this.ruleInfo.data_quality_rule_attributes[0].entity_id;
            this.concept_id = this.ruleInfo.data_quality_rule_attributes[0].attribute_id;
        }
        
        this.bindCatalog();
    }

    bindCatalog() {
        let data = { user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'] };
        this.clContentService.getCatalogs(data.user_id)
            .subscribe(catalogs => {
                if (catalogs) {
                    this.catalogs = catalogs;
                    if (this.catalog_id) {                        
                       // this.changeCatalogName(this.catalog_id);
                       this.bindObject()
                    }
                }
            }, error => {
            });
    }
    bindObject() {
        this.clContentService.getSemanticsObjects(this.catalog_id).subscribe(objectList => {
            this.objects = objectList;
            if (this.semantic_id) {
               // this.changeObjectName(this.semantic_id)
               this.bindConcept()
               this.validateConcept();
            }
        }, error => { });
    }
    changeCatalogName(selectedCatalog) {
        this.objects = [];
        this.concepts =[];
        this.catalog_id = selectedCatalog;
        this.semantic_id=0;
        this.concept_id=0;
        this.validateConcept();
        this.bindObject()
    }

    bindConcept() {
        this.clContentService.getConcepts(this.catalog_id, this.semantic_id, null, true).subscribe(conceptList => {
            this.concepts = conceptList;
            this.validateConcept();
            if(this.is_clone) {
                this.changeConceptName(this.concept_id);
            }
        }, error => { });
    }

    changeObjectName(object_id) {        
        this.semantic_id = object_id;
        this.concepts = [];
        this.concept_id=0;
        this.validateConcept()
        this.bindConcept();
    }

    changeConceptName(concept_id) {  
        this.concept_id = concept_id > 0 ? concept_id : null;
        const conceptDs = this.concepts.filter(attr => attr.attribute_id == this.concept_id);
        if(conceptDs.length){
         this.concept_type = conceptDs[0].data_type;
        }
        this.validateConcept();
    }
    validateConcept() {
        if (this.catalog_id && this.semantic_id && this.concept_id) {
            this.formIsValid = true;
        }else{
            this.formIsValid = false;
        }
    }
    onNext() {
        if (this.dq_rule_level == 'concept') {
            this.router.navigate(['./zs-cl/rules/create-bussiness-rule/out-of-box-rule'],
            { queryParams: { catalog_id: this.catalog_id, object_id: this.semantic_id, concept_id: this.concept_id,concept_type:this.concept_type },queryParamsHandling:'merge' });
        } else if (this.dq_rule_level == 'catalog') {
            this.router.navigate(['./zs-cl/rules/create-bussiness-rule/out-of-box-rule'],
            { queryParams: { object_id: this.semantic_id, concept_id: this.concept_id,concept_type:this.concept_type },queryParamsHandling:'merge' });
        } else if (this.dq_rule_level == 'object') {
            this.router.navigate(['./zs-cl/rules/create-bussiness-rule/out-of-box-rule'],
            { queryParams: {concept_id: this.concept_id,concept_type:this.concept_type },queryParamsHandling:'merge' });
        } else {
            this.router.navigate(['./zs-cl/rules/create-bussiness-rule/out-of-box-rule'],
            { queryParams: { catalog_id: this.catalog_id, object_id: this.semantic_id, concept_id: this.concept_id, concept_type: this.concept_type, rule_id: this.rule_id, is_clone: this.is_clone }});
        }
        
    }
    onCancel() {
        $('.modal').modal('hide');
        if (this.dq_rule_level == 'concept') {
            if(this.backTo=='catalog'){
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.semantic_id}`],{queryParams:{object_id:this.semantic_id,showBreadcrumb:true}});
            }else{
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.semantic_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
            }            
        } else if (this.dq_rule_level == 'catalog') {
            if(this.backTo=='catalog'){
                this.router.navigate(['/zs-cl/catalogs'], {queryParams:{showBreadcrumb:true}});
            }else{
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
            }
        } else if (this.dq_rule_level == 'object') {
            if (this.backTo == 'catalog') {
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
            } else {
                this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.semantic_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
            }
        } else {
            if(this.is_clone) {
                let rule_ids: any = [];
                rule_ids.push(this.rule_id)
                this.clContentService.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
                sessionStorage.removeItem('ruleInfo');
                sessionStorage.removeItem('ruleInfoClone');
                sessionStorage.removeItem("tRuleDefinition");
            } 
            this.router.navigate(['/zs-cl/dataquality']);
        }
    }
}