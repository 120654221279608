import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AngularGridInstance } from 'angular-slickgrid';
import { AppGlobals } from '../app.globals';
import { DatasetService } from '../../components/datasets/dataset.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/common/components/message/message.service';

declare var $: any;

@Component({
  selector: 'zetta-classifier-records-list',
  templateUrl: './classifier-records-list.component.html',
  styleUrls: ['./classifier-records-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassifierRecordsListComponent implements OnInit, OnChanges {
  rightGridDataSource: any[];
  rightGridColumnsDef = [];
  showRightLoader: boolean = false;
  gridApi : GridApi;
  grid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  agGrid: AgGridAngular;
  loggedInUserDetails;
  pageNo = 1;
  limit = 100;
  rightTableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 100, 'isNormalPagination': false };
  @Input() resultDetails?;
  @Input() name?;
  @Input() dataSetId;
  @Input() recordId;
  @Input() popupPosition;
  @Input() projectId;
  @Output() onModalClose = new EventEmitter<boolean>();
  fsGridOptions: GridOptions = { 
    enableCellTextSelection: true,
    headerHeight: 45, 
    rowHeight: 40,
    floatingFiltersHeight: 49,
    suppressRowClickSelection: true,
    suppressRowTransform: true,
    suppressContextMenu: true,
    getRowStyle: params => {
      if (params.node.rowIndex % 2 === 0) {
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      }
      return { background: AppGlobals.ODD_ROW_BG_COLOR };
    },
  };
  constructor( private datasetSvc: DatasetService,
               private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.getDatasetColumns(this.pageNo);
    this.rightGridColumnsDef = [
      {
        headerName: 'Label',
        field: 'dataset_column',
        sortable: true, 
        editable: false, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
        minWidth: 230,
        autoHeight: true,
        wrapText: true,
        tooltipField : 'dataset_column',
        suppressColumnsToolPanel: true,
        headerComponentParams: { menuIcon: null  }
    },
    {
      headerName: 'Value',
      field: 'dataset_Info',
      sortable: true, 
      editable: false, 
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
      suppressFilterButton: true,
      },
      minWidth: 300,
      autoHeight: true,
      wrapText: true,
      tooltipField : 'dataset_Info',
      suppressColumnsToolPanel: true,
      headerComponentParams: { menuIcon: null  }
  },
    ];
  }

  rightGridCreated(grid) {
    this.rightGrid = grid;
    this.agGrid = grid;
    this.gridApi = grid.api;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.name && changes.name.currentValue) {
      this.name = changes.name.currentValue;
    }
    if(changes && changes.dataSetId && changes.dataSetId.currentValue) {
      this.dataSetId = changes.dataSetId.currentValue;
    }
    if(changes && changes.popupPosition && changes.popupPosition.currentValue) {
      this.popupPosition = changes.popupPosition.currentValue;
    }
    if(changes && changes.recordId && changes.recordId.currentValue){
      this.recordId = changes.recordId.currentValue;
    }
  }

  onCancel() {
    this.onModalClose.emit(false);
  }

  getDatasetColumns(page) {
    this.rightTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataSetId}/records/${this.recordId}?projectId=${this.projectId}&projectType=classify`; 
    let data = {
      tenant_id: this.loggedInUserDetails['tenant_id'],
      user_id: this.loggedInUserDetails['user_id'],
      dataset_id: this.dataSetId,
      record_id:this.recordId,
      project_id:this.projectId,
      pageno: page,
      perpage: this.limit,
    }
    
    this.showRightLoader = true;
    this.datasetSvc.getDatasetRecords(data)
    .subscribe(resp => {
      if(resp && resp.length) {
        console.log('Response',resp);
        let datasetColumns = resp[0] && Object.keys(resp[0]).length ? Object.keys(resp[0]) : [] ;
        let datasetInfo = [] ;
        // this.rightGridDataSource = resp['currentpage'];
        if(datasetColumns.length) {
          console.log('----------------',datasetColumns.length);
          datasetInfo = datasetColumns.map((dcolumn )=>{
            return {
              'dataset_column':dcolumn,
              'dataset_Info' : resp[0][dcolumn]         
              }
          });

        }
       this.rightGridDataSource = datasetInfo ;
        console.log('the dataset colunms are ehrer ', resp);
      }
      this.showRightLoader = false;
    }, err => {
      this.showRightLoader = false;
      if(err && err.status === 500){
      if(err.error.error.toLowerCase() == "The operation is in a pending state".toLowerCase()){
        this.messageSvc.sendMessage({ message: "Databricks Cluster is still activating to get the data. Please try again in a couple of minutes", type: 'INFO', hideboard: true });
      }
      }
    });
  }
}
