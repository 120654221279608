import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { Analytic } from '../../analytic.model';
import { AnalyticService } from '../../analytic.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../zm-project-progress/project-progress.model';
import { Options } from '../../../../../common/zm-components/zm-range-slider/options';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'define-thresholds',
    templateUrl: './define-thresholds.component.html',
    styleUrls: ['./define-thresholds.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DefineThresholdsComponent implements OnInit, OnDestroy {
    isDataReady = false;
    analyticDetail: Analytic = new Analytic();
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    loggedInUserDetails: [];
    errorRibbon = false;

    thresholdsList: any = [];
    thresholdsRows = 0;
    sliderControlLeft: any = [];
    sliderControlRight: any = [];
    // sliderControlRight: FormControl = new FormControl(0);
    optionsLeft: any = [];
    optionsRight: any = [];
  
    // sliderControl1: FormControl = new FormControl(5000);
    // sliderControl2: FormControl = new FormControl(0);

    // options1: Options = {
    //     floor: 0,
    //     ceil: 5000,
    //     showSelectionBar: true,
    //     barDimension: 160,
    //     // translate: (value: number): string => {
    //     //     return value + '%';
    //     // }
    // };

    // options2: Options = {
    //     floor: 0,
    //     ceil: 5000,
    //     showSelectionBarEnd: true,
    //     barDimension: 160,
    //     // translate: (value: number): string => {
    //     //     return value + '%';
    //     // }
    // };
    
    // value: number = 100;
    // options: Options = {
    //     floor: 0,
    //     ceil: 200
    // };


    constructor(
        public zettaUtils: ZmZettaUtils,
        public analyticSvc: AnalyticService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }
        

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
        }

        this.activatedRoute.parent.params.subscribe(params => {
            this.analyticDetail.analyticid = params['id'];
        });

        this.progressState.states = this.zettaUtils.getStateList('create-analytic');
        this.progressState.currentStateIndex = 3;
        this.progressState.currentStateInfo = null; 
        this.progressState.type = 'Analitycs';
        this.progressState.project = this.analyticDetail;
        this.progressState.project.name = "Mortgage Default Prediction Model Analytics";            //example
        this.progressState.project.img = "icon-analytics-correlation.png";            //example
        this.isDataReady = true;

        this.getThresholdsList();
    }

    getThresholdsList() {
        this.analyticSvc.getAnalyticDataSets(this.loggedInUserDetails['userid'], '1', this.loggedInUserDetails['groupid']).subscribe(responseList => {
                this.thresholdsList = responseList[1]['currentpage'][0]['datasets'][2]['attributes'];
                this.thresholdsList = this.thresholdsList.filter(attribute1 => attribute1.setdata === 'output');
                this.thresholdsRows = this.thresholdsList.length;

                for(let i=0 ; i < this.thresholdsRows; i++){
                    this.sliderControlLeft[i] = new FormControl(5000);
                    this.sliderControlRight[i] = new FormControl(0);
                    this.optionsLeft[i] = {
                        floor: 0,
                        ceil: 5000,
                        showSelectionBar: true,
                        barDimension: 160,
                    };
                    this.optionsRight[i] = {
                        floor: 0,
                        ceil: 5000,
                        showSelectionBarEnd: true,
                        barDimension: 160,
                    };
                }

                this.thresholdsList.forEach(index => {
                    
                });

            }, err => {
                // alert('error');
            });
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zm/analytics']);
        } else {
            // this.router.navigate(['/zm/analytics', this.analyticDetail.analyticid]);
            this.router.navigate(['/zm/analytics/1']);
        }
    }

    onPrevious() {
        // this.router.navigate(['/zm/analytics/create-analytic'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
        this.router.navigate(['/zm/analytics/1/define-outputs'], {queryParams: {'id': '1', showBreadcrumb: this.showBreadcrumb}});

    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
    }

    addUserRole(boolean: boolean) {
        // const selectedProjectAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
        // const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
        // const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
        // if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
        //     selectedProjectReviewerList.length === 0) {
        //     this.errorRibbon = true;
        // } else {
        //     this.errorRibbon = false;
        //     this.saveUserRole(boolean);
        // }

        // this.router.navigate(['/zm/analytics/15/define-inputs'], {queryParams: {'id': this.analyticDetail.analyticid, showBreadcrumb: this.showBreadcrumb}});
        this.router.navigate(['/zm/analytics/1/define-inputs'], {queryParams: {'id': '1', showBreadcrumb: this.showBreadcrumb}});

    }
}
