import { Component, OnInit } from '@angular/core';
import { UserProfileService } from './user-profile.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AppGlobals } from '../../shared/app.globals';
import { MsalService} from '@azure/msal-angular';
@Component({
  selector: 'zetta-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  showNewPassword: Boolean = false;
  showCnfPassword: Boolean = false;
  newpasswordfocus: Boolean = false;
  cnfpasswordfocus: Boolean = false;
  userInfoForm: FormGroup;
  userAppsForm: FormGroup;
  userPasswordForm: FormGroup;
  failStatus: string;
  passwordChangeStatus: string;
  profilePic;
  profilePicType:string;
  profilePicSize:string;
  public userinfo:any;
  defaultAppId:number;
  userApps:any;
  isAppChanged=false;
  profilePicMsg:string;
  profileUpdateMag:string;
  changesPwdFailed:string;
  userLoggedInFromAzure=false;
  constructor(private _userprofileSvc: UserProfileService,private authService: MsalService,
    private _formBuilder: FormBuilder) { }

  get upf() {
    return this.userPasswordForm.controls;
  }

   formData: any = {};
  ngOnInit() {
    this.userLoggedInFromAzure = !!this.authService.getAccount();
    this.setForms();
    this._userprofileSvc.getUserInfo().subscribe((data) => { 
      this.userInfoForm.patchValue({
        user_name: data.user_name,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        preference:{
            default_app_id:this.defaultAppId
        }
      });
      this.getUserProfilePic();
    });
  }

  showNewPasswordHandler(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  showCnfPasswordHandler(): void {
    this.showCnfPassword = !this.showCnfPassword;
  }

  setForms(): void {
    this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.userApps=this.userinfo.apps;
    this.defaultAppId=this.userinfo.preference.default_app_id;
    this.userInfoForm = this._formBuilder.group({
      user_name: [{value: '', disabled: true}],
      first_name: [{value: '', disabled: this.userLoggedInFromAzure?true:false}],
      last_name: [{value: '', disabled: this.userLoggedInFromAzure?true:false}],
      email: [{value: '', disabled: this.userLoggedInFromAzure?true:false}],
      profile_pic:[''],
      apps:new FormArray([]),
      preference :this._formBuilder.group({
        default_app_id:[]
      })
    });
    this.userPasswordForm = this._formBuilder.group({
      cpassword: [{value: '', disabled: this.userLoggedInFromAzure?true:false}, [Validators.required]],
      npassword: [{value: '', disabled: this.userLoggedInFromAzure?true:false}, [Validators.required]],
      cnfnpassword: [{value: '', disabled: this.userLoggedInFromAzure?true:false}, [Validators.required]]
    });
    
     // creating the daynamic control for user apps   
    this.userApps.forEach(() => this.userAppsFormArray.push(new FormControl(false)));
  }
  
  get userAppsFormArray() {
    return this.userInfoForm.controls.apps as FormArray;
  }

  onSubmitUserInfo(): void {
    const data = {
      first_name: this.userInfoForm.controls.first_name.value,
      last_name:this.userInfoForm.controls.last_name.value,
      email:this.userInfoForm.controls.email.value,     
      preference: {
        default_app_id: this.userInfoForm.get('preference.default_app_id').value,
      }
    }
    this._userprofileSvc.updateUserInfo(data).subscribe((response) => {
      this.profilePicMsg='';
      this.passwordChangeStatus='';
      this.profileUpdateMag= AppGlobals.PROFILE_UPDATE_MSG;
    },errorResponse=>{
      this.failStatus = errorResponse.error.message;
    });
  }
  // Uploading the user profile in server through api
  uploadUserProfile(event:any){     
    let files = event.target.files;
    if (!this.validateProfileType(files[0].name)) {
      return false;
    }
    this.validateProfileSize(files[0].size)
    const fd = new FormData();
    fd.append('profile_pic', files[0], files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.profilePic = e.target.result;
    }
    this._userprofileSvc.updateUserProfile(fd).subscribe((response) => {
      this.profileUpdateMag='';
      this.passwordChangeStatus='';
      this.profilePicMsg = AppGlobals.PROFILE_PIC_MSG;
    }, errorResponse => {
      this.profilePicType=errorResponse
    })
  }

  // Validating the user profile picture type it should be only png, jpg pr jpeg.
  validateProfileType(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase()=='jpeg') {
      this.profilePicType='';
        return true;
    }
    else {
      this.profilePicType =AppGlobals.PROFILE_PICTYPE;
      this.profilePicMsg='';
      this.profilePicSize='';
        return false;
    }
  }

  // Validating the user profile picture size that should not be more than 5 MB
  validateProfileSize(profileSize:number){
    if(profileSize>5e+6){
      this.profilePicMsg='';
      this.profilePicType='';
      this.profilePicSize=AppGlobals.PROFILE_SIZE;
    }else{
      this.profilePicSize='';
    }
  }

  // Getting the user profile picture
  public getUserProfilePic() {
		this._userprofileSvc.getUserPic().subscribe(image => {
      let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.profilePic = reader.result;
			}, false);
			if (image && image.size && image.type=="image/gif") {
				this.profilePic = reader.readAsDataURL(image);
			}else{
				this.profilePic=null;
			}
      console.log(this.profilePic)
		}, err => {
      this.profilePic = err.url;
		});
	}

  // Making the radio button should be selected by defalt
  isDefaultAppSelected(appId: number) {    
    const selectedApp = this.userinfo.preference.default_app_id;
    if(appId===parseInt(selectedApp)){    
      return true;
    }
    return false;
  }

  // setting the default app id when user change the radio button selection
  onUserAppChange(e){
    if (e.target.checked) {
      this.userInfoForm.patchValue({
        preference:{
          default_app_id:e.target.value
          }
      });
    }
  }

  onSubmitUserPassword(): void {
    const pwds = this.userPasswordForm.getRawValue();
    this._userprofileSvc.changePassword({
      new_password: pwds.npassword,
    }).subscribe((response) => {
      this.profileUpdateMag='';
      this.profilePicMsg = '';
      this.passwordChangeStatus =response.message;
      this.userPasswordForm.reset();
    },
    errorResponse => {
         this.userPasswordForm.reset();
         if (errorResponse && errorResponse.error.message) {
           this.changesPwdFailed = errorResponse.error.message;
         }
       });
     }

     currentPasswordFocus(): void {
       this.changesPwdFailed = '';
       this.passwordChangeStatus='';
     }

     newPasswordFocus(): void {
       this.newpasswordfocus = !this.newpasswordfocus;
       this.changesPwdFailed = '';
       this.passwordChangeStatus='';
     }

     cnfPasswordFocus(): void {
       this.cnfpasswordfocus = !this.cnfpasswordfocus;
       this.changesPwdFailed = '';
       this.passwordChangeStatus='';
     }
   }
