import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../../../common/services/base-service';
@Component({
  selector: 'zetta-technical-column-level-rules',
  templateUrl: './technical-column-level-rules.component.html',
  styleUrls: ['./technical-column-level-rules.component.scss'],
})
export class TechnicalColumnLevelRulesComponent implements OnInit {

  constructor(private baseService: BaseService) { }

  ngOnInit() {
        
  }
 
}
