import { Inject, Injectable, NgZone, Compiler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppGlobals } from '../shared/app.globals';
import { MessageService } from '../components/message/message.service';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { AuthTokenInterceptor } from '../services/AuthTokenInterceptor';

export function authProviderFactory(authenticationService: AuthenticationService,
     router: Router,
     zone: NgZone,
     _compiler: Compiler,
     messageSvc: MessageService, 
     msalService: MsalService, 
     msalBroadcastService: BroadcastService) {
    if(sessionStorage.getItem('loginProvider') === "azure_sos" || !!msalService.getAccount()){
        return new MsalInterceptor(msalService, msalBroadcastService);
    } else {
        return new AuthTokenInterceptor(authenticationService, router, zone, _compiler, messageSvc);
    }
};