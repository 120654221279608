import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-project-name-formatter',
  template: `
  <ng-container >
  <div class="project-container text-truncate" [ngClass]="getCssClasses()" style="line-height: 1" [title]="params.value">
    <div>
      <i class="{{ getProjectEntityClassById() }}" style="color:#0d71a1"></i>
    </div>

    <div class="project-details text-truncate">
      <span class="text-truncate">{{ value }}</span><br>
      <span class="description text-truncate">{{ params.data.description }}</span>
    </div>
  </div>
</ng-container>
 
`,
styles: [
  `
    .agGrid .ag-theme-balham .ag-cell .hyper-link {
      display: inline;
    }

    .agGrid .ag-theme-balham .ag-cell .hyper-link:hover {
      text-decoration: underline;
      color: #0e709f;
    }

    .project-container {
      display: flex;
      align-items: center;
    }

    .project-details {
      margin: 10px;
    }

    .blueLink {
      color: #0e709f;
    }

    .description {
      
      color: black;
    }
  `
],

})
export class ProjectNameDescriptionFormatterComponent implements ICellRendererAngularComp {
    active = false;
    value: any;
    params: any;
    zettaUtilsInstance = new ZettaUtils();
    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.value = params.value;  
    }
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
    ngOnInit(): void {
      
    }
    getCssClasses(): string {
      return  'blueColor pointer modal-feedback text-truncate'
    }
    getProjectEntityClassById()
    {
      return this.zettaUtilsInstance. getProjectEntityClassById(this.value);
    }
   
    
    getProjectDesc() {
        const hasDescription = (this.params && this.params['isDisplayDesc'] !== undefined) ? this.params.isDisplayDesc : true;
        const applyWidth = (this.params && this.params['applyWidth'] !== undefined) ? this.params.applyWidth : false;
        // if (applyWidth) {
        //     cssClass = 'wd-8rem';
        // }
        return hasDescription ? this.params.description : 'fg';
    }
  }
