import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-zm-content',
  templateUrl: './zm-content.component.html',
  styleUrls: ['./zm-content.component.scss']
})
export class ZmContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
