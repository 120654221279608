import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ProjectService } from '../project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { MessageService } from 'src/app/common/components/message/message.service';

@Component({
  selector: 'zetta-production-project-config',
  templateUrl: './production-project-config.component.html',
  styleUrls: ['./production-project-config.component.scss']
})
export class ProductionProjectConfigComponent implements OnInit {
  entitiesMasteredChartColors = ['#4bb100', '#E4E7F4',];
  openTaskDataSource;
  projectDetail: object = {};
  loggedInUserDetails;
  projectId;
  asOfDate: any = '';
  entityId;
  entity_name;
  configDetails;
  openTaskChartOptions: object = {
    'resizeRequired': true,
    'category': 'category',
    'variable': 'count',
    'labelPercentValue': 0,
    'labelPercentLabel': 'Data Quality',
    'labelPercentLabel2': 'Measure',
    'isMoneyFormat': true,
  };
  @Input() projectData: any;
  constructor(public activeRoute: ActivatedRoute, private projectService: ProjectService, private router: Router, private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo');
    this.activeRoute.parent.params.subscribe(params => {
      this.projectId = params['id']; 
    });
    this.activeRoute.queryParams.subscribe(query => {
      this.entityId = query.entity_id;
      this.entity_name = query.entity_name;
    });
    this.getProjConfigSettings();
}

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.projectData && changes.projectData.currentValue && changes.projectData.currentValue) {
      this.projectDetail = changes.projectData.currentValue;
      this.onProjectDataReceive(changes.projectData.currentValue);
    }
  }

  onProjectDataReceive(eve) {
    if(this.loggedInUserDetails && this.projectDetail) {
      this.projectDetail = eve;
      this.projectService.getProjectDqScroce(this.loggedInUserDetails['user_id'], this.projectId)
      .subscribe(res => {
        if(res) {
          this.asOfDate = new Date(res[1].count).getMonth() +1  +'/' + new Date(res[1].count).getDate() +'/'+new Date(res[1].count).getFullYear();
          this.openTaskDataSource = this.getDonutParseData(res);
        }
      });
    }
  }

  getProjConfigSettings() {
    this.projectService.getProjectConfigSettings(this.loggedInUserDetails['user_id'], this.projectId)
    .subscribe(resp => {
      this.configDetails = resp;
    }, err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
    });
  }

  getDonutParseData(data) {
    const dqData = [];
    data.forEach((element) => {
      if (element.name === AppGlobals.DQ_SCORE_LABEL) {
        if (element.count > 0) {
          let percentVal = Number(element.count * 100).toFixed(2);
          percentVal = percentVal == '100.00' ? '100' : percentVal;
          if(Number(percentVal) >= 75) {
            this.entitiesMasteredChartColors = ['#4bb100', '#E4E7F4'];
          } else if ( Number(percentVal) >= 50 && Number(percentVal) < 75) {
            this.entitiesMasteredChartColors = ['#eeb049', '#E4E7F4'];
          } else {
            this.entitiesMasteredChartColors = ['#d35162', '#E4E7F4'];
          }
          this.openTaskChartOptions["labelPercentValue"] = percentVal;
          dqData.push({
            count: percentVal,
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
          dqData.push({
            count: 100 - Number(percentVal),
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
        } else {
          let percentVal = 0
          this.openTaskChartOptions["labelPercentValue"] = element.count;
          dqData.push({
            count: 0,
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
          dqData.push({
            count: 100 - Number(percentVal),
            display_name: element.display_name,
            name: element.name,
            category: element.total_records
          });
        }
      }
    });
    return dqData;
  }
  
    navigateToGoldenRecords() {
    this.router.navigate(['/zs/projects/' + this.projectId + '/production-stage-details/golden-records'], { queryParams: { entity_id: this.entityId, entity_name: this.entity_name, showRecent: false } });
  }

}
