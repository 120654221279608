import { User } from '../../../../../common/shared/zetta-models';
import { ObservableService } from '../../../../../common/services/observable.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ProjectService } from '../../project.service';
import { Project } from '../../project.model';
import { AppGlobals } from 'src/app/common/shared/app.globals';

@Component({
  selector: 'zetta-pd-users',
  templateUrl: './pd-users.component.html',
  styleUrls: ['./pd-users.component.scss']
})
export class PdUsersComponent implements OnInit {

  projectDetail = new Project();
  totalUsers = 0;
  activeUsers: User[] = [];
  private _project_entitlements = {};
  ever_executed = false;
  isProductionStage: boolean = false;
  @Input() hideEditIcon?: boolean = false;
  @Output() projectDataReceived = new EventEmitter<any>();

  constructor(public zettaUtility: ZettaUtils,
    private _projectService: ProjectService,
    public observeSvc: ObservableService,
    private activeRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activeRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });
    if(this.router.url.includes('production-stage-details')) {
    this.isProductionStage = true;
    } else {
    this.isProductionStage = false;
    }

    this.observeSvc.getCurrentEntitlements.subscribe(data => { 
this._project_entitlements = data ? data : {}; });

    this.getProject();
  }

  getProject() {
    this._projectService.getProject(this._projectService.getObjectFromSession('userInfo').user_id,
      this.projectDetail.project_id).subscribe(responseList => {
        this.projectDetail = responseList;
        this.ever_executed = responseList.ever_executed;
        if (this.projectDetail.users) {
          this.totalUsers = this.projectDetail.users.length;
          this.activeUsers = this.projectDetail.active_users.splice(0, 6);
        }
        this.projectDataReceived.emit(responseList);
      }, err => {
        this.projectDetail.created_ts = new Date();
        this.projectDetail.users = [];
      });

  }

  isHavingAccess() {
    if (this.zettaUtility.isProcessRunning && !this.zettaUtility.isFileNotFound) {
      return true;
    } else {
      if(this.hideEditIcon) {
        let loggedInUser =   (this.projectDetail && this.projectDetail.users && this.projectDetail.users.length) ? this.projectDetail.users.find(user => user.user_id == this._projectService.getObjectFromSession('userInfo').user_id) : null;
        if(loggedInUser) {
         let isAdmin = loggedInUser.roles.find(role => role === AppGlobals.RESOLVE_PROJECT_ADMIN);
         return isAdmin == 1 ? false: true;
        } else {
          return true;
        }
      } else {
        return (this._project_entitlements['canUpdate'] && this._project_entitlements['canView']) ? false : true;
      }
    }
  }


  getProfileImageClass(index: number, total: number) {
    if (total === 1) {
      return 'col-md-12 text-center';
    } else if ([0, 1, 2, 3].indexOf(index) !== -1) {
      return 'col-md-6 mb-2';
    } else if ([2, 4].indexOf(index) !== -1 && [3, 5].indexOf(total) !== -1) {
      return 'col-md-12';
    } else if ([4, 5].indexOf(index) !== -1) {
      return 'col-md-6';
    }
  }
}
