import { Component, OnInit } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { dateFormatter } from '../../common/shared/formatters/dateFormatter'
import { ContentService } from '../../zettasense/content/content.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../common/components/message/message.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';

const activityParameterFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {	
  let noData = false
  if (!dataContext.param.object_id) {
    noData = true
  } 
  return noData ? 
  `<div class="text-wrap text-truncate wd-16">
    <span data-title='${dataContext.param.object_id}' class="text-truncate"></span>
  </div>`
  : `<div class="text-wrap text-truncate wd-16">
      <span data-title='${dataContext.param.object_id}' class="text-truncate">${dataContext.param.object_id}</span>
    </div>`;
};
@Component({
  selector: 'zetta-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent implements OnInit {

  public loggedInUserDetails;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
  public logsGrid: AngularGridInstance;
  historyTableColDef: object = [];
  gridOptions: object = {};

  isLoading = true;
  hasScrolled = false;
  noData = false;
  activityLogsForm: FormGroup;
  datePickerConfig: Partial<BsDatepickerConfig>;
  activityHistory: any = [];
  totalCount = 0;
  page = 1;
  limit = 100;
  userList = [];
  activityTypes:any = [];
  user_id;
  ex_date: any;
  ex_date2: any;

  constructor(public contentSvc: ContentService,
    public zettaUtils: ZettaUtils,
    private datePipe: DatePipe,
    private messageSvc: MessageService,
    private ZsClContentService: ZsClContentService) { }

  ngOnInit() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.loggedInUserDetails = this.contentSvc.getObjectFromSession('userInfo');
    this.user_id = this.loggedInUserDetails.user_id;
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
    this.historyTableColDef = [{
      'displayname': 'User ID',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'User First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    },
    {
      'displayname': 'User Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
    }, {
      'displayname': 'E-mail Address',
      'physicalname': 'email',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 150,
    }, {
      'displayname': 'Activity',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 150,
    }, {
      'displayname': 'Parameter',
      'physicalname': 'param',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 200,
      'formatter': activityParameterFormatter,

    }, {
      'displayname': 'Date Time',
      'physicalname': 'created_ts',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': dateFormatter
    }];

    this.getActivityHistoryData(this.page, this.limit);
    this.setForm();
    this.getUserList();
    this.getActivityList();
  }

  setForm() {
    this.activityLogsForm = new FormGroup({
      ex_date: new FormControl(),
      ex_date2: new FormControl(),
      userName: new FormControl(),
      activityType: new FormControl(),
    });
  }

  onGridCreation(grid) {
    this.logsGrid = grid;
  }

  getActivityHistoryData(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/activities?pageno=${page}&pagesize=${limit}`;   

    let serverFilter="";
    if(sessionStorage.getItem("serverfilter")){
       serverFilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    this.contentSvc.getActivityHistory(this.user_id, page, limit, serverFilter, sortfilter).subscribe((data) => {
      this.totalCount = data.totalrecords
      this.activityHistory = data['currentpage'];
      if (this.activityHistory.length === 0) {
        this.noData = true;
      }
      this.isLoading = false;
      let concatedRows = this.activityHistory;
      if (this.logsGrid) {
        concatedRows = [...this.logsGrid.dataView.getItems(), ...this.activityHistory];
        this.activityHistory.forEach(element => {
          element.id = Math.random();
        });
        this.activityHistory = concatedRows;
        this.logsGrid.dataView.setItems(this.activityHistory);
        this.logsGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.messageSvc.sendMessage({ message: 'View Audit logs has failed', type: 'INFO', hideboard: true });
    });
  }

  getUserList() {
    this.contentSvc.getUserList().subscribe((data) => {
      this.userList = data['currentpage']
    })
  }

  getActivityList() {
    this.contentSvc.getActivityList(this.user_id).subscribe((data) => {
      this.activityTypes = data
    })
  }

  onNext(): void {
    this.page++;
    this.getActivityHistoryData(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onExport() {
    let start_date = this.activityLogsForm.controls.ex_date.value
    start_date = this.datePipe.transform(start_date, 'yyyy-MM-dd')
    let end_date = this.activityLogsForm.controls.ex_date2.value
    end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd')
    let userId = this.activityLogsForm.controls.userName.value    
    let activity = this.activityLogsForm.controls.activityType.value       
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails.user_id}/activities/export?start_date=${start_date}&end_date=${end_date}&format=csv&user_id=${userId}&activity_id=${activity}`,'_self');
    this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails.user_id}/activities/export?start_date=${start_date}&end_date=${end_date}&format=csv&user_id=${userId}&activity_id=${activity}`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `Activity_Report`);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
    this.activityLogsForm.reset();
  }

}
