import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
declare var $: any;


const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="datasource-name-formatter text-wrap mr-left-10">
                <span title='${value}' class="pointer hyper-link">${value}</span><br>
            </div>`;
};

const datasetNameRadioFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="datasource-name-formatter text-wrap d-flex">
  <span class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </span>
                <span title='${value}' class="pointer hyper-link">${value}</span><br>
            </div>`;
};

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
};
const mappingColumnmDownvoteFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
  let score100 = Math.round(dataContext.score * 100);
  let txt = '';

  if (level === 'HIGH') { txt = txt + `<div class="box-container text-center text-uppercase" title='${level}'>${level}</div>`; }
  if (level === 'MEDIUM') { txt = txt + `<div class="box-container boxM text-center text-uppercase" title='${level}'>${level}</div>`; }
  if (level === 'LOW') { txt = txt + `<div class="box-container boxL text-center text-uppercase" title='${level}'>${level}</div>`; }

  txt = txt + `<div class="porcent text-center" title='${score100}%'>${score100}%</div>`;
  return txt;
};

@Component({
  selector: "zetta-downvote-modal",
  templateUrl: "./downvote-modal.component.html",
  styleUrls: ["./downvote-modal.component.scss"],
})
export class DownvoteModalComponent implements OnInit {
  public modalGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  tableSettings: object = { height: "100%", width: "100%" };
  modeltableSettings: object = { height: "100%", width: "99.50%" };
  modalGridOptions: any;
  modelColumnDef = [];
  noData = false;
  ds_classification: any = [];
  is_downvote_loading = true;
  old_row = 0;
  ddl_catalogs: any = [];
  ddl_semantics: any = [];
  ddl_concepts: any = [];
  ddl_catalogs_id;
  ddl_concept_id;
  ddl_object_id;
  selectedTag = "";
  selectedTagOwn = "";
  public gridClassification: AngularGridInstance;
  classificationTableColDef: any;
  gridDataOptions: any;
  showModal: boolean;
  isTagSelected = false;
  page = 1;
  limit = 100;
  isCustomSearchAbleFormatter: boolean = false;
  @Input() dsName: any;
  @Input() dataSetId: any;
  @Input() count;
  @Input() dataset_column_id;
  @Input() catalog_id;
  @Input() object_id;
  @Input() mapping_type;
  @Input() ds_column_name;
  @Input() attribute_id;
  @Input() showConcept: boolean;
  @Input() showBackdrop: boolean;
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
  @Input() issynonym: boolean = false;
  @Input() isConceptLevel: boolean = false;
  @Input() attribute_id_left;
  @Output() selectedSynonymTag: EventEmitter<any> = new EventEmitter();
  @Input() concept_name;
  @Input() catalog_name;
  @Input() showSynonym: boolean;
  @Input() entity_id_left;
  @Input() entity_name_right;
  constructor(private catalogSvc: ZsClContentService) {}

  ngOnInit(): void {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    this.isCustomSearchAbleFormatter = !this.showConcept && !this.showSynonym;
    this.modelColumnDef = [
      {
        displayname: "Catalog Name",
        physicalname: "catalog_name",
        sortable: true,
        datatype: "String",
        filterable: true,
        cssClass: "blueLink",
        formatter: datasetNameFormattter,
        maxWidth: 350,
      },
      {
        displayname: "Semantic Object",
        physicalname: "semantic_object_name",
        sortable: true,
        datatype: "String",
        filterable: true,
        cssClass: "blueLink",
        formatter: datasetNameRadioFormattter,
        maxWidth: 250,
      },
      {
        displayname: "Mapping Confidence Level",
        physicalname: "score",
        sortable: true,
        datatype: "String",
        filterable: true,
        formatter: mappingColumnmDownvoteFormatter,
        minWidth: 200,
        maxWidth: 390,
      },
    ];

    if (this.showConcept) {
      this.modelColumnDef = [
        {
          displayname: "Catalog Name",
          physicalname: "catalog_name",
          sortable: true,
          datatype: "String",
          filterable: true,
          cssClass: "blueLink",
          formatter: datasetNameFormattter,
          maxWidth: 350,
        },
        {
          displayname: "Semantic Object",
          physicalname: "semantic_object_name",
          sortable: true,
          datatype: "String",
          filterable: true,
          cssClass: "blueLink",
          formatter: datasetNameFormattter,
          maxWidth: 250,
        },
        {
          displayname: "Concept",
          physicalname: "logical_name",
          sortable: true,
          datatype: "String",
          filterable: true,
          cssClass: "blueLink",
          formatter: datasetNameRadioFormattter,
          minWidth: 70,
          maxWidth: 250,
        },
        {
          displayname: "Mapping Confidence Level",
          physicalname: "score",
          sortable: true,
          datatype: "String",
          filterable: true,
          formatter: mappingColumnmDownvoteFormatter,
          minWidth: 200,
          maxWidth: 390,
        },
      ];
    }

    if (this.showSynonym) {
      this.modelColumnDef = [
        {
          displayname: "Synonym Catalog",
          physicalname: "catalog_name_right",
          sortable: true,
          datatype: "String",
          filterable: true,
          formatter: datasetNameRadioFormattter,
          maxWidth: 350,
        },
        {
          displayname: "Synonym Object",
          physicalname: "entity_name_right",
          sortable: true,
          datatype: "String",
          filterable: true,
          maxWidth: 250,
        },
        {
          displayname: "Synonym Concept",
          physicalname: "attribute_logical_name_right",
          sortable: true,
          datatype: "String",
          filterable: true,
          maxWidth: 250,
        },
        {
          displayname: "Synonym Confidence",
          physicalname: "score",
          sortable: true,
          datatype: "String",
          filterable: true,
          formatter: mappingColumnmDownvoteFormatter,
          minWidth: 200,
          maxWidth: 390,
        },
      ];
    }

    if (this.showConcept) {
      this.getClassificationTags();
    } else if (this.showSynonym && !this.isConceptLevel) {
      this.getSemanticSynonymTag();
    } else if (this.showSynonym && this.isConceptLevel) {
      this.getConceptSynonymTag();
    } else {
      this.getClassificationObjectTags();
    }
    this.getAllCatalogs();

    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };
  }

  onClassiCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.modalGrid.gridService.getDataItemByRowIndex(args.row);
    $("#data-" + this.old_row).prop("checked", false);
    this.selectedTag = row.attribute_id;
    if (eventEle.target.className.includes("radio-formatter")) {
      this.catalog_id = row.catalog_id;
      if (this.showConcept) {
        this.object_id = row.entity_id;
      }
      $("#rbtCatalog").prop("checked", false);
      $("#tagKeep").prop("checked", false);
      $("#tagNotSure").prop("checked", false);
      $("#rbtConcept").prop("checked", false);
      $("#rbtObject").prop("checked", false);
      this.old_row = args.row;
      $("#data-" + args.row).prop("checked", true);
      if (!this.showConcept) {
        this.selectedTag = row.entity_id;
      }
      if (this.showSynonym) {
        this.selectedTag = row.attribute_id_right;
      }
      this.isTagSelected = true;
    }
  }
  getAllCatalogs() {
    this.catalogSvc
      .getCatalogs(this.loggedInUserDetails["user_id"])
      .subscribe((resp) => {
        this.ddl_catalogs = resp;
      });
  }
  getAllSemanticObjects(catalogId) {
    this.catalogSvc.getSemanticsObjects(catalogId).subscribe((resp) => {
      this.ddl_semantics = resp;
    });
  }
  getSelectedConcept(objectId) {
    this.catalogSvc
      .getConcepts(this.ddl_catalogs_id, objectId,null,false)
      .subscribe((resp) => {
        this.ddl_concepts = resp;
      });
  }
  onChangeNotSure() {
    $("#data-" + this.old_row).prop("checked", false);
    $("#rbtConcept").prop("checked", false);
    this.selectedTag = "not_sure";
    this.isTagSelected = true;
  }
  onChangeRbtConcept() {
    $("#data-" + this.old_row).prop("checked", false);
    $("#rbtConcept").prop("checked", true);
    if (this.ddl_concept_id) {
      this.selectedTag = this.ddl_concept_id;
      this.isTagSelected = true;
    } else {
      this.isTagSelected = false;
    }
  }
  onChangeRbtObject() {
    $("#data-" + this.old_row).prop("checked", false);
    $("#rbtObject").prop("checked", true);
    if (this.ddl_object_id) {
      this.selectedTag = this.ddl_object_id;
      this.isTagSelected = true;
    } else {
      this.isTagSelected = false;
    }
  }
  onChangesKeepTag() {
    $("#data-" + this.old_row).prop("checked", false);
    this.selectedTag = undefined;
  }
  onChangeCatalog(event) {
    if (event.target.value !== "Pick a Catalog") {
      this.ddl_catalogs_id = event.target.value;
      this.catalog_id = event.target.value;
      $("#rbtCatalog").prop("checked", true);
      this.getAllSemanticObjects(this.ddl_catalogs_id);
    } else {
      this.ddl_catalogs_id = null;
      this.catalog_id = null;
      this.object_id = null;
      this.ddl_concept_id = null;
      this.ddl_semantics = [];
      this.ddl_concepts = [];
      this.isTagSelected = false;
    }
  }
  onChangesObject(event) {
    if (event.target.value !== "Pick a Semantic Object") {
      this.ddl_object_id = event.target.value;
      if (this.showConcept) {
        this.object_id = event.target.value;
      }
      if (this.showConcept || this.showSynonym) {
        this.getSelectedConcept(this.ddl_object_id);
      }
      if (!this.showConcept && !this.showSynonym) {
        this.isTagSelected = true;
        this.selectedTag = this.ddl_object_id;
      }
    } else {
      this.object_id = null;
      this.ddl_concept_id = null;
      this.ddl_concepts = [];
      this.isTagSelected = false;
    }
  }
  onChangesConcept(event) {
    if (event.target.value !== "Pick a Concept") {
      this.ddl_concept_id = event.target.value;
      if ($("#rbtConcept").prop("checked")) {
        this.selectedTag = this.ddl_concept_id;
      }
      if (this.showSynonym) {
        this.selectedTag = this.ddl_concept_id;
      }
      this.isTagSelected = true;
    } else {
      this.ddl_concept_id = null;
      this.isTagSelected = false;
    }
  }
  getClassificationObjectTags() {
    this.catalogSvc
      .getClassificationOjectTags({
        user_id: this.loggedInUserDetails["user_id"],
        tenant_id: this.loggedInUserDetails["tenant_id"],
        dataset_id: this.dataSetId,
      })
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            data.forEach((element) => {
              element.semantic_object_name = element.tags;
            });
            ($("#classification_tag_model") as any).modal("show");
            this.bindGrid(data);
          }
        },
        (err) => {
          this.is_downvote_loading = false;
          this.ds_classification = [];
          ($("#classification_tag_model") as any).modal("show");
        }
      );
  }
  bindGrid(data) {
    this.ds_classification = data;
    if (this.ds_classification.length === 0) {
      this.noData = true;
    }
    this.ds_classification.forEach((element) => {
      element.id = Math.random();
      element.level =
        element.score * 100 >= 75
          ? "HIGH"
          : element.score * 100 >= 50
          ? "MEDIUM"
          : "LOW";
      element.score100 = Math.round(element.score * 100);
      element.checked = element.level === "HIGH" ? true : false;
    });
    this.ds_classification.sort((a, b) => b.score100 - a.score100);
    this.is_downvote_loading = false;
    ($("#classification_tag_model") as any).modal("show");
    if (this.gridClassification) {
      this.gridClassification.dataView.setItems(this.ds_classification);
      this.gridClassification.gridService.setSelectedRows([]);
    }
  }
  getClassificationTags() {
    this.catalogSvc
      .getClassificationTags({
        user_id: this.loggedInUserDetails["user_id"],
        tenant_id: this.loggedInUserDetails["tenant_id"],
        dataset_id: this.dataSetId,
        column_id: this.dataset_column_id,
      })
      .subscribe(
        (data) => {
          if (data) {
            this.bindGrid(data);
          }
        },
        (err) => {
          this.is_downvote_loading = false;
          this.ds_classification = [];
          ($("#classification_tag_model") as any).modal("show");
        }
      );
  }

  saveClassificationTags() {
    if (this.showConcept) {
      if (this.selectedTag !== "" && this.selectedTag !== undefined) {
        if (this.selectedTag === "not_sure") {
          const payload: any = {
            dataset_id: +this.dataSetId,
            dataset_column_id: +this.dataset_column_id,
            removing_attribute_id: +this.attribute_id,
            mapping_type: "not_sure",
            entity_id: +this.object_id,
            catalog_id: +this.catalog_id,
          };
          this.downVote(payload);
        } else {
          this.selectedTag = this.selectedTag + "";
          this.selectedTag = this.selectedTag.split("_")[0];
          const payload: any = {
            dataset_id: +this.dataSetId,
            dataset_column_id: +this.dataset_column_id,
            removing_attribute_id: +this.attribute_id,
            attribute_id: +this.selectedTag,
            mapping_type: this.mapping_type,
            entity_id: +this.object_id,
            catalog_id: +this.catalog_id,
          };
          this.downVote(payload);
        }
      }
    } else if (this.showSynonym) {
      this.selectedSynonymTag.emit(this.selectedTag);
      $("#classification_tag_model").modal("hide");
      //$("#divBackdrop").addClass("d-none");
      this.modelPopupOutput.emit(false);
    } else {
      this.saveClassificationObjectTags();
    }
    //$("#classification_tag_model").modal("hide");
    this.selectedTag = "";
    this.selectedTagOwn = "";
  }

  downVote(payload) {
    const payloadData: any = {
      user_id: this.loggedInUserDetails["user_id"],
      tenant_id: this.loggedInUserDetails["tenant_id"],
      payload: payload,
    };
    if (this.showConcept) {
      this.catalogSvc.downVote(payloadData).subscribe((data) => {
        $("#classification_tag_model").modal("hide");
        this.modelPopupOutput.emit(true);
      }, (err) => {
        $("#classification_tag_model").modal("hide");
        this.modelPopupOutput.emit(false);
      });
    } else {
      $("#divBackdrop").addClass("d-none");
      this.catalogSvc.saveVoteSemanticObject(payloadData, "down_vote").subscribe((resp) => {
        $("#classification_tag_model").modal("hide");
            this.modelPopupOutput.emit(true);
      }, (error) => {
        $("#classification_tag_model").modal("hide");
        this.modelPopupOutput.emit(false);
      });
    }
  }
  closeClassificationTags() {
    $("#classification_tag_model").modal("hide");
    this.selectedTag = "";
    this.selectedTagOwn = "";
    this.modelPopupOutput.emit(false);
  }

  onModelGridCreation(grid) {
    this.modalGrid = grid;
  }
  onClassificationGridCreation(grid) {
    this.gridClassification = grid;
  }
  closeModelPopup() {
    this.modelPopupOutput.emit(false);
  }

  saveClassificationObjectTags() {
    let mappingType = "manual";
    if (this.selectedTag && this.selectedTag === "not_sure") {
      mappingType = "not_sure";
      const payload = {
        dataset_id: this.dataSetId,
        mapping_type: mappingType,
        entity_id: this.object_id, /// have to remove this line after fixing  this from api
        removing_entity_id: +this.object_id,
        catalog_id: +this.catalog_id,
        is_semantic_level_mapping: true,
      };
      this.downVote(payload);
    } else {
      const payload = {
        dataset_id: this.dataSetId,
        mapping_type: mappingType,
        entity_id: this.selectedTag,
        removing_entity_id: +this.object_id,
        catalog_id: +this.catalog_id,
        is_semantic_level_mapping: true,
      };
      this.downVote(payload);
    }
  }

  onChangeCatalogclick() {
    $("#data-" + this.old_row).prop("checked", false);
    $("#rbtCatalog").prop("checked", true);
    if (this.ddl_catalogs_id && this.ddl_object_id && this.ddl_concept_id) {
      this.isTagSelected = true;
    } else {
      this.isTagSelected = false;
    }
  }
  getConceptSynonymTag() {
    const data = {
      user_id: this.loggedInUserDetails["user_id"],
      tenant_id: this.loggedInUserDetails["tenant_id"],
      catalog_id: this.catalog_id,
      object_id: this.entity_id_left,
      concept_id: this.attribute_id_left,
      page: this.page,
      limit: this.limit,
    };
    this.catalogSvc.getSynonymConceptTags(data).subscribe(
      (data) => {
        if (data["currentpage"]) {
          this.bindGrid(data["currentpage"]);
        }
      },
      (err) => {
        this.is_downvote_loading = false;
        this.ds_classification = [];
        ($("#classification_tag_model") as any).modal("show");
      }
    );
  }

  getSemanticSynonymTag() {
    const data = {
      user_id: this.loggedInUserDetails["user_id"],
      tenant_id: this.loggedInUserDetails["tenant_id"],
      catalog_id: this.catalog_id,
      object_id: this.entity_id_left,
      page: this.page,
      limit: this.limit,
    };
    this.catalogSvc.getSynonymTags(data).subscribe(
      (data) => {
        if (data["currentpage"]) {
          this.bindGrid(data["currentpage"]);
        }
      },
      (err) => {
        this.is_downvote_loading = false;
        this.ds_classification = [];
        ($("#classification_tag_model") as any).modal("show");
      }
    );
  }
}