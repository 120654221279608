import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'zetta-technical-applied-columns',
  templateUrl: './technical-applied-columns.component.html',
  styleUrls: ['./technical-applied-columns.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TechnicalAppliedColumnsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
