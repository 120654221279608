import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class AppGlobals {

  // Run Model
  // 0 create Project--> run model
  // 1 Entities Resolved --> Train model --> Re Run Model
  // 2 Entities Record Mastered --> Train model --> Re Run Model

  private static runModelAlgo = [1, 1, 1];
  private static runModelAlgoJobType = ['loader', 'matcher', 'merger'];

  static APP_ENTITLEMENTS = {
    admin: {
      user: {
        'canAdd': true,
        'canEdit': true,
        'canDelete': true,
        'canSetpassword': true,
      },
      usergroup: {

      },
      dataset: {

      },
      project: {

      }
    },
    user: {

    }
  };

  static USER_INFO = 'userInfo';
  static MATCH_FEEDBACK_STATUS = '1';
  static NO_MATCH_FEEDBACK_STATUS = '0';
  static NOT_SURE_FEEDBACK_STATUS = '2';
  static SUGGESTED_CLUSTER_FEEDBACK_STATUS = '3';

  static PROJECT_ADMIN = 'project_admin';
  static REVEIWER = 'project_reviewer';
  static APPROVER = 'project_approver';

  static CHAIN_ATTRIBUTES = 'Chain';
  static ISLOGGEDIN = 'isloggedIn';
  static ISZMLOGGEDIN = 'isZmloggedIn';
  static ACTIVE_JOB_ERROR = ' Project has active job. Please wait or cancel the job.';
  static UNAUTHORIZED_ACCESS_ERROR = 'Unauthorized access!';

  static IS_ZS_LOGIN_PAGE = 'is_zs_login_page';
  static IS_ZM_LOGIN_PAGE = 'is_zm_login_page';

  /********Default Msg for Empty grid**********/

  static DEFAULT_EMPTY_GRID_MSG = 'No data found.';
  static DEFAULT_EMPTY_GRID_MSG_STYLE = 'default-empty-grid-msg';
  static DEFAULT_ERROR_MSG = 'Error Occurred';

  /***************** COMMON *****************/
  static FULL = 'full';
  /***************** router URLS *****************/
  // Landing
  static ZETTALABS = 'zettalabs';
  static ZETTALABS_PATH = '/zettalabs';

  // login
  static REGISTER = 'register';
  static FOTGOT_PASSWORD = 'forgot-password';
  static STYLEGUIDE = 'styleguide';
  static RESET_PASSWORD = 'reset-password';
  static USER_SESSION_TIMEOUT = 900;
  static ENTITIES_RESOLVED_JOB_INTERVEL = 60000; // 1000*60*1 : 1 min
  static NUMERIC_REGEX = /\d/;
  static CHARACTERS_REGEX = /[A-Za-z ]+/;
  static MM_DD_YYYY_FORMAT = 'MM/dd/yyyy';

  // Zettamesh Login
  static ZETTAMESH_LOGIN = 'zm/login';
  static ZETTAMESH_PATH = '/zm/login';

  // Zettamesh home
  static ZETTAMESH_HOME = 'zm/home';
  static ZETTAMESH_HOME_PATH = '/zm/home';

  // Zettamesh Projects
  static ZETTAMESH_PROJECT = 'zm/project';
  static ZETTAMESH_PROJECT_PATH = '/zm/project';

  // Zettamesh Data
  static ZETTAMESH_DATA = 'zm/data';
  static ZETTAMESH_DATA_PATH = '/zm/data';

  // Zettamesh Dataset
  static ZETTAMESH_DATASET = 'zm/dataset';
  static ZETTAMESH_DATASET_PATH = '/zm/dataset';

  // Zettamesh Projects
  static ZETTAMESH_TASKS = 'zm/tasks';
  static ZETTAMESH_TASKS_PATH = '/zm/tasks';

  // Zettamesh Projects
  static ZETTAMESH_MANAGEJOBS = 'zm/managejobs';
  static ZETTAMESH_MANAGEJOBS_PATH = '/zm/managejobs';

  //  Run Model API  calls.
  static getRunModelAlgo(scenarioId: number): number {
    return AppGlobals.runModelAlgo[scenarioId];
  }

  static getRunModelAlgoJobType(scenarioId: number): string {
    return AppGlobals.runModelAlgoJobType[scenarioId];
  }

  // Users

  // Admin

}
