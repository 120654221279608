import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import { formatDate } from '@angular/common';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zm-zetta-bar-chart',
    templateUrl: './zm-bar-chart.component.html',
    styleUrls: ['./zm-bar-chart.component.scss']
})
export class ZmBarChartComponent implements AfterViewInit {

    public donut: any = {};
    public chartHeight: number;
    public chartWidth: number;
    public fontSize: number;
    public isChartInValid = false;
    public $container;
    public colors: any;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;

    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(public cdRef: ChangeDetectorRef, public observeSvc: ZmObservableService, public zettaUtils: ZmZettaUtils) {}

    ngAfterViewInit() {
        this.isChartInValid = (!this.dataDataSource || this.dataDataSource.length == 0) ? true : false;
        if (!this.chartColors) {
            this.chartColors = ['#483da0', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }
        this.cdRef.detectChanges();
        if (this.dataDataSource && !this.isChartInValid) {
            setTimeout(() => {
                this.renderChart();
            }, 100);
        }
        let parent = this;
        this.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    renderChart() { 
        let parent = this;
        parent.$container = $('#chart_' + parent.chartUniqueID).parent().closest('div');

        parent.chartHeight = parent.chartOptions['height'];
        parent.chartWidth = parent.$container.width()-20;
        parent.fontSize = (Math.min(parent.chartWidth, parent.chartHeight) / 4);

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        $('#chart_' + parent.chartUniqueID).empty();

        let margin = {  top: parent.chartOptions.margin.top, 
                        right: parent.chartOptions.margin.right, 
                        bottom: parent.chartOptions.margin.bottom, 
                        left: parent.chartOptions.margin.left };
        
                const svg = d3.select('#chart_' + parent.chartUniqueID).append('svg')
                        .attr('width', parent.chartWidth)
                        .attr('height', parent.chartHeight);

                const contentWidth = parseFloat(parent.chartWidth.toString()) - margin.left - margin.right;
                const contentHeight = parseFloat(parent.chartHeight.toString()) - margin.top - margin.bottom;

                let lineTooltip = d3.select('#chart_' + parent.chartUniqueID).append('div').attr('class', 'toolTip');
                lineTooltip.style('display', 'none');

                parent.dataDataSource.forEach(function(d, i) {
                    // d.label = d['fulldate'];
                    d.label = d.label;
                    d.value = +d.value;
                });

                parent.dataDataSource.sort(function(a, b) { return a.label - b.label; });

                const x = d3.scaleBand()
                    .rangeRound([0, contentWidth])
                    .padding(0.7)
                    .domain(parent.dataDataSource.map(d => d.label));

                const y = d3.scaleLinear()
                .rangeRound([contentHeight, 0])
                .domain([0, d3.max(parent.dataDataSource, d => d.value)]);
          
                const g = svg.append('g')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
          
                this.colors = ["#EC43A2", "#FBB54D", "#00C219"];

                // gridlines in x axis function
                // function make_x_gridlines() {		
                //     return d3.axisBottom(x)
                //         .ticks(10)
                //         // .ticks(parent.dataDataSource.length+2)
                // }
          
                // gridlines in y axis function
                function make_y_gridlines() {		
                    return d3.axisLeft(y)
                        .ticks(10)
                }

                // g.append("rect")
                //     .attr("x", 1)
                //     .attr("y", 0)
                //     .attr("fill", this.colors[0])
                //     .style("opacity", "0.08")
                //     .attr("height", contentHeight)
                //     .attr("width", contentWidth + 1);
                //     //   .style("stroke", "royalblue")
                //     //   .style("stroke-width", "3.5px");

                g.append("rect")
                    .attr("x", 1)
                    .attr("y", 0)
                    .attr("fill", this.colors[0])
                    .style("opacity", "0.08")
                    .attr("height", contentHeight)
                    .attr("width", contentWidth/3.1)

                g.append("rect")
                    .attr("x", contentWidth/3.1)
                    .attr("y", 0)
                    .attr("fill", this.colors[1])
                    .style("opacity", "0.08")
                    .attr("height", contentHeight)
                    .attr("width", contentWidth/2.9);

                g.append("rect")
                    .attr("x", contentWidth/3.1 + contentWidth/2.9)
                    .attr("y", 0)
                    .attr("fill", this.colors[2])
                    .style("opacity", "0.08")
                    .attr("height", contentHeight)
                    .attr("width", contentWidth - (contentWidth/3.1 + contentWidth/2.9));
                    //   .style("stroke", "royalblue")
                    //   .style("stroke-width", "3.5px");


                g.append("line").style("stroke", this.colors[0]).attr("x1", contentWidth/3.1).attr("y1", -15).attr("x2", contentWidth/3.1).attr("y2", contentHeight).style("stroke-dasharray", ("4, 4"));
                g.append('text').text('Low/Bad Performance').style('fill', this.colors[0]).attr('transform', 'rotate(0)').attr('x', 0).attr('dx', contentWidth/3.1 - 8).attr('y', -6).attr('text-anchor', 'end');

                g.append("line").style("stroke", this.colors[1]).attr("x1", contentWidth/3.1 + contentWidth/4).attr("y1", -15).attr("x2", contentWidth/3.1 + contentWidth/4).attr("y2", contentHeight).style("stroke-dasharray", ("4, 4"));
                g.append('text').text('Average Performance').style('fill', this.colors[1]).attr('transform', 'rotate(0)').attr('x', 0).attr('dx', contentWidth/3.1 + contentWidth/4 -8).attr('y', -6).attr('text-anchor', 'end');
                
                g.append("line").style("stroke", this.colors[2]).attr("x1", contentWidth/3.1 + contentWidth/2.9).attr("y1", -15).attr("x2", contentWidth/3.1 + contentWidth/2.9).attr("y2", contentHeight).style("stroke-dasharray", ("4, 4"));
                g.append('text').text('High/God Performance').style('fill', this.colors[2]).attr('transform', 'rotate(0)').attr('x', 0).attr('dx', contentWidth/3.1 + contentWidth/2.9 + 100 + 8).attr('y', -6).attr('text-anchor', 'end');


          
                g.append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0,' + contentHeight + ')')
                    .call(d3.axisBottom(x))
                    .append('text')
                    .attr('transform', 'rotate(0)')
                    .attr('x', contentWidth/2)
                    .attr('dx', '0.71em')
                    .attr('y', +40)
                    .attr('text-anchor', 'middle')
                    .text(this.chartOptions.labelX);
            
                g.append('g')
                    .attr('class', 'axis axis--y')
                    .call(d3.axisLeft(y).ticks(10))
                    .append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('y', -55)
                    .attr('x', -contentHeight/2 + 80)
                    // .attr('dy', 10)
                    .attr('text-anchor', 'end')
                    .text(parent.chartOptions.labelY);

                // add the X gridlines
                // g.append("g")			
                //     .attr("class", "grid")
                //     // .attr("transform", "translate(" + margin.left + "," + (contentHeight + margin.top) + ")")
                //     .attr("transform", "translate(0," + (contentHeight) + ")")
                //     .call(make_x_gridlines()
                //         .tickSize(-contentHeight)
                //         .tickFormat("")
                //     );

                // add the Y gridlines
                 svg.append("g")			
                 .attr("class", "grid")
                //  .attr("transform", "translate(" + margin.left + "," + (contentHeight + margin.top) + ")")
                 .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                 .call(make_y_gridlines()
                     .tickSize(-contentWidth)
                     .tickFormat("")
                 );
          
                g.selectAll('.bar')
                    .data(parent.dataDataSource)
                    .enter().append('rect')
                    .attr('class', 'bar')
                    .on('mouseover', function() { lineTooltip.style('display', 'none'); })
                    .on('mouseout', function() { lineTooltip.style('display', 'none'); })
                    .on('mousemove', mousemove)
                    .attr('x', d => x(d.label))
                    .attr('y', d => y(0))
                    .attr('width', x.bandwidth()) 
                    .attr('height', 0)  //this is the initial value
                    // .attr("fill", parent.chartOptions.barColor1)
                    // .style('fill', (d, i) => this.colors(i))
                    .style('fill', (d,i) => {  
                        if( i < 3 ) { return this.colors[0] }
                        if( i < 7 ) { return this.colors[1] }
                        if( i >= 7 ) { return this.colors[2] }

                    })
                    // .attr("opacity", "0.8")
                        .transition()
                        .duration(1500)
                        // .delay(function(d,i){ return i*15})   //a different delay for each bar
                    .attr('y', d => y(d.value))
                    .attr('height', d => contentHeight - y(d.value));
          
                //Tooltip mouseovers            
                function mousemove(d,i) {
                lineTooltip
                    .style('left', d3.event.pageX + 'px')
                    .style('top', d3.event.pageY - 35 + 'px')
                    .style('display', 'inline-block')
                    .html(function() {
                            return '<span>' + parent.chartOptions.labelY + ': <strong>' + d.value + '</strong></span><br>'+
                                   '<span>' + parent.chartOptions.labelX + ': <strong>' + d.label + '</strong></span>';
                    });
                };  
    }

    onResize() {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }
}