import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from 'src/app/common/shared/app.globals';

const numericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const mapped_icon = (dataContext.mapped_data_sets > 0 || dataContext.mapped_data_sources > 0) ? `<i class="fal fa-code-branch"></i>` : ``;
  return `<div class="row align-items-center formatter project-title-formatter-nodesc  pointer m-0 p-0 h-100">
  <div class="col-auto m-0 p-0 text-left mapped-icon">
   ${mapped_icon}
  </div>
  <div class="col-auto ml-2 m-0 p-0 text-left">
  <div class="text-truncate" title="${value}">${value}</div>
  </div>
</div>`
};

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
    if(dataContext.isChecked){
      return `<div class="formatter match-radio-formatter">
      <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
    }else{
      return `<div class="formatter match-radio-formatter">
      <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
  </div>`;
    }
      
};

const EMPTY_GRID_MSG = 'No Semantic Objects.';

@Component({
  selector: 'zetta-zs-cl-project-select-object',
  templateUrl: './zs-cl-project-select-object.component.html',
  styleUrls: ['./zs-cl-project-select-object.component.scss']
})
export class ZsClProjectSelectObjectComponent implements OnInit, OnDestroy {
  progressState = new ProjectStatus();
  tableColDef: any;
  gridOptions: any;
  selectedGridRowsSize = 0;
  semantic_id: string;
  catalogs = [];
  catalogId: number;
  defaultCatalog:any;
  semantic_objects = [];
  projId:string;
  showBreadcrumb= false;
  total=0;
  projectData:any;
  isLoading=true;
  isDataReady=false;
  isPrevEntityId:string;
  isever_executed: string;
  isEditProject: string;
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  constructor(public zettaUtils: ZettaUtils, private clContentService: ZsClContentService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.selObjectInit();
    this.route.parent.params.subscribe(params => {
      this.projId = params['id'];
    });
    sessionStorage.removeItem("activeLinkStep");
    this.isever_executed= this.route.snapshot.queryParamMap.get('ever_executed');
    this.isEditProject= this.route.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.route.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
    this.getProject();
  }

  getProject(){
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.projId
    };
    this.clContentService.getclassifyProject(payload).subscribe(resp => {   
      if(resp){        
        this.projectData = resp;  
        this.isPrevEntityId = resp.semantic_id;      
        this.semantic_id=this.isPrevEntityId;
        this.defaultCatalog = {catalog_id:resp.catalog_id}
        if (this.showBreadcrumb === true) {                    
          this.progressState.states = this.zettaUtils.getStateList('manage-project-data');
          this.progressState.currentStateIndex = 0;
          this.progressState.type = 'manageProjectData';
        }
        else {
          this.progressState.states = this.zettaUtils.getStateList('create-classify-project');
          this.progressState.currentStateIndex = 2;
          this.progressState.classificationProject = resp;
          this.progressState.type = 'Data Classification Project';
          this.progressState.isEdit = this.isEditProject === 'true';

        }
        this.progressState.currentStateInfo = 'Saved...';
        this.isDataReady = true;
        let data = { user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], id: this.projId };
        this.clContentService.getCatalogs(data.user_id)
          .subscribe(catalogs => {
            if (catalogs) {
              this.catalogs = catalogs;
              if(this.defaultCatalog &&  this.defaultCatalog.catalog_id){
                this.changeCatalogName(this.defaultCatalog.catalog_id);
              }else{
                this.defaultCatalog = this.catalogs.find(item => item.name.toLowerCase() === AppGlobals.DEFAULT_SYSTEM_CATALOG.toLowerCase());
                if (this.defaultCatalog) {
                  this.changeCatalogName(this.defaultCatalog['catalog_id']);
                }
              }
            }
          }) 
      }
    });
  }  
  selObjectInit() {
    this.tableColDef = [
      {
        'displayname': ' ',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 35,
        'maxWidth': 35,
        // 'params': this.dataSelected,
      },{
        'displayname': 'Semantic Object',
        'physicalname': 'semantic_object',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 150,
        'maxWidth': 250,
        'formatter': numericFormatter,
      }, {
        'displayname': 'Description',
        'physicalname': 'definitions',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      editable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      noDataMsg: EMPTY_GRID_MSG,      
    };
  }

  changeCatalogName(valu) {
    this.isLoading=true;
    this.catalogId = valu;
    this.semantic_id=null;
    if(this.defaultCatalog.catalog_id == valu){
      this.semantic_id=this.isPrevEntityId;
    }    
    this.getEntities(valu)
  }

  getEntities(id) {
    this.semantic_objects=[];
    if (this.grid) {
      this.grid.dataView.setItems(this.semantic_objects);
    }
    this.clContentService.getSemanticsObjects(id)
    .subscribe(res => {
      res.forEach(el => {
       el.isChecked= el.entity_id == this.isPrevEntityId ? true :false;
       el.id = Math.random();
      });
      this.semantic_objects = res;
      this.total = res.length;
      let concatedLeftRows = this.semantic_objects;
      this.isLoading=false;
      if (this.grid) {
        this.semantic_objects = concatedLeftRows;
        this.semantic_objects.forEach(element => {
          element._id = Math.random();
        });
        this.grid.dataView.setItems(this.semantic_objects);
        this.grid.gridService.setSelectedRows([]);
      }
    },error=>{
      this.isLoading=false;
    })
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedGridRowsSize = args.rows.length;
    });
  }

  onCellClick(eventData): void {
    const parent = this;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent.semantic_id = row.entity_id;
    $("#data-" + args.row).prop("checked", true);
    parent.selectedGridRowsSize = 1;
  }

  updateEntity() {
    let data = {
      user_id: this.loggedInUserDetails['user_id'], 
      tenant_id: this.loggedInUserDetails['tenant_id'], 
      id:this.projId, 
      payload: {
        semantic_id: this.semantic_id,
        catalog_id:this.catalogId
      }
      };
    this.clContentService.updateSelectObject(data)
    .subscribe(resp => {
      if(resp && resp.length) {
        const qParams = {catalog_id: this.catalogId, entity_id: resp[0].semantic_id, showBreadcrumb: this.showBreadcrumb };
        this.router.navigate([`/zs-cl/projects/${this.projId}/new-classifier`], { queryParams:  qParams, queryParamsHandling:'merge'});
      }
    })
  }
  onPrevious(){
    if (this.isEditProject) {
      this.router.navigate([`/zs-cl/projects/${this.projId}/add-users`], { queryParams:  {  showBreadcrumb: this.showBreadcrumb, isEditProject: true,ever_executed: this.isever_executed}, queryParamsHandling: 'merge' });
    }
    else {
      this.router.navigate([`/zs-cl/projects/${this.projId}/add-users`], { queryParams:  {  showBreadcrumb: this.showBreadcrumb }});
    }   
  }
  onCancel() {
    if (this.showBreadcrumb === true) { 
      this.router.navigate(['/zs-cl/projects/', this.projId ],{ queryParams: { showBreadcrumb: 'true' }, queryParamsHandling: 'merge' });
    } 
    else {
      this.router.navigate(['/zs-cl/projects' ]);
    }                 
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }

}

