import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularGridInstance } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { BaseService } from 'src/app/common/services/base-service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { environment } from '../../../../../../../environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
declare var $: any;
const DATA_MSG = '';
import { ObservableService } from "../../../../../../common/services/observable.service";

@Component({
  selector: 'zetta-run-execution-result-exception',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss'],
})
export class ExceptionsComponent implements OnInit {

  activeNavLnk="dq-business";
  @Input() showNoImage: boolean;
  @Input() showRuleTile = true;
  @Input() isRuleAdmin: boolean;
  @Input() isRuleExecuter: boolean;
  showExceptionGrid = false;
  selectedRuleName: string;
  eventData: any;
  tableSettings: object = { 'height': "100%", 'width': "99%" };
  hasTechnicalScrolled = false;
  noData = false;
  noTechnicalData = false;
  loggedInUserDetails: [];
  selectedFiles: string[] = [];
  isTechnicalDataReady = false;
  entity: any = [];
  is_exceptions_loading=false;
  showBreadcrumb = false;
  total = 0;
  totalTechnicalView = 0;
  catalog_id;
  object_id;
  concept_id;
  technicalView: any = [];
  lastRowSelected = 0;
  viewMode = "business";
  technicalViewTableColDef: any;
  gridTechnicalViewOptions: any;
  leftGrid: AngularGridInstance;
  page = 1;
  limit = 100;
  selectedObjectMetadata: any;
  dq_rule_level: string;
  rule_id: number;
  isViewMode = false;
  isScroll = true;
  isDatasetLevel = false;
  dataset_id:string;
  isMultiAttributeRule;
  isFiltering = false;
  isSorting = false;
  columnApi: any;
  gridApi:any;
  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  subTotal = null;

  constructor(private catalogSvc: ZsClContentService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zettaUtils: ZettaUtils,
              private baseService: BaseService,
              private messageSvc: MessageService, 
              private projService: ProjectService,
              public _observableService: ObservableService
              ) { }

  ngOnInit() {
    this.isMultiAttributeRule = false;
    this.baseService.hasPageRefresh.subscribe(value => {
      if (value) {
        this.activeNavLnk = "dq-business"
        sessionStorage.removeItem('activeNavLnk');
      }
    });

    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    let mode = sessionStorage.getItem('viewMode');
    this.activatedRoute.queryParamMap.subscribe(params=>{
      this.catalog_id = params.get('catalog_id');
      this.object_id = +params.get('object_id');
      this.concept_id = +params.get('concept_id');
      this.dq_rule_level = params.get('dqRuleLevel');
      this.dataset_id = params.get('dataset_id');
      if (this.dq_rule_level == 'dataset') {
        this.isDatasetLevel = true;
      }
    });
    this.activatedRoute.parent.queryParamMap.subscribe(params => {
      this.rule_id = +params.get('rule_id');
      if (this.rule_id > 0) {
        this.isViewMode = true;
        this.is_exceptions_loading = true;
        this.getRuleException(this.page, this.limit);
      }

    });

    this._observableService.getRuleSequenceType.subscribe(text => {      
      this.isMultiAttributeRule = text;
      if (this.dq_rule_level == 'catalog' || this.dq_rule_level == 'object' || this.dq_rule_level == 'concept') {
        this.loadExceptionColumn();
      }
    })

    this.gridTechnicalViewOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      rowHeight: 30,
      enableCellTextSelection: true,
    };

    if (this.dq_rule_level != 'catalog' && this.dq_rule_level != 'object' && this.dq_rule_level != 'concept') {
      this.loadExceptionColumn();
    }

    this.catalogSvc.showExceptionsGrid.subscribe(data => {
      this.showExceptionGrid = data['showException'];
      this.selectedRuleName = data['name'];
      if (data['showException'] && mode !== 'technical') {
        this.rule_id = data['rule_id'];
        if (this.leftGrid) {
          this.technicalView = [];
          this.leftGrid.dataView.refresh();
          this.leftGrid.dataView.setItems(this.technicalView);
          this.leftGrid.gridService.setSelectedRows([]);
        }
        this.getRuleException(1, this.limit);
      }
      if (this.selectedRuleName) {
        this.is_exceptions_loading = true;
      }
    });
  }
  
  loadExceptionColumn(width=0) {
    let mode = sessionStorage.getItem('viewMode');
    if (!mode) {
      mode = 'business';
    }

      this.technicalViewTableColDef = [
        {
          headerName:'Data Source',
          field:  'datasource_name',
          filter: 'agTextColumnFilter',
          cellClass: 'elipse-overflow',
          editable: false, 
           minWidth:130, 
        
       
          floatingFilter: true,
          sortable: true,  
         
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField :'datasource_name'
          
        },
        {
          headerName: 'Data Set',
          field: 'dataset_name',
          filter: 'agTextColumnFilter',
          cellClass: 'elipse-overflow',
          editable: false, 
           minWidth:130, 
          floatingFilter: true,
          sortable: true,  
          
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'dataset_name'
        },
        {
          headerName:'Primary Key Value',
          field: 'primary_key',
          filter: 'agTextColumnFilter',
           minWidth:200, 
          cellClass: 'elipse-overflow',
          editable: false, 
          floatingFilter: true,
          sortable: true,  
      
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'primary_key'
        },
        {
          headerName: 'Column Name',
          field: 'dataset_column_name',
          minWidth:130, 
          filter: 'agTextColumnFilter',
          cellClass: 'elipse-overflow',
          editable: false, 
          floatingFilter: true,
        sortable: true,  
       
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'dataset_column_name'
        },
     {
          headerName: 'Column Value',
          field: 'dataset_column_value',
          filter: 'agTextColumnFilter',
          minWidth:130, 
          cellClass: 'elipse-overflow',
          editable: false, 
          floatingFilter: true,
          sortable: true,  
        
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'dataset_column_value'
        },
        {
          headerName: 'Rule Break Reason',
          field: 'rule_break_reason',
          filter: 'agTextColumnFilter',
          minWidth:200, 
          cellClass: 'elipse-overflow',
          editable: false, 
          floatingFilter: true,
          sortable: true,  
      
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'rule_break_reason'
        },
        {
          headerName: 'Last Updated',
          field: 'last_updated_date',
          filter: 'agTextColumnFilter',
          cellClass: 'elipse-overflow',
          minWidth:130, 
          editable: false, 
          floatingFilter: true,
          sortable: true,  
        
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField :'last_updated_date'
        },
       
      ];
    

    if (mode == "business" || this.dq_rule_level == 'catalog' || this.dq_rule_level == 'object' || this.dq_rule_level == 'concept') {
      this.technicalViewTableColDef.unshift(
        {
          headerName: 'Semantic Object',
          field: 'entity_name',
          filter: 'agTextColumnFilter',
          cellClass: 'elipse-overflow',
          minWidth:130, 
          editable: false, 
          floatingFilter: true,
          sortable: true,  
          
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'entity_name'
        },
        {
          headerName: 'Concept',
          field: 'attribute_name',
          filter: 'agTextColumnFilter',
          minWidth:130, 
          cellClass: 'elipse-overflow',
          editable: false, 
          floatingFilter: true,
          sortable: true,  
        
          floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField :'attribute_name'
        },
       
      );
    }
  }

  getRuleException(pageNo, limit){
   this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${ this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}`;   
    let serverfilter = "";
   
    let sortfilter = "";
  
    this.catalogSvc.getRuleException(pageNo, limit, this.rule_id, this.isDatasetLevel, this.dataset_id, serverfilter, sortfilter).subscribe(resp => {
      this.technicalView = resp['currentpage'];
      this.showExceptionGrid = true;
      this.showNoImage = true;
      this.is_exceptions_loading = false;
      if (this.technicalView.length === 0) {
          this.noTechnicalData = true;
      }
      let maxlen = 0;
      this.technicalView.forEach((element, i) => {
        element.id = Math.random();
        if (this.technicalView[i].rule_break_reason && this.technicalView[i].rule_break_reason.length > maxlen) {
          maxlen = this.technicalView[i].rule_break_reason.length;
        }
      });
      let width = 0;
      if (maxlen) {
        width = Math.round((7 * maxlen));
      }
      this.loadExceptionColumn(width);
      this.totalTechnicalView = resp['totalrecords'];
      this.isTechnicalDataReady = true;
      this.isScroll = false;
      let concatedRowsT = this.technicalView;
     
      if(pageNo < resp['totalrecords']) {
        this.hasTechnicalScrolled = false;
      } 
      if (resp.pageno === 1) {
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}&pagination=true&pageno=1&pagesize=${resp['totalrecords']}`;
      }
    },err => {
      this.isTechnicalDataReady = true;
      this.is_exceptions_loading = false;
      this.showExceptionGrid = true;
      this.showNoImage = true;
      if(err.error.error.toLowerCase() == "The operation is in a pending state".toLowerCase()){
        this.messageSvc.sendMessage({ message: "Databricks Cluster is still activating to get the data. Please try again in a couple of minutes", type: 'INFO', hideboard: true });
      }else{
        // this.messageSvc.sendMessage({ message: err.error.error, type: 'INFO', hideboard: true });
      }
    });
  }
 
  conceptLeftGrid(grid) {
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
  }

  onScrollTechview(parentClass) {
    const filterKey = sessionStorage.getItem("serverfilter");
    const sortKey = sessionStorage.getItem("sortfilter");
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    this.isScroll = true;
     if (this.zettaUtils.virtualScroll(parentClass) && !this.hasTechnicalScrolled  && !filterKey && !sortKey) {
   // if (!this.hasTechnicalScrolled) {
      this.hasTechnicalScrolled = true;
      this.onNextPageTechView();
    }
  }

  onNextPageTechView(): void {
    this.page++;
    this.getRuleException(this.page, this.limit);
  }

  ngOnDestroy() {    
    // if (!this.showBreadcrumb) {
        $('#breadcrumb').removeClass('d-none');
    // }
    this.messageSvc.clearMessage();
    this.catalogSvc.unsubscribeExceptionGrid();
  }

  onTabClick(activeLink: string) {
    this.activeNavLnk = activeLink;
    sessionStorage.setItem('activeNavLnk', activeLink);
  }

  onExport() {
    if (this.isDatasetLevel && this.dataset_id) {
      // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}&dataset_id=${this.dataset_id}&export=true`, '_self');
      this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}&dataset_id=${this.dataset_id}&export=true`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, 'DQExceptionsReport');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    } else {
      // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}&export=true`, '_self');
      this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules/exceptions?rule_id=${this.rule_id}&export=true`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, 'DQExceptionsReport');
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    }
  }

  onReRunRule(){    
    let mode = sessionStorage.getItem('viewMode');
    if(!mode){
      mode='business';
    }
    this.updateRunExecutor(true);
    this.catalogSvc.reRunRule(this.rule_id, mode).subscribe(resp => {
      this.messageSvc.sendMessage({
        message: '', type: 'Info', hideInfo: true,isReRunRule: true,activeJobs: 'DataQuality',
        messageHeader: AppGlobals.RULES_MSG_HEADER, messageText: AppGlobals.RULES_MSG_TEXT,
      });
    });
  }
  updateRunExecutor(is_run_executor) {
    const data = {
      rule_id: this.rule_id,
      payload: {
        run_executor: is_run_executor
      }
    };
    this.catalogSvc.updateRunExecutor(data).subscribe(resp => {
    }, error => { });
  } 

  receiveTotalRecords($event) { 
    this.subTotal = this.zettaUtils.getSubTotal(this.totalTechnicalView, $event.subTotal); 
   }
}
