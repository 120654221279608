import { Component, ElementRef, EventEmitter, OnInit, Input,   Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import panzoom from "panzoom";
import { MessageService } from "../../../message/message.service";
import { DatasetService } from "../../dataset.service";
import { ZsClContentService } from '../../../../../zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from '../../../../../common/shared/app.globals';

@Component({
  selector: 'zetta-dataset-relationships',
  templateUrl: './dataset-relationships.component.html',
  styleUrls: ['./dataset-relationships.component.scss'],
})

export class DatasetRelationShipsComponent implements OnInit {
  
  @ViewChild('scene') scene: ElementRef;     
  url;   
  dataset_id;
  element = document.getElementById('scene');
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  diagram = true;
  viewMode: String;
  techincalview: String;
  bussinessview: String;
  user_apps: Boolean = false;
  datasetObjectTypeId = AppGlobals.OBJECT_TYPE_ID_DATSET;
  @Input() isDatasetHaveAdminORReadWriteAccess: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private messageSvc: MessageService,
              private datasetService: DatasetService, 
              private sanitizer : DomSanitizer,
              private catalogSvc: ZsClContentService) {
                const apps = JSON.parse(sessionStorage.userInfo).apps;
                if (apps.length) {
                  const cl_apps = apps.filter(item => item.app_id == AppGlobals.CLASSIFY_APP_ID);
                  if (cl_apps.length) {
                    this.user_apps = true;
                  }
                }
               }

  ngOnInit() {    
    this.techincalview = AppGlobals.TECHNICAL_VIEW;
    this.bussinessview = AppGlobals.BUSINESS_VIEW; 
    this.activatedRoute.parent.params.subscribe(params => {
      this.dataset_id = params['id'];
    });
    if (!this.user_apps) {
      this.viewMode = AppGlobals.TECHNICAL_VIEW;
      this.getTechnicalRelationshipDiagram();
    } else {
      this.viewMode = AppGlobals.BUSINESS_VIEW;
      this.getBusinessRelationshipDiagram();
    }
  }

  changeView(viewMode) {
    this.viewMode = viewMode;
    if (this.viewMode == AppGlobals.TECHNICAL_VIEW) {
      this.getTechnicalRelationshipDiagram();
    }
    else {
      this.getBusinessRelationshipDiagram();
    }
  }
  
  public getTechnicalRelationshipDiagram() {
    this.datasetService.getTechRelationshipDiagram(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id).subscribe(resp => {
      this.diagram = true;
      let reader = new FileReader();
      let base64Img: any;
      reader.addEventListener('load', () => {
        base64Img = reader.result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(base64Img);
      }, false);
      if (resp && resp.size && resp.type == "image/svg+xml") {
        base64Img = reader.readAsDataURL(resp);
      } else {
        this.url = null;
      }
    }, error => {
      this.diagram = false;
     });

  }

  public getBusinessRelationshipDiagram() {
    this.datasetService.getBussRelationshipDiagram(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id).subscribe(resp => {
      this.diagram = true;
      let reader = new FileReader();
      let base64Img: any;
      reader.addEventListener('load', () => {
        base64Img = reader.result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(base64Img);
      }, false);
      if (resp && resp.size && resp.type == "image/svg+xml") {
        base64Img = reader.readAsDataURL(resp);
      } else {
        this.url = null;
      }
    }, error => {
      this.diagram = false;
     });

  }

  ngAfterViewInit() {   
    panzoom(this.scene.nativeElement, {
        bounds: true,
        boundsPadding: 0.1,
        autocenter:true,
        transformOrigin: {x: 0.5, y: 0.5}
    });   
  }

  onRunModel() {
    this.catalogSvc.runModel303().subscribe((res) => {
      this.messageSvc.sendMessage({ message: '', type: 'INFO', hideInfo: true, activeJobs: 'Dataset', messageHeader: AppGlobals.CLASSIFICATION_MSG_HEADER, messageText: AppGlobals.CLASSIFICATION_MSG_TEXT, path: 'zs/datasets/' + this.dataset_id, queryParams: { showBreadcrumb: true } });
    }, (err) => {
      this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  shouldEnableDatasetGraphButton() {
    return this.isDatasetHaveAdminORReadWriteAccess;
  }
  
}
