import { AppGlobals } from '../../common/shared/app.globals';
import { Injectable } from '@angular/core';
import { BaseService } from '../../common/services/base-service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ZsClContentService extends BaseService {
	constructor(private http: HttpClient) { super(); }
    public engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
    public showExceptionsGrid:BehaviorSubject<object> = new BehaviorSubject({showException:false,name:'',rule_id:''});
	public showActiveLink:BehaviorSubject<string> = new BehaviorSubject("rule-definition");
	public isEditView:BehaviorSubject<Boolean> = new BehaviorSubject(false);
	public defaultView:BehaviorSubject<Boolean> = new BehaviorSubject(false);
	public isRuleEdittable:BehaviorSubject<Boolean> = new BehaviorSubject(false);
	public showGraphGrid:BehaviorSubject<object> = new BehaviorSubject({showGraph:false,name:'',rule_id:''});
	public thresholdValue:BehaviorSubject<Number> = new BehaviorSubject(0);
	public matcherConfidenceValue:BehaviorSubject<Number> = new BehaviorSubject(0);
	public mergerThreshold:BehaviorSubject<number> = new BehaviorSubject(0);
	public mergerConfidenceValue:BehaviorSubject<Number> = new BehaviorSubject(0);
	public currentProjectExecutingStep:BehaviorSubject<Number> = new BehaviorSubject(0);
	public goldenRecordsPublishStatus:BehaviorSubject<Number> = new BehaviorSubject(0);
	public isJobCompleted:BehaviorSubject<Boolean> = new BehaviorSubject(false);
	unsubscribeExceptionGrid() {
		this.showExceptionsGrid.next({ showException: false, name: '', rule_id: '' });
	}
	public getReports(): Observable<any> {
		return this.http.get(`./assets/reports.json`);
	}

	public getCatalogs(userId: any, admin_only = false): Observable<any> {
		let adminQuery = '';
		if (admin_only) {
			adminQuery = '?admin_only=true'
		}
		return this.http.get(`${this.apiUrl}/users/${userId}/catalogs${adminQuery}`);
	}

	public getCatalogsListWithPaged(pageNo, limit): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs?pagination=true&pageno=${pageNo}&pagesize=${limit}`);
	}

	public getCatalogsListPagedAndSearch(pageNo, limit, serverfilter='',sortfilter='', latest_records = false): Observable<any> {
		let queryParams = '';
		if (latest_records) {
			queryParams = '&latest_records=true';
		}
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs?pagination=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}${queryParams}`);
	}
	
	public getCatalogDatasources(data: any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/datasources`);
	}

	public getCatalogDatasets(data: any, serverfilter="", sortFilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/datasets?pagination=true&pageno=${data.pageNo}&pagesize=${data.limit}${serverfilter}${sortFilter}`);
	}

	public getCatalog(catalogId, isBasicNeeded = false): Observable<any> {
		if (isBasicNeeded) {
			return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}?basic=true`);
		}
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}`);
	}

	public deleteCatalog(userId, catalogId): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}`);
	}

	public getSemanticsObjects(catalogId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics`);
	}

	public getDatasetSchemaLists(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=DatasetSchemaType`);
	}

	public getSemanticsObjectsPaged(catalogId, pageNo, limit,serverfilter,sortFilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics?pagination=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortFilter}`);
	}

	public getSemanticObject(catalogId, objectId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}`);
	}

	public deleteSemanticObject(deleteData): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${deleteData.user_id}/catalogs/${deleteData.catalog_id}/semantics/${deleteData.object_id}`);
	}

	public saveSemanticObject(userId, catalogId, payload: any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/semantics`, payload);
	}

	public getConcepts(catalogId, objectId, dataType="", ignoreSystemAttributes): Observable<any> {
		let ignoreSystemAttributeQueryParams;
		if(dataType && dataType.length > 0){
			ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '&ignore_system_attributes=true' : '' ;
			return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts?data_type=${dataType}${ignoreSystemAttributeQueryParams}`);//?data_type=${dataType} // need to add this querystring after api deployement	
		}else{
			ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '?ignore_system_attributes=true' : '' ;
			return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts${ignoreSystemAttributeQueryParams}`);
		}		
	}	

	public getConceptsPaged(catalogId, objectId, pageNo, limit,serverfilter='',sortfilter='', ignoreSystemAttributes = false): Observable<any> {
		if (ignoreSystemAttributes) {
			return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts?pagination=true&ignore_system_attributes=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);	
		}
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts?pagination=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}

	public getConcept(catalogId, objectId, conceptId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts/${conceptId}`);
	}

	public getMapConcepts(semanticIds, ignoreSystemAttributes = false): Observable<any> {
		let queryString = `?include_semantic=true&semantic_ids=${semanticIds}`;
		if (ignoreSystemAttributes) {
			queryString = `${queryString}&ignore_system_attributes=true`;
		}
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/concepts${queryString}`);
	}

	public deleteConcept(deleteData): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${deleteData.user_id}/catalogs/${deleteData.catalog_id}/semantics/${deleteData.object_id}/concepts/${deleteData.attribute_id}`);
	}
	
	public saveConcept(userId, catalogId, semanticId, payload: any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/semantics/${semanticId}/concepts`, payload);
		// return this.http.post(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/defineconcepts`, payload);
	}

	public editConcept(userId, catalogId, semanticId, attributeId, payload: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/semantics/${semanticId}/concepts/${attributeId}`, payload);
		// return this.http.post(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/defineconcepts`, payload);
	}

	saveCatalog(catalogId, payload: any): Observable<any> {
		if (catalogId === undefined || catalogId === null) {
			// return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs`, payload, { observe: 'response' });
			return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs`, payload);
		} else {
			return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/` + catalogId, payload);
		}
	}

	public getCatalogIcons(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=CatalogIcons`);
	}

	removeMappedColumns(datasetClassifyId): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/${datasetClassifyId}`);
	}

	removeMappedColumn(payload): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/vote?type=down_vote`, payload);
	}

	runModelClassify(datasetId, body): Observable<any> {
		// return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/datasets/${datasetId}/classify`, body);
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify`, body);
	}

	runModelClassify1(body): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify`, body);
	}
	
	runModel(datasetId, jobId): Observable<any> {	
		return this.http.post(`${this.apiUrl}/algos/301`, { 'dataset_id': datasetId, 'job_id': jobId });
	}
	runModelMapDataColumn(datasetId, jobId): Observable<any> {
		let api_url = `${this.apiUrl}/algos/303`;
		if (this.engine_type_id == 452) {
			api_url = `${this.apiUrl}/algos/406`;
		}
		return this.http.post(api_url, { });
	}

	public getSynonyms(): Observable<any> {
		return this.http.get(`./assets/synonyms.json`);
	}

	public getDatasources(data: any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/datasources`);
	}

	public getDatasets(data: any,serverFilter="",sortFilter=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/datasets?pagination=true&pageno=${data.page}&pagesize=${data.limit}${serverFilter}${sortFilter}`);
	}

	public getConceptsDatasets(data: any, serverFilter="",sortfilter=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/mappedcolumns?pagination=true&pageno=${data.pageNo}&pagesize=${data.limit}${serverFilter}${sortfilter}`);
		// return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/datasets`);
		// return this.http.get('../../../assets/objectsTrainingModel.json');
	}

	public getHighConceptsDatasets(data: any,serverFilter="",sortfilter=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/mappedcolumns?pagination=true&pageno=${data.pageNo}&pagesize=${data.limit}&high_confidance_only=true${serverFilter}${sortfilter}`);
	}

	public getBusinessRulesAppliedColumns(data: any,serverFilter="",sortfilter=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/mappedcolumns?high_confidance_only=true&attributeIds=${data.attribute_ids}&pagination=true&pageno=${data.pageNo}&pagesize=${data.limit}${serverFilter}${sortfilter}`);
}
	
	public getAllConcept(data: any,serverFilter="",sortfilter=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/techview${data.concept}`);
	}
	public getTrainSemanticsLevel(data: any, ignoreSystemAttributes): Observable<any> {
		let queryParams = '';
		let ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '?ignore_system_attributes=true' : '' ;
		if (data.is_multi_attribute) {
			queryParams = `?attribute_ids=${data.attribute_ids}`;
		}
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/mappings${queryParams}${ignoreSystemAttributeQueryParams}`);
		// return this.http.get('../../../assets/objectsTrainingModel.json');
	}

	public getTrainConceptsLevel(data: any,ignoreSystemAttributes): Observable<any> {
		let ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '?ignore_system_attributes=true' : '' ;
		let queryParams = '';
		if (data.is_multi_attribute) {
			queryParams = `?attribute_ids=${data.attribute_ids}`;
		}
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/mappings${queryParams}${ignoreSystemAttributeQueryParams}`);
		// return this.http.get('../../../assets/objectsTrainingModel.json');
	}

	public getSynonymTags(data: any):Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/synonyms?pagination=true&pageno=${data.page}&pagesize=${data.limit}`);		
	}

	public getSynonymConceptTags(data: any):Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/synonyms?pagination=true&pageno=${data.page}&pagesize=${data.limit}`);		
	}
	removeObjectOfListMapping(data, payload: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/mappings`, payload);
	}

	downVoteMapping(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote?type=down_vote`, data.payload);
	}

	upvotetMapping(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote?type=up_vote`, data.payload);
	}
	
	public getConceptById(catalogId, objectId, conceptId){
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts/${conceptId}`);		
	}

	public getClassificationTags(data: any){		
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/columns/${data.column_id}/tags`);		
	}
	
	public getSearchClassificationTags(data: string){
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/attributes?physical_name=${data}`);		
		// return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/attributes?logical_name=${data}`);	
		// return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/attributes?display_name=${data}`);
	}

	public getMappedConcepts(catalogId, objectId,pageNo,limit): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts?only_predicted=true&pagination=true&pageno=${pageNo}&pagesize=${limit}`);
	}

	public runModel301(datasetId: any): Observable<any> {
		let api_url = `${this.apiUrl}/algos/301`;
		if (this.engine_type_id == 452) {
			//api_url = `${this.apiUrl}/algos/406`;
		}
		return this.http.post(api_url, { 'dataset_id': datasetId });
	}
    public runModel303(): Observable<any> {
		let api_url = `${this.apiUrl}/algos/303`;
		if (this.engine_type_id == 452) {
			api_url = `${this.apiUrl}/algos/406`;
		}
		return this.http.post(api_url, { });
	}

	public runModel301Empty(): Observable<any> {
		return this.http.post(`${this.apiUrl}/algos/301`, { });
	}
	
	upVote(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote?type=up_vote`, data.payload);
	}

	downVote(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote?type=down_vote`, data.payload);
	}

	getEntityDiagram(user_id,object_id: any,catalog_id:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${user_id}/catalogs/${catalog_id}/semantics/${object_id}/er-diagram`, { responseType: 'blob' });
	}
	public saveDatasetMapping(data): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/entity-mapping`, data.payload);
	}
	public getMappingAttributes(semanticIds, handleForLargeQueryParams?: boolean, reqBody?: any, ignoreSystemAttributes?:boolean): Observable<any> {
		let ignoreSystemAttributeQueryParams = ignoreSystemAttributes ? '&ignore_system_attributes=true' : '' ;
		if (handleForLargeQueryParams) {
			return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/concepts/retrieve_concepts?include_semantic=true&group_results_by=whole${ignoreSystemAttributeQueryParams}`, reqBody);
		}
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/concepts?include_semantic=true&group_results_by=whole&semantic_ids=${semanticIds}${ignoreSystemAttributeQueryParams}`);
	}
	public getMultiEntityMappedAttributes(data: any, handleForLargeQueryParams?: boolean, reqBody?: any): Observable<any> {	
		if (handleForLargeQueryParams) {
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/mappings/retrieve_semantic_mappings`,reqBody);		
		}	
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/mappings?semantic_ids=${data.object_id}`);		
	}

	public getProjectsData(userId, tenantId,project_id, pageNo, limit): Observable<any> {
		const tenantDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets?pagination=true&pageno=${pageNo}&pagesize=${limit}`);
		const projectDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${project_id}/projectsdata?pagination=true&pageno=${pageNo}&pagesize=${limit}`);
		return forkJoin([tenantDs,projectDs]);
	}
	public getMappedProjectData(userId, tenantId,project_id, pageNo, limit, serverFilter='', sortfilter=''): Observable<any> {
		const tenantDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets?pagination=true&checkAtrributes=true&pageno=${pageNo}&pagesize=${limit}${serverFilter}${sortfilter}`);
		const projectDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${project_id}/projectsdata?pagination=true&pageno=${pageNo}&pagesize=${limit}${serverFilter}${sortfilter}`);
		return forkJoin([tenantDs,projectDs]);
	}
	public saveProjectsData(data): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${data.data.userId}/tenants/${data.data.tenantId}/classify/projects/${data.data.id}/projectsdata`, data.data.payload);
	}

	public getMappedDatasets(data): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.data.userId}/tenants/${data.data.tenantId}/classify/projects/${data.data.id}/projectsdata`);
	}

	public removeProjectsData(data): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${data.data.userId}/tenants/${data.data.tenantId}/classify/projects/${data.data.id}/projectsdata`, data.data.payload);
	}
	public saveVoteSemanticObject(data: any,voteType:string): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote/semantics?type=${voteType}`, data.payload);
	}
	public getClassificationOjectTags(data: any):Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/semantics`);		
	}
    public saveClassifire(data:any):Observable<any>{
		if(data.payload.attribute_id){
			return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/attributes`, data.payload);
		}else{
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/attributes`, data.payload);
		}
		
	}
	public getClassifireSource(data:any):Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?pagination=true`);			 
		const extingSource = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source`);
		return forkJoin([tenantDs, extingSource]);
	}
	public getClassifireDataSource(data:any):Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?pagination=true&checkAtrributes=true`);			 
		const extingSource = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source`);
		return forkJoin([tenantDs, extingSource]);
	}
	public saveClassifireSource(data:any,classify_project_source_id:string):Observable<any>{
		if(classify_project_source_id){
			return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source/${classify_project_source_id}`, data.payload);
		}else{
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source`, data.payload);
		}
        
	}
	public getClassifireColumn(data : any):Observable<any>{
		const columnDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/columns`);	
		const existingColumnDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source/${data.source_id}/column`);
		return forkJoin([columnDs,existingColumnDs]);
	}
	public saveClassifireColumn(data : any,classify_project_source_column_id:string):Observable<any>{
		if(classify_project_source_column_id){
			return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source/${data.source_id}/column/${classify_project_source_column_id}`, data.payload);
		}else{
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/source/${data.source_id}/column`, data.payload);
		}
		
	}
	public getClassificationType(): Observable<any> {
        return this.http.get(`${this.apiUrl}/lookups?group=ClassificationProjectType`);
    }

	public createClassificationProject(data:any, project_id:string=""): Observable<any> {	
        if(project_id){
			return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${project_id}`, data.payload);	
		}else{
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects`, data.payload);
		}		
	}

	public getCurrentActiveJobs(userId, selectedProjectId,object_type_id): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/track?object_type_id=${object_type_id}&object_id=${selectedProjectId}`);
    }

	saveProject(project): Observable<any> {
        if (project.project_id === undefined || project.project_id === null) {
            return this.http.post(`${this.apiUrl}/users/` + project.user_id + `/projects`, project, { observe: 'response' });
        } else {
            return this.http.put(`${this.apiUrl}/users/` + project.user_id + `/projects/` + project.project_id, project);
        }
    }

	public hasActiveJob(job): boolean {
        if (job && (job.job_completed || job.job_completed === undefined || (!job.job_completed && job.is_errored))) {
            return false;
        } else {
            return true;
        }
    }

	public getUsersTenant(userId: string, projectId: String, tenantid: string) {
        const usersTenant = this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/users`);
        const projectUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantid + `/classify/projects/` + projectId + `/users/`);
        const projectResponse = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantid}/classify/projects/${projectId}`);
        return forkJoin([usersTenant, projectUsers, projectResponse]);
    }

	public getLeftUsers(userId: string, tenantid: string) {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/users`);
	}

	public getRuleUsers(userId: string, tenantid: string, ruleId:any) {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/dataquality/rules/${ruleId}/users`);
	}

	public saveDqUsers(userId: string, tenantid: string, ruleId, payload) {
        return this.http.post(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/dataquality/rules/users`, payload);
	}

	public updateDqUsers(userId: string, tenantid: string, payload) {
        return this.http.put(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/dataquality/rules/users/${payload.user_id}`, payload);
	}

	public removeDqUsers(userId: string, tenantid: string, payload: any) {
        return this.http.delete(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/dataquality/rules/users`, payload);
	}

	saveUserRoles(userId: string, tenantId: string, projectId: string, user): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/classify/projects/` + projectId + `/users`, user);
	}
	public getTrainingInputData(data:any, serverFilter='',serverSort=''): Observable<any>{
		const concept = this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts?pagination=true&pageno=${data.pageno}&pagesize=${data.pagesize}${serverFilter}${serverSort}&ignore_system_attributes=true`);
		const trainingInput = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-input?pagination=true&pageno=${data.pageno}&pagesize=${data.pagesize}${serverFilter}${serverSort}`);        
		return forkJoin([concept,trainingInput]);
	}
	public saveTrainingInput(data:any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-input`, data.payload);
	}
	public removeTrainingInput(data:any): Observable<any> {	
		return this.http.delete(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-input`, data.payload);
	}

	public getClassifierTrainigData(data:any): Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets`);
		const trainigData = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-data?pagination=true`);
		return forkJoin([tenantDs, trainigData]);
	}

	public getClassifierProjectTrainigData(data:any, serverFilter='', serverSort=''): Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?checkAtrributes=true${serverFilter}${serverSort}`);
		const trainigData = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-data?pagination=true`);
		return forkJoin([tenantDs, trainigData]);
	}

	public getConceptTrainigData(data:any): Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets`);
		const trainigData = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/trainingdatasets?pagination=true`);
		return forkJoin([tenantDs, trainigData]);
	}

	public getConceptsProjectTrainigData(data:any): Observable<any>{
		const tenantDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?checkAtrributes=true`);
		const trainigData = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/trainingdatasets`);
		return forkJoin([tenantDs, trainigData]);
	}

	public saveClassifireTrainingData(data: any): Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-data`,data.payload);
	}
	public removeClassifireTrainingData(data: any): Observable<any>{
		return this.http.delete(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training-data`,data.payload);
	}
    public getTenantDatasets(data: any): Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets`);
	}
	public getclassifyProject(data:any): Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}`);
	}


	public updateSelectObject(data): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.id}`, data.payload);
	}
	public getAllClassifyProject(data:any, serverFilter='', sortfilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects?pagination=true&pageno=${data.pageno}&pagesize=${data.pagesize}${serverFilter}${sortfilter}`);
	}
	public getClassifyProject(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}`);
	}

	public getEntityModalRuns(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/modelruns`);
	}
	public getConfidenceModalSummary(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/summary`);
	}
	public getClassifyRecords(userId, tenantId, selectedProjectId, pageno, perpage,serverFilter='',serverSort=''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/classify/projects/` + selectedProjectId + `/viewresults` + `?pageno=${pageno}&pagesize=${perpage}${serverFilter}${serverSort}`)
    }

	public getClassifyPredictions(userId, tenantId, selectedProjectId,predictionID,selectedDatasetId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/classify/projects/` + selectedProjectId + `/viewresults` + `?ids=${predictionID}&dataset_id=${selectedDatasetId}`)
    }
	public getStatisticsSummary(data:any): Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/summary?summaryfor=statistics`);	
	}
	public getClassifyProjGraphSummary(data:any): Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/dashboard/statistics`);	
	}
	public getTenantDataSources(data:any): Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets?pagination=true`);	
	};
	public getDatasetCoulumns(data:any): Observable<any>{
		const columnDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/columns`);	
		const existingColumnDs = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/datasets/${data.dataset_id}/columns`);	
		return forkJoin([columnDs,existingColumnDs]);
	}
	public updateTaskFeedback(info) : Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback`, info.payload);
    }
	public updateClassIfySocTaskFeedback(info) : Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback/updatetask`, info.payload);
    }
	updateClassIfySocTaskHistoryFinishFeedback(info) : Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback/${info.payload.classify_project_feedback_id}/updatefeedbackhistory?project_type=14862`, info.payload);
    }
	public approveClassIfySocTaskFeedback(info) : Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback/capturelog`, info.payload);
    }
	runModelClassifyProject(payload:object): Observable<any> {
        let api_url = `${this.apiUrl}/algos/500`;
        if (this.engine_type_id == 451) {
            api_url = `${this.apiUrl}/algos/307`;
        }
        return this.http.post(api_url, payload);
    }

	runModelClassifyProjectAddData(payload:object, project_type): Observable<any> {
		let api_url ='';
		if(this.engine_type_id == 451 && project_type== 14862){ 
			api_url = `${this.apiUrl}/algos/307`;
		}
		if(this.engine_type_id == 451 && project_type== 14863){
			api_url = `${this.apiUrl}/algos/310`;
		}
		if(this.engine_type_id == 452 && project_type== 14862){
			api_url = `${this.apiUrl}/algos/500`;
		}
		if(this.engine_type_id == 452 && project_type== 14863){
			api_url = `${this.apiUrl}/algos/412`;
		}

        // let api_url = `${this.apiUrl}/algos/500`;
		// if (this.engine_type_id == 451) {
        //     api_url = `${this.apiUrl}/algos/307`;
        // }
		// if ( project_type === 14862 ) {			
		// 	if (this.engine_type_id == 451) {
		// 		api_url = `${this.apiUrl}/algos/303`;
		// 	}

		// 	if (this.engine_type_id == 452) {
		// 		api_url = `${this.apiUrl}/algos/406`;
		// 	}
		// } else if(project_type === 14863) {
		// 	if (this.engine_type_id == 451) {
		// 		api_url = `${this.apiUrl}/algos/310`;
		// 	}
		// 	if (this.engine_type_id == 452) {
		// 		api_url = `${this.apiUrl}/algos/412`;
		// 	}
		// }
        return this.http.post(api_url, payload);
    }
	public deleteProject(data:any): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}`, { observe: 'response' });		
	}
	public cloneProject(data:any, info): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/copy`, info);		
	}
	public getClassifireName(data : any):Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/attributes`);
	}
	public getAllTags(data : any):Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/semantictags/?paginate=false`);
	}
	public addTagToConcept(data : any):Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/tags`,data.payload);
	}
	public addNewTag(data : any):Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/semantictags`,data.payload);
	}
	public removeTagToConcept(data : any):Observable<any>{
		return this.http.delete(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.object_id}/concepts/${data.concept_id}/tags/${data.semantic_concept_tag_id}`);
	}
	public getSchedulerFrequency(): Observable<any> {
		return this.http.get(`${this.apiUrl}/lookups?group=SchedulerFrequency`);
	}
	 public addSchedulerFrequency(userId, tenantId, dqid, data): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${dqid}/schedules`, data);
	}
	public updateSchedulerFrequency(userId, tenantId, dqid, data): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${dqid}/schedules`, data);
	}
	// public getDataQualityRules(userId, tenantId, type): Observable<any> { 
    //     return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/dataquality/rules?type=${type}&pagesize=all`)
    // }
	// public getDataQualityRules(userId, tenantId, type, page: number, limit: number, serverFilter = '', serverSort = ''): Observable<any> { 
    //     return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/dataquality/rules?pagination=true&type=${type}&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`)
    // }
	public getDataQualityRules(userId, tenantId, datasetId, type, page: number, limit: number, serverFilter = '', serverSort = ''): Observable<any> { 
		if(!datasetId || datasetId == null) {
        	return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/dataquality/rules?pagination=true&type=${type}&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`)
		} else {
			return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/datasets/` + datasetId + `/dqrules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`)
		}
    }
	public getDataQualityRule(userId, tenantId, ruleId): Observable<any> { 
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantId + `/dataquality/rules/` + ruleId)
    }
	public getDataQualityDimensions(): Observable<any> { 
        return this.http.get(`${this.apiUrl}/lookups?group=DataQualityDimensions`)
    }
	public getDataQualitySequenceRuleTypes(view): Observable<any> { 
        return this.http.get(`${this.apiUrl}/lookups?group=DataQuality` + view + `SequenceRuleTypes`)
    }
	public getDataQualityValueTypes(): Observable<any> { 
        return this.http.get(`${this.apiUrl}/lookups?group=DataQualityValueTypes`)
    }
	public getDataQualityOperators(): Observable<any> { 
        return this.http.get(`${this.apiUrl}/lookups?group=DataQualityOperators`)
    }
	public saveDataQualityRules(userId, tenantId, type, payload: any): Observable<any> { 
        return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules?type=${type}`, payload)
    }
	public updateBusinessDataQualityRules(userId, tenantId, ruleId, payload: any): Observable<any> { 
        return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${ruleId}`, payload)
    }

	public updateTechnicalDataQualityRules(userId, tenantId, ruleId, payload: any): Observable<any> { 
        return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${ruleId}?type=technical`, payload)
    }
	public getMeasuredRule(dqScore, enable_dq_rule: boolean): object {
		let fixed = Math.pow(10, 2);
		if (enable_dq_rule) {
			const scoreText = dqScore != 'n/a' ? Math.floor(dqScore * fixed) / fixed : dqScore;
			const ruleCss = scoreText >= 75 ? 'priorityCell high' : (scoreText >= 50 && scoreText < 75) ? 'priorityCell medium' : 'priorityCell low';
			const measured_rule_css = dqScore != 'n/a' ? ruleCss : 'disable-rule'
			const dqScoreText = dqScore != 'n/a' ? `${scoreText}%` : dqScore;
			return { dqRuleText: dqScoreText, dqRuleCss: measured_rule_css }
		} else {
			return { dqRuleText: 'n/a', dqRuleCss: 'disable-rule' }
		}
	}

	public getDatasetRules(userId, tenantId, datasetId, pagination): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/dqrules?pagination=${pagination}`);
	}

	public getDQSummarySemanticLevel(userId, catalogId, semanticId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/semantics/${semanticId}/dataquality/summary`);
	}

	public getDQRulesSemanticLevel(catalogId, semanticId, page:number,limit:number, serverFilter='', serverSort=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${semanticId}/dataquality/rules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
	}

	public getDQDimensions(userId, catalogId, semanticId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/semantics/${semanticId}/dataquality/dimensionview`);
	}
	
	public getRulesByConcept(catalogId:string, objectId:string, conceptId:string,page:number,limit:number, serverFilter='', serverSort=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts/${conceptId}/dataquality/rules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
	}
	public getDimentionsByConcept(catalogId:string, objectId:string, conceptId:string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts/${conceptId}/dataquality/dimensionview`);
	}
	public getExecutionSummaryByConcept(catalogId:string, objectId:string, conceptId:string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/concepts/${conceptId}/dataquality/summary`);
	}

	public getDQDimensionsByCatalog(userId, catalogId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/catalogs/${catalogId}/dataquality/dimensionview`);
	}

	public getRulesByCatalog(catalogId:string, page:number,limit:number, serverFilter = '', serverSort = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/dataquality/rules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
	}

	public getExecutionSummaryByCatalog(catalogId:string): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/dataquality/summary`);
	}

	public getUsers(ruleId) {
        return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules/${ruleId}/users`);
    }
	public reRunRule(rule_id, rule_mode, is_mapping_changed = false): Observable<any> {
		let api_url='';
		if(rule_mode=='business'){
			 api_url = `${this.apiUrl}/algos/309`;
			if (this.engine_type_id == 452) {
				api_url = `${this.apiUrl}/algos/411`;
			}
		}
		if(rule_mode == 'technical'){
			api_url = `${this.apiUrl}/algos/308`;
			if (this.engine_type_id == 452) {
				api_url = `${this.apiUrl}/algos/410`;
			}
		}
		
		if(rule_mode == 'business' && api_url == `${this.apiUrl}/algos/411` && is_mapping_changed) {
			return this.http.post(api_url, {data_quality_rule_id:rule_id, isMappingChanged: true });
		} else {
			return this.http.post(api_url, {data_quality_rule_id:rule_id, isMappingChanged: false });
		}
	}
	
	public getRuleException(page, limit, rule_id, isDatasetLevel=false, dataset_id=null, serverFilter, serverSort) : Observable<any>{
		let queryParams = '';
		if (isDatasetLevel) {
			queryParams = `&dataset_id=${dataset_id}`;
		}
	  return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules/exceptions?rule_id=${rule_id}&pagination=true&pageno=${page}&pagesize=${limit}${queryParams}${serverFilter}${serverSort}`);	
	}
	
	public saveOutOfBoxRule(payload) : Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules?type=default`,payload);		
	}
	public deleteDataQualityRule(payload) : Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules/delete`,payload);		
	}
	public getTimeZones(): Observable<any> {
		return this.http.get(`./assets/timezones.json`);
	}
	public getSchedulerDetailsById(userId, tenantId, ruleId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${ruleId}/schedules`);
	}

	public saveSelectedFeedBack(payload,project_id,feedbackType): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/feedback?project_type=14863&is_training_task_feedback=${feedbackType}`,payload);		
	}
	public getCharacteristicList(project_id,classifier): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/feedback/classes?project_type=14863&name=${classifier}`);		
	}
	public getPredictionList(project_id, record_id): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/viewresults/${record_id}?project_type=14863`);		
	}
	public getSubConceptList(project_id,record_id): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/training/feedback?id=${record_id}`);		
	}
	public getParseRecords(userId, tenantId, classifyProjectId, pageno, perpage, serverFilter = '', serverSort = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${classifyProjectId}/viewresults?project_type=14863&pageno=${pageno}&pagesize=${perpage}${serverFilter}${serverSort}`);
    }
	public getParsingPrediction(userId, tenantId, classifyProjectId, idParsing, pageno, perpage, serverFilter = '', serverSort = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${classifyProjectId}/viewresults/${idParsing}/pedictions?project_type=14863&pageno=${pageno}&pagesize=${perpage}${serverFilter}${serverSort}`);
    }
	public getSubConcept(userId, tenantId, classifyProjectId, idParsing, subConcept, serverFilter = '', serverSort = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${classifyProjectId}/viewresults/${idParsing}/pedictions/${subConcept}?project_type=14863${serverFilter}${serverSort}`);
    }

	public runConceptParserProject(payload:object): Observable<any>  	{
		let url;
		if(this.engine_type_id == 451) {
			url = `${this.apiUrl}/algos/310`;
		} else if (this.engine_type_id == 452) {
			url = `${this.apiUrl}/algos/412`;
		}
		return this.http.post(url, payload);
    }

	public deleteFeedback(classifyProjectId, feedback_id): Observable<any> {		                                                                                       
        return this.http.delete(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}
		/classify/projects/${classifyProjectId}/training/feedback?classify_project_concept_parser_feedback_id=${feedback_id}`);
    }

	public saveSelectedConceptParsed(project_id, data): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/concepts`, data);
	}

	public getProjectConceptParsed(classifyProjectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${classifyProjectId}/concepts`);
    }
	public getObjectClassifier(catalogId, objectId, pageNo, limit,serverfilter,sortfilter=''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/semantics/${objectId}/classifiers?paginate=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}&ignore_system_attributes=true`);
	}
	public saveObjectClassifier(catalogId, objectId, project_id, payload): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/semantic_object_classifier`, payload);
	}
	public getSubConceptSource(project_id, concept_id, pageNo, limit, serverfilter, sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/subconceptsources?concept_id=${concept_id}&paginate=true&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}
	public saveSubConceptSource(project_id, payload): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/subconceptsources`, payload);
	}

	public saveTrainDatasetColumns(project_id, payload): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/classify/projects/${project_id}/trainingdatasets`, payload);
	}
	
	public getDQRsummary(userId, tenantId, ruleId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${ruleId}/summary`);
    }

	public saveReferenceData(userId, tenantId, projectId, data): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/referencedata`, data);
	}

	public getReferenceData(userId, tenantId, projectId, page, limit, serverFilter = '', serverSort = ''): Observable<any>{
		const columnDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/referencedata?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
		const existingColumnDs = this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projectId}/referencedata?getReferencedataById=true`);	
		return forkJoin([columnDs,existingColumnDs]);
	}

	public getDQRunModel(data:any, dsDQRulequeryParams=""): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/dataquality/rules/${data.rule_id}/runmodel${dsDQRulequeryParams}`);
	}
	public getModalRuns(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/dataquality/rules/${data.rule_id}/runmodel`);
	}

	public getCatalogSummaryGraph(data:any, level): Observable<any> {
		let apiUrl;
		if(level == 'catalog') {
			apiUrl =  `${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/dataquality/rules/history`;
		} else if(level == 'object') {
			apiUrl =  `${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.semantic_id}/dataquality/rules/history`;
		} else {
			apiUrl =  `${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.semantic_id}/concepts/${data.concept_id}/dataquality/rules/history`;
		}
		return this.http.get(apiUrl);
	}

	public getSemanticModalRuns(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.semantic_id}/dataquality/rules/${data.rule_id}/runmodel`);
	}
	public getConceptModalRuns(data:any): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.semantic_id}/concepts/${data.concept_id}/dataquality/rules/${data.rule_id}/runmodel`);
	}
	public saveSynonymsMapping(catalogId, payload){
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/synonyms`,payload);		
	}

	public saveSynonymLevel(tenant_id, payload){
		return this.http.post(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${tenant_id}/synonyms/feedback`,payload);		
	}
	public getSynonymsMapping(catalogId, queryParams){
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/synonyms?${queryParams}`);		
	}
	public deleteSynonymsMapping(catalogId, payload){
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/synonyms?delete_mapping=true`,payload);		
	}
    public updateChangeMappings(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules/${data.rule_id}/mapping`, data.payload);
	}
	public updateRunExecutor(data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${this.getObjectFromSession(AppGlobals.USER_INFO).tenant_id}/dataquality/rules/${data.rule_id}/runexecutor`, data.payload);
	}
	public getSynonymsPaged(catalogId, queryParams, page, limit, serverFilter, serverSort) {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/synonyms?pagination=true&${queryParams}&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
	}

	public getCatalogUser(catalogId) : Observable<any>{
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${catalogId}/users`);		
	}

	public getDQDatasetRules(user_id, tenant_id, dataset_id, column_id, page, limit, serverFilter = '', serverSort = ''): Observable<any> {
		if (column_id) {
			return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/columns/${column_id}/dqrules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
		} else {
			return this.http.get(`${this.apiUrl}/users/${user_id}/tenants/${tenant_id}/datasets/${dataset_id}/dqrules?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${serverSort}`);
		}
	}

	public getDQSummaryAtDatasetLevel(userId, tenantId, datasetId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/dqrules/summary`);
    }

	public getDQSummaryAtColumnLevel(userId, tenantId, datasetId, columnId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/datasets/${datasetId}/columns/${columnId}/dqrules/summary`);
    }

	public exportResult(apiUrl): Observable<any> {
		return this.http.get(`${apiUrl}`,{responseType: 'text'});
	}
	
	public exportCsvFiles(data, fileName) {
		var a = document.createElement("a");
		document.body.appendChild(a);
		const blob = new Blob([data], { type: 'text/csv' });
		let url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = `${fileName}_${Date.now()}.csv`;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	public exportTechDqRule(data: any) {
		return `${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/dataquality/rules/${data.ruleType}/import`;
	}
	
	public validateRunModelIcon(userId, catalog_id): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/catalogs/${catalog_id}/activaterunmodel`);
	}
	public runBulkDQRule(payload: any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${payload.user_id}/tenants/${payload.tenant_id}/dataquality/rules/run?rule_type=${payload.rule_type}`, payload.rules);
	}

	public bulkVoteUpdate(userInfo, data: any): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${userInfo.user_id}/tenants/${userInfo.tenant_id}/classify/vote`, data);
	}
	
	public cloneDQRule(userId, tenantId, ruleId): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/${ruleId}/copy`, { });
	}

	public getSemanticDatasetsByEntity(userId, tenantId, entityId) {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/semanticdatasets?entityId=${entityId}`);
	}
	
	public updateSemanticDatasets(userId, tenantId, data) {
		return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/semanticdatasets`, data);
	}

	publishAllSemanticDatasets(payloadData) {
        let api_url = `${this.apiUrl}/algos/`;
        if (this.engine_type_id == 451) {
            api_url = `${this.apiUrl}/algos/315`;
        } else if(this.engine_type_id == 452) {
            api_url = `${this.apiUrl}/algos/418`;
        }
        return this.http.post(api_url, payloadData);
    }

    publishSemanticDatasetPipelines(payloadData) {
        let api_url = `${this.apiUrl}/algos/`;
        if (this.engine_type_id == 451) {
            api_url = `${this.apiUrl}/algos/302`;
        } else if(this.engine_type_id == 452) {
            api_url = `${this.apiUrl}/algos/405`;
        }
        return this.http.post(api_url, payloadData);
    }

	public getReferenceDataForConcept(entityId, attribute_id): Observable<any> {
		return this.http.get(`${this.apiUrl}/entities/${entityId}/attributes/${attribute_id}/referencedata`);
	}
	public getUpVotedUsersByConcept(payload, pageNo, limit, serverfilter = '', sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${payload.catalog_id}/semantics/${payload.object_id}/concepts/${payload.concept_id}/mappedcolumns/${payload.column_id}/history?pagination=true&for=up_vote&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}

	public getDownVotedUsersByConcept(payload, pageNo, limit, serverfilter = '', sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${payload.catalog_id}/semantics/${payload.object_id}/concepts/${payload.concept_id}/mappedcolumns/${payload.column_id}/history?pagination=true&for=down_vote&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}

	public getUpVotedUsersBySemanticObject(payload, pageNo, limit, serverfilter = '', sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${payload.catalog_id}/semantics/${payload.object_id}/datasets/${payload.dataset_id}/history?pagination=true&for=up_vote&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}

	public getDownVotedUsersBySemanticObject(payload, pageNo, limit, serverfilter = '', sortfilter = ''): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/catalogs/${payload.catalog_id}/semantics/${payload.object_id}/datasets/${payload.dataset_id}/history?pagination=true&for=down_vote&pageno=${pageNo}&pagesize=${limit}${serverfilter}${sortfilter}`);
	}

	public getGroupScheduler(group): Observable<any> {
		//group: Timezones / Weekdays / SchedulerFrequency / ObjectTypeId
		return this.http.get(`${this.apiUrl}/lookups?group=${group}`);
	}

	public getSchedulerByObjectId(userId, tenantId, objectTypeId, objectId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/scheduler?objectTypeId=${objectTypeId}&objectId=${objectId}`);
	}

	public createUpdateScheduler(userId, tenantId, objectTypeId, payload): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/scheduler?objectTypeId=${objectTypeId}`, payload);
	}
   
	public reProfileDatasetStage2(payload) {
		let api_url = '';
		if (this.engine_type_id === 452) {
			api_url = `${this.apiUrl}/algos/404`;
		}
		if (this.engine_type_id === 451) {
			api_url = `${this.apiUrl}/algos/300`;
		}
		return this.http.post(api_url, payload);
	}

	public getMappings(data): Observable<Object> {
		let multiAttributeQuery = '';
		if (data.attributeIds) {
			multiAttributeQuery = `&attributeIds=${data.attributeIds}`;
		}
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.entity_id}/mappedcolumns?high_confidance_only=true&datasetIds=${data.dataset_ids}${multiAttributeQuery}`);
	}

	public getMappingsForConcept(data): Observable<Object> {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.entity_id}/concepts/${data.concept_id}/mappedcolumns?high_confidance_only=true&datasetIds=${data.dataset_ids}`);
	}
	
	public getMappingsForEntities(data,handleForLargeQueryParams?: boolean, reqBody?: any): Observable<Object> {
		if (handleForLargeQueryParams) {
			return this.http.post(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/mappedcolumns/retrieve_mapped_columns?high_confidance_only=true&datasetIds=${data.dataset_ids}`, reqBody);
		}
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/mappedcolumns?high_confidance_only=true&datasetIds=${data.dataset_ids}&semanticIds=${data.semanticIds}`);
	}

	public taskReviewer(userId, tenantId, taskId, taskTypeId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/taskReviewer?taskId=${taskId}&task_type_id=${taskTypeId}`);
	}

	public finishTaskOfCatalog(data: any): Observable<any> {
		return this.http.post(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/jobs/feedback`, data.payload);
	}

	public getClassifyProjectFeedbacks(userId, tenantId, projId, pageno, limit): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/feedback?paginate=true&pageno=${pageno}&pagesize=${limit}&is_manual_only=true`);
	}

	public getClassifierPredictions(userId, tenantId, projId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/governance/class`);
	}

	public getClassifierClassList(userId, tenantId, projId): Observable<any> {
		return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/class?is_predicted_class_only=true`);
	}

	public updatePredictionClasses(userId, tenantId, projId, payload): Observable<any> {
		return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/governance/class`, payload);
	}

	public getClassifySummaryData(data, pageno?, limit?) {
		const projDetails = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}`);
		const feedbacks = this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/feedback?paginate=true&pageno=${pageno}&pagesize=${limit}&is_manual_only=true`);
		return forkJoin([projDetails, feedbacks]).pipe(map(([projDetails, feedbacks]) => ({projDetails, feedbacks})));
	}
	
}
