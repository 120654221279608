import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'jsonFilter'
})
export class JsonFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
        if (!filter) {
            return items;
        }

        if (!Array.isArray(items)) {
            return items;
        }

        if (filter && Array.isArray(items)) {
            const keyName = Object.keys(filter[0])[0];
            return items.filter(item => {
                return new RegExp(filter[0][keyName], 'gi').test(item[keyName]);
            })[0].value;
        }
    }
}