import { Project } from '../../../zettasense/content/project/project.model';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { HttpClient } from '@angular/common/http';
import { DatasourceService } from '../../../common/components/datasources/datasource.service';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { MessageService } from '../../../common/components/message/message.service';
import { IDataset } from '../../../common/components/datasets/dataset.model';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { ZsClContentService } from "../../../zettaclassify/zs-cl-content/zs-cl-content.service";
declare var $: any;

const RIGTH_EMPTY_GRID_MSG = 'Select a data source from the left or upload a file from your local hard drive.';
const LEFT_EMPTY_GRID_MSG = 'No data sources.';
@Component({
  selector: 'zetta-tm-select-data-sets',
  templateUrl: './tm-select-data-sets.component.html',
  styleUrls: ['./tm-select-data-sets.component.scss'],
  //encapsulation: ViewEncapsulation.None
})

export class TmSelectDataSetsComponent implements OnInit, OnDestroy {

    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;
    datasetList;

    tableSettings: object = { 'height': "100%", 'width': "99.37%", 'pageSize': 100, 'isNormalPagination': false };
    rightTableSettings: object = { 'height': "100%", 'width': "99.37%", 'pageSize': 100, 'isNormalPagination': false };
    modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
    loggedInUserDetails: [];
    datasourceId: number;
    datasourceName: string;
    enableUploadBtn = false;
    selectedLeftGridRowsSize = 0;
    selectedRightGridRowsSize = 0;
    dataset: any = {};
    showTab: string;
    newDatasetSel: any = {};
    newDataset: any = {};
    errorMessage = undefined;
    public dataset_id;
    dataRes;
    is_upload_complete = false;
    showDelimiter = true;
    dataSelected = -1;
    isDataSelected = false;
    dataSourceSelected = -1;


    catalog_id;
    object_id;
    concept_id;
    attributeId;
    dataset_classify_ids: any = [];
    dataset_ids: any = [];
    no_matches: string = '';
    datasets: string = '';;

    datasetsSelect = '';
    datasourceSelect = '';

    // public datasets: any = [];
    public grid: AngularGridInstance;
    hasScrolled = false;
    isLoading = true;
    total = 0;
    grandTotal = 0;
    subTotal = '';
    page = 1;
    limit = 100;
    noData = false;

    modalRowData: any;
    modalGridOptions: any;
    modelColumnDef = [];
    isSampleDsLoading = true;
    public modalGrid: AngularGridInstance;
    url;
    safeSrc: SafeResourceUrl;
    dsName;
    showmodelpopup=false;
    datasetId:any;
    showNoDataImg = true;
    @Input() data_rule;
    @Input() train_dataset;
    hasDatasourceSelected=false;
    hasRightDataSelected=false;
    dq_rule_level:string;
    mappedDataSets = [];
    manageData=false;
    project_id:number;
    project_type:string;
    project_type_id:number;
    backTo:string;
    classify_cp_training_datasets_ids = [];
    is_clone = false;
    rule_id;
    isSkipedMappedColumn: Boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        public zettaUtils: ZettaUtils,
        private http: HttpClient,
        public datasourceSvc: DatasourceService,
        public datasetSvc: DatasetService,
        private projectService: ProjectService,
        private sanitizer: DomSanitizer,
        private baseService: BaseService,
        private messageSvc: MessageService,
        private clContentService: ZsClContentService) { }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        // const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');

        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.activatedRoute.parent.parent.params.subscribe(params => {
          // this.dataset_id = params['id'];
          this.catalog_id = params['catalog_id'];
          this.project_id = +params['id'];
        });
        this.activatedRoute.queryParamMap.subscribe(params => {
            if(params.has('dqRuleLevel')){
              this.dq_rule_level = params['params']['dqRuleLevel'];
            }
            this.backTo = params.get('BackTo');
            if(params.has('manageData')){
                this.manageData = params.get('manageData')=='true'?true:false;
            }
            this.project_type = params.get('project_type');
            this.project_type_id = +params.get('project_type_id');
            const is_clone = params.get('is_clone');
            if (is_clone && is_clone === 'true') {
                this.is_clone = true;
            }
            this.rule_id = params.get('rule_id');
        });
        
        this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
        this.concept_id = this.activatedRoute.snapshot.queryParamMap.get('concept_id');
        this.no_matches = this.activatedRoute.snapshot.queryParamMap.get('no_matches');
        if (this.no_matches !== null) {
            this.dataset_classify_ids = this.no_matches.split(',');
        }

        this.datasets = this.activatedRoute.snapshot.queryParamMap.get('datasets');
        if (this.datasets !== null && this.datasets !== '') {
            let all_datasets = this.datasets.split(',');
            if (all_datasets.length) {
                all_datasets.forEach((element) => {
                    if (!this.dataset_ids.includes(element)) {
                        this.dataset_ids.push(element);
                    }
                });
            }
        }
        const mappedData = this.activatedRoute.snapshot.queryParamMap.get('mappedDataSets');
        if (mappedData !== null && mappedData !== '') {
            this.mappedDataSets = mappedData.split(',');
        }

        if (this.concept_id === undefined || this.concept_id === null) {
            this.activatedRoute.parent.params.subscribe(params => {
              this.catalog_id = params['catalog_id'];
            });
            if (this.data_rule) {
                this.activatedRoute.parent.parent.parent.params.subscribe(params => {
                    this.catalog_id = params['catalog_id'];
                  });
            }
            if(this.router.url.includes('create-bussiness-rule')) {
                this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
              }
        } else {
            this.activatedRoute.parent.parent.params.subscribe(params => {
              this.catalog_id = params['catalog_id'];
            });
            if (this.data_rule) {
                this.activatedRoute.parent.parent.parent.params.subscribe(params => {
                    this.catalog_id = params['catalog_id'];
                  });
            }
            if(this.router.url.includes('create-bussiness-rule')) {
                this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
              }
        };

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.modalGridOptions = {
            enableGridMenu: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true
        };

        this.progressState.states = this.zettaUtils.getStateList('classify-train-model');
        this.progressState.currentStateIndex = 1;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'classifyTrain';
        this.progressState.isEdit = false;

if(this.router.url.includes('add-reference-data/data-sets')) {
            this.activatedRoute.queryParamMap.subscribe(resp => {
                this.attributeId = resp['params']['attributeId'];
            });
        }
        this.initColDef();
        this.getDatasets(this.page, this.limit);        

    }

    getDatasets(pageNo, limit = 1) {
        const getDatasets: IDataset = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            pageno: pageNo,
            perpage: limit,
        };
        let serverFilter = "";
        if (sessionStorage.getItem("serverfilter")) {
            serverFilter = sessionStorage.getItem("serverfilter");
        }
        let sortfilter = "";
        if (sessionStorage.getItem("sortfilter")) {
            sortfilter = sessionStorage.getItem("sortfilter");
        }
       // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets?paginate=true&pageno=${1}&pagesize=${limit}`;
        
        if(this.router.url.includes('add-reference-data/data-sets')) {
            this.datasetSvc.getDatasetsHasMappings(getDatasets, 'true', this.attributeId, serverFilter, sortfilter).subscribe((data) => {
                this.leftGridDataSource = data["currentpage"];
                let leftArr: any;
                if (this.dataset_ids.length > 0) {
                    this.dataset_ids.forEach(element => {
                        leftArr = this.leftGridDataSource.filter(dataset => dataset.dataset_id === +element);
                        leftArr[0].id = Math.random();
                        this.rightGridDataSource.push(leftArr[0])
                    });
                    this.dataset_ids.forEach(element => {
                        this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== +element);
                    });
                }
                if (this.leftGridDataSource.length === 0) {
                    this.noData = true;
                }
                this.leftGridDataSource.forEach(element => {
                    element.id = Math.random();
                });
                this.isDataReady = true;
                this.isLoading = false;
                this.total = data['totalrecords'];
                if (pageNo === 1) {
                    this.grandTotal = data['totalrecords'];
                }
                let concatedRows = this.leftGridDataSource;
                if (this.leftGrid) {
                    concatedRows = [...this.leftGrid.dataView.getItems(), ...this.leftGridDataSource];
                    this.leftGridDataSource.forEach(element => {
                        element.id = Math.random();
                    });
                    this.leftGridDataSource = concatedRows;
                    this.leftGrid.dataView.setItems(concatedRows);
                    this.leftGrid.gridService.setSelectedRows([]);
                    this.hasScrolled = false;
                }
                this.getCPProjectTraingDatasets();
            }, (error) => {
                this.isLoading = false;
            });
        } else {
            this.datasetSvc.getDatasets(getDatasets, serverFilter, sortfilter).subscribe((data) => {
            this.leftGridDataSource = data["currentpage"];
            let leftArr: any;
            if (this.dataset_ids.length > 0) {
                this.dataset_ids.forEach(element => {
                    leftArr = this.leftGridDataSource.filter(dataset => dataset.dataset_id === +element);
                    if(leftArr && leftArr.length) {
                        leftArr[0].id = Math.random();
                        this.rightGridDataSource.push(leftArr[0])
                    }
                });
                this.dataset_ids.forEach(element => {
                    this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== +element);
                });
            }
            if (this.leftGridDataSource.length === 0) {
                this.noData = true;
            }
            this.leftGridDataSource.forEach(element => {
                element.id = Math.random();
            });
            this.isDataReady = true;
            this.isLoading = false;
            this.total = data['totalrecords'];
            if (pageNo === 1) {
                this.grandTotal = data['totalrecords'];
            }
            let concatedRows = this.leftGridDataSource;
            if (this.leftGrid) {
                concatedRows = [...this.leftGrid.dataView.getItems(), ...this.leftGridDataSource];
                this.leftGridDataSource.forEach(element => {
                    element.id = Math.random();
                });
                this.leftGridDataSource = concatedRows;
                this.leftGrid.dataView.setItems(concatedRows);
                this.leftGrid.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }
        }, (error) => {
            this.isLoading = false;
        });
        }
    }

    getCPProjectTraingDatasets() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            project_id: this.project_id
        };
        this.projectService.getClassifyProjectTraingDataset(data).subscribe(resp => {
            this.rightGridDataSource = resp;
            this.rightGridDataSource.forEach(element => {
                element.id = Math.random();
                element.name = element.dataset_name
                this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== element.dataset_id);
            });
            if (this.rightGrid) {
                this.rightGridDataSource.forEach(element => {
                    element.id = Math.random();
                });
                this.rightGrid.dataView.setItems(this.rightGridDataSource);
                this.rightGrid.gridService.setSelectedRows([]);
                this.hasScrolled = false;
            }
            setTimeout(() => {
                this.leftGrid.dataView.setItems(this.leftGridDataSource);
            }, 1000);            
        }, error => {
        });
    }
    removeCancel1() {
        $('#cancelBoxModal1').removeClass('show');
    }

    initColDef() {
        this.gridColumnsDef = [{
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink'
        }, {
            'displayname': 'Data Set Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink'
        }
    ];

        this.rightGridColumnsDef = [
        {
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink'
        }, {
            'displayname': 'Data Set Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'cssClass': 'blueLink'
        }];
    }

    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
            this.rightGrid.gridService.updateDataGridItem(row, false);
            if (eventEle.target.title === row.name) {
                this.dsName = row.name;
                this.showmodelpopup=true;
                this.datasetId=row.dataset_id;
                // this.selectOptionDataSetModelList(row.dataset_id);
                // $('#data_refresh_modal').modal('show');
                // $('#data_refresh_modal').removeClass('d-none');
            } else if (eventEle.target.title === row.datasource_name) {
                this.router.navigate(['/zs/datasources', row.datasource_id]);
            }
        }
    }

    onAdd() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const datasetList: Object[] = [];
        const rows: any[] = [];
        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            if (this.rightGrid.dataView.getItems().length) {
                rightGridDataService.addItem(row);
                this.rightGridDataSource = this.rightGrid.dataView.getItems();
            } else {
                this.rightGridDataSource.push(row)
            }
            // remove Item from left grid
            datasetList.push({ dataset_id: row.dataset_id });
            this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
            leftGridDataService.deleteDataGridItem(row);

        });

        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        // Update Row Count
        this.leftGridRows = this.leftGridRows - datasetList.length;
        this.rightGridRows = this.rightGridRows + datasetList.length;
        this.total -= datasetList.length;

    }

    onRemove() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const datasetList: String[] = [];
        const rows: any[] = [];
        this.classify_cp_training_datasets_ids = [];
        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            if (this.leftGrid.dataView.getItems().length) {
                leftGridDataService.addItem(row);
                this.leftGridDataSource = this.leftGrid.dataView.getItems();
            } else {
                this.leftGridDataSource.push(row)
            }
            // remove Item from right grid
            datasetList.push(row.dataset_id);
            if (row.classify_cp_training_dataset_id) {
                this.classify_cp_training_datasets_ids.push(row.classify_cp_training_dataset_id)
            }
            this.rightGridDataSource = this.rightGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
            rightGridDataService.deleteDataGridItem(row);
        });
        // this.leftGrid.slickGrid.setData();
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);

        this.leftGridRows = this.leftGridRows + datasetList.length;
        this.rightGridRows = this.rightGridRows - datasetList.length;
        this.total += datasetList.length;
        if (this.train_dataset && this.classify_cp_training_datasets_ids.length) {
            this.removeClassifyTrainigDatasets();
        }
        
    }

    removeClassifyTrainigDatasets() {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            project_id: this.project_id,           
            payload: {
                body: {
                    classify_cp_training_datasets_ids: this.classify_cp_training_datasets_ids
                }
            }           
        };
        this.projectService.removeClassifyTrainigDatasets(data).subscribe(resp => {
            console.log(resp);
        }, error => {
        });
    }

    getDataSetById(datasourceId) {
        const rightGridDataService = this.rightGrid.gridService;
        this.datasetSvc.getDataSourcesPg({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: datasourceId }).subscribe(resp => {
            this.rightGridDataSource = resp['currentpage'];
            this.datasetList = this.rightGridDataSource;
            if (this.rightGridDataSource) {
                this.rightGridDataSource.forEach(element => {
                    element.id = Math.random();
                    element.dataset_type_id = 4;  
                    element.delimiter = ',';
                    element.source_table_or_filename = element.name;
                    element.header = true;
                    element.schema = '';
                });
            }
            this.rightGridRows = this.rightGridDataSource.length;
        }, error => {
            this.rightGridDataSource = [];
            this.rightGridRows = this.rightGridDataSource.length;
            this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
        });
    }


    rightGridCreated(grid) {        
        const parent = this;
        this.rightGrid = grid;
        parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasRightDataSelected = args.rows.length > 0 ? true : false;
        });
    }

    leftGridCreated(grid) {
        const parent = this;
        this.leftGrid = grid;
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.hasDatasourceSelected = args.rows.length > 0 ? true : false;
        });
    }

    onCancelStep(): void {
      if (this.concept_id === undefined || this.concept_id === null) {
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
      } else {
        this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
      };
    }

    onPrevious() {
        let datasetsSelected = []
        this.rightGridDataSource.forEach(element => {
            datasetsSelected.push(element.dataset_id) 
        });
        this.datasets = datasetsSelected.toString();

        if(this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/review-training'], 
                            { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/review-training'], 
                            { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
        };
    }

    onNext() {
        let datasetsSelected = []
        this.rightGridDataSource.forEach(element => {
            datasetsSelected.push(element.dataset_id) 
        });
        this.datasets = datasetsSelected.toString();

        if(this.concept_id === undefined || this.concept_id === null) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/map-data-columns'], 
                            { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
        } else {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/map-data-columns'], 
                            { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
        };
    }

    onSkipClick() {
        let datasetsSelected = []
        this.rightGridDataSource.forEach(element => {
            datasetsSelected.push(element.dataset_id) 
        });
        this.datasets = datasetsSelected.toString();
        if(this.router.url.includes('create-bussiness-rule')) {
            this.isSkipedMappedColumn = true;
            this.router.navigate(['/zs-cl/rules/create-bussiness-rule/add-users'], { queryParams: { datasets: this.mappedDataSets.toString(), is_skiped_mapped_column: this.isSkipedMappedColumn }, queryParamsHandling: 'merge' });
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/map-data-columns'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id, datasets: this.datasets }});
        }

      }

    ngOnDestroy() {
        // if (!this.showBreadcrumb) {
        //     $('#breadcrumb').removeClass('d-none');
        // }
        // this.messageSvc.clearMessage();
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    cancel() {
        $("#data-" + this.dataSelected).prop("checked", false);
        this.isDataSelected = false;
    }

    onChangeHeader(){
        if($('#header').is(":checked")){
            $('#divSchema').addClass('d-none');
        }
        else if($('#header').is(":not(:checked)")){
            $('#divSchema').removeClass('d-none');
        }
    }

    // Modal Datasets
    selectOptionDataSetModelList(dataSetId, limit = 30) {
        this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
        this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  
        // this.isSampleDsLoading = true;
        this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
            this.modalRowData = data['currentpage'];
            let rowKeys = [];
            if (data['currentpage'].length > 0) {
                let myVar: any = data['currentpage'][0];
                for (var key in myVar) {
                    rowKeys.push(key) 
                }
            }
            this.modelColumnDef = [];
            rowKeys.forEach(column => {
              this.modelColumnDef.push({
                'displayname': column,
                'physicalname': column,
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'minWidth': 150,
              });
            });
    
            this.modalRowData.forEach((element: any) => {
              element.id = Math.random();
            });
    
            // this.total = data['totalrecords'];
            if (this.modalGrid) {
              this.modalGrid.dataView.setItems(this.modalRowData);
              this.modalGrid.gridService.setSelectedRows([]);
            }
            this.isSampleDsLoading = false;
          }, err => {
            this.isSampleDsLoading = false;
            this.modalRowData = [];
          });
      }
  
      onModelGridCreation(grid) {
        this.modalGrid = grid;
      }

      onDatasetCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
          if (eventEle.target.title === row.name) {
            this.dsName = row.name;
            this.showmodelpopup=true;
            this.datasetId=row.dataset_id;
          } else if (eventEle.target.title === row.datasource_name) {
            this.router.navigate(['/zs/datasources', row.datasource_id]);
          }
        }
      }

      popupoutput(message){
        this.showmodelpopup = message;   
      }
      onPrevStepRule(){
        if(this.router.url.includes('create-bussiness-rule')) {
            //this.router.navigate(['/zs-cl/rules/create-bussiness-rule/review-mapping'], { queryParams: { catalog_id: this.catalog_id, object_id: this.object_id, concept_id: this.concept_id }});
            this.router.navigate(['/zs-cl/rules/create-bussiness-rule/review-mapping'], {queryParamsHandling:'preserve'});
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/review-mapping'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
        }
    }

      onNextClick() {
        let datasetsSelected = []
        this.rightGridDataSource.forEach(element => {
            datasetsSelected.push(element.dataset_id) 
        });
        this.datasets = datasetsSelected.toString();
        if(this.router.url.includes('create-bussiness-rule')) {
            this.isSkipedMappedColumn = false;
            this.router.navigate(['/zs-cl/rules/create-bussiness-rule/map-data-columns'], { queryParams: { datasets: this.datasets, is_skiped_mapped_column: this.isSkipedMappedColumn }, queryParamsHandling: 'merge' });
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules/map-data-columns'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id, datasets: this.datasets }});
        }

      }

    onCancel(){
        if(this.router.url.includes('create-bussiness-rule')) {         
            $('.modal').modal('hide');
            if (this.dq_rule_level == 'concept') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}`], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
                } else {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/concepts/${this.concept_id}/dq-rules/manage-rules`], { queryParamsHandling: 'preserve' });
                }                
            } else if (this.dq_rule_level == 'catalog') {
                if(this.backTo=='catalog'){
                    this.router.navigate(['/zs-cl/catalogs'], {queryParams:{showBreadcrumb:true}});
                }else{
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }                   
            } else if (this.dq_rule_level == 'object') {
                if (this.backTo == 'catalog') {
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}`], { queryParams: { showBreadcrumb: true } });
                }else{
                    this.router.navigate([`/zs-cl/catalogs/${this.catalog_id}/objects/${this.object_id}/dq-rule/manage-rules`], { queryParamsHandling: 'preserve' });
                }                
            }else {
                if(this.is_clone) {
                    let rule_ids: any = [];
                    rule_ids.push(this.rule_id)
                    this.clContentService.deleteDataQualityRule({ dq_rules_ids: rule_ids }).subscribe(resp => { });
                    sessionStorage.removeItem('ruleInfo');
                    sessionStorage.removeItem('ruleInfoClone');
                    sessionStorage.removeItem("tRuleDefinition");
                } 
                this.router.navigate(['/zs-cl/dataquality']);
            }
        }
        if (this.router.url.includes('data-quality-rules')) {
            this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/data-quality-rules'],{ queryParams: { object_id: this.object_id, concept_id: this.concept_id }});
        }    
    }

    onCancelDataset() {
        if(this.manageData){
            this.router.navigate(['/zs-cl/projects', this.project_id], { queryParams: { project_type: this.project_type, project_type_id: this.project_type_id  }});
        }else{
            this.router.navigate(['/zs-cl/projects']);
        }
        
    }

    onPreviousDataset() {
        this.router.navigate(['../../concept-training-data'], { relativeTo: this.activatedRoute, queryParams: { activeLink: '1' }, queryParamsHandling: 'merge' })
    }

    onNextDataset() {
        let datasetsSelected = [];
        let datasourceSelected = [];        
        this.rightGridDataSource.forEach(element => {
            datasetsSelected.push(element.dataset_id) &&
            datasourceSelected.push(element.datasource_id)
        });
        
        this.datasetsSelect = datasetsSelected.toString();
        this.datasourceSelect = datasourceSelected.toString();
        this.router.navigate(['../data-column'], { relativeTo: this.activatedRoute, queryParams: { dataset_id: this.datasetsSelect, datasource_id: this.datasourceSelect, activeLink: '3' }, queryParamsHandling: 'merge' })
    }

    onScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
          this.hasScrolled = true;
          this.onNextPage();
        }
      }
    
      onNextPage(): void {
        this.page++;
        this.getDatasets(this.page, this.limit);
      }
      receiveTotalRecords($event) {
        this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
      }
    onUploadDataSource() {
        $('.modal').modal('hide');
        this.router.navigate(['/zs/datasets/select-data-source']);
    }
    onManageDataSet() {
        $('.modal').modal('hide');
        this.router.navigate(['/zs/datasets']);
    }
}


