import { Inject, Injectable, NgZone, Compiler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppGlobals } from '../shared/app.globals';
import { MessageService } from '../components/message/message.service';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MSALTokenInterceptor extends MsalInterceptor {

    constructor(
        private router: Router,
        private zone: NgZone,
        private messageSvc: MessageService,
        private _compiler: Compiler,
        private authService: MsalService, 
        broadcastService: BroadcastService) {
            super(authService, broadcastService);
            this.APIToken = 'Basic c3luYWk6cGFzc3BvcnRzcw==";//localStorage.getItem("id_token")';
    }

    private APIToken = null;
    private defaultApplicationHeaders = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
    };

    buildRequestHeaders(): HttpHeaders {
        const headers = this.defaultApplicationHeaders;
        return new HttpHeaders(headers);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let headers = req.headers;
        if( headers.keys().indexOf('Authorization') > -1){
            const newRequest = req.clone({
                withCredentials: false
            });
            return next.handle(newRequest);
        } 
        // console.log('------------------------Azure Interceptor start------------------------------');
        // console.log('headers keys:', headers.keys());
        // console.log('URL:', req.url);
        // console.log('-------------------------Azure Interceptor End---------------------------');
        return next.handle(req);
    }
}
