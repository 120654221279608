import { toolTipFormatter } from '../../zm-shared/zm-formatters/toolTipFormatter';
import { AppGlobals } from './../../zm-shared/app.globals';

import { OnInit, Component, Input, AfterViewInit,
    ViewEncapsulation, Injectable, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import { ZmBaseService } from '../../zm-services/zm-base-service';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { HttpClient } from '@angular/common/http';
import {
    Column,
    FieldType,
    Filters,
    Formatters,
    GridOdataService,
    GridOption,
    AngularGridInstance,
    Statistic,
    GridStateChange,
    OperatorType,
    SortDirection,
    Formatter

} from 'angular-slickgrid';
declare var Slick: any;
declare var $: any;

const defaultPageSize = 20;

@Component({
    selector: 'zm-zetta-grid',
    templateUrl: './zm-zetta-grid.component.html',
    styleUrls: ['./zm-zetta-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@Injectable()
export class ZmZettaGridComponent implements OnInit, AfterViewChecked {

    @Input() rowsDataSource;
    @Input() columnsDataSource;
    @Input() tableSettings;
    @Input() ctmGridOptions;
    @Output() gridCreated = new EventEmitter<AngularGridInstance>();
    @Output() cellClicked = new EventEmitter();
    @Output() cellEdited = new EventEmitter();
    @Output() rowsSelected = new EventEmitter();
    @Output() openContextMenu = new EventEmitter();
    @Output() gridScroll = new EventEmitter();


    grid: any;
    columnDefinitions: Column[];
    gridOptions: GridOption;
    dataset = [];
    tableUniqueID: any;
    dataSize = -1;
    angularGrid: AngularGridInstance;

    _commandQueue: any[] = [];

    odataQuery = '';
    processing = true;
    compaq = true;
    buttonLabel = 'Click to Standard View';

    dataView: any;
    isNormalPaginationRequired;
    validData = true;
    isPaginationRequired;
    search_api_url = '';
    inValidDataMsg  = AppGlobals.DEFAULT_EMPTY_GRID_MSG;
    inValidDataMsgStyle = AppGlobals.DEFAULT_EMPTY_GRID_MSG_STYLE;

    constructor(public http: HttpClient, public zettaUtils: ZmZettaUtils, public baseSvc: ZmBaseService, public observeSvc: ZmObservableService) {
        this.tableUniqueID = this.zettaUtils.generateUUID();
    }

    getInValidDataMsg() {
        return this.getCtmGridOption('noDataMsg') || this.inValidDataMsg;
    }

    getInValidDataMsgStyle() {
        const style = this.getCtmGridOption('noDataMsgStyle') || this.inValidDataMsgStyle;
        return style;
    }
    isValidData(): boolean {

        const currentDataValidity = this.rowsDataSource.length > 0 ? true :
            this.getCtmGridOption('showNoData') !== undefined ?
                !this.getCtmGridOption('showNoData')
                : false;

        if (this.angularGrid && this.validData !== currentDataValidity) {
            // just to redraw grid
            // this.angularGrid.resizerService.resizeGrid();
            this.angularGrid.sortService.clearSorting();
        }
        this.validData = currentDataValidity;
        return this.validData;
    }

    ngOnInit(): void {

        this.rowsDataSource.forEach((item, index) => { item['id'] = Math.random(); });
        this.initializeGridColumns(this.rowsDataSource, this.columnsDataSource);
        this.dataSize = this.tableSettings.totalrecords || this.rowsDataSource.length;
        this.isNormalPaginationRequired = this.tableSettings.isNormalPagination || false;
        this.tableSettings.pageSize = this.isNormalPaginationRequired ? this.tableSettings.pageSize : this.rowsDataSource.length;
        // this.tableSettings.pageSize = this.tableSettings.pageSize || this.rowsDataSource.length;
        this.isPaginationRequired = (Math.ceil(this.dataSize / this.tableSettings.pageSize) > 1) ? true : false;
        this.search_api_url += this.tableSettings.search_url || '';

        const parent = this;
        this.gridOptions = {
            enableGrouping: true,
            enableAutoResize: true,
            enableSorting: true,
            frozenColumn: parent.getCtmGridOption('frozenColumn'),
            frozenRow: parent.getCtmGridOption('frozenRow'),
            enableAddRow: parent.getCtmGridOption('enableAddRow'),
            enableGridMenu: parent.getCtmGridOption('enableGridMenu'),
            createPreHeaderPanel: parent.getCtmGridOption('createPreHeaderPanel'),
            showPreHeaderPanel: parent.getCtmGridOption('showPreHeaderPanel'),
            preHeaderPanelHeight:  parent.getCtmGridOption('preHeaderPanelHeight') || 0,
            enableColumnReorder:  parent.getCtmGridOption('enableColumnReorder') !== undefined ? parent.getCtmGridOption('enableColumnReorder') : true,
            multiSelect: false,
            defaultFilterPlaceholder: '',
            autoResize: {
                containerId: parent.tableUniqueID,
                sidePadding: 15
            },
            checkboxSelector: {
                // you can toggle these 2 properties to show the 'select all' checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },

            enableCellNavigation: parent.getCtmGridOption('editable'),
            enableFiltering: parent.getCtmGridOption('enableFiltering') !== undefined ? parent.getCtmGridOption('enableFiltering') : true,
            // enableFiltering: true,

            enablePagination: parent.isNormalPaginationRequired,
            cellFlashingCssClass: 'flashing',
            selectedCellCssClass: 'selected',

            enableCheckboxSelector: parent.getCtmGridOption('CheckboxSelector') !== undefined ?
                parent.getCtmGridOption('CheckboxSelector') : true,
            enableRowSelection: parent.getCtmGridOption('selectable') !== undefined ? parent.getCtmGridOption('selectable') : true,
            editable: parent.getCtmGridOption('editable') || false,
            autoEdit: parent.getCtmGridOption('autoEdit') || false,
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: parent.getCtmGridOption('multiSelectable') !== undefined ? parent.getCtmGridOption('multiSelectable') : true,
            },
            pagination: {
                pageSizes: [5, 10, 15, 20, 25, 30, 40, 50, 75, 100],
                pageSize: parent.tableSettings.pageSize,
                totalItems: 0
            },
            rowHeight: parent.getCtmGridOption('rowHeight') || 30,
            editCommandHandler: (item, column, editCommand) => {
                parent.cellEdited.emit(editCommand);
            },
            backendServiceApi: {
                service: new GridOdataService(),
                preProcess: () => { },
                process: (query) => parent.getCustomerApiCall(query),
                postProcess: (response) => {
                    parent.getCustomerCallback(response);
                }
            }
        };

        this.observeSvc.refreshDataGrid.subscribe(data => {
            parent.onResize();
        });


    }

    /**
	 * @param {Array<Object>} tableRowsDS
	 * @param {Array<Object>} columnDS
	 * @memberof ZettaGridComponent
	 */
    initializeGridColumns(tableRowsDS: Array<Object>, columnDS: Array<Object>): void {
        const parent = this;
        const tableCols = [];
        for (const col in columnDS) {
            if (columnDS.hasOwnProperty(col)) {
                const findColumnObj = columnDS[col];
                if (findColumnObj) {
                    const columnObj = {};
                    columnObj['sortable'] = findColumnObj['sortable'] !== undefined ? findColumnObj['sortable'] : true;
                    columnObj['filterable'] = findColumnObj['filterable'] !== undefined ? findColumnObj['filterable'] : true;
                    columnObj['id'] = findColumnObj['physicalname'];
                    columnObj['field'] = findColumnObj['physicalname'];
                    columnObj['name'] = findColumnObj['displayname'];
                    columnObj['toolTip'] = findColumnObj['displayname'];
                    columnObj['headerCssClass'] = findColumnObj['headerCssClass'] ||
                        parent.zettaUtils.getWidthOfText(columnObj['headerCssClass']);

                    columnObj['editor'] = findColumnObj['editor'];
                    columnObj['columnGroup'] = findColumnObj['columnGroup'];
                    columnObj['onCellClick'] = findColumnObj['onCellClick'];
                    columnObj['params'] = findColumnObj['params'];
                    // columnObj['minWidth'] = findColumnObj['minWidth'] || parent.zettaUtils.getWidthOfText(columnObj['name']);
                    // Set Default column width
                    if (findColumnObj['maxWidth']) {
                        columnObj['maxWidth'] = findColumnObj['maxWidth'];
                    } else {
                        columnObj['minWidth'] = findColumnObj['minWidth'] &&
                            findColumnObj['minWidth'] > parent.zettaUtils.getWidthOfText(columnObj['name'])
                            ? findColumnObj['minWidth'] : parent.zettaUtils.getWidthOfText(columnObj['name']);
                        columnObj['cssClass'] = findColumnObj['cssClass'] || parent.zettaUtils.getWidthOfText(columnObj['cssClass']);
                    }
                    if (findColumnObj['datatype'] === 'Long') {
                        columnObj['filter'] = { model: Filters.inputText };
                        columnObj['type'] = FieldType.number;
                        columnObj['formatter'] = findColumnObj['formatter'] || Formatters.multiple;
                        columnObj['params'] = findColumnObj['params'] || { formatters: [Formatters.complexObject] };
                    } else if (findColumnObj['datatype'] === 'DateTime') {
                        columnObj['formatter'] = findColumnObj['formatter'] || Formatters.dateIso;
                        columnObj['type'] = FieldType.date;
                        columnObj['filter'] = { model: Filters.inputText };
                    } else {
                        columnObj['filter'] =   findColumnObj['filter'] || { model: Filters.inputText };
                        columnObj['formatter'] = findColumnObj['formatter'] || toolTipFormatter;
                    }
                    tableCols.push(columnObj);
                }
            }
        }
        parent.columnDefinitions = tableCols;
    }

    getCustomerCallback(data) {
        // totalItems property needs to be filled for pagination to work correctly
        // however we need to force Angular to do a dirty check, doing a clone object will do just that
        this.gridOptions.pagination.pageSize = this.tableSettings.pageSize;
        this.gridOptions.pagination.totalItems = this.dataSize; // data['totalRecordCount'];
        this.gridOptions = Object.assign({}, this.gridOptions);

        // once pagination totalItems is filled, we can update the dataset
        this.dataset = data ? data['items'] : this.angularGrid.dataView.getItems();
        this.odataQuery = data['query'];
    }


    getCustomerApiCall(query) {
        // in your case, you will call your WebAPI function (wich needs to return a Promise)
        // for the demo purpose, we will call a mock WebAPI function
        return this.getDataApiMock(query);
    }

    /** This function is only here to mock a WebAPI call (since we are using a JSON file for the demo)
	 *  in your case the getCustomer() should be a WebAPI function returning a Promise
	 */
    getDataApiMock(query) {
        const parent = this;
        // the mock is returning a Promise, just like a WebAPI typically does
        return new Promise((resolve, reject) => {
            const queryParams = query.toLowerCase().split('&');
            let top = 0;
            if (!parent.tableSettings.search_url) {
                top = parent.rowsDataSource.length;
            }
            let skip = 0;
            let orderBy = '';
            let countTotalItems = 100;
            const columnFilters = {};

            for (const param of queryParams) {
                // if (param.includes('$top=')) {
                // 	top = +(param.substring('$top='.length));
                // }
                if (param.includes('$skip=')) {
                    skip = +(param.substring('$skip='.length));
                }
                if (param.includes('$orderby=')) {
                    orderBy = param.substring('$orderby='.length);
                }
                if (param.includes('$filter=')) {
                    const filterBy = param.substring('$filter='.length).replace('%20', ' ');
                    const searchedColumn = filterBy.split('and');
                    if (searchedColumn.length > 0) {
                        searchedColumn.forEach(_filterByColumn => {
                            if (filterBy.includes('eq')) {
                                        const filterMatch = filterBy.match(/([a-zA-Z _]*) eq '(.*?)'/);
                                        const fieldName = filterMatch[1].trim();
                                        columnFilters[fieldName] = {
                                            type: 'equal',
                                            term: filterMatch[2].replace('%20', ' ').trim()
                                        };
                                    }
                        });
                    }
 
                    // Below Code is for reference for future use if required.
 
                //     else {
                //     if (filterBy.includes('substringof')) {
                //         const filterMatch = filterBy.match(/substringof\('(.*?)',([a-zA-Z _]*)/);
                //         const fieldName = filterMatch[2].trim();
                //         columnFilters[fieldName] = {
                //             type: 'substring',
                //             term: filterMatch[1].replace('%20', ' ').trim()
                //         };
                //     }
                //     if (filterBy.includes('eq')) {
                //         const filterMatch = filterBy.match(/([a-zA-Z _]*) eq '(.*?)'/);
                //         const fieldName = filterMatch[1].trim();
                //         columnFilters[fieldName] = {
                //             type: 'equal',
                //             term: filterMatch[2].replace('%20', ' ').trim()
                //         };
                //     }
                //     if (filterBy.includes('startswith')) {
                //         const filterMatch = filterBy.match(/startswith\(([a-zA-Z _]*),\s?'(.*?)'/);
                //         const fieldName = filterMatch[1].trim();
                //         columnFilters[fieldName] = {
                //             type: 'starts',
                //             term: filterMatch[2].replace('%20', ' ').trim()
                //         };
                //     }
                //     if (filterBy.includes('endswith')) {
                //         const filterMatch = filterBy.match(/endswith\(([a-zA-Z _]*),\s?'(.*?)'/);
                //         const fieldName = filterMatch[1].trim();
                //         columnFilters[fieldName] = {
                //             type: 'ends',
                //             term: filterMatch[2].replace('%20', ' ').trim()
                //         };
                //     }
                // }
                }
            }

            const sort = orderBy.includes('asc')
                ? 'ASC'
                : orderBy.includes('desc')
                    ? 'DESC'
                    : '';


            const dataArray = parent.rowsDataSource;


            // Read the result field from the JSON response.
            const firstRow = skip;
            let filteredData = dataArray;
            let searchTerm = '';
            let filterType = '';
            if (columnFilters) {
                for (const columnId in columnFilters) {
                    if (columnFilters.hasOwnProperty(columnId)) {
                        filterType = columnFilters[columnId].type;
                        searchTerm = columnFilters[columnId].term;
                        filteredData = filteredData.filter(column => {
                            let colId = columnId;
                            if (columnId && columnId.indexOf(' ') !== -1) {
                                const splitIds = columnId.split(' ');
                                colId = splitIds[splitIds.length - 1];
                            }
                           // Compare columns in lower case
                            let filterTerm ;
                            const matchedKey = Object.keys(column).filter(key => key.toLowerCase() === colId);
                            if (matchedKey.length > 0) {
                                filterTerm = column[matchedKey[0]];
                            }
                            if (filterTerm) {
                                // please refactor code incase filter type required is starts with
                                filterType = 'substring';
                                filterTerm = filterTerm.toString();
                                switch (filterType) {
                                    case 'equal': return filterTerm.toLowerCase() === searchTerm;
                                    case 'ends': return filterTerm.toLowerCase().endsWith(searchTerm);
                                    case 'starts': return filterTerm.toLowerCase().startsWith(searchTerm);
                                    case 'substring': return filterTerm.toLowerCase().includes(searchTerm);
                                }
                            }
                        });
                    }
                }
                countTotalItems = filteredData.length;
            }
            const updatedData = filteredData.slice(firstRow, firstRow + top);
            if (parent.tableSettings.search_url) {
                let pageNo = Math.ceil((firstRow + top) / parent.tableSettings.pageSize);
                parent.http.get(parent.search_api_url + '?pageno=' + pageNo + '&key=' + searchTerm).subscribe(response => {
                    response['currentpage'].map((item, index) => { item['id'] = Math.random(); return item; });
                    parent.dataView = new Slick.Data.DataView({});
                    parent.dataView.beginUpdate();
                    parent.angularGrid.dataView.setItems(response['currentpage']);
                    parent.dataView.endUpdate();
                    resolve({ items: response['currentpage'], totalRecordCount: response['totalrecords'], query });
                });
            } else {
                if (dataArray.length > 0 && orderBy.length >0) {

                    const columnName = orderBy.split(' ')[0];
                    this.zettaUtils.customSortFun(updatedData, columnName, sort);
                }
                setTimeout(() => {
                    resolve({ items: updatedData, totalRecordCount: countTotalItems, query });
                }, 500);
            }

        });
    }

	/**
	 * Toggle Standard/Compaq view
	 * @memberof ZettaGridComponent
	 */
    toggleView(): void {
        this.compaq = !this.compaq;
        if (!this.compaq) {
            this.gridOptions.rowHeight = 40;
            this.buttonLabel = 'Click to Compaq View';
        } else {
            this.gridOptions.rowHeight = 20;
            this.buttonLabel = 'Click to Standard View';
        }
        // update Grid upon metaupdate
        this.grid.setOptions(this.gridOptions);
        this.grid.invalidate();
        this.grid.render();
    }


	/**
	 * Get Grid Instance once grid is ready
	 * @param {AngularGridInstance} angularGrid
	 * @memberof ZettaGridComponent
	 */
    angularGridReady(angularGrid: AngularGridInstance): void {
        this.grid = angularGrid.slickGrid;
        this.gridCreated.emit(angularGrid);
        this.angularGrid = angularGrid;

        const parent = this;

        // added Context Menu 
        this.grid.onClick.subscribe(function(e, args) {
            e.preventDefault();
            parent.openContextMenuAction(e);
        });
    }

    openContextMenuAction(e) {
        const parent = this;
        const args = parent.grid.getCellFromEvent(e);
        if (args && args.cell === parent.grid.getColumnIndex('actions')) {
            parent.openContextMenu.emit({ 'eventData': e, 'args': args });
        }
    }

    onCellClicked(e, args) {
        if (args.cell !== 0) {
            this.grid.setSelectedRows([args.row]);
        }
        this.cellClicked.emit({ 'eventData': e, 'args': args });
    }

    getCtmGridOption(property: string) {

        if (this.ctmGridOptions && this.ctmGridOptions[property] !== null) {
            return this.ctmGridOptions[property];
        }
    }

    onSelectedRowsChanged(e, args) {
        this.rowsSelected.emit(args);
    }


    ngAfterViewChecked() {

        // Remove grouped Rows Border
        this.groupedRows();

        // Add Last Row bottom Border
        this.lastRowBorder();

        // for attaching the dragble elements
        if (this.getCtmGridOption('dragRow') !== null && this.getCtmGridOption('dragRow')) {
            $('.slick-row').draggable({

                // cursor: 'crosstalk',
                containment: 'document',
                appendTo: 'body',
                cursor: 'move',
                helper: 'clone',
                revert: true,
                revertDuration: 200,
                zIndex: 9999,
                scroll: false
            });
        }
    }

    groupedRows() {
        const groupedCell = $('.groupedDiv').parent();
        if (groupedCell.length > 0 ) {
            for (let i = 0 ; i < groupedCell.length ; i++) {
                $(groupedCell[i]).removeClass('grouped-row');
                $(groupedCell[i]).addClass('grouped-row');
                $(groupedCell[i]).siblings().removeClass('grouped-row');
                $(groupedCell[i]).siblings().addClass('grouped-row');
            }
        }
    }

    lastRowBorder() {

        const elements = $('div.grid-canvas-left');
        if (elements) {
            for (let i = 0; i < elements.length; i++) {

                let maxTop = 0;
                let lastRowElement = elements[i].children[0];

                for (let k = 0; k < elements[i].children.length; k++) {

                    // Clean existing css if any
                    for (let j = 0; j < elements[i].children[k].children.length; j++) {
                        $(elements[i].children[k].children[j]).removeClass('grid-last-row-bottom-border');
                    }
                    // get last row based on top style 
                    let tempTopStyle = parseInt($(elements[i].children[k]).css("top").replace('px', ''));
                    if (tempTopStyle > maxTop) {
                        maxTop = tempTopStyle;
                        lastRowElement = elements[i].children[k];
                    }
                }

                if (lastRowElement) {
                    // Add Bottom Border on Cells of last row
                    for (let j = 0; j < lastRowElement.children.length; j++) {
                        $(lastRowElement.children[j]).addClass('grid-last-row-bottom-border');
                    }
                }
            }
        }
    }

    onResize() {
        if (this.angularGrid) {
            this.angularGrid.resizerService.resizeGrid(250);
        }
    }

    onGridScroll(e, args) {
        this.gridScroll.emit({ 'eventData': e, 'args': args });
        // this.cellClicked.emit();
    }
}
