import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends BaseService {

  userid: number = this.getObjectFromSession('userInfo').user_id;
  constructor(private http: HttpClient) { super(); }

  public getUserInfo(): Observable<any> {
      return this.http.get(`${this.apiUrl}/users/${this.userid}`);
  }

  public updateUserInfo(payload): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${this.userid}`, payload);
  }

  public changePassword(payload): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${this.userid}/changepassword`, payload);
  }

  public updateUserProfile(payload): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${this.userid}/profile_pic`, payload);
  }
  public getUserPic(): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/${this.userid}/profile_pic`,{ responseType: 'blob' });
  }
}
