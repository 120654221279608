import { Column, Formatter } from 'angular-slickgrid';

export const groupsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

  if (dataContext.selectGroupsCount > 0) {
    return `<i class='far fa-users-class userIcon'></i><span class="roundedCorner" title='${dataContext.selectGroupsCount}'>${dataContext.selectGroupsCount}</span>`;
  } else {
    return ``;
  }
};

