
import { Column, Formatter} from 'angular-slickgrid';

export const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
value ?
  `<label class="checkbox-container">
  <input type="checkbox" checked>
  <span class="checkmark"></span>
</label>` :
  `<label class="checkbox-container">
  <input type="checkbox">
  <span class="checkmark"></span>
</label>`;
