import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ProjectService } from '../../project.service';
import { ObservableService } from '../../../../../common/services/observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable, Subject } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { MessageService } from 'src/app/common/components/message/message.service';
import $ from 'jquery';
import { ContentService } from '../../../content.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { take } from 'rxjs/operators';

@Component({
	selector: 'zetta-pd-entity-mastered',
	templateUrl: './pd-entity-mastered.component.html',
	styleUrls: ['./pd-entity-mastered.component.scss']
})

export class PdEntityMasteredComponent implements OnInit, OnDestroy {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	public lineProjectResolvedChartDS = null;
	public entitiesMasteredDataSource = null;
	public entitiesMasteredChartColors = ['#4bb100', '#eeb049', '#d35162'];
	public notApplicable = false;
	public projectId;

	public publishStatus: number;
	public pubStatus: number;
	public setInterv: any;
	public captionBtn: string = "Golden Records";
	public enableBtn: boolean = false;

	public panelScreen: number;
	public buttonWrap: boolean;
	public login_user = JSON.parse(sessionStorage.userInfo).entitlements;	
	public project_view_all:any;
	public project_create:any;	
	
	is_visible_golden=false;	
	is_enable_golden=false;
	object_type_id:number;
	showMergerProgress: boolean = false;
	disableThresholdValue: boolean;
	overallConfidence: string;
	thresholdValue: number;
	currentSteps: object = {
		steps_completed : 0
	};
	showMergerData: boolean = true;
	mergerConfidence = 0;
	matcherValue = 0;
	onDestroy$: Subject<void> = new Subject();
	@Output() updatMergerValues = new EventEmitter<any>();
	public entitiesChartOptions: object = {
		'labelPercentValue': 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence',
		'resizeRequired': true,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'isPercentRequired': true
	};
	public lineProjectResolvedChartOptions: object = {
		'isMoneyFormat': false,
		'height': 133,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#e5b500',
		'circleColor': '#ff0000'
	};

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZettaUtils,
		private _projectService: ProjectService,
		public observeSvc: ObservableService,
		private activeRoute: ActivatedRoute,
		private router: Router,
		private clContentService: ZsClContentService,
		private contentService: ContentService,
		private messageSvc: MessageService) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
		if(this.login_user){	
			try{         
				let user_entitlements=this.login_user[0].entitlement;
				if(this.login_user.length>1){
				  user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
				  if(user_entitlements.length){
					user_entitlements=user_entitlements[0].entitlement;
				  }else{
					user_entitlements=this.login_user[0].entitlement;
				  }
				}
			  const my_projects = user_entitlements.screens.filter(item=>item.name=='project_details')[0].childs.filter(item=>item.name=='golden_records')[0];
			  if(my_projects){   
				this.is_visible_golden = my_projects.visible;
				this.is_enable_golden= my_projects.enable;            
			  }
			  this.contentService.getSystemSettings().subscribe(res => {
				if(res) {
					let filtered = res.filter(element => element.name == 'resolve_project_accuracy_threshold');
					if(filtered && filtered.length>0) {
						this.overallConfidence = filtered[0]['value'];
					}
				}
			  });
			}catch (error){
					  console.log(error);
			}				 
				  
		  }
	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;		
		this.object_type_id = +sessionStorage.getItem('project_type_id');
		parent.getEntityResolvedData(parent.userinfo.user_id, routeParams.id);
		this.projectId = routeParams.id;

		this.publishStatus = parseInt(this.activeRoute.snapshot.queryParamMap.get('publishStatus'));
		// console.log(this.publishStatus)
		parent.onResize();
		// console.log(this.publishStatus)
		if (this.publishStatus) {
			this.publishStatus = parseInt(this.activeRoute.snapshot.queryParamMap.get('publishStatus'));
		}else{
			parent._projectService.getProjectPublishStatus( this.userinfo.user_id, this.projectId ).subscribe(resp => {
				this.clContentService.goldenRecordsPublishStatus.next(Number(resp['publish_status']));
				this.publishStatus = parseInt(resp['publish_status']);
				switch(this.publishStatus) {
					case 370:  // -1 = Stale (Published data is stale, and need to be re-published)
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
						break;
					case 371:  //  0 = Not Published (Result is not published & ready to publish) 
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
						break;
					// case 372:  //  1 = Publishing (Result is being published) 
					// 	this.captionBtn = "Publishing";
					// 	this.enableBtn = false;
					// 	this.publishResults();
					// 	break;
					case 372:  //  1 = Publishing (Result is being published) 
						this.captionBtn = "Publishing";
						this.enableBtn = false;
						break;
					case 373:  //  2 = Published (Published & can be viewed) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						break;
					case 374:  //  3 = Failed (Publishing result process is failed & can be re-published or cancel) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						break;
					case 375:  //  4 = Ready to publish (Result is ready to be published) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						break;
					default:
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
				} 
			}, error => { });
			// this.publishStatus = 375
			// console.log(this.publishStatus)
		}

		switch(this.publishStatus) {
			case 370:  // -1 = Stale (Published data is stale, and need to be re-published)
				this.captionBtn = "Golden Records";
				this.enableBtn = false;
				break;
			case 371:  //  0 = Not Published (Result is not published & ready to publish) 
				this.captionBtn = "Golden Records";
				this.enableBtn = false;
				break;
			// case 372:  //  1 = Publishing (Result is being published) 
			// 	this.captionBtn = "Publishing";
			// 	this.enableBtn = false;
			// 	this.publishResults();
			// 	break;
			case 372:  //  1 = Publishing (Result is being published) 
				this.captionBtn = "Publishing";
				this.enableBtn = false;
				break;
			case 373:  //  2 = Published (Published & can be viewed) 
				this.captionBtn = "Golden Records";
				this.enableBtn = true;
				break;
			case 374:  //  3 = Failed (Publishing result process is failed & can be re-published or cancel) 
				this.captionBtn = "Golden Records";
				this.enableBtn = true;
				break;
			case 375:  //  4 = Ready to publish (Result is ready to be published) 
				this.captionBtn = "Golden Records";
				this.enableBtn = true;
				break;
			default:
				this.captionBtn = "Golden Records";
				this.enableBtn = false;
		} 
		
		parent._projectService.getModelSummaryLatest(parent.userinfo.user_id, routeParams.id, 'merger').subscribe(
			data => {
				parent.statisticsObj = { 'model_runs': 0, 'model_runs_as_of': new Date(), 'master_records': 0, 'master_records_as_of': new Date() };
				for (let index = 0; index < data.length; index++) {
					const obj = data[index];
					if (obj.name === 'run_number') {
						if(obj['value'] > 0 ) {
							this.showMergerData = true;
							this.checkForThreshold();
						} else {
							this.showMergerData = false;
							this.checkForThreshold();
						}
						parent.statisticsObj['master_records'] = obj['value'] || 0;
					} else if (obj.name === 'as_of') {
						parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
						parent.statisticsObj['master_records_as_of'] = obj['count'] || new Date();
					} else if (obj.name === 'total_entities') {
						parent.statisticsObj['model_runs'] = obj['count'] || 0;
					} else if (obj.name === 'overall_confidence') {
						parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
						parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
						parent.mergerConfidence = obj['value'] || 0;
					}
				}
				parent.entitiesMasteredDataSource = parent.getDonutParseData(data);
			},
			error => {
				parent.entitiesMasteredDataSource = [];
				parent.statisticsObj = { 'model_runs': 0, 'model_runs_as_of': new Date(), 'master_records': 0, 'master_records_as_of': new Date() };
			}
		);

		parent._projectService.getModelSummary(parent.userinfo.user_id, routeParams.id, 'merger').subscribe(
			data => {
				parent.lineProjectResolvedChartDS = parent.prepareLineChartData(data);
			},
			error => {
				parent.lineProjectResolvedChartDS = [];
			}
		);

		parent.observeSvc.getEntityName.subscribe(data => {
			this.selectedProjectName = data;
		});
		parent.checkForThreshold();
		parent.clContentService.matcherConfidenceValue.subscribe(resp => {
			this.checkForThreshold();
			this.matcherValue = Number(resp);
		});
	}

	isHavingAccess() {
		if (this.zettaUtils.isProcessRunning || this.notApplicable) {
			return true;
		}
		else (!this.zettaUtils.isProcessRunning && !this.notApplicable)
		{
			return false;
		}
	}

	onResize() {
		 this.panelScreen = $("#entitiesMasteredPanel").outerWidth();
		 if(this.panelScreen < 650){
			this.buttonWrap = true;
		 } else {
			this.buttonWrap = false;
		 }
	}

	getDonutParseData(data) {
		const dataSet = [];
		data.forEach((element) => {
			if (element['name'] === 'high_confidence') {
				dataSet.push({ 'Current Model': 'High', 'Confidence': element['records'] });
			} else if (element['name'] === 'medium_confidence') {
				dataSet.push({ 'Current Model': 'Medium', 'Confidence': element['records'] });
			} else if (element['name'] === 'low_confidence') {
				dataSet.push({ 'Current Model': 'Low', 'Confidence': element['records'] });
			}
		});
		this.updatMergerValues.emit({showMergerPlaceHolder: this.disableThresholdValue, mergerValue: this.mergerConfidence, matcherValue: this.matcherValue});
		return dataSet;
	}
	
	getEntityResolvedData(userId, projectId) {
		const parent = this;
		parent.zettaUtils.isProcessRunning = true;
		parent.currentJobStatusObj['algoid'] = AppGlobals.getRunModelAlgo(1);
		parent.observableRef = Observable.timer(0, AppGlobals.ENTITIES_RESOLVED_JOB_INTERVEL)
			.takeWhile(() => parent.alive)
			.subscribe(() => {
				// Cancel subscription in case URL changes
				if (parent.currentURL !== parent.router.url) {
					parent.alive = false;
					parent.observableRef.unsubscribe();
					return;
				}
				if(this.object_type_id){
					parent._projectService.getCurrentActiveJobs(userId, projectId,this.object_type_id).subscribe(response => {
						this.zettaUtils.isFileNotFound=false;
						parent.currentSteps = response;
						parent.checkForThreshold();
						if (Object.values(response).length === 0) {
							parent.zettaUtils.isProcessRunning = false;
							parent.notApplicable = true;
							parent.entitiesMasteredDataSource = [];
							return;
						}
						response['steps_completed'] = response['steps_completed'] || 0;
						response['total_steps'] = response['total_steps'] || 0;
						parent.currentJobStatusObj = response;
						if (response['job_completed']) {
							parent.isStepsCompleted = false;
							parent.zettaUtils.isProcessRunning = false;
							this.showMergerProgress = false;				
						} else if(response && response['stage_no'] == AppGlobals.RESOLVE_MERGER_STAGE_NUMBER && !response['is_errored']) {
								this.showMergerProgress = true;
						} else if(response && response['stage_no'] == AppGlobals.RESOLVE_MERGER_STAGE_NUMBER && response['is_errored']) {
							parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
							parent.errorDetails = response['error_message'];
							parent.showMergerProgress = false;
							parent.clContentService.currentProjectExecutingStep.next(response.stage_no);
							parent.updatMergerValues.emit({showMergerPlaceHolder: parent.disableThresholdValue, mergerValue: parent.mergerConfidence, matcherValue: 0, stageNo: response.stage_no});
						} else if(response && response['stage_no'] != AppGlobals.RESOLVE_MERGER_STAGE_NUMBER) {
							this.showMergerProgress = false;
						} else if (response['is_errored'] && parent.currentJobStatusObj['type'] !== 'matcher' && parent.currentJobStatusObj['type'] !== 'unsupervised') {
							parent.isStepsCompleted = true;
							parent.errorMessage = AppGlobals.DEFAULT_ERROR_MSG;
							parent.errorDetails = response['error_message'];
							parent.zettaUtils.isProcessRunning = false;
							parent.observableRef.unsubscribe();
						} else if (parent.currentJobStatusObj['type'] !== 'matcher' && parent.currentJobStatusObj['type'] !== 'unsupervised'
							&& !parent.currentJobStatusObj['job_completed']) {
							parent.isStepsCompleted = true;
							parent.alive = false;
							parent.observableRef.unsubscribe();
						}
						parent.observeSvc.setTrackStatus = parent.currentJobStatusObj;
						parent.observeSvc.getTrackStatus.subscribe(data => parent.currentJobStatusObj = data);
					},
						error => {					
							if(error.status===404){
								this.zettaUtils.isFileNotFound =true;
								parent.isStepsCompleted = false;
								parent.zettaUtils.isProcessRunning = false;
							}
						});
				}
				
			});
	}

	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
		this.messageSvc.clearMessage();
		clearInterval(this.setInterv);
		this.disableThresholdValue = true;
		this.entitiesMasteredDataSource;
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.matcherValue = 0;
		this.mergerConfidence = 0;
		this.disableThresholdValue;
	}

	@HostListener('window:unload', ['$event'])
	unloadHandler(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander(event) {
		if (this.observableRef) {
			this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	cancelJOB() {
		this._projectService.cancelActiveJob(this.userinfo.user_id, this.projectId).subscribe(response => {
			this.alive = false;
			this.zettaUtils.isProcessRunning = false;
			this.observableRef.unsubscribe();
		});
	}

	prepareLineChartData(data) {
		const dataSet = [];
		data.forEach((element) => {
			const d = new Date(element['created_ts']);
			dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
		});
		return dataSet;
	}

	refresh() {
		window.location.reload();
	}
	
	publishResults() {
		const payload = {
			'stage': 'stage5',
			'project_id': this.projectId
		}
		this._projectService.projectPublishResults({ scenarioId: 201, payload: payload }).subscribe(res => {
			this.captionBtn = "Publishing";
			this.enableBtn = false;
		}, error => { });

		// var i=1;
		this.setInterv = setInterval(() => {
			// console.log(i++);
			this._projectService.getProjectPublishStatus( this.userinfo.user_id, this.projectId ).subscribe(resp => { 
				this.pubStatus = parseInt(resp['publish_status']);
				// this.pubStatus = 374;
				switch(this.pubStatus) {
					case 370:  // -1 = Stale (Published data is stale, and need to be re-published)
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
						clearInterval(this.setInterv);
						break;
					case 371:  //  0 = Not Published (Result is not published & ready to publish) 
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
						clearInterval(this.setInterv);
						break;
					case 372:  //  1 = Publishing (Result is being published) 
						this.captionBtn = "Publishing";
						this.enableBtn = false;
						break;
					case 373:  //  2 = Published (Published & can be viewed) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						clearInterval(this.setInterv);
						break;
					case 374:  //  3 = Failed (Publishing result process is failed & can be re-published or cancel) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						this.messageSvc.sendMessage({ message: 'Golden Records Failed', type: 'ERROR', hideboard: true });
						clearInterval(this.setInterv);
						break;
					case 375:  //  4 = Ready to publish (Result is ready to be published) 
						this.captionBtn = "Golden Records";
						this.enableBtn = true;
						clearInterval(this.setInterv);
						break;
					default:
						this.captionBtn = "Golden Records";
						this.enableBtn = false;
				}
				
			}, error => { });
			
		}, 1000);
	}

	btnPublishResults() {
		// this.router.navigate(['/zs/projects/' + this.projectId + '/publish-result'], { queryParams: { 'publishStatus': this.publishStatus } });
		this.router.navigate(['/zs/projects/' + this.projectId + '/golden-records']);
	}

	checkForThreshold() {
		let parent = this;
		if(!parent.showMergerData) {
			parent.clContentService.thresholdValue
			.subscribe(res => {
				parent.thresholdValue = Number(res);
				parent.clContentService.mergerThreshold.next(parent.thresholdValue)
				if(res) {
					parent.clContentService.matcherConfidenceValue
					.subscribe(resp => {
						if(resp && Number(resp) > 0) {
							if (Number(resp) < parent.thresholdValue) {
									parent.disableThresholdValue = true;
								} else {
									parent.disableThresholdValue = false;
								}
						}
					});
				}
			});
		} else {
			this.disableThresholdValue = false;
		}
	}

}

