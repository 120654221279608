import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';
@Component({
  selector: 'name-link-formatter',
  template: `<button *ngIf="showButton" type="button" class="btn btn-primary btn-zetta btn-light btn-zetta1 btn-light1" [disabled]="!active">
  <span  class="btn-label btn-label1">Classify Set</span>
</button>`,
  styles: [
  ]
})
export class ButtonFormatterComponent implements OnInit, ICellRendererAngularComp {

  active = false;
  value: any;
  params: any;
  showButton = false;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
    this.showButton = params.data ? true : false;
    if (params.data && params.data.entitlement) {
      if (params.data.entitlement.read_write || params.data.entitlement.read || params.data.entitlement.is_admin) {
        if(this.params.user_apps){
          this.active = true;
       }
      } else {
        this.active = false;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.params.context.componentParent.updateRow(this.params.rowIndex);
  }


}
