import { MapDataSetsService } from '../../../zettasense/content/project/map-data-sets/map-data-sets-service';
import { Project, Dataset } from '../../../zettasense/content/project/project.model';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { AngularGridInstance, Grouping, Column, Formatter } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import * as _ from 'lodash';
 import { dropFormatter } from '../../../common/shared/formatters/dropFormatter';
import { toggleFormatter } from '../../../common/shared/formatters/toggleFormatter';
import { IDeactivateComponent } from '../../../common/shared/auth-guard.service';
import { ConfirmDialogService } from '../../../common/components/confirm-dialog/confirm-dialog.service';
declare var $: any;

import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { ZsClContentService } from '../zs-cl-content.service';
import { AppGlobals } from '../../../common/shared/app.globals';
import { environment } from 'src/environments/environment';


const RIGHT_EMPTY_GRID_MSG = 'Entity attribute list is empty for mapping.';
const LEFT_EMPTY_GRID_MSG = 'No attribute to map.';
const MODAL_EMPTY_GRID_MSG = 'No Data.';

export const toggleFormatter1: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const spacer = '<span style=\'display:inline-block;height:1px;width:' + (15 * dataContext['indent']) + 'px\'></span>';
    value = (dataContext['isNewlyCreated'] && dataContext['isNewlyCreated'] === true) ? '' : value;
    if (dataContext['datatype'] === 'Chain' && value !== '') {
        if (dataContext._collapsed) {
            return spacer + ' <span class=\'map-toggle map-expand\'></span>&nbsp;<span class="text-bold">' + value +
                '</span><span class=\'pull-right\'><i class="fa fa-caret-right expandCollapse" aria-hidden="true"></i></span>';
        } else {
            return spacer + ' <span class=\'map-toggle map-collapse\'></span>&nbsp;<span class="text-bold">'
                + value + '</span><span class=\'pull-right\'><i class="fa fa-caret-down expandCollapse" aria-hidden="true"></i></span>';
        }
    } else {
        return (dataContext['indent'] === 0) ? (spacer + ' <span class=\'map-toggle\'></span>')
                : (spacer + ' <span class=\'map-toggle\'>' + value + '</span>');
    }
};

export const emptyFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return '';
};

@Component({
    selector: 'zetta-ds-map-columns',
    templateUrl: './ds-map-columns.component.html',
    styleUrls: ['./ds-map-columns.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DsMapColumnsComponent implements OnInit, OnDestroy, AfterViewChecked, IDeactivateComponent {

    isDataReady = false;
    isSampelDsLoading = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    modalGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    leftGridMappedAttribute: any = [];
    rightGridDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    modalGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    fieldsMapped = 0;
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    entityAttribute = new Map<string, any>();
    defaultEntityAttributes = [];
    mappedDataSetAttributes = [];
    defaultUnMappedEntityAttributes = [];
    leftGridMappedAttributes=[];
    modalRowData = [];
    entityResp;
    entityRespAfter;
    entityFullResp
    updatedDataSetAttributes: Dataset[] = [];
    leftGridOptions: any;
    rightGridOptions: any;
    modalGridOptions: any;
    leftTableSettings: object = { 'height': "100%", 'width': "100%" };
    rightTableSettings: object = { 'height': "100%", 'width': "100%" };
    leftGridMappingHeader: any;
    hasActiveJob = true;
    loggedInUserDetails: [];
    rightGridDataView: any;
    leftPageNo = 1;
    leftPageSize = 20;
    leftDataColumns;
    hasScrolled: Boolean = false;
    dataset_id;
    catalogs_ids;
    objects_ids;
    catalogs_ids_arr: any = [];
    objects_ids_arr: any = [];
    entitiesResp: any = [];
    count = 0;
    dataviewObj: any;
    gridObj: any;
    catalogs: any;
    objectsList: any = [];
    resp: any = [];
    respOneDrop: any = [];
    job_id: any;

    canGoNextScreen = false;
    defaulMappedtEntityAttributes:any=[];

    flash: any = {};
    xxxxx;
    selectedMappedColumn:number;
    dsName;
    showmodelpopup=false;
    datasetId:any;
    showLoader: Boolean = true;

    constructor(public zettaUtils: ZettaUtils,
        public mapService: MapDataSetsService,
        public service: ProjectService,
        private datasetSvc: DatasetService,
        private catalogSvc: ZsClContentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private messageSvc: MessageService,
        private confirmDialogService: ConfirmDialogService,) { }

    ngOnInit() {

        this.flash.showboard = false;

        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        
        this.activatedRoute.parent.params.subscribe(params => {
            this.dataset_id = params['id'];
        });
    
        this.catalogs_ids = this.activatedRoute.snapshot.queryParamMap.get('catalogs_ids');
        this.catalogs_ids_arr = this.catalogs_ids.split(',');
        this.objects_ids = this.activatedRoute.snapshot.queryParamMap.get('objects_ids');
        this.objects_ids_arr = this.objects_ids.split(',');
        
        this.progressState.states = this.zettaUtils.getStateList('classify-set');
        this.progressState.currentStateIndex = 2;
        this.progressState.currentStateInfo = 'Saved...';
        this.progressState.type = 'classifySet';
        this.progressState.isEdit = false;

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: false,
            enableRowSelection: true,
            mapDataSets: true,
            noDataMsg: RIGHT_EMPTY_GRID_MSG,
        };

        this.modalGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: MODAL_EMPTY_GRID_MSG,
        };
        this.bindGrid();
    }
    bindGrid() {
        let entityData: any = []
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['user_id'],
            dataset_id: this.dataset_id,
            object_id: this.objects_ids,
        };
        this.catalogSvc.getMultiEntityMappedAttributes(data, true, { semantic_ids: this.objects_ids && this.objects_ids.length ? this.objects_ids.split(",") : []}).subscribe(resp => {
            this.defaulMappedtEntityAttributes = resp;
            this.catalogSvc.getMappingAttributes(this.objects_ids, true, {semantic_ids: this.objects_ids && this.objects_ids.length ? this.objects_ids.split(",") : []},true).subscribe(entitiesResp => {
                Object.entries(entitiesResp).forEach(([key, value]) => {
                    entityData = entityData.concat(value);
                });
                this.prepareEntityMapping(entityData);
                this.getMappedAttributesForEntities();
             
                // This is old API call to get the mapping i keep for reference
               // this.setDefaultRightGridDataSources();
               // this.initRightGridRows();
              // this.setDefaultLeftGridDataSources(this.leftPageNo, this.leftPageSize);
            }, error => {
                this.messageSvc.sendMessage({ message: error.error.error, type: 'INFO', hideboard: true });
            });
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }
    getSemanticObject(catalogId) {
        this.catalogSvc.getSemanticsObjects(catalogId).subscribe(resp => {
            resp.forEach(element => {
              element.catalog_id = catalogId;
            });
            this.objectsList = this.objectsList.concat(resp);
        }, err => { });
    }

    getChainedAttribute(atts): string[] {
        const arr: string[] = [];
        atts.map((arrayItem) => {
            if (arrayItem.is_visible_input) {
                arr.push(arrayItem);
                if (arrayItem.chained) {
                    arrayItem.chained.map(element => arr.push(element)
                    );
                }
            }
        });
        return arr;
    }
    setDefaultRightGridDataSources() {        
        this.rightGridDataSource = [];
        this.defaultEntityAttributes.forEach(attribute => {
            this.entityAttribute.set(attribute.physical_name, attribute);
            this.rightGridDataSource.push({
                'id': Math.random(),
                'logical_name': attribute.logical_name,
                'dataset_name': '',
                'target_attribute': 'dropDiv',
                'system_attribute': attribute.physical_name,
                'attribute_id': attribute.attribute_id,
                'sort_order': attribute.sort_order,
                'indent': attribute.indent,
                'parent': attribute.parent,
                'sequenceIndex': attribute.sequenceIndex,
                '_collapsed': attribute._collapsed,
                'data_type': attribute.data_type,
                'chained': attribute.chained,
                'parentattributeid': attribute.parentattributeid,
                'catalog_id': this.catalogs_ids,
                'entity_id': attribute.entity_id,
                'name': attribute.name,
            });
        });
    }

    setDefaultLeftGridDataSources(page, pageSize) {
        this.leftGridDataSource = [];
        let serverFilter = "";
        let serverSort = "";
        if (sessionStorage.getItem("serverfilter")) {
          serverFilter = sessionStorage.getItem("serverfilter");
        }
        if (sessionStorage.getItem("sortfilter")) {
          serverSort = sessionStorage.getItem("sortfilter");
        }
        const dataset: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.dataset_id,
            pageno: page,
            perpage: pageSize
        }
        this.showLoader = true;
        this.datasetSvc.getAllDatasetColumns(dataset, this.dataset_id).subscribe(resp => {
            this.showLoader = false;
            if(resp.length){
                resp.forEach(element => {
                    element.id = Math.random();
                    element.groupedRow = false;
                    element.datasource_name = dataset.datasource_name;
                    const firstRow = this.mappedDataSetAttributes.find(row =>
                        row.dataset_id === element.dataset_id &&
                        row.dataset_column_id === element.dataset_column_id);
                    if (firstRow) {
                        element.mapping = firstRow.attribute_name;
                    }
                });
                this.defaultUnMappedEntityAttributes.push(...resp);
                this.leftDataColumns = resp.length;
                if(this.leftPageNo < resp.totalrecords) {
                    this.hasScrolled = false;
                  }
                this.showLoader = false;
            }
            
            this.initLeftGridRows();
        }, error => {
            this.showLoader = false;
            if (error.status === 404 || error.error.message == 'Not found!') {
                this.messageSvc.sendMessage({ message: 'No columns found for the selected dataset', type: 'ERROR', hideboard: true });
              }     
              else{
                this.messageSvc.sendMessage({ message: error && error.error && error.error.message ? error.error.message: 'something went wrong', type: 'ERROR', hideboard: true });
              }   
        });
    }

    selectOptionDataSetModelList() {
        this.isSampelDsLoading = true;
        setTimeout(() => {
            if (this.entityResp) {
                this.isSampelDsLoading = false;
                this.entityRespAfter = this.entityResp;
            }
        }, 1000);
    }
    initRightGridRows() {
        const parent = this;
        this.rightGridDataSource.forEach(attribute => {
            attribute.id = Math.random();
            const firstRow = parent.defaulMappedtEntityAttributes.filter(row => row.attribute_id === attribute.attribute_id && parseInt(row.dataset_id) === parseInt(this.dataset_id));
            if (firstRow) {
                firstRow.forEach(element => {
                    element['logical_name'] = attribute.logical_name;
                    element['dataset_name'] = element.dataset;
                    element['dataset_id'] = element.dataset_id;
                    element['sort_order'] = attribute.sort_order;
                    element['isNewlyCreated'] = true;
                    element['styleCss'] = 'remove-border';
                    element['target_attribute'] = element.dataset_column;
                    element['name'] = attribute.name,
                    element['entity_id'] = element.entity_id,
                        this.mappedDataSetAttributes.push(element);
                });
            }
        });
        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== AppGlobals.WHOLE_DATA_TYPE) {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });

        // Remove the chain attributes from the right panel
        // let rightGridDataSource = this.rightGridDataSource;
        // this.rightGridDataSource = [];
        // rightGridDataSource.forEach(attribute => {
        //     if(attribute.data_type == 'Child' && attribute.target_attribute == 'dropDiv') {
                
        //     } else {
        //         this.rightGridDataSource.push(attribute);
        //     }
        // });
    }

    removeMapping(row: any, isLeftgrid: boolean) {
        if (!isLeftgrid) {
            let payload = {
                dataset_id: row.dataset_classify_id ? row.dataset_classify_id : row.dataset_id,
                dataset_column_id: row.dataset_column_id,
                removing_attribute_id: parseInt(row.attribute_id),
                mapping_type: "not_sure",
                entity_id: row.entity_id,
                catalog_id: row.catalog_id
            }
            // Commenting this code as this API call is not required to call here as we always get predicted mapping
            // this.catalogSvc.removeMappedColumn(payload).subscribe(resp => {
            //     }, err => {
            //         this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
            //     });

            // Remove Mapped Row from right Grid
            row['dataset_name'] = '';
            // updated Mapped Record data
            this.mappedDataSetAttributes = this.mappedDataSetAttributes.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));

            const leftGridRow = this.leftGrid.dataView.getItems().filter(leftRow => (leftRow.column === row.target_attribute
                && leftRow.mapping === row.logical_name));

            const leftGridDataSourceRow = this.leftGridDataSource.find(leftRow => (leftRow.column === row.target_attribute
                && leftRow.mapping === row.logical_name));

            // Remove Mapped Row from Left Grid
            if (leftGridRow.length > 0) {
                if (leftGridRow[0].groupedRow) {
                    this.leftGrid.gridService.deleteDataGridItem(leftGridRow[0]);
                } else {
                    leftGridRow[0].mapping = undefined;
                    this.leftGrid.gridService.updateDataGridItem(leftGridRow[0], false);
                }
            }
            if (leftGridDataSourceRow) {
                if (leftGridDataSourceRow.groupedRow) {
                    this.leftGridDataSource = this.leftGridDataSource.filter(leftRow => !(leftRow.column === row.target_attribute
                        && leftRow.mapping === row.logical_name));
                } else {
                    leftGridDataSourceRow.mapping = undefined;
                }
            }
            if (this.rightGridDataSource.length) {
                this.rightGridDataSource = this.rightGridDataSource.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                    && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));
            }
            this.saveDataSetMapping(row, 'DELETE', row.dataset_id);
            this.progressState = Object.assign({}, this.progressState);
            this.updateMappingLeftGridColumn(false);
            if (row['isNewlyCreated'] && row['isNewlyCreated'] === true) {
                this.rightGrid.dataView.beginUpdate();
                this.rightGrid.dataView.deleteItem(row['id']);
                this.rightGrid.dataView.endUpdate();
            } else {
                row['target_attribute'] = 'dropDiv';
                this.rightGrid.gridService.updateDataGridItem(row, false);
            }
        }
        // console.log('******************* removeMapping() **********************')
        // console.log(this.mappedDataSetAttributes)

        this.mappingResp();

    }

    groupRows() {
        this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);
        if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.leftGridDataSource);
        }
    }
    initLeftGridRows() {
        this.leftGridDataSource = [];
        this.leftGridDataSource.push(... this.defaultUnMappedEntityAttributes);
        this.defaultUnMappedEntityAttributes.forEach(attribute => {
            const firstRow = this.mappedDataSetAttributes.filter(row => row.dataset_column_id === attribute.dataset_column_id && row.dataset_id === attribute.dataset_id);
            if (attribute.hasOwnProperty('mapping') && firstRow) {
                firstRow.forEach((element, index) => {
                    if (element.logical_name && index > 0) {
                        this.leftGridMappedAttribute.push({
                            'id': Math.random(),
                            'dataset_name': attribute.dataset_name,
                            'column': attribute.column,
                            'mapping': element.logical_name,
                            'dataset_id': attribute.dataset_id,
                            'groupedRow': true
                        });
                    }
                });
        }
        });
        this.leftGridDataSource.push(... this.leftGridMappedAttribute);
        const totalLeftMappedAttribute = this.leftGridDataSource.filter(data => data.mapping).length;
        this.initColDef(totalLeftMappedAttribute);
        this.leftGridRows = this.defaultUnMappedEntityAttributes.length;
        this.groupRows();
        this.isDataReady = true;
    }

    getRightGridColumns() {
        return [
            {
                'displayname': '',
                'physicalname': '',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'minWidth': 30,
                'maxWidth': 30,
                'formatter': emptyFormatter,
            }, {
                'displayname': 'Attribute',
                'physicalname': 'logical_name',
                'sortable': false,
                'datatype': 'String',
                'filterable': true,
                'params': { 'rootObj': this },
                'formatter': toggleFormatter,
                'cssClass': 'border-right',
            }, {
                'displayname': 'Mapping',
                'physicalname': 'target_attribute',
                'sortable': false,
                'datatype': 'String',
                'filterable': true,
                'formatter': dropFormatter
            }
        ];
    }

    initColDef(totalLeftMappedAttribute) {
        this.leftGridColumnsDef = this.mapService.getLeftGridColumns(totalLeftMappedAttribute);
        this.rightGridColumnsDef = this.getRightGridColumns();
        this.modalGridColumnsDef = this.mapService.getModalGridColumns();
    }

    rightGridCreated(grid) {
        this.rightGrid = grid;
        this.buildParentAndChildRelation(this.rightGridDataSource);
        this.rightGridDataView = this.rightGrid.dataView;
        this.rightGridDataView.beginUpdate();
        this.rightGridDataView.setFilter(this.myFilter);
        this.rightGridDataView.setFilterArgs(this.rightGridDataSource);
        this.rightGridDataView.endUpdate();

        const self = this;
        grid.slickGrid.onCellChange.subscribe(function (e, args) {
            self.rightGrid.dataView.updateItem(args.item.id, args.item);
        });

        grid.slickGrid.onClick.subscribe(function (e, args) {
            if ($(e.target).hasClass('expandCollapse')) {
                const rowitem = self.rightGrid.dataView.getItem(args.row);
                if (rowitem) {
                    if (!rowitem._collapsed) {
                        rowitem._collapsed = true;
                        self.hidingParentElement(rowitem);
                    } else {
                        rowitem._collapsed = false;
                        self.hidingParentElement(rowitem);
                    }
                    self.rightGrid.dataView.updateItem(rowitem.id, rowitem);
                }
                e.stopImmediatePropagation();
            }
        });

        // wire up model events to drive the grid
        self.rightGrid.dataView.onRowCountChanged.subscribe(function (e, args) {
            grid.slickGrid.updateRowCount();
            grid.slickGrid.render();
            self.showHideDragDrop();
           // self.removeBorders();
        });

        grid.slickGrid.onScroll.subscribe(function (e, args) {
            self.showHideDragDrop();
            //self.removeBorders();
        });

        self.rightGrid.dataView.onRowsChanged.subscribe(function (e, args) {
            grid.slickGrid.invalidateRows(args.rows);
            grid.slickGrid.render();
            self.showHideDragDrop();
            //self.removeBorders();
        });

        this.dataviewObj = this.rightGrid.dataView;
        this.gridObj = this.rightGrid.slickGrid; // grid object

       this.groupBySemanticObject();

    }

    groupBySemanticObject() {
        this.dataviewObj.setGrouping([
          {
            getter: 'name',
            formatter: (g) => {
              return `<span class="text-bold" style="color:#505050">${g.value}</span>`;
            },
            aggregators: [],
            collapsed: false,
            lazyTotalsCalculation: true
          }
        ] as Grouping[]);
      }

      popupoutput(message){
        this.showmodelpopup = message;   
      }

    hidingParentElement(row) {
        const self = this;
        self.mappedDataSetAttributes.forEach(element => {
            const rightTableDS = self.rightGridDataView.getItems();
            // get the index of element
            const index = rightTableDS.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = rightTableDS[index];
                if (parentObj.parentattributeid === -1 && row.attribute_id === parentObj.attribute_id) {
                    if (!row._collapsed) {
                        parentObj['dataset_name'] = element['dataset_name'];
                        parentObj['target_attribute'] = 'dropDiv';
                        setTimeout(() => {
                            self.rightGridDataView.deleteItem(element.id);
                        }, 10);
                    } else {
                        element['parent'] = parentObj.parent;
                        element['parentattributeid'] = parentObj.parentattributeid;
                        element['sequenceIndex'] = parentObj.sequenceIndex;
                        element['_collapsed'] = parentObj._collapsed;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        self.rightGridDataView.insertItem(index + 1, element);
                        parentObj['dataset_name'] = '';
                    }
                    self.rightGrid.dataView.updateItem(parentObj.id, parentObj);
                }
            }
        });
    }

    showHideDragDrop() {
        $('.fa-caret-right').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').show();
        });
        $('.fa-caret-down').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').hide();
        });
    }

    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    myFilter(item, args) {
        if (item.parent != null) {
            let parent = args[item.parent.id];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = args[parent.parent ? parent.parent.id : null];
            }
        }
        return true;
    }

    onModelGridCreation(grid) {
        this.modalGrid = grid;
    }

    // TO Do clean up--- Save Project and run model
    updateProject(next: boolean) {
        // Add Validation for all Datasets
        const project = new Project();
        project.project_id = this.projectDetail.project_id;
        // project.user_id = this.projectDetail.user_id;
        project.user_id = this.loggedInUserDetails['user_id'];
        project.tenant_id = this.projectDetail.tenant_id;
        project.datasets = [...this.updatedDataSetAttributes];

        // Convert as per api
        project.datasets = project.datasets.map(dataset => {
            dataset.attributes.forEach(attribute => {
                attribute['id'] = undefined;
                attribute['logical_name'] = undefined;
                attribute['dataset_name'] = undefined;
            });
            return dataset;
        });

        // this.service.saveProject(project).subscribe(resp => {
        //     this.updatedDataSetAttributes = [];
        //     $('#projectState3').removeClass('d-none');
        //     setTimeout(function () {
        //         $('#projectState3').addClass('d-none');
        //     }, 5000);
        //     if (next) {
        //         // Scenario id is 0 for this case
        //         this.service.runModel(project.project_id, 0).subscribe(resp => {
        //             this.goToNext();
        //         }, err => {
        //             // alert(err.error.message);
        //             this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        //         });
        //     }
        // }, err => {
        //     // alert(err.error.message);
        //     this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        // });
    }

    // Save Project Mapping only
    updateProjectMapping(datasets: Dataset[]) {
        // Add Validation for all Datasets
        const project = new Project();
        project.project_id = this.projectDetail.project_id;
        // project.user_id = this.projectDetail.user_id;
        project.user_id = this.loggedInUserDetails['user_id'];
        project.tenant_id = this.projectDetail.tenant_id;
        project.datasets = [...datasets];

        // Convert as per api
        project.datasets = project.datasets.map(dataset => {
            dataset.attributes.forEach(attribute => {
                attribute['id'] = undefined;
                attribute['logical_name'] = undefined;
                attribute['dataset_name'] = undefined;
            });
            return dataset;
        });

        // this.service.saveProject(project).subscribe(resp => {
        //     $('#projectState3').removeClass('d-none');
        //     setTimeout(function () {
        //         $('#projectState3').addClass('d-none');
        //     }, 5000);

        // }, err => {
        //     this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        // });
    }

    onPrevious() {
        // this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'datasets'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/catalogs/objects'], { queryParams: { catalogs_ids: this.catalogs_ids,  objects_ids: this.objects_ids } });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }
    
    onSaveClose() {
        this.updateEntityMapping();
        this.canGoNextScreen = true;
        this.messageSvc.sendMessage({ message: 'Changes Saved Successfully', type: 'SUCCESS', hideboard: true });
        setTimeout(() => {
            this.router.navigate(['/zs/datasets/' + this.dataset_id], { queryParams: { showBreadcrumb: true } });
        }, 300);
    }

    onRunModel() {
         // commenting this code because classify api call is not requrired when we click on run model button, 
        // it's already gets called when we drag and drop attribute from left to right grid
        
        // if(this.resp.length<1) {
        //     this.mappingResp();
        // } else {
        //     let payload = [];
        //     this.resp.forEach(resp => {
        //         if(resp && resp.entities && resp.entities.length) {
        //             payload.push(resp);
        //         } else {
        //             return;
        //         }
        //     });
        //     this.resp = payload;
        // }
        // // TO Do clean up below code
        // this.catalogSvc.runModelClassify(this.dataset_id, this.resp).subscribe(resp => {
        //     this.job_id = resp.job_id;
           this.goNext(null);
        // }, err => {
        //     this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
        // });
    }

    onRunModelClick() {
        this.updateEntityMapping();
        this.goNext(null);
    }
    goNext(jobId) {
        this.catalogSvc.runModelMapDataColumn(+this.dataset_id, jobId).subscribe(respo => {            
            this.canGoNextScreen = true;
            this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Dataset', messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:'zs/datasets/'+this.dataset_id, queryParams: { showBreadcrumb: true } });           
        }, err => {
            this.messageSvc.sendMessage({ message: err.error.error.message, type: 'ERROR', hideboard: true });
        });

        
    }

    goToNext() {
        this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
    }

    // removeBorders() {
    //     $('.remove-border').each(function () {
    //         $(this).parent().parent().children('.slick-cell').each(function () {
    //             $(this).attr('id', 'remove-border-bottom');
    //         });
    //     });
    // }

    onDrop(draggedRowIndex, droppedRowIndex) {
        // console.log('onDrop')
        // console.log(draggedRowIndex)
        // console.log(droppedRowIndex)
        const draggedRowData = this.leftGrid.gridService.getDataItemByRowIndex(draggedRowIndex);
        const droppedRowData = this.rightGrid.gridService.getDataItemByRowIndex(droppedRowIndex);
        // console.log('----- draggedRowData')
        // console.log(draggedRowData)
        // console.log('----- droppedRowData')
        // console.log(droppedRowData)
        const newRightRow = {
            'id': Math.random(), 
            'logical_name': droppedRowData.logical_name, 
            'dataset_name': draggedRowData.dataset_name,
            'target_attribute': draggedRowData.column, 
            'system_attribute': droppedRowData.system_attribute,
            'attribute_id': droppedRowData.attribute_id, 
            'dataset_id': draggedRowData.dataset_id, 
            'sort_order': droppedRowData.sort_order,
            'authoritativeness': draggedRowData.authoritativeness,
            'dataset_column_id': draggedRowData.dataset_column_id,
            'entity_id': droppedRowData.entity_id,
            'catalog_id': parseInt(droppedRowData.catalog_id)
        };
        const newLeftRow = {
            'id': Math.random(), 
            'dataset_name': draggedRowData.dataset_name, 
            'column': draggedRowData.column,
            'mapping': droppedRowData.logical_name, 
            'dataset_id': draggedRowData.dataset_id, 
            'groupedRow': true
        };

        const existingMapping = this.getDuplicate(newRightRow);
        if (existingMapping) {
            this.messageSvc.sendMessage({ message: `Mapping already exists.`, type: 'INFO', hideboard: true });
        } else {
            this.saveDataSetMapping(newRightRow, 'ADD', draggedRowData.dataset_id);            
            this.mappedDataSetAttributes.push(newRightRow);
            const coloneAttribute = Object.assign({}, droppedRowData);
            coloneAttribute['isNewlyCreated'] = false;
            if (droppedRowData['dataset_name'] !== '') {
                const dropIndex = (parseInt(droppedRowIndex, 10) + 1);
                coloneAttribute.id = newRightRow.id;
                coloneAttribute['styleCss'] = 'remove-border';
                droppedRowData['styleCss'] = 'remove-border';
                coloneAttribute['isNewlyCreated'] = true;
                coloneAttribute['dataset_id'] = newRightRow.dataset_id;
                coloneAttribute['target_attribute'] = newRightRow.target_attribute;
                coloneAttribute['dataset_name'] = newRightRow.dataset_name;
                coloneAttribute['dataset_column_id'] = newRightRow.dataset_column_id;
               // this.rightGridDataView.insertItem(dropIndex, coloneAttribute);
                let rowData = this.rightGridDataView.getItems();
                const grid_index = rowData.findIndex(item => item.attribute_id === coloneAttribute.attribute_id);
                rowData.splice(grid_index + 1, 0, coloneAttribute);
                this.rightGridDataView.setItems(rowData);
                const index = this.rightGridDataSource.findIndex(item => item.attribute_id === coloneAttribute.attribute_id);
                this.rightGridDataSource.splice(index + 1, 0, coloneAttribute);
                //this.removeBorders();
            } else {
                droppedRowData['dataset_id'] = newRightRow.dataset_id;
                droppedRowData['target_attribute'] = newRightRow.target_attribute;
                droppedRowData['dataset_name'] = newRightRow.dataset_name;
                droppedRowData['authoritativeness'] = newRightRow.authoritativeness;
                droppedRowData['dataset_column_id'] = newRightRow.dataset_column_id;
            }
            this.rightGrid.gridService.updateDataGridItem(droppedRowData, false);
            this.onDropUpdateLeftGridRow(true, newLeftRow);
            this.updateMappingLeftGridColumn(true);

            this.mappingResp();
            this.mappingRespOneDrop(newRightRow);
            // This is the old flow it call the API when user drag and drop the attribute
           // this.catalogSvc.runModelClassify(this.dataset_id, this.respOneDrop).subscribe(resp => {  }, err => {  });
        }
        // console.log('this.mappedDataSetAttributes ********************* onDrop() ********************')
        // console.log(this.mappedDataSetAttributes)
    }


    mappingResp() {
        let catalogs = [];
        this.mappedDataSetAttributes.forEach(row => {
            catalogs.push(row.catalog_id)
        });
        var catalogsU = Array.from(new Set(catalogs))
        catalogsU.forEach((cat, i) => {
            this.resp.push(
                {
                    "catalog_id": +cat,
                    "entities": []
                }
            )
            let objects = [];
            this.mappedDataSetAttributes.forEach(row => {
                if (parseInt(row.catalog_id) === parseInt(cat)) {
                    objects.push(row.entity_id, row.dataset_classify_id);
                }
            });
            var objectsU = Array.from(new Set(objects))
            objectsU.forEach((obj, j) => {
                this.resp[i]['entities'][j] = {
                    'entity_id': +obj,
                    'mappings': []
                }
                const availableAttributes = this.mappedDataSetAttributes.filter(attr => parseInt(attr.catalog_id) === parseInt(cat));
                if (availableAttributes.length) {
                    availableAttributes.forEach((catmap, k) => {
                        if (parseInt(catmap.catalog_id) === parseInt(cat) && parseInt(catmap.entity_id) === parseInt(obj)) {
                            this.resp[i]['entities'][j]['mappings'][k] = {
                                'dataset_column_id': +catmap.dataset_column_id,
                                'attribute_id': +catmap.attribute_id,
                                'dataset_classify_id ': +catmap.dataset_classify_id
                            }
                        }
                    });
                }
            });
        });
    }

    mappingRespOneDrop(data) {
        this.respOneDrop = [];
        let catalogs = [];
        this.mappedDataSetAttributes.forEach(row => {
            catalogs.push(row.catalog_id)
        });
        var catalogsU = Array.from(new Set(catalogs))
        catalogsU.forEach((cat, i) => {
            this.respOneDrop.push(
                {
                    "catalog_id": +cat,
                    "entities": []
                }
            )
            let objects = [];
            this.mappedDataSetAttributes.forEach(row => {
                if (parseInt(row.catalog_id) === parseInt(cat)) {
                    objects.push(row.entity_id);
                }
            });
            var objectsU = Array.from(new Set(objects))
            objectsU.forEach((obj, j) => {
                this.respOneDrop[i]['entities'][j] = {
                    'entity_id': +obj,
                    'mappings': []
                }
                
                const availableAttributes = this.mappedDataSetAttributes.filter(attr => parseInt(attr.catalog_id) === parseInt(cat));
                if (availableAttributes.length) {
                    let payload = {
                        'dataset_column_id': data.dataset_column_id,
                        'attribute_id': data.attribute_id
                    };
                    this.respOneDrop[i]['entities'][j]['mappings'].push(payload);
                }
            });
        });
    }

    onDropUpdateLeftGridRow(addRow: boolean, row: any) {
        if (addRow) {
            const leftGridDatasetRows = this.leftGrid.dataView.getItems();
            const newFirstRow = leftGridDatasetRows.find(leftRow => row.column === leftRow.column && row.dataset_id === leftRow.dataset_id && !leftRow.hasOwnProperty('mapping'));
            //const firstRow = this.leftGridDataSource.find(leftRow => row.column === leftRow.column && row.dataset_id === leftRow.dataset_id && leftRow.mapping === undefined && leftRow.groupedRow === false);  // kept for reference
            if (newFirstRow) {
                newFirstRow.mapping = row.mapping;
                this.leftGrid.gridService.updateDataGridItem(newFirstRow, false);
            } else {
                this.leftGridDataSource.push(row);
                this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);

                let currentLeftGridRow = this.leftGrid.dataView.getItems();
                currentLeftGridRow.push(row);
                currentLeftGridRow = _.orderBy(currentLeftGridRow, ['dataset_name', 'column'], ['asc']);
                this.leftGrid.dataView.setItems(currentLeftGridRow);
            }
            this.progressState = Object.assign({}, this.progressState);
        }
    }

    ngAfterViewChecked() {
        const self = this;
        $('.dropDiv').droppable({
            drop: function (event, ui) {
                const dragElement = $(ui.draggable).find('div.dragMe');
                if (dragElement === undefined || dragElement === null || dragElement.length === 0) {
                    return;
                }
                const droppedRowIndex = $(this).attr('value');
                const draggedRowIndex = dragElement.attr('value');
                self.onDrop(draggedRowIndex, droppedRowIndex);
                $(this).removeClass('drag-enter');
            },
            over: function (event, ui) {
                $(this).addClass('drag-enter');
            },
            out: function (event, ui) {
                $(this).removeClass('drag-enter');
            }
        });
    }

    onRightGridCellClicked(params) {
        const event = params.eventData;
        const args = params.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);

        if (event.target.className === 'fa fa-minus deleteBtn') {
            this.removeMapping(row, false);
        }
    }

    onDatasetCellClick(e): void {    
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
        this.selectedMappedColumn = args.row;
        if (row !== undefined && row !== null) {
          if (eventEle.target.textContent === row.dataset_name) {
            this.dsName = row.dataset_name;           
            this.showmodelpopup=true;
            this.datasetId=row.dataset_id;
          }
          
        }
      }

    getDuplicate(newRightGridRow: any) {
        return this.mappedDataSetAttributes.find(existingMapping => {
            return existingMapping.logical_name === newRightGridRow.logical_name &&
                existingMapping.target_attribute === newRightGridRow.target_attribute &&
                existingMapping.dataset_name === newRightGridRow.dataset_name;
        });
    }

    isMappingValid(): boolean {
        return (this.hasActiveJob || !this.mappedDataSetAttributes.length) ? false : true;
    }

    updateMappingLeftGridColumn(addRow: boolean) {
        let prevValue: number;
        if (addRow) {
            prevValue = this.mappedDataSetAttributes.length - 1;
        } else {
            prevValue = this.mappedDataSetAttributes.length + 1;
        }

        if (this.leftGridMappingHeader === undefined) {
            const allHeaders = $('.slick-header-column');
            for (let i = 0; i < allHeaders.length; i++) {
                const existingTooltip = $(allHeaders[i]).attr('title');
                if (existingTooltip === `Mapping(${prevValue})`) {
                    this.leftGridMappingHeader = allHeaders[i];
                }
            }
        }
        if (this.leftGridMappingHeader) {
            $(this.leftGridMappingHeader).attr('title', `Mapping(${this.mappedDataSetAttributes.length})`);
            const headerCell = this.leftGridMappingHeader.children[0];
            if (headerCell) {
                $(headerCell).html(`Mapping(${this.mappedDataSetAttributes.length})`);
            }
        }
    }

    getEntityName() {
        if (this.projectDetail.entity_name) {
            return this.projectDetail.entity_name;
        }
    }

    saveDataSetMapping(input: any, opcode: string, selectedDataSetId: number) {
        // create dataset from mapping
        const attribute = Object.assign({}, input);
        const dataSet = new Dataset();
        dataSet.dataset_id = selectedDataSetId;
        dataSet.attributes = [];
        attribute.opCode = opcode;
        dataSet.attributes.push(attribute);
        // save in DB
        this.updateProjectMapping([dataSet]);
    }

    // TO Do: Code clean if not required
    populateUpdateDatasetAttributeMap(input: any, opcode: string, selectedDataSetId: number): boolean {
        const selectedDataset = this.updatedDataSetAttributes.find(dataset => dataset.dataset_id === selectedDataSetId);
        if (selectedDataset) {
            const updateAttribute = selectedDataset.attributes.find(attribute => attribute.system_attribute === input.system_attribute && attribute.target_attribute === input.target_attribute);
            if (updateAttribute && opcode !== updateAttribute.opCode) {
                // Filter it
                selectedDataset.attributes = selectedDataset.attributes.filter(attribute => !(attribute.system_attribute === input.system_attribute && attribute.target_attribute === input.target_attribute));
            } else {
                input.opCode = opcode;
                selectedDataset.attributes.push(input);
            }
        } else {
            // Add Dataset first time
            const dataSet = new Dataset();
            dataSet.dataset_id = selectedDataSetId;
            dataSet.attributes = [];
            input.opCode = opcode;
            dataSet.attributes.push(input);
            this.updatedDataSetAttributes.push(dataSet);
        }
        return true;
    }

    getLeftGridCount(): number {
        if (this.leftGrid) {
            const filterRows = this.leftGrid.dataView.getItems().filter(row => !row.groupedRow);
            return filterRows.length;
        } else {
            return this.defaultUnMappedEntityAttributes.length;
        }
    }

    isPropRootKey(rootObject, prop) {
        return rootObject.hasOwnProperty(prop);
    }
    prepareEntityMapping(dataSet) {
        let counter = -1;
        const chaninedItems = [];
        dataSet.filter(function (item) {
            if (item['data_type'] === AppGlobals.WHOLE_DATA_TYPE) { chaninedItems.push(...item['chained']); } return item['data_type'] === AppGlobals.WHOLE_DATA_TYPE;
        });
        for (let chainedIndex = 0; chainedIndex < chaninedItems.length; chainedIndex++) {
            const chainedElement = chaninedItems[chainedIndex];
            // loop parent obj
            for (let parentIndex = 0; parentIndex < dataSet.length; parentIndex++) {
                const parentElement = dataSet[parentIndex];
                if ((chainedElement.attribute_id === parentElement.attribute_id) && (!parentElement.hasOwnProperty('chained') || !chainedElement.hasOwnProperty('chained'))) {
                    dataSet.splice(parentIndex, 1);
                    break;
                }
            }
        }
        this.defaultEntityAttributes = [];
        dataSet.forEach((attribute) => {
            counter += 1;
            attribute['indent'] = 0;
            attribute['parent'] = null;
            attribute['sequenceIndex'] = counter;
            attribute['parentattributeid'] = -1;
            attribute['_collapsed'] = false;
            this.defaultEntityAttributes.push(attribute); // expand root element
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === AppGlobals.WHOLE_DATA_TYPE && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['indent'] = 1;
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                    chainElement['parentattributeid'] = attribute['attribute_id'];
                    chainElement['_collapsed'] = false;
                    this.defaultEntityAttributes.push(chainElement);
                }
            }
        });
        // console.log('defaultEntityAttributes -----------------')
        // console.log(this.defaultEntityAttributes)
    }    

    buildParentAndChildRelation(dataSource) {
        let counter = -1;
        dataSource.forEach((attribute) => {
            counter += 1;
            attribute['sequenceIndex'] = counter;
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === AppGlobals.WHOLE_DATA_TYPE && attribute.chained && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                }
            }
        });
        dataSource.forEach((attribute) => {
            const filteredData = dataSource.filter(data => data.parentattributeid === attribute.attribute_id);
            if (filteredData.length > 1) {
                const index = dataSource.findIndex(item => item.attribute_id === attribute.attribute_id);
                for (let chainIndex = 0; chainIndex < filteredData.length; chainIndex++) {
                    const chainElement = filteredData[chainIndex];
                    chainElement['parent'] = { id: index };
                }
            }
        });
    }

    onCancel() {
        this.router.navigate(['/zs/datasets']);
    }

    onPreviousStep(): void {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/catalogs/objects'], { queryParams: { catalogs_ids: this.catalogs_ids,  objects_ids: this.objects_ids } });
    }

    onMessageCancel() {
        return false
    }

    onMessageYes() {
        return true
    }

    showDialog() {  
        const parent = this;
        parent.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
            // alert("Yes clicked");  
            parent.xxxxx = true;
            console.log(parent.xxxxx)
            // return 'Yes'
        }, function () {  
            // alert("No clicked");
            parent.xxxxx = false;
            console.log(parent.xxxxx)
            // return 'No'  
        })  
        
    } 

    canExit(): boolean {
        // this.showDialog();
        // console.log(qqq)
        console.log(this.mappedDataSetAttributes.length)
        console.log(this.canGoNextScreen)
        if (this.mappedDataSetAttributes.length > 0 && !this.canGoNextScreen) {
            if (confirm("Your data will be lost if you leave this screen. Are you sure?")) {
                return true
            } else {
                // console.log(this.mappedDataSetAttributes.length)
                // const xxx = this.messageSvc.sendMessage({ message: `Your data will be lost if you leave this screen. Are you sure?`, type: 'WARN2', hideboard: false });
                // console.log(xxx)
                this.flash.type = 'WARN2';
                this.flash.message = "Your data will be lost if you leave this screen. Are you sure?";
                this.flash.showboard = false;
                // console.log(this.onMessageCancel())
                return false
            }
        } else {
            return true
        }

        const parent = this;
        parent.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
            // alert("Yes clicked");  
            parent.xxxxx = true;
            console.log(parent.xxxxx)
            return true
        }, function () {  
            // alert("No clicked");
            parent.xxxxx = false;
            console.log(parent.xxxxx)
            return false  
        })  

        // return true

    }

    onLeftColumns(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled) {
          this.hasScrolled = true;
          //this.onNextPageView();
        }
      }
    
      onNextPageView(): void {
        this.leftPageNo++;
        this.setDefaultLeftGridDataSources(this.leftPageNo, this.leftPageSize);
      }

    getMappedAttributesForEntities() {
        let data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogs_ids,
            semanticIds: this.objects_ids,
            dataset_ids: this.dataset_id
        };
        this.catalogSvc.getMappingsForEntities(data, true, {semantic_ids: this.objects_ids && this.objects_ids.length ? this.objects_ids.split(",") : []}).subscribe(resp => {
            this.rightGridDataSource = [];
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction(resp);
        }, error => {
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsHighPrediction([]);
            this.showLoader = false;
        });
    }

    initRightGridRowsHighPrediction(maapedAttribute) {
        const parent = this;
        this.rightGridDataSource.forEach(attribute => {
            attribute.id = Math.random();
            const mappedAttributeForDataset = maapedAttribute.filter(row => row.attribute_id === attribute.attribute_id && parseInt(row.dataset_id) === parseInt(this.dataset_id));
            if (mappedAttributeForDataset.length) {
                mappedAttributeForDataset.forEach(element => {
                    element['logical_name'] = attribute.logical_name;
                    element['dataset_id'] = element.dataset_id;
                    element['sort_order'] = attribute.sort_order;
                    element['isNewlyCreated'] = true;
                    element['styleCss'] = 'remove-border';
                    element['target_attribute'] = element.column_name;
                    element['name'] = attribute.name,
                    element['entity_id'] = element.entity_id,
                    this.mappedDataSetAttributes.push(element);
                });
            }
        });
        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== AppGlobals.WHOLE_DATA_TYPE) {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });
        parent.rightGridDataSource.forEach((element) => {
            element.id = Math.random();
        });
        this.setDefaultLeftGridDataSources(this.leftPageNo, this.leftPageSize);
    }

    updateEntityMapping() {
        let attributes = [];
        const entities = [];
        const mappedData = this.rightGrid.dataView.getItems();
        this.objects_ids_arr.forEach(entity_id => {
            attributes = [];
            mappedData.forEach(attr => {
                if (attr.dataset_column_id && attr.entity_id === +entity_id) {
                    attributes.push({
                        dataset_column_id: attr.dataset_column_id,
                        attribute_id: attr.attribute_id,
                    });
                }
            });
            if (attributes.length) {
                entities.push({
                    entity_id: +entity_id,
                    mappings: attributes
                });
            }
        });

        const payload = [
            {
                catalog_id: +this.catalogs_ids,
                entities: entities
            }
        ];
        this.catalogSvc.runModelClassify(this.dataset_id, payload).subscribe(resp => {
        }, err => { });
    }

}