import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEntityPrams } from './datasource.model';

@Injectable({
    providedIn: 'root'
})
export class DatasourceService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    public getDatasources(param: any,serverFilter='',sortfilter=''): Observable<any> {
        if (param.pageno && param.perpage) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources?pageno=${param.pageno}&pagesize=${param.perpage}${serverFilter}${sortfilter}`);
        } else if(param.query) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources?${param.query}`);
        } else {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources?${sortfilter}`);
        }
    }

    public createDatasource(param: IEntityPrams): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources`, param.payload);
    }

    public updateDatasource(param: IEntityPrams): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}`,
            param.payload);
    }

    public getDatasource(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.typeId}`);
    }

    public getDatasourceTypes(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/types`);
    }

    public testAndValidate(param: any): Observable<any> {
        if (param.sourceType === 'Cloud Object Storage') {
            if(param.dir){
                const lastChar =  param.dir.slice(-1);
                if(lastChar !== '/'){
                    param.dir = `${param.dir}/`;
                }
            }
            const dirQuery= param.cloudProvider === 328 ? param.dir ? `&dir=${param.dir}`:'' :'';
            return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/validate/cloud?cloudProvider=${param.cloudProvider}${dirQuery}`, param.payload);
            //return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/testconnection/cloud/azure`, param.payload);
        } else if (param.sourceType === 'Hadoop') {
            return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/testconnection/cloud/azure`, param.payload);
        } else if (param.sourceType === 'Relational Database') {
            return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/connection`, param.payload);
        } else if (param.sourceType === 'Snowflake Data Warehouse') {
            return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/connection`, param.payload);
        }
    }

    public getDataTables(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/types`);
    }

    public getGroups(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/groups`);
    }

    public getUsers(param: IEntityPrams, serverFilter = '', sortfilter = ''): Observable<any> {
        if (serverFilter || sortfilter) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/groups/${param.groupId}/users?${serverFilter}${sortfilter}`);
        }
        else {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/groups/${param.groupId}/users`);
        }
    }

    public getActivityHistory(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/activities`);
    }

    public getDatasourceContents(param: any, serverFilter = '', sortfilter = ''): Observable<any> {
        let dirQuery = '';
        let ignoreType = 'ignoreType=true';
        let paginateQuery = `paginate=true&pageno=${param.pageNo}&pagesize=${param.limit}${serverFilter}${sortfilter}`;
        if (param.datasource_type === 'Cloud Object Storage') {
            param.dir = param.dir ? param.dir : `data/${param.tenant_id}/raw/`;
            const lastChar = param.dir.slice(-1);
            if (lastChar !== '/') {
                param.dir = `${param.dir}/`;
            }
        }
        dirQuery = param.dir ? `?dir=${param.dir}&${paginateQuery}&${ignoreType}` : `?${ignoreType}&${paginateQuery}`;
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/contents${dirQuery}`);
    }
    public getDatasourceDirContents(param: IEntityPrams,dirname:any): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/contents?dir=${dirname}`);
    }

    public getDatasourceEntitlements(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/userentitlements`);
    }

    public getDatasourceGroupsUsers(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/contents/entitlements`);
    }

    public getDatasourceUsersAndGroupUsers(datasourceGroupUsersParams, datasourceUsersParams): Observable<any> {
        let datasourceGroupUsers = this.http.get(`${this.apiUrl}/users/${datasourceGroupUsersParams.userId}/tenants/${datasourceGroupUsersParams.tenant_id}/datasources/${datasourceGroupUsersParams.sourceId}/contents/entitlements`);
        let datasourceUsers = this.http.get(`${this.apiUrl}/users/${datasourceUsersParams.userId}/tenants/${datasourceUsersParams.tenant_id}/datasources/${datasourceUsersParams.typeId}/users`);
        return forkJoin([datasourceGroupUsers, datasourceUsers])
        .pipe(map(([datasourceGroupUsersData, datasourceUsersData]) => ({datasourceGroupUsersData, datasourceUsersData})));
    }

    public updateDefineConnection(param: IEntityPrams): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}`, param.payload);
    }

    public updateDatasourceEntitlements(param: IEntityPrams): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/userentitlements`, param.payload);
    }

    public saveDatasourceEntitlements(param: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${param.user_id}/tenants/${param.tenant_id}/datasources/${param.datasource_id}/contents`, param.payload);
    }

    public updateUsersToDatasource(param: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.user_id}/tenants/${param.tenant_id}/datasources/${param.datasource_id}/users`, param.payload);
    }

    public getUsersToDatasource(param: any, serverFilter = '', sortfilter = ''): Observable<any> {
        if (serverFilter || sortfilter) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.typeId}/users?${serverFilter}${sortfilter}`);
        }
        else {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.typeId}/users`);
        }
    }

    public createAllDataset(param: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasources/${param.sourceId}/datasets?bulk=true&source=contents`, {});
    }

    public getDataCloudStorageLayer(group_name:string): Observable<any> {
        return this.http.get(`${this.apiUrl}/lookups?group=${group_name}`);
    }

    public getEntityAdminUsers(param): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/catalogs/${param.catalogId}/semantics/${param.semanticId}/users?only_admins=true`);
    }

    public updateEntityUsers(param: any, userPayload): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.user_id}/catalogs/${param.catalogId}/semantics/${param.semanticId}/users`, userPayload);
    }
    
}
