import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../shared/zettaUtils';
import { Formatter, Column } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { MessageService } from '../../message/message.service';
import { ContentService } from '../../../../zettasense/content/content.service';

const editSystemSettingFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<span><i class="ml-10"></i></span>
          <a href="#">Edit</a>`;
};

@Component({
  selector: 'zetta-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SystemSettingsComponent implements OnInit, OnDestroy {

  public systemSettingDataSource = [];
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "99%", 'pageSize': 15, 'isNormalPagination': false };
  systemSettingTableColDef: any;
  gridOptions: any;
  isLoading = true;
  noData = false;
  login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	is_visible_system=false;	
	is_enable_system=false;

  constructor(
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService,
    public contentSvc: ContentService) {
    if (this.login_user) {
      try {
        let user_entitlements = this.login_user[0].entitlement;
        if (this.login_user.length > 1) {
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if (user_entitlements.length) {
            user_entitlements = user_entitlements[0].entitlement;
          } else {
            user_entitlements = this.login_user[0].entitlement;
          }
        }
        const admin = user_entitlements.screens.filter(item => item.name == 'admin')[0];
        if (admin) {
          const system_settings = admin.childs.filter(item => item.name == 'manage_system_setting')[0];
          if (system_settings) {
            this.is_visible_system = system_settings.visible;
            this.is_enable_system = system_settings.enable;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnInit() {
    this.getSystemSettingList();
    this.systemSettingTableColDef = [{
      'displayname': 'Setting',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 240,
      'formatter': '',
    }, {
      'displayname': 'Parameter Value',
      'physicalname': 'value',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': null,
      'minWidth': 120,
    }, {
      'displayname': 'Action',
      'physicalname': '',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': editSystemSettingFormattter,
      'minWidth': 120,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
    };
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getSystemSettingList() {
    this.contentSvc.getSystemSettings().subscribe((data) => {
        this.systemSettingDataSource = data;
        if (this.systemSettingDataSource.length === 0) {
          this.noData = true;
        }
        this.isLoading = false;
      }, err => {
        this.messageSvc.sendMessage({ message: 'Get list of system settings failed', type: 'INFO', hideboard: true });
        this.isLoading = false;
      });
  }
}
