import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-admin-checkbox-renderer',
  template: `
   
    <div class="formatter match-radio-formatter d-flex justify-content-center">
    <input type="radio" [checked]="params.data.role_id === 39" />
    <label for="data-{{ params.rowIndex }}" class="radio-formatter radio-label notsure-formatter ml-3"></label>
  </div>
  `,
})
export class AdminCheckboxRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
