import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-business-entity-level-rules',
  templateUrl: './business-entity-level-rules.component.html',
  styleUrls: ['./business-entity-level-rules.component.scss'],
})
export class BusinessEntityLevelRulesComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
    
    
  }
  
}
