import { Injectable, EventEmitter, HostListener  } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, CanLoad, Route, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService, BroadcastService } from '@azure/msal-angular';

export interface IDeactivateComponent {
  canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuard implements CanLoad, CanDeactivate<IDeactivateComponent> {

  constructor(private router: Router, private authService: MsalService) {
    const self = this;
  }

  // @HostListener('window:beforeunload')
  canDeactivate(component: IDeactivateComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    return component.canExit ? component.canExit() : true;
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    const self = this;
    const callback = () => {
      if (!self.checkLogin(url)) {
        return false;
      }
      if (!self.checkIsAdmin(url)) {
        return false;
      }
      return true;
    };
    return callback();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    const isloggedIn = sessionStorage.getItem('isloggedIn') === 'true' ? true : false;
    const userLoggedInFromAzure = !!this.authService.getAccount();
    console.log('Check Login:', userLoggedInFromAzure);
    const rememberMe = sessionStorage.getItem('rememberMe');
    if (!isloggedIn && !userLoggedInFromAzure) {
      // this.router.navigate(['/zs/login']);
      this.router.navigate(['/zl/login']);
      return false;
    }
    return true;
  }

  checkIsAdmin(url: string): boolean {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    let isAdmin = userInfo.is_super_admin || userInfo.entitlements[0].role.role_id === 17;
    if (isAdmin) {
      if (url === '/zs/admin' || url === '/zs/manage-tenants' || url === '/zs/manage-users' || url === '/zs/admin/users?showBreadcrumb=true' || url === '/zs/admin/ad-users?showBreadcrumb=true'
     || url === '/zs/admin/superadmin-users?showBreadcrumb=true' || url === '/zs/manage-security' || url === '/zs/jobs' || url === '/zs/manage-settings' || url === '/zs/admin/activities' || url === '/zs/success-matrix' 
     || url === '/zs/help' || url === '/zs/help/user-help' || url === '/zs/profile' || url === '/zs/notifications' || url === '/zs/help/user-api' || url === '/zs/help/user-db') {
        return true;
      }
      this.router.navigate(['/zs/admin']);
      return false;
    }
    return true;
  }


  get isLoggedin() {
    if (sessionStorage.getItem('isloggedIn')) {
      return true;
    } else {
      return false;
    }
  }

}
