import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { DataQualityService } from 'src/app/common/components/data-quality-rules/data-quality.service';
import { MessageService } from 'src/app/common/components/message/message.service';
import { BaseService } from 'src/app/common/services/base-service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../content.service';

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return value ? `<label id='unchk-${row}-${columnDef.field}' class="mr-top-7 check-cell d-none">
    <input type="checkbox" id='unchk-${row}-${columnDef.field}' class="check"> 
    </label> 
    <label  id='chk-${row}-${columnDef.field}'class="mr-top-7  check-cell">
    <input type="checkbox" id='chk-${row}-${columnDef.field}' class="check" checked> 
    </label>` : `<label id='unchk-${row}-${columnDef.field}' class="mr-top-7  check-cell">
    <input type="checkbox" id='unchk-${row}-${columnDef.field}' class="check"> 
    </label> 
    <label  id='chk-${row}-${columnDef.field}'class="mr-top-7  check-cell d-none">
    <input type="checkbox" id='chk-${row}-${columnDef.field}' class="check" checked> 
    </label>`;
}
const referenceDataListFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return dataContext.reference_data_list === true ? `<div id="view-ref-${row}" class="w-100 text-center pt-1">
                 <i class="fa-light fa-copy" actionInlineBtn></i>
                 </div>` : `<div id="add-ref-${row}" class="w-100 text-center pt-1">
                                      <i class="fa fa-plus actionInlineBtn"></i>
                                     </div><div id="view-ref-${row}" class="w-100 text-center pt-1 d-none">
                                     <i class="fa-light fa-copy" actionInlineBtn></i>
                                     </div>`;
}
const customDropDownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  let options = '';
  if (columnDef.field === 'must_come_before' || columnDef.field === 'must_come_after' ||
    columnDef.field === 'must_be_greater_than' || columnDef.field === 'must_be_less_than') {
    columnDef.params.concepts.forEach(element => {
      let selected_attribute_id = null;
      selected_attribute_id = columnDef.field === 'must_be_greater_than' ? dataContext.must_be_greater_than_attribute_id :
        (columnDef.field === 'must_be_less_than' ? dataContext.must_be_less_than_attribute_id :
          (columnDef.field === 'must_come_before' ? dataContext.must_come_before_attribute_id : (columnDef.field === 'must_come_after' ? dataContext.must_come_after_attribute_id : null)))
      if (element.attribute_id == selected_attribute_id) {
        options = options + `<option value= ${element.attribute_id}  selected="selected">${element.logical_name}</option>\n`;
      } else {
        options = options + `<option value= ${element.attribute_id}>${element.logical_name}</option>\n`;
      }

    });
  }

  let placeholder = 'Select One';
  let active_ddl = `<div class="w-100 display-block">
  <select id='attribute-${columnDef.field}-${row}' class="ctmInputBox drop-down-minimal">
      <option value="">${placeholder}</option>
      ${options}
  </select>
</div>`;

  let inactive_ddl = `<div class="selectInput w-100">
 <input class="ctmInputBox disabled-input"  disabled />
</div>`;

  if (columnDef.field === 'must_come_before' || columnDef.field === 'must_come_after') {
    if (dataContext.data_type.toLowerCase() == 'Date'.toLowerCase() || dataContext.data_type == 'DateTime'.toLowerCase()) {
      return `<div class="w-100 display-block">
      <select id='attribute-${columnDef.field}-${row}' class="ctmInputBox drop-down-minimal">
          <option value="${dataContext.attribute_id}_${columnDef.field}">${placeholder}</option>
          ${options}
      </select>
</div>`;
    } else {
      return inactive_ddl;
    }
  }
  if (columnDef.field === 'must_be_greater_than' || columnDef.field === 'must_be_less_than') {
    if (dataContext.data_type.toLowerCase() == 'Int'.toLowerCase() || dataContext.data_type.toLowerCase() == 'Number'.toLowerCase()) {
      return `<div class="w-100 display-block">
      <select id='attribute-${columnDef.field}-${row}' class="ctmInputBox drop-down-minimal">
      <option value="${dataContext.attribute_id}_${columnDef.field}">${placeholder}</option>
          ${options}
      </select>
    </div>`;
    } else {
      return inactive_ddl;
    }
  }
  if (columnDef.field === 'must_be_equal_to') {
    return `<div class="selectInput w-100">
      <select id='concept-${columnDef.field}-${row}' class="ctmInputBox drop-down-minimal">
          <option value="">Select One</option>
      </select>
  </div>`;
  }
}
const groupnumericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="text-right grp-enitile wd-total-entitle" title="${value}">${value}</div>`
}
@Component({
  selector: 'zetta-data-quality-rules-model',
  templateUrl: './data-quality-rules-model.component.html',
  styleUrls: ['./data-quality-rules-model.component.scss']
})
export class DataQualityRulesModelComponent implements OnInit {
  _count: boolean = false;
  dsName1: string;
  grid: AngularGridInstance;
  public loggedInUserDetails = null;
  isDataLoading = true;
  tableSettings2: object = { 'height': "100%", 'width': "100%" };
  tableSettings: object = { 'height': "100%", 'width': "99.71%" };
  modalRowData: any;
  modalGridOptions: any;
  modelColumnDef = [];
  attributeTableRows: any;
  attributeTableColDef: any;
  page = 1;
  limit = 100;
  datasetId;
  showModal: boolean;
  url: string;
  total = 0;
  attribute_list = [];
  entityAttributeRules = [];
  allConcept = [];
  catalog_id: number;
  safeSrc: SafeResourceUrl;
  showTrainingDataModal = false;
  selectedDatasets = [];
  within_datasource_id = [];
  within_dataset_id = [];
  within_dataset_column_id = [];
  displayReferenceData = false;
  noData = false;
  rowNo = 0;
  attribute_id: number
  defaultRule: any;
  outOfBoxRules = [];
  ruleToBeDelete = [];
  ruleToCreate = [];
  reference_data = [];
  dqMappingPayload = [];
  isFiltering = false;
  isSorting = false;
  hasScrolled = false;
  totalPage: number;
  @Input() entity_name: string;
  @Input() dataSetId: string;
  @Input() count: boolean;
  @Input() entity_id: number;
  @Input() date_concepts: any = [];
  @Input() number_concepts: any = [];
  @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();

  constructor(public _projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private baseService: BaseService,
    private contentSvc: ContentService,
    private zsclContentSVc: ZsClContentService,
    private dqService: DataQualityService,
    private zettaUtils: ZettaUtils,
    private messageSvc: MessageService) {
  }


  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.catalog_id = +params.get('catalog_id');
    });
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.getEntityAttributes(this.page, this.limit);
    this.initAttributeGrid();
    this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + this.dataSetId + '/dataprofiler/report';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.modalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true
    };
  }
  initAttributeGrid() {
    this.modelColumnDef = [
      {
        'displayname': 'Logical Name',
        'physicalname': 'logical_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 70,
        'maxWidth': 150,
      }, {
        'displayname': 'Data Type',
        'physicalname': 'data_type',
        'sortable': true,
        'datatype': 'String', 
        'filterable': true,
        'minWidth': 30,
        'maxWidth': 80,
      }, {
        'displayname': 'Must Be Populated or Non-null',
        'physicalname': 'must_be_populated_or_non_null',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': checkBoxFormattter,
        'minWidth': 250,
        'maxWidth': 350,
        'cssClass': 'text-center',
        'headerCssClass': 'text-left titleBreak',
      }, {
        'displayname': 'Must Be of the Defined Data Type',
        'physicalname': 'must_be_defined_data_type',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': checkBoxFormattter,
        'minWidth': 350,
        'maxWidth': 450,
        'cssClass': 'text-center',
        'headerCssClass': 'text-left titleBreak',
      }, {
        'displayname': 'Must have a Valid Value from the Reference Data List',
        'physicalname': 'reference_data_list',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': referenceDataListFormatter,
        'minWidth': 550,
        'maxWidth': 750,
        'cssClass': 'text-center',
        'headerCssClass': 'text-left titleBreakRef',
      }, {
        'displayname': 'Must Be a Unique value',
        'physicalname': 'must_be_unique_value',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 90,
        'maxWidth': 100,
        'formatter': checkBoxFormattter,
        'cssClass': 'text-center',
        'headerCssClass': 'text-left titleBreak',
      }, {
        'displayname': 'Must Come Before',
        'physicalname': 'must_come_before',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 100,
        'maxWidth': 160,
        'formatter': customDropDownFormatter,
        'headerCssClass': 'text-left titleBreak pr-3',
        'params': { concepts: this.date_concepts, 'placeholder': 'Select One:' }
      }, {
        'displayname': 'Must Come After',
        'physicalname': 'must_come_after',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 100,
        'maxWidth': 200,
        'formatter': customDropDownFormatter,
        'headerCssClass': 'text-left titleBreak pr-3',
        'params': { concepts: this.date_concepts, 'placeholder': 'Select One:' }

      }, 
      // as per user-story 7023 we may need it for later so commenting.
      // {
      //   'displayname': 'Must Be Equal To',
      //   'physicalname': 'must_be_equal_to',
      //   'sortable': false,
      //   'datatype': 'String',
      //   'filterable': false,
      //   'minWidth': 100,
      //   'maxWidth': 150,
      //   'formatter': customDropDownFormatter,
      //   'headerCssClass': 'text-left titleBreak pr-3'
      // }, 
      {
        'displayname': 'Must Be Greater Than',
        'physicalname': 'must_be_greater_than',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 15,
        'maxWidth': 200,
        'formatter': customDropDownFormatter,
        'headerCssClass': 'text-left titleBreak pr-3',
        'params': { concepts: this.number_concepts, 'placeholder': 'Select One:' }

      },
      {
        'displayname': 'Must Be less Than',
        'physicalname': 'must_be_less_than',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 100,
        'maxWidth': 150,
        'formatter': customDropDownFormatter,
        'headerCssClass': 'text-left titleBreak pr-3',
        'params': { concepts: this.number_concepts, 'placeholder': 'Select One:' }
      }, {
        'displayname': '# of Rules',
        'physicalname': 'total_rules',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 10,
        'maxWidth': 90,
        'cssClass': 'text-right',
        'formatter': groupnumericFormatter,
      }
    ];

  }


  getEntityAttributes(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/entities/${this.entity_id}/attributesdefaultrules?pagination=true&pageno=1&pagesize=${limit}`;
    var serverfilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      var serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) {
        pageNo = 1;
      }
      this.isFiltering = false;
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.contentSvc.getEntityAttributeDefaultRules(this.entity_id, pageNo, limit, serverfilter, sortfilter).subscribe(data => {
      this.modalRowData = data['currentpage'];
      this.entityAttributeRules = data['currentpage'];
      if (this.entityAttributeRules.length) {
        this.entityAttributeRules = this.baseService.getAttributeRules(this.entityAttributeRules);
      }
      this.total = data.totalrecords;
      if (pageNo === 1) {
        this.totalPage = data.totalpages;
      }
      this.showModal = true;
      this.isDataLoading = false;
      let concatedRows = []
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.entityAttributeRules];
        this.entityAttributeRules.forEach(element => {
          element.id = Math.random();
        });
        this.entityAttributeRules = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isDataLoading = false;
      this.modalRowData = [];
    });

  }


  onModelGridCreation(grid) {
    this.grid = grid;
  }

  closeModelPopup() {
    this.modelPopupOutput.emit(false);
  }

  onDataCellClick(eventData) {
    const args = eventData.args;
    const event = eventData.eventData;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    const self = this;
    if (row !== undefined && row !== null) {
      this.attribute_id = row.attribute_id;
      let idCell = '#attribute-' + fieldName + '-' + args.row;
      let default_rule_lookup_id = null;
      let rule_name = '';
      if (fieldName === 'must_be_populated_or_non_null') {
        default_rule_lookup_id = AppGlobals.MUST_BE_POPULATED_OR_NOT_NULL_LOOKUP_ID;
        rule_name = AppGlobals.NOT_NULL;
        this.toggleTheRules(row, args.row, default_rule_lookup_id, rule_name, fieldName, 'must_be_populated_or_non_null', 'must_be_populated_or_non_null_rule_id', 'check');
      }
      if (fieldName === 'must_be_defined_data_type') {
        default_rule_lookup_id = AppGlobals.MUST_BE_DEFINED_DATA_TYPE_LOOKUP_ID;
        rule_name = AppGlobals.MUST_BE_DATATYPE;
        this.toggleTheRules(row, args.row, default_rule_lookup_id, rule_name, fieldName, 'must_be_defined_data_type', 'must_be_defined_data_type_rule_id', 'check');
      }
      if (fieldName === 'must_be_unique_value') {
        default_rule_lookup_id = AppGlobals.MUST_BE_UNIQUE_VALUE_LOOKUP_ID;
        rule_name = AppGlobals.MUST_UNIQE;
        this.toggleTheRules(row, args.row, default_rule_lookup_id, rule_name, fieldName, 'must_be_unique_value', 'must_be_unique_value_rule_id', 'check');
      }
      if (fieldName === 'reference_data_list') {
        default_rule_lookup_id = AppGlobals.MUST_HAVE_VALID_VALUE_FROM_REFERENCE_DATA_LIST_LOOKUP_ID;
        if (event.target.className.includes('fa fa-plus actionInlineBtn')) {
          this.selectedDatasets = [];
          this.getDefaultRules(row.attribute_id);
          this.showTrainingDataModal = true;
          this.rowNo = args.row;
        }
        if (event.target.className === 'fa-light fa-copy') {
          this.getDefaultRules(row.attribute_id);
          this.rowNo = args.row;
          this.selectedDatasets = [];
          this.getRefenceData();
        }
      }
      $(idCell).change(function () {
        let right_attribute_id = null;
        if ($(idCell)[0]['value'] != `${row.attribute_id}_${fieldName}`) {
          right_attribute_id = $(idCell)[0]['value'];
          if (fieldName === 'must_come_before') {
            default_rule_lookup_id = AppGlobals.MUST_COME_BEFORE_LOOKUP_ID;
            rule_name = AppGlobals.MUST_COME_BEFORE;
          }
          if (fieldName === 'must_come_after') {
            default_rule_lookup_id = AppGlobals.MUST_COME_AFTER_LOOKUP_ID;
            rule_name = AppGlobals.MUST_COME_AFTER;
          }
          if (fieldName === 'must_be_greater_than') {
            default_rule_lookup_id = AppGlobals.MUST_BE_GREATER_THAN_LOOKUP_ID;
            rule_name = AppGlobals.MUST_BE_GREATER;
          }
          if (fieldName === 'must_be_less_than') {
            default_rule_lookup_id = AppGlobals.MUST_BE_LESS_THAN_LOOKUP_ID;
            rule_name = AppGlobals.MUST_BE_LESS;
          }
          if (self.ruleToBeDelete.length) {
            self.ruleToBeDelete = self.ruleToBeDelete.filter(rule => rule.rule_default_name !== `${row.attribute_id}_${fieldName}`);
          }
          if (self.ruleToCreate.length) {
            const newRule = self.ruleToCreate.filter(rule => rule.rule_default_name === `${row.attribute_id}_${fieldName}`);
            if (newRule.length) {
              self.ruleToCreate.forEach(element => {
                if (newRule[0].rule_default_name === element.rule_default_name) {
                  element.right_attribute_id = right_attribute_id;
                }
              });
              return;
            }
          }
          self.ruleToCreate.push({
            rule_default_name: `${row.attribute_id}_${fieldName}`,
            default_rule_lookup_id: default_rule_lookup_id,
            right_attribute_id: right_attribute_id,
            attribute_id: row.attribute_id,
            entity_id: self.entity_id,
            rule_name: rule_name
          });
        } else {
          if (row[`${fieldName}_rule_id`]) {
            if (self.ruleToBeDelete.length) {
              const ruleExist = self.ruleToBeDelete.filter(rule => rule.rule_default_name === `${row.attribute_id}_${fieldName}`);
              if (ruleExist.length) {
                return;
              }
            }
            self.ruleToBeDelete.push({
              rule_id: row[`${fieldName}_rule_id`],
              rule_default_name: `${row.attribute_id}_${fieldName}`,
              default_rule_lookup_id: default_rule_lookup_id
            });
          }
          if (self.ruleToCreate.length) {
            self.ruleToCreate = self.ruleToCreate.filter(rule => rule.rule_default_name !== `${row.attribute_id}_${fieldName}`);
          }
        }
      });
    }

  }

  toggleTheRules(row, rowNo, default_rule_lookup_id, rule_name, fieldName, targetFieldName, rule_id, ruleClass) {
    if (fieldName === targetFieldName) {
      if (ruleClass === 'check') {
        if (row[targetFieldName]) {
          row[targetFieldName] = !row[targetFieldName];
          $("#chk-" + rowNo + '-' + fieldName).addClass('d-none');
          $("#unchk-" + rowNo + '-' + fieldName).removeClass('d-none');
          if (row[rule_id]) {
            this.ruleToBeDelete.push({
              rule_id: row[rule_id],
              rule_default_name: `${row.attribute_id}_${targetFieldName}`,
              default_rule_lookup_id: default_rule_lookup_id
            });
          }
          if (this.ruleToCreate.length) {
            this.ruleToCreate = this.ruleToCreate.filter(rule => rule.rule_default_name !== `${row.attribute_id}_${targetFieldName}`);
          }
        } else {
          row[targetFieldName] = !row[targetFieldName];
          $("#chk-" + rowNo + '-' + fieldName).removeClass('d-none');
          $("#unchk-" + rowNo + '-' + fieldName).addClass('d-none');
          this.ruleToCreate.push({
            rule_default_name: `${row.attribute_id}_${targetFieldName}`,
            default_rule_lookup_id: default_rule_lookup_id,
            right_attribute_id: null,
            attribute_id: row.attribute_id,
            entity_id: this.entity_id,
            rule_name: rule_name
          });
          if (this.ruleToBeDelete.length) {
            this.ruleToBeDelete = this.ruleToBeDelete.filter(rule => rule.rule_default_name !== `${row.attribute_id}_${targetFieldName}`);
          }
        }
      }
    }
  }

  hideOverlay(eve) {
    this.showTrainingDataModal = false;
  }
  updateData(eve) {
    if (eve.data && eve.data.length > 0) {
      this.reference_data = [];
      this.dqMappingPayload = []
      this.selectedDatasets = eve.data;
      this.displayReferenceData = true;
      this.within_datasource_id = this.selectedDatasets.map(dataset => dataset.description);
      this.within_dataset_id = this.selectedDatasets.map(datset => datset.dataset_id);
      this.within_dataset_column_id = this.selectedDatasets.map(datset => datset.dataset_column_id);
      this.reference_data.push({
        rule_default_name: `${this.attribute_id}_reference_data_list`,
        default_rule_lookup_id: AppGlobals.MUST_HAVE_VALID_VALUE_FROM_REFERENCE_DATA_LIST_LOOKUP_ID,
        right_attribute_id: null,
        attribute_id: this.attribute_id,
        entity_id: this.entity_id,
        rule_name: AppGlobals.SET_REFERENCE,
        within_dataset_id: this.within_dataset_id,
        within_dataset_column_id: this.within_dataset_column_id
      });
      const dataset_column_id = this.selectedDatasets.map(data => data.latest_column_id);
      if (dataset_column_id.length) {
        this.dqMappingPayload.push({
          dataset_column_id: dataset_column_id.length ? +dataset_column_id[0] : null,
          attribute_id: this.attribute_id,
        });
      }
      $('#add-ref-' + this.rowNo).addClass('d-none');
      $('#view-ref-' + this.rowNo).removeClass('d-none');
    } else {
      this.displayReferenceData = false;
    }
  }
  getDefaultRules(attribute_id) {
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      concept_id: attribute_id
    };
    this.dqService.getOutOfBoxRules(data).subscribe(resp => {
      if (resp) {
        this.outOfBoxRules = resp['currentpage'];
        if (this.outOfBoxRules.length) {
          this.defaultRule = this.outOfBoxRules.find(rule => rule.rule_name === AppGlobals.SET_REFERENCE);

        }
      }
    }, error => {
    });
  }
  getRefenceData() {
    this.zsclContentSVc.getReferenceDataForConcept(this.entity_id, this.attribute_id).subscribe(resp => {
      if (resp.length) {
        resp.forEach(element => {
          element.logical_name = element.left_within_source_id;
          element.description = element.left_within_list_column_id;
        });
        this.selectedDatasets = resp;
      }
      this.showTrainingDataModal = true;
    }, error => {
    });
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true;
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled) {
      this.hasScrolled = true;
      if (this.page < this.totalPage) {
        this.onNext();
      }
    }
  }
  onNext(): void {
    this.page++;
    this.getEntityAttributes(this.page, this.limit);
  }

  saveAttributeRules() {
    this.ruleToCreate = [...this.ruleToCreate, ...this.reference_data];
    if (this.ruleToBeDelete.length || this.ruleToCreate.length) {
      if (this.ruleToBeDelete.length) {
        const rules_to_be_delete = this.ruleToBeDelete.map(rule => rule.rule_id);
        const deleteRulePayload = {
          dq_rules_ids: rules_to_be_delete
        };
        this.zsclContentSVc.deleteDataQualityRule(deleteRulePayload).subscribe(resp => {
          this.ruleToBeDelete = [];
          if (!this.ruleToCreate.length) {
            this.messageSvc.sendMessage({ message: 'Rule(s) updated successfully.', type: 'SUCCESS', hideboard: true });
            this.modelPopupOutput.emit(false);
            ($('#createRuleModel') as any).modal('hide');
          }
        });
      }
      if (this.ruleToCreate.length) {
        const payload = [];
        const selectDefulatRules = this.ruleToCreate;
        selectDefulatRules.forEach(rule => {
          const rulePaylod = {
            enity_id: this.entity_id,
            lookup_id: rule.default_rule_lookup_id,
            attribute_id: rule.attribute_id,
            operator_lookup_id: null,
            calculation: null,
            name: rule.rule_name,
            description: '',
            data_quality_rule_sequence_id: null,
            comparision: {
              enity_id: rule.right_attribute_id ? this.entity_id : null,
              attribute_id: rule.right_attribute_id
            },
            within_source_id: rule.within_dataset_id ? rule.within_dataset_id : null,
            within_list_column_id: rule.within_dataset_column_id ? rule.within_dataset_column_id : null,
          };
          payload.push(rulePaylod);
        });
        if (this.reference_data.length) {
          this.reference_data = [];
          this.createDQRuleMapping();
        }
        this.zsclContentSVc.saveOutOfBoxRule(payload).subscribe(resp => {
          this.ruleToCreate = [];
          this.messageSvc.sendMessage({ message: 'Rule(s) updated successfully.', type: 'SUCCESS', hideboard: true });
          this.modelPopupOutput.emit(false);
          ($('#createRuleModel') as any).modal('hide');
        }, error => {
          this.messageSvc.sendMessage({ message: error.error.message, type: 'SUCCESS', hideboard: true });
        });
      }
    } else {
      this.modelPopupOutput.emit(false);
      ($('#createRuleModel') as any).modal('hide');
    }
  }
  createDQRuleMapping() {
    const payload = [
      {
        catalog_id: this.catalog_id,
        entities: [
          {
            entity_id: +this.entity_id,
            mappings: this.dqMappingPayload
          }
        ]
      }
    ];
    this.zsclContentSVc.runModelClassify1(payload).subscribe(resp => {
    }, err => {
    });
  }
}