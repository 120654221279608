import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ObservableService {
  refreshDataGridSub: Subject<boolean> = new Subject<boolean>();
  getEntityNameSub: Subject<string> = new Subject<string>();
  currentEntitlementsSub: Subject<boolean> = new Subject<boolean>();
  trackSub: Subject<object> = new Subject<object>();
  dataSetDetails: Subject<object> = new Subject<object>();
  searchText = new BehaviorSubject("");
  RuleSequenceType = new BehaviorSubject(false);
  isEntityStateChangeForHeuristicBuilder: Subject<boolean> = new Subject<boolean>();
  isCatalogUserRoleSelectionFulfilled: Subject<boolean> = new Subject<boolean>();

  set refreshDataGrid(value: any) {
    this.refreshDataGridSub.next(value);
  }
  get refreshDataGrid() {
    return this.refreshDataGridSub.asObservable();
  }
  set setEntityName(value: any) {
    this.getEntityNameSub.next(value);
  }
  get getEntityName() {
    return this.getEntityNameSub.asObservable();
  }
  set setCurrentEntitlements(value: any) {
    this.currentEntitlementsSub.next(value);
  }
  get getCurrentEntitlements() {
    return this.currentEntitlementsSub.asObservable();
  }
  set setTrackStatus(value) {
    this.trackSub.next(value);
  }
  get getTrackStatus() {
    return this.trackSub.asObservable();
  }
  set setDataSetDetails(value: any) {
    this.dataSetDetails.next(value);
  }
  get getDataSetDetails() {
    return this.dataSetDetails.asObservable();
  }
  set sendSearchText(value: any) {
    this.searchText.next(value);
  }
  get getSearchObservable() {
    return this.searchText.asObservable();
  }
  set sendRuleSequenceType(value: any) {
    this.RuleSequenceType.next(value);
  }
  get getRuleSequenceType() {
    return this.RuleSequenceType.asObservable();
  }
  unsubscribe() {
    this.RuleSequenceType.next(false);
  }

  unsubscribeSearchData() {
    this.searchText.next("");
  }

  set setEntityStateChangedForHB(value: any) {
    this.isEntityStateChangeForHeuristicBuilder.next(value);
  }

  get getEntityChangeStateForHeuristicBuilder() {
    return this.isEntityStateChangeForHeuristicBuilder.asObservable();
  }
  
  set setClassifyCatalogUserRoleFulfillmentStatus(value: any) {
    this.isCatalogUserRoleSelectionFulfilled.next(value);
  }

  get getClassifyCatalogUserRoleFulfillmentStatus() {
    return this.isCatalogUserRoleSelectionFulfilled.asObservable();
  }

  public datasetLabelUpdate:BehaviorSubject<string> = new BehaviorSubject('');
}
