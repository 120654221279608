import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AppGlobals } from "../../shared/app.globals";

@Component({
    selector:'zetta-job-error-details',
    templateUrl:'job-error-details.component.html',
    styleUrls:['job-error-details.component.scss']
})
export class JobErrorDetailsComponent implements OnInit, OnChanges{
    @Input() headerTitle:string;
    @Input() errorDetails:string;
    @Input() changeIndex = false;
    
    ngOnInit(): void {
        
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (!changes.errorDetails.currentValue) {
                this.errorDetails = AppGlobals.JOB_CANCELLED_MSG;
            }

        }
    }

}