import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../common/components/message/message.service';
import { ContentService } from '../zettasense/content/content.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'zetta-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
	
	public forgotPasswordForm: FormGroup;
	public submitted = false;
	public notFound = false;
	public token;
	public emailPattern;
	@ViewChild('focusElement') focusElement;
	
	constructor(private _formBuilder: FormBuilder,
				private contentSvc: ContentService,
				private activatedRoute: ActivatedRoute,
				private router: Router,
				private messageSvc: MessageService) { }

	ngOnInit() {
		this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
        if (this.token) {
			this.router.navigate(['/zl/login/reset-password'], { queryParams: { token: this.token } });
        } else {
			this.initializeForgotPwdForm();
		}
	}

	ngAfterViewInit() {
		let interval = setInterval(() => {
		  if(this.focusElement){
			this.focusElement.nativeElement.focus();
			clearInterval(interval);
		  }
		}, 1000);
	  }
	  
	// initialize the default Form
	initializeForgotPwdForm() {
		// this.emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
		this.emailPattern = "^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,4}$";
		this.forgotPasswordForm = this._formBuilder.group({
			currentEmail: ['', [Validators.required,]]
		});
	}

	forgotPassword() {
		const parent = this;
		if (parent.forgotPasswordForm.valid) {
			const payload: any = {
				email: parent.forgotPasswordForm.value.currentEmail
			}
			parent.contentSvc.forgetPassword(payload).subscribe((result) => {
				parent.submitted = true;
			}, (err) => { 
				parent.submitted = false;
				parent.notFound = true;
				setTimeout(() => {
					parent.notFound = false;
				}, 5000);
			});
		}
	}

}
