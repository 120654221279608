import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { actionsFormatter } from '../../../common/shared/formatters/actionsFormatter';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { AngularGridInstance } from 'angular-slickgrid';
import { Router } from '@angular/router';
// import { ZsClContentService } from '../zs-cl-content.service';

@Component({
  selector: 'zetta-zs-cl-admin',
  templateUrl: './zs-cl-admin.component.html',
  styleUrls: ['./zs-cl-admin.component.scss']
})
export class ZsClAdminComponent implements OnInit {

  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public dataReports: any = [];
  public tableSettings: object = { 'height': '100%', 'pageSize': 8, 'isNormalPagination': false };
  reportsTableColDef: any;
  gridOptions: any;
  isLoading = true;

  constructor(
    // private ContentSvc: ZsClContentService,
    private _router: Router) { }

  ngOnInit() {
    // this.getReportList();
    this.reportsTableColDef = [{
      'displayname': '',
      'physicalname': 'actions',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': actionsFormatter,
      'maxWidth': 20,
    },
    {
      'displayname': 'Report Name',
      'physicalname': 'reportname',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 30,
      'formatter': blueTextFormattter,
    }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 30
    };

  }

  // getReportList() {
  //   this.ContentSvc.getReports().subscribe((data) => {
  //     this.dataReports = data;
  //     this.isLoading = false;
  //   }, err => {
  //     this.isLoading = false;
  //   });
  // }

  onCellClick(e) {
    const parent = this;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    parent._router.navigate(['zc/reports/' + row.reportid]);
  }

  onGridCreated(grid) {
    this.grid = grid;
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    if (row.isdisable) {
      $('#context_menu').hide();
    } else {
      $('#context_menu')
        .removeClass('d-none')
        .data('row', args.row)
        .css('top', eventEle.pageY)
        .css('left', eventEle.pageX + 5)
        .css('position', 'fixed')
        .css('display', 'block')
        .show();
    }

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      if (action === 'Edit') {
        // parent._router.navigate(['/zs/projects/create-project'], { queryParams: { 'id': row.projectid, showBreadcrumb: false } });
      } else if (action === 'Delete') {
        // parent.deleteRow(row.projectid, row.id);
      } else if (action === 'Clone') {
        // parent.cloneRow(row);
      }
    });
  }

}
