import { Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-search-dropdown-cell-renderer',
  template: `
          <div #dropdownWrapper class="dropdown-wrapper"  [class]="getInputClass()">
          <ng-multiselect-dropdown 
            #dropdownElement
            [placeholder]="getPlaceholderText()" 
            [data]="params.list"
            (onSelect)="onDropdownValueChange($event)"
            [settings]="getSettings()"
            (onDeSelect)="onItemDeSelect($event)"
            >
            </ng-multiselect-dropdown>
            </div>
            `, 

            })
export class SearchDropdownRendererComponent implements ICellRendererAngularComp {
  params: any;
  @Output() dropdownValueChanged = new EventEmitter<string>();
  showDefaultDropdown = false;

  @ViewChild('dropdownWrapper', { static: true } as any) dropdownWrapper: ElementRef;
  @ViewChild('dropdownElement', { static: true } as any)  dropdownElement: ElementRef;

  agInit(params: any): void {
    this.params = params;    
    if(params.node.id === 't-0'){
      this.showDefaultDropdown = true;
    }
    this.adjustDropdownPosition();
  }

  refresh(params): boolean {
    this.params = params; 
    this.adjustDropdownPosition();
    return true;
  }

  adjustDropdownPosition() {
    setTimeout(() => {
      const dropdownWrapperRect = this.dropdownWrapper.nativeElement.getBoundingClientRect();
      const dropdownListElement = this.dropdownElement.nativeElement.querySelector('.dropdown-list');
  
      if (dropdownListElement) {
        const dropdownRect = dropdownListElement.getBoundingClientRect();
        const availableSpaceBelow = window.innerHeight - dropdownWrapperRect.bottom;
        const availableSpaceAbove = dropdownWrapperRect.top;
  
        let dropdownMaxHeight;
  
        if (availableSpaceBelow < dropdownRect.height && availableSpaceAbove > dropdownRect.height) {
          dropdownListElement.style.position = 'absolute';
          dropdownListElement.style.bottom = `${availableSpaceAbove}px`;
          dropdownListElement.style.top = 'auto';
          dropdownMaxHeight = availableSpaceAbove - 10;
        } else {
          dropdownListElement.style.position = 'absolute';
          dropdownListElement.style.top = `${dropdownWrapperRect.bottom}px`;
          dropdownListElement.style.bottom = 'auto';
          dropdownMaxHeight = availableSpaceBelow - 10;
        }
  
        dropdownListElement.style.left = `${dropdownWrapperRect.left}px`;
        dropdownListElement.style.zIndex = '1050';
   
        dropdownListElement.style.maxHeight = `${Math.min(dropdownMaxHeight, 300)}px`; 
        dropdownListElement.style.overflowY = 'auto'; 
      }
    }, 0);
  }
  

  getCssClass(): string {
    return this.params.data.action !== 'delete' ? 'ctmInputBox drop-down-minimal dd-edit-entity-wd' : 'ctmInputBoxNoEdit drop-down-minimal';
  }

  getSettings() {
    return {
        singleSelection: true,
        idField: 'class',
        textField: 'class',      
        itemsShowLimit: 1,
        enableCheckAll:false,
        allowSearchFilter: true,
        closeDropDownOnSelection:true,
    };
  }

  getInputClass() {
    return `search-select-dropdown`;
  }

  getPlaceholderText() {
    return `Select a Class`;
  }

  onDropdownValueChange(event: any ) { 
    const newValue = event.class; //for ng-select dropdown
    // const newValue = event.target.value ? event.target.value : 'NOT-SELECTED';
    const oldValue = this.params.data; 
    const rowIndex = this.params.rowIndex;
    this.params.list[this.params.rowIndex]['selectedItem'] = true;
    const fieldName =  this.params.column.colId;  
    this.params['data']['isSelected'] = newValue;
    
    const idIs = this.params.data.classify_project_feedback_id;
    let ind = this.params['rowsData'].findIndex(ele =>  ele.classify_project_feedback_id == idIs);
    if(ind > -1 && this.params['rowsData'][ind]) {
      this.params['rowsData'][ind]['selectedOne'] = newValue;
    }

    const dropdownObj = {
      oldValue: oldValue,
      fieldName: fieldName,
      rowIndex: rowIndex,  
      id: this.params.node.id,
      newValue: newValue
    }
  
    // this.dropdownValueChanged.emit(newValue);
    this.params.context.componentParent.onDropdown(dropdownObj)    
 
  } 

  onItemDeSelect(eve: any) {
    let event = eve['class'] = 'NOT-SELECTED';
    this.onDropdownValueChange({event});
  }

  isSelected(name: string, ind): boolean { 
    return this.params.value === name;
  }

  getSelectedItem(item) {
    const idIs = this.params.data.classify_project_feedback_id;
    if(idIs && this.params['rowsData'] && this.params['rowsData'].length>0) {
    let ind = this.params['rowsData'].findIndex(ele =>  ele.classify_project_feedback_id == idIs);
      return (this.params['rowsData'] && this.params['rowsData'][ind] && this.params['rowsData'][ind]['selectedOne']) ? this.params['rowsData'][ind]['selectedOne'] == item.class : false;
    } else {
      return;
    }
  }
}
 