import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-confidence-level-formatter',
  template: `
    <div class="wd-6rem">
      <div [ngClass]="getCssClasses()">
        <span class=" p-3">{{ getFormattedText() }}</span>
      </div>
    </div>
  `,
})
export class ProjectMeasuredDQRuleFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getCssClasses(): string {
    let dqScore = this.params.data.dq_score;
    const score100 = this.getScoreAsPercentage(dqScore);

    if (dqScore == undefined||dqScore == 0) {
      return ' disable-rule';
    } else if (score100 >= 75) {
      return 'priorityCell high  box-cat-obj';
    } else if (score100 >= 50 && score100 < 75) {
      return 'priorityCell medium  box-cat-obj';
    } else {
      return 'priorityCell low  box-cat-obj';
    }
  }

  getFormattedText(): string {
   let dqScore = this.params.data.dq_score;
    if (dqScore == undefined||dqScore == 0) {
        dqScore = 'n/a';
      }
   
    return dqScore === 'n/a' ? 'n/a' : `${this.getScoreAsPercentage(dqScore)}%`;
  }

  

  private getScoreAsPercentage(score: any): number {
    if (Number.isInteger(score)) {
      return Number(score * 100);
    } else {
      return score !== 'n/a' ? Number((score * 100).toFixed(2)) : 0;
    }
  }
}
