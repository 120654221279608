import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../../../../zettasense/content/content.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from '../../message/message.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { DatePipe } from '@angular/common';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { UserGroupService } from "../../../../zettasense/usergroup/usergroup.service";
declare var $: any;

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if( dataContext.action !== 'edit'){
      if (columnDef.field !== 'actions') {
          return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>` 
                                       : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v" aria-hidden="true" style="font-size:22px;"></i></span>`;
      } else {
          return ``;
      }
  }else {
      return dataContext.isdisable ? `<span class="text-right pointer context-menu disabled-state"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>` 
                                   : `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v" aria-hidden="true" style="font-size:22px;"></i></span>`;
  }
};

const actionsObjectFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
if (columnDef.field === 'action') {
    if (dataContext.action !== 'add') {
      return `
      <div class='d-flex col-1'>
          <div class="text-center col-6 p-0 number mr-3" title="${value}">
                  <i id='edit-${row}' class="fal fa-pen actionInlineBtn blue-color-link"></i>
          </div>
          <div class="text-center col-6 p-0 number" title="delete">
                  <i id='delete-${row}' class="far fa-trash-alt actionInlineBtn red-color-link"></i>
          </div>
      </div>`
    } else {
      return `<div class="col-6 number" title="${value}">
              <i class="fa fa-plus actionInlineBtn blue-color-link"></i>
          </div>`
    }
} else {
      if(dataContext.action === 'edit'){
              return `<div class="text-center w-100 number">
                          <i class="fa fa-graduation-cap actionInlineBtn"></i>
                      </div>`
          } else {
              return ``;
          }
  }
}

const customObjectRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {  
  if( dataContext.action === 'add'){

      return `<div class="box-input-tenant"><input id='object-${columnDef.field}-${row}' class='ctmInputBox-tenant' type='text' placeholder='${columnDef.params.placeholder}' 
              tabindex='${columnDef.params.tabindex}' autofocus='autofocus' value=''></input></div>`;
  }else{
          return `<div id="noEdit-${columnDef.field}-${row}" title="${value}" class="w-100 pr-3 text-truncate">${value}&nbsp;</div>
                  <div id="isEdit-${columnDef.field}-${row}" class="w-100 pr-3" style="display:none">
                      <input id='object-${columnDef.field}-${row}' title='${value}' class='ctmInputBoxNoEdit-tenant' type='text' placeholder='${columnDef.params.placeholder}' value='${value}' autocomplete="off">
                  </div>`
      }
};

@Component({
  selector: 'zetta-tenant-management',
  templateUrl: './tenant-management.component.html',
  styleUrls: ['./tenant-management.component.scss']
})

export class TenantManagementComponent implements OnInit {
  eventData: any;
  selectedObjectMetadata: any;
  tenantForm: FormGroup;
  public panelScreen: number;
  public buttonWrap: boolean;
  public tenants: Array<object>;
  isAdmin: boolean;
  tenant: any;
  page = 1;
  limit = 3;
  title = '';
  isEdit = false;
  logoImage = '../../../../assets/images/common/placeholder.png';
  iconImage = '../../../../assets/images/common/';
  isFormValid=false;
  tanent_apps:any=[];
  datePickerConfig: Partial<BsDatepickerConfig>;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  is_apps=false;  
  is_logo=false;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  gridOptions: any;
  objectTableColDef: any;
  objectsList: any = [];
  isDataReady = false;
  showBreadcrumb = true;
  gridObj: AngularGridInstance;
  clusterManagementTypes=[];
  addObject: Object = new Object();
  uniqueList = [];
  uniques = [];
  dataBricksVal='';
  lastRowSelected: number = 0;
  admin = {
    description:'',
    name:'',
    user_name: '',
    email: '',
    id: Math.random().toString(36).slice(-6), 
    entity_id: '-1', 
    action: 'edit',
    synonyms: Math.floor(Math.random() * (9 - 0)) + 0
  };
  clusterValue:'';
  @ViewChild('focusElement') focusElement;
  addFirstRow;
  quotaTypes: object;
  isLoading: Boolean = true;
  minDate: Date;
  tenantAdminInfo = {
    tenantName: '',
    tenantAdminName: '',
    tenantAdminEmailId: ''
  }
  constructor(private router: Router,
    public contentSvc: ContentService,
    private formBuilder: FormBuilder,
    private messageSvc: MessageService,
    private datePipe: DatePipe,
    private userGroupService: UserGroupService) { 
      this.datePickerConfig = Object.assign({},
        {        
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false
        });
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate());
    }

  ngOnInit() {
    this.onResize();
    this.isAdmin = this.loggedInUserDetails.is_super_admin; 
    this.addFirstRow = this.getAddObjectRow();    
    if (this.isAdmin) {
      this.getTenantsList();
    } else {
      this.getTenant();
    }
    this.objectTableColDef = [
    {
          'displayname': 'First Name',
          'physicalname': 'description',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'formatter': customObjectRowFormatter,
          'headerCssClass': 'mr-top-1',
          'minWidth': 200,
          'maxWidth': 210,
          'params': { placeholder: 'First Name..', tabindex: 1, showBreadCrumb: this.showBreadcrumb  }
      }, {
          'displayname': 'Last Name',
          'physicalname': 'name',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'formatter': customObjectRowFormatter,
          'headerCssClass': 'mr-top-1',
          'minWidth': 200,
          'maxWidth': 210,
          'params': { placeholder: 'Last Name...', tabindex: 2, showBreadCrumb: this.showBreadcrumb  }
      },
      {
        'displayname': 'User Name',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customObjectRowFormatter,
        'headerCssClass': 'mr-top-1',
        'minWidth': 200,
        'maxWidth': 210,
        'params': { placeholder: 'User Name...', tabindex: 3, showBreadCrumb: this.showBreadcrumb  }
    },{
      'displayname': 'E-Mail',
      'physicalname': 'email',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': customObjectRowFormatter,
      'headerCssClass': 'mr-top-1',
      'minWidth': 210,
      'maxWidth': 210,
      'params': { placeholder: 'Email...', tabindex: 4, showBreadCrumb: this.showBreadcrumb  }
  }      
  , {
          'displayname': 'Actions',
          'physicalname': 'action',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': actionsObjectFormatter,
          'cssClass': 'text-center',
          'headerCssClass': 'text-center pr-1',
          'maxWidth': 80,
          'params': { tabindex: 5 }

      }
  ];
   
  this.gridOptions = {
    enableGridMenu: false,
    enableAddRow: false,
    selectable: true,
    enableFiltering: true,
    CheckboxSelector: false,
    enableCellNavigation: true,
    rowHeight: 31,
};
this.isDataReady = true;
    this.getTenantApps();
    this.getQuotaType();
  }

  getTenantsList() {
    this.contentSvc.getTenants(this.loggedInUserDetails.user_id, this.page, this.limit).subscribe((data) => {
      this.tenants = data['currentpage'];
      this.tenant = data['currentpage'];
      this.tenants.map(tena => {
        tena['id'] = Math.random()
      });
      this.tenants.forEach(tenant => {
        tenant['image'] = this.logoImage;
        this.contentSvc.getLogo(this.loggedInUserDetails.user_id, tenant['tenant_id']).subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener('load', () => {
            tenant['image'] = reader.result;
          }, false);
          if (image && image.size) {
            reader.readAsDataURL(image);
          } else {
            tenant['image'] = this.logoImage;
          }
        })
      });
    });
  }

  setTenantAdminInfo(tenantAdminObj, tenantName) {
    this.tenantAdminInfo.tenantName = tenantName ? tenantName : '';
    this.tenantAdminInfo.tenantAdminName = tenantAdminObj ? (tenantAdminObj.first_name ? tenantAdminObj.first_name: '')  + ' ' + (tenantAdminObj.last_name ? tenantAdminObj.last_name: '') : '';
    this.tenantAdminInfo.tenantAdminEmailId = tenantAdminObj && tenantAdminObj.email ? tenantAdminObj.email: '';
  }

  getTenant() {
    this.contentSvc.getTenant(this.loggedInUserDetails).subscribe(resp => {
      this.tenant = resp;
      if (resp.admins && resp.admins.length > 0 && this.loggedInUserDetails) {
        let loggedInTenantAdmin = resp.admins.find((tAdmin) => {
          return tAdmin && tAdmin.user_id && tAdmin.user_id == this.loggedInUserDetails.user_id;
        });
        this.setTenantAdminInfo(loggedInTenantAdmin,resp.name);
      }
      this.tenant.image = this.logoImage;
      this.contentSvc.getLogo(this.loggedInUserDetails.user_id, resp.tenant_id).subscribe(image => {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
          this.tenant.image = reader.result;
        }, false);
        if (image && image.size) {
          reader.readAsDataURL(image);
        } else {
          this.tenant.image = this.logoImage;
        }
      })
    });
  }

  getQuotaType() {    
    this.contentSvc.getQuotaTypes().subscribe((res) => {
      this.quotaTypes = res;
    });
  }
  
  getTenantApps(){
    this.contentSvc.getApps(this.loggedInUserDetails.user_id).subscribe(resp=>{
      this.tanent_apps=[];
      if(resp.length){
        resp.forEach(el => {
          let app_icon="";
          if(el.app_id==1){
            app_icon=this.iconImage+"Zs_resolve.png"
          }if(el.app_id==4){
            app_icon=this.iconImage+"Zs_ingest.png"
          }if(el.app_id==3){
            app_icon=this.iconImage+"Zs_classify.png"
          }
          this.tanent_apps.push({
            'name':el.name,
            'app_icon':app_icon,
            'app_id':el.app_id,
            'ex_date': '',
            'is_checked':false
          });
        });
        this.is_apps=true;
      }
      if (!this.isAdmin) {
        this.contentSvc.getTenant(this.loggedInUserDetails).subscribe(resp => {
          if (resp.apps.length) {
            resp.apps.forEach(element => {
              this.tanent_apps.forEach(app => {
                if (element.app_id === app.app_id) {
                  app.ex_date = element.auto_expire_on_ts;
                }
              });
            });
          }
        });
      }      
    },error=>{
      this.is_apps=false;
    });
  }

  getClustureManagementTypes(){
    this.contentSvc.getClustureManagerTypes().subscribe(res=>{
      res.map(element => {
        if(element.lookup_id === AppGlobals.APACHE_SPARK || element.lookup_id === AppGlobals.DATABRICKS ) {
          element['disable'] = false;
        } else {
           element['disable'] = true;
        }
      });
      this.clusterManagementTypes=res;
      return res;
    },err=>{
      console.log("error is there is clusture");
    })
  }

  selectDB(val) {
    this.dataBricksVal = val;
    if(val==='token') {
        this.isFormValid = false;
      }
  }

  validateDbForm() {
    const dbVal = this.dataBricksVal==='token';
    if(dbVal) {
      if(this.tenantForm.controls['token'].value && this.tenantForm.controls['token'].value.length>0) {
        this.validateForm();
      } else {
        this.isFormValid = false;
      }
    } else {
      return;
    }
  }
  
  onResize() {
    this.panelScreen = $('#tenanManagementPanel').outerWidth();
    if (this.panelScreen < 480) {
      this.buttonWrap = true;
    } else {
      this.buttonWrap = false;
    }
  }
  objectGrid(grid: AngularGridInstance) {
    this.gridObj = grid;
    this.addDefaultRow();
    this.gridObj.slickGrid.onSort.subscribe((e, args) => {
      this.readObjectDetails();
      const firstRow = grid.gridService.getDataItemByRowIndex(0);
      setTimeout(() => {
          this.setNewObjectDetails(firstRow);
          grid.gridService.deleteDataGridItem(firstRow);
          const currentData = grid.dataView.getItems();
          currentData.splice(0, 0, firstRow);
          grid.dataView.setItems(currentData);
          grid.gridService.setSelectedRows([]);
      }, 500);
    });
  }
  getManageTenants() {
    this.router.navigate(['/zs/manage-tenants']);
  }
  getAddObjectRow() {
    return { 
        'id': Math.random(), 
        'entity_id': '-1', 
        'description': '', 
        'name': '', 
        'user_name': '', 
        'email': '', 
        'action': 'add' 
    };
  }

  // On Click of edit tenant button
  editTenantClick() {
    this.title = 'Edit Tenant Parameters';
    this.isEdit = true;
    this.getTenantDetails(this.loggedInUserDetails.tenant_id);
    this.getClustureManagementTypes();
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.focusElement) {
        this.focusElement.nativeElement.focus();
        clearInterval(interval);
      }
    }, 1000);
  }

  displayTenantApps(apps) {
    this.tanent_apps.forEach((element, index) => {
      apps.find(app => {
        if (element.app_id === app.app_id) {
          element.is_checked = true;
          element.ex_date = this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
        }
      });
    });
  }

  // Display Licensed solution details on edit pop-up
  applyApps(apps) {
    if (apps && apps.length > 0) {
      apps.forEach(app => {
        if (app.app_id === 1) {
          app['is_checked'] = true,
            app['app_icon'] = this.iconImage + "Zs_resolve.png",
            app['app_id'] = app.app_id,
            app['name'] = "ZettaSense",
            app['ex_date'] = this.datePipe.transform(app.auto_expire_on_ts, 'shortDate')
        } else if (app.app_id === 3) {
          app['is_checked'] = true,
            app['app_icon'] = this.iconImage + "Zs_classify.png",
            app['app_id'] = app.app_id,
            app['name'] = "Classify",
            app['ex_date'] = this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
        } else if (app.app_id === 4) {
          app['is_checked'] = true,
            app['app_icon'] = this.iconImage + "Zs_ingest.png",
            app['app_id'] = app.app_id,
            app['name'] = "Ingest",
            app['ex_date'] = this.datePipe.transform(app.auto_expire_on_ts, 'shortDate');
        }
      });
      let nonExistApps = [{ app_id: 1 }, { app_id: 3 }, { app_id: 4 }].filter(obj => apps.every(s => s.app_id !== obj.app_id));
      nonExistApps.forEach(ap => {
        ap['is_checked'] = false,
          ap['app_icon'] = ap.app_id === 1 ? this.iconImage + "Zs_resolve.png" :
            (ap.app_id === 3 ? this.iconImage + "Zs_classify.png" : this.iconImage + "Zs_ingest.png"),
          ap['app_id'] = ap.app_id,
          ap['name'] = ap.app_id === 1 ? "ZettaSense" :
            (ap.app_id === 3 ? "Classify" : "Ingest"),
          ap['ex_date'] = ''
      })
      this.tanent_apps = [...apps];
      nonExistApps.forEach(nonApp => {
        apps.push(nonApp);
      });
    }
  }

  mapClusterValues(values) {
    const clusterValue = values.clustureType;
    this.clusterValue = values.clusterType;
    this.tenantForm.controls['clustureType'].patchValue(values.clustureType);
    this.tenant.clustureType = values.clustureType;
    switch (clusterValue) {
      case '14846':
        this.tenant.livyEndPoint = values.livyEndPoint;
        this.tenant.livyUsername = values.livyUsername;
        this.tenant.livyPassword = values.livyPassword;
        this.tenantForm.controls['livyEndPoint'].patchValue(values.livyEndPoint);
        this.tenantForm.controls['livyUsername'].patchValue(values.livyUsername);
        this.tenantForm.controls['livyPassword'].patchValue(values.livyPassword);
        let livyInfo = {
          clustureType: values.clusterValue,
          livyEndPoint: values.livyEndPoint,
          livyUsername: values.livyUsername,
          livyPassword: values.livyPassword
        }
        return livyInfo;
        break;
      case '14847':
        if (values.credentialType === 'token') {
          this.dataBricksVal = 'token';
          this.tenant.token = values.engine_auth_token;
          this.tenant.dbUrl = values.dbUrl,
            this.tenant.clusterId = values.clusterId,
            this.tenant.deploymentMode = values.credentialType,
            this.tenant.engine_auth_token = values.engine_auth_token
          this.tenant.clustureType = values.clustureType;

          this.tenantForm.controls['token'].patchValue(values.token);
          this.tenantForm.controls['dbUrl'].patchValue(values.dbUrl);
          this.tenantForm.controls['clusterId'].patchValue(values.clusterId);
          this.tenantForm.controls['deployment_mode'].patchValue(values.credentialType);
          this.tenantForm.controls['token'].patchValue(values.engine_auth_token);
        } else {
          this.dataBricksVal = 'dbcreds';
          clustureType: values.clusterValue,
            this.tenant.dbUrl = values.dbUrl,
            this.tenant.clusterId = values.clusterId,
            this.tenant.deploymentMode = 'userOrPassword',
            this.tenant.dbUserName = values.dbUserName,
            this.tenant.dbPassword = values.dbPassword
          this.tenant.clustureType = values.clustureType;

          this.tenantForm.controls['token'].patchValue(values.token);
          this.tenantForm.controls['dbUrl'].patchValue(values.dbUrl);
          this.tenantForm.controls['clusterId'].patchValue(values.clusterId);
          // this.tenantForm.controls['deploymentMode'].patchValue(values.deploymentMode);
          this.tenantForm.controls['deployment_mode'].patchValue(values.credentialType);
          this.tenantForm.controls['dbUserName'].patchValue(values.dbUserName);
          this.tenantForm.controls['dbPassword'].patchValue(values.dbPassword);
        }
        break;
      case '14848':
        break;
      case '14849':
        return {
          clustureType: clusterValue,
          projectId: 'proj',
          GCSStagingURI: '',
          clusterName: ''
        }
        break;
      case '14850':
        break;
      case '14854':
        return {
          clustureType: clusterValue,
          livyEndPoint: 'LIVY',
          username: 'livyUserName',
          password: 'livyPassword'
        }
      default:
    }
    this.validateDbForm();
    this.validateForm();
  }
  // Get tenant details in the pop-up
  getTenantDetails(tenant_id) {
    this.objectsList = [];
    this.uniques = [];
    const tenantInfo = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: tenant_id
    };

    this.contentSvc.getTenant(tenantInfo).subscribe(res => {
      if (res) {
        this.tenant = res;
        this.displayTenantApps(res.apps);
        this.isLoading = false;
        this.contentSvc.getLogo(this.loggedInUserDetails.user_id, tenant_id).subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener('load', () => {
            this.tenant.image = reader.result;
          }, false);
          if (image && image.size) {
            reader.readAsDataURL(image);
          } else {
            this.tenant.image = this.logoImage;
          }
        });
        this.tenantForm.patchValue({
          tenant_id: res.tenant_id,
          name: res.name,
          description: res.description,
          quota_type_lookup_id: res.quota_type_lookup_id,
          quota_type_value: res.quota_type_value,
          enable_dq_rule: res.enable_dq_rule,
          is_azure_sos_tenant: res.is_azure_sos_tenant,
          is_trial_tenant: res.is_trial_tenant,
        });
        if (res.admins && res.admins.length > 0) {
          this.uniques = res.admins.map((item) => {
            item['action'] = 'edit';
            item['id'] = Math.random().toString(36).slice(-6);
            item['entity_id'] = '-1';
            item['description'] = item['first_name'];
            item['name'] = item['last_name'];
            item['user_name'] = item['user_name'];
            item['email'] = item['email'];
            return item;
          });
          // this.gridObj.dataView.refresh();
          this.clusterValue = res.cluster_information.clustureType;
          this.mapClusterValues(res.cluster_information);
          this.validateForm();
          //this.objectsList = [];
        }
        //this.objectsList = [];
        if (this.gridObj || res.admins.length > 0) {
          this.objectsList = [{
            'id': Math.random().toString(36).slice(-6),
            'entity_id': '-1',
            'description': '',
            'name': '',
            'user_name': '',
            'email': '',
            'action': 'add'
          }, ...this.uniques];      
          // this.gridObj.dataView.refresh();
          /** Temporary fix added.  */
          setTimeout(() => {
            this.gridObj.dataView.setItems(this.objectsList);
          }, 500);
          
        }
      } else {
        if (this.gridObj) {
          this.objectsList = [{
            'id': Math.random().toString(36).slice(-6),
            'entity_id': '-1',
            'description': '',
            'name': '',
            'user_name': '',
            'email': '',
            'action': 'add'
          }];
          
          setTimeout(() => {
            this.gridObj.dataView.setItems(this.objectsList);
          }, 500);
        }
      }

    });
    this.isLoading = false;
    this.setTenantForm();
  }

  // On Check/Uncheck license app solution
  onAppChange(isChecked, i) {
    this.tanent_apps.forEach((element, index) => {
      if (index === i) {
        element.is_checked = isChecked;
      }
    });
    this.validateForm();
  }

  isTenantExpiryDateValid(tenantAppExpiryDate) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    tenantAppExpiryDate.setHours(0, 0, 0, 0);
    if (tenantAppExpiryDate && tenantAppExpiryDate.getTime() >= currentDate.getTime()) {
      return true;
    }
    return false;
  }
  // On Check/Uncheck license app date solution
  onAppDateChange(date, i) {
    this.tanent_apps.forEach((element, index) => {
      if (index === i) {
        element.ex_date = this.isTenantExpiryDateValid(date) ? this.datePipe.transform(date, 'shortDate'): null;
      }
    });
    this.validateForm();
  }

  validateForm() {
    const apps = this.tanent_apps.filter(app => app.is_checked == true && app.ex_date);
    const clusterType = this.tenantForm.controls['clustureType'].value;
    let isQuotaValueBetween = this.tenantForm.controls.quota_type_value.value > 0 && this.tenantForm.controls.quota_type_value.value < AppGlobals.MAXIMUM_QUOTA_VALUE ? true : false;
    const isQuotaType = this.tenantForm.controls.quota_type_lookup_id.value != -1 && isQuotaValueBetween ? true : false;
    if (apps.length && this.uniques && this.uniques.length > 0 && clusterType && clusterType.length > 0 && clusterType !== '-1' && isQuotaType) {
        if(clusterType == AppGlobals.APACHE_SPARK) { 
          this.tenantForm.patchValue({ 
            dbUrl:null,
            clusterId:null,
            dbUserName:null,
            dbPassword:null
          })        
            if(this.tenantForm.controls['livyEndPoint'].value && this.tenantForm.controls['livyUsername'].value && this.tenantForm.controls['livyPassword'].value ) {
              this.isFormValid = true;
            } else {
              this.isFormValid = false;
            }
          } 
          if(clusterType == AppGlobals.DATABRICKS) {
            this.tenantForm.patchValue({ 
              livyEndPoint:null,
              livyUsername:null,
              livyPassword:null
            })
            if(this.tenantForm.controls['dbUrl'].value && this.tenantForm.controls['clusterId'].value){
              if(this.dataBricksVal==='token' && this.tenantForm.controls['token'].value){
                this.isFormValid=true;
              }
              else if(this.dataBricksVal!=='token') {
                if(this.tenantForm.controls['dbUserName'].value && this.tenantForm.controls['dbPassword'].value){
                  this.isFormValid=true;
                }
              }else{
                this.isFormValid=false;
              }
            }else{
              this.isFormValid=false;
            }                  
          }
        }else{
          this.isFormValid=false;
        }  
  }
  
  setTenantForm() {
    this.tenantForm = this.formBuilder.group({
      name: [this.tenant.name],
      description: [this.tenant.description],
      created_by: [this.tenant.tenant_admin_name],
      email: [this.tenant.tenant_admin_email],
      first_name: [this.tenant.first_name],
      last_name: [this.tenant.last_name],
      tenant_id: [this.tenant.tenant_id],
      app_name:[],
      ex_date: '',
      is_checked: false,
      clustureType:'-1',
      authentication_method:'-1',
      deployment_mode:'-1',
      token: '',
      is_trial_tenant:false,
      is_azure_sos_tenant:false,
      enable_dq_rule: false,
      dbUrl:'',
      clusterId: '',
      dbUserName: '',
      dbPassword:'',
      livyEndPoint:'',
      livyUsername:'',
      livyPassword:'',
      quota_type_lookup_id: '-1',
      quota_type_value: [this.tenant.quota_type_value]
    });
    this.isFormValid=false;
  }

  // On click of save and close button
  onSave() {
    const apps = this.tanent_apps.filter((app) => {
      return app.is_checked==true && app.ex_date
    });
    let tanent_app = [];
    apps.forEach(el => {
      tanent_app.push({
        'app_id': el.app_id,
        'auto_expire_on_ts': this.datePipe.transform(el.ex_date, 'yyyy-MM-dd')
      });
    });

    if (this.tenantForm.valid) {
      const tenantTemp: any = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.tenant.tenant_id,
      };
      if (this.isEdit) {
        this.uniques.forEach(admin => {
          admin['first_name'] = admin.description,
            admin['last_name'] = admin.name,
            admin['user_name'] = admin.user_name,
            admin['email'] = admin.email,
            delete admin.description,
            delete admin.name,
            delete admin.id,
            delete admin.entity_id,
            delete admin.action,
            delete admin.synonyms
        })
        const adminUser = this.uniques;
        tenantTemp.payload = {
          'name': this.tenantForm.controls.name.value,
          'description': this.tenantForm.controls.description.value,
          'default_app_id': apps[0].app_id,
          'apps': tanent_app,
          'app_id': this.loggedInUserDetails.preference.default_app_id,
          'is_trial_tenant': this.tenantForm.controls.is_trial_tenant.value ? true : false,
          'is_azure_sos_tenant': this.tenantForm.controls.is_azure_sos_tenant.value ? true : false,
          'enable_dq_rule': this.tenantForm.controls.enable_dq_rule.value ? true : false,
          'engine_type_id': parseInt(this.tenantForm.controls['clustureType'].value) === AppGlobals.DATABRICKS ? AppGlobals.ENGINE_TYPE.DBR : AppGlobals.ENGINE_TYPE.HDI,
          'admin': adminUser,
          'cluster_information': this.getClusterInfo(),
          'quota_type_lookup_id': this.tenantForm.controls.quota_type_lookup_id.value,
          'quota_type_value': parseInt(this.tenantForm.controls.quota_type_value.value)
        };
        this.contentSvc.editTenant(tenantTemp).subscribe(responseList => {
          if(responseList) {
            this.messageSvc.sendMessage({ message: 'Tenant updated successfully', type: 'SUCCESS', hideboard: true });
            this.isLoading = true;
            if (this.gridObj) {
              this.gridObj.dataView.setItems([]);
            }
            this.getTenant();
            this.getTenantsList();
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
          }            
        }, err => {
          if (err.error.error) {
            let isLimitExceeds: Boolean = err.error.error.includes('out of range');
            if (isLimitExceeds) {
              this.messageSvc.sendMessage({ message: AppGlobals.QUOTA_TYPE_LIMIT_EXCEEDS_MSG, type: 'ERROR', hideboard: true });
            } else {
              if (err.status === 400) {
                this.messageSvc.sendMessage({ message: err.error.message ? err.error.message : err.error.error, type: 'ERROR', hideboard: true });
              } else {
                this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
              } 
            }
          }
        });
      } else {
        this.uniques.forEach(admin => {
          admin['first_name'] = admin.description,
            admin['last_name'] = admin.name,
            admin['user_name'] = admin.user_name,
            admin['email'] = admin.email,
            delete admin.description,
            delete admin.name,
            delete admin.id,
            delete admin.entity_id,
            delete admin.action,
            delete admin.synonyms
        })
        const adminUser = this.uniques;
        tenantTemp.payload = {
          'name': this.tenantForm.controls.name.value,
          'description': this.tenantForm.controls.description.value,
          'app_id': this.loggedInUserDetails.preference.default_app_id,
          'default_app_id': apps[0].app_id,
          'apps': tanent_app,
          'is_trial_tenant': this.tenantForm.controls.is_trial_tenant.value ? true : false,
          'admin': adminUser,
          'engine_type_id': this.tenantForm.controls['clustureType'].value === '14847' ? 452 : 451,
          'is_azure_sos_tenant': this.tenantForm.controls.is_azure_sos_tenant.value ? true : false,
          'enable_dq_rule': this.tenantForm.controls.enable_dq_rule.value ? true : false,
          'cluster_information': this.getClusterInfo(),
          'quota_type_lookup_id': this.tenantForm.controls.quota_type_lookup_id.value,
          'quota_type_value': parseInt(this.tenantForm.controls.quota_type_value.value)
        };
        if (this.dataBricksVal === 'token') {
          tenantTemp.payload['engine_auth_token'] = this.tenantForm.controls.token.value
        }
        this.objectsList = [{
          'id': Math.random().toString(36).slice(-6),
          'entity_id': '-1',
          'description': '',
          'name': '',
          'user_name': '',
          'email': '',
          'action': 'add'
        }];
        this.uniques = [];   
        this.contentSvc.createTenant(tenantTemp).subscribe(responseList => {
          if(responseList) {
            this.messageSvc.sendMessage({ message: 'Tenant created successfully', type: 'SUCCESS', hideboard: true });
            this.isLoading = true;
            if (this.gridObj) {
              this.gridObj.dataView.setItems([]);
            }
            this.getTenantsList();
            this.isFormValid = false;
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
          }
        }, err => {
          if (err.status === 400) {
            this.messageSvc.sendMessage({ message: err.error.message ? err.error.message : err.error.error, type: 'ERROR', hideboard: true });
          } else {
            this.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
          }        
        });
      }
    }
  }

  saveLogo(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const URL = window.URL;
      const Img = new Image();
      let fd = new FormData();
      let reader = new FileReader();
      fd.append('logo', ev.target.files[0]);
      const logoPayload: any = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.loggedInUserDetails.tenant_id,
        payload: fd
      };
      this.contentSvc.uploadLogo(logoPayload).subscribe(resp => {
        reader.onload = (e: any) => {
          this.tenant.image = e.target.result;
          this.is_logo=true;
        }       
        reader.readAsDataURL(ev.target.files[0]);
        this.messageSvc.sendMessage({ message: 'Logo Updated', type: 'INFO', hideboard: true });
      }, err => {
      });
    }
  }

  createTenantClick() {
    this.objectsList = [];
    this.isLoading = false;
    this.getTenantApps();
    this.title = 'Create New Tenant';
    this.isEdit = false;
    this.tenant = {
      'name': '',
      'description': '',
      'system_user_name': '',
      'system_user_password': '',
      'system_user_email': '',
      'image': this.logoImage,
      'is_trial_tenant': false,
      'is_azure_sos_tenant': false,
      'enable_dq_rule': false
    };
    if (this.gridObj) {
      const firstRow = this.getAddObjectRow();
      this.setNewObjectDetails(firstRow);
      this.objectsList.splice(0, 0, firstRow);
      this.isDataReady = true;
      this.gridObj.dataView.refresh();
      this.gridObj.dataView.setItems(this.objectsList);
      this.uniques = this.objectsList;
    }
    this.setTenantForm();
    this.getClustureManagementTypes();
  }

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
  }

  onRowSelection(eventData) {
    const parent = this;
    this.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const metadata = this.gridObj.gridService.getColumnFromEventArguments(args);
    this.selectedObjectMetadata = metadata;
    const row = this.gridObj.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;

    if (event.target.className.includes('blue-color-link') && event.target.className.includes('fa fa-plus')) {
      const newNAME = $('#object-description-' + args.row)[0]['value'];
      const newDESC = $('#object-name-' + args.row)[0]['value'];
      const newUser_Name = $('#object-user_name-' + args.row)[0]['value'];
      const newEmail = $('#object-email-' + args.row)[0]['value'];
      let newTenantAdmin = [{
        description: newNAME,
        name: newDESC,
        user_name: newUser_Name,
        email: newEmail,
        id: Math.random().toString(36).slice(-6),
        action: 'edit',
        synonyms: Math.floor(Math.random() * (9 - 0)) + 0
      }];
      if (newUser_Name) {
        this.contentSvc.tenantAdminUserValidation(newUser_Name).subscribe((responseData) => {
          if (responseData) {
            this.messageSvc.sendMessage({ message: 'User Name already exists.', type: 'ERROR', hideboard: true });
          }
        }, error => {
          if (this.gridObj) {
            let concatedRows = [...this.gridObj.dataView.getItems(), ...newTenantAdmin];
            const firstRow = this.getAddObjectRow();
            this.setNewObjectDetails(firstRow);
            this.objectsList.splice(0, 0, firstRow);
            this.isDataReady = true;
            this.objectsList = concatedRows;
            const unique = [];
            const map = new Map();
            for (const item of concatedRows) {
              if (!map.has(item.id)) {
                map.set(item.id, true);
                if (item && item.id && item.name) {
                  unique.push(
                    item
                  );
                }
              }
            }
            this.gridObj.dataView.refresh();
            this.uniqueList = [{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...unique];
            this.uniques = [...unique];
            this.gridObj.dataView.setItems(this.uniqueList);
            this.uniqueList = [];
            this.validateForm();
            $('#object-description-' + args.row)[0]['value'] = '';
            $('#object-name-' + args.row)[0]['value'] = '';
            $('#object-user_name-' + args.row)[0]['value'] = '';
            $('#object-email-' + args.row)[0]['value'] = '';
          }
        })
      }
      this.validateForm();
    } else if(event.target.className.includes('red-color-link') && event.target.className.includes('far fa-trash-alt')) {
        if (row.user_id) {
          this.userGroupService.deleteUsers(row.user_id).subscribe((responseData) => {
          });
        }
      this.uniques = this.uniques.filter(item => item.id !== row.id);
      this.objectsList = this.objectsList.filter(item => item.id !== row.id);
      if(!this.isEdit) {
        if(this.objectsList.length===0) {
          this.objectsList=  [{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }];
        this.validateForm();
          this.gridObj.dataView.setItems([...this.objectsList]);
        } else if(this.uniques.length===0){
          this.objectsList = this.objectsList.filter(item => item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
          this.gridObj.dataView.setItems([...this.objectsList]);
        } else {
             this.objectsList = this.objectsList.filter(item => item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
          this.gridObj.dataView.setItems([{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }, ...this.objectsList]);
        }
      } else {
        if(this.objectsList.length===0) {
          this.objectsList=  [
            { 
              'id': Math.random().toString(36).slice(-6), 
              'entity_id': '-1', 
              'description': '', 
              'name': '', 
              'user_name': '', 
              'email': '', 
              'action': 'add' 
          }
          ];
        this.validateForm();
          this.gridObj.dataView.setItems([{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }]);
        } else if(this.uniques.length===0){
          this.objectsList = this.objectsList.filter(item => item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
        this.objectsList = [
          { 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }];
        if(this.objectsList.length===1) {
          this.gridObj.dataView.setItems([...this.objectsList]);
        } else {
          this.gridObj.dataView.setItems([{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }, this.uniques]);
        }

        } else if(this.uniques.length===1){
          this.objectsList = this.objectsList.filter(item => item.id)
          .filter((value, index, self) => self.indexOf(value) === index);
          this.gridObj.dataView.setItems([{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }, ...this.uniques])
        } else {
          this.gridObj.dataView.setItems([{ 
            'id': Math.random().toString(36).slice(-6), 
            'entity_id': '-1', 
            'description': '', 
            'name': '', 
            'user_name': '', 
            'email': '', 
            'action': 'add' 
        }, ...this.objectsList]);
        }
      }
      this.validateForm();
    } else if (row !== undefined && row !== null && metadata.columnDef.field === 'action') {
      let idCell = '#object-' + fieldName + '-' + args.row;
      if (metadata.dataContext.action === 'edit' || event.target.className == 'fal fa-pen actionInlineBtn blue-color-link' ||
        event.target.className == 'fal blue-color-link fa-pen actionInlineBtn' || event.target.className == 'blue-color-link fas fa-pen actionInlineBtn') {

        var oldValue: any;
        var newValue: any;
        $('#object-description-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-name-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-user_name-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        $('#object-email-' + args.row).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });
        // $(idCell).css({ 'background-color': '#fff', 'border': '1px solid #e6e6e6', 'border-radius': '3px' });

        $(idCell).focusout(function () {
          newValue = $(idCell)[0]['value'];
          if (fieldName === 'description') { oldValue = row.description; }
          if (fieldName === 'name') { oldValue = row.name; }
          if (fieldName === 'email') { oldValue = row.email; }
          if (fieldName === 'user_name') { oldValue = row.user_name; }
          if (oldValue !== newValue) {
            const objectX: any = {};
            objectX.entity_id = row.entity_id;
            if (fieldName === 'description') {
              objectX.description = newValue;
              parent.admin['description'] = newValue;
            }
            if (fieldName === 'name') {
              objectX.name = newValue
              parent.admin['name'] = newValue;
            }
            if (fieldName === 'user_name') {
              objectX.user_name = newValue
              parent.admin['user_name'] = newValue;
            }
            if (fieldName === 'email') {
              objectX.email = newValue
              parent.admin['email'] = newValue;
            }
          }
          $('#object-description-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-name-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-email-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
          $('#object-user_name-' + args.row).css({ 'background-color': '#fff', 'border': '0' });
        });
        if (event.target.className.includes('fal fa-pen actionInlineBtn') || event.target.className.includes('fas fa-pen actionInlineBtn') || 
            event.target.className == 'blue-color-link fas fa-pen actionInlineBtn' || event.target.className == 'fal blue-color-link fa-pen actionInlineBtn') {

          if (event.target.className.includes('fal fa-pen actionInlineBtn') ||
            event.target.className == 'fal blue-color-link fa-pen actionInlineBtn') {
            $('#edit-' + args.row).removeClass('fal fa-pen actionInlineBtn');
            $('#edit-' + args.row).addClass('fas fa-pen actionInlineBtn');
            $('#edit-' + args.row).show();

            $('#noEdit-description-' + args.row).hide();
            $('#noEdit-name-' + args.row).hide();
            // Email and Username are made non-editable
            // $('#noEdit-email-' + args.row).hide();
            // $('#noEdit-user_name-' + args.row).hide();
            $('#isEdit-description-' + args.row).show();
            $('#isEdit-name-' + args.row).show();
            // Email and Username are made non-editable
            // $('#isEdit-user_name-' + args.row).show();
            // $('#isEdit-email-' + args.row).show();
          } else if (event.target.className.includes('fas fa-pen actionInlineBtn') ||
            event.target.className == 'blue-color-link fas fa-pen actionInlineBtn') {

            let email = $('#object-email-' + args.row).val();
            let uName = $('#object-user_name-' + args.row).val();
            let desc = $('#object-description-' + args.row).val();
            let name = $('#object-name-' + args.row).val();
              this.objectsList[args.row]['email'] = email;
              this.objectsList[args.row]['user_name'] = uName;
              this.objectsList[args.row]['description'] = desc;
              this.objectsList[args.row]['name'] = name;
  
              let concatedData = [...this.gridObj.dataView.getItems(), ...this.objectsList];
              const firstRow = this.getAddObjectRow();
              this.setNewObjectDetails(firstRow);
              this.objectsList.splice(0, 0, firstRow);
              this.isDataReady = true;
              this.uniqueList = [];
              concatedData.map(ten => ten.id = Math.random().toString(36).slice(-6))              
              this.objectsList = concatedData;
              const unique = [];
              const map = new Map();
              for (const item of concatedData) {
                if (!map.has(item.id)) {
                  map.set(item.id, true);
                  if (item && item.id && item.name) {
                    unique.push(
                      item
                    );
                  }
                }
              }
              this.gridObj.dataView.refresh();
              this.uniqueList = [{
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              }, ...unique];              
              this.uniques = [...unique];

              this.gridObj.dataView.setItems(this.uniqueList);
              this.uniqueList = [];
              // this.gridObj.dataView.setItems([...this.objectsList]);
  
              $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
              $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
              $('#edit-' + args.row).show();
  
              $('#noEdit-description-' + args.row).show();
              $('#noEdit-name-' + args.row).show();
              $('#noEdit-user_name-' + args.row).show();
              $('#noEdit-email-' + args.row).show();
              $('#isEdit-description-' + args.row).hide();
              $('#isEdit-name-' + args.row).hide();
              $('#isEdit-user_name-' + args.row).hide();
              $('#isEdit-email-' + args.row).hide();  
          } else {
            if (!this.admin.description) {
              this.admin.description = row.description;
            } else {
              this.admin.description = this.admin.description;
            }
            if (!this.admin.name) {
              this.admin.name = row.name;
            } else {
              this.admin.name = this.admin.name;
            }
            if (!this.admin.user_name) {
              this.admin.user_name = row.user_name;
            } else {
              this.admin.user_name = this.admin.user_name;
            }
            if (!this.admin.email) {
              this.admin.email = row.email;
            } else {
              this.admin.email = this.admin.email;
            }

            this.isDataReady = false;
            this.objectsList = [];
            // args.row
            this.gridObj.dataView.refresh();
            this.uniques[args.row - 1] = this.admin;



            // const firstRow = this.getAddObjectRow();
            // this.setNewObjectDetails(firstRow);
            // this.objectsList.splice(0, 0, firstRow);
            this.gridObj.dataView.setItems([
              {
                'id': Math.random().toString(36).slice(-6),
                'entity_id': '-1',
                'description': '',
                'name': '',
                'user_name': '',
                'email': '',
                'action': 'add'
              },
              ...this.uniques]);
            this.gridObj.gridService.setSelectedRows([]);

            this.objectsList = [{
              'id': Math.random().toString(36).slice(-6),
              'entity_id': '-1',
              'description': '',
              'name': '',
              'user_name': '',
              'email': '',
              'action': 'add'
            }, ...this.uniques];
            this.isDataReady = true;
            this.admin = {
              description: '',
              name: '',
              user_name: '',
              email: '',
              id: Math.random().toString(36).slice(-6),
              entity_id: '-1',
              action: 'edit',
              synonyms: Math.floor(Math.random() * (9 - 0)) + 0
            };
            $('#edit-' + args.row).removeClass('fas fa-pen actionInlineBtn');
            $('#edit-' + args.row).addClass('fal fa-pen actionInlineBtn');
            $('#edit-' + args.row).show();

            $('#noEdit-description-' + args.row).show();
            $('#noEdit-name-' + args.row).show();
            $('#noEdit-user_name-' + args.row).show();
            $('#noEdit-email-' + args.row).show();
            $('#isEdit-description-' + args.row).hide();
            $('#isEdit-name-' + args.row).hide();
            $('#isEdit-user_name-' + args.row).hide();
            $('#isEdit-email-' + args.row).hide();
          }

        }
        else if (event.target.className.includes('fal fa-link actionInlineBtn')) {
          $('#edit-' + this.lastRowSelected).removeClass('fas fa-pen actionInlineBtn');
          $('#edit-' + this.lastRowSelected).addClass('fal fa-pen actionInlineBtn');
          $('#edit-' + this.lastRowSelected).show();

          $('#noEdit-description-' + this.lastRowSelected).show();
          $('#noEdit-name-' + this.lastRowSelected).show();
          $('#noEdit-email-' + this.lastRowSelected).show();
          $('#noEdit-user_name-' + this.lastRowSelected).show();
          $('#isEdit-description-' + this.lastRowSelected).hide();
          $('#isEdit-name-' + this.lastRowSelected).hide();
          $('#isEdit-email-' + this.lastRowSelected).hide();
          $('#isEdit-user_name-' + this.lastRowSelected).hide();
        }

        this.lastRowSelected = args.row;
      }
    }
}
  onChange(event) {
    var keyCode = ('which' in event) ? event.which : event.keyCode;
    if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105) && keyCode != 8) {
      event.preventDefault();
      return false;
    }
  }
getClusterInfo() {
  const clusterValue = this.tenantForm.controls['clustureType'].value;
  switch (clusterValue) {
    case '14846':
       const livyInfo = {
        clustureType: clusterValue,
        livyEndPoint: this.tenantForm.controls.livyEndPoint.value,
        livyUsername: this.tenantForm.controls.livyUsername.value,
        livyPassword: this.tenantForm.controls.livyPassword.value
      };
      return livyInfo;
        break;
    case '14847':
      if(this.dataBricksVal==='token') {
        let clustInfo = {
            clustureType: clusterValue,
            dbUrl: this.tenantForm.controls.dbUrl.value,
            clusterId: this.tenantForm.controls.clusterId.value,
            credentialType: 'token',
            engine_auth_token: this.tenantForm.controls.token.value
          }
          return clustInfo;
      } else {
        let clustInfo = {
            clustureType: clusterValue,
            dbUrl: this.tenantForm.controls.dbUrl.value,
            clusterId: this.tenantForm.controls.clusterId.value,
            credentialType: 'dbcreds',
            dbUserName: this.tenantForm.controls.dbUserName.value,
            dbPassword: this.tenantForm.controls.dbPassword.value
        };
        return clustInfo;
      }
        break;
    case '14848':
        break;
    case '14849':
      return {
        clustureType: clusterValue,
        projectId: 'proj',
        GCSStagingURI: '',
        clusterName: ''
      }
        break;
    case '14850':
        break;
    case '14854':
      return {
        clustureType: clusterValue,
      }
    default:
      console.log('DEFAULT ');   
}
}

  readObjectDetails() {
    this.addObject['description'] = $('#object-description-0')[0]['value'];
    this.addObject['name'] = $('#object-name-0')[0]['value'];
    this.addObject['user_name'] = $('#object-user_name-0')[0]['value'];
    this.addObject['email'] = $('#object-email-0')[0]['value'];
  }

  setNewObjectDetails(row) {
    row.description = this.addObject['description'];
    row.name = this.addObject['name'];
    row.user_name = this.addObject['user_name'];
    row.email = this.addObject['email'];
  }

  clearInputField(input) {
    this.tenant[input] = '';
    this.tenantForm.controls[input].patchValue('');
  }

  getTenantDetail(tenant_id) {
    this.title = 'Edit Tenant Parameters';
    this.isEdit = true;
    this.getTenantDetails(tenant_id);
    this.getClustureManagementTypes();
  }

  onClose() {
    this.isLoading = true;
    this.getTenantApps();
  }

  addDefaultRow() {
    this.addFirstRow = this.getAddObjectRow();
    if (this.gridObj && !this.objectsList.length) {
      this.objectsList.push(this.addFirstRow);
      const currentData = this.gridObj.dataView.getItems();
      currentData.splice(0, 0, this.addFirstRow);
      this.gridObj.dataView.setItems(currentData);
      this.gridObj.gridService.setSelectedRows([]);
    }
  }
}
