import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'numeric-cell-renderer',
  template: `<div class="text-right w-100 pd-right-10 blueLink" [title]="params.value">{{ params.value }}</div>`
})
export class NumericCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
