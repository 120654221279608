import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../common/services/base-service';

@Component({
  selector: 'zetta-zs-in-transformation',
  templateUrl: './zs-in-transformation.component.html',
  styleUrls: ['./zs-in-transformation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsInTransformationComponent implements OnInit {

  safeSrc: SafeResourceUrl;
  url;
  // pipelineType;

  constructor(private sanitizer: DomSanitizer,
              private baseService: BaseService) { }

  ngOnInit() {

    // this.pipelineType = sessionStorage.getItem("pipelineType");
    // console.log(this.pipelineType)

    console.log(this.baseService.apiUrl)

    //Ingestion Pipelines: http://40.121.147.116:18630/        admin/admin
    //Transformation Pipelines: http://40.121.147.116:19630/        admin/admin

    // this.url = this.baseService.apiUrl + '/users/3/tenants/1/datasets/181/dataprofiler/report';
    // if (this.pipelineType === 'Transformation') {
      this.url = 'http://40.121.147.116:19630/';
    // } 
    

    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    
  }

}