import { Component, OnInit, ViewContainerRef, OnDestroy, Input, Output  } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../common/components/message/message.service';
import { matherMergerFormatter } from '../../../common/shared/formatters/matherMergerFormatter';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { Project } from 'src/app/zettasense/content/project/project.model';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { checkBoxFormattter } from 'src/app/common/shared/formatters/checkBoxFormatter';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { ZsClContentService } from '../zs-cl-content.service';
import * as _ from 'lodash';
import { BaseService } from 'src/app/common/services/base-service';
declare var $: any;
import { environment } from '../../../../environments/environment';
import { StatisticService } from 'src/app/zettasense/content/statistics/statistics.service';
import { confidenceLevelFormatter } from 'src/app/common/shared/formatters/confidenceLevelFormatter';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { checkBoxDisableFormatter } from 'src/app/common/shared/formatters/checkBoxDisableFormatter';
import { revieweCheckBoxFormattter } from 'src/app/common/shared/formatters/reviewerCheckBoxFormatter';
import { disabledCheckBoxFormatter } from 'src/app/common/shared/formatters/disableCheckBoxFormatter';
import { aprooveerCheckBoxFormattter } from 'src/app/common/shared/formatters/approverCheckBoxFormatter';
import { ZsSocFeedbackComponent } from '../zs-soc-feedback/zs-soc-feedback.component';


const datasetNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  let name = value;
  if (value.includes('"')) {
    name = value.replace(/"/g, "'");
  }
  return `<div class="datasource-name-formatter text-wrap">
            <span title="${value}">${name}</span><br>
        </div>`;
};

const conditionalDateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.updated_ts === null || dataContext.updated_ts === undefined || dataContext.updated_ts === '') {
    if(dataContext.created_ts === null || dataContext.created_ts === undefined || dataContext.created_ts === '') {
      return;
    } else {
      const d = new Date(dataContext.created_ts);
      const m = d.getMonth() + 1;
      const month = (m <= 9 ? '0' + m : m);
      return `<div class='text-center' title='${month}/${d.getDate()}/${d.getFullYear()}'>${month}/${d.getDate()}/${d.getFullYear()}</div>`;
    }
  }
  const d = new Date(dataContext.updated_ts);
  const m = d.getMonth() + 1;
  const month = (m <= 9 ? '0' + m : m);
  return `<div class='text-center' title='${month}/${d.getDate()}/${d.getFullYear()}'>${month}/${d.getDate()}/${d.getFullYear()}</div>`;
}

const graduateHat: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {


  let boldDecider = dataContext.assigned_to !== null ? '#0e709f' : '#0e709f6b';
  return `
  <span class="feedback-trigger" style="cursor: pointer; color: ${boldDecider}" ><i class="fas fa-graduation-cap ml-7-5" aria-hidden="true"></i></span>
  `;
}

const thumbsUpandDown: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  if(dataContext.vote === 1){
   return `<div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="fas fa-thumbs-up"></i></div>`
 }else {
   return `<div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="fas fa-thumbs-down"></i></div>`
 }
}
const formatStatus: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const zettaUtilsInstance = new ZettaUtils();
  function getTrainResolveModelColorByStatus() {
    return zettaUtilsInstance.getTrainResolveModelColorByStatus(value);
  }
  let statusInfo = dataContext.status;
  return `
  <div class ="ml-0">
  <div style="border-left: 6px solid ${getTrainResolveModelColorByStatus()}">
  <span  class="d-inline-block ml-1 text-truncate wd-95">${statusInfo}</span>
  </div>
  </div>
  `;
}

const mappingFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  // let cssLevel = '';
  // let confidenceLevel = '';
  // let condLike = dataContext.vote === null ? 'fal' : (dataContext.vote === 1 ? 'fas' : 'fal');
  // let condUnlike = dataContext.vote === null ? 'fal' : (dataContext.vote === 0 ? 'fas' : 'fal');
  // cssLevel = dataContext.level === 'HIGH' ? '' : dataContext.level === 'MEDIUM' ? 'boxM' : 'boxL';
  // confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${dataContext.level}</div>
  //                     <div class="porcent text-center">${dataContext.score100}%</div>
  //                     <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
  //                     <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>`;
  // return confidenceLevel; 

  let cssLevel = '';
  let confidenceLevel = '';
  let score100 = Math.round(dataContext.score * 100);
  let conf = score100 >= 75 ? 'HIGH' : (score100 >= 50  && score100 < 75 ) ? 'MEDIUM' : 'LOW';
  let condLike = dataContext.vote === null ? 'fal' : (dataContext.vote === 1 ? 'fas' : 'fal');
  let condUnlike = dataContext.vote === null ? 'fal' : (dataContext.vote === 0 ? 'fas' : 'fal');
  cssLevel = conf === 'HIGH' ? 'boxHigh' : conf === 'MEDIUM' ? 'boxMed' : 'boxLow';
  confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${conf}</div>
               <div class="porcent text-center  mr-2" title='${score100}%'>${score100}%</div>
                        <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
                        <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down"></i></div>`;
  return confidenceLevel;
};

const nameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95">${value}</label></div>`;
}

const subConceptFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
  return `<div class="text-truncate pl-1">${value}</div>`;
}

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(dataContext.isChecked){
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" checked value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }else{
    return `<div class="formatter match-radio-formatter">
    <input type="radio" id="data-${row}" name="dataSelected" value="data-${row}"><label for="data-${row}" class="radio-formatter radio-label notsure-formatter"></label>
</div>`;
  }

};

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return dataContext.status !== AppGlobals.TASK_COMPLETED
    ? `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v fa-2x" aria-hidden="true"></i></span>`
    : ``;
};

@Component({
  selector: 'zetta-zs-cl-project-training',
  templateUrl: './zs-cl-project-training.component.html',
  styleUrls: ['./zs-cl-project-training.component.scss']
})
export class ZsClProjectTrainingComponent implements OnInit {
  public loggedInUserDetails = null;
  public selectedCluster;
  taskGridOptions: any;
  rightGridOptions: any;
  taskGridColumnsDef: any;
  rightGridColumnsDef: any;
  tasksDataSource: any = [];
  priorFeedbackDataSource: any = [];
  rightTasksDataSource: any = [];
  isTasksLoading = true;
  isRightLoading = true;
  hasScrolled = false;
  noData = false;
  public taskGrid: AngularGridInstance;
  public rightTaskGrid: AngularGridInstance;
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  selectedRowDataSource;
  taskType: string;
  taskTypeId: string;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModalDatasetSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  public leftGridTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  projectDetail = new Project();
  total = 0;
  page = 1;
  taskDetailsPage = 1;
  taskDetailsLimit = 100;
  limit = 100;
  grandTotal = 0;
  subTotal = '';
  selectedMappedColumn:number;
  taskId: number;
  projId: string;
  public catalogs: any;
  public grid: AngularGridInstance;
  projectHomeTableColDef: any;
  trainModalGridOptions: any;
  isLoading = true;
  apiUrl: string = environment.config.API_URL;
  usersData;
  catalog_id;
  datasources: any = [];
  datasets: any = [];
  totalDatasources = 0;
  totalDatasets = 0;
  isLoadingDatasources = true;
  isLoadingDatasets = true;
  gridDatasources: AngularGridInstance;
  gridDatasets: AngularGridInstance;
  hasDatasourcesScrolled = false;
  hasDatasetsScrolled = false;
  datasourcesTableColDef: any;
  datasetsTableColDef: any;
  reviewPriorMappingsDef: any;
  taskFeedbackColumnsDef: any;
  gridDataOptions: any;
  dataCatalog;
  modalRowData: any;
  modalGridOptions: any;
  modelColumnDef = [];
  isSampleDsLoading = true;
  public modalGrid: AngularGridInstance;
  url;
  safeSrc: SafeResourceUrl;
  dsName;
  showmodelpopup=false;
  datasetId:any;
  showDownvoteModal:boolean=false;
  showReviewPrior: boolean = false;
  datasetColumnId:number;
  object_id;
  taskName:string;
  classInfo: any;
  viewTaskAssignments = false;
  screen_name = "classify";
  rowData:any;
  classes: any;
  selected_record_id:string;
  show_run_model=false;
  showPriorFeedbackRerun = false;
  is_import_enable = true;
  showDownvoteTrainModal=false;
  selectedColumn:string;
  clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL
  dataRes;
  is_upload_complete = false;
  fixClIssue = false;
  public project_type;
  public subConceptGrid: AngularGridInstance;
  selectedSubConcept:string;
  isSubConceptLoading=true;
  selectedSubConceptRow:string;
  isSubConcept=false;
  selectedPridiction=[];
  subConceptList=[];
  subConceptColumn: object =[];
	showSummaryModal : boolean = false;
  subConceptResult=[];
  feedbackAdded=false;
  trainModalPayload;
  selected_task_id:number;
  classifier:string;
  trainModalPage = 1;
  msgHeader:string;
  msgText:string;
  showConfirmBox:boolean;
  hasDeleted:boolean;
  btnModifyDisabled: boolean = true;
  selectedUserRoles: any = [];
  enableApplyBtn = false;
  modalClasses = [];
  statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
  errorMsg = '';
  isShowRightGrid = false;
  manageClassify = false;
  isFourEyeChecked: Boolean;
  is_task_completed = false;
  classify_project_users = [];
  fu_type_classifyProjectTraining;
  taskAssignDetails: {};
  assignType: string = 'classifyProject';
  selectedProjects = [];
  // viewTaskAssignments = false;
  selectedRow: any;
  projectTasksData: object = {};

  isProjectAdmin: Boolean = false;
  trainModalTaskGridOptions: any;
  currentScrolledPosition: number = 0;
  updatePageLimit: boolean = false;

  is_approver: boolean = false;
  is_pending_for_approval: boolean = false;
  enableFinishTask: boolean = false;
  enableApprove:boolean = false;
  enableApproved: boolean = false;
  taskReviewerName: string = '';
  taskReviewerDate: string = '';
  userRole: object = {};
  disableConceptParserFeedback: boolean;
  is_reviewer = false; 
  isPriorFeedbackOpen: boolean= false;
  projectTrainingTaskGridState = {
    sort: null,
    search: ''
  } 
  recordId;
  
  isFeedbackCaptured:boolean= false;
  project_id: string;
  projectDetailInfo: object = {};
  isClassifyProjectUser: boolean = false;
  isClassifyProjectRanOnce: boolean = false;
  showResultsListModal: boolean = false;
  resultDetail: object = {};
  constructor(
    public zettaUtils: ZettaUtils,
    public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private messageSvc: MessageService,
    private ZsClContentService:ZsClContentService,
    private staticRailService : StatisticService,
    private _router :Router,
    private baseService: BaseService) { }

  ngOnInit() { 
    this.fu_type_classifyProjectTraining = AppGlobals.FU_TYPE_CLASSIFY_PROJECT_TRAINING;
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo'); 
    this.activatedRoute.parent.params.subscribe(params => {
      console.log('PARAMS ',params);
      this.projId = params['id'];
    });
    this.project_id = this.projId; 
    if(this._router.url.includes('fix-issues')) {
      this.fixClIssue = true;
    }
    this.getClassifyProject();
    sessionStorage.removeItem("serverfilter");
    sessionStorage.removeItem("sortfilter");
    sessionStorage.removeItem('resetpage_filter');
    //this.project_type = sessionStorage.getItem("project_type");
    this.getRightClassList();
    this.taskGridColumnsDef = [
      {
        'displayname': '',
        'physicalname': 'actions',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'cssClass': 'text-right',
        'formatter': actionsFormatter,
        'maxWidth': 15,
      },
      {
        'displayname': 'Data set',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'headerCssClass': 'text-left',
        'minWidth': 80,
      }, {
        'displayname': 'ID',
        'physicalname': 'record_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 70,
      }, {
        'displayname': 'Name',
        'physicalname': 'summary',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'args': false,
        'minWidth': 120,
        'cssClass': 'text-left',
        'params': { 'isDisplayDesc': false },
        'formatter': nameFormattter
      }, {
        'displayname': 'Status',
        'physicalname': 'status',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 80,
        'formatter': formatStatus
      }, {
        'displayname': 'Open',
        'physicalname': 'taskname',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'minWidth': 10,
        'cssClass': 'text-left',
        'formatter': graduateHat
      },
      {
        'displayname': 'Last Updated',
        'physicalname': 'updated_ts',
        'sortable': true,
        'datatype': 'DateTime',
        'filterable': true,
        'minWidth': 80,
        'cssClass': 'text-left',
        'formatter': conditionalDateFormatter
      }
    ];

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 50,
      'params': { 'isDisplayDesc': false },
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'reviewer',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': revieweCheckBoxFormattter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }, {
      'displayname': 'Approver',
      'physicalname': 'approver',
      'sortable': true,
      'datatype': 'String',
      'filterable': false,
      'formatter': aprooveerCheckBoxFormattter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }];

    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      rowHeight: 30,
      enableRowSelection:true,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: false,
      CheckboxSelector: false,
      rowHeight: 34
    }

    this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.trainModalGridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };

    this.datasetsTableColDef = [{
      'displayname': 'Classifier Prediction',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormattter,
      'minWidth': 450,
      'maxWidth': 500
    },
    {
      'displayname': 'Confidence Level',
      'physicalname': 'confidence',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': confidenceLevelFormatter,
      'minWidth': 200,
      'maxWidth': 370,
    }
    ];

    this.reviewPriorMappingsDef = [{
      'displayname': 'Classifier Prediction',
      'physicalname': 'class',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormattter,
      'minWidth': 250,
      'maxWidth': 200,
    },
    {
      'displayname': 'Confidence Level',
      'physicalname': 'confidence',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': confidenceLevelFormatter,
      'minWidth': 200,
      'maxWidth': 270,
    }
    ];

    this.subConceptColumn = [
      {
        'displayname': '',
        'physicalname': 'radio',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': radioFormatter,
        'minWidth': 30,
        'maxWidth': 30,
      },
      {
        'displayname': 'Sub-Concept',
        'physicalname': 'characteristic',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': subConceptFormatter,
      }
    ];

   
    this.assignType = 'classifyProject';
    this.taskAssignDetails = {
      title: 'All Users',
      selectImgPath: '../../../../assets/images/common/select-data1.png',
      gridColDef: this.rightGridColumnsDef,
      // homeUrl: '/zs-cl/home',
      alignCardHeight: true,
      diffTaskStatusSelected: false,
      user_id: this.loggedInUserDetails['user_id'],
      updateURL: `users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks`,
      getUserUrl: `users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/users`,
    };

    this.baseService.showAssignUsers.subscribe(toggle => {
      if (toggle) {
        this.viewTaskAssignments = toggle;
      } else {
        this.viewTaskAssignments = false;
      }
    });
  }

  getClassifyProject() {
    this.isTasksLoading = true;
    const data = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      project_id: this.projId
    };
    this.ZsClContentService.getclassifyProject(data).subscribe(responseList => { 
      this.isFourEyeChecked = responseList.four_eye_check;
      this.isClassifyProjectRanOnce = responseList && responseList.ever_executed ? responseList.ever_executed: false;
      this.classify_project_users = responseList.users;
      let projectType = responseList.type;
      this.project_type = responseList.type_name;
      this.classify_project_users.forEach(user => {
        if (user.user_id === this.loggedInUserDetails.user_id) {
          const adminUser = user.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_ADMIN);
          if (adminUser) {

            this.isProjectAdmin = adminUser ? true : false;
            this.showPriorFeedbackRerun = adminUser ? true : false;
          }
          // Refactor later
          this.isClassifyProjectUser = true;
        }
      });
      this.trainModalTaskGridOptions = {
        enableGridMenu: false,
        enableAddRow: false,
        selectable: true,
        enableFiltering: true,
        CheckboxSelector: this.isProjectAdmin,
        enableCellNavigation: true,
        multiSelectable: true,
        rowHeight: 30,
        enableRowSelection: true,
        editable: false,
        autoEdit: false,
      };
      if (projectType === AppGlobals.CONCEPT_PARSER) {
        this.loadLeftTasksDataforParserProject(this.trainModalPage, this.limit);
      } else {
        this.loadLeftTasksData(this.page, this.limit);
      }

      if(projectType == AppGlobals.SEMANTIC_OBJECT_CLASSIFIER){
        this.validateIfSocFeedbackCapturedforProject();
      }
      this.projectDetailInfo = responseList;
    }, err => {
    });
  }

  canShowResultSummary() {
    if (this.isClassifyProjectUser && this.isClassifyProjectRanOnce) {
      return true;
    }
    return false;
  }

  loadRightTasksData() {
    AppGlobals.PROJECT_ADMIN = AppGlobals.CLASSIFY_PROJECT_ADMIN;
    AppGlobals.REVEIWER = AppGlobals.CLASSIFY_PROJECT_REVEIWER;
    AppGlobals.APPROVER = AppGlobals.CLASSIFY_PROJECT_APPROVER;
    this.projectService.getTaskAssignments(this.loggedInUserDetails, this.projId).subscribe((data) => {
      data.forEach(ele => {
        if (ele['roles'] && ele['roles'].length) {
          return ele['roles'].map(role => {
            if (role && role['role_id'] || role.role_name) {
              ele['roles'].push(role.role_id);
              return ele['roles'].shift();
            } else {
              return role;
            }
          })
        }
      });
      this.rightTasksDataSource = data;

      if (this.rightTasksDataSource.length === 0) {
        this.noData = true;
      }
      this.isRightLoading = false;
      let concatedRows: string[] = this.rightTasksDataSource;
      this.rightTasksDataSource.forEach(row => {
        row['id'] = Math.random();
        row.isFourEyeChecked = this.isFourEyeChecked;
        row['isProjectAdminChecked'] = false;
        row['isReviewerChecked'] = false;
        row['isApproverChecked'] = false;
        row.roles.forEach(roles => {
          switch (roles) {
            case AppGlobals.PROJECT_ADMIN:
              row['isProjectAdminChecked'] = false;
              row['selectedRoles'] = row['roles'];
              break;
            case AppGlobals.REVEIWER:
              row['isReviewerChecked'] = false;
              row['isReviewer'] = true;
              row['selectedRoles'] = row['roles'];
              break;
            case AppGlobals.APPROVER:
              row['isApproverChecked'] = false;
              row['isApprover'] = true;
              row['selectedRoles'] = row['roles'];
              break;
            default:
              row['selectedRoles'] = [];
          }
        });
      });
    }, err => {
      // this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
      this.isRightLoading = false;
    });
  }

  onDatasetsGridCreation(grid) {
    this.gridDatasets = grid;
  }

  // On click of View Task Assignments button
  onTaskAssignments() {
    this.viewTaskAssignments = !this.viewTaskAssignments;
    this.baseService.showAssignUsers.next(this.viewTaskAssignments);
  }

  onGridStatusChanged(_projectTrainingTaskGridState) {
    this.projectTrainingTaskGridState.sort = _projectTrainingTaskGridState.sort ? _projectTrainingTaskGridState.sort: null;
  }

  loadLeftTasksData(page, limit) {
    if(this.fixClIssue) {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks?task_type=fixing&pagination=true&pageno=${1}&pagesize=${limit}`;
    } else {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks?pagination=true&pageno=${1}&pagesize=${limit}`;
    }

    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.staticRailService.isStatisticDataChanged.next(true);
    if(this.fixClIssue) {
      this.projectService.getClassIssueFixes(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projId, page, limit, serverFilter, sortfilter)
        .subscribe((data) => {
       
          if(data) {
            this.tasksDataSource = data.currentpage; 
            let concatedRows = this.tasksDataSource;
            if (this.tasksDataSource.length === 0) {
              this.noData = true;
              this.is_import_enable=false;

            }
            this.isTasksLoading = false;
            this.total = data.currentpage.length;
            if (page === 1) {
              this.grandTotal = data.totalrecords;
            }
            if (this.taskGrid) {
              this.tasksDataSource.forEach(element => {
                element.id = element['task_id'];
                // hide checkbox selections for completed tasks
                element.shouldDisable = (element.status == AppGlobals.TASK_COMPLETED || element.status == AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
              });
              concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
              ($('#classification_tag_model') as any).modal('show');
              this.tasksDataSource.forEach(element => {
                element.id = element.task_id;
              });
              concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];

              const unique = [];
              const map = new Map();
              for (const item of concatedRows) {
                if(!map.has(item.task_id)){
                  map.set(item.task_id, true);
                  if(item && item.task_id) {
                    unique.push(
                      item
                    );
                  }
                }
              }
              this.tasksDataSource = [...unique];
              this.taskGrid.dataView.setItems(this.tasksDataSource);
              this.taskGrid.dataView.refresh();
              this.hasScrolled = false;
            }
          }
        }, err => {
          this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
          this.isTasksLoading = false;
        });
    } else {
      let splitby = sortfilter && sortfilter.split('&').length > 0 ? sortfilter.split('&')[1]: null;
      let sort_amp = splitby && splitby.split("=").length > 0 ? splitby.split("=")[1] : null;
      if (sort_amp === 'confidence' || sort_amp === 'class') {
        sortfilter = '';
      }
      let isCustomGridStateHandling = this.projectTrainingTaskGridState.sort && this.projectTrainingTaskGridState.sort != '' ? this.projectTrainingTaskGridState.sort: null;
      this.projectService.getClassifyTasks(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projId, page, limit, serverFilter, sortfilter, isCustomGridStateHandling)
        //  this.ProjectSvc.getClassifyTasks(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId)
        .subscribe((data) => {
          if(data) {
            this.tasksDataSource = data.currentpage;
            if (this.tasksDataSource.length === 0) {
              this.noData = true;
              this.is_import_enable=false;
            }
            this.isTasksLoading = false;
            this.total = data.totalrecords;
            if (page === 1) {
              this.grandTotal = data.totalrecords;
            }
            this.tasksDataSource.forEach(element => {
              element.shouldDisable = element.status == AppGlobals.TASK_COMPLETED ? true : false;
            });
            let concatedRows = this.tasksDataSource;
            if (this.taskGrid) {
              concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
              ($('#classification_tag_model') as any).modal('show');
              this.tasksDataSource.forEach(element => {
                element.id = Math.random();
              });
              const unique = [];
              const map = new Map();
              for (const item of concatedRows) {
                if(!map.has(item.task_id)){
                  map.set(item.task_id, true);
                  if(item && item.task_id) {
                    unique.push(
                      item
                    );
                  }
                }
              }
              this.tasksDataSource = [...unique];
              this.taskGrid.dataView.refresh();
              this.taskGrid.dataView.setItems(this.tasksDataSource);
              if (page < data.totalpages) {
                this.hasScrolled = false;
              }
            }
          }
        }, err => {
          this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
          this.isTasksLoading = false;
        });
    }
  }
  
  loadLeftTasksDataforParserProject(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks?project_type=14863&pagination=true&pageno=${1}&pagesize=${limit}`;

    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    if(this.trainModalPage<2) {
      this.staticRailService.isStatisticDataChanged.next(true);
    }
    let isCustomGridStateHandling = this.projectTrainingTaskGridState.sort && this.projectTrainingTaskGridState.sort != '' ? this.projectTrainingTaskGridState.sort: null;
    this.projectService.getClassifyConceptParserTasks(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projId, page, limit, serverFilter, sortfilter, isCustomGridStateHandling)
      .subscribe((data) => {
        if (data) { 
          this.hasScrolled = false;
          this.tasksDataSource = data.currentpage;
          if (this.tasksDataSource.length === 0) {
            this.noData = true;
            this.is_import_enable=false;
          } else {
            this.noData = false;
          }
          this.isTasksLoading = false;
          this.total = data.totalrecords;
          if (page === 1) {
            this.grandTotal = data.totalrecords;
          }
          this.tasksDataSource.forEach(element => {
            element.shouldDisable = element.status == AppGlobals.TASK_COMPLETED ? true : false;
          });
          let concatedRows = this.tasksDataSource;
          if (this.taskGrid) {
            this.tasksDataSource.forEach(element => {
              element.id = Math.random();
            });
            concatedRows = this.tasksDataSource;
            concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
            this.tasksDataSource = concatedRows;
            this.taskGrid.dataView.setItems(concatedRows);
            this.taskGrid.gridService.setSelectedRows([]);
            this.taskGrid.dataView.refresh();
          }
        }
      }, err => {
        this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
        this.isTasksLoading = false;
      });
  }

  onDatasetCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    this.updatePageLimit = true;
    if (row !== undefined && row !== null) {
      this.showmodelpopup = true;
      this.dsName = row.class;
      if (eventEle.target.className.trim() == 'fal fa-thumbs-down handDown' || eventEle.target.className.trim() == 'fal handDown fa-thumbs-down' || eventEle.target.className.trim() == 'handDown fal fa-thumbs-down' || eventEle.target.className.trim() == 'fal fa-thumbs-down') {
        this.showDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;
        let dislikeData = {
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 0,
          task_id: this.taskId,
          class: row.class,
          dataset_id: this.datasetId
        };
        this.classInfo = dislikeData;
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          projId: this.projId,
          payload: dislikeData
        };
        this.rowData = args.row;
        this.classes = 'fal fa-thumbs-down';
      } else if (eventEle.target.className.trim() === 'fal fa-thumbs-up') {
        const upVotedata = {
          dataset_id: this.datasetId,
          score: row.score100,
          id: row.newId,
          name: row.name,
          confidence: row.confidence,
          vote: 1,
          task_id: this.taskId,
          class: row.class
        };
        const payload = {
          user_id: this.loggedInUserDetails['user_id'],
          tenant_id: this.loggedInUserDetails['tenant_id'],
          projId: this.projId,
          payload: upVotedata
        };
        this.projectService.updateTaskFeedback(payload, 'classifySOCTYPE').subscribe(resp => {
          $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
          $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
          $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
          $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          if (row.vote === 0) {
            $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
            $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
          }
          this.revokeUpvoteAccessForOtherRecordSOCProjectFeedback(args.row);

          this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, this.taskId)
          .subscribe(res => {
            if(resp) {
                this.checkFinishTaskBtnValidation(res['task_outputs']);
              }
            });
          
          this.tasksDataSource = [];
          if (this.taskGrid) {
            this.taskGrid.dataView.setItems(this.tasksDataSource);
          }
          this.limit = this.page * 100; // limit made dynamic to support auto-scroll to previous selected item while giving train-modal feedback
          this.loadLeftTasksData(1, this.limit);
        }, error => {
          this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
      }
    }
  }

  /**
   * 
   * Revoking upvote access for non-selected or previously selected as we only allow 1 upvote
   *  for SOC project feedback per task. 
   */
  revokeUpvoteAccessForOtherRecordSOCProjectFeedback(upvotedRow) {
    let totalRecords = this.tasksDataSource.length;
    for(let row =0; row < totalRecords; row++) {
      if (row != upvotedRow) {
        $('#thumbs-up-' + row).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + row).addClass('fal fa-thumbs-up');
      } 
    }
  }
  onClosePriorFeedback(){ 
    this.isPriorFeedbackOpen = false;
  }

  onClose(){ 

    this.datasets=[];
    if(this.gridDatasets){
      this.gridDatasets.dataView.setItems(this.datasets);
      this.gridDatasets.filterService.clearFilters();
    }
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    if(this.updatePageLimit) {
      setTimeout(() => {
        $(".slick-viewport-left")[0].scrollTo({top: this.currentScrolledPosition,left: 0,behavior: "smooth"});
        this.updatePageLimit = false;
      }, 1500);
    }
    this.is_pending_for_approval = false;
    this.is_approver = false;
    this.enableFinishTask = false;
    this.enableApprove = false;
    this.enableApproved = false;
    this.showResultsListModal = false;
  }
  closeModalPopup(event){
    if(event){
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      this.tasksDataSource=[];
      if(this.taskGrid){
        this.taskGrid.dataView.setItems(this.tasksDataSource);
      }
      this.page=1;
      this.loadLeftTasksData(this.page, this.limit);

    }
    this.showDownvoteModal = false
  }
  closeFeedbackModel(event) {
    if (event) {
      this.tasksDataSource = [];
      if (this.taskGrid) {
        this.taskGrid.dataView.setItems(this.tasksDataSource);
      }
      this.page = 1;
      this.trainModalPage = 1;
      this.loadLeftTasksDataforParserProject(this.trainModalPage, this.limit);
    }
    this.showDownvoteTrainModal = false;
  }

  closeModal() {
    $('#datasets_modal').removeClass('datasets_modal');
  }

  popupoutput(message){
    this.showmodelpopup = message;
  }

  getCompletedTask(completed_task) {
    if (completed_task == 100) {
      this.show_run_model = true;
    }
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  taskGridReady(grid) {
    const parent = this;
    parent.taskGrid = grid;
    parent.taskGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedProjects = [];
      args.rows.forEach(ind => {
        const row = parent.taskGrid.gridService.getDataItemByRowIndex(ind);
        parent.selectedProjects.push(row);
      });
      parent.selectedRow = parent.taskGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.baseService.showAssignUsers.next(false);
      parent.viewTaskAssignments = false;
      parent.checkForTaskTypes();
      parent.taskAssignDetails['gridColDef'] = parent.rightGridColumnsDef;
      parent.btnModifyDisabled = args.rows.length > 0 ? false : true;
    });

  }

  taskRightGridReady(grid) {
    this.rightTaskGrid = grid;
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadLeftTasksData(this.page, this.limit);
  }

  onNext(): void {
    if ( this.project_type === 'Concept Parser' ) {
      this.trainModalPage++;
      this.loadLeftTasksDataforParserProject(this.trainModalPage, this.limit);
    } else {
      this.page++;
      this.loadLeftTasksData(this.page, this.limit);
    }
  }

  onPrev(): void {
    if ( this.project_type === 'Concept Parser' ) {
      this.trainModalPage--;
      this.loadLeftTasksDataforParserProject(this.trainModalPage, this.limit);
    } else {
      this.page--;
      this.loadLeftTasksData(this.page, this.limit);
    }
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      this.selected_record_id =row.record_id;
      this.selected_task_id = row.task_id;
      this.datasetId=row.dataset_id;
      this.is_task_completed = true;
      this.recordId = encodeURIComponent(this.selected_record_id);
      if($(eventEle.target).hasClass('fa-graduation-cap')) {
        this.is_task_completed = false;
        let userDetails = this.classify_project_users.find(user => user.user_id === this.loggedInUserDetails.user_id);
        const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        if (row.status === AppGlobals.TASK_COMPLETED) {
          this.is_task_completed = true;
        }
        if (isReviewdUser && row.status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.is_task_completed = true;
        }
        
        if(row.status == AppGlobals.TASK_PENDING_APPROVAL || row.status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          this.is_pending_for_approval = true;
        } else {
          this.is_pending_for_approval = false;
        }
        
        if(this.project_type.toLowerCase() === 'Concept Parser'.toLowerCase()){
          this.showDownvoteTrainModal=true;
          this.selectedColumn=row.summary;
          this.getPridictionList(row.status,row.assigned_to);
        }else{
          $('#datasets_modal').modal('show');
        }
        this.getTaskDetails(row.task_id);
        this.checkUserRoleDetails();
        this.taskTypeId = row.task_type_id;
        if (eventEle.target.title === row.name) {
        } else if (eventEle.target.title === row.taskname || eventEle.target.title === row.task_type) {
          this.selectedClusterId = row.cluster_id;
          this.selectedTaskType = row.task_type;
          this.selectedTaskName = row.taskname;
          this.projectDetail.project_id = row.project_id;
          this.taskType = row.task_type;
          this.selectedTaskStatus = row.task_status;
          this.selectedRowDataSource = row;
        }
        //  $('#datasets_modal').modal('show');
      } else {
        return;
      }
    }

  }

  onRightTaskGridCellClick(e): void {
    const args = e.args;
    const row = this.rightTaskGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.rightTaskGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.rightTaskGrid.dataView.getItems();
    row['selectedRoleID'] = null;

    if (row !== undefined && row !== null) {
      if (fieldName === 'isReviewerChecked') {
        row['selectedRoleID'] = AppGlobals.REVEIWER;
        row['isReviewerChecked'] = !row['isReviewerChecked'];
        if (row['isReviewerChecked']) {
          row['selectedRoles'].push(AppGlobals.REVEIWER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
        }
      } else if (fieldName === 'isApproverChecked') {
        row['selectedRoleID'] = AppGlobals.APPROVER;
        row['isApproverChecked'] = !row['isApproverChecked'];
        if (row['isApproverChecked']) {
          row['selectedRoles'].push(AppGlobals.APPROVER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
        }
      }
      this.rightTaskGrid.gridService.updateDataGridItem(row, false);
      this.setStatusProperty();
      this.validationChecking(dataSet, row);
    }
  }

  getTaskDetails(id, page?, limit?) {
    this.taskId = id;
    this.datasets = [];
    this.trainModalDatasetSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks/${id}?pagination=true&pageno=${1}&pagesize=${this.limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    if(serverFilter == 'confidence' || serverFilter == 'score' || serverFilter == 'class' || sortfilter.includes('orderby=score') || sortfilter.includes('orderby=confidence') || sortfilter.includes('orderby=class')) {
      serverFilter = serverFilter;
      sortfilter = sortfilter;
    } else {
      serverFilter = '';
      sortfilter = '';
    }
    this.isLoadingDatasets = true;
    this.projectService.getTaskDetails(this.loggedInUserDetails.user_id,
      this.loggedInUserDetails.tenant_id,this.projId, this.taskId, this.taskDetailsPage, this.taskDetailsLimit, serverFilter, sortfilter)
      .subscribe(res => {
        if(res) {
          this.getTaskReviewer();
          this.checkForTaskStatus(res);
          this.checkFinishTaskBtnValidation(res['task_outputs']);
          this.datasets = res['task_outputs'];
          this.taskName = res['summary'];
          this.selected_record_id = res['record_id'];
          this.datasets.forEach(element => {
            element.newId = element.id;
            element.datasource_name = element.name;
            element.score100 = (element.score * 100).toFixed();
            element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
            element.shouldDisableFeedback = this.is_task_completed;
            element.id = Math.random();
          });
          if(this.is_task_completed) {
            this.enableFinishTask = false;
            this.enableApprove = false;
          }
          this.datasets.sort(((a, b) => b.score100 - a.score100));
          this.totalDatasets = this.datasets.length;
          this.isLoadingDatasets = false;
          let concatedRows = this.datasets;

          let self = this;
          setTimeout(function() {
            if (self.gridDatasets) {
              concatedRows = [...self.gridDatasets.dataView.getItems(), ...self.datasets];
              self.datasets = concatedRows;
              self.gridDatasets.dataView.setItems(self.datasets);
              self.gridDatasets.gridService.setSelectedRows([]);
              self.hasDatasetsScrolled = false;
            }
            if (self.datasets.length === 0) {
              self.noData = true;
            }
          }, 100)
          this.checkFinishTaskBtnValidation(this.datasets);
          
        }
      });
  }

  validateIfSocFeedbackCapturedforProject(){
    if(this.loggedInUserDetails && this.loggedInUserDetails['user_id'] && this.loggedInUserDetails['tenant_id']){
      let self=this;
      this.projectService.validateIfClassifySocProjectFeedbackCaptured(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projId, AppGlobals.SEMANTIC_OBJECT_CLASSIFIER)
      .subscribe(feedbackStatus=>{
        if(feedbackStatus){ 
          self.isFeedbackCaptured = feedbackStatus.feedbackCaptured;
        }
        else{
          self.isFeedbackCaptured = false;
        }
        
      },error=>{
          self.isFeedbackCaptured = false;
      })
    }
  }

  onScroll(parentClass, eve) {
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    this.currentScrolledPosition = (eve && eve.args.scrollTop) ? eve.args.scrollTop : 0;
    if (this.zettaUtils.virtualScroll(parentClass) &&!this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onRightScroll(parentClass) {
  }

  onRunModel(){ 
    const payload ={
      classify_project_id:this.projId,
      classify_picker:true,
      supervised: true
    };

    this.ZsClContentService.runModelClassifyProject(payload).subscribe(resp =>{
      if (resp) {
        this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.projId}`});
      }
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }


  downVoted(event) {
    if(event) {
      if(this.classes === 'fas fa-thumbs-down') {
        $('#thumbs-down-' + this.rowData).removeClass('fas fa-thumbs-down');
        $('#thumbs-down-' + this.rowData).addClass('fal fa-thumbs-down');
      } else {
        // $('#thumbs-down-' + this.rowData.args.row).removeClass('fal fa-thumbs-down');
        // $('#thumbs-down-' + this.rowData.args.row).addClass('fas fa-thumbs-down');
        $('#thumbs-up-' + this.rowData).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + this.rowData).addClass('fal fa-thumbs-up');
      }
      this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, this.taskId)
      .subscribe(res => {
          if(res) {
            this.checkFinishTaskBtnValidation(res['task_outputs']);
          }
        });
      // this.loadLeftTasksData(this.page, this.limit);


      //  if(this.classes === 'fas fa-thumbs-down') {
      //     $('#thumbs-down-' + this.rowData.args.row).removeClass('fas fa-thumbs-down');
      //     $('#thumbs-down-' + this.rowData.args.row).addClass('fal fa-thumbs-down');
      //  } else if(this.classes = 'fal fa-thumbs-down') {
      //     // $('#thumbs-down-' + this.rowData.args.row).removeClass('fal fa-thumbs-down');
      //     // $('#thumbs-down-' + this.rowData.args.row).addClass('fas fa-thumbs-down');
      //     $('#thumbs-up-' + this.rowData.args.row).removeClass('fas fa-thumbs-up');
      //     $('#thumbs-up-' + this.rowData.args.row).addClass('fal fa-thumbs-up');
      //  }
}
  }
  

  downloadTasks() {
    // window.open(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks/export`,'_self');
    this.ZsClContentService.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projId}/tasks/export`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `classify_training_tasks_${this.projId}`);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

  receiveFileName($event) {
    this.dataRes = $event
    this.is_upload_complete = this.dataRes.is_upload_complete;
    if(this.is_upload_complete){
      this.tasksDataSource=[];
      if(this.taskGrid){
        this.taskGrid.dataView.setItems(this.tasksDataSource);
      }
      this.page=1;
      this.loadLeftTasksData(this.page, this.limit);
    }
  }
  cancelUpload() {
    this.clearModal =  Math.random();
    $('#uploadFileSelectFileModal').modal('hide');
  }

  onSubConceptCreation(grid) {
    this.subConceptGrid = grid;
  }
  onSubConceptCellClick(e){
    const args = e.args;
    const row = this.subConceptGrid.gridService.getDataItemByRowIndex(args.row);
    $("#data-" + args.row).prop("checked", true);
    this.selectedSubConcept = row.characteristic;
    this.selectedSubConceptRow=args.row;
  }
  onApplySubConcept(){
    this.subConceptList=[];
    this.selectedPridiction.forEach(element => {
      this.subConceptList.push(
        {
          sub_concept: this.selectedSubConcept,
          prediction:element,
        }
      )
    });
    this.saveFeedback();
  }

  showSubConceptModal(event){
    if(event.isSubConcept){
      this.isSubConceptLoading=true
      this.isSubConcept=event.isSubConcept;
      this.selectedPridiction = event.data;
      this.GetCharacteristicData(this.classifier);
    }
  }
  getPridictionList(taskStatus,taskAssignedTo) {
    this.ZsClContentService.getPredictionList(this.projId,this.selected_record_id).subscribe(resp => {
      this.classifier=resp['classifier'];
      //this.GetCharacteristicData(resp['classifier']);
      ($('#classification_tag_model') as any).modal('show');
    }, error => {
      ($('#classification_tag_model') as any).modal('show');
      this.classifier="";
    });
    this.disableConceptParserFeedback = this.canDisableConceptParserFeedback(taskStatus, taskAssignedTo)
  }

  GetCharacteristicData(classifier){
    this.ZsClContentService.getCharacteristicList(this.projId,classifier).subscribe(resp =>{
      this.subConceptResult= resp;
      this.isSubConceptLoading = false;
      if (this.subConceptGrid) {
        this.subConceptResult.forEach(element => {
          element.id = Math.random();
        });
        this.subConceptGrid.dataView.setItems(this.subConceptResult);
      }
    },error=>{
      this.isSubConceptLoading = false;
    });
  }

  saveFeedback() { 
    const payloadList = [];
    let payloadData;
    if (this.subConceptList.length) {
      this.subConceptList.forEach(element => {
        payloadList.push({
          "id": this.selected_record_id,
          "vote": 1,
          "sub_concept": element.sub_concept,
          "prediction": element.prediction
        })
      });
    }
    if(payloadList.length){
      // this.ZsClContentService.saveSelectedFeedBack(payloadList, this.projId, true).subscribe(resp => {
      // if (resp) {  
      this.baseService.addSubConcept.next({isAddConcept:true,data:this.subConceptList});
      this.feedbackAdded=true;
      this.closeSubConcept();
      // } else {
      //this.disliked.emit(false);
      // }
      // }, error => {  $('#subConceptModal').modal('hide');  });
    }


    //  $('#classification_tag_model').modal('hide');
    $('#divBackdrop').addClass('d-none');

  }
  closeSubConcept() {
    this.selectedSubConcept="";
    $('#subConceptModal').modal('hide');
    $("#data-" +this.selectedSubConceptRow).prop("checked", false);
    this.selectedSubConceptRow=undefined;
    this.subConceptResult = [];
    if (this.subConceptGrid) {
      this.subConceptGrid.dataView.setItems(this.subConceptResult);
    }
  }

  onConceptParseRunModel() {
    const payload ={
      classify_project_id:this.projId,
      classify_picker:true,
      supervised: true
    };
    this.ZsClContentService.runConceptParserProject(payload)
      .subscribe(resp =>{
        this._router.navigate(['/zs-cl/projects', this.projId]);
      },error=>{
      });
  }

  onDeletePrediction(data){
    this.msgHeader= data.messageHeader;
    this.msgText= data.messageText;
    this.showConfirmBox =true;
  }

  onConfirmDelete(event){
    this.hasDeleted = event;
  }
  clearText(event){
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader= "";
    this.msgText= "";
  }

  onCancel() {
    this._router.navigate(['/zs-cl/projects/', this.projId ],{ queryParamsHandling: 'merge' });
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
  }
  addUserRole() {
    this.rightTasksDataSource = this.rightTaskGrid.dataView.getItems();
    const selectedProjectApproverList = this.rightTasksDataSource.filter(item => item.isApproverChecked === true);
    const selectedProjectReviewerList = this.rightTasksDataSource.filter(item => item.isReviewerChecked === true);
    if (selectedProjectApproverList.length !== 0 ||
      selectedProjectReviewerList.length !== 0) {
      this.saveUserRole();
    }
  }

  saveUserRole() {
    const parent = this;
    const leftGridDataService = parent.taskGrid.gridService;
    const leftSideGridDataSet = parent.taskGrid.slickGrid.getSelectedRows();

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        parent.rightTasksDataSource.map(resp => {
          if (resp.isApproverChecked === true || resp.isReviewerChecked === true) {
            parent.selectedUserRoles.push({ user_id: resp.user_id, role_id: resp.selectedRoleID, cluster_id: row.cluster_id });
          }
        });
      }
    });

    this.projectService.updateTaskToUsers(this.loggedInUserDetails, this.projId,
      this.selectedUserRoles).subscribe(resp => {
        this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
        if (this.taskGrid) {
          this.tasksDataSource = [];
          this.taskGrid.dataView.setItems(this.tasksDataSource);
          this.isLoading = true;
          if ( this.project_type === 'Concept Parser' ) {
            this.loadLeftTasksDataforParserProject(1, this.limit);
          } else {
            this.loadLeftTasksData(1, this.limit);
          }
        }
      }, err => {
        this.selectedUserRoles = [];
        this.messageSvc.sendMessage({ message: 'Users save user role has failed', type: 'INFO', hideboard: true });
      });
  }
  validationChecking(dataSet, row) {
    this.errorMsg = '';
    this.enableApplyBtn = false;
    const reviewsList = dataSet.filter(item => item.isReviewerChecked === true);
    const approverList = dataSet.filter(item => item.isApproverChecked === true);

    if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size === 0
      && this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
      row.isReviewerChecked = false;
    } else if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size >= 0
      && this.statusObj.inreview_size > 0 && row.isReviewerChecked === true) {
      row.isReviewerChecked = (reviewsList.length === 1) ? true : false;
      if (row.isReviewerChecked) {
        this.errorMsg = `${row.user_name} will be set as the Reviewer only for the
            ${this.statusObj.inreview_size} tasks that are in Review status, out of ${this.statusObj.totalSelection} total tasks selected.`;
      } else {
        this.errorMsg = 'Only one Reviewer is allowed.';
      }
    } else if (this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
    } else if (reviewsList.length > 1) {
      this.errorMsg = 'Only one Reviewer is allowed.';
      row.isReviewerChecked = false;
    } else if (approverList.length > 1) {
      this.errorMsg = 'Only one Approver is allowed.';
      row.isApproverChecked = false;
    } else {
      this.enableApplyBtn = true;
    }

    if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.REVEIWER;
    } else if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.APPROVER;
    }
    this.rightTaskGrid.gridService.updateDataGridItem(row, false);
  }

  setStatusProperty() {
    this.statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
    const leftGridDataService = this.taskGrid.gridService;
    const leftSideGridDataSet = this.taskGrid.slickGrid.getSelectedRows();
    const rows: any[] = [];

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
      }
    });
    const completedList = rows.filter(item => item.status === AppGlobals.TASK_COMPLETED);
    const reviewsList = rows.filter(item => item.status === AppGlobals.TASK_IN_REVIEW);
    const approveList = rows.filter(item => item.status === AppGlobals.TASK_PENDING_APPROVAL);
    this.statusObj = {
      'completed_size': completedList.length, 'inreview_size': reviewsList.length,
      'pendingapproval_size': approveList.length, 'totalSelection': rows.length
    };
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }
 
  openPriorFeedbackTaskModal(modal) {   
    
    $('#' + modal).modal('show');
    this.isPriorFeedbackOpen = true;
  }

  checkForTaskTypes() {
    let reviewerTasks = this.selectedProjects.every(task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW);
    let approverTasks = this.selectedProjects.every(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));
    let inReviewTasks = this.selectedProjects.some( task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW)
    let inPendingTasks = this.selectedProjects.some(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));

    if (reviewerTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = disabledCheckBoxFormatter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(approverTasks) {
      this.rightGridColumnsDef[3]['formatter'] = disabledCheckBoxFormatter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(inReviewTasks && inPendingTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = true;
    }
  }

  onReloadScreen(event) {
    if (event) {
      this.projectTasksData = [];
      this.viewTaskAssignments = false;
      if (this.taskGrid) {
        this.tasksDataSource = [];
        this.taskGrid.dataView.setItems(this.tasksDataSource);
        this.taskGrid.dataView.refresh();
        this.taskGrid.dataView.setItems(this.projectTasksData);
        this.taskGrid.gridService.setSelectedRows([]);
      }
      if ( this.project_type === AppGlobals.CONCEPT_PARSER_TASK_TYPE ) {
        this.trainModalPage = 1;
        this.loadLeftTasksDataforParserProject(this.trainModalPage, this.limit);
      } else {
        this.page = 1;
        this.loadLeftTasksData(this.page, this.limit);
      }
    }
  }

checkFinishTaskBtnValidation(tasks) {
if(tasks && tasks.length > 0 && !this.is_task_completed) {
      let at_least_one_vote = tasks.filter(task => task.vote == 1 || task.vote == 0);
      if(this.is_approver && this.is_pending_for_approval) {
        this.enableApprove = at_least_one_vote.length > 0 ? true : false;
      } else {
        this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
      }
    }
  }

getTaskReviewer(){
  this.ZsClContentService.taskReviewer(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.taskId, this.taskTypeId)
  .subscribe(resp => {
    if (resp.length) {
      this.taskReviewerName = resp[0].first_name;
      this.taskReviewerDate = resp[0].created_ts;
    } else {
      this.taskReviewerName = resp.name;
      this.taskReviewerDate = resp.date;
    } 
  }, error=>{
    this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
  });
}

checkForTaskStatus(taskData) {
  if(taskData && taskData.task_status == AppGlobals.TASK_PENDING_APPROVAL || taskData.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
    this.is_pending_for_approval = true;
    } else {
      this.is_pending_for_approval = false;
    }
}

checkUserRoleDetails() {
  this.userRole = this.projectService.getObjectFromSession('userRole');
  if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_APPROVER) {
    this.is_approver = true;
  }
  if(this.userRole['role_id'] === AppGlobals.CLASSIFY_PROJECT_REVEIWER) {
    this.is_reviewer = true;
  }
}

approveBtn() {
  const data = {
    "user_id": this.loggedInUserDetails.user_id, 
    "tenant_id": this.loggedInUserDetails.tenant_id,
    "projId": this.projId,
    payload: {
      "task_id": this.taskId
    }
  };
  this.ZsClContentService.approveClassIfySocTaskFeedback(data)
  .subscribe(res => {
    if(res) {
      this.enableApprove = false;
      this.enableApproved = true;
      this.enableFinishTask = true;
    }
  });
}

finishTasks() {
  const data = {
    "user_id": this.loggedInUserDetails.user_id, 
    "tenant_id": this.loggedInUserDetails.tenant_id,
    "projId": this.projId,
    payload: {
      "task_id": this.taskId,
      "dataset_id": this.datasetId
    }
  };
  this.ZsClContentService.updateClassIfySocTaskFeedback(data)
  .subscribe(resp => {
    this.messageSvc.sendMessage({ message: resp.message, type: 'SUCCESS', hideboard: true });
    this.tasksDataSource=[];
    if(this.taskGrid){
      this.taskGrid.dataView.setItems(this.tasksDataSource);
    }
    this.page=1;
    this.loadLeftTasksData(this.page, this.limit);
    this.onClose();
    this.is_pending_for_approval = false;
  });
}

canDisableConceptParserFeedback(taskStatus, assignedTo) {
  let loggedInUserDetails = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;

  if (taskStatus == AppGlobals.TASK_COMPLETED) {
    return true;
  }

  if (taskStatus === AppGlobals.TASK_REVIEWED_STATUS) {
    return true;
  }
  
  if (taskStatus === AppGlobals.TASK_IN_REVIEW) {
    return false;
  }

  if (taskStatus === AppGlobals.TASK_PENDING_FOR_APPROVAL || taskStatus === AppGlobals.TASK_PENDING_APPROVAL) {
    if (loggedInUserDetails && loggedInUserDetails.user_id && loggedInUserDetails.user_id === assignedTo) {
      return false;
    }
    return true;
  }
  return false;
}

hidePriorModal(eve) {
  if(eve) {
    this.showReviewPrior = false;
  }
}

showResultSummaryModal() {
  this.showSummaryModal = true;
}
hideConfigModal(eve) {
  this.showSummaryModal = eve;
}

getRightClassList() {
  this.ZsClContentService.getClassifierClassList(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projId)
  .subscribe(classes => {
    if(classes) {
    this.modalClasses= classes;
    }
  }, err => {
  });
  }

  triggerClassifyReslutsModal() {
    this.showResultsListModal = true;
  }

  hideClassifyResultsModal(eve) {
    this.showResultsListModal = eve;
  }

}
