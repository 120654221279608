// actions-cell-renderer.component.ts

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-actions-cell-renderer',
  template: `
  <span *ngIf="value" class="text-right pointer context-menu" [ngClass]="{'disabled-state':!active}"><i class="fal fa-ellipsis-v fa-2x" aria-hidden="true" (click)="onActionClick()"></i></span>
  `,
})
export class ActionsCellRendererComponent implements OnInit, ICellRendererAngularComp {
    active = false;
    value = undefined;
    params: any;
    // html = `<div [ngClass]="{'disabled-state':!active}" class="text-truncate"><label title="{{value}}" class="blueLink text-truncate w-100">{{value}}</label></div>`;

    agInit(params: ICellRendererParams): void {
        this.value = params.data ? true : false;
        this.params = params.data;
     if(params.data.entitlements.menu.clone && params.data.entitlements.menu.delete && params.data.entitlements.menu.edit){
      this.active = true;
    }
    else{
      this.active = false ;
    }
    }
    refresh(params: ICellRendererParams): boolean {
        return false;
      }
    
      constructor() { }
    
      ngOnInit(): void {
      }
    
      onClick() {
        this.params.context.componentParent.updateRow(this.params.rowIndex);
      }
      onActionClick() {
    
      }
}
