

import { Column , Formatter } from 'angular-slickgrid';


export const attributeMappingFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
value !== undefined ? `<div class="row">
                            <div class="col-md-10 pr-0 text-truncate ">
                                <label title="${value}">
                                    <span>${value}</span>
                                </label>
                            </div>
                            <div class="col-md-1"></div>
                        </div>`
                    : ``;
