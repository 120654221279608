import { User } from '../../../../../common/shared/zetta-models';
import { ObservableService } from '../../../../../common/services/observable.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { Project } from 'src/app/zettasense/content/project/project.model';
import { ZsClContentService } from '../../../zs-cl-content.service';
//import { Project } from '../../../classify.model';
//import { ProjectService } from '../../project.service';
//import { Project } from '../../project.model';

@Component({
  selector: 'zetta-zscl-pd-users',
  templateUrl: './zs-cl-users.component.html',
  styleUrls: ['./zs-cl-users.component.scss']
})
export class ZsClPdUsersComponent implements OnInit {

  projectDetail = new Project();
  totalUsers = 0;
  activeUsers: User[] = [];
  private _project_entitlements = {};
  private _userinfo;
  project_type: String = '';
  ever_executed = false;
  @Input() projectAdmin 

  constructor(public zettaUtility: ZettaUtils,
    private _projectService: ZsClContentService,
    public observeSvc: ObservableService,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this._userinfo = this._projectService.getObjectFromSession('userInfo');
    this.activeRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.observeSvc.getCurrentEntitlements.subscribe(data => { this._project_entitlements = data ? data : {}; });

    this.getProject();
    this.project_type = sessionStorage.getItem('project_type');
  }

  getProject() {
    const data ={
      user_id: this._userinfo.user_id,
      tenant_id:this._userinfo.tenant_id,
      project_id: this.projectDetail.project_id
    };
    this._projectService.getclassifyProject(data).subscribe(responseList => {
        this.projectDetail = responseList;
        this.ever_executed = responseList.ever_executed;
        if (this.projectDetail.users) {
          this.totalUsers = this.projectDetail.users.length;
          this.activeUsers = this.projectDetail.active_users.splice(0, 6);
        }
      }, err => {
        this.projectDetail.created_ts = new Date();
        this.projectDetail.users = [];
      });

  }

  isHavingAccess() {
    if (this.zettaUtility.isProcessRunning && !this.zettaUtility.isFileNotFound) {
      return true;
    } else {
      return (this._project_entitlements['canUpdate'] && this._project_entitlements['canView']) ? false : true;
    }
  }


  getProfileImageClass(index: number, total: number) {
    if (total === 1) {
      return 'col-md-12 text-center';
    } else if ([0, 1, 2, 3].indexOf(index) !== -1) {
      return 'col-md-6 mb-2';
    } else if ([2, 4].indexOf(index) !== -1 && [3, 5].indexOf(total) !== -1) {
      return 'col-md-12';
    } else if ([4, 5].indexOf(index) !== -1) {
      return 'col-md-6';
    }
  }

  toAddUsers() {
    this.router.navigate(['../../add-users'], {relativeTo : this.activeRoute, queryParams: {showBreadcrumb: true, ever_executed: this.ever_executed, class: null }, queryParamsHandling: 'merge' });
  }
}
