import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { UserEntilementCheckboxRendererComponent } from 'src/app/common/shared/cell-renderer/user-entitlements-checkbox-formatter/user-entitlements-checkbox-formatter.component';
import { AdminCheckboxRendererComponent } from 'src/app/common/shared/cell-renderer/user-entitlements-admin-checkbox-formatter/admin-checkbox-formatter.component';
import { ExecutorCheckboxRendererComponent } from 'src/app/common/shared/cell-renderer/user-entitlements-admin-checkbox-formatter/executor-checkbox-renderer.component';
import { NullCellRendererComponent } from 'src/app/common/shared/cell-renderer/null-formatter/null-formatter.component';

@Component({
  selector: 'zetta-business-user-entitlements',
  templateUrl: './business-user-entitlements.component.html',
  styleUrls: ['./business-user-entitlements.component.scss']
})
export class BusinessUserEntitlementsComponent implements OnInit {
  userGrid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  userGridDataSource: any = [];
  gridColumnsDef: any;
  usersColumnsDef: any;
  tableSettings: object = { 'height': "100%", 'width': "99.40%" };
  gridOptions: any;
  loggedInUserDetails;
  noData = false;
  isDataReady = false;
  totalUsers: number;
  rule_id;
  isEditView;
  isRuleEdittable;
  rightGridOptions: any;
  @Input() ruleName;
  @Input() ruleNameSelected;
  rule: any = {};
  viewMode;
  selectedRules: any = [];
  columnApi: any;
  gridApi:any;
  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;

  constructor(public ZsClContentService: ZsClContentService, private activatedRoute: ActivatedRoute, private router: Router) {

   }

  ngOnInit() {
    const mode = sessionStorage.getItem('viewMode');
    if(!mode) {
      this.viewMode = 'business'
    } else {
      this.viewMode = mode;
    } 
    this.loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    this.activatedRoute.parent.queryParamMap.subscribe(params => {
      this.rule_id = +params.get('rule_id');
      this.selectedRules.push(this.rule_id); 
      this.ZsClContentService.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id).subscribe((resp) => {
        this.rule = resp;
      }, err => { });
    });

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      rowHeight: 30,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      rowHeight: 30,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: false,
    };

    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.initColDef();
    this.editUserDef();
    this.getUsersList();
    this.ZsClContentService.isEditView.subscribe(resp  => {
      this.isEditView = resp;
     });
    this.ZsClContentService.isRuleEdittable.subscribe(res => {
      this.isRuleEdittable = res;
    })

  }

  getUsersList() {
    this.ZsClContentService.getUsers(this.rule_id).subscribe((responseList) => {
      this.userGridDataSource = responseList;
      if (this.userGridDataSource.length === 0) {
        this.noData = true;
      }      
      this.totalUsers = this.userGridDataSource.length;
      this.isDataReady = true;
      let concatedRows = this.userGridDataSource;
      if (this.userGrid) {
        concatedRows = [...this.userGrid.dataView.getItems(), ...this.userGridDataSource];
        this.userGridDataSource = this.userGridDataSource.map((item) => {
          item['id'] = Math.random();
          return item;
        });
        this.userGridDataSource = concatedRows;
        this.userGrid.dataView.setItems(concatedRows);
      }
    }, err => {
    });
  }

  initColDef() {
    this.gridColumnsDef = [{
      headerName: 'User Name',
      field: 'user_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,  
      minWidth: 100,
      tooltipField:'user_name',
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,  
      minWidth: 100,
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      tooltipField:'first_name'
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,  
      minWidth: 100,
      cellRendererFramework: NullCellRendererComponent,
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      tooltipField : 'last_name'
    },
    {
      headerName: 'Rule Admin',
      field: 'role_name',
      minWidth: 100,
      cellClass: 'custom-class-name',
      cellRendererFramework: UserEntilementCheckboxRendererComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      tooltipField:'role_name'
    },
    {
      headerName: 'Rule Executor',
      field: 'role_name',
    
      minWidth: 100,
      cellRendererFramework: UserEntilementCheckboxRendererComponent,    
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      tooltipField:'role_name'
    },
  ]
  }

  editUserDef() {
    this.usersColumnsDef = [{
      headerName: 'User Name',
      field: 'user_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,  
      minWidth: 100,
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,  
      minWidth: 100,
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortable: true,
      minWidth: 100,  
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
    {
      headerName: 'Rule Admin',
      field: 'role_id',
      minWidth: 100,  
      cellRendererFramework: AdminCheckboxRendererComponent, 
      cellClass: 'custom-class-name',
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
    {
      headerName: 'Rule Executor',
      field: 'role_name',
      minWidth: 100,
      cellClass: 'custom-class-name',
      cellRendererFramework: ExecutorCheckboxRendererComponent, 
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
    },
   ]
  }

  gridCreated(grid) {
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
  }

  rightGridCreated(grid) {
    const parent = this;
    parent.agGrid = grid;
    this.columnApi = grid.columnApi;  
  }

  onRightGridCellClick(e): void {
    const eventEle = e.eventData;
    const rowNode = e.node;
    const args = e.args;
    const row = e.data
    const fieldName = e.colDef.field;
    if(this.isRuleEdittable) {
      if (row !== undefined && row !== null) {
        if (fieldName === 'role_id') {
          row['role_id'] = 39;
        } else if(fieldName === 'role_name') {
          row['role_id'] = 40;
        }
        let payload = {
          data_quality_rule_id: this.rule_id,
          user_id: row.user_id,
          role_id: row.role_id
        };
        this.ZsClContentService.updateDqUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], payload)
        .subscribe(res => {
          console.log('ress ', res);
        });
        // this.agGrid.api.refreshCells({ rowNodes: [rowNode], force: true });
      }
    }
  }

  toEditUsers() {
    if(this.isRuleEdittable) {
      this.router.navigate(['zs-cl/rules/create-bussiness-rule/add-users'], { queryParams: {rule_id: this.rule_id}});
    }
  }



  navigateToAddUsers() {
    if(this.viewMode === 'business') {
      this.router.navigate(['/zs-cl/rules/create-bussiness-rule/edit-users'], {queryParams: {rule_id: this.selectedRules, showBreadCrumb: true}});
    } else if(this.viewMode === 'technical'){
      this.router.navigate(['/zs-cl/rules/create-technical-rule/edit-users'], {queryParams: {rule_id: this.selectedRules, showBreadCrumb: true}});
    }
    this.selectedRules = [];
  }
}
