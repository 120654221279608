import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../project.service';
import { blueTextFormattter } from '../../../../common/shared/formatters/blueTextFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { checkBoxDisableFormatter } from '../../../../common/shared/formatters/checkBoxDisableFormatter';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { optionSelectionFormatter } from '../../../../common/shared/formatters/optionSelectionFormatter';
import { ActivatedRoute, Router } from '@angular/router';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { Project } from '../project.model';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
declare var $: any;

const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';

@Component({
  selector: 'zetta-merger-remediation-tasks',
  templateUrl: './merger-remediation-tasks.component.html',
  styleUrls: ['./merger-remediation-tasks.component.scss']
})
export class MergerRemediationTasksComponent implements OnInit, OnDestroy {

  mergerRemediationTasksData: [];
  grid: AngularGridInstance;
  loggedInUserDetails = null;
  mergerRemediationTasksGridOptions: any;
  tableSettings: object = { 'height': '100%', 'width': '100%' };
  mergerRemediationTaskTableColDef: any;
  gridOptions: any;
  projectDetail = new Project();
  isLoading = false;
  total = 0;
  page = 1;
  limit = 15;
  isShowRightGrid = false;
  rightGridDataSource: any = [];
  rightGridColumnsDef: any;
  rightGridOptions: any;
  rightGrid: AngularGridInstance;
  showBreadcrumb = false;
  selectedLeftGridRowsSize = 0;
  errorRibbon = false;
  selectedUserRoles: any = [];
  errorMsg: string = '';
  statusObj: any = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
  isProjectAdmin: Boolean = false;
  isFourEyeChecked: Boolean;
  openMergerFeedbackModal: boolean = false;
  selectedClusterId: string;
  selectedTaskType: string;
  selectedTaskName: string;
  taskType: string;
  selectedTaskStatus: string;
  selectedRow: string;

  constructor(private projectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {

    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');

    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.mergerRemediationTaskList(this.page, this.limit);

    this.mergerRemediationTaskTableColDef = [{
      'displayname': 'Task Name',
      'physicalname': 'zs_cluster_identifier',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': blueTextFormattter,
      'minWidth': 220,
    }, {
      'displayname': 'Records',
      'physicalname': 'zs_total_records_cluster',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-right',
      'formatter': numericFormatter,
    }, {
      'displayname': 'Assigned To',
      'physicalname': 'assigned_to',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-left',
      'minWidth': 120,
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 100,
    }, {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': assignmentStatusFormatter,
      'minWidth': 180,
    }, {
      'displayname': 'Open',
      'physicalname': 'has_suggested_clusters',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': optionSelectionFormatter,
      'minWidth': 80,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      'formatter': dateFormatter
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.mergerRemediationTasksGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 15,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 10,
      noDataMsg: RIGTH_EMPTY_GRID_MSG,
    };

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'isReviewerChecked',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxDisableFormatter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }, {
      'displayname': 'Approver',
      'physicalname': 'isApproverChecked',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxDisableFormatter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }];

    this.getProject();

    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }
  }

  getProject() {
    this.projectSvc.getProject(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(resp => {
      this.isFourEyeChecked = resp.four_eye_check;
      const settingsValue = resp['entitlement']['project'];
      if (settingsValue['canUpdate'] && settingsValue['canView']) {
        this.isProjectAdmin = true;
      }
    });
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  onGridCreation(grid) {
    let parent = this;
    parent.grid = grid;

    grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedLeftGridRowsSize = args.rows.length;
      if (parent.isShowRightGrid) {
        parent.isShowRightGrid = false;
        parent.errorMsg = '';
      }
      //parent.isShowRightGrid = parent.selectedLeftGridRowsSize === 0 ? false : true;
    });
  }

  rightGridCreated(grid) {
    this.rightGrid = grid;
  }
  onRightGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.rightGrid.dataView.getItems();
    row['selectedRoleID'] = null;

    if (row !== undefined && row !== null) {
      if (fieldName === 'isProjectAdminChecked') {
        row['selectedRoleID'] = AppGlobals.PROJECT_ADMIN;
        row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
        if (row['isProjectAdminChecked']) {
          row['selectedRoles'].push(AppGlobals.PROJECT_ADMIN);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.PROJECT_ADMIN);
        }
      } else if (fieldName === 'isReviewerChecked') {
        row['selectedRoleID'] = AppGlobals.REVEIWER;
        row['isReviewerChecked'] = !row['isReviewerChecked'];
        if (row['isReviewerChecked']) {
          row['selectedRoles'].push(AppGlobals.REVEIWER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
        }
      } else if (fieldName === 'isApproverChecked') {
        row['selectedRoleID'] = AppGlobals.APPROVER;
        row['isApproverChecked'] = !row['isApproverChecked'];
        if (row['isApproverChecked']) {
          row['selectedRoles'].push(AppGlobals.APPROVER);
        } else {
          row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
        }
      }

      this.setStatusProperty();
      this.validationChecking(dataSet, row);
    }
  }

  validationChecking(dataSet, row) {
    this.errorMsg = '';
    const reviewsList = dataSet.filter(item => item.isReviewerChecked === true);
    const approverList = dataSet.filter(item => item.isApproverChecked === true);

    if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size === 0 && this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
      row.isReviewerChecked = false;
    }
    else if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size >= 0 && this.statusObj.inreview_size > 0 && row.isReviewerChecked === true) {
      row.isReviewerChecked = (reviewsList.length === 1) ? true : false;
      if (row.isReviewerChecked) {
        this.errorMsg = `${row.username} will be set as the Reviewer only for the ${this.statusObj.inreview_size} tasks that are in Review status, out of ${this.statusObj.totalSelection} total tasks selected.`;
      } else {
        this.errorMsg = 'Only one Reviewer is allowed.';
      }
    }
    else if (this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
    }
    else if (reviewsList.length > 1) {
      this.errorMsg = 'Only one Reviewer is allowed.';
      row.isReviewerChecked = false;
    }
    else if (approverList.length > 1) {
      this.errorMsg = 'Only one Approver is allowed.';
      row.isApproverChecked = false;
    }
    if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.REVEIWER;
    } else if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.APPROVER;
    }
    // TODO: Call API to Update DB
    this.rightGrid.gridService.updateDataGridItem(row, false);
  }

  setStatusProperty() {
    this.statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
    const leftGridDataService = this.grid.gridService;
    const leftSideGridDataSet = this.grid.slickGrid.getSelectedRows();
    const rows: any[] = [];

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
      }
    });
    const completedList = rows.filter(item => item.task_status === 'Completed');
    const reviewsList = rows.filter(item => item.task_status === 'In Review');
    const approveList = rows.filter(item => item.task_status === 'Pending Approval');

    this.statusObj = { 'completed_size': completedList.length, 'inreview_size': reviewsList.length, 'pendingapproval_size': approveList.length, "totalSelection": rows.length };
  }
  mergerRemediationTaskList(pageno, limit = 1) {
    this.isLoading = true;
    this.projectSvc
      .getEntitiesMasteredList({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }).subscribe((data) => {
        this.isLoading = false;
        if (data.length <= 0) {
          this.mergerRemediationTasksData = [];
        }
        data['currentpage'].forEach(mergerData => {
          mergerData['id'] = Math.random();
          mergerData.shouldDisable = (mergerData.task_status === AppGlobals.TASK_COMPLETED || mergerData.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
        });
        this.mergerRemediationTasksData = data['currentpage'];
        this.isLoading = false;
        this.total = data['totalrecords'];
        if (this.grid) {
          this.grid.dataView.setItems(this.mergerRemediationTasksData);
          this.grid.gridService.setSelectedRows([]);
        }
      }, err => {
        this.isLoading = false;
        this.mergerRemediationTasksData = [];
      });
  }

  getUsersList() {
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';
    this.projectSvc.getUsers(this.loggedInUserDetails['user_id'],
      this.projectDetail.project_id, this.loggedInUserDetails['group_id']).subscribe(responseList => {
        this.isShowRightGrid = true;
        this.rightGridDataSource = responseList[1];
        this.rightGridDataSource.forEach(element => {
          element.id = Math.random();
          element.isFourEyeChecked = this.isFourEyeChecked;
        });
        this.rightGridDataSource.forEach(row => {
          row.roles.forEach(roles => {

            switch (roles) {
              case AppGlobals.PROJECT_ADMIN:
                row['isProjectAdminChecked'] = true;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.REVEIWER:
                row['isReviewerChecked'] = true;
                row['selectedRoles'] = row['roles'];
                row['isReviewer'] = true;
                break;
              case AppGlobals.APPROVER:
                row['isApproverChecked'] = true;
                row['selectedRoles'] = row['roles'];
                row['isApprover'] = true;
                break;
              default:
                row['isProjectAdminChecked'] = false;
                row['isReviewerChecked'] = false;
                row['isApproverChecked'] = false;
                row['selectedRoles'] = [];
            }
          });
        });
      }, err => {
        // alert('error');
        this.messageSvc.sendMessage({message: err.error.message, type: 'INFO', hideboard: true});
      });
  }
  addUserRole(boolean: boolean) {
    const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
    const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
    //const emptyUserRoleList = this.grid.slickGrid.getSelectedRows().filter(item => item.selectedRoles.length === 0);
    if (selectedProjectApproverList.length === 0 ||
      selectedProjectReviewerList.length === 0) {
      this.errorRibbon = true;
    } else {
      this.errorRibbon = false;
      this.saveUserRole(boolean);
    }
  }

  saveUserRole(bool: boolean) {
    let parent = this;
    const leftGridDataService = parent.grid.gridService;
    const leftSideGridDataSet = parent.grid.slickGrid.getSelectedRows();

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        parent.rightGridDataSource.map(resp => {
          if (resp.isApproverChecked === true || resp.isReviewerChecked === true) {
            parent.selectedUserRoles.push({ user_id: resp.user_id, role_id: resp.selectedRoleID, cluster_id: row.cluster_id });
          }
        });
      }
    });

    this.projectSvc.saveTasksRoles(this.loggedInUserDetails['user_id'], this.projectDetail.project_id,
      this.selectedUserRoles).subscribe(resp => {
        this.messageSvc.sendMessage({ message: AppGlobals.SAVE_TASK_ASSIGNMENT_TEXT, type: 'SUCCESS', hideboard: true });
        if (this.grid) {
          this.grid.dataView.setItems([]);
          this.isLoading = true;
          this.mergerRemediationTaskList(1, this.limit);
        }
      }, err => {
        this.selectedUserRoles = [];
      });

  }


  goToPage(n: number): void {
    this.page = n;
    this.mergerRemediationTaskList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.mergerRemediationTaskList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.mergerRemediationTaskList(this.page, this.limit);
  }

  closeModal() {
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
    $('#tm-modal').modal('hide');
    $('#tm-modal').addClass('d-none');
  }

  // rename the method name later
  onLeftGridCellClick(event) {
    const args = event.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const clickedCellIndex = args.cell;
    if (clickedCellIndex === this.grid.slickGrid.getColumnIndex('zs_cluster_identifier')) {
      this.openMergerFeedbackModal = true;
      this.selectedClusterId = row.zs_cluster_id;
      this.selectedTaskType = row.tasktype;
      this.selectedTaskName = row.summary;
      this.taskType = row.tasktype;
      this.selectedTaskStatus = row.task_status;
      this.selectedRow = row;
    }
  }

  onMergerFeedbackModalClosed(event) {
    this.openMergerFeedbackModal = false;
  }

  onReloadFixingDataGrid($event) {
    if ($event) {
      this.openMergerFeedbackModal = false;
      this.mergerRemediationTasksData = [];
      if (this.grid) {
        this.grid.dataView.setItems(this.mergerRemediationTasksData);
      }
      this.mergerRemediationTaskList(1, this.limit);
    }
  }

  assignTaskBtnClick() {
    this.getUsersList();
    this.selectedLeftGridRowsSize = 0;
  }

  isHavingAccess() {
    if (this.selectedLeftGridRowsSize > 0 && this.isProjectAdmin) {
      return false;
    } else {
      return true;
    }
  }

}
