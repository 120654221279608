import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../services/base-service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService extends BaseService {

	constructor(private http: HttpClient, private router:Router) { super(); }

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}
	signOut() {
		// remove user from local storage to log user out
		localStorage.clear();
		this.clearSession();
		return this.http.get(`${this.apiUrl}/auth/logout`);
	}

	login(obj) {
		return this.http.post(`${this.apiUrl}/auth/login`, obj).pipe(
			map(this.extractData));
	}
	loginWithJwt(obj) {
		return this.http.post(`${this.apiUrl}/auth/jwt/login`, obj).pipe(
			map(this.extractData));
	}

	getEntitlements() {
		return this.http.get(`${this.apiUrl}/entitlements`);
	}

	getUserRegistry(userName){
		return this.http.get(`${this.apiUrl}/users/${userName}?ssoRequest=true`);
	}

	getAzureUsers(){
		return this.http.post(`${this.apiUrl}/users/5/groups/azureGroupUsers`, {});
	}

	getMSGroups(pageSize){
		const graphEndpoint = `https://graph.microsoft.com/v1.0/groups?$top=${pageSize}`;
		//const graphEndpoint = "https://graph.microsoft.com/v1.0/users";
		//const graphEndpoint = "https://graph.microsoft.com/v1.0/me";
		const headers = new HttpHeaders({
			'ConsistencyLevel': 'eventual',
		});
		return this.http.get(`${graphEndpoint}`, { headers: headers });
	}

	getPagedMSGroup(graphEndpoint){
		return this.http.get(`${graphEndpoint}`);
	}
	getGroupMembers(group_id, pageSize){
		let groupId = "0e720a98-df78-4bef-932d-18f3622d71fc";
		const graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${group_id}/members?$top=${pageSize}`;
		return this.http.get(`${graphEndpoint}`);
	}
	getPagedMSGroupUsers(graphEndpoint){
		return this.http.get(`${graphEndpoint}`);
	}

	getAdminConcent(){
		// const adminConcentEndpoint = `https://login.microsoftonline.com/9d5784cb-31ce-4e3d-b42c-a8fb0d41d233/v2.0/adminconsent?
		// client_id=ac2b2a98-8a08-4191-bdc4-32704649935b
		// &redirect_uri=http://localhost:4200/#/zs/home
		// &scope=https://graph.microsoft.com/user.read`;
		let payload = {
			scope: ["openid"]
		};
		const adminConcentEndpoint1 = `https://login.microsoftonline.com/9d5784cb-31ce-4e3d-b42c-a8fb0d41d233/oauth2/v2.0/authorize?client_id=ac2b2a98-8a08-4191-bdc4-32704649935b&response_type=code&redirect_uri=http://localhost:4200/#/zs/home&response_mode=query&scope=openid https://graph.microsoft.com/.default&state=12345`;
		return this.http.post(`${adminConcentEndpoint1}`, payload);
	}
	
	validateUser(user_id,payload):Observable<any>{
		return this.http.post(`${this.apiUrl}/users/${user_id}/groups/azureGroupUsers`,payload);
	}
	
	createGroup(payload){
		return this.http.post(`${this.apiUrl}/groups`,payload);
	}

	logOut(){
		// remove user from local storage to log user out
		localStorage.clear();
		this.clearSession();
		this.router.navigate(['/']);
	}
}
