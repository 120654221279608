import { MapDataSetsService } from './map-data-sets-service';
import { Project, Dataset } from '../project.model';
import { ProjectService } from '../project.service';
import { AngularGridInstance } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, AfterViewChecked, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from './../../../../common/shared/zettaUtils';
import { MessageService } from '../../../../common/components/message/message.service';
import * as _ from 'lodash';
import { dropFormatter } from './../../../../common/shared/formatters/dropFormatter';
import { toggleFormatter } from './../../../../common/shared/formatters/toggleFormatter';
import { AppGlobals } from 'src/app/common/shared/app.globals';
declare var $: any;
import { unMappedAttributeFormattter } from '../../../../common/shared/formatters/unMappedAttributeFormatter';
import { attributeMappingFormatter } from '../../../../common/shared/formatters/attributeMappingFormatter';
import { dragFormatterLink } from 'src/app/common/shared/formatters/dragFormatterLink';

const RIGHT_EMPTY_GRID_MSG = 'Entity attribute list is empty for mapping.';
const LEFT_EMPTY_GRID_MSG = 'No attribute to map.';
const MODAL_EMPTY_GRID_MSG = 'No Data.';

@Component({
    selector: 'zetta-map-data-sets',
    templateUrl: './map-data-sets.component.html',
    styleUrls: ['./map-data-sets.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MapDataSetsComponent implements OnInit, OnDestroy, AfterViewChecked {

    isDataReady = false;
    isSampelDsLoading = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    modalGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    modalGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    fieldsMapped = 0;
    deletedAttributes = [];
    progressState = new ProjectStatus();
    showBreadcrumb = false;
    entityAttribute = new Map<string, any>();
    defaultEntityAttributes = [];
    mappedDataSetAttributes = [];
    draggedAttributes = [];
    defaultUnMappedEntityAttributes = [];
    modalRowData = [];
    entityResp;
    entityRespAfter;
    updatedDataSetAttributes: Dataset[] = [];
    leftGridOptions: any;
    rightGridOptions: any;
    modalGridOptions: any;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    leftGridMappingHeader: any;
    hasActiveJob = true;
    loggedInUserDetails: [];
    rightGridDataView: any;
    dsName;
    showmodelpopup = false;
    datasetId;
    object_type_id:number;
    modeState: any = {};
    entity_id: number;
    isProjectEditFlow = false;
    resolveEditFlow: boolean = false;
    total_entity_modal: number;

    dataRes;
    is_upload_complete = false;
    newDataset: any = {};
    uploaded_files=[];
    showTab: string;
    is_allow_to_import = false;
    fu_type_project_mapping: any;
    clearModal =  Math.random(); 
    projectId: number;
    openFileUploadImport:boolean = false;
    existingMappingRemovedState = [];
    originalDatasetEntityMapping = []; // Maintaining dataset entity mapping at it's original state(ones which are already mapped)
    constructor(public zettaUtils: ZettaUtils,
        public mapService: MapDataSetsService,
        public service: ProjectService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private messageSvc: MessageService) { }

        dir_data: object[] = [];
        files_data: object[] = [];
        dir_files:any=[];   
        child_file:object[]=[];    
        sub_file_data:object[]=[]; 

    ngOnInit() {
        this.fu_type_project_mapping = AppGlobals.FU_TYPE_PROJECT_MAPPING;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.object_type_id = +sessionStorage.getItem('project_type_id');       
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            if (queryParams.has('resolveEditFlow')) {
                this.resolveEditFlow = queryParams.get('resolveEditFlow') == "true" ? true : false;
            }
        });
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
            this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
        } else {
            this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
        }

        

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: false,
            enableRowSelection: true,
            mapDataSets: true,
            noDataMsg: RIGHT_EMPTY_GRID_MSG,
        };

        this.modalGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            multiSelectable: true,
            enableRowSelection: false,
            dragRow: true,
            showNoData: false,
            mapDataSets: true,
            noDataMsg: MODAL_EMPTY_GRID_MSG,
        };

        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];   
        });

        this.service.getProject(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(resp => {
            this.projectDetail = <Project>resp;

            // Set Project Progress Bar
            this.progressState.states = this.service.getProjectStateList();
            this.progressState.currentStateIndex = 4;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Project';
            this.progressState.project = this.projectDetail;
            this.progressState.isEdit = (this.projectDetail.project_id !== undefined && this.projectDetail.project_id !== null) ? true : false;
            this.progressState.isEdit = sessionStorage.getItem('newProject') === 'true' ? false : this.progressState.isEdit;

            if (this.projectDetail.datasets.length === 0) {
                // revert back to Manage Data set as no Data set mapped
                this.onPrevious();
            }
            
            this.service.fetchProjectEntities(this.projectDetail.entity_id, this.projectDetail.project_id).subscribe(entityResp => {
                // Get Right Grid Attribute Columns Display Name Map with its systemAttributeID
                this.entity_id = entityResp.entity_id;
                if (entityResp.attributes) {
                    this.entityResp = entityResp.attributes;
                    this.prepareEntityMapping(this.entityResp);
                }
                if(this.resolveEditFlow) {
                                        // commenting below code as create and edit flow now both should be same and keeping this for reference 
                                        // this should be called for edit and manage project data of resolve-project
                        this.setDefaultRightGridDataSources();
                        this.initRightGridRows();
                        //Define cols
                        this.initColDef();
                        this.setDefaultLeftGridDataSources();
                } else if (!this.isProjectEditFlow) {
                        this.getMappedAttributes(); /// This function will call only on create project flow
                }

               
            },
                error => {
                    this.messageSvc.sendMessage({ message: 'Map dataset fetch entities has failed', type: 'INFO', hideboard: true });
                });
        },
            error => {
                this.messageSvc.sendMessage({ message: 'Map dataset get projects has failed', type: 'INFO', hideboard: true });
            });

        this.service.getCurrentActiveJobs(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.object_type_id).subscribe(
            jobs => {
                this.hasActiveJob = this.service.hasActiveJob(jobs);
            },
            error => {
                this.hasActiveJob = false;
                // this.messageSvc.sendMessage({ message: 'Map dataset get current active jobs has failed', type: 'INFO', hideboard: true });
            });
    }
    
    getChainedAttribute(atts): string[] {
        const arr: string[] = [];
        atts.map((arrayItem) => {
            if (arrayItem.is_visible_input) {
                arr.push(arrayItem);
                if (arrayItem.chained) {
                    arrayItem.chained.map(element => arr.push(element)
                    );
                }
            }
        });
        return arr;
    }

    setDefaultRightGridDataSources() {
        this.rightGridDataSource = [];
        this.defaultEntityAttributes.forEach(attribute => {
            this.entityAttribute.set(attribute.physical_name, attribute);
            this.rightGridDataSource.push({
                'id': Math.random(), 'logical_name': attribute.logical_name,
                'dataset_name': '', 'target_attribute': 'dropDiv', 'system_attribute': attribute.physical_name,
                'attribute_id': attribute.attribute_id, 'sort_order': attribute.sort_order, 'indent': attribute.indent,
                'parent': attribute.parent, 'sequenceIndex': attribute.sequenceIndex, '_collapsed': attribute._collapsed,
                'data_type': attribute.data_type, 'chained': attribute.chained, 'parentattributeid': attribute.parentattributeid, 'entity_id': this.entity_id 
                
            });            
        });
    }

    // Get Left Grid Data
    setDefaultLeftGridDataSources() {
        this.leftGridDataSource = [];
        let datasetCount = this.projectDetail.datasets.length;
        if (this.projectDetail.datasets) {
            this.projectDetail.datasets.forEach(dataset => {
                this.service.
                getDataSetAttributes(this.loggedInUserDetails['tenant_id'], dataset.dataset_id, this.projectDetail.project_id).
                    subscribe(resp => {
                        resp.forEach(element => {
                            element.id = Math.random();
                            element.dataset_name = dataset.name;
                            element.dataset_id = dataset.dataset_id;
                            element.groupedRow = false;
                            element.authoritativeness = dataset.authoritativeness;
                        });
                        this.defaultUnMappedEntityAttributes.push(...resp);
                        datasetCount = datasetCount - 1;
                        if (datasetCount === 0) {
                            this.initLeftGridRows(); 
                        }
                    }, err => {
                        datasetCount = datasetCount - 1;
                    });
            });
        }
    }

    selectOptionDataSetModelList() {
        this.isSampelDsLoading = true;
        setTimeout(() => {
            if (this.entityResp) {
                this.isSampelDsLoading = false;
                const entityAttribute = this.entityResp.slice();
                const attributesWithChained = [];
                if (entityAttribute.length) {
                    entityAttribute.forEach(element => {
                        if (element.hasOwnProperty('chained')) {
                            const chainedAttr = [...element.chained];
                            if (chainedAttr && chainedAttr.length) {
                                chainedAttr.forEach(chainAttr => {
                                    attributesWithChained.push(chainAttr);
                                });
                            }
                        }
                        attributesWithChained.push(element)
                    });
                    this.total_entity_modal = attributesWithChained.length;
                }
                this.entityRespAfter = attributesWithChained;
            }
        }, 1000);
    }
    initRightGridRowsForCreate(maapedAttribute) {
        const parent = this;
        if (parent.projectDetail.datasets) {
            parent.projectDetail.datasets.forEach(dataset => {
                const mappedAttributeForDataset = maapedAttribute.filter(attr => attr.dataset_id === dataset.dataset_id);
                if (mappedAttributeForDataset.length) {
                    mappedAttributeForDataset.forEach(attribute => {
                        attribute['logical_name'] = attribute.attribute_name;
                        attribute['id'] = Math.random();
                        attribute['sort_order'] = ''
                        attribute['isNewlyCreated'] = true;
                        attribute['styleCss'] = 'remove-border';
                        attribute['entity_id'] = this.entity_id;
                        attribute['target_attribute'] = attribute.column_name;
                        parent.mappedDataSetAttributes.push(attribute);
                        parent.originalDatasetEntityMapping.push({...attribute});
                    });
                }

            });
        }
        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== 'Whole') {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                } else {
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                }
            }

        });
        parent.rightGridDataSource.forEach((element) => {
            element.id = Math.random();
        });
        this.initColDef();
        this.setDefaultLeftGridDataSources();
    }
    initRightGridRows() {
        const parent = this;
        const rightDataSet = [];                
        if (parent.projectDetail.datasets) {
            parent.projectDetail.datasets.forEach(dataset => {
                if (dataset.attributes) {
                    dataset.attributes.forEach(attribute => {
                        attribute['dataset_column_id'] = attribute.dataset_column_id;
                        attribute['id'] = Math.random();
                        if (parent.entityAttribute.has(attribute.system_attribute)) {
                            attribute['logical_name'] = parent.entityAttribute.get(attribute.system_attribute).logical_name;
                            attribute['dataset_name'] = dataset.name;
                            attribute['dataset_id'] = dataset.dataset_id;
                            attribute['sort_order'] = parent.entityAttribute.get(attribute.system_attribute).sort_order;
                            attribute['isNewlyCreated'] = true;
                            attribute['styleCss'] = 'remove-border';
                            attribute['entity_id'] = this.entity_id;
                            const duplicateList = parent.rightGridDataSource.filter(function (item) {
                                return item.target_attribute === attribute.target_attribute;
                            });
                            if (duplicateList.length > 0) {
                                duplicateList[0]['dataset_name'] = attribute['dataset_name'];
                                duplicateList[0]['dataset_id'] = attribute['dataset_id'];
                                duplicateList[0]['target_attribute'] = attribute['target_attribute'];
                            } else {
                                rightDataSet.push(attribute);
                            }
                            parent.mappedDataSetAttributes.push(attribute);
                        }
                    });
                }
            });
        }
        parent.mappedDataSetAttributes.forEach(element => {
            // get the index of element
            const index = parent.rightGridDataSource.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = parent.rightGridDataSource[index];
                if (parentObj.data_type !== 'Whole') {
                    element['parent'] = parentObj.parent;
                    element['parentattributeid'] = parentObj.parentattributeid;
                    element['sequenceIndex'] = parentObj.sequenceIndex;
                    element['_collapsed'] = parentObj._collapsed;
                    parent.rightGridDataSource.splice(index + 1, 0, element);
                    parent.originalDatasetEntityMapping.push({...element});
                }
            }

        });
    }

    removeMapping(row: any, isLeftgrid: boolean) {
        if (!isLeftgrid) {

            let payload = {};
            let tempRow = {...row};
            if (this.validateIfDatasetEntityInitiallyMapped(tempRow)) {
                // maintaining existing mapped state after removal of mapping
                this.existingMappingRemovedState.push({...row});
            }
            if(this.resolveEditFlow) {
                payload = {
                    target_attribute: row.target_attribute,
                    system_attribute: '',
                    attribute_id: row.attribute_id,
                    entity_id: row.entity_id,
                    dataset_id: row.dataset_classify_id ? row.dataset_classify_id : row.dataset_id,
                    dataset_column_id: row.dataset_column_id,
                    removing_attribute_id: parseInt(row.attribute_id),
                    mapping_type: "not_sure",
                    catalog_id: this.projectDetail.catalog_id
                };
            } else {
                payload = {
                   dataset_id: row.dataset_classify_id ? row.dataset_classify_id : row.dataset_id,
                   dataset_column_id: row.dataset_column_id,
                   removing_attribute_id: parseInt(row.attribute_id),
                   mapping_type: "not_sure",
                   entity_id: row.entity_id,
                   catalog_id: this.projectDetail.catalog_id
               };
            }
            this.deletedAttributes.push(payload);
            // Remove Mapped Row from right Grid
            row['dataset_name'] = '';
            // updated Mapped Record data
            this.mappedDataSetAttributes = this.mappedDataSetAttributes.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));

            const leftGridRow = this.leftGrid.dataView.getItems().filter(leftRow => (leftRow.column === row.target_attribute
                && leftRow.mapping === row.logical_name));

            const leftGridDataSourceRow = this.leftGridDataSource.find(leftRow => (leftRow.column === row.target_attribute
                && leftRow.mapping === row.logical_name));

            // Remove Mapped Row from Left Grid
            if (leftGridRow.length > 0) {
                if (leftGridRow[0].groupedRow) {
                    this.leftGrid.gridService.deleteDataGridItem(leftGridRow[0]);
                } else {
                    leftGridRow[0].mapping = undefined;
                    this.leftGrid.gridService.updateDataGridItem(leftGridRow[0], false);
                }
            }
            if (leftGridDataSourceRow) {
                if (leftGridDataSourceRow.groupedRow) {
                    this.leftGridDataSource = this.leftGridDataSource.filter(leftRow => !(leftRow.column === row.target_attribute
                        && leftRow.mapping === row.logical_name));
                } else {
                    leftGridDataSourceRow.mapping = undefined;
                }
            }
            if (this.rightGridDataSource.length) {
                this.rightGridDataSource = this.rightGridDataSource.filter(mappedAttr => !(mappedAttr.dataset_id === row.dataset_id
                    && mappedAttr.attribute_id === row.attribute_id && mappedAttr.dataset_column_id === row.dataset_column_id));
            }           
            this.saveDataSetMapping(row, 'DELETE', row.dataset_id);
            this.progressState = Object.assign({}, this.progressState);
            this.updateMappingLeftGridColumn(false);
            if (row['isNewlyCreated'] && row['isNewlyCreated'] === true) {
                this.rightGrid.dataView.beginUpdate();
                this.rightGrid.dataView.deleteItem(row['id']);
                this.rightGrid.dataView.endUpdate();
            } else {
                row['target_attribute'] = 'dropDiv';
                this.rightGrid.gridService.updateDataGridItem(row, false);
            }
        }
    }

    // Edit flow
    validateIfDatasetEntityInitiallyMapped(datasetEntityRow) {
         if (this.originalDatasetEntityMapping && this.originalDatasetEntityMapping.length) {
            let isOriginalDatasetMappingExist = this.originalDatasetEntityMapping.find((entityMapping) => {
                return entityMapping.logical_name === datasetEntityRow.logical_name && entityMapping.target_attribute === datasetEntityRow.target_attribute
                && entityMapping.dataset_name === datasetEntityRow.dataset_name;
            })
            return isOriginalDatasetMappingExist ? true : false;
         }
         return false;
    }

    groupRows() {
        this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);
        if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.leftGridDataSource);
        }
    }

    initLeftGridRows() {
        this.leftGridDataSource = [];
        this.leftGridDataSource.push(... this.defaultUnMappedEntityAttributes);
        this.mappedDataSetAttributes.forEach(attribute => {
            const firstRow = this.leftGridDataSource.find(row =>
                row.column === attribute.target_attribute && row.dataset_id === attribute.dataset_id &&
                row.mapping === undefined && row.groupedRow === false);

            if (firstRow) {
                firstRow.mapping = attribute.logical_name;
            } else {
                // has any matching dataset with column: Safety check in case Data issue.
                const anyRow = this.leftGridDataSource.find(row =>
                    row.column === attribute.target_attribute && row.dataset_id === attribute.dataset_id);

                if (anyRow) {
                    this.leftGridDataSource.push({
                        'id': Math.random(), 'dataset_name': attribute.dataset_name,
                        'column': attribute.target_attribute,
                        'mapping': attribute.logical_name, 'dataset_id': attribute.dataset_id, 'groupedRow': true
                    });
                }
            }
        });
        this.leftGridRows = this.defaultUnMappedEntityAttributes.length;
        this.groupRows();
        this.isDataReady = true;
    }

    getRightGridColumns() {
        return [{
            'displayname': 'Attribute',
            'physicalname': 'logical_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'params': { 'rootObj': this },
            'formatter': toggleFormatter,
        }, {
            'displayname': 'Mapping',
            'physicalname': 'target_attribute',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': dropFormatter
        }];
    }

    getResolveLeftGridColumns(totalMapping?: number) {
        return [
            {
                'displayname': 'Data Set',
                'physicalname': 'dataset_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': dragFormatterLink,
                'minWidth': 120,
            },
            {
                'displayname': 'Column Name',
                'physicalname': 'column',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': unMappedAttributeFormattter,
            },
            {
                'displayname': `Mapping(${totalMapping})`,
                'physicalname': 'mapping',
                'sortable': false,
                'datatype': 'String',
                'filterable': false,
                'formatter': attributeMappingFormatter,
            }
        ];
    }

    initColDef() {
        this.leftGridColumnsDef = this.getResolveLeftGridColumns(this.mappedDataSetAttributes.length);
        this.rightGridColumnsDef = this.getRightGridColumns();
        this.modalGridColumnsDef = this.mapService.getModalGridColumns();
    }

    rightGridCreated(grid) {
        this.buildParentAndChildRelation(this.rightGridDataSource);
        this.rightGrid = grid;
        this.rightGridDataView = this.rightGrid.dataView;
        this.rightGridDataView.beginUpdate();
        this.rightGridDataView.setFilter(this.myFilter);
        this.rightGridDataView.setFilterArgs(this.rightGridDataSource);
        this.rightGridDataView.endUpdate();

        const self = this;
        grid.slickGrid.onCellChange.subscribe(function (e, args) {
            self.rightGrid.dataView.updateItem(args.item.id, args.item);
        });

        grid.slickGrid.onClick.subscribe(function (e, args) {
            if ($(e.target).hasClass('expandCollapse')) {
                const rowitem = self.rightGrid.dataView.getItem(args.row);
                if (rowitem) {
                    if (!rowitem._collapsed) {
                        rowitem._collapsed = true;
                        self.hidingParentElement(rowitem);
                    } else {
                        rowitem._collapsed = false;
                        self.hidingParentElement(rowitem);
                    }
                    self.rightGrid.dataView.updateItem(rowitem.id, rowitem);
                }
                e.stopImmediatePropagation();
            }
        });

        // wire up model events to drive the grid
        self.rightGrid.dataView.onRowCountChanged.subscribe(function (e, args) {
            grid.slickGrid.updateRowCount();
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });

        grid.slickGrid.onScroll.subscribe(function (e, args) {
            self.showHideDragDrop();
            self.removeBorders();
        });

        self.rightGrid.dataView.onRowsChanged.subscribe(function (e, args) {
            grid.slickGrid.invalidateRows(args.rows);
            grid.slickGrid.render();
            self.showHideDragDrop();
            self.removeBorders();
        });
    }

    hidingParentElement(row) {
        const self = this;
        self.mappedDataSetAttributes.forEach(element => {
            const rightTableDS = self.rightGridDataView.getItems();
            // get the index of element
            const index = rightTableDS.findIndex(item => item.attribute_id === element.attribute_id);
            if (index > -1) {
                const parentObj = rightTableDS[index];
                if (parentObj.parentattributeid === -1 && row.attribute_id === parentObj.attribute_id) {
                    if (!row._collapsed) {
                        parentObj['dataset_name'] = element['dataset_name'];
                        parentObj['target_attribute'] = 'dropDiv';
                        setTimeout(() => {
                            self.rightGridDataView.deleteItem(element.id);
                        }, 10);
                    } else {
                        element['parent'] = parentObj.parent;
                        element['parentattributeid'] = parentObj.parentattributeid;
                        element['sequenceIndex'] = parentObj.sequenceIndex;
                        element['_collapsed'] = parentObj._collapsed;
                        element['isNewlyCreated'] = true;
                        element['styleCss'] = 'remove-border';
                        self.rightGridDataView.insertItem(index + 1, element);
                        parentObj['dataset_name'] = '';
                    }
                    self.rightGrid.dataView.updateItem(parentObj.id, parentObj);
                }
            }
        });
    }

    showHideDragDrop() {
        $('.fa-caret-right').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').show();
        });
        $('.fa-caret-down').each(function (index, element) {
            $(this).parent().parent().parent().find('.r1').hide();
        });
    }

    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    myFilter(item, args) {
        if (item.parent != null) {
            let parent = args[item.parent.id];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = args[parent.parent ? parent.parent.id : null];
            }
        }
        return true;
    }

    onModelGridCreation(grid) {
        this.modalGrid = grid;
    }

    // TO Do clean up--- Save Project and run model
    updateProject(next: boolean) {
        // Add Validation for all Datasets
        const project = new Project();
        project.project_id = this.projectDetail.project_id;
        // project.user_id = this.projectDetail.user_id;
        project.user_id = this.loggedInUserDetails['user_id'];
        project.tenant_id = this.projectDetail.tenant_id;
        project.datasets = [...this.updatedDataSetAttributes];

        // Convert as per api
        project.datasets = project.datasets.map(dataset => {
            dataset.attributes.forEach(attribute => {
                attribute['id'] = undefined;
                attribute['logical_name'] = undefined;
                attribute['dataset_name'] = undefined;
            });
            return dataset;
        });

        this.service.saveProject(project).subscribe(resp => {
            this.updatedDataSetAttributes = [];
            $('#projectState3').removeClass('d-none');
            setTimeout(function () {
                $('#projectState3').addClass('d-none');
            }, 5000);
            if (next) {
                // Scenario id is 0 for this case
                if(resp) {
                    this.service.runModel(project.project_id, 0).subscribe(resp => {
                          this.goToNext();
                        }, err => {
                            // alert(err.error.message);
                            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
                        });
                }
            }
        }, err => {
            // alert(err.error.message);
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }

    // Save Project Mapping only
    updateProjectMapping(datasets: Dataset[]) {
        // Add Validation for all Datasets
        const project = new Project();
        project.project_id = this.projectDetail.project_id;
        // project.user_id = this.projectDetail.user_id;
        project.user_id = this.loggedInUserDetails['user_id'];
        project.tenant_id = this.projectDetail.tenant_id;
        project.datasets = [...datasets];

        // Convert as per api
        project.datasets = project.datasets.map(dataset => {
            dataset.attributes.forEach(attribute => {
                attribute['id'] = undefined;
                attribute['logical_name'] = undefined;
                attribute['dataset_name'] = undefined;
            });
            return dataset;
        });
        if (this.isProjectEditFlow) {
            this.updateProjectMappingData(project);
        }
    }

    onPrevious() {
        this.router.navigate(['/zs/projects', this.projectDetail.project_id,
            'edit-entity-model'], { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    onRunModel() {  
        //this.updateEntityMapping();
        /**
         * Before running a model, validating below if each project dataset has been mapped to a primary key
         */
        let projectDataset = [];
        let primaryKeyEntity = [];
        let isPrimaryKeyMappedToEachDataset = false;
        if (this.projectDetail.datasets && this.projectDetail.datasets.length) {
            this.projectDetail.datasets.forEach((datasets) => {
                if (projectDataset.indexOf(datasets.dataset_id) <= -1) {
                    projectDataset.push(datasets.dataset_id);
                }
            });
        }
        const mappedData = this.rightGrid.dataView.getItems();

        if (mappedData && mappedData.length) {
            primaryKeyEntity = mappedData.filter((mappingData) => {
                return ((mappingData.logical_name === AppGlobals.IDENTIFIER_AT_SOURCE) || (mappingData.logical_name === AppGlobals.SOURCE_SYSTEM_IDENTIFIER) || (mappingData.logical_name === AppGlobals.SOURCE_PRIMARY_KEY) || 
                (mappingData.logical_name === AppGlobals.SOURCE_SYSTEM_PRIMARY_KEY))
            });
        }

        if (projectDataset.length && primaryKeyEntity && primaryKeyEntity.length) {

            isPrimaryKeyMappedToEachDataset = projectDataset.every( dataset => {
                let isDatasetPresent = primaryKeyEntity.find((mappingData) => {
                    return mappingData.dataset_id === dataset;
                })
                return isDatasetPresent ? true: false;
            });
        } 

        if (isPrimaryKeyMappedToEachDataset) {
            this.updateEntityMapping();
        } else {
            this.messageSvc.sendMessage({ message: 'To run successfully, we require one Primary Key mapping from each Data Source to the Source Primary Key', type: 'ERROR', hideboard: true});
        }
    }

    removeElementFromArray(element: number) {
        this.draggedAttributes.forEach((value, index) => {
            if (value.dataset_column_id === element) this.deletedAttributes.splice(index, 1);
        });
    }
    
    updateEntityMapping() {
        const mappedData = this.rightGrid.dataView.getItems();
        const datasets = [];
        this.projectDetail.datasets.forEach(dataset => {
            const mappedAttrbutes = [];           
            mappedData.forEach(element => {
                if (dataset.dataset_id === element.dataset_id && element.dataset_column_id) {
                    mappedAttrbutes.push({
                        target_attribute: element.target_attribute,
                        system_attribute: '',
                        dataset_column_id: element.dataset_column_id,
                        attribute_id: element.attribute_id,
                        dataset_id: element.dataset_id,
                        entity_id: element.entity_id,
                        authoritativeness: 1,
                        opCode: 'ADD',
                    });
                }
            });
            if(this.resolveEditFlow) {
                this.deletedAttributes.forEach(ele => {
                    if (dataset.dataset_id === ele.dataset_id && ele.dataset_column_id) {
                        mappedAttrbutes.push({
                            target_attribute: ele.target_attribute,
                            system_attribute: '',
                            dataset_column_id: ele.dataset_column_id,
                            attribute_id: ele.attribute_id,
                            dataset_id: ele.dataset_id,
                            entity_id: ele.entity_id,
                            authoritativeness: 1,
                            opCode: 'DELETE',
                        });
                    }
                });
            }

            datasets.push({
                dataset_id: dataset.dataset_id,
                attributes: mappedAttrbutes
            });
        });
        const payload = {
            is_deleted: false,
            project_id: this.projectDetail.project_id,
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            datasets: datasets
        };
        this.updateProjectMappingData(payload);
    }

    updateProjectMappingData(payload){
        this.service.saveProject(payload).subscribe(resp => {                       
            $('#projectState3').removeClass('d-none');
            setTimeout(function () {
                $('#projectState3').addClass('d-none');
            }, 5000);
            if(resp) {
                this.service.runModel(this.projectDetail.project_id, 0).subscribe(resp => {
                    let payload = [];
                    if(this.deletedAttributes.length>0)
                    {
                        if (this.draggedAttributes.length > 0) {
                            this.deletedAttributes.forEach(element => {
                                this.removeElementFromArray(element.dataset_column_id);
                            });
                            this.downVote(this.deletedAttributes);
                        }
                        else {
                            this.downVote(this.deletedAttributes);
                        }
                }
                    this.goToNext();
                    sessionStorage.removeItem('newProject');
                }, err => {
                    this.messageSvc.sendMessage({ message: err.error[0].message, type: 'ERROR', hideboard: true, time: 20000 });
                });
            }
        }, err => {
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }

    goToNext() {
        this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel: true, messageHeader: AppGlobals.RESOLVE_PROJECT_RUN_MODEL_HEADER_TEXT, messageText: AppGlobals.CLASSIFICATION_MSG_TEXT, path: `/zs/projects/${this.projectDetail.project_id}` });
    }
    downVote(payload) {
        const getRow: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            payload: payload
        };
        this.service.downVote(getRow).subscribe((data) => {
        }, err => { });
    }
    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zs/projects']);
        } else {
            this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
        }
    }

    removeBorders() {
        $('.remove-border').each(function () {
            $(this).parent().parent().children('.slick-cell').each(function () {
                $(this).attr('id', 'remove-border-bottom');
            });
        });
    }

    onDrop(draggedRowIndex, droppedRowIndex) {
        const draggedRowData = this.leftGrid.gridService.getDataItemByRowIndex(draggedRowIndex);
        const droppedRowData = this.rightGrid.gridService.getDataItemByRowIndex(droppedRowIndex);
        const newRightRow = {
            'id': Math.random(), 'logical_name': droppedRowData.logical_name, 'dataset_name': draggedRowData.dataset_name,
            'target_attribute': draggedRowData.column, 'system_attribute': droppedRowData.system_attribute, 'dataset_column_id': draggedRowData.dataset_column_id, 
            'attribute_id': droppedRowData.attribute_id, 'dataset_id': draggedRowData.dataset_id, 'sort_order': droppedRowData.sort_order, 'entity_id': this.entity_id,
            'authoritativeness': draggedRowData.authoritativeness
        };
        const newLeftRow = {
            'id': Math.random(), 'dataset_name': draggedRowData.dataset_name, 'column': draggedRowData.column,
            'mapping': droppedRowData.logical_name, 'dataset_id': draggedRowData.dataset_id, 'groupedRow': true
        };

        const existingMapping = this.getDuplicate(newRightRow);
        if (existingMapping) {
            this.messageSvc.sendMessage({ message: `Mapping already exists.`, type: 'INFO', hideboard: true });
        } else {
            this.saveDataSetMapping(newRightRow, 'ADD', draggedRowData.dataset_id);
            this.mappedDataSetAttributes.push(newRightRow);
            this.draggedAttributes.push(newRightRow);
            const coloneAttribute = Object.assign({}, droppedRowData);
            coloneAttribute['isNewlyCreated'] = false;
            if (droppedRowData['dataset_name'] !== '') {
                const dropIndex = (parseInt(droppedRowIndex, 10) + 1);
                coloneAttribute.id = newRightRow.id;
                coloneAttribute['styleCss'] = 'remove-border';
                droppedRowData['styleCss'] = 'remove-border';
                coloneAttribute['isNewlyCreated'] = true;
                coloneAttribute['dataset_id'] = newRightRow.dataset_id;
                coloneAttribute['target_attribute'] = newRightRow.target_attribute;
                coloneAttribute['dataset_name'] = newRightRow.dataset_name;
                coloneAttribute['dataset_column_id'] = newRightRow.dataset_column_id;
                 this.rightGridDataView.insertItem(dropIndex, coloneAttribute);
                // const rowData = this.rightGridDataView.getItems();
                // this.rightGridDataView.setItems(rowData);
                const index = this.rightGridDataSource.findIndex(item => item.attribute_id === coloneAttribute.attribute_id);
                this.rightGridDataSource.splice(index + 1, 0, coloneAttribute);
                const rowData = this.rightGridDataView.getItems();
                this.rightGridDataView.setItems(rowData);
                this.removeBorders();
            } else {
                droppedRowData['dataset_id'] = newRightRow.dataset_id;
                droppedRowData['target_attribute'] = newRightRow.target_attribute;
                droppedRowData['dataset_name'] = newRightRow.dataset_name;
                droppedRowData['authoritativeness'] = newRightRow.authoritativeness;
                droppedRowData['dataset_column_id'] = newRightRow.dataset_column_id;
            }
            this.rightGrid.gridService.updateDataGridItem(droppedRowData, false);
            this.onDropUpdateLeftGridRow(true, newLeftRow);
            this.updateMappingLeftGridColumn(true);
        }
    }

    onDropUpdateLeftGridRow(addRow: boolean, row: any) {
        if (addRow) {
            const firstRow = this.leftGridDataSource.find(leftRow => row.column === leftRow.column
                && row.dataset_id === leftRow.dataset_id &&
                leftRow.mapping === undefined &&
                leftRow.groupedRow === false);
            if (firstRow) {
                firstRow.mapping = row.mapping;
                this.leftGrid.gridService.updateDataGridItem(firstRow, false);
            } else {
                this.leftGridDataSource.push(row);
                this.leftGridDataSource = _.orderBy(this.leftGridDataSource, ['dataset_name', 'column'], ['asc']);

                let currentLeftGridRow = this.leftGrid.dataView.getItems();
                currentLeftGridRow.push(row);
                currentLeftGridRow = _.orderBy(currentLeftGridRow, ['dataset_name', 'column'], ['asc']);
                this.leftGrid.dataView.setItems(currentLeftGridRow);
            }
            this.progressState = Object.assign({}, this.progressState);
        }
    }

    ngAfterViewChecked() {
        const self = this;
        $('.dropDiv').droppable({
            drop: function (event, ui) {
                const dragElement = $(ui.draggable).find('div.dragMe');
                if (dragElement === undefined || dragElement === null || dragElement.length === 0) {
                    return;
                }
                const droppedRowIndex = $(this).attr('value');
                const draggedRowIndex = dragElement.attr('value');
                self.onDrop(draggedRowIndex, droppedRowIndex);
                $(this).removeClass('drag-enter');
            },
            over: function (event, ui) {
                $(this).addClass('drag-enter');
            },
            out: function (event, ui) {
                $(this).removeClass('drag-enter');
            }
        });
    }

    onRightGridCellClicked(params) {
        const event = params.eventData;
        const args = params.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);

        if (event.target.className === 'fa fa-minus deleteBtn') {
            this.removeMapping(row, false);
        }
    }
    onLeftGridCellClicked(params) {
        const args = params.args;
        const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
        if (row !== undefined && row !== null) {
            this.showmodelpopup = true;
            this.dsName = row.dataset_name;
            this.datasetId = row.dataset_id;
        }
    }

    getDuplicate(newRightGridRow: any) {
        return this.mappedDataSetAttributes.find(existingMapping => {
            return existingMapping.logical_name === newRightGridRow.logical_name &&
                existingMapping.target_attribute === newRightGridRow.target_attribute &&
                existingMapping.dataset_name === newRightGridRow.dataset_name;
        });
    }

    isMappingValid(): boolean {
        return (this.hasActiveJob || !this.mappedDataSetAttributes.length) ? false : true;
    }

    updateMappingLeftGridColumn(addRow: boolean) {
        let prevValue: number;
        if (addRow) {
            prevValue = this.mappedDataSetAttributes.length - 1;
        } else {
            prevValue = this.mappedDataSetAttributes.length + 1;
        }

        if (this.leftGridMappingHeader === undefined) {
            const allHeaders = $('.slick-header-column');
            for (let i = 0; i < allHeaders.length; i++) {
                const existingTooltip = $(allHeaders[i]).attr('title');
                if (existingTooltip === `Mapping(${prevValue})`) {
                    this.leftGridMappingHeader = allHeaders[i];
                }
            }
        }
        if (this.leftGridMappingHeader) {
            $(this.leftGridMappingHeader).attr('title', `Mapping(${this.mappedDataSetAttributes.length})`);
            const headerCell = this.leftGridMappingHeader.children[0];
            if (headerCell) {
                $(headerCell).html(`Mapping(${this.mappedDataSetAttributes.length})`);
            }
        }
    }

    getEntityName() {
        if (this.projectDetail.entity_name) {
            return this.projectDetail.entity_name;
        }
    }

    saveDataSetMapping(input: any, opcode: string, selectedDataSetId: number) {
        // create dataset from mapping
        const attribute = Object.assign({}, input);
        const dataSet = new Dataset();
        dataSet.dataset_id = selectedDataSetId;
        dataSet.attributes = [];
        attribute.opCode = opcode;
        dataSet.attributes.push(attribute);
        // save in DB
        this.updateProjectMapping([dataSet]);
    }

    // TO Do: Code clean if not required
    populateUpdateDatasetAttributeMap(input: any, opcode: string, selectedDataSetId: number): boolean {
        const selectedDataset = this.updatedDataSetAttributes.find(dataset => dataset.dataset_id === selectedDataSetId);
        if (selectedDataset) {
            const updateAttribute = selectedDataset.attributes.find(attribute => attribute.system_attribute === input.system_attribute &&
                attribute.target_attribute === input.target_attribute);
            if (updateAttribute && opcode !== updateAttribute.opCode) {
                // Filter it
                selectedDataset.attributes = selectedDataset.attributes.
                    filter(attribute => !(attribute.system_attribute === input.system_attribute &&
                        attribute.target_attribute === input.target_attribute));
            } else {
                input.opCode = opcode;
                selectedDataset.attributes.push(input);
            }
        } else {
            // Add Dataset first time
            const dataSet = new Dataset();
            dataSet.dataset_id = selectedDataSetId;
            dataSet.attributes = [];
            input.opCode = opcode;
            dataSet.attributes.push(input);
            this.updatedDataSetAttributes.push(dataSet);
        }
        return true;
    }

    getLeftGridCount(): number {
        if (this.leftGrid) {
            const filterRows = this.leftGrid.dataView.getItems().filter(row => !row.groupedRow);
            return filterRows.length;
        } else {
            return this.defaultUnMappedEntityAttributes.length;
        }
    }

    isPropRootKey(rootObject, prop) {
        return rootObject.hasOwnProperty(prop);
    }

    prepareEntityMapping(dataSet) {
        let counter = -1;
        const chaninedItems = [];
        dataSet.filter(function (item) {
            if (item['data_type'] === 'Whole') { chaninedItems.push(...item['chained']); } return item['data_type'] === 'Whole';
        });
        for (let chainedIndex = 0; chainedIndex < chaninedItems.length; chainedIndex++) {
            const chainedElement = chaninedItems[chainedIndex];
            // loop parent obj
            for (let parentIndex = 0; parentIndex < dataSet.length; parentIndex++) {
                const parentElement = dataSet[parentIndex];
                if ((chainedElement.attribute_id === parentElement.attribute_id) &&
                    (!parentElement.hasOwnProperty('chained') || !chainedElement.hasOwnProperty('chained'))) {
                    dataSet.splice(parentIndex, 1);
                    break;
                }
            }
        }
        this.defaultEntityAttributes = [];
        dataSet.forEach((attribute) => {
            counter += 1;
            attribute['indent'] = 0;
            attribute['parent'] = null;
            attribute['sequenceIndex'] = counter;
            attribute['parentattributeid'] = -1;
            attribute['_collapsed'] = false;
            this.defaultEntityAttributes.push(attribute); // expand root element
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === 'Whole' && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['indent'] = 1;
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                    chainElement['parentattributeid'] = attribute['attribute_id'];
                    chainElement['_collapsed'] = false;
                    this.defaultEntityAttributes.push(chainElement);
                }
            }
        });
    }

    buildParentAndChildRelation(dataSource) {
        let counter = -1;
        dataSource.forEach((attribute) => {
            counter += 1;
            attribute['sequenceIndex'] = counter;
            if (this.isPropRootKey(attribute, 'chained') && attribute['data_type'] === 'Whole' && attribute.chained.length > 0) {
                for (let chainIndex = 0; chainIndex < attribute.chained.length; chainIndex++) {
                    counter += 1;
                    const chainElement = attribute.chained[chainIndex];
                    chainElement['parent'] = { id: attribute['sequenceIndex'] };
                    chainElement['sequenceIndex'] = counter;
                }
            }
        });
        dataSource.forEach((attribute) => {
            const filteredData = dataSource.filter(data => data.parentattributeid === attribute.attribute_id);
            if (filteredData.length > 1) {
                const index = dataSource.findIndex(item => item.attribute_id === attribute.attribute_id);
                for (let chainIndex = 0; chainIndex < filteredData.length; chainIndex++) {
                    const chainElement = filteredData[chainIndex];
                    chainElement['parent'] = { id: index };
                }
            }
        });
    }
    popupoutput(message){
        this.showmodelpopup = message;   
      }

    getMappedAttributes() {
        const datasetIDs = [];
        this.projectDetail.datasets.forEach(ds => {
            datasetIDs.push(ds.dataset_id);
        });
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.projectDetail.catalog_id,
            entity_id: this.projectDetail.entity_id,
            dataset_ids: datasetIDs.toString()
        };
        this.service.getMappings(data, this.projectDetail.project_id).subscribe(resp => {
            this.rightGridDataSource = [];          
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsForCreate(resp);
        }, error => {
            this.setDefaultRightGridDataSources();
            this.initRightGridRowsForCreate([]);
        });
    }
   
    onUploadDatasetEntityMapping(uploadObj) { 
        if (uploadObj && uploadObj.isUploadComplete && !uploadObj.isPartialUpload) {
            $('#uploadFileSelectFileModal').modal('hide');
            this.openFileUploadImport = false;
        }
        if ((uploadObj && uploadObj.data && uploadObj.data.length) || (uploadObj && uploadObj.filteredImportedData && uploadObj.filteredImportedData.length)) {
            this.handleDatasetEntityMappingOnImport(uploadObj.data, uploadObj.filteredImportedData);
        }
    }

    handleDatasetEntityMappingOnImport(datasetEntityMapping, filteredImportedData) {
        this.isDataReady = false;
        if (datasetEntityMapping && datasetEntityMapping.length) {
            
            datasetEntityMapping.forEach((datasetEntityRecord) => {
                let entityRowData = {
                    id: Math.random(),
                    logical_name: datasetEntityRecord.attributeName,
                    dataset_name: datasetEntityRecord.datasetName,
                    target_attribute: datasetEntityRecord.datasetColumn,
                    system_attribute: datasetEntityRecord.attributePhysicalName,
                    dataset_column_id: datasetEntityRecord.datasetColumnId,
                    attribute_id: datasetEntityRecord.attributeId,
                    dataset_id: datasetEntityRecord.datasetId,
                    sort_order: datasetEntityRecord.sort_order,
                    entity_id: datasetEntityRecord.entityId,
                    authoritativeness: datasetEntityRecord.authoritativeness,
                }
                const existingMapping = this.getDuplicate(entityRowData);
                if (!existingMapping && this.rightGrid && this.leftGrid) {
                
                    let droppedIndex = this.rightGrid.dataView.getItems().findIndex((entityGridData) => {
                        return entityGridData.attribute_id === datasetEntityRecord.attributeId;
                    })
                    let draggedIndex = this.leftGrid.dataView.getItems().findIndex((datasetColumn)=> {
                        return datasetColumn.dataset_id === datasetEntityRecord.datasetId && datasetColumn.dataset_column_id === datasetEntityRecord.datasetColumnId;
                    })
                    
                    if (draggedIndex > -1 && droppedIndex >-1) {
                        this.onDrop(draggedIndex, droppedIndex);
                    }
                }
            })
        }
        this.isDataReady = true;
        console.log('existing mapping/l; ', this.existingMappingRemovedState);
        if ((this.existingMappingRemovedState && this.existingMappingRemovedState.length) && 
        (filteredImportedData && filteredImportedData.length)) {
            this.existingMappingRemovedState.forEach((mappingsState, index) => {
                let isDatasetAttributeMappingRemoved = filteredImportedData.find((importedAttributeData) => {
                    return importedAttributeData && importedAttributeData.attributeName === mappingsState.logical_name
                    && importedAttributeData.datasetColumn === mappingsState.target_attribute &&
                    importedAttributeData.datasetId === mappingsState.dataset_id &&
                    importedAttributeData.datasetColumnId === mappingsState.dataset_column_id;
                })
                
                if (isDatasetAttributeMappingRemoved) {
                    let entityRowData = {
                        id: Math.random(),
                        logical_name: mappingsState.logical_name,
                        dataset_name: mappingsState.dataset_name,
                        target_attribute: mappingsState.target_attribute,
                        system_attribute: mappingsState.system_attribute,
                        dataset_column_id: mappingsState.dataset_column_id,
                        attribute_id: mappingsState.attribute_id,
                        dataset_id: mappingsState.dataset_id,
                        sort_order: mappingsState.sort_order,
                        entity_id: mappingsState.entity_id,
                    }
                    
                    const existingMapping = this.getDuplicate(entityRowData);
                    
                    if (!existingMapping && this.rightGrid && this.leftGrid) {
                    
                        let droppedIndex = this.rightGrid.dataView.getItems().findIndex((entityGridData) => {
                            return entityGridData.attribute_id === mappingsState.attribute_id;
                        })
                        let draggedIndex = this.leftGrid.dataView.getItems().findIndex((datasetColumn)=> {
                            return datasetColumn.dataset_id === mappingsState.dataset_id && datasetColumn.dataset_column_id === mappingsState.dataset_column_id;
                        })

                        if (draggedIndex > -1 && droppedIndex >-1) {
                            this.onDrop(draggedIndex, droppedIndex);
                        }
                    }
                }
            })
        }
        this.existingMappingRemovedState = [];
    }

    closeUploadModel(){
        $('#uploadFileSelectFileModal').modal('hide');
        this.is_upload_complete = false
    //     this.getTenentGroup(this.uploaded_files);
    //    // this.getDataSourceFiles(this.datasourceId,this.uploaded_files);    
    }

    cancelUpload() {
        this.clearModal =  Math.random(); 
        $('#uploadFileSelectFileModal').modal('hide'); 
        //this.datasetSvc.isFileUploaded.next(true);
    }
    previousUpload() {
        this.showTab = 'select-file';
    }

    openModalImport(modal) {
        this.openFileUploadImport = true;
        setTimeout(() => {
            $('#' + modal).modal('show');
        }, 200);
        
    }

    onFileUploadModelClose(status) {
        this.openFileUploadImport = false;
        
    }
}
