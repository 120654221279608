import { Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from '../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

export const assignmentStatusFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    function getTrainResolveModelColorByStatus() {
        return zettaUtilsInstance.getTrainResolveModelColorByStatus(value);
    }

    return `<div class="" style='height: 40vh;border-left:6px solid ${getTrainResolveModelColorByStatus()}'>
                <span class="ml-2" title='${value}'>${value}</span>
            </div>`;
};

