
export class Datasource {
    name: string;
    description: string;
    user_id: string;
    group_id: string;
    tenant_id: string;
    lookup_id: string;
    datasource_id: string;
    connection_method: string;
    database_system: string;
    driver: string;
    password: string;
    server: string;
    user_name: string;
    database: string;

    constructor() { }
}

export interface IEntityPrams {
    userId?: string;
    groupId?: string;
    typeId?: string;
    sourceId?: string;
    payload?: object;
    tenant_id?: string;
    pageno?: string;
    perpage?: number;
}
