
import { Column, Formatter } from 'angular-slickgrid';

export const radioButtonFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
  value ?
    `<div class="div-checkbox radio-item">
  <input type="radio" id="key_${row}" checked>
  <label class="m-0 pointer" ></label>
</div>` :
    `<div class="div-checkbox radio-item table-radio">
    <input type="radio" name="key" id="key_${row}">
     <label class="m-0 pointer radio-button"></label>
  </div>`;
