import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';
import { ZettaUtils } from '../../zettaUtils';
const zettaUtilsInstance = new ZettaUtils();

@Component({
    selector: 'name-link-formatter',
    template: `<div class="text-center w-100 text-bold" title="{{value}}">{{value}}</div>`,
    styles: [
    ]
})
export class NumberFormatterComponent implements ICellRendererAngularComp {
    value: any;
    params: any;

    agInit(params: ICellRendererParams): void {
        if (params.data) {
            this.value = params.value ? zettaUtilsInstance.commarization(params.value, 1) : 0;
        }
    }
    refresh(params: ICellRendererParams): boolean {
        return false;
    }


}
