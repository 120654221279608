import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ProjectService } from '../../../zettasense/content/project/project.service';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { dateFormatter } from '../../../common/shared/formatters/dateFormatter';
import { assignmentStatusFormatter } from '../../../common/shared/formatters/assignmentStatusFormatter';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { actionsFormatter } from '../../../common/shared/formatters/actionsFormatter';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/common/components/message/message.service';
import { Project } from 'src/app/zettasense/content/project/project.model';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZsClContentService } from '../zs-cl-content.service';
import { mappingConfidenceFormatter } from 'src/app/common/shared/formatters/mappingConfidenceFormatter';
import { BaseService } from 'src/app/common/services/base-service';
import { revieweCheckBoxFormattter } from 'src/app/common/shared/formatters/reviewerCheckBoxFormatter';
import { aprooveerCheckBoxFormattter } from 'src/app/common/shared/formatters/approverCheckBoxFormatter';
import { disabledCheckBoxFormatter } from 'src/app/common/shared/formatters/disableCheckBoxFormatter';
declare var $: any;


const nameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{  
  return `<div class="text-truncate"><label title="${value}" class="blueLink text-truncate wd-95">${value}</label></div>`;
}
const taskNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{  
  const aclciveLink ='blueLink';
  return `<div class="text-truncate"><label title="${value}" class="text-truncate wd-95 ${aclciveLink}">${value}</label></div>`;
}

const dsNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{  
  if(value) {
    return `<div class="text-truncate"><label title="${value}" class="blueLink text-truncate wd-95">${value}</label></div>`;
  } else {
    return `<div class="font-wieght disable-rule text-center"><span class="inactive-text mr-3">n/a</span></div>`;
  }
}

const TaskNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>`<label title="${value}" class="blueLink text-truncate div-wd-100">${value}</label>`;

@Component({
  selector: 'zetta-zs-cl-tasks',
  templateUrl: './zs-cl-tasks.component.html',
  styleUrls: ['./zs-cl-tasks.component.scss']
})
export class ZsClTasksComponent implements OnInit {
  tasksDataSource: any;
  leftTasksDataSource: any = []; // initialzed to empty array since it is erroring in console and some UI break untill data is loading
  rightTasksDataSource: any;
  taskGridColumnsDef: any;
  rightGridColumnsDef: any;
  leftTaskGridColumnsDef: any;
  taskGridOptions: any;
  leftTaskGridOptions: any;
  rightTaskGridOptions: any;
  public taskGrid: AngularGridInstance;
  public leftTaskGrid: AngularGridInstance;
  public rightTasksGrid: AngularGridInstance;
  total = 0;
  page = 1;
  limit = 100;
  isOpenTaskLoading = true;
  hasScrolled = false;
  noData = false;
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  allTasktableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  taskType: string;
  _userInfo;
  viewOnlyTasks: Boolean = false;
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedRowDataSource;
  selectedTaskStatus: string;
  projectDetail = new Project();
  triggerFeedbackModal: Boolean = false;
  hasLeftUsersSelected: Boolean = false;
  viewTaskAssignments = false;
  rightDataFetched = false;
  projId;
  myTotalTask=0;
 
  selectedSubConcept:string;
  isSubConceptLoading=true;  
  selectedSubConceptRow:string;
  selected_record_id:number;
  isSubConcept=false;
  selectedPridiction=[]; 
  subConceptList=[];
  subConceptColumn: object =[];
  selectedColumn:string;
  subConceptResult=[];
  feedbackAdded=false;
  trainModalPayload;
  selected_task_id:number;
  classifier:string;
  trainModalPage = 1;
  msgHeader:string;
  msgText:string;    
  showConfirmBox:boolean;
  hasDeleted:boolean;
  showConceptParserModel=false;
  project_id:number;
  summaryHasChanged=false;
  mappedColumntableSettings: object = { 'height': "100%", 'width': "100%" };
  datasetTableSettings: object = { 'height': "100%", 'width': "100%" };
  mappedColumns;
  datasets;
  isLoadingDatasets = false;
  hasDatasetsScrolled = false;
  totalDatasets = 0;
  isFiltering = false;
  mappedColumnList:any;
  hasMaapedColumnScrolled = false;
  public gridMappedColumns: AngularGridInstance;
  gridDatasets: AngularGridInstance;
  isLoadingMappedColumns = false;
  noDataMappedColumns = false;
  totalMappedColumns;
  mappedColumnPageNo=1;
  selectedMappedColumn;
  mappedColumnsTableColDef: any;
  datasetsTableColDef: any;
  conceptName;
  catalogId;
  attributeId;
  objectId;
  conceptId;
  showDownvoteModal=false;
  showDatasetsDownvoteModal = false;
  datasetPage=1;
  datasetId:any;
  datasetColumnId;
  mappingType;
  dsColumnName="";
  removing_attribute_id;
  gridDataOptions: any;
  dsName;
  showmodelpopup=false;
  originalData = [];
  projectType: String = '';
  feedbackChanged = false;
  enableFinishTask: Boolean = false;
  dataRes;
  is_upload_complete = false;
  clearModal: Number;
  taskAssignDetails = {};
  selectedRow: any;
  assignType: string = 'classifyProject';
  selectedProjects = [];
  validatedSelectedProjects: boolean = false;
  is_task_completed = false;
  classify_project_users = [];
  fu_type_tasks;
  _userRole;
  is_reviewer = false; 
  is_approver = false;
  is_pending_for_approval = false;
  enableApprove = false;
  enableApproved = false;
  taskReviewerName: string = '';
  taskReviewerDate: string = '';
  task_id: number;
  task_type_id: number;
  task_type: string;
  task_name: string;
  catalog_name: string;
  entity_name: string;
  total_page: number;
  current_row: any;
  disableConceptParserFeedback: boolean;
  allowVotingForCatalogTasks: boolean = true;
  classifyAllTasksGridState = {
    sort: null,
    search: ''
  }
  classifyAllMyTasksGridState = {
    sort: null,
    search: ''
  }

  constructor(public service: ProjectService,
              public zettaUtils: ZettaUtils, 
              private activatedRoute: ActivatedRoute,
              public viewContainerRef: ViewContainerRef,
              private messageSvc: MessageService, 
              private _router:Router, 
              private catalogSvc: ZsClContentService,
              private observableSvc: BaseService) { }

  ngOnInit() {
    this.fu_type_tasks = AppGlobals.FU_TYPE_TASKS;
    this._userInfo = this.service.getObjectFromSession('userInfo');
    this._userRole = this.service.getObjectFromSession('userRole');
    if(this._userRole.role_id === AppGlobals.CLASSIFY_PROJECT_APPROVER) {
      this.is_approver = true;
    }
    if(this._userRole.role_id === AppGlobals.CLASSIFY_PROJECT_REVEIWER) {
      this.is_reviewer = true;
    }
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.activatedRoute.queryParams.subscribe(res => {
      this.viewOnlyTasks = res['ViewAllTasks'];
    })
  
    if(this.viewOnlyTasks) {
      this.initTaskColDef();
      this.loadTasksData(this.page, this.limit);
    }else {
      this.loadGridLayout();
      this.loadLeftTasksData(this.page, this.limit);
     }
     this.gridDataOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
    this.initMappedColDef();
    this.initDatasetsColDef();

    this.taskAssignDetails = {
      title: 'All Users',
      selectImgPath: '../../../../assets/images/common/select-data1.png',
      gridColDef: this.rightGridColumnsDef,
      getUserUrl: '',
      homeUrl: '/zs-cl/home',
      user_id: this._userInfo['user_id'],
    };
    this.observableSvc.showAssignUsers.subscribe(toggle => {
      if (toggle) {
        this.viewTaskAssignments = toggle;
      } else {
        this.viewTaskAssignments = false;
      }
    });
  }

  initTaskColDef() {
    this.taskGridColumnsDef = [
    {
      'displayname': '',
      'physicalname': 'actions',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-right',
      'formatter': actionsFormatter,
      'maxWidth': 12,
      }, {
        'displayname': 'Id',
        'physicalname': 'task_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'maxWidth': 100,
      }, {
      'displayname': 'Task Type',
      'physicalname': 'task_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
    }, {
      'displayname': 'Catalog / Project Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 170,
      'formatter': blueTextFormattter
    }, {
      'displayname': 'Task Name',
      'physicalname': 'task_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 170,
      'formatter': nameFormattter
    }, {
      'displayname': 'Role',
      'physicalname': 'role_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90
    }, {
      'displayname': 'Task Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': assignmentStatusFormatter,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
      'formatter': dateFormatter
    }];

    this.taskGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      enableCellNavigation: false,
      enableRowSelection: true,
      CheckboxSelector: false,
      rowHeight: 34
    };
  }

  loadGridLayout() {
    this.leftTaskGridColumnsDef = [
      {
        'displayname': 'Id',
        'physicalname': 'task_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100,
      }, {
        'displayname': 'Task Type',
        'physicalname': 'task_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
      }, {
        'displayname': 'Catalog / Project  Name',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'args': false,
        'minWidth': 110,
        'formatter': blueTextFormattter
      }, {
        'displayname': 'Task Name',
        'physicalname': 'task_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 110,
        'formatter': TaskNameFormattter
      },
      {
        'displayname': 'Assigned To',
        'physicalname': 'assigned_to_user',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 50
      },
      {
        'displayname': 'Role',
        'physicalname': 'role_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100
      }, {
        'displayname': 'Task Status',
        'physicalname': 'task_status',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': assignmentStatusFormatter,
      }];

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'args': false,
      'minWidth': 50,
      'params': { 'isDisplayDesc': false },
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 50,
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'reviewer',
      'sortable': true,
      'datatype': 'DateTime',
      'filterable': true,
      'minWidth': 10,
      'formatter': revieweCheckBoxFormattter
    },
    {
      'displayname': 'Approver',
      'physicalname': 'approver',
      'sortable': true,
      'datatype': 'DateTime',
      'filterable': false,
      'minWidth': 10,
      'formatter': aprooveerCheckBoxFormattter
    }
  ];

  this.leftTaskGridOptions = {
    enableGridMenu: false,
    selectable: true,
    enableFiltering: true,
    enableCellNavigation: false,
    enableRowSelection: true,
    CheckboxSelector: true,
    rowHeight: 34,
    multiSelectable: true,
  };

  this.rightTaskGridOptions = {
    enableGridMenu: false,
    selectable: false,
    enableFiltering: true,
    enableCellNavigation: false,
    enableRowSelection: false,
    CheckboxSelector: false,
    rowHeight: 34,
    multiSelectable: false,
  };

  }

  onGridStatusChanged(allTaskGridState) {
    this.classifyAllTasksGridState.sort = allTaskGridState.sort ? allTaskGridState.sort: null;
  }

  onGridStatusChangedForAllMyTasks(allMyTaskGridState) {
    this.classifyAllMyTasksGridState.sort = allMyTaskGridState.sort ? allMyTaskGridState.sort: null;
  }

  loadTasksData(pageno, limit = 1) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tenants/${this._userInfo['tenant_id']}/classify/tasks?opentasks=false&pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter="";
    if(sessionStorage.getItem("serverfilter")){
       serverFilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
       //clearing sort of classify SOC feedback sort
       sortfilter = sortfilter && (sortfilter.includes('confidence') || sortfilter.includes('class')) ? "" : sortfilter;
    }    
    let isCustomGridStateHandling = this.classifyAllMyTasksGridState.sort && this.classifyAllMyTasksGridState.sort != '' ? this.classifyAllMyTasksGridState.sort: null;
    this.service.getAllOpenTasks(this._userInfo['user_id'], this._userInfo['tenant_id'], pageno, limit,false, serverFilter, sortfilter, isCustomGridStateHandling).subscribe((data) => {
      if(data) {
        this.tasksDataSource = data['currentpage'];
        if (this.tasksDataSource.length === 0) {
          this.noData = true;
        }
        this.myTotalTask = data['totalrecords'];
        let concatedRows = this.tasksDataSource;
        
        if (this.taskGrid) {
          concatedRows = [...this.taskGrid.dataView.getItems(), ...this.tasksDataSource];
          this.tasksDataSource.forEach(element => {
            element.id = Math.random();
          });
          this.tasksDataSource = concatedRows;
          this.taskGrid.dataView.setItems(concatedRows);
          this.taskGrid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
        this.isOpenTaskLoading = false;
      }
    }, err => {
      this.isOpenTaskLoading = true;
    });
  }


  loadLeftTasksData(pageno, limit = 1) {
    this.isOpenTaskLoading = true;
    this.allTasktableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/tenants/${this._userInfo['tenant_id']}/classify/tasks?opentasks=false&allusers=true&pagination=true&pageno=${1}&pagesize=${limit}`;
    const data ={
      user_id:this._userInfo['user_id'],
      tenant_id:this._userInfo['tenant_id'],
      pageno:pageno,
      limit:limit
    };
    let serverFilter="";
   if(sessionStorage.getItem("serverfilter")){
      serverFilter=sessionStorage.getItem("serverfilter");
   }
   let sortfilter="";
   if(sessionStorage.getItem("sortfilter")){
      sortfilter=sessionStorage.getItem("sortfilter");
      //clearing sort of classify SOC feedback sort
      sortfilter = sortfilter && (sortfilter.includes('confidence') || sortfilter.includes('class')) ? "" : sortfilter;
   }   
   let isCustomGridStateHandling = this.classifyAllTasksGridState.sort && this.classifyAllTasksGridState.sort != '' ? this.classifyAllTasksGridState.sort: null;
   this.service.getAppClassifyTasks(this._userInfo['user_id'], this._userInfo['tenant_id'], pageno, limit,false, serverFilter, sortfilter, isCustomGridStateHandling).subscribe((data) => {
      this.leftTasksDataSource = data['currentpage'];
      if (this.leftTasksDataSource.length === 0) {
        this.noData = true;
      }
      this.leftTasksDataSource.forEach(element => {
         element.enableLink = element.assigned_to == this._userInfo['user_id'] ? true : false;
       });
      this.isOpenTaskLoading = false;
      this.total = data['totalrecords'];
      let concatedRows = this.leftTasksDataSource;
      if (this.leftTaskGrid) {
        concatedRows = [...this.leftTaskGrid.dataView.getItems(), ...this.leftTasksDataSource];
        this.leftTasksDataSource.forEach(element => {
          element.id = Math.random();
        });
        this.leftTasksDataSource = concatedRows;
        this.leftTaskGrid.dataView.setItems(concatedRows);
        this.leftTaskGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
    }, err => {
      this.isOpenTaskLoading = false;
    });
  }

  loadRightTasksData() { 
    this.projId = this.leftTasksDataSource[0]['reference_object_id'];
    this.service.getTaskAssignments(this._userInfo, this.projId).subscribe((data) => {
       this.rightTasksDataSource = data;
       this.rightDataFetched = true;
 
       if (this.rightTasksDataSource.length === 0) {
         this.noData = true;
       }
       let concatedRows: string[] = this.rightTasksDataSource;
       if (this.rightTasksGrid) {
         concatedRows = [...this.rightTasksGrid.dataView.getItems(), ...this.rightTasksDataSource];
         this.rightTasksDataSource.forEach(element => {
           element['id'] = Math.random();
         });
         this.rightTasksDataSource = concatedRows;
         this.rightTasksGrid.dataView.setItems(concatedRows);
         this.rightTasksGrid.gridService.setSelectedRows([]);
         this.hasScrolled = false;
       }
     }, err => {
       this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
      //  this.isRightLoading = false;
     });
  }

  taskGridReady(grid) {
    this.taskGrid = grid;
  }

  rightTaskGridReady(grid) {
    this.rightTasksGrid = grid;
  }

  taskLeftGridReady(grid) {
    const parent = this;
    parent.leftTaskGrid = grid;
    parent.leftTaskGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    parent.selectedProjects = [];
    args.rows.forEach(ind => {
      const row = parent.leftTaskGrid.gridService.getDataItemByRowIndex(ind);
      parent.selectedProjects.push(row);
    });
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
      parent.selectedRow = parent.leftTaskGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.observableSvc.showAssignUsers.next(false);
      this.viewTaskAssignments = false;
      if(parent.selectedProjects && parent.selectedProjects.length>0) {
        parent.checkValidates(parent.selectedProjects);
        if(parent.selectedProjects[0].catalog_id) {
          parent.assignType = 'catalogTask';
          parent.taskAssignDetails['catalogId'] = parent.selectedProjects[0].reference_object_id;
          parent.taskAssignDetails['getUserUrl'] = `users/${parent._userInfo['user_id']}/catalogs/${parent.taskAssignDetails['catalogId']}/users`;
          parent.taskAssignDetails['updateURL'] = `users/${parent._userInfo['user_id']}/catalogs/${parent.taskAssignDetails['catalogId']}/tasks/users?taskTypeId=${parent.selectedProjects[0]['task_type_id']}`;
        } else {
          parent.taskAssignDetails['getUserUrl'] = `users/${parent._userInfo['user_id']}/tenants/${parent._userInfo['tenant_id']}/classify/projects/${parent.selectedProjects[0].reference_object_id}/users`;
          parent.taskAssignDetails['updateURL'] = `users/${parent._userInfo['user_id']}/tenants/${parent._userInfo['tenant_id']}/classify/projects/${parent.selectedProjects[0].reference_object_id}/tasks`;
        }
      } else {
        parent.validatedSelectedProjects = false;
      }
      parent.checkForTaskTypes();
      parent.taskAssignDetails['gridColDef'] = parent.rightGridColumnsDef;
      });
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadTasksData(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.loadTasksData(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.loadTasksData(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  canDisableConceptParserFeedback(taskStatus, assignedTo) {
    let loggedInUserDetails = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;

    if (taskStatus == AppGlobals.TASK_COMPLETED) {
      return true;
    }

    if (taskStatus === AppGlobals.TASK_REVIEWED_STATUS) {
      return true;
    }
    
    if (taskStatus === AppGlobals.TASK_IN_REVIEW) {
      return false;
    }

    if (taskStatus === AppGlobals.TASK_PENDING_FOR_APPROVAL || taskStatus === AppGlobals.TASK_PENDING_APPROVAL) {
      if (loggedInUserDetails && loggedInUserDetails.user_id && loggedInUserDetails.user_id === assignedTo) {
        return false;
      }
      return true;
    }
    return false;
  }

  onTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskGrid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      this.enableApprove = false;
      this.enableApproved = false;
      this.task_id = row.task_id;
      this.task_type_id = row.task_type_id;
      this.getTaskReviewer();
      if (eventEle.target.title === row.name) {
        this.navigateToClassifyProjectOrCatalogScreen(row.reference_object_id, row.task_type, row.task_type_id, row.semantic_id);
      }       
       if (eventEle.target.title === row.task_name) {       
         this.summaryHasChanged = false;
         this.is_task_completed = false;      
         if(row.task_status == AppGlobals.TASK_PENDING_APPROVAL || row.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          this.is_pending_for_approval = true;
        }
        if(row.task_type_id === AppGlobals.CLASSIFY_PROJECT_TASK_TYPE && row.project_type_id === AppGlobals.CONCEPT_PARSER){
          this.showConceptParserModel =true;
          this.selected_record_id =row.record_id;
          this.selected_task_id = row.task_id;
          this.selectedColumn=row.task_name;
          this.project_id = row.reference_object_id;
          this.getPridictionList(row.task_status, row.assigned_to);
        } else if (row.task_type == AppGlobals.CLASSIFYCATALOGCONCEPTASK) { // should trigger mapped columns modal with 5 cols
          sessionStorage.removeItem('serverfilter');
          sessionStorage.removeItem('sortfilter');
          this.mappedColumns = [];
          this.isLoadingMappedColumns = true;
          this.conceptName = row.task_name;
          this.catalogId = row.catalog_id;
          this.conceptId = row.attribute_id;
          this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
          this.projectType = row.task_type;
          this.task_type = row.task_type;
          this.task_name = row.task_name;
          this.catalog_name = row.name;
          this.entity_name = row.entity_name;
          this.getMappedColumns(1, this.limit,this.shouldDisableCatalogTask(row.task_status, row.assigned_to));
          $('#mapped_columns_modal').modal('show');
          } else if (row.task_type == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) { // should trigger datasets modal with 3 cols
          sessionStorage.removeItem('serverfilter');
          sessionStorage.removeItem('sortfilter');
          this.datasets = [];
          this.isLoadingDatasets = true;
          this.conceptName = row.task_name;
          this.catalogId = row.catalog_id;
          this.conceptId = row.attribute_id;
          this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
          this.projectType = row.task_type;
          this.task_type = row.task_type;
          this.task_name = row.task_name;
          this.catalog_name = row.name;
          let shouldDisableTskvoting = this.shouldDisableCatalogTask(row.task_status, row.assigned_to);
          this.allowVotingForCatalogTasks = !shouldDisableTskvoting;
          this.getDatasets(row.semantic_id, this.page, this.limit, this.shouldDisableCatalogTask(row.task_status, row.assigned_to));
          $('#datasets_modal').modal('show');
        } else {
          this.getClassifyProject(row);
        }
      }
    }
  }
  getClassifyProject(row) {
    const data = {
      user_id: this._userInfo['user_id'],
      tenant_id: this._userInfo['tenant_id'],
      project_id: row.reference_object_id
    };
    this.catalogSvc.getclassifyProject(data).subscribe(responseList => {
      this.classify_project_users = responseList.users;
      let userDetails = this.classify_project_users.find(user => user.user_id === this._userInfo['user_id']);
      if (userDetails) {
        const isReviewdUser = userDetails.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_REVEIWER);
        if (row.task_status === AppGlobals.TASK_COMPLETED) {
          this.is_task_completed = true;
        }
        if (isReviewdUser && row.task_status === AppGlobals.TASK_REVIEWED_STATUS) {
          this.is_task_completed = true;
        }
      }
      
      this.selectedClusterId = row.cluster_id;
      this.selectedTaskName = row.task_name;
      this.selectedTaskType = row.task_type;
      this.projectDetail.project_id = row.project_id;
      this.taskType = row.task_type;
      this.selectedTaskStatus = row.task_status;
      this.selectedRowDataSource = row;
      this.triggerFeedbackModal = true;  
    }, err => {
    });
  }

  navigateToClassifyProjectOrCatalogScreen(reference_object_id, task_type, task_type_id, semantic_id?): void{
    if (task_type.toLowerCase() == AppGlobals.CLASSIFICATION_PROJECT.toLowerCase()) {
        this._router.navigate(['/zs-cl/projects', reference_object_id],
        { queryParams: { project_type: task_type, project_type_id: task_type_id } });
        
    } else if (task_type.toLowerCase() == AppGlobals.CLASSIFYCATALOGSEMANTICTASK.toLowerCase() || task_type.toLowerCase() == AppGlobals.CLASSIFYCATALOGCONCEPTASK.toLowerCase()) {
        this._router.navigate(['zs-cl/catalogs',reference_object_id],
        { queryParams: { project_type: task_type, project_type_id: task_type_id } });
    }
  }

  onLeftTaskGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.leftTaskGrid.gridService.getDataItemByRowIndex(args.row);
    this.enableApprove = false;
    this.enableApproved = false;
    this.task_id = row.task_id;
    this.task_type_id = row.task_type_id;
    this.getTaskReviewer();
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this.navigateToClassifyProjectOrCatalogScreen(row.reference_object_id, row.task_type, row.task_type_id, row.semantic_id);
      }       
      if (eventEle.target.title === row.task_name) {
          this.summaryHasChanged = false;
          this.is_task_completed = false;   
          if(row.task_status == AppGlobals.TASK_PENDING_APPROVAL || row.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL) {
            this.is_pending_for_approval = true;
          }
          if (row.task_type_id === AppGlobals.CLASSIFY_PROJECT_TASK_TYPE && row.project_type_id === AppGlobals.CONCEPT_PARSER) {
            this.showConceptParserModel = true;
            this.selected_record_id = row.record_id;
            this.selected_task_id = row.task_id;
            this.selectedColumn = row.task_name;
            this.project_id = row.reference_object_id;
            this.getPridictionList(row.task_status,row.assigned_to);
          } else if (row.task_type == AppGlobals.CLASSIFYCATALOGCONCEPTASK) { // should trigger mapped columns modal with 5 cols
            sessionStorage.removeItem('serverfilter');
            sessionStorage.removeItem('sortfilter');
            this.mappedColumns = [];
            this.isLoadingMappedColumns = true;
            this.conceptName = row.task_name;
            this.catalogId = row.catalog_id;
            this.conceptId = row.attribute_id;
            this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
            this.projectType = row.task_type;
            this.task_type = row.task_type;
            this.task_name = row.task_name;
            this.catalog_name = row.name;
            this.entity_name = row.entity_name;
            let shouldDisableTskUpvote = this.shouldDisableCatalogTask(row.task_status, row.assigned_to);
            this.allowVotingForCatalogTasks = !shouldDisableTskUpvote;
            this.getMappedColumns(1, this.limit, this.shouldDisableCatalogTask(row.task_status, row.assigned_to));
            $('#mapped_columns_modal').modal('show');
          } else if (row.task_type == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) { // should trigger datasets modal with 3 cols
            sessionStorage.removeItem('serverfilter');
            sessionStorage.removeItem('sortfilter');
            this.datasets = [];
            this.isLoadingDatasets = true;
            this.conceptName = row.task_name;
            this.catalogId = row.catalog_id;
            this.conceptId = row.attribute_id;
            this.objectId = row.semantic_id ? row.semantic_id : row.entity_id;
            this.projectType = row.task_type;
            this.task_type = row.task_type;
            this.task_name = row.task_name;
            this.catalog_name = row.name;

            
            let shouldDisableTskvoting = this.shouldDisableCatalogTask(row.task_status, row.assigned_to);
            this.allowVotingForCatalogTasks = !shouldDisableTskvoting;
            this.getDatasets(row.semantic_id,this.page, this.limit, shouldDisableTskvoting);
            $('#datasets_modal').modal('show');
          } else {
            this.getClassifyProject(row);
          }
      }
    }
  }

  onTaskAssignments(){
    this.viewTaskAssignments = !this.viewTaskAssignments;
    this.observableSvc.showAssignUsers.next(this.viewTaskAssignments);
  }

  closeModalPopup(event) {   
   
    if(event) {
      if(this.originalData && this.originalData.length<1) {
        this.originalData = this.datasets;
      }
      this.triggerFeedbackModal = false; 
      this.showDatasetsDownvoteModal = false;
      $('#thumbs-up-' + this.selectedMappedColumn).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selectedMappedColumn).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selectedMappedColumn).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selectedMappedColumn).addClass('fas fa-thumbs-down');
      if (this.task_type == AppGlobals.CLASSIFYCATALOGCONCEPTASK) {
        this.mappedColumns.forEach(element => {
          if (element.dataset_column_id === this.current_row.dataset_column_id) {
            element.is_down_vote = true;
          }
        });
      }
      if (this.task_type == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
        this.datasets.forEach(element => {
          if (element.dataset_id === this.current_row.dataset_id) {
            element.is_down_vote = true;
          }
        });
      }
    
      this.summaryHasChanged=true;
      // if(!this.is_pending_for_approval) {
      //   this.enableFinishTask = true;
      // };      
    }
   
    if(event){
    if (this.viewOnlyTasks ) {
      this.tasksDataSource = [];
      if (this.taskGrid) {
        this.taskGrid.dataView.setItems([]);
      }
      this.loadTasksData(1, this.limit);
    } else {
      this.leftTasksDataSource = [];
      if (this.leftTaskGrid) {
        this.leftTaskGrid.dataView.setItems(this.leftTasksDataSource);
      }
      this.loadLeftTasksData(1, this.limit);
    }
  }
    this.showDownvoteModal = false;
    this.showDatasetsDownvoteModal = false;
    this.selectedRowDataSource = {};
  }

  checkFinishTaskValidation(tasks, enable) {
    if(enable) {
      if(tasks && tasks.length > 0) {
        const parent = this;
        parent.enableFinishTask = tasks.every(function(ele) {
          return ele.is_up_vote == true || ele.is_down_vote == true;
        });
      }
    }
  }

  checkFinishTaskBtnValidation(tasks, enable, shouldDisableFinishButton?) {
    if(shouldDisableFinishButton) {
      return this.enableFinishTask = false;
    }

    if(enable) {
      if(tasks && tasks.length > 0) {
        let at_least_one_vote = tasks.filter(task => task.is_up_vote == true || task.is_down_vote == true);
        if(this.is_approver && this.is_pending_for_approval) {
          this.enableApprove = at_least_one_vote.length > 0 ? true : false;
        } else {
          this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
        }
      }
    }
  }
  
  updateFeedbackResponse(event) {
    /*if (event) {
      this.page = 1;
      if (this.viewOnlyTasks) {
        console.log('898');
        this.tasksDataSource = [];
        if (this.taskGrid) {
          this.taskGrid.dataView.setItems(this.tasksDataSource);
        }
        this.loadTasksData(this.page, this.limit);
      } else {
        // not calling api after feedback provided
        this.leftTasksDataSource = [];
        if (this.leftTaskGrid) {
          this.leftTaskGrid.dataView.setItems(this.leftTasksDataSource);
        }
        this.loadLeftTasksData(this.page, this.limit);
      }
      this.summaryHasChanged=true;
    }*/
  }
  onLeftTasksScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onLeftNext();
    }
  }

  receiveMyTotalRecords($event) {
    this.myTotalTask = $event;
  }
  receiveTotalRecords($event) {
    this.total = $event;
  }
  onLeftNext() {
    
    this.page++;
    this.loadLeftTasksData(this.page, this.limit);
  }

  goToLeftPage(n: number): void {
    this.page = n;
    this.loadLeftTasksData(this.page, this.limit);
  }

  onLeftPrev(): void {
    this.page--;
    this.loadLeftTasksData(this.page, this.limit);
  }

  // feedback model changes
  getPridictionList(taskStatus,taskAssignedTo) {
    this.catalogSvc.getPredictionList(this.project_id, this.selected_record_id).subscribe(resp => {
      this.classifier = resp['classifier'];
      ($('#classification_tag_model') as any).modal('show');
    }, error => {
      ($('#classification_tag_model') as any).modal('show');
      this.classifier = "";
    });
    this.disableConceptParserFeedback = this.canDisableConceptParserFeedback(taskStatus, taskAssignedTo)
  }
  showSubConceptModal(event) {
    if (event.isSubConcept) {
      this.isSubConceptLoading = true
      this.isSubConcept = event.isSubConcept;
      this.selectedPridiction = event.data;
    }
  }

  closeFeedbackModel(event) {
    if (event) {            
      if(this.viewOnlyTasks) {
        this.tasksDataSource=[];
        if(this.taskGrid){
        this.taskGrid.dataView.setItems(this.tasksDataSource);
        }
        this.page=1;
        this.loadTasksData(this.page, this.limit); 
      }else{
        this.leftTasksDataSource = [];        
        if(this.leftTaskGrid){
          this.leftTaskGrid.dataView.setItems(this.leftTasksDataSource);
        }  
        this.page=1;      
        this.loadLeftTasksData(this.page, this.limit);
      }
      this.summaryHasChanged=true;
          
    }
    this.selectedPridiction=[];
    this.showConceptParserModel = false;
  }

  closeSubConceptModel(data) {
    this.isSubConcept = false;
  }
  clearText(event) {
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }
  onDeletePrediction(data) {
    this.msgHeader = data.messageHeader;
    this.msgText = data.messageText;
    this.showConfirmBox = true;
  }

  onConfirmDelete(event) {
    this.hasDeleted = event;
  }


  addSubConcept(event) {
    this.feedbackAdded = event;
  }

    initMappedColDef() {
      this.mappedColumnsTableColDef = [{
        'displayname': 'Data Source',
        'physicalname': 'datasource_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'minWidth': 70,
      }, {
        'displayname': 'Data Set',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'minWidth': 70,
      }, {
        'displayname': 'Data Set Column',
        'physicalname': 'column_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 70,
      }, {
        'displayname': 'Data Type',
        'physicalname': 'data_type',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 70,
      }, {
        'displayname': 'Mapping Confidence Level',
        'physicalname': 'score',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': mappingConfidenceFormatter,
        'minWidth': 200,
      }];
    }

    initDatasetsColDef() {
      this.datasetsTableColDef = [{
        'displayname': 'Data Source',
        'physicalname': 'datasource_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'minWidth': 70,
      }, {
        'displayname': 'Data Set',
        'physicalname': 'name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'blueLink',
        'minWidth': 70,
      }, {
        'displayname': 'Mapping Confidence Level',
        'physicalname': 'score',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': mappingConfidenceFormatter,
        'minWidth': 380,
        'maxWidth': 390,
      }];
    }
    
    onMappedColumnsGridCreation(grid) {
      this.gridMappedColumns = grid;
    }

    getDatasets(objectId,page, limit, shouldDisableFeedback?) {
      this.objectId = objectId;
      this.isLoadingDatasets = true;
      this.datasetTableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/datasets?pagination=true&pageno=${1}&pagesize=${limit}`;
      var serverfilter = "";
      if(sessionStorage.getItem("serverfilter")){
        var serverfilter = sessionStorage.getItem("serverfilter");
        this.isFiltering = true;
      } else {
        if(this.isFiltering){ 
          page = 1;
        }
        this.isFiltering = false;
      }
    let sortfilter = "";
    if(sessionStorage.getItem("sortfilter")){
        sortfilter = sessionStorage.getItem("sortfilter");
    } 
      const getDataset: any = {
          user_id: this._userInfo['user_id'],
          catalog_id: this.catalogId,
          object_id: this.objectId,
          page: page,
          limit: limit
        };
        this.catalogSvc.getDatasets(getDataset,serverfilter,sortfilter).subscribe((data) => {
            this.datasets = data['currentpage'];
            if (this.datasets.length === 0) {
              this.noData = true;
            }        
            this.datasets.forEach(element => {
                element.id = Math.random();
                element.score100 = (element.score * 100).toFixed();
                element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
                element.shouldDisableFeedback = shouldDisableFeedback;
                if(this.is_reviewer) {
                  element.is_up_vote = false;
                  element.is_down_vote = false;
                }
            });
            this.datasets.sort(((a, b) => b.score100 - a.score100));
            this.totalDatasets = data['totalrecords'];
            this.isLoadingDatasets = false;
            let concatedRows = this.datasets;
            this.total_page = data['totalpages'];
            if (this.gridDatasets && !shouldDisableFeedback) {
              concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
              this.datasets = concatedRows;
              this.gridDatasets.dataView.setItems(this.datasets);
              this.gridDatasets.gridService.setSelectedRows([]);
              this.hasDatasetsScrolled = false;
            }
            this.isLoadingDatasets = false;
            this.checkFinishTaskBtnValidation(this.datasets, true, shouldDisableFeedback);
        }, err => {
          this.isLoadingDatasets = false;
        });
      }

  getMappedColumns(pageNo, limit, shouldDisableFeedback?) {
    this.mappedColumntableSettings['search_url'] = `${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/concepts/${this.conceptId}/mappedcolumns?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.isLoadingMappedColumns = true;
    this.catalogSvc.getConceptsDatasets({ user_id: this._userInfo['user_id'], catalog_id: this.catalogId, object_id: this.objectId, concept_id: this.conceptId, pageNo:pageNo, limit: limit }, serverFilter, sortfilter).subscribe((data) => {
        this.mappedColumns = data['currentpage'];
        this.mappedColumnList =data;
        if (this.mappedColumns.length === 0) {
          this.noDataMappedColumns = true;
        }
        this.totalMappedColumns = data.totalrecords;
        this.isLoadingMappedColumns = false;
        this.mappedColumns.forEach(element => {   
            element.id = Math.random();
            element.score100 = (element.score * 100).toFixed();
            element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
            element.shouldDisableFeedback = shouldDisableFeedback;
            if(this.is_reviewer) {
              element.is_up_vote = false;
              element.is_down_vote = false;
            }
        });
        this.mappedColumns.sort(((a, b) => b.score100 - a.score100));                  
        let concatedRows=this.mappedColumns
      if (this.gridMappedColumns && !shouldDisableFeedback) {
        concatedRows = [...this.gridMappedColumns.dataView.getItems(), ...this.mappedColumns];
        this.mappedColumns = concatedRows;
        this.gridMappedColumns.dataView.setItems(concatedRows);
        this.gridMappedColumns.gridService.setSelectedRows([]);
        this.hasMaapedColumnScrolled = false;
      }
      this.checkFinishTaskBtnValidation(this.mappedColumns, true, shouldDisableFeedback);
    }, err => {
      this.isLoadingMappedColumns = false;
        this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  onMappedColumnsCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridMappedColumns.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.gridMappedColumns.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    this.selectedMappedColumn = args.row;
    this.current_row = row;

    if (!this.allowVotingForCatalogTasks) {
      return;
    }

    if (fieldName === 'score') { 
      if (eventEle.target.className.includes('fa-thumbs-down')) {
        this.dsColumnName = row.column_name;
        this.datasetId = row.dataset_id;
        this.datasetColumnId = row.dataset_column_id;
        this.mappingType = row.mapping_type === undefined ? 'manual' : row.mapping_type;
        this.showDownvoteModal = true;
        this.removing_attribute_id = row.attribute_id;
      }
      const payload: any = {
        "dataset_id": +row.dataset_id,
        "dataset_column_id": +row.dataset_column_id,
        "removing_attribute_id": +row.attribute_id,
        "attribute_id": +row.attribute_id,
        "mapping_type": row.mapping_type === undefined ? 'manual' : row.mapping_type,
        "entity_id": +this.objectId,
        "catalog_id": +this.catalogId
      }
      if (eventEle.target.className.includes('fal fa-thumbs-up')) {
        $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
        $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
        $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
        $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
        if(!this.is_pending_for_approval) {
          this.enableFinishTask = true;
        };
        this.upVote(payload);
      } else if (eventEle.target.className.includes('fas fa-thumbs-up')) {
        $('#thumbs-up-' + args.row).removeClass('fas fa-thumbs-up');
        $('#thumbs-up-' + args.row).addClass('fal fa-thumbs-up');
        this.upVote(payload);
      }
    }
  }

  onDatasetsGridCreation(grid) {
    const parent = this;
    parent.gridDatasets = grid;
    parent.gridDatasets.slickGrid.onSort.subscribe((e, args) => {
    this.page = 1;
    this.hasScrolled = false;
    });
  }

  onDatasetCellClick(e): void {        
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridDatasets.gridService.getDataItemByRowIndex(args.row);
    this.selectedMappedColumn = args.row;
    if (row !== undefined && row !== null) {
      this.current_row = row;
      if (eventEle.target.title === row.name) {
        this.dsName = row.name;           
        this.showmodelpopup = true;
        this.datasetId=row.dataset_id;
      } else if(eventEle.target.className =='fal fa-thumbs-down handDown' || eventEle.target.className =='handDown fal fa-thumbs-down'){
        this.showDatasetsDownvoteModal = true;
        this.datasetColumnId = row.dataset_type_id;
        this.datasetId = row.dataset_id;
      } else if(eventEle.target.className =='fal fa-thumbs-up'){
            const upVotedata = {
              "dataset_id": row.dataset_id,
              "mapping_type": "manual",
              "entity_id": this.objectId,
              "catalog_id": this.catalogId,
              "is_semantic_level_mapping": true
            };
            const payload = {
              user_id: this._userInfo['user_id'],
              tenant_id : this._userInfo['tenant_id'],
              payload: upVotedata
            };
            this.catalogSvc.saveVoteSemanticObject(payload,'up_vote').subscribe(resp => {
              $('#thumbs-up-' + args.row).removeClass('fal fa-thumbs-up');
              $('#thumbs-up-' + args.row).addClass('fas fa-thumbs-up');
              $('#thumbs-down-' + args.row).removeClass('fas fa-thumbs-down');
              $('#thumbs-down-' + args.row).addClass('fal fa-thumbs-down');
              this.datasets.forEach(element => {
                if (element.dataset_id === row.dataset_id) {
                  element.is_up_vote = true;
                }
              });
              if(this.is_approver && this.is_pending_for_approval) {
                this.enableApprove = true;
              } else {
                this.enableFinishTask = true;
              }
            }, error=>{
              this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            });
      }
    }
  }

  clearDatasets() {
    this.gridDatasets.dataView.setItems([]);
  }

  upVote(payload) {
    const getRow: any = {
      user_id: this._userInfo['user_id'],
      tenant_id: this._userInfo['tenant_id'],
      payload: payload
    };
    this.catalogSvc.upVote(getRow).subscribe((data) => {      
      this.mappedColumns.forEach(element => {
        if (element.dataset_column_id === this.current_row.dataset_column_id) {
          element.is_up_vote = true;
        }
      });
    }, err => { });
  }
  
  onMappedColumnsScroll(parentClass) {
    $('.basic-tooltip').hide();
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasMaapedColumnScrolled && !this.noData) {
      this.hasMaapedColumnScrolled = true;    
      this.onMappedColumnNextPage();
    }
  }

  onMappedColumnNextPage(): void {
    if(this.mappedColumnPageNo < this.mappedColumnList.totalpages){
      this.mappedColumnPageNo++;
      this.getMappedColumns(this.mappedColumnPageNo, this.limit);
    }
  }

  receiveMappedColsTotalRecords($event) {
    this.totalMappedColumns=$event;      
  }

  clearMappedColumns() {
    this.showDownvoteModal = false;
    this.showDatasetsDownvoteModal = false;
    this.mappedColumns = [];
    if (this.gridMappedColumns) {     
      this.gridMappedColumns.dataView.setItems([]);
    }
    this.datasets = [];
    if (this.gridDatasets) {      
      this.gridDatasets.dataView.setItems([]);
    } 
    $('#mapped_columns_modal').modal('hide');
    $('#datasets_modal').modal('hide');
  }

  onScrollD(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
          this.isFiltering = true;
          this.noData = false;
        } else {
          if (this.isFiltering) {
            this.page = 1;
            this.isFiltering = false;
            this.noData = false;
          }
        } 
      if (this.zettaUtils.virtualScroll(parentClass) && !this.hasDatasetsScrolled && this.page < this.total_page) {  
          this.hasDatasetsScrolled = true;
          this.isLoadingDatasets = true;
          this.onNextDs();
      }
  }

  onNextDs(): void {
    this.datasetPage++;
    this.getDatasets(this.objectId,this.datasetPage, this.limit);
  }

  popupoutput(message){
    this.showmodelpopup = message;   
  }

  shouldDisableCatalogTask(taskStatus, assignedTo): boolean {
    let loggedInUserDetails = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo): null;
    if (taskStatus === AppGlobals.TASK_COMPLETED) {
      return true;
    }
    
    if (taskStatus === AppGlobals.TASK_REVIEWED_STATUS) {
      return true;
    }

    if (taskStatus === AppGlobals.TASK_IN_REVIEW) {
      return false;
    }

    if (taskStatus === AppGlobals.TASK_PENDING_FOR_APPROVAL || taskStatus === AppGlobals.TASK_PENDING_APPROVAL) {
      if (loggedInUserDetails && loggedInUserDetails.user_id && loggedInUserDetails.user_id === assignedTo) {
        return false;
      }
      return true;
    }
    
    return false;
  }

  finishAllTasks() {
    if(this.projectType == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
      const votesPayload = this.datasets.map(data => {
        if(data.is_up_vote) {
          return {
              "dataset_id": data.dataset_id,
              "mapping_type": "manual",
              "entity_id": this.objectId,
              "catalog_id": this.catalogId,
              "is_semantic_level_mapping": true,
              "type":"up_vote"
          };
        } else {
          return {
            "dataset_id": data.dataset_id,
            "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
            "entity_id": this.objectId,
            "catalog_id": this.catalogId,
            "is_semantic_level_mapping": true,
            "removing_entity_id":this.objectId,
            "type":"down_vote"
          };
        }
      });
      $('#mapped_columns_modal').modal('hide');
      $('#datasets_modal').modal('hide');
      // this.catalogSvc.bulkVoteUpdate(this._userInfo, votesPayload).subscribe(res => {});
    } else if(this.projectType == AppGlobals.CLASSIFYCATALOGCONCEPTASK){
      const votesPayload = this.mappedColumns.map(data => {
        if(data.is_up_vote) {
          return {
            "dataset_id": data.dataset_id,
            "dataset_column_id": data.dataset_column_id,
            "removing_attribute_id": data.attribute_id,
            "attribute_id": data.attribute_id,
            "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
            "entity_id": this.objectId,
            "catalog_id": this.catalogId,
            "type":"up_vote"
          };
        } else {
          return {
            "dataset_id": data.dataset_id,
            "dataset_column_id": data.dataset_column_id,
            "removing_attribute_id": +this.attributeId, 
            "mapping_type": data.mapping_type === undefined ? 'manual' : data.mapping_type,
            "entity_id": +this.objectId,
            "catalog_id": +this.catalogId,
            "type": 'down_vote'
          };
        }
      });
        $('#datasets_modal').modal('hide');
        $('#mapped_columns_modal').modal('hide');
        // As discussed from API team commenting bulk feedback since individual feedback call is being made 
      // this.catalogSvc.bulkVoteUpdate(this._userInfo, votesPayload).subscribe(res => {});
    }
  }

onExportMappedColumn() {
  this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/concepts/${this.conceptId}/mappedcolumns?export=true`).subscribe(resp => {
  this.catalogSvc.exportCsvFiles(resp, 'DiscoveredMappedDataColumnsReport');
      }, error => {
      this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
}

onExport() {
    this.catalogSvc.exportResult(`${environment.config.API_URL}/users/${this._userInfo['user_id']}/catalogs/${this.catalogId}/semantics/${this.objectId}/datasets?export=true`).subscribe(resp => {
    this.catalogSvc.exportCsvFiles(resp, 'DataSetsReport_forSemanticObject');
   }, error => {
    this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
    });
  }

receiveFileName($event) {
  this.dataRes = $event;
  this.is_upload_complete = this.dataRes.is_upload_complete;
    if(this.is_upload_complete) {
      if(this.viewOnlyTasks) {
        //this.initTaskColDef();
        this.taskGrid.dataView.setItems([]);
        this.taskGrid.gridService.setSelectedRows([]);
        this.loadTasksData(this.page, this.limit);
      } else {
        //this.loadGridLayout();
        this.leftTaskGrid.dataView.setItems([]);
        this.leftTaskGrid.gridService.setSelectedRows([]);
        this.loadLeftTasksData(this.page, this.limit);
      }
    }
}

uploadTasks() {
  this.clearModal = Math.random();
}

cancelUpload() {
  this.clearModal = Math.random();
  $('#uploadFileModal').modal('hide');
}

checkValidates(indexes) {
  this.validatedSelectedProjects = indexes.every(proj => {
     if(proj['task_type_id'] == indexes[0]['task_type_id']) {
      if(proj['reference_object_id'] == indexes[0]['reference_object_id']) {
        return true;
      } else {
        return false;
      }
     } else {
      return false;
     }
  });
  }

  assignedTasks(eve) {
    if(eve) {
      this.leftTasksDataSource = [];
        if (this.leftTaskGrid) {
          this.leftTaskGrid.dataView.setItems(this.leftTasksDataSource);
        }
      this.loadLeftTasksData(1, this.limit);
    }
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }

  checkForTaskTypes() {
    let reviewerTasks = this.selectedProjects.every(task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW);
    let approverTasks = this.selectedProjects.every(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));
    let inReviewTasks = this.selectedProjects.some( task => task.status == AppGlobals.TASK_IN_REVIEW || task.task_status == AppGlobals.TASK_IN_REVIEW) 
    let inPendingTasks = this.selectedProjects.some(task => (task.status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.status == AppGlobals.TASK_REVIEWED_STATUS) || (task.task_status == AppGlobals.TASK_PENDING_FOR_APPROVAL || task.task_status == AppGlobals.TASK_REVIEWED_STATUS));
    
    if (reviewerTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = disabledCheckBoxFormatter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(approverTasks) {
      this.rightGridColumnsDef[3]['formatter'] = disabledCheckBoxFormatter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(inReviewTasks && inPendingTasks) {
      this.rightGridColumnsDef[3]['formatter'] = revieweCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = aprooveerCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = true;
    } 
  }

  approveBtn() {
    this.enableApprove = false;
    this.enableApproved = true;
    this.enableFinishTask = true;
  }

  getTaskReviewer(){
    this.catalogSvc.taskReviewer(this._userInfo['user_id'], this._userInfo['tenant_id'], this.task_id, this.task_type_id).subscribe(resp => {
      if (resp.length) {
        this.taskReviewerName = resp[0].first_name;
        this.taskReviewerDate = resp[0].created_ts;
      } else {
        this.taskReviewerName = resp.name;
        this.taskReviewerDate = resp.date;
      } 
    }, error=>{
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  finishTasks() {
      const data = {
        "task_id": this.task_id,
      };
      const payload = {
        user_id: this._userInfo['user_id'],
        catalog_id : this.catalogId,
        payload: data,
      };
      this.catalogSvc.finishTaskOfCatalog(payload).subscribe(resp => {
        this.hideFeedbackModal();
          if (this.viewOnlyTasks) {
              this.isOpenTaskLoading = true;
              this.tasksDataSource = [];
              if (this.taskGrid) {
                this.taskGrid.dataView.setItems(this.tasksDataSource);
              }
                this.loadTasksData(1, this.limit);
            } else {
              this.leftTasksDataSource = true;
              this.leftTasksDataSource = [];
              if (this.leftTaskGrid) {
                this.leftTaskGrid.dataView.setItems(this.leftTasksDataSource);
               }
                this.loadLeftTasksData(1, this.limit);
        }
      }, error => {
        this.hideFeedbackModal();
         this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
       });   
  }

  hideFeedbackModal() {
    if (this.projectType == AppGlobals.CLASSIFYCATALOGSEMANTICTASK) {
      $('#mapped_columns_modal').modal('hide');
      $('#datasets_modal').modal('hide');
    } else if (this.projectType == AppGlobals.CLASSIFYCATALOGCONCEPTASK) {
      $('#datasets_modal').modal('hide');
      $('#mapped_columns_modal').modal('hide');
    }
  }
}
