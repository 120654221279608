import { AppGlobals } from '../../../common/shared/app.globals';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { map, concatMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { Records } from './../../../common/services/records.constants';
import { Project, IEntityPrams } from './project.model';

@Injectable({
    providedIn: 'root'
})

export class ProjectService extends BaseService {

    constructor(private http: HttpClient) { super(); }
    public engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    fetchEntities(id?: string): Observable<any> {
        return this.fetchRecords(Records.ENTITIES, this.http, id);
    }

    fetchProjectEntities(entityId: string, projectId: string):Observable<any> {
    if (entityId) {
      return this.http.get(`${this.apiUrl}` + `/entities` + `/` + entityId+`?project=${projectId}&group_results_by=whole&allUsers=true`);
    } else {
      return this.http.get(`${this.apiUrl}` + `/entities`);
    }
    }

    saveProject(project: Project): Observable<any> {
        if (project.project_id === undefined || project.project_id === null) {
            return this.http.post(`${this.apiUrl}/users/` + project.user_id + `/projects`, project, { observe: 'response' });
        } else {
            return this.http.put(`${this.apiUrl}/users/` + project.user_id + `/projects/` + project.project_id, project, { observe: 'response' });
        }
    }

    updateDatasetPosition(project, payload) {
        return this.http.put(`${this.apiUrl}/users/` + project.user_id + `/projects/` + project.project_id+`?drag_drop_sort_order=true`, payload, { observe: 'response' });
    }

    saveDuplicateDatasets(user_id, project_id, payload: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + user_id + `/projects/` + project_id, payload);
    }

    saveProjectDataSets(project: Project): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + project.user_id + `/projects/` + project.project_id + `/updatedatasource`, project);
    }

    public getUsers(userId: string, projectId: String, groupid: string) {
        const groupUsers = this.http.get(`${this.apiUrl}/users/${userId}/groups/` + groupid + `/users`);
        const projectUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
        const projectResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        return forkJoin([groupUsers, projectUsers, projectResponse]);
    }

    public getDataForMatchingRemediationTasks(userId: string, projectId: String, productionState = false, page?, limit?) {
        const projectAPI = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        const projectUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
        let fixIssueTasksAPI = this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/entitiesresolved/fixissues/summary`); 
        if(productionState) {
            fixIssueTasksAPI = this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/entitiesresolved/fixissues/summary?onlyopentasks=true&pagination=true&pageno=${page}&pagesize=${limit}`);
        }
        return forkJoin([projectAPI, projectUsers, fixIssueTasksAPI])
        .pipe(map(([projectAPIData, projectUsersData, fixIssuesTasksData]) => ({projectAPIData, projectUsersData, fixIssuesTasksData})));
    }

    public getResolveUsers(userId: string, projectId: string) {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
    }

    public getUsersTenant(userId: string, projectId: String, tenantid: string) {
        const usersTenant = this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/users`);
        const projectUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
        const projectResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        return forkJoin([usersTenant, projectUsers, projectResponse]);
    }

    public getProjectDataSets(userId: string, projectId: String, tenant_id: string) {
        const dataSetReponse = this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenant_id + `/datasets?checkAtrributes=true`);
        const projectResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        return forkJoin([dataSetReponse, projectResponse]);
    }

    public getProject(userId: string, projectId: String, query={}): Observable<any> {
        if(query && query['basic'] == true) {
            return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId+`?basic=true`); 
        } else {
            return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
        }
    }

    public getUserEntitlements(userId: string, tenant_id: string, datasetId: String): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenant_id + `/datasets/` + datasetId + `/userentitlements`);
    }

    public deleteProject(userId: string, projectId: String): Observable<any> {
        return this.http.delete(`${this.apiUrl}/users/` + userId + `/projects/` + projectId);
    }

    public getProjectDataSetAttributes(userId: String, projectId: string, entityId: string, dataSetId: String): Observable<any> {
        const datasetAttributes = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/datasets/` + dataSetId);
        const entityAttributes = this.http.get(`${this.apiUrl}/entities/` + entityId);
        return forkJoin([datasetAttributes, entityAttributes]);
    }

    public getEntityAttributes(entityId) {
        return this.http.get(`${this.apiUrl}/entities/` + entityId);
    }

    public getDataSetAttributes(tenant_id: string, datasetId: number, projectId?): Observable<any> {
        if (projectId) {
            return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${tenant_id}/datasets/${datasetId}/columns?project=${projectId}`);
        }
        return this.http.get(`${this.apiUrl}/users/${this.getObjectFromSession(AppGlobals.USER_INFO).user_id}/tenants/${tenant_id}/datasets/${datasetId}/columns`);
    }

    public getProjectStateList() {
        return ['Describe Project', 'Add Users', 'Add Data Sets','Edit Entity Model' ,'Map Data Sets to Entity Model'];
    }

    public getMyProjectDetails(userId: number, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects?pageno=${pageno}&pagesize=${perpage}`);
    }

    public getMyTasksData(param: IEntityPrams, tasks: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + param.userId + `/tasks?${tasks}=true`);
    }

    getEntitiesResultsByProjectId(userId, selectedProjectId, pageno, perpage, serverFilter = ''): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved?pageno=${pageno}&pagesize=${perpage}${serverFilter}`).pipe(
            map(this.extractData));
    }

    getParametersByProjectId(userId, selectedProjectId): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/params`).pipe(
            map(this.extractData));
    }

    saveParam(selectedGroup, paramsData: any, userid, projectid): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${userid}/projects/${projectid}/params/` + selectedGroup, paramsData);
    }

    getEntitiesDetailsByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getEntitiesmasteredByLoggedInUser(userId, selectedProjectId, pageno, perpage, serverFilter = '', sortfilter = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered` + `?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }

    getProjectResults(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/consume` + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getProjectPublishStatus(userId, selectedProjectId): Observable<any> {
        // return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/consume/status`).pipe(
        //     map(this.extractData));
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/consume/status`);
    }

    getEntitiesmasteredByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<any> {        
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    getGoldenRecordByClusterId(userId, selectedProjectId, clusterId, pageno, perpage,serverFilter='',sortfilter=''): Observable<any> {        
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }

    getGoldenRecordsEntity(userId, selectedProjectId, clusterId, attribute, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `/attributes` + `?for_golden_records=true&attribute_name=` + attribute + `&pageno=${pageno}&pagesize=${perpage}`);
    }

    downVote(data: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/vote?type=down_vote`, data.payload);
    }

    getEntitiesMasterByClusterId(userId, selectedProjectId, clusterId, pageno, perpage, noPagination?): Observable<any> {
        if (noPagination) {
            return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `?paginate=false&for_golden_records=true`).pipe(
                map(this.extractData))
        }
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}&for_golden_records=true`).pipe(
            map(this.extractData));
    }
    
    getExceptionsByClusterId(userId, selectedProjectId, clusterId,attribute_name): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/` + clusterId + `/dqExceptions?fieldName=${attribute_name}`).pipe(
            map(this.extractData));
    }
    getAttributesOfEntitiesmasteredByClusterId(userId, selectedProjectId, clusterId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/` + clusterId + `/attributes` + `?for_golden_records=true`).pipe(
            map(this.extractData));
    }

    public getActivityHistory(userid: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userid}/activities`);
    }
    public getProjectActivityHistory(userid: string, projectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userid}/projects/${projectId}/activities`);
    }
    getProjectEntitiesByProjects(userId, pageno, perpage, serverFilter = '', sortfilter = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects` + `?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }

    getRelatedProjectsByDataSetId(userId: string, tenant_id: string, datasetId: string, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenant_id + `/datasets/` + datasetId + `/projects?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    cloneProject(project): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/` + project.user_id + `/projects/` + project.project_id + `/copy`, project);
    }

    public getUserPic(userid: string, groupid: string): Observable<any> {
        return this.getUserPic(userid, groupid);
    }

    getTrainEntitiesByProjectId(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved/feedback/summary?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // userId, selectedProjectId  
    getRunsuperviseByProjectId(param: IEntityPrams, entityType): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.selectedProjectId}/${entityType}/runsupervised`).pipe(
            map(this.extractData));
    }

    getTrainEntitiesMasterByProjectId(userId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/feedback/summary?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // public getEntityResolvedData(userId, selectedProjectId, jobId) {
    //     return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/jobs/track`).pipe(
    //         map(this.extractData));
    // }

    public getCurrentActiveJobOld(userId, selectedProjectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/jobs/track`);
    }
    public getCurrentActiveJobs(userId, selectedProjectId, object_type_id): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/track?object_type_id=${object_type_id}&object_id=${selectedProjectId}`);
    }
    public getObjectTypes(type): Observable<any> {
        return this.http.get(`${this.apiUrl}/lookups/?group=${type}`);
    }

    public cancelActiveJob(userId, projectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/jobs/cancel`);
    }

    public cancelActiveJob2(userId, objectTypeId, objectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/cancel?object_type_id=${objectTypeId}&object_id=${objectId}`);
    }

    //    scenario id to be passed
    //     0 -->  Create Project --> Run model
    //     1 -->  Entities Resolved --> Train model --> Re Run Model
    //     2 -->  Entities Record Mastered --> Train model --> Re Run Model
    runModel(projectId: string, scenarioId: number): Observable<any> {
        //return this.http.post(`${this.apiUrl}/algorithms/${AppGlobals.getRunModelAlgo(scenarioId)}/run`, { 'projectid': projectId });
        let algo_id = AppGlobals.getRunModelAlgo(scenarioId);
        if (this.engine_type_id == 452) {
            algo_id = 401;
        }
        return this.http.post(`${this.apiUrl}/algos/${algo_id}`, { 'project_id': projectId });
    }
    // Pass stage as parameter as its being used in matcher and merger
    reRunModel(param: IEntityPrams, modelStage: string): Observable<any> {
        //return this.http.post(`${this.apiUrl}/algorithms/${AppGlobals.getRunModelAlgo(param.scenarioId)}/run`, { projectid: param.projectId, stage: modelStage, supervised: true });
        let algo_id = AppGlobals.getRunModelAlgo(param.scenarioId);
        if (this.engine_type_id == 452) {
            algo_id = 401;
        }
        return this.http.post(`${this.apiUrl}/algos/${algo_id}`, { project_id: param.projectId, stage: modelStage, supervised: true });
    }

    getSelectOptionTrainEntitiesByProjectId(userId, clusterId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/entitiesresolved/feedback/clusters/${clusterId}?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    // {{url}}/users/4/projects/10/entitiesmastered/train/clusters/37a49974-9181-4b94-a0e4-28d67f4717d2?pageno=1&pagesize=5

    getEntitiesMasteredFeedback(userId, clusterId, selectedProjectId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/entitiesmastered/feedback/clusters/${clusterId}?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    updateMergerFeedback(userId, projectId, clusterId, requestPayload): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${userId}/projects/${projectId}/entitiesmastered/feedback/clusters/${clusterId}`, requestPayload);
    }

    public getSelectOptionModelListByDataSetId(userId, tenant_id, dataSetId, pageno, perpage,serverFilter='',sortfilter=''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenant_id}/datasets/${dataSetId}/sampledata?checkAtrributes=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }
    public getSampleDataset(userId, tenant_id, dataSetId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenant_id}/datasets/${dataSetId}/sampledata?checkAtrributes=true?pageno=${pageno}&pagesize=${perpage}`)
    }

    public getselectViewMergedBtnModelList(userId, tenant_id, projectid, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenant_id}/datasets/${projectid}/mergesampledata?checkAtrributes=true?pageno=${pageno}&pagesize=${perpage}`).pipe(
            map(this.extractData));
    }

    public getOpentasks(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/tasks`);
    }

    public getModelSummaryLoader(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries`);
    }

    public getModelSummaryLatest(userId, projectId, model): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries?model=${model}&summaryfor=viewresults&latest=true`);
    }

    public getModelSummary(userId: string, projectId: string, model: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries?model=${model}&summaryfor=viewresults`);
    }

    public getSparkjobHistory(userId: string, pageno, perpage): Observable<any> {
        // return this.http.get(`${this.apiUrl}/users/${userId}/projects/sparkjobs?pagesize=${pagesize}`);
        // return this.http.get('../../../../assets/managejobs.json');
        // return this.http.get(`${this.apiUrl}/users/${userId}/jobs?pagesize=${pagesize}`);
        // return this.http.get(`${this.apiUrl}/users/${userId}/jobs?pageno=${pageno}&pagesize=${perpage}`);
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs`);
    }

    public getProjectsJobs(userId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs`);
    }

    public getObjectsJobs(userId: string, objectTypeId: string, filterBy?: string): Observable<any> {
        if (filterBy) {
            return this.http.get(`${this.apiUrl}/users/${userId}/jobs?object_type_id=${objectTypeId}&status=${filterBy}`);
        }
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs?object_type_id=${objectTypeId}`);
    }

    public getJobsByProjectId(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs/${projectId}`);
    }

    public getJobsByObjectId(userId: string, objectId: string, objectTypeId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs/${objectId}?object_type_id=${objectTypeId}`);
    }

    public getSparkjob(userId: string, objectId: string, jobId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/jobs/${objectId}/${jobId}`);
    }

    public getJobLog(userId: string, tenantId: string, jobId: string, stageName: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/logs?job_id=${jobId}&stage_name=${stageName}`);
    }

    public getSuggestedCluster(param: IEntityPrams): Observable<any> {
        const encodedIdentifier = encodeURIComponent(param.clusterName);
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}/closest?identifier=${encodedIdentifier}`);
    }

    public updateFeedback(param: IEntityPrams): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}`, param.payload);
    }

    public updateFeedbackOfMatchFixingTasks(param: IEntityPrams, mergeClusters): Observable<any> {
        if (mergeClusters) {
            return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/matchfixing/feedback/clusters/${param.clusterId}?fix_task=true`, param.payload);    
        }
        return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/matchfixing/feedback/clusters/${param.clusterId}`, param.payload);    
    }

    public updateStep3FeedbackOfMatchFixingTasks(param: IEntityPrams, moveClusters): Observable<any> {
        if (moveClusters) {
            return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/matchfixing/feedback/clusters/${param.clusterId}?feedback_type=not_matched_records&fix_task=true`, param.payload);         
        }
        return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/matchfixing/feedback/clusters/${param.clusterId}?feedback_type=not_matched_records&update_task_status=true`, param.payload);   
    }

    public runDeltaMatcherAlgoJob(algoStage: string, jobId: string, projectId: string, clusterId: string) {
        let api_url = `${this.apiUrl}/algos/401`;
        return this.http.post(api_url, { 'stage': algoStage, 'job_id': jobId,'project_id': projectId,'cluster_id': clusterId });
    }

    public updateResoveProjectFeedback(param: IEntityPrams): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}?feedback_type=not_matched_records`, param.payload);
    }

    public hasActiveJob(job): boolean {
        if (job && (job.job_completed || job.job_completed === undefined || (!job.job_completed && job.is_errored))) {
            return false;
        } else {
            return true;
        }
    }

    public searchEntityCluster(param: IEntityPrams, searchQuery?: boolean): Observable<any> {
        if(searchQuery) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/${param.clusterId}/search?key=${param.searchString}&original=false`);
        } else {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/${param.clusterId}/search?key=${param.searchString}`);
        }
    }

    saveUserRoles(userId: string, projectId: string, user): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users`, user);
    }

    getProjectTasksList(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/projecttasks?getall=false`);
    }

    getManageProjectTasksList(param: IEntityPrams, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/projecttasks?getall=true&pageno=${pageno}&pagesize=${perpage}`);
    }

    getClassIssueFixes(userId, tenantId, projId, page, limit, serverFilter="", sortfilter=""): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks?task_type=fixing&pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${sortfilter}`);
    }

    public getAllMyOpenTasks(userId, pageno, perpage, serverFilter="", sortfilter=""): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tasks?opentasks=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }

    public getAllOpenTasks(userId, tenantID, pageno, perpage,isOpenTask=true, serverFilter="", sortfilter="",isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantID + `/classify/tasks?opentasks=${isOpenTask}&pagination=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`).pipe(
            map(this.extractData));
    }

    public getAppClassifyTasks(userId, tenantID, pageno, perpage,isOpenTask, serverFilter="", sortfilter="",isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tenants/` + tenantID + `/classify/tasks?opentasks=${isOpenTask}&allusers=true&pagination=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`).pipe(
            map(this.extractData));
    }

    public getAllTasks(userId, pageno, perpage, serverFilter="", sortfilter="", isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tasks?allusers=true&pagination=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`).pipe(
            map(this.extractData));
    }

    public getAllMyTasks(userId, pageno, perpage, serverFilter="", sortfilter="", isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/tasks?allusers=false&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`).pipe(
            map(this.extractData));
    }

    getEntitiesResolvedList(param: IEntityPrams, page, limit, paginate=false): Observable<any> {
        if(paginate) {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/fixissues/summary?onlyopentasks=true&pagination=true&pageno=${page}&pagesize=${limit}`);
        } else {
            return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/fixissues/summary`);
        }
    }

    getOpenGoldenRecords(param: IEntityPrams, page, limit): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/fixissues/summary?onlyopentasks=true&pagination=true&pageno=${page}&pagesize=${limit}`);
    }
    
    getEntitiesMasteredList(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesmastered/fixissues/summary`);
    }

    saveTasksRoles(userId: string, projectId: string, user): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/tasks`, user);
    }

    saveTasksToUsers(userId: string, projectId: string, user): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/projecttasks`, user);
    }

    public getColumns(param: IEntityPrams): Observable<any> {
        const datasetColumns = this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasets/${param.datasetId}/columns`);
        const dataset = this.http.get(`${this.apiUrl}/users/${param.userId}/tenants/${param.tenant_id}/datasets/${param.datasetId}`);
        return forkJoin([datasetColumns, dataset]);
    }

    public getProductionProjectTasks(param: IEntityPrams, page, limit): Observable<any> {
        const projectTasks = this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/fixissues/summary?onlyopentasks=true&pagination=true&pageno=${page}&pagesize=${limit}`);
        const projectDetails  = this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}`);
        return forkJoin([projectTasks, projectDetails])
        .pipe(map(([projectTasks, projectDetails]) => ({projectTasks, projectDetails})));
    }

    public getColumnsTypes(): Observable<any> {
        return this.http.get(`${this.apiUrl}/lookups?group=AttributeDataTypes`);
    }

    public getFormulas(): Observable<any> {
        return this.http.get(`${this.apiUrl}/lookups?group=DatasetGeneratedColumnFormulas`);
    }

    public getLatestJobId(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/jobs/latest`);
    }

    // public getLatestJobId(param: IEntityPrams): Observable<any> {
    //     return this.http.get(`${this.apiUrl}/users/${param.userId}/zsjobs/latest?object_type_id=${param.objectTypeId}&object_id=${param.objectId}`);
    // }

    public publishResults(param: IEntityPrams): Observable<any> {
        return this.http.post(`${this.apiUrl}/algos/${param.scenarioId}`, param.payload);
    }

    public projectPublishResults(param: IEntityPrams): Observable<any> {
        let api_url = `${this.apiUrl}/algos/${param.scenarioId}`;
        if (this.engine_type_id == 452) {
            api_url = `${this.apiUrl}/algos/401`;
        }
        return this.http.post(api_url, param.payload);
    }

    public getSimilarClusters(param: IEntityPrams): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${param.userId}/projects/${param.projectId}/entitiesresolved/feedback/clusters/${param.clusterId}/similar`);
    }

    public setApproval(userId: string, projectId: string, approval): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/mandatoryapproval`, approval);
    }

    public getApproval(userId: string, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/mandatoryapproval`);
    }

    public getGoldenRecords(userId: number, projectId: string, pageno, perpage, serverFilter = '', sortfilter = '', enableQuery = false, queryparam?): Observable<any> {
        if(enableQuery) {
            return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?forGoldenRecords=true&time_range=${queryparam}&paginate=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
                map(this.extractData));
        } else {
            return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?forGoldenRecords=true&paginate=true&pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
                map(this.extractData));
        }
    }

    getGoldenRecordsHistory(userId, selectedProjectId, clusterId, pageno, perpage, serverFilter = '', sortfilter = ''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/` + clusterId + `/history?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`);
    }
    saveGoldenRecordsHistory(userId, selectedProjectId, clusterId, payload: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/` + clusterId + `/history`,payload);
    }

    getGoldenRecordsLineage(userId, selectedProjectId, clusterId, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesmastered/winners/${clusterId}/lineage?pageno=${pageno}&pagesize=${perpage}`);
    }

    public publishRecords(userId: number, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?format=csv`);
    }

    public getGoldenRecord(userId: number, projectId: string, clusterId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners/` + clusterId);
    }

    public getGoldenRecordColumn(userId: number, projectId: string, clusterId: string, columnName: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners/` + clusterId + '/' + columnName);
    }

    public updateGoldenRecord(userId: string, projectId: string, payload: any): Observable<any> {
        if (payload.is_custom == true) {
            return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?custom_option=true`, payload);
        }
        else {
            return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners`, payload);
        }
    }

    public updateGoldenRecordAttribute(userId: string, projectId: string, payload: any, isCustom: boolean): Observable<any> {
        if (isCustom == true) {
            return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?custom_option=true`, payload);
        } else {
            return this.http.put(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners`, payload);
        }
    }
    
    public getGoldenRecordColumns(userId: number, projectId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/displaynames`);
    }

    public getMainGoldenRecords(userId: number, projectId: string, clusterId, pageno, perpage) {
        let displayNames =  this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/displaynames`);
        let clusterRecords = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners/` + clusterId + `?pageno=${pageno}&pagesize=${perpage}`);
        return forkJoin([displayNames, clusterRecords]);
    }

    public getCatalogDetails(userId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/catalogs`);
    }

    public getEntityDetails(userId: string, catalogId: string, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId + `/semantics?pagination=true&pageno=${pageno}&pagesize=${perpage}`)
    }

    public getEntityGroup() {
        return this.http.get(`${this.apiUrl}/lookups?group=EntityTypes`)
    }

    public searchEntityDetails(userId: string, catalogId: string, pageno, perpage) {
       return `${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId + `/semantics?pagination=true&pageno=${pageno}&pagesize=${perpage}`
    }

    public saveClassifyPrediction(userId: string, projectId: string, payload: any) {
        return this.http.post(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + '/map-classify-predictions', payload);
    }

    public addUserToCatalog(data: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/users`, data.payload);
    }
    public deleteUserFromCatalog(data: any): Observable<any> {
        return this.http.delete(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/users`, data.payload);
    }

    public getCatalogUsers(userId: string, catalogId: String, tenantid: string): Observable<any> {
        const usersTenant = this.http.get(`${this.apiUrl}/users/${userId}/tenants/` + tenantid + `/users`);
        const catalogUsers = this.http.get(`${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId + `/users`);
        const catalogResponse = this.http.get(`${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId);
        return forkJoin([usersTenant, catalogUsers, catalogResponse]);
    }

    public getUsersByCatalog(userId, catalogId) {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId + `/users`);
    }

    public updateCatalogTaskUsers(userId, catalogId, taskTypeId, payload) {
        return this.http.put(`${this.apiUrl}/users/` + userId + `/catalogs/` + catalogId + `/users?taskTypeId=${taskTypeId}`, payload);
    }

    public saveapprovaldata(data: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}`, data.payload);
    }
    public saveprojapprovaldata(data: any,project_id): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${data.user_id}/projects/${project_id}`, data.payload);
    }

    public getClassifyTasks(userId, tenantId, projId, page, limit, serverFilter = '', sortfilter='',isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`);
    }

    public getTaskDetails(userId, tenantId, projId, id, page?, limit?, serverFilter = '', sortfilter=''): Observable<any> {
        if(page && limit) {
            return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks/${id}?pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${sortfilter}`);
        } else {
            return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks/${id}`);
        }
    }

    public updateTaskFeedback(info,screenName): Observable<any> {        
        if (screenName === "TRAINING-TASKS") {
            return this.http.post(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback?is_training_task_feedback=true`, info.payload);
        } else {
            return this.http.post(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback`, info.payload);
        }
    }

    updateFeedbackHistory(info, screenName): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${info.projId}/feedback`, info.payload);
    }

    public getClassNames(userId, tenantId, projId, pageno, limit, serverFilter = '', sortfilter='') {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/feedback/classes?paginate=true&pageno=${pageno}&pagesize=${limit}${serverFilter}${sortfilter}`);
    }

    public getTaskAssignments(info, projId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${projId}/users`);
    }
    reRunModelClassify(classify_project_id): Observable<any> {
        let api_url = `${this.apiUrl}/algos/500`;
        if (this.engine_type_id == 451) {
            api_url = `${this.apiUrl}/algos/307`;
        }
        return this.http.post(api_url, { 'classify_project_id': classify_project_id });
    }
    
    public getClassifyUserTasks(userId, tenantId, projId, page, limit, serverFilter = '', sortfilter=''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks?allusers=true&projecttasks=true`);
    }

    public classifyTaskUpdate(userId, tenantId, projId, data) {
        return this.http.put(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}`, data)
    }
    public updateTaskToUsers(info, projId, payload): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${info.user_id}/tenants/${info.tenant_id}/classify/projects/${projId}/tasks`, payload);
    }
    public getAllClassifyTasks(data:any,serverFilter="", sortfilter=""): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/tasks?allusers=true&pagination=true&pageno=${data.pageno}&pagesize=${data.limit}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }
    public getRuleExceptions(userId, tenantId, ruleId) {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/dataquality/rules/exceptions?rule_id=${ruleId}`);
    }

    public getClassifyConceptParserTasks(userId, tenantId, projId, page, limit, serverFilter = '', sortfilter='', isCustomGridStateHandling?): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/tasks?project_type=14863&pagination=true&pageno=${page}&pagesize=${limit}${serverFilter}${sortfilter}${isCustomGridStateHandling ? '&gridStateChanged=true' :''}`);
    }

    public getSOCFeedback(userId, tenantId, projId, page, limit, serverFilter = '', sortfilter=''): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/feedback/feedbackhistory?pagination=true&pageno=${page}&pagesize=${limit}&project_type=14862${serverFilter}${sortfilter} `);
    }
  
    public validateIfClassifySocProjectFeedbackCaptured(userId, tenantId, projId,projectTypeId): Observable<any>{
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${projId}/feedback/isfeedbackcaptured?project_type=${projectTypeId}`)
    }
    public getLatestJob(userId, selectedProjectId, object_type_id): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/latest?object_type_id=${object_type_id}&object_id=${selectedProjectId}`);
    }
    public getCurrentActiveJobsById(userId, selectedProjectId, object_type_id, job_id): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/zsjobs/${job_id}/track?object_type_id=${object_type_id}&object_id=${selectedProjectId}`);
    }

    public getParseRecords(userId, tenantId, classifyProjectId): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/tenants/${tenantId}/classify/projects/${classifyProjectId}/viewresults?project_type=14863`);
    }

    public getSearchResults(userId, searchedText): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/search?search=${searchedText}`);
    }  
    
    public getFilteredSearchResults(userId, searchedText, searchedFilters): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userId}/search?search=${searchedText}&dataset=${searchedFilters.statusDataSets}&dataset_columns=${searchedFilters.statusDataSetColumn}&concepts=${searchedFilters.statusConcepts}&semantic_objects=${searchedFilters.statusSemanticObject}&tags=${searchedFilters.statusTags}`);
    } 

    public getAllJobQueue(data:any,serverFilter="", sortfilter=""): Observable<any> {
        return this.http.get(`${this.apiUrl}/algos/queue?pagination=true&pageno=${data.pageno}&pagesize=${data.limit}${serverFilter}${sortfilter}`)
    }

    public getProjectDqScroce(userId, projectId) {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/summaries?model=master&summaryfor=viewresults`);
    }

    public getProjectDatasets(userId, projectId) {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/datasets`);
    }

    publishGoldenData(payloadData) {
            let api_url = `${this.apiUrl}/algos/417`;
            if (this.engine_type_id == 451) {
                api_url = `${this.apiUrl}/algos/314`;
            } else if(this.engine_type_id == 452) {
                api_url = `${this.apiUrl}/algos/417`;
            }
            return this.http.post(api_url, payloadData);
        }

    public getListDatasetsForProject(userId, projectId) {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${projectId}/datasets?pagination=true`);
    }

    public getAdvanceSearch(userId, selectedProjectId, pageno, perpage, serverFilter = '',sortfilter=''): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + selectedProjectId + `/entitiesresolved?pageno=${pageno}&pagesize=${perpage}${serverFilter}${sortfilter}`).pipe(
            map(this.extractData));
    }

    getProjectUsers(url): Observable<any> {
        return this.http.get(`${this.apiUrl}/${url}`);
    }

    assignProjects(url, payload): Observable<any> {
        return this.http.put(`${this.apiUrl}/${url}`, payload, {observe: 'response'});
    }
    
    getprojectUsers(userId, projectId): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/users/`);
    }

    getProjectConfigSettings(userId, projectId) {
        return this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/production`);
    }

    saveProjectConfigSettings(userId, projectId, payload) {
        return this.http.post(`${this.apiUrl}/users/${userId}/projects/${projectId}/production?objectTypeId=14778`, payload);
    }

    public getMappings(data, projectId?): Observable<Object> {
        if (projectId) {
            return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.entity_id}/mappedcolumns?high_confidance_only=true&datasetIds=${data.dataset_ids}&project=${projectId}`);    
        }
        return this.http.get(`${this.apiUrl}/users/${data.user_id}/catalogs/${data.catalog_id}/semantics/${data.entity_id}/mappedcolumns?high_confidance_only=true&datasetIds=${data.dataset_ids}`);
    }
    public getClassifyProjectTraingDataset(data): Observable<any>  {
		return this.http.get(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/trainingdatasets`);
	}
	public removeClassifyTrainigDatasets(data): Observable<any> {
		return this.http.delete(`${this.apiUrl}/users/${data.user_id}/tenants/${data.tenant_id}/classify/projects/${data.project_id}/training_datasets`, data.payload);
	}

    public getGoldenRecordListData(userId: any, projectId: string,pageno: number, perpage: number ){
        let goldenRecordsRowApi = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/winners?pageno=${pageno}&pagesize=${perpage}&forGoldenRecords=true`);
        let goldenRecordsColumnsApi = this.http.get(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entitiesmastered/displaynames`);
        return forkJoin([goldenRecordsRowApi, goldenRecordsColumnsApi]) .pipe(map(([goldenRecordsRowApi, goldenRecordsColumnsApi]) => ({goldenRecordsRowApi, goldenRecordsColumnsApi})));
    }

    public getProjectEntityAttributeData(entity_id: string, project_id: string): Observable<any> {
        let resolveAttributeUsageData = this.http.get(`${this.apiUrl}/lookups/ResolveAttributeUsage`);
        let resolveMergingStrategyData = this.http.get(`${this.apiUrl}/lookups/ResolveMergingStrategy`);
        let entityAttributeData = this.http.get(`${this.apiUrl}/entities/${entity_id}/attributes?project=${project_id}`);
        return forkJoin([entityAttributeData, resolveAttributeUsageData, resolveMergingStrategyData])
        .pipe(map(([entityAttributeData, resolveAttributeUsageData, resolveMergingStrategyData]) => ({entityAttributeData, resolveAttributeUsageData, resolveMergingStrategyData})));
    }

    public saveProjectEntityAttribute(userId: string, projectId: string, entityId: string, payload: Object): Observable <any>{
        return this.http.post(`${this.apiUrl}/users/` + userId + `/projects/` + projectId + `/entity/` + entityId + '/attributes', payload);
    }

    
    processCatalogFile(payload): Observable<any> {
        return this.http.post(`${this.apiUrl}/algos/420`, payload);
    }
    getJobQueueStatus(params) {
        return this.http.get(`${this.apiUrl}/algos/queue/status?object_id=${params.objectId}&object_type_id=${params.objectTypeId}&algo_id=420`);
    }
}
