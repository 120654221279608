// blue-text-formatter.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-blue-text-formatter',
  template: `
  <div>
    <div class="arrow-text-icon">
      <div class=" mr-l-3 green-right-arrow f-size-16">
        <i class="fas fa-right-long"></i>
      </div>
    </div>
    <div class="mr-left-20 blueLink pointer modal-feedback text-truncate"
         [title]="params.value"
         data-toggle="modal"
         data-backdrop="static"
         data-target="#tm-modal">
      {{ params.value }}
    </div>
  </div>
  `,
  styles: [
    // Add any additional styles if needed
  ]
})
export class RightArrowIconTextFormatterComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
