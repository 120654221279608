import { Injectable } from '@angular/core';
import { BaseService } from '../../common/services/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { User, Group } from '../../common/shared/zetta-models';
import { RequestOptions } from '@angular/http';

@Injectable({
    providedIn: 'root'
})

export class UserGroupService extends BaseService {

    private _userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

    constructor(private http: HttpClient) { super(); }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    public getUserAndGroups(userid, tenent_id, pageno, perpage): Observable<any> {
        // const userReponse = this.http.get(`${this.apiUrl}/users?pageno=${pageno}&pagesize=${perpage}`);
        const userReponse = this.http.get(`${this.apiUrl}/users/${userid}/tenants/${tenent_id}/users?pageno=${pageno}&pagesize=${perpage}`);
        const projectResponse = this.http.get(`${this.apiUrl}/users/${userid}/tenants/${tenent_id}/groups?pageno=${pageno}&pagesize=${perpage}&getAll=true`);
        return forkJoin([userReponse, projectResponse]);
    }

    // getPagedUsers(pageno, perpage): Observable<any> {
    //     return this.http.get(`${this.apiUrl}/users?pageno=${pageno}&pagesize=${perpage}`).pipe(
    //         map(this.extractData));
    // }

    getPagedUsers(userid, tenent_id, pageno, perpage): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/${userid}/tenants/${tenent_id}/users?pageno=${pageno}&pagesize=${perpage}`).pipe(map(this.extractData));
    }

    async getPagedGroups(userid, tenent_id, pageno, perpage) {
        return await this.http.get(`${this.apiUrl}/users/${userid}/tenants/${tenent_id}/groups?pageno=${pageno}&pagesize=${perpage}&getAll=true`)
            .pipe( // return this.http.get(`${this.apiUrl}/users/${userid}/groups?pageno=${pageno}&pagesize=${perpage}&getAll=true`).pipe(
                map(this.extractData)).toPromise();
    }

    getUsers(): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/`).pipe(
            map(this.extractData));
    }

    changePassword(user: User): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${user.user_id}/changepassword`, user).pipe(map(this.extractData));
    }

    saveUser(user: User): Observable<any> {
        if (user.user_id) {
            // Update user
            return this.http.put(`${this.apiUrl}/users/` + user.user_id, user).pipe(
                map(this.extractData));
        } else {
            // create new User
            return this.http.post(`${this.apiUrl}/users/`, user).pipe(
                map(this.extractData));
        }
    }

    deleteUsers(id: string): Observable<Object> {
        return this.http.delete(`${this.apiUrl}/users/` + id).pipe(
            map(this.extractData));
    }

    getGroups(): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/${this._userInfo.user_id}/groups/`).pipe(
            map(this.extractData));
    }

    saveGroup(group: Group, id): Observable<any> {
        if (group.group_id) {
            // Update group
            return this.http.put(`${this.apiUrl}/users/${id}/groups/` + group.group_id, group).pipe(
                map(this.extractData));
        } else {
            // create new group
            return this.http.post(`${this.apiUrl}/users/${id}/groups/`, group).pipe(
                map(this.extractData));
        }
    }
    
    deleteGroup(id: string, userid) {
        return this.http.delete(`${this.apiUrl}/users/${userid}/groups/` + id).pipe(
            map(this.extractData));
    }

    getProjectsByLoggedInUserId(userId): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects`).pipe(
            map(this.extractData));
    }

    getProjectDetailsByProjectId(userId, selectedProjectId): Observable<Object> {
        return this.http.get(`${this.apiUrl}/users/${userId}/projects/${selectedProjectId}/jobs`).pipe(
            map(this.extractData));
    }

    uploadFile() {
        return `${this.apiUrl}/users/import`;
    }
    changeLockStatus(userId: string,data): Observable<any> {
        // Update lock status
        return this.http.put(`${this.apiUrl}/users/${userId}`, data)
    }
    createUser(payload){
		console.log("Save User: "+payload);
		return this.http.post(`${this.apiUrl}/users`,payload);
	}
}
