// option-selection-renderer.component.ts
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';

@Component({
  selector: 'app-option-selection-renderer',
  template: `
 
      <label title="{{value}}"class="blueLink">{{value}}</label>
   
  `,
})
export class BlueTextRendererComponent implements OnInit, ICellRendererAngularComp{
    value: any;
    params: any;
    agInit(params: ICellRendererParams): void {
        this.value = params.value;

    }
    refresh(params: ICellRendererParams): boolean {
        return false;
      }
    
      constructor() { }
    
      ngOnInit(): void {
      }
}
