import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp, } from 'ag-grid-angular';

export interface CellParams {
  name?: string;
  active?: boolean;
}

@Component({
  selector: 'name-link-formatter',
  template: `<div *ngIf="value" class="text-center w-100 pd-20 blueLink" [ngClass] ="{'blueLink': active, 'disabled-state':!active}" title="{{value}}">{{value}}</div>`,
  styles: [
  ]
})
export class RuleCountFormatterComponent implements OnInit, ICellRendererAngularComp {

  active = false;
  value: any;
  params: any;
  loggedInUserDetail = JSON.parse(sessionStorage.getItem('userInfo'));

  agInit(params: ICellRendererParams & CellParams): void {
    if (params.data && params.data.action !== 'add') {
      this.value = params.value > 0 ? params.value : 'Add';
      this.active = this.loggedInUserDetail['enable_dq_rule'] ? true : false;
    }

    this.value = params.value > 0 ? params.value : 'Add';
    if (params.data && params.data.entitlement) {
      if (params.data.entitlement.read_write || params.data.entitlement.read || params.data.entitlement.is_admin) {
        this.active = true;
      } else {
        this.active = false;
      }
    }

    const disabledElements = document.querySelectorAll('.text-truncate.disabled-state'); 
disabledElements.forEach((element) => {
	const parentCell = element.closest('.ag-cell.ag-cell-not-inline-editing');
	if (parentCell) {
		parentCell.addEventListener('click', (event) => {
			event.stopPropagation();  
		});
	}
});
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.loggedInUserDetail['enable_dq_rule']) {
      this.active = true;
    }
  }

  onClick() {
    this.params.context.componentParent.updateRow(this.params.rowIndex);
  }

}
