import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ZmObservableService } from '../../zm-services/zm-observable.service';
import { ZmZettaUtils } from '../../zm-shared/zm-zettaUtils';
import { formatDate } from '@angular/common';
import * as d3 from 'd3';
import $ from 'jquery';

@Component({
    selector: 'zm-zetta-hbar-neg-chart',
    templateUrl: './zm-hbar-neg-chart.component.html',
    styleUrls: ['./zm-hbar-neg-chart.component.scss']
})
export class ZmHbarNegChartComponent implements AfterViewInit {

    public margin: any = {};
    public chartHeight: number;
    public chartWidth: number;
    public fontSize: number;
    public isChartInValid = false;
    public $container;
    public $container1;
    public colors: any;

    @Input() dataDataSource;
    @Input() chartOptions;
    @Input() chartColors;

    chartUniqueID: any = Math.floor(Math.random() * 1000);

    constructor(public cdRef: ChangeDetectorRef, public observeSvc: ZmObservableService, public zettaUtils: ZmZettaUtils) {}

    ngAfterViewInit() {
        this.isChartInValid = (!this.dataDataSource || this.dataDataSource.length == 0) ? true : false;
        if (!this.chartColors) {
            this.chartColors = ['#483da0', '#4d86ff', '#57cff2', '#2fb1eb', '#ffe453', '#ffc45f', '#e5b500', '#00eb09', '#00cb00'];
        }
        if(this.chartOptions['chartID']){
            this.chartUniqueID = this.chartOptions['chartID']
        }
        this.cdRef.detectChanges();
        if (this.dataDataSource && !this.isChartInValid) {
            setTimeout(() => {
                this.renderChart();
            }, 100);
        }

        let parent = this;
        this.observeSvc.refreshDataGrid.subscribe(function(data) {
            setTimeout(() => {
                $('#chart_' + parent.chartUniqueID).empty();
                parent.renderChart();
            }, 10);
        });
    }

    renderChart() { 
        let parent = this;
        parent.$container = $('#chart_' + parent.chartUniqueID);

        if(!parent.chartOptions['chartID']) {
            parent.chartWidth = parent.$container.parent().closest('div').width()-50;
            console.log('parent.chartWidth: ' + parent.chartWidth)
        } else {
            parent.$container1 = $('#subchartDS1_1').parent().closest('div');
            parent.chartWidth = parent.$container1.width()-50;
            console.log('parent.chartWidth: ' + parent.chartWidth)
        }

        if(screen.width < 1370) { 
            parent.chartHeight = parent.chartOptions['height'] * 0.77;
            parent.margin = {  
                top: parent.chartOptions['margin'].top * 0.30, 
                right: parent.chartOptions['margin'].right * 0.80, 
                bottom: parent.chartOptions['margin'].bottom * 1, 
                left: parent.chartOptions['margin'].left * 0.70 
            };
        }
        else { 
            parent.chartHeight = parent.chartOptions['height'];
            parent.margin = {  
                top: parent.chartOptions['margin'].top, 
                right: parent.chartOptions['margin'].right, 
                bottom: parent.chartOptions['margin'].bottom, 
                left: parent.chartOptions['margin'].left 
            };
        }

        parent.fontSize = (Math.min(parent.chartWidth, parent.chartHeight) / 4);

        if (parent.chartWidth === undefined || parent.chartHeight === undefined) {
            return false;
        }

        $('#chart_' + parent.chartUniqueID).empty();
        
        const contentWidth = parseFloat(parent.chartWidth.toString()) - parent.margin.left - parent.margin.right;
        const contentHeight = parseFloat(parent.chartHeight.toString()) - parent.margin.top - parent.margin.bottom;

        var posData = false;
        parent.dataDataSource.forEach(function(d, i) {
            // d.label = d['fulldate'];
            d.id = d.id;
            d.label = d.label;
            d.value = +d.value;
            if(d.value>=0){ posData = true; console.log('POS = ' + posData) }
        });

        if(!posData){ parent.margin.right = parent.margin.right + 120 }

        const svg = d3.select('#chart_' + parent.chartUniqueID).append('svg')
                .attr('width', parent.chartWidth)
                .attr('height', parent.chartHeight + 40);

        let lineTooltip = d3.select('#chart_' + parent.chartUniqueID).append('div').attr('class', 'toolTip');
        lineTooltip.style('display', 'none');

        parent.dataDataSource.sort(function(a, b) { return a.label - b.label; });

        var x = d3.scaleLinear()
            .domain(d3.extent(parent.dataDataSource, d => d.value))
            .rangeRound([0, contentWidth]);
    
        var y = d3.scaleBand()
            .domain(parent.dataDataSource.map(d => d.label))
            .rangeRound([0, contentHeight])
            // .rangeRound([contentHeight, 0])
            .padding(0.3);
            
        var g = svg.append('g')
            .attr('transform', 'translate(' + parent.margin.left + ',' + parent.margin.top + ')');
    
        // gridlines in x axis function
        function make_x_gridlines() {		
            return d3.axisBottom(x)
                .ticks(10)
                // .ticks(parent.dataDataSource.length+2)
        }
    
        // gridlines in y axis function
        // function make_y_gridlines() {		
        //     return d3.axisLeft(y)
        //         .ticks(28)
        // }

        if(!posData){ var more = contentWidth + 40 }
        if(posData){ var more = contentWidth}
        g.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("fill", parent.chartOptions.barColor1)
            .style("opacity", "0.08")
            .attr("height", contentHeight)
            .attr("width", more);
            //   .style("stroke", "royalblue")
            //   .style("stroke-width", "3.5px");
    
        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + contentHeight + ')')
            .call(d3.axisBottom(x))
            .append('text')
            .attr('transform', 'rotate(0)')
            .attr('x', `${ posData ? contentWidth/2+60 : contentWidth/2 + 70 }`)
            .attr('dx', '0.71em')
            .attr('y', +35)
            .attr('text-anchor', 'end')
            .text(this.chartOptions.labelX);

        if(posData){ 
            g.append('g')
                .attr('class', 'axis axis--y')
                .attr('transform', 'translate(0,0)')
                .call(d3.axisLeft(y).ticks(10, '%'))
                .append('text')
                // .attr('transform', 'rotate(-90)')
                .attr('transform', 'rotate(0)')
                .attr('y', -15)
                .attr('dy', '0.71em')
                .attr('text-anchor', 'end')
                .text(parent.chartOptions.labelY);
        }else{
            g.append('g')
                .attr('class', 'axis axis--y')
                .attr('transform', 'translate(' + x(0) + ',0)')
                .call(d3.axisRight(y).ticks(10, '%'))
                .append('text')
                // .attr('transform', 'rotate(-90)')
                .attr('transform', 'rotate(0)')
                .attr('y', -15)
                .attr('dy', '0.71em')
                .attr('text-anchor', 'end')
                .text(parent.chartOptions.labelY);
        }


        g.append("line")
            .attr("x1", x(0))
            .attr("x2", x(0))
            .attr("y1", 0)
            .attr("y2", contentHeight)
            .attr("stroke", "#777");

        if(!posData){ 
            g.append("line")
                .attr("x1", x(0)-x(0))
                .attr("x2", x(0))
                .attr("y1", contentHeight)
                .attr("y2", contentHeight)
                .style("stroke", "#000")
                .style("stroke-width", "0.6px");
        }
            
        // add the X gridlines
        g.append("g")			
            .attr("class", "grid")
            // .attr("transform", "translate(" + margin.left + "," + (contentHeight + margin.top) + ")")
            .attr("transform", "translate(0," + (contentHeight) + ")")
            .call(make_x_gridlines()
                .tickSize(-contentHeight)
                .tickFormat("")
            );

        //add the Y gridlines
        //  svg.append("g")			
        //  .attr("class", "grid")
        // //  .attr("transform", "translate(0," + (contentWidth+30) + ")")
        //  .call(make_y_gridlines()
        //      .tickSize(-contentWidth)
        //      .tickFormat("")
        //  )
    
        g.selectAll('.bar')
            .data(parent.dataDataSource)
            .enter().append('rect')
            .attr('class', 'bar')
            // .attr('class', d => `bar ${ d.value < 0 ? 'negative': 'positive' }`)
            .attr('fill', d => `${ d.value < 0 ? parent.chartOptions.barColor1 : parent.chartOptions.barColor2 }`)
            .on('mouseover', function() { lineTooltip.style('display', 'none'); })
            .on('mouseout', function() { lineTooltip.style('display', 'none'); })
            .on('mousemove', mousemove)
            .on('click', function(d, i) {
                if((parent.chartOptions['chartID']) && !parent.chartOptions['subchart']) {
                    for(let j=1; j<=parent.dataDataSource.length; j++){
                        document.getElementById('subchartDS2_' + j).style.display = 'none';
                    }
                    var elem = document.getElementById('subchartDS2_' + (i+1));
                    elem.style.display = 'inline';
                    elem.style.visibility = 'visible';
                    var elem = document.getElementById('panel4');
                    elem.style.overflow = 'auto'; 
                }
            })
            ///////// .style('fill', (d, i) => this.colors(i))
            // .attr("fill", parent.chartOptions.barColor1)
            ///////// .attr("opacity", "0.8")
            .attr('y', d => y(d.label))
            .attr('height', y.bandwidth())
            .attr('x', d => x(Math.min(0, d.value)))
            //.attr('width', d => x(0)); //this is the initial value
            .transition()
            .duration(250)
            .attr('width', d => Math.abs(x(d.value) - x(0))); //this is the initial value
            // .attr('width', d => x(0)) //this is the initial value
            // .attr('width', d => `${ d.value < 0 ? x(0) : x(0) }`)
                // .transition()
                // .duration(5000)
                ///////////// .delay(function(d,i){ return i*15})   //a different delay for each bar
            //////////// .attr('x', 0)
            // .attr('width', d => Math.abs(x(d.value) - x(0)));
            // .attr('width', d => `${ d.value < 0 ? x(0) : Math.abs(x(d.value) - x(0)) }`);
            

    
        //Tooltip mouseovers            
        function mousemove(d,i) {
        lineTooltip
            .style('left', d3.event.pageX + 'px')
            .style('top', d3.event.pageY - 35 + 'px')
            .style('display', 'inline-block')
            .html(function() {
                    return '<span>' + parent.chartOptions['labelY'] + ': <strong>' + d.label + '</strong></span><br>'+
                            '<span>' + parent.chartOptions['labelX'] + ': <strong>' + d.value + '</strong></span>';
            });
        };  
    }

    onResize() {
        if (this.chartOptions['resizeRequired']) {
            this.renderChart();
        }
    }

}