import { Component, OnInit, ViewContainerRef, OnDestroy  } from '@angular/core';
import { ProjectService } from '../project.service';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { optionSelectionFormatter } from '../../../../common/shared/formatters/optionSelectionFormatter';
import { blueTextModelFormatter } from '../../../../common/shared/formatters/blueTextModelFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Column, FieldType, Formatter, Formatters, GridOption, OnEventArgs, AngularGridInstance, Editors } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { Project } from '../project.model';
import { ActivatedRoute } from '@angular/router';
import $ from 'jquery';
import { matchFormatter } from '../../../../common/shared/formatters/matchFormatter';
import { noMatchFormatter } from '../../../../common/shared/formatters/noMatchFormatter';
import { notSureFormatter } from '../../../../common/shared/formatters/notSureFormatter';
import { FeedbackFilter } from '../../../../common/shared/filters/feedback-filter';
import { blueTextFormattter } from 'src/app/common/shared/formatters/blueTextFormatter';
import { checkBoxFormattter } from 'src/app/common/shared/formatters/checkBoxFormatter';
import {MessageService} from 'src/app/common/components/message/message.service';

@Component({
  selector: 'zetta-train-resolution-model',
  templateUrl: './train-resolution-model.component.html',
  styleUrls: ['./train-resolution-model.component.scss']
})
export class TrainResolutionModelComponent implements OnInit, OnDestroy {

  public suggestedClusters: object = {};
  public isSuggestedClusterLoading = false;
  public isSuggested = false;
  public suggestedColumns;
  public trainModelDataSource: object = {};
  public selectOptionDataSource: object = {};
  public suggestedClusterkeys = [];
  public rowData;
  public grid: AngularGridInstance;
  public suggestedGrid: AngularGridInstance;
  public matcherGrid: AngularGridInstance;
  public loggedInUserDetails = null;
  public selectedClusterName;
  public selectedCluster;
  public isSelectedClusterExpanded = false;
  public selectedColumnsfeedbackColumns;
  public currentStep = 0;

  viewTaskAssignments = false;
  taskAssignmentsColDef: any;
  isTaskAssignmentsLoading  = true;
  taskAssignmentsRowData: any;
  taskAssignmentsGridOptions: any;
  taskAssignmentsGrid: AngularGridInstance;


  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  trainModelTableColDef: any;
  gridOptions: any;
  popGridOptions: any;
  projectDetail = new Project();

  isLoading = false;
  isClusterItemsLoading = false;
  total = 0;
  page = 1;
  limit = 100;
  selectedClusterTotal = 0;

  constructor(
    private ProjectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    private _router: Router, public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.loggedInUserDetails = this.ProjectSvc.getObjectFromSession('userInfo');
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    this.getTrainModelList(this.page, this.limit);

    this.trainModelTableColDef = [
      {
        'displayname': 'Task Name',
        'physicalname': 'zs_cluster_identifier',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 130,
        'formatter': blueTextModelFormatter,
      },
      {
        'displayname': 'Records',
        'physicalname': 'zs_total_records_cluster',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-right',
        'headerCssClass': 'text-right',
        'formatter': numericFormatter,
      },
      {
        'displayname': 'Status',
        'physicalname': 'status',
        'sortable': false,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 140,
        'headerCssClass': 'pl-4 ml-3',
        'formatter': assignmentStatusFormatter,
      },
      {
        'displayname': 'Open',
        'physicalname': '',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-center',
        'formatter': optionSelectionFormatter,
      },
      {
        'displayname': 'Last Updated',
        'physicalname': 'last_updated',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'headerCssClass': 'pl-4',
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.taskAssignmentsGridOptions = {

      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 15,
    };

    this.popGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      frozenColumn: 2,
    };
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getTaskAssignmentsColDef() {
    this.taskAssignmentsColDef = [
      {
        'displayname': 'User Name',
        'physicalname': 'user_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 100,
        'formatter': blueTextFormattter,
      },
      {
        'displayname': 'Project Admin',
        'physicalname': 'isprojectadmin',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': checkBoxFormattter,
        'columnGroup': 'Authorizations'
      },
      {
        'displayname': 'Reviewer',
        'physicalname': 'isreviewer',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': checkBoxFormattter,
        'columnGroup': 'Authorizations'
      },
      {
        'displayname': 'Approver',
        'physicalname': 'isapprover',
        'sortable': false,
        'datatype': 'String',
        'filterable': false,
        'formatter': checkBoxFormattter,
        'columnGroup': 'Authorizations'
      }
    ];
  }

  getTrainModelList(pageno, limit = 1) {
    this.isLoading = true;
    this.ProjectSvc
    .getTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
      this.projectDetail.project_id, pageno, limit).subscribe((data) => {
      this.trainModelDataSource = data;
      this.isLoading = false;
      this.total = data['totalrecords'];
      if (this.grid) {
        this.trainModelDataSource['currentpage'].forEach(element => {
          element.id = Math.random();
        });
        this.grid.dataView.setItems(this.trainModelDataSource['currentpage']);
        this.grid.gridService.setSelectedRows([]);
      }
    }, err => {
      this.isLoading = false;
      this.trainModelDataSource['currentpage'] = [];
    });
  }

  onSelectedCluster(e) {
    this.isSelectedClusterExpanded = !this.isSelectedClusterExpanded;
  }

  getSelectedClusterCount() {
    return this.selectedClusterTotal;
  }

  selectOptionModelList(clusterId, limit = 5) {
    this.isLoading = true;
    this.isSelectedClusterExpanded = false;
    this.selectedClusterTotal = 0;
    this.ProjectSvc
      .getSelectOptionTrainEntitiesByProjectId(this.loggedInUserDetails.user_id,
      clusterId, this.projectDetail.project_id, this.page, limit).subscribe((data) => {
      this.selectOptionDataSource = data;
      this.rowData = this.selectOptionDataSource['currentpage'];
      this.selectedColumnsfeedbackColumns = this.selectOptionDataSource['columns'];
      this.selectedColumnsfeedbackColumns.unshift(...this.getFeedbackColumns());
      this.rowData.forEach((element: any) => {
        element.id = Math.random();
        this.selectedClusterName = element.zs_cluster_identifier;
      });

      this.getSuggestedCluster();

      this.selectedClusterTotal = data['totalrecords'];
      if (this.matcherGrid) {
        this.matcherGrid.dataView.setItems(this.rowData);
        this.matcherGrid.gridService.setSelectedRows([]);
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  getSuggestedCluster(): void {
    // this.isSuggestedClusterLoading = true;
    this.isSuggested = true;
    this.ProjectSvc
      .getSuggestedCluster({userId: '1', projectId: '183', clusterId: '26646ad7-f5dd-444d-a368-ce4b2e93d92a', clusterName: 'Toronto Standard Condo Corp. No. 2481'})
      .subscribe( resp => {
        this.isSuggestedClusterLoading = true;
        this.suggestedClusterkeys = Object.keys(resp.currentpage);
        this.suggestedClusters = resp.currentpage;
          // if (this.suggestedGrid) {
            this.suggestedColumns =  resp.columns;
            this.suggestedClusterkeys.forEach(e => {
              this.suggestedClusters[e].forEach(element => {
                element.id = Math.random();
            });
          });
          this.isSuggested = false;
      // }

  });
}

  getFeedbackColumns() {
    return [{
      'displayname': '',
      'physicalname': 'match',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': matchFormatter,
      'filter': {
        model: new FeedbackFilter('Match', 'match-feedback')
      },
    },
    {
      'displayname': '',
      'physicalname': 'nomatch',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': noMatchFormatter,
      'filter': {
        model: new FeedbackFilter('No Match', 'nomatch-feedback')
      },
    },
    {
      'displayname': '',
      'physicalname': 'notsure',
      'sortable': false,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 70,
      'formatter': notSureFormatter,
      'filter': {
        model: new FeedbackFilter('Not Sure', 'notsure-feedback')
      },
    }];
  }

  onSelectedClusterCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.matcherGrid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('radio-formatter')) {
        if ($(eventEle.target).hasClass('match-formatter')) {
          row.zs_feedbackid = '1';
        } else if ($(eventEle.target).hasClass('nomatch-formatter')) {
          row.zs_feedbackid = '0';
        } else if ($(eventEle.target).hasClass('notsure-formatter')) {
          row.zs_feedbackid = '2';
        }
        this.matcherGrid.gridService.updateDataGridItem(row, false);
        this.updateFeedback(row);
      }
    }
  }

  updateFeedback(data) {
    const requestPayload = [];
    requestPayload.push({
      'zs_recid': data.zs_recid,
      'zs_feedbackid': data.zs_feedbackid
    });
    this.isClusterItemsLoading = true;
    this.ProjectSvc
      .updateFeedback({userId: this.loggedInUserDetails.user_id,
      projectId: this.projectDetail.project_id,  clusterId: data.zs_cluster_id, payload: requestPayload})
      .subscribe(resp => {
        this.isClusterItemsLoading = false;
          this.selectedCluster.status = resp.status;
        this.grid.gridService.updateDataGridItem(this.selectedCluster, false);
      }, err => {
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onSuggestedGridCreation(grid) {
    this.suggestedGrid = grid;
  }

  onModelMatchingCreation(grid) {
    this.matcherGrid = grid;
  }

  onTaskAssignmentsGridCreation(grid) {
    this.taskAssignmentsGrid = grid;
  }

  onTaskAssignmentsGridCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.taskAssignmentsGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = this.taskAssignmentsGrid.slickGrid.getColumns()[args.cell].field;
    const dataSet = this.taskAssignmentsGrid.dataView.getItems();

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('checkmark') ) {
        const currentValue = row[fieldName];
        if (currentValue !== undefined) {
          row[fieldName] = !row[fieldName];
        } else {
          row[fieldName] = true;
        }
        this.taskAssignmentsGrid.gridService.updateDataGridItem(row, false);

        // TODO: Call API to Update DB

      }
    }
  }

  goToPage(n: number): void {
    this.page = n;
    this.getTrainModelList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getTrainModelList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getTrainModelList(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtilsSvc.getTotalPages(this.total, this.limit);
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    this.selectedCluster = row;
    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/projects', row.project_id]);
      } else if ($(eventEle.target).hasClass('modal-feedback')) {
        this.selectOptionModelList(row.zs_cluster_id);
      }
    }
  }

  onReRunModel(data) {
    // Scenario id is 1 for this case
    this.ProjectSvc.runModel(this.projectDetail.project_id, 1).subscribe(resp => {
    }, err => {
      // alert(err.error.message);
      this.messageSvc.sendMessage({message: err.error.message, type: 'INFO', hideboard: true});
    });
  }

  onTaskAssignments() {
    if (!this.viewTaskAssignments) {
      this.getTaskAssignmentsColDef();
      this.isTaskAssignmentsLoading = true;
      this.taskAssignmentsRowData = undefined;
      // TODO: Need Proper GET API for user with existing access.
      this.ProjectSvc
      .getProject( this.ProjectSvc.getObjectFromSession('userInfo').user_id, this.projectDetail.project_id).subscribe(responseList =>  {
        this.projectDetail = responseList;
        this.taskAssignmentsRowData = [... this.projectDetail.users];
        this.taskAssignmentsRowData.forEach(user => {
          user.id = Math.random();
        });
        this.isTaskAssignmentsLoading = false;
      }, err =>  {
        this.taskAssignmentsRowData = [];
        this.isTaskAssignmentsLoading = false;
      });
    } else {
      this.taskAssignmentsRowData  = undefined;
    }
    this.viewTaskAssignments = !this.viewTaskAssignments;
  }

  toggleAccordian(event) {
    const target = event.target.target;
    if ($(target).hasClass('show')) {
      $(target).removeClass('show');
      // this.isSuggested = false;
    } else {
      $(target).addClass('show');
      this.getSuggestedCluster();
    }
  }

}
