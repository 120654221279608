import { Project } from '../../../../../zettasense/content/project/project.model';
import { IDataset } from '../../../../../common/components/datasets/dataset.model';
import { ProjectService } from '../../../../../zettasense/content/project/project.service';
import { DatasetService } from '../../../../../common/components/datasets/dataset.service';
import { AngularGridInstance, Formatter, Column, Editors } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { AppGlobals } from '../../../../../common/shared/app.globals';
import { MessageService } from '../../../../../common/components/message/message.service';
import { radioButtonFormatter } from 'src/app/common/shared/formatters/radioButtonFormatter';
import { environment } from 'src/environments/environment';
import { viewUserFormatter } from '../../../../../common/shared/formatters/viewUserFormatter';
import * as _ from 'lodash';

export const radioButtonFormattr: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    columnDef.field = columnDef.field === 'Read/Write' ? 'isReadWriteOnly' : columnDef.field;    
    return value ? `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div id="cell-${columnDef.field}-${row}" class="formatter match-radio-formatter div-checkbox radio-iteem">
                        <input type="radio" name="radio-${row}" id="radio-${columnDef.field}-${row}" checked>
                        <label for="radio-${columnDef.field}-${row}" class="radio-formatter radio-label m-0 pointer"></label>
                    </div>` 
                 : `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div id="cell-${columnDef.field}-${row}" class="formatter match-radio-formatter div-checkbox radio-iteem">
                        <input type="radio" name="radio-${row}" id="radio-${columnDef.field}-${row}">
                        <label for="radio-${columnDef.field}-${row}" class="radio-formatter radio-label m-0 pointer"></label>
                    </div>`;
}
export const userFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<i class='fa fa-user userIcon'></i><span class="roundedCorner" title='${dataContext.selectUsersCount}'>${dataContext.selectUsersCount}</span>`;
}
    
const RIGTH_EMPTY_GRID_MSG = 'Select one or more groups from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';

@Component({
  selector: 'zetta-workbook-entitlements',
  templateUrl: './workbook-entitlements.component.html',
  styleUrls: ['./workbook-entitlements.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WorkbookEntitlementsComponent implements OnInit, OnDestroy, DoCheck {

    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;

    tableSettings: object = { 'height': "100%", 'width': "100%" };
    rightTableSettings: object = { 'height': "100%", 'width': "99.42%" };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];
    datasetId;
    selectedCenterRowIndex = -1;
    tableGridSelectedItemsSize = 0;
    usersSelectedRows: any = [];
    tableSelectedRows: any = [];
    groupSelected = 0;
    userSelected = 0;
    groupName: string;
    selectedUserLength: number;
    selectRightGridUsers  = {};
    isShowRightGridDataSource = false;
    isAdminSelectAll = false;
    isReadOnlySelectAll = false;
    isReadWriteOnlySelectAll = false;
    isAdminChecked = false;
    isReadWriteonlyChecked = false;
    isReadonlyChecked = false;
    isUserSelected = false;

    isAdminChk: any = { top: '0px', left: '0px' };
    isReadOnlyChk: any = { top: '0px', left: '0px' };
    isReadWriteOnlyChk: any = { top: '0px', left: '0px' };

    usersSavedIds: any = [];
    dataset_ids: any = [];

    constructor(public projectSvc: ProjectService,
        public datasetSvc: DatasetService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService) { }
    
    ngDoCheck() {
        if ( $("#cell-isAdmin-0").length ) {
            var isAdmin = $("#cell-isAdmin-0").offset();
            var isReadOnly = $("#cell-isReadOnly-0").offset();
            var isReadWriteOnly = $("#cell-isReadWriteOnly-0").offset();
            this.isAdminChk = { top: (Math.round(isAdmin.top) - 32) + 'px', left: (Math.round(isAdmin.left)-8) + 'px' };
            this.isReadOnlyChk = { top: (Math.round(isReadOnly.top) - 32) + 'px', left: (Math.round(isReadOnly.left)-8) + 'px' };
            this.isReadWriteOnlyChk = { top: (Math.round(isReadWriteOnly.top) - 32) + 'px', left: (Math.round(isReadWriteOnly.left)-8) + 'px' };
        }  
    }
  
    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');

        this.datasetId = '1';

        this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
        this.progressState.isEdit = sessionStorage.getItem('newDataSet') === 'true' ? false : this.progressState.isEdit;
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        this.showBreadcrumb = true;

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
            if(sessionStorage.getItem("bulkDatasetIds")) {
                this.dataset_ids = sessionStorage.getItem("bulkDatasetIds").split(",");
            } 
        } else {
            if(this.datasetId === null) {
                this.activatedRoute.parent.params.subscribe(params => {
                    this.datasetId = params['id'];
                });
            }
            // this.dataset_ids = [this.datasetId];
            // this.datasetSvc.getDataset({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], dataset_id: this.datasetId }).subscribe((data) => {
            //     this.usersSavedIds = data.entitlement.users;
            // }, err => { })
        }


        

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.gridColumnsDef = [{
            'displayname': 'Group Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Users',
            'physicalname': '',
            'sortable': true,
            'datatype': 'String',
            'filterable': false,
            'minWidth': 80,
            'formatter': userFormatter
        }, {
            'displayname': ' ',
            'physicalname': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'maxWidth': 96,
            'formatter': viewUserFormatter
        }];

        this.rightGridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 180,
        }, {

            'displayname': 'Admin',
            'physicalname': 'isAdmin',
            'sortable': false,
            'datatype': 'boolean',
            'filterable': false,
            'formatter': radioButtonFormattr,
            'minWidth': 130,
            'headerCssClass': 'mr-l-12',
        }, {
            'displayname': 'Read Only',
            'physicalname': 'isReadOnly',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'columnGroup': 'Authorizations',
            'formatter': radioButtonFormattr,
            'minWidth': 130,
            'headerCssClass': 'mr-l-13',
        }, {
            'displayname': 'Read/Write',
            'physicalname': 'isReadWriteOnly',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'columnGroup': 'Authorizations',
            'formatter': radioButtonFormattr,
            'minWidth': 130,
        }];
        
        setTimeout(() => {
            this.datasetSvc.getUsersGroup(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
                this.leftGridDataSource = responseList['currentpage'];            
                this.leftGridDataSource.forEach((element, index) => {
                    element.id = Math.random();
                    element.selectGroupsCount = 0;
                    element.selectUsersCount = 0;
                    element.defaultSelectedUsersLength = 0;                
                    element['selectedRightGridRows'] = [];
                    element.groupsCount = 0;

                    if (element.users.length){
                    element.users.forEach((user, idx) => {
                        user.isAdmin = false;
                        user.isReadOnly = false;
                        user.isReadWriteOnly = false;
                        this.usersSavedIds.forEach(u => {
                            if (user.user_id === u.user_id) {
                                if (u.is_admin === true) {
                                    user.isAdmin = true;
                                } else if (u.read_write === true) {
                                    user.isReadWriteOnly = true;
                                } else if (u.read_only === true) {
                                    user.isReadOnly = true;
                                }

                                if (!this.tableSelectedRows.includes(index)) {
                                    this.tableSelectedRows.push(index);
                                    element.groupsCount = element.groupsCount + 1;
                                }

                                if (!element['selectedRightGridRows'].includes(idx)) {
                                    element['selectedRightGridRows'].push(idx);
                                    element.selectUsersCount = element.selectUsersCount + 1;
                                }
                            }
                        });
                    });
                    element.defaultSelectedUsersLength = element['selectedRightGridRows'].length;
                    }
                });        
                
                this.isDataReady = true;
                this.rightGridDataSource = this.leftGridDataSource[0].users;
                this.rightGridRows = this.rightGridDataSource.length;
                if (this.showBreadcrumb) {
                    this.selectedCenterRowIndex = 0;
                    this.isShowRightGridDataSource = true;
                    this.groupSelected = 1;
                }
                this.progressState.states = this.zettaUtils.getStateList('create-dataset');
                this.progressState.currentStateIndex = 2;
                this.progressState.currentStateInfo = 'Saved...';
                this.progressState.type = 'Data Set';
            }, err => {
                this.messageSvc.sendMessage({ message: 'User entitlements get users has failed', type: 'INFO', hideboard: true });
            })
        }, 500);
    }

    onLeftGridCellClicked(e) {
        const args = e.args;
        var adminselectedrows = [];
        var readonlyselectedrows = [];
        var readwriteonlyselectedrows = [];
        let userDataSource = [];
        const eventEle = e.eventData;
        if (eventEle.target.title === 'View Users') {
            this.selectedCenterRowIndex = args.row;
            const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);      
            this.rightGridDataSource = row.users;
            this.groupName = row.name;
            this.enableNextButtton();
            row['selectedRightGridRows'] = (row['selectedRightGridRows'] && row['selectedRightGridRows'].length > 0) ? row['selectedRightGridRows'] : [];
            this.rightGridDataSource.forEach(element => {
                element.id = Math.random();
            });
            if (this.rightGrid) {                
                this.rightGrid.dataView.setItems(this.rightGridDataSource);
                if (row['selectedRightGridRows'].length === 0) {
                   // this.enableAllCheckbox(row);
                } else {
                    this.rightGrid.gridService.setSelectedRows(row['selectedRightGridRows']);
                    this.enableAllCheckbox(row);
                }

                this.rightGridDataSource.forEach((row, index) => {
                    if (row['isAdmin'] === true) {
                        adminselectedrows.push(index);
                    }
                    if (row['isReadOnly'] === true) {
                        readonlyselectedrows.push(index);
                    }
                    if (row['isReadWriteOnly'] === true) {
                        readwriteonlyselectedrows.push(index);
                    }

                    if (adminselectedrows.length == this.rightGridDataSource.length) {
                        $("#isAdmin").prop("checked", true);
                        this.isAdminChecked = true;
                    } else {
                        $("#isAdmin").prop("checked", false);
                        this.isAdminChecked=false;
                    }

                    if (readonlyselectedrows.length == this.rightGridDataSource.length) {
                        $("#isReadOnly").prop("checked", true);
                        this.isReadonlyChecked = true;
                    } else {
                        $("#isReadOnly").prop("checked", false);
                        this.isReadonlyChecked=false;
                    }

                    if (readwriteonlyselectedrows.length == this.rightGridDataSource.length) {
                        $("#isReadWriteOnly").prop("checked", true);
                        this.isReadWriteonlyChecked = true;
                    } else {
                        $("#isReadWriteOnly").prop("checked", false);
                        this.isReadWriteonlyChecked = false;
                    }
                });
            }
            this.isShowRightGridDataSource = true;
        }
    }

    leftGridCreated(grid) {
        const parent = this;
        parent.leftGrid = grid;
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.tableGridSelectedItemsSize = args.rows.length;
            parent.isShowRightGridDataSource = false;
            parent.tableSelectedRows = args.rows;
            parent.reRenderSlickGrid(parent.leftGrid);
            parent.groupSelected = args.rows.length;
            parent.usersSelectedRows = args.rows;
            parent.updateLeftGridProperties();
            if (parent.tableGridSelectedItemsSize == 0) {
                parent.isShowRightGridDataSource = false;
                parent.rightGridDataSource.forEach(row => {
                    if (row['isAdmin'] === true) { 
                        row['isAdmin'] = false; 
                    }

                    if (row['isReadOnly'] === true) { 
                        row['isReadOnly'] = false; 
                    }

                    if (row['isReadWriteOnly'] === true) { 
                        row['isReadWriteOnly'] = false; 
                    }

                    $("#isAdmin").prop("checked", false);
                    $("#isReadOnly").prop("checked", false);
                    $("#isReadWriteOnly").prop("checked", false);
                });
                parent.selectedUserLength = 0;
            }
            if (parent.leftGridDataSource.length !== parent.tableGridSelectedItemsSize) {
                parent.reRenderSlickGrid(parent.leftGrid);
            }
        });
        parent.leftGrid.slickGrid.setSelectedRows(parent.tableSelectedRows);  // OjO <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
        parent.isShowRightGridDataSource = true;
    }

    updateLeftGridProperties() {
        const parent = this;
        parent.userSelected = 0;
        for (let centerGridIndex = 0; centerGridIndex < parent.leftGridDataSource.length; centerGridIndex++) {
            const leftGridRow = parent.leftGridDataSource[centerGridIndex];
            leftGridRow.selectUsersCount = 0; // -1;
            leftGridRow.selectGroupsCount = 0; // -1;
            for (let selectedRowsIndex = 0; selectedRowsIndex < parent.usersSelectedRows.length; selectedRowsIndex++) {
                if (centerGridIndex === parent.usersSelectedRows[selectedRowsIndex]) {
                    leftGridRow.selectUsersCount = leftGridRow.defaultSelectedUsersLength;
                    leftGridRow.selectGroupsCount = parent.groupSelected;
                    parent.userSelected += leftGridRow.selectUsersCount;
                    break;
                }
            }
        }
        parent.reRenderSlickGrid(parent.leftGrid);
    }

    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
        const dataSet = this.rightGrid.dataView.getItems();
        row['selectedRightGridRows'] = args.row;
        if (row !== undefined && row !== null) {
            if (fieldName === 'isAdmin') {
                row['isAdmin'] = !row['isAdmin'];
                row['isReadOnly'] = false;
                row['isReadWriteOnly'] = false;
            } else if (fieldName === 'isReadOnly') {
                row['isAdmin'] = false;
                row['isReadOnly'] = !row['isReadOnly'];
                row['isReadWriteOnly'] = false;
            } else if (fieldName === 'isReadWriteOnly') {
                row['isAdmin'] = false;
                row['isReadOnly'] = false;
                row['isReadWriteOnly'] = !row['isReadWriteOnly'];
            }
            this.enableNextButtton();
            this.enableAllCheckbox(args.row);
        }
    }

    rightGridCreated(grid) {
        const parent = this;
        parent.rightGrid = grid;
        parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            const rightGridRow = parent.leftGridDataSource[parent.selectedCenterRowIndex];
            rightGridRow['selectedRightGridRows'] = args.rows;
            rightGridRow.defaultSelectedUsersLength = args.rows.length;
            parent.selectedUserLength = args.rows.length;
            parent.selectRightGridUsers[rightGridRow.group_id] = [];
            rightGridRow['selectedRightGridRows'].forEach((item) => {
                parent.selectRightGridUsers[rightGridRow.group_id].push(parent.rightGrid.gridService.getDataItemByRowIndex(item));
            });
            if (rightGridRow['selectedRightGridRows'].length == 0) {
                parent.rightGridDataSource.forEach((row, index) => {
                    if (row['isAdmin'] === true) {
                        row['isAdmin'] =! row['isAdmin'];
                    }

                    if (row['isReadOnly'] === true) {
                        row['isReadOnly'] =! row['isReadOnly'];
                    }

                    if (row['isReadWriteOnly'] === true) {
                    row['isReadWriteOnly'] =! row['isReadWriteOnly'];
                    }
                });  
            }
            parent.rightGrid.slickGrid.setSelectedRows(rightGridRow['selectedRightGridRows']);
            parent.reRenderSlickGrid(parent.rightGrid);
            parent.reRenderSlickGrid(parent.leftGrid);
            parent.updateLeftGridProperties();
        });
        parent.rightGrid.slickGrid.setSelectedRows(parent.leftGridDataSource[parent.selectedCenterRowIndex]['selectedRightGridRows']);
        // parent.rightGrid.gridService.highlightRow([3, 4, 8, 9], 2000);
        parent.leftGridDataSource.forEach((element, index) => {
            parent.leftGridDataSource[index].selectUsersCount = parent.leftGridDataSource[index]['selectedRightGridRows'].length;
        });
    }

    reRenderSlickGrid(gridInstance) {
        gridInstance.slickGrid.updateRowCount();
        gridInstance.slickGrid.render();
        gridInstance.slickGrid.invalidate();
        this.enableNextButtton();
    }

    onNext() {
        {
            const rows: any[] = [];
            const grouprows: any[] = [];
            const leftGridDataService = this.leftGrid.gridService;
            const selectedRows: number[] = leftGridDataService.getSelectedRows();
            const parent = this;

            let userdata: any[] = [];;
            let groupdata: any[] = [];;
            selectedRows.forEach(index => {
                const row = leftGridDataService.getDataItemByRowNumber(index);
                if (row !== undefined && row !== null) {
                    grouprows.push(row);
                }
            });

            grouprows.forEach(ele => {
                const selected_users = ele.users.filter(user=> user.isAdmin === true || user.isReadOnly === true || user.isReadWriteOnly === true)
                if(selected_users.length){
                    selected_users.forEach(user => {
                        userdata.push({
                            "user_id": user.user_id,
                            "is_admin": user.isAdmin,
                            "read_only": user.isReadOnly,
                            "read_write": user.isReadWriteOnly
                        });
                    });
                }                
                groupdata.push({
                    "group_id": ele.group_id,
                    "users": userdata
                });
                userdata = [];
            }); 

            const postData: any = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                payload: {
                    "datasets": this.dataset_ids,
                    "entitlements": groupdata                   
                }
            };
          
            this.datasetSvc.updateuserEntitlements(postData).subscribe(resp => {
                if(resp){
                    // console.log(resp)
                    if (this.showBreadcrumb) {
                        // this.router.navigate(['/zs/datasets/' + this.datasetId]);
                    } else {
                        this.router.navigate(['/zs/datasets/schedule-refresh'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
                    }
                }               
            },
            error => {
                this.messageSvc.sendMessage({message: 'User entitlements update dataset has failed', type: 'INFO', hideboard: true});
            });
        }
    }

    onPrevious() {
        this.router.navigate(['/zs/datasets/define-datasets'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    enableAllCheckbox(centerGridRow) {
        const parent = this;
        var rows = [];
        var adminselectedrows = [];
        var readonlyselectedrows = [];
        var readwriteonlyselectedrows = [];

        parent.rightGridDataSource.forEach((row, index) => {
            if (row['isAdmin'] === true || row['isReadOnly'] === true || row['isReadWriteOnly'] === true) {
                rows.push(index);
            } else if (row['isAdmin'] === false && row['isReadOnly'] === false && row['isReadWriteOnly'] === false) {
                rows = rows.filter(row => row !== index);
                $("#isAdmin").prop("checked", false);
                $("#isReadOnly").prop("checked", false);
                $("#isReadWriteOnly").prop("checked", false);
            }

            if (row['isAdmin'] === true) {
                adminselectedrows.push(index);
            }

            if (row['isReadOnly'] === true) {
                readonlyselectedrows.push(index);
            }

            if (row['isReadWriteOnly'] === true) {
                readwriteonlyselectedrows.push(index);
            }
        });

        if (adminselectedrows.length == parent.rightGridDataSource.length) {
            $("#isAdmin").prop("checked", true);
            this.isAdminChecked = true;
        } else {
            $("#isAdmin").prop("checked", false);
            this.isAdminChecked = false;
        }

        if (readonlyselectedrows.length==parent.rightGridDataSource.length) {
            $("#isReadOnly").prop("checked", true);
            this.isReadonlyChecked = true;
        } else {
            $("#isReadOnly").prop("checked", false);
            this.isReadonlyChecked = false;
        }

        if (readwriteonlyselectedrows.length==parent.rightGridDataSource.length) {
            $("#isReadWriteOnly").prop("checked", true);
            this.isReadWriteonlyChecked = true;
        } else {
            $("#isReadWriteOnly").prop("checked", false);
            this.isReadWriteonlyChecked = false;
        }
        parent.rightGrid.slickGrid.setSelectedRows(rows);
        parent.reRenderSlickGrid(parent.rightGrid);
    }

    onCheckAll(opc,e) {
        var rows = [];
        if (opc === 'isAdmin')
        this.isAdminChecked = !this.isAdminChecked;

        if (opc === 'isReadOnly')
        this.isReadonlyChecked =! this.isReadonlyChecked;

        if (opc === 'isReadWriteOnly')
        this.isReadWriteonlyChecked =! this.isReadWriteonlyChecked;

        this.rightGridDataSource.forEach((row, index) => {
            rows.push(index);
            if (opc === 'isAdmin' && this.isAdminChecked) {
                row['isAdmin'] = true;
                row['isReadOnly'] = false;
                row['isReadWriteOnly'] = false;
            } else {
                row['isAdmin'] = false;
                row['isReadOnly'] = false;
                row['isReadWriteOnly'] = false;
            }

            if (opc === 'isReadOnly' && this.isReadonlyChecked) {
                row['isReadOnly'] = true;
                row['isAdmin'] = false;
                row['isReadWriteOnly'] = false;
            }
            
            if (opc === 'isReadWriteOnly' && this.isReadWriteonlyChecked) {
                row['isAdmin'] = false;
                row['isReadOnly'] = false;
                row['isReadWriteOnly'] = true;
            }
      
            if(row['isAdmin'] == false && row['isReadOnly'] == false && row['isReadWriteOnly'] == false) {
                this.rightGrid.slickGrid.setSelectedRows([]);
                if (e.currentTarget.checked==true) {
                    e.currentTarget.checked=false;
                }
            } else {
                this.rightGrid.slickGrid.setSelectedRows(rows);
            }
        });
        
        this.selectedUserLength = this.rightGridDataSource.length;
        this.reRenderSlickGrid(this.rightGrid);
    }

    enableNextButtton(){
        let selected_rows = this.rightGridDataSource.filter(ele => ele.isAdmin == true || ele.isReadOnly == true || ele.isReadWriteOnly == true);
        if (selected_rows.length > 0){
            this.isUserSelected = true
        } else {
            this.isUserSelected = false;
        }
    }

}


