import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ZmObservableService {
  refreshDataGridSub: Subject<boolean> = new Subject<boolean>();
  getEntityNameSub: Subject<string> = new Subject<string>();

  set refreshDataGrid(value: any) {
    this.refreshDataGridSub.next(value);
  }
  get refreshDataGrid() {
    return this.refreshDataGridSub.asObservable();
  }
  set setEntityName(value: any) {
    this.getEntityNameSub.next(value);
  }
  get getEntityName() {
    return this.getEntityNameSub.asObservable();
  }
}
