import { Compiler, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from '../common/shared/app.globals';
import { AuthenticationService } from '../common/services/authentication.service';
import { ZettaUtils } from '../common/shared/zettaUtils';
import { BaseService } from '../common/services/base-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'zetta-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

  public loginUser: Object = sessionStorage.getItem('userInfo');
  public userinfo;
  public profilePic;
  defaultApp;
  defaultAppId;
  existApp = true;
  valueResolve = true;
  valueClassify = false;
  valueIngest = false;
  bgAvatar = "rgb(44,62,80)";

	private appLocation: String; // Hold app location currently only supports ingest app only. If we are supporting multiple then this var should be an array/list. 
	private userApps: any[];
	private _apiUrl: string = environment.config.API_URL;

  constructor(private _router: Router,
              private _authSvc: AuthenticationService,
              public zettaUtils: ZettaUtils,
              private baseService: BaseService,
              private http: HttpClient,
              private _compiler:Compiler) {
  }

  ngOnInit() {
    this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.defaultAppId = this.userinfo.preference ? this.userinfo.preference.default_app_id : -1;
		this.defaultAppId === -1 ? this.defaultApp = '' : this.defaultApp = this.userinfo.apps.find( app => parseInt(app.app_id) === parseInt(this.userinfo.preference.default_app_id)).short_name;

    if(this.defaultAppId === 'zs-cl') {
      this.valueResolve = false;
      this.valueClassify = true;
      this.valueIngest = false;
    }else if(this.defaultAppId === 'z-ing') {
      this.valueResolve = false;
      this.valueClassify = false;
      this.valueIngest = true;
    }else if(this.defaultAppId === 'zs') {
      this.valueResolve = true;
      this.valueClassify = false;
      this.valueIngest = false;
    } else {
      this.valueResolve = false;
      this.valueClassify = false;
      this.valueIngest = false;
    }

    if(sessionStorage.getItem("profilePicSrc")){
			if(sessionStorage.getItem("profilePicSrc")){
				this.profilePic=sessionStorage.getItem("profilePicSrc");
			}
		}else{
			this.getUserPic();
		}
    this.getAppLocation();
  }

  public getUserPic() {		
		sessionStorage.setItem('isUserProfile', "1");
		this.baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {
      let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.profilePic = reader.result;
			}, false);
			if (image && image.size && image.type=="image/gif") {
				this.profilePic = reader.readAsDataURL(image);
			}else{
				this.profilePic=null;
			}
		}, err => {
			this.profilePic = err.url;
		});
	}

  reDirectApp(routeUrl, IsZmLogin, IsZsLogin, short_name) {
		if(short_name === 'z-ing') {
			// sessionStorage.setItem('selectedApp', short_name);
			const selectedAppShortName = sessionStorage.getItem('selectedApp') || 'zs';
			var redirectingFrom = this.userApps.filter((app)=>{	return app.short_name === selectedAppShortName;	})[0].app_location;

      let index = redirectingFrom.indexOf("#");
			redirectingFrom = redirectingFrom.substring(0, index);

			//get the status of ingest host if primary location is not available then re-direct to secondary location.
			// this.baseService.getIngestHostStatus(this.userinfo.user_id, this.http).subscribe((result) => {
			// 	let ingestApp = result[0];
			// 	console.log("ingestApp:", ingestApp);
			// 	if(ingestApp.is_primary_live){
			// 		this.appLocation = ingestApp.app_location;
      //     var isPrimaryUp = "true";
			// 	} else {
			// 		this.appLocation = ingestApp.sec_app_location;
      //     var isPrimaryUp = "false";
			// 	}
			// 	window.location.href = `${this.appLocation}?userId=${this.userinfo.user_id}&tenantId=${this.userinfo.tenant_id}&firstName=${this.userinfo.first_name}&lastName=${this.userinfo.last_name}&bgAvatar=${this.bgAvatar}&defAppId=${this.userinfo.preference.default_app_id}&profilePic=${this.profilePic}&isPrimaryUp=${isPrimaryUp}&redirectingFrom=${redirectingFrom}&redirectingFromAPI=${this._apiUrl}`;
			// }, error => { 
			// 	console.log(`Error in getting app location:`, error);
			// });

      this.baseService.getIngestLocationByTenant(this.userinfo.user_id, this.userinfo.tenant_id, this.http).subscribe((result) => {
				let ingestApp = result;
				ingestApp.forEach(element => {
					if (element.is_current_environment) {
						this.appLocation = element.ingest_acquisition_url;
					}
				});
				window.location.href = `${this.appLocation}:18630/?userId=${this.userinfo.user_id}&
																	tenantId=${this.userinfo.tenant_id}&
																	firstName=${this.userinfo.first_name}&
																	lastName=${this.userinfo.last_name}&
																	defAppId=${this.userinfo.preference.default_app_id}&
																	redirectingFrom=${redirectingFrom}&
																	redirectingFromAPI=${this._apiUrl}&
                                  access_token=${localStorage.getItem('access_token')}`;
			}, error => { 
				console.log(`Error in getting app location:`, error);
			});
		} else {
			sessionStorage.setItem('selectedApp', short_name);
			sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, IsZmLogin);		//'isZmloggedIn'
			sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, IsZsLogin);		//'isZsloggedIn'
      // this._router.navigate([routeUrl]);
			this._router.navigate([short_name + '/home']);
		}
	}

  logout() {
    // clearing the cache and session storage before login
		sessionStorage.clear();
    localStorage.clear();
		this._compiler.clearCache();
    localStorage.clear();
    this._authSvc.signOut();
  }

  changed1() {    //zs
    if(this.valueResolve === true){
        this.valueResolve = false;
        this.defaultAppId = -1;
    } else {
      this.valueResolve = true;
      this.valueClassify = false;
      this.valueIngest = false;
      this.defaultAppId = 1;
    }
    this.updateUserPreferences();
  }

  changed2() {    //zs-cl
    if(this.valueClassify === true){
      this.valueClassify = false;
      this.defaultAppId = -1;
    } else {
      this.valueResolve = false;
      this.valueClassify = true;
      this.valueIngest = false;
      this.defaultAppId = 3;
    }
    this.updateUserPreferences();
  }

  changed3() {    //z-ing
    if(this.valueIngest === true){
      this.valueIngest = false;
      this.defaultAppId = -1;
    } else {
      this.valueResolve = false;
      this.valueClassify = false;
      this.valueIngest = true;
      this.defaultAppId = 4; 
    }
    this.updateUserPreferences();
  }

  updateUserPreferences() {
    const postData = {
                      user_id: this.userinfo.user_id,
                      payload: {
                        preference: { default_app_id: this.defaultAppId }
                      }
    };
    this.baseService.updateUserPreferences(postData, this.http).subscribe((result) => {
      this.userinfo.preference.default_app_id = this.defaultAppId;
      sessionStorage.setItem('userInfo', JSON.stringify(this.userinfo));
    }, err => { });
  }

  getAppLocation(){
		this.baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
			this.userApps = result;
			this.appLocation = result.filter((app)=>{ return app.app_id == 4; })[0].app_location;
		}, error => { 
			console.log(`Error in getting app location:`, error);
		});
	}

	
}
