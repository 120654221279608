import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-dropdown-cell-renderer',
  template: ` 
    <div class="w-100 display-block">
      <select [id]="'attribute-' + params.column.colId + '-' + params.node.rowIndex"
              [class]="getCssClass()"
              (change)="onDropdownValueChange($event)">
        <option [value]="''">{{ getPlaceholder() }}</option>
        <ng-container *ngFor="let item of params.list">
          <option [value]="item.lookup_id" [selected]="isSelected(item.name)">
            {{ item.name }}
          </option>
        </ng-container>
      </select>
    </div>
   
  `,
})
export class DropdownCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  @Output() dropdownValueChanged = new EventEmitter<string>();
  showDefaultDropdown = false;

  agInit(params: any): void {
    this.params = params;     

    if(params.node.id === 't-0'){
      this.showDefaultDropdown = true;
    }
  }

  refresh(params: any): boolean {
    this.params = params; 
    return true;
  }

  getCssClass(): string {
    return this.params.data.action !== 'delete' ? 'ctmInputBox drop-down-minimal dd-edit-entity-wd' : 'ctmInputBoxNoEdit drop-down-minimal';
  }

  onDropdownValueChange(event: any ) { 
    const newValue = event.target.value;
    const rowIndex = this.params.rowIndex; 

    const fieldName =  this.params.colDef.field;  

    const dropdownObj = {
      value: newValue,
      fieldName: fieldName,
      rowIndex: rowIndex,  
      id: this.params.node.id
    }
  
    // this.dropdownValueChanged.emit(newValue);
    this.params.context.componentParent.onDropdown(dropdownObj)  
 
  } 

  getPlaceholder(): string {
    switch (this.params.column.getColId()) {
      case 'data_type':
        return 'Select Data Type';
      case 'scrubber_name':
        return 'Select Scrubber Type';
      case 'matcher_type_name':
        return 'Select Matcher Type';
      default:
        return '';
    }
  }

  isSelected(name: string): boolean {
    return this.params.data.action === 'delete' && this.params.value === name;
  }
}
 