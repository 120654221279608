import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, Injector, APP_INITIALIZER } from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe, LOCATION_INITIALIZED } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ZettaUtils } from '../shared/zettaUtils';
import { AppGlobals } from './app.globals';
import { AuthenticationService } from '../services/authentication.service';
import { AuthTokenInterceptor } from '../services/AuthTokenInterceptor';
import { ObservableService } from '../services/observable.service';
import { BaseService } from '../services/base-service';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZettaGridComponent } from '../components/zetta-grid/zetta-grid.component';
import { DonutChartComponent } from '../components/donut-chart/donut-chart.component';
import { LineChartComponent } from '../components/line-chart/line-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from './auth-guard.service';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { AvatarModule } from 'ngx-avatar';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { SwitchButtonComponent } from '../components/switch-button/switch-button.component';
import { MatcherFeedbackModalComponent } from '../components/matcher-feedback-modal/matcher-feedback-modal.component';

import { MergerFeedbackModalComponent } from '../components/merger-feedback-modal/merger-feedback-modal.component';

import { ProjectProgressComponent } from '../components/project-progress/project-progress.component';
import { ProjectModeComponent } from '../components/project-mode/project-mode.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MessageComponent } from '../components/message/message.component';
import { DatasetsSampleComponent } from '../components/datasets/dataset-sample/dataset-sample.component';
import { MSALTokenInterceptor } from '../../common/services/msal.token.interceptor';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { NgZone, Compiler } from '@angular/core';
import { MessageService } from '../components/message/message.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { ChartsModule } from 'ng2-charts'
import { authProviderFactory } from './auth-providers-factory';
import { DownvoteModalComponent } from '../components/datasets/downvote-modal/downvote-modal.component';
import { PdDataComponent } from 'src/app/zettasense/content/project/project-detail/pd-data/pd-data.component';
import { PdUsersComponent } from 'src/app/zettasense/content/project/project-detail/pd-users/pd-users.component';
import { PdTasksComponent } from 'src/app/zettasense/content/project/project-detail/pd-tasks/pd-tasks.component';
import { PdActivityHistoryComponent } from 'src/app/zettasense/content/project/project-detail/pd-activity-history/pd-activity-history.component';
import { ButtonComponent } from '../components/button/button.component';
import { ZsClTrainingStatisticsComponent } from 'src/app/zettaclassify/zs-cl-content/zs-cl-training-statistics/zs-cl-training-statistics.component';
import { ZsClTrainingDownvoteComponent } from 'src/app/zettaclassify/zs-cl-content/zs-cl-training-downvote/zs-cl-training-downvote.component';
import { ClassifyFeedbackModalComponent } from 'src/app/zettaclassify/zs-cl-content/classify-feedback-modal/classify-feedback-modal.component';
import { JobErrorDetailsComponent } from '../components/job-error-details/job-error-details.component';
import { StatisticsComponent } from 'src/app/zettasense/content/statistics/statistics.component';
import { DqUsersComponent } from 'src/app/zettaclassify/zs-cl-content/dq-users/dq-users.component';
import { TextSelectDirective } from '../components/text-select/text-select.directive';
import { TextSelectDemoComponent } from '../components/text-select/text-select-demo.component';
import { ZsClDownvoteSelecteTextModalComponent } from 'src/app/zettaclassify/zs-cl-content/zs-cl-downvote-select-text-modal/zs-cl-downvote-select-text-modal.component';
import { ZsClTrainingTaskModalComponent } from 'src/app/zettaclassify/zs-cl-content/zs-cl-training-task-modal/zs-cl-training-task-modal.component';
import { SubConceptModalConcept } from 'src/app/zettaclassify/zs-cl-content/sub-concept-modal/sub-concept-modal.component';
import { ConceptTrainingDataComponent } from 'src/app/zettaclassify/zs-cl-content/concept-training-data/concept-training-data.component';
import { AddDataSourceModalComponent } from 'src/app/zettaclassify/add-data-source-modal/add-data-source-modal.component';
import { ProfileResult } from 'src/app/zettaclassify/zs-cl-content/zs-cl-project/profile-result/profile-result.component';
import { ZsClFeedbackSummaryComponent } from '../../zettaclassify/zs-cl-content/zs-cl-feedback-summary/zs-cl-feedback-summary.component';
import { NumberFormatPipe } from './filters/number-format.pipe';
import { AssignUsersComponent } from '../components/assign-users/assign-users.component';
import { ConfirmationMessageComponent } from '../components/confirmation-message/confirmation-message.component';
import { ProcessingModalComponent } from '../components/processing-modal/processing-modal.component';
import { ZsClReviewPriorFeedbackComponent } from 'src/app/zettaclassify/zs-cl-review-prior-feedback/zs-cl-review-prior-feedback.component';
//import { ConfirmationMessageComponent } from '../components/confirmation-message/confirmation-message.component'; 
import { FsGridComponent } from '../components/fs-grid/fs-grid.component';

import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise'
import { DeleteIconRendererComponent } from './cell-renderer/delete-icon-formatter/delete-icon-formatter.component';
import { CustomCellRenderer } from './cell-renderer/custom-cell-render-formatter/custom-cell-render-formatter-component';
import { CustomDropdownCellRenderer } from './cell-renderer/custom-cell-dropdown-formatter/custom-cell-dropdown-formatter-component';
import { CustomCellEditModeFormatter } from './cell-renderer/custom-cell-render-formatter/custom-cell-editmode-formatter-component';
import { MinusPlusIconRendererComponent } from './cell-renderer/delete-icon-formatter/minus-plus-formatter-component';
import { DropdownCellRendererComponent } from './cell-renderer/custom-cell-render-formatter/custom-edit-mode-dropdown-formatter-component';
// import { DropdownCellRendererComponent } from './cell-renderer/custom-cell-render-formatter/custom-edit-mode-dropdown-formatter-component';
LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49");
 
import 'ag-grid-enterprise';
//LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")
 
import { ZsSocFeedbackComponent } from 'src/app/zettaclassify/zs-cl-content/zs-soc-feedback/zs-soc-feedback.component';
// import { AgGridModule } from 'ag-grid-angular';
// import { FsGridComponent } from '../components/fs-grid/fs-grid.component';
// import { NameLinkFormatterComponent } from './cell-renderer/name-link-formatter/name-link-formatter.component';
// import { ClassificationTagFormatterComponent } from './cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
import { DateFormatterFormatterComponent } from './cell-renderer/date-formatter/date-formatter.component';
import { ProjectNameFormatterComponent } from './cell-renderer/project-name-formatter/project-name-formatter.component';
import { MatherMergerFormatterComponent } from './cell-renderer/mathermerger-formatter/mathermerger-formatter.component';
// import { ButtonFormatterComponent } from './cell-renderer/button-formatter/button-formatter.component';
import { AssignmentStatusFormatterComponent } from './cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
// import { RuleCountFormatterComponent } from './cell-renderer/rule-count-formatter/rule-count-formatter.component';
// import { MeasuredDQRuleFormatterComponent } from './cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { NoRowOverlayComponent } from '../components/no-row-overlay/no-row-overlay.component';
import { GoldenRecordsComponent } from 'src/app/zettasense/content/project/golden-records/golden-records.component';
//import { ConfidenceFormatterComponent } from './cell-renderer/confidence-formatter/confidence-formatter.component';
import { SourceSystemRendererComponent } from './cell-renderer/source-system-formatter/source-system-formatter';
import { DatasourceNameFormatterComponent } from './cell-renderer/data-source-name/data-source-name-formatter.component';
import { DatasourceTypeFormatterComponent } from './cell-renderer/data-source-typename/data-source-typename-formatter.component';
import { NumericFormatterTextComponent } from './cell-renderer/data-source-numeric-formatter/data-source-numeric-formatter.component';
import { CreatedByCellRendererComponent } from './cell-renderer/data-source-createdby-formatter/datasource-createdby-formatter.component';
import { ActionsCellRendererComponent } from './cell-renderer/data-source-action-formatter/data-source-action-formatter.component';
import { GroupNumericCellRendererComponent } from './cell-renderer/data-source-groupname-formatter/data-source-groupname-formatter.component';
import { ProjectNameDescriptionFormatterComponent } from './cell-renderer/project-name-description-formattter/project-name-description-formattter.component';
import { ProjectMeasuredDQRuleFormatterComponent } from './cell-renderer/project-measure-dq-rule-formatter/project-measure-dq-rule-formatter.component';
import { DataClassificationProjectFormatterComponent } from './cell-renderer/data-classification-project-formattter/data-classification-project-formattter.component';
import { DaysCellRenderer } from './cell-renderer/days-formatter/days-formatter.component';
import { LineageCustomCellRendererComponent } from './cell-renderer/lineage-custom-formatter/lineage-custom-formatter.component';
import { BlueTextCellRendererComponent } from './cell-renderer/catalog-blue-text-formatter/catalog-blue-text-formatter.component';
import { NullCellRendererComponent } from './cell-renderer/null-formatter/null-formatter.component';
import { NumericCellRendererComponent } from './cell-renderer/catalog-numeric-formatter/catalog-numeric-formatter-component';
import { ActionsObjectFormatterComponent } from './cell-renderer/action-object-formatter/action-object-formatter.component';
import { AdminActionsRendererComponent } from './cell-renderer/catalog-action-link-formatter/catalog-action-link-formatter.component';
import { GRHistoryDateFormatterComponent } from './cell-renderer/date-formatter/gr-history-date-formatter.component';
import { AgDropdownCellRendererComponent } from './cell-renderer/custom-cell-render-formatter/AgDropdownCellRendererComponent';
import { CopyIconRendererComponent } from './cell-renderer/delete-icon-formatter/copy-icon-formatter';
import { ProdConfigSettingsModalComponent } from './prod-config-settings-modal/prod-config-settings-modal.component';
import { BusinessRunScheduleComponent } from 'src/app/zettaclassify/zs-cl-content/zs-cl-data-quality-rules/business-entity-level-rules/business-run-schedule/business-run-schedule.component';
import { ClassifierResultSummaryModalComponent } from './classifier-result-summary-modal/classifier-result-summary-modal.component';
import { RightArrowIconFormatter } from './cell-renderer/right-arrow-icon-formatter/right-arrow-icon-formatter';
import { SearchDropdownRendererComponent } from './cell-renderer/custom-cell-render-formatter/search-dropdown-formatter';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClassifierRecordsListComponent } from './classifier-records-list/classifier-records-list.component';
// import { ActionLinkFormatterComponent } from './cell-renderer/action-link-formatter/action-link-formatter.component';
// import { LicenseManager } from 'ag-grid-enterprise'
// import 'ag-grid-enterprise';
// import { NumberFormatterComponent } from './cell-renderer/number-formatter/number-formatter.component';
// LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-051680}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 January 2024}____[v3]_[0102]_MTcwNjY1OTIwMDAwMA==28c721feb2e1886381b211053fd5ff49")

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// use an Initializer Factory as describe here: https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        // console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

// @dynamic
@NgModule({
  imports: [
    // AgGridModule,
    CommonModule,
    AgGridModule,
    RouterModule,
    ChartsModule,
    AgGridModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgGridModule,
    AngularSlickgridModule.forRoot(
      {
        alwaysShowVerticalScroll: true,
        autoEdit: false,
        asyncEditorLoading: false,
        autoFitColumnsOnFirstLoad: true,
        enablePagination: true,
        autoResize: {
          bottomPadding: 20,
          minHeight: 180,
          minWidth: 300,
          sidePadding: 0
        },
        cellHighlightCssClass: 'slick-cell-modified',
        checkboxSelector: {
          cssClass: 'slick-cell-checkboxsel'
        },
        columnPicker: {
          hideForceFitButton: false,
          hideSyncResizeButton: true
        },
        datasetIdPropertyName: 'id',
        defaultFilterPlaceholder: '&#128269;',
        editable: false,
        enableAutoResize: true,
        enableAutoSizeColumns: true,
        enableCellNavigation: false,
        enableColumnPicker: true,
        enableColumnReorder: true,
        enableExport: true,
        enableGridMenu: true,
        enableHeaderMenu: true,
        enableMouseHoverHighlightRow: true,
        enableSorting: true,
        enableTextSelectionOnCells: true,
        explicitInitialization: true,
        exportOptions: {
          delimiter: ',',
          exportWithFormatter: false,
          filename: 'export',
          groupingAggregatorRowText: '',
          sanitizeDataExport: false,
          useUtf8WithBom: true
        },
        forceFitColumns: false,
        gridMenu: {
          hideClearAllFiltersCommand: false,
          hideClearAllSortingCommand: false,
          hideExportCsvCommand: false,
          hideExportTextDelimitedCommand: true,
          hideForceFitButton: false,
          hideRefreshDatasetCommand: false,
          hideSyncResizeButton: true,
          hideToggleFilterCommand: false,
          hideTogglePreHeaderCommand: false,
          iconCssClass: 'fa fa-bars',
          iconClearAllFiltersCommand: 'fa fa-filter text-danger',
          iconClearAllSortingCommand: 'fa fa-unsorted text-danger',
          iconExportCsvCommand: 'fa fa-download',
          iconExportTextDelimitedCommand: 'fa fa-download',
          iconRefreshDatasetCommand: 'fa fa-refresh',
          iconToggleFilterCommand: 'fa fa-random',
          iconTogglePreHeaderCommand: 'fa fa-random',
          menuWidth: 16,
          resizeOnShowHeaderRow: true
        },
        headerMenu: {
          autoAlign: true,
          autoAlignOffset: 12,
          minWidth: 140,
          iconSortAscCommand: 'fa fa-sort-asc',
          iconSortDescCommand: 'fa fa-sort-desc',
          iconColumnHideCommand: 'fa fa-times',
          hideColumnHideCommand: false,
          hideSortCommands: false
        },
        headerRowHeight: 35,
        multiColumnSort: true,
        numberedMultiColumnSort: true,
        tristateMultiColumnSort: false,
        sortColNumberInSeparateSpan: true,
        suppressActiveCellChangeOnEdit: true,
        pagination: {
          pageSizes: [10, 15, 20, 25, 30, 40, 50, 75, 100],
          pageSize: 25,
          totalItems: 0
        },
        topPanelHeight: 35
      }
    ),
    FileUploadModule,
    AvatarModule,
  ],
  exports: [
    RouterModule,
    CommonModule,
    DecimalPipe,
    CurrencyPipe,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ZettaGridComponent,
    FsGridComponent,
    DonutChartComponent,
    LineChartComponent,
    PaginationComponent,
    SpinnerComponent,
    SwitchButtonComponent,
    AvatarModule,
    MatcherFeedbackModalComponent,
    MergerFeedbackModalComponent,
    ProjectProgressComponent,
    ProjectModeComponent,
    FileUploadComponent,
    MessageComponent,
    DatasetsSampleComponent,
    DownvoteModalComponent,
    ClassifyFeedbackModalComponent,
    PdDataComponent,
    PdUsersComponent,
    PdTasksComponent,
    PdActivityHistoryComponent,
    ButtonComponent,
    ZsClTrainingStatisticsComponent,
    ZsClTrainingDownvoteComponent,
    JobErrorDetailsComponent,
    StatisticsComponent,
    DqUsersComponent,
    TextSelectDirective,
    TextSelectDemoComponent,
    ZsClDownvoteSelecteTextModalComponent,
    ZsClTrainingTaskModalComponent,
    ProfileResult,
    ConceptTrainingDataComponent,
    SubConceptModalConcept,
    AddDataSourceModalComponent,
    ZsClFeedbackSummaryComponent,
    AssignUsersComponent,
    NumberFormatPipe,
    ConfirmationMessageComponent,
    ProcessingModalComponent,
    ZsClReviewPriorFeedbackComponent,
    ZsSocFeedbackComponent,
    // FsGridComponent,
		NoRowOverlayComponent,
		DateFormatterFormatterComponent, 
    GRHistoryDateFormatterComponent,
		ProjectNameFormatterComponent,
		MatherMergerFormatterComponent,
		AssignmentStatusFormatterComponent, 
    // GoldenRecordsComponent
		AssignmentStatusFormatterComponent,
    DatasourceNameFormatterComponent,
    DatasourceTypeFormatterComponent,
    NumericFormatterTextComponent,
    CreatedByCellRendererComponent,
    ActionsCellRendererComponent,
    GroupNumericCellRendererComponent,
    ProjectNameDescriptionFormatterComponent,
    DataClassificationProjectFormatterComponent,
    ProjectMeasuredDQRuleFormatterComponent,
    DaysCellRenderer, 
    LineageCustomCellRendererComponent,
    DaysCellRenderer,
    BlueTextCellRendererComponent,
    NullCellRendererComponent,
    NumericCellRendererComponent,
    ActionsObjectFormatterComponent,
    AdminActionsRendererComponent,
    BusinessRunScheduleComponent,
    ProdConfigSettingsModalComponent,
    ClassifierResultSummaryModalComponent,
    ClassifierRecordsListComponent
  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [
    
    DeleteIconRendererComponent,
    CustomCellRenderer,
    CustomDropdownCellRenderer,
    CustomCellEditModeFormatter,
    MinusPlusIconRendererComponent,
    CopyIconRendererComponent,
    DropdownCellRendererComponent,
    SearchDropdownRendererComponent,
    RightArrowIconFormatter,
    AgDropdownCellRendererComponent,
    NoRowOverlayComponent,
    DatasourceNameFormatterComponent,
    DatasourceTypeFormatterComponent,
		DateFormatterFormatterComponent,
    GRHistoryDateFormatterComponent,
		ProjectNameFormatterComponent,
    ProjectMeasuredDQRuleFormatterComponent,
		MatherMergerFormatterComponent,
		AssignmentStatusFormatterComponent,
   // ConfidenceFormatterComponent,
    SourceSystemRendererComponent,
    // GoldenRecordsComponent
    NumericFormatterTextComponent,
    CreatedByCellRendererComponent,
    ActionsCellRendererComponent,
    GroupNumericCellRendererComponent,
    ProjectNameDescriptionFormatterComponent,
    DataClassificationProjectFormatterComponent,
    DaysCellRenderer, 
    LineageCustomCellRendererComponent,
    DaysCellRenderer,
    BlueTextCellRendererComponent,
    NullCellRendererComponent,
    NumericCellRendererComponent,
    ActionsObjectFormatterComponent,
    AdminActionsRendererComponent,
    BusinessRunScheduleComponent,
    ProdConfigSettingsModalComponent,
    ClassifierResultSummaryModalComponent,
    ClassifierRecordsListComponent,
  ],
  providers: [
    ZettaUtils, AuthenticationService, AuthGuard, AppGlobals, BaseService, ObservableService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useFactory: authProviderFactory, 
      deps: [AuthenticationService, Router, NgZone, Compiler, MessageService, MsalService, BroadcastService],
      multi: true 
    },
    {
          provide: HTTP_INTERCEPTORS,
          useFactory: authProviderFactory,
          deps: [AuthenticationService, Router, NgZone, Compiler, MessageService, MsalService, BroadcastService],
          multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: MSALTokenInterceptor, 
      multi: true 
    },
    { 
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  declarations: [
    ZettaGridComponent,
    FsGridComponent,
    DonutChartComponent,
    LineChartComponent,
    PaginationComponent,
    SpinnerComponent,
    SwitchButtonComponent,
    MatcherFeedbackModalComponent,
    MergerFeedbackModalComponent,
    ProjectProgressComponent,
    ProjectModeComponent,
    FileUploadComponent,
    MessageComponent,
    DatasetsSampleComponent,
    DownvoteModalComponent,
    ClassifyFeedbackModalComponent,
    PdDataComponent,
    PdUsersComponent,
    PdTasksComponent,
    PdActivityHistoryComponent,
    ButtonComponent,
    ZsClTrainingStatisticsComponent,
    ZsClTrainingDownvoteComponent,
    JobErrorDetailsComponent,
    StatisticsComponent,
    DqUsersComponent,
    TextSelectDirective,
    TextSelectDemoComponent,
    ZsClDownvoteSelecteTextModalComponent, 
    ZsClTrainingTaskModalComponent,
    ConceptTrainingDataComponent,
    ProfileResult,
    SubConceptModalConcept,
    AddDataSourceModalComponent,
    ZsClFeedbackSummaryComponent,
    AssignUsersComponent,
    NumberFormatPipe,
    ConfirmationMessageComponent,
    ProcessingModalComponent,
    ZsClReviewPriorFeedbackComponent, 
    DeleteIconRendererComponent,
    CustomCellRenderer,
    CustomDropdownCellRenderer,
    CustomCellEditModeFormatter,
    MinusPlusIconRendererComponent,
    CopyIconRendererComponent,
    DropdownCellRendererComponent,
    SearchDropdownRendererComponent,
    RightArrowIconFormatter,
    AgDropdownCellRendererComponent,
    ZsSocFeedbackComponent,
    DateFormatterFormatterComponent,
    GRHistoryDateFormatterComponent,
    NoRowOverlayComponent,
    ProjectNameFormatterComponent,
    ProjectMeasuredDQRuleFormatterComponent,
    MatherMergerFormatterComponent,
    AssignmentStatusFormatterComponent,
    GoldenRecordsComponent,
    //ConfidenceFormatterComponent,
    SourceSystemRendererComponent,
    DatasourceNameFormatterComponent,
    DatasourceTypeFormatterComponent,
    NumericFormatterTextComponent,
    CreatedByCellRendererComponent,
    ActionsCellRendererComponent,
    GroupNumericCellRendererComponent,
    ProjectNameDescriptionFormatterComponent,
    DataClassificationProjectFormatterComponent,
    DaysCellRenderer,
    LineageCustomCellRendererComponent, 
    BlueTextCellRendererComponent,
    NullCellRendererComponent,
    NumericCellRendererComponent,
    ActionsObjectFormatterComponent,
    AdminActionsRendererComponent,
    ProdConfigSettingsModalComponent,
    BusinessRunScheduleComponent,
    ClassifierResultSummaryModalComponent,
    ClassifierRecordsListComponent
  ]
})

export class AppSharedModule {
  constructor() { }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppSharedModule
    };
  }
}