import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { Router } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from 'src/app/common/components/project-progress/project-progress.model';
declare var $: any;

@Component({
  selector: 'zetta-technical-run-schedule',
  templateUrl: './technical-run-schedule.component.html',
  styleUrls: ['./technical-run-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TechnicalRunScheduleComponent implements OnInit {
  progressState = new ProjectStatus();
  isTechnicalRunSchedule: Boolean = false;
  selectedOccurance:string = '';
  dataset_size = [
    { data_set_size_id: '1', data_set_size: 'Full data set' },
    { data_set_size_id: '2', data_set_size: 'Sample size of' },
  ];
  timeScheduler;
  addedDataSize:string;
  lookup_id:string;
  schedule_time_id:string
  // Once we will get exact requirement, this hardcoded values have to remove.
  time_schedule = [
    { schedule_time_id: '1', schedule_time: '1:00 AM EST' },
    { schedule_time_id: '2', schedule_time: '2:00 AM EST' },
    { schedule_time_id: '3', schedule_time: '3:00 AM EST' },
    { schedule_time_id: '4', schedule_time: '4:00 AM EST' },
    { schedule_time_id: '5', schedule_time: '5:00 AM EST' },
    { schedule_time_id: '6', schedule_time: '6:00 AM EST' },
    { schedule_time_id: '7', schedule_time: '7:00 AM EST' },
    { schedule_time_id: '8', schedule_time: '8:00 AM EST' },  
    { schedule_time_id: '9', schedule_time: '9:00 AM EST' },
    { schedule_time_id: '10', schedule_time: '10:00 AM EST' },
    { schedule_time_id: '11', schedule_time: '11:00 AM EST' }, 
    { schedule_time_id: '12', schedule_time: '12:00 AM EST' }, 
    { schedule_time_id: '13', schedule_time: '1:00 PM EST' },
    { schedule_time_id: '14', schedule_time: '2:00 PM EST' },
    { schedule_time_id: '15', schedule_time: '3:00 PM EST' },
    { schedule_time_id: '16', schedule_time: '4:00 PM EST' },
    { schedule_time_id: '17', schedule_time: '5:00 PM EST' },
    { schedule_time_id: '18', schedule_time: '6:00 PM EST' },
    { schedule_time_id: '19', schedule_time: '7:00 PM EST' },
    { schedule_time_id: '20', schedule_time: '8:00 PM EST' },  
    { schedule_time_id: '21', schedule_time: '9:00 PM EST' },
    { schedule_time_id: '22', schedule_time: '10:00 PM EST' },
    { schedule_time_id: '23', schedule_time: '11:00 PM EST' }, 
    { schedule_time_id: '24', schedule_time: '12:00 PM EST' },
  ];

  constructor(private service: ZsClContentService,
    private router: Router,
    public zettaUtils: ZettaUtils,
    ) { }

  ngOnInit() {
    this.getSchedulerFrequency();

    if (this.router.url.includes('technical-run-schedule')) {      
      this.isTechnicalRunSchedule = true;
      this.progressState.states = this.zettaUtils.getStateList('create-technical-rules');
      this.progressState.currentStateIndex = 2;
      this.progressState.type = 'create-technical-rules';
      this.progressState.type = 'classifySet';
    }
  }

  getSchedulerFrequency() {
    this.service.getSchedulerFrequency().subscribe(response => {
      this.timeScheduler = response;
    })
  }

  scheduleRule(selectedVal) {
    this.lookup_id = selectedVal;
      this.selectedOccurance = selectedVal;
  }

  scheduleRuleExecution() {
    $('.modal').modal('hide');
    this.router.navigate(['zs-cl/dataquality']);
  }

  onPreviousClick() {
    if(this.isTechnicalRunSchedule) {
      this.router.navigate(['/zs-cl/rules/create-technical-rule/add-users']);
    }
  }

  onCancelClick() {
    $('.modal').modal('hide');
        this.router.navigate(['/zs-cl/dataquality']);
  }

  submitTime(e) {
    console.log('submitTime')
    console.log(e)
  }
}
