
export class Project {

    name: string ;
    description: string;
    user_id: string;
    entity_id: string;
    datasets: Dataset[];
    tenant_id: number;
    project_id: string;
    source_ip: string;
    created_ts: Date ;
    updated_ts: Date;
    users: any[];
    active_users: any[];
    deleteusers: any[];
    deletedatasets: any[];
    total_records: number = undefined;
    is_deleted  = false;
    entity_name: string  = undefined;
    img: string = undefined;
    catalog_id: number;
    constructor() {}
}

export class Dataset {

  dataset_id: number;
  name: string = undefined;
  authoritativeness: number = undefined;
  sort_order: number = undefined;

  attributes: {'target_attribute': string, 'system_attribute': string, 'attribute_id': number, 'opCode', 'dataset_column_id' }[];
  constructor() { }
}

export class ProjectUser {

    constructor() { }

    user_id: String;
    user_name: String;
    group: {name: string, id: string};
  }

  export class ProjectDataSet {

    constructor() { }

    user_id: String;
    user_name: String;
    group: {name: string, id: string};
  }


export interface IEntityPrams {
  userId?: string;
  projectId?: string;
  clusterId?: any;
  clusterName?: string;
  searchString?: string;
  payload?: object;
  selectedProjectId?: string;
  scenarioId?: number;
  tenant_id?: number;
  datasetId?: number;
}
