import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'zetta-workbook-lineage',
    templateUrl: './workbook-lineage.component.html',
    styleUrls: ['./workbook-lineage.component.scss']
})
export class WorkbookLineageComponent implements OnInit {
    
    constructor( ) { }

    ngOnInit(): void {
     
    }
   
}