export class User {

    constructor() { }
    user_id: string;
    user_name: string;
    first_name: string;
    last_name: string;
    email: string;
    is_super_admin: boolean;
    password: string;
    old_password: string;
    confirm_password: string;
    group_name: string;
    group_id: number;
    profile_pic: string;
    role_name:string;
}

export class Group {

    constructor() { }
    group_id: string;
    name: string;
    description: string;
    created_by: string;
    created_ts: Date;
    updated_by: string;
    updated_ts: Date;
    source_ip: string;
    tenant_id?: string;
}
