   import { Component } from '@angular/core';
  import { ICellRendererAngularComp } from 'ag-grid-angular';
  
  @Component({
    selector: 'app-placeholder-renderer',
    template: `
      <div *ngIf="dataContext.action !== 'delete'" class="selectInput w-100">
        <select [id]="'attribute-' + (columnDef.field ) + '-' + row" class="ctmInputBox textSelectBox"
                style="color: #444; background-color: #F8F8F8" disabled>
          <option value="">{{ placeholder }}</option>
        </select>
      </div>
      <div *ngIf="dataContext.action === 'delete'" class="text-truncate" [title]="value">{{ value }}</div>
    `,
  })
  export class CustomDropdownCellRenderer implements ICellRendererAngularComp {
    value: any;
  row: number;
  columnDef: any;
  dataContext: any;
  placeholder: string;
  shouldDisplayDatatype = false;

  agInit(params: any): void {
    this.value = params.value;
    this.row = params.node.rowIndex;
    this.columnDef = params.column;
    this.dataContext = params.data;

    this.setPlaceholder();
  }

  refresh(): boolean {
    return false;
  }

  private setPlaceholder(): void {
    if ( this.columnDef.field === 'data_type') {
      this.placeholder = 'Select Data Type';
    } else if (this.columnDef && this.columnDef.field && this.columnDef.field === 'scrubber_name') {
      this.placeholder = 'Select Scrubber Type';
    } else if (this.columnDef && this.columnDef.field && this.columnDef.field === 'matcher_type_name') {
      this.placeholder = 'Select Matcher Type';
    }  else {
      this.placeholder = "Add "+ this.columnDef.colDef.headerName
    }
    // switch (this.columnDef.field) {
    //   case 'data_type':
    //     this.placeholder = 'Select Data Type';
    //     break;
    //   case 'scrubber_name':
    //     this.placeholder = 'Select Scrubber Type';
    //     break;
    //   case 'matcher_type_name':
    //     this.placeholder = 'Select Matcher Type';
    //     break;
    //   default:
    //     this.placeholder = 'Default Placeholder';
    //     break;
    // }
  }
  }