import { Component, OnInit } from '@angular/core';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from '../../../project-progress/project-progress.model';

@Component({
  selector: 'zetta-review-prior-mappings',
  templateUrl: './review-prior-mappings.component.html',
  styleUrls: ['./review-prior-mappings.component.scss']
})
export class ReviewPriorMappingsComponent implements OnInit {

  progressState = new ProjectStatus();
  constructor(public zettaUtils: ZettaUtils) { }

  ngOnInit() {
    this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
    this.progressState.currentStateIndex = 3;
    this.progressState.type = 'create-bussiness-rules';
    this.progressState.type = 'classifySet';  
  }

}
