import { Column, Formatter } from 'angular-slickgrid';

export const mappingConfidenceFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    let level = (dataContext.score * 100) >= 75 ? 'HIGH' : (dataContext.score * 100) >= 50 ? 'MEDIUM' : 'LOW';
    let score100 = Math.round(dataContext.score * 100);
    let cssLevel = '';
    let confidenceLevel = '';
    let condLike = dataContext.is_up_vote ? 'fas' : 'fal';
    let condUnlike = dataContext.is_down_vote ? 'fas' : 'fal';
    cssLevel = level === 'HIGH' ? '' : level === 'MEDIUM' ? 'boxM' : 'boxL';
    let feedbackStatus = dataContext.shouldDisableFeedback ? 'disabled-feedback' : ''
    if (feedbackStatus && feedbackStatus != '') {
        confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
        <div class="porcent text-center" title='${score100}%'>${score100}%</div>
        <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up ${feedbackStatus}"></i></div>
        <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown ${feedbackStatus}"></i></div>`;
    } else {
        confidenceLevel += `<div class="box-cat-obj ${cssLevel} text-center">${level}</div>
        <div class="porcent text-center" title='${score100}%'>${score100}%</div>
        <div class="handUp text-bold pointer"><i id="thumbs-up-${row}" class="${condLike} fa-thumbs-up"></i></div>
        <div class="handDown text-bold pointer"><i id="thumbs-down-${row}" class="${condUnlike} fa-thumbs-down handDown"></i></div>`;
    }
    return confidenceLevel;
};