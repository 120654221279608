import { Project } from '../project.model';
import { ProjectService } from '../project.service';
import { AngularGridInstance, Column, Formatter, parseBoolean } from 'angular-slickgrid';
import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { MessageService } from 'src/app/common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { IDeactivateComponent } from 'src/app/common/shared/auth-guard.service';
import { ObservableService } from 'src/app/common/services/observable.service';


const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No users to map.';
const ATLEAST_ONE_ADMIN_MSG = 'There must be another project admin assigned before you can be removed from the project';
const ATLEAST_ONE_CADMIN_MSG='There must be at-least one Catalog Admin checked and assigned before removing a user from the Catalog';

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (cell == 4) {
        return value ?
            `<label class="check-cell text-center">
          <input type="checkbox" checked> 
         </label>` :
            `<label class="check-cell text-center">
          <input type="checkbox">  
        </label>`;
    } else {
        return value ?
            `<label class="check-cell text-center">
          <input type="checkbox" checked> 
        </label>` :
            `<label class="ml-2 mr-top-7 check-cell">
          <input type="checkbox">  
        </label>`;
    }
}

const reviewerCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (cell == 4) {
        return value ?
            `<label class="check-cell text-center">
          <input type="checkbox" checked> 
         </label>` :
            `<label class="check-cell text-center ">
          <input type="checkbox">  
        </label>`;
    } else {
        return value ?
            `<label class="check-cell text-center">
          <input type="checkbox" checked> 
        </label>` :
            `<label class="check-cell text-center isrvw">
          <input type="checkbox">  
        </label>`;
    }
}
export const checkBoxFormattter2: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
    value ?
        `<label class="ml-4">
        <input type="checkbox" checked> 
      </label>` :
        `<label class="ml-2">
        <input type="checkbox">  
      </label>`;

export const disabledCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
    dataContext.isApprovalRequired ? (value ? `<label class="check-cell text-center apprcheck"><input type="checkbox" checked></label>` :
        `<label class="check-cell text-center aprv"><input type="checkbox"></label>`) :
        `<label class="check-cell text-center aaprv"><input type="checkbox" disabled></label>`;

const lastNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<p class="text-truncate" title="${value || 'null'}">${value || '<span class="blurNullColor">null</span>'}</p>`;
}
@Component({
    selector: 'zetta-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UsersComponent implements OnInit, OnDestroy, IDeactivateComponent {
    
    isDataReady = false;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    gridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: Project = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    progressState = new ProjectStatus();

    leftGridOptions: any;
    rightGridOptions: any;
    showBreadcrumb = false;
    hasActiveJob = true;

    tableSettings: object = { 'height': "100%", 'width': "99.40%" };
    rightTableSettings: object = { 'height': "100%", 'width': "99.40%" };
    loggedInUserDetails: [];
    selectedUserRoles: any = [];
    errorRibbon = false;
    nextstepbtn = false;
    isApprovalRequired = false;
    isApproved = false;
    modeState: any = {};
    object_type_id: number;
    isClassifyUser: boolean = false;
    leftGridHeaderText: string = 'Project';
    catalogId: string;
    isSelectedRowsOnLeftGrid: boolean = false;
    isSelectedRowsOnRightGrid: boolean = false;
    catalogname:string;
    isEditFlow = false;
    ever_executed = false;
    isEditProject: string;
    btnApplyChanges = false;
    isProductionStage: boolean = false;
    user_role_validation_msg = AppGlobals.USER_ROLE_VALIDATION_MSG;


    constructor(public projectSvc: ProjectService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private _observableService: ObservableService) { }

    ngOnInit() {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        this.object_type_id = +sessionStorage.getItem('project_type_id');
        AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_admin') : '';
        AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_reviewer') : '';
        AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtils.getEntitlementid('project_approver') : '';
        this.ever_executed = this.activatedRoute.snapshot.queryParamMap.get('ever_executed') === "true" ? true : false;;
        this.isEditProject= this.activatedRoute.snapshot.queryParamMap.get('isEditProject');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
            this.catalogId = params['catalog_id']; 
         
        });
        this.activatedRoute.queryParamMap.subscribe(params => {
            this.isClassifyUser = parseBoolean(params.get('isClassify'));
            this.leftGridHeaderText = this.isClassifyUser ? 'Catalog' : 'Project';
            this.hasActiveJob = this.isClassifyUser ? false : true;
            if (params.has('isEdit')) {
                this.isEditFlow = params.get('isEdit') === "true" ? true : false;
            }
        });    
        if(this.router.url.includes('production-stage-details')) {
            this.isProductionStage = true;
        } else {
            this.isProductionStage = false;
        }   
        if (!this.isClassifyUser) {
            this.projectSvc.getCurrentActiveJobs(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.object_type_id).subscribe(jobs => {
                this.hasActiveJob = this.projectSvc.hasActiveJob(jobs);
            }, error => {
                this.hasActiveJob = false;
                // this.messageSvc.sendMessage({ message: 'Users get current active jobs has failed', type: 'INFO', hideboard: true });
            });
        }




        // this.projectSvc.getApproval(this.loggedInUserDetails['user_id'], this.projectDetail.project_id).subscribe(resp => {
        //     this.isApprovalRequired = resp;
        // }, error => {
        // });

        if (this.isClassifyUser) {
            this.getCatalogUserList();
        } else {
            this.getUsersList();
        }

        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
            this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
        } else {
            if(this.isProductionStage) {
                this.modeState.mode = 'Production';
            } else {
                this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
            }
        }


    }

    initColDef() {
        this.gridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'First Name',
            'physicalname': 'first_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Last Name',
            'physicalname': 'last_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': lastNameFormatter,
        }, {
            'displayname': 'Group Name',
            'physicalname': 'group_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }];
        this.rightGridColumnsDef = [{
            'displayname': 'User Name',
            'physicalname': 'user_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'First Name',
            'physicalname': 'first_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        }, {
            'displayname': 'Last Name',
            'physicalname': 'last_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            // 'maxWidth': 150,
            'formatter': lastNameFormatter,
        }, {
            'displayname': this.isClassifyUser ? 'Catalog Admin' : 'Project Admin',
            'physicalname': 'isProjectAdminChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': checkBoxFormattter,
            'headerCssClass': 'text-center',
            'maxWidth': 120,
        }, {
            'displayname': 'Reviewer',
            'physicalname': 'isReviewerChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': reviewerCheckBoxFormattter,
            'headerCssClass': 'text-center',
            'maxWidth': 80,
        }, {
            'displayname': 'Approver',
            'physicalname': 'isApproverChecked',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': disabledCheckBoxFormattter,
            'headerCssClass': 'text-center',
            'maxWidth': 80,
        }];
    }

    getCatalogUserList() {
        let userCount;
        let self = this;
        this.projectSvc.getCatalogUsers(this.loggedInUserDetails['user_id'], this.catalogId, this.loggedInUserDetails['tenant_id']).subscribe(respList => {
           if(respList.length>0)
           {this.rightGridDataSource = respList[1];
            const catalog = respList[2];
            this.rightGridRows = this.rightGridDataSource.length;
            this.leftGridDataSource = respList[0]['currentpage'];
            this.progressState.states = this.zettaUtils.getStateList('create-catalog');
            this.progressState.currentStateIndex = 1;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Catalog';
            this.progressState.catalog = catalog;
            this.progressState.isEdit = this.isEditFlow;
            this.leftGridDataSource = this.leftGridDataSource.filter(user1 => !this.rightGridDataSource.find(user2 => user1.user_id === user2.user_id));
            this.leftGridRows = this.leftGridDataSource.length;
            this.initColDef();
            this.isApproved=catalog['four_eye_check'];
            this.catalogname=catalog['name'];
            if (this.isApproved && this.isClassifyUser) {
                this.isApprovalRequired = true;
                $('.aaprv input:checkbox').removeAttr('disabled');
                $('.aprv input:checkbox').removeAttr('disabled');
                $('.apprcheck input:checkbox').removeAttr('disabled');
                $('.aprv.slick-row').removeClass('disableClick');
                $('.aaprv.slick-row').removeClass('disableClick');
                $('.apprcheck.slick-row').removeClass('disableClick');
            }

            if (this.isClassifyUser) {
                userCount = this.getCatalogUserRoles();
            }
       

            this.isDataReady = true;
            if (userCount['colUserCount'] > 2 && userCount['rowUserCount'] > 2) {
                this.nextstepbtn = true;
            } else {
                this.nextstepbtn = false;
            }
            this.nextStepBtn();

           }
           // emiting the status to header component
            self.emitCatalogUserRoleStatusChangeObservable(self,self.isUserRoleFulfilledForClassifyCatalog(self.catalogId));
            
        }, error => {

        })
    }
    getUserRoles(): Object {
        let adminCount = 0;
        let reviewerCount = 0;
        let approberCount = 0;
        let rowUserCount = 0;
        let colUserCount = 0;
        this.rightGridDataSource.forEach(row => {
            let countRoles = 0;
            row.roles.forEach(roles => {
                switch (roles) {
                    case AppGlobals.PROJECT_ADMIN:
                        row['isProjectAdminChecked'] = true;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['selectedRoles'] = row['roles'];
                        adminCount++;
                        countRoles++;
                        break;
                    case AppGlobals.REVEIWER:
                        row['isReviewerChecked'] = true;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['selectedRoles'] = row['roles'];
                        reviewerCount++;
                        countRoles++;
                        break;
                    case AppGlobals.APPROVER:
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        if (row['isApprovalRequired'] == true) {
                            row['isApproverChecked'] = true;
                        }
                        else {
                            row['isApproverChecked'] = false;
                        }

                        row['selectedRoles'] = row['roles'];
                        approberCount++;
                        countRoles++;
                        break;
                    default:
                        row['isProjectAdminChecked'] = false;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['isReviewerChecked'] = false;
                        row['isApproverChecked'] = false;
                        row['selectedRoles'] = [];
                }
            });
            if (countRoles > 0) {
                rowUserCount++;
            } 
        });

        if (adminCount > 0) {
            colUserCount++;
        }
        if (reviewerCount > 0) {
            colUserCount++;
        }
        if (approberCount > 0) {
            colUserCount++;
        }
        return { colUserCount, rowUserCount };
    }

    getCatalogUserRoles(): Object {
        let adminCount = 0;
        let reviewerCount = 0;
        let approberCount = 0;
        let rowUserCount = 0;
        let colUserCount = 0;
        this.rightGridDataSource.forEach(row => {
            let countRoles = 0;
            row.roles.forEach(roles => {
                switch (roles) {
                    case AppGlobals.CATALOG_ADMIN:
                        row['isProjectAdminChecked'] = true;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['selectedRoles'] = row['roles'];
                        adminCount++;
                        countRoles++;
                        break;
                    case AppGlobals.CATALOG_REVEIWER:
                        row['isReviewerChecked'] = true;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['selectedRoles'] = row['roles'];
                        reviewerCount++;
                        countRoles++;
                        break;
                    case AppGlobals.CATALOG_APPROVER:

                        row['isApprovalRequired'] = this.isApprovalRequired;
                        if (row['isApprovalRequired'] == true) {
                            row['isApproverChecked'] = true;
                        }
                        else {
                            row['isApproverChecked'] = false;
                        }

                        row['selectedRoles'] = row['roles'];
                        approberCount++;
                        countRoles++;
                        break;
                    default:
                        row['isProjectAdminChecked'] = false;
                        row['isApprovalRequired'] = this.isApprovalRequired;
                        row['isReviewerChecked'] = false;
                        row['isApproverChecked'] = false;
                        row['selectedRoles'] = [];
                }
            });
            if (countRoles > 0) {
                rowUserCount++;
            }
        });

        if (adminCount > 0) {
            colUserCount++;
        }
        if (reviewerCount > 0) {
            colUserCount++;
        }
        if (approberCount > 0) {
            colUserCount++;
        }
        return { colUserCount, rowUserCount };
    }

    getUsersList() {
        this.projectSvc.getUsersTenant(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
            this.rightGridDataSource = responseList[1];
            this.projectDetail = <Project>responseList[2];
         

            this.rightGridRows = this.rightGridDataSource.length;
            this.leftGridDataSource = responseList[0]['currentpage'];
            this.progressState.states = this.zettaUtils.getStateList('create-project');
            this.progressState.currentStateIndex = 1;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Project';
            this.progressState.project = this.projectDetail;
            this.progressState.isEdit = (this.projectDetail.project_id !== undefined && this.projectDetail.project_id !== null) ? true : false;
            this.progressState.isEdit = sessionStorage.getItem('newProject') === 'true' ? false : this.progressState.isEdit;
            this.leftGridDataSource = this.leftGridDataSource.filter(user1 => !this.rightGridDataSource.find(user2 => user1.user_id === user2.user_id));
            this.leftGridRows = this.leftGridDataSource.length;
            this.initColDef();
            this.isApproved=this.projectDetail['four_eye_check'];
            
            if (this.isApproved) {
                this.isApprovalRequired = true;
                $('.aaprv input:checkbox').removeAttr('disabled');
                $('.aprv input:checkbox').removeAttr('disabled');
                $('.apprcheck input:checkbox').removeAttr('disabled');
                $('.aprv.slick-row').removeClass('disableClick');
                $('.aaprv.slick-row').removeClass('disableClick');
                $('.apprcheck.slick-row').removeClass('disableClick');
            }
           
            const userCount = this.getUserRoles(); 
          
            this.isDataReady = true;
            if (userCount['colUserCount'] > 2 && userCount['rowUserCount'] > 2) {
                this.nextstepbtn = true;
            } else {
                this.nextstepbtn = false;
            }
            this.nextProjStepBtn();
        }, err => {
            // alert('error');
            this.messageSvc.sendMessage({ message: 'Users get user list has failed', type: 'INFO', hideboard: true });
        });
    }

    onRightGridCellClick(e): void {
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
        const dataSet = this.rightGrid.dataView.getItems();

        if (row !== undefined && row !== null) {

            if (this.isClassifyUser) {
                if (fieldName === 'isProjectAdminChecked') {
                    row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
                    if (row['isProjectAdminChecked']) {
                        row['selectedRoles'].push(AppGlobals.CATALOG_ADMIN);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CATALOG_ADMIN);
                    }
                } else if (fieldName === 'isReviewerChecked') {
                    row['isReviewerChecked'] = !row['isReviewerChecked'];
                    if (row['isReviewerChecked']) {
                        row['selectedRoles'].push(AppGlobals.CATALOG_REVEIWER);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CATALOG_REVEIWER);
                    }
                } else if (fieldName === 'isApproverChecked') {
                    row['isApproverChecked'] = !row['isApproverChecked'];
                    if (row['isApproverChecked']) {
                        row['selectedRoles'].push(AppGlobals.CATALOG_APPROVER);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.CATALOG_APPROVER);
                    }
                }

                
            }
            else {
                if (fieldName === 'isProjectAdminChecked') {
                    row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
                    if (row['isProjectAdminChecked']) {
                        row['selectedRoles'].push(AppGlobals.PROJECT_ADMIN);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.PROJECT_ADMIN);
                    }
                } else if (fieldName === 'isReviewerChecked') {
                    row['isReviewerChecked'] = !row['isReviewerChecked'];
                    if (row['isReviewerChecked']) {
                        row['selectedRoles'].push(AppGlobals.REVEIWER);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
                    }
                } else if (fieldName === 'isApproverChecked') {
                    row['isApproverChecked'] = !row['isApproverChecked'];
                    if (row['isApproverChecked']) {
                        row['selectedRoles'].push(AppGlobals.APPROVER);
                    } else {
                        row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
                    }
                }
            }


            let colAdmin = false;
            let colReview = false;
            let colApprobe = false;
            let diferentUsers = 0;
            let filas = 0;
            let selectedRoles: number = 0;
            dataSet.forEach(row => {
                if (row['isProjectAdminChecked'] === true && colAdmin === false) {
                    colAdmin = true;
                    diferentUsers++;
                }
                if (row['isReviewerChecked'] === true && colReview === false) {
                    colReview = true;
                    diferentUsers++;
                }
                if (row['isApproverChecked'] === true && colApprobe === false) {
                    colApprobe = true;
                    diferentUsers++;
                }
                if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
                    filas++;
                }
                if (row.selectedRoles.length && (row['isProjectAdminChecked'] || row['isReviewerChecked'] || row['isApproverChecked'])) {
                    selectedRoles++; 
                }
            });
            if (this.isClassifyUser) {
                if (this.isApproved) {
                    this.rightGridDataSource.forEach(ele => {
                        this.isApprovalRequired = true;
                        ele['isApprovalRequired'] = this.isApprovalRequired;
                    });
                    if (diferentUsers > 2 && filas > 2) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    if (colReview && colAdmin) {
                        if (diferentUsers > 1 && filas > 1) {
                            this.nextstepbtn = true;
                        } else {
                            this.nextstepbtn = false;
                        }
                    } else {
                        this.nextstepbtn = false;
                    }
                }

            }
            else {
                if (this.isApproved) {
                    this.rightGridDataSource.forEach(ele => {
                        this.isApprovalRequired = true;
                        ele['isApprovalRequired'] = this.isApprovalRequired;
                    });
                    if (diferentUsers > 2 && filas > 2 && this.rightGridDataSource.length === selectedRoles) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    if (diferentUsers > 1 && filas > 1 && this.rightGridDataSource.length === selectedRoles) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                }
                this.validateApplyChangeBtn(true);
            }



            this.rightGrid.gridService.updateDataGridItem(row, false);
            if (this.isClassifyUser && this.catalogId) {
                this.emitCatalogUserRoleStatusChangeObservable(this,this.isUserRoleFulfilledForClassifyCatalog(this.catalogId));
            } 
        }
    }

    onAdd() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid
        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const userList: Object[] = [];
        const rows: any[] = [];

        if (selectedRows.length === 0) {
            this.messageSvc.sendMessage({ message: 'Please select one or more users from the left panel.', type: 'INFO', hideboard: true });
        }
        selectedRows.forEach(index => {
            const row = leftGridDataService.getDataItemByRowNumber(index);
            if (row !== undefined && row !== null) {
                rows.push(row);
            }
        });

        rows.forEach(row => {
            row['selectedRoles'] = [];
            row['isApprovalRequired'] = this.isApprovalRequired;
            this.rightGridDataSource.push(row);
            if (this.isApproved) {
                this.isApprovalRequired = true;
            }
            this.rightGridDataSource.forEach(ele => {
                ele['isApprovalRequired'] = this.isApprovalRequired;
            });
            rightGridDataService.addItemToDatagrid(row, true);
            // remove Item from left grid
            userList.push({ user_id: row.user_id });
            this.leftGridDataSource = this.leftGridDataSource.filter(user => user.user_id !== row.user_id);
            leftGridDataService.deleteDataGridItem(row);
        });

        // remove row selection from left/right grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);
        // Update Row Count
        this.leftGridRows = this.leftGridRows - userList.length;
        this.rightGridRows = this.rightGridRows + userList.length;
        // update DB  
        if (selectedRows.length > 0) {
            if (this.isClassifyUser) {
                this.addCatalogUsers(userList);
            } else {               
                //this.updateProject(userList, 'ADD'); // commenting as its not required to save the user without any role
            }
        }
        if (this.isClassifyUser) {
            this.nextStepBtn();
        } else {
            this.nextProjStepBtn();
            this.validateApplyChangeBtn(true);
        }
    }

    onRemove() {
        const leftGridDataService = this.leftGrid.gridService;
        const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI
        const selectedRows: number[] = rightGridDataService.getSelectedRows();
        const userList: String[] = [];
        const rows: any[] = [];

        if (selectedRows.length === 0) {
            this.messageSvc.sendMessage({ message: 'Please select one or more users from the right panel.', type: 'INFO', hideboard: true });
        }

        selectedRows.forEach(index => {
            const row = rightGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                row.roles = [];
                rows.push(row);
            }
        });

        const projectAdmin = this.rightGridDataSource.filter(user => user.isProjectAdminChecked === true);
        const selectedAdmin = rows.filter(user => user.isProjectAdminChecked === true);
        const totalAdmin = projectAdmin.length - selectedAdmin.length;
        if (selectedRows.length > 0) {
            if (totalAdmin < 1 && this.isClassifyUser) {
                this.messageSvc.sendMessage({ message: ATLEAST_ONE_CADMIN_MSG, type: 'ERROR', hideboard: true });
                return false;
            }
            if (totalAdmin < 1 && !this.isClassifyUser) {
                this.messageSvc.sendMessage({ message: ATLEAST_ONE_ADMIN_MSG, type: 'ERROR', hideboard: true });
                return false;
            }
        }

        rows.forEach(row => {
            row.isProjectAdminChecked = false;
            row.isApproverChecked = false;
            row.isReviewerChecked = false;
            delete row['isApprovalRequired'];
            this.leftGridDataSource.push(row);
            leftGridDataService.addItemToDatagrid(row, true);
            // remove Item from right grid
            userList.push(row.user_id);
            this.rightGridDataSource = this.rightGridDataSource.filter(user => user.user_id !== row.user_id);
            rightGridDataService.deleteDataGridItem(row);
        });
        // this.leftGrid.slickGrid.setData();
        leftGridDataService.renderGrid();
        rightGridDataService.renderGrid();
        // remove row selection from left grid
        leftGridDataService.setSelectedRows([]);
        rightGridDataService.setSelectedRows([]);

        this.leftGridRows = this.leftGridRows + userList.length;
        this.rightGridRows = this.rightGridRows - userList.length;
        if (selectedRows.length > 0) {
            if (this.isClassifyUser) {
                this.removeCatalogUsers(userList);
            } else {
                this.updateProject(userList, 'DELETE');
            }
        }
        if (this.isClassifyUser) {
            this.nextStepBtn();
        } else {
           this.nextProjStepBtn();
           this.validateApplyChangeBtn(true);
        }
    }

    nextStepBtn() {
        if (this.isClassifyUser) {
            let colAdmin = false;
            let colReview = false;
            let colApprobe = false;
            let diferentUsers = 0;
            let filas = 0;
            this.rightGridDataSource.forEach(row => {
                if (row['isProjectAdminChecked'] === true && colAdmin === false) {
                    colAdmin = true;
                    diferentUsers++;
                }
                if (row['isReviewerChecked'] === true && colReview === false) {
                    colReview = true;
                    diferentUsers++;
                }
                if (row['isApproverChecked'] === true && colApprobe === false) {
                    colApprobe = true;
                    diferentUsers++;
                }
                if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
                    filas++;
                }
            });
        
            if (this.isApproved) {
                if (diferentUsers > 2 && filas > 2) {
                    this.nextstepbtn = true;
                } else {
                    this.nextstepbtn = false;
                }
            } else {
                if (colReview && colAdmin) {
                    if (diferentUsers > 1 && filas > 1) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    this.nextstepbtn = false;
                }
            }
        }
    }

    nextProjStepBtn() {

            let colAdmin = false;
            let colReview = false;
            let colApprobe = false;
            let diferentUsers = 0;
            let filas = 0;
            let selectedRoles: number = 0;
            this.rightGridDataSource.forEach(row => {
                if (row['isProjectAdminChecked'] === true && colAdmin === false) {
                    colAdmin = true;
                    diferentUsers++;
                }
                if (row['isReviewerChecked'] === true && colReview === false) {
                    colReview = true;
                    diferentUsers++;
                }
                if (row['isApproverChecked'] === true && colApprobe === false) {
                    colApprobe = true;
                    diferentUsers++;
                }
                if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
                    filas++;
                }
                if (row.selectedRoles.length && (row['isProjectAdminChecked'] || row['isReviewerChecked'] || row['isApproverChecked'])) {
                    selectedRoles++; 
                }
            });
        
            if (this.isApproved) {
                if (diferentUsers > 2 && filas > 2 && this.rightGridDataSource.length === selectedRoles) {
                    this.nextstepbtn = true;
                } else {
                    this.nextstepbtn = false;
                }
            } else {
                if (colReview && colAdmin) {
                    if (diferentUsers > 1 && filas > 1 && this.rightGridDataSource.length === selectedRoles) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    this.nextstepbtn = false;
                }
            }
        
    }


    rightGridCreated(grid) {
        const parent = this;
        parent.rightGrid = grid;
        parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.isSelectedRowsOnRightGrid = args.rows.length > 0 ? true : false;
            if (parent.isApproved) {
                parent.rightGridDataSource.forEach(ele => {
                    if (parent.isApproved) {
                        parent.isApprovalRequired = true;
                        ele['isApprovalRequired'] = true;
                    }
                    else {
                        parent.isApprovalRequired = false;
                        ele['isApprovalRequired'] = false;
                    }
                });

            }
        });
    }

    leftGridCreated(grid) {
        const parent = this;
        parent.leftGrid = grid;
        parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
            parent.isSelectedRowsOnLeftGrid = args.rows.length > 0 ? true : false;
        });
    }

    updateProject(users: any[], opcode: string) {
        if (users.length > 0) {
            const project = new Project();
            project.project_id = this.projectDetail.project_id;
            project.user_id = this.loggedInUserDetails['user_id'];
            project.tenant_id = this.projectDetail.tenant_id;
            if ('ADD' === opcode) {
                project.users = users;
            } else {
                project.deleteusers = users;
            }
            this.projectSvc.saveProject(project).subscribe(resp => {
                $('#projectState1').removeClass('d-none');
                setTimeout(function () {
                    $('#projectState1').addClass('d-none');
                }, 5000);
            });
        }
    }

    addCatalogUsers(users: any) {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogId,
            payload: users
        }
        this.projectSvc.addUserToCatalog(data).subscribe(resp => {
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }

    // Remove user from catalog
    removeCatalogUsers(usersList: any) {
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogId,
            payload: {
                body: {
                    'users': usersList
                }
            }
        }
        /**
         * TODO: Removal of record from right grid has to be moved after service confirmation. 
         */
        let self = this;
        this.projectSvc.deleteUserFromCatalog(data).subscribe(resp => {
            self.emitCatalogUserRoleStatusChangeObservable(self,self.isUserRoleFulfilledForClassifyCatalog(self.catalogId));
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
        });
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            if (this.isClassifyUser) {
                this.router.navigate(['/zs-cl/catalogs']);
            } else {
                this.router.navigate(['/zs/projects']);
            }

        } else {
            if (this.isClassifyUser) {
                this.router.navigate(['/zs-cl/catalogs']);
            } else {
                if(this.isProductionStage) {
                    this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details`], {
                        queryParams: { 
                            entity_id: this.projectDetail.entity_id, entity_name: this.projectDetail.entity_name
                        }, 
                        queryParamsHandling: 'merge' 
                      });
                } else {
                    this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
                }
            }

        }
    }

    onPrevious() {
        if (this.isClassifyUser) {
            this.router.navigate(['/zs-cl/catalogs/create-catalog'], { queryParams: { catalog_id: this.catalogId, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge'});
        } else {
            this.router.navigate(['/zs/projects/create'],
                { queryParams: { 'id': this.projectDetail.project_id, showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge' });
        }

    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    rightGridCount() {
        if (this.rightGrid) {
            return this.rightGrid.dataView.getItems().length;
        } else {
            return this.rightGridDataSource.length;
        }
    }

    addUserRole(boolean: boolean) {
        const selectedProjectAdminLst = this.rightGridDataSource.filter(item => item.selectedRoles.length == 1 && item.selectedRoles[0] == 33 && item.isApprovalRequired == false);
        const selectedProjectAdminList = this.rightGridDataSource.filter(item => item.isProjectAdminChecked === true);
        const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
        const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
        const emptyUserRoleList = this.rightGridDataSource.filter(item => item.selectedRoles.length === 0);
        if(this.isClassifyUser)
        {
            if (!this.isApproved) {
                if (selectedProjectAdminList.length === 0 || selectedProjectReviewerList.length === 0) {
                    this.errorRibbon = true;
                }
                if (selectedProjectAdminLst.length > 0) {
                    this.errorRibbon = true;
                }
                else {
                    if (this.isClassifyUser) {
                        this.saveCatalogUserRoles();
                    } else {
                        this.errorRibbon = false;
                        this.saveUserRole(boolean);
                    }
                }
            } else {
                if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
                    selectedProjectReviewerList.length === 0 || emptyUserRoleList.length !== 0) {
                    this.errorRibbon = true;
                } else {
                    if (this.isClassifyUser) {
                        this.saveCatalogUserRoles();
                    } else {
                        this.errorRibbon = false;
                        this.saveUserRole(boolean);
                    }
                }
            }
        }
        else{
            if(!this.isApproved) {
                if(selectedProjectAdminList.length === 0 || selectedProjectReviewerList.length === 0 || emptyUserRoleList.length !== 0) {
                  this.errorRibbon = true;
                } else {
                  this.errorRibbon = false;
                  this.saveUserRole(boolean);
                }}
           else if (selectedProjectAdminList.length === 0 || selectedProjectApproverList.length === 0 ||
                selectedProjectReviewerList.length === 0 || emptyUserRoleList.length !== 0) {
                this.errorRibbon = true;
            }else{
                    this.errorRibbon = false;
                    this.saveUserRole(boolean);
                }
            }   
    }

    saveUserRole(bool: boolean) {
        this.rightGridDataSource.map(resp => {
            if (!this.isApproved) {
             let userselectedrole = [];
             if (resp.selectedRoles && resp.selectedRoles.length > 0) {
                 resp.selectedRoles.forEach(ele => {
                     if (ele !== 33) {
                         userselectedrole.push(ele);
                     }
                 });
                 
                 this.selectedUserRoles.push({ user_id: resp.user_id, roles: userselectedrole });
                 this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
             }
             else
             {
                let userselectedrole = [];
                if (resp.selectedRoles.length == 0) {
                    userselectedrole.push(5);//Needs to be changes once API is Done
                }
                this.selectedUserRoles.push({ user_id: resp.user_id, roles: userselectedrole });
                this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
             }
         } else {
             this.selectedUserRoles.push({ user_id: resp.user_id, roles: resp.selectedRoles });
             this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
         }

     });
        this.projectSvc.saveUserRoles(this.loggedInUserDetails['user_id'], this.projectDetail.project_id,
            this.selectedUserRoles).subscribe(resp => {
                this.btnApplyChanges = false;
                if (bool) {
                    if(this.isProductionStage) {
                        this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details`], {
                            queryParams: { 
                                entity_id: this.projectDetail.entity_id, entity_name: this.projectDetail.entity_name
                            }, 
                            queryParamsHandling: 'merge' 
                          });
                    } else {
                        this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
                    }
                } else {
                    this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'datasets'],
                        { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling:'merge'});
                }
            }, err => {
                this.selectedUserRoles = [];
                this.messageSvc.sendMessage({ message: 'Users save user role has failed', type: 'INFO', hideboard: true });
            });
    }

    saveCatalogUserRoles() {
        this.rightGridDataSource.map(resp => {
               if (!this.isApproved) {
                let userselectedrole = [];
                if (resp.selectedRoles && resp.selectedRoles.length > 0) {
                    resp.selectedRoles.forEach(ele => {
                        if (ele !== 33) {
                            userselectedrole.push(ele);
                        }
                    });
                    this.selectedUserRoles.push({ user_id: resp.user_id, roles: userselectedrole });
                    this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
                }
            } else {
                this.selectedUserRoles.push({ user_id: resp.user_id, roles: resp.selectedRoles });
                this.selectedUserRoles = this.selectedUserRoles.filter(res => res.user_id);
            }

        });
        const data = {
            user_id: this.loggedInUserDetails['user_id'],
            catalog_id: this.catalogId,
            payload: this.selectedUserRoles
        };
        let self = this;
        this.projectSvc.addUserToCatalog(data).subscribe(resp => {
            self.emitCatalogUserRoleStatusChangeObservable(self,self.isUserRoleFulfilledForClassifyCatalog(self.catalogId));
            this.router.navigate(['/zs-cl/catalogs/' + this.catalogId + '/users/objects'], { queryParams: { showBreadcrumb: this.showBreadcrumb, isClassify: true }, queryParamsHandling:'merge' });
        }, err => {
            this.selectedUserRoles = [];
            this.messageSvc.sendMessage({ message: 'Users save user role has failed', type: 'INFO', hideboard: true });
        });
    }

    mandatoryApproval() {
        if (this.isClassifyUser) {
            let data = {
                user_id: this.loggedInUserDetails['user_id'],
                catalog_id: this.catalogId,
                payload: { 
                    name:this.catalogname,
                    four_eye_check: this.isApproved },
            };
            const dataSet = this.rightGrid.dataView.getItems();
            this.projectSvc.saveapprovaldata(data).subscribe(responseList => {
            if (responseList['four_eye_check']== true) {
                this.isApprovalRequired = true;
                this.isApproved = true;
                $('.aaprv input:checkbox').removeAttr('disabled');
                $('.aprv input:checkbox').removeAttr('disabled');
                $('.apprcheck input:checkbox').removeAttr('disabled');
                $('.aprv.slick-row').removeClass('disableClick');
                $('.aaprv.slick-row').removeClass('disableClick');
                $('.apprcheck.slick-row').removeClass('disableClick');
            } else {
                this.isApprovalRequired = false;
                this.isApproved = false;
                $('.aaprv input:checkbox').attr('disabled', 'disabled');
                $('.aprv input:checkbox').attr('disabled', 'disabled');
                $('.apprcheck input:checkbox').removeAttr('checked');
                $('.apprcheck input:checkbox').attr('disabled', 'disabled');
                $("#apprcheck").prop('checked', false);
                $("#aaprv").prop('checked', false);
                $("#aprv").prop('checked', false);
                this.rightGridDataSource.forEach(row => {
                    row['isApproverChecked'] = false;
                    row['isApprovalRequired'] = false;
                });
            }
            let colAdmin = false;
            let colReview = false;
            let colApprobe = false;
            let diferentUsers = 0;
            let filas = 0;
            let selection = true;
            this.rightGridDataSource.forEach(row => {
                if (row['isProjectAdminChecked'] === true && colAdmin === false) {
                    colAdmin = true;
                    diferentUsers++;
                }
                if (row['isReviewerChecked'] === true && colReview === false) {
                    colReview = true;
                    diferentUsers++;
                }
                if (row['isApproverChecked'] === true && colApprobe === false) {
                    colApprobe = true;
                    diferentUsers++;
                }
                if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
                    filas++;
                }
            });
            if (this.isApproved) {
                if (diferentUsers > 2 && filas > 2) {
                    this.nextstepbtn = true;
                } else {
                    this.nextstepbtn = false;
                }
            } else {
                if (colReview && colAdmin) {
                    if (diferentUsers > 1 && filas > 1) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    this.nextstepbtn = false;
                }
            }
        });
            
        }
        else{
            let data = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                payload: {four_eye_check : this.isApproved},
              };

            const dataSet = this.rightGrid.dataView.getItems();
            this.projectSvc.saveprojapprovaldata(data,this.projectDetail.project_id).subscribe(responseList => {
            if (responseList['four_eye_check']== true) {
                this.isApprovalRequired = true;
                this.isApproved = true;
                $('.aaprv input:checkbox').removeAttr('disabled');
                $('.aprv input:checkbox').removeAttr('disabled');
                $('.apprcheck input:checkbox').removeAttr('disabled');
                $('.aprv.slick-row').removeClass('disableClick');
                $('.aaprv.slick-row').removeClass('disableClick');
                $('.apprcheck.slick-row').removeClass('disableClick');
            } else {
                this.isApprovalRequired = false;
                this.isApproved = false;
                $('.aaprv input:checkbox').attr('disabled', 'disabled');
                $('.aprv input:checkbox').attr('disabled', 'disabled');
                $('.apprcheck input:checkbox').removeAttr('checked');
                $('.apprcheck input:checkbox').attr('disabled', 'disabled');
                $("#apprcheck").prop('checked', false);
                $("#aaprv").prop('checked', false);
                $("#aprv").prop('checked', false);
                this.rightGridDataSource.forEach(row => {
                    row['isApproverChecked'] = false;
                    row['isApprovalRequired'] = false;
                });
            }
            let colAdmin = false;
            let colReview = false;
            let colApprobe = false;
            let diferentUsers = 0;
            let filas = 0;
            let selection = true;
            let selectedRoles: number = 0;            
            this.rightGridDataSource.forEach(row => {
                if (row['isProjectAdminChecked'] === true && colAdmin === false) {
                    colAdmin = true;
                    diferentUsers++;
                }
                if (row['isReviewerChecked'] === true && colReview === false) {
                    colReview = true;
                    diferentUsers++;
                }
                if (row['isApproverChecked'] === true && colApprobe === false) {
                    colApprobe = true;
                    diferentUsers++;
                }
                if (row['isProjectAdminChecked'] === true || row['isReviewerChecked'] === true || row['isApproverChecked'] === true) {
                    filas++;
                }
                if (row.selectedRoles.length && (row['isProjectAdminChecked'] || row['isReviewerChecked'] || row['isApproverChecked'])) {
                    selectedRoles++; 
                }
            });
            if (this.isApproved) {
                if (diferentUsers > 2 && filas > 2 && this.rightGridDataSource.length === selectedRoles) {
                    this.nextstepbtn = true;
                } else {
                    this.nextstepbtn = false;
                }
            } else {
                if (colReview && colAdmin) {
                    if (diferentUsers > 1 && filas > 1 && this.rightGridDataSource.length === selectedRoles) {
                        this.nextstepbtn = true;
                    } else {
                        this.nextstepbtn = false;
                    }
                } else {
                    this.nextstepbtn = false;
                }
            }
        });
        }
    }

    validateApplyChangeBtn(flag) {
        if (this.showBreadcrumb) {
            this.btnApplyChanges = flag;
            this.nextstepbtn = flag;
        }
    }

    isUserRoleFulfilledForClassifyCatalog(_catalogId) {
        if (_catalogId || this.isClassifyUser) {   
            /**
             *  classify
             */
           let selectedCatalogAdminUsers = [];
           let selectedCatalogApproverUsers = [];
           let selectedCatalogReviewerUsers = [];
            this.rightGridDataSource.forEach((user) => {
                if (user.selectedRoles && user.selectedRoles.length > 0) {
                    let tempAdmins = user.selectedRoles.filter((val) => {
                            return val == AppGlobals.CATALOG_ADMIN;
                    });
                    selectedCatalogAdminUsers = selectedCatalogAdminUsers.concat(tempAdmins);

                    let tempApprovers = user.selectedRoles.filter((val) => {
                        return val == AppGlobals.CATALOG_APPROVER;
                    });
                    selectedCatalogApproverUsers  = selectedCatalogApproverUsers.concat(tempApprovers);

                    let tempReviewers = user.selectedRoles.filter((val) => {
                        return val == AppGlobals.CATALOG_REVEIWER;
                    });
                    selectedCatalogReviewerUsers = selectedCatalogReviewerUsers.concat(tempReviewers);
                }
            });

            if (this.isApproved) {
                /** case: Four EYE check ON */
                return selectedCatalogAdminUsers.length > 0 && selectedCatalogApproverUsers.length > 0 &&
                selectedCatalogReviewerUsers.length > 0 ? true: false;
            } else {
                /** case: Four EYE check OFF */
                return selectedCatalogAdminUsers.length > 0 && selectedCatalogReviewerUsers.length > 0 ? true : false;
            }
        } else {
            /**
             * Resolve
             * Since this component is being re-used in resolve and no requirement to showcase error 
             * message on user role not fulfilled in, returning as true to avoid checks. 
             */
            return true;
        }
    }   

    /**
     *  Handled for classify
     */
    canExit(): boolean { 
        let userInfo = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')) : null;
        if (!this.isUserRoleFulfilledForClassifyCatalog(this.catalogId) && userInfo) {
            this.messageSvc.sendMessage({ message: AppGlobals.CLASSIFY_USER_ROLE_NOT_FULFILLMENT_ERROR_MSG, type: 'Info', hideboard: true , time: 9000});
            return false;
        } 
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
       if (!this.isUserRoleFulfilledForClassifyCatalog(this.catalogId) && $event) {
           $event.preventDefault();
           $event.returnValue = false;
        }
    }

    // emitted only in case of classify
    emitCatalogUserRoleStatusChangeObservable(context, state: boolean) {
        context._observableService.setClassifyCatalogUserRoleFulfillmentStatus = state;
    }

    redirectConditionally() {
        if(this.isProductionStage) {
            return `/zs/projects/${this.projectDetail.project_id}/production-stage-details`;
        } else {
            return `/zs/projects/${this.projectDetail.project_id}`;
        }
    }
}
