import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
declare var $: any;

@Component({
    selector: 'zetta-downvote-select-text',
    templateUrl: './zs-cl-downvote-select-text-modal.component.html',
    styleUrls: ['./zs-cl-downvote-select-text-modal.component.scss']
})
export class ZsClDownvoteSelecteTextModalComponent implements OnInit {
    public loggedInUserDetails = null;
    old_row = 0;
    selectedTag = "";
    selectedTagOwn = "";
    selectedOption = "keep default";
    @Input() subConcept: string;    
    @Input() parsingPredictions: string;
    @Input() dataset_id?: number;
    @Input() classifier?: string;
    @Input() record_id: number;
    @Input() content: string;
    @Input() count;
    showBackdrop = false;
    @Output() modelPopupOutput: EventEmitter<any> = new EventEmitter();
    @Output() disliked: EventEmitter<any> = new EventEmitter();
    projId: any;
    selectedText = "";
    pridictionList = [];
    divHight = '100px';
    isBoxEnable = true;
    constructor(
        private catalogSvc: ZsClContentService,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
            this.projId = params['id'];
        });
        this.getPridictionList();
    }

    onChangeNoValid() {
        $("#rbtnSelectText").prop('checked', false);
        $("#tagKeep").prop('checked', false);
        $("#noValidTag").prop('checked', true);
        this.selectedOption = undefined;
        this.isBoxEnable = true;
        this.pridictionList = [];
    }
    onSelectTag() {
        $("#tagKeep").prop('checked', false);
        $("#noValidTag").prop('checked', false);
        $("#rbtnSelectText").prop('checked', true);
        this.selectedTag = undefined;
        this.selectedOption = 'select tag';
        this.isBoxEnable = false;
    }
    onChangesKeepTag() {
        $("#rbtnSelectText").prop('checked', false);
        $("#noValidTag").prop('checked', false);
        $("#tagKeep").prop('checked', true);
        this.selectedTag = undefined;
        this.selectedOption = "keep default";
        this.isBoxEnable = true;
        this.pridictionList = [];
    }

    closeClassificationTags() {
        $('#classification_tag_model').modal('hide');
        this.selectedTag = "";
        this.selectedTagOwn = "";
        this.selectedOption = undefined;
        this.modelPopupOutput.emit({isDownVoted:false});
    }
   
    recieveSelectedText(data) {
        const selectedList = [];
        if (data.isMultiSelect) {
            this.selectedText = data.text;
            this.pridictionList.push(data.text);
        } else {
            this.pridictionList = [];
            this.selectedText = data.text;
            this.pridictionList.push(data.text);
        }
    }

    getPridictionList() {
        this.catalogSvc.getPredictionList(this.projId, this.record_id).subscribe(resp => {
            ($('#classification_tag_model') as any).modal('show');
        }, error => {
            ($('#classification_tag_model') as any).modal('show');
        });
    }
    saveFeedback() {
        const payloadList = [];
        let payloadData:any;
        if (this.pridictionList.length) {
            this.pridictionList.forEach(element => {
                payloadList.push({
                    "id": this.record_id,
                    "vote": 1,
                    "sub_concept": this.parsingPredictions,
                    "prediction": element,
                    "classifier": this.classifier,
                   "dataset_id": this.dataset_id
                });
            });                
        } 
        if(this.selectedOption){
            payloadList.push({
                "id": this.record_id,
                "vote": this.selectedOption == 'keep default' ? 1 : 0,
                "sub_concept": this.parsingPredictions,
                "prediction": this.subConcept,
                "classifier": this.classifier,
                "dataset_id": this.dataset_id
            });
        }

        const payload = {
            tasks: payloadList
        }        
        if ((this.selectedText !== "" && this.selectedText !== undefined) || this.selectedOption == 'keep default') {
            this.catalogSvc.saveSelectedFeedBack(payload, this.projId, false).subscribe(resp => {
                if (resp) {
                    let vote = 0;
                    if(this.selectedOption == 'keep default'){
                      vote =resp[0].vote;  
                    }
                    this.modelPopupOutput.emit({isDownVoted:true, vote:vote});
                } 
            }, error => { this.modelPopupOutput.emit({isDownVoted:false}); });
        }else{
            this.modelPopupOutput.emit({isDownVoted:true});
        }
        $('#classification_tag_model').modal('hide');
        $('#divBackdrop').addClass('d-none');
    }
}
