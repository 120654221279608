import { Component, OnInit, Output,EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { BaseService } from 'src/app/common/services/base-service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../common/components/message/message.service';
declare var $: any;
import { environment } from '../../../../environments/environment';
import { Project } from 'src/app/zettasense/content/project/project.model';
import { ProjectService } from 'src/app/zettasense/content/project/project.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { dateFormatter } from 'src/app/common/zm-shared/zm-formatters/dateFormatter'; 

const blueTextFeedbackModelFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  if(dataContext.updated_by === columnDef.params.loggedinUid || dataContext.created_by === columnDef.params.loggedinUid || columnDef.params.adminUser) {
    return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}' data-toggle="modal" data-backdrop="true" data-target="#merger-feedback-modal">
    ${value}
  </div>`; 
   } 
   return value 
 }

const thumbsUpandDown: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
  if(dataContext.vote === 1){
   return `<div id="feedback-align-selector" class="handUp text-center feedback-center text-bold pointer"><i id="thumbs-up-${row}" class="fas fa-thumbs-up"></i></div>`
 }else {
   return `<div id="feedback-align-selector" class="handDown text-center feedback-center text-bold pointer"><i id="thumbs-down-${row}" class="fal fa-thumbs-down"></i></div>`
 }
}


const conditionalDateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.latest_feedback_date === null || dataContext.latest_feedback_date === undefined || dataContext.latest_feedback_date === '') {
    if(dataContext.created_ts === null || dataContext.created_ts === undefined || dataContext.created_ts === '') {
      return;
    } else {
      const d = new Date(dataContext.created_ts);
      const m = d.getMonth() + 1;
      const month = (m <= 9 ? '0' + m : m);
      return `<div class='text-left' title='${month}/${d.getDate()}/${d.getFullYear()}'>${month}/${d.getDate()}/${d.getFullYear()}</div>`;
    }
  }
  const d = new Date(dataContext.latest_feedback_date);
  const m = d.getMonth() + 1;
  const month = (m <= 9 ? '0' + m : m);
  return `<div class='text-left' title='${month}/${d.getDate()}/${d.getFullYear()}'>${month}/${d.getDate()}/${d.getFullYear()}</div>`;
}
@Component({
  selector: 'zetta-zs-soc-feedback',
  templateUrl: './zs-soc-feedback.component.html',
  styleUrls: ['./zs-soc-feedback.component.scss']
})


export class ZsSocFeedbackComponent implements OnInit, OnChanges {
  show_run_model=false;
  priorFeedbackDataSource;
  feedbackGridOptions: any;
  taskFeedbackColumnsDef: any;
  public loggedInUserDetails = null;
  public selectedCluster;
  total = 0;
  page = 1;
  limit = 100;
  grandTotal = 0;
  subTotal = '';
  selectedMappedColumn:number;
  taskId: number;
  projId: string;
  apiUrl: string = environment.config.API_URL;
  hasScrolled = false;
  noData = false;
  isTasksLoading = true;
  public taskGrid: AngularGridInstance;
  gridSOCFeedback: AngularGridInstance;
  updatePageLimit: boolean = false;
  currentScrolledPosition: number = 0; 
  @Input() project_id: string;
  @Input() taskName: number;
  @Input() selectedRecId: string;
  @Input() mappings = [];
  @Input() ctmGridOptions;
  @Input() datasetsTableColDef;
  @Input() trainModalDatasetSettings
  @Input() is_task_completed;
  @Input() datasets;
  @Input() isProjectAdmin : boolean = false;
  @Output() hidePriorModal: EventEmitter<any> = new EventEmitter();
  isLoadingDatasets: boolean;
  showReviewPrior: boolean = false;
  enableFinishTask: boolean = false;
  classify_project_feedback_id: string;
  dataset_id: string;
  selectedRecordId: string = null;
  selectedDatasetId: string = null;
  selectedFeedbackVoteStatus: string = null;
  selectedFeedbackClass: string = null;
  selectedTaskSummary: string = null;
  selectedTaskId: string = null;
  isFiltering: boolean = false;
  isSorting: boolean = false;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  // @Input() loadReviewFeedbackData = new EventEmitter();

  constructor(
    public zettaUtils: ZettaUtils, 
    private projectService: ProjectService,
    private baseService: BaseService, 
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,) { }

  ngOnInit() { 
   
    this.loggedInUserDetails = this.projectService.getObjectFromSession('userInfo'); 
    this.activatedRoute.parent.params.subscribe(params => this.projId = params['id']);  
    this.taskFeedbackColumnsDef = [
      {
        'displayname': 'Task Id',
        'physicalname': 'task_id',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'text-left',   
        'maxWidth': 100,
      },
      {
        'displayname': 'Task Name',
        'physicalname': 'task_summary',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'cssClass': 'mr-1',
        'headerCssClass': 'text-left', 
        'maxWidth': 200,
      }, {
        'displayname': 'Selected Class',
        'physicalname': 'class',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'maxWidth': 200,
        'formatter': blueTextFeedbackModelFormatter,
        'params': { loggedinUid : this.loggedInUserDetails.user_id, adminUser: this.isProjectAdmin}
      }, {
        'displayname': 'Voted By',
        'physicalname': 'voted_by',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'args': false,
        'maxWidth': 150,
        'cssClass': 'text-left',
        'params': { 'isDisplayDesc': false }, 
      }, {
        'displayname': 'Feedback Date',
        'physicalname': 'latest_feedback_date',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'maxWidth': 150,
        'formatter': conditionalDateFormatter
      }, {
        'displayname': 'Feedback',
        'physicalname': 'vote',
        'sortable': true,
        'datatype': 'String',
        'filterable': false,
        'maxWidth': 150,
        'cssClass': 'text-center',
        'formatter': thumbsUpandDown
      },
      {
        'displayname': 'Dataset',
        'physicalname': 'dataset_name',
        'sortable': true,
        'datatype': 'string',
        'filterable': true,
        // 'minWidth': 120,
         'maxWidth': 150,
        'cssClass': 'text-left', 
      }
    ];
    this.feedbackGridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      rowHeight: 30,
      enableRowSelection:true,
    };

    this.loadReviewFeedbackData(this.page, this.limit);

  }
  onFeedbackGridCreation(grid) {
    this.gridSOCFeedback = grid;
    this.gridSOCFeedback.slickGrid.onSort.subscribe((e, args) => {
      this.page = 1;
      this.hasScrolled = false;
    });
    this.gridSOCFeedback.filterService.onFilterChanged.subscribe(resp =>  {
      this.page = 1;
      this.hasScrolled = false;
    });
  }

  onDatasetCellClick(e) {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.gridSOCFeedback.gridService.getDataItemByRowIndex(args.row);
    this.taskId = row.task_id;
    this.classify_project_feedback_id = row.classify_project_feedback_id;
    this.dataset_id = row.dataset_id;
    if (row !== undefined && row !== null) {
      if (eventEle.target.classList.contains('blueLink')) {
        this.mappings = [];
        this.selectedRecordId = row.record_id;
        this.selectedDatasetId = row.dataset_id;
        this.selectedFeedbackClass = row.class;
        this.selectedFeedbackVoteStatus = row.vote;
        this.selectedTaskSummary= row.task_summary;
        this.selectedTaskId = row.task_id;
        this.showReviewPrior = true;
        /*this.projectService.getTaskDetails(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, this.projId, this.taskId)
        .subscribe(res => {
          if(res) {
            this.showReviewPrior = true;
            this.mappings = res['task_outputs'];
            this.taskName = res['summary'];
            this.checkFinishTaskBtnValidation(res['task_outputs']);
          }
        }, (err) => {
          this.showReviewPrior = true;
          this.mappings = [];
        });*/

      }
    }
  } 

  onCloseFeedback(){
    this.priorFeedbackDataSource=[];
    if(this.gridSOCFeedback){
      this.gridSOCFeedback.dataView.setItems(this.priorFeedbackDataSource);
      this.gridSOCFeedback.filterService.clearFilters();
    }
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    if(this.updatePageLimit) {
      setTimeout(() => {
        $(".slick-viewport")[0].scrollTo({top: this.currentScrolledPosition,left: 0,behavior: "smooth"});
        this.updatePageLimit = false;
      }, 1500);
    }  
      this.loadReviewFeedbackData(this.page, this.limit);
  }

  loadReviewFeedbackData(page, limit) { 
    let serverfilter = "";
    if(sessionStorage.getItem("serverfilter")) {
       serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
    } else {
      if (this.isFiltering) { 
        this.page = 1;
      }
      this.isFiltering = false;
    } 
   let sortfilter = "";
   if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
   }  
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.project_id}/feedback/feedbackhistory?pagination=true&pageno=${1}&pagesize=${limit}&project_type=14862`;
 
    this.projectService.getSOCFeedback(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.project_id, page, limit, serverfilter, sortfilter)
      .subscribe((data) => { 
        
        if (data) { 
          this.priorFeedbackDataSource = data.currentpage;
          if (this.priorFeedbackDataSource.length === 0) {
            this.noData = true; 
          } else {
            this.noData = false;
          }
          this.isTasksLoading = false;
          this.total = data.totalrecords;
          if (page === 1) {
            this.grandTotal = data.totalrecords;
          }
          if(data['next']) {
            this.hasScrolled = false;
          } else {
            this.hasScrolled = true
          }
          let concatedRows = this.priorFeedbackDataSource;
          if (this.gridSOCFeedback) {
            concatedRows = this.priorFeedbackDataSource;
            concatedRows = [...this.gridSOCFeedback.dataView.getItems(), ...this.priorFeedbackDataSource];
            this.priorFeedbackDataSource = concatedRows;
            this.priorFeedbackDataSource.forEach(element => {
              element.id = Math.random();
            });
            this.gridSOCFeedback.dataView.setItems(concatedRows);
            this.gridSOCFeedback.gridService.setSelectedRows([]);
            this.gridSOCFeedback.dataView.refresh();
            this.isTasksLoading = false;
          }
        }
      }, err => {
        this.messageSvc.sendMessage({ message: 'Get list of tasks failed', type: 'INFO', hideboard: true });
        this.isTasksLoading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.taskName && changes.taskName['currentValue']) { 
      this.taskName = changes.taskName['currentValue'];
    }
    if(changes && changes.selectedRecId && changes.selectedRecId['currentValue']) {
      this.selectedRecId = changes.selectedRecId['currentValue'];
    }
    if(changes && changes.datasets && changes.datasets['currentValue']) {
      this.datasets = changes.datasets['currentValue'];
    }
    if(changes && changes.ctmGridOptions && changes.ctmGridOptions['currentValue']) {
      this.ctmGridOptions = changes.ctmGridOptions['currentValue'];
    }
    if(changes && changes.datasetsTableColDef && changes.datasetsTableColDef['currentValue']) {
      this.datasetsTableColDef = changes.datasetsTableColDef['currentValue'];
    }
    if(changes && changes.trainModalDatasetSettings && changes.trainModalDatasetSettings['currentValue']) {
      this.trainModalDatasetSettings = changes.trainModalDatasetSettings['currentValue'];
    }
    if(changes && changes.is_task_completed && changes.is_task_completed['currentValue']) {
      this.is_task_completed = changes.is_task_completed['currentValue'];
    }
    if(changes && changes.mappings && changes.mappings['currentValue']) {
      this.mappings = changes.mappings['currentValue'];
      this.isLoadingDatasets = false;
      ($('#review_prior_modal') as any).modal('show');
    }
    if(changes && changes.datasets && changes.datasets['currentValue']) {
      this.mappings = changes.datasets['currentValue'];
      this.isLoadingDatasets = false;
    }
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  checkFinishTaskBtnValidation(tasks) {
    if(tasks && tasks.length > 0 && !this.is_task_completed) {
        let at_least_one_vote = tasks.filter(task => task.vote == 1 || task.vote == 0);
        this.enableFinishTask = at_least_one_vote.length > 0 ? true : false;
    }
  }

  refreshPriorModalData(eve) {
    if(eve) {
      this.priorFeedbackDataSource=[];
      if(this.gridSOCFeedback){
        this.gridSOCFeedback.dataView.setItems([]);
        this.gridSOCFeedback.filterService.clearFilters();
      }
      this.loadReviewFeedbackData(1, this.limit);
      this.showReviewPrior = false;
    }
  }

  hideUpdateFeedbackModalPopup() {
     // resetting other input values on popup closes
      this.showReviewPrior = false; 
      this.selectedRecordId = null;
      this.selectedDatasetId = null;
      this.selectedFeedbackVoteStatus = null;
      this.selectedFeedbackClass = null;
      this.selectedTaskSummary = null;
      this.selectedTaskId = null;
  }

  goToPage(n: number): void {
    this.page = n;
    this.loadReviewFeedbackData(this.page, this.limit);
  }

  onNext(): void {
      this.page++;
      this.loadReviewFeedbackData(this.page, this.limit);
      this.isTasksLoading = true;
  }

  onPrev(): void {
      this.page--;
      this.loadReviewFeedbackData(this.page, this.limit);
      this.isTasksLoading = true;
  }

  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true;
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    //On Apply Filter and remove the appied filter, Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (this.zettaUtils.virtualScroll(parentClass) &&!this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
}
