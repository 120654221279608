import { Component, ViewEncapsulation, Input, OnChanges, OnInit, SimpleChanges  } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZsClContentService } from '../../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/common/components/message/message.service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { environment } from 'src/environments/environment';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ConfidenceLevelNoEditCellRendererComponent } from 'src/app/common/shared/cell-renderer/confidence-level-formatter-NoEdit/confidence-level-formatter-NoEdit.component';
import { ColDef, GridApi, GridOptions, ICellRendererParams, ITextFilterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';

declare var $: any;



@Component({
  selector: 'zetta-business-applied-columns',
  templateUrl: './business-applied-columns.component.html',
  styleUrls: ['./business-applied-columns.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BusinessAppliedColumnsComponent implements OnInit {

  datasets: any = [];
  selectedRowDataSource;
  taskName;
  gridDatasets: AngularGridInstance;
  gridDataOptions: any;
  datasetsTableColDef: any;
  datasetsTableColDefEdit: any;
  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  showDownvoteModal = false;
  loggedInUserDetails: [];
  object_id: number;
  attribute_id: number;
  selectedDsColumn;
  datasetColumnId: number;
  isVoted = false;
  mappingType: string;
  isLoading = true;
  hasScrolled = false;
  noData = false;
  page = 1;
  limit = 100;
  @Input() taskInfo: any;
  @Input() dsColumnName: any;
  @Input() datasetId: any;
  @Input() data: any;
  @Input() isEditRuleView: any;
  @Input() catalog_id: number;
  @Input() concept_id: number
  @Input() entity_id: number;
  @Input() rule_id: number;
  showFeedbackSummaryModel: Boolean = false;
  votedUsers: [];
  datasourceName: string;
  datasetName: string;
  datasetColumnName: string;
  screenName: string;
  voteValue: string;
  conceptName: string;
  datasetColumnID: number;
  catalogRelatedUser: boolean = false;
  columnDefs: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid:AgGridAngular;
  gridApi:any;
  columnApi: any;
  constructor(private catalogSvc: ZsClContentService, private activatedRoute: ActivatedRoute,
                private router: Router, private messageSvc: MessageService,public zettaUtils: ZettaUtils
              ) { }

  ngOnInit() {
    

  this.datasetsTableColDefEdit = [
  {
    headerName: 'Data Source',
    field:'datasource_name',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    tooltipField:'datasource_name',
    floatingFilter: true,
    sortable: true,
    minWidth:150
  },
  {
    headerName: 'Data Set',
    field:'dataset_name',
    tooltipField:'dataset_name',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    floatingFilter: true,
    sortable: true,
    minWidth:150
   
  }, {
    headerName: 'Data Set Column',
    field:'column_name',
    tooltipField:'column_name',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    floatingFilter: true,
    sortable: true,
    minWidth:150
   
  }, {
    headerName: 'Data Type',
    field:'data_type',
    tooltipField:'data_type',
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    floatingFilter: true,
    sortable: true,
    minWidth:150
 
  },
  {
    headerName: 'Mapping Confidence Level',
    field:'',
    tooltipField:'',
    sortable: true,
    cellRendererFramework:ConfidenceLevelNoEditCellRendererComponent,
    minWidth:220
  },
];

    this.gridDataOptions = {
      enableCellTextSelection: true,
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      rowHeight: 30,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
    };
  }

  updateData(data) {
    this.datasets = data;
    this.datasets.forEach(element => {
      element.newId = element.id;
      element.score100 = (element.score * 100).toFixed();
      element.level = element.score100 >= 75 ? 'HIGH' : (element.score100 >= 50  && element.score100 < 75 ) ? 'MEDIUM' : 'LOW';
      element.id = Math.random();
    });
    this.datasets.sort(((a, b) => b.score100 - a.score100));
    let concatedRows = this.datasets;
    this.isLoading = false;
    if (this.gridDatasets) {
      concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
      this.datasets = concatedRows;
      this.gridDatasets.dataView.setItems(this.datasets);
      this.gridDatasets.gridService.setSelectedRows([]);
    }
  }
  getAppliedColumnsData(page,limit) {
    let serverFilter='';
    let serverSort='';
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    this.catalogSvc.getBusinessRulesAppliedColumns({ user_id: this.loggedInUserDetails['user_id'], catalog_id: this.catalog_id, object_id: this.entity_id, attribute_ids: this.concept_id,pageNo:page, limit:limit },serverFilter,serverSort).subscribe((datasetsData) => {
      this.datasets = datasetsData.currentpage;
      if (this.datasets.length === 0) {
        this.noData = true;
      }
        this.isLoading = false;
        this.datasets.forEach(element => {
            element.id = Math.random();
            element.score100 = (element.score * 100).toFixed();
            element.level = element.score100 >= 75 ? 'HIGH' : (element.score100 >= 50  && element.score100 < 75 ) ? 'MEDIUM' : 'LOW';
        });
        this.datasets.sort(((a, b) => b.score100 - a.score100));
        let concatedRows = this.datasets;
        if (this.gridDatasets) {
          concatedRows = [...this.gridDatasets.dataView.getItems(), ...this.datasets];
          this.datasets = concatedRows;
          this.gridDatasets.dataView.setItems(this.datasets);
          this.gridDatasets.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
    }, err => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: 'Data mismatch in Applied columns section', type: 'ERROR', hideboard: true });
     });
     this.getCatalogUsers();
  }
  onDatasetsGridCreation(grid) {
    const parent = this;
    parent.agGrid = grid;
    parent.columnApi = grid.columnApi;
  }

  onDatasetCellClick(cellParams): void {        
    const { event, data, colDef } = cellParams;
    const args = cellParams.args;
    const row = cellParams.data;
    this.selectedDsColumn = row;
    if (row !== undefined && row !== null) {
      if (event.target.title === row.name) {
        this.dsColumnName = row.name;           
        this.showDownvoteModal=true;
        this.datasetId=row.dataset_id;
      } else if (event.target.title === row.datasource_name) {
        // $('#datasets_modal').modal('hide');
        $('#datasets_modal').addClass('d-none');
        // this.router.navigate(['/zs/datasources', row.datasource_id]);
      } else if(event.target.className =='fal fa-thumbs-down handDown' ||
       event.target.className == 'fal fa-thumbs-down'){
        this.showDownvoteModal = true;
        this.datasetId=row.dataset_id;
        this.datasetColumnId = row.dataset_column_id;
        this.object_id= row.entity_id;
        this.attribute_id = row.attribute_id;
        this.mappingType=row.mapping_type;
        this.dsColumnName = row.column_name;

      } else if(event.target.className =='fal fa-thumbs-up'){
            const upVotedata ={            
              attribute_id: row.attribute_id,
              catalog_id: this.catalog_id,
              dataset_column_id: row.dataset_column_id,
              dataset_id: row.dataset_id,
              entity_id: row.entity_id,
              mapping_type: "manual",
              removing_attribute_id: this.concept_id
            }
            const payload = {
              user_id: this.loggedInUserDetails['user_id'],
              tenant_id : this.loggedInUserDetails['tenant_id'],
              payload:upVotedata
            }
            
            this.catalogSvc.saveVoteSemanticObject(payload,'up_vote').subscribe(resp =>{
              this.isVoted=true;
              event.target.classList.remove('fal', 'fa-thumbs-up');
              event.target.classList.add('fas', 'fa-thumbs-up');
              event.target.classList.remove('fas fa-thumbs-down');
              event.target.classList.add('fas fa-thumbs-down');
              if (this.gridDatasets) {
                this.gridDatasets.dataView.setItems([]);
                this.isLoading = true;
                this.getAppliedColumnsData(1, this.limit);
              }
            }, error=>{
              this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            });
      } else if (event.target.className.includes('countUp')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.upvotes;
        this.datasourceName = row.datasource_name;
        this.datasetName = row.dataset_name;
        this.datasetColumnName = row.column_name;
        this.conceptName = row.attribute_name;
        this.catalog_id = this.catalog_id;
        this.entity_id = row.entity_id;
        this.attribute_id = row.attribute_id;
        this.datasetColumnID = row.dataset_column_id;
        this.voteValue = 'upVote';
        this.screenName = 'mappedColumn';

      } else if (event.target.className.includes('countDown')) {
        this.showFeedbackSummaryModel = true;
        this.votedUsers = row.downvotes;
        this.datasourceName = row.datasource_name;
        this.datasetName = row.dataset_name;
        this.datasetColumnName = row.column_name;
        this.conceptName = row.attribute_name;
        this.catalog_id = this.catalog_id;
        this.entity_id = row.entity_id;
        this.attribute_id = row.attribute_id;
        this.datasetColumnID = row.dataset_column_id;
        this.voteValue = 'downVote';
        this.screenName = 'mappedColumn';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
      sessionStorage.removeItem("serverfilter");
      sessionStorage.removeItem("sortfilter")
      if(changes.datasetId && changes.datasetId.currentValue) {
        this.datasetId = changes.datasetId.currentValue;
      }
      if(changes.dsColumnName && changes.dsColumnName.currentValue) {
        this.dsColumnName = changes.dsColumnName.currentValue;
      }
      if(changes.rule_id && changes.rule_id.currentValue) {        
        this.isLoading = true;
        this.datasets = [];
        this.selectedRowDataSource = [];
        if (this.gridDatasets) {
          this.gridDatasets.dataView.setItems(this.datasets);
          this.gridDatasets.gridService.setSelectedRows([]);
        }
        if(this.concept_id) {
          this.getAppliedColumnsData(this.page, this.limit); 
        }  
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.entity_id}/concepts/${this.concept_id}/mappedcolumns?pagination=true&pageno=${1}&pagesize=${this.limit}&high_confidance_only=true`;        
      }
    }
  }

  closeModalPopup(event) {
    if (event) {
      this.isVoted = true;
      var selectedDsColumnId = this.selectedDsColumn.id; 

      var thumbsUpIcon = $('#thumbs-up-' + selectedDsColumnId);
      var thumbsDownIcon = $('#thumbs-down-' + selectedDsColumnId);
  
      thumbsUpIcon.removeClass('fas fa-thumbs-up');
      thumbsUpIcon.addClass('fal fa-thumbs-up');
      thumbsDownIcon.removeClass('fal fa-thumbs-down');
      thumbsDownIcon.addClass('fas fa-thumbs-down');
  
      var selectedDsColumn = this.selectedDsColumn;
 
      if (this.agGrid) {
        var api = this.agGrid.api;

        var selectedNode = api.getRowNode(selectedDsColumn);

        if (selectedNode) {
          var thumbsUpCell = api.getCellRendererInstances({
            columns: ['Mapping Confidence Level'], 
            rowNodes: [selectedNode],
          })[0];
  
          var thumbsDownCell = api.getCellRendererInstances({
            columns: ['Mapping Confidence Level'], 
            rowNodes: [selectedNode],
          })[0];
  
          thumbsUpCell.getGui().classList.remove('fal', 'fa-thumbs-up');
          thumbsUpCell.getGui().classList.add('fas', 'fa-thumbs-up');
          thumbsDownCell.getGui().classList.remove('fas', 'fa-thumbs-down');
          thumbsDownCell.getGui().classList.add('fal', 'fa-thumbs-down');
        }
  
        api.setRowData([]);
        this.isLoading = true;
        this.getAppliedColumnsData(1, this.limit);
      }
    }
  
    this.showDownvoteModal = false;
  }
  
  onScrollView(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  onNext(): void {
    this.page++;
    this.getAppliedColumnsData(this.page, this.limit);
  }

  onTrainModel() {
    this.catalogSvc.runModel303().subscribe((res) => {
      this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT });
    }, (err) => { });
  }

  navigateToTrainModal() {
    this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.entity_id + '/concepts/review-training'], { queryParams: { object_id: this.entity_id, concept_id: this.concept_id,  catalogLevel: 'concept'}});
  }

  closeFeedbackModel(event) {
    $('#feedback_summary_modal').modal('hide');
    this.showFeedbackSummaryModel = event;
  }

  getCatalogUsers() {
    this.catalogSvc.getCatalogUser(this.catalog_id)
    .subscribe(catalogUsers => {
      if(catalogUsers) {
        catalogUsers.forEach(user => {
          if(user.user_id == this.loggedInUserDetails['user_id']) {
            if(user.roles.includes(AppGlobals.CATALOG_ADMIN) || user.roles.includes(AppGlobals.CATALOG_APPROVER) || user.roles.includes(AppGlobals.CATALOG_REVEIWER)) {
              this.catalogRelatedUser = true;           
            } else {
              this.catalogRelatedUser = false
            }
          }
        });
      }
    });
  }
}
