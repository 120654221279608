import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { ProjectStatus } from "src/app/common/components/project-progress/project-progress.model";
import * as _ from 'lodash';
import { ZsClContentService } from '../../zs-cl-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/common/components/message/message.service';
import { environment } from 'src/environments/environment';
import { DatasetService } from 'src/app/common/components/datasets/dataset.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, dataContext: any) => {
  return value == true ? `<label class="checkbox-container">
               <input type="checkbox" checked>
               <span class="checkmark"></span>
            </label>`
    : `<label class="checkbox-container">
               <input type="checkbox">
               <span class="checkmark"></span>
            </label>`;
}

const tagFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.tags && dataContext.tags != null) {
    let resp = `<i class="fa fa-tag" aria-hidden="true"></i>`;
    if (dataContext.tags !== undefined) {
      if (dataContext.tags != null && dataContext.tags.length) {
        const tagarr = dataContext.tags.split(',');
        if (tagarr.length > 1) {
          tagarr.forEach(elem => {
            if (elem.length > 26) {
              elem = elem.substring(0, 26) + '...';
            }
            resp += `
                    <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2">
                                      <span class="btn-label btn-label1" title="${elem}">${elem}</span>
                                      <span class="text-secondary">X</span>
                                   </button>`;
          });
        } else {
          if (dataContext.tags.length > 26) {
            dataContext.tags = dataContext.tags.substring(0, 26) + '...';
          }
          resp += `

                  <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2">
                                      <span class="btn-label btn-label1" title="${dataContext.tags}">${dataContext.tags}</span>
                                      <span class="text-secondary">X</span>
                                   </button>`;
        }
      }
      resp += ``;
    }
    return resp;
  }
  else {
    return;
  }
};

const rightTagFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.classification_tag && dataContext.classification_tag != null) {
    let resp = `<i class="fa fa-tag" aria-hidden="true"></i>`;
    if (dataContext.classification_tag !== undefined) {
      if (dataContext.classification_tag != null && dataContext.classification_tag.length) {
        const tagarr = dataContext.classification_tag.split(',');
        if (tagarr.length > 1) {
          tagarr.forEach(elem => {
            if (elem.length > 26) {
              elem = elem.substring(0, 26) + '...';
            }
            resp += `
                    <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2">
                                      <span class="btn-label btn-label1" title="${elem}">${elem}</span>
                                      <span class="text-secondary">X</span>
                                   </button>`;
          });
        } else {
          if (dataContext.classification_tag.length > 26) {
            dataContext.classification_tag = dataContext.classification_tag.substring(0, 26) + '...';
          }
          resp += `

                  <button type="button" class="d-inline-block mb-1 btn btn-zetta btn-tag btn-zetta1 btn-light1 tag-zetta tag-light pr-2">
                                      <span class="btn-label btn-label1" title="${dataContext.classification_tag}">${dataContext.classification_tag}</span>
                                      <span class="text-secondary">X</span>
                                   </button>`;
        }
      }
      resp += ``;
    }
    return resp;
  }
  else {
    return;
  }
};

const dsNameFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class="text-truncate pointer"><label title="${value}" class="blueLink modal-feedback text-truncate wd-10">${value}</label></div>`;
}

const datasetNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (dataContext.shouldDisable) {
    return `<div class="disable-row">${value}</div>`;
  } else {
    return `<div>${value}</div>`;
  }
}

const LEFT_EMPTY_GRID_MSG = 'No Data Found.';
const RIGHT_EMPTY_GRID_MSG = 'No Data Found.'
@Component({
  selector: 'zetta-zs-cl-project-add-data',
  templateUrl: './zs-cl-project-add-data.component.html',
  styleUrls: ['./zs-cl-project-add-data.component.scss']
})
export class ZsClProjectAddDataComponent implements OnInit, OnDestroy, AfterViewChecked {
  progressState = new ProjectStatus();
  leftGridDataSource: any = [];
  leftGridColumnsDef: any;
  leftTableSettings: object = { 'height': "100%", 'width': "99.40%" };
  leftGrid: AngularGridInstance;
  projId: string;
  rightGridDataSource: any = [];
  rightGridColumnsDef: any;
  rightTableSettings: object = { 'height': "100%", 'width': "99%" };
  rightGrid: AngularGridInstance;
  loggedInUserDetails = null;
  leftGridOptions: any;
  rightGridOptions: any;
  hasLeftUsersSelected = false;
  hasRightUsersSelected = false;
  enabled: Boolean = false;
  catalog_id: string;
  entity_id: string;
  showBreadcrumb = false;
  totalLeft = 0;
  totalRight = 0;
  grandTotalDs = 0;
  subTotalDs = '';
  datasource_id: string;
  source_id: string;
  dataset_id: string;
  projectData: any;
  page = 1;
  noData = false;
  limit = 100;
  hasScrolled = false;
  isDataReady=false;
  filterDataSets = [];
  isFiltering = false;
  showmodelpopup = false;
  dsName;
  datasetId;
  project_type_id: number;
  project_type:string;
  manageData=false;
  leftGridRows = 0;
    rightGridRows = 0;
    isEditProject : string

  constructor(public zettaUtils: ZettaUtils, private ZsClService: ZsClContentService, 
    private datasetSvc: DatasetService,
    private route: ActivatedRoute, private router: Router, private messageSvc :MessageService) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.projId = params['id'];
    });
    this.route.queryParamMap.subscribe(param => {
      this.catalog_id = param.get('catalog_id');
      this.entity_id = param.get('entity_id');
      this.project_type = param.get('project_type');
      this.project_type_id = +param.get('project_type_id');
      if (param.has('datasource_id') && param.has('dataset_id') && param.has('source_id')) {
        this.datasource_id = param.get('datasource_id');
        this.dataset_id = param.get('dataset_id');
        this.source_id = param.get('source_id');
      }
      if(param.has('manageData')){
        this.manageData = param.get('manageData')=='true'?true:false;
    }
    });
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.isEditProject= this.route.snapshot.queryParamMap.get('isEditProject');
    const showBreadcrumb = this.route.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }
    this.getProject();
    this.getFilterDataSets();
    this.initColDef();
    this.getProjectData();
    this.bindConceptGrid(this.page, this.limit);
   
  }

  getFilterDataSets() {

    if(this.project_type=='14863')
    {
      const data = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.loggedInUserDetails.tenant_id,
        project_id: this.projId,
    };
    this.ZsClService.getConceptsProjectTrainigData(data).subscribe(resp => {
        if (resp.length) {
            if (resp[1].length) {
              this.filterDataSets = resp[1];
            }
        }
    }, error => {
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
    }
    else
    {
      const data = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.loggedInUserDetails.tenant_id,
        project_id: this.projId,
    };
    this.ZsClService.getClassifierProjectTrainigData(data).subscribe(resp => {
        if (resp.length) {
            if (resp[1]['currentpage'].length) {
              this.filterDataSets = resp[1]['currentpage'];
            }
        }
    }, error => {
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
    }
    
  }

  checkForDisable() {
    if (this.filterDataSets) {
    this.leftGridDataSource.forEach(element => {
        this.filterDataSets.forEach(rightEle => {
          if (element.dataset_id === rightEle.dataset_id) {
            element['shouldDisable'] = true;
          } else {
            return;
          }
        });
    });
    }
      $('div.disableRow div.slick-row div.disable-row').parent().parent().addClass('disabled-state');
  }

  initColDef() {
    this.rightGridColumnsDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasourceName',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    },
    {
      'displayname': 'Data Set Name',
      'physicalname': 'datasetName',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': dsNameFormattter,
    },
    {
      'displayname': 'Classification',
      'physicalname': 'classification_tag',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': rightTagFormatter,
    }
    ];

    this.leftGridColumnsDef = [{
      'displayname': 'Data Source',
      'physicalname': 'datasource_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': datasetNameFormatter,
    },
    {
      'displayname': 'Data Set Name',
      'physicalname': 'name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': dsNameFormattter,

    },
    {
      'displayname': 'Classification',
      'physicalname': 'all_tags',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': tagFormatter,
    },
    ];

    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: false,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: false,
      asyncEditorLoading: false,
      multiSelectable: false,
      autoEdit: true,
      editable: true,
      noDataMsg: LEFT_EMPTY_GRID_MSG,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: false,
      asyncEditorLoading: false,
      multiSelectable: false,
      autoEdit: true,
      editable: true,
      noDataMsg: RIGHT_EMPTY_GRID_MSG,
    };
  }

  bindConceptGrid(pageNo, limit) {
    this.page = pageNo;
    this.leftTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets?paginate=true&checkAtrributes=true&pageno=${1}&pagesize=${limit}`;
    let serverfilter;
    if(sessionStorage.getItem("serverfilter")){
       serverfilter = sessionStorage.getItem("serverfilter");
      this.isFiltering = true;
      this.page = 1;
    } else {
      if(this.isFiltering){ 
        this.page = 1;
      }
      this.isFiltering = false;
    }
    const getDatasets = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      pageno: this.page,
      perpage: limit,
    };

    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    this.datasetSvc.getAttributeDatasets(getDatasets,serverfilter, sortfilter).subscribe((data) => {
      this.leftGridDataSource = data['currentpage'];
      if (this.leftGridDataSource.length === 0) {
          this.noData = true;
      }
      this.totalLeft = data['totalrecords'];      
      let concatedRows = this.leftGridDataSource;
      let all_tags = [];
      this.leftGridDataSource.forEach(element => {
        element.id = Math.random();
        element.tags = element.tags === null ? '' : element.tags;
        element['action'] = 'edit';
        all_tags.push(element.tags);
        element.all_tags = all_tags.toString();
        return element;
      });
      if (pageNo === 1) {
        this.grandTotalDs = this.totalLeft - this.rightGridDataSource.length;
      }
      this.totalLeft = this.totalLeft - this.rightGridDataSource.length;
      if (this.leftGrid) {        
        concatedRows = [...this.leftGrid.dataView.getItems(), ...this.leftGridDataSource];
        this.leftGridDataSource = concatedRows;
        this.leftGrid.dataView.setItems(concatedRows);
        this.leftGrid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
      this.leftGridRows = this.totalLeft;
      if(this.rightGridDataSource.length>0)
      {            this.rightGridDataSource.forEach(element => {
        let leftGridDS = this.leftGridDataSource.filter(obj => obj.dataset_id !== element.dataset_id);
        this.leftGridDataSource = leftGridDS;
       
      });
        
}

    }, 
    err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    }
    );
}
  getProjectData(reload=false){
    // this.page = pageNo;
    this.rightTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/classify/projects/${this.projId}/projectsdata?pagination=true&pageno=${1}&pagesize=${this.limit}`;
    let serverfilter;
    if(sessionStorage.getItem("serverfilter")){
       serverfilter = sessionStorage.getItem("serverfilter");
      this.page = 1;
    } else {
      if(this.isFiltering){ 
        this.page = 1;
      }
      this.isFiltering = false;
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    this.ZsClService.getMappedProjectData(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id,this.projId, this.page, this.limit, serverfilter, sortfilter)
    .subscribe(resp => {
      if (resp.length) {
        if (resp[1].length) {
            this.rightGridDataSource = resp[1];
           // this.totalRight = resp[1].length;
            let concatedRows = this.rightGridDataSource;
            this.rightGridDataSource = resp[1].map((item) => {
              item['id'] = Math.random();
              return item;
            });
            this.enabled = true;
            if (this.rightGrid) {
              this.rightGridDataSource = resp[1].map((item) => {
                item['id'] = Math.random();
                return item;
              });
              this.rightGridDataSource = concatedRows;
              this.totalRight=this.rightGridDataSource.length;
              this.rightGridRows = this.rightGridDataSource.length;
              this.rightGrid.dataView.setItems(concatedRows);
              this.rightGrid.gridService.setSelectedRows([]);
              this.rightGrid.dataView.refresh();
              const inputGridService = this.rightGrid.gridService;
              inputGridService.renderGrid();
              if(this.rightGridDataSource.length>0)
              {            this.rightGridDataSource.forEach(element => {
                let leftGridDS = this.leftGridDataSource.filter(obj => obj.dataset_id !== element.dataset_id);
                this.leftGridDataSource = leftGridDS;
               
              });
              if (this.page === 1) {
                this.grandTotalDs = this.totalLeft - this.rightGridDataSource.length;
              }
              if(reload==false)
              {
                this.totalLeft=this.totalLeft-this.rightGridDataSource.length;
              }
              else
              {
                this.totalLeft= this.leftGridRows-this.rightGridDataSource.length;
              }
              
              
            }
             
            }
        }
    }})
  }
  getProject() {
    const payload = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      project_id: this.projId
    };
    this.ZsClService.getclassifyProject(payload).subscribe(resp => {
      if (resp) {
        this.projectData = resp;
        this.project_type_id = resp.type;
        if (this.showBreadcrumb === true) {
          this.progressState.states = this.project_type.toLowerCase() == 'concept parser' ? 
          this.zettaUtils.getStateList('manage-classify-concept-parser-project-data') : this.zettaUtils.getStateList('manage-project-data');
          this.progressState.currentStateIndex = this.project_type.toLowerCase() == 'concept parser' ? 1:3;
          this.progressState.type = 'manageProjectData';
        } else if(this.project_type_id === 14863) {
          this.progressState.states = this.zettaUtils.getStateList('create-classify-concept-parser-project');
          this.progressState.currentStateIndex = 5;
          this.progressState.type = 'Data Classification Project';
          this.progressState.classificationProject = resp;
          this.progressState.isEdit = false;
      this.progressState.currentStateInfo = 'Saved...';
        } else {
          this.progressState.states = this.zettaUtils.getStateList('create-classify-project');
          this.progressState.currentStateIndex = 5;
          this.progressState.type = 'Data Classification Project';
          this.progressState.classificationProject = resp;
          this.progressState.isEdit = this.isEditProject === 'true';
        }
        this.progressState.currentStateInfo = 'Saved...';
      }
      this.isDataReady=true;
    });
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('modal-feedback')) {
        this.showmodelpopup = true;
        this.dsName = row.name;
        this.datasetId = row.dataset_id;

      }
    }
  }

  onRightGridCellClicked(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if ($(eventEle.target).hasClass('modal-feedback')) {
        this.showmodelpopup = true;
        this.dsName = row.name;
        this.datasetId = row.dataset_id;

      }
    }
  }

  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
    });
  }

  rightGridCreated(grid) {
    const parent = this;
    parent.rightGrid = grid;
    parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasRightUsersSelected = args.rows.length > 0 ? true : false;
    });
  }
  rightGridCount() {
    if (this.rightGrid) {
        return this.rightGrid.dataView.getItems().length;
    } else {
        return this.rightGridDataSource.length;
    }
}


  onAdd() {
    
    const leftGridDataService = this.leftGrid.gridService;
    const rightGridDataService = this.rightGrid.gridService;
    // call service to update DB as bulk operation (pass list of items to be inserted).
    // on success Add to left Grid
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const datasetList: Object[] = [];
    const rows: any[] = [];
       let lastRow = this.getHigherRankingRow(10000000000);
       let lastRowRanking = lastRow !== undefined ? lastRow.sort_order : undefined;

    selectedRows.forEach(index => {
        const row = leftGridDataService.getDataItemByRowNumber(index);
        if (row !== undefined && row !== null) {
          row['datasetName'] = row.name;
          row['classification_tag']=row.tags;
          row['datasourceName']=row.datasource_name;
          rows.push(row);
        }
    });
    rows.forEach(row => {
      console.log(row);
      this.ZsClService.saveProjectsData(
                   {data: {tenantId: this.loggedInUserDetails['tenant_id'], userId: this.loggedInUserDetails['user_id'], id: this.projId,
                 payload: {dataset_id: row.dataset_id, datasource_id: row.datasource_id, classification_tag: row.tags}
               }})
               .subscribe(res => {
                if(res) {
                this.ZsClService.getMappedDatasets({data: {tenantId: this.loggedInUserDetails['tenant_id'], userId: this.loggedInUserDetails['user_id'], id: this.projId}})
                .subscribe(ress => {
                  console.log(ress)
                                 ress.forEach(data => {
                                    data.id = Math.random();
                                    row['classify_project_data_id']=data.classify_project_data_id;
                                   //this.rightGridDataSource.push(row);
                                 });
                                });

                                this.rightGridDataSource.push(row);
                                rightGridDataService.addItemToDatagrid(row, true);
                                // remove Item from left grid
                                datasetList.push({ dataset_id: row.dataset_id });
                                this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
                                leftGridDataService.deleteDataGridItem(row);
                                this.getProjectData(true);
                                 // remove row selection from left/right grid
                                 this.rightGrid.dataView.setItems(_.orderBy(this.rightGrid.dataView.getItems(), ['sort_order'], ['asc']));           
                                leftGridDataService.setSelectedRows([]);
                                rightGridDataService.setSelectedRows([]);
                                this.progressState = Object.assign({}, this.progressState);
                                // Update Row Count
                               
                              }
               },error => {
                         if (error.error.error) {
                           this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
                         }
                       });

                      
       
    });
    this.totalLeft = this.totalLeft - datasetList.length;
    this.rightGridRows = this.rightGridRows + datasetList.length;
   
}

onRemove() {
  const leftGridDataService = this.leftGrid.gridService;
  const rightGridDataService = this.rightGrid.gridService;
  const selectedRows: number[] = rightGridDataService.getSelectedRows();
  const datasets: String[] = [];
  const rows: any[] = [];
  const classifyAddDataIds: any[] = [];
  let lastRow = this.getHigherRankingRow(10000000000);
  let lastRowRanking = lastRow !== undefined ? lastRow.sort_order : undefined;
  selectedRows.forEach(index => {
      const row = rightGridDataService.getDataItemByRowIndex(index);
      if (row !== undefined && row !== null) {
              rows.push(row);
              console.log(row);
              classifyAddDataIds.push(row.classify_project_data_id);
             
      }
      this.ZsClService.removeProjectsData(
        {
          data: {
            tenantId: this.loggedInUserDetails['tenant_id'], userId: this.loggedInUserDetails['user_id'], id: this.projId,
            payload: {body : { classify_project_data_ids: classifyAddDataIds }} 
          }
        })
        .subscribe(res => {});
  });
  rows.forEach((row,i) => {
    row.name = rows[i].datasetName;
          row.datasource_name = rows[i].datasourceName;  
          row.tags=rows[i].classification_tag;
    this.leftGridDataSource.push(row);
    leftGridDataService.addItemToDatagrid(row, true);
    // remove Item from right grid
    datasets.push(row.dataset_id);
    this.rightGridDataSource = this.rightGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
    rightGridDataService.deleteDataGridItem(row);
});
// this.leftGrid.slickGrid.setData();
leftGridDataService.renderGrid();
rightGridDataService.renderGrid();
// remove row selection from left grid
leftGridDataService.setSelectedRows([]);
rightGridDataService.setSelectedRows([]);
this.totalLeft = this.totalLeft + datasets.length;
this.rightGridRows = this.rightGridRows - datasets.length;
}
  getHigherRankingRow(ranking: number) {
    let immediateHigherRankingRow: any;
    this.rightGrid.dataView.getItems().forEach(row => {
      if (row.sort_order < ranking) {
        if (immediateHigherRankingRow) {
          if (immediateHigherRankingRow.sort_order < row.sort_order) {
            immediateHigherRankingRow = row;
          }
        } else {
          immediateHigherRankingRow = row;
        }
      }
    });

    return immediateHigherRankingRow;
  }

  onRunModel() {
    if (this.enabled) {
      const payload = {
        classify_picker: true,
        supervised: false,
        classify_project_id: this.projId
      };
      this.ZsClService.runModelClassifyProjectAddData(payload, this.project_type_id).subscribe(resp => {
        if (resp) {
          this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, isModel:true, messageHeader:AppGlobals.CLASSIFICATION_MSG_HEADER, messageText:AppGlobals.CLASSIFICATION_MSG_TEXT, path:`/zs-cl/projects/${this.projId}`});
        }
      }, error => {
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
      });
    }
  }
  onPrevious() {
    sessionStorage.setItem("activeStep", "2");
    const qParams = { catalog_id: this.catalog_id, entity_id: this.entity_id, datasource_id: this.datasource_id, dataset_id: this.dataset_id, source_id: this.source_id, showBreadcrumb: this.showBreadcrumb};
    if(this.project_type_id == 14863){  
    this.router.navigate([`/zs-cl/projects/${this.projId}/add-reference-data/data-column`], { queryParams: { activeLink: '3' }, queryParamsHandling: 'merge' });
    }else{
      this.router.navigate([`/zs-cl/projects/${this.projId}/add-training-data`], { queryParams: qParams,queryParamsHandling:'merge' });
    }
    
  }


  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }
  leftGridCount() {
    if (this.leftGrid) {
        return this.leftGrid.dataView.getItems().length;
    } else {
        return this.leftGridDataSource.length;
    }
}

onScroll(parentClass) {
  if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
    this.hasScrolled = true;
    this.onNext();
  }
}

onNext(): void {
  this.page++;
  this.bindConceptGrid(this.page, this.limit);
}

onPrev(): void {
  this.page--;
  this.bindConceptGrid(this.page, this.limit);
}

onCancel() {
  if (this.manageData) { 
    this.router.navigate(['/zs-cl/projects', this.projId], { queryParams: { project_type: this.project_type, project_type_id: this.project_type_id  },queryParamsHandling: 'merge'});
  } 
  else {
    this.router.navigate(['/zs-cl/projects' ]);
  }                 
}

ngAfterViewChecked() {
  this.checkForDisable();
}

popupoutput(message){
  this.showmodelpopup = message;   
}
  onUploadDataSource() {
    this.router.navigate(['/zs/datasets/select-data-source']);
  }
  onManageDataSet() {
    this.router.navigate(['/zs/datasets']);
  }
  receiveLeftDataSourceRecords($event) {
    this.subTotalDs = this.zettaUtils.getSubTotal(this.grandTotalDs, $event);

  }
}
