import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatasetService } from '../../dataset.service';
import { MessageService } from '../../../message/message.service';
import { ZettaUtils } from '../../../../../common/shared/zettaUtils';
import { ObservableService } from '../../../../../common/services/observable.service';

@Component({
  selector: 'zetta-data-transformations',
  templateUrl: './data-transformations.component.html',
  styleUrls: ['./data-transformations.component.scss']
})

export class DataTransformationsComponent implements OnInit, OnDestroy {

  public tableSettings: object = { 'height': '100%', 'width': "99%", 'pageSize': 15, 'isNormalPagination': false };
  public loggedInUserDetails;
  public datasetAttributes: any = [];
  viewResultsTableColDef: any;
  gridOptions: any;
  dataset_id = 0;
  isLoading = false;
  isNotDataAdmin: boolean = true;

  constructor(private datasetService: DatasetService,
              private activatedRoute: ActivatedRoute,
              private messageSvc: MessageService,
              public zettaUtils: ZettaUtils,
              public observeSvc: ObservableService) {

      this.loggedInUserDetails = this.datasetService.getObjectFromSession('userInfo');
      this.activatedRoute.parent.params.subscribe(params => {
          this.dataset_id = params['id'];
      });
   }

  ngOnInit() {
    this.viewResultsTableColDef = [{
      'displayname': 'Attribute',
      'physicalname': 'column',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 175,
      'formatter': ''
    }, {
      'displayname': 'Transformation Type',
      'physicalname': 'column_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-uppercase',
      'minWidth': 60,
      'formatter': ''
    }];
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34,
    };

    this.observeSvc.getDataSetDetails.subscribe(resp => {
      this.isNotDataAdmin = resp['created_by'] === this.loggedInUserDetails.user_name ? false : true;
    })
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

}
