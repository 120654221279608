import { Component, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';

@Component({
	selector: 'ad-view-prediction-model-accuracy',
	templateUrl: './ad-view-prediction-model-accuracy.component.html',
	styleUrls: ['./ad-view-prediction-model-accuracy.component.scss']
})

export class AdViewPredictionModelAccuracyComponent implements OnInit {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public screenHeight: number = screen.height;
	
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName: string = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;
	public entitiesChartOptions: object = {
		'resizeRequired': false,
		'variable': 'Confidence',
		'category': 'Current Model',
		'isMoneyFormat': true,
		'labelPercentValue' : 0,
		'labelPercentLabel': 'Current Model',
		'labelPercentLabel2': 'Confidence'
	};

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];

	public lineChartOptions: object = {
		// 'height': 180,
		'width': 1250,
		'resizeRequired': true,
		'label': 'year',
		'value': 'value',
		'lineColor': '#57cff2',
		'circleColor': '#ffff00',
		'isMoneyFormat':true,
		'margin' : { 'top': 0, 'right': 40, 'bottom': 20, 'left': 250 },
	};
	public lineChartDS = null;
	public lineChartDS1 = null;



	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZmZettaUtils,
		private _projectService: AnalyticService,
		public observeSvc: ZmObservableService,
		private activeRoute: ActivatedRoute, private router: Router) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
		
	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;
		// parent.getEntityResolvedData(parent.userinfo.userid, routeParams.id);

		if(parent.screenHeight <= 768) {
			parent.lineChartOptions['height'] = 200;
		}else{
			parent.lineChartOptions['height'] = 400;
		}

		let dataSet = [];
		let yy = '0';
		// for (var i = 0; i < 2; i++) {
			let values = [];
			let values1 = [];
			let years = 21;
			let year = new Date();
			let year_start = year.getFullYear() - years;

			var price_value = 0;
			// let num = Math.floor(Math.random() * (400 - 10)) + 10;
			for (var j = 0; j < years; j++) {
				// num = num + Math.floor(Math.random() * (1000 - 10)) - 100;
				// values.push({ 'date': (year_start + j + 1).toString(), 'price': num });
				price_value = Math.floor(Math.random() * (1000 - 10)) + 10;
				values.push({ 'date': (year_start + j + 1).toString(), 'price': price_value });
				values1.push({ 'date': (year_start + j + 1).toString(), 'price': price_value + (Math.floor(Math.random() * (200 - 20)) + 40) });
				console.log('values[j]: ' + values[j])
			}
			
			dataSet.push({ 'name': 'LINE 1', 'values': values });
			dataSet.push({ 'name': 'LINE 2', 'values': values1 });
			console.log('name: ' + name + ' --- values: ' + values)
		// }
		parent.lineChartDS1 = dataSet;

		// parent._projectService.getEntitiesSummaryData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.statisticsObj = {
		// 			'model_runs': 0,
		// 			'model_runs_as_of': new Date(),
		// 			'unique_institutions': 0,
		// 			'compression_ratio': 'N/A'
		// 		};
				
		// 	for (let index = 0; index < data.length; index++) {
		// 		const obj = data[index];
		// 		if (obj.name === 'run_number') {
		// 			parent.statisticsObj['model_runs'] = obj['value'] || 0;
		// 		}
		// 		else if (obj.name === 'as_of') {
		// 			parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
		// 		} else if (obj.name === 'total_entities') {
		// 			parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
		// 		} else if (obj.name === 'compression_ratio') {
		// 			parent.statisticsObj['compression_ratio'] = obj['count'] || 'N/A';
		// 		} else if (obj.name === 'overall_confidence') {
		// 			parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
		// 			parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
		// 		}
		// 	}
		// 		parent.entitiesDataSource = parent.getDonutParseData(data);
		// 	},
		// 	error => {
		// 		parent.entitiesDataSource = [];
		// 		parent.statisticsObj = {'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 'N/A'};
		// 	}
		// );

		// parent._projectService.getEntitiesModelsData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.lineChartDS = parent.prepareLineChartData(data);
		// 	},
		// 	error => {
		// 		parent.lineChartDS = [];
		// 	}
		// );

		// parent.observeSvc.getEntityName.subscribe(data => {
		// 	this.selectedProjectName = data;
	    // });
	}

	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
				this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	// prepareLineChartData(data) {
	//     const dataSet = [];
	//     data.forEach((element) => {
	//     	const d = new Date(element['createdts']);
	//         dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
	//     });
	//     return dataSet;
	//   }
	  
	  refresh() {
		window.location.reload();
	  }
}
