import { AppGlobals } from '../../../../common/shared/app.globals';
import { Dataset } from '../project.model';
import { IDataset } from '../../../../common/components/datasets/dataset.model';
import { Project } from '../project.model';
import { DatasetService } from '../../../../common/components/datasets/dataset.service';
import { DatasourceService } from '../../../../common/components/datasources/datasource.service';
import { ProjectService } from '../project.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Event, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { circledNumberFormatter } from '../../../../common/shared/formatters/circledNumberFormatter';
import { dataModalFormatter } from '../../../../common/shared/formatters/dataModalFormatter';
import { DatePipe } from '@angular/common';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { Column, FieldType, Formatter, Formatters, GridOption, OnEventArgs, AngularGridInstance, Editors } from 'angular-slickgrid';
import * as _ from 'lodash';
import { MessageService } from '../../../../common/components/message/message.service';
// import $ from 'jquery';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from '../../../../common/services/base-service';
import { environment } from 'src/environments/environment';
import { ColDef, GridOptions, GridApi } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
declare var $: any;
// import { dragFormatterLink } from '../../../../common/shared/formatters/dragFormatterLink';

const RIGTH_EMPTY_GRID_MSG = 'Select one or more data sets from the left panel.';
const LEFT_EMPTY_GRID_MSG = 'No Datasets to map.';

const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return value == true ? `
   <div class="pl-4">
        <label class="checkbox-container mb-0">
            <input type="checkbox" checked>
            <span class="checkmark"></span>
        </label>
   </div>`: `
    <div class="pl-4">
        <label class="checkbox-container mb-0">
            <input type="checkbox">
            <span class="checkmark"></span>
        </label>          
    </div>`;
}
const dsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
   return `<div class="blueLink pointer modal-feedback text-truncate" title='${value}'>${value}</div>`;
}

const checkForSortOrder = (params) => {
    let value = params.data.sort_order;
    let grid = {};
    let row = params.rowIndex;
    let dataLength = (params.node.gridOptionsWrapper && params.node.gridOptionsWrapper.gridOptions.rowData && params.node.gridOptionsWrapper.gridOptions.rowData.length) ? params.node.gridOptionsWrapper.gridOptions.rowData.length : 0;
    if(row == 0) {
        return `
        <div class="p-0 m-0">
            <div class="row p-0 m-0">
                <div class="col-auto numberCircle p-0 ml-2">${value}</div>
                <div class="col-1 pl-1 pr-3"><i class="fa fa-arrow-up notActiveGreenFont" aria-hidden="true"></i></div>
                <div class="col-1 px-1 pr-3"><i class="fa fa-arrow-down redFont" aria-hidden="true"></i></div>
                <div class="col-auto px-1"><label class="ctmDateFont"> </label></div>
            </div>
        </div>
        `;
    } else if(row === dataLength-1) {
        return `
        <div class="p-0 m-0">
                        <div class="row p-0 m-0">
                            <div class="col-auto numberCircle p-0 ml-2">${value}</div>
                            <div class="col-1 pl-1 pr-3"><i class="fa fa-arrow-up greenFont"  aria-hidden="true"></i></div>
                            <div class="col-1 px-1 pr-3"><i class="fa fa-arrow-down notActiveRedFont"  aria-hidden="true"></i></div>
                            <div class="col-auto px-1"><label class="ctmDateFont"></label></div>
                        </div>
        </div>
        `;
    } else {
        return `
        <div class="p-0 m-0">
                        <div class="row p-0 m-0">
                            <div class="col-auto numberCircle p-0 ml-2">${value}</div>
                            <div class="col-1 pl-1 pr-3"><i class="fa fa-arrow-up greenFont"  aria-hidden="true"></i></div>
                            <div class="col-1 px-1 pr-3"><i class="fa fa-arrow-down redFont"  aria-hidden="true"></i></div>
                            <div class="col-auto px-1"><label class="ctmDateFont"></label></div>
                        </div>
        </div>
        `;
    }
}

const checkBoxFormatter = (params) => {
    let value = params.data.check_for_duplicates;
    if(value) {
        return `<div class="checkbox-formatter">
        <label class="checkbox-container">
        <input type="checkbox" checked>
        <span class="checkmark"></span>
        </label>
        </div>`;
    } else {
        return `<div class="checkbox-formatter">
        <label class="checkbox-container">
        <input type="checkbox">
        <span class="checkmark"></span>
        </label>
        </div>`;
    }
}

const acativeLinkFormatter = params => {
    if(params && params.data && params.data.name) {
        return `<div class="blueLink pointer modal-feedback">${params.data.name}</div>`;
    }
}

@Component({
    selector: 'zetta-data-sets',
    templateUrl: './data-sets.component.html',
    styleUrls: ['./data-sets.component.scss'],
    encapsulation: ViewEncapsulation.None
})  

export class DataSetsComponent implements OnInit, OnDestroy {

    isDataReady = false;
    rightGridReady = true;
    leftGrid: AngularGridInstance;
    rightGrid: AngularGridInstance;
    leftGridDataSource: any = [];
    rightGridDataSource: any = [];
    leftGridColumnsDef: any;
    rightGridColumnsDef: any;
    projectDetail: any = new Project();
    leftGridRows = 0;
    rightGridRows = 0;
    rightDatasourcDataLength = 0;
    progressState = new ProjectStatus();
    rightGridOptions: object;
    leftGridOptions: object;
    showBreadcrumb = false;
    isMergedBtnModalView = false;
    isDataSetModalView = false;
    datasources;
    gridApi : GridApi;
    public grid: AngularGridInstance;
    public modalGrid: AngularGridInstance;
    public selectedDataSet;
    public rowData;
    public loggedInUserDetails = null;
    public selectedDataSetName : string ;
    public selectedDataSourcename : string;

    hasActiveJob = true;
    anyLoader = false;

    tableSettings: object = { 'height': "100%", 'width': "100%" };
    leftTableSettings: object = { 'height': "100%", 'width': "100%" };
    rightTableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 30, 'isNormalPagination': false };
    pipe = new DatePipe('en-US'); // Use your own locale

    gridOptions: any;
    modalGridOptions: any;
    isLoading = false;
    isSampelDsLoading = false;
    total = 0;
    page = 1;
    limit = 20;
    modelColumnDef = [];
    modalRowData: any;
    showTab: string;
    datasourceId: number;
    datasetTypeId: number;
    newDataset;
    errorMessage = undefined;
    public dataset_id;
    dataRes;
    is_upload_complete = false;
    url;
    safeSrc: SafeResourceUrl;
    datasetTypes: any = [];
    mySubscription: any;
    projectDatasetList: any = [];
    clearModal =  Math.random();   // "clearModal === 0" SHOW MODAL   /   "clearModal > 0" HIDE MODAL

    modeState: any = {};
    object_type_id;
    imgpath:string = "../../../assets/images/common/YETI-Databases.png"

    dsName;
    showmodelpopup = false;
    datasetId;
    entity_id;
    isProjectAdded = false;
    dataset_ids: any = [];
    isProjectEdit = false;
    new_project: any = {};
    fu_type_project_dataset: object;
    

    columnDefs: ColDef[] = [];
    defaultColDef: ColDef = {}
    // fsGridOptions: GridOptions;
    agGrid: AgGridAngular;
    dataset_name: string;
    filterKeys: any;
    sortKeys: any;
    columnApi: any;
    dragRowInfo: any;
    dropRowPosition: any;
    fsGridOptions: GridOptions = { 
        headerHeight: 45, 
        rowHeight: 30,
        floatingFiltersHeight: 49,
        suppressRowClickSelection: true,
        getRowStyle: params => {
          if (params.node.rowIndex % 2 === 0) {
            return { background: AppGlobals.EVEN_ROW_BG_COLOR };
          }
          return { background: AppGlobals.ODD_ROW_BG_COLOR };
        },
      };
    constructor(public service: ProjectService,
        private datasetSvc: DatasetService,
        private datasourceSvc: DatasourceService,
        private baseService: BaseService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private _router: Router,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.fu_type_project_dataset = AppGlobals.FU_TYPE_PROJECT_DATASET;
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        
        this.object_type_id = +sessionStorage.getItem('project_type_id');
        
        const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
        if (showBreadcrumb && showBreadcrumb === 'true') {
            this.showBreadcrumb = true;
        }
        this.activatedRoute.queryParamMap.subscribe(params =>{
            this.isProjectEdit = params.get('isEditProject')== 'true' ? true : false;
        });
        this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets`; 
        if (!this.showBreadcrumb) {
            // Remove BreadCrumb
            $('#breadcrumb').addClass('d-none');
            this.modeState.mode = 'Setup';  //Modes states: "Setup", "Training", "Production"
        } else {
            this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
        }

        this.modalGridOptions = {
            enableGridMenu: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            enableRowSelection: true
        };

        this.rightGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: RIGTH_EMPTY_GRID_MSG,
        };

        this.leftGridOptions = {
            enableGridMenu: false,
            enableAddRow: false,
            selectable: true,
            enableFiltering: true,
            CheckboxSelector: true,
            enableCellNavigation: true,
            multiSelectable: true,
            noDataMsg: LEFT_EMPTY_GRID_MSG,
        };

        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });


        this.rightTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id }`;
        this.rightTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id }`;
        this.service.getProjectDataSets(this.loggedInUserDetails['user_id'],
        this.projectDetail.project_id, this.loggedInUserDetails['tenant_id']).subscribe(responseList => {

            this.projectDatasetList = responseList[0];
            this.projectDetail = <Project>responseList[1];
            this.rightGridDataSource = this.projectDetail.datasets;
            this.rightGridDataSource.forEach(row => {
                    row.updated_ts = this.pipe.transform(new Date(this.projectDetail.updated_ts ||
                    this.projectDetail.created_ts), AppGlobals.MM_DD_YYYY_FORMAT);
            });
            this.rightGridRows = this.rightGridDataSource.length;
            
            this.leftGridDataSource = responseList[0];
            this.leftGridDataSource = this.leftGridDataSource.filter(dataset1 => !this.rightGridDataSource.find(dataset2 => dataset2.dataset_id === dataset1.dataset_id));
            this.leftGridRows = this.leftGridDataSource.length;

            this.progressState.states = this.service.getProjectStateList();
            this.progressState.currentStateIndex = 2;
            this.progressState.currentStateInfo = 'Saved...';
            this.progressState.type = 'Project';
            this.progressState.project = this.projectDetail;
            this.progressState.isEdit = (this.projectDetail.project_id !== undefined && this.projectDetail.project_id !== null) ? true : false;
            this.progressState.isEdit = sessionStorage.getItem('newProject') === 'true' ? false : this.progressState.isEdit; 
            this.initColDef();
            this.isDataReady = true;
        },
        error => {
            this.messageSvc.sendMessage({message: 'Get project dataset has failed', type: 'INFO', hideboard: true});
        });
        if(this.object_type_id){
            this.service.getCurrentActiveJobs(this.loggedInUserDetails['user_id'],
            this.projectDetail.project_id, this.object_type_id).subscribe(jobs => {
                    this.hasActiveJob = this.service.hasActiveJob(jobs);
            }, error => {
                this.hasActiveJob = false;
                // this.messageSvc.sendMessage({message: 'Dataset get current active jobs has failed', type: 'INFO', hideboard: true});
            });
        }
        

        this.datasourceSvc.getDatasources({ userId: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], pageno: '1', perpage: 100 }).subscribe(respDatasources => {
            this.datasources = respDatasources['currentpage'];
            this.datasourceId = this.datasources[0].datasource_id;
        }, error => {
            this.messageSvc.sendMessage({ message: 'Select Datasources has failed', type: 'INFO', hideboard: true });
        });

        this.datasetSvc.getDataSetTypes().subscribe(respDatasetTypes => {
            this.datasetTypes = respDatasetTypes;
            this.datasetTypeId = this.datasetTypes[0].lookup_id;
        }, error => {
            this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
        });
        this.service.getProject(this.loggedInUserDetails['user_id'],this.projectDetail.project_id).subscribe(resp =>{
         if(resp){
            this.new_project = resp;
            this.entity_id= resp.entity_id;
         }
        });
    }

    removeCancel1() {
        $('#cancelBoxModal1').removeClass('show');
    }
    
    removeCancel2() {
        $('#cancelBoxModal2').removeClass('show');
        this.cancelUpload();
    }

    initColDef() {
        this.leftGridColumnsDef = [{
            'displayname': 'Data Source',
            'physicalname': 'datasource_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true
        },
        {
            'displayname': 'Data Set Name',
            'physicalname': 'name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            // 'formatter': dataModalFormatter
            'formatter': dsFormatter
        }
        ];

        this.rightGridColumnsDef = [
            {
                headerName: '',
                field: 'datasource_name', 
                filter: false,
                sortable: false, 
                editable: false, 
                rowDrag: true,
                maxWidth: 30,
            },
            {
                headerName: '',
                field: '', 
                filter: false,
                sortable: false, 
                editable: false, 
                maxWidth: 30,
                headerCheckboxSelection: true,
                checkboxSelection: true,
            },

            {
            headerName: 'Data Source',
            field: 'datasource_name', 
            sortable: true, 
            editable: false, 
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            floatingFilterComponentParams: {
            suppressFilterButton: true,
            
        },
           tooltipField :'datasource_name'
        },
        {
            headerName: 'Data Set Name',
            field: 'name',
            sortable: true, 
            editable: false, 
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellRenderer: (params) => acativeLinkFormatter(params),
            floatingFilterComponentParams: {
            suppressFilterButton: true,
            },
            tooltipField : 'name'
        },
    {
        headerName: 'Authoritativeness Ranking',
        field: 'sort_order', 
        filter: false,
        sortable: true, 
        editable: false, 
        minWidth: 120,
        cellRenderer: (params) => checkForSortOrder(params),
        cellClass: ['d-inline-block', 'ranking', 'pl-5'],
    },
    {
        headerName: 'Check For Duplicates',
        field: 'check_for_duplicates', 
        filter: false,
        sortable: false, 
        cellRenderer: (params) => checkBoxFormatter(params),
        headerComponentParams: { 
            template: `<div><span class="check-info">Check For Duplicates</span>
                        <i class="info-icon fa fa-info-circle"></i>
                    </div>`
                },
        minWidth: 120,
        cellClass: ['text-center', 'pl-5'],
    }
        ];
    }

    onAdd() { 
        if(this.rightGridDataSource && this.rightGridDataSource.length < 1) {
            this.rightGridReady = false;
        }
        const leftGridDataService = this.leftGrid.gridService;
        // const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be inserted).
        // on success Add to left Grid

        const selectedRows: number[] = leftGridDataService.getSelectedRows();
        const datasets = [];
        const rows: any[] = [];

        let lastRow = this.getHigherRankingRow(10000000000);
        let lastRowRanking = lastRow !== undefined ? lastRow.sort_order : undefined;
        selectedRows.forEach(index => {

            this.leftGridRows = this.leftGridRows - 1;
            this.rightGridRows = this.rightGridRows + 1;

            const row = leftGridDataService.getDataItemByRowIndex(index);
            if (row !== undefined && row !== null) {
                if (lastRowRanking) {
                    lastRowRanking = lastRowRanking + 1;
                } else {
                    lastRowRanking = this.rightGridRows;
                }
                row.sort_order = lastRowRanking;
                row.updated_ts = this.pipe.transform(new Date(), AppGlobals.MM_DD_YYYY_FORMAT);
                row['check_for_duplicates'] = true;
                rows.push(row);
            }
        });

        var countUsers = 0;
        rows.forEach(row => {
            this.rightGridDataSource.push(row);
            // rightGridDataService.addItemToDatagrid(row, true);
            this.gridApi.applyTransaction({add: [row] });
            const dataset = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                dataset_id: row.dataset_id
            }
            this.datasetSvc.getDataset(dataset).subscribe(response => {
                response.entitlement.users.forEach(ds_user => {
                    this.new_project.users.forEach(pj_user => {
                        if (pj_user.user_id === ds_user.user_id) {
                            if (ds_user.read_only || ds_user.read_write || ds_user.is_admin) {
                                countUsers++;
                            }
                        }
                    });
                });

                if (countUsers !== this.new_project.users.length) {
                    this.messageSvc.sendMessage({ message: `There is at-least one user which does not have even Read access to this dataset.\n 
                                                            Please remove data-set or ignore if that's fine.`, type: 'INFO', hideboard: true });
                }
            });
         
            // remove Item from left grid
            datasets.push(row);
            this.leftGridDataSource = this.leftGridDataSource.filter(leftRow => !(leftRow.datasource_name === row.datasource_name && leftRow.name === row.name));
            leftGridDataService.deleteDataGridItem(row);
            setTimeout(() => {
                this.rightGridReady = true;
            }, 1500);
        });

        // this.rightGrid.dataView.setItems(_.orderBy(this.rightGrid.dataView.getItems(), ['sort_order'], ['asc']));
        // remove row selection from left/right grid

        leftGridDataService.setSelectedRows([]);
        // update DB
        this.progressState = Object.assign({}, this.progressState);
        this.updateProject(datasets, 'ADD');        
        //this.updateProjectDataSets(datasets);
    }

    updateProjectProgressBar() {

        $('#projectState2').removeClass('d-none');
        setTimeout(function () {
            $('#projectState2').addClass('d-none');
        }, 5000);
    }

    updateProject(datasets: any[], opcode: string, isNextBtnClicked = false) {
        
        if (datasets.length > 0) {
            const project = new Project();
            project.project_id = this.projectDetail.project_id;
            project.user_id = this.loggedInUserDetails['user_id'];
            project.tenant_id = this.projectDetail.tenant_id;
            if ('ADD' === opcode) {
                project.datasets = datasets;
            } else {
                project.deletedatasets = datasets;
            }
            if(opcode == 'ADD'){
                datasets.forEach(element => {
                    this.dataset_ids.push({
                        dataset_id:element.dataset_id
                    });
                });
            }           
            this.service.saveProject(project).subscribe(resp => {
                this.updateProjectProgressBar();   
                // Commenting this as this call for now is not required on Adding the dataset it should only call on next button             
                //if(!this.isProjectAdded){
                    // this.service.saveClassifyPrediction(this.loggedInUserDetails['user_id'],this.projectDetail.project_id,payload).subscribe(resp =>{                    
                    //     this.isProjectAdded =true;   
                    // },error=>{});
               // }                         
            }, err => {
                this.messageSvc.sendMessage({message: 'Dataset update project has failed', type: 'INFO', hideboard: true});
            });

            
        }

    }

    saveProjectData() {
        let mappedDatasets = this.rightGrid.dataView.getItems();
        if(this.dataset_ids.length){
            const payload = {
                "entity_id": this.entity_id,
                "datasets": this.dataset_ids
            }
            this.service.saveClassifyPrediction(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, payload).subscribe(resp => {              
                this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'attributes'],
            { queryParams: { showBreadcrumb: this.showBreadcrumb } });
            }, error => {
                this.messageSvc.sendMessage({ message: 'Dataset update project has failed', type: 'INFO', hideboard: true });
            });
        } else if (mappedDatasets.length && this.dataset_ids.length === 0) {
            this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'attributes'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
        }
        
    }

    onRemove() {
        const leftGridDataService = this.leftGrid.gridService;
        // const rightGridDataService = this.rightGrid.gridService;
        // call service to update DB as bulk operation (pass list of items to be removed).
        // on success removed from UI

        const selectedRows: number[] = this.gridApi.getSelectedRows();
        let datasets = [];
        const rows: any[] = [];
        let minRanking: number;

        selectedRows.forEach(row => {

            if (row !== undefined && row !== null) {
                row['id'] = Math.random();
                rows.push(row);

                // Used to move all rows up 
                if (minRanking) {
                    if (minRanking > row['sort_order']) {
                        minRanking = row['sort_order'];
                    }
                } else {
                    minRanking = row['sort_order'];
                }
            }

        });

        rows.forEach(row => {
            this.leftGridDataSource.push(row);
            leftGridDataService.addItemToDatagrid(row, true);

            //this.leftGrid.slickGrid.getData().addItem(row);
            // remove Item from right grid
            datasets.push(row.dataset_id);
            this.rightGridDataSource = this.rightGridDataSource.filter(rightRow => !(rightRow.datasource_name === row.datasource_name && rightRow.name === row.name));
            this.gridApi.applyTransaction({ remove: [row] });
            // this.rightGrid.slickGrid.getData().deleteItem(row.id);
            
            //Updating the payload
            if(this.dataset_ids.length){
                this.dataset_ids = this.dataset_ids.filter(ds => ds.dataset_id != undefined && ds.dataset_id != row.dataset_id);               
            }
             
        });

        this.leftGridRows = this.leftGridRows + datasets.length;
        this.rightGridRows = this.rightGridRows - datasets.length;

        // update DB
        this.updateProject(datasets, 'DELETE');
        this.progressState = Object.assign({}, this.progressState);
        // move all rows up and update DB
        datasets = [];

        this.rightGridDataSource.forEach(row => {
            if (minRanking < row.sort_order) {
                let higherRow = this.getHigherRankingRow(row.sort_order);
                if (higherRow) {
                    row.sort_order = higherRow.sort_order + 1;
                } else {
                    row.sort_order = 1;
                }

                this.updateRightGridRow(row);
                this.gridApi.applyTransaction({ update: [row] });
                datasets.push(row);
            }
        });

        // update DB for the moved up rows
        this.updateProject(datasets, 'ADD');

        leftGridDataService.setSelectedRows([]);

    }

    rightGridCreated(grid) {
        this.rightGrid = grid;
        // this.rightGrid.dataView.setItems(_.orderBy(this.rightGrid.dataView.getItems(), ['sort_order'], ['asc']));

        this.agGrid = grid;
        this.gridApi = grid.api;
        // this.columnApi = grid.columnApi;
        this.rightDatasourcDataLength =  grid.api.getModel().gridOptionsWrapper.gridOptions.rowData.length;
        this.rightGridDataSource = grid.api.getModel().gridOptionsWrapper.gridOptions.rowData;
    }

    leftGridCreated(grid) {
        this.leftGrid = grid;
    }

    leftGridCount() {
        if (this.leftGrid) {
            return this.leftGrid.dataView.getItems().length;
        } else {
            return this.leftGridDataSource.length;
        }
    }

    hasLoaderExecuted(): boolean {
        return this.anyLoader;
    }

    rightGridCount() {
        // if (this.rightGrid) {
        //     return this.rightGrid.dataView.getItems().length;
        // } else {
            return this.rightGridDataSource.length;
        // }
    }

    onNext(isCreateFlow) {
        const rightGridDataService = this.rightGrid.gridService;
        // const gridrows = this.rightGrid.dataView.getItems();
        const userList: any = [];
        this.rightGridDataSource.forEach(row => {
            userList.push({ dataset_id: row.dataset_id, check_for_duplicates: row.check_for_duplicates });
        });
        const payload: any = {
            "checkforduplicates": userList
        }
        this.service.saveDuplicateDatasets(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, payload).subscribe(resp => {
        });
       // this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'attributes'],
        //        { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling: 'merge' });
        

        this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'edit-entity-model'],
        { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling: 'merge' });

        // this.updateProject(this.rightGridDataSource, 'ADD');        
        // commenting this code as /map-classify-predictions api call is not required
        // if (isCreateFlow && !this.isProjectEdit) {
        //     this.saveProjectData();
        // } else {
        //     this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'attributes'],
        //         { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling: 'merge' });
        // }
    }

    onPrevious() {
        this.router.navigate(['/zs/projects', this.projectDetail.project_id, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb }, queryParamsHandling: 'merge' });
    }

    ngOnDestroy() {
        if (!this.showBreadcrumb) {
            $('#breadcrumb').removeClass('d-none');
        }
        this.messageSvc.clearMessage();
    }

    onRightGridCellClicked(params) {
        this.modalRowData = null;
        this.isDataSetModalView = true;
        this.isMergedBtnModalView = false;

        this.rightGridDataSource = params.api.getModel().gridOptionsWrapper.gridOptions.rowData;
        this.rightDatasourcDataLength = this.rightGridDataSource.length;
        const fieldName = params.colDef.field;
        const row = params.data;
        // const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
        let swapRow;
        let updateRow = false;

        if (row !== undefined && row !== null) {
            if($(params.event.target).hasClass('checkmark')) {
            let checked = params.event.target.parentNode.children[0].hasAttribute('checked');
            if(checked) {
                params.event.target.parentNode.children[0].removeAttribute('checked');
                row['check_for_duplicates'] = false;
            } else {
                params.event.target.parentNode.children[0].setAttribute('checked', 'checked');
                row['check_for_duplicates'] = true;
            }
        }
            if ($(params.event.target).hasClass('modal-feedback')) {
                // this.selectedDataSourcename = row.datasource_name;
                // this.selectedDataSet = row.name;
                // this.url = this.baseService.apiUrl +  
                //            '/users/' + this.loggedInUserDetails['user_id'] + 
                //            '/tenants/' + this.loggedInUserDetails['tenant_id'] + 
                //            '/datasets/' + row.dataset_id + '/dataprofiler/report';
                // this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
                // this.selectOptionDataSetModelList(row.dataset_id);
                this.showmodelpopup = true;
                this.dsName = row.name;
                this.datasetId = row.dataset_id;

            } else if (params.event.target.className === 'fa fa-arrow-up greenFont') {

                if (row.sort_order !== undefined && row.sort_order !== null && this.rightGridDataSource.length > 1) {
                    let selectedRowIndex = params.rowIndex;
                    let rowNode = params.api.getRowNode(selectedRowIndex);
                    let nextRowNode = params.api.getRowNode(selectedRowIndex - 1);
                    // Swap Ranking with higher ranked row 
                    const tempsortorder = row.sort_order;
                    swapRow = this.getHigherRankingRow(tempsortorder);
                    if (swapRow) {
                        row.sort_order = swapRow.sort_order;
                        swapRow.sort_order = tempsortorder;
                    } else {
                        row.sort_order = row.sort_order - 1;
                    }
                    updateRow = true;
                    rowNode.setData(swapRow);
                    nextRowNode.setData(row);
                } else if (row.sort_order === null) {
                    // in case data is corrupted from DB
                    row.sort_order = 1;
                    updateRow = true;
                }

            } else if (params.event.target.className === 'fa fa-arrow-down redFont') {

                if (row.sort_order !== undefined && row.sort_order !== null && row.sort_order !== this.rightDatasourcDataLength && this.rightDatasourcDataLength > 1) {
                    // Swap Ranking with lower ranked row 
                    let selectedRowIndex = params.rowIndex;
                    let rowNode = params.api.getRowNode(selectedRowIndex);
                    let nextRowNode = params.api.getRowNode(selectedRowIndex + 1);
                    const tempsortorder = row.sort_order;

                    swapRow = this.getLowerRankingRow(tempsortorder);
                    if (swapRow) {
                        row.sort_order = swapRow.sort_order;
                        swapRow.sort_order = tempsortorder;
                    } else {
                        row.sort_order = row.sort_order + 1;
                    }
                    updateRow = true;
                    rowNode.setData(swapRow);
                    nextRowNode.setData(row);
                } else if (row.sort_order === null) {
                    // in case data is corrupted from DB
                    row.sort_order = this.rightGridRows;
                    updateRow = true;
                }
            }
            if (updateRow) {
                this.updateRightGridRow(row);
                let datasets: Dataset[] = [];

                if (swapRow) {
                    const swappedDataset = new Dataset();
                    swappedDataset.dataset_id = swapRow.dataset_id;
                    swappedDataset.sort_order = swapRow.sort_order;
                    datasets.push(swappedDataset);
                }
                // this.rightGrid.dataView.setItems(_.orderBy(this.rightGrid.dataView.getItems(), ['sort_order'], ['asc']));
                const dataset = new Dataset();
                dataset.dataset_id = row.dataset_id;
                dataset.sort_order = row.sort_order;
                datasets.push(dataset);

                this.updateProject(datasets, 'ADD');

                // this.rightGrid.gridService.setSelectedRows([]);
            }

        }

    }

    updateRightGridRow(row) {
        let rightRow = this.rightGridDataSource.find(rightRow => (rightRow.datasource_name === row.datasource_name && rightRow.name === row.name));
        if (rightRow) {
            rightRow.sort_order = row.sort_order;
        }
    }

    // Less ranking number is higher
    getHigherRankingRow(ranking: number) {
        let immediateHigherRankingRow: any;
        this.rightGridDataSource.forEach(row => {
            if (row.sort_order < ranking) {
                if (immediateHigherRankingRow) {
                    if (immediateHigherRankingRow.sort_order < row.sort_order) {
                        immediateHigherRankingRow = row;
                    }
                } else {
                    immediateHigherRankingRow = row;
                }
            }
        });

        return immediateHigherRankingRow;
    }

    onCancel() {
        if (!this.showBreadcrumb) {
            this.router.navigate(['/zs/projects']);
        } else {
            this.router.navigate(['/zs/projects', this.projectDetail.project_id]);
        }
    }

    // bigger ranking number is lower
    getLowerRankingRow(ranking: number) {
        let immediateLowerRankingRow: any;
         this.rightGridDataSource.forEach(row => {
            if (row.sort_order > ranking) {
                if (immediateLowerRankingRow) {
                    if (immediateLowerRankingRow.sort_order > row.sort_order) {
                        immediateLowerRankingRow = row;
                    }
                } else {
                    immediateLowerRankingRow = row;
                }
            }
        });
        return immediateLowerRankingRow;
    }

    onModelGridCreation(grid) {
        this.modalGrid = grid;
    }

    totalPages(): number {
        return this.zettaUtils.getTotalPages(this.total, this.limit);
    }

    // selectOptionDataSetModelList(dataSetId, limit = 30) {
    //     this.isDataSetModalView = true;
    //     this.isMergedBtnModalView = false;
    //     this.isSampelDsLoading = true;
    //     this.service.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.projectDetail.tenant_id, dataSetId, this.page, limit).subscribe((data) => {
    //         this.modalRowData = data.currentpage;
    //         const rowKeys = [];
    //         if (data.currentpage.length) {
    //             const myVar: any = data.currentpage[0];
    //             for (var key in myVar) {
    //                 rowKeys.push(key);
    //             }
    //         }
    //         this.modelColumnDef = [];
    //         rowKeys.forEach(column => {
    //             this.modelColumnDef.push({
    //                 'displayname': column,
    //                 'physicalname': column,
    //                 'sortable': true,
    //                 'datatype': 'String',
    //                 'filterable': true,
    //                 'minWidth': 150,
    //             });
    //         });
    //         this.modalRowData.forEach((element: any) => {
    //             element.id = Math.random();
    //         });
    //         if (this.modalGrid) {
    //             this.modalGrid.dataView.setItems(this.modalRowData);
    //             this.modalGrid.gridService.setSelectedRows([]);
    //         }
    //         this.isSampelDsLoading = false;
    //     }, err => {
    //         this.messageSvc.sendMessage({ message: 'No data available', type: 'INFO', hideboard: true });
    //         this.isSampelDsLoading = false;
    //         this.modalRowData = [];
    //     });
    // }

    onCellClick(e): void {
        //this.isDataSetModalView = true;
        this.modalRowData = null;
        const eventEle = e.eventData;
        const args = e.args;
        const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);

        if (row !== undefined && row !== null) {
            // if ($(eventEle.target).hasClass('modal-feedback')) {
            //     this.selectedDataSourcename = row.datasource_name;
            //     this.selectedDataSet = row.name;
            //     // row.dataset_id = 1;  //delete, is mockdata
            //     this.url = this.baseService.apiUrl +  
            //                '/users/' + this.loggedInUserDetails['user_id'] + 
            //                '/tenants/' + this.loggedInUserDetails['tenant_id'] + 
            //                '/datasets/' + row.dataset_id + '/dataprofiler/report';
            //     this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            //     this.selectOptionDataSetModelList(row.dataset_id);
            // }

            this.showmodelpopup = true;
            this.dsName = row.name;
            this.datasetId = row.dataset_id;

        }
    }





    // onLeftGridCellClicked(params) {
    //     const args = params.args;
    //     const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
    //     if (row !== undefined && row !== null) {
    //         this.showmodelpopup = true;
    //         this.dsName = row.dataset_name;
    //         this.datasetId = row.dataset_id;
    //     }
    // }





    onViewMergedDataSetClick(e) {
        this.modalRowData = null;
        this.isMergedBtnModalView = true;
        this.isDataSetModalView = false;

        this.isSampelDsLoading = true;
        this.service.getselectViewMergedBtnModelList(this.loggedInUserDetails['user_id'], this.projectDetail.tenant_id, this.projectDetail.project_id, this.page, 50).subscribe((data) => {
                this.modalRowData = data;
                let rowKeys  = [];
                if(data.length>0){
                    rowKeys = Object.keys(data[0]);
                }
                this.modelColumnDef = [];

                rowKeys.forEach(column =>{
                    this.modelColumnDef.push({
                        'displayname': column,
                        'physicalname': column,
                        'sortable': true,
                        'datatype': 'String',
                        'filterable': true,
                    })
                })

                this.modalRowData.forEach((element: any) => {
                    element.id = Math.random();
                });

                if (this.modalGrid) {
                    this.modalGrid.dataView.setItems(this.modalRowData);
                    this.modalGrid.gridService.setSelectedRows([]);
                }
                this.isSampelDsLoading = false;
            }, err => {
                this.isSampelDsLoading = false;
                this.modalRowData = [];
            });
    }

    receiveFileName($event) {
        this.dataRes = $event
        this.newDataset = this.dataRes.newDataset;
        this.is_upload_complete = this.dataRes.is_upload_complete;
    }

    previousUpload() {
        this.showTab = 'select-file';
    }

    saveMetadataDataset() {
        const postData: IDataset = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.newDataset.dataset_id + "",
            payload: {
                name: this.newDataset.name,
                description: this.newDataset.description,
                datasource_id: this.newDataset.datasource_id ? +this.newDataset.datasource_id : this.datasourceId,
                dataset_type_id: this.newDataset.dataset_type_id ? this.newDataset.dataset_type_id : this.datasetTypeId,
                delimiter: this.newDataset.delimiter,
                source_table_or_filename: this.newDataset.source_table_or_filename,
                header: this.newDataset.header,
            }
        };
        this.datasetSvc.editDataset(postData).subscribe((result) => {
            var newData: any = result;
            newData.id = Math.random();
            const datasource = this.datasources.find(datasource => datasource.datasource_id === +this.newDataset.datasource_id);
            newData.datasource_name = datasource.name;
            const leftGridDataService = this.leftGrid.gridService;
            this.leftGridDataSource.push(newData);
            this.projectDatasetList.push(newData);
            leftGridDataService.addItemToDatagrid(newData, true);
            this.leftGridRows = this.leftGridRows + 1;
            this.showTab = 'select-file';
            $('#uploadFileSelectFileModal').modal('hide');
            this.newDataset = undefined;
        }, (responseError) => {
            this.errorMessage = responseError.error.message;
            this.messageSvc.sendMessage({ message: 'Create dataset has failed', type: 'INFO', hideboard: true });
        });

        //Update Entitlements
        const entitlements: any = {
            user_id: this.loggedInUserDetails['user_id'],
            tenant_id: this.loggedInUserDetails['tenant_id'],
            dataset_id: this.newDataset.dataset_id + "",
            payload: [
                {
                  "group_id": this.loggedInUserDetails['group_id'],
                  "entitlements": [
                    {
                      "user_id": this.loggedInUserDetails['user_id'],
                      "rw": true
                    }
                  ]
                }
              ]              
        };
        this.datasetSvc.updateEntitlements(entitlements).subscribe((result) => {
        }, (responseError) => {
            this.errorMessage = responseError.error.message;
            this.messageSvc.sendMessage({ message: 'Update Entitlements has failed', type: 'INFO', hideboard: true });
        });
        this.is_upload_complete = false;
    }
    
    cancelUpload() {
        this.clearModal =  Math.random();
        // this.showTab = 'select-file';
        this.dataset_id = Math.random();
        // this.dataset_id = undefined;
        if(this.newDataset !== undefined) {
            const getDataset: IDataset = {
                user_id: this.loggedInUserDetails['user_id'],
                tenant_id: this.loggedInUserDetails['tenant_id'],
                dataset_id: this.newDataset.dataset_id + "",
            };
            this.datasetSvc.deleteDataset(getDataset).subscribe((data) => { 
            }, err => { });
            this.newDataset = undefined;
            this.router.navigate(['/zs/projects/' + this.projectDetail.project_id + '/datasets'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
        } 
        $('#uploadFileSelectFileModal').modal('hide');
        this.newDataset = undefined;
    }

    cancelUpload2() {
        this.showTab = 'select-file';
        //delete the previously created dataset 
    }

    popupoutput(message){
        this.showmodelpopup = message;   
      }

      onDragRowStart(eve) {
        let selectedRow = eve.api.getRowNode(eve.overIndex);
        this.dragRowInfo = selectedRow.data;
      }

      onDragRowEnd(eve) {
        let dropRowData = eve.api.getRowNode(eve.overIndex).data;
        this.dropRowPosition = eve.overIndex;
        let payload =  {
            'is_deleted':false,
            'project_id':this.projectDetail.project_id,
            'user_id':this.loggedInUserDetails.user_id,
            'tenant_id':this.loggedInUserDetails.tenant_id,
            'datasets':[{'sort_order': dropRowData.sort_order,'dataset_id':this.dragRowInfo.dataset_id}]
        };
        this.service.updateDatasetPosition({'user_id':this.loggedInUserDetails.user_id,'project_id':this.projectDetail.project_id}, payload)
        .subscribe(resp => {
            if(resp) {
                this.refreshRightGridData(); 
            }                             
        }, err => {
            this.messageSvc.sendMessage({message: 'Dataset update project has failed', type: 'INFO', hideboard: true});
        });
      }

      refreshRightGridData() {
        this.service.getProjectDataSets(this.loggedInUserDetails['user_id'],
        this.projectDetail.project_id, this.loggedInUserDetails['tenant_id']).subscribe(responseList => {
            if(responseList && responseList[1]) {
                this.rightGridDataSource = [];
                this.projectDetail = <Project>responseList[1];
                this.rightGridDataSource = this.projectDetail.datasets;
                this.rightGridDataSource.forEach(row => {
                        row.updated_ts = this.pipe.transform(new Date(this.projectDetail.updated_ts ||
                        this.projectDetail.created_ts), AppGlobals.MM_DD_YYYY_FORMAT);
                });
            }
        });
      }
}
