import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ZettaUtils } from '../../zettaUtils';

@Component({
  selector: 'app-custom-cell-renderer',
  template: `
    <div [class]="getCellClasses()" [title]="getTitle()">{{ getValue() }}</div>
  `
})
export class  NumericFormatterTextComponent implements ICellRendererAngularComp {
  value: any;
  zettaUtilsInstance = new ZettaUtils();
  params: any;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }

  getCellClasses(): string {
    let cssColmWidth = 'wd-total-entitle';
    if (this.params.column.getColDef().field === 'datasets') {
      cssColmWidth = 'wd-total-ds';
    }
    return `text-right`; 
  }

  getTitle(): string {
    return this.value ? String(this.value) : '0';
  }

  getValue(): string {
    return this.value ? this.zettaUtilsInstance.commarization(this.value, 2) : '0';
  }
}
