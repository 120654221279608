import { ZmZettaUtils } from './../../../../common/zm-shared/zm-zettaUtils';
import { Analytic } from '../analytic.model';
import { AnalyticService } from '../analytic.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatus } from '../../zm-project-progress/project-progress.model';
import { AppGlobals } from '../../../../common/zm-shared/app.globals';
import { HttpClient } from '@angular/common/http';
import $ from 'jquery';
import { trigger, transition, animate, style, state } from '@angular/animations';

@Component({
  selector: 'zm-create-analytic',
  templateUrl: './create-analytic.component.html',
  styleUrls: ['./create-analytic.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    // trigger('slideView', [
    //     state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
    //     state('false', style({ transform: 'translateX(0)', opacity: 1 })),
    //     transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
    //     transition('1 => 1', animate('500ms', style({ transform: 'translateX(50%)', 'opacity': 0 }))),
    //   ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),
      
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('300ms ease-in', style({ transform: 'translateX(-100%)', 'opacity': 0 }))
      ])
    ]),

    trigger('slideInOut1', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),
      
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('300ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ]) 
    ])
  ]


})
export class CreateAnalyticComponent implements OnInit {

  createAnalyticForm: FormGroup;
  entityGroup: Map<String, any[]> = new Map<String, any[]>();
  entityIdMap: Map<String, any> = new Map<String, any>();
  now: number = Date.now();
  isDataReady = false;
  analytic = new Analytic();
  loadAnalytic = false;
  errorMessage = undefined;
  showBreadcrumb = false;
  progressState = new ProjectStatus();
  savingAnalytic = false;
  analyticType: any = [];
  selectedAnalyticType: {};
  analyticTypeImages: any = [];
  analyticsNNNType: any = [];
  typeImages: string;

  show: boolean = true;
  show1: boolean = true;
  option: string = 'none';

  toggle(evt, opc): void {
    this.show1= true;
    // this.show = !this.show;  
    this.option = opc; 
  }

  toggle1(evt): void {
    // this.show1 = !this.show1;
    this.show1 = false;
  }

  constructor(public zettaUtils: ZmZettaUtils,
    private formBuilder: FormBuilder,
    public service: AnalyticService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient) {
  }

  ngOnInit() {
    this.analytic.analyticid = this.activatedRoute.snapshot.queryParamMap.get('id');
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');

    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    if (this.analytic.analyticid !== undefined && this.analytic.analyticid !== null) {
      this.loadAnalytic = true;
      this.getAnalytic();
    } else {
      this.getEntityGroup();
    }

    this.progressState.states = this.zettaUtils.getStateList('create-analytic');
    this.progressState.currentStateIndex = 0;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Analytics';
    
  }

  setAnalyticForm() {
    this.createAnalyticForm = this.formBuilder.group({
      // typeModel: [this.analytic.algoid, Validators.required]
      name: [this.analytic.name, [Validators.required]],
      description: [this.analytic.description],
      typeModel: [this.analytic.algoid]
    });
  }

  getAnalytic() { 
    this.service.getAnalytic(this.service.getObjectFromSession('userInfo').userid, this.analytic.analyticid).subscribe(responseList => {
      // this.analytic = responseList['currentpage'][this.analytic.analyticid];
      this.analytic = responseList;
      console.log("id: " + this.analytic.name)
      this.setAnalyticForm();
      this.getEntityGroup();
    }, err => {
    });

    // this.service.getCurrentActiveJobs(this.service.getObjectFromSession('userInfo').userid, this.analytic.analyticid).subscribe(jobs => {
    //     if (this.service.hasActiveJob(jobs)) {
    //       alert(AppGlobals.ACTIVE_JOB_ERROR);
    //       this.router.navigate(['/zs/projects', this.analytic.analyticid]);
    //     }
    //   });
  }

  getEntityGroup() {
    //Get images
    // this.http.get('./assets/entities-analytic.json').subscribe(responseList => { 
    //   this.analyticTypeImages = responseList['currentpage'];
    // });

      this.service.getAnalyticsType().subscribe(responseList => { responseList.forEach(element => {
          // this.entityIdMap.set(element.entityid.toString(), element);
          // if ((element.algoid === this.analytic.algoid) && this.loadAnalytic) {
          if ((element.analytictypeid === this.analytic.analytictypeid) && this.loadAnalytic) {
            // this.createAnalyticForm.controls.entityModel.setValue(element);
            // this.createAnalyticForm.controls.typeModel = element.algoid;
            // solo entra cuando se edita
          }
          
        // console.log('1111: ' + element['name']);
        // if (this.entityGroup.has(element['entitytypename'])) {
        //   this.entityGroup.get(element['entitytypename']).push(element);
        //   console.log('aaa: ' + element['entitytypename']);
        //   console.log('entityGroup: ' + this.entityGroup);
        // } else {
        //   const list: any[] = [];
        //   list.push(element);
        //   if ((element.entityid === this.analytic.analytictypeid) && this.loadAnalytic) {
        //     this.createAnalyticForm.controls.entityModel.setValue(element);
        //     // solo entra cuando se edita
        //   }
        //   this.entityGroup.set(element['entitytypename'], list);
        //   console.log('this.entityGroup list: ' + this.entityGroup);
        // }
      });

      this.analyticType = responseList;

      if (!this.loadAnalytic) {
        this.setAnalyticForm();
      }
      this.isDataReady = true;
    }, err => {
    });
  }

  onSubmit(redirect: boolean) {
    // // Analytic is getting saved..
    // if (this.savingAnalytic) {
    //   return;
    // }
    // if (this.createAnalyticForm.valid) {
    //   this.errorMessage = undefined;
    //   this.savingAnalytic = true;
    //   const analytictemp = new Analytic();
    //   if (this.analytic) {
    //     analytictemp.analyticid = this.analytic.analyticid;
    //   }
    //   analytictemp.name = this.createAnalyticForm.controls.name.value;
    //   analytictemp.description = this.createAnalyticForm.controls.description.value;
    //   analytictemp.algoid = this.createAnalyticForm.controls.typeModel.value;
    //   analytictemp.userid = this.service.getObjectFromSession('userInfo').userid;
    //   analytictemp.groupid = this.service.getObjectFromSession('userInfo').groupid;

    //   if (!this.loadAnalytic) {
    //     // analytictemp.users = [];
    //     // analytictemp.users.push({ userid: analytictemp.userid });
    //     analytictemp.analyticid = undefined;
    //   }

    //   this.service.saveAnalytic(analytictemp).subscribe(responseList => {
    //     if (responseList.body) {
    //       this.loadAnalytic = true;
    //       this.analytic = responseList.body;
    //     }
    //     this.errorMessage = '';
    //     this.savingAnalytic = false;
    //     $('#projectState0').removeClass('d-none');
    //     setTimeout(function () {
    //       $('#projectState0').addClass('d-none');
    //     }, 5000);

    //     if (redirect) {
    //       this.router.navigate(['/zm/analytics', this.analytic.analyticid, 'users']);
    //     } else {
    //       this.router.navigate(['/zm/analytics', this.analytic.analyticid, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
    //     }
    //   }, err => {
    //     this.errorMessage = err.error.message;
    //     this.savingAnalytic = false;
    //   });
    // }

    if (redirect) {
      // this.router.navigate(['/zm/analytics', this.analytic.analyticid, 'users']);
      this.router.navigate(['/zm/analytics/1/users']);
    } else {
      // this.router.navigate(['/zm/analytics', this.analytic.analyticid, 'users'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
      this.router.navigate(['/zm/analytics/1/users'], { queryParams: { showBreadcrumb: this.showBreadcrumb } });
    }

  }

  // onItemChange(event) {
  //   const entity = this.entityIdMap.get(event.target.value);
  //   this.createAnalyticForm.controls.entityModel.setValue(entity);
  // }

  isRadioBtnChecked(inputSource: any) {
    if (inputSource) {
      if(inputSource.algoid == 11) { this.typeImages = "icon-analytics-correlation.png" }
      if(inputSource.algoid == 12) { this.typeImages = "icon-analytics-classification.png" }
      if(inputSource.algoid == 13) { this.typeImages = "icon-analytics-clustering.png" }
      if(inputSource.algoid == 14) { this.typeImages = "icon-analytics-simulation.png" }
      this.selectedAnalyticType = inputSource;
    }
  }


  // isRadioBtnChecked(inputType: any) {
  //   const selectedType = this.createAnalyticForm.controls['typeModel'].value;
  //   if (selectedType !== undefined && selectedType !== null && selectedType.algoid === inputType.algoid) {
  //     return true;
  //   }
  //   return false;
  // }

  getRadioBtnRowClass(inputEntity: any, index: number, total: number) {
    const selectedEntity = this.createAnalyticForm.controls['typeModel'].value;
    if (selectedEntity !== undefined && selectedEntity !== null && selectedEntity.entityid === inputEntity.entityid) {
      if (index === 0 && index === total - 1) {
        return 'check-box-selected first-groupRow-radius last-groupRow-radius border-top border-right border-left border-bottom';
      } else if (index === 0) {
        return 'check-box-selected first-groupRow-radius border-top border-right border-left';
      } else if (index === total - 1) {
        return 'check-box-selected last-groupRow-radius border-right border-left border-bottom';
      } else {
        return 'check-box-selected border-right border-left';
      }
    }

    if (index === 0 && index === total - 1) {
      return 'border-left first-groupRow-radius last-groupRow-radius   border-top border-bottom border-right';
    } else if (index === 0) {
      return 'border-left first-groupRow-radius   border-top border-right';
    } else if (index === total - 1) {
      return 'border-left last-groupRow-radius border-bottom border-right';
    } else {
      return 'border-left border-right';
    }
  }

  onCancel() {
    // this.onSubmit(false);
    this.router.navigate(['/zm/analytics']);
  }

  onNext() {
    this.onSubmit(false);
    // this.router.navigate(['/zm/analytics/1/users']);
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
  }

}
