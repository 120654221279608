import { AppGlobals } from '../../common/shared/app.globals';
import { addRemoveFormattter } from '../../common/shared/formatters/addRemoveFormatter';
import { UserGroupService } from '../usergroup/usergroup.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Group, User } from '../../common/shared/zetta-models';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { PatternValidator } from '../../common/shared/formValidators/patternValidator';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { MessageService } from '../../common/components/message/message.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
const zettaUtils = new ZettaUtils();
declare var $: any;
const customDropDownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
    dataContext.action !== 'delete'
        ? `<div class='row'>
                        <div class='col-md-10 text-truncate pr-0'>
                            <label id='user-${columnDef.field}' placeholder='Select Group' "title='${dataContext.group_name}'>${dataContext.group_name}</label>
                        </div>
                        <div class='col-md-2 p-0'> 
                            <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                        </div>
                   </div>`
        : `<div class='row'>
                        <div class='col-md-10 text-truncate pr-0'>
                            <label placeholder='Select Group' title='${dataContext.group_name}'>${dataContext.group_name}</label>
                        </div>
                        <div class='col-md-2 p-0'> 
                            <span><i class="fa fa-caret-down ctmCaretDown"></i></span>
                        </div>
                   </div>`;
const userNameFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return dataContext.action !== 'delete'
        ? `<input id='user-${columnDef.field}' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' tabindex='${columnDef.params.tabindex}' 
       autofocus="autofocus" value='${value}'></input>` : `<div class="row">
    <div class="col-md-3">
    <img class="" src="../../../../assets/images/common/icon_azureActiveDirectory.png" height="16px" width="16px">
    </div>
    <div class="col-md-9 p-0 m-0 text-truncate"><div class='text-truncate'><span class='text-truncate' title='${value}'> ${value}</span></div></div>
    </div>`
};
const customUserRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return dataContext.action !== 'delete'
        ? `<input id='user-${columnDef.field}' class='ctmInputBox' type='text' placeholder='${columnDef.params.placeholder}' tabindex='${columnDef.params.tabindex}' 
                   autofocus="autofocus" value='${value}'></input>` : `<div class='text-truncate'><span class='text-truncate' title='${value}'> ${value}</span></div>`;
};
const customGroupRowFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    return `<div class='text-truncate'><span class='text-truncate' title='${value}'> ${value}</span></div>`;
};
const lockUnlockFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (value === 'none')
        return ''
    return value ? `<div class='text-center'> <i title='Unlock account' class="fas fa-lock actionInlineBtn lock-user"
        ></i></div>` : `<div class='text-center'><i title='Account is Unlocked' class="fa fa-unlock actionInlineBtn grey-color cursor-default-imp"></i></div>`;
};
export const removeFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (dataContext.user_id > 0) {
        return `<i class="fa fa-minus actionInlineBtn
        ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'remove_user') ? 'disabled-state' : ''}"
        data-toggle="modal" data-backdrop="false" data-target=#${columnDef.field}></i>`;
    } else {
        return `<i class="fa fa-minus actionInlineBtn disabled-state
         ${!zettaUtils.getUserGroupEntitlements('users_and_groups', 'users', 'remove_user') ? 'disabled-state' : ''}"
         data-toggle="modal" data-backdrop="false" data-target=#${columnDef.field}></i>`;
    }

};
export const checkBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    if (dataContext.app_user == true) {
        return `<label id='unchk-${row}' class="mr-left-30 mr-top-7 check-cell d-none">
        <input type="checkbox" id='ttunchk-${row}' class="check"> 
       </label> 
       <label id='chk-${row}' class="mr-left-30 mr-top-7 check-cell">
       <input type="checkbox" id='ttchk-${row}' class="check delete" checked> 
       </label>`
    } else {
        return `<label id='unchk-${row}' class="mr-left-30 mr-top-7 check-cell">
        <input type="checkbox" id='ttunchk-${row}' class="check"> 
       </label> 
       <label  id='chk-${row}'class="mr-left-30 mr-top-7 check-cell d-none">
        <input type="checkbox" id='ttchk-${row}' class="check delete" checked> 
       </label>`
    }

}
@Component({
    selector: 'zetta-usergroups',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsergroupsComponent implements OnInit, OnDestroy {

    passwordChangeForm: FormGroup;
    userDataSource: any = [];
    groupDataSource: any = [];
    addedUser = new User();
    addedGroup = new Group();
    usertableRows: any = [];
    grouptableRows: any = [];
    isUserResponseReady = false;
    isGroupResponseReady = false;
    userTableColDef: any;
    groupTableColDef: any;
    gridOptions: any;
    grouplist: any;
    grouplistDropDown: any;
    // used for change password model window
    selectedUserMetadata: any;
    selectedGroupMetadata: any;
    eventData: any;
    leftGridAction = false;
    tableSettings: object = { 'height': "100%", 'width': "100%" };
    usertableSettings: object = { 'height': "100%", 'width': "100%" };
    userGridRef: AngularGridInstance;
    groupGridRef: AngularGridInstance;
    addUser: User = new User();
    addGroup: Group = new Group();
    totalUsers = 0;
    pageLeft = 1;
    limitLeft = 100;
    totalGroups = 0;
    pageRight = 1;
    limitRight = 100;
    hasScrolled = false;
    noData = false;
    hasScrolledRight = false;
    noDataRight = false;
    isLoading = false;
    loggedInUserDetails: [];
    selectedFiles: string[] = [];
    inputFocus: string = "#user-user_name";
    public userLoggedInFromAzure: boolean;
    usersForm: FormGroup;
    groupsForm: FormGroup;
    group_name;
    user_exist = false;
    group_id:number
    submitted = false;
    msGroups:object;
    msUsers:object;
    fu_type_users: any;
    clearModal = Math.random();
    dataRes;

    constructor(private userGroupService: UserGroupService,
        private formBuilder: FormBuilder,
        public zettaUtils: ZettaUtils,
        private messageSvc: MessageService, private authService: MsalService, private authSvc: AuthenticationService) { }

    ngOnInit() {
        this.fu_type_users = AppGlobals.FU_TYPE_USERS_SUPERUSERS;
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
        this.userLoggedInFromAzure = !!this.authService.getAccount();
        this.gridOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: true,
            autoEdit: true,
            enableFiltering: true,
            CheckboxSelector: false,
        };
        this.passwordChangeForm = this.formBuilder.group({
            old_password: ['', [Validators.minLength(8)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirm_password: ['', Validators.required]
        }, {
            validator: [PatternValidator('password', AppGlobals.NUMERIC_REGEX, { hasNumber: true }, 'confirm_password'), PatternValidator('password', AppGlobals.CHARACTERS_REGEX, { hasNumber: true }, 'confirm_password')]
        });
        this.initGroupGrid();
        this.initUserGrid();
        this.setGroupForm();
        this.setUserForm();
        if (this.userLoggedInFromAzure) {            
            this.getMSGroupDetails('', this.limitLeft);
        } else {
            this.getUserAndGroupList();
        }
    }
    // set up col def
    initUserGrid() {
        this.userTableColDef = [{
            'displayname': 'User Name',
            'physicalname': 'displayName',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': this.userLoggedInFromAzure ? userNameFormatter : customUserRowFormatter,
            'minWidth': 200,
            'maxWidth': 400
        }, {
            'displayname': 'Account Lock Status ',
            'physicalname': 'is_locked',
            'sortable': true,
            'datatype': 'String',
            'filterable': false,
            'formatter': lockUnlockFormattter,
            'cssClass': 'text-center',
            'headerCssClass': 'text-start lock-status',
            'minWidth': 20,
            'maxWidth': 80
        }, {
            'displayname': 'First Name',
            'physicalname': 'givenName',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customUserRowFormatter,
            'minWidth': 120,
            'maxWidth': 300
        }, {
            'displayname': 'Last Name',
            'physicalname': 'surname',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customUserRowFormatter,
            'minWidth': 120,
            'maxWidth': 200
        }, {
            'displayname': 'E-mail',
            'physicalname': 'mail',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customUserRowFormatter,
            'minWidth': 120,
            'maxWidth': 300
        }, {
            'displayname': 'App User',
            'physicalname': 'app_user',
            'sortable': true,
            'datatype': 'String',
            'filterable': false,
            'formatter': checkBoxFormattter,
            'minWidth': 120,
            'maxWidth': 200
        }, {
            'displayname': ' ',
            'physicalname': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': this.userLoggedInFromAzure ? removeFormattter : addRemoveFormattter,
            'cssClass': 'text-right',
            'headerCssClass': 'text-center',
            'minWidth': 20,
            'maxWidth': 50
        }];
        this.isUserResponseReady = true;
    }

    setUserForm() {
        this.usersForm = this.formBuilder.group({
            user_name: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', Validators.required],
            password: [],
            role: []
        })
    }
    get f() { return this.usersForm.controls; }
    setGroupForm() {
        this.groupsForm = this.formBuilder.group({
            group_name: [],
            description: []
        });
    }
    initGroupGrid() {
        this.groupTableColDef = [{
            'displayname': 'Group Name',
            'physicalname': 'displayName',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customGroupRowFormatter,
            'minWidth': 120,
            'params': { 'placeholder': 'Add group' },
        }, {
            'displayname': 'Description',
            'physicalname': 'description',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': customGroupRowFormatter,
            'minWidth': 240,
            'params': { 'placeholder': 'Add description' },
        }, {
            'displayname': ' ',
            'physicalname': 'action',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': this.userLoggedInFromAzure ? removeFormattter : addRemoveFormattter
        }];
        //this.isGroupResponseReady = true;
    }

    // Get user Group
    getMSGroupDetails(nextPageUrl = '', pageSize) {
        this.tableSettings['search_url'] = `https://graph.microsoft.com/v1.0/groups`;
        const self = this;
        let userGroups = [];
        this.userLoggedInFromAzure = !!this.authService.getAccount();
        if (this.userLoggedInFromAzure) {
            const msGroup = nextPageUrl ? self.authSvc.getPagedMSGroup(nextPageUrl) : self.authSvc.getMSGroups(pageSize);
            msGroup.toPromise().then(resp => {
                this.msGroups = resp;          
                resp["value"].forEach(group => {                    
                    userGroups.push({
                        'displayName': group.displayName,
                        'description': '',
                        'group_id': group.id,
                        'action': ''
                    });
                });
                if (userGroups.length === 0) {
                    this.noDataRight = true;
                }
                let concatedLeftRows = userGroups;
                if (this.groupGridRef) {
                    concatedLeftRows = [...this.groupGridRef.dataView.getItems(), ...userGroups];
                    this.grouptableRows = concatedLeftRows;
                    this.grouptableRows = userGroups.map((item) => {
                        item['action'] = 'delete';
                        item['id'] = Math.random();
                        return item;
                    });
                                   
                    this.groupGridRef.dataView.setItems(concatedLeftRows);
                    this.groupGridRef.gridService.setSelectedRows([]);
                    
                }
                this.isGroupResponseReady = true;
                this.hasScrolled = false;
                this.totalGroups = concatedLeftRows.length;
                this.grouptableRows = concatedLeftRows;
            });
        }
    }

    getMSGroupUsers(nextPageUrl, group_id, pageSize ) {
        this.usertableSettings['search_url'] = `https://graph.microsoft.com/v1.0/groups/${group_id}/members`;
        const self = this;
        let usersList = []
        let graphUsers = [];
        this.userLoggedInFromAzure = !!this.authService.getAccount();        
        if (this.userLoggedInFromAzure) {
            const msUser = nextPageUrl ? self.authSvc.getPagedMSGroup(nextPageUrl) : self.authSvc.getGroupMembers(group_id, pageSize);
            msUser.toPromise().then(members => {    
                this.msUsers = members;          
                members["value"].forEach(member => {                    
                    graphUsers.push({
                        'givenName': member.givenName,
                        'surName': member.surname,
                        'email': member.mail
                    });
                    usersList.push({
                        'displayName': member.displayName,
                        'givenName': member.givenName,
                        'surname': member.surname,
                        'mail': member.mail,
                        'app_user': false,
                        'user_id': member.id
                    });
                });
                let payload = {
                    'group_name': this.group_name,
                    'tenant_id': this.loggedInUserDetails['tenant_id'],
                    'graph_users': graphUsers
                }
                let systemUsers = usersList;
                this.authSvc.validateUser(this.loggedInUserDetails['user_id'], payload).subscribe(resp => {                    
                    if (resp.length) {
                        const respList = resp.filter(user => user.user_id > 0);
                        respList.forEach(user => {
                            systemUsers = usersList.map((item) => {
                                if (user['email'].toUpperCase() === item['mail'].toUpperCase()) {
                                    item['app_user'] = true;
                                    item['user_id'] = user.user_id;
                                }
                            });
                        });
                    }
                    this.bindData(usersList)
                }, error => {
                    this.bindData(usersList);                    
                });
                if (usersList.length === 0) {
                    this.noData = true;
                }
            }).catch((error) => {                
            });
        }
    }

    bindData(usersList) {
        let concatedRightRows=[];
            concatedRightRows = usersList;
        if (this.userGridRef) {
            concatedRightRows = [...this.userGridRef.dataView.getItems(), ...usersList];
                this.usertableRows = concatedRightRows;
                    
            this.usertableRows = usersList.map((item) => {
                item['password'] = 'Change Password';
                item['action'] = 'delete';
                item['id']= Math.random();
                return item;
            });
        }
        
        this.hasScrolledRight = false;
        this.isUserResponseReady = true;
        this.usertableRows = concatedRightRows;
        this.userGridRef.dataView.setItems(concatedRightRows);
        this.userGridRef.gridService.setSelectedRows([]);
        const total =[...this.userGridRef.dataView.getItems()].length;
        this.totalUsers = total ? total: this.usertableRows.length;
    }
    
    
    async getUserAndGroupList() {
        let groupService = await this.userGroupService.getPagedGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], 1, this.limitRight);
        this.grouplistDropDown = groupService['currentpage'].map((item) => ({ 'value': item.group_id, 'text': item.name }));
        this.userGroupService.getUserAndGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.pageLeft, this.limitLeft).subscribe(responseList => {
            this.userDataSource = responseList[0];
            this.grouplist = responseList[1];
            this.totalGroups = responseList[1]['total_records'];
            this.totalUsers = responseList[0]['totalrecords'];
            if (this.grouplist['currentpage']) {
                this.grouptableRows = this.grouplist['currentpage'].map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }
            if (this.userDataSource['currentpage']) {
                this.usertableRows = this.userDataSource['currentpage'].map((item) => {
                    item['password'] = 'Change Password';
                    item['action'] = 'delete';
                    item['app_user'] = false;
                    item['id'] = Math.random();
                    return item;
                });
            }
            this.isGroupResponseReady = true;
        }, err => {
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }
    getPaggedUser(page, limit) {
        this.readUserDetails();
        this.userGroupService.getPagedUsers(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], page, limit).subscribe(resp => {
            this.usertableRows = resp;
            if (this.usertableRows['currentpage'].length === 0) {
                this.noData = true;
            }
            let concatedLeftRows = this.usertableRows['currentpage'];
            if (this.userGridRef) {
                concatedLeftRows = [...this.userGridRef.dataView.getItems(), ...this.usertableRows['currentpage']];
                this.usertableRows = resp['currentpage'].map((item) => {
                    item['password'] = 'Change Password';
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }
            this.isUserResponseReady = true;
            this.usertableRows['currentpage'] = concatedLeftRows;
            this.userGridRef.dataView.setItems(concatedLeftRows);
            this.userGridRef.gridService.setSelectedRows([]);
            this.hasScrolled = false;
        }, err => {
            this.isUserResponseReady = true;
            this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
        });
    }
    async getPaggedGroups(page, limit) {
        this.readGroupDetails();
        try {
            let resp = this.userGroupService.getPagedGroups(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], page, limit)
            this.grouptableRows = resp;
            if (this.grouptableRows['currentpage'].length === 0) {
                this.noDataRight = true;
            }
            let concatedLeftRows = this.grouptableRows['currentpage'];
            if (this.groupGridRef) {
                concatedLeftRows = [...this.groupGridRef.dataView.getItems(), ...this.grouptableRows['currentpage']];
                this.grouptableRows = resp['currentpage'].map((item) => {
                    item['action'] = 'delete';
                    item['id'] = Math.random();
                    return item;
                });
            }
            this.isGroupResponseReady = true;
            this.grouptableRows['currentpage'] = concatedLeftRows;
            this.groupGridRef.dataView.setItems(concatedLeftRows);
            this.groupGridRef.gridService.setSelectedRows([]);
            this.hasScrolledRight = false;
        } catch (error) {
            this.isGroupResponseReady = true;
            this.messageSvc.sendMessage({ message: error.error.message, type: 'INFO', hideboard: true });
        }
    }
    readUserDetails() {
        this.addUser.user_name = $('#user-user_name')[0]['value'];
        this.addUser.first_name = $('#user-first_name')[0]['value'];
        this.addUser.last_name = $('#user-last_name')[0]['value'];
        this.addUser.email = $('#user-email')[0]['value'];
    }
    readGroupDetails() {
        this.addGroup.name = $('#group-name')[0]['value'];
        this.addGroup.description = $('#group-description')[0]['value'];
        this.addGroup.tenant_id = this.loggedInUserDetails['tenant_id'];
    }
    handleGroupGridClickEvent(eventData) {
        this.eventData = eventData;
        const args = eventData.args;
        const event = eventData.eventData;
        const metadata = this.groupGridRef.gridService.getColumnFromEventArguments(args);
        this.selectedGroupMetadata = metadata;
        this.leftGridAction = false;
        const row = this.groupGridRef.gridService.getDataItemByRowIndex(args.row);
        this.group_name = row.displayName;
        this.isUserResponseReady = false;
        this.group_id=row.group_id;
        this.userGridRef.dataView.setItems([]);
        this.userGridRef.gridService.setSelectedRows([]);
        this.getMSGroupUsers('', row.group_id, this.limitRight);
    }
    handleUserGridClickEvent(eventData) {
        this.eventData = eventData;
        const args = eventData.args;
        const event = eventData.eventData;
        const metadata = this.userGridRef.gridService.getColumnFromEventArguments(args);
        this.selectedUserMetadata = metadata;
        const row = this.userGridRef.gridService.getDataItemByRowIndex(args.row);        
        if (row !== undefined && row !== null) {
            if (event.target.className == 'check') {
                if (row.app_user) {
                    row.app_user = !row.app_user;
                    $("#chk-" + args.row).addClass('d-none');
                    $("#unchk-" + args.row).removeClass('d-none');
                } else {
                    row.app_user = !row.app_user;
                    $("#chk-" + args.row).removeClass('d-none');
                    $("#unchk-" + args.row).addClass('d-none');
                }
                const payload = {
                    "user_name": row.mail,
                    "password": "password",
                    "first_name": row.givenName,
                    "email": row.mail,
                    "group_name": this.group_name,
                    "tenant_id": this.loggedInUserDetails['tenant_id'],
                    "is_super_admin": false,
                    "is_tenant_admin": false,
                    "is_internal_user": false,
                    // "sso_provider": "azure",
                    "app_id": this.loggedInUserDetails['preference'].default_app_id
                }               
                this.createUser(payload);
            }
            if (event.target.className.includes('delete')) {
                if (row.app_user) {
                    row.app_user = !row.app_user;
                    $("#chk-" + args.row).addClass('d-none');
                    $("#unchk-" + args.row).removeClass('d-none');
                } else {
                    row.app_user = !row.app_user;
                    $("#chk-" + args.row).removeClass('d-none');
                    $("#unchk-" + args.row).addClass('d-none');
                }
                this.userGroupService.deleteUsers(row.user_id).subscribe(resp=>{
                },error=>{
                    this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
                })
            }
        }

    }
    createUser(payload) {
        this.userGroupService.createUser(payload).subscribe(resp => {
            $('#addUserModal').modal('hide');
            this.messageSvc.sendMessage({ message: 'User has been added successfully!', type: 'SUCCESS', hideboard: true });
        }, error => {
            if (error.status == 400) {
                this.user_exist = true;
            } else {
                $('#addUserModal').modal('hide');
            }
            if (this.userLoggedInFromAzure) {
                this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            }
        });
    }
    getAddUserRow() {
        return { 'id': Math.random(), 'user_id': '-1', 'user_name': '', 'first_name': '', 'last_name': '', 'email': '', 'group_id': '1', 'group_name': 'Default Group', 'password': '', 'action': 'add', 'is_locked': 'none' };
    }

    getAddGroupRow() {
        return { 'id': Math.random(), 'group_id': '-1', 'name': '', 'description': '', 'action': 'add' };
    }
    onSubmit() {
        // stop here if form is invalid
        if (this.passwordChangeForm.valid) {
            const row = this.selectedUserMetadata.dataContext;
            if (row.user_id === '-1') {
                this.addUser.password = this.passwordChangeForm.controls.password.value;
                this.passwordChangeForm.reset();
                $('#btnClosePwdModel').click();
            } else {
                const user = new User();
                user.user_id = row.user_id;
                user.user_name = row.user_name;
                user.old_password = this.passwordChangeForm.controls.old_password.value;
                user.password = this.passwordChangeForm.controls.password.value;
                user.confirm_password = this.passwordChangeForm.controls.confirm_password.value;
                this.userGroupService.changePassword(user).subscribe(responseList => {
                    this.passwordChangeForm.reset();
                    $('#btnClosePwdModel').click();
                }, err => {

                });
            }
        } else {
            return;
        }
    }
    // Delete User/Group Rows on Delete Action Confirmation
    deleteSelectedRow() {
        if (this.leftGridAction) {
            this.userGroupService.deleteUsers(this.selectedUserMetadata.dataContext.user_id).subscribe(responseList => {
                this.userGridRef.gridService.deleteDataGridItemById(this.selectedUserMetadata.dataContext.id);
                this.totalGroups = this.totalGroups - 1;
                $('#btnCloseActionModel').click();
            }, err => {
            });
        } else {
            this.userGroupService.deleteGroup(this.selectedGroupMetadata.dataContext.group_id,
                this.loggedInUserDetails['user_id']).subscribe(responseList => {
                    this.totalUsers = this.totalUsers - 1;
                    this.grouplist['currentpage'] = this.grouplist['currentpage'].filter(group => group.group_id === this.selectedGroupMetadata.dataContext.group_id);
                    this.groupGridRef.gridService.deleteDataGridItemById(this.selectedGroupMetadata.dataContext.id);
                    $('#btnCloseActionModel').click();
                }, err => {
                });
        }
    }
    groupGrid(grid: AngularGridInstance) {
        this.groupGridRef = grid;
    }
    userGrid(grid: AngularGridInstance) {
        this.userGridRef = grid;
    }
    ngOnDestroy() {
        this.messageSvc.clearMessage();
    }
    goToPageLeft(n: number): void {
        this.pageLeft = n;
        this.getPaggedUser(this.pageLeft, this.limitLeft);
    }

    onNextLeft(): void {
        this.pageLeft++;
        if(this.msGroups){
            let nextPageUrl=this.msGroups["@odata.nextLink"];
            if(nextPageUrl){
                this.hasScrolled = true;
                this.getMSGroupDetails(nextPageUrl,this.limitLeft);
            }
        }
    }

    onPrevLeft(): void {
        this.pageLeft--;
        this.getPaggedUser(this.pageLeft, this.limitLeft);
    }

    goToPageRight(n: number): void {
        this.pageRight = n;
        this.getPaggedGroups(this.pageRight, this.limitRight);
    }

    onNextRight(): void {
        this.pageRight++;
        if(this.msUsers){
            let nextPageUrl=this.msUsers["@odata.nextLink"];
            if(nextPageUrl){
                this.hasScrolledRight = true;
               this.getMSGroupUsers(nextPageUrl,this.group_id,this.limitRight);
            }
        }
    }

    onPrevRight(): void {
        this.pageRight--;
        this.getPaggedGroups(this.pageRight, this.limitRight);
    }

    totalRightPages(): number {
        return this.zettaUtils.getTotalPages(this.totalUsers, this.limitRight);
    }

    totalLeftPages(): number {
        return this.zettaUtils.getTotalPages(this.totalGroups, this.limitLeft);
    }

    leftGridCount() {
        if (this.userGridRef) {
            // Remove Add Row Count
            const hasFilter = this.userGridRef.dataView.getItems().length - this.usertableRows.length;
            if (hasFilter < 0) {
                return this.userGridRef.dataView.getItems().length;
            } else {
                return this.userGridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.usertableRows.length - 1;
        }
    }

    rightGridCount() {
        if (this.groupGridRef) {
            // Remove Add Row Count
            const hasFilter = this.groupGridRef.dataView.getItems().length - this.grouptableRows.length;
            if (hasFilter < 0) {
                return this.groupGridRef.dataView.getItems().length;
            } else {
                return this.groupGridRef.dataView.getItems().length - 1;
            }
        } else {
            return this.grouptableRows.length - 1;
        }
    }

    onLeftGridScroll(parentClass) {
        // this.tabFocus();
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.onNextLeft();
        }
    }

    onRightGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolledRight && !this.noDataRight) { 
            this.onNextRight();
        }
    }

    removeFile(index) {
        this.selectedFiles.splice(index, 1);
    }

    getModelPosition(position: string, isPasswordModel?: boolean) {
        let styleValue = '';
        if (position === 'top') {
            styleValue = (this.eventData.eventData.pageY - 50) + 'px';
        } else if (position === 'left') {
            if (this.leftGridAction) {
                styleValue = 37 + 'vw';
            } else {
                styleValue = -25 + 'vw';
            }
        }
        return styleValue;
    }

    onSaveUser() {
        this.submitted = true;
        if (this.usersForm.invalid) {
            return;
        }
        const app_ids = this.loggedInUserDetails['apps'].map(app => app.app_id);
        const userPayload = {
            "user_name": this.usersForm.controls.email.value,
            "password": "password",
            "first_name": this.usersForm.controls.first_name.value,
            "email": this.usersForm.controls.email.value,
            "group_name": this.group_name,
            "is_super_admin": false,
            "is_tenant_admin": false,
            "is_internal_user": false,
            "app_id": app_ids
        }
        this.createUser(userPayload);
    }
    onSaveGroup() {
        this.addGroup.name = this.groupsForm.controls.group_name.value,
            this.addGroup.description = this.groupsForm.controls.description.value
        this.addGroup.tenant_id = this.loggedInUserDetails['tenant_id'];
        this.userGroupService.saveGroup(this.addGroup, this.loggedInUserDetails['user_id']).subscribe(responseList => {            
            if (responseList) {
                this.messageSvc.sendMessage({ message: 'Group has been added sucessfully!', type: 'SUCCESS', hideboard: true });
                $('#addGroupModal').modal('hide');
            }
        }, error => {
            this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
            $('#addGroupModal').modal('hide');
        });

    }

    receivedFileName($event) {
        this.dataRes = $event;
      }

    openModalImport(modal) {
        $('#' + modal).modal('show');
    }
}
