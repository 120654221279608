import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../content/content.service';
import { actionsFormatter } from '../../../../common/shared/formatters/actionsFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { MessageService } from '../../../../common/components/message/message.service';
import { entityListNameFormatter } from '../../../../common/shared/formatters/entityListNameFormatter';
import { entityListTypeFormatter } from '../../../../common/shared/formatters/entityListTypeFormatter';
import { entityListNumericFormatter } from '../../../../common/shared/formatters/entityListNumericFormatter';
import { entityActionsFormatter } from '../../../../common/shared/formatters/entityActionsFormatter';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { environment } from 'src/environments/environment';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { AppGlobals } from '../../../../common/shared/app.globals';

const entityTitleFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
return `<div class="w-100 text-center pr-1" title="${value}">${value}</div>`
}
@Component({
  selector: 'zetta-entity-home',
  templateUrl: './entity-home.component.html',
  styleUrls: ['./entity-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityHomeComponent implements OnInit, OnDestroy {

  public entities: object = {};
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  entityHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;

  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  grandTotal = 0;
  subTotal = '';
  noData = false;
  isEditMode = false;
  public login_user = JSON.parse(sessionStorage.userInfo).entitlements;
	is_visible_create = false;	
	is_enable_create = false
  selected_catalog_id: number;
  is_catalog_admin = false;
  catalogList = [];
  isCatalogSelected: Boolean = false;
  disableCatalogSelection: boolean = true;


  constructor(
    private contentSvc: ContentService,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService,
    private classifyContentSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute) {

      if (this.login_user) {
        try {
          let user_entitlements = this.login_user[0].entitlement;
          if(this.login_user.length > 1){
            user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
            if(user_entitlements.length){
              user_entitlements = user_entitlements[0].entitlement;
            }else{
              user_entitlements = this.login_user[0].entitlement;
            }
          }
          const entity = user_entitlements.screens.filter(item => item.name == 'Entities')[0].childs.filter(item => item.name == 'create_new_entity')[0];
          if (entity) {
            this.is_visible_create = entity.visible;
            this.is_enable_create = entity.enable;
          }
        } catch (error) {
          console.log(error);
        }
      }

    }

  ngOnInit() {
    this.removeGridSearchedDataFromStorage();
    let requestedCatalogId;
    this.activatedRoute.queryParams.subscribe((params) => {
      requestedCatalogId = params['catalog_id'] ? params['catalog_id'] : null;
    });
    this.getCatalogDetail(requestedCatalogId);
    this.entityHomeTableColDef = [{
      'displayname': '',
      'physicalname': 'actions',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': entityActionsFormatter,
      'maxWidth': 15,
    }, {
      'displayname': 'Id',
      'physicalname': 'entity_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-center',
      'maxWidth': 100,
    }, {
      'displayname': 'Entity Name',
      'physicalname': 'semantic_object',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': entityListNameFormatter,
    }, {
      'displayname': 'Entity Type',
      'physicalname': 'entity_type',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': entityListTypeFormatter,
      'maxWidth': 120,
    }, {
      'displayname': 'Projects Using Entity Type',
      'physicalname': 'projects',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-right',
      'formatter': entityTitleFormatter,
      'minWidth': 190,
      'maxWidth': 190,
    }, {
      'displayname': 'Date Created',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': dateFormatter,
      'maxWidth': 120,
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true,
      rowHeight: 50,
    };
  }

  removeGridSearchedDataFromStorage() {
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getEntityHomeList(pageno, limit) {  
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.selected_catalog_id}/semantics?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverfilter="";
    if(sessionStorage.getItem("serverfilter")){
      serverfilter=sessionStorage.getItem("serverfilter");
    }
    let sortfilter="";
    if(sessionStorage.getItem("sortfilter")){
       sortfilter=sessionStorage.getItem("sortfilter");
    }
    let self = this;
    this.classifyContentSvc.getSemanticsObjectsPaged(this.selected_catalog_id, pageno, limit, serverfilter, sortfilter).subscribe((data) => {
      self.entities = data;
      
      self.isLoading = false;
      if (self.entities['currentpage'].length === 0) {
        self.noData = true;
      }
      self.entities['currentpage'].forEach(element => {
        element.id = Math.random();
        element.projects = +element.projects;
        element.entity_admin = this.is_catalog_admin;
      });
      
      self.isLoading = false;
      self.total = data['totalrecords'];
      if(pageno === 1){
        self.grandTotal = data['totalrecords'];
      }
      let concatedRows = self.entities['currentpage'];
      if (self.grid) {
        concatedRows = [...self.grid.dataView.getItems(), ...self.entities['currentpage']];
        self.entities['currentpage'] = concatedRows;
        self.grid.dataView.setItems(concatedRows);
        self.grid.gridService.setSelectedRows([]);
        self.hasScrolled = false;
      }
      self.enableCatalogSelectionDropdown(false);
    }, err => {
      self.enableCatalogSelectionDropdown(false);
      self.messageSvc.sendMessage({ message: 'Get List of Entities failed', type: 'INFO', hideboard: true });
      self.isLoading = false;
    });
  }

  onGridCreation(grid) {
    this.grid = grid;
  }

  onNext(): void {
    this.page++;
    this.getEntityHomeList(this.page, this.limit);
  }

  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    if (row !== undefined && row !== null) {
      if (eventEle.target.title === row.name) {
        this._router.navigate(['/zs/entities/' + row.entity_id + '/entity-model-view'], {queryParams: {catalog_id:this.selected_catalog_id, total_rules:row.total_rules}});
      }
    }
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);

    if(row.entity_admin){ 
      if (row.isdisable) {
        $('#context_menu').hide();
      } else {
        $('#context_menu')
          .removeClass('d-none')
          .data('row', args.row)
          .css('top', eventEle.pageY)
          .css('left', eventEle.pageX + 5)
          .css('position', 'fixed')
          .css('display', 'block')
          .show();
      }

      $('body').one('click', function () {
        $('#context_menu').hide();
      });

      $('#context_menu').click(function (e) {
        if (!($(e.target).is('span') || $(e.target).is('li'))) {
          return;
        }
        if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
          return;
        }
        const action = $(e.target).attr('data');
        if (action === 'Edit') {
          parent._router.navigate(['/zs/entities/create-entity'], { queryParams: { 'id': row.entity_id, catalog_id: parent.selected_catalog_id, showBreadcrumb: false } });

        } else if (action === 'Delete') {
          // parent.deleteRow(row.entity_id, row.id);
        } else if (action === 'Clone') {
          // parent.cloneRow(row);
        }
      });
    }
  }

  // cloneRow(entityObj) {
  //   const parent = this;
  //   const tempEntity = new Entity();
  //   tempEntity.name = 'Copy_of_' + entityObj.name + '_' + Math.floor((Math.random() * 1000) + 1);
  //   tempEntity.entity_id = entityObj.entity_id;
  //   tempEntity.user_id = this.loggedInUserDetails.user_id;
  //   tempEntity.tenant_id = this.loggedInUserDetails.tenant_id;
  //   this.isLoading = true;
  //   parent.contentSvc.cloneEntity(tempEntity).subscribe(response => {
  //     parent._router.navigate(['/zs/entities/create-entity'], { queryParams: { 'id': response.entity_id, showBreadcrumb: false } });
  //   }, err => {
  //     this.isLoading = true;
  //   });
  // }

  // deleteRow(entityId, rowId) {
  //   const parent = this;
  //   this.isLoading = true;
  //   const currentEntity = new Entity();
  //   currentEntity.entity_id = entityId;
  //   currentEntity.is_deleted = true;
  //   currentEntity.user_id = this.loggedInUserDetails.user_id;
  //   parent.contentSvc.saveEntity(currentEntity).subscribe(responseList => {
  //     this.entities['currentpage'] = this.entities['currentpage'].filter(entity => entity.entity_id !== entityId);
  //     this.isLoading = false;
  //   }, err => {
  //     this.isLoading = false;
  //   });
  // }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
  }
 
  getCatalogDetail(requestedToViewCatalogId?) {
    let self = this;
    this.classifyContentSvc.getCatalogs(this.loggedInUserDetails.user_id).subscribe(response => {
      this.catalogList = response;
      const defaultCatalog = this.catalogList.find(catalog => catalog.name === AppGlobals.DEFAULT_SYSTEM_CATALOG);
      this.selected_catalog_id = requestedToViewCatalogId ? requestedToViewCatalogId : defaultCatalog.catalog_id;
        this.checkCatalogRole(this.selected_catalog_id);
    }, (err) => {
      self.enableCatalogSelectionDropdown(false);
    });
  }

  checkCatalogRole(catalog_id) {  
    let self = this; 
    this.classifyContentSvc.getCatalogUser(catalog_id).subscribe(resp => {
      if (resp.length) {
        resp.forEach(user => {
          if (user.user_id == this.loggedInUserDetails.user_id) {
            const userRoles = user.roles.filter(role => role === AppGlobals.CATALOG_ADMIN);
            if (userRoles.length) {
              self.is_catalog_admin = true;
            } else {
              self.is_catalog_admin = false;
            }
          }          
        });
        const selected_catalog = this.catalogList.find(catalog => catalog.catalog_id == catalog_id);
        if (selected_catalog && selected_catalog.name === AppGlobals.DEFAULT_SYSTEM_CATALOG) {
          self.is_catalog_admin = false;
        }        
      }
      self.getEntityHomeList(this.page, this.limit);
    }, (err) => {
      self.enableCatalogSelectionDropdown(false);
      self.isLoading= false;
      self.messageSvc.sendMessage({ message: 'Something went wrong', type: 'ERROR', hideboard: true });
    });
  }
  onChangeCatalog() {
    if (isNaN(this.selected_catalog_id)) {
      this.isCatalogSelected = true;
    } else {
      this.isCatalogSelected = false;
    }
    this.entities = [];
    if(this.selected_catalog_id>0) {
      this.isLoading = true;
      this.enableCatalogSelectionDropdown(true);
    }
    this.is_catalog_admin= false;
    this.subTotal = null;
    this.grandTotal = null;
    this.removeGridSearchedDataFromStorage();
    
    if (this.grid) {
      this.entities['currentpage'] = [];
      this.grid.dataView.setItems([]);
      this.grid.gridService.setSelectedRows([]);
      this.page = 1;
    }
    if(this.selected_catalog_id>0) {
      this.isLoading = true;
      this.checkCatalogRole(this.selected_catalog_id);
    }
  }

  enableCatalogSelectionDropdown(toggleStatus) {
    this.disableCatalogSelection = toggleStatus ? toggleStatus: false;
  }
}

