import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../common/services/base-service';
import { Component, OnInit, NgZone, DoCheck, Compiler, HostListener, OnDestroy } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AppGlobals } from '../../common/shared/app.globals';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { ContentService } from '../../zettasense/content/content.service';
import { MsalService } from '@azure/msal-angular';
import {ObservableService} from  '../../common/services/observable.service';
import { FsDownloadGraphService } from 'src/app/common/components/fs-download-graph/fs-download-graph.service';
declare var $: any;
@Component({
	selector: 'zetta-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnDestroy, OnInit, DoCheck {

	public userinfo;
	public profilePic;
	private _apiUrl: string = environment.config.API_URL;
	endTime = 1;
	minutesDisplay = 0;
	secondsDisplay = 0;
	unsubscribe$: Subject<void> = new Subject();
	timerSubscription: Subscription;
	routeDelay;
	defaultApp;
	defaultAppId;
	selectedApp = 'zs';
	value;
	notificationcount = 0;
	noticationlist = [];
	filterednotifications;
	badgecount: boolean = true;
	isview: boolean = false;
	recallnotf: any;
	page = 1;
	limit = 100;
	shortname: string;
	bgAvatar = '';
	customStyleAvtar = { fontSize:'12px' };
	searchText:string = ''
	private appLocation: String; // Hold app location currently only supports ingest app only. If we are supporting multiple then this var should be an array/list. 
	private userApps: any[];
	isSuperAdmin: Boolean = false;
	isEntityStateChangedForHeuristicBuilder: Boolean = false;
	msgHeader = '';
	msgText = 'Are you sure you want to logout?';
	entityChangeSubscription: Subscription;

	constructor(public app: AppComponent,
		private _router: Router,
		private zone: NgZone,
		private _compiler: Compiler,
		private _baseService: BaseService,
		private http: HttpClient,
		private _authSvc: AuthenticationService,
		public zettaUtils: ZettaUtils,
		public contentSvc: ContentService,
		private authService: MsalService,
		public _obervableService:ObservableService,
		public _fsGraphDownloadService: FsDownloadGraphService) { }

	ngOnInit(): void {		
		this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
		this.isSuperAdmin = this.userinfo.is_super_admin;
		this.defaultAppId = this.userinfo.preference ? this.userinfo.preference.default_app_id : -1;
		this.defaultAppId === -1 ? this.defaultApp = '' : this.userinfo.apps.find( app => parseInt(app.app_id) === parseInt(this.userinfo.preference.default_app_id))
																	? this.defaultApp = this.userinfo.apps.find( app => parseInt(app.app_id) === parseInt(this.userinfo.preference.default_app_id)).short_name
																	: this.defaultApp = '';
		this.value = this.defaultApp === this.selectedApp ? true : false;
		if(this.userinfo && this.userinfo.apps && this.userinfo.apps.length > 0) {
		   const ids = this.userinfo.apps.map(apps => apps.app_id);
		   this.userinfo.apps = this.userinfo.apps.filter(({app_id}, index) => !ids.includes(app_id, index + 1))
		}
		this.getAppLocation();
		// this.value = this.defaultApp;
		if (sessionStorage.getItem("isUserProfile")) {
			this.profilePic = sessionStorage.getItem("profilePicSrc");			
		} else {
			this.getUserPic();
		}
		
		this.resetTimer();
		this._baseService.userActionOccured.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
			}
			this.resetTimer();
		});
		
		this.getNotificationListData();
		this.bgAvatar = $(".avatar-content").css('background-color');
		this._obervableService.getSearchObservable.subscribe(text => {
			this.searchText = text;
		});

		this.msgHeader = AppGlobals.ENTITY_ATTRIBUTE_STATE_CHANGE_WARNING_MSG_SUB;
		this.isEntityStateChangedForHeuristicBuilder = false;

		let self = this;
		this.entityChangeSubscription = this._obervableService.getEntityChangeStateForHeuristicBuilder.subscribe((entityState) => {
			self.isEntityStateChangedForHeuristicBuilder = entityState;
		})
	}

	onLogoutConfirmed() {
		this.hideLogoutAlertPopup();
		this.performLogoutOperations();
	}

	onLogoutCancel() {
		this.hideLogoutAlertPopup();
	}


	showLogoutAlertPopup() {
		$('#logoutalert').removeClass('d-none');
		$('#logoutalert').modal('show');
	}

	hideLogoutAlertPopup() {
		$('#logoutalert').modal('hide');
	}

	getAppLocation(){
		this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
			this.userApps = result;
			this.appLocation = result.filter((app)=>{ return app.app_id == 4; })[0].app_location;
		}, error => {			
		});
	}

	ngDoCheck(){
		this.bgAvatar = $(".avatar-content").css('background-color');
	}

	@HostListener('document:keyup', ['$event'])
	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:click', ['$event'])
	@HostListener('document:wheel', ['$event'])
	
	resetActiveTimer() {
		this._baseService.notifyUserAction();
	}

	public getUserPic() {
		sessionStorage.setItem('isUserProfile', "1");
		this._baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {	
			let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.profilePic = reader.result;
			}, false);
			if (image && image.size && image.type == "image/gif") {
				this.profilePic = reader.readAsDataURL(image);
			} else {
				this.profilePic = null;
			}		
		}, err => {
			this.profilePic = err.url;
		});
	}

	performLogoutOperations() {
		this.timerSubscription.unsubscribe();
		this._compiler.clearCache();

		if(!!this.authService.getAccount() && sessionStorage.getItem('loginProvider') === 'azure_sos'){
			this.authService.logout();
		} else {
			this._authSvc.signOut();
		}
		
		// const route = sessionStorage.getItem('is_zs_login_page') === 'true' ? '/zs/login' : '/zl/login';
		const route = sessionStorage.getItem('is_zs_login_page') === 'true' ? '/zl/login' : '/zl/login';
		sessionStorage.clear();		
		localStorage.clear();
		this._fsGraphDownloadService.resetGraphData();
		this._router.navigate([route]);
	}

	logout() {
		// As of now, Confirmation Dialog is been invoked only from Edit Entity screen.
		if (this.isEntityStateChangedForHeuristicBuilder) {
			// Show Logout confirmation Dialog
			this.showLogoutAlertPopup();
			
		} else {
			this.performLogoutOperations();
		}		
	}

	ngOnDestroy() {
		this.entityChangeSubscription.unsubscribe();
		this.timerSubscription.unsubscribe();
		clearInterval(this.recallnotf);
		// clearTimeout(this.routeDelay);
	}

	resetTimer(endTime: number = this.endTime) {
		const interval = 1000;
		const duration = endTime * AppGlobals.USER_SESSION_TIMEOUT;
		this.timerSubscription = timer(0, interval).pipe(take(duration)).subscribe(value =>
			this.render((duration - +value) * interval),
			err => { },
			() => {
				//TODO fix logout timer issue
				// this.logout();
			}
		)
	}

	private render(count) {
		this.secondsDisplay = this.getSeconds(count);
		this.minutesDisplay = this.getMinutes(count);
	}

	private getSeconds(ticks: number) {
		const seconds = ((ticks % 60000) / 1000).toFixed(0);
		return this.pad(seconds);
	}

	private getMinutes(ticks: number) {
		const minutes = Math.floor(ticks / 60000);
		return this.pad(minutes);
	}

	private pad(digit: any) {
		return digit <= 9 ? '0' + digit : digit;
	}
	reDirectApp(short_name, IsZmLogin, IsZsLogin) {
		if (short_name === 'z-ing') {
			const loginProvider = sessionStorage.getItem('loginProvider');
			const access_token=sessionStorage.getItem('accessToken');
			console.log(access_token)
			let apps = [];
			this.userinfo.apps.forEach(ele => {
				apps.push(ele.app_id)
			});
			if (loginProvider === 'azure_sos') {
				const selectedAppShortName = sessionStorage.getItem('selectedApp') || 'zs';
				var redirectingFrom = this.userApps.filter((app) => {
					return app.short_name === selectedAppShortName;
				})[0].app_location;

				if (redirectingFrom) {
					let index = redirectingFrom.indexOf("#");
					redirectingFrom = redirectingFrom.substring(0, index);
				}
				this._baseService.getIngestLocationByTenant(this.userinfo.user_id, this.userinfo.tenant_id, this.http).subscribe((result) => {
					let ingestApp = result;
					if (ingestApp.length > 0) {
						ingestApp.forEach(element => {
							if (element.is_current_environment) {
								this.appLocation = element.ingest_acquisition_url;
							}
						});
					} else {
						this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
							let ingestApp = result;
							ingestApp.forEach(element => {
								if (element.app_id === 4) {
									this.appLocation = element.app_location;
								}
							});
						}, error => {
							console.log(`Error in getting app location:`, error);
						});
					}
					window.location.href = `${this.appLocation}:18630/?userId=${this.userinfo.user_id}&
																		tenantId=${this.userinfo.tenant_id}&
																		firstName=${this.userinfo.first_name}&
																		lastName=${this.userinfo.last_name}&
																		apps=${apps.toString()}&
																		defAppId=${this.userinfo.preference.default_app_id}&																		
																		redirectingFrom=${redirectingFrom}&
																		redirectingFromAPI=${this._apiUrl}&
																		access_token=${access_token}}`;
				}, error => {
					console.log(`Error in getting app location:`, error);
				});
			} else {
				// sessionStorage.setItem('selectedApp', short_name);
				const selectedAppShortName = sessionStorage.getItem('selectedApp') || 'zs';
				var redirectingFrom = this.userApps.filter((app) => {
					return app.short_name === selectedAppShortName;
				})[0].app_location;

				if (redirectingFrom) {
					let index = redirectingFrom.indexOf("#");
					redirectingFrom = redirectingFrom.substring(0, index);
				}
				this._baseService.getIngestLocationByTenant(this.userinfo.user_id, this.userinfo.tenant_id, this.http).subscribe((result) => {
					let ingestApp = result;
					if (ingestApp.length > 0) {
						ingestApp.forEach(element => {
							if (element.is_current_environment) {
								this.appLocation = element.ingest_acquisition_url;
							}
						});
					} else {
						this._baseService.getAppLocation(this.userinfo.user_id, this.http).subscribe((result) => {
							let ingestApp = result;
							ingestApp.forEach(element => {
								if (element.app_id === 4) {
									this.appLocation = element.app_location;
								}
							});
						}, error => {
							console.log(`Error in getting app location:`, error);
						});
					}
					window.location.href = `${this.appLocation}:18630/?userId=${this.userinfo.user_id}&
																		tenantId=${this.userinfo.tenant_id}&
																		firstName=${this.userinfo.first_name}&
																		lastName=${this.userinfo.last_name}&
																		apps=${apps.toString()}&																		
																		defAppId=${this.userinfo.preference.default_app_id}&																		
																		redirectingFrom=${redirectingFrom}&
																		redirectingFromAPI=${this._apiUrl}&
																		access_token=${localStorage.getItem('access_token')}`;
				}, error => {
					console.log(`Error in getting app location:`, error);
				});
			}
		} else {
			/** 
			 * Reset app to resolve if any state change in edit entity page(HB logic is applied only in resolve). 
			 */
			if (this.isEntityStateChangedForHeuristicBuilder) {
				/** 
				 *  Navigating the Home route to trigger error message in edit entity screen
				 */
				this.selectedApp = 'zs';
				this._router.navigate([short_name + '/home']);
			} else {
				sessionStorage.setItem('selectedApp', short_name);
				sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, IsZmLogin);		//'isZmloggedIn'
				sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, IsZsLogin);		//'isZsloggedIn'
				this._router.navigate([short_name + '/home']);
			}
		}
	}

	
	changedDefaultApp() {
		let appId = -1;
		if(this.selectedApp === 'zs' && this.value) {
			appId = 1;
		} 
		if(this.selectedApp === 'zs-cl' && this.value) {
			appId = 3;
		}
		if(this.selectedApp === 'z-ing' && this.value) {
			appId = 4;
		}
		
		const postData = {
			user_id: this.userinfo.user_id,
			// payload: { preference: { default_app_id: 4 } }
			payload: { preference: { default_app_id: appId } }
		};
		this._baseService.updateUserPreferences(postData, this.http).subscribe((result) => {
			this.userinfo.preference.default_app_id = appId;
			sessionStorage.setItem('userInfo', JSON.stringify(this.userinfo));
		}, err => { });
	}
	
	//Retreiving notficationList and badge number
	getNotificationListData() {
		this.contentSvc.getNotificationCount(this.userinfo.user_id).subscribe((data) => {
			this.noticationlist = data;
			if (this.noticationlist) {
				this.notificationcount = data["badge"];
				let concatedRows = this.noticationlist["notifications"];
				let obj = concatedRows.map((item) => ({ 'value': item.short_name, 'text': item.name }));
				this.filterednotifications = obj.slice(0, 10);
				if (this.noticationlist['notifications'].length > 10) {
					this.isview = true;
				}
			}
		}, err => {
			this.notificationcount = 0;
		});
		if (this.notificationcount > 0) {
			this.badgecount = true;
		} 
	}

	// To find notfications read or not
	notifcationviewed() {
		this.badgecount = false;
		this.notificationcount = 0;
		this.contentSvc.notificationview(this.userinfo.user_id).subscribe((data) => {
			this.badgecount = false;
		});
	}

	//AfterViewInit
	ngAfterViewInit() {
		this.recallnotf = setInterval(() => {
			this.getNotificationListData();
		}, 30000)
	}

	//Grid Filtering based on the value selected
	Filterddata(event) {
		    if(event){
				this.shortname=event.value;			
				sessionStorage.setItem('shortname', this.shortname)
			}			
			let url = this._router.url.split('zs')[1];
			if(url.includes('notifications')){
              window.location.reload();
			}else{
				this._router.navigate(['zs/notifications']);
			}
			
	}
	searchData(){
		this._obervableService.sendSearchText=this.searchText
	}
	searchClick() {
		if (this._router.url !== '/zs/search') {
			this._router.navigate(['/zs/search']);
		}
	}

}

