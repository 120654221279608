import { Component,Output,SecurityContext,EventEmitter, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseService } from "../../services/base-service";
import { TextSelectEvent } from "./text-select.directive";interface SelectionRectangle {
	left: number;
	top: number;
	width: number;
	height: number;
}

@Component({
	selector: "zetta-text-select",
    templateUrl: './text-select-demo.component.html',
	styleUrls: ["./text-select-demo.component.scss" ],
})
export class TextSelectDemoComponent implements OnChanges {
    searchTerm="";
	public hostRectangle: SelectionRectangle | null;
	private selectedText: string;
	isMultiSelect=false;
	hightlightColor="red-text";	
    @Input() textContent:string;
    newText="";
	@Output() getSelectedText = new EventEmitter<any>();
	@Input() boxHight=''
	@Input() isDisabled:boolean;
            
	colorList = [
		{color:'red-text', isUsed:false},
     	{color:'green-text',isUsed:false},
		{color:'pink-text',isUsed:false},
		{color:'orange-text',isUsed:false}];
	// I initialize the app-component.
	constructor(private sanitizer: DomSanitizer, private baseService:BaseService) {

		this.hostRectangle = null;
		this.selectedText = "";

	}
	ngOnChanges(changes: SimpleChanges): void {
		if(!this.isDisabled){
			this.selectedText = this.isDisabled ? "" :this.selectedText;	
			this.getSelectedText.emit(this.selectedText);
		}else{
			const sanitzedText = this.sanitizer.sanitize(
				SecurityContext.HTML,
				this.textContent
			  );
			  this.newText =sanitzedText;
		}

		if(changes.textContent) {
			this.textContent = changes.textContent.currentValue;
			this.newText = this.textContent;
		}
	}

	ngOnInit(){
		this.newText = this.textContent;
		  this.baseService.deSelectText.subscribe(data =>{
           if(data){
			   data.forEach(element => {
				const regex = new RegExp(
					element.prediction,
				  );
			   const newContent = this.newText.replace(regex, (match: string) => {
				   return `<span class="p-0 m-0 ${element.textColor}">${match}</span>`;
				  });
				  const sanitzed = this.sanitizer.sanitize(
					SecurityContext.HTML,
					newContent
				  );
				  this.newText = sanitzed;
				  this.textContent = sanitzed;
			   });
			
		   }
		   
		  });

		  this.baseService.resetTextSelected.subscribe((newData) => {
			this.textContent = newData;
			this.newText = newData;
		  })
	}
	
	// I render the rectangles emitted by the [textSelect] directive.
	public renderRectangles( event: TextSelectEvent ) : void {		
        this.searchTerm = event.text;

		// If a new selection has been created, the viewport and host rectangles will
		// exist. Or, if a selection is being removed, the rectangles will be null.
		if ( event.hostRectangle ) {
			this.hostRectangle = event.hostRectangle;
			this.selectedText = event.text;

			const regex = new RegExp(
				this.searchTerm,
			  );
			  let newContent=""
			  if(this.isMultiSelect){
				newContent = this.newText.replace(regex, (match: string) => {
					return `<span class="p-0 m-0 bg-yellow">${match}</span>`;
				  });
			  }else{
				newContent = this.textContent.replace(regex, (match: string) => {
					return `<span class="p-0 m-0  bg-yellow">${match}</span>`;
				  });
			  }
			  
			  const sanitzed = this.sanitizer.sanitize(
				SecurityContext.HTML,
				newContent
			  );
			  this.newText = sanitzed;
           this.getSelectedText.emit({text:this.selectedText, isMultiSelect:this.isMultiSelect});
		} else {
			this.hostRectangle = null;
			this.selectedText = "";
		}

	}

	
	clearSelection() {
		if (!this.selectedText) {
			const sanitzed = this.sanitizer.sanitize(
				SecurityContext.HTML,
				this.textContent
			);
			this.newText = sanitzed;
			this.getSelectedText.emit({text:this.selectedText, isMultiSelect:this.isMultiSelect});
		}

	}
	
	isKeyPressed(event) {
		if (event.ctrlKey) {
		  this.isMultiSelect=true;
		} else {
			this.isMultiSelect=false;
		}
	  }
}