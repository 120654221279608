import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { ProjectStatus } from '../../../../common/components/project-progress/project-progress.model';
import { Router } from '@angular/router';
import { DatasetService } from '../dataset.service';
import { MessageService } from '../../message/message.service';
import { ContentComponent } from 'src/app/zettasense/content/content.component';
import { AppGlobals } from 'src/app/common/shared/app.globals';
declare var $: any;

@Component({
  selector: 'zetta-schedule-refresh',
  templateUrl: './schedule-refresh.component.html',
  styleUrls: ['./schedule-refresh.component.scss']
})
export class ScheduleRefreshComponent implements OnInit, OnDestroy {

  loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  showBreadcrumb = false;
  progressState = new ProjectStatus();
  datasetId;
  errorMessage = undefined;
  public engine_type_id = JSON.parse(sessionStorage.userInfo).engine_type_id;
  datasetScheduleSaveChanges = false;
  datasetSchedule_ids: any = [];
  isNewDataset: boolean = false;
  constructor(public zettaUtils: ZettaUtils,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private datasetSvc: DatasetService,
              private messageSvc: MessageService) { }

  ngOnInit() {
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');
    if(this.datasetId && showBreadcrumb == 'true') {
      this.isNewDataset = false;
    } else {
      this.isNewDataset = true;
    }
    if (!this.showBreadcrumb) {
      $('#breadcrumb').addClass('d-none');
    }

    this.progressState.states = this.zettaUtils.getStateList('create-dataset');
    this.progressState.currentStateIndex = 3;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'Data Set';
    this.progressState.isEdit = (this.datasetId !== undefined && this.datasetId !== null) ? true : false;
    this.progressState.isEdit ? sessionStorage.setItem('newDataSet', 'false') : sessionStorage.setItem('newDataSet', 'true');
  }

  ngOnDestroy() {
    if (!this.showBreadcrumb) {
      $('#breadcrumb').removeClass('d-none');
    }
    sessionStorage.removeItem('newDataSet');
  }

  callAlgo203(dataset_ids){
    this.datasetSvc.runModel203(dataset_ids).subscribe(resp =>{
      console.log(resp);
    },error=>{
      this.messageSvc.sendMessage({message: 'Algo 203 call has failed', type: 'ERROR', hideboard: true});
    });
  }

  onSaveAndClose(){    
    let payload: any = {};
    let dataset_ids=[];
    if(sessionStorage.getItem("bulkDatasetIds")){
      dataset_ids =sessionStorage.getItem("bulkDatasetIds").split(",");  
    }
    this.datasetScheduleSaveChanges = true;
    this.datasetSchedule_ids = dataset_ids;
  
    if(dataset_ids.length){   
     //  this.callAlgo203(dataset_ids);  
     let algo_id;    
     if(this.engine_type_id === 452){
        algo_id=404;
     }
     if(this.engine_type_id === 451){
      algo_id=300;
     }    
       this.datasetSvc.runModel300(dataset_ids).subscribe((result) => {      
           if(result.length){
            sessionStorage.removeItem('bulkDatasetIds');
            this.messageSvc.sendMessage({ message: '', type: 'Info', hideInfo: true, activeJobs:'Dataset', 
            messageHeader:AppGlobals.DATASET_MSG_HEADER, messageText:AppGlobals.DATASET_MSG_TEXT, path:'zs/datasets'});                          
           }      
        },error=>{
          this.messageSvc.sendMessage({message: 'Algo 300 call has failed', type: 'ERROR', hideboard: true});
        });      
    }
    
  }
  onSaveDataSet() {
    this.datasetSvc.runModel203(this.datasetId).subscribe((result) => {
        setTimeout(() => {
          this.datasetSvc.runModel(this.datasetId).subscribe((res) => {
            sessionStorage.removeItem('newDataSet');
            this.router.navigate(['/zs/datasets', this.datasetId]);
          }, (err) => {
              this.errorMessage = err.error.message;
          });

        }, 5000);
    }, (responseError) => {
        this.errorMessage = responseError.error.message;
    });
  }

  onPrevious() {
    this.router.navigate(['/zs/datasets/user-entitlements'], { queryParams: { datasetId: this.datasetId, showBreadcrumb: this.showBreadcrumb } });
  }

  removeCancel() {
    $('#cancelBoxModal').removeClass('show');
  }

}
