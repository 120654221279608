import { Column, Editor, KeyCode } from 'angular-slickgrid';
declare var $: any;

/*
 * An example of a 'detached' editor.
 * KeyDown events are also handled to provide handling for Tab, Shift-Tab, Esc and Ctrl-Enter.
 */
export class DropDownEditor implements Editor {
    $input: any;
    defaultValue: any;

    constructor(private args: any) {
        this.init();
    }

    populateSelect(select, dataSource) {
        let newOption;

        $.each(dataSource, function (key, data) {
            newOption = new Option(data.text, data.value);
            select.appendChild(newOption);
        });
    }

    addData(args: any) {
        this.populateSelect(this.$input[0], args.column.params);
    }

    init(): void {
        this.$input = $(`<select style="width: 50%" ></select>`)
            .appendTo(this.args.container)
            .on('keydown.nav', (e) => {
                if (e.keyCode === KeyCode.UP || e.keyCode === KeyCode.DOWN || e.keyCode === KeyCode.ENTER) {
                    e.stopImmediatePropagation();
                }
            });

        this.$input.width(this.args.container.clientWidth);
        this.$input.height(this.args.container.clientheight);

        this.$input.focus().select();
        this.addData(this.args);

        this.$input.select2({
            placeholder: 'Select Type',
            allowClear: true,
            autocomplete: true,

            sorter: function (data) {
                return data.sort(function (a, b) {
                    return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
                });
            },
        });

        this.$input.on('select2:select', (event) => {
            const _selection = $(event.target).find(':selected');
            const selected = _selection[0].value;
            if (selected) {
                this.setValue(selected);
                if (this.defaultValue !== selected) {
                    this.args.item.column_type = _selection[0].text;
                    this.args.item.lookup_id = selected;
                    this.save();
                }
            }
        });

        setTimeout(() => {
            this.$input.focus().select();
        }, 50);

    }

    destroy() {
        this.$input.remove();
    }

    focus() {
        this.$input.focus();
    }

    getValue() {
        return this.$input.val();
    }

    setValue(val: string) {
        this.$input.val(val);
    }

    loadValue(item: any) {
        this.defaultValue = item[this.args.column.field] || '';
        this.$input[0].defaultValue = this.defaultValue;
        this.$input.val(this.defaultValue).trigger('change');
    }

    serializeValue() {
        return this.$input.val();
    }

    applyValue(item: any, state: any) {
        item[this.args.column.field] = state;
    }

    isValueChanged() {
        return (!(this.$input.val() === '' && this.defaultValue === null)) && (this.$input.val() !== this.defaultValue);
    }

    /** Get Column Definition object */
    get columnDef(): Column {
        return this.args && this.args.column || {};
    }

    /** Get Column Editor object */
    get columnEditor(): any {
        return this.columnDef && this.columnDef.internalColumnEditor || {};
    }

    get hasAutoCommitEdit() {
        return true;
    }

    save() {
        if (this.hasAutoCommitEdit) {
            this.args.grid.getEditorLock().commitCurrentEdit();
        } else {
            this.args.commitChanges();
        }
    }

    validate() {
        return {
            valid: true,
            msg: null
        };
    }
}
