import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zetta-project-mode',
  templateUrl: './project-mode.component.html',
  styleUrls: ['./project-mode.component.scss'],
})
export class ProjectModeComponent implements OnInit {

  @Input() modeData: any;

  constructor() { }

  ngOnInit() { }

}
