import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import panzoom from "panzoom";
import { MessageService } from "src/app/common/components/message/message.service";
import { ZsClContentService } from "../zs-cl-content.service";
import { AngularGridInstance, Column, Formatter } from "angular-slickgrid";
import { DatasourceService } from "src/app/common/components/datasources/datasource.service";
import { AppGlobals } from "src/app/common/shared/app.globals";
import { BaseService } from "src/app/common/services/base-service";
import { environment } from 'src/environments/environment';
declare var $: any;

const dataSourceDropdownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  var options = '';
  var valueName = '';
  if (columnDef.field === 'name') {
  if (columnDef.params.list && columnDef.params.list.length > 0) {
     for (var j of columnDef.params.list) {
                  options = options + '<option value=\''+j.datasource_id +'\'>'+ j.name +'</option>\n';
        }
      }   
  
  let placeholder = '';  
  placeholder = 'Select Datasource';
  var classList = 'grid-select-dropdown';
      return `<div class="selectInput w-100">
                  <select id='datasource-${columnDef.field}-${row}' class="${classList} ctm-obj-wd">
                      <option value="">${placeholder}</option>
                      ${options}
                  </select>
              </div>`;
  }
}




const textBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
          return `
          <div>
          <input id='${columnDef.field}-${row}' type="text" class="input-text-box" value="${value}" />
          </div>`;
}


const checkBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if (columnDef.params.includePipeline == false) {
    return ` <label class="ml-4 mr-top-7 check-cell aaprv">
             <input type="checkbox" disabled></label>
            `;
  } else if(columnDef.params.includePipeline == true){
    if (value || columnDef.params.includePipeline) {
      return `
      <label class="checkbox-container">
            <input id='${columnDef.field}-${row}' type="checkbox" checked>
            <span class="checkmark checkmark-left-56 checkbox-border"></span>
         </label>
      `;
    } else {
      return `
      <label class="checkbox-container">
            <input id='${columnDef.field}-${row}' type="checkbox">
            <span class="checkmark checkmark-left-56 checkbox-border"></span>
         </label>
      `;
    }
  }
}



@Component({
  selector: 'zetta-zs-cl-view-object-model',
  templateUrl: './zs-cl-view-object-model.component.html',
  styleUrls: ['./zs-cl-view-object-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZsClViewObjectModelComponent implements OnInit{
    @ViewChild('scene') scene: ElementRef;     
    object_id:string;     
    imageUrl:any;   
    catalog_id;
    datasources: Array<object> = [];
    semanticDatasetsTableSettings: object = { 'height': "100%", 'width': "100%" };
    semanticDatasetsTableColDef: any;
    semanticDatasetsGridOptions: any;
    semanticDatasets: any;
    semanticDatasetsGrid: AngularGridInstance;
    isRowSelected: Boolean = false;
    selectedDatasets: Array<any> = [];
    isCatalogAdmin: boolean;
    checkValidate: Boolean = false;
    entityName: string = '';
    enablePipelineInclude: Boolean;
    noData = false;
    semanticsDataLoaded = false;
    selectedRows;
    element = document.getElementById('scene');
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    objectTypeId = AppGlobals.OBJECT_TYPE_ID_CATALOG;
    isCatalogUser: boolean = false;
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,        
        private messageSvc: MessageService,
        private contentService:ZsClContentService,
        private datasourceService: DatasourceService,
        private baseService: BaseService,
        private sanitizer: DomSanitizer,
        private catalogSvc: ZsClContentService) { }
    ngOnInit(){     
      this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');    
      this.catalog_id = this.activatedRoute.snapshot.queryParamMap.get('catalog_id');
      this.entityName = this.activatedRoute.snapshot.queryParamMap.get('entityName');  
      this.isCatalogAdmin = JSON.parse(sessionStorage.getItem("isCatalogAdmin"));
      this.getEntityImage();
      this.initColDef();
      this.getDatasources();
      this.getCatalogUsers();
      let ingestAccess = this.loggedInUserDetails.apps.find( app => parseInt(app.app_id) == AppGlobals.INGEST_APP_ID);
     this.enablePipelineInclude = ingestAccess.app_id == AppGlobals.INGEST_APP_ID;
       this.semanticDatasetsGridOptions = {
        enableGridMenu: false,
        enableAddRow: false,
        selectable: true,
        enableFiltering: true,
        CheckboxSelector: true,
        enableCellNavigation: true,
        multiSelectable: true,
       };
    }

    
    getCatalogUsers() {
      this.catalogSvc.getCatalogUser(this.catalog_id)
      .subscribe((users) => {
          if (this.loggedInUserDetails && this.loggedInUserDetails.user_id) {
            let isLoggedInUserCatalogUser = users && users.length > 0 && users.find((catalogUser) => {
              return catalogUser.user_id === this.loggedInUserDetails.user_id;
            })
            if (isLoggedInUserCatalogUser) {
              this.isCatalogUser = true;
            } else {
              this.isCatalogUser = false;
            }
          }
      }, (err) => {
          this.isCatalogUser = false;
      })
    }
    isUserNotCatalogUser() {
      return this.isCatalogUser ? false: true;
    }
    
    initColDef() {
      this.semanticDatasetsTableColDef = [
        {
          'displayname': 'Original Data Source',
          'physicalname': 'original_datasource_name',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
          'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
          },
          {
            'displayname': 'Original Data Set',
            'physicalname': 'original_dataset_name',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'minWidth': 150,
            'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
            },
            {
              'displayname': 'Target Data Source',
              'physicalname': 'name',
              'sortable': false,
              'datatype': 'String',
              'filterable': false,
              'formatter': dataSourceDropdownFormatter,
              'minWidth': 150,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              'params': { list: this.datasources, tabindex: 1},
              },{
                'displayname': 'Target Data Set',
                'physicalname': 'target_dataset_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': textBoxFormatter,
                'minWidth': 150,
                'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
                },

                {
                  'displayname': 'Include Pipeline',
                  'physicalname': 'include_pipeline',
                  'sortable': false,
                  'datatype': 'String',
                  'filterable': false,
                  'formatter': checkBoxFormatter,
                  'minWidth': 150,
                  'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
                  'params': { includePipeline: this.enablePipelineInclude},
                  },
      ];
    }
   

  public getEntityImage() {
    this.contentService.getEntityDiagram(this.loggedInUserDetails['user_id'], this.object_id, this.catalog_id).subscribe(resp => {
      let reader = new FileReader();
      let base64Img: any;
      reader.addEventListener('load', () => {
        base64Img = reader.result;
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(base64Img);
      }, false);
      if (resp && resp.size && resp.type == "image/svg+xml") {
        base64Img = reader.readAsDataURL(resp);
      } else {
        this.imageUrl = null;
      }
    }, error => {
    });
  }

  ngAfterViewInit() {    
    panzoom(this.scene.nativeElement,{
        bounds: true,
        boundsPadding: 0.1,
        autocenter:true,
        transformOrigin: {x: 0.5, y: 0.5}
      });    
    
  }
  
  onCancel(){
   this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, istechView: true,showBreadcrumb: true } });
  }

  getSemanticDatasetsByEntity() {
    this.semanticDatasets = [];
    this.semanticsDataLoaded = false;
    this.semanticDatasetsTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/1325/semanticdatasets?entityId=${this.object_id}`;
    this.contentService.getSemanticDatasetsByEntity(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id,this.object_id)
    .subscribe(res => {
      if(res) {
        this.semanticDatasets = res;
        this.semanticDatasets.forEach(element => {
          //element['targetDsName'] = `${element.original_datasource_name}_${this.entityName}_${Date.now()+Math.floor((Math.random()*100))}`;
          if (this.enablePipelineInclude) {
            element['include_pipeline'] = true;
          }
          this.semanticDatasets.forEach(ele => ele.id = Math.random());
        });
        this.semanticsDataLoaded = true;
      }
      this.initColDef();
      },error=>{
        this.semanticsDataLoaded = true;
        this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
      });
  }

  getDatasources() {
    const payload = {
      userId: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      pageno:1,
      perpage: 5000
    };
    this.datasourceService.getDatasources(payload)
    .subscribe(res => {
      if(res && res['currentpage']) {
        this.datasources = res['currentpage'].filter(source => { 
          return (source['entitlements'].menu.edit == true && source['entitlements'].menu.delete == true && source['entitlements'].menu.clone == true) ;
        });
      }
      this.initColDef();
    });
  }

  onDataFiltered(data) {
    //Hack: reset datasets here. Refactor later after changing grid component
    if (data.items && data.items.length > 0) {
      this.semanticDatasets = data.items;
      this.semanticDatasets = this.semanticDatasets.map((val) => {
        val.target_datasource_id = null; 
        return {...val};
      });
    }
  }

  semanticsModalCreated(grid) {
    const parent = this;
        parent.semanticDatasetsGrid = grid;
        parent.semanticDatasetsGrid.slickGrid.onScroll.subscribe(function(e, args )  {
        parent.semanticDatasets.forEach((selectedRow, ind) => {
          if(selectedRow.dataSourceId) {
            $('#datasource-name-' + ind)[0]['value'] = selectedRow['dataSourceId']
          }
        });
        }),
        parent.semanticDatasetsGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
          parent.semanticDatasets.forEach((selectedRow, ind) => {
            if(selectedRow.dataSourceId) {
              $('#datasource-name-' + ind)[0]['value'] = selectedRow['dataSourceId']
            }
            let dsName = $('#target_dataset_name-' + ind);
            if(dsName[0] && dsName[0]['value']) {
              $('#target_dataset_name-' + ind)[0]['value'] = selectedRow['target_dataset_name'];
            } else {
              return;
            }
          });
          parent.selectedRows = args.rows
          parent.isRowSelected = args.rows.length > 0 ? true : false;
          parent.checkValidates();
        });
  }

  onSemanticDsGridClickEvent(eve) {
    const eventEle = eve.eventData;
    const args = eve.args;
    const row = this.semanticDatasetsGrid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.semanticDatasetsGrid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
          if (fieldName === 'include_pipeline') { 
            let idCell = '#' + fieldName + '-' + args.row;
            row['include_pipeline'] = !row['include_pipeline'];
            $(idCell)[0]['checked'] = !$(idCell)[0]['checked'];
            this.semanticDatasets[args.row]['include_pipeline'] = row['include_pipeline'];
          } else if(fieldName == 'target_dataset_name') {
            let parent = this;
              $('#target_dataset_name-' + args.row).on('keyup', function(e) {
                let targetDsName = $('#target_dataset_name-' + args.row)[0]['value'] ? $('#target_dataset_name-' + args.row)[0]['value'] : null;
                row['target_dataset_name'] = targetDsName;
              //  row['targetDsName'] = targetDsName;
                parent.checkValidates();
                parent.semanticDatasets[args.row]['target_dataset_name'] = targetDsName;
              });
            } else if(fieldName == 'name') {
              let parent = this;
              $('#datasource-name-' + args.row).on('change', function(e) {
                let dsId = $('#datasource-name-' + args.row)[0]['value'];
                row['dataSourceId'] = dsId ? dsId : null;
                parent.checkValidates();
                parent.semanticDatasets[args.row]['target_datasource_id'] = dsId;
              });
            }   
  }

  publishData() {
    const leftGridDataService = this.semanticDatasetsGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const includePipeleineRows = [];
    const ruleIds = [];
    this.selectedDatasets = [];
    
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null && row['dataSourceId']) {
        this.selectedDatasets.push(row);
        ruleIds.push(row.semantic_dataset_id);
        if(row.include_pipeline){
          includePipeleineRows.push(row.semantic_dataset_id);
        }  
      }
    });
    let actualPayload  = (this.selectedDatasets).map(({ dataSourceId,target_datasource_id, target_dataset_name, include_pipeline, semantic_dataset_id }, ind) => {
      return {
        'target_datasource_id': target_datasource_id ? target_datasource_id: dataSourceId,
        'target_dataset_name': target_dataset_name ? target_dataset_name : $('#target_dataset_name-' + ind)[0]['value'],
        'semantic_dataset_id': semantic_dataset_id,
        'include_pipeline': include_pipeline
      };
    });
    this.contentService.updateSemanticDatasets(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, actualPayload)
    .subscribe(res => {
      if(res) {
        let mandatoryPayload = {
          entity_id: this.object_id,
          loggedInUserId: this.loggedInUserDetails['user_id'],
          semantic_dataset_id: ruleIds.join(),
        };
        this.contentService.publishAllSemanticDatasets(mandatoryPayload)
        .subscribe(algoResp => {
          if(algoResp) {
            let includePipelinesPayload = {
              entity_id: this.object_id,
              loggedInUserId: this.loggedInUserDetails['user_id'],
              semantic_dataset_id: includePipeleineRows.join(),
            };
            if(includePipeleineRows && includePipeleineRows.length>0) {
              this.contentService.publishSemanticDatasetPipelines(includePipelinesPayload)
              .subscribe(pipeLineAlgoResp => {
                if(pipeLineAlgoResp) {
                  this.messageSvc.sendMessage({
                      type: "INFO",
                      hideInfo: true,
                      semanticDatasetsCreate: true,
                      activeJobs: "classifySemanticDatasets",
                      isModel: true,
                      messageHeader: AppGlobals.SEMANTIC_DADA_SET_HEADER_MESSAGE,
                      message: AppGlobals.SEMANTIC_DADA_SET_MESSAGE,
                      messageText: AppGlobals.SEMANTIC_DADA_SET_FOOTER_MESSAGE,
                    });
                  this.semanticDatasetsGrid.gridService.setSelectedRows([]);
                  this.semanticDatasetsGrid.dataView.refresh();
                  this.selectedDatasets = [];
                }
              });
            } else {
              this.messageSvc.sendMessage({
                  type: "INFO",
                  hideInfo: true,
                  semanticDatasetsCreate: true,
                  activeJobs: "classifySemanticDatasets",
                  isModel: true,
                  messageHeader: AppGlobals.SEMANTIC_DADA_SET_HEADER_MESSAGE,
                  message: AppGlobals.SEMANTIC_DADA_SET_MESSAGE,
                  messageText: AppGlobals.SEMANTIC_DADA_SET_FOOTER_MESSAGE,
                });
                  this.semanticDatasetsGrid.gridService.setSelectedRows([]);
                  this.semanticDatasetsGrid.dataView.refresh();
                  this.selectedDatasets = [];
            }
          }
        });
      }
    });
  }

  hidesemanticModal() {
    $('#hideCreatesemanticModal').modal('hide');
    this.semanticDatasetsGrid.gridService.setSelectedRows([]);
    this.semanticDatasetsGrid.dataView.refresh();
    this.selectedDatasets = [];
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  checkValidates() {
    if(this.isRowSelected) {
      const leftGridDataService = this.semanticDatasetsGrid.gridService;
      const selectedRows: number[] = leftGridDataService.getSelectedRows();
      this.checkValidate = selectedRows.every(index => {
        const row = leftGridDataService.getDataItemByRowNumber(index);
        return row.dataSourceId && ((row.target_dataset_name && row.target_dataset_name.length>0) || (row.targetDsName && row.targetDsName.length>0));
      
      });
    }
  }
}