import { Component, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AssignmentStatusFormatterComponent } from 'src/app/common/shared/cell-renderer/assignment-status-formatter/assignment-status-formatter.component';
import { BlueTextFormatterComponent } from 'src/app/common/shared/cell-renderer/blue-Text-Formatter/blue-Text-Formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
import { NumericFormatterComponent } from 'src/app/common/shared/cell-renderer/numeric-formatter/numeric-formatter.component';
import { OptionSelectionRendererComponent } from 'src/app/common/shared/cell-renderer/option-Selection-formatter/option-Selection-formatter.component';
import { ProjectService } from '../project.service';
import { Project } from '../project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';
import { MessageService } from 'src/app/common/components/message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { MatherMergerFormatterComponent } from 'src/app/common/shared/cell-renderer/mather-merger-formatter/mather-merger-formatter.component';

@Component({
  selector: 'zetta-open-golden-records',
  templateUrl: './open-golden-records.component.html',
  styleUrls: ['./open-golden-records.component.scss']
})
export class OpenGoldenRecordsComponent implements OnInit {
  trainModelDataSource: Array<Object> = [];
  columnDefs: ColDef[] = [];
  fsGridOptions: GridOptions;
  agGrid:AgGridAngular;
  gridApi:any;
  columnApi: any;
  gridOptions: any;
  feedbackGridOptions: any;
  loggedInUserDetails: object = {};
  projectDetail = new Project();
  isLoading: boolean = false;
  tableSettings: object = { 'height': "100%", 'width': "100%" };
  total = 0;
  page = 1;
  limit = 100;
  hasScrolled = false;
  noData = false;
  shouldDisableFeedback = false;
  project_users = [];
  isFiltering = false;
  isSorting = false;
  openMatcherFeedbackModal: boolean = false;
  selectedClusterId: string;
  taskType: string;
  selectedTaskName: string;
  selectedTaskType: string;
  selectedTaskStatus: string;
  shouldDisableMatchFixingTasks: boolean = true;
  shouldMoveMatchFixingData: boolean = false;
  isProjectFourEyeCheckOn: boolean = false;
  isProjectDataLoaded: boolean = false;
  selectedJobId: string;
  subTotal;
  currentDatalength = '';
  grandTotal;
  selectedRowData: object = {};
  constructor(private projectSvc: ProjectService,     private router: Router, private activatedRoute: ActivatedRoute, 
    private zettaUtilsSvc: ZettaUtils,     
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService,
  ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });
        this.gridOptions = {
          enableGridMenu: false,
          selectable: true,
          enableFiltering: true,
          CheckboxSelector: true,
          checkboxSelection:true,
          suppressRowClickSelection:true,
          enableCellNavigation: true,
          enableRowSelection: true,
          rowHeight: 34
        };

        this.getProjectTasks(this.page, this.limit);

    this.columnDefs = [
      {
        headerName: 'Task Name',
        field: 'zs_cluster_identifier',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        minWidth: 250,
        cellRendererFramework: BlueTextFormatterComponent,
       
      },
      {
        headerName: 'Task Type',
        field: 'tasktype', 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        cellRendererFramework: MatherMergerFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        minWidth:150,
      },
      {
        headerName: 'Assigned To',
        field:'zs_assigned_to',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        floatingFilter: true,
        sortable: true,
        cellClass: 'ag-right-aligned-cell',
        tooltipField : 'zs_assigned_to',
        minWidth: 140,
      },{
        headerName: 'Task Status',
        field:'task_status',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework:AssignmentStatusFormatterComponent,
        minWidth: 130,
        tooltipField : 'task_status',
        floatingFilter: true,
        sortable: true,
      },{
        headerName: 'Date Assigned',
        field: 'last_updated',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework: DateFormatterFormatterComponent,
        tooltipField : 'last_updated',
        floatingFilter: true,
        sortable: true,
        minWidth: 140,
      },
    ];
  }

  getProjectTasks(pageno, limit) {
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved/fixissues/summary?onlyopentasks=true`;
    this.projectSvc.getProductionProjectTasks({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }, pageno, limit)
    .subscribe(resp => {
      if(resp) {
        if(resp['projectTasks']) {
          const tasksData = resp['projectTasks'];
          this.trainModelDataSource = tasksData['currentpage'];
          if(pageno === 1){
            this.grandTotal = tasksData['totalrecords'];
          }
          this.isLoading = false;
        }
        if(resp['projectDetails']) {
          this.projectDetail = resp['projectDetails'];
          this.isProjectFourEyeCheckOn = this.projectDetail['four_eye_check'] ? this.projectDetail['four_eye_check']:false;
          this.isProjectDataLoaded = true;
        }
      }
    }, err => {
      this.trainModelDataSource = [];
      this.isLoading = false;
    });
  }

  getProductionTasks(pageno, limit) {
    this.projectSvc.getOpenGoldenRecords({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }, pageno, limit)
    .subscribe(tasksData => {
      this.trainModelDataSource = tasksData['currentpage'];
      if(pageno === 1){
        this.grandTotal = tasksData['totalrecords'];
      }
      this.isLoading = false;
    }, err => {
      this.trainModelDataSource = [];
      this.isLoading = false;
    });
  }

  onScroll(parentClass) { 
    if (this.zettaUtilsSvc.virtualScroll(parentClass) && !this.hasScrolled) {
      this.zettaUtilsSvc.setGridAutoScrollTogether(parentClass);
      this.hasScrolled = true;
      this.onNext();
    }    
  }

  onGridCreation(grid) {
    const parent = this;
    parent.agGrid = grid;
    const gridApi = grid.api;
  }


  goToPage(n: number): void {
    this.page = n;
    this.getProductionTasks(this.page, this.limit);
  }

  onNext(): void {
    this.hasScrolled = true;
    this.page++;
    this.getProductionTasks(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getProductionTasks(this.page, this.limit);
  }

  totalPages(): number {
    return this.zettaUtils.getTotalPages(this.total, this.limit);
  }
  
  onAgCellClick(cellParams){
    const row = cellParams.data;
    const field = cellParams.colDef.field;
    if (row !== undefined && row !== null) {
      if ((field === 'zs_cluster_identifier' || field === 'tasktype') && row.tasktype === 'Match Fixing') {
        this.selectedRowData = row;
        this.selectedClusterId = row.cluster_id;
        this.taskType = row.tasktype;
        this.selectedTaskName = row.zs_cluster_identifier;
        this.selectedTaskType =  row.tasktype; // 'Golden Record Change';  // row.tasktype;
        this.selectedTaskStatus = row.task_status;
        this.shouldDisableFeedback = false;
        this.selectedJobId = row.zs_job_id;
        this.shouldDisableMatchFixingTasks = (row.task_status === AppGlobals.TASK_COMPLETED || row.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
        this.shouldMoveMatchFixingData = this.validateMatchFixingTasksDataShouldBeMerged(row);
          if(this.selectedClusterId && this.taskType === 'Match Fixing'){
            this.openMatcherFeedbackModal = true;
          }
        } else {
          return;
        }
       }
  }


  onReloadScreen(event) {
    if (event) {
      this.openMatcherFeedbackModal = false;
      this.trainModelDataSource = [];
      this.page = 1;
      this.subTotal = null;
      this.grandTotal = null;
      this.getProductionTasks(this.page, this.limit);
    }
  }
  
  onMatcherFeedbackModalClosed(eve) {
    this.openMatcherFeedbackModal = false;
    this.page=1;
    this.getProductionTasks(this.page, this.limit);
  }

  resetMatcherTaskGridStates() {
    this.selectedClusterId = '';
    this.selectedTaskName = '';
    this.selectedTaskType = '';
    this.taskType = '';
    this.selectedTaskStatus = '';
    this.shouldDisableMatchFixingTasks = true;
    this.shouldMoveMatchFixingData = false;
    this.selectedJobId = '';
  }

  validateMatchFixingTasksDataShouldBeMerged(rowData) {
    if (rowData && rowData.task_status) {
        if (this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          return true;
        }
        if (!this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_IN_REVIEW) {
          return true;
        }
    }
    return false;  
  }

  onReloadFixingDataGrid($event) {
    if ($event) {
      this.openMatcherFeedbackModal = false;
      this.isLoading = true;
      this.trainModelDataSource = [];
      this.page = 1;
      this.subTotal = null;
      this.grandTotal = null;
      this.getProductionTasks(this.page, this.limit);
      if ($event.shouldRunDeltaMerger && $event.completed) {
          // call algo API from here
          this.runDeltaMatcherAlgoAPI();
      } else {
        this.resetMatcherTaskGridStates();
      }
    }
  }

  runDeltaMatcherAlgoAPI() {
    let self = this;
    this.selectedJobId = this.selectedJobId ? this.selectedJobId : this.zettaUtilsSvc.generateUUID();
    this.projectSvc.runDeltaMatcherAlgoJob('stage8', this.selectedJobId, this.projectDetail.project_id,
    this.selectedClusterId).subscribe((res) => {
      self.resetMatcherTaskGridStates();
    }, (err) => {
      self.resetMatcherTaskGridStates();
    })
  }

  receiveTotalRecords($event) {
  if ($event.subTotal == this.grandTotal) {
    this.subTotal = 0;
  } else {
    this.subTotal =  $event.subTotal; 
  }
  }
}
