import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'zetta-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	currentYear: Date = new Date();
	env: Object;

	constructor() { }

	ngOnInit() {
		this.env = {
			version: environment.version,
			name: environment.envName
		};
	}

}
