import { Column, Formatter } from 'angular-slickgrid';

export const customColumnValidatorFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
    value = value ? value : '';
    if (dataContext.isEditing || dataContext.action === 'add') {
        return `
        <span><input autocomplete="off" id='group_formula_${row}' class='ctmInputBox ctmValidatorInputBox' type='text' value='${value}'></input></span>
        <span><button type="button" class="btn btn-primary btn-zetta btn-dark reset-validator column-btn column-validator" ><span class="btn-label column-validator">Validate</span></button></span>
        <span class="text-success ml-2 validated ${dataContext.isValidFormula ? '' : 'd-none'}">Validated <i class="fa fa-check "></i> </span>
        <span class="text-success ml-2 validated ${dataContext.isAddedColumn ? '' : 'd-none'}">Added <i class="fa fa-check "></i> </span>`;
    } else {
        return `<div class='text-truncate'><label title='${value}'> ${value}</label><span class="text-success ml-3 validated ${dataContext.isSavedFormula ? '' : 'd-none'}">Saved <i class="fa fa-check "></i> </span></div>`;
    }
};

