import { Component, OnInit } from '@angular/core';
import { ContentService } from '../content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'zetta-entities-view',
  templateUrl: './entities-view.component.html',
  styleUrls: ['./entities-view.component.scss']
})
export class EntitiesViewComponent implements OnInit {
  public tableDataSource: any = [];
  public changedDataSource: any = [];
  public searchText : string;
  public entityId: number = -1;

  constructor(public contentSvc: ContentService,public activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.entityId = this.activeRoute.snapshot.params.entityId;
    this.getEntitiesSettingsDS();
  }

  getEntitiesSettingsDS() {
    this.contentSvc.getEntitiesSettings(this.entityId).subscribe((data) => {
      if(data && data['name']){
        this.tableDataSource = data['attributes'];   
      }         
    });
  }

  updateSettings(){
    this.contentSvc.updateEntitiesSettings(this.entityId,this.changedDataSource).subscribe((data) => {
      this.getEntitiesSettingsDS(); 
    });
  }

  changeData(iteData){
    this.changedDataSource.push(iteData);
  }
}
