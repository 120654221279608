

import { Column , Formatter } from 'angular-slickgrid';

export const dragIconFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        switch (dataContext.setdata) {
          case 'training':
            return `<img src="./assets/images/zettamesh/add-training-data.png" width="13">`;
          case 'prediction':
            return `<img src="./assets/images/zettamesh/add-prediction-data.png" width="13">`;
          case 'output':
            return `<img src="./assets/images/zettamesh/define-outputs.png" width="13">`;
          case 'input':
            return `<img src="./assets/images/zettamesh/define-inputs.png" width="13">`;
          case 'segment':
            return `<img src="./assets/images/zettamesh/define-segments.png" width="13">`;
          default:
            return '';
        }
};
