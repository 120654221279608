import { Component, OnInit, ViewEncapsulation, OnDestroy, DoCheck } from '@angular/core';
import { Column, Formatter, AngularGridInstance } from 'angular-slickgrid';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { MessageService } from '../../../common/components/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
declare var $: any;
import { BaseService } from '../../../common/services/base-service';
import { AppGlobals } from '../../../common/shared/app.globals';
import { first } from 'rxjs/operators';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { DatasetService } from '../../../common/components/datasets/dataset.service';
import { dqRuleNameFormattter } from 'src/app/common/shared/formatters/dqRuleNameFormatter';
import { ObservableService } from "../../../common/services/observable.service";

const DATA_MSG = '';

const columnFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {  
  if(columnDef.name === 'Rule Name') {
      return `<div class="text-truncate"><div class="blueLink text-truncate" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title="${value}">${value}</div></div>`
  } else if(columnDef.name === 'Measured DQ') {
      let txt = `<div class="mr-left-0 wd-cell"><div class="wd-20 text-truncate">`;
      if(value >= 75) { txt = txt + `<div class="box-right text-center text-uppercase" title='${value}%'>${value}%</div>`; }
      else if(value >= 50) { txt = txt + `<div class="box-right boxM text-center text-uppercase" title='${value}%'>${value}%</div>`; }
      else { txt = txt + `<div class="box-right boxL text-center text-uppercase" title='${value}%'>${value}%</div>`; }
      txt = txt + `</div></div>`;
      return txt;
  } else {
      return `<div class="mr-left-0 wd-cell"><div class="wd-20 text-truncate" title="${value}">${value === null || value === undefined ? '' : value}</div></div>`
  }
};

const deleteFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => { 
      const cssClass = dataContext.role_name =='dq_rule_admin' ? 'deleteRow':'deleteRow-disabled';
      return `<div id="row_${row}" class="${cssClass}"><i class="fa-light fa-trash-can"></i></div>`
};

const actionsFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return !dataContext.shouldDisable
    ? `<span class="text-right pointer context-menu"><i class="fal fa-ellipsis-v fa-2x" aria-hidden="true"></i></span>`
    : ``;
};
@Component({
  selector: 'zetta-data-quality-rules',
  templateUrl: './data-quality-rules.component.html',
  styleUrls: ['./data-quality-rules.component.scss'],
})
export class DataQualityRulesComponent implements OnInit, OnDestroy {

  progressState = new ProjectStatus();
  eventData: any;
  // tableSettings: object = { 'height': "100%", 'width': "100%" };
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 30, 'isNormalPagination': false };
  noData = false;
  loggedInUserDetails: [];
  selectedFiles: string[] = [];
  totalView = 0;
  grandTotal = 0;
  subTotal = '';
  totalPages = 0;
  viewMode;   //business, technical
  dqType: String;
  tableColDef: any;
  tableColTechDef: any;
  gridViewOptions: any;
  gridOptions: any;
  leftGrid: AngularGridInstance;
  page = 1;
  limit = 100;
  btnModifyDisabled = true;
  ruleNameSelected;
  rule_id = 0;
  golden_rule_id: string;
  dataQualityRules: any = [];
  hasScrolled = false;
  isDataReady = false;
  selectedRows = [];
  catalog_id:string;
  object_id:string;
  concept_id:string;
  dq_rule_level:string;
  selectedRules = [];
  is_rule_selected=false;
  activeLink:string;
  defaultView: any = false;
  isEditRuleView;
  activeNavLnkBusiness;
  payloadRuleDefination;
  payloadTechnicalRuleDefination;
  singleRuleDelete=[];
  isRuleEdittable;
  multiSchedule = false;
  golden_record: string;
  roleName = '';
  dataset_id;
  dataset_column_id;
  msgHeader:string;
  msgText:string;    
  showConfirmBox:boolean;
  hasDeleted:boolean;
  rules_to_be_delete=[];
  newRuleSuccess; 
  showRuleType=true;
  isRuleInValid=false;
  showValidationModel=false;
  leftPos="63%";
  topPos= "17%";
  dataQualityMenuSelected: Boolean = false;
  isFiltering = false;
  isSorting = false;
  dataRes:any;
  is_upload_complete = false;
  clearModal: Number;
  dqRuleRrrorDetails:string;
  changeModelIndex=false;
  dqRuleErrorMessage:string;
  isRuleAdmin = false;
  isRuleExecuter = false;
  fu_type_dqRules: any;

  constructor(private catalogSvc: ZsClContentService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zettaUtils: ZettaUtils,
              private baseService: BaseService,
              private messageSvc: MessageService,
              private datasetService: DatasetService,
              public _observableService: ObservableService) { }

  ngOnInit() {
    this.fu_type_dqRules = AppGlobals.FU_TYPE_DQRYLES;
    sessionStorage.setItem('isEditRuleView', 'false');
    this.newRuleSuccess = sessionStorage.getItem('newRuleSuccess') == 'true' ? 'true' : 'false'; 
    if(this.newRuleSuccess == 'true') { 
      this.messageSvc.sendMessage({ message: "New rule created successfully", type: 'SUCCESS', hideboard: true }); 
      sessionStorage.removeItem('newRuleSuccess'); 
    } 
    this.isEditRuleView = false;
    this.page = 1;
    const mode = sessionStorage.getItem('viewMode');
    this.catalogSvc.isEditView.subscribe(res => {this.isEditRuleView = res;})
    if(!mode) {
      this.viewMode = 'business';
    } else {
      this.viewMode = mode;
    }
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    sessionStorage.removeItem('resetpage_filter');
    this.progressState.states = this.zettaUtils.getStateList('create-bussiness-rules');
    this.progressState.currentStateIndex = 1;
    this.progressState.type = 'create-bussiness-rules';
    this.progressState.type = 'classifySet';  
    
    this.catalogSvc.showActiveLink.subscribe(activeLink=>{
     this.activeLink = activeLink;
    });
    this.catalogSvc.defaultView.subscribe(view=>{
      this.defaultView = view;
        if(this.defaultView == true) {
          this.changeView(this.viewMode);
        }
     });
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.catalog_id = params.get('catalog_id');
      this.object_id = params.get('object_id');
      this.concept_id = params.get('concept_id');
      this.dq_rule_level = params.get('dqRuleLevel');
      this.dataset_id = params.get('dataset_id');
      this.golden_record = params.get('golden_record');
      this.dataset_column_id = params.get('dataset_column_id');
      if (this.dq_rule_level) {
        this.showRuleType = false;
        this.dqType = this.dq_rule_level;
      }
    });
    if(this.dq_rule_level=='concept' ){
      this.dataQualityRules=[];
     if(this.leftGrid){
      this.leftGrid.dataView.setItems(this.dataQualityRules);
     } 
     this.dataQualityMenuSelected = false;
      this.getRulesByConcept(this.page,this.limit)
    } else if (this.dq_rule_level == 'dataset') {
      this.dataQualityMenuSelected = false;
      this.dataQualityRules = [];
      if (this.leftGrid) {
        this.leftGrid.dataView.setItems(this.dataQualityRules);
      }
      this.getDataQualityRulesByDatset(this.page, this.limit);
    } else if (this.dq_rule_level == 'catalog') {
      this.dataQualityMenuSelected = false;
      this.dataQualityRules = [];
      if (this.leftGrid) {
        this.leftGrid.dataView.setItems(this.dataQualityRules);
      }
      this.getRulesByCatalog(this.page, this.limit);
    } else if (this.dq_rule_level == 'object') {
      this.dataQualityMenuSelected = false;
      this.dataQualityRules = [];
      if (this.leftGrid) {
        this.leftGrid.dataView.setItems(this.dataQualityRules);
      }
      this.getRulesBySemanticObject(this.page, this.limit);
    }
    else if (this.golden_record) {
      this.dataQualityMenuSelected = false;
      this.dataQualityRules = [];
      this.activatedRoute.queryParamMap.subscribe(params => {
        if(params['params'] && params['params']['rule_id'] && params['params']['rule_type']) {
          this.golden_rule_id = params['params']['rule_id'];
          this.viewMode = params['params']['rule_type'];
          sessionStorage.setItem('viewMode', this.viewMode);
          this.dqType = this.viewMode;
          this.router.navigate(['/zs-cl/dataquality/' + this.golden_rule_id + '/' + this.viewMode + '/rule-definition'], { queryParams: { rule_id: this.golden_rule_id } });
          this.getDataQualityRules(this.page, this.limit);
        }
      });
      setTimeout(() => {
        this.getRuleByID(this.golden_rule_id, this.dqType);
      }, 1500);
    }
    else{
     
      this.dataQualityMenuSelected = true;
      if(!this.dq_rule_level){
        this.router.navigate(['/zs-cl/dataquality/'+  this.rule_id + '/' + this.viewMode + '/rule-definition'], {queryParams: {rule_id: this.rule_id, dataset_id: this.dataset_id}});
      }      
      this.getDataQualityRules(this.page,this.limit);
      this.dqType = this.viewMode;

    }
    this.gridViewOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,     
      enableCellNavigation: true,
      multiSelectable: true,
      rowHeight: 30,
      enableRowSelection:true,
      noDataMsg: DATA_MSG,
     editable: false,
      autoEdit: false,
    };

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
    };
    this.updateColumnDef(this.viewMode);
  }
  changeView(viewMode) {
    if(this.isDataReady) {
      this.catalogSvc.defaultView.next(false);
      this.viewMode = viewMode;
      this.ruleNameSelected = null;
      sessionStorage.setItem('viewMode', viewMode);
      this.rule_id = 0;
      this.catalogSvc.showActiveLink.next('');
      this.dqType = this.viewMode;
      setTimeout(() => {
        this.dataQualityRules = [];
        if (this.leftGrid) {
          this.leftGrid.dataView.refresh();
          this.leftGrid.dataView.setItems(this.dataQualityRules);
        }
        this.ngOnInit();
      }, 500);
    }
    
  }

  changeIsEditRuleView(isEditRuleView) {
    this.showValidationModel=false;
    sessionStorage.setItem('isEditRuleView', isEditRuleView);
    this.catalogSvc.isEditView.next(isEditRuleView);
    this.isEditRuleView = isEditRuleView;

    
  }

  // getDataQualityRules(pageNo, limit = 1) {
  //   this.catalogSvc.getDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.viewMode).subscribe(respT => {
  //     this.dataQualityRules = respT["currentpage"];
  //     if (this.dataQualityRules.length === 0) {
  //         this.noData = true;
  //     }      
  //     this.totalView = respT["totalrecords"];
  //     // if (this.leftGrid) {
  //         this.dataQualityRules.forEach(element => {
  //           element.id = Math.random();
  //           element.measured_dq_score = element.measured_data_quality; 
  //           element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
  //           element.shouldDisable = element.role_name =='dq_rule_admin' ? false : true;
  //           this.viewMode === 'technical' ? element.rule_id = element.data_quality_rule_id : '';
  //         });
  //     // }
  //     this.isDataReady = true;
  //     // let concatedRowsT = this.dataQualityRules;
  //     if (this.leftGrid) {
  //       // concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
  //       // this.dataQualityRules = concatedRowsT;
  //       // this.leftGrid.dataView.setItems(concatedRowsT);
  //       this.leftGrid.dataView.setItems(this.dataQualityRules);
  //       this.leftGrid.gridService.setSelectedRows([]);
  //     }
  //     this.hasScrolled = false;
  //   }, err => {
  //     this.isDataReady = true;
  //     this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
  //   });
  // }

  getDataQualityRules(pageNo, limit = 1) {
    if(pageNo==1) {
      this.isDataReady = false;
    }
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules?pagination=true&type=${this.viewMode}&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('rule_id=') ? `&${filter}` : ''
                          || filter.includes('data_quality_rule_id=') ? `&${filter}` : ''
                          || filter.includes('rule_name=') ? `&${filter}` : ''
                          || filter.includes('dq_dimension=') ? `&${filter}` : ''
                          || filter.includes('frequency=') ? `&${filter}` : '' 
                          || filter.includes('time=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=rule_id') ? `&${sort}` : ''
                           || sort.includes('orderby=data_quality_rule_id') ? `&${sort}` : '' 
                           || sort.includes('orderby=rule_name') ? `&${sort}` : ''
                           || sort.includes('orderby=dq_dimension') ? `&${sort}` : '' 
                           || sort.includes('orderby=measured_data_quality') ? `&${sort}` : ''
                           || sort.includes('orderby=frequency') ? `&${sort}` : ''
                           || sort.includes('orderby=time') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += (sort.includes('type=asc') || sort.includes('orderType=asc')) ? `&orderType=asc` : ''
                             || (sort.includes('type=desc')|| sort.includes('orderType=desc')) ? `&orderType=desc` : '';
            }
          }
        });
      }
    }
    this.catalogSvc.getDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id, this.viewMode, pageNo, limit, appliedFilter, appliedSorting).subscribe(respT => {
      this.dataQualityRules = respT["currentpage"];
      if (this.dataQualityRules.length === 0) {
          this.noData = true;
      } else {
        this.noData = false;
      }     
      this.totalView = respT["totalrecords"];
      if (pageNo === 1) {
        this.grandTotal = respT['totalrecords'];
      }
      this.totalPages = respT["totalpages"];
      if (!this.dataset_id || this.dataset_id == null || this.dataset_id == 'null') {
          this.dataQualityRules.forEach((element,i) => {
            element.id = Math.random();
            element.measured_dq_score = element.measured_data_quality; 
            element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
            element.shouldDisable = element.role_name =='dq_rule_admin' ? false : true;
            this.viewMode === 'technical' ? element.rule_id = element.data_quality_rule_id :element.data_quality_rule_id = element.rule_id;
          });
      } else {
          this.dataQualityRules.forEach((element,i) => {
            element.id = Math.random();
            element.measured_dq_score = element.measured_dq_score; 
            element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
            element.shouldDisable = element.role_name =='dq_rule_admin' ? false : true;
            element.data_quality_rule_id = element.rule_id;
            //element.measured_data_quality = element.measured_dq_score;
            element.measured_dq_score = element.measured_data_quality;
            element.dq_dimension = element.rule_dimension;
          });
      }

      this.isDataReady = true;
      let concatedRowsT = this.dataQualityRules;
      if (this.leftGrid) {
        concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
        this.dataQualityRules = concatedRowsT;
        this.leftGrid.dataView.setItems(concatedRowsT);
        // this.leftGrid.dataView.setItems(this.dataQualityRules);
        this.leftGrid.gridService.setSelectedRows([]);
        if (pageNo < respT['totalpages']) {
          this.hasScrolled = false;
        }
      
        if (pageNo > respT['totalpages']) {
            this.page = 1;
          this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/dataquality/rules?pagination=true&type=${this.viewMode}&pageno=${1}&pagesize=${limit}`
        }
      }
      // this.hasScrolled = false;
    }, err => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  getDataQualityRulesByDatset(pageNo, limit = 1) {
    if (this.dataset_column_id) {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/columns/${this.dataset_column_id}/dqrules?pagination=true&pageno=${1}&pagesize=${limit}`;
		} else {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/datasets/${this.dataset_id}/dqrules?pagination=true&pageno=${1}&pagesize=${limit}`;
		}
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('rule_id=') ? `&${filter}` : ''
                          || filter.includes('data_quality_rule_id=') ? `&${filter}` : ''
                          || filter.includes('rule_name=') ? `&${filter}` : ''
                          || filter.includes('rule_dimension=') ? `&${filter}` : ''
                          || filter.includes('frequency=') ? `&${filter}` : '' 
                          || filter.includes('measured_dq_score=') ? `&${filter}` : ''
                          || filter.includes('time=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=rule_id') ? `&${sort}` : ''
                           || sort.includes('orderby=data_quality_rule_id') ? `&${sort}` : '' 
                           || sort.includes('orderby=rule_name') ? `&${sort}` : ''
                           || sort.includes('orderby=rule_dimension') ? `&${sort}` : '' 
                           || sort.includes('orderby=measured_dq_score') ? `&${sort}` : ''
                           || sort.includes('orderby=frequency') ? `&${sort}` : ''
                           || sort.includes('orderby=time') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += (sort.includes('type=asc') || sort.includes('orderType=asc')) ? `&orderType=asc` : ''
                             || (sort.includes('type=desc')|| sort.includes('orderType=desc')) ? `&orderType=desc` : '';
            }
          }
        });
      }
    }
    this.catalogSvc.getDQDatasetRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.dataset_id, this.dataset_column_id, pageNo, limit, appliedFilter, appliedSorting).subscribe(respT => {
      this.dataQualityRules = respT['currentpage'];
      if (this.dataQualityRules.length === 0) {
        this.noData = true;
      }
      this.totalView = respT['totalrecords'];
      if (pageNo === 1) {
        this.grandTotal = respT['totalrecords'];
      }
      this.dataQualityRules.forEach(element => {
        element.id = Math.random();
        // element.measured_dq_score = element.measured_dq_score;
        element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
        element.shouldDisable = element.role_name == 'dq_rule_admin' ? false : true;
        element.data_quality_rule_id = element.rule_id;
        element.measured_data_quality = element.measured_dq_score;
        element.rule_dimension = element.rule_dimension;
      });
      this.isDataReady = true;
      let concatedRowsT = this.dataQualityRules;
      if (this.leftGrid) {
        concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
        this.dataQualityRules = concatedRowsT;
        this.leftGrid.dataView.setItems(concatedRowsT);
        this.leftGrid.gridService.setSelectedRows([]);
      }
      if(pageNo < respT['totalpages']) {
        this.hasScrolled = false;
      }
    }, err => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
  }

  getRulesByCatalog(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/dataquality/rules?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('rule_id=') ? `&${filter}` : ''
                          || filter.includes('data_quality_rule_id=') ? `&${filter}` : ''
                          || filter.includes('rule_name=') ? `&${filter}` : ''
                          || filter.includes('rule_dimension=') ? `&${filter}` : ''
                          || filter.includes('quality_score=') ? `&${filter}` : '' 
                          || filter.includes('frequency=') ? `&${filter}` : '' 
                          || filter.includes('time=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=rule_id') ? `&${sort}` : ''
                           || sort.includes('orderby=data_quality_rule_id') ? `&${sort}` : '' 
                           || sort.includes('orderby=rule_name') ? `&${sort}` : ''
                           || sort.includes('orderby=rule_dimension') ? `&${sort}` : '' 
                           || sort.includes('orderby=quality_score') ? `&${sort}` : ''
                           || sort.includes('orderby=frequency') ? `&${sort}` : ''
                           || sort.includes('orderby=time') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += (sort.includes('type=asc') || sort.includes('orderType=asc')) ? `&orderType=asc` : ''
                             || (sort.includes('type=desc')|| sort.includes('orderType=desc')) ? `&orderType=desc` : '';
            }
          }
        });
      }
    }
    this.catalogSvc.getRulesByCatalog(this.catalog_id, page, limit, appliedFilter, appliedSorting).subscribe(respList => {
      if (respList["currentpage"]) {
        this.dataQualityRules = respList["currentpage"]
        this.dataQualityRules.forEach(element => {
          element.id = Math.random();
          // element.measured_dq_score = element.quality_score;
          // element.measured_data_quality = element.measured_dq_score;
          element.measured_dq_score = element.quality_score;
          element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
          element.rule_dimension = element.rule_dimension;
          element.shouldDisable = element.role_name == 'dq_rule_admin' ? false : true;
          element.disableMr = false;
        });
        this.totalView = respList.totalrecords;
        if (page === 1) {
          this.grandTotal = respList['totalrecords'];
        }
        let concatedRowsT = this.dataQualityRules;
        this.isDataReady = true;
        if (this.leftGrid) {
          concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
          this.dataQualityRules = concatedRowsT;
          this.leftGrid.dataView.setItems(this.dataQualityRules);
          this.leftGrid.gridService.setSelectedRows([]);
        }
        if(page < respList['totalpages']) {
          this.hasScrolled = false;
        }
      }
    }, error => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  getRulesBySemanticObject(page, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/dataquality/rules?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('rule_id=') ? `&${filter}` : ''
                          || filter.includes('data_quality_rule_id=') ? `&${filter}` : ''
                          || filter.includes('rule_name=') ? `&${filter}` : ''
                          || filter.includes('rule_dimension=') ? `&${filter}` : ''
                          || filter.includes('quality_score=') ? `&${filter}` : '' 
                          || filter.includes('frequency=') ? `&${filter}` : '' 
                          || filter.includes('time=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=rule_id') ? `&${sort}` : ''
                           || sort.includes('orderby=data_quality_rule_id') ? `&${sort}` : '' 
                           || sort.includes('orderby=rule_name') ? `&${sort}` : ''
                           || sort.includes('orderby=rule_dimension') ? `&${sort}` : '' 
                           || sort.includes('orderby=quality_score') ? `&${sort}` : ''
                           || sort.includes('orderby=frequency') ? `&${sort}` : ''
                           || sort.includes('orderby=time') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += (sort.includes('type=asc') || sort.includes('orderType=asc')) ? `&orderType=asc` : ''
                             || (sort.includes('type=desc')|| sort.includes('orderType=desc')) ? `&orderType=desc` : '';
            }
          }
        });
      }
    }

    this.catalogSvc.getDQRulesSemanticLevel(this.catalog_id, this.object_id, page, limit, appliedFilter, appliedSorting).subscribe(respList => {
      if (respList["currentpage"]) {
        this.dataQualityRules = respList["currentpage"]
        this.dataQualityRules.forEach(element => {
          element.id = Math.random();
          element.measured_dq_score = element.quality_score;
          // element.measured_data_quality = element.quality_score;
          element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
          element.shouldDisable = element.role_name == 'dq_rule_admin' ? false : true;
          element.rule_dimension = element.rule_dimension;
          element.disableMr = false;
        });
        this.totalView = respList.totalrecords;
        if (page === 1) {
          this.grandTotal = respList['totalrecords'];
        }
        let concatedRowsT = this.dataQualityRules;
        this.isDataReady = true;
        if (this.leftGrid) {
          concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
          this.dataQualityRules = concatedRowsT;
          this.leftGrid.dataView.setItems(this.dataQualityRules);
          this.leftGrid.gridService.setSelectedRows([]);
        }
        if(page < respList['totalpages']) {
          this.hasScrolled = false;
        }
      }
    }, error => {
      this.isDataReady = true;
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }

  getRulesByConcept(page, limit){
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.catalog_id}/semantics/${this.object_id}/concepts/${this.concept_id}/dataquality/rules?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    let serverSort = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    if (sessionStorage.getItem("sortfilter")) {
      serverSort = sessionStorage.getItem("sortfilter");
    }
    let appliedFilter = "";
    let appliedSorting = "";
    if (serverFilter) {
      let filterKeys = serverFilter.split("&");
      if (filterKeys.length) {
        filterKeys.forEach(filter => {
          if (filter) {
            appliedFilter += filter.includes('rule_id=') ? `&${filter}` : ''
                          || filter.includes('data_quality_rule_id=') ? `&${filter}` : ''
                          || filter.includes('rule_name=') ? `&${filter}` : ''
                          || filter.includes('rule_dimension=') ? `&${filter}` : ''
                          || filter.includes('quality_score=') ? `&${filter}` : '' 
                          || filter.includes('frequency=') ? `&${filter}` : '' 
                          || filter.includes('time=') ? `&${filter}` : '';
          }
        });
      }
    }

    if (serverSort) {
      let sortKeys = serverSort.split("&");
      if (sortKeys.length) {
        sortKeys.forEach(sort => {
          if (sort) {
            appliedSorting += sort.includes('orderby=rule_id') ? `&${sort}` : ''
                           || sort.includes('orderby=data_quality_rule_id') ? `&${sort}` : '' 
                           || sort.includes('orderby=rule_name') ? `&${sort}` : ''
                           || sort.includes('orderby=rule_dimension') ? `&${sort}` : '' 
                           || sort.includes('orderby=quality_score') ? `&${sort}` : ''
                           || sort.includes('orderby=frequency') ? `&${sort}` : ''
                           || sort.includes('orderby=time') ? `&${sort}` : ''
            if (appliedSorting) {
              appliedSorting += (sort.includes('type=asc') || sort.includes('orderType=asc')) ? `&orderType=asc` : ''
                             || (sort.includes('type=desc')|| sort.includes('orderType=desc')) ? `&orderType=desc` : '';
            }
          }
        });
      }
    }
    this.catalogSvc.getRulesByConcept(this.catalog_id,this.object_id,this.concept_id, page, limit, appliedFilter, appliedSorting).subscribe(respList=>{
       if(respList["currentpage"]){
           this.dataQualityRules = respList["currentpage"]
           this.dataQualityRules.forEach(element => {
            element.id = Math.random();
            element.measured_dq_score = element.quality_score;
            // element.measured_data_quality = element.measured_dq_score;    

            element.isRuleEnabled = this.loggedInUserDetails["enable_dq_rule"];
            element.shouldDisable = element.role_name == 'dq_rule_admin' ? false : true;
            element.rule_dimension = element.rule_dimension;
            element.disableMr = false;
          });
          this.totalView= respList.totalrecords;
          if (page === 1) {
            this.grandTotal = respList['totalrecords'];
          }
          let concatedRowsT = this.dataQualityRules ;
          this.isDataReady = true;
          if (this.leftGrid) {
            concatedRowsT = [...this.leftGrid.dataView.getItems(), ...this.dataQualityRules];
            this.dataQualityRules = concatedRowsT;
            this.leftGrid.dataView.setItems(this.dataQualityRules);
            this.leftGrid.gridService.setSelectedRows([]);
          }
          if(page < respList['totalpages']) {
            this.hasScrolled = false;
          }
       }
   },error=>{
    this.isDataReady = true;
    this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
   });
  }
  handleObjectGridClickEvent(eventData) {
    const parent = this;
    parent.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const metadata = parent.leftGrid.gridService.getColumnFromEventArguments(args);
    const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    if (row !== undefined && row !== null) { 
      if (event.target.className.includes('blueLink') && fieldName === 'rule_name') { 
        this.isRuleInValid  = row.is_errored;
        this.showValidationModel =  false;
        this.isRuleAdmin = row.is_dq_rule_admin;
        this.isRuleExecuter = row.is_dq_rule_executor;
        this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], row.rule_id).subscribe(response => {
          if (response.is_errored) {
            this.dqRuleRrrorDetails = response.error_details;
            const errorMsg = response.error_message;
            this.dqRuleErrorMessage = errorMsg.length > 100 ? errorMsg.slice(0, 100) + '...' : errorMsg;
            this.changeModelIndex = response.is_errored;
          }
        });
        const existingClass = $(".left-grid-row-selected");
        if(existingClass) {
          existingClass.removeClass("left-grid-row-selected");
        }
        event.target.parentElement.parentElement.parentElement.classList.add("left-grid-row-selected");
        sessionStorage.setItem('activeNavLnkBusiness', 'rule-definition');
        sessionStorage.setItem('activeNavLnkTechnical', 'rule-definition');
        parent.is_rule_selected = true;
        this.catalogSvc.showActiveLink.next("rule-definition");
        parent.ruleNameSelected = row.rule_name;
        parent.roleName = row.role_name;
        parent.isRuleEdittable = row.user_id == this.loggedInUserDetails['user_id'] && row.role_name === 'dq_rule_admin';
        this.catalogSvc.isRuleEdittable.next(parent.isRuleEdittable);
        this.catalogSvc.isEditView.next(false);
        parent.rule_id = parent.viewMode === 'business' ? row.rule_id : row.data_quality_rule_id ;
        if (this.catalog_id && this.object_id && this.concept_id) {
          parent.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/' +
            this.concept_id + '/dq-rules/manage-rules/' + parent.rule_id],
            { queryParams: { rule_id: parent.rule_id }, queryParamsHandling: 'merge' },
          );
        } else if (this.catalog_id && this.object_id && !this.concept_id) {
          parent.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + 
          '/dq-rule/manage-rules/' + parent.rule_id],
            { queryParams: { rule_id: parent.rule_id }, queryParamsHandling: 'merge' },
          );
        } else if (this.catalog_id && !this.object_id && !this.concept_id) {
          parent.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + 
          '/dq-rule/manage-rules/' + parent.rule_id],
            { queryParams: { rule_id: parent.rule_id }, queryParamsHandling: 'merge' },
          );
        } else if (this.dataset_id || this.dataset_column_id) {
          let ruleType = row.rule_type_lookup_id;
          if ( ruleType === AppGlobals.BUSINESS_LEVEL_RULE ) {
            this.viewMode = 'business';
            sessionStorage.setItem('viewMode', 'business');
          } else {
            this.viewMode = 'technical';
            sessionStorage.setItem('viewMode', 'technical');
          }
          if(this.dataset_id && !this.dataset_column_id){
            parent.router.navigate(['/zs/datasets/' + this.dataset_id + '/manage-rules/' + parent.rule_id], { queryParams: { rule_id: row.rule_id }, queryParamsHandling: 'merge' } );
          }
          if (this.dataset_id && this.dataset_column_id) {
            parent.router.navigate(['/zs/datasets/' + this.dataset_id + '/columns/' + this.dataset_column_id + '/manage-rules/' + parent.rule_id], { queryParams: { rule_id: row.rule_id }, queryParamsHandling: 'merge' });
          }
        } else {      
          parent.router.navigate(['/zs-cl/dataquality/' + parent.rule_id + '/' + this.viewMode + '/rule-definition'], { queryParams: { rule_id: parent.rule_id, dataset_id: parent.dataset_id } });
        }
        // sessionStorage.setItem('activeNavLnkBusiness', 'rule-definition');
      } else if (fieldName === 'actions') {
        this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], row.rule_id ? row.rule_id : row.data_quality_rule_id).subscribe(response => {
          sessionStorage.setItem('ruleInfo', JSON.stringify(response));
        });
      }

      if (event.target.className.includes('fa-trash-can')) {
        this.singleRuleDelete = [];
        if (row.role_name == 'dq_rule_admin') {
          const rule_id = row.rule_id ? row.rule_id : row.data_quality_rule_id;
          this.singleRuleDelete.push(rule_id);
          this.rules_to_be_delete = this.singleRuleDelete;
          this.showConfirmBox = true;
          this.msgHeader = "This will delete the rule although the history will be preserved.";
          this.msgText = "Are you sure that you want to delete this Rules ?";
        }
      }
    }
  }

  onDeteleRules() {
    const leftGridDataService = this.leftGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const rows = [];
    const ruleIds = [];
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
        if(row.data_quality_rule_id)
        {
          ruleIds.push(row.data_quality_rule_id);
        }  
        else{
          ruleIds.push(row.rule_id);
        }     
      }
    });
    this.rules_to_be_delete = ruleIds;
    this.msgHeader = `You are about to delete ${rows.length} selected rules.`;
    this.msgText = 'Are you sure that you want to delete these Rules ?';
    this.showConfirmBox = true;
  }

  updateRuleDefinationPayload(payload) {    
    this.payloadRuleDefination = payload;
    return this.payloadRuleDefination
  }

  updateTechnicalRuleDefinationPayload(payload) {
    this.payloadTechnicalRuleDefination = payload;
    return this.payloadTechnicalRuleDefination;
  }

  updateRuleDefination() {
    if (this.viewMode === 'technical') {      
      this.catalogSvc.updateTechnicalDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, this.payloadTechnicalRuleDefination).subscribe(response => {
      }, err => {
      });
    } else {
      this.catalogSvc.updateBusinessDataQualityRules(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.rule_id, this.payloadRuleDefination).subscribe(response => {
      }, err => {
      });
    }
  }

  deleteRule() {

    const payload = {
      dq_rules_ids: this.rules_to_be_delete
    };
      this.singleRuleDelete=[];
      this.rules_to_be_delete=[];
      this.catalogSvc.deleteDataQualityRule(payload).subscribe(resp => {
      this.dataQualityRules=[]
      this.clearText(resp);
      if (this.leftGrid) {
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('isEditRuleView');
        this.ruleNameSelected = null;
        this.rule_id = 0;
        this.catalogSvc.showActiveLink.next('');
        this.leftGrid.dataView.setItems(this.dataQualityRules);
      }
      this.page=1;
      this.getDataQualityRules(this.page, this.limit);
         
      },error=>{
         this.singleRuleDelete = [];
         this.rules_to_be_delete=[];
    });
  }
  dataQualityRulesLeftGrid(grid: AngularGridInstance) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.btnModifyDisabled = args.rows.length > 0 ? false : true;
      const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.rows);
      parent.selectedRules = [];
      if(args.rows && args.rows.length && args.rows.length>0 ) {
      const leftGridDataService = parent.leftGrid.gridService;
      const selectedRows: number[] = leftGridDataService.getSelectedRows();
      selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
        if (row !== undefined && row !== null) {
          parent.selectedRules.push(row.rule_id ? row.rule_id : row.data_quality_rule_id);
        }
      });
    }
    });



    parent.leftGrid.slickGrid.onSort.subscribe((e, args) => {
      parent.page = 1;
      parent.hasScrolled = false;
      // const firstRow = leftGrid.gridService.getDataItemByRowIndex(0);
      // setTimeout(() => {
      //     grid.gridService.deleteDataGridItem(firstRow);
      //     const currentData = leftGrid.dataView.getItems();
      //     currentData.splice(0, 0, firstRow);
      //     leftGrid.dataView.setItems(currentData);
      //     leftGrid.gridService.setSelectedRows([]);
      // }, 500);
    });



  }

  onScrollView(parentClass) {

    if (sessionStorage.getItem("serverfilter")) {
      this.isFiltering = true;
      this.noData = false;
    } else {
      if (sessionStorage.getItem("sortfilter")) {
        this.isSorting = true
      }
      if (this.isSorting) {
        this.isSorting = false;
        this.noData = false;
      }
      if (this.isFiltering) {
        this.page = 1;
        this.isFiltering = false;
        this.noData = false;
      }
    }
    //Apply Filter and remove the appied filter.Resetting the page to 1
    if (sessionStorage.getItem("resetpage_filter")) {
      this.page = 1;
      this.hasScrolled = false;
      sessionStorage.removeItem("resetpage_filter");
    }
    if (!this.hasScrolled && this.zettaUtils.virtualScroll(parentClass) && !this.noData && this.page<this.totalPages) {
        this.hasScrolled = true;
        this.onNextPageView();  
    }
  }

  onNextPageView(): void {
    this.page++;
    if (this.dq_rule_level == 'concept') {
      this.getRulesByConcept(this.page, this.limit)
    } else if (this.dq_rule_level == 'dataset') {
      this.getDataQualityRulesByDatset(this.page, this.limit);
    } else if (this.dq_rule_level == 'catalog') {
      this.getRulesByCatalog(this.page, this.limit);
    } else if (this.dq_rule_level == 'object') {
      this.getRulesBySemanticObject(this.page, this.limit);
    }
    else {
      this.getDataQualityRules(this.page, this.limit);
    }
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
    this.baseService.showConfirmation.unsubscribe();
    this._observableService.unsubscribe();
    sessionStorage.removeItem('activeNavLnkTechnical');
    sessionStorage.removeItem('activeNavLnkBusiness');
  }

  navigateBack() {
    if(this.router.url.includes('create-bussiness-rule')) {
      this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
    }
  }

  navigateToCreate() {
    sessionStorage.removeItem('ruleInfo');
    sessionStorage.removeItem('ruleInfoClone');
    sessionStorage.removeItem('activeNavLnkBusiness');
    sessionStorage.removeItem('activeNavLnkTechnical');
    if(this.viewMode === 'business') {    
      if(this.dq_rule_level=='concept') {
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule/out-of-box-rule'],{queryParamsHandling:'preserve'});
      } else {
        sessionStorage.removeItem('ruleDefintion'); 
        this.router.navigate(['/zs-cl/rules/create-bussiness-rule'],{queryParamsHandling:'preserve'});
      } 
    } else if(this.viewMode === 'technical') {
      sessionStorage.removeItem('tRuleDefintion');       
      if (this.dq_rule_level == 'dataset') {
        this.router.navigate(['/zs-cl/rules/create-technical-rule'], { queryParamsHandling: 'preserve' });
      } else {
        this.router.navigate(['/zs-cl/rules/create-technical-rule']);
      }
    }
  }

  navigateToAddUsers() {
    // this.selectedRules = [];
    const leftGridDataService = this.leftGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        this.selectedRules.push(row.rule_id);
      }
    });
    if(this.viewMode === 'business') {
      this.router.navigate(['/zs-cl/rules/create-bussiness-rule/add-users'], {queryParams: {rule_id: this.selectedRules, showBreadCrumb: true, isMultiRules: true}});
    } else if(this.viewMode === 'technical'){
      this.router.navigate(['/zs-cl/rules/create-technical-rule/add-users'], {queryParams: {rule_id: this.selectedRules, showBreadCrumb: true,isMultiRules: true}});
    }
    this.selectedRules = [];
  }
  
  EditSchedule() {
    const parent = this;
    const leftGridDataService = this.leftGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const rows: any[] = [];
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
          rows.push(row);
      }
  });
    this.selectedRows = rows;
    this.multiSchedule = true;
  }

  modelPopupOutput(events) {
    if(events) {
      this.leftGrid.gridService.setSelectedRows([]);
      this.leftGrid.dataView.refresh();
      $('.modal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.multiSchedule = false;
    }
  }

  isScheduleEdit(events) {
    if(events) {      
      setTimeout(() => {
        if (this.leftGrid) {
          this.dataQualityRules = [];
          this.leftGrid.dataView.setItems(this.dataQualityRules);
        }
        this.getDataQualityRules(1,this.limit);
      }, 500);
    }
  }

  hideModal() {
    this.leftGrid.gridService.setSelectedRows([]);
    this.leftGrid.dataView.refresh();
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.multiSchedule = false;
  }
  onReRunRule(){    
    this.catalogSvc.reRunRule(this.rule_id, this.viewMode).subscribe(resp=>{
      this.messageSvc.sendMessage({
        message: '', type: 'Info', hideInfo: true, activeJobs: 'Dataset',
        messageHeader: AppGlobals.RULES_MSG_HEADER, messageText: AppGlobals.RULES_MSG_TEXT,
      });
    });
  }
  onConfirmDelete(event) {
    this.deleteRule();
  }
  clearText(event) {
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }
  receiveTotalRecords($event) {
    this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);    
  }

  receiveFileName(eve) {
    if(eve) {
      this.dataRes = eve;
      this.is_upload_complete = this.dataRes.is_upload_complete;
      if(this.is_upload_complete) {
        this.leftGrid.gridService.setSelectedRows([]);
        this.leftGrid.dataView.refresh();
        if(this.dq_rule_level=='concept' ){
          this.dataQualityRules=[];
         if(this.leftGrid){
          this.leftGrid.dataView.setItems(this.dataQualityRules);
         } 
         this.dataQualityMenuSelected = false;
          this.getRulesByConcept(this.page,this.limit)
        } else if (this.dq_rule_level == 'dataset') {
          this.dataQualityMenuSelected = false;
          this.dataQualityRules = [];
          if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.dataQualityRules);
          }
          this.getDataQualityRulesByDatset(this.page, this.limit);
        } else if (this.dq_rule_level == 'catalog') {
          this.dataQualityMenuSelected = false;
          this.dataQualityRules = [];
          if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.dataQualityRules);
          }
          this.getRulesByCatalog(this.page, this.limit);
        } else if (this.dq_rule_level == 'object') {
          this.dataQualityMenuSelected = false;
          this.dataQualityRules = [];
          if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.dataQualityRules);
          }
          this.getRulesBySemanticObject(this.page, this.limit);
        } else{
          this.dataQualityMenuSelected = true;
          this.dqType = this.viewMode;
          this.dataQualityRules = []
          if (this.leftGrid) {
            this.leftGrid.dataView.setItems(this.dataQualityRules);
          }
          this.getDataQualityRules(this.page,this.limit);
        }
      }
  }
}
uploadDqRules() {
  this.clearModal = Math.random();
}
  updateColumnDef(mode) {
    if(mode == 'business') {
      this.tableColDef = [
        {
          'displayname': '',
          'physicalname': 'actions',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'cssClass': 'text-right',
          'formatter': actionsFormatter,
          'maxWidth': 15,
        }, 
        {
          'displayname': 'Rule ID',
          'physicalname': 'rule_id',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 72,
          'maxWidth': 72,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': columnFormattter,
        },
        {
          'displayname': 'Rule Name',
          'physicalname': 'rule_name',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          // 'minWidth': 100,
          // 'maxWidth': 200,
          'formatter': dqRuleNameFormattter,
        },
        {
          'displayname': 'DQ Dimension',
          'physicalname': this.dataQualityMenuSelected ? 'dq_dimension' : 'rule_dimension',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 100,
          'maxWidth': 110,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': columnFormattter,
        },
        {
          'displayname': 'Measured DQ',
          'physicalname': (this.dq_rule_level == 'object' || this.dq_rule_level == 'concept' || this.dq_rule_level == 'catalog') ? 'quality_score': (this.dq_rule_level == 'dataset'? 'measured_dq_score' : 'measured_data_quality'),
          'sortable': true,
          'datatype': 'String',
          'filterable': false,
          'minWidth': 100,
          'maxWidth': 105,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': measuredDQFormatter,
        },
        {
          'displayname': 'Frequency',
          'physicalname': 'frequency',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 120,
          'maxWidth': 120,
          // 'headerCssClass': 'toRight',
          'formatter': columnFormattter
        },
        {
          'displayname': 'Time',
          'physicalname': 'time',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 60,
          'maxWidth': 60,
          'formatter': columnFormattter
        },
        {
          'displayname': 'Action',
          'physicalname': '',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'minWidth': 45,
          'maxWidth': 45,
          'formatter': deleteFormattter
        }
  
      ];
    } else if(mode == 'technical') {
      this.tableColTechDef = [
        {
          'displayname': '',
          'physicalname': 'actions',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'cssClass': 'text-right',
          'formatter': actionsFormatter,
          'maxWidth': 15,
        }, 
        {
          'displayname': 'Rule ID',
          'physicalname': this.dq_rule_level == 'dataset' ? 'rule_id' : 'data_quality_rule_id',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 60,
          'maxWidth': 60,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': columnFormattter,
        },
        {
          'displayname': 'Rule Name',
          'physicalname': 'rule_name',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          // 'minWidth': 100,
          // 'maxWidth': 200,
          'formatter': dqRuleNameFormattter,
        },
        {
          'displayname': 'DQ Dimension',
          'physicalname': this.dataQualityMenuSelected ? 'dq_dimension' : 'rule_dimension',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 130,
          'maxWidth': 130,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': columnFormattter,
        },
        {
          'displayname': 'Measured DQ',
          'physicalname': (this.dq_rule_level == 'object' || this.dq_rule_level == 'concept' || this.dq_rule_level == 'catalog') ? 'quality_score': (this.dq_rule_level == 'dataset'? 'measured_dq_score' : 'measured_data_quality'),
          'sortable': true,
          'datatype': 'String',
          'filterable': false,
          'minWidth': 120,
          'maxWidth': 120,
          'headerCssClass': 'text-left titleBreak pd-top-10 pb-0 pr-3 toRight',
          'formatter': measuredDQFormatter,
        },
        {
          'displayname': 'Frequency',
          'physicalname': 'frequency',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 120,
          'maxWidth': 120,
          // 'headerCssClass': 'toRight',
          'formatter': columnFormattter
        },
        {
          'displayname': 'Time',
          'physicalname': 'time',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 60,
          'maxWidth': 60,
          'formatter': columnFormattter
        },
        {
          'displayname': 'Action',
          'physicalname': '',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'minWidth': 45,
          'maxWidth': 45,
          'formatter': deleteFormattter
        }
  
      ];
    }
  }
  showRuleError() {
    this.showValidationModel = true;
  }
  cancelRequest(){
    this.showValidationModel = false;
  }  
  showErrorDetails(){
    $('#error_details').modal('show');
    this.dqRuleRrrorDetails = "Error of rule" + this.rule_id;
  }

  openContextMenu(e): void {
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
    // // const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    // this.selectedProjectMetadata = row;

    if (row.isdisable) {
      $('#context_menu').hide();
    } else {
      $('#context_menu')
        .removeClass('d-none')
        .data('row', args.row)
        .css('top', eventEle.pageY)
        .css('left', eventEle.pageX + 5)
        .css('position', 'fixed')
        .css('display', 'block')
        .show();
        if(parent.viewMode === 'business') {
          $('#context_menu').children('li')[0].className = row.is_default_rule ? 'disable-option' : '';
        }
    }

    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').off('click').on('click', function (ee) {
      if (!($(ee.target).is('span') || $(ee.target).is('li'))) {
        return;
      }
      if (!parent.leftGrid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(ee.target).attr('data');
      if (action === 'Clone') {
        if(!row.is_default_rule) {
          sessionStorage.removeItem('activeNavLnkBusiness');
          sessionStorage.removeItem('activeNavLnkTechnical');
          parent.cloneRow(row);
        }
      }
    });
  }

  cancelUpload() {
    this.clearModal = Math.random();
    $('#uploadFileModal').modal('hide');
  }

  cloneRow(rule) {
    const parent = this;
    parent.catalogSvc.cloneDQRule(parent.loggedInUserDetails['user_id'], parent.loggedInUserDetails['tenant_id'], rule.data_quality_rule_id ? rule.data_quality_rule_id : rule.rule_id).subscribe(response => {
      if(response.length){
        sessionStorage.setItem('ruleInfoClone', JSON.stringify(response[0]));
        let data_quality_rule_id = response[0].data_quality_rule_id;
        if(parent.viewMode === 'business') {    
          parent.router.navigate(['/zs-cl/rules/create-bussiness-rule'], {queryParams: {rule_id: data_quality_rule_id, is_clone: true }});
        } else if(parent.viewMode === 'technical'){
          parent.router.navigate(['/zs-cl/rules/create-technical-rule'], {queryParams: {rule_id: data_quality_rule_id, is_clone: true }});
        }
      }
    }, err => {
      parent.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
    });
  }
  
  runBulkRules() {
    const leftGridDataService = this.leftGrid.gridService;
    const selectedRows: number[] = leftGridDataService.getSelectedRows();
    const ruleIds = [];
    selectedRows.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        const rule_id = row.data_quality_rule_id || row.rule_id;
        ruleIds.push({ data_quality_rule_id: rule_id });
      }
    });
    let payload = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      rule_type: this.viewMode,
      rules: ruleIds
    };
    this.catalogSvc.runBulkDQRule(payload).subscribe(resp => {
      this.messageSvc.sendMessage({
        message: '', type: 'Info', hideInfo: true, activeJobs: 'DataQuality',
        messageHeader: AppGlobals.BULKRULES_MSG_HEADER, messageText: AppGlobals.BUILRULES_MSG_TEXT,
      });
      this.leftGrid.gridService.setSelectedRows([]);
    }, error => {
      this.messageSvc.sendMessage({ message: error.error.error, type: 'ERROR', hideboard: true });
    });
  }

  openModalImport(modal) {
    $('#' + modal).modal('show');
  }

  getRuleByID(ruleId, ruleType) {
    this.catalogSvc.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], ruleId).subscribe(response => {
      if (response.is_errored) {
        this.dqRuleRrrorDetails = response.error_details;
        const errorMsg = response.error_message;
        this.dqRuleErrorMessage = errorMsg.length > 100 ? errorMsg.slice(0, 100) + '...' : errorMsg;
        this.changeModelIndex = response.is_errored;
      }
      if(response) {
        sessionStorage.removeItem('activeNavLnkBusiness');
        sessionStorage.removeItem('activeNavLnkTechnical');
        this.ruleNameSelected = response.rule_name;
        this.roleName = response['entitlement'][0].role_name;
        this.isRuleExecuter = response['run_executor'];
        this.isRuleEdittable = response['entitlement'][0].user_id == this.loggedInUserDetails['user_id'] && response['entitlement'][0].role_name === 'dq_rule_admin';
        this.catalogSvc.isRuleEdittable.next(this.isRuleEdittable);
        this.viewMode = ruleType;
        this.rule_id = ruleId;
        this.isRuleInValid = response.is_errored;
        this.catalogSvc.isEditView.next(false);
        if(ruleType == 'business') {
          sessionStorage.setItem('activeNavLnkBusiness', this.activeLink);
        } else if(ruleType == 'technical') {
          sessionStorage.setItem('activeNavLnkTechnical', this.activeLink);
        }
        this.catalogSvc.defaultView.subscribe(view=>{
          this.defaultView =view;
            if(this.defaultView == true) {
              this.changeView(this.viewMode);
            }
         });
        this.is_rule_selected = true;
      }
    }, 
    err => { this.messageSvc.sendMessage({ message: err.error.error,  type: 'ERROR', hideboard: true }); });
  }

  onScheduleEdit(event) {
    this.dataQualityRules = [];
    this.leftGrid.dataView.setItems([]);
    this.isDataReady = false;
    this.getDataQualityRules(1,this.limit);
  }
}
