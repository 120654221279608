import { FormGroup, ValidationErrors } from '@angular/forms';
export function PatternValidator(controlName: string ,  regex: RegExp, error: ValidationErrors, matchingControlName ?: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (!control.value) {
            // if control is empty return no error
            return null;
          }

        if ( matchingControlName) {

        const matchingControl = formGroup.controls[matchingControlName];

        if ((matchingControl.dirty || matchingControl.touched) && matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            }
        }

     // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

        // set error on matchingControl if validation fails
        if (!valid) {
            control.setErrors(error);
        }
    };
}
