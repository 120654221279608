import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zettaclassify/zs-cl-content/zs-cl-content.service';
declare var $: any;
@Component({
  selector: 'zetta-technical-rules',
  templateUrl: './technical-rules.component.html',
  styleUrls: ['./technical-rules.component.scss']
})
export class TechnicalRulesComponent implements OnInit {

  @ViewChild('techModal') techModal: ElementRef;
  loggedInUserDetails;
  ruleIds: any = [];
  rule;
  header = "Create New Technical Column-Level Data Quality Rule";
  dq_rule_level:string;
  dataset_id:string;
  dataset_column_id:string;
  backTo:string;

  constructor(private router: Router, 
              private activatedRoute:ActivatedRoute,
              public ZsClContentService: ZsClContentService) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.dataset_id = params.get('dataset_id');
      this.dataset_column_id = params.get('dataset_column_id');
    });
    this.activatedRoute.parent.queryParamMap.subscribe(params => {
      let ruleIds = params.getAll('rule_id');
      this.ruleIds = ruleIds.filter((id, ind) => ruleIds.indexOf(id) == ind);
      if(this.ruleIds.length && this.ruleIds[0]) {
        this.ZsClContentService.getDataQualityRule(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.ruleIds[0]).subscribe((resp) => {
          this.rule = resp;
          if(this.router.url.includes('edit-users') && this.router.url.includes('showBreadCrumb=true')) {
            this.header = "Rule: " + this.rule.rule_name;
          } else if(this.router.url.includes('add-users') && this.router.url.includes('showBreadCrumb=true')) {
            this.header = `Edit Rules for (${this.ruleIds.length}) Rules Selected`;
          } else {
            this.header = "Create New Technical - Column Level Data Quality Rule";
          }
        }, err => { });
      }
    });
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.dq_rule_level = params.get('dqRuleLevel');
      if(params.has('BackTo')){
        this.backTo = params.get('BackTo');
      }
    });
    $(this.techModal.nativeElement).modal({backdrop: 'static', keyboard: false})
  }

  isUserAccessModalFromAddUsers() {
    return this.router.url.includes('add-users') ? true : false;
  }

  onCancel() {   
    if (this.dq_rule_level == 'dataset') {     
      if(this.dataset_id && !this.dataset_column_id && this.backTo === 'dataset'){
        this.router.navigate([`/zs/datasets`]);   
      }          
      if (this.dataset_id && !this.dataset_column_id && this.backTo !== 'dataset') {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
      if (this.dataset_id && this.dataset_column_id) {
        this.router.navigate(['/zs/datasets/' + this.dataset_id + '/columns/' + this.dataset_column_id + '/manage-rules'], { queryParamsHandling: 'merge' });
      }
    }else{
      sessionStorage.removeItem('ruleInfo');
      sessionStorage.removeItem('ruleInfoClone');
      sessionStorage.removeItem("tRuleDefinition");
      // adding condition to not to delete quality rule if user is closing modal from 'add-users' modal popup.  
      if (!this.isUserAccessModalFromAddUsers) {
        this.ZsClContentService.deleteDataQualityRule({ dq_rules_ids: this.ruleIds }).subscribe(resp => { });
      } 
      this.router.navigate([`/zs-cl/dataquality`]);   
    }
  }
}
