import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { DatasetService } from '../dataset.service';
import { UserGroupService } from '../../../../zettasense/usergroup/usergroup.service';
import { IDataset } from '../dataset.model';
import { blueTextFormattter } from '../../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../../environments/environment';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { MessageService } from '../../message/message.service';
import _ from 'lodash';
import { measuredDQFormatter } from 'src/app/common/shared/formatters/measuredDQFormatter';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { hyperLinkFormattter } from 'src/app/common/shared/formatters/hyperLinkFormatter';
import { totalRulesFormatter } from 'src/app/common/shared/formatters/totalRulesFormtter';
import { ObservableService } from 'src/app/common/services/observable.service';
import { BaseService } from 'src/app/common/services/base-service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ColDef, GridOptions, ICellRendererParams, ITextFilterParams, INumberFilterParams } from 'ag-grid-community';
import { NameLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/name-link-formatter/name-link-formatter.component';
import { ClassificationTagFormatterComponent } from 'src/app/common/shared/cell-renderer/classification-tag-formatter/classification-tag-formatter.component';
import { ButtonFormatterComponent } from 'src/app/common/shared/cell-renderer/button-formatter/button-formatter.component';
import { RuleCountFormatterComponent } from 'src/app/common/shared/cell-renderer/rule-count-formatter/rule-count-formatter.component';
import { MeasuredDQRuleFormatterComponent } from 'src/app/common/shared/cell-renderer/measure-dq-rule-formatter/measure-dq-rule-formatter.component';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { NoRowOverlayComponent } from '../../no-row-overlay/no-row-overlay.component';
import { ActionLinkFormatterComponent } from 'src/app/common/shared/cell-renderer/action-link-formatter/action-link-formatter.component';
import { DateFormatterFormatterComponent } from 'src/app/common/shared/cell-renderer/date-formatter/date-formatter.component';
 
import { NumberFormatterComponent } from 'src/app/common/shared/cell-renderer/number-formatter/number-formatter.component';
import { event, param } from 'jquery';
declare var $: any;
import { GridApi } from 'ag-grid-community';
const zettaUtilsInstance = new ZettaUtils();
 
@Component({
  selector: 'zetta-dataset-home',
  templateUrl: './dataset-home.component.html',
  styleUrls: ['./dataset-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatasetHomeComponent implements OnInit, OnDestroy { 
  public datasets: any = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);

  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 30, 'isNormalPagination': false };
  projectHomeTableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  columnApi: any;
  isLoading = true;
  total = 0;
  grandTotal = 0;
  subTotal = '';
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  usersData;
  dataset: any = [];
  tagsList: any = [];
  datasetTags: any = [];
  rowHeight = 30;
  isFiltering = false;
  isSorting = false;
  user_apps = false;
  msgHeader: string;
  msgText: string;
  showConfirmBox: boolean;
  hasDeleted: boolean;
  dataset_id;
  datasetName: string;
  showClassificationTag = false;
  showDownvoteModal = false;
  catalog_id: number;
  object_id: number;
  selected_row: number;
  gridApi:any;

  columnDefs: ColDef[] = []; 
  fsGridOptions: GridOptions;
  agGrid: AgGridAngular;
  dataset_name: string;
  filterKeys: any;
  sortKeys: any;
  totalPages = 1;
  subTotalRecords = 0;

  constructor(
    private datasetSvc: DatasetService,
    private userGroupSvc: UserGroupService,
    public zettaUtils: ZettaUtils,
    private router: Router,
    public _observableService: ObservableService,
    private baseService: BaseService,
    private messageSvc: MessageService, private catalogSvc: ZsClContentService, private http : HttpClient) {
    const apps = JSON.parse(sessionStorage.userInfo).apps;
    if (apps.length) {
      const cl_apps = apps.filter(item => item.name == 'Classify');
      if (cl_apps.length) {
        this.user_apps = true;
      }

    }
  }
  
  onRowSelectionChanged(rowSelection: string) {  
  }

  ngOnInit() {  

    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets`; 
    this.getDatasetList(this.page, this.limit);
      
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableFiltering: true, 
    };
    $('#breadcrumb').removeClass('d-none');  
    this.intialiseGrid(); 
  }

  intialiseGrid() {
    this.columnDefs = [
      {
        headerName: '',
        field: 'actions', 
        filter: false,
        sortable: false, 
        cellClass: 'elipse-overflow',
        editable: false, 
        maxWidth: 23,
        cellRendererFramework: ActionLinkFormatterComponent,
        suppressColumnsToolPanel: true,
      },{
        headerName: 'Id',
        field: 'dataset_id',  
        cellClass: ["ag-cell--tomato-background", "ag-cell--monospace-font"],
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        floatingFilter: true,
        maxWidth:130, 
        sortable: true, 
        tooltipField:'dataset_id'
      }, {
        headerName: 'Data Set Name',
        field: 'name',
        sortable: true, 
        minWidth: 200,
        floatingFilter: true,
        cellRendererFramework: NameLinkFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField : 'name'
        
      }, {
        headerName: 'Data Set Description',
        field: 'description',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        cellClass: 'custom-class-name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:'description'
      }, {
        headerName: 'Version',
        field: 'version',
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        sortable: false,
        cellClass: 'ag-right-aligned-cell',
        cellRendererFramework: NumberFormatterComponent,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:'version'
      }, {
        headerName: 'Data Source',
        field: 'datasource_name',
        cellRendererFramework: NameLinkFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:'datasource_name'
      }, { 
        headerName: 'Created By',
        field: 'created_by_user',
        filter: 'agTextColumnFilter',
        floatingFilter: true, 
        tooltipField: 'created_by_user',
        sortable: true, 
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Last Modified On',
        field: 'last_updated_ts',
        cellRendererFramework: DateFormatterFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,  
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField:'last_updated_ts'
      }, {
        headerName: 'Classification',
        field: 'all_tags',
        cellRendererFramework: ClassificationTagFormatterComponent,
        cellRendererParams: {
          user_apps: this.user_apps
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,   
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        tooltipField: 'all_tags'
      }, {
        headerName: 'Action',
        field: 'classifySet',
        cellRendererFramework: ButtonFormatterComponent, 
        cellRendererParams: {
          user_apps: this.user_apps
        },
        tooltipField: 'classifySet', 
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: '# of Data Quality Rules',
        field: 'total_rules',
        cellRendererFramework: RuleCountFormatterComponent,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        tooltipField: "total_rules",  
        cellClass: 'cell-align-center-text',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      }, {
        headerName: 'Measured Data Quality',
        field: 'measured_dq_score',
        cellRendererFramework: MeasuredDQRuleFormatterComponent,
        filter: 'agTextColumnFilter',
        resizable: true, 
        floatingFilter: true,
        cellClass: 'cell-align-center-text',
        sortable: true, 
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }, 
        tooltipField:'measured_dq_score'
      }, 
    ];
    
    this.fsGridOptions = {  
      enableCellTextSelection: true,
      headerHeight: 45, 
      rowHeight: 30,
      floatingFiltersHeight: 49,
      getRowStyle: params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: AppGlobals.EVEN_ROW_BG_COLOR };
        }
        return { background: AppGlobals.ODD_ROW_BG_COLOR };
      },
    };
 
  }
   
  ngOnDestroy() {
    this.messageSvc.clearMessage();
    this.baseService.showConfirmation.unsubscribe();
    this._observableService.unsubscribe();
  }

  getDatasetList(pageNo, limit = 1) {

    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets?paginate=true&pageno=${1}&pagesize=${limit}`;
    this.tableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets`;
    var serverfilter = ""; 
    let sortfilter = ""; 
    const getDatasets: IDataset = {
      user_id: this.loggedInUserDetails.user_id,
      tenant_id: this.loggedInUserDetails.tenant_id,
      pageno: pageNo,
      perpage: limit,
    };
    this.isLoading = true;
    this.datasetSvc.getDatasets(getDatasets, serverfilter, sortfilter).subscribe((data) => { 
      this.datasets = data['currentpage']; 
      if (this.datasets.length === 0) {
        this.noData = true;
      }
      
      this.datasets.forEach(element => {
        element.id = Math.random();
        element.enable_classify = this.user_apps;
        element.isRuleEnabled = this.loggedInUserDetails.enable_dq_rule;
      });

      this.isLoading = false;
      this.total = data['totalrecords'];
      if (pageNo === 1) {
        this.grandTotal = data['totalrecords'];
      }
      let concatedRows = this.datasets;
       
      if (pageNo > data['totalpages']) {
        this.isLoading = false;
        this.page = 1;
        
        this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets?paginate=true&pageno=${1}&pagesize=${limit}`;
      }
    }, err => {
      this.isLoading = false;
      this.messageSvc.sendMessage({ message: err.error.message, type: 'INFO', hideboard: true });
    });
 
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.slickGrid.onSort.subscribe((e, args) => {
      this.page = 1;
      this.hasScrolled = false;
    }); 
  }

  onNext(): void {
    this.isLoading = true;
    this.page++;
    this.getDatasetList(this.page, this.limit);
    
  }

  getDataSetHomeList(pageNo, limit = 1) {
    this.userGroupSvc.getUsers().subscribe((usersData) => {
      this.usersData = usersData['currentpage'];
      const getDatasets: IDataset = {
        user_id: this.loggedInUserDetails.user_id,
        tenant_id: this.loggedInUserDetails.tenant_id,
        pageno: pageNo,
        perpage: limit,
      };
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails.tenant_id}/datasets?paginate=true&pageno=${1}&pagesize=${limit}`;
      let serverfilter = ""; 
      this.datasetSvc.getDatasets(getDatasets, serverfilter).subscribe((data) => {
        this.datasets = data['currentpage'];
 
        if (this.datasets.length === 0) {
          this.noData = true;
        }
        this.datasets.forEach(element => {
          const user = this.usersData.find(userData => userData.user_id === element.created_by);
          element.isdisable = this.loggedInUserDetails.user_id !== element.created_by ? true : false;
          if (user) {
            element.created_by_user = user.first_name + ' ' + user.last_name;
          }
          element.id = Math.random();
          element.tags = element.tags === null ? '' : element.tags;
          const all_tags = [];
          this.datasetSvc.getDatasetColumnsModal(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, element.dataset_id).subscribe((res) => {
            this.datasetTags = res;
            this.datasetTags.forEach(row => {
              row.score100 = (row.score * 100).toFixed();
              row.level = row.score100 >= 75 ? 'HIGH' : row.score100 >= 50 ? 'MEDIUM' : 'LOW';
              all_tags.push(row.tags);
            });
            this.datasetTags.sort(((a, b) => b.score100 - a.score100));
            element.datasetTags = this.datasetTags;
            element.all_tags = all_tags.toString();
            this.isLoading = false;
          }, (error) => {
            element.datasetTags = undefined;
            element.all_tags = "";
            this.isLoading = false;
          });
        });
        this.total = data['totalrecords'];
        let concatedRows = this.datasets; 
         
      }, (error) => { });
    }, (error) => { });

  }

  getDataset(datasetId) {
    // this.datasetSvc.getDataset({ user_id: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id, dataset_id: datasetId}).subscribe((res) => {
    this.datasetSvc.getDatasetColumnsModal(this.loggedInUserDetails.user_id, this.loggedInUserDetails.tenant_id, datasetId).subscribe((res) => {
      this.dataset = res;
      this.dataset.forEach(element => {
        element.score100 = (element.score * 100).toFixed();
        element.level = element.score100 >= 75 ? 'HIGH' : element.score100 >= 50 ? 'MEDIUM' : 'LOW';
      });
      this.dataset.sort(((a, b) => b.score100 - a.score100));
      // this.tagsList = this.dataset.tags.split(',');
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });

  }

  onCellClick(eventData): void { 
    const event = eventData.eventData;
    const args = eventData.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    const metadata = this.grid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    if (row !== undefined && row !== null) {
      this.dataset_id=row.dataset_id;
      let user_entitlement = row.entitlement.is_admin + ',' + row.entitlement.read_write + ',' + row.entitlement.read
        sessionStorage.setItem("user_entitlement", user_entitlement);
      if (event.target.title === row.name) {
        this.router.navigate(['/zs/datasets', row.dataset_id], { queryParams: { showBreadcrumb: true, dataset_flow: true } });
      } else if (event.target.title === row.datasource_name) {
        this.router.navigate(['/zs/datasources', row.datasource_id]);
      } else if (fieldName === 'classifySet' && this.user_apps) {
        if (row.entitlement.is_admin || row.entitlement.read_write) {
          this.router.navigate(['/zs/datasets/' + row.dataset_id + '/catalogs']);
        }
      } if (fieldName === 'total_rules') {
        if (this.loggedInUserDetails['enable_dq_rule']) {
          if (!row.total_rules || row.total_rules == 0) {
            this.router.navigate(['/zs-cl/rules/create-technical-rule'], { queryParams: { dataset_id: row.dataset_id, dqRuleLevel: 'dataset', BackTo: 'dataset' } });
          }
          if (row.total_rules > 0) {
            sessionStorage.setItem('viewMode', 'technical');
            this.router.navigate(['/zs/datasets', row.dataset_id, 'manage-rules'], { queryParams: { showBreadcrumb: true, dataset_id: row.dataset_id, dqRuleLevel: 'dataset' } });
          }
        }
      }

      if (event.target.className.includes('fas fa-glasses')) {
        this.datasetName = row.name;
        this.showClassificationTag = true;
      }
    }
  }

  openContextMenu(e): void {  
    let curr_dataset_id;
    // sessionStorage.removeItem('curr_dataset_id');
    const parent = this;
    const eventEle = e.eventData;
    eventEle.stopImmediatePropagation();
    const args = e.args;
    const row = this.grid.gridService.getDataItemByRowIndex(args.row);
    let dataset_id = row.dataset_id;
     sessionStorage.setItem('curr_dataset_id', dataset_id);
    if (row.entitlement.is_admin) {
      $('#context_menu')
        .removeClass('d-none')
        .data('row', args.row)
        .css('top', eventEle.pageY)
        .css('left', eventEle.pageX + 5)
        .css('position', 'fixed')
        .css('display', 'block')
        .show();
    } else {
      $('#context_menu').hide();
    }
    $('body').one('click', function () {
      $('#context_menu').hide();
    });

    $('#context_menu').click(function (e) {
      this.curr_dataset_id = JSON.parse(sessionStorage.getItem('curr_dataset_id'));
      if (!($(e.target).is('span') || $(e.target).is('li'))) {
        return;
      }
      if (!parent.grid.slickGrid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      const action = $(e.target).attr('data');
      const env = JSON.parse(sessionStorage.getItem('userApp'));
      if (action === 'Edit') {
        let user_entitlement = row.entitlement.is_admin + ',' + row.entitlement.read_write + ',' + row.entitlement.read
        sessionStorage.setItem("user_entitlement", user_entitlement);
        // parent.router.navigate(['/zs/datasets/create-dataset'], { queryParams: { 'datasetId': row.dataset_id, showBreadcrumb: false } });
        parent.router.navigate(['/zs/datasets', row.dataset_id], { queryParams: { showBreadcrumb: true } });
      } else if (action === 'Export' && this.curr_dataset_id == row.dataset_id) 
      {
          parent.catalogSvc.exportResult(`${parent.apiUrl}/users/${parent.loggedInUserDetails.user_id}/tenants/${parent.loggedInUserDetails.tenant_id}/datasets/${row.dataset_id}/export`).subscribe(resp => {
          parent.catalogSvc.exportCsvFiles(resp, row.name);
          }, error => {
            this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
          });
      } else if (action === 'Delete') {
        parent.msgHeader = `${AppGlobals.DELETE_DATASET_MSG} ${row.name}`;
        parent.msgText = AppGlobals.ARE_YOU_SURE;
        parent.showConfirmBox = true;
      }
    });
  }

  onConfirmDelete(event) {
    this.deleteRow();
  }
  clearText(event) {
    this.hasDeleted = false;
    this.showConfirmBox = false;
    this.msgHeader = "";
    this.msgText = "";
  }

  deleteRow() {
    const user_id = this.loggedInUserDetails.user_id;
    const tenant_id = this.loggedInUserDetails.tenant_id;

    const deleteDataset: IDataset = {
      user_id: user_id,
      tenant_id: tenant_id,
      dataset_id: this.dataset_id
    };

    this.isLoading = true;

    this.datasetSvc.deleteDataset(deleteDataset).subscribe(
      () => { 
        this.clearText(this.datasets);

        if (this.agGrid) { 
          this.agGrid.api.purgeServerSideCache();  
        }  
      },
      (error) => {
        this.isLoading = false;
        this.messageSvc.sendMessage({ message: 'Delete dataset has failed', type: 'INFO', hideboard: true });
      }
    );
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) &&!this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onClick() {
    $('#object_classification_tags').modal('show');
  } 

  onGridReady(grid:any) { 
    this.agGrid = grid; 
    this.columnApi = grid.columnApi;  
    this.intialiseGrid();
    // grid.api.sizeColumnsToFit();
  }
 
  receiveTotalRecords($event) { 
   this.isLoading = false;   
   this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal); 
  }
  
  onAgCellClick(cellParams) {   
    const fieldName = cellParams.colDef.field;
    const row = cellParams.data;
    if (row !== undefined && row !== null) {
      this.dataset_id = row.dataset_id;
      this.dataset_name = row.name;
      let user_entitlement = row.entitlement.is_admin + ',' + row.entitlement.read_write + ',' + row.entitlement.read;
      sessionStorage.setItem("user_entitlement", user_entitlement);
      if (cellParams.value === row.name) {
        this.router.navigate(['/zs/datasets', row.dataset_id], { queryParams: { showBreadcrumb: true, dataset_flow: true } });
      } else if (cellParams.value === row.datasource_name) {
        this.router.navigate(['/zs/datasources', row.datasource_id]);
      } else if (fieldName === 'classifySet' && this.user_apps) {
        if (row.entitlement.is_admin || row.entitlement.read_write) {
          this.router.navigate(['/zs/datasets/' + row.dataset_id + '/catalogs']);
        }
      }
      if (fieldName === 'total_rules') {
        if (this.loggedInUserDetails['enable_dq_rule'] && (cellParams.event.target.classList && cellParams.event.target.classList.contains('blueLink'))) {
          if (!row.total_rules || row.total_rules == 0) {
            this.router.navigate(['/zs-cl/rules/create-technical-rule'], { queryParams: { dataset_id: row.dataset_id, dqRuleLevel: 'dataset', BackTo: 'dataset' } });
          }
          if (row.total_rules > 0) {
             sessionStorage.setItem('viewMode', 'technical');
            this.router.navigate(['/zs/datasets', row.dataset_id, 'manage-rules'], { queryParams: { showBreadcrumb: true, dataset_id: row.dataset_id, dqRuleLevel: 'dataset' } });
          }
        }
      }
      if (fieldName === 'all_tags') {
        this.getDataset(row.dataset_id);
      }
      if (fieldName === 'actions') {
        if (row.entitlement.is_admin) {
          // let topPos = 275; 
          // topPos = topPos + cellParams.rowIndex * 30;  
          // const letPos = 88;
          $('#context_menu')
            .removeClass('d-none')
            .data('row', cellParams.rowIndex)
            .css('top', cellParams.event.clientY)
            .css('left', cellParams.event.clientX + 4)
            .css('position', 'fixed')
            .css('display', 'block')
            .show();
        } else {
          $('#context_menu').hide();
        }
        $('body').one('click', function () {
          $('#context_menu').hide();
        });
      }
      
      if (cellParams.event.target.id.includes('glass-icon')) { 
        this.datasetName = row.name;
        this.showClassificationTag = true;
      }
    }
  }
 
  onEditDataset(hasEditClicked) {
    if (hasEditClicked) {
      this.router.navigate(['/zs/datasets', this.dataset_id], { queryParams: { showBreadcrumb: true } });
    }
  }

  onDeleteDataset(hasDeleteClicked) {
    if (hasDeleteClicked) {
      this.msgHeader = `You are about to delete the selected dataset.`;
      this.msgText = 'Are you sure that you want to delete dataset ?';
      this.showConfirmBox = true;
    }
  }

  onExportDataset(hasExprtClicked) {
    if (hasExprtClicked) {
      this.catalogSvc.exportResult(`${this.apiUrl}/users/${this.loggedInUserDetails.user_id}/tenants/${this.loggedInUserDetails.tenant_id}/datasets/${this.dataset_id}/export`).subscribe(resp => {
        this.catalogSvc.exportCsvFiles(resp, this.dataset);
      }, error => {
        this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
      });
    }
  }

  hideClassificationModal(event) {
    this.showClassificationTag = event;
  }
  
  closeModalPopup(event) {
    if (event) {
      $('#thumbs-up-' + this.selected_row).removeClass('fas fa-thumbs-up');
      $('#thumbs-up-' + this.selected_row).addClass('fal fa-thumbs-up');
      $('#thumbs-down-' + this.selected_row).removeClass('fal fa-thumbs-down');
      $('#thumbs-down-' + this.selected_row).addClass('fas fa-thumbs-down');      
    }
    this.showDownvoteModal = false;
  }
} 