import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Project } from "../../project.model";
import { environment } from 'src/environments/environment';
import { ProjectService } from "../../project.service";
import { dateFormatter } from 'src/app/common/shared/formatters/dateFormatter';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { MessageService } from 'src/app/common/components/message/message.service';
import { AppGlobals } from 'src/app/common/shared/app.globals';
import { ZsClContentService } from "src/app/zettaclassify/zs-cl-content/zs-cl-content.service";
import { DatasourceService } from "src/app/common/components/datasources/datasource.service";
import { DatasetService } from "src/app/common/components/datasets/dataset.service";
declare var $: any;

const radioFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
if (dataContext.ischecked) {
        return `<div class="formatter match-radio-formatter aaprcheck">
                  <input type="radio" name="selected_row" id="selected_row_${row}" checked>
                  <label class="m-0 pointer radio-formatter radio-label notsure-formatter" ></label>
                </div>`;
  } else {
        return `<div class="formatter match-radio-formatter">
                  <input type="radio" name="selected_row" id="selected_row_${row}">
                  <label class="m-0 pointer radio-formatter radio-label notsure-formatter" ></label>
                </div>`;
  }
}

const textFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {

  if (columnDef.name === AppGlobals.CUSTOM_SOURCE) {
      if ((dataContext.is_custom_record && (dataContext.created_by === dataContext.loggedInUserId)) || (dataContext.is_custom_new_row)) {
        return `<label title="${columnDef.params.userFullName}" id="Source">${columnDef.params.userFullName}</label>`;
      } 
      else {
        return value;
      }

  } else if (columnDef.name === AppGlobals.CUSTOM_SOURCEVALUE) {
      if ((dataContext.is_custom_record && (dataContext.created_by === dataContext.loggedInUserId))) {
            return `<input type="text" class="custom-txtbox outline-none" autofocus id="display_name" value="${value}"
            >`
      } else if ((dataContext.is_custom_new_row)) {
        return `<input type="text" class="custom-txtbox outline-none" autofocus id="display_name" value="${value ? value: ''}"
        >`
      } 
      else {
        return `<input type="text" readonly="true" class="custom-txtbox readonly-text-input" autofocus id="display_name_non_editable" value="${value}"
                    >`
      }
  } 
  else {
    return value;
  }
}

const customConfidenceFormatter : Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return value;
}

const blueTextFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(dataContext.is_active) {
    return `<div title="${value}" class="blueLink ruleDetails">${value}</div>`;
  } else {
    return `<div title="${value}" class="ruleDetails">${value}</div>`;
  }
}
const dataSourceDropdownFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid?: any) => {
  var options = '';
  var valueName = '';
  if (columnDef.field === 'name') {
  if (columnDef.params.list && columnDef.params.list.length > 0) {
    for (var j of columnDef.params.list) {
                  options = options + '<option value=\''+j.datasource_id +'\'>'+ j.name +'</option>\n';
        }
      }   
  
  let placeholder = '';  
  placeholder = 'Select Datasource';
  var classList = 'grid-select-dropdown';
      return `<div class="selectInput w-100">
                  <select id='concept-${columnDef.field}-${row}' class="${classList} ctm-obj-wd">
                      <option value="">${placeholder}</option>
                      ${options}
                  </select>
              </div>`;
  }
}
const textBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>
                `<div>
                <input type="text" class="input-box" id="concept-${columnDef.field}-${row}" value="${value}"  />
                </div>` 
            
@Component({
    selector:'zetta-golden-records-detail',
    templateUrl:'./golden-records-details.component.html',
    styleUrls:['./golden-records-details.component.scss']
})
export class GoldenRecordsDetailsComponent implements OnInit{
  formTitle :string;
  formValue : string;
  activeNavLnk = "main";
  tableDataSource: any = [];
  gridColumnsDef: any;
  gridOptions: any;
  fieldName = "";
  tableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 5, 'isNormalPagination': false };
  projectDetail: Project = new Project();
  cluster_id: string;
  record_id: string;
  project_id: string;
  attribute_name: string;
  goldel_records;
  goldel_records1 = [];
  goldel_records2 = [];
  flName = "";
  dqScore: number;
  overall_quality_score;
  score_class = "";
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public exceptionGrid: AngularGridInstance;
  modeState: any = {};
  goldenRecordData: any = [];
  project_user: any = [];
  enableApplyBtn = false;
  leftGrid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  leftDataSource: any = [];
  public updated_recordval: any;
  history_payload: any;
  rightDataSource: any = [];
  leftgridColumnsDef: any;
  rightGridColumnsDef: any;
  leftGridRows = 0;
  rightGridRows = 0;
  leftGridOptions: any;
  rightGridOptions: any;
  hasLeftUsersSelected = false;
  hasRightUsersSelected = false;
  showBreadcrumb = false;
  page = 1;
  limit = 100;
  rightpage = 1;
  rightlimit = 10000;
  noData = false;
  hasScrolled = false;
  NameSelected;
  enable_edit = false;
  leftdisplay_name;
  leftsource_value;
  leftphysical_name;
  totalrightcout = 0;
  zs_record_id = 0;
  goldenRecordAttributeLength = 0;
  leftTableSettings: object = { 'height': "100%", 'width': "100%" };
  rightTableSettings: object = { 'height': "100%", 'width': "100%" };
  public isleftLoading = false;
  public isrightLoading = false;
  is_success: boolean;
  rightsource_value: string;
  trainModelTableColDef = [];
  entityName: string;
  loggedInUserFullName: string = '';
  isCustomRecord: boolean;
  showSelectedLeftRow: boolean = false;
  selectedLeftRow;
  showGoldenEditModal: boolean = false;
  showPublishModal: boolean=false;
  semanticDatasetsTableSettings: object = { 'height': "100%", 'width': "100%" };
  semanticDatasetsTableColDef: any;
  semanticDatasetsGridOptions: any;
  semanticDatasets: any = [];
  semanticDatasetsGrid: AngularGridInstance;
  datasources= [];
  entityId: any;
  entity_name: string;
  total = 0;
  eventData: any;
  selectedConceptMetadata: any;
  targetDatasourceId: any;
  targetDataSetName: any;
  includeReconciliation: Boolean = false;
  public apiUrl: string = environment.config.API_URL;
  public login_user = JSON.parse(sessionStorage.userInfo).entitlements;
  is_visible_publish = false;
  is_enable_publish = false
  showMainRecodsLoader: boolean = true;
  constructor(private projectService: ProjectService, private messageSvc: MessageService, private route: ActivatedRoute, private router: Router, private datasourceService: DatasourceService, private datssetService: DatasetService, private ZsClContentService: ZsClContentService) {
    if (this.login_user) {
      try {
        let user_entitlements=this.login_user[0].entitlement;
        if(this.login_user.length>1){
          user_entitlements = this.login_user.filter(obj => obj.force_entitlement == true);
          if(user_entitlements.length){
            user_entitlements=user_entitlements[0].entitlement;
          }else{
            user_entitlements=this.login_user[0].entitlement;
          }
        }
        const my_projects = user_entitlements.screens.filter(item => item.name == 'project_details')[0].childs.filter(item => item.name == 'golden_records')[0].childs[0];
        if (my_projects) {
          this.is_visible_publish = my_projects.visible;
          this.is_enable_publish = my_projects.enable;
        }
      } catch (error) {
        console.log(error);
      }

    }
  }
    ngOnInit(){
      this.flName = sessionStorage.getItem("selected_FN") == 'undefined' ? '' : this.flName;
      if(sessionStorage.getItem('activeNavLnk')){
        this.activeNavLnk =sessionStorage.getItem('activeNavLnk');
     }    
     this.route.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    if(this.router.url.includes('production-stage-details')) {
      this.modeState.mode = 'Production';
    } else {
      this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
    }

    this.projectService.getProject(this.loggedInUserDetails.user_id, this.projectDetail.project_id).subscribe(resp => {
      if (resp && resp.users) {
        resp.users.forEach((user) => {
          if (user.user_id === this.loggedInUserDetails.user_id) {
            this.enable_edit = true;
          }
        });
      }
    });
    this.loggedInUserFullName = `${this.loggedInUserDetails['first_name']}` + `${(this.loggedInUserDetails['last_name'] ? this.loggedInUserDetails['last_name'] : '')}`;
      this.route.queryParamMap.subscribe((param) => {
        this.cluster_id= param.get('record_id');
        this.record_id = param.get('cluster_id');
        this.entityName = param.get('selectedEntity');
        this.entityId = param.get('entityId') || param.get('entity_id');
        if(param.get('noReloadRequired') == 'false') {
          this.getEntitiesmasteredByClusterId(1, 20, this.cluster_id);
        }
      });
      this.leftgridColumnsDef = [{
        'displayname': 'Field Name',
        'physicalname': 'display_name',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 180
      }, {
        'displayname': 'Value Selected',
        'physicalname': 'value',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'minWidth': 180
      }];
      this.rightGridColumnsDef = [
        {
          'displayname': ' ',
          'physicalname': 'radio',
          'sortable': false,
          'datatype': 'String',
          'filterable': false,
          'formatter': radioFormatter,
          'minWidth': 35,
          'maxWidth': 35
        },{
        'displayname': 'Source',
        'physicalname': 'source_system',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': textFormatter,
        'params': {userFullName: this.loggedInUserFullName},
      }, {
        'displayname': 'Source Value',
        'physicalname': 'source_value',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': textFormatter,
        'params': {isCustom: this.isCustomRecord}
      }, {
        'displayname': 'Confidence',
        'physicalname': 'confidence',
        'sortable': true,
        'datatype': 'String',
        'filterable': true,
        'formatter': customConfidenceFormatter,
      }];
     this.gridColumnsDef = [{
      'displayname': 'Rule Id',
      'physicalname': 'rule_id',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'formatter': blueTextFormattter,
    }, {
      'displayname': 'Rule Name',
      'physicalname': 'rule_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
    }, {
      'displayname': 'Rule Description',
      'physicalname':  'rule_description',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 90,
    }, {
      'displayname': 'Rule Break Reason',
      'physicalname': 'rule_break_reason',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 250,
    }];
    this.leftGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true
  };

  this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
  };
    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.getDatasources();
    this.getSemanticDatasetsByEntity();
    this.initColDef();
    this.semanticDatasetsGridOptions = {
     enableGridMenu: false,
     enableAddRow: false,
     selectable: false,
     enableFiltering: false,
     CheckboxSelector: false,
     enableCellNavigation: true,
     multiSelectable: false,
    };
  
    this.getEntityDetails(this.entityId);
  
    }

  
  onShowModel(title, value, fieldName) {
    this.formTitle = title;
    this.fieldName = fieldName;
    this.formValue = value ? value : 'null';
    this.goldenRecordData = [];
    this.getexceptionList(title, this.cluster_id);
    $('#edit_Classification_tag').modal('show');
  }
    getAPI(activeLink: string) {
      this.activeNavLnk = activeLink;
      sessionStorage.setItem('activeNavLnk', activeLink);
   }

   onReloadScreen(eventData) {
    this.activeNavLnk = 'main';
    this.cluster_id = eventData.masterID;
    this.record_id = eventData.record_id;
    this.goldel_records1 = [];
    this.goldel_records2 = [];
    this.getEntitiesmasteredByClusterId(1, 20, this.cluster_id);
  }

   OnGridCreation(grid) {
    this.exceptionGrid = grid;
  }
  onScroll(parentClass) {
    if (sessionStorage.getItem("serverfilter")) {
      this.noData = false;
    }
    if (sessionStorage.getItem("sortfilter")) {
      this.noData = false;
    }
    if (!this.hasScrolled) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  onNext(): void {
    this.page++;
    this.getRecordsData(this.cluster_id,this.page, this.limit,);
  }

  getexceptionList(attribute_name, zsClusterId) {
    this.projectService.getExceptionsByClusterId(this.loggedInUserDetails.user_id, this.projectDetail.project_id, zsClusterId, attribute_name).subscribe((data) => {
      this.goldenRecordData = data;
      let concatedRows = this.goldenRecordData;
      if (this.exceptionGrid) {
       //concatedRows = [...this.exceptionGrid.dataView.getItems(), ...this.goldenRecordData];
        this.goldenRecordData.forEach(element => {
          element.id = Math.random();
        });
        this.goldenRecordData = concatedRows;
        this.exceptionGrid.dataView.setItems(concatedRows);
        this.exceptionGrid.gridService.setSelectedRows([]);
      }
    }, err => { });
  }
  onCellClick(e): void {
    const eventEle = e.eventData;
    const args = e.args;
    const row = this.exceptionGrid.gridService.getDataItemByRowIndex(args.row);
    if (row !== undefined && row !== null) {
      if (eventEle.target.className.includes("ruleDetails")) {
        if (row.is_active) {
          $('#edit_Classification_tag').modal('hide');
          if(row.rule_type_lookup_id == AppGlobals.BUSINESS_LEVEL_RULE) {
            this.router.navigate(['/zs-cl/dataquality/' + row.rule_id + '/business/rule-definition'], { queryParams: { rule_id: row.rule_id ? row.rule_id : row.data_quality_rule_id, golden_record: true, rule_type: 'business'} });
          } else {
            this.router.navigate(['/zs-cl/dataquality/' + row.rule_id + '/technical/rule-definition'], { queryParams: { rule_id: row.rule_id ? row.rule_id : row.data_quality_rule_id, golden_record: true, rule_type: 'technical' } });
          }
        }
      }
    }
  }

  leftGridCreated(grid) {
    const parent = this;
    parent.leftGrid = grid;
    parent.leftGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.hasLeftUsersSelected = args.rows.length > 0 ? true : false;
    });
}

rightGridCreated(grid) {
  const parent = this;
  parent.rightGrid = grid;
  parent.rightGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
    parent.hasRightUsersSelected = args.rows.length > 0 ? true : false;
    const row = parent.rightGrid.gridService.getDataItemByRowIndex(args.rows);
  });
}

  onLeftCellClick(e): void {
    const parent = this;
    const eventEle = e.eventData;
    const args = e.args;
    const row = parent.leftGrid.gridService.getDataItemByRowIndex(args.row);
    this.isrightLoading = true;
    if (row !== undefined && row !== null) {
      this.NameSelected = row.display_name;
      this.rightDataSource = [];
      this.enableApplyBtn = false;
      this.is_success = false;
      this.leftsource_value = row.value;
      this.leftdisplay_name = row.display_name;
      this.leftphysical_name = row.physical_name;
      this.leftGrid.gridService.setSelectedRow(e.args.row);
      this.selectedLeftRow = e.args.row;
      this.leftDataSource[this.selectedLeftRow]['shouldHighlight'] = true;
      this.getRecordvalue(this.cluster_id, row.physical_name, this.rightpage, this.rightlimit);
    }
  }

  ngDoCheck() {
    this.onChangeSource();
  }

  initColDef() {
    this.semanticDatasetsTableColDef = [
          {
            'displayname': 'Target Data Source',
            'physicalname': 'name',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': dataSourceDropdownFormatter,
            'minWidth': 150,
            'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
            'params': { list: this.datasources, tabindex: 1},
            },{
              'displayname': 'Target Data Set',
              'physicalname': 'target_dataset_name',
              'sortable': true,
              'datatype': 'String',
              'filterable': false,
              'formatter': textBoxFormatter,
              'minWidth': 150,
              'headerCssClass': 'mr-top-1 pd-l-6-5-imp',
              }
    ];
  
  }
  getSemanticDatasetsByEntity() {
    this.semanticDatasets = [{
      id: Math.random(),
      target_dataset_name: this.entity_name + '_' + Date.now(),
    }];
  }
  getEntityDetails(entityId) {
    this.projectService.getEntityAttributes(entityId)
    .subscribe(resp => {
      if(resp && resp['attributes']) {
        let attribute = resp['attributes'].filter(ele => ele['attribute_id'] == resp['cluster_identifier_attribute_id']);
        this.entityName = attribute[0]['logical_name'];
      }
    });
  }
  
  getDatasources() {
    this.datasourceService.getDatasources({userId: this.loggedInUserDetails.user_id, tenant_id: this.loggedInUserDetails.tenant_id, query: `datasource_types=${AppGlobals.DATASOURCE_TYPE_HDFS},${AppGlobals.DATASOURCE_TYPE_CLOUDFS}&has_access_only=true`})
    .subscribe(res => {
      this.datasources = res['currentpage'];
    });
  }

  semanticsModalCreated(grid) {
    this.semanticDatasetsGrid = grid;
  }
  onSemanticDsGridClickEvent(eventData) {
    const parent = this;
    this.eventData = eventData;
    const args = eventData.args;
    const event = eventData.eventData;
    const metadata = this.semanticDatasetsGrid.gridService.getColumnFromEventArguments(args);
    this.selectedConceptMetadata = metadata;
    const row = this.semanticDatasetsGrid.gridService.getDataItemByRowIndex(args.row);
    const fieldName = metadata.columnDef.field;
    if(fieldName == 'name') {
      $('#concept-name-0').on('change', function(e) {
        let entityVal = $('#concept-name-0')[0]['value'];
        parent.targetDatasourceId = entityVal;
        parent.targetDataSetName = $('#concept-target_dataset_name-0').val();
      });
    }
     if (fieldName == 'target_dataset_name') {
        $('#concept-target_dataset_name-0').on('keyup',function(e){
          // to get the value from textbox which is returned as formatter from grid
          let dsName = $(this).val();
          parent.targetDataSetName = `${dsName}`;
        });
      }
  }
  
  publishData() {
    let validationData = {
      user_id: this.loggedInUserDetails['user_id'],
      tenant_id: this.loggedInUserDetails['tenant_id'],
      name: this.targetDataSetName
    };
    this.datssetService.validateDatasetName(validationData)
    .subscribe(resp => {
        let data = {
          "project_id": this.projectDetail.project_id,
          "datasource_id": this.targetDatasourceId,
          "dataset_name": `${this.targetDataSetName}`,
          "loggedInUserId": this.loggedInUserDetails['user_id']
        };
        this.projectService.publishGoldenData(data)
        .subscribe(res => {
          if(res) {
            this.targetDataSetName = '';
            this.targetDatasourceId = '';
            this.includeReconciliation = false;
            $('#concept-name-0')[0]['value'] = '';
            $('#concept-target_dataset_name-0')[0]['value'] = '';
            this.showPublishModal = false;
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.initColDef();
            this.messageSvc.sendMessage({
              type: "INFO",
              hideInfo: true,
              publishGoldenRecords: true,
              activeJobs: "ResolvePublishedProject",
              isModel: true,
              messageHeader: AppGlobals.PUBLISH_GOLDEN_RECORDS_HEADER_MESSAGE,
              message: AppGlobals.PUBLISH_GOLDEN_RECORDS_MESSAGE,
            });
          }
        },
        err => {
          this.messageSvc.sendMessage({ message: err.error, type: 'ERROR', hideboard: true });
        });
        if(this.includeReconciliation) {
          this.publishGoldenRecord();
        }
    }, 
    error => {
      this.messageSvc.sendMessage({ message: error.error.message, type: 'ERROR', hideboard: true });
    });
  }


  triggerModal() {
    this.initColDef();
    this.showPublishModal = true;
  }
  onRightCellClick(e): void {
    const parent = this;
    const eventEle = e.eventData;
    const args = e.args;
    let columnValue;
    let columnName;
    let source_system;
    this.is_success = false;
    const rightrow = parent.rightGrid.gridService.getDataItemByRowIndex(args.row);
    const metadata = parent.rightGrid.gridService.getColumnFromEventArguments(args);
    const fieldName = metadata.columnDef.field;
    if(fieldName == 'source_value') {
      $('#display_name').on('keydown', function(e) {
        rightrow['display_name'] = $('#display_name')[0]['value'];
        columnName = rightrow['display_name'];
        if(columnName && columnName.length>0) {
          parent.enableApplyBtn = true;
        } else {
          parent.enableApplyBtn = true;
        }
      });
      rightrow['value_selected'] = true;
    }
    this.zs_record_id = rightrow.zs_recid;
    this.rightsource_value = rightrow.source_value;
      columnName = $("#display_name").val();
      $('#display_name-' + args.row).on('keyup', function(e) {
        let targetDsName = $('#display_name-' + args.row)[0]['value'] ? $('#display_name-' + args.row)[0]['value'] : null;
      });
    if ((this.leftsource_value == rightrow.source_value) && rightrow.Source != AppGlobals.CUSTOM_OPTIONVALUE) {

    }
    else {
      this.rightGrid.gridService.setSelectedRows([]);
      if (rightrow.Source === AppGlobals.CUSTOM_OPTIONVALUE) {
        rightrow['value_selected'] = true;
        columnValue = rightrow['display_name'];
        this.rightsource_value = columnValue;
      } else {
        parent.rightDataSource.forEach(element => {
          if (element.Source == AppGlobals.CUSTOM_OPTIONVALUE) {
            element['value_selected'] = false;
            // $("#Source").val(AppGlobals.CUSTOM_OPTION);
            $("#display_name").val('');
          }
        });
        source_system = rightrow.source_system;
        columnValue = rightrow.source_value;
        this.rightsource_value = rightrow.source_value;
      }
      this.updated_recordval = {
        zs_cluster_id: this.cluster_id,
        zs_record_id: this.record_id,
        column_name: this.leftphysical_name,
        column_value: columnValue,
        source_system: source_system,
        zs_recid: this.zs_record_id,
        is_custom: (rightrow.customValue || rightrow.is_custom_new_row ) ? true : false
      };
      this.history_payload = {
        zs_cluster_id: this.cluster_id,
        zs_recid: this.zs_record_id,
        attribute: this.leftdisplay_name,
        original_value: this.leftsource_value,
        activity: AppGlobals.GOLDEN_RECORD_MSG,
        changed_value: columnValue,
      };

      if ((rightrow.Source === AppGlobals.CUSTOM_OPTIONVALUE)) {
        if (columnValue !== "" && source_system !== "" && source_system !== AppGlobals.CUSTOM_OPTION) {
          this.enableApplyBtn = true;
        }
        else {
          this.enableApplyBtn = false;
        }
      }
      else {
        this.enableApplyBtn = true;
      }
    }
    $("#selected_row_" + args.row).prop("checked", true);
  }

  getRecordvalue(zsclusterid, attribute, pageno, limit = 10000) {
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.isrightLoading = true;
    this.projectService.getGoldenRecordsEntity(this.loggedInUserDetails.user_id, this.projectDetail.project_id, zsclusterid, attribute, pageno, limit).subscribe((data) => {   
      if(data) {   
        data.push(data.splice(data.indexOf(data[0]), 1)[0]);
      this.rightDataSource = data;
        this.rightDataSource = this.rightDataSource.filter(data => data && data.source_system !== "Manually Updated");
        this.isrightLoading = false;
        this.totalrightcout = data.length;
        if(data) {
          data.forEach((element, ind) => {
            element.loggedInUserId = this.loggedInUserDetails && this.loggedInUserDetails['user_id'] ? this.loggedInUserDetails['user_id']: null;
            if(element && element.is_custom_record) {
              this.rightDataSource.splice(ind, 1);
              return element['customValue'] = true;
            } else {
              return element['customValue'] = false;
            }
          });
        }
        const firstRow = this.addLastRow();
        this.setNewUserDetails(firstRow);
        let customRecordExists = data.filter(ele => ele['is_custom_record']);
        if(customRecordExists && customRecordExists.length>0) {
          this.rightDataSource.push(customRecordExists[0]);
          this.isCustomRecord = true;
        } else {
          this.isCustomRecord = false;
        }
        

        this.rightGridColumnsDef = [
          {
            'displayname': ' ',
            'physicalname': 'radio',
            'sortable': false,
            'datatype': 'String',
            'filterable': false,
            'formatter': radioFormatter,
            'minWidth': 35,
            'maxWidth': 35
          },{
          'displayname': 'Source',
          'physicalname': 'source_system',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'formatter': textFormatter,
          'params': {userFullName: this.loggedInUserFullName},
        }, {
          'displayname': 'Source Value',
          'physicalname': 'source_value',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'formatter': textFormatter,
          'params': {isCustom: this.isCustomRecord}
        }, {
          'displayname': 'Confidence',
          'physicalname': 'confidence',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'formatter': customConfidenceFormatter,
        }];
        if(!this.isCustomRecord) {
          this.rightDataSource.splice(this.totalrightcout + 1, 1, firstRow);
        }
          let is_winner = '0';
          this.rightDataSource.forEach((element, i) => {
            element['id'] = Math.random();
            if ((element.is_winner && element.is_winner == '1') && this.leftsource_value == element.source_value) {
              is_winner = '1';
              element['ischecked'] = true;
              $("#apprcheck").prop('checked', true);
            } else if (this.leftsource_value != '' && this.leftsource_value == element.source_value && is_winner == '0') {
              is_winner = '1';
              element['ischecked'] = true;
              $("#apprcheck").prop('checked', true);
            } else if(this.rightDataSource.length-1 == i && is_winner == '0') {
              is_winner = '1';
              element['ischecked'] = true;
            $("#apprcheck").prop('checked', true);
          }
        });
        let concatedRows = this.rightDataSource;
        if (this.rightGrid) {
          // concatedRows = [...this.rightGrid.dataView.getItems(), ...this.rightDataSource];
          this.rightDataSource = concatedRows;
  
          this.rightGrid.dataView.setItems(this.rightDataSource);
          this.rightGrid.gridService.setSelectedRows([]);
        }
        setTimeout(() => {
          this.updaterightgrid();
        }, 1000);
      }
    }, err => {
      this.isrightLoading = false;
    });
  }
  
  updateLeftPanleData() {
    let selectedrows = this.leftGrid.dataView.getItems();
    let index = 0;
    selectedrows.forEach(element => {
      if (this.leftsource_value == element.source_value) {
        this.leftGrid.gridService.setSelectedRow(index);
      }
      index++;
    });
  }

  updaterightgrid() {
    let selectedrows = this.rightGrid.dataView.getItems();
    let index = 0;
    selectedrows.forEach(element => {
      if (this.leftsource_value == element.source_value) {
        this.rightGrid.gridService.setSelectedRow(index);
      }
      index++;
    });
  }

  setNewUserDetails(row) {
    row.Source = 'Custom';
    row.value = '';
  }
  onChangeSource() {
    if (this.rightDataSource) {
      this.rightDataSource.forEach(element => {
        if (element && element.value_selected == true) {
          let source_system = $("#Source").val();
          let columnValue = $("#display_name").val();
          if(!this.isCustomRecord) {
            if (columnValue == "" || source_system == AppGlobals.CUSTOM_OPTION) {
              this.enableApplyBtn = false;
            } else {
              this.enableApplyBtn = true;
            }
          }
        }
      });
    }
  }
  getEntitiesmasteredByClusterId(pageno, limit, zsClusterId) {
    var serverfilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverfilter = sessionStorage.getItem("serverfilter");
    }
    var sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.projectService.getMainGoldenRecords(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, zsClusterId, pageno, limit)
    .subscribe(mainRecords => {
      if(mainRecords) {
        if (mainRecords[0]['rows'].length) {
          this.trainModelTableColDef.push(mainRecords[0]['rows']);
        }
        this.goldel_records = mainRecords[1];
      }
      let data:any = mainRecords[1];
      let total = data ? data['length'] : [];
      let i = 1;
      let addressSplitDelimiter = '$fsDelimiter$';
      let formattedReponse = [];
      let orderedObject;
      if(this.trainModelTableColDef && this.trainModelTableColDef[0] && this.trainModelTableColDef[0].length) {
        this.trainModelTableColDef[0].forEach(col => {
          if(col && col.physical_name) {
            orderedObject = this.getByLogicalName(col.physical_name);
            if(orderedObject) {
              formattedReponse.push(orderedObject);
            }
          }
        });
      }
      this.showMainRecodsLoader = false;
      this.goldel_records = formattedReponse.map((val) => {
      if(val && val.value && val.value.toString().includes('$fsDelimiter$')) {
            val.value =  val.value.split('$fsDelimiter$');
            val['delimeterExists'] = true;
          } else {
            val['delimeterExists'] = false;
            val;
          }
       return val;
    });
      if (data && data['length']) {
        if (data[0]['dq_score']) {
          this.dqScore = Number(Number.isInteger(data[0]['dq_score']) ? Number(data[0]['dq_score'] * 100) : Number(data[0]['dq_score'] * 100).toFixed(2));
          this.overall_quality_score = Number.isInteger(data[0]['dq_score']) ? Number(data[0]['dq_score'] * 100) : Number(data[0]['dq_score'] * 100).toFixed(2);
          this.score_class = (data[0]['dq_score'] * 100) >= 75 ? 'high' : (data[0]['dq_score'] * 100) >= 50 ? 'medium' : 'low';
          this.overall_quality_score = this.overall_quality_score + '%';
        }
        else {
          this.overall_quality_score = 'n/a';
          this.score_class = 'n/a';
        }
        this.goldel_records.forEach((order: any) => {
          let column = Object.keys(order);
          let colname = column.filter(obj => obj.includes("_exc"));
          order['hasexception'] = false;
          if (colname) {
            Object.entries(order).forEach(([key, value], index) => {
              if (key === colname[0] && value == true) {
                order['hasexception'] = true;
              }
            });
          }
          if (i <= total / 2) {
            this.goldel_records1.push(order);
          } else {
            this.goldel_records2.push(order);
}
          i++;
        });
        let records = data.filter(item => item['display_name'] == this.entityName)[0];
        if (records) {
          this.flName = records['value'] == null ? '' : records['value'];
        } else {
          records = data.filter(item => item['display_name'] == 'Product Descrption')[0];
          if (records) {
            this.flName = records['value'] == null ? '' : records['value'];
          } else {
            this.flName = '';
          }
        }
      }
      if(this.showSelectedLeftRow) {
        setTimeout(() => {
          this.updateLeftPanleData();
          this.showSelectedLeftRow = false;
        }, 1000);
      }
      $('.progress-bar').css('width', this.overall_quality_score + '%').attr('aria-valuenow', this.overall_quality_score);
    }, err => {
      this.showMainRecodsLoader = false;
    });
  }

  onGoldenRecordClick() {
    if(this.router.url.includes('production-stage-details')) {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details/golden-records`], {queryParamsHandling: 'merge' });
    } else {
      const qParams = { selectedEntity: this.entityName };
      this.router.navigate(['/zs/projects/' + this.projectDetail.project_id + '/golden-records'], { queryParams: qParams, queryParamsHandling: 'merge' });
    }
  }
  publishGoldenRecord() {
    this.ZsClContentService.exportResult(this.apiUrl + `/users/` + this.loggedInUserDetails['user_id'] + `/projects/` + this.projectDetail.project_id + `/entitiesmastered/winners/publish?file_name=${this.targetDataSetName}`).subscribe(resp => {
      this.ZsClContentService.exportCsvFiles(resp, `winner`);
    this.includeReconciliation = false;
    this.initColDef();
  }, error => {
    this.messageSvc.sendMessage({ message: error.error, type: 'ERROR', hideboard: true });
  });
  }
  editdata() {
    
    this.showGoldenEditModal = true
    this.NameSelected = "";
    this.rightDataSource = [];
    this.getRecordsData(this.cluster_id, this.page, this.limit);

    setTimeout(()=>{
      ($('#edit_data') as any).modal('show');
    },200)
  }

  getRecordsData(zsClusterId, page, limit) {
    this.projectService.getGoldenRecordByClusterId(this.loggedInUserDetails.user_id, this.projectDetail.project_id, zsClusterId, page, limit).subscribe((data) => {
      if(data) {
        ($('#edit_data') as any).modal('show');
        this.leftDataSource = data;
        this.leftDataSource = this.leftDataSource.filter(elem => elem.display_name != "Identifier at Source" && elem.display_name != "Source Primary Key" && elem.display_name != "Source System Primary Key" && elem.display_name != "Source System Identifier" && elem.display_name != "Source System" && elem.display_name != "Last Record Updated Date" && elem.display_name != "Record Id" && elem.display_name !== "zs_record_end_date");
        this.goldenRecordAttributeLength = this.leftDataSource.length;
        
        let newResponse = [];
        this.leftDataSource.forEach((gRecord)=>{
            if(gRecord.value && gRecord.value.toString().includes('$fsDelimiter$')){
                gRecord.value.split('$fsDelimiter$').map((element)=>{ if(element){ newResponse.push({...gRecord, id: Math.random(),original_value: gRecord.value,value: element}); } });
            } else {
                newResponse.push({...gRecord, id: Math.random()});
            }
        }); 
        this.leftDataSource = newResponse;

        let concatedRows = this.leftDataSource;
        if (this.leftGrid) {
          this.leftDataSource = concatedRows;
          this.leftGrid.dataView.setItems(concatedRows);
          this.leftGrid.gridService.setSelectedRows([]);
          this.hasScrolled = false;
        }
        this.isleftLoading = false;
      }
    }, err => {
      this.leftDataSource = [];
      this.isleftLoading = false;
    });
  }
  updateGoldenData() {
    this.showSelectedLeftRow = true;
    if (this.rightDataSource) {
      this.rightDataSource.forEach(element => {
        if (element.value_selected == true) {
          element.source_system = element.source_system ? element.source_system : $("#Source")[0].title;
          element.source_value = $("#display_name").val();
            this.updated_recordval = {
              zs_cluster_id: this.cluster_id,
              column_name: this.leftphysical_name,
              column_value: element.source_value,
              source_system: element.source_system,
              zs_record_id: this.record_id,
              is_custom: element.customValue || element.is_custom_new_row ? true : false
            };
            if (element.zs_recid) {
              this.updated_recordval['zs_recid']= element.zs_recid;
            }
            // should attach the zs_recid for custom existing, else attach the existing rec_id
            // if(this.isCustomRecord) {
            //   this.updated_recordval['zs_recid']= this.zs_record_id;
            // } else {
            //   this.updated_recordval['zs_record_id']= this.record_id;
            // }
          this.history_payload = {
            zs_cluster_id: this.cluster_id,
            zs_recid: this.zs_record_id,
            attribute: this.leftdisplay_name,
            original_value: this.leftsource_value,
            activity: AppGlobals.GOLDEN_RECORD_MSG,
            changed_value: element.source_value,
          };
        }
      });
    }   
    
    this.projectService.updateGoldenRecordAttribute(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.updated_recordval, (this.updated_recordval && this.updated_recordval.is_custom) ? true: false).subscribe((data) => {
      this.projectService.saveGoldenRecordsHistory(this.loggedInUserDetails['user_id'], this.projectDetail.project_id, this.cluster_id, this.history_payload).subscribe((data) => { });
      this.is_success = true;
      this.leftsource_value=this.rightsource_value;
      this.goldel_records = [];
      this.goldel_records1=[];
      this.goldel_records2 =[];
      this.getRecordsData(this.cluster_id, this.page, this.limit);
      this.getEntitiesmasteredByClusterId(1, 20, this.cluster_id);   
      this.isrightLoading = true;
      this.getRecordvalue(this.cluster_id, this.leftphysical_name, this.rightpage, this.rightlimit);   
    }, err => {
      this.messageSvc.sendMessage({ message: err.error.message, type: 'ERROR', hideboard: true });
    });
  }

  onclose() {
    this.goldel_records1 = [];
    this.goldel_records2 = [];
    this.goldel_records = [];
    this.showGoldenEditModal = false
    this.leftDataSource = [];
    
    this.getEntitiesmasteredByClusterId(1, 20, this.cluster_id);
    this.showSelectedLeftRow = false;
    
    if (this.leftGrid) {
      this.leftGrid.dataView.setItems(this.leftDataSource);
      this.leftGrid.gridService.setSelectedRows([]);
    }
    if(this.rightGrid) {
      this.rightDataSource = [];  
      this.rightGrid.dataView.setItems(this.rightDataSource);
        this.rightGrid.gridService.setSelectedRows([]);
    }

    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $('#edit_data').modal('hide');
  }
  
  addLastRow() {
    return { '': '', 'is_custom_new_row': true ,'source_system': 'Create your own option', 'source_value': '', };
  }

   getByLogicalName(physicalName) {
    if(this.goldel_records && this.goldel_records.length) {
      return this.goldel_records.find(record => (record && record.physical_name && physicalName) && record.physical_name === physicalName);
    } else {
      return null;
    }
  }

  navigateToHome() {
    if(this.router.url.includes('production-stage-details')) {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details`], {queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}`]);
    }
  }
  
}