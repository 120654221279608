import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../content/project/project.service';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance } from 'angular-slickgrid';
import { confidenceFormatter } from '../../../common/shared/formatters/confidenceFormatter';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { Project } from '../../content/project/project.model';
import { toolTipFormatter } from '../../../common/shared/formatters/toolTipFormatter';
import { environment } from 'src/environments/environment';
import { Column, Formatter } from 'angular-slickgrid';
import { AppGlobals } from '../../../common/shared/app.globals';

const zettaUtilsInstance = new ZettaUtils();

const numericFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
        return `<div class="text-right w-100 text-bold pr-2" title="${value}">${zettaUtilsInstance.commarization(value, 2)}</div>`
}
@Component({
    selector: 'zetta-entity-records-view-results',
    templateUrl: './entity-records-view-results.component.html',
    styleUrls: ['./entity-records-view-results.component.scss']
})
export class EntityRecordsViewResultsComponent implements OnInit {
    public leftSidePaneTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
    public middlePanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
    public rightSidePanelTableSettings: object = { 'height': "100%", 'width': "100%", 'pageSize': 15, 'isNormalPagination': false };
    public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
    public rightSidePanelDS: object;
    public middlePanelDS: any = [];
    public leftSidePanelDS: object;
    public leftPanelGridObject: AngularGridInstance;
    public middlePanelGridObject: AngularGridInstance;
    public rightPanelGridObject: AngularGridInstance;
    public leftPanelGridColumnsSettings: object = [];
    public rightPanelGridColumnsSettings: object = [];
    public middlePanelGridColumnsSettings: object = [];
    public leftSidePanelOptions: object = {};
    public rightSidePanelOptions: object = {};
    public middlePanelOptions: object = {};
    public isLeftLoading = true;
    public isMiddleLoading = false;
    public isRightLoading = false;
    public hasScrolled = false;
    projectDetail = new Project();
    leftGridSelection: string;
    middleGridSelection: string;

    totalLeft = 0;
    pageLeft = 1;
    limitLeft = 200;
    grandTotal = 0;
    subTotal = '';

    totalMiddle = 0;
    pageMiddle = 1;
    limitMiddle = 200;

    totalRight = 0;
    pageRight = 1;
    limitRight = 200;

    initMiddleGrid = true;
    initRightGrid = true;
    noData = false;

    modeState: any = {};

    constructor(private ProjectService: ProjectService,
        public zettaUtils: ZettaUtils,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.parent.params.subscribe(params => {
            this.projectDetail.project_id = params['id'];
        });
        sessionStorage.removeItem("serverfilter");     
        this.modeState.mode = 'Training';  //Modes states: "Setup", "Training", "Production"
        sessionStorage.removeItem('serverfilter');
        sessionStorage.removeItem('sortfilter');
        this.leftSidePanelOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,
            rowHeight: 34,
        };

        this.middlePanelOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,


        };

        this.rightSidePanelOptions = {
            enableGridMenu: false,
            frozenRow: -1,
            enableAddRow: false,
            selectable: true,
            editable: false,
            autoEdit: false,
            enableFiltering: true,
            CheckboxSelector: false,
            enableCellNavigation: true,


        };

        this.leftPanelGridColumnsSettings = [
            {
                'displayname': 'Name',
                'physicalname': 'display_name',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': toolTipFormatter,
                'minWidth': 160,
            },
            {
                'displayname': 'Records',
                'physicalname': 'count',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': numericFormatter,
                'cssClass': 'mr-1',
                'headerCssClass': 'text-left',
                'minWidth': 80,
               
            },
            {
                'displayname': 'Confidence Level',
                'physicalname': 'zs_accuracy_level',
                'sortable': true,
                'formatter': confidenceFormatter,
                'datatype': 'String',
                'filterable': true,
                'cssClass': 'text-center',
                'minWidth': 80,

            }
        ];

        this.middlePanelGridColumnsSettings = [
            {
                'displayname': 'Field Name',
                'physicalname': 'display_name',
                'sortable': true, 'datatype': 'String',
                'filterable': true, 'minWidth': 120,
                'formatter': blueTextFormattter,
            },
            {
                'displayname': 'Value Selected',
                'physicalname': 'value',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': null,
            },
            {
                'displayname': 'Confidence',
                'physicalname': 'score',
                'sortable': true,
                'datatype': 'String',
                'filterable': true,
                'formatter': numericFormatter,
                'headerCssClass': 'text-left',
                'minWidth': 80,
                'maxWidth': 90,
            }
        ];

        this.rightPanelGridColumnsSettings = [{
            'displayname': 'Source',
            'physicalname': 'source',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': null,
        },
        {
            'displayname': 'Source Value',
            'physicalname': 'value',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': null,
            'minWidth': 120,
            'maxWidth': 130,
        },
        {
            'displayname': 'Score',
            'physicalname': 'score',
            'sortable': true,
            'datatype': 'String',
            'filterable': true,
            'formatter': numericFormatter,
            'cssClass': 'mr-3',
            'headerCssClass': 'text-left',
            'minWidth': 70,
            'maxWidth': 80,
        }
        ];

        this.getEntitiesmasteredByLoggedInUser(this.pageLeft, this.limitLeft);
    }

    getEntitiesmasteredByLoggedInUser(pageno, limitLeft) {
        // this.isLeftLoading = true;
        this.leftSidePaneTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesmastered?pageno=${1}&pagesize=${this.limitLeft}`;
        let serverFilter="";
        let sortfilter = "";
        if(sessionStorage.getItem("serverfilter")){
          serverFilter=sessionStorage.getItem("serverfilter");
        }
        
        if (sessionStorage.getItem("sortfilter")) {
          sortfilter = sessionStorage.getItem("sortfilter");
        }
        this.ProjectService.getEntitiesmasteredByLoggedInUser(this.loggedInUserDetails.user_id, this.projectDetail.project_id, pageno, limitLeft, serverFilter, sortfilter).subscribe(data => {
                this.leftSidePanelDS = data;
                if (this.leftSidePanelDS['currentpage'].length === 0) {
                    this.noData = true;
                }
                this.isLeftLoading = false;
                this.totalLeft = data['totalrecords'];
                if (pageno === 1) {
                    this.grandTotal = data['totalrecords'];
                }
                let concatedLeftRows = this.leftSidePanelDS['currentpage'];
                if (this.leftPanelGridObject) {
                    concatedLeftRows = [...this.leftPanelGridObject.dataView.getItems(), ...this.leftSidePanelDS['currentpage']];
                    this.leftSidePanelDS['currentpage'].forEach(element => {
                        element.id = Math.random();
                    });
                    this.leftSidePanelDS['currentpage'] = concatedLeftRows;
                    this.leftPanelGridObject.dataView.setItems(concatedLeftRows);
                    this.leftPanelGridObject.gridService.setSelectedRows([]);
                    this.hasScrolled = false;
                }
            }, err => {
                this.leftSidePanelDS = { 'currentpage': [] };
                this.isLeftLoading = false;
            });
    }

    // on click of project grid row
    onLeftPanelRowSelection(args) {
        this.isMiddleLoading = true;
        this.leftPanelGridObject.gridService.setSelectedRow(args.args.row);
        const rowObject = this.leftPanelGridObject.gridService.getDataItemByRowIndex(args.args.row);
        this.leftGridSelection = rowObject.zs_cluster_id;
        this.isMiddleLoading = true;
        if (this.middlePanelGridObject) {
            this.middlePanelDS = [];
            this.middlePanelGridObject.dataView.setItems(this.middlePanelDS);
        }
        this.getEntitiesmasteredByClusterId(this.pageMiddle);
    }

    // not using pagination.
    getEntitiesmasteredByClusterId(pageno, limit = 100) {
        this.ProjectService.getEntitiesMasterByClusterId(this.loggedInUserDetails.user_id,
            this.projectDetail.project_id, this.leftGridSelection, pageno, limit, true).subscribe((data) => {  
                const rows = data;
                this.initMiddleGrid = false;

                if (this.middlePanelGridObject) {
                    rows.forEach(element => {
                        element['id'] = Math.random();
                    });

                    this.middlePanelGridObject.dataView.setItems(rows);
                    this.middlePanelGridObject.gridService.setSelectedRows([]);
                    this.rightSidePanelDS = undefined;

                }
                let systemAttributes = this.zettaUtils.getSystemAttributes();
                rows.forEach(ele => {
                    // if(ele.display_name !== AppGlobals.SOURCE_SYSTEM && 
                    //    ele.display_name !== AppGlobals.IDENTIFIER_AT_SOURCE && 
                    //    ele.display_name !== AppGlobals.LAST_RECORED_UPDATED_DATE && 
                    //    ele.display_name !== AppGlobals.RECORED_LAST_UPDATED_DATE &&
                    //     ele.display_name !== AppGlobals.SOURCE_PRIMARY_KEY && 
                    //     ele.display_name !== AppGlobals.SOURCE_SYSTEM_IDENTIFIER) {
                    //         this.middlePanelDS.push(ele)
                    // }
                    if (ele.display_name !== AppGlobals.RECORED_LAST_UPDATED_DATE && systemAttributes.indexOf(ele.display_name) <= -1) {
                        this.middlePanelDS.push(ele);
                    }
                    
                });
                this.isMiddleLoading = false;
            }, err => {
                this.middlePanelDS = [];
                this.isMiddleLoading = false;
            });

    }

    // on click of project grid row
    onMiddlePanelRowSelection(args) {
        this.isRightLoading = true;
        this.middlePanelGridObject.gridService.setSelectedRow(args.args.row);
        const rowObject = this.middlePanelGridObject.gridService.getDataItemByRowIndex(args.args.row);
        this.middleGridSelection = rowObject.name;
        this.getAttributesOfEntitiesmasteredByClusterId(this.pageRight);
    }

    getAttributesOfEntitiesmasteredByClusterId(pageno, limit = 20) {
        this.ProjectService.getAttributesOfEntitiesmasteredByClusterId(this.loggedInUserDetails.user_id,
            this.projectDetail.project_id, this.leftGridSelection, pageno, limit).subscribe((data) => {
                const rows = data[this.middleGridSelection];
                this.initRightGrid = false;

                if (this.rightPanelGridObject) {
                    if(rows){
                        rows.forEach(element => {
                            element['id'] = Math.random();
                        });
                        this.rightSidePanelDS = rows;
                        this.rightPanelGridObject.dataView.setItems(rows);
                        this.rightPanelGridObject.gridService.setSelectedRows([]);
                    }                    
                }
                this.rightSidePanelDS = rows;
                this.isRightLoading = false;
            }, err => {
                this.rightSidePanelDS = [];
                this.isRightLoading = false;
            });
    }

    onLeftGridCreation(leftgrid) {
        this.leftPanelGridObject = leftgrid;
    }

    onMiddleGridCreation(middlegrid) {
        this.middlePanelGridObject = middlegrid;
    }
    onRightGridCreation(rightgrid) {
        this.rightPanelGridObject = rightgrid;
    }

    goToPageLeft(n: number): void {
        this.pageLeft = n;
        this.getEntitiesmasteredByLoggedInUser(this.pageLeft, this.limitLeft);
    }

    onNextLeft(): void {
        this.pageLeft++;
        this.getEntitiesmasteredByLoggedInUser(this.pageLeft, this.limitLeft);
    }

    onPrevLeft(): void {
        this.pageLeft--;
        this.getEntitiesmasteredByLoggedInUser(this.pageLeft, this.limitLeft);
    }

    totalLeftPages(): number {
        return this.zettaUtils.getTotalPages(this.totalLeft, this.limitLeft);
    }

    onLeftGridScroll(parentClass) {
        if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
            this.hasScrolled = true;
            this.onNextLeft();
        }
    }
    receiveTotalRecords($event) {
        this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event);
    }
}
