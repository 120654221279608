import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProjectService } from '../project.service';
import { blueTextFormattter } from '../../../../common/shared/formatters/blueTextFormatter';
import { assignmentStatusFormatter } from '../../../../common/shared/formatters/assignmentStatusFormatter';
import { checkBoxDisableFormatter } from '../../../../common/shared/formatters/checkBoxDisableFormatter';
import { numericFormatter } from '../../../../common/shared/formatters/numericFormatter';
import { optionSelectionFormatter } from '../../../../common/shared/formatters/optionSelectionFormatter';
import { ZettaUtils } from '../../../../common/shared/zettaUtils';
import { AngularGridInstance, Formatter, Column } from 'angular-slickgrid';
import { Project } from '../project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../common/shared/app.globals';
import { dateFormatter } from '../../../../common/shared/formatters/dateFormatter';
import { MessageService } from 'src/app/common/components/message/message.service';
import { environment } from '../../../../../environments/environment';
import { BaseService } from 'src/app/common/services/base-service';
import { matherMergerFormatter } from 'src/app/common/shared/formatters/matherMergerFormatter';
declare var $: any;

const RIGTH_EMPTY_GRID_MSG = 'Select one or more users from the left panel.';

const graduateHat: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<div class='formatter'><div class='fa fa-graduation-cap pointer modal-feedback'></div></div>`;
}

const disabledCheckBoxFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  return `<label class="ml-3 mr-top-7 pr-5 check-cell">
  <input type="checkbox" disabled>
</label>`;
}

const reviewerCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const checkCond = dataContext.roles.includes(AppGlobals.RESOLVE_PROJECT_REVIEWER);
  if (checkCond) {
    let checkCond = dataContext.isReviewer ? 'checked' : 'unchecked';
    return `<label class="checkbox-container ml-3 pr-5">
    <input type="checkbox" ${checkCond}>
    <span class="checkmark" style="border-radius: 2px; border: 1px solid #D1CFCF;height:15px; width: 15px; background-color: #fff;"></span>
  </label>`;
  } else {
    return `<label class="ml-3 mr-top-7 pr-5 check-cell">
    <input type="checkbox" disabled>
  </label>`
  }
}

const approverCheckBoxFormattter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  const checkCond = dataContext.roles.includes(AppGlobals.RESOLVE_PROJECT_APPROVER);
  if (checkCond) {
    let checkCond = dataContext.isApprover ? 'checked' : 'unchecked';
    return `<label class="checkbox-container ml-3 pr-5">
    <input type="checkbox" ${checkCond}>
    <span class="checkmark" style="border-radius: 2px; border: 1px solid #D1CFCF;height:15px; width: 15px; background-color: #fff;"></span>
  </label>`;
  } else {
    return `<label class="ml-3 mr-top-7 pr-5 check-cell">
    <input type="checkbox" disabled>
  </label>`
  }
}

@Component({
  selector: 'zetta-matching-remediation-tasks',
  templateUrl: './matching-remediation-tasks.component.html',
  styleUrls: ['./matching-remediation-tasks.component.scss']
})
export class MatchingRemediationTasksComponent implements OnInit, OnDestroy {

  matchingRemediationTasksData: [];
  matcherFixingTaskGrid: AngularGridInstance;
  loggedInUserDetails = null;
  matchingRemediationTasksGridOptions: any;
  tableSettings: object = { 'height': '100%', 'width': '100%' };
  matchingRemediationTaskTableColDef: any;
  gridOptions: any;
  projectDetail = new Project();
  isLoading = false;
  totalRecords = 0;
  page = 1;
  limit = 100;
  isShowRightGrid = false;
  rightGridDataSource: any = [];
  rightGridColumnsDef: any;
  rightGridOptions: any;
  rightGrid: AngularGridInstance;
  showBreadcrumb = false;
  selectedLeftGridRowsSize = 0;
  errorRibbon = false;
  selectedUserRoles: any = [];
  errorMsg: string = '';
  statusObj: any = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
  selectedClusterId: string;
  selectedTaskName: string;
  selectedTaskType: string;
  taskType: string;
  selectedTaskStatus: string;
  openMatcherFeedbackModel: boolean = false;
  shouldDisableMatchFixingTasks: boolean = true;
  shouldRefreshTaskStatistics: boolean = false;
  isProjectDataLoaded: boolean = false;
  isProjectFourEyeCheckOn: boolean = false;
  shouldMoveMatchFixingData: boolean = false;
  selectedJobId: string;
  showViewTaskAssignmentsButton: boolean = false;
  assignType: string = 'resolveTask';
  taskAssignDetails;
  selectedTasks = [];
  userGridTitle: string = `Users(${this.rightGridDataSource && this.rightGridDataSource.length ? this.rightGridDataSource.length : 0})`;
  isUserProjectAdmin: boolean = false;
  modeState: any = {};
  showProjectMode: boolean = false;
  constructor(private projectSvc: ProjectService,
    public zettaUtilsSvc: ZettaUtils,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,
    private observableSvc: BaseService) { }

  ngOnInit() {

    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');

    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';

    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });

    if(this.router.url.includes('production-stage-details')) { 
      this.showProjectMode = true;
    } else {
      this.showProjectMode = false;
    }

    this.getInitialDataForMatchFixingTasksAndUsers();
    //this.getUsersList();
   // this.matchingRemediationTaskList(this.page, this.limit);

    this.matchingRemediationTaskTableColDef = [{
      'displayname': 'Task Name',
      'physicalname': 'zs_cluster_identifier',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 220,
      'formatter': blueTextFormattter
    },{
      'displayname': 'Task Type',
      'physicalname': 'tasktype',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 110,
      'formatter': matherMergerFormatter
    },{
      'displayname': 'Records',
      'physicalname': 'zs_total_records_cluster',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'cssClass': 'text-right',
      'headerCssClass': 'text-left',
      'formatter': numericFormatter,
    }, {
      'displayname': 'Status',
      'physicalname': 'task_status',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'headerCssClass': 'pl-2 ml-3',
      'formatter': assignmentStatusFormatter,
      'minWidth': 180,
    }, {
      'displayname': 'Open',
      'physicalname': 'blue_hat_open_task',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'cssClass': 'text-center',
      'formatter': graduateHat,
      'minWidth': 80,
    }, {
      'displayname': 'Last Updated',
      'physicalname': 'last_updated',
      'sortable': true,
      'datatype': 'String',
      'filterable': true,
      'minWidth': 120,
      'formatter': dateFormatter
    }];

    this.gridOptions = {
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      rowHeight: 34
    };

    this.matchingRemediationTasksGridOptions = {
      enableColumnReorder: false,
      enableGridMenu: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      enableRowSelection: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 15,
    };

    this.rightGridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: false,
      enableCellNavigation: true,
      multiSelectable: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 10,
      noDataMsg: RIGTH_EMPTY_GRID_MSG,
    };

    this.rightGridColumnsDef = [{
      'displayname': 'User Name',
      'physicalname': 'user_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'First Name',
      'physicalname': 'first_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Last Name',
      'physicalname': 'last_name',
      'sortable': true,
      'datatype': 'String',
      'filterable': true
    }, {
      'displayname': 'Reviewer',
      'physicalname': 'reviewer',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxDisableFormatter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }, {
      'displayname': 'Approver',
      'physicalname': 'approver',
      'sortable': false,
      'datatype': 'String',
      'filterable': false,
      'formatter': checkBoxDisableFormatter,
      'columnGroup': 'Authorizations',
      'minWidth': 60,
    }];


    if (showBreadcrumb && showBreadcrumb === 'true') {
      this.showBreadcrumb = true;
    }

    if (!this.showBreadcrumb) {
      // Remove BreadCrumb
      $('#breadcrumb').addClass('d-none');
    }

    this.taskAssignDetails = {
      user_id: this.loggedInUserDetails['user_id'],
      title: `Users`,
      selectImgPath: '../../../../assets/images/common/select-data1.png',
      gridColDef: this.rightGridColumnsDef,
      alignCardHeight: false,
      diffTaskStatusSelected: false,
      updateURL: `users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id }/projecttasks`,
      getUserUrl: `users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id }/users/`,
    };
    this.modeState['mode'] = 'Production';
  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  onMatcherFixingTasksGridCreation(grid) {
    let parent = this;
    parent.matcherFixingTaskGrid = grid;

    parent.matcherFixingTaskGrid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedLeftGridRowsSize = args.rows.length;
      parent.showViewTaskAssignmentsButton = (parent.isUserProjectAdmin) && (parent.selectedLeftGridRowsSize > 0) ? true : false;
      // if (!parent.showViewTaskAssignmentsButton) {
      //   parent.isShowRightGrid = false;
      // }

      parent.isShowRightGrid = false;
      parent.selectedTasks = [];
      args.rows.forEach(ind => {
        const row = parent.matcherFixingTaskGrid.gridService.getDataItemByRowIndex(ind);
        parent.selectedTasks.push(row);
      });
      parent.checkForTaskTypes();
      parent.taskAssignDetails['gridColDef'] = parent.rightGridColumnsDef;
    });
  }

  viewTaskAssignments() {
    this.isShowRightGrid = true;
    this.observableSvc.showAssignUsers.next(true);
  }

  rightGridCreated(grid) {
    this.rightGrid = grid;
  }
  // onRightGridCellClick(e): void {
  //   const eventEle = e.eventData;
  //   console.log('on right grid cell click');
  //   const args = e.args;
  //   const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
  //   const fieldName = this.rightGrid.slickGrid.getColumns()[args.cell].field;
  //   const dataSet = this.rightGrid.dataView.getItems();
  //   row['selectedRoleID'] = null;

  //   if (row !== undefined && row !== null) {
  //     if (fieldName === 'isProjectAdminChecked') {
  //       row['selectedRoleID'] = AppGlobals.PROJECT_ADMIN;
  //       row['isProjectAdminChecked'] = !row['isProjectAdminChecked'];
  //       if (row['isProjectAdminChecked']) {
  //         row['selectedRoles'].push(AppGlobals.PROJECT_ADMIN);
  //       } else {
  //         row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.PROJECT_ADMIN);
  //       }
  //     } else if (fieldName === 'isReviewerChecked') {
  //       row['selectedRoleID'] = AppGlobals.REVEIWER;
  //       row['isReviewerChecked'] = !row['isReviewerChecked'];
  //       if (row['isReviewerChecked']) {
  //         row['selectedRoles'].push(AppGlobals.REVEIWER);
  //       } else {
  //         row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.REVEIWER);
  //       }
  //     } else if (fieldName === 'isApproverChecked') {
  //       row['selectedRoleID'] = AppGlobals.APPROVER;
  //       row['isApproverChecked'] = !row['isApproverChecked'];
  //       if (row['isApproverChecked']) {
  //         row['selectedRoles'].push(AppGlobals.APPROVER);
  //       } else {
  //         row['selectedRoles'] = row['selectedRoles'].filter(res => res !== AppGlobals.APPROVER);
  //       }
  //     }

  //     // this.setStatusProperty();
  //     // this.validationChecking(dataSet, row);
  //   }
  // }

  validationChecking(dataSet, row) {
    this.errorMsg = '';
    const reviewsList = dataSet.filter(item => item.isReviewerChecked === true);
    const approverList = dataSet.filter(item => item.isApproverChecked === true);

    if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size === 0 && this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
      row.isReviewerChecked = false;
    }
    else if (this.statusObj.pendingapproval_size > 0 && this.statusObj.completed_size >= 0 && this.statusObj.inreview_size > 0 && row.isReviewerChecked === true) {
      row.isReviewerChecked = (reviewsList.length === 1) ? true : false;
      if (row.isReviewerChecked) {
        this.errorMsg = `${row.username} will be set as the Reviewer only for the ${this.statusObj.inreview_size} tasks that are in Review status, out of ${this.statusObj.totalSelection} total tasks selected.`;
      } else {
        this.errorMsg = 'Only one Reviewer is allowed.';
      }
    }
    else if (this.statusObj.inreview_size === 0 && row.isReviewerChecked === true) {
      this.errorMsg = 'Reviewer cannot be changed.';
    }
    else if (reviewsList.length > 1) {
      this.errorMsg = 'Only one Reviewer is allowed.';
      row.isReviewerChecked = false;
    }
    else if (approverList.length > 1) {
      this.errorMsg = 'Only one Approver is allowed.';
      row.isApproverChecked = false;
    }
    if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.REVEIWER;
    } else if (row.isReviewerChecked === true) {
      row['selectedRoleID'] = AppGlobals.APPROVER;
    }
    // TODO: Call API to Update DB
    this.rightGrid.gridService.updateDataGridItem(row, false);
  }

  setStatusProperty() {
    this.statusObj = { 'completed_size': 0, 'inreview_size': 0, 'pendingapproval_size': 0, 'totalSelection': 0 };
    const leftGridDataService = this.matcherFixingTaskGrid.gridService;
    const leftSideGridDataSet = this.matcherFixingTaskGrid.slickGrid.getSelectedRows();
    const rows: any[] = [];

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row);
      }
    });
    const completedList = rows.filter(item => item.task_status === 'Completed');
    const reviewsList = rows.filter(item => item.task_status === 'In Review');
    const approveList = rows.filter(item => item.task_status === 'Pending Approval');

    this.statusObj = { 'completed_size': completedList.length, 'inreview_size': reviewsList.length, 'pendingapproval_size': approveList.length, "totalSelection": rows.length };
  }

  matchingRemediationTaskList(pageno, limit = 1) {
    this.isLoading = true;
    if(this.showProjectMode) {
      this.projectSvc.getOpenGoldenRecords({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }, pageno, limit).subscribe((data) => {
        this.handleRemediationTasksData(data);
      }, err => {
        this.isLoading = false;
        this.matchingRemediationTasksData = [];
        this.shouldRefreshTaskStatistics = false;
      });
    } else {
      this.projectSvc
        .getEntitiesResolvedList({ userId: this.loggedInUserDetails['user_id'], projectId: this.projectDetail.project_id }, pageno, limit, false).subscribe((data) => {
          this.handleRemediationTasksData(data);
        }, err => {
          this.isLoading = false;
          this.matchingRemediationTasksData = [];
          this.shouldRefreshTaskStatistics = false;
        });
    }
  }

  // handle after receiving response
  handleRemediationTasksData(tasksData) {
    this.shouldRefreshTaskStatistics = false;
    if (tasksData.length <= 0) {
      this.isLoading = false;
      this.matchingRemediationTasksData = [];
      return;
    }

    tasksData['currentpage'].forEach((masteredData) => {
      masteredData['id'] = Math.random();
      masteredData['shouldDisable'] = !this.isUserProjectAdmin ? true : (masteredData && masteredData.task_status && (masteredData.task_status === AppGlobals.TASK_COMPLETED || masteredData.task_status === AppGlobals.TASK_REVIEWED_STATUS)) ? true :false;
    })

    this.matchingRemediationTasksData = tasksData['currentpage'];
    this.isLoading = false;
    this.totalRecords = tasksData['totalrecords'];
    if (this.matcherFixingTaskGrid) {
      this.matcherFixingTaskGrid.dataView.setItems(this.matchingRemediationTasksData);
      this.matcherFixingTaskGrid.gridService.setSelectedRows([]);
    }
  }

  getInitialDataForMatchFixingTasksAndUsers() {
    if(this.showProjectMode) {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved/fixissues/summary?onlyopentasks=true&pagination=true&pageno=${this.page}&pagesize=${this.limit}`;
    } else {
      this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/projects/${this.projectDetail.project_id}/entitiesresolved/fixissues/summary?`;
    }
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';
    this.isLoading = true;
    this.projectSvc.getDataForMatchingRemediationTasks(this.loggedInUserDetails['user_id'],
      this.projectDetail.project_id, this.showProjectMode ? true : false, this.page, this.limit).subscribe(responseList => {
        let projectUsersData: any = responseList.projectUsersData;
        let projectInfo:any = responseList.projectAPIData;
        let fixIssueData = responseList.fixIssuesTasksData;
        this.isProjectDataLoaded = true;
        if (projectInfo) {
          this.isProjectFourEyeCheckOn = projectInfo.four_eye_check ? projectInfo.four_eye_check:false; 
        }

        if (projectUsersData && projectUsersData.length) {
           let projectAdminList = projectUsersData.filter((projectUser) => {
                let projectUserRoles = projectUser.roles;
                if (projectUserRoles && projectUserRoles.length) {
                    let isUserRoleAdmin = projectUserRoles.find((roles) => {
                       return roles === AppGlobals.RESOLVE_PROJECT_ADMIN;
                    })
                    return isUserRoleAdmin ? true : false;
                }
                return false;
           });
          
           if (projectAdminList && projectAdminList.length) {

            let isLoggedInUserAdmin = projectAdminList.find((adminUser) => {
              return adminUser.user_id === this.loggedInUserDetails['user_id'];
            })

            this.isUserProjectAdmin = isLoggedInUserAdmin ? true: false;

           } else {
              this.isUserProjectAdmin = false;
           }
        } else {
          this.isUserProjectAdmin = false;
        }
    
        if (!this.isUserProjectAdmin) {
          // Making CheckboxSelector as false if logged in user is not projectadmin
          this.gridOptions = {...this.gridOptions, CheckboxSelector: this.isUserProjectAdmin ? true : false}
        }

        if (fixIssueData) {
          this.handleRemediationTasksData(fixIssueData);
        }

        // handle Project users data. 
        if (projectUsersData && projectUsersData.length) {
          
          this.rightGridDataSource = projectUsersData;
          this.rightGridDataSource.forEach(row => {
            row['isProjectAdminChecked'] = false;
            row['isReviewerChecked'] = false;
            row['isApproverChecked'] = false;
            row.roles.forEach(roles => {
  
              switch (roles) {
                case AppGlobals.PROJECT_ADMIN:
                  row['isProjectAdminChecked'] = false;
                  row['selectedRoles'] = row['roles'];
                  break;
                case AppGlobals.REVEIWER:
                  row['isReviewerChecked'] = false;
                  row['selectedRoles'] = row['roles'];
                  row['isReviewer'] = true;
                  break;
                case AppGlobals.APPROVER:
                  row['isApproverChecked'] = false;
                  row['selectedRoles'] = row['roles'];
                  row['isApprover'] = true;
                  break;
                default:
                  row['selectedRoles'] = [];
              }
            });
          }); 
        } else {
          this.rightGridDataSource = [];
        }
      }, err => {
        this.isLoading = false;
        this.matchingRemediationTasksData = [];
        this.shouldRefreshTaskStatistics = false;
        this.messageSvc.sendMessage({ message: 'Failed to fetch Tasks', type: 'INFO', hideboard: true });
      });
  }


  /*getUsersList() {
    AppGlobals.PROJECT_ADMIN = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_admin') : '';
    AppGlobals.REVEIWER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_reviewer') : '';
    AppGlobals.APPROVER = JSON.parse(sessionStorage.getItem('entitlements')) ? this.zettaUtilsSvc.getEntitlementid('project_approver') : '';
    this.projectSvc.getUsers(this.loggedInUserDetails['user_id'],
      this.projectDetail.project_id, this.loggedInUserDetails['group_id']).subscribe(responseList => {
        console.log('Project fork j response: ', responseList);
        this.isProjectDataLoaded = true;
        this.rightGridDataSource = responseList[1];
        this.rightGridDataSource.forEach(row => {
          row['isProjectAdminChecked'] = false;
          row['isReviewerChecked'] = false;
          row['isApproverChecked'] = false;
          row.roles.forEach(roles => {

            switch (roles) {
              case AppGlobals.PROJECT_ADMIN:
                row['isProjectAdminChecked'] = false;
                row['selectedRoles'] = row['roles'];
                break;
              case AppGlobals.REVEIWER:
                row['isReviewerChecked'] = false;
                row['selectedRoles'] = row['roles'];
                row['isReviewer'] = true;
                break;
              case AppGlobals.APPROVER:
                row['isApproverChecked'] = false;
                row['selectedRoles'] = row['roles'];
                row['isApprover'] = true;
                break;
              default:
                row['selectedRoles'] = [];
            }
          });
        });
      }, err => {
        // alert('error');
        this.messageSvc.sendMessage({ message: 'Get user list is failed', type: 'INFO', hideboard: true });
      });
  }*/

  addUserRole(boolean: boolean) {
    const selectedProjectApproverList = this.rightGridDataSource.filter(item => item.isApproverChecked === true);
    const selectedProjectReviewerList = this.rightGridDataSource.filter(item => item.isReviewerChecked === true);
    //const emptyUserRoleList = this.grid.slickGrid.getSelectedRows().filter(item => item.selectedRoles.length === 0);
    if (selectedProjectApproverList.length === 0 ||
      selectedProjectReviewerList.length === 0) {
      this.errorRibbon = true;
    } else {
      this.errorRibbon = false;
      this.saveUserRole(boolean);
    }
  }

  saveUserRole(bool: boolean) {
    let parent = this;
    const leftGridDataService = parent.matcherFixingTaskGrid.gridService;
    const leftSideGridDataSet = parent.matcherFixingTaskGrid.slickGrid.getSelectedRows();

    leftSideGridDataSet.forEach(index => {
      const row = leftGridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        parent.rightGridDataSource.map(resp => {
          if (resp.isApproverChecked === true || resp.isReviewerChecked === true) {
            parent.selectedUserRoles.push({ user_id: resp.user_id, role_id: resp.selectedRoleID, cluster_id: row.cluster_id });
          }
        });
      }
    });

    this.projectSvc.saveTasksRoles(this.loggedInUserDetails['user_id'], this.projectDetail.project_id,
      this.selectedUserRoles).subscribe(resp => {

      }, err => {
        this.selectedUserRoles = [];
      });

  }

  goToPage(n: number): void {
    this.page = n;
    this.matchingRemediationTaskList(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.matchingRemediationTaskList(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.matchingRemediationTaskList(this.page, this.limit);
  }

  closeModal() {
    this.zettaUtilsSvc.removeGridAutoScrollTogetherEvent('tm-modal');
    $('#tm-modal').modal('hide');
    $('#tm-modal').addClass('d-none');
  }

  onMatcherTaskGridCellClicked(event) {
    const eventEle = event && event.eventData ? event.eventData : null;
    const args = event.args;
    const row = this.matcherFixingTaskGrid.gridService.getDataItemByRowIndex(args.row);
    const clickedCellIndex = args.cell;
    let isClickedOnGraduateHat = eventEle && eventEle.target && eventEle.target.className && eventEle.target.className.includes('fa-graduation-cap') ? true : false;
    let isClickedOnBlueTextTaskName = eventEle && eventEle.target && eventEle.target.className && eventEle.target.className.includes('blueLink') ? true : false;
    if ((clickedCellIndex === this.matcherFixingTaskGrid.slickGrid.getColumnIndex('blue_hat_open_task') && isClickedOnGraduateHat) || 
    (clickedCellIndex == this.matcherFixingTaskGrid.slickGrid.getColumnIndex('zs_cluster_identifier') && isClickedOnBlueTextTaskName) || (clickedCellIndex == this.matcherFixingTaskGrid.slickGrid.getColumnIndex('tasktype'))) {
      this.selectedClusterId = row.zs_cluster_id;
      this.selectedJobId = row.zs_job_id;
      this.selectedTaskName = row.summary;
      this.selectedTaskType = row.tasktype;
      this.taskType = row.taskType;
      this.selectedTaskStatus = row.task_status;
      this.openMatcherFeedbackModel = true;
      this.shouldMoveMatchFixingData = this.validateMatchFixingTasksDataShouldBeMerged(row);
      this.shouldDisableMatchFixingTasks = (row.task_status === AppGlobals.TASK_COMPLETED || row.task_status === AppGlobals.TASK_REVIEWED_STATUS) ? true : false;
    }
  }

  resetMatcherTaskGridStates() {
    this.selectedClusterId = null;
    this.selectedTaskName = null;
    this.selectedTaskType = null;
    this.taskType = null;
    this.selectedTaskStatus = null;
    this.shouldDisableMatchFixingTasks = true;
    this.shouldMoveMatchFixingData = false;
    this.selectedJobId = null;
  }

  onMatcherFeedbackModalClosed($event) {
    this.openMatcherFeedbackModel = false;
    this.resetMatcherTaskGridStates();
  }

  onReloadFixingDataGrid($event) {
    if ($event) {
      this.openMatcherFeedbackModel = false;
      this.matchingRemediationTasksData = [];
      if (this.matcherFixingTaskGrid) {
        this.matcherFixingTaskGrid.dataView.setItems(this.matchingRemediationTasksData);
      }
      this.isShowRightGrid = false;
      this.showViewTaskAssignmentsButton = false;

      this.shouldRefreshTaskStatistics = true;
      this.matchingRemediationTaskList(1, this.limit);

      if ($event.shouldRunDeltaMerger && $event.completed) {
          // call algo API from here
          this.runDeltaMatcherAlgoAPI();

      } else {
        this.resetMatcherTaskGridStates();
      }

    }
  }

  validateMatchFixingTasksDataShouldBeMerged(rowData) {
    if (!this.isProjectDataLoaded) {
      return false;
    }
    if (rowData && rowData.task_status) {
        if (this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_PENDING_FOR_APPROVAL) {
          return true;
        }

        if (!this.isProjectFourEyeCheckOn && rowData.task_status === AppGlobals.TASK_IN_REVIEW) {
          return true;
        }
    }
    return false;  
  } 

  runDeltaMatcherAlgoAPI() {
    // call API here
    this.selectedJobId = this.selectedJobId ? this.selectedJobId : this.zettaUtilsSvc.generateUUID();
    let self = this;
    this.projectSvc.runDeltaMatcherAlgoJob('stage8', this.selectedJobId, this.projectDetail.project_id,
    this.selectedClusterId).subscribe((res) => {
      self.resetMatcherTaskGridStates();
    }, (err) => {
      self.resetMatcherTaskGridStates();
    })
  }

  onReloadScreen($event) {
    if (this.matcherFixingTaskGrid) {
      this.isShowRightGrid = false;
      this.matchingRemediationTasksData = [];
      this.matcherFixingTaskGrid.dataView.setItems(this.matchingRemediationTasksData);
      this.matchingRemediationTaskList(1, this.limit);
    }
  }

  checkForTaskTypes() {
    let reviewerTasks = this.selectedTasks.every(task => task.status == 'In Review' || task.task_status == 'In Review');
    let approverTasks = this.selectedTasks.every(task => (task.status == 'Pending For Approval' || task.status == 'Reviewed') || (task.task_status == 'Pending For Approval' || task.task_status == 'Reviewed'));
    let inReviewTasks = this.selectedTasks.some( task => task.status == 'In Review' || task.task_status == 'In Review') 
    let inPendingTasks = this.selectedTasks.some(task => (task.status == 'Pending For Approval' || task.status == 'Reviewed') || (task.task_status == 'Pending For Approval' || task.task_status == 'Reviewed'));
  
    if (reviewerTasks) {
      this.rightGridColumnsDef[3]['formatter'] = reviewerCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = disabledCheckBoxFormatter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(approverTasks) {
      this.rightGridColumnsDef[3]['formatter'] = disabledCheckBoxFormatter;
      this.rightGridColumnsDef[4]['formatter'] = approverCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = false;
    } else if(inReviewTasks && inPendingTasks) {
      this.rightGridColumnsDef[3]['formatter'] = reviewerCheckBoxFormattter;
      this.rightGridColumnsDef[4]['formatter'] = approverCheckBoxFormattter;
      this.taskAssignDetails['diffTaskStatusSelected'] = true;
    } 
  }

  navigateBack() {
    if(this.router.url.includes('production-stage-details')) {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}/production-stage-details`], {queryParamsHandling: 'merge'});
    } else {
      this.router.navigate([`/zs/projects/${this.projectDetail.project_id}`]);
    }
  }

}
