import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../content.service';
import { ZettaUtils } from 'src/app/common/shared/zettaUtils';

@Component({
  selector: 'zetta-data-processed',
  templateUrl: './data-processed.component.html',
  styleUrls: ['./data-processed.component.scss']
})
export class DataProcessedComponent implements OnInit {
  totalProcessedData: Number = 0;
  isLoading: Boolean = true;

  constructor(public contentSvc: ContentService, private zettaUtils: ZettaUtils) { }

  ngOnInit() {
    this.getProcessedData();
  }

  getProcessedData() {
    this.contentSvc.getConsumptionReport().subscribe((data) => {
      this.isLoading = false;
      let newVal = this.zettaUtils.truncateDecimals(Number(data.processed_data_in_gb), 3);
      this.totalProcessedData = newVal;
    }, error => {
      this.isLoading = false;
    });
  }

}
