
import { Column, Formatter} from 'angular-slickgrid';

export const notSureFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any) =>{
    if (columnDef.params.shouldDisableFeedback) {
        if (dataContext.zs_feedbackid === '2') {
            return `<div class='formatter match-radio-formatter' style="pointer-events: none; opacity: 0.4;">
                      <input type='radio' name='' checked disabled /><label class="radio-formatter radio-label notsure-formatter"></label>
                    </div>`;
        } else {
            return `<div class='formatter match-radio-formatter' style="pointer-events: none; opacity: 0.4;">
                      <input type='radio' name='' disabled /><label class="radio-formatter radio-label notsure-formatter"></label>
                    </div>`;
        }
    } else {
        let anchor_record = ' ';
        if (dataContext.zs_feedbackid === '2' && dataContext.is_anchor_record === false) {
            return `<div class='formatter match-radio-formatter'>
                      <input type='radio' name='' checked /><label class="radio-formatter radio-label notsure-formatter"></label>
                    </div>`;
        } else if (dataContext.is_anchor_record === true) {
            return `<div class="w-100 h-100 p-0 m-0 anchor-record">${anchor_record}</div>`;
        } else {
            return `<div class='formatter match-radio-formatter'>
                      <input type='radio' name='' /><label class="radio-formatter radio-label notsure-formatter"></label>
                    </div>`;
        }
    }
}

// dataContext.zs_feedbackid === '2' ?
// `<div class='formatter match-radio-formatter'>
//   <input type='radio' name=''  checked />  <label class="radio-formatter notsure-formatter"></label>
// </div>` :
// `<div class='formatter match-radio-formatter'>
//   <input type='radio' name='' /> <label class="radio-formatter notsure-formatter"></label>
// </div>`;
