import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ColDef, Column, GridApi, GridOptions, ITextFilterParams, RowDragEndEvent, RowDragEnterEvent, RowNode} from "ag-grid-community";
import { HttpClient } from "@angular/common/http";
import { AgGridAngular } from "ag-grid-angular"; 
import { ZettaUtils } from "../../shared/zettaUtils";
import { NoRowOverlayComponent } from "../no-row-overlay/no-row-overlay.component";
import { AppGlobals } from "../../shared/app.globals";
import { MessageService } from "../message/message.service";


@Component({
  selector: "fs-grid",
  templateUrl: "./fs-grid.component.html",
  styleUrls: ["./fs-grid.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class FsGridComponent implements OnInit {
  showLoader = false;
  gridApi: any;
  columnApi: any;
  filterInProcess = false;
  @ViewChild("agGrid") agGrid: AgGridAngular;
  @Input() rowData: any;
  @Input() totalRecordsBypass: boolean = false;
  @Input() tableSetting: any;
  @Input() initialFilterload: boolean = false;
  @Input() queryParamsValue: any;
  @Input() gridOptions: GridOptions;
  @Input() columnDefs: ColDef[] = [];
  @Input() agRowModelType?: string;
  @Input() class?: string;
  @Input() pageSize?: number;
  @Input() pagination?: boolean;
  @Input() singleClickEdit: boolean = false; 
  @Input() enableContextMenuEdit: boolean = false; 
  @Input() enableContextMenuDelete: boolean = false;
  @Input() gridEvenRowBgColor?: string;
  @Input() gridOddRowBgColor?: string;
  @Input() hasBestPrediction?: boolean = false;
  @Input()  hasExport?: boolean;
  @Input()  hasClone?: boolean;
  @Input()  hasDelete?: boolean;
  @Input() suppressRowClickSelection?: boolean = false;
  @Input() suppressRowTransform?: boolean = false;
  @Input() hideSpinnerOnCurrentPageLoad?: boolean = false;
  @Input() enableEdit = false;
  @Input() enableClone = true;
  @Input() showCloneOption = true;
  @Input() enableDelete = false;
  @Input() isCatalogAdmin? = false;
  @Input() showPaginateLoader?: boolean = false;
  @Input() passPaginatedData?: boolean = false;
  @Output() agGridReady = new EventEmitter<any>();
  @Output() recvTotalRecords = new EventEmitter<any>();
  @Output() receivePaginatedData = new EventEmitter<any>();
  @Output() onDropdownValue = new EventEmitter<any>();
  @Output() agGridScroll = new EventEmitter<any>();
  @Output() agCellClick = new EventEmitter<any>();
  // @Output() sortColumnEvent = new EventEmitter<any>();
  @Output() sortColumnEvent: EventEmitter<{}> = new EventEmitter();
  @Output() agFilterChanged = new EventEmitter<any>();
  @Output() agSortChanged = new EventEmitter<any>();
  @Output() editObject = new EventEmitter<boolean>();
  @Output() deleteObject = new EventEmitter<boolean>();
  @Output() cloneObject = new EventEmitter<boolean>();
  @Output() exportObject = new EventEmitter<boolean>();
  //Select rows single/Multiple
  @Output() rowSelectionChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() dragRowStart: EventEmitter<{}> = new EventEmitter();
  @Output() dragRowEnd: EventEmitter<{}> = new EventEmitter();
  @Output() OnErrorRetrievingData = new EventEmitter();

  defaultColDef: ColDef = {};
  fsGridOptions: GridOptions;
  fsGridcolumnDefs: ColDef[] = [];
  filterModel: any;
  sortModel: any;
  pageNo = 1; 
  totalPages = 1;
  totalRecords: number; 
  subTotalRecords: number;
  searchFilterApplied: boolean = false;
  customFilterOption = {
    // filterOptions: ["Contains"],
    debounceMs: 300,
    api: null,
  };

 
  constructor(
    private http: HttpClient,
    private zettaUtils: ZettaUtils,
    private messageSvc: MessageService, 
  ) {}

  ngOnInit() {
    this.fsGridOptions = {   
      context: {
        componentParent: this
    },
      suppressDragLeaveHidesColumns: true,
      rowSelection: "multiple",
      pinnedTopRowData: this.getGridGridOption("floatingFilter")
        ? this.getGridGridOption("floatingFilter")
        : [],
      paginationPageSize: this.pageSize
        ? this.pageSize
        : AppGlobals.DEFAULT_PAGE_SIZE,
      cacheBlockSize: this.pageSize
        ? this.pageSize
        : AppGlobals.DEFAULT_PAGE_SIZE,
      floatingFilter: this.getGridGridOption("floatingFilter")
        ? this.getGridGridOption("floatingFilter")
        : false,
      // rowSelection: this.getGridGridOption('rowSelection'),
      rowHeight: this.getGridGridOption("rowHeight")
        ? this.getGridGridOption("rowHeight")
        : AppGlobals.ROW_HEIGHT,
      floatingFiltersHeight: this.getGridGridOption("floatingFiltersHeight")
        ? this.getGridGridOption("floatingFiltersHeight")
        : AppGlobals.FILTER_ROW_HEIGHT,
      headerHeight: this.getGridGridOption("headerHeight")
        ? this.getGridGridOption("headerHeight")
        : AppGlobals.hEADER_ROW_HEIGHT,
        rowDragManaged: this.getGridGridOption("rowDragManaged") ? true : false,
        suppressRowClickSelection: this.getGridGridOption("suppressRowClickSelection") ? true : false,        
        suppressRowTransform: this.getGridGridOption("suppressRowTransform") ? true : false, 
      enableCellTextSelection: this.getGridGridOption("enableCellTextSelection") ? 
      this.getGridGridOption("enableCellTextSelection") : false,

      getRowStyle: (params) => {
        if (params.node.rowIndex % 2 === 0) {
          return {
            background: this.gridEvenRowBgColor
              ? this.gridEvenRowBgColor
              : AppGlobals.EVEN_ROW_BG_COLOR,
          };
        }
        return {
          background: this.gridOddRowBgColor
            ? this.gridOddRowBgColor
            : AppGlobals.ODD_ROW_BG_COLOR,
        };
      },

      noRowsOverlayComponentFramework: <{ new (): NoRowOverlayComponent }>(
        NoRowOverlayComponent
      ),
     
      noRowsOverlayComponentParams: { noRecords: AppGlobals.NO_DATA_MSG },
      maxBlocksInCache: 10, // pages of data being cached so when we scroll back no API call is made untill this value
      blockLoadDebounceMillis: 500//
    };
    this.initializeGridColumns(this.columnDefs);
    this.defaultColDef = {
      resizable: true,
      editable: true, 
      flex: 1,
      filterParams: { newRowsAction: "keep" },
    };

    //this.autoSizeAllColumns();
  }
  getRowStyle = (params) => {
    if (params.node.rowIndex === 0) { // Rows are zero-based, so 2 represents the third row
      return { position: 'sticky', top: '0', zIndex: '1', backgroundColor: 'white' };
    }
    return null; // Return null for other rows to keep their default style
  };
  
  onDropdown( optionObj: any){ 
    this.onDropdownValue.emit(optionObj)
  }

  emitEntityStateObservable(state: boolean) {
    this.zettaUtils.setEntityStateChanged(state);
  }

  // rowSelection change for parent component
  onSelectionChanged() {
    const selectedRows = this.fsGridOptions.api.getSelectedRows() || [];
    const rowSelection = selectedRows.length === 1 ? "single" : "multiple";
    this.rowSelectionChanged.emit(rowSelection);
  }

  getGridGridOption(property: string) {
    if (this.gridOptions && this.gridOptions[property] !== null) {
      return this.gridOptions[property];
    }
  }
  initializeGridColumns(columnDef: Array<Object>) {
    const gridCols = [];
    for (const col in columnDef) {
      if (columnDef.hasOwnProperty(col)) {
        const findColumnObj = columnDef[col];
        if (findColumnObj) {
          const columnObj = {};
          columnObj["field"] = findColumnObj["field"]; 
          columnObj["pinned"] = findColumnObj["pinned"];
          if(findColumnObj["colId"]){
            columnObj["colId"] = findColumnObj["colId"];
          } 
          columnObj["tooltipField"] = findColumnObj["tooltipField"]; 
          columnObj["cellStyle"] = findColumnObj["cellStyle"];
          columnObj["headerName"] = findColumnObj["headerName"];
          columnObj["filter"] = findColumnObj["filter"];  
          columnObj["lockPinned"] = findColumnObj["lockPinned"];  
          columnObj["cellClass"] = findColumnObj["cellClass"];
          columnObj["comparator"]= findColumnObj["comparator"];
          columnObj["headerClass"] = findColumnObj["headerClass"];
          columnObj["editable"] = findColumnObj["editable"]
            ? findColumnObj["editable"]
            : false;
            columnObj["singleClickEdit"] = findColumnObj["singleClickEdit"]
            ? findColumnObj["singleClickEdit"]
            : false;
          columnObj["editable"] = findColumnObj["editable"] 
            ? findColumnObj["editable"]
            : false;
          columnObj["floatingFilter"] = findColumnObj["floatingFilter"]
            ? findColumnObj["floatingFilter"]
            : false;
            columnObj["suppressColumnsToolPanel"] = findColumnObj["suppressColumnsToolPanel"]
            ? findColumnObj["suppressColumnsToolPanel"]
            : false;
          columnObj["sortable"] = findColumnObj["sortable"]
            ? findColumnObj["sortable"]
            : false;
          columnObj["minWidth"] = findColumnObj["minWidth"];
          columnObj["autoHeight"] = findColumnObj["autoHeight"];
                    columnObj["wrapText"] = findColumnObj["wrapText"];
          columnObj["checkboxSelection"] = findColumnObj["checkboxSelection"]
            ? findColumnObj["checkboxSelection"]
            : false;
          columnObj["headerCheckboxSelection"] = findColumnObj[
            "headerCheckboxSelection"
          ]
            ? findColumnObj["headerCheckboxSelection"]
            : false;
          columnObj["rowGroup"] = findColumnObj["rowGroup"]
            ? findColumnObj["rowGroup"]
            : false;
          columnObj["filterParams"] = this.customFilterOption;
          
          columnObj["menuTabs"] = [
            "generalMenuTab", 
            "columnsMenuTab",
          ];
          if (findColumnObj["sortable"]) {
            columnObj["sortingOrder"] = ["asc", "desc"];
          }
          if (findColumnObj["cellClass"]) {
            columnObj["cellClass"] =
              findColumnObj["cellClass"];
          }
          if (findColumnObj["cellRenderer"]) {
            columnObj["cellRenderer"] =
              findColumnObj["cellRenderer"];
          }
          if (findColumnObj["cellRendererFramework"]) {
            columnObj["cellRendererFramework"] =
              findColumnObj["cellRendererFramework"];
          }
          if (findColumnObj["floatingFilterComponentParams"]) {
            columnObj["floatingFilterComponentParams"] =
              findColumnObj["floatingFilterComponentParams"];
          }
          if (findColumnObj["cellRendererParams"]) {
            columnObj["cellRendererParams"] =
              findColumnObj["cellRendererParams"];
          } 
          if (findColumnObj["maxWidth"]) {
            columnObj["maxWidth"] = findColumnObj["maxWidth"];
          }
          if (findColumnObj["minWidth"]) {
            columnObj["minWidth"] = findColumnObj["minWidth"];
          }
          if (findColumnObj["autoHeight"]) {
            columnObj["autoHeight"] = findColumnObj["autoHeight"];
          }
          if (findColumnObj["wrapText"]) {
            columnObj["wrapText"] = findColumnObj["wrapText"];
          }
          if (findColumnObj["headerComponentParams"]) {
            columnObj["headerComponentParams"] = findColumnObj["headerComponentParams"];
          }
          if (findColumnObj["width"]) {
            columnObj["width"] = findColumnObj["width"];
          }
          if (findColumnObj["rowDrag"]) {
            columnObj["rowDrag"] = findColumnObj["rowDrag"];
          }
          if (findColumnObj["headerCheckboxSelection"]) {
            columnObj["headerCheckboxSelection"] = findColumnObj["headerCheckboxSelection"];
          }
          gridCols.push(columnObj);
        }
      }
    }
    this.fsGridcolumnDefs = gridCols;
  }

  onGridReady(paramsGrid) {
    this.gridApi = paramsGrid.api;
    this.columnApi = paramsGrid.columnApi;
    paramsGrid.api.hideOverlay();
    // paramsGrid.api.hideOverlay();
    this.getAgGridDataSource(this);
   
    this.agGridReady.emit(this.agGrid);  
    paramsGrid.api.sizeColumnsToFit();
  }  

    //TODO: Render data at initial load 
    onFirstDataRendered(params) {  
      if(this.initialFilterload){
        let classFilter = this.queryParamsValue.filter((obj: any)=>{
          return obj.resultFilterKey === 'class'
        }); 
    
        if(classFilter && classFilter.length){
          let filterComponent = params.api.getFilterInstance(classFilter[0].resultFilterKey); 
          filterComponent.setModel({ 
              filter: classFilter[0].resultFilterVal,
              type: "equals",
              fiterType: 'text'
          }); 
          filterComponent.onFilterChanged();
          console.log("filterComponent :", filterComponent);
        } 
      }
    } 
  
 
  onFilterChanged(param) {
    this.pageNo = 1;
  }

  onScroll(event) { 
    if (this.zettaUtils.agGridVirtualScroll("ag-theme-flureesense")) { 
      this.agGridScroll.emit(true);
    }
  }

  onCellClick(event) {
    this.agCellClick.emit(event);
  }
 
  onSortChanged(event) { 
    this.pageNo = 1;  
    // this.sortColumnEvent.emit(true);
  }

  onModelUpdated(event) {
    if (this.fsGridOptions.api) {
      if (this.fsGridOptions.api.getDisplayedRowCount() > 0) {
        this.fsGridOptions.api.hideOverlay();
      }
      if (this.fsGridOptions.api.getDisplayedRowCount() === 0) {
        this.fsGridOptions.api.showNoRowsOverlay();
      }
    }
  }

  onEditClick() { 
    this.editObject.emit(true);
  }

  onExportClick() {
    this.exportObject.emit(true);
  }

  onDeteleClick() { 
    this.deleteObject.emit(true);
  }

 
  onCloneClick() {
    this.cloneObject.emit(true);
  }
  
  getAgGridDataSource(parent) {   
    const datasource = {
      
      getRows(params) { 
        parent.pageNo = parent.getCurrentPageNo(params);  
        if (parent.totalPages >= parent.pageNo) { 
          let paginationQuery = "";
          let searchAndSortTerm = "";
          
          if (parent.pagination) {
            if(parent.showPaginateLoader) {
              parent.showLoader = true;
            }
            searchAndSortTerm = parent.getSearchAndSortTerms(params);
            
            const queryParams = parent.tableSetting.api_url.includes("?")
              ? "&"
              : "?";
            paginationQuery = `${queryParams}paginate=true&pageno=${parent.pageNo}&pagesize=${parent.pageSize}${searchAndSortTerm}`;
          } 
          parent.http
            .get(`${parent.tableSetting.api_url}${paginationQuery}`)
            .subscribe(
              (data) => {
              parent.showLoader = false;
              let totalRecords = 0; 
              if(parent.totalRecordsBypass){ 
                const filterKeys = params.request.filterModel; 
                if(data["currentpage"] && filterKeys &&  Object.keys(filterKeys).length > 0) {
                 //  totalRecords =  (data["currentpage"].length < parent.pageSize) ?  data["currentpage"].length : data["totalrecords"];  
                  totalRecords = data["currentpage"].length;
                } else {
                  totalRecords = (data['pageno'] == 1 && (data["currentpage"].length < data.pagesize)) ? data["currentpage"].length : data["totalrecords"];
                }
              } else { 
                  totalRecords = data["totalrecords"]; 
              }
 
              parent.rowData = data["currentpage"];   
                if (parent.pageNo === 1) {
                  this.grandTotal =  parent.totalRecords;
                  this.subTotal = parent.subTotalRecords;
                  if (searchAndSortTerm && data["totalrecords"]) {
                    parent.subTotalRecords = totalRecords; 
                    parent.totalRecords = totalRecords;
                  } else {
                    parent.subTotalRecords = totalRecords;
                  }
                  if (data.totalpages) {
                    parent.totalPages = data.totalpages;
                  }
                } 

                if (!data["currentpage"].length) {
                  params.api.setRowData(this.rowData);
                  params.api.showNoRowsOverlay();
                  params.successCallback([], 0);

                } else {
                  if(parent.hideSpinnerOnCurrentPageLoad) {
                    params.successCallback(
                      data["currentpage"],
                      data.next ? -1 : totalRecords
                    ); 
                  } else {
                    params.successCallback(
                      data["currentpage"],
                      totalRecords
                    ); 
                  }
                }
                parent.recvTotalRecords.emit({ 
                  subTotal: totalRecords
                }); 
                if(parent.passPaginatedData) {
                  parent.receivePaginatedData.emit({data: data["currentpage"]});
                }
              },
              (error) => {
                parent.showLoader = false;
                parent.OnErrorRetrievingData.emit({error: true, errorData: error});
                this.messageSvc.sendMessage({
                  message: error.error.message,
                  type: "ERROR", 
                  hideboard: true,
                });
                params.failCallback();
              }
            );
        }
      },
    };
    if (parent.totalPages >= parent.pageNo) {
      parent.gridApi.setServerSideDatasource(datasource);
    }
  }

  getCurrentPageNo(params) { 
    const startRow = params.request.startRow; 
    const endRow = params.request.endRow; 
    let pageNo = 1;
    if (startRow > 0) {  
      let currentPage = endRow / this.pageSize + "";

      const newPage = currentPage.split("."); 
      if (newPage.length > 1) {
          currentPage = (+newPage[0] + 1).toString();
      } 

      pageNo = +currentPage; 
    }
    return pageNo;
  }

  getSearchAndSortTerms(params) {
    let filterStr = "";
    let sortStr = "";
    let searchAndSortTerm = ""; 
    const filterKeys = params.request.filterModel;
    const sortingKeys = params.request.sortModel;    
    if (filterKeys) { 
      Object.entries(filterKeys).forEach(([key, value], index) => { 
        filterStr += `&${key}=${value["filter"]}`;
      });

    }

    // if (sortingKeys) {
    //   sortingKeys.forEach((sortKey) => {
    //     sortStr += `&orderby=${sortKey.colId}&type=${sortKey.sort}`;
    //   });
    // }

    if (sortingKeys) {
      sortingKeys.forEach((sortKey) => { 
        if (this.hasBestPrediction && sortKey.colId === "class") {
          sortKey.colId = 'best_prediction';
          sortStr += `&orderby=${sortKey.colId}&type=${sortKey.sort}`;
        } else {
          sortStr += `&orderby=${sortKey.colId}&type=${sortKey.sort}`;
        }
      });
    }
    if(filterStr && filterStr.length>0) {
      this.searchFilterApplied = true;
    } else {
      this.searchFilterApplied = false;
    }
    if (filterStr || sortStr) {
      // this.gridApi.showLoadingOverlay();  
      this.showLoader = true;
      searchAndSortTerm = filterStr + sortStr;
    }
    // setTimeout(()=>{
    //   this.showLoader = false;
    // },1500); 
    return searchAndSortTerm;
    
  }

  onRowDragEnter(e: RowDragEnterEvent) {
    this.dragRowStart.emit(e);
  }

  onRowDragEnd(e: RowDragEndEvent) {
    this.dragRowEnd.emit(e);
  }  
}
