import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ColDef, GridOptions, GridApi } from 'ag-grid-community';
import { BaseChartDirective, Label } from 'ng2-charts';
import { ChartType, ChartDataSets, ChartConfiguration, Chart } from 'chart.js';
import { AgGridAngular } from 'ag-grid-angular';
import { AngularGridInstance } from 'angular-slickgrid';
import { BlueTextFormatterComponent } from '../cell-renderer/blue-Text-Formatter/blue-Text-Formatter.component';
import { AppGlobals } from '../app.globals';
import { ObservableService } from '../../services/observable.service';
import { SearchDropdownRendererComponent } from '../cell-renderer/custom-cell-render-formatter/search-dropdown-formatter';
import { Project } from 'src/app/zettaclassify/zs-cl-content/classify.model';
import { ZettaUtils } from '../zettaUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../components/message/message.service';
import { ZsClContentService } from 'src/app/zettaclassify/zs-cl-content/zs-cl-content.service';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../services/base-service';
import { DateFormatterFormatterComponent } from '../cell-renderer/date-formatter/date-formatter.component';
import { dateFormatter } from '../formatters/dateFormatter';
import { RightArrowIconFormatter } from '../cell-renderer/right-arrow-icon-formatter/right-arrow-icon-formatter';
import { RightArrowIconTextFormatterComponent } from '../cell-renderer/right-arrow-text-formatter-component/right-arrow-text-formatter-component';
declare var $: any;

@Component({
  selector: 'zetta-classifier-result-summary-modal',
  templateUrl: './classifier-result-summary-modal.component.html',
  styleUrls: ['./classifier-result-summary-modal.component.scss']
})
export class ClassifierResultSummaryModalComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() onModalClose = new EventEmitter<boolean>();
  @Output() onSelectedClass = new EventEmitter<any>();
  rightGridDataSource: any = [];
  rightGridParams: any = [];
  rightGridColumnsDef = [];
  gridApi : GridApi;
  grid: AngularGridInstance;
  rightGrid: AngularGridInstance;
  agGrid: AgGridAngular;
  loggedInUserDetails;
  projectDetail = new Project();
  graphData: Array<any> = [];
  valuedGraphData: Array<any> = [];
  graphLabels: Array<object> = [];
  classifierPredictorData: Array<any> = [];
  grandTotal = 0;
  subTotal = '';
  rightTableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 100, 'isNormalPagination': false };
   fsGridOptions: GridOptions = { 
        headerHeight: 45, 
        rowHeight: 30,
        floatingFiltersHeight: 49,
        suppressRowClickSelection: true,
        suppressRowTransform: true,
        getRowStyle: params => {
          if (params.node.rowIndex % 2 === 0) {
            return { background: AppGlobals.EVEN_ROW_BG_COLOR };
          }
          return { background: AppGlobals.ODD_ROW_BG_COLOR };
        },
      };
  classList = [];
public barChartLegend = false;
public barChartPlugins = [];
public barChartData = [];
enableApplyBtn: boolean = false;
updatedClassPayload : Array<object> = [];
pageNo = 1;
limit = 100;
isNotAdmin: boolean = false;
public chartColors: any[] = [{
    backgroundColor: '#EFB049'
  }];
public barChartType = 'horizontalBar';
@ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
public barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  barRoundness:1,
  indexAxis: 'y',
  curvature: 1,
  cornerRadius: 20,
  scales: {
    xAxes: [
      {
        display: false,
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        grid: {
          offset: true
        }
      },
    ],
    yAxes: [
      {
        display: false,
        barThickness: 10,
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false
 },
  tooltips: {
    enabled: true,
    backgroundColor: '#E4E4E4',
    bodyFontColor: '#5C5C5C',
    displayColors: false,
    position: 'average',
    callbacks: {
      label: function (tooltipItem, data) {
        return tooltipItem.value + ' Instances';
      },
      title: function() {},
    }
  },
  plugins: {
    roundedBars:{
      borderRadius: 10,
    },
    legend: {
      display: false,
    }
  },
  elements: {
    point: {
      radius: 10,
    },
  },
};
@Input() modalClasses; 
@Input() currentScreen: string = '';
@Input() projectDetailInfo: object = {}; 
showGraphLoader: boolean = false;
showRightLoader: boolean = false;
noData: boolean = false;
public barChartColors = ['#a13bf0', '#a13bf0', '#cca1ed', '#cca1ed'];
public openTaskChartColors = ['#4bb100','#0e709f'];

  constructor( private observeSvc: ObservableService, 
    private baseService: BaseService,
    public zettaUtils: ZettaUtils,
    private activatedRoute: ActivatedRoute,
    private _router : Router,
    private service: ZsClContentService,
    private messageSvc: MessageService,
  ) { }

  ngOnInit() {
    this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectDetail.project_id = params['id'];
    });
    // this.getClassifyProjectData();
    this.getRightClassList();
   this.getLeftClassLists();
   this.getClassifySummaryData();
  //  this.getRightPredictions(this.pageNo, this.limit);
   $('#classifier-results-summary-modal').modal('show');
    this.baseService.addManualClass.subscribe(addClass => {
      if(addClass && addClass === true) {
        this.onAddClass();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.modalClasses && changes.modalClasses.currentValue) {
      this.classList = changes.modalClasses.currentValue;
    }
     if(changes && changes.currentScreen && changes.currentScreen.currentValue) {
      this.currentScreen = changes.currentScreen.currentValue;
    }
     if(changes && changes.projectDetailInfo && changes.projectDetailInfo.currentValue) {
      this.projectDetailInfo = changes.projectDetailInfo.currentValue;
    }
  }

  ngAfterViewInit(): void {
      const parent = this;
  }

  onResize(event) {
  }

  rightGridCreated(grid) {
    this.rightGrid = grid;
    this.agGrid = grid;
    this.gridApi = grid.api;
  }

  onCancel() {
    this.updatedClassPayload = [];
    this.rightGridParams = [];
    this.onModalClose.emit(false);
  }

  hideConfigModal(eve) {
    this.updatedClassPayload = [];
    this.rightGridParams = [];
    this.onModalClose.emit(false);
  }

  onDropdownValueChanged(eve) {
    if(eve && eve.newValue && eve.newValue !== 'NOT-SELECTED') {
      if(this.updatedClassPayload && this.updatedClassPayload.length>0) {
        let foundInd = this.updatedClassPayload.findIndex(ele => ele['indexedAt'] == eve.rowIndex);
        if(foundInd >=0) {
          this.updatedClassPayload[foundInd] =  {
            "indexedAt": eve.rowIndex,
            "candidate_class": eve.oldValue.class,
            "existing_class": eve.newValue
        };
        } else {
          this.updatedClassPayload.push(
            {
              "indexedAt": eve.rowIndex,
              "candidate_class": eve.oldValue.class,
              "existing_class": eve.newValue
          }
          );
        }
      } else {
        this.updatedClassPayload.push(
          {
            "indexedAt": eve.rowIndex,
            "candidate_class": eve.oldValue.class,
            "existing_class": eve.newValue
        }
        );
      }
    } else if(eve.newValue == 'NOT-SELECTED') {
      let idx = this.updatedClassPayload.indexOf(p => p['indexedAt'] == eve.rowIndex);
      let removed = this.updatedClassPayload.splice(idx, 1);     
      console.log('the payload is here ======== ', idx, 'removed is' , removed, '  updatedclasspay  ', this.updatedClassPayload);
      
    }
    this.checkForEnableApplyBtn();
  }

  checkForEnableApplyBtn() {
    if(this.updatedClassPayload && this.updatedClassPayload.length > 0) {
      this.enableApplyBtn = true 
    } else {
      this.enableApplyBtn = false;
    }
  }

  onApplyChanges() {
    this.messageSvc.sendMessage({
      message: "This will replace all instances of the candidate class with the pre-existing one. This cannot be reversed. Are you sure want to continue?",
      type: "INFO",
      showInfoOnly: true,
    });
  }

  onAddClass() {
    if(this.updatedClassPayload && this.updatedClassPayload.length>0) { 
      this.service.updatePredictionClasses(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id, this.updatedClassPayload)
      .subscribe(resp => {
        if(resp) {
          this.baseService.addManualClass.next(false);
          this.showGraphLoader = true;
          this.showRightLoader = true;
          this.updatedClassPayload = [];
          this.graphData = [];
          this.graphLabels = [];
          this.rightGridDataSource = [];
          this.rightGridParams = [];
          this.classifierPredictorData = [];
          this.valuedGraphData = [];
          this.getLeftClassLists();
          this.getRightPredictions(this.pageNo, this.limit);
          this.enableApplyBtn = false;
        }
      }, (err) => {
        this.messageSvc.sendMessage({ message: (err && err.error && err.error.message) ? err.error.message : 'Oops Something Went wrong!', type: 'ERROR', hideboard: true });
      });
    }
  }

  onRightGridCellClicked(event) {
    const fieldName = event.colDef.field;
    let classList = event.event.target.classList;
    let row = event.data
    if (row !== undefined && row !== null) {
      if(fieldName && fieldName == 'class' && classList.contains('blueLink')) {
        this.navigateToViewResults(row.class);
      } else {
        return;
      }
    }
  }

  navigateToViewResults(selectedClass) {
      selectedClass = selectedClass !== 'Others' ? selectedClass  : ""
    if(this.currentScreen == 'classifyViewResults') {
      this.onModalClose.emit(false);
      $('#classifier-results-summary-modal').modal('hide');
       this._router.navigate(['../view-result'], {relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge', queryParams: { class: selectedClass }  })
        this.onSelectedClass.emit({class: selectedClass});
    } else if (this.currentScreen == 'classifyTrainModal') {
      // this.setQueryFilter(selectedClass);
      this._router.navigate(['../view-result'], {relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge', queryParams: { class: selectedClass }  });
        this.onCancel();
        $('#classifier-results-summary-modal').modal('hide');
    } else {
      this._router.navigate(['../../view-result'], {relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge', queryParams: { class: selectedClass }  });
        this.onCancel();
        $('#classifier-results-summary-modal').modal('hide');
    }
  }

  setQueryFilter(filter) {
    this.onModalClose.emit(false);
    $('#classifier-results-summary-modal').modal('hide');
    return {class: filter};
  }

  onExportSummary() {
    this.service.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/results_summary/export?candidate=true`)
    .subscribe((res) => {
      console.log('reso: ', res);
      let fileName = 'Candidate_list';
      this.service.exportCsvFiles(res, fileName);
    }, (err) => {
      console.log('er: ', err)  ;
    });

    this.service.exportResult(`${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/results_summary/export?classifier=true`)
    .subscribe((res) => {
      console.log('reso: ', res);
      let fileName = 'Classifier_list';
      this.service.exportCsvFiles(res, fileName);
    }, (err) => {
      console.log('er: ', err)  ;
    });
  }

  getRightPredictions(page, limit=100) {
    this.rightTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/feedback?is_manual_only=true`; 
    this.rightTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/feedback?is_manual_only=true`; 
    this.showRightLoader = true;
    this.service.getClassifyProjectFeedbacks(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id, page, limit)
    .subscribe(respons => {
      if(respons) {
        this.rightGridDataSource = respons['currentpage'];
        if (page === 1) {
          this.grandTotal = respons['totalrecords'];
        }
        this.initRightColDefinitions();
        this.showRightLoader = false;
        if (this.rightGridDataSource.length === 0) {
          this.noData = true;
        }
      }
    }, err => {
    });
  }

  getLeftClassLists() {
    this.showGraphLoader = true;
    let self = this;
    this.service.getClassifierPredictions(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id)
    .subscribe(resp => {
      if(resp && resp.length) {
        resp.forEach(element => {
          self.graphLabels.push(element.class);
          self.graphData.push(element.count);
            self.valuedGraphData.push(element.count);
        });
        
        self.classifierPredictorData = resp;
        self.barChartData = [{ data: self.graphData, label: 'Classes', 
          fill: true,
          bar: {
            barThickness: 50,
            // barPercentage: 0.5,
          },
          backgroundColor: '#5eb8ff',
          // barPercentage: 0.9,
        
          datalabels: {
            display: false
        },
        }];
        setTimeout(() => {
          this.getClassNames();
          self.showGraphLoader = false;
        }, 500);
      } else {
        self.classifierPredictorData = [];
      }
    }, err => {
      self.showGraphLoader = false;
      self.messageSvc.sendMessage({ message: err.error.error, type: 'ERROR', hideboard: true });
    })  
  }

  getRightClassList() {
    this.service.getClassifierClassList(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], this.projectDetail.project_id)
    .subscribe(classes => {
      if(classes) {
        this.classList = classes;
        console.log('the dropdown classes are here ', classes);
      }
    }, err => {
    });
  }

  initRightColDefinitions(classes?) {
    if(this.isNotAdmin) {
      this.rightGridColumnsDef = [
        {
        headerName: 'Candidate Classifier',
        field: 'class', 
        sortable: true, 
        editable: false, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRendererFramework: RightArrowIconTextFormatterComponent,
        cellRendererParams: { showArrow: true },
        floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
        minWidth: 125,
        },
        {
            headerName: 'Suggested By',
            field: 'suggested_by',
            sortable: true, 
            editable: false, 
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // cellRenderer: (params) => acativeLinkFormatter(params),
            floatingFilterComponentParams: {
            suppressFilterButton: true,
            },
            minWidth: 125,
            tooltipField : 'suggested_by'
        },
      {
        headerName: 'Suggested On',
        field: 'created_ts', 
        filter: 'agTextColumnFilter',
        sortable: true, 
        editable: false, 
        minWidth: 125,
        floatingFilter: true,
        cellRendererFramework : DateFormatterFormatterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          },
      }
    ];
    } else {
      this.rightGridColumnsDef = [
      {
        headerName: 'Candidate Classifier',
        field: 'class', 
        sortable: true, 
        editable: false, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellRendererFramework: RightArrowIconTextFormatterComponent,
        cellRendererParams: { showArrow: true },
        floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
        minWidth: 165,
    },
    {
        headerName: 'Suggested By',
        field: 'suggested_by',
        sortable: true, 
        editable: false, 
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        // cellRenderer: (params) => acativeLinkFormatter(params),
        floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
        minWidth: 160,
        tooltipField : 'suggested_by'
    },
  {
    headerName: 'Suggested On',
    field: 'created_ts', 
    filter: 'agTextColumnFilter',
    sortable: true, 
    editable: false, 
    minWidth: 155,
    floatingFilter: true,
    cellRendererFramework : DateFormatterFormatterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      },
  },
  {
    headerName: 'Substitute With Existing Class',
    field: 'classList', 
    suppressRowTransform: true,
    cellClass: ['overflow-visible'],
    filter: 'agTextColumnFilter',
    sortable: false, 
    cellRendererFramework: SearchDropdownRendererComponent, 
    cellRendererParams: { list: this.modalClasses ? this.modalClasses : classes, rowsData: this.rightGridParams },
    minWidth: 150,
    }];
    }
}

checkForAdminAccess(info) {
  if(info && this.loggedInUserDetails) {
    let loggedInUser =   (info && info['users'] && info['users'].length) ? info['users'].find(user => user['user_id'] == this.loggedInUserDetails['user_id']) : null;
    if(loggedInUser) {
           let isAdmin = loggedInUser.roles.find(role => role === AppGlobals.CLASSIFY_PROJECT_ADMIN);
           return isAdmin == AppGlobals.CLASSIFY_PROJECT_ADMIN ? false: true;
          } else {
            return true;
          }
  }
}

getClassifyProjectData() {
  const data = {
    user_id: this.loggedInUserDetails['user_id'],
    tenant_id: this.loggedInUserDetails['tenant_id'],
    project_id: this.projectDetail.project_id
  };
  this.service.getclassifyProject(data).subscribe((projectDetails) => {
    if(projectDetails) {
      this.isNotAdmin = this.checkForAdminAccess(projectDetails);
      this.initRightColDefinitions();
    }
  }, (err) => {
  })
}

receiveTotalRecords($event) { 
  this.subTotal = this.zettaUtils.getSubTotal(this.grandTotal, $event.subTotal);
}

receivePaginatedData($event) {
  let pageData = $event && $event.data? $event.data : [];
  if(pageData && pageData.length) {
    pageData.forEach(element => {
      if(element && element.classify_project_feedback_id) {
        let dropdownSelected = this.rightGridParams.findIndex(e => e.classify_project_feedback_id == element.classify_project_feedback_id);
         if(dropdownSelected<=-1) {
          this.rightGridParams.push(element);
         }
      }
    });
  }
  this.initRightColDefinitions();
}

getClassNames() {
  if(this.valuedGraphData && this.valuedGraphData.length && this.valuedGraphData.length == 1) {
    return 'graph-height-40-px';
  } else if(this.valuedGraphData && this.valuedGraphData.length && this.valuedGraphData.length == 2) {
    return 'graph-height-80-px';
  } else if(this.valuedGraphData && this.valuedGraphData.length && this.valuedGraphData.length == 3){
    return 'graph-height-110-px';
  } else {
    return '';
  }
}

getClassifySummaryData() {
  const data = {
    user_id: this.loggedInUserDetails['user_id'],
    tenant_id: this.loggedInUserDetails['tenant_id'],
    project_id: this.projectDetail.project_id
  };
  this.showRightLoader = true;
  this.rightTableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/feedback?is_manual_only=true`; 
  this.rightTableSettings['api_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/tenants/${this.loggedInUserDetails['tenant_id']}/classify/projects/${this.projectDetail.project_id}/feedback?is_manual_only=true`; 
  this.service.getClassifySummaryData(data, this.pageNo, this.limit)
  .subscribe(resp => {
    if (resp) {
      if (resp.projDetails) {
          this.isNotAdmin = this.checkForAdminAccess(resp.projDetails);
          this.initRightColDefinitions();
          this.showRightLoader = false;
        }
      if(resp.feedbacks) {
          this.rightGridDataSource = resp.feedbacks;
          if (this.pageNo === 1) {
            this.grandTotal = resp.feedbacks['totalrecords'];
          }
          this.showRightLoader = false;
          if (this.rightGridDataSource.length === 0) {
            this.noData = true;
          }
      }
    }
  }, err => {
    this.showRightLoader = false;
  });
}

}
