import { Component, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmZettaUtils } from '../../../../../common/zm-shared/zm-zettaUtils';
import { AnalyticService } from '../../analytic.service';
import { ZmObservableService } from '../../../../../common/zm-services/zm-observable.service';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { Observable } from 'rxjs/Rx';
import { AppGlobals } from '../../../../../common/zm-shared/app.globals';

@Component({
	selector: 'ad-view-predictions', 
	templateUrl: './ad-view-predictions.component.html',
	styleUrls: ['./ad-view-predictions.component.scss']
})

export class AdViewPredictionsComponent implements OnInit {
	public lineChartUniqueID: any = '';
	public isDataReady = false;
	public alive = true;
	public isStepsCompleted = false;
	public screenHeight: number = screen.height;
	
	public currentJobStatusObj: object = {};
	public statisticsObj: object = null;
	public observableRef: any = null;
	public selectedProjectName: string = '';
	public userinfo;
	public currentURL;
	public errorMessage: string;
	public errorDetails: string;

	public entitiesDataSource = null;
	public entitiesChartColors = ['#4bb100', '#eeb049', '#d35162'];

	public chartDS1 = null;
	public chartOptions1: object = {
		// 'height': 320,
		'width': 1450,
		'resizeRequired': true,
		'labelX': 'PREDICTED OUTCOME VALUE',
		'labelY': 'NUMBER OF EVEN OCURRENCES',
		'barColor1': '#57cff2',
		'barColor2': '#57cff2',
		'margin' : { 'top': 20, 'right': 20, 'bottom': 50, 'left': 100 },
		'isMoneyFormat':true
	};

	constructor(public cdRef: ChangeDetectorRef,
		public zettaUtils: ZmZettaUtils,
		private _projectService: AnalyticService,
		public observeSvc: ZmObservableService,
		private activeRoute: ActivatedRoute, private router: Router) {
		this.lineChartUniqueID = this.zettaUtils.generateUUID();
		this.userinfo = this._projectService.getObjectFromSession('userInfo');
		
	}

	ngOnInit() {
		const parent = this;
		const routeParams = parent.activeRoute.snapshot.params;
		parent.currentURL = parent.router.url;
		// parent.getEntityResolvedData(parent.userinfo.userid, routeParams.id);

		if(parent.screenHeight <= 768) {
			parent.chartOptions1['height'] = 200;
		}else{
			parent.chartOptions1['height'] = 400;
		}


		let dataSet1 = [];
		// parent._projectService.getDataSetAttributes('1', 2).subscribe(response => {
			// let numrandom = (Math.floor(Math.random() * (100-10)) + 10);
			for (var i = 0; i < 10; i++) {
				// dataSet.push({ 'year': response[i].column, 'value': response[i].datasetcolumnid });
				// numrandom = numrandom + (Math.floor(Math.random() * (50-1)) + 1);
				// dataSet1.push({ 'label': response[i].column, 'value': Math.floor(Math.random() * (100-10)) + 10 });
				dataSet1.push({ 'label': i + 0.5, 'value': Math.floor(Math.random() * (100-10)) + 10 });
			}
			parent.chartDS1 = dataSet1;
		// },
		// error => { }
		// );
		

		// parent._projectService.getEntitiesSummaryData(parent.userinfo.userid, routeParams.id, 'matcher').subscribe(
		// 	data => {
		// 		parent.statisticsObj = {
		// 			'model_runs': 0,
		// 			'model_runs_as_of': new Date(),
		// 			'unique_institutions': 0,
		// 			'compression_ratio': 'N/A'
		// 		};
				
		// 	for (let index = 0; index < data.length; index++) {
		// 		const obj = data[index];
		// 		if (obj.name === 'run_number') {
		// 			parent.statisticsObj['model_runs'] = obj['value'] || 0;
		// 		}
		// 		else if (obj.name === 'as_of') {
		// 			parent.statisticsObj['model_runs_as_of'] = obj['count'] || new Date();
		// 		} else if (obj.name === 'total_entities') {
		// 			parent.statisticsObj['unique_institutions'] = obj['count'] || 0;
		// 		} else if (obj.name === 'compression_ratio') {
		// 			parent.statisticsObj['compression_ratio'] = obj['count'] || 'N/A';
		// 		} else if (obj.name === 'overall_confidence') {
		// 			parent.statisticsObj['labelPercentValue'] = obj['value'] || 0;
		// 			parent.entitiesChartOptions['labelPercentValue'] = obj['value'] || 0;
		// 		}
		// 	}
		// 		parent.entitiesDataSource = parent.getDonutParseData(data);
		// 	},
		// 	error => {
		// 		parent.entitiesDataSource = [];
		// 		parent.statisticsObj = {'model_runs': 0, 'model_runs_as_of': new Date(), 'unique_institutions': 0, 'compression_ratio': 'N/A'};
		// 	}
		// );
	}

	
	ngOnDestroy() {
		this.zettaUtils.isProcessRunning = false;
		if (this.observableRef) {
				this.alive = false;
			this.observableRef.unsubscribe();
		}
	}

	
	// prepareLineChartData(data) {
	//     const dataSet = [];
	//     data.forEach((element) => {
	//     	const d = new Date(element['createdts']);
	//         dataSet.push({ 'year': d.getFullYear(), 'value': element['over_all_confidence'], 'fulldate': d });
	//     });
	//     return dataSet;
	//   }
	  
	  refresh() {
		window.location.reload();
	  }
}
