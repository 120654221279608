import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ZettaUtils } from '../../../common/shared/zettaUtils';
import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { ZsClContentService } from '../zs-cl-content.service';
import { blueTextFormattter } from '../../../common/shared/formatters/blueTextFormatter';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../common/components/message/message.service';
import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';

const numericFormatter: Formatter =(row: number, cell: number, value: any, columnDef: Column, dataContext: any) => {
  if(dataContext.checkForDisableCond) {
    return `<div class="disable-row text-truncate">${value}</div>`;
  } else {
    return `<div class="text-truncate">${value}</div>`;
  }
}

const EMPTY_GRID_MSG = 'No catalogs to map.';

@Component({
    selector: 'zetta-syn-select-semantic-objects',
    templateUrl: './syn-select-semantic-objects.component.html',
    styleUrls: ['./syn-select-semantic-objects.component.scss'],
   // encapsulation: ViewEncapsulation.None
})

export class SynSelectSemanticObjectsComponent implements OnInit, OnDestroy {

  public catalogs: any;
  public objects: any;
  objectsList: any = [];
  public grid: AngularGridInstance;
  public loggedInUserDetails = JSON.parse(sessionStorage.userInfo);
  public tableSettings: object = { 'height': '100%', 'width': "100%", 'pageSize': 6, 'isNormalPagination': false };
  tableColDef: any;
  gridOptions: any;
  hasScrolled = false;
  isLoading = true;
  total = 0;
  page = 1;
  limit = 100;
  noData = false;
  apiUrl: string = environment.config.API_URL;
  progressState = new ProjectStatus();
  dataset_id;
  catalogs_ids: any;
  objects_ids = '';
  catalogs_ids_arr: any = [];
  isDataReady;
  count = 0;
  selectedGridRowsSize = 0;
  catalog_id;
  object_id;
  syn_catalog_id;
  syn_objects_ids = '';
  syn_objects_ids_arr: any = [];
  syn_objects_selected: any = [];

  constructor(
    private catalogSvc: ZsClContentService,
    private activatedRoute: ActivatedRoute,
    public zettaUtils: ZettaUtils,
    private _router: Router,
    private messageSvc: MessageService) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.catalog_id = params['catalog_id'];
    });
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has("syn_objects_ids")) {
        this.selectedGridRowsSize = 1;
      }
    });
    this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
    this.syn_catalog_id = this.activatedRoute.snapshot.queryParamMap.get('syn_catalog_id');
    this.syn_objects_ids = this.activatedRoute.snapshot.queryParamMap.get('syn_objects_ids');      
    this.syn_objects_ids_arr = this.syn_objects_ids ? this.syn_objects_ids.split(',').map(i=>Number(i)) : [];
    
    this.progressState.states = this.zettaUtils.getStateList('classify-set');
    this.progressState.currentStateIndex = 1;
    this.progressState.currentStateInfo = 'Saved...';
    this.progressState.type = 'classifySet';
    this.progressState.isEdit = false;
    sessionStorage.removeItem('serverfilter');
    sessionStorage.removeItem('sortfilter');
    this.getSemanticObject(this.page, this.limit);

    this.tableColDef = [
      {
          'displayname': 'Semantic Object',
          'physicalname': 'semantic_object',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
          'minWidth': 150,
          'maxWidth': 250,
          'formatter': numericFormatter,
      }, {
          'displayname': 'Definitions',
          'physicalname': 'definitions',
          'sortable': true,
          'datatype': 'String',
          'filterable': true,
      }
    ];

    this.gridOptions = {
      enableGridMenu: false,
      enableAddRow: false,
      selectable: true,
      enableFiltering: true,
      CheckboxSelector: true,
      enableCellNavigation: true,
      multiSelectable: true,
      noDataMsg: EMPTY_GRID_MSG,
    };

  }

  ngOnDestroy() {
    this.messageSvc.clearMessage();
  }

  getSemanticObject(pageNo, limit) {
    this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${this.loggedInUserDetails['user_id']}/catalogs/${this.syn_catalog_id}/semantics?pagination=true&pageno=${1}&pagesize=${limit}`;
    let serverFilter = "";
    if (sessionStorage.getItem("serverfilter")) {
      serverFilter = sessionStorage.getItem("serverfilter");
    }
    let sortfilter = "";
    if (sessionStorage.getItem("sortfilter")) {
      sortfilter = sessionStorage.getItem("sortfilter");
    }
    this.catalogSvc.getSemanticsObjectsPaged(this.syn_catalog_id, pageNo, limit, serverFilter, sortfilter).subscribe(resp => {        
      this.objects = resp['currentpage'];
      if (this.objects.length === 0) {
          this.noData = true;
      }
      this.objects.forEach((element, index) => {
          element.id = Math.random();
          if(this.syn_objects_ids_arr.includes(element.entity_id)) {
            this.syn_objects_selected.push(index)
          }
      });                
      this.isLoading = false;
      this.total = resp['totalrecords'];
      let concatedRows = this.objects;
      if (this.grid) {
        concatedRows = [...this.grid.dataView.getItems(), ...this.objects];
        this.objects = concatedRows;
        this.grid.dataView.setItems(concatedRows);
        this.grid.gridService.setSelectedRows([]);
        this.hasScrolled = false;
      }
      this.count = this.count + 1;
    }, err => { });
  }
 
  checkForDisable() {
    if (this.catalog_id == this.syn_catalog_id) {
      if (this.objects && this.objects.length) {
        this.objects.forEach(element => {
          if (element.entity_id == this.object_id) {
            element['checkForDisableCond'] = true;
          }
        });
      }
    }
    setTimeout(() => {
      $('div.disable-row').parent().parent().addClass('disabled-state');
    }, 1000)
  }

  ngAfterViewChecked() {
    this.checkForDisable();
  }

  onGridCreation(grid) {
    const parent = this;
    parent.grid = grid;
    parent.grid.gridService.setSelectedRows(parent.syn_objects_selected);
    parent.grid.slickGrid.onSelectedRowsChanged.subscribe(function (e, args) {
      parent.selectedGridRowsSize = args.rows.length;
      let syn_objects_ids = [];
      args.rows.forEach(element => {
        syn_objects_ids.push(parent.objects[element].entity_id)
      });    
      parent.syn_objects_ids = syn_objects_ids.toString();
    });
  }

  onNext(): void {
    this.page++;
    this.getSemanticObject(this.page, this.limit);
  }

  onScroll(parentClass) {
    if (this.zettaUtils.virtualScroll(parentClass) && !this.hasScrolled && !this.noData) {
      this.hasScrolled = true;
      this.onNext();
    }
  }

  receiveTotalRecords($event) {
    this.total=$event;      
  }

  onCancel(): void {
    this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { showBreadcrumb: true} });
  }

  onPreviousStep(): void {
    this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-select-data-catalog'], { queryParams: { syn_objects_ids: this.syn_objects_ids },queryParamsHandling:'merge' });
  }

  onNextStep(): void {
    const gridDataService = this.grid.gridService;
    const selectedRows: number[] = gridDataService.getSelectedRows();
    const rows: any[] = [];
    selectedRows.forEach(index => {
      const row = gridDataService.getDataItemByRowNumber(index);
      if (row !== undefined && row !== null) {
        rows.push(row.entity_id);
      }
    });
    this.syn_objects_ids = rows.toString();
    this._router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-map-concepts'], { queryParams: { syn_objects_ids: this.syn_objects_ids },queryParamsHandling:'merge' });
  }

}
























// import { Project } from '../../../zettasense/content/project/project.model';
// import { AngularGridInstance, Column, Formatter } from 'angular-slickgrid';
// import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
// import { ProjectStatus } from '../../../common/components/project-progress/project-progress.model';
// import { ZettaUtils } from '../../../common/shared/zettaUtils';
// import { HttpClient } from '@angular/common/http';
// import { DatasourceService } from '../../../common/components/datasources/datasource.service';
// import { DatasetService } from '../../../common/components/datasets/dataset.service';
// import { MessageService } from '../../../common/components/message/message.service';
// import { IDataset } from '../../../common/components/datasets/dataset.model';
// import { ProjectService } from '../../../zettasense/content/project/project.service';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { BaseService } from '../../../common/services/base-service';
// import * as _ from 'lodash';
// declare var $: any;

// const RIGTH_EMPTY_GRID_MSG = 'Select a data source from the left or upload a file from your local hard drive.';
// const LEFT_EMPTY_GRID_MSG = 'No data sources.';
// @Component({
//   selector: 'zetta-syn-select-semantic-objects',
//   templateUrl: './syn-select-semantic-objects.component.html',
//   styleUrls: ['./syn-select-semantic-objects.component.scss'],
//   encapsulation: ViewEncapsulation.None
// })

// export class SynSelectSemanticObjectsComponent implements OnInit, OnDestroy {

//     isDataReady = false;
//     leftGrid: AngularGridInstance;
//     rightGrid: AngularGridInstance;
//     leftGridDataSource: any = [];
//     rightGridDataSource: any = [];
//     gridColumnsDef: any;
//     rightGridColumnsDef: any;
//     projectDetail: Project = new Project();
//     leftGridRows = 0;
//     rightGridRows = 0;
//     progressState = new ProjectStatus();

//     leftGridOptions: any;
//     rightGridOptions: any;
//     showBreadcrumb = false;
//     hasActiveJob = true;
//     datasetList;

//     tableSettings: object = { 'height': "100%", 'width': "99.37%", 'pageSize': 100, 'isNormalPagination': false };
//     modeltableSettings: object = { 'height': "100%", 'width': "99.71%" };
//     loggedInUserDetails: [];
//     datasourceId: number;
//     datasourceName: string;
//     enableUploadBtn = false;
//     selectedLeftGridRowsSize = 0;
//     selectedRightGridRowsSize = 0;
//     dataset: any = {};
//     showTab: string;
//     newDatasetSel: any = {};
//     newDataset: any = {};
//     errorMessage = undefined;
//     public dataset_id;
//     dataRes;
//     is_upload_complete = false;
//     showDelimiter = true;
//     dataSelected = -1;
//     isDataSelected = false;
//     dataSourceSelected = -1;


//     catalog_id;
//     object_id;
//     concept_id;

//     dataset_classify_ids: any = [];
//     dataset_ids: any = [];
//     no_matches: string = '';
//     datasets: string = '';;


//     // public datasets: any = [];
//     public grid: AngularGridInstance;
//     hasScrolled = false;
//     isLoading = true;
//     total = 0;
//     page = 1;
//     limit = 100;
//     noData = false;

//     modalRowData: any;
//     modalGridOptions: any;
//     modelColumnDef = [];
//     isSampleDsLoading = true;
//     public modalGrid: AngularGridInstance;
//     url;
//     safeSrc: SafeResourceUrl;
//     dsName;
//     showmodelpopup=false;
//     datasetId:any;

//     constructor(private activatedRoute: ActivatedRoute,
//         private router: Router,
//         public zettaUtils: ZettaUtils,
//         private http: HttpClient,
//         public datasourceSvc: DatasourceService,
//         public datasetSvc: DatasetService,
//         private projectService: ProjectService,
//         private sanitizer: DomSanitizer,
//         private baseService: BaseService,
//         private messageSvc: MessageService) { }

//     ngOnInit() {
//         this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('userInfo'));
//         // const showBreadcrumb = this.activatedRoute.snapshot.queryParamMap.get('showBreadcrumb');
//         this.datasetId = this.activatedRoute.snapshot.queryParamMap.get('datasetId');


//         this.activatedRoute.parent.parent.params.subscribe(params => {
//           // this.dataset_id = params['id'];
//           this.catalog_id = params['catalog_id'];
//         });
    
//         this.object_id = this.activatedRoute.snapshot.queryParamMap.get('object_id');
//         this.concept_id = this.activatedRoute.snapshot.queryParamMap.get('concept_id');
//         this.no_matches = this.activatedRoute.snapshot.queryParamMap.get('no_matches');
//         // console.log(this.no_matches)
//         if (this.no_matches !== null) {
//             this.dataset_classify_ids = this.no_matches.split(',');
//         }

//         this.datasets = this.activatedRoute.snapshot.queryParamMap.get('datasets');
//         if (this.datasets !== null && this.datasets !== '') {
//             this.dataset_ids = this.datasets.split(',');
//         }

//         if (this.concept_id === undefined || this.concept_id === null) {
//             this.activatedRoute.parent.params.subscribe(params => {
//               this.catalog_id = params['catalog_id'];
//             });
//         } else {
//             this.activatedRoute.parent.parent.params.subscribe(params => {
//               this.catalog_id = params['catalog_id'];
//             });
//         };

//         this.leftGridOptions = {
//             enableGridMenu: false,
//             enableAddRow: false,
//             selectable: true,
//             enableFiltering: true,
//             CheckboxSelector: true,
//             enableCellNavigation: true,
//             multiSelectable: true,
//             noDataMsg: LEFT_EMPTY_GRID_MSG,
//         };

//         this.rightGridOptions = {
//             enableGridMenu: false,
//             enableAddRow: false,
//             selectable: true,
//             enableFiltering: true,
//             CheckboxSelector: true,
//             enableCellNavigation: true,
//             multiSelectable: true,
//             noDataMsg: RIGTH_EMPTY_GRID_MSG,
//         };

//         this.modalGridOptions = {
//             enableGridMenu: false,
//             selectable: true,
//             enableFiltering: true,
//             CheckboxSelector: false,
//             enableCellNavigation: true,
//             enableRowSelection: true
//         };

//         this.progressState.states = this.zettaUtils.getStateList('synonyms');
//         this.progressState.currentStateIndex = 1;
//         this.progressState.currentStateInfo = 'Saved...';
//         this.progressState.type = 'synonyms';
//         this.progressState.isEdit = false;

//         this.initColDef();
//         this.getDatasets(this.page, this.limit);

//     }


//     getDatasets(pageNo, limit = 1) {
//         const getDatasets: IDataset = {
//             user_id: this.loggedInUserDetails['user_id'],
//             tenant_id: this.loggedInUserDetails['tenant_id'],
//             pageno: pageNo,
//             perpage: limit,
//           };
//           // this.tableSettings['search_url'] = `${environment.config.API_URL}/users/${user_id}/tenants/${tenant_id}/datasets?paginate=true&pageno=${pageNo}&pagesize=${limit}`;
//           this.datasetSvc.getDatasetsNoPaginate(getDatasets).subscribe((data) => {
//             this.leftGridDataSource = data;
//             let leftArr: any;
//             if(this.dataset_ids.length > 0) {
//                 this.dataset_ids.forEach(element => {
//                     leftArr = this.leftGridDataSource.filter(dataset => dataset.dataset_id === +element);
//                     leftArr[0].id = Math.random();
//                     this.rightGridDataSource.push(leftArr[0])
//                 });
//                 this.dataset_ids.forEach(element => {
//                     this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== +element);
//                 });
//             }
            
//             if (this.leftGridDataSource.length === 0) {
//               this.noData = true;
//             }
//             this.leftGridDataSource.forEach(element => {
//               element.id = Math.random();
//             });
//             this.isDataReady = true;
//             this.isLoading = false;
//             this.total = this.leftGridDataSource.length;
//             if (this.leftGrid) {
//               this.leftGrid.dataView.setItems(this.leftGridDataSource);
//               this.leftGrid.gridService.setSelectedRows([]);
//             }
//             // console.log(this.leftGridDataSource)
//           }, (error) => {
//             this.isLoading = false;
//           });
//     }

//     removeCancel1() {
//         $('#cancelBoxModal1').removeClass('show');
//     }

//     initColDef() {
//         this.gridColumnsDef = [{
//             'displayname': 'Data Source',
//             'physicalname': 'datasource_name',
//             'sortable': true,
//             'datatype': 'String',
//             'filterable': true,
//             'cssClass': 'blueLink'
//         }, {
//             'displayname': 'Data Set Name',
//             'physicalname': 'name',
//             'sortable': true,
//             'datatype': 'String',
//             'filterable': true,
//             'cssClass': 'blueLink'
//         }
//     ];

//         this.rightGridColumnsDef = [
//         {
//             'displayname': 'Data Source',
//             'physicalname': 'datasource_name',
//             'sortable': true,
//             'datatype': 'String',
//             'filterable': true,
//             'cssClass': 'blueLink'
//         }, {
//             'displayname': 'Data Set Name',
//             'physicalname': 'name',
//             'sortable': true,
//             'datatype': 'String',
//             'filterable': true,
//             'cssClass': 'blueLink'
//         }];
//     }

//     onRightGridCellClick(e): void {
//         const eventEle = e.eventData;
//         const args = e.args;
//         const row = this.rightGrid.gridService.getDataItemByRowIndex(args.row);
//         // console.log(row)
//         if (row !== undefined && row !== null) {
//             this.rightGrid.gridService.updateDataGridItem(row, false);
//             if (eventEle.target.title === row.name) {
//                 this.dsName = row.name;
//                 this.showmodelpopup=true;
//                 this.datasetId=row.dataset_id;
//                 // this.selectOptionDataSetModelList(row.dataset_id);
//                 // $('#data_refresh_modal').modal('show');
//                 // $('#data_refresh_modal').removeClass('d-none');
//             } else if (eventEle.target.title === row.datasource_name) {
//                 this.router.navigate(['/zs/datasources', row.datasource_id]);
//             }
//         }
//     }

//     onAdd() {
//         const leftGridDataService = this.leftGrid.gridService;
//         const rightGridDataService = this.rightGrid.gridService;
//         // call service to update DB as bulk operation (pass list of items to be inserted).
//         // on success Add to left Grid
//         const selectedRows: number[] = leftGridDataService.getSelectedRows();
//         const datasetList: Object[] = [];
//         const rows: any[] = [];

//         selectedRows.forEach(index => {
//             const row = leftGridDataService.getDataItemByRowNumber(index);
//             if (row !== undefined && row !== null) {
//                 rows.push(row);
//             }
//         });

//         // console.log(rows)

//         rows.forEach(row => {
//             this.rightGridDataSource.push(row);
//             rightGridDataService.addItemToDatagrid(row, true);
//             // remove Item from left grid
//             datasetList.push({ dataset_id: row.dataset_id });
//             this.leftGridDataSource = this.leftGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
//             leftGridDataService.deleteDataGridItem(row);
//         });

//         // remove row selection from left/right grid
//         leftGridDataService.setSelectedRows([]);
//         rightGridDataService.setSelectedRows([]);
//         // Update Row Count
//         this.leftGridRows = this.leftGridRows - datasetList.length;
//         this.rightGridRows = this.rightGridRows + datasetList.length;
//     }

//     onRemove() {
//         const leftGridDataService = this.leftGrid.gridService;
//         const rightGridDataService = this.rightGrid.gridService;
//         // call service to update DB as bulk operation (pass list of items to be removed).
//         // on success removed from UI
//         const selectedRows: number[] = rightGridDataService.getSelectedRows();
//         const datasetList: String[] = [];
//         const rows: any[] = [];
//         selectedRows.forEach(index => {
//             const row = rightGridDataService.getDataItemByRowIndex(index);
//             if (row !== undefined && row !== null) {
//                 rows.push(row);
//             }
//         });

//         rows.forEach(row => {
//             this.leftGridDataSource.push(row);
//             leftGridDataService.addItemToDatagrid(row, true);
//             // remove Item from right grid
//             datasetList.push(row.dataset_id);
//             this.rightGridDataSource = this.rightGridDataSource.filter(dataset => dataset.dataset_id !== row.dataset_id);
//             rightGridDataService.deleteDataGridItem(row);
//         });
//         // this.leftGrid.slickGrid.setData();
//         leftGridDataService.renderGrid();
//         rightGridDataService.renderGrid();
//         // remove row selection from left grid
//         leftGridDataService.setSelectedRows([]);
//         rightGridDataService.setSelectedRows([]);

//         this.leftGridRows = this.leftGridRows + datasetList.length;
//         this.rightGridRows = this.rightGridRows - datasetList.length;
//     }

//     getDataSetById(datasourceId) {
//         const rightGridDataService = this.rightGrid.gridService;
//         this.datasetSvc.getDataSourcesPg({ user_id: this.loggedInUserDetails['user_id'], tenant_id: this.loggedInUserDetails['tenant_id'], datasourceId: datasourceId }).subscribe(resp => {
//             this.rightGridDataSource = resp['currentpage'];
//             this.datasetList = this.rightGridDataSource;
//             if (this.rightGridDataSource) {
//                 this.rightGridDataSource.forEach(element => {
//                     element.id = Math.random();
//                     element.dataset_type_id = 4;  
//                     element.delimiter = ',';
//                     element.source_table_or_filename = element.name;
//                     element.header = true;
//                     element.schema = '';
//                 });
//             }
//             this.rightGridRows = this.rightGridDataSource.length;
//         }, error => {
//             this.rightGridDataSource = [];
//             this.rightGridRows = this.rightGridDataSource.length;
//             this.messageSvc.sendMessage({ message: 'Select Dataset Types has failed', type: 'INFO', hideboard: true });
//         });
//     }


//     rightGridCreated(grid) {
//         this.rightGrid = grid;
//     }

//     leftGridCreated(grid) {
//         this.leftGrid = grid;
//     }

//     onCancelStep(): void {
//       if (this.concept_id === undefined || this.concept_id === null) {
//         this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
//       } else {
//         this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id], { queryParams: { object_id: this.object_id, showBreadcrumb: true } });
//       };
//     }

//     onPrevious() {
//         let datasetsSelected = []
//         this.rightGridDataSource.forEach(element => {
//             datasetsSelected.push(element.dataset_id) 
//         });
//         this.datasets = datasetsSelected.toString();

//         if(this.concept_id === undefined || this.concept_id === null) {
//             this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-select-data-catalog'], 
//                             { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
//         } else {
//             this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/syn-select-data-catalog'], 
//                             { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
//         };
//     }

//     onNext() {
//         let datasetsSelected = []
//         this.rightGridDataSource.forEach(element => {
//             datasetsSelected.push(element.dataset_id) 
//         });
//         this.datasets = datasetsSelected.toString();

//         if(this.concept_id === undefined || this.concept_id === null) {
//             this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/syn-map-concepts'], 
//                             { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
//         } else {
//             this.router.navigate(['/zs-cl/catalogs/' + this.catalog_id + '/objects/' + this.object_id + '/concepts/syn-map-concepts'], 
//                             { queryParams: { object_id: this.object_id, concept_id: this.concept_id, no_matches: this.no_matches, datasets: this.datasets } });
//         };
//     }

//     ngOnDestroy() {
//         // if (!this.showBreadcrumb) {
//         //     $('#breadcrumb').removeClass('d-none');
//         // }
//         // this.messageSvc.clearMessage();
//     }

//     leftGridCount() {
//         if (this.leftGrid) {
//             return this.leftGrid.dataView.getItems().length;
//         } else {
//             return this.leftGridDataSource.length;
//         }
//     }

//     rightGridCount() {
//         if (this.rightGrid) {
//             return this.rightGrid.dataView.getItems().length;
//         } else {
//             return this.rightGridDataSource.length;
//         }
//     }

//     cancel() {
//         $("#data-" + this.dataSelected).prop("checked", false);
//         this.isDataSelected = false;
//     }

//     onChangeHeader(){
//         if($('#header').is(":checked")){
//             $('#divSchema').addClass('d-none');
//         }
//         else if($('#header').is(":not(:checked)")){
//             $('#divSchema').removeClass('d-none');
//         }
//     }

//     // Modal Datasets
//     selectOptionDataSetModelList(dataSetId, limit = 30) {
//         this.url = this.baseService.apiUrl + '/users/' + this.loggedInUserDetails['user_id'] + '/tenants/' + this.loggedInUserDetails['tenant_id'] + '/datasets/' + dataSetId + '/dataprofiler/report';
//         this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  
//         // this.isSampleDsLoading = true;
//         this.projectService.getSelectOptionModelListByDataSetId(this.loggedInUserDetails['user_id'], this.loggedInUserDetails['tenant_id'], dataSetId, this.page, limit).subscribe((data) => {
//             this.modalRowData = data['currentpage'];
//             let rowKeys = [];
//             if (data['currentpage'].length > 0) {
//                 let myVar: any = data['currentpage'][0];
//                 for (var key in myVar) {
//                     rowKeys.push(key) 
//                 }
//             }
//             this.modelColumnDef = [];
//             rowKeys.forEach(column => {
//               this.modelColumnDef.push({
//                 'displayname': column,
//                 'physicalname': column,
//                 'sortable': true,
//                 'datatype': 'String',
//                 'filterable': true,
//                 'minWidth': 150,
//               });
//             });
    
//             this.modalRowData.forEach((element: any) => {
//               element.id = Math.random();
//             });
    
//             // this.total = data['totalrecords'];
//             if (this.modalGrid) {
//               this.modalGrid.dataView.setItems(this.modalRowData);
//               this.modalGrid.gridService.setSelectedRows([]);
//             }
//             this.isSampleDsLoading = false;
//           }, err => {
//             this.isSampleDsLoading = false;
//             this.modalRowData = [];
//           });
//       }
  
//       onModelGridCreation(grid) {
//         this.modalGrid = grid;
//       }

//       onDatasetCellClick(e): void {
//         const eventEle = e.eventData;
//         const args = e.args;
//         const row = this.leftGrid.gridService.getDataItemByRowIndex(args.row);
//         // console.log(row)
//         if (row !== undefined && row !== null) {
//           if (eventEle.target.title === row.name) {
//             this.dsName = row.name;
//             this.showmodelpopup=true;
//             this.datasetId=row.dataset_id;
//           } else if (eventEle.target.title === row.datasource_name) {
//             this.router.navigate(['/zs/datasources', row.datasource_id]);
//           }
//         }
//       }

//       popupoutput(message){
//         this.showmodelpopup = message;   
//       }


// }


