import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../common/services/base-service';
import { Component, OnInit, NgZone, Compiler, HostListener, OnDestroy } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AppGlobals } from '../../common/shared/app.globals';
import { ZettaUtils } from '../../common/shared/zettaUtils';
import { MsalService } from '@azure/msal-angular';
import { ObservableService } from '../../common/services/observable.service';

@Component({
  selector: 'zetta-zc-header',
  templateUrl: './zc-header.component.html',
  styleUrls: ['./zc-header.component.scss']
})
export class ZcHeaderComponent implements OnDestroy, OnInit {

  
	public userinfo;
	public profilePic;
	private _apiUrl: string = environment.config.API_URL;

	endTime = 1;
	minutesDisplay = 0;
	secondsDisplay = 0;
	unsubscribe$: Subject<void> = new Subject();
	timerSubscription: Subscription;
	routeDelay;
	customStyleAvtar = {
		fontSize: '12px'
	};
	searchText:string=''

	constructor(public app: AppComponent,
		private _router: Router,
		private zone: NgZone,
		private _compiler: Compiler,
		private _baseService: BaseService,
		private http: HttpClient,
		private _authSvc: AuthenticationService,
		public zettaUtils: ZettaUtils,
		private authService:MsalService, 
		public _obervableService:ObservableService) { }

	ngOnInit(): void {
		this.userinfo = JSON.parse(sessionStorage.getItem('userInfo'));		
		this.getUserPic();

		this.resetTimer();
		this._baseService.userActionOccured.pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(() => {
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
			}
			this.resetTimer();
		});
	}

	@HostListener('document:keyup', ['$event'])
	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:click', ['$event'])
	@HostListener('document:wheel', ['$event'])
	resetActiveTimer() {
		this._baseService.notifyUserAction();
	}

	public getUserPic() {
		const { userid } = this.userinfo;		
		this._baseService.getUserPic(this.userinfo.user_id, this.userinfo.group_id, this.http).subscribe(image => {
			let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.profilePic = reader.result;
			}, false);
			if (image && image.size && image.type == "image/gif") {
				this.profilePic = reader.readAsDataURL(image);
			} else {
				this.profilePic = null;
			}
		}, err => {
			this.profilePic = err.url;
		});
	}

	logout() {
		this.timerSubscription.unsubscribe();

		this._compiler.clearCache();
		if(!!this.authService.getAccount() && sessionStorage.getItem('loginProvider') === 'azure_sos'){
			this.authService.logout();
		} else {
			this._authSvc.signOut();
		}
		localStorage.clear();
		sessionStorage.clear();
		// this.routeDelay = setTimeout(() => {
		// 	this._router.navigate([AppGlobals.ZETTASENSE_LOGIN]);
		// }, 100);
	}

	ngOnDestroy() {
		this.timerSubscription.unsubscribe();
		// clearTimeout(this.routeDelay);
	}

	resetTimer(endTime: number = this.endTime) {
		const interval = 1000;
		const duration = endTime * AppGlobals.USER_SESSION_TIMEOUT;
		this.timerSubscription = timer(0, interval).pipe(
			take(duration)
		).subscribe(value =>
			this.render((duration - +value) * interval),
			err => { },
			() => {
				//TODO fix logout timer issue
				// this.logout();
			}
		)
	}

	private render(count) {
		this.secondsDisplay = this.getSeconds(count);
		this.minutesDisplay = this.getMinutes(count);
	}

	private getSeconds(ticks: number) {
		const seconds = ((ticks % 60000) / 1000).toFixed(0);
		return this.pad(seconds);
	}

	private getMinutes(ticks: number) {
		const minutes = Math.floor(ticks / 60000);
		return this.pad(minutes);
	}

	private pad(digit: any) {
		return digit <= 9 ? '0' + digit : digit;
	}

	reDirectApp(routeUrl, IsZmLogin, IsZsLogin) {
		sessionStorage.setItem(AppGlobals.ISZMLOGGEDIN, IsZmLogin);
		sessionStorage.setItem(AppGlobals.ISZSLOGGEDIN, IsZsLogin);
		this._router.navigate([routeUrl]);


		// if (routeUrl === 'zs-cl/home'){
		// 	this._router.navigate(['zc/reports']);
		// } else {
		// 	this._router.navigate([routeUrl]);
		// }

	}
	searchData(){
		this._obervableService.sendSearchText=this.searchText
	}
	searchClick() {
		if (this._router.url !== '/zs/search') {
			this._router.navigate(['/zs/search']);
		}
	}
}
